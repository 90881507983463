import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import Status from '../general/Status';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const FormStatusSelector = ({ filter, onChange, isMulti, ...rest }) => {
  const [selectedFormStatuses, setSelectedFormStatuses] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'form-statuses' },
    { selected: selectedFormStatuses, setSelected: setSelectedFormStatuses }
  );

  return (
    <Select
      autoFocus
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={
        filter?.value
          ? selectedFormStatuses.filter(el => filter?.value?.map(Number).includes(el.id))
          : []
      }
      isMulti={isMulti}
      isAsync
      defaultOptions={() => getInitialAsyncValues('form-statuses')}
      loadOptions={search => getAsyncOptions(search, 'form-statuses')}
      onChange={(selected, options) => {
        setSelectedFormStatuses(isMulti ? selected : [selected]);
        onChange(
          { value: selected ? (isMulti ? selected.map(s => s.id) : selected) : '' },
          options
        );
      }}
      components={{ MultiValue, Option }}
      {...rest}
    />
  );
};

export const MultiValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      {props.data ? <Status value={props.data} /> : null}
    </components.MultiValue>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data ? <Status value={props.data} /> : null}
    </components.Option>
  );
};

export default FormStatusSelector;
