import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'utils/hooks';

import { FormState, FormTypes } from 'common/entities/form/FormTypes';
import Textarea from 'common/components/form/inputs/Textarea';
import SubmitEvaluationButton from './SubmitEvaluationButton';
import SvgRender from 'common/components/general/SvgRender';
import comments from 'common/assets/svg/common/comments.svg';
import Criterion from './criterion';
import Rating from './Rating';
import { parseEvaluationFormParams } from '../helpers';

import { selectEventId } from 'events/store/events/selectors';
import {
  getEventAudits,
  submitEventAuditEvaluations
} from 'events/store/event-modules/audits/actions';

const Body = ({ formState, eventAuditId, collectValues, hasSubmittedEvaluation }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ trainings: null, scale: null });

  const dispatch = useDispatch();
  const { fields, changeField, subStates } = useFormState(formState);

  const eventId = useSelector(selectEventId);

  const criteriaStates = subStates('criteria');

  const onSubmitEvaluation = useCallback(async () => {
    const initErrors = () => {
      const trainings = fields.criteria?.find(criterion => criterion.type.value === 'trainings');
      const scale = fields.criteria?.find(criterion => criterion.type.value === 'scale');

      if (trainings && !trainings.trainings.value?.length) {
        setErrors(e => ({ ...e, trainings: 'This field is required' }));
      } else if (trainings && trainings.trainings.value?.length) {
        setErrors(e => ({ ...e, trainings: null }));
      }

      if (scale && !scale.scale?.value) {
        setErrors(e => ({ ...e, scale: 'This field is required' }));
      } else if (scale && scale.scale?.value) {
        setErrors(e => ({ ...e, scale: null }));
      }
    };

    const values = collectValues();

    initErrors();

    const trainings = fields.criteria?.find(criterion => criterion.type.value === 'trainings');
    const scale = fields.criteria?.find(criterion => criterion.type.value === 'scale');

    if ((trainings && !trainings.trainings.value?.length) || (scale && !scale.scale?.value)) return;

    const params = parseEvaluationFormParams(values, eventAuditId);

    try {
      setIsSubmitting(true);
      await dispatch(submitEventAuditEvaluations(params)).unwrap();
      await dispatch(getEventAudits({ event_id: eventId })).unwrap();

      setIsSubmitting(false);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  }, [collectValues, fields.criteria, eventAuditId, dispatch, eventId]);

  return (
    <div className="audits-event-module-evaluations__body">
      <div className="audits-event-module-evaluations__body--criteria">
        {(criteriaStates || [])?.map((criterionState, index) => (
          <Criterion
            key={index}
            index={index}
            criterionState={criterionState}
            hasSubmittedEvaluation={hasSubmittedEvaluation}
            errors={errors}
          />
        ))}
      </div>
      <Textarea
        placeholder={hasSubmittedEvaluation ? '' : 'Add some remarks'}
        invisible={hasSubmittedEvaluation}
        disabled={hasSubmittedEvaluation}
        className="cpx-12 mt-3 mb-1"
        label={
          <div className="d-flex align-items-center text-violet">
            <SvgRender className="me-1" src={comments} style={{ width: 13, height: 13 }} />
            Remarks
          </div>
        }
        onChange={changeField('remarks')}
        rows={6}
        {...fields.remarks}
      />
      <div className="d-flex align-items-center justify-content-between cpx-12 cpb-12">
        <div className="evaluations-body__rating d-flex align-items-center">
          <Rating
            hasSubmittedEvaluation={hasSubmittedEvaluation}
            showNumber={!hasSubmittedEvaluation}
          />
        </div>
        <SubmitEvaluationButton
          onClick={onSubmitEvaluation}
          disabled={isSubmitting}
          hasSubmittedEvaluation={hasSubmittedEvaluation}
        />
      </div>
    </div>
  );
};

Body.propTypes = {
  formState: FormState,
  eventAuditId: PropTypes.number.isRequired,
  collectValues: FormTypes.collectValues,
  hasSubmittedEvaluation: PropTypes.bool
};

export default Body;
