import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { useActions } from 'utils/hooks';
import { selectPreviousCaptainReportEngines } from 'captain-reports/store/selectors';
import NumberField from 'captain-reports/templates/inputs/NumberField';

import { selectCaptainReportVessel } from 'captain-reports/store/selectors';
import { numberToStr } from 'common/utils/numbers';
import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';

const MainEngineSumpTank = ({
  tooltip,
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const cpVessel = useSelector(selectCaptainReportVessel);
  const previousReportEngines = useSelector(selectPreviousCaptainReportEngines);
  const [setValue] = useActions([reportActions.setValue]);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const getPercent = () => {
    if (!cpVessel.sump_capacity) {
      return '-';
    }

    if (!value) {
      return '0';
    }

    let result = (value / cpVessel.sump_capacity) * 100;

    return result.toFixed(2);
  };

  useEffect(() => {
    const prevValue = previousReportEngines[sectionActiveTab]?.sump_tank_rob?.value;

    if (
      previousReportEngines?.length &&
      (prevValue || prevValue === 0) &&
      prevValue !== value &&
      value === null
    ) {
      setValue({
        key: field.key,
        value: prevValue,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [previousReportEngines?.length, value]);

  return (
    <Row className="align-items-center">
      <Col xs={12}>
        <NumberField
          tooltip={tooltip}
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
        <div className="mt-1 fs-10 text-violet">
          out of {cpVessel.sump_capacity ? numberToStr(cpVessel.sump_capacity) : '-'} L -{' '}
          {getPercent()}%
        </div>
      </Col>
    </Row>
  );
};

export default MainEngineSumpTank;
