import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import CheckboxInput from './CheckboxInput';
import useValue from 'captain-reports/utils/useValue';

const CheckboxInputWithTooltip = ({
  field,
  tooltip,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  ...rest
}) => {
  const { avoidRender, tooltipID } = useTooltipID(field.key);
  const { icon, activeColor, iconStyle = null, color = 'dark', ...restField } = field;

  const value = useValue({
    key: restField.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  if (avoidRender) return null;

  return (
    <div className="d-flex align-items-center">
      <CheckboxInput
        field={restField}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
        {...rest}
      />

      <SvgRender
        id={tooltipID}
        src={field.icon}
        style={iconStyle || { width: 14, height: 14 }}
        className={`${value ? `text-${activeColor}` : `text-${color}`} mt-1 cursor-pointer`}
      />

      <Tooltip innerClassName="min-width-548 text-start" target={tooltipID}>
        {tooltip}
      </Tooltip>
    </div>
  );
};

export default CheckboxInputWithTooltip;
