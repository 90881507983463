import DepartmentsFilterSelector from 'common/components/selectors/departments/FilterSelector';
import FormsSelector from 'common/components/selectors/FormsSelector';
import VesselSelector from 'common/components/selectors/VesselSelector';
import ImportanceSelector from 'common/components/selectors/ImportanceSelector';
import PeriodicitySelector from 'common/components/selectors/PeriodicitySelector';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';

import { stringifyObj, parseQueryParams } from 'utils/urls';
import moment from 'moment';
import DrillsSelector from 'common/components/selectors/DrillsSelector';
import TrainingTypeSelector from 'common/components/selectors/TrainingTypeSelector';

export const mapFormPlanningRows = rows => {
  return rows.map(row => ({
    hasAlarm: _get(row, 'alarm_state') === true,
    importance: _get(row, 'form.importance'),
    department: _get(row, 'form.department'),
    title: _get(row, 'name'),
    form: _get(row, 'form'),
    form_form_field: _get(row, 'form_form_field'),
    form_form_field_value: _get(row, 'form_form_field_value'),
    formSubmissions: _get(row, 'form_submissions'),
    remarks: _get(row, 'remarks'),
    dates: _get(row, 'dates'),
    periodicity: _get(row, 'periodicity'),
    vessel: _get(row, 'vessel'),
    id: _get(row, 'id')
  }));
};

export const getSubmissionDatesPerMonth = submissions => {
  return submissions.reduce((acc, submission) => {
    const month = moment(submission.submission_date).format('MMMM');
    if (!acc[month]) {
      acc[month.toLowerCase()] = [];
    }

    acc[month.toLowerCase()].push({
      date: moment(submission.submission_date, 'YYYY-MM-DD'),
      ...submission
    });

    return acc;
  }, {});
};

export const generateMonths = (startDate, endDate) => {
  const months = {};

  const start = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');

  let current = start;

  let i = 0;

  while (current <= end) {
    const month = current.format('MMMM');
    months[current.format('YYYY-MM')] = {
      key: month,
      title: month.substring(0, 3),
      tooltipTitle: current.format('MMMM, YYYY'),
      yearMonth: current.format('YYYY-MM'),
      dates: {},
      id: i
    };
    current.add(1, 'month');
    i++;
  }

  return months;
};

export const mapFormSubmissions = submissions => {
  return submissions.map(submission => ({
    ...submission,
    submission_date: moment(submission.submission_date, 'YYYY-MM-DD')
  }));
};

export const getSubmissionColumns = (
  data,
  from = moment().startOf('year').format('YYYY-MM-DD'),
  to = moment().endOf('year').format('YYYY-MM-DD')
) => {
  const columnDates = generateMonths(from, to);

  data.forEach(formPlanning => {
    const submissions = _groupBy(
      mapFormSubmissions(formPlanning?.form_submissions || []),
      'submission_date_year_month'
    );
    const settings = _groupBy(
      formPlanning?.dates.map(d => ({ date_year_month: moment(d).format('YYYY-MM'), date: d })),
      'date_year_month'
    );

    Object.keys(columnDates).forEach(key => {
      columnDates[key]['dates'][formPlanning.id] = {
        submissions: submissions[key] || [],
        settings: settings[key] || []
      };
    });
  });

  return Object.values(columnDates);
};

export const getUnitAndValueFromPeriodicity = periodicity => {
  switch (periodicity) {
    case 'annually':
      return { unit: 'years', amount: 1 };
    case 'every_two_months':
      return { unit: 'months', amount: 2 };
    case 'every_three_months':
      return { unit: 'months', amount: 3 };
    case 'every_four_months':
      return { unit: 'months', amount: 4 };
    case 'every_five_months':
      return { unit: 'months', amount: 5 };
    case 'every_six_months':
      return { unit: 'months', amount: 6 };
    case 'every_seven_months':
      return { unit: 'months', amount: 7 };
    case 'every_eight_months':
      return { unit: 'months', amount: 8 };
    case 'every_nine_months':
      return { unit: 'months', amount: 9 };
    case 'every_ten_months':
      return { unit: 'months', amount: 10 };
    case 'every_eleven_months':
      return { unit: 'months', amount: 11 };
    case 'monthly':
      return { unit: 'month', amount: 1 };
    case 'weekly':
      return { unit: 'days', amount: 7 };
    default:
    case 'custom':
      return { unit: 'days', amount: 0 };
  }
};

export const handleSort = (sortKey, history, tableSorting) => {
  const { searchId, ...rest } = parseQueryParams(history.location.search);
  let updatedSorting = {};

  if (tableSorting[sortKey]) {
    if (tableSorting[sortKey] === 'asc') {
      updatedSorting[sortKey] = 'desc';

      const updated = { ...rest, sorting: updatedSorting };

      history.replace({
        pathname: history.location.pathname,
        search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
      });
    } else {
      const updated = { ...rest, sorting: undefined };

      history.replace({
        pathname: history.location.pathname,
        search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
      });
    }
  } else {
    updatedSorting[sortKey] = 'asc';

    const updated = { ...rest, sorting: updatedSorting };

    history.replace({
      pathname: history.location.pathname,
      search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
    });
  }
};

export const tableColumns = [
  {
    header: 'Vessels',
    key: 'vessels',
    type: 'collection',
    canFilter: true,
    component: VesselSelector,
    componentRest: { isMulti: true }
  },
  {
    key: 'forms',
    header: 'Forms',
    type: 'collection',
    canFilter: true,
    component: FormsSelector,
    componentRest: { isMulti: true }
  },
  {
    key: 'departments',
    header: 'Departments',
    type: 'collection',
    canFilter: true,
    component: DepartmentsFilterSelector,
    componentRest: { isMulti: true, styled: false, gray: false }
  },
  {
    key: 'importance',
    header: 'Importance',
    type: 'collection',
    canFilter: true,
    component: ImportanceSelector,
    componentRest: { isMulti: true }
  },
  {
    key: 'periodicity',
    header: 'Periodicity',
    type: 'collection',
    canFilter: true,
    component: PeriodicitySelector,
    componentRest: { isMulti: true, styled: false }
  },
  {
    header: 'Drills',
    key: 'drill',
    type: 'collection',
    hidden: true,
    sort: false,
    component: DrillsSelector,
    canFilter: true,
    componentRest: { isMulti: true }
  },
  {
    header: 'Training',
    key: 'training',
    sort: false,
    hidden: true,
    canFilter: true,
    component: TrainingTypeSelector,
    type: 'collection',
    componentRest: { isMulti: true, listParams: { is_onboard: true } }
  }
];
