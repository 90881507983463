import { createSelector } from 'reselect';
import _orderBy from 'lodash/orderBy';

const selectOfficeNotes = state => state.eventModules.officeNotes;

export const selectOfficeNotesIsLoading = state => selectOfficeNotes(state).loading;
export const selectOfficeSavedNotes = createSelector(selectOfficeNotes, ({ saved_notes }) =>
  _orderBy(saved_notes, 'created_at', 'desc')
);

export const selectOfficeNotesFormData = createSelector(selectOfficeSavedNotes, savedNotes =>
  savedNotes?.length ? savedNotes[0] : {}
);
