import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const styleDefinitions = [
  { type: 'attr', key: 'fill', style: 'labelTextColor', defaultValue: variables.primary },
  { type: 'attr', key: 'font-weight', style: 'labelFontWeight', defaultValue: 700 },
  { type: 'attr', key: 'font-size', style: 'labelFontSize', defaultValue: 10 },
  { type: 'attr', key: 'text-anchor', style: 'labelTextAlign', defaultValue: 'center' }
];

const renderPlot = ({ plotID, plot, getPlotStyle, xFn, y0Fn, element, getGraphStyle }) => {
  // Notice the .selectAll here. It is important since the number of elements we have is the same as our data points.
  // This is different from the rest since in the line plotType for example, we only have one single <path /> element.
  element
    .selectAll(`#${plotID} text`)
    .data(plot.data)
    .attr('x', xFn)
    .attr('y', () => y0Fn() + getGraphStyle('marginBottom'));

  styleDefinitions.forEach(styleDefinition => {
    element
      // Here is the .selectAll again!
      .selectAll(`#${plotID} text`)
      [styleDefinition.type](
        styleDefinition.key,
        getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
      );
  });
};

const renderComponent = ({ plotID, plot }) => {
  return (
    <g id={plotID} key={plotID}>
      {/* We create a <text /> for each data point. */}
      {plot.data.map(dataPoint => (
        <text key={dataPoint.label}>{dataPoint.label}</text>
      ))}
    </g>
  );
};

const xAxisLabels = {
  renderPlot,
  renderComponent
};

export default xAxisLabels;
