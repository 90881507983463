import React from 'react';
import styled from '@emotion/styled';
import CircledButton from 'common/components/buttons/CircledButton';
import PropTypes from 'prop-types';

const FormSaveActions = ({
  onCancel,
  onSave,
  onEdit,
  onDelete,
  onView,
  isDisabled = false,
  isHovering = true,
  mode = 'edit',
  position = 'absolute',
  style = { right: 0, top: 4 },
  editButtonProps = {},
  cancelButtonProps = {},
  saveButtonProps = {},
  deleteButtonProps = {},
  CancelButtonComponent = CircledButton,
  SaveButtonComponent = CircledButton,
  EditButtonComponent = CircledButton,
  DeleteButtonComponent = CircledButton
}) => {
  return (
    <Wrapper
      position={position}
      className="d-flex align-items-center justify-content-end"
      style={style}
    >
      {mode === 'edit' ? (
        <>
          {onCancel && (
            <CancelButtonComponent
              type="close"
              className={`${onSave ? 'me-1' : ''}`}
              onClick={onCancel}
              {...cancelButtonProps}
            />
          )}
          {onSave && (
            <SaveButtonComponent
              type="save"
              disabled={isDisabled}
              onClick={onSave}
              {...saveButtonProps}
            />
          )}
        </>
      ) : isHovering ? (
        <>
          {onView && (
            <CircledButton type="view" svgStyle={{ width: 14, height: 14 }} onClick={onView} />
          )}
          {onEdit ? (
            <EditButtonComponent
              type="edit"
              className="ms-1"
              svgStyle={{ width: 12, height: 12 }}
              disabled={isDisabled}
              onClick={onEdit}
              {...editButtonProps}
            />
          ) : null}
          {onDelete && (
            <DeleteButtonComponent
              type="delete"
              className="ms-1"
              disabled={isDisabled}
              onClick={onDelete}
              {...deleteButtonProps}
            />
          )}
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: ${({ position }) => position};
`;

FormSaveActions.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  mode: PropTypes.oneOf(['edit', 'view']),
  isHovering: PropTypes.bool, // Show edit icon only on hover
  isDisabled: PropTypes.bool,
  saveButtonProps: PropTypes.object,
  editButtonProps: PropTypes.object,
  deleteButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  CancelButtonComponent: PropTypes.node,
  SaveButtonComponent: PropTypes.node,
  EditButtonComponent: PropTypes.node
};

export default FormSaveActions;
