import { getFormFieldParams } from 'common/components/forms/digital/state-utils';
import CircledButton from '@/ts-common/components/buttons/CircledButton';

const AddRowButton = ({ formField, setFormState, formFieldId, visibleRows }) => {
  const { max_row_count } = getFormFieldParams(formField);
  const disabled = visibleRows === max_row_count;

  const onAdd = () => {
    if (disabled) return;

    setFormState(prev => ({
      ...prev,
      [formFieldId]: {
        value: { row_count: visibleRows + 1 },
        error: ''
      }
    }));
  };

  return (
    <div className="pb-1 d-flex justify-content-end cpe-12">
      <CircledButton type="add" onClick={onAdd} disabled={disabled} label="Add more" />
    </div>
  );
};

export default AddRowButton;
