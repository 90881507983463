import React from 'react';
import { Link } from 'react-router-dom';
import SvgRender from 'common/components/general/SvgRender';
import airplane from 'common/assets/svg/common/airplane-up.svg';
import paths from 'routing/routes/_paths';
import { stringifyObj } from 'utils/urls';
import { useSelector } from 'react-redux';
import { selectEventTicketingCase, selectIsCreate } from 'events/store/events/selectors';

const GoToTicketingCaseButton = () => {
  const ticketingCase = useSelector(selectEventTicketingCase);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isCreate = useSelector(selectIsCreate);

  if (!ticketingCase || isOnBoard) return null;

  return (
    <Link
      target="_blank"
      to={{
        pathname: `${paths.ticketing}/events`,
        search: `${stringifyObj({
          filters: [
            {
              name: 'event_id',
              operation: 'oneOf',
              value: [ticketingCase?.event_id]
            },
            {
              name: 'ticketing_status_id',
              operation: 'oneOf',
              value: [ticketingCase?.ticketing_status_id]
            }
          ]
        })}`
      }}
      className={`${
        !isCreate ? 'right-96' : 'right-32'
      } position-absolute top-12 border-0 d-inline-flex align-items-center cpx-12 fw-medium rounded-sm btn btn-primary btn-sm text-decoration-none text-white`}
      data-cy="go-to-ticketing-case"
    >
      <SvgRender src={airplane} style={{ width: 16, height: 16 }} className="me-1" />
      Go to Ticket Case
    </Link>
  );
};

export default GoToTicketingCaseButton;
