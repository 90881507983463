import PropTypes from 'prop-types';

export const FormField = PropTypes.shape({
  value: PropTypes.any.isRequired,
  error: PropTypes.string
});

export const FormState = PropTypes.shape({
  isDirty: PropTypes.bool.isRequired,
  setField: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
});

export const FormTypes = PropTypes.shape({
  collectValues: PropTypes.func,
  hasErrors: PropTypes.bool,
  loadValues: PropTypes.func,
  formState: FormState,
  resetForm: PropTypes.func
});
