import React, { useMemo, useEffect } from 'react';
import _sortBy from 'lodash/sortBy';

import ListSelect from 'common/components/selectors/ListSelect';
import useRouter from 'use-react-router';
import { parseQueryParams } from 'utils/urls';
import { useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { getReportPathname } from '../_helpers';
import { performanceReportPrefilledOptions } from 'common/utils/prefilled-lists';

const getReportAlias = label => {
  switch (label) {
    case 'purchasing-budget':
      return 'budget';
    case 'items-ordered-onboard':
      return 'items-ordered';
    default:
      return label;
  }
};

const getPrefilledOptions = label => {
  switch (label) {
    case 'reports-performance':
      return performanceReportPrefilledOptions;

    default:
      return [];
  }
};

const SelectorWithLabel = ({
  type,
  useLocationSearch = false,
  preventRedirect = true,
  ...rest
}) => {
  const listLabel = `reports-${type}`;
  const options = useSelector(state => selectListOptionsFromStore(state, listLabel));
  const prefilledOptions = getPrefilledOptions(listLabel);

  const sortedOptions = _sortBy([...(options || []), ...prefilledOptions], option => option.name);

  const { history, match } = useRouter();

  const report = useMemo(() => {
    if (match.params?.report) return match.params.report;

    const searchedReport = parseQueryParams(history.location.search)?.report;
    if (searchedReport) return searchedReport;
  }, [history.location.search, match.params?.report]);

  const filters = [{ name: 'category', operation: '=', value: type }];

  const changeReport = value => {
    if (value === report) return;

    if (useLocationSearch) {
      history.replace({ pathname: history.location.pathname, search: `report=${value}` });
    } else {
      if (!report) {
        history.replace(`${history.location.pathname}/${value}`);
      } else {
        history.replace(getReportPathname(history.location.pathname, report, value));
      }
    }
  };

  useEffect(() => {
    if (options?.length && !report && !preventRedirect)
      changeReport(getReportAlias(options[0].label));
  }, [options, report, preventRedirect]);

  return (
    <ListSelect
      onChange={changeReport}
      getOptionLabel={option => option.name}
      getOptionValue={option => getReportAlias(option.label)}
      list={listLabel}
      params={{ filters }}
      isClearable={false}
      options={sortedOptions}
      size="lg"
      value={report}
      placeholder="Select report"
      {...rest}
    />
  );
};

export default SelectorWithLabel;
