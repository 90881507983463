import { useCallback } from 'react';

import { useActions } from 'utils/hooks';

import * as galleryActions from 'common/components/gallery/store/actions';

const useGallery = () => {
  const [setGalleryFiles, toggleGallery, setSelectedFile] = useActions([
    galleryActions.setGalleryFiles,
    galleryActions.toggleGallery,
    galleryActions.setSelectedFile
  ]);

  const setFiles = useCallback(files => {
    setGalleryFiles(files);
  }, []);

  const setIsOpen = useCallback(isOpen => {
    toggleGallery(isOpen);
  }, []);

  const setCurrentFile = useCallback(file => {
    setSelectedFile(file);
  }, []);

  return {
    setFiles,
    setIsOpen,
    setCurrentFile
  };
};

export default useGallery;
