import { get, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getEmailConnection = createAsyncThunk(
  'GET_EMAIL_CONNECTION',
  async (params, { rejectWithValue }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await get(`/events/${event_id}/email-connection`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createEmailConnection = createAsyncThunk(
  'CREATE_EMAIL_CONNECTION',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/email-connection`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteEmailConnection = createAsyncThunk(
  'DELETE_EMAIL_CONNECTION',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, email_connection_id } = params;

    try {
      const res = await deleteRequest(
        `/events/${event_id}/email-connection/${email_connection_id}`
      );
      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
