import { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Row, Col, Label } from 'reactstrap';
import moment from 'moment';
import _debounce from 'lodash/debounce';

import { useForm, useFormState } from 'utils/hooks';

import Drawer, { DrawerHeader, FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import { selectLatestPeriod } from 'common/components/mga/store/selectors';
import ListSelect from 'common/components/selectors/ListSelect';
import DateInput from 'common/components/form/inputs/date';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Input from 'common/components/form/inputs/Input';
import UploadFiles from 'common/components/form/inputs/upload-files';
import Spinner from 'common/components/general/Spinner';
import DigitalSignature from 'common/components/digital-signature';
import {
  setMgaActionForm,
  createMgaAction,
  editMgaAction,
  getMgaAction,
  createMgaActionDigitalSignature,
  deleteMgaActionDigitalSignature,
  getMgaActionDigitalSignatures
} from 'common/components/mga/store/actions';
import { getLocalAmountConversion } from '@/common/components/mga/store/actions-ts';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import { useDrawer } from 'common/components/drawer';
import { strToNumber, numberToStr, hasValue } from 'common/utils/numbers';
import SvgRender from 'common/components/general/SvgRender';
import {
  showActionTypeInventoryItems,
  getIsInventoryDepletionNotActive
} from 'common/components/mga/store/parser';
import DeleteActionModal from 'common/components/mga/components/actions/DeleteActionModal';
import {
  selectActionFormIsOpen,
  selectActionFormActive,
  selectActionFormCell,
  selectInventoryDepletionMethod
} from 'common/components/mga/store/selectors';
import {
  selectBaseCurrency,
  selectBaseCurrencyLabel
} from '@/common/components/mga/store/selectors-ts';
import { getInitialSingleValue } from 'common/utils/lists';

import { selectListOptionsFromStore } from 'store/lists/selectors';

import PastActions from './PastActions';
import SubAccountField from './SubAccountField';
import InventoryItems from './InventoryItems';
import CrewAccountField from './CrewAccountField';
import BankAccountField from './BankAccountField';
import config, { configWithoutExtraCurrencies, configForExtraCurrencies } from './config';

import binIcon from 'common/assets/svg/actions/delete.svg';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';
import { LOGS_ENTITY_TYPES } from '@/common/types/logs';
import LogsTimelineDrawer from '@/common/components/logs-timeline-drawer';

const ActionForm = ({ hasExtraCurrencies }) => {
  const dispatch = useAppDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [convertedAmount, setConvertedAmount] = useState(null);

  const logsDrawer = useDrawer();

  const inventoryDepletionMethodLabel = useAppSelector(selectInventoryDepletionMethod);
  const actionTypes = useAppSelector(state =>
    selectListOptionsFromStore(state, 'mga-action-types')
  );
  const baseCurrency = useAppSelector(selectBaseCurrency);
  const baseCurrencyLabel = useAppSelector(selectBaseCurrencyLabel);

  const structuredConfig = useMemo(
    () => ({
      ...config,
      ...(hasExtraCurrencies ? configForExtraCurrencies : configWithoutExtraCurrencies)
    }),
    [hasExtraCurrencies]
  );
  const { formState, collectValues, resetForm, loadValues, handleSubmitError, hasErrors } = useForm(
    structuredConfig,
    {
      items: []
    }
  );
  const {
    fields,
    selectField,
    changeField,
    subStates,
    addSubform,
    removeSubform,
    removeEverySubform
  } = useFormState(formState);

  const isBaseCurrency = hasExtraCurrencies
    ? baseCurrency?.id === (fields.currency_id?.value?.id || fields.currency_id?.value)
    : true;

  const isOpen = useAppSelector(selectActionFormIsOpen);
  const active = useAppSelector(selectActionFormActive);
  const latestPeriod = useAppSelector(selectLatestPeriod);
  const cell = useAppSelector(selectActionFormCell);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const [hasDateError, setHasDateError] = useState(false);

  const closeForm = useCallback(() => dispatch(setMgaActionForm({ isOpen: false })), [dispatch]);

  const toggleDeleteModal = useCallback(
    (isOpen, active) => dispatch(setMgaActionForm({ isModalOpen: isOpen, isOpen: true, active })),
    [dispatch]
  );

  const checkIfCurrencyIsBase = useCallback(
    currencyId => {
      return baseCurrency?.id === currencyId;
    },
    [baseCurrency?.id]
  );

  const actionType = fields.action_type.value;
  const isDebit = actionType?.has_debit_stores;
  const isCredit = actionType?.has_credit_stores;
  const hasInventoryItems = useMemo(
    () => showActionTypeInventoryItems(actionType),
    [actionType?.id, isDebit, isCredit]
  );

  const signatureDrawer = useDrawer(false);

  const onSubmit = async () => {
    const values = collectValues();

    if (!values || hasDateError || hasErrors) return;

    setIsSaving(true);

    try {
      const {
        credit_account,
        debit_account,
        action_type,
        account_id,
        items,
        attachments,
        bank_account,
        amount,
        ...rest
      } = values;
      const isBaseCurrencySelected = values.currency_id === baseCurrency?.id;

      const params = {
        ...rest,
        currency_id: isBaseCurrencySelected ? undefined : rest.currency_id,
        amount: hasExtraCurrencies
          ? isBaseCurrencySelected
            ? rest.local_amount
            : undefined
          : amount,
        local_amount: isBaseCurrencySelected ? undefined : rest.local_amount,
        crew_bank_account_id: bank_account?.id,
        attachments: attachments.map(a => a.id)
      };
      const isInventoryDepletionNotActive = getIsInventoryDepletionNotActive(
        isDebit,
        inventoryDepletionMethodLabel
      );

      if (hasInventoryItems) {
        params.items = items.map(({ store, id, ...rest }) => ({
          ...rest,
          id: id || undefined,
          price_per_unit: isBaseCurrencySelected ? rest.local_price_per_unit : rest.price_per_unit,
          local_price_per_unit: isBaseCurrencySelected ? undefined : rest.local_price_per_unit,
          store_id: isInventoryDepletionNotActive ? store?.store?.id || store.id : undefined,
          mga_action_item_id: isInventoryDepletionNotActive ? undefined : store.id
        }));
        params.debit_account_id = debit_account?.id;
      }

      if (active) {
        await dispatch(editMgaAction({ ...params, id: active.id }));
      } else {
        params.action_type_id = action_type?.id;
        params.period_id = latestPeriod?.id;

        params.credit_account_id = credit_account?.id;
        params.debit_account_id = debit_account?.id;

        await dispatch(createMgaAction(params));
      }

      closeForm();
    } catch (e) {
      handleSubmitError(e);
    }

    setIsSaving(false);
  };

  const selectActionType = selected => {
    selectField('action_type')(selected);
    selectField('credit_account')(null);
    selectField('debit_account')(null);
    selectField(amountKey)('');
    selectField('bank_account')(null);

    removeEverySubform('items');
    if (showActionTypeInventoryItems(selected)) addSubform('items');
  };

  const constructAccountFields = (isCrew, mgaAccountsRes) => {
    const accountFields = isCrew
      ? {
          debit_account: mgaAccountsRes?.[0]
        }
      : {
          credit_account: mgaAccountsRes?.[0]
        };

    return accountFields;
  };

  const getConversion = useCallback(
    async (local_amount, timestamp, currency_id) => {
      if (isBaseCurrency || !local_amount || !timestamp || !currency_id) return null;

      try {
        const value = await dispatch(
          getLocalAmountConversion({
            local_amount: strToNumber(local_amount),
            timestamp: timestamp.format('YYYY-MM-DD'),
            currency_id
          })
        ).unwrap();
        setConvertedAmount(value?.amount || value);
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch, isBaseCurrency]
  );

  const pricePerUnitKey = useMemo(
    () => (hasExtraCurrencies ? 'local_price_per_unit' : 'price_per_unit'),
    [hasExtraCurrencies]
  );

  const calculateDateError = useCallback(
    date => {
      const fromDate = cell?.data?.from ? cell?.data?.from : latestPeriod?.started_at;
      const toDate = cell?.data?.to ? cell?.data?.to : latestPeriod?.ended_at;

      return (
        moment(date).isBefore(moment(fromDate).format('YYYY-MM-DD 00:00')) ||
        moment(date).isAfter(moment(toDate).format('YYYY-MM-DD 23:59'))
      );
    },
    [cell?.data?.from, cell?.data?.to, latestPeriod?.ended_at, latestPeriod?.started_at]
  );

  const initForm = useCallback(async () => {
    try {
      const actionTypeId = cell ? cell.actionTypeId : active?.action_type_id;

      setIsLoading(true);
      setHasDateError(false);

      let data = null;

      setConvertedAmount(null);
      if (!active && cell?.actionTypeId && cell?.data) {
        const mgaAccountsRes = await getInitialSingleValue('/mga/accounts', {
          ids: [cell.data.account_id]
        });
        const selectedActionType = actionTypes.find(a => a.id == actionTypeId);
        const isCrew = selectedActionType?.debit_type === 'crew';

        const accountfields = constructAccountFields(isCrew, mgaAccountsRes);

        data = { ...accountfields, action_type: selectedActionType };
      } else {
        const { action_type, ...mgaActionData } = await dispatch(getMgaAction({ id: active.id }));

        const isCrew = action_type?.debit_type === 'crew';
        const key = isCrew ? 'debit_account' : 'credit_account';

        const mgaAccountsRes = await getInitialSingleValue('/mga/accounts', {
          ids: [active[`${key}_id`]]
        });

        const accountfields = constructAccountFields(isCrew, mgaAccountsRes);

        data = { ...mgaActionData, action_type, ...accountfields };
      }

      const initialValues = selectDataFromState({ ...data }, structuredConfig);

      const hasDateError = calculateDateError(initialValues?.timestamp);

      setHasDateError(hasDateError);
      const currencyId = initialValues?.currency_id
        ? initialValues.currency_id
        : hasExtraCurrencies
        ? baseCurrency.id
        : undefined;

      const isActiveCurrencyBase = checkIfCurrencyIsBase(currencyId);
      const autoFillPricePerUnitKey =
        hasExtraCurrencies && isActiveCurrencyBase ? 'price_per_unit' : pricePerUnitKey;

      loadValues({
        ...initialValues,
        currency_id: currencyId,
        items:
          initialValues.items?.map(e =>
            e?.mga_action_item?.id
              ? {
                  ...e,
                  [pricePerUnitKey]: e?.mga_action_item?.[autoFillPricePerUnitKey],
                  store: {
                    ...e?.mga_action_item?.store,
                    id: e?.mga_action_item_id,

                    quantity: e?.mga_action_item?.quantity
                  }
                }
              : { ...e, [pricePerUnitKey]: e?.[autoFillPricePerUnitKey] }
          ) || [],
        attachments: initialValues.attachments || []
      });

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      resetForm();
      setIsLoading(false);
    }
  }, [
    actionTypes,
    active,
    calculateDateError,
    cell,
    structuredConfig,
    hasExtraCurrencies,
    checkIfCurrencyIsBase,
    baseCurrency,
    dispatch,
    loadValues,
    pricePerUnitKey,
    resetForm
  ]);

  useEffect(() => {
    if (isOpen) {
      if (active || cell?.data) {
        initForm();
      } else {
        setHasDateError(false);
        resetForm();
        setConvertedAmount(null);
      }
    }
  }, [isOpen, active?.id, cell?.data?.id, cell?.data?.account_id, cell?.actionTypeId, initForm]);

  const getAverageItemsValue = useCallback(
    key => {
      return hasInventoryItems
        ? subStates(`items`)?.reduce(
            (acc, curr) =>
              strToNumber(acc) +
              strToNumber(
                hasExtraCurrencies ? curr.state?.[key]?.value : curr.state?.price_per_unit?.value
              ) *
                strToNumber(curr.state.quantity.value),
            null
          )
        : null;
    },
    [hasExtraCurrencies, hasInventoryItems, subStates]
  );

  const inventoryItemsAmountValue = getAverageItemsValue(
    hasExtraCurrencies ? 'price_per_unit_converted' : 'price_per_unit'
  );
  const inventoryItemsLocalAmountValue = getAverageItemsValue('local_price_per_unit');

  useEffect(() => {
    if (hasInventoryItems) {
      selectField('amount')(inventoryItemsAmountValue);

      if (hasExtraCurrencies) {
        selectField('local_amount')(inventoryItemsLocalAmountValue);
      }
    }
  }, [
    inventoryItemsAmountValue,
    hasExtraCurrencies,
    hasInventoryItems,
    inventoryItemsLocalAmountValue,
    selectField
  ]);

  const amountKey = useMemo(
    () => (hasExtraCurrencies ? 'local_amount' : 'amount'),
    [hasExtraCurrencies]
  );

  const debouncedGetConversion = useMemo(() => _debounce(getConversion, 400), [getConversion]);

  useEffect(() => {
    if (hasExtraCurrencies) {
      debouncedGetConversion(
        fields[amountKey]?.value,
        fields.timestamp?.value,
        fields.currency_id?.value
      );
    }
  }, [amountKey, fields, debouncedGetConversion, hasExtraCurrencies]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        close={closeForm}
        className={`mga-action-drawer ${
          signatureDrawer?.isOpen
            ? 'sign-action-visible'
            : logsDrawer?.isOpen
            ? 'logs-drawer-open'
            : ''
        }`}
      >
        <DrawerHeader className="d-flex align-items-center justify-content-between pt-1 pb-1">
          {`${active ? 'Edit' : 'Create new'} Action`}
          <div className="d-flex">
            {!isOnBoard && active ? (
              <ToggleLogsTimelineDrawerButton className="me-1" onClick={logsDrawer.open} />
            ) : null}

            {active ? (
              <DigitalSignature
                drawer={signatureDrawer}
                onCreate={async (party_id, signature) =>
                  await dispatch(
                    createMgaActionDigitalSignature({ id: active.id, party_id, signature })
                  )
                }
                onFetch={async () =>
                  await dispatch(getMgaActionDigitalSignatures({ id: active?.id }))
                }
                onDelete={async signature_id =>
                  await dispatch(deleteMgaActionDigitalSignature({ id: active?.id, signature_id }))
                }
              />
            ) : null}
          </div>
        </DrawerHeader>
        <FormDrawer>
          {isLoading ? (
            <FormBody className="ps-2">
              <Spinner />
            </FormBody>
          ) : (
            <FormBody>
              <ListSelect
                placeholder="Select type"
                onChange={selectActionType}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                list="mga-action-types"
                label="Action"
                isAsync={true}
                invisible={false}
                isClearable
                disabled={!!active || !!cell?.data}
                {...fields.action_type}
              />
              <CrewAccountField
                disabled={!!active | !!cell?.data}
                fields={fields}
                selectField={selectField}
              />
              <SubAccountField
                disabled={!!active | !!cell?.data}
                fields={fields}
                selectField={selectField}
              />

              <BankAccountField fields={fields} selectField={selectField} />

              <Row>
                <Col xs="auto">
                  <DateInput
                    label="DATE"
                    onChange={e => {
                      const hasError = calculateDateError(e);

                      setHasDateError(hasError);
                      selectField('timestamp')(e);
                    }}
                    initialVisibleMonth={
                      cell?.data?.from ? moment(cell?.data?.from) : moment(latestPeriod?.started_at)
                    }
                    isOutsideRange={date => {
                      const hasError = calculateDateError(date);

                      return hasError;
                    }}
                    {...fields.timestamp}
                    error={hasDateError ? 'Date is not correct' : fields.timestamp.error}
                  />
                </Col>
                <Col xs="auto">
                  {hasInventoryItems ? (
                    hasExtraCurrencies ? (
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column me-2">
                          <Label className="form-label">AMOUNT</Label>

                          <div className="fs-12 cmt-4 fw-medium text-primary">
                            {numberToStr(fields.local_amount?.value, 2, 2)}
                          </div>
                        </div>

                        {!isBaseCurrency ? (
                          <div className="d-flex flex-column">
                            <Label className="form-label">AMOUNT ({baseCurrencyLabel})</Label>

                            <div className="fs-12 cmt-4 fw-medium text-primary">
                              {numberToStr(fields.amount?.value, 2, 2)}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null
                  ) : (
                    <NumberInput
                      label={
                        <div className="d-flex align-items-center">
                          <div className="form-label mb-0">AMOUNT</div>
                          {hasExtraCurrencies ? null : (
                            <div className="form-label mb-0 text-violet cms-4">
                              {baseCurrencyLabel}
                            </div>
                          )}
                        </div>
                      }
                      name="amount"
                      onChange={e => {
                        if (!hasValue(e.target.value)) {
                          setConvertedAmount(null);
                        }

                        changeField(amountKey)(e);
                      }}
                      placeholder="Add value"
                      fixedDecimalScale
                      {...fields[amountKey]}
                    />
                  )}
                </Col>

                {hasExtraCurrencies ? (
                  <>
                    <Col xs={2}>
                      <ListSelect
                        onChange={e => selectField('currency_id')(e)}
                        getOptionLabel={option => option.label}
                        params={{ mga: true }}
                        getOptionValue={option => option.id}
                        className="mb-0"
                        label="Currency"
                        disabled={!!active}
                        list="currencies"
                        placeholder=""
                        {...fields.currency_id}
                      />
                    </Col>
                    {!hasInventoryItems && !isBaseCurrency ? (
                      <Col>
                        <div className="d-flex align-items-center">
                          <div className="form-label">Amount</div>
                          <div className="form-label text-violet cms-4">{baseCurrencyLabel}</div>
                        </div>

                        <div className="fs-12 cmt-2">{numberToStr(convertedAmount, 2, 2)}</div>
                      </Col>
                    ) : null}
                  </>
                ) : null}
              </Row>
              <Input
                label="DESCRIPTION"
                name="description"
                onChange={changeField('description')}
                placeholder="Add description"
                {...fields.description}
              />
              <InventoryItems
                isBaseCurrency={isBaseCurrency}
                hasInventoryItems={hasInventoryItems}
                isCredit={isCredit}
                isDebit={isDebit}
                isInEditMode={!!active}
                actionType={fields.action_type.value}
                subStates={subStates}
                addSubform={addSubform}
                removeSubform={removeSubform}
                removeEverySubform={removeEverySubform}
                hasExtraCurrencies={hasExtraCurrencies}
                dateAction={fields.timestamp?.value}
                currencyId={fields?.currency_id?.value}
              />
              <div className="mt-3 pt-3 border-top">
                <UploadFiles
                  group="mga-actions.attachments"
                  files={fields.attachments.value}
                  onChange={files => selectField('attachments')(files)}
                  uploadButtonAsBox={true}
                  size="lg"
                />
              </div>

              {!active && cell?.actionTypeId && cell?.data ? (
                <PastActions actionTypeId={cell.actionTypeId} accountId={cell.data.account_id} />
              ) : null}
            </FormBody>
          )}

          <FormFooter className="p-3 d-flex justify-content-end">
            {active ? (
              <div
                className="px-0 fw-bold py-1 text-coral flex-1 ms-3 d-flex align-items-center cursor-pointer"
                onClick={() => toggleDeleteModal(true, active)}
              >
                <SvgRender src={binIcon} style={{ width: 14, height: 16 }} className={`me-1`} />
                <div>Delete</div>
              </div>
            ) : null}

            <Button
              color="cancel"
              className="px-0 py-1 me-4"
              onClick={closeForm}
              disabled={isSaving}
            >
              CANCEL
            </Button>
            <Button onClick={onSubmit} disabled={isSaving} color="primary" className="px-4">
              SAVE
            </Button>
          </FormFooter>
        </FormDrawer>

        <DeleteActionModal />
      </Drawer>

      {!isOnBoard && active ? (
        <LogsTimelineDrawer
          drawer={logsDrawer}
          entityId={active.id}
          entityType={LOGS_ENTITY_TYPES.mga_action}
        />
      ) : null}
    </>
  );
};

export default ActionForm;
