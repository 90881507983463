import { useEffect, useCallback, isValidElement } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDashboardTablePagination,
  selectDashboardTableData,
  selectDashboardTableIsLoading,
  selectDashboardTableFetchedData,
  selectShouldRefetchDashboardTableData,
  selectEvaluationsTableData,
  selectEvaluationsFetchedData
} from 'store/dashboard/tables/selectors';
import { Row, Col, Button } from 'reactstrap';
import ContentLoader from 'react-content-loader';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import { getDashboardTableData } from 'store/dashboard/tables/actions';
import { useDispatch } from 'react-redux';

const Body = ({ label, columns, rows, name }) => {
  const data = useSelector(state => selectDashboardTableData(state, label));
  const evaluationsData = useSelector(state => selectEvaluationsTableData(state, label, name));

  const selectedData = label === 'evaluation_obligations' ? evaluationsData : data;

  return (
    <div className="dashboard-table__rows">
      {selectedData?.map((d, i) => (
        <Row key={i} className="dashboard-table__row text-primary">
          {columns.map(column => (
            <Col key={column.key} xs={column.width || ''} className={column.className}>
              {rows?.[column.key] ? rows[column.key](d) : null}
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

const Header = ({ columns }) => {
  return (
    <div className="dashboard-table__columns">
      <Row className="dashboard-table__column">
        {columns.map(column => (
          <Col
            key={column.key}
            xs={column.width || ''}
            className={column.headerClassName || column.className}
          >
            {isValidElement(column.header) ? (
              column.header
            ) : (
              <strong className="fs-10 text-violet">{column.header}</strong>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const Loader = ({ label }) => {
  const isLoading = useSelector(state => selectDashboardTableIsLoading(state, label));

  return isLoading ? (
    <div className="mb-1 w-100p">
      <ContentLoader
        speed={1}
        backgroundColor="#eef0f4"
        foregroundColor="#ebebeb"
        viewBox="0 0 400 30"
        height="100%"
        width="auto"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect x="0" y="0" rx="1px" ry="1px" width="100%" height="45%" />
        <rect x="0" y="55%" rx="1px" ry="1px" width="100%" height="45%" />
      </ContentLoader>
    </div>
  ) : null;
};

const LoadMore = ({ label, onLoadMore, name }) => {
  const isLoading = useSelector(state => selectDashboardTableIsLoading(state, label));
  const fetchedData = useSelector(state => selectDashboardTableFetchedData(state, label));
  const evaluationsFetchedData = useSelector(state =>
    selectEvaluationsFetchedData(state, label, name)
  );

  const { total, current_page, last_page, per_page } = useSelector(state =>
    selectDashboardTablePagination(state, label)
  );

  const data = label === 'evaluation_obligations' ? evaluationsFetchedData : fetchedData;

  return !isLoading && total && current_page < last_page ? (
    <Button
      disabled={isLoading}
      color="grey"
      className="fs-12 px-1 py-0 text-primary rounded-sm mt-1"
      onClick={() => onLoadMore({ paging: { current_page: current_page + 1, per_page } })}
    >
      <span className="fw-medium">
        Show <strong>{total - data < per_page ? total - data : per_page}</strong> more
      </span>
    </Button>
  ) : null;
};

const Table = ({
  label,
  columns,
  rows,
  header,
  customHeaderComponent = null,
  emptyBodyComponent = null,
  showEmpty = false,
  subheader,
  className = '',
  requestParams = null, // should be passed memoized
  paginated = true,
  name,
  showLoadMoreButton = true
}) => {
  const dispatch = useDispatch();
  const paging = useSelector(state => selectDashboardTablePagination(state, label));
  const hasData = useSelector(state => selectDashboardTableFetchedData(state, label)) !== 0;
  const shouldRefetchTableData = useSelector(state =>
    selectShouldRefetchDashboardTableData(state, label)
  );

  const fetchData = useCallback(
    async params => {
      try {
        await dispatch(
          getDashboardTableData({
            table: label,

            ...params,
            ...(requestParams ?? {})
          })
        );
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, label, requestParams]
  );

  const initialFetch = useCallback(() => {
    fetchData({
      paging: !paginated ? undefined : { current_page: 1, per_page: paging.per_page }
    });
  }, [fetchData, paginated, paging.per_page]);

  useUpdateEffect(() => {
    if (shouldRefetchTableData) initialFetch();
  }, [shouldRefetchTableData]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  return hasData || emptyBodyComponent ? (
    <div className={`dashboard-table app-table ${className}`}>
      {customHeaderComponent ? (
        customHeaderComponent
      ) : (
        <h3 className="narrow-black fs-18 text-primary mt-0">{header}</h3>
      )}
      {subheader ? <h4 className="mt-0 fs-14">{subheader}</h4> : null}

      <Header columns={columns} />
      {(!hasData || showEmpty) && emptyBodyComponent ? (
        emptyBodyComponent
      ) : (
        <Body label={label} rows={rows} columns={columns} name={name} />
      )}

      <Loader label={label} />
      {showLoadMoreButton ? <LoadMore label={label} onLoadMore={fetchData} name={name} /> : null}
    </div>
  ) : null;
};

export default Table;
