import React from 'react';

import SvgRender from 'common/components/general/SvgRender';

import moment from 'moment';
import calendar from 'common/assets/svg/common/calendar.svg';

const DateWithIcon = ({ date, className = '', svgClassName, hideIcon = false }) =>
  date ? (
    <div className={`align-items-center d-inline-flex ${className}`}>
      <span className="lh-1">{moment(date).format('DD/MM/YYYY')}</span>
      {hideIcon ? null : (
        <SvgRender
          className={svgClassName || 'ms-1'}
          src={calendar}
          style={{ width: 16, height: 17 }}
        />
      )}
    </div>
  ) : (
    '-'
  );

export default DateWithIcon;
