import { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useForm, useFormState } from 'utils/hooks';
import { upload, download } from 'utils/api';

import config from './config';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import Input from 'common/components/form/inputs/Input';
import UploadFiles from 'common/components/form/inputs/upload-files';
import add from 'common/assets/svg/actions/add.svg';

import { selectEventId, selectIsCreate } from 'events/store/events/selectors';

import {
  createOfficeAttachments,
  getOfficeAttachments,
  updateOfficeAttachments
} from 'events/store/event-modules/office-attachments/actions';
import {
  createVesselAttachments,
  getVesselAttachments,
  updateVesselAttachments
} from 'events/store/event-modules/vessel-attachments/actions';
import { editAttachmentGroup } from 'events/store/event-modules/office-attachments/slice';

const AttachmentForm = ({ attachment, onClose, type }) => {
  const { formState, collectValues, loadValues } = useForm(config, {});
  const { fields, selectField, changeField } = useFormState(formState);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isCreate = useSelector(selectIsCreate);

  const [isLoading, setIsLoading] = useState(false);

  const saveHandler = async () => {
    const values = collectValues();

    if (!values) return;

    const { attachments, ...rest } = values;

    let params = {
      event_id: eventId,
      attachments: attachments.map(a => a?.id) || [],
      attachment_group_id: attachment?.id ? attachment?.id : undefined,
      ...rest
    };

    const isOfficeAttachments = type === 'office_attachments';

    if (isCreate) {
      dispatch(editAttachmentGroup({ id: attachment.id, name: values.name, attachments }));

      setIsLoading(false);
      onClose();
      return;
    }

    const editAction = attachment?.id
      ? isOfficeAttachments
        ? updateOfficeAttachments
        : updateVesselAttachments
      : isOfficeAttachments
      ? createOfficeAttachments
      : createVesselAttachments;

    const getAction = isOfficeAttachments ? getOfficeAttachments : getVesselAttachments;

    try {
      setIsLoading(true);

      await dispatch(editAction(params)).unwrap();

      await dispatch(getAction({ event_id: eventId }));
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (attachment?.id) {
      loadValues({
        ...attachment
      });
    }
  }, [attachment, attachment?.id, loadValues]);

  return (
    <Row className="event-attachments__editing position-relative pt-2 pb-2 ps-3 mb-2" noGutters>
      <Col xs={11}>
        <Input
          onChange={changeField('name')}
          className="mb-2"
          label="Description"
          placeholder="Add description"
          {...fields.name}
        />
      </Col>
      <Col xs={11}>
        <UploadFiles
          upload={upload}
          download={download}
          className="square-upload mb-0"
          group="events.attachments"
          uploadIcon={add}
          files={fields.attachments.value}
          onChange={files => selectField('attachments')(files)}
          uploadText="Upload File(s)"
          smallView={false}
          viewOnly={false}
          showDownload={false}
          showFileSize={false}
          error={fields.attachments.error}
          size="lg"
        />
      </Col>
      <div className="event-attachments__form-edit-buttons">
        <InputSaveActions disabled={isLoading} onCancel={onClose} onSave={saveHandler} />
      </div>
    </Row>
  );
};

export default AttachmentForm;
