import React from 'react';
import { Col, Row } from 'reactstrap';
import { SortableHandle } from 'react-sortable-hoc';

import MentionInput from 'common/components/jobs/_base/inputs/MentionInput';
import CircledButton from 'common/components/buttons/CircledButton';
import SvgRender from 'common/components/general/SvgRender';

import { parseTextWithInitialMentions } from '../helpers';

import rearrange from 'common/assets/svg/actions/rearrange.svg';

const DragHandler = SortableHandle(props => <div>{props.children}</div>);

const Edit = ({
  state,
  index,
  onChange,
  onAdd,
  isEditMode,
  onRemove,
  onSubmit,
  onCancel,
  lastIndex,
  disabled,
  placeholder,
  hasActionButtons = true
}) => {
  const { title } = state;

  const onChecklistTitleChange = (e, _, __, mentions) => {
    let res = e.target.value.length > 255 ? e.target.value.substring(0, 255) : e.target.value;

    onChange(res, mentions);
  };

  return (
    <>
      <div className="sortable-checklist position-relative">
        <Row className="g-0 align-items-center">
          <Col xs="auto" className="p-0 width-16">
            <DragHandler>
              <SvgRender
                className="pointer me-1"
                src={rearrange}
                style={{ width: 6, height: 10 }}
              />
            </DragHandler>
          </Col>
          <Col className="sortable-checklist__checkbox" />
          <Col>
            <MentionInput
              placeholder={placeholder}
              className="sortable-checklist__input"
              value={parseTextWithInitialMentions(state.title, state.mentions)}
              onChange={onChecklistTitleChange}
            />
          </Col>
          <Col xs={1} className="sortable-checklist__remove">
            {index === 0 && !title ? null : (
              <CircledButton type="remove" onClick={onRemove} svgStyle={{ width: 6, height: 6 }} />
            )}
          </Col>
        </Row>
      </div>
      <div>
        {index + 1 === lastIndex && (
          <Row className="d-flex justify-content-between align-items-center mt-1 ">
            <Col xs={5} className="ps-1 add-more-checklist-options">
              <CircledButton
                type="add"
                className="text-primary fw-bold fs-12 edit-circled-btn"
                label="Add more"
                onClick={onAdd}
                style={{ width: 20, height: 20 }}
                svgStyle={{ width: 8, height: 8 }}
                disabled={disabled}
              />
            </Col>

            {hasActionButtons ? (
              <Col xs={7} className="d-flex align-items-center justify-content-end pe-5">
                <CircledButton
                  type="close"
                  className={isEditMode ? 'me-1' : ''}
                  onClick={onCancel}
                  disabled={disabled}
                />
                {isEditMode && onSubmit ? (
                  <CircledButton type="save" onClick={onSubmit} disabled={disabled} />
                ) : null}
              </Col>
            ) : null}
          </Row>
        )}
      </div>
    </>
  );
};

export default Edit;
