import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const TrainingTypeSelector = ({
  filter,
  onChange,
  isMulti,
  listParams,
  showDefaultOptions = true
}) => {
  const [selected, setSelected] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'crew-training-types', listParams },
    { selected, setSelected }
  );

  return (
    <Select
      autoFocus
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => getPartyDisplayName(option)}
      isAsync
      defaultOptions={() =>
        showDefaultOptions
          ? getInitialAsyncValues('crew-training-types', null, null, listParams)
          : []
      }
      isMulti={isMulti}
      value={filter.value ? selected.filter(el => filter.value.map(Number).includes(el.id)) : []}
      loadOptions={search => getAsyncOptions(search, 'crew-training-types', listParams)}
      onChange={selected => {
        setSelected(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
    />
  );
};

export default TrainingTypeSelector;
