import { optionField } from '@/common/utils/form/fieldTypes';
import { Currency } from '@/common/types/enums.ts';

const config = {
  extra: { currency: optionField() }
};

type ExtraType = {
  currency: Currency;
};

export type FormConfigType = {
  extra: ExtraType[];
};

export default config;
