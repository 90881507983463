import React, { useState, useEffect, useMemo } from 'react';
import _get from 'lodash/get';
import { hours, convertInternalShiftsToDateTimeShifts, isDateToday } from 'crew/utils/helpers';

import RowHour from './RowHour';
import Totals from './Totals';
import DateLabel from './DateLabel';
import Comments from './Comments';

import { useActions, usePrevious } from 'utils/hooks';
import { selectCrewDateWarnings } from 'crew/store/shifts/selectors';
import * as shiftActions from 'crew/store/shifts/actions';

import error from 'common/assets/svg/common/error.svg';
import Information from 'common/components/general/Information';
import { useSelector } from 'react-redux';

const ShiftsTableRow = ({
  shiftTypes,
  selectedShiftType,
  internalShifts,
  setInternalShifts,
  date,
  dateKey,
  isEditing,
  comment,
  crew,
  i,
  selectedRow,
  timezoneDifference,
  setSelectedRow,
  isDisabled,
  totals,
  validateShifts
}) => {
  const [firstSelectionIndex, setFirstSelectionIndex] = useState(-1); //  -1 Does not mean anything. It is there for simple null checking, because we have indices that can be 0. Think of -1 as null or undefined in this case.
  const [secondSelectionIndex, setSecondSelectionIndex] = useState(-1);
  const isHoliday = useMemo(() => _get(crew, `dates.${dateKey}.is_holiday`), [crew, dateKey]);
  const [setInternalShift] = useActions([shiftActions.setInternalShift]);

  const prevInternalShifts = usePrevious(internalShifts);

  const warnings = useSelector(state => selectCrewDateWarnings(state, crew.id, dateKey));

  useEffect(() => {
    if (prevInternalShifts !== internalShifts) {
      if (internalShifts.filter(t => t).length) {
        // If row has any shifts

        const shifts = convertInternalShiftsToDateTimeShifts(internalShifts, dateKey);
        const params = { shifts, date: dateKey, id: crew.id };

        setInternalShift(params);
      }
    }
  }, [crew.id, dateKey, internalShifts, prevInternalShifts, setInternalShift]);

  const onClickShiftHalfHour = shiftIndex => {
    if ((selectedRow || selectedRow === 0) && selectedRow !== i) return;

    if (
      firstSelectionIndex === -1 &&
      (selectedShiftType.id ? true : internalShifts[shiftIndex].id)
    ) {
      setSelectedRow(i);
      setFirstSelectionIndex(shiftIndex);
      setSecondSelectionIndex(shiftIndex);
    } else if (firstSelectionIndex !== -1) {
      // Internal logic for making shifts. This is after he has pressed on both a 'from' hour and a 'to' hour.
      // setInternalShifts is only for the internal table logic

      const newShifts = internalShifts.map((shift, i) => {
        const shiftWithoutID = { ...shift, shiftID: null };
        // When editing a row we delete all the previous ids.
        // This is only done when editing a row to preserve the ids in case nothing in the row changes.
        // Basically a backend improvement to avoid making new shift entries in the api if they are exactly the same as before.

        if (
          (firstSelectionIndex < shiftIndex && i >= firstSelectionIndex && i <= shiftIndex) ||
          (i >= shiftIndex && i <= firstSelectionIndex)
        ) {
          if (selectedShiftType.color !== shift.color) {
            return selectedShiftType;
          } else {
            return {};
          }
        }
        return shiftWithoutID;
      });

      setInternalShifts(newShifts);

      setSelectedRow(null);

      setFirstSelectionIndex(-1);
      setSecondSelectionIndex(-1);

      if (isEditing && validateShifts) {
        const shifts = convertInternalShiftsToDateTimeShifts(newShifts, dateKey);
        const params = { shifts, date: dateKey, id: crew.id };

        validateShifts(params);
      }
    }
  };

  const onHoverShiftHalfHour = shiftIndex => {
    if (firstSelectionIndex !== -1) {
      setSecondSelectionIndex(shiftIndex);
    }
  };

  useEffect(() => {
    setSelectedRow(null);
    setFirstSelectionIndex(-1);
    setSecondSelectionIndex(-1);
  }, [isEditing, setSelectedRow]);

  return (
    <>
      <div
        className={`shifts-table--row${isDateToday(date) ? ' shifts-table--row-today' : ''} ${
          ((selectedRow || selectedRow === 0) && selectedRow !== i) || isDisabled
            ? 'opacity-4 pointer-events-none'
            : ''
        } ${
          (timezoneDifference < 0 || timezoneDifference > 0) && isDateToday(date)
            ? 'shifts-table--row--with-timediff'
            : ''
        }`}
      >
        <DateLabel date={date} isHoliday={isHoliday} timezoneDifference={timezoneDifference} />
        {hours.map((_, i) =>
          i !== 24 ? (
            <RowHour
              key={i}
              shifts={internalShifts}
              shiftTypes={shiftTypes}
              selectedShiftType={selectedShiftType}
              firstSelectionIndex={firstSelectionIndex}
              secondSelectionIndex={secondSelectionIndex}
              onHoverShiftHalfHour={onHoverShiftHalfHour}
              onClickShiftHalfHour={onClickShiftHalfHour}
              index={i}
              isEditing={isEditing}
            />
          ) : null
        )}
        {warnings.length ? (
          <div className="position-absolute ms-11 cmt-2">
            <Information
              svgIcon={error}
              svgStyle={{ heihgt: 14, width: 14 }}
              message={
                <>
                  {warnings.map(warning => (
                    <div className="text-start m-1">{warning.message}</div>
                  ))}
                </>
              }
            />
          </div>
        ) : null}
        <Totals totals={totals} />
        <Comments isEditing={isEditing} date={date} crew={crew} comment={comment} />
      </div>
    </>
  );
};

export default ShiftsTableRow;
