const EmptyField = ({ label }) => (
  <div>
    <div className="form-label">{label}</div>
    <div className="fs-12 lh-1 text-primary empty-preview">
      <strong>-</strong>
    </div>
  </div>
);

export default EmptyField;
