import PropTypes from 'prop-types';
import Table, { useTable } from 'common/components/table';

import { selectEventId } from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import edit from 'common/assets/svg/actions/edit.svg';

import bin from 'common/assets/svg/common/bin.svg';
import moment from 'moment';
import Form from 'events/event/modules/module-components/underwater-services/services/form';
import { selectEventUnderwaterServicesData } from 'events/store/event-modules/underwater-services/selectors';

import NamesInTooltip from 'common/components/general/NamesInTooltip';
import {
  deleteUnderwaterService,
  getUnderwaterServices
} from 'events/store/event-modules/underwater-services/actions';

const UndewaterServicesTable = ({ drawer, setActive, active }) => {
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const servicesData = useSelector(selectEventUnderwaterServicesData);
  const eventId = useSelector(selectEventId);

  const onEditUnderwaterService = data => {
    setActive(data);
    drawer.open();
  };

  const fetchData = async params => {
    if (!eventId) return;
    try {
      await dispatch(
        getUnderwaterServices({
          event_id: eventId,
          ...params
        })
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const table = useTable({
    label: 'events_underwater_services',
    requestTableListFrom: fetchData,
    defaultRequestParams: { paging: false, filters: false, sorting: true },
    columns: [
      {
        header: 'Type',
        key: 'type_name',
        minWidth: 200,
        maxWidth: 200,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Location',
        key: 'location',
        minWidth: 200,
        maxWidth: 200,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Date',
        key: 'date',
        minWidth: 120,
        maxWidth: 120,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Company',
        key: 'company_name',
        minWidth: 310,
        maxWidth: 310,
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Divers',
        key: 'divers',
        minWidth: 200,
        headerClassName: 'bg-transparent',
        maxWidth: 200
      },
      {
        key: 'actions',
        field: 'actions'
      }
    ]
  });

  const onDeleteUnderwaterService = async data => {
    setActive(data);

    await dispatch(
      deleteUnderwaterService({ event_id: eventId, underwater_service_id: data.id })
    ).unwrap();

    table.refetchData();
  };

  return (
    <div className="mb-1">
      {servicesData?.length ? (
        <Table
          hideTopPagination
          hideTableFilters
          hideTableSearch
          loader
          getRowClassName={() => 'align-items-center'}
          rows={{
            type_name: data => data.type?.name || '-',
            company_name: data => data.company?.full_name || '-',
            divers: data => <NamesInTooltip names={data.divers.map(d => d.full_name)} />,
            date: data => (data?.date ? moment(data.date).format('DD/MM/YYYY') : '-'),
            actions: data => ({
              options: isOnBoard
                ? []
                : [
                    {
                      label: 'Edit',
                      icon: edit,
                      onClick: () => onEditUnderwaterService(data)
                    },
                    {
                      label: 'Delete',
                      icon: bin,
                      preventionModal: true,
                      modalProps: {
                        onAccept: () => onDeleteUnderwaterService(data),
                        header: 'Delete',
                        actionText: 'Delete',
                        action: 'delete',
                        actionHoverColor: 'delete',
                        body: (
                          <div className="text-center">
                            You are about to delete this underwater service. <br />
                            Are you sure you want to continue?
                          </div>
                        )
                      }
                    }
                  ]
            })
          }}
          {...table}
        />
      ) : null}
      <Form drawer={drawer} refetchData={table.refetchData} active={active} setActive={setActive} />
    </div>
  );
};

UndewaterServicesTable.propTypes = {
  drawer: DrawerState,
  setActive: PropTypes.func,
  active: PropTypes.shape({
    id: PropTypes.number
  })
};

export default UndewaterServicesTable;
