import React from 'react';

import Information from 'common/components/general/Information';

const ILORegulations = () => {
  return (
    <div className="d-flex align-items-center">
      <Information
        className="text-primary me-1"
        tooltipClassname="working-schedule__tooltip"
        placement="left-start"
        svgStyle={{ height: 14, width: 14 }}
        message={
          <>
            The minimum hours of rest are applicable in accordance with relevant Flag state Maritime
            Regulations issued in conformity with ILO’s Maritime Labour Convention, 2006, and with
            any collective agreement registered or authorized in accordance with that Convention and
            with the International Convention on Standards of Training, Certification and
            Watchkeeping for Seafarers, 1978, as amended. <br />
            <br />
            <b>Minimum hours of rest:</b> Minimum Hours or Rest shall not be less than (i) 10 hours
            in any 24-day period (ii) 77 hours in any 7-day period.
          </>
        }
      />
      <span className="fw-medium fs-12 line-height-15">ILO Regulations</span>
    </div>
  );
};

export default ILORegulations;
