import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';

import PageLoader from 'common/components/general/PageLoader';
import CurrentContractPreview from './preview';

import { selectVesselContracts } from 'crew/store/contracts/selectors';

import { getVesselContracts } from 'crew/store/contracts/actions';

import { useDispatch } from 'react-redux';

const Contracts = () => {
  const { match } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const contracts = useSelector(selectVesselContracts);

  const initData = async () => {
    try {
      setIsLoading(true);

      await dispatch(
        getVesselContracts({ id: match.params.id, active_or_ended_within_past_month: true })
      );

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <div className={`crew-profile__contracts d-flex flex-column`}>
      <div className={`text-violet fs-12`}>
        *Here you see seaman contracts with sign off date in the past 30 days
      </div>
      <div className={`crew-profile__contracts-header d-flex align-items-center w-100p`}></div>
      <div className={`crew-profile__contracts-body d-flex flex-1`}>
        <div className={`crew-profile__contracts-aside`}></div>
        <div className={`crew-profile__contracts-main flex-1 pt-3`}>
          {contracts?.map(contract => (
            <CurrentContractPreview key={contract.id} contract={contract} />
          ))}
        </div>
      </div>
      <PageLoader isLoading={isLoading} />
    </div>
  );
};

export default Contracts;
