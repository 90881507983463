import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import _flatten from 'lodash/flatten';

export const getRoutePermissions = (route, match) => {
  let permissions;

  if (
    Array.isArray(route.path) &&
    route.permissions &&
    typeof route.permissions === 'object' &&
    !Array.isArray(route.permissions)
  ) {
    /* Supports routes with the above format: 
           path: [PATH_1, PATH_2],
           permissions: { 0: ['PATH_1_PERMISSION'], 1: ['PATH_2_PERMISSION'], } - where 0 & 1 stands for the paths' index */

    permissions = route.permissions?.[route.path.findIndex(p => p === match.path)];
  } else {
    permissions = route.permissions;
  }

  return permissions || null;
};

export const getMenuItemPermissions = item => {
  if (item.submenu) {
    return _flatten(
      item.submenu.filter(({ permissions }) => permissions).map(({ permissions }) => permissions)
    );
  }

  return item.permissions || [];
};

export const getMenuItemOrganizationSettings = item => {
  if (item.submenu) {
    return _flatten(
      item.submenu
        .filter(({ requiredOrganizationSettings }) => requiredOrganizationSettings)
        .map(({ requiredOrganizationSettings }) => requiredOrganizationSettings)
    );
  }

  return item.requiredOrganizationSettings || [];
};

export const flattenPermissions = permissions => {
  let result = {};

  for (const key in permissions) {
    if (typeof permissions[key] === 'object') {
      const nestedPermissions = flattenPermissions(permissions[key]);
      result = { ...result, ...nestedPermissions };
    } else {
      result[permissions[key]] = null;
    }
  }

  return result;
};

const getAccountPermission = ({ account, permission }, isOnboard) => {
  const { permissions } = account;

  if (!permissions) return false;

  const hasAccountPermission = permissions?.hasOwnProperty(permission);
  const isOwnPermission = permission?.includes('_own');

  if (isOnboard) {
    const isCurrentUserProfile =
      (account?.type === 'person' &&
        window.location.pathname.startsWith(`${paths.persons}/${account.id}`)) ||
      (account?.type === 'crew' &&
        window.location.pathname.startsWith(`${paths.crew}/${account.id}`));

    const isUserProfile = ['/crew/', '/persons/'].some(p => window.location.pathname.startsWith(p));

    if (isUserProfile && !isCurrentUserProfile && isOwnPermission) return false;
  } else {
    const isCurrentUserProfile = window.location.pathname.startsWith(
      `${paths.persons}/${account.id}`
    );

    const isUserProfile = ['/persons/'].some(p => window.location.pathname.startsWith(p));

    if (isUserProfile && !isCurrentUserProfile && isOwnPermission) return false;
  }

  return hasAccountPermission;
};

// Do not use this function directly. Use the `isAuthorized` function instead.
export const authorizeAccount = (
  { account, permissions = [], mode = 'or', environments = [] },
  isOnboard = false
) => {
  const validEnv =
    (environments?.length && environments.includes(import.meta.env.VITE_RELEASE_STAGE)) ||
    !environments?.length;

  if (environments?.length) return validEnv;
  if (!permissions?.length) return validEnv;
  if (!account) return false;

  const boolArray = permissions
    ?.filter(
      permission =>
        (!isOnboard && permission?.startsWith('office.')) ||
        (isOnboard && permission?.startsWith('vessel.'))
    )
    .map(permission => getAccountPermission({ account, permission }, isOnboard));

  if (mode === 'or') {
    // Some of them must be enabled
    return !boolArray.every(el => el === false) && validEnv;
  } else if (mode === 'and') {
    // All of them must be enabled
    return boolArray.every(el => el === true) && validEnv;
  }
};

export const getPermissionSettings = (
  { account, permission, mode = 'or', environments = [] },
  isOnboard = false
) => {
  if (authorizeAccount({ account, permissions: [permission], mode, environments }, isOnboard)) {
    return account.permissions?.[permission];
  }

  return null;
};

export const getPartyPermissions = type => {
  switch (type) {
    case 'crew':
      return [
        permissions.office.crew.seaman_profile.view,
        permissions.onboard.crew.seaman_profile.view
      ];
    case 'person':
      return [
        permissions.office.contacts.persons.view,
        permissions.onboard.crew.seaman_profile.view
      ];
    case 'company':
      return [permissions.office.contacts.companies.view];
    default:
      return null;
  }
};
