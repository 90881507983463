import React from 'react';
import UploadFiles from 'common/components/form/inputs/upload-files';
import SvgRender from 'common/components/general/SvgRender';

import forms from 'common/assets/svg/common/forms.svg';

export const EmptyDisplay = ({ isLocked }) => (
  <div className="d-flex align-items-center w-100p">
    <SvgRender src={forms} style={{ width: 72, height: 80 }} className="text-gray-100" />
    <strong className="ms-2 fs-12 fw-medium text-gray-common">
      There is no uploaded form yet. {!isLocked ? 'Click Edit to upload one.' : ''}
    </strong>
  </div>
);

const Display = ({ file, isLocked }) => {
  return (
    <div className="upload-form-display">
      {!file ? (
        <EmptyDisplay isLocked={isLocked} />
      ) : (
        <UploadFiles
          group="forms.submissions"
          files={file ? [file] : []}
          viewOnly={true}
          size="lg"
        />
      )}
    </div>
  );
};

export default Display;
