import { useCallback, useEffect, useMemo, useState } from 'react';

import { getZoom, zoomAllGroupedGraphs } from 'common/components/graph/base/helpers/_zoom';
import { getDateFormatForGraphPeriodFromXAxis } from 'common/components/graph/base/helpers/_functions';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import _isEqual from 'lodash/isEqual';
import * as d3 from 'd3';
import { usePrevious } from 'utils/hooks';
import useD3Active from './useD3Active';

const useD3Zoom = ({ events, schema, graphUID }) => {
  const element = document.getElementById(`svg-${graphUID}`);
  const isZoomDisabled = _get(schema, 'isZoomDisabled');

  const zoom = useMemo(() => {
    if (!element) return null;

    const { width, height } = element.getBoundingClientRect();
    return getZoom(width, height);
  }, [element]);

  const [lastZoomEvent, setLastZoomEvent] = useState(null);

  const { isActive } = useD3Active(events, [element]);

  const pluckUsefulGraphAxesInfo = () => {
    const graphAxesInfo = _get(events, `groupGraphsAxesInfo[${graphUID}]`);
    if (!graphAxesInfo) return null;
    return {
      width: graphAxesInfo.width,
      disabledPlots: graphAxesInfo.disabledPlots
    };
  };

  const unbloatedGraphAxesInfo = pluckUsefulGraphAxesInfo();

  const zoomFn = useCallback(
    event => {
      if (!events.groupGraphsAxesInfo[graphUID]) return;

      const dateTimeFormat =
        schema?.customDateFormat ??
        getDateFormatForGraphPeriodFromXAxis(
          events.groupGraphsAxesInfo[graphUID].x,
          events.groupGraphsAxesInfo[graphUID].width
        );

      zoomAllGroupedGraphs(event, events, graphUID, dateTimeFormat, !lastZoomEvent);
      setLastZoomEvent(event);
    },
    [events, graphUID, lastZoomEvent, schema?.customDateFormat]
  );

  const throttledZoomFn = _throttle(zoomFn, 8);

  const removeGraphZoomListener = useCallback(() => {
    if (!element) return;

    const elementSelection = d3.select(element);
    elementSelection.call(zoom.on('zoom', null));
  }, [element, zoom]);

  const updateGraphZoomListener = useCallback(() => {
    if (!isZoomDisabled) {
      if (!element) return null;

      const elementSelection = d3.select(element);
      elementSelection.call(zoom.on('zoom', throttledZoomFn));

      if (!lastZoomEvent) {
        elementSelection.call(zoom.scaleBy, 1);
      }
    }
  }, [element, isZoomDisabled, lastZoomEvent, throttledZoomFn, zoom]);

  const prevGroupGraphsAxesInfo = usePrevious(unbloatedGraphAxesInfo);

  useEffect(() => {
    if (unbloatedGraphAxesInfo && !_isEqual(prevGroupGraphsAxesInfo, unbloatedGraphAxesInfo)) {
      // We need to do that because when disabling a legend the graph rerenders and comes back to its original position
      if (!isZoomDisabled) {
        if (!element) return null;

        const initialZoomLevel = 1;
        const initialZoomTransform = d3.zoomIdentity.scale(initialZoomLevel);

        zoomFn({ target: zoom, type: 'zoom', transform: initialZoomTransform });
      }
    }
  }, [
    element,
    isZoomDisabled,
    prevGroupGraphsAxesInfo,
    throttledZoomFn,
    unbloatedGraphAxesInfo,
    updateGraphZoomListener,
    zoom,
    zoomFn
  ]);

  useEffect(() => {
    removeGraphZoomListener();

    const updateEventListeners = setTimeout(() => {
      if (isActive) {
        updateGraphZoomListener();
      }
    }, 16);

    return () => {
      clearTimeout(updateEventListeners);
    };
  }, [isActive, removeGraphZoomListener, updateGraphZoomListener]);

  return { isLoading: isZoomDisabled ? false : !lastZoomEvent };
};

export default useD3Zoom;
