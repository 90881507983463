import { FC } from 'react';
import { css } from '@emotion/react';

import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
};

const TopFilters: FC<TopFiltersComponentProps> = ({ topFilters, setTopFilters }) => {
  const [status, setStatus] = useTableTopFilterValue({ topFilters, setTopFilters }, 'status');

  const tabs = [
    {
      label: 'Pending',
      onClick: () => setStatus('pending'),
      size: 'sm',
      isActive: status === 'pending',
      className: 'button-pending'
    },
    {
      label: 'Ignored',
      onClick: () => setStatus('ignored'),
      size: 'sm',
      isActive: status === 'ignored'
    },
    {
      label: 'Completed',
      onClick: () => setStatus('completed'),
      size: 'sm',
      isActive: status === 'completed',
      className: 'button-completed'
    }
  ];

  return <NavigationGroupTabs css={style} tabs={tabs} />;
};

export default TopFilters;

const style = css`
  .button-pending {
    &.active {
      color: ${variables.warning};
      border-color: ${variables.warning};
    }
  }

  .button-completed {
    &.active {
      color: ${variables.turquoise};
      border-color: ${variables.turquoise};
    }
  }
`;
