import TYPES from './types';
import moment from 'moment';

const INITIAL_STATE = {
  evaluations: [],
  criteria_averages: {},
  selectedDates: {
    from: moment().subtract(12, 'months'),
    to: moment()
  },
  isExpanded: false,
  fetching: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_EVALUATION_COMPARISON.START:
      return { ...state, fetching: true };
    case TYPES.GET_CREW_EVALUATION_COMPARISON.SUCCESS:
      return {
        ...state,
        evaluations: payload?.evaluations || [],
        criteria_averages: payload?.criteria_averages || {},
        fetching: false
      };
    case TYPES.GET_CREW_EVALUATION_COMPARISON.ERROR:
      return {
        ...state,
        fetching: false
      };
    case TYPES.CREW_EVALUATION_COMPARISON_SET_DATES:
      const { from, to } = payload;

      return {
        ...state,
        selectedDates: { from, to }
      };
    case TYPES.SET_CREW_EVALUATION_EXPAND:
      return {
        ...state,
        isExpanded: payload
      };
    default:
      return state;
  }
};

export default reducer;
