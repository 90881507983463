import SvgRender from 'common/components/general/SvgRender';

import chat from 'common/assets/svg/common/chat-empty.svg';

const EmpyState = () => {
  return (
    <SvgRender
      className="global-unread-chat-messages__empty"
      src={chat}
      style={{ width: '90%', height: 'auto' }}
    />
  );
};

export default EmpyState;
