import { Button } from 'reactstrap';

const AtSeaSwitchButtons = ({ periodType, shiftsPortSeaState, onSwitch }) => {
  return (
    periodType === 'daily' && (
      <div className="shifts-filters__state d-flex border-start ps-3 ms-3">
        <span className="fs-12 text-primary cmt-2">Choose state:</span>
        <Button
          type="button"
          onClick={() => onSwitch('sea')}
          color={shiftsPortSeaState === 'sea' ? 'primary' : ''}
          className={`cpy-4 cpx-12 fs-12 align-items-center justify-content-center lh-1 
      ms-1 fw-medium ${
        shiftsPortSeaState === 'sea' ? 'text-white active' : 'text-inactive inactive'
      }`}
        >
          Sea
        </Button>
        <Button
          type="button"
          onClick={() => onSwitch('port')}
          color={shiftsPortSeaState === 'port' ? 'violet' : ''}
          className={`cpy-4 px-1 fs-12 align-items-center justify-content-center lh-1 
      cms-4 fw-medium ${
        shiftsPortSeaState === 'port' ? 'text-white active' : 'text-inactive inactive'
      }`}
        >
          Port
        </Button>
      </div>
    )
  );
};

export default AtSeaSwitchButtons;
