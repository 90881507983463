import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import SvgRender from 'common/components/general/SvgRender';
import _maxBy from 'lodash/maxBy';
import { displayDate } from 'common/utils/dates';

const FieldsCategoryRow = ({ category, isOpen, data, onClick }) => {
  const getLastEditedTimestamp = useCallback(
    data =>
      _maxBy(data, ({ state }) => state.last_survery_timestamp?.value)?.state
        ?.last_survery_timestamp?.value,
    []
  );

  return category ? (
    <Row
      className="border border-primary fs-10 fw-bold text-primary bg-lighter-blue border-radius-5 cps-4 pe-4 pointer"
      noGutters
      onClick={onClick}
    >
      <Col xs={10} className="text-truncate">
        {category}
      </Col>
      <Col xs={2} className="d-flex justify-content-between fw-medium fs-10">
        <div className="text-violet text-truncate">
          Edited at: {displayDate(getLastEditedTimestamp(data), { time: true }) || '-'}
        </div>
        <div className="d-flex align-items-center flex-nowrap me-n3">
          {isOpen ? 'Shrink' : 'Expand'}
          <SvgRender
            className="cms-4"
            src={arrow}
            style={{
              transform: isOpen ? 'rotate(0deg)' : 'rotate(-180deg)',
              width: 10,
              height: 10
            }}
          />
        </div>
      </Col>
    </Row>
  ) : null;
};

FieldsCategoryRow.propTypes = {
  category: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func
};

export default FieldsCategoryRow;
