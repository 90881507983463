import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { dateToLocal, dateToUtc } from 'common/utils/dates';
import { getDateObject } from '@/ts-common/utils/dates';
import { strToNumber } from '@/ts-common/utils/numbers';
import { format } from 'date-fns-tz';

const types = {
  number: {
    initialValue: null,
    transformOnLoad: value => {
      if (value === null) return '';
      return parseFloat(value).toLocaleString('en', { maximumFractionDigits: 2 });
    },
    transformOnSubmit: value => {
      if (isNaN(value)) {
        if (!value || value.length === 0) return null;
        return strToNumber(value);
      } else {
        return value;
      }
    },
    validation: Yup.number()
      .typeError('This must be a numeric value')
      // Dont throw errors when value is ''
      .transform((cv, ov) => (ov === '' ? undefined : cv))
  },
  date: {
    initialValue: null,
    transformOnLoad: (m: string | null) => (m ? moment(m) : null),
    transformOnSubmit: (m: Moment | null) => (m ? m.format('YYYY-MM-DD') : null)
  },
  dateTime: {
    initialValue: null,
    transformOnLoad: (m: string | null) => (m ? dateToLocal(m) : null),
    transformOnSubmit: (m: Moment | null) =>
      m ? dateToUtc(m.format('YYYY-MM-DD HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss') : null
  },
  utcDateTimeField: {
    initialValue: null,
    transformOnLoad: (m: string | null) => (m ? moment(m) : null),
    transformOnSubmit: (m: Moment | null) => (m ? m.format('YYYY-MM-DD HH:mm:ss') : null)
  },

  singleDate: {
    initialValue: null,
    transformOnLoad: (d: string | null) => getDateObject(d),
    transformOnSubmit: (d: Date | null) => (d ? format(d, 'yyyy-MM-dd') : null)
  },
  singleDateTime: {
    initialValue: null,
    transformOnLoad: (d: string | null) => getDateObject(d),
    transformOnSubmit: (d: Date | null) => (d ? format(d, 'yyyy-MM-dd HH:mm') : null)
  },

  string: {
    initialValue: null
  },
  option: {
    initialValue: null
  },
  boolean: {
    initialValue: true,
    validation: Yup.boolean()
  },
  hidden: {
    initialValue: null
  }
};

const createFieldType = (type, config) => {
  const { email, required, positive, decimalScale, ...overrides } = config;

  config = { ...types[type], ...overrides };

  if (required) {
    config = {
      ...config,
      isRequired: true
    };
    config = addToValidation(config, v => v.required());
  }

  if (email) {
    config = {
      ...config,
      validation: Yup.string()
    };
    config = addToValidation(config, v => v.email());
  }

  if (positive && type === 'number') {
    config = addToValidation(config, v => v.positive());
  }

  if ((decimalScale || decimalScale === 0) && !overrides.transformOnLoad) {
    config = {
      ...config,
      transformOnLoad: value => {
        if (value === null) return '';

        return parseFloat(value).toLocaleString('en', { maximumFractionDigits: decimalScale });
      }
    };
  }

  return config;
};

const addToValidation = (config, cb) => {
  const validation = config.validation || Yup.mixed();
  return { ...config, validation: cb(validation) };
};

export const dateField = (config = {}) => createFieldType('date', config); // Soon-to-be depracated, use along with @/common/components/form/inputs/date
export const dateTimeField = (config = {}) => createFieldType('dateTime', config); // Soon-to-be depracated, use along with @/common/components/form/inputs/date
export const utcDateTimeField = (config = {}) => createFieldType('utcDateTimeField', config); // Soon-to-be depracated, use along with @/common/components/form/inputs/date

export const singleDateField = (config = {}) => createFieldType('singleDate', config);
export const singleDateTimeField = (config = {}) => createFieldType('singleDateTime', config);

export const numberField = (config = {}) => createFieldType('number', config);
export const stringField = (config = {}) => createFieldType('string', config);
export const optionField = (config = {}) => createFieldType('option', config);
export const booleanField = (config = {}) => createFieldType('boolean', config);
export const hiddenField = (config = {}) => createFieldType('hidden', config);

export const arrayOf = (config = {}) => ({
  initialValue: [],
  transformOnLoad: config.transformOnLoad ? values => values.map(config.transformOnLoad) : null,
  transformOnSubmit: config.transformOnSubmit
    ? values => values.map(config.transformOnSubmit)
    : null,
  validation: config.validation ? Yup.array().of(config.validation) : Yup.array()
});
