import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import Filesystem from 'views/filesystem';

const routes = [
  {
    path: `${paths.filesystem}`,
    component: Filesystem,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.files.view],
    documentTitle: 'Files'
  },
  {
    path: `${paths.filesystem}/:id`,
    component: Filesystem,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.files.view],
    documentTitle: 'Files'
  },
  {
    path: `${paths.filesystem}/folder/:id`,
    component: Filesystem,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.files.view],
    documentTitle: 'Files'
  }
];

export default routes;
