import React from 'react';
import SvgRender from 'common/components/general/SvgRender';

import { Col, Button } from 'reactstrap';

import _isEqual from 'lodash/isEqual';

import dots from 'common/assets/svg/actions/three-dots.svg';

const PageActions = React.memo(
  ({ actions, className }) => {
    const ActionContent = ({ action }) => {
      switch (action.type) {
        case 'actions':
          return (
            <Button
              color={'link'}
              className={`page-layout--table-action type-${action.type} d-inline-flex align-items-center justify-content-center`}
              disabled={action.disabled}
            >
              <SvgRender src={dots} style={{ height: 18, width: 18 }} />
            </Button>
          );
        default:
          return action.customElement ? (
            action.customElement()
          ) : (
            <Button
              color={action.color ? action.color : 'link'}
              className={`page-layout--table-action type-${action.type} d-inline-flex align-items-center justify-content-center`}
              onClick={action.onClick}
              disabled={action.disabled}
            >
              {action.text || ''}
            </Button>
          );
      }
    };

    return actions && actions.length ? (
      <Col className={`d-flex align-items-center justify-content-end ${className || ''}`}>
        {actions.map((action, i) => (
          <ActionContent key={action.type || i} action={action} />
        ))}
      </Col>
    ) : null;
  },
  (prevProps, nextProps) => !_isEqual(prevProps, nextProps)
);

export default PageActions;
