import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import { FormGroup, Label } from 'reactstrap';

import { hasRequiredValidation } from '@/ts-common/utils/form/validation';
import SvgRender from '@/ts-common/components/general/SvgRender';
import ErrorMessage from '@/ts-common/components/form/helpers/ErrorMessage';

export type FormGroupWrapProps = PropsWithChildren<{
  error?: string | null;
  className?: string;
  iconLabel?: string;
  label?: ReactNode | (() => ReactNode);
  dataCy?: string;
}>;

const FormGroupWrap: FC<FormGroupWrapProps> = ({
  error,
  className = '',
  iconLabel,
  label,
  children,
  dataCy
}) => {
  const isRequired = useMemo(() => hasRequiredValidation(error), [error]);

  return (
    <FormGroup
      noMargin
      className={`input-wrapper form-group ${error ? ' has-error' : ''}${
        isRequired ? ' has-required-error' : ''
      } ${className}`}
      data-cy={dataCy}
    >
      {iconLabel || label ? (
        <div className="d-flex align-items-center position-relative">
          {iconLabel && (
            <SvgRender
              src={iconLabel}
              style={{ height: 14, width: 14, top: -1, left: 0 }}
              className="position-absolute text-primary"
            />
          )}
          {label && (
            <Label className={`form-label w-100p ${iconLabel ? 'cps-20' : ''}`}>
              {typeof label === 'function' ? label() : label}
            </Label>
          )}
        </div>
      ) : null}

      {children}
      {error && <ErrorMessage withLabel={!!label}>{error}</ErrorMessage>}
    </FormGroup>
  );
};

export default FormGroupWrap;
