import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api';

export const getInventorySurveysDaysLeft = createAsyncThunk(
  'GET_INVENTORY_SURVEYS_DAYS_LEFT',
  async (params, { rejectWithValue, getState }) => {
    try {
      const res = await get('/inventory/surveys/next', params);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
