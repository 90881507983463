import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import { selectPreviousCaptainReport } from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import * as reportActions from 'captain-reports/store/actions';

const BallastWaterTotalField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue] = useActions([reportActions.setValue]);
  const previousReportData = useSelector(selectPreviousCaptainReport);
  const prevBallastWaterTotal = previousReportData?.ballast_water_amount_total?.value;

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    if (
      (prevBallastWaterTotal || prevBallastWaterTotal === 0) &&
      prevBallastWaterTotal !== value &&
      value === null
    ) {
      setValue({
        key: field.key,
        value: prevBallastWaterTotal,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [prevBallastWaterTotal, value]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default BallastWaterTotalField;
