import moment from 'moment';

export const getClassNameForExpirationColumn = (data, warnings) => {
  let className = '';

  const isExpired = moment(data.expires_at).isBefore(moment().format('YYYY-MM-DD'));
  if (isExpired) className = 'text-red';

  const hasReminder = warnings.reminder.filter(reminder => reminder.id === data.id).length;
  if (hasReminder) className = 'text-warning';

  return className ? ` ${className}` : '';
};
