import { useEffect } from 'react';
import { useFormState } from 'utils/hooks';
import { Row, Col } from 'reactstrap';
import NumberInput from 'common/components/form/inputs/NumberInput';
import CircledButton from 'common/components/buttons/CircledButton';
import { roundNumber } from 'common/utils/numbers';
import PropTypes from 'prop-types';
import Tooltip from 'common/components/general/Tooltip';
import paths from 'routing/routes/_paths';
import { numberToStr } from 'common/utils/numbers';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Checkbox from 'common/components/form/inputs/Checkbox';
import SvgRender from 'common/components/general/SvgRender';
import checkedIcon from 'common/assets/svg/common/check-circle.svg';

const Value = ({
  formState,
  addValueSubForm,
  removeValueSubForm,
  isLastIndex,
  index,
  dynamicFieldSummary,
  view,
  invoices,
  showPaidText
}) => {
  const { fields, selectField, changeField } = useFormState(formState);

  const { avoidRender, tooltipID } = useTooltipID('invoice-button-crew-medical-case');

  useEffect(() => {
    selectField('sort_index')(index);
  }, [index, selectField]);

  if (avoidRender) return null;

  const invoicesTotal = invoices?.length
    ? invoices.reduce((acc, item) => {
        return acc + parseFloat(item.price_base_currency_equivalent);
      }, 0)
    : null;

  return (
    <Row
      className={`d-flex align-items-center justify-content-between ${
        index !== 0 ? 'mt-1' : ''
      } w-100p`}
    >
      <Col xs={4}>
        <NumberInput
          placeholder="Add value"
          className="pb-0 mb-0"
          innerClassName={'text-end'}
          allowNegative={false}
          onChange={changeField('value')}
          fixedDecimalScale={2}
          invisible={view}
          disabled={view}
          {...fields.value}
        />
      </Col>

      {view ? (
        <Col xs={4} className="d-flex">
          {dynamicFieldSummary && isLastIndex ? (
            <div className="d-flex align-items-center text-violet fs-12">/&nbsp;Total:</div>
          ) : null}
          {dynamicFieldSummary && isLastIndex ? (
            <TextWithTooltip className="text-primary fw-medium">
              &nbsp;{roundNumber(dynamicFieldSummary)}
            </TextWithTooltip>
          ) : null}
        </Col>
      ) : null}

      <Col xs={view ? 4 : 8} className="d-flex justify-content-end pe-0">
        <div className="w-100p d-flex align-items-center">
          {fields.is_paid?.value && view ? (
            <SvgRender
              className={`text-turquoise`}
              src={checkedIcon}
              style={{ height: 14, width: 14 }}
            />
          ) : (
            <div className="width-14"></div>
          )}
          {!view && dynamicFieldSummary && isLastIndex ? (
            <div className="d-flex align-items-center text-violet fs-12">/ Total:</div>
          ) : null}
          <div className="flex-1 d-flex align-items-center justify-content-end">
            {!view && dynamicFieldSummary && isLastIndex ? (
              <div className="text-primary pe-3 fw-medium">{roundNumber(dynamicFieldSummary)}</div>
            ) : null}
            {!view ? (
              <div
                className={`${showPaidText ? 'paid-checkbox' : ''} ms-1 ${
                  !isLastIndex ? 'me-6' : ''
                }`}
              >
                <Checkbox
                  className="mb-0"
                  color="turquoise"
                  value={fields.is_paid?.value}
                  outline={'turquoise'}
                  onChange={() => selectField('is_paid')(!fields.is_paid?.value)}
                />
              </div>
            ) : null}

            {view && isLastIndex ? (
              <div
                className={`d-flex align-items-center expense-value ${
                  invoices?.length ? '' : 'hidden'
                }`}
              >
                <div className="d-flex me-2">
                  <>
                    <CircledButton
                      type={'expense'}
                      id={tooltipID}
                      svgStyle={{ width: 14, height: 14 }}
                      onClick={() => window.open(`${paths.accounting_invoices}/${invoices[0]?.id}`)}
                    />
                    <Tooltip
                      placement="top"
                      target={tooltipID}
                      innerClassName="min-width-fit max-width-fit"
                    >
                      <div className="text-start">Opens linked Invoices in a new tab</div>
                    </Tooltip>
                  </>
                </div>

                <div className="text-turquoise width-48">
                  <TextWithTooltip>
                    {invoicesTotal ? numberToStr(invoicesTotal, 2, 2) : null}
                  </TextWithTooltip>
                </div>
              </div>
            ) : null}

            {isLastIndex && !view ? (
              <>
                {index !== 0 ? (
                  <CircledButton
                    type="remove"
                    svgStyle={{ width: 8, height: 8 }}
                    style={{ width: 16, height: 16 }}
                    className={`text-primary fw-bold fs-12 small-icon pb-0 mb-0 ms-1`}
                    onClick={removeValueSubForm}
                  />
                ) : null}

                <CircledButton
                  type="add"
                  svgStyle={{ width: 8, height: 8 }}
                  style={{ width: 16, height: 16 }}
                  className={`text-primary fw-bold fs-12 small-icon pb-0 mb-0 ms-1 ${
                    index === 0 && !view ? 'ms-4' : ''
                  }`}
                  onClick={() => addValueSubForm('values')}
                />
              </>
            ) : null}
          </div>
        </div>
      </Col>
    </Row>
  );
};

Value.propTypes = {
  formState: PropTypes.object,
  addValueSubForm: PropTypes.func,
  removeValueSubForm: PropTypes.func,
  isLastIndex: PropTypes.bool,
  dynamicFieldSummary: PropTypes.number || PropTypes.string,
  view: PropTypes.bool,
  showPaidText: PropTypes.bool
};

export default Value;
