import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_CREW_EVALUATION'),
  ...asyncTypes('UPDATE_CREW_EVALUATION'),
  ...asyncTypes('GET_CREW_EVALUATION'),
  ...asyncTypes('DELETE_CREW_EVALUATION'),
  ...asyncTypes('GET_CREW_EVALUATIONS'),
  ...asyncTypes('GET_RESULTS_PERCENTAGES'),
  ...asyncTypes('GET_ONBOARD_PERIOD_INFO'),
  ...asyncTypes('ACKNOWLEDGE_EVALUATION'),
  ...asyncTypes('CONSENT_EVALUATION'),
  ...asyncTypes('GET_CREW_EVALUATION_STATISTICS'),
  ...asyncTypes('UPDATE_CREW_EVALUATION_AVERAGE_RATING'),
  SET_IS_EDITING: 'SET_IS_EDITING',
  SET_TAB_VIEW: 'SET_TAB_VIEW',
  CLEAR_ACTIVE_CREW_EVALUATION: 'CLEAR_ACTIVE_CREW_EVALUATION'
};

export default TYPES;
