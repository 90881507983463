import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Body from 'events/event/modules/module-components/components/attachments/Body';
import Loader from 'events/event/modules/module-components/components/attachments/Loader';
import ModuleWrapper from '../components/ModuleWrapper';

import { selectEventId } from 'events/store/events/selectors';

import {
  selectOfficeAttachmentsData,
  selectOfficeAttachmentsLoading
} from 'events/store/event-modules/office-attachments/selectors';
import { getOfficeAttachments } from 'events/store/event-modules/office-attachments/actions';

const OfficeAttachments = ({ isCreate }) => {
  const eventId = useSelector(selectEventId);
  const isLoading = useSelector(selectOfficeAttachmentsLoading);
  const attachments = useSelector(selectOfficeAttachmentsData);

  const type = 'office_attachments';

  const dispatch = useDispatch();

  useEffect(() => {
    if (eventId) {
      dispatch(getOfficeAttachments({ event_id: eventId }));
    }
  }, [eventId, dispatch]);

  return (
    <ModuleWrapper className="event-attachments" type={type}>
      {isLoading ? <Loader /> : <Body isCreate={isCreate} attachments={attachments} type={type} />}
    </ModuleWrapper>
  );
};

export default OfficeAttachments;
