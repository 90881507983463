import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { getInitialAsyncValues, getAsyncOptions } from 'utils/helpers';

import _debounce from 'lodash/debounce';

import arrow from 'common/assets/svg/common/arrow.svg';
import SvgRender from 'common/components/general/SvgRender';
import Input from 'common/components/form/inputs/Input';
import Clear from './Clear';
import Item from './Item';
import RenderValue from './RenderValue';
import { convertAtSeaName } from './helpers';
import { hasRequiredValidation } from 'common/utils/form/validation';

let initialPorts = [];

const PortsAtSea = ({
  coordinates,
  placeholder,
  className,
  value,
  selectField,
  direction,
  isClearable = true,
  onPortSelect,
  autoFocus = true,
  onlyPort,
  groupCoords,
  isSubmitted = null,
  saveBtnTitle = 'APPLY',
  showCoordsInValue = false,
  invisible,
  disabled,
  error
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSeaPortOpen, setIsSeaPortOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const [keepCoordinates, setKeepCoordinates] = useState(true);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [ports, setPorts] = useState([]);
  const [atSea, setAtSea] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [inputFocused, setInputFocused] = useState(autoFocus);

  const onFocus = () => setInputFocused(true);
  const onBlur = () => setInputFocused(false);

  const dropdownRef = React.useRef();

  const listPorts = 'ports';
  const listAtSea = 'ports&at_sea=true';

  const isRequired = useMemo(() => hasRequiredValidation(error), [error]);

  useEffect(() => {
    getDefaultData();
  }, []);

  useEffect(() => {
    if (search) {
      _debounce(() => searchPorts(search), 1000)();
    }
  }, [search]);

  useEffect(() => {
    if (selected && selected.id) {
      if (onlyPort) {
        selectField('port')(selected);
      } else {
        selectField('port_id')(selected.id);
        selectField('port')(selected);
      }
      setSearch('');
    }
  }, [selected && selected.id]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    if (!search) {
      setPorts(initialPorts);
    }

    if (!isSeaPortOpen && !keepCoordinates) {
      if (groupCoords) {
        selectField('coords')({ lat: null, lon: null });
      } else {
        selectField('lat')(null);
        selectField('lon')(null);
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSettingsOpen, isSeaPortOpen, value && value.name]);

  useEffect(() => {
    if (coordinates && (coordinates.lat.value || coordinates.lon.value)) {
      if (selected && !selected.name.includes('At Sea')) {
      } else {
        isSeaPortOpenHandler(true, true);
      }
    }
  }, [coordinates, isSettingsOpen]);

  useEffect(() => {
    if (!selected || (selected && value?.id && selected.id !== value.id)) {
      setSelected(value);
    }
  }, [value?.id]);

  useEffect(() => {
    if (isSubmitted) {
      isSubmitted(isSaved);
    }
  }, [isSaved]);

  const getDefaultData = async () => {
    const resPorts = await getInitialAsyncValues(listPorts);
    const resAtSea = await getInitialAsyncValues(listAtSea);

    if (!initialPorts.length) {
      initialPorts = [...resPorts];
    }

    setPorts(resPorts);
    setAtSea(resAtSea);
  };

  const searchPorts = async search => {
    let res = null;

    if (
      (value && convertAtSeaName(value.name)) === convertAtSeaName(search) ||
      (selected && convertAtSeaName(selected.name)) === convertAtSeaName(search)
    ) {
      res = initialPorts;
    } else {
      res = await getAsyncOptions(search, listPorts);
    }

    setIsLoading(false);

    setPorts(res && res.length ? res : [{ name: 'No Options' }]);
  };

  const changeSearchInputValue = (inputValue, e) => {
    setIsLoading(inputValue ? true : false);
    toggleDropdownMenu(true, true);

    setSearch(convertAtSeaName(inputValue));
  };

  const saveHandler = atSeaValue => {
    setIsSaved(true);

    setKeepCoordinates(true);
    changeSearchInputValue(atSeaValue.name);
    setSelected(atSeaValue);

    if (onlyPort) {
      selectField('port')(atSeaValue);
    } else {
      selectField('port_id')(atSeaValue.id);
      selectField('port')(atSeaValue);
    }

    onPortSelect && onPortSelect(atSeaValue);

    isSeaPortOpenHandler(false, true);
    toggleDropdownMenu(false, true);

    setIsLoading(false);
    setSearch('');
  };

  const cancelHandler = () => {
    isSeaPortOpenHandler(false, true);
    setKeepCoordinates(false);
    setSelected(null);

    if (onlyPort) {
      selectField('port')(null);
    } else {
      selectField('port_id')(null);
      selectField('port')(null);
    }

    if (groupCoords) {
      selectField('coords')({ lat: null, lon: null });
    } else {
      selectField('lat')(null);
      selectField('lon')(null);
    }

    onPortSelect && onPortSelect(null);
  };

  const handleClickOutside = useCallback(
    e => {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
        return;
      }

      if (coordinates && !coordinates.lat.value && !coordinates.lon.value) {
        setKeepCoordinates(false);
      } else {
        if (keepCoordinates) {
          if (groupCoords) {
            selectField('coords')({ lat: coordinates.lat.value, lon: coordinates.lon.value });
          } else {
            selectField('lat')(coordinates.lat.value);
            selectField('lon')(coordinates.lon.value);
          }
        }
      }

      toggleDropdownMenu(false, true);
      isSeaPortOpenHandler(false, true);
      setSearch('');
    },
    [
      selected && selected.name,
      value && value.name,
      coordinates && coordinates.lat.value,
      coordinates && coordinates.lon.value
    ]
  );

  const toggleDropdownMenu = (value, hasDefault = false) => {
    setIsSettingsOpen(hasDefault ? value : !isSettingsOpen);
  };

  const isSeaPortOpenHandler = (value, hasDefault = false) => {
    setIsSeaPortOpen(hasDefault ? value : !isSeaPortOpen);
  };

  return (
    <div
      className={`${ports?.length ? '' : 'pointer-events-none'} ${
        isRequired ? 'has-required-error' : ''
      }`}
      ref={dropdownRef}
    >
      <Dropdown
        className={`ports-dropdown ${className ? className : ''}`}
        isOpen={isSettingsOpen}
        toggle={() => null}
      >
        <DropdownToggle
          color="link"
          className={`d-inline-flex ports-dropdown__toggle align-items-center justify-content-start ${
            inputFocused ? 'port-btn__focused' : ''
          } ${invisible ? 'invisible-port' : ''} ${error ? 'border-red' : ''}`}
          disabled={disabled}
        >
          <div
            className="ports-dropdown__container d-inline-flex align-items-center justify-content-start"
            onClick={() => toggleDropdownMenu()}
          >
            {!search ? (
              <div className="ports-dropdown__container-value text-truncate">
                <RenderValue
                  name={selected?.name || null}
                  isSaved={isSaved}
                  showCoordsInValue={showCoordsInValue}
                  data={coordinates}
                  setIsSaved={setIsSaved}
                />
              </div>
            ) : null}

            <Input
              autoFocus={autoFocus}
              onFocus={onFocus}
              onBlur={onBlur}
              invisible={true}
              type="text"
              className="mb-0 fw-medium ports-dropdown__container-input"
              value={search ? search : null}
              onChange={event => changeSearchInputValue(event.target.value, event)}
              placeholder={selected ? '' : placeholder || 'Select Item'}
              dataCy="select_option"
            />

            {isLoading ? <div className="indicator-loading"></div> : null}

            {disabled ? null : (
              <div className="ports-dropdown__container-indicators">
                {isClearable && selected && !isLoading ? (
                  <Clear
                    onClick={() => {
                      cancelHandler();
                      toggleDropdownMenu(false, false);
                    }}
                  />
                ) : null}

                <SvgRender
                  src={arrow}
                  style={{
                    width: 11,
                    height: 6,
                    transform: isSettingsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                  onClick={() => toggleDropdownMenu()}
                  className="text-primary ports-dropdown__container-indicators-arrow"
                />
              </div>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu end={true} className="mb-6">
          <div className="menuPorts">
            {ports &&
              ports?.length &&
              ports.map((item, index) =>
                item.name === 'No Options' ? (
                  <span
                    key={index}
                    className="d-flex no-options align-items-center fw-light text-secondary"
                  >
                    {item.name}
                  </span>
                ) : (
                  <Item
                    onPortSelect={onPortSelect}
                    item={item}
                    key={index}
                    value={value}
                    saveBtnTitle={saveBtnTitle}
                    keepCoordinates={keepCoordinates}
                    setKeepCoordinates={setKeepCoordinates}
                    toggleDropdownMenu={toggleDropdownMenu}
                    isSeaPortOpenHandler={isSeaPortOpenHandler}
                    setSelected={setSelected}
                    selectField={selectField}
                  />
                )
              )}
          </div>

          {atSea && atSea?.length ? (
            <div
              className={`menuAtSea border-top menuAtSea--${
                isSeaPortOpen ? 'containerOpen' : 'containerClose'
              }`}
            >
              {atSea.map((item, index) => (
                <Item
                  onPortSelect={onPortSelect}
                  item={item}
                  key={index}
                  value={value}
                  isAtSea={true}
                  keepCoordinates={keepCoordinates}
                  isSeaPortOpen={isSeaPortOpen}
                  setKeepCoordinates={setKeepCoordinates}
                  isSeaPortOpenHandler={isSeaPortOpenHandler}
                  saveHandler={saveHandler}
                  coordinates={coordinates}
                  selectField={selectField}
                  setSelected={setSelected}
                  cancelHandler={cancelHandler}
                  saveBtnTitle={saveBtnTitle}
                />
              ))}
            </div>
          ) : null}
        </DropdownMenu>

        {error ? <div className="form-error fs-10 text-red">{error}</div> : null}
      </Dropdown>
    </div>
  );
};

export default PortsAtSea;
