import React, { ReactNode, MouseEventHandler } from 'react';
import { Button, ButtonProps } from 'reactstrap';

type ToggleButtonProps = {
  id?: string;
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & Pick<ButtonProps, 'size' | 'disabled'>;

const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  className = '',
  onClick,
  size,
  ...rest
}) => {
  return (
    <Button
      type="button"
      className={`toggle-button ${className}`}
      onClick={onClick}
      size={size}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ToggleButton;
