import React, { useCallback, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import useRouter from 'use-react-router';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import moment from 'moment';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import rankIcon from 'common/assets/svg/crew/rank.svg';
import { getCrewContracts } from 'crew/store/contracts/actions';
import calendar from 'common/assets/svg/common/calendar-blue.svg';
import { displayDate } from 'common/utils/dates';
import Rank from 'crew/profile/main/evaluation/headers/components/Rank';

const PeriodCrewInfo = ({ fields, isOnboard }) => {
  const { match } = useRouter();
  const dispatch = useDispatch();
  const [latestContract, setLatestContract] = useState();
  const vesselId = _get(fields, 'vessel_id.value');
  const id = _get(match, 'params.id', null);

  const fetchContracts = useCallback(async () => {
    if (!id || !vesselId) return;

    try {
      const res = await dispatch(
        getCrewContracts({
          id,
          date: moment()?.format('YYYY-MM-DD'),
          vessel_id: vesselId,
          latest: true,
          has_promotion: true
        })
      );

      setLatestContract(res?.[0] || {});
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, id, vesselId]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  return (
    <Row noGutters>
      {!isOnboard ? (
        <Col xs="auto" className="d-flex align-items-start text-violet min-width-200 max-width-200">
          <SvgRender src={vesselIcon} className="me-1" style={{ width: 14, height: 16 }} />
          <span className="fs-12">VESSEL: </span>
          <TextWithTooltip className="h-auto fs-12 fw-bold cms-4">
            {fields.vessel_name.value || '-'}
          </TextWithTooltip>
        </Col>
      ) : null}
      <Col xs="auto" className="d-flex text-violet align-items-start me-5 max-width-280">
        <SvgRender src={rankIcon} className="me-1" style={{ width: 14, height: 16 }} />
        <span className="fs-12 cme-4">RANK: </span>
        <Rank latestContract={latestContract} />
      </Col>

      <Col xs="auto" className="d-flex align-items-center ms-2 ">
        <SvgRender src={calendar} style={{ width: 15, height: 15 }} className="me-1" />
        <div className="d-flex align-items-center fs-12 text-violet">
          <span>SIGN ON DATE:</span> &nbsp;
          <span className="fw-bold ">{displayDate(latestContract?.sign_on_date) || '-'}</span>
          <div className="small-separator bg-violet cpy-6 cme-12 cms-12" />
        </div>
      </Col>

      <Col xs="auto" className="d-flex align-items-center">
        <SvgRender src={calendar} style={{ width: 15, height: 15 }} className="me-1" />
        <div className="d-flex align-items-center fs-12 text-violet">
          <span>SIGN OFF DATE:</span> &nbsp;
          <span className="fw-bold ">{displayDate(latestContract?.sign_off_date) || '-'}</span>
        </div>
      </Col>
    </Row>
  );
};

export default PeriodCrewInfo;
