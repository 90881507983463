import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { useActions, useRedirect } from 'utils/hooks';
import * as vesselActions from 'store/vessels/actions';
import tabs from './_tabs';
import VesselProfile from 'common/components/vessels/profile';
import { selectVesselInfo } from 'store/vessels/selectors';

const Profile = () => {
  const { match } = useRouter();
  const [getVesselInfo] = useActions([vesselActions.getVesselInfo]);
  const profile = useSelector(selectVesselInfo);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useRedirect(shouldRedirect);

  const getProfile = async () => {
    try {
      await getVesselInfo();
    } catch (e) {
      if (e.status === 404) {
        setShouldRedirect(true);
      }
    }
  };

  useEffect(() => {
    if (parseInt(match.params.id, 10) !== profile.id) {
      getProfile();
    }
  }, [match.params.id]);

  return <VesselProfile profile={profile} tabs={tabs} />;
};

export default Profile;
