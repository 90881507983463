import React, { Suspense } from 'react';

import templates from 'common/assets/svg/common/templates.svg';
import checklist from 'common/assets/svg/jobs/checklist.svg';
import link from 'common/assets/svg/jobs/link.svg';
import forms from 'common/assets/svg/common/forms.svg';
import comment from 'common/assets/svg/common/comments.svg';

import { Row, Col } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  selectIsJobLocked,
  selectOnboardUserIsAuthorized,
  selectOfficeUserIsAuthorized
} from 'common/components/jobs/_base/store/selectors';
import {
  selectIsPms,
  selectIsPlannedJob,
  selectIsUnscheduledJob
} from 'common/components/pms/jobs/store/selectors';

import { selectJobHasForms } from 'common/components/jobs/_base/modules/forms/store/selectors';
import {
  isUserAllowedToEdit,
  jobPermissions
} from 'common/components/jobs/_base/permissions/helpers';
import { selectIfFieldIsVisible, selectRestrictedFields } from 'store/jobs-fields/selectors';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import AuthCheck from 'components/permissions/AuthCheck';

import {
  selectJobHasLinkedJobs,
  selectJobHasChecklist,
  selectJobHasNotes
} from 'common/components/jobs/_base/store/selectors';
import { jobEnums } from 'common/utils/fixed';
import TabsModule from 'common/components/general/TabsModule';

const FlagDispensation = React.lazy(() =>
  import('common/components/jobs/flag-extensions-dispensations')
);
const Template = React.lazy(() => import('common/components/jobs/_base/modules/template'));
const JobLinking = React.lazy(() => import('common/components/jobs/_base/modules/linked-jobs'));
const JobsChecklist = React.lazy(() => import('common/components/jobs/_base/modules/checklist'));
const Forms = React.lazy(() => import('common/components/jobs/_base/modules/forms'));
const JobNotes = React.lazy(() => import('common/components/jobs/_base/modules/notes'));

const renderSpecificTypeSections = type => {
  switch (type) {
    case jobEnums.flag_dispensation:
      return <FlagDispensation />;
    default:
      return null;
  }
};

const Tabs = ({ className = '', hide = [], avoidCheck = false, hideMultipleTemplates = false }) => {
  const [setActiveJobTab] = useActions([jobProfileActions.setActiveJobTab]);
  const activeTabs = useSelector(state => selectJobField(state, 'activeTabs'));
  const jobId = useSelector(state => selectJobField(state, 'id'));

  const isPms = useSelector(selectIsPms);
  const isUnscheduled = useSelector(selectIsUnscheduledJob);
  const isPlannedJob = useSelector(selectIsPlannedJob);
  const isOnboard = useSelector(state => state.isOnBoard);

  const typeOfLoggedInUser = useSelector(state => selectJobField(state, 'typeOfLoggedInUser'));

  const isTemplateVisible = useSelector(selectIfFieldIsVisible('template_id'));
  const isChecklistVisible = useSelector(selectIfFieldIsVisible('checklist_options'));

  const canEdit = useSelector(state =>
    isOnboard ? selectOnboardUserIsAuthorized(state) : selectOfficeUserIsAuthorized(state)
  );

  const isJobLocked = useSelector(selectIsJobLocked) || !canEdit;

  const restrictedChecklist = useSelector(state =>
    selectRestrictedFields(state)?.find(item => item.field === 'checklist_options')
  );
  const restrictedTemplate = useSelector(state =>
    selectRestrictedFields(state)?.find(item => item.field === 'template_id')
  );
  const type = useSelector(state => selectJobField(state, 'type'));

  const jobTemplateError = useSelector(state => selectJobFieldError(state, 'template'));
  const jobChecklistError = useSelector(state => selectJobFieldError(state, 'checklist'));
  const jobHasForms = useSelector(selectJobHasForms);
  const jobHasLinkedJobs = useSelector(selectJobHasLinkedJobs);
  const jobHasChecklists = useSelector(selectJobHasChecklist);
  const jobHasNotes = useSelector(selectJobHasNotes);

  const canEditTemplate =
    useSelector(selectOfficeUserIsAuthorized) &&
    isUserAllowedToEdit({
      restrictedFields: restrictedTemplate,
      typeOfLoggedInUser,
      avoidCheck
    });
  const canEditChecklist =
    useSelector(selectOfficeUserIsAuthorized) &&
    isUserAllowedToEdit({
      restrictedFields: restrictedChecklist,
      typeOfLoggedInUser,
      avoidCheck
    });

  const tabs = [
    {
      label: 'template',
      icon: templates,
      name: 'Template',
      disabled: !canEditTemplate || isJobLocked,
      visible: isTemplateVisible && !isPms && !isOnboard,
      error: jobTemplateError
    },
    {
      label: 'linked',
      icon: link,
      name: 'Link Jobs',
      disabled: isJobLocked || jobHasLinkedJobs,
      visible:
        (isPms ? (isJobLocked && !jobHasLinkedJobs ? false : true) : !isJobLocked) && !isOnboard
    },
    {
      label: 'checklist',
      icon: checklist,
      name: 'Checklist',
      disabled:
        (isPms ? false : jobHasChecklists) || !canEditChecklist || isJobLocked || isPlannedJob,
      visible: isPms && !jobId ? false : isChecklistVisible,
      error: jobChecklistError
    },
    {
      label: 'forms',
      icon: forms,
      name: 'Forms',
      disabled: isJobLocked || jobHasForms,
      visible: isPms ? (isUnscheduled ? true : jobHasForms) : true
    },
    {
      label: 'notes',
      icon: comment,
      name: 'Notes',
      disabled: isJobLocked || jobHasNotes,
      visible: isPms ? false : true
    }
  ];

  return (
    <Suspense fallback={null}>
      <AuthCheck
        avoidCheck={avoidCheck}
        permissions={jobId ? jobPermissions.office.edit : jobPermissions.office.create}
      >
        <Row className={`g-0 align-items-center mb-2 ${className ? className : ''}`}>
          <Col className="pe-1">
            <TabsModule
              tabs={tabs.map(tab => ({
                ...tab,
                visible: tab.visible && hide.indexOf(tab.label) === -1,
                active: activeTabs.indexOf(tab.label) !== -1
              }))}
              onTabClick={label => setActiveJobTab(label)}
            />
          </Col>
        </Row>
      </AuthCheck>

      {renderSpecificTypeSections(type)}

      {!isOnboard && (
        <Template
          hideMultipleTemplates={hideMultipleTemplates}
          className={`${!canEditTemplate ? 'template-disabled' : null}`}
          key={jobId}
        />
      )}

      {isPms ? null : <JobLinking />}
      {isPms ? null : <JobsChecklist avoidCheck={avoidCheck} />}
      {isPms ? null : <Forms canAddForms={!isJobLocked} canRemoveForms={!isJobLocked} />}
      {isPms || isOnboard ? null : <JobNotes />}
    </Suspense>
  );
};

export default Tabs;
