import { useEffect } from 'react';
import moment from 'moment';

import Cookie from 'utils/cookie';
import { useActions } from 'utils/hooks';

import * as authActions from 'store/auth/actions';

const useLogout = authenticated => {
  const [logout] = useActions([authActions.logout]);

  const intervalValue = 300; // 5 min
  const timeoutValue = 600; // 10 min

  useEffect(() => {
    if (authenticated) {
      // If the browser or last opened tab was closed (in *intervalValue* after closing), then we logout.
      // We check every *timeoutValue* because close tab, close browser, refresh actions are considered
      // by the browsers as the same action. With the timeout workaround we skip refresh action.
      if (wasBrowserOrTabClosedAfterSignin()) {
        logout();
        return;
      }

      // Every *intervalValue* update cookie with new timestamp.
      setActiveTimestamp(new Date());
      const aliveSessionInterval = setInterval(() => {
        setActiveTimestamp(new Date());
      }, intervalValue * 1000);

      return () => {
        clearTimeout(aliveSessionInterval);
      };
    }
  }, [authenticated]);

  const setActiveTimestamp = timeStamp => {
    Cookie.set('activeSessionTimestamp', `${timeStamp.getTime()}`, 0);
  };

  const wasBrowserOrTabClosedAfterSignin = () => {
    const value = Cookie.get('activeSessionTimestamp');

    const lastTrackedTimeStampWhenAppWasAlive = value ? new Date(Number(value)) : null;
    const currentTimestamp = new Date();
    const differenceInSec = moment(currentTimestamp).diff(
      moment(lastTrackedTimeStampWhenAppWasAlive),
      'seconds'
    );

    // if difference between current timestamp and last tracked timestamp when app was alive
    // is more than *timeoutValue* (if user close browser or all opened tabs more than *timeoutValue* ago)
    return !!lastTrackedTimeStampWhenAppWasAlive && differenceInSec > timeoutValue;
  };
};

export default useLogout;
