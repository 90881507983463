import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import {
  selectBsBioFuelsByFuelGrade,
  selectConsumptionFuelGrade
} from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import Select from 'captain-reports/templates/inputs/Select';
import { strToNumber } from 'common/utils/numbers';

const BiofuelCF = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const { label, id } = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );

  const previousBioFuels = useSelector(state => selectBsBioFuelsByFuelGrade(state, id));

  const options = useMemo(() => {
    if (!previousBioFuels?.values?.length) return [];

    return previousBioFuels.values.map(prevBioFuelValue => ({
      label: prevBioFuelValue,
      value: strToNumber(prevBioFuelValue)
    }));
  }, [previousBioFuels]);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  if (label !== 'biodiesel') return null;

  if (!options?.length)
    return (
      <NumberField
        field={field}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
        fixedDecimalScale={4}
      />
    );

  return (
    <Select
      field={{ ...field, options }}
      sectionLabel={sectionLabel}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      sectionActiveTab={sectionActiveTab}
      value={value}
    />
  );
};

export default BiofuelCF;
