import { createSelector } from 'reselect';

const selectAccountReducer = state => state.account;

export const selectAccount = createSelector(selectAccountReducer, account => account);

export const selectAccountId = createSelector([selectAccount], account => account.id);

export const selectAccountDepartments = createSelector(
  [selectAccount],
  account => account.departments
);

export const selectAccountFullname = createSelector(
  [selectAccount],
  account => account.full_name || account.name
);

export const selectAccountEmail = createSelector([selectAccount], account => account.email);

export const selectOrganizationType = createSelector([selectAccount], account =>
  account.organization && account.organization.type ? account.organization.type : null
);

export const selectAccountRank = createSelector([selectAccount], account => account?.crew_rank);
