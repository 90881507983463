import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('UPDATE_JOB'),
  ...asyncTypes('CREATE_JOB'),
  ...asyncTypes('GET_JOB'),
  ...asyncTypes('ARCHIVE_JOB'),
  ...asyncTypes('UNARCHIVE_JOB'),
  ...asyncTypes('CLONE_JOB'),
  ...asyncTypes('GET_JOB_MESSAGES'),
  ...asyncTypes('POST_JOB_MESSAGE'),
  ...asyncTypes('GET_JOB_UNREAD_MESSAGES'),
  ...asyncTypes('JOB_MARK_AS_READ'),
  SET_JOB_PROFILE: 'SET_JOB_PROFILE',
  RESET_JOB_PROFILE: 'RESET_JOB_PROFILE',
  SET_JOB_FIELD_VALUE: 'SET_JOB_FIELD_VALUE',
  SET_JOB_ERROR_FIELD: 'SET_JOB_ERROR_FIELD',
  CLEAR_JOB_PROFILE: 'CLEAR_JOB_PROFILE',
  SET_JOB_CUSTOM_TEMPLATE: 'SET_JOB_CUSTOM_TEMPLATE',
  SET_ACTIVE_JOB_TAB: 'SET_ACTIVE_JOB_TAB',
  SET_SELECTED_JOB: 'SET_SELECTED_JOB',
  SET_DEFAULT_JOB_STATUS: 'SET_DEFAULT_JOB_STATUS',
  SET_TYPE_OF_LOGGED_IN_USER: 'SET_TYPE_OF_LOGGED_IN_USER',
  SET_UNSAVED_CHANGES_ALERT: 'SET_UNSAVED_CHANGES_ALERT',
  SET_A4_LAYOUT: 'SET_A4_LAYOUT',
  SET_PREFILLED_VESSEL: 'SET_PREFILLED_VESSEL',
  SET_TABLE_SHOULD_REFETCH_DATA: 'SET_TABLE_SHOULD_REFETCH_DATA'
};

export default TYPES;
