import { useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';

import TotalsBar from 'common/components/graph/TotalsBar';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const ContentForms = () => {
  const totals = useSelector(state => selectDashboardTotals(state, 'sms_forms'));

  return totals ? (
    <div className="dashboard__totals-forms">
      <TotalsBar
        legends={[
          {
            label: 'To finalize',
            total: (totals?.draft || 0) + (totals?.not_filled_in || 0),
            color: variables.primary
          },
          {
            label: 'Obligations',
            total: totals?.obligations_pending || 0,
            color: variables.warning
          },
          { label: 'Corrections', total: totals?.for_correction || 0, color: variables.red }
        ]}
      />
    </div>
  ) : null;
};

export default ContentForms;
