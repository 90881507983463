import { useState, useEffect, useCallback } from 'react';

import PickerNavigation from '../components/PickerNavigation';
import PickerSelectionGrid from '../components/PickerSelectionGrid';

import moment from 'moment';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { usePrevious } from 'utils/hooks';

const SingleMonthYearPicker = ({
  value,
  onChange,
  useStringValue,
  isOutsideRange,
  isDayHighlighted,
  isDaySelected
}) => {
  const [yearMonths, setYearMonths] = useState([]);
  const [selectedYearIndex, setSelectedYearIndex] = useState(0);
  const previousSelectedYearIndex = usePrevious(selectedYearIndex);

  const getOptions = useCallback(currentDate => {
    const year = currentDate.year();

    const months = Array(12)
      .fill(null)
      .map((_, i) => {
        const date = moment().month(i).year(year);

        const selectItem = {
          label: date.format('MMM'),
          value: date.format('YYYY-MM'),
          year,
          month: date.format('MM')
        };

        return selectItem;
      });

    return months;
  }, []);

  useEffect(() => {
    const currentDate = value || moment();

    setYearMonths(getOptions(currentDate));
  }, []);

  useUpdateEffect(() => {
    let currentDate = null;

    if (selectedYearIndex <= previousSelectedYearIndex) {
      currentDate = moment().year(yearMonths[yearMonths.length - 1].year - 1);
    } else {
      currentDate = moment().year(yearMonths[yearMonths.length - 1].year + 1);
    }

    setYearMonths(getOptions(currentDate));
  }, [selectedYearIndex]);

  const handleChange = selected => {
    const date = `${selected}-01`;

    if (useStringValue) {
      onChange(date || null);
    } else {
      onChange(date ? moment(date) : null);
    }
  };

  return (
    <div className="d-flex flex-column">
      <PickerNavigation goToIndex={setSelectedYearIndex} currentIndex={selectedYearIndex}>
        {yearMonths?.length ? yearMonths[0]?.year : '-'}
      </PickerNavigation>
      <PickerSelectionGrid
        options={yearMonths}
        selectedOption={value && value.format('YYYY-MM')}
        onChange={handleChange}
        isOptionDisabled={
          isOutsideRange ? ({ month, year }) => isOutsideRange(moment(`${year}-${month}-01`)) : null
        }
        isOptionHighlighted={
          isDayHighlighted
            ? ({ month, year }) => isDayHighlighted(moment(`${year}-${month}-01`))
            : null
        }
        isOptionSelected={
          isDaySelected ? ({ month, year }) => isDaySelected(moment(`${year}-${month}-01`)) : null
        }
      />
    </div>
  );
};

export default SingleMonthYearPicker;
