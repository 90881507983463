import React, { useEffect, useState } from 'react';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { FormDrawer } from 'common/components/drawer';
import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import { Button } from 'reactstrap';
import { useFormState } from 'utils/hooks';
import config from './config';
import { useForm } from 'utils/hooks';
import { linkPurchaseCase } from 'events/store/event-modules/linked-purchase-cases/actions';
import { selectEventId } from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import PurchasingRequisitionSelector from 'common/components/selectors/PurchasingRequisitionSelector';
import PropTypes from 'prop-types';

const Form = ({ drawer, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { formState, resetForm, collectValues } = useForm(config);
  const { fields, selectField } = useFormState(formState);
  const eventId = useSelector(selectEventId);

  const dispatch = useDispatch();

  const onLink = async () => {
    const values = collectValues();
    if (!values) return null;
    const { requisition } = values;

    try {
      setIsSubmitting(true);

      await dispatch(
        linkPurchaseCase({
          id: eventId,
          purchasing_requisition_id: requisition?.id || null
        })
      ).unwrap();

      refetchData();

      setIsSubmitting(false);

      drawer.close();
    } catch (e) {
      console.error(e);
      resetForm();
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!drawer.isOpen) {
      resetForm();
    }
  }, [drawer.isOpen, resetForm]);

  return (
    <Drawer {...drawer}>
      <DrawerHeader>{'Link to existing Case'}</DrawerHeader>

      <FormDrawer>
        <FormBody className="d-flex flex-column justify-content-between">
          <PurchasingRequisitionSelector
            label="Requisition"
            onChange={selectField('requisition')}
            placeholder="Select Requisition"
            {...fields.requisition}
          />
        </FormBody>

        <FormFooter>
          <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
            CANCEL
          </Button>
          <Button onClick={onLink} disabled={isSubmitting} color="primary" className="px-4">
            LINK
          </Button>
        </FormFooter>
      </FormDrawer>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState,
  refetchData: PropTypes.func
};

export default Form;
