import { useSelector } from 'react-redux';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';

import SavedJobForms from 'common/components/jobs/_base/modules/forms/components/SavedJobForms';

const SavedJobFormsContainer = ({ canRemoveForms }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));

  return <SavedJobForms canRemoveForms={canRemoveForms} jobId={jobId} />;
};

export default SavedJobFormsContainer;
