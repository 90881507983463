import TextField from 'captain-reports/templates/inputs/TextField';

const config = () => [
  {
    fields: [
      {
        label: 'Spares',
        key: 'spares',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Spares Text',
        key: 'spares_t',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Stores',
        key: 'stores',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Stores Text',
        key: 'stores_t',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Crew Off',
        key: 'crew_off',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Crew Off Text',
        key: 'crew_off_t',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Crew On',
        key: 'crew_on',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Crew On Text',
        key: 'crew_on_t',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Repair Cases',
        key: 'repair',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Repair Cases Text',
        key: 'repair_t',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Inspections',
        key: 'inspections',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Inspections Text',
        key: 'inspections_t',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Nautical Carts and Publications',
        key: 'naut_charts',
        width: { xs: 4 },
        render: TextField
      }
    ]
  }
];

const receivablesConfig = {
  noon: () => [],
  arrival: () => [],
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => [], //data => config(data),
  port_noon: () => [],
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default receivablesConfig;
