import TYPES from './types';
import { get } from 'utils/api';

export const getDashboardTotals = params => dispatch => {
  dispatch({ type: TYPES.GET_DASHBOARD_TOTALS.START });

  return get(`/dashboard/totals`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_DASHBOARD_TOTALS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_DASHBOARD_TOTALS.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const getDashboardTotalFindings = params => dispatch => {
  dispatch({ type: TYPES.GET_DASHBOARD_TOTAL_FINDINGS.START });

  return get(`/findings/totals`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_DASHBOARD_TOTAL_FINDINGS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_DASHBOARD_TOTAL_FINDINGS.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};
