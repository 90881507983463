import { useState, useCallback } from 'react';

const useDrawer = <T>(initialState = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const [payload, setPayload] = useState<T | null>(null);

  const open = useCallback((payload?: T) => {
    setIsOpen(true);
    setPayload(payload || null);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setPayload(null);
  }, []);

  const toggle = useCallback((payload?: T) => {
    setIsOpen(isOpen => !isOpen);
    setPayload(payload || null);
  }, []);

  return {
    isOpen,
    payload,
    setPayload,
    open,
    close,
    toggle
  };
};

export default useDrawer;
