import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_PORT_DOCUMENT_OVERVIEW: 'SET_PORT_DOCUMENT_OVERVIEW',
  SET_EDITTING_PORT_DOCUMENT_OVERVIEW: 'SET_EDITTING_PORT_DOCUMENT_OVERVIEW',
  SET_PORT_DOCUMENT_OVERVIEW_REBUILD_COMMENTS: 'SET_PORT_DOCUMENT_OVERVIEW_REBUILD_COMMENTS',
  SET_SELECTED_FORM: 'SET_SELECTED_FORM',
  SET_FORM_DRAWER: 'SET_FORM_DRAWER',
  SET_FORM_DRAWER_IS_DISABLED: 'SET_FORM_DRAWER_IS_DISABLED',
  SET_CURRENT_ACTION: 'SET_CURRENT_ACTION',
  ...asyncTypes('GET_PORT_DOCUMENT_OVERVIEW'),
  ...asyncTypes('UPDATE_PORT_DOCUMENT_OVERVIEW'),
  ...asyncTypes('APPROVE_PORT_DOCUMENT_OVERVIEW'),
  ...asyncTypes('TECH_REVIEW_PORT_DOCUMENT_OVERVIEW'),
  ...asyncTypes('REBUILD_PORT_DOCUMENT_OVERVIEW'),
  ...asyncTypes('REVOKE_APPROVAL_PORT_DOCUMENT_OVERVIEW'),
  ...asyncTypes('UPDATE_PORT_DOCUMENT_OVERVIEW_ACTION'),
  ...asyncTypes('UPDATE_PORT_DOCUMENT_OVERVIEW_INNER_ACTION')
};

export default TYPES;
