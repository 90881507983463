import EmptyState from './EmptyState';

import { useSelector } from 'react-redux';
import { hasChapters, isCreatingChapter } from 'manuals/store/selectors';
import { useActions } from 'utils/hooks';
import * as manualsActions from 'manuals/store/actions';

import Main from './main';

const ChaptersTree = ({ chapterActions, chaptersLoading }) => {
  const notEmpty = useSelector(hasChapters);
  const showCreationForm = useSelector(isCreatingChapter);

  const [toggleChapterCreationForm] = useActions([manualsActions.toggleChapterCreationForm]);

  const handleChapterCreation = () => {
    toggleChapterCreationForm(true);
  };

  return (
    <div className="chapters-tree">
      {notEmpty || showCreationForm ? (
        <Main chapterActions={chapterActions} handleChapterCreation={handleChapterCreation} />
      ) : !notEmpty && !chaptersLoading ? (
        <EmptyState handleChapterCreation={handleChapterCreation} />
      ) : null}
    </div>
  );
};
export default ChaptersTree;
