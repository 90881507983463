import { Row, Col } from 'reactstrap';

import Textarea from 'common/components/form/inputs/Textarea';
import Information from 'common/components/general/Information';
import SvgRender from 'common/components/general/SvgRender';
import { selectAccount } from 'store/account/selectors';

import Checkbox from 'common/components/form/inputs/Checkbox';
import { isAuthorized } from 'utils/permissions/authorize';

import comments from 'common/assets/svg/common/comments.svg';
import edit from 'common/assets/svg/actions/edit-empty.svg';

import { useSelector } from 'react-redux';
import permissions from '@/common/utils/permissions/constants';

const Input = ({ disabled, value, onChange, icon, label, info }) => (
  <Textarea
    disabled={disabled}
    value={value}
    onChange={e => onChange(e.target.value)}
    className="mb-0"
    placeholder="Add some remarks..."
    label={
      <>
        <SvgRender className="me-1" src={icon} style={{ width: 16, height: 16 }} />
        <div className="fs-12">{label}</div>
        {info ? (
          <div className="ms-auto text-primary">
            <Information svgStyle={{ height: 14, width: 14 }} message={info} />
          </div>
        ) : null}
      </>
    }
    invisible
  />
);

const FormRemarks = ({
  remarks,
  setRemarks,
  onRemarksChange,
  actionable,
  onActionableChange,
  isFillingNewForm,
  disableFormRemarks
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const account = useSelector(selectAccount);
  const isLocked = isOnboard
    ? true
    : !isAuthorized(account, [permissions.office.forms.edit_values]);

  const { core, onboard } = remarks;

  return (
    <div className="forms-remarks edit">
      <Row>
        {!isOnboard ? (
          <Col xs={5} className="position-relative">
            <Input
              disabled={disableFormRemarks || isLocked}
              value={core}
              onChange={value =>
                isFillingNewForm
                  ? setRemarks({ onboard, core: value })
                  : onRemarksChange({ onboard, core: value })
              }
              label="Review Remarks for Office"
              icon={comments}
              info={
                <span>
                  This field will not be visible in <strong>Orca Onboard</strong>.
                </span>
              }
            />
            <Checkbox
              disabled={disableFormRemarks || isLocked}
              className="position-absolute bg-transparent border-0"
              label="Actionable Remarks"
              labelClassName="text-primary fs-12"
              style={{ top: 0, right: 42 }}
              value={actionable.core}
              onChange={e => {
                onActionableChange({ onboard: actionable.onboard, core: e.target.checked });
              }}
            />
          </Col>
        ) : null}
        <Col xs={!isOnboard ? 7 : 12} className="position-relative">
          <Input
            disabled={disableFormRemarks || isLocked}
            value={onboard}
            onChange={value =>
              isFillingNewForm
                ? setRemarks({ core, onboard: value })
                : onRemarksChange({ core, onboard: value })
            }
            label="Review Remarks for Vessel"
            icon={edit}
            info={
              isOnboard ? null : (
                <span>
                  Write here Instructions needed for the correction of the form. This will be
                  visible in <strong>Orca Onboard</strong>.
                  <br /> No notification will be sent to vessel.
                </span>
              )
            }
          />
          <Checkbox
            disabled={disableFormRemarks || isLocked}
            className="position-absolute bg-transparent border-0"
            label="Actionable Remarks"
            labelClassName="text-primary fs-12"
            style={{ top: 0, right: 42 }}
            value={actionable.onboard}
            onChange={e => onActionableChange({ core: actionable.core, onboard: e.target.checked })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FormRemarks;
