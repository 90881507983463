import { numberToStr } from 'common/utils/numbers';
import Warning from './components/Warning';
import { calculateWarning } from './calculations';
import _get from 'lodash/get';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import CalculationTooltip from './components/CalculationTooltip';
import SvgRender from 'common/components/general/SvgRender';
import calculator from 'common/assets/svg/common/calculator.svg';

const NumberView = ({ value, formField, formFields }) => {
  const rule = _get(formField, 'rules.0.rule', null);
  const ruleValues = _get(formField, 'rules.0.values', []);
  const decimalDigitPrecision = _get(formField, 'decimal_digit_precision');

  const message = calculateWarning(rule, ruleValues, value);

  const { avoidRender, tooltipID } = useTooltipID('numeric-formula-view-tooltip');

  if (avoidRender) return null;

  return (
    <div className="d-flex align-items-center">
      {message ? (
        <Warning
          valuesMessage={
            <div>
              <span className="text-nowrap">Value entered is out of the validation range</span>
              <div className="d-flex cmt-4">Range: {message}</div>
            </div>
          }
        />
      ) : null}
      {formField.is_calculated && formFields?.length ? (
        <div
          id={tooltipID}
          className={`calculated-numeric-field d-flex align-items-center justify-content-center cursor-pointer ${
            message ? 'ms-1' : ''
          }`}
        >
          <SvgRender className="text-turquoise" style={{ width: 7, height: 9 }} src={calculator} />

          <Tooltip target={tooltipID} innerClassName="min-width-fit">
            <CalculationTooltip
              formula={formField.formula}
              formulaVariables={formField.formula_variables}
              fields={formFields}
            />
          </Tooltip>
        </div>
      ) : null}
      <span className="fs-12 text-primary ms-1">
        {decimalDigitPrecision
          ? numberToStr(value, decimalDigitPrecision, decimalDigitPrecision)
          : value}
      </span>
    </div>
  );
};

export default NumberView;
