import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

import ShadowBox from 'common/components/general/ShadowBox';

import Field from './Field';

const Overtime = ({ defaultOvertime, overtime }) => {
  return (
    <ShadowBox className="w-100p h-100p d-flex flex-column p-1" color="light-1" flat>
      <div className="d-flex align-items-center">
        <div className="cmt-2 border-radius-5 fs-10 fw-bold d-inline-flex border border-moody-blue text-moody-blue px-1">
          Fixed overtime hours
        </div>
      </div>

      <Row className="w-100p cmt-12">
        <Field defaultValue={defaultOvertime} currentValue={overtime} label="overtime" />
      </Row>
    </ShadowBox>
  );
};

export default Overtime;

Overtime.propTypes = {
  defaultOvertime: PropTypes.string,
  overtime: PropTypes.string
};
