import { useMemo } from 'react';
import { useActions } from 'utils/hooks';
import { Option, SingleValue } from 'common/components/selectors/_formSelectComponents';
import { useSelector } from 'react-redux';

import Select from 'common/components/form/inputs/Select';
import { selectAccount } from 'store/account/selectors';

import * as formActions from 'store/forms/actions';

import { getInitialStateWithoutValues } from 'common/components/forms/digital/state-utils';
import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const FormType = ({
  form,
  excludeFormIds = [],
  setIsFetching,
  setUploadedFile,
  setUploadedFileError,
  setFormState,
  onFormTypeChange,
  selectedFormId,
  setSelectedFormId,
  selectedForm,
  setSelectedForm,
  className,
  disabled,
  selectedVesselId
}) => {
  const [getDigitalForm] = useActions([formActions.getDigitalForm]);
  const account = useSelector(selectAccount);
  const permissionFormIds = getAuthorizedPermissionSettings(
    account,
    permissions.office.forms.create
  )?.forms;

  const exclude_ids = useMemo(() => {
    const dataIds = form ? excludeFormIds.filter(id => id !== form.id) : excludeFormIds;
    const result = selectedFormId ? [...dataIds, selectedFormId] : dataIds;

    return [...new Set(result)];
  }, [form, excludeFormIds, selectedFormId]);

  const getFullForm = async id => {
    try {
      setIsFetching(true);

      const res = await getDigitalForm({ id });
      const newFormState = getInitialStateWithoutValues(res);

      setUploadedFile(null);
      setUploadedFileError(null);

      setFormState(newFormState);
      onFormTypeChange(res);

      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  };

  const onFormChange = form => {
    if (!form) return;
    setSelectedForm(form);
    setSelectedFormId(form.id);
    getFullForm(form.id);
  };

  return (
    <div className="form-type-selector w-100p me-1">
      <Select
        label={'FORM TYPE'}
        isAsync
        defaultOptions={async () => {
          const options = await refetchAsyncOptions('forms', {
            visibility: 'fill_new_form',
            vessel_ids: selectedVesselId ? [selectedVesselId] : undefined,
            uid: permissionFormIds || undefined
          });
          return (options || []).filter(
            el => !exclude_ids.includes(el.id) || el.id === selectedFormId
          );
        }}
        loadOptions={async search => {
          const options = await getAsyncOptions(search, 'forms', {
            vessel_ids: selectedVesselId ? [selectedVesselId] : undefined,
            visibility: 'fill_new_form',
            uid: permissionFormIds || undefined
          });
          return (options || []).filter(
            el => !exclude_ids.includes(el.id) || el.id === selectedFormId
          );
        }}
        value={selectedForm}
        onChange={onFormChange}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        className={`flex-1 mb-0 fs-12 ${className || ''}`}
        placeholder="Select form type"
        disabled={disabled}
        components={{ Option, SingleValue }}
      />
    </div>
  );
};

export default FormType;
