import ContentLoader from 'react-content-loader';

const PostsWithoutImageLoader = () => {
  return (
    <ContentLoader
      speed={1}
      backgroundColor="#F8F9FA"
      foregroundColor="#EEF0F4"
      preserveAspectRatio="none"
      width="100%"
      height={200}
      viewBox="0 0 100 200"
    >
      <rect x="0" y="50" width="100" height="0" />
      <rect height="10" rx="1" ry="1" width="3" x="3" y="51" />
      <rect height="5" rx="12" ry="0" width="18" x="7" y="52" />
      <rect x="3" y="76" rx="12" ry="0" width="28" height="109" />
      <rect x="34" y="76" rx="12" ry="0" width="28" height="109" />

      <rect x="68" y="76" rx="12" ry="0" width="28" height="109" />
    </ContentLoader>
  );
};

export default PostsWithoutImageLoader;
