import { FC } from 'react';
import DepartmentName from './DepartmentName';
import RoleName from './RoleName';

type DepartmentRoleProps = {
  index: number;
  color: string;
  departmentName?: string;
  roleName?: string;
};

const DepartmentRole: FC<DepartmentRoleProps> = ({ index, color, departmentName, roleName }) => {
  return (
    <div>
      <div className="text-violet cmb-4">{index}. Department / Role</div>
      <div className="d-flex align-items-center">
        {departmentName && <DepartmentName name={departmentName} color={color} />}
        {roleName && <RoleName name={roleName} color={color} />}
      </div>
    </div>
  );
};

export default DepartmentRole;
