import permissions from 'common/utils/permissions/constants';
import _get from 'lodash/get';
import { isAuthorized } from 'utils/permissions/authorize';
import { selectAccount } from 'store/account/selectors';
import { jobsNavigationTabs } from 'common/components/jobs/regular/components/NavTabs';
import { tabs as manualTabs } from '@/manuals/components/NavTabs';

import forms from 'common/assets/svg/common/forms.svg';
import captainReports from 'common/assets/svg/common/captain_reports.svg';
import manuals from 'common/assets/svg/common/manuals.svg';
import events from 'common/assets/svg/common/events.svg';
import jobs from 'common/assets/svg/common/jobs.svg';
import pms from 'common/assets/svg/common/setup.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import vessel from 'common/assets/svg/vessels/vessel.svg';
import clock from 'common/assets/svg/common/clock-line.svg';
import starIcon from 'common/assets/svg/common/star.svg';
import accounting from 'common/assets/svg/accounting/calculate.svg';
import past from 'common/assets/svg/common/history.svg';
import runningHours from 'common/assets/svg/common/reminder.svg';
import inventory from 'common/assets/svg/accounting/inventory.svg';
import list from 'common/assets/svg/common/list.svg';
import toDelivered from 'common/assets/svg/actions/to-be-delivered.svg';
import voyages from 'common/assets/svg/common/voyages.svg';

// Use groupPath to highlight a specific menu item for every other route that starts with the same path

const items = paths => [
  {
    path: paths?.captain_reports,
    icon: captainReports,
    label: 'captain_reports',
    name: 'Captain Reports',
    permissions: [permissions.onboard.captain_reports.view]
  },
  {
    icon: voyages,
    name: 'Voyages',
    label: 'voyages',
    submenu: [
      {
        icon: voyages,
        name: 'Operations',
        path: paths?.voyages_itinerary,
        requiredOrganizationSettings: [{ itinerary_edit_scheduled_ports_onboard: true }],
        permissions: [
          permissions.onboard.voyages.itinerary.view,
          permissions.onboard.voyages.itinerary.edit
        ]
      }
    ]
  },
  {
    path: paths?.form_planning,
    icon: forms,
    name: 'Forms',
    label: 'forms',
    permissions: [permissions.onboard.forms.view]
  },
  {
    path: paths?.crew,
    icon: crewPlanning,
    name: 'Crew',
    label: 'crew',
    permissions: [permissions.onboard.crew.list.view]
  },
  {
    path: paths?.crew_evaluations,
    icon: starIcon,
    name: 'Evaluation Obligations',
    label: 'crew_evaluations',
    permissions: [permissions.onboard.crew.evaluation_obligations.view]
  },
  {
    path: paths?.shifts,
    icon: clock,
    name: 'Watchkeeping',
    label: 'watchkeeping',
    permissions: [permissions.onboard.crew.watchkeeping.view]
  },
  {
    icon: manuals,
    name: 'Manuals',
    selectPath: state => {
      const tab = manualTabs.find(tab => isAuthorized(selectAccount(state), tab.permissions));

      return tab ? tab.to : paths.manuals;
    },
    label: 'manuals',
    permissions: [permissions.onboard.manuals.view, permissions.onboard.files.view]
  },
  {
    icon: events,
    name: 'Events',
    label: 'events',
    path: paths?.events,
    permissions: [permissions.onboard.events.view]
  },
  {
    selectPath: state =>
      _get(
        jobsNavigationTabs.filter(tab => isAuthorized(selectAccount(state), tab.permissions)),
        '[0].path'
      ) || paths.regular_jobs,
    icon: jobs,
    name: 'Jobs & Findings',
    label: 'jobs',
    permissions: [permissions.onboard.jobs.list.view]
  },
  {
    icon: pms,
    name: 'PMS',
    label: 'pms',
    certified: 'Orca PMS V1.2.9 Certified',
    submenu: [
      {
        label: 'pms_jobs_per_system',
        path: paths?.pms_jobs_per_system,
        icon: pms,
        name: 'PMS Jobs',
        permissions: [permissions.onboard.pms.jobs.view],
        groupPath: paths?.pms_jobs_label
      },
      {
        label: 'pms_running_hours',
        path: paths?.pms_running_hours,
        icon: runningHours,
        permissions: [permissions.onboard.pms.running_hours.view],
        name: 'Running Hours'
      },
      {
        label: 'pms_inventory',
        path: paths?.pms_inventory,
        icon: inventory,
        permissions: [permissions.onboard.pms.spare_parts_inventory.view],
        name: 'Spare Parts Inventory'
      }
    ]
  },
  {
    icon: purchasing,
    name: 'Purchasing',
    label: 'purchasing',
    submenu: [
      {
        path: `${paths.purchasing_requisitions}`,
        icon: list,
        permissions: [permissions.onboard.purchasing.requisitions.view],
        name: 'Case List'
      },
      {
        path: paths?.purchasing_deliverables,
        icon: toDelivered,
        permissions: [permissions.onboard.purchasing.deliverables.view],
        name: 'Deliverables'
      },
      {
        path: paths?.purchasing_inventory,
        icon: inventory,
        permissions: [permissions.onboard.purchasing.stores_inventory.view],
        name: 'Stores Inventory'
      }
    ]
  },
  {
    path: paths?.vessels,
    label: 'vessel',
    icon: vessel,
    name: 'Vessel',
    permissions: [permissions.onboard.vessel.view]
  },
  {
    icon: accounting,
    name: 'MGA',
    label: 'mga',
    submenu: [
      {
        path: `${paths?.mga}/overview`,
        label: 'mga',
        icon: accounting,
        name: 'MGA',
        permissions: [permissions.onboard.accounting.mga.current.view],
        groupPath: paths?.mga
      },
      {
        path: `${paths?.mga}/past/overview`,
        label: 'mga_past',
        icon: past,
        name: 'Past MGA',
        permissions: [permissions.onboard.accounting.mga.past.view]
      }
    ]
  }
];

export default items;
