import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import { selectJobType, selectSelectedJob } from 'common/components/jobs/_base/store/selectors';
import { jobEnums } from 'common/utils/fixed';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';
import { departmentTypeEnums } from 'common/utils/fixed';

const JobTypeTabs = ({ isOnCreate, drawerIsOpen }) => {
  const { pms, regular, unscheduled, flag_dispensation, orca_jobs } = jobEnums;
  const selectedJob = useSelector(selectSelectedJob);

  const tabs = useMemo(
    () => [
      { label: 'Regular Job', value: regular },
      { label: 'Flag Dispensation', value: flag_dispensation },
      { label: 'Orca Jobs', value: orca_jobs },
      { label: 'Unplanned Maintenance Job', value: unscheduled },
      { label: 'PMS Job', value: pms, show: isOnCreate ? false : true }
    ],
    [regular, flag_dispensation, orca_jobs, unscheduled, pms, isOnCreate]
  );

  const [setSelectedVesselFilter, setSelectedSystemFilter] = useActions([
    pmsJobsActions.setSelectedVesselFilter,
    pmsJobsActions.setSelectedSystemFilter
  ]);

  const [setJobField, resetJobProfile, setSelectedJob] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.resetJobProfile,
    jobProfileActions.setSelectedJob
  ]);

  const jobType = useSelector(selectJobType) || regular;

  const onChangeTab = value => {
    if (value === jobType) return;

    if (selectedJob) {
      // First reset select job state, and then change job type
      resetJobProfile();
      setSelectedJob(null);
    }

    // Reset job filters when changing to regular
    if (value === regular) {
      setSelectedVesselFilter(null);
      setSelectedSystemFilter(null);
    }

    setJobField('type', value);
    // When job type changes we set responsible_onboard_department to null
    setJobField(departmentTypeEnums.responsible_onboard_department, null, true);
  };

  useEffect(() => {
    if (!drawerIsOpen) {
      setSelectedVesselFilter(null);
      setSelectedSystemFilter(null);
      setJobField('type', regular);
    }
  }, [drawerIsOpen, regular, setJobField, setSelectedSystemFilter, setSelectedVesselFilter]);

  return (
    <div className={`${isOnCreate ? 'd-flex-column' : 'd-flex'} align-items-center cmb-4`}>
      {isOnCreate ? <div className="form-label mb-1 cms-4">JOB TYPE</div> : null}

      <div className="d-flex align-items-center">
        {tabs.map(tab => {
          const isActive = tab.value === jobType || (tab.value === regular && jobType === null);

          return tab.show === false ? null : (
            <Button
              key={tab.value}
              color={isActive ? 'primary' : 'link'}
              size="sm"
              className={`height-16 lh-12 fs-10 py-0 cms-4 fw-medium ${
                !isActive ? 'border rounded text-inactive' : ''
              }`}
              onClick={() => onChangeTab(tab.value)}
            >
              {tab.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

JobTypeTabs.propTypes = {
  drawerIsOpen: PropTypes.bool,
  isOnCreate: PropTypes.bool
};

export default JobTypeTabs;
