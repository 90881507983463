import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import { FC, PropsWithChildren } from 'react';
import { Size } from '@/ts-common/types/files';

const boxSize: {
  [key in 'lg' | 'sm' | 'xs']: string;
} = {
  lg: variables.size56,
  sm: variables.size24,
  xs: variables.size20
};

const boxRadius: {
  [key in 'lg' | 'sm' | 'xs']: string | number;
} = {
  lg: variables.borderRadiusLg,
  sm: 0,
  xs: 0
};

type FileContainerProps = PropsWithChildren<{
  size: Size;
  height?: string;
  id?: string;
}>;

const FileContainer: FC<FileContainerProps> = ({ size, children, height, id }) => {
  return (
    <Container size={size} height={height} id={id}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  min-width: ${({ size }: { size: Size }) => boxSize[size]};
  max-width: ${({ size }: { size: Size }) => boxSize[size]};
  width: 100%;
  height: ${({ size, height }: { size: Size; height?: string }) => height || boxSize[size]};
  border-radius: ${({ size }: { size: Size }) => boxRadius?.[size] || variables.borderRadiusBase};
  color: currentColor;
  position: relative;

  &:hover {
    .file-remove-icon {
      opacity: 1;
    }
  }
`;

export default FileContainer;
