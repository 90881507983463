import React from 'react';
import VesselSelector from 'common/components/selectors/VesselSelector';

const VesselsInput = ({ value = [], error, onChange }) => {
  return (
    <VesselSelector
      filter={{ value: value?.map(v => v.id || v) }}
      error={error}
      onChange={e => onChange(e.value)}
      placeholder="Select vessel(s)"
      isMulti={true}
      styled={false}
      autoFocus={false}
      gray={true}
    />
  );
};

export default VesselsInput;
