import { parseQueryParams, stringifyObj } from 'utils/urls';

const clearFormIdFromLocationSearch = history => {
  const { jobID, ...rest } = parseQueryParams(history.location.search || '');
  const search = stringifyObj(rest);

  return search;
};

export const removeJobIdFromURL = (history, match) => {
  if (match.params.jobID) {
    history.replace({
      pathname: history.location.pathname.replace(`/${match.params.jobID}`, ''),
      search: history.location.search
    });
  } else if (match.path.includes('/create')) {
    history.replace({
      pathname: history.location.pathname.replace(`/create`, ''),
      search: history.location.search
    });
  } else if (history.location.search && parseQueryParams(history.location.search)?.jobID) {
    history.replace({
      pathname: history.location.pathname,
      search: clearFormIdFromLocationSearch(history)
    });
  }
};
