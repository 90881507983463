import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';

import PropTypes from 'prop-types';
import { constructPageTitle } from 'common/utils/pages';

const PageDocumentTitle = ({ documentTitle }) => {
  const { location, match } = useRouter();

  const title = useSelector(state =>
    typeof documentTitle === 'function' ? documentTitle(state, { location, match }) : documentTitle
  );

  useEffect(() => {
    document.title = constructPageTitle(title);
  }, [title]);

  if (documentTitle === undefined && import.meta.env.MODE === `development`) {
    throw new Error('The documentTitle is required in route. Check src/routing/index.js for more.');
  }

  return null;
};

PageDocumentTitle.propTypes = {
  documentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
};

export default PageDocumentTitle;
