import { useCallback, useEffect, useState } from 'react';
import { selectIsTreeInitialized } from 'common/components/filesystem/store/selectors';
import Sidebar from 'common/components/filesystem/components/Sidebar';
import Content from 'common/components/filesystem/components/content';

import { getFolders } from 'common/components/filesystem/store/actions';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import PageLoader from '@/ts-common/components/general/PageLoader';

const FileSystemLayout = () => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const initialized = useSelector(selectIsTreeInitialized);

  const init = useCallback(async () => {
    if (initialized) return null;

    try {
      setIsFetching(true);

      await dispatch(getFolders());

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error(err);
    }
  }, [dispatch, initialized]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="filesystem-layout">
      <div className="filesystem-layout__inner">
        <PageLoader isLoading={isFetching} />
        <Sidebar />
        <div className="filesystem-layout__main">{initialized && <Content />}</div>
      </div>
    </div>
  );
};

export default FileSystemLayout;
