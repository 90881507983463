import axios, { AxiosResponse } from 'axios';
import store from 'store';

import Cookie from 'utils/cookie';

import AUTH_TYPES from 'store/auth/types';

import { stringifyObj } from 'utils/urls';
import { getAccountPermissions } from 'store/account/actions';

type ReturnedHeadersType = {
  Accept: string;
  'Content-Type': string;
  Authorization?: string;
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const authHeader = () => {
  const token = Cookie.get('accessToken');

  return { Authorization: `Bearer ${token}` };
};

export const getApiBaseUrl = () => {
  const base_url =
    import.meta.env.MODE === 'development'
      ? import.meta.env.VITE_API_URL
      : `${window.location.protocol}//${window.location.hostname}:${import.meta.env.VITE_API_PORT}`;

  return base_url;
};

const getURL = (path: string) => getApiBaseUrl() + (path.startsWith('/') ? path : '/' + path);

const getHeaders = (auth?: boolean) => {
  let headers = { ...defaultHeaders };

  if (auth) {
    headers = { ...headers, ...authHeader() };
  }

  return headers;
};

const apiService = import.meta.env.MODE === 'test' ? axios : axios.create({});

export const get = <T>(
  path: string,
  params: Record<string, unknown> = {},
  auth = true
): Promise<AxiosResponse<T>> =>
  apiService.get(getURL(path), {
    params,
    headers: getHeaders(auth),
    paramsSerializer: params => stringifyObj(params)
  });

export const post = <T>(
  path: string,
  params: Record<string, unknown> = {},
  auth = true
): Promise<AxiosResponse<T>> => {
  return apiService.post(getURL(path), params, { headers: getHeaders(auth) });
};

export const put = <T>(
  path: string,
  params: Record<string, unknown> = {},
  auth = true
): Promise<AxiosResponse<T>> =>
  apiService.put(getURL(path), params, {
    headers: getHeaders(auth)
  });

type DeleteParamsType = {
  data?: Record<string, unknown>;
};

export const deleteRequest = <T>(
  path: string,
  params: DeleteParamsType = {},
  auth = true
): Promise<AxiosResponse<T>> =>
  apiService.delete(getURL(path), { params, headers: getHeaders(auth) });

export const upload = <T>(
  path: string,
  params: Record<string, unknown> | FormData = {},
  config: Record<string, unknown> = {}
): Promise<AxiosResponse<T>> =>
  apiService.post(getURL(path), params, {
    headers: { ...getHeaders(true), 'content-type': 'multipart/form-data' },
    ...config
  });

type DownloadConfigType = {
  responseType: 'blob';
  params: Record<string, unknown>;
  headers: ReturnedHeadersType;
  paramsSerializer?: (params: Record<string, unknown>) => string;
};

export const download = <T = Blob>(
  path: string,
  params: Record<string, unknown> = {},
  parsed?: boolean
): Promise<AxiosResponse<T>> | null => {
  let config: DownloadConfigType = {
    responseType: 'blob',
    params,
    headers: getHeaders(true)
  };
  if (parsed) {
    config = {
      ...config,
      paramsSerializer: params => stringifyObj(params)
    };
  }

  const isUrlFromS3 = path.includes('s3');
  const isUrlPublic = path.includes('public-files');

  if (isUrlFromS3 || isUrlPublic) {
    window.open(isUrlPublic ? getURL(path) : path, '_blank');
    return null;
  }

  return apiService.get(getURL(path), config);
};

(function checkIfAuthStatusHasChanged() {
  window.addEventListener(
    'focus',
    event => {
      const authState = store.getState().auth;

      if (
        Cookie.get('accessToken') &&
        'isAuthenticated' in authState &&
        !authState.isAuthenticated
      ) {
        // if user has logged in another tab, log him in this tab too
        console.log('Tab Focus - Updating tab token');

        store.dispatch({
          type: AUTH_TYPES.CREDENTIALS_LOGIN.SUCCESS,
          payload: {
            token: Cookie.get('accessToken'),
            refresh_token: window.localStorage.getItem('refreshToken')
          }
        });
      } else if (
        !Cookie.get('accessToken') &&
        'isAuthenticated' in authState &&
        authState.isAuthenticated
      ) {
        // if user is logged in this tab, but the token is missing, log him out
        console.log('Tab Focus - Will logout user from this tab');
        store.dispatch({
          type: AUTH_TYPES.LOGOUT.SUCCESS
        });
      }
    },
    false
  );
})();

// API request interceptor
export function setupRequestInterceptors() {
  apiService.interceptors.request.use(
    function (config) {
      // Check if the request has expired token. Request new tokens if it does.

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

type ToolkitStoreType = {
  dispatch: (params: any) => unknown;
};

// API response interceptor
export function setupResponseInterceptors(store: ToolkitStoreType) {
  apiService.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      switch (error && error.response && error.response.status) {
        case 401:
          console.log('401 Response, will logout user');
          store.dispatch({
            type: AUTH_TYPES.LOGOUT.SUCCESS
          });

          break;
        case 403:
          store.dispatch(getAccountPermissions());

          break;
        default:
          return Promise.reject(error.response);
      }

      return Promise.reject(error.response);
    }
  );
}
