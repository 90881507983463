import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

import { numberToStr } from 'common/utils/numbers';

const enumNames = {
  overtime: 'Overtime Hours',
  holiday: 'Holidays'
};

const Field = ({ defaultValue, currentValue, className, label }) => {
  return (
    <Col className={`d-flex flex-column pe-1 ${className || ''} ${label}`}>
      <div className="text-uppercase mb-0 text-primary fs-10 fw-normal text-nowrap">
        {enumNames[label] || label}
      </div>

      <div className="fw-bold d-flex align-items-center text-primary w-100p pe-1">
        <div className="flex-1"></div>
        <div className="text-end">{numberToStr(currentValue, 2, 2)}</div>
      </div>
      {defaultValue && defaultValue !== currentValue ? (
        <div className="d-flex align-items-center w-100p text-warning fs-10 pe-1 fw-bold">
          <div className="flex-1"></div>
          <div className="text-end">{numberToStr(defaultValue, 2, 2)}</div>
        </div>
      ) : null}
    </Col>
  );
};

export default Field;

Field.propTypes = {
  defaultValue: PropTypes.string,
  currentValue: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired
};
