import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Textarea from 'common/components/form/inputs/Textarea';
import PageSaving from 'common/components/general/PageSaving';

import useRouter from 'use-react-router';
import {
  selectErrors,
  selectIsSaving,
  selectType,
  selectEventType,
  selectEventSubtypes,
  selectEventVessel,
  selectEventIsForVessel,
  selectEventIsScheduled,
  selectEventName,
  selectEventApprovers,
  selectEventDepartmentIds,
  selectEventResponsible,
  selectEventTags,
  selectEventRemarks,
  selectIsMainInfoLocked,
  selectEventImportance
} from 'events/store/events/selectors';
import { v4 as uuid } from 'uuid';

import Switch from 'common/components/form/inputs/Switch';
import Importance from '../../components/Importance';
import EventStatus from '../../components/EventStatus';
import CustomSelect from '../../components/Select';
import Type from 'events/components/Type';
import SubType from 'events/components/SubType';
import Title from 'events/components/Title';
import Port from 'events/components/Port';
import Responsibility from 'events/components/Responsibility';
import TagInput from 'common/components/general/Tags';
import comments from 'common/assets/svg/common/comments.svg';
import SvgRender from 'common/components/general/SvgRender';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import PageLoader from 'common/components/general/PageLoader';
import ForVesselSwitch from 'common/components/form/styled-inputs/ForVesselSwitch';
import ForVesselModal from './ForVesselModal';
import EventDatePicker from '../../components/event-dates';
import { parseSelectValues } from '../helpers';
import { setEventTemplateChecklist } from 'events/event-templates/store/slice';
import {
  addModule,
  changeEventValue,
  editEvent,
  removeAllModules
} from 'events/store/events/actions';
import { getEventTemplateFieldData } from 'events/event-templates/store/actions';
import { setErrors } from 'events/store/events/actions';
import { warningHandler } from 'common/utils/notifications';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { setChecklistOptions } from 'events/store/event-modules/checklist/slice';
import { setOfficeAttachmentsData } from 'events/store/event-modules/office-attachments/slice';

const SideContent = ({ vessel }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const dispatch = useDispatch();
  const { location, match } = useRouter();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const isSaving = useSelector(selectIsSaving);
  const errors = useSelector(selectErrors);
  const moduleList = useSelector(state => selectListOptionsFromStore(state, 'event-modules'));
  const type = useSelector(selectType);
  const eventType = useSelector(selectEventType);
  const eventSubtypes = useSelector(selectEventSubtypes);
  const eventVessel = useSelector(selectEventVessel);
  const eventIsForVessel = useSelector(selectEventIsForVessel);
  const eventIsScheduled = useSelector(selectEventIsScheduled);
  const eventImportance = useSelector(selectEventImportance);
  const eventName = useSelector(selectEventName);
  const eventApprovers = useSelector(selectEventApprovers);
  const eventDepartmentIds = useSelector(selectEventDepartmentIds);
  const eventResponsible = useSelector(selectEventResponsible);
  const eventTags = useSelector(selectEventTags);
  const eventRemarks = useSelector(selectEventRemarks);
  const isMainInfoLocked = useSelector(selectIsMainInfoLocked);

  const isCreate = useMemo(() => {
    if (!location) return null;
    return location.pathname.includes('create');
  }, [location]);

  const saveItems = useCallback(
    params => {
      dispatch(editEvent(params));
    },
    [dispatch]
  );
  const memoizedDebounce = useMemo(() => _debounce(saveItems, 600), [saveItems]);

  const checkForTemplate = useCallback(async () => {
    if (
      !eventType?.id ||
      !isCreate ||
      (eventType?.has_subtype && !eventSubtypes?.length) ||
      isOnBoard
    )
      return;

    setIsSearching(true);

    const params = {
      mode: 'single',
      type_id: eventType.id,
      subtype_ids: eventSubtypes?.map(s => s.id) || undefined
    };

    const fieldsToFill = [
      'name',
      'importance',
      'status',
      'responsible',
      'departments',
      'approvers',
      'watchers'
    ];

    try {
      const res = await dispatch(getEventTemplateFieldData(params));

      dispatch(removeAllModules());

      if (!Array.isArray(res)) {
        fieldsToFill.forEach(f => {
          dispatch(changeEventValue({ [f]: res[f] }));
        });
      }

      if (res?.modules?.length) {
        res.modules.forEach(m => {
          const foundModule = moduleList.find(el => el.id === m.type.id);
          if (foundModule) {
            const mod = {
              id: uuid(),
              temporary: true,
              type: {
                id: foundModule.id,
                label: foundModule.label,
                name: foundModule.name
              }
            };
            dispatch(
              addModule({
                type_id: foundModule.id,
                mod,
                isCreate: true
              })
            );
          }
        });
      }

      if (res?.checklist?.length) {
        dispatch(setEventTemplateChecklist(res?.checklist));
      }

      if (res?.checklist?.length) {
        dispatch(setChecklistOptions(res.checklist));
      }

      if (res?.departments?.length) {
        const ids = res.departments.map(d => d.id);
        dispatch(changeEventValue({ department_ids: ids }));
      }

      if (res?.office_attachment_groups?.length) {
        dispatch(
          setOfficeAttachmentsData(
            res.office_attachment_groups.map(group => ({
              id: group.id,
              name: group.name,
              attachments: []
            }))
          )
        );
      }

      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
    }
  }, [
    dispatch,
    eventSubtypes,
    eventType?.has_subtype,
    eventType?.id,
    isCreate,
    isOnBoard,
    moduleList
  ]);

  const onSelectChange = useCallback(
    (name, editName) => opt => {
      if (isMainInfoLocked) return;
      const valueParams = { [name]: opt };

      dispatch(changeEventValue(valueParams));

      if (name === 'type') {
        dispatch(changeEventValue({ subtypes: null }));
      } else if (name === 'vessel') {
        // Reset itinerary port
        if (!opt) {
          dispatch(
            changeEventValue({
              port_type: 'port',
              itinerary_port: null
            })
          );
        } else {
          dispatch(changeEventValue({ itinerary_port: null }));
        }
      }

      if (isCreate) {
        if (name === 'type') {
          dispatch(setErrors({ type: '' }));
        }
        if (name === 'subtypes') {
          dispatch(setErrors({ subtypes: '' }));
        }
      } else if (!isCreate) {
        const params = {
          id: match.params.id,
          [editName]: parseSelectValues(editName, opt)
        };

        if (name === 'type') {
          params.subtype_ids = [];
        }

        if (name === 'subtypes' && type?.id) {
          params.type_id = type?.id;
        }

        if (name === 'vessel') {
          params.itinerary_port_id = null;
        }

        memoizedDebounce(params);
      }
    },
    [dispatch, isCreate, isMainInfoLocked, match.params.id, memoizedDebounce, type?.id]
  );

  const onInputChange = e => {
    if (isMainInfoLocked) return;
    const params = {
      [e.target.name]: e.target.value
    };
    dispatch(changeEventValue(params));

    if (isCreate) {
      if (e.target.name === 'name') {
        dispatch(setErrors({ title: '' }));
      }
    }
    if (!isCreate) {
      if (e.target.name === 'name' && !e.target.value) {
        dispatch(
          warningHandler({ title: 'Warning', message: 'Event title should not stay empty' })
        );
        return;
      }
      let params = {
        id: match.params.id,
        [e.target.name]: e.target.value
      };
      memoizedDebounce(params);
    }
  };

  const onScheduledChange = value => {
    if (isMainInfoLocked) return;

    const params = {
      is_scheduled: value
    };

    dispatch(changeEventValue(params));

    if (!isCreate) {
      let params = {
        id: match.params.id,
        is_scheduled: value
      };
      memoizedDebounce(params);
    }
  };

  const onTagsChange = value => {
    if (isMainInfoLocked) return;
    const params = {
      tags: value
    };
    dispatch(changeEventValue(params));

    if (!isCreate) {
      let params = {
        id: match.params.id,
        tags: value
      };
      memoizedDebounce(params);
    }
  };

  const handleVesselToggle = () => {
    if (isMainInfoLocked) return;
    if (!isCreate && eventIsForVessel) return;

    if (eventIsForVessel) {
      const params = { is_for_vessel: eventIsForVessel };

      dispatch(changeEventValue(params));

      if (!isCreate) {
        let params = {
          id: match.params.id,
          is_for_vessel: eventIsForVessel
        };
        memoizedDebounce(params);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (!eventType?.has_subtype) {
      checkForTemplate();
    }
  }, [checkForTemplate, eventType?.has_subtype]);

  useEffect(() => {
    if (eventSubtypes?.length) {
      checkForTemplate();
    }
  }, [checkForTemplate, eventSubtypes?.length]);

  useEffect(() => {
    if (!eventVessel && isOnBoard && vessel && isCreate) {
      onSelectChange('vessel', 'vessel_id')(vessel);
    }
  }, [eventVessel, isCreate, isOnBoard, onSelectChange, vessel]);

  return (
    <div>
      <PageSaving isSaving={isSaving} />

      <Row className="d-flex align-items-center px-1">
        <Col>
          <Type
            className="w-auto"
            value={eventType}
            onChange={onSelectChange('type', 'type_id')}
            error={_get(errors, 'type')}
            disabled={isMainInfoLocked}
          />
        </Col>
        {!isOnBoard ? (
          <Col xs="auto">
            <ForVesselSwitch
              value={eventIsForVessel}
              onChange={handleVesselToggle}
              disabled={isMainInfoLocked}
            />
          </Col>
        ) : null}
      </Row>
      <SubType
        className="ps-1"
        type={eventType}
        value={eventSubtypes}
        onChange={onSelectChange('subtypes', 'subtype_ids')}
        error={_get(errors, 'subtypes')}
        disabled={isMainInfoLocked}
      />

      <Title
        className="px-1"
        value={eventName}
        error={_get(errors, 'title')}
        onChange={onInputChange}
        disabled={isMainInfoLocked}
      />

      <div className="ps-1">
        <Switch
          switchClassName="orange-violet-switch flex-row-reverse justify-content-end"
          size={'sm'}
          label={
            <div className={`text-${eventIsScheduled ? 'orange' : 'violet'} fw-medium`}>
              {eventIsScheduled ? 'Scheduled' : 'Unscheduled'}
            </div>
          }
          labelClassName="ms-1"
          onChange={() => onScheduledChange(!eventIsScheduled)}
          value={!eventIsScheduled}
          disabled={isMainInfoLocked}
        />
      </div>

      <div className="divider" />

      <EventDatePicker
        isCreate={isCreate}
        memoizedDebounce={memoizedDebounce}
        disabled={isMainInfoLocked}
      />

      <div className="d-flex ps-1">
        <Importance
          isCreate={isCreate}
          changeEventValue={params => dispatch(changeEventValue(params))}
          memoizedDebounce={memoizedDebounce}
          importance={eventImportance}
          disabled={isMainInfoLocked || isSearching}
        />
        <EventStatus
          isCreate={isCreate}
          className="ms-2 mb-3"
          isForVesselStatus={selectedStatus}
          changeEventValue={params => dispatch(changeEventValue(params))}
          memoizedDebounce={memoizedDebounce}
          disabled={isMainInfoLocked}
        />
      </div>

      <div className="divider" />

      <Responsibility
        className="px-1 mb-3"
        responsible={eventResponsible}
        departmentIDs={eventDepartmentIds}
        approvers={eventApprovers}
        disabled={isMainInfoLocked}
        onResponsibleChange={onSelectChange('responsible', 'responsible_id')}
        onDepartmentsChange={onSelectChange('department_ids', 'department_ids')}
        onApproversChange={onSelectChange('approvers', 'approver_ids')}
      />

      <div className="divider" />
      <CustomSelect
        value={eventVessel}
        type="vessel"
        list={'vessels'}
        preventOptionsFetch={isOnBoard ? true : false}
        isCreate={isCreate}
        disabled={isMainInfoLocked}
        className={`mt-3 px-1 ${!isCreate && eventIsForVessel ? 'pointer-events-none' : ''}`}
        dataCy="event_vessel"
        onChange={isOnBoard ? () => {} : onSelectChange('vessel', 'vessel_id')}
      />
      <Port
        isCreate={isCreate}
        vesselId={eventVessel?.id}
        memoizedDebounce={memoizedDebounce}
        disabled={isMainInfoLocked}
      />
      <div className="divider" />
      <TagInput
        tags={eventTags?.length ? eventTags : []}
        className="mb-2 px-1 mt-3"
        onChange={onTagsChange}
        label={<div className="fw-bold">Tags</div>}
        type="event"
        disabled={isMainInfoLocked}
      />
      <Textarea
        white
        autoResize
        disabled={isMainInfoLocked}
        label={
          <div className="d-flex align-items-center">
            <SvgRender src={comments} style={{ width: 13, height: 13 }} className="me-1" />
            <span>REMARKS</span>
          </div>
        }
        value={eventRemarks}
        name="remarks"
        rows={8}
        placeholder="Add some remarks"
        onChange={onInputChange}
        className="px-1"
      />
      <PageLoader isLoading={isSearching} />

      <ForVesselModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isCreate={isCreate}
        memoizedDebounce={memoizedDebounce}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
    </div>
  );
};

export default SideContent;
