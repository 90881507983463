import _isString from 'lodash/isString';

export const REQUIRED_FIELD_MESSAGE = 'Required';

export const hasRequiredValidation = (error?: string | null) => {
  if (error && _isString(error))
    return error?.toLowerCase()?.includes(REQUIRED_FIELD_MESSAGE.toLowerCase()) || false;

  return false;
};
