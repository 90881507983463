import paths from 'routing/routes/_paths';

import PmsSystemJobs from 'views/pms/system';
import PmsJobs from 'views/pms/list';
import PmsRunningHours from 'views/pms/running-hours';
import Inventory from 'views/pms/inventory';
import SystemRunningHours from 'views/captain-reports/system-running-hours';
import permissions from 'common/utils/permissions/constants';

const routes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.pms_jobs}/:jobID?`,
    component: PmsJobs,
    permissions: [permissions.onboard.pms.jobs.view],
    documentTitle: 'PMS Jobs'
  },
  {
    path: `${paths.pms_jobs_per_system}/:jobID?`,
    component: PmsSystemJobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.pms.jobs.view],
    documentTitle: 'PMS Jobs'
  },
  {
    path: `${paths.pms_running_hours}`,
    component: PmsRunningHours,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.pms.running_hours.view],
    documentTitle: 'PMS Running Hours'
  },
  {
    path: `${paths.pms_running_hours}/:id`,
    component: SystemRunningHours,
    type: 'private',
    exact: true,
    app: true,
    permissions: [permissions.onboard.pms.running_hours.view],
    documentTitle: 'System Running Hours'
  },
  {
    path: `${paths.pms_inventory}`,
    component: Inventory,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.pms.spare_parts_inventory.view],
    documentTitle: 'Spare Parts Inventory'
  }
];

export default routes;
