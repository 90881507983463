import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import SvgRender from 'common/components/general/SvgRender';
import { icon } from '@/ts-common/utils/files.ts';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import FileButton from 'common/components/buttons/FileButton';
import download from 'common/assets/svg/actions/download.svg';
import AttachmentFile from 'common/components/attachments/AttachmentFile';
import { useInView } from 'react-intersection-observer';
import { calculateAttachmentsWidth } from 'common/components/attachments/helpers';

const AttachmentsMenu = ({
  downloadHandler,
  isDisabled,
  attachments,
  popupPlacement,
  canDownloadAllAttachments,
  size,
  previewAttachmentHandler,
  isAttachmentMenuOpen,
  attachmentsButtonRef
}) => {
  const { tooltipID, avoidRender } = useTooltipID(`attachments-sync-state`);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const containerWrapperRef = useRef(null);
  const containerRef = useRef(null);

  const [ref, inView] = useInView({
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && isAttachmentMenuOpen) {
      const autoAdjustPopperWidth = () => {
        const boundariesDimensions = document
          .querySelector('.app-content--main-inner')
          ?.getBoundingClientRect();
        const attachmentsWrapperDimensions = containerWrapperRef.current.getBoundingClientRect();
        const attachmentsButtonDimensions = attachmentsButtonRef.current?.getBoundingClientRect();

        calculateAttachmentsWidth(
          popupPlacement,
          { boundariesDimensions, attachmentsWrapperDimensions, attachmentsButtonDimensions },
          { containerWrapperRef, containerRef }
        );
      };

      autoAdjustPopperWidth();
    }
  }, [attachmentsButtonRef, inView, isAttachmentMenuOpen, popupPlacement]);

  if (avoidRender) return null;

  return (
    <div
      ref={node => {
        ref(node);
        containerWrapperRef.current = node;
      }}
      className={`download-attachments-menu download-attachments-menu-${
        isAttachmentMenuOpen ? 'open' : ''
      } download-attachments-menu--${popupPlacement} download-attachments-menu--${size} position-absolute ${
        !isAttachmentMenuOpen ? 'opacity-0 pointer-events-none' : ''
      }`}
    >
      <div className={`d-flex align-items-center`} ref={containerRef}>
        <FileButton
          className={`text-white cme-4 bg-primary cmb-6`}
          onClick={downloadHandler}
          disabled={isDisabled}
          id={tooltipID}
          size={size}
        >
          <SvgRender
            className="text-white pointer-events-none"
            src={download}
            style={{ width: 12, height: 12 }}
          />
        </FileButton>

        {attachments.map((file, index) => (
          <AttachmentFile
            key={file.id}
            file={file}
            onClick={previewAttachmentHandler}
            size={size}
            icon={icon(file.mime_type, file.extension)}
            index={index}
            className={'cmb-6'}
          />
        ))}
      </div>

      {isOnBoard && !canDownloadAllAttachments && (
        <Tooltip target={tooltipID}>Syncing from office still in progress</Tooltip>
      )}
    </div>
  );
};

export default AttachmentsMenu;
