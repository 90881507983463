const DepartmentEmpty = () => {
  return (
    <>
      <div className="text-violet cmb-4">1. Department / Role</div>
      <div className="fs-12">-</div>
      <div className="text-violet mt-2">Vessels</div>
      <div className="fs-12 mb-2">-</div>
    </>
  );
};

export default DepartmentEmpty;
