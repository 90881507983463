import { FC } from 'react';
import spinner from '@/ts-common/assets/svg/common/spinner.svg';
import SvgRender from '@/ts-common/components/general/SvgRender.tsx';

type SpinnerProps = {
  size?: number;
  style?: {
    width: number;
    height: number;
  };
  className?: string;
};

const Spinner: FC<SpinnerProps> = ({ size = 32, style = {}, className = '' }) => (
  <div className={`d-inline-flex align-items-center ${className}`}>
    <SvgRender src={spinner} style={{ width: size, height: size, ...style }} />
  </div>
);
Spinner.defaultProps = {
  size: 32
};

export default Spinner;
