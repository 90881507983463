import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';

import SvgRender from 'common/components/general/SvgRender';
import languageIcon from 'common/assets/svg/common/language.svg';

const LanguageRow = ({ row }) => {
  return (
    <Row className="text-dark fw-normal">
      <Col xs={4} className="d-flex align-items-center">
        <SvgRender
          src={languageIcon}
          style={{ width: 16, height: 16 }}
          className="me-3 text-purple"
        />
        <span>{_get(row, 'language.name', '-')}</span>
      </Col>
      <Col xs={4}>{_get(row, 'language_level.name', '-')}</Col>
      <Col xs={4}>{_get(row, 'mother_tongue') ? 'Yes' : 'No'}</Col>
    </Row>
  );
};

export default LanguageRow;
