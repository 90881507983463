import { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import VisibleGroupField from 'captain-reports/templates/layout/fields/VisibleGroupField';
import VisibleField from 'captain-reports/templates/layout/fields/VisibleField';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import { SubGroupHeader } from 'captain-reports/templates/layout/fields/SubGroup';

import useSubGroupLength from 'captain-reports/utils/useSubGroupLength';
import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';

const GroupFields = ({ section, label, showLabelIndex }) => {
  const numberOfGroups = useSubGroupLength(label, section.subGroup);
  const indexes = Array.from(Array(numberOfGroups).keys());

  return indexes.map(index =>
    section.fields.map((field, findex) => {
      return (
        <VisibleGroupField
          key={findex}
          field={{
            ...field,
            label: `${field.label}${showLabelIndex ? ` ${index + 1}` : ''}`,
            subLabel: `# ${index + 1}`
          }}
          subGroup={section.subGroup}
          subGroupIndex={index}
        />
      );
    })
  );
};

const FieldsHeader = ({ header }) => (
  <div className="template-group mb-0">
    <div className="template-field mb-0">
      <div className="form-label mb-0">
        <FieldLabel label={header} />
      </div>
    </div>
  </div>
);

const Fields = ({ section, header }) => {
  const isVisible = useSelector(state => hasAtLeastOneVisibleField(state, section.fields));

  return isVisible ? (
    <Col xs={4}>
      <FieldsHeader header={header} />

      <Row className="template-group">
        {section.fields.map((field, i) => (
          <Fragment key={field.key}>
            <VisibleField field={{ ...field, label: null, subLabel: null, width: { xs: '' } }} />
            {i % 2 === 0 ? (
              <Col className="px-0 pb-2 d-flex align-items-center" xs={'auto'}>
                <strong className="fs-12 lh-1">/</strong>
              </Col>
            ) : null}
          </Fragment>
        ))}
      </Row>
    </Col>
  ) : null;
};

const Flowmeters = ({ section }) => {
  const me_booster_unit = section;
  const me_inlet = section.extraFields()[0];
  const me_outlet = section.extraFields()[1];

  const dg_booster_unit = section.extraFields()[2];
  const dg_inlet = section.extraFields()[3];
  const dg_outlet = section.extraFields()[4];

  const me_lo_pumps_no = section.extraFields()[5];
  const main_cooler_sw_pumps_no = section.extraFields()[6];
  const jacket_cooler_pumps_no = section.extraFields()[7];

  const me_ltcw_pumps_no = section.extraFields()[8];
  const me_htcw_pumps_no = section.extraFields()[9];

  const fo_purifiers_no = section.extraFields()[10];
  const lo_purifiers_no = section.extraFields()[11];

  return (
    <div>
      <Row>
        <Col xs={6}>
          <SubGroupHeader
            groups={[me_booster_unit, me_inlet, me_outlet]}
            section={{
              subGroupHeader: me_booster_unit.header,
              subGroupHeaderClassName: 'border-bottom-grey'
            }}
          />
          <Row>
            <Fields section={me_booster_unit} header={'BOOSTER UNIT'} />
            <Fields section={me_inlet} header={'INLET'} />
            <Fields section={me_outlet} header={'OUTLET'} />
          </Row>
        </Col>
        <Col xs={6}>
          <SubGroupHeader
            groups={[dg_booster_unit, dg_inlet, dg_outlet]}
            section={{
              subGroupHeader: dg_booster_unit.header,
              subGroupHeaderClassName: 'border-bottom-grey'
            }}
          />
          <Row>
            <Fields section={dg_booster_unit} header={'BOOSTER UNIT'} />
            <Fields section={dg_inlet} header={'INLET'} />
            <Fields section={dg_outlet} header={'OUTLET'} />
          </Row>
        </Col>
      </Row>

      <SubGroupHeader
        groups={[me_lo_pumps_no, main_cooler_sw_pumps_no, jacket_cooler_pumps_no]}
        section={{
          subGroupHeader: 'PUMPS IN OPERATION DISCHARGE PRESSURE (bar)',
          subGroupHeaderClassName: 'border-bottom-grey'
        }}
      />
      <Row className="template-group">
        <GroupFields section={me_lo_pumps_no} />
        <GroupFields section={main_cooler_sw_pumps_no} />
        <GroupFields section={jacket_cooler_pumps_no} />
      </Row>
      <Row className="template-group">
        <GroupFields section={me_ltcw_pumps_no} />
        <GroupFields section={me_htcw_pumps_no} />
      </Row>

      <SubGroupHeader
        groups={[fo_purifiers_no, lo_purifiers_no]}
        section={{
          subGroupHeader: 'PURIFIERS IN OPERATION',
          subGroupHeaderClassName: 'border-bottom-grey cmb-12'
        }}
      />
      <Row className="template-group">
        <GroupFields section={fo_purifiers_no} showLabelIndex />
        <GroupFields section={lo_purifiers_no} showLabelIndex />
      </Row>
    </div>
  );
};

export default Flowmeters;
