import React, { FC, ReactNode } from 'react';
import TitleLayout from './TitleLayout';
import config from '@/common/components/shared-table-configs/index.ts';

import { Row, Col } from 'reactstrap';
import PageActions from '../PageActions';
import { TableLayoutPageAction } from '@/common/types/front-entities/table';

type SaveTableLayoutProps = {
  pageTitle?: string | ReactNode;
  label: keyof typeof config;
  className?: string;
  headerClassName?: string;
  pageActions?: TableLayoutPageAction[];
};

const SaveTableLayout: FC<SaveTableLayoutProps> = React.memo(function SaveTableLayout({
  pageTitle,
  label,
  className = '',
  headerClassName = '',
  pageActions
}) {
  return (
    <div className={`page-layout page-layout--table ${className}`}>
      <Row className={`align-items-center page-table-layout--header ${headerClassName}`}>
        <Col className="d-flex align-items-center" xs="auto">
          <TitleLayout title={pageTitle} label={label} />
        </Col>

        <PageActions {...{ actions: pageActions, label }} />
      </Row>
    </div>
  );
});

export default SaveTableLayout;
