import React from 'react';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { Row, Col } from 'reactstrap';

const Attachments = ({ disabled, error, value, onChange }) => {
  return (
    <Row noGutters>
      <Col xs={8} className={`attachments-field ${disabled ? 'pointer-events-none' : ''}`}>
        <UploadFiles
          group="forms.submissions"
          onChange={onChange}
          files={value || []}
          error={error}
          size="lg"
        />
      </Col>
    </Row>
  );
};

export default Attachments;
