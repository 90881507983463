import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { components } from 'react-select';

import Select from 'common/components/form/inputs/Select';
import EntityLabel from 'common/components/labels/EntityLabel';
import { CrewOption, CrewSingleValue } from './CrewDepartment';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import departmentIcon from 'common/assets/svg/common/department.svg';

import { DropdownIndicator } from 'common/components/form/inputs/Select';
import * as listActions from 'store/lists/actions';
import { selectListFromStore } from 'store/lists/selectors';
import OnMenuCloseSelector from '../OnMenuCloseSelector';

const DepartmentSelector = ({
  filter = {},
  value,
  onChange,
  isMulti = true,
  id = undefined,
  showIcon = false,
  showMore = true,
  placeholder = filter === undefined ? 'NO DEPARTMENT' : 'Select value',
  boxPlaceholder = undefined,
  withCrewDepartments = false,
  onlyCrewDepartments = false,
  components = {},
  handleOnMenuClose = false,
  allowedDepartmentsIDs = [],
  ...rest
}) => {
  const listKey =
    withCrewDepartments || onlyCrewDepartments ? 'party-and-crew-departments' : 'departments';

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const departments = useSelector(state => selectListFromStore(state, 'departments'));
  const party_n_crew_departments = useSelector(state =>
    selectListFromStore(state, 'party-and-crew-departments')
  );

  useEffect(() => {
    fetchListOptions(listKey);
  }, [listKey, fetchListOptions]);

  const SelectTag = showIcon ? IconSelect : handleOnMenuClose ? OnMenuCloseSelector : Select;

  const getOptions = () => {
    let options = [];
    if (withCrewDepartments) {
      options = party_n_crew_departments.options;
    } else if (onlyCrewDepartments) {
      options = party_n_crew_departments.options.filter(e => e.is_crew_department);
    } else {
      options = departments.options;
    }

    if (allowedDepartmentsIDs?.length && options?.length) {
      options = options.filter(o => allowedDepartmentsIDs.includes(o.id));
    }

    return options;
  };

  const options = getOptions();

  return (
    <SelectTag
      className="department-select"
      placeholder={placeholder}
      components={{
        ...components,
        DropdownIndicator,
        Option,
        MultiValueLabel,
        SingleValue,
        Placeholder
      }}
      icon={showIcon ? departmentIcon : null}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={value !== undefined ? value : filter?.value}
      isMulti={isMulti}
      isClearable
      isEntityLabeled
      onChange={(selected, options) => {
        onChange(
          value !== undefined
            ? selected
            : { value: selected ? (isMulti ? selected.map(s => s) : selected) : '' },
          options
        );
      }}
      boxPlaceholder={boxPlaceholder}
      id={id}
      {...rest}
      options={rest?.options?.length ? rest?.options : options}
    />
  );
};

export const Placeholder = props => {
  return (
    <components.Placeholder
      className={props.selectProps.boxPlaceholder ? 'box-placeholder' : null}
      {...props}
    />
  );
};

export const SingleValue = ({ children, ...props }) =>
  props.data?.is_crew_department ? (
    <CrewSingleValue {...props}>{children}</CrewSingleValue>
  ) : (
    <components.SingleValue {...props}>
      <EntityLabel type="department" color={props.data.color}>
        {props.data.name}
      </EntityLabel>
    </components.SingleValue>
  );

export const MultiValueLabel = ({ children, ...props }) => {
  return (
    <EntityLabel
      type={props.data?.is_crew_department ? 'crew-department' : 'department'}
      color={props.data.color}
    >
      <components.MultiValueLabel {...props}>{children}</components.MultiValueLabel>
    </EntityLabel>
  );
};

export const Option = ({ children, ...props }) => {
  return props.data?.is_crew_department ? (
    <CrewOption {...props}>{children}</CrewOption>
  ) : (
    <components.Option {...props}>
      <EntityLabel type="department" color={props.data.color}>
        {props.data.name}
      </EntityLabel>
    </components.Option>
  );
};

export default DepartmentSelector;
