import { useState, useEffect } from 'react';
import { convertCoordinates, convertAtSeaName } from './helpers';
import { padLeadingZeros } from 'common/utils/numbers';

const RenderValue = ({ name, showCoordsInValue, data, setIsSaved, isSaved }) => {
  const [coordinates, setCoordinates] = useState(data);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (isSaved) {
      setCoordinates(data);
      setIsSaved(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if (firstTime && (data?.lat?.value || data?.lon?.value)) {
      setCoordinates(data);
      setFirstTime(false);
    }
  }, [data?.lat?.value, data?.lon?.value]);

  const handleZeros = value => {
    if (value === 0 || value == 0) {
      return 0;
    }

    return value;
  };

  if (name === 'At Sea' || name === 'At Sea - AT' || name === 'At Sea - ') {
    if (showCoordsInValue) {
      const convertedLatitude = convertCoordinates(coordinates.lat.value, 'latitude');
      const convertedLongitude = convertCoordinates(coordinates.lon.value, 'longitude');

      return (
        <div className="d-flex align-items-center fs-12 text-primary">
          <span className="fw-medium">{convertAtSeaName(name)}</span>
          {coordinates.lat.value || coordinates.lon.value ? (
            <span className="cms-4 cme-4">-</span>
          ) : null}

          {coordinates.lat.value ? (
            <>
              <span>{handleZeros(padLeadingZeros(convertedLatitude?.degrees, 2))}</span>
              <span className="text-violet">° &nbsp;</span>

              <span>{handleZeros(padLeadingZeros(convertedLatitude?.minutes, 2))}</span>
              <span className="text-violet">' &nbsp;</span>

              <span>{convertedLatitude?.direction}</span>
            </>
          ) : null}

          {coordinates.lon.value ? (
            <>
              <span className="cms-4 cme-4">|</span>
              <span>{handleZeros(padLeadingZeros(convertedLongitude?.degrees, 3))}</span>
              <span className="text-violet">° &nbsp;</span>

              <span>{handleZeros(padLeadingZeros(convertedLongitude?.minutes, 2))}</span>
              <span className="text-violet">' &nbsp;</span>

              <span>{convertedLongitude?.direction}</span>
            </>
          ) : null}
        </div>
      );
    } else {
      return convertAtSeaName(name);
    }
  } else {
    return name;
  }
};

export default RenderValue;
