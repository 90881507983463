import clock from 'common/assets/svg/common/clock-line.svg';
import SvgRender from 'common/components/general/SvgRender';

const EmptyState = ({ message = '' }) => {
  return (
    <div className="shifts-filters-empty-state">
      <SvgRender src={clock} className="text-light-gray-2 shifts-filters-empty-state--icon" />
      <div className="shifts-filters-empty-state--text">
        No filtered Shifts.
        <br /> {message}
      </div>
    </div>
  );
};

export default EmptyState;
