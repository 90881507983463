import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';

import Fade from 'react-reveal/Fade';
import { getValue } from './utils';
import FieldHeaderIcon from 'common/components/layouts/profile-data-box/FieldHeaderIcon';
import ResponsiblePersons from '../components/general/ResponsiblePersons';

const MainLayout = ({ config }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const profile = useSelector(selectVesselProfile);

  return (
    <>
      <Fade>
        <Row>
          <Col xs={config?.customComponentWidth ? config?.customComponentWidth : 12}>
            {Object.keys(config).map((el, i) => {
              let item = config[el];

              if (item.customComponent) {
                return <item.customComponent key={i.toString()} fields={item.fields} />;
              }

              return (
                <div key={i.toString()}>
                  {item.header ? (
                    <Row className="mt-2 cmb-4">
                      <Col className="text-end fs-12  fw-bold" xs={4}>
                        <FieldHeaderIcon item={item} />

                        <strong className="fs-10 text-end">
                          {item.subHeader ? item.subHeader : null}
                        </strong>
                      </Col>
                    </Row>
                  ) : null}

                  {item.fields.map(field => {
                    if (field.condition && !field.condition(profile)) return null;

                    if (field.component) {
                      return <field.component key={field.key} field={field} />;
                    }

                    return (
                      <Row
                        key={field.key}
                        className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
                      >
                        <Col
                          className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                          xs={4}
                        >
                          {field.label}
                        </Col>

                        <Col xs={8} className="fs-12  d-flex align-items-start ">
                          {field.render ? (
                            field.render(profile)
                          ) : (
                            <span>{getValue(field, profile)}</span>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              );
            })}
          </Col>
        </Row>
      </Fade>
      {isOnBoard && <ResponsiblePersons />}
    </>
  );
};

export default MainLayout;
