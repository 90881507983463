import React, { useState } from 'react';

import { components } from 'react-select';
import Select from 'common/components/form/inputs/Select';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import Status from 'common/components/general/Status';

const EventStatusSelector = ({ filter, onChange, isMulti, label, placeholder }) => {
  const [selectedEventStatuses, setSelectedEventStatuses] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'event-statuses' },
    { selected: selectedEventStatuses, setSelected: setSelectedEventStatuses }
  );

  return (
    <Select
      label={label}
      placeholder={placeholder ? placeholder : 'Select status'}
      components={{ Option, MultiValue }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      value={
        filter.value
          ? selectedEventStatuses.filter(el => filter.value.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() => getInitialAsyncValues('event-statuses')}
      loadOptions={search => getAsyncOptions(search, 'event-statuses')}
      isMulti={isMulti}
      onChange={(_, selected) =>
        onChange({
          value: isMulti
            ? selected?.length
              ? selected.map(s => s.id)
              : ''
            : selected
            ? selected
            : null
        })
      }
      isClearable
    />
  );
};

export const MultiValue = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      {props.data ? <Status value={props.data} /> : null}
    </components.MultiValue>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data ? <Status value={props.data} /> : null}
    </components.Option>
  );
};

export default EventStatusSelector;
