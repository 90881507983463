import TYPES from './types';
import CAPTAIN_REPORT_TYPES from 'captain-reports/store/types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

import {
  setInvalidReport,
  setReportOnBoard,
  setReportVessel,
  setReportData,
  setReportTemplateData,
  setReportTemplateTooltips,
  setReportTemplateVisibleFields,
  setReportTemplateValidationFields,
  setReportTotals,
  setPreviousReport,
  setReportNavigation,
  setReportPreviousBsViscosities,
  setReportBunkering,
  setReportPreviousBsBioFuels
} from 'captain-reports/store/actions';

export const createReport = params => dispatch => {
  dispatch({ type: TYPES.CREATE_REPORT.START, payload: { params } });

  return post('/captain-reports', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_REPORT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_REPORT.ERROR, payload: error });
      throw error;
    });
};

export const updateReport = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_REPORT.START, payload: { params } });

  return put(`/captain-reports/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_REPORT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Report updated successfully' }));
      dispatch({ type: CAPTAIN_REPORT_TYPES.CHANGE_REPORT_STATUS, payload: params.status });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_REPORT.ERROR, payload: error });
    });
};

export const deleteReport = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.DELETE_REPORT.START, payload: { params } });

  return deleteRequest(`/captain-reports/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_REPORT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Report deleted successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_REPORT.ERROR, payload: error });
      throw error;
    });
};

export const getReportTotals = (params, type) => dispatch => {
  dispatch({ type: TYPES.GET_REPORT_TOTALS.START, payload: { params } });

  return get(`/captain-reports/summary`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_REPORT_TOTALS.SUCCESS, payload: response.data });

      switch (type) {
        case 'totals':
          return dispatch(setReportTotals(response.data.totals));
        case 'previous':
          return dispatch(setPreviousReport(response.data.previous));
        case 'navigation':
          return dispatch(setReportNavigation(response.data.navigation));
        default:
          dispatch(setReportTotals(response.data.totals));
          dispatch(setPreviousReport(response.data.previous));
          dispatch(setReportNavigation(response.data.navigation));
          dispatch(setReportPreviousBsViscosities(response.data.previous_bs_viscosities));
          dispatch(setReportPreviousBsBioFuels(response.data.previous_biofuel_cfs));
      }

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_REPORT_TOTALS.ERROR, payload: error });
      throw error;
    });
};

export const getReport = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_REPORT.START, payload: { params } });

  return get(`/captain-reports/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_REPORT.SUCCESS, payload: response.data });

      const { template, vessel, ...reportData } = response.data;
      const { fields, ...templateData } = template;

      dispatch(setReportOnBoard(params.on_board));
      dispatch(setReportVessel({ vessel, template, initialValues: reportData }));
      dispatch(setReportData(reportData));
      dispatch(setReportTemplateData(templateData));
      dispatch(setReportTemplateTooltips(fields));
      dispatch(setReportTemplateVisibleFields(fields));
      dispatch(setReportTemplateValidationFields(fields));
      dispatch(setInvalidReport(false));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_REPORT.ERROR, payload: error });
      dispatch(setInvalidReport(true));

      throw error;
    });
};

export const getLastReportType = params => dispatch => {
  dispatch({ type: TYPES.GET_LAST_REPORT_TYPE.START, payload: { params } });

  return get(`/captain-reports/last`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_LAST_REPORT_TYPE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_LAST_REPORT_TYPE.ERROR, payload: error });

      throw error;
    });
};

export const canCreateNewReport = params => dispatch => {
  dispatch({ type: TYPES.CAN_CREATE_NEW_REPORT.START, payload: { params } });

  return get(`/captain-reports/can-create`, params)
    .then(response => {
      dispatch({ type: TYPES.CAN_CREATE_NEW_REPORT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CAN_CREATE_NEW_REPORT.ERROR, payload: error });
      throw error;
    });
};

export const getCaptainReportBunkering = (params, type) => dispatch => {
  dispatch({ type: TYPES.GET_CAPTAIN_REPORT_BUNKERING.START, payload: { params } });
  let { report_id, ...rest } = params;

  return get(`/captain-reports/${report_id}/bunkering`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_CAPTAIN_REPORT_BUNKERING.SUCCESS, payload: response.data });
      dispatch(setReportBunkering(response.data));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CAPTAIN_REPORT_BUNKERING.ERROR, payload: error });
      throw error;
    });
};
