import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import brokenPage from 'common/assets/svg/common/errorIcon.svg';
import Bugsnag from '@bugsnag/js';
import sync from 'common/assets/svg/actions/sync.svg';

class ErrorWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Bugsnag.notify(error);
  }

  onClick = () => {
    this.setState({ hasError: false });
    this.props.onReload();
  };

  render() {
    const { maxHeight } = this.props;
    const style = maxHeight ? { maxHeight } : {};

    if (this.state.hasError) {
      return (
        <div
          style={style}
          className="modular-error-wrapper d-flex align-items-center justify-content-around flex-column h-100p"
        >
          <SvgRender
            className="justify-content-center"
            src={brokenPage}
            style={{ width: '60%', height: '60%' }}
          />

          <div>
            <div className="fs-20 mb-2  text-violet fw-medium">{this.props.text}</div>
            {this.props.onReload && (
              <div
                onClick={this.onClick}
                className="fs-16 fw-medium text-purple d-flex flex-column justify-content-center align-items-center cursor-pointer"
              >
                <span>Reload</span>
                <SvgRender className="mt-1" src={sync} style={{ width: 16, height: 16 }} />
              </div>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorWrapper;
