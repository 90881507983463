import React from 'react';
import Radio from 'common/components/form/inputs/Radio';
import PropTypes from 'prop-types';

const PreExistingRadios = ({ selectField, fields }) => {
  return (
    <div>
      <div className="fs-10 text-primary cmb-4">
        <strong>PRE EXISTING (CHRONIC) CONDITION</strong>
      </div>
      <div className="d-flex">
        <Radio
          className="me-3 mb-0"
          name="yes"
          label="Yes"
          checked={fields.pre_existing_condition?.value === 'yes'}
          onChange={() => selectField('pre_existing_condition')('yes')}
        />
        <Radio
          className="me-3 mb-0"
          name="no"
          label="No"
          checked={fields.pre_existing_condition?.value === 'no'}
          onChange={() => selectField('pre_existing_condition')('no')}
        />
        <Radio
          className="mb-0"
          name="dont_know"
          label="Don't know"
          checked={fields.pre_existing_condition?.value === 'dont_know'}
          onChange={() => selectField('pre_existing_condition')('dont_know')}
        />
      </div>
    </div>
  );
};

PreExistingRadios.propTypes = {
  selectField: PropTypes.func,
  fields: PropTypes.object
};

export default PreExistingRadios;
