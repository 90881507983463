import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_VESSEL_SYSTEM_MAINTENANCE_JOBS'),
  ...asyncTypes('FETCH_PMS_JOB_HISTORY'),
  ...asyncTypes('CHANGE_JOB_STATUSES'),
  SET_SELECTED_VESSELS: 'SET_SELECTED_VESSELS',
  SET_EXPANDED_ASSIGNMENT: 'SET_EXPANDED_ASSIGNMENT',
  TOGGLE_JOB_DRAWER: 'TOGGLE_JOB_DRAWER',
  SET_ALL_EXPANDED: 'SET_ALL_EXPANDED',
  SET_SELECTED_VESSEL_FILTER: 'SET_SELECTED_VESSEL_FILTER',
  SET_SELECTED_SYSTEM_FILTER: 'SET_SELECTED_SYSTEM_FILTER'
};

export default TYPES;
