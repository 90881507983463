import React, { useState, useEffect } from 'react';

import TagInput from 'common/components/general/Tags';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { isUserAllowedToEdit } from 'common/components/jobs/_base/permissions/helpers';
import { selectIfFieldIsVisible, selectRestrictedFields } from 'store/jobs-fields/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import useDebounce from 'common/utils/hooks/useDebounce';
import _isEqual from 'lodash/isEqual';

const Tags = ({ avoidCheck = false }) => {
  const [setJobField, setJobFieldError] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError
  ]);
  const tags = useSelector(state => selectJobField(state, 'tags'));
  const typeOfLoggedInUser = useSelector(state => selectJobField(state, 'typeOfLoggedInUser'));
  const jobTagsError = useSelector(state => selectJobFieldError(state, 'tags'));
  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));
  const restrictedFields = useSelector(state =>
    selectRestrictedFields(state)?.find(item => item.field === 'tags')
  );

  const isVisible = useSelector(selectIfFieldIsVisible('tags'));

  const [inputTags, setInputTags] = useState(tags);
  const debouncedTags = useDebounce(inputTags, 1000);

  useEffect(() => {
    if (!_isEqual(debouncedTags, tags)) setJobField('tags', debouncedTags, true);
  }, [debouncedTags]);

  useEffect(() => {
    if (!_isEqual(inputTags, tags)) setInputTags(tags);
  }, [tags]);

  if (!isVisible) return null;

  return (
    <>
      <TagInput
        tags={inputTags}
        onChange={v => {
          if (jobTagsError && v.length) setJobFieldError({ tags: null });
          setInputTags(v);
        }}
        disabled={
          !isUserAllowedToEdit({
            restrictedFields,
            typeOfLoggedInUser,
            avoidCheck
          }) || isJobLocked
        }
        label={'Tags'}
        type="job"
        className="mb-0"
      />
      {jobTagsError ? <div className="fs-10 text-coral">*{jobTagsError}</div> : null}
    </>
  );
};

export default Tags;
