import { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import useRouter from 'use-react-router';

import Priority from 'common/components/general/Priority';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import DueOnRh from 'common/components/pms/jobs/components/DueOnRh';
import JobStatus from 'common/components/pms/jobs/components/JobStatus';
import JobLink from 'common/components/jobs/maintenance/components/JobLink';
import SvgRender from 'common/components/general/SvgRender';

import { selectMaintenanceJob } from 'common/components/pms/jobs/store/selectors';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { numberToStr } from 'common/utils/numbers';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import moment from 'moment';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import clock from 'common/assets/svg/common/clock-line.svg';
import paths from 'routing/routes/_paths';
import historyIcon from 'common/assets/svg/common/history.svg';
import { periodicityEnums } from 'common/utils/fixed';

const TableRow = ({ jobID, filterPrototypeJobs }) => {
  const job = useSelector(state => selectMaintenanceJob(state, jobID));
  const jobStatuses = useSelector(selectJobStatuses);
  const status = jobStatuses.find(st => st.id === job.status_id);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const { history } = useRouter();
  const [updateJob] = useActions([jobProfileActions.updateJob]);

  const onJobUpdate = useCallback(
    params => updateJob({ id: jobID, ...params }),
    [jobID, updateJob]
  );

  return (
    <Row
      className="mb-1 pe-2 cps-4 cpt-4 cpb-4 fs-12 fw-medium text-primary bg-light-gray rounded cursor-pointer align-items-center"
      noGutters
    >
      <Col xs={6} className="d-flex flex-nowrap overflow-hidden align-items-center">
        <Row className="w-100p me-5 overflow-hidden">
          <div className="width-32 d-flex ps-1 cpb-2 justify-content-center align-items-center">
            <Priority value={job.importance_id} withText={false} />
          </div>
          <Col xs={9}>
            <JobLink
              isPms={true}
              linkClassName="link-bold text-decoration-none text-primary"
              job={job}
              attributes={{
                is_class: job.is_class,
                is_critical: job.is_critical,
                is_environmental_critical: job.is_environmental_critical
              }}
              onClick={e => {
                e.preventDefault();

                history.replace({
                  pathname: `${paths.pms_jobs_per_system}/${job.id}`,
                  search: history.location.search
                });
              }}
            />
          </Col>

          <Col xs={2} className="pe-0 d-flex justify-content-start">
            <div>
              <JobStatus status={status} onJobUpdate={onJobUpdate} />
            </div>
          </Col>
        </Row>
      </Col>

      <Col className="overflow-hidden pe-1">
        <TextWithTooltip>{job.job_type_name}</TextWithTooltip>
      </Col>
      <Col className="overflow-hidden">
        {job.periodicity_description ? (
          job.periodicity_description
        ) : (
          <div className="d-flex text-violet fs-12 align-items-center">
            <SvgRender src={clock} style={{ width: 13, height: 13 }} className="me-1" />
            <div className="text-violet text-truncate w-100p">
              <TextWithTooltip>Unplanned Job</TextWithTooltip>
            </div>
          </div>
        )}
      </Col>
      <Col>{job.last_done_at ? moment(job.last_done_at).format('DD/MM/YYYY') : ''}</Col>
      <Col className="d-flex align-items-center justify-content-between">
        {job?.periodicity?.type === periodicityEnums.as_needed ? null : (
          <DueDate
            className="fs-12"
            dueDate={job.due_date}
            dueDateChanged={job.due_date_changed_at || job.due_on_rh_changed_at}
            showTime={job.due_date_show_time}
            createdAt={job.created_at}
            status={status}
            condition={{
              is_becoming_due_date: job.is_becoming_due_date,
              is_overdue_date: job.is_overdue_date
            }}
          />
        )}
      </Col>
      <Col className="text-end">{numberToStr(job.last_done_rh)}</Col>

      <Col className="d-flex justify-content-end ">
        <DueOnRh
          className="fs-12"
          dueOnRh={job?.due_on_rh}
          condition={{
            is_becoming_due_rh: job.is_becoming_due_rh,
            is_overdue_rh: job.is_overdue_rh
          }}
        />
      </Col>
      <Col xs={'auto'}>
        <div className="width-48 d-flex align-items-center justify-content-end">
          {!isOnBoard && job?.prototype_id && (
            <div
              className="text-purple cursor-pointer d-inline-flex"
              onClick={() => filterPrototypeJobs(job.prototype_id)}
            >
              <SvgRender src={historyIcon} style={{ width: 14, height: 14 }} />
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default TableRow;
