import { formatDaysDuration } from 'common/utils/dates';

const Label = ({ value, variant, valueClassName, variantClassName, showFullDuration }) => (
  <>
    {showFullDuration ? (
      <div>
        <strong className={valueClassName || 'fs-18 lh-20 fw-medium'}>{value}</strong>
        <span className={variantClassName || 'fs-12 lh-13 fw-medium'}>
          {variant} {variant === '.' || variant === ' years' ? '' : <span>&nbsp;&nbsp;</span>}
        </span>
      </div>
    ) : value ? (
      <div>
        <strong className={valueClassName || 'fs-12 text-primary'}>{value}</strong>
        <strong className={variantClassName || 'fs-12 text-primary'}>
          {variant
            .split(' ')
            .map(item => item.substring(0, 1))
            .join('')}
          {variant === '.' || variant === ' years' ? '' : <span>&nbsp;</span>}
        </strong>
      </div>
    ) : null}
  </>
);

const PreviewDuration = ({
  durationInDays,
  className = '',
  variantClassName,
  valueClassName,
  format,
  showFullDuration = true
}) => {
  const { years, months, days } = formatDaysDuration(durationInDays, format);

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {durationInDays === '-' ? '-' : null}
      {years ? (
        <Label
          {...years}
          variantClassName={variantClassName}
          valueClassName={valueClassName}
          showFullDuration={showFullDuration}
        />
      ) : null}
      {months ? (
        <Label
          {...months}
          variantClassName={variantClassName}
          valueClassName={valueClassName}
          showFullDuration={showFullDuration}
        />
      ) : null}
      {days ? (
        <Label
          {...days}
          variantClassName={variantClassName}
          valueClassName={valueClassName}
          showFullDuration={showFullDuration}
        />
      ) : null}
    </div>
  );
};

export default PreviewDuration;
