import JobProfileDrawer from 'common/components/jobs/regular/drawer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedJob } from 'common/components/jobs/_base/store/selectors';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { Button } from 'reactstrap';

import { createJob } from 'common/components/jobs/_base/store/actions';

const LinkedJobDrawer = ({ drawer, jobPreviewState, isCreatingJob, addLinkedJob }) => {
  const selectedJob = useSelector(selectSelectedJob);
  const dispatch = useDispatch();

  const onCreate = async () => {
    try {
      const res = await dispatch(createJob());

      addLinkedJob(res);
      drawer.toggle();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onLink = () => {
    if (!selectedJob?.id) return;

    addLinkedJob(selectedJob);
    drawer.toggle();
  };

  return (
    <JobProfileDrawer
      drawer={drawer}
      jobID={null}
      footer={
        <div className="d-flex align-items-center">
          <Button color="cancel" className="p-0 fs-10 me-2 ms-auto" onClick={() => drawer.toggle()}>
            CANCEL
          </Button>
          {jobPreviewState?.id ? null : (
            <Button
              color="primary"
              className="px-2 height-28 fs-10 d-flex align-items-center me-2"
              onClick={isCreatingJob ? onCreate : onLink}
            >
              {isCreatingJob ? 'CREATE' : 'LINK'}
            </Button>
          )}
        </div>
      }
      isJobLinkingView={!isCreatingJob && !jobPreviewState?.id}
      isViewOnly={!isCreatingJob}
      drawertTitle={
        isCreatingJob
          ? 'Create new Job'
          : jobPreviewState?.id
          ? jobPreviewState?.title
          : 'Link to existing Job'
      }
    />
  );
};

LinkedJobDrawer.propTypes = {
  drawer: DrawerState,
  jobPreviewState: PropTypes.object,
  isCreatingJob: PropTypes.bool,
  addLinkedJob: PropTypes.func
};

export default LinkedJobDrawer;
