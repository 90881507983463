import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import Status from 'common/components/general/Status';

const StatusChange = props => {
  const { properties } = props;

  return (
    <div className="d-flex align-items-center">
      <Status value={properties.old.status_id} />
      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <Status value={properties.attributes.status_id} />
    </div>
  );
};

export default StatusChange;
