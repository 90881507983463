import { createSelector } from 'reselect';
import { jobEnums } from 'common/utils/fixed';
import { selectJobType } from 'common/components/jobs/_base/store/selectors';

const selectJobsReducer = state => state.jobs;

const selectFlagExtension = createSelector([selectJobsReducer], jobs => jobs.flag_extension);

export const selectFlagExtensionAttachments = state => selectFlagExtension(state)?.attachments;

export const selectFlagExtensionCompletedOn = state => selectFlagExtension(state)?.completed_at;

export const selectFlagExtensionInformedOn = state => selectFlagExtension(state)?.informed_at;

export const selectFlagExtensionRequestedOn = state => selectFlagExtension(state)?.requested_on;

export const selectFlagExtensionTypeOfExtensionOrDispensation = state =>
  selectFlagExtension(state)?.type;

export const selectFlagExtensionCountry = state => selectFlagExtension(state)?.country;

export const selectFlagExtensionRequestedFor = state => selectFlagExtension(state)?.requested_for;

export const selectIsFlagDispensationJob = state =>
  selectJobType(state) === jobEnums.flag_dispensation;

export const selectIsUnscheduledJob = state => selectJobType(state) === jobEnums.unscheduled;
