import styled from '@emotion/styled';
import Table from 'common/components/table';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const LinkedEntitiesTable = ({ ...table }) => {
  return (
    <Container>
      <Table
        hideTableFilters
        hideTopPagination
        hideBottomPagination
        hideTableSearch
        grayBg
        {...table}
      />
    </Container>
  );
};

// Horizontal scroll is prevented
const Container = styled.div`
  .app-table--head,
  .app-table--body,
  .app-table--footer {
    max-width: 100%;
  }

  .app-table--main {
    margin: 0 -${variables.size8};
    padding: 0 ${variables.size8};
  }
`;

export default LinkedEntitiesTable;
