import React, { useState } from 'react';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import clock from 'common/assets/svg/common/reminder.svg';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import { DocumentTypeReminders } from 'common/entities/vessels/VesselDocumentReminderTypes';
import _get from 'lodash/get';

export const generateVesselDocumentTyoeRemindersInfo = type => {
  const reminders = [];

  (_get(type, 'reminders') || []).forEach(r => {
    if (r?.reminder?.dates?.length > 0) {
      r.reminder.dates.forEach(({ days_before, hours_before, months_before }) => {
        if (days_before > 0) {
          reminders.push({ value: days_before, unit: 'day' });
        } else if (hours_before > 0) {
          reminders.push({ value: hours_before, unit: 'hour' });
        } else if (months_before > 0) {
          reminders.push({ value: months_before, unit: 'month' });
        }
      });
    }
  });

  return reminders;
};

const DocumentRemindersTooltip = ({ reminders }) => {
  const { avoidRender, tooltipID } = useTooltipID('doc-reminders');
  const [remindersState, setRemindersState] = useState(null);

  if (!reminders?.length) return null;

  const parseRemindersState = () => {
    if (!remindersState) {
      setRemindersState(generateVesselDocumentTyoeRemindersInfo({ reminders }));
    }
  };

  return (
    <>
      <div
        className="d-inline-flex align-items-center cursor-pointer width-16"
        id={tooltipID}
        onMouseOver={parseRemindersState}
      >
        <SvgRender src={clock} style={{ width: 14, height: 14 }} className="text-violet" />
      </div>

      {avoidRender || !remindersState ? null : (
        <Tooltip
          className="fs-12 text-nowrap"
          innerClassName="min-width-180 max-width-fit text-start"
          target={tooltipID}
        >
          <div className="border-bottom border-white fw-bold lh-1 mb-1 pb-1">Reminders</div>
          <div>
            {remindersState.map(({ value, unit }, index) => (
              <div key={index}>
                •{' '}
                <strong>
                  {value} {`${unit}${value > 0 ? 's' : ''}`}
                </strong>{' '}
                before exp. date
              </div>
            ))}
          </div>
        </Tooltip>
      )}
    </>
  );
};

DocumentRemindersTooltip.propTypes = {
  reminders: DocumentTypeReminders
};

export default DocumentRemindersTooltip;
