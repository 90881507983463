import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr } from 'common/utils/numbers';
import { useActions } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';
import useReportTimeDiff from 'captain-reports/utils/useReportTimeDiff';

import {
  selectGeneratorsLoConsumptionTotal,
  selectGeneratorsRunningHoursTotal,
  selectCaptainReportTemplateType,
  selectFieldVisibility
} from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';

const LubricantsConsumptionInput = ({ field }) => {
  const captainReportTemplateType = useSelector(selectCaptainReportTemplateType);
  const mins_since_last_report = useReportTimeDiff();
  const [average, setAverage] = useState('');

  const [setValue] = useActions([reportActions.setValue]);
  const generatorsLoConsumptionTotal = useSelector(selectGeneratorsLoConsumptionTotal);
  const generatorsRunningHoursTotal = useSelector(selectGeneratorsRunningHoursTotal);
  const isLubOilConsumptionTotalVisible = useSelector(state =>
    selectFieldVisibility(state, 'dg_lub_oil_consumption_total')
  );

  const lastTotalWordRegex = /\_total\b$/;

  const rateKey = `${field.key.replace(lastTotalWordRegex, '_rate')}`;

  let steamingTimeValue = useValue({ key: 'steaming_time' });
  const value = useValue({ key: field.key });

  const steamingTime =
    captainReportTemplateType === 'port_noon' ? mins_since_last_report : steamingTimeValue;

  useEffect(() => {
    if (
      field.key === 'go_consumption_total' &&
      (generatorsLoConsumptionTotal || generatorsLoConsumptionTotal === 0) &&
      isLubOilConsumptionTotalVisible
    ) {
      setValue({
        key: field.key,
        value: generatorsLoConsumptionTotal
      });
    }
  }, [generatorsLoConsumptionTotal]);

  useEffect(() => {
    let result = null;
    let cons = value ? value : 0;

    if (field.key === 'go_consumption_total') {
      if (!generatorsRunningHoursTotal) {
        setAverage('-');
        setValue({
          key: rateKey,
          value: null
        });
        return;
      }

      const generatorHours = generatorsRunningHoursTotal / 60;

      // go_consumption_rate = go_consumption_total * 24 / sum(dg_running_minutes / 60)
      result = (cons * 24) / generatorHours;
    } else {
      if (!steamingTime) {
        setAverage('-');
        setValue({
          key: rateKey,
          value: null
        });
        return;
      }

      let steamingTimeHours = steamingTime / 60;

      result = (cons * 24) / steamingTimeHours;
    }

    if (cons !== 0) {
      result = +result.toFixed(4);
    }

    setAverage(result);
    setValue({
      key: rateKey,
      value: result
    });
  }, [steamingTime, generatorsRunningHoursTotal, value]);

  return (
    <Row noGutters>
      <Col
        xs={6}
        className={`${
          field.key === 'co_total_consumption_total'
            ? 'cpe-6'
            : field.key === 'co_total_leg_total_consumption_total'
            ? 'cpe-4'
            : field.key === 'go_consumption_total' && isLubOilConsumptionTotalVisible
            ? 'readonly-input'
            : 'pe-1'
        }`}
      >
        <NumberField field={field} />
      </Col>

      <Col className="d-flex align-items-center justify-content-end fs-10 fw-bold" xs={6}>
        {numberToStr(average)}&nbsp;<span className="text-violet">L/day</span>
      </Col>
    </Row>
  );
};

export default LubricantsConsumptionInput;
