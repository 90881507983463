import React from 'react';

import { Row, Col } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import comments from 'common/assets/svg/common/comments.svg';

const WorkingScheduleTableHeader = () => {
  return (
    <Row noGutters className="working-schedule__table-header">
      <Col xs={4} className="ps-2">
        RANK
      </Col>
      <Col xs={2} className="ps-1">
        <div className="working-schedule__table-header--label bg-primary">
          SCHEDULED DAILY WORK&nbsp;<b>AT SEA</b>
        </div>
        <Row noGutters>
          <Col xs={6} className="d-flex justify-content-center">
            WATCHKEEPING
          </Col>
          <Col xs={6} className="d-flex justify-content-center">
            NON-WATCHKEEPING
          </Col>
        </Row>
      </Col>
      <Col xs={2} className="ps-1">
        <div className="working-schedule__table-header--label bg-violet">
          SCHEDULED DAILY WORK&nbsp;<b>AT PORT</b>
        </div>
        <Row noGutters>
          <Col xs={6} className="d-flex justify-content-center">
            WATCHKEEPING
          </Col>
          <Col xs={6} className="d-flex justify-content-center">
            NON-WATCHKEEPING
          </Col>
        </Row>
      </Col>
      <Col xs={2} className="ps-1 d-flex align-items-center">
        <SvgRender src={comments} style={{ height: 14, width: 14 }} className="me-1" />
        <span>COMMENTS</span>
      </Col>
      <Col xs={2} className="ps-1">
        <div className="working-schedule__table-header--label">
          TOTAL DAILY&nbsp;<b>REST HOURS</b>
        </div>
        <Row noGutters>
          <Col xs={6} className="cps-12">
            AT SEA
          </Col>
          <Col xs={6}>AT PORT</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default WorkingScheduleTableHeader;
