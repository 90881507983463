import NumberField from 'captain-reports/templates/inputs/NumberField';
import React from 'react';
import {
  autocompleteTotalSludgeProduced,
  autocompleteTotalSludgeConsumed,
  autocompleteSludgeTotalRob
} from 'captain-reports/templates/config/_autocompleteHelpers';
import TimeInput from 'captain-reports/templates/inputs/TimeInput';
import SludgeRob from 'captain-reports/templates/components/custom-fields/SludgeRob';
import TotalProducedField from 'captain-reports/templates/components/custom-fields/TotalProducedField';
import TotalRemovedField from 'captain-reports/templates/components/custom-fields/TotalRemovedField';

const noon = () => [
  {
    fields: [
      {
        label: 'Incinerator Running hours',
        key: 'incinerator_running_minutes',
        width: { xs: 3 },
        render: TimeInput
      }
    ]
  },
  {
    header: (
      <div className="w-100p incinerator-separator">
        SLUDGE (m<sup>3</sup>)
      </div>
    ),
    headerClassName: 'border-bottom-grey',
    fields: [
      {
        label: 'From main engine use',
        key: 'sludge_produced_me',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'From maint. & overhaulings',
        key: 'sludge_produced_maintenance',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total produced',
        key: 'sludge_produced_total',
        autocompleteValues: [{ key: 'sludge_produced_me' }, { key: 'sludge_produced_maintenance' }],
        autocomplete: autocompleteTotalSludgeProduced,
        width: { xs: 3 },
        className: 'ms-auto ps-2 incinerator-after-element',
        render: TotalProducedField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Incinerated',
        key: 'sludge_removed_incinerated',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Evaporated',
        key: 'sludge_removed_evaporated',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total',
        key: 'sludge_removed_total',
        className: 'ms-auto ps-2 incinerator-after-element',
        autocompleteValues: [
          { key: 'sludge_removed_incinerated' },
          { key: 'sludge_removed_evaporated' }
        ],
        autocomplete: autocompleteTotalSludgeConsumed,
        width: { xs: 3 },
        render: TotalRemovedField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Total Rob',
        className: 'ms-auto ps-2',
        key: 'sludge_rob',
        width: { xs: 3 },
        autocompleteValues: [
          { key: 'sludge_produced_total' },
          { key: 'sludge_removed_total' },
          { previousReportKey: 'sludge_rob' },
          { key: 'sludge_rob' }
        ],
        type: 'number',
        autocomplete: autocompleteSludgeTotalRob,
        render: SludgeRob
      }
    ]
  }
];

const config = () => [
  {
    fields: [
      {
        label: 'Incinerator Running hours',
        key: 'incinerator_running_minutes',
        width: { xs: 3 },
        render: TimeInput
      }
    ]
  },
  {
    header: (
      <div>
        SLUDGE (m<sup>3</sup>)
      </div>
    ),
    headerClassName: 'border-bottom-grey',
    fields: [
      {
        label: 'From main engine use',
        key: 'sludge_produced_me',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'From maint. & overhaulings',
        key: 'sludge_produced_maintenance',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total produced',
        key: 'sludge_produced_total',
        autocompleteValues: [{ key: 'sludge_produced_me' }, { key: 'sludge_produced_maintenance' }],
        autocomplete: autocompleteTotalSludgeProduced,
        width: { xs: 3 },
        className: 'ms-auto ps-2 incinerator-after-element',
        render: TotalProducedField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Incinerated',
        key: 'sludge_removed_incinerated',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Evaporated',
        key: 'sludge_removed_evaporated',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Delivered ashore',
        key: 'sludge_removed_delivered_ashore',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total Removed',
        key: 'sludge_removed_total',
        className: 'ms-auto ps-2 incinerator-after-element',
        autocompleteValues: [
          { key: 'sludge_removed_incinerated' },
          { key: 'sludge_removed_evaporated' },
          { key: 'sludge_removed_delivered_ashore' }
        ],
        autocomplete: autocompleteTotalSludgeConsumed,
        width: { xs: 3 },
        render: TotalRemovedField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Total Rob',
        className: 'ms-auto ps-2',
        key: 'sludge_rob',
        width: { xs: 3 },
        type: 'number',
        autocompleteValues: [
          { key: 'sludge_produced_total' },
          { key: 'sludge_removed_total' },
          { previousReportKey: 'sludge_rob' },
          { key: 'sludge_rob' }
        ],
        autocomplete: autocompleteSludgeTotalRob,
        render: SludgeRob
      }
    ]
  }
];

const incineratorSludgesConfig = {
  noon: data => noon(data),
  arrival: data => config(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => config(data),
  port_noon: data => config(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default incineratorSludgesConfig;
