import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useActions } from 'utils/hooks';
import {
  selectJobField,
  selectIsJobLocked,
  checkIfEditMode
} from 'common/components/jobs/_base/store/selectors';

import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';
import ImportanceDropdownSelector from 'common/components/selectors/ImportanceDropdownSelector';

const Importance = ({ hideLabel, avoidCheck = false }) => {
  const importanceId = useSelector(state => selectJobField(state, 'importance_id'));
  const isVisible = useSelector(selectIfFieldIsVisible('importance_id'));
  const isJobLocked = useSelector(state => selectIsJobLocked(state, avoidCheck ? false : true));
  const isOnboard = useSelector(state => state.isOnBoard);
  const isEditMode = useSelector(checkIfEditMode);

  const [setJobField] = useActions([jobProfileActions.setJobField]);

  const onImportanceChange = (_, v) => {
    setJobField('importance_id', v?.id, true);
  };

  const FieldComponent = ({ ...innerRest }) => {
    return (
      <ImportanceDropdownSelector
        list="job-importances"
        value={importanceId}
        onChange={onImportanceChange}
        initializeWhennEmtpy={!isEditMode && !isJobLocked}
        {...innerRest}
      />
    );
  };

  if (!isVisible) return null;

  return (
    <UserCanEdit
      field="importance"
      fallback={() => <FieldComponent disabled />}
      avoidCheck={avoidCheck}
    >
      <FieldComponent disabled={isJobLocked || isOnboard} />
    </UserCanEdit>
  );
};

export default Importance;

Importance.propTypes = {
  hideLabel: PropTypes.bool,
  hideImportanceLabel: PropTypes.bool,
  avoidCheck: PropTypes.bool
};
