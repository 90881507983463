export const getEvaluationCriteriaValues = value => {
  switch (value.criteria.audit_evaluation_type) {
    case 'scale':
      return { scale: value.values || null };

    case 'trainings':
      return { trainings: value.values || [] };

    case 'radio-button-with-text-input':
      return { radios: value.values, text: value.remarks };

    default:
      return null;
  }
};

export const parseEvaluationFormParams = (values, id) => {
  const { criteria, ...rest } = values;

  const params = {
    event_audit_id: id,
    values: (criteria || [])?.map(criterion => {
      let criterionValue = null;

      switch (criterion.type) {
        case 'scale':
          criterionValue = criterion.scale || null;
          break;

        case 'trainings':
          criterionValue = criterion.trainings?.map(training => training.id) || null;
          break;

        case 'radio-button-with-text-input':
          criterionValue = criterion.radios;
          break;

        default:
          return null;
      }

      return {
        id: criterion.id,
        value: criterionValue,
        remarks: criterion?.text
      };
    }),
    ...rest
  };

  return params;
};

export const getInitialEvaluationValues = (audit, evaluationCriteria) => {
  let values = {};

  if (audit.evaluation) {
    const { evaluation } = audit;

    values = {
      remarks: evaluation.remarks,
      criteria: (evaluation?.evaluation_values || [])?.map(value => {
        return {
          ...getEvaluationCriteriaValues(value),
          ...value?.criteria,
          type: value?.criteria?.audit_evaluation_type
        };
      })
    };
  } else {
    values = {
      criteria: (evaluationCriteria || [])?.map(criterion => {
        return {
          ...criterion,
          type: criterion?.audit_evaluation_type
        };
      })
    };
  }

  return values;
};
