import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _find from 'lodash/find';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import {
  selectConsumptionFuelGrade,
  selectCaptainReportRobDifferences
} from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';

const CorrectionWarning = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const fuel_grade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const robDifferenceData = useSelector(selectCaptainReportRobDifferences);
  const constructKey = field.key !== 'correction' ? `_${field.key.split('_')[1]}` : '';

  // Select the corresponding (lfo / hfo) rob_difference key.
  const robDifferenceKey = `rob_difference${constructKey}`;
  const fuelGradeDifference = _find(robDifferenceData, e => e.fuel_grade_id === fuel_grade.id);
  const bunkerSurveyValue = strToNumber(fuelGradeDifference?.[robDifferenceKey]);

  const isHidden = fuel_grade?.category === 'go' && field.key !== 'correction';

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const color = useMemo(() => {
    if (bunkerSurveyValue === null) {
      return '';
    } else if (strToNumber(value) === bunkerSurveyValue) {
      return 'text-turquoise';
    } else {
      return 'text-red';
    }
  }, [value, bunkerSurveyValue]);

  return (
    <div className={`${isHidden ? 'd-none' : ''} text-end fs-12 ${color} pe-1`}>
      {numberToStr(bunkerSurveyValue)}
    </div>
  );
};

export default CorrectionWarning;
