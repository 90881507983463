import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import lock from 'common/assets/svg/common/lock.svg';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const FormBaseStatusLabel = ({
  value,
  color,
  className,
  isLocked,
  lockedTooltipText,
  showLockIcon
}) => {
  const { tooltipID, avoidRender } = useTooltipID('form-status-label-locked-tooltip');

  if (avoidRender) return null;

  return (
    <div
      id={tooltipID}
      className={`d-flex fs-8 align-items-center status-single status-single--${
        color || 'default'
      } fw-bold d-flex text-uppercase ${className ? className : ''} ${
        isLocked || showLockIcon ? 'pointer-events-auto' : ''
      }`}
    >
      {value}

      {showLockIcon || isLocked ? (
        <>
          <span className="ms-2 d-flex align-items-center">
            <SvgRender src={lock} style={{ height: 14, width: 11 }} />
          </span>
          {lockedTooltipText ? (
            <Tooltip
              target={tooltipID}
              innerClassName="min-width-fit max-width-fit"
              placement={'top-start'}
            >
              {lockedTooltipText}
            </Tooltip>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default FormBaseStatusLabel;
