import PropTypes from 'prop-types';

import OriginIcon from 'common/components/findings/report/components/OriginIcon';
import { LinkedEntityContainer } from 'common/components/findings/report/tableConfig';
import { Finding } from 'common/entities/findings/FindingTypes';

const LinkedOriginIcon = ({ data, isEvent }) => (
  <>
    {data?.origin_audit && !isEvent ? (
      <LinkedEntityContainer>
        <OriginIcon type="event" />
      </LinkedEntityContainer>
    ) : null}
    {data?.origin_form_submission ? (
      <LinkedEntityContainer>
        <OriginIcon type="origin_form" />
      </LinkedEntityContainer>
    ) : null}
    {data?.form_submissions?.length
      ? data?.form_submissions?.map((_, i) => (
          <LinkedEntityContainer key={i}>
            <OriginIcon type="form" />
          </LinkedEntityContainer>
        ))
      : null}
    {data?.jobs?.length
      ? data?.jobs?.map((_, i) => (
          <LinkedEntityContainer key={i}>
            <OriginIcon type="jobs" />
          </LinkedEntityContainer>
        ))
      : null}
    {data?.purchasing_requisitions?.length
      ? data?.purchasing_requisitions?.map((_, i) => (
          <LinkedEntityContainer key={i}>
            <OriginIcon type="purchasing_requisition" />
          </LinkedEntityContainer>
        ))
      : null}
    {!data?.is_linked ? (
      <LinkedEntityContainer className="align-items-center">
        <OriginIcon isLinked={data?.is_linked} isFromVessel={data?.is_from_vessel} />
      </LinkedEntityContainer>
    ) : null}
  </>
);

export default LinkedOriginIcon;

LinkedOriginIcon.propTypes = {
  data: Finding,
  isEvent: PropTypes.bool
};
