import React from 'react';
import _get from 'lodash/get';
import { parseTextHtml } from 'common/components/jobs/_base/modules/checklist/helpers';
import Checkbox from 'common/components/form/inputs/Checkbox';

const ChecklistToggle = ({ properties, ...rest }) => {
  let title = _get(properties, 'object.title', null);
  if (!title) {
    title = _get(properties, 'object.text', null);
  }
  const mentions = _get(properties, 'object.mentions', []);

  const createMarkup = () => {
    return {
      __html: parseTextHtml(title, mentions)
    };
  };

  const checked = _get(properties, 'action', null) === 'checked';

  return (
    <div className="d-flex align-items-center  activity-checklist">
      <Checkbox className="activity-checklist__checkbox" value={checked} disabled {...rest} />
      <div className="text-violet fw-light" dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

export default ChecklistToggle;
