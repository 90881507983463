import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

export const getCrewContracts = params => dispatch => {
  dispatch({ type: TYPES.GET_CREW_CONTRACTS.START });
  const { id, ...rest } = params;

  return get(`/crew/${id}/contracts`, { ...rest })
    .then(res => {
      dispatch({
        type: TYPES.GET_CREW_CONTRACTS.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_CONTRACTS.ERROR, payload: error });

      throw error;
    });
};

// For onboard
export const getVesselContracts = params => dispatch => {
  const { id, ...rest } = params;

  dispatch({ type: TYPES.GET_VESSEL_CONTRACTS.START }, rest);

  return get(`crew/${id}/contracts`, rest)
    .then(res => {
      dispatch({ type: TYPES.GET_VESSEL_CONTRACTS.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_VESSEL_CONTRACTS.ERROR, payload: error });

      throw error;
    });
};

export const getCrewPreviousContracts = params => dispatch => {
  dispatch({ type: TYPES.GET_CREW_PREVIOUS_CONTRACTS.START });

  return get(`/crew/${params.id}/previous-contracts`)
    .then(res => {
      dispatch({
        type: TYPES.GET_CREW_PREVIOUS_CONTRACTS.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_PREVIOUS_CONTRACTS.ERROR, payload: error });

      throw error;
    });
};

export const getCrewContract = params => dispatch => {
  dispatch({ type: TYPES.GET_CREW_CONTRACT.START });

  return get(`/crew-contracts/${params.id}`)
    .then(res => {
      dispatch({
        type: TYPES.GET_CREW_CONTRACT.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_CONTRACT.ERROR, payload: error });

      throw error;
    });
};

export const updateCrewContract = params => dispatch => {
  const { id, crew_id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CREW_CONTRACT.START, payload: { params } });

  return put(`/crew-contracts/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CREW_CONTRACT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      if (crew_id) dispatch(getCrewActiveRotations({ id: crew_id }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_CREW_CONTRACT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const updateCrewPreviousContract = params => dispatch => {
  const { crew_id, id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CREW_PREVIOUS_CONTRACT.START, payload: { params } });

  return put(`/crew/${crew_id}/previous-contracts/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CREW_PREVIOUS_CONTRACT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_CREW_PREVIOUS_CONTRACT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const createCrewContract = params => dispatch => {
  const { id, crew_id, ...rest } = params;
  dispatch({ type: TYPES.CREATE_CREW_CONTRACT.START, payload: { params } });

  return post(`/crew/${crew_id}/contracts`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.CREATE_CREW_CONTRACT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Contract created successfully' }));
      dispatch(getCrewActiveRotations({ id: crew_id }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_CREW_CONTRACT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const createCrewPreviousContract = params => dispatch => {
  const { crew_id, ...rest } = params;
  dispatch({ type: TYPES.CREATE_CREW_PREVIOUS_CONTRACT.START, payload: { params } });

  return post(`/crew/${crew_id}/previous-contracts`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.CREATE_CREW_PREVIOUS_CONTRACT.SUCCESS, payload: response.data });
      dispatch(
        successHandler({ title: 'Success!', message: 'Previous contract created successfully' })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_CREW_PREVIOUS_CONTRACT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const deleteCrewContract = params => dispatch => {
  dispatch({ type: TYPES.DELETE_CREW_CONTRACT.START, payload: { params } });

  return deleteRequest(`/crew-contracts/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_CREW_CONTRACT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Document deleted successfully' }));
      dispatch(getCrewActiveRotations({ id: params.crew_id }));
      dispatch(getCrewContracts({ id: params.crew_id }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_CREW_CONTRACT.ERROR,
        payload: { response: error, params }
      });

      return error;
    });
};
export const deleteCrewPreviousContract = params => dispatch => {
  const { crew_id, id } = params;
  dispatch({ type: TYPES.DELETE_CREW_PREVIOUS_CONTRACT.START, payload: { params } });

  return deleteRequest(`/crew/${crew_id}/previous-contracts/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_CREW_PREVIOUS_CONTRACT.SUCCESS, payload: response.data });
      dispatch(
        successHandler({ title: 'Success!', message: 'Previous contract deleted successfully' })
      );
      dispatch(getCrewPreviousContracts({ id: crew_id }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_CREW_PREVIOUS_CONTRACT.ERROR,
        payload: { response: error, params }
      });

      return error;
    });
};

export const getCrewContractAttachments = params => dispatch => {
  dispatch({ type: TYPES.GET_CREW_CONTRACT_ATTACHMENTS.START });

  return get(`/crew-contracts/${params.contract_id}/attachments`)
    .then(res => {
      dispatch({
        type: TYPES.GET_CREW_CONTRACT_ATTACHMENTS.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_CONTRACT_ATTACHMENTS.ERROR, payload: error });

      throw error;
    });
};

export const getCrewContractsDigitalSignatures = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_CREW_CONTRACTS_DIGITAL_SIGNATURES.START, payload: { params } });

  return get(`/crew-contracts/${id}/signatures`)
    .then(response => {
      dispatch({
        type: TYPES.GET_CREW_CONTRACTS_DIGITAL_SIGNATURES.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_CONTRACTS_DIGITAL_SIGNATURES.ERROR, payload: error });
      return error;
    });
};

export const createCrewContractsDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.CREATE_CREW_CONTRACTS_DIGITAL_SIGNATURE.START, payload: { params } });
  const { id, ...rest } = params;

  return post(`/crew-contracts/${id}/signatures`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_CREW_CONTRACTS_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_CREW_CONTRACTS_DIGITAL_SIGNATURE.ERROR, payload: error });
      return error;
    });
};

export const deleteCrewContractsDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.DELETE_CREW_CONTRACTS_DIGITAL_SIGNATURE.START, payload: { params } });

  const { id, signature_id } = params;

  return deleteRequest(`/crew-contracts/${id}/signatures/${signature_id}`)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_CREW_CONTRACTS_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_CREW_CONTRACTS_DIGITAL_SIGNATURE.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const setActiveContract = contract => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_CONTRACT, payload: contract });
};

export const setActivePreviousContract = contract => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_PREVIOUS_CONTRACT, payload: contract });
};

export const addNewPreviousContract = contract => dispatch => {
  dispatch({ type: TYPES.ADD_NEW_PREVIOUS_CONTRACT, payload: contract });
};

export const removeNewPreviousContract = contract => dispatch => {
  dispatch({ type: TYPES.REMOVE_NEW_PREVIOUS_CONTRACT, payload: contract });
};

export const getCrewActiveRotations = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_CREW_ACTIVE_ROTATIONS.START, payload: { params } });

  return get(`/crew/${id}/rotation`)
    .then(response => {
      dispatch({ type: TYPES.GET_CREW_ACTIVE_ROTATIONS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_CREW_ACTIVE_ROTATIONS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};
