import React, { useState } from 'react';
import Form from './Form';
import Preview from './Preview';

const Samples = () => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? <Form setIsEditing={setIsEditing} /> : <Preview setIsEditing={setIsEditing} />;
};

export default Samples;
