import paths from 'routing/routes/_paths';

export const isRedirectable = type => {
  const redirectablePaths = ['Maintenance', 'Delivery'];
  return redirectablePaths.includes(type);
};

export const constructPath = history => {
  switch (history?.type) {
    case 'Maintenance':
      return `${paths.pms_jobs}/${history?.action_entity_id}`;

    case 'Delivery':
      return `${paths.purchasing_requisitions}/${
        history?.action_entity?.requisition_id || history?.action_entity_id
      }`;
    default:
      break;
  }
};

export const redirectTo = history => {
  const path = constructPath(history);

  if (path) {
    return window.open(path);
  }
};
