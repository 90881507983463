import React, { useState } from 'react';
import _get from 'lodash/get';
import moment from 'moment';
import { parseTextHtml, parseText, transformDataForSubmit } from '../helpers';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import * as jobChecklistActions from 'common/components/jobs/_base/modules/checklist/store/actions';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import Input from 'common/components/jobs/_base/inputs/MentionInput';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { displayDate } from '@/ts-common/utils/dates.ts';

const Comment = ({ comment, parentRef, jobId, optionId }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const accountId = useSelector(state => state.account.id);

  const [updateComment, deleteComment] = useActions([
    jobChecklistActions.updateComment,
    jobChecklistActions.deleteComment
  ]);

  const [message, setMessage] = useState('');
  const [mentions, setMentions] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const createMarkup = () => {
    return {
      __html: parseTextHtml(_get(comment, 'text', ''), comment.mentions)
    };
  };

  const onInputChange = (e, newValue, newPlainText, mentions) => {
    setMentions(mentions);
    setMessage(e.target.value);
  };

  const setEditMode = () => {
    setMessage(parseText(_get(comment, 'text', ''), comment.mentions));
    setMentions(
      comment.mentions.map(el => ({ id: `${el.type}---${el.id}`, display: `@${el.name}` }))
    );
    setIsEdit(true);
  };
  const cancelEditMode = () => {
    setIsEdit(false);
  };

  const onSave = async () => {
    if (message === '') return;
    setSubmitting(true);
    let params = transformDataForSubmit(message, mentions);

    const res = await updateComment({
      job_id: jobId,
      option: optionId,
      comment: comment.id,
      ...params
    });

    if (res && res.id) {
      setMessage('');
      setMentions('');
      setIsEdit(false);
    }
    setSubmitting(false);
  };

  const onDelete = async () => {
    await deleteComment({
      job_id: jobId,
      option: optionId,
      comment: comment.id
    });
  };

  const isEdited = moment(comment.created_at).isBefore(moment(comment.updated_at));

  return (
    <div className="single-message">
      {isEdit ? (
        <div className={`w-100p ${submitting ? 'loading' : ''}`}>
          <Input parentRef={parentRef} value={message} onChange={onInputChange} />
          <div className="new-comment-actions">
            <InputSaveActions onCancel={cancelEditMode} onSave={onSave} />
          </div>
        </div>
      ) : (
        <div
          className={`display-text text-violet fs-14`}
          dangerouslySetInnerHTML={createMarkup()}
        />
      )}

      <div className="d-flex align-items-center fs-12 comment-footer pt-1">
        <div>{comment.party.full_name}</div>
        <div className="divider" />
        <div>
          {displayDate(comment.created_at, {}, 'dd MMM yyyy, HH:mm')}
          {isEdited && <span className="text-primary"> - Edited</span>}
        </div>

        {comment.party.id === accountId && (
          <>
            <div className="ms-4 cursor-pointer edit-comment" onClick={setEditMode}>
              Edit
            </div>
            <div className="divider" />
            <div className="cursor-pointer delete-comment" onClick={toggleModal}>
              Remove
            </div>
          </>
        )}
      </div>
      <DangerousActionModal
        transparent
        isOpen={isModalOpen}
        header="Remove Comment"
        body={
          <div className="text-center">
            You are about to remove this comment. <br />
            Are you sure you want to continue?
          </div>
        }
        actionText="REMOVE"
        onAccept={onDelete}
        closeModal={toggleModal}
      />
    </div>
  );
};

export default Comment;
