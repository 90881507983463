import { FC } from 'react';
import { TableFilterSelectorProps } from '@/common/types/front-entities/table';
import Select from '@/ts-common/components/form/inputs/select';

const BooleanFilterSelector: FC<
  TableFilterSelectorProps & { options?: { label: string; value: string }[] }
> = ({ value, onChange, isMulti, listParams, options, ...rest }) => {
  return (
    <Select
      isMulti={false}
      placeholder="Select value"
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      value={value}
      options={
        options ?? [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
      onChange={s => onChange(s)}
      {...rest}
    />
  );
};
export default BooleanFilterSelector;
