import { FC, CSSProperties } from 'react';

import spinner from 'common/assets/svg/common/spinner.svg';
import SvgRender from 'common/components/general/SvgRender';

type SpinnerProps = {
  size?: number;
  style?: CSSProperties;
  rest?: any[];
};

const Spinner: FC<SpinnerProps> = ({ size = 32, style, ...rest }) => {
  return (
    <div className="spinner-loader d-inline-flex align-items-center">
      <SvgRender src={spinner} style={{ width: size, height: size, ...style }} {...rest} />
    </div>
  );
};

export default Spinner;
