import ContentLoader from 'react-content-loader';

const AnnouncementsLoader = () => {
  return (
    <ContentLoader
      speed={1}
      backgroundColor="#F8F9FA"
      foregroundColor="#EEF0F4"
      preserveAspectRatio="none"
      width="100%"
      height={141}
      viewBox="0 0 100 141"
    >
      <rect x="3" y="0" rx="12" ry="0" width="45" height="141" />
      <rect x="51" y="0" rx="12" ry="0" width="45" height="141" />
    </ContentLoader>
  );
};

export default AnnouncementsLoader;
