import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_BUNKER_SURVEY'),
  ...asyncTypes('CREATE_BUNKER_SURVEY'),
  ...asyncTypes('UPDATE_BUNKER_SURVEY'),
  ...asyncTypes('DELETE_BUNKER_SURVEY'),
  ...asyncTypes('GET_BUNKER_SURVEY_FUTURE_ROBS'),
  ...asyncTypes('CREATE_BUNKER_SURVERY_CALCULATIONS'),
  ...asyncTypes('UPDATE_BUNKER_SURVEY_CALCULATIONS'),

  SET_BUNKER_SURVEY_DATA: 'SET_BUNKER_SURVEY_DATA',
  SET_BUNKER_SURVEY_SLIM_VIEW: 'SET_BUNKER_SURVEY_SLIM_VIEW',
  SET_BUNKER_SURVEY_VIEW_ONLY: 'SET_BUNKER_SURVEY_VIEW_ONLY',
  SET_BUNKER_SURVEY_DRAWER: 'SET_BUNKER_SURVEY_DRAWER'
};

export default TYPES;
