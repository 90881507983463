import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrayMove from 'array-move';
import {
  isTabActive,
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';

import Display from './display';
import Checklist from 'common/components/checklist/index';
import { transformDataForSubmit } from './helpers';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';

import {
  getChecklistOptions,
  updateChecklistOptions,
  toggleChecklistOption,
  setChecklistOptions
} from 'common/components/jobs/_base/modules/checklist/store/actions';
import { setActiveJobTab, setJobFieldError } from 'common/components/jobs/_base/store/actions';

import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';
import ModuleContentLoader from '../../components/ModuleContentLoader';
import ModuleTabActions from '../../components/ModuleTabActions';
import Header from 'common/components/jobs/_base/modules/checklist/display/Header';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const JobsChecklist = ({ avoidCheck }) => {
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dangerousModalIsOpen, setDangerousModalIsOpen] = useState(false);

  const dispatch = useDispatch();
  const checklistRef = useRef(null);

  const isJobEditMode = useSelector(checkIfEditMode);
  const isJobLocked = useSelector(selectIsJobLocked);
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const jobHasChecklistOptions = useSelector(state =>
    selectJobField(state, 'has_checklist_options')
  );
  const checklistJobId = useSelector(state => selectJobField(state, 'checklist').jobId);
  const jobChecklistError = useSelector(state => selectJobFieldError(state, 'checklist'));
  const checklistOptions = useSelector(state => selectJobField(state, 'checklist').options);
  const editMode = useSelector(state => isTabActive(state, 'checklist'));

  const showChecklist =
    useSelector(selectIfFieldIsVisible('checklist_options')) || jobHasChecklistOptions;

  const [checklistState, setChecklistState] = useState([{ title: '', mentions: [] }]);
  const [isModuleEditMode, setIsModuleEditMode] = useState(!jobHasChecklistOptions);
  const [isHovering, setIsHovering] = useState(false);

  const initChecklist = useCallback(async () => {
    setIsLoading(true);
    await dispatch(getChecklistOptions({ job_id: jobId }));

    setIsLoading(false);
  }, [dispatch, jobId]);

  useEffect(() => {
    if (checklistOptions.length) {
      setChecklistState(
        checklistOptions.map(c => ({
          ...c,
          mentions: c?.mentions?.map(el => ({ id: `${el.type}---${el.id}`, display: el.name }))
        }))
      );
    } else {
      setChecklistState([{ title: '', mentions: [] }]);
    }
  }, [checklistOptions]);

  useEffect(() => {
    if (editMode && !jobHasChecklistOptions && !isModuleEditMode) setIsModuleEditMode(true);
  }, [editMode, isModuleEditMode, jobHasChecklistOptions]);

  useEffect(() => {
    if (jobId && jobId !== checklistJobId && jobHasChecklistOptions) {
      initChecklist();
    }
  }, [checklistJobId, initChecklist, jobHasChecklistOptions, jobId]);

  const onSaveModuleAction = async () => {
    if (checklistState.some(check => !check.title)) return;

    setSubmitting(true);

    if (isJobEditMode) {
      const params = {
        job_id: jobId,
        checklist_options: checklistState
          .filter(ch => ch.title)
          .map((v, index) => {
            const par = transformDataForSubmit(v.title, v.mentions);

            return {
              title: par.text,
              mentions: par.mentions,
              id: v.id || undefined,
              sort_index: index
            };
          })
      };

      const res = await dispatch(updateChecklistOptions(params));

      if (res && res.length) {
        setChecklistState(
          res.map(c => ({
            ...c,
            mentions: c.mentions?.map(el => ({ id: `${el.type}---${el.id}`, display: el.name }))
          }))
        );
      }
    } else {
      const hasOptions = checklistState.filter(check => check.title);
      if (jobChecklistError && hasOptions.length) {
        dispatch(setJobFieldError({ checklist: null }));
      }
      dispatch(
        setChecklistOptions(
          checklistState
            .filter(ch => ch.title)
            .map(v => {
              const par = transformDataForSubmit(v.title, v.mentions, true);

              return {
                ...v,
                title: par.text,
                mentions: par.mentions
              };
            })
        )
      );
    }

    dispatch(setActiveJobTab('checklist', true));
    setSubmitting(false);
    setIsModuleEditMode(false);
  };

  const onCancelModuleAction = () => {
    if (!isJobEditMode) {
      setListOptionsFromCreate(
        checklistOptions.length ? checklistOptions : [{ title: '', mentions: [] }]
      );
    } else {
      setChecklistState(
        checklistOptions.length
          ? checklistOptions.map(c => ({
              ...c,
              mentions: c?.mentions?.map(el => ({ id: `${el.type}---${el.id}`, display: el.name }))
            }))
          : [{ title: '', mentions: [] }]
      );
    }

    setIsModuleEditMode(false);

    if (!checklistOptions.length) dispatch(setActiveJobTab('checklist', true));
  };

  const onCheck = async (check, index) => {
    dispatch(
      setChecklistOptions(
        checklistOptions.map((c, i) => (i === index ? { ...c, checked: !c.checked } : c))
      )
    );

    if (isJobEditMode) {
      await dispatch(toggleChecklistOption({ job_id: jobId, option: check.id }));
    }
  };

  const setListOptionsFromCreate = checkState => {
    const hasOptions = checkState.filter(check => check.title);

    if (jobChecklistError && hasOptions.length) {
      dispatch(setJobFieldError({ checklist: null }));
    }
    dispatch(
      setChecklistOptions(
        checkState
          .filter(check => check.title)
          .map(v => {
            const par = transformDataForSubmit(v.title, v.mentions, true);

            return {
              ...v,
              title: par.text,
              mentions: par.mentions
            };
          })
      )
    );
  };

  const updateChecklistState = (val, mentions, index) => {
    const updatedState = checklistState.map((c, i) =>
      i === index ? { ...c, title: val, mentions } : c
    );

    setChecklistState(updatedState);
  };

  const isVisible = checklistOptions.some(check => check.title) || editMode;

  const handleSort = (newIndex, oldIndex) => {
    const newChecklistOptions = arrayMove(checklistState, oldIndex, newIndex);
    const updatedState = newChecklistOptions.map((checklist, index) => ({
      ...checklist,
      sort_index: index + 1
    }));
    setChecklistState(updatedState);
  };

  const onDeleteModuleAction = () => {
    setDangerousModalIsOpen(true);
  };

  const onDeleteChecklistWidget = async () => {
    if (isJobEditMode) {
      await dispatch(updateChecklistOptions({ job_id: jobId, checklist_options: [] }));
    }

    dispatch(setChecklistOptions([]));
    setChecklistState([{ title: '', mentions: [] }]);
    dispatch(setActiveJobTab('checklist', true));

    setIsModuleEditMode(false);
    setDangerousModalIsOpen(false);
  };

  if (!showChecklist) return null;

  return isVisible ? (
    <div
      className="job-checklist mb-3"
      ref={checklistRef}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <UserCanEdit
        field="checklist_options"
        avoidCheck={avoidCheck}
        fallback={() => <Header checklistOptions={checklistOptions} editMode={editMode} />}
      >
        <ModuleTabActions
          header={<Header checklistOptions={checklistOptions} editMode={editMode} />}
          onCancel={onCancelModuleAction}
          onSave={onSaveModuleAction}
          onEdit={() => setIsModuleEditMode(true)}
          onDelete={onDeleteModuleAction}
          isEditMode={isModuleEditMode}
          isDisabled={isLoading || submitting}
          isHovering={isHovering}
        />
      </UserCanEdit>

      {isLoading ? (
        <ModuleContentLoader />
      ) : (
        <div className="job-checklist__container">
          <Checklist
            hasActionButtons={false}
            editMode={isModuleEditMode}
            isEditMode={true}
            handleSort={handleSort}
            updateChecklistState={updateChecklistState}
            setChecklistState={setChecklistState}
            checklistState={checklistState}
            submitting={submitting}
            isLoading={isLoading}
            checklistOptions={checklistOptions}
            sectionId={jobId}
            checklistRef={checklistRef}
            isLocked={isJobLocked}
            onCheck={onCheck}
            DisplayComponent={Display}
            placeholder="Add option"
          />
        </div>
      )}

      <DangerousActionModal
        transparent
        isOpen={dangerousModalIsOpen}
        header={'Delete Widget'}
        body={
          <div className="text-center">
            Are you sure you want to delete the checklist widget? <br />
            This cannot be undone.
          </div>
        }
        actionHoverColor={'delete'}
        actionText={'DELETE'}
        action={'delete'}
        onAccept={onDeleteChecklistWidget}
        onClose={() => setDangerousModalIsOpen(false)}
        closeModal={() => setDangerousModalIsOpen(false)}
      />
    </div>
  ) : null;
};

export default JobsChecklist;
