import { useCallback, useMemo } from 'react';
import { parseQueryParams } from 'utils/urls';
import useRouter from 'use-react-router';

const useTableTopFilter = ({ name }, label) => {
  const { history } = useRouter();

  const isFilterSelected = useCallback(
    (search, label) => {
      if (search?.length) {
        const { filters } = parseQueryParams(search);

        return !!filters?.find(
          f => f.name === name && ((label && f.value?.includes(label)) || (!label && f.value))
        );
      }

      return false;
    },
    [name]
  );

  const isEnabled = useMemo(
    () => isFilterSelected(history.location.search, label),
    [history.location.search, label, isFilterSelected]
  );

  return isEnabled;
};

export default useTableTopFilter;
