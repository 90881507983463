import types from 'common/utils/filters/operators';
import { parseQueryParams } from 'common/utils/urls';
import _isEqual from 'lodash/isEqual';

export const formatColumn = column => ({
  type: column.type,
  symbol: column.symbol,
  label: column.header,
  filterLabel: column.filterLabel,
  bottomHeader: column.bottomHeader,
  key: column.key,
  value: column.key,
  showUtc: column.showUtc,
  valueOptions: column.options,
  component: column.component,
  componentRest: column.componentRest,
  sticky: column.sticky,
  left: column.left,
  minWidth: column.minWidth,
  maxWidth: column.maxWidth,
  footer: column.footer,
  defaultHidden: column.defaultHidden,
  defaultFilterValues: column.defaultFilterValues
});

export const getInitialParams = ({ columns, sorting, paging, filters }, defaultRequestParams) => {
  return {
    visible:
      defaultRequestParams && !defaultRequestParams.visible
        ? undefined
        : columns
        ? columns
            .filter(c => c.key && !c.hidden)
            .map(c => ({ key: c.key, visible: !c.defaultHidden }))
        : [],
    sorting: defaultRequestParams && !defaultRequestParams.sorting ? undefined : {},
    paging:
      defaultRequestParams && !defaultRequestParams.paging
        ? undefined
        : paging
        ? { current_page: paging.current_page, per_page: paging.per_page }
        : undefined,
    filters: defaultRequestParams && !defaultRequestParams.filters ? undefined : filters
  };
};

export const initFiltersState = (requestParams, columns) => {
  if (requestParams.filters && requestParams.filters.length) {
    const filters = requestParams.filters
      .map(f => {
        if (!f.value) return undefined;

        if (f.name === 'archived') {
          return {
            column: {
              key: 'archived',
              label: 'Archived',
              type: 'boolean',
              value: 'archived'
            },
            operation: {
              label: 'is',
              value: '='
            },
            value: 'true'
          };
        }

        const column = columns.find(c => c.key === f.name);
        if (!column) return undefined;

        const operation = (types[column.type]?.operations || []).find(o => o.value === f.operation);
        if (!operation) return undefined;

        return {
          column: formatColumn(column),
          operation,
          value:
            f.operation === 'between'
              ? {
                  from: Array.isArray(f.value) && f.value[0] ? f.value[0] : null,
                  to: Array.isArray(f.value) && f.value[1] ? f.value[1] : null
                }
              : f.value
        };
      })
      .filter(f => f !== undefined);

    return [...filters, { column: null, operation: null, value: '' }];
  } else {
    return [{ column: null, operation: null, value: '' }];
  }
};

export const initTopFiltersState = (requestParams, top) => {
  if (top && top.filters) {
    if (requestParams.filters && requestParams.filters.length) {
      return top.filters.map(f => {
        if (requestParams.filters && requestParams.filters.length) {
          const filter = requestParams.filters.find(r => r.name === f.name);

          if (filter) {
            return {
              ...f,
              operation: filter.operation,
              value: filter.value
            };
          }
        }

        return f;
      });
    } else {
      return top.filters;
    }
  }

  return [];
};

export const getFiltersParams = filters => {
  return filters
    .filter(
      f =>
        f.column &&
        f.operation &&
        f.operation.value &&
        f.value !== undefined &&
        f.value !== '' &&
        ((Array.isArray(f.value) && f.value.length) || !Array.isArray(f.value)) &&
        ((f.operation.value === 'between' && f.value.from && f.value.to) ||
          (f.operation.value !== 'between' && f.value))
    )
    .map(f => ({
      name: f.column.key,
      operation: f.operation.value,
      value: f.operation.value === 'between' ? [f.value.from, f.value.to] : f.value
    }));
};

const getInitialTopFilters = (top, excludedFilters = [], search, initial) => {
  if (search) {
    const { filters } = parseQueryParams(search);

    return filters
      ? filters
          .filter(f => !excludedFilters.includes(f.name))
          .filter(f => f.value && top.filters.some(t => t.name === f.name))
          .map(f => {
            const topFilter = top.filters.find(t => t.name === f.name);

            return {
              name: f.name,
              operation: f.operation,
              value:
                topFilter?.initialValue !== undefined && initial ? topFilter.initialValue : f.value
            };
          })
      : [];
  } else if (top?.filters?.length && initial) {
    return top.filters.filter(f => f.value);
  } else {
    return [];
  }
};

export const getTopFiltersParams = (top, excludedFilters, search, initial) => {
  return top && top.filters ? getInitialTopFilters(top, excludedFilters, search, initial) : [];
};

export const commonExcludedFilters = ['archived'];

export const getUpdatedTableFiltersParams = (
  { filters, topFilters, excludedFilters, requestParams, state },
  location
) => {
  const params = {
    filters: [
      ...getFiltersParams(filters),
      ...getTopFiltersParams({ filters: topFilters }, excludedFilters, location.search)
    ]
  };

  if (state.paging) {
    params.paging = { per_page: state.paging.per_page, current_page: 1 };
  }

  if (!_isEqual(params.filters, requestParams.filters)) {
    return params;
  }

  return null;
};
