import { useState } from 'react';
import PropTypes from 'prop-types';

import { useDrawer } from 'common/components/drawer';
import AuditForm from './AuditForm';
import Findings from './findings';

const Body = ({ isAdding, setIsAdding }) => {
  const [active, setActive] = useState(null);
  const drawer = useDrawer();

  return (
    <>
      <Findings active={active} setActive={setActive} drawer={drawer} />

      {isAdding && <AuditForm drawer={drawer} setActive={setActive} setIsAdding={setIsAdding} />}
    </>
  );
};

AuditForm.propTypes = {
  isAdding: PropTypes.func,
  setIsAdding: PropTypes.func
};

export default Body;
