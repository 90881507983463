import { FC } from 'react';
import PropTypes from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';

import add from 'common/assets/svg/actions/add.svg';
import send from 'common/assets/svg/actions/send.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import bin from 'common/assets/svg/actions/delete.svg';
import save from 'common/assets/svg/actions/save.svg';
import close from 'common/assets/svg/actions/close.svg';
import edit from 'common/assets/svg/actions/edit.svg';
import check from 'common/assets/svg/actions/check.svg';
import reply from 'common/assets/svg/actions/reply.svg';
import replyToAll from 'common/assets/svg/actions/reply-to-all.svg';
import expand from 'common/assets/svg/actions/expand.svg';
import reduce from 'common/assets/svg/actions/reduce.svg';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import arrowThin from 'common/assets/svg/common/arrows/arrow-thin.svg';
import arrowBold from 'common/assets/svg/common/arrow.svg';
import share from 'common/assets/svg/common/share-solid.svg';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import hide from 'common/assets/svg/common/hide.svg';
import versioning from 'common/assets/svg/common/versioning.svg';
import backArrow from 'common/assets/svg/common/arrows/back-arrow.svg';
import location from 'common/assets/svg/common/location-solid.svg';
import archive from 'common/assets/svg/actions/archive.svg';
import unarchive from 'common/assets/svg/actions/unarchive.svg';
import settings from 'common/assets/svg/actions/settings.svg';
import calculator from 'common/assets/svg/common/calculator.svg';
import warningIcon from 'common/assets/svg/common/validation-error.svg';
import starSolidIcon from 'common/assets/svg/common/star-solid.svg';
import link from 'common/assets/svg/jobs/link.svg';
import unlink from 'common/assets/svg/common/unlink.svg';
import deactivate from 'common/assets/svg/actions/deactivate.svg';
import reset from 'common/assets/svg/actions/reset.svg';
import events from 'common/assets/svg/common/events.svg';
import dollar from 'common/assets/svg/common/dollar.svg';
import invoices from 'common/assets/svg/common/invoices.svg';

import Tooltip from '@/ts-common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

// DEPRECATED, used this one instead src/ts-common/components/buttons/CircledButton.tsx
const CircledButton: FC<any> = ({
  onClick,
  type,
  className,
  disabled,
  label,
  tooltipMessage = '',
  tooltipInnerClassName = '',
  svgStyle,
  style,
  reversed,
  svgClassName,
  svgWrapperClassName,
  wrappedLabel,
  whiteBg,
  squarified,
  labelClassName,
  dataCy,
  ...rest
}) => {
  const renderSvg = () => {
    const getProps = () => {
      switch (type) {
        case 'location':
          return { src: location, style: svgStyle || { width: 12, height: 12 } };
        case 'add':
          return { src: add, style: svgStyle || { width: 10, height: 10 } };
        case 'add-transparent':
          return {
            src: add,
            style: svgStyle || { width: 10, height: 10 }
          };
        case 'add-purple':
          return { src: add, style: svgStyle || { width: 10, height: 10 } };
        case 'add-primary':
          return { src: add, style: svgStyle || { width: 10, height: 10 } };
        case 'add-green':
          return {
            src: add,
            className: 'text-white',
            style: svgStyle || { width: 10, height: 10 }
          };
        case 'reply':
          return { src: reply, style: svgStyle || { width: 10, height: 10 } };
        case 'expand':
          return { src: expand, style: svgStyle || { width: 10, height: 10 } };
        case 'reduce':
          return { src: reduce, style: svgStyle || { width: 10, height: 10 } };
        case 'replyToAll':
          return { src: replyToAll, style: svgStyle || { width: 10, height: 10 } };
        case 'share':
          return { src: share, style: svgStyle || { width: 10, height: 10 } };
        case 'remove':
          return { className: 'text-white', src: remove, style: svgStyle || { width: 8 } };
        case 'bin':
        case 'delete':
          return { className: 'text-white', src: bin, style: svgStyle || { width: 12 } };
        case 'edit':
          return { className: 'text-primary', src: edit, style: svgStyle || { width: 8 } };
        case 'view':
          return {
            className: 'text-primary',
            src: eyeSvg,
            style: svgStyle || { width: 10, height: 10 }
          };
        case 'check':
          return { className: 'text-white', src: check, style: svgStyle || { width: 8 } };
        case 'invoice':
          return { className: 'text-white', src: invoices, style: svgStyle || { width: 8 } };
        case 'save':
          return {
            className: 'text-white',
            src: save,
            style: svgStyle || { width: 12, height: 12 }
          };
        case 'send':
          return {
            className: 'text-white',
            src: send,
            style: svgStyle || { width: 12, height: 12 }
          };
        case 'close':
          return { src: close, style: svgStyle || { width: 12, height: 12 } };
        case 'arrow':
          return {
            className: 'text-white',
            src: arrow,
            style: svgStyle || { width: 12, height: 12 }
          };
        case 'arrow-bold':
          return {
            className: 'text-white',
            src: arrowBold,
            style: svgStyle || { width: 11, height: 11 }
          };
        case 'arrow-thin':
          return {
            className: 'text-white',
            src: arrowThin,
            style: svgStyle || { width: 11, height: 11 }
          };
        case 'back-arrow':
          return { src: backArrow, style: svgStyle || { width: 12, height: 12 } };
        case 'versioning':
          return {
            className: 'text-white',
            src: versioning,
            style: svgStyle || { width: 14, height: 14 }
          };
        case 'versioning-yellow':
          return {
            className: 'text-dark',
            src: versioning,
            style: svgStyle || { width: 14, height: 14 }
          };
        case 'archive':
          return {
            className: 'text-white',
            src: archive,
            style: svgStyle || { width: 14, height: 14 }
          };
        case 'unarchive':
          return {
            src: unarchive,
            style: svgStyle || { width: 14, height: 14 }
          };
        case 'versioning-orange':
          return {
            className: 'text-white',
            src: versioning,
            style: svgStyle || { width: 14, height: 14 }
          };
        case 'settings':
          return {
            className: 'text-white',
            src: settings,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'calculator':
          return {
            className: 'text-white',
            src: calculator,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'warning':
          return {
            className: 'text-warning',
            src: warningIcon,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'evaluate':
          return {
            className: 'text-primary',
            src: starSolidIcon,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'view-evaluation':
          return {
            className: 'text-white',
            src: starSolidIcon,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'hide':
          return {
            className: 'text-primary',
            src: hide,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'deactivate':
          return {
            className: 'text-white',
            src: deactivate,
            style: svgStyle || { width: 14, height: 14 }
          };

        case 'expense':
          return {
            src: dollar,
            style: svgStyle
          };

        case 'reset':
          return {
            className: 'text-white',
            src: reset,
            style: svgStyle || { width: 13, height: 13 }
          };

        case 'events':
          return {
            src: events,
            style: svgStyle || { width: 11, height: 11 }
          };

        case 'link':
          return {
            src: link,
            style: svgStyle || { width: 12, height: 12 }
          };

        case 'unlink':
          return {
            className: 'text-primary',
            src: unlink,
            style: svgStyle || { width: 12, height: 12 }
          };

        default:
          return null;
      }
    };

    const props = getProps();

    if (!props) return null;

    return <SvgRender {...props} className={`${svgClassName || props.className || ''}`} />;
  };

  const { tooltipID, avoidRender } = useTooltipID(`${type}-circled-button`);

  return (
    <>
      <div
        id={tooltipID}
        className={`circled-button ${style ? 'circled-button__custom' : ''} ${
          disabled ? 'disabled' : ''
        } ${type} ${reversed ? 'flex-row-reverse' : ''} ${whiteBg ? 'white-bg' : ''} ${
          className || ''
        }`}
        data-cy={dataCy}
        onClick={onClick}
        {...rest}
      >
        <div
          className={`circled-button--icon${svgWrapperClassName ? ` ${svgWrapperClassName}` : ''} ${
            wrappedLabel ? 'px-1' : ''
          } ${squarified ? 'border-radius-3' : ''}`}
          style={style}
        >
          {renderSvg()}
          {wrappedLabel ? <div className="circled-button-wrapped-label">{wrappedLabel}</div> : null}
        </div>
        {label ? (
          <div
            className={`circled-button--label ${labelClassName || ''} ${
              reversed ? 'pe-1' : 'ps-1'
            }`}
          >
            {label}
          </div>
        ) : null}
      </div>

      {tooltipMessage && !avoidRender ? (
        <Tooltip target={tooltipID} innerClassName={tooltipInnerClassName}>
          {tooltipMessage}
        </Tooltip>
      ) : null}
    </>
  );
};

CircledButton.propTypes = {
  squarified: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  tooltipInnerClassName: PropTypes.string
};

export default CircledButton;
