import React from 'react';

import Title from './Title';
import SubGroups from './SubGroups';

const Group = ({ id }) => {
  return (
    <div className="filesystem-folder">
      <Title id={id} />
      <SubGroups id={id} />
    </div>
  );
};

export default Group;
