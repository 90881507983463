import { useDrawer } from 'common/components/drawer';
import Responsible from 'common/components/form-obligations/components/Responsible';
import FormsDrawer from 'common/components/forms/drawer';
import Status from 'common/components/general/Status';
import Table, { useTable } from 'common/components/table';
import {
  getEventFormSubmissions,
  removeEventFormSubmission
} from 'events/store/event-modules/forms/actions';
import { selectEventId } from 'events/store/events/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import eye from 'common/assets/svg/common/eye.svg';
import { EVENT_SUBMISSIONS_TABLE_LABEL } from '.';
import Priority from 'common/components/general/Priority';
import { selectTableListIsEmpty } from 'common/components/table/store/selectors';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';
import clock from 'common/assets/svg/common/clock-line.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import bin from 'common/assets/svg/common/bin.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import { deleteFormSubmission } from 'store/forms/actions';
import ListLoader from '../components/ListLoader';

const SubmissionsTable = () => {
  const [selectedFormSubmissionId, setSelectedFormSubmissionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { tooltipID, avoidRender } = useTooltipID();
  const drawer = useDrawer();
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventId = useSelector(selectEventId);
  const tableIsEmpty = useSelector(state =>
    selectTableListIsEmpty(state, EVENT_SUBMISSIONS_TABLE_LABEL)
  );

  const fetchData = async params => {
    if (!eventId) return;

    try {
      setIsLoading(true);
      const res = await dispatch(getEventFormSubmissions({ eventId, ...params }));
      setIsLoading(false);

      return res;
    } catch (err) {
      setIsLoading(false);

      console.error(err);
    }
  };

  const table = useTable({
    columns: [
      {
        header: 'No.',
        key: 'id',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: true,
        width: 1
      },
      {
        header: 'I',
        key: 'importance',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: true,
        width: 1
      },
      {
        header: 'Form',
        key: 'form',
        sortingKey: 'form',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: true,
        width: 4
      },
      {
        header: 'Responsible',
        key: 'responsible',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: false,
        width: 3
      },
      {
        header: 'Status',
        key: 'status',
        type: 'string',
        headerClassName: 'bg-transparent',
        sort: true,
        width: 2
      },
      {
        header: 'Form Date',
        key: 'submission_date',
        type: 'date',
        headerClassName: 'bg-transparent',
        sort: true
      },
      {
        header: 'Actions',
        key: 'actions',
        field: 'actions'
      }
    ],
    label: EVENT_SUBMISSIONS_TABLE_LABEL,
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: false },
    requestTableListFrom: fetchData
  });

  if (tableIsEmpty || avoidRender) return null;

  return isLoading ? (
    <div className="mt-4">
      <ListLoader />
    </div>
  ) : (
    <div className="mt-4">
      <Table
        hideTableFilters
        hideBottomPagination
        hideTableSearch
        hideTopPagination
        drawer={
          <FormsDrawer
            drawerType="view"
            drawer={drawer}
            onDrawerClose={() => {
              drawer.close();
              setSelectedFormSubmissionId(null);
            }}
            initialFormSubmissionID={selectedFormSubmissionId}
            showDeleteButton={false}
          />
        }
        handleTableSort={({ sorting }) => table.refetchData({ sorting })}
        rows={{
          id: data => (isOnBoard ? data.orca_id || '-' : data.id),
          form: data => (
            <div className="d-flex align-items-center justify-content-between">
              <TextWithTooltip>{data.form.name}</TextWithTooltip>
              {!!data.form_obligation?.id && (
                <>
                  <SvgRender
                    id={tooltipID}
                    className="text-purple"
                    src={clock}
                    style={{ width: 13, height: 13 }}
                  />
                  <Tooltip target={tooltipID}>Obligation</Tooltip>
                </>
              )}
            </div>
          ),
          responsible: data => (
            <Responsible
              type={data.form_obligation?.responsible_type}
              responsible={data.form_obligation?.responsible}
            />
          ),
          importance: data => <Priority value={data.form.importance_id} />,
          status: data => <Status type="forms" value={data.status} />,
          actions: data => ({
            options: [
              {
                label: 'View Form Submission',
                icon: eye,
                onClick: () => {
                  setSelectedFormSubmissionId(data.id);
                  drawer.open();
                }
              },
              !data.form_obligation
                ? {
                    label: 'Remove the link between the Form Submissions and the Event',
                    icon: remove,
                    preventionModal: true,
                    svgStyle: { width: 11, height: 11 },
                    modalProps: {
                      onAccept: async () => {
                        await dispatch(
                          removeEventFormSubmission({ eventId, submissionId: data.id })
                        );
                        table.refetchData();
                      },
                      header: 'Remove the link',
                      actionText: 'REMOVE',
                      action: 'remove',
                      actionHoverColor: 'delete',
                      body: (
                        <div className="text-center">
                          You are about to remove the link between the form submissions and the
                          event. <br />
                          Are you sure you want to continue?
                        </div>
                      )
                    }
                  }
                : {
                    label: 'Delete the Form Submission and mark the obligation as pending',
                    icon: bin,
                    preventionModal: true,
                    svgStyle: { width: 11, height: 11 },
                    modalProps: {
                      onAccept: async () => {
                        await dispatch(deleteFormSubmission({ id: data.id }));
                        table.refetchData();
                      },
                      header: 'Delete Form Submission',
                      actionText: 'DELETE',
                      action: 'remove',
                      actionHoverColor: 'delete',
                      body: (
                        <div className="text-center">
                          You are about to delete the form submission and mark the obligation as
                          pending.
                          <br />
                          Are you sure you want to continue?
                        </div>
                      )
                    }
                  }
            ]
          })
        }}
        {...table}
      />
    </div>
  );
};

export default SubmissionsTable;
