import { Button } from 'reactstrap';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectMessages, areMessagesFetching } from 'common/components/chatbox/store/selectors';
import { selectAccountId, selectAccount } from 'store/account/selectors';

import Drawer, { DrawerHeader, DrawerBody, DrawerFooter } from 'common/components/drawer';
import SvgRender from 'common/components/general/SvgRender';
import UploadFileButton from 'common/components/form/inputs/UploadFileButton';
import ProfileIcon from './ProfileIcon';
import EmptyLoader from './EmptyLoader';
import MessageBubble from './message-bubble';
import MentionInput from './MentionInput';
import { isAuthorized } from 'utils/permissions/authorize';

import send from 'common/assets/svg/actions/send.svg';
import { messageInitialState } from './helpers';

import PropTypes from 'prop-types';
import { File } from 'common/entities/files/FileTypes';
import AuthCheck from '@/components/permissions/AuthCheck';

const ChatDrawer = ({
  drawer,
  message,
  setMessage,
  permissions,
  file,
  setFile,
  postMessageHandler,
  drawerClassName
}) => {
  const [openActions, setOpenActions] = useState(false);
  const endOfListRef = useRef();
  const messages = useSelector(selectMessages);
  const isOnboard = useSelector(state => state.isOnBoard);
  const isFetching = useSelector(areMessagesFetching);
  const userId = useSelector(selectAccountId);
  const account = useSelector(selectAccount);
  const isLocked = !isAuthorized(account, permissions);

  const scrollToBottom = () => {
    if (messages?.length) {
      endOfListRef?.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  const cancelHandler = () => {
    setOpenActions(false);
    setMessage(messageInitialState);
  };

  useEffect(() => {
    if (drawer.isOpen) {
      scrollToBottom();
    }
  }, [messages?.length, drawer.isOpen]);

  useEffect(() => {
    if (openActions) {
      scrollToBottom();
    }
  }, [openActions]);

  const keyPressHandler = event => {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault();
      postMessageHandler();
    }
  };

  const updateMessage = (e, newValue, newPlainTextValue, mentions) => {
    setMessage({ text: e.target.value, mentions });
  };

  const onRemoveFile = () => setFile(null);

  return (
    <Drawer {...drawer} className={`chatbox__drawer ${drawerClassName}`}>
      <DrawerHeader className="chatbox__drawer-header">Chatbox</DrawerHeader>

      <DrawerBody className={`d-flex flex-column chatbox__drawer-body`}>
        {messages?.length && !isFetching ? (
          messages.map((message, index) => (
            <MessageBubble
              key={index}
              timestamp={message.created_at}
              message={message.message}
              mentions={message.mentions}
              type={message.side}
              username={message.party.full_name}
              attachments={message.attachments}
              side={message.party_id === userId ? 'right' : 'left'}
            />
          ))
        ) : (
          <EmptyLoader />
        )}
        <div className={`end-of-chatbox`} ref={endOfListRef}></div>
      </DrawerBody>

      <DrawerFooter className="p-3 pt-4 pb-3 d-flex flex-column align-items-center overflow-visible chatbox__drawer-footer">
        <AuthCheck permissions={permissions}>
          <div className="d-flex w-100p">
            <ProfileIcon type={isOnboard ? 'vessel' : 'office'} />
            <MentionInput
              wrapperClassName="bg-white cpx-12 py-1 border-radius-5 w-100p ms-1 mb-0"
              placeholder="Type a message"
              onFocus={() => setOpenActions(true)}
              onChange={updateMessage}
              onKeyDown={keyPressHandler}
              disabled={isLocked}
              isExpanded={openActions}
              value={message.text}
              file={file}
              onRemoveFile={onRemoveFile}
            />
          </div>
        </AuthCheck>

        {openActions ? (
          <AuthCheck permissions={permissions}>
            <div className="d-flex align-items-center mt-1 ps-5 w-100p">
              <UploadFileButton
                group="chat-messages.forms"
                onChange={setFile}
                disabled={!!file?.id}
              />

              <div
                className="fs-10 text-inactive fw-bold cursor-pointer me-2 ms-auto"
                onClick={cancelHandler}
              >
                CANCEL
              </div>

              <Button
                type="button"
                color="yellow"
                disabled={!message.text || isFetching}
                className="d-flex align-items-center chatbox__drawer-button cps-12"
                onClick={postMessageHandler}
              >
                <SvgRender src={send} className="text-dark" style={{ width: 14, height: 14 }} />
                <span className="fw-bold fs-10 text-dark cps-6">SEND</span>
              </Button>
            </div>
          </AuthCheck>
        ) : null}
      </DrawerFooter>
    </Drawer>
  );
};

ChatDrawer.propTypes = {
  drawer: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  postMessageHandler: PropTypes.func.isRequired,
  drawerClassName: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  file: File,
  setFile: PropTypes.func.isRequired
};

export default ChatDrawer;
