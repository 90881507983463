import { useSelector } from 'react-redux';

import { multiTabsSections, arrayFields } from 'captain-reports/templates/config/_constants';
import {
  selectFieldComment,
  selectEngineDataComment,
  selectEngineSubGroupDataComment,
  selectGeneratorsDataComment,
  selectGeneratorsSubGroupDataComment,
  selectSubGroupDataComment
} from 'captain-reports/store/selectors';

const useComment = ({ key, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const error = useSelector(state => {
    if (!key) return null;

    if (multiTabsSections[sectionLabel]) {
      switch (sectionLabel) {
        case 'engines':
          if (subGroup) {
            return selectEngineSubGroupDataComment(
              state,
              sectionActiveTab,
              subGroup,
              subGroupIndex,
              key
            );
          }

          return selectEngineDataComment(state, sectionActiveTab, key);

        case 'generators':
          if (subGroup) {
            return selectGeneratorsSubGroupDataComment(
              state,
              sectionActiveTab,
              subGroup,
              subGroupIndex,
              key
            );
          }

          return selectGeneratorsDataComment(state, sectionActiveTab, key);

        default:
          console.error('Missing multiple section selector: ' + sectionLabel);
          return null;
      }
    } else {
      if (arrayFields[subGroup]) {
        return selectSubGroupDataComment(state, subGroup, subGroupIndex, key);
      } else {
        return selectFieldComment(state, key);
      }
    }
  });

  return error;
};

export default useComment;
