import React from 'react';
import _get from 'lodash/get';
import { dateToLocal } from 'common/utils/dates';

const SingleActivity = ({ activity, isProfile }) => {
  const relation = _get(activity, 'properties.object.relation', null);

  const renderRelation = () => (
    <span>
      for {relation.type} <span className="fw-medium">{relation.name}</span>
    </span>
  );

  return (
    <div className="job-activity-list__item d-flex align-items-start">
      <div className="dot me-1" />
      <div className="d-flex flex-column w-100p">
        <div className={isProfile ? 'd-flex justify-content-between align-items-center' : ''}>
          <div className={`d-flex align-items-center text-primary fs-12 fw-medium lh-16 d-flex`}>
            {_get(activity, 'causer.full_name', 'Orca')}
            {activity?.properties?.rank ? ` | ${_get(activity, 'properties.rank.name', '-')}` : ''}

            <span className="fw-normal d-flex">
              &nbsp;{_get(activity, 'text', '-')}{' '}
              <span className="fw-medium cms-4">{_get(activity, 'name', '')}</span>{' '}
              {relation ? renderRelation() : null}
            </span>

            <div className={`ms-auto fs-10 text-violet fw-light lh-15`}>
              {dateToLocal(activity.created_at).format('MMMM DD YYYY, HH:mm')}
            </div>
          </div>
        </div>

        {activity.renderComponent ? (
          <div className="cmt-4 cmb-4">{activity.renderComponent(activity)}</div>
        ) : null}
      </div>
    </div>
  );
};

export default SingleActivity;
