import React, { useEffect } from 'react';
import useRouter from 'use-react-router';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import Header from './header';
import PageLoader from 'common/components/general/PageLoader';

import SystemRunningHours from 'captain-reports/templates/system-running-hours';
import * as runningHoursActions from 'captain-reports/store/system-running-hours/actions';
import {
  selectLoading,
  selectCaptainReportComments,
  isReportApproved
} from 'captain-reports/store/system-running-hours/selectors';
import PageCommentsPopover from 'common/components/popovers/PageCommentsPopover';

import * as systemRunningHoursActions from 'store/system-running-hours/actions';
import CorrectionsAlert from './header/CorrectionsAlert';

const PortStatements = () => {
  const { match } = useRouter();
  const [getRunningHours, getRunningHoursTotals, resetState] = useActions([
    systemRunningHoursActions.getRunningHours,
    systemRunningHoursActions.getRunningHoursTotals,
    runningHoursActions.resetState
  ]);

  const loading = useSelector(selectLoading);
  const commentsText = useSelector(selectCaptainReportComments);
  const isApproved = useSelector(isReportApproved);

  const init = async () => {
    try {
      await getRunningHours({ id: match.params.id, on_board: true });
      await getRunningHoursTotals({ id: match.params.id, on_board: true });
    } catch (e) {}
  };

  useEffect(() => {
    if (match.params.id) init();

    return () => {
      resetState();
    };
  }, [match.params.id]);

  return (
    <div className="captain-report-scroll-view">
      <Header />
      <div className="system-running-hours captain-report-form-view ">
        <div className="template-layout">
          <SystemRunningHours />

          {commentsText && !isApproved ? <PageCommentsPopover message={commentsText} /> : null}
        </div>
      </div>

      <CorrectionsAlert />
      <PageLoader isLoading={loading} />
    </div>
  );
};

export default PortStatements;
