import BallastTanks from 'common/components/vessels/profile/tabs/info/components/ballast-tanks/BallastTanks';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const ballastTanks = [
  {
    fields: [
      {
        label: 'NORMAL BALLAST CAPACITY (m³) ',
        key: 'ballast_capacity',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'HEAVY BALLAST CAPACITY (m³) ',
        key: 'heavy_ballast_capacity',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'MAX BALLASTING RATE (m³/h) ',
        key: 'max_ballasting_rate',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NORMAL BALLASTING TIME (h)',
        key: 'ballasting_time',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'HEAVY BALLASTING TIME (h)',
        key: 'heavy_ballasting_time',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'UNPUMPABLE BALLAST CAPACITY (m³) ',
        key: 'unpumpable_ballast_capacity',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'SEGREGATED BALLAST TANKS',
        key: 'segregated_ballast_tanks',
        type: 'boolean',
        inputProps: {
          size: 'sm'
        }
      },
      {
        label: 'SEGREGATED BALLAST CAP (m³) ',
        key: 'segregated_ballast_tanks_capacity',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        key: 'ballast_tanks',
        type: 'multiple',
        component: BallastTanks,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            key: 'tank_number',
            type: 'number',
            render: () => null
          },
          {
            label: 'TYPE',
            key: 'type.name',
            select_key: 'type',
            type: 'select',
            inputProps: {
              placeholder: 'Add type',
              isAsync: true,
              getOptionValue: option => option.id,
              getOptionLabel: option => option.name,
              defaultOptions: () => getInitialAsyncValues('vessel-ballast-tank-types'),
              loadOptions: search => getAsyncOptions(search, 'vessel-ballast-tank-types'),
              isClearable: false
              // isCreatable: true
            }
          },
          {
            label: 'NUMBER',
            key: 'number',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },

          {
            label: 'SIDE',
            key: 'side'
          },
          {
            label: 'CAPACITY (m³)',
            key: 'capacity',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'COMMENTS',
            key: 'comments'
          }
        ]
      }
    ]
  }
];

export default ballastTanks;
