import React from 'react';

const HowToAssess = ({ previewMode }) => {
  return (
    <div className={`how-to-assess h-100p cpt-4 cps-4 cpe-4 ${previewMode ? 'preview' : ''}`}>
      <strong className="title d-flex fs-12 text-primary ps-1">HOW TO ASSESS RISK</strong>
      <li>Select Severity expression that applies to hazard WITH NO Controls</li>
      <li>Select appropriate likelihood with NO Controls</li>
      <li>Apply controls and RECALCULATE risk</li>
    </div>
  );
};

export default HowToAssess;
