import { FC, useState } from 'react';
import { css } from '@emotion/react';

import backArrowIcon from 'common/assets/svg/common/arrows/back-arrow.svg';

import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import ResetVisibilityModal from './Modal';

type ResetVisibilityProps = {
  onReset: () => void;
};

const ResetVisibility: FC<ResetVisibilityProps> = ({ onReset }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center" onClick={() => setIsModalOpen(true)}>
        <Button
          className="d-flex align-items-center justify-content-center p-0"
          css={css`
            width: 1rem;
            height: 1rem;
          `}
          color="primary"
        >
          <SvgRender
            className="d-flex align-items-center justify-content-center"
            src={backArrowIcon}
            style={{ width: 10, height: 10 }}
          />
        </Button>
        <strong className="text-primary cms-12 cursor-pointer">Reset visibility</strong>
      </div>
      <ResetVisibilityModal
        isOpen={isModalOpen}
        onAccept={onReset}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ResetVisibility;
