import Drawer from 'common/components/drawer';
import ChangePassword from './ChangePassword';

const renderComponent = (drawer, crew, type, deleteModalHandler) => {
  switch (type) {
    case 'change_password':
      return <ChangePassword drawer={drawer} crew={crew} type={type} />;
    default:
      return null;
  }
};

const DrawerComponent = ({ drawer, crew, type, deleteModalHandler }) => (
  <Drawer {...drawer}>{renderComponent(drawer, crew, type, deleteModalHandler)}</Drawer>
);

export default DrawerComponent;
