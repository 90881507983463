import React from 'react';
import { Row, Col } from 'reactstrap';

import HelpTooltip from '../_components/HelpTooltip';
import { renderInputType, customFieldRows } from 'common/components/forms/digital/render-helpers';
import {
  getFieldTypeLabel,
  getFieldName,
  getFormFieldHelpText,
  getFormFieldTypeConfig,
  initFormFieldsStructure,
  getFormFieldItemDescription
} from 'common/components/forms/digital/state-utils';

const FormColumn = ({ fieldTypeLabel, width = '', className = '', children }) => (
  <Col xs={width} className={`digital-forms-inputs-col form-field-${fieldTypeLabel} ${className}`}>
    {children}
  </Col>
);

const FormInput = ({
  formField,
  formSubmission,
  formState,
  setFormState,
  disabled,
  headerFormState,
  warning,
  fields,
  isFormForVessel
}) =>
  renderInputType({
    formSubmission,
    formField,
    state: formState,
    setState: setFormState,
    disabled,
    headerFormState,
    warning,
    fields,
    isFormForVessel
  });

const Form = ({
  setFormState,
  formSubmission,
  formFields = [],
  formState,
  disabled,
  headerFormState,
  formSubmissionValues,
  isFormForVessel
}) => {
  const fields = initFormFieldsStructure(formFields);

  return (
    <div className="digital-forms-inputs">
      {fields.map(formField => {
        const fieldTypeLabel = getFieldTypeLabel(formField);
        const fieldName = getFieldName(formField);
        const formFieldHelpText = getFormFieldHelpText(formField);
        const warning = formSubmissionValues
          ? formSubmissionValues.find(w => w?.id === formField.id)?.warning
          : null;

        return (
          <Row noGutters key={formField.id} className="digital-forms-inputs-row">
            {customFieldRows.includes(fieldTypeLabel) ? (
              <FormColumn width={12} fieldTypeLabel={fieldTypeLabel}>
                <FormInput
                  formSubmission={formSubmission}
                  formField={formField}
                  formState={formState}
                  setFormState={setFormState}
                  disabled={disabled}
                  headerFormState={headerFormState}
                  isFormForVessel={isFormForVessel}
                  warning={warning}
                  fields={fields}
                />
              </FormColumn>
            ) : (
              <>
                <FormColumn
                  width={3}
                  fieldTypeLabel={fieldTypeLabel}
                  className={`text-end text-primary fw-bold fs-12`}
                >
                  {getFormFieldTypeConfig(formField)?.hideFieldName
                    ? ''
                    : getFormFieldTypeConfig(formField)?.showFieldItemDescription
                    ? getFormFieldItemDescription(formField)
                    : fieldName}
                </FormColumn>

                <FormColumn
                  width="auto"
                  fieldTypeLabel={fieldTypeLabel}
                  className={`digital-forms-inputs-col-info d-flex align-items-center justify-content-center ms-3 cme-12 cmt-2`}
                >
                  {formFieldHelpText ? <HelpTooltip text={formFieldHelpText} /> : null}
                </FormColumn>

                <FormColumn fieldTypeLabel={fieldTypeLabel}>
                  <Row noGutters className="w-100p">
                    <FormInput
                      formSubmission={formSubmission}
                      formField={formField}
                      formState={formState}
                      setFormState={setFormState}
                      disabled={disabled}
                      headerFormState={headerFormState}
                      isFormForVessel={isFormForVessel}
                      warning={warning}
                      fields={fields}
                    />
                  </Row>
                </FormColumn>
              </>
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default Form;
