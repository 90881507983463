import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import Tooltip from 'common/components/general/Tooltip';
import CreateReport from 'views/captain-reports/CreateReport';

import * as reportActions from 'store/captain-reports/actions';

import { selectListOptionsFromStore } from 'store/lists/selectors';

const CreateReportAction = ({ topFilters }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const groups = useSelector(state => selectListOptionsFromStore(state, 'report-groups'));

  const [loading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const [modalOpen, setModalOpen] = useState(null);

  const [canCreateNewReport] = useActions([reportActions.canCreateNewReport]);

  const reportGroupId = useMemo(
    () => topFilters?.find(el => el.name === 'report_group_id')?.value,
    [topFilters]
  );

  useEffect(() => {
    if (isOnBoard && groups?.length) {
      const init = async group => {
        try {
          setLoading(true);

          const groupId = group?.id;

          const { can_create } = await canCreateNewReport({
            report_group_id: groupId,
            type: 'system_running_hours'
          });

          setCanCreate(can_create);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setCanCreate(true);
        }
      };

      const currentGroup =
        groups.find(el => +el.id === +reportGroupId) || groups.find(el => el.is_default);

      init(currentGroup);
    }
  }, [isOnBoard, groups, reportGroupId, canCreateNewReport]);

  return (
    <>
      <div
        className={`btn btn-primary page-layout--table-action type-create ms-2 ${
          loading || !canCreate ? 'disabled' : ''
        }`}
        onClick={() => (!loading && canCreate ? setModalOpen(true) : {})}
        id="create-report-button"
      >
        Create Report
      </div>
      {!canCreate ? (
        <Tooltip target="create-report-button" placement="bottom">
          You already have a draft System Running Hours report, you cannot create a new one
        </Tooltip>
      ) : null}

      <CreateReport topFilters={topFilters} modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default CreateReportAction;
