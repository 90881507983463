import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import * as crewDocumentsActions from 'store/crew/documents/actions';

import { Row, Col } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import Information from 'common/components/general/Information';
import HistoryLoader from './HistoryLoader';

import calendar from 'common/assets/svg/common/calendar.svg';
import comments from 'common/assets/svg/common/comments.svg';

import moment from 'moment';
import { dateToLocal } from 'common/utils/dates';

import _get from 'lodash/get';

const OldDocument = ({ isDownloading, downloadAttachments, data }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <Row className={`app-table--body--row text-violet`}>
      <Col className="cell ms-4">
        <Row className="align-items-center flex-nowrap g-0 text-violet">
          <Col xs={6} />
          <Col xs={'auto'} className="p-0">
            <div className="small-seperator"></div>
          </Col>
          <Col>{_get(data, 'code', '-')}</Col>
          {data.comments ? (
            <Col xs="auto">
              <Information
                target={`crew-doc-comments-${data.id}`}
                svgIcon={comments}
                svgStyle={{ width: 16, height: 14 }}
                className="text-primary"
                message={data.comments}
              />
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col xs={2} className="cell">
        <div className="d-flex align-items-center lh-1 text-violet">
          {data.issued_at ? (
            <>
              <span className="text-nowrap">{moment(data.issued_at).format('DD/MM/YYYY')}</span>
              <div className="small-seperator text-violet"></div>
              <span>{data.country ? data.country.name : '-'}</span>
            </>
          ) : (
            '-'
          )}
        </div>
      </Col>
      <Col xs={1} className="cell">
        {data.expires_at ? (
          <div className="d-flex align-items-center lh-1 text-violet" dataCy="expires-at">
            <span>{moment(data.expires_at).format('DD/MM/YYYY')}</span>
          </div>
        ) : null}
      </Col>
      <Col xs={1} className="cell">
        <div className="d-flex align-items-center lh-1 text-violet">
          <span>{dateToLocal(data.created_at).format('DD/MM/YYYY')}</span>
        </div>
      </Col>
      <Col className="cell app-table--body--row__attachments col-auto">
        {(data.attachments || []).length ? (
          <DownloadAttachment
            isDisabled={isDownloading}
            downloadFileName={(_get(data, 'name.name') || '').split(' ').join('_')}
            data={data}
            downloadFiles={() => downloadAttachments(data)}
            hasAttachments={true}
          />
        ) : null}
      </Col>
      {!isOnBoard && <Col className="cell app-table--body--row__actions text-center" />}
    </Row>
  );
};

const HistoryRowCollapsable = ({ crewId, type, isDownloading, downloadAttachments }) => {
  const [documentHistory, setDocumentHistory] = useState([]);
  const [getCrewDocumentHistory] = useActions([crewDocumentsActions.getCrewDocumentHistory]);

  useEffect(() => {
    const getDocumentHistory = async () => {
      try {
        const oldDocuments = await getCrewDocumentHistory({ crewId, id: type.id });
        setDocumentHistory(oldDocuments);
      } catch {
        setDocumentHistory([]);
      }
    };

    getDocumentHistory();
  }, []);

  return documentHistory.length ? (
    documentHistory.map(oldDocument => (
      <OldDocument
        key={oldDocument.id}
        isDownloading={isDownloading}
        downloadAttachments={downloadAttachments}
        data={oldDocument}
      />
    ))
  ) : (
    <HistoryLoader />
  );
};

export default HistoryRowCollapsable;
