import React, { useEffect } from 'react';
import SidebarLayout from 'common/components/layouts/sidebar-layout';
import SideContent from './side-content';
import MainContent from './main-content';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading } from 'events/store/events/selectors';
import { parseQueryParams } from 'common/utils/urls';
import useRouter from 'use-react-router';
import { changeEventValue, getEvent, setIsEditingModules } from 'events/store/events/actions';
import { fetchListOptions } from 'store/lists/actions';

const Event = ({ vessel }) => {
  const dispatch = useDispatch();
  const { match, history } = useRouter();

  const loading = useSelector(selectLoading);

  useEffect(() => {
    const eventId = match.params?.id;

    if (eventId) {
      dispatch(getEvent({ id: eventId }));
    } else {
      dispatch(setIsEditingModules(true));

      if (history.location.search) {
        const { started_at } = parseQueryParams(history.location.search);

        if (started_at) dispatch(changeEventValue({ dates: [{ started_at }] }));
      }
    }

    dispatch(fetchListOptions('event-modules'));
  }, [dispatch, history.location.search, match.params?.id]);

  return (
    <SidebarLayout
      loading={loading}
      sidebarClassName="event__sidebar "
      className="event"
      sidebarComponent={<SideContent vessel={vessel} />}
      mainComponent={<MainContent />}
    />
  );
};

export default Event;
