import { FC } from 'react';
import loader from '@/ts-common/assets/svg/common/loader.svg';
import styled from '@emotion/styled';

type PageLoaderProps = {
  isLoading: boolean;
};

const PageLoader: FC<PageLoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <Container>
      <img alt="page-loader" src={loader} width={48} height={48} />
    </Container>
  ) : null;
};

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  user-select: none;
`;

export default PageLoader;
