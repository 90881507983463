import React from 'react';
import PropTypes from 'prop-types';
import { getMinutesHoursOrDays } from 'common/utils/dates';
import styled from '@emotion/styled';
import Tag from './Tag';

const NewsBox = ({ size, post, className, type, onClick }) => {
  return (
    <NewsBoxContainer
      onClick={onClick}
      className={`news-box d-flex flex-column px-3 py-2 ${size} ${className} ${
        type === 'announcements'
          ? 'text-white bg-current-color fs-14'
          : 'bg-white text-current-color fs-16'
      }`}
    >
      <div className={`d-flex flex-column ${type === 'announcements' ? 'h-100p' : ''}`}>
        <span className="title fw-bold text-current-color overflow-hidden">{post?.name}</span>
        <span
          className={`text-violet fs-12 fw-medium ${type === 'announcements' ? 'mt-auto' : 'mt-1'}`}
        >
          {getMinutesHoursOrDays(post?.created_at)} ago
        </span>
      </div>

      {type !== 'announcements' ? (
        <div className="d-flex mt-auto">
          {(post.tags || [])?.map(tag => (
            <Tag key={tag.id} name={tag.name} />
          ))}
        </div>
      ) : null}
    </NewsBoxContainer>
  );
};

const NewsBoxContainer = styled.div`
  box-shadow: 4px 14px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 13px;

  &:hover {
    box-shadow: none;
    cursor: pointer;
  }

  &.md {
    height: 6.875rem;
    width: 18.375rem;
  }

  &.lg {
    height: 8.75rem;
    width: 27.5rem;
  }

  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

NewsBox.propTypes = {
  size: PropTypes.oneOf(['md', 'lg']),
  post: PropTypes.shape({
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    )
  }),
  className: PropTypes.string,
  type: PropTypes.oneOf(['posts-without-image', 'announcements']),
  onClick: PropTypes.func
};

export default NewsBox;
