import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_ITINERARIES_SCHEDULED_PORTS'),
  ...asyncTypes('GET_ITINERARIES_REAL_PORTS'),
  ...asyncTypes('CREATE_ITINERARY'),
  ...asyncTypes('EDIT_ITINERARY'),
  ...asyncTypes('GET_ITINERARY'),
  ...asyncTypes('DELETE_SCHEDULED_ITINERARY'),
  ...asyncTypes('MATCH_ITINERARY_PORT'),
  ...asyncTypes('REORDER_ITINERARIES_SCHEDULED_PORTS'),
  ...asyncTypes('CALCULATE_ITINERARY_TRIP_MILES'),
  ...asyncTypes('GET_CARGO_PORTS'),
  ...asyncTypes('EVALUATE_ITINERARY_PORT_AGENT'),
  SET_ACTIVE_FORM_PORT: 'SET_ACTIVE_FORM_PORT',
  SET_ACTIVE_ITINERARY_FORM: 'SET_ACTIVE_ITINERARY_FORM',
  SET_ITINERARIES_REORDER_MODE: 'SET_ITINERARIES_REORDER_MODE',
  UPDATE_ITINERARIES_VOYAGE_NUMBER: 'UPDATE_ITINERARIES_VOYAGE_NUMBER',
  GET_ITINERARIES_PORTS_FIRST_ARRIVAL_REPORT: 'GET_ITINERARIES_PORTS_FIRST_ARRIVAL_REPORT'
};

export default TYPES;
