import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_MANUAL'),
  ...asyncTypes('GET_MANUAL_CHAPTERS'),
  ...asyncTypes('GET_CHAPTER_POST'),
  ...asyncTypes('SEARCH_IN_MANUAL_CHAPTERS'),
  ...asyncTypes('COMPARE_VERSION_CHAPTER_POSTS'),
  ...asyncTypes('COMPARE_VERSIONS_CHAPTERS'),
  ...asyncTypes('COMPARE_MANUAL_VERSIONS'),
  ...asyncTypes('ACKNOWLEDGE_MANUAL_VERSION'),
  ...asyncTypes('DOWNLOAD_MANUAL_UPLOADED_FILES'),
  ...asyncTypes('DOWNLOAD_MANUAL_ATTACHMENTS')
};

export default TYPES;
