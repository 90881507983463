import moment from 'moment';
import {
  stringField,
  numberField,
  optionField,
  hiddenField,
  booleanField,
  dateField
} from 'common/utils/form/fieldTypes';

import EditorInput from 'common/components/input-table/components/fields/inputs/EditorInput';
import DescriptionHeader from 'common/components/input-table/components/fields/headers/DescriptionHeader';
import RiskHeader from 'common/components/input-table/components/fields/headers/RiskHeader';
import RiskInput from 'common/components/input-table/components/fields/inputs/RiskInput';
import RemoveRowButton from 'common/components/input-table/components/RemoveRowButton';
import TextInput from 'common/components/input-table/components/fields/inputs/TextInput';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import DateTimePicker from 'captain-reports/templates/port-statement/components/DateTimePicker';

export const riskAssessmentSubmissionConfig = {
  name: stringField(),
  category: optionField({ required: true }),
  template_id: hiddenField(),
  id: hiddenField(),

  primary_hazards: {
    id: hiddenField(),
    description: stringField({ required: true }),
    sort_index: numberField(),
    hazard_risk_severity: numberField({ required: true }),
    hazard_risk_likelihood: numberField({ required: true }),
    control_measure: stringField(),
    control_measure_risk_severity: numberField(),
    control_measure_risk_likelihood: numberField(),
    preview: hiddenField({ initialValue: false })
  },

  additional_hazards: {
    id: hiddenField(),
    description: stringField({ required: true }),
    sort_index: numberField(),
    hazard_risk_severity: numberField({ required: true }),
    hazard_risk_likelihood: numberField({ required: true }),
    control_measure: stringField({ required: true }),
    control_measure_risk_severity: numberField({ required: true }),
    control_measure_risk_likelihood: numberField({ required: true }),
    preview: hiddenField({ initialValue: false })
  },

  contingencies: {
    id: hiddenField(),
    description: stringField({ required: true }),
    sort_index: numberField(),
    safe_guards: stringField({ required: true }),
    preview: hiddenField({ initialValue: false })
  },

  control_measures: {
    id: hiddenField(),
    sort_index: numberField(),
    description: stringField({ required: true }),
    control_measure_risk_severity: numberField({ required: true }),
    control_measure_risk_likelihood: numberField({ required: true }),
    department_id: optionField({ required: true }),
    target_date: dateField({ required: true }),
    verified_by: stringField({ required: true }),
    preview: hiddenField({ initialValue: false })
  }
};

export const hazardsTableConfig = {
  title: 'SUMMARY OF SPECIFIC TO THE TASK HAZARDS IDENTIFIED AND CONTROL MEASURES TO BE TAKEN',
  addMoreButtonLabel: 'Add Hazard',
  columns: [
    {
      key: 'no',
      headerComponent: () => <strong className="fs-12 text-primary">NO.</strong>,
      cellComponent: ({ index }) => (
        <strong className="numbering fs-9 text-white bg-primary rounded-sm">{index + 1}</strong>
      ),
      previewCellComponent: ({ index }) => (
        <strong className="numbering fs-9 text-white bg-primary rounded-sm">{index + 1}</strong>
      ),
      width: 48
    },

    {
      key: 'description',
      headerComponent: () => (
        <DescriptionHeader
          title="HAZARD DESCRIPTION"
          subtitle="Assume NO CONTROLS to initially assess risks"
        />
      ),
      cellComponent: ({ fields, selectField }) => (
        <EditorInput fields={fields} selectField={selectField} fieldKey="description" />
      ),
      previewCellComponent: ({ fields, selectField }) => (
        <div
          className="fs-12 text-primary text-break redactor-in"
          dangerouslySetInnerHTML={{ __html: fields.description.value }}
        ></div>
      ),
      width: 337
    },
    {
      key: 'risk_rating',
      headerComponent: () => <RiskHeader type="rating" />,
      cellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="hazard_risk_severity"
          likelihoodKey="hazard_risk_likelihood"
        />
      ),
      previewCellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="hazard_risk_severity"
          likelihoodKey="hazard_risk_likelihood"
          editMode={false}
        />
      ),
      width: 180
    },
    {
      key: 'risk_rating_overview',
      headerComponent: () => <RiskHeader />,
      cellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="hazard_risk_severity"
          likelihoodKey="hazard_risk_likelihood"
          viewMode={true}
        />
      ),
      previewCellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="hazard_risk_severity"
          likelihoodKey="hazard_risk_likelihood"
          viewMode={true}
          editMode={false}
        />
      ),
      width: 100
    },
    {
      key: 'control_measure',
      headerComponent: () => (
        <DescriptionHeader
          title="CONTROL MEASURES TO BE TAKEN"
          subtitle="To reduce the risk and calculate the residual / FINAL Risk Rating"
        />
      ),
      cellComponent: ({ fields, selectField }) => (
        <EditorInput fields={fields} selectField={selectField} fieldKey="control_measure" />
      ),
      previewCellComponent: ({ fields, selectField }) => (
        <div
          className="fs-12 text-primary text-break redactor-in"
          dangerouslySetInnerHTML={{ __html: fields.control_measure.value }}
        ></div>
      ),
      width: 465
    },
    {
      key: 'measures_risk_rating',
      headerComponent: () => <RiskHeader type="rating" />,
      cellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="control_measure_risk_severity"
          likelihoodKey="control_measure_risk_likelihood"
        />
      ),
      previewCellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="control_measure_risk_severity"
          likelihoodKey="control_measure_risk_likelihood"
          editMode={false}
        />
      ),
      width: 180
    },
    {
      key: 'risk_rating_overview',
      headerComponent: () => <RiskHeader />,
      cellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="control_measure_risk_severity"
          likelihoodKey="control_measure_risk_likelihood"
          viewMode={true}
        />
      ),
      previewCellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="control_measure_risk_severity"
          likelihoodKey="control_measure_risk_likelihood"
          viewMode={true}
          editMode={false}
        />
      ),
      width: 100
    },
    {
      key: 'remove_row',
      isAction: true,
      headerComponent: () => <></>,
      cellComponent: ({ removeRow }) => <RemoveRowButton onRemoveRow={removeRow} />,
      previewCellComponent: ({ removeRow }) => <RemoveRowButton onRemoveRow={removeRow} />,
      width: 50
    }
  ]
};

export const controlMeasuresTableconfig = {
  title:
    'ACTION PLAN FOR ADDITIONAL CONTROL MEASURES TO BE TAKEN WHEN A FINAL RISK IS OUT OF THE ACCEPTABLE LIMITS',
  addMoreButtonLabel: 'Add Control Measure',
  columns: [
    {
      key: 'no',
      headerComponent: () => <strong className="fs-12 text-primary">NO.</strong>,
      cellComponent: ({ index }) => (
        <strong className="numbering fs-9 text-white bg-primary rounded-sm">{index + 1}</strong>
      ),
      previewCellComponent: ({ index }) => (
        <strong className="numbering fs-9 text-white bg-primary rounded-sm">{index + 1}</strong>
      ),
      width: 48
    },
    {
      key: 'description',
      headerComponent: () => <strong className="fs-12 text-primary">CONTROL MEASURES</strong>,
      cellComponent: ({ fields, changeField }) => (
        <TextInput
          placeholder="Add Description"
          fields={fields}
          changeField={changeField}
          fieldKey="description"
        />
      ),
      previewCellComponent: ({ fields }) => (
        <div className="text-break fs-12 text-primary">{fields.description.value}</div>
      ),
      width: 553
    },
    {
      key: 'risk_rating_overview',
      headerComponent: () => <RiskHeader type="rating" />,
      cellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="control_measure_risk_severity"
          likelihoodKey="control_measure_risk_likelihood"
        />
      ),
      previewCellComponent: ({ fields, changeField }) => (
        <RiskInput
          fields={fields}
          changeField={changeField}
          severityKey="control_measure_risk_severity"
          likelihoodKey="control_measure_risk_likelihood"
          editMode={false}
        />
      ),
      width: 180
    },
    {
      key: 'department_id',
      headerComponent: () => <strong className="fs-12 text-primary">RESPONSIBLE DEPT.</strong>,
      cellComponent: ({ fields, selectField }) => (
        <DepartmentSelector
          placeholder="Select Department"
          value={fields.department_id.value || []}
          onChange={selected => selectField('department_id')(selected || [])}
          invisible={false}
          white={false}
          showMore={true}
          isMulti={false}
          {...fields.department_id}
        />
      ),
      previewCellComponent: ({ fields, selectField }) => (
        <DepartmentSelector
          className="department-preview-input"
          placeholder="Select Department"
          value={fields.department_id.value || []}
          onChange={selected => selectField('department_id')(selected || [])}
          invisible={true}
          white={false}
          showMore={true}
          isMulti={false}
          disabled={true}
          {...fields.department_id}
        />
      ),
      width: 200
    },
    {
      key: 'target_date',
      headerComponent: () => <strong className="fs-12 text-primary">TARGET DATE</strong>,
      cellComponent: ({ fields, selectField }) => (
        <DateTimePicker
          datePlaceholder="Set Date"
          hasTime={false}
          className="mb-0"
          onChange={d => selectField('target_date')(d)}
          {...fields.target_date}
        />
      ),
      previewCellComponent: ({ fields, selectField }) => {
        const date = moment(fields.target_date.value).format('DD/MM/YYYY');
        return <span className="fs-12 text-primary">{date}</span>;
      },
      width: 180
    },
    {
      key: 'verified_by',
      headerComponent: () => <strong className="fs-12 text-primary">VERIFIED BY, DATE</strong>,
      cellComponent: ({ fields, changeField }) => (
        <TextInput
          placeholder="Select dept."
          fields={fields}
          changeField={changeField}
          fieldKey="verified_by"
        />
      ),
      previewCellComponent: ({ fields }) => (
        <div className="text-break fs-12 text-primary">{fields.verified_by.value}</div>
      ),
      width: 230
    },
    {
      key: 'remove_row',
      isAction: true,
      headerComponent: () => <></>,
      cellComponent: ({ removeRow }) => <RemoveRowButton onRemoveRow={removeRow} />,
      previewCellComponent: ({ removeRow }) => <RemoveRowButton onRemoveRow={removeRow} />,
      width: 50
    }
  ]
};

export const contingenciesTableConfig = {
  title: 'CONTINGENCIES',
  addMoreButtonLabel: 'Add Contingency',
  columns: [
    {
      key: 'no',
      headerComponent: () => <strong className="fs-12 text-primary">NO.</strong>,
      cellComponent: ({ index }) => (
        <strong className="numbering fs-9 text-white bg-primary rounded-sm">{index + 1}</strong>
      ),
      previewCellComponent: ({ index }) => (
        <strong className="numbering fs-9 text-white bg-primary rounded-sm">{index + 1}</strong>
      ),
      width: 48
    },
    {
      key: 'description',
      headerComponent: () => (
        <strong className="fs-12 text-primary">CONTINGENCY DESCRIPTION</strong>
      ),
      cellComponent: ({ fields, changeField }) => (
        <TextInput
          placeholder="Add Description"
          fields={fields}
          changeField={changeField}
          fieldKey="description"
        />
      ),
      previewCellComponent: ({ fields }) => (
        <div className="text-break fs-12 text-primary">{fields.description.value}</div>
      ),
      width: 660
    },
    {
      key: 'safeguards',
      headerComponent: () => <strong className="fs-12 text-primary">SAFEGUARDS</strong>,
      cellComponent: ({ fields, changeField }) => (
        <TextInput
          placeholder="Add Safeguard"
          fields={fields}
          changeField={changeField}
          fieldKey="safe_guards"
        />
      ),
      previewCellComponent: ({ fields }) => (
        <div className="text-break fs-12 text-primary">{fields.safe_guards.value}</div>
      ),
      width: 675
    },
    {
      key: 'remove_row',
      isAction: true,
      headerComponent: () => <></>,
      cellComponent: ({ removeRow }) => <RemoveRowButton onRemoveRow={removeRow} />,
      previewCellComponent: ({ removeRow }) => <RemoveRowButton onRemoveRow={removeRow} />,
      width: 50
    }
  ]
};
