import { useSelector } from 'react-redux';
import { selectShiftTypes } from 'crew/store/shifts/selectors';

const ShiftTypesList = ({ className = '', selectedShiftType, onClick, inline }) => {
  const shiftTypes = useSelector(selectShiftTypes);

  return (
    <div
      className={`shifts-type-selector--types ${
        inline ? 'd-flex align-items-center' : ''
      } ${className}`}
    >
      {shiftTypes.map(shiftType => (
        <div
          key={shiftType.id}
          onClick={() => (onClick ? onClick(shiftType) : '')}
          className={`shifts-type-selector--shift-type shifts-type-selector--shift-type__${
            shiftType.color
          }${
            selectedShiftType?.id === shiftType.id
              ? ` shifts-type-selector--shift-type__selected`
              : ''
          }${inline ? ' mb-0 me-3' : ''}`}
        >
          <div className="shifts-type-selector--dot" />
          <span>{shiftType.name}</span>
        </div>
      ))}
    </div>
  );
};

export default ShiftTypesList;
