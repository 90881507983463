import React from 'react';
import PropTypes from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';

import edit from 'common/assets/svg/actions/edit-empty.svg';
import pending from 'common/assets/svg/common/pending-circle.svg';
import history from 'common/assets/svg/common/history.svg';
import check from 'common/assets/svg/common/check-circle.svg';

const VersionStatus = ({ status, title }) => {
  const getColor = () => {
    switch (status) {
      case 'working':
        return 'blue';

      case 'pending_approval':
      case 'pending_release':
        return 'orange';

      case 'active':
        return 'green';

      default:
        return 'violet';
    }
  };

  const getIcon = () => {
    switch (status) {
      case 'working':
        return edit;

      case 'pending_approval':
      case 'pending_release':
        return pending;

      case 'active':
        return check;

      default:
        return history;
    }
  };

  const getContent = () => {
    switch (status) {
      case 'working':
        return 'Working on';

      case 'pending_approval':
        return 'For Approval';

      case 'pending_release':
        return 'For Release';

      case 'active':
        return `Active ${title}`;

      default:
        return `${title}`;
    }
  };

  return (
    <div
      className={`d-inline-flex align-items-center text-${getColor()} manual-version-status fw-medium`}
    >
      <SvgRender src={getIcon()} style={{ width: 14, height: 14 }} className="me-1" />
      {getContent()}
    </div>
  );
};

VersionStatus.propTypes = {
  status: PropTypes.oneOf(['working', 'pending_approval', 'pending_release', 'active', 'past'])
    .isRequired
};

export default VersionStatus;
