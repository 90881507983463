import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { setJobField, updateJobInTable } from 'common/components/jobs/_base/store/actions';

export const getChecklistOptions = params => dispatch => {
  const { job_id } = params;
  dispatch({ type: TYPES.GET_CHECKLIST_OPTIONS.START });

  return get(`/jobs/${job_id}/checklist-options`)
    .then(response => {
      dispatch({
        type: TYPES.GET_CHECKLIST_OPTIONS.SUCCESS,
        payload: response.data.map(el => ({ ...el, comments: [] }))
      });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_CHECKLIST_OPTIONS.ERROR, payload: error }));
};

export const updateChecklistOptions = params => (dispatch, getState) => {
  const { job_id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CHECKLIST_OPTIONS.START });

  return put(`/jobs/${job_id}/checklist-options`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CHECKLIST_OPTIONS.SUCCESS, payload: response.data });

      if (!getState().isOnBoard) {
        const tableData = {
          id: job_id,
          has_checklist_options: rest.checklist_options.length ? true : false
        };

        dispatch(updateJobInTable(tableData));
      }

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_CHECKLIST_OPTIONS.ERROR, payload: error }));
};

export const toggleChecklistOption = params => dispatch => {
  const { job_id, option } = params;
  dispatch({ type: TYPES.TOGGLE_CHECKLIST_OPTION.START });

  return post(`/jobs/${job_id}/checklist-options/${option}/toggle`)
    .then(response => {
      dispatch({ type: TYPES.TOGGLE_CHECKLIST_OPTION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.TOGGLE_CHECKLIST_OPTION.ERROR, payload: error }));
};

export const createComment = params => dispatch => {
  const { job_id, option, ...rest } = params;
  dispatch({ type: TYPES.CREATE_COMMENT.START });

  return post(`/jobs/${job_id}/checklist-options/${option}/comments`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.CREATE_COMMENT.SUCCESS, payload: { data: response.data, option } });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.CREATE_COMMENT.ERROR, payload: error }));
};

export const updateComment = params => dispatch => {
  const { job_id, option, comment, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_COMMENT.START });

  return put(`/jobs/${job_id}/checklist-options/${option}/comments/${comment}`, { ...rest })
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_COMMENT.SUCCESS,
        payload: { data: response.data, option, comment }
      });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_COMMENT.ERROR, payload: error }));
};

export const deleteComment = params => dispatch => {
  const { job_id, option, comment, ...rest } = params;
  dispatch({ type: TYPES.DELETE_COMMENT.START });

  return deleteRequest(`/jobs/${job_id}/checklist-options/${option}/comments/${comment}`, {
    ...rest
  })
    .then(response => {
      dispatch({
        type: TYPES.DELETE_COMMENT.SUCCESS,
        payload: { data: response.data, option, comment }
      });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_COMMENT.ERROR, payload: error }));
};

export const getComments = params => dispatch => {
  const { job_id, option, ...rest } = params;
  dispatch({ type: TYPES.GET_COMMENTS.START });

  return get(`/jobs/${job_id}/checklist-options/${option}/comments`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.GET_COMMENTS.SUCCESS, payload: { data: response.data, option } });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_COMMENTS.ERROR, payload: error }));
};

export const clearChecklist = () => dispatch => {
  dispatch({ type: TYPES.CLEAR_CHECKLIST, payload: [] });
};

export const showLessComments = options => dispatch => {
  dispatch({ type: TYPES.SHOW_LESS_COMMENTS, payload: options });
};

export const setChecklistOptions = options => dispatch => {
  dispatch({ type: TYPES.SET_CHECKLIST_OPTIONS, payload: options });
  dispatch(setJobField('has_checklist_options', options?.length > 0));
};

export const toggleCollapse = id => dispatch => {
  dispatch({ type: TYPES.TOGGLE_COLLAPSE, payload: id });
};
