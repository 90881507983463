import React from 'react';
import useValue from 'captain-reports/utils/useValue';
import { Row, Col } from 'reactstrap';
import * as reportActions from 'captain-reports/store/actions';
import { useActions, useUpdateEffect } from 'utils/hooks';
import {
  selectFieldVisibility,
  selectActiveTab,
  selectDgConsumptionPerGeneratorIndex
} from 'captain-reports/store/selectors';

import { selectLastUpdatedGeneratorTabIndex } from 'captain-reports/store/selectors-ts.ts';
import { useSelector } from 'react-redux';
import { hasValue, numberToStr } from 'common/utils/numbers';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import { setLastUpdatedGeneratorTabIndex } from '@/captain-reports/store/actions.js';
import { useAppDispatch } from '@/store/hooks';

const SfocField = ({ field, sectionLabel, sectionActiveTab, subGroupIndex, subGroup }) => {
  const dispatch = useAppDispatch();
  const [setValue] = useActions([reportActions.setValue]);
  const visible = useSelector(state => selectFieldVisibility(state, field.key));
  const activeTab = useSelector(state => selectActiveTab(state, 'generators'));
  const lastUpdatedGeneratorTabIndexFromBunkers = useSelector(selectLastUpdatedGeneratorTabIndex);
  const activeIndex = hasValue(lastUpdatedGeneratorTabIndexFromBunkers)
    ? lastUpdatedGeneratorTabIndexFromBunkers
    : activeTab;

  const dgConsumptionPerGenerator = useSelector(
    selectDgConsumptionPerGeneratorIndex(activeIndex + 1)
  );

  const value = useValue({
    key: field.key,
    sectionLabel: 'generators',
    sectionActiveTab: activeIndex
  });

  const runningHours = useValue({
    key: 'dg_running_minutes',
    sectionLabel: 'generators',
    sectionActiveTab: activeIndex
  });

  const load = useValue({
    key: 'dg_power',
    sectionLabel: 'generators',
    sectionActiveTab: activeIndex
  });

  useUpdateEffect(() => {
    if (!runningHours || !load) {
      setValue({
        key: field.key,
        value: null,
        sectionLabel: 'generators',
        sectionActiveTab: activeTab
      });
      return;
    }
    let loadFiltered = load ? load : 0;
    let runningHoursFiltered = runningHours ? runningHours : 0;

    // Calculation for dg_sfoc is: dg_consumption_[generator index] (in gramms) /  dg_running_minutes (in hours) / dg_power
    let result =
      (dgConsumptionPerGenerator * 1000 * 1000) / (runningHoursFiltered / 60) / loadFiltered;

    if (value !== +result.toFixed(2)) {
      setValue({
        key: field.key,
        value: +result.toFixed(2),
        sectionLabel: 'generators',
        sectionActiveTab: activeIndex
      });
    }

    // Set the generator index that we set from the bunkers back to null
    dispatch(setLastUpdatedGeneratorTabIndex(null));
  }, [load, runningHours, activeTab, activeIndex, dgConsumptionPerGenerator]);

  if (!visible) return null;

  return (
    <Row className="pe-1">
      <Col xs={8}>
        <ValidationWrapper
          field={field}
          value={value}
          sectionLabel={sectionLabel}
          errorClassName="align-items-center"
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        >
          <div className="fs-12 fw-bold">{numberToStr(value)}</div>
        </ValidationWrapper>
      </Col>
    </Row>
  );
};

export default SfocField;
