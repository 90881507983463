import Editor from 'common/components/form/inputs/Editor';
import { v4 as uuid } from 'uuid';

const TextEditorInput = ({ onChange, value, disabled, error }) => {
  return (
    <Editor
      id={uuid()}
      onChange={html => onChange(html)}
      value={value}
      className="mb-0"
      placeholder="Add some text"
      error={error}
      viewOnly={disabled}
    />
  );
};

export default TextEditorInput;
