import React from 'react';

import { ButtonGroup, Button } from 'reactstrap';

// Deprecated - use NavigationGroupTabs instead
const ButtonGroupTabs = ({ options, onClick, active, equalityCheck, getStyle }) => {
  const isActive = opt => {
    if (equalityCheck) {
      return equalityCheck(active, opt.value);
    }

    return active === opt.value;
  };

  return (
    <ButtonGroup className="d-inline-flex g-0 group-button">
      {options.map((opt, key) => (
        <Button
          color="group-button"
          key={key}
          className={`${isActive(opt) ? 'active' : 'inactive'} ${
            opt.value ? `btn-${opt.value}` : ''
          }`}
          onClick={() => onClick(opt.value)}
          style={getStyle ? getStyle(opt, isActive(opt)) : undefined}
          size={opt.size}
        >
          {opt.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupTabs;
