import TYPES from './types';
import { post, deleteRequest } from 'utils/api';
import { updateJobInTable } from 'common/components/jobs/_base/store/actions';

export const updateJobWatchers = params => dispatch => {
  const { jobId, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_JOB_WATCHERS.START, payload: { params } });

  return post(`/jobs/${jobId}/watch`)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_JOB_WATCHERS.SUCCESS,
        payload: { data: response.data, params }
      });

      const tableData = {
        id: jobId,
        is_watcher: rest.is_watcher
      };

      dispatch(updateJobInTable(tableData));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_JOB_WATCHERS.ERROR, payload: error }));
};

export const deleteJobWatchers = params => dispatch => {
  const { jobId, ...rest } = params;
  dispatch({ type: TYPES.DELETE_JOB_WATCHERS.START, payload: { params } });

  return deleteRequest(`/jobs/${jobId}/watch`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_JOB_WATCHERS.SUCCESS, payload: response.data });

      const tableData = {
        id: jobId,
        is_watcher: rest.is_watcher
      };

      dispatch(updateJobInTable(tableData));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.DELETE_JOB_WATCHERS.ERROR, payload: error }));
};
