import paths from 'routing/routes/_paths';
import _isObject from 'lodash/isObject';

const titleMap = {
  [paths.persons]: { prefix: "Person's", suffix: 'Profile ' },
  [paths.vessels]: { prefix: 'Vessel', suffix: 'Profile ' },
  [paths.companies]: { prefix: 'Company', suffix: 'Profile ' },
  [paths.crew]: { prefix: 'Crew', suffix: 'Profile ' },
  [paths.ports]: { prefix: 'Port', suffix: 'Profile ' },
  [paths.purchasing_pricelist]: { prefix: 'Purchasing', suffix: 'Pricelist ' },
  [paths.forms]: { prefix: 'Form' },
  [paths.events]: { prefix: 'Event' },
  [paths.jobs]: { prefix: 'Job' },
  [paths.projects]: { prefix: 'Project' },
  [paths.estimations]: { prefix: 'Estimation' },
  [paths.captain_reports]: { prefix: 'Report' },
  [paths.pms_running_hours]: { prefix: 'Report' },
  [paths.voyages]: { prefix: 'Itinerary' },
  voyage_charter_parties: { prefix: 'Charterer' },
  purchasing_requisitions: { prefix: 'Purchasing', suffix: 'Requisition ' },
  purchasing_requisitions_templates: { prefix: 'Template' }
};

const getMapping = path => {
  const pathMappings = path.split('/').filter(Boolean);
  const basePath = `/${pathMappings[0]}`;

  if (basePath.startsWith(paths.voyages) && pathMappings.includes('charter-parties')) {
    return titleMap['voyage_charter_parties'];
  } else if (basePath.startsWith(paths.purchasing) && pathMappings.includes('requisitions')) {
    return titleMap['purchasing_requisitions'];
  } else if (
    basePath.startsWith(paths.purchasing) &&
    pathMappings.includes('requisitions-templates')
  ) {
    return titleMap['purchasing_requisitions_templates'];
  } else {
    return titleMap[basePath];
  }
};

export const constructTooltipMessage = path => {
  if (!path) return;

  const titleObj = getMapping(_isObject(path) ? path?.pathname : path);

  return `Opens${titleObj?.prefix ? ` the ${titleObj?.prefix}` : ''} ${
    titleObj?.suffix || ''
  }in a new tab`;
};
