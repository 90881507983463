/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import { useActions } from 'utils/hooks';
import { Row, Col } from 'reactstrap';

import useValue from 'captain-reports/utils/useValue';
import AdjustmentField from 'captain-reports/templates/components/custom-fields/AdjustmentField';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import * as reportActions from 'captain-reports/store/actions';
import _debounce from 'lodash/debounce';

import numeral from 'numeral';

const TotalConsumptionField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue] = useActions([reportActions.setValue]);

  const me_consumption_total = useValue({
    key: 'me_consumption_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const dg_consumption_total = useValue({
    key: 'dg_consumption_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const boiler_consumption_total = useValue({
    key: 'boiler_consumption_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const incinerator_consumption_total = useValue({
    key: 'incinerator_consumption_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const aux_consumption_total = useValue({
    key: 'aux_consumption_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const cii_reduction_boiler_for_cargo_cons_total = useValue({
    key: 'cii_reduction_boiler_for_cargo_cons_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const cii_reduction_other_for_cargo_cons_total = useValue({
    key: 'cii_reduction_other_for_cargo_cons_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const debouncedAutoComplete = _debounce(total => {
    setValue({
      key: field.key,
      value: total,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  }, 600);

  useEffect(() => {
    const fieldsArr = [
      me_consumption_total,
      dg_consumption_total,
      boiler_consumption_total,
      incinerator_consumption_total,
      aux_consumption_total,
      cii_reduction_boiler_for_cargo_cons_total,
      cii_reduction_other_for_cargo_cons_total
    ];
    const total = fieldsArr.reduce((acc, cur) => {
      const number = numeral(acc);
      return number.add(strToNumber(cur) || 0);
    }, 0)._value;

    if (total !== value) {
      if (total) {
        debouncedAutoComplete(total);
      } else {
        if (fieldsArr.every(el => el === null)) {
          debouncedAutoComplete(null);
        } else {
          debouncedAutoComplete(total);
        }
      }
    }
  }, [
    me_consumption_total,
    dg_consumption_total,
    boiler_consumption_total,
    incinerator_consumption_total,
    aux_consumption_total,
    cii_reduction_boiler_for_cargo_cons_total,
    cii_reduction_other_for_cargo_cons_total
  ]);

  return (
    <Row className="align-items-center fw-bold fs-12 lh-1 text-end height-24">
      <Col xs={7}>
        <ValidationWrapper
          field={{ key: field.key, label: null }}
          value={value}
          className={'align-items-center'}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
          right={true}
        >
          {value || value === 0 ? numberToStr(value) : '-'}
        </ValidationWrapper>
      </Col>
      <Col xs={5}>
        <AdjustmentField
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </Col>
    </Row>
  );
};

export default TotalConsumptionField;
