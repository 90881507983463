import React, { useState } from 'react';

import { download } from 'utils/api';
import { handleBlobDownload } from 'common/utils/downloads';
import ExportPdf from 'common/components/general/ExportPdf';
import { Col } from 'reactstrap';

const DownloadFormButton = ({
  xs,
  className = '',
  formSubmission,
  showLabel = true,
  disableDownloadButton
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadPdf = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    try {
      const file = await download(`/form-submissions/${formSubmission.id}/pdf`);
      const type = file.headers['content-type'];

      const blob = new Blob([file.data], { type });
      handleBlobDownload({ filename: `${formSubmission?.form?.name || 'export'}.pdf` }, blob);
    } catch (e) {
      console.log(e);
    }
    setIsDownloading(false);
  };

  return (
    <Col xs={xs} className={className}>
      <ExportPdf
        showLabel={showLabel}
        onClick={downloadPdf}
        type="pdfSquare"
        svgStyle={{ width: 11, height: 10 }}
        disabled={disableDownloadButton || isDownloading}
      />
    </Col>
  );
};

export default DownloadFormButton;
