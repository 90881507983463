import { FC } from 'react';

import { Button } from 'reactstrap';
import SvgRender from '@/ts-common/components/general/SvgRender';

type LinkButtonProps = {
  className?: string;
  disabled: boolean;
  onClick: () => void;
  image: string;
  text: string;
};

const LinkButton: FC<LinkButtonProps> = ({ className = '', disabled, onClick, image, text }) => {
  return (
    <Button
      type="button"
      color="white"
      className={`d-flex align-items-center h-24 cpx-12 fs-12 ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <SvgRender
        className={`${disabled ? 'text-granite' : 'text-primary'} me-1`}
        src={image}
        style={{ width: 15, height: 15 }}
      />
      <span className={`${disabled ? 'text-granite' : 'text-primary'}`}>{text}</span>
    </Button>
  );
};

export default LinkButton;
