import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

/* Folders Actions */
export const getFolders = params => dispatch => {
  dispatch({ type: TYPES.GET_FOLDERS.START, payload: { params } });

  return get(`/directories`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_FOLDERS.SUCCESS,
        payload: { data: response.data, params }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FOLDERS.ERROR, payload: error });

      throw error;
    });
};

export const createFolder = params => dispatch => {
  dispatch({ type: TYPES.CREATE_FOLDER.START, payload: { params } });

  return post(`/directories`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_FOLDER.SUCCESS, payload: response.data });
      dispatch(getFolders());
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_FOLDER.ERROR, payload: error });

      throw error;
    });
};

export const editFolder = params => dispatch => {
  dispatch({ type: TYPES.EDIT_FOLDER.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/directories/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_FOLDER.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_FOLDER.ERROR, payload: error });

      throw error;
    });
};

export const deleteFolder = params => (dispatch, getState) => {
  dispatch({ type: TYPES.DELETE_FOLDER.START, payload: { params } });
  const { id, ...rest } = params;

  return deleteRequest(`/directories/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_FOLDER.SUCCESS, payload: params });
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));
      dispatch(getFolders({ search: getState().filesystem.treeSearch || undefined }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_FOLDER.ERROR, payload: error });

      throw error;
    });
};

export const getFolder = params => dispatch => {
  dispatch({ type: TYPES.GET_FOLDER.START, payload: { params } });
  const { id } = params;

  return get(`/directories/${id}`)
    .then(response => {
      dispatch({ type: TYPES.GET_FOLDER.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FOLDER.ERROR, payload: error });

      throw error;
    });
};

export const addFolderFiles = params => dispatch => {
  const { id, file_ids, files } = params;
  dispatch({ type: TYPES.ADD_FOLDER_FILES.START, payload: { id, files } });

  return post(`/directories/${id}/files`, { file_ids })
    .then(response => {
      dispatch({ type: TYPES.ADD_FOLDER_FILES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.ADD_FOLDER_FILES.ERROR, payload: error });
      dispatch(getFolder({ id }));

      throw error;
    });
};

export const deleteFolderFile = params => dispatch => {
  const { id, file_id } = params;
  dispatch({ type: TYPES.DELETE_FOLDER_FILE.START, payload: { file_id } });

  return deleteRequest(`/directories/${id}/files/${file_id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_FOLDER_FILE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_FOLDER_FILE.ERROR, payload: error });
      dispatch(getFolder({ id }));

      throw error;
    });
};

export const setIsCreatingFolder = isCreating => dispatch => {
  dispatch({ type: TYPES.SET_IS_CREATING_FOLDER, payload: isCreating });
};

export const setIsEditingFolder = (id, isEditing) => dispatch => {
  dispatch({ type: TYPES.SET_IS_EDITING_FOLDER, payload: { [id]: isEditing } });
};

export const setIsAddingSubFolder = (id, isAdding) => dispatch => {
  dispatch({ type: TYPES.SET_IS_ADDING_SUBFOLDER, payload: { [id]: isAdding } });
};

/* Expand Folder */
export const expandFolder = (id, isOpen) => dispatch => {
  dispatch({ type: TYPES.EXPAND_FOLDER, payload: { id, isOpen } });
};

export const toggleAllFolders = areOpen => dispatch => {
  dispatch({ type: TYPES.TOGGLE_ALL_FOLDERS, payload: areOpen });
};
/* -- */

/* Search */
export const setTreeSearch = search => dispatch => {
  dispatch({ type: TYPES.SET_TREE_SEARCH, payload: { search } });
};
/* -- */

/* ActiveFolder */
export const setActiveFolderID = id => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_FOLDER_ID, payload: id ? parseInt(id) : null });
};
/* -- */

export const setPreventionModal = (isOpen = false, id = null) => dispatch => {
  dispatch({ type: TYPES.SET_PREVENTION_MODAL, payload: { isOpen, id } });
};
