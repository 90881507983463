import React from 'react';
import PropTypes from 'prop-types';

import Drawer, { DrawerHeader, FormDrawer, FormBody, FormFooter } from 'common/components/drawer';
import Spinner from 'common/components/general/Spinner';

import { Row, Col } from 'reactstrap';

import _isString from 'lodash/isString';

const ProfileDrawer = ({
  className = '',
  drawer,
  leftSide,
  rightSide,
  children,
  header,
  footer,
  isRightSideExpanded,
  isLoading,
  headerComponent = null,
  ...rest
}) => {
  return (
    <Drawer
      {...drawer}
      size="lg"
      className={`profile-drawer ${className}`}
      dontCloseOnClickOutside={true}
      {...rest}
    >
      <DrawerHeader className={header && _isString(header) ? 'd-flex align-items-center' : ''}>
        {header}
      </DrawerHeader>
      <FormDrawer>
        <FormBody>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {headerComponent ? headerComponent : null}
              <Row className="profile-drawer__layout flex-nowrap" noGutters>
                {leftSide ? (
                  <Col className="profile-drawer__content profile-drawer__content--left" xs={4}>
                    {leftSide}
                  </Col>
                ) : null}
                {!isRightSideExpanded ? (
                  <Col className="profile-drawer__content profile-drawer__content--main flex-1 overflow-hidden">
                    {children}
                  </Col>
                ) : null}

                {rightSide ? (
                  <Col
                    className="profile-drawer__content profile-drawer__content--right"
                    xs={isRightSideExpanded ? '' : 'auto'}
                  >
                    {rightSide}
                  </Col>
                ) : null}
              </Row>
            </>
          )}
        </FormBody>

        {footer && <FormFooter>{footer}</FormFooter>}
      </FormDrawer>
    </Drawer>
  );
};

ProfileDrawer.propTypes = {
  className: PropTypes.string,
  drawer: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func,
    open: PropTypes.func
  }),
  leftSide: PropTypes.node,
  rightSide: PropTypes.node,
  children: PropTypes.node,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  footer: PropTypes.node,
  isRightSideExpanded: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default ProfileDrawer;
