import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 750 150"
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    animate={true}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
    <rect x="0" y="70" rx="5" ry="5" width="100%" height="60" />
  </ContentLoader>
);

export default Loader;
