import _isObject from 'lodash/isObject';
import { strToNumber } from 'common/utils/numbers';
import { getEntityGroup } from 'common/components/inventory/store/helpers';
import _get from 'lodash/get';

export const containedInSearch = (search, { title, code }) => {
  if (search && (title || code)) {
    const searchTerm = search.toLowerCase();

    return (
      (!_isObject(title) && !!title && title.toLowerCase().includes(searchTerm)) ||
      (!_isObject(code) && !!code && code.toString().toLowerCase().includes(searchTerm))
    );
  }

  return false;
};

export const getLatestSurveyFormInitialState = (items, entityType) => {
  if (entityType === 'store') {
    return {
      items: items?.map(entity => ({
        group: getEntityGroup(entity),
        unit: _get(entity, 'item.purchasing_unit.notation', ''),
        id: entity.item_id,
        entity_type: entityType,
        title: _get(entity, 'item.description', ''),
        code: _get(entity, 'item.code', ''),
        previous_rob: strToNumber(_get(entity, 'last_survey_rob', '')),
        last_survery_timestamp: _get(entity, 'last_survey_timestamp', '')
      })),
      spare_parts: []
    };
  } else {
    return {
      items: [],
      spare_parts: items?.map(entity => ({
        group: getEntityGroup(entity),
        previous_rob: strToNumber(_get(entity, 'last_survey_rob', '')),
        id: entity.item_id,
        entity_type: entityType,
        title: _get(entity, 'item.description', ''),
        part_no: _get(entity, 'item.part_no', ''),
        drawing_no: _get(entity, 'item.drawing_no', ''),
        unit: _get(entity, 'item.unit.name', ''),
        last_survery_timestamp: _get(entity, 'last_survey_timestamp', '')
      }))
    };
  }
};

export const surveyFormHasUnsavedChanges = (state, entityType) => {
  if (entityType === 'spare_part') {
    return state.some(
      ({ state }) =>
        state.location.value ||
        state.quantity_new.value ||
        state.quantity_used.value ||
        state.quantity_in_repair.value ||
        state.quantity.value
    );
  } else {
    return state.some(({ state }) => state.quantity.value);
  }
};
