import React from 'react';

const Group = ({ group, className }) => {
  return group && group.id ? (
    <div
      style={{ backgroundColor: `${group.color}1a` }}
      className={` ${
        className
          ? className
          : 'd-flex align-items-center fs-12 fw-bold cpe-12 cps-12 border-radius-3  py-1'
      }`}
    >
      <span style={{ color: group.color }} className="text-uppercase">
        {group.label}
      </span>
    </div>
  ) : null;
};

export default Group;
