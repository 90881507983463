import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';
import CopyText from 'common/components/general/CopyText';
import copy from 'common/assets/svg/actions/copy.svg';
import { capitalizeFirst } from 'common/utils/texts';
import { ItineraryAgent } from 'common/entities/itineraries/ItineraryTypes';

const CopyAgentDetails = ({ agent }) => {
  const getAgentInfo = () => {
    let info = `${_get(agent, 'party.company_name', '') || ''}\n`;

    if (agent?.party?.address) info += `${agent?.party?.address}\n`;
    if (agent?.party?.city)
      info += `${agent?.party?.city}, ${agent?.party?.state ? `${agent?.party?.state} ` : ''}${
        agent?.party?.postal_code || ''
      } \n`;
    if (agent?.party?.country?.name) info += `${agent?.party?.country?.name}\n`;
    if (agent?.party?.email) info += `Primary Email: ${agent?.party?.email}\n`;
    if (agent?.party?.emails?.length)
      info += `${agent?.party?.emails
        .map(({ type, value }) => `${capitalizeFirst(type)}: ${value}`)
        .join('\n')}\n`;
    if (agent?.party?.phone) info += `Primary Phone: ${agent?.party?.phone}\n`;
    if (agent?.party?.emails?.length)
      info += agent?.party?.extra_phones
        .map(({ type, value }) => `${capitalizeFirst(type)}: ${value}`)
        .join('\n');
    if (agent?.party?.first_employee)
      info += `Representative: ${agent.party.first_employee.full_name}\n`;

    return info;
  };

  return (
    <CopyText
      text={getAgentInfo()}
      customText="Copied Details to Clipboard"
      infoTooltip="Copy Contact Details to Clipboard"
    >
      <SvgRender
        className="pointer-events-none text-primary"
        src={copy}
        style={{ height: 12, width: 12 }}
      />
    </CopyText>
  );
};

CopyAgentDetails.propTypes = {
  agent: ItineraryAgent.isRequired
};

export default CopyAgentDetails;
