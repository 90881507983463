import { get, put } from '@/api';
import { PaginatedTableQuery, TableRequestPaging } from '@/common/types/front-entities/table';
import {
  VesselSystem,
  VesselSystemAssignment,
  VesselSystemMaintenanceJob
} from '@/common/types/vessel-systems';

/* Job Prototype */
export const getVesselSystemMaintenanceJob = async (params: { id: number }) => {
  const response = await get<VesselSystemMaintenanceJob>(
    `/vessel-systems/maintenance-jobs/${params.id}`
  );

  return response.data;
};

type toggleVesselAssignmentJobParamsType = {
  assignmentId: number;
  jobId: number;
  isActivate: boolean;
};

export const toggleVesselAssignmentJob = async (params: toggleVesselAssignmentJobParamsType) => {
  const { assignmentId, jobId, isActivate } = params;

  return (
    await put(`/vessel-systems/assignments/${assignmentId}/job-prototypes/${jobId}/class`, {
      active: isActivate
    })
  ).data;
};
/* -- */

/* Subsystems */
type getSystemSubSystemsParamsType = {
  systemId: number;
};

export const getVesselSystemSubSystems = async (params: getSystemSubSystemsParamsType) => {
  const { systemId, ...rest } = params;

  return (await get(`/vessel-systems/${systemId}/subsystems`, rest)).data;
};
/* -- */

/* Systems */
type getVesselSystemsParamsType = {
  vessel_system_group_id?: number;
  vessel_ids?: number[];
  search?: string;
  purchasing_category_id?: number;
  paging?: TableRequestPaging;
  with_counts?: boolean;
};

export const getVesselSystems = async (params: getVesselSystemsParamsType) => {
  return (await get<PaginatedTableQuery<VesselSystem[]>>(`/vessel-systems`, params)).data;
};
/* -- */

/* Vessel System Assignments Params */
export type getVesselSystemAssignmentsParams = {
  system_id: number;
  vessel_id: number;
};

export const getVesselSystemAssignments = async (params: getVesselSystemAssignmentsParams) => {
  const { system_id, vessel_id, ...rest } = params;

  return (
    await get<VesselSystemAssignment[]>(
      `/vessel-systems/${system_id}/assignments/${vessel_id}`,
      rest
    )
  ).data;
};
/* -- */
