import { asyncTypes } from 'store/_helpers';

const TYPES = {
  /* RISK ASSESSMENT CATEGORY TYPES */
  ...asyncTypes('CREATE_RISK_ASSESSMENT_CATEGORY'),
  ...asyncTypes('EDIT_RISK_ASSESSMENT_CATEGORY'),
  ...asyncTypes('DELETE_RISK_ASSESSMENT_CATEGORY'),

  /* RISK ASSESSMENT OPERATION TYPES */
  ...asyncTypes('GET_RISK_ASSESSMENT_OPERATION'),
  ...asyncTypes('CREATE_RISK_ASSESSMENT_OPERATION'),
  ...asyncTypes('EDIT_RISK_ASSESSMENT_OPERATION'),
  ...asyncTypes('DELETE_RISK_ASSESSMENT_OPERATION')
};

export default TYPES;
