import React from 'react';
import SvgRender from 'common/components/general/SvgRender';

import attendanceIcon from 'events/assets/svg/event/attendance.svg';

const AttendanceButton = ({ onClick, disabled, className }) => {
  return (
    <div
      onClick={onClick}
      className={`event-participant__attendance-btn position-relative d-flex align-items-center justify-content-center cursor-pointer
       ${disabled ? 'bg-white border-primary offer-disabled' : 'bg-primary'} ${className || ''}
      `}
    >
      <SvgRender
        src={attendanceIcon}
        style={{ width: 23, height: 23 }}
        className={disabled ? 'text-primary' : 'text-white'}
      />
    </div>
  );
};

export default AttendanceButton;
