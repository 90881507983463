import { createAsyncThunk } from '@reduxjs/toolkit';

import { successHandler } from 'common/utils/notifications';

import { put, post, get, deleteRequest } from 'utils/api';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';

import TYPES from './types';

export const resetCrewProfile = () => dispatch => {
  return dispatch({ type: TYPES.RESET_CREW_PROFILE });
};

export const getCrewProfile = params => dispatch => {
  dispatch({ type: TYPES.GET_CREW.START });

  return get(`/crew/${params.id}`)
    .then(res => {
      dispatch({ type: TYPES.GET_CREW.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW.ERROR, payload: error });
      dispatch(resetCrewProfile());

      throw error;
    });
};

export const updateCrew = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CREW.START, payload: { params } });
  dispatch(updateTableRow({ data: params, table: 'crew' }));

  return put(`/crew/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CREW.SUCCESS, payload: response.data });
      dispatch(getCrewProfile({ id }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(updateTableRow({ data: response.data, table: 'crew' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_CREW.ERROR, payload: { response: error, params } });
      dispatch(resetTableRowUpdate({ data: params, table: 'crew' }));

      throw error;
    });
};

export const updateCrewStatus = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CREW_STATUS.START, payload: { params } });

  return put(`/crew/${id}/status`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CREW_STATUS.SUCCESS, payload: response.data });
      dispatch(getCrewProfile({ id }));
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_CREW_STATUS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const updateCrewStatusHistory = params => dispatch => {
  const { crewId, id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CREW_STATUS_HISTORY.START, payload: { params } });

  return put(`/crew/${crewId}/status-histories/${id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CREW_STATUS_HISTORY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_CREW_STATUS_HISTORY.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const revertStatusChange = createAsyncThunk(
  'REVERT_STATUS_CHANGE',
  async (params, { rejectWithValue, dispatch }) => {
    const { crewId, ...rest } = params;

    try {
      const res = await post(`/crew/${crewId}/status-histories/revert`, rest);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getCrewStatusHistory = params => dispatch => {
  const { crewId } = params;
  dispatch({ type: TYPES.GET_CREW_STATUS_HISTORY.START, payload: { params } });

  return get(`/crew/${crewId}/status-histories`)
    .then(response => {
      dispatch({ type: TYPES.GET_CREW_STATUS_HISTORY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_CREW_STATUS_HISTORY.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const createCrew = params => dispatch => {
  dispatch({ type: TYPES.CREATE_CREW.START, payload: { params } });

  return post(`/crew`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_CREW.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Crew created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_CREW.ERROR, payload: { response: error, params } });

      return error;
    });
};

export const archiveCrew = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_CREW.START, payload: { params } });

  return put(`/parties/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_CREW.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.ARCHIVE_CREW.ERROR, payload: error }));
};

export const unarchiveCrew = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_CREW.START, payload: { params } });

  return put(`/parties/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_CREW.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UNARCHIVE_CREW.ERROR, payload: error }));
};

export const setPromotionPlan = (id, params) => dispatch => {
  dispatch({ type: TYPES.SET_PROMOTION_PLAN.START, payload: { params } });

  return post(`/crew/${id}/promotion-plans`, params)
    .then(response => {
      dispatch({ type: TYPES.SET_PROMOTION_PLAN.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.SET_PROMOTION_PLAN.ERROR, payload: error });

      throw error;
    });
};

export const editPromotionPlan =
  ({ crew_id, promotion_plan_id, params }) =>
  dispatch => {
    dispatch({ type: TYPES.EDIT_PROMOTION_PLAN.START, payload: { params } });

    return put(`/crew/${crew_id}/promotion-plans/${promotion_plan_id}`, params)
      .then(response => {
        dispatch({ type: TYPES.EDIT_PROMOTION_PLAN.SUCCESS, payload: response.data });

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.EDIT_PROMOTION_PLAN.ERROR, payload: error });

        throw error;
      });
  };

export const deletePromotionPlan =
  ({ crew_id, promotion_plan_id }) =>
  dispatch => {
    dispatch({ type: TYPES.DELETE_PROMOTION_PLAN.START });

    return deleteRequest(`/crew/${crew_id}/promotion-plans/${promotion_plan_id}`)
      .then(response => {
        dispatch({ type: TYPES.DELETE_PROMOTION_PLAN.SUCCESS, payload: response.data });

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.DELETE_PROMOTION_PLAN.ERROR, payload: error });

        throw error;
      });
  };

export const getPromotionPlanActive = id => dispatch => {
  return get(`/crew/${id}/promotion-plans/active`)
    .then(response => {
      dispatch({
        type: TYPES.GET_PROMOTION_PLAN_ACTIVE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PROMOTION_PLAN_ACTIVE.ERROR, payload: error });

      throw error;
    });
};

export const changePassword = data => dispatch => {
  const { id, ...params } = data;

  return put(`/crew/${id}/change-password`, params)
    .then(response => {
      dispatch({
        type: TYPES.CHANGE_PASSWORD.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CHANGE_PASSWORD.ERROR, payload: error });

      throw error;
    });
};

export const setCrewProfile = data => dispatch => {
  return dispatch({ type: TYPES.SET_CREW_PROFILE, payload: data });
};
