export const getFormSectionColor = label => {
  switch (label) {
    case 'forms_for_correction':
    case 'forms_periodical_forms':
      return 'red';

    case 'forms_obligations':
      return 'warning';

    case 'forms_to_finalize':
      return 'primary';

    default:
      return '';
  }
};

export const getFormSectionRequestParams = (label, formStatuses) => {
  switch (label) {
    case 'forms_for_correction':
      if (!formStatuses?.length) return null;

      const forCorrectionStatus = formStatuses.find(
        status => status.progress === 'for-correction' && status.edit_side === 'vessel'
      );

      if (!forCorrectionStatus) return null;

      return {
        filters: [
          {
            name: 'status',
            operation: 'oneOf',
            value: [forCorrectionStatus.id]
          }
        ]
      };

    case 'forms_to_finalize':
      if (!formStatuses?.length) return null;

      const statusesForVessel = formStatuses.filter(
        status => status.edit_side === 'vessel' && status.progress !== 'for-correction'
      );

      return {
        filters: [
          {
            name: 'status',
            operation: 'oneOf',
            value: statusesForVessel.map(({ id }) => id)
          }
        ]
      };

    default:
      return null;
  }
};
