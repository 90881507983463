import React from 'react';

import { useActions } from 'utils/hooks';
import Editor from 'common/components/form/inputs/Editor';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';

const EditorField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const isLocked = useSelector(isReportLocked);

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onChange = html => {
    setValue({
      key: field.key,
      value: html,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  };

  return (
    <div className="">
      <Editor
        placeholder={isLocked ? '' : 'Add some remarks'}
        name="remarks"
        onChange={onChange}
        minHeight={'200px'}
        disabled={isLocked}
        id="evaluation-notes"
        value={value}
      />
    </div>
  );
};

export default EditorField;
