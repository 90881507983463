import { FC } from 'react';

import { FieldsStateType, FormState } from '@/common/types/form';
import NumberInput from '@/ts-common/components/form/inputs/NumberInput';
import { OffHireRobsFormStateType } from './config';
import { useFormState } from '@/utils/hooks';

type RobInputProps = { formState: FormState<OffHireRobsFormStateType>; className?: string };

const RobInput: FC<RobInputProps> = ({ formState, className = '' }) => {
  const { changeField, ...rest } = useFormState(formState);
  const fields: FieldsStateType<OffHireRobsFormStateType> = rest.fields;

  return (
    <NumberInput
      label={fields.fuel_grade.value?.name}
      className={`mb-0 ${className}`}
      placeholder="Add value"
      onChange={changeField('value')}
      {...fields.value}
    />
  );
};

export default RobInput;
