import React, { useState, useEffect } from 'react';

import BasicModal from 'common/components/modals/BasicModal';
import ListSelect, { notAllowedTypes } from 'captain-reports/components/ListSelect';
import Information from 'common/components/general/Information';
import useRouter from 'use-react-router';

import { OptionType, SingleValueType } from 'captain-reports/components/ReportTemplateName';
import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { reportURL } from 'captain-reports/components/ReportLink';
import { useSelector } from 'react-redux';

import * as reportActions from 'store/captain-reports/actions';
import paths from 'routing/routes/_paths';
import _get from 'lodash/get';
import Group from 'captain-reports/components/Group';

const Info = ({ lastReport }) => {
  const hiddenTypes = notAllowedTypes[_get(lastReport, 'template.type')];

  return hiddenTypes && hiddenTypes.length ? (
    <Information
      className={'text-primary'}
      svgStyle={{ width: 14, height: 14 }}
      target={'create-report-info'}
      message={
        <div>
          Last submitted report is{' '}
          <span className="text-capitalize">
            {_get(lastReport, 'template.type').replace(/_/g, ' ')}
          </span>
          . You cannot create{' '}
          {`${hiddenTypes
            .map(t => t.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()))
            .join(' or ')}`}{' '}
          report.
        </div>
      }
    />
  ) : null;
};

const CreateReport = ({ modalOpen, setModalOpen, topFilters }) => {
  const [createReport, getLastReportType] = useActions([
    reportActions.createReport,
    reportActions.getLastReportType
  ]);
  const { history } = useRouter();

  const reportGroupId = topFilters?.find(el => el.name === 'report_group_id')?.value;

  const groups = useSelector(state => state.lists['report-groups'].options);

  const currentGroup =
    groups.find(el => el?.id == reportGroupId) || groups?.find(el => el.is_default);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const [lastReport, setLastReport] = useState(null);
  const [lastReportLoading, setLastReportLoading] = useState(false);

  const handleSubmit = async () => {
    if (!selectedTemplate) setError(true);
    else setError(false);

    try {
      setIsSubmitting(true);

      const res = await createReport({ template_id: selectedTemplate });

      history.push(`${reportURL[res.template.type] || paths.captain_reports}/${res.id}`);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const getLastType = async () => {
    try {
      setLastReportLoading(true);
      const groupId = currentGroup?.id;
      const result = await getLastReportType({ report_group_id: groupId });

      setLastReport(result);
      setLastReportLoading(false);
    } catch (e) {
      setLastReportLoading(false);
    }
  };

  useEffect(() => {
    if (modalOpen && selectedTemplate) {
      setSelectedTemplate(null);
    }
    if (modalOpen) {
      getLastType();
    }
  }, [modalOpen]);

  const inOnPmsRunningHoursPage = history?.location?.pathname?.includes(
    `${paths?.pms_running_hours}`
  );

  return (
    <BasicModal
      isOpen={modalOpen}
      toggle={() => (!isSubmitting ? setModalOpen(false) : {})}
      header="Create Report"
      body={
        <div>
          <div className="cmb-4 d-flex align-items-center">
            <span className="form-label mb-0 me-2">Template</span>
            <Info lastReport={lastReport} />
          </div>
          <ListSelect
            listParams={{ report_group_id: currentGroup?.id }}
            list="captain-report-templates"
            placeholder="Select Template"
            className="mb-3"
            lastReport={lastReport}
            value={selectedTemplate}
            onChange={e => {
              setSelectedTemplate(e);
              setError(false);
            }}
            disabled={lastReportLoading}
            components={{ Option: OptionType, SingleValue: SingleValueType }}
            error={error ? 'Template is required' : null}
            filterOption={option =>
              inOnPmsRunningHoursPage ? option?.data?.type === 'system_running_hours' : option
            }
          />

          {groups.length > 1 ? (
            <>
              <div className="cmb-4 d-flex align-items-center">
                <span className="form-label mb-0 me-2">REPORTING GROUP</span>
              </div>
              <div className="d-flex">
                <Group
                  group={currentGroup}
                  className="d-flex align-items-center fs-10 fw-bold px-1 border-radius-3 cpt-6 cpb-6"
                />
              </div>
            </>
          ) : null}
        </div>
      }
      footer={
        <div>
          <Button color="cancel" onClick={() => setModalOpen(false)} disabled={isSubmitting}>
            CANCEL
          </Button>
          <Button color="primary" disabled={isSubmitting} type="submit" onClick={handleSubmit}>
            CREATE
          </Button>
        </div>
      }
    />
  );
};

export default CreateReport;
