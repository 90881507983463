import TYPES from './types';
import { get } from 'utils/api';
import { transformActivity } from 'common/components/jobs/_base/modules/activity/helpers';

export const getJobActivity = params => dispatch => {
  dispatch({ type: TYPES.GET_JOB_ACTIVITY.START, payload: { params } });

  const { id, ...rest } = params;

  return get(`/jobs/${id}/activity`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_JOB_ACTIVITY.SUCCESS,
        payload: {
          jobId: id,
          data: transformActivity(response.data.data),
          meta: response.data.meta
        }
      });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_JOB_ACTIVITY.ERROR, payload: error }));
};

export const getDueDateActivity = params => dispatch => {
  dispatch({ type: TYPES.GET_DUE_DATE_ACTIVITY.START, payload: { params } });

  const { id } = params;
  const filters = [{ name: 'properties', operation: 'all', value: ['due_date'] }];
  const paging = { current_page: 1, per_page: 20 };

  return get(`/jobs/${id}/activity`, { filters, paging })
    .then(response => {
      dispatch({
        type: TYPES.GET_DUE_DATE_ACTIVITY.SUCCESS
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_DUE_DATE_ACTIVITY.ERROR, payload: error });
      throw new Error();
    });
};

export const clearJobActivity = params => dispatch => {
  dispatch({ type: TYPES.CLEAR_JOB_ACTIVITY });
};
