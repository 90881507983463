import React, { useState, useEffect } from 'react';
import { imageUrlSelector } from 'common/utils/image-size';
import { useSelector } from 'react-redux';
import { getApiBaseUrl } from 'utils/api';
import { getFileMetadata } from '@/api/files/api';

const Image = ({ file }) => {
  const [imageURL, setImageURL] = useState('');
  const isOnBoard = useSelector(state => state.isOnBoard);
  const previewImageBaseUrl = isOnBoard ? getApiBaseUrl() : '';

  const getFileUrl = async () => {
    try {
      const res = await getFileMetadata(file.id);

      setImageURL(imageUrlSelector({ file: res, label: 'gallery_small' }));
    } catch (err) {
      console.error(err);
      setImageURL(file?.url);
    }
  };

  useEffect(() => {
    getFileUrl();
  }, [file?.id]);

  return (
    <div
      className="gallery__footer--item-image"
      style={{ backgroundImage: `url(${previewImageBaseUrl + imageURL})` }}
    />
  );
};

export default Image;
