export const parseSelectValues = (type, value) => {
  switch (type) {
    case 'department_ids':
      return value;
    case 'approver_ids':
      return value?.map(approver => approver.id);
    case 'subtype_ids':
      return value?.map(s => s.id);
    default:
      return value?.id || null;
  }
};
