import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { selectIsJobPeriodicityAsNeeded } from 'common/components/pms/jobs/store/selectors';

import SpareParts from './spare-parts';
import SystemRunningHours from './system-running-hours';
import Attachments from 'common/components/jobs/_base/inputs/Attachments';
import WorkDuration from 'common/components/jobs/_base/inputs/WorkDuration';

import add from 'common/assets/svg/actions/add.svg';

const Main = ({ isUnscheduled, side, jobId }) => {
  const isPeriodicityAsNeeded = useSelector(selectIsJobPeriodicityAsNeeded);

  return (
    <>
      <Row className="w-100p">
        <Col xs={12} className="w-100p">
          <Row className="w-100p">
            {side === 'right' ? (
              <>
                <SystemRunningHours className="mb-1" type="fill_in" />

                <Col xs={6} className="d-flex flex-column justify-content-between mb-1">
                  <Attachments
                    className="square-upload mt-0 pms-attachments mb-0"
                    uploadText=" "
                    uploadIcon={add}
                    showFileName={false}
                    showFileSize={false}
                    smallView
                  />
                  <WorkDuration className="mt-2" />
                </Col>
              </>
            ) : (
              <>
                {isPeriodicityAsNeeded ? null : <SystemRunningHours type="due" />}
                {!isUnscheduled ? <SystemRunningHours type="previous" /> : null}
              </>
            )}
          </Row>
        </Col>
      </Row>
      {side === 'left' ? <SpareParts type="required" className="mt-4" /> : null}
      {side === 'right' ? (
        <SpareParts type="consumed" className="mt-3" jobId={jobId} side={side} />
      ) : null}
    </>
  );
};

export default Main;
