import React, { useEffect } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import brokenPage from 'assets/svg/common/broken-page.svg';
import arrow from 'assets/svg/common/arrow-404.svg';

const ErrorBoundaryFallback = ({ history }) => {
  useEffect(() => {
    window.addEventListener('popstate', e => {
      if (e.state !== null) history.go();
    });
  }, []);

  return (
    <div className="error-page">
      <SvgRender src={brokenPage} style={{ width: '40vmax', height: '48vmin' }} />
      <div className="text-container fs-24 fs-hd-28">
        <div className="text-primary">Oops... Something went wrong.</div>
        <div className="text-primary text-center">We're working on it.</div>
        <div
          className="mt-3 mt-hd-5 d-flex align-items-center justify-content-center cursor-pointer"
          onClick={() => {
            history.push('/');
            history.go();
          }}
        >
          <div className="text-violet text-center">Let’s get you home safely</div>
          <SvgRender
            src={arrow}
            style={{ width: '4vmin', height: '4vmin' }}
            className="animated-arrow text-violet ms-5"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
