import { useState, useEffect } from 'react';

import SingleYearPicker from 'common/components/form/inputs/date/pickers/SingleYearPicker';
import moment from 'moment';
import PropTypes from 'prop-types';

const RangeYearPicker = ({ dateRange, onChange }) => {
  const [focusedInput, setFocusedInput] = useState(
    dateRange.starts?.value && !dateRange.ends?.value ? 'endDate' : 'startDate'
  );

  const onDatesChange = date => {
    const dates = {
      from: dateRange.starts.value,
      to: dateRange.ends.value ? dateRange.ends.value : null
    };

    if (focusedInput === 'startDate') {
      dates.from = date;
    } else {
      dates.to = date;
    }

    if (dates.from && dateRange.ends.value && moment(dates.from).isAfter(dateRange.ends.value)) {
      dates.to = null;
    }

    if (dates.from || dates.to) {
      if (focusedInput === 'startDate') {
        setFocusedInput('endDate');
      } else {
        setFocusedInput('startDate');
      }
      onChange(dates);
    } else {
      setFocusedInput('startDate');
      onChange({ from: null, to: null });
    }
  };

  useEffect(() => {
    dateRange.onFocus(focusedInput);
  }, [focusedInput]);

  const isOutsideRange = date =>
    focusedInput === 'endDate' && dateRange.starts.value
      ? moment(date).isBefore(dateRange.starts.value)
      : false;

  const isDateInRange = date => dateRange.starts.value <= date && dateRange.ends.value >= date;

  const isYearSelected = date => dateRange.ends.value === date;

  return (
    <div className="date-input-range-month d-flex">
      <SingleYearPicker
        value={dateRange.starts.value}
        isOutsideRange={isOutsideRange}
        onChange={onDatesChange}
        isDayHighlighted={isDateInRange}
        isYearSelected={isYearSelected}
        isDateInRange={isDateInRange}
      />
    </div>
  );
};

RangeYearPicker.propTypes = {
  dateRange: PropTypes.shape({
    starts: PropTypes.shape({
      value: PropTypes.number
    }),
    ends: PropTypes.shape({
      value: PropTypes.number
    })
  }),
  onChange: PropTypes.func
};

export default RangeYearPicker;
