import { FC } from 'react';

import SvgRender from '@/ts-common/components/general/SvgRender';
import check from '@/common/assets/svg/common/check-circle.svg';
import styled from '@emotion/styled';
import { VesselPmsIntegrationClassType } from '@/common/types/reports';
import { useVesselPmsAnnualReportIntegrationStatus } from '@/common/api/reports/queries';
import TextWithTooltip from '@/common/components/general/TextWithTooltip';
import { selectHasTableFilters } from '@/common/components/table/store/selector-ts.ts';
import { useAppSelector } from '@/store/hooks';
import { displayDate } from '@/ts-common/utils/dates';

type AnnualReportStatusProps = {
  integrationType: VesselPmsIntegrationClassType;
  vesselId: number;
};

const AnnualReportStatus: FC<AnnualReportStatusProps> = ({ integrationType, vesselId }) => {
  const hasSavedTableFilters = useAppSelector(selectHasTableFilters);
  const { data } = useVesselPmsAnnualReportIntegrationStatus(vesselId, integrationType);

  if (!data) return null;

  return (
    <StatusContainer
      className={`${
        hasSavedTableFilters ? 'max-w-249' : ''
      } py-1 cpe-12 cps-12 fs-12 cme-6 text-nowrap d-flex align-items-center justify-content-center text-turquoise`}
    >
      <SvgRender className="cme-4" src={check} style={{ width: 15, height: 15 }} />
      <TextWithTooltip className="h-auto">
        <strong>{integrationType.toUpperCase()} - Annual Report Submitted&nbsp;</strong>
        <span>
          | by {data.submitted_by.full_name}, at {displayDate(data.submitted_at, { time: true })}
        </span>
      </TextWithTooltip>
    </StatusContainer>
  );
};

export default AnnualReportStatus;

const StatusContainer = styled.div`
  background-color: rgba(1, 217, 164, 0.1);
`;
