import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectCrewProfile } from 'crew/store/profile/selectors';

import Fade from 'react-reveal/Fade';

import CreatableTable from 'common/components/creatable-table';
import Preview from 'crew/profile/main/personal/layout/LanguageRow';

const Languages = () => {
  const profile = useSelector(selectCrewProfile);
  const languages = profile?.languages;

  return (
    <Fade>
      <CreatableTable
        rows={languages}
        color="gray"
        hideIndex={true}
        previewComponent={Preview}
        hideActions={true}
        topComponent={
          <Row className="fw-bold pe-11">
            <Col xs={4}>
              <span className="ps-5">LANGUAGE</span>
            </Col>
            <Col xs={4}>LANGUAGE LEVEL</Col>
            <Col xs={4}>MOTHER TONGUE</Col>
          </Row>
        }
      />
    </Fade>
  );
};

export default Languages;
