import React from 'react';
import PropTypes from 'prop-types';
import { DepartmentRole, Department } from 'common/entities/parties/PartyTypes';
import { RankType } from 'common/entities/crew/RankTypes';
import Departments from 'common/components/general/Departments';
import CrewRanks from 'common/components/CrewRanks';

const Responsible = ({ type, responsible }) => {
  if (type === 'department_role') return responsible?.name;
  if (type === 'department') return <Departments values={[responsible]} />;

  return <CrewRanks values={[responsible]} />;
};

Responsible.propTypes = {
  type: PropTypes.oneOf(['department_role', 'department', 'crew_rank']),
  responsible: PropTypes.oneOfType([DepartmentRole, RankType, Department])
};

export default Responsible;
