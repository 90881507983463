import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';
import { selectMaintenanceDetaisDueOnRhChangedAt } from 'common/components/pms/jobs/store/selectors';
import { selectJobDueDateChangedAt } from 'common/components/jobs/_base/store/selectors';
import PropTypes from 'prop-types';
import clock from 'common/assets/svg/common/clock.svg';

const UpdatedValueIcon = ({ isDueTypeField, icon }) => {
  const isDueDateChanged = useSelector(selectJobDueDateChangedAt);
  const isRunningHoursDueChanged = useSelector(selectMaintenanceDetaisDueOnRhChangedAt);

  const systemRunningHoursInputsHaveChanged =
    isDueTypeField && (isDueDateChanged || isRunningHoursDueChanged);

  return (
    <SvgRender
      data-testid="system_running_hours_icon"
      src={systemRunningHoursInputsHaveChanged ? icon : clock}
      className={`text-white mt-auto ${
        systemRunningHoursInputsHaveChanged ? 'changed' : 'default'
      }`}
      style={{ width: 11, height: 11 }}
    />
  );
};

UpdatedValueIcon.propTypes = {
  isDueTypeField: PropTypes.bool,
  icon: PropTypes.string
};

export default UpdatedValueIcon;
