import { JobStatusDropdown } from 'common/components/jobs/_base/inputs/Status';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { useSelector } from 'react-redux';

const JobStatus = ({ status, className, onJobUpdate, avoidCheck = true }) => {
  const jobStatuses = useSelector(selectJobStatuses);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <JobStatusDropdown
      hideLabel={true}
      isForVessel={true}
      vesselJobsOnly={true}
      className={className}
      isJobLocked={
        jobStatuses.find(st => st.id === status?.id)?.edit_side !==
        (isOnBoard ? 'vessel' : 'office')
      }
      status={status}
      onChange={status_id => onJobUpdate({ status_id })}
      avoidCheck={avoidCheck}
    />
  );
};

export default JobStatus;
