import React from 'react';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { Row, Col } from 'reactstrap';
import { getValue } from '../../layout/utils';
import _get from 'lodash/get';

const HoldsHatchesList = ({ fields }) => {
  const profile = useSelector(selectVesselProfile);

  const holds = fields[0];
  const hatches = fields[1];

  const biggestLength =
    _get(profile, 'holds', []).length > _get(profile, 'hatches', []).length
      ? _get(profile, holds.key, [])
      : _get(profile, hatches.key, []);

  return (
    <>
      {biggestLength.map((item, index) => {
        let hold = _get(profile, 'holds', [])[index];
        let hatch = _get(profile, 'hatches', [])[index];
        return (
          <Row className="mt-2" key={index.toString()}>
            <Col xs={6}>
              {hold ? (
                <div key={hold.id} className="w-100p mt-2">
                  <Row>
                    <Col
                      className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
                      xs={8}
                    >
                      HOLD {+index + 1}
                    </Col>
                  </Row>
                  <div className="position-relative">
                    <div className="col-left-border col-left-border__holds" />
                    {holds.data.map(field => {
                      if (field.condition && !field.condition(profile)) return null;

                      if (field.component) {
                        return <field.component key={field.key} field={field} />;
                      }

                      if (field.key === 'hold_no' || field.key === 'id') {
                        return null;
                      }

                      return (
                        <Row
                          key={field.key}
                          className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
                        >
                          <Col
                            className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                            xs={8}
                          >
                            {field.label}
                          </Col>
                          <Col xs={4} className="fs-12  d-flex align-items-start position-relative">
                            {field.render ? (
                              field.render(hold, field)
                            ) : (
                              <span>{getValue(field, hold)}</span>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </Col>
            <Col xs={6}>
              {hatch ? (
                <div className="w-100p mt-2 position-relative">
                  <Row>
                    <Col
                      className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
                      xs={8}
                    >
                      HATCH {+hatch.hatch_no + 1}
                    </Col>
                  </Row>
                  <div className="position-relative">
                    <div className="col-left-border col-left-border__holds" />

                    {hatches.data.map(field => {
                      if (field.condition && !field.condition(profile)) return null;

                      if (field.component) {
                        return <field.component key={field.key} field={field} />;
                      }

                      if (field.key === 'hatch_no' || field.key === 'id') {
                        return null;
                      }

                      return (
                        <Row
                          key={field.key}
                          className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
                        >
                          <Col
                            className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                            xs={8}
                          >
                            {field.label}
                          </Col>
                          <Col xs={4} className="fs-12  d-flex align-items-start position-relative">
                            {field.render ? (
                              field.render(hatch, field)
                            ) : (
                              <span>{getValue(field, hatch)}</span>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default HoldsHatchesList;
