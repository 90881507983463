import React from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import warningIcon from 'common/assets/svg/common/warning-icon.svg';

const Preview = ({ assignments, analysis }) => {
  return (
    <div className="d-flex text-red fw-medium fs-12 mt-1">
      <SvgRender src={warningIcon} style={{ width: 12, height: 12 }} className="me-1 cmt-2" />
      <div>
        Analysis for system{`${assignments.length > 1 ? 's' : ''}`}{' '}
        {assignments
          .map(({ vessel_system_assignment }) => `“${vessel_system_assignment.description}“`)
          .join(' & ')}{' '}
        resulted in "<span className="text-capitalize">{analysis}</span>", you should create a new
        Lub Oil Analysis Event.
      </div>
    </div>
  );
};

Preview.propTypes = {
  analysis: PropTypes.oneOf(['attention', 'action']),
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      vessel_system_assignment: PropTypes.shape({
        description: PropTypes.string
      })
    })
  )
};

export default Preview;
