import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const rightSideInfo = [
  {
    header: (
      <div className="fw-normal fs-12 cpb-12 border-bottom text-violet cpb-12 cmb-12">
        Nearest Airport
      </div>
    ),
    fields: [
      {
        label: 'COUNTRY',
        key: 'nearest_airport_country.name',
        inputWidth: 9,
        type: 'select',
        select_key: 'nearest_airport_country',
        onChangeCallback: ({ selectField }) => selectField('nearest_airport')(null),
        inputProps: {
          placeholder: 'Select country',
          dataCy: 'select-nearest_airport_country',
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          defaultOptions: () => getInitialAsyncValues('countries'),
          loadOptions: search => getAsyncOptions(search, 'countries'),
          isClearable: true
        }
      },
      {
        label: 'AIRPORT',
        key: 'nearest_airport.name',
        type: 'select',
        inputWidth: 9,
        select_key: 'nearest_airport',
        inputProps: {
          placeholder: 'Select airport',
          dataCy: 'select-nearest_airport',
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          defaultOptions: fields =>
            getInitialAsyncValues('airports', null, null, {
              country_code: fields?.nearest_airport_country.value?.code
            }),
          loadOptions: (search, fields) =>
            getAsyncOptions(search, 'airports', {
              country_code: fields?.nearest_airport_country?.value?.code
            }),
          defaultOptionsTriggerChange: 'nearest_airport_country',
          isClearable: true
        }
      },
      {
        label: 'AVAILABILITY',
        key: 'available_at',
        type: 'date',
        inputProps: {
          placeholder: 'Set date',
          dataCy: 'availability_date'
        }
      }
    ]
  },
  {
    header: (
      <div className="fw-normal fs-12 cpb-12 mt-4 border-bottom text-violet cpb-12 cmb-12">
        Vessel Groups
      </div>
    ),
    fields: [
      {
        label: 'GROUP(S)',
        key: 'vessel_groups',
        inputWidth: 9,
        type: 'select',
        select_key: 'vessel_groups',
        inputProps: {
          className: 'mb-0',
          placeholder: 'Select group(s)',
          dataCy: 'vessel-groups',
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          defaultOptions: () => getInitialAsyncValues('vessel-groups'),
          loadOptions: search => getAsyncOptions(search, 'vessel-groups'),
          isClearable: true,
          isMulti: true
        }
      }
    ]
  }
];

export default rightSideInfo;
