import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModuleWrapper from '../components/ModuleWrapper';
import InspectionForm from './InspectionForm';
import Observations from './observations';
import Loader from './Loader';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import {
  getEventVetting,
  getEventVettingObservations
} from 'events/store/event-modules/vetting/actions';

const VettingModule = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);

  useEffect(() => {
    const initModule = async () => {
      setIsLoading(true);

      await dispatch(getEventVetting({ id: eventId })).unwrap();
      await dispatch(getEventVettingObservations({ id: eventId })).unwrap();

      setIsLoading(false);
    };

    if (!isTemplate && eventId) initModule();
  }, [dispatch, eventId, isTemplate]);

  return (
    <ModuleWrapper className="event-vetting" type="vetting">
      {!isTemplate && eventId ? (
        isLoading ? (
          <Loader />
        ) : (
          <>
            <InspectionForm />
            <Observations />
          </>
        )
      ) : null}
    </ModuleWrapper>
  );
};

export default VettingModule;
