import { createSelector } from 'reselect';

const getKey = (_, key) => key;

const selectVessel = state => state.vessels;

export const selectVesselInfo = createSelector([selectVessel], vessel => vessel.info);

export const selectVesselName = createSelector([selectVesselInfo], vessel => vessel.name);

export const selectVesselNameAndId = createSelector([selectVesselInfo], vessel => ({
  name: vessel.name,
  id: vessel.id
}));

export const selectVesselProfile = selectVesselInfo;

const selectVesselDocuments = createSelector(selectVessel, reducer => reducer.documents);
const selectVesselDocumentsCategories = createSelector(
  selectVessel,
  reducer => reducer.documents.categories
);

export const selectDocumentDrawerIsOpen = createSelector(
  selectVesselDocuments,
  documents => documents.drawerOpen
);

export const selectDocumentDrawerType = createSelector(
  selectVesselDocuments,
  documents => documents.drawerType
);

export const selectDocumentDrawerActiveItem = createSelector(
  selectVesselDocuments,
  documents => documents.drawerActiveItem
);

export const selectDocumentsLoading = createSelector(
  selectVesselDocuments,
  documents => documents.isDataLoading
);

export const selectDocumentsTypes = createSelector(
  selectVesselDocuments,
  documents => documents.types
);

export const selectDocumentTypeCategories = createSelector(
  selectVesselDocumentsCategories,
  getKey,
  (documentsCategories, key) => documentsCategories[key]
);

export const exludedDocumentCategories = createSelector(
  selectVesselDocuments,
  documents => documents.exludedCategories
);

export const areAllDocumentTypePanelsExpanded = createSelector(
  selectVesselDocuments,
  documents => documents.expandedAllPanels
);

export const selectDocumentSearch = createSelector(
  selectVesselDocuments,
  documents => documents.search
);

export const selectCiiReferenceYearly = createSelector(
  selectVessel,
  vessel => vessel?.ciiReferenceYearly
);

export const selectVesselPool = () => () => null;
export const selectPoolFetching = () => () => null;
export const selectActivePool = () => () => null;
export const selectPoolDrawerIsOpen = () => () => null;
export const selectPoolDrawerType = () => () => null;
export const selectPool = () => () => null;
export const selectHasPoolData = () => () => null;
