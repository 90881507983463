import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = ({ children, label }) => {
  return (
    <Wrapper>
      {label && <div className="form-label">{label}</div>}
      <div className="d-flex align-items-start flex-wrap">{children}</div>
    </Wrapper>
  );
};

export default Container;

Container.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

const Wrapper = styled.div`
  position: relative;
`;
