import React, { useEffect } from 'react';

import NumberField from 'captain-reports/templates/inputs/NumberField';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import _debounce from 'lodash/debounce';

import { trimFloatNumber } from 'common/utils/numbers';
import { selectEngineSubGroupTotal } from 'captain-reports/store/selectors';
import useSubGroupLength from 'captain-reports/utils/useSubGroupLength';

const MainEngineExGasDeviationField = ({
  tooltip,
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const number_of_ex_gas_max_temps = useSubGroupLength(sectionLabel, 'ex_gas_max_temps');
  const total_ex_gas_max_temps = useSelector(state =>
    selectEngineSubGroupTotal(
      state,
      sectionActiveTab,
      'ex_gas_max_temps',
      'me_exhaust_gas_temperature_max_per_cylinder'
    )
  );
  const me_exhaust_gas_temperature_max_per_cylinder = useValue({
    key: 'me_exhaust_gas_temperature_max_per_cylinder',
    sectionLabel,
    sectionActiveTab,
    subGroup: 'ex_gas_max_temps',
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const debouncedAutoComplete = _debounce(newVal => {
    if (newVal !== value) {
      setValue({
        key: field.key,
        value: newVal,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, 600);

  useEffect(() => {
    const ex_gas_max_temps_average =
      total_ex_gas_max_temps && number_of_ex_gas_max_temps
        ? total_ex_gas_max_temps / number_of_ex_gas_max_temps
        : 0;

    const me_eg_deviation =
      me_exhaust_gas_temperature_max_per_cylinder && ex_gas_max_temps_average
        ? trimFloatNumber(me_exhaust_gas_temperature_max_per_cylinder - ex_gas_max_temps_average)
        : null;

    debouncedAutoComplete(me_eg_deviation);
  }, [total_ex_gas_max_temps, me_exhaust_gas_temperature_max_per_cylinder]);

  return (
    <NumberField
      field={field}
      tooltip={tooltip}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default MainEngineExGasDeviationField;
