import { Row, Col } from 'reactstrap';
import { useAppSelector } from '@/store/hooks';
import { FC } from 'react';
import Input from 'common/components/form/inputs/Input';
import FindingCategoriesSelector from '@/common/components/selectors/FindingCategoriesSelector';
import FindingSubCategoriesSelector from '@/common/components/selectors/FindingSubCategoriesSelector';
import { FormFieldFinding } from '@/common/types/form-submissions';

type FormFieldInputType = {
  error?: string;
  onChange: (value: any) => void;
  value?: FormFieldFinding;
};

const FindingInput: FC<FormFieldInputType> = ({ error, value, onChange }) => {
  const isOnboard = useAppSelector(state => state.isOnBoard);
  const isDisabled = isOnboard && value?.id ? true : false;

  return (
    <div>
      <Row className="cmb-6" noGutters>
        <Col xs={6}>
          <Input
            onChange={e => onChange({ ...value, name: e.target.value })}
            value={value?.name}
            className="mb-0"
            placeholder="Add description"
            error={error}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col className="w-100p" xs={3}>
          <FindingCategoriesSelector
            onChange={e => onChange({ ...value, category: e })}
            value={value?.category || null}
            isMulti={false}
            disabled={isDisabled}
            className="mb-1"
            placeholder="Select category"
          />

          <FindingSubCategoriesSelector
            onChange={e => onChange({ ...value, subcategory: e })}
            value={value?.subcategory || null}
            isMulti={false}
            disabled={isDisabled}
            placeholder="Select subcategory"
          />
        </Col>
      </Row>
    </div>
  );
};

export default FindingInput;
