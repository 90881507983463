import Checkbox from 'common/components/form/inputs/Checkbox';
import { getFieldName, getFormFieldParentID } from 'common/components/forms/digital/state-utils';

const BooleanInput = ({ formField, value, error, onChange }) => {
  const fieldName = getFieldName(formField);
  const formFieldParentID = getFormFieldParentID(formField);

  return (
    <Checkbox
      color={error ? 'red' : 'green'}
      outline
      value={value || false}
      error={error}
      onChange={e => onChange(e.target.checked)}
      className="digital-form-checkbox m-0"
      data-cy="form-field-input--boolean"
      label={
        formFieldParentID ? (
          <span>&nbsp;</span>
        ) : (
          <span className="fs-12 text-primary">{fieldName}</span>
        )
      }
    />
  );
};

export default BooleanInput;
