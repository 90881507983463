import React from 'react';

import BooleanInput from 'common/components/forms/digital/components/boolean/BooleanInput';
import Input from 'common/components/form/inputs/Input';

import _get from 'lodash/get';

const CheckboxWithDetailsInput = ({ formField, className = '', value, onChange, error }) => {
  const isChecked = _get(value, 'option', false);
  const additionalInfo = _get(value, 'additional_info', '');

  return (
    <div className={`checkbox-with-details ${className}`}>
      <BooleanInput
        formField={formField}
        value={isChecked}
        error={error}
        onChange={checked => onChange({ option: checked, additional_info: additionalInfo })}
      />

      <Input
        rows={3}
        type="textarea"
        onChange={e => onChange({ option: isChecked, additional_info: e.target.value })}
        value={additionalInfo}
        className="mb-0 mt-1"
        placeholder="Add some text"
        error={error}
      />
    </div>
  );
};

export default CheckboxWithDetailsInput;
