import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import Select from 'common/components/form/inputs/Select';

import * as listActions from 'store/lists/actions';

const VesselGroupsSelector = ({ filter, value, onChange, isMulti, placeholder, ...rest }) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const vesselGroups = useSelector(state => state.lists['vessel-groups'].options).map(group => ({
    ...group,
    label: group.name
  }));

  useEffect(() => {
    if (!vesselGroups.length) fetchListOptions('vessel-groups');
  }, []);

  return (
    <Select
      placeholder={placeholder ? placeholder : 'Select value'}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter ? filter.value : value}
      options={vesselGroups}
      isMulti={isMulti}
      onChange={selected =>
        onChange({
          data: selected ? selected : [],
          value: selected ? (isMulti ? selected : selected) : ''
        })
      }
      isClearable
      {...rest}
    />
  );
};

export default VesselGroupsSelector;
