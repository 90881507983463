import React, { useState } from 'react';

import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { selectIsDraft, selectCrewMembers } from 'store/working-schedule/selectors';
import { useSelector } from 'react-redux';
import useTooltipID from 'common/utils/hooks/useTooltipID';

import WorkingSchedulePublishModal from './PublishModal';
import ILORegulations from './ILORegulations';
import Tooltip from 'common/components/general/Tooltip';
import SvgRender from 'common/components/general/SvgRender';

import * as workingScheduleActions from 'store/working-schedule/actions';

import send from 'common/assets/svg/actions/send.svg';
import moment from 'moment';

const WorkingScheduleHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const isDraft = useSelector(selectIsDraft);
  const crewMembers = useSelector(selectCrewMembers);
  const areAllCrewMembersOnboard = crewMembers.every(crew => crew.onboard === true);

  const { tooltipID, avoidRender } = useTooltipID('publish_schedule_btn');

  const [publishCrewSchedule] = useActions([workingScheduleActions.publishCrewSchedule]);

  const onPublish = async applicableSince => {
    setIsPublishing(true);
    try {
      await publishCrewSchedule({ applicable_since: moment(applicableSince).format('YYYY-MM-DD') });
      setIsModalOpen(false);
      setIsPublishing(false);
    } catch {
      setIsPublishing(false);
    }
  };

  if (avoidRender) return null;

  return (
    <div className="working-schedule__header ms-auto cmt-4">
      <div className="d-flex align-items-center">
        <ILORegulations />
        {isDraft ? (
          <div className="border-start ms-3" id={tooltipID}>
            <Button
              color="primary"
              onClick={() => setIsModalOpen(true)}
              disabled={!areAllCrewMembersOnboard}
              className="fw-normal fs-14 line-height-16 px-2 cpt-12 cpb-12 d-flex align-items-center"
            >
              <SvgRender src={send} style={{ width: 14, height: 14 }} className="me-1" />
              Publish this Schedule
            </Button>
            {!areAllCrewMembersOnboard ? (
              <Tooltip
                innerClassName="working-schedule__publish-tooltip me-7 p-1"
                placement={'bottom'}
                target={tooltipID}
                hideArrow={false}
              >
                Crew list does not match with seamen in working schedule. Please remove all not
                onboard. (Red means seaman has signed off)
              </Tooltip>
            ) : null}
          </div>
        ) : null}
      </div>
      <WorkingSchedulePublishModal
        isOpen={isModalOpen}
        isPublishing={isPublishing}
        toggle={() => setIsModalOpen(!isModalOpen)}
        onPublish={onPublish}
      />
    </div>
  );
};

export default WorkingScheduleHeader;
