import React from 'react';
import SingleActivity from './SingleActivity';
import Spinner from 'common/components/general/Spinner';

const List = ({
  data,
  fetchingMore,
  hasError,
  hasMore,
  getActivity,
  isProfile,
  currentActivityPage
}) => {
  const loadMore = () => {
    const element = document.querySelector('.job-activity-list');
    const { clientHeight, scrollTop, scrollHeight } = element;

    if (fetchingMore || hasError || !hasMore) return;

    if (scrollHeight === scrollTop + clientHeight) {
      getActivity(currentActivityPage + 1);
    }
  };

  return (
    <div onScroll={loadMore} className="job-activity-list gray-scrollbar ">
      {data.map((el, index) => (
        <SingleActivity isProfile={isProfile} key={index} activity={el} />
      ))}
      {fetchingMore ? (
        <div className="d-flex justify-content-center align-items-center mt-1">
          <Spinner />
        </div>
      ) : null}
    </div>
  );
};

export default List;
