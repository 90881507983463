import FilterButton from '@/common/components/buttons/FilterButton';
import { FC } from 'react';

type FilterButtonProps = {
  isHidden?: boolean;
  disabled?: boolean;
  updateTopFilters: (key: string) => void;
};

export const BecomingDueFilter: FC<FilterButtonProps & { selectedBecomingDue?: boolean }> = ({
  isHidden,
  disabled,
  selectedBecomingDue,
  updateTopFilters
}) =>
  isHidden ? null : (
    <FilterButton
      isActive={selectedBecomingDue}
      onClick={() => updateTopFilters('becoming_due')}
      status="proceeding"
      disabled={disabled}
      size="sm"
    >
      Becoming Due
    </FilterButton>
  );

export const OverdueFilter: FC<FilterButtonProps & { selectedOverdue?: boolean }> = ({
  isHidden,
  disabled,
  selectedOverdue,
  updateTopFilters
}) =>
  isHidden ? null : (
    <FilterButton
      isActive={selectedOverdue}
      onClick={() => updateTopFilters('is_overdue')}
      status="error"
      disabled={disabled}
      size="sm"
    >
      Overdue
    </FilterButton>
  );

export const PostponedFilter: FC<FilterButtonProps & { selectedPostponed?: boolean }> = ({
  isHidden,
  disabled,
  selectedPostponed,
  updateTopFilters
}) =>
  isHidden ? null : (
    <FilterButton
      isActive={selectedPostponed}
      onClick={() => updateTopFilters('is_postponed')}
      status="proceeding"
      disabled={disabled}
      size="sm"
    >
      Postponed
    </FilterButton>
  );
