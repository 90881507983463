import TYPES from './types';
import { getApiBaseUrl, get, post, download } from 'utils/api';
import {
  setManualInnerData,
  setManualInnerChapters,
  searchInChaptersTree,
  setComparedAtVersionChapterPost,
  updateCurrentVersion
} from 'manuals/store/actions';
import { getFileIdsFromHtml, putFileUrlsInHtml } from 'common/utils/post-images';
import { handleBlobError } from 'common/utils/downloads';
import { getFilesMetadata } from '@/api/files/api';

import _get from 'lodash/get';

export const getManual = params => dispatch => {
  dispatch({ type: TYPES.GET_MANUAL.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/manuals/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_MANUAL.SUCCESS, payload: { data: response.data, params } });
      dispatch(setManualInnerData(response.data, true, true));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MANUAL.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const getManualChapters = params => dispatch => {
  dispatch({ type: TYPES.GET_MANUAL_CHAPTERS.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/manuals/${id}/chapters`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_MANUAL_CHAPTERS.SUCCESS, payload: response.data });
      dispatch(setManualInnerChapters(response.data, params));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MANUAL_CHAPTERS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const getChapterPost = params => dispatch => {
  dispatch({ type: TYPES.GET_CHAPTER_POST.START, payload: { params } });
  const { manual_id, uid, ...rest } = params;

  return get(`/manuals/${manual_id}/chapters/${uid}/post`, rest)
    .then(response => {
      const { post, ...data } = response.data;

      if (post && post.content) {
        const filePreviewURL = getApiBaseUrl();

        const attachments = (post.attachments || []).map(attachment => ({
          ...attachment,
          id: attachment.orca_id,
          url: `${filePreviewURL}${attachment.url}`
        }));

        const content = putFileUrlsInHtml(post.content, attachments, {
          imgTagAttributes: 'style="cursor: pointer"'
        });
        data.post = { ...post, content };
      } else {
        data.post = post;
      }

      dispatch({ type: TYPES.GET_CHAPTER_POST.SUCCESS, payload: data });

      return data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CHAPTER_POST.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const compareVersionsChapterPosts = params => (dispatch, getState) => {
  dispatch({ type: TYPES.COMPARE_VERSION_CHAPTER_POSTS.START, payload: {} });

  const { manual_id, uid, ...rest } = params;
  const firstVersionContent = _get(
    getState(),
    'manualInner.activeChapterContent?.post.raw_content',
    ''
  );
  const firstVersionData = getState().manualInner.activeChapterContent;

  return get(`/manuals/${manual_id}/chapters/${uid}/post`, rest)
    .then(async response => {
      const { post, ...data } = response.data;

      if (post && post.content) {
        const fileIds = getFileIdsFromHtml(post.content, true).filter(
          f => !getFileIdsFromHtml(firstVersionContent, true)?.includes(f)
        );

        const firstVersionPost = firstVersionData?.post || {};

        const files = [
          ...(await getFilesMetadata(fileIds)),
          ...(firstVersionPost?.attachments || [])
        ];

        const content = putFileUrlsInHtml(post.content, files);

        data.post = { ...post, content };

        if (firstVersionContent) {
          const content = putFileUrlsInHtml(firstVersionContent, firstVersionPost?.attachments);

          firstVersionData.post = { ...firstVersionPost, content };
        } else {
          firstVersionData.post = firstVersionPost;
        }
      } else {
        data.post = post;
      }

      dispatch({
        type: TYPES.COMPARE_VERSION_CHAPTER_POSTS.SUCCESS,
        payload: { comparedVersionData: data, firstVersionData: firstVersionData }
      });
      dispatch(
        setComparedAtVersionChapterPost({
          comparedVersionData: data,
          firstVersionData: firstVersionData
        })
      );

      return data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.COMPARE_VERSION_CHAPTER_POSTS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const compareVersionsChapters = params => dispatch => {
  dispatch({ type: TYPES.COMPARE_MANUAL_VERSIONS.START });
  const { versionId, secondVersionId } = params;

  return get(`/manuals/versions/${versionId}/compare/${secondVersionId}`)
    .then(response => {
      dispatch({
        type: TYPES.COMPARE_MANUAL_VERSIONS.SUCCESS,
        payload: response.data
      });
      dispatch(
        setManualInnerChapters(
          response.data.map(c => {
            return {
              ...c,
              compare_has_changed: c.has_changed,
              compare_is_new: c.is_new,
              compare_is_deleted: c.is_deleted
            };
          })
        )
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.COMPARE_MANUAL_VERSIONS.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const searchInManualChapters = search => (dispatch, getState) => {
  dispatch({ type: TYPES.SEARCH_IN_MANUAL_CHAPTERS.START });
  const id = getState().manualInner.data?.id;

  if (!search) {
    return dispatch(searchInChaptersTree({ data: [], search }));
  }

  return get(`/manuals/${id}/chapters`, { search, basic: 1 })
    .then(response => {
      dispatch({
        type: TYPES.SEARCH_IN_MANUAL_CHAPTERS.SUCCESS,
        payload: { data: response.data, search }
      });
      dispatch(searchInChaptersTree({ data: response.data, search }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.SEARCH_IN_MANUAL_CHAPTERS.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};

export const acknowledgeManualVersion = params => dispatch => {
  dispatch({ type: TYPES.ACKNOWLEDGE_MANUAL_VERSION.START });
  const { versionId } = params;

  return post(`/manuals/versions/${versionId}/acknowledgements`)
    .then(response => {
      dispatch({
        type: TYPES.ACKNOWLEDGE_MANUAL_VERSION.SUCCESS,
        payload: response.data
      });

      dispatch(
        updateCurrentVersion({
          current_user_acknowledged: true,
          current_version_acknowledge_trigger: true
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.ACKNOWLEDGE_MANUAL_VERSION.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const downloadManualUploadedFiles = params => dispatch => {
  dispatch({ type: TYPES.DOWNLOAD_MANUAL_UPLOADED_FILES.START, payload: { params } });
  const { id, version_id } = params;

  return download(`/manuals/${id}/files`, { version_id })
    .then(response => {
      dispatch({ type: TYPES.DOWNLOAD_MANUAL_UPLOADED_FILES.SUCCESS, payload: response.data });

      return response;
    })
    .catch(async error => {
      dispatch({
        type: TYPES.DOWNLOAD_MANUAL_UPLOADED_FILES.ERROR,
        payload: await handleBlobError(error)
      });

      throw error;
    });
};

export const downloadManualAttachments = params => dispatch => {
  const { manual_id, chapter_uid, ...rest } = params;

  dispatch({ type: TYPES.DOWNLOAD_MANUAL_ATTACHMENTS.START, payload: { params } });

  return download(`/manuals/${manual_id}/chapters/${chapter_uid}/attachments`, rest)
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_MANUAL_ATTACHMENTS.SUCCESS,
        payload: { data: response.data, params }
      });
      return response;
    })
    .catch(error => dispatch({ type: TYPES.DOWNLOAD_MANUAL_ATTACHMENTS.ERROR, payload: error }));
};
