import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useCallback } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';

import add from 'common/assets/svg/actions/add.svg';

import * as mgaActions from 'common/components/mga/store/actions';

const PeriodButtonCreateDraft = ({
  color = 'yellow',
  className = 'text-dark px-2 py-1 fw-medium',
  icon = add
}) => {
  const [setMgaPeriodForm] = useActions([mgaActions.setMgaPeriodForm]);

  const openModal = useCallback(() => setMgaPeriodForm({ isModalOpen: true, active: null }), []);

  return (
    <Button
      type="button"
      color={color}
      className={`d-inline-flex align-items-center border-0 ${className}`}
      onClick={openModal}
    >
      <SvgRender src={icon} style={{ width: 14, height: 14 }} />
      <span className="lh-1 ps-1">Create new Draft MGA Period</span>
    </Button>
  );
};

PeriodButtonCreateDraft.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string
};

export default PeriodButtonCreateDraft;
