import useRouter from 'use-react-router';

import SvgRender from 'common/components/general/SvgRender';

import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

const GoBackArrow = ({ to, className = '', avoidHistoryCheck = false, dataCy = undefined }) => {
  const { history } = useRouter();

  return (
    <div
      className={`btn btn-go-back p-0 d-inline-flex align-items-center ${className}`}
      data-cy={dataCy}
      onClick={() =>
        history.action === 'PUSH' && !avoidHistoryCheck ? history.goBack() : history.push(to)
      }
    >
      <SvgRender src={arrow} style={{ width: 16, height: 16, transform: 'rotate(-90deg)' }} />
    </div>
  );
};

export default GoBackArrow;
