import React from 'react';
import { Fragment, useRef, useCallback, useMemo } from 'react';
import _findLastIndex from 'lodash/findLastIndex';
import _isFunction from 'lodash/isFunction';
import useRouter from 'use-react-router';
import useActions from 'common/utils/hooks/useActions';

import { css } from '@emotion/react';
import { parseQueryParams, stringifyObj } from 'common/utils/urls';
import { getDefaultSorting } from 'store/tables/lists/defaults';
import { isOverflownX } from 'common/utils/overflow';
import { selectTableHoveredColumnKey } from 'common/components/table/store/selectors';
import { useSelector } from 'react-redux';

import useHorizontalScroll from 'common/utils/hooks/useHorizontalScroll';

import HeaderCell from './HeaderCell';
import BottomPagination from 'common/components/table/main/BottomPagination';
import TotalsBarWrapper from './TotalsBarWrapper';
import Row from './Row';
import SelectableRowsHeader from './SelectableRowsHeader';

import * as tableActions from 'common/components/table/store/actions';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const MainWrapper = ({
  label,
  hideTableFilters,
  hideTopPagination,
  canSelectRows,
  highlightColumnOnHover,
  children
}) => {
  const tableRef = useRef(null);

  const { onMouseDown, onMouseUp, onMouseMove } = useHorizontalScroll(tableRef);

  const hoveredColumnKey = useSelector(state => selectTableHoveredColumnKey(state, label));
  const [setTableHoveredColumn] = useActions([tableActions.setTableHoveredColumn]);

  const checkOverflow = useCallback(() => {
    const element = tableRef && tableRef.current;

    return isOverflownX(element);
  }, [tableRef.current?.length]);

  const mainProps = useMemo(() => {
    const props = {};

    if (highlightColumnOnHover) {
      props.onMouseLeave = () => {
        if (hoveredColumnKey) setTableHoveredColumn({ table: label });
      };
    }

    return props;
  }, [highlightColumnOnHover, hoveredColumnKey]);

  return (
    <div
      className={`app-table--main${checkOverflow() ? ' cursor-grab' : ''}${
        hideTableFilters && !hideTopPagination ? ' mt-1' : ''
      }${canSelectRows ? ' app-table--main--selectable-rows' : ''}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      ref={tableRef}
      css={
        highlightColumnOnHover && hoveredColumnKey
          ? css`
              .cell[data-key='${hoveredColumnKey}'] {
                background-color: ${variables.hoverColor} !important;
              }
            `
          : null
      }
      {...mainProps}
    >
      {children}
    </div>
  );
};

const TableMain = ({
  columns,
  rows,
  label,
  state,
  fetchData,
  getRowClassName,
  getRowId,
  setDangerousModal,
  handleTableSort,
  paginationLimits,
  mainHeaderComponent,
  hideTableFilters,
  hideTopPagination,
  hideBottomPagination,
  hideTableHead,
  onCellDoubleClick,
  isRowSelectable,
  selectedRowsLabel,
  solidTotalBarKeys,
  highlightColumnOnHover,
  emptyStateComponent,
  showEmptyState,
  hideSelectableRowsHeader,
  isModularTableShownInWidget,
  scrollToTopAfterPageChanged
}) => {
  const { history, location } = useRouter();

  const getColStyle = useCallback(
    col => ({
      left: col.sticky && col.left !== undefined ? col.left : '',
      right: col.sticky && col.right !== undefined ? col.right : '',
      minWidth: col.minWidth ? col.minWidth : null,
      maxWidth: col.maxWidth ? col.maxWidth : null
    }),
    []
  );

  const lastSticky = _findLastIndex(columns, o => o.sticky && !o.hidden);
  const lastCell = _findLastIndex(columns, o => o.key !== 'actions' && !o.hidden);
  const groupedLastSticky = _findLastIndex(columns, e => e.sticky && e.columns && !e.hidden);

  const canSelectRows = _isFunction(isRowSelectable);

  const handleSort = useCallback(
    (col, ctrlPressed) => {
      let newSorting = {};
      let initialSorting = getDefaultSorting(label);
      const prevSorting = state?.sorting;

      const sortingKey = col.sortingKey || col.key;

      let sort = null;

      if (Object.keys(initialSorting).length > 0 && initialSorting[sortingKey]) {
        newSorting = { ...initialSorting };
        sort = prevSorting[sortingKey] === 'asc' ? 'desc' : 'asc';
      } else {
        sort =
          prevSorting[sortingKey] === 'desc' ? null : !prevSorting[sortingKey] ? 'asc' : 'desc';
      }

      if (!ctrlPressed) {
        if (sort) {
          newSorting[sortingKey] = sort;
        }
      } else {
        newSorting = prevSorting;
        if (!sort) {
          delete newSorting[sortingKey];
        } else {
          newSorting[sortingKey] = sort;
        }
      }

      const { searchId, sorting, ...rest } = parseQueryParams(history.location.search);

      const updated = {
        ...rest,
        sorting: newSorting
      };

      if (handleTableSort) {
        handleTableSort(updated);
      } else {
        history.push({
          pathname: location.pathname,
          search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
        });
      }
    },
    [label, state?.sorting, history, handleTableSort, location.pathname]
  );

  const hasGroupHeader = useMemo(
    () => columns.find(col => col.columns && col.columns.length > 0),
    [columns]
  );

  const renderEmptyStateComponent = useCallback(() => {
    const overrideEmptyStateVisibility = showEmptyState;

    if (overrideEmptyStateVisibility === null) {
      if (emptyStateComponent && !state.data?.length) {
        return emptyStateComponent;
      } else {
        return null;
      }
    } else {
      if (overrideEmptyStateVisibility) {
        return emptyStateComponent;
      } else {
        return null;
      }
    }
  }, [showEmptyState, emptyStateComponent, state?.data]);

  return (
    <>
      {canSelectRows ? (
        mainHeaderComponent ? null : (
          <SelectableRowsHeader
            label={label}
            selectedRowsLabel={selectedRowsLabel}
            hideSelectableRowsHeader={hideSelectableRowsHeader}
          />
        )
      ) : null}
      <MainWrapper
        label={label}
        hideTableFilters={hideTableFilters}
        hideTopPagination={hideTopPagination}
        canSelectRows={canSelectRows}
        highlightColumnOnHover={highlightColumnOnHover}
      >
        {mainHeaderComponent ? (
          <div className="app-table--main-header">{mainHeaderComponent}</div>
        ) : null}
        {hideTableHead ? null : (
          <div className="app-table--head">
            <div className={`app-table--head-inner d-flex `}>
              {columns.map((col, i) => {
                const groupLastStickyColumn = col.columns
                  ? _findLastIndex(col.columns, o => o.sticky)
                  : null;

                return (
                  col.field !== 'actions' && (
                    <Fragment key={col.key}>
                      {col.columns ? ( // Grouped Columns
                        <div
                          className={`grouped-columns col${col.width ? `-${col.width}` : ''} ${
                            col.className || ''
                          } ${col.sticky ? 'sticky' : ''} ${
                            groupedLastSticky === i ? 'grouped-last-sticky' : ''
                          } px-0`}
                          style={{
                            left: col?.left,
                            right: col?.right,
                            minWidth: col?.minWidth,
                            maxWidth: col?.maxWidth
                          }}
                          data-group={col.key}
                        >
                          <div
                            style={{ maxWidth: col?.maxWidth }}
                            className="grouped-columns__header fs-12 group-title text-blue text-truncate text-center text-uppercase bg-base pt-1 px-1"
                          >
                            {React.isValidElement(col.header) ? (
                              col.header
                            ) : (
                              <strong>{col.header}</strong>
                            )}
                          </div>
                          <div className="grouped-columns__body d-flex justify-content-between">
                            {!hideTableHead &&
                              col.columns.map(subCol => (
                                <HeaderCell
                                  groupWidth={col.width}
                                  key={subCol.key}
                                  groupExists={hasGroupHeader}
                                  isGroup={true}
                                  col={subCol}
                                  isLastSticky={i === groupLastStickyColumn}
                                  sorting={state?.sorting}
                                  onSort={handleSort}
                                  style={getColStyle(subCol)}
                                  highlightColumnOnHover={highlightColumnOnHover}
                                  label={label}
                                />
                              ))}
                          </div>
                        </div>
                      ) : hideTableHead ? null : (
                        <HeaderCell
                          col={col}
                          groupExists={hasGroupHeader}
                          isLastSticky={i === lastSticky}
                          sorting={state?.sorting}
                          onSort={handleSort}
                          style={getColStyle(col)}
                          highlightColumnOnHover={highlightColumnOnHover}
                          label={label}
                        />
                      )}
                    </Fragment>
                  )
                );
              })}
            </div>
            <TotalsBarWrapper
              columns={columns}
              state={state}
              label={label}
              isSolid={solidTotalBarKeys?.length ? solidTotalBarKeys.includes('total_key') : true}
              totalBaseKey="total"
              isModularTableShownInWidget={isModularTableShownInWidget}
            />

            {renderEmptyStateComponent()}
          </div>
        )}
        {showEmptyState ? null : (
          <>
            <div className="app-table--body gray-scrollbar">
              {state?.data?.map((data, index) => (
                <Row
                  key={index}
                  rows={rows}
                  label={label}
                  data={data}
                  columns={columns}
                  index={index}
                  getRowClassName={getRowClassName}
                  getRowId={getRowId}
                  setDangerousModal={setDangerousModal}
                  onCellDoubleClick={onCellDoubleClick}
                  getColStyle={getColStyle}
                  lastSticky={lastSticky}
                  lastCell={lastCell}
                  isRowSelectable={isRowSelectable}
                  canSelectRows={canSelectRows}
                  highlightColumnOnHover={highlightColumnOnHover}
                />
              ))}

              <TotalsBarWrapper
                columns={columns}
                label={label}
                totalBaseKey="footer_total"
                isSolid={solidTotalBarKeys.includes('footer_total_key')}
                className="cmt-4"
              />
              <TotalsBarWrapper
                columns={columns}
                label={label}
                totalBaseKey="second_footer_total"
                isSolid={solidTotalBarKeys.includes('second_footer_total_key')}
                className="cmt-6"
              />

              <TotalsBarWrapper
                columns={columns}
                label={label}
                totalBaseKey="third_footer_total"
                isSolid={solidTotalBarKeys.includes('third_footer_total_key')}
                className="cmt-6"
              />
            </div>
            {columns.some(c => c.footer) && state.data.length ? (
              <div className="app-table--footer">
                <div className="app-table--footer--inner">
                  {columns.map((col, i) => (
                    <div
                      key={i}
                      className={`cell col${col.width ? `-${col.width}` : ''}${
                        col.sticky ? ' sticky' : ''
                      }${i === lastSticky ? ' last-sticky' : ''} ${col.className || ''}`}
                      style={getColStyle(col)}
                    >
                      {col.footer ? col.footer(col) : null}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        )}
      </MainWrapper>
      {hideBottomPagination ? null : (
        <BottomPagination
          paging={state.paging}
          fetchData={fetchData}
          scrollToTopAfterPageChanged={scrollToTopAfterPageChanged}
          {...paginationLimits}
        />
      )}
    </>
  );
};

export default TableMain;
