import React from 'react';
import { useSelector } from 'react-redux';

import Vessel from 'common/components/jobs/_base/inputs/Vessel';
import Departments from 'common/components/jobs/_base/inputs/Departments';
import { departmentTypeEnums } from 'common/utils/fixed';
import JobLink from 'common/components/jobs/regular/components/JobLink';
import Priority from 'common/components/jobs/_base/inputs/Priority';
import Status from 'common/components/jobs/_base/inputs/Status';

import MaintenanceJobType from 'common/components/pms/jobs/inputs/MaintenanceJobType';
import JobPeriodicity from 'common/components/pms/jobs/components/JobPeriodicity';

import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import { selectIsUnscheduledJob } from 'common/components/pms/jobs/store/selectors';

const PmsHeader = ({ className, avoidCheck, drawerIsOpen }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const isUnscheduled = useSelector(selectIsUnscheduledJob);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <div className={`d-flex g-0 align-items-center flex-wrap flex-mdhd-nowrap ${className || ''}`}>
      {jobId ? (
        <div className="me-3">
          <JobLink disabled={true} className="mt-0" avoidCheck={avoidCheck} />
        </div>
      ) : null}
      <div className="job-header__pms-status">
        <Status vesselJobsOnly={true} hideLabel />
      </div>
      <div className="small-separator mx-2 bg-platinum py-1"></div>
      <Priority
        className="pms-importance min-width-120 max-width-120 col-1"
        disabled={!isUnscheduled}
        drawerIsOpen={drawerIsOpen}
        isPms
        hideLabel
      />
      <div className="small-separator mx-2  bg-platinum py-1"></div>
      <Departments
        avoidCheck={avoidCheck}
        type={departmentTypeEnums.main_department}
        disabled={!isUnscheduled || isOnBoard}
        boxPlaceholder={true}
        withCrewDepartments={false}
        onlyCrewDepartments={true}
        className="min-width-120"
      />
      <div className="small-separator mx-2 bg-platinum py-1"></div>
      <Departments
        type={departmentTypeEnums.departments}
        boxPlaceholder={true}
        showMore={false}
        avoidCheck={avoidCheck}
        disabled={!isUnscheduled || isOnBoard}
        placeholder={'Secondary dept(s)'}
        className="min-width-120 max-width-165 p-0"
      />
      <div className="small-separator mx-2 bg-platinum py-1"></div>
      <Vessel label={false} className="mb-0 p-0 min-width-120 max-width-120" isRequired />
      <div className="small-separator mx-2 bg-platinum py-1"></div>
      <MaintenanceJobType disabled={!isUnscheduled} className="width-308" />
      {isUnscheduled ? null : (
        <>
          <div className="small-separator mx-2 bg-platinum py-1"></div>
          <JobPeriodicity />
        </>
      )}
    </div>
  );
};

export default PmsHeader;
