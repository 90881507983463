import Footer from 'common/components/forms/digital/Footer';
import DigitalDisplay from 'common/components/forms/digital/Display';
import UploadDisplay from 'common/components/forms/upload/Display';
import Spinner from 'common/components/general/Spinner';
import SvgRender from 'common/components/general/SvgRender';
import edit from 'common/assets/svg/actions/edit.svg';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import FormInnerBody from './form-inner/Body';
import _get from 'lodash/get';
import ShadowBox from 'common/components/general/ShadowBox';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

const Body = ({
  form,
  formSubmission,
  formSubmissionPreviousValues,
  isEditing,
  setIsEditing,
  isFetching,
  formState,
  setFormState,
  formType,
  uploadedFileError,
  setUploadedFileError,
  uploadedFile,
  originalFile,
  setUploadedFile,
  isLocked,
  headerFormState,
  previewMode,
  hideEditButton
}) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <div className="form-manager--body gray-scrollbar">
      <ShadowBox flat={isEditing || previewMode}>
        {isFetching ? (
          <div className="p-2">
            <Spinner className="mt-2 cmb-12" />
          </div>
        ) : isEditing || previewMode ? (
          !form ? null : (
            <FormInnerBody
              form={form}
              formSubmission={formSubmission}
              formState={formState}
              formType={formType}
              selectedForm={form}
              isFetching={isFetching}
              setFormState={setFormState}
              uploadedFileError={uploadedFileError}
              setUploadedFile={setUploadedFile}
              setUploadedFileError={setUploadedFileError}
              uploadedFile={uploadedFile}
              originalFile={originalFile}
              previewMode={previewMode}
              headerFormState={headerFormState}
            />
          )
        ) : (
          <div className="d-flex flex-column p-2">
            {!hideEditButton && !isEditing && !isLocked && !previewMode ? (
              <AuthCheck
                permissions={[permissions.office.forms.edit_values, permissions.onboard.forms.view]}
              >
                <Button
                  type="button"
                  color="yellow"
                  className="edit-form-button d-flex align-items-center fs-14 
              cpe-12 cps-12 text-nowrap height-24 position-absolute text-primary"
                  onClick={() => setIsEditing(true)}
                >
                  <SvgRender className="me-1" src={edit} style={{ width: 12, height: 12 }} />
                  <strong>Edit Form</strong>
                </Button>
              </AuthCheck>
            ) : null}
            {formType === 'upload' ? (
              <UploadDisplay file={formSubmission?.file} isLocked={isLocked} />
            ) : formType === 'digital' ? (
              <div className="w-100p">
                <DigitalDisplay
                  form_submission_values={_get(formSubmission, 'values', null)}
                  form_submission_previous_values={formSubmissionPreviousValues}
                  form_fields={_get(form, 'form_fields', [])}
                />
              </div>
            ) : null}
          </div>
        )}
      </ShadowBox>

      {formSubmission ? <Footer formSubmission={formSubmission} /> : null}
    </div>
  );
};

export default Body;
