import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectActiveChapterBreadcrumbs,
  selectActiveChapterId,
  selectActiveChapterUid,
  selectManualId,
  isChapterPostFormOpen,
  selectActiveChapterContentHasPost,
  selectCurrentVersionId,
  selectChapter,
  selectIsComparedAtVersion,
  selectComparedAtVersionId,
  selectChapters,
  selectPreviousChapterUid,
  selectNextChapterUid
} from 'manuals/store/selectors';
import { useActions, useUpdateEffect } from 'utils/hooks';
import useRouter from 'use-react-router';

import * as manualsActions from 'manuals/store/actions';

import Breadcrumbs from 'common/components/tree-layout/components/Breadcrumbs';
import NavigationArrows from 'common/components/general/NavigationArrows';
import ChapterSortIndexPath from 'manuals/components/ChapterSortIndexPath';

import PostLoader from './PostLoader';
import Preview from './preview';

const ChapterContent = ({ postActions, form, changeRequestActions }) => {
  const { match, history } = useRouter();
  const [isLoading, setIsLoading] = useState(null);
  const [
    setActiveChapterUid,
    setChapterPost,
    setChapterPostAttachments,
    togglePostForm,
    setNavigationPreventionModal
  ] = useActions([
    manualsActions.setActiveChapterUid,
    manualsActions.setChapterPost,
    manualsActions.setChapterPostAttachments,
    manualsActions.togglePostForm,
    manualsActions.setNavigationPreventionModal
  ]);

  const manualId = useSelector(selectManualId);
  const activeId = useSelector(selectActiveChapterId);
  const activeUid = useSelector(selectActiveChapterUid);
  const isComparedAtVersion = useSelector(selectIsComparedAtVersion);
  const comparedAtVersionId = useSelector(selectComparedAtVersionId);
  const chapters = useSelector(selectChapters);

  const previousChapterUid = useSelector(selectPreviousChapterUid);
  const nextChapterUid = useSelector(selectNextChapterUid);

  const chapter = useSelector(state => selectChapter(state, activeUid));
  const isDraft = chapter && chapter.draft_change_request_count;

  const breadcrumbs = useSelector(!activeUid ? () => [] : selectActiveChapterBreadcrumbs);

  const isFormOpen = useSelector(isChapterPostFormOpen);
  const hasContent = useSelector(selectActiveChapterContentHasPost);

  const currentVersionId = useSelector(selectCurrentVersionId);
  const selectedPost = chapters[activeUid];

  const fetchPost = async (hideLoader = false) => {
    try {
      if (!hideLoader) setIsLoading(activeUid);

      const res = await postActions.onPostFetch({
        manual_id: manualId,
        uid: activeUid,
        version_id: !selectedPost.is_deleted ? currentVersionId : comparedAtVersionId
      });

      setChapterPost(res);

      if (
        isComparedAtVersion &&
        manualId &&
        activeUid &&
        comparedAtVersionId &&
        selectedPost?.compare_has_changed
      ) {
        setIsLoading(activeUid);

        await postActions.onComparePosts({
          manual_id: manualId,
          uid: activeUid,
          version_id: comparedAtVersionId
        });
      }

      if (res?.post && res.post?.attachments.length) {
        setChapterPostAttachments(res.post?.attachments);
      }

      setIsLoading(null);
    } catch (error) {
      setIsLoading(null);
      setChapterPost(null);
    }
  };

  useEffect(() => {
    setActiveChapterUid(match.params.chapter_uid ? parseInt(match.params.chapter_uid) : null);
  }, [match.params.chapter_uid]);

  useEffect(() => {
    if (
      activeUid &&
      activeUid === parseInt(match.params.chapter_uid) &&
      isLoading !== parseInt(match.params.chapter_uid)
    ) {
      fetchPost();
    }
  }, [activeUid]);

  useUpdateEffect(() => {
    if (isDraft && activeUid && !isLoading) {
      fetchPost(true);
    }
  }, [isDraft]);

  useUpdateEffect(() => {
    if (isFormOpen && activeUid && !isLoading) {
      fetchPost(true);
    }
  }, [isFormOpen]);

  const navigateToPost = chapterUid => {
    if (isFormOpen) {
      setNavigationPreventionModal({ isOpen: true, chapterUid });
    } else {
      history.push(`/manuals/${manualId}/chapter/${chapterUid}?version=${currentVersionId}`);
    }
  };

  return manualId && activeId && activeUid ? (
    <div
      className={`chapter-content${isFormOpen ? ' form-open' : hasContent ? ' with-content' : ''}`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <Breadcrumbs
          className="mb-3"
          breadcrumbs={breadcrumbs.map(b =>
            activeUid === chapter.uid ? { ...b, has_post: hasContent } : b
          )}
          activeUID={activeUid}
          render={chapter => (
            <>
              <ChapterSortIndexPath chapter={chapter} />
              {chapter.title}
            </>
          )}
          maxLimit={3}
          onClick={b => history.replace(`/manuals/${manualId}/chapter/${b.uid}`)}
        />

        <NavigationArrows
          onNextClick={() => navigateToPost(nextChapterUid)}
          onPreviousClick={() => navigateToPost(previousChapterUid)}
          disabledPrevious={!previousChapterUid}
          disabledNext={!nextChapterUid}
        />
      </div>

      <PostLoader isLoading={isLoading} togglePostForm={togglePostForm} />

      {form ? form : null}
      {!isLoading && hasContent ? (
        <Preview changeRequestActions={changeRequestActions} postActions={postActions} />
      ) : null}
    </div>
  ) : null;
};
export default ChapterContent;
