import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApiBaseUrl } from 'utils/api';

const excludedFileTypes = ['pdf', 'txt'];

const Document = ({ fileType, file }) => {
  const [hasError, setHasError] = useState(false);
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const [index, setIndex] = useState(0);
  const forceRerenderTimes = 0;
  const isOnBoard = useSelector(state => state.isOnBoard);

  const previewImageBaseUrl = isOnBoard ? getApiBaseUrl() : '';

  useEffect(() => {
    let timer = null;

    if (!isFrameLoaded) {
      timer = setTimeout(() => {
        // If iframe is not loaded & index is 0, increase index to force iframe to rerender.
        if (index <= forceRerenderTimes) {
          setIndex(old => old + 1);
        } else {
          // If iframe is not loaded & index is 1 then show the error message.
          setHasError(true);
        }
      }, 2500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [file?.url_inline, file?.encoded_url, index, isFrameLoaded]);

  const previewService = 'https://view.officeapps.live.com/op/view.aspx?src=';

  const onLoadHandler = () => {
    setIsFrameLoaded(true);
    setHasError(false);
  };

  return (
    <>
      {hasError ? (
        <div className="text-white fs-14 position-absolute top-50 start-0 w-100p text-center">
          Preview Unavailable
        </div>
      ) : null}

      <iframe
        key={index}
        title={file.id}
        src={
          isOnBoard || excludedFileTypes.includes(fileType)
            ? `${previewImageBaseUrl}${file?.url_inline}`
            : `${previewService}${file?.encoded_url}`
        }
        width="100%"
        height="100%"
        onLoad={onLoadHandler}
        frameBorder="0"
      ></iframe>
    </>
  );
};

export default Document;
