import { useQuery } from '@tanstack/react-query';
import { getEventOffHire } from './api';
import { GET_EVENT_OFF_HIRE } from './query-keys';
import { useAppSelector } from '@/store/hooks';
import { selectEventId } from '@/events/store/events/selectors';

export const useGetEventOffHireQuery = () => {
  const eventId: number | undefined = useAppSelector(selectEventId);

  return useQuery({
    queryKey: [GET_EVENT_OFF_HIRE, eventId],
    queryFn: () => getEventOffHire({ eventId }),
    enabled: !!eventId
  });
};
