import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import air from 'common/assets/svg/crew/transportation-airplane.svg';
import other from 'common/assets/svg/crew/transportation-other.svg';
import road from 'common/assets/svg/crew/transportation-road.svg';
import train from 'common/assets/svg/crew/transportation-train.svg';

const TicketTypeIcon = ({ type, style }) => {
  const icons = { air, other, road, train };

  if (!icons[type]) return null;

  return <SvgRender src={icons[type]} style={style ? style : { width: 16, height: 16 }} />;
};

export default TicketTypeIcon;
