import React, { useState, useEffect } from 'react';
import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';
import useValue from 'captain-reports/utils/useValue';
import { useSelector } from 'react-redux';
import * as reportActions from 'captain-reports/store/actions';
import { useActions } from 'utils/hooks';

const StoppageAssociatedElement = props => {
  const listOptions = useSelector(state => state.lists['stoppage-reason'].options);
  const value = useValue({
    key: 'stoppage_reason'
  });
  const [setValue] = useActions([reportActions.setValue]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!listOptions || !listOptions.length) return;

    if (value) {
      let found = listOptions.find(el => el.value === value);

      if (found && found.is_special) {
        setShow(true);
      } else {
        setShow(false);
        setValue({
          key: 'stoppage_associated_element',
          value: null
        });
      }
    } else {
      setShow(false);
      setValue({
        key: 'stoppage_associated_element',
        value: null
      });
    }
  }, [value, listOptions]);

  return show ? <DynamicSelect {...props} /> : null;
};

export default StoppageAssociatedElement;
