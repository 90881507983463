import React, { useState } from 'react';

import Input from 'common/components/form/inputs/Input';
import SvgRender from 'common/components/general/SvgRender';
import Spinner from 'common/components/general/Spinner';

import searchSvg from 'common/assets/svg/common/search.svg';
import close from 'common/assets/svg/actions/close.svg';
import PropTypes from 'prop-types';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { useDebounce } from 'utils/hooks';

const SearchInput = ({
  className = '',
  wrapperClassName = '',
  onChange,
  initialValue,
  inputProps = {},
  debounceDelay = 800,
  showClearButton = false,
  onClear
}) => {
  const [textFieldValue, setTextFieldValue] = useState(initialValue || '');
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(textFieldValue, debounceDelay);

  const onSearch = async search => {
    setIsSearching(true);

    await onChange(search);

    setIsSearching(false);
  };

  useUpdateEffect(() => {
    setTextFieldValue(initialValue);
  }, [initialValue]); // Only call effect if debounced search term changes

  useUpdateEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === '') {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]); // Only call effect if debounced search term changes

  const onClearButtonClick = () => {
    setTextFieldValue('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className={`styled-search-input ${className}`}>
      <div className={`search-input-wrapper d-flex align-items-center ${wrapperClassName}`}>
        <SvgRender src={searchSvg} style={{ height: 14, width: 14 }} />
        <Input
          data-testid="search-input__input"
          className="w-100p"
          placeholder="Search"
          value={textFieldValue}
          onChange={e => setTextFieldValue(e.target.value)}
          {...inputProps}
        />

        {isSearching ? (
          <Spinner size={22} className={'me-1'} />
        ) : showClearButton ? (
          <SvgRender
            data-testid="search-input__clear"
            className="search-input-wrapper__clear"
            src={close}
            style={{ height: 10, width: 10 }}
            onClick={onClearButtonClick}
          />
        ) : null}
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string
};

export default SearchInput;
