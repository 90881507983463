import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import Profile from 'views/vessels/profile';

const vessels = [
  {
    path: `${paths.vessels}`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.vessel.view],
    documentTitle: 'Vessel Profile'
  },
  {
    path: `${paths.vessels}/:tab(documents)`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [
      permissions.onboard.vessel.documents.edit,
      permissions.onboard.vessel.documents.view
    ],
    documentTitle: 'Vessel Profile'
  },
  {
    path: `${paths.vessels}/:tab(info)`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.vessel.view],
    documentTitle: 'Vessel Profile'
  },
  {
    path: `${paths.vessels}/:tab(info)/:subtab`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.vessel.view],
    documentTitle: 'Vessel Profile'
  },
  {
    path: `${paths.vessels}/:tab(info)/:subtab/:panel`,
    component: Profile,
    key: 'vessel-profile',
    app: true,
    appClass: 'vessel-profile-wrapper',
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.vessel.view],
    documentTitle: 'Vessel Profile'
  }
];

export default vessels;
