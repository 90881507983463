import TYPES from './types';
import { get } from 'utils/api';

export const getRunningHoursDue = params => dispatch => {
  dispatch({ type: TYPES.GET_RUNNING_HOURS_DUE.START });

  return get(`/captain-report-due`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_RUNNING_HOURS_DUE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_RUNNING_HOURS_DUE.ERROR,
        payload: { response: error }
      });

      throw error;
    });
};
