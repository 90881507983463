import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';
import { selectMaintenanceJobHasSystemRunningHours } from 'common/components/pms/jobs/store/selectors';
import RunningHoursDueInput from './RunningHoursDueInput';
import DueDateInput from './DueDateInput';
import { getConfig } from './helpers';
import UpdatedValueIcon from 'common/components/pms/jobs/inputs/maintenance/main/system-running-hours/UpdatedValueIcon';

const SystemRunningHours = ({ key, className, type }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isJobLocked = useSelector(selectIsJobLocked);
  const hasSystemRunningHoursFlag = useSelector(selectMaintenanceJobHasSystemRunningHours);

  const { styleClass, title, icon, ...configuration } = useMemo(
    () => getConfig(type, isJobLocked, isOnBoard, hasSystemRunningHoursFlag),
    [type, isJobLocked, isOnBoard, hasSystemRunningHoursFlag]
  );

  const isPrevious = useMemo(() => type === 'previous', [type]);
  const isDueTypeField = useMemo(() => type === 'due', [type]);

  return (
    <Col
      key={key}
      xs={isDueTypeField ? 5 : 6}
      className={`d-flex system-running-hour ${className || ''}`}
    >
      <div
        className={`system-running-hour__sidebar d-flex align-items-center flex-column ${
          styleClass || ''
        }`}
      >
        <div className="title fs-9 fw-bold text-white pt-1">{title}</div>
        <UpdatedValueIcon isDueTypeField={isDueTypeField} icon={icon} />
      </div>

      <div className="d-flex flex-column">
        <DueDateInput
          isPrevious={isPrevious}
          isDueTypeField={isDueTypeField}
          type={type}
          {...configuration}
        />
        <RunningHoursDueInput
          isPrevious={isPrevious}
          isDueTypeField={isDueTypeField}
          type={type}
          {...configuration}
        />
      </div>
    </Col>
  );
};

export default SystemRunningHours;
