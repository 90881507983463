import { FC } from 'react';
import styled from '@emotion/styled';

import ToggleButton from 'common/components/general/ToggleButton';
import SvgRender from 'common/components/general/SvgRender';

import logsIcon from '@/ts-common/assets/svg/common/logs.svg';

type ToggleLogsTimelineDrawerButtonProps = {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  buttonClassName?: string;
  iconStyle?: React.CSSProperties;
};

const ToggleLogsTimelineDrawerButton: FC<ToggleLogsTimelineDrawerButtonProps> = ({
  className = '',
  buttonClassName = '',
  iconStyle,
  onClick,
  disabled
}) => {
  return (
    <Container className={`${className}`}>
      <ToggleButton onClick={onClick} className={buttonClassName} disabled={disabled}>
        <SvgRender
          src={logsIcon}
          style={iconStyle || { width: 16, height: 16 }}
          className="text-primary"
        />
      </ToggleButton>
    </Container>
  );
};

const Container = styled.div`
  .toggle-button {
    width: 2rem;
    height: 2rem;
  }
`;

export default ToggleLogsTimelineDrawerButton;
