import moment from 'moment';

const DateView = ({ value = [] }) => {
  return (
    <div>
      {(value || []).map((value, index) => (
        <div className={`fs-12 text-primary ${index !== 0 ? 'cmt-4' : ''}`} key={index}>
          {value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
        </div>
      ))}
    </div>
  );
};

export default DateView;
