import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';

import { v4 as uuid } from 'uuid';

import bold from 'common/assets/svg/editor/bold.svg';
import italic from 'common/assets/svg/editor/italic.svg';
import underline from 'common/assets/svg/editor/underline.svg';
import alignment from 'common/assets/svg/editor/left-align-text.svg';
import ul from 'common/assets/svg/editor/bulleted-list.svg';
import ol from 'common/assets/svg/editor/ordered-list.svg';
import indent from 'common/assets/svg/editor/indent.svg';
import outdent from 'common/assets/svg/editor/outdent.svg';
import link from 'common/assets/svg/editor/link.svg';
import table from 'common/assets/svg/editor/table.svg';
import sup from 'common/assets/svg/editor/superscript.svg';
import sub from 'common/assets/svg/editor/subscript.svg';

export const PreviewHtml = ({ value }: { value: string }) => {
  return (
    <div className="redactor-box preview-html">
      <div className="redactor-in" dangerouslySetInnerHTML={{ __html: value }}></div>
    </div>
  );
};

const $R = (window as any).Redactor;

type ToolBarButton = { key: string; icon: string };

const buttons = [
  { key: 'bold', icon: bold },
  { key: 'italic', icon: italic },
  { key: 'underline', icon: underline },
  { key: 'alignment', icon: alignment },
  { key: 'ul', icon: ul },
  { key: 'ol', icon: ol },
  { key: 'indent', icon: indent },
  { key: 'outdent', icon: outdent },
  { key: 'link', icon: link },
  { key: 'table', icon: table },
  { key: 'sup', icon: sup },
  { key: 'sub', icon: sub }
] as ToolBarButton[];

type EditorProps = {
  value: string | null;
  error?: string | null;
  className?: string;
  label?: ReactNode;
  id?: string;
  minHeight?: string;
  maxHeight?: string;
  onChange?: (html: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  white?: boolean;
  invisible?: boolean;
  disabled?: boolean;
  viewOnly?: boolean;
  plugins?: 'alignment' | 'table' | 'scriptbuttons';
  hiddenToolbarButtons?: string[];
};

const Editor: FC<EditorProps> = ({
  value,
  error,
  className = '',
  label,
  id,
  minHeight,
  maxHeight,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  white,
  invisible,
  disabled = false,
  viewOnly = false,
  plugins = ['alignment', 'table', 'scriptbuttons'],
  hiddenToolbarButtons = []
}) => {
  const [init, setInit] = useState(false);

  const fID = useMemo(() => id || uuid(), [id]);
  // If id is not passed and you need multiple editors on the same page, this will take care of everything for you.

  const initRedactor = () => {
    const toolbarButtons = buttons.filter(b =>
      b.key === 'table' || b.key === 'alignment'
        ? plugins.includes(b.key)
        : !hiddenToolbarButtons.includes(b.key)
    );

    $R(`#editor-${fID}`, {
      buttons: toolbarButtons.map(b => b.key),
      plugins,
      minHeight: minHeight || '168px',
      maxHeight: maxHeight || '668px',

      callbacks: {
        changed: (html: string | null) => {
          if (onChange && html !== value && !disabled) {
            onChange(html === '<p><br></p>' ? null : html);
          }
        },

        focus: () => (onFocus ? onFocus() : false),
        blur: () => (onBlur ? onBlur() : false)
      },
      linkTarget: '_blank',
      pasteLinkTarget: '_blank',
      pastePlainText: true,
      pasteKeepAttrs: [],
      pasteBlockTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'blockquote', 'p'],
      linkSize: '100%',
      styles: false,
      stylesClass: 'editor-content-wrapper',
      toolbarFixed: false,
      cleanSpaces: true,
      placeholder
    });

    toolbarButtons.forEach(b => {
      const button = $R(`#editor-${fID}`)?.toolbar.getButton(b.key);
      if (button) button?.setIcon(`<img src="${b.icon}" height="14" />`);
    });

    setInit(true);
  };

  useEffect(() => {
    initRedactor();
  }, []);

  useEffect(() => {
    const editorID = `#editor-${fID}`;

    if (document.querySelector(editorID) && init && value !== $R(editorID, 'source.getCode')) {
      $R(editorID, 'source.setCode', value);
    }
  }, [init, value]);

  return (
    <FormGroupWrap
      className={`form-editor ${disabled ? 'disabled' : ''} ${viewOnly ? 'view-only' : ''} ${
        white ? 'job-input job-input--description' : ''
      } ${invisible ? 'invisible-editor' : ''} ${className}`}
      label={label}
      error={error}
    >
      <div className="position-relative flex-fill form-field">
        <textarea className="editor-content form-control w-100p" id={`editor-${fID}`} rows={6} />
      </div>
    </FormGroupWrap>
  );
};

export default Editor;
