import inventory from 'common/assets/svg/accounting/inventory.svg';
import TopFilters from './TopFilters';

export const getColumns = () => [
  {
    header: 'SPARE PARTS',
    type: 'string',
    key: 'items',
    className: 'position-static',
    minWidth: 470,
    maxWidth: 470,
    canFilter: false
  },
  {
    header: 'UNIT',
    key: 'unit',
    type: 'string',
    minWidth: 72,
    maxWidth: 72,
    canFilter: false
  },
  {
    header: 'OPTIMAL/MIN QUANTITY',
    key: 'minimum_required_rob',
    type: 'number',
    headerClassName: 'text-center',
    minWidth: 154,
    maxWidth: 154,
    canFilter: false
  },
  {
    header: 'ROB',
    key: 'rob',
    type: 'number',
    minWidth: 55,
    maxWidth: 55,
    canFilter: false
  },
  {
    header: 'Last Survey',
    key: 'last_survey',
    type: 'group',
    canFilter: false,
    columns: [
      {
        header: 'LOCATION',
        key: 'last_survey_location',
        type: 'string',
        minWidth: 224,
        maxWidth: 224,
        canFilter: false
      },
      {
        header: 'ROB NEW',
        key: 'last_survey_rob_new',
        type: 'number',
        minWidth: 55,
        maxWidth: 55,
        canFilter: false
      },
      {
        header: 'ROB USED',
        key: 'last_survey_rob_used',
        type: 'number',
        minWidth: 55,
        maxWidth: 55,
        canFilter: false
      },
      {
        header: 'ROB IN REPAIR',
        key: 'last_survey_rob_in_repair',
        type: 'number',
        minWidth: 60,
        maxWidth: 60,
        canFilter: false
      },
      {
        header: 'ROB',
        key: 'last_survey_rob',
        type: 'number',
        minWidth: 55,
        maxWidth: 55,
        canFilter: false
      },
      {
        header: 'VS CALC ROB',
        key: 'last_survey_calculated_rob',
        type: 'number',
        minWidth: 64,
        maxWidth: 64,
        canFilter: false
      },
      {
        header: 'DATE',
        key: 'last_survey_timestamp',
        type: 'date',
        minWidth: 110,
        maxWidth: 110,
        canFilter: false
      }
    ]
  },
  {
    header: 'Last Auto Update',
    key: 'last_auto_update',
    type: 'group',
    canFilter: false,
    columns: [
      {
        header: 'ACTION',
        type: 'string',
        key: 'last_auto_update_action',
        minWidth: 104,
        maxWidth: 104,
        canFilter: false
      },
      {
        header: 'QTY',
        type: 'number',
        key: 'last_auto_update_quantity',
        minWidth: 64,
        maxWidth: 64,
        canFilter: false
      },
      {
        header: 'DATE',
        key: 'last_auto_update_date',
        type: 'date',
        minWidth: 110,
        maxWidth: 110,
        canFilter: false
      }
    ]
  },
  {
    header: '',
    key: 'history',
    minWidth: 24,
    maxWidth: 24,
    className: 'action',
    canFilter: false
  },
  {
    header: 'Minimum Quantity Is Specified',
    key: 'has_minimum_required_rob',
    type: 'boolean',
    hidden: true,
    canFilter: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Minimum Quantity',
    key: 'minimum_required_rob',
    type: 'number',
    hidden: true,
    canFilter: true
  },
  {
    header: 'Optimal Quantity Is Specified',
    key: 'has_optimal_rob',
    type: 'boolean',
    hidden: true,
    canFilter: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Optimal Quantity',
    key: 'optimal_rob',
    type: 'number',
    hidden: true,
    canFilter: true
  },
  {
    header: 'Last Survey Date',
    key: 'last_survey_timestamp',
    type: 'date',
    hidden: true,
    canFilter: true
  }
];

const getTopFilters = () => [
  {
    name: 'vessel_id',
    operation: 'oneOf',
    value: null
  },
  {
    name: 'vessel_system_group_id',
    operation: 'oneOf',
    value: null
  },
  {
    name: 'rob_is_under_optimal',
    operation: '=',
    value: null
  },
  {
    name: 'rob_is_under_minimum_required',
    operation: '=',
    value: null
  },
  {
    name: 'hidden',
    operation: '=',
    value: 'false'
  }
];

const config = {
  getColumns,
  getTopFilters,
  topFiltersComponent: TopFilters,
  icon: inventory,
  hideTableFilters: false,
  hideTableReorder: true,
  isModular: true,
  tableStyle: 'report',
  pageTitleComponent: null,
  name: 'Spare Parts Inventory',
  entity: 'pms-spare-parts'
};

export default config;
