import React, { useState } from 'react';

import _get from 'lodash/get';

import warningIcon from 'common/assets/svg/common/warning-icon.svg';

import RowColumns from './RowColumns';
import Departments from 'common/components/general/Departments';
import Priority from 'common/components/general/Priority';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';

import { useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import * as formActions from 'store/forms/actions';
import { useActions } from 'utils/hooks';
import PageLoader from 'common/components/general/PageLoader';

export const FormAlarm = ({ hasAlarm }) => {
  const { tooltipID, avoidRender } = useTooltipID();
  if (avoidRender || !hasAlarm) return null;

  return (
    <>
      <div className="cme-4 cpe-6" id={tooltipID}>
        <SvgRender style={{ height: 14, width: 14 }} className="text-red" src={warningIcon} />
      </div>
      <Tooltip target={tooltipID} placement="top-start">
        <span>This form is currently in alarm</span>
      </Tooltip>
    </>
  );
};

const RowFields = ({ row, setInitialData, isHovering, drawer, setDrawerType }) => {
  const [isLoading, setIsLoading] = useState(false);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const scheduledIntervalsOptions = useSelector(state =>
    selectListOptionsFromStore(state, 'schedule-intervals')
  );
  const periodicity = scheduledIntervalsOptions.find(({ label }) => label === row.periodicity);

  const [getDigitalForm] = useActions([formActions.getDigitalForm]);

  const onFormFillWithInitialValues = async () => {
    try {
      setIsLoading(true);

      const form = await getDigitalForm({ id: _get(row, 'form.id') });
      setInitialData({ form: form, vesselId: _get(row, 'vessel.id') });
      drawer.toggle();
      setDrawerType('add');
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <RowColumns
        isHovering={isHovering}
        isOnBoard={isOnBoard}
        alarm={<FormAlarm hasAlarm={row.hasAlarm} />}
        vessels={<div className={`d-flex`}>{_get(row, 'vessel.name')}</div>}
        form={_get(row, 'form.name')}
        formUid={_get(row, 'form.uid')}
        revision={_get(row, 'form.revision')}
        form_form_field={_get(row, 'form_form_field')}
        form_form_field_value={_get(row, 'form_form_field_value')}
        importance={
          <Priority size="large" withText={false} value={_get(row, 'importance.label')} />
        }
        department={
          _get(row, 'form.department') ? <Departments values={[row.form.department]} /> : '-'
        }
        periodicity={_get(periodicity, 'name') || '-'}
        onFormFillWithInitialValues={onFormFillWithInitialValues}
      />
      <PageLoader isLoading={isLoading} />
    </>
  );
};

export default RowFields;
