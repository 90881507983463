import { useState } from 'react';
import FormInnerHeader from './form-inner/Header';
import EmptyFormSubmission from './EmptyFormSubmission';

import FormRemarks from 'common/components/forms/_components/FormRemarks';
import PreviousFormRemarks from 'common/components/forms/_components/PreviousFormRemarks';

import { useDispatch, useSelector } from 'react-redux';
import ShadowBox from 'common/components/general/ShadowBox';
import { Collapse } from 'reactstrap';
import LinkedFindings from 'common/components/link-entities/findings/LinkedFindings';
import { unlinkFormFinding } from 'store/forms/actions';

const Header = ({
  form,
  formSubmission,
  status,
  setStatus,
  setSubmissionDate,
  remarks,
  setRemarks,
  collapsedRemarks,
  onRemarksChange,
  actionable,
  onActionableChange,
  previewMode,
  filterVesselSelectorOptions,
  formType,
  isOnLinkMode,
  refetchFormSubmission,
  customSelectorComponent,
  ...rest
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const [collapsedFindings, setCollapsedFindings] = useState(true);
  const dispatch = useDispatch();
  const formSubmissionFindings = formSubmission?.findings;
  const hasFormSubmissionFindings = formSubmissionFindings?.length > 0;
  const previousFormSubmission = formSubmission
    ? formSubmission?.form?.previous_form_submission
    : form?.previous_form_submission;

  const hasRemarks = remarks.core || remarks.onboard;
  const hasPreviousRemarks =
    previousFormSubmission?.review_remarks_actionable?.review_remarks ||
    previousFormSubmission?.for_correction_remarks_actionable?.for_correction_remarks;

  const shouldShowRemarks =
    status &&
    (hasRemarks ||
      (!isOnboard && formSubmission && !['draft', 'scheduled'].includes(status?.progress)));

  const onUnlink = async id => {
    if (!id || !formSubmission?.id) return;

    try {
      await dispatch(unlinkFormFinding({ submission_id: formSubmission?.id, id })).unwrap();
      refetchFormSubmission();
    } catch (err) {
      console.error(err);
    }
  };

  if (previewMode) return null;

  return (
    <div
      className={`form-manager--header header-drawer ${
        collapsedRemarks && collapsedFindings ? 'hidden-body' : ''
      }`}
    >
      {customSelectorComponent ? customSelectorComponent : null}
      <ShadowBox flat={!formSubmission} className={`px-3 cpt-4 cpb-4`}>
        {formSubmission || (!formSubmission && form) ? (
          <FormInnerHeader
            form={form}
            formSubmission={formSubmission}
            status={status}
            setStatus={setStatus}
            shouldShowRemarksCollapse={shouldShowRemarks || hasPreviousRemarks}
            collapsedRemarks={collapsedRemarks}
            setCollapsedFindings={setCollapsedFindings}
            collapsedFindings={collapsedFindings}
            filterVesselSelectorOptions={filterVesselSelectorOptions}
            formType={formType}
            isOnLinkMode={isOnLinkMode}
            customSelectorComponent={customSelectorComponent}
            {...rest}
          />
        ) : !customSelectorComponent ? (
          <EmptyFormSubmission customSelectorComponent={customSelectorComponent} {...rest} />
        ) : null}

        <Collapse
          isOpen={
            ((shouldShowRemarks || hasPreviousRemarks) && !collapsedRemarks) ||
            (hasFormSubmissionFindings && !collapsedFindings)
          }
        >
          <div className="min-height-40 w-100p">
            {!collapsedFindings && collapsedRemarks ? (
              <LinkedFindings
                findingsState={formSubmissionFindings}
                onUnlink={onUnlink}
                className="cmt-12 cmb-4"
                color="light-1"
              />
            ) : collapsedFindings && !collapsedRemarks ? (
              <div className="cmt-12 mb-1">
                {shouldShowRemarks ? (
                  <FormRemarks
                    status={status}
                    remarks={remarks}
                    setRemarks={setRemarks}
                    onRemarksChange={onRemarksChange}
                    actionable={actionable}
                    onActionableChange={onActionableChange}
                    isFillingNewForm={!formSubmission}
                    disableFormRemarks={isOnLinkMode}
                  />
                ) : null}
                {hasPreviousRemarks ? (
                  <PreviousFormRemarks
                    wrapperClassName={`${formSubmission ? '' : 'cmt-20'} ${
                      shouldShowRemarks ? 'mt-1' : ''
                    }`}
                    remarksClassName={`${formSubmission ? 'bg-light-gray' : 'bg-diamond-cut'}`}
                    previousFormSubmission={previousFormSubmission}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </Collapse>
      </ShadowBox>
    </div>
  );
};

export default Header;
