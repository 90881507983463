import React, { useState } from 'react';
import { components } from 'react-select';

import AsyncSelector from 'common/components/selectors/AsyncSelector';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const PurchasingItemsSelector = ({
  filter,
  onChange,
  isMulti,
  autoFocus = true,
  isMga = false,
  className = '',
  ...rest
}) => {
  const [selectedPurchasingItems, setSelectedPurchasingItems] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'stores' },
    { selected: selectedPurchasingItems, setSelected: setSelectedPurchasingItems }
  );

  return (
    <AsyncSelector
      autoFocus={autoFocus}
      className={`mb-0 ${className}`}
      placeholder="Select items"
      components={{ Option }}
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.code} ${option.description}`}
      isAsync
      isMulti={isMulti}
      value={
        filter?.value
          ? selectedPurchasingItems.filter(el => filter.value?.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() =>
        getInitialAsyncValues('stores', null, null, {
          is_mga: isMga,
          includes: ['store_group'],
          limit: 100
        })
      }
      loadOptions={search =>
        getAsyncOptions(search, 'stores', {
          is_mga: isMga,
          includes: ['store_group'],
          limit: 100
        })
      }
      onChange={selected => {
        setSelectedPurchasingItems(isMulti ? selected : [selected]);
        onChange(selected ? (isMulti ? selected.map(s => s.id) : selected) : '', 'store_ids');
      }}
      {...rest}
    />
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex overflow-hidden">
        <span className="cpe-12 ">{props.data.code}</span>
        <span className="text-nowrap text-truncate d-inline-block">{props.data.description}</span>
      </div>
    </components.Option>
  );
};

export default PurchasingItemsSelector;
