import React from 'react';
import ActionButton from 'common/components/buttons/ActionButton';
import PropTypes from 'prop-types';
import add from 'common/assets/svg/actions/add.svg';
import link from 'common/assets/svg/common/link.svg';
import { useSelector } from 'react-redux';
import { selectModuleSyncButtonIsVisible } from 'events/store/events/selectors';

const AddJobAction = ({ toggleJobDrawer }) => {
  const isSyncButtonVisible = useSelector(selectModuleSyncButtonIsVisible);

  return (
    <div className={`d-flex align-items-center ${isSyncButtonVisible ? 'me-8' : ''}`}>
      <ActionButton
        className="text-dark me-1"
        color="yellow"
        icon={add}
        onClick={() => toggleJobDrawer(true)}
      >
        Create Job
      </ActionButton>
      <ActionButton color="white" onClick={() => toggleJobDrawer(false)} icon={link}>
        Link to existing Job
      </ActionButton>
    </div>
  );
};

AddJobAction.propTypes = {
  toggleJobDrawer: PropTypes.func
};

export default AddJobAction;
