import React, { useCallback, useState } from 'react';
import AddSystem from './AddSystem';
import ModularTable from 'common/components/ModularTable';
import EditSystemForm from './EditSystemForm';

import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectEventSavedVessel } from 'events/store/events/selectors';
import {
  getEventLubOilAnalysisAssignments,
  removeEventLubOilAnalysisAssignment
} from 'events/store/event-modules/lub-oil-analysis/actions';
import { useTable } from 'common/components/table';
import { columns, rows } from './tableConfig';
import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import { useDrawer } from 'common/components/drawer';

import edit from 'common/assets/svg/actions/edit.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import { useUpdateEffect } from 'utils/hooks';

const Assignments = () => {
  const drawer = useDrawer(false);
  const [active, setActive] = useState(null);

  const dispatch = useDispatch();

  const eventVessel = useSelector(selectEventSavedVessel);
  const eventId = useSelector(selectEventId);
  const isOnboard = useSelector(state => state.isOnBoard);

  const fetchData = async params => {
    if (!eventVessel) return;

    try {
      await dispatch(
        getEventLubOilAnalysisAssignments({
          event_id: eventId,
          ...params
        })
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const table = useTable({
    label: 'events_lub_oil_analysis_assignments',
    requestTableListFrom: fetchData,
    defaultRequestParams: { paging: false, filters: false, sorting: true },
    columns: constructColumns(columns)
  });

  const toggleDrawer = useCallback(
    data => {
      setActive(data);
      drawer.open();
    },
    [drawer]
  );

  useUpdateEffect(() => {
    table.refetchData();
  }, [eventVessel?.id]);

  if (!eventVessel) return;

  return (
    <div className={table?.state?.data?.length ? 'mt-1' : ''}>
      {table?.state?.data?.length ? (
        <ModularTable
          style="default"
          hideTopPagination
          hideTableFilters
          hideTableSearch
          handleTableSort={({ sorting }) => table.refetchData({ sorting })}
          drawer={<EditSystemForm drawer={drawer} active={active} setActive={setActive} />}
          onDelete={async ({ id }) => {
            await dispatch(removeEventLubOilAnalysisAssignment({ id, event_id: eventId }));
            table.refetchData();
          }}
          rows={{
            ...rows,
            actions: data => ({
              options: [
                {
                  label: 'Edit',
                  icon: edit,
                  onClick: () => toggleDrawer(data)
                },
                isOnboard
                  ? {}
                  : {
                      label: 'Remove System',
                      icon: remove,
                      preventionModal: true,
                      wrapperClassName: 'bg-red text-white',
                      modalProps: {
                        params: { id: data.id },
                        header: 'Remove System',
                        actionText: 'REMOVE',
                        body: (
                          <div className="text-center">
                            Are you sure you want to remove the system{' '}
                            <strong>{`${data?.vessel_system_assignment?.description}`}</strong> from
                            lub oil analysis?
                          </div>
                        )
                      }
                    }
              ]
            })
          }}
          {...table}
        />
      ) : null}

      {!isOnboard && <AddSystem tableLabel={table.label} refetchData={table.refetchData} />}
    </div>
  );
};

export default Assignments;
