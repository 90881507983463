import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { lubOilSystemAnalysisResultsOptions } from 'common/utils/fixed';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const LubOilAnalysisResult = ({ analysis, withText = true }) => {
  const selected = useMemo(
    () => lubOilSystemAnalysisResultsOptions.find(({ value }) => value === analysis),
    [analysis]
  );

  if (!analysis) return '';

  const styles = { attention: Attention, action: Action, normal: Normal };
  const StyledIcon = styles[analysis];

  return (
    <div className="d-flex align-items-center">
      {StyledIcon ? <StyledIcon /> : null}
      {withText && <span className="ps-1">{selected?.label ?? ''}</span>}
    </div>
  );
};

const Attention = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid ${variables.goldBell};
`;

const Action = styled.div`
  min-width: ${variables.size10};
  max-width: ${variables.size10};
  height: ${variables.size10};
  border-radius: 1px;
  background-color: ${variables.crimson};
`;

const Normal = styled.div`
  min-width: ${variables.size10};
  max-width: ${variables.size10};
  height: ${variables.size10};
  border-radius: 100%;
  background-color: ${variables.turquoise};
`;

LubOilAnalysisResult.propTypes = {
  analysis: PropTypes.oneOf(lubOilSystemAnalysisResultsOptions.map(({ value }) => value)),
  withText: PropTypes.bool
};

export default LubOilAnalysisResult;
