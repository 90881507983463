import { resolveFinding, getFindings, createReportFinding } from './actions';
import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  totals: null
};

const slice = createSlice({
  name: 'findings',
  initialState: INITIAL_STATE,
  extraReducers: {
    [getFindings.pending]: state => {
      state.isFetching = true;

      return state;
    },
    [getFindings.fulfilled]: (state, { payload }) => {
      state.data = payload.data.data;
      state.isFetching = false;

      return state;
    },
    [getFindings.rejected]: state => {
      state.date = {};
      state.isFetching = false;

      return state;
    },

    [resolveFinding.pending]: state => {
      state.isFetching = true;

      return state;
    },
    [resolveFinding.rejected]: state => {
      state.isFetching = false;

      return state;
    },
    [resolveFinding.fulfilled]: state => {
      state.isFetching = false;
      return state;
    },

    [createReportFinding.pending]: state => {
      state.isFetching = true;
    }
  }
});

export default slice.reducer;
