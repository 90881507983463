import { createSelector } from 'reselect';

const selectChatboxReducer = state => state.chatbox;

export const areMessagesFetching = createSelector(
  selectChatboxReducer,
  chatboxReducer => chatboxReducer.isFetching
);

export const selectMessages = createSelector(
  selectChatboxReducer,
  chatboxReducer => chatboxReducer.messages
);

export const selectUnreadMessagesCount = createSelector(
  selectChatboxReducer,
  chatboxReducer => chatboxReducer.unread_messages
);

//
const selectGlobalMessages = createSelector(
  selectChatboxReducer,
  chatboxReducer => chatboxReducer.global
);

export const areGlobalUnreadMessagesFetching = createSelector(
  selectGlobalMessages,
  global => global.isFetching
);

export const selectGlobalUnreadMessages = createSelector(
  selectGlobalMessages,
  global => global.unread_messages
);

export const selectGlobalTotalUnreadMessages = createSelector(
  selectGlobalMessages,
  global => global.total
);
