// import SvgRender from 'common/components/general/SvgRender';
// import info from 'common/assets/svg/common/info-line.svg';

const HTML = ({ title, value, parentId, previewMode = false }) => {
  return (
    <div
      className={`digital-forms-html redactor-box ${previewMode ? 'preview' : ''} ${
        !parentId ? 'redactor-box' : 'digital-forms-html__empty'
      } w-100p`}
    >
      {/* <div className="fs-12 text-violet mb-1 d-flex align-items-center">
        <SvgRender src={info} style={{ width: 12, height: 12 }} className={`me-1`} />
        {title}
      </div> */}
      <div
        className="fs-12 text-primary redactor-in"
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    </div>
  );
};

export default HTML;
