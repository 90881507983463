import PropTypes from 'prop-types';

export const FormType = PropTypes.shape({
  uid: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  is_for_vessel: PropTypes.bool,
  department_id: PropTypes.number,
  importance_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  deleted_at: PropTypes.string,
  updated_at_on_sync_to_onboard: PropTypes.string,
  department: PropTypes.object,
  importance: PropTypes.object,
  vessels: PropTypes.array,
  id: PropTypes.number,
  file_id: PropTypes.number,
  created_by_id: PropTypes.number,
  revision: PropTypes.string,
  is_current: PropTypes.bool,
  form_fields: PropTypes.array
});

export const FormSubmissionType = PropTypes.shape({
  id: PropTypes.number,
  form_id: PropTypes.number,
  file_id: PropTypes.number,
  created_by_id: PropTypes.number,
  updated_by_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  vessel_id: PropTypes.number,
  submitted_on_vessel: PropTypes.bool,
  submitted_by_id: PropTypes.number,
  submitted_at: PropTypes.string,
  last_sent_version: PropTypes.number,
  form_status_id: PropTypes.number,
  updated_at_on_sync_to_onboard: PropTypes.string,
  sync_to_onboard: PropTypes.bool,
  submission_date: PropTypes.string,
  review_remarks: PropTypes.string,
  for_correction_remarks: PropTypes.string,
  deleted_at: PropTypes.string,
  updated_at_on_sync_to_elastic: PropTypes.string,
  sync_to_core: PropTypes.bool,
  uuid: PropTypes.string,
  synced_at: PropTypes.string,
  type_value: PropTypes.string,
  type_entity_type: PropTypes.string,
  type_entity_id: PropTypes.number,
  type_entity_uuid: PropTypes.string,
  resubmitted: PropTypes.bool,
  for_correction_remarks_actionable: PropTypes.bool,
  review_remarks_actionable: PropTypes.bool,
  reviewed_at: PropTypes.string,
  reviewed_by_id: PropTypes.number,
  values: PropTypes.array,
  job_count: PropTypes.number,
  chapter_form_count: PropTypes.number,
  action_document_count: PropTypes.number,
  origin: PropTypes.string,
  review_process_substep_form_count: PropTypes.number,
  created_by: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string
  }),
  submitted_by: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    crew_rank: PropTypes.object
  }),
  updated_by: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    crew_rank: PropTypes.object
  }),
  form: FormType,
  status: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    progress: PropTypes.string,
    active: PropTypes.bool,
    is_for_vessel: PropTypes.bool,
    is_for_office: PropTypes.bool,
    can_select_from_onboard: PropTypes.bool,
    edit_side: PropTypes.string,
    sort_index: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    color: PropTypes.string,
    deleted_at: PropTypes.string,
    updated_at_on_sync_to_onboard: PropTypes.string
  }),
  vessel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    imo_no: PropTypes.string
  }),
  reviewed_by: PropTypes.object,
  form_field_findings_values: PropTypes.array
});
