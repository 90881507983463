import React, { useEffect, useState } from 'react';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import {
  selectCaptainReportLubricantTotals,
  selectTotalLegStartTimestamp,
  selectTotalSteamingTime,
  selectGeneratorsLegRunningHoursTotal,
  selectCaptainReportTemplateType
} from 'captain-reports/store/selectors';

import useReportTimeDiff from 'captain-reports/utils/useReportTimeDiff';
import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import _debounce from 'lodash/debounce';
import { numberToStr } from 'common/utils/numbers';
import {
  autocompleteLegTotalConsumedRate,
  autocompleteGoLegTotalConsumedRate
} from 'captain-reports/templates/config/_autocompleteHelpers';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const LubricantLegTotalConsumed = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const captainReportTemplateType = useSelector(selectCaptainReportTemplateType);
  const mins_since_last_report = useReportTimeDiff();
  const [legTotalRate, setLegTotalRate] = useState(null);
  const [setValue] = useActions([reportActions.setValue]);
  const totalLubricants = useSelector(selectCaptainReportLubricantTotals);
  const legStartTimestamp = useSelector(selectTotalLegStartTimestamp);
  const totalSteamingTime = useSelector(selectTotalSteamingTime);
  const generatorsLegRunningHoursTotal = useSelector(selectGeneratorsLegRunningHoursTotal);

  const steamingTimeValue = useValue({ key: 'steaming_time' });
  const steamingTime =
    captainReportTemplateType === 'port_noon' ? mins_since_last_report : steamingTimeValue;

  const timestamp = useValue({ key: 'timestamp' });
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const autocompleteValue = useValue({ key: field.autocompleteValues[0].key });
  const isSoTotal = field.key === 'so_leg_total_consumption_total';
  const isGoTotal = field.key === 'go_leg_total_consumption_total';
  const debouncedAutoComplete = _debounce(() => {
    let autocompleteKey = field.autocompleteValues[0].key;

    let total = totalLubricants[`total_${autocompleteKey}`]
      ? totalLubricants[`total_${autocompleteKey}`]
      : '';
    const currentValue = autocompleteValue ? autocompleteValue : '';
    const result = currentValue + total;

    if (result !== value) {
      setValue({ key: field.key, value: result || result === 0 ? +result : null });
    }
  }, 600);

  useEffect(() => {
    debouncedAutoComplete();
  }, [autocompleteValue]);

  useEffect(() => {
    let val = null;

    if (isGoTotal) {
      val = autocompleteGoLegTotalConsumedRate({ generatorsLegRunningHoursTotal, value });
    } else {
      val = autocompleteLegTotalConsumedRate(
        {
          totals: {
            leg_start_timestamp: legStartTimestamp,
            steaming_time: totalSteamingTime
          },
          steamingTime,
          timestamp,
          value
        },
        captainReportTemplateType
      );
    }

    if (val !== 0 && val) {
      val = +val.toFixed(3);
    }

    setLegTotalRate(val);
    setValue({ key: field.lubricantRateKey, value: val });
  }, [value, steamingTime, generatorsLegRunningHoursTotal]);

  return (
    <>
      <Row className="align-items-center height-24 pt-1 flex-nowrap" noGutters>
        <Col xs={isSoTotal ? 'auto' : 6} className={`${isSoTotal ? '' : 'pe-1'}`}>
          <div className={`${isSoTotal ? '' : 'text-end'} fw-bold fs-12 lh-1`}>
            <ValidationWrapper
              field={{
                key: field.key,
                label: isSoTotal ? field.label : null,
                subLabel: isSoTotal ? field.subLabel : null
              }}
              value={value}
              labelClassName={isSoTotal ? 'fs-10 cmb-10 mt-1' : null}
              errorClassName="align-items-center"
              sectionLabel={sectionLabel}
              sectionActiveTab={sectionActiveTab}
              subGroup={subGroup}
              subGroupIndex={subGroupIndex}
              right={isSoTotal ? false : true}
            >
              {numberToStr(value)}
            </ValidationWrapper>
          </div>
        </Col>

        {legTotalRate ? (
          <Col
            xs={isSoTotal ? '' : 6}
            className={`d-flex align-items-center ${
              isSoTotal ? `mt-5` : 'ms-1'
            }  justify-content-end fs-10 fw-bold`}
          >
            {numberToStr(legTotalRate)}
            <span className="text-violet">&nbsp;L/day</span>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default LubricantLegTotalConsumed;
