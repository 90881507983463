import TYPES from './types';
import { get, post, put } from 'utils/api';

import {
  setMessages,
  postMessage,
  setUnreadMessages,
  setMarkAsRead,
  setFetching
} from 'common/components/chatbox/store/actions';

export const getCaptainReportMessages = params => dispatch => {
  dispatch({ type: TYPES.GET_CAPTAIN_REPORT_MESSAGES.START, payload: { params } });
  dispatch(setFetching(true));

  return get(`/captain-reports/${params.report_id}/messages`)
    .then(response => {
      dispatch(setMessages(response.data));
      dispatch(setFetching(false));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CAPTAIN_REPORT_MESSAGES.ERROR, payload: error });
      dispatch(setFetching(false));
      throw error;
    });
};

export const postCaptainReportMessage = params => dispatch => {
  const { report_id, ...rest } = params;

  dispatch({ type: TYPES.POST_CAPTAIN_REPORT_MESSAGE.START, payload: { params } });

  return post(`/captain-reports/${report_id}/messages`, rest)
    .then(response => {
      dispatch(postMessage(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.POST_CAPTAIN_REPORT_MESSAGE.ERROR, payload: error });
      throw error;
    });
};

export const getCaptainReportUnreadMessages = params => dispatch => {
  dispatch({ type: TYPES.GET_CAPTAIN_REPORT_UNREAD_MESSAGES.START, payload: { params } });

  return get(`/captain-reports/${params.report_id}/messages/get-unread`)
    .then(response => {
      dispatch(setUnreadMessages(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CAPTAIN_REPORT_UNREAD_MESSAGES.ERROR, payload: error });
      throw error;
    });
};

export const captainReportMarkAsRead = params => dispatch => {
  dispatch({ type: TYPES.CAPTAIN_REPORT_MARK_AS_READ.START, payload: { params } });

  return put(`/captain-reports/${params.report_id}/messages/mark-as-read`)
    .then(response => {
      dispatch(setMarkAsRead(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CAPTAIN_REPORT_MARK_AS_READ.ERROR, payload: error });
      throw error;
    });
};

export const getSharedActions = (
  {
    getCaptainReportMessages,
    postCaptainReportMessage,
    getCaptainReportUnreadMessages,
    captainReportMarkAsRead
  },
  id
) => {
  const getUnreadMessagesAction = async () => {
    if (!id) return;

    try {
      await getCaptainReportUnreadMessages({ report_id: id });
    } catch (err) {
      console.error(err);
    }
  };

  const postMessageAction = async params => {
    try {
      await postCaptainReportMessage({ report_id: id, ...params });
    } catch (error) {
      console.error(error);
    }
  };

  const getMessagesAction = async () => {
    if (!id) return;

    getCaptainReportMessages({ report_id: id });
  };

  const markAsReadAction = async () => {
    if (!id) return;

    try {
      await captainReportMarkAsRead({ report_id: id });
    } catch (error) {
      console.error(error);
    }
  };

  return { getUnreadMessagesAction, postMessageAction, getMessagesAction, markAsReadAction };
};
