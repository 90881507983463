import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModuleWrapper from 'events/event/modules/module-components/components/ModuleWrapper';
import TicketsDrawer from 'common/components/tickets/drawer';
import Loader from './Loader';
import Body from './Body';
import TicketButton from './TicketButton';

import {
  selectEventVessel,
  selectEventPortId,
  selectEventPort,
  selectEventItinerayPort,
  selectEventDateStartedAt,
  selectEventDateEndedAt,
  selectIsTemplate,
  selectIsCreate
} from 'events/store/events/selectors';

import moment from 'moment';
import _get from 'lodash/get';
import {
  selectCrewChangeData,
  selectCrewChangeIsLoading
} from 'events/store/event-modules/crew-changes/selectors';
import { getCrewChanges } from 'events/store/event-modules/crew-changes/actions';
import { clearCrewChanges } from 'events/store/event-modules/crew-changes/slice';
import { fetchListOptions } from 'store/lists/actions';

const CrewChange = () => {
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventVessel = useSelector(selectEventVessel);
  const eventDateStartedAt = useSelector(selectEventDateStartedAt);
  const eventDateEndedAt = useSelector(selectEventDateEndedAt);
  const isLoading = useSelector(selectCrewChangeIsLoading);
  const crewChangeData = useSelector(selectCrewChangeData);
  const isTemplate = useSelector(selectIsTemplate);
  const isCreate = useSelector(selectIsCreate);

  const eventPortId = useSelector(selectEventPortId);
  const eventPort = useSelector(selectEventPort);
  const eventItinerayPort = useSelector(selectEventItinerayPort);

  useEffect(() => {
    const fetchCrewSwitches = async () => {
      const params = {
        vessel_id: eventVessel?.id,
        port_id: eventPortId,
        date_of_change: eventDateEndedAt
          ? [
              moment(eventDateStartedAt).format('YYYY-MM-DD'),
              moment(eventDateEndedAt).format('YYYY-MM-DD')
            ]
          : [moment(eventDateStartedAt).format('YYYY-MM-DD')]
      };

      try {
        await dispatch(getCrewChanges(params)).unwrap();
      } catch (err) {
        console.error(err);
        dispatch(clearCrewChanges());
      }
    };

    if (eventVessel?.id && eventPortId && eventDateStartedAt) {
      fetchCrewSwitches();
    } else {
      dispatch(clearCrewChanges());
    }
  }, [dispatch, eventDateEndedAt, eventDateStartedAt, eventPortId, eventVessel?.id]);

  useEffect(() => {
    dispatch(fetchListOptions('ticketing-statuses'));
  }, [dispatch]);

  return (
    <ModuleWrapper
      className="crew-switches"
      type="crew_changes"
      infoText={
        crewChangeData?.length
          ? 'In this event we show all crew changes for this port and date (or period).'
          : null
      }
      action={
        !isTemplate &&
        !isOnBoard && (
          <div className="d-flex align-items-center">
            <div
              className={`d-flex align-items-center justify-content-center me-2 fs-12 text-violet fw-medium ${
                crewChangeData?.length ? 'border-end' : ''
              }`}
            >
              {eventPortId
                ? `${_get(eventItinerayPort, 'description') || _get(eventPort, 'name') || '-'}, `
                : ''}
              {eventDateStartedAt && eventDateEndedAt
                ? `${moment(eventDateStartedAt).format('DD/MM/YYYY')} - ${moment(
                    eventDateEndedAt
                  ).format('DD/MM/YYYY')}`
                : eventDateStartedAt
                ? moment(eventDateStartedAt).format('DD/MM/YYYY')
                : null}
            </div>
            {crewChangeData?.length ? <TicketButton /> : null}
          </div>
        )
      }
    >
      {isLoading ? <Loader /> : <Body />}

      <TicketsDrawer />
    </ModuleWrapper>
  );
};

export default CrewChange;
