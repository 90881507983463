import { FC } from 'react';

import CircledButton from '@/ts-common/components/buttons/CircledButton.tsx';
import { selectBaseCurrency } from '@/common/components/mga/store/selectors-ts';
import { MgaCurrencyType } from '@/common/types/mga.ts';
import ListSelect from '@/common/components/selectors/ListSelect';
import { Currency } from '@/common/types/enums.ts';
import { FormState as FormStateType } from '@/common/types/form';
import { useAppSelector } from '@/store/hooks';
import { useFormState } from 'utils/hooks';

type FilterType = {
  value: string;
};

type ExtraCurrencyProps = {
  onRemove: () => void;
  state: FormStateType;
  extraState: FormStateType[];
};

const ExtraCurrency: FC<ExtraCurrencyProps> = ({ state, extraState, onRemove }) => {
  const baseCurrency = useAppSelector<MgaCurrencyType>(selectBaseCurrency);

  const selectedCurrencies = extraState.map(e => ({
    id: e.state.currency.value
  }));

  const allSelectedCurrencies = [...selectedCurrencies, baseCurrency];

  const { fields, selectField } = useFormState(state);

  return (
    <div className="d-flex align-items-center mb-1">
      <ListSelect
        onChange={(e: Currency) => selectField('currency')(e)}
        getOptionLabel={(option: Currency) => option.label}
        getOptionValue={(option: Currency) => option.id}
        list="currencies"
        isAsync
        params={{ mga: false }}
        className="mb-0 min-w-140 w-100p"
        filterOption={(currency: FilterType) =>
          !allSelectedCurrencies.some(e => (e?.id?.id || e?.id) === currency?.value)
        }
        placeholder="Select currency"
        {...fields.currency}
      />

      <CircledButton
        type={'remove'}
        className="cms-10"
        size={16}
        onClick={onRemove}
        svgStyle={{ width: 8, height: 8 }}
      />
    </div>
  );
};

export default ExtraCurrency;
