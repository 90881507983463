import React from 'react';

type MessagesCountBubbleType = {
  count: number;
  className?: string;
  size?: string;
};

const MessagesCountBubble: React.FC<MessagesCountBubbleType> = ({
  count,
  className = '',
  size = 'medium'
}) => {
  return (
    <div
      className={`${
        size === 'medium' ? 'fs-10' : ''
      } chat-messages-count-bubble fw-bold text-white ${className}`}
    >
      {count > 99 ? '99+' : count}
    </div>
  );
};

export default MessagesCountBubble;
