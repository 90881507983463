import React from 'react';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { Row, Col } from 'reactstrap';
import { getValue } from '../../layout/utils';

const BallastTanks = ({ field }) => {
  const profile = useSelector(selectVesselProfile);

  const ballast_tanks = profile.ballast_tanks ? profile.ballast_tanks : [];

  const data = field.data;
  return (
    <div className="mt-2 mb-3 ps-2">
      <Row className="">
        <Col xs={12}>
          <Row>
            {data.map(el => {
              if (el.key === 'id' || el.key === 'tank_number') {
                return null;
              }
              return (
                <Col
                  className={`text-end mt-2 fs-12 text-blue fw-bold d-flex align-items-center `}
                  key={el.key}
                  xs={el.key === 'comments' ? 3 : 2}
                >
                  {el.label}
                </Col>
              );
            })}
          </Row>
          {ballast_tanks.map((tank, i) => {
            return (
              <Row key={i.toString()}>
                {data.map(field => {
                  if (field.render) {
                    return field.render();
                  }

                  return (
                    <Col
                      className={`text-end mt-1 fs-12  d-flex align-items-center `}
                      key={field.key}
                      xs={field.key === 'comments' ? 3 : 2}
                    >
                      <span>{getValue(field, tank)}</span>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default BallastTanks;
