import React from 'react';

const RiskHeader = ({ type }) => {
  if (type === 'rating') {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex fs-12">
          <strong className="text-primary">RISK RATING</strong>
          <span className="text-violet cms-4">(SxL = RR)</span>
        </div>
        <div className="d-flex text-violet fs-12">
          <strong className="me-5">S</strong>
          <strong className="me-5">L</strong>
          <strong>RR</strong>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column fs-12">
        <strong className="text-primary">RISK</strong>
        <strong className="text-violet">L / M / H</strong>
      </div>
    );
  }
};

export default RiskHeader;
