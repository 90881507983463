import React, { useState } from 'react';
import { Form } from 'reactstrap';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import FloatingLabelInput from 'common/components/form/styled-inputs/FloatingLabelInput';

import { useForm, useFormState } from 'utils/hooks';
import config from './config';

import { useActions } from 'utils/hooks';
import * as authActions from 'store/auth/actions';

const CredentialsLogin = () => {
  const [credentialslogin] = useActions([authActions.credentialslogin]);
  const [loading, setLoading] = useState(false);
  const { formState, collectValues } = useForm(config);
  const { fields, changeField } = useFormState(formState);

  const onSubmit = async e => {
    e.preventDefault();
    const values = collectValues();

    if (!values) return;

    try {
      setLoading(true);
      await credentialslogin(values);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className={`login-page__credentials`}>
      <Form className="login-page__credentials-form">
        <div className={`login-page__credentials-header fw-light`}>
          <h3 className="fs-32 mb-1">Hello!</h3>
          <h1 className="fs-16 mb-0">Login to your account</h1>
        </div>

        <div className="login-page__credentials-inputs">
          <FloatingLabelInput
            label="Username"
            placeholder="Username"
            className="mb-0"
            wrapperClassName="login-input login-input__username mb-10"
            onChange={changeField('username')}
            {...fields.username}
          />

          <FloatingLabelInput
            label="Password"
            placeholder="Password"
            className="mb-2"
            hideValidation
            wrapperClassName="login-input login-input__password mb-2"
            onChange={changeField('password')}
            type="password"
            {...fields.password}
          />
        </div>

        <div className="text-end login-page__credentials-actions">
          <BoxShadowButton color="yellow" type="submit" onClick={onSubmit} disabled={loading}>
            Login
          </BoxShadowButton>
        </div>
      </Form>
    </div>
  );
};

export default CredentialsLogin;
