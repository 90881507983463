import { FC, PropsWithChildren } from 'react';

type ErrorMessageProps = PropsWithChildren<{
  withLabel?: boolean;
}>;

const ErrorMessage: FC<ErrorMessageProps> = ({ withLabel, children }) => (
  <div className={`form-error ${withLabel ? 'form-error-with-label' : ''}`}>{children}</div>
);

export default ErrorMessage;
