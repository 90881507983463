import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';
import { selectCaptainReportTemplateName } from 'captain-reports/store/selectors';

const Reports = React.lazy(() => retry(() => import('@/views/captain-reports/index.jsx')));
const ReportForm = React.lazy(() => retry(() => import('@/views/captain-reports/form/index.jsx')));
const PortStatements = React.lazy(() =>
  retry(() => import('@/views/captain-reports/port-statement/index.jsx'))
);
const InstructedSpeed = React.lazy(() =>
  retry(() => import('@/views/captain-reports/instructed-speed/index.jsx'))
);
const SystemRunningHours = React.lazy(() =>
  retry(() => import('@/views/captain-reports/system-running-hours/index.jsx'))
);
const PortDocumentOverview = React.lazy(() =>
  retry(() => import('@/views/captain-reports/port-document-overview/index.jsx'))
);
const PassagePlan = React.lazy(() =>
  retry(() => import('@/views/captain-reports/passage-plan/index.tsx'))
);

const reportRoutes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: paths.captain_reports,
    component: Reports,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: 'Captain Reports'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/port-statement/:id`,
    component: PortStatements,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: 'Port Statement'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/port-document-overview/:id`,
    component: PortDocumentOverview,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: 'Port Document Overview'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/instructed-speed/:id`,
    component: InstructedSpeed,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: 'Instructed Speed'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/system-running-hours/:id`,
    component: SystemRunningHours,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: 'System Running Hours'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.captain_reports}/passage-plan/:id`,
    component: PassagePlan,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: 'Passage Plan'
  },
  {
    path: `${paths.captain_reports}/:id`,
    component: ReportForm,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.captain_reports.view],
    documentTitle: selectCaptainReportTemplateName
  }
];

export default reportRoutes;
