// Comments on purpose we will probably need this information
import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import paths from 'routing/routes/_paths';

import Details from 'crew/profile/main/contracts/preview/parts/Details';
import Wages from 'crew/profile/main/contracts/preview/parts/Wages';
import ArrivalsDepartures from 'crew/profile/main/contracts/preview/parts/ArrivalsDepartures';
import Remarks from 'crew/profile/main/contracts/preview/parts/Remarks';
// import ExpandedContract from 'common/components/crew/ExpandedContract';
import Duration from 'crew/profile/main/contracts/preview/parts/Duration';
import Tickets from 'crew/profile/main/contracts/preview/tickets';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';

import { selectCrewProfile } from 'crew/store/profile/selectors';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';

// import { isContractExtendedPeriodReduced } from './parts/helpers';
// import { useActions } from 'utils/hooks';

import moment from 'moment';

const CurrentContractPreview = ({ contract }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const crewProfile = useSelector(selectCrewProfile);
  const from = contract?.contract_from_date;
  const to = contract?.contract_to_date;
  const signOn = contract?.sign_on_date;
  const signOff = contract?.sign_off_date;
  const isActive = moment().isAfter(from) && moment().isBefore(to);

  return (
    <>
      <div
        className={`crew-profile__contracts-preview ${contract ? ' editing' : 'mb-2'} `}
        id={`contract-preview-${contract.id}`}
      >
        {contract.off_signer_tickets && contract.off_signer_tickets.length ? (
          <Tickets className="mb-2" tickets={contract.off_signer_tickets} />
        ) : null}
        <div
          className={`crew-profile__contracts-preview-body ${
            isActive ? 'crew-profile__contracts-preview-body--active' : ''
          } `}
        >
          {isActive && (
            <div className="crew-profile__contracts-preview-body--active_circle d-flex align-items-center justify-content-center">
              <span></span>
            </div>
          )}
          <div
            className={`crew-profile__contracts-preview-header d-flex align-items-center justify-content-between pb-1 mb-2 border-bottom fw-bold${
              !contract ? ' disabled' : ''
            }`}
          >
            <div className="d-flex align-items-center">
              {isOnBoard ? (
                <span>{contract?.vessel?.name}</span>
              ) : (
                <Link to={`${paths.vessels}/${contract?.vessel?.id}`} className={`text-primary`}>
                  {contract?.vessel?.name}
                </Link>
              )}

              {contract.vessel && contract.vessel.type ? (
                <span className="fw-normal ms-1">{contract.vessel.type.name}</span>
              ) : null}

              {contract.rank ? (
                <>
                  <div className="small-seperator text-purple"></div>
                  <span className="text-purple">{contract.rank.name}</span>
                </>
              ) : null}

              {contract.number ? (
                <>
                  <div className="small-seperator text-gray-common"></div>
                  <span className="text-gray-common">{contract.number}</span>
                </>
              ) : null}
            </div>
            <div className="text-violet d-inline-flex align-items-center">
              {/* {contract?.extensions?.length ? (
                <ExpandedContract
                  reduced={isContractExtendedPeriodReduced(to, contract.initial_contract_to_date)}
                />
              ) : null} */}
              <Duration signOn={signOn} signOff={signOff} from={from} to={to} />
            </div>
          </div>

          <Row className={`flex-nowrap ${!contract ? ' disabled' : ''}`}>
            <Col xs={3}>
              <Details contract={contract} />
            </Col>
            <Col xs={3}>
              <ArrivalsDepartures contract={contract} />
            </Col>
            <Col className="overflow-hidden d-flex flex-column">
              <Remarks contract={contract} />
              {contract.attachments_count ? (
                <div className="cpb-2 mt-2">
                  <DownloadAttachment
                    downloadFileName={`${contract.vessel.name}-${contract?.rank?.name}`}
                    data={{ attachments_count: contract.attachments_count }}
                    downloadFiles={() =>
                      downloadAllFilesOfEntity({ id: contract.id, type: 'crew_contract' })
                    }
                    // requestAttachments={async () =>
                    //   await getCrewContractAttachments({ contract_id: contract.id })
                    // }
                    hasAttachments={contract.attachments_count > 0}
                    popupPlacement="right"
                    size="lg"
                  />
                </div>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="crew-profile__contracts-preview__wages-container">
              <Wages contract={contract} crewProfile={crewProfile} isActive={isActive} />
            </Col>
          </Row>
        </div>
        {contract.on_signer_tickets && contract.on_signer_tickets.length ? (
          <Tickets className="mt-2 mb-7" tickets={contract.on_signer_tickets} />
        ) : null}
      </div>
    </>
  );
};

export default CurrentContractPreview;
