import { useEffect } from 'react';
import numeral from 'numeral';
import { strToNumber } from 'common/utils/numbers';

import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import SvgRender from 'common/components/general/SvgRender';
import * as reportActions from 'captain-reports/store/actions';

import arrow from 'captain-reports/assets/svg/weather-arrow.svg';

const RelativeWeatherField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  tooltip
}) => {
  const value = useValue({ key: field.key });

  const weatherDir = useValue({ key: field.directionKey });
  const shipDir = useValue({ key: 'course_og' });

  const [setValue] = useActions([reportActions.setValue]);

  const relativeColor = () => {
    switch (field.key) {
      case 'wind_relative_direction':
        return 'text-primary';
      case 'sea_relative_direction':
        return 'text-turquoise';
      case 'swell_relative_direction':
        return 'text-warning';
      case 'ocean_current_relative_direction':
        return 'text-yellow';
      default:
        return '';
    }
  };

  useEffect(() => {
    let calculation = null;

    if ((weatherDir || weatherDir === 0) && (shipDir || shipDir === 0)) {
      const number = numeral(weatherDir);
      calculation =
        Math.abs(
          number
            .add(360)
            .subtract(strToNumber(shipDir) || 0)
            ?.value()
        ) % 360;
    } else {
      calculation = null;
    }

    setValue({
      key: field.key,
      value: calculation,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  }, [shipDir, weatherDir]);

  const isReverseDirection =
    field.key === 'sea_relative_direction' ||
    field.key === 'swell_relative_direction' ||
    field.key === 'wind_relative_direction';

  const getRelativeArrowPosition = () => {
    const position = value;
    const offset = isReverseDirection ? 270 : 90;

    return position + offset;
  };

  return (
    <div>
      <FieldLabel
        info={tooltip}
        className="form-label fw-bold text-uppercase mb-0"
        label={field.label}
      />

      <div className={`d-flex align-items-center height-24`}>
        <div className="d-flex align-items-center cmt-10">
          {value || value === 0 ? (
            <div className="d-flex align-items-center">
              <div className="fs-12 fw-bold text-dark min-width-20">{value}</div>

              <div className="fs-10 fw-bold text-violet cms-4">{field.inputProps.subLabel}</div>
            </div>
          ) : null}
        </div>

        <div className="w-100p cmt-10">
          {value || value === 0 ? (
            <SvgRender
              className={`${relativeColor()} ms-1`}
              src={arrow}
              style={{
                height: 15,
                width: 15,
                transformOrigin: 'center',
                transform: `rotate(${getRelativeArrowPosition()}deg)`
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RelativeWeatherField;
