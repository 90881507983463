import { createAsyncThunk } from '@reduxjs/toolkit';
import { successHandler } from 'common/utils/notifications';
import { get, post, deleteRequest, put } from 'utils/api';

export const getVesselAttachments = createAsyncThunk(
  'GET_VESSEL_ATTACHMENTS',
  async (params, { rejectWithValue }) => {
    const { event_id } = params;

    try {
      const res = await get(`/events/${event_id}/vessel-attachments`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createVesselAttachments = createAsyncThunk(
  'CREATE_VESSEL_ATTACHMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/vessel-attachments/group`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateVesselAttachments = createAsyncThunk(
  'UPDATE_VESSEL_ATTACHMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, attachment_group_id, ...rest } = params;

    try {
      const res = await put(
        `/events/${event_id}/vessel-attachments/group/${attachment_group_id}`,
        rest
      );
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteVesselAttachments = createAsyncThunk(
  'DELETE_VESSEL_ATTACHMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, attachment_group_id } = params;

    try {
      const res = await deleteRequest(
        `/events/${event_id}/vessel-attachments/group/${attachment_group_id}`
      );
      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
