import React from 'react';

import _isEqual from 'lodash/isEqual';

import Tooltip from 'common/components/general/Tooltip';

const MiniShiftTypeSelector = ({ shiftTypes, selectedShiftType, setSelectedShiftType }) => {
  return shiftTypes.map(shiftType => {
    const id = `mini-shift-type--${shiftType.id}`;

    return (
      <div
        key={id}
        id={id}
        onClick={() =>
          _isEqual(selectedShiftType, shiftType)
            ? setSelectedShiftType({})
            : setSelectedShiftType(shiftType)
        }
        className={`shifts-type-selector--mini-shift-type shifts-type-selector--mini-shift-type__${
          shiftType.color
        }${
          selectedShiftType.id === shiftType.id
            ? ` shifts-type-selector--mini-shift-type__selected`
            : ''
        }`}
      >
        <Tooltip target={id}>{shiftType.name}</Tooltip>
        <div className="shifts-type-selector--dot" />
      </div>
    );
  });
};

export default MiniShiftTypeSelector;
