import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

const useTooltipID = (tooltipIDText = 'tooltip', deps = []) => {
  const [ID, setID] = useState<string>('');

  const resetTooltip = useCallback(async () => {
    if (ID) setID('');
    const newID = `${tooltipIDText}--${uuid()}`;
    setID(newID);
  }, [ID, tooltipIDText]);

  useEffect(() => {
    resetTooltip();
  }, deps);

  return { avoidRender: !ID, tooltipID: ID, resetTooltip };
};

export default useTooltipID;
