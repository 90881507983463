import React from 'react';
import CollapseColoredBox from 'common/components/collapse/CollapseColoredBox';
import CircledButton from 'common/components/buttons/CircledButton';

const CollapseForm = ({
  header,
  color = 'violet',
  background = 'gray',
  children,
  isEditing,
  setIsEditing,
  headerClassName,
  disabled,
  onSave,
  onRemove,
  onClose,
  triggerOpen,
  disableCollapse,
  renderHeader,
  reversedExpandBtn,
  hideActions = false,
  hideSave,
  hideEdit,
  onExpandCallback,
  circledButtonProps = {},
  ...rest
}) => {
  return (
    <div className={`collapse-form ${isEditing ? 'is-editting' : ''}`}>
      <CollapseColoredBox
        renderHeader={renderHeader}
        header={header}
        color={color}
        background={background}
        reversedExpandBtn={reversedExpandBtn}
        headerClassName={`fw-normal fs-14 ${headerClassName || ''}`}
        expandButton={true}
        disableCollapse={disableCollapse}
        triggerOpen={isEditing ? null : triggerOpen}
        isDefaultOpened={isEditing ? true : undefined}
        onExpandCallback={onExpandCallback}
        {...rest}
      >
        {children}
      </CollapseColoredBox>
      {hideActions ? null : (
        <div className="collapse-form__actions">
          {isEditing ? (
            <>
              <CircledButton
                type="close"
                disabled={disabled}
                onClick={() => {
                  setIsEditing(false);
                  if (onClose) onClose();
                }}
                svgStyle={{ width: 10, height: 10 }}
                {...circledButtonProps}
              />
              {onRemove ? (
                <CircledButton
                  type="remove"
                  disabled={disabled}
                  onClick={onRemove}
                  svgStyle={{ width: 10, height: 10 }}
                  className="cms-6"
                  {...circledButtonProps}
                />
              ) : null}
              {!hideSave ? (
                <CircledButton
                  type="save"
                  disabled={disabled}
                  onClick={onSave}
                  className="cms-6"
                  svgStyle={{ width: 10, height: 10 }}
                  {...circledButtonProps}
                />
              ) : null}
            </>
          ) : (
            !hideEdit && (
              <CircledButton
                type="edit"
                onClick={() => setIsEditing(true)}
                svgStyle={{ width: 12, height: 12 }}
                style={{ width: 24, height: 24 }}
                className="collapse-form__edit"
                {...circledButtonProps}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default CollapseForm;
