import _get from 'lodash/get';
import { extension } from 'react-native-mime-types';
import { Attachment, FileSyncState } from '@/ts-common/types/files.ts';

import excelBordered from '@/ts-common/assets/svg/files/bordered/excel.svg';
import pdfBordered from '@/ts-common/assets/svg/files/bordered/pdf.svg';
import emlBordered from '@/ts-common/assets/svg/files/bordered/eml.svg';
import videoBordered from '@/ts-common/assets/svg/files/bordered/video.svg';
import docBordered from '@/ts-common/assets/svg/files/bordered/doc.svg';
import imgBordered from '@/ts-common/assets/svg/files/bordered/img.svg';
import presentationBordered from '@/ts-common/assets/svg/files/bordered/presentation.svg';
import textBordered from '@/ts-common/assets/svg/files/bordered/txt.svg';
import audioBordered from '@/ts-common/assets/svg/files/bordered/audio.svg';
import zipBordered from '@/ts-common/assets/svg/files/bordered/zip.svg';
import unknownBordered from '@/ts-common/assets/svg/files/bordered/unknown.svg';

import archive from '@/ts-common/assets/svg/files/archive.svg';
import audio from '@/ts-common/assets/svg/files/audio.svg';
import doc from '@/ts-common/assets/svg/files/doc.svg';
import illustrator from '@/ts-common/assets/svg/files/illustrator.svg';
import img from '@/ts-common/assets/svg/files/img.svg';
import pdf from '@/ts-common/assets/svg/files/pdf.svg';
import eml from '@/ts-common/assets/svg/files/eml.svg';
import photoshop from '@/ts-common/assets/svg/files/photoshop.svg';
import numbers from '@/ts-common/assets/svg/files/numbers.svg';
import pages from '@/ts-common/assets/svg/files/pages.svg';
import presentation from '@/ts-common/assets/svg/files/presentation.svg';
import sketch from '@/ts-common/assets/svg/files/sketch.svg';
import spreadsheets from '@/ts-common/assets/svg/files/spreadsheets.svg';
import txt from '@/ts-common/assets/svg/files/txt.svg';
import unknown from '@/ts-common/assets/svg/files/unknown.svg';
import video from '@/ts-common/assets/svg/files/video.svg';
import xd from '@/ts-common/assets/svg/files/xd.svg';

interface FileTypeData {
  type: string;
  path: string;
}

export const borderedFilesTypes = {
  excel: 'excel',
  pdf: 'pdf',
  video: 'video',
  doc: 'doc',
  eml: 'eml',
  img: 'img',
  presentation: 'presentation',
  text: 'text',
  audio: 'audio',
  zip: 'zip',
  unknown: 'unknown'
} as const;

export const borderedFiles: Record<string, string> = {
  excel: excelBordered,
  pdf: pdfBordered,
  eml: emlBordered,
  video: videoBordered,
  doc: docBordered,
  img: imgBordered,
  presentation: presentationBordered,
  text: textBordered,
  audio: audioBordered,
  zip: zipBordered,
  unknown: unknownBordered
};

const types: Record<string, FileTypeData> = {
  archive: { type: 'archive', path: archive },
  audio: { type: 'audio', path: audio },
  doc: { type: 'doc', path: doc },
  illustrator: { type: 'illustrator', path: illustrator },
  img: { type: 'image', path: img },
  pdf: { type: 'pdf', path: pdf },
  eml: { type: 'eml', path: eml },
  photoshop: { type: 'photoshop', path: photoshop },
  numbers: { type: 'numbers', path: numbers },
  pages: { type: 'pages', path: pages },
  presentation: { type: 'presentation', path: presentation },
  sketch: { type: 'sketch', path: sketch },
  spreadsheets: { type: 'spreadsheets', path: spreadsheets },
  txt: { type: 'txt', path: txt },
  unknown: { type: 'unknown', path: unknown },
  video: { type: 'video', path: video },
  xd: { type: 'xd', path: xd }
};

export const getFileType = (mimeType?: string | null, extension?: string): FileTypeData => {
  let type = 'unknown';
  let pref = null;

  extension = (extension || '').toLowerCase();

  if (mimeType) pref = mimeType.match(/^(.*)\//);
  else return types[type];

  if (extension) {
    switch (extension) {
      case 'svg':
        type = 'img';
        break;

      case 'pdf':
        type = 'pdf';
        break;

      case 'eml':
        type = 'eml';
        break;

      case 'doc':
      case 'docm':
      case 'docx':
        type = 'doc';
        break;

      case 'odt':
      case 'ods':
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
      case 'xltx':
      case 'xltm':
      case 'xlml':
      case 'xlt':
      case 'dbf':
      case 'dif':
      case 'csv':
        type = 'spreadsheets';
        break;

      case 'numbers':
        type = 'numbers';
        break;

      case 'pages':
        type = 'pages';
        break;

      case 'odp':
      case 'pps':
      case 'ppsm':
      case 'ppsx':
      case 'ppt':
      case 'pptm':
      case 'pptx':
        type = 'presentation';
        break;

      case 'prn':
      case 'txt':
        type = 'txt';
        break;

      case '3gp':
      case '3gpp':
      case '3gpp2':
      case 'asf':
      case 'avi':
      case 'dv':
      case 'flv':
      case 'm2t':
      case 'm4v':
      case 'mkv':
      case 'mov':
      case 'mp4':
      case 'mpeg':
      case 'mpg':
      case 'mts':
      case 'oggtheora':
      case 'ogv':
      case 'rm':
      case 'ts':
      case 'vob':
      case 'webm':
      case 'wmv':
        type = 'video';
        break;

      case 'aac':
      case 'm4a':
      case 'mp3':
      case 'oga':
      case 'wav':
        type = 'audio';
        break;

      case 'sketch':
        type = 'sketch';
        break;

      case 'xd':
        type = 'xd';
        break;

      case 'ai':
        type = 'illustrator';
        break;

      case 'url':
      case 'webloc':
      case 'website':
        type = 'unknown';
        break;

      default:
        type = 'unknown';
    }
  }

  if (pref && pref[0] && type == 'unknown') {
    switch (pref[0]) {
      case 'image/':
        switch (mimeType) {
          case 'image/vnd.adobe.photoshop':
          case 'image/psd':
            type = 'photoshop';
            break;
          default:
            type = 'img';
            break;
        }

        break;

      case 'video/':
        type = 'video';
        break;

      case 'audio/':
        type = 'audio';
        break;

      case 'application/':
        switch (mimeType) {
          case 'application/x-photoshop':
          case 'application/photoshop':
          case 'application/psd':
            type = 'photoshop';
            break;

          case 'application/pdf':
            type = 'pdf';
            break;

          case 'application/octet-stream':
          case 'application/x-bzip':
          case 'application/x-bzip2':
          case 'application/java-archive':
          case 'application/x-rar-compressed':
          case 'application/x-tar':
          case 'application/zip':
          case 'application/x-7z-compressed':
            type = 'archive';
            break;

          default:
            type = 'unknown';
            break;
        }

        break;
      case 'text/':
        type = 'txt';
        break;

      default:
        type = 'unknown';
        break;
    }
  }

  return types[type];
};

export const icon = (mime_type?: string | null, extension?: string): string => {
  return getFileType(mime_type, extension).path;
};

export const fileSize = (bytes: number | null | undefined): string => {
  if (!bytes) return '-';

  if (!bytes) return '-';

  const thresh = 1024;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = 0;
  let fileSize = bytes;

  while (fileSize >= thresh && u < units.length - 1) {
    fileSize /= thresh;
    ++u;
  }

  return fileSize.toFixed(1) + ' ' + units[u];
};

export const getResponseContentTypeExtension = (contentType: string): string | false => {
  return extension(contentType);
};

export const getFileSyncState = (file: Attachment, isOnboard = false): FileSyncState => {
  const isSynced = _get(file, 'is_synced', false);
  const syncState = _get(file, 'sync_states[0].state');
  const syncProgress = isOnboard
    ? _get(file, 'sync_states[0].progress', 0)
    : _get(file, 'onboard_sync_progress', 0);
  const vesselId = isOnboard
    ? _get(file, 'sync_states[0].vessel_id')
    : _get(file, 'vessel_id', null);

  if (!isOnboard) {
    /* Office Case */
    if (isSynced === false && vesselId !== null) {
      // SHOW TOOLTIP
      return { is_synced: false, sync_progress: syncProgress, is_onboard: isOnboard };
    }
  } else {
    /* Onboard Case */
    if (vesselId && syncState !== 'synced') {
      // SHOW TOOLTIP
      return { is_synced: false, sync_progress: syncProgress, is_onboard: isOnboard };
    }
  }

  return { is_synced: true, sync_progress: null, is_onboard: isOnboard };
};
