import React from 'react';
import ShadowBox from 'common/components/general/ShadowBox';

const ShadowContainer = ({ children, className }) => {
  return (
    <div>
      <ShadowBox className={`${className ? className : ''}`}>{children}</ShadowBox>
    </div>
  );
};

export default ShadowContainer;
