import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';

import WorkingSchedule from 'views/working-schedule';

const routes = [
  {
    type: 'private',
    exact: true,
    path: paths.working_schedule,
    app: true,
    component: WorkingSchedule,
    permissions: [permissions.onboard.crew.watchkeeping.working_schedule.view],
    documentTitle: 'Watchkeeping / Working Schedule'
  }
];

export default routes;
