import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button } from 'reactstrap';
import Select from 'common/components/form/inputs/Select';
import BasicModal from 'common/components/modals/BasicModal';

import useRouter from 'use-react-router';

import {
  selectManualVersions,
  selectCurrentVersion,
  selectComparedAtVersion
} from 'manuals/store/selectors';

import { getColor } from './helpers';

const VersionModal = ({ isModalTypeOpen, modalHandler }) => {
  const [selectedVersion, setSelectedVersion] = useState({ value: null });

  const { history, location } = useRouter();

  const currentVersion = useSelector(selectCurrentVersion);
  const versions = useSelector(selectManualVersions);
  const comparedAtVersion = useSelector(selectComparedAtVersion);

  const options = versions
    ?.filter(
      e =>
        e.id !== currentVersion?.id &&
        e.valid_from &&
        moment(e.valid_until).isBefore(currentVersion?.valid_from)
    )
    .map(e => ({
      value: e.id,
      label: `${moment(e.valid_from).format('DD/MM/YYYY')}`,
      title: e.title,
      status: e.status
    }));

  useEffect(() => {
    if (isModalTypeOpen) setSelectedVersion(comparedAtVersion);
  }, [isModalTypeOpen]);

  const submit = async () => {
    history.push({
      pathname: location.pathname,
      search: location.search.includes('version=')
        ? `version=${currentVersion?.id}&compared=${selectedVersion?.id}`
        : `compared=${selectedVersion?.id}`
    });

    modalHandler();
  };

  const changeHandler = selected => {
    const version = versions?.find(e => e.id === selected);
    setSelectedVersion(version);
  };

  return (
    <BasicModal
      isOpen={isModalTypeOpen}
      toggle={modalHandler}
      className="since-changes-modal"
      header={'See changes since'}
      body={
        <Select
          autoFocus
          label="Version"
          className={`form-field react-select position-relative mb-0`}
          placeholder="Select type"
          isCreatable={false}
          getOptionLabel={option => (
            <div className="d-flex align-items-center fs-14">
              <div>{option.label}</div>
              {option.title ? <div className="fw-bold text-violet cms-6  cme-6 ">-</div> : null}
              <div className={`fw-bold text-${getColor(option.status)}`}>
                {option.title ? `${option.title}` : ''}
              </div>
            </div>
          )}
          options={options}
          onChange={changeHandler}
          value={selectedVersion?.id}
        />
      }
      footer={
        <div className="mt-0 me-0 d-flex align-items-center">
          <Button color="cancel" onClick={modalHandler}>
            CANCEL
          </Button>
          <Button
            className="since-changes-modal__apply-btn"
            onClick={() => submit()}
            color="primary"
          >
            APPLY
          </Button>
        </div>
      }
    />
  );
};
export default VersionModal;
