import SvgRender from 'common/components/general/SvgRender';
import vessel from 'common/assets/svg/common/vessels.svg';

const VesselGroupLabel = ({ vesselGroup }) => {
  return (
    <div className="bg-lighter-blue text-purple border-radius-3 d-inline-flex align-items-center cpt-4 cpb-4 px-1 me-1 mb-1">
      <SvgRender src={vessel} style={{ width: 14, height: 14 }} className="me-1" />
      <span className="fs-12">{vesselGroup?.name}</span>
    </div>
  );
};

export default VesselGroupLabel;
