import React, { useMemo } from 'react';

import StyledSelect from 'common/components/form/styled-inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import {
  Option,
  SingleValue
} from 'common/components/jobs/_base/modules/forms/components/_jobSelectorComponents';
import {
  selectJobType,
  selectJobPrefilledVessel
} from 'common/components/jobs/_base/store/selectors';
import {
  selectSelectedVesselFilter,
  selectSelectedSystemFilter
} from 'common/components/pms/jobs/store/selectors';
import { jobEnums } from 'common/utils/fixed';
import { useSelector } from 'react-redux';

const JobSelector = ({ selectedJob, onJobChange, label, invisible }) => {
  const { regular, pms } = jobEnums;
  const jobType = useSelector(selectJobType) || regular;
  const prefilledVessel = useSelector(selectJobPrefilledVessel);

  const selectedVessels = useSelector(selectSelectedVesselFilter);
  const selectedSystem = useSelector(selectSelectedSystemFilter);

  const jobSelectorFilterParams = useMemo(() => {
    const params = {};

    if (jobType) {
      params.type = jobType;
    }
    if (jobType === pms) {
      params.exclude_status_progresses = ['done'];
    }
    if (selectedVessels?.length) {
      params.vessel_ids = selectedVessels.map(v => v.id);
    }
    if (selectedSystem) {
      params.vessel_system_assignment_ids = [selectedSystem.id];
    }

    if (prefilledVessel) {
      params.vessel_ids = [prefilledVessel?.id];
    }
    return params;
  }, [jobType, pms, selectedVessels, selectedSystem, prefilledVessel]);

  return (
    <StyledSelect
      isAsync
      defaultOptions={async () =>
        await getInitialAsyncValues('jobs', null, false, jobSelectorFilterParams)
      }
      loadOptions={search => getAsyncOptions(search, 'jobs', jobSelectorFilterParams)}
      value={selectedJob}
      showLabel
      onChange={onJobChange}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      className="flex-1 mb-0 fs-12 jobs-selector"
      placeholder="Select job"
      defaultOptionsTriggerChange={jobSelectorFilterParams}
      components={{ Option, SingleValue }}
      label={label}
      invisible={invisible}
    />
  );
};

export default JobSelector;
