import React, { useState } from 'react';
import { Selectors } from 'common/entities/selectors/SelectorTypes';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const EventFindingsSelector = ({
  value,
  filter,
  onChange,
  styled,
  isMulti,
  listParams,
  ...rest
}) => {
  const [selectedFindings, setSelectedFindings] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'event-audit-findings', listParams },
    { selected: selectedFindings, setSelected: setSelectedFindings }
  );

  return (
    <Select
      placeholder="Select value"
      autoFocus
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      size="sm"
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selectedFindings, filter?.value)
          : []
      }
      defaultOptions={() => getInitialAsyncValues('event-audit-findings', null, null, listParams)}
      loadOptions={search => getAsyncOptions(search, 'event-audit-findings', listParams)}
      onChange={selected => {
        setSelectedFindings(selected);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      isClearable
      {...rest}
    />
  );
};

EventFindingsSelector.propTypes = Selectors;

export default EventFindingsSelector;
