import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { getCrewProfile } from 'crew/store/profile/actions';

export const createCrewEvaluation = params => async dispatch => {
  const { id, ...rest } = params;
  try {
    dispatch({ type: TYPES.CREATE_CREW_EVALUATION.START, payload: { rest } });

    const response = await post(`/crew/${id}/evaluation`, rest);
    dispatch({ type: TYPES.CREATE_CREW_EVALUATION.SUCCESS, payload: response.data });
    dispatch(getCrewProfile({ id }));
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.CREATE_CREW_EVALUATION.ERROR, payload: error });
    throw error;
  }
};

export const getCrewEvaluations = params => async dispatch => {
  const { id, ...rest } = params;
  try {
    dispatch({ type: TYPES.GET_CREW_EVALUATIONS.START, payload: { rest } });

    const response = await get(`/crew/${id}/evaluation`, rest);
    dispatch({ type: TYPES.GET_CREW_EVALUATIONS.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.GET_CREW_EVALUATIONS.ERROR, payload: error });
  }
};

export const getCrewEvaluationStatistics = params => async dispatch => {
  const { id, ...rest } = params;
  try {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_STATISTICS.START, payload: { rest } });

    const response = await get(`/crew/${id}/statistics`, rest);
    dispatch({ type: TYPES.GET_CREW_EVALUATION_STATISTICS.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_STATISTICS.ERROR, payload: error });
  }
};

export const getCrewEvaluation = params => async dispatch => {
  const { evaluationID, ...rest } = params;
  try {
    dispatch({ type: TYPES.GET_CREW_EVALUATION.START, payload: { rest } });

    const response = await get(`/crew-evaluation/${evaluationID}`, rest);
    dispatch({ type: TYPES.GET_CREW_EVALUATION.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.GET_CREW_EVALUATION.ERROR, payload: error });
    throw error;
  }
};

export const deleteCrewEvaluation = params => async dispatch => {
  const { id } = params;

  try {
    dispatch({ type: TYPES.DELETE_CREW_EVALUATION.START, payload: { id } });
    await deleteRequest(`/crew-evaluation/${id}`);
    dispatch({ type: TYPES.DELETE_CREW_EVALUATION.SUCCESS, payload: { id } });
  } catch (error) {
    dispatch({ type: TYPES.DELETE_CREW_EVALUATION.ERROR, payload: error });
  }
};

export const updateCrewEvaluation = params => async dispatch => {
  const { id, ...rest } = params;
  try {
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION.START, payload: { rest } });

    const response = await put(`/crew-evaluation/${id}`, rest);
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION.ERROR, payload: error });
    throw error;
  }
};

export const getResultPercentages = params => async dispatch => {
  const { id, ...rest } = params;
  try {
    dispatch({ type: TYPES.GET_RESULTS_PERCENTAGES.START, payload: { rest } });

    const response = await get(`/crew-evaluation/${id}/average-details`, rest);
    dispatch({ type: TYPES.GET_RESULTS_PERCENTAGES.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.GET_RESULTS_PERCENTAGES.ERROR, payload: error });
  }
};

export const updateCrewEvaluationAverageScore = params => async dispatch => {
  const { id, averageScore, ...rest } = params;

  // If value is already provided by the payload just change state and return. We use this to reset the average score
  if (averageScore || averageScore === null) {
    dispatch({
      type: TYPES.UPDATE_CREW_EVALUATION_AVERAGE_RATING.SUCCESS,
      payload: { average_score: averageScore }
    });
    return;
  }

  try {
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION_AVERAGE_RATING.START, payload: { rest } });

    const response = await put(`/crew-evaluation/average`, rest);
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION_AVERAGE_RATING.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION_AVERAGE_RATING.ERROR, payload: error });
    throw error;
  }
};

export const consentEvaluation = params => async dispatch => {
  const { id, ...rest } = params;

  try {
    dispatch({ type: TYPES.CONSENT_EVALUATION.START, payload: rest });

    const response = await put(`/crew-evaluation/${id}/consent`, rest);
    dispatch({ type: TYPES.CONSENT_EVALUATION.SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.CONSENT_EVALUATION.ERROR, payload: error });
    throw error;
  }
};

export const setEvaluationEditing = isEditing => dispatch => {
  dispatch({ type: TYPES.SET_IS_EDITING, payload: isEditing });
};

export const clearActiveCrewEvaluation = () => dispatch => {
  dispatch({ type: TYPES.CLEAR_ACTIVE_CREW_EVALUATION });
};
