import React from 'react';
import { Row, Col } from 'reactstrap';
import { timeZoneOptions } from 'common/utils/fixed';
import Utc from './Utc';
import DateInput from 'common/components/form/inputs/date';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import Select from 'common/components/form/inputs/Select';
import * as runningHoursActions from 'captain-reports/store/system-running-hours/actions';
import { useActions } from 'utils/hooks';
import {
  selectData,
  selectErrors,
  isReportLocked,
  selectIsEditting
} from 'captain-reports/store/system-running-hours/selectors';
import { useSelector } from 'react-redux';
import moment from 'moment';

const MainInputs = () => {
  const [setDataValue, setErrorValue] = useActions([
    runningHoursActions.setDataValue,
    runningHoursActions.setErrorValue
  ]);
  const errors = useSelector(selectErrors);
  const isLocked = useSelector(isReportLocked);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isEditting = useSelector(selectIsEditting);

  const data = useSelector(selectData);

  const onDateChange = d => {
    if (!d) return;

    const formatedDate = d.format('YYYY-MM-DD HH:mm:ss');
    setDataValue({ local_timestamp: d.format('YYYY-MM-DD HH:mm:ss') });
    setErrorValue({ local_timestamp: '' });
    setGmtDate(formatedDate, data.timezone);
  };

  const onTimezoneChange = v => {
    setDataValue({ timezone: v });
    setErrorValue({ timezone: '' });
    setGmtDate(data.local_timestamp, +v);
  };

  const setGmtDate = (d, t) => {
    if (d && (t || t === 0)) {
      const val = moment(d);
      const gmtValue = val.subtract(t, 'hours');

      const formattedGmtValue = gmtValue.format('YYYY-MM-DD HH:mm:ss');
      setDataValue({ timestamp: formattedGmtValue });
    }
  };

  return (
    <Row className="template-field">
      <Col xs={'auto'}>
        <div className="date-time-input form-group mt-0">
          <div className={`form-label ${isOnBoard ? 'text-primary' : 'text-dark'}`}>
            <FieldLabel label="DATE & TIME" />
          </div>

          <div className="d-flex align-items-center position-relative">
            <DateInput
              value={data?.local_timestamp}
              hasTime
              showTime
              className={`mb-0`}
              onChange={onDateChange}
              disabled={isLocked && !isEditting}
              error={errors?.local_timestamp}
            />
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <div className="select-input">
          <div className={`form-label ${isOnBoard ? 'text-primary' : 'text-dark'} `}>
            <FieldLabel label="TIMEZONE" />
          </div>
          <Select
            placeholder=""
            value={data?.timezone}
            options={timeZoneOptions}
            onChange={onTimezoneChange}
            isDisabled={isLocked && !isEditting}
            error={errors?.timezone}
            className="mb-0 position-relative system-running-hours-timezone"
          />
        </div>
      </Col>
      <Col xs={3} className="d-flex align-items-center">
        <Utc />
      </Col>
    </Row>
  );
};

export default MainInputs;
