const LockedInputFallback = ({ label, children }) => {
  return (
    <div className="form-group form-group-spacing invisible-input">
      <div className="form-label">{label}</div>
      <div
        className={`form-control form-field height-inherit ${
          !children?.length ? 'text-inactive' : ''
        }`}
      >
        {!children?.length ? 'None' : children}
      </div>
    </div>
  );
};

export default LockedInputFallback;
