import _map from 'lodash/map';
import _get from 'lodash/get';
import { errorHandler } from 'common/utils/notifications';
import { capitalizeFirst } from '@/common/utils/texts';

export const humanizeErrorMessage = message => {
  const errorMessageCapitalized = message ? capitalizeFirst(message) : null;
  const parseMessage = errorMessageCapitalized?.split('_')?.join(' ') || '';

  return parseMessage;
};

export const getErrorMessage = error => {
  console.log(error);
  if (error?.status === 403 || error?.response?.status === 403) {
    return '';
  } else if (error && error.status && error.status === 422 && error.data.data) {
    return error?.data?.data?.length
      ? getValidationMessage(error.data.data)
      : _get(error, 'data.message', '');
  } else if (error?.status === 400 && error?.data?.data?.request && error?.data?.data?.response) {
    const errorMessage = _get(error, 'data.data.response.error');

    return humanizeErrorMessage(errorMessage);
  } else if (error && error.data && error.data.message) {
    return error.data.message;
  } else if (error && error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else {
    return error?.message || 'An error has occured';
  }
};

const getValidationMessage = details => _map(details, value => value[0]).join('\n');

const mustShowErrorMessage = error => {
  if (!error) {
    return false;
  }

  const ignoreMessages = [
    'jwt.expired',
    'jwt.invalid',
    'token_invalid',
    'token_expired',
    'token_not_provided',
    'apparatus_user_not_found'
  ];

  const r =
    _get(error, 'data.error', '') ||
    _get(error, 'error.data.error', '') ||
    _get(error, 'error.data.code', '') ||
    _get(error, 'data.code', '') ||
    '';

  if (_get(error, 'error.message', '') === 'canceled_request') {
    return false;
  } else if (r === 'unauthenticated') {
    return false;
  } else if (ignoreMessages.includes(r)) {
    return false;
  } else {
    return true;
  }
};

const notificationsMiddleware = store => next => action => {
  next(action);
  const { type, payload } = action;

  if (
    (type.endsWith('ERROR') || type.endsWith('rejected')) &&
    mustShowErrorMessage(payload, store)
  ) {
    const message = getErrorMessage(payload);
    if (message) {
      store.dispatch(errorHandler({ title: 'Uh oh!', message }));
    }
  }
};
export default notificationsMiddleware;
