import React from 'react';
import PropTypes from 'prop-types';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';

const AsyncSelector = ({
  label,
  onChange,
  loadOptions,
  defaultOptions,
  value,
  error,
  type,
  styled,
  gray,
  defaultOptionsTriggerChange,
  listParams = {},
  dataCy,
  icon,
  ...rest
}) => {
  const SelectTag = icon ? IconSelect : styled ? StyledSelect : Select;

  return (
    <SelectTag
      label={label}
      showLabel={styled && label ? true : null}
      dataCy={dataCy}
      onChange={onChange}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      loadOptions={loadOptions ? loadOptions : search => getAsyncOptions(search, type, listParams)}
      defaultOptions={
        defaultOptions
          ? defaultOptions
          : () =>
              getInitialAsyncValues(type, null, null, {
                defaultOptionsTriggerChange,
                ...listParams
              })
      }
      defaultOptionsTriggerChange={defaultOptionsTriggerChange}
      value={value}
      error={error}
      icon={icon}
      placeholder=""
      isAsync
      isClearable
      {...rest}
    />
  );
};

AsyncSelector.propTypes = {
  type: PropTypes.string, // the listing type
  styled: PropTypes.bool // whether to render a styled select or not
};

export default AsyncSelector;
