import React from 'react';

import { Row, Col } from 'reactstrap';

import CircledButton from 'common/components/buttons/CircledButton';
import CrewMemberScheduleShiftContainer from './ShiftContainer';

import _get from 'lodash/get';
import { numberToStr } from 'common/utils/numbers';

const CrewMemberSchedule = ({ crewMember, onEdit, onDelete, isViewOnly, oldSelectedSchedule }) => {
  const atSeaWatchKeepingShifts = _get(crewMember, 'shifts.atSea.watchkeeping') || [];
  const atSeaWatchNonKeepingShifts = _get(crewMember, 'shifts.atSea.nonWatchkeeping') || [];

  const atPortWatchKeepingShifts = _get(crewMember, 'shifts.atPort.watchkeeping') || [];
  const atPortNonWatchKeepingShifts = _get(crewMember, 'shifts.atPort.nonWatchkeeping') || [];

  const maxNumberOfShifts = Math.max(
    ...[
      atSeaWatchKeepingShifts.length,
      atSeaWatchNonKeepingShifts.length,
      atPortWatchKeepingShifts.length,
      atPortNonWatchKeepingShifts.length
    ]
  );

  return (
    <div className="working-schedule__crew--container">
      <Row noGutters className="working-schedule__crew">
        <Col xs={4} className="working-schedule__crew--bg">
          <Row noGutters className="h-100p fs-12 lh-15 fw-bold">
            <Col xs={6} className="d-flex align-items-center">
              <span
                className={`ms-2 ${
                  isViewOnly || crewMember.onboard ? 'text-moody-blue' : 'text-coral'
                }`}
              >
                {_get(crewMember, 'seaman.schedule_contract.rank.name')}
              </span>
            </Col>
            <Col xs={6} className="d-flex align-items-center">
              <span
                className={`working-schedule__crew--name ${
                  isViewOnly || crewMember.onboard ? 'text-primary' : 'text-coral'
                }`}
              >
                {_get(crewMember, 'seaman.full_name')}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={2} className="ps-1">
          <div className="working-schedule__crew--card--container">
            <div className="working-schedule__crew--card d-flex align-items-center w-100p">
              <CrewMemberScheduleShiftContainer
                watchKeepingShifts={atSeaWatchKeepingShifts}
                nonWatchKeepingShifts={atSeaWatchNonKeepingShifts}
                numberOfShifts={maxNumberOfShifts}
              />
            </div>
          </div>
        </Col>
        <Col xs={2} className="ps-1">
          <div className="working-schedule__crew--card--container">
            <div className="working-schedule__crew--card d-flex align-items-center w-100p">
              <CrewMemberScheduleShiftContainer
                watchKeepingShifts={atPortWatchKeepingShifts}
                nonWatchKeepingShifts={atPortNonWatchKeepingShifts}
                numberOfShifts={maxNumberOfShifts}
              />
            </div>
          </div>
        </Col>
        <Col xs={2} className="ps-1">
          <div className="working-schedule__crew--bg d-flex align-items-center ps-1 fs-12 text-violet lh-15 pt-2 cpb-12">
            {_get(crewMember, 'comments')}
          </div>
        </Col>
        <Col xs={2} className="ps-1">
          <div className="working-schedule__crew--card--container">
            <div className="working-schedule__crew--card">
              <Row noGutters className="h-100p d-flex align-items-center text-violet fw-bold">
                <Col xs={6}>
                  {_get(crewMember, 'daily_rest_hours_at_sea')
                    ? `${numberToStr(_get(crewMember, 'daily_rest_hours_at_sea'))}hr`
                    : '-'}
                </Col>
                <Col xs={6}>
                  {_get(crewMember, 'daily_rest_hours_at_port')
                    ? `${numberToStr(_get(crewMember, 'daily_rest_hours_at_port'))}hr`
                    : '-'}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {!isViewOnly && !oldSelectedSchedule ? (
        <>
          {!crewMember.onboard ? (
            <CircledButton
              type="remove"
              onClick={onDelete}
              svgStyle={{ width: 8, height: 1 }}
              svgClassName="text-white"
              className="ms-1"
              style={{ width: 20, height: 20 }}
            />
          ) : null}
          <CircledButton
            type="edit"
            onClick={onEdit}
            svgStyle={{ width: 12, height: 12 }}
            svgClassName="text-white"
            svgWrapperClassName="bg-primary"
            className="ms-1"
            style={{ width: 20, height: 20 }}
          />
        </>
      ) : null}
    </div>
  );
};

export default CrewMemberSchedule;
