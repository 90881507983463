import { FC } from 'react';

import SvgRender from 'common/components/general/SvgRender';
import ActivateButton from 'common/components/buttons/ActivateButton';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import check from 'common/assets/svg/actions/check.svg';

import {
  getPreventedApprovalInfoMessage,
  selectActiveEntityId
} from 'common/components/review-process/store/selectors';
import { useAppSelector } from '@/store/hooks';

type ApproveButtonProps = {
  disabled: boolean;
  textBeforeCompletion: string | null;
  textAfterCompletion: string | null;
  completedBy: string | null;
  canApproveStep: boolean;
  canRevokeApprovedStep: boolean;
  isCompleted: boolean;
  handleApprove: () => void;
};

const ApproveButton: FC<ApproveButtonProps> = ({
  handleApprove,
  disabled,
  textBeforeCompletion,
  textAfterCompletion,
  completedBy,
  canApproveStep,
  canRevokeApprovedStep,
  isCompleted
}) => {
  const activeEntityId = useAppSelector(selectActiveEntityId);
  const preventionMessage = useAppSelector(state =>
    !canApproveStep && !isCompleted ? getPreventedApprovalInfoMessage(state, activeEntityId) : null
  );
  const isDisabled = disabled || (isCompleted ? !canRevokeApprovedStep : !canApproveStep);
  const { avoidRender, tooltipID } = useTooltipID('step-approve');

  return (
    <div className="d-flex align-items-center justify-content-end text-end" id={tooltipID}>
      <ActivateButton
        color="green"
        className={null}
        disabled={isDisabled}
        isActive={!!completedBy}
        onClick={handleApprove}
      >
        {completedBy ? (
          <>
            <SvgRender className="me-1" src={check} style={{ width: 14, height: 14 }} />
            {textAfterCompletion}
          </>
        ) : (
          textBeforeCompletion
        )}
      </ActivateButton>

      {tooltipID && !avoidRender && preventionMessage && !isDisabled && (
        <Tooltip target={tooltipID}>{preventionMessage}</Tooltip>
      )}
    </div>
  );
};

export default ApproveButton;
