import { Link } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';
import type { Placement } from '@popperjs/core';
import { constructTooltipMessage } from './helpers';
import Tooltip from '@/ts-common/components/general/Tooltip';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import linkIcon from 'common/assets/svg/common/link.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import AuthCheck from 'components/permissions/AuthCheck';

type PageLinkProps = PropsWithChildren<{
  to: string | object;
  customTooltipMessage?: string;
  tooltipPlacement?: Placement;
  openOnNewTab?: boolean;
  permissions?: string[];
  hideLinkIcon?: boolean;
  allowOverflow?: boolean;
  className?: string;
}>;

const PageLink: FC<PageLinkProps> = ({
  customTooltipMessage = null,
  to,
  children,
  tooltipPlacement = 'auto',
  openOnNewTab = true,
  permissions,
  hideLinkIcon = false,
  allowOverflow = false,
  ...rest
}) => {
  const { avoidRender, tooltipID } = useTooltipID('page-link-tooltip');

  const tooltipMessage = customTooltipMessage || constructTooltipMessage(to);

  const Content = () => (
    <AuthCheck permissions={permissions} unAuthorizedRender={children}>
      <Link target={openOnNewTab ? '_blank' : undefined} to={to} {...rest}>
        {children}
      </Link>
    </AuthCheck>
  );

  return (
    <div
      className={`d-flex ${
        !allowOverflow ? 'overflow-hidden text-nowrap align-items-center' : 'flex-wrap'
      }`}
    >
      {hideLinkIcon ? null : (
        <AuthCheck permissions={permissions}>
          <SvgRender
            className={`cme-4 text-royal-blue ${allowOverflow ? 'cmt-4' : ''}`}
            src={linkIcon}
            id={tooltipID}
            style={{ minWidth: 11, minHeight: 11, maxWidth: 11, maxHeight: 11 }}
          />
          {tooltipMessage && tooltipID && !avoidRender ? (
            <Tooltip
              placement={tooltipPlacement}
              innerClassName="min-width-fit max-width-fit"
              target={tooltipID}
              fade={false}
            >
              {tooltipMessage}
            </Tooltip>
          ) : null}
        </AuthCheck>
      )}

      {!allowOverflow ? (
        <TooltipContainer>
          <Content />
        </TooltipContainer>
      ) : (
        <div className="d-flex align-items-center flex-1 text-break text-left text-wrap">
          <Content />
        </div>
      )}
    </div>
  );
};

const TooltipContainer: FC<PropsWithChildren> = ({ children }) => (
  <div className="d-flex align-items-center overflow-hidden text-nowrap">
    <TextWithTooltip>{children}</TextWithTooltip>
  </div>
);

export default PageLink;
