const Title = ({ value, isSubTitle, previewMode = false }) => {
  return (
    <div
      className={`digital-forms-title fs-12 px-1 fw-bold ${
        isSubTitle ? 'digital-forms-title--sub' : ''
      } ${previewMode ? 'preview' : ''}`}
    >
      {value}
    </div>
  );
};

export default Title;
