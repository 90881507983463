import SvgRender from '@/ts-common/components/general/SvgRender.tsx';
import ribbonFirstHalf from '@/common/assets/svg/common/ribbon-first-half.svg';
import ribbonSecondHalf from '@/common/assets/svg/common/ribbon-second-half.svg';
import ribbonFull from '@/common/assets/svg/common/ribbon-full.svg';
import ribbonEmpty from '@/common/assets/svg/common/ribbon-line.svg';
import Tooltip from '@/ts-common/components/general/Tooltip';

export const constructSelectedAssignment = (
  shouldLeaveAssignment,
  responseHasData,
  selectedAssignment,
  payload
) => {
  let finalAssignment = {};

  if (!shouldLeaveAssignment && responseHasData) {
    finalAssignment = payload.data[0];
  } else {
    const foundAssignmentIndex = payload.data.findIndex(
      assignment => assignment.id === selectedAssignment?.id
    );

    finalAssignment =
      foundAssignmentIndex !== -1
        ? { ...selectedAssignment, ...payload.data[foundAssignmentIndex] }
        : selectedAssignment;
  }

  return finalAssignment;
};

export const getSubSystemIcon = (system, groupsCollapsed) => {
  if (!system.parent_id) return null;

  const getSubSystemInfo = (jobsCount, sparesCount) => {
    if (jobsCount && sparesCount)
      return {
        icon: ribbonFull,
        render: (
          <div>
            <div className="d-flex align-items-center">
              Jobs: <strong>{jobsCount}</strong>
            </div>
            <div className="d-flex align-items-center">
              Spare Parts: <strong>{sparesCount}</strong>
            </div>
          </div>
        )
      };
    if (jobsCount)
      return {
        icon: ribbonFirstHalf,
        render: (
          <div className="d-flex align-items-center">
            Jobs: <strong>{jobsCount}</strong>
          </div>
        )
      };
    if (sparesCount)
      return {
        icon: ribbonSecondHalf,
        render: (
          <div className="d-flex align-items-center">
            Spare Parts: <strong>{sparesCount}</strong>
          </div>
        )
      };

    return { icon: ribbonEmpty, render: null };
  };

  const { icon, render } = getSubSystemInfo(
    system.maintenance_job_prototypes_count,
    system.spare_parts_count
  );

  return (
    <>
      <SvgRender
        id={`subsystem-${system.id}`}
        className={groupsCollapsed ? '' : 'ms-3 me-1'}
        src={icon}
        style={{ minWidth: 14, maxWidth: 14, height: 14 }}
      />

      {!!render && <Tooltip target={`subsystem-${system.id}`}>{render}</Tooltip>}
    </>
  );
};
