import { useState, useEffect } from 'react';

import _debounce from 'lodash/debounce';

const useWindowWidth = debounce => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth || 0);
  const resizeWindow = () => setWindowWidth(window.innerWidth);

  const resizeWindowThrottled = debounce ? _debounce(resizeWindow, debounce) : resizeWindow;

  useEffect(() => {
    resizeWindowThrottled();
    window.addEventListener('resize', resizeWindowThrottled);
    return () => window.removeEventListener('resize', resizeWindowThrottled);
  }, []);

  return windowWidth;
};

export default useWindowWidth;
