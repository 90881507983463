import React, { useEffect, useMemo, useState } from 'react';
import { bool, func } from 'prop-types';

import BasicModal from 'common/components/modals/BasicModal';

import { Button } from 'reactstrap';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import * as jobTemplateActions from 'common/components/jobs/_base/modules/template/store/actions';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { isJobSaving, selectJobField } from 'common/components/jobs/_base/store/selectors';

import { filterJobStatuses } from 'common/components/jobs/_base/_helpers';
import { getFleetType } from 'common/components/jobs/_base/inputs/Vessel';
import Body from './Body';

const Modal = ({ isOpen, setIsOpen, avoidCheck }) => {
  const [setJobField, setForVesselJobField, updateJob] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setForVesselJobField,
    jobProfileActions.updateJob
  ]);
  const [updateFleetTemplateData] = useActions([jobTemplateActions.updateFleetTemplateData]);
  const jobStatuses = useSelector(selectJobStatuses);
  const departments = useSelector(state => selectJobField(state, 'departments'));

  const departmentsOptions = useSelector(state => state.lists['departments']).options;
  const partyAndCrewDepartments = useSelector(
    state => state.lists['party-and-crew-departments']
  ).options;

  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const mainDepartment = useSelector(state => selectJobField(state, 'main_department'));
  const projectId = useSelector(state => selectJobField(state, 'project'))?.id;

  const templateTypes = useSelector(state => state.lists['job-template-types']);
  const template = useSelector(state => state.jobsTemplates.template);

  const [selectedVessels, setSelectedVessels] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const isSubmitting = useSelector(isJobSaving);

  const filteredJobStatuses = useMemo(
    () =>
      filterJobStatuses({
        statuses: jobStatuses,
        departments,
        mainDepartment,
        isForVessel,
        vesselJobsOnly: true,
        departmentsOptions,
        partyAndCrewDepartments
      }),
    [
      departments,
      departmentsOptions,
      isForVessel,
      jobStatuses,
      mainDepartment,
      partyAndCrewDepartments
    ]
  );

  useEffect(() => {
    if (isOpen) {
      if (filteredJobStatuses.length) {
        setSelectedStatus(filteredJobStatuses[0]?.id);
      }
      if (vessels?.length) {
        setSelectedVessels(projectId ? vessels : [vessels[0]]);
      }
    }
  }, [filteredJobStatuses, isOpen, vessels, projectId]);

  const onSend = async () => {
    if (template && template.type_id && getFleetType(templateTypes) === template.type_id) {
      updateFleetTemplateData(template, [selectedVessels]);
    }

    // We need to set the itinerary_port back to null when we change the vessel to avoid problems with itinerary ports that are different from the vessel
    await setJobField('itinerary_port', null, true);

    await updateJob({
      is_for_vessel: !isForVessel,
      status_id: selectedStatus,
      vessels: selectedVessels
    });

    await setJobField('vessels', selectedVessels);
    await setForVesselJobField('is_for_vessel', !isForVessel);

    if (!isForVessel) {
      await setJobField('responsible_onboard_department', null);
    }

    await setJobField('status_id', selectedStatus);
    setIsOpen(false);
  };

  return (
    <BasicModal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      header="Sync Onboard"
      body={
        <Body
          selectedVessels={selectedVessels}
          setSelectedVessels={setSelectedVessels}
          filteredJobStatuses={filteredJobStatuses}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          avoidCheck={avoidCheck}
        />
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button color="cancel" onClick={() => setIsOpen(false)}>
            CANCEL
          </Button>
          <Button
            color="primary"
            disabled={!selectedStatus || !selectedVessels?.length || isSubmitting}
            onClick={onSend}
          >
            SEND TO VESSEL{projectId ? '(S)' : ''}
          </Button>
        </div>
      }
    />
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  avoidCheck: bool
};
