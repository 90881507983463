import PropTypes from 'prop-types';

import { displayDate } from 'common/utils/dates';
import { LinkedEntityContainer } from 'common/components/findings/report/tableConfig';
import { Finding } from 'common/entities/findings/FindingTypes';

const LinkedDate = ({ data, isEvent = false }) => (
  <>
    {data?.origin_audit && !isEvent ? (
      <LinkedEntityContainer>
        {displayDate(data?.origin_audit?.event?.started_at, { time: true })}
      </LinkedEntityContainer>
    ) : null}
    {data?.origin_form_submission ? (
      <LinkedEntityContainer>
        {displayDate(data?.origin_form_submission?.submission_date, { time: true })}
      </LinkedEntityContainer>
    ) : null}
    {data?.form_submissions?.length
      ? data?.form_submissions?.map((form, i) => (
          <LinkedEntityContainer key={i}>
            {displayDate(form?.submission_date, { time: true })}
          </LinkedEntityContainer>
        ))
      : null}
    {data?.jobs?.length
      ? data?.jobs?.map((job, i) => (
          <LinkedEntityContainer key={i}>
            {displayDate(job?.due_date, { time: true })}
          </LinkedEntityContainer>
        ))
      : null}
    {data?.purchasing_requisitions?.length
      ? data?.purchasing_requisitions?.map((requisition, i) => (
          <LinkedEntityContainer key={i}>
            {displayDate(requisition?.submitted_at, { time: true })}
          </LinkedEntityContainer>
        ))
      : null}
  </>
);

export default LinkedDate;

LinkedDate.propTypes = {
  data: Finding,
  isEvent: PropTypes.bool
};
