import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import config from './config';
import { useForm, useFormState, useUpdateEffect } from 'utils/hooks';
import { strToNumber } from 'common/utils/numbers';

import OfferRoutes from './OfferRoutes';
import MainFields from './MainFields';
import TicketType from './TicketType';
import TransportationMedium from './TransportationMedium';
import Actions from './Actions';

import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';

import SvgRender from 'common/components/general/SvgRender';
import dollar from 'common/assets/svg/common/dollar.svg';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import CircledButton from 'common/components/buttons/CircledButton';
import { useDrawer } from 'common/components/drawer';
import * as ticketingActions from 'store/ticketing/actions';
import { useActions } from 'utils/hooks';
import { useAppSelector } from '@/store/hooks';
import { selectListOptionsFromStore } from '@/store/lists/selectors-ts';

const FormBody = ({
  formInitialState,
  closeForm,
  active,
  disabledMode,
  hideActions,
  onOfferCreate,
  onOfferUpdate,
  onOfferAccept,
  onOfferDelete,
  onSubmitValidation,
  setPreventionModal,
  className,
  offersCount,
  components
}) => {
  const [previewMode, setPreviewMode] = useState(active ? true : false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [offerType, setOfferType] = useState(null);
  const [showLinkCases, setShowLinkCases] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState({ isOpen: false, id: null });
  const currencyOptions = useAppSelector(state => selectListOptionsFromStore(state, 'currencies'));

  const drawer = useDrawer(false);

  const [getTrip] = useActions([ticketingActions.getTrip]);

  const { formState, collectValues, hasErrors, handleSubmitError, resetForm } = useForm(
    config,
    active
      ? selectDataFromState(active, config)
      : formInitialState || { routes: [{ arrival: null, departure: null }] }
  );
  const { fields, changeField, selectField, subStates, addSubform, removeSubform } =
    useFormState(formState);

  const initForm = () => {
    resetForm();
  };

  const handleSubmitForm = async () => {
    const values = collectValues();
    if (onSubmitValidation) {
      onSubmitValidation();
    }
    if (!values) return;

    const {
      transportation_type,
      itinerary_type,
      agent,
      currency,
      routes,
      documents,
      is_bulk,
      participants_count,
      ...rest
    } = values;

    const params = {
      ...rest,
      transportation_type,
      itinerary_type,
      agent_id: agent ? agent.id : null,
      currency_id: currency ? currency.id : null,
      document_ids: documents ? documents.map(d => d.id) : [],
      routes: routes.map(r => {
        const { arrival, departure, from, to, airline, itinerary_no, ticket_no } = r;
        const route = { arrival: arrival, departure: departure };

        if (transportation_type === 'air') {
          route.from_airport_id = from.id;
          route.to_airport_id = to.id;
          route.from = null;
          route.to = null;
          route.itinerary_no = itinerary_no;
          route.ticket_no = ticket_no;
          route.airline_id = airline ? airline.id : null;
        } else {
          route.from = from;
          route.to = to;
          route.from_airport_id = null;
          route.to_airport_id = null;
          route.itinerary_no = null;
          route.ticket_no = ticket_no;
          route.airline_id = null;
        }

        return route;
      })
    };

    setIsSubmitting(true);

    try {
      if (active) {
        await onOfferUpdate({
          ...params,
          updateStateWith: {
            agent,
            currency,
            routes,
            transportation_type,
            itinerary_type,
            documents
          }
        });

        setPreviewMode(true);
      } else {
        await onOfferCreate(params);
        closeForm();
        initForm();
      }
    } catch (e) {
      console.log(e);
      handleSubmitError(e);
      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

  const acceptTicket = async accepted => {
    const params = { id: active.id, accepted };
    setIsSubmitting(true);

    try {
      await onOfferAccept(params);
    } catch (e) {
      console.log(e);
    }

    setIsSubmitting(false);
  };

  const handleTicketDelete = async () => {
    setIsSubmitting(true);

    try {
      await onOfferDelete({ id: active.id });
      closeForm();
      initForm();
    } catch (e) {
      console.log(e);
    }

    setIsSubmitting(false);
  };

  useUpdateEffect(() => {
    // Calculating Payable Price of the ticket
    if (fields.initial_price.value) {
      const discountAmount = fields.discount.value
        ? strToNumber(fields.initial_price.value) * (strToNumber(fields.discount.value) / 100)
        : 0;
      const amountAfterDiscount = strToNumber(fields.initial_price.value) - discountAmount;

      const taxAmount = fields.tax.value
        ? amountAfterDiscount * (strToNumber(fields.tax.value) / 100)
        : 0;
      const payablePrice = amountAfterDiscount - (taxAmount || 0);

      selectField('payable_price')(payablePrice);
    }
  }, [fields.initial_price.value, fields.discount.value, fields.tax.value]);

  useEffect(() => {
    if (fields.transportation_type.value !== offerType) {
      setOfferType(fields.transportation_type.value);
    }
  }, [fields.transportation_type.value]);

  useEffect(() => {
    if (activeInvoice.id && activeInvoice.isOpen) {
      drawer.open();
    }
  }, [activeInvoice.id, activeInvoice.isOpen]);

  const defaultCurrency = useMemo(
    () => currencyOptions.find(currency => currency.label === 'EUR'),
    [currencyOptions]
  );

  useEffect(() => {
    if (!active && defaultCurrency) {
      selectField('currency')(defaultCurrency);
    }
  }, [active, defaultCurrency, selectField]);

  const refetchData = async () => {
    await getTrip({ id: active.trip_id });
  };

  return offerType ? (
    <>
      <div
        className={`tickets-form-body small-elements-form ${previewMode ? 'preview-mode' : ''} ${
          active && active.accepted ? ' accepted-offer' : ''
        } ${className ? className : ''}`}
      >
        <TicketType
          documents={fields.documents.value}
          participantsCount={fields.participants_count.value}
          offerType={offerType}
          itineraryType={fields.itinerary_type?.value}
          selectField={selectField}
          previewMode={previewMode}
          setPreviewMode={setPreviewMode}
          disabledMode={disabledMode}
          acceptTicket={acceptTicket}
          price={fields.payable_price.value}
          currency={fields.currency.value}
          accepted={active && active.accepted}
          hideActions={hideActions}
          isSubmitting={isSubmitting}
          active={active}
        />
        {fields.participants_count.value > 1 ? (
          <div className="text-orange fs-12 px-4 mb-2">
            *The prices here refer to the total ticket price. The price of each person's ticket is
            the total price divided equally by the number of people.
          </div>
        ) : null}

        {offerType === 'other' ? (
          <TransportationMedium
            previewMode={previewMode}
            field={fields.transportation_medium}
            changeField={changeField}
          />
        ) : null}

        <MainFields
          fields={fields}
          participantsCount={fields.participants_count.value}
          selectField={selectField}
          changeField={changeField}
          previewMode={previewMode}
        />

        <OfferRoutes
          previewMode={previewMode}
          disabledMode={disabledMode}
          subStates={subStates}
          addSubform={addSubform}
          removeSubform={removeSubform}
          offerType={offerType}
          routes={fields.routes}
        />

        {!hideActions ? (
          <Actions
            active={active}
            isSubmitting={isSubmitting}
            setPreventionModal={setPreventionModal}
            handleTicketDelete={handleTicketDelete}
            handleSubmitForm={handleSubmitForm}
            acceptTicket={acceptTicket}
            previewMode={previewMode}
            setPreviewMode={setPreviewMode}
            closeForm={closeForm}
            resetForm={resetForm}
            hasErrors={hasErrors}
            offersCount={offersCount}
          />
        ) : null}

        {active?.accepted && active?.invoices?.length ? (
          <div className="d-flex-column ps-4 mt-2">
            <div className="text-turquoise d-flex">
              <SvgRender src={dollar} style={{ width: 14, height: 14 }} className="cme-4 mb-1" />
              <div className="fs-10">INVOICING</div>
            </div>
            {active.invoices?.map(invoice => {
              return (
                <>
                  <Row
                    className={`tickets-form-body align-items-center fs-12 cmb-4 border-radius-5 ${
                      active && active.accepted ? 'accepted-invoice' : ''
                    }`}
                  >
                    <Col xs={3}>
                      <div className="text-primary">
                        Vendor: <strong>{invoice?.vendor?.full_name}</strong>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="text-primary">
                        No. <strong>{invoice?.invoice_no}</strong>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="text-primary">
                        Invoice Date: <strong>{moment(invoice?.date).format('DD/MM/YYYY')}</strong>
                      </div>
                    </Col>
                    <Col xs={2}>
                      <div className="d-flex text-primary me-auto">
                        Amount:&nbsp;
                        <div className="pe-4 border-1 border-primary border-end text-primary d-flex">
                          <strong>{invoice?.price}&nbsp;</strong>
                          <strong className="width-24 overflow-hidden d-flex justify-content-start">
                            {invoice?.currency.symbol}
                          </strong>
                        </div>
                        <strong className="cps-6">
                          {invoice?.amount_base_currency_equivalent}&nbsp;$
                        </strong>
                      </div>
                    </Col>
                    <Col xs="auto" className="d-flex justify-content-end ms-auto me-1">
                      <CircledButton
                        type="invoice"
                        className={'open-invoice-drawer-button'}
                        style={{ width: 19, height: 19 }}
                        svgStyle={{ width: 11, height: 11 }}
                        onClick={() => setActiveInvoice({ isOpen: true, id: invoice.id })}
                        svgWrapperClassName={'bg-primary'}
                      />
                    </Col>
                  </Row>
                </>
              );
            })}
          </div>
        ) : null}
      </div>

      {components?.InvoiceDrawer ? (
        <components.InvoiceDrawer
          drawer={drawer}
          activeID={activeInvoice.id}
          showLinkCases={showLinkCases}
          setShowLinkCases={setShowLinkCases}
          refetchData={refetchData}
          onClose={() => setActiveInvoice({ isOpen: false, id: null })}
          showLinking={false}
          drawerPlace="ticketing"
        />
      ) : null}
    </>
  ) : null;
};

FormBody.propTypes = {
  disabledMode: PropTypes.bool, // is 'true' when editting is disabled
  active: PropTypes.object, // used in edit mode
  setPreventionModal: PropTypes.func, // setPreventionModal({ open: bool, onClose: func, onAccept: func, message: string })
  closeForm: PropTypes.func, // Close form
  onOfferCreate: PropTypes.func, // Create new offer
  onOfferUpdate: PropTypes.func, // Update an existing offer
  onOfferAccept: PropTypes.func, // Accept/Reject an offer
  onOfferDelete: PropTypes.func, // Delete an offer,
  className: PropTypes.string
};

export default FormBody;
