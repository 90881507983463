import { resetCurrentPage } from 'store/tables/actions';
import _get from 'lodash/get';
import { trimLocationPath } from 'common/utils/urls';
import { LOCATION_CHANGE } from 'connected-react-router';

const pathsMapping = {};

const resetDynamicTablesMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    let trimmedPath = trimLocationPath(action.payload.location.pathname);

    const state = store.getState();

    if (!pathsMapping[trimmedPath] && _get(state, 'tables.location.path', null)) {
      if (pathsMapping[state.tables.location.path]) {
        store.dispatch(resetCurrentPage({ label: pathsMapping[state.tables.location.path] }));
      }
    }
  }
  next(action);
};

export default resetDynamicTablesMiddleware;
