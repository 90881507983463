import { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

import Modal from 'common/components/modals/BasicModal';
import TextArea from 'common/components/form/inputs/Textarea';

import { useActions } from 'utils/hooks';
import * as shiftActions from 'crew/store/shifts/actions';

import { useSelector } from 'react-redux';
import { selectActiveShiftComments } from 'crew/store/shifts/selectors';
import CommentsPopover from 'common/components/popovers/CommentsPopover';

const dateFormat = 'YYYY-MM-DD';

const Comments = ({ date, isEditing, comment, crew }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [value, setValue] = useState('');

  const [setActiveShiftComment] = useActions([shiftActions.setActiveShiftComment]);
  const activeShiftComments = useSelector(selectActiveShiftComments);

  const toggleModal = async () => {
    if (isModalOpen) {
      setIsModalOpen(false);
      setTimeout(() => {
        setShowModal(() => false);
      }, 500);
    } else {
      setShowModal(true);
      setIsModalOpen(true);
    }
  };

  const savedComment = activeShiftComments[crew.id]
    ? activeShiftComments[crew.id][date.format(dateFormat)]
    : null;

  useEffect(() => {
    setValue(savedComment || savedComment === '' ? savedComment : comment);
  }, [savedComment, isModalOpen, comment]);

  const onSave = () => {
    setActiveShiftComment({ date: date.format(dateFormat), comment: value, id: crew.id });
    toggleModal();
  };

  const hasComment = savedComment ? true : savedComment === '' ? false : comment;

  return (
    <>
      <div
        className={`shifts-table--row-comments ${
          isEditing && !value ? 'cursor-pointer min-w-24 h-16' : ''
        } ${hasComment ? 'shifts-table--row-comments__has-comment' : ''} ms-2`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => (isEditing ? toggleModal() : null)}
      >
        <CommentsPopover
          svgStyle={{ height: 13, width: 15 }}
          className="cmb-2"
          isHovering={isHovering}
          popoverTitle={
            <>
              Comments / <strong>{date.format('dddd DD.MM')}</strong>
            </>
          }
          comments={isEditing && isHovering && !value ? ' ' : value}
        />
      </div>

      {showModal ? (
        <Modal
          header={
            <span>
              Comments / <b>{moment(date).format('dddd DD.MM')}</b>
            </span>
          }
          className="shifts-table--row-comments-modal"
          body={
            <TextArea
              rows={13}
              onChange={e => setValue(e.target.value)}
              value={value}
              className="mb-0 fs-14 lh-16"
              placeholder="Add some comments"
              invisible
            />
          }
          isOpen={isModalOpen}
          toggle={() => toggleModal()}
          footer={
            <>
              <Button color="cancel" onClick={() => toggleModal()}>
                CANCEL
              </Button>
              <Button color="primary" onClick={onSave}>
                APPLY
              </Button>
            </>
          }
        />
      ) : null}
    </>
  );
};

export default Comments;
