import React, { useState, useEffect } from 'react';

import UploadImage from 'common/components/form/inputs/UploadImage';
import PreviewProfileImage from 'common/components/general/ProfileImage';

import { useSelector } from 'react-redux';
import { upload } from 'utils/api';
import { imageUrlSelector } from 'common/utils/image-size';
import { getApiBaseUrl } from 'utils/api';

import rolodex from 'common/assets/svg/avatars/rolodex.svg';

const ProfileImage = ({ person, onUpdate }) => {
  const [updating, setUpdating] = useState(false);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const previewImageBaseUrl = isOnBoard ? getApiBaseUrl() : '';

  useEffect(() => {
    if (updating) {
      setUpdating(false);
    }
  }, [person?.photo?.url]);

  return isOnBoard ? (
    <PreviewProfileImage
      size="xl"
      rounded
      className="rounded-lg bg-white"
      image={
        previewImageBaseUrl +
        imageUrlSelector({
          file: person?.photo ? person.photo : null,
          label: 'contact_profile'
        })
      }
      fallback={rolodex}
    />
  ) : (
    <UploadImage
      size="xl"
      upload={upload}
      imageSizeLabel="contact_profile"
      innerDelete
      square
      group={'parties.photos'}
      image={person?.photo?.url ? person.photo : null}
      className="bg-white"
      onChange={val => {
        setUpdating(true);
        onUpdate({ id: person?.id, photo_id: val ? val.id : null, table: 'persons' });
      }}
      disabled={updating}
      fallback={rolodex}
    />
  );
};

export default ProfileImage;
