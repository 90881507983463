import Select, { SelectProps } from '@/ts-common/components/form/inputs/select';
import IconSelect, { IconSelectProps } from '@/ts-common/components/form/inputs/select/icon-select';
import useFilterQuerySelector from '@/common/components/filters/useFilterQuerySelector';
import { Props } from 'react-select';
import { OptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';

type BaseCollectionFilterProps<Option> = {
  memoizedRequestParams: OptionsRequestParams;
  value: string[] | null;
  onChange: (value: string[] | null) => void;
} & SelectProps<Option>;

export type CollectionFilterProps<Option> =
  | (BaseCollectionFilterProps<Option> & { icon?: undefined }) // when there's no icon
  | (BaseCollectionFilterProps<Option> & IconSelectProps); // when the icon is provided

function CollectionFilterSelector<Option extends { id: number; name?: string }>({
  memoizedRequestParams,
  value,
  icon,
  onChange,
  isMulti = true,
  ...rest
}: Omit<Props<Option>, 'value'> & CollectionFilterProps<Option>) {
  const selectedOptions = useFilterQuerySelector(value || null, memoizedRequestParams) as
    | Option[]
    | null;

  const CollectionFilterComponent = icon ? IconSelect : Select;

  return (
    <CollectionFilterComponent
      placeholder="Select value"
      icon={icon ? icon : ''}
      autoFocus={true}
      value={
        !isMulti
          ? selectedOptions?.length
            ? (selectedOptions[0] as unknown as Option)
            : null
          : (selectedOptions as unknown as Option[])
      } // Type assertion to make TypeScript happy
      onChange={selected => {
        if (isMulti) {
          const selectedValues = selected as Option[] | null;

          onChange(selectedValues?.length ? selectedValues.map(({ id }) => id.toString()) : null);
        } else {
          const selectedValue = selected as Option | null;

          onChange(selectedValue ? [selectedValue.id.toString()] : null);
        }
      }}
      memoizedRequestParams={memoizedRequestParams}
      getOptionValue={option => option.id.toString()}
      getOptionLabel={option => option.name ?? ''}
      isAsync={true}
      isMulti={isMulti}
      {...rest}
    />
  );
}

export default CollectionFilterSelector;
