import _reduce from 'lodash/reduce';
import _findKey from 'lodash/findKey';
import _pickBy from 'lodash/pickBy';
import _orderBy from 'lodash/orderBy';

export const getLastVersion = versions =>
  versions.find(v => v.status === 'active') ||
  versions.find(v => v.status === 'pending_approval') ||
  versions[versions.length - 1];

export const getWorkingVersion = versions => versions.find(v => v.status === 'working');

export const getChaptersIDs = chapters => chapters.map(c => c.id);

export const getChaptersUIDs = chapters => chapters.map(c => c.uid);

export const createChaptersAsObject = chapters =>
  _reduce(
    chapters,
    (result, value) => {
      result[value.uid] = value;
      return result;
    },
    {}
  );

export const findParentChapter = (parentUid, stateChapters) => {
  return _findKey(stateChapters, c => c.uid === parentUid);
};

export const getChapterTree = (chapterUid, stateChapters) => {
  // Ascendance
  const chapter = stateChapters[chapterUid];
  if (!chapter) return [];

  let tree = [
    {
      id: chapter.id,
      title: chapter.title,
      uid: chapter.uid,
      show_sort_index_path: chapter.show_sort_index_path,
      sort_index_path: chapter.sort_index_path
    }
  ];

  if (chapter.parent_uid) {
    const parentUid = findParentChapter(chapter.parent_uid, stateChapters);

    tree = [...tree, ...getChapterTree(parentUid, stateChapters)];
  }

  return tree;
};

export const getParentChapterChildren = (chapterUid, stateChapters, subChapters) => {
  // Descendance
  const chapter = stateChapters[chapterUid];
  if (!chapter) return [];

  let tree = [{ id: chapter.id, title: chapter.title, uid: chapter.uid }];

  if (subChapters[chapterUid]) {
    subChapters[chapterUid].forEach(childUid => {
      tree = [...tree, ...getParentChapterChildren(childUid, stateChapters, subChapters)];
    });
  }

  return tree;
};

export const getChapterBreadcrumbs = (chapterUid, stateChapters) => {
  return getChapterTree(chapterUid, stateChapters).reverse();
};

export const searchChapters = (chapters, search) => {
  // Searches tree in the front
  const filtered = _pickBy(chapters, chapter => {
    if (chapter.parent_uid) {
      const parentUid = findParentChapter(chapter.parent_uid, chapters);
      const parent = chapters[parentUid];

      return (
        parent.title.toLowerCase().includes(search.toLowerCase()) ||
        chapter.title.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      return chapter.title.toLowerCase().includes(search.toLowerCase());
    }
  });

  return Object.keys(filtered).reduce((acc, key) => {
    const chapterTree = getChapterTree(key, chapters).reduce((obj, curP) => {
      obj[curP.uid] = true;

      return obj;
    }, {});

    acc = { ...acc, ...chapterTree };

    return acc;
  }, {});
};

export const parseSearchedChapters = (chapters, searchedChapters, subChapters) => {
  let chaptersInSearch = {};

  searchedChapters.forEach(chapter => {
    const chapterChildren = getParentChapterChildren(
      chapter.parent_uid || chapter.uid,
      chapters,
      subChapters
    ).reduce((obj, curP) => {
      obj[curP.uid] = true;

      return obj;
    }, {});

    const chapterTree = getChapterTree(chapter.uid, chapters).reduce((obj, curP) => {
      obj[curP.uid] = true;

      return obj;
    }, {});

    chaptersInSearch = {
      ...chaptersInSearch,
      ...chapterTree,
      ...chapterChildren
    };
  });

  return chaptersInSearch;
};

export const highlightSearchedChapters = searchedChapters => {
  const highlightedChapters = searchedChapters.reduce((obj, curP) => {
    obj[curP.uid] = true;

    return obj;
  }, {});
  return highlightedChapters;
};

export const sortChaptersBySortIndex = chapters => _orderBy(chapters, ['sort_index'], ['asc']);

export const highlightTextInTitle = (originalText, highlightValue) => {
  if (!highlightValue) return originalText;

  const searchRegex = new RegExp(`(${highlightValue})`, 'gi');
  const parts = originalText?.split(searchRegex);

  return parts?.map((part, i) => (
    <span
      key={i}
      className={part.toLowerCase() === highlightValue.toLowerCase() ? 'marked-text' : 'p-0'}
    >
      {part}
    </span>
  ));
};

export const highlightTextInHtml = (originalText, highlightValue) => {
  const characterRegex = /^[ a-z]+$/i;
  if (!highlightValue || !characterRegex.test(highlightValue)) return originalText;

  const searchRegex = new RegExp(`(?<!<\/?[^>]*|&[^;]*)${highlightValue}`, 'gi');

  return originalText.replace(searchRegex, match => `<mark>` + match + `</mark>`);
};
