import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import {
  OptionFullDepartment,
  SingleValueFullDepartment
} from 'common/components/selectors/_partySelectComponents';

const OfficeUserInput = ({ error, value, onChange }) => {
  const listParams = { can_login: true, with_departments: true, type: 'person' };

  return (
    <Select
      isAsync
      isClearable
      value={value}
      onChange={onChange}
      loadOptions={search => getAsyncOptions(search, 'parties', listParams)}
      defaultOptions={() => getInitialAsyncValues('parties', null, false, listParams)}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      components={{ Option: OptionFullDepartment, SingleValue: SingleValueFullDepartment }}
      className="mb-0"
      error={error}
      placeholder="Select"
    />
  );
};

export default OfficeUserInput;
