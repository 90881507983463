import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteJob } from '@/api/jobs/api.ts';
import { removeTableRow } from 'store/tables/lists/actions';

export const deleteJobAction = createAsyncThunk(
  'DELETE_JOB',
  async (params: { id: number; table: string }, { rejectWithValue, dispatch }) => {
    const { id, table } = params;

    try {
      await deleteJob({ id });
      dispatch(removeTableRow({ data: params, table }));

      return params;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
