import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DateInput, { mode } from 'common/components/form/inputs/date';
import Modal from 'common/components/modals/BasicModal';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from 'events/store/events/actions';
import { selectEventId } from 'events/store/events/selectors';
import moment from 'moment';

const PeriodicityModal = ({ modal, setModal }) => {
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);

  const toggle = () => setModal(prev => ({ ...prev, isOpen: !prev.isOpen }));

  const { isOpen, nextDate } = modal;

  const onTrigger = async () => {
    if (!date) return;

    setIsLoading(true);

    try {
      await dispatch(
        createEvent({
          is_recurring: true,
          event_id: eventId,
          next_occurrence: moment(date).format('YYYY-MM-DD')
        })
      );

      toggle();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen && nextDate) {
      setDate(nextDate);
    }
  }, [nextDate, isOpen]);

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      header="Create Next Event"
      footer={
        <div className="d-flex align-items-center justify-content-between w-100p">
          <Button color="cancel" disabled={isLoading} onClick={toggle}>
            CANCEL
          </Button>

          <div className="d-flex align-items-center">
            <Button type="button" color="white" disabled={isLoading} onClick={toggle}>
              DO NOT CREATE
            </Button>
            <Button
              type="button"
              color="primary"
              className="ms-1"
              disabled={isLoading}
              onClick={onTrigger}
            >
              CREATE
            </Button>
          </div>
        </div>
      }
      body={
        <div>
          <span className="text-violet fs-12 fw-medium">
            The next Event is going to be created with Date:
          </span>

          <DateInput
            className="mt-3"
            useStringValue
            label="NEW EVENT DATE"
            onChange={selected => setDate(selected)}
            activeMode={mode.single.date}
            value={date}
          />
        </div>
      }
    />
  );
};

PeriodicityModal.propTypes = {
  modal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    nextDate: PropTypes.string.isRequired
  }).isRequired,
  setModal: PropTypes.func.isRequired
};

export default PeriodicityModal;
