import TYPES from './types';
import { get, post, put } from 'utils/api';

export const getEventTemplates = params => dispatch => {
  dispatch({ type: TYPES.GET_EVENT_TEMPLATES.START, payload: { params } });

  return get(`/event-templates`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_EVENT_TEMPLATES.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_EVENT_TEMPLATES.ERROR, payload: error });
      throw error;
    });
};

export const getEventTemplate = id => dispatch => {
  dispatch({ type: TYPES.GET_EVENT_TEMPLATE.START, payload: id });

  return get(`/event-templates/${id}`)
    .then(response => {
      dispatch({ type: TYPES.GET_EVENT_TEMPLATE.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_EVENT_TEMPLATE.ERROR, payload: error });
      throw error;
    });
};

export const createEventTemplate = params => dispatch => {
  dispatch({ type: TYPES.CREATE_EVENT_TEMPLATE.START, payload: { params } });

  return post(`/event-templates`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_EVENT_TEMPLATE.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_EVENT_TEMPLATE.ERROR, payload: error });
      throw error;
    });
};

export const editEventTemplate = (params, id) => dispatch => {
  dispatch({ type: TYPES.EDIT_EVENT_TEMPLATE.START, payload: { params } });

  return put(`/event-templates/${id}`, params)
    .then(response => {
      dispatch({ type: TYPES.EDIT_EVENT_TEMPLATE.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_EVENT_TEMPLATE.ERROR, payload: error });
      throw error;
    });
};

export const getEventTemplateFieldData = params => dispatch => {
  dispatch({ type: TYPES.GET_EVENT_TEMPLATE_FIELD_DATA.START, payload: { params } });

  return get(`/event-templates`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_EVENT_TEMPLATE_FIELD_DATA.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_EVENT_TEMPLATE_FIELD_DATA.ERROR, payload: error });
      throw error;
    });
};

export const archiveTemplate = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_TEMPLATE.START, payload: { params } });

  return put(`/event-templates/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_TEMPLATE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.ARCHIVE_TEMPLATE.ERROR, payload: error }));
};

export const unarchiveTemplate = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_TEMPLATE.START, payload: { params } });

  return put(`/event-templates/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_TEMPLATE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UNARCHIVE_TEMPLATE.ERROR, payload: error }));
};
