import { useMemo, useCallback } from 'react';

import _debounce from 'lodash/debounce';

const useRandomDebounce = (fn, min = 1, max = 1000) => {
  const randomTime = useMemo(() => Math.random() * (max - min) + min, [min, max]);
  const debouncedFn = useCallback(_debounce(fn, randomTime), []);

  return debouncedFn;
};

export default useRandomDebounce;
