import UploadFiles from 'common/components/form/inputs/upload-files';
import { download, getApiBaseUrl } from 'utils/api';
import { useSelector } from 'react-redux';
import { isManualOnBoard } from 'manuals/store/selectors';

const ChapterFile = ({ chapter }) => {
  const isOnBoard = useSelector(isManualOnBoard);
  const filePreviewURL = isOnBoard ? getApiBaseUrl() : '';

  return chapter?.file ? (
    <div className="chapter-file-container h-100p d-inline-flex align-items-center me-2">
      <UploadFiles
        download={download}
        className="square-upload chapter-file me-1"
        group="manuals"
        files={[chapter.file]}
        showFileName={false}
        viewOnly={true}
        showDelete={false}
        smallView={true}
        previewImageBaseUrl={filePreviewURL}
      />
    </div>
  ) : null;
};

export default ChapterFile;
