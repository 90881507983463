import ShadowBox from 'common/components/general/ShadowBox';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import UploadFiles from 'common/components/form/inputs/upload-files';
import FormSaveActions from 'common/components/form/FormSaveActions';
import Select from 'common/components/form/inputs/Select';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';

import { optionField } from 'common/utils/form/fieldTypes';
import { Row, Col } from 'reactstrap';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useForm, useFormState } from 'utils/hooks';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';

import { useEffect, useState } from 'react';
import { selectEventId } from 'events/store/events/selectors';
import _get from 'lodash/get';
import {
  selectEventHasVettingInspection,
  selectEventVettingInspection
} from 'events/store/event-modules/vetting/selectors';
import { getEventVetting, updateEventVetting } from 'events/store/event-modules/vetting/actions';
import { getCompanyEmployeesAction } from 'store/companies/actions';

const config = {
  inspecting_company: optionField({ required: true }),
  inspector_id: optionField({ required: true }),
  attachments: optionField({ initialValue: [] })
};

const InspectionForm = () => {
  const [inspectors, setInspectors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewMode, setPreviewMode] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();
  const { formState, loadValues, collectValues, handleSubmitError } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventId = useSelector(selectEventId);
  const eventHasVetting = useSelector(selectEventHasVettingInspection);
  const eventVettingInspection = useSelector(selectEventVettingInspection);

  const onSave = async () => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSubmitting(true);
      const { inspecting_company, inspector_id, attachments } = values;

      const params = {
        inspecting_company_id: inspecting_company?.id,
        inspector_id,
        attachment_ids: attachments?.map(a => a.id)
      };

      await dispatch(updateEventVetting({ id: eventId, ...params })).unwrap();
      await dispatch(getEventVetting({ id: eventId }));

      setIsSubmitting(false);
      setPreviewMode(true);
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  const getInpsectors = useCallback(
    async id => {
      try {
        const employees = await dispatch(getCompanyEmployeesAction({ id }));

        setInspectors(employees);
      } catch (error) {
        console.error(error);
        setInspectors([]);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (fields.inspecting_company.value?.id) {
      getInpsectors(fields.inspecting_company.value?.id);
    } else if (!fields.inspecting_company.value?.id) {
      setInspectors([]);
    }
  }, [fields.inspecting_company.value?.id, getInpsectors]);

  useEffect(() => {
    loadValues(eventVettingInspection);
  }, [eventVettingInspection, loadValues]);

  useEffect(() => {
    if (isOnBoard) {
      setPreviewMode(true);
      return;
    }

    if (!eventHasVetting) {
      setPreviewMode(false);
    }
  }, [eventHasVetting, isOnBoard]);

  return (
    <ShadowBox
      className="d-flex align-items-center ps-3 py-1 position-relative"
      color={previewMode ? null : 'light-4'}
      flat={previewMode ? false : true}
      flatOnHover={previewMode ? true : false}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {previewMode ? (
        <div className="d-flex align-items-center pe-4 w-100p">
          <div className="flex-1 text-violet fw-medium fs-14">
            Inspecting Company:{' '}
            <span className="text-primary">
              {_get(eventVettingInspection, 'inspecting_company.company_name', '-')}
            </span>
            <span className="mx-2">|</span>Inspector:{' '}
            <span className="text-primary">
              {_get(eventVettingInspection, 'inspector.full_name', '-')}
            </span>
          </div>

          <DownloadAttachment
            data={eventVettingInspection}
            downloadFileName="event_vetting_attachments"
            downloadFiles={() => downloadAllFilesOfEntity({ id: eventId, type: 'vetting' })}
            hasAttachments={eventVettingInspection?.attachments?.length}
          />
        </div>
      ) : (
        <Row className="w-100p">
          <Col xs={4}>
            <AsyncSelector
              label="INSPECTING COMPANY"
              onChange={value => {
                selectField('inspecting_company')(value);
                selectField('inspector_id')(null);
              }}
              type="parties"
              placeholder="Select company"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              loadOptions={search =>
                getAsyncOptions(search, 'parties', { type: 'company', role: ['vetting'] })
              }
              defaultOptions={() =>
                getInitialAsyncValues('parties', null, null, { type: 'company', role: ['vetting'] })
              }
              className="mb-0"
              {...fields.inspecting_company}
            />
          </Col>
          <Col xs={4}>
            <Select
              label="INSPECTOR"
              onChange={selectField('inspector_id')}
              placeholder="Select inspector"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              className="mb-0"
              disabled={!fields.inspecting_company.value?.id}
              options={inspectors}
              {...fields.inspector_id}
            />
          </Col>
          <Col xs={4}>
            <UploadFiles
              label="ATTACHMENTS"
              group="events.vetting"
              files={fields.attachments.value}
              onChange={selectField('attachments')}
            />
          </Col>
        </Row>
      )}

      {!isOnBoard && (
        <FormSaveActions
          isDisabled={isSubmitting}
          isHovering={isHovering}
          mode={previewMode ? 'view' : 'edit'}
          onCancel={eventHasVetting ? () => setPreviewMode(true) : null}
          onEdit={() => setPreviewMode(false)}
          onSave={onSave}
          style={{ right: -12, top: 5 }}
        />
      )}
    </ShadowBox>
  );
};

export default InspectionForm;
