import { get, post, put } from 'utils/api';
import { parseInventoryData } from 'common/components/inventory/store/helpers';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

import TABLE_TYPES from 'common/components/table/store/types';

export const getInventoryTableData = createAsyncThunk(
  'GET_INVENTORY_TABLE_DATA',
  async (params, { rejectWithValue, dispatch, getState }) => {
    const { table, ...rest } = params;
    const itemType = table === 'inventory_spare_parts' ? 'spare_part' : 'store';

    try {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

      const response = await get(`/inventory/table/${itemType}`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: parseInventoryData(response.data, { isOnBoard: getState().isOnBoard }),
          table
        }
      });

      return response.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      return rejectWithValue(error);
    }
  }
);

export const getInventoryLatestSurvey = createAsyncThunk(
  'GET_INVENTORY_LATEST_SURVEY',
  async ({ item_type, ...rest }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await get(`/inventory/table/${item_type}`, rest);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInventorySurvey = createAsyncThunk(
  'CREATE_INVENTORY_NEW_SURVEY',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await post('/inventory/surveys', params);

      dispatch(successHandler({ title: 'Success!', message: 'Survey created' }));

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInventoryItem = createAsyncThunk(
  'UPDATE_INVENTORY_ITEM',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, ...rest } = params;

    try {
      const response = await put(`/inventory/table/${id}`, rest);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInventoryHistory = createAsyncThunk(
  'GET_INVENTORY_HISTORY',
  async (params, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const { vessel_id } = params;

      const response = await get(
        getState().isOnBoard ? `/inventory/history` : `vessels/${vessel_id}/inventory/history`,
        params
      );

      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
