import useRouter from 'use-react-router';

import paths from '@/routing/routes/_paths';

const useIsInOnboardSetupFilters = () => {
  const { location } = useRouter();

  return location.pathname.includes(paths.setup_onboard_filters);
};

export default useIsInOnboardSetupFilters;
