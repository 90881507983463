import { Row, Col } from 'reactstrap';

const TableHeader = () => {
  return (
    <Row className="mb-1 pe-2 cps-4  fs-10 fw-bold text-violet" noGutters>
      <Col xs={6}></Col>
      <Col className="pe-1">JOB TYPE</Col>
      <Col>PERIODICITY</Col>
      <Col>LAST DONE AT</Col>
      <Col>DUE DATE</Col>
      <Col className="text-end">LAST DONE R/H</Col>
      <Col className="text-end">R/H DUE ON</Col>
      <Col xs={'auto'}>
        <div className="width-48" />
      </Col>
    </Row>
  );
};

export default TableHeader;
