import { usePlannedRouteWaypointsQuery } from '@/api/planned-routes/queries';
import { selectCaptainReportPlannedRouteId } from '@/captain-reports/store/selectors';
import { useSelector } from 'react-redux';
import { numberToStr, strToNumber } from '@/ts-common/utils/numbers';
import { Label } from 'reactstrap';
import { useMemo } from 'react';

const ReportMilesToGo = () => {
  const plannedRouteId = useSelector(selectCaptainReportPlannedRouteId);
  const plannedRouteWaypoints = usePlannedRouteWaypointsQuery({
    id: plannedRouteId
  });

  const milesToGo = useMemo(() => {
    return (plannedRouteWaypoints?.data || [])
      .map(({ distance }) => strToNumber(distance || 0))
      .reduce((acc, cur) => {
        return (acc || 0) + (cur || 0);
      }, 0);
  }, [plannedRouteWaypoints?.data]);

  return (
    <div className="border-start mb-0 cms-4 ps-2">
      <Label>MILES TO GO</Label>
      <div className="cpt-4 fs-12 fw-medium">{numberToStr(milesToGo, 1, 1)}</div>
    </div>
  );
};

export default ReportMilesToGo;
