import WhiteShadowButton from 'common/components/buttons/WhiteShadowButton';
import SvgRender from 'common/components/general/SvgRender';
import { FC, ReactNode } from 'react';

type EmptyReportPageProps = {
  className?: string;
  pageText: ReactNode;
  pageIcon: string;
  subTitle?: ReactNode;
  buttonText?: string;
  buttonType?: string;
  buttonOnClick?: () => void;
  buttonClassName?: string;
  svgClassName?: string;
  backgroundSvgContainerClassName?: string;
};

const EmptyReportPage: FC<EmptyReportPageProps> = ({
  className,
  pageText,
  pageIcon,
  subTitle,
  buttonText,
  buttonType,
  buttonOnClick,
  buttonClassName,
  svgClassName,
  backgroundSvgContainerClassName = ''
}) => {
  return (
    <div className={`empty-report-page ${className || ''}`}>
      {pageIcon ? (
        <div className={`empty-report-page__bg ${backgroundSvgContainerClassName}`}>
          <SvgRender src={pageIcon} style={{ width: '100%', height: '100%' }} />
        </div>
      ) : null}
      <div className={`empty-report-page__text ${subTitle ? 'mb-1' : ''}`}>{pageText}</div>
      {subTitle ? <div className="empty-report-page__subTitle">{subTitle}</div> : null}

      {buttonOnClick ? (
        <WhiteShadowButton
          text={buttonText}
          type={buttonType}
          onClick={buttonOnClick}
          className={buttonClassName}
          svgClassName={svgClassName}
        />
      ) : null}
    </div>
  );
};

export default EmptyReportPage;
