import React, { useEffect, useState } from 'react';

import Select, { styles } from 'common/components/form/inputs/Select';
import { components } from 'react-select';

import { getInitialAsyncValues } from 'utils/helpers';

import * as workingScheduleActions from 'store/working-schedule/actions';
import { useActions } from 'utils/hooks';

import ExportPdf from 'common/components/general/ExportPdf';

import SvgRender from 'common/components/general/SvgRender';
import historyIcon from 'common/assets/svg/common/history.svg';

import { saveAs } from 'file-saver';

import {
  selectOldSchedule,
  selectLatestSchedule,
  selectIsDraft
} from 'store/working-schedule/selectors';
import { useSelector } from 'react-redux';

import moment from 'moment';

const DatesPreview = ({ props, fontSize = 12 }) => {
  return (
    <div className="d-flex">
      <span className={`text-nowrap text-primary fs-${fontSize} fw-normal`}>
        Applicable since:&nbsp;
      </span>
      <strong className={`fs-${fontSize} text-primary`}>
        {moment(props.data.applicable_since, 'YYYY-MM-DD').format('DD/MM/YYYY') || '-'}
      </strong>
      <span className={`text-nowrap text-violet fs-${fontSize} ms-1`}>
        {' '}
        | {props.data.published_at ? 'Published at: ' : ''}{' '}
        <strong>{moment(props.data.published_at).format('DD/MM/YYYY') || 'Draft'}</strong>
      </span>
    </div>
  );
};

const WorkingScheduleHistoryDropdown = () => {
  const [options, setOptions] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const [getOldSchedule, getLatestSchedule, downloadLatestPDF, downloadOldPDF] = useActions([
    workingScheduleActions.getOldSchedule,
    workingScheduleActions.getLatestSchedule,
    workingScheduleActions.downloadLatestPDF,
    workingScheduleActions.downloadOldPDF
  ]);

  const isDraft = useSelector(selectIsDraft);
  const oldSelectedSchedule = useSelector(selectOldSchedule);
  const latestSchedule = useSelector(selectLatestSchedule);

  useEffect(() => {
    const initFetch = async () => {
      const listingOptions = await getInitialAsyncValues('crew-working-schedules');
      setOptions([{ ...latestSchedule, isLatest: true }, ...(listingOptions || [])]);
    };

    if (latestSchedule) {
      initFetch();
    }
  }, [latestSchedule]);

  const onSelect = (_, schedule) => {
    if (!schedule.isLatest) {
      getOldSchedule(schedule);
    } else {
      getLatestSchedule();
    }
  };

  const downloadPdf = async () => {
    try {
      setIsDownloading(true);

      let response;

      if (oldSelectedSchedule) {
        response = await downloadOldPDF({ id: oldSelectedSchedule });
      } else {
        response = await downloadLatestPDF();
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, 'working-schedule.pdf');
      } else {
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, 'working-schedule.pdf');
      }

      setIsDownloading(false);
    } catch (error) {
      console.error(error);
      setIsDownloading(false);
    }
  };

  const humanizeDateDifference = date => {
    const now = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
    return moment.duration(moment(date).diff(now)).humanize(true);
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props} className="w-100p pe-2">
        <DatesPreview props={props} />
      </components.SingleValue>
    );
  };

  const Placeholder = ({ children, ...props }) => {
    return (
      <components.Placeholder {...props}>
        <DatesPreview
          props={{
            data: {
              applicable_since: latestSchedule?.applicable_since,
              published_at: latestSchedule?.published_at
            }
          }}
        />
      </components.Placeholder>
    );
  };

  const DropdownIndicator = ({ children, ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="working-schedule__dropdown--history">
          <SvgRender src={historyIcon} style={{ width: 16, height: 16 }} className="cme-6" />
          {options.length - 1}
        </div>
      </components.DropdownIndicator>
    );
  };

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <DatesPreview props={props} fontSize={14} />
      </components.Option>
    );
  };

  return (
    <div
      className={`d-flex align-items-center base-transition ${!options.length ? ' opacity-0' : ''}`}
    >
      <Select
        className={`form-field react-select position-relative mb-0 working-schedule__dropdown`}
        classNamePrefix={`react-select`}
        styles={styles(null, null)}
        value={oldSelectedSchedule}
        components={{ SingleValue, Option, Placeholder, DropdownIndicator }}
        options={options.length === 1 && options[0].isLatest ? [] : options}
        onChange={onSelect}
      />
      <div className="working-schedule__seperator">
        <ExportPdf
          onClick={downloadPdf}
          disabled={isDownloading || isDraft}
          textClassname="ms-1 fs-12 line-height-15"
        />
      </div>
    </div>
  );
};

export default WorkingScheduleHistoryDropdown;
