import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _find from 'lodash/find';
import { useActions } from 'utils/hooks';

import * as reportActions from 'captain-reports/store/actions';
import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import {
  selectConsumptionFuelGrade,
  selectCaptainReportRobDifferences
} from 'captain-reports/store/selectors';

const LfoHfoCorrectionField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const fuel_grade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const robDifferenceData = useSelector(selectCaptainReportRobDifferences);
  const [setValue] = useActions([reportActions.setValue]);
  // Select the corresponding (lfo / hfo) rob_difference key.
  const robDifferenceKey = `rob_difference_${field.key.split('_')[1]}`;

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const isFoGrade = fuel_grade.category === 'fo';

  useEffect(() => {
    if (!isFoGrade) return;

    if (value === null) {
      const fuelGradeDifference = _find(robDifferenceData, e => e.fuel_grade_id === fuel_grade.id);
      const autofillValue = fuelGradeDifference?.[robDifferenceKey];

      if (autofillValue || autofillValue === 0) {
        setValue({
          key: field.key,
          value: autofillValue || null,
          sectionLabel: sectionLabel,
          sectionActiveTab: sectionActiveTab,
          subGroup: subGroup,
          subGroupIndex: subGroupIndex
        });
      }
    }
  }, [robDifferenceData?.length]);

  return (
    <div className={`${isFoGrade ? '' : 'd-none'}`}>
      {/* We use d-none classname to hide only the NumberField component, it's important to keep, so calculation can be occur */}
      <NumberField
        field={field}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </div>
  );
};

export default LfoHfoCorrectionField;
