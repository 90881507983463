import { Button } from 'reactstrap';
import { useState, useCallback } from 'react';
import SvgRender from 'common/components/general/SvgRender';

import edit from 'common/assets/svg/actions/edit.svg';
import editEmpty from 'common/assets/svg/actions/edit-empty.svg';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const PeriodButtonEditApproved = ({ updatePeriodStatus, latestPeriod, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  return (
    <>
      <Button
        type="button"
        color="white"
        className={`d-inline-flex align-items-center px-2 py-1 border-0 ms-1 ${className}`}
        onClick={openModal}
      >
        <SvgRender src={edit} style={{ width: 14, height: 14 }} />
        <span className="lh-1 ps-1 fw-normal">
          Edit this <strong>Reviewed</strong> Period
        </span>
      </Button>

      <DangerousActionModal
        transparent
        actionIcon={
          <SvgRender src={editEmpty} style={{ width: 80, height: 70 }} className="text-white" />
        }
        onAccept={() => updatePeriodStatus(latestPeriod?.id, 'for-review')}
        actionHoverColor="primary"
        closeModal={closeModal}
        isOpen={isModalOpen}
        actionText="EDIT"
        cancelText="CANCEL"
        header={'Edit this Reviewed Period'}
        body={`Are you sure you want to edit this Reviewed MGA Period?`}
      />
    </>
  );
};

export default PeriodButtonEditApproved;
