import { useEffect } from 'react';
import { useActions } from 'utils/hooks';

import * as tablesActions from 'store/tables/actions';

const useResetCurrentPage = label => {
  const [resetCurrentPage] = useActions([tablesActions.resetCurrentPage]);

  useEffect(() => {
    return () => {
      resetCurrentPage({ label });
    };
  }, [label, resetCurrentPage]);
};
export default useResetCurrentPage;
