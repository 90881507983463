import NumberField from 'captain-reports/templates/inputs/NumberField';
import TimeInput from 'captain-reports/templates/inputs/TimeInput';
import React from 'react';
import GeneratorsOilAndAir from 'captain-reports/templates/components/custom-fields/GeneratorsOilAndAir';
import GeneratorSfoc from 'captain-reports/templates/components/custom-fields/GeneratorSfoc';
import GeneratorsHeaderExhaustGas from 'captain-reports/templates/components/custom-fields/GeneratorsHeaderExhaustGas';
import GeneratorLoConsumptionField from 'captain-reports/templates/components/custom-fields/GeneratorLoConsumptionField';
import GeneratorLoFeedRateField from 'captain-reports/templates/components/custom-fields/GeneratorLoFeedRateField';
import GeneratorLoadSharingField from 'captain-reports/templates/components/custom-fields/GeneratorLoadSharingField';

import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';

const config = () => [
  {
    fields: [
      {
        label: 'Running Hours',
        key: 'dg_running_minutes',
        width: { xs: 2 },
        render: TimeInput
      },
      {
        label: 'Load',
        key: 'dg_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'DG LOAD SHARING',
        key: 'dg_load_sharing_percentage',
        subLabel: '%',
        width: { xs: 2 },
        render: GeneratorLoadSharingField,
        type: 'number'
      },
      {
        label: 'Active Current',
        subLabel: 'A',
        key: 'dg_active_current',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Power Factor',
        key: 'dg_power_factor',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Phase Shift',
        key: 'dg_power_factor_phase_shift',
        width: { xs: 2 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-phase-shift',
          isAsync: false
        }
      },
      {
        label: 'SFOC',
        key: 'dg_sfoc',
        subLabel: 'gr/kWh',
        type: 'number',
        width: { xs: 3 },
        render: GeneratorSfoc
      }
    ]
  },
  {
    header: 'FUEL OIL INLET',
    customComponent: GeneratorsOilAndAir,
    extraFields: () => [config()[2], config()[3]],
    fields: [
      {
        label: 'Pressure',
        key: 'dg_fo_inlet_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Temperature',
        key: 'dg_inlet_temperature',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'SCAVENGE AIR',
    customComponent: () => <div />,
    fields: [
      {
        label: 'Pressure',
        key: 'dg_scavenge_air_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Temperature',
        key: 'dg_scavenge_air_temperature',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'LUB OIL',
    customComponent: () => <div />,
    fields: [
      {
        label: 'lo pressure',
        key: 'dg_lub_oil_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'lo consumption',
        key: 'dg_lub_oil_consumption_total',
        subLabel: 'L',
        width: { xs: '' },
        render: GeneratorLoConsumptionField,
        type: 'number'
      },
      {
        label: 'lo consumption rate',
        key: 'dg_lub_oil_consumption_rate',
        className: 'd-none',
        width: { xs: '' },
        render: () => null,
        type: 'number'
      },
      {
        label: 'lo feed rate',
        key: 'dg_lub_oil_feed_rate',
        subLabel: 'g/kWh',
        width: { xs: 3 },
        render: GeneratorLoFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'ex_gas_temps',
    subGroupHeaderComponent: GeneratorsHeaderExhaustGas,
    subGroupInline: true,
    fields: [
      {
        label: 'Exhaust Gas Temperature',
        subLabel: '°C',
        key: 'dg_exhaust_gas_temperature',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Ltcw pressure',
        subLabel: 'bar',
        key: 'lt_cw_pump_p',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Hltcw pressure',
        subLabel: 'bar',
        key: 'dg_htcw_pump_pressure',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Air Cooler inlet water Temp',
        subLabel: '°C',
        key: 'dg_air_cooler_in_temperature',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Jacket CW In Temperature',
        subLabel: '°C',
        key: 'dg_jacket_cool_water_in_temperature',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Jacket CW out Temperature',
        subLabel: '°C',
        key: 'dg_jacket_cool_water_out_temperature',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: (
      <span className="fw-normal">
        <strong>TURBOCHARGER</strong> - EXHAUST GAS TEMPERATURE
      </span>
    ),
    fields: [
      {
        label: 'In',
        key: 'dg_tc_inlet_max_temperature',
        subLabel: '°C',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Out',
        key: 'dg_tc_outlet_max_temperature',
        subLabel: '°C',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const generatorsConfig = {
  noon: data => config(data),
  arrival: data => config(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => config(data),
  port_noon: data => config(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default generatorsConfig;
