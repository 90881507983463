import warningIcon from 'common/assets/svg/common/warning-icon.svg';
import errorInCircleSolidIcon from 'common/assets/svg/common/error-in-circle-solid.svg';

export const getWarningStyles = (warning: string): { icon: string; className: string } => {
  switch (warning) {
    case 'max-work-hours-violation-72h':
      return {
        icon: errorInCircleSolidIcon,
        className: 'bg-white-fever text-warning'
      };
    default:
      return {
        icon: warningIcon,
        className: 'bg-coy text-coral'
      };
  }
};
