import { hiddenField, numberField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  items: {
    title: stringField(),
    unit: stringField(),
    rob: numberField(),
    quantity: numberField({ initialValue: null }),
    group: hiddenField(),
    entity_type: hiddenField(),
    id: hiddenField(),
    code: hiddenField(),
    previous_rob: numberField(),
    last_survery_timestamp: hiddenField()
  },
  spare_parts: {
    title: stringField(),
    unit: stringField(),
    quantity: numberField({ initialValue: null }),
    min_quantity: numberField(),
    previous_rob: numberField(),
    location: stringField({ initialValue: null }),
    quantity_new: numberField({ initialValue: null }),
    quantity_used: numberField({ initialValue: null }),
    quantity_in_repair: numberField({ initialValue: null }),
    group: hiddenField(),
    entity_type: hiddenField(),
    id: hiddenField(),
    part_no: hiddenField(),
    drawing_no: hiddenField(),
    last_survery_timestamp: hiddenField()
  }
};

export default config;
