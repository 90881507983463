import {
  selectPost,
  selectPostTitle,
  selectPostContent,
  selectPostPreviousContent,
  isChapterPostFormOpen,
  cannotEditManual,
  selectIsComparedAtVersion,
  selectComparedAtVersionChapterPostContent
} from 'manuals/store/selectors';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import {
  selectChapter,
  selectActiveChapterUid,
  selectIsActiveChapterChapterDeleted,
  selectActiveChapterChangeRequestStatus,
  selectCurrentVersion,
  selectChaptersTreeSearch,
  selectChaptersInSearchLength,
  hasChaptersTreeSearch
} from 'manuals/store/selectors';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import CircledButton from 'common/components/buttons/CircledButton';
import DeletedBanner from './DeletedBanner';
import ChangeRequestPreview from './ChangeRequestPreview';
import FormRelatedHeader from './FormRelatedHeader';
import Forms from './Forms';
import Attachments from './Attachments';
import PreviewPage from './PreviewPage';
import SpecificFor from './SpecificFor';
import documents from 'common/assets/svg/common/documents.svg';
import attachment from 'common/assets/svg/common/attachment-no-rotation.svg';

import { getHTMLDiff } from './_helpers';
import { highlightTextInHtml } from 'manuals/_helpers';

import * as manualsActions from 'manuals/store/actions';
import ChapterTitle from 'manuals/manual-inner/chapters-tree/main/ChapterTitle';

const Preview = ({ changeRequestActions, postActions }) => {
  const [togglePostForm] = useActions([manualsActions.togglePostForm]);

  const currentVersion = useSelector(selectCurrentVersion);

  const activeUid = useSelector(selectActiveChapterUid);

  const chapter = useSelector(state => selectChapter(state, activeUid));
  const isLocked = useSelector(cannotEditManual);
  const isGettingDeleted = useSelector(selectIsActiveChapterChapterDeleted);

  const changeRequestStatus = useSelector(selectActiveChapterChangeRequestStatus);
  const searchValue = useSelector(selectChaptersTreeSearch);

  const isFormOpen = useSelector(isChapterPostFormOpen);
  const post = useSelector(selectPost);
  const title = useSelector(selectPostTitle);
  const content = useSelector(selectPostContent);
  const prevContent = useSelector(selectPostPreviousContent);

  const chaptersLength = useSelector(selectChaptersInSearchLength);
  const hasSearch = useSelector(hasChaptersTreeSearch);

  const comparedAtPostContent = useSelector(selectComparedAtVersionChapterPostContent);
  const isComparedAtVersion = useSelector(selectIsComparedAtVersion);

  const isWorkingVersion = currentVersion.status === 'working';
  const [contentHtml, setContentHtml] = useState(content);
  const [diffHtml, setDiffHtml] = useState('');
  const [pagesCount, setPagesCount] = useState(1);

  useEffect(() => {
    if (content !== contentHtml) setContentHtml(content);
  }, [content]);

  useEffect(() => {
    if (isWorkingVersion && !isComparedAtVersion && prevContent && contentHtml) {
      setDiffHtml(getHTMLDiff(prevContent, contentHtml));
    }
  }, [contentHtml, isComparedAtVersion]);

  useEffect(() => {
    if (isComparedAtVersion && !isWorkingVersion && comparedAtPostContent) {
      setDiffHtml(getHTMLDiff(comparedAtPostContent, contentHtml));
    } else if (!isWorkingVersion && !isComparedAtVersion) {
      setDiffHtml('');
    }
  }, [isComparedAtVersion, comparedAtPostContent, isWorkingVersion]);

  useEffect(() => {
    if (hasSearch) {
      setContentHtml(highlightTextInHtml(content, searchValue));
    }
  }, [hasSearch, chaptersLength, activeUid]);

  useUpdateEffect(() => {
    if (!hasSearch) {
      setContentHtml(content);
    }
  }, [hasSearch]);

  if (isFormOpen || !post || !chapter) return null;

  return (
    <div className="chapter-content__wrapper">
      {!isLocked &&
      !isGettingDeleted &&
      changeRequestStatus !== 'approved' &&
      !isComparedAtVersion ? (
        <CircledButton
          className="chapter-content__wrapper-edit"
          type="edit"
          svgStyle={{ width: 12, height: 12 }}
          onClick={() => togglePostForm(true)}
        />
      ) : null}
      <div className="chapter-content__preview d-flex flex-column gray-scrollbar">
        <div className="chapter-content__preview-title d-flex flex-column align-items-start">
          <div className="d-flex align-items-start flex-nowrap w-100p">
            <div className="d-flex align-items-center flex-nowrap flex-1">
              {['working', 'pending_approval'].includes(currentVersion.status) ? (
                <ChangeRequestPreview
                  chapter={chapter}
                  currentVersionStatus={currentVersion.status}
                />
              ) : null}
              <div className="chapter-content__preview-title-inner">
                <ChapterTitle
                  chapter={chapter}
                  titleClassName="fs-16 fw-bold"
                  subTitle={title}
                  isInContent
                />
                <SpecificFor />
              </div>
            </div>

            {/* <div className="d-flex align-items-center">
            <Information
              message="Total Pages"
              svgStyle={{ width: 16, height: 16 }}
              className={'text-dark cme-10'}
            />
            <div className="fs-12 text-dark">
              Total Pages: <strong>{pagesCount}</strong>
            </div>
          </div> */}
          </div>

          <div className="separator"></div>
        </div>
        <PreviewPage
          content={diffHtml ? diffHtml : contentHtml}
          pagesCount={pagesCount}
          setPagesCount={setPagesCount}
        />

        <FormRelatedHeader icon={documents} text="Forms related to this section" />
        <Forms />

        <FormRelatedHeader icon={attachment} text="Attachments" className="mt-8" />
        <Attachments postActions={postActions} />

        <DeletedBanner />
        {changeRequestActions ? changeRequestActions : null}
      </div>
    </div>
  );
};

export default Preview;
