import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_JOB_TEMPLATE_LABEL: 'SET_JOB_TEMPLATE_LABEL',
  UPDATE_FLEET_TEMPLATE_DATA: 'UPDATE_FLEET_TEMPLATE_DATA',
  ADD_OPEN_VESSEL: 'ADD_OPEN_VESSEL',
  REMOVE_OPEN_VESSEL: 'REMOVE_OPEN_VESSEL',
  SET_OPEN_VESSELS: 'SET_OPEN_VESSELS',
  RESET_JOB_TEMPLATE_DATA: 'RESET_JOB_TEMPLATE_DATA',
  ...asyncTypes('UPDATE_JOB_TEMPLATE_LABEL'),
  ...asyncTypes('GET_TEMPLATE_LABELS'),
  ...asyncTypes('GET_JOB_TEMPLATE_DATA')
};

export default TYPES;
