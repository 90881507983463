import React from 'react';
import PropTypes from 'prop-types';
import dnvCertified from 'common/assets/png/dnv-certifications.png';

const CertificationBanner = ({ text }) => {
  return (
    <div className="sidebar-menu--certified-container mt-auto px-2 mb-6">
      <div className="sidebar-menu--certified-separator"></div>
      <div className="sidebar-menu--certified-item mt-2  border-radius-5 py-1 w-100p text-center">
        <img alt="satellite view" src={dnvCertified} width={64} height={64} />
        <div className="text-sail-away fs-12">{text}</div>
      </div>
    </div>
  );
};

CertificationBanner.propTypes = {
  text: PropTypes.string
};

export default CertificationBanner;
