import { Row, Col } from 'reactstrap';
import { useCallback, useMemo, useState, useEffect } from 'react';
import PageTitle from 'common/components/general/PageTitle';
import NavTabs from 'common/components/mga/components/NavTabs';
import Period from 'common/components/mga/components/period';
import Actions from 'common/components/mga/components/actions';
import DeleteActionModal from 'common/components/mga/components/actions/DeleteActionModal';
import useRouter from 'use-react-router';

import ActionsView from 'common/components/mga/views/Actions';
import AccountsView from 'common/components/mga/views/accounts';
import OverviewView from 'common/components/mga/views/overview';
import InventoryView from 'common/components/mga/views/inventory';
import ReportsView from 'common/components/mga/views/reports';

import { selectLatestPeriod, selectIsPastMga } from 'common/components/mga/store/selectors';
import { selectListOptionsFromStore } from '@/store/lists/selectors-ts';
import { useAppSelector } from '@/store/hooks';
import { useActions } from 'utils/hooks';
import * as listActions from 'store/lists/actions';
import * as mgaActions from 'common/components/mga/store/actions';

const Mga = () => {
  const { match } = useRouter();
  const latestPeriod = useAppSelector(selectLatestPeriod);
  const isPastMga = useAppSelector(selectIsPastMga);
  const actionTypes = useAppSelector(state =>
    selectListOptionsFromStore(state, 'mga-action-types')
  );

  const [initialized, setInitialized] = useState(false);
  const [fetchListOptions, getMgaSettings] = useActions([
    listActions.fetchListOptions,
    mgaActions.getMgaSettings
  ]);

  const View = useMemo(() => {
    switch (match.params.view) {
      case 'accounts':
        return AccountsView;
      case 'actions':
        return ActionsView;
      case 'inventory':
        return InventoryView;
      case 'reports':
        return ReportsView;
      default:
        return OverviewView;
    }
  }, [match.params.view]);

  const fetchInitialLists = useCallback(async () => {
    setInitialized(false);

    try {
      await fetchListOptions('mga-action-types');
      await fetchListOptions('mga-period-statuses');
      await getMgaSettings();

      setInitialized(true);
    } catch {
      setInitialized(true);
    }
  }, [fetchListOptions, getMgaSettings]);

  useEffect(() => {
    fetchInitialLists();
  }, [fetchInitialLists]);

  return (
    <div className="mga">
      <Row className="align-items-center mb-4">
        <Col xs="auto">
          <PageTitle
            className="mb-0"
            title="MGA"
            render={() => (
              <div className="d-flex align-items-center">
                <NavTabs />

                <div className="border-start ms-3 ps-3">
                  {isPastMga ? 'Past' : ''} MGA /{' '}
                  <strong className="text-capitalize">{match.params.view}</strong>
                </div>
              </div>
            )}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Actions />
        </Col>
      </Row>

      {latestPeriod && initialized && actionTypes?.length ? (
        <View
          key={
            match.params.view === 'accounts'
              ? `${match.params.view}_${latestPeriod?.id}`
              : match.params.view
          }
        />
      ) : null}
      <Period />
      <DeleteActionModal />
    </div>
  );
};

export default Mga;
