import React, { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import TableMain from './main';
import TableHeader from './header';
import TableTop from './TableTop';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import tableHook from './utils/useTable';

import PageLoader from '../general/PageLoader';

import {
  TopType,
  ColumnsType,
  DefaultRequestParamsType,
  PaginationLimitType
} from 'common/entities/tables/TableTypes';
import { getVisibleColumns } from './utils/helpers';
import { TableProps } from '@/common/types/front-entities/table';
import { useAppSelector } from '@/store/hooks';

const Table: FC<TableProps> = ({
  label,
  columns,
  rows,
  getRowClassName,
  getRowId,
  archivedLabel,
  hiddenLabel,
  drawer,
  onArchive,
  onUnarchive,
  top,
  topFiltersComponent,
  topCustomComponent,
  emptyStateComponent,
  mainHeaderComponent,
  loader = true,
  defaultRequestParams,
  refetchData,
  showArchived = false,
  showHidden = false,
  requestParams,
  // setRequestParams,
  state,
  fetching,
  filters,
  setFilters,
  topFilters,
  setTopFilters,
  handleTableSort,
  handleTableSearch,
  handleTableApplyFilters,
  customColumnsReorder,
  excludedFilters,
  onDelete,
  downLoadExtension,
  downloadText,
  isAsyncTaskProccessing,
  onDownload,
  paginationLimits = {},
  scrollToTopAfterPageChanged = true,
  hideTableFilters = false,
  hideTableReorder = false,
  hideTableSearch = false,
  hideTableHead = false,
  hideTopPagination = false,
  hideBottomPagination = false,
  onCellDoubleClick = () => null,
  showEmptyState,
  isRowSelectable,
  selectedRowsLabel,
  grayFiltersBg,
  grayBg = false,
  solidTotalBarKeys = [],
  highlightColumnOnHover = false,
  innerFiltersComponent,
  hideSelectableRowsHeader = false,
  isModularTableShownInWidget = false
}) => {
  const [dangerousModal, setDangerousModal] = useState({ isOpen: null });
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const filteredCols = useMemo(
    () => (columns || []).filter(column => (column.hiddenOnBoard && isOnBoard ? false : column)),
    [columns, isOnBoard]
  );

  return (
    <div className={`app-table ${label ? `app-table__${label}` : ''} ${grayBg ? 'gray-bg' : ''} `}>
      <TableTop
        archivedLabel={archivedLabel}
        hiddenLabel={hiddenLabel}
        top={top}
        topFiltersComponent={topFiltersComponent}
        topCustomComponent={topCustomComponent}
        defaultRequestParams={defaultRequestParams}
        refetchData={refetchData}
        showArchived={showArchived}
        showHidden={showHidden}
        state={state}
        fetching={fetching}
        filters={filters}
        setFilters={setFilters}
        topFilters={topFilters}
        setTopFilters={setTopFilters}
        downLoadExtension={downLoadExtension}
        downloadText={downloadText}
        isAsyncTaskProccessing={isAsyncTaskProccessing}
        onDownload={onDownload}
        hideTopPagination={hideTopPagination}
        scrollToTopAfterPageChanged={scrollToTopAfterPageChanged}
      />

      {innerFiltersComponent ? (
        <div className="app-table__top-inner-filters bg-white">{innerFiltersComponent}</div>
      ) : null}

      {!hideTableFilters || (hideTableFilters && !hideTableSearch) ? (
        <TableHeader
          state={state}
          columns={hideTableFilters ? [] : filteredCols}
          filters={hideTableFilters ? [] : filters}
          setFilters={setFilters}
          topFilters={topFilters}
          fetchData={refetchData}
          label={label}
          customColumnsReorder={customColumnsReorder}
          excludedFilters={excludedFilters}
          hideTableSearch={hideTableSearch}
          hideTableReorder={hideTableReorder || hideTableFilters}
          handleTableSearch={handleTableSearch}
          handleTableApplyFilters={handleTableApplyFilters}
          grayFiltersBg={grayFiltersBg}
        />
      ) : null}

      <TableMain
        filters={filters}
        state={state}
        label={label}
        setDangerousModal={setDangerousModal}
        columns={getVisibleColumns({ requestParams, columns: filteredCols })}
        rows={rows}
        getRowClassName={getRowClassName}
        getRowId={getRowId}
        fetchData={refetchData}
        handleTableSort={handleTableSort}
        paginationLimits={paginationLimits}
        mainHeaderComponent={mainHeaderComponent}
        hideTableFilters={hideTableFilters}
        hideTopPagination={hideTopPagination}
        hideBottomPagination={hideBottomPagination}
        onCellDoubleClick={onCellDoubleClick}
        hideTableHead={hideTableHead}
        isRowSelectable={isRowSelectable}
        selectedRowsLabel={selectedRowsLabel}
        solidTotalBarKeys={solidTotalBarKeys}
        highlightColumnOnHover={highlightColumnOnHover}
        emptyStateComponent={emptyStateComponent}
        showEmptyState={showEmptyState}
        hideSelectableRowsHeader={hideSelectableRowsHeader}
        isModularTableShownInWidget={isModularTableShownInWidget}
        scrollToTopAfterPageChanged={scrollToTopAfterPageChanged}
      />

      <PageLoader isLoading={fetching && loader} />

      {drawer ? React.cloneElement(drawer, { refetchData }) : null}

      <DangerousActionModal
        transparent
        action={
          dangerousModal?.action
            ? dangerousModal?.action
            : dangerousModal.isArchived
            ? 'unarchive'
            : 'archive'
        }
        actionHoverColor={dangerousModal?.actionHoverColor || 'primary'}
        onAccept={async () => {
          if (dangerousModal?.onAccept) {
            dangerousModal?.onAccept();
            return;
          }

          dangerousModal.isArchived
            ? await onUnarchive(dangerousModal.params)
            : await onArchive(dangerousModal.params);
          refetchData();
        }}
        closeModal={() => setDangerousModal({ isOpen: false })}
        isOpen={dangerousModal.isOpen}
        actionText={dangerousModal.actionText ? dangerousModal.actionText.toUpperCase() : ''}
        header={dangerousModal.header}
        body={dangerousModal.body}
      />

      {onDelete ? (
        <DangerousActionModal
          transparent
          action={'delete'}
          onAccept={async () => {
            await onDelete(dangerousModal.params);
            refetchData();
          }}
          closeModal={() => setDangerousModal({ isOpen: false })}
          isOpen={dangerousModal.isOpen}
          actionText={dangerousModal.actionText ?? 'DELETE'}
          header={dangerousModal.header}
          body={dangerousModal.body}
        />
      ) : null}
    </div>
  );
};

/* Use table hook 'useTable' to init table props */

Table.propTypes = {
  label: PropTypes.string.isRequired,
  columns: ColumnsType,
  rows: PropTypes.object, // The object keys are the key of each column
  hideTableFilters: PropTypes.bool, // Will hide the table main filters. However, if there are any filter search params, they will still filter the table and passed as request params to API.
  hideTableReorder: PropTypes.bool, // Will hide the reorder columns functionality
  hideTableSearch: PropTypes.bool, // Will hide the table search input
  hideTableHead: PropTypes.bool, // Will hide the table header cells,
  customColumnsReorder: PropTypes.element, // Use this to rerder your own columns reoder component
  defaultRequestParams: DefaultRequestParamsType,
  topFiltersComponent: PropTypes.element, // Component to render
  topCustomComponent: PropTypes.element,
  top: TopType,
  drawer: PropTypes.element, // Drawer Component
  onArchive: PropTypes.func,
  whiteBg: PropTypes.bool, // Use this prop to have a white bg on the table
  grayBg: PropTypes.bool, // Use this prop to have a smaller table with gray rows
  /* Pass the 'excludedFilters' prop in the useTable hook or in the <Table /> in order to exlclude some filters from the Filters UI Bar. 
  However, the exlculded filters will have exactly the same behavior as any other filter. */
  excludedFilters: PropTypes.array,
  hideTopPagination: PropTypes.bool, // Use to hide table top pagination
  paginationLimits: PaginationLimitType,
  scrollToTopAfterPageChanged: PropTypes.bool, // Prevent scrolling to top of the page after changing the pagination
  handleTableSort: PropTypes.func, // Override the 'sorting param in URL' functionality
  handleTableSearch: PropTypes.func, // Override the 'search param in URL' functionality
  handleTableApplyFilters: PropTypes.func, // Override the 'filters param in URL' functionality
  onCellDoubleClick: PropTypes.func,
  solidTotalBarKeys: PropTypes.array,
  getRowId: PropTypes.func,
  showEmptyState: PropTypes.bool,
  getRowClassName: PropTypes.func,
  hiddenLabel: PropTypes.string,
  showHidden: PropTypes.func,
  taskUuid: PropTypes.string,
  isAsyncTaskProccessing: PropTypes.bool,
  isRowSelectable: PropTypes.func, // whether or not a row is selectable
  selectedRowsLabel: PropTypes.string, // Reffering to message: `*Click on the prefered rows to select ${selectedRowsLabel}`
  grayFiltersBg: PropTypes.bool, // Have a grayBg in Filters,
  emptyStateComponent: PropTypes.element, // Component to show when there are no data,
  isModularTableShownInWidget: PropTypes.bool, // defines if the table is on widget page and is using ModularTable ,we use it to access the data from another places than the ordinary,
  hideSelectableRowsHeader: PropTypes.bool // overides the default selectable table design format
};

export const useTable = tableHook;

export default Table;
