import TYPES from './types';
import { get, put } from 'utils/api';
import { updateJobInTable, setJobField } from 'common/components/jobs/_base/store/actions';

export const getJobLinkedJobs = params => dispatch => {
  const { job_id } = params;
  dispatch({ type: TYPES.GET_JOB_LINKED_JOBS.START, payload: { params } });

  return get(`/jobs/${job_id}/linked`)
    .then(response => {
      const linked_jobs = response.data.map(linked => {
        const { title, id, importance_id, status_id, link_type } = linked;

        return {
          job: { title, id, importance_id, status_id },
          type: link_type
        };
      });

      dispatch({ type: TYPES.GET_JOB_LINKED_JOBS.SUCCESS, payload: linked_jobs });

      return linked_jobs;
    })
    .catch(error => dispatch({ type: TYPES.GET_JOB_LINKED_JOBS.ERROR, payload: error }));
};

export const updateJobLinkedJobs = params => dispatch => {
  const { job_id, ...rest } = params;

  dispatch(setJobField('has_linked_jobs', rest.linked_jobs?.length ? true : false));

  dispatch({ type: TYPES.UPDATE_JOB_LINKED_JOBS.START, payload: { params } });

  return put(`/jobs/${job_id}/linked`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_JOB_LINKED_JOBS.SUCCESS, payload: response.data });
      const tableData = {
        id: job_id,
        has_linked_jobs: rest.linked_jobs.length ? true : false
      };

      dispatch(updateJobInTable(tableData));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_JOB_LINKED_JOBS.ERROR, payload: error }));
};

export const setLinkedJobs = options => dispatch => {
  dispatch({ type: TYPES.SET_LINKED_JOBS, payload: options });
  dispatch(setJobField('has_linked_jobs', options?.length > 0));
};
