import { createSelector } from 'reselect';

const selectAsyncFiltersReducer = state => state.asyncFilters;
const getListKey = (_, key) => key;
const getFilterKey = (_, __, key) => key;

export const selectListAsyncFilters = createSelector(
  selectAsyncFiltersReducer,
  getListKey,
  (asyncFilters, list_key) => asyncFilters?.[list_key]
);

export const selectListAsyncFilterValue = createSelector(
  selectListAsyncFilters,
  getFilterKey,
  (list, filter_key) => list?.[filter_key]
);
