import PartyBankAccountsSelector from 'common/components/selectors/PartyBankAccountsSelector';

const BankAccountField = ({ fields, selectField }) => {
  const key = fields.action_type.value?.debit_type === 'crew' ? 'debit_account' : 'credit_account';

  return fields.action_type.value?.is_bank_account_selection_enabled ? (
    <div className="mb-2">
      <div className="form-label">BANK ACCOUNT</div>

      <PartyBankAccountsSelector
        listParams={{ party_id: fields[key]?.value?.crew_member_id }}
        className="mb-1"
        defaultOptionsTriggerChange={fields[key]?.value?.crew_member_id}
        disabled={!fields[key]?.value?.crew_member_id}
        isMulti={false}
        onChange={selectField('bank_account')}
        value={fields?.bank_account?.value}
      />
    </div>
  ) : null;
};

export default BankAccountField;
