const tempMemoryElements = {
  'custom.area': {
    shape: 'area'
  },
  'custom.path': {
    shape: 'path'
  },
  'custom.circle': {
    shape: 'circle'
  },
  'custom.polygon': {
    shape: 'polygon'
  },
  'custom.dash': {
    shape: 'dash'
  }
};

const fixNum = num => Math.floor(+num);

const renderDash = (context, node) => {
  const x = fixNum(node.getAttribute('x'));
  const y = fixNum(node.getAttribute('y'));

  const dashWidth = +node.getAttribute('dash-width');

  // Centered line [x - (dashWidth / 2), x + (dashWidth / 2)]
  const startX = x - dashWidth / 2;
  const endX = x + dashWidth / 2;

  context.moveTo(startX, y);
  context.lineTo(endX, y);

  context.closePath();

  const strokeWidth = fixNum(node.getAttribute('stroke-width'));

  context.lineWidth = strokeWidth;

  if (strokeWidth) context.stroke();
};

const renderCircle = (context, node) => {
  const strokeWidth = fixNum(node.getAttribute('stroke-width'));
  context.arc(
    fixNum(node.getAttribute('x')),
    fixNum(node.getAttribute('y')),
    fixNum(node.getAttribute('radius')),
    0,
    2 * Math.PI
  );
  context.fill();
  if (strokeWidth) context.stroke();
};

const renderPath = (context, node) => {
  const path = new Path2D(node.getAttribute('d'));
  const strokeWidth = fixNum(node.getAttribute('stroke-width'));
  const strokeDashArray = node.getAttribute('stroke-dasharray')?.split(',');

  context.lineWidth = strokeWidth;
  if (strokeDashArray) context.setLineDash(strokeDashArray);

  if (strokeWidth) context.stroke(path);
  context.setLineDash([0, 0]);
};

const renderArea = (context, node) => {
  const path = new Path2D(node.getAttribute('d'));
  const strokeWidth = +node.getAttribute('stroke-width');

  context.lineWidth = strokeWidth;

  if (strokeWidth) context.stroke(path);
  context.fill(path);
};

const renderPolygon = (context, node) => {
  const points = node.getAttribute('points').split(' ');
  const polygonX = fixNum(node.getAttribute('x'));
  const polygonY = fixNum(node.getAttribute('y'));
  const strokeWidth = +node.getAttribute('stroke-width');

  points.forEach((point, i) => {
    const [x, y] = point.split(',');

    if (i === 0) {
      context.moveTo(fixNum(x) + polygonX, fixNum(y) + polygonY);
    } else {
      context.lineTo(fixNum(x) + polygonX, fixNum(y) + polygonY);
    }
  });

  context.closePath();

  if (strokeWidth) context.stroke();
  context.fill();
};

const rotateElement = (context, node) => {
  const x = fixNum(node.getAttribute('x'));
  const y = fixNum(node.getAttribute('y'));
  const rotationAngleInDeg = fixNum(node.getAttribute('rotation'));

  // If zero do nothing
  if (rotationAngleInDeg) {
    context.translate(x, y);
    context.rotate(rotationAngleInDeg * (Math.PI / 180));
    context.translate(-x, -y);
  }
};

const resetRotation = (context, originalScaling = 1) => {
  // Reset transformation matrix to the identity matrix
  context.setTransform(originalScaling, 0, 0, originalScaling, 0, 0);
};

const renderElement = (context, elementGroup, node, shouldBeFaded, originalScaling) => {
  const elementInfo = tempMemoryElements[elementGroup];
  const opacity = +(node.getAttribute('opacity') || 1);

  context.beginPath();

  context.lineCap = 'round';
  context.lineJoin = 'round';

  context.strokeStyle = node.getAttribute('stroke');
  context.fillStyle = node.getAttribute('fill');
  context.lineWidth = node.getAttribute('stroke-width');
  context.globalAlpha = shouldBeFaded ? opacity / 2 : opacity;

  rotateElement(context, node);

  switch (elementInfo.shape) {
    case 'circle':
      renderCircle(context, node);
      break;
    case 'path':
      renderPath(context, node);
      break;
    case 'area':
      renderArea(context, node);
      break;
    case 'polygon':
      renderPolygon(context, node);
      break;
    case 'dash':
      renderDash(context, node);
      break;
    default:
      break;
  }

  resetRotation(context, originalScaling);

  context.globalAlpha = 1;
  context.closePath();
};

export const clipCanvasToInnerDimensions = (context, dimensions) => {
  if (dimensions === {}) return;

  const { innerWidth, innerHeight, leftOffset, topOffset } = dimensions;

  if (!innerWidth || !innerHeight) return;

  context.beginPath();
  context.moveTo(0, 0);
  context.rect(fixNum(leftOffset), fixNum(topOffset), fixNum(innerWidth), fixNum(innerHeight));
  context.clip();
  context.closePath();
};

export const sortByYVal = (a, b) => {
  const aYVal = +a.getAttribute('y-value');
  const bYVal = +b.getAttribute('y-value');

  return bYVal - aYVal;
};

export const renderD3ElementsToCanvas = (
  context,
  memoryElement,
  disabledPlots,
  dimensions,
  width,
  height,
  originalScaling
) => {
  if (context && memoryElement) {
    const elementGroups = Object.keys(tempMemoryElements);

    const hoveringElement = memoryElement.selectAll('.hovering-node');

    // clear canvas
    context.clearRect(0, 0, fixNum(width), fixNum(height));

    clipCanvasToInnerDimensions(context, dimensions);

    elementGroups.forEach(elementGroup => {
      // d3.selectAll from memory by class
      const allElementsInGroup = memoryElement.selectAll(elementGroup);

      Array.prototype.slice
        .call(allElementsInGroup._groups[0], 0)
        .sort(sortByYVal)
        .forEach(element => {
          const plotIndex = element.getAttribute('plot-index');

          // If you're hovering another plot...
          const shouldBeFaded = !hoveringElement.empty()
            ? hoveringElement.attr('plot-index') !== plotIndex
            : false;

          if (!disabledPlots.includes(+plotIndex)) {
            renderElement(context, elementGroup, element, shouldBeFaded, originalScaling);
          }
        });
    });
  }
};
