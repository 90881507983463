import { FC, PropsWithChildren } from 'react';
import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

const Tooltip: FC<PropsWithChildren<UncontrolledTooltipProps>> = ({
  target,
  placement,
  innerClassName = '',
  children,
  ...rest
}) => {
  return (
    <UncontrolledTooltip
      target={target}
      placement={placement ?? 'top'}
      innerClassName={innerClassName ?? 'show-more-tooltip'}
      boundariesElement="window"
      {...rest}
    >
      {children}
    </UncontrolledTooltip>
  );
};

export default Tooltip;
