import React from 'react';

import Field from './Field';
import { useSelector } from 'react-redux';
import {
  selectFieldVisibility,
  selectActiveTab,
  selectFieldTemplateTooltip
} from 'captain-reports/store/selectors';

// Used to render the fields of the mutli sections AND their inner subgroups

const VisibleMultiField = ({ field, label, subGroup, subGroupIndex }) => {
  const visible = useSelector(state => selectFieldVisibility(state, field.key));
  const activeTab = useSelector(state => selectActiveTab(state, label));
  const tooltip = useSelector(selectFieldTemplateTooltip(field.key));

  if (!visible) return null;

  return (
    <Field className={field.className} width={field.width}>
      <field.render
        field={field}
        tooltip={tooltip ? tooltip : ''}
        sectionLabel={label}
        sectionActiveTab={activeTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </Field>
  );
};

export default VisibleMultiField;
