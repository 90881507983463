import { useState, useCallback } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import PropTypes from 'prop-types';

const RemoveTripPerson = ({
  className = '',
  person,
  tripID,
  tripParties,
  onDeleteTripParticipant
}) => {
  const [dangerousModal, setDangerousModal] = useState({ isOpen: false });
  const closeModal = () => setDangerousModal({ isOpen: false });

  const handleDelete = useCallback(
    params => onDeleteTripParticipant({ ...params, tripID }),
    [onDeleteTripParticipant, tripID]
  );

  return tripID && tripParties?.length > 1 && onDeleteTripParticipant !== null ? (
    <div className={`d-flex align-items-center ${className}`}>
      <CircledButton
        type={'remove'}
        onClick={() => setDangerousModal({ isOpen: true })}
        style={{ width: 17, height: 17 }}
        svgStyle={{ width: 6, height: 1 }}
      />

      <DangerousActionModal
        transparent
        onAccept={async () => {
          await handleDelete({ tripID: tripID, id: person.id });
          closeModal();
        }}
        closeModal={() => closeModal()}
        isOpen={dangerousModal.isOpen}
        actionText={'REMOVE'}
        header={'Remove '}
        body={
          <div className="text-center">
            Are you sure you want to remove <strong>{person?.full_name}</strong> from these tickets?
          </div>
        }
      />
    </div>
  ) : null;
};

RemoveTripPerson.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object,
  tripID: PropTypes.number,
  tripParties: PropTypes.func,
  onDeleteTripParticipant: PropTypes.func
};

export default RemoveTripPerson;
