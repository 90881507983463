import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShadowBox from 'common/components/general/ShadowBox';
import history from 'common/assets/svg/common/history.svg';
import SvgRender from 'common/components/general/SvgRender';
import ExpandButton from 'common/components/buttons/ExpandButton';
import { Col, Collapse, Row } from 'reactstrap';
import { displayDate } from 'common/utils/dates';

const Log = ({ text, timestamp }) => {
  return (
    <Row className="text-primary fs-12 align-items-center" noGutters>
      <Col xs="auto" className="pe-1">
        <div className="border rounded-circle " style={{ width: 12, height: 12 }} />
      </Col>
      <Col>
        <ShadowBox flat color="light-1" className="p-1 d-flex align-items-center">
          <div className="flex-1">{text}</div>
          <div className="text-violet">{displayDate(timestamp)}</div>
        </ShadowBox>
      </Col>
    </Row>
  );
};

Log.propTypes = {
  text: PropTypes.node,
  timestamp: PropTypes.string
};

const Logs = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  if (!data?.resolved_at || !data?.resolved_by) return null;

  return (
    <ShadowBox className="cpy-12 cpx-12 mt-2">
      <div className="d-flex align-items-center fs-12 fw-medium text-violet cpb-6">
        <SvgRender src={history} className="me-1" style={{ width: 14, height: 14 }} />
        Logs
        <ExpandButton
          className="fs-12 fw-medium text-violet p-0 ms-auto"
          label={isExpanded ? 'Shrink' : 'Expand'}
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>

      <Collapse isOpen={isExpanded}>
        <div className="border-top cpt-12">
          <Log
            timestamp={data?.resolved_at}
            text={
              <>
                <strong>{data?.resolved_by?.full_name}</strong> updated the resolution date
              </>
            }
          />
        </div>
      </Collapse>
    </ShadowBox>
  );
};

Logs.propTypes = {
  data: PropTypes.shape({
    resolved_at: PropTypes.string,
    resolved_by: PropTypes.shape({
      full_name: PropTypes.string
    })
  })
};

export default Logs;
