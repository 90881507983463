import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import TextWithTooltip from 'common/components/general/TextWithTooltip';

import { highlightTextInTitle } from 'manuals/_helpers';

import {
  selectChaptersTreeSearch,
  selectChaptersInSearchLength,
  hasChaptersTreeSearch
} from 'manuals/store/selectors';

const ChapterTitle = ({ chapter, titleClassName, subTitle, isInContent }) => {
  const [header, setHeader] = useState(chapter.title);
  const [subHeader, setSubHeader] = useState(subTitle);

  const searchValue = useSelector(selectChaptersTreeSearch);
  const chaptersLength = useSelector(selectChaptersInSearchLength);
  const hasSearch = useSelector(hasChaptersTreeSearch);

  useEffect(() => {
    if (hasSearch) {
      setHeader(highlightTextInTitle(chapter.title, searchValue));

      if (subTitle) {
        setSubHeader(highlightTextInTitle(subTitle, searchValue));
      }
    }
  }, [hasSearch, chaptersLength]);

  useUpdateEffect(() => {
    if (!hasSearch) {
      setHeader(chapter.title);

      if (subTitle) setSubHeader(subTitle);
    }
  }, [hasSearch]);

  return (
    <>
      {isInContent ? (
        <h1 className={`${titleClassName} mb-0 mt-0`}>{header || chapter?.title}</h1>
      ) : (
        <div className="overflow-hidden">
          <TextWithTooltip className={`${titleClassName} mb-0 mt-0`}>
            {header || chapter?.title}
          </TextWithTooltip>
        </div>
      )}
      {subTitle ? <h2 className="fs-14 mt-1 mb-0 fw-normal">{subHeader || subTitle}</h2> : null}
    </>
  );
};

export default ChapterTitle;
