import PropTypes from 'prop-types';

import { useModularTable } from 'common/components/ModularTable';
import { getInventoryTableData } from 'common/components/inventory/store/actions';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { itemsColumns } from './tableConfig';
import config from '@/common/components/shared-table-configs/index.ts';

const INVENTORY_ITEMS_LABEL = 'inventory_items';
const INVENTORY_SPARE_PARTS_LABEL = 'inventory_spare_parts';

const useInventoryTable = ({ type }) => {
  const inventoryOnPms = useMemo(() => type === 'pms', [type]);
  const dispatch = useDispatch();

  const refetchTable = async params => {
    if (inventoryOnPms && params.table === INVENTORY_ITEMS_LABEL) return;
    if (!inventoryOnPms && params.table === INVENTORY_SPARE_PARTS_LABEL) return;

    if (inventoryOnPms) params.for_survey = true;

    return await dispatch(getInventoryTableData(params));
  };

  const sparePartsColumns = config[INVENTORY_SPARE_PARTS_LABEL].getColumns();
  const sparePartsConfigTopFilters = config[INVENTORY_SPARE_PARTS_LABEL].getTopFilters();

  const {
    topFilters: sparePartsTopFilters,
    setTopFilters: sparePartsSetTopFilters,
    fetching: sparePartsFetching,
    label: sparePartsLabel,
    refetchData: sparePartsRefetchData,
    filters: sparePartsFilters,
    setFilters: setSparePartsFilters
  } = useModularTable({
    requestTableListFrom: refetchTable,
    defaultRequestParams: { visible: false, paging: true, sorting: false, filters: true },
    config: () => ({ columns: sparePartsColumns }),
    label: INVENTORY_SPARE_PARTS_LABEL,
    top: {
      filters: sparePartsConfigTopFilters
    }
  });

  const {
    topFilters: itemsTopFilters,
    setTopFilters: itemsSetTopFilters,
    fetching: itemsFetching,
    label: itemsLabel,
    refetchData: itemsRefetchData
  } = useModularTable({
    requestTableListFrom: refetchTable,
    defaultRequestParams: { visible: false, paging: true, sorting: false, filters: true },
    config: () => ({ columns: itemsColumns }),
    label: INVENTORY_ITEMS_LABEL,
    top: {
      filters: [
        {
          name: 'vessel_id',
          operation: 'oneOf',
          value: null,
          initialValue: null
        }
        // {
        //   name: 'store_group_id',
        //   operation: 'oneOf',
        //   value: null,
        //   initialValue: null
        // }
      ]
    }
  });

  if (inventoryOnPms) {
    return {
      topFilters: sparePartsTopFilters,
      setTopFilters: sparePartsSetTopFilters,
      fetching: sparePartsFetching,
      label: sparePartsLabel,
      refetchData: sparePartsRefetchData,
      filters: sparePartsFilters,
      setFilters: setSparePartsFilters
    };
  }

  return {
    topFilters: itemsTopFilters,
    setTopFilters: itemsSetTopFilters,
    fetching: itemsFetching,
    label: itemsLabel,
    refetchData: itemsRefetchData
  };
};

useInventoryTable.propTypes = {
  type: PropTypes.oneOf(['pms', 'purchasing']).isRequired
};

export default useInventoryTable;
