import React from 'react';

import ProfileImage from 'crew/components/ProfileImage';

import { selectCrewProfile } from 'crew/store/profile/selectors';
import { useSelector } from 'react-redux';

import _get from 'lodash/get';

const BasicInfo = ({ components, crewActions }) => {
  const crew = useSelector(selectCrewProfile);

  return (
    <div className="crew-sidebar__basic d-flex flex-column align-items-center">
      <ProfileImage crew={crew} onUpdate={crewActions?.update} />

      <div className="mb-1 w-100p text-center d-flex justify-content-center align-items-center flex-wrap text-primary fs-12 lh-15 fw-medium crew-sidebar__basic--name">
        {crew.full_name} - {crew.id}
      </div>

      <div className="text-purple  text-center fs-10 lh-12">
        <strong>{_get(crew, 'rank.name', null)}</strong>
      </div>

      {components.CrewStatus ? (
        <components.CrewStatus
          className="cmt-12"
          vessel={crew && crew?.current_status_vessel ? crew.current_status_vessel : null}
          status={_get(crew, 'status.label', null)}
          date={_get(crew, 'boarding_date.date', null)}
          future={_get(crew, 'boarding_date.future', null)}
        />
      ) : null}
    </div>
  );
};

export default BasicInfo;
