import { combineReducers } from 'redux';
import profile from 'crew/store/profile/reducer';
import documents from 'crew/store/documents/reducer';
import evaluations from 'crew/store/evaluations/index';
import contracts from 'crew/store/contracts/reducer';

export default combineReducers({
  profile,
  documents,
  evaluations,
  contracts
});
