import SvgRender from 'common/components/general/SvgRender';
import binIcon from 'common/assets/svg/actions/delete.svg';

import Cell from './Cell';

const RemoveRowButton = ({ removeTableRow, index, heading, isLast, isFirst }) => {
  return (
    <Cell className="sm d-flex align-items-center justify-content-end">
      {!heading && isLast && !isFirst ? (
        <div className="text-red cursor-pointer" onClick={() => removeTableRow(index)}>
          <SvgRender
            src={binIcon}
            style={{ width: 16, height: 16 }}
            className="pointer-events-none"
          />
        </div>
      ) : null}
    </Cell>
  );
};

export default RemoveRowButton;
