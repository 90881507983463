import PropTypes from 'prop-types';

export const TemplateFieldType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  type: PropTypes.string,
  render: PropTypes.func,
  groupedBox: PropTypes.string,
  subLabel: PropTypes.string,
  labelClassName: PropTypes.string
});

export const TemplateSectionField = PropTypes.shape({
  field: TemplateFieldType.isRequired,
  sectionLabel: PropTypes.string,
  sectionActiveTab: PropTypes.number,
  subGroup: PropTypes.string.isRequired,
  subGroupIndex: PropTypes.number.isRequired
});
