import { createSelector } from 'reselect';

const selectrunningHoursReducer = state => state.systemRunningHours;

const selectIsOnboard = state => state.isOnBoard;
// const getId = (_, id) => id;

export const selectLoading = createSelector(selectrunningHoursReducer, report => {
  return report.loading;
});

export const selectData = createSelector(selectrunningHoursReducer, report => {
  return report.data;
});

export const selectStatus = createSelector(selectrunningHoursReducer, report => {
  return report.data?.status;
});

export const selectReviewedByTechData = createSelector(selectrunningHoursReducer, report => {
  return {
    reviewed_by_tech: report.data?.reviewed_by_tech,
    is_reviewed_by_tech: report.data?.is_reviewed_by_tech,
    reviewed_by_tech_at: report.data?.reviewed_by_tech_at
  };
});

export const selectCaptainReportComments = createSelector(selectrunningHoursReducer, report => {
  return report.data?.rebuild_comments;
});

export const selectId = createSelector(selectData, data => {
  return data?.id;
});

export const selectErrors = createSelector(selectrunningHoursReducer, report => {
  return report.errors;
});

export const isReportLocked = createSelector(selectData, selectIsOnboard, (report, isOnBoard) => {
  const officeLockedStatuses = ['approved', 'rebuild', 'draft'];
  const onBoardLockedStatuses = ['approved', 'pending'];

  if (isOnBoard) {
    return onBoardLockedStatuses.indexOf(report.status) !== -1 ? true : false;
  } else {
    if (report.status === 'approved' && report.isEditting) {
      return false;
    }

    return officeLockedStatuses.indexOf(report.status) !== -1 ? true : false;
  }
});

export const isReportApproved = createSelector(
  selectrunningHoursReducer,
  report => report.data?.status === 'approved'
);

export const selectIsEditting = createSelector(selectrunningHoursReducer, report => {
  return report.isEditting;
});

export const selectNavigation = createSelector(selectrunningHoursReducer, report => {
  return report.navigation;
});

const selectPreviousReport = state => selectrunningHoursReducer(state)?.previousReport;

export const selectPreviousReportTimestamp = state => selectPreviousReport(state)?.timestamp;

export const selectPreviouVesselSystems = createSelector(selectrunningHoursReducer, report => {
  return report.previousVesselSystems;
});
