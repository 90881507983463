import TYPES from './types';

// Initialized in utils/store/initial load
export const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case TYPES.CREDENTIALS_LOGIN.SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };

    case TYPES.LOGOUT.SUCCESS:
      return {
        isAuthenticated: false
      };

    default:
      return state;
  }
}
