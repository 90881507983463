import React, { useMemo, useState, useEffect } from 'react';
import Drawer, { DrawerFooter, DrawerHeader, FormBody, FormDrawer } from 'common/components/drawer';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'common/components/form/inputs/Checkbox';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import _uniqBy from 'lodash/uniqBy';
import List from './List';
import { changeEventValue, setEventWatchers } from 'events/store/events/actions';
import { selectEventId, selectEventWatchers } from 'events/store/events/selectors';
import { selectAccount } from 'store/account/selectors';

const Form = ({ drawer }) => {
  const [watchers, setWatchers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const account = useSelector(selectAccount);
  const eventId = useSelector(selectEventId);
  const eventWatchers = useSelector(selectEventWatchers);

  const isCurrentAccountSelected = useMemo(
    () => !!watchers.find(watcher => watcher.id === account.id),
    [watchers, account.id]
  );
  const watchersWithoutCurrentAccount = useMemo(
    () => watchers.filter(watcher => watcher.id !== account.id),
    [watchers, account.id]
  );
  const selectedIds = useMemo(() => watchers.map(watcher => watcher.id), [watchers]);

  const onCheckboxChange = () => {
    if (isCurrentAccountSelected) {
      setWatchers(prev => prev.filter(watcher => watcher.id !== account.id));
      return;
    }

    setWatchers(prev => [...prev, account]);
  };

  const onSet = async () => {
    if (!eventId) {
      dispatch(changeEventValue({ watchers }));
      drawer.close();
      return;
    }

    setIsLoading(true);

    try {
      await dispatch(
        setEventWatchers({ watcher_ids: watchers.map(watcher => watcher.id), id: eventId })
      ).unwrap();
      setWatchers([]);
      drawer.close();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (drawer.isOpen && eventWatchers?.length) {
      setWatchers(prev => (prev.length ? prev : eventWatchers));
    }
  }, [drawer.isOpen, eventWatchers]);

  return (
    <Drawer {...drawer}>
      <DrawerHeader>Set Event Watchers</DrawerHeader>

      <FormDrawer>
        <FormBody>
          <Checkbox
            className="mt-2 border-bottom pb-3 mb-3"
            label={`${account.full_name} (Me)`}
            onChange={onCheckboxChange}
            value={isCurrentAccountSelected}
          />

          <AsyncSelector
            className="cmb-12"
            closeMenuOnSelect={false}
            label="WATCHERS"
            getOptionLabel={option => option.full_name}
            getOptionValue={option => option.id}
            placeholder="Select people"
            onChange={option => {
              if (!option) return;
              setWatchers(prev => _uniqBy([...prev, option], 'id'));
            }}
            loadOptions={search =>
              getAsyncOptions(search, 'parties', { can_login: true, exclude_ids: selectedIds })
            }
            defaultOptions={() =>
              getInitialAsyncValues('parties', null, false, {
                can_login: true,
                exclude_ids: selectedIds
              })
            }
            defaultOptionsTriggerChange={selectedIds?.length}
            value={null}
          />

          <List people={watchersWithoutCurrentAccount} setPeople={setWatchers} />
        </FormBody>
      </FormDrawer>

      <DrawerFooter className="d-flex justify-content-end ">
        <Button onClick={drawer.close} color="cancel" className="px-4">
          CANCEL
        </Button>
        <Button disabled={isLoading} onClick={onSet} color="primary" className="px-4">
          SET
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState.isRequired
};

export default Form;
