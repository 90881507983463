import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import useRouter from 'use-react-router';
import _get from 'lodash/get';
import paths from 'routing/routes/_paths';
import { Link } from 'react-router-dom';

import { parseQueryParams, stringifyObj } from 'utils/urls';
import ContractsTable from './contracts';
import Form from 'views/crew/form';
import PlaceAndDateText from './_components/PlaceAndDateText';
import PromotionLabel from 'views/crew/_components/PromotionLabel';

import useDrawer from 'common/components/drawer/useDrawer';
import Table, { useTable } from 'common/components/table';
import SaveTableLayout from 'components/layouts/page/table/save-table';

import TextWithTooltip from 'common/components/general/TextWithTooltip';

import edit from 'common/assets/svg/actions/edit.svg';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';
import { getCrewContract } from 'crew/store/contracts/actions';
import { useDispatch } from 'react-redux';
import { selectContractDate, selectContractPort } from '@/views/crew/profile/helpers.ts';
import { displayDate } from '@/ts-common/utils/dates';

import config from '@/common/components/shared-table-configs/index.ts';

const LABEL = 'crew';

const CrewTable = () => {
  const { history, location } = useRouter();
  const [active, setActive] = useState(null);
  const columns = config[LABEL].getColumns();
  const topFilters = config[LABEL].getTopFilters();
  const TopFilters = config[LABEL].topFiltersComponent;

  const dispatch = useDispatch();
  const drawer = useDrawer();

  const initDateFilter = useCallback(async () => {
    const { filters } = parseQueryParams(history.location.search);

    if (!filters || (filters && !filters.some(f => f.name === 'onboard_vessel_id'))) {
      history.replace({
        pathname: location.pathname,
        search: `${stringifyObj({
          filters: [
            {
              name: 'onboard_as_of_date',
              operation: '=',
              value: moment().format('YYYY-MM-DD')
            }
          ]
        })}`
      });
    }
  }, [history, location.pathname]);

  useEffect(() => {
    initDateFilter();
  }, [initDateFilter]);

  const table = useTable({
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: true },
    label: LABEL,
    urlRefetchLevel: 3,
    columns,
    top: {
      filters: topFilters
    }
  });

  const isEditable = data => {
    if (!data?.sign_off_date) {
      return true;
    } else {
      return moment(data?.sign_off_date)?.isAfter(moment().subtract(1, 'months'));
    }
  };

  return (
    <Row className="crew-table">
      <Col>
        <SaveTableLayout pageTitle={'Crew'} label={LABEL} />
      </Col>
      <Col xs={12} className="mb-4">
        <Table
          loader
          hideTopPagination
          mainHeaderComponent={<ContractsTable />}
          topFiltersComponent={<TopFilters table={table} />}
          drawer={<Form drawer={drawer} active={active} />}
          rows={{
            full_name: data => (
              <AuthCheck
                permissions={[permissions.onboard.crew.seaman_profile.personal.all_users.view]}
                unAuthorizedRender={data.full_name}
              >
                <div className="d-flex align-items-center justify-content-between flex-nowrap w-100p">
                  <div className="flex-1 max-width-calc-16">
                    {data.type === 'crew' ? (
                      <Link to={`${paths.crew}/${data.id}`}>
                        <TextWithTooltip>{data.full_name || '-'}</TextWithTooltip>
                      </Link>
                    ) : (
                      <Link to={`${paths.persons}/${data.person_id}`}>
                        <TextWithTooltip>{data.full_name || '-'}</TextWithTooltip>
                      </Link>
                    )}
                  </div>
                </div>
              </AuthCheck>
            ),
            promotion_label: data => {
              const initialRank = _get(data, 'contract_rank.name');
              const promotionRank = _get(data, 'active_promotion_plan.promotion_rank.name');

              return initialRank && promotionRank ? (
                <PromotionLabel initialRank={initialRank} promotionRank={promotionRank} />
              ) : null;
            },
            rank_id: data => (
              <TextWithTooltip className="d-flex align-items-center">
                {data.contract_rank ? data.contract_rank.name : '-'}
              </TextWithTooltip>
            ),
            birthday: data => (
              <PlaceAndDateText
                place={data?.place}
                date={data?.birthday ? moment(data.birthday).format('DD/MM/YYYY') : null}
              />
            ),
            nationality_id: data => _get(data, 'nationality.name', '-'),
            initial_contract_sign_on: data => {
              const signOnDate = selectContractDate(data?.initial_contract);
              const signOnPort = selectContractPort(data?.initial_contract);

              return (
                <PlaceAndDateText
                  place={_get(signOnPort, 'name')}
                  date={displayDate(signOnDate, 'DD/MM/YYYY HH:mm')}
                />
              );
            },
            active_contract_sign_off: data => {
              const signOffPort = selectContractPort(data?.active_contract, false);
              const signOffDate = selectContractDate(data?.active_contract, false);

              return (
                <PlaceAndDateText
                  place={_get(signOffPort, 'name')}
                  date={displayDate(signOffDate, 'DD/MM/YYYY HH:mm')}
                />
              );
            },
            requested_sign_off_date: data => {
              const requestedSignOffDate = _get(data, 'active_contract.requested_sign_off_date');
              return displayDate(requestedSignOffDate, 'DD/MM/YYYY');
            },
            passport_no: data =>
              data?.passport?.code ? (
                <TextWithTooltip className="h-auto">{data.passport.code}</TextWithTooltip>
              ) : (
                '-'
              ),
            passport_exp_date: data => {
              const date = data?.passport?.expires_at;
              return date ? moment(date).format('DD/MM/YYYY') : '-';
            },
            usa_visa_no: data =>
              data?.visa?.code ? (
                <TextWithTooltip className="h-auto">{data.visa.code}</TextWithTooltip>
              ) : (
                '-'
              ),
            usa_visa_exp_date: data => {
              return data?.visa?.expires_at
                ? moment(data.visa.expires_at).format('DD/MM/YYYY')
                : '-';
            },
            actions: data => ({
              options:
                data?.type === 'crew' && isEditable(data)
                  ? [
                      {
                        label: 'Edit',
                        icon: edit,
                        permissions: [
                          permissions.onboard.crew.seaman_profile.contracts.sign_on_date.edit,
                          permissions.onboard.crew.seaman_profile.contracts.sign_off_date.edit
                        ],
                        onClick: async () => {
                          await dispatch(getCrewContract({ id: data.contract_id }));
                          setActive(data);
                          drawer.open();
                        }
                      }
                    ]
                  : []
            })
          }}
          {...table}
        />
      </Col>
    </Row>
  );
};

export default CrewTable;
