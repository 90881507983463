import PropTypes from 'prop-types';
import { ScaleRatingValue } from 'common/components/general/ScaleRatings';
import ScaleNumberInput from 'common/components/form/inputs/ScaleNumberInput';

const ScaleNumberInputWrapper = ({ fields, selectField, hasSubmittedEvaluation, errors }) => {
  const scaleValue = fields?.scale?.value;

  const sliderProps = {
    renderMark: props => <span {...props}>{props.key}</span>,
    markClassName: 'fs-10 text-violet mt-1',
    defaultValue: 0,
    pearling: true,
    renderThumb: (props, state) =>
      !scaleValue ? <div {...props}>-</div> : <div {...props}>{state.valueNow}</div>,
    error: errors?.scale && !fields?.scale?.value ? errors?.scale : null,
    dataCy: 'evaluation-slider'
  };

  return hasSubmittedEvaluation ? (
    <ScaleRatingValue value={scaleValue} />
  ) : (
    <ScaleNumberInput
      onChange={v => selectField('scale')(v)}
      value={fields?.scale?.value}
      sliderProps={sliderProps}
      widths={[10, 2]}
    />
  );
};

ScaleNumberInputWrapper.propTypes = {
  fields: PropTypes.shape({
    scale: PropTypes.shape({ value: PropTypes.number, error: PropTypes.string })
  }).isRequired,
  selectField: PropTypes.func.isRequired,
  hasSubmittedEvaluation: PropTypes.bool
};

export default ScaleNumberInputWrapper;
