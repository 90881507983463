import TYPES from './types';

export const resetTableData = name => dispatch => {
  return dispatch({ type: TYPES.RESET_TABLE_DATA, payload: name });
};

export const resetTableSearch = () => dispatch => {
  return dispatch({ type: TYPES.RESET_TABLE_SEARCH });
};

export const resetCurrentPage = params => dispatch => {
  return dispatch({ type: TYPES.RESET_CURRENT_PAGE, payload: params });
};

export const setTableFilters = params => dispatch => {
  return dispatch({ type: TYPES.SET_TABLE_FILTERS, payload: params });
};

export const setTableDefaultRequestParams = params => dispatch => {
  return dispatch({ type: TYPES.SET_TABLE_DEFAULT_REQUEST_PARAMS, payload: params });
};
