const mainInfo = [
  {
    fields: [
      {
        label: 'FIRST NAME',
        key: 'first_name',
        type: 'string',
        render: false,
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add first name',
          dataCy: 'first-name'
        },
        validations: { required: true }
      },
      {
        label: 'MIDDLE NAME',
        key: 'middle_name',
        type: 'string',
        render: false,
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add middle name',
          dataCy: 'middle-name'
        }
      },
      {
        label: 'LAST NAME',
        key: 'last_name',
        type: 'string',
        render: false,
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add last name',
          dataCy: 'last-name'
        },
        validations: { required: true }
      },
      {
        key: 'department_parties',
        render: false,
        type: 'multiple',
        data: [
          {
            label: 'Department',
            key: 'department',
            type: 'select'
          },
          {
            label: 'Role',
            key: 'department_role',
            type: 'select'
          },
          {
            label: 'Vessel(s)',
            key: 'vessels',
            type: 'select'
          }
        ]
      },
      {
        label: 'COMPANY',
        key: 'company.full_name',
        select_key: 'company',
        type: 'select',
        render: false,
        inputWidth: 5,
        rowAlignment: 'start',
        dataCy: 'company-name'
      },
      {
        label: 'ROLE',
        key: 'position',
        type: 'string',
        render: false,
        inputWidth: 5,
        inputProps: { placeholder: 'Add role' }
      },
      {
        label: 'BIRTH DATE',
        key: 'birthday',
        type: 'date',
        inputWidth: 5,
        inputProps: { placeholder: 'Set date' }
      },
      {
        label: 'EMAIL',
        key: 'email',
        type: 'email',
        render: false,
        validations: { email: true }
      },
      {
        type: 'multiple',
        key: 'emails',
        render: false,
        data: [
          {
            label: 'type',
            key: 'type',
            type: 'string'
          },
          {
            label: 'value',
            key: 'value',
            type: 'email',
            validations: { email: true }
          }
        ]
      },
      {
        label: 'PHONE',
        key: 'phone',
        type: 'string',
        render: false
      },
      {
        type: 'multiple',
        key: 'contact_info',
        render: false,
        data: [
          {
            label: 'type',
            key: 'type',
            type: 'string'
          },
          {
            label: 'value',
            key: 'value',
            type: 'string'
          }
        ]
      }
    ]
  }
];

export default mainInfo;
