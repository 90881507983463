import React, { Suspense } from 'react';

import { Switch, Redirect } from 'react-router-dom';
import AppAlerts from 'common/components/alerts/AppAlerts';

import ContentWrapper from 'components/layouts/ContentWrapper';
import RenderRoute from './components';

import NotFound from 'views/NotFound';
import Unauthorized from 'views/Unauthorized';

import accountRoutes from './routes/account';
import authRoutes from './routes/auth';
import dashboardRoutes from './routes/dashboard';
import crewRoutes from './routes/crew';
import jobsRoutes from './routes/jobs';
import captainReportsRoutes from './routes/captain_reports';
import shiftsRoutes from './routes/shifts';
import eventRoutes from './routes/events';
import workingScheduleRoutes from './routes/working_schedule';
import manualsRoutes from './routes/manuals';
import formRoutes from './routes/forms';
import purchasingRoutes from './routes/purchasing';
import pmsRoutes from './routes/pms';
import personsRoutes from './routes/persons';
import vesselsRoutes from './routes/vessels';
import evaluationRoutes from './routes/evaluations';
import filesystemRoutes from './routes/filesystem';
import mgaRoutes from './routes/mga';
import voyagesRoutes from './routes/voyages';

import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/auth/selectors';

import paths from 'routing/routes/_paths';
import PageLoader from 'common/components/general/PageLoader';

const appRoutes = [
  ...accountRoutes,
  ...authRoutes,
  ...dashboardRoutes,
  ...crewRoutes,
  ...jobsRoutes,
  ...captainReportsRoutes,
  ...shiftsRoutes,
  ...workingScheduleRoutes,
  ...eventRoutes,
  ...manualsRoutes,
  ...formRoutes,
  ...purchasingRoutes,
  ...pmsRoutes,
  ...personsRoutes,
  ...evaluationRoutes,
  ...vesselsRoutes,
  ...filesystemRoutes,
  ...mgaRoutes,
  ...voyagesRoutes
];

const AppRouter = ({ history }) => {
  const authenticated = useSelector(selectIsAuthenticated);

  return (
    <ConnectedRouter history={history}>
      <ContentWrapper routes={appRoutes}>
        <Suspense fallback={<PageLoader isLoading={true} />}>
          <Switch>
            {appRoutes.map((route, i) => (
              <RenderRoute authenticated={authenticated} key={i} strict {...route} />
            ))}

            <RenderRoute
              type="default"
              documentTitle="Unauthorized"
              path={paths.unauthorized}
              exact
              component={Unauthorized}
            />
            <RenderRoute
              type="default"
              documentTitle="Not Found"
              path={paths.not_found}
              exact
              component={NotFound}
            />

            <Redirect to={paths.not_found} />
          </Switch>
          <AppAlerts />
        </Suspense>
      </ContentWrapper>
    </ConnectedRouter>
  );
};

export default AppRouter;
