import { GroupBase, MultiValueGenericProps, components } from 'react-select';

const MultipleMultiValueContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: MultiValueGenericProps<Option, IsMulti, Group>) => {
  return (
    <div className={`react-select-multiple-value-container`}>
      <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>
    </div>
  );
};

export default MultipleMultiValueContainer;
