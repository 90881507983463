import { useEffect } from 'react';

import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

const useRedirect = shouldRedirect => {
  const { history } = useRouter();

  useEffect(() => {
    if (shouldRedirect) {
      history.replace(paths.not_found);
    }
  }, [shouldRedirect]);
};
export default useRedirect;
