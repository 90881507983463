import { Link } from 'react-router-dom';
import { constructUrlPath } from '@/captain-reports/utils/helpers.js';
import paths from 'routing/routes/_paths';

export const reportURL = {
  port_statement: `${paths.captain_reports}/port-statement`,
  instructed_speed: constructUrlPath('instructed_speed'),
  system_running_hours: constructUrlPath('system_running_hours'),
  port_document_overview: constructUrlPath('port_document_overview'),
  passage_plan: constructUrlPath('passage_plan')
};

const ReportLink = ({ templateType, reportId, children, ...rest }) => {
  return (
    <Link to={`${reportURL[templateType] || paths.captain_reports}/${reportId}`} {...rest}>
      {children}
    </Link>
  );
};

export default ReportLink;
