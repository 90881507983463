import React from 'react';

import CircledButton from 'common/components/buttons/CircledButton';
import SvgRender from 'common/components/general/SvgRender';

import binIcon from 'common/assets/svg/actions/delete.svg';
import AddMore from 'common/components/form/helpers/AddMore';

const FormList = ({
  inner,
  subFormInline,
  showBin = true,
  sideBin,
  forms,
  addText,
  children,
  onAdd,
  onRemove,
  filter,
  canRemove,
  canAdd = true,
  className,
  formListclassName,
  style,
  footer,
  removeButtonProps
}) => {
  forms = forms.filter(formState => (filter ? filter(formState.state) : true));

  return (
    <div
      className={`form-list-wrap ${subFormInline ? 'sub-form-inline' : ''} ${
        className?.includes('pt-') ? className : 'pt-1 pb-1'
      }`}
      style={style}
    >
      {forms.map((form, index) => {
        let isRemovable = canRemove ? canRemove(form, index) : true && onRemove && forms.length > 1;

        return (
          <div
            key={form.index}
            className={`form-list${inner ? ' inner' : ''} ${formListclassName || ''}`}
          >
            {isRemovable && (
              <RemoveForm
                onClick={() => onRemove(form.index, form)}
                variant={inner}
                showBin={showBin}
                sideBin={sideBin}
                removeButtonProps={removeButtonProps}
              />
            )}
            {children(form, index)}
          </div>
        );
      })}
      <div className="form-list-footer d-flex align-items-center justify-content-between">
        {onAdd && canAdd && <AddMore onClick={onAdd} variant={inner} label={addText}></AddMore>}
        {footer ? footer : null}
      </div>
    </div>
  );
};
export default FormList;

const RemoveForm = ({ onClick, variant, showBin, sideBin, removeButtonProps = {} }) =>
  variant ? (
    showBin ? (
      sideBin ? (
        <SvgRender
          onClick={onClick}
          className="cursor-pointer text-red position-absolute"
          src={binIcon}
          style={{ width: 16, height: 16, top: 4, right: -24 }}
        />
      ) : (
        <SvgRender
          style={{ width: 14, height: 14 }}
          onClick={onClick}
          src={binIcon}
          className="remove-icon variant"
        />
      )
    ) : (
      <CircledButton
        onClick={onClick}
        type="remove"
        className="remove-icon"
        style={{ width: 16, height: 16 }}
        {...removeButtonProps}
      />
    )
  ) : (
    <CircledButton
      onClick={onClick}
      type="remove"
      className="remove-icon"
      style={{ width: 16, height: 16 }}
      {...removeButtonProps}
    />
  );
