import React, { useEffect } from 'react';

import { useActions } from 'utils/hooks';
import { numberToStr, strToNumber } from 'common/utils/numbers';

import useValue from 'captain-reports/utils/useValue';
import useReportTimeDiff from 'captain-reports/utils/useReportTimeDiff';

import * as reportActions from 'captain-reports/store/actions';

const AdjustmentField = ({ sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const total_consumption = useValue({
    key: 'total_consumption',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const total_consumption_rate = useValue({
    key: 'total_consumption_rate',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const mins_since_last_report = useReportTimeDiff();

  const [setValue] = useActions([reportActions.setValue]);

  useEffect(() => {
    if (mins_since_last_report && total_consumption) {
      const totalAdj = strToNumber(
        parseFloat((total_consumption * 24 * 60) / mins_since_last_report).toFixed(2)
      );

      if (total_consumption_rate !== totalAdj) {
        setValue({
          key: 'total_consumption_rate',
          value: totalAdj,
          sectionLabel,
          sectionActiveTab,
          subGroup,
          subGroupIndex
        });
      }
    } else if (!total_consumption && total_consumption_rate) {
      setValue({
        key: 'total_consumption_rate',
        value: null,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [mins_since_last_report, total_consumption]);

  return <span>{total_consumption_rate ? `${numberToStr(total_consumption_rate)}` : null}</span>;
};

export default AdjustmentField;
