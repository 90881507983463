import { EventSubtypes } from 'common/entities/events/EventTypes';
import Tooltip from 'common/components/general/Tooltip';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import PropTypes from 'prop-types';

const Subtypes = ({ data }) => {
  if (!data?.subtypes?.length) return null;

  return (
    <div className="d-flex align-items-center text-nowrap overflow-hidden text-violet">
      <span className="text-violet cps-2 cpe-2">|</span>
      <TextWithTooltip>{data.subtypes?.[0].name}</TextWithTooltip>
      {data.subtypes?.length > 1 ? (
        <>
          <div id={`events-${data.id}`} className="fw-bold fs-10 cms-4 cursor-pointer">
            + {data.subtypes?.length - 1}
          </div>
          <Tooltip target={`events-${data.id}`} placement="top">
            {(data.subtypes || [])
              ?.filter((_, i) => i !== 0)
              ?.map((s, index, array) => (
                <span key={index}>
                  {s.name}
                  {index < array.length - 1 ? ', ' : ''}
                </span>
              ))}
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

Subtypes.propTypes = {
  data: PropTypes.shape({
    subtypes: EventSubtypes
  })
};

export default Subtypes;
