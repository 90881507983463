import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import useTooltipID from 'common/utils/hooks/useTooltipID';

import SvgRender from 'common/components/general/SvgRender';

import star from 'common/assets/svg/common/star.svg';
import starSolid from 'common/assets/svg/common/star-solid.svg';

const EvaluationPopover = ({
  placement = 'left',
  headerClassName = '',
  bodyClassName = '',
  children,
  className,
  color = 'turquoise',
  popoverTitle,
  popoverBody,
  ...rest
}) => {
  const { avoidRender, tooltipID, resetTooltip } = useTooltipID('evaluations-popup');
  const [isHovering, setIsHovering] = useState(false);

  if (avoidRender) return null;

  return (
    <>
      <Button
        type="button"
        color="link"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`${className}`}
        onClick={() => resetTooltip()}
        id={tooltipID}
      >
        <SvgRender
          className={`text-${color}`}
          src={isHovering ? starSolid : star}
          style={{ width: 15, height: 15 }}
        />
      </Button>
      {isHovering ? (
        <UncontrolledPopover
          trigger="hover"
          placement={placement}
          target={tooltipID}
          className="evaluations-popup"
          boundariesElement="window"
          {...rest}
        >
          <PopoverHeader
            className={`d-flex align-items-center px-0 py-0 pt-0 pb-1 bg-white ${headerClassName}`}
          >
            {popoverTitle}
          </PopoverHeader>
          <PopoverBody className={`px-0 py-0 ${bodyClassName}`}>{popoverBody}</PopoverBody>
        </UncontrolledPopover>
      ) : null}
    </>
  );
};

EvaluationPopover.propTypes = {
  placement: PropTypes.string,
  bodyClassName: PropTypes.string,
  children: PropTypes.node,
  headerClassName: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  popoverTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  popoverBody: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default EvaluationPopover;
