import React from 'react';

import DownloadAttachment from 'common/components/buttons/DownloadAttachment';

const CellAttachmentsButton = ({ row, data }) => {
  const options = row(data);

  return React.isValidElement(options) ? options : <DownloadAttachment {...options} />;
};

export default CellAttachmentsButton;
