import React from 'react';
import { components } from 'react-select';
import SvgRender from 'common/components/general/SvgRender';
import spinner from 'common/assets/svg/common/spinner.svg';

const SelectLoaderIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SvgRender src={spinner} style={{ width: 20, height: 20 }} />
    </components.DropdownIndicator>
  );
};

export default SelectLoaderIndicator;
