export const lists = {
  'reports-performance-setups': {
    options: [
      { name: 'Benchmarks', label: 'benchmarks' },
      { name: 'Formulas', label: 'formulas' },
      { name: 'Engine Load Diagram', label: 'engine-load-diagram' },
      { name: 'Comparison', label: 'comparison' },
      { name: 'Tags', label: 'tags' },
      { name: 'AMS Configuration', label: 'ams-configuration' }
    ]
  },
  'phone-types': {
    options: [
      { name: 'Phone', label: 'phone' },
      { name: 'Mobile', label: 'mobile' },
      { name: 'Work', label: 'work' },
      { name: 'Fax', label: 'fax' }
    ]
  },
  'email-types': {
    options: [
      { name: 'Work', label: 'work' },
      { name: 'Personal', label: 'personal' }
    ]
  },
  'for-vessel-company': {
    options: [
      { name: 'For Vessel', label: 'for_vessel' },
      { name: 'For Company', label: 'for_company' }
    ]
  }
};

export const performanceReportPrefilledOptions = [
  { name: 'Analytics', label: 'analytics' },
  { name: 'AMS Dashboard', label: 'ams-dashboard' }
];
