const isValidHttpUrl = string => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const containsHTML = str => /<[a-z][\s\S]*>/i.test(str);

const urlFormat = text => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let formattedText = text;

  formattedText = formattedText.replace(urlRegex, url => {
    return isValidHttpUrl(url) ? `<a href='${url}' target="_blank">${url}</a>` : url;
  });

  return formattedText;
};

const breakLineFormat = text => {
  const breakLine = /\n/gm;

  let formattedText = text;

  formattedText = formattedText.replace(breakLine, '<br />');

  return formattedText;
};

const formatMentions = (text, mentions) => {
  let formattedText = text;

  mentions?.forEach(mention => {
    formattedText = formattedText.replace(`^^^${mention.display}`, '^^^');
  });

  return formattedText;
};

export const messageFormatter = (message, mentions) => {
  let formattedMessage = message;

  formattedMessage = urlFormat(formattedMessage);
  formattedMessage = breakLineFormat(formattedMessage);
  formattedMessage = formatMentions(formattedMessage, mentions);

  return formattedMessage;
};

export const messageInitialState = {
  text: '',
  mentions: []
};
