import React from 'react';
import PropTypes from 'prop-types';
import { SignatureRequirementType } from 'common/entities/digital-signatures/SignatureRequirementTypes';
import { Alert } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import rank from 'common/assets/svg/crew/rank.svg';
import department from 'common/assets/svg/common/department.svg';
import roles from 'common/assets/svg/common/roles.svg';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { getValidSignatures } from '../../helpers';

export const icon = { crew_rank: rank, department: department, department_role: roles };
export const label = { crew_rank: 'rank', department: 'department', department_role: 'role' };

const SignatureRequirements = ({ data }) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const signatures = getValidSignatures(data, isOnboard);

  return signatures?.length ? (
    <div className="pt-3 mt-3 mb-3 border-top">
      {signatures?.map(requirement => (
        <Alert
          key={requirement.id}
          color="red"
          className="cmb-4 d-flex align-items-center cpx-12 cpy-4 text-coral rounded fs-12 lh-1"
        >
          Missing signature from {label?.[requirement.required_signer_type]}:
          <SvgRender
            src={icon?.[requirement.required_signer_type]}
            style={{ width: 10, height: 16 }}
            className="mx-1"
          />
          <strong>{_get(requirement, 'required_signer.name', '-')}</strong>
        </Alert>
      ))}
    </div>
  ) : null;
};

SignatureRequirements.propTypes = {
  data: PropTypes.arrayOf(SignatureRequirementType)
};

export default SignatureRequirements;
