import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
// import useRouter from 'use-react-router';
// import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
// import * as sidebarActions from 'store/sidebar/actions';

import SvgRender from 'common/components/general/SvgRender';
import User from './User';
import VesselInfo from './VesselInfo';

import logo_small from 'assets/svg/logos/logo_small.svg';
import VesselButton from './VesselButton';

const Action = ({ action, activePanel }) => {
  return (
    <div
      className={`sidebar-action text-center mb-3${activePanel === action.label ? ' active' : ''}`}
    >
      <Button
        type="button"
        color="link"
        className={`p-0 text-white d-inline-flex align-items-center justify-content-center ${
          action.isActive && action.isActive() ? 'is-active' : ''
        }`}
        onClick={action.onClick ? action.onClick : () => {}}
      >
        {action.renderComponent ? (
          action.renderComponent()
        ) : (
          <SvgRender
            src={action.icon}
            style={{
              height: action.height ? action.height : 24,
              width: action.width ? action.width : 24
            }}
          />
        )}
      </Button>
    </div>
  );
};

const Navbar = () => {
  // const { location } = useRouter();
  const activePanel = useSelector(state => state.sidebar.activePanel);
  // const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const actions = {
    top: [
      // {
      //   icon: star,
      //   onClick: () => togglePanel('favorites'),
      //   label: 'favorites',
      //   width: 20,
      //   height: 20
      // },
    ],
    bottom: [
      // {
      //   label: 'notifications',
      //   renderComponent: () => <NotificationBadge />,
      //   onClick: () => togglePanel('notifications')
      // },
    ]
  };

  return (
    <div className="sidebar-navbar py-4 d-flex flex-column justify-content-between align-items-center">
      <div className="sidebar-navbar--top">
        <div className="sidebar-brand mb-4">
          <Link to={'/'}>
            <img src={logo_small} alt="Orca logo" width={34} height={40} />
          </Link>
        </div>

        {actions.top.map((action, index) => (
          <Action action={action} key={index} activePanel={activePanel} />
        ))}
      </div>

      <div className="sidebar-navbar--bottom">
        <VesselInfo />

        {actions.bottom.map((action, index) => (
          <Action action={action} key={index} activePanel={activePanel} />
        ))}

        <VesselButton />
        <User />
      </div>
    </div>
  );
};

export default Navbar;
