import { FC, ReactNode } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { numberToStr } from '@/ts-common/utils/numbers';
import FormFieldPreviewer from '@/ts-common/components/form/helpers/FormFieldPreviewer';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';

interface NumberInputProps extends NumericFormatProps {
  error?: string | null;
  className?: string;
  innerClassName?: string;
  name?: string;
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  invisible?: boolean;
  viewOnly?: boolean;
  allowUnlimitedDecimalScale?: boolean;
  dataCy?: string;
}

const NumberInput: FC<NumberInputProps> = ({
  error,
  className,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  maxLength,
  disabled,
  decimalScale,
  invisible,
  innerClassName = '',
  viewOnly,
  thousandSeparator = ',',
  decimalSeparator = '.',
  allowUnlimitedDecimalScale = false,
  dataCy,
  ...rest
}) => {
  if (viewOnly)
    return (
      <FormFieldPreviewer
        className={className}
        label={label}
        value={numberToStr(value, decimalScale)}
        type="number"
      />
    );

  return (
    <FormGroupWrap
      className={`${className} ${invisible ? 'invisible-input' : ''}`}
      label={label}
      error={error}
    >
      {invisible && error ? <div className="input-error-icon"></div> : ''}
      <NumericFormat
        data-cy={dataCy || 'number_input'}
        className={`form-control form-field ${innerClassName}`}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur && onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowLeadingZeros={false}
        decimalScale={
          decimalScale !== undefined ? decimalScale : allowUnlimitedDecimalScale ? undefined : 2
        }
        {...rest}
      />
    </FormGroupWrap>
  );
};

export default NumberInput;
