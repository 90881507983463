import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _groupBy from 'lodash/groupBy';
import _isBoolean from 'lodash/isBoolean';
import _get from 'lodash/get';
import { strToNumber, numberToStr } from 'common/utils/numbers';
import { parseBunkersReportFields } from 'captain-reports/templates/config/_bunkersHelpers';

const EXCLUDE_VALIDATION_WHEN_CONTAINED = ['leg_'];

/* Group section's fields based on their SubGroup */
export const getSectionConfigSubGroups = config => {
  const subGroups = _groupBy(
    config.filter(t => t.subGroup),
    s => s.subGroup
  );

  return subGroups;
};
/* -- */

export const checkIfField = field => {
  const valuesToCheck = ['value', 'comment'];

  return valuesToCheck.every(value => Object.keys(field).includes(value));
};

export const getSectionError = (sectionState, templateVisibleFields) => {
  let hasError = false;

  const setHasError = newHasError => {
    if (newHasError) hasError = true;
  };

  Object.keys(sectionState).forEach(key => {
    if (_isArray(sectionState[key])) {
      for (let index = 0; index < sectionState[key].length; index++) {
        setHasError(getSectionError(sectionState[key][index], templateVisibleFields));
      }
    } else if (_isObject(sectionState[key]) && !checkIfField(sectionState[key])) {
      Object.keys(sectionState[key]).forEach(key2 => {
        setHasError(getSectionError(sectionState[key][key2], templateVisibleFields));
      });
    } else if (templateVisibleFields[key] && sectionState[key]) {
      setHasError(
        (sectionState[key].error && !sectionState[key].comment) ||
          (!sectionState[key].error && sectionState[key].comment)
      );
    }
  });

  return hasError;
};

/* Get the total progress of a section's fields (total fields & completed fields).
   Use the flatConfigFields array param, to have only the fields declared in config files. */
export const getSectionTotalProgress = (sectionState, templateVisibleFields, flatConfigFields) => {
  let total = 0;
  let withValue = 0;

  Object.keys(sectionState).forEach(key => {
    if (_isArray(sectionState[key])) {
      for (let index = 0; index < sectionState[key].length; index++) {
        const totals = getSectionTotalProgress(
          sectionState[key][index],
          templateVisibleFields,
          flatConfigFields
        );

        total = total + totals.total;
        withValue = withValue + totals.withValue;
      }
    } else if (_isObject(sectionState[key]) && !checkIfField(sectionState[key])) {
      Object.keys(sectionState[key]).forEach(key2 => {
        const totals = getSectionTotalProgress(
          sectionState[key][key2],
          templateVisibleFields,
          flatConfigFields
        );

        total = total + totals.total;
        withValue = withValue + totals.withValue;
      });
    } else if (
      templateVisibleFields[key] &&
      ((flatConfigFields && flatConfigFields.find(c => c.key === key)) || !flatConfigFields)
    ) {
      total++;

      if (_isObject(sectionState[key])) {
        if (sectionState[key].value || sectionState[key].value === 0) withValue++;
      } else {
        if (sectionState[key]) withValue++;
      }
    }
  });

  return { withValue, total };
};
/* -- */

/* Get ONLY the state of a section */
export const getSectionValues = ({ config, label }, formState, forSectionValidation = false) => {
  const values = {};

  config.forEach(section => {
    if (_isArray(formState[label])) {
      values[label] = formState[label];
    } else if (section.subGroup) {
      values[section.subGroup] = formState[section.subGroup];
    } else {
      section.fields.forEach(field => {
        if (field.key) values[field.key] = formState[field.key];
      });
    }
  });

  return parseReportFields(values, { asObject: true, forSectionValidation });
};
/* -- */

/* Parse fields before sending to API */
export const getFieldValue = (field, asObject = true, forSectionValidation) => {
  if (_isArray(field)) {
    return field.map(el => {
      return parseReportFields(el, { asObject, forSectionValidation });
    });
  } else {
    // have any other checks based on field validations

    return asObject
      ? {
          value: field.value,
          comment: field.comment,
          ...(forSectionValidation ? { error: field.error } : {})
        }
      : field.value;
  }
};

export const getIsFieldExcluded = (key, forFieldsComparison) => {
  if (!forFieldsComparison) return false;

  const containsExcluded = EXCLUDE_VALIDATION_WHEN_CONTAINED.some(excluded =>
    key?.includes(excluded)
  );

  if (containsExcluded) return true;

  return false;
};

export const parseReportFields = (
  data,
  { asObject = true, forSectionValidation = false, forFieldsComparison = false } = {}
) => {
  let params = {};

  Object.keys(data).forEach(fieldKey => {
    if (!getIsFieldExcluded(fieldKey, forFieldsComparison)) {
      if (fieldKey === 'consumptions') {
        params.bunkers = parseBunkersReportFields(data, {
          forSectionValidation,
          forFieldsComparison
        });
      } else if (fieldKey === 'ballast_water') {
        params.ballast_water = data.ballast_water.map(c => {
          const { ballast_tank, ...rest } = c;

          return {
            ballast_water_tank_id: { value: ballast_tank?.id },
            ...rest
          };
        });

        params.ballast_water = getFieldValue(params.ballast_water, true, forSectionValidation);
      } else {
        params[fieldKey] = getFieldValue(
          data[fieldKey],
          !asObject ? false : !data[fieldKey].sendOnlyValue,
          forSectionValidation
        );
      }
    }
  });

  return params;
};
/* -- */

/* Init report's form state */
export const initFieldValue = (field, initialValue, selectFullValue) => {
  if (_isArray(field)) {
    return field.map((el, index) => {
      return initReportFields(
        el,
        initialValue && initialValue.length ? initialValue[index] : {},
        selectFullValue && selectFullValue.length ? selectFullValue[index] : {}
      );
    });
  } else {
    const value = _isObject(initialValue) ? initialValue.value : initialValue;
    const comment = _isObject(initialValue) ? initialValue.comment : null;
    const file = _isObject(initialValue) ? initialValue.file : null;

    const formatted = {
      ...field,
      value: null,
      comment: null
    };

    if (field?.type === 'file') {
      formatted.value = value;
      formatted.file = file;
    } else if (field?.type === 'string' && !isNaN(value)) {
      // Parse any field with type string to string. With that way we avoid deep compare issues like timezone.
      formatted.value = value ? numberToStr(value) : value;
    } else if (value !== null && value !== undefined) {
      if (isNaN(value) || _isBoolean(value)) {
        formatted.value = value;
      } else {
        // parse any numeric field to Number
        formatted.value = strToNumber(value);
      }
      formatted.comment = comment;
    }

    if (selectFullValue) {
      formatted.selectValue = selectFullValue;
    }

    return formatted;
  }
};

export const initReportFields = (emptyState, initialValues) => {
  let state = {};

  Object.keys(emptyState).forEach(fieldKey => {
    let fieldValue =
      initialValues && initialValues[fieldKey]
        ? initialValues[fieldKey]
        : _get(initialValues, fieldKey, null) === 0
        ? 0
        : null;

    if (fieldKey === 'consumptions') {
      // Consumptions are already parsed in formReducer
      state[fieldKey] = initFieldValue(emptyState[fieldKey], initialValues[fieldKey]);
    } else {
      if (emptyState[fieldKey]?.selectKey) {
        let selectFullValue = '';

        if (fieldKey === 'charter_party_id') {
          fieldValue = initialValues?.charter_party?.id;
          selectFullValue = initialValues?.charter_party
            ? {
                value: initialValues?.charter_party?.id,
                charter_party_id: initialValues?.charter_party?.id,
                description: initialValues?.charter_party?.charterer_description
              }
            : null;
        } else {
          selectFullValue =
            initialValues && initialValues[emptyState[fieldKey].selectKey]
              ? initialValues[emptyState[fieldKey].selectKey]
              : null;
        }

        state[fieldKey] = initFieldValue(emptyState[fieldKey], fieldValue, selectFullValue);
      } else {
        state[fieldKey] = initFieldValue(emptyState[fieldKey], fieldValue);
      }
    }
  });

  return state;
};
/* -- */

/* Parse the format of the template's visible fields before adding them to Redux Store */
export const parseTemplateVisibleFields = fields =>
  fields.reduce((acc, cur) => {
    acc[cur.field_label] = true;

    return acc;
  }, {});
/* -- */

/* Filter the validated fields before adding them to Redux Store */
export const filterTemplateValidatedFields = (fields, isOnboard) => {
  const filteredFieldsArr = fields.filter(field =>
    isOnboard
      ? (field.rule && field.limit && !field.hide) || (field.is_required && !field.hide)
      : (field.rule && field.limit) || field.is_required
  );
  const fieldsObjArr = filteredFieldsArr.map(field => ({ [field.field_label]: field }));

  return filteredFieldsArr.length ? Object.assign(...fieldsObjArr) : {};
};

export const filterVesselValidatedFields = fields => {
  const filteredFieldsArr = fields.filter(field => field.rule && field.limit && field.hide);
  const fieldsObjArr = filteredFieldsArr.map(field => ({ [field.field_label]: field }));

  return filteredFieldsArr.length ? Object.assign(...fieldsObjArr) : {};
};
/* -- */

/* Any other Fields related functions */

export const berthOptions = [
  {
    id: 'SPM',
    label: 'Single Point Mooring'
  },
  {
    id: 'CMB',
    label: 'Conventional Buoy Mooring'
  },
  {
    id: 'DRFT',
    label: 'Draft'
  },
  {
    id: 'Jetty',
    label: 'Jetty'
  },
  {
    id: 'STS',
    label: 'Ship To Ship'
  },
  {
    id: 'TMS',
    label: 'Turret Mooring System'
  }
];
