import TYPES from './types';

import _reduce from 'lodash/reduce';
import _orderBy from 'lodash/orderBy';

const INITIAL_STATE = {
  // Trip (Drawer State)
  tripDrawerIsOpen: false,
  tripIDs: null,
  tripType: null, // crew || events
  tripCaseID: null,
  tripParties: [],
  tripVisibleParties: [], // [ { id, trip_id } ] - when in crew planning, show only the trip on/off signers
  tripOffers: [],
  tripDisabled: false,
  tripIsEditting: false,
  tripIsCollapsed: {}, // { tripID_1: true, tripID_2: false }
  logDetails: {},

  // Prevention modal state
  preventionModal: {
    open: false,
    message: null
  }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    // Trip
    case TYPES.OPEN_TRIP_DRAWER:
      return {
        ...state,
        shouldRefetchTicketingCases: false,
        tripDrawerIsOpen: true,
        tripIDs: payload.tripIDs || null,
        tripCaseID: payload.ticketingCaseID || null,
        tripType: payload.type || null,
        tripParties: payload.parties || [],
        tripOffers: [],
        tripDisabled: payload.disabled || false,
        tripVisibleParties: payload.tripVisibleParties || [],
        tripIsCollapsed: {}
      };

    case TYPES.CLOSE_TRIP_DRAWER:
      return {
        ...state,
        tripDrawerIsOpen: false
      };

    case TYPES.GET_TRIP.SUCCESS:
      if (payload) {
        const {
          id,
          parties,
          port,
          vessel,
          offers,
          master_offers,
          started_at,
          created_at,
          created_by,
          updated_at,
          updated_by,
          is_bulk
        } = payload;

        const tripOffers = _orderBy(
          _reduce(
            master_offers || offers,
            (acc, { routes, transportation_type, ...offer }) => {
              const ticket = {
                ...offer,
                logDetails: {
                  created_at,
                  created_by,
                  updated_at,
                  updated_by
                },
                rotation_id: payload.rotation_id,
                is_bulk,
                participants_count: parties?.length,
                trip_id: id,
                transportation_type,
                routes: routes.map(r => {
                  const { arrival, departure, from_airport, to_airport, from, to, ...rest } = r;

                  return {
                    arrival,
                    departure,
                    from: transportation_type === 'air' ? from_airport : from,
                    to: transportation_type === 'air' ? to_airport : to,
                    ...rest
                  };
                })
              };

              acc.push(ticket);
              return acc;
            },
            []
          ),
          ['accepted', 'routes[0].departure'],
          ['desc', 'asc']
        );

        const formattedParties = parties.map(person => ({
          person,
          port,
          vessel,
          trip_id: id,
          started_at,
          type: person?.pivot?.type || null
        }));

        return {
          ...state,
          tripIDs: !state.tripIDs ? [id] : state.tripIDs,
          tripParties:
            state.tripIDs?.length === 1 || !state.tripIDs
              ? formattedParties
              : [...state.tripParties, ...formattedParties],
          tripOffers:
            state.tripIDs?.length === 1 || !state.tripIDs
              ? tripOffers
              : [...state.tripOffers, ...tripOffers]
        };
      } else {
        return state;
      }
    //

    case TYPES.SET_TRIP_PREVENTION_MODAL:
      return {
        ...state,
        preventionModal: !payload ? { open: false, message: '' } : payload
      };

    default:
      return state;
  }
};

export default reducer;
