import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const DrillDropdownInput = ({ error, value, onChange, ...inputComponentProps }) => {
  return (
    <Select
      isAsync
      isMulti={true}
      value={value}
      onChange={onChange}
      getOptionValue={option => option.id}
      defaultOptions={() => getInitialAsyncValues('drills')}
      loadOptions={search => getAsyncOptions(search, 'drills')}
      getOptionLabel={option => option.name}
      className="mb-0"
      error={error}
      placeholder="Select"
      {...inputComponentProps}
    />
  );
};

export default DrillDropdownInput;
