import React from 'react';
import { DropdownItem } from 'reactstrap';
import AtSeaMenu from './AtSeaMenu';
import { convertAtSeaName } from './helpers';

const Item = ({
  item,
  value,
  toggleDropdownMenu,
  setSelected,
  isAtSea,
  isSeaPortOpen,
  isSeaPortOpenHandler,
  saveHandler,
  coordinates,
  selectField,
  setKeepCoordinates,
  cancelHandler,
  onPortSelect,
  saveBtnTitle
}) => {
  return (
    <DropdownItem
      tag="div"
      active={(value && value.id) === item.id ? true : false}
      className={`${isAtSea ? '' : 'btn'} d-flex align-items-center fw-normal ${
        isAtSea && isSeaPortOpen ? 'menuAtSea--menuOpen' : ''
      }`}
      onClick={() => {
        if (isAtSea) {
          if (!isSeaPortOpen) {
            isSeaPortOpenHandler(true, true);
          }
        } else {
          toggleDropdownMenu();
          isSeaPortOpenHandler(false, true);
          setSelected(item);
          onPortSelect && onPortSelect(item);

          setKeepCoordinates(false);
          selectField('lat')(' ');
          selectField('lon')(' ');
        }
      }}
    >
      {isAtSea ? (
        <div
          className={`at-sea-container ${
            isSeaPortOpen ? 'at-sea-container--containerOpen' : 'at-sea-container--containerClose'
          }`}
        >
          <span
            className={`text-primary fs-12 ${isSeaPortOpen ? 'fw-bold' : 'fw-normal'}`}
            onClick={() => isSeaPortOpen && isSeaPortOpenHandler(false, true)}
          >
            {convertAtSeaName(item.name)}
          </span>
          {isSeaPortOpen && (
            <AtSeaMenu
              atSeaValue={item}
              saveHandler={saveHandler}
              coordinates={coordinates}
              selectField={selectField}
              onCancelMenu={cancelHandler}
              saveBtnTitle={saveBtnTitle}
            />
          )}
        </div>
      ) : (
        <span className="text-wrap">{item.name}</span>
      )}
    </DropdownItem>
  );
};

export default Item;
