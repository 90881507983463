import { useEffect } from 'react';
import {
  selectPreviousCaptainReport,
  selectPreviousNavigational,
  selectCaptainReportStatus,
  selectCaptainReportIsOnBoard
} from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import _get from 'lodash/get';

const useInitializeFromPreviousReport = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const previousReportData = useSelector(selectPreviousCaptainReport);
  const previousNavigationalData = useSelector(selectPreviousNavigational);
  const status = useSelector(selectCaptainReportStatus);
  const isOnBoard = useSelector(selectCaptainReportIsOnBoard);

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const init = () => {
    const data = field.previousReportInitializationKey
      ? previousReportData
      : previousNavigationalData;
    const key = field.previousReportInitializationKey
      ? field.previousReportInitializationKey
      : field.previousNavigationalInitializationKey;

    let previousValue = _get(data, `${key}.value`, _get(data, key, null));
    previousValue = previousValue || previousValue === 0 ? previousValue : null;

    setValue({
      key: field.key,
      value: previousValue,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex,
      selectKey: _get(field, 'selectRest.selectKey')
    });
  };

  useEffect(() => {
    if (
      (!field.previousReportInitializationKey && !field.previousNavigationalInitializationKey) ||
      status !== 'draft' ||
      !isOnBoard ||
      !!value
    )
      return;
    if (value === 0) return;

    init();
  }, []);
};

export default useInitializeFromPreviousReport;
