import moment from 'moment';
import DateInput from 'common/components/form/inputs/date';

import { Row, Col, Button } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import binIcon from 'common/assets/svg/actions/delete.svg';
import CircledButton from '@/ts-common/components/buttons/CircledButton';

const FormDateInput = ({ disabled, value, onChange, error }) => {
  const dates = disabled || !value || !value?.length ? [''] : value;

  const onDateChange = (newDate, index) => {
    const updatedDates = dates.map((date, key) => {
      if (key === index) return newDate ? newDate.format('YYYY-MM-DD') : null;

      return date;
    });

    onChange(updatedDates);
  };

  const onRemove = index => {
    const updatedDates = dates.filter((_, key) => key !== index);

    onChange(updatedDates);
  };

  const onAdd = () => {
    onChange([...dates, '']);
  };

  return (
    <div>
      {(dates || []).map((date, index) => (
        <Row key={index} className="align-items-center mb-1 position-relative" noGutters>
          <Col xs="auto">
            <DateInput
              onChange={newDate => (disabled ? null : onDateChange(newDate, index))}
              className="mb-0 me-1"
              value={disabled ? null : date ? moment(date, 'YYYY-MM-DD') : null}
              error={error}
            />
          </Col>
          {index !== 0 || (index === 0 && date) ? (
            <Col className="multiple-date-remove" xs="auto">
              <Button
                type="button"
                color="link"
                className="p-0 text-red cursor-pointer"
                onClick={() => onRemove(index)}
                disabled={disabled}
              >
                <SvgRender
                  className="pointer-events-none"
                  src={binIcon}
                  style={{ width: 16, height: 16 }}
                />
              </Button>
            </Col>
          ) : null}
        </Row>
      ))}
      <CircledButton type="add" disabled={disabled} onClick={onAdd} label="Add more" />
    </div>
  );
};

export default FormDateInput;
