import { oneOfType, func, number, string, bool } from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import starSolid from 'common/assets/svg/common/star-solid.svg';
import starIcon from 'common/assets/svg/common/star.svg';
import { numberToStr } from 'common/utils/numbers';
import { Button } from 'reactstrap';

const Evaluation = ({ combinedRating, rating, onEvaluate, className = '', disabled }) => {
  return (
    <div className={`d-flex ${className}`}>
      <div className="d-flex align-items-center justify-content-between bg-violet border-radius-3 width-56 px-1 height-20 me-1">
        <SvgRender
          className="text-white cme-10"
          src={starSolid}
          style={{ height: 12, width: 12, paddingTop: 0 }}
        />
        <span className="fs-12 fw-bold text-white">{combinedRating || '-'}</span>
      </div>

      <Button
        color="yellow"
        className={`px-1 py-0 height-20 d-flex align-items-center justify-content-start ${
          rating ? 'width-56 me-3' : 'width-82 '
        } ${disabled ? 'pointer-events-none' : ''}`}
        onClick={onEvaluate}
      >
        <SvgRender
          className="cme-10 text-primary"
          src={rating ? starSolid : starIcon}
          style={{ height: 12, width: 12, paddingTop: 0 }}
        />
        <span className="fs-12 fw-bold text-primary">
          {rating ? numberToStr(rating, 2, 1) : 'Evaluate'}
        </span>
      </Button>
    </div>
  );
};

Evaluation.propTypes = {
  combinedRating: oneOfType([string, number]),
  rating: number,
  onEvaluate: func.isRequired,
  className: string,
  disabled: bool
};

export default Evaluation;
