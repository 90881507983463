import ContentLoader from 'react-content-loader';

const PostsWithImageLoader = () => {
  return (
    <ContentLoader
      speed={1}
      backgroundColor="#F8F9FA"
      foregroundColor="#EEF0F4"
      preserveAspectRatio="none"
      width="100%"
      height={351}
      viewBox="0 0 100 351"
    >
      <rect x="3" y="0" width="45" height="1" />
      <rect x="3" y="51" rx="12" ry="0" width="45" height="126" />
      <rect x="51" y="51" rx="4" ry="0" width="45" height="5" />
      <rect x="51" y="61" rx="4" ry="0" width="8" height="8" />
      <rect x="51" y="76" rx="4" ry="0" width="45" height="10" />
      <rect x="51" y="91" rx="4" ry="0" width="45" height="10" />
      <rect x="51" y="106" rx="4" ry="0" width="45" height="10" />
      <rect x="51" y="121" rx="4" ry="0" width="27" height="10" />
      <rect x="51" y="136" rx="4" ry="0" width="27" height="10" />
      <rect height="12" rx="1" ry="1" width="3" x="51" y="162" />
      <rect height="12" rx="1" ry="1" width="3" x="55" y="162" />
      <rect x="3" y="202" width="1" height="1" />
      <rect x="3" y="203" rx="12" ry="0" width="45" height="126" />
      <rect x="51" y="203" rx="4" ry="0" width="45" height="5" />
      <rect x="51" y="213" rx="4" ry="0" width="8" height="8" />
      <rect x="51" y="221" width="1" height="5" />
      <rect x="51" y="233" rx="4" ry="0" width="45" height="10" />
      <rect x="51" y="248" rx="4" ry="0" width="45" height="10" />
      <rect x="51" y="263" rx="4" ry="0" width="45" height="10" />
      <rect x="51" y="278" rx="4" ry="0" width="27" height="10" />
      <rect x="51" y="293" rx="4" ry="0" width="27" height="10" />
      <rect height="12" rx="1" ry="1" width="3" x="51" y="316" />
      <rect height="12" rx="1" ry="1" width="3" x="55" y="316" />
    </ContentLoader>
  );
};

export default PostsWithImageLoader;
