import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import vesselIcon from 'common/assets/svg/common/vessels.svg';

import { useEffect } from 'react';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectVesselFilter } from 'common/components/mga/store/selectors';
import { selectListDefaultOptions } from 'store/lists/selectors';

import * as listActions from 'store/lists/actions';
import useRouter from 'use-react-router';

import paths from 'routing/routes/_paths';

const VesselSelector = () => {
  const vesselFilter = useSelector(selectVesselFilter);
  const vessels = useSelector(state => selectListDefaultOptions(state, 'vessels'));

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const { history, match } = useRouter();

  const onChange = id => {
    history.replace(`${paths.mga}/${match.params.view}${id ? `/${id}` : ''}`);
  };

  useEffect(() => {
    if (!vessels.length) {
      fetchListOptions('vessels');
    }
  }, []);

  return (
    <IconSelect
      options={vessels}
      isMulti={false}
      isClearable={true}
      placeholder="Select vessel"
      getOptionValue={v => v.id}
      getOptionLabel={v => v.name}
      onChange={onChange}
      value={vesselFilter}
      icon={vesselIcon}
      className="me-1"
      containerClassName={`mga-vessel-selector`}
    />
  );
};

export default VesselSelector;
