import SvgRender from 'common/components/general/SvgRender';

import draft from 'common/assets/svg/common/draft.svg';
import versioning from 'common/assets/svg/common/versioning.svg';
import deleteIcon from 'common/assets/svg/actions/delete.svg';

import { selectCurrentVersionStatus, selectIsComparedAtVersion } from 'manuals/store/selectors';

import { useSelector } from 'react-redux';

const ChapterRequests = ({ chapter, hasChildren, isOpen }) => {
  const hasRequests = chapter.open_crs || chapter.draft_crs || chapter.approved_crs;
  const currentVersionStatus = useSelector(selectCurrentVersionStatus);
  const isComparedAtVersion = useSelector(selectIsComparedAtVersion);

  if (isComparedAtVersion) {
    if (chapter.compare_is_new) {
      return <div className="chapters-tree__view-requests__bullet-status bg-turquoise me-1"></div>;
    }

    if (chapter.compare_is_deleted) {
      return <div className="chapters-tree__view-requests__bullet-status bg-red me-1"></div>;
    }

    if (chapter.compare_has_changed) {
      return <div className="chapters-tree__view-requests__bullet-status bg-yellow me-1"></div>;
    }

    return null;
  }

  if (currentVersionStatus === 'pending_approval') {
    if (chapter.approved_change_request_count || (chapter.approved_crs && !isOpen)) {
      if (chapter.is_new) {
        return <div className="chapters-tree__view-requests__bullet-status bg-turquoise"></div>;
      } else {
        return <div className={`chapters-tree__view-requests__bullet-status bg-yellow`} />;
      }
    }

    return null;
  }

  if (chapter.deleted) {
    if (hasChildren) {
      return <div className={`chapters-tree__view-requests deleted`}>D</div>;
    } else {
      return (
        <SvgRender
          className={`chapters-tree__view-requests-icon deleted text-red`}
          src={deleteIcon}
          style={{ width: 14, height: 14 }}
        />
      );
    }
  }

  return hasRequests && hasChildren && !isOpen ? (
    <>
      {chapter.draft_crs ? (
        <div className="chapters-tree__view-requests draft">{chapter.draft_crs}</div>
      ) : null}
      {chapter.open_crs ? (
        <div className="chapters-tree__view-requests">{chapter.open_crs}</div>
      ) : null}
    </>
  ) : (!hasChildren && hasRequests) ||
    (hasChildren && (chapter.draft_change_request_count || chapter.open_change_request_count)) ? (
    <SvgRender
      className={`chapters-tree__view-requests-icon text-${
        chapter.draft_change_request_count ? 'violet' : 'yellow'
      }`}
      src={chapter.draft_change_request_count ? draft : versioning}
      style={{ width: 18, height: 18 }}
    />
  ) : null;
};

export default ChapterRequests;
