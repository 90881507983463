import React, { useEffect } from 'react';
import { Label } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import { components } from 'react-select';
import Select from 'common/components/form/inputs/Select';
import Priority from 'common/components/general/Priority';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { DropdownIndicator } from 'common/components/form/inputs/Select';

import * as listActions from 'store/lists/actions';

const PrioritySelector = ({
  filter,
  onChange,
  isMulti,
  label,
  placeholder,
  isClearable = true,
  withText = true,
  styled,
  ...rest
}) => {
  const SelectTag = styled ? StyledSelect : Select;
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const { options, isFetching } = useSelector(state => state.lists['job-importances']);

  useEffect(() => {
    if (!options.length && !isFetching) {
      fetchListOptions('job-importances');
    }
  }, []);

  return (
    <div>
      {label && <Label className="form-label">{label}</Label>}

      <SelectTag
        className={`form-field position-relative`}
        autoFocus
        placeholder={placeholder ? placeholder : 'Select value'}
        components={{ DropdownIndicator, Option, MultiValueLabel, SingleValue }}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        value={filter.value}
        options={options}
        isMulti={isMulti}
        onChange={selected => onChange({ value: selected })}
        isClearable={isClearable}
        withText={withText}
        {...rest}
      />
    </div>
  );
};

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.label ? (
        <div className="d-flex align-items-center justify-content-center h-100p">
          <Priority value={props.data.label} withText={props.selectProps.withText} />
        </div>
      ) : null}
    </components.MultiValueLabel>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.label ? (
        <div className="d-flex align-items-center justify-content-center h-100p">
          <Priority value={props.data.label} withText={props.selectProps.withText} />
        </div>
      ) : null}
    </components.SingleValue>
  );
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.label ? <Priority value={props.data.label} withText /> : null}
    </components.Option>
  );
};

export default PrioritySelector;
