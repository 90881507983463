import React from 'react';
import PropTypes from 'prop-types';

import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';

const LubOilAnalysisAttachments = ({ id, attachments, fileType }) => {
  return (
    <DownloadAttachment
      data={{ attachments }}
      downloadFiles={() =>
        downloadAllFilesOfEntity({
          id,
          type: 'event_lub_oil_analysis_assignment',
          file_type: fileType
        })
      }
      hasAttachments={attachments?.length > 0}
      canAddAttachments={false}
    />
  );
};

LubOilAnalysisAttachments.propTypes = {
  id: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired, // The lub analysis module event id
  attachments: PropTypes.arrayOf(PropTypes.object),
  fileType: PropTypes.oneOf(['office_attachments', 'vessel_attachments']).isRequired
};

export default LubOilAnalysisAttachments;
