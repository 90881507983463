import permissions from 'common/utils/permissions/constants';
import _isEmpty from 'lodash/isEmpty';
import { isAuthorized } from 'utils/permissions/authorize';

export const jobPermissions = {
  office: {
    edit: [permissions.office.jobs.edit],
    create: [permissions.office.jobs.create]
  },
  onboard: {
    edit: [permissions.onboard.jobs.edit]
  }
};

export const validateOnboardUserPermissions = ({ account }) =>
  isAuthorized(account, jobPermissions.onboard.edit);

export const validateOfficeUserPermissions = ({ account, editMode }) =>
  editMode
    ? isAuthorized(account, jobPermissions.office.edit)
    : isAuthorized(account, jobPermissions.office.create);

// Function to check if a user is able to edit restricted fields in Job
export const isUserAllowedToEdit = ({ restrictedFields, typeOfLoggedInUser, avoidCheck }) => {
  if (avoidCheck) return true;

  if (restrictedFields && !_isEmpty(typeOfLoggedInUser)) {
    if (restrictedFields.settings) {
      return !restrictedFields.settings.can_edit.length
        ? true
        : restrictedFields.settings.can_edit.some(
            item =>
              (item === typeOfLoggedInUser.approvers.name && typeOfLoggedInUser.approvers.value) ||
              (item === typeOfLoggedInUser.assignee.name && typeOfLoggedInUser.assignee.value) ||
              (item === typeOfLoggedInUser.creator.name && typeOfLoggedInUser.creator.value)
          );
    } else {
      return true;
    }
  } else {
    return true;
  }
};
