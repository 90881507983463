import React, { useState } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';

import MenuItem from './MenuItem';
import CurrentVersion from 'components/layouts/sidebar/panel/components/CurrentVersion';
import NewsAndAnnouncementsButton from 'common/components/news-and-announcements/_components/NewsAndAnnouncementsButton';

import useRouter from 'use-react-router';

import arrow from 'assets/svg/common/arrow.svg';

import CertificationBanner from 'common/components/banners/CertificationBanner';

const Menu = () => {
  const { location } = useRouter();
  const toggleSubmenu = item => setActive(item);
  const menu = useSelector(state => state.sidebar.menu);

  const getActive = () => {
    return menu.items.find(
      i =>
        i.submenu &&
        i.submenu.some(
          s => location.pathname.startsWith(s.path) || location.pathname.startsWith(s.groupPath)
        )
    );
  };
  const [active, setActive] = useState(getActive());

  return (
    <div className={`sidebar-menu d-flex flex-column h-100p ${active ? ' submenu-open' : ''}`}>
      <div className="ps-2 text-uppercase">{active ? active.name : 'ORCA.TOOLS'}</div>
      <div className={`sidebar-menu--inner flex-1 ${active ? 'mt-4' : 'cmt-12'}`}>
        <div className="d-flex flex-column sidebar-menu--section sidebar-menu--top pt-1 gray-scrollbar overflow-y">
          <div className="d-flex flex-column">
            {menu.items.map((item, index) => (
              <MenuItem key={index} item={item} onItemClick={toggleSubmenu} />
            ))}
          </div>
          <NewsAndAnnouncementsButton />
        </div>

        <div className="sidebar-menu--section sidebar-menu--sub gray-scrollbar overflow-y">
          {active ? (
            <div className="sidebar-menu--sub-inner h-100p d-flex flex-column">
              <div
                className="sidebar-menu--sub-back mb-2 d-inline-flex align-items-center"
                onClick={() => toggleSubmenu(null)}
              >
                <SvgRender src={arrow} style={{ width: 10, height: 10 }} />
                Back to menu
              </div>
              <div className="overflow-y flex-1">
                {active.submenu.map((item, index) => (
                  <MenuItem
                    isDashboard={active.label === 'dashboard'}
                    key={index}
                    item={item}
                    sub
                  />
                ))}
              </div>
              {active?.certified ? <CertificationBanner text={active.certified} /> : null}
            </div>
          ) : null}
        </div>
      </div>
      <CurrentVersion />
    </div>
  );
};

export default Menu;
