import React, { useState } from 'react';
import DateInput from 'common/components/form/inputs/date';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode
} from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceDetaisField,
  selectPreviousMaintenanceDetailsField,
  selectIsUnscheduledJob
} from 'common/components/pms/jobs/store/selectors';
import { selectJobPeriodicityHasRunningHours } from 'common/components/pms/jobs/store/selectors';
import { Button } from 'reactstrap';
import moment from 'moment';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';
import { useActions } from 'utils/hooks';
import edit from 'common/assets/svg/actions/edit.svg';
import SvgRender from 'common/components/general/SvgRender';
import DueDateInputModal from './DueDateInputModal';
import DueDateInputTooltip from './DueDateInputTooltip';
import DueDateInputRemarksPopover from './DueDateInputRemarksPopover';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const DueDateInput = ({
  isPrevious,
  isDueTypeField,
  dateFieldKey,
  dateTitleField,
  dateInputProps,
  isLocked,
  isDisabled,
  color
}) => {
  const [remarksModalOpen, setRemarksModalOpen] = useState(false);

  const isEditMode = useSelector(checkIfEditMode);
  const isUnscheduledJob = useSelector(selectIsUnscheduledJob);
  const hasRunningHoursPeriodicity = useSelector(selectJobPeriodicityHasRunningHours);

  const [setMaintenanceField, setJobField, setJobFieldError] = useActions([
    maintenanceActions.setMaintenanceField,
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError
  ]);

  const isOnBoard = useSelector(state => state.isOnBoard);

  const isUnscheduledJobOnCreate = isUnscheduledJob && !isEditMode;

  const dueDateValue = useSelector(state => selectJobField(state, 'due_date'));

  const dateFieldError = useSelector(state => selectJobFieldError(state, dateFieldKey));
  const dateFieldValue = useSelector(state =>
    isPrevious
      ? selectPreviousMaintenanceDetailsField(state, dateFieldKey)
      : selectMaintenanceDetaisField(state, dateFieldKey)
  );

  const onDateChange = d => {
    if (isDueTypeField) {
      setJobField('due_date', d ? d : null, true, false, !isOnBoard);

      if (dateFieldError && d) {
        setJobFieldError({ due_date: null });
      }
    } else {
      setMaintenanceField(dateFieldKey, d ? d.format('YYYY-MM-DD HH:mm:ss') : null, true);
    }
  };

  const { avoidRender, tooltipID } = useTooltipID('due-date-id');

  return (
    <>
      <div
        className={`fs-10 fw-bold text-primary cmb-4 text-${color} d-flex align-items-center`}
        data-testid="pms-due-date-label"
      >
        {hasRunningHoursPeriodicity && isDueTypeField ? 'ESTIMATED DUE DATE' : dateTitleField}
      </div>
      <div className="d-flex align-items-center">
        <div id={tooltipID}>
          <DateInput
            datePlaceholder="Set Date"
            value={isDueTypeField ? dueDateValue : dateFieldValue ? moment(dateFieldValue) : null}
            onChange={onDateChange}
            disabled={
              isOnBoard ||
              (!isOnBoard && !isDueTypeField) ||
              (!isOnBoard && isDueTypeField && isUnscheduledJobOnCreate)
                ? isLocked || isDisabled
                : true
            }
            error={dateFieldError}
            dataTestid="pms-due-date-input"
            innerClassName="min-w-160"
            {...dateInputProps}
          />
        </div>

        {isDueTypeField && !isUnscheduledJobOnCreate ? (
          <>
            {!isOnBoard && (
              <Button
                className="text-primary ms-1 cursor-pointer lh-12 p-0"
                onClick={() => setRemarksModalOpen(true)}
                color="link"
                disabled={isLocked || isDisabled}
                data-testid="pms-due-date-edit"
              >
                <SvgRender style={{ height: 14, width: 14 }} src={edit} />
              </Button>
            )}
            <DueDateInputRemarksPopover />
            {!isOnBoard && <DueDateInputTooltip avoidRender={avoidRender} tooltipID={tooltipID} />}
          </>
        ) : null}
      </div>

      <DueDateInputModal
        remarksModalOpen={remarksModalOpen}
        setRemarksModalOpen={setRemarksModalOpen}
        label={hasRunningHoursPeriodicity && isDueTypeField ? 'Estimated Due Date' : 'Due Date'}
      />
    </>
  );
};

export default DueDateInput;
