import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_RUNNING_HOURS'),
  ...asyncTypes('UPDATE_RUNNING_HOURS'),
  ...asyncTypes('APPROVE_RUNNING_HOURS'),
  ...asyncTypes('REVOKE_APPROVAL_RUNNING_HOURS'),
  ...asyncTypes('REBUILD_RUNNING_HOURS'),
  ...asyncTypes('GET_RUNNING_HOURS_TOTALS'),
  SET_RUNNING_HOURS: 'SET_RUNNING_HOURS',
  SET_DATA_VALUE: 'SET_DATA_VALUE',
  SET_ASSIGNMENT_VALUE: 'SET_ASSIGNMENT_VALUE',
  SET_ERROR_VALUE: 'SET_ERROR_VALUE',
  SET_IS_EDITTING: 'SET_IS_EDITTING',
  RESET_STATE: 'RESET_STATE',
  SET_RUNNING_HOURS_REBUILD_COMMENTS: 'SET_RUNNING_HOURS_REBUILD_COMMENTS'
};

export default TYPES;
