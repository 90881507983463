import { useMemo } from 'react';
import { components } from 'react-select';

import Select from 'common/components/form/inputs/Select';
import moment from 'moment';
import _sortBy from 'lodash/sortBy';

export const loadYearOptions = (value, dateOptions, yearOptions = {}) => {
  const { yearStart, yearEnd } = yearOptions;
  const currentYear = value ? value?.year() : moment().year();
  const years = [];
  const OPTIONS_OFFSET = 3;
  const hasCustomYearEndings = yearStart !== undefined && yearEnd !== undefined;

  let minStartingYear = yearStart ? yearStart.clone().year() : currentYear;
  let endingYear = yearEnd ? yearEnd.clone().year() : currentYear;

  const minStartYear = yearStart
    ? yearStart.clone().year()
    : moment
        .min([...dateOptions?.map(option => option?.startDate), value ? value : currentYear])
        ?.year();

  const maxEndingYear = yearEnd
    ? yearEnd.clone().year()
    : moment
        .max([...dateOptions?.map(option => option?.endDate), value ? value : currentYear])
        ?.year();

  const belongsToPast = minStartYear < currentYear && maxEndingYear <= currentYear;
  const belongsToFuture = minStartYear >= currentYear && maxEndingYear > currentYear;
  const belongsToPresent = minStartYear === currentYear && maxEndingYear === currentYear;
  const belongsToPastAndFuture = minStartYear < currentYear && maxEndingYear >= currentYear;

  if (!hasCustomYearEndings) {
    if (belongsToPast || belongsToPresent) {
      minStartingYear = currentYear - OPTIONS_OFFSET;

      endingYear = currentYear + OPTIONS_OFFSET;
    } else if (belongsToFuture) {
      minStartingYear = currentYear;

      endingYear = currentYear + OPTIONS_OFFSET;
    } else if (belongsToPastAndFuture || !dateOptions?.length) {
      minStartingYear = currentYear - OPTIONS_OFFSET;

      endingYear = currentYear + OPTIONS_OFFSET;
    }
  }

  for (let i = minStartingYear; i <= endingYear; i++) {
    const year = {
      value: moment().year(i).format('YYYY'),
      label: moment().year(i).format('YYYY')
    };

    years.push(year);
  }

  if (
    hasCustomYearEndings &&
    value &&
    value?.year() &&
    !years.find(year => parseInt(year.value) === value?.year())
  ) {
    years.push({ value: value?.year(), label: value?.year() });
  }

  return _sortBy(years, ['value']);
};

const YearSelect = ({ value, onChange, dateOptions, yearStart, yearEnd }) => {
  const options = useMemo(
    () => loadYearOptions(value, dateOptions, { yearStart, yearEnd }),
    [
      dateOptions,
      value && value?.format('MMM-YYYY'),
      yearStart && yearStart?.format('MMM-YYYY'),
      yearEnd && yearEnd?.format('MMM-YYYY')
    ]
  );

  const handleYearChange = selectedYear => {
    onChange({ moment: value.clone().year(selectedYear) });
  };

  return (
    <Select
      options={options}
      className="mb-0 date-input-year-select"
      dataCy="date-input-year-select"
      placeholder=""
      invisible
      isCreatable={true}
      onCreateOption={str => handleYearChange(str)}
      formatCreateLabel={str => (
        <div className="text-center">
          <span className="fs-12">Set year:</span> <span className="fs-12 fw-medium">{str}</span>
        </div>
      )}
      components={{ Option, NoOptionsMessage }}
      value={value?.format('YYYY')}
      onChange={(_, y) => handleYearChange(y.value)}
      isValidNewOption={(inp, _, options) =>
        inp?.length === 4 && !isNaN(parseInt(inp)) && !options.filter(o => o.value == inp)?.length
      }
      noOptionsMessage={({ inputValue }) => (!inputValue.length ? `Search for years` : `No years`)}
      menuPortalTarget={null}
    />
  );
};

const Option = props => {
  return <components.Option {...props} />;
};

const NoOptionsMessage = props => {
  return (
    <div className="fs-12">
      <components.NoOptionsMessage {...props} />
    </div>
  );
};

export default YearSelect;
