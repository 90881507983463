import React from 'react';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { TableTypes } from 'common/entities/tables/TableTypes';
import { FormDrawer } from 'common/components/drawer';

import CreateForm from './CreateForm';
import EditForm from './EditForm';

const Form = ({ drawer, active, refetchData, setActive }) => {
  return (
    <Drawer {...drawer}>
      <DrawerHeader>{active ? 'Edit Expense' : 'Add Expense'}</DrawerHeader>

      <FormDrawer>
        {active ? (
          <>
            <EditForm
              drawer={drawer}
              active={active}
              refetchData={refetchData}
              setActive={setActive}
            />
          </>
        ) : (
          <CreateForm refetchData={refetchData} drawer={drawer} />
        )}
      </FormDrawer>
    </Drawer>
  );
};

Form.propTypes = {
  active: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    event_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes?.string,
    currency: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  drawer: DrawerState,
  refetchData: TableTypes.refetchData,
  setActive: PropTypes.func
};

export default Form;
