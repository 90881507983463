import { selectCrewWarnings, selectPeriodType } from 'crew/store/shifts/selectors';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import { getWarningStyles } from './helpers';

const CrewWarnings = ({ id, isEditing }) => {
  const warnings = useSelector(state => selectCrewWarnings(state, id)) || {};
  const datesWithWarnings = Object.keys(warnings).filter(date => warnings[date]?.length);

  const type = useSelector(selectPeriodType);

  if (!Object.keys(datesWithWarnings)?.length) return null;

  return (
    <div
      className={`border-top mt-1 me-1 pt-1 ps-${type === 'daily' && !isEditing ? '0' : '2'} ${
        isEditing ? 'border-white' : ''
      }`}
    >
      {_orderBy(Object.keys(warnings), date => date, 'asc').map(date =>
        warnings[date]?.length
          ? warnings[date].map((warning, i) => {
              const warningStyles = getWarningStyles(warning.warning);

              return (
                <Alert
                  className={`cmb-4 px-1 cpt-4 cpb-4 text-red fs-12 lh-15 rounded d-flex align-items-center ${warningStyles.className}`}
                  key={i}
                >
                  <SvgRender
                    src={warningStyles.icon}
                    style={{ width: 12, height: 12 }}
                    className="me-1"
                  />
                  <strong className="text-nowrap">{moment(date).format('DD/MM/YYYY')}</strong>
                  <span className="ps-1">{warning.message}</span>
                </Alert>
              );
            })
          : null
      )}
    </div>
  );
};

export default CrewWarnings;
