import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Spinner from 'common/components/general/Spinner';
import { selectRotateDirection } from 'common/components/gallery/store/selectors';
import { getFileMetadata, getFileStream } from '@/api/files/api';
import { getFileType } from '@/ts-common/utils/files';
import useWindowWidth from 'common/utils/hooks/useWindowWidth';

import Preview from './preview';

const GalleryCenterContainer = ({ file = {}, isGalleryHovered }) => {
  const file_type = getFileType(file.mime_type, file.extension);
  const rotateDirection = useSelector(selectRotateDirection);
  const [containerWidth, setContainerWidth] = useState();
  const [containerHeight, setContainerHeight] = useState();

  const containerRef = useRef();
  const windowWidth = useWindowWidth();

  const [isLoading, setIsLoading] = useState(false);

  const [fileMetadata, setFileMetadata] = useState(null);

  const [fileData, setFileData] = useState(null);

  const loadFile = async () => {
    try {
      setIsLoading(true);
      const res = await getFileMetadata(file.id);
      const data = await getFileStream(file.id);
      setFileData(data);
      setFileMetadata(res);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setFileMetadata(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file?.id) loadFile();
  }, [file?.id]);

  useEffect(() => {
    if (containerRef?.current?.clientWidth && containerRef?.current?.clientHeight) {
      setContainerWidth(containerRef?.current?.clientWidth);
      setContainerHeight(containerRef?.current?.clientHeight);
    }
  }, [windowWidth, containerRef?.current?.clientWidth]);

  return (
    <div
      ref={containerRef}
      className={`gallery__center--container w-100p ${
        rotateDirection !== 'top' && rotateDirection !== 'bottom'
          ? 'd-flex align-items-center justify-content-center'
          : ''
      }`}
    >
      {isLoading ? (
        <div className="position-absolute top-50  start-0 w-100p text-center ">
          <Spinner size={48} className="gallery__center--container-loader" />
        </div>
      ) : fileMetadata ? (
        <Preview
          key={file?.id}
          isGalleryHovered={isGalleryHovered}
          file={{ ...file, ...fileMetadata, fileData: fileData }}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          type={file_type.type}
        />
      ) : null}
    </div>
  );
};

export default GalleryCenterContainer;
