import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const DrillsSelector = ({ filter, value, onChange, isMulti, autoFocus = true, ...rest }) => {
  const [selectedDrills, setSelectedDrills] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'drills' },
    { selected: selectedDrills, setSelected: setSelectedDrills }
  );

  return (
    <Select
      autoFocus={autoFocus}
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      size="sm"
      value={
        value !== undefined
          ? value
          : filter?.value
          ? selectedDrills.filter(el => filter?.value.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() => getInitialAsyncValues('drills')}
      loadOptions={search => getAsyncOptions(search, 'drills')}
      onChange={selected => {
        setSelectedDrills(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      {...rest}
    />
  );
};

export default DrillsSelector;
