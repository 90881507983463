import { createSelector } from 'reselect';
import paths from 'routing/routes/_paths';

const getKey = (_, key) => key;

const selectEventsReducer = state => state.events;

const selectPathName = state => state?.router?.location?.pathname;

export const selectEvent = createSelector([selectEventsReducer], events => events.event);

export const selectEventId = createSelector([selectEvent], event => event.id);

export const selectEventName = createSelector([selectEvent], event => event?.name);

export const selectEventVessel = createSelector([selectEvent], event => event?.vessel);
export const selectEventSavedVessel = state => selectEvent(state)?.savedVessel;
export const selectEventType = state => selectEvent(state)?.type;
export const selectEventSubtypes = state => selectEvent(state)?.subtypes;
export const selectEventResponsible = state => selectEvent(state)?.responsible;
export const selectEventDepartmentIds = state => selectEvent(state)?.department_ids;
export const selectEventApprovers = state => selectEvent(state)?.approvers;
export const selectEventIsSubmitted = state => selectEvent(state)?.submitted;
export const selectEventTags = state => selectEvent(state)?.tags;
export const selectEventImportance = state => selectEvent(state)?.importance;
export const selectEventRemarks = state => selectEvent(state)?.remarks;
export const selectEventStatus = state => selectEvent(state)?.status;
export const selectEventIsForVessel = createSelector([selectEvent], event => event?.is_for_vessel);
export const selectEventIsScheduled = state => selectEvent(state)?.is_scheduled;
export const selectEventPort = createSelector([selectEvent], event => event?.port);
export const selectEventItinerayPort = createSelector(
  [selectEvent],
  event => event?.itinerary_port
);
export const selectEventSavedItinerayPort = state => selectEvent(state)?.savedItineraryPort;

export const selectEventPortId = createSelector(
  [selectEventPort, selectEventItinerayPort],
  (port, itineraryPort) => itineraryPort?.port_id || port?.id
);

export const selectEventDateStartedAt = createSelector([selectEvent], event => event?.started_at);

export const selectEventDateEndedAt = createSelector([selectEvent], event => event?.ended_at);

export const selectEventModules = createSelector([selectEvent], event => event.modules);

export const selectEventModuleLabels = createSelector([selectEventModules], modules =>
  modules.map(el => el.type.label)
);

export const selectModuleIdByLabel = createSelector(
  [selectEventModules, getKey],
  (modules, label) => modules.find(module => module?.type?.label === label)?.id
);

export const selectMultiEventsDateValue = createSelector([selectEvent], event => event.dates);

export const selectIsMultipleEventsCreate = createSelector(
  [selectEvent],
  event => event.isMultipleEventsCreate
);

export const selectEventWatchers = state => selectEvent(state)?.watchers;

export const selectType = state => selectEvent(state).type;

export const selectEventTicketingCase = state => selectEvent(state)?.ticketing_case;

export const selectisEditingModules = createSelector(
  [selectEventsReducer],
  events => events.isEditingModules
);
export const selectActiveModulesLabels = createSelector(
  [selectEventsReducer],
  events => events.activeModulesLabels
);

export const selectIsActiveModule = (state, label) =>
  selectActiveModulesLabels(state).includes(label);

export const selectIsCreating = createSelector([selectEventsReducer], events => events.isCreating);
export const selectErrors = createSelector([selectEventsReducer], events => events.errors);

export const selectLoading = createSelector([selectEventsReducer], events => events.loading);
export const selectIsSaving = createSelector([selectEventsReducer], events => events.isSaving);

export const selectIsOnboard = state => state.isOnBoard;

export const selectIsSubmittingEvent = createSelector(
  [selectEventsReducer],
  events => events.isSubmittingEvent
);

export const selectIsCreate = createSelector([selectEvent], events => !events.id);

export const selectModuleSyncButtonIsVisible = state => {
  const isCreate = selectIsCreate(state);
  const isOnBoard = selectIsOnboard(state);
  const isForVessel = selectEventIsForVessel(state);

  if (!isCreate && !isOnBoard && isForVessel) return true;

  return false;
};

export const selectIsMainInfoLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);

  if (isCreate) return false;

  return isOnBoard;
};

export const selectIsStatusLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const status = selectEventStatus(state);

  if (!status?.id) return false;

  if (isOnBoard && status.edit_side === 'office') return true;
  if (!isOnBoard && status.edit_side === 'vessel') return true;

  return false;
};

export const selectIsTemplate = createSelector([selectPathName], pathName =>
  pathName?.startsWith(paths.event_templates)
);
