import React from 'react';

import Information from 'common/components/general/Information';
import { hours } from 'crew/utils/helpers';

const ShiftsTableHeader = () => {
  return (
    <div className="shifts-table--header">
      <div className="shifts-table--header-hours">
        {hours.map(hour => (
          <span
            key={hour}
            className={`fs-12 lh-15 text-primary fw-bold${hour !== '24' ? ' me-2' : ''}`}
          >
            {hour === '24' ? '00' : hour}
          </span>
        ))}
      </div>

      <div className="shifts-table--header-totals">
        <div className="d-flex flex-column shifts-table--header-totals--border pe-1 me-1">
          <span className="shifts-table--header-totals--title">Working Hours</span>
          <div className="w-100p d-flex">
            <div className="shifts-table--header-totals--type d-flex align-items-center">
              Normal{' '}
              <Information
                className="cms-6"
                message="As per Contract"
                svgStyle={{ width: 11, height: 11 }}
              />
            </div>
            <span className="shifts-table--header-totals--type">Worked</span>
            <span className="shifts-table--header-totals--type">Overtime</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <span className="shifts-table--header-totals--title">Hours of Rest</span>
          <div className="w-100p d-flex">
            <span className="shifts-table--header-totals--type shifts-table--header-totals--type-sm">
              any 24h
            </span>
            <span className="shifts-table--header-totals--type shifts-table--header-totals--type-sm">
              any 7d
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftsTableHeader;
