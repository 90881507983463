import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import jobs from 'common/components/pms/jobs/store/reducer';
import setup from 'common/components/pms/setup/store/reducer';

const pmsSetupPersistConfig = {
  key: 'pms.setup',
  storage,
  whitelist: ['groups', 'rootGroups', 'subGroups']
};

export default combineReducers({ jobs, setup: persistReducer(pmsSetupPersistConfig, setup) });
