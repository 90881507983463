import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import {
  selectPreviousCaptainReportBunkerPerFuelGrade,
  selectConsumptionFuelGrade
} from 'captain-reports/store/selectors';
import * as reportActions from 'captain-reports/store/actions';

const AutofillBunkerField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue, forceUpdateSectionProgress] = useActions([
    reportActions.setValue,
    reportActions.forceUpdateSectionProgress
  ]);

  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const prevBunker = useSelector(state =>
    selectPreviousCaptainReportBunkerPerFuelGrade(state, fuelGrade?.id)
  );

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    if (prevBunker?.[field.key] && !value && value !== 0) {
      setValue({
        key: field.key,
        value: prevBunker[field.key]?.value || null,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });

      forceUpdateSectionProgress(true);
    }
  }, [field.key, fuelGrade.id, prevBunker?.[field.key]]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default AutofillBunkerField;
