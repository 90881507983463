import _get from 'lodash/get';
import Departments from 'common/components/general/Departments';
import { CrewDepartment } from 'common/components/selectors/departments/CrewDepartment';
import { Link } from 'react-router-dom';
import paths from 'routing/routes/_paths';

const EvaluatorDepartment = ({ evaluator, evaluatorType }) => {
  const evaluatorName = _get(evaluator, 'name', '');
  const crewMemberName = _get(evaluator, 'full_name', '');

  if (!evaluator) return null;

  return evaluatorType === 'department' || evaluatorType === 'crew_department' ? (
    <Departments values={[evaluator]} />
  ) : evaluatorType === 'crew_member' ? (
    <Link className="link-medium" to={`${paths.crew}/${evaluator.id}`}>
      {crewMemberName}
    </Link>
  ) : (
    <div>
      <CrewDepartment name={evaluatorName} />
    </div>
  );
};

export default EvaluatorDepartment;
