export const MAX_ALLOWED_SCROLL_OFFSET = 18;
export const SCROLL_PERCENTAGE = 15;

export const calculateMinAllowedLeft = () => {
  const parent = document
    .querySelector('.event-toolbar__slider-container')
    ?.getBoundingClientRect();
  const child = document
    .querySelector('.event-toolbar__slider-container-slides')
    ?.getBoundingClientRect();

  if (!parent || !child) return 0;

  const widthPercentageDiff = ((child.width - parent.width) / parent.width) * 100;

  return -widthPercentageDiff;
};

export const getCorrectLeft = (prev, isLeft, minAllowedLeft) => {
  if (isLeft) {
    if (prev + SCROLL_PERCENTAGE > 0) return 0;

    return prev + SCROLL_PERCENTAGE;
  } else {
    if (prev - SCROLL_PERCENTAGE < minAllowedLeft) return minAllowedLeft;

    return prev - SCROLL_PERCENTAGE;
  }
};
