import { FC, PropsWithChildren } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const ScrollableMidSection: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Scrollbars
      className="scroll-wrapper scrollable-content"
      onScroll={e => {
        const header = document.querySelector('.job-header');
        const target = e.target as HTMLElement;

        if (header) {
          if (target.scrollTop > 0 && !header.classList.contains('scrolled')) {
            header.classList.add('scrolled');
          } else if (target.scrollTop === 0 && header.classList.contains('scrolled')) {
            header.classList.remove('scrolled');
          }
        }
      }}
      autoHide
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollableMidSection;
