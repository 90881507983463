export const columns = [
  {
    header: 'P.',
    key: 'priority',
    type: 'collection',
    sort: true,
    minWidth: 50,
    maxWidth: 50,
    headerClassName: 'bg-transparent',
    canFilter: false
  },
  {
    header: 'NO.',
    key: 'code',
    sort: true,
    headerClassName: 'bg-transparent',
    minWidth: 155,
    maxWidth: 155
  },
  {
    header: 'Vessel/Company',
    key: 'vessel_company',
    type: 'string',
    sort: true,
    headerClassName: 'bg-transparent',
    minWidth: 144,
    maxWidth: 144
  },
  {
    header: 'DESCRIPTION',
    key: 'description',
    sort: true,
    headerClassName: 'bg-transparent',
    minWidth: 300,
    maxWidth: 300
  },
  {
    header: 'Tags',
    key: 'tag_id',
    type: 'string',
    headerClassName: 'bg-transparent',
    minWidth: 147,
    maxWidth: 147
  },
  {
    header: 'Category',
    key: 'category_id',
    type: 'string',
    headerClassName: 'bg-transparent',
    minWidth: 224,
    maxWidth: 224
  },
  {
    header: 'Submitted At',
    key: 'submitted_at',
    sort: true,
    type: 'date',
    headerClassName: 'bg-transparent',
    minWidth: 130,
    maxWidth: 130
  },
  {
    header: 'STATUS',
    key: 'status',
    sort: true,
    headerClassName: 'bg-transparent',
    canFilter: false
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];
