const selectVesselCondition = state => state.eventModules.vesselCondition;

export const selectVesselConditiondData = state => selectVesselCondition(state).data;
export const selectVesselConditionRange = state => selectVesselConditiondData(state).range;
export const selectVesselConditionRemarks = state => selectVesselConditiondData(state)?.remarks;

export const selectVesselConditionUpdatedBy = state =>
  selectVesselConditiondData(state).updated_by?.full_name;

export const selectVesselConditionUpdatedAt = state => selectVesselConditiondData(state).updated_at;

export const selectVesselConditionCreatedBy = state =>
  selectVesselConditiondData(state).created_by?.full_name;

export const selectVesselConditionCreatedAt = state => selectVesselConditiondData(state).created_at;
