import _reduce from 'lodash/reduce';
import _findKey from 'lodash/findKey';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';

export const getItemsIDs = items => items.map(item => item.id);

export const getRootItemsIDs = items => {
  return getItemsIDs(items.filter(item => !item.parent_id));
};

export const getRootItems = items => {
  return items.filter(item => !item.parent_id);
};

export const getSubItems = items => {
  const subItems = _groupBy(
    items.filter(item => item.parent_id),
    item => item.parent_id
  );

  return _reduce(
    Object.keys(subItems),
    (result, id) => {
      const parent = items.find(c => c.id === (isNaN(id) ? id : parseInt(id)));

      if (parent) {
        result[parent.id] = getItemsIDs(subItems[id]);
      }

      return result;
    },
    {}
  );
};

export const normalizeTreeStructure = items =>
  _reduce(
    items,
    (result, value) => {
      result[value.id] = value;
      return result;
    },
    {}
  );

export const toggleNestedItems = (subItems, open) => {
  return !open ? {} : _mapValues(subItems, () => true);
};

export const findParentItem = (parentID, stateItems) => {
  return _findKey(stateItems, c => c.id === parentID);
};

export const getAscendanceTree = (itemID, stateItems) => {
  const item = stateItems[itemID];
  if (!item) return [];

  let tree = [item];

  if (item.parent_id) {
    const parentID = findParentItem(item.parent_id, stateItems);

    tree = [...tree, ...getAscendanceTree(parentID, stateItems)];
  }

  return tree;
};

export const getDescendanceTree = (itemID, stateItems, subItems) => {
  const item = stateItems[itemID];
  if (!item) return [];

  let tree = [item];

  if (subItems[itemID]) {
    subItems[itemID].forEach(childID => {
      tree = [...tree, ...getDescendanceTree(childID, stateItems, subItems)];
    });
  }

  return tree;
};

export const getTreeOfSearchedItems = (items, subItems, matchedItems) => {
  let treeInSearch = {};

  matchedItems.forEach(item => {
    const itemDescendanceTree = getDescendanceTree(
      item.parent_id || item.id,
      items,
      subItems
    ).reduce((obj, curP) => {
      obj[curP.id] = true;

      return obj;
    }, {});

    const itemAscendanceTree = getAscendanceTree(item.id, items).reduce((obj, curP) => {
      obj[curP.id] = true;

      return obj;
    }, {});

    treeInSearch = { ...treeInSearch, ...itemAscendanceTree, ...itemDescendanceTree };
  });

  return treeInSearch;
};

export const getItemBreadcrumbs = (itemID, stateItems) => {
  return getAscendanceTree(itemID, stateItems).reverse();
};
