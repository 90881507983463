import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import {
  selectConsumptionFuelGrade,
  selectCaptainReportBunkeringPerFuelGrade,
  selectConsumptionBunkerConsumptionIndex
} from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';

const SuppliedCorrectionWarning = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const bunkeringData = useSelector(state =>
    selectCaptainReportBunkeringPerFuelGrade(state, fuelGrade?.id)
  );
  const bunkerConsumptionIndex = useSelector(state =>
    selectConsumptionBunkerConsumptionIndex(state, 'consumptions', subGroupIndex)
  );

  const suppliedKey = `bdn_${field.key}`;
  const bunkerSurveyValue = strToNumber(
    _get(bunkeringData, `[${bunkerConsumptionIndex}].${suppliedKey}`)
  );

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const color = useMemo(() => {
    const formattedValue = strToNumber(value);

    if (bunkerSurveyValue === null) {
      return '';
    } else if (formattedValue === bunkerSurveyValue) {
      return 'text-turquoise';
    } else {
      return 'text-red';
    }
  }, [value, bunkerSurveyValue]);

  return <div className={`text-end fs-12 ${color} pe-1`}>{numberToStr(bunkerSurveyValue)}</div>;
};

export default SuppliedCorrectionWarning;
