import React from 'react';
import PropTypes from 'prop-types';
import OvalButton from 'common/components/buttons/OvalButton';
import FormSaveActions from 'common/components/form/FormSaveActions';
import styled from '@emotion/styled';
import AuthCheck from 'components/permissions/AuthCheck';
import { jobPermissions } from '../permissions/helpers';
import { useSelector } from 'react-redux';
import { selectIsJobLocked, selectJobField } from '../store/selectors';

const ModuleTabActions = ({
  header,
  isEditMode = false,
  isDisabled = false,
  isSaveDisabled = false,
  isHovering = false,
  onCancel,
  onSave,
  onEdit,
  onDelete
}) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const isJobLocked = useSelector(selectIsJobLocked);

  return (
    <div className="d-flex flex-nowrap align-items-center mb-1 min-height-20">
      <div className="flex-1 form-label mb-0">{header}</div>
      {isJobLocked ? null : (
        <AuthCheck permissions={jobId ? jobPermissions.office.edit : jobPermissions.office.create}>
          <Actions className="d-flex" isHovering={isHovering}>
            <FormSaveActions
              mode={isEditMode ? 'edit' : 'view'}
              isDisabled={isDisabled}
              onSave={onSave}
              onCancel={onCancel}
              onEdit={onEdit}
              onDelete={onDelete}
              CancelButtonComponent={OvalButton}
              EditButtonComponent={OvalButton}
              SaveButtonComponent={OvalButton}
              DeleteButtonComponent={OvalButton}
              cancelButtonProps={{ size: 'sm' }}
              editButtonProps={{ size: 'sm' }}
              saveButtonProps={{ size: 'sm', disabled: isDisabled || isSaveDisabled }}
              deleteButtonProps={{ size: 'sm', text: 'Delete Widget' }}
              style={{ top: 0 }}
              position="relative"
            />
          </Actions>
        </AuthCheck>
      )}
    </div>
  );
};

const Actions = styled.div`
  .oval-button__delete {
    order: 1;
    opacity: ${({ isHovering }) => (isHovering ? 1 : 0)};
  }

  .oval-button__edit {
    order: 2;
  }
`;

ModuleTabActions.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isEditMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHovering: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func
};

export default ModuleTabActions;
