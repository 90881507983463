import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import {
  getItineraryPortFirstArrivalReport,
  getItineraryRealPorts,
  getItineraryScheduledPorts,
  reorderItineraryScheduledPorts
} from '@/api/itinerary-ports/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

// /* Itineraries */
export const getItinerariesScheduledPorts = params => dispatch => {
  dispatch({ type: TYPES.GET_ITINERARIES_SCHEDULED_PORTS.START, payload: { params } });

  return getItineraryScheduledPorts(params)
    .then(response => {
      dispatch({
        type: TYPES.GET_ITINERARIES_SCHEDULED_PORTS.SUCCESS,
        payload: response
      });

      return response;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_ITINERARIES_SCHEDULED_PORTS.ERROR, payload: error });

      throw error;
    });
};

export const getItinerariesRealPorts = params => (dispatch, getState) => {
  const { vessel_id, loadMore, firstPage, ...rest } = params;

  const { per_page, current_page, last_page } = getState().voyages.itinerary.itinerariesPaging;
  const { isLoading } = getState().voyages.itinerary;

  if (loadMore && (isLoading || (current_page >= last_page && !firstPage))) return;

  const requestParams = { ...rest, paging: { per_page } };

  if (firstPage) {
    requestParams.paging.current_page = 1;
  } else if (loadMore) {
    requestParams.paging.current_page = current_page + 1;
  }

  dispatch({ type: TYPES.GET_ITINERARIES_REAL_PORTS.START, payload: { params: requestParams } });

  return getItineraryRealPorts({ vessel_id, ...requestParams })
    .then(response => {
      dispatch({
        type: TYPES.GET_ITINERARIES_REAL_PORTS.SUCCESS,
        payload: response
      });

      return response;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_ITINERARIES_REAL_PORTS.ERROR, payload: error });

      throw error;
    });
};

export const createItinerary = params => dispatch => {
  dispatch({ type: TYPES.CREATE_ITINERARY.START, payload: { params } });

  return post('/itinerary-ports', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_ITINERARY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Port added successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_ITINERARY.ERROR, payload: error });

      throw error;
    });
};

export const editItinerary = params => dispatch => {
  dispatch({ type: TYPES.EDIT_ITINERARY.START, payload: { params } });

  return put(`/itinerary-ports/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.EDIT_ITINERARY.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Port updated successfully' }));

      dispatch(updateItinerariesVoyageNumber(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_ITINERARY.ERROR, payload: error });

      throw error;
    });
};

export const getItinerary = params => dispatch => {
  dispatch({ type: TYPES.GET_ITINERARY.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/itinerary-ports/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_ITINERARY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_ITINERARY.ERROR, payload: error });

      throw error;
    });
};

export const deleteScheduledItinerary = params => dispatch => {
  dispatch({ type: TYPES.DELETE_SCHEDULED_ITINERARY.START, payload: { params } });
  const { id, ...rest } = params;

  return deleteRequest(`/itinerary-ports/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_SCHEDULED_ITINERARY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export const reorderItinerariesScheduledPorts = params => dispatch => {
  dispatch({ type: TYPES.REORDER_ITINERARIES_SCHEDULED_PORTS.START, payload: { params } });

  return reorderItineraryScheduledPorts(params)
    .then(response => {
      dispatch({
        type: TYPES.REORDER_ITINERARIES_SCHEDULED_PORTS.SUCCESS,
        payload: response
      });

      return response;
    })
    .catch(error => {
      dispatch({ type: TYPES.REORDER_ITINERARIES_SCHEDULED_PORTS.ERROR, payload: error });

      throw error;
    });
};

export const matchItineraryPort = params => dispatch => {
  dispatch({ type: TYPES.MATCH_ITINERARY_PORT.START, payload: { params } });

  return get(`/itinerary-ports/match`, params)
    .then(response => {
      dispatch({ type: TYPES.MATCH_ITINERARY_PORT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.MATCH_ITINERARY_PORT.ERROR, payload: error });

      throw error;
    });
};

export const getItinerariesPortsFirstArrivalReport = createAsyncThunk(
  'GET_ITINERARIES_PORTS_FIRST_ARRIVAL_REPORT',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getItineraryPortFirstArrivalReport();

      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
/* -- */

/* Trips */
export const calculateItineraryTripMiles = params => dispatch => {
  dispatch({ type: TYPES.CALCULATE_ITINERARY_TRIP_MILES.START, payload: { params } });

  return get(`/itinerary-ports/calculate-trip-miles`, params)
    .then(response => {
      dispatch({ type: TYPES.CALCULATE_ITINERARY_TRIP_MILES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CALCULATE_ITINERARY_TRIP_MILES.ERROR, payload: error });

      throw error;
    });
};
/* -- */

/* Forms */
export const setActiveItineraryForm = vessel => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_ITINERARY_FORM, payload: vessel });
};

export const setActiveFormPort = port => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_FORM_PORT, payload: port });
};

export const getCargoPorts = params => dispatch => {
  dispatch({ type: TYPES.GET_CARGO_PORTS.START, payload: { params } });

  const { id, ...rest } = params;

  return get(`/charter-parties/${id}/cargo-ports`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_CARGO_PORTS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CARGO_PORTS.ERROR, payload: error });

      throw error;
    });
};
/* -- */

export const evaluateItineraryPortAgent = params => dispatch => {
  dispatch({ type: TYPES.EVALUATE_ITINERARY_PORT_AGENT.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`itinerary-ports/agent/${id}/evaluate `, rest)
    .then(response => {
      dispatch({ type: TYPES.EVALUATE_ITINERARY_PORT_AGENT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EVALUATE_ITINERARY_PORT_AGENT.ERROR, payload: error });

      throw error;
    });
};

export const setItinerariesReorderMode = enabled => dispatch => {
  dispatch({ type: TYPES.SET_ITINERARIES_REORDER_MODE, payload: enabled });
};

export const updateItinerariesVoyageNumber = itinerary => dispatch => {
  dispatch({ type: TYPES.UPDATE_ITINERARIES_VOYAGE_NUMBER, payload: itinerary });
};
