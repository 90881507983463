import { combineReducers } from 'redux';

import comparison from 'crew/store/evaluations/comparison/reducer';
import profile from 'crew/store/evaluations/profile/reducer';
import templates from 'crew/store/evaluations/templates/reducer';

export default combineReducers({
  comparison,
  profile,
  templates
});
