import { DayPickerRangeController } from 'react-dates';
import { renderMonthElement } from './SingleDatePicker';
import Fade from 'react-reveal/Fade';
import { Button } from 'reactstrap';

import { useState } from 'react';
import moment from 'moment';

import { dateRangeDefaultOptions } from '../_constants';
import { useEffect } from 'react';

export const CUSTOM_OPTION = { label: 'Custom' };

const RangeDatePicker = ({
  value,
  isOpen,
  dateRange,
  initialVisibleMonth,
  showTime,
  hasTime,
  showOptions = true,
  options = [...dateRangeDefaultOptions],
  onChange,
  yearStart,
  yearEnd,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const [focusedInput, setFocusedInput] = useState(
    dateRange.starts?.value && !dateRange.ends?.value ? 'endDate' : 'startDate'
  );

  const [initialVisibleMonthValue, setInitialVisibleMonthValue] = useState(
    (initialVisibleMonth && initialVisibleMonth instanceof moment) ||
      dateRange.starts.value ||
      moment()
  );

  const getUpdatedDate = (newDate, currentDate) => {
    if (showTime) {
      const formattedDate = newDate.clone().format('YYYY-MM-DD');
      const newDatetime = moment(
        `${formattedDate} ${currentDate ? moment(currentDate).format('HH:mm:ss') : '00:00:00'}`
      );

      return newDatetime;
    } else {
      return newDate;
    }
  };

  const onDatesChange = ({ startDate, endDate }, updatedFocusedInput) => {
    const dates = {};

    if (startDate) dates.from = getUpdatedDate(startDate, dateRange.starts.value);
    if (endDate) dates.to = getUpdatedDate(endDate, dateRange.ends.value);

    if (
      dates.from &&
      !dates.to &&
      dateRange.ends.value &&
      moment(dates.from.clone().format('YYYY-MM-DD')).isAfter(
        dateRange.ends.value.clone().format('YYYY-MM-DD')
      )
    ) {
      dates.to = null;
    }

    if (dates.from || dates.to) {
      if (updatedFocusedInput) setFocusedInput(updatedFocusedInput);
      else {
        if (focusedInput === 'startDate') {
          setFocusedInput('endDate');
        } else {
          setFocusedInput('startDate');
        }
      }

      onChange(dates);
    } else {
      setFocusedInput('startDate');
      onChange({ from: null, to: null });
    }
  };

  const handleMonthChange = value => {
    setInitialVisibleMonthValue(value.moment);
  };

  const handleYearChange = value => {
    setInitialVisibleMonthValue(value.moment);
  };

  const handleOptionChange = opt => {
    onDatesChange(
      CUSTOM_OPTION.label === opt.label ? { startDate: null, endDate: null } : opt,
      'startDate'
    );

    if (opt.startDate) setInitialVisibleMonthValue(opt.startDate);
  };

  useEffect(() => {
    if (showOptions) {
      let label = CUSTOM_OPTION.label;

      if (dateRange.starts?.value && dateRange.ends?.value) {
        const matchingOption = options
          .filter(opt => opt.startDate && opt.endDate)
          .find(
            opt =>
              opt.startDate.format('DD-MM-YYYY') ===
                moment(dateRange.starts?.value).format('DD-MM-YYYY') &&
              opt.endDate.format('DD-MM-YYYY') ===
                moment(dateRange.ends?.value).format('DD-MM-YYYY')
          );

        if (matchingOption) label = matchingOption?.label;
      }

      setSelectedOption(label);
    }
  }, [showOptions, dateRange.starts?.value, dateRange.ends?.value]);

  useEffect(() => {
    dateRange.onFocus(focusedInput);
  }, [focusedInput]);

  return (
    <Fade
      duration={600}
      key={initialVisibleMonthValue && initialVisibleMonthValue.format('YYYY-MM')}
    >
      <div className={`date-input-range-date ${showOptions ? 'with-options' : ''}`}>
        {showOptions ? (
          <div className="date-input-range-date__options">
            {[...options, CUSTOM_OPTION].map(opt => (
              <Button
                color="link"
                key={opt.label}
                type="button"
                className={selectedOption === opt.label ? 'active' : ''}
                onClick={() => handleOptionChange(opt)}
              >
                <span className="ms-1">{opt.label}</span>
              </Button>
            ))}
          </div>
        ) : null}

        <div className="date-input-range-date__inner">
          <DayPickerRangeController
            weekDayFormat="ddd"
            daySize={32}
            numberOfMonths={2}
            firstDayOfWeek={1}
            hideKeyboardShortcutsPanel
            startDate={dateRange.starts.value}
            endDate={dateRange.ends.value}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            renderMonthElement={props =>
              renderMonthElement(props, handleMonthChange, handleYearChange, options, {
                yearStart,
                yearEnd
              })
            }
            initialVisibleMonth={() => initialVisibleMonthValue}
            isOutsideRange={date =>
              focusedInput === 'endDate' && dateRange.starts.value
                ? moment(date.format('YYYY-MM-DD')).isBefore(
                    dateRange.starts.value.clone().format('YYYY-MM-DD')
                  )
                : false
            }
            minimumNights={0}
            {...rest}
          />
        </div>
      </div>
    </Fade>
  );
};

export default RangeDatePicker;
