import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _isObject from 'lodash/isObject';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import ShadowBox from 'common/components/general/ShadowBox';
import FormStatus from 'common/components/forms/_components/FormStatus';
import FormDate from 'common/components/forms/_components/FormDate';
import Priority from 'common/components/general/Priority';
import DownloadFormButton from 'common/components/forms/_components/DownloadFormButton';
import CircledButton from 'common/components/buttons/CircledButton';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const FormHeaderPreview = ({
  form,
  formSubmission,
  onView,
  onRemove,
  hideEmptySubmissionMessage = false,
  unreadMessagesCount,
  hiddenFields,
  flat = false,
  emptySubmissionMessage = '*This form needs to be filled in'
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const flexDirectionReversed = useMemo(
    () =>
      flat &&
      hiddenFields?.id &&
      hiddenFields?.importance &&
      hiddenFields?.submission_date &&
      hiddenFields?.download_form_button &&
      !hiddenFields?.status,
    [
      flat,
      hiddenFields?.id,
      hiddenFields?.importance,
      hiddenFields?.submission_date,
      hiddenFields?.download_form_button,
      hiddenFields?.status
    ]
  );

  return (
    <>
      <ShadowBox
        flatOnHover={!flat}
        flat={flat}
        color={flat ? 'light-4' : ''}
        className={`form-header-preview cpt-6 cpb-6 mb-1 ps-1 position-relative ${
          flat && (onView || onRemove) ? 'me-6' : ''
        }`}
      >
        <Row
          noGutters
          className={`align-items-center ${flexDirectionReversed ? 'flex-row-reverse' : ''}`}
        >
          {formSubmission && formSubmission?.status && !hiddenFields?.status ? (
            <Col xs="auto" className={flexDirectionReversed ? 'cpe-12' : 'border-end cpe-12'}>
              <FormStatus
                formSubmission={formSubmission}
                value={formSubmission.status}
                hideLabel
                onChange={() => {}}
                isUnlocked={false}
                isLocked={true}
              />
            </Col>
          ) : null}
          {(form?.importance || form?.importance_id) && !hiddenFields?.importance && (
            <Col xs="auto" className="pe-0 cms-12">
              <Priority
                value={
                  (_isObject(form?.importance) ? form?.importance?.id : form?.importance) ||
                  form?.importance_id
                }
                withText={false}
                size="small"
              />
            </Col>
          )}
          {formSubmission && !hiddenFields?.id ? (
            <Col xs="auto" className={`text-violet fs-10 ${formSubmission ? 'ms-1' : ''}`}>
              {isOnBoard ? formSubmission?.orca_id || '' : formSubmission?.id}
            </Col>
          ) : null}
          <Col className="ms-1 overflow-hidden pe-2">
            <div
              className={`form-manager--inner-header-name d-flex align-items-center text-nowrap ${
                flat ? 'fs-12 text-primary fw-medium' : 'fs-14 text-violet'
              }`}
            >
              <div
                className={`${onView ? 'cursor-pointer' : ''} overflow-hidden text-nowrap`}
                onClick={() => onView && onView(formSubmission)}
              >
                <TextWithTooltip>
                  {form?.name}
                  {form?.revision && <strong> - {form?.revision}</strong>}
                </TextWithTooltip>
              </div>

              {unreadMessagesCount > 0 && (
                <span className="unread-messages text-white d-flex align-items-center justify-content-center fs-10 ms-1">
                  {unreadMessagesCount}
                </span>
              )}
            </div>
          </Col>
          {formSubmission ? (
            <>
              {!hiddenFields?.submission_date ? (
                <Col xs="auto" className="ms-auto">
                  <FormDate value={formSubmission?.submission_date} isViewMode={true} />
                </Col>
              ) : null}

              {!hiddenFields?.download_form_button ? (
                <DownloadFormButton
                  xs="auto"
                  form={form}
                  formSubmission={formSubmission}
                  showLabel={false}
                />
              ) : null}
            </>
          ) : !hideEmptySubmissionMessage ? (
            <Col xs="auto" className="ms-auto me-1">
              <div className="d-flex align-items-center ps-2">
                <div className="form-manager--filled-in">{emptySubmissionMessage}</div>
              </div>
            </Col>
          ) : null}

          <Col
            xs="auto"
            className={`d-flex align-items-center action-buttons ${
              flat ? 'visible position-absolute right-n48 width-48' : 'position-relative'
            }`}
          >
            {onView && (
              <CircledButton
                type={'view'}
                whiteBg
                onClick={() => onView(formSubmission)}
                svgStyle={flat ? { width: 10, height: 10 } : { width: 14, height: 14 }}
                style={flat ? { width: 16, height: 16 } : null}
                className="ms-1"
              />
            )}
            {onRemove ? (
              <CircledButton
                type={'remove'}
                className="ms-1"
                onClick={() => setShowDeleteModal(true)}
                style={flat ? { width: 16, height: 16 } : null}
              />
            ) : null}
          </Col>
        </Row>
      </ShadowBox>

      <DangerousActionModal
        transparent
        action={'Remove'}
        onAccept={() => onRemove(form)}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText={'REMOVE'}
        header={'Remove'}
        body={<div>Are you sure you want to remove the form {form?.name || ''}?</div>}
      />
    </>
  );
};

FormHeaderPreview.propTypes = {
  form: PropTypes.object,
  formSubmission: PropTypes.object,
  onView: PropTypes.func,
  onRemove: PropTypes.func,
  hideEmptySubmissionMessage: PropTypes.bool,
  unreadMessagesCount: PropTypes.number,
  hiddenFields: PropTypes.shape({
    id: PropTypes.bool,
    status: PropTypes.bool,
    importance: PropTypes.bool,
    submission_date: PropTypes.bool,
    download_form_button: PropTypes.bool
  }),
  flat: PropTypes.bool,
  emptySubmissionMessage: PropTypes.string
};

export default FormHeaderPreview;
