import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useActions } from 'utils/hooks';
import styled from '@emotion/styled';

import Loader from './Loader';
import Title from './Title';
import ArrowNext from 'common/components/news-and-announcements/_components/ArrowNext';
import ArrowPrevious from 'common/components/news-and-announcements/_components/ArrowPrevious';
import NewsBox from 'common/components/news-and-announcements/_components/NewsBox';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import { selectPostCategoryAnnouncements } from 'store/lists/selectors';
import {
  selectAnnouncementsIsLoading,
  selectAnnouncementsData,
  selectAnnouncementsCurrentPage,
  selectAnnouncementsLastPage,
  selectAnnouncementsLoadMoreLoading
} from 'common/components/news-and-announcements/store/selectors';

import * as postsActions from 'common/components/news-and-announcements/store/actions';

const SLIDES_PER_PAGE = 3;

const Announcements = ({ setActivePost }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: SLIDES_PER_PAGE,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrevious />,
    afterChange: index => setActiveSlide(index)
  };

  const categoryAnnouncement = useSelector(selectPostCategoryAnnouncements);
  const isLoading = useSelector(selectAnnouncementsIsLoading);
  const loadMoreLoading = useSelector(selectAnnouncementsLoadMoreLoading);
  const data = useSelector(selectAnnouncementsData);
  const currentPage = useSelector(selectAnnouncementsCurrentPage);
  const totalPages = useSelector(selectAnnouncementsLastPage);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const [getPosts] = useActions([postsActions.getPosts]);

  useEffect(() => {
    if (loadMoreLoading || currentPage === totalPages) return;

    const currentPageIndex = Math.floor(activeSlide / SLIDES_PER_PAGE);

    // Calculate the last index of the current page.
    const lastSlideIndex = (currentPageIndex + 1) * SLIDES_PER_PAGE - 1;

    // Check if the current slide is the last slide of the current page.
    if (activeSlide === lastSlideIndex) {
      const params = {
        paging: {
          per_page: 5,
          current_page: currentPage + 1
        },
        filters: [{ name: 'category_id', operation: 'oneOf', value: [categoryAnnouncement?.id] }]
      };

      if (categoryAnnouncement?.id) getPosts({ params, key: 'announcements', loadMore: true });

      // Update the slider position to prevent multiple fetch requests.
      setActiveSlide(lastSlideIndex + 1);
    }
  }, [activeSlide, getPosts, categoryAnnouncement, totalPages, currentPage, loadMoreLoading]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={`mt-8 position-relative ${!isOnBoard ? 'text-dark' : 'text-primary'}`}>
      <Title />
      <div className="w-100p position-relative">
        <Slider {...sliderSettings}>
          {(data || [])?.map((post, i) => (
            <NewsBox
              key={i}
              size="md"
              className={`${!isOnBoard ? 'bg-dark' : 'bg-primary'}`}
              post={post}
              type="announcements"
              onClick={() => setActivePost(post)}
            />
          ))}
        </Slider>
        {loadMoreLoading ? (
          <LoaderContainer className="position-absolute">
            <Loader />
          </LoaderContainer>
        ) : null}
      </div>
    </div>
  );
};

const LoaderContainer = styled.div`
  min-width: 64.25rem;
  max-width: 64.25rem;
  top: ${variables.size16};
  right: -59.75rem;
`;

Announcements.propTypes = {
  setActivePost: PropTypes.func
};

export default Announcements;
