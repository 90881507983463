import TYPES from './types';
import { get } from 'utils/api';

export const getPerson = params => dispatch => {
  dispatch({ type: TYPES.GET_PERSON.START, payload: { params } });

  return get(`/parties/${params.id}`)
    .then(response => {
      dispatch({ type: TYPES.GET_PERSON.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PERSON.ERROR, payload: error });
      throw error;
    });
};

export const getPersonDocuments = params => dispatch => {
  dispatch({ type: TYPES.GET_PERSON_DOCUMENTS.START, payload: { params } });

  return get(`/parties/${params.id}/documents`)
    .then(response => {
      dispatch({ type: TYPES.GET_PERSON_DOCUMENTS.SUCCESS, payload: { documents: response.data } });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_PERSON_DOCUMENTS.ERROR, payload: error });
      throw error;
    });
};

export const resetProfile = () => dispatch => {
  return dispatch({ type: TYPES.RESET_PROFILE });
};

export const createPerson = () => {};
