import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';

import LinkTo from 'views/dashboard/components/LinkTo';
import paths from 'routing/routes/_paths';
import { useEffect } from 'react';
import { getDashboardTotalFindings } from 'store/dashboard/totals/actions';
import { stringifyObj } from 'utils/urls';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const ContentFindings = () => {
  const dispatch = useDispatch();
  const totals = useSelector(state => selectDashboardTotals(state, 'findings'));

  useEffect(() => {
    dispatch(getDashboardTotalFindings());
  }, [dispatch]);

  return totals ? (
    <div className="d-flex align-items-center justify-content-between">
      <div className="fs-20 fw-bold line-height-1 text-primary me-1">
        {totals?.total_pending || 0}
      </div>
      <AuthCheck permissions={[permissions.onboard.jobs.list.findings.view]}>
        <LinkTo
          bg="white"
          to={`${paths.findings}?${stringifyObj({
            filters: [{ name: 'resolved', operation: '=', value: 'false' }]
          })}`}
        />
      </AuthCheck>
    </div>
  ) : null;
};

export default ContentFindings;
