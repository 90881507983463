import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'reactstrap';

import { mapCriteria, getEvaluationCategories, getModalProps } from '../helpers';
import ComponentAnalysis from '../components/component-analysis';
import TrainingNeeds from '../components/TrainingNeeds';
import EvaluationViewTop from './EvaluationViewTop';
import Notes from 'crew/profile/main/evaluation/form/components/Notes';
import { FloatingButtonsGroup } from 'common/components/buttons/FloatingButton';
import * as evaluationActions from 'crew/store/evaluations/profile/actions';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';

import comments from 'common/assets/svg/common/comments.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import crew from 'common/assets/svg/common/crew.svg';
import send from 'common/assets/svg/actions/send.svg';
import company from 'common/assets/svg/common/company.svg';
import training from 'common/assets/svg/crew/training.svg';

import { useFormState } from 'utils/hooks';
import {
  selectEvaluationEditing,
  selectIsEvaluationSentToCaptainForReview,
  selectIsEvaluationSentToSeamanForConsent,
  selectHasEvaluateeConsented,
  selectIsEvaluationSubmitted,
  selectActiveEvaluationId,
  selectAverageScore
} from 'crew/store/evaluations/profile/selectors';
import { isAuthorized } from 'utils/permissions/authorize';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';

const EvaluationView = ({
  active,
  components,
  isOnboard,
  isCreatedOnVessel,
  onRecalculateAverageScore,
  isSaving,
  onSave,
  hasErrors,
  formState
}) => {
  const [criteria, setCriteria] = useState([]);
  const [currentModalType, setCurrentModalType] = useState(null); // Types: captain - seaman - submit
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { fields, selectField } = useFormState(formState);

  const isEdit = useSelector(selectEvaluationEditing);
  const account = useSelector(selectAccount);

  const averageScore = useSelector(selectAverageScore);
  const isEvaluationSubmitted = useSelector(selectIsEvaluationSubmitted);
  const isEvalutionSentToCaptain = useSelector(selectIsEvaluationSentToCaptainForReview);
  const isEvalutionSentToSeaman = useSelector(selectIsEvaluationSentToSeamanForConsent);
  const hasEvaluateeConsented = useSelector(selectHasEvaluateeConsented);
  const evaluationId = useSelector(selectActiveEvaluationId);
  const evaluateeId = _get(active, 'evaluatee_id');
  const modalProps = useMemo(() => getModalProps(currentModalType), [currentModalType]);
  const accountId = _get(account, 'id');

  const [setEvaluationEditing] = useActions([evaluationActions.setEvaluationEditing]);

  const authorizedToViewNotes = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.view_submission_notes
  ]);

  const actions =
    accountId === evaluateeId
      ? []
      : [
          ...(!isEdit && !isEvaluationSubmitted && !isEvalutionSentToSeaman
            ? [
                {
                  type: 'edit',
                  text: 'EDIT EVALUATION',
                  onClick: () => setEvaluationEditing(true),
                  permissions: [
                    permissions.office.crew.seaman_profile.evaluations.submit,
                    permissions.onboard.crew.seaman_profile.evaluations.all_users.submit_to_office
                  ]
                }
              ]
            : []),
          ...(isEdit && !isEvaluationSubmitted
            ? [
                {
                  type: 'close',
                  text: 'CANCEL',
                  disabled: isSaving,
                  onClick: () => setEvaluationEditing(false)
                },
                {
                  type: 'save',
                  text: 'SAVE',
                  svgWrapperClassName: 'bg-primary',
                  svgClassName: 'text-white',
                  textClassName: 'text-primary fw-bold',
                  disabled: isSaving || hasErrors,
                  onClick: () => onSave()
                }
              ]
            : []),
          ...(isOnboard && !isEvaluationSubmitted && !isEvalutionSentToCaptain
            ? [
                {
                  icon: crew,
                  text: 'SEND TO CAPTAIN FOR REVIEW',
                  svgWrapperClassName: 'bg-primary',
                  svgClassName: 'text-white',
                  textClassName: 'text-primary fw-bold',
                  onClick: () => {
                    setCurrentModalType('captain');
                    setIsModalOpen(true);
                  },
                  permissions: [
                    permissions.onboard.crew.seaman_profile.evaluations.all_users
                      .send_to_captain_for_review
                  ]
                }
              ]
            : []),
          ...(isEvalutionSentToSeaman || hasEvaluateeConsented !== null
            ? []
            : [
                {
                  icon: crewPlanning,
                  text: 'SEND TO SEAMAN FOR CONSENT',
                  svgWrapperClassName: 'bg-primary',
                  svgClassName: 'text-white',
                  textClassName: 'text-primary fw-bold',
                  onClick: () => {
                    setCurrentModalType('seaman');
                    setIsModalOpen(true);
                  },
                  permissions: [
                    permissions.office.crew.seaman_profile.evaluations.send_for_consent,
                    permissions.onboard.crew.seaman_profile.evaluations.all_users
                      .send_to_seaman_for_consent
                  ]
                }
              ]),
          ...(!isEvaluationSubmitted
            ? [
                {
                  type: 'save',
                  icon: send,
                  svgClassName: 'text-white',
                  text: `SUBMIT ${isOnboard ? 'TO OFFICE' : ''}`,
                  disabled: isSaving || hasErrors,
                  onClick: () => {
                    setCurrentModalType('submit');
                    setIsModalOpen(true);
                  },
                  permissions: [
                    permissions.office.crew.seaman_profile.evaluations.submit,
                    permissions.onboard.crew.seaman_profile.evaluations.all_users.submit_to_office
                  ]
                }
              ]
            : [])
        ];

  const additionalActionsAfterPermissions = actions.filter(el =>
    isAuthorized(account, el.permissions)
  );

  useEffect(() => {
    if (active) {
      setCriteria(mapCriteria(active.criteria_values, getEvaluationCategories(active.template)));
    }
  }, [active]);

  return (
    <>
      <EvaluationViewTop active={active} components={components} isOnboard={isOnboard} />

      {active && isEdit ? (
        <ComponentAnalysis
          formState={formState}
          criteria={getEvaluationCategories(active?.template)}
          categories={active?.template.categories.filter(cat => cat.criteria.length)}
          onRecalculateAverageScore={onRecalculateAverageScore}
        />
      ) : (
        <ComponentAnalysis
          view
          criteria={criteria}
          categories={active?.template?.categories || []}
          active={active}
          onRecalculateAverageScore={onRecalculateAverageScore}
        />
      )}

      <TrainingNeeds
        fields={fields}
        configKey="training_types"
        selectField={selectField}
        icon={training}
        label="Training Needs"
        selectorLabel="I SUGGEST THE FOLLOWING TRAINING(S)"
        className="mb-2"
        viewOnly
      />

      <Notes
        label="Evaluator Notes"
        icon={comments}
        notes={active?.evaluator_notes}
        className="crew-profile__evaluation--notes-evaluator"
        viewOnly
      />

      <div className="border-bottom-gray-200 w-100p my-4" />

      {isCreatedOnVessel || isOnboard ? (
        <Row className="mb-2">
          <Col xs={authorizedToViewNotes ? 6 : 12}>
            <Notes
              label="Captain Notes"
              icon={crew}
              notes={active?.captain_notes}
              className="crew-profile__evaluation--notes-captain"
              configKey="captain_notes"
              selectField={selectField}
              fields={fields}
              viewOnly={!isEdit}
            />
          </Col>

          {authorizedToViewNotes ? (
            <Col xs={6}>
              <Notes
                fields={fields}
                label="Submission Notes"
                configKey="submission_notes"
                selectField={selectField}
                notes={active?.submission_notes}
                icon={send}
                viewOnly={!isEdit}
                subtitle="*Not visible to evaluatee"
                isHighlighted
              />
            </Col>
          ) : null}
        </Row>
      ) : null}

      <Notes
        fields={fields}
        label="Evaluatee Notes"
        icon={crewPlanning}
        notes={active?.evaluatee_notes}
        viewOnly
        className="mb-2"
      />

      {!isOnboard ? (
        <Notes
          fields={fields}
          label="Office Only Notes"
          icon={company}
          notes={active?.office_only_notes}
          selectField={selectField}
          configKey="office_only_notes"
          viewOnly={!isEdit}
          subtitle="*Not visible to evaluatee"
          isHighlighted
        />
      ) : null}

      <FloatingButtonsGroup
        fixed
        actions={additionalActionsAfterPermissions}
        rating={averageScore}
      />

      <DangerousActionModal
        transparent
        onAccept={() =>
          onSave(
            modalProps.params,
            evaluationId ? true : false,
            evaluationId || isEvaluationSubmitted ? true : false
          )
        }
        onClose={() => {
          setIsModalOpen(false);
          setCurrentModalType(null);
        }}
        closeModal={() => setIsModalOpen(false)}
        actionHoverColor={modalProps.color}
        isOpen={isModalOpen}
        actionText={modalProps.actionText}
        header={modalProps.title}
        body={modalProps.body}
        cancelText="CANCEL"
        actionIcon={<SvgRender src={modalProps.icon} style={{ width: 80, height: 80 }} />}
      />
    </>
  );
};

export default EvaluationView;
