export default [
  'captain_reports',
  'crew',
  'crew_profile_documents',
  'crew-unsigned',
  'manuals',
  'events',
  'jobs',
  'jobs_list',
  'jobs_flag_extensions_dispensations_list',
  'pms_jobs_list',
  'form_submissions',
  'form_obligations',
  'purchasing_requisitions',
  'purchasing_requisitions_dlv',
  'purchasing_requisitions_ev',
  'purchasing_deliverables',
  'form_planning',
  'vessel_documents',
  'crew_evaluation_obligations',
  'mga_actions',
  'mga_accounts',
  'mga_overview',
  'pms_jobs_per_system',
  'mga_inventory_items_additions',
  'mga_inventory_items_removals',
  'mga_inventory_items_overview',
  'inventory_spare_parts',
  'inventory_items',
  'inventory_survey_items',
  'reports_findings'
];
