import React from 'react';
import Fade from 'react-reveal/Fade';

import { useSelector } from 'react-redux';
import { selectCrewProfile } from 'crew/store/profile/selectors';

import ProfileDataBox from 'common/components/layouts/profile-data-box';

import { nokConfig, nokConfigWithSameAddress } from 'crew/profile/main/personal/sections/_nok';

const Nok = () => {
  const crew = useSelector(selectCrewProfile);

  return (
    <Fade>
      <div className="nok-addresses-view">
        <ProfileDataBox
          profile={crew}
          sections={
            (crew.nok_address_same_as_seafarer ? nokConfigWithSameAddress : nokConfig).sections
          }
        />
      </div>
    </Fade>
  );
};

export default Nok;
