import { useCallback, useEffect, useState } from 'react';

import closeIcon from '@/ts-common/assets/svg/actions/close.svg';
import downloadIcon from '@/ts-common/assets/svg/actions/download.svg';
import rotateIcon from '@/ts-common/assets/svg/actions/rotate.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';

import { Attachment } from '@/ts-common/types/files';
import { FC } from 'react';
import { rotateDirectionEnums } from './helpers';
import { fileSize, getFileType } from '@/ts-common/utils/files';
import { handleFileDownload } from '@/ts-common/utils/download';
import { RotateDirectionType } from '../store/types';
import { isFileSynced } from '@/api/files/api';

type GalleryHeaderProps = {
  file: Attachment;
  setIsOpen: (isOpen: boolean) => void;
  setRotateDirection: (rotateDirection: RotateDirectionType) => void;
};

const GalleryHeader: FC<GalleryHeaderProps> = ({ file, setIsOpen, setRotateDirection }) => {
  const [isPreparingDownload, setIsPreparingDownload] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const { is_synced } = isFileSynced(file);
  const rotateDirectionKeys = Object.keys(rotateDirectionEnums);
  const currentFileType = getFileType(file.mime_type, file.extension).type;

  const isNotSynced = !is_synced;

  const onClose = () => {
    setIsOpen(false);
  };

  const resetRotateDirection = useCallback(() => {
    setCurrentIndex(0);
    setRotateDirection('top');
  }, [setRotateDirection]);

  useEffect(() => {
    resetRotateDirection();
  }, [file?.id, resetRotateDirection]);

  const onClickRotateButton = () => {
    const increasedIndex = currentIndex <= 2 ? currentIndex + 1 : 0;

    setRotateDirection(rotateDirectionKeys[increasedIndex] as RotateDirectionType);
    setCurrentIndex(increasedIndex);
  };

  const downloadFile = async () => {
    setIsPreparingDownload(true);
    await handleFileDownload(file);
    setIsPreparingDownload(false);
  };

  return (
    <div className="gallery__header">
      <div>
        <div className="gallery__header--title">{file.filename}</div>
        <div className="gallery__header--subtitle">
          <span className="gallery__header--subtitle-filetype">{currentFileType}</span>
          <div className="gallery__header--subtitle-divider" />
          <span className="gallery__header--subtitle-filesize">{fileSize(file.size)}</span>
        </div>
      </div>
      <div className="d-flex  align-items-center">
        {currentFileType === 'image' ? (
          <div
            onClick={onClickRotateButton}
            className={`gallery__header--icon gallery__header--icon-rotate cursor-pointer`}
          >
            <SvgRender src={rotateIcon} style={{ width: 19, height: 19 }} />
          </div>
        ) : null}

        <div
          data-cy="download_file"
          onClick={isPreparingDownload ? () => null : downloadFile}
          className={`gallery__header--icon gallery__header--icon-download ${
            isPreparingDownload || isNotSynced ? 'opacity-5 pe-none' : 'cursor-pointer'
          }`}
        >
          <SvgRender src={downloadIcon} style={{ width: 17, height: 18.5 }} />
        </div>
        <div
          data-cy="close_file_preview"
          onClick={onClose}
          className="gallery__header--icon gallery__header--icon-close cursor-pointer"
        >
          <SvgRender src={closeIcon} style={{ width: 12, height: 12 }} />
        </div>
      </div>
    </div>
  );
};

export default GalleryHeader;
