import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import Input from 'common/components/form/inputs/Input';

import { Button } from 'reactstrap';
import { parseQueryParams, stringifyObj } from 'common/utils/urls';
import { selectTableListPageSearch } from 'common/components/table/store/selectors';
import useRouter from 'use-react-router';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import search from 'common/assets/svg/common/search.svg';
import x from 'common/assets/svg/actions/close.svg';
import { useSelector } from 'react-redux';
import useActions from 'common/utils/hooks/useActions';

import * as tableActions from 'common/components/table/store/actions';

const TableSearchText = ({ onSearch, label, grayBg, className = '' }) => {
  const inputRef = useRef(null);
  const { history, location } = useRouter();

  const [setTablePageSearch] = useActions([tableActions.setTablePageSearch]);

  const textValue = useSelector(state => selectTableListPageSearch(state, label));
  const setTextValue = useCallback(text => setTablePageSearch({ text, table: label }), [label]);

  const onFormSubmit = e => {
    e.preventDefault();

    // Prevent manual empty search (pressing enter with nothing in search)
    if (!textValue) {
      return;
    }

    const { searchId, searchText, ...rest } = parseQueryParams(history.location.search);
    const updated = { ...rest };

    let search = stringifyObj(updated);

    if (textValue) {
      if (search.length) search = search + `&`;

      search = search + `searchText=${textValue}`;
    }

    if (searchId) {
      if (search.length) search = search + `&`;

      search = search + `searchId=${searchId}`;
    }

    if (onSearch) onSearch(textValue);
    else history.push({ pathname: location.pathname, search });
  };

  const clearSearch = () => {
    setTextValue('');
  };

  useUpdateEffect(() => {
    if (!textValue?.length) {
      if (onSearch) onSearch('');
      else {
        const { searchText, ...rest } = parseQueryParams(history.location.search);
        if (searchText)
          history.push({
            pathname: location.pathname,
            search: stringifyObj(rest)
          });
      }
    }
  }, [textValue]);

  useEffect(() => {
    const { searchText } = parseQueryParams(history.location.search);

    if (searchText) setTextValue(searchText);

    return () => clearSearch();
  }, []);

  return (
    <form
      onSubmit={onFormSubmit}
      ref={inputRef}
      className={`table-search-text d-flex align-items-center flex-nowrap ${
        grayBg ? 'bg-light-gray rounded rounded-lg' : ''
      } ${className || ''}`}
    >
      <SvgRender
        src={search}
        className={`table-search-text--icon text-${
          textValue?.length ? 'primary' : grayBg ? 'gray-400' : 'secondary'
        }`}
        style={{ width: 14, height: 14 }}
      />
      <Input
        className="table-search-text--input mb-0 w-100p"
        value={textValue}
        onChange={e => setTextValue(e.target.value)}
        placeholder={'Search and press enter'}
        inputClassName={grayBg ? 'border-0' : ''}
      />
      {textValue?.length ? (
        <Button
          color="link"
          type="button"
          className="p-1 text-primary table-search-text--clear"
          onClick={clearSearch}
        >
          <SvgRender src={x} style={{ width: 8, height: 8 }} />
        </Button>
      ) : null}
    </form>
  );
};

TableSearchText.propTypes = {
  label: PropTypes.string.isRequired, // table label
  onSearch: PropTypes.func, // Override the 'search param in URL' functionality
  grayBg: PropTypes.bool,
  className: PropTypes.string
};

export default TableSearchText;
