import NumberField from 'captain-reports/templates/inputs/NumberField';
import TextAreaField from 'captain-reports/templates/inputs/TextAreaField';

import {
  autocompleteFreshTotalConsumed,
  autocompleteFreshTotalRob
} from 'captain-reports/templates/config/_autocompleteHelpers';
import TimeInput from 'captain-reports/templates/inputs/TimeInput';
import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';

const config = () => [
  {
    header: 'FRESH WATER GENERATOR',
    fields: [
      {
        label: 'Running hours',
        key: 'fw_generator_running_minutes',
        width: { xs: 2 },
        category: 'fresh_water_generator',
        render: TimeInput
      },
      {
        label: 'Salinity',
        key: 'fw_generator_salinity',
        subLabel: 'ppm',
        width: { xs: 2 },
        render: NumberField,
        category: 'fresh_water_generator',
        type: 'number'
      },
      {
        label: 'Vacuum',
        key: 'fw_generator_vacuum',
        subLabel: 'bar',
        width: { xs: 2 },
        render: NumberField,
        category: 'fresh_water_generator',
        type: 'number'
      },
      {
        label: 'Ejector pump water press',
        key: 'fw_generator_eject_water_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        category: 'fresh_water_generator',
        type: 'number'
      },
      {
        label: 'O/B Backpressure',
        key: 'fw_generator_ejector_ob_backpressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        category: 'fresh_water_generator',
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Shell water temp',
        key: 'fw_generator_shell_water_temperature',
        sublabel: 'mt',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Last cleaning date',
        key: 'fw_generator_last_cleaning_date',
        sublabel: 'mt',
        width: { xs: 2 },
        render: DateTimeInput,
        inputProps: {
          hasTime: false
        }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Comments',
        key: 'fw_comments',
        width: { xs: '' },
        render: TextAreaField
      }
    ]
  },
  {
    header: 'FRESH WATER (mt)',
    fields: [
      {
        label: 'Produced',
        subLabel: 'mt',
        key: 'fw_generator_water_produced',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Supplied',
        key: 'fw_supplied',
        subLabel: 'mt',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total Added',
        key: 'fw_total_added',
        autocompleteValues: [{ key: 'fw_generator_water_produced' }, { key: 'fw_supplied' }],
        autocomplete: autocompleteFreshTotalConsumed,
        width: { xs: 2 },
        render: NumberField,
        type: 'number',
        inputProps: { fixedDecimalScale: 2 },
        className: 'readonly-input'
      }
    ]
  },
  {
    fieldTitle: 'total consumed',
    fields: [
      {
        label: 'Domestic Cons',
        key: 'fw_consumption_domestic',
        width: { xs: '' },
        className: 'max-width-112',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Distillate cons',
        key: 'fw_consumption_distillate',
        width: { xs: '' },
        className: 'max-width-112',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Holds washing',
        key: 'fw_consumption_holds_washing',
        width: { xs: '' },
        className: 'max-width-112',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cleaning / Maintenance',
        key: 'fw_consumption_vessel_cleaning',
        className: 'text-nowrap max-width-112 me-2',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Dumped overboard',
        key: 'fw_dumped_overboard',
        className: 'max-width-112 text-nowrap',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total Cons',
        key: 'fw_consumption_total',
        autocompleteValues: [
          { key: 'fw_consumption_domestic' },
          { key: 'fw_consumption_distillate' },
          { key: 'fw_consumption_holds_washing' },
          { key: 'fw_consumption_vessel_cleaning' },
          { key: 'fw_dumped_overboard' }
        ],
        autocomplete: autocompleteFreshTotalConsumed,
        width: { xs: 2 },
        render: NumberField,
        type: 'number',
        inputProps: { fixedDecimalScale: 2 }
      }
    ]
  },
  {
    fieldTitle: 'total rob',
    fields: [
      {
        label: 'Domestic rob',
        key: 'fw_rob_domestic',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Distillate rob',
        key: 'fw_rob_distillate',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total rob',
        key: 'fw_rob_total',
        className: 'pe-2',
        width: { xs: 2 },
        autocompleteValues: [
          { key: 'fw_total_added' },
          { key: 'fw_consumption_total' },
          { previousReportKey: 'fw_rob_total' },
          { key: 'fw_rob_total' }
        ],
        autocomplete: autocompleteFreshTotalRob,
        render: NumberField,
        type: 'number',
        inputProps: {
          fixedDecimalScale: 2
        }
      }
    ]
  }
];

const defaultConfig = {
  noon: data => config(data),
  arrival: data => config(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => config(data),
  port_noon: data => config(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default defaultConfig;
