import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';

import arrow from 'common/assets/svg/jobs/priority_arrow.svg';

const CrewRotationType = ({
  type,
  textClassName = '',
  svgClassName = '',
  svgStyle = {},
  plural,
  showLabel = true
}) => {
  return (
    <div
      className={`d-flex align-items-center flex-nowrap text-${
        type === 'off_signer' ? 'gray-common' : 'green'
      }`}
    >
      <SvgRender
        className={svgClassName}
        src={arrow}
        style={{
          width: 8,
          height: 13,
          ...svgStyle,
          transform: type === 'off_signer' ? 'rotate(180deg)' : ''
        }}
      />
      {showLabel && (
        <span
          className={`${textClassName || 'text-violet'} ${
            !textClassName.includes('fs-') ? 'fs-12' : ''
          } ps-1 lh-1 text-nowrap fw-medium`}
        >
          {type === 'off_signer' ? 'Off Signer' : 'On Signer'}
          {plural ? 's' : ''}
        </span>
      )}
    </div>
  );
};

CrewRotationType.propTypes = {
  type: PropTypes.oneOf(['off_signer', 'on_signer']).isRequired
};

export default CrewRotationType;
