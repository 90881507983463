import React from 'react';

import FormList from 'common/components/form/FormList';
import SvgRender from 'common/components/general/SvgRender';

import EmptyField from './EmptyField';
import Input from 'common/components/form/styled-inputs/Input';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import DateInput from 'common/components/form/inputs/date';
import TicketTypeLine from 'common/components/crew/TicketTypeLine';

import { useFormState } from 'utils/hooks';
import { getCustomFormat } from 'common/utils/dates';
import { getAiportOptionLabel } from 'common/utils/labels';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import { Row, Col, Button } from 'reactstrap';
import add from 'common/assets/svg/actions/add.svg';

import _isObject from 'lodash/isObject';

const PreviewDate = ({ date, label }) => (
  <div className="ticket-preview-date">
    <div className="form-label">{label}</div>
    <div className="fs-12 lh-1">
      <strong className="text-primary pe-1">
        {date ? getCustomFormat(date, 'DD/MM/YYYY') : '-'}
      </strong>
      {date ? <strong className="text-violet">{getCustomFormat(date, 'HH:mm')}</strong> : null}
    </div>
  </div>
);

const TicketNo = ({ previewMode, field, changeField }) =>
  previewMode && !field.value ? (
    <EmptyField label="Ticket No." />
  ) : (
    <Input
      label="Ticket No."
      onChange={changeField('ticket_no')}
      placeholder="Ticket No."
      disabled={previewMode}
      {...field}
    />
  );

const Airline = ({ field, selectField, previewMode }) =>
  previewMode && !field.value ? (
    <EmptyField label="AIRLINE" />
  ) : (
    <AsyncSelector
      label="AIRLINE"
      onChange={e => selectField('airline')(e)}
      placeholder="Select airline"
      styled={true}
      name="airline"
      type="airlines"
      isDisabled={previewMode}
      getOptionLabel={field => field.display_name}
      {...field}
    />
  );

const From = ({ field, previewMode, selectField, offerType }) => {
  useUpdateEffect(() => {
    if (offerType !== 'air' && _isObject(field.value)) {
      selectField('from')(null);
    }
  }, [offerType]);

  return offerType === 'air' ? (
    <AsyncSelector
      label="FROM"
      onChange={e => selectField('from')(e)}
      type="airports"
      placeholder="Select airport"
      styled={true}
      isDisabled={previewMode}
      getOptionLabel={getAiportOptionLabel}
      {...field}
    />
  ) : previewMode && !field.value ? (
    <EmptyField label="FROM" />
  ) : (
    <Input
      label="FROM"
      onChange={e => selectField('from')(e.target.value)}
      placeholder="Add city"
      disabled={previewMode}
      {...field}
    />
  );
};

const To = ({ field, previewMode, selectField, offerType }) => {
  useUpdateEffect(() => {
    if (offerType !== 'air' && _isObject(field.value)) {
      selectField('to')(null);
    }
  }, [offerType]);

  return offerType === 'air' ? (
    <AsyncSelector
      label="TO"
      onChange={e => selectField('to')(e)}
      type="airports"
      placeholder="Select airport"
      styled={true}
      isDisabled={previewMode}
      getOptionLabel={getAiportOptionLabel}
      {...field}
    />
  ) : previewMode && !field.value ? (
    <EmptyField label="TO" />
  ) : (
    <Input
      label="TO"
      onChange={e => selectField('to')(e.target.value)}
      placeholder="Add city"
      disabled={previewMode}
      {...field}
    />
  );
};

const Routes = React.memo(({ formState, previewMode, offerType }) => {
  const { fields, selectField, changeField } = useFormState(formState);

  return (
    <div>
      <Row className="mb-1">
        <Col xs={3}>
          <From
            field={fields.from}
            previewMode={previewMode}
            selectField={selectField}
            offerType={offerType}
          />
        </Col>
        <Col xs={3}>
          <Row className="align-items-center">
            <Col xs="auto">
              {previewMode ? (
                <PreviewDate date={fields.departure.value} label={'DEPARTURE'} />
              ) : (
                <DateInput
                  dataCy="ticket-departure-date"
                  label="DEPARTURE"
                  value={fields.departure.value}
                  error={fields.departure.error}
                  onChange={e => selectField('departure')(e)}
                  className={'mb-0 ticket-preview-date'}
                  hasTime={true}
                  showTime={true}
                  invisible
                />
              )}
            </Col>
            <Col xs={4} className={'pe-1 d-none d-hd-block ms-auto'}>
              <TicketTypeLine type={offerType} />
            </Col>
          </Row>
        </Col>

        <Col xs={3}>
          <To
            field={fields.to}
            previewMode={previewMode}
            selectField={selectField}
            offerType={offerType}
          />
        </Col>
        <Col xs={3}>
          {previewMode ? (
            <PreviewDate date={fields.arrival.value} label={'ARRIVAL'} />
          ) : (
            <DateInput
              dataCy="ticket-arrival-date"
              label="ARRIVAL"
              value={fields.arrival.value}
              error={fields.arrival.error}
              onChange={e => selectField('arrival')(e)}
              className={'mb-0 ticket-preview-date'}
              hasTime={true}
              showTime={true}
              invisible
            />
          )}
        </Col>
      </Row>
      <Row>
        {offerType === 'air' ? (
          <>
            <Col xs={3}>
              <Airline
                field={fields.airline}
                ticket_no={fields.ticket_no}
                previewMode={previewMode}
                selectField={selectField}
              />
            </Col>
            <Col xs={3}>
              <TicketNo
                previewMode={previewMode}
                field={fields.ticket_no}
                changeField={changeField}
              />
            </Col>
            <Col xs={3}>
              {previewMode && !fields.itinerary_no.value ? (
                <EmptyField label="FLIGHT NO." />
              ) : (
                <Input
                  label="FLIGHT NO."
                  onChange={changeField('itinerary_no')}
                  placeholder="Flight No."
                  disabled={previewMode}
                  {...fields.itinerary_no}
                />
              )}
            </Col>
          </>
        ) : (
          <Col xs={3}>
            <TicketNo
              previewMode={previewMode}
              field={fields.ticket_no}
              changeField={changeField}
            />
          </Col>
        )}
      </Row>
    </div>
  );
});

const OfferRoutes = ({
  subStates,
  addSubform,
  removeSubform,
  previewMode,
  disabledMode,
  offerType,
  routes
}) => {
  return (
    <div className="offer-routes">
      <FormList
        forms={subStates(`routes`)}
        className="offer-routes-wrap pt-0"
        formListclassName={`offer-routes-list ${
          previewMode && routes.length === 1 ? '' : 'timeline'
        }`}
        canAdd={false}
        canRemove={form => !previewMode && form.index > 0}
        onRemove={index => removeSubform('routes', index)}
      >
        {(formState, index) => (
          <Routes
            previewMode={previewMode}
            disabledMode={disabledMode}
            offerType={offerType}
            formState={formState}
            index={index}
          />
        )}
      </FormList>

      {!disabledMode && !previewMode ? (
        <div className="offer-routes__add d-inline-flex">
          <Button type="button" color="link" onClick={() => addSubform('routes', {})}>
            <SvgRender src={add} />
            <span className="fs-12 lh-1 fw-medium">Add more</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default OfferRoutes;
