import { FC } from 'react';
import _get from 'lodash/get';

import ButtonFilter from 'common/components/buttons/ButtonFilter';
import { colors } from 'common/components/purchasing/requisition/components/OnboardStatus';
import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import { selectTotals } from 'common/components/purchasing/requisition/store/selectors';
import { useAppSelector } from '@/store/hooks';

const statuses = [
  { label: null, text: 'All' },
  { label: 'draft', text: 'Draft' },
  { label: 'submitted', text: 'Submitted' },
  { label: 'for_correction', text: 'For Correction' }
];

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
};

const TopFilters: FC<TopFiltersComponentProps> = ({ topFilters, setTopFilters }) => {
  const [onboardStatus, setOnboardStatus] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'onboard_status'
  );
  const totals = useAppSelector(selectTotals);

  return (
    <div className="d-flex align-items-center justify-content-between ">
      {statuses.map((status, i) => (
        <ButtonFilter
          key={status.label || i}
          onClick={() => setOnboardStatus(status.label || null)}
          className="me-2"
          isActive={onboardStatus === status.label}
          innerClassName={`bg-${
            status.label ? colors[status.label as keyof typeof colors] : 'primary'
          } text-white d-inline-flex align-items-center px-1`}
          label={status.text}
          disabled={false}
        >
          {!status.label ? _get(totals, 'all', 0) : _get(totals, status.label, 0)}
        </ButtonFilter>
      ))}
    </div>
  );
};

export default TopFilters;
