import { get } from '@/api';
import {
  VesselPmsIntegrationClassType,
  VesselPmsIntegrationStatusBase
} from '@/common/types/reports';

type getVesselPmsAnnualReportIntegrationStatusParams = {
  vesselId: number;
  classType: VesselPmsIntegrationClassType;
};

export const getVesselPmsAnnualReportIntegrationStatus = async ({
  vesselId,
  classType
}: getVesselPmsAnnualReportIntegrationStatusParams) => {
  return (
    await get<VesselPmsIntegrationStatusBase>(
      `/class-${classType}/annual-report/${vesselId}/status`
    )
  ).data;
};
