import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import ModularTable, { useModularTable } from 'common/components/ModularTable';
import {
  selectRefetchTableTrigger,
  selectLatestPeriod,
  selectIsLatestPeriodForCorrections,
  canEditPeriodActions,
  selectIsPastMga
} from 'common/components/mga/store/selectors';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import SvgRender from 'common/components/general/SvgRender';
import info from 'common/assets/svg/common/info.svg';
import ExportIcons from './ExportIcons';
import NameColumn from './NameColumn';
import CrewClosingBalanceWarnings from 'common/components/mga/components/CrewClosingBalanceWarnings';
import { getGroupedRows } from './helpers';
import { getTableList, setTableWarnings } from 'store/tables/lists/actions';
import { getCrewOverviewWarnings } from 'common/components/mga/store//parser';
import {
  getMgaInfo,
  getMgaWarnings,
  setMgaActionForm,
  setWarningCrewClosingBalance,
  setWarningCrewContractIds,
  setWarningCrewOverview
} from 'common/components/mga/store/actions';
import { setMiscWarnings } from 'common/components/mga/store/actions-ts.ts';
import { displayUTCDate } from '@/ts-common/utils/dates';

const Overview = () => {
  const isPastMga = useSelector(selectIsPastMga);
  const dispatch = useDispatch();

  const shouldRefetchTable = useSelector(selectRefetchTableTrigger);
  const latestPeriod = useSelector(selectLatestPeriod);
  const isForCorrections = useSelector(selectIsLatestPeriodForCorrections);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const canEdit = useSelector(canEditPeriodActions);

  const fetchData = useCallback(
    async params => {
      try {
        const requestParams = { ...params, period_id: latestPeriod.id };
        dispatch(setMiscWarnings([]));

        const tableData = await dispatch(getTableList(requestParams));
        dispatch(getMgaInfo({ period_id: latestPeriod.id }));

        const tableWarnings = await dispatch(getMgaWarnings({ period_id: latestPeriod.id }));
        const actionWarnings = tableWarnings.automated_action_warnings;
        const miscWarnings = tableWarnings.misc_warnings;
        dispatch(setMiscWarnings(miscWarnings));

        dispatch(setWarningCrewOverview(getCrewOverviewWarnings(actionWarnings, tableData)));
        dispatch(
          setWarningCrewContractIds(actionWarnings.map(warning => warning.crew_contract_id))
        );

        dispatch(
          setWarningCrewClosingBalance({
            data: tableData?.data,
            period_id: requestParams.period_id
          })
        );

        return tableData;
      } catch (error) {
        dispatch(setTableWarnings({ table: params.table, warnings: null }));
      }
    },
    [latestPeriod?.id, dispatch]
  );

  const table = useModularTable({
    defaultRequestParams: { visible: false, paging: false, sorting: false, filters: true },
    requestTableListFrom: fetchData,
    label: 'mga_overview',
    config: columns => ({ columns: constructColumns(columns, [], 'mga_overview') }),
    shouldRefetchDataOnUrlChange: true
  });

  useUpdateEffect(() => {
    if (shouldRefetchTable) {
      table.refetchData();
    }
  }, [shouldRefetchTable]);

  useUpdateEffect(() => {
    table.refetchData();
  }, [latestPeriod?.id]);

  return (
    <div>
      {isOnBoard && isForCorrections && !isPastMga ? (
        <div className="text-red fs-12 fw-medium mb-1">
          *Please make any corrections & submit MGA to office
        </div>
      ) : null}

      {isPastMga ? null : (
        <div className="d-flex align-items-center fs-12 text-violet mb-1 cpb-2">
          <SvgRender src={info} style={{ width: 13, height: 13 }} />
          <div className="ms-1 border-end pe-2 me-2">
            Double click on any cell to create, edit or delete action
          </div>
          <ExportIcons periodId={latestPeriod.id} />
        </div>
      )}

      <CrewClosingBalanceWarnings />

      <ModularTable
        loader
        hideTableSearch
        hideTopPagination
        hideTableFilters
        onCellDoubleClick={(data, col, isGroup) => {
          if (!isGroup || !canEdit) return null;

          const extractedKeys = col?.key?.split('.');
          const actionTypeId = extractedKeys[1];

          dispatch(
            setMgaActionForm({
              isOpen: true,
              cell: {
                actionTypeId: actionTypeId,
                data: data
              }
            })
          );
        }}
        rows={{
          full_name: data => <NameColumn data={data} />,
          from: data => displayUTCDate(data.from, { time: true }),
          to: data => displayUTCDate(data.to, { time: true }),
          ...getGroupedRows(table.columns)
        }}
        {...table}
      />
    </div>
  );
};

export default Overview;
