import React, { useState } from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { parseQueryParams, stringifyObj } from 'common/utils/urls';
import { numberToStr } from 'common/utils/numbers';
import PaginationNavigation from './PaginationNavigation';
import useRouter from 'use-react-router';

import _get from 'lodash/get';

const TopPagination = ({
  state,
  fetchData,
  standAlone = false,
  hidePerPage = false,
  hideNavigation = false,
  scrollToTop = true,
  customOptions = null
}) => {
  const { history, location } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const options = [20, 50];

  const handleChange = number => {
    if (number !== state.paging.per_page) {
      const { searchId, page_size, ...rest } = parseQueryParams(history.location.search);
      const updated = { ...rest };

      if (parseInt(number) !== parseInt(state.paging.default_per_page)) {
        updated.page_size = number;
      }

      history.push({
        pathname: location.pathname,
        search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
      });
    }
  };

  const { total, per_page } = _get(state, 'paging', {}) || {};

  const render = () => (
    <>
      <div className="app-table--toptotal-results me-1">
        Results:{' '}
        <strong>
          {total === 1 && state?.data !== undefined && !(_get(state, 'data') || []).length
            ? 0
            : numberToStr(total === undefined ? (_get(state, 'data') || [])?.length : total)}
        </strong>
      </div>
      {per_page ? (
        <div
          className={`app-table--toptotal-perpage d-flex align-items-center ${
            hideNavigation || hidePerPage ? 'hidden-navigation pe-0 me-0' : 'me-1'
          }`}
        >
          {hidePerPage ? null : (
            <>
              <div className="fw-normal">Per page:&nbsp;</div>
              <Dropdown direction="down" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle
                  color="link"
                  className="p-0 text-center text-primary border fw-bold fs-12 lh-16 cps-4 cpe-4"
                >
                  {per_page}
                </DropdownToggle>
                <DropdownMenu end={true}>
                  {(customOptions ? customOptions : options).map(o => (
                    <DropdownItem key={o} onClick={() => handleChange(o)}>
                      <strong>{o}</strong>
                      <span className="fw-light">&nbsp;rows</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </>
          )}
        </div>
      ) : null}
      {hideNavigation ? null : (
        <PaginationNavigation
          paging={state?.paging}
          fetchData={fetchData}
          scrollToTop={scrollToTop}
        />
      )}
    </>
  );

  if (standAlone) {
    return (
      <div className="app-table">
        <div className="app-table--toptotal d-flex align-items-center justify-content-end text-nowrap">
          {render()}
        </div>
      </div>
    );
  } else {
    return render();
  }
};

export default TopPagination;
