import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import Input from 'common/components/jobs/_base/inputs/MentionInput';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import CommentsList from './CommentList';
import spinner from 'common/assets/svg/common/spinner.svg';
import SvgRender from 'common/components/general/SvgRender';

import { transformDataForSubmit } from 'common/components/jobs/_base/modules/checklist/helpers';
import { selectChecklistOption } from 'events/store/event-modules/checklist/selectors';
import { createComment, getComments } from 'events/store/event-modules/checklist/actions';

const Comments = ({ parentRef, onClose, optionId, isOpen, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [newMentions, setNewMentions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const option = useSelector(state => selectChecklistOption(state, optionId));
  const { comments_count, comments } = option;

  const onInputChange = (e, newValue, newPlainText, mentions) => {
    setNewMentions(mentions);
    setNewMessage(e.target.value);
  };

  const onNewMessage = async () => {
    if (newMessage === '') return;

    try {
      setSubmitting(true);

      let params = transformDataForSubmit(newMessage, newMentions);

      const res = await dispatch(
        createComment({ event_id: eventId, checklist_id: optionId, ...params })
      );

      if (res && res.id) {
        setNewMessage('');
        setNewMentions('');
      }

      setSubmitting(false);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const getOptionsComments = async () => {
      try {
        setLoading(true);

        await dispatch(
          getComments({
            event_id: eventId,
            checklist_id: optionId,
            paging: { per_page: 2 }
          })
        );

        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    if (isOpen) {
      if (comments?.length === 0) {
        getOptionsComments();
      }
    }
  }, [comments?.length, dispatch, eventId, isOpen, optionId]);

  return (
    <Row className="comments-section ps-4">
      {comments_count === 0 ? (
        <Col
          xs={11}
          className={`comments-section--empty position-relative ${submitting ? 'loading' : ''}`}
        >
          <Input parentRef={parentRef} value={newMessage} onChange={onInputChange} />
          <div className="new-comment-actions">
            <InputSaveActions onCancel={onClose} onSave={onNewMessage} />
          </div>
        </Col>
      ) : loading ? (
        <div className="w-100p d-flex align-items-center justify-content-center">
          <SvgRender src={spinner} style={{ width: 20, height: 20 }} />
        </div>
      ) : (
        <Col xs={11} className="h-100p position-relative">
          <CommentsList
            comments_count={comments_count}
            eventId={eventId}
            optionId={optionId}
            parentRef={parentRef}
            comments={comments}
          />
        </Col>
      )}
    </Row>
  );
};

export default Comments;
