import NumberField from 'captain-reports/templates/inputs/NumberField';
import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';

const coolers = () => [
  {
    fields: [
      {
        label: 'ME Cooling Water Inlet Temperature',
        subLabel: '°C',
        key: 'me_cool_water_inlet_temperature',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ME Cooling Water Outlet Temperature',
        key: 'me_cool_water_outlet_temperature',
        width: { xs: 4 },
        subLabel: '°C',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'air_coolers',
    subGroupHeader: 'AIR COOLER',
    fields: [
      {
        label: 'CW TEMP IN',
        subLabel: '°C',
        key: 'air_cooler_water_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CW TEMP OUT',
        subLabel: '°C',
        key: 'air_cooler_water_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'AIR TEMP IN',
        subLabel: '°C',
        key: 'air_cooler_air_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'AIR TEMP OUT',
        subLabel: '°C',
        key: 'air_cooler_air_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'PRES. DROP',
        subLabel: 'mmWG',
        key: 'air_cooler_pressure_drop',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Air Coolers Clean Date',
        key: 'air_cooler_cleaning_date',
        width: { xs: '' },
        render: DateTimeInput
      }
    ]
  },
  {
    subGroup: 'jacket_coolers',
    subGroupHeader: 'JACKET COOLER',
    fields: [
      {
        label: 'FW TEMP IN',
        subLabel: '°C',
        key: 'jacket_cool_fresh_water_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'FW TEMP OUT',
        subLabel: '°C',
        key: 'jacket_cool_fresh_water_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'PRESSURE',
        subLabel: 'bar',
        key: 'jacket_cooler_pressure',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CW Temp In',
        subLabel: '°C',
        key: 'me_jacket_cooler_cw_temperature_inlet',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CW Temp Out',
        subLabel: '°C',
        key: 'me_jacket_cooler_cw_temperature_outlet',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'central_coolers',
    subGroupHeader: 'CENTRAL COOLER',
    fields: [
      {
        label: 'SW TEMP IN',
        subLabel: '°C',
        key: 'central_cooler_sea_water_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'SW TEMP OUT',
        subLabel: '°C',
        key: 'central_cooler_sea_water_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'FW TEMP IN',
        subLabel: '°C',
        key: 'central_cooler_fresh_water_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'FW TEMP OUT',
        subLabel: '°C',
        key: 'central_cooler_fresh_water_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'me_lo_coolers',
    subGroupHeader: 'MAIN LO COOLER',
    fields: [
      {
        label: 'CW TEMP IN',
        subLabel: '°C',
        key: 'main_lub_oil_cooler_water_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CW TEMP OUT',
        subLabel: '°C',
        key: 'main_lub_oil_cooler_water_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'LO TEMP IN',
        subLabel: '°C',
        key: 'main_lub_oil_cooler_lub_oil_inlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'LO TEMP OUT',
        subLabel: '°C',
        key: 'main_lub_oil_cooler_lub_oil_outlet_temperature',
        className: 'smaller-template-input',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const coolersConfig = {
  noon: () => coolers(),
  arrival: () => coolers(),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => coolers(),
  port_noon: () => coolers(),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default coolersConfig;
