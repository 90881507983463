import { LOCATION_CHANGE } from 'connected-react-router';

import PROFILE_TYPES from 'common/components/jobs/_base/store/types';
import FLAG_EXTENSIONS_DISPENSATIONS_TYPES from 'common/components/jobs/flag-extensions-dispensations/store/types';
import MAINTENANCE_TYPES from 'common/components/jobs/maintenance/store/types';

import LINKED_TYPES from 'common/components/jobs/_base/modules/linked-jobs/store/types';
import ACTIVITY_TYPES from 'common/components/jobs/_base/modules/activity/store/types';
import REMINDER_TYPES from 'common/components/jobs/_base/modules/reminders/store/types';
import TEMPLATE_LABEL_TYPES from 'common/components/jobs/_base/modules/template/store/types';
import TABLE_TYPES from 'store/tables/types';

import FORMS_INITIAL_STATE from 'common/components/jobs/_base/modules/forms/store/state';
import CHECKLIST_INITIAL_STATE from 'common/components/jobs/_base/modules/checklist/store/state';
import NOTES_INITIAL_STATE from 'common/components/jobs/_base/modules/notes/store/state';

import formsReducer from 'common/components/jobs/_base/modules/forms/store/reducer';
import checkListReducer from 'common/components/jobs/_base/modules/checklist/store/reducer';
import notesSlice from 'common/components/jobs/_base/modules/notes/store/slice';

import _omit from 'lodash/omit';
import paths from 'routing/routes/_paths';
import {
  getJobTemplateInitialValues,
  getJobTemplateFleetInitialValues,
  getJobTemplateFleetExtraValues
} from 'common/components/jobs/_base/modules/template/_helpers';

import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';

const INITIAL_STATE = {
  isSaving: false,
  unauthorizedViewer: false,

  formErrors: {},

  id: null,
  status_id: null,
  assignee: null,
  project: null,
  type: null,
  approvers: [{}],
  vessels: null,
  due_date_show_time: false,
  importance_id: null,

  due_date: null,
  title: null,
  document_title: null,
  description: null,
  remarks: null,
  has_many_due_date_changes: false,
  last_due_date_change: null,
  main_department: null,
  responsible_onboard_department: null,
  departments: [],
  is_archived: false,
  created_at: null,
  created_by: null,
  attachments: [],
  attached_manuals: [],
  findings: [],
  events: [],
  vessel_comments: '',
  vessel_attachments: [],

  has_linked_jobs: false,
  tags: [],
  viewers_count: 0,
  viewers: [],
  port: {},
  itinerary_port: {},

  linked: {
    jobId: null,
    data: []
  },

  maintenanceFetching: false,
  maintenance: {
    maintenance_attachments: [],
    maintenance_details: {},
    maintenance_spare_parts: {
      consumed: [],
      required: []
    }
  },
  previous_maintenance_job: {},
  periodicity: null,
  periodicity_description: null,

  isSparePartsCollapsed: true,

  activity: {
    jobId: null,
    data: [],
    currentPage: 1,
    hasMore: true
  },
  reminders: {
    jobId: null,
    data: []
  },

  jobCustomTemplate: null,
  template: null,
  labels: [],
  openTemplateVessels: [],
  templateData: {
    id: null,
    fleetTemplate: false,
    type_id: null,
    labels: []
  },

  isA4Layout: false,

  activeTabs: [],

  shouldNotifyUserOnLeave: false, // if the user has unsaved changes on job CREATION, show an alert when leaving the page without saving

  typeOfLoggedInUser: {}, // Set if the logged in user is Creator/Assignee/Approver of a job

  is_for_vessel: false,

  tableShouldRefetchData: false,

  resubmitted: false,

  is_drydock: false,

  flag_extension: {
    completed_at: null,
    informed_at: null,
    type: null,
    requested_on: null,
    country: null,
    requested_for: null,
    attachments: []
  },

  prefilledVessel: null, // If this key is populated will lock the vessel field and the job selector will search with the id of the specific vessel

  ..._cloneDeep(FORMS_INITIAL_STATE),
  ...CHECKLIST_INITIAL_STATE,
  ...NOTES_INITIAL_STATE
};

const chainedReducers = [formsReducer, checkListReducer, notesSlice];

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case MAINTENANCE_TYPES.UPDATE_MAINTENANCE.START:
      return {
        ...state,
        isSaving: true
      };
    case MAINTENANCE_TYPES.UPDATE_MAINTENANCE.ERROR:
    case MAINTENANCE_TYPES.UPDATE_MAINTENANCE.SUCCESS:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          maintenance_details: {
            ...state.maintenance.maintenance_details,
            ...payload.maintenance_details
          }
        },

        isSaving: false
      };
    case MAINTENANCE_TYPES.RESET_SPARE_PARTS:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          maintenance_spare_parts: {
            consumed: [],
            required: []
          }
        }
      };
    case MAINTENANCE_TYPES.ADD_MAINTENANCE_SPARE_PART:
      if (payload) {
        const type = payload?.type || 'consumed';

        return {
          ...state,
          maintenance: {
            ...state.maintenance,
            maintenance_spare_parts: {
              ...state.maintenance.maintenance_spare_parts,
              [type]: [
                ...state.maintenance.maintenance_spare_parts[type],
                { spare_part: null, quantity: null }
              ]
            }
          }
        };
      }

      return state;

    case MAINTENANCE_TYPES.REMOVE_MAINTENANCE_SPARE_PART:
      if (payload) {
        const type = payload?.type || 'consumed';

        return {
          ...state,
          maintenance: {
            ...state.maintenance,
            maintenance_spare_parts: {
              ...state.maintenance.maintenance_spare_parts,
              [type]: state.maintenance.maintenance_spare_parts[type].filter(
                (_, i) => i !== payload.index
              )
            }
          }
        };
      }

      return state;

    case MAINTENANCE_TYPES.SET_MAINTENANCE_SPARE_PART:
      const newParts = state.maintenance.maintenance_spare_parts[payload.type].map((el, i) => {
        if (i === payload.index) {
          return {
            ...el,
            [payload.name]: payload.value
          };
        } else {
          return el;
        }
      });

      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          maintenance_spare_parts: {
            ...state.maintenance.maintenance_spare_parts,
            [payload.type]: newParts
          }
        }
      };

    case MAINTENANCE_TYPES.INITIALIZE_PMS_SPARE_PARTS:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          maintenance_spare_parts: {
            ...state.maintenance.maintenance_spare_parts,
            required: payload
          }
        }
      };

    case MAINTENANCE_TYPES.INITIALIZE_CONSUMED_SPARE_PARTS:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          maintenance_spare_parts: {
            ...state.maintenance.maintenance_spare_parts,
            consumed: payload
          }
        }
      };

    case MAINTENANCE_TYPES.SET_MAINTENANCE_FIELD:
      const prevFields = state.maintenance.maintenance_details
        ? { ...state.maintenance.maintenance_details }
        : {};
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          maintenance_details: {
            ...prevFields,
            [payload.field]: payload.value
          }
        }
      };
    case MAINTENANCE_TYPES.GET_MAINTENANCE.START:
      return {
        ...state,
        maintenanceFetching: true
      };

    case MAINTENANCE_TYPES.GET_MAINTENANCE.SUCCESS:
      return {
        ...state,
        maintenanceFetching: false,
        maintenance: {
          ...state.maintenance,
          maintenance_attachments: payload?.maintenance_attachments,
          maintenance_details: payload?.maintenance_details,
          maintenance_spare_parts: payload?.maintenance_spare_parts
        },
        previous_maintenance_job: {
          ...state.previous_maintenance_job,
          ...payload?.previous_maintenance_job
        }
      };

    case MAINTENANCE_TYPES.GET_MAINTENANCE.ERROR:
      return {
        ...state,
        maintenanceFetching: false
      };

    case MAINTENANCE_TYPES.SET_SPARE_PARTS_COLLAPSED:
      return {
        ...state,
        isSparePartsCollapsed: payload
      };

    case FLAG_EXTENSIONS_DISPENSATIONS_TYPES.SET_FLAG_EXTENSIONS_DISPENSATIONS_FIELD:
      return {
        ...state,
        flag_extension: {
          ...state.flag_extension,
          [payload.field]: payload.value
        }
      };

    case PROFILE_TYPES.SET_JOB_FIELD_VALUE:
      return {
        ...state,
        [payload.field]: payload.value
      };

    case PROFILE_TYPES.SET_PREFILLED_VESSEL:
      return {
        ...state,
        prefilledVessel: payload
      };

    case PROFILE_TYPES.SET_JOB_ERROR_FIELD:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...payload
        }
      };

    case PROFILE_TYPES.SET_JOB_CUSTOM_TEMPLATE:
      return {
        ...state,
        jobCustomTemplate: payload
      };

    case PROFILE_TYPES.SET_A4_LAYOUT:
      return {
        ...state,
        isA4Layout: payload
      };

    case PROFILE_TYPES.UPDATE_JOB.START:
    case PROFILE_TYPES.CREATE_JOB.START:
      return {
        ...state,
        isSaving: true
      };

    case PROFILE_TYPES.UPDATE_JOB.SUCCESS:
    case PROFILE_TYPES.CREATE_JOB.SUCCESS:
      return {
        ...state,
        last_due_date_change: payload.last_due_date_change
          ? payload.last_due_date_change
          : state.last_due_date_change,
        has_many_due_date_changes: payload.has_many_due_date_changes
          ? payload.has_many_due_date_changes
          : state.has_many_due_date_changes,
        due_date_changed_at: payload.due_date_changed_at
          ? payload.due_date_changed_at
          : state.due_date_changed_at,
        isSaving: false
      };

    case PROFILE_TYPES.UPDATE_JOB.ERROR:
    case PROFILE_TYPES.CREATE_JOB.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case PROFILE_TYPES.GET_JOB.START:
    case PROFILE_TYPES.RESET_JOB_PROFILE:
      return {
        ...state,
        ...INITIAL_STATE,
        ...(payload?.type ? { type: payload?.type } : {}),
        isA4Layout: state.isA4Layout,
        checklist: {
          jobId: null,
          options: [],
          openLists: []
        },
        reminders: {
          jobId: '',
          data: []
        },
        linked: {
          jobId: null,
          data: []
        },
        jobCustomTemplate: null,
        template: null,
        labels: [],
        openTemplateVessels: [],
        templateData: {
          id: null,
          fleetTemplate: false,
          type_id: null,
          labels: []
        },
        ...NOTES_INITIAL_STATE,
        ..._cloneDeep(FORMS_INITIAL_STATE)
      };

    case PROFILE_TYPES.GET_JOB.ERROR:
      return {
        ...state,
        ...INITIAL_STATE,
        isA4Layout: state.isA4Layout,
        unauthorizedViewer: payload.status === 403 ? true : false,
        reminders: {
          jobId: '',
          data: []
        }
      };

    case PROFILE_TYPES.SET_JOB_PROFILE:
      if (payload.id !== state.id) {
        const activeTabs = [];

        if (payload.template) activeTabs.push('template');
        if (payload.has_forms) activeTabs.push('forms');

        return {
          ...state,
          ...INITIAL_STATE,
          ...payload,
          unauthorizedViewer: false,
          activeTabs
        };
      } else {
        return {
          ...state,
          ...payload
        };
      }

    case PROFILE_TYPES.GET_JOB.SUCCESS:
      const activeTabs = [];

      if (payload.template) activeTabs.push('template');
      if (payload.has_forms) activeTabs.push('forms');

      return {
        ...state,
        ...payload,
        unauthorizedViewer: false,
        activeTabs
      };

    case PROFILE_TYPES.SET_TYPE_OF_LOGGED_IN_USER:
      return {
        ...state,
        typeOfLoggedInUser: payload
      };

    case TEMPLATE_LABEL_TYPES.ADD_OPEN_VESSEL:
      return {
        ...state,
        openTemplateVessels: state.openTemplateVessels.includes(payload.vessel)
          ? state.openTemplateVessels
          : [...state.openTemplateVessels, payload.vessel]
      };

    case TEMPLATE_LABEL_TYPES.REMOVE_OPEN_VESSEL:
      return {
        ...state,
        openTemplateVessels: state.openTemplateVessels.includes(payload.vessel)
          ? [...state.openTemplateVessels.filter(el => el !== payload.vessel)]
          : state.openTemplateVessels
      };

    case TEMPLATE_LABEL_TYPES.SET_OPEN_VESSELS:
      return {
        ...state,
        openTemplateVessels: payload.vessels
      };

    case PROFILE_TYPES.SET_ACTIVE_JOB_TAB:
      return {
        ...state,
        activeTabs:
          state.activeTabs.indexOf(payload.tab) !== -1 || payload.remove
            ? state.activeTabs.filter(t => t !== payload.tab)
            : [...state.activeTabs, payload.tab]
      };

    case PROFILE_TYPES.SET_SELECTED_JOB:
      return {
        ...state,
        selectedJob: payload
      };

    case PROFILE_TYPES.ARCHIVE_JOB.SUCCESS:
    case PROFILE_TYPES.UNARCHIVE_JOB.SUCCESS:
      if (state.id === payload.id) {
        return {
          ...state,
          is_archived: payload.is_archived
        };
      } else {
        return state;
      }

    case ACTIVITY_TYPES.GET_JOB_ACTIVITY.SUCCESS:
      const fiveMinutesAfterCreated = state.created_at
        ? moment(state.created_at).add(5, 'minutes')
        : null;

      return {
        ...state,
        activity: {
          jobId: payload.jobId,
          data: [...state.activity.data, ...payload.data].filter(el => {
            if (el.description === 'created') return true;
            return moment(el.created_at).isAfter(fiveMinutesAfterCreated);
          }),
          currentPage: payload.meta.current_page,
          hasMore: payload.meta.last_page > payload.meta.current_page
        }
      };

    case ACTIVITY_TYPES.GET_JOB_ACTIVITY.ERROR:
      return {
        ...state,
        activity: {
          jobId: '',
          data: [],
          currentPage: 1,
          hasMore: true
        }
      };
    case ACTIVITY_TYPES.CLEAR_JOB_ACTIVITY:
      return {
        ...state,
        activity: {
          jobId: '',
          data: [],
          currentPage: 1,
          hasMore: true
        }
      };

    case LINKED_TYPES.UPDATE_JOB_LINKED_JOBS.START:
      return {
        ...state,
        isSaving: true
      };

    case LINKED_TYPES.UPDATE_JOB_LINKED_JOBS.SUCCESS:
    case LINKED_TYPES.UPDATE_JOB_LINKED_JOBS.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case LINKED_TYPES.GET_JOB_LINKED_JOBS.SUCCESS:
    case LINKED_TYPES.SET_LINKED_JOBS:
      return {
        ...state,
        linked: {
          jobId: state.id,
          data: payload
        }
      };

    case LINKED_TYPES.GET_JOB_LINKED_JOBS.ERROR:
      return {
        ...state,
        linked: {
          jobId: null,
          data: []
        }
      };

    case REMINDER_TYPES.GET_JOB_REMINDERS.START:
      if (payload.job_id !== state.id) {
        return {
          ...state,
          reminders: {
            jobId: '',
            data: []
          }
        };
      } else {
        return state;
      }

    case REMINDER_TYPES.GET_JOB_REMINDERS.SUCCESS:
      return {
        ...state,
        reminders: {
          jobId: payload.jobId,
          data: payload.data
        }
      };

    case REMINDER_TYPES.CLEAR_REMINDERS:
      return {
        ...state,
        reminders: {
          jobId: null,
          data: []
        }
      };

    case REMINDER_TYPES.ADD_JOB_REMINDER:
      return {
        ...state,
        reminders: {
          ...state.reminders,
          data: [...state.reminders.data, ...payload]
        }
      };

    case REMINDER_TYPES.CHANGE_JOB_REMINDER:
      return {
        ...state,
        reminders: {
          ...state.reminders,
          data: state.reminders.data.map(el => {
            if (el.id === payload.id) return payload;
            return el;
          })
        }
      };

    case REMINDER_TYPES.REMOVE_JOB_REMINDER:
      return {
        ...state,
        reminders: {
          ...state.reminders,
          data: state.reminders.data.filter(el => el.id !== payload.id)
        }
      };

    case TEMPLATE_LABEL_TYPES.GET_JOB_TEMPLATE_DATA.START:
      return {
        ...state,
        templateData: {
          id: payload.params.id,
          labels: []
        }
      };

    case TEMPLATE_LABEL_TYPES.RESET_JOB_TEMPLATE_DATA:
      return {
        ...state,
        labels: [],
        templateData: {
          id: null,
          fleetTemplate: false,
          type_id: null,
          labels: []
        }
      };

    case TEMPLATE_LABEL_TYPES.SET_JOB_TEMPLATE_LABEL:
      const { relation_specific_id } = payload;

      if (relation_specific_id) {
        return {
          ...state,
          templateData: {
            ...state.templateData,
            labels: {
              ...state.templateData.labels,
              [`vessels_${relation_specific_id}`]: state.templateData.labels[
                `vessels_${relation_specific_id}`
              ].map(l => (l.label_id === payload.label_id ? { ...l, ...payload } : l))
            }
          }
        };
      } else {
        return {
          ...state,
          templateData: {
            ...state.templateData,
            labels: state.templateData.labels.map(l =>
              l.label_id === payload.label_id ? { ...l, ...payload } : l
            )
          }
        };
      }

    case TEMPLATE_LABEL_TYPES.UPDATE_JOB_TEMPLATE_LABEL.START:
      return {
        ...state,
        isSaving: true
      };

    case TEMPLATE_LABEL_TYPES.UPDATE_JOB_TEMPLATE_LABEL.SUCCESS:
    case TEMPLATE_LABEL_TYPES.UPDATE_JOB_TEMPLATE_LABEL.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case TEMPLATE_LABEL_TYPES.UPDATE_FLEET_TEMPLATE_DATA:
      let newLabels = { ...state.labels };
      let newOpen = [
        ...state.openTemplateVessels.filter(el => payload.vessels.find(v => v.id === el))
      ];

      let oldLabelVesselIds = Object.keys(newLabels).map(el => +el.split('_')[1]);
      oldLabelVesselIds.forEach(labelVesselId => {
        if (!payload.vessels.find(el => el.id === labelVesselId)) {
          newLabels = _omit(newLabels, [`vessels_${labelVesselId}`]);
        }
      });

      return {
        ...state,
        labels: newLabels,
        openTemplateVessels: newOpen,
        templateData: {
          ...state.templateData,
          labels: getJobTemplateFleetExtraValues(
            state.templateData.labels,
            payload.template,
            state.labels,
            payload.vessels
          )
        }
      };

    case TEMPLATE_LABEL_TYPES.GET_JOB_TEMPLATE_DATA.SUCCESS:
      const { isFleet } = payload;

      return {
        ...state,
        templateData: {
          id: payload.id,
          fleetTemplate: isFleet ? true : false,
          type_id: payload.type_id,
          labels: isFleet
            ? getJobTemplateFleetInitialValues(
                payload,
                state.labels || [],
                state.vessels ? state.vessels : []
              )
            : getJobTemplateInitialValues(payload.labels, state.labels || [])
        }
      };

    case TEMPLATE_LABEL_TYPES.GET_TEMPLATE_LABELS.START:
    case TEMPLATE_LABEL_TYPES.GET_TEMPLATE_LABELS.ERROR:
      return {
        ...state,
        labels: []
      };

    case TEMPLATE_LABEL_TYPES.GET_TEMPLATE_LABELS.SUCCESS:
      return {
        ...state,
        labels: payload
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(paths.regular_jobs) && state.id) {
        return {
          ...state,
          ...INITIAL_STATE
        };
      }

      return state;

    case TABLE_TYPES.RESET_CURRENT_PAGE:
      return state;

    case PROFILE_TYPES.SET_DEFAULT_JOB_STATUS:
      return {
        ...state,
        status_id: payload
      };

    case PROFILE_TYPES.SET_UNSAVED_CHANGES_ALERT:
      const hasApprover = payload.job.approvers.filter(approver => approver.id);
      let notify = false;

      payload.requiredFields.map(field => {
        if (
          (field.field === 'assignee_id' && payload.job.assignee) ||
          (field.field === 'approvers' && hasApprover.length) ||
          (field.field === 'due_date' && payload.job.due_date) ||
          (field.field === 'tags' && payload.job.tags) ||
          (field.field === 'title' && payload.job.title) ||
          (field.field === 'description' && payload.job.description) ||
          (field.field === 'template_id' && payload.job.template) ||
          (field.field === 'checklist_options' && payload.job.checklist)
        ) {
          notify = true;
        }

        return notify;
      });

      return {
        ...state,
        shouldNotifyUserOnLeave: notify
      };

    case PROFILE_TYPES.SET_TABLE_SHOULD_REFETCH_DATA:
      return {
        ...state,
        tableShouldRefetchData: payload
      };

    case 'UNLINK_JOB_FINDING/fulfilled':
      return {
        ...state,
        findings: state.findings.filter(e => e.id !== payload?.id)
      };

    default:
      return chainedReducers.reduce(
        (prevState, reducer) => reducer(prevState, { type, payload }),
        state
      );
  }
};

export default reducer;
