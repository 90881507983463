import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modals/BasicModal';
import DateInput from 'common/components/form/inputs/date';
import { Button } from 'reactstrap';

import { useForm, useFormState } from 'utils/hooks';
import { dateField } from 'common/utils/form/fieldTypes';
import moment from 'moment';

const MarkAsResolvedModal = ({ isOpen, onCancel, onSave, initialValue, container }) => {
  const { formState, loadValues } = useForm({ resolved_at: dateField() });
  const { fields, selectField } = useFormState(formState);

  useEffect(() => {
    if (isOpen) loadValues({ resolved_at: initialValue });
  }, [isOpen, initialValue]);

  const onSetDate = () => {
    onSave({ resolved_at: fields.resolved_at.value });
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      container={container}
      backdropClassName="blurred-backdrop"
      header="Mark as Resolved"
      size="sm"
      footer={
        <>
          <Button color="cancel" onClick={onCancel}>
            CANCEL
          </Button>
          <Button color="primary" onClick={onSetDate}>
            SAVE
          </Button>
        </>
      }
      body={
        <DateInput
          label="RESOLVED DATE"
          datePlaceholder="Set Date"
          onChange={selectField('resolved_at')}
          {...fields.resolved_at}
        />
      }
    />
  );
};

MarkAsResolvedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.instanceOf(moment)
};

export default MarkAsResolvedModal;
