import TYPES from './types';

const INITIAL_STATE = {
  onBoard: false,
  isAutoSaving: false,
  isEditting: false,

  actionDetails: {},
  actionDocuments: {},
  actions: [],

  current_port: {},
  id: null,
  rebuild_comments: null,
  vessel: {},
  approved_by: null,
  reviewed_by_tech: {},
  is_reviewed_by_tech: null,
  status: null,
  report_group: {},
  office_remarks: null,
  timestamp: null,
  arrival_timestamp: null,
  isFormDrawerOpen: false,
  selectedFormData: null,
  currentAction: null,
  isFormDrawerDisabled: false,
  linked_cpt_report_id: null
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_PORT_DOCUMENT_OVERVIEW:
      const {
        actions,
        current_port,
        id,
        rebuild_comments,
        vessel,
        approved_by,
        reviewed_by_tech,
        is_reviewed_by_tech,
        status,
        report_group,
        onBoard,
        office_remarks,
        timestamp,
        arrival_timestamp,
        linked_cpt_report_id
      } = payload;

      const reportActionsState = actions.reduce(
        (acc, action) => {
          const { documents, ...rest } = action;

          acc.actions.push(action.id);
          acc.actionDetails[action.id] = rest;
          acc.actionDocuments[action.id] = documents;

          return acc;
        },
        { actions: [], actionDetails: {}, actionDocuments: {} }
      );

      return {
        ...state,
        ...reportActionsState,
        onBoard,
        current_port,
        id,
        rebuild_comments,
        vessel,
        approved_by,
        reviewed_by_tech,
        is_reviewed_by_tech,
        report_group,
        status,
        office_remarks,
        timestamp,
        arrival_timestamp,
        linked_cpt_report_id
      };

    case TYPES.SET_SELECTED_FORM:
      return {
        ...state,
        selectedFormData: payload
      };

    case TYPES.SET_CURRENT_ACTION:
      return {
        ...state,
        currentAction: payload
      };

    case TYPES.SET_FORM_DRAWER:
      return {
        ...state,
        isFormDrawerOpen: payload
      };

    case TYPES.SET_FORM_DRAWER_IS_DISABLED:
      return {
        ...state,
        isFormDrawerDisabled: payload
      };

    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW_INNER_ACTION.START:
      const { isAutosave } = payload?.params;

      return {
        ...state,
        isAutoSaving: isAutosave ? true : false
      };

    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW.START:
    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW_ACTION.START:
      return {
        ...state,
        isAutoSaving: payload?.params?.isAutosave ? true : false
      };

    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW_ACTION.SUCCESS:
    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW_INNER_ACTION.SUCCESS: {
      const { id, is_filled, action } = payload;

      return {
        ...state,
        actionDocuments: {
          ...state.actionDocuments,
          [action.id]: state.actionDocuments[action.id].map(document => {
            if (document.id === id) {
              return {
                ...document,
                is_filled: is_filled
              };
            }

            return document;
          })
        },
        isAutoSaving: false
      };
    }
    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW.ERROR:
    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW_ACTION.ERROR:
    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW_INNER_ACTION.ERROR:
      return {
        ...state,
        isAutoSaving: false
      };

    case TYPES.UPDATE_PORT_DOCUMENT_OVERVIEW.SUCCESS:
      return {
        ...state,
        ...payload?.params,
        isAutoSaving: false
      };

    case TYPES.SET_EDITTING_PORT_DOCUMENT_OVERVIEW:
      return {
        ...state,
        isEditting: payload.isEditting
      };

    case TYPES.SET_PORT_DOCUMENT_OVERVIEW_REBUILD_COMMENTS:
      return {
        ...state,
        rebuild_comments: payload
      };

    default:
      return state;
  }
};

export default reducer;
