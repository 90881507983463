import { getFormFieldParams } from 'common/components/forms/digital/state-utils';
import { Link } from 'react-router-dom';
import SvgRender from 'common/components/general/SvgRender';
import ChapterSortIndexPath from 'manuals/components/ChapterSortIndexPath';

import paths from 'routing/routes/_paths';
import _get from 'lodash/get';

import solidRibbon from 'common/assets/svg/common/ribbon-solid.svg';

const ManualChapter = ({ formField }) => {
  const { manual, manual_id, chapter_uid, chapter } = getFormFieldParams(formField);

  return manual_id ? (
    <div className="fs-12">
      <div>
        <Link
          className="me-1 text-primary link-medium"
          to={`${paths.manuals}/${manual_id}`}
          target="_blank"
        >
          {_get(manual, 'title', '-')}
        </Link>
      </div>
      {chapter ? (
        <div className="d-flex align-items-center text-violet mt-1">
          <SvgRender
            style={{ width: 14, height: 14 }}
            src={solidRibbon}
            className="text-yellow cme-4"
          />
          <ChapterSortIndexPath className="text-violet" chapter={chapter || {}} />
          <Link
            className="me-1 text-violet link-medium"
            to={`${paths.manuals}/${manual_id}/chapter/${chapter_uid}`}
            target="_blank"
          >
            {_get(chapter, 'title', '-')}
          </Link>
        </div>
      ) : null}
    </div>
  ) : (
    '-'
  );
};

export default ManualChapter;
