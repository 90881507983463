import React from 'react';
import Header from 'common/components/link-entities/_components/Header';
import ShadowBox from 'common/components/general/ShadowBox';

import styled from '@emotion/styled';

import PropTypes from 'prop-types';

const LinkedEntitiesBox = ({ entitiesState, className, icon, headerTitle, color, render }) => {
  return (
    <div className={className}>
      <Header icon={icon} title={headerTitle} />
      {entitiesState.map((entity, index) => (
        <Container key={index}>
          <ShadowBox
            flat
            whiteOnHover
            color={color}
            className="cps-12 cpy-6 overflow-hidden text-nowrap cmb-4 cursor-pointer position-relative"
          >
            {render(entity)}
          </ShadowBox>
        </Container>
      ))}
    </div>
  );
};

const Container = styled.div`
  &:hover {
    .linked-actions {
      opacity: 1;
    }
  }
`;

LinkedEntitiesBox.propTypes = {
  entitiesState: PropTypes.array,
  className: PropTypes.string,
  icon: PropTypes.string,
  headerTitle: PropTypes.string,
  color: PropTypes.string,
  render: PropTypes.func
};

export default LinkedEntitiesBox;
