import PropTypes from 'prop-types';
import Sidebar from './sidebar';
import Header from './header';

import { useSelector } from 'react-redux';

const ManualInnerLayout = ({
  sidebarActions,
  chapterActions,
  chaptersLoading,
  children,
  postActions,
  manualActions
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);

  return (
    <div className={`manual-layout ${isOnboard ? 'is-onboard' : ''}`}>
      <Header
        isOnboard={isOnboard}
        actions={sidebarActions}
        postActions={postActions}
        manualActions={manualActions}
        chaptersLoading={chaptersLoading}
      />

      <div className="manual-layout__inner">
        <Sidebar chapterActions={chapterActions} chaptersLoading={chaptersLoading} />
        <div className="manual-layout__main">{children}</div>
      </div>
    </div>
  );
};

ManualInnerLayout.propTypes = {
  sidebarActions: PropTypes.node,
  chaptersLoading: PropTypes.bool,
  chapterActions: PropTypes.shape({
    onChapterCreate: PropTypes.func,
    onChapterRename: PropTypes.func,
    onChapterDelete: PropTypes.func,
    onChapterRearrange: PropTypes.func,
    onChapterMove: PropTypes.func,
    searchInManualChapters: PropTypes.func
  })
};

export default ManualInnerLayout;
