import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_EVENT'),
  ...asyncTypes('EDIT_EVENT'),
  ...asyncTypes('WATCH_EVENT'),
  ...asyncTypes('UNWATCH_EVENT'),
  ...asyncTypes('GET_EVENT'),
  ...asyncTypes('DELETE_EVENT'),
  ...asyncTypes('ADD_MODULE'),
  ...asyncTypes('UPDATE_MODULE'),
  ...asyncTypes('REMOVE_MODULE'),
  ...asyncTypes('SUBMIT_EVENT'),
  ...asyncTypes('CREATE_EVENT_DIGITAL_SIGNATURE'),
  ...asyncTypes('DELETE_EVENT_DIGITAL_SIGNATURE'),
  ...asyncTypes('GET_EVENT_DIGITAL_SIGNATURES'),
  SET_EVENT_VALUE: 'SET_EVENT_VALUE',
  SET_EVENT_ERRORS: 'SET_EVENT_ERRORS',
  RESET_EVENT_STATE: 'RESET_EVENT_STATE',
  SET_IS_EDITING_MODULES: 'SET_IS_EDITING_MODULES',
  SET_ACTIVE_MODULES_LABELS: 'SET_ACTIVE_MODULES_LABELS',
  SET_IS_ON_BOARD: 'SET_IS_ON_BOARD',
  SET_IS_LOCKED: 'SET_IS_LOCKED',
  REMOVE_ALL_MODULES: 'REMOVE_ALL_MODULES',
  SET_IS_MULTIPLE_EVENTS_CREATE: 'SET_IS_MULTIPLE_EVENTS_CREATE',
  RESET_MULTIPLE_EVENTS_DATES: 'RESET_MULTIPLE_EVENTS_DATES',
  RESET_SINGLE_EVENT_DATES: 'RESET_SINGLE_EVENT_DATES',
  REORDER_MODULES: 'REORDER_MODULES'
};

export default TYPES;
