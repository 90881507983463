import moment from 'moment';
import DateInput from 'common/components/form/inputs/date';

const DatetimeInput = ({ error, value, onChange }) => {
  return (
    <DateInput
      onChange={d => onChange(d ? d.format('YYYY-MM-DD HH:mm:ss') : null)}
      className="mb-0"
      error={error}
      value={value ? moment(value) : null}
      showTime
      hasTime
    />
  );
};

export default DatetimeInput;
