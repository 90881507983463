import { useMemo } from 'react';
import Select from 'common/components/form/inputs/Select';
import moment from 'moment';

const MonthSelect = ({ value, onChange }) => {
  const options = useMemo(() => {
    let months = [];

    for (let i = 0; i < 12; i++) {
      const month = {
        moment: moment().month(i),
        value: moment().month(i).format('MMMM'),
        label: moment().month(i).format('MMMM')
      };

      months.push(month);
    }

    return months;
  }, []);

  const handleMonthChange = selectedMonth => {
    onChange({ moment: value.clone().month(selectedMonth) });
  };

  return (
    <Select
      options={options}
      className="mb-0 date-input-month-select"
      dataCy="date-input-month-select"
      placeholder=""
      invisible
      isSearchable={false}
      value={value?.format('MMMM')}
      onChange={(_, m) => handleMonthChange(m?.value)}
      menuPortalTarget={null}
    />
  );
};

export default MonthSelect;
