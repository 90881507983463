import PropTypes from 'prop-types';

import Table from './Table';

const ColoredTable = ({ className = '', color = 'white', ...tableProps }) => {
  const getClassNames = () => {
    const classNames = [];

    if (tableProps.columns.some(c => c.field === 'actions')) {
      classNames.push('with-actions');
    }

    return classNames.join(' ');
  };

  return (
    <div className={`colored-table color-${color} ${getClassNames()} ${className}`}>
      <Table {...tableProps} />
    </div>
  );
};

ColoredTable.propTypes = {
  color: PropTypes.oneOf(['coral', 'white', 'lighter-blue', 'lighter-gray'])
};

export default ColoredTable;
