import { createSelector } from 'reselect';
import numeral from 'numeral';

import { RootState } from '@/store';

const selectCaptainReportsReducer = (state: RootState) => state.captainReports;
const selectCaptainReportsData = (state: RootState) => selectCaptainReportsReducer(state).data;
const selectCaptainReportsFormEngines = (state: RootState) => state.captainReportsForm.engines;

export const selectCaptainReportsFormReducer = (state: RootState) => state.captainReportsForm;
const getKey = (_: RootState, key: string) => key;

export const selectFieldValue = createSelector(
  selectCaptainReportsFormReducer,
  getKey,
  (fields, key) => {
    if (!fields[key]) return null;

    if (fields[key].selectValue !== undefined) {
      return fields[key].selectValue;
    }

    return fields[key].value;
  }
);

export const selectLastUpdatedGeneratorTabIndex = (state: RootState): number | null =>
  selectCaptainReportsReducer(state).lastUpdatedGeneratorTabIndex;

export const selectOffHireEventId = (state: RootState): number | undefined =>
  selectCaptainReportsData(state).off_hire_event?.id;

export const selectMainEngineTotalByKey = createSelector(
  selectCaptainReportsFormEngines,
  getKey,
  (mainEngine: Array<{ [key: string]: { value: number | null } }> | undefined, key: string) => {
    if (!key) return null;

    const arrayOfValues = mainEngine?.map(g => g[key].value) || [];

    return arrayOfValues.reduce((acc, cur) => {
      if (cur !== null) {
        return numeral(acc || 0)
          .add(cur)
          .value();
      } else {
        return acc;
      }
    }, null);
  }
);
