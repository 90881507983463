import React from 'react';
import ShadowContainer from '../layout/ShadowContainer';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { Row, Col } from 'reactstrap';
import vesselInfoKeys from '../vesselInfoConfig';
import _get from 'lodash/get';
import Fade from 'react-reveal/Fade';
import ActiveTabViewActions from 'common/components/layouts/vertical-tab-view/components/ActiveTabViewActions';
import permissions from '@/common/utils/permissions/constants';

const VesselInfo = ({ showVesselInfoForm }) => {
  const profile = useSelector(selectVesselProfile);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <Fade>
      <ShadowContainer className="py-1 position-relative">
        {!isOnBoard ? (
          <ActiveTabViewActions
            mode={'view'}
            editPermissions={[permissions.office.vessels.edit]}
            onEditClick={showVesselInfoForm}
          />
        ) : null}
        {vesselInfoKeys.map((el, index) => {
          return el.fields.map((section, i) => (
            <Row
              key={section.key}
              className={`${
                i === el.fields.length - 1 && vesselInfoKeys.length - 1 !== index
                  ? 'border-divider-bottom'
                  : ''
              }  g-0`}
            >
              <Col
                xs={4}
                className="fs-12 d-flex align-items-start text-blue fw-bold border-divider-right px-1 cpb-4 cpt-4"
              >
                {section.name}
              </Col>
              <Col className="px-1 cpb-4 cpt-4 fs-12 d-flex align-items-start" xs={8}>
                {section.key === 'vessel_groups' ? (
                  <div>
                    {_get(profile, section.key, []).map((group, groupIndex) => (
                      <span key={groupIndex.toString()}>
                        {group.name}
                        {_get(profile, section.key, []).length - 1 !== groupIndex && ', '}
                      </span>
                    ))}
                  </div>
                ) : (
                  _get(profile, section.key, '-')
                )}
              </Col>
            </Row>
          ));
        })}
      </ShadowContainer>
    </Fade>
  );
};

export default VesselInfo;
