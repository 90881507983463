import React, { useState } from 'react';
import { string } from 'prop-types';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  checkIfEditMode,
  selectOfficeUserIsAuthorized
} from 'common/components/jobs/_base/store/selectors';
import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';

import ForVesselSwitch from 'common/components/form/styled-inputs/ForVesselSwitch';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import Modal from './modal';
import { selectIsOrcaJob } from 'common/components/jobs/orca-jobs/store/selectors';

import PropTypes from 'prop-types';
import { departmentTypeEnums } from 'common/utils/fixed';

const ForVessel = ({ className, avoidCheck }) => {
  const isVesselVisible = useSelector(selectIfFieldIsVisible('vessels'));

  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));
  const isEditMode = useSelector(checkIfEditMode);
  const isOrcaJob = useSelector(selectIsOrcaJob);
  const canEdit = useSelector(selectOfficeUserIsAuthorized);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [setJobField] = useActions([jobProfileActions.setJobField]);

  const handleVesselToggle = () => {
    if (isForVessel && isEditMode) return;

    if (isForVessel) {
      setJobField('is_for_vessel', !isForVessel, true);

      // When is_for_vessel changes or is  null we set responsible_onboard_department to null
      if (!isForVessel) {
        setJobField(departmentTypeEnums.responsible_onboard_department, null, true);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  if (!isVesselVisible) return null;

  return (
    <>
      <ForVesselSwitch
        value={isForVessel}
        onChange={handleVesselToggle}
        hideLabel={false}
        className={className}
        disabled={(isForVessel && isEditMode) || isOrcaJob || !canEdit}
      />

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} avoidCheck={avoidCheck} />
    </>
  );
};

ForVessel.propTypes = {
  className: PropTypes.string,
  avoidCheck: PropTypes.bool
};

export default ForVessel;

ForVessel.propTypes = {
  className: string
};
