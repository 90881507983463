import React from 'react';

import ShiftsTable from 'crew/components/shifts/shifts-table';
import ShiftTypeSelector from 'crew/components/shifts/shift-type-selector';

import * as shiftDataActions from 'store/shifts-data/actions';
import { useActions } from 'utils/hooks';

import { selectActiveShiftId } from 'crew/store/shifts/selectors';
import { useSelector } from 'react-redux';
import ContractPeriod from 'crew/components/shifts/crew-member-card/ContractPeriod';
import PeriodDatePicker from 'crew/components/shifts/top-filters/PeriodDatePicker';

import paths from 'routing/routes/_paths';
import { Link } from 'react-router-dom';

const SingleContractShiftsCard = ({ crew, isViewOnly, ...rest }) => {
  const activeShiftId = useSelector(selectActiveShiftId);
  const isEditing = activeShiftId === crew.id;

  const [updateShift] = useActions([shiftDataActions.updateShift]);

  const updateShiftWithParams = async (params = {}) => {
    const combinedParams = {
      ...params,
      vesselId: crew.contract.vessel.id,
      crewId: crew.id
    };

    return updateShift(combinedParams);
  };

  const url = `${paths.vessels}/${crew.contract.vessel.id}`;

  return (
    <div
      className={`crew-profile-shifts-card ${isEditing ? 'crew-profile-shifts-card--editing' : ''}`}
    >
      <div className="crew-profile-shifts-card__header">
        <div>
          <Link to={url} className="link-medium">
            <span className="crew-profile-shifts-card__header--vessel">
              {crew.contract.vessel.name}
            </span>
          </Link>
          <span className="crew-profile-shifts-card__header--rank">{crew.rank}</span>
          <span className="crew-profile-shifts-card__header--contract">{crew.contract.name}</span>
        </div>
        <PeriodDatePicker isCrewProfile crew={crew} />
      </div>
      <div className="crew-profile-shifts-card__body">
        <div className="crew-profile-shifts-card__body-topbar">
          <div className="crew-profile-shifts-card__body-topbar--contract">
            <ContractPeriod crew={crew} isCrewProfile />
          </div>
          <div className="border-start">
            <ShiftTypeSelector isEditing={isEditing} crew={crew} isCrewProfile />
          </div>
        </div>
        <ShiftsTable
          isEditing={isEditing}
          crew={crew}
          updateShift={updateShiftWithParams}
          isViewOnly={isViewOnly}
          isCrewProfile={true}
          {...rest}
        />
      </div>
    </div>
  );
};

export default SingleContractShiftsCard;
