import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import * as d3 from 'd3';

/* 

    valueKey of schema is the key of the value of the dataPoint.
    colorKey of schema is the key of the color of the dataPoint.

*/

const DEFAULT_BORDER_WIDTH = 1;

const styleDefinitions = [
  { type: 'attr', key: 'stroke', style: 'pieBorderColor', defaultValue: variables.primary },
  {
    type: 'style',
    key: 'stroke-width',
    style: 'pieBorderWidth',
    defaultValue: DEFAULT_BORDER_WIDTH
  },
  { type: 'style', key: 'opacity', style: 'pieOpacity', defaultValue: 1 }
];

const renderPlot = ({ plotID, plot, getPlotStyle, element, schema, getDimensions }) => {
  const colors = d3
    .scaleOrdinal()
    .domain(plot.data)
    .range(plot.data.map(dataPoint => dataPoint[schema.colorKey]));

  const pie = d3.pie().value(d => d[schema.valueKey]);
  const pieData = pie(plot.data);

  const { width, height } = getDimensions();
  const radius = Math.min(width, height) / 2 - getPlotStyle('pieBorderWidth', DEFAULT_BORDER_WIDTH);

  element
    .select(`#${plotID}`)
    .style(
      'transform',
      `translate(${radius + DEFAULT_BORDER_WIDTH}px, ${radius + DEFAULT_BORDER_WIDTH}px)`
    );

  element
    .selectAll(`#${plotID} path`)
    .data(pieData)
    .attr('d', d3.arc().innerRadius(0).outerRadius(radius))
    .attr('fill', d => colors(d.data[schema.colorKey]));

  styleDefinitions.forEach(styleDefinition => {
    element
      .selectAll(`#${plotID} path`)
      [styleDefinition.type](
        styleDefinition.key,
        getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
      );
  });
};

const renderComponent = ({ plotID, plot }) => {
  return (
    <g id={plotID} key={plotID}>
      {/* We create a <path /> for each data point. */}
      {plot.data.map((_, i) => (
        <path key={i} />
      ))}
    </g>
  );
};

const pie = {
  renderPlot,
  renderComponent
};

export default pie;
