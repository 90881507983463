import React, { useEffect } from 'react';
import useValue from 'captain-reports/utils/useValue';
import { useSelector } from 'react-redux';
import {
  selectCaptainReportVessel,
  selectCaptainReportIsOnBoard,
  selectFieldTemplateTooltip
} from 'captain-reports/store/selectors';
import { numberToStr, trimFloatNumber } from 'common/utils/numbers';
import { useActions } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import { Col, Row } from 'reactstrap';

const CpValuesHeader = () => {
  const isOnBoard = useSelector(selectCaptainReportIsOnBoard);
  const [setValue] = useActions([reportActions.setValue]);
  const tooltip = useSelector(selectFieldTemplateTooltip('apparent_slip'));

  const { avoidRender, tooltipID } = useTooltipID('field-label-reports');

  let slip = useValue({
    key: 'apparent_slip'
  });

  let engine_miles = useValue({
    key: 'engine_miles'
  });

  let distance_run = useValue({
    key: 'distance_run'
  });

  const vessel = useSelector(selectCaptainReportVessel);

  useEffect(() => {
    if (engine_miles && distance_run) {
      const slipValue = trimFloatNumber(100 - (distance_run / engine_miles) * 100);

      setValue({
        key: 'apparent_slip',
        value: slipValue
      });
    }
  }, [engine_miles, distance_run]);

  if (avoidRender) return null;

  return (
    <Row className="d-flex align-items-center justify-content-between fs-10 w-100p mt-1">
      <Col className="d-flex align-items-center me-2 text-violet fw-normal">
        PROPELLER PITCH (m/rev):
        <strong className="ms-1">
          {vessel.propeller_pitch ? numberToStr(vessel.propeller_pitch) : '-'}
        </strong>
      </Col>

      <Col xs="auto">
        <ValidationWrapper
          field={{ key: 'apparent_slip', label: null }}
          errorClassName="align-items-center"
          value={slip}
        >
          <div id={tooltipID} className={`${isOnBoard ? 'text-primary' : 'text-dark'}`}>
            <strong>
              SLIP: <span className="ms-1">{slip ? `${numberToStr(slip, 2, 2)}%` : '-'}</span>
            </strong>
          </div>
        </ValidationWrapper>
      </Col>

      {tooltip ? <Tooltip target={tooltipID}>{tooltip}</Tooltip> : null}
    </Row>
  );
};

export default CpValuesHeader;
