import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAgents,
  selectAgentsItineraryPort
} from 'events/store/event-modules/agents/selectors';
import Evaluation from 'common/components/itineraries/Evaluation';
import phoneIcon from 'common/assets/svg/common/phone.svg';
import email from 'common/assets/svg/socials/email.svg';
import comments from 'common/assets/svg/common/comments.svg';
import SvgRender from 'common/components/general/SvgRender';
import CopyAgentDetails from 'common/components/itineraries/CopyAgentDetails';
import { ItineraryAgent } from 'common/entities/itineraries/ItineraryTypes';
import Actions from './Actions';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { editItinerary } from '@/common/components/voyages/itinerary/store/actions';
import Form from './Form';
import Drawer, { useDrawer } from 'common/components/drawer';
import EvaluationForm from 'common/components/itineraries/EvaluationForm';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { RoundedContainer } from '.';

const Agent = ({ agent, setModalInfo, setActiveAgent, drawer }) => {
  const [isHoveringAgent, setIsHoveringAgent] = useState(false);
  const [isHoveringContainer, setIsHoveringContainer] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing)
    return (
      <Form
        isEdit
        onCancel={() => setIsEditing(false)}
        agentId={agent.id}
        onSubmitCallback={() => setIsEditing(false)}
      />
    );

  return (
    <RoundedContainer
      onMouseEnter={() => setIsHoveringContainer(true)}
      onMouseLeave={() => setIsHoveringContainer(false)}
      flatOnHover={true}
      className="cpy-12 px-3 mb-1 position-relative border-radius-11"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="d-flex align-items-center"
          onMouseEnter={() => setIsHoveringAgent(true)}
          onMouseLeave={() => setIsHoveringAgent(false)}
        >
          <span className="fs-14 fw-medium text-primary me-1">{agent.party.full_name}</span>
          {isHoveringAgent ? <CopyAgentDetails agent={agent} /> : null}
        </div>

        <Evaluation
          className="me-4"
          combinedRating={agent.combined_rating?.combined_rating}
          rating={agent.evaluation?.rating}
          onEvaluate={() => {
            drawer.open();
            setActiveAgent(agent);
          }}
        />
      </div>

      <div className="d-flex align-items-center fs-14 text-violet mt-1">
        <span className="me-4">{agent.type?.name || ''}</span>

        {agent.party?.phone ? (
          <div className="d-flex align-items-center me-4">
            <SvgRender
              className="text-violet me-1"
              src={phoneIcon}
              style={{ width: 12, height: 12 }}
            />
            <span>{agent.party.phone}</span>
          </div>
        ) : null}

        {agent.party?.email ? (
          <div className="d-flex align-items-center">
            <SvgRender src={email} style={{ width: 14, height: 14 }} className="text-violet me-1" />
            <span>{agent.party.email}</span>
          </div>
        ) : null}
      </div>

      {agent.party?.notes ? (
        <div className="border-top cpt-12 cmt-12">
          <div className="d-flex align-items-center">
            <SvgRender
              className="text-violet me-1"
              src={comments}
              style={{ width: 12, height: 12 }}
            />
            <span className="fw-medium text-violet fs-12">Notes</span>
          </div>

          <span className="fs-12 text-violet">{agent.party.notes}</span>
        </div>
      ) : null}

      {isHoveringContainer && (
        <ActionsContainer>
          <Actions
            onEdit={() => setIsEditing(true)}
            onDelete={() =>
              setModalInfo({ isOpen: true, id: agent.id, name: agent.party.full_name })
            }
          />
        </ActionsContainer>
      )}
    </RoundedContainer>
  );
};

Agent.propTypes = {
  agent: ItineraryAgent.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  setActiveAgent: PropTypes.func.isRequired,
  drawer: DrawerState.isRequired
};

const List = () => {
  const [modalInfo, setModalInfo] = useState({ isOpen: false, id: null, name: '' });
  const [activeAgent, setActiveAgent] = useState(null);

  const dispatch = useDispatch();

  const drawer = useDrawer();

  const agents = useSelector(selectAgents);
  const itineraryPortId = useSelector(selectAgentsItineraryPort);

  const onRemove = () => {
    dispatch(
      editItinerary({
        id: itineraryPortId,
        agents: agents
          .filter(agent => agent.id !== modalInfo.id)
          .map(agent => ({ id: agent.id, party_id: agent.party_id, type_id: agent.type_id }))
      })
    );
  };

  if (!agents?.length) return null;

  return (
    <>
      {agents.map((agent, index) => (
        <Agent
          key={`agent-${index}`}
          agent={agent}
          setModalInfo={setModalInfo}
          setActiveAgent={setActiveAgent}
          drawer={drawer}
        />
      ))}

      <DangerousActionModal
        transparent
        action="delete"
        onAccept={onRemove}
        closeModal={() => setModalInfo({ isOpen: false, id: null, name: '' })}
        isOpen={modalInfo.isOpen}
        actionText="REMOVE"
        header="Remove"
        body={`Are you sure you want to remove ${modalInfo.name}?`}
      />

      <Drawer {...drawer}>
        <EvaluationForm
          agent={activeAgent}
          closeForm={() => drawer.close()}
          onSubmitCallback={() => drawer.close()}
        />
      </Drawer>
    </>
  );
};

export default List;

const ActionsContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: -0.75rem;
`;
