import Table from 'views/dashboard/components/Table';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';
import { getRows, getColumns } from 'common/components/findings/widget/tableConfig';
import TableViewIcon from 'views/dashboard/components/TableViewIcon';
import { numberToStr } from 'common/utils/numbers';
import paths from 'routing/routes/_paths';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import { stringifyObj } from '@/utils/urls';

const Findings = () => {
  const rows = getRows(true);
  const columns = getColumns(true);

  return (
    <Table
      label="findings"
      className="mt-8"
      header={
        <HeaderTitle
          title="Findings"
          path={`${paths.findings}?${stringifyObj({
            filters: [{ name: 'resolved', operation: '=', value: 'false' }]
          })}`}
        />
      }
      subheader={<span className="text-violet">Sorted by Date Reported</span>}
      requestParams={{
        sorting: { reported_at: 'desc' },
        filters: [
          {
            name: 'resolved',
            operation: '=',
            value: false
          }
        ]
      }}
      columns={columns}
      rows={{
        ...rows,
        days_unresolved: data => (
          <div className="d-flex w-100p align-items-center">
            {numberToStr(data.days_unresolved)}{' '}
            {data?.id ? (
              <div className="ms-auto">
                <TableViewIcon
                  to={`${paths.events}/${data.id}`}
                  icon={eyeSvg}
                  className={'text-primary'}
                />
              </div>
            ) : null}
          </div>
        )
      }}
    />
  );
};

export default Findings;
