import React from 'react';
import CommonSelect from 'common/components/form/inputs/Select';

const Select = ({ showLabel, ...rest }) => {
  return (
    <CommonSelect
      invisible={true}
      {...rest}
      label={showLabel ? rest.label : null}
      className={`${rest.className ? rest.className : ''} mb-0`}
      menuPlacement={rest.menuPlacement || 'auto'}
    />
  );
};
export default Select;
