import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_JOB_REMINDERS'),
  ...asyncTypes('CREATE_JOB_REMINDERS'),
  ...asyncTypes('UPDATE_JOB_REMINDER'),
  ...asyncTypes('DELETE_JOB_REMINDER'),
  ADD_JOB_REMINDER: 'ADD_JOB_REMINDER',
  CHANGE_JOB_REMINDER: 'CHANGE_JOB_REMINDER',
  REMOVE_JOB_REMINDER: 'REMOVE_JOB_REMINDER',
  CLEAR_REMINDERS: 'CLEAR_REMINDERS'
};

export default TYPES;
