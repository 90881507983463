import { useEffect } from 'react';
import { refetchAsyncOptions } from 'common/utils/lists';
import { useSelector } from 'react-redux';
import { useActions, useUpdateEffect } from 'utils/hooks';
import { selectListAsyncFilters } from 'store/async-filters/selectors';

import * as asyncFiltersActions from 'store/async-filters/actions';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

export default function useFilterSelector( // soon to be deprecated - use useFilterQuerySelector instead
  value,
  { list, listParams = {} },
  { selected, setSelected },
  identifier = 'id'
) {
  const [setAsyncFilterValues] = useActions([asyncFiltersActions.setAsyncFilterValues]);
  const values = useSelector(state => selectListAsyncFilters(state, list));

  const initAsyncFiltersValues = async (initialValues = [], refetchMissing = false) => {
    let selectedValues = [];
    let missingValues = [];
    let allValuesExist = true;
    let values = value?.length ? (_isArray(value) ? value : [value]) : [];

    if (values?.length > 0 && initialValues) {
      for (let i = 0; i < values.length; i++) {
        let fieldIdentifier = '';

        if (!isNaN(values[i])) {
          fieldIdentifier = +values[i];
        } else {
          fieldIdentifier = values[i];
        }

        if (initialValues[fieldIdentifier]) {
          selectedValues.push(initialValues[fieldIdentifier]);
        } else {
          allValuesExist = false;
          missingValues.push(
            _isObject(fieldIdentifier) ? fieldIdentifier[identifier] : fieldIdentifier
          );
        }
      }

      if (allValuesExist) {
        return setSelected(selectedValues);
      } else if (refetchMissing) {
        fetchMissingValues(missingValues);
      }
    }
  };

  const fetchMissingValues = async missingValues => {
    try {
      const res = await refetchAsyncOptions(list, {
        ...listParams,
        [identifier]: missingValues
      });

      setAsyncFilterValues({ label: list, data: res });
      initAsyncFiltersValues(
        (res?.data || res || []).reduce((acc, cur) => {
          if (cur[identifier]) acc[cur[identifier]] = cur;

          return acc;
        }, {})
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Init selected filters on initial load
    if (value?.length && !selected.length) {
      initAsyncFiltersValues(values, true);
    }
  }, []);

  useUpdateEffect(() => {
    /*
     When the value (URL filter) is different than the selected (local state), 
     re-init the selected state
    */
    if (value) {
      // check if value is matching the selected value
      let valid = true;
      for (let i = 0; i < value?.length; i++) {
        const v = value[i];

        if (Array.isArray(selected) && !selected.find(s => s?.[identifier] == v)) {
          // Using double equality to check for both integers & strings
          valid = false;
          break;
        }
      }

      if (!valid) {
        initAsyncFiltersValues(values, true);
      }
    }
  }, [value, selected]);
}
