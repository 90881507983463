import React from 'react';

import { Row, Col } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';

import moment from 'moment';

import commentsIcon from 'common/assets/svg/common/comments.svg';
import editIcon from 'common/assets/svg/actions/edit-empty.svg';

import PropTypes from 'prop-types';

const PreviousFormRemarks = ({
  wrapperClassName = '',
  remarksClassName = '',
  previousFormSubmission
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);

  return (
    <Row className={wrapperClassName}>
      {!isOnboard ? (
        <Col xs={5} className="position-relative">
          <Remarks
            className={remarksClassName}
            icon={commentsIcon}
            title="Previous Actionable Remarks for Office"
            date={moment(
              previousFormSubmission?.review_remarks_actionable?.review_remarks_update_at
            ).format('DD/MM/YYYY, HH:mm')}
            text={previousFormSubmission?.review_remarks_actionable?.review_remarks}
          />
        </Col>
      ) : null}
      <Col xs={!isOnboard ? 7 : 12} className="position-relative">
        <Remarks
          className={remarksClassName}
          icon={editIcon}
          title="Previous Actionable Remarks for Vessel"
          date={moment(
            previousFormSubmission?.for_correction_remarks_actionable
              ?.for_correction_remarks_updated_at
          ).format('DD/MM/YYYY, HH:mm')}
          text={previousFormSubmission?.for_correction_remarks_actionable?.for_correction_remarks}
        />
      </Col>
    </Row>
  );
};

PreviousFormRemarks.propTypes = {
  wrapperClassName: PropTypes.string,
  remarksClassName: PropTypes.string,
  previousFormSubmission: PropTypes.object
};

const Remarks = ({ className = '', icon, title, date, text }) => {
  return (
    <div className={`h-100 border-radius-7 cpx-12 cpy-12 text-violet fs-12 ${className}`}>
      <div className="d-flex align-items-center justify-content-between cmb-12">
        <div className="d-flex align-items-center">
          <SvgRender className="me-1" src={icon} style={{ width: 16, height: 16 }} />
          <div>{title}</div>
        </div>
        <div>{date}</div>
      </div>
      <div>{text}</div>
    </div>
  );
};

Remarks.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default PreviousFormRemarks;
