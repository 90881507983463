import paths from 'routing/routes/_paths';

import Login from 'views/auth/login';
import Logout from 'views/auth/Logout';

export default [
  {
    type: 'no_auth',
    exact: true,
    path: paths.login,
    component: Login,
    documentTitle: 'Login'
  },
  {
    path: paths.logout,
    component: Logout,
    type: 'private',
    exact: true,
    documentTitle: 'Logout'
  }
];
