import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import * as warningActions from 'store/dashboard/warnings/actions';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

import watchkeeping from 'common/assets/svg/common/clock-line.svg';
import calendar from 'common/assets/svg/common/calendar.svg';
import { isAuthorized } from 'utils/permissions/authorize';

import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';

const NavTabs = () => {
  const [shouldShowScheduleWarning, setShouldShowScheduleWarning] = useState(false);
  const account = useSelector(state => state.account);
  const [getCrewScheduleWarnings] = useActions([warningActions.getCrewScheduleWarnings]);

  const fetchWarnings = useCallback(async () => {
    try {
      const res = await getCrewScheduleWarnings();
      const hasScheduleWarning = res.find(w => w.label === 'crew-list-updated');

      setShouldShowScheduleWarning(!!hasScheduleWarning);
    } catch (e) {}
  }, []);

  useEffect(() => {
    fetchWarnings();
  }, [fetchWarnings]);

  const options = [
    {
      to: paths.shifts,
      isActive: (_, location) => location.pathname.startsWith(paths.shifts),
      icon: watchkeeping,
      permissions: [permissions.onboard.crew.watchkeeping.shifts.view],
      warning: null
    },
    {
      to: paths.working_schedule,
      isActive: (_, location) => location.pathname.startsWith(paths.working_schedule),
      icon: calendar,
      permissions: [permissions.onboard.crew.watchkeeping.working_schedule.view],
      warning: shouldShowScheduleWarning ? (
        <div className="d-flex flex-column align-items-start">
          <span className="text-nowrap">
            Please <strong>Publish</strong> new Working Schedule.
          </span>
          <span>Crew List has been updated.</span>
        </div>
      ) : null
    }
  ];

  const optionsAfterPermissions = options.filter(el => isAuthorized(account, el.permissions, 'or'));

  return <NavigationGroupTabs tabs={optionsAfterPermissions} />;
};

export default NavTabs;
