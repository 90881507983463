import { deleteRequest } from 'utils/api';
import { JobBase } from '@/common/types/jobs.ts';

type DeleteJobParams = {
  id: number;
};

export const deleteJob = async (params: DeleteJobParams) => {
  const { id } = params;

  const response = await deleteRequest<JobBase>(`/jobs/${id}`);

  return response.data;
};
