import TYPES from './types';
import moment from 'moment';

const INITIAL_STATE = {
  messages: [],
  unread_messages: 0,
  isFetching: false,
  global: {
    // Unread messages from all over the App
    unread_messages: [],
    total: 0,
    isFetching: false
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_MESSAGES:
      return {
        ...state,
        messages: payload,
        isFetching: false
      };
    case TYPES.SET_FETCHING:
      return {
        ...state,
        isFetching: payload
      };
    case TYPES.POST_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, payload]
      };
    case TYPES.SET_UNREAD_MESSAGES:
      return {
        ...state,
        unread_messages: payload.count
      };
    case TYPES.SET_MARK_AS_READ:
      return {
        ...state,
        unread_messages: 0
      };

    case TYPES.GET_GLOBAL_UNREAD_MESSAGES.START:
    case TYPES.GET_GLOBAL_UNREAD_MESSAGES.SUCCESS:
    case TYPES.GET_GLOBAL_UNREAD_MESSAGES.ERROR:
      return {
        ...state,
        global: {
          unread_messages: (payload.data?.messages || []).map(m => ({
            ...m,
            show_full_date: moment().utc().diff(m.last_received_at, 'hours') > 24
          })),
          total: payload.data?.total || 0,
          isFetching: payload.isFetching
        }
      };

    default:
      return state;
  }
};

export default reducer;
