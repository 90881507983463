import React, { useEffect, useState } from 'react';

import PageTitle from 'common/components/general/PageTitle';
import PageLoader from 'common/components/general/PageLoader';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import NavTabs from 'components/watchkeeping/NavTabs';

import * as workingScheduleActions from 'store/working-schedule/actions';
import { useActions } from 'utils/hooks';

import { useDrawer } from 'common/components/drawer';

import {
  selectCrewMembers,
  selectIsLoading,
  selectOldSchedule,
  selectIsDraft
} from 'store/working-schedule/selectors';

import { useSelector } from 'react-redux';

import WorkingScheduleTable from 'crew/components/shifts/working-schedule-table';
import WorkingScheduleHistoryDropdown from './HistoryDropdown';
import Drawer from './drawer';
import SvgRender from 'common/components/general/SvgRender';
import WorkingScheduleHeader from './PageHeader';
import { isAuthorized } from 'utils/permissions/authorize';

import draft from 'common/assets/svg/common/draft.svg';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';

const WorkingSchedule = () => {
  const [active, setActive] = useState();
  const [deleteModalId, setDeleteModalId] = useState();
  const [getLatestSchedule, removeCrewMemberFromWorkingSchedule] = useActions([
    workingScheduleActions.getLatestSchedule,
    workingScheduleActions.removeCrewMemberFromWorkingSchedule
  ]);

  const account = useSelector(selectAccount);
  const oldSelectedSchedule = useSelector(selectOldSchedule);
  const crewMembers = useSelector(selectCrewMembers);
  const isLoading = useSelector(selectIsLoading);
  const isDraft = useSelector(selectIsDraft);

  const drawer = useDrawer();

  useEffect(() => {
    getLatestSchedule();
  }, []);

  const onEdit = crew => {
    setActive(crew);
    drawer.open();
  };

  const onDelete = async () => {
    try {
      await removeCrewMemberFromWorkingSchedule({ id: deleteModalId });
      await getLatestSchedule();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="working-schedule">
      <div className="d-flex">
        <PageTitle
          label="watchkeeping"
          render={
            <div className="d-flex align-items-center">
              <NavTabs />
              <div className="d-flex align-items-center ms-3 ps-3 border-start">
                Watchkeeping
                <strong className="ms-1">/ Working Schedule</strong>
                {isDraft ? (
                  <div className="working-schedule__header--draft">
                    <SvgRender src={draft} style={{ width: 16, height: 16 }} className="me-1" />{' '}
                    This is a draft mode. You have to publish it to save it to the system.
                  </div>
                ) : null}
              </div>
            </div>
          }
        />
        <WorkingScheduleHeader />
      </div>
      <WorkingScheduleHistoryDropdown />
      <PageLoader isLoading={isLoading} />
      <div className={`base-transition ${isLoading ? 'opacity-0' : ''}`}>
        <WorkingScheduleTable
          crewMembers={crewMembers}
          isViewOnly={
            !isAuthorized(account, [permissions.onboard.crew.watchkeeping.working_schedule.edit])
          }
          onEdit={crewMember => onEdit(crewMember)}
          onDelete={crewMember => setDeleteModalId(crewMember.seaman.id)}
          oldSelectedSchedule={oldSelectedSchedule}
        />

        <Drawer drawer={drawer} active={active} />

        <DangerousActionModal
          transparent
          action={'Delete'}
          actionHoverColor={'delete'}
          onAccept={onDelete}
          closeModal={() => setDeleteModalId(null)}
          isOpen={deleteModalId}
          actionText={'CONTINUE'}
          header={'Remove'}
          body={`Are you sure you want to remove this crew member from the current schedule?`}
        />
      </div>
    </div>
  );
};

export default WorkingSchedule;
