import { sections } from '@/captain-reports/templates/config/_initialState';

import arrival from '@/captain-reports/assets/svg/arrival.svg';
import ballast from '@/captain-reports/assets/svg/ballast.svg';
import delivery from '@/captain-reports/assets/svg/delivery.svg';
import redelivery from '@/captain-reports/assets/svg/redelivery.svg';
import port_noon from '@/captain-reports/assets/svg/port-noon.svg';
import noon from '@/captain-reports/assets/svg/noon.svg';
import departure from '@/captain-reports/assets/svg/departure.svg';
import stoppage from '@/captain-reports/assets/svg/stoppage.svg';
import port_statement from '@/captain-reports/assets/svg/port-statement.svg';
import instructed_speed from '@/captain-reports/assets/svg/instructed-speed.svg';
import system_running_hours from '@/captain-reports/assets/svg/running-hours.svg';
import port_document_overview from '@/captain-reports/assets/svg/port-documents-overview.svg';
import passage_plan from '@/captain-reports/assets/svg/passage-plan.svg';

import paths from 'routing/routes/_paths';

export const leftSide = sections.filter(s => s.side === 'left');

export const rightSide = sections.filter(s => s.side === 'right');

export const constructUrlPath = (reportType, isPmsRunningHours) => {
  switch (reportType) {
    case 'instructed_speed':
      return `${paths.captain_reports}/instructed-speed`;
    case 'system_running_hours':
      return !isPmsRunningHours
        ? `${paths.captain_reports}/system-running-hours`
        : `${paths.pms_running_hours}`;
    case 'port_document_overview':
      return `${paths.captain_reports}/port-document-overview`;
    case 'passage_plan':
      return `${paths.captain_reports}/passage-plan`;
    default:
      return `${paths.captain_reports}`;
  }
};

export const captainReportIcon = {
  arrival,
  ballast,
  delivery,
  redelivery,
  noon,
  departure,
  port_noon,
  stoppage,
  port_statement,
  instructed_speed,
  system_running_hours,
  port_document_overview,
  passage_plan
};
