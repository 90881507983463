import React, { useState, useEffect } from 'react';

import NumberInput from 'common/components/form/inputs/NumberInput';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';

import useValue from 'captain-reports/utils/useValue';
import useError from 'captain-reports/utils/useError';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';
import { getCoordValue, getIntegerPart, getMinute } from 'common/utils/coordinates';
import * as reportActions from 'captain-reports/store/actions';
import { strToNumber, padLeadingZeros } from 'common/utils/numbers';

const latDirectionOptions = [
  { name: 'North', id: 1 },
  { name: 'South', id: 2 }
];

const lonDirectionOptions = [
  { name: 'East', id: 1 },
  { name: 'West', id: 2 }
];

export const CoordinateDirection = ({ field, isLocked, setDirection, direction, className }) => {
  const isPositionLatitude =
    field.key === 'lat' ||
    field.key === 'position_latitude' ||
    field.key === 'stoppage_end_latitude';

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{props.data.name.charAt(0)}</components.SingleValue>
  );

  return (
    <Select
      placeholder=""
      value={direction}
      options={isPositionLatitude ? latDirectionOptions : lonDirectionOptions}
      getOptionLabel={item => item.name}
      getOptionValue={item => item.id}
      components={{ SingleValue }}
      extraPortalStyle={{ width: 92 }}
      onChange={setDirection}
      isDisabled={isLocked}
      className={className || ''}
    />
  );
};

export const CoordinateLatLong = ({
  fieldKey,
  direction,
  setDirection,
  isLocked,
  value,
  error,
  onChange,
  className = ''
}) => {
  const [initValueChange, setInitValueChange] = useState(true); // this prevents the deep-compare trigger without the value changing
  const [degrees, setDegrees] = useState('');
  const [minutes, setMinutes] = useState('');
  const [locked, setLocked] = useState(false);
  const isPositionLatitude =
    fieldKey === 'lat' || fieldKey === 'position_latitude' || fieldKey === 'stoppage_end_latitude';
  const isPositionLongitude =
    fieldKey === 'lon' ||
    fieldKey === 'position_longitude' ||
    fieldKey === 'stoppage_end_longitude';

  const isAllowed = ({ floatValue }) => {
    if (isPositionLatitude) {
      return floatValue ? floatValue <= 90 && floatValue >= 0 : true;
    } else if (isPositionLongitude) {
      return floatValue ? floatValue <= 180 && floatValue >= 0 : true;
    }
    return true;
  };

  const onChangeDegrees = e => {
    setDegrees(e.target.value);
  };

  const onChangeMinutes = e => {
    setMinutes(e.target.value);
  };

  const updateReduxValue = (deg, min) => {
    let updatedValue = getCoordValue(strToNumber(deg), strToNumber(min));

    if (updatedValue < 0) {
      updatedValue = 0;
    } else if (isPositionLatitude && updatedValue > 90) {
      updatedValue = 90;
    } else if (!isPositionLatitude && updatedValue > 180) {
      updatedValue = 180;
    }

    if (direction === 2) {
      // If it's id: 2 it has to be negative
      updatedValue = updatedValue * -1;
    }

    if (updatedValue !== value) {
      onChange({
        key: fieldKey,
        value: updatedValue
      });
    }
  };

  useUpdateEffect(() => {
    if (initValueChange) {
      setInitValueChange(false);
    } else if (degrees && minutes) {
      updateReduxValue(degrees, minutes);
    } else if (degrees && !minutes) {
      updateReduxValue(degrees, 0);
    } else if (!degrees && !minutes && value) {
      onChange({
        key: fieldKey,
        value: null
      });
    }
  }, [degrees, minutes, direction]);

  useEffect(() => {
    if (isPositionLatitude && (degrees == '90' || degrees == '-90')) {
      setMinutes('0');
      setLocked(true);
      return;
    } else if (isPositionLongitude && (degrees == '180' || degrees == '-180')) {
      setMinutes('0');
      setLocked(true);
      return;
    }
    setLocked(false);
  }, [degrees]);

  useEffect(() => {
    if (value) {
      let deg = getIntegerPart(value);
      const min = getMinute(value, deg);

      if (value >= 0) {
        setDirection(1); // this is the id on the latDirectionOptions & lonDirectionOptions
      } else {
        // When value < 0, direction = South || West
        setDirection(2);
        deg = deg * -1;
      }

      setDegrees(padLeadingZeros(deg, isPositionLatitude ? 2 : 3));
      setMinutes(padLeadingZeros(min, 2));
    }
  }, []);

  return (
    <>
      <NumberInput
        onChange={onChangeDegrees}
        onBlur={() => setDegrees(padLeadingZeros(degrees, isPositionLatitude ? 2 : 3))}
        placeholder=""
        value={degrees}
        decimalScale={0}
        isAllowed={isAllowed}
        disabled={isLocked}
        error={error}
        allowLeadingZeros
        maxLength={isPositionLatitude ? 2 : 3}
        className={className}
      />
      <div className="coordinate-input__seperator" />
      <NumberInput
        onChange={onChangeMinutes}
        onBlur={() => setMinutes(padLeadingZeros(minutes, 2))}
        placeholder=""
        value={minutes}
        error={null}
        allowLeadingZeros
        maxLength={2}
        disabled={isLocked || locked}
        className={className}
      />
      <div className="coordinate-input__mins text-violet">'</div>
    </>
  );
};

const CoordinateInput = ({
  field,
  tooltip,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const isLocked = useSelector(isReportLocked);

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [direction, setDirection] = useState(1);

  const onChange = changed => {
    setValue({
      key: changed.key,
      value: changed.value,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  };

  return (
    <div className="coordinate-input form-group">
      <div className="form-label">
        <FieldLabel info={tooltip} label={field.label} subLabel={field.subLabel} />
      </div>

      <div className="d-flex align-items-center">
        <CoordinateLatLong
          fieldKey={field.key}
          direction={direction}
          setDirection={setDirection}
          isLocked={isLocked}
          value={value}
          error={error}
          onChange={onChange}
        />

        <CoordinateDirection
          isLocked={isLocked}
          direction={direction}
          setDirection={setDirection}
          field={field}
        />
      </div>
    </div>
  );
};

export default CoordinateInput;
