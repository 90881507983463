import { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAllExpanded } from 'common/components/pms/jobs/store/actions';
import {
  selectAllExpanded,
  selectTotalVesselSystemAssignments
} from 'common/components/pms/jobs/store/selectors';
import {
  BecomingDueFilter,
  OverdueFilter,
  PostponedFilter
} from '@/common/components/pms/jobs/components/StatusFilters';

import VesselSelector from 'common/components/selectors/VesselSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import SvgRender from 'common/components/general/SvgRender';
import ButtonFilter from 'common/components/buttons/ButtonFilter';

import classIcon from 'common/assets/svg/common/class-color.svg';
import critical from 'common/assets/svg/common/critical-color.svg';
import environmental from 'common/assets/svg/common/environmental-color.svg';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import navigational from 'common/assets/svg/common/navigational-color.svg';
import RunningHoursReportTooltip from './RunningHoursReportTooltip';

const PerSystemTopFilters = ({ hasSelectedVessel, table }) => {
  const { topFilters, setTopFilters, label } = table;
  const dispatch = useDispatch();
  const selectedVessel = topFilters.find(f => f?.name === 'vessel_id');
  const selectedOverdue = topFilters.find(f => f?.name === 'timing')?.value?.includes('is_overdue');
  const selectedBecomingDue = topFilters
    .find(f => f?.name === 'timing')
    ?.value?.includes('becoming_due');
  const selectedPostponed = topFilters
    .find(f => f?.name === 'timing')
    ?.value?.includes('is_postponed');
  const totalVesselAssignments = useSelector(selectTotalVesselSystemAssignments);

  const allExpanded = useSelector(selectAllExpanded);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const disabledFilter = isOnBoard ? false : !selectedVessel?.value?.length;

  const attibutesFilters = [
    { label: 'Class', value: 'is_class', icon: classIcon },
    { label: 'Critical', value: 'is_critical', icon: critical },
    { label: 'Navigational', value: 'is_navigational', icon: navigational },
    { label: 'Environmental', value: 'is_environmental_critical', icon: environmental }
  ];

  const updateTopFilters = useCallback(
    (key, value, operation) => {
      setTopFilters(
        topFilters.map(f =>
          f?.name === key ? { ...f, value, operation: operation || f.operation } : f
        )
      );
    },
    [setTopFilters, topFilters]
  );

  const updateTimingFilters = key => {
    const timingValue = topFilters.find(f => f?.name === 'timing')?.value;
    const newTimingValue =
      timingValue && timingValue.includes(key)
        ? timingValue.filter(t => t !== key)
        : [...(timingValue || []), key];

    updateTopFilters('timing', newTimingValue);
  };

  return (
    <Row className="d-flex align-items-center cmb-4" noGutters>
      {!isOnBoard ? (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{
                value: selectedVessel.value?.[0] ?? null
              }}
              isMulti={false}
              isClearable
              autoFocus={false}
              onChange={({ value }) => updateTopFilters('vessel_id', value?.id ? [value.id] : null)}
              placeholder="Select vessel"
            />
          </TableTopFilter>
        </Col>
      ) : null}

      <Col xs="auto" className="me-1">
        <BecomingDueFilter
          disabledFilter={disabledFilter}
          selectedBecomingDue={selectedBecomingDue}
          updateTopFilters={updateTimingFilters}
        />
      </Col>
      <Col xs="auto" className="me-1">
        <OverdueFilter
          disabledFilter={disabledFilter}
          selectedOverdue={selectedOverdue}
          updateTopFilters={updateTimingFilters}
        />
      </Col>
      <Col xs="auto" className="me-1">
        <PostponedFilter
          disabledFilter={disabledFilter}
          selectedPostponed={selectedPostponed}
          updateTopFilters={updateTimingFilters}
        />
      </Col>

      <Col className="d-flex align-items-center">
        <div className="border-start ps-1 ms-2 height-24"></div>

        {attibutesFilters.map(atr => {
          const selectedFilter = topFilters.find(f => f?.name === atr.value)?.value;

          return (
            <ButtonFilter
              key={atr.value}
              onClick={() => updateTopFilters(atr.value, selectedFilter ? null : 'true')}
              className="me-2"
              isActive={selectedFilter}
              innerClassName="bg-white text-purple d-inline-flex align-items-center px-1"
              label={atr.label}
              disabled={disabledFilter}
            >
              <SvgRender src={atr.icon} style={{ width: 14, height: 14 }} />
            </ButtonFilter>
          );
        })}

        {!isOnBoard && (
          <div className="ps-3 ms-1 border-start d-flex align-items-center h-24 d-flex align-items-center">
            <RunningHoursReportTooltip vesselId={selectedVessel.value?.[0]} />
          </div>
        )}
      </Col>
      {label && (
        <Col className="ms-auto app-table">
          <div className="app-table--toptotal d-flex align-items-center justify-content-end">
            <div className="app-table d-flex align-items-center">
              <div className="app-table--toptotal ">
                Total Systems & Subsystems: {totalVesselAssignments || 0}
              </div>
              <div className="small-separator bg-violet mx-1"></div>
              <div
                className={`text-primary cursor-pointer ${hasSelectedVessel ? '' : 'disabled'}`}
                onClick={hasSelectedVessel ? () => dispatch(setAllExpanded(!allExpanded)) : null}
              >
                {allExpanded ? 'Shrink All' : 'Expand All'}
                <SvgRender
                  className="text-violet ms-1"
                  src={arrow}
                  style={{
                    width: 11,
                    height: 11,
                    transform: allExpanded ? `` : 'rotate(180deg)'
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default PerSystemTopFilters;
