import React from 'react';

import SvgRender from 'common/components/general/SvgRender';

const EvaluationInfoLabel = ({ className, svg, children }) => {
  return (
    <div
      className={`border cpt-6 cpb-6 rounded-sm d-flex align-items-center fs-12
      px-2 ${className}`}
    >
      <SvgRender src={svg} className="me-1" style={{ height: 18, width: 18 }} />
      {children}
    </div>
  );
};

export default EvaluationInfoLabel;
