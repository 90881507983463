import { useEffect } from 'react';
import { useActions } from 'utils/hooks';

import useValue from 'captain-reports/utils/useValue';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import * as reportActions from 'captain-reports/store/actions';

const GoConsField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [setValue] = useActions([reportActions.setValue]);

  const instructed_speed_consumption_target = useValue({
    key: 'instructed_speed_consumption_target',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const isHidden = instructed_speed_consumption_target === 'weather_routing_instruction';

  useEffect(() => {
    setValue({
      key: field.key,
      value: isHidden ? null : value,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  }, [isHidden, value]);

  return isHidden ? (
    <div className={`me-3 ps-3 cms-4 text-nowrap`}>
      <ValidationWrapper
        field={{ ...field, subLabel: '' }}
        value={value}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        labelClassName="cpt-4"
        className="height-24"
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      >
        <div className="height-32 fs-12 fw-bold cpt-2">Various - WOG</div>
      </ValidationWrapper>
    </div>
  ) : (
    <div className="select-input">
      <NumberField
        field={field}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </div>
  );
};

export default GoConsField;
