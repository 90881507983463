import React from 'react';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { Row, Col } from 'reactstrap';
import { getValue } from '../../layout/utils';

const PerformanceCurves = ({ field }) => {
  const profile = useSelector(selectVesselProfile);

  const engine_performance_curves = profile.engine_performance_curves
    ? profile.engine_performance_curves
    : [];

  const data = field.data;

  return (
    <div className="mt-2 mb-3 ps-0 ps-mdlaptop-5">
      <Row className="">
        <Col className="col-10 col-mdlaptop-8 col-hd-7">
          <Row>
            {data.map(el => {
              if (el.key === 'id') {
                return null;
              }
              return (
                <Col
                  className={`text-end mt-2 fs-12 text-blue fw-bold d-flex align-items-center ${
                    el.className || ''
                  }`}
                  key={el.key}
                  xs={3}
                >
                  {el.label}
                </Col>
              );
            })}
          </Row>
          {engine_performance_curves.map((curve, i) => {
            return (
              <Row key={i.toString()}>
                {data.map(el => {
                  if (el.render) {
                    return el.render();
                  }

                  return (
                    <Col
                      className={`text-end mt-1 fs-12  d-flex align-items-center `}
                      key={el.key}
                      xs={3}
                    >
                      <span>{getValue(el, curve)}</span>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default PerformanceCurves;
