import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import { icon } from '@/ts-common/utils/files.ts';

const UnknownType = ({ file }) => {
  return (
    <div className="gallery__center--container-unknown">
      <SvgRender src={icon(file.mime_type, file.extension)} style={{ height: 64, width: 64 }} />
      <span className="fs-14 fw-normal mt-2 text-white">
        No preview available for this file type.
      </span>
    </div>
  );
};

export default UnknownType;
