import { Row, Col } from 'reactstrap';

import Table from 'views/dashboard/components/Table';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/general/Status';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import TableViewIcon from 'views/dashboard/components/TableViewIcon';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';

import _get from 'lodash/get';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';

import { selectJobStatuses } from 'store/lists/selectors';
import { useSelector } from 'react-redux';

const Jobs = () => {
  const jobStatuses = useSelector(selectJobStatuses);

  return jobStatuses?.length ? (
    <div className="forms-listing">
      <Table
        className="mt-8"
        header={<HeaderTitle title="Jobs" path={paths.regular_jobs} />}
        subheader={<span className="text-violet">Sorted by Due Date</span>}
        label="jobs"
        columns={[
          {
            key: 'title',
            width: 7
          },
          {
            key: 'status',
            header: 'STATUS',
            width: 2
          },
          {
            key: 'due_date',
            header: 'DUE DATE',
            width: 3
          }
        ]}
        rows={{
          title: data => (
            <Row className="align-items-center ">
              <Col className="max-width-24">
                <Priority size="large" value={data?.importance_id} withText={false} />
              </Col>
              <Col>
                <span>{_get(data, 'title', '-')}</span>
              </Col>
            </Row>
          ),
          status: data => (
            <Status type="jobs" value={jobStatuses.find(st => st.id === data.status_id)} />
          ),
          due_date: data => (
            <div className="d-flex align-items-center">
              {data.due_date ? (
                <DueDate
                  dueDateChanged={data.last_due_date_change}
                  showTime={data.due_date_show_time}
                  className="fs-14 fw-normal"
                  dueDate={data.due_date}
                  createdAt={data.created_at}
                  status={data.status_id}
                />
              ) : null}
              {data.id ? (
                <TableViewIcon
                  to={`${paths.regular_jobs}/${data.id}`}
                  icon={eyeSvg}
                  className={'text-primary'}
                />
              ) : null}
            </div>
          )
        }}
      />
    </div>
  ) : null;
};

export default Jobs;
