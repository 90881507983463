import React from 'react';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import ConsumptionInput from 'captain-reports/templates/components/custom-fields/ConsumptionInput';
import ConsumptionDgConsTotalInput from 'captain-reports/templates/components/custom-fields//ConsumptionDgConsTotalInput';
import TotalConsumptionField from 'captain-reports/templates/components/custom-fields/TotalConsumptionField';
import {
  autocompleteStoppageConsumptions,
  autocompleteDgConsumptionTotal
} from 'captain-reports/templates/config/_autocompleteHelpers';
import HfoLfoConsumptionRob from 'captain-reports/templates/components/custom-fields/HfoLfoConsumptionRob';
import ConsumptionRob from 'captain-reports/templates/components/custom-fields/ConsumptionRob';
import AutofillBunkerField from 'captain-reports/templates/components/custom-fields/AutofillBunkerField';
import HfoLfoDifferenceField from 'captain-reports/templates/components/custom-fields/HfoLfoDifferenceField';
import DifferenceField from 'captain-reports/templates/components/custom-fields/DifferenceField';
import LfoHfoCorrectionField from 'captain-reports/templates/components/custom-fields/LfoHfoCorrectionField';
import CorrectionField from 'captain-reports/templates/components/custom-fields/CorrectionField';
import SuppliedViscosityField from 'captain-reports/templates/components/custom-fields/SuppliedViscosityField';
import ViscosityOfFuelConsumedField from 'captain-reports/templates/components/custom-fields/ViscosityOfFuelConsumedField';
import CorrectionWarning from 'captain-reports/templates/components/custom-fields/CorrectionWarning';
import SuppliedCorrectionWarning from 'captain-reports/templates/components/custom-fields/SuppliedCorrectionWarning';
import SuppliedField from 'captain-reports/templates/components/custom-fields/SuppliedField';

import SvgRender from 'common/components/general/SvgRender';
import pollution from 'common/assets/svg/common/pollution.svg';
import BiofuelCF from '../components/custom-fields/BiofuelCF';

export const consumption_rate_per_dg_keys = [
  'dg_1_consumption_rate',
  'dg_2_consumption_rate',
  'dg_3_consumption_rate',
  'dg_4_consumption_rate',
  'dg_5_consumption_rate',
  'dg_6_consumption_rate'
];

export const consumption_per_dg_keys = [
  'dg_1_consumption_total',
  'dg_2_consumption_total',
  'dg_3_consumption_total',
  'dg_4_consumption_total',
  'dg_5_consumption_total',
  'dg_6_consumption_total'
];

const noon = () => [
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'VISCOSITY OF FUEL CONSUMED',
        key: 'viscosity',
        subLabel: 'cSt',
        width: { xs: 4 },
        labelClassname: 'text-wrap cpt-6',
        render: ViscosityOfFuelConsumedField,
        type: 'number',
        groupedBox: 'consumption_today',
        templateValidation: { required: true }
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: (
          <span>
            C<sub>F</sub>
          </span>
        ),
        key: 'biofuel_cf',
        subLabel: 't-CO2/t-fuel',
        width: { xs: 4 },
        labelClassname: 'text-wrap cpt-6',
        render: BiofuelCF,
        decimalScale: 4,
        type: 'number',
        groupedBox: 'consumption_today',
        templateValidation: { required: true }
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'SULFUR',
        subLabel: '%',
        key: 'sulfur_content',
        width: { xs: 4 },
        type: 'number',
        render: AutofillBunkerField,
        className: 'text-end-input',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'LOWER CAL VALUE',
        key: 'lower_calorific_value',
        subLabel: 'kJ/kg',
        width: { xs: 4 },
        type: 'number',
        render: AutofillBunkerField,
        className: 'text-end-input',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'DENSITY',
        subLabel: 'kg/m³',
        key: 'density',
        type: 'number',
        width: { xs: 4 },
        render: AutofillBunkerField,
        className: 'text-end-input',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'MAX STORAGE TEMP',
        key: 'max_storage_temperature',
        subLabel: '°C',
        width: { xs: 4 },
        render: AutofillBunkerField,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'MAIN ENGINE CONS',
        key: 'me_consumption_total',
        rateLabel: 'me_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        labelClassname: 'cmb-10',
        className: 'text-end-input cmb-10',
        templateValidation: { required: true },
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'DG 1 CONS',
        key: 'dg_1_consumption_total',
        generatorActiveTab: 0,
        labelClassname: 'ms-2 cmt-2',
        rateLabel: 'dg_1_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input cmt-2',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 2 CONS',
        key: 'dg_2_consumption_total',
        generatorActiveTab: 1,
        labelClassname: 'ms-2',
        rateLabel: 'dg_2_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 3 CONS',
        key: 'dg_3_consumption_total',
        generatorActiveTab: 2,
        labelClassname: 'ms-2',
        rateLabel: 'dg_3_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 4 CONS',
        key: 'dg_4_consumption_total',
        generatorActiveTab: 3,
        labelClassname: 'ms-2',
        rateLabel: 'dg_4_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 5 CONS',
        key: 'dg_5_consumption_total',
        labelClassname: 'ms-2',
        generatorActiveTab: 4,
        rateLabel: 'dg_5_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 6 CONS',
        key: 'dg_6_consumption_total',
        generatorActiveTab: 5,
        labelClassname: 'ms-2',
        rateLabel: 'dg_6_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG TOTAL CONS',
        key: 'dg_consumption_total',
        rateLabel: 'dg_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionDgConsTotalInput,
        autocompleteValues: [
          { key: 'dg_1_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_2_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_3_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_4_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_5_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_6_consumption_total', subGroup: 'consumptions' }
        ],
        autocomplete: autocompleteDgConsumptionTotal,
        className: 'text-end-input cmb-10',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'BOILER CONS',
        key: 'boiler_consumption_total',
        rateLabel: 'boiler_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        labelClassname: 'cmt-2',
        className: 'text-end-input cmt-2',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'INCINERATOR CONS',
        key: 'incinerator_consumption_total',
        rateLabel: 'incinerator_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'OTHER CONS',
        key: 'aux_consumption_total',
        rateLabel: 'aux_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: (
          <>
            <SvgRender
              src={pollution}
              style={{ width: 18, height: 18 }}
              className="text-red cme-4"
            />
            <span className="cpe-4">BOILER CONS</span>
          </>
        ),
        labelClassname: 'd-flex align-items-center',
        key: 'cii_reduction_boiler_for_cargo_cons_total',
        rateLabel: 'cii_reduction_boiler_for_cargo_cons_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today',
        subLabel: 'Cargo',
        tooltipClassName: 'min-width-548 text-start'
      },
      {
        label: (
          <>
            <SvgRender
              src={pollution}
              style={{ width: 18, height: 18 }}
              className="text-red cme-4"
            />
            <span className="cpe-4">OTHER CONS</span>
          </>
        ),
        key: 'cii_reduction_other_for_cargo_cons_total',
        rateLabel: 'cii_reduction_other_for_cargo_cons_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today',
        subLabel: 'Cargo'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'DAILY TOTAL CONS',
        key: 'total_consumption',
        width: { xs: 4 },
        render: TotalConsumptionField,
        templateValidation: { required: true },
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'LEG/PORT TOTAL CONSUMPTION',
        key: 'leg_consumption_total',
        rateLabel: 'leg_consumption_rate',
        width: { xs: 4 },
        render: () => null,
        templateValidation: { required: true },
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: '24hrs ADJUSTMENT',
        key: 'total_consumption_rate',
        width: { xs: 4 },
        render: () => null,
        templateValidation: { required: true },
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'VLSFO≤80 cSt ROB',
        key: 'rob_lfo',
        subLabel: 'mt',
        width: { xs: 4 },
        labelClassname: 'ms-auto pt-2 pe-5 cme-4 text-violet',
        type: 'number',
        render: HfoLfoConsumptionRob,
        className: 'text-end-input height-32',
        groupedBox: 'rob'
      },
      {
        label: 'VLSFO>80 ROB',
        key: 'rob_hfo',
        labelClassname: 'ms-auto pt-2 pe-5 cme-4 text-violet',
        subLabel: 'mt',
        width: { xs: 4 },
        type: 'number',
        render: HfoLfoConsumptionRob,
        className: 'text-end-input height-32',
        groupedBox: 'rob'
      },
      {
        label: 'ROB',
        key: 'rob',
        subLabel: 'mt',
        width: { xs: 4 },
        type: 'number',
        render: ConsumptionRob,
        labelClassname: 'pt-1',
        className: 'text-end-input',
        groupedBox: 'rob'
      }
    ]
  }
];

const departure = () => [
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'VISCOSITY OF FUEL CONSUMED',
        key: 'viscosity',
        subLabel: 'cSt',
        width: { xs: 4 },
        labelClassname: 'text-wrap cpt-6',
        render: ViscosityOfFuelConsumedField,
        type: 'number',
        groupedBox: 'consumption_today',
        templateValidation: { required: true }
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: (
          <span>
            C<sub>F</sub>
          </span>
        ),
        key: 'biofuel_cf',
        subLabel: 't-CO2/t-fuel',
        width: { xs: 4 },
        labelClassname: 'text-wrap cpt-6',
        render: BiofuelCF,
        decimalScale: 4,
        type: 'number',
        groupedBox: 'consumption_today',
        templateValidation: { required: true }
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'SULFUR',
        subLabel: '%',
        key: 'sulfur_content',
        type: 'number',
        width: { xs: 4 },
        render: AutofillBunkerField,
        className: 'text-end-input',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'LOWER CAL VALUE',
        key: 'lower_calorific_value',
        type: 'number',
        subLabel: 'kJ/kg',
        width: { xs: 4 },
        render: AutofillBunkerField,
        className: 'text-end-input',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'DENSITY',
        subLabel: 'kg/m³',
        key: 'density',
        type: 'number',
        width: { xs: 4 },
        render: AutofillBunkerField,
        className: 'text-end-input',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'MAX STORAGE TEMP',
        key: 'max_storage_temperature',
        subLabel: '°C',
        width: { xs: 4 },
        render: AutofillBunkerField,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'MAIN ENGINE CONS',
        key: 'me_consumption_total',
        rateLabel: 'me_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        labelClassname: 'cmb-10',
        className: 'text-end-input cmb-10',
        templateValidation: { required: true },
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'DG 1 CONS',
        key: 'dg_1_consumption_total',
        labelClassname: 'ms-2 cmt-2',
        generatorActiveTab: 0,
        rateLabel: 'dg_1_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input cmt-2',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 2 CONS',
        key: 'dg_2_consumption_total',
        generatorActiveTab: 1,
        labelClassname: 'ms-2',
        rateLabel: 'dg_2_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 3 CONS',
        key: 'dg_3_consumption_total',
        generatorActiveTab: 2,
        labelClassname: 'ms-2',
        rateLabel: 'dg_3_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },

      {
        label: 'DG 4 CONS',
        key: 'dg_4_consumption_total',
        labelClassname: 'ms-2',
        generatorActiveTab: 3,
        rateLabel: 'dg_4_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: 'DG 5 CONS',
        key: 'dg_5_consumption_total',
        labelClassname: 'ms-2',
        generatorActiveTab: 4,
        rateLabel: 'dg_5_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },

      {
        label: 'DG 6 CONS',
        key: 'dg_6_consumption_total',
        labelClassname: 'ms-2',
        generatorActiveTab: 5,
        rateLabel: 'dg_6_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },

      {
        label: 'DG TOTAL CONS',
        key: 'dg_consumption_total',
        rateLabel: 'dg_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionDgConsTotalInput,
        autocompleteValues: [
          { key: 'dg_1_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_2_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_3_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_4_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_5_consumption_total', subGroup: 'consumptions' },
          { key: 'dg_6_consumption_total', subGroup: 'consumptions' }
        ],
        autocomplete: autocompleteDgConsumptionTotal,
        className: 'text-end-input cmb-10',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'BOILER CONS',
        key: 'boiler_consumption_total',
        rateLabel: 'boiler_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        labelClassname: 'cmt-2',
        className: 'text-end-input cmt-2',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'INCINERATOR CONS',
        key: 'incinerator_consumption_total',
        rateLabel: 'incinerator_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'OTHER CONS',
        key: 'aux_consumption_total',
        rateLabel: 'aux_consumption_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today'
      },
      {
        label: (
          <>
            <SvgRender
              src={pollution}
              style={{ width: 18, height: 18 }}
              className="text-red cme-4"
            />
            <span className="cpe-4">BOILER CONS</span>
          </>
        ),
        key: 'cii_reduction_boiler_for_cargo_cons_total',
        rateLabel: 'cii_reduction_boiler_for_cargo_cons_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today',
        subLabel: 'Cargo',
        tooltipClassName: 'min-width-548 text-start'
      },
      {
        label: (
          <>
            <SvgRender
              src={pollution}
              style={{ width: 18, height: 18 }}
              className="text-red cme-4"
            />
            <span className="cpe-4">OTHER CONS</span>
          </>
        ),
        key: 'cii_reduction_other_for_cargo_cons_total',
        rateLabel: 'cii_reduction_other_for_cargo_cons_rate',
        width: { xs: 4 },
        render: ConsumptionInput,
        className: 'text-end-input',
        type: 'number',
        groupedBox: 'consumption_today',
        subLabel: 'Cargo'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'DAILY TOTAL CONS',
        key: 'total_consumption',
        width: { xs: 4 },
        render: TotalConsumptionField,
        templateValidation: { required: true },
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'LEG/PORT TOTAL CONSUMPTION',
        key: 'leg_consumption_total',
        rateLabel: 'leg_consumption_rate',
        width: { xs: 4 },
        render: () => null,
        templateValidation: { required: true },
        type: 'number',
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: '24hrs ADJUSTMENT',
        key: 'total_consumption_rate',
        width: { xs: 4 },
        render: () => null,
        templateValidation: { required: true },
        groupedBox: 'consumption_today'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'SUPPLIED VISCOSITY',
        key: 'supplied_viscosity',
        subLabel: 'cSt',
        width: { xs: 4 },
        render: SuppliedViscosityField,
        templateValidation: { required: true },
        className: 'text-end-input',
        groupedBox: 'supplied'
      },
      {
        label: 'Port Statement Bunkering',
        key: 'supplied_viscosity',
        isWarning: true,
        width: { xs: 4 },
        type: 'number',
        render: SuppliedCorrectionWarning,
        className: 'text-end-input mt-1',
        groupedBox: 'supplied'
      },
      {
        label: 'SUPPLIED',
        key: 'supplied',
        subLabel: 'mt',
        width: { xs: 4 },
        render: SuppliedField,
        templateValidation: { required: true },
        className: 'text-end-input',
        groupedBox: 'supplied'
      },
      {
        label: 'Port Statement Bunkering',
        key: 'supplied',
        isWarning: true,
        width: { xs: 4 },
        type: 'number',
        render: SuppliedCorrectionWarning,
        className: 'text-end-input mt-1',
        groupedBox: 'supplied'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'VLSFO≤80 cSt BUNKER SURVEY DIFF.',
        key: 'bs_difference_lfo',
        labelClassname: 'ms-3 text-violet pe-2',
        subLabel: 'mt',
        width: { xs: 4 },
        render: HfoLfoDifferenceField,
        groupedBox: 'difference_corrections'
      },
      {
        label: 'VLSFO>80 cSt BUNKER SURVEY DIFF.',
        key: 'bs_difference_hfo',
        labelClassname: 'ms-3 text-violet pe-2',
        subLabel: 'mt',
        width: { xs: 4 },
        render: HfoLfoDifferenceField,
        groupedBox: 'difference_corrections'
      },
      {
        label: 'BUNKER SURVEY DIFF.',
        key: 'bs_difference',
        subLabel: 'mt',
        width: { xs: 4 },
        render: DifferenceField,
        groupedBox: 'difference_corrections'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'VLSFO≤80 cSt CORRECTION',
        key: 'correction_lfo',
        subLabel: 'mt',
        labelClassname: 'ms-2 cpt-4 cme-2 text-violet',
        width: { xs: 4 },
        type: 'number',
        render: LfoHfoCorrectionField,
        className: 'text-end-input',
        groupedBox: 'difference_corrections'
      },
      {
        label: 'Bunker Survey',
        key: 'correction_lfo',
        isWarning: true,
        labelClassname: 'ms-2 cpt-4 cme-2',
        width: { xs: 4 },
        type: 'number',
        render: CorrectionWarning,
        className: 'text-end-input',
        groupedBox: 'difference_corrections'
      },
      {
        label: 'VLSFO>80 cSt CORRECTION',
        key: 'correction_hfo',
        subLabel: 'mt',
        labelClassname: 'ms-2 cpt-4 cme-2 text-violet',
        width: { xs: 4 },
        type: 'number',
        render: LfoHfoCorrectionField,
        className: 'text-end-input',
        groupedBox: 'difference_corrections'
      },
      {
        label: 'Bunker Survey',
        key: 'correction_hfo',
        isWarning: true,
        labelClassname: 'ms-2 cpt-4 cme-2',
        width: { xs: 4 },
        type: 'number',
        render: CorrectionWarning,
        className: 'text-end-input',
        groupedBox: 'difference_corrections'
      },
      {
        label: 'CORRECTION',
        key: 'correction',
        subLabel: 'mt',
        width: { xs: 4 },
        render: CorrectionField,
        labelClassname: 'pt-1',
        className: 'text-end-input',
        groupedBox: 'difference_corrections'
      },
      {
        label: 'Bunker Survey',
        key: 'correction',
        isWarning: true,
        width: { xs: 4 },
        render: CorrectionWarning,
        labelClassname: 'pt-1',
        className: 'text-end-input',
        groupedBox: 'difference_corrections'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'VLSFO≤80 cSt ROB',
        key: 'rob_lfo',
        subLabel: 'mt',
        width: { xs: 4 },
        labelClassname: 'ms-2 cpt-12  pe-5 cme-4 text-violet',
        type: 'number',
        render: HfoLfoConsumptionRob,
        className: 'text-end-input height-32',
        groupedBox: 'rob'
      },
      {
        label: 'VLSFO>80 cSt ROB',
        key: 'rob_hfo',
        labelClassname: 'ms-2 cpt-12  pe-5 cme-4 text-violet',
        subLabel: 'mt',
        width: { xs: 4 },
        type: 'number',
        render: HfoLfoConsumptionRob,
        className: 'text-end-input height-32',
        groupedBox: 'rob'
      },
      {
        label: 'ROB',
        key: 'rob',
        subLabel: 'mt',
        width: { xs: 4 },
        type: 'number',
        render: ConsumptionRob,
        className: 'text-end-input',
        groupedBox: 'rob'
      }
    ]
  }
];

const delivery = ({ label }) => [
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: `${label} ROB`,
        key: 'rob',
        subLabel: 'mt',
        width: { xs: 4 },
        type: 'number',
        render: NumberField,
        className: 'text-end-input',
        groupedBox: 'rob'
      }
    ]
  }
];

const stoppage = () => [
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'ROB On Stoppage Start',
        key: 'rob_stoppage_start',
        subLabel: 'mt',
        width: { xs: 4 },
        render: NumberField,
        className: 'text-end-input',
        groupedBox: 'rob'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'ROB On Stoppage End',
        key: 'rob_stoppage_end',
        subLabel: 'mt',
        width: { xs: 4 },
        render: NumberField,
        className: 'text-end-input',
        groupedBox: 'rob'
      }
    ]
  },
  {
    subGroup: 'consumptions',
    fields: [
      {
        label: 'Consumption during Stoppage',
        key: 'total_consumption',
        isStoppage: true,
        subLabel: 'mt',
        width: { xs: 4 },
        disabled: true,
        autocompleteValues: [
          { key: 'rob_stoppage_start', subGroup: 'consumptions' },
          { key: 'rob_stoppage_end', subGroup: 'consumptions' }
        ],
        autocomplete: autocompleteStoppageConsumptions,
        render: NumberField,
        className: 'noborder-input text-end-input',
        groupedBox: 'rob'
      }
    ]
  }
];

const port_noon = () => {
  const groups = departure();

  return groups.filter(
    group => !group.fields.find(({ groupedBox }) => groupedBox === 'difference_corrections')
  );
};

const bunkerConsumptionConfig = {
  noon: () => noon(),
  arrival: () => noon(),
  ballast: () => [],
  delivery: () => delivery({ label: 'DELIVERY' }),
  redelivery: () => delivery({ label: 'REDELIVERY' }),
  departure: () => departure(),
  port_noon: () => port_noon(),
  stoppage: () => stoppage(),
  instructed_speed: () => [],
  passage_plan: () => []
};

export default bunkerConsumptionConfig;
