import {
  stringField,
  numberField,
  booleanField,
  dateField,
  optionField,
  hiddenField
} from 'common/utils/form/fieldTypes';

const config = {
  description: stringField({ required: true }),
  location: stringField(),
  occurred_at: dateField({ required: true }),
  medical_type: optionField({ required: true }),
  medical_category: optionField({ required: true }),
  reported_at: dateField(),
  reported_to: stringField(),
  pre_existing_condition: stringField({ initialValue: 'dont_know' }),
  hospitalized_at_from: dateField(),
  hospitalized_at_to: dateField(),
  repatriated: booleanField({ initialValue: false }),
  hospitalized: booleanField({ initialValue: false }),
  telemedico: booleanField({ initialValue: false }),
  telemedico_occurred_at: dateField(),
  telemedico_info: stringField(),
  duty_suspended_at: dateField(),
  duty_resumed_at: dateField(),
  remarks: stringField(),
  paid_amount: numberField(),
  covered_by_pni: numberField(),
  attachments: optionField({ initialValue: [] }),
  is_work_related: booleanField({ initialValue: false }),
  is_crew_claim: booleanField({ initialValue: false }),
  crew_member: optionField({ required: true }),
  expenses: {
    event_medical_expense_id: hiddenField(),
    invoices: hiddenField(),
    title: stringField(),
    values: {
      value: numberField(),
      sort_index: hiddenField(),
      is_paid: booleanField({ initialValue: false })
    }
  },
  arrived_at: dateField(),
  arrived_at_location: stringField(),
  closed_at: dateField(),
  local_pni_correspondent: optionField({ initialValue: [] }),
  company_status: optionField(),
  pni_status: optionField(),
  ocimf_system_categorization: optionField()
};

export default config;
