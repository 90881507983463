export const getCoordValue = (deg, min) => +(deg + min / 60).toFixed(8);

export const getIntegerPart = val => Math.trunc(val);

export const getMinute = (val, deg, shouldRoundDegrees) =>
  shouldRoundDegrees ? Math.round(Math.abs((val - deg) * 60)) : Math.abs((val - deg) * 60);

export const parseCoordinateValue = val =>
  val
    ? parseFloat(val).toLocaleString('en', {
        maximumFractionDigits: 9
      })
    : null;
