const languagesInfo = [
  {
    fields: [
      {
        key: 'languages',
        type: 'multiple',
        data: [
          {
            label: 'LANGUAGE',
            key: 'language',
            select_key: 'language',
            type: 'select',
            validations: { required: true }
          },
          {
            label: 'LANGUAGE LEVEL',
            key: 'language_level.name',
            type: 'listSelect',
            select_key: 'language_level_id',
            validations: { required: true }
          },
          {
            label: 'MOTHER TONGUE',
            key: 'mother_tongue',
            type: 'boolean'
          }
        ]
      }
    ]
  }
];

export default languagesInfo;
