import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldsHeader } from 'captain-reports/templates/layout/_helpers';

import VisibleMultiField from 'captain-reports/templates/layout/fields/VisibleMultiField';
import Group from 'captain-reports/templates/layout/fields/Group';

import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';

const GroupFields = ({ group, subGroup, subGroupIndex, label }) =>
  group.fields.map((field, findex) => {
    const formattedField = { ...field };

    return (
      <VisibleMultiField
        key={findex}
        field={formattedField}
        label={label}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    );
  });

const MainEngineTurbochargers = ({ groups, subGroup, subGroupIndex, label }) => {
  const group1 = groups[0];
  const group2 = groups[1];
  const group3 = groups[2];
  const group4 = groups[3];

  const visibleGroup1Section = useSelector(state =>
    hasAtLeastOneVisibleField(state, group1.fields)
  );
  const visibleGroup2Section = useSelector(state =>
    hasAtLeastOneVisibleField(state, group2.fields)
  );
  const visibleGroup3Section = useSelector(state =>
    hasAtLeastOneVisibleField(state, group3.fields)
  );
  const visibleGroup4Section = useSelector(state =>
    hasAtLeastOneVisibleField(state, group4.fields)
  );

  return (
    <div className="main-engine-turbos-group">
      <Row className="align-items-end template-group main-engine-turbos-group__grid-8">
        {visibleGroup1Section ? (
          <Col xs={1} className="template-field mb-0">
            <Group className="align-items-center">
              <GroupFields
                group={group1}
                subGroup={subGroup}
                subGroupIndex={subGroupIndex}
                label={label}
              />
            </Group>
          </Col>
        ) : null}
        {visibleGroup2Section ? (
          <Col xs={2} className="template-field mb-0">
            <FieldsHeader header={group2.header} />
            <Group className="align-items-center">
              <GroupFields
                group={group2}
                subGroup={subGroup}
                subGroupIndex={subGroupIndex}
                label={label}
              />
            </Group>
          </Col>
        ) : null}
        {visibleGroup3Section ? (
          <Col xs={3} className="template-field mb-0">
            <FieldsHeader header={group3.header} />
            <Group className="align-items-center">
              <GroupFields
                group={group3}
                subGroup={subGroup}
                subGroupIndex={subGroupIndex}
                label={label}
              />
            </Group>
          </Col>
        ) : null}
      </Row>

      {visibleGroup4Section ? (
        <Row>
          <Col xs={12}>
            <Group className="align-items-center">
              <GroupFields
                group={group4}
                subGroup={subGroup}
                subGroupIndex={subGroupIndex}
                label={label}
              />
            </Group>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default MainEngineTurbochargers;
