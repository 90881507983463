import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import {
  selectOfficeUserIsAuthorized,
  selectJobField
} from 'common/components/jobs/_base/store/selectors';
import { isUserAllowedToEdit } from './helpers';
import { selectRestrictedFields } from 'store/jobs-fields/selectors';

// Wrapper component to check if the logged in user is able to edit the restricted fields in a Job

const UserCanEdit = ({ field, children, fallback, avoidCheck = false }) => {
  const typeOfLoggedInUser = useSelector(state => selectJobField(state, 'typeOfLoggedInUser'));
  const restrictedFields = useSelector(state =>
    selectRestrictedFields(state)?.find(item => item.field === field)
  );
  const canEdit = useSelector(selectOfficeUserIsAuthorized);
  const isOnboard = useSelector(state => state.isOnBoard);

  if (isOnboard) return children;

  const authorized = isUserAllowedToEdit({
    restrictedFields,
    typeOfLoggedInUser
  });

  if ((authorized && canEdit) || avoidCheck) {
    return <>{children}</>;
  } else if (fallback) {
    return fallback();
  } else {
    return null;
  }
};

UserCanEdit.propTypes = {
  field: PropTypes.string.isRequired, // job field to check
  fallback: PropTypes.func, // fallback component to render, when unauthorized
  avoidCheck: PropTypes.bool // set false to avoid any validation
};

export default UserCanEdit;
