import _get from 'lodash/get';
import {
  scheduledActionsToPortStatementActionsMappings,
  scheduledActionsToDeliveryFieldsMappings
} from 'common/utils/voyages/mappings';
import { detailedPortActionTypes } from 'common/utils/voyages/enums';
import { numberToStr } from 'common/utils/numbers';
import { compareDates, portDateUtcToLocal } from 'common/utils/dates';
import moment from 'moment';

export const renderNumericValue = (value, unit) =>
  value || value === 0 ? (
    <span>
      {numberToStr(value)}
      <span className="text-violet">&nbsp;{unit}</span>
    </span>
  ) : (
    <span className="text-primary">-</span>
  );

export const getActionScheduledValue = (action, fieldKey) => {
  return _get(action, fieldKey);
};

export const getActionRealValueDetailsKey = action => {
  return action?.port_statement_action_id
    ? 'port_statement'
    : action?.delivery_report_id
    ? 'delivery'
    : action?.redelivery_report_id
    ? 'redelivery'
    : '';
};

export const getActionRealValue = (action, fieldKey) => {
  const realValueDetailsKey = getActionRealValueDetailsKey(action);

  return _get(action, `${realValueDetailsKey}_details.${fieldKey}`);
};

export const getActionValue = (action, fieldKey) => {
  return getActionRealValue(action, fieldKey) || getActionScheduledValue(action, fieldKey);
};

const createReportActionDetails = (data, mappings) => {
  const details = {};

  Object.keys(mappings).forEach(key => {
    details[key] = _get(data, mappings[key]);
  });

  return details;
};

const getPortStatementActions = port_statement => {
  return (port_statement?.detailed_actions || []).filter(
    action => detailedPortActionTypes[action.type_label]
  );
};

const getPortStatementActionInfo = (details, type) => {
  if (!details) return null;

  if (type === 'cargo_grade') {
    const info = [];

    if (details?.cargo_grade) info.push(details.cargo_grade?.name);

    const quantityField = details.bl_quantity || details.cargo_expected_quantity;

    if (quantityField)
      info.push(
        `${numberToStr(quantityField)} ${
          (details.bl_quantity_unit
            ? details.bl_quantity_unit?.notation
            : details.cargo_expected_quantity_unit?.notation) || ''
        }`
      );

    return info.join(', ');
  }

  return null;
};

export const matchActionWithReport = (
  action,
  { port_statement, delivery_report_id, redelivery_report_id }
) => {
  const port_statement_actions = getPortStatementActions(port_statement).filter(
    p => p.type_label === action?.type_label
  );

  switch (action?.type_label) {
    case 'loading':
    case 'discharging': // matched by cargo_grade_id
      const loadingAction = port_statement_actions.find(
        p => _get(p, 'details.cargo_grade_id') === action.cargo_grade_id
      );

      if (loadingAction) {
        return {
          cargo_grade_info: getPortStatementActionInfo(loadingAction?.details, 'cargo_grade'),
          port_statement_action_id: loadingAction.id,
          match_by_key: 'cargo_grade_id'
        };
      }

      return null;

    case 'bunkering':
      const bunkeringAction = port_statement_actions.find(
        p => _get(p, 'details.bunkering_is_charterers') === action.bunkering_is_charterers
      );

      if (bunkeringAction) {
        return {
          cargo_grade_info: getPortStatementActionInfo(bunkeringAction?.details, 'cargo_grade'),
          port_statement_action_id: bunkeringAction.id,
          match_by_key: 'bunkering_is_charterers'
        };
      }

      return null;

    case 'delivery':
      if (delivery_report_id) return { delivery_report_id, match_by_key: 'delivery_report_id' };

      return null;

    case 'redelivery':
      if (redelivery_report_id)
        return { redelivery_report_id, match_by_key: 'redelivery_report_id' };

      return null;

    default:
      return null;
  }
};

export const getActionTypeDetails = action => {
  if (!action) return {};

  return { ...(action?.details || {}) };
};

export const isItineraryDeliveredAtSea = itinerary => {
  const deliveredAtAfterDeparture =
    itinerary?.departed_at &&
    itinerary?.delivered_at &&
    compareDates(itinerary?.delivered_at, itinerary?.departed_at, 'isAfter');

  return deliveredAtAfterDeparture;
};

export const isItineraryReDeliveredAtSea = itinerary => {
  const redeliveredAtAfterDeparture =
    itinerary?.departed_at &&
    itinerary?.redelivered_at &&
    compareDates(itinerary?.redelivered_at, itinerary?.departed_at, 'isAfter');

  return redeliveredAtAfterDeparture;
};

const getScheduledActionInfo = (action, type) => {
  if (type === 'cargo_grade') {
    return action?.cargo_grade
      ? `${action?.cargo_grade?.name}${
          action?.expected_quantity
            ? `, ${numberToStr(action.expected_quantity)} ${
                action.expected_quantity_unit?.notation || ''
              }`
            : ''
        }`
      : '';
  }

  return null;
};

export const getActionTypeFuels = action => {
  if (!action) return null;

  return action?.fuels || null;
};

export const getBunkeringRemarks = action => {
  if (!action) return null;

  return action?.details?.remarks || null;
};

export const getItineraryPortActions = (actions, reportsState = {}) => {
  let matchedActions = [];
  const {
    port_statement,
    delivered_to_charter_party,
    redelivered_to_charter_party,
    delivery_report_id,
    delivery_report,
    delivered_at,
    redelivery_report_id,
    redelivery_report,
    redelivered_at,
    departed_at
  } = reportsState;

  /* 1. Format all scheduled actions and add to them the match_by_keys (if any) */
  if (actions?.length) {
    actions.forEach(action => {
      let state = {
        ...getActionTypeDetails(action),
        type_label: action.type
      };

      const fuels = getActionTypeFuels(action);
      if (fuels) state.fuels = fuels;

      // Match scheduled actions with reports
      const matchedAction = matchActionWithReport(state, {
        port_statement,
        delivery_report_id,
        redelivery_report_id
      });

      if (matchedAction) {
        state = { ...state, ...matchedAction };
      } else {
        state.cargo_grade_info = getScheduledActionInfo(state, 'cargo_grade');
      }

      matchedActions.push(state);
    });
  }

  const port_statement_actions = getPortStatementActions(port_statement);

  /* 2. Add the unmatched port statement actions */
  if (port_statement_actions?.length) {
    port_statement_actions
      .filter(action => !matchedActions.find(a => a.port_statement_action_id === action.id))
      .forEach(action => {
        const state = {
          type_label: action.type_label,
          port_statement_action_id: action.id,
          cargo_grade_info: getPortStatementActionInfo(action.details, 'cargo_grade')
        };

        matchedActions.push(state);
      });
  }

  /* 3. When we have a delivery/redelivery report but no matching scheduled delivery/redelivery action,
   and this delivery/redelivery is NOT happening at sea,
   we add the report as a real action manually. 
  */
  if (
    delivery_report_id &&
    !matchedActions?.find(
      a =>
        a.type_label === 'delivery' &&
        a.delivery_report_id === delivery_report_id &&
        a.match_by_key === 'delivery_report_id'
    ) &&
    !isItineraryDeliveredAtSea({ departed_at, delivered_at })
  ) {
    const deliveryActionState = { type_label: 'delivery', delivery_report_id };

    matchedActions.push(deliveryActionState);
  }

  if (
    redelivery_report_id &&
    !matchedActions?.find(
      a =>
        a.type_label === 'redelivery' &&
        a.redelivery_report_id === redelivery_report_id &&
        a.match_by_key === 'redelivery_report_id'
    ) &&
    !isItineraryReDeliveredAtSea({ departed_at, redelivered_at })
  ) {
    const redeliveryActionState = { type_label: 'redelivery', redelivery_report_id };

    matchedActions.push(redeliveryActionState);
  }

  /* 4. Add the $REPORT_details to matched actions */
  matchedActions = matchedActions.map(action => {
    const state = { ...action };

    // Find the matched port_statement_action
    if (port_statement_actions?.length) {
      const matchedPortStatementAction = port_statement.detailed_actions.find(
        a => a.id === action.port_statement_action_id
      );

      if (matchedPortStatementAction) {
        state.port_statement_details = createReportActionDetails(
          matchedPortStatementAction,
          scheduledActionsToPortStatementActionsMappings
        );
      }
    }

    // Add the delivery report data to actions
    if (action?.delivery_report_id) {
      state.delivery_details = createReportActionDetails(
        { ...(delivery_report || {}), timestamp: delivered_at, delivered_to_charter_party },
        scheduledActionsToDeliveryFieldsMappings
      );
    }

    if (action?.redelivery_report_id) {
      state.redelivery_details = createReportActionDetails(
        { ...(redelivery_report || {}), timestamp: redelivered_at, redelivered_to_charter_party },
        scheduledActionsToDeliveryFieldsMappings
      );
    }

    return state;
  });

  return matchedActions;
};

export const getPortDeliveryAction = (action, itinerary, isDeliveredAtSea) => {
  const updatedAction = { ...action };

  updatedAction.delivery_date_local =
    action.delivery_date &&
    portDateUtcToLocal(action.delivery_date, itinerary.port, itinerary.timezone).format(
      'YYYY-MM-DD HH:mm'
    );

  updatedAction[`${action?.type_label === 'redelivery' ? 're' : ''}delivered_to_charter_party`] =
    action?.charter_party;

  const {
    delivery_details,
    redelivery_details,
    delivery_report_id,
    redelivery_report_id,
    ...rest
  } = updatedAction;

  if (isDeliveredAtSea) return rest;
  else {
    if (delivery_details) {
      updatedAction.delivery_details.delivery_date_local =
        itinerary.delivered_at &&
        portDateUtcToLocal(itinerary.delivered_at, itinerary.port, itinerary.timezone).format(
          'YYYY-MM-DD HH:mm'
        );
    } else if (redelivery_details) {
      updatedAction.redelivery_details.delivery_date_local =
        itinerary.redelivered_at &&
        portDateUtcToLocal(itinerary.redelivered_at, itinerary.port, itinerary.timezone).format(
          'YYYY-MM-DD HH:mm'
        );
    }

    return updatedAction;
  }
};

export const getBunkeringMatchedFuels = action => {
  let matchedFuels = [];
  const portStatementFuels = action?.port_statement_details?.fuels?.length
    ? action?.port_statement_details.fuels
    : null;

  if (action?.fuels?.length) {
    matchedFuels = action?.fuels.map(fuel => {
      const state = { ...fuel };

      if (portStatementFuels) {
        const match = portStatementFuels?.find(
          psFuel => psFuel.fuel_grade_id === fuel.fuel_grade_id
        ); // match with port statement fuel

        if (match) state.port_statement_details = match;
      }

      return state;
    });
  }

  if (portStatementFuels) {
    portStatementFuels.forEach(psFuel => {
      if (
        !matchedFuels.find(
          matchedFuel => matchedFuel.port_statement_details?.fuel_grade_id === psFuel.fuel_grade_id
        )
      ) {
        matchedFuels.push({ port_statement_details: psFuel });
      }
    });
  }

  return matchedFuels;
};

export const getTotalDaysDuration = (openingDate, endingDate, offHireDays) => {
  const openingEndingDuration =
    openingDate && endingDate ? moment.duration(endingDate.diff(openingDate)).asDays() : null;

  const duration =
    openingDate && endingDate ? numberToStr(openingEndingDuration - offHireDays, 2) : null;

  return duration;
};

export const getRedelivery = charterParty => _get(charterParty, 'redelivery_date');

export const getHasRDLError = (charterParty, isSubCharterer) => {
  const earliest = _get(charterParty, 'estimated_earliest_redelivery_date');
  const latest = _get(charterParty, 'estimated_latest_redelivery_date');
  const estimated = _get(charterParty, 'estimated_redelivery_date');
  const actualRDL = getRedelivery(charterParty, isSubCharterer);

  if ((earliest || latest) && actualRDL) {
    const isBeforeEarliest = earliest ? moment(actualRDL).isBefore(earliest) : false;
    const isAfterLatest = latest ? moment(actualRDL).isAfter(latest) : false;
    return isBeforeEarliest || isAfterLatest;
  }

  if (estimated && actualRDL) {
    const isEstimatedDifferentThanActual = !moment(actualRDL).isSame(estimated, 'day');
    return isEstimatedDifferentThanActual;
  }

  return false;
};

export const getItineraryTotalDays = (arrival, departure) => {
  const diff = moment(arrival).diff(departure);
  const diffDuration = moment.duration(diff);

  return diffDuration;
};

export const displayItineraryTotalDays = duration => {
  if (duration && duration._isValid) {
    const days = Math.trunc(duration.asDays());
    const hours = duration.hours();

    return `${days ? `${days}d` : ''}${hours ? ` ${days ? Math.abs(hours) : hours}h` : ''}`;
  }
};

export const getItineraryEvents = (id, events) => ({ [id]: events });

export const getItineraryPurchaseOrders = (id, purchaseOrders) => ({ [id]: purchaseOrders });
