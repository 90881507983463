import React from 'react';
import { getApiBaseUrl } from 'utils/api';
import { useSelector } from 'react-redux';

const FormInstructionsImageView = ({ formFieldParams }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  if (!formFieldParams?.file?.url) return '-';

  const url = isOnBoard ? getApiBaseUrl() + formFieldParams.file.url : formFieldParams.file.url;

  return (
    <img
      src={url}
      alt="form-instructions"
      className={`h-100p max-width-100p`}
      width={+formFieldParams?.width}
    ></img>
  );
};

export default FormInstructionsImageView;
