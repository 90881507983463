import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_SHIFTS'),
  ...asyncTypes('GET_SHIFT'),
  ...asyncTypes('UPDATE_SHIFT'),
  ...asyncTypes('GET_SCHEDULE'),
  ...asyncTypes('UPDATE_SHIFTS_PORT_SEA_STATE'),
  ...asyncTypes('INITIALIZE_SHIFTS_PORT_SEA_STATE'),
  ...asyncTypes('GET_SHIFTS_PORT_SEA_STATE_INFO'),
  ...asyncTypes('LOAD_WORKING_SCHEDULE_ON_EMPTY_DATES'),
  ...asyncTypes('VALIDATE_SHIFTS')
};

export default TYPES;
