import { get, put } from '..';
import { FirstArrivalReport } from '@/common/types/captain-reports';
import { ItineraryPort } from '@/common/types/itineraries';
import { PagingMeta } from '@/ts-common/types/table-query';

type GetItineraryScheduledPortsType = {
  charter_party_id?: number;
};

export const getItineraryScheduledPorts = async (params: GetItineraryScheduledPortsType) => {
  return (await get<ItineraryPort[]>(`/itinerary-ports/scheduled`, params)).data;
};

type GetItineraryRealPortsType = {
  charter_party_id?: number;
  paging?: { current_page: number; per_page: number };
};

export const getItineraryRealPorts = async (params: GetItineraryRealPortsType) => {
  return (await get<{ data: ItineraryPort[]; meta: PagingMeta }>(`/itinerary-ports/real`, params))
    .data;
};

type ReorderItineraryScheduledPortsType = {
  itinerary_port_ids: string[];
};

export const reorderItineraryScheduledPorts = async (
  params: ReorderItineraryScheduledPortsType
) => {
  return (await put<ItineraryPort[]>(`/itinerary-ports`, params)).data;
};

export const getItineraryFirstScheduledPort = async () => {
  return (await get<ItineraryPort>(`/itinerary-ports/first-scheduled`)).data;
};

export const getItineraryPortFirstArrivalReport = async () => {
  return (await get<FirstArrivalReport>(`/itinerary-ports/first-arrival-report`)).data;
};
