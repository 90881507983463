import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/jobs/priority_arrow.svg';

const CalculationTooltip = ({ formula, formulaVariables, fields }) => {
  return (
    <>
      <div className="text-start border-bottom cpy-12 cmb-12">
        Formula: <strong>{formula}</strong>
      </div>

      {formulaVariables?.length
        ? formulaVariables.map(fv => {
            let parentField = null;

            const field = fields.reduce((prev, curr) => {
              if (curr.id === fv.variable_form_form_field_id) {
                if (prev) return prev;
                //find flat field
                prev = curr;
                return prev;
              } else if (curr.form_field_params.fields) {
                if (prev) return prev;
                //find field inside table
                const foundField =
                  Object.keys(curr.form_field_params.fields).find(
                    fk => curr.form_field_params.fields[fk].id === fv.variable_form_form_field_id
                  ) || null;

                prev = curr.form_field_params.fields[foundField];

                return prev;
              } else {
                return prev;
              }
            }, null);

            if (!field) return null;

            if (field.parent_id) {
              parentField = fields.find(f => f.id === field.parent_id);
            }

            return (
              <div className="d-flex align-items-center text-nowrap">
                <strong className="cme-10">{fv.variable}</strong>
                <SvgRender
                  src={arrow}
                  className="me-1"
                  style={{ width: 10, height: 10, transform: 'rotate(90deg)' }}
                />
                <span>{field.field.name}</span>
                <span className="text-violet">
                  &nbsp; - Form Field #{parentField ? parentField.sort_index : field.sort_index}
                  {parentField ? `, Row ${field.sort_index} - Col ${field.column_index}` : ''}
                </span>
              </div>
            );
          })
        : null}
    </>
  );
};

export default CalculationTooltip;
