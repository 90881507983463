import React, { useState } from 'react';
import { transformProjectData } from 'common/components/jobs/_base/inputs/Project';
import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';
import arrow90 from 'common/assets/svg/jobs/arrow90.svg';
import SvgRender from 'common/components/general/SvgRender';
import _get from 'lodash/get';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const ProjectSelector = ({ filter, onChange, isMulti, listParams }) => {
  const [selectedProjects, setSelectedProjects] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'projects', listParams },
    { selected: selectedProjects, setSelected: setSelectedProjects }
  );

  const getDefaultOptions = async () => {
    const result = await getInitialAsyncValues('projects', null, null, listParams);
    return transformProjectData(result);
  };

  const loadOptions = async search => {
    const result = await getAsyncOptions(search, 'projects', listParams);

    return transformProjectData(result);
  };

  return (
    <Select
      className={`mb-0`}
      placeholder="Select value"
      autoFocus
      components={{ Option, MultiValueLabel }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.title}
      isAsync
      defaultOptions={getDefaultOptions}
      isMulti={isMulti}
      value={getFilterSelectorValues(selectedProjects, filter?.value)}
      loadOptions={search => loadOptions(search)}
      onChange={selected => {
        setSelectedProjects(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
      isClearable
      // menuIsOpen
    />
  );
};

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <div className="d-flex align-items-center">
        <span className="text-primary text-nowrap overflow-x-hidden text-truncate">
          {props.data.title}
        </span>
      </div>
    </components.MultiValueLabel>
  );
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {_get(props, 'data.parent_id') ? (
        <div className="d-flex align-items-center ">
          <SvgRender className="text-warning " src={arrow90} style={{ width: 9, height: 12 }} />
          <span className="fs-12 text-violet ms-1">{props.data.title}</span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <span>{props.data.title}</span>
        </div>
      )}
    </components.Option>
  );
};

export default ProjectSelector;
