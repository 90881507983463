import React, { useState, useCallback } from 'react';
import Select from 'common/components/form/inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const CharterPartySelector = ({
  filter, // Use filter when in a table filter
  value,
  onChange,
  isMulti,
  listParams = {},
  withCargoes = false,
  vesselId,
  icon,
  ...rest
}) => {
  const [selected, setSelected] = useState([]);

  const params = { with_cargoes: withCargoes, vessel_id: vesselId, ...listParams };
  const Tag = icon ? IconSelect : Select;

  useFilterSelector(
    filter?.value || value,
    { list: 'charter-parties', listParams: params },
    { selected, setSelected },
    'charter_party_id'
  );

  const getOptionValue = useCallback(
    option =>
      `${option.charter_party_id}${
        option.charter_party_cargo_id ? `_cargo_${option.charter_party_cargo_id}` : ''
      }`,
    []
  );

  const getValue = () => {
    if (value !== undefined) return value;

    if (isMulti) {
      if (filter.value)
        return selected.filter(option => filter.value.map(Number).includes(getOptionValue(option)));
      else return [];
    } else {
      return selected;
    }
  };

  return (
    <Tag
      placeholder="Select value"
      getOptionValue={getOptionValue}
      getOptionLabel={option => option.description || option.charterer_description}
      value={getValue()}
      isAsync
      isMulti={isMulti}
      loadOptions={search => getAsyncOptions(search, 'charter-parties', params)}
      defaultOptions={() => getInitialAsyncValues('charter-parties', null, null, params)}
      onChange={s => {
        onChange(s);
        setSelected(s);
      }}
      icon={icon}
      defaultOptionsTriggerChange={vesselId}
      {...rest}
    />
  );
};

export default CharterPartySelector;
