import { FC } from 'react';
import { Img } from 'react-image';
import Spinner from './Spinner';
import styled from '@emotion/styled';

type ImageProps = {
  src?: string;
  fallback?: string;
  width?: number;
  height?: number;
  radius?: number;
  className?: string;
};

const Image: FC<ImageProps> = ({ src, fallback, width, height, radius = 3, className = '' }) => {
  return src ? (
    <Container
      className={`d-inline-flex align-items-center justify-content-center bg-white rounded-${radius} ${className}`}
    >
      <Img
        width={width}
        height={height}
        src={fallback ? [src, fallback] : src}
        loader={<Spinner size={width} />}
      />
    </Container>
  ) : null;
};

const Container = styled.div`
  overflow: hidden;
  box-shadow: -2px 4px 14px 0 rgba(0, 0, 0, 0.05);

  > img {
    object-fit: contain;
  }
`;

export default Image;
