import TYPES from './types';

import { LOCATION_CHANGE } from 'connected-react-router';

import _groupBy from 'lodash/groupBy';
import _reduce from 'lodash/reduce';
import _orderBy from 'lodash/orderBy';

export const INITIAL_STATE = {
  data: [],
  ports: {},
  isLoading: false
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PURCHASING_REQUISITIONS_SUPPLIERS.START:
      return {
        ...state,
        isLoading: true
      };

    case TYPES.GET_PURCHASING_REQUISITIONS_SUPPLIERS.SUCCESS:
      const dataWithPorts = _orderBy(
        payload.data
          .map(d => ({
            ...d,
            port: d.delivery_port,
            port_id: d.delivery_port_id,
            date: d.delivery_date
          }))
          .filter(d => d.port_id),
        ['date']
      );

      return {
        ...state,
        isLoading: false,
        data: _groupBy(dataWithPorts, 'port_id'),
        ports: _reduce(
          dataWithPorts,
          (result, value) => {
            result[value.port_id] = value.port;
            return result;
          },
          {}
        )
      };

    case TYPES.GET_PURCHASING_REQUISITIONS_SUPPLIERS.ERROR:
      return {
        ...state,
        isLoading: false
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        data: []
      };

    default:
      return state;
  }
};

export default reducer;
