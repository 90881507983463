import { useSelector } from 'react-redux';

import _isObject from 'lodash/isObject';

import { multiTabsSections, arrayFields } from 'captain-reports/templates/config/_constants';
import {
  selectFieldValue,
  selectEngineData,
  selectEngineSubGroupData,
  selectGeneratorsData,
  selectGeneratorsSubGroupData,
  selectSubGroupData
} from 'captain-reports/store/selectors';

const useValue = ({ key, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const value = useSelector(state => {
    if (!key) return null;

    if (multiTabsSections[sectionLabel]) {
      switch (sectionLabel) {
        case 'engines':
          if (subGroup) {
            return selectEngineSubGroupData(state, sectionActiveTab, subGroup, subGroupIndex, key);
          }

          return selectEngineData(state, sectionActiveTab, key);

        case 'generators':
          if (subGroup) {
            return selectGeneratorsSubGroupData(
              state,
              sectionActiveTab,
              subGroup,
              subGroupIndex,
              key
            );
          }

          return selectGeneratorsData(state, sectionActiveTab, key);

        default:
          console.error('Missing multiple section selector: ' + sectionLabel);
          return null;
      }
    } else {
      if (arrayFields[subGroup]) {
        return selectSubGroupData(state, subGroup, subGroupIndex, key);
      } else {
        return selectFieldValue(state, key);
      }
    }
  });

  return value;
};

export default useValue;
