import React, { useState } from 'react';

import useRouter from 'use-react-router';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobDueDate,
  selectJobDueDateShowTime,
  selectJobField
} from 'common/components/jobs/_base/store/selectors';

import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import edit from 'common/assets/svg/actions/edit-empty.svg';
import clone from 'common/assets/svg/actions/clone-filled.svg';
import archive from 'common/assets/svg/common/modal_archive.svg';
import unarchive from 'common/assets/svg/common/modal_unarchive.svg';

import MoreActionsDropdown from 'common/components/general/MoreActionsDropdown';

import ClonedJobDateModal from 'common/components/jobs/_base/components/ClonedJobDateModal';

import AuthCheck from 'components/permissions/AuthCheck';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import permissions from 'common/utils/permissions/constants';
import { jobPermissions } from 'common/components/jobs/_base/permissions/helpers';
import { getJobLocationPath } from 'common/utils/urls';
import paths from 'routing/routes/_paths';

const Actions = ({ onArchive }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const jobIsArchived = useSelector(state => selectJobField(state, 'is_archived'));
  const jobType = useSelector(state => selectJobField(state, 'type'));
  const isOnboard = useSelector(state => state.isOnBoard);
  const dueDate = useSelector(selectJobDueDate);
  const dueDateShowTime = useSelector(selectJobDueDateShowTime);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { history } = useRouter();
  const [cloneJob, archiveJob, unarchiveJob] = useActions([
    jobProfileActions.cloneJob,
    jobProfileActions.archiveJob,
    jobProfileActions.unarchiveJob
  ]);

  const [isClonedJobModalOpen, setIsClonedJobModalOpen] = useState(false);
  const [jobIdToClone, setJobIdToClone] = useState(null);
  const [cloneJobLoading, setCloneJobLoading] = useState(null);

  const handleJobClone = async (date, showTime) => {
    if (!jobIdToClone) return;
    setCloneJobLoading(true);

    const res = await cloneJob({ id: jobIdToClone, due_date: date, due_date_show_time: showTime });

    if (res && res.id) {
      history.push(
        `${getJobLocationPath({ id: res.id, type: jobType })}${history?.location?.search || ''}`
      );
    }
    setCloneJobLoading(false);
  };

  const options = [
    {
      icon: edit,
      clickAction: () =>
        history.push({
          pathname: `${paths.regular_jobs}/${jobId}`
        }),
      title: 'Edit',
      permissions: [permissions.office.jobs.view, permissions.office.jobs.edit]
    },
    {
      permissions: [permissions.office.jobs.create],
      icon: clone,
      clickAction: () => {
        setJobIdToClone(jobId);
        setIsClonedJobModalOpen(true);
      },
      title: 'Clone'
    },
    {
      isEnabled: jobIsArchived,
      customAuth: {
        component: UserCanEdit,
        params: {
          field: 'is_archived'
        }
      },
      permissions: [permissions.office.jobs.edit],
      icon: unarchive,
      clickAction: () => setIsModalOpen(true),
      title: 'Unarchive'
    },
    {
      isEnabled: !jobIsArchived,
      containerComponent: {
        component: UserCanEdit,
        params: {
          field: 'is_archived'
        }
      },
      permissions: [permissions.office.jobs.edit],
      icon: archive,
      clickAction: () => setIsModalOpen(true),
      title: 'Archive'
    }
  ];

  return isOnboard ? null : (
    <>
      <AuthCheck permissions={jobId ? jobPermissions.office.edit : jobPermissions.office.create}>
        <div className="small-separator mx-1 cpy-12"></div>
        <MoreActionsDropdown options={options} className="ms-1" menuClass="mt-2" />
      </AuthCheck>

      <DangerousActionModal
        transparent
        contentClassName="modal-content-box-shadow"
        container=".job-profile"
        backdropClassName="blurred-backdrop"
        action={jobIsArchived ? 'unarchive' : 'archive'}
        actionHoverColor={'primary'}
        isOpen={isModalOpen}
        header={`${jobIsArchived ? 'Unarchive' : 'Archive'} Job`}
        body={
          <div className="text-center">
            You are about to {jobIsArchived ? 'unarchive' : 'archive'} this job. <br />
            Are you sure you want to continue?
          </div>
        }
        actionText={jobIsArchived ? 'UNARCHIVE' : 'ARCHIVE'}
        onAccept={async () => {
          if (jobIsArchived) {
            await unarchiveJob({ id: jobId });
          } else {
            await archiveJob({ id: jobId });
          }

          return onArchive ? onArchive() : null;
        }}
        closeModal={() => setIsModalOpen(null)}
      />
      <ClonedJobDateModal
        isOpen={isClonedJobModalOpen}
        dueDate={dueDate}
        dueDateShowTime={dueDateShowTime}
        onSubmit={handleJobClone}
        submitting={cloneJobLoading}
        toggle={() => setIsClonedJobModalOpen(prev => !prev)}
      />
    </>
  );
};

export default Actions;
