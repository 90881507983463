import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import * as reportActions from 'captain-reports/store/actions';
import { TemplateSectionField } from 'common/entities/captain-reports/ReportTemplateTypes';

import {
  selectConsumptionFuelGrade,
  selectCaptainReportBunkeringPerFuelGrade,
  selectConsumptionBunkerConsumptionIndex
} from 'captain-reports/store/selectors';

const SuppliedField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [setValue] = useActions([reportActions.setValue]);
  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const bunkeringData = useSelector(state =>
    selectCaptainReportBunkeringPerFuelGrade(state, fuelGrade?.id)
  );
  const bunkerConsumptionIndex = useSelector(state =>
    selectConsumptionBunkerConsumptionIndex(state, 'consumptions', subGroupIndex)
  );

  const autofillValue = _get(bunkeringData, `[${bunkerConsumptionIndex}].bdn_supplied`);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    if ((autofillValue || autofillValue === 0) && value === null && !isInitialized) {
      setIsInitialized(true);
      setValue({
        key: field.key,
        value: autofillValue || null,
        sectionLabel: sectionLabel,
        sectionActiveTab: sectionActiveTab,
        subGroup: subGroup,
        subGroupIndex: subGroupIndex
      });
    }
  }, [autofillValue, value]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default SuppliedField;

SuppliedField.propTypes = {
  ...TemplateSectionField
};
