import { FormFieldFinding } from '@/common/types/form-submissions';
import { FC } from 'react';

type FindingViewType = {
  value: FormFieldFinding;
};

const FindingView: FC<FindingViewType> = ({ value }) => {
  return (
    <div className="d-flex-column fs-12">
      {value?.name || '-'}
      <div className="d-flex text-violet">
        Category: <strong className="cms-4">{value?.category?.name || '-'}</strong>
        <span className="mx-1">|</span>
        Subcategory:
        <strong className="cms-4">{value?.subcategory?.name || '-'}</strong>
      </div>
    </div>
  );
};

export default FindingView;
