import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import check from 'common/assets/svg/actions/check.svg';

const SubmitEvaluationButton = ({ onClick, disabled, hasSubmittedEvaluation }) => {
  return (
    <Button
      disabled={disabled}
      className={`audits-event-module-evaluations__body--submit-button d-flex align-items-center justify-content-center fs-12 fw-bold cpy-4 cpx-12 ${
        hasSubmittedEvaluation ? 'bg-with-opacity shadow-none pointer-events-none' : 'bg-turquoise'
      }`}
      onClick={() => (hasSubmittedEvaluation ? {} : onClick())}
    >
      <div
        className={`d-flex align-items-center ${
          hasSubmittedEvaluation ? 'text-turquoise' : 'text-white'
        }`}
      >
        <SvgRender className="me-1" src={check} style={{ height: 12, width: 12 }} />
        {hasSubmittedEvaluation ? 'Submitted Evaluation' : 'Submit Evaluation'}
      </div>
    </Button>
  );
};

SubmitEvaluationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasSubmittedEvaluation: PropTypes.bool
};

export default SubmitEvaluationButton;
