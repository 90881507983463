import VersionStatus from 'manuals/components/VersionStatus';
import ManualVersionLink from 'manuals/components/ManualVersionLink';

import { getLastVersion } from 'manuals/_helpers';

import Information from 'common/components/general/Information';
import vessels from 'common/assets/svg/common/vessels.svg';

const Status = ({ data }) => {
  const lastVersion = getLastVersion(data.versions);

  const totalVessels = data?.vessels || [];

  return (
    <div className="d-flex align-items-center">
      <ManualVersionLink
        manualId={data.id}
        versionId={lastVersion.status === 'active' ? '' : lastVersion.id}
      >
        <VersionStatus status={lastVersion.status} title={lastVersion.title} />
      </ManualVersionLink>

      {data.vessels.length ? (
        <div className="ms-auto">
          <Information
            svgStyle={{ width: 13, height: 16 }}
            className="text-moody-blue"
            svgIcon={vessels}
            tooltipClassname="max-width-412"
            message={
              <div>
                This manual in onboard&nbsp;
                {totalVessels.map((vessel, i, totalVessels) => (
                  <span className="fw-bold">
                    {i === totalVessels.length - 1 && totalVessels.length > 1 ? ' & ' : ''}
                    {vessel.name}
                    {i + 1 === totalVessels.length ? '' : i === totalVessels.length - 2 ? '' : ', '}
                  </span>
                ))}
                &nbsp;
                {totalVessels.length > 1 ? 'vessels' : 'vessel'}.
              </div>
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default Status;
