import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import { selectCaptainReportVessel } from 'captain-reports/store/selectors';
import { numberToStr } from 'common/utils/numbers';

const SewageRobField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const vessel = useSelector(selectCaptainReportVessel);

  const value = useValue({ key: field.key });

  const sewageRobPercent =
    vessel.sewage_tank_capacity !== null ? (value / vessel.sewage_tank_capacity) * 100 : null;

  return (
    <Row>
      <Col xs={12}>
        <NumberField
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </Col>

      <Col xs={12}>
        <div className="text-violet fs-10 cmt-6">
          out of {vessel.sewage_tank_capacity ? numberToStr(vessel.sewage_tank_capacity) : '-'} mt -{' '}
          {sewageRobPercent ? sewageRobPercent.toFixed(2) : '0'}%
        </div>
      </Col>
    </Row>
  );
};

export default SewageRobField;
