import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectAccountId } from 'store/account/selectors';

import SvgRender from 'common/components/general/SvgRender';
import rankIcon from 'common/assets/svg/crew/rank.svg';

import PropTypes from 'prop-types';

const Body = ({ message, mentions }) => {
  const accountId = useSelector(selectAccountId);

  const newlineParsedMessage = useMemo(() => message.replace(/<br \/>/g, '\n'), [message]);

  const textTokens = useMemo(() => {
    const mentionPattern = /!#_.*?\^\^\^!#\$/;

    return newlineParsedMessage.split(mentionPattern);
  }, [newlineParsedMessage]);

  const mappedMentions = useMemo(() => {
    const mentionIdCapturePattern = /!#_(.*?)\^\^\^!#\$/g;
    const mentionIds = [...newlineParsedMessage.matchAll(mentionIdCapturePattern)].map(
      res => res[1]
    );

    return mentionIds.map(mentionId => mentions.find(mention => mention.id == mentionId));
  }, [mentions, newlineParsedMessage]);

  return (
    <div className="message-bubble-body mt-1">
      {textTokens.map((token, index) => {
        const mention = mappedMentions[index];
        const mentionRank = mention?.details?.rank?.name;

        return (
          <React.Fragment key={index}>
            <span className="white-space-preline">{token}</span>
            {mention ? (
              <span
                className={`${
                  accountId === mention.id ? 'self-mention-tag' : 'mention-tag'
                } d-inline-flex align-items-center fw-medium cpx-4 cpy-2 border-radius-3`}
              >
                <span>@{mention.full_name}</span>
                {!!mentionRank && (
                  <span className="d-inline-flex align-items-center cms-4 fs-10">
                    <SvgRender src={rankIcon} style={{ width: 10, height: 10 }} />
                    <span className="cms-4">{mentionRank}</span>
                  </span>
                )}
              </span>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

Body.propTypes = {
  message: PropTypes.string.isRequired,
  mentions: PropTypes.array
};

export default Body;
