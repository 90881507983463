import React from 'react';
import useRouter from 'use-react-router';

import { parseQueryParams, stringifyObj } from 'common/utils/urls';
import { getColStyle } from 'common/components/colored-table/_helpers';

import HeaderCell from 'common/components/table/main/HeaderCell';

const Head = ({ columns, state, handleTableSort, lastSticky, collapsableRows }) => {
  const { history, location } = useRouter();

  const handleSort = (col, ctrlPressed) => {
    let newSorting = {};
    const prevSorting = state.sorting;
    const sort = prevSorting[col.key] === 'desc' ? null : !prevSorting[col.key] ? 'asc' : 'desc';

    if (!ctrlPressed) {
      if (sort) {
        newSorting[col.key] = sort;
      }
    } else {
      newSorting = prevSorting;
      if (!sort) {
        delete newSorting[col.key];
      } else {
        newSorting[col.key] = sort;
      }
    }

    const { searchId, sorting, ...rest } = parseQueryParams(history.location.search);

    const updated = {
      ...rest,
      sorting: newSorting
    };

    if (handleTableSort) {
      handleTableSort(updated);
    } else {
      history.push({
        pathname: location.pathname,
        search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
      });
    }
  };

  return (
    <div className="app-table--head">
      <div className={`app-table--head-inner d-flex`}>
        {collapsableRows ? <div className="cell col-auto cell-collapse-hidden"></div> : null}

        {columns.map(
          (col, i) =>
            col.field !== 'actions' && (
              <HeaderCell
                key={col.key}
                col={col}
                isLastSticky={i === lastSticky}
                sorting={state.sorting}
                onSort={handleSort}
                style={getColStyle(col)}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Head;
