import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ type }) => {
  return (
    <div
      className="event-attachments__preview py-4 fs-12 fw-medium 
      text-violet d-flex align-items-center justify-content-center mb-2"
    >
      {`There are no ${
        type === 'office_attachments' ? 'office' : 'vessel'
      } attachments at the moment.`}
    </div>
  );
};

EmptyState.propTypes = {
  type: PropTypes.oneOf(['office_attachments', 'vessel_attachments']).isRequired
};

export default EmptyState;
