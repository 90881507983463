import { Props } from 'react-select';
import CollectionFilterSelector, { CollectionFilterProps } from './CollectionFilterSelector';

function PartyCollectionFilterSelector<Option extends { id: number; full_name?: string }>(
  props: Omit<Props<Option>, 'value'> & CollectionFilterProps<Option>
) {
  return <CollectionFilterSelector {...props} getOptionLabel={option => option.full_name ?? ''} />;
}

export default PartyCollectionFilterSelector;
