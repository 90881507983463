import { UncontrolledTooltip } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const RoundedLineIconWithTooltip = ({
  icon,
  svgStyle = { width: 12, height: 12 },
  tooltipMessage,
  tooltipClassName,
  placement,
  isActive,
  activeColor,
  className,
  onClick
}) => {
  const { avoidRender, tooltipID } = useTooltipID('rounded-line-icon-with-tooltip');

  if (avoidRender) return null;

  return (
    <div
      id={tooltipID}
      className={`rounded-line-icon-with-tooltip d-flex align-items-center ${
        isActive ? `border-${activeColor} bg-${activeColor} text-white` : ''
      } ${isActive ? 'rounded-line-icon-with-tooltip--active' : ''} ${onClick ? 'pointer' : ''} ${
        className || ''
      }`}
      onClick={onClick || null}
    >
      <SvgRender src={icon} style={svgStyle} />

      {tooltipMessage ? (
        <UncontrolledTooltip
          className={`tooltip-max-content ${tooltipClassName || ''}`}
          boundariesElement="window"
          placement={placement}
          target={tooltipID}
          fade={false}
        >
          {tooltipMessage}
        </UncontrolledTooltip>
      ) : null}
    </div>
  );
};

export default RoundedLineIconWithTooltip;
