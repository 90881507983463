import styled from '@emotion/styled';

import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import CircledButton from 'common/components/buttons/CircledButton';

import pending from 'common/assets/svg/common/pending-squares.svg';
import check from 'common/assets/svg/actions/check.svg';
import airplane from 'common/assets/svg/crew/airplane-solid.svg';
import TicketingBulletStatus from '@/common/components/tickets/TicketingBulletStatus.tsx';

const TicketOfferHandler = ({
  hasAcceptedOffers,
  hasPendingOffers,
  size = 'sm',
  onClick,
  showEmptyButton = false,
  showCheckedButtonWhenAccepted = false,
  showAddButtonWhenEmpty = false,
  disabled = false,
  className = '',
  status,
  id
}) => {
  return !hasAcceptedOffers && !hasPendingOffers && showAddButtonWhenEmpty ? (
    <CircledButton
      type="add"
      style={{ width: 16, height: 16 }}
      svgStyle={{ width: 6, height: 6 }}
      onClick={onClick}
      disabled={disabled}
      className={className}
      data-cy="add_ticket"
    />
  ) : (
    <div
      id={id}
      className={`ticket-offer-handler ticket-offer-handler--${size} ticket-offer-handler--${
        hasAcceptedOffers ? 'accepted' : hasPendingOffers ? 'pending' : 'empty'
      } ticket-offer-handler--${showCheckedButtonWhenAccepted ? 'circled' : ''} ${
        disabled ? 'disabled' : ''
      } ${onClick && !disabled ? 'cursor-pointer' : ''} ${className} position-relative`}
      onClick={onClick}
      data-cy="ticket_drawer"
    >
      {hasAcceptedOffers ? (
        showCheckedButtonWhenAccepted ? (
          <SvgRender className="text-white" src={check} />
        ) : (
          <SvgRender className="text-white" src={airplane} />
        )
      ) : hasPendingOffers ? (
        <SvgRender src={pending} />
      ) : showEmptyButton ? (
        <SvgRender src={airplane} />
      ) : null}

      {status ? (
        <BulletContainer>
          <TicketingBulletStatus
            status={status}
            enableShadow
            className="border border-white min-w-8 max-w-8 min-h-8 max-h-8"
          />
        </BulletContainer>
      ) : null}
    </div>
  );
};

TicketOfferHandler.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

const BulletContainer = styled.div`
  position: absolute;
  z-index: 4;
  width: 8px;
  height: 8px;
  bottom: 0;
  right: 8px;
`;

export default TicketOfferHandler;
