import { Input } from 'reactstrap';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import TextareaAutosize from 'react-autosize-textarea';

const Textarea = ({
  error = '',
  className = '',
  name = undefined,
  value,
  onChange,
  label = '',
  placeholder,
  rows,
  maxLength = undefined,
  readOnly = false,
  disabled,
  autoResize = false,
  invisible = false,
  dataCy = undefined,
  ...rest
}) => {
  const Tag = autoResize ? TextareaAutosize : Input;

  return (
    <FormGroupWrap
      className={`${invisible ? 'invisible-input' : ''} ${className}`}
      label={label}
      error={error}
      dataCy={dataCy}
    >
      <div className="position-relative flex-fill">
        <Tag
          type={'textarea'}
          value={value || ''}
          name={name}
          onChange={onChange}
          placeholder={placeholder ? placeholder : ''}
          rows={rows ? rows : 3}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled || false}
          className={`form-control form-field gray-scrollbar ${disabled ? 'disabled' : ''}`}
          {...rest}
        />
      </div>
    </FormGroupWrap>
  );
};

export default Textarea;
