import PropTypes from 'prop-types';

import PageLink from 'common/components/general/page-link';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import permissions from 'common/utils/permissions/constants';
import { getJobLocationPath } from 'common/utils/urls';
import { LinkedEntityContainer } from 'common/components/findings/report/tableConfig';
import { Finding } from 'common/entities/findings/FindingTypes';

import paths from 'routing/routes/_paths';

const LinkedFindingsDescription = ({ data, isEvent }) => (
  <div>
    {data?.origin_audit && !isEvent ? (
      <LinkedEntityContainer>
        <PageLink
          to={`${paths.events}/${data?.origin_audit?.event_id}`}
          permissions={[permissions.office.events.list.view, permissions.onboard.events.view]}
        >
          <TextWithTooltip>{data?.origin_audit?.event?.name}</TextWithTooltip>
        </PageLink>
      </LinkedEntityContainer>
    ) : null}
    {data?.origin_form_submission ? (
      <LinkedEntityContainer>
        <PageLink
          to={`${paths.forms}/${data?.origin_form_submission?.id}`}
          permissions={[permissions.office.forms.list.view, permissions.onboard.forms.view]}
        >
          <TextWithTooltip>{data?.origin_form_submission?.form?.name}</TextWithTooltip>
        </PageLink>
      </LinkedEntityContainer>
    ) : null}
    {data?.form_submissions?.length
      ? data?.form_submissions?.map((form, i) => (
          <LinkedEntityContainer key={i}>
            <PageLink
              to={`${paths.forms}/${form?.id}`}
              permissions={[permissions.office.forms.list.view, permissions.onboard.forms.view]}
            >
              <TextWithTooltip>{form?.form?.name}</TextWithTooltip>
            </PageLink>
          </LinkedEntityContainer>
        ))
      : null}
    {data?.jobs?.length
      ? data?.jobs?.map((job, i) => (
          <LinkedEntityContainer key={i}>
            <PageLink
              to={getJobLocationPath(job)}
              permissions={
                job?.type === 'regular'
                  ? [permissions.office.jobs.view, permissions.onboard.jobs.list.view]
                  : [permissions.office.pms.jobs.view, permissions.onboard.pms.jobs.view]
              }
            >
              <TextWithTooltip>{job?.title}</TextWithTooltip>
            </PageLink>
          </LinkedEntityContainer>
        ))
      : null}
    {data?.purchasing_requisitions?.length
      ? data?.purchasing_requisitions?.map((requisition, i) => (
          <LinkedEntityContainer key={i}>
            <PageLink
              to={`${paths.purchasing_requisitions}/${requisition?.id}`}
              permissions={[
                permissions.office.purchasing.requisitions.view,
                permissions.onboard.purchasing.requisitions.view
              ]}
            >
              <TextWithTooltip>{requisition?.description}</TextWithTooltip>
            </PageLink>
          </LinkedEntityContainer>
        ))
      : null}
  </div>
);

export default LinkedFindingsDescription;

LinkedFindingsDescription.propTypes = {
  data: Finding,
  isEvent: PropTypes.bool
};
