import PropTypes from 'prop-types';

const ReminderDateType = PropTypes.shape({
  reminder_id: PropTypes.number.isRequired,
  months_before: PropTypes.number.isRequired,
  days_before: PropTypes.number.isRequired,
  hours_before: PropTypes.number.isRequired
});

export const ReminderType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  created_by_id: PropTypes.number.isRequired,
  updated_by_id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  dates: PropTypes.arrayOf(ReminderDateType).isRequired
});
