import { mode } from '@/ts-common/components/form/inputs/date/_constants';
import { DisplayInputValueType } from '@/ts-common/types/forms';
import { displayDate } from '@/ts-common/utils/dates';

export const displayFormFieldValue = (
  value?: number | string | Date | null,
  type?: DisplayInputValueType
) => {
  switch (type) {
    case `${mode.single.date}-with-time`:
    case mode.single.date: {
      const hideTime = !type.endsWith('with-time');

      if (typeof value === 'number' || !value) return '-';

      return (
        displayDate(value, {
          time: hideTime
        }) || '-'
      );
    }
    default:
      return value && !(value instanceof Date) ? value : '-';
  }
};
