import {
  getEventLubOilAnalysis,
  getNextEventLubOilAnalysis,
  updateEventLubOilAnalysis
} from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  nextEventId: null
};

const eventLubOilAnalysisSlice = createSlice({
  name: 'eventLubOilAnalysis',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder
      .addCase(getEventLubOilAnalysis.pending, state => {
        state.isLoading = true;
        state.data = null;
        state.nextEventId = null;

        return state;
      })
      .addCase(getEventLubOilAnalysis.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;

        return state;
      })
      .addCase(updateEventLubOilAnalysis.fulfilled, (state, { payload }) => {
        state.data.result_date = payload.result_date;
        state.data.samples_delivery_date = payload.samples_delivery_date;

        return state;
      })
      .addCase(getNextEventLubOilAnalysis.fulfilled, (state, { payload }) => {
        state.nextEventId = payload?.event_id;

        return state;
      })
      .addCase(getEventLubOilAnalysis.rejected, state => {
        state.isLoading = false;
        state.data = null;
        state.nextEventId = null;

        return state;
      });
  }
});

export default eventLubOilAnalysisSlice.reducer;
