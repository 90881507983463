import React from 'react';

const CharterType = ({ type, className }) =>
  type ? (
    <div className={`charter-type charter-type-${type} ${className ? className : ''}`}>
      {type.replace('_', ' ')}
    </div>
  ) : null;

export default CharterType;
