import React from 'react';

import Input from 'common/components/form/inputs/Input';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';
import * as reportActions from 'captain-reports/store/actions';

import useValue from 'captain-reports/utils/useValue';

const TextField = ({ field, tooltip, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const isLocked = useSelector(isReportLocked);

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onChange = e => {
    setValue({
      key: field.key,
      value: e.target.value || null,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  };

  return (
    <Input
      onChange={onChange}
      label={
        <FieldLabel
          info={tooltip}
          label={field.label}
          subLabel={field.subLabel}
          className={field?.labelClassName || ''}
        />
      }
      placeholder=""
      value={value}
      error={null}
      disabled={isLocked}
    />
  );
};

export default TextField;
