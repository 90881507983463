import { Attachment } from '@/ts-common/types/files';
import { useCallback, useReducer } from 'react';
import reducer, { INITIAL_STATE } from './store/reducer';
import TYPES, { RotateDirectionType } from './store/types';
import { renewFilesMetadata } from '@/api/files/api';

const useGallery = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setGalleryFiles = useCallback((files: Attachment[]) => {
    dispatch({ type: TYPES.SET_GALLERY_FILES, payload: files });
  }, []);

  const toggleGallery = useCallback(
    async (isOpen: boolean) => {
      if (
        isOpen &&
        state.files.some((file: Attachment) => {
          const expiresDate = file.url_expires_at ? new Date(file.url_expires_at) : new Date();
          const currentDate = new Date();

          if (expiresDate.toISOString() < currentDate.toISOString()) return true;
        })
      ) {
        const files = await renewFilesMetadata(state.files);

        setGalleryFiles(files || []);
      }

      dispatch({ type: TYPES.TOGGLE_GALLERY, payload: isOpen });
    },
    [state.files, setGalleryFiles]
  );

  const setGalleryCurrentFile = useCallback((file: Attachment) => {
    dispatch({ type: TYPES.SET_SELECTED_FILE, payload: file });
  }, []);

  const setGalleryRotateDirection = useCallback((direction: RotateDirectionType) => {
    dispatch({ type: TYPES.SET_ROTATE_DIRECTION, payload: direction });
  }, []);

  return {
    ...state,
    setGalleryFiles,
    toggleGallery,
    setGalleryCurrentFile,
    setGalleryRotateDirection
  };
};

export default useGallery;
