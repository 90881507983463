import React from 'react';
import PropTypes from 'prop-types';

import saveIcon from 'common/assets/svg/actions/save.svg';
import editIcon from 'common/assets/svg/actions/edit.svg';
import close from 'common/assets/svg/actions/close.svg';
import bin from 'common/assets/svg/common/bin-solid.svg';
import send from 'common/assets/svg/actions/send.svg';

import SvgRender from 'common/components/general/SvgRender';

const getType = (type, icon) => {
  const data = { text: '', icon };

  switch (type) {
    case 'edit':
      data.text = 'Edit';
      data.icon = editIcon;
      break;
    case 'save':
      data.text = 'Save';
      data.icon = saveIcon;
      break;
    case 'send':
      data.text = 'Send';
      data.icon = send;
      break;
    case 'create':
      data.text = 'Create';
      data.icon = saveIcon;
      break;
    case 'close':
      data.text = 'Cancel';
      data.icon = close;
      break;
    case 'delete':
      data.text = 'Delete';
      data.icon = bin;
      break;

    default:
      break;
  }

  return {
    text: data.text,
    icon: data.icon ? <SvgRender src={icon || data.icon} style={{ width: 12, height: 12 }} /> : null
  };
};

const OvalButton = ({
  onClick,
  className = '',
  type = 'save',
  disabled,
  size = 'md',
  text = '',
  icon = ''
}) => {
  const typeInfo = getType(type, icon);

  return (
    <div
      onClick={onClick}
      className={`oval-button oval-button__${type} d-flex align-items-center ${
        disabled ? 'disabled' : ''
      } size-${size} ${className}`}
      data-cy={`${type}`}
    >
      {typeInfo.icon}
      <div className="cms-6">{text || typeInfo.text}</div>
    </div>
  );
};

OvalButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(['edit', 'save', 'create', 'close']),
  size: PropTypes.oneOf(['sm', 'md']),
  text: PropTypes.string,
  icon: PropTypes.string
};

export default OvalButton;
