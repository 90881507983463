import { FC } from 'react';
import styled from '@emotion/styled';

import { Col, Row } from 'reactstrap';

import { formatDate } from 'common/utils/dates';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { constructSentence } from './helpers';
import { EntityLog } from '@/common/types/logs';

export interface TimelinePointProps {
  className?: string;
  index: number;
  pointCount: number;
  entityLog: EntityLog;
}

const TimelinePoint: FC<TimelinePointProps> = ({
  className = '',
  index,
  pointCount,
  entityLog
}) => {
  return (
    <Container
      className={`position-relative bg-light-gray border-radius-7 p-1 ${className}`}
      index={index}
      pointCount={pointCount}
    >
      <Row className="fw-light fs-12 m-0">
        <Col xs={8} className="text-break text-primary p-0">
          {constructSentence(entityLog)}
        </Col>
        <Col xs={4} className="d-flex justify-content-end text-violet p-0">
          {formatDate(entityLog.created_at)}
        </Col>
      </Row>
    </Container>
  );
};

type ContainerProps = {
  index: number;
  pointCount: number;
};

const Container = styled.div<ContainerProps>`
  ::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: ${({ index, pointCount }) =>
      index < pointCount - 1 ? variables.pastelGray : 'transparent'};
    top: 20px;
    left: -15px;
  }

  ::after {
    content: '';
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 1px solid ${variables.pastelGray};
    left: -21px;
    top: 11px;
    background-color: white;
  }
`;

export default TimelinePoint;
