import { getCrewChanges } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  data: [],
  loading: false
};

const slice = createSlice({
  name: 'eventCrewChanges',
  initialState: INITIAL_STATE,
  reducers: {
    clearCrewChanges: state => {
      state.data = [];
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCrewChanges.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getCrewChanges.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        return state;
      })

      .addCase(getCrewChanges.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { clearCrewChanges } = slice.actions;

export default slice.reducer;
