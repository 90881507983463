import { useState, useEffect } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import clock from 'common/assets/svg/common/clock-line.svg';
import moment from 'moment';
import { formatTime } from 'cleave-zen';

const TimeInput = ({
  className,
  innerClassName,
  value = '00:00',
  onChange,
  disabled,
  svgIcon,
  svgStyle,
  label,
  error,
  hasMaxTime = true
}) => {
  const [showError, setShowError] = useState(false);
  const [localTime, setLocalTime] = useState(value ? value : '00:00');
  const [cleave, setCleave] = useState(null);

  useEffect(() => {
    if (hasMaxTime) {
      if (!moment(localTime, 'HH:mm').isValid()) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else {
      if (!localTime) {
        setShowError(false);
        return;
      }
      let splitTime = localTime.split(':');

      if (
        !splitTime[0] ||
        splitTime[0].length !== 2 ||
        !splitTime[1] ||
        splitTime[1].length !== 2
      ) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
  }, [localTime]);

  const onInit = cleaveRaw => {
    setCleave(cleaveRaw);
  };

  useEffect(() => {
    setLocalTime(value);
    if (cleave) {
      if (value) {
        cleave.setRawValue(value.split(':').join(''));
      } else {
        cleave.setRawValue(null);
      }
    }
  }, [value]);

  return (
    <div className={`form-time-input form-group${className ? ` ${className}` : ''}`}>
      {label ? <div className="form-label mb-2">{label}</div> : null}
      <div
        className={` d-flex align-items-center time-input${disabled ? ' pointer-events-none' : ''}`}
      >
        <SvgRender
          className={`${showError ? 'text-red' : 'text-primary'} me-1`}
          src={svgIcon || clock}
          style={svgStyle || { width: 16, height: 16 }}
        />
        <input
          className={`form-control form-field flex-fill value-input ps-1${
            innerClassName ? ` ${innerClassName}` : ''
          }`}
          value={value}
          onInit={onInit}
          placeholder={'hh:mm'}
          onChange={e => {
            const timeValue = formatTime(e.target.value, {
              timePattern: ['h', 'm'],
              delimiterLazyShow: true
            });
            setLocalTime(timeValue);
            onChange(timeValue);
          }}
        />
        {error ? <div className="form-error mt-4 ms-3">{error}</div> : null}
      </div>
    </div>
  );
};

export default TimeInput;
