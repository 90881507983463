import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import pending from 'common/assets/svg/common/pending.svg';

const PendingLabel = ({ isPending, label = 'Pending', className = '' }) =>
  isPending ? (
    <div
      className={`pending-label d-inline-flex align-items-end py-1 px-4 text-orange ${className}`}
    >
      <SvgRender src={pending} style={{ width: 14, height: 19 }} />
      <strong className="fs-12 ps-1 fw-medium">{label}</strong>
    </div>
  ) : null;

export default PendingLabel;
