import React from 'react';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const FieldLabel = ({ label, subLabel, info, className, tooltipClassName = '' }) => {
  const { tooltipID, avoidRender } = useTooltipID('field-label-reports');

  if (avoidRender) return null;

  return (
    <div className="d-flex align-items-center" style={{ pointerEvents: 'all' }}>
      <span className={`${className ? className : ''}`} id={tooltipID}>
        {label}{' '}
        {subLabel ? (
          <span className="text-initial text-violet form-sublabel">{`${subLabel}`}</span>
        ) : null}{' '}
      </span>
      {info ? (
        <Tooltip innerClassName={tooltipClassName} target={tooltipID}>
          {info}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default FieldLabel;
