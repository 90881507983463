import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import EntityLabel from 'common/components/labels/EntityLabel';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import _get from 'lodash/get';

const AssigneeChange = props => {
  const { properties } = props;

  const oldName = _get(properties, "old['assignee.full_name']", 'None');
  const newName = _get(properties, "attributes['assignee.full_name']", 'None');

  const oldColor = _get(properties, "old['assignee.first_department'][0].color", null);
  const newColor = _get(properties, "attributes['assignee.first_department'][0].color", null);

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        {oldColor && <EntityLabel type="department" color={oldColor} bullet />}

        <div data-testid="job-activity-assignee-before" className="fs-12 text-violet fw-light">
          {oldName ? oldName : 'None'}
        </div>
      </div>

      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />

      <div className="d-flex align-items-center">
        {newColor && <EntityLabel type="department" color={newColor} bullet />}
        <div data-testid="job-activity-assignee-after" className="fs-12 text-violet fw-light">
          {newName ? newName : 'None'}
        </div>
      </div>
    </div>
  );
};

export default AssigneeChange;
