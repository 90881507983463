import _get from 'lodash/get';

import ShadowBox from 'common/components/general/ShadowBox';
import Divider from './Divider';
import CreatedAt from './CreatedAt';
import Vessels from './Vessels';
import DepartmentRole from './DepartmentRole';
import DepartmentEmpty from './DepartmentEmpty';

import PropTypes from 'prop-types';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const DepartmentParties = ({ className, profile, mode }) => {
  const departmentParties = _get(profile, 'department_parties');
  const accessIsGranted = _get(profile, 'can_login');
  const company = _get(profile, 'company');
  const createdAt = _get(profile, 'created_at');
  const position = _get(profile, 'position');

  return (
    <ShadowBox className={`bg-white px-2 cpy-12 fs-10 ${className}`} flat>
      {mode === 'create' || (!company && !position && !accessIsGranted) ? (
        <strong className="fs-12">-</strong>
      ) : (
        <div>
          {company ? (
            <TextWithTooltip className="fw-bold fs-12">{company?.full_name}</TextWithTooltip>
          ) : null}
          {!accessIsGranted && position ? (
            <div className="cmt-2">
              <TextWithTooltip>{position}</TextWithTooltip>
              <Divider className="my-2" />
            </div>
          ) : null}

          {accessIsGranted && company ? <Divider className="my-2" /> : null}

          {accessIsGranted ? (
            departmentParties?.length > 0 ? (
              departmentParties.map((departmentParty, i) => (
                <>
                  <DepartmentRole
                    index={i + 1}
                    color={departmentParty.department?.color}
                    departmentName={departmentParty.department?.name}
                    roleName={departmentParty.department_role?.name}
                  />
                  {departmentParty.vessels?.length ? (
                    <Vessels
                      className={`cmt-12 ${departmentParty.vessels?.length ? '' : 'mb-4'}`}
                      vessels={departmentParty.vessels}
                      color={departmentParty.department?.color}
                    />
                  ) : null}
                  <Divider className="my-2" />
                </>
              ))
            ) : (
              <DepartmentEmpty />
            )
          ) : null}
        </div>
      )}

      <CreatedAt className={!accessIsGranted ? 'mt-2' : ''} createdAt={createdAt} />
    </ShadowBox>
  );
};

DepartmentParties.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object,
  mode: PropTypes.string.isRequired
};

export default DepartmentParties;
