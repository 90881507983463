import React from 'react';

import { Row, Col } from 'reactstrap';
import Section, { SectionHeader } from '../../components/Section';
import GroupFields from '../../components/GroupFields';
import airplaneUp from 'common/assets/svg/crew/airplane-up.svg';
import airplaneDown from 'common/assets/svg/crew/airplane-down.svg';
import FieldSubLabel from 'crew/profile/main/contracts/preview/parts/FieldSubLabel';

const Departures = ({ contract }) => {
  const {
    left_home_date,
    starting_date,
    arrived_home_date,
    requested_sign_off_date,
    sign_on_date_as_per_office,
    sign_on_port_as_per_office,
    sign_off_date_as_per_office,
    sign_off_port_as_per_office,
    sign_on_date_as_per_captain,
    sign_off_date_as_per_captain,
    sign_on_port_as_per_captain,
    sign_off_port_as_per_captain
  } = contract;

  return (
    <Section className="h-100p with-border">
      <Row className="g-0">
        <Col>
          <SectionHeader
            header={{
              icon: airplaneDown,
              label: 'Arrivals',
              info: (
                <div className="fs-12 text-start">
                  <strong className="fs-10">Regarding Sign On Date & Port</strong>
                  <div>
                    <div className="cmb-4 text-start ps-0 ms-0">
                      Crew List,wil work on all cases:
                    </div>
                    <li>Only Captain fills in the fields</li>
                    <li>Only Office fills in the fields</li>
                    <li>
                      If Captain and Office fill in the fields,
                      <br /> then Office date/port is used
                    </li>
                  </div>
                </div>
              )
            }}
          />
        </Col>
        <Col>
          <SectionHeader
            header={{
              icon: airplaneUp,
              label: 'Departures',
              info: (
                <div className="fs-12 text-start">
                  <strong className="fs-10">Regarding Sign Off Date & Port</strong>
                  <div>
                    <div className="cmb-4 text-start ps-0 ms-0">
                      {' '}
                      Crew List,wil work on all cases:
                    </div>
                    <li>Only Captain fills in the fields</li>
                    <li>Only Office fills in the fields</li>
                    <li>
                      If Captain and Office fill in the fields,
                      <br /> then Office date/port is used
                    </li>
                  </div>
                </div>
              )
            }}
          />
        </Col>
      </Row>

      <GroupFields
        left={{
          data: { left_home_date },
          field: {
            label: 'LEFT FROM HOME',
            type: 'date',
            key: 'left_home_date',
            dateFormat: 'DD MMM YYYY'
          }
        }}
        right={{
          data: { requested_sign_off_date },
          field: {
            label: 'REQUESTED SIGN OFF DATE',
            type: 'date',
            key: 'requested_sign_off_date',
            dateFormat: 'DD MMM YYYY'
          }
        }}
      />

      <GroupFields
        left={{
          data: { sign_on_date_as_per_office },
          field: {
            label: 'SIGN ON DATE',
            subLabel: <FieldSubLabel text={'As per Office'} />,
            type: 'datetime',
            localizeDatetime: false,
            key: 'sign_on_date_as_per_office',
            dateFormat: 'DD MMM YYYY'
          }
        }}
        right={{
          data: { sign_off_date_as_per_office },
          field: {
            label: 'SIGN OFF DATE',
            subLabel: <FieldSubLabel text={'As per Office'} />,
            type: 'datetime',
            localizeDatetime: false,
            key: 'sign_off_date_as_per_office',
            dateFormat: 'DD MMM YYYY'
          }
        }}
      />

      <GroupFields
        left={{
          data: { sign_on_date_as_per_captain },
          field: {
            label: 'SIGN ON DATE',
            subLabel: <FieldSubLabel text={'As per Captain'} />,
            type: 'datetime',
            localizeDatetime: false,
            key: 'sign_on_date_as_per_captain',
            dateFormat: 'DD MMM YYYY hh:mm'
          }
        }}
        right={{
          data: { sign_off_date_as_per_captain },
          field: {
            label: 'SIGN OFF DATE',
            subLabel: <FieldSubLabel text={'As per Captain'} />,
            type: 'datetime',
            localizeDatetime: false,
            key: 'sign_off_date_as_per_captain',
            dateFormat: 'DD MMM YYYY hh:mm'
          }
        }}
      />

      <GroupFields
        left={{
          data: { sign_on_port_as_per_office },
          field: {
            label: 'SIGN ON PORT',
            subLabel: <FieldSubLabel text={'As per Office'} />,
            type: 'select',
            key: 'sign_on_port_as_per_office.name'
          }
        }}
        right={{
          data: { sign_off_port_as_per_office },

          field: {
            label: 'SIGN OFF PORT',
            subLabel: <FieldSubLabel text={'As per Office'} />,
            type: 'select',
            key: 'sign_off_port_as_per_office.name'
          }
        }}
      />

      <GroupFields
        left={{
          data: { sign_on_port_as_per_captain },
          field: {
            label: 'SIGN ON PORT',
            subLabel: <FieldSubLabel text={'As per Captain'} />,
            type: 'string',
            key: 'sign_on_port_as_per_captain.name'
          }
        }}
        right={{
          data: { sign_off_port_as_per_captain },
          field: {
            label: 'SIGN OFF PORT',
            subLabel: <FieldSubLabel text={'As per Captain'} />,
            type: 'string',
            key: 'sign_off_port_as_per_captain.name'
          }
        }}
      />

      <GroupFields
        left={{
          data: { starting_date },
          field: {
            label: 'STARTING DATE',
            type: 'date',
            key: 'starting_date',
            dateFormat: 'DD MMM YYYY'
          }
        }}
        right={{
          data: { arrived_home_date },
          field: {
            label: 'ARRIVED HOME',
            type: 'date',
            key: 'arrived_home_date',
            dateFormat: 'DD MMM YYYY'
          }
        }}
      />
    </Section>
  );
};

export default Departures;
