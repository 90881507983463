import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import { isAuthorized } from 'utils/permissions/authorize';
import { useSelector } from 'react-redux';
import ComponentAnalysis from '../components/component-analysis';
import TrainingNeeds from '../components/TrainingNeeds';
import { FloatingButtonsGroup } from 'common/components/buttons/FloatingButton';
import EvaluationCreateTop from './EvaluationCreateTop';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import PageLoader from 'common/components/general/PageLoader';
import Notes from '../components/Notes';
import comments from 'common/assets/svg/common/comments.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import send from 'common/assets/svg/actions/send.svg';
import crew from 'common/assets/svg/common/crew.svg';
import company from 'common/assets/svg/common/company.svg';
import training from 'common/assets/svg/crew/training.svg';
import { useFormState } from 'utils/hooks';
import { getEvaluationCategories, getModalProps } from 'crew/profile/main/evaluation/form/helpers';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';
import { selectAverageScore } from 'crew/store/evaluations/profile/selectors';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';

const EvaluationCreateDraft = ({
  formState,
  evaluationID,
  isDraft,
  evaluationTemplate,
  onSave,
  isSaving,
  onDelete,
  onRecalculateAverageScore,
  id,
  isOnboard,
  hasErrors,
  isCreatedOnVessel
}) => {
  const { history } = useRouter();

  const account = useSelector(selectAccount);

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(null); // Types: captain - seaman - submit - delete

  const averageScore = useSelector(selectAverageScore);

  const { fields, selectField } = useFormState(formState);

  const modalProps = useMemo(
    () => getModalProps(currentModalType, isOnboard),
    [currentModalType, isOnboard]
  );

  const shouldDisplayCriteria = !!fields.template_id.value;

  const actions = [
    {
      type: 'close',
      text: 'CANCEL',
      disabled: isSaving,
      onClick: () => history.push(`${paths.crew}/${id}/evaluation`)
    },
    ...(isDraft
      ? [
          {
            type: 'delete',
            text: 'DELETE',
            disabled: isSaving,
            onClick: () => {
              setCurrentModalType('delete');
              setIsModalOpen(true);
            }
          }
        ]
      : []),
    {
      type: 'draft',
      text: 'DRAFT',
      onClick: () => onSave({ isDraft: true }),
      disabled: isSaving || !fields?.template_id?.value
    },
    ...(isOnboard
      ? [
          {
            icon: crew,
            text: 'SEND TO CAPTAIN FOR REVIEW',
            svgWrapperClassName: 'bg-primary',
            svgClassName: 'text-white',
            textClassName: 'text-primary fw-bold',
            onClick: () => {
              setCurrentModalType('captain');
              setIsModalOpen(true);
            },
            permissions: [
              permissions.onboard.crew.seaman_profile.evaluations.all_users
                .send_to_captain_for_review
            ],
            disabled: isSaving || hasErrors || !fields?.template_id?.value
          }
        ]
      : []),
    {
      icon: crewPlanning,
      text: 'SEND TO SEAMAN FOR CONSENT',
      svgWrapperClassName: 'bg-primary',
      svgClassName: 'text-white',
      textClassName: 'text-primary fw-bold',
      onClick: () => {
        setCurrentModalType('seaman');
        setIsModalOpen(true);
      },
      permissions: [
        permissions.office.crew.seaman_profile.evaluations.send_for_consent,
        permissions.onboard.crew.seaman_profile.evaluations.all_users.send_to_seaman_for_consent
      ],
      disabled: isSaving || hasErrors || !fields?.template_id?.value
    },
    {
      type: 'save',
      icon: send,
      svgClassName: 'text-white',
      text: `SUBMIT ${isOnboard ? 'TO OFFICE' : ''}`,
      onClick: () => {
        setCurrentModalType('submit');
        setIsModalOpen(true);
      },
      permissions: [
        permissions.office.crew.seaman_profile.evaluations.submit,
        permissions.onboard.crew.seaman_profile.evaluations.all_users.submit_to_office
      ],
      disabled: isSaving || hasErrors || !fields?.template_id?.value
    }
  ];

  const actionsAfterPermissions = actions.filter(el => isAuthorized(account, el.permissions));
  const authorizedToViewNotes = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.view_submission_notes
  ]);

  useEffect(() => {
    if (+id === +account?.id) {
      history.replace({ pathname: paths.unauthorized });
    }
  }, [account?.id, id, history]);

  return (
    <>
      <EvaluationCreateTop
        formState={formState}
        evaluationID={evaluationID}
        isDraft={isDraft}
        setLoading={setLoading}
        isOnboard={isOnboard}
      />
      {evaluationTemplate && shouldDisplayCriteria && !loading ? (
        <>
          <ComponentAnalysis
            formState={formState}
            criteria={getEvaluationCategories(evaluationTemplate)}
            categories={evaluationTemplate.categories.filter(cat => cat.criteria.length)}
            onRecalculateAverageScore={onRecalculateAverageScore}
          />

          <div className="border-bottom-gray-200 w-100p my-4" />

          <TrainingNeeds
            fields={fields}
            configKey="training_types"
            selectField={selectField}
            icon={training}
            label="Training Needs"
            selectorLabel="I SUGGEST THE FOLLOWING TRAINING(S)"
            className="mb-2"
          />

          <Notes
            fields={fields}
            label="Evaluator Notes"
            configKey="evaluator_notes"
            selectField={selectField}
            icon={comments}
          />

          <div className="border-bottom-gray-200 w-100p my-4" />

          {isCreatedOnVessel || isOnboard ? (
            <Row className="mb-2">
              <Col xs={authorizedToViewNotes ? 6 : null}>
                <Notes
                  fields={fields}
                  label="Captain Notes"
                  configKey="captain_notes"
                  selectField={selectField}
                  icon={crew}
                />
              </Col>

              {authorizedToViewNotes ? (
                <Col xs={6}>
                  <Notes
                    fields={fields}
                    label="Submission Notes"
                    configKey="submission_notes"
                    selectField={selectField}
                    icon={send}
                    subtitle="*Not visible to evaluatee"
                    isHighlighted
                  />
                </Col>
              ) : null}
            </Row>
          ) : null}

          <Notes
            fields={fields}
            label="Evaluatee Notes"
            icon={crewPlanning}
            configKey="evaluatee_notes"
            viewOnly={true}
            className="mb-2"
          />

          {!isOnboard ? (
            <Notes
              fields={fields}
              label="Office Only Notes"
              icon={company}
              configKey="office_only_notes"
              selectField={selectField}
              subtitle="*Not visible to evaluatee"
              isHighlighted
            />
          ) : null}
        </>
      ) : (
        <PageLoader isLoading={loading} />
      )}
      <FloatingButtonsGroup fixed actions={actionsAfterPermissions} rating={averageScore} />

      <DangerousActionModal
        transparent
        onAccept={() => (currentModalType === 'delete' ? onDelete() : onSave(modalProps.params))}
        onClose={() => {
          setIsModalOpen(false);
          setCurrentModalType(null);
        }}
        closeModal={() => setIsModalOpen(false)}
        actionHoverColor={modalProps.color}
        isOpen={isModalOpen}
        actionText={modalProps.actionText}
        header={modalProps.title}
        body={modalProps.body}
        cancelText="CANCEL"
        actionIcon={
          <SvgRender
            className={modalProps.iconClassName || ''}
            src={modalProps.icon}
            style={{ width: 80, height: 80 }}
          />
        }
      />
    </>
  );
};

export default EvaluationCreateDraft;
