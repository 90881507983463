import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { useEffect } from 'react';
import {
  selectVesselNotesExist,
  selectVesselNotesIsLoading,
  selectVesselSavedNote
} from 'events/store/event-modules/vessel-notes/selectors';
import {
  createVesselNotes,
  editVesselNotes,
  getVesselNotes
} from 'events/store/event-modules/vessel-notes/actions';
import { changeVesselNotesContent } from 'events/store/event-modules/vessel-notes/slice';
import Notes from '../components/notes';

const VesselNotes = () => {
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const savedNote = useSelector(selectVesselSavedNote);
  const isLoading = useSelector(selectVesselNotesIsLoading);
  const notesExist = useSelector(selectVesselNotesExist);
  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);

  const onSave = ({ content }) => {
    if (isTemplate || !eventId) {
      dispatch(changeVesselNotesContent(content));
      return;
    }

    if (notesExist) {
      dispatch(editVesselNotes({ id: eventId, content }));
    } else {
      dispatch(createVesselNotes({ id: eventId, content }));
    }
  };

  useEffect(() => {
    if (!eventId) return;

    dispatch(getVesselNotes({ id: eventId }));
  }, [dispatch, eventId]);

  return (
    <Notes
      isLoading={isLoading}
      savedNotes={savedNote?.content ? [savedNote] : []}
      onSave={onSave}
      type="vessel_notes"
      hideActions={!isOnBoard || isTemplate || savedNote?.content}
    />
  );
};

export default VesselNotes;
