import { components } from 'react-select';

export const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <div className="d-flex align-items-center">
        <span className="text-primary fs-12 text-nowrap overflow-x-hidden text-truncate">
          {props.data.title || props.data.name}
        </span>
      </div>
    </components.MultiValueLabel>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.id ? (
        <div className="d-flex align-items-center">
          <span className="text-primary fs-10 me-2 fw-bold">{props.data.id}</span>
          <span className="text-primary fs-14 text-nowrap overflow-x-hidden text-truncate">
            {props.data.title || props.data.name}
          </span>
        </div>
      ) : null}
    </components.Option>
  );
};
