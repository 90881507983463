import { Button } from 'reactstrap';
import TreeTitleActions from './TreeTitleActions';
import SvgRender from 'common/components/general/SvgRender';

import arrow from 'assets/svg/common/arrow.svg';
import emptyRibbon from 'common/assets/svg/common/ribbon-line.svg';
import solidRibbon from 'common/assets/svg/common/ribbon-solid.svg';
import folder from 'common/assets/svg/common/folder.svg';
import burger from 'common/assets/svg/common/chapter.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { capitalizeFirst } from 'common/utils/texts';
import ColorViewer from 'common/components/general/ColorViewer';

const Title = ({
  hasChildren,
  onClick,
  title,
  isActive,
  isHighlighted,
  preffix,
  onExpand,
  parent_id,
  post_count,
  draft_change_request_count,
  isOpen,
  actions,
  disabledActions = false,
  rightComponent,
  leftComponent,
  startComponent,
  customIcon,
  withRibbon = false,
  withFolder = false,
  hideIcon = false,
  customIconComponent = null,
  type,
  color
}) => {
  return (
    <div
      className={`chapters-tree__view-title d-flex flex-1 align-items-center${
        isActive ? ' active' : ''
      }`}
    >
      {hasChildren ? (
        <Button
          color="link"
          className="d-inline-flex align-items-center btn-subs-toggle"
          onClick={onExpand}
        >
          <img
            className={`base-transition`}
            alt="arrow"
            src={arrow}
            width={hasChildren ? 10 : 12}
            height={hasChildren ? 10 : 14}
            style={{
              transform: hasChildren && !isOpen ? 'rotate(-90deg)' : ''
            }}
          />
        </Button>
      ) : null}

      {startComponent ?? null}

      <div
        className={`cursor-pointer d-flex align-items-center h-100p flex-1 overflow-hidden chapters-tree__view-title--inner ${
          hideIcon ? 'cps-4' : ''
        }`}
        onClick={onClick}
      >
        {customIconComponent ? (
          customIconComponent
        ) : parent_id && post_count ? (
          <SvgRender
            className={`ms-3 me-1 ${
              parent_id && post_count
                ? `text-${draft_change_request_count ? 'violet' : 'yellow'}`
                : ''
            }`}
            src={post_count ? solidRibbon : emptyRibbon}
            style={{ minWidth: 14, maxWidth: 14, height: 14 }}
          />
        ) : !hideIcon ? (
          <img
            className="ms-3 me-1"
            alt="ribbon"
            src={
              customIcon
                ? customIcon
                : withRibbon
                ? emptyRibbon
                : withFolder
                ? folder
                : !parent_id
                ? burger
                : emptyRibbon
            }
            width={14}
            height={14}
          />
        ) : null}
        <div
          className={`overflow-hidden text-nowrap fw-medium flex-1 fs-14 ${
            isHighlighted ? 'highlighted' : ''
          } `}
        >
          <TextWithTooltip>
            {preffix}&nbsp;{title}
            {type ? (
              <>
                <span className="text-violet mx-1">|</span>
                <span className="text-violet fs-14 fw-normal">
                  {capitalizeFirst(type?.split('_')?.join(' '))}
                </span>
              </>
            ) : null}
          </TextWithTooltip>
        </div>
      </div>

      {color ? <ColorViewer color={`#${color}`} className={'ms-auto me-1'} hideLabel /> : null}

      {leftComponent ? leftComponent : null}

      {disabledActions ? null : (
        <TreeTitleActions actions={actions} disabled={actions ? false : true} />
      )}

      {rightComponent ? rightComponent : null}
    </div>
  );
};

export default Title;
