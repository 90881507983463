import React from 'react';

import { Row, Col } from 'reactstrap';

import DateInput from 'common/components/form/inputs/date';
import PropTypes from 'prop-types';

const OccuredDate = ({ selectField, fields }) => {
  return (
    <Row xs={12} className="d-flex">
      <Col xs={6} className="pe-0 me-0">
        <DateInput
          label="Date occurred"
          value={fields.occurred_at.value}
          onChange={selected => selectField('occurred_at')(selected)}
          error={fields.occurred_at.error}
        />
      </Col>
      <Col xs={6} className="ps-0 ms-0">
        <DateInput
          label="Date reported"
          value={fields.reported_at.value}
          from={fields.reported_at.value}
          onChange={selected => selectField('reported_at')(selected)}
        />
      </Col>
    </Row>
  );
};

OccuredDate.propTypes = {
  selectField: PropTypes.func,
  fields: PropTypes.object
};

export default OccuredDate;
