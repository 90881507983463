import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Drawer, { DrawerHeader, DrawerBody, DrawerFooter } from 'common/components/drawer';
import { Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import closeIcon from 'common/assets/svg/actions/close.svg';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import ListSelect from 'common/components/selectors/ListSelect';
import SignaturePreview from './components/SignaturePreview';
import { useForm, useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectAccountId, selectAccountFullname, selectAccount } from 'store/account/selectors';
import EmptyPage from 'common/components/general/EmptyPage';
import config from './config';
import editEmpty from 'common/assets/svg/actions/edit-empty.svg';
import PropTypes from 'prop-types';
import SignatureCanvas from '../components/SignatureCanvas';
import { getSignatureSvgPath, setSignaturePad } from '../helpers';
import SignatureRequirements from './components/SignatureRequirements';
import { shouldShowCrewAccordingToRank } from 'common/components/digital-signature/helpers';
import { isAuthorized } from 'utils/permissions/authorize';

const DigitalSignatureDrawer = ({
  drawer,
  onCreate,
  onClose,
  onFetch,
  onFetchRequirements,
  onDelete,
  identifier,
  viewOnly = false,
  refetchWrapper = () => {}
}) => {
  const [drawingPad, setDrawingPad] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [savedPath, setSavedPath] = useState('');
  const [savedSignatures, setSavedSignatures] = useState([]);
  const [signatureRequirements, setSignatureRequirements] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({ isOpen: false, signature_id: null });

  const isOnBoard = useSelector(state => state.isOnBoard);
  const account = useSelector(selectAccount);
  const userId = useSelector(selectAccountId);
  const userFullname = useSelector(selectAccountFullname);

  const userCanSign = useMemo(
    () => (!isOnBoard ? true : isOnBoard && isAuthorized(account, ['vessel.signatures.sign'])),
    [account, isOnBoard]
  );

  const { formState, collectValues, loadValues, resetForm } = useForm(config);

  const { fields, selectField } = useFormState(formState);

  const emptySvgPathLength = 137;

  const initializeSignature = () => {
    const signaturePad = setSignaturePad(identifier);

    if (!signaturePad) return null;

    setDrawingPad(signaturePad);
  };

  const fetchSavedSignatures = async () => {
    setSavedSignatures(await onFetch());
  };

  const onSaveSignature = async () => {
    if (drawingPad?.isEmpty()) {
      return null;
    } else {
      setIsSubmitting(true);

      try {
        const signatureSvgPath = await getSignatureSvgPath(drawingPad);

        setSavedPath(signatureSvgPath);

        const { person } = collectValues();

        await onCreate(person.id, signatureSvgPath);
        await fetchSavedSignatures();
        await onFetchSignatureRequirements();

        onClearCanvas();
        resetForm();
        refetchWrapper();
        setIsSubmitting(false);
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
      }
    }
  };

  const onDeleteSignature = async () => {
    try {
      setIsDeleting(true);

      await onDelete(showDeleteModal?.signature_id);
      await fetchSavedSignatures();
      await onFetchSignatureRequirements();

      setShowDeleteModal({ isOpen: false, signature_id: null });
      refetchWrapper();
      setIsDeleting(false);
    } catch (error) {
      console.log(error);
      setIsDeleting(false);
    }
  };

  const onClearCanvas = () => {
    drawingPad?.clear();
    setSavedPath('');
  };

  const onFetchSignatureRequirements = useCallback(async () => {
    if (onFetchRequirements) {
      try {
        const res = await onFetchRequirements().unwrap();
        setSignatureRequirements(res);
      } catch (error) {
        console.log(error);
        setSignatureRequirements([]);
      }
    } else {
      setSignatureRequirements([]);
    }
  }, [onFetchRequirements]);

  useEffect(() => {
    if (!drawer.isOpen) {
      setSavedPath('');
      setSavedSignatures([]);
      onClearCanvas();
    } else {
      initializeSignature();
      fetchSavedSignatures();
      loadValues({ person: userId && userFullname ? { id: userId, name: userFullname } : null });
    }
  }, [drawer.isOpen]);

  useEffect(() => {
    if (drawer.isOpen) {
      onFetchSignatureRequirements();
    }
  }, [drawer.isOpen, onFetchSignatureRequirements]);

  return (
    <>
      <Drawer
        className={`digital-signature-drawer ${viewOnly ? 'view-only' : ''} `}
        {...drawer}
        close={() => {
          if (onClose) {
            onClose();
          }
          drawer.close();
        }}
      >
        <DrawerHeader className="py-2 d-flex align-items-center">{'Sign'}</DrawerHeader>
        <DrawerBody className="mt-2 pe-9 digital-signature-drawer--body">
          {viewOnly ? null : (
            <>
              <SignatureCanvas
                identifier={identifier}
                onMouseLeave={() => setSavedPath(drawingPad?.toSVG())}
              />
              <Row>
                <Col>
                  <ListSelect
                    list="parties"
                    params={{ can_login: true, ...(isOnBoard ? { onboard: true } : {}) }}
                    isAsync={true}
                    onChange={selectField('person')}
                    placeholder={'Select person'}
                    filterOption={e =>
                      (isOnBoard &&
                        shouldShowCrewAccordingToRank(
                          e?.data?.details?.rank?.id,
                          'vessel.signatures.sign',
                          account
                        )) ||
                      e.value === userId ||
                      !isOnBoard
                    }
                    className="mb-0"
                    {...fields.person}
                  />
                </Col>
                <Col xs={'auto'} className="d-flex align-items-start">
                  <Button
                    color={'grey'}
                    type="button"
                    onClick={onClearCanvas}
                    className="d-inline-flex align-items-center text-primary me-1 height-24 py-0"
                    size="sm"
                  >
                    <SvgRender
                      src={closeIcon}
                      style={{ width: 8, height: 8 }}
                      className="me-1 text-primary"
                    />
                    Clear
                  </Button>
                  <Button
                    className="d-inline-flex align-items-center fs-10 px-2 height-24"
                    size="sm"
                    onClick={onSaveSignature}
                    color={'primary'}
                    disabled={
                      !userCanSign
                        ? true
                        : isSubmitting ||
                          !fields?.person?.value?.id ||
                          savedPath.length < emptySvgPathLength
                    }
                  >
                    SAVE
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {!savedSignatures?.length && viewOnly ? (
            <EmptyPage pageText={<span>There are no signs yet.</span>} pageIcon={editEmpty} />
          ) : (
            <SignaturePreview
              signaturesData={savedSignatures}
              setShowDeleteModal={setShowDeleteModal}
              disabledDelete={isDeleting}
              viewOnly={viewOnly}
            />
          )}
          {signatureRequirements?.length > 0 && (
            <SignatureRequirements data={signatureRequirements} />
          )}
        </DrawerBody>
        <DrawerFooter className="px-3 cpt-12 cpb-12  d-flex justify-content-end">
          <Button
            color="cancel"
            onClick={() => {
              if (onClose) {
                onClose();
              }

              drawer.close();
            }}
          >
            CLOSE
          </Button>
        </DrawerFooter>
      </Drawer>
      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDeleteSignature}
        closeModal={() => setShowDeleteModal({ isOpen: false, signature_id: null })}
        isOpen={showDeleteModal.isOpen}
        actionText={'DELETE'}
        header={'Delete'}
        body={`Are you sure you want to delete this signature?`}
      />
    </>
  );
};
DigitalSignatureDrawer.propTypes = {
  drawer: PropTypes.shape({
    isOpen: PropTypes.bool,
    open: PropTypes.func
  }),
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
  onFetchRequirements: PropTypes.func,
  onDelete: PropTypes.func,
  identifier: PropTypes.number,
  viewOnly: PropTypes.bool,
  refetchWrapper: PropTypes.func
};
export default DigitalSignatureDrawer;
