import PropTypes from 'prop-types';

import Status from 'common/components/general/Status';
import PurchasingRequisitionStatus from 'common/components/purchasing/requisition/components/Status';
import { LinkedEntityContainer } from 'common/components/findings/report/tableConfig';
import { Finding } from 'common/entities/findings/FindingTypes';

const LinkedStatus = ({ data, isEvent = false }) => (
  <>
    {data?.origin_audit && !isEvent ? (
      <LinkedEntityContainer>
        <Status value={data?.origin_audit?.event?.status?.id} type="events" />
      </LinkedEntityContainer>
    ) : null}
    {data?.origin_form_submission ? (
      <LinkedEntityContainer>
        <Status value={data?.origin_form_submission?.form_status_id} type="forms" />
      </LinkedEntityContainer>
    ) : null}
    {data?.form_submissions?.length
      ? data?.form_submissions?.map((form, i) => (
          <LinkedEntityContainer key={i}>
            <Status value={form?.form_status_id} type="forms" />
          </LinkedEntityContainer>
        ))
      : null}
    {data?.jobs?.length
      ? data?.jobs?.map((job, i) => (
          <LinkedEntityContainer key={i}>
            <Status value={job?.status_id} type="jobs" />
          </LinkedEntityContainer>
        ))
      : null}
    {data?.purchasing_requisitions?.length
      ? data?.purchasing_requisitions?.map((requisition, i) => (
          <LinkedEntityContainer key={i}>
            <PurchasingRequisitionStatus status={requisition?.status} isBullet hasTooltip />
          </LinkedEntityContainer>
        ))
      : null}
  </>
);

export default LinkedStatus;

LinkedStatus.propTypes = {
  data: Finding,
  isEvent: PropTypes.bool
};
