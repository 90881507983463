import paths from 'routing/routes/_paths';
import PersonProfile from 'views/persons/profile';
import { selectPersonTitle } from 'store/persons/selectors';
import permissions from 'common/utils/permissions/constants';

const persons = [
  {
    path: `${paths.persons}/:id`,
    component: PersonProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.crew.seaman_profile.view],
    documentTitle: selectPersonTitle
  }
];

export default persons;
