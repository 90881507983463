import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrganizationSettings } from '@/types/organization-settings';

export const getSettings = createAsyncThunk('GET_SETTINGS', async (_, { rejectWithValue }) => {
  try {
    const res = await get<OrganizationSettings>('/settings');
    return res?.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
