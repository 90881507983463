import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import LinkTo from 'views/dashboard/components/LinkTo';
import AuthCheck from 'components/permissions/AuthCheck';

const Box = ({
  size = 'sm',
  bg = 'white',
  header,
  subheader,
  icon,
  content,
  linkTo,
  permissions,
  className = ''
}) => {
  return (
    <AuthCheck permissions={permissions}>
      <div
        className={`dashboard__totals-box d-flex flex-column bg-${bg} ${
          className ? className : ''
        } size-${size} `}
      >
        <div className={`mb-1 dashboard__totals-box-container`}>
          {icon ? (
            <div className="mb-1">
              <SvgRender
                className={`${`text-${
                  bg === 'primary' ? 'white' : 'primary'
                }`} dashboard__totals-box-icon `}
                src={icon}
                style={{ width: 32, height: 32 }}
              />
            </div>
          ) : null}
          <div
            className={`d-flex align-items-center dashboard__totals-box-header ${`text-${
              bg === 'primary' ? 'white' : 'primary'
            }`}  `}
          >
            <strong className="pe-2">{header}</strong>
            {linkTo ? <LinkTo bg={bg} to={linkTo} permissions={permissions} /> : null}
          </div>

          {subheader ? (
            <div className={`text-violet dashboard__totals-box-subheader fs-12`}>{subheader}</div>
          ) : null}
        </div>

        <div className="dashboard__totals-content  mt-auto">{content}</div>
      </div>
    </AuthCheck>
  );
};

Box.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  bg: PropTypes.oneOf(['primary', 'white', 'red', 'orange'])
};

export default Box;
