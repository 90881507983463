import { useEffect } from 'react';
import numeral from 'numeral';

import { useActions } from 'utils/hooks';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import * as reportActions from 'captain-reports/store/actions';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const GeneratorLoFeedRateField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  tooltip
}) => {
  const [setValue] = useActions([reportActions.setValue]);

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const lubOilConsumption = useValue({
    key: 'dg_lub_oil_consumption_total',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const runningMinutes = useValue({
    key: 'dg_running_minutes',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const load = useValue({
    key: 'dg_power',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  useEffect(() => {
    let result = null;

    if (
      (runningMinutes || runningMinutes === 0) &&
      (lubOilConsumption || lubOilConsumption === 0) &&
      (load || load === 0)
    ) {
      let lubOilConsumptionInGramms = +lubOilConsumption * 0.92 * 1000;
      let runningHours = runningMinutes / 60;

      // dg_lub_oil_consumption_total * 0.92 * 1000 / (dg_running_minutes / 60) / dg_power
      result = numeral(strToNumber(lubOilConsumptionInGramms))
        .divide(strToNumber(runningHours))
        .divide(strToNumber(load))?._value;
    }

    if (value !== +result?.toFixed(2)) {
      setValue({
        key: 'dg_lub_oil_feed_rate',
        value: +result?.toFixed(2) || null,
        sectionLabel: sectionLabel,
        sectionActiveTab: sectionActiveTab,
        subGroup: subGroup,
        subGroupIndex: subGroupIndex
      });
    }
  }, [runningMinutes, lubOilConsumption, load]);

  return (
    <div>
      <FieldLabel
        info={tooltip}
        className="form-label fw-bold text-uppercase cmb-6"
        label={field.label}
        subLabel={field.subLabel}
      />

      <ValidationWrapper
        field={{ key: 'dg_lub_oil_feed_rate', label: '' }}
        value={value}
        sectionLabel={sectionLabel}
        className="pt-2"
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      >
        <div className="height-32 fs-12 fw-bold text-end  pe-3">{numberToStr(value)}</div>
      </ValidationWrapper>
    </div>
  );
};

export default GeneratorLoFeedRateField;
