import { createSelector } from 'reselect';

export const selectCrewEvaluationTemplatesState = state => state.crew.evaluations.templates;

export const selectActiveTemplateEvaluation = createSelector(
  selectCrewEvaluationTemplatesState,
  template => template.active
);

export const selectActiveTemplateEvaluationId = state => selectActiveTemplateEvaluation(state)?.id;
