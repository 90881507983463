import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import promotedIcon from 'common/assets/svg/common/promoted.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import PropTypes from 'prop-types';

const RankPromotionLabel = ({
  className = '',
  text,
  textClassName = '',
  svgClassName = '',
  svgStyle,
  tooltipText,
  tooltipInnerClassName = ''
}) => {
  const { avoidRender, tooltipID } = useTooltipID('rank-promotion-label');

  if (avoidRender) return null;

  return (
    <>
      <div
        id={tooltipID}
        className={`d-flex align-items-center bg-melting-glacier border border-turquoise border-radius-3 ${className}`}
      >
        <SvgRender className={svgClassName} src={promotedIcon} style={svgStyle} />
        <span className={textClassName}>{text}</span>
      </div>
      {tooltipText ? (
        <Tooltip innerClassName={tooltipInnerClassName} placement={'top'} target={tooltipID}>
          {tooltipText}
        </Tooltip>
      ) : null}
    </>
  );
};

RankPromotionLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  textClassName: PropTypes.string,
  svgClassName: PropTypes.string,
  svgStyle: PropTypes.object,
  tooltipText: PropTypes.string,
  tooltipInnerClassName: PropTypes.string
};

export default RankPromotionLabel;
