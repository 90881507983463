import _get from 'lodash/get';

import SvgRender from 'common/components/general/SvgRender';

import promoted from 'common/assets/svg/common/promoted.svg';

const WageClass = ({ crewWage }) => {
  return (
    <div className="text-primary crew-wage">
      <div className="fs-10 mb-1">WAGE CLASS</div>
      {crewWage ? (
        <div>
          <div className="d-flex align-items-center fs-12 fw-bold">
            {_get(crewWage, 'name')}{' '}
            {crewWage?.recently_promoted ? (
              <div className="d-flex align-items-center">
                <span className="cms-6 cme-6 text-violet fw-normal">|</span>
                <SvgRender src={promoted} style={{ height: 12, width: 12 }} />
                <span className="cms-6">Newly Promoted</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        '-'
      )}
    </div>
  );
};

export default WageClass;
