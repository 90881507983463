import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_FORM_SUBMISSION_MESSAGES'),
  ...asyncTypes('POST_FORM_SUBMISSION_MESSAGE'),
  ...asyncTypes('GET_FORM_SUBMISSION_UNREAD_MESSAGES'),
  ...asyncTypes('FORM_SUBMISSION_MARK_AS_READ')
};

export default TYPES;
