import React from 'react';
import UploadFiles from 'common/components/form/inputs/upload-files';

import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { selectIsPms } from 'common/components/pms/jobs/store/selectors';

import { setJobField, setJobFieldError } from 'common/components/jobs/_base/store/actions';
import { useDispatch } from 'react-redux';

const Attachments = ({ className, ...rest }) => {
  const dispatch = useDispatch();

  const attachments = useSelector(state => selectJobField(state, 'attachments'));

  const attachmentsError = useSelector(state => selectJobFieldError(state, 'attachments'));

  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));
  const isPms = useSelector(selectIsPms);

  return (
    <div className={`form-group-spacing ${className}`}>
      <UploadFiles
        label={
          <div>
            <span>Attachments</span>
            {isPms ? (
              <span className="ms-1 text-turquoise text-capitalize fw-normal">
                *Attach here files related to job completion
              </span>
            ) : null}
          </div>
        }
        group="jobs.attachments"
        files={attachments}
        onChange={files => {
          dispatch(setJobField('attachments', files, true));
          if (files?.length && isPms) {
            dispatch(setJobFieldError({ attachments: null }));
          }
        }}
        viewOnly={isJobLocked}
        size={!isPms ? 'lg' : 'sm'}
        error={attachmentsError}
        {...rest}
      />
    </div>
  );
};

export default Attachments;
