import React from 'react';
import ForCorrectionsAlert from 'common/components/alerts/ForCorrectionsAlert';

import { useSelector } from 'react-redux';
import { selectData } from 'captain-reports/store/system-running-hours/selectors';

const CorrectionsAlert = () => {
  const reportStatus = useSelector(selectData)?.status;

  return reportStatus === 'rebuild' ? <ForCorrectionsAlert /> : null;
};

export default CorrectionsAlert;
