import Information from 'common/components/general/Information';

import { useSelector } from 'react-redux';
import { isManualOnBoard } from 'manuals/store/selectors';

import vessels from 'common/assets/svg/common/vessels.svg';

const ChapterVessels = ({ chapter }) => {
  const isOnBoard = useSelector(isManualOnBoard);

  return chapter?.file && !isOnBoard ? (
    <div className="chapter-vessels-container h-100p d-inline-flex align-items-center pe-2">
      <div
        className={!chapter?.vessels?.length && !chapter?.vessel_groups?.length ? 'invisible' : ''}
      >
        <Information
          className="text-violet"
          svgIcon={vessels}
          svgStyle={{ heihgt: 14, width: 14 }}
          message={
            <>
              <div className="fs-12 text-start">
                <div className="pe-8">Chapter specific for:</div>
                <div className="fw-bold">
                  {[...chapter.vessels, ...chapter.vessel_groups].map(v => v.name).join(', ')}
                </div>
              </div>
            </>
          }
        />
      </div>
    </div>
  ) : null;
};

export default ChapterVessels;
