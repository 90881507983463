import { FC } from 'react';
import _get from 'lodash/get';

import { dateToLocal } from 'common/utils/dates';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SvgRender from 'common/components/general/SvgRender';
import { JobLogEntryType } from '@/common/types/jobs.ts';

import arrow from 'assets/svg/common/arrow-right-long.svg';

type SingleDateProps = {
  data: JobLogEntryType;
  isLast?: boolean;
};

const SingleDate: FC<SingleDateProps> = ({ data, isLast }) => {
  const timeBefore: string | null = dateToLocal(
    _get(data, 'properties.old.due_date', ''),
    null,
    'DD/MM/YYYY'
  ) as string | null;

  const timeAfter: string | null = dateToLocal(
    _get(data, 'properties.attributes.due_date', ''),
    null,
    'DD/MM/YYYY'
  ) as string | null;

  const created_at: string | null = dateToLocal(
    _get(data, 'created_at', ''),
    null,
    'MMMM DD, YYYY, hh:mm'
  ) as string | null;

  return (
    <div className={`fs-12 ${isLast ? '' : 'border-bottom border-platinum cmb-12'} cpb-12`}>
      <div className=" d-flex align-items-center mb-1">
        <span className="text-primary fw-bold">{timeBefore}</span>
        <SvgRender src={arrow} className="text-primary cmx-6" style={{ width: 18, height: 14 }} />
        <span className="fw-bold text-primary">{timeAfter}</span>
      </div>

      <TextWithTooltip className="d-flex align-items-center text-violet fw-light fs-12">
        <div className="d-flex align-items-start ">
          Changed by
          <span className="fw-bold cmx-4">{_get(data, 'causer.full_name', '')}</span>
          at <span className="fw-bold cms-4">{created_at}</span>
        </div>
      </TextWithTooltip>
    </div>
  );
};

export default SingleDate;
