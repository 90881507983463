import React from 'react';

import CommonNumberIput from 'common/components/form/inputs/NumberInput';

const NumberInput = ({ value, onChange, placeholder, className, ...rest }) => {
  return (
    <CommonNumberIput
      value={value}
      onChange={onChange}
      placeholder={placeholder || 'Add Value'}
      label={null}
      invisible={true}
      className={`${className ? className : ''} mb-0 fs-12`}
      {...rest}
    />
  );
};
export default NumberInput;
