import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormState, FormTypes } from 'common/entities/form/FormTypes';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'utils/hooks';
import { Row, Col, Button, Label } from 'reactstrap';
import { upload, download } from 'utils/api';
import add from 'common/assets/svg/actions/add.svg';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import Select from 'common/components/form/inputs/Select';
import DateInput from 'common/components/form/inputs/date';
import NumberInput from 'common/components/form/inputs/NumberInput';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import Textarea from 'common/components/form/inputs/Textarea';
import UploadFiles from 'common/components/form/inputs/upload-files';
import Logs from './Logs';
import { getAccountCurrencyEquivalent } from 'events/store/event-modules/accounts/actions';
import { fetchListOptions } from 'store/lists/actions';

const Form = ({
  formState,
  collectValues,
  drawer,
  hasErrors,
  submitForm,
  isSubmitting,
  active
}) => {
  const dispatch = useDispatch();
  const { fields, selectField } = useFormState(formState);

  const onSave = () => {
    const values = collectValues();
    if (!values) return null;
    const { supplier, comments, attachments, ...rest } = values;

    submitForm({
      supplier_id: supplier?.id || null,
      comments: comments?.length ? comments : undefined,
      attachments: attachments?.map(a => a?.id) || [],
      ...rest
    });
  };

  const categories = useSelector(state =>
    selectListOptionsFromStore(state, 'event-account-categories')
  );
  const currencies = useSelector(state => selectListOptionsFromStore(state, 'currencies'));

  useEffect(() => {
    dispatch(fetchListOptions('event-account-categories'));
    dispatch(fetchListOptions('accounts'));
    dispatch(fetchListOptions('currencies'));
  }, [dispatch]);

  useEffect(() => {
    if (fields.price.value && fields.currency_id.value) {
      const changeCurrencyEquivalent = async params => {
        try {
          const res = await dispatch(getAccountCurrencyEquivalent(params)).unwrap();

          selectField('price_base_currency_equivalent')(res.price_base_currency_equivalent);
          selectField('base_currency_equivalent_rate')(res.base_currency_equivalent_rate);
        } catch (e) {
          console.err(e);
        }
      };

      changeCurrencyEquivalent({
        price: strToNumber(fields.price.value),
        currency_id: fields.currency_id.value
      });
    }
  }, [dispatch, fields.currency_id.value, fields.price.value, selectField]);

  return (
    <>
      <FormBody className="d-flex flex-column justify-content-between">
        <Row>
          <Col xs={12}>
            <DateInput
              label={'DATE'}
              onChange={date => selectField('date')(date)}
              {...fields.date}
            />
          </Col>
          <Col xs={12}>
            <Select
              placeholder="Select Category"
              label="COST CATEGORY"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              options={categories}
              onChange={type => selectField('category_id')(type)}
              {...fields.category_id}
            />
          </Col>
          <Col xs={12}>
            <AsyncSelector
              label="SUPPLIER"
              placeholder="Select supplier"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              loadOptions={search =>
                getAsyncOptions(search, 'parties', {
                  type: 'company',
                  role: ['supplier', 'supplier_representative']
                })
              }
              defaultOptions={() =>
                getInitialAsyncValues('parties', null, null, {
                  type: 'company',
                  role: ['supplier', 'supplier_representative']
                })
              }
              onChange={supplier => selectField('supplier')(supplier)}
              {...fields.supplier}
            />
          </Col>
          <Col xs={3}>
            <Select
              placeholder="Select Currency"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.label}
              onChange={currency => selectField('currency_id')(currency)}
              options={currencies}
              label="Currency"
              {...fields.currency_id}
            />
          </Col>
          <Col xs={3}>
            <NumberInput
              placeholder="Add amount"
              label={'LOCAL AMOUNT'}
              onChange={e => selectField('price')(e.target.value)}
              {...fields.price}
            />
          </Col>
          <Col className="d-flex align-itmes-center flex-column">
            <Label className="form-label">RATE</Label>
            <div>
              {fields?.base_currency_equivalent_rate?.value
                ? numberToStr(fields?.base_currency_equivalent_rate?.value, 2, 2)
                : '-'}
            </div>
          </Col>
          <Col className="d-flex align-itmes-center flex-column">
            <Label className="form-label">AMOUNT IN USD</Label>
            <div>
              {fields?.price_base_currency_equivalent?.value
                ? numberToStr(fields?.price_base_currency_equivalent?.value, 2, 2)
                : '-'}
            </div>
          </Col>
          <Col xs={12}>
            <Textarea
              rows={8}
              placeholder="Add some text"
              label="COMMENTS"
              name="comments"
              onChange={e => selectField('comments')(e.target.value)}
              {...fields.comments}
            />
          </Col>
          <Col xs={12}>
            <UploadFiles
              upload={upload}
              download={download}
              className="square-upload mb-0"
              group="events.accounts"
              uploadIcon={add}
              files={fields.attachments.value}
              onChange={files => selectField('attachments')(files)}
              uploadText="Upload File(s)"
              smallView={false}
              viewOnly={false}
              showDownload={false}
              showFileSize={false}
              error={fields.attachments.error}
              size="lg"
            />
          </Col>
        </Row>
        <Logs
          created_at={active?.created_at}
          created_by={active?.created_by}
          updated_at={active?.updated_at}
          updated_by={active?.updated_by}
        />
      </FormBody>

      <FormFooter>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
          CANCEL
        </Button>
        <Button
          onClick={onSave}
          disabled={isSubmitting || hasErrors}
          color="primary"
          className="px-4"
        >
          SAVE
        </Button>
      </FormFooter>
    </>
  );
};

Form.propTypes = {
  drawer: DrawerState,
  formState: FormState,
  collectValues: FormTypes.collectValues,
  hasErrors: FormTypes.hasErrors,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  active: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    event_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes?.string,
    currency: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  })
};

export default Form;
