import DigitalForm from 'common/components/forms/digital/Form';
import UploadForm from 'common/components/forms/upload/Form';

const Body = ({
  setFormState,
  formType,
  uploadedFileError,
  selectedForm,
  setUploadedFileError,
  uploadedFile,
  setUploadedFile,
  originalFile,
  form,
  formSubmission,
  formState,
  headerFormState,
  previewMode
}) => {
  return (
    <div className="p-2">
      {formType === 'digital' ? (
        <DigitalForm
          formSubmission={formSubmission}
          setFormState={setFormState}
          formFields={selectedForm.form_fields}
          formState={formState}
          disabled={previewMode}
          headerFormState={headerFormState}
          isFormForVessel={selectedForm.is_for_vessel}
        />
      ) : formType === 'upload' ? (
        <UploadForm
          uploadedFileError={uploadedFileError}
          setUploadedFileError={setUploadedFileError}
          uploadedFile={uploadedFile}
          originalFile={
            originalFile ? originalFile : selectedForm?.file?.id ? selectedForm.file : null
          }
          form={selectedForm}
          setUploadedFile={setUploadedFile}
        />
      ) : null}

      {formType === 'upload' && !uploadedFile && form ? (
        <div className="fs-10 text-violet">*Upload the edited form before save</div>
      ) : null}
    </div>
  );
};

const FormInnerBody = ({
  form,
  formSubmission,
  formState,
  formType,
  setFormState,
  uploadedFileError,
  selectedForm,
  setUploadedFileError,
  uploadedFile,
  setUploadedFile,
  originalFile,
  headerFormState,
  previewMode
}) => {
  return form ? (
    <div className={`form-manager--inner-body`}>
      <Body
        setFormState={setFormState}
        formType={formType}
        uploadedFileError={uploadedFileError}
        selectedForm={selectedForm}
        setUploadedFileError={setUploadedFileError}
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
        originalFile={originalFile}
        form={form}
        formSubmission={formSubmission}
        formState={formState}
        headerFormState={headerFormState}
        previewMode={previewMode}
      />
    </div>
  ) : null;
};

export default FormInnerBody;
