import Radio from 'common/components/form/inputs/Radio';

const RadioInput = ({ options, disabled, value, onChange, error }) => {
  return options.map((opt, i) => (
    <Radio
      key={opt}
      invisible
      label={<span className="text-primary fs-12">{opt}</span>}
      onChange={() => onChange(opt)}
      checked={value === opt}
      type={error ? 'red' : 'green'}
      className="mb-0 me-5"
      error={disabled ? null : i === 0 ? error : null}
    />
  ));
};

export default RadioInput;
