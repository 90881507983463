import VesselSelector from 'common/components/selectors/VesselSelector';
import PortSelector from 'common/components/selectors/PortSelector';
import PrioritySelector from 'common/components/selectors/PrioritySelector';
import JobSelector from 'common/components/selectors/jobs/JobSelector';
import ProjectSelector from 'common/components/selectors/ProjectSelector';
import StatusSelector from 'common/components/selectors/JobStatusSelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import TemplateSelector from 'common/components/selectors/jobs/TemplateSelector';
import DepartmentsFilterSelector from 'common/components/selectors/departments/FilterSelector';
import JobTriggerPrototypeSelector from 'common/components/selectors/jobs/JobTriggerPrototypeSelector';
import JobStatusProgress from 'common/components/selectors/jobs/JobStatusProgress';

const columns = [
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance_id',
    type: 'collection',
    sort: true,
    component: PrioritySelector,
    componentRest: { isMulti: true },
    sticky: true,
    left: 0,
    minWidth: 72,
    maxWidth: 72,
    className: 'priority-cell z-index-3'
  },
  {
    header: 'No.',
    key: 'id',
    type: 'number',
    sort: true,
    sticky: true,
    left: 72,
    minWidth: 72,
    maxWidth: 72,
    className: 'number-cell text-start z-index-3'
  },
  {
    header: 'Title',
    key: 'title',
    type: 'string',
    sort: true,
    sticky: true,
    left: 144,
    minWidth: 372,
    maxWidth: 372,
    className: 'title-cell z-index-3'
  },
  {
    header: 'Vessel',
    key: 'vessels',
    type: 'collectionMultiple',
    sort: false,
    component: VesselSelector,
    componentRest: { isMulti: true },
    minWidth: 174,
    maxWidth: 174
  },
  {
    header: 'Due Date',
    key: 'due_date',
    type: 'date',
    sort: true,
    minWidth: 150,
    maxWidth: 150
  },
  {
    header: 'Due Date Changed',
    key: 'due_date_changed',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'True' },
      { value: 'false', label: 'False' }
    ]
  },
  {
    header: 'Drydock',
    key: 'is_drydock',
    type: 'boolean',
    sort: false,
    canFilter: true,
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Watching',
    key: 'is_watcher',
    type: 'boolean',
    hidden: true,
    hiddenOnBoard: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Status',
    key: 'status_id',
    type: 'collection',
    sort: true,
    component: StatusSelector,
    componentRest: { isMulti: true },
    minWidth: 155,
    maxWidth: 155
  },
  {
    header: 'Dpts-all',
    key: 'all_departments',
    type: 'collection',
    hidden: true,
    component: DepartmentsFilterSelector
  },
  {
    header: 'Template',
    key: 'template_id',
    type: 'collection',
    hidden: true,
    component: TemplateSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Dpt-main',
    key: 'main_department_id',
    type: 'collection',
    sort: true,
    component: DepartmentsFilterSelector,
    minWidth: 145,
    maxWidth: 145
  },
  {
    header: 'Assignee',
    key: 'assignee_id',
    type: 'collection',
    sort: true,
    component: PersonSelector,
    minWidth: 215,
    maxWidth: 215,
    componentRest: { isMulti: true, listParams: { can_login: true } }
  },
  {
    header: 'Approvers',
    key: 'approvers',
    type: 'collection',
    sort: true,
    component: PersonSelector,
    minWidth: 210,
    maxWidth: 210,
    componentRest: { isMulti: true, showDefaultOptions: true, listParams: { can_login: true } }
  },

  {
    header: 'Ports',
    key: 'port_id',
    type: 'collection',
    sort: true,
    component: PortSelector,
    componentRest: { isMulti: true },
    minWidth: 200
  },
  {
    header: 'Dpts-secondary',
    key: 'departments',
    type: 'collectionMultiple',
    component: DepartmentsFilterSelector,
    componentRest: { isMulti: true },
    minWidth: 210,
    maxWidth: 210
  },
  {
    header: 'Created By',
    key: 'created_by_id',
    type: 'collection',
    sort: true,
    component: PersonSelector,
    minWidth: 218,
    maxWidth: 218,
    componentRest: { isMulti: true, showDefaultOptions: true, listParams: { can_login: true } }
  },
  {
    header: 'Tags',
    key: 'tags',
    type: 'string',
    hidden: true,
    className: 'd-none'
  },
  {
    header: 'Blocked by',
    key: 'blocked_by',
    type: 'collection',
    hidden: true,
    component: JobSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Status progress',
    key: 'progress',
    type: 'collection',
    hidden: true,
    component: JobStatusProgress,
    componentRest: { isMulti: true }
  },
  {
    header: 'Blocks',
    key: 'blocks',
    type: 'collection',
    hidden: true,
    component: JobSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Project',
    key: 'project_id',
    type: 'collection',
    hidden: true,
    hideFromDropdown: false,
    component: ProjectSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Relates to',
    key: 'relates',
    hidden: true,
    type: 'collection',
    component: JobSelector,
    componentRest: { isMulti: true }
  },
  { header: 'Date Created', key: 'created_at', type: 'date', sort: true },
  {
    header: 'Date Updated',
    key: 'updated_at',
    type: 'date',
    hidden: true,
    sort: true
  },
  {
    header: 'Triggered by',
    key: 'job_trigger_prototype_id',
    type: 'collection',
    sort: true,
    hidden: true,
    component: JobTriggerPrototypeSelector,
    componentRest: {
      isMulti: true,
      showDefaultOptions: true,
      listParams: { is_maintenance: false }
    }
  },
  {
    header: 'In Project',
    key: 'in_project',
    type: 'boolean',
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Linked to Findings',
    key: 'is_linked_to_findings',
    type: 'boolean',
    hidden: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export default columns;
