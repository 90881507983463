import PropTypes from 'prop-types';

export const Action = PropTypes.shape({
  abbreviation: PropTypes.string,
  credit_account_id: PropTypes.number,
  credit_type: PropTypes.string,
  debit_account_id: PropTypes.number,
  id: PropTypes.number.isRequired,
  is_active: PropTypes.bool,
  is_automated: PropTypes.bool,
  is_bank_account_selection_enabled: PropTypes.bool,
  name: PropTypes.string.isRequired
});

export const Account = PropTypes.shape({
  code: PropTypes.string,
  contract_amount_payment_type: PropTypes.string,
  crew_member_id: PropTypes.number,
  id: PropTypes.number.isRequired,
  is_crew_contract_monthly_amount: PropTypes.bool,
  is_master: PropTypes.bool,
  is_parent_of_crew_accounts: PropTypes.bool,
  is_set_on_crew_contract: PropTypes.bool,
  show_account_page_onboard: PropTypes.bool,
  parent_id: PropTypes.number,
  type_id: PropTypes.number,
  name: PropTypes.string.isRequired
});
