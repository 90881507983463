import { Row, Col } from 'reactstrap';
import FormFieldLabel from './FormFieldLabel';

import _isString from 'lodash/isString';

const FormHeader = ({ item, className = 'mb-1', width = 3, color = 'dark' }) => {
  return _isString(item.header) ? (
    <Row className={`vertical-view-form-header align-items-center ${className}`}>
      <Col className="text-end" xs={width}>
        <FormFieldLabel color={color}>{item.header}</FormFieldLabel>
      </Col>
    </Row>
  ) : (
    item.header?.label || item.header || null
  );
};

export default FormHeader;
