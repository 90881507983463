import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'common/components/form/inputs/Select';

import { fetchListOptions } from 'store/lists/actions';
import { selectListDefaultOptions } from 'store/lists/selectors';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const PurchasingRequisitionStatusesSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  label,
  placeholder,
  visibleStatuses = []
}) => {
  const dispatch = useDispatch();

  const statuses = useSelector(state =>
    selectListDefaultOptions(state, 'purchasing-requisition-statuses')
  );

  const options = visibleStatuses?.length
    ? statuses.filter(opt => visibleStatuses.includes(opt.label))
    : statuses;

  useEffect(() => {
    dispatch(fetchListOptions('purchasing-requisition-statuses'));
  }, [dispatch]);

  return (
    <Select
      label={label}
      placeholder={placeholder ? placeholder : 'Select value'}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter ? (filter.value && Array.isArray(filter.value) ? filter.value : []) : value}
      options={options}
      isMulti={isMulti}
      onChange={selected =>
        onChange({
          data: selected ? selected : [],
          value: selected ? (isMulti ? selected : selected) : ''
        })
      }
      isClearable
    />
  );
};

PurchasingRequisitionStatusesSelector.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  visibleStatuses: PropTypes.array
};

export default PurchasingRequisitionStatusesSelector;
