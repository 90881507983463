import React from 'react';

import Section from '../../components/Section';

import comments from 'common/assets/svg/common/comments.svg';

const Remarks = ({ contract }) => {
  const { remarks } = contract;

  return (
    <div className="flex-1 with-border d-flex flex-column">
      <Section
        className="with-border contract-remarks"
        header={{ icon: comments, label: 'Remarks' }}
      >
        <span className="white-space-preline">{remarks}</span>
      </Section>
    </div>
  );
};

export default Remarks;
