import { FC } from 'react';

import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';
import send from '@/common/assets/svg/actions/send.svg';

type ReportSubmissionModalProps = {
  isLoe?: boolean;
  isBv?: boolean;
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
};

const ReportSubmissionModal: FC<ReportSubmissionModalProps> = ({
  isLoe = false,
  isBv,
  onAccept,
  onCancel = () => null,
  isOpen
}) => {
  const text = isLoe
    ? 'Are you sure you want to submit LOE? This cannot be undone.'
    : `Are you sure you want to submit ${
        isBv ? 'BV' : 'DNV'
      } annual report? This cannot be undone.`;
  const header = isLoe ? 'Submit LOE' : `Submit ${isBv ? 'BV' : 'DNV'} Annual Report`;

  return (
    <DangerousActionModal
      isOpen={isOpen}
      icon={send}
      onAccept={onAccept}
      onCancel={onCancel}
      header={header}
      cancelButtonText="CANCEL"
      acceptButtonBackground="primary"
      acceptButtonText="SUBMIT"
      svgColor="white"
    >
      {text}
    </DangerousActionModal>
  );
};

export default ReportSubmissionModal;
