import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModuleWrapper from '../components/ModuleWrapper';
import CkEditor from 'common/components/form/inputs/ckeditor';
import CircledButton from 'common/components/buttons/CircledButton';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';

import Loader from './Loader';

import Slider from 'common/components/form/inputs/Slider';

import { strToNumber } from 'common/utils/numbers';
import PersonInfo from 'events/event/modules/module-components/vessel-condition/PersonInfo';
import {
  createVesselCondition,
  getVesselCondition,
  updateVesselCondition
} from 'events/store/event-modules/vessel-condition/actions';
import {
  selectVesselConditionRange,
  selectVesselConditionRemarks
} from 'events/store/event-modules/vessel-condition/selectors';

const VesselCondition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [range, setRange] = useState(0);

  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventId = useSelector(selectEventId);
  const savedRemarks = useSelector(selectVesselConditionRemarks);
  const savedRange = useSelector(selectVesselConditionRange);
  const isTemplate = useSelector(selectIsTemplate);

  const saveHandler = async () => {
    if (!remarks && !range) return;

    try {
      setIsLoading(true);

      const params = {
        id: eventId,
        remarks,
        range
      };

      if (!savedRemarks && !savedRange) {
        await dispatch(createVesselCondition(params)).unwrap();
      } else {
        await dispatch(updateVesselCondition(params)).unwrap();
      }

      await dispatch(getVesselCondition({ id: eventId })).unwrap();

      setEditMode(false);

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const moduleEditModeHandler = action => {
    if (savedRemarks) {
      setRemarks(savedRemarks);
    } else {
      setRemarks('');
    }

    if (savedRange) {
      setRange(strToNumber(savedRange));
    } else {
      setRange(0);
    }

    setEditMode(action);
  };

  useEffect(() => {
    const initialFetch = async () => {
      if (eventId) {
        dispatch(getVesselCondition({ id: eventId })).unwrap();
      }
    };

    initialFetch();
  }, [eventId, dispatch]);

  return (
    <ModuleWrapper className="event-vessel-condition" type="vessel_condition">
      {isLoading ? (
        <Loader />
      ) : !isTemplate ? (
        eventId ? (
          <div
            className={`event-vessel-condition__content  position-relative px-2 border-radius-5 ${
              editMode ? 'edit-content' : ''
            }`}
          >
            {!editMode && (!savedRemarks || !savedRange) ? (
              <div className="d-flex align-items-center justify-content-center text-violet fw-medium fs-12 pt-4 pb-4">
                There are no data at the moment.
              </div>
            ) : (
              <div
                className={`${
                  !editMode ? 'event-vessel-condition__content-preview py-4' : 'my-4'
                }  px-2`}
              >
                <div className="event-vessel-condition__slider mb-4">
                  <div className={'form-label mb-3'}>Overall condition of vessel</div>
                  <Slider
                    disabled={!editMode}
                    renderMark={props => <span {...props}>{props.key}</span>}
                    marks={
                      !editMode
                        ? [0, 10]
                        : [
                            0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5,
                            9, 9.5, 10
                          ]
                    }
                    markClassName="fs-10 text-violet mt-1"
                    onChange={v => setRange(v)}
                    step={0.1}
                    pearling={!editMode}
                    min={0}
                    max={10}
                    defaultValue={0}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    value={!editMode ? savedRange : range}
                  />
                </div>

                <div className="event-vessel-condition__remarks mt-6">
                  <div className={'form-label'}>Remarks</div>
                  {!editMode ? (
                    <div
                      className={`gray-scrollbar event-vessel-condition__remarks-view px-1 py-1`}
                      dangerouslySetInnerHTML={{
                        __html: savedRemarks
                      }}
                    />
                  ) : (
                    <CkEditor
                      placeholder={!editMode ? '' : 'Add some text…'}
                      onChange={v => setRemarks(v)}
                      value={remarks}
                      id="vessel-condition-editor"
                      key="vessel-condition-editor"
                      onFocus={() => {}}
                      disabled={!editMode}
                    />
                  )}
                </div>

                {!editMode ? <PersonInfo /> : null}
              </div>
            )}

            {!isOnBoard && (
              <div className="event-vessel-condition__buttons">
                {!editMode ? (
                  <CircledButton
                    type="edit"
                    svgStyle={{ width: 12, height: 12 }}
                    onClick={() => moduleEditModeHandler(true)}
                  />
                ) : (
                  <InputSaveActions
                    disabled={isLoading || !remarks}
                    onCancel={() => moduleEditModeHandler(false)}
                    onSave={saveHandler}
                  />
                )}
              </div>
            )}
          </div>
        ) : null
      ) : null}
    </ModuleWrapper>
  );
};

export default VesselCondition;
