import { Row } from 'reactstrap';
import _get from 'lodash/get';

import { WorkingHoursType } from 'common/entities/crew/WorkingHoursTypes';
import ShadowBox from 'common/components/general/ShadowBox';

import Field from './Field';

const enums = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'holiday'
];

const DaysAndHoliday = ({ data }) => {
  return (
    <ShadowBox className="w-100p h-100p d-flex flex-column p-1" color="light-1" flat>
      <div className="d-flex align-items-center">
        <div className="cmt-2 border-radius-5 fs-10 fw-bold d-inline-flex border border-moody-blue text-moody-blue px-1">
          Normal working hours per day of week
        </div>
      </div>

      <Row className="w-100p cmt-12 gx-0">
        {enums.map((label, index) => (
          <Field
            label={label}
            key={index}
            defaultValue={_get(data, `default_${label}`)}
            currentValue={_get(data, label)}
          />
        ))}
      </Row>
    </ShadowBox>
  );
};

export default DaysAndHoliday;

DaysAndHoliday.propTypes = {
  data: WorkingHoursType
};
