import React, { useState, useEffect } from 'react';
import _find from 'lodash/find';
import { useSelector } from 'react-redux';

import {
  selectConsumptionFuelGrade,
  selectCaptainReportRobDifferences
} from 'captain-reports/store/selectors';

const HfoLfoDifferenceField = ({ field, subGroupIndex }) => {
  const [robDifferenceValue, setRobDifferenceValue] = useState();
  const robDifferenceData = useSelector(selectCaptainReportRobDifferences);

  const isHfo = field.key.includes('hfo');

  const fuel_grade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );

  const isFoGrade = fuel_grade.category === 'fo';

  useEffect(() => {
    // For bs_difference_hfo/bs_difference_lfo field we send always null and not a value. It's always view only and we get the difference value from the port statement.
    if (robDifferenceData?.length && fuel_grade?.id) {
      setRobDifferenceValue(_find(robDifferenceData, e => e.fuel_grade_id === fuel_grade.id));
    } else {
      setRobDifferenceValue('-');
    }
  }, [robDifferenceData?.length, fuel_grade?.id]);

  return (
    <div className="d-flex justify-content-end fw-bold fs-12 text-violet lh-1 height-32 pt-1">
      {isFoGrade
        ? (isHfo
            ? robDifferenceValue?.rob_difference_hfo
            : robDifferenceValue?.rob_difference_lfo) || '-'
        : null}
    </div>
  );
};

export default HfoLfoDifferenceField;
