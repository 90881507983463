import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import Date from 'captain-reports/components/Date';
import SvgRender from 'common/components/general/SvgRender';
import { Row, Col } from 'reactstrap';

import arrowLeft from 'common/assets/svg/common/arrows/bordered-arrow-left.svg';
import arrowRight from 'common/assets/svg/common/arrows/bordered-arrow-right.svg';
import clock from 'common/assets/svg/common/clock.svg';
import { getTimezoneHoursDiff } from 'common/utils/dates';

import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import { selectData } from 'captain-reports/store/system-running-hours/selectors';

const Utc = ({ tooltip }) => {
  const [timezoneDiff, setTimezoneDiff] = useState(null);

  const data = useSelector(selectData);

  const { avoidRender, tooltipID } = useTooltipID('field-label-reports-gmt');

  const isOnBoard = useSelector(state => state.isOnBoard);

  if (avoidRender) return null;

  return (
    <div className="position-relative mt-2">
      <Row className="fs-10">
        <Col xs="auto">
          {timezoneDiff !== null && !isNaN(timezoneDiff) ? (
            <div
              className={`d-flex align-items-center border-bottom border-white lh-1 cpb-4 cmb-4 ${
                timezoneDiff > 0 ? 'text-violet' : timezoneDiff < 0 ? 'text-orange' : ''
              }`}
            >
              <SvgRender
                src={timezoneDiff === 0 ? clock : timezoneDiff > 0 ? arrowRight : arrowLeft}
                style={{ width: 14, height: 14 }}
              />

              <strong className="ps-1">
                {timezoneDiff === 0 ? (
                  'Same Timezone'
                ) : (
                  <>
                    {getTimezoneHoursDiff(timezoneDiff)}
                    {`${timezoneDiff > 0 ? ' Advanced' : ' Retarted'}`}
                  </>
                )}
              </strong>
            </div>
          ) : null}

          <div className={`d-flex align-items-center ${isOnBoard ? 'text-primary' : 'text-dark'}`}>
            <div id={tooltipID} className="fw-bold me-1">
              UTC:
            </div>
            <Date value={data?.timestamp} withTime={true} timeClassName={'cps-4'} />
            {tooltip ? <Tooltip target={tooltipID}>{tooltip}</Tooltip> : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Utc;
