import React from 'react';
import Fade from 'react-reveal/Fade';

import { useSelector } from 'react-redux';
import { selectCrewProfile } from 'crew/store/profile/selectors';

import ProfileDataBox from 'common/components/layouts/profile-data-box';

const MainInfo = ({ config }) => {
  const profile = useSelector(selectCrewProfile);

  return (
    <Fade>
      <ProfileDataBox profile={profile} sections={config} />
    </Fade>
  );
};

export default MainInfo;
