import { useCallback, useMemo } from 'react';
import _get from 'lodash/get';

import { useActions } from 'utils/hooks';

import ModularTable, { useModularTable } from 'common/components/ModularTable';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { constructColumns } from 'common/components/table/utils/modular-table/helpers';

import {
  selectRefetchTableTrigger,
  selectLatestPeriod
  // selectInventoryDepletionMethod TO DO UNTIL AVERAGE TYPE COME FROM THE RESPONSE
} from 'common/components/mga/store/selectors';

import { selectCurrenciesWithoutBase } from '@/common/components/mga/store/selectors-ts';
import * as tableListActions from 'store/tables/lists/actions';
import { numberToStr } from 'common/utils/numbers';
import { useAppSelector } from '@/store/hooks';

const OverviewTable = ({ setForexRates }) => {
  const latestPeriod = useAppSelector(selectLatestPeriod);
  const [getTableList] = useActions([tableListActions.getTableList]);
  const shouldRefetchTable = useAppSelector(selectRefetchTableTrigger);
  const currencies = useAppSelector(selectCurrenciesWithoutBase);
  //   const depletionMethod = useAppSelector(selectInventoryDepletionMethod); TO DO UNTIL AVERAGE TYPE COME FROM THE RESPONSE

  const fetchData = useCallback(
    async (params, extraRequestParams) => {
      const selectedStore = _get(params, 'filters', []).find(
        f => f.name === 'store_ids' && f.value
      );

      const updatedParams = { ...params, filters: undefined, period_id: latestPeriod.id };

      if (selectedStore) updatedParams.store_ids = selectedStore?.value;

      const additionsRequestParams = { ...updatedParams };

      if (extraRequestParams?.sorting) additionsRequestParams.sorting = extraRequestParams?.sorting;

      const res = await getTableList(additionsRequestParams);

      setForexRates(res?.forex_rates || []);

      return res;
    },
    [latestPeriod?.id]
  );

  const columnsData = useMemo(
    () => [
      {
        header: 'SUPPLIED ON',
        type: 'date',
        key: 'date',
        width: 146,
        totalsCellClassName: `col-1 px-0`,
        sort: true
      },
      {
        header: 'ITEM',
        type: 'string',
        key: 'store',
        width: 584,
        totalsCellClassName: `col-4 px-0`,
        sort: true
      },
      ...currencies.map(cur => ({
        header: (
          <div className="">
            <span className="cme-4">UNIT PRICE</span>
            <span className="cme-4 text-violet">{cur.label}</span>
            <span>(MGA PERIOD START)</span>
          </div>
        ),
        type: 'number',
        key: `price_per_unit_${cur.id}`,
        totalsCellClassName: `col-1 px-0`,
        width: 240
      })),
      {
        header: (
          <div className="">
            <span className="cme-4">UNIT PRICE</span>
            <span className="cme-4 text-violet">USD</span>
            <span>(MGA PERIOD START)</span>
          </div>
        ),
        type: 'number',
        key: 'price_per_unit',
        totalsCellClassName: `col-1 px-0`,
        width: 240,
        sort: true
      },
      {
        header: 'ROB (MGA PERIOD START)',
        type: 'number',
        key: 'opening_quantity',
        total_key: 'opening_quantity',
        totalsCellClassName: `col-1 px-0 text-end`,
        width: 182,
        sort: true
      },
      ...currencies.map(cur => ({
        header: (
          <div className="">
            <span className="cme-4">TOTAL PRICE</span>
            <span className="cme-4 text-violet">{cur.label}</span>
            <span>(MGA PERIOD START)</span>
          </div>
        ),
        key: `total_opening_price_${cur.id}`,
        total_key: `total_opening_price_${cur.id}`,
        totalsCellClassName: `col-1 px-0 text-end`,
        width: 182,
        type: 'price'
      })),
      {
        header: (
          <div className="">
            <span className="cme-4">TOTAL PRICE</span>
            <span className="cme-4 text-violet">USD</span>
            <span>(MGA PERIOD START)</span>
          </div>
        ),
        key: 'total_opening_price',
        total_key: 'total_opening_price',
        totalsCellClassName: `col-1 px-0 text-end`,
        width: 182,
        type: 'price',
        sort: true
      },
      {
        header: 'ROB (MGA PERIOD END)',
        type: 'number',
        key: 'closing_quantity',
        total_key: 'closing_quantity',
        totalsCellClassName: `col-1 px-0 text-end`,
        width: 182,
        sort: true
      },
      ...currencies.map(cur => ({
        header: (
          <div className="">
            <span className="cme-4">TOTAL PRICE</span>
            <span className="cme-4 text-violet">{cur.label}</span>
            <span>(MGA PERIOD END)</span>
          </div>
        ),
        type: 'price',
        total_key: `total_closing_price_${cur.id}`,
        key: `total_closing_price_${cur.id}`,
        totalsCellClassName: `col-1 px-0 text-end`,
        width: 182
      })),
      {
        header: 'TOTAL PRICE (MGA PERIOD END)',
        key: 'total_closing_price',
        total_key: 'total_closing_price',
        totalsCellClassName: `col-1 px-0 text-end`,
        width: 182,
        type: 'price',
        sort: true
      }
    ],
    [currencies]
  );

  const table = useModularTable({
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: true },
    requestTableListFrom: fetchData,
    urlRefetchLevel: 4,
    label: 'mga_inventory_items_overview',
    config: columns => ({
      columns: constructColumns(columnsData, [], 'mga_inventory_items_overview')
    }),
    top: {
      filters: [
        {
          name: 'store_ids',
          operation: '=',
          value: null
        }
      ]
    }
  });

  useUpdateEffect(() => {
    if (shouldRefetchTable) {
      table.refetchData();
    }
  }, [shouldRefetchTable]);

  useUpdateEffect(() => {
    table.refetchData();
  }, [latestPeriod?.id]);

  const handleTableSort = ({ sorting }) => {
    table.refetchData({ sorting }, { fetchOnly: table.label, sorting });
  };

  return (
    <ModularTable
      mainHeaderComponent={
        <div className="text-white bg-primary fs-10 fw-bold rounded-sm px-1 mt-1 cmx-4">
          OVERVIEW
        </div>
      }
      className="cmt-10"
      loader
      hideTableSearch
      hideTopPagination
      hideTableFilters
      handleTableSort={handleTableSort}
      {...table}
      rows={{
        price_per_unit: data => numberToStr(data.price_per_unit, 2, 2),
        ...currencies.reduce(
          (acc, curr) => ({
            ...acc,
            [`price_per_unit_${curr?.id}`]: data => {
              const shouldShowValue = data?.currency_id === curr?.id;
              if (!shouldShowValue) return null;

              return <div>{numberToStr(data?.local_price_per_unit, 2, 2)}</div>;
            },
            [`total_opening_price_${curr?.id}`]: data => {
              const shouldShowValue = data?.currency_id === curr?.id;
              if (!shouldShowValue) return null;

              return <div>{numberToStr(data?.local_total_opening_price, 2, 2)}</div>;
            },
            [`total_closing_price_${curr?.id}`]: data => {
              const shouldShowValue = data?.currency_id === curr?.id;
              if (!shouldShowValue) return null;

              return <div>{numberToStr(data?.local_total_closing_price, 2, 2)}</div>;
            }
          }),
          {}
        ),
        store: data => {
          const description = _get(data, 'store.description');
          const code = _get(data, 'store.code');

          return (
            <div className="d-flex align-items-center">
              {code ? `${code}.` : ''} {description ? description : '-'}
            </div>
          );
        }
      }}
    />
  );
};

export default OverviewTable;
