import SvgRender from 'common/components/general/SvgRender';
import PricePreview from 'common/components/tickets/PricePreview';

import groups from 'common/assets/svg/common/group.svg';

const TicketPrice = ({
  offers,
  participantsCount,
  priceClassName = '',
  hideLabel = false,
  tripIndex,
  showInDollars = false,
  smallView = false
}) => {
  const isBulkOffer = participantsCount > 1;
  const multipleOffers = offers?.length > 1;

  const acceptedOffersTotalAmount = offers.reduce(
    (total, offer) =>
      total +
      Math.round(
        showInDollars ? offer.payable_price_base_currency_equivalent : offer.payable_price
      ),
    0
  );

  const bulkTicketGroupClassName = tripIndex >= 0 ? `bulk-group-index-${tripIndex}` : '';

  return (
    <>
      {offers.length ? (
        <div className="d-flex flex-nowrap align-items-center justify-content-end text-nowrap">
          <PricePreview
            className={`cms-4 px-2 ${priceClassName} ${bulkTicketGroupClassName}`}
            currency={showInDollars ? { symbol: '$' } : offers[0].currency}
            value={
              multipleOffers
                ? acceptedOffersTotalAmount * participantsCount
                : showInDollars
                ? offers[0].payable_price_base_currency_equivalent * participantsCount
                : offers[0].payable_price * participantsCount
            }
            label={
              hideLabel ? null : isBulkOffer ? <span className="fw-normal">&nbsp;total</span> : null
            }
            icon={
              isBulkOffer ? (
                <SvgRender
                  src={groups}
                  style={{ width: 16, height: 16 }}
                  className={`me-1 ${bulkTicketGroupClassName}`}
                />
              ) : null
            }
            smallView={smallView}
          />
          {isBulkOffer ? (
            <PricePreview
              className={`mb-1 cms-6 mb-hd-0 ${priceClassName} ${bulkTicketGroupClassName}`}
              currency={showInDollars ? { symbol: '$' } : offers[0].currency}
              value={
                multipleOffers
                  ? acceptedOffersTotalAmount
                  : showInDollars
                  ? offers[0].payable_price_base_currency_equivalent
                  : offers[0].payable_price
              }
              label={hideLabel ? null : <span className="fw-normal">&nbsp;/&nbsp;person</span>}
              smallView={smallView}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default TicketPrice;
