import React from 'react';
import SvgRender from 'common/components/general/SvgRender';

import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectData, selectId } from 'captain-reports/store/system-running-hours/selectors';
import { useActions } from 'utils/hooks';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';

import * as systemRunningHoursActions from 'store/system-running-hours/actions';
import * as runningHoursActions from 'captain-reports/store/system-running-hours/actions';

import draft from 'common/assets/svg/common/draft.svg';
import save from 'common/assets/svg/actions/save.svg';
import pending from 'captain-reports/assets/svg/report-status/pending.svg';
import approved from 'captain-reports/assets/svg/report-status/approved.svg';
import * as runningHoursReportActions from 'captain-reports/store/system-running-hours/actions';

const ReportActions = ({ setIsLoading, isLoading }) => {
  const [updateRunningHours, setErrorValue, setRunningHoursInputsAreValid] = useActions([
    systemRunningHoursActions.updateRunningHours,
    runningHoursActions.setErrorValue,
    runningHoursReportActions.setRunningHoursInputsAreValid
  ]);

  const reportId = useSelector(selectId);

  const data = useSelector(selectData);

  const status = data?.status;

  const handleSave = async newStatus => {
    if (!data.local_timestamp) {
      setErrorValue({ local_timestamp: REQUIRED_FIELD_MESSAGE });
      return;
    }
    if (!data.timezone) {
      setErrorValue({ timezone: REQUIRED_FIELD_MESSAGE });
      return;
    }

    if (newStatus !== 'draft') {
      if (!setRunningHoursInputsAreValid()) {
        return;
      }
    }

    const params = {
      id: reportId,
      status: newStatus,
      local_timestamp: data.local_timestamp,
      timezone: data.timezone,
      vessel_systems: data.vessel_systems
    };

    if (!params) return;

    try {
      setIsLoading(true);

      await updateRunningHours(params);

      setIsLoading(false);

      // history.push(`${paths.captain_reports}`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  switch (status) {
    case 'draft':
    case 'rebuild':
      return (
        <div className="captain-report-actions">
          <Button
            type="button"
            color="white"
            className={`with-shadow ${
              status !== 'rebuild' ? 'grouped-button' : 'me-1'
            } d-inline-flex align-items-center border-0 height-32`}
            onClick={() => handleSave(status)}
            disabled={isLoading}
          >
            {status === 'rebuild' ? (
              'Save'
            ) : (
              <>
                <SvgRender src={draft} className="me-1" style={{ width: 16, height: 20 }} />
                Save as Draft
              </>
            )}
          </Button>
          <Button
            type="button"
            color="primary"
            className="with-shadow d-inline-flex align-items-center border-0 height-32"
            onClick={() => handleSave('pending')}
            disabled={isLoading}
          >
            <SvgRender src={save} className="me-1" style={{ width: 16, height: 20 }} />
            Save & {status === 'rebuild' ? 'Resend' : 'Send'} to Office
          </Button>
        </div>
      );

    case 'approved':
      return (
        <div className="d-inline-flex align-items-center report-approved-label px-2 height-32">
          <SvgRender src={approved} className="me-1" style={{ width: 20, height: 20 }} />
          <span className="line-height-1">Approved</span>
        </div>
      );

    case 'pending':
      return (
        <div className="text-orange d-inline-flex align-items-center height-32">
          <SvgRender src={pending} className="me-1" style={{ width: 20, height: 20 }} />
          <span className="line-height-1">Pending for approval</span>
        </div>
      );

    default:
      return null;
  }
};

export default ReportActions;
