export const getCurrentVersionLabel = (version = '', prefix = '') => {
  return version.replace(prefix, '').replace(/[.-]hotfix.+$/, '');
};

export const getPartyDisplayName = opt => {
  if (opt.full_name && opt.full_name.length) {
    return opt.full_name;
  } else if (opt.first_name || opt.last_name) {
    return `${opt.first_name} ${opt.last_name}`;
  } else {
    return opt.name || '';
  }
};

export const getAiportOptionLabel = option =>
  !option.name.endsWith(` - ${option.iata_code}`)
    ? `${option.name} - ${option.iata_code}`
    : option.name;

export const getCaptainReportTypeName = cptReportType =>
  (cptReportType || '-').split('_').join(' ');
