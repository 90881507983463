import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import moment from 'moment';
import { dateToLocal } from 'common/utils/dates';

const DueDateChange = props => {
  const { properties, isInPms } = props;

  return (
    <div className={`d-flex align-items-center ${isInPms ? 'cpt-6' : ''}`}>
      <div
        className={`${isInPms ? 'text-violet' : ''} fs-12 text-violet fw-light`}
        data-testid="job-activity-date-before"
      >
        {dateToLocal(moment(properties.old), null, 'DD/MM/YYYY')}
      </div>
      <SvgRender
        className={`${isInPms ? 'text-violet' : 'text-primary'}`}
        src={arrow}
        style={{ width: 32, height: 17 }}
      />
      <div
        className={`${isInPms ? 'text-violet' : ''} fs-12 text-violet fw-light`}
        data-testid="job-activity-date-after"
      >
        {dateToLocal(moment(properties.new), null, 'DD/MM/YYYY')}
      </div>
    </div>
  );
};

export default DueDateChange;
