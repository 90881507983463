import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import airplane from 'common/assets/svg/common/airplane-up.svg';
import paths from 'routing/routes/_paths';
import { stringifyObj } from 'common/utils/urls';

import { selectCrewChangeData } from 'events/store/event-modules/crew-changes/selectors';

import { selectEventVessel } from 'events/store/events/selectors';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { getTicketStatus, getTicketRotations } from './helpers';

const TicketButton = () => {
  const eventVessel = useSelector(selectEventVessel);
  const crewChangeData = useSelector(selectCrewChangeData);

  const statuses = useSelector(state => selectListOptionsFromStore(state, 'ticketing-statuses'));

  const offSignerRotationsIds = useMemo(
    () => getTicketRotations('off_signer', crewChangeData)?.map(s => s.off_signer_id),
    [crewChangeData]
  );
  const onSignerRotationsIds = useMemo(
    () => getTicketRotations('on_signer', crewChangeData)?.map(s => s.on_signer_id),
    [crewChangeData]
  );

  const ticketingStatus = useMemo(
    () =>
      crewChangeData[0]?.accepted_offers_count
        ? getTicketStatus(statuses, 'ticket-issued')
        : crewChangeData[0]?.pending_offers_count
        ? getTicketStatus(statuses, 'ticket-pending')
        : getTicketStatus(statuses, 'no-ticket-needed'),
    [crewChangeData, statuses]
  );

  return (
    <Button
      color="primary"
      className="d-flex align-items-center justify-content-center fs-12 fw-medium border-radius-5 cpe-12 cps-12 cpt-4 cpb-4"
      onClick={() =>
        window.open(
          `${paths.ticketing}/crew?${stringifyObj({
            filters: [
              { name: 'vessel_id', operation: 'oneOf', value: [eventVessel?.id] },
              {
                name: 'ticketing_status_id',
                operation: 'oneOf',
                value: [ticketingStatus]
              },
              {
                name: 'crew_member_id',
                operation: 'oneOf',
                value: [...offSignerRotationsIds, ...onSignerRotationsIds]
              }
            ]
          })}`,
          '_blank'
        )
      }
    >
      <SvgRender src={airplane} className="text-white me-1" style={{ width: 16, height: 16 }} />
      View tickets
    </Button>
  );
};

export default TicketButton;
