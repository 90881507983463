import TYPES from './types';
import { getWarnings } from 'crew/utils/helpers';

export const INITIAL_STATE = {
  shiftTypes: [],
  shifts: [],
  selectedShiftType: {},
  activeShift: {},
  activeShiftId: null,
  activeShiftComments: {},
  startDate: null,
  endDate: null,
  isLoading: false,
  periodType: 'daily',
  selectedRankFilter: null,
  selectedVesselFilter: null,
  selectedSorting: 'rank_index',
  warnings: {}, // { CREW_ID: { YYYY-MM-DD: [], YYYY-MM-DD: [] } }
  highlightedSeaman: null,
  selectedContract: null,
  shiftsPortSeaState: null,
  watchkeepingStatistics: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_IS_LOADING:
      return { ...state, isLoading: payload };

    case TYPES.SET_SHIFT_TYPES:
      return { ...state, shiftTypes: payload, selectedShiftType: payload[0] };

    case TYPES.SET_SHIFTS:
      return { ...state, shifts: payload, warnings: getWarnings(payload) };

    case TYPES.SET_PERIOD_TYPE:
      return { ...state, periodType: payload };

    case TYPES.SET_SELECTED_SORTING:
      return { ...state, selectedSorting: payload };

    case TYPES.SET_HIGHLIGHTED_SEAMAN:
      return { ...state, highlightedSeaman: payload };

    case TYPES.SET_SELECTED_CONTRACT:
      return { ...state, selectedContract: payload };

    case TYPES.SET_SHIFTS_PORT_SEA_STATE:
      return { ...state, shiftsPortSeaState: payload };

    case TYPES.SET_SHIFT:
      const newShifts = state.shifts.map(shift => {
        if (shift.id === payload.id)
          return {
            ...shift,
            dates: payload.dates,
            avg_hours_daily: payload.avg_hours_daily,
            avg_hours_weekly: payload.avg_hours_weekly
          };

        return shift;
      });

      return {
        ...state,
        shifts: newShifts,
        warnings: getWarnings(newShifts)
      };

    case TYPES.SET_ACTIVE_SHIFT_COMMENT:
      return {
        ...state,
        activeShiftComments: {
          ...state.activeShiftComments,
          [payload.id]: {
            ...state.activeShiftComments[payload.id],
            [payload.date]: payload.comment
          }
        }
      };

    case TYPES.SET_SELECTED_SHIFT_TYPE:
      return { ...state, selectedShiftType: payload };

    case TYPES.SET_ACTIVE_SHIFT_ID:
      return { ...state, activeShiftId: payload };

    case TYPES.SET_INTERNAL_SHIFT:
      return {
        ...state,
        activeShift: {
          ...state.activeShift,
          [payload.id]: { ...state.activeShift[payload.id], [payload.date]: payload.shifts }
        }
      };

    case TYPES.SET_START_DATE:
      return { ...state, startDate: payload };

    case TYPES.SET_END_DATE:
      return { ...state, endDate: payload };

    case TYPES.SET_SELECTED_RANK_FILTER:
      return { ...state, selectedRankFilter: payload };

    case TYPES.SET_SELECTED_VESSEL_FILTER:
      return { ...state, selectedVesselFilter: payload };

    case TYPES.CLEAR_SHIFTS_STATE:
      return INITIAL_STATE;

    case TYPES.CLEAR_INTERNAL_TABLE:
      return {
        ...state,
        activeShift: {},
        activeShiftId: null,
        activeShiftComments: {},
        warnings: getWarnings(state.shifts)
      };

    case TYPES.SET_SHIFT_WARNINGS:
      return {
        ...state,
        warnings: {
          ...state.warnings,
          [payload.crewID]: payload.warnings
        }
      };

    case TYPES.GET_WATCHKEEPING_STATISTICS.SUCCESS:
      return { ...state, watchkeepingStatistics: payload };

    default:
      return state;
  }
};
export default reducer;
