import { Button } from 'reactstrap';
import ItineraryTypeIcon from 'common/components/crew/ItineraryTypeIconTypeIcon';

export const types = [
  { value: 'to_go', label: 'To go' },
  { value: 'to_return', label: 'To return' },
  { value: 'round_trip', label: 'Round trip' }
];

const ItineraryType = ({ itineraryType, selectField, previewMode, disabledMode }) => {
  return (
    <div className={`${!previewMode ? 'border-start  ps-2' : null} `}>
      <div
        className={`d-flex align-items-center ${
          !disabledMode && !previewMode ? 'justify-content-between' : ''
        }`}
      >
        <div>
          {types.map(t => {
            if (previewMode) {
              if (itineraryType === t.value) {
                return (
                  <div className="ticket-type-button active text-nowrap" key={t.value}>
                    <ItineraryTypeIcon type={t.value} />
                    <span className="fs-12 fw-medium lh-1 ms-1">{t.label}</span>
                  </div>
                );
              } else {
                return null;
              }
            }

            return (
              <Button
                className={`ticket-type-button ${itineraryType === t.value ? 'active' : ''}`}
                type="button"
                color="link"
                key={t.value}
                onClick={() => selectField('itinerary_type')(t.value)}
              >
                <ItineraryTypeIcon type={t.value} />
                <span className="fs-12 fw-medium lh-1 ms-1">{t.label}</span>
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ItineraryType;
