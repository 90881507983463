import { Row, Col } from 'reactstrap';
import { useFormState } from 'utils/hooks';
import { useCallback, useEffect, useState } from 'react';
import { refetchAsyncOptions } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { selectVesselFilter } from 'common/components/mga/store/selectors';

import DateInput from 'common/components/form/inputs/date';
import DateWithIcon from 'common/components/dates/DateWithIcon';
import InitialBalnces from './InitialBalances';
import moment from 'moment';

const Body = ({ formState, disabledStartedAt, initialPeriod, loadValues, className = '' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { fields, selectField, subStates } = useFormState(formState);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const vesselFilter = useSelector(selectVesselFilter);

  const getInitialAccounts = useCallback(async () => {
    if (isInitialized || !fields.started_at.value || !fields.ended_at.value) return;

    setIsLoading(true);
    let options = [];

    try {
      const started_at = fields.started_at.value.clone().format('YYYY-MM-DD');
      const ended_at = fields.ended_at.value.clone().format('YYYY-MM-DD');

      const requestParams = {
        crew_from: started_at,
        crew_to: ended_at,
        show_account_page_onboard: true
      };

      if (!isOnBoard && vesselFilter) requestParams.vessel_id = vesselFilter;

      const accounts = (await refetchAsyncOptions('mga-accounts', requestParams)) || [];
      const crew_accounts = accounts.filter(a => a.crew_member_id);

      accounts
        .filter(a => !a.crew_member_id)
        .forEach(account => {
          options.push({ account, amount: '', is_crew: false });

          if (account.is_parent_of_crew_accounts) {
            options = [
              ...options,
              ...crew_accounts.map(crew_account => ({
                account: crew_account,
                amount: '',
                is_crew: true
              }))
            ];
          }
        });

      loadValues({ started_at, ended_at, initial_balances: options });

      setIsLoading(false);
      setIsInitialized(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [
    fields.started_at.value,
    fields.ended_at.value,
    isOnBoard,
    loadValues,
    vesselFilter,
    isInitialized
  ]);

  useEffect(() => {
    if (initialPeriod) {
      getInitialAccounts();
    }
  }, [initialPeriod, getInitialAccounts]);

  return (
    <>
      <Row className={`align-items-center mb-4 ${className}`}>
        <Col xs={'auto'}>
          {disabledStartedAt ? (
            <>
              <div className="form-label mb-1">STARTS</div>
              <DateWithIcon
                className="flex-row-reverse text-primary fw-medium fs-12"
                svgClassName="me-1"
                date={fields.started_at.value}
              />
            </>
          ) : (
            <DateInput
              label="STARTS"
              onChange={selectField('started_at')}
              className="mb-0"
              isOutsideRange={
                fields.ended_at.value
                  ? date =>
                      moment(date).isAfter(moment(fields.ended_at.value).format('YYYY-MM-DD 00:00'))
                  : () => false
              }
              {...fields.started_at}
            />
          )}
        </Col>
        <Col xs={'auto'}>
          <div className="px-3 pt-2 text-primary fw-bold">-</div>
        </Col>
        <Col xs={'auto'}>
          <DateInput
            label="ENDS"
            onChange={selectField('ended_at')}
            initialVisibleMonth={disabledStartedAt ? moment(fields.started_at.value) : null}
            isOutsideRange={
              fields.started_at.value
                ? date =>
                    moment(date).isBefore(
                      moment(fields.started_at.value).format('YYYY-MM-DD 00:00')
                    )
                : () => false
            }
            className="mb-0"
            {...fields.ended_at}
          />
        </Col>
      </Row>
      {initialPeriod && fields.started_at.value && fields.ended_at.value ? (
        <InitialBalnces
          startedAt={fields.started_at.value}
          isLoading={isLoading}
          subStates={subStates}
        />
      ) : null}
    </>
  );
};

export default Body;
