import CommentsPopover from 'common/components/popovers/CommentsPopover';
import { useSelector } from 'react-redux';
import { selectJobDueDateRemarks } from 'common/components/jobs/_base/store/selectors';

const DueDateInputRemarksPopover = () => {
  const jobDueDateRemarks = useSelector(selectJobDueDateRemarks);

  return jobDueDateRemarks ? (
    <CommentsPopover
      className="d-flex justify-content-center align-items-center p-0 h-16"
      popoverTitle="Remarks"
      comments={jobDueDateRemarks}
      popoverProps={{ placement: 'right' }}
    />
  ) : null;
};

export default DueDateInputRemarksPopover;
