import React from 'react';
import SvgRender from 'common/components/general/SvgRender';

import approved from 'captain-reports/assets/svg/report-status/approved.svg';
import draft from 'common/assets/svg/common/draft.svg';
import pending from 'captain-reports/assets/svg/report-status/pending.svg';
import reopened from 'captain-reports/assets/svg/report-status/reopened.svg';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import PropTypes from 'prop-types';

const statusMap = {
  approved: 'Approved',
  pending: 'Pending',
  draft: 'Draft',
  rebuild: 'For Correction'
};

export const ReportStatus = ({ status, className = '', target, showTooltip = true }) => {
  const icon = { approved, pending, draft, rebuild: reopened };
  const { avoidRender, tooltipID } = useTooltipID(`${target}`);

  if (avoidRender) return null;

  return (
    <>
      <SvgRender
        id={tooltipID}
        className={`report-status status-${status} ${className} cursor-pointer`}
        src={icon[status]}
        style={{ width: 16, height: 16 }}
      />
      {showTooltip && tooltipID && <Tooltip target={tooltipID}>{statusMap[status]}</Tooltip>}
    </>
  );
};

ReportStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
  showTooltip: PropTypes.bool
};

export default ReportStatus;
