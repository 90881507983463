import React from 'react';

import PropTypes from 'prop-types';
import { Button, UncontrolledTooltip } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import view from 'common/assets/svg/actions/view.svg';
import { useDrawer } from 'common/components/drawer';
import Form from './Form';
import { useSelector } from 'react-redux';
import { selectEventWatchers } from 'events/store/events/selectors';

const Watchers = ({ className = '', svgStyle = { width: 26, height: 26 } }) => {
  const drawer = useDrawer();

  const eventWatchers = useSelector(selectEventWatchers);
  const isOnBoard = useSelector(state => state.isOnBoard);

  if (isOnBoard) return null;

  return (
    <div className={`job-watchers ${className}`} id={`single-event-watch`}>
      <Button
        color="link"
        type="button"
        className={`d-inline-flex align-items-center justify-content-center${
          eventWatchers.length ? ' active' : ''
        }`}
        onClick={() => drawer.open()}
      >
        <SvgRender src={view} style={svgStyle} />
        {eventWatchers.length ? (
          <span className="fs-12 text-primary fw-medium ms-1">{eventWatchers.length}</span>
        ) : null}
      </Button>

      <UncontrolledTooltip
        placement="top"
        popperClassName="fs-10 job-watchers-tooltip "
        target={`single-event-watch`}
        boundariesElement="window"
      >
        Event Watchers
      </UncontrolledTooltip>

      <Form drawer={drawer} />
    </div>
  );
};

export default Watchers;

Watchers.propTypes = {
  className: PropTypes.string,
  svgStyle: PropTypes.object
};
