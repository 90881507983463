import ShadowBox from 'common/components/general/ShadowBox';
import ColoredLabel from 'common/components/labels/ColoredLabel';
import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';

import check from 'common/assets/svg/actions/check.svg';

const StepWrapper = ({ title, index, children, substeps, steps, collapsable = false }) => {
  const [isOpen, setIsOpen] = useState(collapsable ? false : true);

  const isStepApproved = useMemo(
    () => collapsable && substeps?.length === substeps?.filter(s => s.completed_by)?.length,
    [collapsable, substeps]
  );

  useEffect(() => {
    if (collapsable && steps?.findIndex(st => !st?.substeps.every(s => s.completed_by)) === index) {
      // Find and open the first uncompleted step
      setIsOpen(true);
    }
  }, []);

  return (
    <ShadowBox
      className={`${collapsable ? 'cpy-4 cpx-12 cmb-4' : 'cp-12 cmb-12'}`}
      color="light-2"
      flat
    >
      <div
        className={`review-process-step__header d-flex align-items-center ${
          collapsable ? 'pointer' : ''
        }`}
        onClick={collapsable ? () => setIsOpen(!isOpen) : null}
      >
        <ColoredLabel text={`STEP ${index + 1}`} index={index} />
        <div className="fw-bold fs-12 lh-1 ps-2 text-primary">{title}</div>
        {isStepApproved && (
          <ColoredLabel
            text={
              <>
                <SvgRender src={check} style={{ width: 12, height: 12 }} className="me-1" />
                Approved
              </>
            }
            className="fs-12 height-20 py-0 fw-medium rounded-xl ms-auto"
            color="green"
          />
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="pt-2">{children}</div>
      </Collapse>
    </ShadowBox>
  );
};

export default StepWrapper;
