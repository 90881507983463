import React, { useEffect, useState } from 'react';

import remarksIcon from 'common/assets/svg/common/comments-solid.svg';
import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';
import ColoredLabel from 'common/components/labels/ColoredLabel';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

import PropTypes from 'prop-types';
import RemarksReviewTooltipContent from 'common/components/forms/_components/RemarksReviewTooltipContent';
import RemarksCorrectionTooltipContent from 'common/components/forms/_components/RemarksCorrectionTooltipContent';
import { getRemarksCounter } from 'common/components/forms/helpers';

const ActionableRemarksLabel = ({
  reviewRemarks,
  reviewRemarksActionable,
  forCorrectionRemarks,
  forCorrectionRemarksActionable,
  className = 'me-1',
  isForRegularRemarks = false
}) => {
  const [sum, setSum] = useState(0);
  const isOnboard = useSelector(state => state.isOnBoard);
  const { avoidRender, tooltipID } = useTooltipID('actionable-remarks-label');

  useEffect(
    () =>
      setSum(
        getRemarksCounter(
          forCorrectionRemarks,
          reviewRemarks,
          reviewRemarksActionable,
          forCorrectionRemarksActionable,
          isOnboard,
          isForRegularRemarks
        )
      ),
    [
      forCorrectionRemarks,
      reviewRemarks,
      isOnboard,
      isForRegularRemarks,
      reviewRemarksActionable,
      forCorrectionRemarksActionable
    ]
  );

  if (!sum || avoidRender) return null;

  return (
    <>
      <ColoredLabel
        id={tooltipID}
        key={`form-remarks-${isForRegularRemarks}`}
        className={`border-radius-7 cpx-6 py-0 height-16 cursor-pointer ${className}`}
        color={isForRegularRemarks ? 'moody-blue' : 'coral'}
        text={
          <>
            <span className="text-white fs-10 fw-bold cme-4">{sum}</span>
            <SvgRender src={remarksIcon} className="text-white" style={{ width: 10, height: 10 }} />
          </>
        }
        withShadow
      />
      <Tooltip target={tooltipID} placement="top" innerClassName="max-width-412 p-1">
        <div className="text-start fs-12">
          {isForRegularRemarks && !isOnboard ? (
            reviewRemarks && !reviewRemarksActionable ? (
              <RemarksReviewTooltipContent
                reviewRemarks={reviewRemarks}
                reviewRemarksActionable={reviewRemarksActionable}
              />
            ) : null
          ) : reviewRemarks && reviewRemarksActionable && !isOnboard ? (
            <RemarksReviewTooltipContent
              reviewRemarks={reviewRemarks}
              reviewRemarksActionable={reviewRemarksActionable}
            />
          ) : null}

          {isForRegularRemarks ? (
            forCorrectionRemarks && !forCorrectionRemarksActionable ? (
              <RemarksCorrectionTooltipContent
                forCorrectionRemarks={forCorrectionRemarks}
                forCorrectionRemarksActionable={forCorrectionRemarksActionable}
                isOnboard={isOnboard}
                sum={sum}
              />
            ) : null
          ) : forCorrectionRemarks && forCorrectionRemarksActionable ? (
            <RemarksCorrectionTooltipContent
              forCorrectionRemarks={forCorrectionRemarks}
              forCorrectionRemarksActionable={forCorrectionRemarksActionable}
              isOnboard={isOnboard}
              sum={sum}
            />
          ) : null}
        </div>
      </Tooltip>
    </>
  );
};

ActionableRemarksLabel.propTypes = {
  reviewRemarks: PropTypes.string,
  reviewRemarksActionable: PropTypes.bool,
  forCorrectionRemarks: PropTypes.string,
  forCorrectionRemarksActionable: PropTypes.bool,
  className: PropTypes.string,
  isForRegularRemarks: PropTypes.bool
};

export default ActionableRemarksLabel;
