import { useSelector } from 'react-redux';
import {
  cannotEditManual,
  selectIsParentChapterDeleted,
  canDeleteChapter,
  selectIsComparedAtVersion
} from 'manuals/store/selectors';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useActions } from 'utils/hooks';

import SvgRender from 'common/components/general/SvgRender';
import * as manualsActions from 'manuals/store/actions';

import threeDots from 'common/assets/svg/actions/three-dots.svg';

export const CannotDeleteMessage = () =>
  'You cannot delete this chapter as it has subchapters with changes.';

const ChapterActions = ({ chapter }) => {
  const [setEdittingChapter, setAddingSubchapterTo, setChapterModalAction] = useActions([
    manualsActions.setEdittingChapter,
    manualsActions.setAddingSubchapterTo,
    manualsActions.setChapterModalAction
  ]);
  const isLocked = useSelector(cannotEditManual);
  const isComparedAtVersion = useSelector(selectIsComparedAtVersion);

  const isParentChapterDeleted = useSelector(state =>
    selectIsParentChapterDeleted(state, chapter.uid)
  );
  const isChapterDeleted = chapter.deleted;

  const isDeleteEnabled = useSelector(state => canDeleteChapter(state, chapter.uid)).valid;

  return !isLocked && !isChapterDeleted && !isParentChapterDeleted && !isComparedAtVersion ? (
    <UncontrolledDropdown direction="left">
      <DropdownToggle color="link" className="p-0 lh-1 me-1 ms-1">
        <SvgRender src={threeDots} style={{ height: 12, width: 12 }} />
      </DropdownToggle>
      <DropdownMenu modifiers={[{ name: 'preventOverflow' }]} strategy="fixed">
        <DropdownItem
          onClick={() =>
            setChapterModalAction({
              id: chapter.id,
              uid: chapter.uid,
              isOpen: true,
              type: 'rearrange'
            })
          }
        >
          Rearrange
        </DropdownItem>
        <DropdownItem onClick={() => setEdittingChapter({ [chapter.uid]: true })}>
          Rename
        </DropdownItem>
        <DropdownItem
          onClick={() =>
            setChapterModalAction({
              id: chapter.id,
              uid: chapter.uid,
              isOpen: true,
              type: 'move'
            })
          }
        >
          Move
        </DropdownItem>
        <DropdownItem onClick={() => setAddingSubchapterTo({ [chapter.uid]: true })}>
          Add sub-chapter
        </DropdownItem>

        {isDeleteEnabled ? (
          <DropdownItem
            onClick={() =>
              isDeleteEnabled
                ? setChapterModalAction({
                    id: chapter.id,
                    uid: chapter.uid,
                    isOpen: true,
                    type: 'delete'
                  })
                : null
            }
          >
            Delete
          </DropdownItem>
        ) : (
          <>
            <DropdownItem divider />
            <DropdownItem tag="div" className="fs-10 lh-12 pt-0 px-1 text-red" text>
              <CannotDeleteMessage />
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <div className="chapters-tree__view-noactions"></div>
  );
};

export default ChapterActions;
