import ShadowBox from 'common/components/general/ShadowBox';
import FormSubmissionsSelector from 'common/components/selectors/FormSubmissionsSelector';
import { Col, Row } from 'reactstrap';
import { Option, SingleValue } from 'common/components/selectors/_formSubmissionsSelectComponent';
import FormsSelector from 'common/components/selectors/FormsSelector';
import DateInput, { mode } from 'common/components/form/inputs/date';
import moment from 'moment';
import FormStatusSelector from 'common/components/selectors/FormStatusSelector';

import React from 'react';
import PropTypes from 'prop-types';

const LinkFormFilters = ({
  excludeFormIds = [],
  selectedForm,
  setSelectedForm,
  selectedDates,
  setSelectedDates,
  selectedStatuses,
  setSelectedStatuses,
  selectedFormSubmission,
  setSelectedFormSubmission,
  submissionListParams,
  formsListParams
}) => {
  return (
    <ShadowBox flat className="px-3 pt-2 pb-2 mb-1">
      <Row noGutters>
        <Col xs={5}>
          <FormsSelector
            isMulti={false}
            label="FILTER FORM TYPE"
            className="mb-2"
            placeholder="Select form(s)"
            value={selectedForm}
            onChange={(_, selected) => setSelectedForm(selected)}
            filterOption={opt => !excludeFormIds.includes(opt.value)}
            listParams={formsListParams}
            isClearable
          />
        </Col>
        <Col xs="auto" className="cps-12">
          <DateInput
            label="FITLER DATE"
            activeMode={mode.range.date}
            onChange={({ from, to }) => setSelectedDates([from, to])}
            from={selectedDates[0] ? moment(selectedDates[0]) : null}
            to={selectedDates[1] ? moment(selectedDates[1]) : null}
          />
        </Col>
        <Col xs={2} className="cps-12">
          <FormStatusSelector
            isMulti
            label="FILTER STATUS"
            filter={{ value: selectedStatuses }}
            onChange={({ value }) => setSelectedStatuses(value)}
          />
        </Col>
      </Row>

      <Col className="ps-0 pe-4" xs={10}>
        <FormSubmissionsSelector
          label="Form"
          onChange={form_submission => setSelectedFormSubmission(form_submission)}
          value={selectedFormSubmission}
          isClearable={true}
          components={{ Option, SingleValue }}
          listParams={submissionListParams}
          defaultOptionsTriggerChange={submissionListParams}
        />
      </Col>
    </ShadowBox>
  );
};

LinkFormFilters.propTypes = {
  excludeFormIds: PropTypes.array,
  selectedForm: PropTypes.object,
  setSelectedForm: PropTypes.func,
  selectedDates: PropTypes.array,
  setSelectedDates: PropTypes.func,
  selectedStatuses: PropTypes.array,
  setSelectedStatuses: PropTypes.func,
  selectedFormSubmission: PropTypes.object,
  setSelectedFormSubmission: PropTypes.func,
  submissionListParams: PropTypes.object,
  formsListParams: PropTypes.object
};

export default LinkFormFilters;
