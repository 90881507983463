import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import { selectJobPeriodicityDescription } from 'common/components/pms/jobs/store/selectors';

const JobPeriodicity = () => {
  const periodicity = useSelector(selectJobPeriodicityDescription);
  const periodicityValue = periodicity || '-';

  return (
    <div className="d-flex align-items-center fs-12 height-32">
      <Label className="form-label mb-0">Periodicity:</Label>
      <div className="cms-4 fw-bold text-primary min-width-fit">{periodicityValue}</div>
    </div>
  );
};

export default JobPeriodicity;
