import React, { useState } from 'react';
import { func } from 'prop-types';

import { moduleIcons } from '../config';
import {
  selectActiveModulesLabels,
  selectEventModules,
  selectIsCreate,
  selectIsTemplate,
  selectisEditingModules
} from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeModule,
  reorderModules,
  setActiveModulesLabels,
  updateModule
} from 'events/store/events/actions';

import Module from './Module';

const ModuleSlider = ({ toggleModal, setDeleting, setLabelToDelete, left }) => {
  const [dragged, setDragged] = useState();

  const dispatch = useDispatch();

  const modules = useSelector(selectEventModules);
  const activeModulesLabels = useSelector(selectActiveModulesLabels);
  const isEditingModules = useSelector(selectisEditingModules);
  const isCreate = useSelector(selectIsCreate);
  const isTemplate = useSelector(selectIsTemplate);

  const onModulePress = label => {
    if (!isEditingModules) {
      dispatch(setActiveModulesLabels(label));

      return;
    }

    if (isEditingModules && isCreate) {
      const module = modules.find(el => el.type.label === label);

      if (!module) {
        return;
      }

      setDeleting(true);
      dispatch(
        removeModule({
          id: module.id,
          isCreate,
          labelToDelete: label
        })
      );
    } else {
      toggleModal();
      setLabelToDelete(label);
    }
  };

  const handleModuleReorder = (oldIndex, newIndex, moduleId) => {
    dispatch(reorderModules(oldIndex, newIndex));

    if (isTemplate || isCreate) return;

    dispatch(updateModule({ id: moduleId, sort_index: newIndex }));
  };

  if (!modules?.length)
    return (
      <div className="event-toolbar__area-empty d-flex align-items-center justify-content-center text-primary">
        +
      </div>
    );

  return (
    <div
      style={{ left: `${left}%` }}
      className="event-toolbar__slider-container-slides d-flex align-items-center cpb-4"
    >
      {modules
        .filter(el => moduleIcons[el.type.label])
        .map((module, i) => {
          const moduleLabel = module.type.label;
          const isActive = activeModulesLabels?.includes(moduleLabel);
          const icon = isEditingModules
            ? moduleIcons[moduleLabel].icon
            : moduleIcons[moduleLabel].iconWithLetter;

          return (
            <Module
              key={i}
              index={i}
              module={module}
              isActive={isActive}
              isEditingModules={isEditingModules}
              dragged={dragged}
              icon={icon}
              onModulePress={onModulePress}
              setDragged={setDragged}
              handleModuleReorder={handleModuleReorder}
            />
          );
        })}
    </div>
  );
};

export default ModuleSlider;

ModuleSlider.propTypes = {
  toggleModal: func.isRequired,
  setDeleting: func.isRequired,
  setLabelToDelete: func.isRequired
};
