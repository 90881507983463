import moment from 'moment';
import numeral from 'numeral';
import { strToNumber } from 'common/utils/numbers';

export const autocompleteTrim = (aft, fwd) => aft - fwd;

export const autocompleteStoppageDuration = (start, end) => {
  if (start && end) {
    let startMom = moment(start);
    let endMom = moment(end);
    const minutes = endMom.diff(startMom, 'minutes');
    return minutes;
  }
};

export const autocompleteStoppageConsumptions = (start, end) => {
  return start - end;
};

export const autocompleteRelativeWeatherCurrent = (relativeDir, speed, current) => {
  if ((!relativeDir && relativeDir !== 0) || !speed || !current) return null;
  const angle = Math.abs(relativeDir);

  const result = Math.sqrt(
    Math.pow(speed, 2) +
      Math.pow(current, 2) +
      2 * (speed * current) * Math.cos((angle * Math.PI) / 180).toFixed(15)
  );

  return result;
};

export const autocompleteTotalCo = (co1, co2, co3) => {
  if (!co1 && !co2 && !co3) {
    return null;
  }
  let num1 = co1 ? co1 : 0;
  let num2 = co2 ? co2 : 0;
  let num3 = co3 ? co3 : 0;

  return num1 + num2 + num3;
};

export const autocompleteFreshTotalConsumed = (v1, v2, v3, v4, v5) => {
  let var1 = v1 ? v1 : 0;
  let var2 = v2 ? v2 : 0;
  let var3 = v3 ? v3 : 0;
  let var4 = v4 ? v4 : 0;
  let var5 = v5 ? v5 : 0;

  return var1 + var2 + var3 + var4 + var5;
};

export const autocompleteFreshTotalRob = (totalAdded, totalConsumed, previousRob, currentValue) => {
  let added = totalAdded ? +totalAdded : 0;
  let consumed = totalConsumed ? +totalConsumed : 0;
  let prevRob = previousRob ? +previousRob : 0;

  if (!prevRob) {
    return currentValue;
  }

  return +prevRob + +added - +consumed;
};

export const autocompleteTotalSludgeProduced = (v1, v2) => {
  let var1 = v1 ? v1 : 0;
  let var2 = v2 ? v2 : 0;

  return var1 + var2;
};

export const autocompleteTotalSludgeConsumed = (v1, v2, v3) => {
  let var1 = v1 ? v1 : 0;
  let var2 = v2 ? v2 : 0;
  let var3 = v3 ? v3 : 0;

  return var1 + var2 + var3;
};

export const autocompleteSludgeTotalRob = (
  totalProduced,
  totalRemoved,
  previousRob,
  currentValue
) => {
  let produced = totalProduced ? totalProduced : 0;
  let removed = totalRemoved ? totalRemoved : 0;
  let previous = previousRob ? +previousRob : 0;
  let current = currentValue ? +currentValue : 0;

  if (!previousRob) {
    return current;
  }

  return previous + produced - removed;
};

export const autocompleteLegTotalConsumedRate = (
  { totals, steamingTime, timestamp, value },
  type
) => {
  const minutes = ['noon', 'arrival'].includes(type)
    ? +steamingTime + +totals?.steaming_time
    : totals?.leg_start_timestamp && timestamp
    ? moment(timestamp).diff(totals.leg_start_timestamp, 'minutes')
    : 0;

  if (+minutes) {
    const res = +value * ((24 * 60) / minutes);

    return res;
  }

  return null;
};

export const autocompleteGoLegTotalConsumedRate = ({ generatorsLegRunningHoursTotal, value }) => {
  if (!generatorsLegRunningHoursTotal) {
    return null;
  }

  const generatorsLegRunningHours = generatorsLegRunningHoursTotal / 60;
  const total = (+value * 24) / generatorsLegRunningHours;

  return total;
};

export const autocompleteLubricantsSoTotalRob = (val1, val2) => {
  let value1 = val1 ? val1 : 0;
  let value2 = val2 ? val2 : 0;

  return +value1 + +value2;
};

export const autocompleteSoConsumption = (val1, val2, val3, val4) => {
  let so_rob = val1 ? val1 : 0;
  let so_supplied = val2 ? val2 : 0;
  let previous_rob = val3 ? val3 : 0;
  let currentValue = val4 ? val4 : 0;

  if (!previous_rob || previous_rob == 0) {
    return currentValue;
  }

  if ((!so_supplied || so_supplied == 0) && (!so_rob || so_rob == 0)) {
    return null;
  }

  return +previous_rob + +so_supplied - +so_rob;
};

export const autocompleteGarbageTotalRob = (
  garbage_rob_food_wastes,
  garbage_rob_plastic,
  garbage_rob_domestic_wastes,
  garbage_rob_cooking_oil,
  garbage_rob_incinerator_ashes,
  garbage_rob_operational_wastes,
  garbage_rob_cargo_residues
) => {
  let food_wastes = garbage_rob_food_wastes ? garbage_rob_food_wastes : 0;
  let plastic = garbage_rob_plastic ? garbage_rob_plastic : 0;
  let domestic_wastes = garbage_rob_domestic_wastes ? garbage_rob_domestic_wastes : 0;
  let cooking_oil = garbage_rob_cooking_oil ? garbage_rob_cooking_oil : 0;
  let incinerator_ashes = garbage_rob_incinerator_ashes ? garbage_rob_incinerator_ashes : 0;
  let operational_wastes = garbage_rob_operational_wastes ? garbage_rob_operational_wastes : 0;
  let cargo_residues = garbage_rob_cargo_residues ? garbage_rob_cargo_residues : 0;

  return (
    +food_wastes +
    +plastic +
    +domestic_wastes +
    +cooking_oil +
    +incinerator_ashes +
    +operational_wastes +
    +cargo_residues
  );
};

export const autocompleteCorrectionTotal = (val1, val2) => {
  if (!val1 && !val2) {
    return null;
  }

  const hfo = val1 ? val1 : 0;
  const lfo = val2 ? val2 : 0;

  return hfo + lfo;
};

export const autocompleteDgConsumptionTotal = (
  dg_1_consumption_total,
  dg_2_consumption_total,
  dg_3_consumption_total,
  dg_4_consumption_total,
  dg_5_consumption_total,
  dg_6_consumption_total
) => {
  const fieldsArr = [
    dg_1_consumption_total,
    dg_2_consumption_total,
    dg_3_consumption_total,
    dg_4_consumption_total,
    dg_5_consumption_total,
    dg_6_consumption_total
  ];
  const total = fieldsArr.reduce((acc, cur) => {
    const number = numeral(acc);
    return number.add(strToNumber(cur) || 0);
  }, 0)._value;

  if (fieldsArr.every(el => el === null)) {
    return null;
  } else {
    return total;
  }
};
