import { FC } from 'react';
import { Label } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';

import hourglass from 'common/assets/svg/common/hourglass.svg';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import { JobLogEntryType } from '@/common/types/jobs.ts';

import { useAppSelector } from '@/store/hooks';

import SingleDate from './SingleDate';
import DueDateInputRemarksPopover from '@/common/components/pms/jobs/inputs/maintenance/main/system-running-hours/DueDateInputRemarksPopover';
import styled from '@emotion/styled';

type DueDateHistoryLabelProps = {
  history: JobLogEntryType[];
};

const DueDateHistoryLabel: FC<DueDateHistoryLabelProps> = ({ history }) => {
  const lastDueDateChange = useAppSelector(state => selectJobField(state, 'last_due_date_change'));
  const { avoidRender, tooltipID } = useTooltipID('due-date-history');

  if (avoidRender) return null;

  return (
    <Label className={`form-label lh-1 d-flex align-items-center max-w-140`}>
      <div className="flex-1">Due date</div>

      <DueDateInputRemarksPopover />

      {lastDueDateChange ? (
        <HistoryTooltip
          className="d-flex align-items-center bg-coral text-white cpx-6 cpy-2 rounded-pill h-16 pointer base-transition"
          id={`${tooltipID}`}
        >
          <span className="fs-10">{history.length}</span>

          <SvgRender
            className={'text-white cms-4'}
            style={{ width: 6, height: 9 }}
            src={hourglass}
          />

          <Tooltip
            hideArrow
            placement="right"
            innerClassName="bg-white min-width-fit max-width-fit"
            target={`${tooltipID}`}
          >
            <div className="text-coral border-bottom border-platinum d-flex align-items-center fw-medium justify-content-start fs-12 cpb-4 cmb-12">
              <SvgRender
                className="text-red me-1"
                style={{ width: 8, height: 12 }}
                src={hourglass}
              />
              Due date changes
            </div>

            {history.map((data, i) => (
              <SingleDate key={i} data={data} isLast={history.length === i + 1} />
            ))}
          </Tooltip>
        </HistoryTooltip>
      ) : null}
    </Label>
  );
};

export const HistoryTooltip = styled.div`
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: none;
  }
`;

export default DueDateHistoryLabel;
