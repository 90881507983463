import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_PORT_STATEMENT: 'SET_PORT_STATEMENT',
  SET_ERROR_PORT_STATEMENT: 'SET_ERROR_PORT_STATEMENT',
  SET_EDITTING_PORT_STATEMENT: 'SET_EDITTING_PORT_STATEMENT',
  SET_COLLAPSE: 'SET_COLLAPSE',
  SET_GLOBAL_COLLAPSE: 'SET_GLOBAL_COLLAPSE',
  SET_PORT_STATEMENT_REBUILD_COMMENTS: 'SET_PORT_STATEMENT_REBUILD_COMMENTS',
  SET_FUTURE_REPORTS: 'SET_FUTURE_REPORTS',
  SET_SELECTED_FUTURE_REPORTS: 'SET_SELECTED_FUTURE_REPORTS',
  ...asyncTypes('GET_PORT_STATEMENT'),
  ...asyncTypes('CREATE_PORT_STATEMENT_ACTION'),
  ...asyncTypes('DELETE_PORT_STATEMENT_ACTION'),
  ...asyncTypes('UPDATE_PORT_STATEMENT_ACTION'),
  ...asyncTypes('REBUILD_PORT_STATEMENT_ACTION'),
  ...asyncTypes('TECH_REVIEW_PORT_STATEMENT_ACTION'),
  ...asyncTypes('UPDATE_PORT_STATEMENT'),
  ...asyncTypes('APPROVE_PORT_STATEMENT'),
  ...asyncTypes('REVOKE_APPROVAL_PORT_STATEMENT'),
  ...asyncTypes('GET_FUTURE_REPORTS'),
  ...asyncTypes('SELECT_FUTURE_REPORTS')
};

export default TYPES;
