import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr } from 'common/utils/numbers';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import * as reportActions from 'captain-reports/store/actions';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import {
  selectConsumptionFuelGrade,
  selectCaptainReportBunkeringPerFuelGrade,
  selectConsumptionBunkerConsumptionIndex
} from 'captain-reports/store/selectors';
import { TemplateSectionField } from 'common/entities/captain-reports/ReportTemplateTypes';

const SuppliedViscosityField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [setWarning, setValue] = useActions([reportActions.setWarning, reportActions.setValue]);
  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const bunkeringData = useSelector(state =>
    selectCaptainReportBunkeringPerFuelGrade(state, fuelGrade?.id)
  );
  const bunkerConsumptionIndex = useSelector(state =>
    selectConsumptionBunkerConsumptionIndex(state, 'consumptions', subGroupIndex)
  );
  const autofillValue = _get(bunkeringData, `[${bunkerConsumptionIndex}].bdn_supplied_viscosity`);

  const supplied_value = useValue({
    key: 'supplied',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    setWarning({
      key: field.key,
      isRequired: true,
      error:
        supplied_value !== null && supplied_value > 0 && value === null
          ? REQUIRED_FIELD_MESSAGE
          : null,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  }, [value, supplied_value]);

  useEffect(() => {
    if ((autofillValue || autofillValue === 0) && value === null && !isInitialized) {
      setIsInitialized(true);
      setValue({
        key: field.key,
        value: numberToStr(autofillValue) || null,
        sectionLabel: sectionLabel,
        sectionActiveTab: sectionActiveTab,
        subGroup: subGroup,
        subGroupIndex: subGroupIndex
      });
    }
  }, [autofillValue, value, isInitialized]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default SuppliedViscosityField;

SuppliedViscosityField.propTypes = {
  ...TemplateSectionField
};
