import TYPES from './types';
import { get } from 'utils/api';

export const getTrip = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_TRIP.START, payload: { params } });

  return get(`/trips/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_TRIP.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_TRIP.ERROR, payload: { response: error, params } });

      throw error;
    });
};

export const createTrip = () => {};

export const editTrip = () => {};

export const deleteTrip = () => {};

export const downloadTicketOfferAttachments = () => {};

export const addNewTicketToTrip = () => {};

export const updateTripTicket = () => {};

export const acceptTripTicket = () => {};

export const deleteTripTicket = () => {};

export const deleteTripParticipant = () => {};

export const addTripParticipant = () => {};

export const updateTripData = () => {};

export const updateTripParties = () => {};

export const setTripPreventionModal = params => dispatch => {
  dispatch({ type: TYPES.SET_TRIP_PREVENTION_MODAL, payload: params });
};

/* Trip Drawer */

export const openTripDrawer =
  ({ tripIDs, tripVisibleParties, ticketingCaseID, parties, type, disabled }) =>
  dispatch => {
    dispatch({
      type: TYPES.OPEN_TRIP_DRAWER,
      payload: { tripIDs, tripVisibleParties, ticketingCaseID, parties, type, disabled }
    });
  };

export const closeTripDrawer = () => dispatch => {
  dispatch({ type: TYPES.CLOSE_TRIP_DRAWER });
};

export const updateTripParty = (personID, data) => dispatch => {
  dispatch({ type: TYPES.UPDATE_TRIP_PARTY, payload: { personID, data } });
};

export const collapseTrip = tripID => dispatch => {
  dispatch({ type: TYPES.COLLAPSE_TRIP, payload: { tripID } });
};

export const getTicketsTotals = () => {};
/* -- */
