import React, { useCallback } from 'react';
// import { useRef, useEffect, useState } from 'react';
// import _debounce from 'lodash/debounce';
import useGallery from 'common/components/gallery/useGallery';
import { getFileMetadata } from '@/api/files/api';

const PreviewPage = ({ content, pagesCount, setPagesCount }) => {
  const { setIsOpen, setCurrentFile } = useGallery();

  // const previewContainerRef = useRef();
  // const elements = document.querySelectorAll('.break-element');

  // const [currentPage, setCurrentPage] = useState(1);

  // const a4PageHeight = 1122;

  // const addClasses = (el, currentPage) => {
  //   el.classList.add(`break-element`);
  //   el.classList.add(`page-${currentPage}`);
  // };

  // const renderPageBreak = () => {
  //   if (previewContainerRef && previewContainerRef.current) {
  //     let pageHeight = 0;
  //     let currentPage = 1;
  //     const container = previewContainerRef.current;
  //     const children = container.children;

  //     // iterate over all child nodes
  //     Array.from(children).forEach(child => {
  //       const childHeight = child.offsetHeight;

  //       if (pageHeight + childHeight < a4PageHeight) {
  //         pageHeight = pageHeight + childHeight;
  //         addClasses(child, currentPage);
  //       } else {
  //         const pageBreak = document.createElement('hr'); // Append a <div className="chapter-page__break"> pageCount/numberOfPages </div>
  //         const hr = container.insertBefore(pageBreak, child);
  //         currentPage = currentPage + 1;
  //         addClasses(child, currentPage);
  //         addClasses(hr, currentPage);
  //         setPagesCount(prev => prev + 1);

  //         pageHeight = childHeight;
  //       }
  //     });
  //   }
  // };

  // const debouncedRenderPageBreak = _debounce(renderPageBreak, 1000);

  // useEffect(() => {
  //   setPagesCount(1);
  //   debouncedRenderPageBreak();
  // }, []);

  // const observableTarget = payload => {
  //   if (payload[0].isIntersecting) {
  //     const classes = payload[0].target.classList.value.split(' ');
  //     const pageNumber = classes?.find(e => e?.includes('page-'))?.replace('page-', '') || '-';

  //     setCurrentPage(pageNumber);
  //   }
  // };

  // useEffect(() => {
  //   if (!elements) {
  //     return;
  //   }

  //   const ob = new IntersectionObserver(observableTarget);
  //   elements.forEach(child => {
  //     ob.observe(child);
  //   });
  // }, [elements]);

  const openFilePreview = useCallback(
    async fileId => {
      if (!fileId) return;

      try {
        const res = await getFileMetadata(fileId);

        setIsOpen(true);

        setCurrentFile(res);
      } catch (err) {
        console.error(err);
      }
    },
    [setCurrentFile, setIsOpen]
  );

  return (
    <div className="chapter-content__preview-content position-relative ck-content mb-6">
      <div
        // ref={previewContainerRef}
        className="chapter-page gray-scrollbar"
        onClick={e => openFilePreview(e.target?.getAttribute('fileid'))}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>

      {/* <div className="chapter-content__pages-count d-flex align-items-center justify-content-center">
        {currentPage} / {pagesCount}
      </div> */}
    </div>
  );
};

export default PreviewPage;
