import { deleteEvent, getEvents, updateEvent, createEvent } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  data: [],
  loading: false,
  editing: false
};

const slice = createSlice({
  name: 'eventEvents',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(deleteEvent.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.data = state.data.filter(el => {
          return el.id !== payload.id;
        });

        return state;
      })

      .addCase(getEvents.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getEvents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        return state;
      })

      .addCase(getEvents.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(updateEvent.fulfilled, (state, { payload }) => {
        const index = state.data.findIndex(j => j.id === payload.id);
        if (index !== -1) state.data[index] = payload;

        state.editing = false;
        return state;
      })

      .addCase(createEvent.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.data.push(payload);
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default slice.reducer;
