import React from 'react';

import { Row, Col } from 'reactstrap';
import CircledButton from 'common/components/buttons/CircledButton';
import Filter from 'common/components/filters/Filter';
import TopPagination from 'common/components/table/main/TopPagination';
import TableTopFilter from 'common/components/table/TableTopFilter';

import { useSelector } from 'react-redux';

import ArchivedButton from 'common/components/table/main/ArchivedButton';

const Filters = ({ table, onCreateClick, expirationFilter, setExpirationFilter }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <Row className="align-items-center">
      {onCreateClick ? (
        <Col xs="auto">
          <CircledButton
            type="add"
            label={'Add more'}
            className="text-primary fw-bold crew-add-doc-button me-2"
            onClick={onCreateClick}
            dataCy="add-more"
          />
        </Col>
      ) : null}
      <Col>
        <div className="d-flex align-items-center">
          <TableTopFilter>
            <Filter
              columns={table.columns}
              filter={expirationFilter}
              removeFilter={() => setExpirationFilter({ ...expirationFilter, value: '' })}
              updateFilter={value => setExpirationFilter({ ...expirationFilter, ...value })}
              isHidden={false}
              isCreate={false}
              isTopFilter={true}
            />
          </TableTopFilter>

          {!isOnBoard && (
            <ArchivedButton
              label="Documents"
              topFilters={table.topFilters}
              setTopFilters={table.setTopFilters}
              className="ms-3"
              dataCy="archived"
            />
          )}
        </div>
      </Col>
      <Col xs="auto">
        <TopPagination
          standAlone
          state={table.state}
          fetchData={params => table.refetchData(params)}
        />
      </Col>
    </Row>
  );
};

export default Filters;
