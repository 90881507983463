import ReactDOM from 'react-dom';
import { useState } from 'react';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';

import usePortal from 'common/utils/hooks/usePortal';
import SvgRender from 'common/components/general/SvgRender';
import useTooltipID from 'common/utils/hooks/useTooltipID';

import commentsSolid from 'common/assets/svg/common/comments-solid.svg';
import comment from 'common/assets/svg/common/comments.svg';
import x from 'common/assets/svg/common/x.svg';

const PageCommentsPopover = ({
  popoverProps = {},
  message,
  title = 'Comments',
  portalView = 'root'
}) => {
  const target = usePortal(portalView);

  const { avoidRender, tooltipID } = useTooltipID('page-comments-popover');
  const [isActive, setIsActive] = useState(false);

  if (avoidRender) return null;

  const activeHandler = e => {
    setIsActive(!isActive);
  };

  return ReactDOM.createPortal(
    <div className="page-comments-popover">
      <Button
        type="button"
        className={`btn-circle ${isActive ? '' : 'btn-circle--shadow'}`}
        color={'yellow'}
        onClick={activeHandler}
        id={tooltipID}
      >
        <div className="d-flex align-items-center fw-bold fs-14 text-primary">
          <span>Comments</span>

          <SvgRender
            src={commentsSolid}
            style={{ height: 18, width: 20 }}
            className={`text-primary cms-10`}
          />
        </div>
      </Button>

      <UncontrolledPopover
        placement="top"
        target={tooltipID}
        isOpen={isActive}
        className="page-comments-popover__container"
        boundariesElement="window"
        {...popoverProps}
      >
        <PopoverHeader className="border-bottom d-flex align-items-center bg-white px-0 pt-0">
          <div className="d-flex align-items-center w-100p">
            <div className="d-flex align-items-center flex-1">
              <SvgRender
                src={comment}
                className="page-comments-popover__container-title-icon"
                style={{ width: 15, height: 15 }}
              />
              <span className="fs-12 fw-medium page-comments-popover__container-title">
                {title}
              </span>
            </div>

            <SvgRender
              src={x}
              onClick={activeHandler}
              className="text-primary cursor-pointer"
              style={{ width: 10, height: 10 }}
            />
          </div>
        </PopoverHeader>

        <PopoverBody
          className="text-primary page-comments-popover__container-message px-0 fs-14 overflow-y"
          dangerouslySetInnerHTML={{ __html: message }}
        ></PopoverBody>
      </UncontrolledPopover>
    </div>,
    target
  );
};

export default PageCommentsPopover;

PageCommentsPopover.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  popoverProps: PropTypes.object,
  portalView: PropTypes.string
};
