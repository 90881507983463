import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const TrainingDropdownInput = ({ disabled, error, value, onChange, ...inputComponentProps }) => {
  return (
    <Select
      isAsync
      isMulti
      value={value}
      onChange={onChange}
      getOptionValue={option => option.id}
      defaultOptions={() =>
        getInitialAsyncValues('crew-training-types', null, null, { is_onboard: true })
      }
      loadOptions={search => getAsyncOptions(search, 'crew-training-types', { is_onboard: true })}
      getOptionLabel={option => option.name}
      className="mb-0"
      error={error}
      placeholder="Select"
      {...inputComponentProps}
    />
  );
};

export default TrainingDropdownInput;
