import React from 'react';
import PropTypes from 'prop-types';

import { getSharedActions } from 'captain-reports/store/chatbox/actions';
import { useActions } from 'utils/hooks';
import * as chatboxActions from 'captain-reports/store/chatbox/actions';
import { useDrawer } from 'common/components/drawer';
import Chatbox from 'common/components/chatbox';

const ReportsChatbox = ({ id, reportStatus = '', isOnboard, className = '' }) => {
  const chatDrawer = useDrawer();

  const [
    getCaptainReportMessages,
    postCaptainReportMessage,
    getCaptainReportUnreadMessages,
    captainReportMarkAsRead
  ] = useActions([
    chatboxActions.getCaptainReportMessages,
    chatboxActions.postCaptainReportMessage,
    chatboxActions.getCaptainReportUnreadMessages,
    chatboxActions.captainReportMarkAsRead
  ]);

  const { getUnreadMessagesAction, postMessageAction, getMessagesAction, markAsReadAction } =
    getSharedActions(
      {
        getCaptainReportMessages,
        postCaptainReportMessage,
        getCaptainReportUnreadMessages,
        captainReportMarkAsRead
      },
      id
    );

  const chatboxClassNames = className
    ? className
    : `${reportStatus === 'approved' ? 'cms-14' : 'cms-4'} ${
        reportStatus === 'draft' && !isOnboard ? 'cms-10' : ''
      }`;

  if (!id) return null;

  return (
    <Chatbox
      className={`${chatboxClassNames} width-32 height-32`}
      svgStyle={{ width: 18, height: 18 }}
      getMessages={getMessagesAction}
      sendMessage={postMessageAction}
      getUnreadMessages={getUnreadMessagesAction}
      markMessagesAsRead={markAsReadAction}
      drawer={chatDrawer}
    />
  );
};

export default ReportsChatbox;

ReportsChatbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
  reportStatus: PropTypes.string,
  isOnboard: PropTypes.bool
};
