import moment from 'moment';

import Departments from 'common/components/general/Departments';
import Status from 'common/components/general/Status';
import Priority from 'common/components/general/Priority';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import JobLink from 'common/components/jobs/maintenance/components/JobLink';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from '@/api/files/api';
import PageLink from 'common/components/general/page-link';

import paths from 'routing/routes/_paths';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';
import Resubmitted from 'common/components/forms/_components/Resubmitted';

const rows = (getTemplateLabelsRows, history) => {
  return {
    title: data => (
      <JobLink customTooltipMessage="Opens the Job" history={history} job={data} isRegularJob />
    ),
    id: data => (
      <TextWithTooltip className="d-flex align-items-center">
        {data?.orca_id || data.id}
      </TextWithTooltip>
    ),
    completed_at: data => {
      const completedAt = _get(data, 'flag_extension.completed_at');

      return completedAt ? moment(completedAt).format('DD/MM/YYYY') : '-';
    },
    informed_at: data => {
      const informedAt = _get(data, 'flag_extension.informed_at');

      return informedAt ? moment(informedAt).format('DD/MM/YYYY') : '-';
    },
    requested_on: data => {
      const requestedOn = _get(data, 'flag_extension.requested_on');

      return requestedOn ? moment(requestedOn).format('DD/MM/YYYY') : '-';
    },
    flag_extension_and_dispensation_types: data => {
      const type = _get(data, 'flag_extension.type.name');

      return <TextWithTooltip className="d-flex align-items-center">{type}</TextWithTooltip>;
    },
    requested_for: data => {
      const requestedFor = _get(data, 'flag_extension.requested_for') || '-';

      return (
        <TextWithTooltip className="d-flex align-items-center">{requestedFor}</TextWithTooltip>
      );
    },
    attachment: data => {
      const attachments = _get(data, 'flag_extension.attachments', []);
      const attachmentsCount = _get(data, 'flag_extension.attachments_count', []);

      return (
        <div className="d-flex align-items-center justify-content-end">
          <DownloadAttachment
            data={{ attachments_count: attachmentsCount, attachments: attachments }}
            downloadFiles={() =>
              downloadAllFilesOfEntity({ id: data?.id, type: 'job_flag_extension' })
            }
            hasAttachments={true}
            canAddAttachments={false}
            popupPlacement={'left'}
          />
        </div>
      );
    },
    due_date: data => (
      <DueDate
        dueDateChanged={data.last_due_date_change}
        className="fs-12"
        dueDate={data.due_date}
        createdAt={data.created_at}
        status={data.status_id}
      />
    ),
    importance_id: data => <Priority value={data.importance_id} />,
    status_id: data => (
      <div className="d-flex align-items-center">
        <Status value={data.status_id} /> <Resubmitted isResubmitted={data?.resubmitted} />
      </div>
    ),
    vessels: data => {
      const vessels = data.is_for_vessel && data.vessel ? [data.vessel] : data.vessels;

      return vessels && vessels.length > 0 ? (
        <NamesInTooltip
          target={`job-table-vessel-${data.id}`}
          names={vessels.map((vessel, index) => {
            const Tag = index > 0 ? Link : PageLink;

            return (
              <AuthCheck
                key={vessel.id}
                permissions={[permissions.office.vessels.list]}
                unAuthorizedRender={_get(vessel, 'name', '-')}
              >
                <Tag to={`${paths.vessels}/${vessel?.id}`}>{vessel.name}</Tag>
              </AuthCheck>
            );
          })}
        />
      ) : (
        '-'
      );
    },
    created_by_id: data =>
      data.created_by ? (
        data.created_by.id ? (
          <PageLink to={`${paths.persons}/${data.created_by_id}`}>
            {_get(data, 'created_by.full_name', '-')}
          </PageLink>
        ) : data.created_by.label ? (
          'Orca'
        ) : (
          '-'
        )
      ) : (
        '-'
      ),
    assignee_id: data =>
      data.assignee && data.assignee.full_name ? (
        <PageLink to={`${paths.persons}/${data.assignee.id}`}>{data.assignee.full_name}</PageLink>
      ) : data.assignee_crew_rank ? (
        <div>{data.assignee_crew_rank.name}</div>
      ) : (
        <div className="text-secondary">Unassigned</div>
      ),
    approvers: data =>
      data.approvers && data.approvers.length > 0 ? (
        <NamesInTooltip
          target={`job-table-approvers-${data.id}`}
          names={data.approvers.map((approver, index) => {
            const Tag = index > 0 ? Link : PageLink;

            return <Tag to={`${paths.persons}/${approver.id}`}>{approver.full_name}</Tag>;
          })}
        />
      ) : (
        <div className="text-secondary">-</div>
      ),
    main_department_id: data => (
      <Departments values={data.main_department ? [data.main_department] : []} />
    ),
    type: data => <div>{_get(data, 'type', '')}</div>,
    tags: () => null,
    ...getTemplateLabelsRows()
  };
};

export default rows;
