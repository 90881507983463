import React from 'react';

import WorkingScheduleTableHeader from './TableHeader';
import CrewMemberSchedule from './crew-member-schedule';

const WorkingScheduleTable = ({
  crewMembers,
  onEdit,
  onDelete,
  isViewOnly,
  oldSelectedSchedule
}) => {
  return (
    <div>
      <WorkingScheduleTableHeader />
      <div className="working-schedule--container mb-4">
        {crewMembers.map(crewMember => (
          <CrewMemberSchedule
            isViewOnly={isViewOnly}
            key={crewMember.id}
            crewMember={crewMember}
            onEdit={() => onEdit(crewMember)}
            onDelete={() => onDelete(crewMember)}
            oldSelectedSchedule={oldSelectedSchedule}
          />
        ))}
      </div>
    </div>
  );
};

export default WorkingScheduleTable;
