import CircledButton from 'common/components/buttons/CircledButton';

const NavigationArrows = ({
  onNextClick,
  onPreviousClick,
  disabledNext,
  disabledPrevious,
  className = 'mb-3'
}) => {
  return (
    <div className={`d-flex ${className || ''}`}>
      <CircledButton
        style={{ width: 20, height: 20 }}
        type={'arrow-thin'}
        svgWrapperClassName="me-1 bg-dark"
        svgStyle={{ transform: 'rotate(180deg)', width: 10, height: 10 }}
        disabled={disabledPrevious}
        onClick={onPreviousClick}
      />
      <CircledButton
        style={{ width: 20, height: 20 }}
        type={'arrow-thin'}
        svgClassName="dark"
        svgWrapperClassName="me-1 bg-dark"
        svgStyle={{ width: 10, height: 10 }}
        disabled={disabledNext}
        onClick={onNextClick}
      />
    </div>
  );
};

export default NavigationArrows;
