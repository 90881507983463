import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_TABLE_LIST'),
  UPDATE_TABLE_ROW: 'UPDATE_TABLE_ROW',
  REMOVE_TABLE_ROW: 'REMOVE_TABLE_ROW',
  RESET_TABLE_ROW_UPDATE: 'RESET_TABLE_ROW_UPDATE',
  TOGGLE_TABLE_FILTERS: 'TOGGLE_TABLE_FILTERS',
  APLLY_TABLE_FILTERS: 'APLLY_TABLE_FILTERS',
  SELECT_TABLE_ROW: 'SELECT_TABLE_ROW',
  SELECT_TABLE_ROWS: 'SELECT_TABLE_ROWS',
  CLEAR_SELECTED_TABLE_ROWS: 'CLEAR_SELECTED_TABLE_ROWS',
  GET_SELECTED_TABLE_ROWS: 'GET_SELECTED_TABLE_ROWS',
  SET_TABLE_PAGE_SEARCH: 'SET_TABLE_PAGE_SEARCH',
  SET_TABLE_WARNINGS: 'SET_TABLE_WARNINGS',
  SET_TABLE_TOTALS: 'SET_TABLE_TOTALS',
  SET_TABLE_HOVERED_COLUMN: 'SET_TABLE_HOVERED_COLUMN',
  SET_TABLE_REQUEST_PARAMS: 'SET_TABLE_REQUEST_PARAMS'
};

export default TYPES;
