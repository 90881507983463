import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShadowBox from 'common/components/general/ShadowBox';
import FormSaveActions from 'common/components/form/FormSaveActions';
import NoteEditor from './NoteEditor';
import ProfileImage from 'common/components/general/ProfileImage';
import { PreviewHtml } from 'common/components/form/inputs/Editor';
import { Party } from 'common/entities/parties/PartyTypes';
import { useForm } from 'utils/hooks';
import { hiddenField, optionField, stringField } from 'common/utils/form/fieldTypes';
import { useSelector } from 'react-redux';
import { selectAccount } from 'store/account/selectors';
import rolodex from 'common/assets/svg/avatars/rolodex.svg';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { dateToLocal } from 'common/utils/dates';

const config = {
  id: hiddenField(),
  content: stringField({ required: true }),
  tags: optionField()
};

const NoteWithForm = ({
  content,
  tags,
  id,
  index,
  created_by,
  created_at,
  onEditNote,
  onDeleteNote
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { formState, collectValues, loadValues } = useForm(config, {});

  const account = useSelector(selectAccount);
  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);

  const canEdit = isTemplate || !eventId || (created_by?.id && account?.id === created_by?.id);

  const toggleForm = () => {
    loadValues({ content, tags: tags?.map(({ text }) => text), id });
    setIsEditing(true);
  };

  const onSave = async () => {
    try {
      const values = collectValues();
      if (!values) return;

      setIsSaving(true);

      await onEditNote(values);

      setIsSaving(false);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsSaving(true);

      await onDeleteNote(id);

      setIsSaving(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  return (
    <ShadowBox
      className={`px-3 cpt-12 cpb-12 position-relative ${index ? 'mt-1' : ''}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      flat={isEditing ? true : false}
      color={isEditing ? 'light-4' : ''}
      flatOnHover
    >
      {created_by ? (
        <div className="d-flex align-items-center flex-nowrap cpb-12 cmb-12 border-bottom">
          <ProfileImage
            image={created_by?.photo?.url}
            fallback={rolodex}
            rounded
            className="shadow bg-white"
            style={{ width: 28, height: 28 }}
            imgProps={{ width: 28, height: 28 }}
          />
          <strong className="fs-14 fw-medium text-primary cps-12">
            {created_by?.full_name || '-'}
          </strong>
          {!isEditing && (
            <div className="flex-1 text-end text-nowrap text-violet fs-12">
              <span className="text-truncate d-inline-block">
                {created_at ? dateToLocal(created_at).format('DD MMM YYYY, hh:mm a') : null}
              </span>
            </div>
          )}
        </div>
      ) : null}
      <div className="fs-12 text-primary">
        {isEditing ? (
          <NoteEditor formState={formState} />
        ) : (
          <ShadowBox className="cp-12" color="light-1" flat>
            <PreviewHtml value={content} />
            {tags?.length > 0 && (
              <div className="mt-1 d-flex align-items-center">
                {tags?.map(tag => (
                  <div
                    key={tag.id}
                    className="bg-ghost-white-3 text-primary fw-bold fs-12 rounded-sm cme-4 cmt-4 cpx-4 cpy-4"
                  >
                    {tag.text}
                  </div>
                ))}
              </div>
            )}
          </ShadowBox>
        )}
      </div>

      {canEdit ? (
        <FormSaveActions
          mode={isEditing ? 'edit' : 'view'}
          isHovering={isEditing ? true : isHovering}
          isDisabled={isSaving}
          onEdit={toggleForm}
          onDelete={onDelete}
          onCancel={() => setIsEditing(false)}
          onSave={onSave}
          style={{ top: 8, right: -12 }}
        />
      ) : null}
    </ShadowBox>
  );
};

NoteWithForm.propTypes = {
  content: PropTypes.string,
  index: PropTypes.number,
  id: PropTypes.number,
  created_by: Party,
  created_at: PropTypes.string,
  onEditNote: PropTypes.func,
  onDeleteNote: PropTypes.func
};

const PreviewNote = ({ content, id, onToggleNoteEditor }) => {
  const [isHovering, setIsHovering] = useState(false);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const canEdit = isOnBoard;

  return (
    <div
      className="event-notes__full-preview position-relative text-primary fs-12 position-relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className="event-notes__full-preview--content gray-scrollbar"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>

      {canEdit && (
        <FormSaveActions
          mode={'view'}
          isHovering={isHovering}
          onEdit={() => onToggleNoteEditor({ content, id })}
          style={{ top: 8, right: -12 }}
        />
      )}
    </div>
  );
};

PreviewNote.propTypes = {
  content: PropTypes.string,
  id: PropTypes.number,
  onToggleNoteEditor: PropTypes.func
};

const SavedNotes = ({ notes, onEditNote, onDeleteNote, onToggleNoteEditor, fullPreview }) => {
  const Tag = fullPreview ? PreviewNote : NoteWithForm;

  return (
    <div>
      {notes.map((note, index) => (
        <Tag
          key={note.id}
          index={index}
          onEditNote={onEditNote}
          onDeleteNote={onDeleteNote}
          onToggleNoteEditor={onToggleNoteEditor}
          {...note}
        />
      ))}
    </div>
  );
};

SavedNotes.propTypes = {
  notes: PropTypes.array,
  fullPreview: PropTypes.bool,
  onEditNote: PropTypes.func,
  onDeleteNote: PropTypes.func,
  onToggleNoteEditor: PropTypes.func
};

export default SavedNotes;
