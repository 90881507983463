import React, { useEffect, useState, useCallback } from 'react';
import _get from 'lodash/get';

import ShiftsTableHeader from './Header';
import ShiftsTableRow from './row';
import LocationLabels from './LocationLabels';
import TopRightButtons from './TopRightButtons';
import ClearScheduleButton from './ClearScheduleButton';

import {
  generateDates,
  convertDateTimeShiftToInternalShift,
  cells,
  sumTotals
} from 'crew/utils/helpers';

import { useSelector } from 'react-redux';
import {
  selectShiftTypes,
  selectSelectedShiftType,
  selectPeriodType,
  selectStartDate,
  selectEndDate
} from 'crew/store/shifts/selectors';
import { numberToStr } from 'common/utils/numbers';

import TotalsSum from './TotalsSum';
import CrewWarnings from './CrewWarnings';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const getTableIndexFromDates = (startDate, date) => {
  return moment(date, dateFormat).diff(moment(startDate, dateFormat), 'days');
};

const ShiftsTable = ({
  crew,
  isEditing,
  updateShift,
  validateShifts,
  isViewOnly,
  isCrewProfile
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [internalRows, setInternalRows] = useState([]);
  const [internalDates, setInternalDates] = useState([]);
  const [summedTotals, setSummedTotals] = useState({});
  const [dates, setDates] = useState([]);
  const [comments, setComments] = useState([]);

  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const type = useSelector(selectPeriodType);
  const selectedShiftType = useSelector(selectSelectedShiftType());
  const shiftTypes = useSelector(selectShiftTypes);

  const setInternalShifts = (content, index) => {
    setInternalRows(internalRows.map((row, i) => (i === index ? content : row)));
  };

  const shifts = crew.dates;
  const fixedOvertime = numberToStr(_get(internalDates, '[0].totals.fixed_overtime'), 2, 2);

  const initTable = useCallback(() => {
    const days = moment(endDate, dateFormat).diff(moment(startDate, dateFormat), 'days') + 1;

    if (!days) return;

    const newDates = generateDates(days, moment(startDate, 'YYYY-MM-DD')).map(date =>
      date.format('YYYY-MM-DD')
    );
    setDates(newDates);

    const mappedDates = newDates
      .map(date => ({
        index: getTableIndexFromDates(startDate, date),
        date,
        shifts:
          shifts[date] && shifts[date].shifts && shifts[date].shifts.length
            ? convertDateTimeShiftToInternalShift(shifts[date].shifts)
            : cells,
        isAtSea: shifts[date] ? shifts[date].at_sea : false,
        comments: shifts[date] ? shifts[date].comments : null,
        timezoneDifference: shifts[date] ? shifts[date].timezone_difference : false,
        isDisabled:
          moment(date, dateFormat).isBefore(moment(crew.contract.sign_on_date, dateFormat)) ||
          moment(date, dateFormat).isAfter(moment(crew.contract.sign_off_date, dateFormat)),
        totals: shifts[date] ? shifts[date].totals || {} : {}
      }))
      .filter(date => date.index >= 0 && date.index < days);

    const finalArray = Array(days)
      .fill(null)
      .map((_, i) =>
        mappedDates.find(date => date.index === i)
          ? mappedDates.find(date => date.index === i).shifts
          : cells
      );

    setInternalRows(finalArray);
    setComments(mappedDates.map(shift => shift.comments));

    setInternalDates(
      mappedDates.map(shift => ({
        date: shift.date,
        isAtSea: shift.isAtSea,
        comments: shift.comments,
        isDisabled: shift.isDisabled,
        timezoneDifference: shift.timezoneDifference,
        totals: shift.totals
      }))
    );

    setSummedTotals(sumTotals(mappedDates));
  }, [crew.contract.sign_off_date, crew.contract.sign_on_date, endDate, shifts, startDate]);

  useEffect(() => {
    initTable();
  }, [initTable, shifts]);

  return (
    <div className="flex-1">
      <div
        className={`shifts-table-container ${
          isEditing ? (type !== 'daily' ? 'border-start border-white' : 'ps-2') : ''
        }`}
      >
        <div
          className={`shifts-table-container--scrollable period-${type} ${
            isCrewProfile || type === 'daily' ? 'no-border' : ''
          }`}
        >
          <div
            className={`position-relative shifts-table${
              isEditing ? ' shifts-table--is-editing' : ''
            }`}
          >
            <ClearScheduleButton
              isEditing={isEditing}
              internalRows={internalRows}
              setInternalShifts={setInternalShifts}
              crew={crew}
            />
            <LocationLabels rows={internalDates} isEditing={isEditing} type={type} />

            <div className={`shifts-table--inner ${type === 'daily' ? '' : 'ps-hd-1'}`}>
              <ShiftsTableHeader />
              <div className="shifts-table--row-container">
                {internalRows.map((row, i) => (
                  <ShiftsTableRow
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    key={i}
                    i={i}
                    isDisabled={internalDates[i].isDisabled}
                    totals={internalDates[i].totals}
                    timezoneDifference={internalDates[i]?.timezoneDifference}
                    internalShifts={internalRows[i]}
                    setInternalShifts={content => setInternalShifts(content, i)}
                    validateShifts={
                      validateShifts
                        ? updatedDateShifts => validateShifts(internalDates, updatedDateShifts)
                        : false
                    }
                    shiftTypes={shiftTypes}
                    selectedShiftType={selectedShiftType}
                    date={moment(dates[i])}
                    dateKey={dates[i]}
                    row={row}
                    crew={crew}
                    isEditing={isEditing}
                    comment={comments[i]}
                  />
                ))}
              </div>
              {type !== 'daily' && (
                <div className="d-flex align-items-center w-100p justify-content-end">
                  <div className="bg-moody-blue border-radius-3 px-1 d-flex align-items-center me-1">
                    <div className="fs-10 fw-normal text-white">
                      FIXED OVERTIME AS PER CONTRACT:
                    </div>

                    <div className="fs-10 fw-bold text-white ms-1 cme-4">{fixedOvertime}</div>

                    <div className="fs-10 fw-normal text-white">hours per month</div>
                  </div>

                  <TotalsSum totals={summedTotals} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <CrewWarnings isEditing={isEditing} id={crew.id} />

      {!isViewOnly ? (
        <TopRightButtons
          updateShift={updateShift}
          initTable={initTable}
          id={crew.id}
          dates={internalDates}
        />
      ) : null}
    </div>
  );
};

export default ShiftsTable;
