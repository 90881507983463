import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';

import Table from 'views/dashboard/components/Table';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';

import _get from 'lodash/get';
import paths from 'routing/routes/_paths';
import RoundedLineIconWithTooltip from 'common/components/general/RoundedLineIconWithTooltip';

import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import {
  isDraftEvaluation,
  getConsentSeamanStatusParams
} from 'crew/profile/main/evaluation/form/helpers';

import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import draftSvg from 'common/assets/svg/common/draft.svg';
import crewSvg from 'common/assets/svg/common/crew.svg';

import EvaluateSeamanAction from './EvaluateSeamanAction';
import ViewEvaluationAction from './ViewEvaluationAction';
import { selectEvaluationsHaveData } from 'store/dashboard/tables/selectors';
import { useMemo } from 'react';

const Evaluations = ({ name }) => {
  const requestParams = useMemo(
    () => ({ filters: [{ name: 'status', operation: '=', value: 'pending' }] }),
    []
  );

  const { history } = useRouter();

  const isFirst = name === 'PENDING MY ACTIONS';
  const isLast = name === 'EVALUATIONS';

  const evaluationsHaveData = useSelector(state =>
    selectEvaluationsHaveData(state, 'evaluation_obligations', name)
  );

  return (
    <div className="forms-listing">
      <Table
        className={isFirst ? 'mt-8' : 'mt-5'}
        header={isFirst ? <HeaderTitle title="Evaluations" path={paths.crew_evaluations} /> : null}
        showLoadMoreButton={isLast}
        name={name}
        subheader={isFirst ? <span className="text-violet">Sorted by Days Left</span> : null}
        dynamicLabel={name?.toLowerCase().replaceAll(' ', '_')}
        label="evaluation_obligations"
        emptyBodyComponent={'-'}
        showEmpty={!evaluationsHaveData}
        requestParams={requestParams}
        columns={[
          {
            header: name,
            key: 'title',
            width: 5
          },
          {
            header: 'EVALUATOR',
            key: 'evaluator',
            width: 2
          },
          { header: 'EVALUATEE', width: 2, key: 'evaluatee' },
          { header: 'DAYS LEFT', key: 'days_left', width: 1 },
          {
            key: 'evaluation_status'
          }
        ]}
        rows={{
          title: data => (
            <TextWithTooltip className="d-flex align-items-center">
              {_get(data, 'reason.name', '-')}
            </TextWithTooltip>
          ),
          evaluator: data => {
            const evaluatorType = _get(data, 'evaluator_type', false);
            const evaluator = _get(data, 'evaluator', '');

            return evaluatorType === 'crew_member' ? (
              <span>{_get(evaluator, 'full_name', '')}</span>
            ) : (
              <div>
                <EvaluatorDepartment evaluator={evaluator} evaluatorType={evaluatorType} />
              </div>
            );
          },
          evaluatee: data => {
            const evaluateeName = _get(data, 'evaluatee.full_name', '-');
            const evaluateeId = _get(data, 'evaluatee.id', '');

            return evaluateeId || evaluateeId === 0 ? (
              <div className="overflow-hidden flex-nowrap pe-1">
                <TextWithTooltip>{evaluateeName}</TextWithTooltip>
              </div>
            ) : (
              '-'
            );
          },
          days_left: data => {
            const colorClassName = _get(data, 'is_overdue', false)
              ? 'text-red'
              : _get(data, 'is_approaching_overdue', false)
              ? 'text-warning'
              : '';
            return <span className={colorClassName}>{_get(data, 'days_left', '-')} days</span>;
          },
          evaluation_status: data => {
            const isDraft = isDraftEvaluation(data?.evaluation, true);
            const isCaptainForReview = _get(data, 'evaluation.sent_to_captain_for_review');
            const obligationId = _get(data, 'id', '');
            const evaluateeId = _get(data, 'evaluatee_id', '');
            const evaluationId = _get(data, 'evaluation.id');

            return (
              <div className="d-flex align-items-center me-4">
                <RoundedLineIconWithTooltip
                  tooltipMessage="Draft"
                  icon={draftSvg}
                  activeColor={'secondary-gray'}
                  isActive={isDraft}
                  className="cme-10"
                />

                <RoundedLineIconWithTooltip
                  tooltipMessage={
                    isCaptainForReview ? 'Sent to Captain for Review' : 'Captain’s Review'
                  }
                  icon={crewSvg}
                  isActive={isCaptainForReview}
                  activeColor={'primary'}
                />

                <div className="small-separator text-platinum cme-12 cms-12"></div>
                <RoundedLineIconWithTooltip
                  onClick={
                    data?.evaluation?.id
                      ? () => {
                          history.push(
                            `${paths.crew}/${data.evaluatee.id}/evaluation/view/${data.evaluation.id}`
                          );
                        }
                      : false
                  }
                  icon={crewPlanning}
                  {...getConsentSeamanStatusParams(data)}
                />

                <ViewEvaluationAction
                  evaluationId={evaluationId}
                  evaluateeId={evaluateeId}
                  data={data}
                />

                <EvaluateSeamanAction
                  evaluationId={evaluationId}
                  evaluateeId={evaluateeId}
                  obligationId={obligationId}
                />
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default Evaluations;
