import React from 'react';

import CircledButton from 'common/components/buttons/CircledButton';

const RemoveRowButton = ({ onRemoveRow }) => {
  return (
    <div className="mt-1">
      <CircledButton
        onClick={onRemoveRow}
        type="remove"
        className="fw-bold d-flex align-items-start"
        style={{ width: 16, height: 16 }}
        svgStyle={{ width: 6, height: 6 }}
      />
    </div>
  );
};

export default RemoveRowButton;
