import { useEffect, useState, useCallback } from 'react';

import Drawer, { DrawerHeader, FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useForm } from 'utils/hooks';

import * as mgaActions from 'common/components/mga/store/actions';

import { useSelector } from 'react-redux';
import {
  selectPeriodFormIsOpen,
  selectInventoryDepletionMethod,
  selectVesselFilter
} from 'common/components/mga/store/selectors';
import Body from './Body';

import config, { fifoInitialInventoryConfig } from './config';
import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';

const Form = () => {
  const [setMgaPeriodForm, createMgaPeriod] = useActions([
    mgaActions.setMgaPeriodForm,
    mgaActions.createMgaPeriod
  ]);
  const [isSaving, setIsSaving] = useState(false);
  const depletionMethod = useSelector(selectInventoryDepletionMethod);

  const { history, match } = useRouter();

  const constructedConfig = {
    ...config,
    initial_balances: { ...config.initial_balances, items: fifoInitialInventoryConfig }
  };

  const { formState, collectValues, loadValues, resetForm, handleSubmitError } = useForm(
    constructedConfig,
    { initial_balances: [] }
  );
  const isOpen = useSelector(selectPeriodFormIsOpen);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const vesselFilter = useSelector(selectVesselFilter);

  const closeForm = useCallback(() => setMgaPeriodForm({ isOpen: false }), []);

  const getInventoryItemsValidation = values => {
    return (
      (depletionMethod === 'fifo' &&
        values?.initial_balances?.some(e =>
          e.account.store_groups_count
            ? e.items.some(
                item => !item.store || !item.date || !item.price_per_unit || !item.quantity
              )
            : false
        )) ||
      false
    );
  };

  const onSubmit = async () => {
    const values = collectValues();
    const inventoryItemsValidation = getInventoryItemsValidation(values);

    if (!values || inventoryItemsValidation) return;

    setIsSaving(true);

    try {
      const { initial_balances, ...rest } = values;
      const requestParams = { ...rest };

      if (!isOnBoard && vesselFilter) requestParams.vessel_id = vesselFilter;

      if (initial_balances?.length) {
        requestParams.initial_balances = initial_balances
          .filter(acc => (depletionMethod === 'fifo' ? !acc.account.store_groups_count : acc))
          .map(a => ({
            account_id: a.account.id,
            amount: a.amount
          }));
      }
      if (depletionMethod === 'fifo') {
        requestParams.initial_inventory = initial_balances
          .map(balance =>
            balance.account.store_groups_count
              ? {
                  account_id: balance.account.id,
                  items: balance.items.map(e => {
                    const { store, total, ...rest } = e;

                    return { ...rest, store_id: store.id };
                  })
                }
              : {}
          )
          .filter(e => e?.account_id && e?.items?.length);
      }

      const res = await createMgaPeriod(requestParams);

      if (vesselFilter && res?.id) {
        history.replace(`${paths.mga}/${match.params.view}/${vesselFilter}/${res?.id}`);
      }

      closeForm();
    } catch (error) {
      handleSubmitError(error);
    }

    setIsSaving(false);
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  return (
    <Drawer size="md" isOpen={isOpen} close={closeForm}>
      <DrawerHeader>Create new MGA Period</DrawerHeader>
      <FormDrawer>
        <FormBody>
          <div className="rounded bg-light-gray text-violet mt-1 mb-3 cps-12 cpe-12 py-1 d-flex align-items-center">
            <strong className="fs-12 fw-medium">
              * Please fill in starting & ending date of MGA period.
            </strong>
          </div>
          {isOpen ? <Body formState={formState} loadValues={loadValues} initialPeriod /> : null}
        </FormBody>
        <FormFooter className="p-3 d-flex justify-content-end">
          <Button color="cancel" className="px-0 py-1 me-4" onClick={closeForm} disabled={isSaving}>
            CANCEL
          </Button>
          <Button onClick={onSubmit} disabled={isSaving} color="primary" className="px-4">
            SAVE
          </Button>
        </FormFooter>
      </FormDrawer>
    </Drawer>
  );
};

export default Form;
