import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { File } from 'common/entities/files/FileTypes';
import { isFileSynced } from '@/api/files/api';
import { getFileType } from '@/ts-common/utils/files';
import ProfileImage from 'common/components/general/ProfileImage';

const Attachments = ({ value }) => {
  if (!value?.length > 0) return null;

  const file = value?.[0]; // we display only a single file
  const { is_synced } = isFileSynced(file);
  const { type } = getFileType(file?.mime_type, file?.extension);

  return (
    <div className="cpt-12">
      {is_synced && type === 'image' ? (
        <Preview>
          <ProfileImage image={file.url} />
        </Preview>
      ) : (
        <UploadFiles files={file} size="sm" singleUpload viewOnly />
      )}
    </div>
  );
};

Attachments.propTypes = {
  value: PropTypes.arrayOf(File)
};

const Preview = styled.div`
  .profile-image-wrap {
    height: auto;
    max-width: 100%;
    border-radius: 7px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    > img {
      height: auto;
      max-width: 100%;
      object-fit: contain;
      border-radius: 7px;
    }
  }
`;

export default Attachments;
