import React from 'react';
import { Button } from 'reactstrap';

const FileButton = ({ className = '', children, size = 'sm', ...rest }) => {
  return (
    <Button
      type="button"
      color="white"
      className={`download-attachment-btn border-0 p-0 d-inline-flex align-items-center justify-content-center ${className}`}
      size={size}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default FileButton;
