import React from 'react';

import CredentialsLogin from './credentials';

const Loginpage = () => {
  return (
    <div className={`login-page`}>
      <CredentialsLogin />
    </div>
  );
};

export default Loginpage;
