import TYPES from './types';
import { get, put, download } from 'utils/api';
import JOB_TYPES from 'common/components/jobs/_base/store/types';
import { updateJobInTable } from 'common/components/jobs/_base/store/actions';

export const getMaintenance = params => dispatch => {
  const { jobId } = params;
  dispatch({ type: TYPES.GET_MAINTENANCE.START });

  return get(`/jobs/${jobId}/maintenance`)
    .then(response => {
      dispatch({
        type: TYPES.GET_MAINTENANCE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_MAINTENANCE.ERROR, payload: error }));
};

export const updateMaintenance = params => (dispatch, getState) => {
  const state = getState();

  if (!state.jobs.id) return;

  dispatch({ type: TYPES.UPDATE_MAINTENANCE.START });
  dispatch(updateJobInTable({ ...params, id: state.jobs.id }));

  const { attached_manuals, field, ...rest } = params;

  const formattedParams = {
    attachments: attached_manuals ? attached_manuals?.map(e => e.id) || [] : undefined,
    ...rest
  };

  return put(`/jobs/${state.jobs.id}/maintenance`, formattedParams)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_MAINTENANCE.SUCCESS, payload: response.data });
      dispatch(updateJobInTable(response.data));

      if (field) {
        dispatch({
          type: JOB_TYPES.UPDATE_JOB.SUCCESS,
          payload: { id: state.jobs.id, due_on_rh: response.data.due_on_rh }
        });
      }

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_MAINTENANCE.ERROR, payload: error });
      throw error;
    });
};

export const setMaintenanceField =
  (field, value, autoSave, updateTable = false) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: TYPES.SET_MAINTENANCE_FIELD, payload: { field, value } });

    if (autoSave && state.jobs.id) {
      try {
        const response = await dispatch(
          updateMaintenance({ [field]: value, field: updateTable ? field : null })
        );

        return response.data;
      } catch (error) {
        return null;
      }
    }
  };

export const addMaintenanceSparePart = type => dispatch => {
  dispatch({ type: TYPES.ADD_MAINTENANCE_SPARE_PART, payload: { type } });
};

export const removeMaintenanceSparePart = (index, type) => dispatch => {
  dispatch({ type: TYPES.REMOVE_MAINTENANCE_SPARE_PART, payload: { index, type } });
};

export const setMaintenanceSparePart =
  ({ name, value, index }, type) =>
  dispatch => {
    dispatch({ type: TYPES.SET_MAINTENANCE_SPARE_PART, payload: { name, value, index, type } });
  };

export const initializePmsSpareParts = data => dispatch => {
  dispatch({ type: TYPES.INITIALIZE_PMS_SPARE_PARTS, payload: data });
};

export const initializeConsumedSpareParts = data => dispatch => {
  dispatch({ type: TYPES.INITIALIZE_CONSUMED_SPARE_PARTS, payload: data });
};

export const resetSpareParts = () => dispatch => {
  dispatch({ type: TYPES.RESET_SPARE_PARTS });
};

export const downloadAttachedManuals = params => dispatch => {
  dispatch({
    type: TYPES.DOWNLOAD_ATTACHED_MANUALS.START,
    payload: { params }
  });

  return download(`/files/entity`, { id: params.id, type: 'job_maintenance_details' })
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_ATTACHED_MANUALS.SUCCESS,
        payload: { data: response.data, params }
      });
      return response;
    })
    .catch(error =>
      dispatch({
        type: TYPES.DOWNLOAD_ATTACHED_MANUALS.ERROR,
        payload: { error, params }
      })
    );
};

export const setSparePartsCollapsed = collapsed => dispatch => {
  dispatch({ type: TYPES.SET_SPARE_PARTS_COLLAPSED, payload: collapsed });
};
