import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import useLogout from 'utils/hooks/useLogout';
import Sidebar from './sidebar';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import { selectAccount } from 'store/account/selectors';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { getAccount, getAccountPermissions } from 'store/account/actions';
import { getVesselInfo } from 'store/vessels/actions';
import { fetchListOptions } from 'store/lists/actions';
import { getSettings } from '@/store/settings/actions';

const ContentWrapper = ({ children, routes }) => {
  const [appLayout, setAppLayout] = useState(false);

  const dispatch = useAppDispatch();
  const authenticated = useSelector(selectIsAuthenticated);
  const account = useSelector(selectAccount);
  const { location } = useRouter();

  useEffect(() => {
    if (authenticated) {
      const activeRoute = routes.find(route => {
        if (location.pathname === '/') return route.path === '/';

        const parts = location.pathname.split('/');
        return (typeof route.path === 'string' ? [route.path] : route.path).some(path =>
          path.startsWith(`/${parts[1]}${parts[2] ? `/` : ''}`)
        );
      });
      if (activeRoute && activeRoute.app) {
        setAppLayout(true);
      } else {
        setAppLayout(false);
      }
    } else {
      setAppLayout(false);
    }
  }, [authenticated, location.pathname, routes]);

  useEffect(() => {
    if (authenticated && !account.id) {
      // runs only once, after login
      dispatch(getAccount());
      dispatch(getVesselInfo());
    } else if (!authenticated && appLayout) {
      setAppLayout(false);
    }
  }, [authenticated, account, appLayout, dispatch]);

  //Do the initial fetches - runs on refresh
  useEffect(() => {
    if (authenticated && account.id) {
      dispatch(getSettings());
      dispatch(fetchListOptions('job-statuses'));
      dispatch(fetchListOptions('job-importances'));
      dispatch(fetchListOptions('units'));
      dispatch(getAccountPermissions());

      if (!account?.party) dispatch(getAccount());
    }
  }, [authenticated, account.id, account?.party, dispatch]);

  useLogout(authenticated);

  return (
    <div className="content-wrapper">
      {appLayout && <Sidebar />}
      {children}
    </div>
  );
};

export default ContentWrapper;
