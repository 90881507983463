import { Button } from 'reactstrap';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import BasicModal from 'common/components/modals/BasicModal';
import SvgRender from 'common/components/general/SvgRender';

import arrayMove from 'array-move';
import columnsIcon from 'common/assets/svg/table/columns.svg';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import rearrange from 'common/assets/svg/actions/rearrange.svg';

import useColumnsReorder from './useColumnsReorder';
import Tooltip from 'common/components/general/Tooltip';

export const DragHandler = SortableHandle(() => (
  <div>
    <SvgRender className="pointer" src={rearrange} style={{ width: 6, height: 10 }} />
  </div>
));

export const ColumnRow = ({ value, updateColumn }) => (
  <>
    <div className="flex-fill ms-3 fw-normal">{value.header || value.reorderColumnHeader}</div>
    {updateColumn ? (
      <div className="ms-auto d-inline-flex pointer" onClick={() => updateColumn(value)}>
        <SvgRender
          className={`text-${!value.isVisible ? 'placeholder' : 'blue'}`}
          src={eyeSvg}
          style={{ width: 18, height: 18 }}
        />
      </div>
    ) : null}
  </>
);

export const SortableItem = SortableElement(({ value, updateColumn }) => (
  <div className="table-columns-rearrange--value d-flex align-items-center">
    <DragHandler />
    <ColumnRow value={value} updateColumn={updateColumn} />
  </div>
));

export const SortableList = SortableContainer(({ columns, updateColumn }) => {
  return (
    <div className="table-columns-rearrange">
      {columns.map((col, index) => {
        if (!col.key || col.key?.endsWith('actions') || (!col.header && !col.reorderColumnHeader))
          return null;
        return (
          <SortableItem
            key={`item-${col.key}`}
            index={index}
            value={col}
            updateColumn={updateColumn}
          />
        );
      })}
    </div>
  );
});

export const ModalWrapper = ({ state, modalOpen, setModalOpen, handleSave, body }) => {
  const tableIsInvalid = state.invalid === undefined ? false : state.invalid;

  return (
    <div className="d-inline-flex">
      <Button
        color="white"
        id="reorder-col"
        className="height-24 width-24 p-0 border-0 cms-12 d-inline-flex align-items-center justify-content-center column-reorder"
        type="button"
        size="sm"
        onClick={() => (!tableIsInvalid ? setModalOpen(true) : {})}
        disabled={tableIsInvalid}
      >
        <SvgRender src={columnsIcon} style={{ width: 12, height: 12 }} />
      </Button>
      <Tooltip target="reorder-col">Customize Columns</Tooltip>

      <BasicModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        header="Customize Columns"
        body={body}
        footer={
          <div className="d-flex align-items-center">
            <Button color="cancel" onClick={() => setModalOpen(false)}>
              CANCEL
            </Button>
            <Button color="primary" onClick={() => handleSave()}>
              SAVE
            </Button>
          </div>
        }
      />
    </div>
  );
};

const Columns = ({ columns, state }) => {
  const {
    modalOpen,
    setModalOpen,
    sortableColumns,
    setSortableColumns,
    staticColumns,
    handleSave,
    updateColumn
  } = useColumnsReorder(columns);

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      state={state}
      handleSave={handleSave}
      body={
        <div>
          {staticColumns.map(col => {
            if (col.key.endsWith('actions') || !col.header) return null;
            return (
              <div
                key={`item-${col.key}`}
                className="table-columns-static--value d-flex align-items-center"
              >
                <ColumnRow value={col} />
              </div>
            );
          })}

          <SortableList
            helperClass="table-columns-rearrange--value-sortable"
            useDragHandle={true}
            columns={sortableColumns}
            onSortEnd={({ oldIndex, newIndex }) =>
              setSortableColumns(arrayMove(sortableColumns, oldIndex, newIndex))
            }
            updateColumn={updateColumn}
          />
        </div>
      }
    />
  );
};

export default Columns;
