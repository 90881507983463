import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setMessages,
  setUnreadMessages,
  postMessage,
  setMarkAsRead,
  setFetching
} from 'common/components/chatbox/store/actions';

export const createEvent = params => dispatch => {
  dispatch({ type: TYPES.CREATE_EVENT.START, payload: { params } });

  const { id, ...rest } = params;

  return post('/events', rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_EVENT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_EVENT.ERROR, payload: error });
    });
};

export const editEvent = params => dispatch => {
  dispatch({ type: TYPES.EDIT_EVENT.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/events/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_EVENT.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_EVENT.ERROR, payload: error });
    });
};

export const getEvent = params => dispatch => {
  dispatch({ type: TYPES.GET_EVENT.START, payload: { params } });

  const { id, ...rest } = params;

  return get(`/events/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_EVENT.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_EVENT.ERROR, payload: error });
    });
};

export const deleteEvent = params => dispatch => {
  const { id } = params;

  dispatch({ type: TYPES.DELETE_EVENT.START, payload: { params } });

  return deleteRequest(`/events/${id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_EVENT.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_EVENT.ERROR, payload: error });
    });
};

export const addModule = params => dispatch => {
  const { id, mod, isCreate, ...rest } = params;

  dispatch({ type: TYPES.ADD_MODULE.START, payload: { mod, isCreate } });

  if (isCreate) {
    return;
  }

  return post(`/events/${id}/modules`, rest)
    .then(response => {
      dispatch({ type: TYPES.ADD_MODULE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.ADD_MODULE.ERROR, payload: error });
    });
};

export const updateModule = params => dispatch => {
  const { id, ...rest } = params;

  return put(`/event-modules/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_MODULE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_MODULE.ERROR, payload: error });
      throw error;
    });
};

export const removeModule = params => dispatch => {
  const { id, isCreate, labelToDelete, ...rest } = params;

  dispatch({ type: TYPES.REMOVE_MODULE.START, payload: { labelToDelete, isCreate } });

  if (isCreate) {
    return;
  }

  return deleteRequest(`/event-modules/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.REMOVE_MODULE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.REMOVE_MODULE.ERROR, payload: error });
      throw error;
    });
};

export const removeAllModules = () => dispatch => {
  dispatch({ type: TYPES.REMOVE_ALL_MODULES });
};

export const submitEvent = params => dispatch => {
  dispatch({ type: TYPES.SUBMIT_EVENT.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/events/${id}/submit`, rest)
    .then(response => {
      dispatch({ type: TYPES.SUBMIT_EVENT.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.SUBMIT_EVENT.ERROR, payload: error });
    });
};

/* DIGITAL SIGNATURE ACTIONS */

export const createEventDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.CREATE_EVENT_DIGITAL_SIGNATURE.START, payload: { params } });
  const { id, ...rest } = params;

  return post(`events/${id}/signatures`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_EVENT_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_EVENT_DIGITAL_SIGNATURE.ERROR, payload: error });
      return error;
    });
};

export const deleteEventDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.DELETE_EVENT_DIGITAL_SIGNATURE.START, payload: { params } });

  const { id, signature_id } = params;

  return deleteRequest(`events/${id}/signatures/${signature_id}`)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_EVENT_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_EVENT_DIGITAL_SIGNATURE.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const getEventDigitalSignatures = params => dispatch => {
  const { id } = params;
  dispatch({ type: TYPES.GET_EVENT_DIGITAL_SIGNATURES.START, payload: { params } });

  return get(`events/${id}/signatures`)
    .then(response => {
      dispatch({
        type: TYPES.GET_EVENT_DIGITAL_SIGNATURES.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_EVENT_DIGITAL_SIGNATURES.ERROR, payload: error });
      return error;
    });
};

export const getEventPeriodicity = createAsyncThunk(
  'GET_EVENT_PERIODICITY',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get('/events/recurring', { event_id: id });

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setEventWatchers = createAsyncThunk(
  'SET_EVENT_WATCHERS',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await put(`/events/${id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const changeEventValue = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.SET_EVENT_VALUE, payload: rest });
};

export const setErrors = params => dispatch => {
  dispatch({ type: TYPES.SET_EVENT_ERRORS, payload: params });
};

export const resetEventState = params => dispatch => {
  dispatch({ type: TYPES.RESET_EVENT_STATE, payload: params });
};

// Modules

export const setIsEditingModules = params => dispatch => {
  dispatch({ type: TYPES.SET_IS_EDITING_MODULES, payload: params });
};

export const setActiveModulesLabels = params => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_MODULES_LABELS, payload: params });
};

export const reorderModules = (oldIndex, newIndex) => dispatch => {
  dispatch({ type: TYPES.REORDER_MODULES, payload: { oldIndex, newIndex } });
};

export const setIsOnBoard = params => dispatch => {
  dispatch({ type: TYPES.SET_IS_ON_BOARD, payload: params });
};

export const setIsLocked = params => dispatch => {
  dispatch({ type: TYPES.SET_IS_LOCKED, payload: params });
};

export const resetMultipleEventsDates = params => dispatch => {
  dispatch({ type: TYPES.RESET_MULTIPLE_EVENTS_DATES });
};
export const resetSingleEventDates = params => dispatch => {
  dispatch({ type: TYPES.RESET_SINGLE_EVENT_DATES });
};

export const setIsMultipleEventsCreate = params => dispatch => {
  dispatch({ type: TYPES.SET_IS_MULTIPLE_EVENTS_CREATE, payload: params });
};

// CHATBOX

export const getEventChatMessages = createAsyncThunk(
  'GET_EVENT_CHAT_MESSAGES',
  async (params, { rejectWithValue, dispatch }) => {
    const { id } = params;
    dispatch(setFetching(true));

    try {
      const res = await get(`/events/${id}/messages`, params);
      dispatch(setMessages(res.data));
      dispatch(setFetching(false));

      return res.data;
    } catch (e) {
      dispatch(setFetching(false));
      return rejectWithValue(e);
    }
  }
);

export const sendEventChatMessage = createAsyncThunk(
  'SEND_EVENT_CHAT_MESSAGE',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/events/${id}/messages`, rest);
      dispatch(postMessage(res.data));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getEventUnreadChatMessages = createAsyncThunk(
  'GET_EVENT_UNREAD_CHAT_MESSAGES',
  async (params, { rejectWithValue, dispatch }) => {
    const { id } = params;

    try {
      const res = await get(`/events/${id}/messages/get-unread`);
      dispatch(setUnreadMessages(res.data));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const markEventChatMessagesAsRead = createAsyncThunk(
  'MARK_EVENT_CHAT_MESSAGES_AS_READ',
  async (params, { rejectWithValue, dispatch }) => {
    const { id } = params;

    try {
      const res = await put(`/events/${id}/messages/mark-as-read`);
      dispatch(setMarkAsRead(res.data));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
