import { React } from 'react';
import ModuleWrapper from '../components/ModuleWrapper';

import { useDrawer } from 'common/components/drawer';
import LinkedPurchaseCasesTable from './table';
import LinkToExistingCaseButton from 'events/event/modules/module-components/linked-purchase-cases/LinkToExistingCaseButton';
import Form from 'events/event/modules/module-components/linked-purchase-cases/form';
import { getLinkedPurchaseCases } from 'events/store/event-modules/linked-purchase-cases/actions';
import { selectEventId, selectIsCreate } from 'events/store/events/selectors';
import { useTable } from 'common/components/table';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from 'events/event/modules/module-components/linked-purchase-cases/table/columns';

const LinkedPurchaseCases = () => {
  const drawer = useDrawer(false);

  const eventId = useSelector(selectEventId);

  const isCreate = useSelector(selectIsCreate);

  const dispatch = useDispatch();

  const fetchData = async params => {
    if (!eventId) return;

    try {
      await dispatch(getLinkedPurchaseCases({ id: eventId, ...params })).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const table = useTable({
    label: 'events_linked_purchase_cases',
    requestTableListFrom: fetchData,
    defaultRequestParams: { paging: false, filters: false, sorting: true },
    columns: columns
  });

  return (
    <ModuleWrapper className="events-accounts" type="purchasing_requisitions">
      <LinkedPurchaseCasesTable table={table} />

      <Form drawer={drawer} refetchData={table.refetchData} />

      {!isCreate ? <LinkToExistingCaseButton drawer={drawer} /> : null}
    </ModuleWrapper>
  );
};

export default LinkedPurchaseCases;
