import { UncontrolledTooltip } from 'reactstrap';
import { FC, ReactNode } from 'react';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const Name = React.memo(
  ({
    isLast,
    name,
    customSeparator
  }: {
    isLast: boolean;
    name: NameInTooltipType;
    customSeparator?: string;
  }) => (
    <>
      {name}
      {isLast ? '' : customSeparator ? customSeparator : ', '}
    </>
  )
);
Name.displayName = 'Name';

const Tag = React.memo(
  ({
    name,
    isLast,
    isHidden,
    customSeparator
  }: {
    isLast: boolean;
    isHidden?: boolean;
    name: NameInTooltipType;
    customSeparator?: string;
  }) =>
    isHidden ? (
      <div className={'flex-column justify-content-center d-inline-flex'}>
        <Name isLast={isLast} name={name} />
      </div>
    ) : (
      <Name isLast={isLast} name={name} customSeparator={customSeparator} />
    )
);
Tag.displayName = 'Tag';

type NameInTooltipType = string | ReactNode;

type NamesInTooltipProps = {
  names: NameInTooltipType[];
  containerClassName?: string;
  tooltipTargetClassName?: string;
  className?: string;
  maxHeight?: React.CSSProperties['maxHeight'];
  maxLimit?: number;
  showThreeDots?: boolean;
  customSeparator?: string;
  allowOverflow?: boolean;
  renderTooltipContent?: (hiddenNames: NameInTooltipType[]) => ReactNode;
  customIndicator?: (hiddenNamesCount: number, tooltip: string) => ReactNode;
};

const NamesInTooltip: FC<NamesInTooltipProps> = ({
  names = [],
  containerClassName = '',
  tooltipTargetClassName = '',
  allowOverflow = false,
  maxHeight = 18,
  maxLimit = allowOverflow ? names.length : 1,
  renderTooltipContent,
  customSeparator,
  showThreeDots = true,
  customIndicator
}) => {
  const { avoidRender, tooltipID } = useTooltipID('names-in-tooltip');
  const innerRef = useRef<HTMLDivElement>(null);
  const [hiddenItems, setHiddenItems] = useState<NameInTooltipType[]>([]);
  const [hiddenNames, setHiddenNames] = useState<NameInTooltipType[]>([]);

  useEffect(() => {
    setHiddenItems(names.slice(maxLimit, names?.length));
  }, [names, maxLimit]);

  useEffect(() => {
    const hidden = hiddenItems.map((_, index) => {
      const childNode = innerRef.current?.childNodes[index];

      // Cast the ChildNode to an HTMLElement to access innerText
      if (childNode instanceof HTMLElement) {
        return childNode.innerText.replace(',', '');
      }
      return '';
    });

    setHiddenNames(hidden);
  }, [hiddenItems]);

  const visibleNames = useMemo(() => [...names].slice(0, maxLimit), [names, maxLimit]);
  const hiddenNamesCount = names.length - visibleNames.length;

  const tags = visibleNames.map((name, index) => (
    <Tag
      key={index}
      name={name}
      isLast={index === visibleNames?.length - 1}
      customSeparator={customSeparator}
    />
  ));

  return (
    <div
      className={`names-in-tooltip overflow-hidden d-flex flex-nowrap ${containerClassName}`}
      style={{ maxHeight: allowOverflow ? '100%' : maxHeight }}
    >
      <div ref={innerRef} className="d-none">
        {hiddenItems.map((name, index) => (
          <Tag key={index} name={name} isLast={index === hiddenItems?.length - 1} isHidden />
        ))}
      </div>

      <div
        className={`names-in-tooltip__inner d-flex align-items-center overflow-hidden ${
          showThreeDots ? '' : 'flex-wrap'
        }${maxLimit === 1 && names?.length === 1 ? 'max-width-100p' : ''}`}
      >
        {allowOverflow ? tags : showThreeDots ? <TextWithTooltip>{tags}</TextWithTooltip> : tags}
      </div>

      {hiddenNamesCount > 0 && !avoidRender && !allowOverflow ? (
        <div
          className={`names-in-tooltip__indicator ${
            customIndicator ? 'max-width-none' : ''
          } d-flex align-items-center justify-content-end overflow-hidden ${
            hiddenItems.length ? '' : 'invisible'
          }`}
        >
          {customIndicator ? (
            customIndicator(hiddenNamesCount, tooltipID)
          ) : (
            <strong
              id={tooltipID}
              className={`fs-10 lh-1 ${tooltipTargetClassName || ''} cursor-pointer text-truncate`}
            >
              +{hiddenNamesCount}
            </strong>
          )}

          <UncontrolledTooltip
            placement="top"
            boundariesElement="window"
            target={tooltipID}
            innerClassName="show-more-tooltip"
            className="multiple-names-tooltip"
          >
            {renderTooltipContent ? renderTooltipContent(hiddenNames) : hiddenNames.join(', ')}
          </UncontrolledTooltip>
        </div>
      ) : null}
    </div>
  );
};

export default NamesInTooltip;
