import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import useFilterSelector from 'common/components/filters/useFilterSelector';

import eventIcon from 'common/assets/svg/common/events.svg';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const TypeSelector = ({
  value,
  filter,
  showIcon = false,
  styled,
  onChange,
  isMulti,
  label,
  listParams,
  placeholder = 'Select value',
  ...rest
}) => {
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'event-types', listParams },
    { selected: selectedEventTypes, setSelected: setSelectedEventTypes }
  );

  const SelectTag = showIcon ? IconSelect : styled ? Select : Select;

  return (
    <SelectTag
      placeholder={placeholder}
      label={label}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      size="sm"
      icon={showIcon ? eventIcon : null}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? selectedEventTypes.filter(el => filter?.value.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() => getInitialAsyncValues('event-types')}
      loadOptions={search => getAsyncOptions(search, 'event-types')}
      onChange={selected => {
        setSelectedEventTypes(selected);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      isClearable
      {...rest}
    />
  );
};

export default TypeSelector;
