import { numberToStr, strToNumber } from 'common/utils/numbers';
import { getInitialSingleValue } from 'common/utils/lists';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import _reduce from 'lodash/reduce';

export const showActionTypeInventoryItems = actionType => {
  const isDebit = actionType?.has_debit_stores;
  const isCredit = actionType?.has_credit_stores;

  return (
    (isDebit && actionType?.debit_type === 'coa') || (isCredit && actionType?.credit_type === 'coa')
  );
};

export const parseMgaActionsColumns = (params, currencies, defaultCurrencyLabel) => {
  const { action_type } = params;

  const hasInventoryItems = showActionTypeInventoryItems(action_type);
  const isDebit = action_type?.has_debit_stores;
  const isSeamanColumnVisible =
    action_type?.debit_type === 'crew' || action_type?.credit_type === 'crew';
  const seamanColumnKey = action_type?.debit_type === 'crew' ? 'debit_account' : 'credit_account';
  const isBankAccountSelectionEnabled = action_type?.is_bank_account_selection_enabled;

  const isAccountColumnVisible =
    action_type?.debit_type === 'sub' || action_type?.credit_type === 'sub';
  const accountColumnKey = action_type?.debit_type === 'sub' ? 'debit_account' : 'credit_account';

  const columns = [
    {
      header: 'DATE',
      type: 'date',
      key: 'timestamp',
      width: 146,
      totalsCellClassName: 'col-1 pe-0',
      text_align: 'left',
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      sort: true
    },
    {
      header: 'ACTION TYPE',
      type: 'string',
      key: 'action_type',
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      width: 584,
      totalsCellClassName: 'col-4 px-0',
      hidden: action_type !== undefined,
      sort: true
    },
    {
      header: 'SEAMAN',
      type: 'string',
      key: `${seamanColumnKey}_id`,
      data_key: `${seamanColumnKey}.name`,
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      hidden: !isSeamanColumnVisible,
      ...((isSeamanColumnVisible && isAccountColumnVisible) || hasInventoryItems
        ? {
            width: 292,
            totalsCellClassName: 'col-2 px-0'
          }
        : isBankAccountSelectionEnabled
        ? {
            width: 438,
            totalsCellClassName: 'col-3 px-0'
          }
        : {
            width: 584,
            totalsCellClassName: 'col-4 px-0'
          })
    },
    {
      header: 'BANK ACCOUNT',
      type: 'string',
      key: 'bank_account',
      width: isAccountColumnVisible ? 218 : 356,
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      hidden: !isBankAccountSelectionEnabled
    },
    {
      header: 'ACCOUNT',
      type: 'string',
      key: `${accountColumnKey}_id`,
      data_key: `${accountColumnKey}.name`,
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      hidden: !isAccountColumnVisible,
      ...((isSeamanColumnVisible && isAccountColumnVisible) || hasInventoryItems
        ? { grid_column_width: 2, totalsCellClassName: 'col-2 px-0' }
        : { grid_column_width: 4, totalsCellClassName: 'col-4 px-0' })
    },
    {
      header: 'DESCRIPTION',
      type: 'string',
      key: 'description',
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      ...(!isSeamanColumnVisible && !isAccountColumnVisible && !hasInventoryItems
        ? {
            width: action_type !== undefined ? 1314 : 730,
            totalsCellClassName: `col-${action_type !== undefined ? '9' : '5'} px-0`
          }
        : hasInventoryItems
        ? {
            width: isSeamanColumnVisible ? 292 : 584,
            totalsCellClassName: `col-${isSeamanColumnVisible ? '2' : '4'} px-0`
          }
        : isBankAccountSelectionEnabled
        ? {
            width: 510
          }
        : {
            width: 730,
            totalsCellClassName: `col-5 px-0`
          })
    },
    {
      header: isDebit
        ? 'ITEMS ADDED TO INVENTORY'
        : `ITEMS REMOVED FROM INVENTORY - PRICE PER UNIT (${defaultCurrencyLabel})`,
      type: 'string',
      key: 'items',
      bodyClassName: 'cps-12 py-1 align-items-start',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      hidden: !hasInventoryItems,
      grid_column_width: 3,
      totalsCellClassName: 'col-3 px-0'
    },
    {
      header: `PRICE PER UNIT SOLD (${defaultCurrencyLabel})`,
      type: 'price',
      key: 'items_price',
      bodyClassName: 'cps-12 py-1',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      hidden: !hasInventoryItems,
      width: 146,
      totalsCellClassName: 'col-1 px-0'
    },
    {
      header: 'QUANTITY',
      type: 'price',
      key: 'items_quantity',
      bodyClassName: 'cps-12 py-1',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      hidden: !hasInventoryItems,
      width: 146,
      totalsCellClassName: 'col-1 px-0'
    },
    ...currencies.map(cur => ({
      header: 'AMOUNT',
      subLabel: cur.label,
      type: 'price',
      key: `local_amount_${cur.id}`,
      bodyClassName: 'cps-12 py-1',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      width: 146,
      totalsCellClassName: 'col-1 px-0',
      total_key: `amount_${cur.id}`,
      highlight: true
    })),
    {
      header: 'AMOUNT',
      subLabel: defaultCurrencyLabel,
      type: 'price',
      key: 'amount',
      bodyClassName: 'cps-12 py-1',
      headerClassName: 'cps-12 border-bottom-0 pb-0',
      width: 146,
      totalsCellClassName: 'col-1 px-0',
      total_key: 'amount',
      highlight: true
    },
    {
      header: 'Actions',
      key: 'actions',
      avoidColumnHover: true,
      bodyClassName: 'cps-12 py-1 align-items-start position-sticky cell-actions right-0',
      headerClassName: 'cps-12 border-bottom-0 pb-0 text-center cell-actions',
      width: 146,
      totalsCellClassName: 'px-0 col-1 action-totals position-sticky right-0',
      text_align: 'center'
    }
  ];

  return columns;
};

export const parseMgaActionsData = ({ data, totals }) => {
  return {
    data,
    totals: { ...totals, amount: numberToStr(totals?.amount, 2, 2) }
  };
};

export const parseMgaAccountsColumns = async (
  params,
  isSummary = false,
  currencies = [],
  defaultCurrencyLabel
) => {
  let columns = [];
  const { filters } = params;
  const accountId = filters?.find(e => e.name === 'account_id')?.value;

  try {
    const mgaAccount = await getInitialSingleValue('/mga/accounts', {
      ids: [accountId]
    });

    if (!isSummary) {
      columns = [
        {
          header: 'DATE',
          type: 'date',
          key: 'timestamp',
          width: 146,
          sticky: true,
          left: 0,
          totalsCellClassName: 'col-1 pe-0',
          text_align: 'left',
          className: 'cps-12',
          sort: true
        },
        {
          header: 'ACTION',
          type: 'string',
          key: 'action_type',
          data_key: 'action.action_type.name',
          bodyClassName: 'cps-12 py-1',
          sticky: true,
          left: 146,
          headerClassName: 'cps-12 border-bottom-0 pb-0',
          width: 438,
          totalsCellClassName: 'col-3 px-0',
          sort: true
        },
        {
          header: 'OTHER ACCOUNT',
          type: 'string',
          key: 'other_account',
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'cps-12 border-bottom-0 pb-0',
          width: 292,
          totalsCellClassName: `col-2 px-0`
        },
        {
          header: 'DESCRIPTION',
          type: 'string',
          key: 'action.description',
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'cps-12 border-bottom-0 pb-0',
          width: 400,
          totalsCellClassName: `col-3 px-0`
        },
        ...currencies.map(cur => ({
          header: mgaAccount[0]?.crew_member_id ? 'SEAMAN DUE TO VESSEL' : 'DEBIT',
          subLabel: cur.label,
          type: 'price',
          key: `debit_${cur.id}`,
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 160,
          totalsCellClassName: 'col-1 px-0',
          total_key: `opening_debit_${cur.id}`,
          total_label: 'OPENING BALANCE',
          footer_total_key: `debit_${cur.id}`,
          footer_total_label: 'TOTALS',
          second_footer_total_key: `closing_debit_${cur.id}`,
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        })),
        {
          header: mgaAccount[0]?.crew_member_id ? 'SEAMAN DUE TO VESSEL' : 'DEBIT',
          subLabel: defaultCurrencyLabel,
          type: 'price',
          key: 'debit',
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 160,
          totalsCellClassName: 'col-1 px-0',
          total_key: 'opening_debit',
          total_label: 'OPENING BALANCE',
          footer_total_key: 'debit',
          footer_total_label: 'TOTALS',
          second_footer_total_key: 'closing_debit',
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        },
        ...currencies.map(cur => ({
          header: mgaAccount[0]?.crew_member_id ? 'VESSEL DUE TO SEAMAN' : 'CREDIT',
          subLabel: cur.label,
          type: 'price',
          key: `credit_${cur.id}`,
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 146,
          totalsCellClassName: 'col-1 px-0',
          total_key: `opening_credit_${cur.id}`,
          total_label: 'OPENING BALANCE',
          footer_total_key: `credit_${cur.id}`,
          footer_total_label: 'TOTALS',
          second_footer_total_key: `closing_credit_${cur.id}`,
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        })),
        {
          header: mgaAccount[0]?.crew_member_id ? 'VESSEL DUE TO SEAMAN' : 'CREDIT',
          subLabel: defaultCurrencyLabel,
          type: 'price',
          key: 'credit',
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 146,
          totalsCellClassName: 'col-1 px-0',
          total_key: 'opening_credit',
          total_label: 'OPENING BALANCE',
          footer_total_key: 'credit',
          footer_total_label: 'TOTALS',
          second_footer_total_key: 'closing_credit',
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        },
        {
          header: 'Actions',
          key: 'actions',
          bodyClassName: 'cps-12 py-1 position-sticky cell-actions right-0',
          headerClassName: 'cps-12 border-bottom-0 pb-0 text-center cell-actions',
          width: 146,
          totalsCellClassName: 'p-0 action-totals position-sticky right-0',
          text_align: 'center'
        }
      ];
    } else {
      columns = [
        {
          header: 'ACCOUNT',
          type: 'string',
          key: 'name',
          width: 1460,
          totalsCellClassName: 'col-8 pe-0',
          text_align: 'left',
          className: 'cps-12'
          //sort: true
        },
        ...currencies.map(cur => ({
          header: mgaAccount[0]?.crew_member_id ? 'SEAMAN DUE TO VESSEL' : 'DEBIT',
          subLabel: cur.label,
          type: 'price',
          key: `debit_${cur.id}`,
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 146,
          totalsCellClassName: 'col-3 px-0',
          total_key: `opening_debit_${cur.id}`,
          total_label: 'OPENING BALANCE',
          footer_total_key: `debit_${cur.id}`,
          footer_total_label: 'TOTALS',
          second_footer_total_key: `closing_debit_${cur.id}`,
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        })),
        {
          header: mgaAccount[0]?.crew_member_id ? 'SEAMAN DUE TO VESSEL' : 'DEBIT',
          subLabel: defaultCurrencyLabel,
          type: 'price',
          key: 'debit',
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 146,
          totalsCellClassName: 'col-3 px-0',
          total_key: 'opening_debit',
          total_label: 'OPENING BALANCE',
          footer_total_key: 'debit',
          footer_total_label: 'TOTALS',
          second_footer_total_key: 'closing_debit',
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        },
        ...currencies.map(cur => ({
          header: mgaAccount[0]?.crew_member_id ? 'VESSEL DUE TO SEAMAN' : 'CREDIT',
          subLabel: cur.label,
          type: 'price',
          key: `credit_${cur.id}`,
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 146,
          totalsCellClassName: 'col-1 px-0',
          total_key: `opening_credit_${cur.id}`,
          total_label: 'OPENING BALANCE',
          footer_total_key: `credit_${cur.id}`,
          footer_total_label: 'TOTALS',
          second_footer_total_key: `closing_credit_${cur.id}`,
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        })),
        {
          header: mgaAccount[0]?.crew_member_id ? 'VESSEL DUE TO SEAMAN' : 'CREDIT',
          subLabel: defaultCurrencyLabel,
          type: 'price',
          key: 'credit',
          bodyClassName: 'cps-12 py-1',
          headerClassName: 'px-0 border-bottom-0 pb-0',
          width: 146,
          totalsCellClassName: 'col-1 px-0',
          total_key: 'opening_credit',
          total_label: 'OPENING BALANCE',
          footer_total_key: 'credit',
          footer_total_label: 'TOTALS',
          second_footer_total_key: 'closing_credit',
          second_footer_total_label: 'CLOSING BALANCE',
          highlight: true
        }
      ];
    }
  } catch (err) {
    console.error(err);
  }

  return columns;
};

export const parseMgaAccountsData = ({ data, totals }) => {
  return {
    data,
    totals: {
      ...totals,
      debit: numberToStr(totals?.debit, 2, 2),
      credit: numberToStr(totals?.credit, 2, 2)
    }
  };
};

export const parseMgaInventoryItems = (data, params) => {
  return _orderBy(
    data,
    ['timestamp', 'name', 'item'],
    [params?.sorting?.timestamp || 'desc', 'asc', 'asc']
  );
};

export const getIsInventoryDepletionNotActive = (addedToInventory, depletionMethodLabel) => {
  // When account is to add items & depletion method is not fifo

  return addedToInventory || depletionMethodLabel !== 'fifo';
};

export const getCrewClosingBalanceWarnings = ({ data, period_id }) => {
  const warnings = (data || [])
    .filter(d => strToNumber(d.closing_balance) !== 0)
    .map(d => ({ ...d, period_id, uid: `${d.id}_${period_id}` }));

  return _uniqBy(warnings, 'uid');
};

export const getCrewOverviewWarnings = (tableWarnings, tableData) => {
  return _reduce(
    tableWarnings,
    (acc, value) => {
      const rowIndex = tableData.data.findIndex(
        c => c.id === value.crew_member_id && c.contract_id === value.crew_contract_id
      );

      acc[rowIndex] = value.warnings;

      return acc;
    },
    {}
  );
};
