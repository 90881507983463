import React, { useCallback, useEffect, useMemo } from 'react';

import useRouter from 'use-react-router';
import BalanceSheetReport from './balance-sheet';
import Layout from 'common/components/layouts/ReportLayout';
import { parseQueryParams } from 'utils/urls';
import { useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';

const MgaReports = () => {
  const { history, match } = useRouter();
  const isOnBoard = useSelector(state => state.isOnBoard);
  const options = useSelector(state => selectListOptionsFromStore(state, 'reports-mga'));

  const report = useMemo(
    () => parseQueryParams(history.location.search)?.report,
    [history.location.search]
  );

  useEffect(() => {
    if (!options?.length) return;

    if (isOnBoard) {
      history.push(`${history.location.pathname}?report=${options[0].label}`);
    } else {
      if (!match.params?.vesselID || !match.params?.periodID) return;

      history.push(`${history.location.pathname}?report=${options[0].label}`);
    }
  }, [history, isOnBoard, match.params?.periodID, match.params?.vesselID, options]);

  const reportToRender = useCallback(() => {
    switch (report) {
      case 'mga-balance-sheet':
        return <BalanceSheetReport />;

      default:
        return null;
    }
  }, [report]);

  return (
    <Layout className="mga-reports" type="mga" report={report} useLocationSearch={true}>
      {reportToRender()}
    </Layout>
  );
};

export default MgaReports;
