import PropTypes from 'prop-types';

export const Country = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  code: PropTypes.string
});

export const Currency = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  symbol: PropTypes.string
});
