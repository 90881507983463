import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import rankIcon from 'common/assets/svg/crew/rank.svg';
import { getFilterSelectorValues } from '../filters/helpers';

const CrewRankSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  autoFocus = true,
  showIcon = false,
  styled,
  className,
  ...rest
}) => {
  const [selectedRanks, setSelectedRanks] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'crew-ranks' },
    { selected: selectedRanks, setSelected: setSelectedRanks }
  );

  const SelectTag = showIcon ? IconSelect : styled ? StyledSelect : Select;

  return (
    <SelectTag
      autoFocus={autoFocus}
      placeholder="Select value"
      className={className || `mb-0`}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      icon={showIcon ? rankIcon : null}
      size="sm"
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selectedRanks, filter?.value, isMulti)
          : []
      }
      isMulti={isMulti}
      isAsync
      defaultOptions={() => getInitialAsyncValues('crew-ranks')}
      loadOptions={search => getAsyncOptions(search, 'crew-ranks')}
      onChange={selected => {
        setSelectedRanks(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      {...rest}
    />
  );
};

export default CrewRankSelector;
