import React from 'react';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import { Label } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';
import * as reportActions from 'captain-reports/store/actions';
import CheckboxWithTime from 'common/components/general/CheckboxWithTime';
import useValue from 'captain-reports/utils/useValue';
import useAutocompleteValues from 'captain-reports/utils/useAutocompleteValues';
import _debounce from 'lodash/debounce';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

export const getTimeValue = v => {
  if (!v && v !== 0) return null;
  let minutes = v % 60;
  let hours = Math.trunc(v / 60);

  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

const TimeInput = ({ field, tooltip, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const isLocked = useSelector(isReportLocked);

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onChange = time => {
    if (time.length === 5) {
      let hours = +time.split(':')[0];
      let minutes = +time.split(':')[1];

      let minutesTotal = minutes + hours * 60;
      setValue({
        key: field.key,
        value: minutesTotal,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    } else if (time.length === 0) {
      setValue({
        key: field.key,
        value: null,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  };

  const autocompleteArray = useAutocompleteValues(
    field.autocompleteValues ? field.autocompleteValues.map(el => ({ ...el, subGroupIndex })) : []
  );

  const debouncedAutoComplete = _debounce(() => {
    const result = field.autocomplete(...autocompleteArray);

    if (result !== value) {
      setValue({
        key: field.key,
        value: result,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, 600);

  useUpdateEffect(() => {
    if (field.autocomplete) {
      debouncedAutoComplete();
    }
  }, [...autocompleteArray]);

  return (
    <div className={`form-time-input form-group ${isLocked ? 'pointer-events-none' : ''} `}>
      <Label className="form-label fw-bold text-uppercase">
        <FieldLabel info={tooltip} label={field.label} subLabel={field.subLabel} />
      </Label>

      <CheckboxWithTime
        key={`${sectionLabel}_${sectionActiveTab}_${subGroup}_${subGroupIndex}`}
        hideCheckbox={true}
        time={getTimeValue(value)}
        onTimeChange={onChange}
        checkboxValue={true}
        alwaysShowTime={true}
        hasMaxTime={false}
        className={`d-flex align-items-center time-input position-relative`}
      />
    </div>
  );
};

export default TimeInput;
