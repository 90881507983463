import moment from 'moment';
import Counter from './Counter';
import EmpyState from './EmpyState';
import Loader from './Loader';
import MessagesBox from './MessagesBox';

import {
  areGlobalUnreadMessagesFetching,
  selectGlobalUnreadMessages
} from 'common/components/chatbox/store/selectors';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useActions } from 'utils/hooks';

import * as chatboxActions from 'common/components/chatbox/store/actions';

const UnreadMessages = () => {
  const isFetching = useSelector(areGlobalUnreadMessagesFetching);
  const messages = useSelector(selectGlobalUnreadMessages);

  const [getGlobalUnreadMessages] = useActions([chatboxActions.getGlobalUnreadMessages]);

  useEffect(() => {
    getGlobalUnreadMessages();
  }, [getGlobalUnreadMessages]);

  return (
    <div className="global-unread-chat-messages">
      <div className="global-unread-chat-messages__header mb-2 pb-2 d-flex align-items-center justify-content-between">
        <div className="flex-1">
          <div className="narrow-black fs-16 text-primary">Unread Messages</div>
          <div className="text-violet">{moment().format('DD MMM YYYY')}</div>
        </div>
        <Counter />
      </div>
      <div className="global-unread-chat-messages__body overflow-y">
        {isFetching && <Loader />}

        {messages.map((message, i) => (
          <MessagesBox message={message} key={i} />
        ))}
      </div>
      {!messages?.length && <EmpyState />}
    </div>
  );
};

export default UnreadMessages;
