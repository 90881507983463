import React, { useState, useEffect } from 'react';

import SvgRender from 'common/components/general/SvgRender';
import errorIcon from 'common/assets/svg/common/validation-error.svg';
import warningIcon from 'captain-reports/assets/svg/warning-empty.svg';
import closeIcon from 'common/assets/svg/actions/close.svg';
import CircledButton from 'common/components/buttons/CircledButton';
import OvalButton from 'common/components/buttons/OvalButton';

import useTooltipID from 'common/utils/hooks/useTooltipID';

import { Popover, PopoverBody } from 'reactstrap';
import Textarea from 'common/components/form/inputs/Textarea';

import { useSelector } from 'react-redux';
import { selectCaptainReportIsOnBoard } from 'captain-reports/store/selectors';

const getAllowedValuesText = templateValidation => {
  if (!templateValidation) return;

  const { limit, rule } = templateValidation;

  switch (rule) {
    case 'gt':
      return `> ${limit[0]}`;
    case 'gte':
      return `>= ${limit[0]}`;
    case 'lt':
      return `< ${limit[0]}`;
    case 'lte':
      return `<= ${limit[0]}`;
    case 'between':
      return `${limit[0]} - ${limit[1]}`;
    default:
      return '';
  }
};

const FieldErrorPopover = ({
  id,
  isOpen,
  toggle,
  onSaveComment,
  comment,
  templateValidation,
  isViewOnly,
  error,
  isVesselValidationValid,
  customAllowedOptions
}) => {
  const [localComment, setLocalComment] = useState('');

  useEffect(() => {
    setLocalComment(comment);
  }, [isOpen]);

  const onSave = () => {
    onSaveComment(localComment);
    toggle();
  };

  const allowedValuesText = getAllowedValuesText(templateValidation);

  return (
    <Popover
      placement="right"
      isOpen={isOpen}
      target={id}
      className="template-field__error--popover"
      toggle={toggle}
    >
      <PopoverBody>
        <div className="template-field__error--popover__header">
          <div className="d-flex align-items-center">
            <SvgRender src={warningIcon} style={{ height: 14, width: 14 }} />
            <span className="cms-12">Attention</span>
          </div>
          {isViewOnly ? (
            <div className="cursor-pointer" onClick={toggle}>
              <SvgRender src={closeIcon} style={{ height: 10, width: 10 }} />
            </div>
          ) : null}
        </div>
        <div className="template-field__error--popover__body mt-3">
          <div className="text-coral fs-12 lh-15 d-block mb-1">
            {allowedValuesText ? (
              <>
                Allowed Values: <span className="fw-bold">{allowedValuesText}</span>
                <br />
              </>
            ) : null}

            {error === 'Required' ? <span>Field is Required</span> : null}

            {customAllowedOptions ? (
              <>
                <span className="text-coral cme-4">{customAllowedOptions.title}</span>
                <span className="fw-bold">
                  {customAllowedOptions?.values?.map((e, index) => (
                    <span>
                      {e}
                      {index < customAllowedOptions.values?.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </span>
                <br />
              </>
            ) : null}
            {!isVesselValidationValid
              ? 'Hidden from vessel validation'
              : isViewOnly
              ? comment
                ? "Captain's notes:"
                : null
              : allowedValuesText
              ? 'Please explain why the value is out of range:'
              : ''}
          </div>

          {isViewOnly ? (
            <div className="fs-14 lh-15 text-primary">{comment}</div>
          ) : allowedValuesText ? (
            <Textarea
              placeholder="Write here"
              onChange={e => setLocalComment(e.target.value)}
              value={localComment}
            />
          ) : null}
        </div>
        {!isViewOnly && allowedValuesText ? (
          <div className="d-flex  align-items-center justify-content-end">
            <CircledButton type="close" onClick={toggle} />
            <OvalButton type="save" onClick={onSave} disabled={!localComment} className="ms-1" />
          </div>
        ) : null}
      </PopoverBody>
    </Popover>
  );
};

const FieldError = ({
  error,
  comment,
  onSaveComment,
  isLocked,
  isVesselValidationValid,
  validationTemplate,
  staticPosition = false,
  right,
  customAllowedOptions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { avoidRender, tooltipID } = useTooltipID('template-field__error');

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const isOnBoard = useSelector(selectCaptainReportIsOnBoard);

  const isViewOnly = isLocked || !isOnBoard || !isVesselValidationValid;

  if (avoidRender) return null;

  return (
    <>
      <div
        id={tooltipID}
        className={`template-field__error ${
          isOpen
            ? 'template-field__error--open'
            : comment && isOnBoard
            ? 'template-field__error--comment'
            : ''
        } ${staticPosition ? `position-static ${right ? 'cme-6' : 'cms-6'}` : ''}`}
        onClick={toggle}
      >
        <SvgRender src={errorIcon} style={{ width: 16, height: 16 }} />
      </div>
      <FieldErrorPopover
        isVesselValidationValid={isVesselValidationValid}
        templateValidation={validationTemplate}
        onSaveComment={onSaveComment}
        comment={comment}
        id={tooltipID}
        isViewOnly={isViewOnly}
        error={error}
        isOpen={isOpen}
        toggle={toggle}
        customAllowedOptions={customAllowedOptions}
      />
    </>
  );
};

export default FieldError;
