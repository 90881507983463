import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectFolder,
  selectFolderSubFolders,
  selectIsFolderExpanded,
  selectActiveFolderId,
  selectIsEditingFolder,
  selectIsAddingSubFolder,
  isFoldersInSearch,
  isFolderHighlighted,
  selectHasSearch,
  selectTreeSearch
} from 'common/components/filesystem/store/selectors';
import permissions from '@/common/utils/permissions/constants';

import TreeTitle from 'common/components/tree-layout/main/TreeTitle';
import TitleInput from 'common/components/tree-layout/components/TitleInput';

import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import { useActions } from 'utils/hooks';
import { highlightTextInTitle } from 'manuals/_helpers';

import * as filesystemActions from 'common/components/filesystem/store/actions';

const Name = ({ folder, hasSearch }) => {
  const searchValue = useSelector(state => (hasSearch ? selectTreeSearch(state) : null));

  return hasSearch && searchValue ? highlightTextInTitle(folder.name, searchValue) : folder.name;
};

const Title = ({ id }) => {
  const { history } = useRouter();

  const [
    expandFolder,
    setIsAddingSubFolder,
    setIsEditingFolder,
    createFolder,
    editFolder,
    setPreventionModal
  ] = useActions([
    filesystemActions.expandFolder,
    filesystemActions.setIsAddingSubFolder,
    filesystemActions.setIsEditingFolder,
    filesystemActions.createFolder,
    filesystemActions.editFolder,
    filesystemActions.setPreventionModal
  ]);

  const activeFolderId = useSelector(selectActiveFolderId);
  const subFolders = useSelector(state => selectFolderSubFolders(state, id));
  const folder = useSelector(state => selectFolder(state, id));
  const isOpen = useSelector(state => selectIsFolderExpanded(state, id));
  const isEditing = useSelector(state => selectIsEditingFolder(state, id));
  const isAddingSubFolder = useSelector(state => selectIsAddingSubFolder(state, id));
  const isVisible = useSelector(state => isFoldersInSearch(state, id));
  const isHighlighted = useSelector(state => isFolderHighlighted(state, id));
  const hasSearch = useSelector(selectHasSearch);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const onClick = useCallback(() => {
    history.replace(`${paths.filesystem}/folder/${folder.id}`);
  }, [folder.id]);

  const onExpand = () => expandFolder(id, !isOpen);

  const handleAction = useCallback((type, id) => {
    if (type === 'edit') {
      setIsEditingFolder(id, true);
    } else if (type === 'addSub') {
      setIsAddingSubFolder(id, true);
    } else {
      setPreventionModal(true, id);
    }
  }, []);

  const actions = useMemo(
    () => [
      {
        name: 'Edit',
        onClick: () => handleAction('edit', folder?.id),
        permissions: [permissions.office.files.edit]
      },
      {
        name: 'Add sub-folder',
        onClick: () => handleAction('addSub', folder?.id),
        permissions: [permissions.office.files.edit]
      },
      {
        name: 'Delete',
        onClick: () => handleAction('delete', folder?.id),
        permissions: [permissions.office.files.delete]
      }
    ],
    [folder?.id]
  );

  const cancelEditting = useCallback(() => setIsEditingFolder(folder?.id, false), [folder?.id]);

  const onEdit = useCallback(
    async name => {
      await editFolder({ name, id: folder?.id });

      cancelEditting();
    },
    [folder?.id]
  );

  const cancelCreating = useCallback(() => setIsAddingSubFolder(folder?.id, false), [folder?.id]);

  const onCreateSubFolder = useCallback(
    async name => {
      const res = await createFolder({ name, parent_id: folder?.id });

      cancelCreating();

      if (res?.id) history.replace(`${paths.filesystem}/folder/${res.id}`);
    },
    [folder?.id]
  );

  return isVisible ? (
    <div className="chapters-tree__view">
      {isEditing ? (
        <div className="chapters-tree__view-edit">
          <TitleInput initialTitle={folder.name} onCancel={cancelEditting} onSave={onEdit} />
        </div>
      ) : (
        <TreeTitle
          isActive={id === activeFolderId}
          hasChildren={!!subFolders}
          parent_id={folder.parent_id}
          title={<Name folder={folder} hasSearch={hasSearch} />}
          onClick={onClick}
          onExpand={onExpand}
          isOpen={isOpen}
          actions={isOnBoard ? null : actions}
          isHighlighted={isHighlighted}
          withFolder
        />
      )}

      {isAddingSubFolder ? (
        <div className="chapters-tree__view-add">
          <TitleInput onCancel={cancelCreating} onSave={onCreateSubFolder} />
        </div>
      ) : null}
    </div>
  ) : null;
};

export default Title;
