import React from 'react';
import { Alert } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import reopened from 'captain-reports/assets/svg/report-status/reopened.svg';

const ForCorrectionsAlert = ({ showIcon = true, message = 'For correction' }) => {
  return (
    <div className="for-corrections-alert">
      <Alert color="danger" isOpen>
        {showIcon ? (
          <SvgRender src={reopened} className="cme-12" style={{ width: 16, height: 16 }} />
        ) : null}
        <span className="fs-14 fw-normal lh-1">{message}</span>
      </Alert>
    </div>
  );
};

export default ForCorrectionsAlert;
