import { FC, ReactNode } from 'react';
import { FormGroup, Label, Input, InputProps } from 'reactstrap';

interface CheckboxProps extends Omit<InputProps, 'type'> {
  color?: string; // any bootrstrap theme color
  outline?: boolean;
  error?: string | null;
  className?: string;
  name?: string;
  value?: boolean;
  label?: ReactNode | string;
  labelClassName?: string;
  disabled?: boolean;
  dataCy?: string;
  checkMarkClassName?: string;
  style?: React.CSSProperties;
  circled?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  color = 'blue',
  outline,
  error,
  className,
  name,
  value,
  onChange,
  label,
  labelClassName,
  disabled,
  dataCy,
  checkMarkClassName,
  style,
  circled
}) => (
  <FormGroup
    noMargin
    className={`input-wrapper form-group justify-content-center ${error ? ' has-error' : ''}${
      className ? ` ${className}` : ''
    }`}
    style={style}
  >
    <FormGroup check className={`${circled ? 'circled' : ''}`}>
      <Label className={disabled ? 'disabled' : ''} check>
        <Input
          name={name}
          type="checkbox"
          checked={value}
          dataCy={dataCy}
          onChange={disabled ? () => null : onChange}
        />
        <span className={`checkbox-label fw-normal ${labelClassName}`}>{label}</span>
        <span
          className={`checkmark checkmark__${color} ${
            outline ? `outlined outlined__${color}` : ''
          } ${checkMarkClassName || ''}`}
        />
      </Label>
    </FormGroup>
  </FormGroup>
);

export default Checkbox;
