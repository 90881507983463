import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import { Option } from 'common/components/selectors/_partySelectComponents';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const PersonSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  listParams,
  showDefaultOptions = true,
  placeholder = 'Select value',
  ...rest
}) => {
  const [selectedPersons, setselectedPersons] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'parties', listParams },
    { selected: selectedPersons, setSelected: setselectedPersons }
  );

  return (
    <Select
      autoFocus
      className={`mb-0`}
      placeholder={placeholder}
      getOptionValue={option => option.id}
      getOptionLabel={option => getPartyDisplayName(option)}
      isAsync
      defaultOptions={() =>
        showDefaultOptions ? getInitialAsyncValues('parties', null, null, listParams) : []
      }
      isMulti={isMulti}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selectedPersons, filter?.value)
          : []
      }
      components={{ Option }}
      loadOptions={search => getAsyncOptions(search, 'parties', listParams)}
      onChange={selected => {
        setselectedPersons(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      {...rest}
    />
  );
};

export default PersonSelector;
