import { createSelector } from 'reselect';
import _findKey from 'lodash/findKey';
import _orderBy from 'lodash/orderBy';

const selectFilesystemReducer = state => state.filesystem;
const getKey = (_, key) => key;

/* Generic  */

export const selectIsLoading = createSelector(
  selectFilesystemReducer,
  reducer => reducer.isLoading
);

export const selectIsLocked = state => state.isOnBoard === true;

export const selectIsCreatingFolder = createSelector(
  selectFilesystemReducer,
  reducer => reducer.isCreatingFolder
);

export const selectIsEditingFolders = createSelector(
  selectFilesystemReducer,
  reducer => reducer.isEditingFolders
);

export const selectIsEditingFolder = createSelector(
  selectIsEditingFolders,
  getKey,
  (isEditingFolders, id) => isEditingFolders[id]
);

export const selectIsAddingSubFolders = createSelector(
  selectFilesystemReducer,
  reducer => reducer.isAddingSubFolders
);

export const selectIsAddingSubFolder = createSelector(
  selectIsAddingSubFolders,
  getKey,
  (isAddingSubFolders, id) => isAddingSubFolders[id]
);
/* -- */

/* Root lvl */
export const selectIsTreeInitialized = createSelector(
  selectFilesystemReducer,
  reducer => reducer.initialized
);

export const selectRootFolders = createSelector(
  selectFilesystemReducer,
  reducer => reducer.rootFolders || []
);

export const selectIsEmpty = createSelector(selectFilesystemReducer, reducer => reducer.isEmpty);
/* -- */

/* Sub-folders */
export const selectSubFolders = createSelector(
  selectFilesystemReducer,
  reducer => reducer.subFolders
);
export const selectFolderSubFolders = createSelector(
  selectSubFolders,
  getKey,
  (subFolders, id) => subFolders[id]
);
export const hasNestedContent = createSelector(
  selectSubFolders,
  subFolders => Object.keys(subFolders)?.length
);
/* -- */

/* Folders */
export const selectFoldersLoading = createSelector(
  selectFilesystemReducer,
  reducer => reducer.foldersLoading
);
export const selectFolders = createSelector(selectFilesystemReducer, reducer => reducer.folders);
export const selectFolder = createSelector(selectFolders, getKey, (folders, id) => folders[id]);
export const selectNumberOfFolders = createSelector(
  selectFolders,
  folders => Object.keys(folders).length
);

/* -- */

/* Expanded Folders */
export const selectExpandedFolders = createSelector(
  selectFilesystemReducer,
  reducer => reducer.expandedFolders
);
export const selectIsFolderExpanded = createSelector(
  selectExpandedFolders,
  getKey,
  (expandedFolders, id) => expandedFolders[id]
);
export const areAllFoldersExpanded = createSelector(
  selectExpandedFolders,
  selectSubFolders,
  (expandedFolders, subFolders) =>
    Object.keys(expandedFolders).length === Object.keys(subFolders).length
);
/* -- */

/* Tree Search */
export const selectTreeSearch = createSelector(
  selectFilesystemReducer,
  reducer => reducer.treeSearch
);

export const selectHasSearch = createSelector(
  selectTreeSearch,
  treeSearch => treeSearch?.length > 0
);

export const selectFoldersInSearch = createSelector(
  selectFilesystemReducer,
  reducer => reducer.foldersInSearch
);

export const isFoldersInSearch = createSelector(
  selectFoldersInSearch,
  getKey,
  selectHasSearch,
  selectIsLoading,
  (foldersInSearch, id, hasSearch, isLoading) =>
    !isLoading && hasSearch ? foldersInSearch[id] : true
);

export const selectHighlightedFolders = createSelector(
  selectFilesystemReducer,
  reducer => reducer.highlightedFolders
);

export const isFolderHighlighted = createSelector(
  selectHighlightedFolders,
  getKey,
  selectHasSearch,
  (highlightedFolders, id, hasSearch) =>
    hasSearch && highlightedFolders?.length && highlightedFolders.includes(id)
);
/* -- */

/* Active Folder */
export const selectActiveFolderId = createSelector(
  selectFilesystemReducer,
  reducer => reducer.activeFolderId || null
);

export const selectActiveFolderBreadcrumbs = createSelector(
  selectFilesystemReducer,
  reducer => reducer.activeFolderBreadcrumbs
);

export const selectActiveFolder = createSelector(
  selectFilesystemReducer,
  reducer => reducer.activeFolder
);

export const selectActiveFolderFiles = createSelector(selectActiveFolder, activeFolder =>
  _orderBy(activeFolder?.files || [], [f => f?.filename.toLowerCase()], ['asc'])
);

export const selectActiveFolderIsForAllVessels = createSelector(
  selectActiveFolder,
  activeFolder => activeFolder?.is_for_all_vessels
);

export const selectActiveFolderVessels = createSelector(
  selectActiveFolder,
  activeFolder => activeFolder?.vessels || []
);

export const selectActiveFolderSortIndex = createSelector(
  selectActiveFolderId,
  selectFolders,
  (activeFolderId, folders) => (activeFolderId ? folders?.[activeFolderId]?.sort_index : null)
);

export const selectPreviousFolder = createSelector(
  selectActiveFolderSortIndex,
  selectFolders,
  (sortIndex, folders) =>
    folders?.[_findKey(folders, folder => folder.sort_index === sortIndex - 1)]
);

export const selectNextFolder = createSelector(
  selectActiveFolderSortIndex,
  selectFolders,
  (sortIndex, folders) =>
    folders?.[_findKey(folders, folder => folder.sort_index === sortIndex + 1)]
);
/* -- */

export const selectPreventionModal = createSelector(
  selectFilesystemReducer,
  reducer => reducer.preventionModal
);

export const selectPreventionModalIsOpen = createSelector(
  selectPreventionModal,
  preventionModal => preventionModal?.isOpen
);

export const selectPreventionModalFolder = createSelector(
  selectPreventionModal,
  preventionModal => preventionModal?.folder
);
