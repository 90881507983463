import PropTypes from 'prop-types';
import Radio from 'common/components/form/inputs/Radio';
import Input from 'common/components/form/inputs/Input';

const RadiosWithText = ({ selectField, fields, hasSubmittedEvaluation }) => {
  return hasSubmittedEvaluation ? (
    <div data-cy="view-radios-with-text" className="d-flex align-items-center fs-13 text-primary">
      <div className="fw-bold">
        {fields?.radios?.value ? 'Yes' : 'No'}
        {fields?.text?.value ? ':' : ''}
        &nbsp;
      </div>
      <div className="fw-medium">{fields?.text?.value ? fields?.text?.value : null}</div>
    </div>
  ) : (
    <div data-cy="edit-radios-with-text" className="d-flex align-items-center">
      <Radio
        name="recommended_for_reemployment"
        className="me-4 mb-0"
        label="Yes"
        checked={fields?.radios?.value}
        onChange={() => selectField('radios')(true)}
        {...fields?.radios}
      />
      <Radio
        name="recommended_for_reemployment"
        className="me-4 mb-0"
        label="No"
        checked={!fields?.radios?.value}
        onChange={() => selectField('radios')(false)}
        {...fields?.radios}
      />
      <Input
        className="flex-1 mb-0"
        placeholder="Add some text"
        onChange={e => selectField('text')(e.target.value)}
        value={fields?.text?.value}
      />
    </div>
  );
};

RadiosWithText.propTypes = {
  fields: PropTypes.shape({
    radios: PropTypes.shape({ value: PropTypes.bool, error: PropTypes.string }),
    text: PropTypes.shape({ value: PropTypes.string, error: PropTypes.string })
  }).isRequired,
  selectField: PropTypes.func.isRequired,
  hasSubmittedEvaluation: PropTypes.bool
};

export default RadiosWithText;
