import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import ColoredLabel from 'common/components/labels/ColoredLabel';
import ModularTable from 'common/components/ModularTable';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { selectVesselProfile } from 'store/vessels/selectors';

const columns = [
  {
    header: 'ROLE',
    key: 'role',
    info: 'Edit Roles from Person Profile > Main Info > Role',
    width: 3
  },
  {
    header: 'NAME',
    key: 'name',
    width: 3
  },
  {
    header: 'PHONE',
    key: 'phone',
    info: 'Edit Phone Number from Person Profile > Main Info > Primary Phone'
  }
];

const ResponsiblePersons = () => {
  const profile = useSelector(selectVesselProfile);

  return (
    <div className="mt-3 pb-1">
      <div className="d-flex mb-1">
        <ColoredLabel text="Responsible for Vessel" className="fw-medium" />
      </div>

      <StyledModularTable
        style="transparent"
        hideTableSearch
        hideTopPagination
        hideTableFilters
        setPageMaxHeight={false}
        highlightColumnOnHover={false}
        getRowClassName={(_, i) => `rounded-0 shadow-none ${i > 0 ? 'mt-1' : ''}`}
        rows={{
          role: data => <TextWithTooltip>{data?.department_role?.name}</TextWithTooltip>,
          name: data => <TextWithTooltip>{data?.party?.full_name}</TextWithTooltip>,
          phone: data => <TextWithTooltip>{data?.party?.phone}</TextWithTooltip>
        }}
        columns={columns.map(column => ({
          ...column,
          headerClassName: 'py-0 border-bottom-0',
          infoTooltipClassname: 'tooltip-inner-max-content cpx-6 py-1',
          infoTooltipOffset: [0, 9],
          bodyClassName: 'py-0 border-bottom-0 min-height-16 rounded-0'
        }))}
        state={{
          data: profile?.department_parties || []
        }}
      />
    </div>
  );
};

const StyledModularTable = styled(ModularTable)`
  .app-table--head-inner {
    margin-bottom: 0.25rem;

    .cell--inner {
      display: flex;
      align-items: center !important;
    }
  }
`;

export default ResponsiblePersons;
