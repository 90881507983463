import { Props } from 'react-select';
import CollectionFilterSelector, { CollectionFilterProps } from './CollectionFilterSelector';

function OcimfCategoryCollectionFilterSelector<Option extends { id: number; label?: string }>(
  props: Omit<Props<Option>, 'value'> & CollectionFilterProps<Option>
) {
  return (
    <CollectionFilterSelector
      {...props}
      memoizedRequestParams={{
        path: 'lists',
        params: { list: 'crew-medical-case-ocimf-system-categorization' }
      }}
      getOptionLabel={option => option.label ?? ''}
    />
  );
}

export default OcimfCategoryCollectionFilterSelector;
