import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import {
  selectDocumentsLoading,
  selectDocumentsTypes,
  selectDocumentSearch,
  areAllDocumentTypePanelsExpanded
} from 'store/vessels/selectors';
import AddNew from './components/AddNew';
import CollapseColoredBox from 'common/components/collapse/CollapseColoredBox';
import PageLoader from 'common/components/general/PageLoader';
import Drawer from './drawer';
import CategoriesTable from './table';
import TableSearchText from 'common/components/table/TableSearchText';
import ExpandButton from '@/common/components/buttons/ExpandButton';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import useRouter from 'use-react-router';
import {
  expandAllVesselDocumentPanels,
  getVesselDocumentsAction,
  setVesselDocumentsSearch
} from './store/actions';
import { DocumentTypeState } from './store/types';
import { selectAccount } from '@/store/account/selectors-ts';
import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const Documents = () => {
  const { match } = useRouter<{ id: string }>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectDocumentsLoading);
  const types = useAppSelector(selectDocumentsTypes);
  const allExpanded = useAppSelector(areAllDocumentTypePanelsExpanded);
  const searchInput = useAppSelector(selectDocumentSearch);
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const account = useAppSelector(selectAccount);

  const onSearchChange = useCallback(
    (search: string) => {
      dispatch(setVesselDocumentsSearch(search));
    },
    [dispatch]
  );

  useUpdateEffect(() => {
    dispatch(getVesselDocumentsAction({ id: match.params.id }));
  }, [searchInput, dispatch]);

  useEffect(() => {
    dispatch(getVesselDocumentsAction({ id: match.params.id }));
  }, [match.params.id, dispatch]);

  return (
    <div className="vessel-documents">
      <div className="d-flex align-items-center">
        <AddNew isLoading={isLoading} />
        <div className="d-flex align-items-center ms-auto">
          <TableSearchText label="vessel_documents" onSearch={onSearchChange} />

          <ExpandButton
            isExpanded={allExpanded}
            disabled={!types.length}
            className="fs-12 fw-medium text-primary px-1 cpt-2 cpb-2"
            label={allExpanded ? 'Minimize all' : 'Expand All'}
            onClick={() => dispatch(expandAllVesselDocumentPanels(allExpanded ? false : true))}
          />
        </div>
      </div>
      <div className="mt-2">
        {types.map((type: DocumentTypeState) => (
          <CollapseColoredBox
            key={type.id}
            header={type.type_name}
            subHeader={`${type.categories_count} cert${type.categories_count === 1 ? '' : 's'}`}
            className="mb-1"
            triggerOpen={allExpanded}
          >
            <CategoriesTable
              vesselId={match.params.id}
              type={{ id: type.id, name: type.type_name, template: type?.template }}
            />
          </CollapseColoredBox>
        ))}
      </div>
      <PageLoader isLoading={isLoading} />
      {!isLoading &&
      (!isOnBoard ||
        (isOnBoard && isAuthorized(account, [permissions.onboard.vessel.documents.edit]))) ? (
        <Drawer vesselId={match.params.id} />
      ) : null}

      {!isLoading && !types.length ? (
        <div className="vessel-documents__empty text-gray-common">No documents found</div>
      ) : null}
    </div>
  );
};

export default Documents;
