import { get, put, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createJob = createAsyncThunk('CREATE_JOB', async (params, { rejectWithValue }) => {
  const { id, ...rest } = params;

  try {
    const res = await post(`/events/${id}/jobs`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getJobs = createAsyncThunk('GET_JOBS', async (params, { rejectWithValue }) => {
  const { id, ...rest } = params;

  try {
    const res = await get(`/events/${id}/jobs`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const updateJob = createAsyncThunk('UPDATE_JOB', async (params, { rejectWithValue }) => {
  const { id, previousJobId, ...rest } = params;

  try {
    const res = await put(`/events/${id}/jobs/${previousJobId}`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const deleteJob = createAsyncThunk('DELETE_JOB', async (params, { rejectWithValue }) => {
  const { id, previousJobId, ...rest } = params;

  try {
    const res = await deleteRequest(`/events/${id}/jobs/${previousJobId}`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
