import { useSelector } from 'react-redux';

import { sectionSubGroups } from 'captain-reports/templates/config/_initialState';
import {
  selectEnginesSubGroupsLength,
  selectGeneratorsSubGroupsLength,
  selectSubGroupLength
} from 'captain-reports/store/selectors';

const useSubGroupLength = (sectionLabel, subGroupLabel) => {
  const value = useSelector(state => {
    if (sectionSubGroups[subGroupLabel]) {
      if (sectionLabel) {
        switch (sectionLabel) {
          case 'engines':
            return selectEnginesSubGroupsLength(state, subGroupLabel);

          case 'generators':
            return selectGeneratorsSubGroupsLength(state, subGroupLabel);

          default:
            console.error('Missing sub group selector: ' + subGroupLabel);
            return null;
        }
      } else {
        return selectSubGroupLength(state, subGroupLabel);
      }
    } else {
      return null;
    }
  });

  return value;
};

export default useSubGroupLength;
