import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import {
  selectAgentsItineraryPort,
  selectAgentsRemarks
} from 'events/store/event-modules/agents/selectors';
import { useDispatch, useSelector } from 'react-redux';
import SvgRender from 'common/components/general/SvgRender';
import comments from 'common/assets/svg/common/comments.svg';
import CircledButton from 'common/components/buttons/CircledButton';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import { editItinerary } from '@/common/components/voyages/itinerary/store/actions';
import Textarea from 'common/components/form/inputs/Textarea';
import { RoundedContainer } from '.';

const Remarks = () => {
  const [tempRemarks, setTempRemarks] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();

  const remarks = useSelector(selectAgentsRemarks);
  const itineraryPortId = useSelector(selectAgentsItineraryPort);

  const onSave = () => {
    dispatch(editItinerary({ id: itineraryPortId, details: { remarks: tempRemarks } }));
  };

  useEffect(() => {
    if (!remarks) return;

    setTempRemarks(prev => prev || remarks);
  }, [remarks]);

  return isEditing ? (
    <Container flat className="cpt-20 cpb-20 px-3 mt-3 border-radius-11 position-relative">
      <Textarea
        rows={5}
        onChange={e => setTempRemarks(e.target.value)}
        className="mb-0"
        label="REMARKS"
        placeholder="Add some text"
        value={tempRemarks}
      />

      <Actions>
        <InputSaveActions
          onCancel={() => {
            setIsEditing(false);
            setTempRemarks(remarks);
          }}
          onSave={() => {
            onSave();
            setIsEditing(false);
          }}
        />
      </Actions>
    </Container>
  ) : (
    <RoundedContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      flatOnHover={true}
      className="px-3 py-2 fs-12 position-relative mt-3 border-radius-11"
    >
      <div className="d-flex align-items-center cmb-12">
        <SvgRender className="text-purple" src={comments} style={{ width: 16, height: 16 }} />
        <span className="text-purple fw-medium ms-1">Remarks</span>
      </div>

      <div
        className={`fw-medium ${tempRemarks ? 'text-primary' : 'text-violet'}`}
        dangerouslySetInnerHTML={{
          __html: tempRemarks || 'There are no remarks at the moment'
        }}
      />

      <Actions className={!isHovering ? 'd-none' : ''}>
        <CircledButton
          type="edit"
          svgStyle={{ width: 12, height: 12 }}
          onClick={() => setIsEditing(true)}
        />
      </Actions>
    </RoundedContainer>
  );
};

export default Remarks;

export const Container = styled.div`
  background-color: rgba(134, 147, 171, 0.1);
`;

export const Actions = styled.div`
  position: absolute;
  top: 0.5rem;
  right: -0.75rem;
`;
