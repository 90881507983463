import React, { useCallback, useEffect, useState } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';

import { useDispatch, useSelector } from 'react-redux';
import { isTabActive, selectJobField } from 'common/components/jobs/_base/store/selectors';
import { selectJobHasForms } from 'common/components/jobs/_base/modules/forms/store/selectors';

import AddNewForm from './AddNewForm';
import SavedJobForms from './SavedJobForms';
import FormsLoader from 'common/components/forms/_components/FormsLoader';

import {
  bulkRemoveJobForms,
  getJobForms
} from 'common/components/jobs/_base/modules/forms/store/actions';
import { setActiveJobTab } from 'common/components/jobs/_base/store/actions';
import ModuleTabActions from '../../components/ModuleTabActions';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const JobForms = ({ canAddForms = true, canRemoveForms = true }) => {
  const editMode = useSelector(state => isTabActive(state, 'forms'));
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));
  const vesselIds = (vessels || [])?.map(e => e?.id);

  const jobId = useSelector(state => selectJobField(state, 'id'));
  const statusId = useSelector(state => selectJobField(state, 'status_id'));
  const jobHasForms = useSelector(selectJobHasForms);

  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [dangerousModalIsOpen, setDangerousModalIsOpen] = useState(false);

  const dispatch = useDispatch();

  const isVisible = jobHasForms || editMode;

  const fecthJobForms = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getJobForms(jobId));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    if (isVisible && jobId && jobHasForms) {
      fecthJobForms();
    }
  }, [fecthJobForms, isVisible, jobHasForms, jobId, statusId]);

  const onDeleteModuleAction = () => {
    if (!jobHasForms) {
      dispatch(setActiveJobTab('forms', true));
    } else {
      setDangerousModalIsOpen(true);
    }
  };

  const onDeleteFormsWidget = async () => {
    try {
      await dispatch(bulkRemoveJobForms({ jobId })).unwrap(); // Not yet implemented by API

      dispatch(setActiveJobTab('forms', true));
      setDangerousModalIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (!isVisible) return null;

  return (
    <div className={`job-forms mb-4 pe-1 ${!canAddForms ? 'hide-actions' : ''}`}>
      <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <ModuleTabActions
          header="Forms"
          onDelete={onDeleteModuleAction}
          isEditMode={false}
          isDisabled={isLoading}
          // Temp fix to hide the Delete Widget (bulk rempove button)
          isHovering={jobHasForms && jobId ? false : isHovering}
        />
        {jobHasForms ? <SavedJobForms canRemoveForms={canRemoveForms} /> : null}

        <FormsLoader isLoading={isLoading} />
        {/* <LinkForm />  -- // TEMP file to be used along with the Job Form Obligations refactor */}

        {isAdding ? (
          <AddNewForm
            fecthJobForms={fecthJobForms}
            setIsAdding={setIsAdding}
            jobId={jobId}
            vesselIds={vesselIds}
            isForVessel={isForVessel}
          />
        ) : null}
      </div>

      {canAddForms ? (
        <CircledButton
          type="add"
          className={`text-primary fw-bold fs-12 lh-1`}
          label="Add more"
          svgStyle={{ width: 8, height: 8 }}
          style={{ width: 20, height: 20 }}
          disabled={isAdding}
          onClick={() => setIsAdding(true)}
        />
      ) : null}

      <DangerousActionModal
        transparent
        isOpen={dangerousModalIsOpen}
        header={'Delete Widget'}
        body={
          <div className="text-center">
            Are you sure you want to delete the forms widget? <br />
            This cannot be undone.
          </div>
        }
        actionHoverColor={'delete'}
        actionText={'DELETE'}
        action={'delete'}
        onAccept={onDeleteFormsWidget}
        onClose={() => setDangerousModalIsOpen(false)}
        closeModal={() => setDangerousModalIsOpen(false)}
      />
    </div>
  );
};

export default JobForms;
