import React from 'react';

import ChapterContent from 'manuals/manual-inner/chapter-content';

import { useSelector } from 'react-redux';
import { hasChapters } from 'manuals/store/selectors';

const MainContent = ({ manualId, postActions }) => {
  const notEmpty = useSelector(hasChapters);

  return manualId && notEmpty ? (
    <div className="manuals-chapter-content">
      <ChapterContent postActions={postActions} />
    </div>
  ) : null;
};

export default MainContent;
