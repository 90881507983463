import React from 'react';
import { Route } from 'react-router-dom';
import App from 'components/layouts/App'; // Remove App wrap if you wish

const PublicRoute = ({ component: Component, app, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      app ? (
        <App title={rest.title}>
          <Component {...props} />
        </App>
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default PublicRoute;
