export const calculateAttachmentsWidth = (
  popupPlacement,
  { boundariesDimensions, attachmentsWrapperDimensions, attachmentsButtonDimensions },
  { containerWrapperRef, containerRef }
) => {
  const leftOverflow = attachmentsWrapperDimensions.left < boundariesDimensions.left;
  const rightOverflow = attachmentsWrapperDimensions.right > boundariesDimensions.right;

  if (popupPlacement === 'left') {
    if (!leftOverflow) return;
    const width = attachmentsButtonDimensions.left - boundariesDimensions.left;

    containerWrapperRef.current.style.width = `${width - (width * 5) / 100}px`;
    containerRef.current.className = 'd-flex align-items-center flex-wrap';
  } else {
    if (!rightOverflow) return;
    const width = boundariesDimensions.right - attachmentsButtonDimensions.right;
    containerWrapperRef.current.style.width = `${width - (width * 5) / 100}px`;
    containerRef.current.className = 'd-flex align-items-center flex-wrap';
  }
};
