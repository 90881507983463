import { useEffect } from 'react';
import Question from './Question';
import SkippableQuestion from './SkippableQuestion';
import FreeText from './FreeText';
import Error from './Error';
import ScaleNumberInput from './ScaleNumberInput';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import { useAppSelector } from '@/store/hooks';
import { selectEvaluationsRangeFrom } from '@/store/settings/selectors';

const values = [
  { valueType: 'question', component: Question },
  { valueType: 'skippable-question', component: SkippableQuestion },
  { valueType: 'scale', component: ScaleNumberInput },
  { valueType: 'free-text', component: FreeText }
];

const CriterionValue = ({
  valueType,
  onChange,
  value,
  enabled,
  error,
  tooltipID,
  setFieldError
}) => {
  const activeComponent = values.find(value => value.valueType === valueType);
  const hasError = error && enabled;
  const min = useAppSelector(selectEvaluationsRangeFrom);

  useEffect(() => {
    if (enabled && !value && !hasError) {
      if (
        (value === 0 && valueType === 'question') ||
        (valueType === 'scale' && value === 0 && min === 0)
      )
        return;
      setFieldError('value', REQUIRED_FIELD_MESSAGE);
    }
  }, [enabled, value, setFieldError, hasError, valueType, min]);

  return (
    <div className="d-flex align-items-center w-100p">
      <Error error={error} id={tooltipID} className={`opacity-${hasError ? '10' : '0'}`} />
      <activeComponent.component onChange={onChange} value={value} disabled={!enabled} />
    </div>
  );
};

export default CriterionValue;
