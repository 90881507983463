import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTable } from 'common/components/table';

import { selectTableListColumns } from 'common/components/table/store/selectors';

const useModularTable = ({ label, config = {}, ...rest }) => {
  const columns = useSelector(state => selectTableListColumns(state, label));
  const [dynamicColumns, setDynamicColumns] = useState([]);

  useEffect(() => {
    setDynamicColumns(config(columns)?.columns);
  }, [columns, columns?.length]);

  return useTable({
    label,
    columns: dynamicColumns,
    modular: true,
    ...rest
  });
};

export default useModularTable;
