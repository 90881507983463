import CircledButton from 'common/components/buttons/CircledButton';

const Preview = ({ index, children, setRowEditing, hideActions, setRowDeleting }) => {
  return (
    <div className="creatable-table__preview">
      <div className="creatable-table__preview-component">{children}</div>
      <div className="creatable-table__preview-actions">
        {!hideActions ? (
          <div className="creatable-table__preview-actions-inner">
            {setRowEditing ? (
              <CircledButton
                svgStyle={{ width: 12, height: 12 }}
                type="bin"
                className="me-1"
                onClick={setRowDeleting ? setRowDeleting : null}
              />
            ) : null}
            <CircledButton
              svgStyle={{ width: 12, height: 12 }}
              type="edit"
              onClick={setRowEditing}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Preview;
