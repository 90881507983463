import BallastWaterField from 'captain-reports/templates/components/custom-fields/BallastWaterField';
import BallastWaterTotalField from 'captain-reports/templates/components/custom-fields/BallastWaterTotalField';
import NumberField from 'captain-reports/templates/inputs/NumberField';

const config = () => [
  {
    fields: [
      {
        label: 'BM',
        subLabel: '%',
        key: 'ballast_water_bending_moment_max',
        type: 'number',
        width: { xs: 1 },
        render: NumberField
      },
      {
        label: 'SF',
        subLabel: '%',
        key: 'ballast_water_shear_force_max',
        type: 'number',
        width: { xs: 1 },
        render: NumberField
      }
    ]
  },
  {
    subGroup: 'ballast_water',
    fields: [
      {
        label: 'Rob',
        subLabel: 'm³',
        key: 'ballast_water_amount',
        type: 'number',
        width: { xs: 3 },
        render: BallastWaterField,
        className: 'text-end-input'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Total Rob',
        key: 'ballast_water_amount_total',
        type: 'number',
        width: { xs: 3 },
        render: BallastWaterTotalField
      }
    ]
  }
];

const ballastWaterConfig = {
  noon: data => config(data),
  arrival: data => config(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => config(data),
  port_noon: data => config(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default ballastWaterConfig;
