import { createSelector } from 'reselect';

export const selectCrewEvaluationsState = state => state.crew.evaluations.profile;

export const selectCrewEvaluationsIsFetching = createSelector(
  selectCrewEvaluationsState,
  evaluations => evaluations.fetching
);
export const selectCrewEvaluations = createSelector(
  selectCrewEvaluationsState,
  evaluations => evaluations.data
);

export const selectEvaluationEditing = createSelector(
  selectCrewEvaluationsState,
  evaluations => evaluations.isEditing
);

export const selectEvaluationPercentages = createSelector(
  selectCrewEvaluationsState,
  evaluations => evaluations.activePercentages || []
);

export const selectActiveEvaluation = createSelector(
  selectCrewEvaluationsState,
  evaluations => evaluations.active
);

export const selectActiveEvaluationObligation = state => selectActiveEvaluation(state)?.obligation;

export const selectGraphData = createSelector(
  selectCrewEvaluationsState,
  evaluation => evaluation?.graph?.data
);

export const selectActiveEvaluationId = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.id || null
);

export const selectIsEvaluationDraft = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.draft || false
);

export const selectIsEvaluationSubmitted = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.submitted
);

export const selectIsEvaluationSentToCaptainForReview = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.sent_to_captain_for_review
);

export const selectIsEvaluationSentToSeamanForConsent = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.sent_to_evaluatee_for_consent
);

export const selectHasEvaluateeConsented = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.has_evaluatee_consented
);

export const selectIsEvaluationCreatedFromVessel = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.is_created_on_vessel
);

export const selectEvaluationEvaluateeId = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.evaluatee_id
);

export const selectEvaluationTemplateId = createSelector(
  selectActiveEvaluation,
  evaluation => evaluation?.template_id
);

export const selectAverageScore = state => selectActiveEvaluation(state)?.average_score;
