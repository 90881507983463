import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';

import {
  selectJobField,
  selectIsJobLocked,
  selectSingleVesselJobField
} from 'common/components/jobs/_base/store/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import { useActions } from 'utils/hooks';

import VesselPortsSelector from 'common/components/selectors/VesselPortsSelector';
import PortsSelector from 'common/components/selectors/PortSelector';
import SvgRender from 'common/components/general/SvgRender';
import portIcon from 'assets/svg/sidebar/ports.svg';

const Ports = () => {
  const [portType, setPortType] = useState('port');
  const port = useSelector(state => selectJobField(state, 'port'));
  const itineraryPort = useSelector(state => selectJobField(state, 'itinerary_port'));
  const [setJobField] = useActions([jobProfileActions.setJobField]);
  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const isOnboard = useSelector(state => state.isOnBoard);

  const vesselId = useSelector(selectSingleVesselJobField);
  const isVisible = useSelector(selectIfFieldIsVisible('port_id'));

  useEffect(() => {
    if (itineraryPort?.id) {
      setPortType('itinerary_port');
    }
  }, [itineraryPort?.id]);

  useEffect(() => {
    if (vessels?.length > 1) {
      setPortType('port');
    }
  }, [vessels?.length]);

  if (!isVisible) return null;

  const onItineraryPortChange = async itinerary_port => {
    try {
      await setJobField('port', null, true);
      await setJobField('itinerary_port', itinerary_port, true);
    } catch (err) {
      console.error(err);
    }
  };

  const onPortChange = async ports => {
    try {
      await setJobField('itinerary_port', null, true);
      await setJobField('port', ports?.value, true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mb-2">
      <VesselPortsSelector
        type={portType}
        label={
          <div className="d-flex align-items-center">
            <SvgRender
              src={portIcon}
              style={{ width: 14, height: 14 }}
              className="me-1 text-primary"
            />
            <div className="form-label mb-0">PORT</div>
          </div>
        }
        disabledTabs={isJobLocked || vessels?.length > 1 || isOnboard}
        vesselId={vesselId}
        itineraryPort={{ value: itineraryPort, error: null }}
        onChangeItineraryPort={onItineraryPortChange}
        onChangeType={port_type => setPortType(port_type)}
      >
        <PortsSelector
          placeholder="Select Port"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          filter={{ value: port }}
          onChange={onPortChange}
          isClearable
          isAsync
          isMulti={false}
          autoFocus={false}
          disabled={isJobLocked || isOnboard}
          noOptionsMessage={({ inputValue }) =>
            !inputValue.length ? `Search for ports` : `No ports found`
          }
        />
      </VesselPortsSelector>
    </div>
  );
};

export default Ports;
