import React from 'react';

const DescriptionHeader = ({ title, subtitle }) => {
  return (
    <div className="d-flex flex-column fs-12">
      <strong className="text-primary">{title}</strong>
      <span className="text-violet">{subtitle}</span>
    </div>
  );
};

export default DescriptionHeader;
