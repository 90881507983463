import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { AnyAction, Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import {
  selectGroupsLoading,
  selectPmsLibraryIsUsedInCategories,
  selectPmsLibraryIsUsedInRequisition,
  selectPmsLibraryIsUsedInSetup,
  selectSystemsLoading,
  selectSystemsPaging,
  selectSystemsSearch
} from './selectors';
import {
  getVesselSystemAssignments,
  getVesselSystemAssignmentsParams,
  getVesselSystems,
  getVesselSystemSubSystems
} from '@/api/vessel-systems/api';
import { RootState } from '@/store';
import { TablePaging, TableRequestPaging } from '@/common/types/front-entities/table';
import { Vessel } from '@/common/types/vessel';
import { selectActiveGroup, selectGroupsSearch, selectSelectedVesselFilter } from './selectors';
import { getPurchasingRequisitionVesselSystemsAction } from '@/common/components/purchasing/requisition/store/actions';

export const deleteMaintenanceJob = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.DELETE_MAINTENANCE_JOB.START, payload: { params } });
  const { id, system_id, ...rest } = params;

  return deleteRequest(`/vessel-systems/${system_id}/maintenance-jobs/${id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_MAINTENANCE_JOB.SUCCESS,
        payload: { data: response.data, systemId: system_id }
      });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.DELETE_MAINTENANCE_JOB.ERROR, payload: error });
    });
};

export const getMaintenanceJob = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_MAINTENANCE_JOB.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/vessel-systems/maintenance-jobs/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_MAINTENANCE_JOB.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.GET_MAINTENANCE_JOB.ERROR, payload: error });
    });
};

export const getMaintenanceJobs = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_MAINTENANCE_JOBS.START, payload: { params } });
  const { system_id, ...rest } = params;

  return get(`/vessel-systems/${system_id}/maintenance-jobs`, rest || {})
    .then(response => {
      dispatch({
        type: TYPES.GET_MAINTENANCE_JOBS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MAINTENANCE_JOBS.ERROR, payload: error });
      throw error;
    });
};

export const createMaintenanceJob = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.CREATE_MAINTENANCE_JOB.START, payload: { params } });

  const { system_id, ...rest } = params;

  return post(`/vessel-systems/${system_id}/maintenance-jobs`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_MAINTENANCE_JOB.SUCCESS,
        payload: { data: response.data, systemId: system_id }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_MAINTENANCE_JOB.ERROR, payload: error });
      throw error;
    });
};

export const editMaintenanceJob = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.EDIT_MAINTENANCE_JOB.START, payload: { params } });

  const { system_id, id, ...rest } = params;

  return put(`/vessel-systems/${system_id}/maintenance-jobs/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.EDIT_MAINTENANCE_JOB.SUCCESS, payload: response.data });

      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Any changes will be applied only to new jobs'
        }) as unknown as AnyAction
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_MAINTENANCE_JOB.ERROR, payload: error });
      throw error;
    });
};

export const editMaintenanceJobActiveTabs = (key: any, isActive: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_MAINTENANCE_JOB_ACTIVE_TABS, payload: { key, isActive } });
};

export const resetMaintenanceJobActiveTabs = () => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.RESET_MAINTENANCE_JOB_ACTIVE_TABS });
};

export const addVesselAssignment = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.ADD_VESSEL_ASSIGNMENT.START, payload: { params } });

  const { system_id, vessel_id, ...rest } = params;

  return post(`/vessel-systems/${system_id}/assignments/${vessel_id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.ADD_VESSEL_ASSIGNMENT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.ADD_VESSEL_ASSIGNMENT.ERROR, payload: error });
    });
};

export const assignVessel = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.ASSIGN_VESSEL.START, payload: { params } });

  const { system_id, vessel_id, ...rest } = params;

  return post(`/vessel-systems/${system_id}/assignments/${vessel_id}/new`, rest)
    .then(response => {
      dispatch({ type: TYPES.ASSIGN_VESSEL.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.ASSIGN_VESSEL.ERROR, payload: error });
    });
};

export const getVesselAssignments =
  (params: getVesselSystemAssignmentsParams & { leaveSelectedAssignment?: boolean }) =>
  (dispatch: Dispatch) => {
    dispatch({ type: TYPES.GET_VESSEL_ASSIGNMENTS.START, payload: { params } });
    const { system_id, vessel_id, leaveSelectedAssignment, ...rest } = params;

    return getVesselSystemAssignments({ system_id, vessel_id, ...rest })
      .then(response => {
        dispatch({
          type: TYPES.GET_VESSEL_ASSIGNMENTS.SUCCESS,
          payload: { data: response, leaveSelectedAssignment }
        });

        return response;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_VESSEL_ASSIGNMENTS.ERROR, payload: error });
        throw error;
      });
  };

export const getVesselSubAssignments = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_VESSEL_SUB_ASSIGNMENTS.START, payload: { params } });
  const { parent_id, vessel_id, leaveSelectedAssignment, ...rest } = params;

  return get(`/vessel-systems/${parent_id}/assignments/${vessel_id}`, rest || {})
    .then(response => {
      dispatch({
        type: TYPES.GET_VESSEL_SUB_ASSIGNMENTS.SUCCESS,
        payload: { data: response.data, leaveSelectedAssignment }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_VESSEL_SUB_ASSIGNMENTS.ERROR, payload: error });
      throw error;
    });
};

export const updateVesselAssignment = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.UPDATE_ASSIGNMENT.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/vessel-systems/assignments/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_ASSIGNMENT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_ASSIGNMENT.ERROR, payload: error });
      throw error;
    });
};

export const updateVesselAssignmentPrototype = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.UPDATE_VESSEL_ASSIGNMENT_PROTOTYPE.START, payload: { params } });

  const { vessel_system_assignment_id, job_trigger_prototype_id, ...rest } = params;

  return put(
    `vessel-systems/assignments/${vessel_system_assignment_id}/job-prototypes/${job_trigger_prototype_id}/class`,
    rest
  )
    .then(response => {
      dispatch({ type: TYPES.UPDATE_VESSEL_ASSIGNMENT_PROTOTYPE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.UPDATE_VESSEL_ASSIGNMENT_PROTOTYPE.ERROR, payload: error });
    });
};

export const deleteVesselAssignment = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.DELETE_VESSEL_ASSIGNMENT.START, payload: { params } });
  const { id, ...rest } = params;

  return deleteRequest(`/vessel-systems/assignments/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_VESSEL_ASSIGNMENT.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.DELETE_VESSEL_ASSIGNMENT.ERROR, payload: error });
    });
};

// Spare parts
export const getSpareParts = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_SPARE_PARTS.START, payload: { params } });
  const { system_id, ...rest } = params;

  return get(`/vessel-systems/${system_id}/spare-parts`, rest || {})
    .then(response => {
      dispatch({
        type: TYPES.GET_SPARE_PARTS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_SPARE_PARTS.ERROR, payload: error });
      throw error;
    });
};

export const createSparePart = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.CREATE_SPARE_PART.START, payload: { params } });

  const { system_id, ...rest } = params;

  return post(`/vessel-systems/${system_id}/spare-parts`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_SPARE_PART.SUCCESS,
        payload: { data: response.data, systemId: system_id }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_SPARE_PART.ERROR, payload: error });
      throw error;
    });
};

export const getSparePart = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_SPARE_PART.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/vessel-systems/spare-parts/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_SPARE_PART.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.GET_SPARE_PART.ERROR, payload: error });
    });
};

export const updateSparePart = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.UPDATE_SPARE_PART.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/vessel-systems/spare-parts/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_SPARE_PART.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_SPARE_PART.ERROR, payload: error });
      throw error;
    });
};

export const deleteSparePart = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.DELETE_SPARE_PART.START, payload: { params } });
  const { id, system_id, ...rest } = params;

  return deleteRequest(`/vessel-systems/${system_id}/spare-parts/${id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_SPARE_PART.SUCCESS,
        payload: { data: response.data, systemId: system_id }
      });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.DELETE_SPARE_PART.ERROR, payload: error });
    });
};

export const setSelectedSparePart = (part: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SELECTED_SPARE_PART, payload: part });
};

type GetPmsLibraryVesselSystemsAction = {
  paging: TableRequestPaging;
  with_counts: boolean;
  search?: string;
  vessel_system_group_id?: number;
  vessel_ids?: number[];
  keepOpened?: boolean;
  hideLoading?: boolean;
};

const getPmsLibraryVesselSystemsAction = createAsyncThunk(
  TYPES.GET_PMS_LIBRARY_VESSEL_SYSTEMS,
  async (
    params: GetPmsLibraryVesselSystemsAction,
    { rejectWithValue, getState, fulfillWithValue }
  ) => {
    try {
      const { keepOpened, hideLoading, ...rest } = params;

      const selectedVesselFilter = selectSelectedVesselFilter(getState() as RootState);
      const activeGroup = selectActiveGroup(getState() as RootState);
      const systemsSearch = selectSystemsSearch(getState());

      const requestParams = {
        vessel_system_group_id: activeGroup !== 'all' ? activeGroup : undefined,
        vessel_ids: selectedVesselFilter?.id ? [selectedVesselFilter.id] : undefined,
        search: systemsSearch || undefined,
        ...rest // Override any of the above
      };

      const response = await getVesselSystems(requestParams);

      return fulfillWithValue({
        response,
        keepOpened,
        hideLoading,
        hasSearch: requestParams.search?.length > 0,
        search: requestParams.search
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

type GetVesselSystemsActionParams = {
  firstPage?: boolean;
  loadMore?: boolean;
  keepOpened?: boolean;
  hideLoading?: boolean;
  search?: string;
  vessel_system_group_id?: number;
  vessel_ids?: number[];
  purchasing_category_id?: number;
};

export const getVesselSystemsAction = createAsyncThunk(
  TYPES.GET_VESSEL_SYSTEMS,
  async (params: GetVesselSystemsActionParams, { rejectWithValue, getState, dispatch }) => {
    try {
      const isUsedInRequisition = selectPmsLibraryIsUsedInRequisition(getState());
      const isUsedInSetup = selectPmsLibraryIsUsedInSetup(getState());

      const { firstPage, loadMore, ...restParams } = params;
      const { per_page, current_page, last_page } = selectSystemsPaging(getState()) as TablePaging;
      const systemsLoading = selectSystemsLoading(getState());

      if (loadMore && (systemsLoading || (current_page >= last_page && !firstPage))) return;

      const defaultRequestParams = {
        paging: { per_page, current_page },
        with_counts: true
      };

      if (firstPage) {
        defaultRequestParams.paging.current_page = 1;
      } else if (loadMore) {
        defaultRequestParams.paging.current_page = current_page + 1;
      }

      if (isUsedInSetup) {
        dispatch(getPmsLibraryVesselSystemsAction({ ...defaultRequestParams, ...restParams }));
      } else if (isUsedInRequisition) {
        dispatch(
          getPurchasingRequisitionVesselSystemsAction({ ...defaultRequestParams, ...restParams })
        );
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createSystem = createAsyncThunk(
  TYPES.CREATE_SYSTEM,
  async (params: any, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/vessel-systems`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createSubsystem = createAsyncThunk(
  TYPES.CREATE_SUBSYSTEM,
  async (params: any, { rejectWithValue }) => {
    const { parent_id, ...rest } = params;

    try {
      const res = await post(`/vessel-systems/${parent_id}/subsystems`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getSingleSystem = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_SINGLE_SYSTEM.START, payload: { params } });
  const { id, ...rest } = params;

  return get(`/vessel-systems/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.GET_SINGLE_SYSTEM.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.GET_SINGLE_SYSTEM.ERROR, payload: error });
    });
};

export const updateSystem = createAsyncThunk(
  TYPES.UPDATE_SYSTEM,
  async (params: any, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await put(`/vessel-systems/${id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteSystem = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.DELETE_SYSTEM.START, payload: { params } });
  const { id, ...rest } = params;

  return deleteRequest(`/vessel-systems/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_SYSTEM.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.DELETE_SYSTEM.ERROR, payload: error });
    });
};

export const cloneSystem = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.CLONE_SYSTEM.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/vessel-systems/${id}/clone`, rest)
    .then(response => {
      dispatch({ type: TYPES.CLONE_SYSTEM.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.CLONE_SYSTEM.ERROR, payload: error });
    });
};

export const getSystemGroups =
  (params: any = {}) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const groupsLoading = selectGroupsLoading(getState());

    if (groupsLoading) return;

    dispatch({ type: TYPES.GET_SYSTEM_GROUPS.START, payload: { params } });

    const isPmsLibraryUsedInCategories = selectPmsLibraryIsUsedInCategories(getState());
    const isUsedInSetup = selectPmsLibraryIsUsedInSetup(getState());

    const selectedVesselFilter = selectSelectedVesselFilter(getState() as RootState);
    const groupsSearch = selectGroupsSearch(getState() as RootState);

    const requestParams = {
      with_categories_count: isPmsLibraryUsedInCategories ? true : undefined,
      vessel_id: isUsedInSetup ? selectedVesselFilter?.id : undefined,
      search: groupsSearch || undefined,
      ...params
    };

    return get(`/vessel-systems/groups`, requestParams || {})
      .then(response => {
        dispatch({
          type: TYPES.GET_SYSTEM_GROUPS.SUCCESS,
          payload: {
            data: response.data,
            hasSearch: requestParams.search?.length > 0,
            keepOpened: requestParams.keepOpened,
            search: requestParams.search
          }
        });

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_SYSTEM_GROUPS.ERROR, payload: error });
        throw error;
      });
  };

export const getSystemGroup = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.GET_SYSTEM_GROUP.START, payload: { params } });

  return get(`/vessel-systems/groups/${params.id}`)
    .then(response => {
      dispatch({
        type: TYPES.GET_SYSTEM_GROUP.SUCCESS,
        payload: { data: response.data }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_SYSTEM_GROUP.ERROR, payload: error });
      throw error;
    });
};

export const createSystemGroup = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.CREATE_SYSTEM_GROUP.START, payload: { params } });

  const { id, ...rest } = params;

  return post(`/vessel-systems/groups`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_SYSTEM_GROUP.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.CREATE_SYSTEM_GROUP.ERROR, payload: error });
    });
};

export const updateSystemGroup = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.UPDATE_SYSTEM_GROUP.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/vessel-systems/groups/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_SYSTEM_GROUP.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_SYSTEM_GROUP.ERROR, payload: error });
      throw error;
    });
};

export const deleteSystemGroup = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.DELETE_SYSTEM_GROUP.START, payload: { params } });
  const { id, ...rest } = params;

  return deleteRequest(`/vessel-systems/groups/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.DELETE_SYSTEM_GROUP.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.DELETE_SYSTEM_GROUP.ERROR, payload: error });
    });
};

export const setActiveSystemGroupState = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_ACTIVE_SYSTEM_GROUP_STATE, payload: data });
};

// Job Types

export const createMaintenanceJobType = createAsyncThunk(
  TYPES.CREATE_MAINTENANCE_JOB_TYPE,
  async (params: any, { rejectWithValue, dispatch }) => {
    try {
      const res = await post('/vessel-systems/maintenance-job-types', params);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editMaintenanceJobType = createAsyncThunk(
  TYPES.EDIT_MAINTENANCE_JOB_TYPE,
  async (params: any, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await put(`/vessel-systems/maintenance-job-types/${id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteMaintenanceJobType = createAsyncThunk(
  TYPES.DELETE_MAINTENANCE_JOB_TYPE,
  async (params: any, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await deleteRequest(`/vessel-systems/maintenance-job-types/${id}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setActiveGroup = (active: 'all' | number) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_ACTIVE_GROUP, payload: active });
};

export const toggleGroup = (id: string | number) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.TOGGLE_GROUP, payload: id });
};

export const toggleAllGroups = (open: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.TOGGLE_ALL_GROUPS, payload: open });
};

export const setSystemFormType = (payload: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SYSTEM_FORM_TYPE, payload });
};

export const setSelectedSystem = (payload: number | null) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SELECTED_SYSTEM, payload });
};

export const setIsCreatingSubSystem = (payload: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_IS_CREATING_SUBSYSTEM, payload });
};

export const toggleSystem = (id: string | number) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.TOGGLE_SYSTEM, payload: id });
};

export const toggleAllSystems = (open: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.TOGGLE_ALL_SYSTEMS, payload: open });
};

export const setSystemFormParentId = (id: string | number | null) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SYSTEM_FORM_PARENT_ID, payload: id });
};

export const setCurrentFormSystem = (id: number) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_CURRENT_FORM_SYSTEM, payload: id });
};

export const setSelectedTab = (id: string | number | null) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SELECTED_TAB, payload: id });
};

export const setSelectedAssignment = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SELECTED_ASSIGNMENT, payload: params });
};

export const setSelectedSubAssignment = (params: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SELECTED_SUB_ASSIGNMENT, payload: params });
};

export const setPmsLibraryIsUsedIn = (isUsedIn: string) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_PMS_LIBRARY_IS_USED_IN, payload: isUsedIn });
};

// SYSTEM ATTRIBUTES

export const createSystemAttributesGroup = createAsyncThunk(
  'CREATE_SYSTEM_ATTRIBUTES_GROUP',
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await post('/vessel-system-attribute-groups', params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getSystemAttributesGroup = createAsyncThunk(
  'GET_SYSTEM_ATTRIBUTES_GROUP',
  async (params: any, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`/vessel-system-attribute-groups/${id}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateSystemAttributesGroup = createAsyncThunk(
  'UDPATE_SYSTEM_ATTRIBUTES_GROUP',
  async (params: any, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await put(`/vessel-system-attribute-groups/${id}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteSystemAttributesGroup = createAsyncThunk(
  'DELETE_SYSTEM_ATTRIBUTES_GROUP',
  async (params: any, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await deleteRequest(`/vessel-system-attribute-groups/${id}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getSystemSubSystems = createAsyncThunk(
  'GET_SYSTEM_SUB_SYSTEMS',
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await getVesselSystemSubSystems(params);

      return { data: res, systemId: params.systemId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const setGroupsCollapsed = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_GROUPS_COLLAPSED, payload });
};

export const setSystemCollapsed = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SYSTEM_COLLAPSED, payload });
};

export const setSelectedVesselFilter = (payload: Vessel | null) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.SET_SELECTED_VESSEL_FILTER, payload });
};
