import React from 'react';

import ShiftsTable from '../shifts-table';
import ShiftTypeSelector from '../shift-type-selector';

import {
  selectActiveShiftId,
  selectHighlightedSeaman,
  selectCrewShiftComments,
  selectCrewActiveInternalShift
} from 'crew/store/shifts/selectors';
import { useSelector } from 'react-redux';

const ShiftCard = ({
  updateShift = () => null,
  validateShifts,
  vesselId,
  crew,
  isViewOnly,
  ...rest
}) => {
  const activeShiftId = useSelector(selectActiveShiftId);
  const highlightedSeaman = useSelector(selectHighlightedSeaman);
  const isEditing = activeShiftId === crew.id;

  const activeCrewInternalShift = useSelector(state =>
    selectCrewActiveInternalShift(state, crew?.id)
  );
  const activeCrewShiftComments = useSelector(state => selectCrewShiftComments(state, crew?.id));

  const getShiftParams = (dates, updatedDateShifts) => {
    const params = {
      dates: dates.map(date => ({
        date: date.date,
        shifts:
          updatedDateShifts && updatedDateShifts.date === date.date
            ? updatedDateShifts.shifts
            : (activeCrewInternalShift && activeCrewInternalShift[date.date]) || [],
        comments:
          activeCrewShiftComments?.[date?.date] === undefined
            ? date?.comments
            : activeCrewShiftComments?.[date?.date] || null
      })),
      vesselId,
      crewId: crew.id
    };

    return params;
  };

  const onUpdate = async dates => {
    return await updateShift(getShiftParams(dates));
  };

  const onValidate = validateShifts
    ? async (dates, updatedDateShifts) => {
        const params = getShiftParams(dates, updatedDateShifts);
        const datesWithShifts = params.dates.filter(d => d.shifts?.length);

        return await validateShifts({ ...params, dates: datesWithShifts });
      }
    : false;

  return (
    <div
      className={`shifts-card${isEditing ? ' shifts-card--is-editing' : ''}${
        highlightedSeaman && highlightedSeaman !== crew.id ? ' opacity-4' : ''
      }`}
    >
      <ShiftTypeSelector isEditing={isEditing} crew={crew} />
      <ShiftsTable
        isEditing={isEditing}
        crew={crew}
        isViewOnly={isViewOnly}
        updateShift={onUpdate}
        validateShifts={onValidate}
        {...rest}
      />
    </div>
  );
};

export default ShiftCard;
