const TYPES = {
  SET_MANUAL_INNER_DATA: 'SET_MANUAL_INNER_DATA',
  UPDATE_MANUAL_INNER_DATA: 'UPDATE_MANUAL_INNER_DATA',
  SET_MANUAL_INNER_CHAPTERS: 'SET_MANUAL_INNER_CHAPTERS',
  SET_CURRENT_VERSION: 'SET_CURRENT_VERSION',
  UPDATE_CURRENT_VERSION: 'UPDATE_CURRENT_VERSION',
  TOGGLE_CHAPTER_CREATION_FORM: 'TOGGLE_CHAPTER_CREATION_FORM',
  UPDATE_MANUAL_CHAPTERS_TREE: 'UPDATE_MANUAL_CHAPTERS_TREE',
  UPDATE_CHAPTER: 'UPDATE_CHAPTER',
  SET_EDITTING_CHAPTER: 'SET_EDITTING_CHAPTER',
  SET_ADDING_SUBCHAPTER_TO: 'SET_ADDING_SUBCHAPTER_TO',
  SET_CHAPTER_EXPANDED: 'SET_CHAPTER_EXPANDED',
  SET_MOVE_MODAL_CHAPTER_EXPANDED: 'SET_MOVE_MODAL_CHAPTER_EXPANDED',
  EXPAND_OR_COLLAPSE_CHAPTERS: 'EXPAND_OR_COLLAPSE_CHAPTERS',
  SET_ACTIVE_CHAPTER_UID: 'SET_ACTIVE_CHAPTER_UID',
  SET_CHAPTER_MODAL_ACTION: 'SET_CHAPTER_MODAL_ACTION',
  SEARCH_IN_CHAPTERS_TREE: 'SEARCH_IN_CHAPTERS_TREE',
  SET_CHAPTER_POST: 'SET_CHAPTER_POST',
  SET_CHAPTER_POST_ATTACHMENTS: 'SET_CHAPTER_POST_ATTACHMENTS',
  TOGGLE_POST_FORM: 'TOGGLE_POST_FORM',
  SET_COMPARED_AT_VERSION: 'SET_COMPARED_AT_VERSION',
  SET_COMPARED_AT_VERSION_POST: 'SET_COMPARED_AT_VERSION_POST',
  SET_COMPARED_CHANGES_ONLY: 'SET_COMPARED_CHANGES_ONLY',
  SET_NAVIGATION_PREVENTION_MODAL: 'SET_NAVIGATION_PREVENTION_MODAL',
  SET_RELATED_CHAPTERS_MODAL: 'SET_RELATED_CHAPTERS_MODAL'
};

export default TYPES;
