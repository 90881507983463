import React, { useState } from 'react';
import CircleButton from 'common/components/buttons/CircledButton';
import Modal from './Modal';

const Remarks = ({ enabled, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="d-flex w-100p criteria-list--remarks">
      {enabled ? (
        value ? (
          <CircleButton
            type="edit"
            onClick={() => setIsOpen(true)}
            svgStyle={{ width: 12, height: 12 }}
          />
        ) : (
          <CircleButton
            type="add"
            onClick={() => setIsOpen(true)}
            svgStyle={{ width: 8, height: 8 }}
          />
        )
      ) : null}
      <span
        className={`w-100p me-4 text-truncate criteria-list--remarks-text${
          enabled && !value ? ' criteria-list--remarks-text--enabled' : ''
        }`}
      >
        {value || '-'}
      </span>
      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} onChange={onChange} value={value} />
    </div>
  );
};

export default Remarks;
