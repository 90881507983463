import DateInput, { mode } from 'common/components/form/inputs/date';
import moment from 'moment';

const DatetimeRangeInput = ({ disabled, error, value, onChange }) => {
  return (
    <DateInput
      hasTime
      showTime
      activeMode={mode.range.date}
      error={error}
      from={disabled || !value?.from ? null : moment(value.from)}
      to={disabled || !value?.to ? null : moment(value.to)}
      onChange={({ from, to }) =>
        onChange({
          from: from ? from.format('YYYY-MM-DD HH:mm:ss') : null,
          to: to ? to.format('YYYY-MM-DD HH:mm:ss') : null
        })
      }
    />
  );
};

export default DatetimeRangeInput;
