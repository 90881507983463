import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { filterAndMapData } from '../helpers/_functions';

const DEFAULT_RADIUS = 3;
const DEFAULT_BORDER_COLOR = variables.primary;
const DEFAULT_TRANSITION = 100;
const DEFAULT_OPACITY = 1;

const styleDefinitions = [
  { type: 'attr', key: 'radius', style: 'scatterRadius', defaultValue: DEFAULT_RADIUS },
  { type: 'attr', key: 'fill', style: 'scatterBackgroundColor', defaultValue: variables.lightBlue },
  { type: 'attr', key: 'transition', style: 'scatterTransition', defaultValue: DEFAULT_TRANSITION },
  { type: 'attr', key: 'stroke', style: 'scatterBorderColor', defaultValue: DEFAULT_BORDER_COLOR },
  {
    type: 'attr',
    key: 'stroke-width',
    style: 'scatterBorderWidth',
    defaultValue: 1
  },
  { type: 'attr', key: 'opacity', style: 'scatterOpacity', defaultValue: DEFAULT_OPACITY }
];

const getShapeForRenderer = shape => {
  switch (shape) {
    case 'triangle':
      return 'polygon';
    case 'circle':
    default:
      return 'circle';
  }
};

const renderPlot = ({ plotID, plot, getPlotStyle, xFn, yFn, element, plotIndex }) => {
  const filteredData = filterAndMapData(plot, plotID);
  if (!filteredData.length) return;

  // Notice the .selectAll here. It is important since the number of elements we have is the same as our data points.
  // This is different from the rest since in the line plotType for example, we only have one single <path /> element.
  const shape = getPlotStyle('shape', 'circle');

  const existingElements = element.selectAll(`.${plotID}`).data(filteredData);

  const el = (
    !existingElements.empty()
      ? existingElements
      : existingElements
          .enter()
          .append('custom')
          .classed(`${getShapeForRenderer(shape)} ${plotID} plot-${plotIndex}`, true)
  )
    .attr('x', xFn)
    .attr('y', yFn)
    .attr('plot-index', plotIndex);

  if (shape === 'triangle') el.attr('points', '-4,2 4,2 0,-4');

  styleDefinitions.forEach(styleDefinition => {
    el[styleDefinition.type](
      styleDefinition.key,
      getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
    );
  });
};
const scatter = {
  renderPlot
};

export default scatter;
