import { VesselSystemAssignment } from '@/common/types/vessel-systems';
import { GetFilterValueFuncType, GetTopFilterValueFuncType } from '@webthatmatters/orca-table';
import { useAppSelector } from '@/store/hooks';
import { FC, useMemo } from 'react';
import { MultiValue } from 'react-select';
import Select from '@/ts-common/components/form/inputs/select';
import useFilterQuerySelector from '@/common/components/filters/useFilterQuerySelector.ts';

type ValueType = number[] | string[] | null;

type VesselSystemAssignmentSelectorProps = {
  filter?: { value: ValueType };
  value?: ValueType;
  tableFilterVesselKey?: string;
  onChange: (s: number[]) => void;
  getTableFilterValue?: GetFilterValueFuncType;
  getTableTopFilterValue?: GetTopFilterValueFuncType;
};

type TableVesselFilter = number[] | number | undefined | null;

const VesselSystemAssignmentSelector: FC<VesselSystemAssignmentSelectorProps> = ({
  filter,
  value,
  tableFilterVesselKey = 'vessel_id',
  onChange,
  getTableFilterValue,
  getTableTopFilterValue,
  ...rest
}) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const requestedVesselId = useMemo(() => {
    if (isOnBoard) return undefined;

    const vesselFilter = ((getTableFilterValue && getTableFilterValue(tableFilterVesselKey)) ||
      (getTableTopFilterValue && getTableTopFilterValue(tableFilterVesselKey)) ||
      null) as TableVesselFilter;

    if (vesselFilter && Array.isArray(vesselFilter))
      return vesselFilter.length === 1 ? vesselFilter[0] : null;
    else if (vesselFilter) return vesselFilter;

    return null;
  }, [getTableFilterValue, getTableTopFilterValue, isOnBoard, tableFilterVesselKey]);

  const requestParams = useMemo(() => {
    return {
      enabled: isOnBoard || !!requestedVesselId,
      path: isOnBoard
        ? `/vessel-system-assignments`
        : `/vessels/${requestedVesselId}/vessel-system-assignments`,
      params: { paging: { current_page: 1, per_page: 250 } }
    };
  }, [requestedVesselId, isOnBoard]);

  const selectedOptions = useFilterQuerySelector(filter?.value || value || null, requestParams) as
    | VesselSystemAssignment[]
    | null;

  return (
    <Select
      placeholder="Select value"
      {...rest}
      getOptionValue={(option: VesselSystemAssignment) => option.id.toString()}
      getOptionLabel={(option: VesselSystemAssignment) => option.description}
      memoizedRequestParams={requestParams}
      value={selectedOptions}
      onChange={(selected: MultiValue<VesselSystemAssignment>) => onChange(selected.map(s => s.id))}
      isAsync={true}
      isMulti={true}
    />
  );
};

export default VesselSystemAssignmentSelector;
