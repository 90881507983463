import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectLatestPeriod } from 'common/components/mga/store/selectors';
import SvgRender from 'common/components/general/SvgRender';

import edit from 'common/assets/svg/actions/edit.svg';
import * as mgaActions from 'common/components/mga/store/actions';

const PeriodButtonEdit = () => {
  const [setMgaPeriodForm] = useActions([mgaActions.setMgaPeriodForm]);

  const openModal = useCallback(active => setMgaPeriodForm({ isModalOpen: true, active }), []);
  const latestPeriod = useSelector(selectLatestPeriod);

  return (
    <Button
      type="button"
      color="white"
      className="d-inline-flex align-items-center px-2 py-1 border-0 text-primary"
      onClick={() => openModal(latestPeriod)}
    >
      <SvgRender src={edit} style={{ width: 14, height: 14 }} />
      <span className="fw-medium text-primary lh-1 ps-1">Edit Draft MGA Period</span>
    </Button>
  );
};

export default PeriodButtonEdit;
