import { getDateFormatForGraphPeriodFromData } from 'common/components/graph/base/helpers/_functions';
import addHoverListener from '../helpers/_hover';
import {
  getYAxes,
  makeGridLines,
  getLeftOffset,
  getRightOffset,
  getXAxisElement,
  getYAxesElements,
  maskGraphClipPath,
  translateYAxes,
  constructYAxes,
  constructXAxis
} from 'common/components/graph/base/helpers/axes';

import _get from 'lodash/get';

const useD3Axes = (
  plots,
  schema,
  events,
  getGraphStyle,
  getGraphOption,
  getYAxisOption,
  getXAxisOption,
  disabledPlots,
  graphUID,
  dataFromAllPlots,
  axesConfig
) => {
  const yAxes = getYAxes(plots, axesConfig?.y);

  const dateTimeFormat =
    schema?.customDateFormat ?? getDateFormatForGraphPeriodFromData(dataFromAllPlots);

  const renderAxes = (element, getDimensions, renderPlots, memoryElement) => {
    // Schema settings
    const isZoomDisabled = _get(schema, 'isZoomDisabled');
    const hideGridLines = _get(schema, 'hideGridLines');
    const boundlessHover = _get(schema, 'boundlessHover');

    // Of the whole container
    const { width, height } = getDimensions();

    const y = constructYAxes({
      schema,
      getDimensions,
      getGraphStyle,
      dataFromAllPlots,
      yAxes,
      getYAxisOption
    });
    // This sets all of the y axes d3 elements
    const { yAxesElements, axesDimensions } = getYAxesElements({
      element,
      yAxes,
      height,
      getYAxisOption,
      y,
      dataFromAllPlots,
      disabledPlots,
      schema
    });

    const visibleYAxes = yAxes.filter(yAxis => !yAxis.hideAxis);

    const leftOffset = getLeftOffset({ axesDimensions, yAxes: visibleYAxes, getGraphOption });
    const rightOffset = getRightOffset({ axesDimensions, yAxes: visibleYAxes, getGraphOption });

    translateYAxes({
      yAxesElements,
      width,
      getGraphOption,
      getGraphStyle,
      leftOffset,
      rightOffset,
      axesDimensions
    });

    const x = constructXAxis({
      dataFromAllPlots,
      leftOffset,
      rightOffset,
      schema,
      getDimensions,
      getGraphStyle,
      getXAxisOption
    });

    // Of the inner graph
    const innerGraphHeight = height - getGraphStyle('marginBottom') - getGraphStyle('marginTop');
    const innerGraphWidth =
      width - leftOffset - rightOffset - getGraphStyle('marginLeft') - getGraphStyle('marginRight');

    // Reference to hold starting version of scale for zoom
    const xReference = x.copy();
    const yReference = y.map(singleY => singleY.axis.copy());

    // Sets the x axis
    const xAxisElement = element.select('.x-axis').call(
      getXAxisElement({
        getXAxisOption,
        getGraphStyle,
        innerGraphHeight: innerGraphHeight,
        schema,
        x,
        dateTimeFormat,
        width: innerGraphWidth
      })
    );

    events.addToGroupGraphsAxesInfo({
      graphUID,
      x,
      y,
      xAxisElement,
      yAxesElements,
      xReference,
      yReference,
      width: innerGraphWidth,
      height: innerGraphHeight,
      getXAxisOption,
      getYAxisOption,
      getGraphStyle,
      renderPlots,
      memoryElement,
      dataFromAllPlots,
      element,
      getDimensions,
      leftOffset,
      disabledPlots,
      schema
    });

    const axesDetails = {
      x,
      y,
      innerWidth: innerGraphWidth,
      innerHeight: innerGraphHeight,
      leftOffset: leftOffset + getGraphStyle('marginLeft'),
      topOffset: getGraphStyle('marginTop')
    };

    if (!events.groupGraphsAxesInfo[graphUID]) return axesDetails;

    if (y.length && !hideGridLines) {
      // Grid lines:
      makeGridLines({
        getGraphStyle,
        height: innerGraphHeight,
        leftOffset,
        x,
        getXAxisOption,
        getYAxisOption,
        element,
        width: innerGraphWidth,
        y
      });

      if (_get(schema, 'hideGridDomains')) {
        element.selectAll('.domain').classed('d-none', true);
      }
    }

    // Masking the zoomed in overflow:
    if (!isZoomDisabled) {
      maskGraphClipPath({
        element,
        graphUID,
        leftOffset,
        getGraphStyle,
        innerGraphWidth,
        innerGraphHeight
      });
    }

    addHoverListener({
      dataFromAllPlots,
      graphUID,
      schema,
      memoryElement,
      element,
      events,
      yAxes,
      disabledPlots,
      x,
      y,
      boundlessHover,
      getXAxisOption
    });

    return axesDetails;
  };

  return {
    renderAxes,
    getGraphStyle,
    yAxes
  };
};

export default useD3Axes;
