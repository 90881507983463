const Cell = ({ children, className = '', width }) => {
  return (
    <div
      className={`table-form-field-submission__cell ${className}`}
      style={{
        minWidth: width ? `${width}px` : '',
        maxWidth: width ? `${width}px` : ''
      }}
    >
      {children}
    </div>
  );
};

export default Cell;
