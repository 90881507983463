import { useSelector } from 'react-redux';
import {
  selectChapter,
  selectChapterSubChapters,
  isChapterInEditMode,
  isAddingSubChapter,
  isChapterExpanded,
  selectActiveChapterUid,
  selectManualId,
  isChapterInSearch,
  chaptersTreeHasSearch,
  selectIsChapterInComparison,
  selectComparedChangesOnly,
  isChapterPostFormOpen,
  isChapterHighlighted
} from 'manuals/store/selectors';
import { Button, Collapse } from 'reactstrap';
import { useActions } from 'utils/hooks';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

import ChapterInput from './ChapterInput';
import ChapterActions from './ChapterActions';
import ChapterRequests from './ChapterRequests';
import ChapterFile from './ChapterFile';
import ChapterVessels from './ChapterVessels';
import ChapterTitle from './ChapterTitle';
import ChapterSortIndexPath from 'manuals/components/ChapterSortIndexPath';

import SvgRender from 'common/components/general/SvgRender';
import * as manualsActions from 'manuals/store/actions';

import arrow from 'assets/svg/common/arrow.svg';
import emptyRibbon from 'common/assets/svg/common/ribbon-line.svg';
import solidRibbon from 'common/assets/svg/common/ribbon-solid.svg';
import burger from 'common/assets/svg/common/chapter.svg';

const Title = ({ chapter, hasChildren, onChapterRename, onChapterClick }) => {
  const [setEdittingChapter, seChapterExpanded] = useActions([
    manualsActions.setEdittingChapter,
    manualsActions.seChapterExpanded
  ]);

  const editMode = useSelector(state => isChapterInEditMode(state, chapter.uid));
  const isOpen = useSelector(state => isChapterExpanded(state, chapter.uid));
  const activeUid = useSelector(selectActiveChapterUid);
  const isHighlighted = useSelector(state => isChapterHighlighted(state, chapter.uid));

  return editMode ? (
    <div className="chapters-tree__view-edit">
      <ChapterInput
        chapter={chapter}
        onCancel={() => setEdittingChapter({ [chapter.uid]: false })}
        onSave={onChapterRename}
      />
    </div>
  ) : (
    <div
      className={`chapters-tree__view-title d-flex align-items-center${
        activeUid === chapter.uid ? ' active' : ''
      }`}
    >
      {hasChildren ? (
        <Button
          color="link"
          className="d-inline-flex align-items-center btn-subs-toggle"
          onClick={() => seChapterExpanded({ [chapter.uid]: !isOpen })}
        >
          <SvgRender
            className={`base-transition`}
            src={arrow}
            style={{
              width: hasChildren ? 10 : 12,
              height: hasChildren ? 10 : 14,
              transform: hasChildren && !isOpen ? 'rotate(-90deg)' : ''
            }}
          />
        </Button>
      ) : null}

      <div
        className={`flex-1 cursor-pointer d-flex align-items-center h-100p overflow-hidden chapter-title-container ms-3 ${
          isHighlighted ? 'chapter-title-container--highlighted' : ''
        }`}
        onClick={() => onChapterClick(chapter.uid)}
      >
        <SvgRender
          className={`me-1 ms-1 root-icon ${
            chapter.parent_uid && chapter.post_count
              ? `text-${chapter.draft_change_request_count ? 'violet' : 'yellow'}`
              : ''
          }`}
          src={!chapter.parent_uid ? burger : chapter.post_count ? solidRibbon : emptyRibbon}
          style={{ width: 14, height: 14 }}
        />
        <div className="d-flex align-items-center fw-medium lh-1 fs-14 pe-1 chapter-title overflow-hidden">
          <ChapterSortIndexPath chapter={chapter} />
          <ChapterTitle chapter={chapter} titleClassName="fs-14 fw-medium text-truncate flex-1" />
        </div>
      </div>

      <ChapterVessels chapter={chapter} />
      <ChapterFile chapter={chapter} />
      <ChapterRequests chapter={chapter} hasChildren={hasChildren} isOpen={isOpen} />
      <ChapterActions chapter={chapter} />
    </div>
  );
};

const SubMenu = ({ subChapters, onChapterRename, onChapterCreate, chapterUid }) => {
  const isOpen = useSelector(state => isChapterExpanded(state, chapterUid));

  return (
    <Collapse isOpen={isOpen}>
      <div className={`chapters-tree__view-submenu`}>
        {subChapters.map(uid => (
          <ChapterView
            key={uid}
            uid={uid}
            onChapterRename={onChapterRename}
            onChapterCreate={onChapterCreate}
          />
        ))}
      </div>
    </Collapse>
  );
};

const ChapterView = ({ uid, onChapterRename, onChapterCreate }) => {
  const chapter = useSelector(state => selectChapter(state, uid));
  const subChapters = useSelector(state => selectChapterSubChapters(state, uid));
  const isAdding = useSelector(state => isAddingSubChapter(state, uid));
  const isFormOpen = useSelector(isChapterPostFormOpen);

  const manualId = useSelector(selectManualId);
  const [setAddingSubchapterTo, setNavigationPreventionModal] = useActions([
    manualsActions.setAddingSubchapterTo,
    manualsActions.setNavigationPreventionModal
  ]);

  const { history } = useRouter();

  const hasSearch = useSelector(chaptersTreeHasSearch);
  const validSearchedPage = useSelector(state => isChapterInSearch(state, uid));

  const comparedChangesOnly = useSelector(selectComparedChangesOnly);
  const isChapterInComparison = useSelector(state => selectIsChapterInComparison(state, uid));

  if (hasSearch && !validSearchedPage) {
    return null;
  }

  if (comparedChangesOnly && !isChapterInComparison) {
    return null;
  }

  const onChapterClick = clickedUid => {
    if (isFormOpen) {
      setNavigationPreventionModal({ isOpen: true, chapterUid: clickedUid });
    } else {
      history.push({
        pathname: `${paths.manuals}/${manualId}/chapter/${clickedUid}`,
        search: history.location.search
      });
    }
  };

  return (
    <div className="chapters-tree__view">
      <Title
        chapter={chapter}
        hasChildren={subChapters ? true : false}
        onChapterRename={onChapterRename}
        onChapterClick={clickedUid => onChapterClick(clickedUid)}
      />

      {subChapters ? (
        <SubMenu
          subChapters={subChapters}
          onChapterRename={onChapterRename}
          onChapterCreate={onChapterCreate}
          chapterUid={uid}
        />
      ) : null}

      {isAdding ? (
        <div className="chapters-tree__view-add">
          <ChapterInput
            chapter={chapter}
            parentUid={chapter.uid}
            parentId={chapter.id}
            onCancel={() => setAddingSubchapterTo({ [uid]: false })}
            onSave={onChapterCreate}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ChapterView;
