import React from 'react';

import { Row, Col } from 'reactstrap';
import CrewMemberScheduleShift from './Shift';

const CrewMemberScheduleShiftContainer = ({
  watchKeepingShifts,
  nonWatchKeepingShifts,
  numberOfShifts
}) => {
  return (
    <Row className="w-100p">
      <Col xs={6} className="d-flex flex-column align-items-center">
        {numberOfShifts ? (
          Array(numberOfShifts)
            .fill(null)
            .map((_, i) => (
              <CrewMemberScheduleShift key={i} shift={watchKeepingShifts[i]} watchkeeping />
            ))
        ) : (
          <div className="working-schedule__shift">-</div>
        )}
      </Col>
      <Col xs={6} className="d-flex flex-column align-items-center">
        {numberOfShifts ? (
          Array(numberOfShifts)
            .fill(null)
            .map((_, i) => <CrewMemberScheduleShift key={i} shift={nonWatchKeepingShifts[i]} />)
        ) : (
          <div className="working-schedule__shift">-</div>
        )}
      </Col>
    </Row>
  );
};

export default CrewMemberScheduleShiftContainer;
