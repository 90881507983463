import SvgRender from 'common/components/general/SvgRender';
import { Link } from 'react-router-dom';

const TableViewIcon = ({ to, icon, onClick, className }) => (
  <div className="app-table--body--row__actions d-inline-flex ms-auto">
    {to ? (
      <Link target="_blank" to={to} className={`table-view-icon action-icon  ${className || ''}`}>
        <SvgRender src={icon} style={{ width: 14, height: 14 }} />
      </Link>
    ) : (
      <div className={`table-view-icon action-icon ${className || ''}`} onClick={onClick}>
        <SvgRender src={icon} style={{ width: 14, height: 14 }} />
      </div>
    )}
  </div>
);

export default TableViewIcon;
