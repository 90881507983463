import { useSelector } from 'react-redux';
import { selectPeriodType } from 'crew/store/shifts/selectors';

import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';

import close from 'common/assets/svg/actions/close.svg';

import { useActions } from 'utils/hooks';

import * as shiftActions from 'crew/store/shifts/actions';

const ClearScheduleButton = ({ isEditing, internalRows, setInternalShifts, crew }) => {
  const [setShiftWarnings] = useActions([shiftActions.setShiftWarnings]);

  const type = useSelector(selectPeriodType);
  const hasDailyShifts = internalRows?.[0] && internalRows?.[0].some(s => s.id);

  const clearDailySchedule = () => {
    setInternalShifts(
      internalRows?.[0].map(() => ({})),
      0
    );

    setShiftWarnings(crew.id, {});
  };

  return isEditing && type === 'daily' ? (
    <Button
      type="button"
      color="white"
      disabled={!hasDailyShifts}
      onClick={clearDailySchedule}
      className="clear-schedule-button position-absolute d-inline-flex align-items-center justify-content-center border-0 px-1 py-0"
    >
      <SvgRender src={close} style={{ width: 8, height: 8 }} className="me-1 text-primary" />
      <span className="fw-medium fs-12 lh-19 text-nowrap text-primary">Clear Schedule</span>
    </Button>
  ) : null;
};

export default ClearScheduleButton;
