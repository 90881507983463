export const normalizeShifts = (shifts = []) => {
  const atSeaWatchKeeping = [],
    atSeaNonWatchKeeping = [],
    atPortWatchKeeping = [],
    atPortNonWatchKeeping = [];

  shifts.forEach(shift => {
    if (shift.is_at_sea && shift.is_watchkeeping) {
      atSeaWatchKeeping.push(shift);
    } else if (shift.is_at_sea && !shift.is_watchkeeping) {
      atSeaNonWatchKeeping.push(shift);
    } else if (!shift.is_at_sea && shift.is_watchkeeping) {
      atPortWatchKeeping.push(shift);
    } else if (!shift.is_at_sea && !shift.is_watchkeeping) {
      atPortNonWatchKeeping.push(shift);
    }
  });

  return {
    atSea: {
      watchkeeping: atSeaWatchKeeping,
      nonWatchkeeping: atSeaNonWatchKeeping
    },
    atPort: {
      watchkeeping: atPortWatchKeeping,
      nonWatchkeeping: atPortNonWatchKeeping
    }
  };
};
