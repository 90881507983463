import React from 'react';
import CommentsPopover from 'common/components/popovers/CommentsPopover';
import { useSelector } from 'react-redux';
import { selectMaintenanceDetaisDueOnRhRemarks } from 'common/components/pms/jobs/store/selectors';

const RunningHoursDueInputPopover = () => {
  const maintenanceDetaisDueOnRhRemarks = useSelector(selectMaintenanceDetaisDueOnRhRemarks);

  return (
    <>
      {maintenanceDetaisDueOnRhRemarks ? (
        <CommentsPopover
          className="d-flex justify-content-center align-items-center p-0"
          popoverTitle="Remarks"
          comments={maintenanceDetaisDueOnRhRemarks}
          popoverProps={{ placement: 'right' }}
        />
      ) : null}
    </>
  );
};

export default RunningHoursDueInputPopover;
