import Departments from 'common/components/general/Departments';

const OfficeUserView = ({ value }) => {
  return value ? (
    <div className="text-primary fs-12 d-flex align-items-center">
      {value?.full_name}
      {value.departments?.length ? (
        <>
          <div className="small-seperator mx-1 height-12" />
          <Departments values={[value.departments[0]]} />
        </>
      ) : null}
    </div>
  ) : (
    <span>-</span>
  );
};

export default OfficeUserView;
