import CollapseHeader from 'common/components/collapse/CollapseHeader';

const CollpaseRowToggle = ({ setOpen, isOpen }) => {
  return (
    <div className={`cell col-auto cell-collapse-row`}>
      <CollapseHeader isOpen={isOpen} setOpen={setOpen} />
    </div>
  );
};

export default CollpaseRowToggle;
