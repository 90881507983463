import { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ColumnsType } from 'common/entities/tables/TableTypes';
import BodyCell from 'common/components/table/main/BodyCell';
import CollpaseRowToggle from './CollpaseRowToggle';
import { Collapse } from 'reactstrap';
import { getColStyle } from 'common/components/colored-table/_helpers';
import _debounce from 'lodash/debounce';

const Row = ({
  columns,
  rows,
  lastSticky,
  setDangerousModal,
  data,
  triggerOpen,
  collapsableRows,
  rowIndex,
  onRowClick,
  getRowClassName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [innerContentIsOpen, setInnerContentIsOpen] = useState(false);
  const isCollapsed = useMemo(
    () => (collapsableRows ? collapsableRows(data) : false),
    [collapsableRows, data]
  );

  const setDebouncedInnerContentIsOpen = useMemo(
    () => _debounce(setInnerContentIsOpen, 300),
    [setInnerContentIsOpen]
  );

  const onToggleInnerContent = useCallback(
    toggled => {
      if (toggled) setInnerContentIsOpen(toggled);
      else setDebouncedInnerContentIsOpen(toggled); // Delay closing to complete the animation
    },
    [setDebouncedInnerContentIsOpen]
  );

  const onToggle = useCallback(
    toggled => {
      setIsOpen(toggled);
      onToggleInnerContent(toggled);
    },
    [onToggleInnerContent]
  );

  useEffect(() => {
    if (isCollapsed && triggerOpen) {
      setIsOpen(triggerOpen);

      onToggleInnerContent(triggerOpen);
    }
  }, [isCollapsed, onToggleInnerContent, setDebouncedInnerContentIsOpen, triggerOpen]);

  return (
    <div
      className={`app-table--body--row-wrap ${isOpen ? 'is-open' : ''} ${
        getRowClassName ? getRowClassName(data, rowIndex) : ''
      }`}
      onClick={() => onRowClick(data)}
    >
      <div className="app-table--body--row">
        {collapsableRows ? (
          collapsableRows(data) ? (
            <CollpaseRowToggle isOpen={isOpen} setOpen={onToggle} />
          ) : (
            <div className="cell col-auto cell-collapse-hidden"></div>
          )
        ) : null}

        {columns.map((col, i) => (
          <Fragment key={col.key}>
            <BodyCell
              setDangerousModal={setDangerousModal}
              isLastSticky={i === lastSticky}
              key={col.key}
              col={col}
              row={rows ? rows[col.key] : null}
              data={data}
              style={getColStyle(col)}
              rowIndex={rowIndex}
            />
          </Fragment>
        ))}
      </div>

      {isCollapsed ? (
        <Collapse className="collapsed-row-content" isOpen={isOpen}>
          {innerContentIsOpen ? (
            <div className="collapsed-row-content__inner">{collapsableRows(data, isOpen)}</div>
          ) : null}
        </Collapse>
      ) : null}
    </div>
  );
};

export default Row;

Row.propTypes = {
  columns: ColumnsType,
  rows: PropTypes.array.isRequired,
  lastSticky: PropTypes.number,
  setDangerousModal: PropTypes.func,
  data: PropTypes.object,
  triggerOpen: PropTypes.bool,
  collapsableRows: PropTypes.func,
  rowIndex: PropTypes.number,
  onRowClick: PropTypes.func,
  getRowClassName: PropTypes.func
};
