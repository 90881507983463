import React from 'react';
import PropTypes from 'prop-types';

import TableViewIcon from 'views/dashboard/components/TableViewIcon';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import { FormType } from 'common/entities/forms/FormTypes';
import CircledButton from 'common/components/buttons/CircledButton';

const Action = ({ label, form, id, openFormDrawer }) => {
  if (label === 'forms_for_correction' || label === 'forms_to_finalize') {
    // Since we are not in obligations table here based on the label, the id prop can either be null or a form_submission_id
    if (!form?.id) return null;

    return (
      <TableViewIcon
        icon={eyeSvg}
        onClick={() => openFormDrawer({ form, form_submission: { id } })}
      />
    );
  }

  if (label === 'forms_periodical_forms') return null;

  // Since we are in obligations table here based on the label, the id prop is the obligation_id

  return (
    <div className="app-table--body--row__actions ms-auto">
      <CircledButton
        type="add"
        onClick={() =>
          openFormDrawer({
            id,
            form: form.current_form_version
          })
        }
        svgWrapperClassName={{ width: 24, height: 24 }}
        svgStyle={{ width: 10, height: 10 }}
      />
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.oneOf([
    'forms_for_correction',
    'forms_to_finalize',
    'forms_obligations',
    'forms_periodical_forms'
  ]).isRequired,
  form: FormType.isRequired,
  id: PropTypes.number,
  openFormDrawer: PropTypes.func.isRequired
};

export default Action;
