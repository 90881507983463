import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';

import Radio from 'common/components/form/inputs/Radio';
import Input from 'common/components/form/inputs/Input';

const RadioWithDetails = ({
  options = [],
  className = '',
  value = { option: null, additional_info: null },
  onChange = () => {},
  disabled,
  error
}) => {
  const selectedOption = useMemo(() => {
    return (
      options.find(option => option?.option === value?.option) || {
        option: null,
        additional_info: false
      }
    );
  }, [options, value]);

  const onSelect = option => () => {
    if (disabled) {
      return;
    }

    onChange({
      option: option.option,
      additional_info: null
    });
  };

  const onTextChange = e => {
    if (disabled) {
      return;
    }

    onChange({
      option: selectedOption.option,
      additional_info: e?.target?.value || null
    });
  };

  if (!options.length) {
    return null;
  }

  return (
    <Row noGutters className={`radio-with-details ${className}`}>
      {options.map((field, index) => (
        <div key={index} className="me-5">
          <Radio
            label={<span className="text-primary fs-12">{field.option}</span>}
            checked={value?.option === field?.option}
            className="mb-0"
            onChange={onSelect(field)}
            invisible // does not make the input invisible, required for error to display correct
            error={disabled ? null : index === 0 ? error : null}
            type={error ? 'red' : 'green'}
          />
        </div>
      ))}

      {selectedOption.additional_info ? (
        <Col xs={12} className="mt-1">
          <Input
            rows={3}
            type="textarea"
            placeholder="Add some text"
            value={value.additional_info}
            className="mb-0"
            onChange={onTextChange}
            disabled={disabled}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default RadioWithDetails;
