import { createAsyncThunk } from '@reduxjs/toolkit';
import { successHandler } from 'common/utils/notifications';

import { deleteRequest, post, put } from 'utils/api';

export const createFormObligation = createAsyncThunk(
  'CREATE_FORM_OBLIGATION',
  async (params, { rejectWithValue }) => {
    try {
      const res = await post('/form-obligations', params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteFormObligation = createAsyncThunk(
  'DELETE_FORM_OBLIGATION',
  async ({ obligationId }, { rejectWithValue }) => {
    try {
      const res = await deleteRequest(`/form-obligations/${obligationId}`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createFormObligationSubmission = createAsyncThunk(
  'CREATE_FORM_OBLIGATION_SUBMISSION',
  async ({ obligationId, ...rest }, { rejectWithValue }) => {
    try {
      const res = await post(`/form-obligations/${obligationId}/submissions`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const ignoreFormObligation = createAsyncThunk(
  'IGNORE_FORM_OBLIGATION',
  async ({ obligationId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/form-obligations/${obligationId}/ignore`);

      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Form obligation ignored successfully'
        })
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unignoreFormObligation = createAsyncThunk(
  'UNIGNORE_FORM_OBLIGATION',
  async ({ obligationId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await put(`/form-obligations/${obligationId}/unignore`);

      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Form obligation unignored successfully'
        })
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
