import React from 'react';

import PropTypes from 'prop-types';
import SelectorWithLabel from '@/common/components/selectors/reports/SelectorWithLabel';
import ReportsSelectorWithId from '@/common/components/selectors/reports/SelectorWithId.tsx';

import EmptyPage from 'common/components/general/EmptyPage';
import EmptyReportPage from 'common/components/general/EmptyReportPage';
import ExportIcon from 'common/components/general/ExportIcon';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

import { css } from '@emotion/react';
import { Row, Col } from 'reactstrap';
import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';

import reports from 'common/assets/svg/common/reports.svg';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { selectTableListRequestParams } from 'common/components/table/store/selectors';
import SaveTableLayout from 'components/layouts/page/table/save-table';

const getAlias = type => {
  switch (type) {
    case 'budgeting':
      return 'accounting';
    default:
      return type;
  }
};

const ReportLayout = ({
  type,
  className = '',
  report,
  children,
  useLocationSearch,
  shouldShowReportIdInUrl,
  actions,
  tabs,
  path,
  reportSelectorProps = {},
  emptyPageProps = {},
  tableLabel = '',
  infoText = '',
  emptyStateSelector,
  enableSaveTableLayout
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const tableRequestParams = useSelector(state =>
    selectTableListRequestParams(state, `reports_${report?.replaceAll('-', '_')}`)
  );

  const onDownload = async () => {
    setIsDownloading(true);

    const rParams = {
      sorting: tableRequestParams?.sorting,
      filters: tableRequestParams?.filters
    };

    try {
      await handleFileDownload(
        {
          url: `/reports/${report}/export`
        },
        download,
        false,
        {
          requestParams: rParams,
          parsed: true
        }
      );

      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      console.error(e);
    }
  };

  const isEmpty = useSelector(state =>
    emptyStateSelector ? emptyStateSelector(state, tableLabel) : false
  );

  return (
    <div key={report} className={`report-layout ${report || ''} ${className}`} css={style}>
      <Row className="d-flex align-items-center mb-4">
        <Col xs={3}>
          {shouldShowReportIdInUrl ? (
            <ReportsSelectorWithId
              className="mb-0"
              type={type}
              path={path}
              {...reportSelectorProps}
            />
          ) : (
            <SelectorWithLabel
              className="mb-0"
              type={type}
              useLocationSearch={useLocationSearch}
              {...reportSelectorProps}
            />
          )}
        </Col>

        {tabs ? (
          <Col xs="auto" className="d-flex align-items-center border-start ps-3 ms-2">
            <NavigationGroupTabs tabs={tabs} />
          </Col>
        ) : null}

        {enableSaveTableLayout && tableLabel ? (
          <Col>
            <SaveTableLayout label={tableLabel} headerClassName={'mb-0'} />
          </Col>
        ) : null}

        {actions ? (
          <Col className="d-flex align-items-center me-5">
            {actions?.enableExportExcel ? (
              <>
                <ExportIcon
                  className="ms-auto me-1"
                  extension={'excel'}
                  svgStyle={{ width: 14, height: 18 }}
                  textClassname="fs-14 fw-normal"
                  text={'Export excel'}
                  disabled={isDownloading}
                  onClick={onDownload}
                />

                <div className="separator ms-2 me-3" />
              </>
            ) : (
              <div className="ms-auto me-2">{React.isValidElement(actions) ? actions : null}</div>
            )}
          </Col>
        ) : null}

        {infoText ? (
          <Col className="text-end pe-8">
            <span className="text-violet text-nowrap fs-12">{infoText}</span>
          </Col>
        ) : null}
      </Row>
      <div>
        {!report ? (
          <EmptyPage
            className="report-layout__state"
            pageIcon={reports}
            pageText={` You have not selected any ${getAlias(type)} report yet.`}
            {...emptyPageProps}
          />
        ) : (
          children
        )}

        {isEmpty && report ? (
          <EmptyReportPage
            pageIcon={reports}
            pageText={`Report has no data yet.`}
            className="report-layout__state"
          />
        ) : null}
      </div>
    </div>
  );
};

ReportLayout.propTypes = {
  type: PropTypes.string.isRequired,
  report: PropTypes.string,
  shouldShowReportIdInUrl: PropTypes.bool,
  reportSelectorProps: PropTypes.shape({ placeholder: PropTypes.string }),
  tabs: PropTypes.array,
  emptyPageProps: PropTypes.shape({
    pageIcon: PropTypes.string,
    pageText: PropTypes.string,
    className: PropTypes.string
  }),
  actions: PropTypes.oneOf(PropTypes.shape({ enableExportExcel: PropTypes.bool }), PropTypes.node),
  // When the bellow have been set, the save-table functionality will be enabled. Use the same table label as in useTable hooks
  tableLabel: PropTypes.string,
  enableSaveTableLayout: PropTypes.bool
};

const style = css`
  .empty-page {
    &__bg {
      height: calc(100% - 21.5vh);
      left: 5.8vw;
      top: 38vh;
    }

    &__text {
      margin-top: 31vh;
      margin-left: 24vw;
      font-size: ${variables.size24};
    }
  }
`;

export default ReportLayout;
