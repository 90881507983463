import React from 'react';
import { shape, func, bool } from 'prop-types';
import { Col, Row } from 'reactstrap';
import DateInput from 'common/components/form/inputs/date';
import ClearButton from 'common/components/buttons/ClearButton';
import { FormField } from 'common/entities/form/FormTypes';

const ResolveDates = ({ fields, selectField, disabled }) => {
  return (
    <Row className="align-items-end">
      <Col xs="auto">
        <DateInput
          label="Resolution Date"
          onChange={date => selectField('resolved_at')(date)}
          className="mb-0"
          disabled={disabled}
          {...fields.resolved_at}
        />
      </Col>

      {fields.resolved_at?.value ? (
        <Col className="ps-0">
          <ClearButton disabled={disabled} onClick={() => selectField('resolved_at')(null)} />
        </Col>
      ) : null}
    </Row>
  );
};

ResolveDates.propTypes = {
  selectField: func,
  fields: shape({
    resolved_at: FormField
  }),
  disabled: bool
};

export default ResolveDates;
