import NumberInput from 'common/components/form/inputs/NumberInput';

const InventorySurveyRobInput = ({ onChange, value, error, disabled }) => {
  return (
    <NumberInput
      onChange={e => onChange(e.target.value)}
      className="mb-0"
      placeholder="Add no."
      value={value}
      error={error}
      disabled={disabled}
    />
  );
};

export default InventorySurveyRobInput;
