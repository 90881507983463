import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Priority from 'common/components/general/Priority';

import styled from '@emotion/styled';

import PropTypes from 'prop-types';

export const ID = ({ id }) => {
  return <TextWithTooltip className="fw-bold h-auto fs-10 me-1">{id}</TextWithTooltip>;
};

ID.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const Title = ({ importanceId, name }) => {
  return (
    <div className="d-flex align-items-center overflow-hidden">
      <Priority className="me-1" value={importanceId} />
      <div className="overflow-hidden d-flex align-items-center text-primary">
        <TextWithTooltip className="h-auto fs-12">{name}</TextWithTooltip>
      </div>
    </div>
  );
};

Title.propTypes = {
  importanceId: PropTypes.number,
  name: PropTypes.string
};

export const Type = ({ category, className }) => (
  <div className={`text-violet fs-12 ${className || ''}`}>
    Category: <strong>{category?.name}</strong>
  </div>
);

Type.propTypes = {
  category: PropTypes.shape({ name: PropTypes.string.isRequired }),
  className: PropTypes.string
};

export const ActionButtons = ({ children }) => {
  return (
    <ActionButtonsContainer className="linked-actions position-absolute d-flex align-items-center justify-content-center h-100p pe-1 top-0 right-0 bg-white">
      <div className="d-flex align-items-center justify-content-end w-100p h-100p linked-actions-inner position-relative ps-1">
        {children}
      </div>
    </ActionButtonsContainer>
  );
};

const ActionButtonsContainer = styled.div`
  opacity: 0;
  transition: all 0.3s ease-in-out;

  .linked-actions-inner {
    &::before {
      height: 100%;
      width: 0.313rem;
      content: '';
      position: absolute;
      left: -0.313rem;
      top: 0;
      background: linear-gradient(90deg, rgba(248, 249, 251, 0) 0%, white 100%);
    }
  }
`;

ActionButtons.propTypes = {
  children: PropTypes.node
};
