import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import * as listActions from 'store/lists/actions';

const PeriodicitySelector = ({
  filter,
  onChange,
  isMulti,
  autoFocus = true,
  styled,
  gray,
  ...rest
}) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const periodicities = useSelector(state => state.lists['schedule-intervals']);

  useEffect(() => {
    if (!periodicities.options.length && !periodicities.isFetching) {
      fetchListOptions('schedule-intervals');
    }
  }, []);

  const SelectTag = styled ? StyledSelect : Select;

  return (
    <SelectTag
      className={`form-field react-select position-relative mb-0`}
      autoFocus
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter.value}
      options={periodicities.options.map(o => ({ id: o.label, name: o.name }))}
      isClearable
      isMulti
      onChange={sel => {
        onChange({
          value: sel ? (isMulti ? sel : sel) : ''
        });
      }}
      {...rest}
    />
  );
};

export default PeriodicitySelector;
