import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Chatbox from 'common/components/chatbox';

import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import {
  getJobMessages,
  postJobMessage,
  jobMarkAsRead,
  getJobUnreadMessages
} from 'common/components/jobs/_base/store/actions';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { useDispatch } from 'react-redux';

const Chat = ({ className, drawer }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const dispatch = useDispatch();

  const getMessagesAction = async () => {
    if (!jobId) return;
    try {
      await dispatch(getJobMessages({ job_id: jobId }));
    } catch (error) {
      console.error(error);
    }
  };

  const postMessageAction = async params => {
    try {
      await dispatch(postJobMessage({ job_id: jobId, ...params }));
    } catch (error) {
      console.error(error);
    }
  };

  const getUnreadMessages = async () => {
    if (!jobId) return;

    try {
      await dispatch(getJobUnreadMessages({ job_id: jobId }));
    } catch (err) {
      console.error(err);
    }
  };

  const markAsReadAction = async () => {
    if (!jobId) return;

    try {
      await dispatch(jobMarkAsRead({ job_id: jobId }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Chatbox
      className={className}
      getMessages={getMessagesAction}
      sendMessage={postMessageAction}
      getUnreadMessages={getUnreadMessages}
      markMessagesAsRead={markAsReadAction}
      drawer={drawer}
      size={'sm'}
    />
  );
};

export default Chat;

Chat.propTypes = {
  className: PropTypes.string,
  drawer: DrawerState
};
