import React, { useRef } from 'react';

import Navbar from './navbar';
import Panel from './panel';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { useOnClickOutside } from 'utils/hooks';
import * as sidebarActions from 'store/sidebar/actions';

const Sidebar = () => {
  const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const isPanelOpen = useSelector(state => state.sidebar.isPanelOpen);
  const ref = useRef();

  const closeOnClickOutside = () => {
    if (isPanelOpen === true && !document.querySelector('.modal')) {
      togglePanel();
    }
  };

  useOnClickOutside(ref, closeOnClickOutside);

  return (
    <div id="sidebar" className="app-sidebar" ref={ref}>
      <Navbar />
      <Panel />
    </div>
  );
};
export default Sidebar;
