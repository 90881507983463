import PropTypes from 'prop-types';
import _findLastIndex from 'lodash/findLastIndex';

import BottomPagination from 'common/components/table/main/BottomPagination';
import Head from './Head';
import Body from './body';
import { ColumnsType } from 'common/entities/tables/TableTypes';

const TableMain = ({
  columns,
  rows,
  state,
  fetchData,
  setDangerousModal,
  handleTableSort,
  collapsableRows,
  triggerOpen,
  hideTableHead,
  emptyStateComponent,
  getRowClassName,
  onRowClick
}) => {
  const lastSticky = _findLastIndex(columns, o => o.sticky);

  return (
    <>
      <div className={`app-table--main`}>
        {hideTableHead ? null : (
          <Head
            columns={columns}
            state={state}
            handleTableSort={handleTableSort}
            lastSticky={lastSticky}
            collapsableRows={collapsableRows}
          />
        )}
        {!state.data?.length && emptyStateComponent ? (
          emptyStateComponent
        ) : (
          <Body
            columns={columns}
            rows={rows}
            state={state}
            lastSticky={lastSticky}
            setDangerousModal={setDangerousModal}
            collapsableRows={collapsableRows}
            triggerOpen={triggerOpen}
            getRowClassName={getRowClassName}
            onRowClick={onRowClick}
          />
        )}
      </div>
      {state.paging?.total > 1 ? (
        <BottomPagination paging={state.paging} fetchData={fetchData} />
      ) : null}
    </>
  );
};

export default TableMain;

TableMain.propTypes = {
  columns: ColumnsType,
  rows: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  setDangerousModal: PropTypes.func,
  handleTableSort: PropTypes.func,
  collapsableRows: PropTypes.func,
  triggerOpen: PropTypes.bool,
  hideTableHead: PropTypes.bool,
  getRowClassName: PropTypes.func,
  emptyStateComponent: PropTypes.element
};
