import React from 'react';

import Field from './Field';
import { useSelector } from 'react-redux';
import { selectFieldVisibility, selectFieldTemplateTooltip } from 'captain-reports/store/selectors';

// Used to render the array fields

const VisibleGroupField = ({ field, subGroup, subGroupIndex }) => {
  const visible = useSelector(state => selectFieldVisibility(state, field.key));
  const tooltip = useSelector(selectFieldTemplateTooltip(field.key));
  if (!visible) return null;

  return (
    <Field className={field.className} width={field.width}>
      <field.render
        field={field}
        tooltip={tooltip ? tooltip : ''}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </Field>
  );
};

export default VisibleGroupField;
