import React from 'react';

import styled from '@emotion/styled';

import PropTypes from 'prop-types';

const Divider = ({ className = '' }) => {
  return <Container className={`bg-anti-flash-white ${className}`}></Container>;
};

const Container = styled.div`
  height: 1px;
`;

Divider.propTypes = {
  className: PropTypes.string
};

export default Divider;
