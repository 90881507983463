import { configureStore } from '@reduxjs/toolkit';

import notificationsMiddleware from './notificationsMiddleware';
import resetDynamicTablesMiddleware from './resetDynamicTablesMiddleware';
import authMiddleware from './authMiddleware';
import rootReducer from './rootReducer';
import { saveState, loadState } from 'store/_helpers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { history } from './rootReducer';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';

const preloadedState = loadState();

const middlewares = [
  notificationsMiddleware,
  resetDynamicTablesMiddleware,
  authMiddleware,
  routerMiddleware(history)
];

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const configuredStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(middlewares);

      if (import.meta.env.VITE_DEV_REDUX_LOGER == 'true') {
        middleware.push(logger);
      }

      return middleware;
    },
    devTools: import.meta.env.MODE === 'development',
    preloadedState
  });
};

const store = configuredStore();

if (import.meta.env.MODE !== `test`) {
  store.subscribe(() => {
    saveState({
      auth: store.getState().auth
    });
  });
}

export const persistor = persistStore(store);

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

export default store;
