import React from 'react';
import DateWithIcon from 'common/components/dates/DateWithIcon';

import { getLastVersion } from 'manuals/_helpers';
import _get from 'lodash/get';

const LastVersionDate = ({ versions, dateKey }) => {
  const lastVersion = getLastVersion(versions);

  return (
    <DateWithIcon className="fw-medium" date={_get(lastVersion, dateKey, null)} hideIcon={true} />
  );
};

export default LastVersionDate;
