import Fade from 'react-reveal/Fade';

import ProfileDataBox from 'common/components/layouts/profile-data-box';

const Documents = ({ config, data }) => {
  return (
    <Fade>
      <ProfileDataBox profile={data?.documents} sections={config} />
    </Fade>
  );
};

export default Documents;
