import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';

const DueOnRh = props => {
  const { properties } = props;

  return (
    <div className="d-flex align-items-center">
      <div
        className={`text-violet fs-12 text-violet fw-light`}
        data-testid="job-activity-rh-before"
      >
        {properties.old}
      </div>
      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <div className={`text-violet fs-12 text-violet fw-light`} data-testid="job-activity-rh-after">
        {properties.new}
      </div>
    </div>
  );
};

export default DueOnRh;
