import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import ProfileImage from 'common/components/general/ProfileImage';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import PropTypes from 'prop-types';

const ProfileImagesPreview = ({ wrapperClassName = '', parties = [], fallback, slots = 4 }) => {
  const images = parties.map(party => party.photo);
  const hiddenCount = slots === parties.length ? 0 : parties.length - slots + 1;

  const { avoidRender, tooltipID } = useTooltipID('profile-images-preview');

  if (avoidRender || !slots) return;

  return (
    <Container id={tooltipID} className={`d-flex ${wrapperClassName}`}>
      {images.map((image, i) => {
        if (hiddenCount > 0 && i > slots - 2) return null;

        return (
          <ProfileImage
            className={`${i > 0 ? 'cms-n4' : ''}`}
            rounded
            image={image?.url_inline}
            style={{ width: 15, height: 15, zIndex: slots - i }}
            fallback={fallback}
          />
        );
      })}
      {hiddenCount > 0 && (
        <HiddenCount className={`${slots > 1 ? 'cms-n4' : ''}`} count={hiddenCount} />
      )}
      <Tooltip target={tooltipID} innerClassName="text-start">
        {parties.map(party => (
          <div>{party.full_name}</div>
        ))}
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  img {
    vertical-align: baseline;
    padding: 0 !important;
  }
`;

ProfileImagesPreview.propTypes = {
  wrapperClassName: PropTypes.string,
  parties: PropTypes.array,
  fallback: PropTypes.string,
  slots: PropTypes.number
};

const HiddenCount = ({ className = '', count }) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center rounded-circle bg-white fs-8 text-primary fw-bold ${className}`}
      style={{ width: 15, height: 15 }}
    >
      +{count}
    </div>
  );
};

HiddenCount.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired
};

export default ProfileImagesPreview;
