const selectDocumentTypeColor = type => {
  if (type === 'm') {
    return 'royal-blue';
  } else if (type === 'r') {
    return 'turquoise';
  } else if (type === 's') {
    return 'danger';
  }
};

const DocumentTypeIndicator = ({ type, className = '' }) => {
  const color = selectDocumentTypeColor(type);
  const bgColor = color ? `bg-${color}` : '';

  return (
    <div
      className={`border-radius-3 cpe-4 ${bgColor} crew-document-type-indicator ${className}`}
    ></div>
  );
};

export default DocumentTypeIndicator;
