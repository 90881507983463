import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const DepartmentRolesSelector = ({ filter, onChange, isMulti, params, ...rest }) => {
  const [selectedReasons, setSelectedReasons] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'department-roles', listParams: params },
    { selected: selectedReasons, setSelected: setSelectedReasons }
  );

  return (
    <Select
      autoFocus
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={
        isMulti
          ? filter?.value?.length
            ? selectedReasons.filter(el =>
                (isMulti ? filter?.value : [filter?.value]).map(Number).includes(el.id)
              )
            : []
          : filter?.value
      }
      isMulti={isMulti}
      isAsync
      defaultOptions={() => getInitialAsyncValues('department-roles', null, isMulti, params)}
      loadOptions={search => getAsyncOptions(search, 'department-roles', params)}
      onChange={selected => {
        setSelectedReasons(isMulti ? selected : [selected]);
        onChange(
          { value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : '' },
          selected
        );
      }}
      {...rest}
    />
  );
};

export default DepartmentRolesSelector;
