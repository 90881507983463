import { useSelector } from 'react-redux';

import { isAuthorized } from 'utils/permissions/authorize';

import { stringifyObj } from 'common/utils/urls';
import paths from 'routing/routes/_paths';
import TableViewIcon from 'views/dashboard/components/TableViewIcon';

import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';

const EvaluateSeamanAction = ({ evaluateeId, obligationId }) => {
  const account = useSelector(selectAccount);
  const canEvaluate = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.create
  ]);

  return canEvaluate ? (
    <TableViewIcon
      className="bg-primary text-white"
      to={
        evaluateeId
          ? {
              pathname: `${paths.crew}/${evaluateeId}/evaluation/create`,
              search: `${stringifyObj({
                obligation_id: obligationId
              })}`
            }
          : null
      }
      icon={crewPlanning}
    />
  ) : (
    <></>
  );
};

export default EvaluateSeamanAction;
