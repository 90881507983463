import React from 'react';
import { useFormState, useUpdateEffect } from 'utils/hooks';
import { Row, Col } from 'reactstrap';
import NumberInput from 'common/components/form/inputs/NumberInput';
import CircledButton from 'common/components/buttons/CircledButton';
import Input from 'common/components/form/inputs/Input';
import { containedInSearch } from './helpers';
import { strToNumber } from 'common/utils/numbers';
import { displaySparePart } from 'common/utils/form/display';

const FieldsSparePartsRow = React.memo(({ state, index, onRemove, searchText }) => {
  const { fields, changeField, selectField } = useFormState(state);

  const {
    title,
    unit,
    quantity,
    previous_rob,
    location,
    quantity_new,
    quantity_used,
    quantity_in_repair,
    min_quantity,
    part_no,
    drawing_no
  } = fields;

  useUpdateEffect(() => {
    let rob = null;
    if (quantity_new.value) rob += strToNumber(quantity_new.value);
    if (quantity_used.value) rob += strToNumber(quantity_used.value);

    selectField('quantity')(rob === null ? '' : strToNumber(rob));
  }, [quantity_new.value, quantity_used.value]);

  if (searchText && !containedInSearch(searchText, { title: title.value, code: part_no.value }))
    return null;

  return (
    <Row
      className="text-primary fs-12 bg-shades-1 border-radius-5 align-items-center cpy-2 px-1 pe-4 cmb-2 position-relative"
      noGutters
    >
      <Col xs={3}>
        {displaySparePart({
          drawing_no: drawing_no?.value,
          part_no: part_no?.value,
          description: title?.value
        })}
      </Col>
      <Col xs={1}>{unit?.value}</Col>
      <Col xs={1} className="text-end cpe-12">
        {min_quantity?.value}
      </Col>
      <Col xs={1} className="text-end cpe-12">
        {previous_rob?.value}
      </Col>
      <Col xs={2}>
        <Input
          type="text"
          className="mb-0 pb-0 pe-1"
          placeholder="Add Location"
          onChange={changeField('location')}
          {...location}
        />
      </Col>
      <Col xs={1}>
        <NumberInput
          className="mb-0 pb-0 pe-1"
          onChange={changeField('quantity_new')}
          placeholder={'Value'}
          {...quantity_new}
        />
      </Col>
      <Col xs={1}>
        <NumberInput
          className="mb-0 pb-0 pe-1"
          onChange={changeField('quantity_used')}
          placeholder={'Value'}
          {...quantity_used}
        />
      </Col>
      <Col xs={1}>
        <NumberInput
          className="mb-0 pb-0 pe-1"
          onChange={changeField('quantity_in_repair')}
          placeholder={'Value'}
          {...quantity_in_repair}
        />
      </Col>
      <Col xs={1}>
        <NumberInput
          className="mb-0 pb-0"
          onChange={changeField('quantity')}
          placeholder={'Value'}
          disabled={quantity_new.value || quantity_used.value}
          {...quantity}
        />
      </Col>
      {!previous_rob.value ? (
        <CircledButton
          type="remove"
          svgStyle={{ width: 8, height: 8 }}
          style={{ width: 14, height: 14 }}
          onClick={() => onRemove('spare_parts', index)}
          className="position-absolute right-8 w-auto"
        />
      ) : null}
    </Row>
  );
});

export default FieldsSparePartsRow;
