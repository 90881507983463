import TextWithTooltip from 'common/components/general/TextWithTooltip';

import { entityTypePropTypes } from 'common/components/chatbox/_helpers';
import _get from 'lodash/get';

const ChatEntityName = ({ entityType, report, formSubmission, job, purchasingRequisition }) => {
  return (
    <TextWithTooltip>
      {(entityType === 'report'
        ? _get(report, 'template.name')
        : entityType === 'job'
        ? `${_get(job, 'title')} ${
            _get(job, 'maintenance_details.vessel_system_assignment.description')
              ? ` - ${_get(job, 'maintenance_details.vessel_system_assignment.description')}`
              : ''
          }`
        : entityType === 'purchasing_requisition'
        ? `${_get(purchasingRequisition, 'code')} - ${_get(purchasingRequisition, 'description')}`
        : _get(formSubmission, 'form.name')) || ''}
    </TextWithTooltip>
  );
};

ChatEntityName.propTypes = {
  entityType: entityTypePropTypes.isRequired,
  report: function (props, propName) {
    if (props['entityType'] === 'report' && !props[propName]) {
      return new Error('Please provide the Report');
    }
  },
  formSubmission: function (props, propName) {
    if (props['entityType'] === 'form_submission' && !props[propName]) {
      return new Error('Please provide the Form Submission');
    }
  },
  job: function (props, propName) {
    if (props['entityType'] === 'job' && !props[propName]) {
      return new Error('Please provide the Job');
    }
  },
  purchasingRequisition: function (props, propName) {
    if (props['entityType'] === 'purchasing_requisition' && !props[propName]) {
      return new Error('Please provide the Purchasing Requisition');
    }
  }
};

export default ChatEntityName;
