import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import {
  selectVesselConditionCreatedAt,
  selectVesselConditionCreatedBy,
  selectVesselConditionUpdatedAt,
  selectVesselConditionUpdatedBy
} from 'events/store/event-modules/vessel-condition/selectors';

const PersonInfo = () => {
  const updatedBy = useSelector(selectVesselConditionUpdatedBy);
  const updatedAt = useSelector(selectVesselConditionUpdatedAt);
  const createdBy = useSelector(selectVesselConditionCreatedBy);
  const createdAt = useSelector(selectVesselConditionCreatedAt);

  const isUpdated = updatedBy && updatedAt;
  const isCreated = createdAt && createdBy;

  return (
    <>
      {isUpdated || isCreated ? (
        <div className="fs-12 text-violet mt-3">
          Updated by <strong>{isUpdated ? updatedBy : createdBy},</strong>
          &nbsp; at &nbsp;
          <strong>{moment(isUpdated ? updatedAt : createdAt).format('DD/MM/YYYY , HH:mm')}</strong>
        </div>
      ) : null}
    </>
  );
};

export default PersonInfo;
