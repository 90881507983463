import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Spinner from 'common/components/general/Spinner';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { usePrevious } from 'utils/hooks';
import RiskAssessmentTables from './components/RiskAssessmentTables';
import { useForm, useFormState } from 'utils/hooks';
import { riskAssessmentSubmissionConfig } from './components/config';
import { getCleanValues } from './helpers';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { getRiskAssessmentOperation } from 'store/risk-assessment/actions';

const RiskAssessmentInput = ({ value, error, onChange, formSubmission, formField }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [operation, setOperation] = useState(null);

  const formSubmissionId = formSubmission?.id;

  const { formState, collectValues, loadValues, resetForm } = useForm(
    riskAssessmentSubmissionConfig,
    {
      primary_hazards: [],
      contingencies: []
    }
  );
  const { fields, selectField } = useFormState(formState);

  const dispatch = useDispatch();

  const previousFormState = usePrevious(formState?.state);

  const debouncedChange = useCallback(_debounce(onChange, 200), []);

  useEffect(() => {
    if (!operation?.id) return;

    const fetchRiskAssessment = async id => {
      setIsLoading(true);
      const res = await dispatch(getRiskAssessmentOperation(id));
      loadValues({ ...res, additional_hazards: [], control_measures: [], template_id: res.id });
      setIsLoading(false);
    };

    resetForm();
    fetchRiskAssessment(operation.id);
  }, [operation?.id, resetForm, loadValues, dispatch]);

  useEffect(() => {
    if (_isEqual(previousFormState, formState?.state)) return;

    const values = collectValues(true);

    if (!values) {
      debouncedChange(null);
      return;
    }

    const { category, ...rest } = values;

    debouncedChange({
      ...getCleanValues(rest, !formSubmissionId ? true : false),
      category_id: category.id
    });
  }, [formState?.state]);

  useEffect(() => {
    if (!formSubmissionId) return;

    setOperation({ id: value.id, name: value.name });

    if (value?.id === fields?.template_id?.value) return;

    loadValues({ ...value, template_id: value?.id });
  }, [formSubmissionId]);

  return (
    <div className="risk-assessment-field">
      <Row noGutters className="mb-2">
        <Col className="d-flex justify-content-end cpe-4 cpt-4" xs={3}>
          <span className="fs-12 text-primary fw-bold">RISK ASSESSMENT</span>
        </Col>
        <Col className="input-divider mx-2" />
        <Col xs={4}>
          <AsyncSelector
            onChange={e => selectField('category')(e)}
            value={fields.category.value || null}
            className="mb-1"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            loadOptions={search => getAsyncOptions(search, 'risk-categories')}
            defaultOptions={() => getInitialAsyncValues('risk-categories')}
            placeholder="Select category"
            error={!fields.category.value?.id && error ? error : ''}
          />
          <AsyncSelector
            onChange={e => setOperation(e)}
            value={operation}
            className="mb-0"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            loadOptions={search =>
              getAsyncOptions(search, 'risk-assessments', {
                category_id: fields.category.value?.id ?? undefined
              })
            }
            defaultOptions={() =>
              getInitialAsyncValues('risk-assessments', null, null, {
                category_id: fields.category.value?.id ?? undefined
              })
            }
            placeholder="Select operation"
            defaultOptionsTriggerChange={fields.category.value?.id}
            error={error}
          />
        </Col>
      </Row>
      {isLoading ? (
        <Spinner />
      ) : operation?.id ? (
        <>
          <RiskAssessmentTables value={value} formState={formState} formField={formField} />
        </>
      ) : null}
    </div>
  );
};

export default RiskAssessmentInput;
