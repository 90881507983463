import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import PropTypes from 'prop-types';

const Field = ({ title, icon, content }) => {
  return (
    <div>
      <div className="d-flex align-items-center text-violet fs-10">
        <SvgRender src={icon} style={{ width: 12, height: 12 }} />
        <span className="ms-1">{title}</span>
      </div>
      <TextWithTooltip className="fs-12">{content || '-'}</TextWithTooltip>
    </div>
  );
};

Field.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  content: PropTypes.string
};

export default Field;
