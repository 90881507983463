import PropTypes from 'prop-types';
import _get from 'lodash/get';
import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';
import AsyncSelector from 'common/components/selectors/AsyncSelector';

const TrainingNeeds = ({
  fields,
  configKey,
  label,
  icon,
  selectField,
  viewOnly,
  selectorLabel = '',
  className = ''
}) => {
  const value = _get(fields, `${configKey}.value`, '');

  return (
    <ShadowBox
      className={`p-2 flex-1 fs-12 d-flex flex-column h-100p crew-profile__evaluation--training_types ${className}`}
    >
      <div className={`d-flex align-items-center mb-2 pb-1 border-bottom fw-medium`}>
        <div className={`d-flex flex-1 align-items-center text-purple`}>
          <SvgRender src={icon} style={{ width: 16, height: 16 }} className="me-1" />
          {label}
        </div>
      </div>

      {viewOnly ? (
        value?.length ? (
          <ul>
            {value.map(training => (
              <li className="text-primary fs-14">
                <span className="ms-1">{training?.name}</span>
              </li>
            ))}
          </ul>
        ) : null
      ) : (
        <div className="flex-1 overflow-y w-100p">
          <AsyncSelector
            label={selectorLabel}
            type="crew-training-types"
            value={value}
            placeholder="Select training types"
            isMulti={true}
            onChange={e => selectField(configKey)(e)}
          />
        </div>
      )}
    </ShadowBox>
  );
};

TrainingNeeds.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  configKey: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  selectorLabel: PropTypes.string,
  viewOnly: PropTypes.bool.isRequired,
  selectField: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired
};

export default TrainingNeeds;
