import _get from 'lodash/get';
import React from 'react';
import Importance from 'common/components/jobs/_base/modules/activity/components/Importance';
import Assignee from 'common/components/jobs/_base/modules/activity/components/Assignee';
import Approver from 'common/components/jobs/_base/modules/activity/components/Approver';
import Tag from 'common/components/jobs/_base/modules/activity/components/Tag';
import Checklist from 'common/components/jobs/_base/modules/activity/components/Checklist';
import ChecklistToggle from 'common/components/jobs/_base/modules/activity/components/ChecklistToggle';
import Status from 'common/components/jobs/_base/modules/activity/components/Status';
import LinkedJob from 'common/components/jobs/_base/modules/activity/components/LinkedJob';
import TemplateLabel from 'common/components/jobs/_base/modules/activity/components/TemplateLabel';
import Title from 'common/components/jobs/_base/modules/activity/components/Title';
import Description from 'common/components/jobs/_base/modules/activity/components/Description';
import Reminder from 'common/components/jobs/_base/modules/activity/components/Reminder';
import Department from 'common/components/jobs/_base/modules/activity/components/Department';
import DueDate from 'common/components/jobs/_base/modules/activity/components/DueDate';
import DueOnRh from 'common/components/jobs/_base/modules/activity/components/DueOnRh';
import Viewer from 'common/components/jobs/_base/modules/activity/components/Viewer';
import Confidential from 'common/components/jobs/_base/modules/activity/components/Confidential';
import Template from 'common/components/jobs/_base/modules/activity/components/Template';
import MainDepartment from 'common/components/jobs/_base/modules/activity/components/MainDepartment';
import Vessel from 'common/components/jobs/_base/modules/activity/components/Vessel';
import Port from 'common/components/jobs/_base/modules/activity/components/Port';

export const activityMapping = {
  created: {
    text: 'created the job'
  },
  'confidential.updated': {
    text: 'changed',
    name: "job's access",
    renderComponent: props => <Confidential {...props} />
  },
  'template_id.updated': {
    text: 'changed the',
    name: 'Template',
    renderComponent: props => <Template {...props} />
  },
  'main_department.updated': {
    text: 'changed the main department',
    name: 'main department',
    renderComponent: props => <MainDepartment {...props} />
  },
  'job_template_label_values.updated': {
    name: 'template label',
    added: 'added a value in a',
    removed: 'removed a value from s',
    updated: 'changed the value of a',
    renderComponent: props => <TemplateLabel {...props} />
  },
  'priority_id.updated': {
    name: 'Importance',
    text: 'changed the',
    renderComponent: props => <Importance {...props} />
  },
  'assignee_id.updated': {
    name: 'Assignee',
    text: 'changed the',
    renderComponent: props => <Assignee {...props} />
  },
  'status_id.updated': {
    name: 'Status',
    text: 'changed the',
    renderComponent: props => <Status {...props} />
  },
  'title.updated': {
    name: 'Title',
    text: 'changed the',
    renderComponent: props => <Title {...props} />
  },
  'vessels.updated': {
    name: 'vessel',
    added: 'added a',
    removed: 'removed a',
    renderComponent: props => <Vessel {...props} />
  },
  'description.updated': {
    name: 'Description',
    text: 'changed the',
    renderComponent: props => <Description {...props} />
  },
  'ports.updated': {
    name: 'port',
    added: 'added a',
    removed: 'removed a',
    renderComponent: props => <Port {...props} />
  },
  archived: {
    name: 'Job',
    text: 'archived this'
  },
  unarchived: {
    name: 'Job',
    text: 'unarchived this'
  },
  deleted: {
    name: 'Job',
    text: 'deleted this'
  },
  'reminder.added': {
    name: 'Reminder',
    text: 'added a',
    renderComponent: props => <Reminder {...props} />
  },

  'reminder.deleted': {
    name: 'Reminder',
    text: 'removed a',
    renderComponent: props => <Reminder {...props} />
  },
  'departments.updated': {
    name: 'department',
    added: 'added a',
    removed: 'removed a',
    renderComponent: props => <Department {...props} />
  },
  'tags.updated': {
    name: 'tag',
    added: 'added a',
    removed: 'removed a',
    renderComponent: props => <Tag {...props} />
  },
  'approvers.updated': {
    name: 'approver',
    added: 'added an',
    removed: 'removed an',
    renderComponent: props => <Approver {...props} />
  },
  'job_viewers.updated': {
    name: 'job viewer',
    added: 'added a',
    removed: 'removed a',
    renderComponent: props => <Viewer {...props} />
  },
  'checklist_options.updated': {
    name: 'checklist item',
    added: 'added a',
    removed: 'removed a',
    updated: 'updated a',
    renderComponent: props => <Checklist {...props} />
  },

  'checklist_options.toggle': {
    name: 'checklist item',
    checked: 'checked a',
    unchecked: 'unchecked a',
    renderComponent: props => <ChecklistToggle checkMarkClassName="top-0" {...props} />
  },
  'job_checklist_option_comment.updated': {
    name: 'comment',
    added: 'added a',
    removed: 'removed a',
    updated: 'updated a',
    renderComponent: props => <Checklist {...props} />
  },
  'attachments.updated': {
    name: 'attachment',
    added: 'added an',
    removed: 'removed an'
  },
  'job_links.updated': {
    name: 'job',
    added: 'linked a',
    removed: 'unlinked a',
    renderComponent: props => <LinkedJob {...props} />
  },

  'due_on_rh.updated': {
    text: (
      <div className="d-flex">
        changed the&nbsp;<span className="fw-bold">Running Hours Due</span>
      </div>
    ),
    renderComponent: props => <DueOnRh {...props} />
  },
  'due_date.updated': {
    text: (
      <div className="d-flex">
        changed the&nbsp;<span className="fw-bold">Estimated Due Date</span>
      </div>
    ),
    renderComponent: props => <DueDate {...props} />
  },
  'submitted_on_vessel.updated': {
    text: <div className="d-flex">submitted the job</div>
  },
  'maintenance_approve.updated': {
    text: <div className="d-flex">approved the job</div>
  }
};

export const transformActivity = data => {
  let result = [];

  data.forEach(item => {
    if (
      item.description === 'created' ||
      item.description === 'archived' ||
      item.description === 'unarchived'
    ) {
      //created case

      result.push({
        ...item,
        ...activityMapping[`${item.description}`]
      });
    } else if (!item.description.includes('.') && item.description === 'updated') {
      //updated case, map attributes to find the right key

      let attr = Object.keys(item.properties.attributes);
      attr.forEach(activity => {
        let key = `${activity}.${item.description}`;
        if (key === 'department.id.updated') {
          key = 'main_department.updated';
        }
        if (activityMapping[key]) {
          result.push({
            ...item,
            ...activityMapping[key]
          });
        }
      });
    } else {
      //nested activity e.g departments, reminders, linked_jobs
      let key = item.description;
      let action = _get(item, 'properties.action');
      if (!activityMapping[key]) return;

      let text = activityMapping[key][action] || activityMapping[key].text;

      // case where we have to find out the action in checklist

      if (activityMapping[key]) {
        result.push({
          ...item,
          ...activityMapping[key],
          text
        });
      }
    }
  });

  return result;
};
