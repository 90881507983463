import React, { useMemo, useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const MgaAccountsSelector = ({ filter, value, onChange, listParams = {}, isMulti, ...rest }) => {
  const [multiAccounts, setMultiAccounts] = useState([]);

  const requestParams = useMemo(() => {
    return {
      paging: { per_page: 100, current_page: 1 },
      sorting: { code: 'asc', name: 'asc' },
      ...listParams
    };
  }, [listParams]);

  useFilterSelector(
    filter?.value,
    { list: 'mga-accounts', listParams: requestParams },
    { selected: multiAccounts, setSelected: setMultiAccounts }
  );

  const handleChange = selected => {
    if (filter !== undefined) {
      onChange({
        value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
      });

      setMultiAccounts(isMulti ? selected : [selected]);
    } else {
      onChange(selected);
    }
  };

  const getValue = () => {
    if (filter !== undefined) {
      return filter.value
        ? multiAccounts.filter(el => filter.value.map(Number).includes(el?.id))
        : [];
    } else {
      return value;
    }
  };

  return (
    <Select
      className={`mb-0`}
      placeholder="Select account"
      isAsync
      isMulti={isMulti}
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.code}. ${option.name}`}
      loadOptions={search => getAsyncOptions(search, 'mga-accounts', requestParams)}
      defaultOptions={() => getInitialAsyncValues('mga-accounts', null, null, requestParams)}
      value={getValue()}
      onChange={handleChange}
      components={{ Option }}
      {...rest}
    />
  );
};

export const Option = ({ children, ...props }) => {
  const depth = props.data.code.split('.').length - 1;
  return (
    <components.Option {...props} className={`w-100p ps-${depth + 1}`}>
      {children}
    </components.Option>
  );
};

export default MgaAccountsSelector;
