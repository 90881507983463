export const parseTextWithInitialMentions = (text, mentions) => {
  let newText = text;
  mentions?.forEach(mention => {
    let strToReplace = `!#_${mention.id}^^^!#$`;
    let strForReplace = `!#_${mention.id}^^^@${mention.display}!#$`;

    newText = newText.replace(strToReplace, strForReplace);
  });

  return newText;
};
