import { createSlice } from '@reduxjs/toolkit';
import { editParticipant, deleteParticipant, createParticipant, getParticipants } from './actions';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  data: [],
  loading: false
};

const slice = createSlice({
  name: 'eventParticipants',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(editParticipant.fulfilled, (state, { payload }) => {
        const index = state.data.findIndex(p => p.id === payload.id);
        if (index !== -1) state.data[index] = payload;

        state.loading = false;
        return state;
      })

      .addCase(deleteParticipant.fulfilled, (state, { payload }) => {
        state.data = state.data.filter(el => el.id !== payload.id);
        return state;
      })

      .addCase(createParticipant.fulfilled, (state, { payload }) => {
        state.data.push(payload);
        return state;
      })

      .addCase(getParticipants.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getParticipants.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
        return state;
      })

      .addCase(getParticipants.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default slice.reducer;
