import React from 'react';
import _isNumber from 'lodash/isNumber';

import SvgRender from 'common/components/general/SvgRender';

import arrow from 'common/assets/svg/jobs/priority_arrow.svg';
import dash from 'common/assets/svg/jobs/priority_dash.svg';
import { useSelector } from 'react-redux';

const priorities = {
  vlow: {
    icon: (size = 'small') => (
      <div className="d-flex rotated">
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
      </div>
    ),
    label: 'Lowest'
  },
  low: {
    icon: (size = 'small') => (
      <div className="d-flex rotated">
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
      </div>
    ),
    label: 'Low'
  },
  normal: {
    icon: (size = 'small') => (
      <div className="d-flex">
        <SvgRender
          src={dash}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
      </div>
    ),
    label: 'Medium'
  },
  high: {
    icon: (size = 'small') => (
      <div className="d-flex">
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
      </div>
    ),
    label: 'High'
  },
  vhigh: {
    icon: (size = 'small') => (
      <div className="d-flex">
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
      </div>
    ),
    label: 'Very High'
  },
  critical: {
    icon: (size = 'small') => (
      <div className="d-flex">
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
        <SvgRender
          src={arrow}
          style={{ width: size === 'small' ? 11 : 12, height: size === 'small' ? 12 : 14 }}
        />
      </div>
    ),
    label: 'Critical'
  }
};

const Priority = ({
  value,
  name = null,
  type = 'job',
  withText = false,
  size = 'small',
  labelClassName = '',
  className = '',
  list = ''
}) => {
  const listing =
    list ||
    (type === 'job'
      ? 'job-importances'
      : type === 'purchasing'
      ? 'purchasing-requisition-priorities'
      : 'importances');

  const jobImportances = useSelector(state => state.lists[listing])?.options;

  const selectLabel = () => {
    let label = value;

    if (_isNumber(value)) {
      label = jobImportances?.find(e => e.id === value)?.label;
    }

    return label;
  };

  const label = selectLabel();

  return label && priorities[label] ? (
    <div className={`job-priority job-priority--${label} ${className}`}>
      {priorities[label].icon(size)}
      {withText ? (
        <div
          className={`ms-1 text-primary lh-1 job-priority--label fs-12 ${
            labelClassName ? labelClassName : ''
          }`}
        >
          {name || priorities[label].label}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default Priority;
