import ContentLoader from 'react-content-loader';

const Loader = () => {
  return (
    <div className="mb-1 w-100p">
      <ContentLoader
        speed={1}
        backgroundColor="#F8F9FA"
        foregroundColor="#EEF0F4"
        viewBox="0 0 400 100"
        height="100%"
        width="auto"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect x="0" y="0" rx="5px" ry="5px" width="100%" height="45%" />
        <rect x="0" y="55%" rx="5px" ry="5px" width="100%" height="45%" />
      </ContentLoader>
    </div>
  );
};

export default Loader;
