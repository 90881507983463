import { useCallback, useMemo, useState } from 'react';
import _get from 'lodash/get';

import { useActions } from 'utils/hooks';
import { Col, Row } from 'reactstrap';
import PurchasingItemsSelector from 'common/components/selectors/PurchasingItemsSelector';
import { useTable } from 'common/components/table';
import {
  selectRefetchTableTrigger,
  selectLatestPeriod,
  selectInventoryDepletionMethod
} from 'common/components/mga/store/selectors';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { getMgaDepletionMethodInfo } from 'common/utils/depletion-method';
import Information from 'common/components/general/Information';

import * as tableListActions from 'store/tables/lists/actions';

import ItemsTable from './ItemsTable';
import OverviewTable from './OverviewTable';
import { selectCurrenciesWithoutBase } from '@/common/components/mga/store/selectors-ts';
import { useAppSelector } from '@/store/hooks';
import BaseToCurrentCurrency from '@/common/components/mga/components/actions/setup-forex-rate-form/components/BaseToCurrentCurrency.tsx';

const Inventory = () => {
  const [getTableList] = useActions([tableListActions.getTableList]);

  const shouldRefetchTable = useAppSelector(selectRefetchTableTrigger);
  const latestPeriod = useAppSelector(selectLatestPeriod);
  const inventoryDepletionMethodLabel = useAppSelector(selectInventoryDepletionMethod);

  const depletionMethodInfo = getMgaDepletionMethodInfo(inventoryDepletionMethodLabel);
  const currencies = useAppSelector(selectCurrenciesWithoutBase);
  const [forexRates, setForexRates] = useState([]);

  const columns = useMemo(() => [
    {
      header: 'DATE',
      type: 'date',
      key: 'timestamp',
      minWidth: 106,
      maxWidth: 106,
      sort: true
    },
    {
      header: 'ACTION',
      type: 'string',
      key: 'name',
      minWidth: 360,
      maxWidth: 360
    },
    {
      header: 'DESCRIPTION',
      type: 'string',
      key: 'description',
      minWidth: 465,
      maxWidth: 465
    },
    {
      header: 'ITEM',
      type: 'string',
      key: 'item',
      minWidth: 360,
      maxWidth: 360
    },
    {
      header: 'QUANTITY',
      type: 'number',
      key: 'quantity',
      minWidth: 80,
      maxWidth: 80
    },
    ...currencies.map(cur => ({
      header: (
        <div className="d-flex flex-column align-items-end">
          <div className="cme-4">UNIT PRICE</div>
          <div className="cme-4 text-violet">{cur.label}</div>
        </div>
      ),
      type: 'price',
      key: `price_per_unit_${cur.id}`,
      minWidth: 84,
      maxWidth: 84
    })),
    {
      header: (
        <div className="d-flex flex-column align-items-end">
          <div className="cme-4">UNIT PRICE</div>
          <div className="cme-4 text-violet">USD</div>
        </div>
      ),
      type: 'price',
      key: 'price_per_unit',
      minWidth: 84,
      maxWidth: 84
    },
    ...currencies.map(cur => ({
      header: (
        <div className="d-flex flex-column align-items-end">
          <div className="cme-4">TOTAL PRICE</div>
          <div className="cme-4 text-violet">{cur.label}</div>
        </div>
      ),
      type: 'price',
      key: `total_price_${cur.id}`,
      minWidth: 95,
      maxWidth: 95
    })),
    {
      header: (
        <div className="d-flex flex-column align-items-end">
          <div className="cme-4">TOTAL PRICE</div>
          <div className="cme-4 text-violet">USD</div>
        </div>
      ),
      type: 'price',
      key: 'total_price',
      minWidth: 95,
      maxWidth: 95
    }
  ]);

  const topFilters = useMemo(
    () => [
      {
        name: 'store_ids',
        operation: '=',
        value: null
      }
    ],
    []
  );

  const fetchData = useCallback(
    async (params, extraRequestParams) => {
      const selectedStore = _get(params, 'filters', []).find(
        f => f.name === 'store_ids' && f.value
      );

      const updatedParams = { ...params, filters: undefined, period_id: latestPeriod.id };

      if (selectedStore) updatedParams.store_ids = selectedStore?.value;

      const additionsRequestParams = { ...updatedParams };
      const removalsRequestParams = { ...updatedParams, table: 'mga_inventory_items_removals' };

      if (
        !extraRequestParams ||
        extraRequestParams?.fetchOnly === 'mga_inventory_items_additions'
      ) {
        if (
          extraRequestParams?.fetchOnly === 'mga_inventory_items_additions' &&
          extraRequestParams?.sorting
        )
          additionsRequestParams.sorting = extraRequestParams?.sorting;

        await getTableList(additionsRequestParams);
      }

      if (!extraRequestParams || extraRequestParams?.fetchOnly === 'mga_inventory_items_removals') {
        if (
          extraRequestParams?.fetchOnly === 'mga_inventory_items_removals' &&
          extraRequestParams?.sorting
        )
          removalsRequestParams.sorting = extraRequestParams?.sorting;

        await getTableList(removalsRequestParams);
      }
    },
    [latestPeriod?.id]
  );

  const tableAdditions = useTable({
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: true },
    urlRefetchLevel: 4,
    requestTableListFrom: fetchData,
    label: 'mga_inventory_items_additions',
    columns,
    top: { filters: topFilters }
  });

  useUpdateEffect(() => {
    if (shouldRefetchTable) {
      tableAdditions.refetchData();
    }
  }, [shouldRefetchTable]);

  useUpdateEffect(() => {
    tableAdditions.refetchData();
  }, [latestPeriod?.id]);

  const selectedStore = _get(tableAdditions, 'topFilters', []).find(
    f => f.name === 'store_ids' && f.value
  );

  const updateTableFilters = (value, type) => {
    const updatedFilters = tableAdditions.topFilters.map(f =>
      f.name === type ? { ...f, value } : { ...f, value: null }
    );

    tableAdditions.setTopFilters(updatedFilters);
  };

  return (
    <div>
      <Row className="align-items-center justify-content-between">
        <Col xs={5} className="d-flex align-items-center justify-content-between">
          <PurchasingItemsSelector
            className="w-100p"
            filter={selectedStore}
            onChange={updateTableFilters}
            isMga
            isMulti
            isClearable
          />
        </Col>

        <Col className="d-flex align-items-center text-violet fs-12 ms-auto" id="depletion-method">
          <div className="ms-auto">Inventory Depletion Method:</div>
          <div className="fw-bold cms-4 me-1 cpe-6">{depletionMethodInfo?.name || '-'}</div>
          <Information
            svgStyle={{ width: 14, height: 14 }}
            tooltipClassname="min-width-fit max-width-412"
            target={'depletion-method'}
            message={<div className="text-start">{depletionMethodInfo.subTitle}</div>}
          />
        </Col>
      </Row>

      {inventoryDepletionMethodLabel === 'avg' || inventoryDepletionMethodLabel === 'fifo' ? (
        <OverviewTable setForexRates={setForexRates} />
      ) : null}

      <ItemsTable header="ADDED TO INVENTORY" className="mt-4" {...tableAdditions} />
      <ItemsTable
        header="REMOVED FROM INVENTORY"
        className="mt-4"
        {...tableAdditions}
        label="mga_inventory_items_removals"
      />

      <div className="d-flex flex-column flex-1 align-items-end cmt-12">
        <div>
          {forexRates.map(forex => (
            <BaseToCurrentCurrency
              key={forex?.id}
              baseCurrencyLabel={forex?.base_currency?.label}
              currentCurrencyLabel={forex?.currency?.label}
              rate={forex?.rate}
              currenciesClassName="fw-medium"
              period={{ from: forex?.from_date, to: forex?.to_date }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Inventory;
