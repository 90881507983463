import SortableList from 'common/components/jobs/_base/modules/checklist/sortable-checklist';

const Checklist = ({
  editMode,
  isEditMode,
  handleSort,
  updateChecklistState,
  setChecklistState,
  checklistState,
  onSubmit,
  onCancel,
  memoizedDebounce,
  submitting,
  isLoading,
  checklistOptions,
  sectionId,
  checklistRef,
  showComments,
  isLocked,
  onCheck,
  hasActionButtons,
  showEmptyState = false,
  DisplayComponent,
  placeholder
}) => {
  return editMode || !isEditMode ? (
    <SortableList
      useDragHandle={true}
      isEditMode={isEditMode}
      onSortEnd={({ newIndex, oldIndex }) => handleSort(newIndex, oldIndex)}
      helperClass="job-checklist__sortable"
      lockToContainerEdges={true}
      lockAxis="y"
      lockOffset={['0%', '100%']}
      updateChecklistState={updateChecklistState}
      setChecklistState={setChecklistState}
      checklistState={checklistState}
      onSubmit={onSubmit}
      onCancel={onCancel}
      placeholder={placeholder}
      memoizedDebounce={memoizedDebounce}
      submitting={submitting}
      isLoading={isLoading}
      hasActionButtons={hasActionButtons}
    />
  ) : checklistOptions.length ? (
    <div className="job-checklist-display overflow-y">
      {checklistOptions.map((check, index) => (
        <DisplayComponent
          isEditMode={isEditMode}
          state={check}
          key={index}
          sectionId={sectionId}
          showComments={showComments}
          parentRef={checklistRef}
          disabled={submitting || isLoading || isLocked}
          onCheck={() => onCheck(check, index)}
        />
      ))}
    </div>
  ) : !editMode && !checklistOptions.length > 0 && showEmptyState ? (
    <div className="d-flex align-items-center justify-content-center text-violet fw-medium fs-12 pt-2 pb-2">
      There are no data at the moment.
    </div>
  ) : null;
};

export default Checklist;
