import PropTypes from 'prop-types';
import ShadowBox from 'common/components/general/ShadowBox';
import history from 'common/assets/svg/common/history.svg';
import SvgRender from 'common/components/general/SvgRender';
import Log from './Log';

import React, { useMemo } from 'react';
import { parseReviewProcessStepsToLogs } from './helpers';
import { Step } from 'common/entities/review-process/ReviewProcessTypes';

const History = ({ reviewProcessSteps }) => {
  const logs = useMemo(
    () => parseReviewProcessStepsToLogs(reviewProcessSteps),
    [reviewProcessSteps]
  );

  if (!logs?.length) return null;

  return (
    <ShadowBox className="cpy-12 cpe-12 ps-4">
      <div className="d-flex align-items-center fs-12 fw-medium text-violet border-bottom cmb-12 cpb-6">
        <SvgRender src={history} className="me-1" style={{ width: 14, height: 14 }} />
        Logs
      </div>

      {logs.map((log, index) => (
        <Log key={log.id} log={log} isLast={index + 1 === logs.length} />
      ))}
    </ShadowBox>
  );
};

export default History;

History.propTypes = {
  reviewProcessSteps: PropTypes.arrayOf(Step)
};
