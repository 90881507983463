import useValue from 'captain-reports/utils/useValue';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import { setValue } from 'captain-reports/store/actions';

import { selectPreviousCaptainReportFirstRevCounter } from 'captain-reports/store/selectors';
import {
  selectFieldValue,
  selectMainEngineTotalByKey
} from '@/captain-reports/store/selectors-ts.ts';

import { trimFloatNumber } from 'common/utils/numbers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FC, useEffect } from 'react';
import { Field } from '@/common/types/front-entities/captain-reports.ts';

const NavigationalEngineRpm: FC<Field> = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const dispatch = useAppDispatch();

  const lastReportRevCounter = useAppSelector(selectPreviousCaptainReportFirstRevCounter) || 0;
  const steaming_time = useValue({ key: 'steaming_time' });
  const curRevCounter = useAppSelector(state =>
    selectMainEngineTotalByKey(state, 'me_rev_counter')
  );
  const engine_rpm = useAppSelector(state => selectFieldValue(state, 'engine_rpm'));

  useEffect(() => {
    const calcRPM =
      steaming_time && curRevCounter
        ? trimFloatNumber((curRevCounter - lastReportRevCounter) / steaming_time)
        : null;

    if (calcRPM !== engine_rpm) {
      dispatch(setValue({ key: 'engine_rpm', value: calcRPM }));
    }
  }, [curRevCounter, steaming_time, lastReportRevCounter, engine_rpm, dispatch]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      onChangeFallback={() => null}
      tooltip={null}
    />
  );
};

export default NavigationalEngineRpm;
