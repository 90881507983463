import TYPES from './types';
import { get } from 'utils/api';

export const setMessages = data => dispatch => {
  dispatch({ type: TYPES.SET_MESSAGES, payload: data });
};

export const postMessage = data => dispatch => {
  dispatch({ type: TYPES.POST_MESSAGE, payload: data });
};

export const setUnreadMessages = data => dispatch => {
  dispatch({ type: TYPES.SET_UNREAD_MESSAGES, payload: data });
};

export const setMarkAsRead = data => dispatch => {
  dispatch({ type: TYPES.SET_MARK_AS_READ, payload: data });
};

export const setFetching = data => dispatch => {
  dispatch({ type: TYPES.SET_FETCHING, payload: data });
};

export const getGlobalUnreadMessages = params => dispatch => {
  dispatch({
    type: TYPES.GET_GLOBAL_UNREAD_MESSAGES.START,
    payload: { isFetching: true, data: [] }
  });

  return get(`/dashboard/messages`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_GLOBAL_UNREAD_MESSAGES.SUCCESS,
        payload: { isFetching: false, data: response.data }
      });

      return response.data;
    })
    .catch(error =>
      dispatch({
        type: TYPES.GET_GLOBAL_UNREAD_MESSAGES.ERROR,
        payload: { error, isFetching: false, data: [] }
      })
    );
};
