import Information from 'common/components/general/Information';
import { Row, Col } from 'reactstrap';

const Header = () => {
  return (
    <Row className="fs-12 text-primary mb-1 align-items-center" noGutters>
      <Col xs={3} className="fs-12 fw-bold text-primary cme-12">
        ITEM
      </Col>
      <Col xs={2} className="fs-12 fw-bold text-primary me-1 d-flex align-items-center">
        SUPPLIED ON
        <Information
          className="ms-1"
          svgStyle={{ width: 14, height: 14 }}
          message="Supplied on dates must be before the MGA starting period"
        />
      </Col>
      <Col xs={2} className="fs-12 fw-bold cme-10">
        <span className="text-primary">UNIT PRICE</span> <span className="text-violet">(USD)</span>
      </Col>
      <Col xs={2} className="fs-12 fw-bold text-primary cme-12">
        ROB
      </Col>
      <Col xs={2} className="fs-12 fw-bold cme-12">
        <span className="text-primary">TOTAL</span> <span className="text-violet">(USD)</span>
      </Col>
    </Row>
  );
};

export default Header;
