import React from 'react';

import { Row, Col } from 'reactstrap';
import LinkedEntitiesBox from 'common/components/link-entities/_components/linked-entities-box/index';
import {
  ID,
  Title,
  Type,
  ActionButtons
} from 'common/components/link-entities/_components/linked-entities-box/helpers';
import Date from 'common/components/link-entities/events/Date';
import CircledButton from 'common/components/buttons/CircledButton';
import AuthCheck from 'components/permissions/AuthCheck';
import Status from 'common/components/general/Status';

import eventsIcon from 'common/assets/svg/common/events.svg';
import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';

import PropTypes from 'prop-types';
import { Event } from 'common/entities/events/EventTypes';

const LinkedEvents = ({ eventsState, className, color }) => {
  if (!eventsState?.length) return null;

  const goToUrl = id => {
    if (!id) return;

    window.open(`${paths.events}/${id}`, '_blank');
  };

  return (
    <LinkedEntitiesBox
      entitiesState={eventsState}
      className={className}
      icon={eventsIcon}
      headerTitle="Linked Events"
      color={color}
      render={event => (
        <>
          <Row className="w-100p flex-nowrap">
            <Col className="max-width-56 min-width-56 overflow-hidden cpt-4">
              <ID id={event?.id} />
            </Col>

            <Col className="overflow-hidden">
              <Row>
                <Col xs={12} className="overflow-hidden">
                  <Row>
                    <Col className="d-flex align-items-center overflow-hidden cpt-2">
                      <Title importanceId={event?.importance_id} name={event?.name} />
                      <div className="cms-6 cme-6 text-violet fs-12">|</div>
                      <Date date={event?.started_at} />
                    </Col>
                    <Col xs="auto" className="d-flex ps-1 justify-content-end align-items-center">
                      <Status type="events" value={event?.status} />
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} className="cpb-2">
                  <Type type={event?.type} />
                </Col>
              </Row>
            </Col>
          </Row>
          <ActionButtons>
            <AuthCheck
              permissions={[permissions.office.events.list.view, permissions.onboard.events.view]}
            >
              <CircledButton
                tooltipMessage="View"
                type="view"
                onClick={() => goToUrl(event?.id)}
                svgWrapperClassName={{ minWidth: '1.25rem', height: '1.25rem' }}
              />
            </AuthCheck>
          </ActionButtons>
        </>
      )}
    />
  );
};

export default LinkedEvents;

LinkedEvents.propTypes = {
  eventsState: PropTypes.arrayOf(Event),
  className: PropTypes.string,
  color: PropTypes.string
};
