import TYPES from './types';
import { get } from 'utils/api';

export const getCrewScheduleWarnings = () => dispatch => {
  dispatch({ type: TYPES.GET_CREW_SCHEDULE_WARNINGS.START });

  return get(`/crew-working-schedule/warnings`)
    .then(response => {
      dispatch({
        type: TYPES.GET_CREW_SCHEDULE_WARNINGS.SUCCESS,
        payload: { data: response.data }
      });

      return response.data.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_CREW_SCHEDULE_WARNINGS.ERROR,
        payload: error
      });

      throw error;
    });
};
