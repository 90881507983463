import { createSlice } from '@reduxjs/toolkit';
import state from 'common/components/jobs/_base/modules/notes/store/state';
import {
  getJobNotes,
  deleteJobNotes
} from 'common/components/jobs/_base/modules/notes/store/actions';

const slice = createSlice({
  name: 'jobNotes',
  initialState: state,
  reducers: {
    setJobNotes: (state, { payload }) => {
      state.notes = payload;
      state.has_notes = payload?.length ? true : false;

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getJobNotes.fulfilled, (state, { payload }) => {
        state.notes = payload;
        state.has_notes = payload?.length ? true : false;
        return state;
      })

      .addCase(deleteJobNotes.fulfilled, (state, { payload }) => {
        state.notes = null;
        state.has_notes = false;
        return state;
      });
  }
});

export const { setJobNotes } = slice.actions;

export default slice.reducer;
