import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import Evaluations from 'views/evaluations';

const routes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: paths.crew_evaluations,
    component: Evaluations,
    permissions: [permissions.onboard.crew.evaluation_obligations.view],
    documentTitle: 'Evaluation Obligations'
  }
];

export default routes;
