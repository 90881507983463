import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Select from 'common/components/form/inputs/Select';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import useRouter from 'use-react-router';

import { selectJobField, selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import { useActions } from 'utils/hooks';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { jobEnums } from 'common/utils/fixed';

const Type = ({ hideLabel }) => {
  const { match } = useRouter();

  const type = useSelector(state => selectJobField(state, 'type'));
  const jobTypes = useSelector(state => selectListOptionsFromStore(state, 'job-types'));
  const [setJobField] = useActions([jobProfileActions.setJobField]);
  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));

  const isEditMode = match.params.id ? true : false;

  const valueType = useMemo(() => {
    return jobTypes?.find(jobtype => jobtype.label === type);
  }, [type, jobTypes]);

  return (
    <Select
      label={hideLabel ? null : 'Job Type'}
      placeholder="Select type"
      getOptionValue={option => option.label}
      getOptionLabel={option => option.name}
      value={valueType}
      loadOptions={search => getAsyncOptions(search, 'job-types')}
      defaultOptions={() => getInitialAsyncValues('job-types')}
      onChange={type => {
        setJobField('type', type.label, true);
        if (type.label === jobEnums.orca_jobs) setJobField('is_for_vessel', false);
      }}
      isAsync
      invisible={false}
      disabled={isJobLocked || isEditMode}
    />
  );
};

Type.propTypes = {
  hideLabel: PropTypes.bool
};

export default Type;
