const selectPostsReducer = state => state.news;

// Posts without image
export const selectPostsWithoutImage = state => selectPostsReducer(state)?.postsWithoutImage;
export const selectPostsWithoutImageData = state => selectPostsWithoutImage(state)?.data;
export const selectPostsWithoutImageIsLoading = state => selectPostsWithoutImage(state)?.isLoading;
export const selectPostsWithoutImageLoadMoreLoading = state =>
  selectPostsWithoutImage(state)?.loadMoreLoading;

const selectPostsWithoutImagePaging = state => selectPostsWithoutImage(state).paging;

export const selectPostsWithoutImageCurrentPage = state =>
  selectPostsWithoutImagePaging(state).current_page;

export const selectPostsWithoutImageLastPage = state =>
  selectPostsWithoutImagePaging(state).last_page;

// Announcements
export const selectAnnouncements = state => selectPostsReducer(state)?.announcements;
export const selectAnnouncementsData = state => selectAnnouncements(state)?.data;
export const selectAnnouncementsIsLoading = state => selectAnnouncements(state)?.isLoading;
export const selectAnnouncementsLoadMoreLoading = state =>
  selectAnnouncements(state)?.loadMoreLoading;

const selectAnnouncementsPaging = state => selectAnnouncements(state).paging;

export const selectAnnouncementsCurrentPage = state =>
  selectAnnouncementsPaging(state).current_page;

export const selectAnnouncementsLastPage = state => selectAnnouncementsPaging(state).last_page;

// Posts with image
export const selectPostsWithImage = state => selectPostsReducer(state)?.postsWithImage;
export const selectPostsWithImageData = state => selectPostsWithImage(state)?.data;
export const selectPostsWithImageIsLoading = state => selectPostsWithImage(state)?.isLoading;
export const selectPostsWithImageLoadMoreLoading = state =>
  selectPostsWithImage(state)?.loadMoreLoading;

const selectPostsWithImagePaging = state => selectPostsWithImage(state).paging;

export const selectPostsWithImageCurrentPage = state =>
  selectPostsWithImagePaging(state).current_page;

export const selectPostsWithImageLastPage = state => selectPostsWithImagePaging(state).last_page;
