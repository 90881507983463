import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { useDrawer } from 'common/components/drawer';

import CircledButton from 'common/components/buttons/CircledButton';
import FindingForm from 'common/components/findings/form';
import Table from './Table';
import AuditForm from '../AuditForm';

import Evaluations from '../evaluations';
import { selectEventId, selectEventVessel } from 'events/store/events/selectors';
import { useDispatch } from 'react-redux';
import {
  selectAreAuditActionsLocked,
  selectAuditsData,
  selectAuditsAreLoading
} from 'events/store/event-modules/audits/selectors';
import { getEventAudits } from 'events/store/event-modules/audits/actions';
import LinkingForm from '../LinkingForm';
import ListLoader from '../../components/ListLoader';

const Findings = ({ drawer, active, setActive }) => {
  const [auditId, setAuditId] = useState(null);
  const [eventAuditId, setEventAuditId] = useState(null);

  const dispatch = useDispatch();

  const auditsData = useSelector(selectAuditsData);
  const areAuditActionsLocked = useSelector(selectAreAuditActionsLocked);
  const linkingFormDrawer = useDrawer();
  const isLoading = useSelector(selectAuditsAreLoading);
  const [navigation, setNavigation] = useState({
    selected_id: null,
    next_id: null,
    previous_id: null
  });

  const eventId = useSelector(selectEventId);
  const eventVessel = useSelector(selectEventVessel);

  return (
    <>
      {isLoading && !auditsData?.length ? (
        <ListLoader />
      ) : (
        (auditsData || [])?.map((audit, index) => (
          <div
            key={audit.id}
            className={`${index !== 0 ? 'mt-3' : ''} ${
              index < auditsData.length - 1 ? 'border-bottom pb-3' : 'pb-1'
            }`}
          >
            <AuditForm drawer={drawer} setActive={setActive} data={audit} />
            <Table
              key={audit.id}
              audit={audit}
              active={active}
              drawer={drawer}
              navigation={navigation}
              setNavigation={setNavigation}
              setActive={setActive}
              setAuditId={setAuditId}
              setEventAuditId={setEventAuditId}
            />

            {!areAuditActionsLocked && (
              <div className="d-flex align-items-center">
                <CircledButton
                  type="add"
                  className="text-primary fw-bold fs-12 mt-2 me-3"
                  label="Add finding"
                  onClick={() => {
                    setActive(null);
                    setAuditId(audit.id);
                    setEventAuditId(audit.event_audit_id);
                    drawer.open();
                  }}
                  disabled={linkingFormDrawer?.isOpen || drawer?.isOpen}
                  svgStyle={{ width: 8, height: 8 }}
                  style={{ width: 20, height: 20 }}
                />

                <CircledButton
                  className="mt-2"
                  type="link"
                  svgClassName="text-primary"
                  svgWrapperClassName="bg-white"
                  label="Link an existing finding"
                  onClick={() => {
                    setAuditId(audit.id);
                    setEventAuditId(audit.event_audit_id);
                    linkingFormDrawer.open();
                  }}
                  disabled={linkingFormDrawer?.isOpen || drawer?.isOpen}
                  svgStyle={{ width: 10, height: 10 }}
                  style={{ width: 20, height: 20 }}
                />
              </div>
            )}

            {!areAuditActionsLocked && <Evaluations audit={audit} className="mt-3" />}
          </div>
        ))
      )}

      <LinkingForm auditId={auditId} drawer={linkingFormDrawer} />

      <FindingForm
        drawer={drawer}
        activeId={active?.id}
        navigation={navigation}
        setNavigation={setNavigation}
        active={active}
        setActive={setActive}
        auditId={auditId}
        eventAuditId={eventAuditId}
        eventVessel={eventVessel}
        refetchData={async () => await dispatch(getEventAudits({ event_id: eventId }))}
        isOnEvent={true}
      />
    </>
  );
};

Findings.propTypes = {
  drawer: DrawerState,
  active: PropTypes.array,
  setActive: PropTypes.func
};

export default Findings;
