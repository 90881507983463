import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { WinguEmail } from 'common/entities/emails/WinguEmailTypes';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { selectEventId } from 'events/store/events/selectors';

import FormSaveActions from 'common/components/form/FormSaveActions';
import {
  deleteEmailConnection,
  getEmailConnection
} from 'events/store/event-modules/email-connection/actions';
import { displayDate } from '@/ts-common/utils/dates.ts';

const Preview = ({ email, onClose }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);

  const onDelete = async () => {
    try {
      await dispatch(
        deleteEmailConnection({ event_id: eventId, email_connection_id: email.id })
      ).unwrap();
      await dispatch(getEmailConnection({ event_id: eventId })).unwrap();
      onClose();
    } catch (e) {
      console.error(e);
      onClose();
    }
  };

  return (
    <div
      className="email-connection__preview position-relative pt-2 ps-3 pb-2 pe-3 mb-1"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex-column">
        <div className="text-moody-blue fs-14 fw-medium mb-1">{displayDate(email.created_at)}</div>
        <div className="d-inline-flex align-items-center fs-14 text-primary fw-medium text-decoration-none">
          <div>{email.subject}</div>
        </div>
      </div>
      <FormSaveActions
        isHovering={isHovering}
        mode="view"
        onDelete={() => setShowDeleteModal(true)}
        style={{ right: -12, top: 12 }}
      />

      <DangerousActionModal
        transparent
        action={'Remove'}
        onAccept={onDelete}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText={'REMOVE'}
        header={'Remove'}
        body={<div>Are you sure you want to remove this email connection?</div>}
      />
    </div>
  );
};

Preview.propTypes = {
  email: WinguEmail.isRequired,
  onClose: PropTypes.func
};

export default Preview;
