import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import {
  selectisEditingModules,
  selectIsCreate,
  selectIsMainInfoLocked,
  selectEventModules
} from 'events/store/events/selectors';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import ModuleSlider from './ModuleSlider';
import { Button } from 'reactstrap';
import ArrowButton from './ArrowButton';
import { calculateMinAllowedLeft, MAX_ALLOWED_SCROLL_OFFSET } from './helpers';
import { addModule, removeModule, setIsEditingModules } from 'events/store/events/actions';

const Toolbar = ({ className = '' }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState('');
  const [left, setLeft] = useState(0);

  const dispatch = useDispatch();
  const { match } = useRouter();
  const overflowContainerRef = useRef(null);

  const isMainInfoLocked = useSelector(selectIsMainInfoLocked);
  const modules = useSelector(selectEventModules);
  const isCreate = useSelector(selectIsCreate);
  const isEditingModules = useSelector(selectisEditingModules);

  const isOverflown = overflowContainerRef.current
    ? overflowContainerRef.current.scrollWidth - overflowContainerRef.current.clientWidth >=
      MAX_ALLOWED_SCROLL_OFFSET
    : false;

  const minAllowedLeft = calculateMinAllowedLeft();

  const toggleModal = () => {
    setLabelToDelete('');
    setShowDeleteModal(prev => !prev);
  };

  const moduleList = useSelector(state => state.lists['event-modules']);

  const onDrop = e => {
    if (isMainInfoLocked) return;

    const label = e.dataTransfer.getData('module-label');
    if (moduleList && moduleList.options) {
      const elem = document.querySelector('.event-dragable-module');
      if (elem) {
        document.body.removeChild(elem);
      }
      const foundModule = moduleList.options.find(el => el.label === label);
      if (foundModule) {
        const mod = {
          id: Date.now(),
          temporary: true,
          type: {
            id: foundModule.id,
            label: foundModule.label,
            name: foundModule.name
          }
        };
        dispatch(
          addModule({
            id: match.params.id,
            type_id: foundModule.id,
            mod,
            isCreate
          })
        );
      }
    }
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onToolbarClick = () => {
    dispatch(setIsEditingModules(true));
  };

  const onDelete = async () => {
    if (deleting) return;

    try {
      const m = modules.find(el => el.type.label === labelToDelete);
      if (!m) return;
      setDeleting(true);
      await dispatch(
        removeModule({
          id: m.id,
          isCreate,
          labelToDelete
        })
      );
      setDeleting(false);
    } catch (e) {
      setDeleting(false);
    }
  };

  return (
    <div className={`event-toolbar d-flex align-items-center ${className}`}>
      <div className={`event-toolbar__slider position-relative ${isOverflown ? 'overflown' : ''}`}>
        <ArrowButton
          left={left}
          setLeft={setLeft}
          overflowContainerRef={overflowContainerRef}
          isOverflown={isOverflown}
          minAllowedLeft={minAllowedLeft}
        />

        <div
          className={`event-toolbar__slider-container d-flex align-items-center cpy-12 ${
            isOverflown ? 'overflow-x-hidden' : ''
          }`}
          draggable="true"
          onDrop={onDrop}
          onDragOver={onDragOver}
          ref={overflowContainerRef}
        >
          <ModuleSlider
            left={left}
            toggleModal={toggleModal}
            setDeleting={setDeleting}
            setLabelToDelete={setLabelToDelete}
          />
        </div>

        <ArrowButton
          left={left}
          isLeft={false}
          setLeft={setLeft}
          overflowContainerRef={overflowContainerRef}
          isOverflown={isOverflown}
          minAllowedLeft={minAllowedLeft}
        />
      </div>

      <div className="divider mx-3" />

      <Button
        type="button"
        color="white"
        disabled={isMainInfoLocked}
        onClick={onToolbarClick}
        data-cy="customize_toolbar"
        className={`event-toolbar__btn d-flex align-items-center justify-content-center text-nowrap
        text-primary cursor-pointer fs-12 fw-medium ${isEditingModules ? 'opacity-4' : ''}`}
      >
        Customize toolbar
      </Button>

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDelete}
        closeModal={toggleModal}
        isOpen={showDeleteModal}
        actionText={'REMOVE'}
        header={'Remove module'}
        body={`Are you sure you want to remove this module from the event?`}
      />
    </div>
  );
};

export default Toolbar;
