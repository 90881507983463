import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';

import SvgRender from 'common/components/general/SvgRender';
import { selectSystemCurrency } from 'store/settings/selectors';

import claimExpensesIcon from 'common/assets/svg/common/claims_expenses.svg';

import NumberInput from 'common/components/form/inputs/NumberInput';
import { useSelector } from 'react-redux';
import { useFormState } from 'utils/hooks';
import { calculateExpencesSummary } from 'events/event/modules/module-components/crew-medical-case/form/helpers';
import TotalBar from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts/TotalBar';
import DynamicField from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts/DynamicField';
import PniStatus from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts/PniStatus';
import CompanyStatus from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts/CompanyStatus';

const ClaimAmounts = ({ selectField, formState, fields, view = false }) => {
  const settingsCurrency = useSelector(selectSystemCurrency);
  const currencyLabel = _get(settingsCurrency, 'label') || 'USD';

  const { subStates } = useFormState(formState);

  const expenses = subStates('expenses');

  const totalSum = calculateExpencesSummary(expenses);

  const paidTotalSum = calculateExpencesSummary(expenses, true);

  const expensesGroupedData = _groupBy(expenses, item =>
    item?.index % 2 === 0 ? 'even' : 'single'
  );

  const evenSum = calculateExpencesSummary(expensesGroupedData.even);

  const singleSum = calculateExpencesSummary(expensesGroupedData.single);

  const evenPaidSum = calculateExpencesSummary(expensesGroupedData.even, true);

  const singlePaidSum = calculateExpencesSummary(expensesGroupedData.single, true);

  return (
    <>
      <Row className="d-flex align-items-center h-100p mb-2 position-relative event-medical-crew-cases__content-bottom-header">
        <Col xs={4} className="d-flex">
          <SvgRender
            src={claimExpensesIcon}
            className="text-moody-blue"
            style={{ width: 16, height: 16 }}
          />
          <div className="text-moody-blue fs-12 ms-1">Expenses - {currencyLabel}</div>
        </Col>
        {!view ? <div className="text-turquoise fs-12 paid-even">Paid</div> : null}
        {!view ? <div className="text-turquoise fs-12 paid-odd">Paid</div> : null}
      </Row>

      <div className="d-flex justify-content-between h-100p">
        <div className="event-medical-crew-cases__content-bottom-side d-flex flex-column mb-1">
          <div>
            {expensesGroupedData.single?.map(expenseFormState => {
              return <DynamicField formState={expenseFormState} view={view} />;
            })}
          </div>
          <div className="mt-auto">
            <TotalBar
              totalAmount={singleSum}
              paidAmount={singlePaidSum}
              currencyLabel={currencyLabel}
              className={`total-bar fs-10 mt-auto align-self-end`}
              text="TOTAL"
              view={view}
            />
          </div>
        </div>

        <div className="event-medical-crew-cases__content-bottom-side d-flex flex-column mb-1">
          <div>
            {expensesGroupedData.even?.map(expenseFormState => {
              return <DynamicField formState={expenseFormState} view={view} />;
            })}
          </div>
          <div className="mt-auto">
            <TotalBar
              totalAmount={evenSum}
              paidAmount={evenPaidSum}
              currencyLabel={currencyLabel}
              className={`total-bar fs-10 mt-auto align-self-end`}
              text="TOTAL"
              view={view}
            />
          </div>
        </div>
      </div>

      {expenses ? (
        <TotalBar
          totalAmount={totalSum}
          paidAmount={paidTotalSum}
          currencyLabel={currencyLabel}
          className={`bg-primary fs-12`}
          text="GRAND TOTAL"
          view={view}
          isTotalSum={true}
        />
      ) : null}

      <Row className={`mt-2`}>
        <Col xs={'auto'}>
          {view && !fields?.paid_amount?.value ? null : (
            <NumberInput
              label="COVERED BY COMPANY"
              allowNegative={false}
              placeholder="Add value"
              fixedDecimalScale={2}
              onChange={e => selectField('paid_amount')(e.target.value)}
              invisible={view}
              disabled={view}
              {...fields?.paid_amount}
            />
          )}
        </Col>

        {view && !fields?.company_status?.value ? null : (
          <Col xs="auto" className="ps-0">
            <CompanyStatus
              value={fields.company_status.value}
              onChange={selectField('company_status')}
              view={view}
              canChangeStatus={fields?.paid_amount?.value}
            />
          </Col>
        )}

        <Col xs={'auto'} className={`${!view ? 'd-flex justify-content-center ps-0' : ''}`}>
          {view && !fields?.covered_by_pni?.value ? null : (
            <NumberInput
              label="COVERED BY P&I"
              allowNegative={false}
              fixedDecimalScale={2}
              onChange={e => selectField('covered_by_pni')(e.target.value)}
              placeholder="Add value"
              invisible={view}
              disabled={view}
              {...fields?.covered_by_pni}
            />
          )}
        </Col>

        {view && !fields?.pni_status?.value ? null : (
          <Col xs="auto" className="ps-0">
            <PniStatus
              value={fields.pni_status.value}
              onChange={selectField('pni_status')}
              view={view}
              canChangeStatus={fields?.covered_by_pni?.value}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

ClaimAmounts.propTypes = {
  formState: PropTypes.object,
  selectField: PropTypes.func,
  view: PropTypes.bool,
  fields: PropTypes.object
};

export default ClaimAmounts;
