import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import arrowRight from 'common/assets/svg/actions/right-arrow.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  selectMaintenanceDetaisDueOnRh,
  selectMaintenanceDetaisDueOnRhChangedAt,
  selectMaintenanceDetaisDueOnRhChangedBy,
  selectMaintenanceDetaisDueOnRhPrevious
} from 'common/components/pms/jobs/store/selectors';

const RunningHoursDueInputTooltip = ({ avoidRender, tooltipID }) => {
  const maintenanceDetaisDueOnRh = useSelector(selectMaintenanceDetaisDueOnRh);
  const maintenanceDetaisDueOnRhChangedAt = useSelector(selectMaintenanceDetaisDueOnRhChangedAt);
  const maintenanceDetaisDueOnRhChangedBy = useSelector(selectMaintenanceDetaisDueOnRhChangedBy);
  const maintenanceDetaisDueOnRhPrevious = useSelector(selectMaintenanceDetaisDueOnRhPrevious);
  if (avoidRender) return null;

  const hideTooltip =
    !maintenanceDetaisDueOnRhChangedAt ||
    !maintenanceDetaisDueOnRhChangedBy ||
    !maintenanceDetaisDueOnRhPrevious;

  return (
    <Tooltip
      target={tooltipID}
      innerClassName="min-width-fit"
      className={`${hideTooltip ? 'd-none' : ''}`}
      fade={false}
    >
      <div className="d-flex-column">
        <div className="d-flex align-items-center border-bottom-gray-200 flex-nowrap pb-1">
          {maintenanceDetaisDueOnRhPrevious} &nbsp;
          <SvgRender style={{ height: 14, width: 14 }} src={arrowRight} />
          &nbsp; {maintenanceDetaisDueOnRh}
        </div>

        <div className="fs-12 text-white flex-nowrap pt-1 w-100p text-truncate">
          Changed by{' '}
          <span className="fw-bold">{maintenanceDetaisDueOnRhChangedBy?.full_name} </span>
          at{' '}
          <span className="fw-bold">
            {moment.utc(maintenanceDetaisDueOnRhChangedAt).format('MMMM D, YYYY, HH:mm')}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

export default RunningHoursDueInputTooltip;
