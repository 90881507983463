import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExportIcon from 'common/components/general/ExportIcon';

import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';

const ExportIcons = ({ periodId }) => {
  const [isPdfDownloading, setIsPdfDownloading] = useState(false);
  const [isExcelDownloading, setIsExcelDownloading] = useState(false);

  const onPdfDownload = async () => {
    setIsPdfDownloading(true);

    await handleFileDownload(
      { url: `/mga/accounts/pdf`, filename: 'account-payrolls.zip' },
      download,
      false,
      {
        requestParams: { filters: [{ name: 'period_id', operation: '=', value: periodId }] },
        parsed: true
      }
    );

    setIsPdfDownloading(false);
  };

  const onExcelDownload = async () => {
    setIsExcelDownloading(true);

    await handleFileDownload(
      { url: `/mga/export`, filename: 'account-payrolls.zip' },
      download,
      false,
      {
        requestParams: { period_id: periodId },
        parsed: true
      }
    );

    setIsExcelDownloading(false);
  };

  return (
    <div className="d-flex align-items-center">
      <ExportIcon
        extension="pdf"
        disabled={isPdfDownloading}
        onClick={onPdfDownload}
        text="Export pdf for all seamen"
      />
      <ExportIcon
        className="ms-2"
        extension="excel"
        disabled={isExcelDownloading}
        onClick={onExcelDownload}
        text="Export excel"
      />
    </div>
  );
};

export default ExportIcons;

ExportIcons.propTypes = {
  periodId: PropTypes.string.isRequired
};
