import NumberField from 'captain-reports/templates/inputs/NumberField';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr } from 'common/utils/numbers';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const LubricantsSoLegTotalConsumption = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  tooltip
}) => {
  const value = useValue({
    key: field.key
  });

  return (
    <div>
      <div className="d-none">
        <NumberField
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </div>

      <div className={`fw-bold fs-12 lh-1 cmb-6 cmt-4`}>
        <ValidationWrapper
          field={field}
          value={value}
          errorClassName="align-items-center"
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        >
          {numberToStr(value)}
        </ValidationWrapper>
      </div>
    </div>
  );
};

export default LubricantsSoLegTotalConsumption;
