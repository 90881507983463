import TYPES from './types';
import JOB_TYPES from 'common/components/jobs/_base/store/types';
import _isEqual from 'lodash/isEqual';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from 'routing/routes/_paths';

export const INITIAL_STATE = {
  selectedVessels: null,
  isLoading: false,
  vesselSystemAssignments: [], // [vessel_system_assingment_ID_1, vessel_system_assingment_ID_2]
  vesselSystemAssignment: {}, // { vessel_system_assingment_ID_1: {}, vessel_system_assingment_ID_2: {} }
  vesselSystemAssignmentMaintenanceJobs: {}, // { vessel_system_assingment_ID_1: [], vessel_system_assingment_ID_2: [] }
  maintenanceJob: {}, // { job_ID_1: {}, job_ID_2: {} }
  vesselSystem: {}, // { vessel_system_assingment_ID_1: {}, vessel_system_assingment_ID_2: {} }
  expanded: {}, // { vessel_system_assingment_ID_1: true|false }
  activeJobID: null,
  jobDrawerIsOpen: false,
  allExpanded: false,
  paging: {
    current_page: 1,
    last_page: 1,
    per_page: 50,
    total: 0
  },
  selectedVesselFilter: null,
  selectedSystemFilter: null
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.SET_SELECTED_VESSELS:
      const newState = !_isEqual(payload, state.selectedVessels)
        ? {
            vesselSystemAssignments: [],
            vesselSystemAssignment: {},
            vesselSystemAssignmentMaintenanceJobs: {},
            maintenanceJob: {},
            vesselSystem: {}
          }
        : {};

      return {
        ...state,
        ...newState,
        activeJobID: null,
        selectedVessels: payload
      };

    case TYPES.GET_VESSEL_SYSTEM_MAINTENANCE_JOBS.START:
      return { ...state, isLoading: true };

    case TYPES.GET_VESSEL_SYSTEM_MAINTENANCE_JOBS.SUCCESS:
      const initialTableState = {
        vesselSystemAssignments: [],
        vesselSystemAssignment: {},
        vesselSystemAssignmentMaintenanceJobs: {},
        maintenanceJob: {},
        vesselSystem: {}
      };

      const tableState = payload.reduce(
        (acc, { maintenance_jobs, description, extra_description, id, ...assignment }) => {
          acc.vesselSystemAssignments.push(id);
          acc.vesselSystemAssignment[id] = { ...assignment, id };

          acc.vesselSystemAssignmentMaintenanceJobs[id] = [];

          maintenance_jobs.forEach(job => {
            acc.vesselSystemAssignmentMaintenanceJobs[id].push(job.id);

            acc.maintenanceJob[job.id] = job;
            acc.maintenanceJob[job.id].is_critical = assignment?.is_critical;
            acc.maintenanceJob[job.id].is_environmental_critical =
              assignment?.is_environmental_critical;
          });

          acc.vesselSystem[id] = {
            description,
            extra_description,
            group: { code: assignment.system_group_code, name: assignment.system_group_name },
            parent_id: assignment.parent_id
          };

          return acc;
        },
        initialTableState
      );

      return { ...state, ...tableState, isLoading: false };

    case TYPES.GET_VESSEL_SYSTEM_MAINTENANCE_JOBS.ERROR:
      return { ...state, isLoading: false };

    case TYPES.SET_EXPANDED_ASSIGNMENT:
      return { ...state, expanded: { ...state.expanded, [payload.id]: payload.isOpen } };

    case TYPES.TOGGLE_JOB_DRAWER:
      return { ...state, activeJobID: payload.activeJobID, jobDrawerIsOpen: payload.isOpen };

    case JOB_TYPES.UPDATE_JOB.SUCCESS:
      return {
        ...state,
        maintenanceJob: {
          ...state.maintenanceJob,
          [payload.id]: { ...state.maintenanceJob[payload.id], ...payload }
        }
      };

    case TYPES.SET_ALL_EXPANDED:
      return {
        ...state,
        allExpanded: payload
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.includes(`${paths.pms_jobs}`)) {
        return {
          ...state,
          jobDrawerIsOpen: false
        };
      }

      return state;

    case TYPES.SET_SELECTED_SYSTEM_FILTER:
      return {
        ...state,
        selectedSystemFilter: payload
      };

    case TYPES.SET_SELECTED_VESSEL_FILTER:
      return {
        ...state,
        selectedVesselFilter: payload
      };

    default:
      return state;
  }
};

export default reducer;
