import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import _get from 'lodash/get';
import OverFlownText from 'common/components/general/TextOverflow';

const Title = props => {
  const { properties } = props;

  const oldName = _get(properties, 'old.title', 'None');
  const newName = _get(properties, 'attributes.title', 'None');
  const id = _get(props, 'id', 'None');

  return (
    <div className="d-flex align-items-center">
      <OverFlownText
        text={oldName ? oldName : 'None'}
        width={150}
        id={`old-${id}-activity-title`}
        className="fs-12 text-violet fw-light"
      />
      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <OverFlownText
        text={newName ? newName : 'None'}
        width={150}
        id={`new-${id}-activity-title`}
        className="fs-12 text-violet fw-light"
      />
    </div>
  );
};

export default Title;
