import { useEffect, useState } from 'react';

const loadSvg = async (type: string) => {
  try {
    // const svgModule = await import(`@/ts-common/assets/svg/actions/${type}.svg`);
    const svgModule = await import(`../../assets/svg/actions/${type}.svg`);

    return svgModule.default;
  } catch (error) {
    console.error(`Error loading SVG: ${error}`);
    return null;
  }
};

const useSvgIcon = (action?: string | null, fallback?: string | null): string | null => {
  const [svgUrl, setSvgUrl] = useState<string | null>(null);

  useEffect(() => {
    async function gettSvgIcon() {
      const importedIcon = fallback
        ? fallback
        : action
        ? (await loadSvg(action)) || fallback
        : fallback;

      setSvgUrl(importedIcon);
    }
    gettSvgIcon();
  }, [action, fallback]);

  return svgUrl;
};

export default useSvgIcon;
