import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import FormsDrawer from 'common/components/forms/drawer';
import FormSubmissionsSelector from 'common/components/selectors/FormSubmissionsSelector';
import ShadowBox from 'common/components/general/ShadowBox';
import { Option, SingleValue } from 'common/components/selectors/_formSubmissionsSelectComponent';
import { VesselType } from 'common/entities/vessels/VesselTypes';

const FormDrawer = ({
  drawer,
  formPreviewState,
  type,
  setSelectedForm,
  selectedForm,
  addLinkedForm,
  formSubmissionsSelectorRestParams = {},
  ...rest
}) => {
  return (
    <FormsDrawer
      css={selectedForm?.id ? null : style}
      drawerType={type}
      drawer={drawer}
      initialFormSubmissionID={selectedForm?.id}
      initialForm={formPreviewState}
      customSelectorComponent={
        type === 'link_form' ? (
          <ShadowBox flat className="px-3 pt-2 pb-2 mb-1">
            <Row noGutters>
              <Col xs={6}>
                <FormSubmissionsSelector
                  label="Form"
                  onChange={form_submission => setSelectedForm(form_submission)}
                  value={selectedForm}
                  isClearable={true}
                  components={{ Option, SingleValue }}
                  {...formSubmissionsSelectorRestParams}
                />
              </Col>
            </Row>
          </ShadowBox>
        ) : null
      }
      onSubmitCallback={res => {
        if (type === 'link_form' || type === 'add') {
          addLinkedForm(res);
        }
        drawer.close();
      }}
      {...rest}
    />
  );
};

const style = css`
  .form-manager--header {
    .shadow-box-wrap {
      :nth-child(2) {
        display: none;
      }
    }
  }
`;

FormDrawer.propTypes = {
  drawer: DrawerState,
  formPreviewState: PropTypes.object,
  setSelectedForm: PropTypes.func,
  addLinkedJob: PropTypes.func,
  addLinkedForm: PropTypes.func,
  formSubmissionsSelectorRestParams: PropTypes.object,
  vessel: VesselType
};

export default FormDrawer;
