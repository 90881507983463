import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useActions } from 'utils/hooks';
import TimeInput from 'captain-reports/templates/inputs/TimeInput';
import useValue from 'captain-reports/utils/useValue';
import {
  selectPreviousCaptainReportTimestamp,
  selectCaptainReportLastReportStoppageDates
} from 'captain-reports/store/selectors';
import { strToNumber } from 'common/utils/numbers';

import * as reportActions from 'captain-reports/store/actions';

import numeral from 'numeral';

const SteamingTimeField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const previousTimestamp = useSelector(selectPreviousCaptainReportTimestamp);
  const stoppageDates = useSelector(selectCaptainReportLastReportStoppageDates);
  const [setValue] = useActions([reportActions.setValue]);

  const timestamp = useValue({
    key: 'timestamp',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const steaming_time = useValue({
    key: 'steaming_time',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    /* 
      This value should be auto filled (Noon & Arrival report)
    - When the exact previous report is a Noon or Departure, then do the following:
      Steaming Time = Current Report Datetime UTC (timestamp) - Previous Report Datetime UTC (timestamp)
    - When the exact previous report is a Stoppage report, then do the following:
      Steaming Time = Current Report Datetime UTC - Previous Noon/Departure Report Datetime UTC - Stoppage Duration (this is a bit more complex)
      In this case auto fill the value ONLY if it is greater than zero (>0)
    */

    if (previousTimestamp && timestamp && !steaming_time) {
      const timeDiff = moment(timestamp).diff(previousTimestamp, 'minutes', true);

      if (stoppageDates?.length) {
        const stoppageDuration = stoppageDates.reduce((acc, cur) => {
          const sum = numeral(acc);
          return sum.add(strToNumber(cur.stoppage_duration) || 0);
        }, 0)._value;

        if (timeDiff - stoppageDuration > 0) {
          setValue({
            key: 'steaming_time',
            value: timeDiff - stoppageDuration,
            sectionLabel,
            sectionActiveTab,
            subGroup,
            subGroupIndex
          });
        }
      } else {
        setValue({
          key: 'steaming_time',
          value: timeDiff,
          sectionLabel,
          sectionActiveTab,
          subGroup,
          subGroupIndex
        });
      }
    }
  }, [previousTimestamp, timestamp]);

  return (
    <TimeInput
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default SteamingTimeField;
