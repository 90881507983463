import _flatten from 'lodash/flatten';
import _orderBy from 'lodash/orderBy';

export const getTicketStatus = (statuses, label) => statuses?.find(s => s?.label === label)?.id;

export const getTicketRotations = (type, data) => {
  const allRotations = _flatten(data.map(item => item.rotations)); // Flatten all rotations
  return _orderBy(
    allRotations.filter(rotation => rotation[type]),
    [
      rotation => rotation?.[`${type}_trip_id`],
      rotation => rotation?.[type]?.details?.rank?.sort_index,
      rotation => rotation?.[type]?.full_name
    ],
    ['asc', 'asc']
  );
};
