import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import ModuleWrapper from '../components/ModuleWrapper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEventId,
  selectEventSavedItinerayPort,
  selectEventSavedVessel,
  selectIsTemplate
} from 'events/store/events/selectors';
import { getAgents } from 'events/store/event-modules/agents/actions';
import CircledButton from 'common/components/buttons/CircledButton';
import Remarks from './Remarks';
import { Button } from 'reactstrap';
import voyages from 'common/assets/svg/common/voyages.svg';
import SvgRender from 'common/components/general/SvgRender';
import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';
import List from './List';
import Form from './Form';
import { selectAgentsIsLoading } from 'events/store/event-modules/agents/selectors';
import Loader from './Loader';

const Agents = () => {
  const [isAdding, setIsAdding] = useState(false);

  const dispatch = useDispatch();
  const { history } = useRouter();

  const eventId = useSelector(selectEventId);
  const eventVessel = useSelector(selectEventSavedVessel);
  const eventItinerayPort = useSelector(selectEventSavedItinerayPort);
  const isLoading = useSelector(selectAgentsIsLoading);
  const isTemplate = useSelector(selectIsTemplate);

  const showAgentData = !!eventVessel?.id && !!eventItinerayPort?.id && !isTemplate;

  useEffect(() => {
    if (!showAgentData || !eventId) return;

    dispatch(getAgents({ id: eventId }));
  }, [showAgentData, dispatch, eventId, eventVessel?.id, eventItinerayPort?.id]);

  return (
    <ModuleWrapper
      className="event-notes"
      type="agents"
      action={
        showAgentData && (
          <Button
            type="button"
            color="primary"
            className="height-24 d-flex align-items-center justify-content-center cpx-12"
            onClick={() => history.push(`${paths.voyages}/${eventVessel.id}/itinerary`)}
          >
            <SvgRender
              className="text-white me-1"
              src={voyages}
              style={{ width: 16, height: 16 }}
            />
            <span className="fs-12 fw-medium">Go to Itinerary</span>
          </Button>
        )
      }
    >
      {!!eventId &&
        (isLoading ? (
          <Loader />
        ) : !showAgentData ? (
          <RoundedContainer className="d-flex align-items-center justify-content-center py-4">
            <span className="fs-12 text-violet fw-medium">
              Please select vessel & vessel itinerary port to see the agents
            </span>
          </RoundedContainer>
        ) : (
          <div>
            <List />

            {isAdding && (
              <Form
                setIsAdding={setIsAdding}
                onCancel={() => setIsAdding(false)}
                onSubmitCallback={() => setIsAdding(false)}
              />
            )}

            <CircledButton
              type="add"
              className="text-primary fw-bold fs-12 mt-2"
              disabled={isAdding}
              label="Add agent"
              svgStyle={{ width: 8, height: 8 }}
              onClick={() => setIsAdding(true)}
              style={{ width: 20, height: 20 }}
            />

            <Remarks />
          </div>
        ))}
    </ModuleWrapper>
  );
};

export default Agents;

export const RoundedContainer = styled.div`
  background-color: #fff;
  border-radius: 11px;
  box-shadow: -2px 4px 14px 0 rgba(0, 0, 0, 0.05);
`;
