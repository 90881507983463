import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Table, { useTable } from 'common/components/table';
import SvgRender from 'common/components/general/SvgRender';
import ReportStatus from 'captain-reports/components/ReportStatus';
import ValidationWarning from 'captain-reports/components/ValidationWarning';
import AlarmContainer from 'captain-reports/components/alarms/Container';
import Date from 'captain-reports/components/Date';
import SaveTableLayout from 'components/layouts/page/table/save-table';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import paths from 'routing/routes/_paths';

import eyeSvg from 'common/assets/svg/common/eye.svg';
import ballast from 'captain-reports/assets/svg/ballast.svg';
import laden from 'captain-reports/assets/svg/laden.svg';

import TopFilters from './TopFilters';
import MorePageActions from './MorePageActions';

import { getTableList } from 'store/tables/lists/actions';
import { fetchListOptions } from 'store/lists/actions';
import PageLink from 'common/components/general/page-link';
import config from '@/common/components/shared-table-configs/index.ts';
import { useAppDispatch } from '@/store/hooks';

const LABEL = 'captain_reports_running_hours';

const CaptainReportsTable = ({ actions = {}, components = {} }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const columns = config[LABEL].getColumns();
  const dispatch = useAppDispatch();

  const { getRunningHoursReportsAlarms } = actions;

  const fetchData = async params => {
    const { filters } = params;
    const updatedFilters = [
      ...filters,
      {
        name: 'type',
        operation: 'oneOf',
        value: ['system_running_hours']
      }
    ];
    await dispatch(getTableList({ ...params, filters: updatedFilters }));
  };

  const table = useTable({
    label: LABEL,
    requestTableListFrom: fetchData,
    columns,
    top: {
      filters: [
        {
          name: 'vessel',
          operation: 'oneOf',
          value: null,
          initialValue: null
        }
      ]
    }
  });

  useEffect(() => {
    if (getRunningHoursReportsAlarms) getRunningHoursReportsAlarms();

    dispatch(fetchListOptions(`report-groups`));
  }, [dispatch, getRunningHoursReportsAlarms]);

  return (
    <Row>
      <Col>
        <SaveTableLayout pageTitle={'Running Hours'} label={LABEL} />
      </Col>
      <Col xs="auto" className="d-flex align-items-start">
        <MorePageActions topFilters={table.topFilters} component={components.CreateReportAction} />
      </Col>
      <Col xs={12}>
        <Table
          hideTopPagination={!isOnBoard}
          topCustomComponent={!isOnBoard ? <AlarmContainer className="cmb-4 cp-20" /> : null}
          topFiltersComponent={
            !isOnBoard ? (
              <TopFilters
                table={table}
                topFilters={table.topFilters}
                setTopFilters={table.setTopFilters}
              />
            ) : null
          }
          loader
          rows={{
            vessel_name: ({ vessel, id, has_validation_errors }) => (
              <div className="d-flex align-items-center">
                <PageLink className="flex-1 pe-1" to={`${paths.pms_running_hours}/${id}`}>
                  {vessel.name}
                </PageLink>

                {has_validation_errors ? (
                  <ValidationWarning className="me-5" target={`validation-${id}`} />
                ) : null}
              </div>
            ),
            status: ({ status, id }) => (
              <ReportStatus target={`a${id}-${status}`} status={status} className="ms-2" />
            ),
            loading_condition: ({ loading_condition }) => (
              <SvgRender
                src={loading_condition === 'laden' ? laden : ballast}
                style={{ width: 18, height: 18 }}
              />
            ),
            timestamp: ({ timestamp }) => <Date value={timestamp} withTime />,
            reviewed_by_tech: ({ reviewed_by_tech }) => (
              <div>{reviewed_by_tech?.full_name || ''}</div>
            ),
            approved_by: ({ approved_by }) => <div>{approved_by?.full_name || ''}</div>,
            local_timestamp: ({ local_timestamp }) => <Date value={local_timestamp} withTime />,
            actions: data => ({
              options: [
                {
                  label: 'View',
                  icon: eyeSvg,
                  onClick: () => window.open(`${paths.pms_running_hours}/${data.id}`)
                }
              ]
            })
          }}
          {...table}
        />
      </Col>
    </Row>
  );
};

CaptainReportsTable.propTypes = {
  actions: PropTypes.shape({ getRunningHoursReportsAlarms: PropTypes.func }),
  components: PropTypes.shape({ BulkActionsButton: PropTypes.func })
};

export default CaptainReportsTable;
