export const copyTextToClipboard = async (text: string) => {
  try {
    if (navigator.clipboard) {
      if (typeof navigator?.clipboard?.writeText === 'function') {
        await navigator.clipboard.writeText(text);
        return text;
      } else {
        console.error('Reading from clipboard is not supported in this browser.');
      }
      return text;
    } else {
      console.error(
        'Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
      );
    }
  } catch (err) {
    console.error('Failed to copy: ', err);
    throw err;
  }
};

export const getClipboardContents = async () => {
  try {
    if (navigator.clipboard) {
      const text = await navigator.clipboard.readText();

      return text;
    } else {
      console.error(
        'Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
      );
    }
  } catch (err) {
    console.error('Failed to read clipboard contents: ', err);
    throw err;
  }
};

export const removeHtmlTags = (text?: string) => {
  if (!text) return '';

  return text.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ' ');
};
