import {
  stringField,
  optionField,
  dateTimeField,
  numberField,
  hiddenField
} from 'common/utils/form/fieldTypes';
import moment from 'moment';

const config = {
  transportation_type: stringField({ initialValue: 'air' }), // NEW
  itinerary_type: stringField({ initialValue: 'to_go' }), // NEW
  id: hiddenField(),
  agent: optionField(),
  initial_price: numberField({ required: true }),
  currency: optionField({ required: true }),

  transportation_medium: stringField(), // NEW
  documents: optionField({ initialValue: [] }), // NEW
  discount: numberField(),
  tax: numberField(),
  payable_price: numberField(),

  is_bulk: hiddenField(),
  participants_count: hiddenField(),

  routes: {
    from: optionField({ required: true }),
    to: optionField({ required: true }),

    airline: optionField(),
    itinerary_no: stringField(),
    ticket_no: stringField(),

    arrival: dateTimeField({
      required: true,
      transformOnSubmit: d => moment(d).format('YYYY-MM-DD HH:mm'),
      transformOnLoad: d => d
    }),
    departure: dateTimeField({
      required: true,
      transformOnSubmit: d => moment(d).format('YYYY-MM-DD HH:mm'),
      transformOnLoad: d => d
    })
  }
};

export default config;
