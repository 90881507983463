import { FC } from 'react';
import {
  getCharacterColor,
  getNumber,
  getUpperLower,
  isValidPassword,
  specialChars
} from '@/ts-common/components/general/password/helpers';
import Information from '@/ts-common/components/general/Information';

const getColor = (password: string) => {
  if (!password) return '#354069';

  const isOk = isValidPassword(password);

  if (isOk) {
    return '#01D9A4';
  }

  return '#FE5F55';
};

type BulletProps = {
  color: string;
};

const Bullet = ({ color }: BulletProps) => {
  return <div className={`circle bg-${color}`} />;
};

type PopperProps = {
  password: string;
};

const Popper = ({ password }: PopperProps) => {
  return (
    <div className="fs-12 d-flex flex-column align-items-start">
      <div className="text-violet fw-medium mb-2 mt-1">Password must contain</div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={getCharacterColor(password)} />
        <span className="text-primary fw-medium ms-1">at least 12 characters</span>
      </div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={getUpperLower(password)} />
        <span className="ms-1 text-primary fw-medium">
          at least 1 uppercase and 1 lowercase letter
        </span>
      </div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={getNumber(password)} />
        <span className="ms-1 text-primary fw-medium">at least 1 numeric digit</span>
      </div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={specialChars(password)} />
        <span className="text-primary fw-medium ms-1">at least 1 special characters</span>
      </div>
    </div>
  );
};

type PasswordValidationProps = {
  password?: string;
  className?: string;
};

const PasswordValidation: FC<PasswordValidationProps> = ({ password, className = '' }) => {
  if (!password) return null;

  return (
    <Information
      message={<Popper password={password} />}
      className={className}
      svgStyle={{ height: 14, width: 14, color: getColor(password) }}
      target={`cell--info-password`}
      placement="right"
      tooltipClassname="password-validation-tooltip"
      hideArrow={true}
    />
  );
};

export default PasswordValidation;
