import getMainInfo from 'crew/profile/main/personal/sections/_main-info';
import MainInfoPreview from 'crew/profile/main/personal/layout/MainInfo';

import maritalStatus from 'common/assets/svg/common/marital-status.svg';
import _maritalStatusConfig from 'crew/profile/main/personal/sections/_marital-status';
import MaritalStatusPreview from 'crew/profile/main/personal/layout/MaritalStatus';

import language from 'common/assets/svg/common/language.svg';
import _languageInfoConfig from 'crew/profile/main/personal/sections/_languages-info';
import Languages from './Languages';

import location from 'common/assets/svg/common/location.svg';
import { mainContactInfoConfig } from 'crew/profile/main/personal/sections/_contact-info';
import ContactInfoPreview from 'crew/profile/main/personal/layout/ContactInfo';

import Nok from 'crew/profile/main/personal/layout/Nok';

import _digitalCommunicationConfig from 'crew/profile/main/personal/sections/_digital-communication';
import DigitalCommunication from 'crew/profile/main/personal/layout/DigitalCommunication';

import _rightSideConfig from 'crew/profile/main/personal/sections/_rightSideConfig';
import RightSidePreview from 'crew/profile/main/personal/layout/RightSide';
import permissions from 'common/utils/permissions/constants';

export const rightSideInfoConfig = {
  component: RightSidePreview,
  componentProps: {
    config: _rightSideConfig
  }
};

const getPersonalConfig = account => {
  const _mainInfoConfig = getMainInfo(account);

  return [
    {
      label: 'MAIN INFO',
      path: 'main-info',
      component: MainInfoPreview,
      componentProps: {
        config: _mainInfoConfig
      },
      permissions: [
        permissions.onboard.crew.seaman_profile.personal.all_users.main_info,
        permissions.onboard.crew.seaman_profile.personal.own_profile.main_info
      ]
    },
    {
      label: 'MARITAL STATUS',
      path: 'marital-status',
      component: MaritalStatusPreview,
      showEmpty: data =>
        !data?.marital_status_id &&
        !data?.spouse_name &&
        !data?.spouse_birthday &&
        !data?.children.length,
      emptyProps: {
        emptyText: 'This person has no marital status',
        emptyButtonText: 'Add marital status',
        icon: maritalStatus
      },
      componentProps: {
        config: _maritalStatusConfig
      },
      permissions: [
        permissions.onboard.crew.seaman_profile.personal.all_users.marital_status,
        permissions.onboard.crew.seaman_profile.personal.own_profile.marital_status
      ]
    },
    {
      label: 'LANGUAGES',
      path: 'languages',
      component: Languages,
      showEmpty: data => !data?.languages.length,
      emptyProps: {
        emptyText: 'This person has no language data yet',
        emptyButtonText: 'Add language',
        icon: language
      },
      componentProps: {
        config: _languageInfoConfig
      },
      permissions: [
        permissions.onboard.crew.seaman_profile.personal.all_users.languages,
        permissions.onboard.crew.seaman_profile.personal.own_profile.languages
      ]
    },
    {
      label: 'CONTACT INFO',
      path: 'contact-info',
      component: ContactInfoPreview,
      showEmpty: data =>
        !data?.phones?.length &&
        !data.email &&
        !data?.has_current_address &&
        !data?.has_old_address,
      emptyProps: {
        emptyText: 'This person has no contact info yet.',
        emptyButtonText: 'Add contact info',
        icon: location
      },
      componentProps: {
        config: mainContactInfoConfig
      },
      permissions: [
        permissions.onboard.crew.seaman_profile.personal.all_users.contact_info,
        permissions.onboard.crew.seaman_profile.personal.own_profile.contact_info
      ]
    },
    {
      label: 'NOK',
      path: 'nok',
      component: Nok,
      showEmpty: data => !data?.has_nok_address,
      emptyProps: {
        emptyText: 'This person has no NOK info yet.',
        emptyButtonText: 'Add NOK',
        icon: maritalStatus
      },
      permissions: [
        permissions.onboard.crew.seaman_profile.personal.all_users.nok,
        permissions.onboard.crew.seaman_profile.personal.own_profile.nok
      ]
    },
    {
      label: 'SOCIALS',
      path: 'digital-communication',
      component: DigitalCommunication,
      componentProps: {
        config: _digitalCommunicationConfig
      },
      permissions: [
        permissions.onboard.crew.seaman_profile.personal.all_users.socials,
        permissions.onboard.crew.seaman_profile.personal.own_profile.socials
      ]
    }
  ];
};

export default getPersonalConfig;
