import moment from 'moment';

export const MODES = {
  single: 'single',
  range: 'range'
};

export const TIME_FORMAT = {
  time: 'YYYY-MM-DD HH:mm:ss',
  dateOnly: 'YYYY-MM-DD'
};

export const onTimeValidation = ({ started_at, ended_at, mode }) => {
  switch (mode) {
    case MODES.single:
      if (!started_at) {
        return 'Date is required';
      }
      return '';
    case MODES.range:
      if (!started_at || !ended_at) {
        return 'Both dates are required.';
      }

      return '';
    default:
      return '';
  }
};

export const onDateChange = ({ started_at, ended_at, date_type, is_datetime }) => {
  if (date_type === 'range') {
    const formattedStartedAt = started_at
      ? moment(started_at).format(`YYYY-MM-DD${is_datetime ? ' HH:mm' : ''}`)
      : undefined;

    const formattedEndeddAt = ended_at
      ? moment(ended_at).format(`YYYY-MM-DD${is_datetime ? ' HH:mm' : ''}`)
      : undefined;

    return { started_at: formattedStartedAt, ended_at: formattedEndeddAt };
  } else {
    const formattedStartedAt = started_at
      ? moment(started_at).format(`YYYY-MM-DD${is_datetime ? ' HH:mm' : ''}`)
      : undefined;
    return { started_at: formattedStartedAt };
  }
};
