import { useState, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import VesselSystemSelector from 'common/components/selectors/VesselSystemSelector';
import ShadowBox from 'common/components/general/ShadowBox';
import { Row, Col } from 'reactstrap';
import { components } from 'react-select';
import { getInventorySparePartGroup } from 'common/components/inventory/store/helpers';
import emptyRibbon from 'common/assets/svg/common/ribbon-line.svg';
import burger from 'common/assets/svg/common/chapter.svg';
import _isArray from 'lodash/isArray';
import { createInitialState } from 'common/utils/hooks/useForm/helpers';
import config from './config';
import { SparePartOption } from '@/common/components/selectors/_helpers';
import Select from '@/ts-common/components/form/inputs/select';

const SelectSpareParts = ({ onChange, onMultiChange, formState, isDrawerOpen }) => {
  const [vesselSystem, setVesselSystem] = useState(null);
  const [vesselSubSystem, setVesselSubSystem] = useState(null);

  const getSelectedItems = useCallback(
    (formState, maxIndex = 20) =>
      formState.filter((_, index) => index <= maxIndex).map(({ state }) => state?.id?.value),
    []
  );

  const onSelectSpareParts = selected => {
    if (_isArray(selected)) {
      const existingItems = getSelectedItems(formState, formState?.length);

      const newFormSubstate = selected
        .filter(option => !existingItems.includes(option.id))
        .map((option, index) => ({
          index: formState?.length + index,
          state: createInitialState(config.spare_parts, {
            part_no: option?.part_no,
            title: option?.description,
            group: getInventorySparePartGroup(option),
            entity_type: option?.entity_type,
            id: option?.id
          }),
          config: config.spare_parts
        }));

      onMultiChange(newFormSubstate);
    } else {
      onChange({
        part_no: selected?.part_no,
        title: selected?.description,
        group: getInventorySparePartGroup(selected),
        entity_type: selected?.entity_type,
        id: selected?.id
      });
    }
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setVesselSystem(null);
      setVesselSubSystem(null);
    }
  }, [isDrawerOpen]);

  return (
    <Row className="mb-2" noGutters>
      <Col xs={6} className="cpe-4">
        <ShadowBox className="p-2" color="light-1" flat>
          <div className="d-flex fs-10 text-primary fw-bold cmb-4">
            FILTER SPARE PARTS
            <span className="text-violet">
              &nbsp;-&nbsp;in order to add spare parts to the list
            </span>
          </div>
          <VesselSystemSelector
            onChange={({ value }) => {
              setVesselSystem(value);
              setVesselSubSystem(null);
            }}
            value={vesselSystem}
            autoFocus={false}
            icon={burger}
            size="sm"
            className="mb-1"
            isClearable
          />
          <VesselSystemSelector
            className="mb-0"
            onChange={({ value }) => setVesselSubSystem(value)}
            value={vesselSubSystem}
            autoFocus={false}
            disabled={!vesselSystem}
            placeholder="Select sub-system"
            listParams={{ parent_id: vesselSystem?.id }}
            defaultOptionsTriggerChange={vesselSystem?.id}
            icon={emptyRibbon}
            size="sm"
            isClearable
          />
        </ShadowBox>
      </Col>
      <Col xs={6} className="cps-4">
        <ShadowBox
          className="p-2 h-100p w-100p d-flex flex-column justify-content-center"
          color="light-1"
          flat
        >
          <div
            className={`d-flex fs-10 text-primary fw-bold cmb-4 ${
              !vesselSystem && !vesselSubSystem ? 'opacity-5' : ''
            }`}
          >
            SELECT SPARE PART TO ADD TO THE LIST
          </div>
          <Select
            className="mb-0"
            onChange={onSelectSpareParts}
            placeholder="Select spare part(s)"
            filterOption={option =>
              !formState.find(({ state }) => state?.id?.value === option.value)
            }
            memoizedRequestParams={{
              path: 'lists',
              params: {
                list: 'spare-parts',
                exclude_ids: getSelectedItems(formState),
                vessel_system_id: vesselSubSystem ? vesselSubSystem.id : vesselSystem?.id,
                search_subsystems: vesselSystem?.id && !vesselSubSystem?.id ? true : undefined,
                includes: ['vessel_systems']
              }
            }}
            getOptionLabel={option => `${option.part_no}. ${option.description}`}
            components={{ MenuList, Option: SparePartOption }}
            disabled={!vesselSystem && !vesselSubSystem}
          />
        </ShadowBox>
      </Col>
    </Row>
  );
};

SelectSpareParts.propTypes = {
  onChange: PropTypes.func,
  onMultiChange: PropTypes.func,
  formState: PropTypes.array.isRequired,
  isDrawerOpen: PropTypes.bool
};

const MenuList = ({ children, ...props }) => {
  return (
    <Fragment>
      <components.MenuList {...props}>{children}</components.MenuList>
      {props.options?.length > 0 ? (
        <div
          className="cpx-12 py-1 fs-12 fw-bold cursor-pointer border-top text-primary"
          onClick={() => props.selectProps.onChange(props.options)}
        >
          All Spare Parts
        </div>
      ) : null}
    </Fragment>
  );
};

export default SelectSpareParts;
