import React from 'react';
import Sidebar from './Sidebar';
import PropTypes from 'prop-types';
import PageLoader from 'common/components/general/PageLoader';

const SidebarLayout = ({
  className = '',
  sidebarComponent,
  sidebarClassName = '',
  mainComponent,
  loading
}) => {
  return (
    <div className={className}>
      <PageLoader isLoading={loading} />
      <Sidebar sidebarClassName={sidebarClassName} sidebarComponent={sidebarComponent} />
      <div className="side-bar-layout__main">{mainComponent}</div>
    </div>
  );
};

SidebarLayout.propTypes = {
  mainComponent: PropTypes.element,
  sidebarComponent: PropTypes.element,
  sidebarClassName: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool
};

export default SidebarLayout;
