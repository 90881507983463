import { stringField, utcDateTimeField } from 'common/utils/form/fieldTypes';

const config = {
  notes: stringField(),
  sign_on_date: utcDateTimeField(),
  sign_off_date: utcDateTimeField(),
  person_rank: stringField(),
  sign_on_port: stringField(),
  sign_off_port: stringField()
};

export default config;
