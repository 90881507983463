import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPosts = createAsyncThunk('GET_POSTS', async ({ params }, { rejectWithValue }) => {
  try {
    const res = await get(`/posts`, params);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getPostsWithImage = createAsyncThunk(
  'GET_POSTS_WITH_IMAGE',
  async ({ loadMore, currentPage, categoryId }, { rejectWithValue, getState }) => {
    try {
      const params = {
        paging: {
          per_page: 4,
          current_page: loadMore ? currentPage + 1 : 1
        },
        filters: [
          { name: 'category_id', operation: 'oneOf', value: [categoryId] },
          { name: 'has_image', operation: '=', value: true }
        ]
      };

      const res = await get(`/posts`, params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
