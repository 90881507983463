import React, { useState, useEffect } from 'react';

import SvgRender from 'common/components/general/SvgRender';
import { Button } from 'reactstrap';

import {
  selectActiveEvaluationId,
  selectEvaluationEvaluateeId,
  selectIsEvaluationSubmitted,
  selectIsEvaluationCreatedFromVessel,
  selectHasEvaluateeConsented,
  selectIsEvaluationSentToSeamanForConsent
} from 'crew/store/evaluations/profile/selectors';
import * as evaluationActions from 'crew/store/evaluations/profile/actions';
import * as activeEvaluationActions from 'crew/store/evaluations/profile/actions';
import { selectAccountId } from 'store/account/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import useRouter from 'use-react-router';

import checkIcon from 'common/assets/svg/common/check-circle.svg';
import x from 'common/assets/svg/common/x-circle.svg';
import company from 'common/assets/svg/common/company.svg';

import ConsentModal from './ConsentModal';

const EvaluationTopActions = () => {
  const userId = useSelector(selectAccountId);
  const evaluationId = useSelector(selectActiveEvaluationId);
  const evaluateeId = useSelector(selectEvaluationEvaluateeId);
  const isEvaluationSubmitted = useSelector(selectIsEvaluationSubmitted);
  const isEvaluationCreatedOnVessel = useSelector(selectIsEvaluationCreatedFromVessel);
  const hasEvaluateeConsented = useSelector(selectHasEvaluateeConsented);
  const isSentForConsent = useSelector(selectIsEvaluationSentToSeamanForConsent);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [consent, setConsent] = useState(null);

  const { match } = useRouter();

  const [consentEvaluation, getCrewEvaluation, clearActiveCrewEvaluation] = useActions([
    evaluationActions.consentEvaluation,
    evaluationActions.getCrewEvaluation,
    activeEvaluationActions.clearActiveCrewEvaluation
  ]);

  useEffect(() => {
    if (!match?.params?.evaluationID) {
      clearActiveCrewEvaluation();
    }
  }, [match?.params?.evaluationID]);

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const handleConsent = async () => {
    if (!evaluationId || !evaluateeId) return;

    try {
      await consentEvaluation({ id: evaluationId, consent, evaluatee_notes: comments });
      getCrewEvaluation({ evaluationID: evaluationId });
    } catch (e) {
      console.error(e);
    }

    setConsent(null);
    setComments('');
  };

  return evaluationId &&
    evaluateeId === userId &&
    hasEvaluateeConsented === null &&
    isSentForConsent ? (
    <>
      <div className="border-end pe-3 me-3 d-flex">
        <Button
          type="button"
          className="bg-red py-1 px-2 fw-light d-flex align-items-center"
          onClick={() => {
            setConsent(false);
            setIsModalOpen(true);
          }}
        >
          <SvgRender src={x} className="me-1" style={{ height: 18, width: 18 }} />
          Not Consent
        </Button>
        <Button
          type="button"
          className="bg-green py-1 px-3 fw-light ms-1 d-flex align-items-center"
          onClick={() => {
            setConsent(true);
            setIsModalOpen(true);
          }}
        >
          <SvgRender src={checkIcon} className="me-1" style={{ height: 18, width: 18 }} />
          Consent
        </Button>
      </div>
      <ConsentModal
        isOpen={isModalOpen}
        consent={consent}
        toggle={toggleModal}
        comments={comments}
        setComments={setComments}
        handleConsent={handleConsent}
      />
    </>
  ) : (
    <>
      {evaluationId && isEvaluationSubmitted ? (
        <div className="d-flex align-items-center">
          <div
            className="border d-flex align-items-center rounded-sm border-turquoise fs-12 color-turquoise text-turquoise 
          px-2 cpt-6 cpb-6 "
          >
            <SvgRender src={company} className="me-1 " style={{ height: 18, width: 18 }} />
            {`Submitted ${isEvaluationCreatedOnVessel ? 'to' : 'by'} Office`}
          </div>
          <div className="small-seperator text-platinum ms-3 me-3 cpt-12 cpb-12" />
        </div>
      ) : null}
    </>
  );
};

export default EvaluationTopActions;
