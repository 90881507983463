import { useEffect, useState } from 'react';
import Header from './Header';
import FolderVisibility from './FolderVisibility';
import Files from './Files';
import PageLoader from 'common/components/general/PageLoader';

import useRouter from 'use-react-router';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectActiveFolderId, selectIsLocked } from 'common/components/filesystem/store/selectors';

import * as filesystemActions from 'common/components/filesystem/store/actions';

const Content = () => {
  const { match } = useRouter();
  const activeFolderId = useSelector(selectActiveFolderId);
  const isLocked = useSelector(selectIsLocked);
  const [isLoading, setIsLoading] = useState(false);

  const [setActiveFolderID, getFolder] = useActions([
    filesystemActions.setActiveFolderID,
    filesystemActions.getFolder
  ]);

  const initFolder = async () => {
    try {
      setIsLoading(true);

      await getFolder({ id: activeFolderId });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (match.params.id) {
      if (parseInt(match.params.id) !== activeFolderId) setActiveFolderID(match.params.id);
    } else if (activeFolderId) {
      setActiveFolderID(null);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (activeFolderId) {
      initFolder();
    }
  }, [activeFolderId]);

  return activeFolderId ? (
    <div className="filesystem-content">
      <Header />

      {!isLoading ? (
        <div className="filesystem-content__preview gray-scrollbar">
          {!isLocked ? <FolderVisibility /> : null}
          <Files />
        </div>
      ) : null}

      <PageLoader isLoading={isLoading} />
    </div>
  ) : null;
};

export default Content;
