import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_REPORT'),
  ...asyncTypes('UPDATE_REPORT'),
  ...asyncTypes('DELETE_REPORT'),
  ...asyncTypes('GET_REPORT'),
  ...asyncTypes('GET_REPORT_TOTALS'),
  ...asyncTypes('GET_LAST_REPORT_TYPE'),
  ...asyncTypes('CAN_CREATE_NEW_REPORT'),
  ...asyncTypes('GET_CAPTAIN_REPORT_BUNKERING')
};

export default TYPES;
