import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_JOB_TEMPLATE'),
  ...asyncTypes('UPDATE_JOB_TEMPLATE'),
  ...asyncTypes('CREATE_JOB_TEMPLATE'),
  ...asyncTypes('GET_JOBS_TEMPLATES'),
  ...asyncTypes('ARCHIVE_JOB_TEMPLATE'),
  ...asyncTypes('UNARCHIVE_JOB_TEMPLATE'),
  ...asyncTypes('UPDATE_JOB_TEMPLATE_LABELS'),
  ...asyncTypes('DELETE_JOB_TEMPLATE_LABEL'),
  ...asyncTypes('GET_RELATION_TYPES'),
  ...asyncTypes('TOGGLE_TEMPLATE_LABEL'),

  SET_EDITING_LABEL: 'SET_EDITING_LABEL'
};

export default TYPES;
