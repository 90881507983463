import React from 'react';
import PropTypes from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import resubmitted from 'common/assets/svg/common/history.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const Resubmitted = ({ className, isResubmitted }) => {
  const { avoidRender, tooltipID } = useTooltipID('form-resubmitted');

  if (!isResubmitted || avoidRender) return null;

  return (
    <>
      <div
        id={tooltipID}
        className={`resubmitted-label border-radius-3 bg-light-gray-2 height-20 text-violet d-flex align-items-center cpe-4 cps-4 ${
          className || ''
        }`}
      >
        <SvgRender src={resubmitted} style={{ height: 12, width: 12 }} />
      </div>
      <Tooltip target={tooltipID}>Resubmitted</Tooltip>
    </>
  );
};

Resubmitted.propTypes = { isResubmitted: PropTypes.bool, className: PropTypes.string };

export default Resubmitted;
