import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import ReactNotifications from 'react-notification-system-redux';
import style from 'common/utils/notifications/style';

const AppAlerts = ({ notifications, remove }) => {
  const notificationsNoDuplicates = notifications.reduce((filteredNotifications, notification) => {
    const { title, message } = notification;
    if (filteredNotifications.find(n => n.title === title && n.message === message)) {
      remove(notification.uid);
      return filteredNotifications;
    } else {
      return [...filteredNotifications, notification];
    }
  }, []);

  return (
    <Container>
      <ReactNotifications notifications={notificationsNoDuplicates} style={style} />
    </Container>
  );
};

const Container = styled.div`
  white-space: pre-line;
`;

const mapStateToProps = state => ({
  notifications: state.alerts
});

const mapDispatchToProps = dispatch => ({
  remove: uid => dispatch(ReactNotifications.hide(uid))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppAlerts);
