import React from 'react';
import PropTypes from 'prop-types';

const FieldSubLabel = ({ text }) => {
  return <strong className="cps-2 mb-1 text-violet fs-10 lh-1">{text}</strong>;
};

FieldSubLabel.propTypes = {
  text: PropTypes.string
};

export default FieldSubLabel;
