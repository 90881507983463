import ChaptersTree from 'manuals/manual-inner/chapters-tree';

const Sidebar = ({ chapterActions, chaptersLoading }) => {
  return (
    <div className="manual-layout__sidebar">
      <div className="manual-layout__sidebar-body">
        <ChaptersTree chapterActions={chapterActions} chaptersLoading={chaptersLoading} />
      </div>
    </div>
  );
};

export default Sidebar;
