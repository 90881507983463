import PropTypes from 'prop-types';

const RemarksReviewTooltipContent = ({ reviewRemarks, reviewRemarksActionable }) => {
  return (
    <div>
      <div className="fw-bold lh-15 cmb-6">
        Remarks for office use only &nbsp;
        {reviewRemarksActionable ? (
          <span className="text-red fs-10">- Actionable remarks only</span>
        ) : null}
      </div>
      <div className="fw-300">{reviewRemarks}</div>
    </div>
  );
};

RemarksReviewTooltipContent.propTypes = {
  reviewRemarks: PropTypes.string,
  reviewRemarksActionable: PropTypes.bool
};

export default RemarksReviewTooltipContent;
