import React, { useEffect } from 'react';
import {
  selectEventDateStartedAt,
  selectEventId,
  selectModuleSyncButtonIsVisible
} from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getNextEventLubOilAnalysis } from 'events/store/event-modules/lub-oil-analysis/actions';
import { selectNextEventLubOilAnalysis } from 'events/store/event-modules/lub-oil-analysis/selectors';
import paths from 'routing/routes/_paths';
import { Button } from 'reactstrap';
import lubOilAnalysis from 'common/assets/svg/events/lub-oil-analysis.svg';
import SvgRender from 'common/components/general/SvgRender';

const NextLubOilAnalysisEventButton = () => {
  const dispatch = useDispatch();

  const startedAt = useSelector(selectEventDateStartedAt);
  const eventId = useSelector(selectEventId);
  const nextEventId = useSelector(selectNextEventLubOilAnalysis);
  const isSyncButtonVisible = useSelector(selectModuleSyncButtonIsVisible);

  useEffect(() => {
    dispatch(getNextEventLubOilAnalysis({ event_id: eventId }));
  }, [dispatch, eventId, startedAt]);

  const goToEvent = () => window.open(`${paths.events}/${nextEventId}`);

  return (
    <Button
      className={isSyncButtonVisible ? 'me-8' : ''}
      color="primary"
      size="sm"
      disabled={!nextEventId}
      onClick={goToEvent}
    >
      <SvgRender src={lubOilAnalysis} style={{ width: 12, height: 12 }} />
      <strong className="fw-medium ps-1">Next Lub Oil Analysis Event</strong>
    </Button>
  );
};

export default NextLubOilAnalysisEventButton;
