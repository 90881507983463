import { useState, useEffect } from 'react';
import Checkbox from 'common/components/form/inputs/Checkbox';
import SvgRender from 'common/components/general/SvgRender';
import clock from 'common/assets/svg/common/clock.svg';
import moment from 'moment';
import { formatGeneral, formatTime } from 'cleave-zen';

const CheckboxWithTime = ({
  className,
  onCheckboxChange,
  onFocus,
  onBlur,
  onTimeChange,
  checkboxValue = false,
  time = '00:00',
  alwaysShowTime = false,
  disabled,
  hasMaxTime = true,
  hideIcon,
  hasError
}) => {
  const [showError, setShowError] = useState(false);
  const [localTime, setLocalTime] = useState(null);

  useEffect(() => {
    if (hasMaxTime) {
      if (!moment(localTime, 'HH:mm').isValid()) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else {
      if (!localTime) {
        setShowError(false);
        return;
      }
      let splitTime = localTime.split(':');

      if (
        !splitTime[0] ||
        splitTime[0].length !== 2 ||
        !splitTime[1] ||
        splitTime[1].length !== 2
      ) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
  }, [localTime]);

  useEffect(() => {
    if (time && localTime === null) {
      setLocalTime(time);
    }
  }, [time]);

  return (
    <div
      className={`checkbox-time-input ${className ? className : ''} ${
        !hideIcon ? 'with-icon' : ''
      }`}
    >
      {!alwaysShowTime && (
        <Checkbox
          className="mb-0"
          label="Show time"
          value={checkboxValue}
          onChange={e => onCheckboxChange(e.target.checked)}
          disabled={disabled}
        />
      )}
      {checkboxValue && (
        <>
          {!hideIcon ? (
            <SvgRender
              className={`checkbox-time-input__icon text-${
                showError || hasError ? 'red' : 'violet'
              }`}
              src={clock}
              style={{ width: 14, height: 14 }}
            />
          ) : null}
          <input
            className={`form-control form-field flex-fill ${hasError ? 'border-red' : ''}`}
            disabled={disabled}
            value={localTime}
            placeholder={'hh:mm'}
            onChange={e => {
              if (hasMaxTime) {
                const timeValue = formatTime(e.target.value, {
                  timePattern: ['h', 'm'],
                  delimiterLazyShow: true
                });

                setLocalTime(timeValue);
                onTimeChange(timeValue);
              } else {
                const numericValue = formatGeneral(e.target.value, {
                  blocks: [2, 2],
                  numericOnly: true,
                  delimiters: [':'],
                  delimiterLazyShow: true
                });

                setLocalTime(numericValue);
                onTimeChange(numericValue);
              }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </>
      )}
    </div>
  );
};

export default CheckboxWithTime;
