import React from 'react';
import { dateToLocal } from 'common/utils/dates';
import PropTypes from 'prop-types';

const CreatedAt = ({ className = '', createdAt }) => {
  return (
    <div className={`text-violet ${className}`}>
      <span className="fw-medium">Created at:</span>{' '}
      {createdAt ? dateToLocal(createdAt)?.format('DD/MM/YYYY HH:mm') : '-'}
    </div>
  );
};

CreatedAt.propTypes = {
  className: PropTypes.string,
  createdAt: PropTypes.string
};

export default CreatedAt;
