import React from 'react';
import PropTypes from 'prop-types';
import { getMinutesHoursOrDays } from 'common/utils/dates';
import TruncatedTextMultilineContent from 'common/components/general/TruncatedTextMultilineContent';
import styled from '@emotion/styled';
import Tag from '../_components/Tag';
import NamesInTooltip from 'common/components/general/NamesInTooltip';

const Information = ({ post }) => {
  return (
    <InformationContainer className="d-flex flex-column">
      <div className="d-flex flex-column">
        <span className="title truncated-text fw-bold fs-16 text-current-color">{post?.name}</span>
        <span className="fw-medium text-violet fs-12 mt-1">
          {getMinutesHoursOrDays(post.created_at)} ago
        </span>
        <div className="fs-14 text-violet mt-3 mb-3 flex-1 overflow-hidden">
          <TruncatedTextMultilineContent
            className="truncated-text"
            content={post.description.replace(/<\/?[^>]+(>|&nbsp;)/g, '').replace(/&nbsp;/g, '')}
          />
        </div>
      </div>
      <div className="d-flex align-items-center mt-auto">
        {post?.tags?.length > 4 ? (
          <NamesInTooltip
            maxHeight={26}
            maxLimit={4}
            names={(post?.tags || [])?.map(tag => (
              <Tag key={tag.id} name={tag.name} />
            ))}
          />
        ) : (
          (post?.tags || [])?.map(tag => <Tag key={tag.id} name={tag.name} />)
        )}
      </div>
    </InformationContainer>
  );
};

const InformationContainer = styled.div`
  .truncated-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    -webkit-line-clamp: 2;
    max-height: 3rem;
  }

  .truncated-text-multilines {
    -webkit-line-clamp: 5;
    max-height: 6.75rem;
  }
`;

Information.propTypes = {
  post: PropTypes.shape({
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    )
  })
};

export default Information;
