import React from 'react';
import { useSelector } from 'react-redux';
import _flatten from 'lodash/flatten';

import {
  hasAtLeastOneVisibleField,
  selectCaptainReportTemplateType
} from 'captain-reports/store/selectors';
import SvgRender from 'common/components/general/SvgRender';
import { Row, Col } from 'reactstrap';

import weatherConditions from 'captain-reports/templates/config/weatherConditions';
import temperature from 'captain-reports/assets/svg/temperature.svg';

const WeatherTemperatureHeader = () => {
  const templateType = useSelector(selectCaptainReportTemplateType);

  const config = weatherConditions[templateType]({});
  const groupFields = _flatten(config.map(c => c.fields));

  const visible = useSelector(state =>
    hasAtLeastOneVisibleField(
      state,
      groupFields.filter(e => e?.category === 'temperature')
    )
  );

  return visible ? (
    <Row className="justify-content-end w-100p g-0 ps-2">
      <Col xs={{ size: 6 }} className="d-flex align-items-center text-violet">
        <SvgRender src={temperature} style={{ width: 8, height: 16 }} />
        <strong className="ps-1 fs-10 lh-1">TEMPERATURE</strong>
      </Col>
    </Row>
  ) : null;
};

export default WeatherTemperatureHeader;
