import SvgRender from 'common/components/general/SvgRender';
import React from 'react';

import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import { Button } from 'reactstrap';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const VesselButton = () => {
  const { history } = useRouter();

  const onClick = () => {
    history.push(paths.vessels);
  };

  return (
    <AuthCheck permissions={[permissions.onboard.vessel.view]}>
      <div className="sidebar-navbar--vessel-btn d-flex flex-column justify-content-center align-items-center mb-3">
        <Button color="link" onClick={onClick}>
          <SvgRender className="text-white" src={vesselIcon} style={{ width: 18, height: 24 }} />
        </Button>
      </div>
    </AuthCheck>
  );
};

export default VesselButton;
