import React, { useEffect, useMemo, useState } from 'react';
import useRouter from 'use-react-router';
import BaseStatusDropdown from 'common/components/statuses/BaseStatusDropdown';
import { useDispatch, useSelector } from 'react-redux';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { fetchListOptions } from 'store/lists/actions';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import { getEventPeriodicity } from 'events/store/events/actions';
import PeriodicityModal from './PeriodicityModal';
import {
  selectEventId,
  selectEventIsForVessel,
  selectEventStatus,
  selectEventVessel,
  selectIsStatusLocked
} from 'events/store/events/selectors';

const EventStatus = ({
  changeEventValue,
  isForVesselStatus,
  memoizedDebounce,
  isCreate,
  ...rest
}) => {
  const [selectedStatus, setSelectedStatus] = useState({});
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [periodicityModal, setPeriodicityModal] = useState({ nextDate: null, isOpen: false });

  const dispatch = useDispatch();
  const { match } = useRouter();

  const isOnboard = useSelector(state => state.isOnBoard);
  const eventStatuses = useSelector(state => selectListOptionsFromStore(state, 'event-statuses'));
  const status = useSelector(selectEventStatus);
  const id = useSelector(selectEventId);
  const vessel = useSelector(selectEventVessel);
  const isForVessel = useSelector(selectEventIsForVessel);
  const isStatusLocked = useSelector(selectIsStatusLocked);

  const saveStatus = async v => {
    changeEventValue({ status: v });

    if (!isCreate && status && v && status.id !== v.id) {
      await memoizedDebounce({ id: match.params.id, status_id: v ? v.id : null });
    }
  };

  const handleReccuringEvent = async v => {
    if (v.progress === 'closed' && id && !isOnboard) {
      const periodicity = await dispatch(getEventPeriodicity({ id }));

      if (periodicity.payload?.is_recurring) {
        setPeriodicityModal({ isOpen: true, nextDate: periodicity.payload.next_occurrence });
      }
    }
  };

  const onStatusChange = v => {
    handleReccuringEvent(v);

    if ((!isOnboard && !v.is_for_office) || (isOnboard && !v.is_for_vessel)) {
      setSelectedStatus(v);
      setSendModalOpen(true);
      return;
    }

    saveStatus(v);
  };

  const filteredStatuses = useMemo(() => {
    return eventStatuses.filter(s =>
      (status?.id && !isCreate ? !!vessel?.id : isOnboard) && isForVessel
        ? s.is_for_vessel
        : s.is_for_office
    );
  }, [eventStatuses, status?.id, isCreate, vessel?.id, isOnboard, isForVessel]);

  useEffect(() => {
    dispatch(fetchListOptions('event-statuses'));
  }, [dispatch]);

  useEffect(() => {
    if (!eventStatuses?.length || !isCreate || status?.id) return;

    if (isOnboard) {
      changeEventValue({
        status: eventStatuses.find(option => option.edit_side === 'vessel' && option.is_for_vessel)
      });
    } else {
      changeEventValue({
        status: eventStatuses.find(option => option.edit_side === 'office' && option.is_for_office)
      });
    }
  }, [changeEventValue, dispatch, eventStatuses, isCreate, isOnboard, status?.id]);

  return (
    <>
      <BaseStatusDropdown
        isLocked={isStatusLocked}
        value={isForVesselStatus || status}
        onChange={onStatusChange}
        statuses={filteredStatuses}
        showLockIcon={isStatusLocked}
        lockedTooltip={
          isStatusLocked
            ? isOnboard
              ? 'This event is locked onboard. Only the Office can edit it'
              : 'This event is locked in the Office. Only the vessel can edit it'
            : null
        }
        {...rest}
      />

      <PeriodicityModal modal={periodicityModal} setModal={setPeriodicityModal} />

      <DangerousActionModal
        transparent
        action={'send'}
        onAccept={() => {
          saveStatus(selectedStatus);
        }}
        onClose={() => setSendModalOpen(false)}
        closeModal={() => setSendModalOpen(false)}
        actionHoverColor="primary"
        isOpen={sendModalOpen}
        actionText={'SEND'}
        header={`Send to ${isOnboard ? 'Office' : 'Vessel'}`}
        body={`Are you sure you want to send this event to ${
          isOnboard ? 'Office' : 'Vessel'
        }? Editing from the ${isOnboard ? 'vessel' : 'office'} will be locked.`}
        className="digital-forms-modal"
        backdropClassName="blurred-backdrop"
      />
    </>
  );
};

export default EventStatus;
