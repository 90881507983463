import { createSelector } from 'reselect';

const selectGallery = state => state.gallery;

export const selectGalleryFiles = createSelector(selectGallery, gallery => gallery.files);

export const selectGalleryIsOpen = createSelector(selectGallery, gallery => gallery.isOpen);

export const selectedGalleryFile = createSelector(selectGallery, gallery => gallery.selectedFile);

export const selectRotateDirection = createSelector(
  selectGallery,
  gallery => gallery.rotateDirection
);
