import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import * as contractAction from 'crew/store/contracts/actions';
import { useForm, useActions } from 'utils/hooks';
import config from './config';
import Body from './Body';
import Drawer, { DrawerHeader, DrawerFooter, FormDrawer, FormBody } from 'common/components/drawer';

const Form = ({ drawer, active, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateCrewContract] = useActions([contractAction.updateCrewContract]);
  const { formState, resetForm, handleSubmitError, collectValues } = useForm(config);
  const { isOpen } = drawer;

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const onSave = async () => {
    const values = collectValues();

    if (!values) return;

    const { sign_on_port_id_as_per_captain, ...rest } = values;

    const params = {
      ...rest,
      id: active?.contract_id,
      sign_on_port_id_as_per_captain: sign_on_port_id_as_per_captain
        ? sign_on_port_id_as_per_captain?.id
        : null
    };

    try {
      setIsSubmitting(true);

      await updateCrewContract(params);
      refetchData();

      drawer.close();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      handleSubmitError(error);
      console.error(error);
    }
  };

  return (
    <Drawer {...drawer} className="table-drawer">
      <DrawerHeader className="border-bottom pb-2">
        <div className="d-flex flex-column">
          <span>Signed on</span>
          <strong className="mt-1 fs-12">{active?.full_name}</strong>
        </div>
      </DrawerHeader>

      <FormDrawer>
        <FormBody>
          <Body formState={formState} />
        </FormBody>
      </FormDrawer>

      <DrawerFooter className="d-flex justify-content-end p-3">
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={() => drawer.close()}
          disabled={isSubmitting}
        >
          CANCEL
        </Button>
        <Button onClick={onSave} disabled={isSubmitting} color="primary" className="px-4">
          SAVE
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  drawer: DrawerState.isRequired,
  active: PropTypes.object,
  refetchData: PropTypes.func
};

export default Form;
