import React, { useState, useEffect } from 'react';
import _find from 'lodash/find';
import { useSelector } from 'react-redux';

import {
  selectCaptainReportRobDifferences,
  selectConsumptionFuelGrade
} from 'captain-reports/store/selectors';

const DifferenceField = ({ subGroupIndex }) => {
  const [robDifferenceValue, setRobDifferenceValue] = useState();
  const robDifferenceData = useSelector(selectCaptainReportRobDifferences);
  const fuel_grade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );

  useEffect(() => {
    // For bs_difference field we send always null and not a value. It's always view only and we get the difference value from the port statement.
    if ((robDifferenceData?.length, fuel_grade?.id)) {
      setRobDifferenceValue(_find(robDifferenceData, e => e.fuel_grade_id === fuel_grade.id));
    } else {
      setRobDifferenceValue('-');
    }
  }, [robDifferenceData?.length, fuel_grade?.id]);

  return (
    <div className="d-flex align-items-center justify-content-end fw-bold fs-12 lh-1 height-32">
      {robDifferenceValue?.rob_difference || '-'}
    </div>
  );
};

export default DifferenceField;
