import FormHeaderPreview from 'common/components/forms/_components/FormHeaderPreview';

const Form = ({ substepForm, onFormSelect, subStepId }) => {
  const { form, form_submission, id } = substepForm;

  return form ? (
    <FormHeaderPreview
      onView={formSubmission => onFormSelect(form, formSubmission, id, subStepId)}
      form={{
        ...form,
        file_id: form?.file?.id,
        file: form?.file,
        importance: form?.importance,
        importance_id: form?.importance_id
      }}
      formSubmission={
        form_submission?.created_at
          ? {
              ...form_submission,
              created_at: form_submission.created_at,
              created_by: form_submission.created_by_id,
              updated_at: form_submission.updated_at,
              updated_by: form_submission.updated_by_id,
              file_id: form_submission.file_id,
              file: form_submission.form_submission_file,
              values: form_submission?.form_submission_values,
              status: form_submission?.status,
              submission_date: form_submission?.submission_date
            }
          : null
      }
    />
  ) : null;
};

export default Form;
