import React from 'react';

import { Row, Col } from 'reactstrap';

import Input from 'common/components/form/inputs/Input';
import PropTypes from 'prop-types';

const DateReported = ({ changeField, fields }) => {
  return (
    <Row xs={12} className="d-flex">
      <Col>
        <Input
          label="Reported to"
          onChange={e => changeField('reported_to')(e)}
          {...fields.reported_to}
        />
      </Col>
    </Row>
  );
};

DateReported.propTypes = {
  selectField: PropTypes.func,
  changeField: PropTypes.func,
  fields: PropTypes.object
};

export default DateReported;
