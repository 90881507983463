import { combineReducers } from '@reduxjs/toolkit';
import { reducer as alerts } from 'react-notification-system-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import asyncFilters from './async-filters/reducer';
import account from './account/reducer';
import auth from './auth/reducer';
import sidebar from './sidebar/reducer';
import vessels from './vessels/reducer';
import lists from './lists/reducer';
import captainReports from 'captain-reports/store/reducer/reportReducer';
import captainReportsForm from 'captain-reports/store/reducer/formReducer';
import captainReportsAlarms from 'captain-reports/store/alarms/reducer';
import portStatement from 'captain-reports/store/port-statement/reducer';
import systemRunningHours from 'captain-reports/store/system-running-hours/reducer';
import portDocumentOverview from 'captain-reports/store/port-document-overview/reducer';
import gallery from 'common/components/gallery/store/reducer';

import shifts from 'crew/store/shifts/reducer';
import events from 'events/store/events/reducer';
import eventModules from 'events/store/event-modules';
import tables from './tables/reducer';
import crew from './crew';
import workingSchedule from './working-schedule/reducer';
import ticketing from './ticketing/reducer';
import onboardReducer from './on-board/reducer';
import jobs from 'common/components/jobs/_base/store/reducer';
import jobsTemplates from './jobs-templates/reducer';
import jobsRestricted from './jobs-fields/reducer';
import jobsStatuses from './jobs-statuses/reducer';
import findings from 'common/components/findings/store/slice';
import pms from './pms';
import persons from './persons/reducer';

import formPlanning from 'common/components/forms/planning-table/store/reducer';
import chatbox from 'common/components/chatbox/store/reducer';
import manualInner from 'manuals/store/reducer';
import purchasing from 'store/purchasing';
import dashboard from 'store/dashboard';
import filesystem from 'common/components/filesystem/store/reducer';
import mga from 'common/components/mga/store/reducer';
import inventory from 'common/components/inventory/store/slice';
import settings from './settings/slice';
import news from 'common/components/news-and-announcements/store/slice';
import voyages from 'store/voyages';

import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

const settingsPersistConfig = {
  key: 'settings',
  storage
};

const accountPersistConfig = {
  key: 'account',
  storage
};

const vesselPersistConfig = {
  key: 'vessels',
  storage,
  whitelist: ['info']
};

const sidebarPersistConfig = {
  key: 'sidebar',
  storage,
  whitelist: ['isOtherMenuCollapse', 'savedItems', 'recents']
};

const listsPersistConfig = {
  key: 'lists',
  storage,
  whitelist: ['units', 'job-statuses']
};

export const history = createBrowserHistory();

const combinedReducer = combineReducers({
  auth,
  asyncFilters,
  alerts,
  tables,
  captainReports,
  captainReportsForm,
  captainReportsAlarms,
  portStatement,
  portDocumentOverview,
  crew,
  shifts,
  events,
  eventModules,
  ticketing,
  workingSchedule,
  manualInner,
  jobs,
  jobsStatuses,
  jobsTemplates,
  jobsRestricted,
  findings,
  systemRunningHours,
  formPlanning,
  chatbox,
  purchasing,
  persons,
  gallery,
  dashboard,
  filesystem,
  mga,
  inventory,
  pms,
  news,
  voyages,
  isOnBoard: onboardReducer,
  router: connectRouter(history),
  lists: persistReducer(listsPersistConfig, lists),
  account: persistReducer(accountPersistConfig, account),
  sidebar: persistReducer(sidebarPersistConfig, sidebar),
  vessels: persistReducer(vesselPersistConfig, vessels),
  settings: persistReducer(settingsPersistConfig, settings)
});

function rootReducer(state, action) {
  switch (action.type) {
    default:
      return combinedReducer(state, action);
  }
}

export default rootReducer;
