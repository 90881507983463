export const imageSrcRegex = /src=\\?"[^"]+"/g;
export const fileIdregex = /fileId=\\?"([^"]+)"/g;

export const replaceImageUrlsWithIds = (html = '', files = []) => {
  let parsedHtml = html;

  const urlMatches = parsedHtml ? [...html.matchAll(imageSrcRegex)] : [];
  urlMatches.forEach(match => {
    const file = files.find(
      f => match[0].replace('src="', '').replace('"', '').split('?')[0] === f.url.split('?')[0]
    );

    if (file) {
      parsedHtml = parsedHtml.replace(match[0], `fileId="${file.id}"`);
    }
  });

  return parsedHtml;
};

export const getFileIdsFromHtml = (html = '', returnOnlyIds = false) => {
  const fileMatches = html ? [...html.matchAll(fileIdregex)] : [];

  if (returnOnlyIds) {
    return fileMatches.map(match => parseInt(match[1]));
  }

  return fileMatches;
};

export const putFileUrlsInHtml = (
  html = '',
  files = [],
  params = { fileIdentifier: 'id', fileBaseUrl: '', imgTagAttributes: '' }
) => {
  const { fileIdentifier = 'id', fileBaseUrl = '', imgTagAttributes = '' } = params;
  let parsedHtml = html;
  const fileMatches = getFileIdsFromHtml(html);

  fileMatches.forEach(match => {
    const file = files.find(f => f[fileIdentifier] === parseInt(match[1]));

    if (file) {
      parsedHtml = parsedHtml.replace(
        match[0],
        `src="${fileBaseUrl}${file.url}" ${match[0]} ${imgTagAttributes}`
      );
    }
  });

  return parsedHtml;
};
