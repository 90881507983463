import {
  optionField,
  dateField,
  numberField,
  stringField,
  hiddenField
} from 'common/utils/form/fieldTypes';

const config = {
  action_type: optionField({ required: true }),
  timestamp: dateField({ required: true }),
  description: stringField(),

  credit_account: optionField(),
  debit_account: optionField(),
  bank_account: optionField(),

  attachments: optionField({ initialValue: [] })
};

export const configWithoutExtraCurrencies = {
  amount: numberField({ required: true }),
  items: {
    id: hiddenField(),
    store: optionField({ required: true }),
    price_per_unit: numberField({ required: true }),
    quantity: numberField({ required: true })
  }
};

export const configForExtraCurrencies = {
  local_amount: numberField({ required: true }),
  amount: hiddenField(),
  currency_id: optionField({ required: true }),
  items: {
    id: hiddenField(),
    store: optionField({ required: true }),
    local_price_per_unit: numberField({ required: true }),
    price_per_unit_converted: hiddenField(),
    quantity: numberField({ required: true })
  }
};

export default config;
