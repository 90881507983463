import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import chapter from 'common/assets/svg/common/chapter.svg';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const VettingViqChapterSelector = ({
  filter,
  value,
  onChange,
  isMulti = true,
  autoFocus = true,
  showIcon = false,
  ...rest
}) => {
  const [selected, setSelected] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'vetting-viq-chapters' },
    { selected, setSelected }
  );

  const SelectTag = showIcon ? IconSelect : Select;

  return (
    <SelectTag
      autoFocus={autoFocus}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.number}. ${option.name}`}
      isAsync
      isMulti={isMulti}
      size="sm"
      icon={showIcon ? chapter : null}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? selected.filter(el => filter?.value.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() => getInitialAsyncValues('vetting-viq-chapters')}
      loadOptions={search => getAsyncOptions(search, 'vetting-viq-chapters')}
      onChange={selected => {
        setSelected(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      {...rest}
    />
  );
};

export default VettingViqChapterSelector;
