import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const vesselInfoKeys = [
  {
    fields: [
      { name: 'VESSEL NAME', key: 'name' },
      {
        name: 'PREFIX',
        key: 'prefix.name',
        select_key: 'prefix_id',
        type: 'select',
        inputProps: {
          placeholder: '',
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          isClearable: true
        }
      },
      {
        name: 'CODE',
        key: 'code'
      },
      {
        name: 'VESSEL GROUPS',
        key: 'vessel_groups',
        select_key: 'vessel_groups',
        type: 'select',
        inputProps: {
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          loadOptions: search => getAsyncOptions(search, 'vessel-groups'),
          defaultOptions: () => getInitialAsyncValues('vessel-groups'),
          isClearable: true,
          isMulti: true
        }
      },
      { name: 'IMO No.', key: 'imo_no' },
      {
        name: 'VESSEL TYPE',
        key: 'type.name',
        type: 'select',
        select_key: 'type',
        inputProps: {
          placeholder: 'Select',
          isClearable: true
        }
      },
      { name: 'VESSEL SUBTYPE', key: 'subtype.name', type: 'select', select_key: 'subtype' }
    ]
  },
  {
    fields: [
      {
        name: 'CLASS',
        key: 'classification.name',
        type: 'select',
        select_key: 'classification',
        inputProps: {
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          loadOptions: search => getAsyncOptions(search, 'classification-societies'),
          defaultOptions: () => getInitialAsyncValues('classification-societies'),
          isClearable: true
        }
      },
      { name: 'CLASS No.', key: 'class_no' },
      { name: 'CLASS NOTATION', key: 'class_notation' }
    ]
  },
  {
    fields: [
      {
        name: 'FLAG',
        key: 'flag_country.name',
        select_key: 'flag_country',
        type: 'select',
        inputProps: {
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          loadOptions: search => getAsyncOptions(search, 'countries'),
          defaultOptions: () => getInitialAsyncValues('countries'),
          isClearable: true
        }
      },
      {
        name: 'PORT OF REGISTRY',
        key: 'registry_port.name',
        select_key: 'registry_port',
        type: 'select',
        inputProps: {
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          loadOptions: search => getAsyncOptions(search, 'ports'),
          defaultOptions: () => getInitialAsyncValues('ports'),
          isClearable: true
        }
      },
      { name: 'REGISTRATION No.', key: 'registry_no' }
    ]
  }
];

export default vesselInfoKeys;
