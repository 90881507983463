import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldsHeader } from 'captain-reports/templates/layout/_helpers';

import VisibleMultiField from 'captain-reports/templates/layout/fields/VisibleMultiField';
import TemplateGroup from 'captain-reports/templates/layout/fields/Group';

import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';

const MainEngineScavengeAndCamshaft = ({ section, label }) => {
  const camshaftSection = section.extraFields();

  const visibleScavengeSection = useSelector(state =>
    hasAtLeastOneVisibleField(state, section.fields)
  );
  const visibleCamshaftSection = useSelector(state =>
    hasAtLeastOneVisibleField(state, camshaftSection.fields)
  );

  return (
    <Row className="align-items-center template-group">
      {visibleScavengeSection ? (
        <Col xs={6} className="template-field mb-0">
          <FieldsHeader header={section.header} />
          <TemplateGroup className="align-items-center">
            {section.fields.map(field => (
              <VisibleMultiField key={field.key} field={field} label={label} />
            ))}
          </TemplateGroup>
        </Col>
      ) : null}
      {visibleCamshaftSection ? (
        <Col xs={3} className="template-field mb-0">
          <FieldsHeader header={camshaftSection.header} />
          <TemplateGroup>
            {camshaftSection.fields.map(field => (
              <VisibleMultiField key={field.key} field={field} label={label} />
            ))}
          </TemplateGroup>
        </Col>
      ) : null}
    </Row>
  );
};

export default MainEngineScavengeAndCamshaft;
