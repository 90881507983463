import ShiftTypesList from 'crew/components/shifts/shift-type-selector/ShiftTypesList';

const ShiftTypesLegends = ({ className }) => {
  return (
    <div
      className={
        className || 'mt-2 pt-2 mb-2 border-top fs-12 text-primary d-flex align-items-center'
      }
    >
      <span>Shift types</span>

      <ShiftTypesList className="ps-2 mb-0 flex-1" inline />
    </div>
  );
};

export default ShiftTypesLegends;
