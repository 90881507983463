export const dateValidation = ({ startedAt, endedAt, dateType }) => {
  if (dateType === 'single') {
    if (!startedAt) {
      return 'Date is required';
    }
  } else {
    return;
  }

  if (dateType === 'range') {
    if (!startedAt || !endedAt) {
      return 'Both dates are required';
    }
  } else {
    return;
  }
};
