import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CircledButton from 'common/components/buttons/CircledButton';

const ArrowPrevious = ({ onClick, className }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <CircledButton
      type="arrow"
      className={className}
      svgWrapperClassName={`${!isOnBoard ? 'bg-dark' : 'bg-primary'} cp-14`}
      onClick={onClick}
      svgStyle={{ width: 15, height: 15, transform: 'rotate(180deg)' }}
    />
  );
};

ArrowPrevious.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default ArrowPrevious;
