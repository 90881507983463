import { useEffect, useState, useCallback } from 'react';

import { Row, Col } from 'reactstrap';

import CircledButton from 'common/components/buttons/CircledButton';

import SvgRender from 'common/components/general/SvgRender';
import add from 'common/assets/svg/actions/add.svg';

import TimeInput from 'common/components/form/inputs/TimeInput';
import { useFormState } from 'utils/hooks';

import _debounce from 'lodash/debounce';
import moment from 'moment';

const WorkingScheduleShift = ({ formState, removeSubform, setHasTimeError, totalFormState }) => {
  const { fields, selectField } = useFormState(formState);
  const [error, setError] = useState('');

  const onRemove = () => {
    removeSubform();
    setError('');
    setHasTimeError(false);
  };

  const checkForErrors = (fieldValues, totalFormStateValues) => {
    if (
      fieldValues.to.value &&
      fieldValues.to.value.length === 5 &&
      fieldValues.from.value &&
      fieldValues.from.value.length === 5
    ) {
      //the variable below counts the amount of exactly same periods. IF it's 1 then it means
      //it compares a period with itself since the state has every single period inside
      //so we allow it to be up to 1 but not more
      let samePeriodErrorCounter = 0;

      for (const shift of totalFormStateValues) {
        const from = moment(fieldValues.from.value, 'hh:mm');
        const to = moment(fieldValues.to.value, 'hh:mm');
        const fromCompare = moment(shift.from.value, 'hh:mm');
        const toCompare = moment(shift.to.value, 'hh:mm');

        if (from.isSame(fromCompare) && to.isSame(toCompare)) {
          //here we increase it if the period exactly the same
          samePeriodErrorCounter++;
        }

        if (
          from.isBetween(fromCompare, toCompare) ||
          to.isBetween(fromCompare, toCompare) ||
          fromCompare.isBetween(from, to) ||
          toCompare.isBetween(from, to) ||
          //and here we check if it's more than 1 to display the error
          //if it isnt and the whole loop ends, then it becomes 0 again so we continue
          samePeriodErrorCounter > 1
        ) {
          setError('Shifts cannot overlap with each other');
          setHasTimeError(true);
          return;
        }
      }

      setError(false);
      setHasTimeError(false);
    } else if (
      (fieldValues.to.value && fieldValues.to.value.length < 5) ||
      (fieldValues.from.value && fieldValues.from.value.length < 5)
    ) {
      setError('Invalid time value');
      setHasTimeError(true);
    } else {
      setError(null);
      setHasTimeError(false);
    }
  };

  const debouncedCheckForErrors = useCallback(_debounce(checkForErrors, 500), []);

  useEffect(() => {
    debouncedCheckForErrors(fields, totalFormState);
  }, [fields.to.value, fields.from.value]);

  const bothAreFilled = fields.from.value && fields.to.value;

  return (
    <Row className="mb-2">
      <Col xs={6}>
        <TimeInput
          className="mb-0 me-3"
          innerClassName="text-center fw-bold"
          label="From"
          onChange={selectField('from')}
          {...fields.from}
        />
        {error ? (
          <div className="text-red position-absolute line-height-19 text-nowrap ms-3 fs-10">
            {error}
          </div>
        ) : null}
      </Col>
      <Col xs={6} className="d-flex align-items-center">
        <TimeInput
          className="mb-0"
          innerClassName="text-center fw-bold"
          label="To"
          onChange={selectField('to')}
          {...fields.to}
        />
        <CircledButton
          style={{ height: 14, width: 14 }}
          svgStyle={{ width: 6, height: 1 }}
          className={`mt-4 ms-1${!bothAreFilled ? ' opacity-0 pointer-events-none' : ''}`}
          type="remove"
          onClick={onRemove}
        />
      </Col>
    </Row>
  );
};

const WorkingScheduleShiftsContainer = ({
  isWatchkeeping,
  isAtSea,
  subStates,
  addSubform,
  removeSubform,
  setHasTimeError,
  totalFormState
}) => {
  return (
    <div className="working-schedule__shifts">
      <div className="working-schedule__shifts--header">
        <span className="fs-16 fw-bold line-height-19 text-dark">
          {isWatchkeeping ? 'Watchkeeping' : 'Non - Watchkeeping'}
        </span>
        <span
          className={`working-schedule__shifts--header__label working-schedule__shifts--header__label--${
            isAtSea ? 'sea' : 'port'
          }`}
        >
          {isAtSea ? 'at Sea' : 'at Port'}
        </span>
      </div>
      <div className="working-schedule__shifts--body">
        {subStates.map((formState, i) => (
          <WorkingScheduleShift
            key={i}
            formState={formState}
            removeSubform={() => removeSubform(i)}
            setHasTimeError={e => setHasTimeError(i, e)}
            totalFormState={totalFormState}
          />
        ))}
        <div
          className="text-aqua d-flex align-items-center fw-bold cursor-pointer"
          onClick={addSubform}
        >
          <SvgRender src={add} style={{ width: 14, height: 14 }} className="me-1" /> Add more
        </div>
      </div>
    </div>
  );
};

export default WorkingScheduleShiftsContainer;
