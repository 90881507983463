import TYPES from './types';
import { post } from 'utils/api';
import _get from 'lodash/get';
import { errorHandler } from 'common/utils/notifications';

const unauthorizedErrorHandler = (error, message) => dispatch => {
  if (error && error.status === 401 && _get(error, 'data.error', '') === 'unauthenticated') {
    dispatch(
      errorHandler({
        title: 'Uh oh!',
        message: message || 'You are not authorized to perform the requested action.'
      })
    );
  }
};

export const credentialslogin = params => dispatch => {
  dispatch({ type: TYPES.CREDENTIALS_LOGIN.START });

  return post('/login', params, false)
    .then(res => {
      dispatch({
        type: TYPES.CREDENTIALS_LOGIN.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREDENTIALS_LOGIN.ERROR, payload: error });
      dispatch(unauthorizedErrorHandler(error, 'Invalid credentials'));

      throw error;
    });
};

export const logout = () => (dispatch, getState) => {
  if (!getState().auth.isAuthenticated) return;

  dispatch({ type: TYPES.LOGOUT.SUCCESS });
};
