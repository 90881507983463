import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const FilterButton = ({
  disabled,
  isActive,
  onClick,
  children,
  size = 'sm',
  status = 'default',
  className = '',
  tooltip = '',
  tooltipInnerClassName = ''
}) => {
  const { avoidRender, tooltipID } = useTooltipID('filter-button-tooltip');

  if (avoidRender) return null;

  return (
    <Button
      disabled={disabled}
      color="link"
      className={`btn-filter border text-nowrap btn-top-4 btn-group-button ${status} ${
        isActive ? 'active' : ''
      } ${className}`}
      onClick={onClick}
      size={size}
      id={tooltipID}
    >
      {children}
      {tooltip ? (
        <Tooltip target={tooltipID} innerClassName={tooltipInnerClassName}>
          {tooltip}
        </Tooltip>
      ) : null}
    </Button>
  );
};

FilterButton.propTypes = {
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  status: PropTypes.oneOf(['success', 'error', 'proceeding', 'default']),
  className: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipInnerClassName: PropTypes.string
};

export default FilterButton;
