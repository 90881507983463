import React, { useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';
import LockedInputFallback from 'common/components/jobs/_base/components/LockedInputFallback';

import { components } from 'react-select';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectJobField, selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';

import Priority from 'common/components/general/Priority';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';
import * as listActions from 'store/lists/actions';

const PriorityInput = ({
  hideLabel,
  hideImportanceLabel,
  className,
  avoidCheck = false,
  isPms,
  drawerIsOpen = false,
  ...rest
}) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const { options } = useSelector(state => state.lists['job-importances']);

  const [setJobField] = useActions([jobProfileActions.setJobField]);
  const importance_id = useSelector(state => selectJobField(state, 'importance_id'));
  const isVisible = useSelector(selectIfFieldIsVisible('importance_id')) || isPms;
  const isJobLocked = useSelector(selectIsJobLocked);
  const isOnboard = useSelector(state => state.isOnBoard);

  useEffect(() => {
    fetchListOptions('job-importances');
  }, []);

  useEffect(() => {
    if (!importance_id)
      setJobField('importance_id', options.find(e => e.label === 'normal')?.id, true);
  }, [importance_id, options?.length, drawerIsOpen]);

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data.label ? (
        <Priority
          size="large"
          value={props.data.label}
          withText={hideImportanceLabel ? false : true}
        />
      ) : null}
    </components.SingleValue>
  );

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <Priority size="large" value={props.data.label} withText />
      </components.Option>
    );
  };

  const renderFallback = () => {
    return (
      <LockedInputFallback label={hideLabel ? null : 'Importance'}>
        {importance_id?.value ? (
          <Priority
            size="large"
            value={importance_id.value || importance_id}
            withText={hideImportanceLabel ? false : true}
          />
        ) : (
          ''
        )}
      </LockedInputFallback>
    );
  };

  if (!isVisible) return null;

  return (
    <UserCanEdit field="importance" fallback={renderFallback} avoidCheck={avoidCheck}>
      <Select
        label={hideLabel ? null : 'Importance'}
        className={`job-input job-input--priority ${
          hideLabel ? 'hidden-label' : 'form-group-spacing'
        } ${className ? className : ''}`}
        placeholder="Select importance"
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        components={{ Option, SingleValue }}
        value={importance_id}
        onChange={e => setJobField('importance_id', e, true)}
        options={options}
        isSearchable={false}
        disabled={isJobLocked || isOnboard}
        invisible={isPms}
        {...rest}
      />
    </UserCanEdit>
  );
};

export default PriorityInput;
