import TYPES from './types';
import _groupBy from 'lodash/groupBy';

const INITIAL_STATE = {
  template: null,
  relationTypes: null,
  editingLabel: null
};

const updateLabels = (stateLabels, payload) => {
  const { id, name, type } = payload;
  let labels = [...stateLabels];
  let dropdown_values = [];

  if (type === 'dropdown' && payload.dropdown_values) {
    dropdown_values = payload.dropdown_values.map(d => ({ id: d.id, payload: d.payload }));
  }

  if (!stateLabels.find(f => f.id === id)) {
    labels.push({ id, name, type, dropdown_values });
  } else {
    labels = labels.map(l => {
      if (l.id !== id) return l;
      else return { id, name, type, dropdown_values };
    });
  }

  return labels;
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_JOB_TEMPLATE.START:
    case TYPES.GET_JOB_TEMPLATE.ERROR:
      return {
        ...state,
        template: null
      };

    case TYPES.GET_JOB_TEMPLATE.SUCCESS:
      return {
        ...state,
        template: payload
      };

    case TYPES.UPDATE_JOB_TEMPLATE_LABELS.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          labels: updateLabels(state.template.labels, payload)
        }
      };

    case TYPES.DELETE_JOB_TEMPLATE_LABEL.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          labels: state.template.labels.filter(l => l.id == payload.id)
        }
      };

    case TYPES.GET_RELATION_TYPES.SUCCESS:
      return {
        ...state,
        relationTypes: _groupBy(payload, 'group')
      };

    case TYPES.SET_EDITING_LABEL:
      return {
        ...state,
        editingLabel: payload
      };

    default:
      return state;
  }
};

export default reducer;
