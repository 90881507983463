import TABLE_TYPES from 'common/components/table/store/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest, get, post } from 'utils/api';

export const getEventFormObligations = createAsyncThunk(
  'GET_EVENT_FORM_OBLIGATIONS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { eventId, table, ...rest } = params;

      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { table, params: rest } });

      const res = await get(`/events/${eventId}/form-obligations/pending`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: res.data, table }
      });

      return res?.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table: params.table } });
      return rejectWithValue(error);
    }
  }
);

export const getEventFormSubmissions = createAsyncThunk(
  'GET_EVENT_FORM_SUBMISSIONS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { eventId, table, ...rest } = params;

      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { table, params: rest } });

      const res = await get(`/events/${eventId}/form-obligations/submissions`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: res.data, table }
      });

      return res?.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table: params.table } });
      return rejectWithValue(error);
    }
  }
);

export const removeEventFormObligation = createAsyncThunk(
  'REMOVE_EVENT_FORM_OBLIGATION',
  async (params, { rejectWithValue }) => {
    try {
      const { eventId, obligationId } = params;

      const res = await deleteRequest(`/events/${eventId}/form-obligations/${obligationId}`);

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeEventFormSubmission = createAsyncThunk(
  'REMOVE_EVENT_FORM_SUBMISSION',
  async (params, { rejectWithValue }) => {
    try {
      const { eventId, submissionId } = params;

      const res = await deleteRequest(
        `/events/${eventId}/form-obligations/submissions/${submissionId}`
      );

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEventFormObligation = createAsyncThunk(
  'CREATE_EVENT_FORM_OBLIGATION',
  async (params, { rejectWithValue }) => {
    try {
      const { eventId, ...rest } = params;

      const res = await post(`/events/${eventId}/form-obligations`, rest);

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const linkFormSubmissionToEvent = createAsyncThunk(
  'LINK_FORM_SUBMISSION_TO_EVENT',
  async (params, { rejectWithValue }) => {
    try {
      const { eventId, ...rest } = params;

      const res = await post(`/events/${eventId}/form-obligations/submissions`, rest);

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
