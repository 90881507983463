import React from 'react';
import AddNewForm from 'common/components/jobs/_base/modules/forms/components/AddNewForm';

import { useActions } from 'utils/hooks';
import { selectJobFormIds } from 'common/components/jobs/_base/modules/forms/store/selectors';
import { useSelector } from 'react-redux';
import * as jobFormsActions from 'common/components/jobs/_base/modules/forms/store/actions';
import { selectIsPms } from 'common/components/pms/jobs/store/selectors';

const AddNewFormContainer = ({ setIsAdding, jobId, fecthJobForms, vesselIds, isForVessel }) => {
  const [addFormToJob] = useActions([jobFormsActions.addFormToJob]);

  const isPms = useSelector(selectIsPms);
  const excludeFormIds = useSelector(selectJobFormIds);

  const onCreate = async form => {
    const res = await addFormToJob({ jobId, form_uid: form?.uid, form });

    if (res) {
      setIsAdding(false);
      fecthJobForms();
    }
  };

  const onCancel = () => {
    setIsAdding(false);
  };

  return (
    <AddNewForm
      excludeFormIds={excludeFormIds}
      onCreate={onCreate}
      onCancel={onCancel}
      isPms={isPms}
      vesselIds={vesselIds}
      isForVessel={isForVessel}
    />
  );
};

export default AddNewFormContainer;
