import React from 'react';

const ShiftsTableRowHour = ({
  index,
  shifts,
  onClickShiftHalfHour,
  onHoverShiftHalfHour,
  firstSelectionIndex,
  secondSelectionIndex,
  selectedShiftType,
  isEditing
}) => {
  const leftShiftIndex = index * 2;
  const rightShiftIndex = index * 2 + 1;

  const isLeftShiftSelected =
    firstSelectionIndex !== -1 &&
    secondSelectionIndex !== -1 &&
    (firstSelectionIndex < secondSelectionIndex
      ? leftShiftIndex >= firstSelectionIndex && leftShiftIndex <= secondSelectionIndex
      : leftShiftIndex >= secondSelectionIndex && leftShiftIndex <= firstSelectionIndex);

  const isRightShiftSelected =
    firstSelectionIndex !== -1 &&
    secondSelectionIndex !== -1 &&
    (firstSelectionIndex < secondSelectionIndex
      ? rightShiftIndex >= firstSelectionIndex && rightShiftIndex <= secondSelectionIndex
      : rightShiftIndex >= secondSelectionIndex && rightShiftIndex <= firstSelectionIndex);

  return (
    <div className="shifts-table--row-hour">
      <div
        onMouseEnter={() => (isEditing ? onHoverShiftHalfHour(leftShiftIndex) : null)}
        onClick={() => (isEditing ? onClickShiftHalfHour(leftShiftIndex) : null)}
        className={`shifts-table--row-hour__left ${
          isEditing && (selectedShiftType?.color || shifts?.[leftShiftIndex]?.color)
            ? 'cursor-pointer'
            : ''
        } ${
          shifts?.[leftShiftIndex] && !isLeftShiftSelected
            ? shifts?.[leftShiftIndex]?.color
              ? `shifts-table--row-hour__active-${shifts?.[leftShiftIndex]?.color}`
              : ''
            : isLeftShiftSelected
            ? `shifts-table--row-hour__selected-${selectedShiftType?.color || 'empty'}`
            : ''
        }`}
      />
      <div
        onMouseEnter={() => (isEditing ? onHoverShiftHalfHour(rightShiftIndex) : null)}
        onClick={() => (isEditing ? onClickShiftHalfHour(rightShiftIndex) : null)}
        className={`shifts-table--row-hour__right ${
          isEditing && (selectedShiftType?.color || shifts?.[rightShiftIndex]?.color)
            ? 'cursor-pointer'
            : ''
        } ${
          shifts?.[rightShiftIndex] && !isRightShiftSelected
            ? shifts?.[rightShiftIndex]?.color
              ? `shifts-table--row-hour__active-${shifts?.[rightShiftIndex]?.color}`
              : ''
            : isRightShiftSelected
            ? `shifts-table--row-hour__selected-${selectedShiftType?.color || 'empty'}`
            : ''
        }`}
      />
    </div>
  );
};

export default ShiftsTableRowHour;
