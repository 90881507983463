import { useState, useEffect, FC, CSSProperties, ReactNode, ChangeEvent } from 'react';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody, PopoverProps } from 'reactstrap';

import CircledButton from '@/ts-common/components/buttons/CircledButton.tsx';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID.ts';
import BasicModal from '@/common/components/modals/BasicModal';
import Textarea from '@/common/components/form/inputs/Textarea';
import SvgRender from '@/ts-common/components/general/SvgRender.tsx';
import commentFilled from '@/common/assets/svg/common/comments-solid.svg';
import Editor from '@/common/components/form/inputs/Editor.tsx';
import comment from '@/common/assets/svg/common/comments.svg';
import { removeHtmlTags } from '@/ts-common/utils/texts';

interface CommentsPopoverProps {
  addBtn?: boolean;
  onSave?: (value: string | null | undefined) => void;
  comments?: string;
  popoverTitle?: string;
  popoverFooter?: ReactNode;
  popoverProps?: PopoverProps;
  className?: string;
  isHovering?: boolean;
  color?: string;
  type?: 'text' | 'html';
  withBg?: boolean;
  svgStyle?: CSSProperties;
  emptyText?: string;
  renderCustomButtonComponent?: () => ReactNode;
}

const CommentsPopover: FC<CommentsPopoverProps> = ({
  addBtn = false,
  onSave = () => null,
  comments = '',
  popoverTitle = 'Comment',
  popoverFooter = '',
  popoverProps = {},
  className = '',
  isHovering = false,
  color = 'purple',
  type = 'text',
  withBg = false,
  renderCustomButtonComponent,
  svgStyle,
  emptyText = '-'
}) => {
  const { avoidRender, tooltipID } = useTooltipID('comments-popup');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(comments);
  const [hovered, setIsHovered] = useState<boolean>(false);
  const isCurrentlyHovered = (isHovering || hovered) && !avoidRender;
  const showDefaultButton = isCurrentlyHovered && !renderCustomButtonComponent;

  useEffect(() => {
    if (comments) {
      setValue(comments);
    }
  }, [comments]);

  if (avoidRender) return null;

  const saveHandler = () => {
    if (onSave) {
      onSave(value);
    }
    setModalIsOpen(false);
  };

  return (
    <>
      {comments ? (
        <>
          <Button
            type="button"
            color="link"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`btn-comments-popup ${
              withBg ? 'btn-comments-popup__with-background' : ''
            } ${className}`}
            onClick={() => {
              if (addBtn) {
                setIsHovered(false);
                setModalIsOpen(true);
              }
            }}
            id={tooltipID || ''}
          >
            <SvgRender
              className={`text-${color}`}
              src={isCurrentlyHovered ? commentFilled : comment}
              style={svgStyle ?? { width: 15, height: 15 }}
            />
          </Button>

          {isCurrentlyHovered && tooltipID && (
            <UncontrolledPopover
              trigger="hover"
              placement="left-start"
              className="comments-popup"
              boundariesElement={'window' as PopoverProps['boundariesElement']}
              {...popoverProps}
              target={tooltipID}
            >
              <PopoverHeader
                className={`border-bottom d-flex align-items-center bg-white px-0 pt-0 text-${color}`}
              >
                <SvgRender src={comment} style={{ width: 15, height: 15 }} />
                <span className="fs-12 fw-medium comments-popup__title">{popoverTitle}</span>
              </PopoverHeader>

              <PopoverBody className="text-primary comments-popup__message pt-2 px-0 fs-14">
                {type === 'text' ? (
                  typeof comments === 'string' ? (
                    removeHtmlTags(comments)
                  ) : (
                    comments
                  )
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: comments }} />
                )}

                {popoverFooter || null}
              </PopoverBody>
            </UncontrolledPopover>
          )}
        </>
      ) : null}

      {addBtn &&
        !renderCustomButtonComponent &&
        !comments &&
        (showDefaultButton ? (
          <CircledButton
            className="btn-comments-popup__add cursor-pointer z-index-3 w-auto"
            type="add"
            svgStyle={{ width: 8, height: 8 }}
            onClick={() => setModalIsOpen(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        ) : (
          <div className="comments-popup__empty w-auto">
            <span onMouseEnter={() => setIsHovered(true)} className="cursor-pointer">
              {emptyText}
            </span>
          </div>
        ))}

      {renderCustomButtonComponent && !comments ? (
        <div onClick={() => setModalIsOpen(true)}>{renderCustomButtonComponent()}</div>
      ) : null}

      {addBtn && (
        <BasicModal
          isOpen={modalIsOpen}
          toggle={() => setModalIsOpen(false)}
          header={popoverTitle}
          className="comments-popup__modal"
          body={
            type === 'text' ? (
              <Textarea
                placeholder={`Add some ${popoverTitle}`}
                rows={7}
                disabled={false}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setValue(e.target.value)
                }
                value={value}
              />
            ) : (
              <Editor onChange={html => setValue(html)} value={value} />
            )
          }
          footer={
            <div className="d-flex align-items-center">
              <Button color="cancel" onClick={() => setModalIsOpen(false)}>
                CANCEL
              </Button>

              <Button color="primary" onClick={saveHandler}>
                SAVE
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default CommentsPopover;
