import { FC } from 'react';

import backArrowIcon from 'common/assets/svg/common/arrows/back-arrow.svg';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';

type ResetVisibilityModalProps = {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
};

const ResetVisibilityModal: FC<ResetVisibilityModalProps> = ({ isOpen, onAccept, onCancel }) => {
  return (
    <DangerousActionModal
      isOpen={isOpen}
      onAccept={onAccept}
      onCancel={onCancel}
      acceptButtonText="RESET"
      cancelButtonText="CANCEL"
      acceptButtonBackground="primary"
      icon={backArrowIcon}
      svgColor="white"
      header="Reset visibility"
    >
      Αre you sure you want to reset the folder visibility
    </DangerousActionModal>
  );
};

export default ResetVisibilityModal;
