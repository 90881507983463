import React from 'react';
import { Button } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrow.svg';
import { refetchPageTableData } from 'common/components/table/utils/helpers';
import { numberToStr } from 'common/utils/numbers';

const Arrow = ({ style = {}, disabled, onClick }) => (
  <Button
    color="primary"
    type="button"
    size="sm"
    className="d-flex p-0 height-16 width-16 align-items-center justify-content-center"
    onClick={onClick}
    disabled={disabled}
  >
    <SvgRender src={arrow} className="text-wthie" style={{ ...style, width: 9, height: 9 }} />
  </Button>
);

const PaginationNavigation = ({
  fetchData,
  paging,
  scrollToTop = true,
  customTableFetch = null
}) => {
  const goToPage = page => {
    customTableFetch
      ? customTableFetch({ page })
      : refetchPageTableData(fetchData, paging, page, scrollToTop);
  };

  return (
    <div className="d-flex align-items-center position-relative app-table--toptotal-navigation fs-12 text-primary">
      <Arrow
        style={{ transform: 'rotate(90deg)' }}
        disabled={paging?.current_page === undefined || paging?.current_page === 1}
        onClick={() => goToPage(paging?.current_page - 1)}
      />
      <div className="px-1">
        Page <strong>{numberToStr(paging?.current_page || 1)}</strong> of{' '}
        {numberToStr(paging?.last_page || 1)}
      </div>
      <Arrow
        style={{ transform: 'rotate(-90deg)' }}
        disabled={paging?.current_page === undefined || paging?.current_page === paging?.last_page}
        onClick={() => goToPage(paging?.current_page + 1)}
      />
    </div>
  );
};

export default PaginationNavigation;
