import React from 'react';
import SortingArrows from 'common/components/general/SortingArrows';

const Cell = ({ children, isHeader, sorted, order, hideSorting }) => {
  return (
    <>
      {children}
      {isHeader && !hideSorting && <SortingArrows sorted={sorted} order={order} />}
    </>
  );
};

export default Cell;
