import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import checkedIcon from 'common/assets/svg/common/check-circle-solid.svg';
import uncheckedIcon from 'common/assets/svg/common/check-circle-bold.svg';
import x from 'common/assets/svg/common/x-circle.svg';

const CheckedButton = ({
  isChecked,
  className,
  uncheckedIconColor = 'red',
  hideUncheckedIcon,
  ...rest
}) => {
  return (
    <SvgRender
      src={isChecked ? checkedIcon : hideUncheckedIcon ? x : uncheckedIcon}
      className={`aknowledge-btn ${isChecked ? 'aknowledge-btn--shadow' : ''} text-${
        !isChecked && hideUncheckedIcon ? uncheckedIconColor : 'turquoise'
      } pointer-cursor ${className || ''}`}
      {...rest}
    />
  );
};

CheckedButton.propTypes = {
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  uncheckedIconColor: PropTypes.string,
  hideUncheckedIcon: PropTypes.bool
};

export default CheckedButton;
