import React from 'react';

import Chatbox from 'common/components/chatbox';
import { useDrawer } from 'common/components/drawer';
import {
  getEventChatMessages,
  getEventUnreadChatMessages,
  markEventChatMessagesAsRead,
  sendEventChatMessage
} from 'events/store/events/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventId } from 'events/store/events/selectors';

const Chat = () => {
  const dispatch = useDispatch();
  const chatboxDrawer = useDrawer(false);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventId = useSelector(selectEventId);

  return (
    <div className={!isOnBoard ? 'ms-2' : 'border-start ps-2 ms-2'}>
      <Chatbox
        drawerClassName="forms-chatbox"
        getMessages={async () => await dispatch(getEventChatMessages({ id: eventId }))}
        sendMessage={async params =>
          await dispatch(sendEventChatMessage({ id: eventId, ...params }))
        }
        getUnreadMessages={async () => await dispatch(getEventUnreadChatMessages({ id: eventId }))}
        markMessagesAsRead={async () =>
          await dispatch(markEventChatMessagesAsRead({ id: eventId }))
        }
        drawer={chatboxDrawer}
        size={'sm'}
      />
    </div>
  );
};

export default Chat;
