import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Table from 'views/dashboard/components/Table';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/general/Status';
import FormFileIcon from 'common/components/forms/_components/FormFileIcon';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import FormDrawer from 'common/components/forms/drawer';
import PageLoader from 'common/components/general/PageLoader';
import paths from 'routing/routes/_paths';
import { renderViewString } from 'common/components/forms/digital/render-helpers';
import { getFieldName } from 'common/components/forms/digital/state-utils';

import { FormAlarm } from 'common/components/forms/planning-table/components/RowFields';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDrawer } from 'common/components/drawer';

import _get from 'lodash/get';
import moment from 'moment';
import ActionableRemarksLabel from 'common/components/forms/_components/ActionableRemarksLabel';
import { useDispatch, useSelector } from 'react-redux';
import { getDigitalForm } from 'store/forms/actions';
import {
  getDashboardFormPlannings,
  refetchDashboardTableData
} from 'store/dashboard/tables/actions';
import { getFormSectionColor, getFormSectionRequestParams } from './helpers';
import { fetchListOptions } from 'store/lists/actions';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import Action from './components/Action';
import { createFormObligationSubmission } from 'common/components/form-obligations/store/actions';
import SvgRender from 'common/components/general/SvgRender';
import eventsIcon from 'common/assets/svg/common/events.svg';
import { Link } from 'react-router-dom';
import PendingSignatureRequirementsLabel from 'common/components/forms/_components/PendingSignatureRequirementsLabel';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';

const Forms = ({ name, label }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialForm, setInitialForm] = useState(null);
  const [initialFormSubmissionID, setInitialFormSubmissionID] = useState(null);
  const [selectedObligationId, setSelectedObligationId] = useState(null);
  const [drawerType, setDrawerType] = useState('add');

  const dispatch = useDispatch();
  const drawer = useDrawer(false);

  const formStatuses = useSelector(state => selectListOptionsFromStore(state, 'form-statuses'));

  const requestParams = useMemo(
    () => getFormSectionRequestParams(label, formStatuses),
    [formStatuses, label]
  );

  const isFirst = label === 'forms_for_correction';
  const isLast = label === 'forms_to_finalize';
  const isObligationsTable = label === 'forms_obligations';
  const isOverduePeriodicalFormsTable = label === 'forms_periodical_forms';

  const openFormDrawer = useCallback(
    async ({ form, form_submission, id }) => {
      const type = !form_submission?.id ? 'add' : 'view';

      setDrawerType(type);
      setSelectedObligationId(id);
      setIsLoading(true);

      const formData = await dispatch(getDigitalForm({ id: form.id }));

      if (type === 'add') {
        setInitialForm(formData);
        setInitialFormSubmissionID(null);
      } else {
        setInitialForm(formData);
        setInitialFormSubmissionID(form_submission?.id);
      }

      setIsLoading(false);
      drawer.open();
    },
    [drawer, dispatch]
  );

  const refetchTableData = useCallback(() => {
    dispatch(refetchDashboardTableData('forms_for_correction'));
    dispatch(refetchDashboardTableData('forms_obligations'));
    dispatch(refetchDashboardTableData('forms_to_finalize'));
    dispatch(refetchDashboardTableData('forms_periodical_forms'));
    dispatch(getDashboardFormPlannings());
  }, [dispatch]);

  const onSave = async params => {
    if (!selectedObligationId) return;

    try {
      await dispatch(
        createFormObligationSubmission({ obligationId: selectedObligationId, ...params })
      );
      setSelectedObligationId(null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchListOptions('form-statuses'));

    if (isLast) {
      dispatch(getDashboardFormPlannings());
    }
  }, [dispatch, isLast]);

  if (!formStatuses?.length) return;

  return (
    <div className="forms-listing">
      <Table
        name={name}
        header={isFirst && <HeaderTitle title="Forms" path={paths.form_planning} />}
        className={isFirst ? 'mt-8' : 'mt-5'}
        subheader={isFirst && <span className="text-violet">Need your attention</span>}
        emptyBodyComponent={'-'}
        label={label}
        requestParams={requestParams}
        columns={[
          {
            header: <strong className={`fs-10 text-${getFormSectionColor(label)}`}>{name}</strong>,
            key: 'name',
            width: 6
          },
          {
            key: isOverduePeriodicalFormsTable ? 'subtype' : 'event',
            header: isOverduePeriodicalFormsTable ? 'SUBTYPE' : 'EVENT',
            className: isOverduePeriodicalFormsTable
              ? 'ps-5'
              : 'd-flex align-items-center justify-content-center',
            width: isOverduePeriodicalFormsTable ? 3 : 1
          },
          {
            key: 'status',
            header: isObligationsTable ? '' : 'STATUS',
            className: isOverduePeriodicalFormsTable ? 'd-none' : '',
            width: 2
          },
          {
            key: 'form_date',
            header: isObligationsTable || isOverduePeriodicalFormsTable ? 'DAYS LEFT' : 'FORM DATE',
            width: 3
          }
        ]}
        rows={{
          name: data => {
            const {
              review_remarks,
              review_remarks_actionable,
              for_correction_remarks,
              for_correction_remarks_actionable
            } = data;

            return (
              <Row className="align-items-center w-100p h-100p flex-nowrap">
                <Col className="max-width-24">
                  <Priority size="large" value={data?.form?.importance?.label} withText={false} />
                </Col>
                <Col className="overflow-hidden h-100p">
                  <div className="d-flex align-items-center w-100p h-100p overflow-hidden">
                    <div className="d-flex align-items-center overflow-hidden">
                      <div className="overflow-hidden pe-1 d-inline-flex">
                        <TextWithTooltip>{_get(data, 'form.name', '-')}</TextWithTooltip>
                      </div>
                      {data.alarm_state ? <FormAlarm hasAlarm /> : null}
                    </div>
                    <div className="d-flex align-items-center">
                      <ActionableRemarksLabel
                        reviewRemarks={review_remarks}
                        reviewRemarksActionable={review_remarks_actionable}
                        forCorrectionRemarks={for_correction_remarks}
                        forCorrectionRemarksActionable={for_correction_remarks_actionable}
                      />
                      <PendingSignatureRequirementsLabel
                        count={data.pending_signature_requirements_count}
                        formSubmissionId={data?.id}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="auto">
                  {data.form?.type && data?.id && !isOverduePeriodicalFormsTable ? (
                    <FormFileIcon
                      formType={data.form.type}
                      id={data.id}
                      file={data.file}
                      openNewTab
                    />
                  ) : null}
                </Col>
              </Row>
            );
          },
          subtype: data =>
            data.form_form_field ? (
              <TextWithTooltip className="h-auto" innerTooltipClassName="tooltip-white">
                <span className="fs-12">{getFieldName(data.form_form_field)}</span>

                <span className="cmx-4">|</span>

                <span className="text-violet fs-12">
                  {renderViewString(data.form_form_field, data.form_form_field_value)}
                </span>
              </TextWithTooltip>
            ) : null,
          event: data =>
            data.trigger_entity_type === 'event' && (
              <Link
                to={`${paths.events}/${data.trigger_entity.id}`}
                className="d-flex align-items-center justify-content-center bg-primary cpx-4 cpy-4 max-width-fit border-radius-3"
              >
                <SvgRender
                  className="text-white"
                  src={eventsIcon}
                  style={{ width: 11, height: 11 }}
                />
              </Link>
            ),
          status: data =>
            data.status ? (
              <Status type="forms" value={data.status} />
            ) : isObligationsTable ? (
              ''
            ) : (
              '-'
            ),
          form_date: data => (
            <div className="d-flex align-items-center">
              {isObligationsTable || isOverduePeriodicalFormsTable ? (
                <span
                  className={`${data.becoming_overdue || data.is_overdue ? 'fw-500' : ''} ${
                    data.is_overdue || isOverduePeriodicalFormsTable
                      ? 'text-danger'
                      : data.becoming_overdue
                      ? 'text-warning'
                      : ''
                  }`}
                >
                  {data.days_left} days
                </span>
              ) : data.submission_date ? (
                <span className={data.alarm_state ? 'text-red' : ''}>
                  {moment(data.submission_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </span>
              ) : (
                '-'
              )}

              <AuthCheck permissions={[permissions.onboard.forms.view]}>
                <Action
                  form={data.form}
                  id={data.id}
                  label={label}
                  openFormDrawer={openFormDrawer}
                />
              </AuthCheck>
            </div>
          )
        }}
      />

      <FormDrawer
        drawerType={drawerType}
        drawer={drawer}
        onSubmitCallback={refetchTableData}
        initialForm={initialForm}
        initialFormSubmissionID={initialFormSubmissionID}
        onSaveFormSubmission={isObligationsTable ? onSave : null}
      />
      <PageLoader isLoading={isLoading} />
    </div>
  );
};

export default Forms;

Forms.propTypes = {
  name: PropTypes.oneOf(['FOR CORRECTIONS', 'FORM OBLIGATIONS', 'TO FINALIZE']).isRequired,
  label: PropTypes.oneOf([
    'forms_for_correction',
    'forms_to_finalize',
    'forms_obligations',
    'forms_periodical_forms'
  ]).isRequired
};
