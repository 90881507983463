import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import link from 'common/assets/svg/common/link.svg';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';

const LinkToExistingCaseButton = ({ drawer }) => {
  return (
    <div
      onClick={() => drawer.open()}
      className={`right-32 position-absolute top-12 border-0 d-inline-flex align-items-center cpx-12 fw-medium rounded-sm btn btn-white btn-sm text-decoration-none text-primary`}
    >
      <SvgRender src={link} style={{ width: 16, height: 16 }} className="me-1" />
      Link to Existing Case
    </div>
  );
};

LinkToExistingCaseButton.propTypes = {
  drawer: DrawerState
};

export default LinkToExistingCaseButton;
