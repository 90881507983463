import { useQuery } from '@tanstack/react-query';
import { getDefaultOptions } from '@/ts-common/components/form/inputs/select/api/api'; // Do NOT change this import
import { UseQueryOptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';
import { constructKeyFromPath } from '@/ts-common/components/form/inputs/select/select-helpers';
import { listsKey } from '@/ts-common/components/form/inputs/select/api/query-keys';

export const useDefaultOptionsQuery = <T = unknown>(options?: UseQueryOptionsRequestParams<T>) => {
  const key = constructKeyFromPath(options?.path);

  return useQuery({
    queryKey: [listsKey, key, options],
    queryFn: () => getDefaultOptions<T>(options),
    enabled: options?.enabled !== undefined ? options.enabled : true,
    retry: false,
    staleTime: 5 * 60 * 1000,
    select: options?.select
  });
};
