import React from 'react';
import moment from 'moment';

import DateInput from 'common/components/form/inputs/date';

const AsOfDateFilter = ({ onChange, value, ...rest }) => {
  return (
    <DateInput
      value={value ? moment(value) : null}
      innerLabel="As of"
      useStringValue
      onChange={onChange}
      {...rest}
    />
  );
};

export default AsOfDateFilter;
