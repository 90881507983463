import React from 'react';
import warningIcon from 'common/assets/svg/common/warning-empty.svg';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';

const Error = ({ error, id, className = '' }) => {
  return (
    <div className={`me-2 text-coral d-flex align-items-center ${className}`}>
      <SvgRender
        src={warningIcon}
        style={{ height: 16, width: 16 }}
        id={`evaluation-fields-list--error-${id}`}
      />
      {error ? <Tooltip target={`evaluation-fields-list--error-${id}`}>{error}</Tooltip> : null}
    </div>
  );
};

export default Error;
