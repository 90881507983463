import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFormState, useActions } from 'utils/hooks';
import { FormState } from 'common/entities/form/FormTypes';
import FieldsItemsHeader from 'common/components/inventory/survey/FieldsItemsHeader';
import FieldsSparePartsHeader from 'common/components/inventory/survey/FieldsSparePartsHeader';
import _groupBy from 'lodash/groupBy';

// import TableSearchText from 'common/components/table/TableSearchText';
import * as tableActions from 'common/components/table/store/actions';
import SelectSpareParts from './SelectSpareParts';
import SelectPurchasingItems from './SelectPurchasingItems';

import FieldsCategoryCollapse from './FieldsCategoryCollapse';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';

const Group = ({
  formState,
  formType,
  activeGroup,
  onRemove,
  onChangeActiveGroup,
  onSaveSurvey,
  searchText
}) => {
  const groups = _uniq(formState?.map(f => _get(f, 'state.group.value.id'))); // Get groups in the same order as specified in formState
  const groupedData = _groupBy(formState, 'state.group.value.id');

  return (
    <div className="pb-4 pt-1">
      {groups.map(id => (
        <FieldsCategoryCollapse
          key={id}
          id={id}
          formType={formType}
          searchText={searchText}
          activeGroup={activeGroup}
          onRemove={onRemove}
          data={groupedData[id]}
          isExpanded={activeGroup && activeGroup === id}
          onExpand={() => onChangeActiveGroup(id, groupedData?.[activeGroup], formState)}
          onCancel={() => onChangeActiveGroup(null, groupedData?.[id], formState)}
          onSave={() => onSaveSurvey(groupedData?.[id], formState)}
        />
      ))}
    </div>
  );
};

const Body = ({
  formState,
  formType,
  isDrawerOpen,
  activeGroup,
  onChangeActiveGroup,
  onSaveSurvey
}) => {
  const [searchText, setSearchText] = useState('');

  const { subStates, addSubform, removeSubform, setSubStates } = useFormState(formState);
  const formSubstate = subStates(formType);
  const TABLE_LABEL = 'inventory_survey_items';

  const [setTablePageSearch] = useActions([tableActions.setTablePageSearch]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSearchText('');
      setTablePageSearch({ text: '', table: TABLE_LABEL });
    }
  }, [isDrawerOpen, setTablePageSearch]);

  return (
    <div>
      {/* <div className="d-flex justify-content-end pe-0 cmb-4">
        <TableSearchText onSearch={search => setSearchText(search)} label={TABLE_LABEL} />
      </div> */}

      <div className="bg-lighter-blue d-flex-column p-2 fs-12 text-primary fw-medium mb-2 rounded">
        <div className="d-flex">
          <span className="me-2">•</span>
          If you don’t survey for any of the items below just leave the box blank without filling
          any number. In case you enter a number the system will update your inventory quantity with
          this new number.
        </div>
        <div className="d-flex">
          <span className="me-2">•</span> In case you need to survey a spare part out of the list,
          you can use the systems filter and spare part input below. If you select a spare part it
          will be added in the list.
        </div>
      </div>

      {formType === 'spare_parts' ? (
        <SelectSpareParts
          formState={formSubstate}
          isDrawerOpen={isDrawerOpen}
          onChange={sparePart => addSubform('spare_parts', sparePart)}
          onMultiChange={newFormSubstate =>
            setSubStates('spare_parts', [...formSubstate, ...newFormSubstate])
          }
        />
      ) : (
        <SelectPurchasingItems
          formState={formSubstate}
          onChange={item => addSubform('items', item)}
        />
      )}

      {formType === 'items' ? <FieldsItemsHeader /> : <FieldsSparePartsHeader />}

      <Group
        formState={formSubstate}
        formType={formType}
        searchText={searchText}
        activeGroup={activeGroup}
        onRemove={removeSubform}
        onChangeActiveGroup={onChangeActiveGroup}
        onSaveSurvey={onSaveSurvey}
      />
    </div>
  );
};

Body.propTypes = {
  formState: FormState.isRequired,
  formType: PropTypes.oneOf(['spare_parts', 'items']).isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  activeGroup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeActiveGroup: PropTypes.func,
  onSaveSurvey: PropTypes.func
};

export default Body;
