import React from 'react';
import SvgRender from 'common/components/general/SvgRender';

import errorIcon from 'common/assets/svg/common/validation-error.svg';
import Tooltip from 'common/components/general/Tooltip';

export const ValidationWarning = ({ className = '', target }) => {
  return (
    <>
      <SvgRender
        id={target}
        className={`table-validation-warning ${className} cursor-pointer`}
        src={errorIcon}
        style={{ width: 16, height: 16 }}
      />
      {target && <Tooltip target={target}>Validation Error</Tooltip>}
    </>
  );
};

export default ValidationWarning;
