import TYPES from './types';
import _sortBy from 'lodash/sortBy';
import { put, get } from 'utils/api';
import { parseLabelBeforeSave } from 'common/components/jobs/_base/modules/template/_helpers';

export const getActiveJobTemplateData = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_JOB_TEMPLATE_DATA.START, payload: { params } });

  return get(`/job-templates/${id}`, rest)
    .then(response => {
      const template = { ...response.data, labels: _sortBy(response.data.labels, ['sort_index']) };
      template.isFleet = params.fleetTypeId === template.type_id;

      dispatch({ type: TYPES.GET_JOB_TEMPLATE_DATA.SUCCESS, payload: template });

      return template;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_JOB_TEMPLATE_DATA.ERROR, payload: error });
      throw error;
    });
};

export const updateJobTemplateLabel = params => dispatch => {
  const { job_id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE_LABEL.START, payload: { params } });

  return put(`/jobs/${job_id}/labels`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE_LABEL.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UPDATE_JOB_TEMPLATE_LABEL.ERROR, payload: error }));
};

export const getTemplateLabels = jobId => dispatch => {
  dispatch({ type: TYPES.GET_TEMPLATE_LABELS.START });

  return get(`/jobs/${jobId}/labels`)
    .then(response => {
      dispatch({ type: TYPES.GET_TEMPLATE_LABELS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_TEMPLATE_LABELS.ERROR, payload: error }));
};

export const setJobTemplateLabel = (label, shouldSave) => (dispatch, getState) => {
  dispatch({ type: TYPES.SET_JOB_TEMPLATE_LABEL, payload: label });

  const job_id = getState().jobs.id;

  if (job_id && shouldSave !== false)
    dispatch(updateJobTemplateLabel({ ...parseLabelBeforeSave(label), job_id }));
};
export const resetJobTemplateData = () => dispatch => {
  dispatch({ type: TYPES.RESET_JOB_TEMPLATE_DATA });
};

export const updateFleetTemplateData = (template, vessels) => dispatch => {
  dispatch({ type: TYPES.UPDATE_FLEET_TEMPLATE_DATA, payload: { template, vessels } });
};

export const addOpenVessel = vessel => {
  return { type: TYPES.ADD_OPEN_VESSEL, payload: { vessel } };
};

export const removeOpenVessel = vessel => {
  return { type: TYPES.REMOVE_OPEN_VESSEL, payload: { vessel } };
};

export const setOpenVessels = vessels => {
  return { type: TYPES.SET_OPEN_VESSELS, payload: { vessels } };
};
