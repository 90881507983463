import React, { useState, useEffect } from 'react';
import { useForm } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import config from './config';
import Form from './Form';
import { selectEventId } from 'events/store/events/selectors';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { TableTypes } from 'common/entities/tables/TableTypes';
import { createAccounts } from 'events/store/event-modules/accounts/actions';

const CreateForm = ({ drawer, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { formState, collectValues, hasErrors, resetForm } = useForm(config);

  const eventId = useSelector(selectEventId);

  const submitForm = async params => {
    if (!eventId) return;

    try {
      setIsSubmitting(true);
      await dispatch(createAccounts({ id: eventId, ...params })).unwrap();

      setIsSubmitting(false);

      drawer.close();
      refetchData();
      resetForm();
    } catch (error) {
      setIsSubmitting(false);

      console.error(error);
    }
  };

  useEffect(() => {
    if (!drawer.isOpen) {
      resetForm();
    }
  }, [drawer.isOpen, resetForm]);

  return (
    <Form
      drawer={drawer}
      formState={formState}
      collectValues={collectValues}
      hasErrors={hasErrors}
      submitForm={submitForm}
      isSubmitting={isSubmitting}
    />
  );
};

CreateForm.propTypes = {
  drawer: DrawerState,
  refetchData: TableTypes.refetchData
};

export default CreateForm;
