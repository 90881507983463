import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { selectFieldVisibility } from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

import { numberToStr } from 'common/utils/numbers';

const TotalProducedField = ({ field, tooltip }) => {
  const [isViewOnly, setIsViewOnly] = useState(false);

  const isSludgeProducedVisible = useSelector(state =>
    selectFieldVisibility(state, 'sludge_produced_me')
  );

  const isSludgeProducedMaintenanceVisible = useSelector(state =>
    selectFieldVisibility(state, 'sludge_produced_maintenance')
  );

  const sludge_produced_total = useValue({ key: 'sludge_produced_total' });

  useEffect(() => {
    if (isSludgeProducedVisible || isSludgeProducedMaintenanceVisible) {
      setIsViewOnly(true);
    } else {
      setIsViewOnly(false);
    }
  }, [isSludgeProducedVisible, isSludgeProducedMaintenanceVisible]);

  return (
    <Row className="align-items-center">
      <Col xs={12}>
        <div className={`${isViewOnly ? 'd-none' : ''}`}>
          <NumberField field={field} />
        </div>

        {isViewOnly ? (
          <>
            <ValidationWrapper
              field={{ ...field, label: 'TOTAL PRODUCED' }}
              value={sludge_produced_total}
              errorClassName=" pe-1 cme-2"
            >
              <div className="w-100p fs-12 fw-bold height-24">
                {numberToStr(sludge_produced_total)}
              </div>
            </ValidationWrapper>
          </>
        ) : null}
      </Col>
    </Row>
  );
};

export default TotalProducedField;
