import { authorizeAccount } from 'common/utils/permissions/helpers';

export const isAuthorized = (account, permissions = [], mode = 'or', environments = []) => {
  return authorizeAccount({ account, permissions, mode, environments }, true);
};

export const getAuthorizedPermissionSettings = (
  account,
  permission,
  mode = 'or',
  environments = []
) => {
  if (authorizeAccount({ account, permissions: [permission], mode, environments }, true)) {
    return account.permissions?.[permission];
  }

  return null;
};
