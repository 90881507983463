import { createSelector } from 'reselect';

const selectWorkingScheduleReducer = state => state.workingSchedule;

export const selectIsLoading = createSelector(
  selectWorkingScheduleReducer,
  state => state.isLoading
);

export const selectOldSchedule = createSelector(
  selectWorkingScheduleReducer,
  state => state.oldSelectedSchedule
);

export const selectIsDraft = createSelector(selectWorkingScheduleReducer, state => state.isDraft);

export const selectLatestSchedule = createSelector(
  selectWorkingScheduleReducer,
  state => state.latestSchedule
);

export const selectCrewMembers = createSelector(
  selectWorkingScheduleReducer,
  state => state.crewMembers
);
