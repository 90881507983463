import LinkTo from '@/common/components/general/LinkTo';
import { FC } from 'react';

type HeaderTitleProps = {
  title: string;
  path: string;
};

const HeaderTitle: FC<HeaderTitleProps> = ({ title, path }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <span>{title}</span>
      <LinkTo size="sm" to={path} />
    </div>
  );
};

export default HeaderTitle;
