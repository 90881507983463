import { getOfficeNotes, createOfficeNotes, editOfficeNotes, deleteOfficeNotes } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  saved_notes: [],
  loading: false
};

const slice = createSlice({
  name: 'eventOfficeNotes',
  initialState: INITIAL_STATE,
  reducers: {
    addOfficeNotes: (state, { payload }) => {
      state.saved_notes = payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getOfficeNotes.pending, state => {
        state.loading = true;
        return state;
      })
      .addCase(getOfficeNotes.fulfilled, (state, { payload }) => {
        state.saved_notes = payload;
        state.loading = false;

        return state;
      })
      .addCase(getOfficeNotes.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(createOfficeNotes.fulfilled, (state, { payload }) => {
        state.saved_notes = [payload, ...state.saved_notes];

        return state;
      })
      .addCase(editOfficeNotes.fulfilled, (state, { payload }) => {
        state.saved_notes = state.saved_notes.map(note => {
          if (note.id === payload.id) return payload;

          return note;
        });

        return state;
      })
      .addCase(deleteOfficeNotes.fulfilled, (state, { meta }) => {
        state.saved_notes = state.saved_notes.filter(note => note.id !== meta.arg.id);

        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { addOfficeNotes } = slice.actions;

export default slice.reducer;
