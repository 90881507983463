import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const JobStatusProgressSelector = ({
  filter,
  onChange,
  isMulti,
  listParams,
  showDefaultOptions = true
}) => {
  const [selected, setSelected] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'job-progress-statuses', listParams },
    { selected, setSelected },
    'label'
  );

  return (
    <Select
      autoFocus
      placeholder="Select value"
      getOptionValue={option => option.label}
      getOptionLabel={option => getPartyDisplayName(option)}
      isAsync
      defaultOptions={() =>
        showDefaultOptions
          ? getInitialAsyncValues('job-progress-statuses', null, null, listParams)
          : []
      }
      isMulti={isMulti}
      value={filter.value ? selected.filter(el => filter.value.includes(el.label)) : []}
      loadOptions={search => getAsyncOptions(search, 'job-progress-statuses', listParams)}
      onChange={selected => {
        setSelected(selected);

        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.label)] : selected) : ''
        });
      }}
    />
  );
};

export default JobStatusProgressSelector;
