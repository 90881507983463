import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import Stats from 'crew/profile/main/personal/layout/Stats';

import VerticalTabView from 'common/components/layouts/vertical-tab-view';
import ActiveTabViewActions from 'common/components/layouts/vertical-tab-view/components/ActiveTabViewActions';

import { selectCrewProfile } from 'crew/store/profile/selectors';

const Personal = ({ config, rightSideInfoConfig }) => {
  const [active, setActive] = useState(null);
  const [mode, setMode] = useState('view');

  const profile = useSelector(selectCrewProfile);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const editPermissions = config.find(el => el.path === active)?.editPermissions;

  useEffect(() => {
    if (config.length) {
      setActive(config[0].path);
    }
  }, []);

  const onTabClick = item => {
    setActive(item.path);
  };

  const onCloseEditMode = () => {
    setMode('view');
  };
  const onSaveForm = async () => {
    setMode('view');
  };

  const setEditMode = () => {
    setMode('edit');
  };

  const renderComponent = () => {
    if (mode === 'view' || !active) return null;

    const activeElement = config.find(el => el.path === active);

    if (!activeElement || !activeElement?.form) return null;

    return (
      <activeElement.form
        active={activeElement}
        config={activeElement?.componentProps?.config}
        onCloseEditMode={onCloseEditMode}
        mode={mode}
      />
    );
  };

  const showEmpty = useMemo(() => {
    if (active) {
      const selected = config.find(el => el.path === active);
      const showEmpty = selected && selected.showEmpty ? selected.showEmpty(profile) : false;

      return showEmpty;
    } else {
      return false;
    }
  }, [active, config, profile]);

  const showActions = useMemo(() => {
    const excludedTabs = ['notes', 'languages', 'security'];
    return (
      !isOnBoard &&
      mode === 'view' &&
      !showEmpty &&
      !excludedTabs.includes(active) &&
      config.length > 0
    );
  }, [isOnBoard, mode, showEmpty, active, config]);

  return (
    <Row>
      <Col xs={8} className={`crew-tab-active-${active} position-relative`}>
        {showActions ? (
          <ActiveTabViewActions
            onSaveForm={onSaveForm}
            onCloseEditMode={onCloseEditMode}
            editPermissions={editPermissions}
            mode={mode}
            onEditClick={setEditMode}
          />
        ) : null}
        {config.length > 0 && (
          <VerticalTabView
            onEmptyButtonCLick={isOnBoard ? null : setEditMode}
            data={profile}
            onClick={onTabClick}
            disabled={mode === 'edit'}
            className={`h-100p ${mode === 'edit' ? 'tab-view-edit' : ''}`}
            selected={active ? config.find(el => el.path === active) : config[0]}
            config={config}
            renderComponent={mode === 'view' ? null : renderComponent}
          />
        )}
      </Col>
      <Col xs={4}>
        {!isOnBoard ? <Stats /> : null}
        {rightSideInfoConfig.map &&
        profile.nearest_airport &&
        profile.nearest_airport?.lat &&
        profile.nearest_airport?.lon ? (
          <rightSideInfoConfig.map nearestAirport={profile.nearest_airport} />
        ) : null}
        <rightSideInfoConfig.component config={rightSideInfoConfig} />
      </Col>
    </Row>
  );
};

export default Personal;
