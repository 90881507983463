import { deleteRequest, get, post, put } from '@/api';
import {
  PlannedRouteWaypoints,
  PlannedRouteWaypointsQueryRequestParams,
  Waypoint,
  PlannedRouteWaypointMutationRequestParams,
  PlannedRouteId
} from '@/common/types/planned-routes';

export const getPlannedRouteWaypoints = async (params: PlannedRouteWaypointsQueryRequestParams) => {
  const { id } = params;

  const response = await get<PlannedRouteWaypoints>(`/planned-routes/${id}/waypoints`);

  return response.data;
};

export const createPlannedRouteWaypoint = async (
  params: PlannedRouteWaypointMutationRequestParams
) => {
  const { id, ...rest } = params;

  const response = await post<Waypoint>(`/planned-routes/${id}/waypoints`, rest);

  return response.data;
};

export const updatePlannedRouteWaypoint = async (
  params: PlannedRouteWaypointMutationRequestParams
) => {
  const { id, waypoint_id, ...rest } = params;

  const response = await put<Waypoint>(`/planned-routes/${id}/waypoints/${waypoint_id}`, rest);

  return response.data;
};

export const deletePlannedRouteWaypoint = async (
  params: PlannedRouteWaypointMutationRequestParams
) => {
  const { id, waypoint_id } = params;

  const response = await deleteRequest<Waypoint>(`/planned-routes/${id}/waypoints/${waypoint_id}`);

  return response.data;
};

type ImportCsvActionParams = {
  id: PlannedRouteId;
  file_id: string | number;
  timestamp: string;
};

export const importPlannedRouteWaypoints = async (params: ImportCsvActionParams) => {
  const { id, file_id, timestamp } = params;

  const response = await post<PlannedRouteWaypoints>(`/planned-routes/${id}/waypoints/import-csv`, {
    file_id,
    timestamp
  });

  return response.data;
};
