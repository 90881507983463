import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getApiBaseUrl } from 'utils/api';

const ImageViewer = ({ src, alt }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const previewImageBaseUrl = isOnBoard ? getApiBaseUrl() : '';

  return (
    <ImagePreview
      className="image-preview h-100p w-100p"
      src={previewImageBaseUrl + src}
      alt={alt}
    />
  );
};

const ImagePreview = styled.img`
  object-fit: cover;
  border-radius: 13px;
`;

ImageViewer.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default ImageViewer;
