import PropTypes from 'prop-types';

export const FindingOriginSource = PropTypes.oneOfType(['form_submission', 'event', null]);

export const Finding = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resolved_at: PropTypes.string,
  category: PropTypes.object,
  finding_parent_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  finding_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  origin_source: FindingOriginSource
});
