import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions, useUpdateEffect } from 'utils/hooks';
import _find from 'lodash/find';

import { strToNumber } from 'common/utils/numbers';
import * as reportActions from 'captain-reports/store/actions';
import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';

import {
  selectConsumptionFuelGrade,
  selectCaptainReportRobDifferences
} from 'captain-reports/store/selectors';

const CorrectionField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const fuel_grade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const robDifferenceData = useSelector(selectCaptainReportRobDifferences);

  const [setValue] = useActions([reportActions.setValue]);
  const fuelGradeDifference = _find(robDifferenceData, e => e.fuel_grade_id === fuel_grade.id);
  const autofillValue = fuelGradeDifference?.['rob_difference'];
  const autofillHfoValue = fuelGradeDifference?.['rob_difference_hfo'];
  const autofillLfoValue = fuelGradeDifference?.['rob_difference_lfo'];

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const correction_hfo = useValue({
    key: 'correction_hfo',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const correction_lfo = useValue({
    key: 'correction_lfo',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  useEffect(() => {
    let calculation = strToNumber(value);

    if ((autofillValue || autofillValue === 0) && value === null) {
      calculation = strToNumber(autofillValue);
    }

    setValue({
      key: field.key,
      value: calculation || null,
      sectionLabel: sectionLabel,
      sectionActiveTab: sectionActiveTab,
      subGroup: subGroup,
      subGroupIndex: subGroupIndex
    });
  }, []);

  useUpdateEffect(() => {
    if (correction_hfo === autofillHfoValue && correction_lfo === autofillLfoValue) return;
    const calculation = strToNumber(correction_lfo) + strToNumber(correction_hfo);

    if (calculation === value) return;

    setValue({
      key: field.key,
      value: calculation || null,
      sectionLabel: sectionLabel,
      sectionActiveTab: sectionActiveTab,
      subGroup: subGroup,
      subGroupIndex: subGroupIndex
    });
  }, [correction_lfo, correction_hfo]);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <NumberField
        field={field}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </div>
  );
};

export default CorrectionField;
