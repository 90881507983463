import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrayMove from 'array-move';

import { transformDataForSubmit } from 'common/components/jobs/_base/modules/checklist/helpers';
import Checklist from 'common/components/checklist';
import ShadowBox from 'common/components/general/ShadowBox';
import FormSaveActions from 'common/components/form/FormSaveActions';
import Display from './display';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import {
  selectAreChecklistActionsLocked,
  selectChecklistOptions,
  selectChecklistState
} from 'events/store/event-modules/checklist/selectors';
import { setChecklistOptions, setChecklistState } from 'events/store/event-modules/checklist/slice';
import {
  getChecklist,
  toggleChecklistOption,
  updateChecklist
} from 'events/store/event-modules/checklist/actions';

const Body = () => {
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();
  const checklistRef = useRef(null);

  const eventId = useSelector(selectEventId);
  const checklistOptions = useSelector(selectChecklistOptions);
  const checklistState = useSelector(selectChecklistState);
  const areChecklistActionsLocked = useSelector(selectAreChecklistActionsLocked);
  const isTemplate = useSelector(selectIsTemplate);

  const handleSort = (newIndex, oldIndex) => {
    const newChecklistOptions = arrayMove(checklistState, oldIndex, newIndex);
    const updatedState = newChecklistOptions.map((checklist, index) => ({
      ...checklist,
      sort_index: index
    }));

    dispatch(setChecklistState(updatedState));
  };

  const onCheck = async (check, index) => {
    if (isTemplate || !eventId) return;

    try {
      dispatch(
        setChecklistOptions(
          checklistOptions.map((c, i) => (i === index ? { ...c, checked: !c.checked } : c))
        )
      );

      await dispatch(
        toggleChecklistOption({ event_id: eventId, checklist_option_id: check.id })
      ).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const updateChecklistState = (val, mentions, index) => {
    const updatedState = checklistState.map((c, i) =>
      i === index ? { ...c, title: val, mentions } : c
    );

    dispatch(setChecklistState(updatedState));
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const params = {
        event_id: eventId,
        checklist_options: checklistState
          .filter(ch => ch.title)
          .map(v => {
            const par = transformDataForSubmit(v.title, v.mentions);

            return {
              title: par.text,
              mentions: par.mentions,
              id: v.id || undefined,
              sort_index: v.sort_index
            };
          })
      };

      await dispatch(updateChecklist(params)).unwrap();
      await dispatch(getChecklist({ event_id: eventId }));
      setEditMode(false);

      setSubmitting(false);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (checklistOptions.length) {
      dispatch(
        setChecklistState(
          checklistOptions.map(c => ({
            ...c,
            mentions: c?.mentions?.map(el => ({ id: `${el.type}---${el.id}`, display: el.name }))
          }))
        )
      );
    } else if (!isTemplate) {
      dispatch(setChecklistState([{ title: '', mentions: [] }]));
    }
  }, [checklistOptions, dispatch, isTemplate]);

  return (
    <ShadowBox
      color={editMode ? 'light-4' : 'white'}
      className="checklist-body position-relative pt-2 pb-2 ps-3 pe-5"
      flat={editMode ? 'false' : 'true'}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="job-checklist" ref={checklistRef} noGutters>
        <Checklist
          editMode={editMode}
          isEditMode={true}
          handleSort={handleSort}
          updateChecklistState={updateChecklistState}
          setChecklistState={params => dispatch(setChecklistState(params))}
          checklistState={checklistState}
          submitting={submitting}
          checklistOptions={checklistOptions}
          sectionId={eventId}
          checklistRef={checklistRef}
          isLocked={false}
          showEmptyState={true}
          onCheck={onCheck}
          showComments={!isTemplate && eventId}
          DisplayComponent={Display}
          Display={Display}
          hasActionButtons={false}
          placeholder="Add Option"
        />
      </div>

      {!areChecklistActionsLocked && (
        <FormSaveActions
          mode={!editMode ? 'view' : 'edit'}
          isHovering={isHovering}
          onEdit={() => setEditMode(true)}
          onSave={eventId ? () => onSubmit() : null}
          onCancel={() => {
            setEditMode(false);
            dispatch(
              setChecklistState(
                checklistOptions?.length > 0 ? checklistOptions : [{ title: '', mentions: [] }]
              )
            );
          }}
          style={{ right: -12, top: 12 }}
        />
      )}
    </ShadowBox>
  );
};

export default Body;
