import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';
import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';
import Select from 'captain-reports/templates/inputs/Select';
import UtcField from 'captain-reports/templates/components/custom-fields/UtcField';
import ReportMilesToGo from '@/captain-reports/templates/components/passage-plan/ReportMilesToGo';
import ReportEta from '@/captain-reports/templates/components/passage-plan/ReportEta';

import { timeZoneOptions } from 'common/utils/fixed';
import { validateTimeZone, validateTimeStamp } from './validations';

const config = () => [
  {
    fields: [
      {
        label: 'Date & Time',
        key: 'local_timestamp',
        validation: validateTimeStamp,
        render: DateTimeInput,
        width: { xs: 'auto' },
        className: 'mb-0'
      },
      {
        label: 'Timezone',
        key: 'timezone',
        width: { xs: 'auto' },
        sendOnlyValue: true,
        className: 'max-w-80 min-w-80 mb-0',
        validation: validateTimeZone,
        render: Select,
        previousReportInitializationKey: 'timezone',
        options: timeZoneOptions
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 1 },
        render: UtcField
      },
      {
        label: 'Next Port',
        key: 'next_port_id',
        width: { xs: 2 },
        className: 'mb-0',
        sendOnlyValue: true,
        previousReportInitializationKey: 'next_port',
        render: DynamicSelect,
        selectRest: { list: 'ports', isAsync: true, selectKey: 'next_port' }
      },
      {
        key: 'eta',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: ReportEta
      },
      {
        key: 'miles_to_go',
        sendOnlyValue: true,
        className: 'mb-0',
        width: { xs: 2 },
        render: ReportMilesToGo
      }
    ]
  }
];

const passagePlan = {
  noon: () => [],
  arrival: () => [],
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => [],
  port_noon: () => [],
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => config()
};

export default passagePlan;
