import React from 'react';
import ContentLoader from 'react-content-loader';

const HistoryLoader = () => {
  return (
    <div className="certificate-loader px-2 pb-1">
      <ContentLoader
        viewBox="0 0 100 2"
        backgroundColor="#f8f9fb"
        foregroundColor="#dadce4"
        title="Loading documents..."
        speed={2}
      >
        <rect x="0" y="0" rx="0.5" ry="0.5" width="100" height="2" />
      </ContentLoader>
    </div>
  );
};

export default HistoryLoader;
