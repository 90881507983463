import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const address = [
  {
    fields: [
      {
        label: 'ADDRESS',
        key: 'address',
        type: 'string',
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add address'
        }
      },
      {
        label: 'CITY',
        key: 'city',
        type: 'string',
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add city'
        }
      },

      {
        label: 'ZIP CODE',
        key: 'postal_code',
        type: 'string',
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add zip code'
        }
      },

      {
        label: 'COUNTRY',
        key: 'country.name',
        type: 'select',
        select_key: 'country',
        inputWidth: 5,
        inputProps: {
          placeholder: 'Select country',
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => option.name,
          defaultOptions: () => getInitialAsyncValues('countries'),
          loadOptions: search => getAsyncOptions(search, 'countries'),
          isClearable: true
        }
      },

      {
        label: 'STATE',
        key: 'state',
        type: 'string',
        inputWidth: 5,
        inputProps: {
          placeholder: 'Add state'
        }
      }
    ]
  }
];

export default address;
