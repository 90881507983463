import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _isArray from 'lodash/isArray';

import Select from 'common/components/form/inputs/Select';
import permissions from '@/common/utils/permissions/constants';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { selectAccount } from 'store/account/selectors';

import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';

const FormsSelector = ({
  filter,
  onChange,
  avoidPermissions = false,
  isMulti,
  listParams = {},
  ...rest
}) => {
  const [selectedForms, setSelectedForms] = useState([]);
  const account = useSelector(selectAccount);
  const permissionFormUids =
    !avoidPermissions &&
    getAuthorizedPermissionSettings(account, permissions.office.forms.view)?.forms;

  useFilterSelector(
    filter?.value,
    { list: 'forms' },
    { selected: selectedForms, setSelected: setSelectedForms },
    'uid'
  );

  return (
    <Select
      autoFocus
      placeholder="Select value"
      getOptionValue={option => option.uid}
      getOptionLabel={option => option.name}
      value={
        filter?.value
          ? _isArray(filter.value)
            ? selectedForms.filter(el => filter?.value.map(Number).includes(el.uid))
            : []
          : []
      }
      isMulti={isMulti}
      isAsync
      defaultOptions={() =>
        getInitialAsyncValues('forms', null, null, {
          ...listParams,
          uid: permissionFormUids || undefined
        })
      }
      loadOptions={search =>
        getAsyncOptions(search, 'forms', { ...listParams, uid: permissionFormUids || undefined })
      }
      onChange={(selected, option) => {
        setSelectedForms(selected);
        onChange(
          {
            value: selected ? (isMulti ? [...selected.map(s => s.uid)] : selected) : ''
          },
          option
        );
      }}
      {...rest}
    />
  );
};

export default FormsSelector;
