import React, { useState } from 'react';
import { Button } from 'reactstrap';
import Modal from 'common/components/modals/BasicModal';
import Textarea from 'common/components/form/inputs/Textarea';

const RemarksModal = ({ value, isOpen, onChange, toggle }) => {
  const [text, setText] = useState(value || '');
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header="Remarks"
      className="criteria-list--remarks-modal"
      footer={
        <>
          <Button color="cancel" onClick={toggle}>
            CANCEL
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onChange(text);
              toggle();
            }}
          >
            SAVE
          </Button>
        </>
      }
      body={
        <Textarea
          className="mb-0 ms-0 h-100p w-100p evaluation-input"
          white
          autoResize
          rows={5}
          placeholder="Add some remarks"
          value={text}
          onChange={e => setText(e.target.value)}
        />
      }
    />
  );
};

export default RemarksModal;
