import { Button } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';

import useTooltipID from 'common/utils/hooks/useTooltipID';

const RoundedButtonWithTooltip = ({
  children,
  placement,
  className,
  tooltipClassName,
  tooltipMessage,
  expandedText,
  ...restProps
}) => {
  const { avoidRender, tooltipID } = useTooltipID('rounded-button-with-tooltip');
  const { onClick, ...rest } = restProps;

  if (avoidRender) return null;

  return (
    <>
      <Button
        color="white"
        className={`d-flex align-items-center rounded-button-with-tooltip border-0  ${
          className || ''
        } ${
          !!expandedText ? 'rounded-button-with-tooltip--expanded min-width-fit' : 'rounded-circle'
        } ${onClick ? '' : 'rounded-button-with-tooltip--disable-click-btn'}`}
        id={tooltipID}
        onClick={onClick}
        {...rest}
      >
        {children}
        {expandedText}
      </Button>

      {tooltipMessage ? (
        <UncontrolledTooltip
          className={`tooltip-max-content ${tooltipClassName || ''}`}
          boundariesElement="window"
          placement={placement}
          target={tooltipID}
        >
          {tooltipMessage}
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

export default RoundedButtonWithTooltip;
