import { getAgents } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import ITINERARY_TYPES from '@/common/components/voyages/itinerary/store/types';

const INITIAL_STATE = {
  data: {},
  loading: false
};

const slice = createSlice({
  name: 'eventAgents',
  initialState: INITIAL_STATE,

  extraReducers: builder => {
    builder
      .addCase(getAgents.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getAgents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        return state;
      })

      .addCase(getAgents.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(`${ITINERARY_TYPES.EDIT_ITINERARY.SUCCESS}`, (state, { payload }) => {
        state.data = payload;

        return state;
      })

      .addCase(`${ITINERARY_TYPES.EVALUATE_ITINERARY_PORT_AGENT.SUCCESS}`, (state, { payload }) => {
        const agent = state.data?.agents?.find(ag => ag.id === payload.id);

        if (agent) {
          agent.combined_rating = payload.combined_rating;
          agent.evaluation = {};
          agent.evaluation.rating = payload.evaluation.rating;
        }
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default slice.reducer;
