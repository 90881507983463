import React, { useEffect } from 'react';

import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';

import * as reportActions from 'captain-reports/store/actions';

const InstructedEta = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [setValue] = useActions([reportActions.setValue]);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });
  const instructed_speed_consumption_target = useValue({
    key: 'instructed_speed_consumption_target',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    if (instructed_speed_consumption_target !== 'eta' && value) {
      setValue({
        key: field.key,
        value: null,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [instructed_speed_consumption_target]);

  return instructed_speed_consumption_target === 'eta' ? (
    <DateTimeInput
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  ) : null;
};

export default InstructedEta;
