import { FC } from 'react';

import CircledButton from '@/ts-common/components/buttons/CircledButton.tsx';
import OvalButton from 'common/components/buttons/OvalButton';
import AuthCheck from 'components/permissions/AuthCheck';
import { PermissionsType } from '@/common/types/permissions';

type Mode = 'view' | 'edit' | 'create';

interface ActiveTabViewActionsProps {
  mode: Mode;
  onEditClick: () => void;
  onCloseEditMode: () => void;
  onSaveForm: () => void;
  disabled: boolean;
  createPermissions?: PermissionsType;
  editPermissions?: PermissionsType;
  className?: string;
}

const ActiveTabViewActions: FC<ActiveTabViewActionsProps> = ({
  mode,
  onEditClick,
  onCloseEditMode,
  onSaveForm,
  disabled,
  createPermissions = [],
  editPermissions = [],
  className = ''
}) => {
  return (
    <div
      className={`vertical-tab-buttons vertical-tab-buttons__${mode} d-flex align-items-center ${
        disabled ? 'pointer-events-none' : ''
      } ${className}`}
    >
      {mode === 'view' ? (
        <AuthCheck permissions={editPermissions}>
          <OvalButton type="edit" onClick={onEditClick} disabled={false} />
        </AuthCheck>
      ) : (
        <AuthCheck permissions={mode === 'create' ? createPermissions : editPermissions}>
          <CircledButton onClick={onCloseEditMode} type={'close'} />

          <OvalButton
            type={`${mode === 'create' ? 'create' : 'save'}`}
            onClick={onSaveForm}
            disabled={disabled}
            className="ms-1"
          />
        </AuthCheck>
      )}
    </div>
  );
};

export default ActiveTabViewActions;
