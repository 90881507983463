import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';
import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';
import Select from 'captain-reports/templates/inputs/Select';
import UtcField from 'captain-reports/templates/components/custom-fields/UtcField';
import InstructedEta from 'captain-reports/templates/components/custom-fields/InstructedEta';

import { timeZoneOptions } from 'common/utils/fixed';
import { validateTimeZone, validateTimeStamp } from './validations';
import AllowanceUnit from 'captain-reports/templates/components/custom-fields/AllowanceUnit';
import AllowanceField from 'captain-reports/templates/components/custom-fields/AllowanceField';
import SpeedField from 'captain-reports/templates/components/custom-fields/SpeedField';
import FoConsField from 'captain-reports/templates/components/custom-fields/FoConsField';
import GoConsField from 'captain-reports/templates/components/custom-fields/GoConsField';

const config = () => [
  {
    fields: [
      {
        label: 'APPLICABLE SINCE',
        key: 'local_timestamp',
        validation: validateTimeStamp,
        render: DateTimeInput,
        width: { xs: 'auto' },
        templateValidation: { required: true }
      },
      {
        label: 'Timezone',
        key: 'timezone',
        width: { xs: 2 },
        validation: validateTimeZone,
        render: Select,
        sendOnlyValue: true,
        previousReportInitializationKey: 'timezone',
        options: timeZoneOptions,
        templateValidation: { required: true }
      },
      {
        label: 'UTC',
        key: 'timestamp',
        sendOnlyValue: true,
        className: 'mb-0 gmt-dropdown',
        width: { xs: 2 },
        render: UtcField,
        templateValidation: { required: true }
      }
    ]
  },
  {
    fields: [
      {
        label: 'INSTRUCTED BY',
        key: 'instructed_speed_consumption_by',
        width: { xs: 2 },
        className: ' max-width-182',
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-instructed-speed-consumption-by',
          isAsync: false
        }
      },
      {
        label: 'INSTRUCTIONS FOR',
        key: 'instructed_speed_consumption_target',
        width: { xs: 2 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-instructed-speed-consumption-target',
          isAsync: false
        }
      },
      {
        label: 'SPEED',
        key: 'instructed_speed',
        subLabel: 'kt',
        className: ' max-width-112',
        width: { xs: 'auto' },
        render: SpeedField,
        type: 'number'
      },
      {
        label: 'ALLOWANCE',
        key: 'instructed_speed_allowance',
        className: ' max-width-112',
        width: { xs: 'auto' },
        render: AllowanceField,
        type: 'number'
      },
      {
        label: '',
        key: 'instructed_speed_allowance_unit',
        className: ' max-width-112 align-self-end',
        width: { xs: 'auto' },
        render: AllowanceUnit
      },
      {
        label: 'FO CONS',
        key: 'instructed_fo_consumption',
        subLabel: 'mt/d',
        className: ' max-width-112',
        width: { xs: 'auto' },
        render: FoConsField,
        type: 'number'
      },
      {
        label: 'GO CONS',
        key: 'instructed_go_consumption',
        subLabel: 'mt/d',
        className: ' max-width-112',
        width: { xs: 'auto' },
        render: GoConsField,
        type: 'number'
      },
      {
        label: 'ALLOWANCE',
        key: 'instructed_consumption_allowance',
        className: ' max-width-112',
        width: { xs: 'auto' },
        render: AllowanceField,
        type: 'number'
      },
      {
        label: '',
        key: 'instructed_consumption_allowance_unit',
        className: ' max-width-112 align-self-end',
        width: { xs: 'auto' },
        render: AllowanceUnit
      },
      {
        label: 'TARGET ETA',
        key: 'instructed_eta',
        render: InstructedEta,
        width: { xs: 'auto' }
      }
    ]
  }
];

const instructions = {
  noon: () => [],
  arrival: () => [],
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => [],
  port_noon: () => [],
  stoppage: () => [],
  instructed_speed: () => config(),
  passage_plan: () => []
};

export default instructions;
