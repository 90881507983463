import { dateField, optionField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  category: optionField({ required: true }),
  issue_date: dateField({ required: true }),
  expiration_date: dateField(),
  range_from: dateField(),
  range_to: dateField(),
  postponed: dateField(),
  serial_number: stringField(),
  extension_requested: stringField(),
  completed_on: dateField(),
  flag_informed_on: dateField(),
  issued_by: stringField(),
  port: optionField(),
  comments: stringField(),
  attachments: optionField({ initialValue: [] })
};

export default config;
