import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import editEmpty from 'common/assets/svg/actions/edit-empty.svg';
import DigitalSignatureDrawer from './drawer';
import BoxShadowButton from 'common/components/buttons/BoxShadowButton';
import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';

const DigitalSignature = ({
  drawer,
  onCreate,
  count,
  onFetch,
  onFetchRequirements,
  onDelete,
  className,
  onClose,
  hasDrawer = true,
  viewOnly = false,
  refetchWrapper
}) => {
  return (
    <>
      <BoxShadowButton
        className={`d-inline-flex align-items-center border-1 ${className || ''}`}
        onClick={() => drawer.open()}
        size="sm"
        color="primary"
      >
        <SvgRender
          src={editEmpty}
          style={{ width: 12, height: 12 }}
          className="d-flex align-items-center justify-content-center cpb-4 border-bottom border-primary text-primary"
        ></SvgRender>

        {count ? <span className="fw-bold ms-1 cme-4 cps-4">{count}</span> : null}

        <span className={`${count ? '' : 'ms-1'} fw-medium`}>
          Sign{count && count > 1 ? 's' : ''}
        </span>
      </BoxShadowButton>

      {hasDrawer ? (
        <DigitalSignatureDrawer
          drawer={drawer}
          onCreate={onCreate}
          onFetch={onFetch}
          onFetchRequirements={onFetchRequirements}
          onDelete={onDelete}
          onClose={onClose}
          viewOnly={viewOnly}
          refetchWrapper={refetchWrapper}
        />
      ) : null}
    </>
  );
};

DigitalSignature.propTypes = {
  drawer: DrawerState,
  onCreate: PropTypes.func,
  count: PropTypes.number,
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
  onFetchRequirements: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  hasDrawer: PropTypes.bool,
  viewOnly: PropTypes.bool,
  refetchWrapper: PropTypes.func
};

export default DigitalSignature;
