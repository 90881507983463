import { hiddenField, stringField, utcDateTimeField } from 'common/utils/form/fieldTypes';

export default {
  contract_from_date: hiddenField(),
  contract_to_date: hiddenField(),
  sign_on_date_as_per_captain: utcDateTimeField(),
  sign_on_port_id_as_per_captain: stringField(),
  sign_off_date_as_per_captain: utcDateTimeField(),
  sign_off_port_id_as_per_captain: stringField()
};
