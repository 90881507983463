const paths = {
  landing: '/',
  login: '/login',
  logout: '/logout',
  not_found: '/404',
  unauthorized: '/unauthorized',

  jobs: '/jobs',
  regular_jobs: '/jobs/regular',
  flag_extensions_dispensations_jobs: '/jobs/flag-extension-dispensation',
  pms_jobs: '/pms-jobs/list',
  pms_jobs_label: '/pms-jobs',
  pms_jobs_per_system: '/pms-jobs/per-system',
  pms_running_hours: '/pms-running-hours',
  pms_inventory: '/pms-inventory',
  captain_reports: '/captain-reports',
  crew: '/crew',
  crew_evaluations: '/crew-evaluations',
  persons: '/persons',
  voyages_itinerary: '/voyages/itinerary',

  shifts: '/shifts',
  working_schedule: '/working-schedule',
  events: '/events',
  reports_event: '/events-reports',
  forms: '/forms',
  form_planning: '/form-planning',
  form_obligations: '/form-obligations',
  manuals: '/manuals',
  filesystem: '/filesystem',
  setup_onboard_filters: '/setup/onboard/filters',

  purchasing_requisitions: '/purchasing',
  purchasing_inventory: '/purchasing-inventory',
  purchasing_deliverables: '/purchasing-deliverables',

  vessels: '/vessel',

  mga: '/mga',
  findings: '/findings'
};

export default paths;
