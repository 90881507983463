import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import {
  selectPreviousCaptainReportValueByKey,
  selectFieldVisibility
} from 'captain-reports/store/selectors';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr } from 'common/utils/numbers';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import * as reportActions from 'captain-reports/store/actions';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const LubricantSoRobField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  tooltip
}) => {
  const [setValue] = useActions([reportActions.setValue]);
  const previousRob = useSelector(state => selectPreviousCaptainReportValueByKey(state, 'so_rob'));
  const isMeasuredSumpTankVisible = useSelector(state =>
    selectFieldVisibility(state, 'so_sump_tank_rob')
  );
  const isMeasuredRobVisible = useSelector(state =>
    selectFieldVisibility(state, 'so_rob_measured')
  );

  const value = useValue({
    key: field.key
  });

  useEffect(() => {
    if ((previousRob || previousRob === 0) && !isMeasuredRobVisible && !isMeasuredSumpTankVisible) {
      setValue({
        key: field.key,
        value: +previousRob,
        sectionLabel: sectionLabel,
        sectionActiveTab: sectionActiveTab,
        subGroup: subGroup,
        subGroupIndex: subGroupIndex
      });
    }
  }, [previousRob, isMeasuredSumpTankVisible, isMeasuredRobVisible]);

  return (
    <div>
      <div className="d-none">
        <NumberField
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </div>

      <FieldLabel
        info={tooltip}
        className="form-label text-violet fw-bold text-uppercase cmb-6 cmt-4 height-24"
        label={'PREVIOUS ROB'}
        subLabel={field.subLabel}
      />

      <div className={`fw-bold fs-12 lh-1 text-violet height-24 `}>
        {previousRob ? numberToStr(previousRob) : '-'}
      </div>

      <ValidationWrapper
        field={field}
        value={value}
        errorClassName="align-items-center"
        labelClassName="cmt-20 cpt-2"
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      >
        <div className="fs-12 fw-bold">{numberToStr(value) || '-'}</div>
      </ValidationWrapper>
    </div>
  );
};

export default LubricantSoRobField;
