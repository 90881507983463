import _get from 'lodash/get';
import { isValidElement } from 'react';
import { findStickyPosition } from 'common/components/table/utils/modular-table/helpers';
import { numberToStr } from 'common/utils/numbers';

const TotalsBar = ({ totals, hasTotals, columns, totalKeyLabel, totalKey }) => {
  return hasTotals ? (
    <div className="d-flex app-table--head-totals-bar">
      {columns
        .filter(col => !col.hidden)
        .map((col, index) => {
          if (col.columns?.length) {
            return col.columns.map((innerCol, innerIndex) => (
              <CellBar
                key={innerIndex}
                col={innerCol}
                index={innerIndex}
                columns={col.columns}
                isGroupedColumn={true}
                totals={totals}
                totalKeyLabel={totalKeyLabel}
                totalKey={totalKey}
              />
            ));
          } else {
            return (
              <CellBar
                key={index}
                col={col}
                index={index}
                columns={columns}
                totals={totals}
                totalKeyLabel={totalKeyLabel}
                totalKey={totalKey}
              />
            );
          }
        })}
    </div>
  ) : null;
};

const CellBar = ({
  col,
  index,
  columns,
  isGroupedColumn = false,
  totals,
  totalKeyLabel,
  totalKey
}) => {
  const totalValue = _get(totals, `${col?.[totalKey]}`, '');
  const stickyLeftAttribute = col.sticky
    ? col?.isRightSticky
      ? { right: findStickyPosition(col.key, columns, 0, true) }
      : { left: findStickyPosition(col.key, columns) }
    : {};
  const findTotalLabel = totalKeyLabel
    ? columns.find(e => e[totalKeyLabel])?.[totalKeyLabel]
    : null;

  return (
    <>
      <div
        className={`totals-bar-cell ${col.sticky ? 'sticky' : ''} ${
          col.totalsCellClassName || ''
        } ${isGroupedColumn && index === 0 ? 'totals-bar-cell__grouped-space' : ''}`}
        style={{
          maxWidth: col.maxWidth,
          minWidth: col.minWidth,
          ...stickyLeftAttribute
        }}
        data-type={col?.type || ''}
      >
        <div
          className={`totals-bar-cell__inner ${
            col.className ? col.className : col.headerClassName ? col.headerClassName : ''
          } w-100p ${col[`${totalKeyLabel}_color`] ? `text-${col[`${totalKeyLabel}_color`]}` : ''}`}
        >
          {index === 0 && !isGroupedColumn ? (
            <strong className="totals-bar-cell__label">{findTotalLabel || 'TOTALS'}</strong>
          ) : isValidElement(totalValue) ? (
            <div className="totals-bar-cell__value fw-bold">{totalValue}</div>
          ) : (
            <strong className="totals-bar-cell__value">
              {col?.type === 'price' || col?.type === 'double'
                ? numberToStr(totalValue, 2, 2)
                : totalValue}
              &nbsp;
            </strong>
          )}
        </div>
      </div>
      {/* {isGroupedColumn && index + 1 === columns.length ? (
        <div className="grouped-column-spacing"></div>
      ) : null} */}
    </>
  );
};

export default TotalsBar;
