import React from 'react';

import { numberToStr } from 'common/utils/numbers';

import PropTypes from 'prop-types';
import checkedIcon from 'common/assets/svg/common/check-circle.svg';
import SvgRender from 'common/components/general/SvgRender';

const TotalBar = ({
  totalAmount,
  paidAmount,
  currencyLabel,
  className,
  text,
  isTotalSum = false
}) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-between ${className} border-radius-base px-1 ${
        isTotalSum ? 'text-white' : 'text-primary'
      }`}
    >
      <div className="d-flex">
        <strong>{text}</strong>
        &nbsp;
        {currencyLabel}
      </div>
      <div className={`${isTotalSum ? 'text-white fs-16' : 'text-primary fs-12'}`}>
        <span className="fw-bold">{numberToStr(totalAmount, 2, 2)}</span>{' '}
        <span className={`${isTotalSum ? 'fw-300' : 'fw-medium'}`}>
          / {numberToStr(paidAmount, 2, 2)}
        </span>{' '}
        <SvgRender src={checkedIcon} style={{ width: 10, height: 10 }} />
      </div>
    </div>
  );
};

TotalBar.propTypes = {
  totalAmount: PropTypes.number,
  currencyLabel: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  isTotalSum: PropTypes.bool
};

export default TotalBar;
