import TYPES from './types';
import AUTH_TYPES from 'store/auth/types';
import getMenuItems from 'components/layouts/sidebar/panel/menu/_constants';
import paths from 'routing/routes/_paths';

const createItemSubmenuLabels = item => {
  return item?.submenu
    ? item.submenu
        .filter(s => s.path)
        .map(s => {
          if (s.groupPath) {
            return s.groupPath;
          }

          const paths = s.path?.split('/');

          return `/${paths?.[1]}${paths?.[2] ? `/${paths?.[2]}` : ''}`;
        })
    : item.label;
};

const INITIAL_STATE = {
  isPanelOpen: false,
  activePanel: null,
  isOtherMenuCollapse: false,
  menu: {
    items: getMenuItems(paths).map(i => {
      return {
        ...i,
        label: createItemSubmenuLabels(i)
      };
    })
  }
};

const reducer = function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case TYPES.TOGGLE_PANEL:
      const isPanelOpen =
        payload && payload !== state.activePanel && state.isPanelOpen ? true : !state.isPanelOpen;

      return {
        ...state,
        isPanelOpen,
        activePanel: !isPanelOpen ? null : payload
      };

    case TYPES.COLLAPSE_MENU:
      return {
        ...state,
        isOtherMenuCollapse: !state.isOtherMenuCollapse
      };

    case AUTH_TYPES.LOGOUT.SUCCESS:
    case AUTH_TYPES.LOGOUT.ERROR:
      return {
        ...state,
        isPanelOpen: false,
        activePanel: null
      };

    default:
      return state;
  }
};
export default reducer;
