import React from 'react';
import _get from 'lodash/get';

const Tag = props => {
  const { properties } = props;

  const name = _get(properties, 'object', 'None');

  return (
    <div className="d-flex align-items-center">
      <div className=" activity-tag" data-testid="job-activity-tag">
        {name}
      </div>
    </div>
  );
};

export default Tag;
