import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Collapse } from 'reactstrap';

import Checkbox from 'common/components/form/inputs/Checkbox';
import SvgRender from 'common/components/general/SvgRender';
import comments from 'common/assets/svg/common/comments.svg';
import commentsSolid from 'common/assets/svg/common/comments-solid.svg';
import { parseTextHtml } from 'common/components/jobs/_base/modules/checklist/helpers';
import Comments from '../comments';
import {
  selectAreChecklistActionsLocked,
  selectOpenLists
} from 'events/store/event-modules/checklist/selectors';
import { toggleCollapse } from 'events/store/event-modules/checklist/slice';

const Display = ({ disabled, state, onCheck, parentRef, sectionId, isEditMode, showComments }) => {
  const [hovered, setHovered] = useState(false);
  const [iconHovered, setIconHovered] = useState(false);

  const dispatch = useDispatch();

  const openLists = useSelector(selectOpenLists);
  const areChecklistActionsLocked = useSelector(selectAreChecklistActionsLocked);

  const { title, checked, id, comments_count, mentions } = state;
  const isOpen = openLists.includes(id);

  const toggle = () => {
    dispatch(toggleCollapse(id));
  };

  const createMarkup = () => {
    return {
      __html: parseTextHtml(title, mentions)
    };
  };

  const onMouseEnter = () => {
    if (areChecklistActionsLocked) return;

    setHovered(true);
  };
  const onMouseLeave = () => setHovered(false);

  const onMouseEnterIcon = () => setIconHovered(true);
  const onMouseLeaveIcon = () => setIconHovered(false);

  return (
    <div>
      <Row
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`g-0 display-row ${isOpen ? 'mb-1' : ''}`}
      >
        <Col xs={12}>
          <Row className="g-0 align-items-center">
            <Col xs={11} className="d-flex">
              <Checkbox
                className={`job-checklist-display__checkbox ${
                  areChecklistActionsLocked ? 'pointer-events-none' : ''
                }`}
                onChange={() => onCheck()}
                value={checked}
                disabled={disabled}
                color="green"
                outline
              />
              <div
                className="text-primary job-checklist-display__checkbox-text fs-12"
                dangerouslySetInnerHTML={createMarkup()}
              />
            </Col>
            {isEditMode && !disabled && showComments ? (
              <Col
                xs={1}
                onClick={toggle}
                className="d-flex align-items-center cursor-pointer justify-content-end comment-icon"
              >
                <div className="fs-14 fw-bold me-1">
                  {comments_count === 0 ? '' : comments_count}
                </div>
                {(comments_count > 0 || isOpen || hovered) && (
                  <SvgRender
                    onMouseEnter={onMouseEnterIcon}
                    onMouseLeave={onMouseLeaveIcon}
                    src={isOpen || iconHovered ? commentsSolid : comments}
                    style={{ width: 18, height: 18 }}
                  />
                )}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
      {isEditMode && !disabled ? (
        <Collapse isOpen={isOpen}>
          <Comments
            isOpen={isOpen}
            onClose={toggle}
            parentRef={parentRef}
            optionId={id}
            eventId={sectionId}
          />
        </Collapse>
      ) : null}
    </div>
  );
};

export default Display;
