import React from 'react';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const InfoModal = ({ ...rest }) => {
  return (
    <DangerousActionModal
      className={'transparent-info-modal'}
      hideCancelButton={true}
      footerClassName={`transparent-info-modal--round-button`}
      {...rest}
    />
  );
};

export default InfoModal;
