import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import { selectPreviousCaptainReportBallastWater } from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import * as reportActions from 'captain-reports/store/actions';

const BallastWaterField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [setValue] = useActions([reportActions.setValue]);
  const prevBallastWaterData = useSelector(selectPreviousCaptainReportBallastWater);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    const prevValue = prevBallastWaterData[subGroupIndex]?.ballast_water_amount?.value;

    if (
      prevBallastWaterData?.length &&
      (prevValue || prevValue === 0) &&
      value === null &&
      value !== prevValue
    ) {
      setValue({
        key: field.key,
        value: prevValue,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [prevBallastWaterData?.length, value]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default BallastWaterField;
