import { useMutation, useQueryClient } from '@tanstack/react-query';
import { submitVesselPmsAnnualReport } from './api';
import { successHandler } from '@/common/utils/notifications';
import { useAppDispatch } from '@/store/hooks';
import { VESSEL_PMS_ANNUAL_REPORT_INTEGRATION_STATUS } from '../reports/query-keys';

export const useVesselPmsAnnualSubmissionMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: submitVesselPmsAnnualReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [VESSEL_PMS_ANNUAL_REPORT_INTEGRATION_STATUS] });
      dispatch(successHandler({ title: 'Success!', message: 'Submitted successfully' }));
    }
  });
};
