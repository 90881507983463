import { get } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateTableRow } from 'store/tables/lists/actions';

export const getFormSubmissionWarnings = createAsyncThunk<any, any>(
  'GET_FORM_SUBMISSION_WARNINGS',
  async (params: any, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`/form-submissions/${id}/warnings`);

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getFormSubmissionSignatureRequirements = createAsyncThunk<any, any>(
  'GET_FORM_SUBMISSION_REQUIRED_SIGNATURES',
  async ({ id, ...params }, { rejectWithValue, dispatch }) => {
    try {
      const res = await get<any>(`/form-submissions/${id}/signature-requirements`, {
        is_pending: true,
        ...params
      });

      // Update the table pending_signature_requirements_count
      dispatch(
        updateTableRow({
          data: { id, pending_signature_requirements_count: res?.data?.length },
          table: 'form_submissions'
        })
      );

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
