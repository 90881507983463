import { useSelector } from 'react-redux';
import {
  selectChapterSpecificVessels,
  selectChapterSpecificVesselGroups
} from 'manuals/store/selectors';

const SpecificFor = () => {
  const chapterVessels = useSelector(selectChapterSpecificVessels);
  const chapterVesselGroups = useSelector(selectChapterSpecificVesselGroups);

  const specificFor = [...chapterVessels, ...chapterVesselGroups];

  if (specificFor.length) {
    return (
      <div className="text-violet mt-1">
        Chapter specific for: <strong>{specificFor.map(v => v.name).join(', ')}</strong>
      </div>
    );
  }

  return null;
};

export default SpecificFor;
