import React from 'react';

import { Row, Col } from 'reactstrap';
import AnalysisTable from './AnalysisTable';
import HowToAssess from './HowToAssess';
import RiskRating from './RiskRating';

const RiskAssessmentInfo = ({ previewMode }) => {
  return (
    <Row noGutters className="mt-2">
      <Col xs={5} className="pe-1">
        <AnalysisTable previewMode={previewMode} />
      </Col>
      <Col xs={2}>
        <RiskRating previewMode={previewMode} />
      </Col>
      <Col xs={5} className="ps-1">
        <HowToAssess previewMode={previewMode} />
      </Col>
    </Row>
  );
};

export default RiskAssessmentInfo;

//5 4 3
