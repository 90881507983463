import { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';

import { selectCurrencies } from '@/common/components/mga/store/selectors-ts';
import { MgaCurrencyType } from '@/common/types/mga.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useForm } from 'utils/hooks';
import { updateMgaCurrencies } from '@/common/components/mga/store/actions-ts';
import BarLabel from '../components/BarLabel';
import Container from './Container';
import config, { FormConfigType } from './config';

const Currencies = () => {
  const [shouldHideCurrencies, setShouldHideCurrencies] = useState<boolean>(false);
  const currencies = useAppSelector<MgaCurrencyType[]>(selectCurrencies);
  const dispatch = useAppDispatch();
  const currenciesWithoutBase = useMemo(
    () => currencies.filter(currency => !currency?.isBase),
    [currencies]
  );
  const { formState, collectValues, loadValues, resetForm, hasErrors } = useForm(config);

  const initForm = useCallback(() => {
    loadValues({ extra: currenciesWithoutBase.map(e => ({ currency: e })) });
  }, [currenciesWithoutBase, loadValues]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSave = async () => {
    const values = collectValues();

    if (!values || hasErrors) return;
    const extraData = values as FormConfigType;

    const extra_currency_ids = extraData.extra.map(e => e.currency?.id).filter(e => e);

    try {
      await dispatch(updateMgaCurrencies({ extra_currency_ids })).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mb-4 cpb-2">
      <BarLabel title="CURRENCY" />

      {currencies.map(currency => (
        <Container
          key={currency?.id}
          resetForm={resetForm}
          hideRestCurrencies={shouldHideCurrencies}
          setShouldHideCurrencies={setShouldHideCurrencies}
          currency={currency}
          onSave={onSave}
          formState={formState}
          initForm={initForm}
        />
      ))}
    </div>
  );
};

export default Currencies;
