import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createParticipant = createAsyncThunk(
  'CREATE_PARTICIPANT',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/events/${id}/participants`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getParticipants = createAsyncThunk(
  'GET_PARTICIPANTS',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await get(`/events/${id}/participants`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteParticipant = createAsyncThunk(
  'DELETE_PARTICIPANT',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, participant_id, ...rest } = params;

    try {
      const res = await deleteRequest(`/events/${id}/participants/${participant_id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editParticipant = createAsyncThunk(
  'EDIT_PARTICIPANT',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, participant_id, ...rest } = params;

    try {
      const res = await put(`/events/${id}/participants/${participant_id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
