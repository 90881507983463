import { useSelector } from 'react-redux';
import TotalsBar from 'common/components/table/main/TotalsBar';

import { isTotalsBarEnabled } from 'common/components/table/utils/modular-table/helpers';

import { selectTableListTotals } from 'common/components/table/store/selectors';

const TotalsBarWrapper = ({
  columns = [],
  label,
  totalBaseKey,
  isSolid,
  className,
  state,
  isModularTableShownInWidget
}) => {
  const totals = useSelector(state => selectTableListTotals(state, label));

  const hasTotals = isTotalsBarEnabled(columns, `${totalBaseKey}_key`);

  if (!hasTotals) return null;

  return (
    <div
      className={`app-table--totals-wrap ${isSolid ? '' : 'bordered-totals-bar-cell '} ${
        className || ''
      } ${totalBaseKey}`}
    >
      <TotalsBar
        hasTotals={hasTotals}
        totals={isModularTableShownInWidget ? state?.totals : totals}
        columns={columns}
        totalKeyLabel={`${totalBaseKey}_label`}
        totalKey={`${totalBaseKey}_key`}
      />
    </div>
  );
};

export default TotalsBarWrapper;
