import { getLinkedPurchaseCases } from './actions';
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  cases: []
};

const linkedPurchaseCasesSlice = createSlice({
  name: 'linkedPurchaseCases',
  initialState: INITIAL_STATE,
  extraReducers: builder => {
    builder

      .addCase(getLinkedPurchaseCases.fulfilled, (state, { payload }) => {
        state.cases = payload;
        return state;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default linkedPurchaseCasesSlice.reducer;
