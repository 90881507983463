import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import DaysAndHoliday from 'crew/profile/main/contracts/preview/parts/working-hours/DaysAndHoliday';
import Overtime from 'crew/profile/main/contracts/preview/parts/working-hours/Overtime';

const WorkingHours = ({ workingHours }) => {
  const { overtime, default_overtime, ...rest } = workingHours;

  return (
    <Row className="cmb-12 gx-1">
      <Col>
        <DaysAndHoliday data={rest} />
      </Col>

      <Col className="min-width-150 max-width-150">
        <Overtime overtime={overtime} defaultOvertime={default_overtime} />
      </Col>
    </Row>
  );
};

export default WorkingHours;

WorkingHours.propTypes = {
  workingHours: PropTypes.shape({
    monday: PropTypes.string,
    friday: PropTypes.string,
    holiday: PropTypes.string,
    overtime: PropTypes.string,
    saturday: PropTypes.string,
    sunday: PropTypes.string,
    thursday: PropTypes.string,
    tuesday: PropTypes.string,
    wednesday: PropTypes.string,
    default_monday: PropTypes.string,
    default_friday: PropTypes.string,
    default_holiday: PropTypes.string,
    default_overtime: PropTypes.string,
    default_saturday: PropTypes.string,
    default_sunday: PropTypes.string,
    default_thursday: PropTypes.string,
    default_tuesday: PropTypes.string,
    default_wednesday: PropTypes.string
  })
};
