import { Row, Col } from 'reactstrap';

import { numberToStr } from 'common/utils/numbers';
import { download } from 'utils/api';

import TicketTypeLine from 'common/components/crew/TicketTypeLine';
import UploadFiles from 'common/components/form/inputs/upload-files';

import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

const Field = ({ unit, label, path, data, number }) => {
  const value = _get(data, path, null) || null;

  const getValue = () => {
    if (!value) return '-';

    if (number) {
      return numberToStr(value);
    }

    return value;
  };

  return (
    <div className="d-flex flex-column mb-1 text-primary">
      <div className="form-label text-uppercase fw-normal fs-10 lh-1 mb-1">{label}</div>
      <div className="fs-12">
        {unit}
        <strong>{getValue()}</strong>
      </div>
    </div>
  );
};

const Preview = ({ ticket }) => {
  return (
    <div className="crew-profile__contracts-tickets-preview">
      <div className="crew-profile__contracts-tickets-preview-body">
        <div className="ticket-info">
          <Row className="flex-nowrap align-items-center w-100p" noGutters>
            <Col xs={5} className="ticket-info__routes">
              {_orderBy(ticket.routes, ['accepted', 'routes[0].departure'], ['desc', 'asc']).map(
                route => (
                  <Row key={route.id} className="ticket-info__offer align-items-center">
                    <Col xs="auto" className="mb-1">
                      <TicketTypeLine
                        type={ticket.transportation_type}
                        style={{ width: 20, height: 20 }}
                      />
                    </Col>

                    {ticket.transportation_type === 'air' ? (
                      <>
                        <Col xs={4}>
                          <Field label={'AIRLINE'} path={'airline.name'} data={route} />
                        </Col>
                        <Col>
                          <Field label={'FLIGHT NO.'} path={'itinerary_no'} data={route} />
                        </Col>
                        <Col>
                          <Field label={'Ticket No.'} path={'ticket_no'} data={route} />
                        </Col>
                      </>
                    ) : (
                      <Col>
                        <Field label={'Ticket No.'} path={'ticket_no'} data={route} />
                      </Col>
                    )}
                  </Row>
                )
              )}
            </Col>
            <Col xs={2}>
              <Field label={'AGENT'} path={'agent.full_name'} data={ticket} />
            </Col>
            <Col xs={1} className="d-flex align-items-center">
              <Field
                unit={<strong>{_get(ticket, 'currency.symbol', '')}&nbsp;</strong>}
                label={'INITIAL PRICE'}
                path={'initial_price'}
                data={ticket}
              />
            </Col>
            <Col xs={1}>
              <Field label={'DISC %'} path={'discount'} data={ticket} />
            </Col>
            <Col xs={1}>
              <Field label={'TAX %'} path={'tax'} data={ticket} />
            </Col>
            <Col xs={2} className="d-flex justify-content-end pe-1">
              <div className="ticket-info__price">
                <span className="fs-10 lh-1">PAYABLE PRICE:&nbsp;</span>
                <strong className="fs-12 lh-1">
                  {_get(ticket, 'currency.symbol', '')}&nbsp;
                  {numberToStr(_get(ticket, 'payable_price', ''))}
                </strong>
              </div>
            </Col>
          </Row>
          <UploadFiles
            upload={() => {}}
            download={download}
            className={`square-upload mb-0`}
            group="crew.files"
            files={ticket.document ? [ticket.document] : []}
            onChange={() => {}}
            uploadText="Upload doc"
            viewOnly={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
