import PropTypes from 'prop-types';
import { RankType } from 'common/entities/crew/RankTypes';
import { VesselType } from 'common/entities/vessels/VesselTypes';
import { File } from 'common/entities/files/FileTypes';
import { CrewMemberPropTypes } from 'common/entities/crew/MemberTypes';

export const ExtensionPropTypes = PropTypes.shape({
  crew_contract_id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  sort_index: PropTypes.number.isRequired
});

export const ContractType = PropTypes.shape({
  id: PropTypes.number,
  crew_member_id: PropTypes.number,
  vessel_id: PropTypes.number.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contract_from_date: PropTypes.string,
  attachments: PropTypes.arrayOf(File),
  wage_account_amounts: PropTypes.array,
  extensions: PropTypes.arrayOf(ExtensionPropTypes),
  crew_member: CrewMemberPropTypes,
  vessel: VesselType,
  rank: RankType
});
