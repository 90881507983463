import { getFileBaseUrl } from '@/api/files/api';
import { Attachment } from '@/ts-common/types/files';
import { imageUrlSelector } from '@/ts-common/utils/image-size';
import { FC } from 'react';

type ImageProps = {
  file: Attachment;
};

const Image: FC<ImageProps> = ({ file }) => {
  const previewImageBaseUrl = getFileBaseUrl();

  return (
    <div
      className="gallery__footer--item-image"
      style={{
        backgroundImage: file
          ? `url(${previewImageBaseUrl + imageUrlSelector({ file, label: 'gallery_small' })})`
          : ''
      }}
    />
  );
};

export default Image;
