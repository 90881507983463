import { FC, useState, useMemo } from 'react';
import _isArray from 'lodash/isArray';

import Select from '@/ts-common/components/form/inputs/select/index.tsx';
import { TableFilterSelectorProps } from '@/common/types/front-entities/table.ts';
import { getFilterSelectorValues } from '../filters/helpers';

import useFilterSelector from '@/common/components/filters/useFilterSelector.js';

type OptionType = {
  label: string;
  name: string;
};

const DeliveryProcessingSelector: FC<TableFilterSelectorProps> = ({
  filter,
  value,
  onChange,
  isMulti,
  listParams,
  className,
  ...rest
}) => {
  const [selectedProcessing, setSelectedProcessing] = useState<OptionType[] | []>([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'delivery-processing' },
    { selected: selectedProcessing, setSelected: setSelectedProcessing },
    'label'
  );

  const memoizedRequestParams = useMemo(
    () => ({
      params: { ...(listParams || {}), list: 'delivery-processing' },
      path: 'lists'
    }),
    [listParams]
  );

  return (
    <Select
      placeholder="Select value"
      getOptionValue={option => option.label}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selectedProcessing, filter?.value, isMulti, 'label')
          : []
      }
      memoizedRequestParams={memoizedRequestParams}
      onChange={(s: OptionType | OptionType[] | null) => {
        setSelectedProcessing(isMulti && !s ? [] : (s as OptionType[]));
        onChange(
          filter?.value !== undefined ? { value: _isArray(s) ? s?.map(s => s.label) : s } : s
        );
      }}
      {...rest}
    />
  );
};

export default DeliveryProcessingSelector;
