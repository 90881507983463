import { useCallback } from 'react';

import arrows from 'common/assets/svg/table/sorting.svg';
import SvgRender from 'common/components/general/SvgRender';
import Information from 'common/components/general/Information';

const HeaderCell = ({ col, sorting, onSort, style, isLastSticky, isGroup, groupExists }) => {
  const canSort = col.sort;

  const getClassNames = useCallback((column, sortingState) => {
    let classNames = [];

    classNames.push(`col${column.width ? `-${column.width}` : ''}`);

    const columnSortingKey = column.sortingKey || column.key;

    if (sortingState && sortingState[columnSortingKey]) {
      if (sortingState[columnSortingKey] === 'asc') classNames.push('sorting-asc');
      else classNames.push('sorting-desc');
    }

    if (column.sticky) classNames.push('sticky');
    if (isLastSticky) classNames.push('last-sticky');

    if (column.key === 'actions') classNames.push('actions-sticky');
    if (column.field === 'attachments') classNames.push('attachments-cell', 'col-auto');

    if (isGroup) classNames.push('header-group-cell', 'text-violet', 'bg-base');
    if (groupExists) classNames.push('pt-1', 'pb-0', 'align-items-center');

    return classNames.join(' ');
  }, []);

  if (col.hidden) return null;

  return (
    <div
      onClick={e =>
        canSort ? onSort({ ...col, key: col.sortingKey || col.key }, e.metaKey || e.ctrlKey) : null
      }
      className={`cell ${getClassNames(col, sorting)} ${
        col.className ? col.className : col.headerClassName ? col.headerClassName : ''
      }`}
      style={style ? style : null}
      data-type={col?.type || ''}
      data-key={col.key}
    >
      <div className={`cell--inner ${groupExists ? 'align-items-end pb-1' : ''}  `}>
        {col.header}

        {col.rightHeader ? (
          <div className="text-violet fs-10 right-header">{col.rightHeader}</div>
        ) : null}

        {col.info ? (
          <Information
            message={col.info}
            className="cell--info"
            tooltipClassname={col?.infoTooltipClassname}
            tooltipOffset={col?.infoTooltipOffset}
            svgStyle={{ height: 12, width: 12 }}
            target={`cell--info-${col.key}`}
          />
        ) : null}
        {canSort ? (
          <SvgRender
            className={`pointer-events-none sorting-arrows ${isGroup ? 'text-violet' : ''}`}
            src={arrows}
            style={{ height: 12, width: 10 }}
          />
        ) : null}
      </div>

      {col.bottomHeader ? col.bottomHeader : null}
    </div>
  );
};

export default HeaderCell;
