import moment from 'moment';
import _get from 'lodash/get';

import TYPES from './types';

import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  isFetching: false,
  data: [],
  paging: {
    current_page: 1,
    last_page: 1,
    per_page: 20,
    total: 1
  },
  from: moment().startOf('year').format('YYYY-MM-DD'),
  to: moment().endOf('year').format('YYYY-MM-DD'),
  inAlarm: '',
  statuses: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_FORM_PLANNING_FROM:
      return { ...state, from: payload };

    case TYPES.SET_FORM_PLANNING_TO:
      return { ...state, to: payload };

    case TYPES.SET_FORM_PLANNING_IN_ALARM:
      return { ...state, inAlarm: payload };

    case TYPES.SET_FORM_PLANNING_STATUSES:
      return { ...state, statuses: payload };

    case TYPES.GET_FORM_PLANNINGS.START:
      return { ...state, isFetching: true };

    case TYPES.UPDATE_FORM_PLANNING_SUBMISSION:
      const mutatedFormSubmissions =
        state.data?.map(outer =>
          outer.form_id === payload.form_id
            ? {
                ...outer,
                form_submissions: outer.form_submissions?.map(inner => {
                  return inner.id === payload.id
                    ? { ...inner, status: payload.form_status }
                    : inner;
                })
              }
            : outer
        ) || [];

    case TYPES.UPDATE_FORM_PLANNING_FORM_SUBMISSION_STATUS_TABLE_ROW:
      const formattedRows =
        state.data?.map(outer => {
          if (outer.id === payload.id) {
            return {
              ...outer,
              form_submissions: outer.form_submissions?.map(inner => {
                return inner.id === payload.form_id
                  ? { ...inner, status: payload.form_status }
                  : inner;
              })
            };
          } else {
            return outer;
          }
        }) || [];

      return {
        ...state,
        data: formattedRows
      };

    case TYPES.GET_FORM_PLANNINGS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: _get(payload, 'data', payload || []),
        paging: { ...state.paging, ...payload.meta }
      };

    case TYPES.GET_FORM_PLANNINGS.ERROR:
      return { ...state, isFetching: true };

    case LOCATION_CHANGE:
      if (payload.location.pathname.includes('form-planning')) return state;
      return {
        ...state,
        statuses: [],
        inAlarm: '',
        from: moment().startOf('year').format('YYYY-MM-DD'),
        to: moment().endOf('year').format('YYYY-MM-DD')
      };

    default:
      return state;
  }
};

export default reducer;
