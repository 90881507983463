import React, { useState } from 'react';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import deleteSvg from 'common/assets/svg/actions/delete.svg';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const FormDeleteButton = ({ deleteForm }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <Button
        className="d-inline-flex ps-4 fw-bold align-items-center fs-14 cursor-pointer text-red shadow-none me-auto"
        color={'link'}
        outline={false}
        onClick={() => setShowDeleteModal(true)}
      >
        <SvgRender src={deleteSvg} style={{ width: 14, height: 16 }} />
        <span className="ms-1 ">Delete</span>
      </Button>
      <DangerousActionModal
        transparent
        action={'deleteForm'}
        onAccept={deleteForm}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText={'DELETE'}
        header={'Delete'}
        body={'Are you sure you want to delete this form?'}
      />
    </>
  );
};

export default FormDeleteButton;
