import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api';

export const getAgents = createAsyncThunk('GET_AGENTS', async (params, { rejectWithValue }) => {
  const { id } = params;

  try {
    const res = await get(`/events/${id}/agents`);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
