import FuelTanks from 'common/components/vessels/profile/tabs/info/components/fuel-tanks/FuelTanks';
import _get from 'lodash/get';
import { strToNumber } from 'common/utils/numbers';

const ballastTanks = [
  {
    customComponent: FuelTanks,
    fields: [
      {
        label: 'VESSEL HAS SCRUBBERS',
        key: 'has_scrubbers',
        type: 'boolean',
        inputProps: {
          size: 'sm'
        }
      },
      {
        label: 'MANIFOLD HEIGHT - BALLAST (m)',
        key: 'manifold_height_ballast',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'MANIFOLD HEIGHT - LADEN (m)',
        key: 'manifold_height_laden',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'FO MANIFOLD HOSE DIAMETER (inch)',
        key: 'fo_hose_inch',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'FO MANIFOLD MAX RATE (mt/h)',
        key: 'fo_rate',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'FO MANIFOLD LOCATION',
        key: 'fo_location'
      },
      {
        label: 'GO MANIFOLD HOSE DIAMETER (inch)',
        key: 'go_hose_inch',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'GO MANIFOLD RATE (mt/h)',
        key: 'go_rate',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'GO MANIFOLD LOCATION',
        key: 'go_location'
      },
      {
        key: 'fuel_tanks',
        type: 'multiple',
        data: [
          {
            sort_index: 0,
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            sort_index: 1,
            label: 'TANK NAME',
            type: 'string',
            key: 'name',
            validations: { required: true }
          },
          {
            sort_index: 2,
            label: 'TANK TYPE',
            key: 'type.name',
            type: 'select',
            select_key: 'type_id',
            validations: { required: false }
          },
          {
            sort_index: 3,
            label: 'FUEL CATEGORY',
            key: 'is_fo',
            fieldLabel: 'FO Tank',
            type: 'boolean',
            validations: {
              initialValue: false
            }
          },
          {
            sort_index: 4,
            label: 'FUEL CATEGORY',
            key: 'is_go',
            fieldLabel: 'GO Tank',
            type: 'boolean',
            validations: {
              initialValue: false
            }
          },
          { sort_index: 5, label: 'LOCATION', key: 'location', validations: { required: false } },
          {
            sort_index: 6,
            label: 'CAPACITY @ 100% (m³)',
            key: 'capacity',
            type: 'number',
            validations: { required: true }
          },
          {
            sort_index: 7,
            label: 'MAX FILL (%)',
            key: 'max_fill',
            type: 'number',
            validations: { required: true }
          },
          {
            sort_index: 8,
            label: 'ALLOWED MAX CAPACITY (m³)',
            type: 'number',
            key: 'max_capacity',
            render: (profile, findex) =>
              (strToNumber(_get(profile, `fuel_tanks[${findex}].capacity`, 0)) *
                strToNumber(_get(profile, `fuel_tanks[${findex}].max_fill`, 0))) /
              100
          }
        ]
      }
    ]
  }
];

export default ballastTanks;
