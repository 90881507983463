import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectCrewProfile } from 'crew/store/profile/selectors';

import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';

import facebook from 'common/assets/svg/socials/facebook.svg';
import linkedin from 'common/assets/svg/socials/linkedin.svg';
import skype from 'common/assets/svg/socials/skype.svg';
import x from 'common/assets/svg/socials/x.svg';

export const socials = [
  { icon: skype, field: 'skype_id', label: ' Skype' },
  { icon: x, field: 'twitter', label: 'X' },
  { icon: facebook, field: 'facebook', label: 'Facebook' },
  { icon: linkedin, field: 'linkedin', label: 'Linkedin' }
];

export const SocialRow = ({ social, children, isFirst, isLast }) => {
  return (
    <Row
      className={`align-items-center crew-digital-communication__row fs-12 ${
        isFirst ? 'first' : isLast ? 'last' : ''
      }`}
      noGutters
    >
      <Col className="d-flex col-auto col-hd-3">
        <div className="me-1 me-hd-3 crew-digital-communication__row-icon d-inline-flex align-items-center justify-content-center">
          <SvgRender src={social.icon} style={{ width: 18, height: 18 }} className="text-purple" />
        </div>

        <div className="crew-digital-communication__row-label flex-1 d-none d-hd-flex align-items-center">
          <strong>{social.label}</strong>
        </div>
      </Col>
      <Col className="ps-1 ps-hd-3 crew-digital-communication__row-link">{children}</Col>
    </Row>
  );
};

const Preview = () => {
  const crew = useSelector(selectCrewProfile);

  return socials.map((social, index) => (
    <SocialRow
      social={social}
      key={social.field}
      isFirst={index === 0}
      isLast={index === socials.length - 1}
    >
      {social.field === 'email' ? (
        <a
          href={`mailto:${crew[social.field]}`}
          target="_blank"
          rel="noopener noreferrer"
          className="lh-1"
        >
          {crew[social.field] || '-'}
        </a>
      ) : (
        <span>{crew[social.field] || '-'}</span>
      )}
    </SocialRow>
  ));
};

const DigitalCommunication = () => {
  return (
    <Row noGutters>
      <Col xs={11} className="pe-3">
        <div className={`crew-digital-communication`}>
          <ShadowBox
            className="crew-digital-communication__box p-2 h-100p d-flex flex-column justify-content-center"
            color="light-1"
            flat
          >
            <Preview />
          </ShadowBox>
        </div>
      </Col>
    </Row>
  );
};

export default DigitalCommunication;
