import React from 'react';

import { useSelector } from 'react-redux';
import { selectLatestPeriod, selectVesselFilter } from 'common/components/mga/store/selectors';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import moment from 'moment';

const ReportTitle = () => {
  const selectedVesselId = useSelector(selectVesselFilter);
  const latestPeriod = useSelector(selectLatestPeriod);
  const vessels = useSelector(state => selectListOptionsFromStore(state, 'vessels'));
  const selectedVessel = vessels?.find(v => v.id === selectedVesselId);
  const isOnBoard = useSelector(state => state.isOnBoard);

  if (!selectedVesselId || !latestPeriod?.id) return null;

  return (
    <div className="d-flex justify-content-center mt-1">
      <div className="d-flex flex-column align-items-center cmt-6">
        <strong className="text-primary fs-18">Balance Sheet</strong>
        <span className="fw-medium fs-12 text-primary">
          {!isOnBoard ? `${selectedVessel.name} -` : ''} Period:{' '}
          {moment(latestPeriod.started_at).format('DD/MM/YYYY')} -{' '}
          {moment(latestPeriod.ended_at).format('DD/MM/YYYY')}
        </span>
      </div>
    </div>
  );
};

export default ReportTitle;
