import React from 'react';
import Priority from 'common/components/general/Priority';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import _get from 'lodash/get';

const ImportanceChange = props => {
  const { properties } = props;

  const oldImportance = _get(properties, 'old.priority_id', 'None');
  const newImportance = _get(properties, 'attributes.priority_id', 'None');

  return (
    <div className="d-flex align-items-center">
      <Priority size="large" labelClassName="fw-light" withText value={oldImportance} />
      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <Priority size="large" labelClassName="fw-light" withText value={newImportance} />
    </div>
  );
};

export default ImportanceChange;
