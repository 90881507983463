import { FC, useCallback, useMemo, useState } from 'react';
import useRouter from 'use-react-router';
import clsx from 'clsx';

import TableTopFilter from 'common/components/table/TableTopFilter';
import { DropdownIndicator } from 'common/components/form/inputs/Select';

import config from '@/common/components/shared-table-configs/index.ts';
import Select from '@/ts-common/components/form/inputs/select';
import { parseQueryParams } from 'utils/urls';
import { createLink } from './helpers';
import { OnboardSetupFilter } from '@/common/types/onboard-setup.ts';

type SavedSearchesSelectorProps = {
  label: keyof typeof config;
};

const SavedSearchesSelector: FC<SavedSearchesSelectorProps> = ({ label }) => {
  const { history, location } = useRouter();
  const { searchId } = parseQueryParams(history.location.search);
  const entity_type = label ? config[label]?.entity : null;
  const [hasData, setHasData] = useState(false);

  const onChange = useCallback(
    (item: OnboardSetupFilter) => {
      if (!item) {
        history.replace(location.pathname);
      } else {
        const location = createLink({ ...item, type: label });

        history.replace(location);
      }
    },
    [history, label, location.pathname]
  );

  const memoizedRequestParams = useMemo(
    () => ({
      params: {
        filters: [{ name: 'entity_type', operation: '=', value: entity_type || undefined }]
      },
      path: 'onboard-setup/filter-presets'
    }),
    [entity_type]
  );

  if (!entity_type) return null;

  return (
    <div className={clsx('ps-3', 'ms-3', 'border-start', { 'd-none': !hasData })}>
      <TableTopFilter>
        <Select
          placeholder="Select saved filter"
          getOptionValue={option => option.id.toString()}
          getOptionLabel={option => option.name}
          isAsync
          isClearable
          isMulti={false}
          value={searchId}
          memoizedRequestParams={memoizedRequestParams}
          onChange={onChange}
          size="lg"
          parseOptions={e => {
            setHasData(!!e.length);
            return e;
          }}
          key={label}
          hideSelectedOptions
          components={{
            DropdownIndicator: DropdownIndicator
          }}
        />
      </TableTopFilter>
    </div>
  );
};

export default SavedSearchesSelector;
