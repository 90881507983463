import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const LabelWithTooltip = ({ id, text, showTooltip = true, renderTooltipContent, ...rest }) => {
  return (
    <>
      <div
        id={`share-${id}`}
        className="d-flex align-items-center justify-content-center cursor-pointer fs-10 text-primary shared-dashboard-label"
        {...rest}
      >
        {text}
      </div>
      {showTooltip && (
        <UncontrolledTooltip placement="top-end" boundariesElement="window" target={`share-${id}`}>
          {renderTooltipContent()}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default LabelWithTooltip;
