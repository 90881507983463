import React, { useState } from 'react';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import Input from 'common/components/jobs/_base/inputs/MentionInput';
import SvgRender from 'common/components/general/SvgRender';
import add from 'common/assets/svg/actions/add.svg';
import { useActions } from 'utils/hooks';
import * as jobChecklistActions from 'common/components/jobs/_base/modules/checklist/store/actions';
import { transformDataForSubmit } from '../helpers';

const NewComment = ({ parentRef, jobId, optionId }) => {
  const [newMessage, setNewMessage] = useState('');
  const [showNewComment, setShowNewComment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [newMentions, setNewMentions] = useState('');

  const [createComment] = useActions([jobChecklistActions.createComment]);

  const onInputChange = (e, newValue, newPlainText, mentions) => {
    setNewMentions(mentions);
    setNewMessage(e.target.value);
  };

  const onNewMessage = async () => {
    if (newMessage === '') return;
    setLoading(true);
    let params = transformDataForSubmit(newMessage, newMentions);

    const res = await createComment({ job_id: jobId, option: optionId, ...params });

    if (res && res.id) {
      setNewMessage('');
      setNewMentions('');
    }

    setLoading(false);
    setShowNewComment(false);
  };

  return (
    <div
      className={`position-relative d-flex align-items-center w-100p ${loading ? 'loading' : ''}  ${
        showNewComment ? 'pb-2' : 'pb-1'
      }`}
    >
      <div className={`new-comment-line ${showNewComment ? 'new-comment-line-shown' : ''}`} />
      <div
        onClick={() => (showNewComment ? null : setShowNewComment(true))}
        className={`new-comment d-flex align-items-center justify-content-center ${
          showNewComment ? 'new-comment-shown' : 'new-comment-hidden cursor-pointer'
        }`}
      >
        <SvgRender src={add} style={{ width: 6, height: 6 }} />
      </div>

      <div className="w-100p ms-3">
        {showNewComment ? (
          <>
            <Input value={newMessage} onChange={onInputChange} />
            <div className="new-comment-actions">
              <InputSaveActions onCancel={() => setShowNewComment(false)} onSave={onNewMessage} />
            </div>
          </>
        ) : (
          <div
            className="text-turquoise fs-12 cursor-pointer"
            onClick={() => setShowNewComment(true)}
          >
            New
          </div>
        )}
      </div>
    </div>
  );
};

export default NewComment;
