import GroupCellValue from './GroupCellValue';

export const RESUBMITTED_MESSAGE =
  'This action was corrected after the original MGA period submission.';

export const getGroupedRows = columns => {
  return columns.reduce((acc, column) => {
    if (column.type === 'group') {
      column.columns.forEach(groupColumn => {
        acc[groupColumn.key] = (data, _, __, rowIndex) => (
          <GroupCellValue
            data={data}
            groupKey={column.key}
            columnKey={groupColumn.key}
            rowIndex={rowIndex}
          />
        );
      });
    }

    return acc;
  }, {});
};
