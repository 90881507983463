import React, { useEffect } from 'react';
import * as authActions from 'store/auth/actions';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import PageLoader from 'common/components/general/PageLoader';

const Logout = () => {
  const [logout] = useActions([authActions.logout]);
  const authenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    if (authenticated) {
      logout();
    }
  }, []);

  return <PageLoader isLoading={true} />;
};

export default Logout;
