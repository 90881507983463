import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';

import * as mgaActions from 'common/components/mga/store/actions';
import add from 'common/assets/svg/actions/add.svg';

const AddActionButton = ({
  color = 'primary',
  className = 'px-2 py-1 fw-medium',
  hideIcon = true
}) => {
  const [setMgaActionForm] = useActions([mgaActions.setMgaActionForm]);

  const openForm = useCallback(() => setMgaActionForm({ isOpen: true }), []);

  return (
    <Button
      type="button"
      color={color}
      className={`d-inline-flex align-items-center border-0 flex-1 ${className}`}
      onClick={openForm}
    >
      {hideIcon ? null : <SvgRender src={add} style={{ width: 14, height: 14 }} className="me-1" />}
      <span className="lh-1">Add Action</span>
    </Button>
  );
};

AddActionButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  hideIcon: PropTypes.bool
};

export default AddActionButton;
