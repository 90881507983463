const checkIfValueIsValid = (rule, limit, value) => {
  if (!limit?.length) return true;

  switch (rule) {
    case 'between':
      return value >= limit[0] && value <= limit[1];
    case 'gt':
      return value > limit[0];
    case 'gte':
      return value >= limit[0];
    case 'lt':
      return value < limit[0];
    case 'lte':
      return value <= limit[0];
    default:
      return true;
  }
};

export const validateNumber = (field, templateValidation) => {
  const { value } = field;
  const { is_required, limit, rule } = templateValidation;
  let validationType = null;
  let isValid = null;

  if (is_required) {
    const requiredValidation = !value && value !== 0 ? false : true;
    isValid = requiredValidation;
    validationType = 'required';

    if (isValid && rule) {
      isValid = checkIfValueIsValid(rule, limit, value);
      validationType = 'template';
    }
  } else {
    if (!value) return { validationType: 'template', isValid: true };

    validationType = 'template';

    isValid = checkIfValueIsValid(rule, limit, value);
  }

  return { validationType, isValid };
};
