import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_SHIFT_TYPES: 'SET_SHIFT_TYPES',
  SET_SHIFTS: 'SET_SHIFTS',
  SET_SHIFT: 'SET_SHIFT',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_PERIOD_TYPE: 'SET_PERIOD_TYPE',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_SELECTED_SHIFT_TYPE: 'SET_SELECTED_SHIFT_TYPE',
  SET_INTERNAL_SHIFT: 'SET_INTERNAL_SHIFT',
  CLEAR_INTERNAL_TABLE: 'CLEAR_INTERNAL_TABLE',
  SET_ACTIVE_SHIFT_ID: 'SET_ACTIVE_SHIFT_ID',
  SET_ACTIVE_SHIFT_COMMENT: 'SET_ACTIVE_SHIFT_COMMENT',
  SET_SELECTED_RANK_FILTER: 'SET_SELECTED_RANK_FILTER',
  SET_SELECTED_VESSEL_FILTER: 'SET_SELECTED_VESSEL_FILTER',
  SET_SELECTED_SORTING: 'SET_SELECTED_SORTING',
  SET_SHIFT_WARNINGS: 'SET_SHIFT_WARNINGS',
  CLEAR_WARNINGS: 'CLEAR_WARNINGS',
  SET_HIGHLIGHTED_SEAMAN: 'SET_HIGHLIGHTED_SEAMAN',
  SET_SELECTED_CONTRACT: 'SET_SELECTED_CONTRACT',
  CLEAR_SHIFTS_STATE: 'CLEAR_SHIFTS_STATE',
  SET_SHIFTS_PORT_SEA_STATE: 'SET_SHIFTS_PORT_SEA_STATE',
  ...asyncTypes('GET_WATCHKEEPING_STATISTICS')
};

export default TYPES;
