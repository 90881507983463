import PropTypes from 'prop-types';

export const Department = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  parent_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  deleted_at: PropTypes.string,
  email: PropTypes.string,
  is_crew_department: PropTypes.bool
});

export const DepartmentRole = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  identifier: PropTypes.string,
  is_protected: PropTypes.bool,
  pivot: PropTypes.shape({
    party_id: PropTypes.number,
    department_role_id: PropTypes.number,
    department_id: PropTypes.number
  })
});

export const Party = PropTypes.shape({
  id: PropTypes.number.isRequired,
  full_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postal_code: PropTypes.string,
  address: PropTypes.string,
  country_id: PropTypes.number,
  country: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      party_id: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.string
    })
  ),
  extra_phones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      party_id: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.string
    })
  ),
  departments: PropTypes.arrayOf(Department),
  department_roles: PropTypes.arrayOf(DepartmentRole)
});

export const Charterer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  full_name: PropTypes.string
});
