import { inputs } from 'common/components/form/inputs/date/_constants';
import { useMemo } from 'react';

const Input = ({ activeMode, ...props }) => {
  const InputComponent = useMemo(() => (inputs[activeMode] ? inputs[activeMode] : null), [
    activeMode
  ]);

  return <InputComponent activeMode={activeMode} {...props} />;
};

export default Input;
