import { FC, useEffect, useMemo } from 'react';
import { format } from 'date-fns-tz';
import { ColumnDataType, OperationType } from '@webthatmatters/orca-table';
import { NumberFormatValues } from 'react-number-format';
import { strToNumber } from '@/ts-common/utils/numbers';
import SingleDatePicker, { DateInputType, DateRangePicker } from '@webthatmatters/orca-dateinput';
import styled from '@emotion/styled';
import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import NumberInput from '@/ts-common/components/form/inputs/NumberInput';
import usePrevious from '@/ts-common/utils/hooks/usePrevious';
import moment from 'moment';

type RangeValue = string[] | null;
type SingleValue = string | null;

type DateFilterSelectorProps = {
  value: string | string[] | null;
  onChange: (date: string | string[] | number | null) => void;
  filter: { value: unknown; column: { type: ColumnDataType }; operation: OperationType };
};

const dateFormat = 'yyyy-MM-dd';
const datetimeFormat = 'yyyy-MM-dd HH:mm';

// The defaultFilterValues found in some Tables is NOT supported yet
const DateFilterSelector: FC<DateFilterSelectorProps> = ({ value, onChange, filter }) => {
  const previousFilterOperation = usePrevious(filter.operation.value);

  const selectedNumericValue = useMemo(() => {
    if (['lastDays', 'nextDays'].includes(filter.operation.value)) {
      if (Array.isArray(value)) return value[0] as SingleValue;
    } else if (
      filter.operation.value !== 'between' &&
      !Array.isArray(value) &&
      value !== null &&
      !isNaN(+value)
    ) {
      return value as SingleValue;
    }

    return null;
  }, [filter.operation.value, value]);

  const selectedDateRangeValue = useMemo(() => {
    if (filter.operation.value === 'between' && Array.isArray(value)) {
      return value as RangeValue;
    }

    return null;
  }, [filter.operation.value, value]);

  const selectedSingeDateValue = useMemo(() => {
    if (
      filter.operation.value !== 'between' &&
      !Array.isArray(value) &&
      selectedNumericValue === null
    ) {
      return value as SingleValue;
    }

    return null;
  }, [filter.operation.value, value, selectedNumericValue]);

  const hasTime = ['datetime'].includes(filter.column.type);
  const isUtcTime = ['utc_datetime'].includes(filter.column.type);

  const getFormattedDate = (date: Date) => {
    if (isUtcTime) {
      const dateInUtc = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        )
      );

      dateInUtc.toISOString();
    }

    return format(date, hasTime ? datetimeFormat : dateFormat);
  };

  useEffect(() => {
    if (
      filter.operation.value &&
      previousFilterOperation &&
      previousFilterOperation !== filter.operation.value
    ) {
      onChange(null);
    }
  }, [filter.operation.value, onChange, previousFilterOperation]);

  if (!filter.operation.value) return null;

  return (
    <FilterContainer>
      {filter.operation.value === 'between' ? (
        <RangeDateFilter
          from={selectedDateRangeValue?.[0] ? new Date(selectedDateRangeValue[0]) : null}
          to={selectedDateRangeValue?.[1] ? new Date(selectedDateRangeValue[1]) : null}
          onChange={({ from, to }: { from: DateInputType; to: DateInputType }) =>
            onChange(from && to ? [getFormattedDate(from), getFormattedDate(to)] : null)
          }
          hasTime={hasTime}
        />
      ) : ['lastDays', 'nextDays'].includes(filter.operation.value) ? (
        <NumberInput
          placeholder="No. of days"
          value={selectedNumericValue || ''}
          onChange={e => onChange([strToNumber(e.target.value), moment.tz.guess()])}
          isAllowed={(val: NumberFormatValues) =>
            val.value === '' || (val.floatValue !== undefined && val.floatValue > 0)
          }
          className="cps-12 h-100p"
          invisible
        />
      ) : ['beforeDaysFromToday', 'afterDaysFromToday'].includes(filter.operation.value) ? (
        <NumberInput
          placeholder="No. of days"
          value={selectedNumericValue || ''}
          onChange={e => onChange(strToNumber(e.target.value))}
          isAllowed={(val: NumberFormatValues) => (val.value.includes('.') ? false : true)}
          className="cps-12 h-100p"
          invisible
        />
      ) : (
        <SingleDateFilter
          value={selectedSingeDateValue ? new Date(selectedSingeDateValue) : null}
          onChange={(date: DateInputType) => (date ? onChange(getFormattedDate(date)) : null)}
          hasTime={hasTime}
        />
      )}
    </FilterContainer>
  );
};

const SingleDateFilter = ({
  value,
  hasTime = false,
  onChange
}: {
  value: DateInputType;
  hasTime?: boolean;
  onChange: (date: DateInputType) => void;
}) => (
  <SingleDatePicker
    value={value}
    onChange={onChange}
    groupClassName="mb-0 h-100p"
    displayClassName="max-w-100p h-100p"
    isClearable={false}
    hasTime={hasTime}
  />
);

const RangeDateFilter = ({
  from,
  to,
  hasTime = false,
  onChange
}: {
  from: DateInputType;
  to: DateInputType;
  hasTime?: boolean;
  onChange: (dates: { from: DateInputType; to: DateInputType }) => void;
}) => (
  <DateRangePicker
    from={from}
    to={to}
    onChange={onChange}
    groupClassName="mb-0 h-100p"
    displayClassName="max-w-100p h-100p text-nowrap text-truncate overflow-hidden"
    isClearable={false}
    hasTime={hasTime}
  />
);

const FilterContainer = styled.div`
  border: 1px solid ${variables.silver};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 100%;
`;

export default DateFilterSelector;
