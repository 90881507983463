import { useSelector } from 'react-redux';
import MgaAccountsSelector from 'common/components/selectors/MgaAccountsSelector';

const SubAccountField = ({ fields, selectField, disabled }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const { action_type } = fields;
  const isVisible =
    action_type.value?.debit_type === 'sub' || action_type.value?.credit_type === 'sub';

  if (!isVisible) return null;

  const key = action_type.value?.debit_type === 'sub' ? 'debit_account' : 'credit_account';

  return (
    <div className="mb-2">
      <div className="form-label">ACCOUNT</div>

      <MgaAccountsSelector
        placeholder="Select account"
        value={fields[key].value}
        onChange={value => selectField(key)(value)}
        isMulti={false}
        autoFocus={false}
        listParams={{
          parent_id: action_type.value[`${key}_id`],
          ...(isOnBoard ? { show_account_page_onboard: true } : {})
        }}
        disabled={disabled}
        key={action_type.value[key]}
      />
    </div>
  );
};

export default SubAccountField;
