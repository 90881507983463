import PropTypes from 'prop-types';

export const WorkingHoursType = PropTypes.shape({
  monday: PropTypes.string,
  friday: PropTypes.string,
  holiday: PropTypes.string,
  saturday: PropTypes.string,
  sunday: PropTypes.string,
  thursday: PropTypes.string,
  tuesday: PropTypes.string,
  wednesday: PropTypes.string,
  overtime: PropTypes.string
});
