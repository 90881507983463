import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import _get from 'lodash/get';

const RightShipInspection = ({ field }) => {
  const profile = useSelector(selectVesselProfile);

  return (
    <Row key={field.key} className="cmt-4 cmb-4">
      <Col className="text-blue fs-12 fw-bold d-flex align-items-start justify-content-end" xs={4}>
        {field.label}
      </Col>
      <Col xs={8} className="fs-12 ">
        {_get(profile, field.key, [])
          ? _get(profile, field.key, []).map((el, i) => {
              return (
                <div key={i.toString()} className="d-flex align-items-start position-relative">
                  <div className="col-left-border" />
                  <Row className="w-100p">
                    <Col xs={5} className="d-flex align-items-start">
                      <div className="text-blue me-2 fw-bold">NAME</div>
                      <div>{_get(el, 'type.name', '-')}</div>
                    </Col>
                    <Col xs={6} className="d-flex align-items-start">
                      <div className="text-blue me-2 fw-bold">VALUE</div>
                      <div>{_get(el, 'value', '-')}</div>
                    </Col>
                  </Row>
                </div>
              );
            })
          : '-'}
      </Col>
    </Row>
  );
};

export default RightShipInspection;
