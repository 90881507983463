import { get, put, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createEvent = createAsyncThunk('CREATE_EVENT', async (params, { rejectWithValue }) => {
  const { id, ...rest } = params;

  try {
    const res = await post(`/events/${id}/events`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getEvents = createAsyncThunk('GET_EVENTS', async (params, { rejectWithValue }) => {
  const { id, ...rest } = params;

  try {
    const res = await get(`/events/${id}/events`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const updateEvent = createAsyncThunk('UPDATE_EVENT', async (params, { rejectWithValue }) => {
  const { id, previousEventId, ...rest } = params;

  try {
    const res = await put(`/events/${id}/events/${previousEventId}`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const deleteEvent = createAsyncThunk('DELETE_EVENT', async (params, { rejectWithValue }) => {
  const { id, previousEventId, ...rest } = params;

  try {
    const res = await deleteRequest(`/events/${id}/events/${previousEventId}`, rest);

    return res.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
