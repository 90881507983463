import React from 'react';
import Select from 'common/components/form/inputs/Select';
import SeverityLegend from 'common/components/general/SeverityLegend';
import { components } from 'react-select';

const SeveritySelector = ({ value, onChange, ...rest }) => {
  const options = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];

  return (
    <Select
      placeholder="Select value"
      value={value}
      options={options}
      onChange={selected => onChange(selected)}
      components={{ Option, SingleValue }}
      {...rest}
    />
  );
};

export const Option = ({ children, ...props }) => (
  <components.Option {...props}>
    <SeverityLegend number={props?.data?.value} />
  </components.Option>
);

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <SeverityLegend number={props?.data?.value} />
  </components.SingleValue>
);

export default SeveritySelector;
