import moment from 'moment';
import _get from 'lodash/get';

export const sortingOptions = [
  { label: 'Rank', value: 'rank_index', sorting: 'rank_index', direction: 'asc', size: 'sm' },
  { label: 'A-Z', value: 'full_name_asc', sorting: 'full_name', direction: 'asc', size: 'sm' },
  { label: 'Z-A', value: 'full_name_desc', sorting: 'full_name', direction: 'desc', size: 'sm' }
];

export const getSorting = value => {
  const selectedSorting = sortingOptions.find(sorting => sorting.value === value);
  if (!selectedSorting) return;

  return { sorting: { [selectedSorting.sorting]: selectedSorting.direction } };
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  const currDate = moment(startDate, 'YYYY-MM-DD').startOf('day');
  const lastDate = moment(endDate, 'YYYY-MM-DD').startOf('day');

  const dates = [moment(currDate)];

  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const getSeamenWithoutShifts = (seamen, start, end) => {
  const datesToCheck = enumerateDaysBetweenDates(start, end);

  return seamen.filter(seaman => {
    return datesToCheck.every(date => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const selectedShifts = _get(seaman, `dates[${formattedDate}].shifts`) || [];
      return !selectedShifts.length;
    });
  });
};
