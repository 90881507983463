import React, { useState } from 'react';

import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { Selectors } from 'common/entities/selectors/SelectorTypes';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import { getFilterSelectorValues } from '../filters/helpers';

const FormObligationTriggerSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  autoFocus = true,
  styled,
  wrapperClassName = '',
  className,
  ...rest
}) => {
  const [selectedTriggers, setSelectedTriggers] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'form-obligation-setting-trigger-types' },
    { selected: selectedTriggers, setSelected: setSelectedTriggers }
  );

  const SelectTag = styled ? StyledSelect : Select;

  return (
    <div className={`${wrapperClassName}`}>
      <SelectTag
        autoFocus={autoFocus}
        className={className || `mb-0`}
        placeholder="Select value"
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        isAsync
        isMulti={isMulti}
        size="sm"
        value={
          value !== undefined
            ? value
            : filter?.value
            ? getFilterSelectorValues(selectedTriggers, filter?.value, isMulti)
            : []
        }
        defaultOptions={() => getInitialAsyncValues('form-obligation-setting-trigger-types')}
        loadOptions={search => getAsyncOptions(search, 'form-obligation-setting-trigger-types')}
        onChange={selected => {
          setSelectedTriggers(isMulti ? selected : [selected]);
          onChange(
            value !== undefined
              ? selected
              : {
                  value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
                }
          );
        }}
        {...rest}
      />
    </div>
  );
};

export default FormObligationTriggerSelector;

FormObligationTriggerSelector.propTypes = Selectors;
