import { useCallback } from 'react';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import { useActions } from 'utils/hooks';
import {
  selectActionFormActive,
  selectActionModalIsOpen
} from 'common/components/mga/store/selectors';
import { useSelector } from 'react-redux';

import * as mgaActions from 'common/components/mga/store/actions';

const DeleteActionModal = () => {
  const [setMgaActionForm, deleteMgaAction] = useActions([
    mgaActions.setMgaActionForm,
    mgaActions.deleteMgaAction
  ]);
  const isModalOpen = useSelector(selectActionModalIsOpen);
  const active = useSelector(selectActionFormActive);

  const closeDeleteModal = useCallback(() => setMgaActionForm({ isModalOpen: false }), []);
  const deleteAction = useCallback(async () => await deleteMgaAction({ id: active.id }), [
    active?.id
  ]);

  return (
    <DangerousActionModal
      transparent
      action={'Delete'}
      onAccept={deleteAction}
      closeModal={closeDeleteModal}
      isOpen={isModalOpen}
      actionText={'DELETE'}
      header={'Delete'}
      body={`Are you sure you want to delete ${active?.description || 'it'}?`}
    />
  );
};

export default DeleteActionModal;
