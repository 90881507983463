import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import chat from 'common/assets/svg/common/chat-bubble.svg';

import { selectUnreadMessagesCount } from 'common/components/chatbox/store/selectors';

import ChatDrawer from './Drawer';
import { messageFormatter, containsHTML } from './helpers';
import MessagesCountBubble from '@/common/components/general/MessagesCountBubble';
import ToggleButton from '../general/ToggleButton';

import { messageInitialState } from './helpers';

const Chatbox = ({
  className,
  getMessages = () => {},
  sendMessage,
  getUnreadMessages,
  markMessagesAsRead,
  permissions,
  drawerClassName,
  drawer,
  fetchOnMount = true,
  hideChatToggle = false,
  size = null,
  svgStyle
}) => {
  const [message, setMessage] = useState(messageInitialState);
  const [file, setFile] = useState(null);

  const unreadMessagesCount = useSelector(selectUnreadMessagesCount);

  const initChatbox = useCallback(() => {
    if (fetchOnMount) {
      try {
        getUnreadMessages();
        getMessages();
      } catch (e) {
        console.error(e);
      }
    }
  }, [fetchOnMount]);

  const openHandler = () => {
    if (unreadMessagesCount > 0) {
      markMessagesAsRead();
    }

    drawer.open();
  };

  const postMessageHandler = () => {
    try {
      if (containsHTML(message.text)) return;

      if (message.text) {
        const params = {
          message: messageFormatter(message.text, message.mentions),
          party_ids: message.mentions.map(mention => mention.id),
          file_id: file?.id || null
        };

        sendMessage(params);
      }

      setMessage(messageInitialState);
      setFile(null);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  useEffect(() => {
    initChatbox();
  }, [initChatbox]);

  return (
    <div className="chatbox">
      {!hideChatToggle && (
        <>
          <ToggleButton
            className={`chatbox__toggle-button position-relative ${className || ''}`}
            onClick={openHandler}
            size={size}
          >
            <img alt="chatbox-icon" src={chat} style={svgStyle ?? { width: 24, height: 24 }} />
            {unreadMessagesCount > 0 ? (
              <MessagesCountBubble
                className="circle-notification position-absolute width-16 height-16"
                count={unreadMessagesCount}
              />
            ) : null}
          </ToggleButton>
        </>
      )}

      <ChatDrawer
        drawer={drawer}
        drawerClassName={drawerClassName}
        message={message}
        permissions={permissions}
        setMessage={setMessage}
        file={file}
        setFile={setFile}
        postMessageHandler={postMessageHandler}
      />
    </div>
  );
};

export default Chatbox;

Chatbox.propTypes = {
  getMessages: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  markMessagesAsRead: PropTypes.func.isRequired,
  getUnreadMessages: PropTypes.func.isRequired,
  className: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  svgStyle: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }),
  drawer: PropTypes.object.isRequired,
  drawerClassName: PropTypes.string,
  fetchOnMount: PropTypes.bool,
  hideChatToggle: PropTypes.bool,
  size: PropTypes.string
};
