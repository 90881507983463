import PropTypes from 'prop-types';
import { Party } from 'common/entities/parties/PartyTypes';
import { Country } from '../lists/ListTypes';

export const Itinerary = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  port_id: PropTypes.number.isRequired,
  is_scheduled: PropTypes.bool,
  is_laden: PropTypes.bool,
  eta_is_real: PropTypes.bool,
  etb_is_real: PropTypes.bool,
  etd_is_real: PropTypes.bool,
  eta: PropTypes.string,
  etb: PropTypes.string,
  etd: PropTypes.string,
  has_voyage_no: PropTypes.bool,
  is_at_port: PropTypes.bool,
  is_confirmed: PropTypes.bool,
  vessel_id: PropTypes.number,
  timezone: PropTypes.string,
  port: PropTypes.shape({
    code: PropTypes.string,
    country: Country,
    country_code: PropTypes.string,
    id: PropTypes.number,
    lat: PropTypes.string,
    lon: PropTypes.string,
    name: PropTypes.string
  })
});

const ItineraryAgentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

export const ItineraryAgent = PropTypes.shape({
  id: PropTypes.number,
  itinerary_port_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  party_id: PropTypes.number,
  party: Party.isRequired,
  type_id: PropTypes.number,
  type: ItineraryAgentType
});
