import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import useRouter from 'use-react-router';
import { parseQueryParams } from 'common/utils/urls';

import {
  selectComparedAtVersionId,
  selectManualId,
  selectActiveChapterUid,
  selectActiveChapterContentHasPost,
  selectCurrentVersionId,
  selectIsComparedAtVersion,
  selectCurrentVersion,
  selectChapter,
  selectManualVersions
} from 'manuals/store/selectors';

import * as manualsActions from 'manuals/store/actions';

import PageLoader from 'common/components/general/PageLoader';
import VersionModal from './VersionModal';
import Button from './Button';
import moment from 'moment';

const ChangeSince = ({ postActions, className = '' }) => {
  const [isModalTypeOpen, setIsModalTypeOpen] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const { location } = useRouter();

  const currentVersion = useSelector(selectCurrentVersion);
  const manualId = useSelector(selectManualId);
  const activeUid = useSelector(selectActiveChapterUid);
  const activeChapter = useSelector(state => selectChapter(state, activeUid));
  const hasContent = useSelector(selectActiveChapterContentHasPost);

  const currentVersionId = useSelector(selectCurrentVersionId);

  const comparedAtVersionId = useSelector(selectComparedAtVersionId);
  const isComparedAtVersion = useSelector(selectIsComparedAtVersion);

  const versions = useSelector(selectManualVersions);
  const isWorkingVersion = currentVersion?.status === 'working';

  const [setComparedAtVersion] = useActions([manualsActions.setComparedAtVersion]);

  const modalHandler = () => {
    setIsModalTypeOpen(!isModalTypeOpen);
  };

  const clearHandler = async () => {
    setComparedAtVersion(null);

    await postActions.onManualChaptersFetch({ id: manualId, version_id: currentVersionId });
  };

  const isLatestVersion = ({ fistVersionDate, secondVersionDate }) => {
    return moment(fistVersionDate).isAfter(secondVersionDate);
  };

  const compareChapterPosts = async () => {
    if (
      comparedAtVersionId &&
      activeUid &&
      !isWorkingVersion &&
      hasContent &&
      activeChapter?.compare_has_changed
    ) {
      try {
        setIsComparing(true);

        await postActions.onComparePosts({
          manual_id: manualId,
          uid: activeUid,
          version_id: comparedAtVersionId
        });
        setIsComparing(false);
      } catch (error) {
        console.error(error);
        setIsComparing(false);
      }
    }
  };

  const compareManualChapters = async compared => {
    setIsComparing(true);

    const selectedValue = versions.find(v => v.id === parseInt(compared));
    let versionId = undefined;
    let secondVersionId = undefined;

    if (
      isLatestVersion({
        fistVersionDate: selectedValue?.valid_from,
        secondVersionDate: currentVersion?.valid_from
      })
    ) {
      versionId = selectedValue?.id;
      secondVersionId = currentVersion?.id;
    } else {
      versionId = currentVersion?.id;
      secondVersionId = selectedValue?.id;
    }

    const params = { id: manualId, versionId: versionId, secondVersionId: secondVersionId };

    try {
      await postActions.onCompareChapters(params);

      setComparedAtVersion(selectedValue);
      setIsComparing(false);
    } catch (error) {
      console.error(error);
      setIsComparing(false);
    }
  };

  // useEffect(() => {
  //   if (isWorkingVersion) {
  //     clearHandler();
  //   }
  // }, [isWorkingVersion]);

  useEffect(() => {
    const { compared } = parseQueryParams(location.search);

    if (compared && versions.length && currentVersion?.id) {
      compareManualChapters(compared);
    } else if (!compared && isComparedAtVersion) {
      clearHandler();
    }
  }, [location.search, versions?.length, currentVersion?.id]);

  useEffect(() => {
    compareChapterPosts();
  }, [comparedAtVersionId, activeUid, hasContent]);

  return (
    <div className={className}>
      {isWorkingVersion ? null : <Button clickHandler={modalHandler} clearHandler={clearHandler} />}

      <VersionModal isModalTypeOpen={isModalTypeOpen} modalHandler={modalHandler} />
      <PageLoader isLoading={isComparing} />
    </div>
  );
};

export default ChangeSince;
