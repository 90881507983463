import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate, removeTableRow } from 'store/tables/lists/actions';
import {
  parseJobProfile,
  parseJobBeforeSave,
  getNewJobFields,
  retrieveTypeOfUser,
  getAutoSavedFieldValue,
  getAutoSavedFieldKey
} from 'common/components/jobs/_base/store/helpers';
import { selectIsJobLocked, selectJobsReducer } from 'common/components/jobs/_base/store/selectors';
import {
  selectIsUnscheduledJob,
  selectIsPlannedJob
} from 'common/components/pms/jobs/store/selectors';
import { getMaintenance } from 'common/components/jobs/maintenance/store/actions';
import { jobTableLabels } from 'common/utils/fixed';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import { jobEnums } from 'common/utils/fixed';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteJob } from '@/api/jobs/api.ts';
import {
  setMessages,
  postMessage,
  setUnreadMessages,
  setMarkAsRead,
  setFetching
} from 'common/components/chatbox/store/actions';
import { selectRestrictedFields } from 'store/jobs-fields/selectors';

export const updateJobInTable = params => dispatch => {
  jobTableLabels.forEach(table_label => {
    dispatch(updateTableRow({ data: params, table: table_label }));
  });
};

export const removeJobInTable = params => dispatch => {
  jobTableLabels.forEach(table_label => {
    dispatch(removeTableRow({ data: params, table: table_label }));
  });
};

export const resetJobInTable = params => dispatch => {
  jobTableLabels.forEach(table_label => {
    dispatch(resetTableRowUpdate({ data: params, table: table_label }));
  });
};

export const getJob = params => (dispatch, getState) => {
  dispatch({ type: TYPES.GET_JOB.START, payload: { params } });
  const accountId = getState().account.id;

  return get(`/jobs/${params.id}`)
    .then(async response => {
      // Fetch maintenance details
      if (response.data?.type === jobEnums.pms || response.data?.type === jobEnums.unscheduled)
        await dispatch(getMaintenance({ jobId: params.id }));

      dispatch({ type: TYPES.GET_JOB.SUCCESS, payload: parseJobProfile(response.data) });
      dispatch({
        type: TYPES.SET_TYPE_OF_LOGGED_IN_USER,
        payload: retrieveTypeOfUser(response.data, accountId)
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_JOB.ERROR, payload: error });
      throw error;
    });
};

export const setJobRequiredFieldError = error => dispatch => {
  if (error?.data?.error === 'cannot_change_pms_job_status') {
    const requiredField = error.data.data?.required_field;
    dispatch({
      type: TYPES.SET_JOB_ERROR_FIELD,
      payload: { [requiredField]: REQUIRED_FIELD_MESSAGE }
    });
  }
};

export const updateJob =
  (params, skipLock, isMaintenance, previousState) => (dispatch, getState) => {
    const state = getState();
    const isJobLocked = selectIsJobLocked(state);
    if (isJobLocked && !skipLock) return;

    const id = params.id ?? state.jobs.id;
    const accountId = state.account.id;

    if (!id) {
      return;
    }
    const formatted = parseJobBeforeSave(params);

    dispatch({ type: TYPES.UPDATE_JOB.START, payload: { params: formatted } });
    dispatch(updateJobInTable({ ...params, id }));

    return put(`/jobs/${id}`, formatted)
      .then(response => {
        dispatch({ type: TYPES.UPDATE_JOB.SUCCESS, payload: response.data });
        dispatch(updateJobInTable(response.data));

        if (isMaintenance) {
          dispatch(getMaintenance({ jobId: id }));
        }

        dispatch(setTableShouldRefetchData(true, params));

        dispatch({
          type: TYPES.SET_TYPE_OF_LOGGED_IN_USER,
          payload: retrieveTypeOfUser(response.data, accountId)
        });

        return response.data;
      })
      .catch(error => {
        dispatch({
          type: TYPES.UPDATE_JOB.ERROR,
          payload: { response: error, params: selectJobsReducer(state) }
        });
        dispatch(resetJobInTable(selectJobsReducer(state)));

        dispatch(setJobRequiredFieldError(error));

        if (previousState) {
          dispatch({
            type: TYPES.SET_JOB_FIELD_VALUE,
            payload: { field: previousState.field, value: previousState.value }
          });

          if (params.id && !state.jobs.id) {
            dispatch(
              resetJobInTable({ id: params.id, [previousState.field]: previousState.value })
            );
          }
        }

        throw error;
      });
  };

export const createJob = () => (dispatch, getState) => {
  const params = getNewJobFields(getState().jobs);

  dispatch({ type: TYPES.CREATE_JOB.START, payload: { params } });

  return post('/jobs', params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_JOB.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Job created succesfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_JOB.ERROR, payload: error });
      throw error;
    });
};

export const archiveJob = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_JOB.START, payload: { params } });

  return put(`/jobs/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_JOB.SUCCESS, payload: response.data });
      dispatch(removeJobInTable({ ...params, id: params.id }));
      dispatch(successHandler({ title: 'Success!', message: 'Job has been archived' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.ARCHIVE_JOB.ERROR, payload: error }));
};

export const unarchiveJob = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_JOB.START, payload: { params } });

  return put(`/jobs/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_JOB.SUCCESS, payload: response.data });
      dispatch(removeJobInTable({ ...params, id: params.id }));
      dispatch(successHandler({ title: 'Success!', message: 'Job has been unarchived' }));

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.UNARCHIVE_JOB.ERROR, payload: error }));
};

export const cloneJob = params => dispatch => {
  dispatch({ type: TYPES.CLONE_JOB.START, payload: { params } });

  const { id, ...rest } = params;

  return post(`/jobs/${id}/clone`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.CLONE_JOB.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Job has been cloned' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CLONE_JOB.ERROR, payload: error });
      return error;
    });
};

export const setJobCustomTemplate = params => dispatch => {
  dispatch({ type: TYPES.SET_JOB_CUSTOM_TEMPLATE, payload: params });
};

export const setJobProfile =
  (data, avoidAuthCheck = false) =>
  (dispatch, getState) => {
    dispatch({ type: TYPES.SET_JOB_PROFILE, payload: parseJobProfile(data) });

    if (!avoidAuthCheck) {
      const accountId = getState().account.id;
      dispatch({
        type: TYPES.SET_TYPE_OF_LOGGED_IN_USER,
        payload: retrieveTypeOfUser(data, accountId)
      });
    }
  };

export const resetJobProfile = value => dispatch => {
  dispatch({ type: TYPES.RESET_JOB_PROFILE, payload: value });
};

export const setDefaultJobStatus = id => dispatch => {
  dispatch({ type: TYPES.SET_DEFAULT_JOB_STATUS, payload: id });
};

export const setJobField =
  (field, value, autoSave, isMaintenance, skipLock) => (dispatch, getState) => {
    const state = getState();
    const isJobLocked = selectIsJobLocked(state);

    if (isJobLocked && !skipLock) return;

    dispatch({ type: TYPES.SET_JOB_FIELD_VALUE, payload: { field, value } });
    dispatch(setUnsavedChangesAlert());

    if (autoSave && state.jobs.id) {
      const previousValue = state.jobs[field];

      return dispatch(
        updateJob(
          { [getAutoSavedFieldKey(field)]: getAutoSavedFieldValue(field, value) },
          true,
          isMaintenance,
          previousValue !== undefined ? { field, value: previousValue } : null
        )
      );
    }
  };

export const setForVesselJobField = (field, value, autoSave) => async (dispatch, getState) => {
  if (autoSave && getState().jobs.id) {
    const res = await dispatch(updateJob({ [field]: value }));
    if (res) {
      dispatch({ type: TYPES.SET_JOB_FIELD_VALUE, payload: { field, value } });
    }
  } else {
    dispatch({ type: TYPES.SET_JOB_FIELD_VALUE, payload: { field, value } });
  }
};

export const setIsA4Layout = value => dispatch => {
  dispatch({ type: TYPES.SET_A4_LAYOUT, payload: value });
};

export const setJobFieldError = errors => dispatch => {
  dispatch({ type: TYPES.SET_JOB_ERROR_FIELD, payload: errors });
};

export const setActiveJobTab = (tab, remove) => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_JOB_TAB, payload: { tab, remove } });
};

export const setSelectedJob = job => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_JOB, payload: job });
};

export const setPrefilledVessel = vessel => dispatch => {
  dispatch({ type: TYPES.SET_PREFILLED_VESSEL, payload: vessel });
};

export const setUnsavedChangesAlert = () => (dispatch, getState) => {
  if (selectIsUnscheduledJob(getState()) || selectIsPlannedJob(getState())) return;

  const job = getState().jobs;
  const restrictedFields = selectRestrictedFields(getState());
  const requiredFields = restrictedFields.filter(field => field.required);

  dispatch({
    type: TYPES.SET_UNSAVED_CHANGES_ALERT,
    payload: {
      job,
      requiredFields
    }
  });
};

export const hasInvalidRequiredFields =
  (ignore = []) =>
  (dispatch, getState) => {
    const job = getState().jobs;
    const restrictedFields = selectRestrictedFields(getState());
    const requiredFields = restrictedFields.filter(field => field.required);
    const visibleFields = restrictedFields.filter(field => field.visible).map(el => el.field);
    const hasApprover = job.approvers.filter(approver => approver.id);
    const errorMessage = REQUIRED_FIELD_MESSAGE;

    const errors = requiredFields.reduce((acc, cur) => {
      if (ignore.length && ignore.indexOf(cur.field) !== -1) return acc;

      if (!job.assignee && cur.field === 'assignee_id' && visibleFields.includes('assignee_id'))
        acc.assignee = errorMessage;
      if (!hasApprover.length && cur.field === 'approvers' && visibleFields.includes('approvers'))
        acc.approvers = errorMessage;
      if (!job.due_date && cur.field === 'due_date' && visibleFields.includes('due_date'))
        acc.due_date = errorMessage;
      if (!job.tags.length && cur.field === 'tags' && visibleFields.includes('tags'))
        acc.tags = errorMessage;
      if (!job.title && cur.field === 'title' && visibleFields.includes('title'))
        acc.title = errorMessage;
      if (!job.template && cur.field === 'template_id' && visibleFields.includes('template_id'))
        acc.template = errorMessage;
      if (
        !job.checklist.options.length &&
        cur.field === 'checklist_options' &&
        visibleFields.includes('checklist_options')
      )
        acc.checklist = errorMessage;
      if (!job.description && cur.field === 'description' && visibleFields.includes('description'))
        acc.description = errorMessage;

      return acc;
    }, {});

    if (Object.keys(errors).length) {
      dispatch(setJobFieldError(errors));
      return true;
    } else {
      return false;
    }
  };

export const getJobMessages = params => dispatch => {
  dispatch({ type: TYPES.GET_JOB_MESSAGES.START, payload: { params } });
  dispatch(setFetching(true));

  return get(`/jobs/${params.job_id}/messages`)
    .then(response => {
      dispatch(setMessages(response.data));
      dispatch(setFetching(false));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_JOB_MESSAGES.ERROR, payload: error });
      dispatch(setFetching(false));
      throw error;
    });
};

export const postJobMessage = params => dispatch => {
  const { job_id, ...rest } = params;

  dispatch({ type: TYPES.POST_JOB_MESSAGE.START, payload: { params } });

  return post(`/jobs/${job_id}/messages`, rest)
    .then(response => {
      dispatch(postMessage(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.POST_JOB_MESSAGE.ERROR, payload: error });
      throw error;
    });
};

export const getJobUnreadMessages = params => dispatch => {
  dispatch({ type: TYPES.GET_JOB_UNREAD_MESSAGES.START, payload: { params } });

  return get(`/jobs/${params.job_id}/messages/get-unread`)
    .then(response => {
      dispatch(setUnreadMessages(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_JOB_UNREAD_MESSAGES.ERROR, payload: error });
      throw error;
    });
};

export const jobMarkAsRead = params => dispatch => {
  dispatch({ type: TYPES.JOB_MARK_AS_READ.START, payload: { params } });

  return put(`/jobs/${params.job_id}/messages/mark-as-read`)
    .then(response => {
      dispatch(setMarkAsRead(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.JOB_MARK_AS_READ.ERROR, payload: error });
      throw error;
    });
};

export const setTableShouldRefetchData = (value, params) => (dispatch, getState) => {
  // Call this function on demand when you need to refetch the table data
  if (value && params?.status_id) {
    // When updating a job's status to DONE, the table should not be refetched
    const state = getState();
    const jobStatuses = selectJobStatuses(state);
    const status = jobStatuses?.find(st => st.id === params?.status_id);

    if (status?.progress === 'done') {
      dispatch({ type: TYPES.SET_TABLE_SHOULD_REFETCH_DATA, payload: false });

      return;
    }
  }

  dispatch({ type: TYPES.SET_TABLE_SHOULD_REFETCH_DATA, payload: value });
};

export const unlinkJobFinding = createAsyncThunk(
  'UNLINK_JOB_FINDING',
  async (params, { rejectWithValue, dispatch }) => {
    const { job_id, id, ...rest } = params;

    try {
      await deleteRequest(`/jobs/${job_id}/findings/${id}`, rest);

      return params;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
