import { useSelector } from 'react-redux';
import { selectCurrentVersion, hasChapters } from 'manuals/store/selectors';
import GoBackArrow from 'common/components/general/GoBackArrow';

import Title from './Title';
import Versions from './Versions';
import AcknowledgeButton from 'common/components/buttons/AcknowledgeSubmitButton';
import DownloadManualButton from 'manuals/manual-inner/layout/header/DownloadManualButton';

import paths from 'routing/routes/_paths';
import ChangeSince from './change-since';

import { showAcknowledgementSubmitButtonBasedOnRank } from 'manuals/store/selectors';

const Header = ({ isOnboard, actions, postActions, manualActions, chaptersLoading }) => {
  const currentVersion = useSelector(selectCurrentVersion);
  const notEmpty = useSelector(hasChapters);
  const showButtonBasedOnRank = useSelector(showAcknowledgementSubmitButtonBasedOnRank);

  const isActiveVersionNotAcknowledged =
    currentVersion?.status === 'active' && !currentVersion?.current_user_acknowledged;

  const acknowledgeVersion = async () => {
    await manualActions.onAcknowledgeManualVersion({ versionId: currentVersion.id });
  };

  return (
    <div className={`manual-layout__header d-flex align-items-center flex-nowrap`}>
      <GoBackArrow to={paths.manuals} avoidHistoryCheck={true} className="cme-12" />
      <Title />
      <Versions chaptersLoading={chaptersLoading} />

      {actions ? <div className="ps-2 ms-auto">{actions}</div> : null}

      {isOnboard && showButtonBasedOnRank && isActiveVersionNotAcknowledged ? (
        <AcknowledgeButton
          onAccept={acknowledgeVersion}
          modalMessage={
            <div className="text-center">
              Are you sure you want to acknowledge this manual version? This cannot be undone.
            </div>
          }
        />
      ) : !isOnboard && isActiveVersionNotAcknowledged ? (
        <AcknowledgeButton
          onAccept={acknowledgeVersion}
          modalMessage={
            <div className="text-center">
              Are you sure you want to acknowledge this manual version? This cannot be undone.
            </div>
          }
        />
      ) : null}

      <ChangeSince
        className={isActiveVersionNotAcknowledged ? 'd-none' : ''}
        postActions={postActions}
      />

      {isActiveVersionNotAcknowledged || !notEmpty ? null : (
        <DownloadManualButton isOnboard={isOnboard} />
      )}
    </div>
  );
};

export default Header;
