import Cookie from 'utils/cookie';
// const jwt_decode = require('jwt-decode');

export const loadState = () => {
  let state = {
    auth: {
      isAuthenticated: false
    }
  };

  try {
    if (Cookie.get('accessToken')) {
      state.auth.isAuthenticated = true;

      // window.localStorage.setItem('tokenExpiresAt', jwt_decode(Cookie.get('accessToken')).exp);
    }

    return state;
  } catch (err) {
    return state;
  }
};

export const saveState = state => {
  try {
  } catch (err) {
    // Handle errors
  }
};

export { default as asyncTypes } from 'common/utils/asyncTypes';
