import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import _flatten from 'lodash/flatten';
import _isBoolean from 'lodash/isBoolean';
import _get from 'lodash/get';
import send from 'common/assets/svg/actions/send.svg';
import crew from 'common/assets/svg/common/crew.svg';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import bin from 'common/assets/svg/common/bin-large.svg';
import CommentsPopover from 'common/components/popovers/CommentsPopover';

export const cleanValues = (values, template) => {
  const {
    vessel_name,
    rank_name,
    recommended_for_promotion,
    recommended_for_promotion_comment,
    recommended_for_reemployment,
    recommended_for_reemployment_comment,
    training_type_ids,
    training_types,
    ...rest
  } = values;

  return {
    ...rest,
    training_types: training_types && undefined,
    training_type_ids: training_types?.length ? training_types.map(types => types.id) : undefined,
    recommended_for_promotion: template?.has_recommended_for_promotion
      ? _isBoolean(recommended_for_promotion)
        ? recommended_for_promotion
        : undefined
      : undefined,
    recommended_for_promotion_comment: template?.has_recommended_for_promotion
      ? recommended_for_promotion_comment
      : undefined,
    recommended_for_reemployment: template?.has_recommended_for_reemployment
      ? _isBoolean(recommended_for_reemployment)
        ? recommended_for_reemployment
        : undefined
      : undefined,
    recommended_for_reemployment_comment: template?.has_recommended_for_reemployment
      ? recommended_for_reemployment_comment
      : undefined,
    crew_evaluation_obligation_id:
      values.crew_evaluation_obligation_id === null
        ? undefined
        : values.crew_evaluation_obligation_id,
    crew_evaluation_reason_id:
      values.crew_evaluation_reason_id === null ? undefined : values.crew_evaluation_reason_id,
    criteria: rest?.criteria?.length
      ? rest?.criteria?.map(criterion => {
          const { category_id, name, value_type, ...criterionRest } = criterion;

          return criterionRest;
        })
      : undefined
  };
};

export const mapCriteria = (selectedCriteria, allCriteria) => {
  const mappedCriteria = allCriteria
    .map(item => {
      const selected = selectedCriteria.find(c => c.criterion_id === item.criterion.id);
      return selected
        ? {
            ...selected,
            name: item.criterion.name,
            category_id: item.category_id,
            value_type: item.value_type
          }
        : null;
    })
    .filter(t => t);

  return mappedCriteria;
};

export const getGradeValue = (valueType, value) => {
  switch (valueType) {
    case 'question':
      return value === 0 ? 'No' : value === 1 ? 'Yes' : null;

    case 'skippable-question':
      return value === 0 ? 'No' : value === 1 ? 'Yes' : value === 2 ? 'N/A' : null;

    case 'free-text':
    case 'scale':
      return value;

    default:
      return null;
  }
};

export const getAllContractsRange = contracts => {
  return contracts?.reduce(
    (cur, item) => {
      let newRange = { ...cur };

      if (moment(item.contract_from_date).isBefore(newRange.from)) {
        newRange.from = item.contract_from_date;
      }

      if (moment(item.contract_to_date).isAfter(newRange.to)) {
        newRange.to = item.contract_to_date;
      }

      return newRange;
    },
    { from: moment(), to: moment() }
  );
};

export const getContractsInRange = (contracts, range) => {
  return _orderBy(
    contracts.filter(
      item =>
        moment(item.contract_from_date).isBefore(range.to) &&
        moment(range.from).isBefore(item.contract_to_date)
    ),
    'contract_from_date',
    'desc'
  );
};

export const getEvaluationCategories = template => {
  return _flatten(template.categories.filter(cat => cat.criteria.length).map(cr => cr.criteria));
};

export const getModalProps = (currentModalType, isOnboard) => {
  if (!currentModalType) return {};

  if (currentModalType === 'captain') {
    return {
      title: 'Send to Captain for Review',
      body: 'Are you sure you want to send this Evaluation to Captain for Review?',
      actionText: 'SEND',
      params: { sent_to_captain_for_review: true },
      icon: crew,
      color: 'primary'
    };
  } else if (currentModalType === 'seaman') {
    return {
      title: 'Send to Seaman for Consent',
      body: 'Are you sure you want to send this Evaluation to Seaman for Consent?',
      actionText: 'SEND',
      params: { sent_to_evaluatee_for_consent: true },
      icon: crewPlanning,
      color: 'primary'
    };
  } else if (currentModalType === 'submit') {
    if (isOnboard) {
      return {
        title: 'Submit to Office',
        body: 'Are you sure you want to submit this Evaluation to Office? Editing from Onboard will be locked,',
        actionText: 'SUBMIT',
        params: { submitted: true },
        icon: send,
        color: 'turquoise'
      };
    } else {
      return {
        title: 'Submit',
        body: 'Are you sure you want to submit this Evaluation? Editing from Office will be locked.',
        actionText: 'SUBMIT',
        params: { submitted: true },
        icon: send,
        color: 'primary'
      };
    }
  } else {
    return {
      title: 'Delete Evaluation',
      body: 'Are you sure you want to delete this Evaluation?',
      actionText: 'DELETE',
      icon: bin,
      iconClassName: 'text-red'
    };
  }
};

export const isDraftEvaluation = (evaluation, isOnboard) =>
  evaluation &&
  !evaluation.submitted &&
  !evaluation.sent_to_evaluatee_for_consent &&
  (isOnboard ? !evaluation.sent_to_captain_for_review : true);

export const getConsentSeamanStatusParams = data => {
  const isSendToEvaluateeForConsent = _get(data, 'evaluation.sent_to_evaluatee_for_consent');
  const hasEvaluateeConsented = _get(data, 'evaluation.has_evaluatee_consented');

  let params = {};

  if (isSendToEvaluateeForConsent) {
    if (hasEvaluateeConsented === null) {
      params = {
        tooltipMessage: `Waiting for Seaman's Response`,
        activeColor: 'warning',
        isActive: true
      };
    } else if (hasEvaluateeConsented === false) {
      params = {
        tooltipMessage: 'Seaman does not Consent',
        activeColor: 'danger',
        isActive: true
      };
    } else if (hasEvaluateeConsented === true) {
      params = {
        tooltipMessage: 'Seaman Consents',
        activeColor: 'turquoise',
        isActive: true
      };
    }
  } else {
    params = {
      isActive: false,
      tooltipMessage: 'Seaman’s Response'
    };
  }

  return params;
};

export const getFirstFilteredTemplateBasedOnDepartments = (templates, accountDepartments) =>
  templates.filter(template => {
    const templateDepartments = template?.departments;

    if (!templateDepartments?.length || !accountDepartments?.length) return false;

    const accountDepartmentLabels = accountDepartments.map(department => department.label);

    return templateDepartments.some(department =>
      accountDepartmentLabels.includes(department.label)
    );
  })?.[0];

export const getRecommendedForPromotionExpandedText = (hasRecommended, comment) => {
  if (hasRecommended) {
    return (
      <div className="d-flex align-items-center">
        <div className="text-primary fs-12 fw-medium cms-12 me-1">Recommended for Promotion</div>
        <RecommendedCommentPopover comment={comment} />
      </div>
    );
  }

  return <RecommendedCommentPopoverHidden comment={comment} />;
};

export const getRecommendedForReemploymentExpandedText = (hasRecommended, comment) => {
  switch (hasRecommended) {
    case null:
      return <RecommendedCommentPopoverHidden comment={comment} />;
    case false:
      return (
        <div className="d-flex align-items-center">
          <div className="text-coral fs-12 fw-medium ms-1 me-1">
            Not Recommended for Re-Employment
          </div>
          <RecommendedCommentPopover comment={comment} />
        </div>
      );
    case true:
      return (
        <div className="d-flex align-items-center">
          <div className="fs-12 fw-medium cms-4 me-1">Recommended for Re-Employment</div>
          <RecommendedCommentPopover comment={comment} />
        </div>
      );
    default:
      return null;
  }
};

const RecommendedCommentPopoverHidden = ({ comment }) =>
  comment ? (
    <CommentsPopover
      className="p-0 position-absolute top-50 start-50 translate-middle w-75p h-75p opacity-0"
      popoverTitle="Remarks"
      comments={comment}
      popoverProps={{ placement: 'right' }}
      svgStyle={{ width: 'inherit', height: 'inherit' }}
    />
  ) : null;

const RecommendedCommentPopover = ({ comment }) =>
  comment ? (
    <CommentsPopover
      className="p-0 min-width-fit"
      popoverTitle="Remarks"
      comments={comment}
      popoverProps={{ placement: 'right' }}
    />
  ) : null;
