export const convertToFilters = filters => {
  return Object.keys(filters).map(key => ({ name: key, operation: '=', value: filters[key] }));
};

export const convertToFiltersWithOperation = filters => {
  return Object.keys(filters)
    .filter(key => filters[key].value)
    .map(key => ({
      name: key,
      operation: filters[key].operation,
      value: filters[key].value
    }));
};
