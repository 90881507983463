import React, { useState, useEffect } from 'react';

import UploadImage from 'common/components/form/inputs/UploadImage';
import PreviewProfileImage from 'common/components/general/ProfileImage';

import { useSelector } from 'react-redux';
import { upload } from 'utils/api';
import { imageUrlSelector } from 'common/utils/image-size';
import { getApiBaseUrl } from 'utils/api';

import rolodex from 'common/assets/svg/avatars/rolodex.svg';

const ProfileImage = ({ crew, onUpdate }) => {
  const [updating, setUpdating] = useState(false);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const previewImageBaseUrl = isOnBoard ? getApiBaseUrl() : '';

  useEffect(() => {
    if (updating) {
      setUpdating(false);
    }
  }, [crew?.photo?.url]);

  return isOnBoard ? (
    <PreviewProfileImage
      size="md"
      rounded
      className="rounded"
      image={
        previewImageBaseUrl +
        imageUrlSelector({
          file: crew.photo,
          label: 'contact_profile'
        })
      }
      fallback={rolodex}
    />
  ) : (
    <UploadImage
      upload={upload}
      imageSizeLabel="contact_profile"
      innerDelete
      group={'crew.photos'}
      image={crew?.photo?.url ? crew.photo : null}
      onChange={val => {
        setUpdating(true);
        onUpdate({ id: crew.id, photo_id: val ? val.id : null, table: 'crew' });
      }}
      disabled={updating}
      fallback={rolodex}
      size={'md'}
      rounded={true}
      canDelete={false}
    />
  );
};

export default ProfileImage;
