import TYPES from './types';
import { get } from 'utils/api';
import _get from 'lodash/get';

const aliases = {
  forms_for_correction: 'dashboard/forms',
  forms_to_finalize: 'dashboard/forms',
  forms_periodical_forms: 'dashboard/form-plannings',
  forms_obligations: 'dashboard/form-obligations',
  jobs: 'dashboard/jobs',
  crew_rotations: 'dashboard/crew-rotations',
  evaluation_obligations: 'dashboard/crew-evaluation-obligations',
  events: 'dashboard/events',
  flag_extension_dispensation: 'dashboard/job-types/flag-extension-dispensation/jobs',
  findings: 'findings',
  purchasing_requisitions_dlv: 'dashboard/purchasing-suppliers'
};

const parseTableData = (table, data) => {
  switch (table) {
    case 'crew_rotations':
      const { crew_rotations, ...rest } = data;

      return {
        ...rest,
        data: crew_rotations
      };

    default:
      return data;
  }
};

export const getDashboardTableData = params => dispatch => {
  const { table, ...rest } = params;

  dispatch({ type: TYPES.GET_DASHBOARD_TABLE_DATA.START, payload: { params, table } });

  return get(`/${_get(aliases, table, table)}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_DASHBOARD_TABLE_DATA.SUCCESS,
        payload: { data: parseTableData(table, response.data), params, table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_DASHBOARD_TABLE_DATA.ERROR,
        payload: { response: error, params, table }
      });

      throw error;
    });
};

export const refetchDashboardTableData = table => dispatch => {
  dispatch({ type: TYPES.REFETCH_DASHBOARD_TABLE_DATA, payload: { table } });
};

export const getDashboardFormPlannings = params => dispatch => {
  dispatch({ type: TYPES.GET_DASHBOARD_FORM_PLANNINGS.START, payload: params });

  return get(`/dashboard/form-plannings`, params)
    .then(response => {
      dispatch({
        type: TYPES.GET_DASHBOARD_FORM_PLANNINGS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_DASHBOARD_FORM_PLANNINGS.ERROR, payload: error });

      throw error;
    });
};
