import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'common/components/general/Spinner';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import Body from 'common/components/inventory/survey/Body';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import { FormDrawer, FormBody } from 'common/components/drawer';
import { useForm, useFormState } from 'utils/hooks';
import config from './config';
import {
  getInventoryLatestSurvey,
  createInventorySurvey
} from 'common/components/inventory/store/actions';
import {
  parseNewSurveySpareParts,
  parseNewSurveyItems
} from 'common/components/inventory/store/helpers';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestSurveyFormInitialState, surveyFormHasUnsavedChanges } from './helpers';
import SvgRender from 'common/components/general/SvgRender';
import leavePage from 'common/assets/svg/actions/leave-page.svg';
import PageSaving from 'common/components/general/PageSaving';
import { selectInventoryIsSavingSurvey } from '../store/selectors';

const NewSurveyDrawer = ({ drawer, type, refetchData }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);
  const [preventionModal, setPreventionModal] = useState({
    isOpen: false,
    activeGroup: null
  });

  const dispatch = useDispatch();

  const { formState, loadValues, collectValues, resetForm } = useForm(config, {
    items: [],
    spare_parts: []
  });
  const { setSubStates } = useFormState(formState);

  const entityType = useMemo(() => (type === 'pms' ? 'spare_part' : 'store'), [type]);
  const formType = useMemo(() => (type === 'pms' ? 'spare_parts' : 'items'), [type]);
  const isSaving = useSelector(selectInventoryIsSavingSurvey);

  const loadLatestSurvey = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsInitialized(true);

      const params = { item_type: entityType };
      if (entityType === 'spare_part') params.for_survey = true;

      const data = await dispatch(getInventoryLatestSurvey(params)).unwrap();

      loadValues(getLatestSurveyFormInitialState(data, entityType));

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      resetForm();
      setIsLoading(false);
    }
  }, [dispatch, entityType, loadValues, resetForm]);

  const onCancelSurvey = useCallback(
    (groupFormState, formState) => {
      setSubStates(
        formType,
        formState.map(substate => {
          // Reset group form values
          const groupSubstate = groupFormState.find(s => s.index === substate.index);

          if (groupSubstate) {
            const updatedSubstate = { ...groupSubstate };
            updatedSubstate.state.quantity.value = '';

            if (formType === 'spare_parts') {
              updatedSubstate.state.location.value = '';
              updatedSubstate.state.quantity_new.value = '';
              updatedSubstate.state.quantity_used.value = '';
              updatedSubstate.state.quantity_in_repair.value = '';
            }

            return updatedSubstate;
          }

          return substate;
        })
      );
    },
    [formType, setSubStates]
  );

  const onSaveSurvey = useCallback(
    async (groupFormState, formState) => {
      try {
        const values = collectValues();
        const { items, spare_parts } = values;
        const params = { items: [] };

        if (entityType === 'store') {
          params.items = parseNewSurveyItems(items);
        } else {
          params.items = parseNewSurveySpareParts(spare_parts);
        }

        params.items = params.items.filter(item =>
          groupFormState.find(({ state }) => state.id.value === item.entity_id)
        );

        if (!params.items?.length) return;

        const res = await dispatch(createInventorySurvey(params)).unwrap();

        const updatedGroupFormState = groupFormState.map(groupSubstate => {
          const updatedSubstate = { ...groupSubstate };

          if (params.items.find(item => item.entity_id === groupSubstate.state.id.value)) {
            updatedSubstate.state.last_survery_timestamp.value = res?.created_at;
            updatedSubstate.state.previous_rob.value = updatedSubstate.state.quantity.value;
          }

          return updatedSubstate;
        });

        setActiveGroup(null);
        onCancelSurvey(updatedGroupFormState, formState);
        refetchData();
      } catch (err) {
        console.error(err);
      }
    },
    [collectValues, dispatch, entityType, onCancelSurvey, refetchData]
  );

  const onChangeActiveGroup = useCallback(
    (newGroupId, previousActiveGroupFormState, formState) => {
      if (!activeGroup) {
        setActiveGroup(newGroupId);
      } else if (activeGroup !== newGroupId) {
        const hasUnsavedData = surveyFormHasUnsavedChanges(
          previousActiveGroupFormState,
          entityType
        );

        if (hasUnsavedData) {
          setPreventionModal({
            isOpen: true,
            activeGroup: newGroupId,
            previousActiveGroupFormState,
            formState
          });
          return;
        }

        setActiveGroup(newGroupId);
      } else if (activeGroup === newGroupId) {
        onChangeActiveGroup(null, previousActiveGroupFormState, formState);
      }
    },
    [entityType, activeGroup]
  );

  const onCancelLeavingSurvey = useCallback(
    () => setPreventionModal({ isOpen: false, activeGroup: null }),
    []
  );

  const onLeaveSurvey = useCallback(() => {
    onCancelSurvey(preventionModal.previousActiveGroupFormState, preventionModal.formState);
    setActiveGroup(preventionModal.activeGroup);
    setPreventionModal({ isOpen: false, activeGroup: null });
  }, [
    onCancelSurvey,
    preventionModal.activeGroup,
    preventionModal.formState,
    preventionModal.previousActiveGroupFormState
  ]);

  useEffect(() => {
    setActiveGroup(null);
    if (drawer?.isOpen && !isInitialized) {
      loadLatestSurvey();
    } else if (!drawer.isOpen) {
      setIsInitialized(false);
    }
  }, [drawer.isOpen, isInitialized, loadLatestSurvey]);

  return (
    <Drawer className="inventory-survery-drawer" {...drawer} size="md">
      <DrawerHeader>New Survey</DrawerHeader>

      <FormDrawer>
        <FormBody className="px-4">
          {isLoading ? (
            <Spinner />
          ) : (
            <Body
              formState={formState}
              formType={formType}
              isDrawerOpen={drawer.isOpen}
              activeGroup={activeGroup}
              onChangeActiveGroup={onChangeActiveGroup}
              onSaveSurvey={onSaveSurvey}
            />
          )}

          <DangerousActionModal
            transparent
            action={'save'}
            onAccept={onLeaveSurvey}
            closeModal={onCancelLeavingSurvey}
            isOpen={preventionModal.isOpen}
            actionText="LEAVE"
            cancelText="CANCEL"
            actionHoverColor="primary"
            actionIcon={<SvgRender src={leavePage} style={{ width: 85, height: 82 }} />}
            header={'Leave without saving'}
            body={
              <div className="text-center">
                Are you sure you want to leave? <br />
                You are going to lose all of your changes.
              </div>
            }
            contentClassName="modal-content-box-shadow"
            container=".inventory-survery-drawer"
            backdropClassName="blurred-backdrop"
          />

          <PageSaving isSaving={isSaving} />
        </FormBody>
      </FormDrawer>
    </Drawer>
  );
};

NewSurveyDrawer.propTypes = {
  drawer: DrawerState.isRequired,
  type: PropTypes.oneOf(['pms', 'purchasing']).isRequired,
  refetchData: PropTypes.func.isRequired
};

export default NewSurveyDrawer;
