import React from 'react';
import CircledButton from 'common/components/buttons/CircledButton';

const Actions = ({
  active,
  isSubmitting,
  setPreventionModal,
  handleTicketDelete,
  handleSubmitForm,
  previewMode,
  setPreviewMode,
  closeForm,
  resetForm,
  hasErrors,
  offersCount
}) => {
  if (!active || (active && !active.accepted && !previewMode)) {
    // create mode or editing a not-accepted offer
    return (
      <div className="d-flex w-75p align-items-center justify-content-end mt-n2 ms-auto position-relative z-index-3">
        {active ? (
          <CircledButton
            disabled={isSubmitting}
            className="me-1 ticket-action"
            type={'remove'}
            onClick={() =>
              setPreventionModal({
                open: true,
                actionText: 'DELETE',
                onClose: () => setPreventionModal(null),
                onAccept: handleTicketDelete,
                header: 'Delete Offer',
                message:
                  offersCount > 1 ? (
                    'Are you sure you want to delete this offer?'
                  ) : (
                    <span>
                      By deleting this last offer,
                      <b />
                      you are also deleting this ticket.
                      <b /> Are you sure you want to continue?
                    </span>
                  )
              })
            }
          />
        ) : null}
        {closeForm ? (
          <CircledButton
            disabled={isSubmitting}
            className="me-1 ticket-action"
            type={'close'}
            onClick={() => {
              if (active) {
                setPreviewMode(true);
                resetForm();
              } else {
                closeForm();
                resetForm();
              }
            }}
          />
        ) : null}
        <CircledButton
          disabled={isSubmitting || hasErrors}
          type={'save'}
          className="ticket-action"
          data-cy="save_ticket"
          onClick={handleSubmitForm}
        />
      </div>
    );
  }

  return null;
};

export default Actions;
