import React from 'react';
import ContentLoader from 'react-content-loader';
import EmptyStateLoaders from './EmptyStateLoaders';

const ProfileLoader = ({ loading, isEmpty = false }) => (
  <div className="job-reminder-container-loader job-reminder-container-loader-profile">
    <div className="job-reminder-container-loader-svg">
      <ContentLoader
        speed={1}
        viewBox="0 0 2361 868"
        backgroundColor="#f5f5f5"
        foregroundColor={loading ? '#ebebeb' : '#f5f5f5'}
        animate={loading}
      >
        <rect x="90" y="70" rx="18" ry="18" width="1200" height="36" />
        <rect x="2000" y="70" rx="18" ry="18" width="250" height="36" />
        <rect x="90" y="142" rx="18" ry="18" width="600" height="36" />

        <rect x="90" y="250" rx="18" ry="18" width="1200" height="36" />
        <rect x="2000" y="250" rx="18" ry="18" width="250" height="36" />
        <rect x="90" y="322" rx="18" ry="18" width="600" height="36" />

        <rect x="90" y="430" rx="18" ry="18" width="1200" height="36" />
        <rect x="2000" y="430" rx="18" ry="18" width="250" height="36" />
        <rect x="90" y="502" rx="18" ry="18" width="600" height="36" />
      </ContentLoader>
      <EmptyStateLoaders isEmpty={isEmpty} />
    </div>
  </div>
);

export default ProfileLoader;
