import { Row, Col } from 'reactstrap';
import { useCallback, useEffect, useState, FC } from 'react';
import _get from 'lodash/get';

import { displayDate } from '@/ts-common/utils/dates';
import { numberToStr } from '@/ts-common/utils/numbers';
import CircledButton from '@/ts-common/components/buttons/CircledButton.tsx';

import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal.tsx';
import ShadowBox from '@/common/components/general/ShadowBox';
import { getMgaForexRate, deleteMgaForexRate } from '@/common/components/mga/store/actions-ts';
import { selectPastRates } from '@/common/components/mga/store/selectors-ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import BarLabel from './components/BarLabel';
import BaseToCurrectCurrency from './components/BaseToCurrentCurrency';
import { PastRatesType } from '@/common/types/mga.ts';

type SingleRateProps = {
  pastRate: PastRatesType;
  isSubmitting: boolean;
  onDelete: (id: number) => void;
};

type ModalType = {
  isOpen: boolean;
  id: number | null;
};

const SingleRate: FC<SingleRateProps> = ({ pastRate, isSubmitting, onDelete }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const fromDate = _get(pastRate, 'from_date');
  const toDate = _get(pastRate, 'to_date');
  const baseCurrencyLabel = _get(pastRate, 'base_currency.label');
  const currentCurrencyLabel = _get(pastRate, 'currency.label');
  const rate = _get(pastRate, 'rate');
  const id = _get(pastRate, 'id');

  return (
    <ShadowBox
      color="light-1"
      shadowOnHover
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="cps-12 cpe-2 cpy-4 cmb-2 h-26"
      flat
      key={id}
    >
      <Row>
        <Col xs={4} className="fs-12 text-primary">
          {displayDate(fromDate, { time: true })} - {displayDate(toDate, { time: true })}
        </Col>

        <Col xs={3}>
          <BaseToCurrectCurrency
            baseCurrencyLabel={baseCurrencyLabel}
            currentCurrencyLabel={currentCurrencyLabel}
          />
        </Col>

        <Col xs={4} className="fs-12 text-primary">
          {numberToStr(rate, 4, 4)}
        </Col>

        <Col className="d-flex align-items-center">
          {isHovered ? (
            <CircledButton
              size={16}
              disabled={isSubmitting}
              svgStyle={{ width: 8, height: 8 }}
              type="remove"
              onClick={() => onDelete(id)}
            />
          ) : null}
        </Col>
      </Row>
    </ShadowBox>
  );
};

const PastRates = () => {
  const dispatch = useAppDispatch();
  const pastRates = useAppSelector(selectPastRates);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [modal, setModal] = useState<ModalType>({ isOpen: false, id: null });

  const fetchPastRates = useCallback(async () => {
    try {
      await dispatch(getMgaForexRate()).unwrap();
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPastRates();
  }, [fetchPastRates]);

  const onDelete = async () => {
    const id = modal.id;

    if (!id) return null;

    try {
      setIsSubmitting(true);

      await dispatch(deleteMgaForexRate({ id })).unwrap();

      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);

      console.error(err);
    }
  };

  if (!pastRates?.length) return null;

  return (
    <div className="mb-4 cpb-2">
      <BarLabel title="PAST RATES" />

      {pastRates.map(pastRate => (
        <SingleRate
          key={pastRate?.id}
          pastRate={pastRate}
          isSubmitting={isSubmitting}
          onDelete={id => setModal({ isOpen: true, id })}
        />
      ))}

      <DangerousActionModal
        action={'Remove'}
        onAccept={onDelete}
        contentClassName="modal-content-box-shadow"
        container=".setup-forex-rate-form"
        backdropClassName="blurred-backdrop"
        closeModal={() => setModal({ isOpen: false, id: null })}
        onCancel={() => setModal({ isOpen: false, id: null })}
        isOpen={modal.isOpen}
        acceptButtonText="REMOVE"
        cancelButtonText="CANCEL"
        header={'Delete Rate'}
      >
        <div className="text-center">
          Are you sure that you want to delete this rate? This cannot be undone.
        </div>
      </DangerousActionModal>
    </div>
  );
};

export default PastRates;
