import React, { useState, useEffect } from 'react';
import Input from 'common/components/form/inputs/Input';
import useDebounce from 'common/utils/hooks/useDebounce';

const TextInput = ({
  type = 'text',
  placeholder = 'Text',
  filter,
  updateFilter,
  wrapperClassName = '',
  ...rest
}) => {
  const [textFieldValue, setTextFieldValue] = useState(filter.value ? filter.value : '');
  const debouncedSearchTerm = useDebounce(textFieldValue, 800);

  useEffect(
    () => {
      if (debouncedSearchTerm || (!debouncedSearchTerm && filter.column && filter.operation))
        updateFilter({ value: debouncedSearchTerm });
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    setTextFieldValue(filter.value);
  }, [filter.value]);

  return (
    <Input
      autoFocus={true}
      type={type}
      className={`${wrapperClassName}`}
      value={textFieldValue}
      onChange={e => setTextFieldValue(e.target.value)}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default TextInput;
