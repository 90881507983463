import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { selectFieldVisibility } from 'captain-reports/store/selectors';
import useValue from 'captain-reports/utils/useValue';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

import { numberToStr } from 'common/utils/numbers';

const TotalRemovedField = ({ field, tooltip }) => {
  const [isViewOnly, setIsViewOnly] = useState(false);

  const isSludgeRemoveIncineratedVisible = useSelector(state =>
    selectFieldVisibility(state, 'sludge_removed_incinerated')
  );

  const isSludgeRemovedEvaporatedVisible = useSelector(state =>
    selectFieldVisibility(state, 'sludge_removed_evaporated')
  );

  const sludge_removed_total = useValue({ key: 'sludge_removed_total' });

  useEffect(() => {
    if (isSludgeRemoveIncineratedVisible || isSludgeRemovedEvaporatedVisible) {
      setIsViewOnly(true);
    } else {
      setIsViewOnly(false);
    }
  }, [isSludgeRemoveIncineratedVisible, isSludgeRemovedEvaporatedVisible]);

  return (
    <Row className="align-items-center">
      <Col xs={12}>
        <div className={`${isViewOnly ? 'd-none' : ''}`}>
          <NumberField field={field} />
        </div>

        {isViewOnly ? (
          <>
            <ValidationWrapper
              field={{ ...field, label: 'TOTAL REMOVED' }}
              labelClassName="cpt-4"
              value={sludge_removed_total}
              errorClassName=" pe-1 cme-2"
            >
              <div className="w-100p fs-12 fw-bold height-24">
                {numberToStr(sludge_removed_total)}
              </div>
            </ValidationWrapper>
          </>
        ) : null}
      </Col>
    </Row>
  );
};

export default TotalRemovedField;
