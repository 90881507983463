import React, { useRef, useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const OverFlownText = ({ text, id, width = 150, placement = 'top', className, onClick }) => {
  const parentElement = useRef(null);
  const overflownElement = useRef(null);

  const [rendered, setRendered] = useState(false);

  const overflows = (parent, child) => {
    return parent.scrollWidth < child.scrollWidth;
  };

  const showTooltip =
    overflownElement.current &&
    parentElement.current &&
    overflows(parentElement.current, overflownElement.current);

  useEffect(() => {
    setRendered(true);
  }, []);

  const { avoidRender, tooltipID } = useTooltipID(id);

  if (avoidRender) return null;

  return (
    <div onClick={onClick ? onClick : () => {}} ref={parentElement} style={{ maxWidth: width }}>
      <div
        className={`text-nowrap text-truncate overflow-x-hidden  ${
          showTooltip ? 'text-truncate' : ''
        } ${className ? className : ''}`}
        ref={overflownElement}
        id={tooltipID}
      >
        {text}
      </div>
      {showTooltip && rendered && (
        <UncontrolledTooltip
          boundariesElement="window"
          arrowClassName="d-none"
          placement={placement}
          target={tooltipID}
        >
          {text}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

OverFlownText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end'
  ])
};

export default OverFlownText;
