import React, { useState } from 'react';
import ModularTable from 'common/components/ModularTable';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTableListData,
  selectTableListInvalid,
  selectTableListPaging
} from 'common/components/table/store/selectors';
import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import { TableTopFilters } from 'common/entities/tables/TopFilterTypes';
import { itemsColumns, itemsRows, sparePartsRows } from './tableConfig';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import history from 'common/assets/svg/common/history.svg';
import SvgRender from 'common/components/general/SvgRender';

import { Button } from 'reactstrap';

import { setHistoryInventoryDrawerAction } from 'common/components/inventory/store/slice';
import { updateInventoryItem } from 'common/components/inventory/store/actions';

import hideSvg from 'common/assets/svg/common/hide.svg';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import config from '@/common/components/shared-table-configs/index.ts';

const INVENTORY_ITEMS_LABEL = 'inventory_items';
const INVENTORY_SPARE_PARTS_LABEL = 'inventory_spare_parts';

const Table = ({
  label,
  refetchData,
  topFilters,
  setTopFilters,
  filters,
  setFilters,
  singleVesselView,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ isOpen: false, data: null });

  const data = useSelector(state => selectTableListData(state, label));
  const paging = useSelector(state => selectTableListPaging(state, label));
  const invalid = useSelector(state => selectTableListInvalid(state, label));
  const isOnBoard = useSelector(state => state.isOnBoard);

  const isItemsInventory = label === INVENTORY_ITEMS_LABEL;
  const isPmsInventory = label === INVENTORY_SPARE_PARTS_LABEL;

  const sparePartsColumns = config[INVENTORY_SPARE_PARTS_LABEL].getColumns();

  const onHistoryActionClick = async data => {
    await dispatch(
      setHistoryInventoryDrawerAction({
        isOpen: true,
        active: {
          entity_id: data.item_id,
          entity_type: data.item_type,
          entity_name: data.item?.name || data.item?.description,
          entity_vessel_id: data.vessel_id
        }
      })
    );
  };

  return (
    <div className={`inventory-table pb-2`}>
      <ModularTable
        hideTableFilters={isItemsInventory}
        setPageMaxHeight={false}
        highlightColumnOnHover={false}
        hideTopPagination={true}
        hideTableReorder={true}
        hideBottomPagination={isOnBoard || singleVesselView}
        topFilters={topFilters}
        setTopFilters={setTopFilters}
        scrollToTopAfterPageChanged={false}
        columns={constructColumns(isItemsInventory ? itemsColumns : sparePartsColumns, [], label)}
        rows={{
          ...(isItemsInventory ? itemsRows : sparePartsRows),
          history: data =>
            data.isGroup || data.isVessel ? null : (
              <div className="d-flex align-items-center">
                <div>
                  <Button
                    className={`text-white d-inline-flex align-items-center`}
                    type="button"
                    color={'violet'}
                    onClick={() => onHistoryActionClick(data)}
                    size={'xs'}
                  >
                    <SvgRender
                      src={history}
                      className="text-white"
                      style={{ width: 12, height: 12 }}
                    />
                  </Button>
                </div>

                {isPmsInventory && data?.id && !isOnBoard ? (
                  <Button
                    className={`cms-4 text-white d-inline-flex align-items-center`}
                    type="button"
                    color={'white'}
                    onClick={() => setModal({ isOpen: true, data })}
                    size={'xs'}
                  >
                    <SvgRender
                      src={data.hidden ? eyeSvg : hideSvg}
                      className="text-primary"
                      style={{ width: 12, height: 12 }}
                    />
                  </Button>
                ) : null}
              </div>
            )
        }}
        loader={false}
        state={{ invalid, data, paging }}
        label={label}
        refetchData={refetchData}
        filters={filters}
        setFilters={setFilters}
        {...rest}
      />

      <DangerousActionModal
        transparent
        onAccept={async () => {
          if (!modal.data?.id) return;

          await dispatch(
            updateInventoryItem({
              id: modal.data?.id,
              hidden: !modal.data?.hidden
            })
          );
          refetchData();
          setModal({ isOpen: false, data: null });
        }}
        actionIcon={
          <SvgRender
            src={modal.data?.hidden ? eyeSvg : hideSvg}
            style={{ width: 85, height: 80 }}
          />
        }
        closeModal={() => setModal({ isOpen: false, data: null })}
        isOpen={modal.isOpen}
        actionText={modal.data?.hidden ? 'UNHIDE' : 'HIDE'}
        header={`${modal.data?.hidden ? 'Unhide' : 'Hide'} Item`}
        actionHoverColor="primary"
        actionButtonColor="primary"
        body={
          <div className="text-center">
            Are you sure you want to {modal.data?.hidden ? 'unhide' : 'hide'}
            <span className="fw-bold cms-4">{`${
              modal.data?.item?.drawing_no ? `${modal.data?.item?.drawing_no}. ` : ''
            }${modal.data?.item?.description || '-'}`}</span>
            ?
          </div>
        }
      />
    </div>
  );
};

Table.propTypes = {
  topFilters: TableTopFilters,
  setTopFilters: PropTypes.func,
  label: PropTypes.string.isRequired,
  refetchData: PropTypes.func.isRequired,
  singleVesselView: PropTypes.bool,
  filters: PropTypes.array,
  setFilters: PropTypes.func
};

export default Table;
