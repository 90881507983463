import React, { useCallback, useEffect, useState } from 'react';
import Table from 'views/dashboard/components/Table';
// import NamesInTooltip from 'common/components/general/NamesInTooltip';
import CrewRotationType from 'common/components/crew/CrewRotationType';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import { useActions } from 'utils/hooks';
import * as warningActions from 'store/dashboard/warnings/actions';

import moment from 'moment';
import _get from 'lodash/get';

import { useSelector } from 'react-redux';
import selectCrewDateOfChange, { selectCrewPort } from 'store/dashboard/tables/selectors';
import { CrewRanks } from 'views/dashboard/components/CrewRanks';
import paths from '@/routing/routes/_paths';
import LinkTo from '@/common/components/general/LinkTo';

const TABLE_LABEL = 'crew_rotations';

const CrewSwitches = () => {
  const [shouldShowWarning, setShouldShowWarning] = useState(false);

  const [getCrewScheduleWarnings] = useActions([warningActions.getCrewScheduleWarnings]);

  const date = useSelector(state => selectCrewDateOfChange(state, TABLE_LABEL));
  const port = useSelector(state => selectCrewPort(state, TABLE_LABEL));

  const fetchWarnings = useCallback(async () => {
    try {
      const res = await getCrewScheduleWarnings();
      const hasScheduleWarning = (res || []).find(w => w.label === 'crew-list-updated');

      setShouldShowWarning(!!hasScheduleWarning);
    } catch (error) {
      setShouldShowWarning(false);
    }
  }, []);

  useEffect(() => {
    fetchWarnings();
  }, [fetchWarnings]);

  return (
    <div className="crew-switches d-flex flex-column">
      <Table
        className="mt-8"
        customHeaderComponent={
          <div className="d-flex justify-content-between">
            <h3 className="narrow-black fs-18 text-primary mt-0">Crew Change</h3>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center cme-20">
                {shouldShowWarning ? (
                  <span className="schedule-warning fs-12 text-red px-1 cpy-4 rounded-lg">
                    *Please <strong>Publish</strong> new Working Schedule. Crew List has been
                    updated.
                  </span>
                ) : (
                  <span className="fs-12 text-violet bg-light-gray-2 px-1 cpy-4 rounded-lg">
                    *Do not forget to <strong>Publish</strong> a new Working Schedule once the Crew
                    List is updated
                  </span>
                )}
              </div>
              <LinkTo size="sm" to={paths.crew} />
            </div>
          </div>
        }
        paginated={false}
        subheader={
          <span className="d-block mb-3">
            <span className="text-violet pe-3">
              Port -{' '}
              {port ? (
                <strong>{`${_get(port, 'name', '-')} | ${_get(port, 'code', '-')}`}</strong>
              ) : (
                ''
              )}
            </span>
            <span className="text-violet pe-3">
              DOS - {date ? <strong>{moment(date).format('DD MMM YYYY')}</strong> : ''}
            </span>
          </span>
        }
        label={TABLE_LABEL}
        columns={[
          {
            header: (
              <CrewRotationType
                type="off_signer"
                textClassName="fs-10 text-violet text-uppercase"
                svgClassName="text-violet"
              />
            ),
            headerClassName: 'd-flex align-items-center ps-3 cells-border off-signer',
            key: 'off_signer',
            width: 3,
            className: 'ps-3 cells-border off-signer'
          },
          { header: 'RANK', key: 'off_signer_rank', width: 3, className: 'pe-3' },
          {
            key: 'off_signer_cells_bg',
            width: 6,
            headerClassName: 'd-none',
            className: 'cells-bg off-signer'
          },

          {
            header: (
              <CrewRotationType
                type="on_signer"
                textClassName="fs-10 text-green text-uppercase"
                svgClassName="text-green"
              />
            ),
            headerClassName: 'd-flex align-items-center ps-3 cells-border on-signer',
            key: 'on_signer',
            width: 3,
            className: 'ps-3 cells-border on-signer'
          },
          { header: 'RANK', key: 'on_signer_rank', width: 3 },
          {
            key: 'on_signer_cells_bg',
            width: 6,
            headerClassName: 'd-none',
            className: 'cells-bg on-signer'
          }
        ]}
        rows={{
          off_signer: data => (
            <TextWithTooltip className="d-flex align-items-center">
              {_get(data, 'off_signer.full_name')}
            </TextWithTooltip>
          ),
          off_signer_rank: data => {
            const currentRankName = _get(data, 'off_signer.rank.name');
            const promotionRankName = _get(
              data,
              'off_signer.active_promotion_plan.promotion_rank.name'
            );

            return (
              <div className="pe-3">
                <CrewRanks
                  currentRankName={currentRankName}
                  promotionRankName={promotionRankName}
                />
              </div>
            );
          },
          // off_signer_rank: data =>
          //   data?.off_signer_contracts_ordered?.length > 1 ? (
          //     <>
          //       <NamesInTooltip
          //         target={`crew-rank-${data.id}`}
          //         names={data.off_signer_contracts_ordered.map(r => r.rank.name)}
          //         innerTextClass="cms-6 text-primary fs-12 fw-bold"
          //       />
          //     </>
          //   ) : (
          //     data?.off_signer_contracts_ordered?.[0]?.rank?.name || ''
          //   ),
          on_signer: data => {
            const noReplacementNeeded = !data?.intend_to_replace_off_signer;

            return noReplacementNeeded ? (
              <div className="fs-12 text-violet">No Replacement Needed</div>
            ) : (
              <TextWithTooltip className="d-flex align-items-center">
                {_get(data, 'on_signer.full_name')}
              </TextWithTooltip>
            );
          },
          on_signer_rank: data => {
            const currentRankName = _get(data, 'on_signer.rank.name');
            const promotionRankName = _get(
              data,
              'on_signer.active_promotion_plan.promotion_rank.name'
            );

            return (
              <div className="pe-2">
                <CrewRanks
                  currentRankName={currentRankName}
                  promotionRankName={promotionRankName}
                />
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default CrewSwitches;
