import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';
import { useEffect } from 'react';
import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import { strToNumber } from 'common/utils/numbers';

const BaseNumberField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [setWarning] = useActions([reportActions.setWarning]);

  const co1_value = useValue({
    key: 'co_1_base_number',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const co2_value = useValue({
    key: 'co_2_base_number',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const co3_value = useValue({
    key: 'co_3_base_number',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const co1_rob = useValue({
    key: 'co_1_rob',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const co2_rob = useValue({
    key: 'co_2_rob',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const co3_rob = useValue({
    key: 'co_3_rob',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const currentValue = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const selectRobValue = () => {
    switch (field.key) {
      case 'co_1_base_number':
        return co1_rob;
      case 'co_2_base_number':
        return co2_rob;
      case 'co_3_base_number':
        return co3_rob;
      default:
        return null;
    }
  };

  const robValue = selectRobValue();

  const co_values = [
    { value: strToNumber(co1_value), key: 'co_1_base_number', isDuplicated: false },
    { value: strToNumber(co2_value), key: 'co_2_base_number', isDuplicated: false },
    { value: strToNumber(co3_value), key: 'co_3_base_number', isDuplicated: false }
  ]?.filter(e => e.value);

  const duplicatedData = co_values.map(e => ({
    ...e,
    isDuplicated: co_values?.find(inner => e.value === inner.value && inner.key !== e.key)
      ? true
      : false
  }));

  const hasDuplicatedValues = duplicatedData?.some(e => e.isDuplicated);

  const isTheKeySame = duplicatedData?.find(e => e.key === field.key && e.isDuplicated === true)
    ? true
    : false;

  const selectValidationMessage = () => {
    let message = null;
    if (robValue !== null && !currentValue) {
      message = 'Field is required';
    } else if (isTheKeySame && hasDuplicatedValues) {
      message = 'Co value already exists';
    } else {
      message = null;
    }

    return message;
  };

  useEffect(() => {
    const errorMessage = selectValidationMessage();

    setWarning({
      key: field.key,
      isRequired: true,
      error: errorMessage,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  }, [isTheKeySame, hasDuplicatedValues, currentValue, robValue]);

  return (
    <DynamicSelect
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      {...field}
    />
  );
};

export default BaseNumberField;
