import _pickBy from 'lodash/pickBy';
import _isArray from 'lodash/isArray';

export const checkIfArrayHasValues = array => {
  return array.some(
    element =>
      Object.keys(
        _pickBy(element, val => (_isArray(val) ? checkIfArrayHasValues(val) : val !== null))
      ).length !== 0
  );
};

export const isReportStatusLocked = (status, isOnboard, isEdittingApprovedReport) => {
  const officeLockedStatuses = ['approved', 'rebuild', 'draft'];
  const onBoardLockedStatuses = ['approved', 'pending'];

  if (isOnboard) {
    return onBoardLockedStatuses.includes(status);
  } else {
    if (status === 'approved' && isEdittingApprovedReport) {
      return false;
    }

    return officeLockedStatuses.includes(status);
  }
};
