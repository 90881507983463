import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { selectAccount } from 'store/account/selectors';

import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';
import ActiveTabViewActions from 'common/components/layouts/vertical-tab-view/components/ActiveTabViewActions';

import airplane from 'common/assets/svg/crew/airplane-thick.svg';
import availability from 'common/assets/svg/common/availability.svg';

import { selectCrewProfile } from 'crew/store/profile/selectors';

import _get from 'lodash/get';
import moment from 'moment';
import VesselGroupLabel from 'common/components/vessels/components/VesselGroupLabel';
import { isAuthorized } from 'utils/permissions/authorize';

const RightSidePreview = ({ config }) => {
  const account = useSelector(selectAccount);
  const crew = useSelector(selectCrewProfile);
  const [mode, setMode] = useState('view');
  const hasViewPermission = isAuthorized(account, config.componentProps?.permissions);

  const onCloseEditMode = () => {
    setMode('view');
  };

  const setEditMode = () => {
    setMode('edit');
  };

  if (!hasViewPermission) return null;

  return (
    <ShadowBox
      flat={mode === 'edit'}
      className="mb-2 position-relative crew-nearest-airport-wrapper"
    >
      {config.form && (
        <ActiveTabViewActions
          onCloseEditMode={onCloseEditMode}
          mode={mode}
          editPermissions={config.componentProps?.editPermissions}
          onEditClick={setEditMode}
        />
      )}

      {mode === 'edit' && config.form ? (
        <config.form
          config={_get(config, 'componentProps.config', null)}
          onCloseEditMode={onCloseEditMode}
          mode={mode}
        />
      ) : (
        <div className="fs-12">
          <div className="text-violet cpb-12 mb-2 border-bottom">Nearest Airport</div>
          <Row>
            <Col xs={'auto'} className="crew-nearest-airport-preview">
              <div className="d-flex align-items-center mb-2">
                <SvgRender
                  src={airplane}
                  style={{ width: 16, height: 16 }}
                  className="me-1 text-purple"
                />
                <span className="fw-medium">Airport</span>
              </div>
              <div>
                {_get(crew, 'nearest_airport.name', null) || '-'}{' '}
                {crew && crew.nearest_airport_country
                  ? `(${crew.nearest_airport_country.code}), ${crew.nearest_airport_country.name}`
                  : null}
              </div>
            </Col>
            <Col className="mb-4">
              <div className="d-flex align-items-center mb-2">
                <SvgRender
                  src={availability}
                  style={{ width: 16, height: 16 }}
                  className="me-1 text-purple"
                />
                <span className="fw-medium">Availability</span>
              </div>
              <div>
                {crew.available_at ? moment(crew.available_at).format('MMMM DD, YYYY') : '-'}
              </div>
            </Col>
          </Row>
          <div className="text-violet cpb-12 mb-2 border-bottom">Vessel Groups</div>

          <div className="d-flex align-items-center flex-wrap">
            {crew.vessel_groups?.length
              ? crew.vessel_groups.map(vesselGroup => (
                  <VesselGroupLabel key={vesselGroup.id} vesselGroup={vesselGroup} />
                ))
              : '-'}
          </div>
        </div>
      )}
    </ShadowBox>
  );
};

export default RightSidePreview;
