import { useState, useEffect } from 'react';
import NumericInput from 'common/components/form/inputs/NumberInput';
import useDebounce from 'common/utils/hooks/useDebounce';

const NumberInput = ({
  placeholder = 'Number',
  filter,
  updateFilter,
  isAllowed = () => true,
  autoFocus = true,
  ...rest
}) => {
  const [numberFieldValue, setNumberFieldValue] = useState(filter.value ? filter.value : '');

  const debouncedNumber = useDebounce(numberFieldValue?.replace(/,/g, ''), 800);

  useEffect(
    () => {
      if (debouncedNumber || (!debouncedNumber && filter.column && filter.operation))
        updateFilter({ value: debouncedNumber });
    },
    [debouncedNumber] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    setNumberFieldValue(filter.value);
  }, [filter.value]);

  return (
    <NumericInput
      autoFocus={autoFocus}
      value={numberFieldValue}
      onChange={e => setNumberFieldValue(e.target.value)}
      placeholder={placeholder}
      isAllowed={isAllowed}
      {...rest}
    />
  );
};

export default NumberInput;
