import { AccountType } from '@/common/types/account';
import { RootState } from '@/store';

const selectAccountReducer = (state: RootState) => state.account;

export const selectAccount = (state: RootState): AccountType => selectAccountReducer(state);

export const selectAccountIsOceanAdmin = () => false;

export const selectAccountExternalEmail = (state: RootState) =>
  selectAccount(state)?.external_email;
