import PropTypes from 'prop-types';

const ActionFieldsInputTypes = {
  className: PropTypes.string,
  hiddenActions: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export const ActionsNumberInputTypes = {
  ...ActionFieldsInputTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const ActionsTextInputTypes = {
  ...ActionFieldsInputTypes,
  value: PropTypes.string
};

export const ActionsListSelectTypes = {
  ...ActionFieldsInputTypes,
  value: PropTypes.number
};

export const ActionFieldsTypes = {
  type: PropTypes.oneOf(['numberInput', 'textInput', 'listSelect']),
  row: PropTypes.func,
  col: PropTypes.shape({ key: PropTypes.string.isRequired }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })
};
