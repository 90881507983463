import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { getValue } from '../../layout/utils';

const HoldsHatches = ({ fields }) => {
  const profile = useSelector(selectVesselProfile);
  const holds = fields[0];
  const hatches = fields[1];
  return (
    <Row className="mt-2">
      <Col xs={6}>
        <Row>
          <Col
            className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
            xs={8}
          >
            CARGO HOLDS
          </Col>
        </Row>
        {holds.extraData.map(field => {
          if (field.condition && !field.condition(profile)) return null;

          if (field.component) {
            return <field.component key={field.key} field={field} />;
          }

          return (
            <Row
              key={field.key}
              className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
            >
              <Col
                className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                xs={8}
              >
                {field.label}
              </Col>
              <Col xs={4} className="fs-12  d-flex align-items-start ">
                {field.render ? (
                  field.render(profile, field)
                ) : (
                  <span>{getValue(field, profile)}</span>
                )}
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col xs={6}>
        <Row>
          <Col
            className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
            xs={6}
          >
            CARGO HATCHES
          </Col>
        </Row>
        {hatches.extraData.map(field => {
          if (field.condition && !field.condition(profile)) return null;

          if (field.component) {
            return <field.component key={field.key} field={field} />;
          }

          return (
            <Row
              key={field.key}
              className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
            >
              <Col
                className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                xs={6}
              >
                {field.label}
              </Col>
              <Col xs={6} className="fs-12  d-flex align-items-start ">
                {field.render ? (
                  field.render(profile, field)
                ) : (
                  <span>{getValue(field, profile)}</span>
                )}
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

export default HoldsHatches;
