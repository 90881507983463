import { useSelector } from 'react-redux';

import { selectCaptainReportsAlarms } from '@/captain-reports/store/alarms/selectors';

import AlertsContainer from 'common/components/alerts/AlertsContainer';

const AlarmContainer = ({ className = '' }) => {
  const alarms = useSelector(selectCaptainReportsAlarms);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return alarms.length ? (
    <AlertsContainer
      className={className}
      alerts={alarms}
      renderAlert={item => (
        <div className="d-flex align-items-center lh-15">
          <span>{isOnBoard ? '*' : '-'}</span>
          &nbsp;
          <span dangerouslySetInnerHTML={{ __html: item.message }} />
        </div>
      )}
    />
  ) : null;
};

export default AlarmContainer;
