import React from 'react';
import _get from 'lodash/get';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { displayDate, formatDate, getCustomFormat } from 'common/utils/dates';
import { numberToStr } from 'common/utils/numbers';

const renderProfileValue = (field, profileData, findex) => {
  if (field.render) {
    return field.render(profileData, field, findex);
  } else if (field.key) {
    if (field.type === 'boolean') {
      return _get(profileData, field.key, false) ? '' : 'NO';
    } else if (field.type === 'number') {
      const val = _get(profileData, field.key, null) || null;
      return val
        ? `${numberToStr(val, field.decimalScale)}${field.unit ? ` ${field.unit}` : ''}`
        : '-';
    } else if (field.type === 'date') {
      const date = _get(profileData, field.key, null);

      if (!date) return '-';
      else if (field.dateFormat) {
        return getCustomFormat(date, field.dateFormat);
      } else {
        return formatDate(date, { time: true });
      }
    } else if (field.type === 'datetime' && !field.localizeDatetime) {
      return displayDate(_get(profileData, field.key, null), 'DD/MM/YYYY HH:mm');
    } else if (field.type === 'datetime') {
      return formatDate(_get(profileData, field.key, null)) || '-';
    } else if (field.type === 'file') {
      const profileFiles = _get(profileData, field.key, []) || [];
      const files =
        field?.inputProps?.singleUpload && profileFiles?.id ? [profileFiles] : profileFiles;

      return files.length ? (
        <UploadFiles onChange={() => {}} group="uploads" files={files} viewOnly />
      ) : null;
    } else {
      return _get(profileData, field.key, '-') || '-';
    }
  } else {
    return '-';
  }
};

export default renderProfileValue;
