import { FC, useState } from 'react';

import { useGetEventOffHireQuery } from '@/api/events/event-modules/off-hire/queries';
import { useGetVesselStoppageFuelGradesQuery } from '@/api/vessels/queries';
import { FuelGrade } from '@/common/types/enums';
import { EventOffHire } from '@/common/types/events';
import FormSaveActions from '@/common/components/form/FormSaveActions';
import { useAppSelector } from '@/store/hooks';
import { selectEventType, selectEventVessel } from '@/events/store/events/selectors';

type FuelRobViewDataProps = {
  fuelGrades: FuelGrade[] | undefined;
  offHireData: EventOffHire | undefined;
  isStart?: boolean;
};

const FuelRobViewData: FC<FuelRobViewDataProps> = ({ fuelGrades, offHireData, isStart }) => {
  return (
    <div className="d-flex align-items-center flex-wrap w-50p">
      <span className="text-violet me-2">Bunkers - {isStart ? 'Start' : 'End'} ROB (mt)</span>
      <div className="d-flex align-items-center text-primary">
        {fuelGrades?.map((fuelGrade, index) => {
          const rob = offHireData?.fuel_robs?.find(rob => rob.fuel_grade_id === fuelGrade.id);
          const isLast = index === fuelGrades.length - 1;

          return (
            <div key={`view-fuel-grade-${index}`} className="d-flex align-items-center">
              <span>{fuelGrade.name}:&nbsp;</span>
              <span>{(isStart ? rob?.start_rob : rob?.end_rob) ?? '-'}</span>
              {!isLast && <div className="cmx-12">|</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

type ViewModeProps = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const ViewMode: FC<ViewModeProps> = ({ setIsEditing }) => {
  const [isHovering, setIsHovering] = useState(false);

  const vesselId = useAppSelector(selectEventVessel)?.id;
  const eventTypeLabel = useAppSelector(selectEventType)?.label;
  const shouldShowModuleEmptyState = eventTypeLabel !== 'off_hire' || !vesselId;

  const { data: stoppageData } = useGetVesselStoppageFuelGradesQuery();
  const { data: offHireData } = useGetEventOffHireQuery();

  const fuelGrades = stoppageData?.fuel_grades;

  if (shouldShowModuleEmptyState) {
    return (
      <div className="bg-white px-2 py-4 border-radius-11">
        <div className="fw-medium fs-12 text-violet text-center">
          Please select vessel & Off Hire type to see the off hire details.
        </div>
      </div>
    );
  }

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="bg-white px-2 cpy-12 border-radius-11 position-relative"
    >
      <div className="d-flex align-items-center fw-medium fs-14 cmb-12">
        <span className="text-violet">Reason:&nbsp;</span>
        <span className="text-primary">{offHireData?.off_hire_reason?.name || '-'}</span>

        <div className="text-violet cmx-12">|</div>

        <span className="text-violet">Associated Element:&nbsp;</span>
        <span className="text-primary">{offHireData?.associated_element?.name || '-'}</span>
      </div>

      <div className="d-flex align-items-center fw-medium fs-14">
        <FuelRobViewData isStart fuelGrades={fuelGrades} offHireData={offHireData} />
        <FuelRobViewData fuelGrades={fuelGrades} offHireData={offHireData} />
      </div>

      {isHovering && (
        <FormSaveActions
          mode="view"
          onEdit={() => setIsEditing(true)}
          style={{ top: 12, right: -10 }}
        />
      )}
    </div>
  );
};

export default ViewMode;
