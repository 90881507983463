import React, { useEffect } from 'react';

import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';
import { useForm } from 'utils/hooks';
import RiskAssessmentTables from './components/RiskAssessmentTables';
import { riskAssessmentSubmissionConfig } from './components/config';
import SvgRender from 'common/components/general/SvgRender';
import riskAssessment from 'common/assets/svg/common/risk_assessment.svg';

const RiskAssessmentView = ({ formField, value }) => {
  const { formState, loadValues, resetForm } = useForm(riskAssessmentSubmissionConfig, {
    primary_hazards: [],
    contingencies: [],
    additional_hazards: [],
    control_measures: []
  });

  useEffect(() => {
    if (value?.id) {
      loadValues(value);
    } else {
      resetForm();
    }
  }, [value?.id]);

  return (
    <div className="risk-assessment-field">
      <Row className="mb-3">
        <Col className="label d-flex justify-content-end fs-12 fw-bold text-primary" xs={3}>
          RISK ASSESSMENT
        </Col>
        <Col className="d-flex flex-column fs-12 cps-4" xs={6}>
          <span className="text-primary fs-12">{_get(value, 'category.name', '-')}</span>
          <div className="d-flex">
            <SvgRender
              className="text-red"
              src={riskAssessment}
              style={{ width: 13, height: 15 }}
            />
            <span className="text-violet fs-12 ms-1">{_get(value, 'name', '-')}</span>
          </div>
        </Col>
      </Row>
      <RiskAssessmentTables
        value={value}
        formState={formState}
        previewMode={true}
        formField={formField}
      />
    </div>
  );
};

export default RiskAssessmentView;
