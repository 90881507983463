import React from 'react';
import CharterType from 'common/components/general/CharterType';
import RightShipInspection from 'common/components/vessels/profile/tabs/info/components/general/RightShipInspection';
import ContactInfo from 'common/components/vessels/profile/tabs/info/components/general/ContactInfo';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
const general = [
  {
    fields: [
      {
        label: 'BUILDER NAME',
        key: 'shipyard.full_name',
        select_key: 'shipyard',
        type: 'select',
        inputProps: {
          placeholder: 'Add Name',
          isAsync: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => getPartyDisplayName(option),
          defaultOptions: () => getInitialAsyncValues('parties', null, null, { role: 'yards' }),
          loadOptions: search => getAsyncOptions(search, 'parties', { role: 'yards' }),
          isClearable: true
          // isCreatable: true
        }
      },
      {
        label: 'HULL No.',
        key: 'hull_no',
        type: 'string',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      { label: 'DELIVERY DATE', key: 'build_date', type: 'date' },
      {
        label: 'CALL SIGN',
        key: 'call_sign',
        inputProps: {
          placeholder: 'Add call Sign'
        }
      },
      {
        label: 'EMAIL',
        key: 'email',
        inputProps: {
          placeholder: 'Add Email'
        }
      },
      {
        label: 'MMSI',
        key: 'mmsi',
        inputProps: {
          placeholder: 'Add number'
        }
      },
      {
        key: 'contact_info',
        type: 'multiple',
        label: 'CONTACT INFO',
        component: ContactInfo,
        data: [
          {
            labelKey: 'type.name',
            key: 'vessel_contact_info_type_id',
            type: 'option',
            inputProps: {
              placeholder: 'Select'
            }
          },
          {
            key: 'value',
            type: 'string',
            render: false,
            inputProps: {
              placeholder: 'Add value'
            }
          },
          { key: 'show_in_fleet', type: 'boolean', render: () => null }
        ]
      },
      {
        label: 'REGISTERED OWNER',
        key: 'owner.full_name',
        select_key: 'owner',
        type: 'select',
        inputProps: {
          placeholder: 'Select owner',
          isAsync: true,
          isClearable: true,
          getOptionValue: option => option.id,
          getOptionLabel: option => getPartyDisplayName(option),
          loadOptions: search => getAsyncOptions(search, 'parties'),
          defaultOptions: () => getInitialAsyncValues('parties')
        }
      },
      {
        label: 'DISPONENT OWNER',
        key: 'disponent_owner.full_name',
        inputProps: {
          placeholder: 'Select owner',
          getOptionValue: option => option.id,
          getOptionLabel: option => getPartyDisplayName(option),
          loadOptions: search => getAsyncOptions(search, 'parties'),
          defaultOptions: () => getInitialAsyncValues('parties'),
          isClearable: true,
          isAsync: true
        },
        select_key: 'disponent_owner',
        type: 'select'
      },
      {
        label: 'OWNERSHIP TYPE',
        key: 'charter_type',
        inputProps: {
          placeholder: 'Select type'
        },
        render: profile =>
          profile && profile.charter_type ? <CharterType type={profile.charter_type} /> : '-'
      },
      // {
      //   label: 'COMMERCIAL MANAGER',
      //   key: 'official_manager.full_name',
      //   inputProps: {
      //     placeholder: 'Select manager',
      //     getOptionValue: option => option.id,
      //     isAsync: true,
      //     getOptionLabel: option => getPartyDisplayName(option),
      //     loadOptions: search => getAsyncOptions(search, 'parties'),
      //     defaultOptions: () => getInitialAsyncValues('parties'),
      //     isClearable: true
      //   },
      //   select_key: 'official_manager',
      //   type: 'select'
      // },
      // {
      //   label: 'TECHNICAL MANAGER',
      //   key: 'ship_manager.full_name',
      //   inputProps: {
      //     placeholder: 'Select manager',
      //     isAsync: true,
      //     getOptionValue: option => option.id,
      //     getOptionLabel: option => getPartyDisplayName(option),
      //     loadOptions: search => getAsyncOptions(search, 'parties'),
      //     defaultOptions: () => getInitialAsyncValues('parties'),
      //     isClearable: true
      //   },
      //   select_key: 'ship_manager',
      //   type: 'select'
      // },
      // {
      //   label: 'CREW MANAGER',
      //   key: 'crew_manager.full_name',
      //   select_key: 'crew_manager',
      //   inputProps: {
      //     placeholder: 'Select manager',
      //     getOptionValue: option => option.id,
      //     getOptionLabel: option => getPartyDisplayName(option),
      //     loadOptions: search => getAsyncOptions(search, 'parties'),
      //     defaultOptions: () => getInitialAsyncValues('parties'),
      //     isClearable: true,
      //     isAsync: true
      //   },
      //   type: 'select'
      // },
      // {
      //   label: 'OPERATOR',
      //   key: 'operator.full_name',
      //   select_key: 'operator',
      //   type: 'select',
      //   inputProps: {
      //     placeholder: 'Select operator',
      //     isAsync: true,
      //     getOptionValue: option => option.id,
      //     getOptionLabel: option => getPartyDisplayName(option),
      //     loadOptions: search => getAsyncOptions(search, 'parties'),
      //     defaultOptions: () => getInitialAsyncValues('parties'),
      //     isClearable: true
      //   }
      // },
      // {
      //   label: 'SUPERINTENDENT',
      //   key: 'superintendent.full_name',
      //   select_key: 'superintendent',
      //   type: 'select',
      //   inputProps: {
      //     placeholder: 'Select superintendent',
      //     getOptionValue: option => option.id,
      //     getOptionLabel: option => getPartyDisplayName(option),
      //     loadOptions: search => getAsyncOptions(search, 'parties'),
      //     defaultOptions: () => getInitialAsyncValues('parties'),
      //     isClearable: true,
      //     isAsync: true
      //   }
      // },
      {
        label: 'P&I CLUB',
        key: 'pi_club',
        inputProps: {
          placeholder: 'Add club'
        }
      },
      {
        label: 'P&I COVERAGE ($)',
        key: 'pi_coverage',
        type: 'number',
        inputProps: {
          placeholder: 'Add value'
        }
      },
      {
        label: 'H&M CLUB',
        key: 'hm_club',
        inputProps: {
          placeholder: 'Add club'
        }
      },
      {
        label: 'H&M VALUE ($)',
        key: 'hm_value',
        type: 'number',
        inputProps: {
          placeholder: 'Add value'
        }
      },
      {
        label: 'RIGHTSHIP RISK RATING',
        key: 'rightship_approved',
        inputProps: {
          size: 'sm'
        },
        type: 'boolean'
      },
      {
        label: 'RIGHTSHIP ENERGY RATING',
        key: 'rightship_energy_rating',
        condition: profile => profile.rightship_approved
      },
      {
        label: 'last rightship inspection:',
        condition: profile => profile.rightship_approved,
        component: RightShipInspection,
        extraData: [
          {
            label: 'DATE',
            type: 'date',
            key: 'rightship_inspection_date',
            condition: profile => profile.rightship_approved
          },
          {
            label: 'PLACE',
            key: 'rightship_inspection_place',
            inputProps: {
              placeholder: 'Add place'
            }
          }
        ]
      }
    ]
  }
];
export default general;
