import { useState, useCallback, useRef } from 'react';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import NumberInput from 'common/components/form/inputs/NumberInput';
import CircledButton from 'common/components/buttons/CircledButton';
import { ActionsNumberInputTypes } from 'common/entities/tables/ActionsField';

const ActionsNumberInput = ({
  className,
  hiddenActions = false,
  onSave = () => {},
  onCancel = () => {},
  value,
  ...rest
}) => {
  const ref = useRef();

  const [isEdit, setIsEdit] = useState(false);

  const onSaveSubmit = useCallback(
    value => {
      onSave(value);
      setIsEdit(false);
    },
    [onSave]
  );

  const onCancelSubmit = useCallback(() => {
    onCancel();
    setIsEdit(false);
  }, [onCancel]);

  const onEditChange = useCallback(value => {
    setIsEdit(value);
  }, []);

  useUpdateEffect(() => {
    if (ref.current && isEdit) {
      ref.current.focus();
    }
  }, [isEdit]);

  return (
    <div
      className={`actions-input d-flex align-items-center ${className || ''} ${
        hiddenActions ? 'pointer-events-none' : ''
      } ${isEdit ? 'is-edit' : ''} w-100p`}
    >
      <NumberInput
        disabled={!isEdit}
        getInputRef={ref}
        className={`actions-input__field cmt-4 cmb-4 me-1`}
        value={value}
        {...rest}
      />

      <div className="actions-input__button-container  d-flex align-items-center">
        {hiddenActions ? null : isEdit ? (
          <>
            <CircledButton
              type={'close'}
              label=""
              onClick={onCancelSubmit}
              className="actions-input__button--cancel cme-4"
              svgStyle={{ width: 7, height: 7 }}
            />
            <CircledButton
              type={'save'}
              label=""
              onClick={() => onSaveSubmit(value)}
              className="actions-input__button--save"
              svgStyle={{ width: 8, height: 8 }}
            />
          </>
        ) : (
          <CircledButton
            type={'edit'}
            label=""
            onClick={() => onEditChange(true)}
            className="actions-input__button--edit"
            svgStyle={{ width: 8, height: 8 }}
          />
        )}
      </div>
    </div>
  );
};

export default ActionsNumberInput;

ActionsNumberInput.propTypes = ActionsNumberInputTypes;
