import NumberInput from 'common/components/form/inputs/NumberInput';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const SeverityBubble = ({ severity }) => {
  return (
    <div
      className={`severity-bubble cme-6 ${
        severity > 12 ? 'bg-red' : severity < 5 ? 'bg-turquoise' : 'bg-yellow'
      }`}
    />
  );
};

const RiskInput = ({
  className = '',
  fields,
  severityKey,
  likelihoodKey,
  changeField,
  viewMode = false,
  editMode = true
}) => {
  const severity =
    fields[likelihoodKey].value && fields[severityKey].value
      ? fields[likelihoodKey].value * fields[severityKey].value
      : null;

  if (viewMode) {
    return severity ? (
      <div className={`d-flex align-items-center fs-12 ${className}`}>
        <SeverityBubble severity={severity} />
        <strong className="text-primary">{`${
          severity > 12 ? 'High' : severity < 5 ? 'Low' : 'Medium'
        }`}</strong>
      </div>
    ) : null;
  }

  return (
    <Container className={`d-flex align-items-center ${className}`}>
      {editMode ? (
        <>
          <NumberInput
            onChange={changeField(severityKey)}
            placeholder="S"
            value={fields[severityKey].value}
            decimalScale={0}
            error={fields[severityKey].error}
            className="mb-0 me-1 max-width-40"
            isAllowed={values => {
              return (values.floatValue > 0 && values.floatValue < 6) || !values?.floatValue;
            }}
          />
          <NumberInput
            onChange={changeField(likelihoodKey)}
            placeholder="L"
            value={fields[likelihoodKey].value}
            decimalScale={0}
            error={fields[likelihoodKey].error}
            className="mb-0 me-1 max-width-40"
            isAllowed={values => {
              return (values.floatValue > 0 && values.floatValue < 6) || !values?.floatValue;
            }}
          />
        </>
      ) : (
        <>
          <span className="fs-12 text-primary me-5">{fields[severityKey].value}</span>
          <span className="fs-12 text-primary me-5">{fields[likelihoodKey].value}</span>
        </>
      )}
      {severity ? (
        <div className="d-flex align-items-center">
          <SeverityBubble severity={severity} />
          <strong className=" text-primary fs-12">{severity}</strong>
        </div>
      ) : (
        <div className="ms-3" />
      )}
    </Container>
  );
};

const Container = styled.div`
  .form-error {
    transform: translate(0, 100%);
    bottom: -${variables.size4};
  }
`;

export default RiskInput;
