import { useState, useEffect, useMemo, FC, useCallback } from 'react';
import { FormDrawer } from 'common/components/drawer';
import { useForm } from 'utils/hooks';
import {
  selectDocumentDrawerIsOpen,
  selectDocumentDrawerType,
  selectDocumentDrawerActiveItem,
  selectDocumentsTypes
} from 'store/vessels/selectors';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectListDefaultOptions } from '@/store/lists/selectors-ts';
import {
  changeVesselDocumentDrawerStatus,
  createVesselDocumentAction,
  editVesselDocumentAction,
  getVesselDocumentAttachmentsAction,
  renewVesselDocumentAction
} from '../store/actions';
import { fetchListOptions } from '@/store/lists/actions';
import { Vessel, VesselDocumentType } from '@/common/types/vessel';
import { DrawerType } from '../store/types';

import Drawer, { DrawerHeader } from 'common/components/drawer';
import Spinner from '@/ts-common/components/general/Spinner';
import FormBody from './FormBody';
import config from './_config';
import { VesselDocumentMutationParams } from '@/api/vessels/api';
import { useDrawer } from 'common/components/drawer';
import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';
import { LOGS_ENTITY_TYPES } from '@/common/types/logs';
import LogsTimelineDrawer from '@/common/components/logs-timeline-drawer';

type DocumentsDrawerProps = {
  vesselId?: string;
  refetchData?: (active: VesselDocumentType, drawerType: DrawerType) => void;
};

const DocumentsDrawer: FC<DocumentsDrawerProps> = ({ vesselId, refetchData = null }) => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectDocumentDrawerIsOpen);
  const drawerType = useAppSelector(selectDocumentDrawerType);
  const active = useAppSelector(selectDocumentDrawerActiveItem);
  const types = useAppSelector(selectDocumentsTypes);
  const vessels = useAppSelector(state => selectListDefaultOptions(state, 'vessels'));
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const logsDrawer = useDrawer();

  const { formState, collectValues, isDirty, hasErrors, handleSubmitError, loadValues } = useForm(
    config,
    {}
  );

  const template = useMemo(() => {
    return (
      types?.find((type: VesselDocumentType) => type?.id === active?.type.id)?.template ||
      active?.type?.template
    );
  }, [types, active]);

  const vessel = useMemo(() => {
    return vesselId ? vessels?.find((vessel: Vessel) => vessel.id === parseInt(vesselId)) : null;
  }, [vessels, vesselId]);

  const close = () => dispatch(changeVesselDocumentDrawerStatus({ drawerOpen: false }));

  const resetForm = useCallback(
    () =>
      loadValues({
        category: null,
        issue_date: null,
        expiration_date: null,
        issued_by: null,
        port: null,
        comments: null,
        attachments: []
      }),
    [loadValues]
  );

  const loadDocument = useCallback(async () => {
    if (isInitialized) return;

    setLoading(true);
    setIsInitialized(true);

    try {
      const {
        document_id,
        category_name,
        id,
        expiration_date,
        extension_requested,
        completed_on,
        flag_informed_on,
        issue_date,
        issued_by,
        comments,
        type,
        port,
        range_from,
        range_to,
        postponed,
        serial_number
      } = active;

      const attachments = await dispatch(
        getVesselDocumentAttachmentsAction({ id: document_id })
      ).unwrap();

      if (drawerType === 'renew') {
        loadValues({ category: { name: category_name, id, category: type } });
      } else {
        loadValues({
          category: { name: category_name, id, category: type },
          issue_date,
          expiration_date,
          issued_by,
          port,
          comments,
          attachments,
          extension_requested,
          completed_on,
          flag_informed_on,
          range_from,
          range_to,
          postponed,
          serial_number
        });
      }

      setLoading(false);
    } catch (e) {
      resetForm();
      setLoading(false);
      setIsInitialized(true);
    }
  }, [active, isInitialized, dispatch, drawerType, loadValues, resetForm]);

  useEffect(() => {
    if (!isOpen) {
      setIsInitialized(false);
      return;
    } else {
      if (!active) {
        resetForm();
      } else {
        loadDocument();
      }
    }
  }, [active, isOpen, loadDocument, resetForm]);

  useEffect(() => {
    dispatch(fetchListOptions('vessel-document-categories'));
  }, [dispatch]);

  const submitForm = async (params: VesselDocumentMutationParams) => {
    setIsSubmitting(true);

    try {
      if (active) {
        if (drawerType === 'renew') {
          await dispatch(renewVesselDocumentAction(params)).unwrap();
        } else {
          await dispatch(editVesselDocumentAction({ ...params, id: active.document_id })).unwrap();
        }
      } else {
        await dispatch(createVesselDocumentAction(params)).unwrap();
      }

      close();
    } catch (e) {
      handleSubmitError(e);
    }

    if (refetchData) refetchData(active, drawerType);

    setIsSubmitting(false);
  };

  return (
    <>
      <Drawer
        className={`form-layout vessel-profile-document-drawer ${
          logsDrawer.isOpen ? 'logs-drawer-open' : ''
        }`}
        isOpen={isOpen}
        close={close}
      >
        <DrawerHeader className="form-layout--header d-flex  justify-content-between align-items-start">
          <div className="d-flex flex-column">
            {`${!active ? 'Add' : drawerType === 'edit' ? 'Edit' : 'Renew'} document`}
            <span className="fw-bold fs-12 text-dark cmt-4">{vessel?.name}</span>
          </div>
          {!isOnBoard && active ? (
            <ToggleLogsTimelineDrawerButton className="me-1" onClick={logsDrawer.open} />
          ) : null}
        </DrawerHeader>

        <FormDrawer>
          {loading ? (
            <div className="ps-7 pt-3">
              <Spinner />
            </div>
          ) : (
            <FormBody
              formState={formState}
              collectValues={collectValues}
              close={close}
              isDirty={isDirty}
              hasErrors={hasErrors}
              submitForm={submitForm}
              isSubmitting={isSubmitting}
              drawerType={drawerType}
              updated={
                active && active.updated_at && active.updated_by
                  ? { at: active.updated_at, by: active.updated_by }
                  : null
              }
              selectedActiveCategoryId={active ? active.id : null}
              vesselId={vesselId}
              department={active?.responsible_department}
              template={template}
            />
          )}
        </FormDrawer>
      </Drawer>
      {!isOnBoard && active ? (
        <LogsTimelineDrawer
          drawer={logsDrawer}
          entityId={active.document_id}
          entityType={LOGS_ENTITY_TYPES.vessel_document}
        />
      ) : null}
    </>
  );
};

export default DocumentsDrawer;
