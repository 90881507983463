import React, { useCallback } from 'react';
import Header from './header';
import { useSelector } from 'react-redux';
import {
  selectisEditingModules,
  selectActiveModulesLabels,
  selectIsOnboard,
  selectIsCreate,
  selectEventModuleLabels
} from 'events/store/events/selectors';
import { moduleIcons } from '../modules/config';
import SyncButton from './SyncButton';

const MainContent = () => {
  const isEditingModules = useSelector(selectisEditingModules);
  const activeModulesLabels = useSelector(selectActiveModulesLabels);
  const isOnBoard = useSelector(selectIsOnboard);
  const isCreate = useSelector(selectIsCreate);
  const eventModuleLabels = useSelector(selectEventModuleLabels);

  const createTagComponent = useCallback(
    label => {
      let Tag = null;

      if (isOnBoard && !moduleIcons[label]?.onboard) {
        Tag = null;
      } else if (!isEditingModules && moduleIcons[label]) {
        Tag = moduleIcons[label]?.component;
      }

      if (!Tag) return null;

      return (
        <div className="position-relative">
          <SyncButton label={label} />
          <Tag isCreate={isCreate} />
        </div>
      );
    },
    [isEditingModules, isOnBoard, isCreate]
  );

  return (
    <div>
      <Header />
      {!isEditingModules && eventModuleLabels?.length
        ? eventModuleLabels?.map(label =>
            activeModulesLabels.includes(label) ? createTagComponent(label) : null
          )
        : null}
    </div>
  );
};

export default MainContent;
