import { useEffect, useState, useMemo, useCallback } from 'react';
import _sortBy from 'lodash/sortBy';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { getInitialSingleValue } from 'common/utils/lists';

import payroll from 'common/assets/svg/crew/payroll.svg';
import Section from '../../components/Section';
import WageClass from '../../components/WageClass';
import OrangeLegend from '../../components/OrangeLegend';
import WageContainer from './WageContainer';
import WorkingHours from 'crew/profile/main/contracts/preview/parts/working-hours';
import Information from '@/ts-common/components/general/Information';

import { fetchListOptions } from 'store/lists/actions';
import { selectListFromStore } from 'store/lists/selectors';

import _groupBy from 'lodash/groupBy';
import { wageSectionOrder } from './helpers';

const Wages = ({ contract, crewProfile, wageSettings, isActive }) => {
  const { crew_wage, wage_account_amounts, currency } = contract;
  const [mgaAccountsData, setMgaAccountsData] = useState([]);
  const crewWages = useSelector(state => selectListFromStore(state, 'crew-wages'));
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);

  const defaultCrewWage = useMemo(
    () => (crewWages?.options || [])?.find(e => e.id === crew_wage?.id),
    [crewWages?.options, crew_wage?.id]
  );
  const defaultCrewWageHours = useMemo(
    () => (crewWages?.options || [])?.find(e => e.id === crew_wage?.id)?.hours || {},
    [crewWages?.options, crew_wage?.id]
  );
  const workingHours = useMemo(
    () =>
      Object.keys(contract?.hours || {})?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: contract?.hours[curr],
          [`default_${curr}`]: defaultCrewWageHours[curr]
        }),
        {}
      ),
    [contract?.hours, defaultCrewWageHours]
  );

  const wageSettingsWithoutType = (wageSettings || mgaAccountsData)?.filter(
    e => !e.contract_amount_payment_type
  );
  const wageSettingsGroupedByType = _groupBy(
    (wageSettings || mgaAccountsData)?.filter(e => e.contract_amount_payment_type),
    'contract_amount_payment_type'
  );
  const groupedKeys = Object.keys(wageSettingsGroupedByType);

  const getMgaAccountsData = useCallback(async () => {
    try {
      let data = await getInitialSingleValue('/mga/accounts', {
        contract_wage: true,
        show_account_page_onboard: isOnBoard ? false : true
      });

      setMgaAccountsData(data);
    } catch (e) {
      console.error(e);
    }
  }, [isOnBoard]);

  useEffect(() => {
    if (!isOnBoard) {
      dispatch(
        fetchListOptions('crew-wages', '', {
          crew_rank_id: contract?.rank?.id,
          nationality_id: crewProfile?.nationality?.id
        })
      );
    }

    dispatch(fetchListOptions('currencies', ''));

    if (isOnBoard) {
      getMgaAccountsData();
    }
  }, [contract?.rank?.id, crewProfile?.nationality?.id, dispatch, getMgaAccountsData, isOnBoard]);

  return (
    <Section
      className="mt-2 preview with-border"
      isDefaultOpened={isActive}
      header={{
        icon: payroll,
        label: 'Wages',
        subTitle: crew_wage
          ? `${crew_wage?.rank?.name} ${
              crew_wage?.nationality?.name ? `- ${crew_wage?.nationality?.name}` : ''
            } ${crew_wage?.recently_promoted ? '- Newly Promoted' : ''}`
          : '-'
      }}
      isCollapse
    >
      <Row className="cmy-12">
        <Col xs={5} className="d-flex align-items-end">
          {!isOnBoard ? <WageClass crewWage={crew_wage} /> : null}
        </Col>

        <Col className="d-flex align-items-end">
          <div className="d-flex flex-column">
            <div className="form-label text-primary fs-10">Currency</div>
            <div className="d-flex align-items-center">
              <div className="text-primary fs-12 min-w-24">{contract?.currency?.label || '-'}</div>

              <Information
                svgClassname="text-primary"
                svgStyle={{ width: 14, height: 14 }}
                className="pe-auto cursor-initial ms-1"
                tooltipClassname="max-width-none"
                message="Here we show the currencies from the MGA setup."
                target="currency-button"
              />
            </div>
          </div>
        </Col>

        {!isOnBoard ? (
          <Col className="d-flex align-items-end justify-content-end">
            <OrangeLegend />
          </Col>
        ) : null}
      </Row>
      {_sortBy(groupedKeys, section => wageSectionOrder.indexOf(section)).map(label => (
        <WageContainer
          key={label}
          label={label}
          currencyId={currency?.id}
          wageSettings={wageSettingsGroupedByType[label]}
          defaultCrewWage={defaultCrewWage}
          wage_account_amounts={wage_account_amounts?.filter(
            e => e?.contract_amount_payment_type === label
          )}
        />
      ))}
      {wageSettingsWithoutType?.length ? (
        <WageContainer
          currencyId={currency?.id}
          wageSettings={wageSettingsWithoutType}
          defaultCrewWage={defaultCrewWage}
          wage_account_amounts={wage_account_amounts}
        />
      ) : null}

      <WorkingHours workingHours={workingHours} />
    </Section>
  );
};

export default Wages;
