import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import Drawer, { DrawerHeader, FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import { useForm } from 'common/utils/hooks/useForm';
import { useFormState } from 'utils/hooks';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import { selectEventId } from 'events/store/events/selectors';
import * as underwaterServicesActions from 'events/store/event-modules/underwater-services/actions';
import DateInput from 'common/components/form/inputs/date';
import Input from 'common/components/form/inputs/Input';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import * as companiesActions from 'store/companies/actions';
import { useCallback } from 'react';
import Select from 'common/components/form/inputs/Select';
import { config } from 'events/event/modules/module-components/underwater-services/services/form/config';

const Form = ({ drawer, active, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [divers, setDivers] = useState([]);

  const { formState, collectValues, loadValues, resetForm, handleSubmitError } = useForm(
    config,
    {}
  );
  const { fields, selectField, changeField } = useFormState(formState);
  const eventId = useSelector(selectEventId);

  const [addUnderwaterService, updateUnderwaterService, getCompanyEmployeesAction] = useActions([
    underwaterServicesActions.addUnderwaterService,
    underwaterServicesActions.updateUnderwaterService,
    companiesActions.getCompanyEmployeesAction
  ]);

  const onSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      setIsSubmitting(true);

      const { company, divers, underwater_service_type, ...rest } = values;

      const params = {
        event_id: eventId,
        underwater_service_type_id: underwater_service_type.id || null,
        company_id: company?.id || null,
        diver_ids: divers || [],
        ...rest
      };

      if (active) {
        await updateUnderwaterService({ ...params, underwater_service_id: active?.id }).unwrap();
      } else {
        await addUnderwaterService(params).unwrap();
      }

      drawer.close();

      refetchData();

      setIsSubmitting(false);
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (drawer?.isOpen && active) {
      loadValues({
        ...active,
        underwater_service_type: active?.type || null,
        divers: active?.divers?.length ? active.divers.map(d => d.id) : []
      });
    } else {
      resetForm();
    }
  }, [drawer?.isOpen, resetForm, loadValues, active]);

  const getDivers = useCallback(
    async id => {
      if (id) {
        try {
          const employees = await getCompanyEmployeesAction({ id });

          setDivers(employees);
        } catch (error) {
          console.error(error);
          setDivers([]);
        }
      } else {
        setDivers([]);
      }
    },
    [getCompanyEmployeesAction]
  );

  const onCompanyChange = e => {
    selectField('company')(e);

    if (e?.id) {
      getDivers(e?.id);
    } else if (!e.id) {
      selectField('divers')(null);
      getDivers(null);
    }
  };

  return (
    <Drawer className="underwater-service-drawer" {...drawer}>
      <DrawerHeader>{active ? 'Edit' : 'Add'} Underwater Service</DrawerHeader>
      <FormDrawer>
        {drawer?.isOpen ? (
          <FormBody className={'py-2'}>
            <AsyncSelector
              label="TYPE"
              onChange={selectField('underwater_service_type')}
              placeholder="Select Type"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              className="mb-2"
              loadOptions={async search =>
                await getAsyncOptions(search, 'underwater-services-types')
              }
              defaultOptions={() => getInitialAsyncValues('underwater-services-types')}
              {...fields.underwater_service_type}
            />

            <div>
              <div className="d-flex">
                <div className="form-label">LOCATION</div>{' '}
                <div className="text-violet fs-10 lh-12 fw-bold cmb-4">&nbsp;If Shipyard</div>
              </div>
            </div>
            <Input
              type="text"
              onChange={changeField('location')}
              placeholder="Add location"
              {...fields.location}
            />

            <DateInput
              label="Date"
              value={fields.date.value}
              from={fields.date.value}
              onChange={selected => selectField('date')(selected)}
              {...fields.date}
            />

            <AsyncSelector
              label="SERVICE COMPANY"
              onChange={onCompanyChange}
              placeholder="Select company"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              className="mb-2"
              loadOptions={async search =>
                await getAsyncOptions(search, 'parties', {
                  type: 'company',
                  role: 'underwater_services'
                })
              }
              defaultOptions={() =>
                getInitialAsyncValues('parties', null, false, {
                  type: 'company',
                  role: 'underwater_services'
                })
              }
              value
              {...fields.company}
            />
            <Select
              label="DIVERS"
              onChange={selectField('divers')}
              placeholder="Select divers"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.full_name}
              className="mb-0"
              isMulti
              disabled={!fields.company.value?.id}
              options={divers || []}
              defaultOptionsTriggerChange={fields.company.value?.id}
              {...fields.divers}
            />
          </FormBody>
        ) : null}
      </FormDrawer>
      <FormFooter>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
          CANCEL
        </Button>
        <Button onClick={onSubmit} color="primary" className="px-4" disabled={isSubmitting}>
          SAVE
        </Button>
      </FormFooter>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState,
  active: PropTypes.shape({
    id: PropTypes.number
  })
};

export default Form;
