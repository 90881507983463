import { FC } from 'react';
import Slider, { SliderProps } from '@/ts-common/components/form/inputs/Slider';
import { useAppSelector } from '@/store/hooks';
import {
  selectEvaluationsRangeFrom,
  selectEvaluationsRangeTo,
  selectEvaluationsStep
} from '@/store/settings/selectors';
import { scaleNumberIsAllowed } from '@/common/utils/numbers';
import { strToNumber } from '@/ts-common/utils/numbers';

interface EvaluationSliderProps extends Omit<SliderProps, 'onChange'> {
  onChange?: (v: string | number | null) => void;
}

const EvaluationSlider: FC<EvaluationSliderProps> = ({ onChange, ...props }) => {
  const min = useAppSelector(selectEvaluationsRangeFrom);
  const max = useAppSelector(selectEvaluationsRangeTo);
  const step = useAppSelector(selectEvaluationsStep);

  const onInputChange = (val: string | number | null) => {
    if (scaleNumberIsAllowed(val, { min: min, max: max, step: step }) && onChange) {
      onChange(strToNumber(val));
    }
  };

  return (
    <Slider
      {...props}
      min={min}
      max={max}
      step={step}
      defaultValue={min}
      onAfterChange={onInputChange}
    />
  );
};

export default EvaluationSlider;
