import React from 'react';
import DateInput from 'common/components/form/inputs/date';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';

import * as reportActions from 'captain-reports/store/actions';
import useError from 'captain-reports/utils/useError';
import useInitializeFromPreviousReport from 'captain-reports/utils/useInitializeFromPreviousReport';

const DateInputField = ({
  field,
  tooltip,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const isLocked = useSelector(isReportLocked);

  useInitializeFromPreviousReport({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onDateChange = dat => {
    const formatted = dat ? dat.format('YYYY-MM-DD HH:mm:ss') : null;

    if (formatted !== value) {
      setValue({
        key: field.key,
        value: dat ? dat.format('YYYY-MM-DD HH:mm:ss') : null,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  };

  const inputProps = field.inputProps ? field.inputProps : {};

  return (
    <div className={`date-time-input form-group${error ? ' has-error' : ''}`}>
      <div className="form-label">
        <FieldLabel info={tooltip} label={field.label} subLabel={field.subLabel} />
      </div>

      <div className="d-flex align-items-center position-relative">
        <DateInput
          value={value}
          hasTime
          showTime
          className={`mb-0`}
          onChange={onDateChange}
          disabled={isLocked}
          {...inputProps}
        />
        {error && <span className="date-time-error text-red">*{error}</span>}
      </div>
    </div>
  );
};

export default DateInputField;
