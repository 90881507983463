import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { parseQueryParams, stringifyObj } from 'common/utils/urls';

const useColumnsReorder = columns => {
  const [modalOpen, setModalOpen] = useState(false);
  const [staticColumns, setStaticColumns] = useState([]);
  const [sortableColumns, setSortableColumns] = useState([]);
  const { history, location } = useRouter();
  const { searchId, visible, ...rest } = parseQueryParams(history.location.search);

  const calculateModalColumns = () => {
    if (
      history.location.search &&
      history.location.search !== '?' &&
      history.location.search !== '' &&
      visible
    ) {
      let updated = [];
      visible.forEach(element => {
        const col = columns.find(c => c.key == element.key);

        if (col) {
          updated.push({
            ...col,
            isVisible: element.visible === 'true' || element.visible === true
          });
        }
      });

      updated = [
        ...updated,
        ...columns
          .filter(
            col => col.canReorder !== false && !col.hidden && !visible.some(v => v.key == col.key)
          )
          .map(col => ({ ...col, isVisible: false }))
      ];

      return updated;
    } else {
      return columns
        .filter(col => col.canReorder !== false && !col.hidden) // Columns with a 'hidden' or a 'canReorder = false' prop, will be excluded from this modal
        .map(col => ({
          ...col,
          isVisible: !col.defaultHidden
        }));
    }
  };

  const updateColumn = value => {
    const updated = value.sticky
      ? staticColumns.map(c => ({
          ...c,
          isVisible: c.key === value.key ? !c.isVisible : c.isVisible
        }))
      : sortableColumns.map(c => ({
          ...c,
          isVisible: c.key === value.key ? !c.isVisible : c.isVisible
        }));

    if (value.sticky) {
      setStaticColumns(updated);
    } else {
      setSortableColumns(updated);
    }
  };

  const handleSave = () => {
    const updated = {
      ...rest,
      visible: [
        ...staticColumns.map(c => ({ key: c.key, visible: c.isVisible })),
        ...sortableColumns.map(c => ({ key: c.key, visible: c.isVisible }))
      ]
    };

    history.push({
      pathname: location.pathname,
      search: `${stringifyObj(updated)}${searchId ? `&searchId=${searchId}` : ''}`
    });

    setModalOpen(false);
  };

  useEffect(() => {
    if (modalOpen) {
      const cols = calculateModalColumns();

      setStaticColumns(cols.filter(c => c.sticky));
      setSortableColumns(cols.filter(c => !c.sticky));
    }
  }, [modalOpen]);

  return {
    modalOpen,
    setModalOpen,
    sortableColumns,
    setSortableColumns,
    staticColumns,
    setStaticColumns,
    handleSave,
    updateColumn
  };
};

export default useColumnsReorder;
