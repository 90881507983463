import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import ShadowBox from 'common/components/general/ShadowBox';
import { useActions } from 'utils/hooks';

import SvgRender from 'common/components/general/SvgRender';
import TicketOfferHandler from 'common/components/tickets/TicketOfferHandler';
import CrewRotationType from 'common/components/crew/CrewRotationType';

import groups from 'common/assets/svg/common/group.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import * as ticketingActions from 'store/ticketing/actions';
import RankPromotionLabel from 'common/components/labels/RankPromotionLabel';
import { useSelector } from 'react-redux';

const Rotation = ({ type, crewSwitch }) => {
  const [openTripDrawer] = useActions([ticketingActions.openTripDrawer]);

  const isOnBoard = useSelector(state => state.isOnBoard);

  const fullName = crewSwitch?.[type]?.full_name;
  const rank = crewSwitch?.[type]?.details?.rank?.name;
  const hasAcceptedOffers = crewSwitch[`has_${type}_accepted_offer`];
  const tripId = crewSwitch[`${type}_trip_id`];
  const hasPendingOffers = tripId ? true : false;
  const isBulkOffer = crewSwitch?.[`${type}_trip`]?.is_bulk;
  const promotionRank = crewSwitch?.[type]?.active_promotion_plan?.promotion_rank?.name;

  const toggleTicketDrawer = useCallback(() => {
    if (tripId) openTripDrawer({ type: 'event', tripIDs: [tripId], disabled: true });
  }, [openTripDrawer, tripId]);

  return (
    <ShadowBox className={`ticketing-page__inner-row bg-white`} flat>
      <Row className="align-items-center flex-nowrap">
        <Col xs={4} className="d-flex align-items-center me-1">
          {type ? (
            <div className="min-width-88">
              <CrewRotationType type={type} />
            </div>
          ) : null}

          <TextWithTooltip className="h-auto">{fullName || '-'}</TextWithTooltip>
        </Col>
        <Col>
          <Row className="align-items-center flex-nowrap">
            <Col xs={type ? 4 : 3}>
              <TextWithTooltip>{rank || '-'}</TextWithTooltip>
            </Col>

            {promotionRank && (
              <Col className="d-flex justify-content-end">
                <RankPromotionLabel
                  className="px-1 cpy-4"
                  text={promotionRank}
                  textClassName="fs-10 text-primary"
                  svgClassName="me-1"
                  svgStyle={{ width: 12, height: 12 }}
                  tooltipText="Planned Promotion"
                  tooltipInnerClassName="cpx-6 py-1"
                />
                <div className="small-separator align-self-center cms-12 cpy-10"></div>
              </Col>
            )}

            {!isOnBoard && (
              <Col xs="auto" className="ms-auto cps-4 cpe-12 d-flex align-items-center">
                {isBulkOffer && hasPendingOffers && !hasAcceptedOffers && (
                  <div className="me-3 pe-3 border-end">
                    <SvgRender
                      src={groups}
                      style={{ width: 16, height: 16 }}
                      className={`ticket-bulk-icon bulk-group-index-${-1}`}
                    />
                  </div>
                )}

                <TicketOfferHandler
                  hasAcceptedOffers={hasAcceptedOffers}
                  hasPendingOffers={hasPendingOffers}
                  showEmptyButton
                  onClick={hasPendingOffers || hasAcceptedOffers ? toggleTicketDrawer : null}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </ShadowBox>
  );
};

export default Rotation;
