import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import SvgRender from 'common/components/general/SvgRender';

import closeIcon from 'common/assets/svg/actions/close.svg';
import downloadIcon from 'common/assets/svg/actions/download.svg';
import rotateIcon from 'common/assets/svg/actions/rotate.svg';
import { download } from 'utils/api';
import { fileSize, getFileType } from '@/ts-common/utils/files.ts';
import { isFileSynced } from '@/api/files/api';
import { handleFileDownload } from 'common/utils/downloads';
import * as galleryActions from 'common/components/gallery/store/actions';
import { rotateDirectionEnums } from 'common/components/gallery/components/helpers';

const GalleryHeader = ({ file = {}, setIsOpen }) => {
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const { is_synced } = isFileSynced(file);
  const [setRotateDirection] = useActions([galleryActions.setRotateDirection]);
  const rotateDirectionKeys = Object.keys(rotateDirectionEnums);
  const currentFileType = getFileType(file.mime_type, file.extension).type;

  const isNotSynced = !is_synced;

  const onClose = () => {
    setIsOpen(false);
  };

  const resetRotateDirection = () => {
    setCurrentIndex(0);
    setRotateDirection('top');
  };

  useEffect(() => {
    resetRotateDirection();
  }, [file?.id]);

  const onClickRotateButton = () => {
    let increasedIndex = currentIndex <= 2 ? currentIndex + 1 : 0;

    setRotateDirection(rotateDirectionKeys[increasedIndex]);

    setCurrentIndex(increasedIndex);
  };

  const downloadFile = async () => {
    setIsPreparingDownload(true);
    await handleFileDownload(file, download);
    setIsPreparingDownload(false);
  };

  return (
    <div className="gallery__header">
      <div>
        <div className="gallery__header--title">{file.filename}</div>
        <div className="gallery__header--subtitle">
          <span className="gallery__header--subtitle-filetype">{currentFileType}</span>
          <div className="gallery__header--subtitle-divider" />
          <span className="gallery__header--subtitle-filesize">{fileSize(file.size)}</span>
        </div>
      </div>
      <div className="d-flex  align-items-center">
        {currentFileType === 'image' ? (
          <div
            onClick={onClickRotateButton}
            className={`gallery__header--icon gallery__header--icon-rotate`}
          >
            <SvgRender src={rotateIcon} style={{ width: 19, height: 19 }} />
          </div>
        ) : null}

        <div
          data-cy="download_file"
          onClick={isPreparingDownload ? () => null : downloadFile}
          className={`gallery__header--icon gallery__header--icon-download ${
            isPreparingDownload || isNotSynced ? 'opacity-5 pointer-events-none' : ''
          }`}
        >
          <SvgRender src={downloadIcon} style={{ width: 17, height: 18.5 }} />
        </div>
        <div
          data-cy="close_file_preview"
          onClick={onClose}
          className="gallery__header--icon gallery__header--icon-close"
        >
          <SvgRender src={closeIcon} style={{ width: 12, height: 12 }} />
        </div>
      </div>
    </div>
  );
};

export default GalleryHeader;
