import Input from 'common/components/form/inputs/Input';

const StringInput = ({ onChange, value, error, disabled }) => {
  return (
    <Input
      onChange={e => onChange(e.target.value)}
      className="mb-0"
      placeholder="Add Text"
      value={value}
      error={error}
      disabled={disabled}
    />
  );
};

export default StringInput;
