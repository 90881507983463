import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';
import paths from 'routing/routes/_paths';

const INITIAL_STATE = {
  data: null,
  activeView: 'personal'
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW.SUCCESS:
    case TYPES.SET_CREW_PROFILE:
      const { communication_details, ...rest } = payload;
      const promotionData = {
        recommendations_count: state.data?.recommendations_count || 0,
        promotion_plan: state.data?.promotion_plan || null
      };

      if (communication_details) {
        const { id, created_at, updated_at, ...rest_communication_details } = communication_details;

        return {
          ...state,
          data: {
            ...rest,
            ...promotionData,
            ...rest_communication_details,
            has_current_address:
              rest_communication_details?.address_1 ||
              rest_communication_details?.address_2 ||
              rest_communication_details?.city ||
              rest_communication_details?.zip ||
              rest_communication_details?.country ||
              rest_communication_details?.valid_from ||
              rest_communication_details?.valid_to ||
              rest?.phones?.length
                ? true
                : false,
            has_old_address:
              rest_communication_details?.old_address_1 ||
              rest_communication_details?.old_address_2 ||
              rest_communication_details?.old_city ||
              rest_communication_details?.old_zip ||
              rest_communication_details?.old_country ||
              rest_communication_details?.old_valid_from ||
              rest_communication_details?.old_valid_to
                ? true
                : false,
            has_nok_address:
              rest_communication_details?.next_of_kin ||
              rest_communication_details?.nok_relation ||
              rest_communication_details?.nok_address_same_as_seafarer ||
              rest_communication_details?.nok_address_1 ||
              rest_communication_details?.nok_address_2 ||
              rest_communication_details?.nok_city ||
              rest_communication_details?.nok_zip ||
              rest_communication_details?.nok_country ||
              rest_communication_details?.nok_valid_from ||
              rest_communication_details?.nok_valid_to
                ? true
                : false
          }
        };
      } else {
        return {
          ...state,
          data: {
            ...promotionData,
            ...payload
          }
        };
      }

    case TYPES.RESET_CREW_PROFILE:
      return {
        ...state,
        data: null,
        activeView: 'personal'
      };

    case TYPES.GET_PROMOTION_PLAN_ACTIVE.SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...payload }
      };

    case LOCATION_CHANGE:
      // Reset or Init the store on location change
      if (payload.location.pathname.startsWith(`${paths.crew}/`)) {
        const parts = payload.location.pathname.split('/');
        const viewPart = parts.length === 3 ? 'personal' : parts[3]; // active view

        if (isNaN(viewPart)) {
          return {
            ...state,
            activeView: viewPart
          };
        }
      } else if (state.data) {
        // Reset crew profile
        return {
          ...state,
          data: null,
          activeView: 'personal'
        };
      }

      return state;

    default:
      return state;
  }
};

export default reducer;
