import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircledButton from 'common/components/buttons/CircledButton';
import Attachment from 'events/event/modules/module-components/components/attachments/form';
import AttachmentForm from 'events/event/modules/module-components/components/attachments/form/AttachmentForm';

import EmptyState from './EmptyState';
import { selectIsTemplate } from 'events/store/events/selectors';

const Body = ({ isCreate, attachments = [], type }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [activeEditId, setActiveEditId] = useState(null);

  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const isTemplate = useSelector(selectIsTemplate);

  const hideActions =
    (type === 'office_attachments' && isOnBoard) || (type === 'vessel_attachments' && !isOnBoard);

  if (isTemplate) return;

  return (
    <>
      {attachments?.length ? (
        attachments.map((attachment, i) => (
          <Attachment
            key={i}
            attachment={attachment}
            setActiveEditId={setActiveEditId}
            activeEditId={activeEditId}
            type={type}
          />
        ))
      ) : (
        <EmptyState type={type} />
      )}

      {isAdding ? <AttachmentForm type={type} onClose={() => setIsAdding(false)} /> : null}

      {!isCreate && !hideActions && (
        <CircledButton
          type="add"
          className="text-primary fw-bold fs-12"
          label="Add more"
          svgStyle={{ width: 8, height: 8 }}
          disabled={isAdding || activeEditId}
          onClick={() => setIsAdding(true)}
          style={{ width: 20, height: 20 }}
        />
      )}
    </>
  );
};

export default Body;
