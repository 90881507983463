import { Button } from 'reactstrap';
import { useState, useCallback } from 'react';
import SvgRender from 'common/components/general/SvgRender';

import warning from 'common/assets/svg/common/warning-empty.svg';
import SendForCorrectionsModal from 'common/components/modals/SendForCorrectionsModal';

const PeriodButtonSendBackForCorrections = ({ updatePeriodStatus, latestPeriod }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  const onSubmit = async review_comments => {
    setIsLoading(true);

    await updatePeriodStatus(latestPeriod?.id, 'for-corrections', { review_comments });

    setIsLoading(false);
  };

  return (
    <>
      <Button
        type="button"
        color="white"
        className="d-inline-flex align-items-center px-2 py-1 border-0 text-primary"
        onClick={() => openModal(latestPeriod)}
      >
        <SvgRender src={warning} style={{ width: 14, height: 14 }} />
        <span className="fw-normal text-primary lh-1 ps-1">Send Back for Corrections</span>
      </Button>

      <SendForCorrectionsModal
        bodyText={
          <span>
            Are you sure you want to send this MGA Period back to vessel for Correction? <br />{' '}
            Editing from office will be locked.
          </span>
        }
        isOpen={isModalOpen}
        setIsOpen={closeModal}
        onSubmit={onSubmit}
        disabled={isLoading}
      />
    </>
  );
};

export default PeriodButtonSendBackForCorrections;
