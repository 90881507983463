import PropTypes from 'prop-types';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

import { updateCrewContract } from 'crew/store/contracts/actions';
import { useForm } from 'utils/hooks';
import config from './config';
import Body from './Body';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import Drawer, { DrawerHeader, DrawerFooter, FormBody, FormDrawer } from 'common/components/drawer';
import { useDispatch } from 'react-redux';
import { selectContractDate, selectContractPort } from '@/views/crew/profile/helpers.ts';

const Form = ({ drawer, active, refetchData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, loadValues, handleSubmitError, resetForm, collectValues } = useForm(config);

  const dispatch = useDispatch();
  const { isOpen } = drawer;

  const signOnDate = selectContractDate(active?.active_contract);

  const signOnPort = selectContractPort(active?.active_contract);

  const signOffDate = selectContractDate(active?.active_contract, false);

  const signOffPort = selectContractPort(active?.active_contract, false);

  useEffect(() => {
    if (active && isOpen) {
      const params = {
        contract_to_date: active?.active_contract?.contract_to_date,
        contract_from_date: active?.active_contract?.contract_from_date,
        sign_on_date_as_per_captain: signOnDate,
        sign_on_port_id_as_per_captain: signOnPort,
        sign_off_date_as_per_captain: signOffDate,
        sign_off_port_id_as_per_captain: signOffPort
      };

      const initialValues = selectDataFromState(params, config);

      loadValues({ ...initialValues });
    }

    if (!isOpen) {
      resetForm();
    }
  }, [active, isOpen, signOffDate, signOnDate, signOnPort, signOffPort]);

  const onSave = async () => {
    const values = collectValues();
    if (!values) return;
    const {
      sign_on_port_id_as_per_captain,
      sign_off_port_id_as_per_captain,
      sign_off_date_as_per_captain,
      contract_from_date,
      contract_to_date,
      ...rest
    } = values;

    const params = {
      ...rest,
      id: active?.contract_id,
      sign_on_port_id_as_per_captain: sign_on_port_id_as_per_captain
        ? sign_on_port_id_as_per_captain?.id
        : null,
      sign_off_port_id_as_per_captain: sign_off_port_id_as_per_captain
        ? sign_off_port_id_as_per_captain?.id
        : null,
      sign_off_date_as_per_captain: sign_off_date_as_per_captain
        ? sign_off_date_as_per_captain
        : null
    };

    try {
      setIsSubmitting(true);
      await dispatch(updateCrewContract(params));
      refetchData();

      drawer.close();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      handleSubmitError(error);
      console.error(error);
    }
  };

  return (
    <Drawer {...drawer} className="table-drawer">
      <DrawerHeader className="border-bottom pb-2">
        <div className="d-flex flex-column">
          <span>Signed on & Signed off</span>
          <strong className="mt-1 fs-12">{active?.full_name}</strong>
        </div>
      </DrawerHeader>

      <FormDrawer>
        <FormBody>
          <Body formState={formState} key={`${signOnDate}_${signOffDate}`} />
        </FormBody>
      </FormDrawer>

      <DrawerFooter className="d-flex justify-content-end p-3">
        <Button
          color="cancel"
          className="px-0 py-1 me-4"
          onClick={() => drawer.close()}
          disabled={isSubmitting}
        >
          CANCEL
        </Button>
        <Button onClick={onSave} disabled={isSubmitting} color="primary" className="px-4">
          SAVE
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState.isRequired,
  active: PropTypes.object,
  refetchData: PropTypes.func
};

export default Form;
