import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckedButton from 'common/components/buttons/CheckedButton';
import { useDispatch } from 'react-redux';
import {
  ignoreFormObligation,
  unignoreFormObligation
} from 'common/components/form-obligations/store/actions';

const IgnoreButton = ({ isInitialyIgnored, id }) => {
  const [isIgnored, setIsIgnored] = useState(isInitialyIgnored);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsIgnored(isInitialyIgnored);
  }, [isInitialyIgnored]);

  const onCheck = async () => {
    try {
      if (isIgnored) {
        await dispatch(unignoreFormObligation({ obligationId: id })).unwrap();
        setIsIgnored(false);

        return;
      }

      await dispatch(ignoreFormObligation({ obligationId: id })).unwrap();
      setIsIgnored(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CheckedButton
      onClick={onCheck}
      className="cursor-pointer"
      style={{ height: 17, width: 17 }}
      isChecked={isIgnored}
    />
  );
};

IgnoreButton.propTypes = {
  isInitialyIgnored: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired
};

export default IgnoreButton;
