import React, { useEffect, useState, useMemo } from 'react';
import ModuleWrapper from '../components/ModuleWrapper';
import Event from './Event';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import CircledButton from 'common/components/buttons/CircledButton';
import { selectEventData } from 'events/store/event-modules/events/selectors';
import { getEvents } from 'events/store/event-modules/events/actions';

const Events = ({ isCreate }) => {
  const [isAdding, setIsAdding] = useState(false);

  const dispatch = useDispatch();

  const data = useSelector(selectEventData);
  const eventId = useSelector(selectEventId);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isTemplate = useSelector(selectIsTemplate);

  useEffect(() => {
    if (eventId) {
      dispatch(getEvents({ id: eventId }));
    }
  }, [dispatch, eventId]);

  const selectedEventIds = useMemo(() => {
    return [eventId, ...data.map(el => el.related_event_id)];
  }, [data, eventId]);

  return (
    <ModuleWrapper className="event-jobs" type="events">
      {!isTemplate ? (
        <>
          {data
            ? data.map(el => {
                return (
                  <Event
                    selectedEventIds={selectedEventIds}
                    key={el.id}
                    event={el}
                    isViewOnly={isOnBoard}
                  />
                );
              })
            : null}
          {isAdding ? (
            <Event selectedEventIds={selectedEventIds} setIsAdding={setIsAdding} />
          ) : null}
          {!isOnBoard && !isCreate && (
            <CircledButton
              type="add"
              className="text-primary fw-bold fs-12 add-circled-btn"
              label="Add more"
              svgStyle={{ width: 8, height: 8 }}
              disabled={isAdding}
              onClick={() => setIsAdding(true)}
            />
          )}
        </>
      ) : null}
    </ModuleWrapper>
  );
};

export default Events;
