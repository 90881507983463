import SvgRender from '@/ts-common/components/general/SvgRender';
import { Attachment } from '@/ts-common/types/files';
import { icon } from '@/ts-common/utils/files';
import { FC } from 'react';

type UnknownTypeProps = {
  file: Attachment;
};

const UnknownType: FC<UnknownTypeProps> = ({ file }) => {
  return (
    <div className="gallery__footer--item-unknown">
      <SvgRender src={icon(file.mime_type, file.extension)} style={{ height: 24, width: 24 }} />
    </div>
  );
};

export default UnknownType;
