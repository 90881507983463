import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_PURCHASING_GROUPS'),
  ...asyncTypes('GET_PURCHASING_GROUP'),
  ...asyncTypes('CREATE_PURCHASING_GROUP'),
  ...asyncTypes('EDIT_PURCHASING_GROUP'),
  ...asyncTypes('DELETE_PURCHASING_GROUP'),
  ...asyncTypes('GET_PURCHASING_ITEMS'),
  ...asyncTypes('GET_PURCHASING_ITEM'),
  ...asyncTypes('CREATE_PURCHASING_ITEM'),
  ...asyncTypes('EDIT_PURCHASING_ITEM'),
  ...asyncTypes('DELETE_PURCHASING_ITEM'),
  ...asyncTypes('CONNECT_PURCHASING_GROUP_TO_MGA_ACCOUNT'),
  EXPAND_PURCHASING_GROUP: 'EXPAND_PURCHASING_GROUP',
  TOGGLE_PURCHASING_MODAL: 'TOGGLE_PURCHASING_MODAL',
  SET_ACTIVE_PURCHASING_GROUP_IN_MODAL: 'SET_ACTIVE_PURCHASING_GROUP_IN_MODAL',
  SET_ACTIVE_GROUP_ID: 'SET_ACTIVE_GROUP_ID',
  SET_IS_CREATING_ITEM: 'SET_IS_CREATING_ITEM',
  SET_ACTIVE_ITEM_ID: 'SET_ACTIVE_ITEM_ID',
  SET_TRIGGER_ITEM_REFETCH: 'SET_TRIGGER_ITEM_REFETCH',
  SET_ACTIVE_PURCHASING_ITEM_IN_MODAL: 'SET_ACTIVE_PURCHASING_ITEM_IN_MODAL',
  SET_TREE_SEARCH: 'SET_TREE_SEARCH',
  SET_PURCHASING_LIBRARY_IS_USED_IN: 'SET_PURCHASING_LIBRARY_IS_USED_IN',
  SET_SELECTED_SETTINGS_CATEGORY_ID: 'SET_SELECTED_SETTINGS_CATEGORY_ID',
  RESET_LIBRARY_SETUP_STATE: 'RESET_LIBRARY_SETUP_STATE',
  ADD_SELECTED_LIBRARY_ITEM_ID: 'ADD_SELECTED_LIBRARY_ITEM_ID',
  REMOVE_SELECTED_LIBRARY_ITEM_ID: 'REMOVE_SELECTED_LIBRARY_ITEM_ID',
  INITIALIZE_SELECTED_LIBRARY_ITEM_IDS: 'INITIALIZE_SELECTED_LIBRARY_ITEM_IDS'
};

export default TYPES;
