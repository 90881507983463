import { FC } from 'react';
import { Button } from 'reactstrap';

import Drawer, {
  DrawerHeader,
  DrawerBody,
  DrawerFooter
} from '@/ts-common/components/drawer/Drawer.tsx';
import { DrawerStateType } from '@/ts-common/types/drawer';

import Currencies from './currencies/index.tsx';
import ForexRate from './ForexRate.tsx';
import PastRates from './PastRates.tsx';

type SetupForesRatesFormProps = {
  drawer: DrawerStateType;
};

const SetupForexRatesForm: FC<SetupForesRatesFormProps> = ({ drawer }) => {
  return (
    <Drawer {...drawer} className="setup-forex-rate-form">
      <DrawerHeader>Setup Forex Rates</DrawerHeader>

      <DrawerBody key={`${drawer.isOpen}`} className="px-7 pt-3">
        <Currencies />
        <ForexRate />
        <PastRates />
      </DrawerBody>

      <DrawerFooter className="d-flex justify-content-end">
        <Button color="cancel" className="px-0 py-1" onClick={drawer.close}>
          CANCEL
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default SetupForexRatesForm;
