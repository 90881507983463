import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import ShiftsCard from 'crew/components/shifts/shifts-card';
import CrewMemberCard from 'crew/components/shifts/crew-member-card';
import RestHoursRules from 'crew/components/shifts/RestHoursRules';
import ShiftTypesLegends from 'crew/components/shifts/ShiftTypesLegends';

import PageLoader from 'common/components/general/PageLoader';
import PageTitle from 'common/components/general/PageTitle';

import * as shiftDataActions from 'store/shifts-data/actions';
import * as shiftActions from 'crew/store/shifts/actions';

import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import useRouter from 'use-react-router';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectStartDate,
  selectEndDate,
  selectShifts,
  selectIsLoading,
  selectPeriodType,
  selectSelectedRankFilter,
  selectSelectedSorting,
  selectshiftsPortSeaState
} from 'crew/store/shifts/selectors';

import { isAuthorized } from 'utils/permissions/authorize';
import PeriodDatePicker from 'crew/components/shifts/top-filters/PeriodDatePicker';
import PeriodSelect from 'crew/components/shifts/top-filters/PeriodSelect';
import RankSelector from 'crew/components/shifts/top-filters/RankSelector';
import SortingButtonGroup from 'crew/components/shifts/top-filters/SortingButtonGroup';
import AtSeaSwitchButtons from 'crew/components/shifts/AtSeaSwitchButtons';
import EmptyState from 'crew/components/shifts/top-filters/EmtpyState';
import NavTabs from 'components/watchkeeping/NavTabs';
import LoadWorkingScheduleButton from 'crew/components/shifts/LoadWorkingScheduleButton';

import moment from 'moment';
import { getSorting } from 'crew/components/shifts/top-filters/helpers';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';
import ExportPdf from '@/common/components/general/ExportPdf';
import { convertToFilters } from '@/crew/utils/filters';
import { handleFileDownload } from '@/ts-common/utils/download';

const CrewShifts = () => {
  const { match } = useRouter();
  const account = useSelector(selectAccount);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const isLoading = useSelector(selectIsLoading);
  const periodType = useSelector(selectPeriodType);
  const selectedRank = useSelector(selectSelectedRankFilter);
  const selectedSorting = useSelector(selectSelectedSorting);
  const shiftsPortSeaState = useSelector(selectshiftsPortSeaState);
  const shifts = useSelector(selectShifts);

  const [isClearedAndCanFetch, setIsClearedAndCanFetch] = useState(false);
  const [vesselShiftDateInfo, setVesselShiftDateInfo] = useState(null);
  const [showLockModal, setShowLockModal] = useState(false);
  const [currentSelectedPortSeaState, setCurrentSelectedPortSeaState] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const history = useHistory();

  if (
    isAuthorized(account, [permissions.onboard.crew.watchkeeping.working_schedule.view]) &&
    !isAuthorized(account, [permissions.onboard.crew.watchkeeping.shifts.view])
  ) {
    history.push(paths.working_schedule);
  }

  const [
    getShiftTypes,
    getShifts,
    updateShift,
    setStartDate,
    setEndDate,
    clearShiftsState,
    updateShiftsPortSeaState,
    getInitialShiftsPortSeaStateInfo,
    initializeShiftsPortSeaState,
    setShiftsPortSeaState,
    validateShifts
  ] = useActions([
    shiftDataActions.getShiftTypes,
    shiftDataActions.getShifts,
    shiftDataActions.updateShift,
    shiftActions.setStartDate,
    shiftActions.setEndDate,
    shiftActions.clearShiftsState,
    shiftDataActions.updateShiftsPortSeaState,
    shiftDataActions.getInitialShiftsPortSeaStateInfo,
    shiftDataActions.initializeShiftsPortSeaState,
    shiftActions.setShiftsPortSeaState,
    shiftDataActions.validateShifts
  ]);

  const getInitialShiftDateInfo = useCallback(async date => {
    const res = await getInitialShiftsPortSeaStateInfo(date);

    if (_get(res, '[0].id', null)) {
      setShiftsPortSeaState(res[0].type);
      setVesselShiftDateInfo(res[0]);
    } else {
      setVesselShiftDateInfo(null);
    }
  }, []);

  useEffect(() => {
    clearShiftsState();
    getShiftTypes();

    setIsClearedAndCanFetch(true);

    const start = moment().format('YYYY-MM-DD');

    setStartDate(start);
    setEndDate(start);
  }, []);

  useEffect(() => {
    if (!startDate) return;

    setShiftsPortSeaState(null);
    getInitialShiftDateInfo(startDate);
  }, [startDate]);

  const getShiftParams = () => {
    const sorting = getSorting(selectedSorting);

    return {
      vesselId: match.params.id,
      from: startDate,
      to: endDate,
      ...(sorting ? sorting : {}),
      ...(selectedRank ? { rank_id: selectedRank.id } : {})
    };
  };

  const fetch = useCallback(async params => {
    try {
      await getShifts(params);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const downloadPdf = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    const { sorting, ...rest } = getShiftParams();

    try {
      await handleFileDownload({ url: '/crew-shifts/export/daily/pdf' }, false, {
        requestParams: { filters: convertToFilters(rest), sorting },
        parsed: true
      });
    } catch (e) {
      console.error(e);
    }

    setIsDownloading(false);
  };

  useEffect(() => {
    if (
      (periodType !== 'monthly' || (periodType === 'monthly' && selectedRank?.id)) &&
      startDate &&
      endDate &&
      isClearedAndCanFetch
    ) {
      const params = getShiftParams();
      fetch(params);
    }
  }, [startDate, endDate, isClearedAndCanFetch, periodType, selectedRank, selectedSorting, fetch]);

  const handleShiftPortSeaStateChange = async () => {
    if (periodType !== 'daily') return;

    try {
      if (vesselShiftDateInfo?.id) {
        const res = await updateShiftsPortSeaState({
          id: vesselShiftDateInfo.id,
          shiftsPortSeaState: currentSelectedPortSeaState,
          date: startDate
        });
        setVesselShiftDateInfo(res);
      } else {
        const res = await initializeShiftsPortSeaState(currentSelectedPortSeaState, startDate);
        setVesselShiftDateInfo(res);
      }

      fetch();
    } catch (error) {
      console.log(error);
    }
  };

  const isAuthorizedToEditShifts = isAuthorized(account, [
    permissions.onboard.crew.watchkeeping.shifts.edit
  ]);

  // const updateShiftWithRefetch = async shift => {
  //   await updateShift(shift);
  //   fetch();
  // };

  return (
    <>
      <PageTitle
        label="watchkeeping"
        render={
          <div className="d-flex align-items-center mb-3">
            <NavTabs />
            <div className="d-flex align-items-center ms-3 ps-3 border-start">
              Watchkeeping
              <strong className="ms-1">/ Shifts</strong>
            </div>
          </div>
        }
      />
      {isLoading ? <PageLoader isLoading={isLoading} /> : null}
      <div>
        <div className="mb-2 d-flex shifts-filters align-items-center">
          <SortingButtonGroup className="me-2" />
          <RankSelector className="me-2" />
          <PeriodSelect />
          <PeriodDatePicker />

          <AtSeaSwitchButtons
            periodType={periodType}
            shiftsPortSeaState={shiftsPortSeaState}
            onSwitch={newState => {
              if (shiftsPortSeaState === newState) return;
              setCurrentSelectedPortSeaState(newState);
              setShowLockModal(true);
            }}
          />

          {periodType === 'daily' && (
            <ExportPdf
              className="ms-6"
              showLabel
              label="Export Daily Shifts"
              onClick={downloadPdf}
              disabled={isDownloading}
            />
          )}

          <RestHoursRules className="ms-auto" />

          {isAuthorizedToEditShifts ? (
            <LoadWorkingScheduleButton getShiftParams={getShiftParams} refetchShifts={fetch} />
          ) : null}
        </div>

        {periodType === 'daily' && <ShiftTypesLegends />}

        {periodType === 'monthly' && !selectedRank?.id ? (
          <EmptyState message="You have not selected any rank yet." />
        ) : (
          shifts.map(crew => (
            <div key={crew.id} className="d-flex">
              <CrewMemberCard startDate={startDate} endDate={endDate} crew={crew} />
              <ShiftsCard
                startDate={startDate}
                crew={crew}
                vesselId={match.params.id}
                isViewOnly={!isAuthorizedToEditShifts}
                updateShift={updateShift}
                validateShifts={validateShifts}
              />
            </div>
          ))
        )}
      </div>

      <DangerousActionModal
        transparent
        action={'sync'}
        onAccept={handleShiftPortSeaStateChange}
        onClose={() => setShowLockModal(false)}
        closeModal={() => setShowLockModal(false)}
        actionHoverColor="primary"
        isOpen={showLockModal}
        actionText="PROCEED"
        cancelText="NO"
        header="Change state"
        body="By click on proceed button, you change the state in all onboard seamen. Are you sure you want to proceed?"
        actionButtonColor="primary"
        iconStyle={{ width: 71, height: 81, color: variables.primary }}
      />
    </>
  );
};

export default CrewShifts;
