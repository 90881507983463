import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import _get from 'lodash/get';

import { selectIsOnboard, selectEventIsForVessel } from 'events/store/events/selectors';

import ProfileImage from 'common/components/general/ProfileImage';
import Departments from 'common/components/general/Departments';
import CollapseForm from 'common/components/collapse/CollapseForm';

import rolodex from 'common/assets/svg/avatars/rolodex.svg';

import ParticipantForm from '../ParticipantForm';
import AttendanceForm from './attendance';
import TicketButton from './TicketButton';
import AttendanceButton from './AttendanceButton';
import ShadowBox from 'common/components/general/ShadowBox';
import { getPartyUrl } from 'common/utils/urls';
import { getPartyPermissions } from 'common/utils/permissions/helpers';
import { Link } from 'react-router-dom';
import AuthCheck from 'components/permissions/AuthCheck';

const Participant = ({ participant, setActiveEditId, setIsEditing, activeEditId }) => {
  const [showAttendance, setShowAttendance] = useState(false);

  const isOnBoard = useSelector(selectIsOnboard);
  const isForVessel = useSelector(selectEventIsForVessel);

  const isCrew = participant.party.type === 'crew';
  const photoUrl = _get(participant, 'party.photo.url', '');

  const attendanceActionsAreLocked = isOnBoard || isCrew;
  const showWarningMessage = !isForVessel && !isOnBoard;
  const isEditingCurrentParticipant = activeEditId === participant.id;

  const onClose = () => {
    setIsEditing(false);
    setActiveEditId(null);
  };

  const onEdit = () => {
    if (isOnBoard) return;
    setIsEditing(true);
    setActiveEditId(participant.id);
  };

  return isEditingCurrentParticipant && !isOnBoard ? (
    <ParticipantForm participant={participant} type="edit" onClose={onClose} />
  ) : (
    <>
      <CollapseForm
        hideExpandButton={true}
        className="mt-1 main-collapse event-participant cmb-12 border-radius-7"
        headerClassName="w-100p"
        setIsEditing={onEdit}
        triggerOpen={showAttendance}
        disableCollapse={true}
        expandButton={false}
        hideActions={isOnBoard}
        header={
          <div
            data-cy="event_participant_card"
            className="event-participant__card d-flex align-items-center justify-content-between position-relative"
          >
            <div className="d-flex align-items-start ">
              <ProfileImage
                className="me-2"
                image={photoUrl}
                size={'xxs'}
                fallback={rolodex}
                rounded
              />
              <div className="text-primary d-flex flex-column">
                <div className="d-flex align-items-center">
                  {participant?.party?.type === 'company' && isOnBoard ? (
                    <span className="fw-medium">{_get(participant, 'party.full_name', '-')} </span>
                  ) : (
                    <div className="fw-medium">
                      <AuthCheck
                        permissions={getPartyPermissions(participant?.party?.type)}
                        unAuthorizedRender={_get(participant, 'party.full_name', '-')}
                      >
                        <Link
                          className="text-primary"
                          target="_blank"
                          to={getPartyUrl(participant?.party?.type, participant?.party_id)}
                        >
                          {_get(participant, 'party.full_name', '-')}
                        </Link>
                      </AuthCheck>
                    </div>
                  )}
                  {!!participant?.party?.company?.id && (
                    <>
                      <span className="text-violet mx-1"> | </span>
                      <span className="text-violet">
                        {_get(participant, 'party.company.full_name', '-')}
                      </span>
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center cmt-4">
                  {!!participant?.party?.position && (
                    <span className="pe-3 text-violet">
                      {_get(participant, 'party.position', '-')}
                    </span>
                  )}
                  {isCrew ? (
                    <span className="text-violet fw-normal">
                      {_get(participant, 'party.details.rank.name', '-')}
                    </span>
                  ) : (
                    <Departments values={_get(participant, 'party.departments', [])} />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center me-2">
              {showWarningMessage && (
                <div className="border-end pe-3 me-3">
                  <ShadowBox color="lighter-orange" className="cpt-6 cpb-6 px-1" flat>
                    <div className="text-orange fs-12 fw-normal">
                      *This Event is not synced Onboard, so the person will <br /> NOT be visible in
                      the Crew List Onboard the vessel.
                    </div>
                  </ShadowBox>
                </div>
              )}

              {!attendanceActionsAreLocked && (
                <AttendanceButton
                  disabled={showAttendance}
                  onClick={() => setShowAttendance(prev => !prev)}
                  className="me-1"
                />
              )}
              {!isOnBoard && <TicketButton participant={participant} />}
            </div>
          </div>
        }
      >
        {!attendanceActionsAreLocked && <AttendanceForm participant={participant} />}
      </CollapseForm>
    </>
  );
};

export default Participant;
