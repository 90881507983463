import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTableListData } from 'common/components/table/store/selectors';
import _groupBy from 'lodash/groupBy';
import Preview from './Preview';

const Warnings = () => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const data = useSelector(state =>
    selectTableListData(state, 'events_lub_oil_analysis_assignments')
  );

  const warnings = useMemo(
    () =>
      _groupBy(
        data.filter(({ analysis }) => ['attention', 'action'].includes(analysis)),
        'analysis'
      ),
    [data]
  );

  if (isOnboard || !Object.keys(warnings)?.length) return null;

  return (
    <div className="mt-3">
      {Object.keys(warnings).map(analysis => (
        <Preview assignments={warnings[analysis]} analysis={analysis} key={analysis} />
      ))}
    </div>
  );
};

export default Warnings;
