import { FC, MouseEventHandler } from 'react';
import { ReactSVG } from 'react-svg';
import { css } from '@emotion/react';

type SvgRenderProps = {
  className?: string;
  src: string;
  onClick?: MouseEventHandler;
  style?: React.CSSProperties;
  id?: string;
};

const SvgRender: FC<SvgRenderProps> = ({ className = '', src, id = '', ...rest }) => {
  if (import.meta.env.MODE === 'test') return <div className={className} {...rest} id={id} />;

  return (
    <ReactSVG
      css={svgStyles}
      className={`svg-wrap d-inline-flex align-item-center ${className ? className : ''}`}
      id={id}
      src={src}
      wrapper="span"
      {...rest}
    />
  );
};

const svgStyles = css`
  span {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
`;

export default SvgRender;
