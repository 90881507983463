import NumberField from 'captain-reports/templates/inputs/NumberField';
import { setValue } from 'captain-reports/store/actions';

import {
  selectPreviousCaptainReportFirstRevCounter,
  selectCaptainReportVessel
} from 'captain-reports/store/selectors';
import {
  selectFieldValue,
  selectMainEngineTotalByKey
} from '@/captain-reports/store/selectors-ts.ts';

import { trimFloatNumber } from 'common/utils/numbers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FC, useEffect } from 'react';
import { Field } from '@/common/types/front-entities/captain-reports.ts';

const EngineMiles: FC<Field> = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const dispatch = useAppDispatch();
  const vessel = useAppSelector(selectCaptainReportVessel);
  const engine_miles = useAppSelector(state => selectFieldValue(state, 'engine_miles'));
  const lastReportRevCounter = useAppSelector(selectPreviousCaptainReportFirstRevCounter) || 0;
  const curRevCounter = useAppSelector(state =>
    selectMainEngineTotalByKey(state, 'me_rev_counter')
  );

  useEffect(() => {
    const calcMiles =
      vessel && vessel.propeller_pitch && curRevCounter
        ? trimFloatNumber(((curRevCounter - lastReportRevCounter) * vessel.propeller_pitch) / 1852)
        : null;

    if (calcMiles !== engine_miles) {
      dispatch(setValue({ key: 'engine_miles', value: calcMiles }));
    }
  }, [curRevCounter, dispatch, engine_miles, lastReportRevCounter, vessel]);

  return (
    <NumberField
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
      onChangeFallback={() => null}
      tooltip={null}
    />
  );
};

export default EngineMiles;
