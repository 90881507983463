import React from 'react';
import useRouter from 'use-react-router';
import PropTypes from 'prop-types';
import { useForm } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label } from 'reactstrap';

import { selectIsMultipleEventsCreate, selectEvent } from 'events/store/events/selectors';

import config from './config';

import Information from 'common/components/general/Information';
import DatePicker from 'events/components/date-picker';
import MultipleEventDates from './MultipleEventsDates';
import {
  changeEventValue,
  resetMultipleEventsDates,
  resetSingleEventDates,
  setIsMultipleEventsCreate
} from 'events/store/events/actions';

const EventDatePicker = ({ memoizedDebounce, isCreate, disabled }) => {
  const dispatch = useDispatch();
  const { resetForm } = useForm(config);

  const isMultipleEvents = useSelector(selectIsMultipleEventsCreate);
  const event = useSelector(selectEvent);

  const { match } = useRouter();

  return (
    <>
      {!match.params.id ? (
        <div className="d-flex mb-2 ps-1">
          <div className="pe-1">
            <Button
              onClick={() => {
                resetForm();
                dispatch(setIsMultipleEventsCreate(false));
                dispatch(resetMultipleEventsDates());
              }}
              className="max-width-100p height-16 d-inline-flex align-items-center fs-10 border border-primary border-radius-base text-nowrap"
              color={isMultipleEvents ? `link` : `primary`}
            >
              <strong>Single Event</strong>
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                resetForm();
                dispatch(setIsMultipleEventsCreate(true));
                dispatch(resetSingleEventDates());
              }}
              className="max-width-100p height-16 d-inline-flex align-items-center border border-primary border-radius-base fs-10"
              color={isMultipleEvents ? `primary` : `link`}
            >
              <strong>Multiple Events</strong>
              <Information
                svgStyle={{ width: 10, height: 10 }}
                className="cms-6 text-primary mt-0 pt-0"
                svgClassname={isMultipleEvents ? `text-white` : ``}
                message="By selecting Multiple Events you are able to create many Events"
              />
            </Button>
          </div>
        </div>
      ) : null}

      <Label className="form-label d-block ms-1 mb-1">Date</Label>
      {!isMultipleEvents || match.params.id ? (
        <DatePicker
          hasLabel={false}
          isCreate={isCreate}
          event={event}
          changeEventValue={value => dispatch(changeEventValue(value))}
          memoizedDebounce={memoizedDebounce}
          isMultipleEvents={isMultipleEvents}
          disabled={disabled}
        />
      ) : (
        <MultipleEventDates
          memoizedDebounce={memoizedDebounce}
          isCreate={isCreate}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default EventDatePicker;

EventDatePicker.propTypes = {
  memoizedDebounce: PropTypes.func,
  isCreate: PropTypes.bool
};
