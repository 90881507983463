/* Array fields (subGroups) that are not inside a tab - { arrayName: { sectionKey, ... } } */
export const arrayFields = {
  air_coolers: { section: 'coolers' },
  jacket_coolers: { section: 'coolers' },
  me_lo_coolers: { section: 'coolers' },
  central_coolers: { section: 'coolers' },
  consumptions: { section: 'bunker_consumptions' },
  me_lo_pumps_no: { section: 'flowmeters' },
  main_cooler_sw_pumps_no: { section: 'flowmeters' },
  jacket_cooler_pumps_no: { section: 'flowmeters' },
  me_ltcw_pumps_no: { section: 'flowmeters' },
  me_htcw_pumps_no: { section: 'flowmeters' },
  fo_purifiers_no: { section: 'flowmeters' },
  lo_purifiers_no: { section: 'flowmeters' },
  ballast_water: { section: 'ballastWater' },
  intermediate_bearing: { section: 'general' }
};

export const multiTabsSections = {
  engines: vessel => vessel.me_count || 1,
  generators: vessel => vessel.generators_count || 1

  // cargo_documents
};
