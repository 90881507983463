import PropTypes from 'prop-types';

const severities = {
  1: { color: 'turquoise', label: 'Least severe' },
  2: { color: 'aquamarine' },
  3: { color: 'orange' },
  4: { color: 'coral' },
  5: { color: 'crimson', label: 'Most severe' }
};

const SeverityLegend = ({ number, className = 'fw-medium fs-12', showFullLabel = false }) => {
  const severity = severities?.[number];

  if (!severity) return null;

  const { color, label } = severity;

  return (
    <div className={`d-inline-flex align-items-center lh-1 ${className}`}>
      <div className={`bg-${color} min-width-8 height-8 rounded-circle me-1`} />
      <span>{number}</span>
      {showFullLabel && label ? <span>: {label}</span> : null}
    </div>
  );
};

SeverityLegend.propTypes = {
  number: PropTypes.number.isRequired,
  textClassName: PropTypes.string,
  showFullLabel: PropTypes.bool
};

export default SeverityLegend;
