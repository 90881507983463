import CircledButton from 'common/components/buttons/CircledButton';
import AuthCheck from 'components/permissions/AuthCheck';

import permissions from 'common/utils/permissions/constants';
import { useAppDispatch } from '@/store/hooks';
import { changeVesselDocumentDrawerStatus } from '@/common/components/vessels/profile/tabs/documents/store/actions';

const AddNew = ({ isLoading }: { isLoading: boolean }) => {
  const dispatch = useAppDispatch();

  return (
    <AuthCheck
      permissions={[
        permissions.office.vessels.documents.edit,
        permissions.onboard.vessel.documents.edit
      ]}
    >
      <div className="add-certificate-button">
        <CircledButton
          disabled={isLoading}
          type="add"
          onClick={() =>
            dispatch(changeVesselDocumentDrawerStatus({ drawerOpen: true, drawerType: 'create' }))
          }
          className="me-1"
          label={<strong className="text-primary">Add new</strong>}
        />
      </div>
    </AuthCheck>
  );
};

export default AddNew;
