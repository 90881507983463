import { createComment, deleteComment, getChecklist, getComments, updateComment } from './actions';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import _uniqBy from 'lodash/uniqBy';

const INITIAL_STATE = {
  options: [],
  openLists: [],
  loading: false,
  checklistState: [{ title: '', mentions: [] }],
  isSaving: false
};

const slice = createSlice({
  name: 'eventChecklist',
  initialState: INITIAL_STATE,
  reducers: {
    showLessComments: (state, { payload }) => {
      const index = state.options.findIndex(opt => opt.id === payload.checklist_id);
      if (index !== -1) state.options[index].comments = state.options[index].comments.splice(0, 2);

      state.isSaving = false;
      return state;
    },
    setChecklistState: (state, { payload }) => {
      state.checklistState = payload;
      return state;
    },
    setChecklistOptions: (state, { payload }) => {
      state.options = payload;
      return state;
    },
    toggleCollapse: (state, { payload }) => {
      if (state.openLists.includes(payload)) {
        state.openLists = state.openLists.filter(el => el !== payload);
      } else {
        state.openLists.push(payload);
      }

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getChecklist.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getChecklist.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.options = payload;
        return state;
      })

      .addCase(getChecklist.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(getComments.fulfilled, (state, { payload }) => {
        const index = state.options.findIndex(opt => opt.id === payload.checklist_id);
        if (index !== -1)
          state.options[index].comments = _uniqBy(
            [...state.options[index].comments, ...payload.data],
            'id'
          );

        state.isSaving = false;
        return state;
      })

      .addCase(createComment.fulfilled, (state, { payload }) => {
        const index = state.options.findIndex(opt => opt.id === payload.checklist_id);

        if (index !== -1) {
          state.options[index].comments_count = (state.options[index].comments_count ?? 0) + 1;
          state.options[index].comments = [payload.data, ...state.options[index].comments];
        }

        state.isSaving = false;
        return state;
      })

      .addCase(updateComment.fulfilled, (state, { payload }) => {
        const index = state.options.findIndex(opt => opt.id === payload.checklist_id);

        if (index !== -1) {
          const commentIndex = state.options[index].comments.findIndex(
            com => com.id === payload.comment_id
          );
          if (commentIndex !== -1) state.options[index].comments[commentIndex] = payload.data;
        }

        state.isSaving = false;
        return state;
      })

      .addCase(deleteComment.fulfilled, (state, { payload }) => {
        const index = state.options.findIndex(opt => opt.id === payload.checklist_id);

        if (index !== -1) {
          state.options[index].comments_count = (state.options[index].comments_count ?? 1) - 1;
          state.options[index].comments = state.options[index].comments.filter(
            comment => comment.id !== payload.comment_id
          );
        }

        state.isSaving = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addMatcher(
        isAnyOf(
          createComment.pending,
          getComments.pending,
          updateComment.pending,
          deleteComment.pending
        ),
        state => {
          state.isSaving = true;

          return state;
        }
      )

      .addMatcher(
        isAnyOf(
          getComments.rejected,
          createComment.rejected,
          updateComment.rejected,
          deleteComment.rejected
        ),
        state => {
          state.isSaving = false;
          return state;
        }
      )

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { showLessComments, setChecklistState, setChecklistOptions, toggleCollapse } =
  slice.actions;

export default slice.reducer;
