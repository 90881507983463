import documents from 'common/assets/svg/common/documents.svg';
import SvgRender from 'common/components/general/SvgRender';
import UploadFiles from 'common/components/form/inputs/upload-files';
import PageSaving from 'common/components/general/PageSaving';

import { Button } from 'reactstrap';
import { download } from 'utils/api';
import {
  selectActiveFolderFiles,
  selectIsLocked,
  selectActiveFolderId
} from 'common/components/filesystem/store/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { useState } from 'react';
import { handleFileDownload } from 'common/utils/downloads';
import { isAuthorized } from '@/utils/permissions/authorize';

import downloadIcon from 'common/assets/svg/actions/download.svg';

import * as filesystemActions from 'common/components/filesystem/store/actions';
import { selectAccount } from '@/store/account/selectors';
import permissions from '@/common/utils/permissions/constants';

const Files = () => {
  const files = useSelector(selectActiveFolderFiles);
  const isLocked = useSelector(selectIsLocked);
  const activeFolderId = useSelector(selectActiveFolderId);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const account = useSelector(selectAccount);

  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [addFolderFiles, deleteFolderFile] = useActions([
    filesystemActions.addFolderFiles,
    filesystemActions.deleteFolderFile
  ]);

  const addFile = async updatedFiles => {
    try {
      setIsSaving(true);

      if (updatedFiles?.length < files?.length) {
        const file = files.filter(f => !updatedFiles.find(n => n.id === f.id))?.[0];

        await deleteFolderFile({ id: activeFolderId, file_id: file?.id });
      } else {
        const newFiles = updatedFiles.filter(n => !files.find(f => n.id === f.id));

        await addFolderFiles({
          id: activeFolderId,
          file_ids: newFiles.map(f => f.id),
          files: newFiles
        });
      }

      setIsSaving(false);
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  const downloadFiles = async () => {
    setIsDownloading(true);

    await handleFileDownload({ url: `/directories/${activeFolderId}/download` }, download, false, {
      parsed: true
    });

    setIsDownloading(false);
  };

  return (
    <div>
      <div className="d-flex align-items-center text-violet border-bottom pb-1 mb-2">
        <SvgRender src={documents} style={{ width: 16, height: 16 }} />
        <span className="ps-1">Files</span>

        {isOnBoard ? (
          <Button
            onClick={downloadFiles}
            disabled={isDownloading || !files?.length}
            color="link"
            className="d-flex align-items-center justify-content-center p-0 ms-auto"
          >
            <SvgRender src={downloadIcon} style={{ width: 16, height: 16 }} />
            <div className="cms-12 fs-12 fw-medium">Download all</div>
          </Button>
        ) : null}
      </div>

      <UploadFiles
        group="filesystem"
        files={files}
        onChange={addFile}
        viewOnly={isLocked}
        canRemove={isAuthorized(account, [permissions.office.files.delete])}
        canUpload={isAuthorized(account, [permissions.office.files.edit])}
        size="lg"
      />

      <PageSaving isSaving={isSaving} />
    </div>
  );
};

export default Files;
