import { css } from '@emotion/react';

import ActionButton from 'common/components/buttons/ActionButton';
import add from 'common/assets/svg/actions/add.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEventId,
  selectEventIsForVessel,
  selectEventVessel,
  selectModuleSyncButtonIsVisible
} from 'events/store/events/selectors';
import Drawer, {
  FormBody,
  FormDrawer,
  useDrawer,
  DrawerFooter,
  DrawerHeader
} from 'common/components/drawer';
import Radio from 'common/components/form/inputs/Radio';
import { booleanField, dateField, optionField } from 'common/utils/form/fieldTypes';
import { useForm, useFormState } from 'utils/hooks';
import DateInput from 'common/components/form/inputs/date';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import ResponsibleSelector from 'common/components/form-obligations/components/ResponsibleSelector';
import FormsSelector from 'common/components/selectors/FormsSelector';
import { useMemo, useState } from 'react';
import {
  createEventFormObligation,
  getEventFormObligations
} from 'events/store/event-modules/forms/actions';
import { Button } from 'reactstrap';
import { selectTableListRequestParams } from 'common/components/table/store/selectors';
import { EVENT_OBLIGATIONS_TABLE_LABEL } from '.';
import Information from 'common/components/general/Information';

export const config = {
  is_for_vessel: booleanField({ initialValue: false }),
  timeframe_to_date: dateField({ required: true }),
  form: optionField({ required: true }),
  responsible: optionField({ required: true }),
  responsible_type: optionField({ initialValue: 'department' })
};

const NewObligation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const drawer = useDrawer();

  const { formState, collectValues, resetForm } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  const eventId = useSelector(selectEventId);
  const isEventForVessel = useSelector(selectEventIsForVessel);
  const eventVessel = useSelector(selectEventVessel);
  const isSyncButtonVisible = useSelector(selectModuleSyncButtonIsVisible);
  const eventObligationsTableRequestParams = useSelector(state =>
    selectTableListRequestParams(state, EVENT_OBLIGATIONS_TABLE_LABEL)
  );

  const formListParam = useMemo(
    () => ({
      office_or_for_vessel_ids:
        isEventForVessel && !fields.is_for_vessel.value ? [eventVessel?.id] : undefined,
      is_for_vessel: fields.is_for_vessel.value || undefined,
      vessel_ids: isEventForVessel && fields.is_for_vessel.value ? [eventVessel?.id] : undefined,
      visibility: 'events'
    }),
    [isEventForVessel, eventVessel?.id, fields.is_for_vessel.value]
  );

  const close = () => {
    drawer.close();
    resetForm();
  };

  const onSubmit = async () => {
    const values = collectValues();

    if (!values || !eventId) return;

    const { form, responsible, ...rest } = values;

    setIsLoading(true);

    try {
      await dispatch(
        createEventFormObligation({
          eventId,
          form_uid: form.uid,
          responsible_id: responsible?.id ?? responsible,
          ...rest
        })
      ).unwrap();

      close();
      dispatch(getEventFormObligations({ eventId, ...eventObligationsTableRequestParams }));
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  return (
    <>
      <ActionButton
        className={`text-dark ms-auto ${isSyncButtonVisible ? 'me-8' : ''}`}
        color="yellow"
        icon={add}
        onClick={() => drawer.open()}
      >
        Add new form Obligation
      </ActionButton>

      <Drawer {...drawer} close={close}>
        <DrawerHeader>Add new form Obligation</DrawerHeader>

        <FormDrawer>
          <FormBody>
            <div className="d-flex align-items-center mt-1">
              <Radio
                className="mb-0 me-3"
                name="office"
                label="Submit from Office"
                checked={!fields.is_for_vessel.value}
                onChange={() => {
                  selectField('is_for_vessel')(!fields.is_for_vessel.value);
                  selectField('responsible_type')('department');
                  selectField('responsible')('');
                }}
              />
              <Radio
                className="mb-0"
                name="onboard"
                label="Submit from Vessel"
                checked={fields.is_for_vessel.value}
                onChange={() => {
                  selectField('is_for_vessel')(!fields.is_for_vessel.value);
                  selectField('responsible_type')('crew_rank');
                  selectField('responsible')('');
                }}
              />
            </div>

            <div className="mt-3">
              <div className="d-flex align-items-center">
                <strong className="fs-10 text-primary">FORM TYPE</strong>
                {isEventForVessel && (
                  <Information
                    message={
                      <>
                        This event is synced with the vessel, in the forms dropdown you see all
                        office <br />
                        forms and all current vessel’s forms. You DO NOT see any other vessels’
                        forms.
                      </>
                    }
                    tooltipClassname="text-start min-width-422"
                    className="ms-1"
                    target="form-type-event-for-vessel"
                    svgStyle={{ height: 12, width: 12 }}
                    svgClassname="text-primary"
                  />
                )}
              </div>
              <FormsSelector
                isMulti={false}
                className="mb-0"
                placeholder="Select form"
                onChange={(_, selected) => selectField('form')(selected)}
                listParams={formListParam}
                defaultOptionsTriggerChange={formListParam}
                {...fields.form}
              />
            </div>

            <DateInput
              className="my-2"
              label="DUE DATE"
              onChange={date => selectField('timeframe_to_date')(date)}
              {...fields.timeframe_to_date}
            />

            {fields.is_for_vessel.value ? (
              <CrewRankSelector
                css={labelStyle}
                label="RESPONSIBLE TO SUBMIT"
                onChange={rank => selectField('responsible')(rank)}
                isMulti={false}
                placeholder="Select rank"
                autoFocus={false}
                className="mb-0"
                {...fields.responsible}
              />
            ) : (
              <ResponsibleSelector
                isDepartment={fields.responsible_type.value === 'department'}
                selectField={selectField}
                responsible={fields.responsible}
              />
            )}
          </FormBody>
        </FormDrawer>

        <DrawerFooter className="d-flex justify-content-end cpy-12">
          <Button color="cancel" className="px-0 py-1 me-4" onClick={close} disabled={isLoading}>
            CANCEL
          </Button>
          <Button onClick={onSubmit} disabled={isLoading} color="primary" className="px-4">
            ADD
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export default NewObligation;

const labelStyle = css`
  & > :first-child {
    margin-bottom: 0.125rem;
  }
`;
