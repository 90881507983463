import React from 'react';
import { components } from 'react-select';
import { useEffect, useState, useRef, useCallback } from 'react';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const SelectMultipleIndicatorsContainer = ({ children, ...props }) => {
  const tooltipContainer = useRef(null);
  const maxLimit = 15;

  const { tooltipID, avoidRender } = useTooltipID('react-select-multiple-overflow-tooltip');

  const [firstHiddenIndex, setFirstHiddenIndex] = useState(null);

  const values = props.getValue();
  const hiddenValues = values.filter((_, i) => i >= firstHiddenIndex);

  const isVisible = useCallback((parent, child, i) => !child.offsetTop > parent.offsetTop, []);

  useEffect(() => {
    if (tooltipContainer?.current && values?.length) {
      let hasOverflow = false;

      const valueContainer = tooltipContainer.current.previousSibling;
      const controlContainer = valueContainer.parentElement; // react-select__control

      for (let i = 0; i < valueContainer.childNodes.length; i++) {
        const childNode = valueContainer.childNodes[i];

        if (childNode.tagName === 'DIV' && !isVisible(controlContainer, childNode, i)) {
          setFirstHiddenIndex(i);
          hasOverflow = true;

          break;
        }
      }

      if (!hasOverflow) setFirstHiddenIndex(null);
    } else if (firstHiddenIndex) {
      setFirstHiddenIndex(null);
    }
  }, [values?.length, props.selectProps.isDisabled, children, firstHiddenIndex, isVisible]);

  return (
    <>
      <div
        ref={tooltipContainer}
        className={`react-select-multiple-overflow-tooltip ${
          firstHiddenIndex === null || hiddenValues?.length === 0 ? 'invisible' : ''
        }${
          props.selectProps?.isDisabled ? ' react-select-multiple-overflow-tooltip--disabled' : ''
        }`}
        id={tooltipID}
      >
        {`+${hiddenValues?.length}`}
      </div>
      {!avoidRender ? (
        <Tooltip target={tooltipID} fade={false}>
          {hiddenValues
            .filter((_, i) => i < maxLimit)
            .map(opt =>
              props.selectProps.getOptionLabel ? props.selectProps.getOptionLabel(opt) : opt.label
            )
            .join(', ')}
          {hiddenValues?.length - 1 > maxLimit ? ', ...' : ''}
        </Tooltip>
      ) : null}
      <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>
    </>
  );
};

export default SelectMultipleIndicatorsContainer;
