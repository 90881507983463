import SvgRender from 'common/components/general/SvgRender';
import { Link } from 'react-router-dom';

import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import AuthCheck from 'components/permissions/AuthCheck';

const LinkTo = ({ to, bg, permissions }) => (
  <AuthCheck permissions={permissions}>
    <Link
      target="_blank"
      to={to}
      className={`dashboard__totals-link dashboard__totals-link--${bg}`}
    >
      <SvgRender src={arrow} style={{ width: 12, height: 12, transform: 'rotate(90deg)' }} />
    </Link>
  </AuthCheck>
);

export default LinkTo;
