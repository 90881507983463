import React, { useState } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';
import Form from './form';
import Table from './Table';
import { useDrawer } from 'common/components/drawer';
import { useSelector } from 'react-redux';

const Observations = () => {
  const [active, setActive] = useState(null);

  const drawer = useDrawer();

  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <div>
      <Table drawer={drawer} setActive={setActive} />

      {!isOnBoard && (
        <CircledButton
          type="add"
          className="text-primary fw-bold fs-12 mt-2"
          label="Add observation"
          onClick={() => {
            setActive(null);
            drawer.open();
          }}
          disabled={drawer?.isOpen}
          style={{ width: 20, height: 20 }}
        />
      )}

      <Form drawer={drawer} active={active} />
    </div>
  );
};

export default Observations;
