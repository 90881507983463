import React from 'react';
import PropTypes from 'prop-types';
import NamesInTooltip from './general/NamesInTooltip';

const CrewRanks = ({ values }) => {
  return (
    <div className="crew-ranks-wrap">
      <NamesInTooltip names={values.map(v => v?.name)} />
    </div>
  );
};

export default CrewRanks;

CrewRanks.propTypes = {
  values: PropTypes.array
};
