import React from 'react';

import Evaluation from 'crew/profile/main/evaluation';
import D3Graph from 'common/components/graph/base';

const EvaluationProfile = ({ setCustomTitle, isOnboard }) => {
  return (
    <Evaluation setCustomTitle={setCustomTitle} isOnboard={isOnboard} components={{ D3Graph }} />
  );
};

export default EvaluationProfile;
