import React, { useEffect, useMemo } from 'react';
import BaseStatusDropdown from 'common/components/statuses/BaseStatusDropdown';

import { useSelector } from 'react-redux';
import useActions from 'common/utils/hooks/useActions';

import * as listActions from 'store/lists/actions';
import {
  getIsFormSubmitted,
  getIsFormUnlockedBasedOnStatus,
  originAvoidLockKeys
} from '../helpers';
import { selectListDefaultOptions, selectListFromStore } from 'store/lists/selectors';
import FormOrigin from 'common/components/forms/_components/FormOrigin';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';
import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';
import { isAuthorized } from 'utils/permissions/authorize';

export const isStatusLockedByPermissions = (account, value, isOnboard) => {
  if (isOnboard) {
    if (isAuthorized(account, [permissions.onboard.forms.statuses.edit])) {
      const formStatusProgresses = getAuthorizedPermissionSettings(
        account,
        permissions.onboard.forms.statuses.edit
      )?.form_status_progresses;

      return formStatusProgresses?.length
        ? !formStatusProgresses?.includes(value?.progress)
        : false;
    } else {
      return true;
    }
  } else return false;
};

export const isStatusLockedByPendingSignatures = (allowedProgresses, progress, formSubmission) => {
  return (
    formSubmission?.pending_signature_requirements_count > 0 &&
    allowedProgresses?.length &&
    !allowedProgresses?.includes(progress)
  );
};

const FormStatus = ({
  value,
  formSubmission,
  isUnlocked,
  selectedVesselId,
  onChange,
  statusIsDisabled,
  form,
  ...rest
}) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const account = useSelector(selectAccount);

  const formStatuses = useSelector(state => selectListDefaultOptions(state, 'form-statuses'));
  const { isFetching } = useSelector(state => selectListFromStore(state, 'form-statuses') || {});

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const getStatuses = () => {
    if (!formStatuses?.length && !isFetching) {
      fetchListOptions('form-statuses');
    }
  };

  const missingDocumentsStatusProgresses =
    form?.signature_requirement_settings?.status_progresses.map(p => p.progress);

  const formSubmissionOrigin = formSubmission?.origin;

  const isLocked =
    !getIsFormUnlockedBasedOnStatus(value, formSubmission, isOnboard) || formSubmissionOrigin;

  const isSubmitted = getIsFormSubmitted(formSubmission);

  const filteredStatuses = useMemo(() => {
    const formIsForVessel =
      (isSubmitted && !!formSubmission?.vessel_id) || (!isSubmitted && !!selectedVesselId);

    const formattedFormStatuses = formStatuses?.map(status => {
      const formattedStatus = { ...status, isLocked: false };

      if (isStatusLockedByPermissions(account, status, isOnboard)) {
        formattedStatus.isLocked = true;
        formattedStatus.disabled = true;
      } else if (
        isStatusLockedByPendingSignatures(
          missingDocumentsStatusProgresses,
          status.progress,
          formSubmission
        )
      ) {
        formattedStatus.isLocked = true;
        formattedStatus.lockedTooltipText =
          'You cannot select this status because signatures are missing.';
      }

      return formattedStatus;
    });

    return formattedFormStatuses.filter(s =>
      isOnboard ? s.is_for_vessel : formIsForVessel ? s.is_for_vessel : s.is_for_office
    );
  }, [
    isSubmitted,
    formSubmission,
    selectedVesselId,
    formStatuses,
    account,
    isOnboard,
    missingDocumentsStatusProgresses
  ]);

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    if (!isSubmitted && !isOnboard) {
      if (selectedVesselId && !value?.is_for_vessel) {
        onChange(filteredStatuses.find(f => f.edit_side === 'office'));
      } else if (!selectedVesselId && !value?.is_for_office) {
        onChange(filteredStatuses.find(f => f.edit_side === 'office'));
      }
    }
  }, [filteredStatuses, selectedVesselId, isSubmitted, isOnboard, value]);

  const shouldLockByOrigin =
    formSubmissionOrigin && !originAvoidLockKeys.find(e => e === formSubmissionOrigin);

  return filteredStatuses?.length && (formSubmission ? value !== null : true) ? (
    <>
      {shouldLockByOrigin ? <FormOrigin formSubmission={formSubmission} /> : null}
      <div id={`base-status-dropdown-origin_${formSubmission?.id ? formSubmission?.id : ''}`}>
        <BaseStatusDropdown
          isLocked={
            statusIsDisabled ||
            shouldLockByOrigin ||
            isStatusLockedByPermissions(account, value, isOnboard)
          }
          value={value}
          statuses={filteredStatuses}
          showLockIcon={
            shouldLockByOrigin || isStatusLockedByPermissions(account, value, isOnboard)
          }
          statusLabelClassName={`${shouldLockByOrigin && isLocked ? 'cpe-12' : ''}`}
          onChange={onChange}
          lockedTooltip={
            isLocked && !shouldLockByOrigin
              ? isOnboard
                ? 'This Form Submission is locked onboard. Only the Office can edit it'
                : 'This Form Submission is locked in the Office. Only the vessel can edit it'
              : null
          }
          {...rest}
        />
      </div>
    </>
  ) : null;
};

export default FormStatus;
