import React from 'react';
import { useSelector } from 'react-redux';
import { numberToStr } from 'common/utils/numbers';

import { selectActiveTab, selectGeneratorsSubGroupTotal } from 'captain-reports/store/selectors';

const GeneratorsHeaderExhaustGas = ({ subGroup, label, indexes }) => {
  const activeTab = useSelector(state => selectActiveTab(state, label));
  const total = useSelector(state =>
    selectGeneratorsSubGroupTotal(state, activeTab, subGroup, 'dg_exhaust_gas_temperature')
  );

  return (
    <div className="d-flex align-items-center justify-content-between fs-10 w-100p">
      <div>
        EXHAUST GAS TEMPERATURE (°C) <span className="fw-normal">- PER CYLINDER</span>
      </div>
      <div>
        <span className="fw-normal">AVERAGE: </span>
        <strong>
          {total && indexes.length ? `${numberToStr(total / indexes.length)} °C` : '-'}
        </strong>
      </div>
    </div>
  );
};

export default GeneratorsHeaderExhaustGas;
