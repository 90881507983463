import React, { useState } from 'react';

import pdfIcon from 'common/assets/svg/files/pdf-file.svg';
import SvgRender from 'common/components/general/SvgRender';

import { useSelector } from 'react-redux';

import { selectCurrentVersionPdf } from 'manuals/store/selectors';

import InfoModal from 'common/components/modals/InfoModal';

import syncs from 'common/assets/svg/common/syncs.svg';
import { download } from 'utils/api';

const DownloadManualButton = ({ isOnboard }) => {
  const [showModal, setShowModal] = useState(false);
  const manualVersionPdf = useSelector(selectCurrentVersionPdf);

  const previewPdf = async () => {
    try {
      if (manualVersionPdf?.url_inline) {
        download(manualVersionPdf.url_inline);
      } else {
      }
    } catch (e) {
      if (isOnboard && e.status === 400) {
        setShowModal(true);
      }
    }
  };

  const handleDownload = () => {
    previewPdf();
  };

  return (
    <>
      <div className={`separator ${!manualVersionPdf ? 'd-none' : ''}`}></div>
      <div
        className={`align-items-center base-transition ${
          !manualVersionPdf ? 'd-none' : 'd-flex cursor-pointer'
        }`}
        onClick={handleDownload}
      >
        <SvgRender style={{ height: 19, width: 15 }} src={pdfIcon} />
        <span className={`text-primary fs-14 lh-15 cms-12 text-nowrap`}>{'Read Mode'}</span>

        <InfoModal
          transparent
          closeModal={() => setShowModal(!showModal)}
          isOpen={showModal}
          actionIcon={
            <div className="text-red">
              <SvgRender style={{ height: 88, width: 88 }} src={syncs} />
            </div>
          }
          actionText={'OK'}
          header={'OOPS!'}
          body={
            <div className="text-center fw-light fs-18">
              <div>Pdf file is not synced yet.</div>
              <div>Try again later.</div>
            </div>
          }
        />
      </div>
    </>
  );
};

export default DownloadManualButton;
