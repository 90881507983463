import { convertMinutesToHoursAndMinutes } from 'common/utils/dates';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import moment from 'moment';

export const hours = Array(25) // No, each day indeed has 24 hours. But we start from 00:00 :)
  .fill(null)
  .map((_, i) => (i.toString().length === 1 ? `0${i}` : i.toString()));

export const cells = Array(48).fill(false);

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const convertInternalShiftsToDateTimeShifts = (internalShifts, formattedDate) => {
  let i;
  const shifts = [];
  let firstShiftHalfHourIndex; // This is the starting index of each shift
  let previousShiftTypeId; // Last cell's id. We use it to check when the shift type changes (example from empty shift -> id: 1 etc.)

  for (i = 0; i <= internalShifts.length; i++) {
    // The <= ^^ is intentional. We want it to go one over to check for shifts if the last cell is filled. **(length + 1)**
    const shiftTypeId = internalShifts[i] ? internalShifts[i].id : null;

    if (shiftTypeId !== previousShiftTypeId) {
      // If the current shift type is different than the last one.
      // Which basically indicates a shift has finished and the last index is i-1
      if (internalShifts[firstShiftHalfHourIndex] && internalShifts[firstShiftHalfHourIndex].id) {
        // ^^ This checks if the shift we have been going over is empty or not.
        // If its not empty we enter here; where we push the shift into an array
        const shift = {
          start: firstShiftHalfHourIndex,
          duration: i - firstShiftHalfHourIndex,
          crew_shift_type_id: internalShifts[firstShiftHalfHourIndex].id,
          ...(internalShifts[firstShiftHalfHourIndex].shiftID
            ? { id: internalShifts[firstShiftHalfHourIndex].shiftID }
            : {})
        };

        shifts.push(shift);
      }

      firstShiftHalfHourIndex = i;
      previousShiftTypeId = shiftTypeId;
      // Then over down here we "reset" the variables to be ready for the next shift. (Only if the shift changes, cause we are inside the if statement.)
    }
  }

  return shifts.map(shift => {
    // Here we take the output from above which is based on indices and we use the start & duration to turn it into useful datetime strings :)
    const startMinutes = shift.start * 30;
    const durationMinutes = shift.duration * 30;
    const start = convertMinutesToHoursAndMinutes(startMinutes);
    const end = convertMinutesToHoursAndMinutes(startMinutes + durationMinutes);

    return {
      start: moment(
        `${formattedDate} ${start.hours < 10 ? `0${start.hours}` : start.hours}:${
          start.minutes < 10 ? `0${start.minutes}` : start.minutes
        }`,
        'YYYY-MM-DD HH:mm'
      ).format(dateTimeFormat),
      end: moment(
        `${formattedDate} ${end.hours < 10 ? `0${end.hours}` : end.hours}:${
          end.minutes < 10 ? `0${end.minutes}` : end.minutes
        }`,
        'YYYY-MM-DD HH:mm'
      ).format(dateTimeFormat),
      id: shift.id,
      crew_shift_type_id: shift.crew_shift_type_id
    };
  });
};

const getCellIndexFromTo = (start, end) => {
  const startHour = parseInt(start.format('HH'));
  const startMinutes = parseInt(start.format('mm'));
  let endHour = parseInt(end.format('HH'));
  const endMinutes = parseInt(end.format('mm'));

  if (endHour === 0 && endMinutes === 0) endHour = 24;

  const from = startHour * 2 + (startMinutes < 30 ? 0 : 1);
  const to = endHour * 2 + (endMinutes === 0 ? -1 : endMinutes > 30 ? +1 : +0);

  return { from, to };
};

export const convertDateTimeShiftToInternalShift = shifts => {
  const mappedShifts = shifts.map(shift => ({
    ...getCellIndexFromTo(moment(shift.start, dateTimeFormat), moment(shift.end, dateTimeFormat)),
    type: shift.type,
    id: shift.id
  }));

  return cells.map((_, i) => {
    // We cycle through every cell to check if the cell is supposed to be inside a timeframe of a shift.
    const currentShiftType = mappedShifts
      .map(shift => {
        // We check if it is, below:
        if (shift.from <= i && shift.to >= i) return { ...shift.type, shiftID: shift.id };
        // If it is inside a shift we return its type. Otherwise return null
        return null;
      })
      .filter(t => t);

    if (!currentShiftType.length) return false;
    return currentShiftType[0]; // We get the first element because we are using .map() & .filter() . We could use .find() instead.
  });
};

export const calculateHoursOfRestFromInternalShifts = internalShifts => {
  // Returns the number of empty cells multiplied by 30 minutes (each cell is half an hour).
  return convertMinutesToHoursAndMinutes(internalShifts.filter(t => !t.id).length * 30);
};

export const generateDates = (rowsLength, startingDate) => {
  if (!rowsLength) return [];
  return Array(rowsLength)
    .fill(null)
    .map((row, index) => moment(startingDate).add(index, 'day'));
};

export const isDateToday = date =>
  moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY');

export const sumTotals = dates => {
  const totals = {
    any_7d: 0,
    any_24h: 0,
    overtime: 0,
    normal: 0,
    worked: 0
  };

  dates.forEach(date => {
    Object.keys(date.totals).forEach(key => {
      const value = date.totals[key];
      totals[key] += +value;
    });
  });

  return totals;
};

export const getWarnings = shifts => {
  const warnings = shifts.reduce((acc, crew) => {
    if (crew && crew.id && crew.dates && typeof crew.dates === 'object') {
      acc[crew.id] = Object.keys(crew.dates).reduce((accW, date) => {
        if (crew.dates[date] && crew.dates[date]?.warnings) {
          accW[date] = crew.dates[date]?.warnings || [];
        } else {
          accW[date] = [];
        }
        return accW;
      }, {});
    }
    return acc;
  }, {});

  return warnings;
};

export const generateIndexesFromShifts = (scheduledShifts, currentTableShifts) => {
  let updatedTable = [];
  let indexesToSkip = [];

  scheduledShifts.map(shift => {
    const fromHours = parseInt(shift.start.split(':')[0]);
    // < 30 is to check wether we need to color the 2nd box.
    const fromHasMinutes = parseInt(shift.start.split(':')[1]) < 30 ? false : true;
    const toHours = parseInt(shift.end.split(':')[0]);
    const toIndexesToAdd =
      parseInt(shift.end.split(':')[1]) > 0 && parseInt(shift.end.split(':')[1]) < 30
        ? 1
        : parseInt(shift.end.split(':')[1]) > 30
        ? 2
        : 0;

    for (let i = 0; i <= 47; i++) {
      //the loop runs 48 times because each hour out of 24 has 2 boxes to be coloured
      //so we need 2 indexes for each hour of the day.
      if (
        fromHours * 2 + (fromHasMinutes ? 1 : 0) <= i &&
        toHours * 2 + toIndexesToAdd > i
        //here we need the exact boxes to color because we have "> i" this is important because if shift ends at 15:00
        //we don't want to color the index 30 but if it ends at 15:45 we want to color both boxes.
        //and if it ends at 15:15 we want to color 1 extra box
      ) {
        //we get in here when the index is between (start-hour*2) and (end-hour*2) of each shift
        //*2 is needed because hour*2 represents the current index based on the comment above.
        updatedTable[i] = {
          id: shift.crew_shift_type_id,
          name: shift.is_watchkeeping ? 'Watch Keeping' : 'Regular Work',
          color: shift.color,
          description: null,
          shiftID: null
        };
        //once we add a new shift we add the index to a table so we will not override it
        indexesToSkip.push(i);
      } else {
        if (!indexesToSkip.includes(i)) {
          updatedTable[i] = currentTableShifts[0][i];
        }
      }
    }
  });

  return updatedTable;
};

export const isRankRecommendedForPromotion = (rank = '') => {
  const notAllowedRanks = ['captain', 'chief engineer'];
  return rank ? !notAllowedRanks.includes(rank.toLowerCase()) : true;
};

export const durationInYears = days => numberToStr(strToNumber(days / 365));
