import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

import eventIcon from 'common/assets/svg/common/events.svg';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import formIcon from 'common/assets/svg/common/forms.svg';
import jobIcon from 'common/assets/svg/common/jobs.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';

import SvgRender from 'common/components/general/SvgRender';

const originDetails = {
  form: {
    icon: formIcon,
    tooltip: 'Linked with Form',
    bgColor: 'moody-blue',
    textColor: 'white'
  },
  origin_form: {
    icon: formIcon,
    tooltip: 'Created within Form',
    bgColor: 'coral',
    textColor: 'white'
  },
  jobs: {
    tooltip: 'Linked with Job',
    icon: jobIcon,
    bgColor: 'primary',
    textColor: 'white'
  },
  event: {
    tooltip: 'Linked with Event',
    icon: eventIcon,
    bgColor: 'yellow',
    textColor: 'primary'
  },
  purchasing_requisition: {
    tooltip: 'Linked with Purchase Case',
    icon: purchasing,
    bgColor: 'green',
    textColor: 'white'
  }
};

const OriginIcon = ({ type, className, isLinked = true, isFromVessel = false }) => {
  const { avoidRender, tooltipID } = useTooltipID('finding-origin-icon');

  if (avoidRender) return null;

  const tooltipMessage = originDetails[type]?.tooltip;
  const showVesselIcon = !isLinked && isFromVessel;
  const showOfficeIcon = !isLinked && !isFromVessel;

  return (
    <div className={className}>
      {showVesselIcon || showOfficeIcon ? (
        <SvgRender
          src={showVesselIcon ? vesselIcon : companyIcon}
          className={'text-red'}
          style={{ height: 14, width: 14 }}
        />
      ) : (
        <div className="min-width-20 max-width-20 position-relative">
          <div id={tooltipID} className="h-100p position-absolute w-100p"></div>
          <Button
            color={originDetails[type]?.bgColor}
            className="pointer-events-none min-height-20 w-100p h-100p p-0 "
          >
            <SvgRender
              src={originDetails[type]?.icon}
              className={`text-${originDetails[type]?.textColor}`}
              style={{ height: 11, width: 11 }}
            />
          </Button>

          {tooltipMessage ? (
            <Tooltip
              innerClassName="min-width-fit max-width-fit"
              placement={'top'}
              target={tooltipID}
            >
              {tooltipMessage}
            </Tooltip>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default OriginIcon;

OriginIcon.propTypes = {
  type: PropTypes.oneOfType(['jobs', 'event', 'origin_form', 'form']).isRequired,
  isLinked: PropTypes.bool.isRequired,
  isFromVessel: PropTypes.bool.isRequired,
  className: PropTypes.string
};
