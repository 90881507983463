import React from 'react';

import { selectHasBasicJobLayout } from 'common/components/jobs/_base/store/selectors';

import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';

export const types = {
  onboard: 'onboard',
  vessel_processing: 'vessel_processing',
  done: 'done'
};

const JobLocked = ({ type, status = 'Done', className }) => {
  const hasBasicJobLayout = useSelector(selectHasBasicJobLayout);
  const isOnboard = useSelector(state => state.isOnBoard);

  const isGreen = type === types.done;
  return (hasBasicJobLayout && type === types.vessel_processing) || !hasBasicJobLayout ? (
    <div className="d-flex align-items-center">
      <div
        className={`job-locked ${
          isGreen && !hasBasicJobLayout ? 'job-locked__green' : ''
        } d-flex align-items-center justify-content-center ${className ? className : ''}`}
      >
        <div className="fw-medium fs-12">
          {type === types.onboard ? (
            <div className=" text-violet">
              This job is locked for <span className="fw-bold text-primary">onboard</span>
            </div>
          ) : type === types.vessel_processing ? (
            <div className="d-flex align-items-center text-violet">
              <SvgRender
                src={isOnboard ? companyIcon : vesselIcon}
                style={{ width: 14, height: 14 }}
                className=" me-2"
              />
              {isOnboard ? (
                <span>Job is locked for vessel. Office is processing</span>
              ) : (
                <span>Job is locked for office. Vessel is processing</span>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-center text-turquoise">
              <SvgRender src={vesselIcon} style={{ width: 14, height: 14 }} className=" me-2" />
              <span>
                Status was changed to <strong>{status}</strong>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="small-separator ms-2 cpy-12"></div>
    </div>
  ) : null;
};

export default JobLocked;
