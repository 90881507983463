import LinkedJobEntities from './jobs';
import LinkedFormEntities from './forms';
import LinkedFindings from './findings/LinkedFindings';
import LinkedEvents from './events/LinkedEvents';
import LinkedPurchasingRequisitionEntities from './purchasing-requisitions';
import LinkEntitiesWithTabs from './_components/LinkEntitiesWithTabs';

import PropTypes from 'prop-types';
import { Finding } from 'common/entities/findings/FindingTypes';
import { Event } from 'common/entities/events/EventTypes';

const LinkEntities = ({
  vessel,
  cancelTabAction,
  jobsState,
  formsState,
  findingsState,
  eventsState,
  purchasingCasesState
}) => {
  return (
    <>
      {!!jobsState ? (
        <div className="pb-3">
          <LinkedJobEntities vessel={vessel} cancelTabAction={cancelTabAction} {...jobsState} />
        </div>
      ) : null}
      {!!formsState ? (
        <div className="pb-3">
          <LinkedFormEntities vessel={vessel} cancelTabAction={cancelTabAction} {...formsState} />{' '}
        </div>
      ) : null}
      {!!purchasingCasesState ? (
        <div className="pb-3">
          <LinkedPurchasingRequisitionEntities
            vessel={vessel}
            cancelTabAction={cancelTabAction}
            {...purchasingCasesState}
          />
        </div>
      ) : null}
      {!!findingsState ? (
        <div className="pb-3">
          <LinkedFindings {...findingsState} />
        </div>
      ) : null}
      {!!eventsState ? (
        <div className="pb-3">
          <LinkedEvents {...eventsState} />
        </div>
      ) : null}
    </>
  );
};

LinkEntities.propTypes = {
  cancelTabAction: PropTypes.func,
  vessel: PropTypes.object, // Use it to filter vessel entities
  jobsState: PropTypes.shape({
    linkedJobs: PropTypes.array,
    addLinkedJob: PropTypes.func,
    removeLinkedJob: PropTypes.func,
    tabLabel: PropTypes.string
  }),
  formsState: PropTypes.shape({
    linkedForms: PropTypes.array,
    addLinkedForm: PropTypes.func,
    removeLinkedForm: PropTypes.func,
    tabLabel: PropTypes.string
  }),
  findingsState: PropTypes.shape({
    findingsState: PropTypes.arrayOf(Finding),
    className: PropTypes.string,
    color: PropTypes.string,
    onUnlink: PropTypes.func,
    isSingleLine: PropTypes.bool
  }),
  eventsState: PropTypes.shape({
    eventsState: PropTypes.arrayOf(Event),
    className: PropTypes.string,
    color: PropTypes.string
  }),
  purchasingCasesState: PropTypes.shape({
    linkedPurchasingCases: PropTypes.array,
    addLinkedPurchasingCase: PropTypes.func,
    removeLinkedPurchasingCase: PropTypes.func,
    tabLabel: PropTypes.string,
    onTogglePurchasingCaseDrawer: PropTypes.func
  })
};

const LinkEntitiesContainer = ({ withTabs, ...rest }) => {
  return withTabs ? <LinkEntitiesWithTabs {...rest} /> : <LinkEntities {...rest} />;
};

export default LinkEntitiesContainer;
