import PropTypes from 'prop-types';

const RemarksCorrectionTooltipContent = ({
  forCorrectionRemarks,
  forCorrectionRemarksActionable,
  sum
}) => (
  <div className={`${sum === 2 ? 'border-white border-top pt-1 mt-1' : ''}`}>
    <div className="fw-bold lh-15 cmb-6">
      Remarks viewed on the vessel side &nbsp;
      {forCorrectionRemarksActionable ? (
        <span className="text-red fs-10">- Actionable remarks only</span>
      ) : null}
    </div>
    <div className="fw-300">{forCorrectionRemarks}</div>
  </div>
);

RemarksCorrectionTooltipContent.propTypes = {
  forCorrectionRemarks: PropTypes.string,
  forCorrectionRemarksActionable: PropTypes.bool,
  sum: PropTypes.number
};

export default RemarksCorrectionTooltipContent;
