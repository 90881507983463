import { useMemo } from 'react';

import {
  GroupBase,
  Props,
  components,
  default as ReactSelect,
  DropdownIndicatorProps,
  MenuProps,
  OptionProps,
  IndicatorsContainerProps,
  ValueContainerProps,
  LoadingIndicatorProps
} from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

import _debounce from 'lodash/debounce';
import _isString from 'lodash/isString';

import FormGroupWrap, {
  FormGroupWrapProps
} from '@/ts-common/components/form/helpers/FormGroupWrap';
import {
  getAsyncOptions,
  getDefaultOptionLabel,
  getDefaultOptionValue,
  getSelectedValue,
  getSynchronousOptions
} from '@/ts-common/components/form/inputs/select/select-helpers';
import { OptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';
import { useDefaultOptionsQuery } from '@/ts-common/components/form/inputs/select/api/queries'; // Do NOT change this import
import { loadAsyncOptions } from '@/ts-common/components/form/inputs/select/api/api'; // Do NOT change this import
import MultipleMenu from './components/MultipleMenu';
import CreateOption from '@/ts-common/components/general/CreateOption';
import MultipleIndicatorsContainer from './components/MultipleIndicatorsContainer';
import MultipleMultiValueContainer from './components/MultipleMultiValueContainer';
import { styles } from './styles';

export type SelectProps<Option> = {
  memoizedExtraPortalStyle?: Record<string, unknown>;
  defaultOptions?: Option[];
  memoizedRequestParams?: OptionsRequestParams;
  size?: 'sm' | 'lg';
  invisible?: boolean;
  selectClassName?: string;
  parseOptions?: (options: Option[]) => Option[];
  onLoadOptionsCallback?: (options: Option[], search: string) => void;
  dynamicWidth?: boolean;
} & FormGroupWrapProps;

function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  className = '',
  selectClassName = '',
  error,
  isAsync = false,
  isMulti,
  options,
  dynamicWidth,
  menuPortalTarget = document.body,
  getOptionLabel,
  getOptionValue,
  defaultOptions,
  disabled,
  value,
  memoizedRequestParams,
  canSelectAllOptions = true,
  onCreateOption,
  isCreatable,
  components,
  memoizedExtraPortalStyle = {},
  size = 'sm',
  invisible,
  parseOptions,
  onLoadOptionsCallback,
  dataCy,
  ...rest
}: Props<Option, IsMulti, Group> & SelectProps<Option>) {
  const initialOptionsQuery = useDefaultOptionsQuery<Option>(memoizedRequestParams);

  const synchronousOptions = getSynchronousOptions<Option>(
    isAsync,
    initialOptionsQuery,
    parseOptions
  );
  const asyncOptions = getAsyncOptions<Option>(isAsync, initialOptionsQuery, parseOptions);

  const selected = getSelectedValue<Option, Group>(
    {
      isMulti,
      options:
        options ||
        ((synchronousOptions.length
          ? synchronousOptions
          : asyncOptions.length
            ? asyncOptions
            : []) as Option[]),
      value
    },
    getOptionValue
  );

  const fixedSelectProps = useMemo(
    () =>
      isMulti
        ? {
            isSearchable: false,
            hideSelectedOptions: false,
            closeMenuOnSelect: false,
            closeMenuOnScroll: true,
            backspaceRemovesValue: false,
            blurInputOnSelect: false
          }
        : {},
    [isMulti]
  );

  const debounceLoadOptions = isAsync
    ? _debounce((inputValue, callback) => {
        loadAsyncOptions<Option>(memoizedRequestParams, inputValue).then(data => {
          const finalizeData = parseOptions ? parseOptions(data as Option[]) : data;
          callback(finalizeData || []);
          if (onLoadOptionsCallback)
            onLoadOptionsCallback((finalizeData || []) as Option[], inputValue);
        });
      }, 500)
    : () => Promise.resolve([]);

  const selectStyles = useMemo(() => {
    let result = styles<Option, IsMulti, Group>({ size, error });

    if (menuPortalTarget) {
      result = {
        ...result,
        menuPortal: base => ({ ...base, zIndex: 9999, ...memoizedExtraPortalStyle })
      };
    }

    return result;
  }, [error, memoizedExtraPortalStyle, menuPortalTarget, size]);

  const Tag =
    isAsync && isCreatable
      ? AsyncCreatableSelect
      : isAsync
        ? AsyncSelect
        : isCreatable
          ? CreatableSelect
          : ReactSelect;

  return (
    <FormGroupWrap
      className={`${invisible ? 'invisible-select' : ''} ${className || ''}`}
      label={label}
      error={error}
      dataCy={dataCy}
    >
      <Tag
        className={`form-field react-select position-relative ${
          selectClassName ? selectClassName : ''
        } ${disabled ? 'disabled' : ''}${dynamicWidth ? ' dynamic-width' : ''}`}
        classNamePrefix={`react-select`}
        isMulti={isMulti}
        value={selected}
        menuPortalTarget={menuPortalTarget}
        getOptionLabel={getOptionLabel ? getOptionLabel : option => getDefaultOptionLabel(option)}
        getOptionValue={getOptionValue ? getOptionValue : option => getDefaultOptionValue(option)}
        loadOptions={debounceLoadOptions}
        isDisabled={disabled}
        canSelectAllOptions={canSelectAllOptions}
        onCreateOption={isCreatable && onCreateOption ? onCreateOption : undefined}
        styles={selectStyles}
        options={options ?? (synchronousOptions as Option[])}
        defaultOptions={defaultOptions ?? (asyncOptions as Option[])}
        components={{
          DropdownIndicator,
          Option,
          ...components,
          LoadingIndicator,
          Menu,
          ValueContainer: isMulti ? ValueContainer : components?.ValueContainer || ValueContainer,
          MultiValueContainer: MultipleMultiValueContainer,
          IndicatorsContainer
        }}
        menuPlacement="auto"
        noOptionsMessage={({ inputValue }) =>
          isAsync && label
            ? !inputValue.length
              ? `Search for ${_isString(label) ? label.toLowerCase() : 'options'}`
              : `No ${_isString(label) ? label.toLowerCase() : 'options'} found`
            : 'No options'
        }
        {...fixedSelectProps}
        {...rest}
      />
    </FormGroupWrap>
  );
}

export default Select;

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        className="dropdown-arrow"
        width="10"
        height="10"
        viewBox="0 0 10 6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.8333333 0L5 3.76 1.1666667 0 0 1.12 5 6l5-4.88z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const Option = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
) => {
  if (
    props.data &&
    typeof props.data === 'object' &&
    '__isNew__' in props.data &&
    props.data.__isNew__
  ) {
    return (
      <components.Option {...props}>
        <div onClick={props.selectProps.onCreateOption}>
          <CreateOption label="Create new" />
        </div>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};

const Menu = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: MenuProps<Option, IsMulti, Group>) => {
  if (props.isMulti)
    return <MultipleMenu<Option, IsMulti, Group> {...props}>{children}</MultipleMenu>;

  return <components.Menu {...props}>{children}</components.Menu>;
};

export const IndicatorsContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: IndicatorsContainerProps<Option, IsMulti, Group>) => {
  if (props.isMulti)
    return (
      <MultipleIndicatorsContainer<Option, IsMulti, Group> {...props}>
        {children}
      </MultipleIndicatorsContainer>
    );

  return <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>;
};

const ValueContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group>) => {
  // Show always the Placeholder indicator when isMulti && searching && !hasValue
  return (
    <components.ValueContainer {...props}>
      {props.isMulti && !props.hasValue && props.selectProps.inputValue.length ? (
        <span className="hidden-placeholder">{props.selectProps.placeholder}</span>
      ) : null}
      {children}
    </components.ValueContainer>
  );
};

const LoadingIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  ...props
}: LoadingIndicatorProps<Option, IsMulti, Group>) => {
  // Show always the Clear indicator when isMulti && hasValue
  if (props.isMulti) return props.hasValue ? <components.ClearIndicator {...props} /> : null;

  return <components.LoadingIndicator {...props} />;
};
