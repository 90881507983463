import NumberField from 'captain-reports/templates/inputs/NumberField';
import WeatherTemperatureHeader from 'captain-reports/templates/components/custom-fields/WeatherTemperatureHeader';
// import {
//   autocompleteRelativeWeatherDirections
//   // autocompleteRelativeWeatherCurrent
// } from 'captain-reports/templates/config/_autocompleteHelpers';
import { validateWeatherCourse } from './validations';
import RelativeWeatherField from 'captain-reports/templates/components/custom-fields/RelativeWeatherField';
import CheckboxInput from 'captain-reports/templates/inputs/CheckboxInput';

const weather = (
  { courseValidation } = {
    courseValidation: { templateValidation: { required: false } }
  }
) => [
  {
    className: 'weather-temperature-section',
    headerComponent: <WeatherTemperatureHeader />,
    fields: [
      {
        label: 'Vessel Heading',
        key: 'course_og',
        width: { xs: 6 },
        className: 'text-end-input',
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: '°', allowNegative: false },
        ...courseValidation
      },
      {
        label: 'Air',
        key: 'air_temperature',
        className: 'text-end-input',
        width: { xs: 3 },
        render: NumberField,
        category: 'temperature',
        type: 'number',
        inputProps: { subLabel: '°C' }
      },
      {
        label: 'Sea',
        className: 'text-end-input',
        key: 'sea_surface_temperature',
        width: { xs: 3 },
        render: NumberField,
        category: 'temperature',
        type: 'number',
        inputProps: { subLabel: '°C' }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Wind Force',
        key: 'wind_speed',
        width: { xs: 3 },
        className: 'text-end-input',
        render: NumberField,
        type: 'number',
        inputProps: {
          subLabel: 'bft',
          decimalScale: 0,
          isAllowed: ({ floatValue }) => (floatValue ? floatValue < 13 && floatValue >= 0 : true)
        }
      },
      {
        label: 'Direction',
        key: 'wind_direction',
        width: { xs: 3 },
        className: 'text-end-input',
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: '°' }
      },
      {
        key: 'wind_relative_direction',
        directionKey: 'wind_direction',
        label: 'Relative',
        width: { xs: 1 },
        className: 'pointer-events-none noborder-input',
        disabled: true,
        render: RelativeWeatherField,
        type: 'number',
        inputProps: { subLabel: '°', className: 'w-auto' }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Sea',
        key: 'sea_height',
        className: 'text-end-input',
        width: { xs: 3 },
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: 'm' }
      },
      {
        label: 'Direction',
        className: 'text-end-input',
        key: 'sea_direction',
        width: { xs: 3 },
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: '°' }
      },
      {
        key: 'sea_relative_direction',
        directionKey: 'sea_direction',
        label: 'Relative',
        width: { xs: 1 },
        className: 'pointer-events-none noborder-input ',
        disabled: true,
        render: RelativeWeatherField,
        inputProps: { subLabel: '°', className: 'w-auto' }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Swell',
        key: 'swell_height',
        className: 'text-end-input',
        width: { xs: 3 },
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: 'm' }
      },
      {
        label: 'Direction',
        key: 'swell_direction',
        width: { xs: 3 },
        className: 'text-end-input',
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: '°' }
      },
      {
        key: 'swell_relative_direction',
        directionKey: 'swell_direction',
        label: 'Relative',
        width: { xs: 1 },
        className: 'pointer-events-none noborder-input  ',
        disabled: true,
        render: RelativeWeatherField,
        inputProps: { subLabel: '°', className: 'w-auto' }
      }
    ]
  },
  {
    fields: [
      {
        label: 'Current',
        key: 'ocean_current_speed',
        width: { xs: 3 },
        className: 'text-end-input',
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: 'kt' }
      },
      {
        label: 'Direction',
        key: 'ocean_current_direction',
        className: 'text-end-input',
        width: { xs: 3 },
        render: NumberField,
        type: 'number',
        inputProps: { subLabel: '°' }
      },
      {
        key: 'ocean_current_relative_direction',
        directionKey: 'ocean_current_direction',
        label: 'Relative',
        width: { xs: 2 },
        className: 'pointer-events-none noborder-input ',
        disabled: true,
        render: RelativeWeatherField,
        inputProps: { subLabel: '°', className: 'w-auto' }
      },
      {
        key: 'ocean_current_direction_is_variable',
        label: 'Variable Direction',
        width: { xs: 4 },
        className: 'text-uppercase fs-9',
        labelClassName: 'fw-medium',
        render: CheckboxInput
      }
    ]
  },
  {
    fields: [
      {
        label: 'Barometric pressure',
        subLabel: 'mbar',
        className: 'text-end-input',
        inputProps: { subLabel: ' ' },
        key: 'atmospheric_pressure',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const weatherConditionsConfig = {
  noon: () =>
    weather({
      courseValidation: {
        validation: validateWeatherCourse,
        templateValidation: { required: true }
      }
    }),
  arrival: () => weather(),
  ballast: () => [],
  departure: () => weather(),
  port_noon: () => weather(),
  //
  delivery: () => weather({ courseValidation: {} }),
  redelivery: () => weather({ courseValidation: {} }),
  stoppage: () => weather({ courseValidation: {} }),
  instructed_speed: () => [],
  passage_plan: () => []
};

export default weatherConditionsConfig;
