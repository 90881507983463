import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api';

export const getCrewChanges = createAsyncThunk(
  'GET_CREW_CHANGES',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get('/crew-ticketing-cases/events', params);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
