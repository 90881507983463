import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getEventAudits = createAsyncThunk(
  'GET_EVENT_AUDITS',
  async (params, { rejectWithValue }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await get(`/events/${event_id}/audits`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createEventAudit = createAsyncThunk(
  'CREATE_EVENT_AUDIT',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/audits`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEventAudit = createAsyncThunk(
  'UPDATE_EVENT_AUDIT',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, audit_id, ...rest } = params;

    try {
      const res = await put(`/events/${event_id}/audits/${audit_id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteEventAudit = createAsyncThunk(
  'DELETE_EVENT_AUDIT',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, audit_id, ...rest } = params;

    try {
      const res = await deleteRequest(`/events/${event_id}/audits/${audit_id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createEventAuditFinding = createAsyncThunk(
  'CREATE_EVENT_FINDING',
  async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const { event_id, audit_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/audits/${audit_id}/findings`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return fulfillWithValue({ finding: res.data, auditId: audit_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEventAuditFinding = createAsyncThunk(
  'UPDATE_EVENT_FINDING',
  async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const { event_id, audit_id, ...rest } = params;

    try {
      const res = await put(`/events/${event_id}/audits/${audit_id}/findings`, rest);

      return fulfillWithValue({ finding: res.data, auditId: audit_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteEventAuditFinding = createAsyncThunk(
  'DELETE_EVENT_FINDING',
  async (params, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const { event_id, audit_id, finding_id, ...rest } = params;

    try {
      const res = await deleteRequest(
        `/events/${event_id}/audits/${audit_id}/findings/${finding_id}`,
        rest
      );
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return fulfillWithValue({ finding: res.data, auditId: audit_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getAuditFindings = createAsyncThunk(
  'GET_EVENT_AUDIT_FINDINGS',
  async (params, { rejectWithValue, dispatch }) => {
    const { table, eventId, auditId, ...rest } = params;

    try {
      const res = await get(`/events/${eventId}/audits/${auditId}/findings`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getEventSubmissionFindings = createAsyncThunk(
  'GET_EVENT_SUBMISSION_FINDINGS',
  async (params, { rejectWithValue }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await get(`/events/${event_id}/submission-findings`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getEventAuditsEvaluationCriteria = createAsyncThunk(
  'GET_EVENT_AUDITS_EVALUATION_CRITERIA',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/events/audits/evaluation-criteria`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const submitEventAuditEvaluations = createAsyncThunk(
  'SUBMIT_EVENT_AUDIT_EVALUATIONS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_audit_id, ...rest } = params;

    try {
      const res = await post(`/events/audits/${event_audit_id}/evaluation`, rest);
      dispatch(
        successHandler({ title: 'Success!', message: 'Evaluations submitted successfully' })
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const unlinkEventFindings = createAsyncThunk(
  'UNLINK_EVENT_FINDING',
  async (params, { rejectWithValue, dispatch }) => {
    const { eventId, eventAuditId, id, ...rest } = params;

    try {
      await deleteRequest(`/events/${eventId}/audits/${eventAuditId}/findings/${id}`, rest);

      return params;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
