import { Row, Col } from 'reactstrap';

import Table from 'views/dashboard/components/Table';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/general/Status';

import _get from 'lodash/get';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import moment from 'moment';
import { Department } from 'common/components/general/Departments';

import TableViewIcon from 'views/dashboard/components/TableViewIcon';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';

const Events = () => {
  const columns = [
    {
      key: 'name',
      header: '',
      width: 3
    },
    {
      key: 'type',
      header: 'TYPE',
      width: 2
    },
    {
      key: 'department',
      header: 'DEPARTMENT',
      width: 2
    },
    {
      key: 'status',
      header: 'STATUS',
      width: 2
    },
    {
      key: 'date',
      header: 'DATE',
      width: 3
    }
  ];
  return (
    <div className="forms-listing">
      <Table
        label="events"
        className="mt-8"
        header={<HeaderTitle title="Events" path={paths.events} />}
        subheader={<span className="text-violet">Sorted by Date</span>}
        columns={columns}
        rows={{
          name: data => (
            <Row className="d-flex align-items-center ">
              <Col className="max-width-24">
                <Priority size="large" value={data?.importance.id} withText={false} />
              </Col>
              <Col className="width-168">
                <TextWithTooltip>{_get(data, 'name', '-')}</TextWithTooltip>
              </Col>
            </Row>
          ),
          type: data => _get(data, 'type.name', '-'),
          title: data => (
            <Row className="align-items-center ">
              <Col className="max-width-24">
                <Priority size="large" value={data?.importance_id} withText={false} />
              </Col>
              <Col>
                <span>{_get(data, 'title', '-')}</span>
              </Col>
            </Row>
          ),
          department: data =>
            data?.departments?.length ? (
              <div className="w-auto">
                <Department value={data?.departments[0]} />
              </div>
            ) : (
              '-'
            ),
          status: data => (data?.status ? <Status value={data.status} /> : '-'),
          date: data => (
            <div className="d-flex">
              {data.date_type === 'single' && data?.started_at ? (
                <span>{moment(data.started_at).format('DD/MM/YYYY')}</span>
              ) : data.date_type === 'range' && data?.started_at && data?.ended_at ? (
                <TextWithTooltip>
                  {moment(data.started_at).format('DD/MM/YYYY')} -{' '}
                  {moment(data.ended_at).format('DD/MM/YYYY')}
                </TextWithTooltip>
              ) : (
                '-'
              )}
              {data?.id ? (
                <div className="ms-auto">
                  <TableViewIcon
                    to={`${paths.events}/${data.id}`}
                    icon={eyeSvg}
                    className={'text-primary'}
                  />
                </div>
              ) : null}
            </div>
          )
        }}
      />
    </div>
  );
};

export default Events;
