import React from 'react';

const tableData = {
  headers: [
    ['S', 60],
    ['SEVERITY', 160],
    ['L', 60],
    ['LIKELIHOOD', 160],
    ['RISK RATING', 150]
  ],
  rows: [
    ['1', 'Negligible', '1', 'Improbable', 'SxP=', 'RF'],
    ['2', 'Minor', '2', 'Remote', 'RF', 'Risk'],
    ['3', 'Significant', '3', 'Possible', 'L', 'Low'],
    ['4', 'Critical', '4', 'Likely', 'M', 'Medium'],
    ['5', 'Catastrophic', '5', 'Certain', 'H', 'High']
  ],
  widths: [60, 160, 60, 160, 64, 86]
};

const AnalysisTable = ({ previewMode }) => {
  return (
    <div className={`analysis-table h-100p ${previewMode ? 'preview' : ''}`}>
      <strong className="d-flex analysis-table--title bg-violet text-white px-1 fs-12">
        ANALYSIS OF EXPRESSIONS USED TO CALCULATE RISK
      </strong>

      <div className="d-flex">
        {tableData.headers.map((h, i) => (
          <strong
            id={`header-${i}`}
            className={`fs-12 py-1 cps-12 ${(i + 1) % 2 === 0 ? 'text-violet' : 'text-primary'} ${
              i !== 0 ? 'border-start border-bottom border-white' : 'border-bottom border-white'
            }`}
            style={{ width: h[1] }}
          >
            {h[0]}
          </strong>
        ))}
      </div>

      <div>
        {tableData.rows.map((h, index) => {
          return (
            <div id={`row-wrapper-${index}`} className="d-flex">
              {h.map((el, i) => {
                const isLastRow = index + 1 === tableData.rows.length;
                const isLastElement = i + 1 === h.length;
                const specificBold = index === 1 && i === 4;
                return (
                  <span
                    id={`row-${i}`}
                    className={`fs-12 py-1 cps-12 text-primary border-white ${
                      i !== 0 ? 'border-start' : ''
                    } ${!isLastRow ? 'border-bottom' : ''} ${
                      isLastElement || specificBold ? 'fw-bold' : ''
                    }`}
                    style={{ width: tableData.widths[i] }}
                  >
                    {el}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnalysisTable;
