import React from 'react';
import PropTypes from 'prop-types';

const PlaceAndDateText = ({ place, date }) => {
  return (
    <div>
      {!place && !date ? (
        <div>-</div>
      ) : (
        <div className="d-flex align-items-center">
          <span className="fs-12">{place}</span>
          <span className="text-violet fs-12 cms-4">{date && place ? `| ${date}` : date}</span>
        </div>
      )}
    </div>
  );
};

PlaceAndDateText.propTypes = {
  place: PropTypes.string,
  date: PropTypes.string
};

export default PlaceAndDateText;
