import Table from 'views/dashboard/components/Table';
import TableViewIcon from 'views/dashboard/components/TableViewIcon';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';

import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';
import { useMemo } from 'react';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { displayDate } from 'common/utils/dates';
import DeliveryReportStatus from 'common/components/purchasing/requisition/components/DeliveryReportStatus';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const ToBeDeliveredPurchasingCases = () => {
  const requestParams = useMemo(
    () => ({
      filters: [{ name: 'is_delivered', operation: '=', value: false }]
    }),
    []
  );

  return (
    <Table
      className="mt-8"
      header={
        <HeaderTitle
          title="Purchasing Cases to Deliverables"
          path={`${paths.purchasing_deliverables}`}
        />
      }
      label="purchasing_requisitions_dlv"
      requestParams={requestParams}
      columns={[
        {
          key: 'code',
          width: 3
        },
        {
          header: 'SUPPLIER',
          key: 'supplier',
          headerClassName: 'text-violet',
          width: 4
        },
        {
          header: 'DELIVERY PORT',
          key: 'delivery_port',
          headerClassName: 'text-violet',
          width: 2
        },
        {
          header: 'DELIVERY DATE',
          key: 'delivery_date',
          type: 'date',
          width: 1
        },
        {
          key: 'status',
          width: 2
        }
      ]}
      rows={{
        code: data => (
          <TextWithTooltip className="h-auto">
            <span>{data.requisition && data?.requisition?.code}</span>
            {data.requisition && data.requisition.suppliers_count > 1 ? (
              <span className="ms-1">
                <strong>1/{data.requisition.suppliers_count}</strong>
              </span>
            ) : null}
          </TextWithTooltip>
        ),
        supplier: data => (
          <TextWithTooltip className="h-auto">{data.supplier.full_name}</TextWithTooltip>
        ),
        delivery_port: data => (
          <TextWithTooltip className="h-auto">
            {`${
              data.delivery_port?.name
                ? `${data?.delivery_port?.name} (${data.delivery_port?.code})`
                : ''
            }`}
          </TextWithTooltip>
        ),
        delivery_date: data => displayDate(data?.delivery_date, { time: true }),
        status: data => (
          <div className="d-flex align-items-center">
            <DeliveryReportStatus status={data?.delivery_status} />
            <AuthCheck permissions={[permissions.onboard.purchasing.requisitions.view]}>
              <TableViewIcon
                to={`${paths.purchasing_requisitions}/${data?.requisition_id}/suppliers/${data?.id}`}
                icon={eyeSvg}
                className={'text-primary'}
              />
            </AuthCheck>
          </div>
        )
      }}
    />
  );
};

export default ToBeDeliveredPurchasingCases;
