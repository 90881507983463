const state = {
  has_checklist_options: false,
  checklist: {
    jobId: null,
    options: [],
    openLists: []
  }
};

export default state;
