import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ShadowBox from 'common/components/general/ShadowBox';
import DateInput from 'common/components/form/inputs/date';
import SvgRender from 'common/components/general/SvgRender';
import FormSaveActions from 'common/components/form/FormSaveActions';
import { useForm, useFormState } from 'utils/hooks';
import { dateField } from 'common/utils/form/fieldTypes';
import { useDispatch, useSelector } from 'react-redux';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import { selectEventLubOilAnalysisData } from 'events/store/event-modules/lub-oil-analysis/selectors';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import { updateEventLubOilAnalysis } from 'events/store/event-modules/lub-oil-analysis/actions';
import { selectEventId } from 'events/store/events/selectors';

const Section = ({ icon, text, ...field }) => {
  return (
    <ShadowBox className="px-3 py-2 d-flex" color="light-4" flat>
      <DateInput className="mb-0" {...field} />
      <div className="d-flex align-items-center ms-3 ps-3 border-start text-violet">
        <SvgRender src={icon} style={{ width: 14, height: 14 }} className="me-1" />
        <strong className="fs-10 fw-medium">{text}</strong>
      </div>
    </ShadowBox>
  );
};

const config = {
  samples_delivery_date: dateField(),
  result_date: dateField()
};

const Form = ({ setIsEditing }) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const data = useSelector(selectEventLubOilAnalysisData);
  const eventId = useSelector(selectEventId);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const { formState, collectMutatedValues, loadValues } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  useEffect(() => {
    loadValues(selectDataFromState(data, config));
  }, [data, loadValues]);

  const onSave = async () => {
    try {
      const values = collectMutatedValues();

      setIsSaving(true);

      const params = { event_id: eventId, ...values };

      await dispatch(updateEventLubOilAnalysis(params)).unwrap();

      setIsSaving(false);
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  return (
    <Row className="position-relative" noGutters>
      <Col xs={isOnboard ? 12 : 6} className="cpe-4">
        <Section
          label="SAMPLES SENT FOR ANALYSIS"
          disabled={!isOnboard}
          icon={vesselIcon}
          text={
            isOnboard
              ? '*Edit only from the vessel, visible in the office.'
              : '*Edit only from the vessel.'
          }
          onChange={selectField('samples_delivery_date')}
          {...fields.samples_delivery_date}
        />
      </Col>
      {isOnboard ? null : (
        <Col xs={6} className="cps-4">
          <Section
            label="DATE OF RESULT"
            icon={companyIcon}
            text="*Edit only from the office, visible onboard."
            onChange={selectField('result_date')}
            {...fields.result_date}
          />
        </Col>
      )}
      <FormSaveActions
        onCancel={() => setIsEditing(false)}
        onSave={onSave}
        style={{ right: -8, top: 10 }}
        isDisabled={isSaving}
      />
    </Row>
  );
};

Form.propTypes = {
  setIsEditing: PropTypes.func
};

export default Form;
