import React, { useEffect, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import useRouter from 'use-react-router';
import _get from 'lodash/get';

import { useFormState } from 'utils/hooks';
import { getInitialAsyncValues } from 'utils/helpers';

import PeriodCrewInfo from './components/PeriodCrewInfo';
import TemplateSelector from './components/TemplateSelector';
import ReasonSelector from './components/ReasonSelector';
import { getContractsInRange } from 'crew/profile/main/evaluation/form/helpers';

const EvaluationCreateTop = ({ formState, evaluationID, isDraft, setLoading, isOnboard }) => {
  const { fields, selectField } = useFormState(formState);
  const { match } = useRouter();

  const getCrewContractDays = useCallback(async () => {
    try {
      const contracts = await getInitialAsyncValues('crew-contracts', null, null, {
        crew_member_id: match.params.id
      });

      const contractsInRange = getContractsInRange(contracts, {
        from: _get(contracts, '0.contract_from_date', null),
        to: _get(contracts, '0.contract_to_date', null)
      });

      if (contractsInRange && contractsInRange.length) {
        const { vessel, rank } =
          contractsInRange.filter(contract => contract.vessel && contract.rank)[0] || {};

        if (vessel && rank) {
          selectField('vessel_id')(vessel.id);
          selectField('vessel_name')(vessel.name);
          selectField('rank_id')(rank.id);
          selectField('rank_name')(rank.name);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [match.params.id, selectField]);

  useEffect(() => {
    getCrewContractDays();
  }, [getCrewContractDays]);

  return (
    <>
      <Row noGutters>
        <Col>
          <Row noGutters className="mt-3">
            <Col xs={12} className="pe-5">
              <PeriodCrewInfo fields={fields} isOnboard={isOnboard} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={6} className="pe-3">
          <ReasonSelector
            fields={fields}
            selectField={selectField}
            evaluationID={evaluationID}
            isDraft={isDraft}
            setLoading={setLoading}
          />
        </Col>
        <Col>
          <TemplateSelector
            fields={fields}
            selectField={selectField}
            evaluationID={evaluationID}
            isDraft={isDraft}
            setLoading={setLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default EvaluationCreateTop;
