import { numberField, stringField, optionField } from 'common/utils/form/fieldTypes';

const config = {
  category_id: stringField({ required: true }),
  supplier: optionField({ initialValue: {} }),
  currency_id: stringField({ required: true }),
  date: stringField({ required: true }),
  price: numberField({ required: true }),
  comments: stringField(),
  attachments: optionField({ initialValue: [] }),
  base_currency_equivalent_rate: stringField(),
  price_base_currency_equivalent: stringField()
};

export default config;
