import { Button } from 'reactstrap';
import { useState } from 'react';

import Search from 'common/components/form/styled-inputs/SearchInput';
import SvgRender from 'common/components/general/SvgRender';
import VesselSelector from 'common/components/selectors/VesselSelector';
import SelectLoaderIndicator from 'common/components/form/helpers/SelectLoaderIndicator';
import { DropdownIndicator } from 'common/components/form/inputs/Select';

import searchSvg from 'common/assets/svg/common/search.svg';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

const Header = ({
  search,
  disabled,
  allExpanded,
  hasCollapsedContent,
  onSearchChange,
  toggleCollapse,
  placeholder = 'Search...',
  showVesselSelect,
  onVesselChange,
  vessel,
  onSearchClear
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [isVesselSearchLoading, setIsVesselSearchLoading] = useState(false);

  const onSearchPress = () => setShowSearch(true);
  const onClear = () => {
    setShowSearch(false);

    if (onSearchClear) {
      onSearchClear();
    }
  };

  const onVesselSearch = async ({ value }) => {
    setIsVesselSearchLoading(true);

    await onVesselChange(value);

    setIsVesselSearchLoading(false);
  };

  return (
    <div className="chapters-tree__header d-flex align-items-center">
      {!showVesselSelect || showSearch ? (
        <Search
          onChange={onSearchChange}
          className={`chapters-tree__header-search ${disabled ? 'disabled' : ''} flex-1 mb-0 ${
            showVesselSelect ? 'chapters-tree__header-search__with_select' : ''
          } ${showSearch ? 'cme-12' : ''}`}
          inputProps={{ placeholder, disabled: disabled }}
          initialValue={search}
          showClearButton={search || showSearch ? true : false}
          onClear={onClear}
        />
      ) : (
        <>
          <div
            data-testid="tree-layout-header-search-btn"
            onClick={onSearchPress}
            className="chapters-tree__header-search-btn d-flex align-items-center justify-content-center cursor-pointer cme-12"
          >
            <SvgRender src={searchSvg} style={{ width: 14, height: 14 }} className="text-primary" />
          </div>
          <div className="flex-1 filter-handler">
            <VesselSelector
              filter={{ value: vessel ? [vessel.id] : null }}
              isMulti={false}
              isClearable
              autoFocus={false}
              onChange={onVesselSearch}
              placeholder="Select vessel"
              size="lg"
              components={{
                DropdownIndicator: isVesselSearchLoading ? SelectLoaderIndicator : DropdownIndicator
              }}
            />
          </div>
        </>
      )}

      {hasCollapsedContent ? (
        <Button
          type="button"
          color="link"
          className="d-inline-flex align-items-center text-primary p-0 cms-12"
          disabled={disabled}
          onClick={() => (allExpanded ? toggleCollapse(false) : toggleCollapse(true))}
        >
          <strong className="fw-medium fs-12 lh-1">
            {allExpanded ? 'Collapse all' : 'Expand all'}
          </strong>
          <SvgRender
            className="ms-1 base-transition"
            src={arrow}
            style={{ height: 12, width: 8, transform: allExpanded ? '' : 'rotate(180deg)' }}
          />
        </Button>
      ) : null}
    </div>
  );
};

export default Header;
