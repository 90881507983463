import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Nav } from 'reactstrap';

import { useWindowWidth } from 'utils/hooks';

import CircledButton from 'common/components/buttons/CircledButton';
import SvgRender from 'common/components/general/SvgRender';
import sortingIcon from 'common/assets/svg/table/sorting.svg';

const ScrollTabs = props => {
  const { onAdd, pills, children, ...rest } = props;

  const [isScrolling, setIsScrolling] = useState(false);
  const [scroll, setScroll] = useState(0);

  const [domItem, setDomItem] = useState(null);
  const [domParent, setDomParent] = useState(null);

  const windowWidth = useWindowWidth();
  const itemRef = useRef(null);
  const activeTab = children && children.filter(child => child.props.active);

  useEffect(() => {
    if (itemRef.current) {
      setDomItem(ReactDOM.findDOMNode(itemRef.current));
      setDomParent(ReactDOM.findDOMNode(itemRef.current.parentNode.parentNode.parentNode));
    }
  }, [activeTab, windowWidth, children ? children.length : 0]);

  const isScrollable = domParent
    ? ReactDOM.findDOMNode(domParent.parentNode.parentNode.parentNode).getBoundingClientRect()
        .width < domParent.getBoundingClientRect().width
    : false;

  const left = domItem
    ? domItem.getBoundingClientRect().x -
      ReactDOM.findDOMNode(domParent.parentNode.parentNode.parentNode).getBoundingClientRect().x +
      (isScrollable ? scroll : 0)
    : 0;
  const width = !pills && domItem ? domItem.getBoundingClientRect().width : null;

  return (
    <div className={`scrolltabs-container scrolltabs-container-${pills ? 'pills' : 'tabs'}`}>
      <div className="scrolltabs-container-fade" />
      <div className="scrolltabs-container-inner position-relative">
        <Scrollbars
          className="scrolltabs-tabs"
          thumbSize={100}
          renderThumbHorizontal={() =>
            isScrollable ? (
              <div className={`${isScrolling ? 'is-scrolling' : ''} scrolltabs-scroll`}>
                <SvgRender
                  className="scrolltabs-scroll-icon"
                  src={sortingIcon}
                  style={{ height: 16, width: 10 }}
                />
                <span className="scrolltabs-scroll-text">Slide</span>
              </div>
            ) : (
              <div />
            )
          }
          onScrollFrame={_scroll => setScroll(_scroll.scrollLeft)}
          onScrollStart={() => setIsScrolling(true)}
          onScrollStop={() => setIsScrolling(false)}
          onUpdate={_scroll => setScroll(_scroll.scrollLeft)}
        >
          <div className="d-flex">
            <div className="tabs-wrapper">
              <div
                className={`tabs-indicator-${pills ? 'pills' : 'tabs'}`}
                style={{ left, width }}
              />
              <NavBar pills={pills} {...rest} itemRef={itemRef}>
                {children}
              </NavBar>
            </div>

            {!isScrollable && (
              <div className="position-sticky">
                {!!onAdd && (
                  <CircledButton className={`scrolltabs-add`} type="add" onClick={onAdd} />
                )}
              </div>
            )}
          </div>
        </Scrollbars>
        {isScrollable && !!onAdd && (
          <CircledButton
            className={`scrolltabs-add scrolltabs-add__fixed `}
            type="add"
            onClick={onAdd}
          />
        )}
      </div>
    </div>
  );
};

const Tabs = props => {
  const { pills, children, ...rest } = props;

  const [domItem, setDomItem] = useState(null);
  const [domParent, setDomParent] = useState(null);

  const windowWidth = useWindowWidth();
  const itemRef = useRef(null);
  const activeTab = children && children.filter(child => child.props.active);

  useEffect(() => {
    if (itemRef.current) {
      setDomItem(ReactDOM.findDOMNode(itemRef.current));
      setDomParent(ReactDOM.findDOMNode(itemRef.current.parentNode.parentNode.parentNode));
    }
  }, [activeTab, windowWidth]);

  const left = domItem
    ? domItem.getBoundingClientRect().x - domParent.getBoundingClientRect().x
    : 0;

  const width = !pills && domItem ? domItem.getBoundingClientRect().width : null;

  return (
    <div className={`tabs-container position-relative tabs-container-${pills ? 'pills' : 'tabs'}`}>
      <div className={`tabs-indicator-${pills ? 'pills' : 'tabs'}`} style={{ left, width }} />
      <NavBar pills={pills} {...rest} itemRef={itemRef}>
        {children}
      </NavBar>
    </div>
  );
};

const NavBar = ({ pills, children, itemRef, ...rest }) => {
  return (
    <Nav pills={pills} {...rest}>
      {React.Children.map(children, child => {
        if (child && child.props.active) {
          return React.cloneElement(child, {
            children: <div ref={itemRef}>{child.props.children}</div>
          });
        }
        return child;
      })}
    </Nav>
  );
};

export { ScrollTabs, Tabs };
