import React from 'react';
import UltimatePaginationBootstrap4 from 'react-ultimate-pagination-bootstrap-4';
// import GoToPage from './GoToPage';

import { refetchPageTableData } from 'common/components/table/utils/helpers';

const Pagination = ({
  paging,
  fetchData,
  siblingPagesRange,
  boundaryPagesRange,
  scrollToTopAfterPageChanged
}) => {
  return paging?.current_page <= paging?.last_page && paging?.last_page > 1 ? (
    <div className="app-table--pagination">
      {paging.current_page !== undefined && paging.current_page !== 0 && (
        <UltimatePaginationBootstrap4
          currentPage={paging.current_page}
          totalPages={paging.last_page || 1}
          onChange={page =>
            refetchPageTableData(fetchData, paging, page, scrollToTopAfterPageChanged)
          }
          hideFirstAndLastPageLinks={true}
          siblingPagesRange={siblingPagesRange}
          boundaryPagesRange={boundaryPagesRange}
        />
      )}

      {/* {paging.last_page >= 8 && <GoToPage paging={paging} fetchData={fetchData} />} */}
    </div>
  ) : null;
};

export default Pagination;
