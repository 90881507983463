import React from 'react';
import _isNumber from 'lodash/isNumber';

import _get from 'lodash/get';

const TotalsSum = ({ totals }) => {
  const getTime = (key, decimalPlaces = 2) => {
    const value = _get(totals, key);

    if (!value && !_isNumber(value)) return '-';

    return (value / 60).toFixed(decimalPlaces);
  };

  return (
    <div className="shifts-table__totals">
      <span className="shifts-table__totals--total ps-1 fw-normal">TOTAL:</span>
      <span className="shifts-table__totals--scheduled">{getTime('normal')}</span>
      <span className="shifts-table__totals--worked">{getTime('worked')}</span>
      <span className="shifts-table__totals--overtime">{getTime('overtime')}</span>
      <span className="shifts-table__totals--any_24h">{getTime('any_24h', 1)}</span>
      <span className="shifts-table__totals--any_7d">{getTime('any_7d', 1)}</span>
    </div>
  );
};

export default TotalsSum;
