import EntityLabel from 'common/components/labels/EntityLabel';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const RoleName = ({ name, color }: { name: string; color: string }) => (
  <EntityLabel className="border-0 me-0 border-radius-3" type="department" color={color}>
    <TextWithTooltip>{name}</TextWithTooltip>
  </EntityLabel>
);

export default RoleName;
