import React, { Fragment, useState } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import useTooltipID from 'common/utils/hooks/useTooltipID';

import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import Tooltip from 'common/components/general/Tooltip';
import AuthCheck from 'components/permissions/AuthCheck';

const RenderedActions = ({ action, index, handleActionClick }) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  const onClick = (action, id) => {
    setHasBeenClicked(true);
    setTimeout(() => {
      setHasBeenClicked(false);
    }, 2000);
    handleActionClick(action, id);
  };

  const { avoidRender, tooltipID } = useTooltipID(
    `${action.label ? action.label.toLowerCase().split(' ').join('_') : ''}`
  );

  if (avoidRender || _isEmpty(action)) return null;

  return (
    <AuthCheck
      permissions={action.permissions}
      mode={action.permissionsMode}
      avoidCheck={!action.permissions}
    >
      {action.separator ? (
        <div className="cms-12 cme-12 pt-2 border-start" />
      ) : action.rounded ? (
        <div
          key={index}
          id={tooltipID}
          className={`action-button ${action.wrapperClassName}`}
          onClick={() => onClick(action)}
        >
          <SvgRender style={action.iconStyles} className={`me-1`} src={action.icon} />
          <div>{action.label}</div>
        </div>
      ) : (
        <div
          key={index}
          id={tooltipID}
          className={`action-icon ${action.wrapperClassName || action.label?.toLowerCase()}`}
          data-cy={action.label ? `action_${action.label?.toLowerCase()}` : ''}
          onClick={() => onClick(action)}
        >
          <SvgRender
            style={action.svgStyle}
            className={`${action.className ? action.className : ''}`}
            src={action.icon}
          />
        </div>
      )}
      {!hasBeenClicked && action.label && !action.rounded ? (
        <Tooltip target={tooltipID} fade={false}>
          {action.label}
        </Tooltip>
      ) : null}
    </AuthCheck>
  );
};

const BodyCellActions = ({ col, row, rowIndex, data, setDangerousModal }) => {
  const rowData = _isFunction(row) ? row(data) : row;

  const handleActionClick = action => {
    if (action.preventionModal) {
      // any other prevention modal
      setDangerousModal({
        isOpen: true,
        ...action.modalProps
      });
    } else if (!action.modal) {
      action.onClick(col, data, rowIndex);
    } else {
      // archive/unarchive modal
      setDangerousModal({
        isOpen: true,
        isArchived: action.isArchived,
        actionText: action.label,
        header: action.header,
        body: action.body,
        params: { id: data.id }
      });
    }
  };

  if (col?.hidden) return null;

  return rowData.options
    .filter(option => option)
    .map((action, index) => {
      return (
        <Fragment key={index}>
          {!action.condition ? (
            <RenderedActions action={action} index={index} handleActionClick={handleActionClick} />
          ) : action.condition() ? (
            <RenderedActions action={action} index={index} handleActionClick={handleActionClick} />
          ) : null}
        </Fragment>
      );
    });
};

export default React.memo(BodyCellActions);
