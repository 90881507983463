import { createSelector } from 'reselect';
import { selectTableList } from 'common/components/table/store/selectors';

const selectTableReducer = state => state.tables;

export const selectCurrentLocationPath = createSelector(
  [selectTableReducer],
  table => table.location.path
);

export const selectPreviousLocationPath = createSelector(
  [selectTableReducer],
  table => table.previousLocation.path
);

export const selectTableColumns = createSelector(selectTableList, table => table.columns);

export const selectTableTotals = createSelector(selectTableList, table => table?.totals || {});

export const selectTableSorting = createSelector(selectTableList, table => table?.sorting || {});
