import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import findingsIcon from 'common/assets/svg/common/findings.svg';
import findingsHiddenIcon from 'common/assets/svg/common/findings-hidden.svg';
import SvgRender from 'common/components/general/SvgRender';

const FindingsExpandButton = ({
  isCollapsed = true,
  className,
  label,
  findingsCount = 0,
  svgStyle,
  onClick = () => {}
}) => {
  return (
    <Button
      type="button"
      className={`d-flex align-items-center justify-content-center px-1 bg-white ${
        className || ''
      }`}
      onClick={onClick}
    >
      <SvgRender
        src={isCollapsed ? findingsIcon : findingsHiddenIcon}
        style={svgStyle || { width: 14, height: 14 }}
        className="text-primary"
      />

      {label ? (
        <span className="cms-12 fs-12 text-primary text-nowrap fw-medium cme-4">{label}</span>
      ) : null}

      <span className="fw-bold ms-1 fs-12 text-primary">{findingsCount}</span>
    </Button>
  );
};

export default FindingsExpandButton;

FindingsExpandButton.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  findingsCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  svgStyle: PropTypes.object
};
