import { numberToStr, strToNumber } from 'common/utils/numbers';
import _get from 'lodash/get';

const PricePreview = ({
  disabledMode,
  currency,
  value,
  className = '',
  label,
  icon,
  smallView = false
}) => {
  return (
    <div
      className={`ticket-price mode-${disabledMode ? 'disabled' : 'accepted'} ${
        smallView ? 'small-view' : ''
      } ${className} `}
    >
      {icon ? icon : null}
      <strong className="pe-1">{`${_get(currency, 'symbol', '')}`}</strong>
      <strong>{numberToStr(strToNumber(value), 2, 2)}</strong>
      {label ? label : null}
    </div>
  );
};

export default PricePreview;
