import * as d3 from 'd3';

const getStyleDefs = (plotIndex = 0) => [
  { type: 'attr', key: 'opacity', style: 'lineOpacity', defaultValue: 1 },
  { type: 'attr', key: 'stroke', style: 'lineColor', defaultValue: '#354069' },
  { type: 'attr', key: 'stroke-width', style: 'lineWidth', defaultValue: 1 },
  { type: 'attr', key: 'stroke-dasharray', style: 'lineDashArray', defaultValue: '0,0' },
  { type: 'attr', key: 'plot-index', style: 'plotIndex', defaultValue: plotIndex }
];

const renderPlot = ({ plotID, plot, plotIndex, getPlotStyle, xFn, yFn, element }) => {
  // The .defined below is a d3 built-in function that will seperate the <path /> element into multiples when it seed data missing (null yAxis values).
  const line = d3
    .line()
    .curve(plot.isBenchmarks ? d3['curveCardinal'] : d3['curveLinear'])
    .x(xFn)
    .y(yFn)
    .defined(d => !(d.y !== 0 && !d.y));

  let data = plot.data;

  if (getPlotStyle('lineConnectNullValues', false)) {
    data = plot.data.filter(datum => !(!datum.y && datum.y !== 0));
  }

  const existingElement = element.select(`.${plotID}`);

  const lineElement = (
    !existingElement.empty()
      ? existingElement
      : element.append('custom').classed(`path ${plotID} plot-${plotIndex}`, true)
  )
    .attr('d', line(data))
    .attr('plot-index', plotIndex);

  getStyleDefs(plotIndex).forEach(styleDefinition => {
    lineElement[styleDefinition.type](
      styleDefinition.key,
      getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
    );
  });
};

const line = {
  renderPlot
};

export default line;
