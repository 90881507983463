import ShadowBox from 'common/components/general/ShadowBox';
import PropTypes from 'prop-types';
import Information from 'common/components/general/Information';
import EmptyState from './components/EmptyState';

import { selectAccount } from 'store/account/selectors';
import { useSelector } from 'react-redux';
import AuthCheck from 'components/permissions/AuthCheck';

const VerticalTabView = ({
  config,
  onClick,
  selected,
  className,
  disabled,
  renderComponent,
  data,
  onEmptyButtonCLick,
  withShadow = false
}) => {
  const account = useSelector(selectAccount);

  const getComponent = item => {
    if (!item.component || !selected) return null;

    if (item.componentProps) {
      return <item.component data={data} {...item.componentProps} />;
    } else {
      return <item.component data={data} />;
    }
  };

  const showEmptyState = selected?.showEmpty ? selected.showEmpty(data) : false;

  return (
    <ShadowBox
      className={`vertical-tab-view ${withShadow ? 'with-shadow' : ''} ${
        className ? className : ''
      }`}
    >
      <div className="vertical-tab-view__container overflow-hidden p-2">
        <div className="vertical-tab-view__left-part">
          {config.map(el => {
            const shouldHide = el?.hide ? el.hide(data, { account }) : false;
            if (shouldHide) return null;

            return (
              <AuthCheck permissions={el.permissions} key={el.label}>
                <div
                  onClick={() => (disabled ? null : onClick(el))}
                  className={`vertical-tab-view__tab ${
                    selected.label.toLowerCase() === el.label.toLowerCase()
                      ? 'vertical-tab-view__tab--selected'
                      : ''
                  }`}
                >
                  {el.label}
                  {el?.info ? (
                    <Information
                      message={el.info}
                      svgStyle={{ height: 12, width: 12 }}
                      tooltipClassname={`max-width-412 ${el?.infoTooltipClassName}`}
                      tooltipOffset={el?.infoTooltipOffset}
                    />
                  ) : null}
                </div>
              </AuthCheck>
            );
          })}
        </div>
        <div
          className={`vertical-tab-view__right-part overflow-hidden vertical-tab-view__right-part--${selected.path}-tab`}
        >
          {showEmptyState && !renderComponent ? (
            <EmptyState {...selected.emptyProps} onEmptyButtonCLick={onEmptyButtonCLick} />
          ) : renderComponent ? (
            renderComponent(data)
          ) : selected ? (
            getComponent(selected)
          ) : null}
        </div>
      </div>
    </ShadowBox>
  );
};

VerticalTabView.propTypes = {
  config: PropTypes.array,
  onClick: PropTypes.func,
  selected: PropTypes.object,
  className: PropTypes.string,
  renderComponent: PropTypes.func,
  disabled: PropTypes.bool,
  withShadow: PropTypes.bool,
  onEmptyButtonCLick: PropTypes.func
};

export default VerticalTabView;
