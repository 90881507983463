import React, { useEffect } from 'react';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useActions } from 'utils/hooks';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import useValue from 'captain-reports/utils/useValue';
import { selectPreviousCaptainReportValueByKey } from 'captain-reports/store/selectors';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import * as reportActions from 'captain-reports/store/actions';

const LubricantTotalRob = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const previousRob = useSelector(state => selectPreviousCaptainReportValueByKey(state, field.key));
  const [setValue] = useActions([reportActions.setValue]);

  const isViewOnly = previousRob && previousRob > 0;
  const baseKey = field.key?.replace('_rob', '');

  const supplied = useValue({
    key: baseKey + '_supplied'
  });

  const consumption = useValue({
    key: baseKey + '_consumption_total'
  });

  const correction = useValue({
    key: baseKey + '_correction'
  });

  const replacedOil = useValue({
    key: baseKey + '_replaced'
  });

  const value = useValue({
    key: field.key
  });

  useEffect(() => {
    if (!previousRob) {
      return;
    }

    let result = value || null;

    const prev = numeral(strToNumber(previousRob || 0));

    if (!isViewOnly) {
      if (value === null) {
        result = prev?._value;
      }
    } else {
      result = prev
        .add(+supplied || 0)
        .add(+correction || 0)
        .subtract(+consumption || 0)
        .subtract(+replacedOil || 0)?._value;
    }

    setValue({
      key: field.key,
      value: result || null,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  }, [previousRob, supplied, correction, consumption, replacedOil]);

  return (
    <Row className="align-items-center fw-bold fs-12 lh-1 text-end height-24">
      <Col xs={12}>
        <div className={isViewOnly ? 'd-none' : ''}>
          <NumberField
            field={field}
            sectionLabel={sectionLabel}
            sectionActiveTab={sectionActiveTab}
            subGroup={subGroup}
            subGroupIndex={subGroupIndex}
          />
        </div>

        <div className={isViewOnly ? 'pe-1 cme-2' : 'd-none'}>
          <ValidationWrapper
            field={{ key: field.key, label: null }}
            value={value}
            errorClassName="align-items-center"
          >
            {numberToStr(value)}
          </ValidationWrapper>
        </div>
      </Col>
    </Row>
  );
};

export default LubricantTotalRob;
