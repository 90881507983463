import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectIsJobLocked,
  checkIfEditMode
} from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceDetaisVesselSystemAssignment,
  selectMaintenanceDetaisVesselSystemAssignmentVesselSystem,
  selectIsUnscheduledJob,
  selectMaintenanceDetailsVesselSystemAssignmentIsClass
} from 'common/components/pms/jobs/store/selectors';

import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';
import { components } from 'react-select';
import VesselSystemInfo from 'common/components/pms/jobs/components/VesselSystemInfo';
// import * as pmsSetupActions from 'common/components/pms/setup/store/actions';

const VesselSystemAssignment = () => {
  const [
    setMaintenanceField,
    updateMaintenance,
    initializeConsumedSpareParts,
    initializePmsSpareParts
    // getSpareParts
  ] = useActions([
    maintenanceActions.setMaintenanceField,
    maintenanceActions.updateMaintenance,
    maintenanceActions.initializeConsumedSpareParts,
    maintenanceActions.initializePmsSpareParts
    // pmsSetupActions.getSpareParts
  ]);

  const vesselSystemAssignment = useSelector(selectMaintenanceDetaisVesselSystemAssignment);

  const isJobLocked = useSelector(selectIsJobLocked);
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const isUnscheduled = useSelector(selectIsUnscheduledJob);
  const isEditMode = useSelector(checkIfEditMode);

  const vesselId = vessels ? vessels[0]?.id : null;

  const onSelectChange = async vesselSystemAssignment => {
    setMaintenanceField('vessel_system_assignment', vesselSystemAssignment);
    setMaintenanceField(
      'vessel_system_assignment_id',
      vesselSystemAssignment ? vesselSystemAssignment.id : null
    );

    updateMaintenance({
      vessel_system_assignment_id: vesselSystemAssignment ? vesselSystemAssignment.id : null,
      vessel_system_id: vesselSystemAssignment ? vesselSystemAssignment.vessel_system_id : null
    });

    if (!isEditMode && isUnscheduled && vesselSystemAssignment?.vessel_system_id) {
      try {
        // Uncomment to auto-fill the required spare parts
        // const response = await getSpareParts({
        //   system_id: vesselSystemAssignment?.vessel_system_id
        // });

        // const requiredSpareParts = (response || [])?.map(spare_part => ({
        //   quantity: null,
        //   spare_part,
        //   spare_part_id: spare_part.id,
        //   isRequired: true
        // }));

        initializePmsSpareParts([]);
        initializeConsumedSpareParts([]);
      } catch (e) {
        console.error(e);
        initializePmsSpareParts([]);
        initializeConsumedSpareParts([]);
      }
    }
  };

  return (
    <Select
      className="pms-vessel-system"
      placeholder="Select System/Subsystem"
      getOptionValue={option => `${option?.id}-${option?.vessel_system_id}`}
      value={vesselSystemAssignment}
      error={vesselSystemAssignment ? false : 'Required'}
      isAsync
      getOptionLabel={option => option?.description}
      disabled={isJobLocked || !isUnscheduled || !vesselId}
      loadOptions={search =>
        getAsyncOptions(search, 'vessel-system-assignments', { vessel_id: vesselId })
      }
      defaultOptions={() =>
        getInitialAsyncValues('vessel-system-assignments', null, false, {
          vessel_id: vesselId
        })
      }
      defaultOptionsTriggerChange={vesselId}
      onChange={onSelectChange}
      isClearable={false}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for systems` : `No system found`
      }
      components={{ SingleValue }}
      invisible
    />
  );
};

export const SingleValue = ({ children, ...props }) => {
  const vesselSystemAssignmentVesselSystem = useSelector(
    selectMaintenanceDetaisVesselSystemAssignmentVesselSystem
  );

  const isClass = useSelector(selectMaintenanceDetailsVesselSystemAssignmentIsClass);

  return (
    <components.SingleValue {...props}>
      <VesselSystemInfo
        key={`vessel-system-${props.data.id}`}
        className="text-violet"
        vesselSystem={{
          description: props.data?.description
        }}
        attributes={{
          is_class: isClass,
          is_critical: vesselSystemAssignmentVesselSystem?.is_critical,
          is_environmental_critical: vesselSystemAssignmentVesselSystem?.is_environmental_critical,
          is_navigational: vesselSystemAssignmentVesselSystem?.is_navigational
        }}
        showGroup={false}
        showSystemIcon={false}
      />
    </components.SingleValue>
  );
};

export default VesselSystemAssignment;
