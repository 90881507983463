import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import ProfileDataBox from 'common/components/layouts/profile-data-box';
import FormFieldLabel from 'common/components/layouts/vertical-tab-view/components/FormFieldLabel';

import _get from 'lodash/get';

const MainInfo = ({ config, data }) => {
  return (
    <Fade>
      <ProfileDataBox profile={data} sections={config} />

      <Row className="mt-1">
        <Col xs={4} className="text-end fs-12">
          <strong>EMAILS</strong>
        </Col>
      </Row>

      <Row className="fs-12">
        <Col xs={4} className="text-end">
          <FormFieldLabel>PRIMARY EMAIL</FormFieldLabel>
        </Col>
        <Col xs={8}>{_get(data, 'email') || '-'}</Col>
      </Row>

      {(_get(data, 'emails') || []).map(e => (
        <Row className="fs-12" key={e.id}>
          <Col xs={4} className="text-end text-uppercase">
            <FormFieldLabel>{e.type}</FormFieldLabel>
          </Col>
          <Col xs={8}>{e.value}</Col>
        </Row>
      ))}

      <Row>
        <Col xs={4} className="text-end fs-12 mt-3">
          <strong>CONTACTS</strong>
        </Col>
      </Row>

      <Row className="fs-12">
        <Col xs={4} className="text-end">
          <FormFieldLabel>PRIMARY PHONE</FormFieldLabel>
        </Col>
        <Col xs={8}>{_get(data, 'phone') || '-'}</Col>
      </Row>

      {(_get(data, 'contact_info') || []).map(e => (
        <Row className="fs-12" key={e.id}>
          <Col xs={4} className="text-end text-uppercase">
            <FormFieldLabel>{e.type}</FormFieldLabel>
          </Col>
          <Col xs={8}>{e.value}</Col>
        </Row>
      ))}
    </Fade>
  );
};

export default MainInfo;
