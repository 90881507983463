import TYPES from './types';

import { get, post, put } from 'utils/api';
import { fetchListOptions } from 'store/lists/actions';
import {
  setShiftTypes,
  setShifts,
  setShift,
  setIsLoading,
  setSelectedContract,
  setShiftsPortSeaState,
  setShiftWarnings
} from 'crew/store/shifts/actions';
import { convertToFilters } from 'crew/utils/filters';
import { successHandler } from 'common/utils/notifications';
import _pickBy from 'lodash/pickBy';
import _uniqBy from 'lodash/uniqBy';
import _groupBy from 'lodash/groupBy';

export const getShiftTypes = () => async dispatch => {
  const data = await dispatch(fetchListOptions('shift-types'));
  dispatch(setShiftTypes(data));
};

export const getShifts =
  ({ vesselId, sorting, ...rest }) =>
  dispatch => {
    dispatch({ type: TYPES.GET_SHIFTS.START, payload: vesselId });
    dispatch(setIsLoading(true));
    dispatch(setShifts([]));

    return get(`/crew-shifts`, { filters: convertToFilters(rest), sorting })
      .then(response => {
        dispatch({ type: TYPES.GET_SHIFTS.SUCCESS, payload: response.data });
        dispatch(setShifts(response.data));
        dispatch(setIsLoading(false));

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_SHIFTS.ERROR });
        dispatch(setIsLoading(false));

        throw error;
      });
  };

export const getCrewShift =
  ({ crewId, ...rest }) =>
  dispatch => {
    dispatch({ type: TYPES.GET_SHIFT.START, payload: crewId });
    dispatch(setIsLoading(true));
    dispatch(setShifts([]));

    return get(`/crew-shifts/${crewId}`, { filters: convertToFilters(rest) })
      .then(response => {
        dispatch({ type: TYPES.GET_SHIFT.SUCCESS, payload: response.data });
        dispatch(setShifts([response.data]));
        dispatch(setSelectedContract(response.data.contract || null));
        dispatch(setIsLoading(false));

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.GET_SHIFT.ERROR });
        dispatch(setIsLoading(false));

        throw error;
      });
  };

export const updateShift =
  ({ vesselId, crewId, ...rest }) =>
  dispatch => {
    dispatch({ type: TYPES.UPDATE_SHIFT.START, payload: vesselId });
    dispatch(setIsLoading(true));

    return put(`/crew-shifts/${crewId}`, rest)
      .then(response => {
        dispatch({ type: TYPES.UPDATE_SHIFT.SUCCESS, payload: response.data });
        dispatch(successHandler({ title: 'Success!', message: 'Shifts saved successfully!' }));

        const updatedShifts = {
          ...response.data,
          dates: _pickBy(
            response.data?.dates || {},
            (_, date) => rest.dates.find(d => d.date === date) !== undefined
          )
        };
        dispatch(setShift(updatedShifts));
        dispatch(setIsLoading(false));

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.UPDATE_SHIFT.ERROR });
        dispatch(setIsLoading(false));

        throw error;
      });
  };

export const getCrewSchedule = crewId => dispatch => {
  dispatch({ type: TYPES.GET_SCHEDULE.START, payload: crewId });
  dispatch(setIsLoading(true));

  return get(`/crew-working-schedule/crew/${crewId}`)
    .then(response => {
      dispatch({ type: TYPES.GET_SCHEDULE.SUCCESS, payload: response.data });
      dispatch(setIsLoading(false));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_SCHEDULE.ERROR });
      dispatch(setIsLoading(false));

      throw error;
    });
};

export const updateShiftsPortSeaState =
  ({ id, shiftsPortSeaState, ...rest }) =>
  dispatch => {
    dispatch({ type: TYPES.UPDATE_SHIFTS_PORT_SEA_STATE.START, payload: shiftsPortSeaState });
    dispatch(setIsLoading(true));

    return put(`/crew-shift-vessel-dates/${id}`, { type: shiftsPortSeaState, ...rest })
      .then(response => {
        dispatch({ type: TYPES.UPDATE_SHIFTS_PORT_SEA_STATE.SUCCESS, payload: response.data });
        dispatch(setShiftsPortSeaState(shiftsPortSeaState));
        dispatch(setIsLoading(false));

        return response.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.UPDATE_SHIFTS_STATE.ERROR });
        dispatch(setIsLoading(false));

        throw error;
      });
  };

export const initializeShiftsPortSeaState = (shiftsPortSeaState, date) => dispatch => {
  dispatch({ type: TYPES.INITIALIZE_SHIFTS_PORT_SEA_STATE.START, payload: shiftsPortSeaState });
  dispatch(setIsLoading(true));

  return post('/crew-shift-vessel-dates', { type: shiftsPortSeaState, date })
    .then(response => {
      dispatch({ type: TYPES.INITIALIZE_SHIFTS_PORT_SEA_STATE.SUCCESS, payload: response.data });
      dispatch(setShiftsPortSeaState(shiftsPortSeaState));
      dispatch(setIsLoading(false));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.INITIALIZE_SHIFTS_STATE.ERROR });
      dispatch(setIsLoading(false));

      throw error;
    });
};

export const getInitialShiftsPortSeaStateInfo = date => dispatch => {
  dispatch({ type: TYPES.GET_SHIFTS_PORT_SEA_STATE_INFO.START, payload: date });
  dispatch(setIsLoading(true));

  return get(`/crew-shift-vessel-dates`, { date })
    .then(response => {
      dispatch({ type: TYPES.GET_SHIFTS_PORT_SEA_STATE_INFO.SUCCESS, payload: response.data });
      dispatch(setIsLoading(false));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_SHIFTS_PORT_SEA_STATE_INFO.ERROR });
      dispatch(setIsLoading(false));

      throw error;
    });
};

export const loadWorkingScheduleOnEmptyDates = params => dispatch => {
  dispatch({ type: TYPES.LOAD_WORKING_SCHEDULE_ON_EMPTY_DATES.START, payload: params });
  dispatch(setIsLoading(true));

  const { sorting, ...rest } = params;

  return post(`/scheduled-shifts`, { filters: convertToFilters(rest), sorting })
    .then(response => {
      dispatch({
        type: TYPES.LOAD_WORKING_SCHEDULE_ON_EMPTY_DATES.SUCCESS,
        payload: response.data
      });
      dispatch(setIsLoading(false));

      const results = _groupBy(
        Object.keys(response?.data || {}).reduce(
          (acc, date) => [...acc, ...response?.data[date]],
          []
        ),
        'result'
      );

      const generatedResults = results?.generated || [];
      const skippedResults = results?.skipped || [];

      const loaded = _uniqBy(generatedResults, 'crew_member.id')?.length;
      const skipped = _uniqBy(
        skippedResults.filter(
          s => !generatedResults.find(r => r.crew_member?.id === s.crew_member?.id)
        ),
        'crew_member.id'
      )?.length;

      dispatch(
        successHandler({
          title: 'Success!',
          message: (
            <span>
              Schedule hours were loaded for <strong>{loaded}</strong> seamen (skipped:{' '}
              <strong>{skipped}</strong>)
            </span>
          )
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.LOAD_WORKING_SCHEDULE_ON_EMPTY_DATES.ERROR });
      dispatch(setIsLoading(false));

      throw error;
    });
};

export const validateShifts = params => dispatch => {
  dispatch({ type: TYPES.VALIDATE_SHIFTS.START, payload: params });

  return post(`/crew/${params.crewId}/pending-shift-warnings`, params)
    .then(response => {
      dispatch({ type: TYPES.VALIDATE_SHIFTS.SUCCESS, payload: response.data });

      dispatch(setShiftWarnings(params.crewId, response?.data?.dates || {}));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.VALIDATE_SHIFTS.ERROR });

      // throw error;
    });
};
