import React, { useMemo, useState } from 'react';

import StyledSelect from 'common/components/form/styled-inputs/Select';
import CircledButton from 'common/components/buttons/CircledButton';
import ShadowBox from 'common/components/general/ShadowBox';

import lineRibbon from 'common/assets/svg/common/ribbon-line.svg';
import burger from 'common/assets/svg/common/burger.svg';
import SvgRender from 'common/components/general/SvgRender';

import { Row, Col } from 'reactstrap';
import { getInitialAsyncValues, getAsyncOptions } from 'utils/helpers';
import { components } from 'react-select';
import _get from 'lodash/get';
import Priority from 'common/components/general/Priority';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { useSelector } from 'react-redux';
import { isAuthorized } from 'utils/permissions/authorize';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';

const AddNewJobLink = ({
  onChange,
  onCancel,
  linkedJobsState,
  jobHasLinkedJobs,
  value = null,
  listParams = {},
  isMulti = false
}) => {
  const [multiAccounts, setMultiAccounts] = useState([]);

  const account = useSelector(selectAccount);
  const canCreate = isAuthorized(account, [permissions.office.jobs.create]);

  const requestParams = useMemo(() => {
    return {
      paging: { per_page: 100, current_page: 1 },
      ...listParams
    };
  }, [listParams]);

  useFilterSelector(
    value,
    { list: 'maintenance-jobs', listParams: requestParams },
    { selected: multiAccounts, setSelected: setMultiAccounts }
  );

  const handleChange = selected => {
    if (value !== undefined) {
      onChange(selected);

      setMultiAccounts(isMulti ? selected : [selected]);
    } else {
      onChange(selected);
    }
  };

  const getValue = () => {
    if (value !== undefined) {
      return value ? multiAccounts?.filter(el => value.map(Number).includes(el?.id)) : [];
    } else {
      return value;
    }
  };

  const modifiedOptions = async () => {
    const listOptions = await getInitialAsyncValues('maintenance-jobs', null, null, {
      paging: { per_page: 100, current_page: 1 }
    });

    const listWithoutSelected = (listOptions?.data || listOptions).filter(
      option => linkedJobsState?.findIndex(linked => linked?.job?.id === option.id) === -1
    );

    const modifiedListOptions = canCreate
      ? [{ id: 'create', title: 'create' }, ...listWithoutSelected]
      : listWithoutSelected;

    return modifiedListOptions;
  };

  const loadModifiedOptions = async search => {
    const listOptions = await getAsyncOptions(search, 'maintenance-jobs', requestParams);
    const listWithoutSelected = listOptions?.filter(
      option => linkedJobsState?.findIndex(linked => linked?.job?.id === option.id) === -1
    );

    const modifiedListOptions = canCreate
      ? [{ id: 'create', title: 'create' }, ...listWithoutSelected]
      : listWithoutSelected;

    return modifiedListOptions;
  };

  return (
    <ShadowBox flat className="ps-3 pt-2 pb-1 mb-1">
      <Row className="align-items-center" noGutters>
        <Col xs={11}>
          <div className="text-primary fs-10 lh-1 cmb-6">JOB TYPE</div>
          <StyledSelect
            isAsync
            loadOptions={loadModifiedOptions}
            defaultOptions={modifiedOptions}
            onChange={handleChange}
            value={getValue()}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            className={`flex-1 mb-0 fs-12 w-100p`}
            placeholder="Select job"
            components={{ Option }}
            isMulti={isMulti}
          />
        </Col>
        {!jobHasLinkedJobs ? (
          <Col xs="auto" className="ms-auto mb-1 me-n1">
            <CircledButton type="close" onClick={onCancel} />
          </Col>
        ) : null}
      </Row>
    </ShadowBox>
  );
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {props.data ? (
      <Row className="d-flex align-items-center pe-0" noGutters>
        <Col xs="auto" className="d-flex justify-content-center align-items-center">
          <Priority value={_get(props, 'data.priority_id', '')} />
        </Col>
        <Col
          xs={11}
          className="ps-1 text-primary fs-14 fw-light text-nowrap overflow-x-hidden text-truncate"
        >
          {props?.data?.title}
        </Col>
      </Row>
    ) : null}
  </components.SingleValue>
);

export const Option = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className="d-flex-column align-items-center" noGutters>
        <Row className="d-flex align-items-center cpt-2 cpb-2" noGutters>
          {data.id !== 'create' ? (
            <>
              <Col
                xs={'auto'}
                className="d-flex align-items-center justify-content-center width-24"
              >
                <Priority size="small" value={data?.importance_id} />
              </Col>

              <Col xs={'11'} className="fs-14 text-nowrap overflow-x-hidden text-truncate ps-1">
                {data?.title}
              </Col>
            </>
          ) : (
            <div className="d-flex align-items-center w-100p ps-1 cpy-6 mt-0">
              <CircledButton
                type="add"
                style={{ width: 19, height: 19 }}
                svgStyle={{ width: 7, height: 7 }}
                label={'Create new job'}
                labelClassName={'cms-4'}
              />
            </div>
          )}
        </Row>

        <Row className="d-flex align-items-center" noGutters>
          {data.id !== 'create' && data?.assignment_description ? (
            <>
              <Col
                xs={'auto'}
                className="d-flex align-items-center justify-content-center width-24"
              ></Col>

              <Col
                xs={'11'}
                className="fs-12 text-nowrap overflow-x-hidden text-truncate ps-1 text-violet cpt-2 d-flex align-items-center"
              >
                <SvgRender
                  className="me-1 fw-bold lh-1"
                  src={data?.assignment_parent_id ? lineRibbon : burger}
                  style={{ width: 12, height: 12 }}
                />
                <div>{data?.assignment_description}</div>
              </Col>
            </>
          ) : null}
        </Row>
      </div>
    </components.Option>
  );
};
export default AddNewJobLink;
