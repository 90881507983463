import { getFileBaseUrl } from '@/api/files/api';
import { Attachment, Size } from '@/ts-common/types/files';

import FilePreview from './FilePreview';
import { FC, useCallback } from 'react';

type UploadedFilesProps = {
  files: Attachment[];
  size: Size;
  viewOnly?: boolean;
  imageSizeLabel?: string;
  singleUpload?: boolean;
  initGalleryFiles?: boolean;
  onRemoveFile: (file: Attachment) => void;
  setGalleryFiles: (files: Attachment[]) => void;
  toggleGallery: (isOpen: boolean) => void;
  setGalleryCurrentFile: (file: Attachment) => void;
};

const UploadedFiles: FC<UploadedFilesProps> = ({
  files,
  size,
  viewOnly,
  imageSizeLabel,
  singleUpload,
  onRemoveFile,
  initGalleryFiles,
  setGalleryFiles,
  toggleGallery,
  setGalleryCurrentFile
}) => {
  const previewImageBaseUrl = getFileBaseUrl();

  const onFileClick = useCallback(
    (file: Attachment) => {
      if (initGalleryFiles) setGalleryFiles(files);

      setGalleryCurrentFile(file);
      toggleGallery(true);
    },
    [files, initGalleryFiles, setGalleryCurrentFile, setGalleryFiles, toggleGallery]
  );

  return files.map((file, index) => (
    <FilePreview
      key={file.id}
      file={file}
      size={size}
      previewImageBaseUrl={previewImageBaseUrl}
      viewOnly={viewOnly}
      onFileClick={onFileClick}
      onFileRemove={onRemoveFile}
      imageSizeLabel={imageSizeLabel}
      singleUpload={singleUpload}
      index={index}
    />
  ));
};

export default UploadedFiles;
