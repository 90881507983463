import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import _isArray from 'lodash/isArray';
import _find from 'lodash/find';
import _get from 'lodash/get';

import * as d3 from 'd3';
import moment from 'moment';
import { numberToStr } from 'common/utils/numbers';
import { defaultGraphColors } from '../helpers/_defaultOptions';
import { hexToRGB } from 'common/components/graph/helpers/colors';

export const TRANSLATE_PERCENTAGE_FOR_BIG_TOOLTIPS = 60;

export const getPlotTitle = plot =>
  plot ? plot.legendTitle || plot.label || plot.suffix || '-' : '-';

export const getYAxisKeyFromPlot = plot => {
  const axisKey = _get(plot, 'axisKey', null);
  const suffix = _get(plot, 'suffix', null);

  return axisKey || suffix;
};

export const getYAxisIndexFromPlot = (yAxes, plot) => {
  const index = yAxes?.findIndex(e => e.yAxisKey === getYAxisKeyFromPlot(plot));

  if (index === -1) {
    return 0;
  } else {
    return index;
  }
};

export const combineDataFromAllPlots = (plots, yAxes) => {
  let newData = [];

  plots.forEach((plot, i) => {
    const axisIndex = getYAxisIndexFromPlot(yAxes, plot);

    plot.data.forEach(d => {
      d['plotIndex'] = i;
      d['plot'] = plot;
      d['axisIndex'] = axisIndex;

      newData.push(d);
    });
  });

  return newData;
};

export const mapDataFromPlotToAxis = (graphUID, schema, allData) => (plot, plotIndex) => {
  const isTimeseries = _get(schema, 'isTimeseries');
  const dateFormat = _get(schema, 'dateFormat');

  // Try to get the xAxis from inside the plot.
  // If it doesn't find it, it gets it from the schema.
  const xAxis = _get(plot, 'xAxis') || _get(schema, 'xAxis');

  // Try to get the data from inside the plot
  // If it doesn't find them there, it gets them from a seperate prop.
  const data = _get(plot, 'data') || allData;

  const { dataKey, ...rest } = plot;

  const generateDataPointID = dataPointIndex => `data-${plotIndex}-${dataPointIndex}-${graphUID}`;

  const getDataPointData = (dataPoint, dataKey) => {
    if (_get(plot, 'type') === 'arrow') {
      if (!dataPoint[dataKey] && dataPoint[dataKey] !== 0) {
        return { y: null };
      }
      return { y: 0, angle: dataPoint[dataKey] };
    } else {
      return { y: dataPoint[dataKey] };
    }
  };

  return {
    ...rest,
    style: {
      ...generateColorStylings(defaultGraphColors[plotIndex]),
      ...rest.style
    },
    title: getPlotTitle(plot),
    data: data.length
      ? data.map((dataPoint, i) => ({
          ...dataPoint,
          ...(!(dataPoint[xAxis] !== 0 && !dataPoint[xAxis])
            ? {
                // Data for a timeseries graph have to be formatted (if it is a timeseries graph).
                // Specifically, dates that correspond to the x axis have to be parsed as dates (and not as strings per say).
                x:
                  isTimeseries && !(dataPoint[xAxis] instanceof Date)
                    ? d3.timeParse(dateFormat)(dataPoint[xAxis])
                    : dataPoint[xAxis]
              }
            : {}),
          ...(!(dataPoint[xAxis] !== 0 && !dataPoint[xAxis])
            ? getDataPointData(dataPoint, dataKey)
            : {}),
          // this checks the x axis on purpose, just in case the y axis is null (we still want to copy the variable over even if it is null)
          index: i,
          id: generateDataPointID(i)
        }))
      : []
  };
};

export const formatDataForTooltip = (plots, yAxes) => {
  const combinedData = _orderBy(
    combineDataFromAllPlots(plots, yAxes),
    ['plot.tooltipIndex', 'plot.label', 'y'],
    'desc'
  );

  return _groupBy(
    combinedData.filter(dataPoint => !(dataPoint.y !== 0 && !dataPoint.y)),
    'x'
  );
};

const getDateFormatForGraphPeriodInDays = days => {
  // Less than a week:
  if (days <= 3) return '%b %d %H:%M';
  // Less than 2 months:
  if (days <= 31 * 2) return '%b %d';
  // More than 2 months:
  return '%Y %b';
};

export const getDateFormatForGraphPeriodFromData = data => {
  let min = Infinity;
  let max = -Infinity;

  data.forEach(datum => {
    if (min > datum.x) {
      min = datum.x;
    }
    if (max < datum.x) {
      max = datum.x;
    }
  });

  const diffInDays = moment(max).diff(moment(min), 'days');
  return getDateFormatForGraphPeriodInDays(diffInDays);
};

export const getDateFormatForGraphPeriodFromXAxis = (axis, width) => {
  let min = axis.invert(0);
  let max = axis.invert(width);

  const diffInDays = moment(max).diff(moment(min), 'days');
  return getDateFormatForGraphPeriodInDays(diffInDays);
};

export const generateColorStylings = color => {
  const scatterRGB = hexToRGB(color, 0.2);
  const scatterBorderRGB = hexToRGB(color, 0.4);

  return {
    areaBackgroundColor: color,
    lineColor: color,
    polygonBackgroundColor: color,
    scatterBackgroundColor: scatterRGB,
    scatterBorderColor: scatterBorderRGB,
    timedashColor: color
  };
};

const getColorPropertyByType = type => {
  switch (type) {
    case 'area':
      return 'areaBackgroundColor';
    case 'line':
      return 'lineColor';
    case 'polygon':
      return 'polygonBackgroundColor';
    case 'scatter':
      return 'scatterBackgroundColor';
    case 'timedash':
      return 'timedashColor';
    case 'timemarker':
      return 'timeMarkerBackgroundColor';
    default:
      return 'lineColor';
  }
};

export const findColorByType = plot => {
  let color = null;

  const { style, type } = plot;

  if (_isArray(type)) {
    _find(type, e => {
      const cl = _get(style, getColorPropertyByType(e));

      if (cl) {
        color = cl;

        return true;
      }
    });
  } else {
    color = _get(style, getColorPropertyByType(type));
  }

  return color;
};

export const isEvenOrOdd = n => {
  if (n % 2 === 0) {
    return 'even';
  }
  return 'odd';
};

export const trimBigValue = (text, width = '') => {
  if (width === '') {
    if (text?.length >= 9) {
      return text.substring(0, 6) + '...';
    } else {
      return text;
    }
  } else {
    if (width <= 6) {
      return '';
    } else {
      return text?.substring(0, Math.floor(width / 9));
    }
  }
};

// We map the data in this function for a very specific reason.
// This gets run per graph, so when it comes to the event listeners below, where you collect data from multiple graphs,
// you have the correct data mapped beforehand.
export const filterAndMapData = (plot, plotID) =>
  plot.data
    .filter(dataPoint => !(dataPoint.y !== 0 && !dataPoint.y))
    .map(dataPoint => {
      dataPoint['plot'] = plot;
      dataPoint['plotID'] = plotID;

      return dataPoint;
    });

const getHumanReadableNumber = number => {
  const getAbsoluteHumanReadableNumber = () => {
    const absNum = Math.abs(number);
    if (absNum < 1000) return numberToStr(absNum);
    if (absNum < 1000000) return `${absNum / 1000}K`;
    if (absNum < 1000000000) return `${absNum / 1000000}M`;
    return `${absNum / 1000000000}B`;
  };

  const isPositive = number >= 0;
  const absPretified = getAbsoluteHumanReadableNumber();

  return isPositive ? absPretified : `-${absPretified}`;
};

export const getTickValue = value => {
  if (!isNaN(+value)) {
    return getHumanReadableNumber(+value);
  }

  return value;
};

export const getTooltipYPosition = (yScale, { y, name, axesConfig }, height, graphHeight) => {
  if (!yScale) return null;
  const isHorizontal = axesConfig?.y?.scaleType === 'scaleBand';

  if (isHorizontal) {
    return yScale(name) + yScale.bandwidth() / 2;
  }

  if (!height || !graphHeight || graphHeight > height || isHorizontal) {
    return yScale(y);
  }

  // We get the middle of the y axis in pixels
  const middleY = (yScale.range()[0] + yScale.range()[1]) / 2;

  // then we move it downwards based on the tooltip height
  return middleY + (middleY * TRANSLATE_PERCENTAGE_FOR_BIG_TOOLTIPS) / 100;
};

const isBarChart = hoveredItem => {
  const plotTypes = hoveredItem?.plot?.type;
  const types = _isArray(plotTypes) ? plotTypes : [plotTypes];

  if (!plotTypes || !types.includes('bar')) return false;

  return true;
};

export const getBarChartClassNames = hoveredItem => {
  if (!isBarChart(hoveredItem)) return '';

  return 'ps-0 pb-1';
};

export const getTooltipXPosition = ({ xScale, x, y, hoveredItem, tooltipWidth, axesConfig }) => {
  if (!xScale) return null;

  if (!isBarChart(hoveredItem) || !tooltipWidth) return xScale(x);

  const isHorizontal = axesConfig?.y?.scaleType === 'scaleBand';

  const bandStartingPosition = isHorizontal ? xScale(y) : xScale(x);
  const bandWidth = isHorizontal ? 0 : xScale.bandwidth();

  const bandsHalfWidth = bandWidth / 2;

  return bandStartingPosition + bandsHalfWidth - tooltipWidth / 2;
};

export const isPlotTypesTooltipAllowed = dataPoint => {
  const plotType = dataPoint?.plot?.type;

  if (!plotType) return true;

  const types = _isArray(plotType) ? plotType : [plotType];

  if (types.includes('bar')) return false;

  return true;
};
