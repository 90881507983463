import { FC, PropsWithChildren } from 'react';
import { DrawerBody } from '@/ts-common/components/drawer/Drawer';

type FormDrawerProps = PropsWithChildren<{
  className?: string;
  innerClassName?: string;
}>;

const FormDrawer: FC<FormDrawerProps> = ({ children, className = '', innerClassName = '' }) => {
  return (
    <DrawerBody className={`form-drawer--body position-relative ps-0 pe-0 ${className}`}>
      <div className={`form-drawer--body-inner ${innerClassName}`}>{children}</div>
    </DrawerBody>
  );
};

export default FormDrawer;
