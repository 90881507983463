import Breadcrumbs from 'common/components/tree-layout/components/Breadcrumbs';
import NavigationArrows from 'common/components/general/NavigationArrows';
import useRouter from 'use-react-router';

import {
  selectActiveFolderBreadcrumbs,
  selectActiveFolderId,
  selectPreviousFolder,
  selectNextFolder
} from 'common/components/filesystem/store/selectors';
import { useSelector } from 'react-redux';
import paths from 'routing/routes/_paths';

const Header = () => {
  const breadcrumbs = useSelector(selectActiveFolderBreadcrumbs);
  const activeFolderId = useSelector(selectActiveFolderId);
  const previousFolder = useSelector(selectPreviousFolder);
  const nextFolder = useSelector(selectNextFolder);

  const { history } = useRouter();

  return (
    <div className="filesystem-content__header">
      <div className="d-flex align-items-center justify-content-between w-100p">
        <Breadcrumbs
          className="mb-3"
          breadcrumbs={breadcrumbs}
          activeID={activeFolderId}
          onClick={folder => history.replace(`${paths.filesystem}/folder/${folder?.id}`)}
          maxLimit={3}
        />

        <NavigationArrows
          disabledNext={!nextFolder || !activeFolderId}
          disabledPrevious={!previousFolder || !activeFolderId}
          onNextClick={() => history.replace(`${paths.filesystem}/folder/${nextFolder?.id}`)}
          onPreviousClick={() =>
            history.replace(`${paths.filesystem}/folder/${previousFolder?.id}`)
          }
        />
      </div>
    </div>
  );
};

export default Header;
