import TYPES from './types';
import { get } from 'utils/api';

export const getAccount = () => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNT.START });

  return get('/user')
    .then(res => {
      dispatch({ type: TYPES.GET_ACCOUNT.SUCCESS, payload: res.data });
      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_ACCOUNT.ERROR, payload: error }));
};

export const getAccountPermissions = () => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.START });

  return get('/account/permissions')
    .then(res => {
      dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.SUCCESS, payload: res.data });

      return res.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_ACCOUNT_PERMISSIONS.ERROR, payload: error }));
};
