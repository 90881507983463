import { ReportGroup } from '@/common/types/captain-reports.ts';
import captainReportsIcon from '@/common/assets/svg/common/captain_reports.svg';
import TopFilters from './TopFilters';

const getColumns = () => [
  {
    header: 'Type',
    key: 'type',
    sort: true,
    minWidth: 295,
    maxWidth: 295
  },
  {
    header: 'Status',
    key: 'status',
    type: 'collection',
    sort: true
  },
  {
    header: 'Local Date & time',
    key: 'local_timestamp',
    type: 'datetime',
    sort: true,
    showUtc: true,
    className: 'onboard-date-field'
  },

  {
    header: 'UTC Date & time',
    key: 'timestamp',
    type: 'datetime',
    sort: true,
    showUtc: true,
    className: 'onboard-date-field'
  },
  {
    header: 'Date Created',
    key: 'created_at',
    type: 'datetime',
    sort: true
  },
  {
    header: 'Report Sent',
    key: 'sent_at',
    type: 'datetime',
    sort: true
  },
  {
    header: 'Next Port',
    key: 'next_port'
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const getTopFilters = (options?: ReportGroup[]) => [
  {
    name: 'status',
    operation: 'oneOf',
    value: null
  },
  {
    name: 'report_group_id',
    operation: '=',
    value: options?.find(el => el.is_default)?.id.toString() || null
  }
];

const config = {
  getColumns,
  getTopFilters,
  topFiltersComponent: TopFilters,
  tableStyle: 'default',
  icon: captainReportsIcon,
  hideTableFilters: true,
  hideTableReorder: false,
  isModular: false,
  name: 'Captain Reports',
  pageTitleComponent: null,
  entity: 'captain-reports'
};

export default config;
