import React, { CSSProperties, FC } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import pdfNormal from 'common/assets/svg/files/pdf-normal.svg';
import pdfWhite from 'common/assets/svg/files/pdf-white.svg';
import pdfIcon from 'common/assets/svg/files/pdf.svg';

type ExportPdfProps = {
  white?: boolean;
  onClick?: () => void;
  className?: string;
  textClassname?: string;
  svgStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  disabled?: boolean;
  type?: 'default' | 'pdfSquare';
  showLabel?: boolean;
  label?: string;
  customLabel?: React.ReactNode;
  tooltipMessage?: string;
};

const ExportPdf: FC<ExportPdfProps> = ({
  white,
  onClick,
  className,
  textClassname,
  svgStyle,
  containerStyle,
  disabled,
  type = 'default',
  showLabel,
  label = 'Export pdf',
  customLabel,
  tooltipMessage
}) => {
  const { tooltipID, avoidRender } = useTooltipID(`download-pdf-file`);

  const getIcon = () => {
    switch (type) {
      case 'pdfSquare':
        return {
          icon: pdfIcon,
          container:
            'pdf-square-svg-container d-inline-flex align-items-center justify-content-center bg-white h-24 w-2 p-04',
          span: 'fw-normal fs-12 text-violet'
        };

      default:
        return {
          icon: white ? pdfWhite : pdfNormal,
          container: 'd-flex align-items-center',
          span: `fs-10 ${white ? 'text-white' : 'text-primary'} fw-bold`
        };
    }
  };

  return (
    <>
      <div
        id={tooltipID}
        onClick={!disabled ? onClick : undefined}
        className={`base-transition cursor-pointer d-inline-flex align-items-center${
          disabled ? ` opacity-4 ${tooltipMessage ? '' : 'cursor-pointer'}` : ''
        }${className ? ` ${className}` : ''}`}
      >
        <div className={`pointer-events-none ${getIcon().container}`} style={containerStyle}>
          <SvgRender src={getIcon().icon} style={svgStyle || { width: 14, height: 18 }} />
        </div>
        {showLabel ? (
          <span
            className={`cms-12 lh-12 text-nowrap ${getIcon().span} ${
              textClassname ? ` ${textClassname}` : ''
            }`}
          >
            {label}
          </span>
        ) : customLabel ? (
          customLabel
        ) : null}
      </div>
      {tooltipMessage && !avoidRender ? (
        <Tooltip target={tooltipID} innerClassName="text-start max-width-280">
          {tooltipMessage}
        </Tooltip>
      ) : null}
    </>
  );
};

export default ExportPdf;
