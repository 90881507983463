import TimeLogger from 'common/components/form/inputs/TimeLogger';
import {
  selectIsJobLocked,
  selectJobField,
  selectJobFieldError
} from 'common/components/jobs/_base/store/selectors';
import { useSelector } from 'react-redux';

import { setJobField, setJobFieldError } from 'common/components/jobs/_base/store/actions';
import { useDispatch } from 'react-redux';
import Information from 'common/components/general/Information';

const WorkDuration = ({ className = '' }) => {
  const dispatch = useDispatch();
  const timeSpent = useSelector(state => selectJobField(state, 'time_spent'));
  const fieldError = useSelector(state => selectJobFieldError(state, 'time_spent'));
  const isJobLocked = useSelector(selectIsJobLocked);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const onDateChange = m => {
    dispatch(setJobField('time_spent', m ? m : null, true, false, !isOnBoard));

    if (fieldError && m) {
      dispatch(setJobFieldError({ time_spent: null }));
    }
  };

  return (
    <div className={`${className}`}>
      <div className="d-flex align-items-center cmb-4 text-primary">
        <div className="fs-10 fw-bold">WORK DURATION</div>
        <Information
          className={'ms-1'}
          tooltipClassname="min-width-fit max-width-fit"
          svgClassname="text-primary"
          svgStyle={{ width: 12, height: 12 }}
          message="Time spent on this job."
        />
      </div>

      <TimeLogger
        value={timeSpent}
        onChange={minutes => onDateChange(minutes)}
        error={fieldError}
        disabled={isJobLocked}
      />
    </div>
  );
};

export default WorkDuration;
