import audits from 'common/assets/svg/events/modules/empty/audits.svg';
import crewChanges from 'common/assets/svg/events/modules/empty/crew-changes.svg';
import events from 'common/assets/svg/events/modules/empty/events.svg';
import forms from 'common/assets/svg/events/modules/empty/forms.svg';
import jobs from 'common/assets/svg/events/modules/empty/jobs.svg';
import participants from 'common/assets/svg/events/modules/empty/participants.svg';
import officeNotes from 'common/assets/svg/events/modules/empty/office-notes.svg';
import vesselNotes from 'common/assets/svg/events/modules/empty/vessel-notes.svg';
import vesselCondition from 'common/assets/svg/events/modules/empty/vessel-condition.svg';
import officeAttachments from 'common/assets/svg/events/modules/empty/office-attachments.svg';
import vesselAttachments from 'common/assets/svg/events/modules/empty/vessel-attachments.svg';
import checklist from 'common/assets/svg/events/modules/empty/checklist.svg';
import vetting from 'common/assets/svg/events/modules/empty/vetting.svg';
import emailConnection from 'common/assets/svg/events/modules/empty/email-connection.svg';
import accounts from 'common/assets/svg/events/modules/empty/accounts.svg';
import crewMedicalCases from 'common/assets/svg/events/modules/empty/crew-medical-cases.svg';
import underwaterServices from 'common/assets/svg/events/modules/empty/underwater-services.svg';
import agents from 'common/assets/svg/events/modules/empty/agents.svg';
import lubOilAnalysis from 'common/assets/svg/events/modules/empty/lub-oil-analysis.svg';
import linkedPurchaseCases from 'common/assets/svg/events/modules/empty/linked-purchase-cases.svg';
import offHire from '@/common/assets/svg/events/modules/empty/off-hire.svg';

import auditsLetter from 'common/assets/svg/events/modules/with-letter/audits.svg';
import crewChangesLetter from 'common/assets/svg/events/modules/with-letter/crew-changes.svg';
import eventsLetter from 'common/assets/svg/events/modules/with-letter/events.svg';
import formsLetter from 'common/assets/svg/events/modules/with-letter/forms.svg';
import jobsLetter from 'common/assets/svg/events/modules/with-letter/jobs.svg';
import participantsLetter from 'common/assets/svg/events/modules/with-letter/participants.svg';
import officeNotesLetter from 'common/assets/svg/events/modules/with-letter/office-notes.svg';
import vesselNotesLetter from 'common/assets/svg/events/modules/with-letter/vessel-notes.svg';
import vesselConditionLetter from 'common/assets/svg/events/modules/with-letter/vessel-condition.svg';
import officeAttachmentsLetter from 'common/assets/svg/events/modules/with-letter/office-attachments.svg';
import vesselAttachmentsLetter from 'common/assets/svg/events/modules/with-letter/vessel-attachments.svg';
import checklistLetter from 'common/assets/svg/events/modules/with-letter/checklist.svg';
import vettingLetter from 'common/assets/svg/events/modules/with-letter/vetting.svg';
import emailConnectionLetter from 'common/assets/svg/events/modules/with-letter/email-connection.svg';
import accountsWithLetter from 'common/assets/svg/events/modules/with-letter/accounts.svg';
import underwaterServicesWithLetter from 'common/assets/svg/events/modules/with-letter/underwater-services.svg';
import crewMedicalCasesWithLetter from 'common/assets/svg/events/modules//with-letter/crew-medical-cases.svg';
import agentsLetter from 'common/assets/svg/events/modules//with-letter/agents.svg';
import lubOilAnalysisWithLetter from 'common/assets/svg/events/modules/with-letter/lub-oil-analysis.svg';
import linkedPurchaseCasesWithLetter from 'common/assets/svg/events/modules/with-letter/linked-purchase-cases.svg';
import offHireWithLetter from '@/common/assets/svg/events/modules/with-letter/off-hire.svg';

//components
import Participants from './module-components/participants';
import Forms from './module-components/forms';
// import Contracts from './module-components/contracts';
import Jobs from './module-components/jobs';
import Events from './module-components/events';
import Audits from './module-components/audits';
import CrewChange from './module-components/crew-change';
import VesselCondition from './module-components/vessel-condition';
import OfficeAttachments from './module-components/office-attachments';
import Checklist from './module-components/checklist';
import Vetting from './module-components/vetting';
import UnderwaterServicesModule from './module-components/underwater-services';
import EmailConnection from './module-components/email-connection';
import Accounts from './module-components/accounts';
import CrewMedicalCase from './module-components/crew-medical-case';
import OfficeNotes from './module-components/office-notes';
import VesselNotes from './module-components/vessel-notes';
import Agents from './module-components/agents';
import LubOilAnalysis from './module-components/lub-oil-analysis';
import VesselAttachments from './module-components/vessel-attachments';
import LinkedPurchaseCases from './module-components/linked-purchase-cases';
import OffHire from './module-components/off-hire';

export const moduleIcons = {
  events: {
    icon: events,
    iconWithLetter: eventsLetter,
    component: Events,
    title: 'LINKED EVENTS',
    color: '#438CDB',
    onboard: true
  },
  jobs: {
    icon: jobs,
    iconWithLetter: jobsLetter,
    component: Jobs,
    title: 'LINKED JOBS',
    color: '#E28413',
    onboard: true
  },
  // contracts: {
  //   icon: contracts,
  //   iconWithLetter: contractsLetter,
  //   component: Contracts,
  //   title: 'CONTRACTS',
  //   color: '#FE5F55'
  // },
  participants: {
    icon: participants,
    iconWithLetter: participantsLetter,
    component: Participants,
    color: '#7084D3',
    title: 'PARTICIPANTS',
    onboard: true
  },
  forms: {
    icon: forms,
    iconWithLetter: formsLetter,
    component: Forms,
    title: 'FORMS',
    color: '#24C39C',
    onboard: true
  },
  office_notes: {
    icon: officeNotes,
    iconWithLetter: officeNotesLetter,
    component: OfficeNotes,
    title: 'OFFICE NOTES',
    color: '#354069',
    onboard: true
  },
  vessel_notes: {
    icon: vesselNotes,
    iconWithLetter: vesselNotesLetter,
    component: VesselNotes,
    title: 'VESSEL NOTES',
    color: '#354069',
    onboard: true
  },
  audits: {
    icon: audits,
    iconWithLetter: auditsLetter,
    component: Audits,
    title: 'AUDITS',
    color: '#354069',
    onboard: true
  },
  crew_changes: {
    icon: crewChanges,
    iconWithLetter: crewChangesLetter,
    component: CrewChange,
    title: 'CREW CHANGE',
    color: '#636E83',
    onboard: false
  },
  office_attachments: {
    icon: officeAttachments,
    iconWithLetter: officeAttachmentsLetter,
    component: OfficeAttachments,
    title: 'OFFICE ATTACHMENTS',
    color: '#354069',
    onboard: true
  },
  vessel_attachments: {
    icon: vesselAttachments,
    iconWithLetter: vesselAttachmentsLetter,
    component: VesselAttachments,
    title: 'VESSEL ATTACHMENTS',
    color: '#354069',
    onboard: true
  },
  vessel_condition: {
    icon: vesselCondition,
    iconWithLetter: vesselConditionLetter,
    component: VesselCondition,
    title: 'VESSEL CONDITION',
    color: '#354069',
    onboard: true
  },
  checklist: {
    icon: checklist,
    iconWithLetter: checklistLetter,
    component: Checklist,
    title: 'CHECKLIST',
    color: '#7866D8',
    onboard: true
  },
  vetting: {
    icon: vetting,
    iconWithLetter: vettingLetter,
    component: Vetting,
    title: 'VETTING',
    color: '#ff1e1e',
    onboard: true
  },
  email_connection: {
    icon: emailConnection,
    iconWithLetter: emailConnectionLetter,
    component: EmailConnection,
    title: 'EMAIL CONNECTION',
    color: '#232AA6',
    onboard: false
  },
  accounts: {
    icon: accounts,
    iconWithLetter: accountsWithLetter,
    component: Accounts,
    title: 'ACCOUNTS',
    color: '#cd1e3d',
    onboard: false
  },
  crew_medical_cases: {
    icon: crewMedicalCases,
    iconWithLetter: crewMedicalCasesWithLetter,
    component: CrewMedicalCase,
    title: 'CREW MEDICAL CASES',
    color: '#FF1E1E',
    onboard: true
  },
  underwater_services: {
    icon: underwaterServices,
    iconWithLetter: underwaterServicesWithLetter,
    component: UnderwaterServicesModule,
    title: 'UNDERWATER SERVICES',
    color: '#0076FF',
    onboard: true
  },
  agents: {
    icon: agents,
    iconWithLetter: agentsLetter,
    component: Agents,
    title: 'AGENTS',
    color: '#354069',
    onboard: false
  },
  lub_oil_analysis: {
    icon: lubOilAnalysis,
    iconWithLetter: lubOilAnalysisWithLetter,
    component: LubOilAnalysis,
    title: 'LUB OIL ANALYSIS',
    color: '#24C39C',
    onboard: true
  },
  purchasing_requisitions: {
    icon: linkedPurchaseCases,
    iconWithLetter: linkedPurchaseCasesWithLetter,
    component: LinkedPurchaseCases,
    title: 'LINKED PURCHASE CASES',
    color: '#FF1E1E',
    onboard: false
  },
  off_hire: {
    icon: offHire,
    iconWithLetter: offHireWithLetter,
    component: OffHire,
    title: 'OFF HIRE',
    color: '#24C39C',
    onboard: false
  }
};
