import { useState } from 'react';
import { Selectors } from 'common/entities/selectors/SelectorTypes';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const EventAuditsSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  autoFocus = true,
  styled,
  defaultOptionsTriggerChange,
  listParams,
  ...rest
}) => {
  const [selectedType, setSelectedType] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'event-audits' },
    { selected: selectedType, setSelected: setSelectedType }
  );

  return (
    <Select
      placeholder="Select type"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      size="sm"
      value={
        value !== undefined
          ? value
          : filter?.value
          ? selectedType.filter(el => filter?.value.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() =>
        getInitialAsyncValues('event-audits', null, null, {
          defaultOptionsTriggerChange,
          ...listParams
        })
      }
      loadOptions={search => getAsyncOptions(search, 'event-audits')}
      onChange={selected => {
        setSelectedType(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      defaultOptionsTriggerChange={defaultOptionsTriggerChange}
      {...rest}
    />
  );
};

EventAuditsSelector.propTypes = Selectors;

export default EventAuditsSelector;
