import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_IS_CREATING_FOLDER: 'SET_IS_CREATING_FOLDER',
  SET_IS_EDITING_FOLDER: 'SET_IS_EDITING_FOLDER',
  SET_IS_ADDING_SUBFOLDER: 'SET_IS_ADDING_SUBFOLDER',
  EXPAND_FOLDER: 'EXPAND_FOLDER',
  TOGGLE_ALL_FOLDERS: 'TOGGLE_ALL_FOLDERS',
  SET_ACTIVE_FOLDER_ID: 'SET_ACTIVE_FOLDER_ID',
  SET_TREE_SEARCH: 'SET_TREE_SEARCH',
  SET_PREVENTION_MODAL: 'SET_PREVENTION_MODAL',

  ...asyncTypes('GET_FOLDERS'),
  ...asyncTypes('CREATE_FOLDER'),
  ...asyncTypes('EDIT_FOLDER'),
  ...asyncTypes('DELETE_FOLDER'),
  ...asyncTypes('GET_FOLDER'),
  ...asyncTypes('ADD_FOLDER_FILES'),
  ...asyncTypes('DELETE_FOLDER_FILE')
};

export default TYPES;
