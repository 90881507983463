import Switch from 'common/components/form/inputs/Switch';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { selectComparedAtVersion, selectComparedChangesOnly } from 'manuals/store/selectors';

import * as manualsActions from 'manuals/store/actions';

const ViewChangesSwitch = () => {
  const selectedComparedAtVersion = useSelector(selectComparedAtVersion);
  const comparedChangesOnly = useSelector(selectComparedChangesOnly);

  const [setComparedChangesOnly] = useActions([manualsActions.setComparedChangesOnly]);

  if (!selectedComparedAtVersion) return null;

  return (
    <div className="d-flex align-items-center mb-3">
      <div className={`fw-medium fs-12 lh-1 text-${comparedChangesOnly ? 'green' : 'violet'}`}>
        View changes only
      </div>
      <Switch
        onChange={() => setComparedChangesOnly(!comparedChangesOnly)}
        value={comparedChangesOnly}
        switchClassName="green-violet-switch"
        className="mb-0 ms-1"
        size={'sm'}
      />
    </div>
  );
};

export default ViewChangesSwitch;
