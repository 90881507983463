import React from 'react';
import Box from './Box';
import { useDispatch, useSelector } from 'react-redux';
import { getRunningHoursDue } from 'store/dashboard/running-hours-due/actions';
import { selectRunningHoursDueTotals } from 'store/dashboard/running-hours-due/selectors';
import { useEffect } from 'react';
import ContentDueDate from './ContentDueDate';
import runningHours from 'common/assets/svg/common/reminder.svg';
import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import { displayDate } from 'common/utils/dates';

const BoxRunningHoursDue = () => {
  const dispatch = useDispatch();
  const totals = useSelector(state => selectRunningHoursDueTotals(state, 'running_hours_due'));

  const runningHoursDueDateFormatted = displayDate(totals?.due_date, { time: true });

  const color = totals?.is_missing ? 'red' : totals?.time_remaining <= 5 ? 'orange' : 'primary';

  const permissionsInfo = [permissions.onboard.dashboard.running_hours_report_widget.card];

  const item = {
    header: 'Running Hours Report',
    icon: runningHours,
    subheader: `Due Date: ${runningHoursDueDateFormatted || '-'}`,
    content: (
      <ContentDueDate
        path={paths.pms_running_hours}
        color={color}
        permissions={permissionsInfo}
        remainingTime={totals?.time_remaining}
        isMissing={totals?.is_missing}
      />
    ),
    key: `running_hours_report`,
    size: 'md',
    className: `running-hours-due_box text-${color}`,
    permissions: permissionsInfo
  };

  useEffect(() => {
    dispatch(getRunningHoursDue());
  }, [dispatch]);

  return <Box key={item.key} {...item} />;
};

export default BoxRunningHoursDue;
