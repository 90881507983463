import React from 'react';
import Checkbox from 'common/components/form/inputs/Checkbox';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';

import * as reportActions from 'captain-reports/store/actions';
import SvgRender from 'common/components/general/SvgRender';

const CheckboxInput = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const isLocked = useSelector(isReportLocked);

  const keysToResetWhenFalse =
    field.inputProps && field.inputProps.keysToResetWhenFalse
      ? field.inputProps.keysToResetWhenFalse
      : null;

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onChange = checked => {
    setValue({
      key: field.key,
      value: checked,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
    if (keysToResetWhenFalse) {
      keysToResetWhenFalse.forEach(el => {
        setValue({
          key: el,
          value: null
        });
      });
    }
  };

  return (
    <div className="checkbox-input h-100p d-flex flex-column justify-content-center">
      <Checkbox
        className={`${field.className ? field.className : ''} ${
          isLocked ? 'pointer-events-none' : ''
        }`}
        labelClassName={`${field.labelClassName || ''}`}
        value={value}
        onChange={() => onChange(!value)}
        label={
          <div className="d-flex align-items-center">
            <div className="me-1 text-dark">{field.label}</div>
            {field.icon && (
              <SvgRender
                src={field.icon}
                style={{ width: 16, height: 16 }}
                className="text-primary"
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default CheckboxInput;
