import PropTypes from 'prop-types';
import { offSignerRotationsTypes } from 'common/entities/crew/RotationTypes';

export const DetailTypes = PropTypes.shape({
  nationality_id: PropTypes.number,
  rank_id: PropTypes.number,
  gender_id: PropTypes.number,
  place: PropTypes.string,
  code: PropTypes.string,
  available_at: PropTypes.string,
  nearest_airport_country_id: PropTypes.number,
  photo_id: PropTypes.number,
  status_id: PropTypes.number,
  licence_id: PropTypes.number,
  health_status_id: PropTypes.number,
  decision_status_id: PropTypes.number,
  agent_id: PropTypes.number,
  remarks: PropTypes.string,
  height: PropTypes.number,
  weight: PropTypes.number,
  shoes_size: PropTypes.string,
  uniform_size: PropTypes.string,
  blood_type_id: PropTypes.number,
  tattoos_and_marks: PropTypes.string,
  allergies: PropTypes.string,
  tax_authority: PropTypes.string,
  tax_id: PropTypes.string,
  taxation_country_id: PropTypes.number,
  tax_authority_country_id: PropTypes.number,
  tax_authority_nationality_id: PropTypes.number,
  marital_status_id: PropTypes.number,
  spouse_name: PropTypes.string,
  spouse_birthday: PropTypes.string,
  party_id: PropTypes.number,
  nearest_airport_id: PropTypes.number
});

export const CrewMemberPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  photo_id: PropTypes.number,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired,
  company_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  country_id: PropTypes.number,
  city: PropTypes.string,
  state: PropTypes.string,
  postal_code: PropTypes.string,
  address: PropTypes.string,
  profession: PropTypes.string,
  position: PropTypes.string,
  notes: PropTypes.string,
  external_identifier: PropTypes.string,
  can_login: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  last_dashboard_id: PropTypes.number,
  deleted_at: PropTypes.string,
  birthday: PropTypes.string.isRequired,
  vat_registration: PropTypes.string,
  secondary_address: PropTypes.string,
  permission_template_id: PropTypes.number,
  company_type_id: PropTypes.number,
  title: PropTypes.string,
  is_archived: PropTypes.bool.isRequired,
  website: PropTypes.string,
  crew_rank_id: PropTypes.number,
  seen_notifications_at: PropTypes.string,
  fcm_token: PropTypes.string,
  created_by_id: PropTypes.number,
  updated_by_id: PropTypes.number,
  origin_organization_id: PropTypes.number,
  origin_id: PropTypes.number,
  middle_name: PropTypes.string,
  external_ids: PropTypes.string,
  is_iso_certified: PropTypes.bool.isRequired,
  father_name: PropTypes.string,
  mother_name: PropTypes.string,
  updated_at_on_sync_to_onboard: PropTypes.string.isRequired,
  vessel_access_updated_at: PropTypes.string.isRequired,
  updated_at_on_sync_to_elastic: PropTypes.string,
  access_granted_by_id: PropTypes.number,
  access_granted_at: PropTypes.string,
  access_revoked_by_id: PropTypes.number,
  access_revoked_at: PropTypes.string,
  details: DetailTypes,
  off_signer_rotations: offSignerRotationsTypes
});
