import { getVesselNotes, createVesselNotes, editVesselNotes } from './actions';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  exists: false, // this flag is used to know if we should do POST or PUT
  data: {},
  loading: false
};

const slice = createSlice({
  name: 'eventVesselNotes',
  initialState: INITIAL_STATE,
  reducers: {
    changeVesselNotesContent: (state, { payload }) => {
      state.data.content = payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getVesselNotes.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getVesselNotes.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addMatcher(
        isAnyOf(createVesselNotes.fulfilled, editVesselNotes.fulfilled, getVesselNotes.fulfilled),
        (state, { payload }) => {
          if (!payload) {
            state.exists = false;
            state.data = {};
            state.loading = false;

            return state;
          }

          state.data = payload;
          state.loading = false;
          state.exists = true;

          return state;
        }
      )

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { changeVesselNotesContent } = slice.actions;

export default slice.reducer;
