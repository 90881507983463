import React from 'react';
import Radio from 'common/components/form/inputs/Radio';

const SkippableQuestion = ({ value, onChange, disabled }) => {
  return !disabled ? (
    <div className="d-flex">
      <Radio label="Yes" checked={value === 1} onChange={() => onChange(1)} className="mb-0 ms-0" />
      <Radio label="No" checked={value === 0} onChange={() => onChange(0)} className="mb-0 ms-6" />
      <Radio label="N/A" checked={value === 2} onChange={() => onChange(2)} className="mb-0 ms-6" />
    </div>
  ) : null;
};

export default SkippableQuestion;
