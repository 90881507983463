import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import calendarIcon from 'common/assets/svg/common/calendar.svg';

import { useSelector } from 'react-redux';
import {
  selectLatestPeriod,
  selectPeriods,
  selectVesselFilter,
  selectIsPastMga
} from 'common/components/mga/store/selectors';
import { components } from 'react-select';

import { displayUTCDate } from '@/ts-common/utils/dates';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

const statusColors = {
  draft: 'violet',
  'for-review': 'orange',
  'for-corrections': 'red',
  reviewed: 'green'
};

const PeriodSelector = () => {
  const latestPeriod = useSelector(selectLatestPeriod);
  const statusLabel = latestPeriod?.status?.label;
  const periods = useSelector(selectPeriods);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const vessel = useSelector(selectVesselFilter);
  const isPastMga = useSelector(selectIsPastMga);

  const { history, match, location } = useRouter();

  const onChange = id => {
    history.replace({
      pathname: `${paths.mga}/${isPastMga ? 'past/' : ''}${match.params.view}/${vessel}/${id}`,
      search: match.params.view === 'accounts' ? '' : location.search
    });
  };

  return (
    <IconSelect
      icon={calendarIcon}
      placeholder="Select period"
      isMulti={false}
      value={latestPeriod?.id}
      options={periods}
      onChange={onChange}
      getOptionLabel={item =>
        `${displayUTCDate(item.started_at, { time: true })} - ${displayUTCDate(item.ended_at, {
          time: true
        })} | ${item?.status?.name} `
      }
      getOptionValue={item => item.id}
      components={
        isOnBoard && !isPastMga ? { SingleValue, DropdownIndicator: null } : { SingleValue }
      }
      containerClassName={`mga-period-selector text-${statusColors[statusLabel] || 'primary'} ${
        !isOnBoard && !vessel && !isPastMga ? 'opacity-5' : ''
      }`}
      selectClassName={`border-${statusColors[statusLabel] || 'primary'}`}
      isSearchable={isOnBoard && !isPastMga ? false : true}
      disabled={isPastMga ? false : isOnBoard ? true : !vessel}
    />
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className="d-flex flex-nowrap text-nowrap align-items-center fs-12">
      {`${displayUTCDate(props.data.started_at, { time: true })} - ${displayUTCDate(
        props.data.ended_at,
        { time: true }
      )}`}
      <span className="px-1">|</span>
      <strong>{props.data?.status?.name}</strong>
    </div>
  </components.SingleValue>
);

export default PeriodSelector;
