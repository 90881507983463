import { createSelector } from 'reselect';
import { jobEnums } from 'common/utils/fixed';
import { filterJobStatuses } from 'common/components/jobs/_base/_helpers';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { periodicityEnums } from 'common/utils/fixed';

const getKey = (_, key) => key;

const selectPmsJobsReducer = state => state.pms.jobs;
const selectJobsReducer = state => state.jobs;

export const selectIsLoading = createSelector([selectPmsJobsReducer], jobs => jobs.isLoading);

const selectJobType = state => selectJobsReducer(state)?.type;
const selectIsOnboard = state => state.isOnBoard;

export const selectIsUnscheduledJob = state => selectJobType(state) === jobEnums.unscheduled;

export const selectIsPlannedJob = state => selectJobType(state) === jobEnums.pms;

export const selectIsPms = state =>
  state?.router?.location?.pathname?.startsWith('/pms') ||
  selectIsPlannedJob(state) ||
  selectIsUnscheduledJob(state);

export const selectSelectedVessels = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.selectedVessels
);

export const selectVesselSystemAssignments = state =>
  selectPmsJobsReducer(state).vesselSystemAssignments;

export const selectTotalVesselSystemAssignments = state =>
  selectVesselSystemAssignments(state)?.length;

const selectVesselSystemAssignmentData = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.vesselSystemAssignment
);

export const selectVesselSystemAssignment = createSelector(
  selectVesselSystemAssignmentData,
  getKey,
  (vesselSystemAssignmentData, vesselSystemAssignmentID) =>
    vesselSystemAssignmentData[vesselSystemAssignmentID]
);

const selectVesselSystemData = createSelector([selectPmsJobsReducer], jobs => jobs.vesselSystem);

export const selectVesselSystem = createSelector(
  selectVesselSystemData,
  getKey,
  (vesselSystemData, vesselSystemAssignmentID) => vesselSystemData[vesselSystemAssignmentID]
);

const selectVesselSystemAssignmentMaintenanceJobsData = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.vesselSystemAssignmentMaintenanceJobs
);

export const selectVesselSystemAssignmentJobs = createSelector(
  selectVesselSystemAssignmentMaintenanceJobsData,
  getKey,
  (vesselSystemAssignmentMaintenanceJobs, vesselSystemAssignmentID) =>
    vesselSystemAssignmentMaintenanceJobs[vesselSystemAssignmentID]
);

export const selectTotalVesselSystemAssignmentJobs = createSelector(
  selectVesselSystemAssignmentJobs,
  vesselSystemAssignmentMaintenanceJobs => vesselSystemAssignmentMaintenanceJobs.length
);

const selectMaintenanceJobsData = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.maintenanceJob
);

export const selectMaintenanceJob = createSelector(
  selectMaintenanceJobsData,
  getKey,
  (maintenanceJobsData, jobID) => maintenanceJobsData[jobID]
);

const selectExpandedVesselSystemAssignment = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.expanded
);

export const selectIsVesselSystemAssignmentExpanded = createSelector(
  selectExpandedVesselSystemAssignment,
  getKey,
  (expanded, vesselSystemAssignmentID) => expanded[vesselSystemAssignmentID]
);

export const selectActiveJobID = createSelector([selectPmsJobsReducer], jobs => jobs.activeJobID);

export const selectJobDrawerIsOpen = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.jobDrawerIsOpen
);

export const selectAllExpanded = createSelector([selectPmsJobsReducer], jobs => jobs.allExpanded);

export const selectTablePaging = createSelector([selectPmsJobsReducer], jobs => jobs.paging);

export const selectTotalPages = createSelector([selectTablePaging], paging => paging?.total);

export const isSpacePartsCollapsed = createSelector(
  selectJobsReducer,
  jobs => jobs.isSparePartsCollapsed
);

export const selectFilterPMSJobStatuses = createSelector(
  [selectJobStatuses, selectIsOnboard],
  (jobStatuses, isOnBoard) =>
    filterJobStatuses({
      statuses: jobStatuses,
      departments: [],
      mainDepartment: null,
      isForVessel: true,
      vesselJobsOnly: true,
      isOnBoard
    })
);

/* Periodicity selectors*/
export const selectJobPeriodicity = createSelector(selectJobsReducer, jobs => jobs.periodicity);

export const selectIsJobPeriodicityAsNeeded = state =>
  selectJobPeriodicity(state)?.type === periodicityEnums.as_needed;

export const selectJobPeriodicityDescription = createSelector(
  selectJobsReducer,
  jobs => jobs.periodicity_description
);

export const selectJobPeriodicityHasRunningHours = createSelector(
  selectJobPeriodicity,
  periodicity => periodicity && periodicity?.system_running_hours !== null
);
/* -- */

/* PMS - Maintenance */
export const selectMaintenance = createSelector(selectJobsReducer, jobs => jobs.maintenance);

export const selectMaintenanceDetais = createSelector(
  selectMaintenance,
  maintenance => maintenance?.maintenance_details
);

export const selectMaintenanceDetaisField = createSelector(
  selectMaintenanceDetais,
  getKey,
  (maintenanceDetails, key) => maintenanceDetails?.[key]
);

export const selectMaintenanceDetaisVesselSystemAssignmentId = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.vessel_system_assignment_id
);

export const selectMaintenanceDetaisVesselSystemAssignment = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.vessel_system_assignment
);

export const selectMaintenanceDetailsVesselSystemAssignmentIsClass = createSelector(
  selectMaintenanceDetaisVesselSystemAssignment,
  vesselSystemAssignment => vesselSystemAssignment?.is_class
);

export const selectMaintenanceDetaisVesselSystemAssignmentVesselSystem = createSelector(
  selectMaintenanceDetaisVesselSystemAssignment,
  vesselSystemAssignment => vesselSystemAssignment?.vessel_system
);

export const selectMaintenanceJobHasSystemRunningHours = createSelector(
  selectMaintenanceDetaisVesselSystemAssignmentVesselSystem,
  vesselSystem =>
    vesselSystem?.parent
      ? !!vesselSystem?.parent?.report_running_hours
      : !!vesselSystem?.report_running_hours
);

export const selectMaintenanceJobClass = createSelector(
  selectJobsReducer,
  jobs => jobs.maintenance_job_class
);

export const selectMaintenanceFetching = createSelector(
  selectJobsReducer,
  jobs => jobs.maintenanceFetching
);

export const selectShowMaintenanceSpareParts = createSelector(
  selectMaintenance,
  maintenance => maintenance.maintenance_spare_parts
);

export const selectShowMaintenanceConsumedParts = createSelector(
  selectShowMaintenanceSpareParts,
  parts => parts.consumed
);

export const selectShowMaintenanceRequiredParts = createSelector(
  selectShowMaintenanceSpareParts,
  parts => parts.required
);

export const selectPreviousMaintenance = createSelector(
  selectJobsReducer,
  jobs => jobs.previous_maintenance_job
);

export const selectPreviousMaintenanceDetails = createSelector(
  selectPreviousMaintenance,
  previousMaintenance => previousMaintenance?.maintenance_details
);

export const selectPreviousMaintenanceDetailsField = createSelector(
  selectPreviousMaintenanceDetails,
  getKey,
  (previousMaintenance, key) => previousMaintenance?.[key]
);
/* -- */

/* Due on Rh selectors */
export const selectMaintenanceDetaisDueOnRh = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.due_on_rh
);

export const selectMaintenanceDetaisDueOnRhRemarks = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.due_on_rh_remarks
);

export const selectMaintenanceDetaisDueOnRhChangedAt = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.due_on_rh_changed_at
);

export const selectMaintenanceDetaisDueOnRhChangedBy = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.due_on_rh_changed_by
);

export const selectMaintenanceDetaisDueOnRhPrevious = createSelector(
  selectMaintenanceDetais,
  maintenanceDetails => maintenanceDetails?.due_on_rh_previous
);
/* -- */

// Selectors for filtering job
export const selectSelectedVesselFilter = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.selectedVesselFilter
);

export const selectSelectedSystemFilter = createSelector(
  [selectPmsJobsReducer],
  jobs => jobs.selectedSystemFilter
);
