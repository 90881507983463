import React from 'react';
import { Row, Col } from 'reactstrap';

import renderProfileValue from 'common/components/layouts/profile-data-box/renderProfileValue';
import FormFieldLabel from 'common/components/layouts/vertical-tab-view/components/FormFieldLabel';

const FieldRow = ({ field, findex, profile, className = '' }) => {
  return field.condition === undefined || field.condition(profile) ? (
    <Row className={`profile-data-row ${className} align-items-${field.rowAlignment || 'center'}`}>
      <Col xs={4} className={`profile-data-row__left text-end ${field.labelClassName || ''}`}>
        <FormFieldLabel color={field.labelColor}>{field.label}</FormFieldLabel>
      </Col>
      <Col className={`profile-data-row__right fs-12 fw-normal`}>
        {renderProfileValue(field, profile, findex)}
      </Col>
    </Row>
  ) : null;
};

export default FieldRow;
