import { selectIsCreate } from 'events/store/events/selectors';
import { createSelector } from 'reselect';

const selectIsOnboard = state => state.isOnBoard;
const getId = (_, id) => id;

const selectChecklist = state => state.eventModules.checklist;

export const selectChecklistIsLoading = state => selectChecklist(state).loading;

export const selectOpenLists = state => selectChecklist(state).openLists;

export const selectChecklistOptions = state => selectChecklist(state).options;

export const selectChecklistState = state => selectChecklist(state).checklistState;

export const selectChecklistOption = createSelector(selectChecklistOptions, getId, (options, id) =>
  options.find(opt => opt.id === id)
);

export const selectAreChecklistActionsLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);

  if (isCreate) return false;

  return isOnBoard;
};
