export const imageUrlSelector = ({ file, label }) => {
  let url = null;

  if (file && file.image_sizes && file.image_sizes.length && label) {
    const imageSize = file.image_sizes.find(inner => inner.size_label === label);

    if (imageSize) {
      url = imageSize.resized.url;
    } else {
      url = file && file.url ? file.url : null;
    }
  } else {
    url = file && file.url ? file.url : null;
  }

  return url;
};
