import {
  selectConsumptionBunkerId,
  selectBunkerConsumptions
} from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import numeral from 'numeral';
import _get from 'lodash/get';

const selectFieldValue = (secondaryBunkerConsumption, key) =>
  secondaryBunkerConsumption?.[key]?.value;

const useAutoCalculateBunkerConsumptionSecondaryRob = ({ field, subGroupIndex }) => {
  const [robValue, setRobValue] = useState('');

  const bunkerId = useSelector(state =>
    selectConsumptionBunkerId(state, 'consumptions', subGroupIndex)
  );
  const bunkerConsumptions = useSelector(state =>
    selectBunkerConsumptions(state, 'consumptions', bunkerId)
  );

  const secondaryBunkerConsumption = _get(bunkerConsumptions, `[1]`);
  const hasSecondaryBunkerConsumption = !!secondaryBunkerConsumption;

  const supplied = selectFieldValue(secondaryBunkerConsumption, 'supplied');
  const correctionHfo = selectFieldValue(secondaryBunkerConsumption, 'correction_hfo');
  const correctionLfo = selectFieldValue(secondaryBunkerConsumption, 'correction_lfo');
  const consumption = selectFieldValue(secondaryBunkerConsumption, 'total_consumption');
  const viscosity = selectFieldValue(secondaryBunkerConsumption, 'viscosity');
  const suppliedViscosity = selectFieldValue(secondaryBunkerConsumption, 'supplied_viscosity');

  const isSuppliedHfo = suppliedViscosity > 80;
  const isSuppliedLfo = suppliedViscosity && suppliedViscosity <= 80;

  const isHfo = field.key.includes('hfo');

  useEffect(() => {
    if (field?.key === 'rob') return;

    if (!hasSecondaryBunkerConsumption) {
      setRobValue(0);
      return;
    }

    let result = null;
    const prev = numeral(0);
    result = prev;

    // Only Fo grade fuels have HFO/LFO
    // If viscosity is between 80.0 and 700.0 cSt → HFO
    // if viscosity is less than or equal to 80.0 cSt → LFO

    if (viscosity > 80) {
      if (isHfo) {
        result = prev.subtract(+consumption || 0);
      }
    } else if (viscosity && viscosity <= 80) {
      if (isHfo) {
        result = prev;
      }
      if (!isHfo) {
        result = prev.subtract(+consumption || 0);
      }
    }

    // Supplied field use supplied viscosity field to determine which HFO/LFO values to change.
    // Supplied viscosity rules are same with viscosity rules above.

    if (isSuppliedHfo) {
      if (isHfo) {
        result = result.add(+supplied || 0);
      }
    } else if (isSuppliedLfo) {
      if (!isHfo) {
        result = result.add(+supplied || 0);
      }
    }

    setRobValue(result?._value);
  }, [
    consumption,
    supplied,
    isSuppliedLfo,
    correctionLfo,
    correctionHfo,
    viscosity,
    isSuppliedHfo,
    isHfo,
    hasSecondaryBunkerConsumption,
    field?.key
  ]);

  useEffect(() => {
    if (field?.key !== 'rob') return;

    if (!hasSecondaryBunkerConsumption) {
      setRobValue(0);
      return;
    }

    const prev = numeral(0); // Calculate the previous rob only for the First Column
    const result = prev.subtract(+consumption || 0).add(+supplied || 0);

    setRobValue(result?._value);
  }, [field?.key, hasSecondaryBunkerConsumption, supplied, consumption]);

  return robValue;
};

export default useAutoCalculateBunkerConsumptionSecondaryRob;
