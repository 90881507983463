import React from 'react';

import PropTypes from 'prop-types';

export const getColorClass = condition => {
  const { is_becoming_due_rh, is_overdue_rh } = condition;

  if (is_overdue_rh) {
    return 'text-red';
  } else if (is_becoming_due_rh) {
    return 'text-orange';
  } else {
    return '';
  }
};

const DueOnRh = ({ className = '', dueOnRh = '', condition }) => {
  return (
    <div
      className={`d-flex align-items-center text-nowrap ${getColorClass(
        condition
      )} fw-normal fs-8 ${className}`}
    >
      {dueOnRh || dueOnRh === 0 ? dueOnRh : '-'}
    </div>
  );
};

DueOnRh.propTypes = {
  dueOnRh: PropTypes.number,
  className: PropTypes.string,
  condition: PropTypes.shape({
    is_becoming_due_rh: PropTypes.bool,
    is_overdue_rh: PropTypes.bool
  }).isRequired
};

export default DueOnRh;
