import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Checkbox from 'common/components/form/inputs/Checkbox';
import { Collapse } from 'reactstrap';
import * as jobChecklistActions from 'common/components/jobs/_base/modules/checklist/store/actions';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import _isEqual from 'lodash/isEqual';
import SvgRender from 'common/components/general/SvgRender';
import comments from 'common/assets/svg/common/comments.svg';
import commentsSolid from 'common/assets/svg/common/comments-solid.svg';
import { parseTextHtml } from '../helpers';
import { isUserAllowedToEdit } from 'common/components/jobs/_base/permissions/helpers';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import Comments from '../comments';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import { selectRestrictedFields } from 'store/jobs-fields/selectors';

const Display = ({ disabled, state, onCheck, parentRef, sectionId, isEditMode }) => {
  const { title, checked, id, comments_count, mentions } = state;

  const [hovered, setHovered] = useState(false);
  const [iconHovered, setIconHovered] = useState(false);

  const openLists = useSelector(state => state.jobs.checklist.openLists, _isEqual);
  const typeOfLoggedInUser = useSelector(state => selectJobField(state, 'typeOfLoggedInUser'));
  const restrictedFields = useSelector(state =>
    selectRestrictedFields(state)?.find(item => item.field === 'checklist_options')
  );

  const isOpen = openLists.includes(id);
  const [toggleCollapse] = useActions([jobChecklistActions.toggleCollapse]);

  const toggle = () => {
    toggleCollapse(id);
  };

  const createMarkup = () => {
    return {
      __html: parseTextHtml(title, mentions)
    };
  };

  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);

  const onMouseEnterIcon = () => setIconHovered(true);
  const onMouseLeaveIcon = () => setIconHovered(false);

  return (
    <div>
      <Row
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`g-0 display-row ${isOpen ? 'mb-1' : ''}`}
      >
        <Col xs={12}>
          <Row className="g-0 align-items-center">
            <Col xs={11} className="d-flex">
              <Checkbox
                onChange={() =>
                  isUserAllowedToEdit({ restrictedFields, typeOfLoggedInUser }) ? onCheck() : {}
                }
                className={`job-checklist-display__checkbox ${
                  disabled ? 'pointer-events-none' : ''
                }`}
                value={checked}
                color="green"
                outline
              />
              <div
                className="text-primary job-checklist-display__checkbox-text fs-14"
                dangerouslySetInnerHTML={createMarkup()}
              />
            </Col>
            {isEditMode && !disabled && sectionId ? (
              <UserCanEdit field="checklist_options">
                <Col
                  xs={1}
                  onClick={toggle}
                  className="d-flex align-items-center cursor-pointer justify-content-end comment-icon"
                >
                  <div className="fs-14 fw-bold me-1">
                    {comments_count === 0 ? '' : comments_count}
                  </div>
                  {(comments_count > 0 || isOpen || hovered) && (
                    <SvgRender
                      onMouseEnter={onMouseEnterIcon}
                      onMouseLeave={onMouseLeaveIcon}
                      src={isOpen || iconHovered ? commentsSolid : comments}
                      style={{ width: 18, height: 18 }}
                    />
                  )}
                </Col>
              </UserCanEdit>
            ) : null}
          </Row>
        </Col>
      </Row>
      {isEditMode && !disabled ? (
        <Collapse isOpen={isOpen}>
          <Comments
            isOpen={isOpen}
            onClose={toggle}
            parentRef={parentRef}
            optionId={id}
            jobId={sectionId}
          />
        </Collapse>
      ) : null}
    </div>
  );
};

export default Display;
