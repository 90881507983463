import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';

import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _findKey from 'lodash/findKey';
import _pickBy from 'lodash/pickBy';
import _cloneDeep from 'lodash/cloneDeep';
import _orderBy from 'lodash/orderBy';

import paths from 'routing/routes/_paths';

import { toggleNestedItems } from 'common/components/tree-layout/utils/helpers';
import {
  getChaptersUIDs,
  createChaptersAsObject,
  findParentChapter,
  getChapterBreadcrumbs,
  parseSearchedChapters,
  sortChaptersBySortIndex,
  highlightSearchedChapters
} from '../_helpers';

const INITIAL_STATE = {
  isOnBoard: false,
  isEditLocked: false,
  isCreatingChapter: false,
  isAddingSubChapterTo: {}, // { parent_uid: true }
  isEditingChapter: {}, // { uid: true }
  isChapterExpanded: {}, // { uid: true }
  isMoveChapterExpanded: {}, // { uid: true }
  chapterModalAction: {
    id: null,
    isOpen: false,
    type: null // delete || move || rearrange
  },
  sortedChapters: [],
  navigationPreventionModal: {
    isOpen: false,
    chapterUid: null
  }, // navigationPrevention modal state
  relatedChaptersModal: { isOpen: false, manualId: null, chapter: null }, // relatedChaptersModal modal state
  data: {}, // { id, title, description } - manual data
  versions: [], // the manual's versions
  rootChapters: [], // Top level tree
  subChapters: {}, // { uid: [children ids] }
  chapters: {}, // { uid: { title, desc, ... } }
  activeChapterId: null, // Changes every time a new change request is created
  activeChapterUid: null, // Used on every chapters endpoint - STAYS the same on every version
  activeChapterBreadcrumbs: [],
  activeChapterContent: null,
  activeChapterPostAttachments: [],
  currentVersion: null,
  chaptersInSearch: {},
  hightlightedChapters: {},
  searchText: null,
  showPostForm: false,
  comparedAtVersion: null,
  comparedAtVersionChapterPost: null,
  comparedChangesOnly: false
};

const manualInner = function (state = _cloneDeep(INITIAL_STATE), { type, payload }) {
  switch (type) {
    case TYPES.SET_MANUAL_INNER_DATA:
      if (payload && payload.data) {
        const { versions, ...rest } = payload.data;

        return {
          ..._cloneDeep(INITIAL_STATE),
          data: rest,
          versions: _orderBy(versions, ['valid_from'], ['desc']),
          isEditLocked: payload.isEditLocked,
          isOnBoard: payload.isOnBoard || false
        };
      } else {
        return { ..._cloneDeep(INITIAL_STATE) };
      }

    case TYPES.UPDATE_MANUAL_INNER_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case TYPES.SET_MANUAL_INNER_CHAPTERS:
      if (payload) {
        const chapters = [...payload.data];

        const root = chapters.filter(c => !c.parent_uid);
        const subChapters = _groupBy(
          chapters.filter(c => c.parent_uid),
          c => c.parent_uid
        );

        const newState = {
          sortedChapters: payload.data.map(c => c.uid),
          rootChapters: getChaptersUIDs(sortChaptersBySortIndex(root)),
          chapters: createChaptersAsObject(chapters),
          subChapters: _reduce(
            Object.keys(subChapters),
            (result, uid) => {
              const parent = chapters.find(c => c.uid === parseInt(uid));

              if (parent) {
                result[parent.uid] = getChaptersUIDs(sortChaptersBySortIndex(subChapters[uid]));
              }

              return result;
            },
            {}
          )
        };

        if (state.activeChapterUid) {
          // Check if the active chapter id has changed after the creation of a new change request, and update the store if it does
          const active = payload.data.find(d => d.uid === state.activeChapterUid);
          newState.activeChapterId = active?.id;
        }

        return {
          ...state,
          ...newState
        };
      }

      return state;

    case TYPES.SET_CURRENT_VERSION:
      const version = payload ? state.versions.find(v => v.id == payload) : null;
      const isEditLocked = (version && version.status !== 'working') || !version;

      if (version && state.currentVersion && version.id !== state.currentVersion.id) {
        // Reset state on version change
        return {
          ...state,
          isCreatingChapter: false,
          isAddingSubChapterTo: {},
          isEditingChapter: {},
          isChapterExpanded: {},
          rootChapters: [],
          subChapters: {},
          chapters: {},
          activeChapterId: null,
          activeChapterUid: null,
          activeChapterBreadcrumbs: [],
          activeChapterContent: null,
          comparedAtVersionChapterPost: null,
          activeChapterPostAttachments: [],
          currentVersion: version,
          chaptersInSearch: {},
          searchText: null,
          isEditLocked
        };
      } else {
        return {
          ...state,
          currentVersion: version,
          isEditLocked
        };
      }

    case TYPES.UPDATE_CURRENT_VERSION:
      return {
        ...state,
        versions: state.versions.map(v =>
          v.id === state.currentVersion.id ? { ...state.currentVersion, ...payload } : v
        ),
        currentVersion: { ...state.currentVersion, ...payload }
      };

    case TYPES.UPDATE_MANUAL_CHAPTERS_TREE:
      if (payload) {
        if (!payload.parent_uid) {
          return {
            ...state,
            rootChapters: [...state.rootChapters, payload.uid],
            chapters: { ...state.chapters, [payload.uid]: { ...payload } }
          };
        } else {
          const parentChapterUid = findParentChapter(payload.parent_uid, state.chapters);

          if (parentChapterUid) {
            return {
              ...state,
              subChapters: {
                ...state.subChapters,
                [parentChapterUid]: [...(state.subChapters[parentChapterUid] || []), payload.uid]
              },
              chapters: { ...state.chapters, [payload.uid]: { ...payload } }
            };
          }
        }
      }

      return state;

    case TYPES.TOGGLE_CHAPTER_CREATION_FORM:
      return {
        ...state,
        isCreatingChapter: payload
      };

    case TYPES.SET_EDITTING_CHAPTER:
      return {
        ...state,
        isEditingChapter: { ...state.isEditingChapter, ...payload }
      };

    case TYPES.SET_ADDING_SUBCHAPTER_TO:
      return {
        ...state,
        isAddingSubChapterTo: { ...state.isAddingSubChapterTo, ...payload }
      };

    case TYPES.UPDATE_CHAPTER:
      return {
        ...state,
        chapters: {
          ...state.chapters,
          [payload.uid]: { ...state.chapters[payload.uid], ...payload }
        }
      };

    case TYPES.SET_MOVE_MODAL_CHAPTER_EXPANDED:
      const isMoveOpen = _findKey(payload.params, o => o);

      if (isMoveOpen) {
        return {
          ...state,
          isMoveChapterExpanded: {
            ...(payload.closeOtherChapters ? {} : state.isMoveChapterExpanded),
            ...payload.params
          }
        };
      } else {
        return {
          ...state,
          isMoveChapterExpanded: payload.closeOtherChapters
            ? {}
            : _pickBy(
                state.isMoveChapterExpanded,
                (_, key) => Object.keys(payload.params)[0] !== key
              )
        };
      }

    case TYPES.SET_CHAPTER_EXPANDED:
      const isOpen = _findKey(payload.params, o => o);

      if (isOpen) {
        return {
          ...state,
          isChapterExpanded: {
            ...(payload.closeOtherChapters ? {} : state.isChapterExpanded),
            ...payload.params
          }
        };
      } else {
        return {
          ...state,
          isChapterExpanded: _pickBy(
            state.isChapterExpanded,
            (_, key) => Object.keys(payload.params)[0] !== key
          )
        };
      }

    case TYPES.EXPAND_OR_COLLAPSE_CHAPTERS:
      return {
        ...state,
        isChapterExpanded: toggleNestedItems(state.subChapters, payload)
      };

    case TYPES.SET_CHAPTER_MODAL_ACTION:
      if (payload) {
        return { ...state, chapterModalAction: { ...payload } };
      } else {
        return { ...state, chapterModalAction: { id: null, uid: null, isOpen: false, type: null } };
      }

    case TYPES.SEARCH_IN_CHAPTERS_TREE:
      if (payload) {
        const chapters = parseSearchedChapters(state.chapters, payload.data, state.subChapters);
        const hightlightedChapters = highlightSearchedChapters(payload.data);

        return {
          ...state,
          chaptersInSearch: { ...chapters },
          hightlightedChapters: { ...hightlightedChapters },
          searchText: payload.search,
          isChapterExpanded: { ...chapters }
        };
      } else {
        return {
          ...state,
          chaptersInSearch: {},
          hightlightedChapters: {},
          searchText: null,
          isChapterExpanded: {}
        };
      }

    case TYPES.SET_ACTIVE_CHAPTER_UID:
      // const activeChapterId = _findKey(state.chapters, c => c.uid === payload);
      const activeChapterId = state.chapters[payload] && state.chapters[payload].id;

      if (payload && activeChapterId) {
        // Have the active chapter's tree expanded
        let breadcrumbs = getChapterBreadcrumbs(payload, state.chapters);
        let expandedChapterTree = null;

        if (!state.activeChapterId && breadcrumbs.length > 1) {
          expandedChapterTree = _reduce(
            breadcrumbs,
            (result, value) => {
              result[value.uid] = true;
              return result;
            },
            {}
          );
        }

        return {
          ...state,
          showPostForm: false,
          activeChapterContent: null,
          comparedAtVersionChapterPost: null,
          activeChapterPostAttachments: [],
          activeChapterId: activeChapterId ? parseInt(activeChapterId) : null,
          activeChapterUid: payload,
          activeChapterBreadcrumbs: breadcrumbs,
          isChapterExpanded: expandedChapterTree
            ? { ...state.isChapterExpanded, ...expandedChapterTree }
            : state.isChapterExpanded
        };
      } else {
        return {
          ...state,
          showPostForm: false,
          activeChapterContent: null,
          comparedAtVersionChapterPost: null,
          activeChapterPostAttachments: [],
          activeChapterId: null,
          activeChapterUid: null,
          activeChapterBreadcrumbs: []
        };
      }

    case TYPES.SET_CHAPTER_POST:
      return {
        ...state,
        activeChapterContent: payload,
        activeChapterPostAttachments: []
      };

    case TYPES.SET_CHAPTER_POST_ATTACHMENTS:
      if (payload) {
        return {
          ...state,
          activeChapterPostAttachments: [...state.activeChapterPostAttachments, ...payload]
        };
      } else {
        return state;
      }

    case TYPES.TOGGLE_POST_FORM:
      return {
        ...state,
        showPostForm: payload
      };

    case TYPES.SET_COMPARED_AT_VERSION:
      return {
        ...state,
        comparedAtVersion: payload,
        comparedChangesOnly: false
      };

    case TYPES.SET_COMPARED_AT_VERSION_POST:
      return {
        ...state,
        comparedAtVersionChapterPost: payload?.comparedVersionData,
        activeChapterContent: payload?.firstVersionData
      };

    case TYPES.SET_COMPARED_CHANGES_ONLY:
      return {
        ...state,
        comparedChangesOnly: payload
      };

    case TYPES.SET_NAVIGATION_PREVENTION_MODAL:
      return {
        ...state,
        navigationPreventionModal: { ...payload }
      };

    case TYPES.SET_RELATED_CHAPTERS_MODAL:
      return {
        ...state,
        relatedChaptersModal: { ...payload }
      };

    case LOCATION_CHANGE:
      // Reset or Init the store on location change
      if (!payload.location.pathname.startsWith(`${paths.manuals}/`)) {
        return { ..._cloneDeep(INITIAL_STATE) };
      }
      return state;

    default:
      return state;
  }
};

export default manualInner;
