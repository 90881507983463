import {
  components,
  SingleValueProps as ReactSelectSingleValueProps,
  OptionProps as ReactSelectOptionProps
} from 'react-select';
import Select from '@/ts-common/components/form/inputs/select';

import SvgRender from 'common/components/general/SvgRender';
import { Dispatch, FC, SetStateAction } from 'react';
import { BorderedFileType } from '@/ts-common/types/files';

type OptionType = { type: string; extension: BorderedFileType; text: string };

export type OptionsType = {
  value: string;
  label: string;
  icon: string;
  types: OptionType[];
};

type ExportSelectorProps = {
  className?: string;
  selected: OptionsType | null;
  setSelected: Dispatch<SetStateAction<OptionsType | null>>;
  options: OptionsType[];
};

const ExportSelector: FC<ExportSelectorProps> = ({ selected, setSelected, options, ...rest }) => {
  return (
    <Select
      getOptionValue={(option: OptionsType) => option.value}
      getOptionLabel={(option: OptionsType) => option.label}
      onChange={newValue => {
        const onChangedValue = newValue as OptionsType;

        const selectedOption = options.find(o => o.value === onChangedValue.value) as OptionsType;

        setSelected(selectedOption);
      }}
      options={options}
      isMulti={false}
      placeholder="Select an export"
      value={selected}
      components={{ Option, SingleValue }}
      {...rest}
    />
  );
};

export const Option: FC<ReactSelectOptionProps<OptionsType>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Preview data={props.data} />
    </components.Option>
  );
};

export const SingleValue: FC<ReactSelectSingleValueProps<OptionsType>> = ({
  children,
  ...props
}) => (
  <components.SingleValue {...props}>
    <Preview data={props.data} />
  </components.SingleValue>
);

type PreviewProps = {
  data: OptionsType;
};

const Preview: FC<PreviewProps> = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <SvgRender src={data.icon} style={{ width: 17, height: 17 }} />
      <span className="cms-14 text-primary fs-12">{data.label}</span>
    </div>
  );
};

export default ExportSelector;
