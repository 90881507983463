import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const VesselSystemSelector = ({
  filter,
  onChange,
  isMulti,
  autoFocus = true,
  listParams,
  icon,
  ...rest
}) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const Tag = icon ? IconSelect : Select;

  useFilterSelector(
    filter?.value,
    { list: 'vessel-systems', listParams },
    { selected: selectedGroups, setSelected: setSelectedGroups }
  );

  return (
    <Tag
      autoFocus={autoFocus}
      placeholder="Select system"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.description}
      isAsync
      isMulti={isMulti}
      value={
        filter?.value ? selectedGroups.filter(el => filter?.value.map(Number).includes(el.id)) : []
      }
      loadOptions={search => getAsyncOptions(search, 'vessel-systems', listParams)}
      defaultOptions={() => getInitialAsyncValues('vessel-systems', null, null, listParams)}
      onChange={selected => {
        setSelectedGroups(isMulti ? selected : [selected]);
        onChange({
          value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
        });
      }}
      icon={icon}
      {...rest}
    />
  );
};

export default VesselSystemSelector;
