import { useQuery } from '@tanstack/react-query';

import {
  VesselPmsIntegrationClassType,
  VesselPmsIntegrationStatusBase
} from '@/common/types/reports';
import { VESSEL_PMS_ANNUAL_REPORT_INTEGRATION_STATUS } from './query-keys';
import { getVesselPmsAnnualReportIntegrationStatus } from './api';

export const useVesselPmsAnnualReportIntegrationStatus = (
  vesselId: number,
  classType: VesselPmsIntegrationClassType
) => {
  return useQuery<VesselPmsIntegrationStatusBase>({
    queryKey: [VESSEL_PMS_ANNUAL_REPORT_INTEGRATION_STATUS, vesselId, classType],
    queryFn: () => getVesselPmsAnnualReportIntegrationStatus({ vesselId, classType }),
    enabled: !!vesselId && !!classType,
    retry: false
  });
};
