import moment from 'moment';

import { Row, Col } from 'reactstrap';

const DatetimeRangeView = ({ value }) => {
  return (
    <div className="w-100p">
      <Row>
        <Col xs="auto">
          <span className="me-2 fs-12 fw-bold text-violet">FROM</span>{' '}
          {value?.from ? moment(value.from).format('DD/MM/YYYY HH:mm') : '-'}{' '}
        </Col>
        <Col>
          <span className="me-2 fs-12 fw-bold text-violet">TO</span>
          {value?.to ? moment(value.to).format('DD/MM/YYYY HH:mm') : '-'}{' '}
        </Col>
      </Row>
    </div>
  );
};

export default DatetimeRangeView;
