import { createSelector } from 'reselect';
import _isNumber from 'lodash/isNumber';
import _sortBy from 'lodash/sortBy';

const selectListReducer = state => state.lists;
const getKey = (_, key) => key;

const selectListDefaults = createSelector(selectListReducer, lists => lists?._defaults);
export const selectListDefaultOptions = createSelector(
  selectListDefaults,
  getKey,
  (defaults, list_key) => defaults?.[list_key]?.options || []
);
export const selectListDefaultLastFetchedAt = createSelector(
  selectListDefaults,
  getKey,
  (defaults, list_key) => defaults?.[list_key]?.lastFetchedAt || null
);

export const selectMemoizedList = createSelector(
  selectListReducer,
  getKey,
  (lists, list_key) => lists[list_key]
);
export const selectList = list => state => selectMemoizedList(state, list);

export const selectListOptionsFromStore = createSelector(
  selectMemoizedList,
  selectListDefaultOptions,
  (list, defaults) => {
    if (!list?.options?.length && defaults?.length) return defaults;

    return list?.options;
  }
);

export const selectListIsFetching = createSelector(selectMemoizedList, list => list?.isFetching);

export const selectListFromStore = createSelector(
  selectListOptionsFromStore,
  selectListIsFetching,
  (options, isFetching) => ({ options, isFetching })
);

export const hasFetchedList = list => state => {
  const listState = selectList(list)(state);
  return listState.options.length > 0 || listState.options.isFetching;
};

/* Fuel Selectors */
export const selectFuelsById = id => state =>
  state.lists?.['fuel-grades'].options.filter(fuel => fuel.id === id);

/* Units Selectors */
export const selectUnitsByType = type => state =>
  state.lists.units.options.filter(unit => unit.type === type);

export const selectUnits =
  (labels = []) =>
  state =>
    labels
      .map(label => {
        const unit = state.lists.units.options.find(unit => unit.label === label);
        return unit || null;
      })
      .filter(unit => unit !== null);

export const selectUnitById = id => state => state.lists.units.options.find(unit => unit.id === id);

export const selectUnitByLabel = label => state =>
  state.lists.units.options.find(unit => unit.label === label);
// -- /

/* Job Statuses */
export const selectJobStatuses = createSelector(
  selectListReducer,
  lists => lists['job-statuses'].options
);
// -- /

/* Jobs Selectors */
export const selectImportancesList = state => {
  const importances = selectListOptionsFromStore(state, 'job-importances');

  return _sortBy(importances, ['sort_index']);
};
/* -- */

/* Posts Categories Selectors */
export const selectPostCategoriesList = state =>
  selectListOptionsFromStore(state, 'post-categories');

export const selectPostCategoryPosts = state =>
  selectPostCategoriesList(state)?.find(category => category.label === 'post');

export const selectPostCategoryAnnouncements = state =>
  selectPostCategoriesList(state)?.find(category => category.label === 'announcement');
/* -- */

/* Itinerary Selectors */
export const selectItineraryActionType = createSelector(
  state => selectListDefaultOptions(state, 'itinerary-action-types'),
  getKey,
  (itineraryActionTypes, actionLabel) =>
    itineraryActionTypes?.length
      ? itineraryActionTypes.find(type => type?.label === actionLabel)?.name
      : null
);
/* -- */
