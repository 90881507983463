import TYPES from './types';

export const INITIAL_STATE = {
  isFetching: false,
  files: [],
  isOpen: false,
  selectedFile: null,
  rotateDirection: 'top'
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.SET_GALLERY_FILES:
      return {
        ...state,
        files: payload
      };

    case TYPES.SET_ROTATE_DIRECTION:
      return {
        ...state,
        rotateDirection: payload
      };

    case TYPES.SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: payload
      };

    case TYPES.TOGGLE_GALLERY:
      return {
        ...state,
        isOpen: payload
      };
    default:
      return state;
  }
};

export default reducer;
