import CircledButton from 'common/components/buttons/CircledButton';

import { useSelector } from 'react-redux';
import {
  selectIsCreatingFolder,
  selectIsLocked
} from 'common/components/filesystem/store/selectors';
import { selectAccount } from '@/store/account/selectors';
import { useActions } from 'utils/hooks';

import * as filesystemActions from 'common/components/filesystem/store/actions';
import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const Footer = () => {
  const isLocked = useSelector(selectIsLocked);
  const account = useSelector(selectAccount);
  const isCreating = useSelector(selectIsCreatingFolder);
  const [setIsCreatingFolder] = useActions([filesystemActions.setIsCreatingFolder]);

  return (
    <div className="border-top mt-3 pt-2">
      {!isLocked && isAuthorized(account, [permissions.office.files.edit]) ? (
        <CircledButton
          type="add"
          className="text-primary fw-bold fs-12"
          label={'Add folder'}
          onClick={() => setIsCreatingFolder(true)}
          disabled={isCreating}
          style={{ width: 16, maxWidth: 16, height: 16 }}
          svgStyle={{ width: 8, height: 8 }}
        />
      ) : null}
    </div>
  );
};

export default Footer;
