import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Actions, Container } from './Remarks';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { useForm, useFormState } from 'utils/hooks';
import { hiddenField, optionField } from 'common/utils/form/fieldTypes';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAgents,
  selectAgentsItineraryPort
} from 'events/store/event-modules/agents/selectors';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import { editItinerary } from '@/common/components/voyages/itinerary/store/actions';

const Form = ({ isEdit = false, onCancel, agentId, onSubmitCallback = () => {} }) => {
  const dispatch = useDispatch();

  const agents = useSelector(selectAgents);
  const itineraryPortId = useSelector(selectAgentsItineraryPort);

  const { formState, loadValues, collectValues, resetForm } = useForm({
    id: hiddenField(),
    party: optionField({ required: true }),
    type: optionField({ required: true })
  });

  const {
    fields: { type, party },
    selectField
  } = useFormState(formState);

  const onSave = () => {
    const values = collectValues();

    if (!values) return;

    let agentsParam = null;

    if (isEdit) {
      agentsParam = [
        ...agents
          .filter(agent => agent.id !== agentId.id)
          .map(agent => ({ id: agent.id, party_id: agent.party_id, type_id: agent.type_id })),
        { id: values.id, party_id: values.party.id, type_id: values.type.id }
      ];
    } else {
      agentsParam = [
        ...agents.map(agent => ({
          id: agent.id,
          party_id: agent.party_id,
          type_id: agent.type_id
        })),
        { party_id: values.party.id, type_id: values.type.id }
      ];
    }

    dispatch(editItinerary({ id: itineraryPortId, agents: agentsParam }));
    onSubmitCallback();
    resetForm();
  };

  useEffect(() => {
    if (!isEdit || !agentId) return;

    const activeAgent = agents.find(agent => agent.id === agentId);

    if (!activeAgent) return;

    loadValues({ party: activeAgent.party, type: activeAgent.type, id: activeAgent.id });
  }, [isEdit, loadValues, agentId, agents]);

  return (
    <Container className="border-radius-11 position-relative py-2 px-3 d-flex align-items-center mb-1">
      <Col xs={5}>
        <AsyncSelector
          label="AGENT"
          getOptionLabel={option => option.full_name}
          placeholder="Select Agent"
          dataCy="select-agent"
          onChange={selectField('party')}
          loadOptions={search => getAsyncOptions(search, 'parties', { role: 'agent' })}
          defaultOptions={() => getInitialAsyncValues('parties', null, false, { role: 'agent' })}
          className="mb-1"
          {...party}
        />
      </Col>

      <Col xs={4}>
        <AsyncSelector
          isAsync
          label="TYPE"
          type="agent-types"
          placeholder="Select Type"
          dataCy="select-type"
          onChange={selectField('type')}
          className="mb-1"
          {...type}
        />
      </Col>

      <Actions>
        <InputSaveActions
          onCancel={() => {
            onCancel();
            resetForm();
          }}
          onSave={onSave}
        />
      </Actions>
    </Container>
  );
};

Form.propTypes = {
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  agentId: PropTypes.number,
  onSubmitCallback: PropTypes.func
};

export default Form;
