import TYPES, { RotateDirectionType } from './types';
import { Attachment } from '@/ts-common/types/files';
import { Reducer } from 'react';

interface State {
  files: Attachment[];
  isOpen: boolean;
  selectedFile: Attachment | null;
  rotateDirection: RotateDirectionType;
}

type FilePayload = Attachment[];

type IsOpenPayload = boolean;

type SelectedFilePayload = Attachment | null;

type RotateDirectionPayload = RotateDirectionType;

interface SetFilesAction {
  type: typeof TYPES.SET_GALLERY_FILES;
  payload: FilePayload;
}

interface SetIsOpenAction {
  type: typeof TYPES.TOGGLE_GALLERY;
  payload: IsOpenPayload;
}

interface SetSelectedFileAction {
  type: typeof TYPES.SET_SELECTED_FILE;
  payload: SelectedFilePayload;
}

interface SetRotateDirectionAction {
  type: typeof TYPES.SET_ROTATE_DIRECTION;
  payload: RotateDirectionPayload;
}

type Action = SetFilesAction | SetIsOpenAction | SetSelectedFileAction | SetRotateDirectionAction;

export const INITIAL_STATE: State = {
  files: [],
  isOpen: false,
  selectedFile: null,
  rotateDirection: 'top'
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case TYPES.SET_GALLERY_FILES:
      return {
        ...state,
        files: action.payload
      };

    case TYPES.SET_ROTATE_DIRECTION:
      return {
        ...state,
        rotateDirection: action.payload
      };

    case TYPES.SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: action.payload
      };

    case TYPES.TOGGLE_GALLERY:
      return {
        ...state,
        isOpen: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
