import { constructNameFromType } from '../preview/parts/helpers';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import { selectListOptionsFromStore } from '@/store/lists/selectors';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const WageContainerHeader = ({ className, label, currencyId, amounts }) => {
  const currencies = useSelector(state => selectListOptionsFromStore(state, 'currencies'));
  const selectCurrency = useMemo(
    () => currencies.find(currency => strToNumber(currency?.id) === strToNumber(currencyId)),
    [currencies, currencyId]
  );
  const title = constructNameFromType(label);

  return label ? (
    <div className={`w-100p cmt-2 d-flex align-items-center justify-content-between ${className}`}>
      <div className="border-radius-5 fs-10 fw-bold d-inline-flex border border-moody-blue text-moody-blue px-1">
        {title}
      </div>

      {label === 'recurring' ? (
        <div className="d-flex align-items-center bg-primary text-white fs-10 rounded px-1">
          <div className="flex-1 fw-normal">TOTAL</div>
          <div className="fw-bold ms-1">
            {selectCurrency?.label}{' '}
            {numberToStr(
              amounts.reduce((acc, cur) => acc + strToNumber(cur.pivot?.amount), 0),
              2,
              2
            )}
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default WageContainerHeader;
