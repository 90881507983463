import React, { useState } from 'react';
import Comment from './Comment';
import NewComment from './NewComment';
import spinner from 'common/assets/svg/common/spinner.svg';
import SvgRender from 'common/components/general/SvgRender';
import { useDispatch, useSelector } from 'react-redux';
import { getComments } from 'events/store/event-modules/checklist/actions';
import { showLessComments } from 'events/store/event-modules/checklist/slice';
import { selectAreChecklistActionsLocked } from 'events/store/event-modules/checklist/selectors';

const CommentsList = ({ comments, parentRef, eventId, optionId, comments_count = 0 }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const areChecklistActionsLocked = useSelector(selectAreChecklistActionsLocked);

  const getOptionsComments = async () => {
    try {
      setLoading(true);

      await dispatch(getComments({ event_id: eventId, checklist_id: optionId })).unwrap();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const onShowLess = () => {
    dispatch(showLessComments({ option: optionId }));
  };

  return (
    <div>
      {!areChecklistActionsLocked && <NewComment eventId={eventId} optionId={optionId} />}
      {comments?.map((comment, i) => (
        <div
          className={`d-flex position-relative ${i !== comments?.length - 1 ? 'pb-2' : 'pb-1'}`}
          key={comment.id}
        >
          {i !== comments?.length - 1 && <div className="line" />}
          <div className="circle" />
          <Comment comment={comment} eventId={eventId} optionId={optionId} parentRef={parentRef} />
        </div>
      ))}
      {loading ? (
        <div className="ms-3">
          <SvgRender src={spinner} style={{ width: 20, height: 20 }} />
        </div>
      ) : comments?.length === comments_count && comments_count <= 2 ? null : comments?.length ===
        comments_count ? (
        <div className="text-primary ms-3 fs-12 cursor-pointer" onClick={onShowLess}>
          Show less
        </div>
      ) : (
        <div className="text-turquoise ms-3 fs-12 cursor-pointer" onClick={getOptionsComments}>
          Load more
        </div>
      )}
    </div>
  );
};

export default CommentsList;
