/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const Type = ({ value, error, onChange, className, disabled }) => {
  return (
    <Select
      isAsync
      white
      label={'Type'}
      className={className}
      placeholder="Select Type"
      dataCy="select-event-type"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      loadOptions={search => getAsyncOptions(search, 'event-types')}
      defaultOptions={() => getInitialAsyncValues('event-types', null, false)}
      onChange={onChange}
      isClearable={false}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
};

export default Type;
