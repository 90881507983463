import useTableTopFilter from 'common/components/filters/useTableTopFilter';
import { jobEnums } from 'common/utils/fixed';

const TableHeaderName = () => {
  const scheduledTabEnabled = useTableTopFilter({ name: 'type' }, jobEnums.pms);
  const unscheduledTabEnabled = useTableTopFilter({ name: 'type' }, jobEnums.unscheduled);

  if (unscheduledTabEnabled && !scheduledTabEnabled) {
    return <span>Uplanned Maintenance Jobs</span>;
  } else if (!unscheduledTabEnabled && scheduledTabEnabled) {
    return <span>PMS Jobs</span>;
  } else {
    return <span>PMS & Unplanned Maintenance Jobs</span>;
  }
};

export default TableHeaderName;
