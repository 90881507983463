import PropTypes from 'prop-types';
import moment from 'moment';

export const TableTypes = {
  refetchData: PropTypes.func
};

export const TopType = PropTypes.shape({
  // Top table filters (tabs, datepickers, etc)
  filters: PropTypes.arrayOf(
    /* Array of filter objects */
    PropTypes.shape({
      name: PropTypes.string.isRequired, // Name of filter (ex. 'full_name', 'created_at', etc)
      operation: PropTypes.string.isRequired, // Check src/common/utils/filters/operators.js to find the valid values of each operator type
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]), // Value of the filter
      initialValue: PropTypes.any, // Use this prop to always initialize the filter with this value - no matter what you have in the URL
      options: PropTypes.array // Use this to display select options
    })
  )
});

export const ColumnsType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.oneOf('string', 'object').isRequired,
    bottomHeader: PropTypes.element, // Subheader component
    footer: PropTypes.func, // Column footer cell
    key: PropTypes.oneOf('string', 'number').isRequired,
    sortingKey: PropTypes.string,
    type: PropTypes.oneOf('string', 'number', 'collection', 'collectionMultiple', 'boolean', 'date')
      .isRequired, // Check src/common/utils/filters/operators.js
    sort: PropTypes.bool,
    truncate: PropTypes.bool, // Display the cell data that are not defined in rows, truncated - using the TextWithTooltip component
    sticky: PropTypes.bool, // Static column on horizontal scroll
    left: PropTypes.number, // This should be 0 when the first column is STICKY.
    minWidth: PropTypes.oneOf('string', 'number'), // Set minWidth & maxWidth along with the sticky prop
    maxWidth: PropTypes.oneOf('string', 'number'),
    width: PropTypes.number, // Column width (based on bootstrap grid - use values from 1-12 or auto)
    render: PropTypes.func, // (col, row) => {} - first param is the column, second one is the row.
    options: PropTypes.array, // Filter Selector options
    component: PropTypes.oneOf(PropTypes.element, PropTypes.func), // Componet to render in COLLECTION types - If not used, a default selector will be used
    componentRest: PropTypes.object, // Rest props to append in <component>
    hidden: PropTypes.bool, // Hides the column from the 'Reorder' columns modal and the Table, BUT it's still visible in the Filters selector
    hiddenOnBoard: PropTypes.bool, //Hide column when in Vessel
    canReorder: PropTypes.bool, // Hides the column from the 'Reorder' columns modal, BUT it's still visible in columns
    canFilter: PropTypes.bool, // Hides the column from Filters,
    reorderColumnHeader: PropTypes.string, // Column title shown inside reorder modal  (If header is empty),
    defaultFilterValues: PropTypes.shape({
      between: PropTypes.shape({
        yearStart: PropTypes.instanceOf(moment),
        yearEnd: PropTypes.instanceOf(moment)
      }),
      '=': PropTypes.shape({
        yearStart: PropTypes.instanceOf(moment),
        yearEnd: PropTypes.instanceOf(moment)
      }),
      '>': PropTypes.shape({
        yearStart: PropTypes.instanceOf(moment),
        yearEnd: PropTypes.instanceOf(moment)
      }),
      '<': PropTypes.shape({
        yearStart: PropTypes.instanceOf(moment),
        yearEnd: PropTypes.instanceOf(moment)
      })
    })
  })
);

export const DefaultRequestParamsType = PropTypes.shape({
  /* The initial request params are: filters, paging, sorting and visible. 
  If needed to send only some of these, use the 'defaultRequestParams' prop and set the value of the exluded keys to FALSE. */
  filters: PropTypes.bool,
  paging: PropTypes.bool,
  sorting: PropTypes.bool,
  visible: PropTypes.bool
});

export const PaginationLimitType = PropTypes.shape({
  siblingPagesRange: PropTypes.number, // limit the visible page-links (sublings)
  boundaryPagesRange: PropTypes.number // limit the visible page-links (after ellipsis)
});
