export const getDefaultSorting = key => {
  switch (key) {
    case 'captain_reports':
      return { timestamp: 'desc' };

    case 'jobs':
      return { due_date: 'desc' };

    case 'crew_evaluation_obligations':
      return { days_left: 'asc', evaluation_date: 'desc' };

    case 'mga_actions':
      return { timestamp: 'asc' };

    case 'mga_accounts':
      return { timestamp: 'asc' };

    case 'mga_inventory_items_additions':
      return { timestamp: 'asc' };

    case 'mga_inventory_items_overview':
      return { date: 'asc' };

    case 'mga_inventory_items_removals':
      return { timestamp: 'asc' };

    case 'purchasing_requisitions':
      return { id: 'desc' };

    case 'purchasing_requisitions_dlv':
    case 'purchasing_requisitions_ev':
      return { code: 'desc' };

    case 'pms_jobs_list':
      return { due_date: 'asc' };

    case 'events':
      return { started_at: 'desc' };

    default:
      return {};
  }
};

export const perPageDefaults = {
  pms_jobs_list: 100
};

export const getExportDefaultRequestParamsExcluded = key => {
  switch (key) {
    default:
      return [];
  }
};
