import SvgRender from 'common/components/general/SvgRender';
import ChatEmpty from 'common/assets/svg/common/chat-empty.svg';

const EmptyLoader = () => {
  return (
    <div className="chatbox__empty-loader position-relative w-100p h-100p">
      <div className="empty-state-title">Begin....conversation</div>
      <SvgRender className="empty-state-icon" src={ChatEmpty} />
    </div>
  );
};

export default EmptyLoader;
