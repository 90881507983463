import { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const PortSelector = ({ filter, onChange, isMulti, listParams, styled, className, ...rest }) => {
  const [selectedPorts, setSelectedPorts] = useState([]);

  const getValue = () => {
    if (isMulti) {
      if (filter?.value)
        return selectedPorts
          .filter(el => filter.value.map(Number).includes(el.id))
          .filter(r => filter.value.some(v => v == r.id));
      else return [];
    } else {
      return filter?.value;
    }
  };

  useFilterSelector(
    filter?.value,
    { list: 'ports', listParams },
    { selected: selectedPorts, setSelected: setSelectedPorts }
  );

  const SelectTag = styled ? StyledSelect : Select;

  return (
    <SelectTag
      className={className || `mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={getValue()}
      isAsync
      isMulti={isMulti}
      loadOptions={search => getAsyncOptions(search, 'ports', listParams)}
      defaultOptions={() => getInitialAsyncValues('ports', null, null, listParams)}
      onChange={(selected, options) => {
        setSelectedPorts(isMulti ? selected : [selected]);
        onChange(
          { value: selected ? (isMulti ? selected.map(s => s.id) : selected) : '' },
          options
        );
      }}
      {...rest}
    />
  );
};

export default PortSelector;
