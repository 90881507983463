import _uniqBy from 'lodash/uniqBy';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import _isString from 'lodash/isString';

import * as d3 from 'd3';
import { getYAxisKeyFromPlot } from 'common/components/graph/base/helpers/_functions';

const ignorePlotTypes = ['timemarker'];

const Y_AXIS_PADDING_PERCENTAGE = 10;

export const getDomainWithPadding = (domain, getYAxisOption) => {
  const paddingPercentage = getYAxisOption('customPaddingPercentage') || Y_AXIS_PADDING_PERCENTAGE;
  const padding = ((domain[1] - domain[0]) * paddingPercentage) / 100;
  return [domain[0] - padding, domain[1] + padding];
};

export const getYAxes = (plots, axisConfig) => {
  if (!plots?.length && axisConfig?.customDomain) {
    return [{ yAxisKey: getYAxisKeyFromPlot(axisConfig), hideAxis: axisConfig?.hideAxis }];
  }

  const uniq = _uniqBy(
    plots
      .filter(plot => {
        const isArray = _isArray(plot.type);

        if (isArray) {
          return !plot.type.every(type => ignorePlotTypes.includes(type));
        } else {
          return !ignorePlotTypes.includes(plot.type);
        }
      })
      .map(plot => ({ yAxisKey: getYAxisKeyFromPlot(plot), hideAxis: plot.hideAxis })),
    plot => (_isString(plot.yAxisKey) ? plot.yAxisKey.toLowerCase() : plot.yAxisKey)
  );

  return uniq;
};

export const getNumOfAxesOnSide = (side, axesLength) => {
  return Math[side === 'left' ? 'ceil' : 'floor'](axesLength / 2);
};

export const getYAxesSpacing = (side = 'left', axisSpacing, axesLength) => {
  const amountOfAxesOnThatSide = getNumOfAxesOnSide(side, axesLength);
  return (amountOfAxesOnThatSide - 1) * axisSpacing;
};

// The following function gets the total width of the axes located on each side.
// For this to work, the graph needs to be already rendered one time so we intenionally rerender it the first time.
export const getYAxesOffset = (side = 'left', axesDimensions, axesLength) => {
  const isLeftSide = side === 'left';

  const amountOfAxesOnThatSide = getNumOfAxesOnSide(side, axesLength);

  let totalWidth = 0;

  for (let i = 0; i < amountOfAxesOnThatSide; i++) {
    const sideAxisIndex = i * 2 + (isLeftSide ? 0 : 1);
    const value = axesDimensions[sideAxisIndex];

    if (value) {
      totalWidth += value;
    }
  }

  return totalWidth;
};

export const getLeftOffset = ({ axesDimensions, yAxes, getGraphOption }) => {
  return (
    getYAxesOffset('left', axesDimensions, yAxes.length) +
    getYAxesSpacing('left', getGraphOption('axisSpacing'), yAxes.length)
  );
};

export const getRightOffset = ({ axesDimensions, yAxes, getGraphOption }) => {
  return (
    getYAxesOffset('right', axesDimensions, yAxes.length) +
    getYAxesSpacing('right', getGraphOption('axisSpacing'), yAxes.length)
  );
};

export const findYAxisDimension = ({
  width,
  index,
  getGraphOption,
  getGraphStyle,
  leftOffset,
  rightOffset,
  axesDimensions
}) => {
  const isLeftSide = index % 2 === 0;

  const axisSpacing = getGraphOption('axisSpacing');

  const graphStart = getGraphStyle('marginLeft') + leftOffset;
  const graphEnd = width - getGraphStyle('marginRight') - rightOffset;

  const axesWidth = getYAxesOffset(isLeftSide ? 'left' : 'right', axesDimensions, index);
  const axesSpacing = getYAxesSpacing(isLeftSide ? 'left' : 'right', axisSpacing, index + 2);

  if (isLeftSide) {
    return graphStart - (axesWidth + axesSpacing);
  }

  return graphEnd + (axesWidth + axesSpacing);
};

export const getYAxisWithDirection = (y, yIndex, isAxisOnLeft) => {
  if (isAxisOnLeft) {
    return d3.axisLeft(y[yIndex].axis);
  }

  return d3.axisRight(y[yIndex].axis);
};

export const getYAxisTitleFn = ({
  g,
  axis,
  index,
  isAxisOnLeft,
  getYAxisOption,
  yAxisTicksWidth,
  height
}) => {
  const title = g
    .select(`.y-axis-title-${index}`)
    .classed(isAxisOnLeft ? 'y-axis-title--left' : 'y-axis-title--right', true)
    .classed('axis-label', true)
    .attr('y', getYAxisOption('titleFontSize') || 15) // Line height
    .attr('fill', 'currentColor')
    .attr('text-anchor', isAxisOnLeft ? 'end' : 'start')
    .attr('transform', 'translate(0, 1) rotate(-90)  translate(0, -1)')
    .text(getYAxisOption(`title`, index) || axis.yAxisKey);

  const titleBBox = _get(title, '_groups[0][0]')
    ? title._groups[0][0].getBoundingClientRect()
    : { height: 0, width: 0 };

  const titleWidth = titleBBox.width;
  const titleHeight = titleBBox.height;

  const titlePadding = getYAxisOption(`titlePadding`, index);

  const titleXPos =
    (isAxisOnLeft ? -yAxisTicksWidth : yAxisTicksWidth) -
    (isAxisOnLeft ? titleWidth : -titleWidth) -
    (isAxisOnLeft ? titlePadding : -titlePadding);
  const titleYPos = height / 2 - titleHeight / 2;

  g.select(`.y-axis-title__container-${index}`).attr(
    'transform',
    `translate(${titleXPos}, ${titleYPos})`
  );

  return title;
};

export const getXAxisTitleFn = (g, getXAxisOption, xScale) => {
  const title = g
    .select('.x-axis-title')
    .attr('font-size', getXAxisOption('titleFontSize') || 12)
    .attr('fill', 'currentColor')
    .text(getXAxisOption('title') || '')
    .attr(
      'transform',
      `translate(${xScale.range()[1] / 2}, ${getXAxisOption('titleMarginTop') || 40})`
    );

  return title;
};

export const maskGraphClipPath = ({
  element,
  graphUID,
  leftOffset,
  getGraphStyle,
  innerGraphWidth,
  innerGraphHeight
}) => {
  element
    .select(`#mask-${graphUID} rect`)
    .attr('x', leftOffset + getGraphStyle('marginLeft'))
    .attr('y', getGraphStyle('marginTop'))
    .attr('width', innerGraphWidth)
    .attr('height', innerGraphHeight);

  element.select('.overflow-container').attr('clip-path', `url(#mask-${graphUID})`);
};

export const getCustomDomainYBasedOnValues = ({
  yScale,
  dataFromAllPlots,
  disabledPlots,
  getYAxisOption
}) => {
  const customYDomain = getYAxisOption('customDomain') || [];

  if (customYDomain?.length) return customYDomain;

  let totalMinY = Infinity;
  let totalMaxY = -Infinity;

  dataFromAllPlots.forEach(datum => {
    const mappedDisabledPlots = Object.assign(
      {},
      ...disabledPlots.map(plotIndex => ({ [plotIndex]: true }))
    );

    if (mappedDisabledPlots[datum.plotIndex]) return;

    const yInPX = yScale(datum.y);

    if (yInPX && yInPX < totalMinY) totalMinY = yInPX;
    if (yInPX && yInPX > totalMaxY) totalMaxY = yInPX;
  });

  let minY = yScale.invert(totalMaxY);
  let maxY = yScale.invert(totalMinY);

  if (minY.toFixed(6) === maxY.toFixed(6)) {
    minY -= 1;
    maxY += 1;
  }

  return getDomainWithPadding([minY, maxY], getYAxisOption);
};
