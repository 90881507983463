import TYPES from './types';
import { get, download } from 'utils/api';

export const getVesselInfo = () => dispatch => {
  dispatch({ type: TYPES.GET_VESSEL_INFO.START });

  return get('/vessel/details')
    .then(res => {
      dispatch({
        type: TYPES.GET_VESSEL_INFO.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_VESSEL_INFO.ERROR, payload: error });

      throw error;
    });
};

// For core-onboard compatibility, ignore
export const updateVessel = () => () => null;

export const getVesselCiiReferenceYearly = params => dispatch => {
  dispatch({
    type: TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.START,
    payload: { params, loading: true }
  });

  const { id, ...rest } = params;

  return get(`/vessels/${id}/cii-reference`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.SUCCESS,
        payload: response?.data
      });

      return response?.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.ERROR,
        payload: { error, loading: false }
      });
      throw error;
    });
};

// For core-onboard compatibility, ignore
export const createPool = () => () => null;
export const updatePool = () => () => null;
export const deletePool = () => () => null;
export const createPoolPoint = () => () => null;
export const createPoolMonthlyResult = () => () => null;
export const changePoolDrawerStatus = () => () => null;
export const setActivePool = () => () => null;
