import React, { useCallback } from 'react';
import { useState } from 'react';
import Box from './Box';

import { useDispatch } from 'react-redux';
import { getInventorySurveysDaysLeft } from 'store/dashboard/inventory-surveys-days-left/actions';
import { useEffect } from 'react';
import ContentDueDate from './ContentDueDate';
import inventoryIcon from 'common/assets/svg/accounting/inventory.svg';
import permissions from 'common/utils/permissions/constants';
import { displayDate } from 'common/utils/dates';
import paths from 'routing/routes/_paths';

const InventorySurveysDaysLeft = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  const dueDate = displayDate(data?.due_date, { time: true });

  const color =
    data?.is_missing || data?.is_overdue ? 'red' : data?.becoming_overdue ? 'orange' : 'primary';

  const permissionsInfo = [permissions.onboard.dashboard.inventory_survey_overdue.card];

  const item = {
    header: 'Inventory Survey',
    icon: inventoryIcon,
    subheader: `Due Date: ${dueDate || '-'}`,
    content: (
      <ContentDueDate
        path={paths.pms_inventory}
        isMissing={data?.is_missing}
        remainingTime={data?.days_left}
        color={color}
      />
    ),
    key: `inventory_survey`,
    size: 'md',
    className: `running-hours-due_box text-${color}`,
    permissions: permissionsInfo
  };

  const initialize = useCallback(async () => {
    try {
      const res = await dispatch(getInventorySurveysDaysLeft()).unwrap();

      setData(res);
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return <Box key={item.key} {...item} />;
};

export default InventorySurveysDaysLeft;
