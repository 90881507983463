import PropTypes from 'prop-types';

import { useDrawer } from 'common/components/drawer';
import { useEffect, useState } from 'react';
import PurchaseCaseDrawer from './PurchaseCaseDrawer';
import LinkedPurchaseCases from './LinkedPurchaseCases';
import LinkedTabModuleActions from '../_components/LinkedTabModuleActions';
import CircledButton from 'common/components/buttons/CircledButton';

const LinkedPurchasingRequisitionEntities = ({
  vessel,
  linkedPurchasingCases,
  addLinkedPurchasingCase,
  removeLinkedPurchasingCase,
  onTogglePurchasingCaseDrawer,
  cancelTabAction,
  tabLabel
}) => {
  const drawer = useDrawer(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(!linkedPurchasingCases?.length);

  useEffect(() => {
    if (onTogglePurchasingCaseDrawer) onTogglePurchasingCaseDrawer(drawer.isOpen);
  }, [drawer.isOpen, onTogglePurchasingCaseDrawer]);

  return (
    <>
      <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <div className="d-flex align-items-center height-20">
          <div className="flex-1 form-label mb-0">LINKED PURCHASING CASES</div>
          {tabLabel ? (
            <LinkedTabModuleActions
              showDeleteButton={!showCancelButton && isHovering}
              showCancelButton={showCancelButton}
              onCancel={() => cancelTabAction(tabLabel)}
              onDelete={() => removeLinkedPurchasingCase()}
            />
          ) : null}
        </div>

        <LinkedPurchaseCases
          linkedPurchasingCases={linkedPurchasingCases}
          removeLinkedPurchasingCase={index => {
            removeLinkedPurchasingCase(index);
            setShowCancelButton(false);
          }}
        />
      </div>

      <CircledButton
        className="mt-1"
        type="link"
        label=" Link to Purchasing Case"
        onClick={drawer.open}
        svgStyle={{ width: 10, height: 10 }}
        style={{ width: 20, height: 20 }}
      />

      <PurchaseCaseDrawer
        vessel={vessel}
        drawer={drawer}
        onLink={value => {
          addLinkedPurchasingCase(value);
          setShowCancelButton(false);
        }}
      />
    </>
  );
};

LinkedPurchasingRequisitionEntities.propTypes = {
  vessel: PropTypes.object,
  linkedPurchasingCases: PropTypes.array,
  addLinkedPurchasingCase: PropTypes.func,
  removeLinkedPurchasingCase: PropTypes.func,
  tabLabel: PropTypes.string,
  cancelTabAction: PropTypes.func
};

export default LinkedPurchasingRequisitionEntities;
