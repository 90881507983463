import React from 'react';

import { Row, Col } from 'reactstrap';

import DateInput from 'common/components/form/inputs/date';
import PropTypes from 'prop-types';

const Duty = ({ selectField, fields }) => {
  return (
    <Row xs={12} className="d-flex">
      <Col xs={4}>
        <DateInput
          className="mb-0"
          label="Duty suspended"
          value={fields.duty_suspended_at.value}
          from={fields.duty_suspended_at.value}
          onChange={selected => selectField('duty_suspended_at')(selected)}
        />
      </Col>
      <Col xs={4}>
        <DateInput
          className="mb-0"
          label="Duty resumed"
          value={fields.duty_resumed_at.value}
          from={fields.duty_resumed_at.value}
          onChange={selected => selectField('duty_resumed_at')(selected)}
        />
      </Col>
    </Row>
  );
};

Duty.propTypes = {
  selectField: PropTypes.func,
  fields: PropTypes.object
};

export default Duty;
