import { FC } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const BoxShadowButton: FC<any> = ({
  onClick,
  type,
  color,
  className,
  children,
  disabled = false,
  innerShadow = true,
  size = 'lg'
}) => {
  return (
    <Button
      className={`box-shadow-button box-shadow-button__${color ? color : 'default'} ${
        innerShadow ? 'box-shadow-button__inner_shadow' : ''
      } ${color === 'primary' ? 'border-1' : ''} d-inline-flex align-items-center ${
        className || ''
      } ${disabled ? 'disabled' : ''}`}
      onClick={e => (disabled ? {} : onClick(e))}
      color=""
      type={!type ? 'button' : type}
      size={size}
    >
      {children}
    </Button>
  );
};
export default BoxShadowButton;

BoxShadowButton.propTypes = {
  color: PropTypes.oneOf(['flat', 'yellow', 'primary', 'turquoise']),
  size: PropTypes.oneOf(['sm', 'lg'])
};
