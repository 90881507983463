import { useEffect } from 'react';
import useRouter from 'use-react-router';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import { useSelector } from 'react-redux';
import {
  selectManualId,
  selectManualVersions,
  selectCurrentVersion,
  selectComparedAtVersion
} from 'manuals/store/selectors';

const useVersionAcknowledge = () => {
  const manualId = useSelector(selectManualId);
  const versions = useSelector(selectManualVersions);
  const currentVersion = useSelector(selectCurrentVersion);
  const comparedAtVersion = useSelector(selectComparedAtVersion);

  const { location, history } = useRouter();

  useEffect(() => {
    if (
      manualId &&
      currentVersion?.status === 'active' &&
      !currentVersion?.current_user_acknowledged &&
      !comparedAtVersion
    ) {
      /* When in active version, check if the current user has acknowledged it.
        If not, find the previous released version, and compare them.
      */
      const previousVersions = versions.filter(v => v.status === 'past');

      if (previousVersions.length) {
        history.push({ pathname: location.pathname, search: `compared=${previousVersions[0].id}` });
      }
    }
  }, [manualId, currentVersion?.status]);

  useUpdateEffect(() => {
    if (
      currentVersion?.status === 'active' &&
      currentVersion?.current_version_acknowledge_trigger &&
      history.location.search.includes('compared')
    ) {
      // When the active version is acknowledged, remove compared search param

      history.push({ pathname: location.pathname, search: '' });
    }
  }, [currentVersion?.current_version_acknowledge_trigger]);
};

export default useVersionAcknowledge;
