import { FC } from 'react';
import { Tabs } from 'common/components/general/Tabs';
import { NavItem } from 'reactstrap';
import { useAppDispatch } from '@/store/hooks';
import { setEvaluationEditing } from '@/crew/store/evaluations/profile/actions-ts.ts';

type TabViewType = 'received' | 'given';

type HeaderTabsProps = {
  setType: (key: string) => void;
  type: TabViewType;
};

const HeaderTabs: FC<HeaderTabsProps> = ({ setType, type }) => {
  const dispatch = useAppDispatch();

  const onClick = (view: TabViewType) => {
    setType(view);
    dispatch(setEvaluationEditing(view));
  };

  return (
    <Tabs pills>
      <NavItem active={type === 'received'} onClick={() => onClick('received')}>
        <a className="cursor-pointer nav-link fs-14">received</a>
      </NavItem>
      <NavItem active={type === 'given'} onClick={() => onClick('given')}>
        <a className="cursor-pointer nav-link fs-14">given</a>
      </NavItem>
    </Tabs>
  );
};

export default HeaderTabs;
