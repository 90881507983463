import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import { useActions } from 'utils/hooks';
import PostsWithoutImage from './posts-without-image';
import Announcements from './announcements';
import PostsWithImage from './posts-with-image';
import PreviewPost from './preview-post';
import EmptyStatePage from './_components/EmptyStatePage';
import newsAndAnnouncementsIcon from 'common/assets/svg/common/news-announcements.svg';
import newsIcon from 'common/assets/svg/common/news.svg';

import { selectPostCategoryPosts, selectPostCategoryAnnouncements } from 'store/lists/selectors';
import {
  selectPostsWithoutImageData,
  selectAnnouncementsData,
  selectPostsWithImageData,
  selectPostsWithImageCurrentPage,
  selectAnnouncementsIsLoading,
  selectPostsWithoutImageIsLoading,
  selectPostsWithImageIsLoading
} from './store/selectors';

import * as listActions from 'store/lists/actions';
import * as postsActions from 'common/components/news-and-announcements/store/actions';

import moment from 'moment';
import AnnouncementsLoader from 'common/components/news-and-announcements/announcements/Loader';
import PostsWithImageLoader from 'common/components/news-and-announcements/posts-with-image/Loader';
import PostsWithoutImageLoader from 'common/components/news-and-announcements/posts-without-image/Loader';

const NewsAndAnnouncements = () => {
  const [activePost, setActivePost] = useState(null);
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const postsWithoutImageData = useSelector(selectPostsWithoutImageData);
  const announcementsData = useSelector(selectAnnouncementsData);
  const postsWithImageData = useSelector(selectPostsWithImageData);
  const currentPage = useSelector(selectPostsWithImageCurrentPage);

  const announcementsIsLoading = useSelector(selectAnnouncementsIsLoading);
  const postsWithoutImageIsLoading = useSelector(selectPostsWithoutImageIsLoading);
  const postsWithImageIsLoading = useSelector(selectPostsWithImageIsLoading);

  const categoryPost = useSelector(selectPostCategoryPosts);
  const categoryAnnouncement = useSelector(selectPostCategoryAnnouncements);

  const isOnBoard = useSelector(state => state.isOnBoard);

  const [getPosts, getPostsWithImage] = useActions([
    postsActions.getPosts,
    postsActions.getPostsWithImage
  ]);

  const containerRef = useRef(null);

  const initListPosts = useCallback(
    async (loadMore, currentPage, categoryId) => {
      try {
        if (categoryPost?.id) await getPostsWithImage(loadMore, currentPage, categoryId);
      } catch (e) {
        console.error(e);
      }
    },
    [getPostsWithImage, categoryPost]
  );

  const initSliderPosts = useCallback(
    async (params, key) => {
      try {
        await getPosts(params, key);
      } catch (e) {
        console.error(e);
      }
    },
    [getPosts]
  );

  useEffect(() => {
    if (currentPage === 1) {
      initListPosts({
        loadMore: false,
        currentPage: 1,
        categoryId: categoryPost?.id
      });
    }
  }, [initListPosts, currentPage, categoryPost?.id]);

  useEffect(() => {
    let params = {
      paging: {
        per_page: 5,
        current_page: 1
      }
    };

    if (categoryPost?.id) {
      params = {
        ...params,
        filters: [
          { name: 'category_id', operation: 'oneOf', value: [categoryPost?.id] },
          { name: 'has_image', operation: '=', value: false }
        ]
      };

      initSliderPosts({ params, key: 'postsWithoutImage' });
    }

    if (categoryAnnouncement?.id) {
      params = {
        ...params,
        filters: [{ name: 'category_id', operation: 'oneOf', value: [categoryAnnouncement?.id] }]
      };

      initSliderPosts({ params, key: 'announcements' });
    }
  }, [getPosts, categoryPost, categoryAnnouncement, initSliderPosts]);

  useEffect(() => {
    fetchListOptions('post-categories');
  }, [fetchListOptions]);

  return (
    <NewsAndAnnouncementsContainer
      ref={containerRef}
      className={`news-and-announcements height-100 gray-scrollbar overflow-x-hidden overflow-y-scroll pt-5 ${
        !postsWithoutImageData?.length && !announcementsData?.length && !postsWithImageData?.length
          ? 'd-flex flex-column'
          : ''
      }`}
    >
      {activePost ? (
        <PreviewPost active={activePost} setActivePost={setActivePost} />
      ) : (
        <>
          <div className="ps-4">
            <div className="fs-16">{moment().format('MMM DD, YYYY')}</div>
            <div className={`fs-28 fw-bold mt-2 mb-3 ${isOnBoard ? 'text-primary' : 'text-dark'}`}>
              News
            </div>
          </div>

          <>
            {announcementsIsLoading ? (
              <AnnouncementsLoader />
            ) : !announcementsData?.length ? (
              <EmptyStatePage
                label="There are no announcements at the moment."
                icon={newsAndAnnouncementsIcon}
                className="empty-state-page"
              />
            ) : (
              <>
                <Announcements setActivePost={setActivePost} />
              </>
            )}

            {postsWithoutImageIsLoading ? (
              <PostsWithoutImageLoader />
            ) : !postsWithoutImageData?.length ? null : (
              <>
                <PostsWithoutImage setActivePost={setActivePost} />
              </>
            )}

            {postsWithImageIsLoading ? (
              <PostsWithImageLoader />
            ) : !postsWithImageData?.length ? (
              <EmptyStatePage
                label="There are no news at the moment."
                icon={newsIcon}
                className="mb-4 flex-1"
              />
            ) : (
              <PostsWithImage containerRef={containerRef} setActivePost={setActivePost} />
            )}
          </>
        </>
      )}
    </NewsAndAnnouncementsContainer>
  );
};

const NewsAndAnnouncementsContainer = styled.div`
  background-color: ${variables.formShades1};
  min-height: 100%;
  max-height: 100%;

  .empty-state-page {
    min-height: 8.75rem;
    max-height: 8.75rem;
  }
`;

export default NewsAndAnnouncements;
