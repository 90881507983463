import { createSelector } from 'reselect';

const selectCaptainReportsAlarmsReducer = state => state.captainReportsAlarms;

export const selectCaptainReportsAlarms = createSelector(
  selectCaptainReportsAlarmsReducer,
  alarms => {
    return alarms.data;
  }
);
