import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_TRIP'),
  OPEN_TRIP_DRAWER: 'OPEN_TRIP_DRAWER',
  CLOSE_TRIP_DRAWER: 'CLOSE_TRIP_DRAWER',
  SET_TRIP_PREVENTION_MODAL: 'SET_TRIP_PREVENTION_MODAL',
  UPDATE_TRIP_PARTY: 'UPDATE_TRIP_PARTY',
  COLLAPSE_TRIP: 'COLLAPSE_TRIP'
};

export default TYPES;
