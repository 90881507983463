import * as d3 from 'd3';
import { getDomainWithPadding } from 'common/components/graph/base/helpers/axes/helpers';
import _get from 'lodash/get';
import { hasValue } from '@/common/utils/numbers';

const isVisible = (n, svgVisibleRect) => {
  var result =
    n.x + n.width > svgVisibleRect.left &&
    n.x + n.width < svgVisibleRect.right &&
    n.y > svgVisibleRect.top &&
    n.y < svgVisibleRect.bottom;

  return result;
};

const DEFAULT_PADDING = 0.2;
const DEFAULT_RADIUS = 0;
const DEFAULT_ROTATION = -45;

const renderPlot = ({
  plotID,
  plot,
  xFn,
  element,
  getDimensions,
  getGraphStyle,
  graphUID,
  x,
  getYAxisOption,
  events,
  schema
}) => {
  const { height } = getDimensions();
  const yAxisValueKey = _get(schema, 'yAxis');
  const shouldTruncateTickNames =
    typeof getGraphStyle('shouldTruncateTickNames') == 'boolean'
      ? getGraphStyle('shouldTruncateTickNames')
      : true;

  const truncateCharacterLength = hasValue(getGraphStyle('truncateCharacterLength'))
    ? getGraphStyle('truncateCharacterLength')
    : 11;

  const margin = +getGraphStyle('marginTop') + +getGraphStyle('marginBottom');
  const data = plot.data;
  const graphHeight = height - margin;

  const svg = d3.select(document.getElementById(`svg-${graphUID}`));
  const g = element.selectAll(`#${plotID}`);
  const xAxis = svg.select('.x-axis');
  const yAxis = svg.select('.y-axis');

  const customYDomain = getYAxisOption('customDomain', 0) || [];
  const minY =
    customYDomain[0] !== 0 && !customYDomain[0] ? d3.min(data, d => +d.value) : customYDomain[0];
  const maxY =
    customYDomain[1] !== 0 && !customYDomain[1] ? d3.max(data, d => +d.value) : customYDomain[1];
  const hasVariety = minY !== maxY;

  const innerGraphHeight = height - getGraphStyle('marginBottom');
  const isHorizontal = getYAxisOption('scaleType') === 'scaleBand';

  const xScale = x;
  const padding = hasValue(getGraphStyle('barPadding'))
    ? getGraphStyle('barPadding')
    : DEFAULT_PADDING;

  const horizontalYScale = d3
    .scaleBand()
    .domain(data.map(d => d[yAxisValueKey]))
    .range([innerGraphHeight, getGraphStyle('marginTop')])
    .padding(padding);

  const verticalYScale = d3
    .scaleLinear()
    .range([graphHeight, 0])
    .domain(getDomainWithPadding([minY, hasVariety ? maxY : maxY + 1], getYAxisOption));

  const yScale = isHorizontal ? horizontalYScale : verticalYScale;

  const radius = getGraphStyle('barRadius') || DEFAULT_RADIUS;

  const tickRotation = hasValue(getGraphStyle('tickRotation'))
    ? getGraphStyle('tickRotation')
    : DEFAULT_ROTATION;

  (isHorizontal ? yAxis : xAxis).call(g =>
    g
      .selectAll('.tick text')
      .data(data)
      .style('transform', `rotate(${tickRotation}deg)`)
      .style('transform-origin', `-2px 10px`)
      .attr('text-anchor', 'end')
      .each(function (d) {
        // const svgVisibleRect = svg.node().getBoundingClientRect();
        if (!shouldTruncateTickNames) return null;

        d3.select(this).call(t => {
          const text = d.tooltipName || t.text();
          if (text?.length > truncateCharacterLength) {
            t.text(text.substring(0, truncateCharacterLength - 1) + '...');

            t.on('mouseover', () => {
              const tooltip = d3
                .select('body')
                .append('div')
                .attr('class', 'bar-chart__tooltip')
                .style('position', 'absolute')
                .style('pointer-events', 'none')
                .style('opacity', 0)
                .html(text);

              const { x, y } = this.getBoundingClientRect();

              tooltip
                .style('background-color', '#22242a')
                .style('color', '#fff')
                .style('padding', '8px')
                .style('border-radius', '5px')
                .style('font-size', '12px')
                .style('left', `${x + 10}px`)
                .style('top', `${y - 24}px`)
                .style('opacity', 1);
            }).on('mouseleave', () => {
              d3.select('.bar-chart__tooltip').remove();
            });
          }
        });
      })
  );

  (isHorizontal ? yAxis : xAxis).call(g =>
    g.selectAll('.tick').each(function () {
      d3.select(this).call(t => {
        if (!isVisible(t.node().getBoundingClientRect(), svg.node().getBoundingClientRect())) {
          t.classed('d-none', true);
        } else {
          t.classed('d-none', false);
        }
      });
    })
  );

  (isHorizontal ? yAxis : xAxis).call(g =>
    g
      .selectAll('.tick')
      .data(data)
      .attr('display', d => (d.hideTickName ? 'none' : 'block'))
  );

  g.selectAll('.bar')
    .data(data)
    .join('rect')
    .attr('rx', radius)
    .attr('class', 'bar')
    .style('transform', !isHorizontal ? 'scale(1, -1)' : '')
    // .style('clip-path', `inset(${radius}px 0px 0px 0px)`) // clip bottom radius
    .attr(
      'y',
      isHorizontal
        ? d => yScale(d[yAxisValueKey]) - (d.yPosition || 0)
        : -graphHeight - +getGraphStyle('marginTop') - radius
    )
    .attr('x', isHorizontal ? 0 : d => xFn(d))
    .attr(
      'height',
      isHorizontal
        ? yScale.bandwidth()
        : d => (d.value > 0 ? graphHeight - yScale(d.value) - radius : 0)
    )
    // We need to know where the starting width of the bar is, so we get the starting point of X axis
    .attr('transform', isHorizontal ? `translate(${x.range()[0]},0)` : '')
    // Here we remove that extra offset that we added above from the bar width
    .attr('width', isHorizontal ? d => xScale(d.value) - x.range()[0] : xScale.bandwidth())
    .attr('fill', d => d.color)
    .transition();

  const dispatchHoveringEvent = dataPoint => {
    // We have a custom event for the tooltip so all we need to do is just to create it and pass the datapoint in the detail property
    const currentlyHovering = dataPoint || null;

    const event = new CustomEvent(`graph-hovering--${events.eventsID}`, {
      detail: currentlyHovering
    });
    // dispatch the event to the document
    document.dispatchEvent(event);
  };

  const onMouseOver = dataPoint => dispatchHoveringEvent(dataPoint);
  const onMouseOut = () => dispatchHoveringEvent(null);

  if (events)
    element
      .selectAll('.bar')
      .on('mouseover', (_, dataPoint) => onMouseOver(dataPoint))
      .on('mouseout', (_, dataPoint) => onMouseOut(dataPoint));
};

const renderComponent = ({ plotID }) => {
  return <g id={plotID} key={plotID}></g>;
};

const bar = {
  renderPlot,
  renderComponent
};

export default bar;
