import { selectIsCreate } from 'events/store/events/selectors';

const selectParticipants = state => state.eventModules.participants;

const selectIsOnboard = state => state.isOnBoard;

export const selectParticipantsData = state => selectParticipants(state).data;
export const selectParticipantsLoading = state => selectParticipants(state).loading;
export const selectParticipantsAddActionIsLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);

  return isOnBoard || isCreate;
};
