import React, { useRef } from 'react';
import Row from './Row';
import AddRowButton from './AddRowButton';

import useHorizontalScroll from 'common/utils/hooks/useHorizontalScroll';
import {
  getFormFieldParams,
  getFormFieldID,
  getFormFieldTypeConfig
} from 'common/components/forms/digital/state-utils';
import _get from 'lodash/get';

const Body = ({
  formField,
  formFieldId,
  formState,
  setFormState,
  formValues,
  formPreviousValues,
  disabled,
  previewMode,
  dynamic,
  formFields,
  headerFormState,
  formSubmission,
  isFormForVessel
}) => {
  const containerRef = useRef(null);
  const { onMouseDown, onMouseUp, onMouseMove } = useHorizontalScroll(containerRef);
  const { rows } = getFormFieldParams(formField);
  const { cellWidth } = getFormFieldTypeConfig(formField);

  const tableFormFieldID = getFormFieldID(formField);
  const visibleRows = dynamic
    ? previewMode
      ? _get(formValues, `[${tableFormFieldID}].row_count`, 1)
      : _get(formState, `[${tableFormFieldID}].value.row_count`, 1)
    : 1;

  const removeTableRow = () => {
    setFormState(prev => ({
      ...prev,
      [formFieldId]: {
        value: { row_count: visibleRows - 1 },
        error: ''
      }
    }));
  };

  return (
    <div className="table-form-field-submission__body">
      <div className="table-form-field-submission__body-container">
        <div
          className="table-form-field-submission__body-scrollable"
          ref={containerRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <Row
            formField={formField}
            index={0}
            dynamic={dynamic}
            previewMode={previewMode}
            cellWidth={cellWidth}
            heading
            formFields={formFields}
            headerFormState={headerFormState}
            formSubmission={formSubmission}
            isFormForVessel={isFormForVessel}
          />
          {rows.map(index =>
            dynamic && index + 1 > visibleRows ? null : (
              <Row
                formField={formField}
                dynamic={dynamic}
                key={index}
                index={index}
                disabled={disabled}
                formState={formState}
                setFormState={setFormState}
                formValues={formValues}
                formPreviousValues={formPreviousValues}
                previewMode={previewMode}
                removeTableRow={removeTableRow}
                isLast={index + 1 === visibleRows}
                isFirst={index === 0}
                cellWidth={cellWidth}
                formFields={formFields}
                headerFormState={headerFormState}
                formSubmission={formSubmission}
                isFormForVessel={isFormForVessel}
              />
            )
          )}
        </div>
      </div>

      {dynamic && !previewMode && !disabled ? (
        <AddRowButton
          formField={formField}
          formFieldId={formFieldId}
          formState={formState ? formState[formFieldId] : null}
          setFormState={setFormState}
          formValues={formValues}
          visibleRows={visibleRows}
        />
      ) : null}
    </div>
  );
};

export default Body;
