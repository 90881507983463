import TYPES from 'captain-reports/store/types';
import {
  parseTemplateVisibleFields,
  filterTemplateValidatedFields,
  filterVesselValidatedFields
} from 'captain-reports/templates/config/_helpers';
import { multiTabsSections } from 'captain-reports/templates/config/_constants';
import { LOCATION_CHANGE } from 'connected-react-router';

import paths from 'routing/routes/_paths';

const INITIAL_STATE = {
  data: null,
  totals: {},
  navigation: {},
  previousReport: {},
  futureReports: [],
  futureReportsIsFetching: false,
  selectedFutureReports: [],
  previousNavigational: {},
  previousBsViscosities: [],
  previousBsBioFuels: [],
  previousVesselSystems: [],
  vessel: {},
  onBoard: false,
  templateFieldTooltips: {},
  templateVisibleFields: {},
  templateFieldValidations: {},
  templateHiddenFromVesselFieldValidations: {},
  aerLegAverage: null,
  aerLastReport: null,
  template: {},
  openedSections: {},
  activeSectionsTab: {},
  invalidReport: false,
  edittingApprovedReport: false,
  formInitialized: false,
  formValidationTry: 0,
  updateSectionProgress: false,
  hasRequiredValidationError: false,
  hasTemplateValidationError: false,
  lastUpdatedGeneratorTabIndex: null
};

const reportReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_REPORT_VESSEL:
      return {
        ...state,
        formValidationTry: 0,
        formInitialized: true,
        vessel: payload.vessel,
        openedSections: {},
        activeSectionsTab: Object.keys(multiTabsSections).reduce((acc, key) => {
          acc[key] = 0;

          return acc;
        }, {})
      };

    case TYPES.SET_REPORT_DATA:
      return {
        ...state,
        data: payload,
        hasRequiredValidationError: false,
        hasTemplateValidationError: false
      };

    case TYPES.SET_REPORT_TEMPLATE_TOOLTIPS:
      return {
        ...state,
        templateFieldTooltips:
          payload && payload.length
            ? Object.assign(
                ...payload.map(el => ({
                  [el.field_label]: el.description
                }))
              )
            : {}
      };

    case TYPES.SET_REPORT_TEMPLATE_VISIBLE_FIELDS:
      return {
        ...state,
        templateVisibleFields: parseTemplateVisibleFields(payload)
      };

    case TYPES.TOGGLE_REPORT_TEMPLATE_FIELD_VISIBILITY:
      return {
        ...state,
        templateVisibleFields: {
          ...state.templateVisibleFields,
          [payload.field_label]: payload.visible
        }
      };

    case TYPES.SET_REPORT_TEMPLATE_VALIDATION_FIELDS:
      return {
        ...state,
        templateFieldValidations: filterTemplateValidatedFields(payload, state.onBoard),
        templateHiddenFromVesselFieldValidations: !state.onBoard
          ? filterVesselValidatedFields(payload)
          : {}
      };

    case TYPES.SET_TEMPLATE_VALIDATION_ERROR_FLAG:
      return {
        ...state,
        hasTemplateValidationError: payload
      };

    case TYPES.SET_REQUIRED_VALIDATION_ERROR_FLAG:
      return {
        ...state,
        hasRequiredValidationError: payload
      };

    case TYPES.SET_CAPTAIN_REPORT_REBUILD_COMMENTS:
      return {
        ...state,
        data: { ...state.data, rebuild_comments: payload }
      };

    case TYPES.SET_REPORT_TEMPLATE_DATA:
      return {
        ...state,
        template: payload
      };

    case TYPES.TOGGLE_SECTION:
      return {
        ...state,
        openedSections: {
          ...state.openedSections,
          [payload.sectionLabel]: payload.isOpen
        }
      };

    case TYPES.SET_LAST_UPDATED_GENERATOR_TAB_INDEX:
      return {
        ...state,
        lastUpdatedGeneratorTabIndex: payload.index
      };

    case TYPES.CHANGE_SECTION_ACTIVE_TAB:
      return {
        ...state,
        activeSectionsTab: {
          ...state.activeSectionsTab,
          [payload.sectionLabel]: payload.tabIndex
        }
      };

    case TYPES.SET_REPORT_TOTALS:
      return {
        ...state,
        totals: payload
      };

    case TYPES.SET_REPORT_BUNKERING:
      return {
        ...state,
        totals: { ...state.totals, bunkering: payload }
      };

    case TYPES.SET_REPORT_NAVIGATION:
      return {
        ...state,
        navigation: payload
      };

    case TYPES.SET_PREVIOUS_REPORT:
      return {
        ...state,
        previousReport: payload.previous_report,
        previousNavigational: payload.previous_navigational,
        previousVesselSystems: payload.previous_vessel_systems
      };

    case TYPES.SET_AER_LEG_AVERAGE:
      return {
        ...state,
        aerLegAverage: payload
      };

    case TYPES.SET_AER_LAST_REPORT:
      return {
        ...state,
        aerLastReport: payload
      };

    case TYPES.SET_YEAR_ATTAINED_CII:
      return {
        ...state,
        yearAttainedCii: payload
      };

    case TYPES.SET_PREVIOUS_BS_VISCOSITIES:
      return {
        ...state,
        previousBsViscosities: payload
      };

    case TYPES.SET_PREVIOUS_BS_BIOFUELS:
      return {
        ...state,
        previousBsBioFuels: payload
      };

    case TYPES.CHANGE_REPORT_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          status: payload
        }
      };

    case TYPES.SET_REPORT_ON_BOARD:
      return {
        ...state,
        onBoard: payload
      };

    case TYPES.SET_INVALID_REPORT:
      return {
        ...state,
        invalidReport: payload
      };

    case TYPES.SET_EDITTING_APPROVED_REPORT:
      return {
        ...state,
        edittingApprovedReport: payload
      };

    case TYPES.SET_FORM:
      return {
        ...state,
        formValidationTry: state.formValidationTry + 1
      };

    case TYPES.FORCE_UPDATE_SECTION_PROGRESS:
      return {
        ...state,
        updateSectionProgress: payload
      };

    case TYPES.SET_FUTURE_REPORTS:
      return {
        ...state,
        futureReportsIsFetching: payload.isFetching,
        futureReports: payload.data
      };

    case TYPES.SET_SELECTED_FUTURE_REPORTS:
      return {
        ...state,
        selectedFutureReports: payload
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(`${paths.captain_reports}/`)) {
        return { ...state, formInitialized: false };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default reportReducer;
