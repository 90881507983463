import React from 'react';
import { Row, Col } from 'reactstrap';
import { getFormFieldParams } from 'common/components/forms/digital/state-utils';

const CrewParticipantsView = ({ value, formField }) => {
  const { notes_label } = getFormFieldParams(formField);
  const participants = value || [];

  if (!participants?.length) return '-';

  return (
    <Row>
      <Col xs={12}>
        <Row className="fs-12 fw-bold text-violet mb-1">
          <Col xs={1}>NO.</Col>
          <Col xs={3}>NAME</Col>
          <Col xs={3}>RANK</Col>
          <Col>{notes_label || 'NOTES'}</Col>
        </Row>
        {participants.map((el, i) => {
          return (
            <Row key={i.toString()} className="fs-12  text-primary mb-1">
              <Col xs={1}>{i + 1}</Col>
              <Col xs={3}>{el.crew_member?.full_name}</Col>
              <Col xs={3}>{el.crew_rank?.name}</Col>
              <Col>{el.notes}</Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

export default CrewParticipantsView;
