import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import vesselIcon from 'common/assets/svg/common/vessels.svg';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from '../filters/helpers';

const VesselSelector = ({
  filter = {},
  value = undefined,
  onChange,
  isMulti,
  autoFocus = true,
  showIcon = false,
  styled = false,
  wrapperClassName = '',
  className = '',
  ...rest
}) => {
  const [selectedVessels, setSelectedVessels] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'vessels' },
    { selected: selectedVessels, setSelected: setSelectedVessels }
  );

  const SelectTag = showIcon ? IconSelect : styled ? StyledSelect : Select;

  return (
    <div className={`${wrapperClassName}`}>
      <SelectTag
        dataCy="vessel-selector"
        className={className || `mb-0`}
        placeholder="Select value"
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        isAsync
        isMulti={isMulti}
        size="sm"
        icon={showIcon ? vesselIcon : null}
        value={
          value !== undefined
            ? value
            : filter?.value
            ? getFilterSelectorValues(selectedVessels, filter?.value, isMulti)
            : []
        }
        defaultOptions={() => getInitialAsyncValues('vessels')}
        loadOptions={search => getAsyncOptions(search, 'vessels')}
        onChange={selected => {
          setSelectedVessels(isMulti ? selected : [selected]);
          onChange(
            value !== undefined
              ? selected
              : {
                  value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
                }
          );
        }}
        {...rest}
      />
    </div>
  );
};

export default VesselSelector;
