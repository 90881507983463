import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import App from 'components/layouts/App'; // Remove App wrap if you wish

import { parseQueryParams } from 'common/utils/urls';

const handleRedirect = location => {
  if (location.pathname.includes('/login')) {
    const { redirect } = parseQueryParams(location.search);

    if (redirect && !redirect.includes('logout')) {
      return redirect;
    } else {
      return '/';
    }
  } else {
    return '/';
  }
};

const NoAuthRoute = ({ component, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      !rest.authenticated ? (
        rest.app ? (
          <App title={rest.title}>{React.createElement(component, props)}</App>
        ) : (
          React.createElement(component, props)
        )
      ) : (
        <Redirect
          to={{
            pathname: handleRedirect(props.location),
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default NoAuthRoute;
