import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_SYSTEM_GROUPS'),
  ...asyncTypes('GET_SYSTEM_GROUP'),
  ...asyncTypes('CREATE_SYSTEM_GROUP'),
  ...asyncTypes('UPDATE_SYSTEM_GROUP'),
  ...asyncTypes('DELETE_SYSTEM_GROUP'),
  SET_ACTIVE_GROUP: 'SET_ACTIVE_GROUP',
  TOGGLE_GROUP: 'TOGGLE_GROUP',
  TOGGLE_ALL_GROUPS: 'TOGGLE_ALL_GROUPS',
  ...asyncTypes('GET_SINGLE_SYSTEM'),

  GET_VESSEL_SYSTEMS: 'GET_VESSEL_SYSTEMS',
  GET_PMS_LIBRARY_VESSEL_SYSTEMS: 'GET_PMS_LIBRARY_VESSEL_SYSTEMS',
  GET_PURCHASING_REQUISITION_VESSEL_SYSTEMS: 'GET_PURCHASING_REQUISITION_VESSEL_SYSTEMS',

  CREATE_SYSTEM: 'CREATE_SYSTEM',
  CREATE_SUBSYSTEM: 'CREATE_SUBSYSTEM',
  UPDATE_SYSTEM: 'UPDATE_SYSTEM',
  ...asyncTypes('DELETE_SYSTEM'),
  ...asyncTypes('CLONE_SYSTEM'),
  SET_SYSTEM_FORM_TYPE: 'SET_SYSTEM_FORM_TYPE',
  SET_SELECTED_SYSTEM: 'SET_SELECTED_SYSTEM',
  SET_IS_CREATING_SUBSYSTEM: 'SET_IS_CREATING_SUBSYSTEM',
  TOGGLE_SYSTEM: 'TOGGLE_SYSTEM',
  TOGGLE_ALL_SYSTEMS: 'TOGGLE_ALL_SYSTEMS',
  SET_SYSTEM_FORM_PARENT_ID: 'SET_SYSTEM_FORM_PARENT_ID',
  SET_CURRENT_FORM_SYSTEM: 'SET_CURRENT_FORM_SYSTEM',
  ...asyncTypes('GET_SPARE_PARTS'),
  ...asyncTypes('GET_SPARE_PART'),
  ...asyncTypes('CREATE_SPARE_PART'),
  ...asyncTypes('UPDATE_SPARE_PART'),
  ...asyncTypes('DELETE_SPARE_PART'),
  SET_SELECTED_SPARE_PART: 'SET_SELECTED_SPARE_PART',
  SET_PMS_LIBRARY_IS_USED_IN: 'SET_PMS_LIBRARY_IS_USED_IN',
  // vessel assignments
  ...asyncTypes('ASSIGN_VESSEL'),
  ...asyncTypes('ADD_VESSEL_ASSIGNMENT'),
  ...asyncTypes('GET_VESSEL_ASSIGNMENTS'),
  ...asyncTypes('GET_VESSEL_SUB_ASSIGNMENTS'),
  ...asyncTypes('DELETE_VESSEL_ASSIGNMENT'),
  ...asyncTypes('GET_ASSIGNMENT'),
  ...asyncTypes('UPDATE_ASSIGNMENT'),
  ...asyncTypes('UPDATE_VESSEL_ASSIGNMENT_PROTOTYPE'),
  ...asyncTypes('DELETE_ASSIGNMENT'),
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  SET_SELECTED_ASSIGNMENT: 'SET_SELECTED_ASSIGNMENT',
  SET_SELECTED_SUB_ASSIGNMENT: 'SET_SELECTED_SUB_ASSIGNMENT',
  ...asyncTypes('GET_MAINTENANCE_JOBS'),
  ...asyncTypes('CREATE_MAINTENANCE_JOB'),
  ...asyncTypes('EDIT_MAINTENANCE_JOB'),
  ...asyncTypes('GET_MAINTENANCE_JOB'),
  ...asyncTypes('DELETE_MAINTENANCE_JOB'),
  ...asyncTypes('TRIGGER_ASSIGNMENT_JOBS'),
  SET_MAINTENANCE_JOB_CHECKLIST: 'SET_MAINTENANCE_JOB_CHECKLIST',
  SET_MAINTENANCE_JOB_ACTIVE_TABS: 'SET_MAINTENANCE_JOB_ACTIVE_TABS',
  RESET_MAINTENANCE_JOB_ACTIVE_TABS: 'RESET_MAINTENANCE_JOB_ACTIVE_TABS',
  CREATE_MAINTENANCE_JOB_TYPE: 'CREATE_MAINTENANCE_JOB_TYPE',
  EDIT_MAINTENANCE_JOB_TYPE: 'EDIT_MAINTENANCE_JOB_TYPE',
  DELETE_MAINTENANCE_JOB_TYPE: 'DELETE_MAINTENANCE_JOB_TYPE',
  SET_ACTIVE_SYSTEM_GROUP_STATE: 'SET_ACTIVE_SYSTEM_GROUP_STATE',
  SET_GROUPS_COLLAPSED: 'SET_GROUPS_COLLAPSED',
  SET_SYSTEM_COLLAPSED: 'SET_SYSTEM_COLLAPSED',
  SET_SELECTED_VESSEL_FILTER: 'SET_SELECTED_VESSEL_FILTER'
} as const;

export default TYPES;
