import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModuleWrapper from '../components/ModuleWrapper';
import UnderwaterServicesInfo from 'events/event/modules/module-components/underwater-services/UnderwaterServicesInfo';
import UnderwaterServices from './services';
import Loader from './Loader';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { getUnderwaterServicesInfo } from 'events/store/event-modules/underwater-services/actions';

const UnderwaterServicesModule = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);

  useEffect(() => {
    if (eventId) {
      const initModule = async () => {
        setIsLoading(true);

        await dispatch(getUnderwaterServicesInfo({ event_id: eventId }));

        setIsLoading(false);
      };

      initModule();
    }
  }, [eventId, dispatch]);

  return (
    <ModuleWrapper type="underwater_services">
      {!isTemplate && eventId ? (
        isLoading ? (
          <Loader />
        ) : (
          <>
            <UnderwaterServices />
            <UnderwaterServicesInfo />
          </>
        )
      ) : null}
    </ModuleWrapper>
  );
};

export default UnderwaterServicesModule;
