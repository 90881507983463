import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_CONTRACTS'),
  ...asyncTypes('GET_VESSEL_CONTRACTS'),
  ...asyncTypes('GET_CREW_CONTRACT'),
  ...asyncTypes('CREATE_CREW_CONTRACT'),
  ...asyncTypes('UPDATE_CREW_CONTRACT'),
  ...asyncTypes('DELETE_CREW_CONTRACT'),
  ...asyncTypes('GET_CREW_PREVIOUS_CONTRACTS'),
  ...asyncTypes('CREATE_CREW_PREVIOUS_CONTRACT'),
  ...asyncTypes('UPDATE_CREW_PREVIOUS_CONTRACT'),
  ...asyncTypes('DELETE_CREW_PREVIOUS_CONTRACT'),
  ...asyncTypes('GET_CREW_CONTRACT_ATTACHMENTS'),
  ...asyncTypes('GET_CREW_ACTIVE_ROTATIONS'),
  ...asyncTypes('GET_CREW_CONTRACTS_DIGITAL_SIGNATURES'),
  ...asyncTypes('CREATE_CREW_CONTRACTS_DIGITAL_SIGNATURE'),
  ...asyncTypes('DELETE_CREW_CONTRACTS_DIGITAL_SIGNATURE'),
  SET_ACTIVE_CONTRACT: 'SET_ACTIVE_CONTRACT',
  SET_ACTIVE_PREVIOUS_CONTRACT: 'SET_ACTIVE_PREVIOUS_CONTRACT',
  ADD_NEW_PREVIOUS_CONTRACT: 'ADD_NEW_PREVIOUS_CONTRACT',
  REMOVE_NEW_PREVIOUS_CONTRACT: 'REMOVE_NEW_PREVIOUS_CONTRACT'
};

export default TYPES;
