type AsyncType<T extends string> = {
  [key in T]: {
    START: string;
    SUCCESS: string;
    ERROR: string;
  };
};

const asyncTypes = <T extends string>(typeName: T, prefix: string | null = null): AsyncType<T> => {
  const name = prefix ? prefix + '_' + typeName : typeName;

  return {
    [typeName]: {
      START: `${name}_START`,
      SUCCESS: `${name}_SUCCESS`,
      ERROR: `${name}_ERROR`
    }
  } as AsyncType<T>;
};

export default asyncTypes;
