import React from 'react';
import { number, func, arrayOf, bool } from 'prop-types';

import { Row, Col } from 'reactstrap';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectIsRegularJob,
  selectJobPrefilledVessel
} from 'common/components/jobs/_base/store/selectors';
import { components } from 'react-select';
import Status from 'common/components/general/Status';
import { JobStatusType } from 'common/entities/jobs/JobTypes';
import { VesselType } from 'common/entities/vessels/VesselTypes';

import Departments from 'common/components/jobs/_base/inputs/Departments';
import { departmentTypeEnums } from 'common/utils/fixed';

const Body = ({
  selectedVessels,
  setSelectedVessels,
  filteredJobStatuses,
  selectedStatus,
  setSelectedStatus,
  avoidCheck
}) => {
  const projectId = useSelector(state => selectJobField(state, 'project'))?.id;
  const isRegularJob = useSelector(state => selectIsRegularJob(state));
  const prefilledVessel = useSelector(selectJobPrefilledVessel);

  return (
    <>
      <Row>
        <Col xs={12} className="text-warning fs-12 fw-medium mb-3">
          *This action is permanent and cannot be undone. Please be cautious when assigning this job
          to a vessel, as it cannot be reversed.
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={12}>
          <Select
            className="mb-0"
            label="Vessel"
            placeholder={`Select vessel${projectId ? '(s)' : ''}`}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            value={selectedVessels}
            isAsync
            loadOptions={search => getAsyncOptions(search, 'vessels')}
            defaultOptions={() => getInitialAsyncValues('vessels')}
            isMulti={!!projectId}
            disabled={prefilledVessel?.id}
            onChange={value =>
              projectId ? setSelectedVessels(value) : setSelectedVessels([value])
            }
            noOptionsMessage={({ inputValue }) =>
              !inputValue.length ? `Search for vessels` : `No vessels found`
            }
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Select
            options={filteredJobStatuses}
            className="mb-0"
            components={{
              Option,
              SingleValue
            }}
            label="STATUS"
            placeholder="Select status"
            value={selectedStatus}
            onChange={el => {
              setSelectedStatus(el);
            }}
          />
        </Col>
        {isRegularJob ? (
          <Col xs="6" className="ps-0">
            <Departments
              className="mb-0"
              avoidCheck={avoidCheck}
              type={departmentTypeEnums.responsible_onboard_department}
              boxPlaceholder={false}
              invisible={false}
              placeholder="Select dept"
              label="RESPONSIBLE ONBOARD DEPARTMENT"
            />
          </Col>
        ) : null}
      </Row>
      <Row>
        {projectId && selectedVessels?.length ? (
          <Col xs={12} className="text-violet fs-12 fw-medium mt-3">
            *Job will be split into <strong>{selectedVessels.length}</strong> different jobs, one
            for each vessel.
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Body;

Body.propTypes = {
  selectedVessels: arrayOf(VesselType),
  filteredJobStatuses: arrayOf(JobStatusType),
  selectedStatus: number,
  setSelectedVessels: func.isRequired,
  setSelectedStatus: func.isRequired,
  avoidCheck: bool
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <Status value={props.data.id} className="my-0" />
  </components.SingleValue>
);

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div>
        <Status value={props.data.id} />
      </div>
    </components.Option>
  );
};
