import { Props } from 'react-select';
import { SparePartBase } from '@/common/types/pms';
import { SparePartOption } from '@/common/components/selectors/_helpers';
import CollectionFilterSelector, { CollectionFilterProps } from './CollectionFilterSelector';

function SparePartsCollectionFilter(
  props: Omit<Props<SparePartBase>, 'value'> & CollectionFilterProps<SparePartBase>
) {
  return (
    <CollectionFilterSelector
      {...props}
      memoizedRequestParams={{
        path: 'lists',
        params: { list: 'spare-parts' }
      }}
      getOptionLabel={option => `${option.part_no}. ${option.description}`}
      components={{ Option: SparePartOption }}
    />
  );
}

export default SparePartsCollectionFilter;
