import React, { useState, memo } from 'react';

import { useSelector } from 'react-redux';

import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';
import { useActions } from 'utils/hooks';
import { convertToFilters } from 'crew/utils/filters';
import * as shiftActions from 'crew/store/shifts/actions';
import {
  selectStartDate,
  selectEndDate,
  selectSelectedShiftType,
  selectShiftTypes,
  selectPeriodType
} from 'crew/store/shifts/selectors';

import _isEqual from 'lodash/isEqual';

import MiniShiftTypeSelector from './MiniShiftTypeSelector';
import ExportPdf from 'common/components/general/ExportPdf';
import ShiftTypesList from 'crew/components/shifts/shift-type-selector/ShiftTypesList';

const ShiftTypeSelector = memo(({ isEditing, isCrewProfile, crew }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const [setSelectedShiftType] = useActions([shiftActions.setSelectedShiftType]);

  const isOnboard = useSelector(state => state.isOnBoard);
  const selectedShiftType = useSelector(selectSelectedShiftType());
  const shiftTypes = useSelector(selectShiftTypes);
  const periodType = useSelector(selectPeriodType);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);

  const downloadPdf = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    const params = {
      filters: convertToFilters({ from: startDate, to: endDate })
    };

    try {
      await handleFileDownload(
        {
          url: `${
            isOnboard ? `/crew-shifts/${crew?.id}/export` : `/crew/${crew?.id}/shifts/export`
          }`
        },
        download,
        false,
        {
          requestParams: params,
          parsed: true
        }
      );
    } catch (e) {
      console.error(e);
    }
    setIsDownloading(false);
  };

  return periodType !== 'daily' ? (
    <div
      className={`${!isCrewProfile ? 'ms-2 ms-hd-3 pe-2 pe-hd-4 mt-2 ' : ''}shifts-type-selector${
        isEditing ? ' shifts-type-selector--is-editing' : ''
      }`}
    >
      <div className="d-flex">
        <div className="d-flex flex-column">
          <div
            className={`fs-14 lh-16 fw-normal text-primary${
              !isCrewProfile ? ' mb-5 ' : ' '
            }d-flex align-items-center cmt-4`}
          >
            {isEditing ? 'Select type' : 'Shift types'}
          </div>
          <ShiftTypesList
            className={!isCrewProfile ? 'pb-3 ' : ''}
            selectedShiftType={selectedShiftType}
            onClick={shiftType =>
              isEditing
                ? _isEqual(selectedShiftType, shiftType)
                  ? setSelectedShiftType({})
                  : setSelectedShiftType(shiftType)
                : null
            }
          />
          {periodType === 'monthly' && !isCrewProfile ? (
            <ExportPdf
              className="border-top pt-3"
              showLabel="Export PDF"
              textClassname="fw-bold fs-11"
              onClick={downloadPdf}
              svgStyle={{ width: 15, height: 15 }}
              disabled={isDownloading}
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : isEditing ? (
    <div className="d-flex flex-wrap justify-content-center align-items-center ps-1 mt-1 mb-1 shifts-type-selector--mini">
      <MiniShiftTypeSelector
        shiftTypes={shiftTypes}
        selectedShiftType={selectedShiftType}
        setSelectedShiftType={setSelectedShiftType}
      />
    </div>
  ) : (
    // <div className="d-inline-flex align-items-center justify-content-center ps-1 pe-1 me-1 ps-hd-2 pe-hd-2 me-hd-2 border-end">
    //   <ExportPdf disabled />
    // </div>
    <div className="me-4" />
  );
});

export default ShiftTypeSelector;
