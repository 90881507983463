import React, { useState, useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';
import { Label } from 'reactstrap';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as jobRestrictedFieldsActions from 'store/jobs-fields/actions';

import CircledButton from 'common/components/buttons/CircledButton';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';

import _sortBy from 'lodash/sortBy';
import _isEqual from 'lodash/isEqual';

import {
  Option,
  MultiValue,
  SingleValue
} from 'common/components/selectors/_partySelectComponents';

const SingleFieldComponent = ({ disabled, ...rest }) => {
  const [setJobField, setJobFieldError, checkIfFieldIsRequired] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError,
    jobRestrictedFieldsActions.checkIfFieldIsRequired
  ]);
  const approvers = useSelector(state => selectJobField(state, 'approvers'));
  const jobApproversError = useSelector(state => selectJobFieldError(state, 'approvers'));
  const excludeIds = approvers.map(el => el.id);
  const jobId = useSelector(state => selectJobField(state, 'id'));

  const cannotDelete = checkIfFieldIsRequired('approvers') && approvers.length === 1;

  const updateApproversState = newVal => {
    const old = approvers.filter(a => a.id).map(a => a.id);
    const updated = newVal.filter(a => a.id).map(a => a.id);

    if (jobApproversError && newVal.length) {
      setJobFieldError({ approvers: null });
    }

    if (!_isEqual(_sortBy(old), _sortBy(updated))) {
      setJobField('approvers', newVal, true);
    } else {
      setJobField('approvers', newVal);
    }
  };

  return (
    <Select
      label="Approvers"
      placeholder="Select person(s)"
      getOptionValue={option => option.id}
      getOptionLabel={option => getPartyDisplayName(option)}
      value={approvers.filter(a => a.id)}
      error={jobApproversError}
      loadOptions={search =>
        getAsyncOptions(search, 'parties', {
          can_login: true,
          exclude_ids: excludeIds,
          type: 'person'
        })
      }
      defaultOptions={() =>
        getInitialAsyncValues('parties', null, false, {
          can_login: true,
          exclude_ids: excludeIds,
          type: 'person'
        })
      }
      onChange={v => updateApproversState(v)}
      components={{ Option, SingleValue, MultiValue }}
      isClearable={checkIfFieldIsRequired('approvers') ? false : true}
      isAsync
      isMulti
      id={`approvers-select-${jobId}`}
      white
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for persons` : `No persons found`
      }
      className={cannotDelete ? 'remove-clear-button' : ''}
      disabled={disabled}
      {...rest}
    />
  );
};

const MultiFieldComponent = ({ rendered, disabled, ...rest }) => {
  const [setJobField, setJobFieldError, checkIfFieldIsRequired] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError,
    jobRestrictedFieldsActions.checkIfFieldIsRequired
  ]);
  const approvers = useSelector(state => selectJobField(state, 'approvers'));
  const jobApproversError = useSelector(state => selectJobFieldError(state, 'approvers'));
  const isEditMode = useSelector(checkIfEditMode);
  const excludeIds = approvers.map(el => el.id);
  const cannotDelete = checkIfFieldIsRequired('approvers') && approvers.length === 1;

  const handleChange = (value, index) => {
    if (value) {
      updateApproversState(
        approvers.map((a, i) => {
          if (i === index) {
            return value;
          }

          return a;
        })
      );
    } else {
      const updated = approvers.filter((a, i) => index !== i);

      updateApproversState(!updated.length ? [{}] : updated);
    }
  };

  const updateApproversState = newVal => {
    const old = approvers.filter(a => a.id).map(a => a.id);
    const updated = newVal.filter(a => a.id).map(a => a.id);

    if (jobApproversError && newVal.length) {
      setJobFieldError({ approvers: null });
    }

    if (!_isEqual(_sortBy(old), _sortBy(updated))) {
      setJobField('approvers', newVal, true);
    } else {
      setJobField('approvers', newVal);
    }
  };

  return approvers.map((approver, index) => (
    <Select
      key={index}
      className={`job-input--approvers-input mb-1`}
      placeholder="Select person(s)"
      getOptionValue={option => option.id}
      value={approver}
      error={jobApproversError}
      isAsync
      white
      autoFocus={rendered && index === approvers.length - 1}
      loadOptions={search =>
        getAsyncOptions(search, 'parties', { can_login: true, exclude_ids: excludeIds })
      }
      defaultOptions={() =>
        getInitialAsyncValues('parties', null, false, { can_login: true, exclude_ids: excludeIds })
      }
      onChange={v => handleChange(v, index)}
      components={{ Option, SingleValue }}
      isClearable={isEditMode ? (cannotDelete ? false : true) : true}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for persons` : `No persons found`
      }
      disabled={disabled}
      {...rest}
    />
  ));
};

const Approvers = ({ singleField, avoidCheck = false, ...rest }) => {
  const [setJobField, setJobFieldError] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError
  ]);
  const [rendered, setRendered] = useState(false);
  const approvers = useSelector(state => selectJobField(state, 'approvers'));

  const jobApproversError = useSelector(state => selectJobFieldError(state, 'approvers'));

  const isVisible = useSelector(selectIfFieldIsVisible('approvers'));
  const isJobLocked = useSelector(state => selectIsJobLocked(state, avoidCheck ? false : true));

  useEffect(() => {
    setRendered(true);
  }, []);

  const updateApproversState = newVal => {
    const old = approvers.filter(a => a.id).map(a => a.id);
    const updated = newVal.filter(a => a.id).map(a => a.id);

    if (jobApproversError && newVal.length) {
      setJobFieldError({ approvers: null });
    }

    if (!_isEqual(_sortBy(old), _sortBy(updated))) {
      setJobField('approvers', newVal, true);
    } else {
      setJobField('approvers', newVal);
    }
  };

  if (!isVisible) return null;

  return singleField ? (
    <UserCanEdit
      field="approvers"
      fallback={() => <SingleFieldComponent disabled={true} {...rest} />}
      avoidCheck={avoidCheck}
    >
      <SingleFieldComponent disabled={isJobLocked} {...rest} />
    </UserCanEdit>
  ) : (
    <div className="form-group-spacing">
      <Label className="form-label">Approvers</Label>
      <UserCanEdit
        field="approvers"
        avoidCheck={avoidCheck}
        fallback={() => <MultiFieldComponent rendered={rendered} disabled={true} {...rest} />}
      >
        <MultiFieldComponent rendered={rendered} disabled={isJobLocked} {...rest} />
      </UserCanEdit>

      <UserCanEdit field="approvers" avoidCheck={avoidCheck}>
        <div className="text-end">
          {approvers.every(a => a.id) ? (
            <CircledButton
              type="add-primary"
              style={{ width: 20, height: 20 }}
              svgStyle={{ width: 8, height: 8 }}
              onClick={() => updateApproversState([...approvers, {}])}
            />
          ) : null}
        </div>
      </UserCanEdit>
    </div>
  );
};

export default Approvers;
