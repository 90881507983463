import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { useFormState } from 'utils/hooks';

import DateInput from 'common/components/form/inputs/date';
import PortSelector from 'common/components/selectors/PortSelector';
import { getCustomFormat } from 'common/utils/dates';

const Body = ({ formState }) => {
  const { fields, selectField } = useFormState(formState);

  return (
    <div>
      <Row className="g-0">
        {fields.contract_from_date.value || fields.contract_to_date.value ? (
          <Col xs={12} className="d-flex flex-column mb-2">
            <Label className="fs-10">Active Contract</Label>

            <div className="fs-12">
              {getCustomFormat(fields.contract_from_date.value, 'DD/MM/YYYY')}
              {fields.contract_to_date.value ? ' - ' : ''}
              {getCustomFormat(fields.contract_to_date.value, 'DD/MM/YYYY')}
            </div>
          </Col>
        ) : null}
        <Col>
          <DateInput
            label={'Sign on date & time'}
            showTime
            onChange={date => selectField('sign_on_date_as_per_captain')(date)}
            {...fields.sign_on_date_as_per_captain}
          />
        </Col>
      </Row>
      <Row className="no-gutter">
        <Col>
          <PortSelector
            label={'Sign on port'}
            placeholder="Select port"
            isMulti={false}
            filter={{ value: fields.sign_on_port_id_as_per_captain.value }}
            onChange={({ value }) => selectField('sign_on_port_id_as_per_captain')(value)}
            error={fields.sign_on_port_id_as_per_captain.error}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="border-bottom mt-3 mb-3 pe-2 ps-2"></div>
        </Col>
      </Row>

      <Row className="g-0">
        <Col>
          <DateInput
            label={'Sign off date & time'}
            showTime
            onChange={date => selectField('sign_off_date_as_per_captain')(date)}
            {...fields.sign_off_date_as_per_captain}
          />
        </Col>
      </Row>
      <Row className="no-gutter">
        <Col>
          <PortSelector
            label={'Sign off port'}
            placeholder="Select port"
            isMulti={false}
            filter={{ value: fields?.sign_off_port_id_as_per_captain?.value }}
            onChange={({ value }) => selectField('sign_off_port_id_as_per_captain')(value)}
            error={fields?.sign_off_port_id_as_per_captain?.error}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Body;
