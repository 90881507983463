import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, put, post } from 'utils/api';

export const getVesselCondition = createAsyncThunk(
  'GET_VESSEL_CONDITION',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await get(`/events/${id}/vessel-condition`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateVesselCondition = createAsyncThunk(
  'UPDATE_VESSEL_CONDITION',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await put(`/events/${id}/vessel-condition`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createVesselCondition = createAsyncThunk(
  'CREATE_VESSEL_CONDITION',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/events/${id}/vessel-condition`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
