import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_PURCHASING_REQUISITIONS'),
  ...asyncTypes('GET_PURCHASING_REQUISITIONS_TOTALS'),
  ...asyncTypes('CREATE_PURCHASING_REQUISITION'),
  ...asyncTypes('GET_PURCHASING_REQUISITION'),
  ...asyncTypes('GET_PURCHASING_REQUISITION_ITEMS'),
  ...asyncTypes('UPDATE_PURCHASING_REQUISITION_ITEM'),
  ...asyncTypes('GET_PURCHASING_REQUISITIONS_LAST_DELIVERED_ITEMS'),
  ...asyncTypes('GET_PURCHASING_REQUISITION_SUPPLIERS'),
  ...asyncTypes('UPDATE_PURCHASING_REQUISITION'),
  ...asyncTypes('SUBMIT_PURCHASING_REQUISITION'),
  ...asyncTypes('APPROVE_PURCHASING_REQUISITION'),
  ...asyncTypes('DELETE_PURCHASING_REQUISITION'),
  ...asyncTypes('GET_PURCHASING_REQUISITION_SUPPLIERS'),
  ...asyncTypes('ADD_PURCHASING_REQUISITION_SUPPLIER'),
  ...asyncTypes('DELETE_PURCHASING_REQUISITION_SUPPLIER'),
  ...asyncTypes('DOWNLOAD_SUPPLIER_INVOICE_ATTACHMENTS'),
  ...asyncTypes('UPDATE_PURCHASING_REQUISITION_SUPPLIER_STATUS'),
  ...asyncTypes('GET_PURCHASING_REQUISITION_SUPPLIER'),
  ...asyncTypes('UPDATE_PURCHASING_REQUISITION_SUPPLIER'),
  ...asyncTypes('UPLOAD_PURCHASING_REQUISITION_SUPPLIER_FILE'),
  ...asyncTypes('SEND_PURCHASING_REQUISITION_SUPPLIER_DELIVERY_REPORT'),
  ...asyncTypes('TOGGLE_SUPPLIER_REJECTION'),
  ...asyncTypes('ADD_ITEM_TO_PURCHASING_REQUISITION'),
  ...asyncTypes('DELETE_ITEM_FROM_PURCHASING_REQUISITION'),
  ...asyncTypes('EVALUATE_PURCHASING_REQUISITION_SUPPLIER'),
  ...asyncTypes('APPLY_DISCOUNT_TO_ALL_ITEMS'),
  ...asyncTypes('FILL_QUANTITY_FROM_EXISTINGS'),
  ...asyncTypes('FILL_REV_QUANTITY_FROM_REQ'),
  ...asyncTypes('GENERATE_PURCHASING_REQUISITION_CODE'),
  ...asyncTypes('MERGE_PURCHASING_REQUISITIONS'),
  ...asyncTypes('SEND_PURCHASING_REQUISITIONS_EMAILS'),
  ...asyncTypes('GET_PURCHASING_REQUISITIONS_LINKED_EMAILS_TEMP_URL'),
  ...asyncTypes('GET_PURCHASING_REQUISITIONS_EMAILS'),
  ...asyncTypes('GET_PURCHASING_REQUISITIONS_EMAIL_ATTACHMENTS'),
  ...asyncTypes('CREATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES'),
  ...asyncTypes('UPDATE_PURCHASING_REQUISITIONS_EMAIL_TEMPLATES'),
  ...asyncTypes('LINK_PURCHASING_REQUISITIONS_EMAIL'),
  ...asyncTypes('GET_PURCHASING_REQUISITIONS_LINKED_EMAILS'),
  ...asyncTypes('DELETE_PURCHASING_REQUISITION_EMAIL_LINK'),
  ...asyncTypes('APPLY_TO_ALL_ITEMS_IN_CATEGORY'),
  ...asyncTypes('IS_ELIGIBLE_FOR_PENDING_REVIEW'),

  //Template types
  ...asyncTypes('GET_PURCHASING_REQUISITION_TEMPLATES'),
  ...asyncTypes('DELETE_PURCHASING_REQUISITION_TEMPLATE'),
  ...asyncTypes('CREATE_PURCHASING_REQUISITION_TEMPLATE'),
  ...asyncTypes('LOAD_PURCHASING_REQUISITION_SUPPLIERS_FROM_TEMPLATE'),

  //Chat types
  ...asyncTypes('GET_PURCHASING_REQUISITION_CHAT_MESSAGES'),
  ...asyncTypes('POST_PURCHASING_REQUISITION_CHAT_MESSAGE'),
  ...asyncTypes('GET_UNREAD_PURCHASING_REQUISITION_CHAT_MESSAGES'),
  ...asyncTypes('PURCHASING_REQUISITION_CHAT_MESSAGE_MARK_AS_READ'),

  //Table Configuration Types
  ...asyncTypes('GET_PURCHASING_TABLE_CONFIGURATION'),
  ...asyncTypes('CREATE_PURCHASING_TABLE_CONFIGURATION'),
  ...asyncTypes('EDIT_PURCHASING_TABLE_CONFIGURATION'),
  ...asyncTypes('DELETE_PURCHASING_TABLE_CONFIGURATION'),
  SET_ACTIVE_PURCHASING_TABLE_CONFIGURATION: 'SET_ACTIVE_PURCHASING_TABLE_CONFIGURATION',
  SET_ITEMS_TABLE_CONFIGURATION: 'SET_ITEMS_TABLE_CONFIGURATION',
  SET_ITEMS_TABLE_COMPARISON_VIEW_ENABLED: 'SET_ITEMS_TABLE_COMPARISON_VIEW_ENABLED',
  SET_ITEMS_TABLE_COMPARISON_BENCHMARK_THRESH: 'SET_ITEMS_TABLE_COMPARISON_BENCHMARK_THRESH',
  SET_ITEMS_TABLE_COMPARISON_COMMENTS_VISIBILITY: 'SET_ITEMS_TABLE_COMPARISON_COMMENTS_VISIBILITY',
  SET_ITEMS_TABLE_COMPARISON_LOCAL_CURRENCIES_VISIBILITY:
    'SET_ITEMS_TABLE_COMPARISON_LOCAL_CURRENCIES_VISIBILITY',

  RESET_STATE: 'RESET_STATE',
  TOGGLE_LAST_DELIVERED_TOOLTIP: 'TOGGLE_LAST_DELIVERED_TOOLTIP',
  GET_PURCHASING_REQUISITION_PARAMS: 'GET_PURCHASING_REQUISITION_PARAMS',
  SET_CATEGORY: 'SET_CATEGORY',
  ADD_CATEGORY: 'ADD_CATEGORY',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  ADD_CATEGORY_ITEM: 'ADD_CATEGORY_ITEM',
  REMOVE_PMS_ITEMS: 'REMOVE_PMS_ITEMS',
  REMOVE_CATEGORY_ITEM: 'REMOVE_CATEGORY_ITEM',
  SET_ITEM_FIELD: 'SET_ITEM_FIELD',
  SET_IS_ADDING_SUPPLIER: 'SET_IS_ADDING_SUPPLIER',
  SET_PURCHASING_REQUISITION_SUPPLIERS: 'SET_PURCHASING_REQUISITION_SUPPLIERS',
  TOGGLE_EDITING_REQUISITION_CASE: 'TOGGLE_EDITING_REQUISITION_CASE',
  TOGGLE_PURCHASING_ITEMS_SELECTION_DRAWER: 'TOGGLE_PURCHASING_ITEMS_SELECTION_DRAWER',
  TOGGLE_PURCHASING_COMMUNICATION_DRAWER: 'TOGGLE_PURCHASING_COMMUNICATION_DRAWER',
  TOGGLE_PMS_ITEMS_SELECTION_DRAWER: 'TOGGLE_PMS_ITEMS_SELECTION_DRAWER',
  SET_ACTIVE_CATEGORY_ID: 'SET_ACTIVE_CATEGORY_ID',
  TOGGLE_SUPPLIER_VISIBILITY: 'TOGGLE_SUPPLIER_VISIBILITY',
  SET_SUPPLIERS_VISIBILITY: 'SET_SUPPLIERS_VISIBILITY',
  TOGGLE_ALL_SUPPLIERS_VISIBILITY: 'TOGGLE_ALL_SUPPLIERS_VISIBILITY',
  SET_REQUISITION_VESSEL: 'SET_REQUISITION_VESSEL',
  SET_PREVENT_LOCATION_CHANGE: 'SET_PREVENT_LOCATION_CHANGE',
  SET_VISIBLE_ITEM_FIELDS: 'SET_VISIBLE_ITEM_FIELDS',
  SET_COLLAPSED_FINDINGS: 'SET_COLLAPSED_FINDINGS',
  RESET_TEMPLATE_STATE: 'RESET_TEMPLATE_STATE',
  TOGGLE_REMARKS_AND_ATTACHMENTS: 'TOGGLE_REMARKS_AND_ATTACHMENTS',
  TOGGLE_SUMMARY_VIEW: 'TOGGLE_SUMMARY_VIEW',
  SET_IS_LAST_REQUISITION_LOADED: 'SET_IS_LAST_REQUISITION_LOADED',
  GET_PURCHASING_REQUISITION_EMAIL_LINK: 'GET_PURCHASING_REQUISITION_EMAIL_LINK',
  SET_ITEM_COMMENTS_DRAWER_OPEN: 'SET_ITEM_COMMENTS_DRAWER_OPEN',
  SET_SUPPLIER_PO_DETAILS_DRAWER_OPEN: 'SET_SUPPLIER_PO_DETAILS_DRAWER_OPEN',
  SET_SELECTED_ITEM_TO_BE_REPLACED: 'SET_SELECTED_ITEM_TO_BE_REPLACED',
  BULK_UPDATE_PURCHASING_REQUISITION_ITEMS: 'BULK_UPDATE_PURCHASING_REQUISITION_ITEMS'
} as const;

export default TYPES;
