import moment from 'moment';
import { getOnboardCrewParticipants, getOfficeCrewParticipants } from 'store/forms/actions';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const getParams = ({
  isOnBoard,
  isFormForVessel,
  selectedVesselId,
  selectedDate,
  todaysDate,
  search
}) => {
  const params = { filters: [] };

  if (isFormForVessel) {
    if (!isOnBoard) {
      params.filters.push({ name: 'onboard_vessel_id', operation: '=', value: selectedVesselId });
    }

    params.filters.push({
      name: 'onboard_as_of_date',
      operation: '=',
      value: selectedDate ? [selectedDate, selectedDate] : [todaysDate, todaysDate]
    });
    params.sorting = { rank_id: 'asc' };
  }

  if (search) params.search = search;

  return params;
};

export const fetchCrewMembers = async ({
  search = '',
  isOnBoard,
  selectedDate,
  selectedVesselId,
  isFormForVessel,
  dispatch
}) => {
  const todaysDate = moment().format('YYYY-MM-DD');
  const fetchCrew = isOnBoard
    ? params => dispatch(getOnboardCrewParticipants(params))
    : isFormForVessel
    ? params => dispatch(getOfficeCrewParticipants(params))
    : () =>
        search
          ? getAsyncOptions(search, 'crew-members', {})
          : getInitialAsyncValues('crew-members', null, null);

  try {
    const crew = await fetchCrew(
      getParams({
        isOnBoard,
        isFormForVessel,
        selectedVesselId,
        selectedDate,
        todaysDate,
        search
      })
    );

    return crew?.data?.length ? crew.data : crew?.length ? crew : [];
  } catch (error) {
    console.error(error);

    return [];
  }
};
