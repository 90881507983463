import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';

import threeDots from 'common/assets/svg/actions/three-dots.svg';
import { isAuthorized } from '@/utils/permissions/authorize';
import { useAppSelector } from '@/store/hooks';
import { selectAccount } from '@/store/account/selectors';

const ChapterActions = ({ actions, disabled }) => {
  const account = useAppSelector(selectAccount);
  const permissionFilteredActions = (actions || []).filter(action =>
    action.permissions ? isAuthorized(account, action.permissions) : true
  );

  return !disabled ? (
    <UncontrolledDropdown direction="left" className="d-inline-flex align-items-center ms-auto">
      {permissionFilteredActions.length !== 0 && (
        <>
          <DropdownToggle color="link" className="p-0 lh-1 me-1 ms-1">
            <img alt="actions" src={threeDots} height="12" width="12" />
          </DropdownToggle>
          <DropdownMenu modifiers={[{ name: 'preventOverflow' }]} strategy="fixed">
            {permissionFilteredActions &&
              permissionFilteredActions.map((el, i) => (
                <DropdownItem key={i.toString()} onClick={el.onClick} disabled={el?.disabled}>
                  {el.name}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </>
      )}
    </UncontrolledDropdown>
  ) : (
    <div className="chapters-tree__view-noactions" />
  );
};

ChapterActions.propTypes = {
  actions: PropTypes.array
};

export default ChapterActions;
