import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import Drawer, { DrawerHeader, FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import { useForm } from 'common/utils/hooks/useForm';
import { useFormState } from 'utils/hooks';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import SvgRender from 'common/components/general/SvgRender';

import filters from 'common/assets/svg/common/filters.svg';
import { components } from 'react-select';
import { optionField } from 'common/utils/form/fieldTypes';
import ShadowBox from 'common/components/general/ShadowBox';
import VesselSelector from 'common/components/selectors/VesselSelector';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import DateInput, { mode } from 'common/components/form/inputs/date';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { refetchAsyncOptions } from 'utils/helpers';

const config = {
  vessel: optionField(),
  company: optionField(),
  requisition: optionField({ required: true }),
  date: optionField()
};

const PurchaseCaseDrawer = ({ drawer, vessel, onLink }) => {
  const { formState, collectValues, loadValues, resetForm } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  const onClose = () => {
    drawer.close();
  };

  const onSave = () => {
    const values = collectValues();

    if (!values) return;

    onLink(values.requisition);
    onClose();
  };

  const requisitionListParams = useMemo(() => {
    return {
      vessel_id: vessel?.id || fields.vessel.value?.id,
      company_id: fields.company.value?.id,
      date_from:
        fields.date.value?.from && fields.date.value?.to
          ? fields.date.value?.from.format('YYYY-MM-DD')
          : undefined,
      date_to:
        fields.date.value?.from && fields.date.value?.to
          ? fields.date.value?.to.format('YYYY-MM-DD')
          : undefined
    };
  }, [
    vessel?.id,
    fields.vessel.value?.id,
    fields.company.value?.id,
    fields.date.value?.from,
    fields.date.value?.to
  ]);

  useEffect(() => {
    if (!drawer.isOpen) {
      resetForm();
    } else if (drawer.isOpen && vessel) {
      loadValues({ vessel });
    }
  }, [drawer.isOpen, loadValues, resetForm, vessel]);

  return (
    <Drawer className="link-purchase-case-drawer" {...drawer} css={style} close={onClose}>
      <DrawerHeader>Link to Purchase Case</DrawerHeader>
      <FormDrawer>
        {drawer?.isOpen ? (
          <FormBody>
            <ShadowBox className="cpx-12 cpy-12 mb-2" flat>
              <div className="d-flex align-items-center fs-12 lh-1 text-violet fw-medium mb-1 pb-1 border-bottom">
                <SvgRender className="me-1" src={filters} style={{ width: 14, height: 14 }} />
                Filter Selection
              </div>
              <Row className="mb-2" noGutters>
                <Col xs={6} className="cpe-4">
                  <VesselSelector
                    isMulti={false}
                    isClearable={true}
                    autoFocus={false}
                    size="sm"
                    label="Vessel"
                    placeholder="Select vessel"
                    onChange={selectField('vessel')}
                    disabled={fields.company.value || vessel}
                    {...fields.vessel}
                  />
                </Col>
                <Col xs={6} className="cps-4">
                  <AsyncSelector
                    onChange={selectField('company')}
                    placeholder="Select company"
                    label="Company"
                    type="companies"
                    disabled={fields.vessel.value}
                    {...fields.company}
                  />
                </Col>
                <Col xs={12}>
                  <DateInput
                    activeMode={mode.range.date}
                    label="DATE"
                    className="mb-0"
                    onChange={selectField('date')}
                    {...fields.date}
                  />
                </Col>
              </Row>
            </ShadowBox>

            <AsyncSelector
              onChange={selectField('requisition')}
              placeholder="Select requisition"
              label="PURCHASING CASE"
              type="purchasing-requisitions"
              getOptionLabel={option => `${option.code} ${option.description}`}
              components={{ Option, SingleValue }}
              listParams={requisitionListParams}
              defaultOptionsTriggerChange={`${vessel?.id || fields.vessel.value?.id}_${
                fields.company.value?.id
              }_${fields.date.value?.from?.toString()}_${fields.date.value?.to?.toString()}`}
              defaultOptions={() =>
                refetchAsyncOptions('purchasing-requisitions', requisitionListParams)
              }
              {...fields.requisition}
            />
          </FormBody>
        ) : null}
      </FormDrawer>
      <FormFooter>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={onClose}>
          CANCEL
        </Button>
        <Button onClick={onSave} color="primary" className="px-2">
          LINK
        </Button>
      </FormFooter>
    </Drawer>
  );
};

const Option = props => {
  return (
    <components.Option {...props}>
      <strong className={'fs-10 pe-2'}>{props.data.code}</strong>
      {props.data.description}
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <strong className={'fs-10 pe-2'}>{props.data.code}</strong>
    {props.data.description}
  </components.SingleValue>
);

const style = css`
  width: calc(${variables.drawerWidthSm} - ${variables.size16}) !important;
`;

PurchaseCaseDrawer.propTypes = {
  drawer: DrawerState.isRequired,
  vessel: PropTypes.object,
  onLink: PropTypes.func
};

export default PurchaseCaseDrawer;
