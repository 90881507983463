import { components, OptionProps } from 'react-select';
import { getPartyDisplayName } from 'common/utils/labels';
import { Link } from 'react-router-dom';

import EntityLabel from 'common/components/labels/EntityLabel';
import Priority from 'common/components/general/Priority';
import paths from 'routing/routes/_paths';
import { showSparePartsNumber } from '@/common/utils/form/display';
import { SparePartBase } from '@/common/types/pms';
import { FC } from 'react';

export const getDepartmentColor = data =>
  data && data.departments && data.departments[0] ? (
    <EntityLabel type="department" color={data.departments[0].color} bullet></EntityLabel>
  ) : (
    ''
  );

export const getDepartmentName = data => {
  const department = data?.departments?.[0];

  return department ? (
    <EntityLabel type="department" color={department?.color}>
      {department?.name}
    </EntityLabel>
  ) : null;
};

export const PersonWithDepartment = ({ person, link, displayClassname, className = '' }) => {
  const Name = () => (
    <span
      className={`${
        displayClassname ? displayClassname : 'text-primary d-flex align-items-center'
      }`}
    >
      {getPartyDisplayName(person)}
    </span>
  );

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {getDepartmentColor(person)}
      {link ? (
        <Link to={`${paths.persons}/${person.id}`}>
          <Name />
        </Link>
      ) : (
        <Name />
      )}
    </div>
  );
};

export const PersonWithDepartmentOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <PersonWithDepartment person={props.data} />
    </components.Option>
  );
};

export const getReportPathname = (currentPathName, report, newReport) => {
  const updatedLocation = currentPathName.split('/');
  const reportIndex = updatedLocation.findIndex(part => part === report);

  updatedLocation[reportIndex] = newReport;

  return updatedLocation.slice(0, reportIndex + 1).join('/');
};

export const ImportanceMultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.label ? (
        <div className="d-flex align-items-center justify-content-center h-100p">
          <Priority value={props.data.label} name={props.data.name} withText />
        </div>
      ) : null}
    </components.MultiValueLabel>
  );
};

export const ImportanceSingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.label ? (
        <div className="d-flex align-items-center justify-content-center h-100p">
          <Priority value={props.data.label} name={props.data.name} withText />
        </div>
      ) : null}
    </components.SingleValue>
  );
};

export const ImportanceOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.label ? (
        <Priority value={props.data.label} name={props.data.name} withText />
      ) : null}
    </components.Option>
  );
};

export const PartyDisplayMultiValue = ({ children, ...props }) => {
  if (props.data.full_name && props.data.full_name.length) {
    return (
      <components.MultiValueLabel {...props}>{props.data.full_name}</components.MultiValueLabel>
    );
  } else if (props.data.first_name || props.data.last_name) {
    return (
      <components.MultiValueLabel
        {...props}
      >{`${props.data.first_name} ${props.data.last_name}`}</components.MultiValueLabel>
    );
  } else {
    return (
      <components.MultiValueLabel {...props}> {props.data.name || ''}</components.MultiValueLabel>
    );
  }
};

export const PartyDisplaySingleValue = ({ children, ...props }) => {
  if (props.data.full_name && props.data.full_name.length) {
    return <components.SingleValue {...props}>{props.data.full_name}</components.SingleValue>;
  } else if (props.data.first_name || props.data.last_name) {
    return (
      <components.SingleValue
        {...props}
      >{`${props.data.first_name} ${props.data.last_name}`}</components.SingleValue>
    );
  } else {
    return <components.SingleValue {...props}> {props.data.name || ''}</components.SingleValue>;
  }
};

export const PartyDisplayOption = ({ children, ...props }) => {
  if (props.data.full_name && props.data.full_name.length) {
    return <components.Option {...props}>{props.data.full_name}</components.Option>;
  } else if (props.data.first_name || props.data.last_name) {
    return (
      <components.Option
        {...props}
      >{`${props.data.first_name} ${props.data.last_name}`}</components.Option>
    );
  } else {
    return <components.Option {...props}> {props.data.name || ''}</components.Option>;
  }
};

export const SparePartOption: FC<OptionProps<SparePartBase>> = ({ ...props }) => {
  return (
    <components.Option {...props}>
      <div className="d-flex w-100p">
        {showSparePartsNumber(props.data.drawing_no) ? (
          <>
            <strong>{props.data.drawing_no}</strong>
            &nbsp;-&nbsp;
          </>
        ) : null}
        {props.data.part_no}
        {'. '}
        {props.data.description}
      </div>
    </components.Option>
  );
};
