import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW'),
  ...asyncTypes('GET_PROMOTION_PLAN_ACTIVE'),
  ...asyncTypes('SET_PROMOTION_PLAN'),
  ...asyncTypes('EDIT_PROMOTION_PLAN'),
  ...asyncTypes('DELETE_PROMOTION_PLAN'),
  ...asyncTypes('CHANGE_PASSWORD'),
  ...asyncTypes('CREATE_CREW'),
  ...asyncTypes('UPDATE_CREW'),
  ...asyncTypes('UNARCHIVE_CREW'),
  ...asyncTypes('ARCHIVE_CREW'),
  ...asyncTypes('UPDATE_CREW_STATUS'),
  ...asyncTypes('UPDATE_CREW_STATUS_HISTORY'),
  ...asyncTypes('GET_CREW_STATUS_HISTORY'),

  SET_CREW_PROFILE: 'SET_CREW_PROFILE',
  RESET_CREW_PROFILE: 'RESET_CREW_PROFILE',
  SET_ACTIVE_CREW_PROFILE_NOTE: 'SET_ACTIVE_CREW_PROFILE_NOTE'
};

export default TYPES;
