import { FC } from 'react';

import CircledButton from 'common/components/buttons/CircledButton';
import { ModuleType } from '@/common/types/events';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

import SvgRender from 'common/components/general/SvgRender';
import Tooltip from '@/ts-common/components/general/Tooltip';

type ModuleProps = {
  index: number;
  module: ModuleType;
  isActive: boolean;
  isEditingModules: boolean;
  dragged: string | null | undefined;
  icon: string;
  onModulePress: (label: string) => void;
  setDragged: (id: string | null) => void;
  handleModuleReorder: (fromIndex: number, toIndex: number, id: string) => void;
};

const Module: FC<ModuleProps> = ({
  index,
  module,
  isActive,
  isEditingModules,
  dragged,
  icon,
  onModulePress,
  setDragged,
  handleModuleReorder
}) => {
  const { label, name } = module.type;
  const { avoidRender, tooltipID } = useTooltipID('module-tooltip');

  return (
    <div
      id={tooltipID}
      onClick={() => onModulePress(label)}
      key={module.id.toString()}
      className="event-toolbar__single-module d-flex flex-column align-items-center cursor-pointer position-relative"
      data-cy={name}
      data-id={module.id}
      draggable="true"
      onDragStart={e => {
        setDragged(e.currentTarget.getAttribute('data-id'));
        e.dataTransfer.setData('text/plain', index.toString());
      }}
      onDragOver={e => {
        e.preventDefault();
      }}
      onDrop={e => {
        const fromIndex = e.dataTransfer.getData('text/plain');
        const toIndex = index;
        if (dragged) {
          handleModuleReorder(parseInt(fromIndex), toIndex, dragged);
        }
        setDragged(null);
      }}
    >
      <SvgRender src={icon} style={{ width: 56, height: 56 }} />

      {isActive && !isEditingModules && <div className="event-toolbar__selected-dot" />}

      {isEditingModules ? (
        <CircledButton className="event-toolbar-delete" svgStyle={{ width: 7 }} type={'remove'} />
      ) : null}

      {!avoidRender && (
        <Tooltip target={tooltipID} innerClassName="fs-12 lh-15 cpx-6 py-1">
          {name}
        </Tooltip>
      )}
    </div>
  );
};

export default Module;
