import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useActions, useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';

import { selectIsMultipleEventsCreate } from 'events/store/events/selectors';
import * as eventActions from 'events/store/events/actions';

import config from './config';
import CircledButton from 'common/components/buttons/CircledButton';
import DatePicker from 'events/components/date-picker';

const MultipleEventDates = ({ memoizedDebounce, isCreate }) => {
  const [changeEventValue] = useActions([eventActions.changeEventValue]);

  const { formState } = useForm(config);

  const { subStates, addSubform, removeSubform } = useFormState(formState);

  const dates = subStates('dates');

  const isMultipleEvents = useSelector(selectIsMultipleEventsCreate);

  return (
    <div className="mb-2">
      {dates?.map((date, index) => (
        <Row key={index} className="align-items-center mb-2 flex-nowrap">
          <Col>
            <DatePicker
              hasLabel={false}
              isCreate={isCreate}
              event={date}
              changeEventValue={changeEventValue}
              memoizedDebounce={memoizedDebounce}
              className="mb-0"
              isMultipleEvents={isMultipleEvents}
              index={index}
            />
          </Col>
          {index > 0 ? (
            <Col xs="auto" className="d-flex align-items-center">
              <CircledButton
                type={'remove'}
                onClick={() => removeSubform('dates', index)}
                svgStyle={{ width: 8, height: 8 }}
                svgWrapperClassName="width-16 height-16 min-width-16 cme-6"
              />
            </Col>
          ) : null}
        </Row>
      ))}

      <CircledButton
        type={'add'}
        label="Add more"
        className="cmt-12 mb-1 ms-1"
        onClick={() => addSubform('dates')}
        style={{ width: 20, height: 20 }}
      />
    </div>
  );
};

export default MultipleEventDates;

MultipleEventDates.propTypes = {
  memoizedDebounce: PropTypes.func,
  isCreate: PropTypes.bool
};
