import React from 'react';
import { Row, Col } from 'reactstrap';

import Totals from './totals';
import CrewChange from './crew-change';
import Forms from './forms';
import Jobs from './jobs';

import permissions from 'common/utils/permissions/constants';
import Evaluations from './evaluations';
import Events from './events';
import AuthCheck from 'components/permissions/AuthCheck';
import UnreadMessages from 'common/components/chatbox/unread-messages';
import Findings from './findings';
import FlagExtensionDispensation from './flag-extension-dispensation';
import ToBeDeliveredPurchasingCases from './purchasing-cases/dlv';

const Dashboard = () => {
  return (
    <div className="dashboard pb-4">
      <h1 className="narrow-black fs-24 text-primary mt-0">Fast Track</h1>
      <h2 className="fs-14 text-violet mt-0 mb-6">Onboard Dashboard</h2>
      <Row className="me-2">
        <Col className="col-8 col-hd-9">
          <Totals />
          <AuthCheck permissions={[permissions.onboard.dashboard.crew_switches_widget.list]}>
            <CrewChange />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.forms_widget.list]}>
            <Forms name="FOR CORRECTIONS" label="forms_for_correction" />
            <Forms name="FORM OBLIGATIONS" label="forms_obligations" />
            <Forms name="OVERDUE PERIODICAL FORMS" label="forms_periodical_forms" />
            <Forms name="TO FINALIZE" label="forms_to_finalize" />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.jobs_widget.list]}>
            <Jobs />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.evaluations_widget.list]}>
            <Evaluations name="PENDING MY ACTIONS" />
            <Evaluations name="PENDING EVALUATEE CONSENT" />
            <Evaluations name="EVALUATIONS" />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.events_widget.list]}>
            <Events />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.findings_widget.list]}>
            <Findings />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.jobs_widget.list]}>
            <FlagExtensionDispensation />
          </AuthCheck>

          <AuthCheck permissions={[permissions.onboard.dashboard.purchasing_cases_widget.list]}>
            <ToBeDeliveredPurchasingCases />
          </AuthCheck>
        </Col>
      </Row>

      {/* <AuthCheck
        permissions={[
          permissions.onboard.dashboard.card_forms_widget.view,
          permissions.onboard.dashboard.card_reports_widget.view
        ]}
      > */}
      <div className="unread-messages_wrapper">
        <UnreadMessages />
      </div>
      {/* </AuthCheck> */}
    </div>
  );
};

export default Dashboard;
