import { createSelector } from 'reselect';

const selectPersonsReducer = state => state.persons;

export const selectPersonProfile = createSelector(
  [selectPersonsReducer],
  persons => persons.profile
);

export const selectPersonTitle = state =>
  state.persons.profile && state.persons.profile.full_name ? state.persons.profile.full_name : '';

export const selectPersonProfileIsLoading = createSelector(
  [selectPersonsReducer],
  persons => persons.profileIsLoading
);

export const selectPersonSecurity = state => selectPersonsReducer(state)?.security;

export const selectPersonAccessIsGranted = state => selectPersonSecurity(state)?.can_login;
