import { Row, Col } from 'reactstrap';

import ChatEntityIcon from 'common/components/chatbox/components/ChatEntityIcon';
import ChatEntityName from 'common/components/chatbox/components/ChatEntityName';

import _get from 'lodash/get';
import moment from 'moment';
import { dateToLocal } from 'common/utils/dates';
import { getEntityLocationPath } from 'common/components/chatbox/_helpers';

import ColoredLabel from 'common/components/labels/ColoredLabel';
import ProfileImagesPreview from 'common/components/general/ProfileImagesPreview';

import avatar from 'common/assets/svg/avatars/crew.svg';

import PropTypes from 'prop-types';

const MessagesBox = ({ message }) => {
  const onMessageClick = () => {
    window.open(
      `${window.location.origin}${getEntityLocationPath(message.entity_type, message)}/${
        message.entity_id
      }`,
      '_blank'
    );
  };

  return (
    <div
      className="global-unread-chat-messages__box pointer cpx-12 cpy-10"
      onClick={onMessageClick}
    >
      <Row className="align-items-center flex-nowrap" noGutters>
        <Col xs="auto" className="d-flex align-items-center">
          <ChatEntityIcon
            entityType={message.entity_type}
            reportTemplateType={_get(message, 'report.template.type')}
            jobType={_get(message, 'job.type')}
          />
        </Col>
        <Col className="ps-2 pe-1 overflow-hidden fs-12">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <strong className="text-primary">{`${message.count} new`}</strong>
              {message.mention_count > 0 && (
                <ColoredLabel
                  color="boysenberry-shadow"
                  className="text-moody-blue fw-500 border-radius-3 cms-6 cpx-4"
                  text={`${message.mention_count}@`}
                />
              )}
              {message?.parties?.length > 0 && (
                <ProfileImagesPreview
                  wrapperClassName="cms-12"
                  parties={message?.parties}
                  fallback={avatar}
                  slots={3}
                />
              )}
            </div>
            <span className="text-violet">
              {message.show_full_date
                ? `${moment(message.last_received_at).format('DD MMM YYYY, HH:mm')} UTC`
                : dateToLocal(message.last_received_at).fromNow(true)}
            </span>
          </div>
          <div className="overflow-hidden w-100p text-primary">
            <ChatEntityName
              entityType={message.entity_type}
              report={message.report}
              job={message.job}
              formSubmission={message.form_submission}
              purchasingRequisition={message.requisition}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

MessagesBox.propTypes = {
  message: PropTypes.object.isRequired
};

export default MessagesBox;
