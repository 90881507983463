import { useCallback, useState } from 'react';
import { useDrawer } from 'common/components/drawer';
import { useDispatch } from 'react-redux';
import { setSelectedJob } from 'common/components/jobs/_base/store/actions';

import PropTypes from 'prop-types';
import LinkedJobs from './LinkedJobs';
import JobDrawer from './JobDrawer';
import { setPrefilledVessel } from 'common/components/jobs/_base/store/actions';
import { VesselType } from 'common/entities/vessels/VesselTypes';

import add from 'common/assets/svg/actions/add.svg';
import CircledButton from 'common/components/buttons/CircledButton';
import OvalButton from 'common/components/buttons/OvalButton';
import LinkedTabModuleActions from '../_components/LinkedTabModuleActions';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const LinkedJobEntities = ({
  linkedJobs,
  vessel,
  addLinkedJob,
  removeLinkedJob,
  cancelTabAction,
  tabLabel
}) => {
  const [jobPreviewState, setJobPreviewState] = useState(null);
  const [isCreatingJob, setIsCreatingJob] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(!linkedJobs?.length);
  const jobDrawer = useDrawer(false);
  const dispatch = useDispatch();

  const toggleJobDrawer = useCallback(
    (isCreating, job = null) => {
      setJobPreviewState(job);
      dispatch(setSelectedJob(job));
      setIsCreatingJob(isCreating);
      dispatch(setPrefilledVessel(vessel));
      jobDrawer.open();
    },
    [dispatch, vessel, jobDrawer]
  );

  return (
    <>
      <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <div className="d-flex align-items-center height-20">
          <div className="flex-1 form-label mb-0">LINKED JOBs</div>
          {tabLabel ? (
            <LinkedTabModuleActions
              showDeleteButton={!showCancelButton && isHovering}
              showCancelButton={showCancelButton}
              onCancel={() => cancelTabAction(tabLabel)}
              onDelete={() => removeLinkedJob()}
            />
          ) : null}
          <AuthCheck permissions={[permissions.office.jobs.create]}>
            <OvalButton
              onClick={() => toggleJobDrawer(true)}
              type="edit"
              icon={add}
              text="Create Job"
              size="sm"
              className="ms-1"
            />
          </AuthCheck>
        </div>
        <LinkedJobs
          jobs={linkedJobs}
          removeLinkedJob={index => {
            removeLinkedJob(index);
            setShowCancelButton(false);
          }}
          previewLinkedJob={job => toggleJobDrawer(false, job)}
        />
      </div>

      <CircledButton
        className="mt-1"
        type="link"
        label="Link to existing Job"
        onClick={() => toggleJobDrawer(false)}
        svgStyle={{ width: 10, height: 10 }}
        style={{ width: 20, height: 20 }}
      />

      <JobDrawer
        drawer={jobDrawer}
        jobPreviewState={jobPreviewState}
        isCreatingJob={isCreatingJob}
        addLinkedJob={job => {
          addLinkedJob(job);
          setShowCancelButton(false);
        }}
      />
    </>
  );
};

LinkedJobEntities.propTypes = {
  linkedJobs: PropTypes.array.isRequired,
  addLinkedJob: PropTypes.func,
  removeLinkedJob: PropTypes.func,
  vessel: VesselType,
  tabLabel: PropTypes.string,
  cancelTabAction: PropTypes.func
};

export default LinkedJobEntities;
