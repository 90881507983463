import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'reactstrap';
import ReportTitle from './ReportTitle';
import { useActions, useUpdateEffect } from 'utils/hooks';
import * as mgaActions from 'common/components/mga/store/actions';
import AccountTable from './AccountTable';
import _get from 'lodash/get';
import PageLoader from 'common/components/general/PageLoader';
import {
  selectLatestPeriod,
  selectRefetchTableTrigger
} from 'common/components/mga/store/selectors';
import { useSelector } from 'react-redux';

const BalanceSheet = () => {
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const latestPeriod = useSelector(selectLatestPeriod);
  const shouldRefetchTable = useSelector(selectRefetchTableTrigger);

  const [getMgaBalanceSheetReport] = useActions([mgaActions.getMgaBalanceSheetReport]);

  const fetchReport = useCallback(
    async period => {
      setIsLoading(true);

      try {
        const res = await getMgaBalanceSheetReport({
          filters: [
            {
              name: 'period_id',
              operation: '=',
              value: period
            }
          ]
        });
        setReportData(res.data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    },
    [getMgaBalanceSheetReport]
  );

  useEffect(() => {
    if (!latestPeriod?.id) return;
    fetchReport(latestPeriod?.id);
  }, [latestPeriod?.id, fetchReport]);

  useUpdateEffect(() => {
    if (shouldRefetchTable && latestPeriod?.id) {
      fetchReport(latestPeriod?.id);
    }
  }, [shouldRefetchTable, fetchReport, latestPeriod?.id]);

  return (
    <>
      <ReportTitle />

      <Row className="mt-3">
        <Col xs={6}>
          <div className="d-flex align-items-center justify-content-end text-primary fs-14 bg-white rounded-sm pe-3">
            <strong className="fs-14">DEBITS&nbsp;</strong> USD
          </div>
        </Col>
        <Col xs={6}>
          <div className="d-flex align-items-center justify-content-end text-primary fs-14 bg-white rounded-sm pe-3">
            <strong className="fs-14">CREDITS&nbsp;</strong> USD
          </div>
        </Col>
      </Row>

      {reportData?.length
        ? reportData.map((accData, index) => {
            const shouldShowBorder = !!_get(accData, 'debits.length');
            const isLastIndex = index + 1 === reportData.length;

            return (
              <Row
                key={`wrapper-${accData?.account?.id}`}
                className={shouldShowBorder ? 'border-bottom pb-4' : ''}
              >
                <Col xs={6} className="d-flex flex-column justify-content-between">
                  <AccountTable
                    actions={_get(accData, 'debits')}
                    openingBalance={_get(accData, 'totals.opening_debit')}
                    closingBalance={_get(accData, 'totals.closing_debit')}
                    accountName={_get(accData, 'account.name')}
                    totals={_get(accData, 'totals.total_debit')}
                  />
                </Col>
                <Col xs={6} className="d-flex flex-column justify-content-between">
                  <AccountTable
                    actions={_get(accData, 'credits')}
                    openingBalance={_get(accData, 'totals.opening_credit')}
                    closingBalance={_get(accData, 'totals.closing_credit')}
                    accountName={_get(accData, 'account.name')}
                    totals={_get(accData, 'totals.total_credit')}
                    contracts={_get(accData, 'contracts')}
                    contractTotals={_get(accData, 'totals.contracts')}
                  />
                </Col>

                {isLastIndex ? <div className="w-100p border-bottom cmt-4" /> : null}
              </Row>
            );
          })
        : null}

      <PageLoader isLoading={isLoading} />
    </>
  );
};

export default BalanceSheet;
