import PropTypes from 'prop-types';
import DateWithIcon from 'common/components/dates/DateWithIcon';
import moment from 'moment';

const ExpirationDate = ({ date }) => {
  const isDateExpired = date ? moment(date)?.isBefore(moment().format('YYYY-MM-DD')) : false;

  if (date)
    return (
      <DateWithIcon hideIcon={true} className={`${isDateExpired ? 'text-red' : ''}`} date={date} />
    );

  return <span className="text-violet fs-12 fw-medium">No expiration</span>;
};

ExpirationDate.propTypes = {
  date: PropTypes.string
};

export default ExpirationDate;
