import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import CkEditor from 'common/components/form/inputs/ckeditor';
import Switch from 'common/components/form/inputs/Switch';

import InputSaveActions from './InputSaveActions';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';

import { useActions, usePrevious } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectJobId,
  selectIsA4Layout,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as jobRestrictedFieldsActions from 'store/jobs-fields/actions';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';

const Remarks = ({ className, avoidCheck = false, showA4Layout = true }) => {
  const [setJobField, setJobFieldError, updateJob, checkIfFieldIsRequired, setIsA4Layout] =
    useActions([
      jobProfileActions.setJobField,
      jobProfileActions.setJobFieldError,
      jobProfileActions.updateJob,
      jobRestrictedFieldsActions.checkIfFieldIsRequired,
      jobProfileActions.setIsA4Layout
    ]);
  const isEditMode = useSelector(checkIfEditMode);
  const [isFocused, setIsFocused] = useState(false);
  const [hasBeenCanceledOrSaved, setHasBeenCanceledOrSaved] = useState(false);

  const isA4Layout = useSelector(selectIsA4Layout) && showA4Layout;
  const isJobLocked = useSelector(selectIsJobLocked);

  const jobRemarks = useSelector(state => selectJobField(state, 'remarks'));
  const jobRemarksError = useSelector(state => selectJobFieldError(state, 'remarks'));

  const jobId = useSelector(selectJobId);

  const [remarks, setRemarks] = useState(jobRemarks);

  const prevIsFocused = usePrevious(isFocused);

  useEffect(() => {
    if (prevIsFocused && !isFocused && remarks !== jobRemarks && !hasBeenCanceledOrSaved) {
      updateJob({ remarks: jobRemarks });
      setRemarks(jobRemarks);
    }
    setHasBeenCanceledOrSaved(() => false);
  }, [isFocused, remarks, jobRemarks]);

  const createMarkup = () => {
    return {
      __html: jobRemarks
    };
  };

  useEffect(() => {
    if (jobId) {
      setRemarks(jobRemarks);
    }
  }, [jobId]);

  const renderFallback = () => {
    return (
      <>
        <Label className="form-label lh-1 ">Remarks</Label>
        <div className="fw-light text-primary pb-2 pt-1" dangerouslySetInnerHTML={createMarkup()} />
      </>
    );
  };

  return (
    <div
      className={`job-profile__description job-input job-input--with-actions job-input--description ${
        isFocused ? 'is-focused' : ''
      } ${className || ''}`}
    >
      <UserCanEdit field="description" fallback={renderFallback} avoidCheck={avoidCheck}>
        <>
          <div className="d-flex job-profile__description-title justify-content-between align-items-center mb-1">
            <span className="form-label">Remarks</span>
            {showA4Layout ? (
              <div className="d-flex justify-content-end align-items-center">
                <span className="form-label pe-1">A4 LAYOUT</span>
                <Switch
                  value={isA4Layout}
                  className="mb-0"
                  onChange={() => setIsA4Layout(!isA4Layout)}
                  size={'sm'}
                />
              </div>
            ) : null}
          </div>
          <div>
            <CkEditor
              isFocused={isFocused}
              onFocus={focus =>
                focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100)
              }
              className={`job-description-editor mb-0 ${
                isFocused ? '' : 'job-description-editor--hide'
              } ${isA4Layout ? 'mt-1' : ''}`}
              placeholder={isJobLocked ? ' ' : 'Add some text…'}
              id="job-remarks"
              isFullEditor={isA4Layout}
              maxHeight={isA4Layout ? '600px' : ''}
              minHeight={isA4Layout ? '' : '32px'}
              key={isJobLocked}
              onChange={v => {
                setJobFieldError({ remarks: null });
                setJobField('remarks', v);
              }}
              value={jobRemarks ? jobRemarks : ''}
              error={jobRemarksError ? REQUIRED_FIELD_MESSAGE : null}
              disabled={isJobLocked}
            />

            {!isEditMode ? (
              jobRemarksError ? (
                <div className="fs-10 text-coral text-end">*{jobRemarksError}</div>
              ) : null
            ) : null}

            {isEditMode ? (
              <InputSaveActions
                onCancel={() => {
                  setJobField('remarks', remarks);
                  setHasBeenCanceledOrSaved(true);
                  setTimeout(() => setIsFocused(false), 600);
                }}
                onSave={() => {
                  updateJob({ remarks: jobRemarks });
                  setRemarks(jobRemarks);
                  setHasBeenCanceledOrSaved(true);
                  setTimeout(() => setIsFocused(false), 600);
                }}
                disabled={!jobRemarks && checkIfFieldIsRequired('remarks')}
              />
            ) : null}
          </div>
        </>
      </UserCanEdit>
    </div>
  );
};

export default Remarks;
