import CircledButton from 'common/components/buttons/CircledButton';

import { useSelector } from 'react-redux';
import { isCreatingChapter, cannotEditManual } from 'manuals/store/selectors';

import Legends from './Legends';

const Footer = ({ handleChapterCreation }) => {
  const isLocked = useSelector(cannotEditManual);
  const showCreationForm = useSelector(isCreatingChapter);

  return (
    <div className="chapters-tree__footer--inner-manual">
      {!isLocked ? (
        <CircledButton
          type="add"
          className="text-primary fw-bold fs-12"
          label={'Add chapter'}
          onClick={handleChapterCreation}
          disabled={showCreationForm}
          style={{ width: 16, maxWidth: 16, height: 16 }}
          svgStyle={{ width: 8, height: 8 }}
        />
      ) : null}

      <Legends />
    </div>
  );
};

export default Footer;
