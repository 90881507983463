import React from 'react';
import { Col, Row } from 'reactstrap';
import { useFormState } from 'utils/hooks';

import DateInput from 'common/components/form/inputs/date';
import PortSelector from 'common/components/selectors/PortSelector';

const Body = ({ formState }) => {
  const { fields, selectField } = useFormState(formState);

  return (
    <div>
      <Row className="g-0">
        <Col>
          <DateInput
            label={'Sign on date & time'}
            onChange={date => selectField('sign_on_date_as_per_captain')(date)}
            showTime
            {...fields.sign_on_date_as_per_captain}
          />
        </Col>
      </Row>
      <Row className="no-gutter">
        <Col>
          <PortSelector
            label={'Sign on port'}
            placeholder="Select port"
            isMulti={false}
            filter={{ value: fields.sign_on_port_id_as_per_captain.value }}
            onChange={({ value }) => selectField('sign_on_port_id_as_per_captain')(value)}
            error={fields.sign_on_port_id_as_per_captain.error}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Body;
