import { useAppSelector } from '@/store/hooks';
import {
  selectVesselFilter,
  selectPeriodFilter,
  selectIsPastMga
} from 'common/components/mga/store/selectors';

const usePathConstructor = () => {
  const vesselFilter = useAppSelector(selectVesselFilter);
  const periodFilter = useAppSelector(selectPeriodFilter);
  const isPastMga = useAppSelector(selectIsPastMga);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const pathname =
    (isOnBoard && isPastMga && vesselFilter) || (!isOnBoard && vesselFilter)
      ? `/${vesselFilter}${periodFilter ? `/${periodFilter}` : ''}`
      : '';

  return pathname;
};

export default usePathConstructor;
