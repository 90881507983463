import paths from 'routing/routes/_paths';

import Crew from 'views/crew';
import CrewProfile from 'views/crew/profile';
import { selectCrewProfileName } from 'crew/store/profile/selectors';
import permissions from 'common/utils/permissions/constants';

const routes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: paths.crew,
    component: Crew,
    permissions: [permissions.onboard.crew.list.view],
    documentTitle: 'Crew'
  },
  {
    path: `${paths.crew}/:id`,
    app: true,
    type: 'private',
    exact: true,
    component: CrewProfile,
    permissions: [permissions.onboard.crew.seaman_profile.view],
    documentTitle: selectCrewProfileName
  },
  {
    path: `${paths.crew}/:id/:tab(personal|documents|evaluation|contracts|shifts)`,
    component: CrewProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.crew.seaman_profile.view],
    documentTitle: selectCrewProfileName
  },
  {
    path: `${paths.crew}/:id/documents/:category(stcw|travelling|medical|flag|non-stcw|company-reqs|other|license)`,
    component: CrewProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.onboard.crew.seaman_profile.documents.all_users.view,
      permissions.onboard.crew.seaman_profile.documents.own_profile.view
    ],
    documentTitle: selectCrewProfileName
  },
  {
    path: `${paths.crew}/:id/(evaluation)`,
    app: true,
    type: 'private',
    exact: true,
    component: CrewProfile,
    permissions: [
      permissions.onboard.crew.seaman_profile.evaluations.all_users.view,
      permissions.onboard.crew.seaman_profile.evaluations.own_profile.view
    ],
    documentTitle: selectCrewProfileName
  },
  {
    path: `${paths.crew}/:id/evaluation/:view(create)`,
    component: CrewProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.crew.seaman_profile.evaluations.all_users.create],
    documentTitle: selectCrewProfileName
  },
  {
    path: `${paths.crew}/:id/evaluation/:view(draft|view)/:evaluationID`,
    component: CrewProfile,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.onboard.crew.seaman_profile.evaluations.all_users.view,
      permissions.onboard.crew.seaman_profile.evaluations.own_profile.view
    ],
    documentTitle: selectCrewProfileName
  }
];
export default routes;
