import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState, usePrevious } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectActiveTemplateEvaluation,
  selectActiveTemplateEvaluationId
} from 'crew/store/evaluations/templates/selectors';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import config from './config';
import useRouter from 'use-react-router';
import moment from 'moment';
import ViewEvaluation from './view';
import EvaluationCreateDraft from './create-draft';
import paths from 'routing/routes/_paths';

import { cleanValues, getEvaluationCategories } from './helpers';
import _debounce from 'lodash/debounce';
import {
  createCrewEvaluation,
  deleteCrewEvaluation,
  getCrewEvaluation,
  setEvaluationEditing,
  updateCrewEvaluation,
  updateCrewEvaluationAverageScore
} from 'crew/store/evaluations/profile/actions';
import { fetchListOptions } from 'store/lists/actions';
import { getCrewEvaluationTemplate } from 'crew/store/evaluations/templates/actions';

const EvaluationForm = ({ isDraft, evaluationID, components, isOnboard }) => {
  const [active, setActive] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const templateId = useSelector(selectActiveTemplateEvaluationId);
  const evaluationTemplate = useSelector(selectActiveTemplateEvaluation);
  const prevEvaluationTemplate = usePrevious(evaluationTemplate);

  const dispatch = useDispatch();
  const { history, match } = useRouter();
  const { id } = match.params;

  const {
    formState,
    collectValues,
    collectMutatedValues,
    collectMutatedArrayValues,
    loadValues,
    hasErrors
  } = useForm(config);

  const { setFieldError } = useFormState(formState);

  const getEvaluation = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await dispatch(getCrewEvaluation({ evaluationID }));
      dispatch(getCrewEvaluationTemplate({ id: data.template_id }));
      setActive(data);

      const values = {
        ...data,
        rank_name: data.rank.name,
        vessel_name: data.vessel.name,
        training_types: data?.training_types,
        criteria: data.criteria_values
          .map(criterion => {
            const itemFromTemplate = getEvaluationCategories(data.template).find(
              item => _get(item, 'criterion_id') === _get(criterion, 'criterion_id')
            );

            return {
              ...criterion,
              ...itemFromTemplate,
              name: itemFromTemplate?.criterion?.name,
              value: criterion.value
            };
          })
          .filter(t => t)
      };
      loadValues(values);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }, [evaluationID, getCrewEvaluation, loadValues]);

  useEffect(() => {
    if (!_isEqual(prevEvaluationTemplate, evaluationTemplate)) {
      if (evaluationTemplate) {
        const oldValues = collectValues();

        const criteriaValues = getEvaluationCategories(evaluationTemplate).map(criterion => ({
          name: criterion.criterion.name,
          evaluate: true,
          ...criterion,
          value: null
        }));

        loadValues({
          ...oldValues,
          date: moment(),
          notes: '',
          criteria: criteriaValues
        });
      }
    }
  }, [collectValues, evaluationTemplate, loadValues, prevEvaluationTemplate]);

  useEffect(() => {
    if (evaluationID) {
      getEvaluation();
    }
    dispatch(setEvaluationEditing(false));
  }, [evaluationID, getEvaluation, setEvaluationEditing, dispatch]);

  useEffect(() => {
    if (
      active &&
      (isDraft || evaluationID) &&
      evaluationTemplate &&
      evaluationTemplate.id === active.template.id
    ) {
      // Load values from draft evaluation
      const values = {
        ...active,
        rank_name: active.rank?.name,
        vessel_name: active.vessel?.name,
        training_types: active.training_types,
        criteria: active.criteria_values
          ?.map(criterion => {
            const itemFromTemplate = getEvaluationCategories(evaluationTemplate).find(
              item => _get(item, 'criterion_id') === _get(criterion, 'criterion_id')
            );

            if (!itemFromTemplate) {
              console.error('Criterion not found: ', { criterion, active });
              return null;
            }

            return {
              ...criterion,
              ...itemFromTemplate,
              name: itemFromTemplate.criterion.name,
              value: criterion.value
            };
          })
          .filter(t => t)
      };
      loadValues(values);
    }
  }, [active, evaluationID, evaluationTemplate, isDraft, loadValues]);

  useEffect(() => {
    dispatch(fetchListOptions('scales'));
  }, [dispatch]);

  const getFields = (isEdit, data, values, criteria) => {
    let params = {};

    if (isEdit) {
      const {
        evaluatee_notes,
        evaluator_notes,
        training_types,
        office_only_notes,
        submission_notes,
        captain_notes,
        template_id,
        rank_id,
        vessel_id,
        period_end_date,
        period_start_date,
        recommended_for_promotion,
        recommended_for_reemployment,
        crew_evaluation_obligation_id,
        crew_evaluation_reason_id,
        date
      } = data;

      params = {
        evaluatee_notes,
        evaluator_notes,
        training_types,
        office_only_notes,
        submission_notes,
        captain_notes,
        template_id,
        rank_id,
        vessel_id,
        period_end_date,
        period_start_date,
        recommended_for_promotion,
        recommended_for_reemployment,
        crew_evaluation_obligation_id,
        crew_evaluation_reason_id,
        date
      };
    } else if (isDraft) {
      const {
        recommended_for_promotion,
        recommended_for_reemployment,
        crew_evaluation_obligation_id,
        crew_evaluation_reason_id
      } = data;
      params = {
        recommended_for_promotion,
        recommended_for_reemployment,
        crew_evaluation_obligation_id,
        crew_evaluation_reason_id,
        ...values,
        criteria
      };
    } else {
      params = { ...data };
    }

    return params;
  };

  const onSave = async (extraParams = {}, isEdit = false, skipCriteria) => {
    const values = collectMutatedValues();
    const { ...rest } = collectValues();
    const { criteria } = collectMutatedArrayValues([], true);
    const fields = getFields(isEdit, rest, values, !skipCriteria ? criteria : []);

    if (!fields.crew_evaluation_obligation_id && !fields.crew_evaluation_reason_id) {
      setFieldError('crew_evaluation_obligation_id', 'Reason is required');
      setFieldError('crew_evaluation_reason_id', 'Reason is required');
      return;
    }

    if (!values || (hasErrors && !extraParams.isDraft)) return;

    if (
      values?.criteria
        ?.filter(v => v.evaluate)
        ?.find(value => (!value.value || value.value === '') && value.value !== 0) &&
      !extraParams.isDraft
    )
      return;

    const params = {
      id: evaluationID || id,
      ...(!active?.submitted ? cleanValues(fields, evaluationTemplate) : []),
      ...extraParams
    };

    setIsSaving(true);
    try {
      if (evaluationID) {
        await dispatch(updateCrewEvaluation(params));
      } else {
        await dispatch(createCrewEvaluation(params));
      }

      setIsSaving(false);
      history.push(`${paths.crew}/${id}/evaluation`);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const onDelete = async () => {
    await dispatch(deleteCrewEvaluation({ id: evaluationID }));
    history.push(`${paths.crew}/${id}/evaluation`);
  };

  const getOverridenCriteria = (formValues, key, value, categoryId, sortIndex) => {
    const criteria = formValues.criteria.reduce((prev, criterion) => {
      if (criterion.value_type !== 'scale') return prev;

      if (criterion.category_id === categoryId && criterion.sort_index === sortIndex) {
        criterion[key] = value;

        prev.push(criterion);
        return prev;
      }

      if (!criterion?.value) return prev;

      prev.push(criterion);
      return prev;
    }, []);

    return criteria;
  };

  const calculateAverageScore = useCallback(
    async (key, value, categoryId, sortIndex) => {
      const formValues = collectValues(formState, config);
      const criteria = getOverridenCriteria(formValues, key, value, categoryId, sortIndex);

      if (!templateId || !criteria?.length) return;

      await dispatch(
        updateCrewEvaluationAverageScore({
          template_id: templateId,
          criteria: criteria
        })
      );
    },
    [collectValues, formState, templateId, dispatch]
  );

  const onRecalculateAverageScore = useMemo(
    () => _debounce(calculateAverageScore, 1000),
    [calculateAverageScore]
  );

  const mode = evaluationID ? (isDraft ? 'draft' : 'view') : 'create';

  if (isLoading) return null;

  return (
    // This is for firefox issues
    <div style={{ filter: 'blur(0px)' }}>
      {mode !== 'view' ? (
        <EvaluationCreateDraft
          formState={formState}
          evaluationID={evaluationID}
          isDraft={isDraft}
          isSaving={isSaving}
          evaluationTemplate={evaluationTemplate}
          onSave={onSave}
          onDelete={onDelete}
          onRecalculateAverageScore={onRecalculateAverageScore}
          id={id}
          isOnboard={isOnboard}
          isCreatedOnVessel={mode === 'create' ? isOnboard : active?.is_created_on_vessel}
          hasErrors={hasErrors}
        />
      ) : (
        <ViewEvaluation
          formState={formState}
          active={active}
          isOnboard={isOnboard}
          components={components}
          isCreatedOnVessel={active?.is_created_on_vessel}
          onRecalculateAverageScore={onRecalculateAverageScore}
          isSaving={isSaving}
          onSave={onSave}
          hasErrors={hasErrors}
        />
      )}
    </div>
  );
};

export default EvaluationForm;
