import React, { useEffect } from 'react';

import { components } from 'react-select';
import Select from 'common/components/form/inputs/Select';
import Priority from 'common/components/general/Priority';
import { useActions } from 'utils/hooks';
import * as listActions from 'store/lists/actions';
import { useSelector } from 'react-redux';

const ImportanceSelector = ({ filter, onChange, isMulti, label, placeholder }) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const importances = useSelector(state => state.lists['event-importances']);

  useEffect(() => {
    if (!importances.options.length && !importances.isFetching) {
      fetchListOptions('event-importances');
    }
  }, []);

  return (
    <Select
      label={label}
      autoFocus
      placeholder={placeholder ? placeholder : 'Select value'}
      components={{ Option, MultiValueLabel }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter.value}
      options={importances?.options ? importances.options : []}
      isMulti={isMulti}
      onChange={selected => onChange({ value: selected })}
      isClearable
    />
  );
};

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel>
      <div className="d-flex align-items-center justify-content-center h-100p">
        <Priority value={props.data.label} withText />
      </div>
    </components.MultiValueLabel>
  );
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.label ? <Priority value={props.data.label} withText /> : null}
    </components.Option>
  );
};

export default ImportanceSelector;
