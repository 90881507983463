import _get from 'lodash/get';

const selectDataFromState = (state, config) => {
  // state = data object, config = useForm config
  const data = { ...state };

  if (!data) return;

  const values = Object.keys(config).reduce((acc, key) => {
    if (data[key] === false) acc[key] = false;
    else {
      acc[key] = _get(data, key, null) || null;
    }

    return acc;
  }, {});

  return values;
};

export default selectDataFromState;
