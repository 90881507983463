import React from 'react';
import promotedIcon from 'common/assets/svg/common/promoted.svg';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import PropTypes from 'prop-types';

const PromotionLabel = ({ initialRank, promotionRank }) => {
  const { avoidRender, tooltipID } = useTooltipID('promotion-label');

  if (avoidRender) return null;

  return (
    <>
      <SvgRender
        id={tooltipID}
        src={promotedIcon}
        className="cursor-pointer"
        style={{ width: 14, height: 14 }}
      />
      <Tooltip target={tooltipID} placement="top" innerClassName="max-width-none cpx-6 py-1">
        <div className="text-start fw-300 fs-12">
          <div>
            Initial Rank: <span className="fw-500">{initialRank}</span>
          </div>
          <div>
            Promoted Rank: <span className="fw-500">{promotionRank}</span>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

PromotionLabel.propTypes = {
  initialRank: PropTypes.string.isRequired,
  promotionRank: PropTypes.string.isRequired
};

export default PromotionLabel;
