import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import PortsAtSea from 'common/components/selectors/port-at-sea';
import _debounce from 'lodash/debounce';

const PlaceOfOccurence = ({ value, onChange, disabled, error }) => {
  const [port, setPort] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);

  useEffect(() => {
    if (disabled) return;

    setPort(value?.port);
    setLat(value?.lat);
    setLon(value?.lon);
  }, []);

  const saveItems = newValue => {
    if (disabled) return;

    onChange(newValue);
  };

  const memoizedDebounce = useCallback(_debounce(saveItems, 600), [disabled]);

  const onInputChange = name => opt => {
    if (disabled) return;
    if (name === 'port') {
      setPort(opt);
    } else if (name === 'coords') {
      setLat(opt.lat);
      setLon(opt.lon);
    } else if (name === 'lat') {
      setLat(opt);
    } else if (name === 'lon') {
      setLon(opt);
    }
  };

  useEffect(() => {
    const newValue = { port_id: port?.id || null, port: port, lat: lat, lon: lon };

    memoizedDebounce(newValue);
  }, [port, lat, lon]);

  return (
    <Row>
      <Col xs={12} className={disabled ? 'pointer-events-none' : ''}>
        <PortsAtSea
          onlyPort
          groupCoords
          autoFocus={false}
          direction="up"
          coordinates={{ lat: { value: lat }, lon: { value: lon } }}
          placeholder="Select"
          value={disabled ? null : port}
          error={error}
          selectField={onInputChange}
          saveBtnTitle="SAVE"
        />
      </Col>
    </Row>
  );
};

export default PlaceOfOccurence;
