import { FC, useCallback } from 'react';
import { useMemo, useEffect } from 'react';
import _sortBy from 'lodash/sortBy';

import ListSelect from 'common/components/selectors/ListSelect';
import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors-ts.ts';

type SingleOptionType = { id: number; label: string; name: string };

type OptionsType = SingleOptionType[];

const getPrefilledOptions = (label: string): OptionsType => {
  switch (label) {
    default:
      return [];
  }
};

const getParsedOptions = (label: string, options: OptionsType): OptionsType => {
  switch (label) {
    case 'budgeting-reports':
      return options.map((e: SingleOptionType) => ({ ...e, label: 'budgeting-report' }));
    default:
      return options;
  }
};

const getListLabel = (type: string): string => {
  switch (type) {
    case 'budgeting':
      return 'budgeting-reports';
    default:
      return `reports-${type}`;
  }
};

type RouterTypes = {
  id: string;
};

type SelectorWithIdProps = {
  type: string;
  preventRedirect: boolean;
  path: string;
};

const SelectorWithId: FC<SelectorWithIdProps> = ({ type, path, ...rest }) => {
  const listLabel = getListLabel(type);
  const options = useSelector(state => selectListOptionsFromStore(state, listLabel));
  const prefilledOptions = getPrefilledOptions(listLabel);
  const parsedOptions = useMemo(
    () => getParsedOptions(listLabel, options),
    [listLabel, options]
  ) as OptionsType;

  const sortedOptions = _sortBy(
    [...parsedOptions, ...prefilledOptions],
    option => option.name
  ) as OptionsType;

  const { history, match } = useRouter<RouterTypes>();

  const changeReport = useCallback(
    (value: number) => {
      const selectedOption = parsedOptions.find(option => option.id === value) as SingleOptionType;
      const label = selectedOption.label;
      const paramId = match.params.id ? parseInt(match.params.id) : null;

      if (value === paramId) return null;

      history.replace(`${path}/${label}/${value}`);
    },
    [history, match.params.id, parsedOptions, path]
  );

  return (
    <ListSelect
      onChange={changeReport}
      getOptionLabel={(option: SingleOptionType) => option.name}
      getOptionValue={(option: SingleOptionType) => option.id}
      list={listLabel}
      filter={null}
      isClearable={false}
      options={sortedOptions}
      styled={false}
      size="lg"
      icon={null}
      value={match.params.id}
      placeholder="Select report"
      {...rest}
    />
  );
};

export default SelectorWithId;
