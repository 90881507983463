import { filterAndMapData } from '../helpers/_functions';

const getStyleDefs = (plotIndex = 0) => [
  { type: 'attr', key: 'opacity', style: 'timedashOpacity', defaultValue: 1 },
  { type: 'attr', key: 'stroke', style: 'timedashColor', defaultValue: '#354069' },
  { type: 'attr', key: 'stroke-width', style: 'timedashHeight', defaultValue: 2 },
  { type: 'attr', key: 'plot-index', style: 'plotIndex', defaultValue: plotIndex }
];

// IMPORTANT: This plot only works when the graph has `isTimeseries: true`

const renderPlot = ({ plotID, plot, plotIndex, getPlotStyle, xFn, yFn, element }) => {
  const filteredData = filterAndMapData(plot, plotID);
  if (!filteredData.length) return;

  const calculateDashWidth = () => {
    const dashWidthInSeconds = getPlotStyle('timedashWidth', 60 * 60 * 8); // 8 hours is the default

    const originalTimestamp = new Date();
    const endTimestamp = new Date(+originalTimestamp + +dashWidthInSeconds * 1000);

    const originalX = xFn({ x: originalTimestamp });
    const endX = xFn({ x: endTimestamp });

    return endX - originalX;
  };

  const existingElements = element.selectAll(`.${plotID}`).data(filteredData);

  const el = (!existingElements.empty()
    ? existingElements
    : existingElements.enter().append('custom').classed(`dash ${plotID} plot-${plotIndex}`, true)
  )
    .attr('x', xFn)
    .attr('y', yFn)
    .attr('plot-index', plotIndex)
    .attr('dash-width', calculateDashWidth());

  getStyleDefs(plotIndex).forEach(styleDefinition => {
    el[styleDefinition.type](
      styleDefinition.key,
      getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
    );
  });
};

const timedash = {
  renderPlot
};

export default timedash;
