import { useState } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import moment from 'moment';

import { Label } from 'reactstrap';
import { useActions } from 'utils/hooks';

import UploadFiles from 'common/components/form/inputs/upload-files';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';

import { saveDownloadedFile } from 'common/utils/downloads';
import { selectIsJobLocked, selectJobField } from 'common/components/jobs/_base/store/selectors';
import { selectMaintenance } from 'common/components/pms/jobs/store/selectors';

import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';

const AttachedManuals = ({ className, isLocked = false }) => {
  const [isDownloading, setIsDownloading] = useState();
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const jobTitle = useSelector(state => selectJobField(state, 'title'));

  const { maintenance_details, maintenance_attachments } = useSelector(selectMaintenance);
  const [setMaintenanceField, downloadAttachedManuals] = useActions([
    maintenanceActions.setMaintenanceField,
    maintenanceActions.downloadAttachedManuals
  ]);
  const isJobLocked = useSelector(selectIsJobLocked);
  const attached_manuals = _get(
    maintenance_details,
    'attached_manuals',
    maintenance_attachments,
    []
  );

  const downloadHandler = async () => {
    if (!jobId) return;

    try {
      setIsDownloading(true);
      const response = await downloadAttachedManuals({ id: jobId });

      saveDownloadedFile(response, `${jobTitle}-${moment().format('dd-mm-yyyy-HH:mm')}`);
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      console.error(err);
    }
  };

  return isJobLocked || isLocked ? (
    <div className={`d-flex flex-column align-items-start ${className || ''}`}>
      <Label className="form-label">ATTACHED MANUALS</Label>

      {!attached_manuals?.length ? (
        <div>-</div>
      ) : (
        <DownloadAttachment
          isDisabled={isDownloading}
          data={{
            attachments: attached_manuals || [],
            attachments_count: attached_manuals?.length || 0
          }}
          popupPlacement="right"
          downloadFiles={downloadHandler}
          hasAttachments={true}
          canAddAttachments={false}
        />
      )}
    </div>
  ) : (
    <div className={className}>
      <UploadFiles
        label="ATTACHED MANUALS"
        group="jobs.attachments"
        viewOnly={isJobLocked}
        files={attached_manuals || []}
        onChange={files => setMaintenanceField('attached_manuals', files, true)}
      />
    </div>
  );
};

export default AttachedManuals;
