import React from 'react';
import { Row, Col } from 'reactstrap';

const FieldsItemsHeader = React.memo(() => {
  return (
    <Row className="text-primary fs-10 fw-bold pe-4" noGutters>
      <Col xs={7} className="ps-1">
        ITEMS
      </Col>
      <Col xs={2}>UNIT</Col>
      <Col xs={1} className="pe-1">
        PREVIOUS ROB
      </Col>
      <Col xs={2}>ROB</Col>
    </Row>
  );
});

export default FieldsItemsHeader;
