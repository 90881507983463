import date from 'common/assets/svg/dates/date.svg';
import month from 'common/assets/svg/dates/month.svg';
import year from 'common/assets/svg/dates/year.svg';

import SingleDatePicker from './pickers/SingleDatePicker';
import SingleYearPicker from './pickers/SingleYearPicker';
import SingleMonthYearPicker from './pickers/SingleMonthYearPicker';
import SingleWeekDatePicker from './pickers/SingleWeekDatePicker';
import RangeDatePicker from './pickers/RangeDatePicker';
import RangeMonthYearPicker from './pickers/RangeMonthYearPicker';
import MultiDatePicker from './pickers/MultiDatePicker';
import RangeYearPicker from './pickers/RangeYearPicker';

import DateTimeInput from './inputs/DateTimeInput';
import YearInput from './inputs/YearInput';
import DateMonthInput from './inputs/DateMonthInput';
import MultipleDateInput from './inputs/MultipleDateInput';

import moment from 'moment';

// const Empty = () => null;

export const mode = {
  single: {
    date: 'date-single',
    year: 'year-single',
    month: 'month-single'
  },
  range: {
    date: 'date-range',
    year: 'year-range',
    month: 'month-range',
    weekdate: 'weekdate-range'
  },
  multiple: {
    date: 'date-multiple'
  }
};

export const pickers = {
  [mode.single.date]: SingleDatePicker,
  [mode.single.year]: SingleYearPicker,
  [mode.single.month]: SingleMonthYearPicker,
  [mode.range.date]: RangeDatePicker,
  [mode.range.year]: RangeYearPicker,
  [mode.range.month]: RangeMonthYearPicker,
  [mode.range.weekdate]: SingleWeekDatePicker,
  [mode.multiple.date]: MultiDatePicker
};

export const inputs = {
  [mode.single.date]: DateTimeInput,
  [mode.single.year]: YearInput,
  [mode.single.month]: DateMonthInput,
  [mode.range.date]: DateTimeInput,
  [mode.range.year]: YearInput,
  [mode.range.month]: DateMonthInput,
  [mode.range.weekdate]: DateTimeInput,
  [mode.multiple.date]: MultipleDateInput
};

export const options = [
  { label: 'Single Date', value: mode.single.date, icon: date },
  { label: 'Date Range', value: mode.range.date, icon: date },
  { label: 'Single Month', value: mode.single.month, icon: month },
  { label: 'Month Range', value: mode.range.month, icon: month },
  { label: 'Single Year', value: mode.single.year, icon: year },
  { label: 'Year Range', value: mode.range.year, icon: year },
  { label: 'Weekly Range', value: mode.range.weekdate, icon: date },
  { label: 'Multiple Dates', value: mode.multiple.date, icon: date }
];

export const sizes = ['xs', 'sm', 'lg'];

export const dateRangeDefaultOptions = [
  {
    label: 'Today',
    startDate: moment(),
    endDate: moment()
  },
  {
    label: 'Yesterday',
    startDate: moment().subtract(1, 'days'),
    endDate: moment().subtract(1, 'days')
  },
  {
    label: 'Last 7 days',
    startDate: moment().subtract(7, 'days'),
    endDate: moment()
  },
  {
    label: 'Last Month',
    startDate: moment().startOf('month').subtract(1, 'months'),
    endDate: moment().startOf('month').subtract(1, 'months').endOf('month')
  },
  {
    label: 'This Month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
  }
];

export const dateRangePastOptions = [
  {
    label: 'Year to date',
    startDate: moment().startOf('year').hour(0).minute(0),
    endDate: moment()
  },
  {
    label: 'Last year',
    startDate: moment().subtract(1, 'year').startOf('year').hour(0).minute(0),
    endDate: moment().subtract(1, 'year').endOf('year').hour(23).minute(59)
  },
  {
    label: 'Last 6 months',
    startDate: moment().subtract(6, 'months').hour(0).minute(0),
    endDate: moment()
  },
  {
    label: 'Last 12 months',
    startDate: moment().subtract(12, 'months').hour(0).minute(0),
    endDate: moment()
  }
];
