import Select from 'common/components/form/inputs/Select';

const CaptainReportsStatusSelector = ({ filter, onChange, isMulti }) => {
  return (
    <Select
      className={`mb-0`}
      placeholder="Select value"
      isMulti={isMulti}
      value={filter.value}
      options={[
        { value: 'draft', label: 'Draft' },
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
        { value: 'rebuild', label: 'For Correction' }
      ]}
      onChange={selected => onChange({ value: selected })}
    />
  );
};

export default CaptainReportsStatusSelector;
