import React from 'react';
import ContentLoader from 'react-content-loader';

const ListLoader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 750 25"
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    animate={true}
  >
    <rect x="0" y="0" rx="7" ry="7" width="100%" height="25" />
  </ContentLoader>
);

export default ListLoader;
