import { useEffect, useState, useCallback } from 'react';

import _debounce from 'lodash/debounce';

const TIME_TO_INITIALLY_KEEP_RENDERING_IN_MS = 5000;

const useD3Active = (events, customElements) => {
  const [isActive, setActive] = useState(true);
  const [tempRender, setTempRender] = useState(false);

  const activate = useCallback(() => {
    if (!isActive) setActive(true);
  }, [isActive]);

  const deactivate = useCallback(() => {
    if (isActive) setActive(false);
  }, [isActive]);

  const triggerRerender = () => {
    setActive(true);
    const timeout = setTimeout(() => {
      setActive(false);
    }, TIME_TO_INITIALLY_KEEP_RENDERING_IN_MS);

    return () => {
      clearTimeout(timeout);
    };
  };

  const tempActivate = useCallback(() => {
    setTempRender(!tempRender);
  }, [tempRender]);

  const debouncedTempActivate = _debounce(tempActivate, 64);

  const groupedGraphClass = events.getElementClass('zoom');

  const graphElements = customElements || document.querySelectorAll(`.${groupedGraphClass}`);
  const getParentElement = domElement => domElement?.parentNode;

  const addEventListeners = domElement => {
    const parentElement = getParentElement(domElement);
    if (!parentElement) return;

    parentElement.addEventListener('mouseenter', activate);
    parentElement.addEventListener('mouseover', activate);
    parentElement.addEventListener('mouseleave', deactivate);
    window.addEventListener('focus', debouncedTempActivate);
    window.addEventListener('blur', deactivate);
    window.addEventListener('resize', activate);
  };

  const removeEventListeners = domElement => {
    const parentElement = getParentElement(domElement);
    if (!parentElement) return;

    parentElement.removeEventListener('mouseenter', activate);
    parentElement.removeEventListener('mouseover', activate);
    parentElement.removeEventListener('mouseleave', deactivate);
    window.removeEventListener('focus', debouncedTempActivate);
    window.removeEventListener('blur', deactivate);
    window.removeEventListener('resize', activate);
  };

  useEffect(() => {
    if (graphElements.length) {
      graphElements.forEach(addEventListeners);
    }
    return () => {
      if (graphElements.length) {
        graphElements.forEach(removeEventListeners);
      }
    };
  }, [graphElements]);

  useEffect(() => {
    triggerRerender();
  }, [tempRender]);

  return { isActive };
};

export default useD3Active;
