import TYPES from './types';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

import bunkerSurveyReducer, { BUNKER_SURVEY_INITIAL_STATE } from './bunker-survey/reducer';

const INITIAL_STATE = {
  onBoard: false,
  isAutoSaving: false,
  isEditting: false,
  collapse: { isExpandBtnOpen: true, shouldGlobalOpen: null, actions: [] },
  errors: {},
  futureReportsIsFetching: false,
  futureReports: [],

  ...BUNKER_SURVEY_INITIAL_STATE
};

const chainedReducers = [bunkerSurveyReducer];

const isEmpty = field => {
  if (_isArray(field)) {
    return !field.length;
  } else {
    return field === null;
  }
};

const compareElements = (prevElement, currentElement) => {
  return isEmpty(currentElement.index)
    ? prevElement.label === currentElement.label
    : prevElement.label === currentElement.label && prevElement.index === currentElement.index;
};

const upsert = (array, element) => {
  const i = array.findIndex(_element => compareElements(_element, element));

  if (i > -1) array[i] = element;
  else array.push(element);
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_PORT_STATEMENT:
      return {
        ...state,
        ...payload
      };

    case TYPES.SET_ERROR_PORT_STATEMENT:
      if (!payload?.action_id) return;

      const detailedAction = state.detailed_actions.find(e => e.id === payload.action_id);
      let hasError = false;

      if (!payload?.shouldClear) {
        if (payload?.innerPath) {
          const parent = _get(detailedAction, `${payload?.innerPath}`);

          if (_isArray(parent) && parent.length > 0) {
            hasError = parent.some(e => e[payload.fieldKey] === null);
          } else if (!_isArray(parent) && _isObject(parent) && parent !== null) {
            hasError = isEmpty(parent[payload.fieldKey]);
          } else {
            hasError = true;
          }
        } else {
          hasError = isEmpty(detailedAction[payload.fieldKey]);
        }
      }

      let sectionErrorsObj = { ...state.errors };
      const actionFields = sectionErrorsObj[payload?.action_id] || [];
      const params = payload.index ? { index: payload.index } : {};

      upsert(actionFields, {
        label: payload?.fieldKey,
        hasError: hasError,
        ...params
      });
      sectionErrorsObj[payload?.action_id] = actionFields.map(e =>
        e.label === payload.fieldKey && e.index === payload.index && payload.shouldClear
          ? { ...e, hasError: false }
          : { ...e }
      );

      return {
        ...state,
        errors: sectionErrorsObj
      };

    case TYPES.UPDATE_PORT_STATEMENT_ACTION.START:
      return {
        ...state,
        isAutoSaving: payload.params.isAutosave ? true : false
      };
    case TYPES.UPDATE_PORT_STATEMENT_ACTION.SUCCESS:
      return {
        ...state,
        isAutoSaving: false
      };

    case TYPES.UPDATE_PORT_STATEMENT_ACTION.ERROR:
      return {
        ...state,
        isAutoSaving: false
      };
    case TYPES.UPDATE_PORT_STATEMENT.SUCCESS:
      return {
        ...state,
        status: payload.status
      };

    case TYPES.SET_EDITTING_PORT_STATEMENT:
      return {
        ...state,
        isEditting: payload.isEditting
      };

    case TYPES.SET_PORT_STATEMENT_REBUILD_COMMENTS:
      return {
        ...state,
        rebuild_comments: payload
      };

    case TYPES.SET_COLLAPSE:
      const actionIdentifier = _get(payload, 'action.action_identifier', null);

      const stateCollapseActions = _get(state, 'collapse.actions', []).filter(
        e => e.action_identifier !== 'attachments'
      );
      const elementIndex = stateCollapseActions.findIndex(
        e => e.action_identifier === actionIdentifier
      );

      if (elementIndex === -1) {
        stateCollapseActions.push(payload.action);
      } else {
        stateCollapseActions[elementIndex] = payload.action;
      }
      const isAnyActionCollapsed = stateCollapseActions?.filter(e => e?.isOpen);

      return {
        ...state,
        collapse: {
          shouldGlobalOpen: null,
          isExpandBtnOpen: isAnyActionCollapsed?.length ? true : false,
          actions: stateCollapseActions
        }
      };

    case TYPES.SET_GLOBAL_COLLAPSE:
      const collapseActions = _get(state, 'collapse.actions', []);

      return {
        ...state,
        collapse: {
          isExpandBtnOpen: payload,
          shouldGlobalOpen: payload,
          actions: collapseActions.map(e => ({ ...e, isOpen: payload }))
        }
      };

    case TYPES.SET_FUTURE_REPORTS:
      return {
        ...state,
        futureReportsIsFetching: payload.isFetching,
        futureReports: payload.data
      };

    case TYPES.SET_SELECTED_FUTURE_REPORTS:
      return {
        ...state,
        selectedFutureReports: payload
      };

    default:
      return chainedReducers.reduce(
        (prevState, reducer) => reducer(prevState, { type, payload }),
        state
      );
  }
};

export default reducer;
