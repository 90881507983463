import React from 'react';

import Modal from 'common/components/modals/BasicModal';
import Textarea from 'common/components/form/inputs/Textarea';
import { Button } from 'reactstrap';

const ConsentModal = ({ isOpen, consent, toggle, comments, setComments, handleConsent }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header={`${consent ? 'Consent' : 'Not Consent'} to Evaluation`}
      footer={
        <>
          <Button color="cancel" onClick={toggle}>
            CANCEL
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleConsent();
              toggle();
            }}
          >
            {consent ? 'Consent' : 'Not Consent'}
          </Button>
        </>
      }
      body={
        <>
          <span className="text-primary fs-12 fw-bold">COMMENTS</span>
          <Textarea
            className="mb-0 ms-0 h-100p w-100p mt-2"
            autoResize
            rows={5}
            placeholder="Add some text"
            value={comments}
            onChange={e => setComments(e.target.value)}
          />
        </>
      }
    />
  );
};

export default ConsentModal;
