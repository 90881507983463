import TYPES from './types';
import PROFILE_TYPES from 'crew/store/profile/types';

const initialActive = { average_score: null };

const INITIAL_STATE = {
  data: [],
  fetching: false,
  hasError: false,
  activePercentages: [],
  active: initialActive,
  graph: {
    data: [],
    axes: null,
    dates: []
  },
  tabView: 'received',
  isEditing: false
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_EVALUATIONS.START:
    case TYPES.GET_CREW_EVALUATION.START:
      return {
        ...state,
        fetching: true,
        hasError: false
      };

    case TYPES.DELETE_CREW_EVALUATION.SUCCESS:
      return {
        ...state,
        data: state.data.filter(d => d.id !== payload.id)
      };

    case TYPES.GET_CREW_EVALUATIONS.SUCCESS:
      return {
        ...state,
        data: payload,
        fetching: false,
        hasError: false
      };

    case TYPES.GET_RESULTS_PERCENTAGES.SUCCESS:
      return {
        ...state,
        activePercentages: payload
      };

    case TYPES.GET_RESULTS_PERCENTAGES.START:
    case TYPES.GET_RESULTS_PERCENTAGES.ERROR:
      return {
        ...state,
        activePercentages: null
      };

    case TYPES.GET_CREW_EVALUATIONS.ERROR:
      return {
        ...state,
        data: [],
        fetching: false,
        hasError: true
      };

    case TYPES.GET_CREW_EVALUATION.SUCCESS:
      return {
        ...state,
        active: payload,
        fetching: false,
        hasError: false
      };

    case TYPES.SET_TAB_VIEW:
      return {
        ...state,
        tabView: payload
      };

    case TYPES.GET_CREW_EVALUATION.ERROR:
      return {
        ...state,
        active: initialActive,
        fetching: false,
        hasError: true
      };

    case TYPES.CLEAR_CREW_EVALUATION_TEMPLATE:
      return {
        ...state,
        active: initialActive,
        fetching: false,
        hasError: false
      };

    case TYPES.CREATE_CREW_EVALUATION.SUCCESS:
      let newData = [...state.data, payload];
      return {
        ...state,
        data: newData
      };

    case TYPES.GET_CREW_EVALUATION_STATISTICS.SUCCESS:
      return {
        ...state,
        graph: {
          ...payload
        }
      };

    case TYPES.UPDATE_CREW_EVALUATION_AVERAGE_RATING.SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          average_score: payload.average_score
        }
      };

    case TYPES.UPDATE_CREW_EVALUATION.SUCCESS:
      const updatedData = state.data.map(el => {
        if (el.id === payload.id) {
          return {
            ...el,
            name: payload.name
          };
        } else {
          return el;
        }
      });

      return {
        ...state,
        data: updatedData
      };

    case PROFILE_TYPES.GET_CREW.START:
      return { ...INITIAL_STATE };

    case TYPES.SET_IS_EDITING:
      return {
        ...state,
        isEditing: payload
      };

    case TYPES.CLEAR_ACTIVE_CREW_EVALUATION:
      return {
        ...state,
        active: initialActive
      };

    default:
      return state;
  }
};

export default reducer;
