import { Alert } from 'reactstrap';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const CellWarningTooltip = ({ children, warning, color = 'red', textColor = 'red' }) => {
  const { tooltipID, avoidRender } = useTooltipID('cell-warning');

  if (avoidRender) return null;

  return (
    <Alert
      id={tooltipID}
      color={color}
      className={`rounded rounded-sm cps-4 cpe-4 cpt-2 cpb-2 mb-0 mt-0 w-100p text-${textColor} fw-bold`}
    >
      {children}

      <Tooltip innerClassName="text-start" fade={false} target={tooltipID}>
        <span dangerouslySetInnerHTML={{ __html: warning }}></span>
      </Tooltip>
    </Alert>
  );
};

export default CellWarningTooltip;
