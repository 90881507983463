import bunkering from 'common/assets/svg/voyages/bunkering.svg';
import vesselLoading from 'common/assets/svg/voyages/container-load.svg';
import discharge from 'common/assets/svg/voyages/container-discharge.svg';
import delivery from 'common/assets/svg/voyages/delivery.svg';
import redelivery from 'common/assets/svg/voyages/redelivery.svg';
import guards from 'common/assets/svg/jobs/guards.svg';
import transit from 'common/assets/svg/voyages/transit.svg';
import orders from 'common/assets/svg/voyages/orders.svg';
import idle from 'common/assets/svg/voyages/idle.svg';
import otherAction from 'common/assets/svg/voyages/other-action.svg';

import bunkeringIconRed from 'common/assets/svg/voyages/bunkering-red.svg';
import guardsIconRed from 'common/assets/svg/voyages/guards-red.svg';
import dischargeIconRed from 'common/assets/svg/voyages/discharge-red.svg';
import loadingIconRed from 'common/assets/svg/voyages/loading-red.svg';
import deliveryIconRed from 'common/assets/svg/voyages/delivery-red.svg';
import redeliveryIconRed from 'common/assets/svg/voyages/redelivery-red.svg';
import transitIconRed from 'common/assets/svg/voyages/transit-red.svg';
import ordersIconRed from 'common/assets/svg/voyages/orders-red.svg';
import idleRed from 'common/assets/svg/voyages/idle-red.svg';

export const portActionIcon = {
  bunkering: { file: bunkering, satellite: bunkeringIconRed, color: 'danger' },
  loading: { file: vesselLoading, satellite: loadingIconRed, color: 'orange' },
  discharging: { file: discharge, satellite: dischargeIconRed, color: 'orange' },
  delivery: { file: delivery, satellite: deliveryIconRed },
  redelivery: { file: redelivery, satellite: redeliveryIconRed },
  transit: { file: transit, satellite: transitIconRed },
  guards: { file: guards, satellite: guardsIconRed },
  orders: { file: orders, satellite: ordersIconRed },
  idle: { file: idle, satellite: idleRed }
};

export const detailedPortActionTypes = {
  loading: true,
  discharging: true,
  delivery: true,
  redelivery: true,
  bunkering: true
};

export const getPortActionIcon = actionLabel => {
  return portActionIcon[actionLabel] || { file: otherAction, satellite: otherAction };
};
