import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import paths from 'routing/routes/_paths';
import SvgRender from 'common/components/general/SvgRender';
import Copy from 'common/assets/svg/actions/copy.svg';
import copyToClipboard from 'copy-to-clipboard';

import { useSelector } from 'react-redux';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const JobLink = ({ disabled, className }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const orcaId = useSelector(state => selectJobField(state, 'orca_id'));
  const isOnBoard = useSelector(state => state.isOnBoard);

  const [copyTooltipText, setCopyTooltipText] = useState('Copy the link to this issue');

  const copyUrl = () => {
    setCopyTooltipText('Copied!');
    copyToClipboard(`${document.location.origin}${paths.regular_jobs}/${jobId}`);
  };

  const { avoidRender, tooltipID } = useTooltipID('copy-job-tooltip-container');

  if (avoidRender) return null;

  return jobId ? (
    <div className={`d-flex align-items-center ${disabled ? 'pointer-events-none' : ''}`}>
      <Link
        to={`${paths.regular_jobs}/${jobId}`}
        className={`text-primary fs-10 job-id-link ${className || ''}`}
      >
        {isOnBoard ? orcaId : jobId}
      </Link>

      {disabled ? null : (
        <>
          <div
            id={tooltipID}
            onClick={copyUrl}
            className="ps-1 job-detailed-view--copy cursor-pointer d-inline-flex"
            onMouseLeave={() => setCopyTooltipText("Copy this job's link")}
          >
            <SvgRender className="text-primary" style={{ height: 16, width: 13 }} src={Copy} />
          </div>
          <Tooltip
            target={tooltipID}
            innerClassName="copy-job-tooltip-container"
            placement="top"
            arrowClassName="d-none"
          >
            {copyTooltipText}
          </Tooltip>
        </>
      )}
    </div>
  ) : (
    <div className="text-primary fs-10 job-id-link job-id-link__empty me-2 height-24">-</div>
  );
};

export default JobLink;
