import React, { useCallback, useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectIsJobLocked,
  selectJobField,
  selectJobPrefilledVessel
} from 'common/components/jobs/_base/store/selectors';
import {
  selectIsFlagDispensationJob,
  selectIsUnscheduledJob
} from 'common/components/jobs/flag-extensions-dispensations/store/selectors';
import { selectIsPms, selectIsPlannedJob } from 'common/components/pms/jobs/store/selectors';
import * as listActions from 'store/lists/actions';
import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as jobTemplateActions from 'common/components/jobs/_base/modules/template/store/actions';
import { selectVesselName } from 'store/vessels/selectors';

export const getFleetType = templateTypes => {
  const fleetTypeId =
    templateTypes && templateTypes.options ? templateTypes.options.find(el => el.is_fleet) : null;

  return fleetTypeId ? fleetTypeId.id : null;
};

const Vessel = ({ label, className, ...rest }) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const [setJobField, fetchListOptions, updateFleetTemplateData] = useActions([
    jobProfileActions.setJobField,
    listActions.fetchListOptions,
    jobTemplateActions.updateFleetTemplateData
  ]);
  const prefilledVessel = useSelector(selectJobPrefilledVessel);
  const isVisible = useSelector(selectIfFieldIsVisible('vessels'));
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const itineraryPort = useSelector(state => selectJobField(state, 'itinerary_port'));
  const isFlagDispensation = useSelector(selectIsFlagDispensationJob);
  const isUnscheduledJob = useSelector(selectIsUnscheduledJob);
  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));
  const isPms = useSelector(selectIsPms);
  const isPlannedJob = useSelector(selectIsPlannedJob);
  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));

  const templateTypes = useSelector(state => state.lists['job-template-types']);
  const template = useSelector(state => state.jobsTemplates.template);

  useEffect(() => {
    if (!templateTypes.length) fetchListOptions('job-template-types');
  }, [fetchListOptions, templateTypes.length]);

  const onSelectChange = useCallback(
    v => {
      (isPms && !jobId) || (isFlagDispensation && !isForVessel)
        ? setJobField('vessels', [v], true)
        : setJobField('vessels', v, true);

      if (template && template.type_id && getFleetType(templateTypes) === template.type_id) {
        updateFleetTemplateData(template, v);
      }
    },
    [
      isFlagDispensation,
      isForVessel,
      isPms,
      jobId,
      setJobField,
      template,
      templateTypes,
      updateFleetTemplateData
    ]
  );

  useEffect(() => {
    if (prefilledVessel?.id) {
      onSelectChange(isFlagDispensation || isUnscheduledJob ? prefilledVessel : [prefilledVessel]);
    }
  }, [isFlagDispensation, isUnscheduledJob, onSelectChange, prefilledVessel, setJobField]);

  if (!isVisible) return null;

  return (
    <Select
      className={`job-input job-input--vessel form-group-spacing ${className ? className : ''}`}
      label={label === false ? null : 'Vessel'}
      placeholder="Select vessel"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={vessels}
      isAsync
      isMulti={(isPms && !jobId) || (isFlagDispensation && !isForVessel) ? false : true}
      loadOptions={search => getAsyncOptions(search, 'vessels')}
      defaultOptions={() => getInitialAsyncValues('vessels')}
      onChange={onSelectChange}
      disabled={
        (jobId ? isJobLocked || isPlannedJob : false) ||
        itineraryPort?.id ||
        isForVessel ||
        prefilledVessel
      }
      isClearable
      invisible
      id={`vessel-select-${jobId}`}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for vessels` : `No vessels found`
      }
      {...rest}
    />
  );
};

const VesselWrapper = ({ label, className = '', isRequired, ...rest }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const currentVesselName = useSelector(selectVesselName);

  return isOnBoard ? (
    <div className={`fs-14 text-primary min-width-120 max-width-120 ${className}`}>
      {currentVesselName}
    </div>
  ) : (
    <div>
      <Vessel label={label} className={className} isRequired={isRequired} {...rest} />
    </div>
  );
};

export default VesselWrapper;
