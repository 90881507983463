import { FC } from 'react';
import { Row, Col } from 'reactstrap';

import VesselSelector from 'common/components/selectors/VesselSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import TopPagination from 'common/components/table/main/TopPagination';

import { selectTableListPaging } from 'common/components/table/store/selectors';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import { useAppSelector } from '@/store/hooks';
import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';
import { Vessel } from '@/common/types/vessel';

const LABEL = 'inventory_items';

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
};

const TableHeader: FC<TopFiltersComponentProps> = ({ topFilters, setTopFilters }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const [vessel, setVessel] = useTableTopFilterValue({ topFilters, setTopFilters }, 'vessel_id');

  const singleVesselView = vessel?.length === 1;

  const paging = useAppSelector(state => selectTableListPaging(state, LABEL));

  return (
    <Row className={`d-flex align-items-center cmb-2 mt-4 w-100p`} noGutters>
      {!isOnBoard && (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{ value: vessel?.length ? vessel : null }}
              isMulti={true}
              isClearable
              autoFocus={false}
              onChange={({ value }: { value: Vessel[] }) => setVessel(value?.length ? value : null)}
              placeholder="Select vessel"
              showIcon
            />
          </TableTopFilter>
        </Col>
      )}

      <Col xs="auto" className="ms-auto">
        {singleVesselView ? (
          <TopPagination
            hideNavigation
            hidePerPage
            standAlone
            state={{ paging: { ...paging, per_page: null } }}
            customOptions={[20, 50, 100, 200]}
          />
        ) : (
          <TopPagination standAlone state={{ paging }} customOptions={[20, 50, 100, 200]} />
        )}
      </Col>
    </Row>
  );
};

export default TableHeader;
