import { useCallback } from 'react';
import { Collapse } from 'reactstrap';

import TicketForm from 'common/components/tickets/form';
import CircledButton from 'common/components/buttons/CircledButton';

import {
  selectTripOffers,
  selectTripParties,
  selectTripDisabled,
  selectTripCaseID,
  selectTripType,
  selectIsTripCollpased
} from 'store/ticketing/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import * as ticketingActions from 'store/ticketing/actions';

const Offers = ({ isAddingNew, toggleAddNew, tripID, components }) => {
  const [
    createTrip,
    updateTripTicket,
    acceptTripTicket,
    deleteTripTicket,
    addNewTicketToTrip,
    setTripPreventionModal,
    deleteTrip,
    closeTripDrawer
  ] = useActions([
    ticketingActions.createTrip,
    ticketingActions.updateTripTicket,
    ticketingActions.acceptTripTicket,
    ticketingActions.deleteTripTicket,
    ticketingActions.addNewTicketToTrip,
    ticketingActions.setTripPreventionModal,
    ticketingActions.deleteTrip,
    ticketingActions.closeTripDrawer
  ]);

  const tripCaseID = useSelector(selectTripCaseID);
  const tripType = useSelector(selectTripType);
  const disabled = useSelector(selectTripDisabled);
  const offers = useSelector(state => selectTripOffers(state, tripID));
  const tripParties = useSelector(state => selectTripParties(state, tripID));
  const isTripCollapsed = useSelector(state => selectIsTripCollpased(state, tripID));

  const handleCreate = useCallback(
    offer =>
      tripID
        ? addNewTicketToTrip({ tripID, ...offer })
        : createTrip({
            tripType,
            tripCaseID,
            offer,
            parties: tripParties.map(p => ({ id: p.person?.id, ticketing_type_id: p.type?.id }))
          }),
    [tripID, tripType, tripCaseID, tripParties, addNewTicketToTrip, createTrip]
  );

  const handleUpdate = useCallback(
    params => updateTripTicket({ ...params, tripID }),
    [tripID, updateTripTicket]
  );

  const handleAccept = useCallback(
    params => acceptTripTicket({ ...params, tripID }),
    [tripID, acceptTripTicket]
  );

  const handleDelete = useCallback(
    async params => {
      if (offers?.length > 1) {
        await deleteTripTicket({ ...params, tripID });
      } else {
        await deleteTrip({ id: tripID });

        closeTripDrawer();
      }
    },
    [offers?.length, tripID, deleteTripTicket, deleteTrip, closeTripDrawer]
  );

  return (
    <Collapse isOpen={!isTripCollapsed}>
      <div className="tickets-drawer__offers mb-4">
        {offers.map(t => (
          <TicketForm
            key={t.id}
            setPreventionModal={setTripPreventionModal}
            className="mb-1"
            closeForm={() => {}}
            disabledMode={disabled}
            onOfferUpdate={handleUpdate}
            onOfferAccept={handleAccept}
            onOfferDelete={handleDelete}
            active={t}
            offersCount={offers?.length}
            components={components}
          />
        ))}

        {isAddingNew ? (
          <TicketForm
            setPreventionModal={setTripPreventionModal}
            className="mb-1"
            onOfferCreate={handleCreate}
            closeForm={tripID ? toggleAddNew : () => {}}
            formInitialState={{
              routes: [{ arrival: null, departure: null }],
              is_bulk: tripParties?.length > 1,
              participants_count: tripParties?.length
            }}
            components={components}
          />
        ) : null}

        {!disabled && (
          <div className="d-flex align-items-center mt-2 mb-2">
            <CircledButton
              onClick={toggleAddNew}
              label={<strong className="text-green">Add Offer</strong>}
              type="add-green"
              disabled={isAddingNew || offers.filter(o => !o.id)?.length}
            />
          </div>
        )}
      </div>
    </Collapse>
  );
};

export default Offers;
