import React from 'react';
import { Row, Col } from 'reactstrap';

const FieldsSparePartsHeader = React.memo(() => {
  return (
    <Row className="text-primary fs-10 fw-bold cps-4 pe-4" noGutters>
      <Col xs={3}>SPARE PARTS</Col>
      <Col xs={1}>UNIT</Col>
      <Col xs={1}>MIN QUANTITY</Col>
      <Col xs={1}>PREVIOUS ROB</Col>
      <Col xs={2}>LOCATION</Col>
      <Col xs={1}>ROB NEW</Col>
      <Col xs={1}>ROB USED</Col>
      <Col xs={1}>ROB IN REPAIR</Col>
      <Col xs={1}>ROB</Col>
    </Row>
  );
});

export default FieldsSparePartsHeader;
