import React, { useCallback, useEffect } from 'react';
import VesselSelector from 'common/components/selectors/VesselSelector';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import {
  selectSelectedVesselFilter,
  selectSelectedSystemFilter,
  selectIsUnscheduledJob,
  selectIsPlannedJob
} from 'common/components/pms/jobs/store/selectors';
import { selectJobPrefilledVessel } from 'common/components/jobs/_base/store/selectors';
import { getInitialAsyncValues, getAsyncOptions } from 'utils/helpers';
import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';

const JobSelectorFilters = ({ isJobLinkingView }) => {
  const [setSelectedVesselFilter, setSelectedSystemFilter] = useActions([
    pmsJobsActions.setSelectedVesselFilter,
    pmsJobsActions.setSelectedSystemFilter
  ]);

  const isUnscheduledJob = useSelector(selectIsUnscheduledJob);
  const isPlannedJob = useSelector(selectIsPlannedJob);
  const prefilledVessel = useSelector(selectJobPrefilledVessel);
  const selectedVessels = useSelector(selectSelectedVesselFilter);
  const selectedSystem = useSelector(selectSelectedSystemFilter);

  const getVesselSystems = useCallback(
    search => {
      const params = { limit: 30, vessel_ids: selectedVessels?.map(v => v.id) };

      if (search) return getAsyncOptions(search, 'vessel-system-assignments', params);
      else return getInitialAsyncValues('vessel-system-assignments', null, null, params);
    },
    [selectedVessels]
  );

  useEffect(() => {
    if (prefilledVessel?.id && !selectedVessels) {
      setSelectedVesselFilter([prefilledVessel]);
    }
  }, [prefilledVessel, selectedVessels, setSelectedVesselFilter]);

  return (isUnscheduledJob || isPlannedJob) && isJobLinkingView ? (
    <>
      <Col xs={2}>
        <VesselSelector
          label={'Filter Vessel'}
          isMulti
          isClearable
          autoFocus={false}
          onChange={e => setSelectedVesselFilter(e)}
          placeholder="Select vessel"
          disabled={prefilledVessel?.id}
          value={selectedVessels || null}
        />
      </Col>
      <Col xs={5} className="d-flex align-items-center">
        <AsyncSelector
          label="Filter System"
          onChange={e => setSelectedSystemFilter(e)}
          placeholder="Select system"
          type="vessel-system-assignments"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.description}
          value={selectedSystem || null}
          className="w-100p pe-2"
          defaultOptions={getVesselSystems}
          loadOptions={getVesselSystems}
          defaultOptionsTriggerChange={selectedVessels?.length}
        />
        <div className="small-separator height-20 bg-platinum"></div>
      </Col>
    </>
  ) : null;
};

export default JobSelectorFilters;
