import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const SupplierSelector = ({ filter, onChange, isMulti, showDefaultOptions = true, ...rest }) => {
  const [selectedSupplier, setSelectedSupplier] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'parties', type: 'company', role: ['supplier', 'supplier_representative'] },
    { selected: selectedSupplier, setSelected: setSelectedSupplier }
  );

  return (
    <Select
      autoFocus
      placeholder="Select supplier"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.full_name}
      isAsync
      defaultOptions={() =>
        showDefaultOptions
          ? getInitialAsyncValues('parties', null, null, {
              type: 'company',
              role: ['supplier', 'supplier_representative']
            })
          : []
      }
      isMulti={isMulti}
      value={
        filter.value ? selectedSupplier.filter(el => filter.value.map(Number).includes(el.id)) : []
      }
      loadOptions={search =>
        getAsyncOptions(search, 'parties', {
          type: 'company',
          role: ['supplier', 'supplier_representative']
        })
      }
      onChange={selected => {
        setSelectedSupplier(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
    />
  );
};

export default SupplierSelector;
