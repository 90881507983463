import useRouter from 'use-react-router';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import hideSvg from 'common/assets/svg/common/hide.svg';
import SvgRender from 'common/components/general/SvgRender';
import { TableTopFilters } from 'common/entities/tables/TopFilterTypes';
import { parseQueryParams } from 'common/utils/urls';

const HiddenButton = ({ className, label, topFilters, setTopFilters }) => {
  const { location } = useRouter();
  const [isHidden, setIsHidden] = useState(false);

  const onHide = () => {
    const hiddenFilter = { name: 'hidden', operation: '=', value: 'true' };
    const newFilters = [hiddenFilter, ...topFilters];

    setTopFilters(newFilters);
  };

  const onGoBack = () => {
    const isHidden = topFilters?.find(f => f.name === 'hidden');
    const newFilters = isHidden
      ? topFilters.map(f => (f.name === 'hidden' ? { ...f, value: undefined } : f))
      : [...topFilters, { name: 'hidden', operation: '=', value: 'false' }];
    setTopFilters(newFilters);
  };

  useEffect(() => {
    if (location.search) {
      const params = parseQueryParams(location.search);

      if (
        params.filters &&
        params.filters.length > 0 &&
        params.filters.find(el => el.name === 'hidden' && el.value === 'true')
      ) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    } else {
      setIsHidden(false);
    }
  }, [location.search]);

  return (
    <div className={`d-flex align-items-center ${className ? className : ''}`}>
      {isHidden ? (
        <div className="fs-10 d-flex align-items-center">
          <div
            onClick={onGoBack}
            className="d-inline-flex align-items-center cursor-pointer back-to-list"
            dataCy="back-to-list"
          >
            Back to List
          </div>
          <div className="text-primary">Hidden</div>
        </div>
      ) : (
        <div
          onClick={onHide}
          className="d-inline-flex align-items-center text-primary cursor-pointer "
          data-cy="see_hidden"
        >
          <SvgRender src={hideSvg} style={{ width: 14, height: 14 }} className="me-1" />
          <span className="lh-1 fs-10 fw-normal">See hidden {label}</span>
        </div>
      )}
    </div>
  );
};

export default HiddenButton;

HiddenButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  setTopFilters: PropTypes.func.isRequired,
  topFilters: TableTopFilters
};
