import React, { useEffect, useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Priority from 'common/components/general/Priority';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import FormGroupWrap from 'common/components/form/helpers/FormGroupWrap';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectListDefaultOptions } from 'store/lists/selectors';
import { fetchListOptions } from 'store/lists/actions';

const INITIAL_IMPORTANCE_LABEL = 'normal';

const ImportanceDropdownSelector = ({
  onChange,
  value,
  disabled,
  list = 'importances',
  initializeWhennEmtpy = false
}) => {
  const [dropdownOpen, setOpen] = useState(false);

  const importances = useSelector(state =>
    _sortBy(selectListDefaultOptions(state, list), ['sort_index'])
  );

  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListOptions(list));
  }, [dispatch, list]);

  useEffect(() => {
    if (initializeWhennEmtpy && !value && importances.length) {
      const initialImportance = importances.find(
        importance => importance.label === INITIAL_IMPORTANCE_LABEL
      );

      if (initialImportance) onChange(initialImportance.id, initialImportance);
    }
  }, [initializeWhennEmtpy, importances, onChange, value]);

  return (
    <FormGroupWrap label="IMPORTANCE">
      <Container>
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={disabled}>
          <DropdownToggle
            caret
            color="link"
            className="d-flex align-items-center ps-0 py-0 padding-right0-12"
          >
            <Priority size="small" value={value} list={list} />
          </DropdownToggle>
          <DropdownMenu>
            {importances &&
              importances.map(st => (
                <DropdownItem key={st.id} tag="div" onClick={() => onChange(st.id, st)}>
                  <Priority size="small" value={st.id} list={list} />
                </DropdownItem>
              ))}
          </DropdownMenu>
        </ButtonDropdown>
      </Container>
    </FormGroupWrap>
  );
};

const Container = styled.div`
  .dropdown-toggle {
    width: ${variables.size64};
    height: ${variables.size24};
    position: relative;

    &:after {
      right: ${variables.size12};
      position: absolute;
      width: 8px;
      height: 8px;
      border: none !important;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.8333333 0L5 3.76 1.1666667 0 0 1.12 5 6l5-4.88z' fill='%2322242A' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

    &[aria-expanded='true']:after {
      transform: rotate(180deg);
    }

    .job-priority {
      flex: 1;
      height: 100%;
      position: relative;
      padding-left: ${variables.size12};

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + ${variables.size16});
        height: 100%;
        background: currentColor;
        opacity: 0.15;
        border-radius: 3px;
      }
    }
  }

  .dropdown-menu {
    max-width: ${variables.size64};
    min-width: ${variables.size64};
    padding: 0;
  }

  .dropdown-item {
    padding: 0 !important;

    .job-priority {
      width: ${variables.size64};
      height: ${variables.size24};
      justify-content: center;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: currentColor;
        opacity: 0.15;
      }
    }
  }
`;

ImportanceDropdownSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, null]).isRequired,
  list: PropTypes.string,
  initializeWhennEmtpy: PropTypes.bool
};

export default ImportanceDropdownSelector;
