export const initialListState = (key, per_page = 50, sorting) => {
  return {
    invalid: false,
    pendingRequest: false,
    data: [],
    paging: {
      default_per_page: per_page,
      current_page: 1,
      last_page: 1,
      per_page,
      total: 1
    },
    totals: {},
    warnings: {}, // { row_index_1: { column_key_1: '', column_key_2: '' }, row_index_2: { column_key_1: '' } }
    columns: [],
    sorting,
    selectedRows: [], // [row_id_1, row_id_2],
    hoveredColumn: null
  };
};
