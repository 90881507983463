import React from 'react';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';

import Switch from 'common/components/form/inputs/Switch';
import BodyCellActions from 'common/components/table/main/BodyCellActions';

import { formatDate } from 'common/utils/dates';
import { numberToStr } from 'common/utils/numbers';

const formatFieldData = (col, data) => {
  if (col.type === 'number') {
    return numberToStr(_get(data, col.key, ''));
  } else if (col.type === 'date') {
    if (col.showUtc) {
      return formatDate(_get(data, col.key, ''), null, true);
    } else {
      return formatDate(_get(data, col.key, ''));
    }
  } else {
    return _get(data, col.key, '');
  }
};

const renderField = (col, row, data) => {
  if (row) {
    if (col.field) {
      if (col.field === 'toggle') {
        const value = _get(data, col.key, false);
        return (
          <Switch
            name={col.key}
            value={value}
            onChange={() => row.onChange({ id: data.id, [col.key]: !value })}
            switchClassName="justify-content-center"
            className="mb-0"
            size={'sm'}
          />
        );
      } else if (col.field === 'actions') {
        return (
          <div className="d-inline-flex align-items-center pe-1 ps-0 app-table--body--row__actions--inner">
            <BodyCellActions col={col} row={row} data={data} />
          </div>
        );
      }
    } else if (_isFunction(row)) {
      return row(data, col);
    } else {
    }
  } else {
    return formatFieldData(col, data);
  }
};

const BodyCell = ({ col, row, data, style }) => {
  return (
    <div
      className={`cell col${col.width ? `-${col.width}` : ''} ${
        col.field === 'actions' ? 'app-table--body--row__actions' : ''
      } ${col.className || ''} `}
      style={style ? style : null}
    >
      {renderField(col, row, data)}
    </div>
  );
};

export default BodyCell;
