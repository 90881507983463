import Input from 'common/components/form/styled-inputs/Input';
import EmptyField from './EmptyField';

const TransportationMedium = ({ previewMode, field, changeField }) => {
  return (
    <div className="tickets-form-body__medium">
      {previewMode && !field.value ? (
        <EmptyField label="TRANSPORTATION MEDIUM" />
      ) : (
        <Input
          label="TRANSPORTATION MEDIUM"
          onChange={changeField('transportation_medium')}
          placeholder="Add medium"
          disabled={previewMode}
          {...field}
        />
      )}
    </div>
  );
};

export default TransportationMedium;
