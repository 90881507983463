import EmptyState from 'common/components/tree-layout/components/EmptyState';
import Tree from './tree';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectIsCreatingFolder,
  selectIsLoading,
  selectIsEmpty,
  selectIsLocked
} from 'common/components/filesystem/store/selectors';
import { selectAccount } from '@/store/account/selectors';
import * as filesystemActions from 'common/components/filesystem/store/actions';

import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';

const Sidebar = () => {
  const [setIsCreatingFolder] = useActions([filesystemActions.setIsCreatingFolder]);

  const isCreating = useSelector(selectIsCreatingFolder);
  const isEmpty = useSelector(selectIsEmpty);
  const isLoading = useSelector(selectIsLoading);
  const isLocked = useSelector(selectIsLocked);
  const account = useSelector(selectAccount);

  return (
    <div className="filesystem-layout__sidebar">
      {!isEmpty || isCreating ? (
        <Tree />
      ) : isEmpty && !isLoading ? (
        <EmptyState
          emptyText={'No Folders'}
          createText={'Add Folder'}
          onCreate={() => setIsCreatingFolder(true)}
          isLocked={isLocked || !isAuthorized(account, [permissions.office.files.edit])}
        />
      ) : null}
    </div>
  );
};

export default Sidebar;
