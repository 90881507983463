import PageTitle from 'common/components/general/PageTitle';
import NavTabs from 'manuals/components/NavTabs';
import Layout from 'common/components/filesystem';

const FileSystem = () => {
  return (
    <div>
      <PageTitle
        className="mb-0"
        render={() => (
          <div className="d-flex align-items-center">
            <NavTabs />

            <div className="border-start ps-3">Files</div>
          </div>
        )}
      />

      <Layout />
    </div>
  );
};

export default FileSystem;
