import React, { useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';

import * as listActions from 'store/lists/actions';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

const DispensationTypeSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  label,
  placeholder,
  isClearable = true,
  ...rest
}) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const { options } = useSelector(state => state.lists['extension-and-dispensation-types']);

  useEffect(() => {
    fetchListOptions('extension-and-dispensation-types');
  }, [fetchListOptions]);

  return (
    <Select
      autoFocus
      label={label}
      placeholder={placeholder ? placeholder : 'Select value'}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter ? (filter.value && Array.isArray(filter.value) ? filter.value : []) : value}
      options={options}
      isMulti={isMulti}
      onChange={selected =>
        onChange({
          data: selected ? selected : [],
          value: selected ? (isMulti ? selected : selected) : ''
        })
      }
      isClearable={isClearable}
      {...rest}
    />
  );
};

export default DispensationTypeSelector;
