import ShadowBox from 'common/components/general/ShadowBox';

const Notes = ({ person }) => {
  return (
    <ShadowBox className="fs-12 d-flex flex-column flex-1 person-notes-box">
      <div className="d-flex align-items-center mb-3 mx-2 pt-2 fs-12">
        <strong>NOTES</strong>
      </div>

      <div className="flex-1 overflow-y px-2 pb-2 fs-12">{person.notes}</div>
    </ShadowBox>
  );
};

export default Notes;
