import React from 'react';
import _get from 'lodash/get';

const ApproverChange = props => {
  const { properties } = props;

  const name = _get(properties, 'object.full_name', 'None');

  return (
    <div className="d-flex align-items-center">
      <div data-testid="job-activity-approver" className="fs-12 text-violet fw-light">
        {name ? name : 'None'}
      </div>
    </div>
  );
};

export default ApproverChange;
