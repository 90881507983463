import moment from 'moment';
import DateInput from 'common/components/form/inputs/date';

const FormDateInput = ({ error, value, onChange }) => {
  return (
    <DateInput
      onChange={d => onChange(d ? d.format('YYYY-MM-DD') : null)}
      className="mb-0"
      error={error}
      value={value ? moment(value, 'YYYY-MM-DD') : null}
    />
  );
};

export default FormDateInput;
