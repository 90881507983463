import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Row, Col } from 'reactstrap';
import LinkedEntitiesBox from 'common/components/link-entities/_components/linked-entities-box/index';
import {
  ID,
  Title,
  Type,
  ActionButtons
} from 'common/components/link-entities/_components/linked-entities-box/helpers';
import Departments from 'common/components/general/Departments';
import EntityLabel from 'common/components/labels/EntityLabel';
import CircledButton from 'common/components/buttons/CircledButton';
import AuthCheck from 'components/permissions/AuthCheck';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import findingsIcon from 'common/assets/svg/common/findings.svg';
import paths from 'routing/routes/_paths';
import permissions from 'common/utils/permissions/constants';

import PropTypes from 'prop-types';
import { Finding } from 'common/entities/findings/FindingTypes';

const LinkedFindings = ({ findingsState, className, isSingleLine = false, color, onUnlink }) => {
  const [modal, setModal] = useState({ isOpen: false, id: null });
  const isOnBoard = useSelector(state => state.isOnBoard);

  if (!findingsState?.length) return null;

  const goToUrl = id => {
    if (!id) return;

    window.open(`${paths.findings}/${id}`, '_blank');
  };
  const showUnlinkButton = onUnlink && !isOnBoard;

  return (
    <>
      <LinkedEntitiesBox
        entitiesState={findingsState}
        className={className}
        icon={findingsIcon}
        headerTitle="Linked Findings"
        color={color}
        render={finding => (
          <>
            <Row className="w-100p flex-nowrap">
              <Col className="max-width-56 min-width-56 overflow-hidden cpt-4">
                <ID id={finding?.id} />
              </Col>

              <Col className="overflow-hidden">
                <Row>
                  <Col xs={12} className="overflow-hidden">
                    <Row>
                      <Col
                        className={`d-flex overflow-hidden cpt-2 ${
                          finding?.category?.id && isSingleLine ? 'cpb-2' : ''
                        }`}
                      >
                        <Title importanceId={finding?.importance_id} name={finding?.name} />
                        {finding?.category?.id && isSingleLine ? (
                          <Type category={finding?.category} className="ms-2" />
                        ) : null}
                      </Col>
                      <Col xs="auto" className="d-flex ps-1 justify-content-end align-items-center">
                        <Departments values={[finding.department]} />
                        <EntityLabel
                          color={finding?.resolved_at ? 'color_5' : 'color_4'}
                          className="fw-bold cms-4"
                          innerClassName="mx-auto fs-10"
                          type="status"
                        >
                          {finding?.resolved_at ? 'RESOLVED' : 'UNRESOLVED'}
                        </EntityLabel>
                      </Col>
                    </Row>
                  </Col>

                  {finding?.category?.id && !isSingleLine ? (
                    <Col xs={12} className="cpb-2">
                      <Type category={finding?.category} />
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
            <ActionButtons>
              <AuthCheck
                permissions={[
                  permissions.office.jobs.list.findings.view,
                  permissions.onboard.jobs.list.view
                ]}
              >
                <CircledButton
                  tooltipMessage="View"
                  type="view"
                  onClick={() => goToUrl(finding?.id)}
                  svgWrapperClassName={{ minWidth: '1.25rem', height: '1.25rem' }}
                />
              </AuthCheck>
              {showUnlinkButton ? (
                <CircledButton
                  className="cms-6"
                  tooltipMessage="Unlink Finding"
                  type="unlink"
                  onClick={() => setModal({ isOpen: true, id: finding?.id })}
                  svgWrapperClassName={{ minWidth: '1.25rem', height: '1.25rem' }}
                />
              ) : null}
            </ActionButtons>
          </>
        )}
      />
      <DangerousActionModal
        transparent
        action="unlink"
        onAccept={() => onUnlink(modal.id)}
        closeModal={() => setModal({ isOpen: false, id: null })}
        isOpen={modal.isOpen}
        actionText="UNLINK"
        header="Unlink Finding"
        body="Are you sure you want to unlink this finding?"
      />
    </>
  );
};

export default LinkedFindings;

LinkedFindings.propTypes = {
  findingsState: PropTypes.arrayOf(Finding),
  className: PropTypes.string,
  color: PropTypes.string,
  onUnlink: PropTypes.func,
  isSingleLine: PropTypes.bool
};
