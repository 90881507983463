import SvgRender from 'common/components/general/SvgRender';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import CommentsPopover from 'common/components/popovers/CommentsPopover';
import LubOilAnalysisResult from 'events/components/LubOilAnalysisResult';
import LubOilAnalysisAttachments from 'events/components/LubOilAnalysisAttachments';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

export const columns = [
  {
    header: 'System',
    key: 'vessel_system_assignment',
    data_key: 'vessel_system_assignment.description',
    minWidth: 276,
    maxWidth: 276,
    truncate: true,
    sort: true,
    sortingKey: 'assignment_description'
  },
  {
    key: 'office_comments',
    minWidth: 36,
    maxWidth: 36
  },
  {
    header: 'Company',
    key: 'company',
    data_key: 'company.full_name',
    minWidth: 310,
    maxWidth: 310,
    truncate: true,
    sort: true,
    sortingKey: 'company_name'
  },
  {
    header: 'ANALYSIS',
    key: 'analysis',
    minWidth: 104,
    maxWidth: 104,
    sort: true
  },
  {
    key: 'office_attachments',
    minWidth: 64,
    maxWidth: 64
  },
  {
    header: (
      <div className="d-flex align-items-center justify-content-center text-white">
        <SvgRender src={vesselIcon} style={{ width: 10, height: 10 }} />
        <strong className="fs-10 ps-1">VESSEL</strong>
      </div>
    ),
    key: 'vessel',
    type: 'group',
    columns: [
      {
        header: 'Comments',
        key: 'comments',
        minWidth: 294,
        maxWidth: 294
      },
      {
        key: 'attachments',
        minWidth: 32,
        maxWidth: 32
      }
    ]
  },
  {
    header: 'LAST DONE',
    key: 'last_done_date',
    type: 'date',
    sort: true,
    minWidth: 104,
    maxWidth: 104
  },
  {
    key: 'actions',
    field: 'actions'
  }
];

export const rows = {
  analysis: data => <LubOilAnalysisResult analysis={data.analysis} />,
  office_comments: data => (
    <CommentsPopover popoverTitle="Office Comments" comments={data.office_comments} />
  ),
  office_attachments: data => (
    <LubOilAnalysisAttachments
      id={data.id}
      attachments={data.office_attachments}
      fileType="office_attachments"
    />
  ),
  'vessel.comments': data => (
    <TextWithTooltip className="h-auto">{data.vessel_comments}</TextWithTooltip>
  ),
  'vessel.attachments': data => (
    <LubOilAnalysisAttachments
      id={data.id}
      attachments={data.vessel_attachments}
      fileType="vessel_attachments"
    />
  )
};
