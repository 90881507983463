import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import moment from 'moment';
import calendar from 'common/assets/svg/crew//calendar.svg';

import _get from 'lodash/get';

const dateFormatApi = 'YYYY-MM-DD';
const dateFormat = 'DD/MM/YYYY';

const ContractPeriod = ({ crew, isCrewProfile }) => {
  return (
    <>
      {isCrewProfile ? (
        <div className="fs-14 lh-16 text-primary fw-normal cmb-12">Contract Period</div>
      ) : null}
      <div
        className={`d-flex align-items-center fw-normal text-violet ${
          isCrewProfile ? 'fs-12 lh-15' : 'fs-10 lh-12'
        }`}
      >
        <SvgRender src={calendar} style={{ width: 12, height: 12 }} className="me-1" />
        {_get(crew, 'contract.sign_on_date') || _get(crew, 'contract.sign_off_date')
          ? `${moment(crew.contract.sign_on_date, dateFormatApi).format(dateFormat)} - ${moment(
              crew.contract.sign_off_date,
              dateFormatApi
            ).format(dateFormat)}`
          : ''}
      </div>
    </>
  );
};

export default ContractPeriod;
