import TYPES from './types';
import { get, put } from 'utils/api';
import _get from 'lodash/get';
import { successHandler } from 'common/utils/notifications';

export const getVesselSystemMaintenanceJobs = params => (dispatch, getState) => {
  const { loadMore, table, ...rest } = params;
  const { current_page, last_page } = getState().pms.jobs.paging;
  const { isLoading } = getState().pms.jobs;

  if (loadMore && (isLoading || current_page >= last_page)) return;

  const requestParams = { ...rest };

  if (loadMore) {
    requestParams.paging.current_page = current_page + 1;
  }

  dispatch({ type: TYPES.GET_VESSEL_SYSTEM_MAINTENANCE_JOBS.START, payload: { params } });
  dispatch(
    setSelectedVessels(
      _get(
        (params?.filters || []).find(f => f.name === 'vessel_id' && f.value),
        'value',
        []
      )
    )
  );

  return get(`/vessel-systems/assignments/maintenance-jobs`, requestParams)
    .then(response => {
      dispatch({ type: TYPES.GET_VESSEL_SYSTEM_MAINTENANCE_JOBS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      return dispatch({ type: TYPES.GET_VESSEL_SYSTEM_MAINTENANCE_JOBS.ERROR, payload: error });
    });
};

export const changeJobStatuses = params => dispatch => {
  dispatch({ type: TYPES.CHANGE_JOB_STATUSES.START, payload: { params } });

  return put(`/vessel-systems/maintenance-jobs/status`, params)
    .then(response => {
      dispatch(
        successHandler({
          title: 'Success!',
          message: `Successfully updated ${_get(response, 'data.updated_count', 0)} jobs`
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CHANGE_JOB_STATUSES.ERROR, payload: error });
      throw error;
    });
};

//Job history
export const fetchPmsJobHistory = params => dispatch => {
  dispatch({ type: TYPES.FETCH_PMS_JOB_HISTORY.START, payload: { params } });

  return get(`/vessel-systems/maintenance-jobs`, params)
    .then(response => {
      dispatch({ type: TYPES.FETCH_PMS_JOB_HISTORY.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.FETCH_PMS_JOB_HISTORY.ERROR, payload: error });
      throw error;
    });
};

export const setSelectedVessels = vessels => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_VESSELS, payload: vessels });
};

export const setExpandedAssignment = (id, isOpen) => dispatch => {
  dispatch({ type: TYPES.SET_EXPANDED_ASSIGNMENT, payload: { id, isOpen } });
};

export const toggleJobDrawer = (isOpen, activeJobID) => dispatch => {
  dispatch({ type: TYPES.TOGGLE_JOB_DRAWER, payload: { isOpen, activeJobID } });
};

export const setAllExpanded = isExpanded => dispatch => {
  dispatch({ type: TYPES.SET_ALL_EXPANDED, payload: isExpanded });
};

export const setSelectedSystemFilter = params => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_SYSTEM_FILTER, payload: params });
};

export const setSelectedVesselFilter = params => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_VESSEL_FILTER, payload: params });
};
