import React from 'react';
import _get from 'lodash/get';
import { parseTextHtml } from 'common/components/jobs/_base/modules/checklist/helpers';

const Checklist = props => {
  const { properties } = props;

  let title = _get(properties, 'object.title', null);
  if (!title) {
    title = _get(properties, 'object.text', null);
  }
  const mentions = _get(properties, 'object.mentions', []);

  const createMarkup = () => {
    return {
      __html: parseTextHtml(title, mentions)
    };
  };

  return (
    <div className="d-flex align-items-center activity-checklist">
      <div className="text-violet fw-light" dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

export default Checklist;
