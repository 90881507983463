import { usePlannedRouteWaypointsQuery } from '@/api/planned-routes/queries';
import { selectCaptainReportPlannedRouteId } from '@/captain-reports/store/selectors';
import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import { displayDate } from '@/common/utils/dates';
import _last from 'lodash/last';

const ReportEta = () => {
  const plannedRouteId = useSelector(selectCaptainReportPlannedRouteId);
  const plannedRouteWaypoints = usePlannedRouteWaypointsQuery({
    id: plannedRouteId
  });

  const lastEta = _last(plannedRouteWaypoints?.data || [])?.eta;

  return (
    <div className="border-start mb-0 cms-4 ps-2">
      <Label>ETA</Label>
      <div className="cpt-4 fs-12 fw-medium">{lastEta ? displayDate(lastEta) : '-'}</div>
    </div>
  );
};

export default ReportEta;
