import * as d3 from 'd3';
import { trimBigValue } from '../helpers/_functions';

const styleDefinitions = [
  {
    type: 'attr',
    key: 'fill',
    style: 'timeMarkerTextColor',
    element: 'text',
    defaultValue: '#808080'
  },
  {
    type: 'attr',
    key: 'font-weight',
    style: 'timeMarkerFontWeight',
    element: 'text',
    defaultValue: 400
  },
  {
    type: 'attr',
    key: 'font-size',
    style: 'timeMarkerFontSize',
    element: 'text',
    defaultValue: 10
  },

  {
    type: 'attr',
    key: 'stroke',
    style: 'timeMarkerBorderColor',
    element: 'area',
    defaultValue: 'none'
  },
  {
    type: 'style',
    key: 'opacity',
    style: 'timeMarkerAreaOpacity',
    element: 'area',
    defaultValue: 0.2
  },
  {
    type: 'attr',
    key: 'stroke-width',
    style: 'timeMarkerBorderWidth',
    element: 'area',
    defaultValue: 0
  },
  {
    type: 'attr',
    key: 'fill',
    style: 'timeMarkerBackgroundColor',
    element: 'area',
    defaultValue: '#62b58f'
  }
];

const DEFAULT_TIME_MARKER_LABEL_OFFSET = 18;
const DEFAULT_LABEL_Y_POSITION = 2;

const optionDefinitions = {
  timeMarkerOffset: {
    defaultValue: DEFAULT_TIME_MARKER_LABEL_OFFSET
  },
  timeMarkerLabelYPosition: {
    defaultValue: DEFAULT_LABEL_Y_POSITION
  }
};

const renderPlot = ({
  plotID,
  plot,
  getPlotStyle,
  xFn,
  getGraphStyle,
  y0Fn,
  element,
  getDimensions
}) => {
  const getOption = optionKey => getPlotStyle(optionKey, optionDefinitions[optionKey].defaultValue);

  const { height } = getDimensions();

  const area = d3
    .area()
    .x(datum => datum.xPos)
    .y1(() => getGraphStyle('marginTop'))
    .y0(() => height + getGraphStyle('marginTop'));

  const line = d3
    .line()
    .x(xFn)
    .y(datapoint => datapoint.y);

  // Have two points: one x, y:height and another one x, y:0 so that it draws a straight vertical line.
  const verticalLineData = [
    { x: plot.data[0].x, y: getGraphStyle('marginTop') },
    { x: plot.data[0].x, y: y0Fn() }
  ];

  const isMulti = plot.data.filter(datum => datum.x).length > 1;
  const hasDatum = plot.data.filter(datum => datum.y).length > 0;

  // Notice the .selectAll here. It is important since the number of elements we have is the same as our data points.
  // This is different from the rest since in the line plotType for example, we only have one single <path /> element.

  let areaWidth = 0;

  if (isMulti) {
    const data = plot.data.map(datum => {
      datum['xPos'] = xFn(datum);
      return datum;
    });
    element.selectAll(`#${plotID} path`).attr('d', area(data));
    areaWidth = d3.max(data, datum => datum.xPos) - d3.min(data, datum => datum.xPos);
  } else if (hasDatum) {
    element.selectAll(`#${plotID} path`).attr('d', line(verticalLineData));
  }

  plot.data
    ?.filter(e => e.label)
    .map(dataPoint => {
      const text = trimBigValue(dataPoint.label, areaWidth);
      element
        .selectAll(`#${plotID} text`)
        .data(plot.data)
        .attr('x', x => xFn(x) + areaWidth / 2)
        .attr('y', () => height / getOption('timeMarkerLabelYPosition'))
        .text(text);
    });

  styleDefinitions.forEach(styleDefinition => {
    if (styleDefinition.element === 'text') {
      element
        // Here is the .selectAll again!
        .selectAll(`#${plotID} text`)
        [styleDefinition.type](
          styleDefinition.key,
          getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
        );
    } else if (styleDefinition.element === 'area') {
      element
        .selectAll(`#${plotID} path`)
        [styleDefinition.type](
          styleDefinition.key,
          getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
        );
    }
  });
};

const renderComponent = ({ plotID, plot }) => {
  return (
    <g id={plotID} key={plotID} style={{ pointerEvents: 'none' }}>
      <path id={`path-${plotID}`} key={`path-${plotID}`} />
      {plot.data
        ?.filter(e => e.label)
        .map((dataPoint, index) => {
          return (
            <text
              baseline-shift="0%"
              kerning="0"
              textAnchor="middle"
              key={dataPoint.id + '-' + index}
            ></text>
          );
        })}
    </g>
  );
};

const timemarker = {
  renderPlot,
  renderComponent
};

export default timemarker;
