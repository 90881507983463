import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import DateInput from 'common/components/form/inputs/date';

const Survey = ({ fields, selectField }) => {
  return (
    <Row>
      <Col xs="auto">
        <DateInput label="Range From" onChange={selectField('range_from')} {...fields.range_from} />
      </Col>
      <Col xs="auto">
        <DateInput label="Range To" onChange={selectField('range_to')} {...fields.range_to} />
      </Col>
      <Col xs="auto">
        <DateInput label="Postponed" onChange={selectField('postponed')} {...fields.postponed} />
      </Col>
    </Row>
  );
};

Survey.propTypes = {
  fields: PropTypes.object.isRequired,
  selectField: PropTypes.func.isRequired
};

export default Survey;
