import React from 'react';
import UploadVesselImage from 'common/components/vessels/profile/UploadVesselImage';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { useActions } from 'utils/hooks';
import * as vesselActions from 'store/vessels/actions';

const VesselPhoto = () => {
  const profile = useSelector(selectVesselProfile);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const [updateVessel] = useActions([vesselActions.updateVessel]);

  return (
    <Row>
      <Col xs={12}>
        <UploadVesselImage disabled={isOnBoard} profile={profile} updateVessel={updateVessel} />
      </Col>
    </Row>
  );
};

export default VesselPhoto;
