import React, { useState, useMemo } from 'react';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import PropTypes from 'prop-types';

import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import { Question } from 'common/entities/vetting/QuestionTypes';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const VettingViqQuestionSelector = ({ filter, value, onChange, chapterId, isMulti, ...rest }) => {
  const [selected, setSelected] = useState([]);

  const requestParams = useMemo(() => {
    const params = { paging: { per_page: 100, current_page: 1 } };

    if (chapterId) {
      params.filters = [{ name: 'chapter_id', operation: 'oneOf', value: [chapterId] }];
    }

    return params;
  }, [chapterId]);

  useFilterSelector(
    value || filter?.value,
    { list: 'vetting-questions', listParams: requestParams },
    { selected, setSelected }
  );

  return (
    <AsyncSelector
      type="vetting-questions"
      placeholder="Select question"
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.number}. ${option.description}`}
      loadOptions={search => getAsyncOptions(search, 'vetting-questions', requestParams)}
      defaultOptions={() => refetchAsyncOptions('vetting-questions', requestParams, true)}
      defaultOptionsTriggerChange={chapterId}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? selected.filter(el => filter?.value.map(Number).includes(el.id))
          : []
      }
      onChange={selected => {
        setSelected(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      isMulti={isMulti}
      {...rest}
    />
  );
};

VettingViqQuestionSelector.propTypes = {
  value: Question,
  onChange: PropTypes.func,
  chapterId: PropTypes.number
};

export default VettingViqQuestionSelector;
