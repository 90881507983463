import EntityLabel from 'common/components/labels/EntityLabel';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const DepartmentName = ({ name, color }: { name: string; color: string }) => (
  <EntityLabel className="fw-medium border-radius-3" type="department" color={color}>
    <TextWithTooltip>{name}</TextWithTooltip>
  </EntityLabel>
);

export default DepartmentName;
