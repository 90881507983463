import FindingTagsSelector from '@/common/components/selectors/FindingTagsSelector';
import EventRelatedAuditSelector from 'common/components/selectors/EventAuditsSelector';
import EventStatusSelector from 'common/components/selectors/EventStatusSelector';
import OcimfCategorySelector from 'common/components/selectors/OcimfCategorySelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import EventModulesSelector from 'common/components/selectors/EventModulesSelector';
import DepartmentsFilterSelector from 'common/components/selectors/departments/FilterSelector';
import VesselSelector from 'common/components/selectors/VesselSelector';
import PortSelector from 'common/components/selectors/PortSelector';
import ImportanceSelector from 'events/components/selectors/ImportanceSelector';
import TypeSelector from 'events/components/selectors/TypeSelector';
import eventsIcon from '@/common/assets/svg/common/events.svg';

const getColumns = (isOnBoard: boolean) => [
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance',
    type: 'collection',
    sort: true,
    sticky: true,
    canFilter: false,
    left: 0,
    minWidth: 49,
    maxWidth: 49,
    className: 'priority-cell'
  },
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance_id',
    type: 'collection',
    sort: false,
    hidden: true,
    component: ImportanceSelector,
    componentRest: { isMulti: true },
    canFilter: true
  },
  {
    header: 'Name',
    key: 'name',
    type: 'string',
    sort: true,
    sticky: true,
    left: 49,
    minWidth: 384,
    maxWidth: 384,
    className: 'title-cell'
  },
  {
    header: 'Type',
    key: 'type',
    type: 'string',
    canFilter: false,
    sort: false,
    sticky: true,
    left: 433,
    minWidth: 257,
    maxWidth: 257,
    className: 'title-cell'
  },
  {
    header: 'type',
    filterLabel: 'Type',
    key: 'type_id',
    type: 'collection',
    hidden: true,
    sort: false,
    component: TypeSelector,
    componentRest: { isMulti: true },
    canFilter: true
  },
  {
    header: 'ID',
    key: 'id',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 65,
    maxWidth: 65
  },
  {
    header: 'Date',
    key: 'started_at',
    type: 'date',
    sort: true,
    showUtc: true,
    canFilter: true,
    className: 'ps-3',
    minWidth: 229,
    maxWidth: 229
  },
  {
    header: 'Responsible',
    key: 'responsible',
    type: 'string',
    sort: true,
    canFilter: false,
    minWidth: 217,
    maxWidth: 217
  },
  {
    header: 'Responsible',
    key: 'responsible_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: PersonSelector,
    componentRest: {
      isMulti: true,
      listParams: { can_login: true, ...(isOnBoard ? { onboard: true } : {}) }
    }
  },
  {
    header: 'Department',
    key: 'department_ids',
    type: 'collection',
    sort: false,
    component: DepartmentsFilterSelector,
    minWidth: 217,
    maxWidth: 217
  },
  {
    header: 'Approvers',
    key: 'approvers',
    type: 'string',
    sort: true,
    canFilter: false,
    hidden: isOnBoard,
    minWidth: 217,
    maxWidth: 217
  },
  {
    header: 'Approvers',
    key: 'approver_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: PersonSelector,
    componentRest: {
      isMulti: true,
      listParams: { can_login: true, ...(isOnBoard ? { onboard: true } : {}) }
    }
  },
  {
    header: 'Status',
    key: 'status',
    type: 'collection',
    sort: true,
    canFilter: false,
    minWidth: 217,
    maxWidth: 217,
    className: 'ps-4'
  },
  {
    header: 'Status',
    key: 'status_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: EventStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Vessel',
    key: 'vessel',
    type: 'collection',
    sort: true,
    canFilter: false,
    hidden: isOnBoard,
    className: `ps-4 ${isOnBoard ? 'd-none' : ''}`,
    minWidth: 145,
    maxWidth: 145
  },
  {
    header: 'Vessel',
    key: 'vessel_id',
    type: 'collection',
    sort: false,
    canFilter: !isOnBoard,
    component: VesselSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Port',
    key: 'port',
    type: 'collection',
    sort: true,
    minWidth: 217,
    maxWidth: 217,
    canFilter: false,
    className: `${isOnBoard ? 'ps-4' : 'pe-5'}`
  },
  {
    header: 'Port',
    key: 'port_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: PortSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Included Modules',
    key: 'module_type_ids',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: EventModulesSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Finding Tags',
    key: 'finding_tag_ids',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: FindingTagsSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Audit/Inspection',
    key: 'event_audit_id',
    type: 'collection',
    component: EventRelatedAuditSelector,
    componentRest: { isMulti: true },
    sort: false,
    canFilter: !isOnBoard,
    hidden: true
  },
  {
    header: 'Ocimf System Category',
    key: 'ocimf_system_categorization',
    component: OcimfCategorySelector,
    type: 'ocimf_system_category',
    hidden: true,
    canFilter: true
  },
  {
    header: 'Watching',
    key: 'is_watching',
    type: 'boolean',
    hidden: true,
    canFilter: !isOnBoard,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    hidden: isOnBoard,
    field: 'actions'
  }
];

const config = {
  getColumns,
  getTopFilters: () => null,
  topFiltersComponent: () => null,
  tableStyle: 'default',
  isModular: false,
  hideTableReorder: false,
  hideTableFilters: false,
  icon: eventsIcon,
  pageTitleComponent: null,
  name: 'Events',
  entity: 'events'
};

export default config;
