import { useEffect, FC } from 'react';

import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

import ReportStatus from 'captain-reports/components/ReportStatus';
import { fetchListOptions } from 'store/lists/actions';

import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';
import { selectListOptionsFromStore } from '@/store/lists/selectors-ts.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import { ReportGroup } from '@/common/types/captain-reports.ts';
import { strToNumber } from '@/ts-common/utils/numbers';
import useIsInOnboardSetupFilters from '@/common/components/shared-table-configs/hooks/useIsInOnboardSetupFilters.ts';

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
};

const statuses = [
  { text: 'All', id: 'report-status-all', tooltip: 'All' },
  { label: 'approved', id: 'report-status-approved', tooltip: 'Approved' },
  { label: 'pending', id: 'report-status-pending', tooltip: 'Pending' },
  { label: 'draft', id: 'report-status-draft', tooltip: 'Draft' },
  { label: 'rebuild', id: 'report-status-rebuild', tooltip: 'For Correction' }
];

const TopFilters: FC<TopFiltersComponentProps> = ({ topFilters, setTopFilters }) => {
  const dispatch = useAppDispatch();
  const isInOnboardFilters = useIsInOnboardSetupFilters();

  const groups = useAppSelector(state =>
    selectListOptionsFromStore(state, 'report-groups')
  ) as ReportGroup[];

  const [status, setStatus] = useTableTopFilterValue(
    { topFilters: topFilters, setTopFilters: setTopFilters },
    'status'
  );

  const [reportGroupId, setReportGroupId] = useTableTopFilterValue(
    { topFilters: topFilters, setTopFilters: setTopFilters },
    'report_group_id'
  );

  useEffect(() => {
    dispatch(fetchListOptions(`report-groups`));
  }, [dispatch]);

  const onTabClick = (value: string | undefined) => {
    setStatus(value ? [value] : null);
  };

  const onGroupTabClick = (value: number) => {
    setReportGroupId(value);
  };

  return (
    <div
      className={`d-flex align-items-center reports-statuses-filter ${
        isInOnboardFilters ? 'mb-2' : ''
      }`}
    >
      {groups.length > 1 ? (
        <NavigationGroupTabs
          className="me-1"
          tabs={groups.map((group, index) => ({
            isActive: reportGroupId ? strToNumber(reportGroupId) === group.id : index === 0,
            label: group.label,
            onClick: () => onGroupTabClick(group.id)
          }))}
        />
      ) : null}

      <NavigationGroupTabs
        tabs={statuses.map((innerStatus, index) => ({
          isActive:
            (Array.isArray(status) && !!status?.find((e: string) => e === innerStatus.label)) ||
            (!status && index === 0),
          label: innerStatus.text ? (
            innerStatus.text
          ) : (
            <ReportStatus status={innerStatus.label} showTooltip={false} />
          ),
          onClick: () => onTabClick(innerStatus.label),
          tooltip: innerStatus.tooltip
        }))}
      />
    </div>
  );
};

export default TopFilters;
