import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import { Button } from 'reactstrap';
import { getCorrectLeft } from './helpers';

const ArrowButton = ({ left, isLeft = true, setLeft, isOverflown, minAllowedLeft }) => {
  const handleLeft = () => setLeft(prev => getCorrectLeft(prev, isLeft, minAllowedLeft));

  const isDisabled = (isLeft && left === 0) || (!isLeft && left === minAllowedLeft);

  useEffect(() => {
    if (!isOverflown) setLeft(0);
  }, [isOverflown, setLeft]);

  if (!isOverflown || isDisabled) return null;

  return (
    <Button
      disabled={isDisabled}
      onClick={handleLeft}
      className={`event-toolbar__slider-btn position-absolute d-flex align-items-center justify-content-center 
    bg-primary rounded-circle cpx-4 cpy-4 ${isLeft ? 'left' : 'right'}`}
    >
      <SvgRender
        src={arrow}
        style={{ width: 9, height: 9, transform: isLeft ? 'rotate(270deg)' : 'rotate(90deg)' }}
      />
    </Button>
  );
};

export default ArrowButton;

ArrowButton.propTypes = {
  isLeft: bool,
  setLeft: func.isRequired,
  isOverflown: bool.isRequired
};
