import TYPES from './types';

const INITIAL_STATE = {
  active: null,
  data: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_RESTRICTED_FIELDS.SUCCESS:
      return {
        ...state,
        data: payload.data
      };

    case TYPES.SET_ACTIVE:
      return {
        ...state,
        active: payload
      };

    case TYPES.UPDATE_RESTRICTED_FIELDS.SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          ...payload
        }
      };

    default:
      return state;
  }
};

export default reducer;
