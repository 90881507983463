import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { useEffect, useState } from 'react';
import {
  selectOfficeSavedNotes,
  selectOfficeNotesIsLoading
} from 'events/store/event-modules/office-notes/selectors';
import {
  createOfficeNotes,
  deleteOfficeNotes,
  editOfficeNotes,
  getOfficeNotes
} from 'events/store/event-modules/office-notes/actions';
import { addOfficeNotes } from 'events/store/event-modules/office-notes/slice';
import Notes from '../components/notes';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const OfficeNotes = () => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState({});

  const isOnBoard = useSelector(state => state.isOnBoard);
  const savedNotes = useSelector(selectOfficeSavedNotes);

  const isLoading = useSelector(selectOfficeNotesIsLoading);
  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);

  const onSave = async params => {
    const { content, tags, id } = params;

    if (isTemplate || !eventId) {
      dispatch(addOfficeNotes([{ content, tags: tags?.map(text => ({ text })) }]));
      return;
    }

    if (id) {
      await dispatch(editOfficeNotes({ eventId, content, tags, id })).unwrap();
    } else {
      await dispatch(createOfficeNotes({ eventId, content, tags })).unwrap();
    }
  };

  const onPreventDelete = async id => {
    if (isTemplate || !eventId) {
      dispatch(addOfficeNotes([]));
      return;
    }

    setDeleteModal({ isOpen: true, id });
  };

  const onDelete = async () => {
    try {
      await dispatch(deleteOfficeNotes({ eventId, id: deleteModal?.id })).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!eventId || isTemplate) return;

    dispatch(getOfficeNotes({ id: eventId }));
  }, [dispatch, eventId, isTemplate]);

  return (
    <>
      <Notes
        isLoading={isLoading}
        savedNotes={savedNotes}
        onSave={onSave}
        onDelete={onPreventDelete}
        type="office_notes"
        hideActions={isOnBoard || isTemplate || (!eventId && savedNotes.length)}
      />

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDelete}
        closeModal={() => setDeleteModal({})}
        isOpen={deleteModal?.isOpen}
        actionText={'DELETE'}
        header={'Delete'}
        body={`Are you sure you want to delete your note?`}
      />
    </>
  );
};

export default OfficeNotes;
