import { useSelector } from 'react-redux';
import Rotation from './Rotation';
import { useMemo } from 'react';
import { selectCrewChangeData } from 'events/store/event-modules/crew-changes/selectors';
import { getTicketRotations } from './helpers';

const Body = () => {
  const crewChange = useSelector(selectCrewChangeData);

  const offSignerRotations = useMemo(
    () => getTicketRotations('off_signer', crewChange),
    [crewChange]
  );
  const onSignerRotations = useMemo(
    () => getTicketRotations('on_signer', crewChange),
    [crewChange]
  );

  // const bulkTripIds = useMemo(() => {
  //   let ids = offSignerRotations
  //     .filter(rotation => rotation.off_signer_trip_id && rotation.off_signer_trip?.is_bulk)
  //     .map(rotation => rotation.off_signer_trip_id);

  //   ids = [
  //     ...ids,
  //     ...onSignerRotations
  //       .filter(
  //         rotation =>
  //           rotation.on_signer_trip_id &&
  //           rotation.on_signer_trip?.is_bulk &&
  //           !ids.includes(rotation.on_signer_trip_id)
  //       )
  //       .map(rotation => rotation.on_signer_trip_id)
  //   ];

  //   return ids;
  // }, [offSignerRotations, onSignerRotations]);

  return (
    <>
      <div className="ticketing-page__rotations--off-signer mb-3">
        {offSignerRotations.map(crewSwitch => (
          <Rotation key={crewSwitch?.id} crewSwitch={crewSwitch} type="off_signer" />
        ))}
      </div>
      <div className="ticketing-page__rotations--on-signer">
        {onSignerRotations.map(crewSwitch => (
          <Rotation key={crewSwitch?.id} crewSwitch={crewSwitch} type="on_signer" />
        ))}
      </div>
    </>
  );
};

export default Body;
