import _isString from 'lodash/isString';
import _trim from 'lodash/trim';
import _isBoolean from 'lodash/isBoolean';

export const parseBoolean = value => {
  if (_isBoolean(value)) return value;

  if (_isString(value)) {
    const trimmedValue = _trim(value);

    if (trimmedValue === 'true') return true;
    if (trimmedValue === 'false') return false;
  }

  return null;
};
