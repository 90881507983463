import React, { useEffect } from 'react';
import _get from 'lodash/get';

import FieldLabel from 'captain-reports/templates/components/FieldLabel';
import ListSelect from 'captain-reports/components/ListSelect';
import { selectPreviousCaptainReport } from 'captain-reports/store/selectors';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';
import useInitializeFromPreviousReport from 'captain-reports/utils/useInitializeFromPreviousReport';

import * as reportActions from 'captain-reports/store/actions';
import useError from 'captain-reports/utils/useError';

const DynamicSelectField = ({
  field,
  tooltip,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const isLocked = useSelector(isReportLocked);
  const previousReportData = useSelector(selectPreviousCaptainReport);

  useInitializeFromPreviousReport({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const keyValuePath = `${
    field.defaultValueKey ||
    field.previousReportInitializationKey ||
    field.previousNavigationalInitializationKey
  }.value`;

  const defaultValue = _get(previousReportData, keyValuePath) || null;

  useEffect(() => {
    if (!value && (defaultValue || defaultValue === 0)) {
      setValue({
        key: field.key,
        value: defaultValue,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex,
        selectKey: selectRest.selectKey
      });
    }
  }, [defaultValue]);

  const onChange = value => {
    setValue({
      key: field.key,
      value,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex,
      selectKey: selectRest.selectKey
    });
  };

  const { selectRest = {} } = field;

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  if (!selectRest.list) {
    return <div>{field.label}: Missing list</div>;
  }

  return (
    <div className="select-input select-input__dynamic">
      <ListSelect
        isDisabled={isLocked}
        isMulti={false}
        isClearable={true}
        list={selectRest.list}
        label={<FieldLabel info={tooltip} label={field.label} subLabel={field.subLabel} />}
        placeholder=""
        noOptionsMessage={({ inputValue }) =>
          selectRest.isAsync
            ? !inputValue.length
              ? `Search for ${field.label.toLowerCase()}`
              : `No ${field.label.toLowerCase()} found`
            : 'No options'
        }
        value={value}
        onChange={onChange}
        error={error}
        {...selectRest}
      />
    </div>
  );
};

export default DynamicSelectField;
