import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import to_go from 'common/assets/svg/common/arrows/arrow-one-way.svg';
import to_return from 'common/assets/svg/common/arrows/arrow-one-way.svg';
import round_trip from 'common/assets/svg/common/round-trip.svg';

const ItineraryTypeIcon = ({ type, style }) => {
  const icons = { to_go, to_return, round_trip };

  if (!icons[type]) return null;

  return (
    <SvgRender
      src={icons[type]}
      style={
        style
          ? style
          : {
              width: 16,
              height: 16,
              transform: type === 'to_return' ? `rotate(180deg)` : ''
            }
      }
    />
  );
};

export default ItineraryTypeIcon;
