import PropTypes from 'prop-types';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import { ScaleRatingValue } from 'common/components/general/ScaleRatings';

const Evaluations = ({ values }) => {
  return (
    <div className="text-primary fs-12">
      {(values || [])?.map((value, index) => (
        <div key={index} className="d-flex align-items-center cmt-12">
          <div>
            {index + 1}. {value?.criteria?.name}:
          </div>
          <div className="ms-1 fw-medium">
            {value?.criteria?.audit_evaluation_type === 'trainings' ? (
              <NamesInTooltip
                maxHeight={20}
                names={(value?.values || []).map(training => training?.name)}
              />
            ) : value?.criteria?.audit_evaluation_type === 'scale' ? (
              <ScaleRatingValue value={value?.values} />
            ) : (
              <div className="d-flex align-items-center text-primary">
                {value?.values ? 'Yes' : 'No'}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

Evaluations.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.shape({
        name: PropTypes.string,
        audit_evaluation_type: PropTypes.string
      }).isRequired,
      values: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.array]).isRequired
    })
  )
};

export default Evaluations;
