import { get } from 'utils/api';
import store from 'store';
import LISTS_TYPES from 'store/lists/types';
import { getListUrl } from 'store/lists/actions';
import { setAsyncFilterValues } from 'store/async-filters/actions';
import moment from 'moment';
import _findKey from 'lodash/findKey';
import _isEqual from 'lodash/isEqual';
import { v4 as uuid } from 'uuid';
import { lists } from 'common/utils/prefilled-lists';

const initialListState = {
  options: [],
  lastFetchedAt: null,
  isFetching: null,
  requestParams: null,
  requestType: null
};

const constructPrefilledList = list => {
  const prefilledListState =
    {
      ...lists
    }[list] || {};

  return {
    ...initialListState,
    ...prefilledListState
  };
};

export const makeEmptyList = lists => {
  const mappedLists = lists.map(list => ({ [list]: constructPrefilledList(list) }));

  return Object.assign({}, ...mappedLists);
};

// The following function is to be used in place of getInitialAsyncValues to bypass the default caching functionality.
// It refetches data every single time it is called.
export const refetchAsyncOptions = async (type, rest, parseResponse = false) => {
  let params = {};
  if (rest) params = { ...params, ...rest };

  const response = await get(getListUrl(type), params);

  if (!response) return [];

  return parseResponse
    ? response.data.data !== undefined && (params?.paging || params?.current_page)
      ? response.data.data
      : response.data.map(item => ({
          ...item,
          label: item.name,
          value: item.id
        }))
    : response.data;
};

export const getAsyncOptions = async (search, type, rest, parseResponse = true) => {
  if (!search) return [];

  let params = { search };
  if (rest) params = { ...params, ...rest };

  const response = await get(getListUrl(type), params);

  if (!response) return [];

  if (type === 'ranks-n-parties') {
    return response.data.map(item => ({
      ...item,
      entity_uid: `${item.entity_type}-${item.id}`
    }));
  } else if (type === 'port-statement-action-types') {
    return response.data.map(item => ({
      ...item
    }));
  } else if (type === 'mga-accounts' && params?.paging) {
    return response.data?.data || [];
  } else if (type === 'charter-parties') {
    return response.data.map(c => ({
      ...c,
      value: c.charter_party_id
    }));
  } else if (type === 'maintenance-jobs' && params?.paging) {
    return response.data?.data || [];
  } else if (response.data.data !== undefined && (params?.paging || params?.current_page)) {
    return response.data.data || [];
  }

  return parseResponse
    ? response.data.map(item => ({
        ...item,
        label: item.name,
        value: item.id
      }))
    : response.data;
};

export const getDefaultListKey = (type, params = {}) => {
  let key = null;

  /* When no params are set, or the params match any of the cases below, we have a default listing.
    The default lists' options are fetched only once and they are stored to state.lists._defaults.
    When already fetched, all async and list Selects, will has as default options the saved ones. 
  */
  if (type === 'parties' && Object.keys(params).length === 1 && params.can_login) {
    key = 'parties_can_login';
  } else if (type === 'parties' && params.exclude_full_permissions) {
    key = 'parties-limited-permissions';
  } else if (type === 'identifiers' && params.type === 'crew-contract-sign-off-reason') {
    key = 'crew-contract-sign-off-reason';
  } else if (type === 'reports' && params?.filters?.[0]?.value) {
    key = `reports-${params?.filters?.[0]?.value}`;
  } else if (!params || !Object.keys(params).length) {
    key = type;
  } else if (type === 'mga-accounts') {
    return null;
  } else if (!!params) {
    const defaultLists = store.getState()?.lists?._defaults;
    const equalParametersListKey = _findKey(
      defaultLists,
      list =>
        list?.requestType === type && !!list?.requestParams && _isEqual(list?.requestParams, params)
    ); // Find the list key with the same type & request params

    if (!equalParametersListKey) {
      // If not found, create a uniq key for this dynamic listing
      key = `${type}--${uuid()}`;
    } else {
      key = equalParametersListKey;
    }
  }

  return key;
};

export const updateDefaultListOptions = (type, params, data) => (dispatch, getState) => {
  const defaultLists = getState()?.lists?._defaults;
  const defaultListKey = getDefaultListKey(type, params);

  const { options } = defaultLists?.[defaultListKey] || { options: [] };

  if (!options?.length) {
    dispatch({
      type: LISTS_TYPES.SET_DEFAULT_LIST,
      payload: {
        list: defaultListKey,
        isFetching: false,
        options: data,
        requestType: type,
        requestParams: params
      }
    });

    dispatch(setAsyncFilterValues({ label: defaultListKey, data }));
  }
};

export const updateDefaultListTimestamp = (type, params) => dispatch => {
  const defaultListKey = getDefaultListKey(type, params);

  dispatch({
    type: LISTS_TYPES.SET_DEFAULT_LIST,
    payload: {
      list: defaultListKey,
      isFetching: true,
      lastFetchedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      requestType: type,
      requestParams: params
    }
  });
};

export const getInitialAsyncValues = async (type, id, isMulti, restParams = {}) => {
  const { defaultOptionsTriggerChange, ...rest } = restParams || {};
  const params = { ...rest };

  const defaultLists = store.getState()?.lists?._defaults;
  const defaultListKey = getDefaultListKey(type, params);

  if (id) params.id = id;

  const { lastFetchedAt, isFetching, options } = defaultLists?.[defaultListKey] || {};

  if (defaultListKey && !defaultOptionsTriggerChange) {
    if (lastFetchedAt) {
      if (isFetching === false) {
        // Options are already fetched
        return options;
      } else if (isFetching === true) {
        // Options are getting fetched
        return { defaultListKey };
      }
    }

    store.dispatch(updateDefaultListTimestamp(type, params));
  }

  try {
    const response = await get(getListUrl(type), params);
    if (!response) return null;

    if (defaultListKey) store.dispatch(updateDefaultListOptions(type, params, response.data));

    if (type === 'ranks-n-parties') {
      return response.data.map(item => ({
        ...item,
        entity_uid: `${item.entity_type}-${item.id}`
      }));
    } else if (type === 'charter-parties') {
      return response.data.map(c => ({
        ...c,
        value: c.charter_party_id
      }));
    } else if (response.data.data !== undefined && (params?.paging || params?.current_page)) {
      return response.data.data || [];
    }

    if (isMulti) {
      return response.data;
    } else {
      return response.data.length ? response.data : null;
    }
  } catch (error) {
    console.error(error);
    if (defaultListKey) store.dispatch(updateDefaultListOptions(type, params, []));
  }
};

export const getInitialSingleValue = async (path, params) => {
  try {
    const response = await get(path, params);

    return response.data;
  } catch (error) {
    return null;
  }
};
