import React from 'react';
import { useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';

import { Col, Row } from 'reactstrap';

import _isBoolean from 'lodash/isBoolean';

import Radio from 'common/components/form/inputs/Radio';
import Textarea from 'common/components/form/inputs/Textarea';

import notRecommendedForReemploymentIcon from 'common/assets/svg/common/not-recommended-for-reemploment.svg';
import notRecommendedForPromotionIcon from 'common/assets/svg/common/not-recommended-for-promotion.svg';
import recommendedForPromotionIcon from 'common/assets/svg/common/promotion.svg';
import recommendedForReemploymentIcon from 'common/assets/svg/common/reemployed.svg';
import notReemployedIcon from 'common/assets/svg/common/not-reemployed.svg';

import AuthCheck from 'components/permissions/AuthCheck';
import RoundedButtonWithTooltip from 'common/components/buttons/RoundedButtonWithTooltip';

import AverageScoreText from './AverageScoreText';
import { selectAverageScore } from 'crew/store/evaluations/profile/selectors';

import { isRankRecommendedForPromotion } from 'crew/utils/helpers';
import { selectActiveTemplateEvaluation } from 'crew/store/evaluations/templates/selectors';
import permissions from 'common/utils/permissions/constants';

const RecommendButtons = ({ formState }) => {
  const { fields, selectField, changeField } = useFormState(formState);

  const recommendedForPromotion = fields.recommended_for_promotion.value;
  const recommendedForReemployment = fields.recommended_for_reemployment.value;
  const recommendedForPromotionComment = fields.recommended_for_promotion_comment.value;
  const recommendedForReemploymentComment = fields.recommended_for_reemployment_comment.value;

  const averageScore = useSelector(selectAverageScore);

  const activeTemplateEvaluation = useSelector(selectActiveTemplateEvaluation);

  const getReemploymentIcon = () => {
    if (recommendedForReemployment) {
      return recommendedForReemploymentIcon;
    } else if (recommendedForReemployment === null) {
      return notRecommendedForReemploymentIcon;
    } else {
      return notReemployedIcon;
    }
  };

  return (
    <Row className="mt-4">
      {isRankRecommendedForPromotion(fields?.rank_name?.value) &&
      activeTemplateEvaluation?.has_recommended_for_promotion ? (
        <AuthCheck
          permissions={[
            permissions.office.crew.seaman_profile.evaluations.recommend_for_promotion,
            permissions.onboard.crew.seaman_profile.evaluations.all_users.recommend_for_promotion
          ]}
        >
          <Col xs={4} className="pe-3">
            <div
              className={`recommend-button bg-white cp-12 rounded-lg border-1 border border-white`}
            >
              <div className="w-100p d-inline-flex align-items-center justify-content-between cmb-12">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center cme-12">
                    <img
                      alt="recommended for promotion"
                      src={
                        recommendedForPromotion
                          ? recommendedForPromotionIcon
                          : notRecommendedForPromotionIcon
                      }
                      width={26}
                      height={26}
                    />
                  </div>
                  <span className="fw-medium fs-12 text-primary">Recommended for Promotion</span>
                </div>
                <div className="d-flex align-items-center cpe-12">
                  <Radio
                    className="text-primary fw-normal me-4 mb-0"
                    name="recommended_for_promotion"
                    value={null}
                    label="Yes"
                    checked={recommendedForPromotion}
                    onChange={() => selectField('recommended_for_promotion')(true)}
                  />

                  <Radio
                    className={`text-primary fw-normal mb-0`}
                    name="recommended_for_promotion"
                    value={null}
                    label="No"
                    checked={_isBoolean(recommendedForPromotion) ? !recommendedForPromotion : null}
                    onChange={() => selectField('recommended_for_promotion')(false)}
                  />
                </div>
              </div>
              <div>
                <Textarea
                  placeholder="Add some notes"
                  maxLength={255}
                  name="recommended-for-promotion-comment"
                  rows={5}
                  onChange={changeField('recommended_for_promotion_comment')}
                  value={recommendedForPromotionComment}
                  className="mb-0"
                  autoResize
                />
              </div>
            </div>
          </Col>
        </AuthCheck>
      ) : null}

      {activeTemplateEvaluation?.has_recommended_for_reemployment ? (
        <AuthCheck
          permissions={[
            permissions.office.crew.seaman_profile.evaluations.recommend_for_reemployment,
            permissions.onboard.crew.seaman_profile.evaluations.all_users.recommend_for_reemployment
          ]}
        >
          <Col xs={4}>
            <div
              className={`recommend-button bg-white cp-12 rounded-lg border-1 border border-${
                _isBoolean(recommendedForReemployment) && !recommendedForReemployment
                  ? 'red'
                  : 'white'
              }`}
            >
              <div className="w-100p d-inline-flex align-items-center justify-content-between cmb-12">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center cme-12">
                    <img
                      alt="recommended for reemployment"
                      src={getReemploymentIcon()}
                      width={26}
                      height={26}
                    />
                  </div>
                  <span
                    className={`fw-medium fs-12 text-primary ${
                      recommendedForReemployment === false ? 'text-red' : 'text-primary'
                    }`}
                  >
                    Recommended for Re-employment
                  </span>
                </div>
                <div className="d-flex align-items-center cpe-12">
                  <Radio
                    className="text-primary fw-normal me-4 mb-0"
                    name="recommended_for_reemployment"
                    value={null}
                    label="Yes"
                    checked={recommendedForReemployment}
                    onChange={() => selectField('recommended_for_reemployment')(true)}
                  />

                  <Radio
                    className={`text-primary ${
                      recommendedForReemployment === false ? 'recommend-button__negative' : ''
                    } fw-normal mb-0`}
                    name="recommended_for_reemployment"
                    value={null}
                    label="No"
                    checked={
                      _isBoolean(recommendedForReemployment) ? !recommendedForReemployment : null
                    }
                    onChange={() => selectField('recommended_for_reemployment')(false)}
                  />
                </div>
              </div>
              <div>
                <Textarea
                  placeholder="Add some notes"
                  maxLength={255}
                  name="recommended-for-reemployment-comment"
                  rows={5}
                  onChange={changeField('recommended_for_reemployment_comment')}
                  value={recommendedForReemploymentComment}
                  className="mb-0"
                  autoResize
                />
              </div>
            </div>
          </Col>
        </AuthCheck>
      ) : null}

      <Col className="d-flex justify-content-end align-items-start">
        <div>
          <RoundedButtonWithTooltip className="px-3 py-2 w-100p max-w-100p rounded-pill pointer-events-none">
            <AverageScoreText average={averageScore} />
          </RoundedButtonWithTooltip>
        </div>
      </Col>
    </Row>
  );
};

export default RecommendButtons;
