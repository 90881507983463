import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import _get from 'lodash/get';
import moment from 'moment';

const RightShipInspection = ({ field }) => {
  const profile = useSelector(selectVesselProfile);

  const getDate = item => {
    let value = _get(profile, item.key, null);
    if (!value) return '-';

    return moment(value).format('DD MMM YYYY');
  };
  return (
    <Row className="cmt-4 cmb-4">
      <Col className=" fs-12 text-blue fw-bold d-flex align-items-start justify-content-end" xs={4}>
        {field.label}
      </Col>
      <Col xs={8} className="fs-12  d-flex align-items-start ">
        <div className="d-flex">
          {field.extraData.map(el => {
            return (
              <React.Fragment key={el.key}>
                <div className="text-blue me-1 fw-bold">{el.label}</div>
                <div className="me-4">
                  {el.type === 'date'
                    ? getDate(el)
                    : _get(profile, el.key, null)
                    ? _get(profile, el.key, '-')
                    : '-'}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default RightShipInspection;
