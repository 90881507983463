import React, { useState, useEffect } from 'react';

import UploadImage from 'common/components/form/inputs/UploadImage';

import { upload } from 'utils/api';

const UploadVesselImage = ({ profile, updateVessel, disabled }) => {
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (updating) {
      setUpdating(false);
    }
  }, [profile.photo]);

  return (
    <>
      <UploadImage
        upload={upload}
        innerDelete
        group="vessels.photos"
        imageSizeLabel="vessel_profile"
        image={profile.photo && profile.photo.url ? profile.photo : null}
        onChange={val => {
          setUpdating(true);
          updateVessel({ id: profile.id, photo_id: val ? val.id : null });
        }}
        disabled={disabled || updating}
      />
    </>
  );
};

export default UploadVesselImage;
