import React, { useCallback, useEffect, useState } from 'react';
import ModuleWrapper from '../components/ModuleWrapper';
import LinkedJob from './LinkedJob';
import AddJobAction from './AddJobAction';
import ListLoader from '../components/ListLoader';

import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { useDrawer } from 'common/components/drawer';
import { setSelectedJob } from 'common/components/jobs/_base/store/actions';
import LinkedJobDrawer from 'common/components/link-entities/jobs/JobDrawer';
import {
  selectJobData,
  selectJobsActionsAreLocked
} from 'events/store/event-modules/jobs/selectors';
import { createJob, getJobs } from 'events/store/event-modules/jobs/actions';

const Jobs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobPreviewState, setJobPreviewState] = useState(null);
  const [isCreatingJob, setIsCreatingJob] = useState(true);

  const jobDrawer = useDrawer(false);
  const dispatch = useDispatch();

  const data = useSelector(selectJobData);
  const eventId = useSelector(selectEventId);
  const isTemplate = useSelector(selectIsTemplate);
  const actionsAreLocked = useSelector(selectJobsActionsAreLocked);

  const addLinkedJob = async job => {
    await dispatch(createJob({ id: eventId, job_id: job.id }));
  };

  const toggleJobDrawer = useCallback(
    (isCreating, job = null) => {
      setJobPreviewState(job);
      dispatch(setSelectedJob(job));
      setIsCreatingJob(isCreating);
      jobDrawer.open();
    },
    [jobDrawer, dispatch]
  );

  const init = useCallback(async () => {
    setIsLoading(true);

    await dispatch(getJobs({ id: eventId }));

    setIsLoading(false);
  }, [eventId, dispatch]);

  useEffect(() => {
    if (eventId) init();
  }, [eventId, init]);

  return (
    <ModuleWrapper
      className="event-jobs"
      type="jobs"
      action={!actionsAreLocked && <AddJobAction toggleJobDrawer={toggleJobDrawer} />}
    >
      {!isTemplate && (
        <>
          {data?.map(linkedJob => (
            <LinkedJob
              key={linkedJob.id}
              linkedJob={linkedJob}
              isViewOnly={actionsAreLocked}
              toggleJobDrawer={toggleJobDrawer}
            />
          ))}
        </>
      )}
      {isLoading ? <ListLoader /> : null}

      <LinkedJobDrawer
        drawer={jobDrawer}
        jobPreviewState={jobPreviewState}
        isCreatingJob={isCreatingJob}
        addLinkedJob={addLinkedJob}
      />
    </ModuleWrapper>
  );
};

export default Jobs;
