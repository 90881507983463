import React from 'react';
import DateInput, { mode } from 'common/components/form/inputs/date';

import { useSelector } from 'react-redux';
import { selectStartDate, selectEndDate, selectPeriodType } from 'crew/store/shifts/selectors';
import { useActions } from 'utils/hooks';

import * as shiftActions from 'crew/store/shifts/actions';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const PeriodDatePicker = ({ isCrewProfile, crew }) => {
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const type = useSelector(selectPeriodType);

  const [setStartDate, setEndDate] = useActions([
    shiftActions.setStartDate,
    shiftActions.setEndDate
  ]);

  const setWeeklyStart = start => {
    setStartDate(start.format(dateFormat));
  };

  const setWeeklyEnd = end => {
    setEndDate(end.format(dateFormat));
  };

  const setDaily = day => {
    setStartDate(day.format(dateFormat));
    setEndDate(day.format(dateFormat));
  };

  const setMonthly = month => {
    const newStartMonthly = month.startOf('month').format(dateFormat);
    const newEndMonthly = month.endOf('month').format(dateFormat);

    setStartDate(newStartMonthly);
    setEndDate(newEndMonthly);
  };

  const monthPickerExtraProps = isCrewProfile
    ? {
        isOutsideRange: date => {
          const fullDate = moment(`${date.format('YYYY')}-${date.format('MM')}`, 'YYYY-MM');

          const start = moment(crew.contract.sign_on_date, 'YYYY-MM-DD');
          const end = crew.contract.sign_off_date
            ? moment(crew.contract.sign_off_date, 'YYYY-MM-DD')
            : moment();
          const isBetweenContract = fullDate.isBetween(start, end, 'month', '[]');

          return !isBetweenContract;
        },
        yearStart: moment(crew.contract.sign_on_date, 'YYYY-MM-DD'),
        yearEnd: moment(crew.contract.sign_off_date, 'YYYY-MM-DD')
      }
    : {};

  return (
    <div className={`shifts-filters__date-picker ms-3`} key={type}>
      {type === 'weekly' ? (
        <DateInput
          from={moment(startDate, dateFormat)}
          to={moment(endDate, dateFormat)}
          onChange={({ from, to }) => {
            setWeeklyStart(from);
            setWeeklyEnd(to);
          }}
          activeMode={mode.range.weekdate}
        />
      ) : type === 'monthly' ? (
        <DateInput
          onChange={setMonthly}
          value={moment(startDate, dateFormat)}
          activeMode={mode.single.month}
          {...monthPickerExtraProps}
        />
      ) : (
        <DateInput onChange={setDaily} value={moment(startDate, dateFormat)} />
      )}
    </div>
  );
};

export default PeriodDatePicker;
