import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import {
  selectCaptainReportVessel,
  selectPreviousCaptainReportValueByKey,
  selectCaptainReportTemplateType
} from 'captain-reports/store/selectors';
import * as reportActions from 'captain-reports/store/actions';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

import { numberToStr } from 'common/utils/numbers';

const SludgeRob = ({ tooltip, field }) => {
  const vessel = useSelector(selectCaptainReportVessel);
  const previousRob = useSelector(state =>
    selectPreviousCaptainReportValueByKey(state, 'sludge_rob')
  );
  const templateType = useSelector(selectCaptainReportTemplateType);
  const [setValue] = useActions([reportActions.setValue]);

  const robTotal = useValue({ key: 'sludge_rob' });
  const isViewOnly = !!previousRob && templateType !== 'departure';

  const robCapacityPercent = (robTotal / vessel.sludge_capacity) * 100;

  useEffect(() => {
    if (previousRob && !robTotal) {
      setValue({ key: field.key, value: previousRob });
    }
  }, [previousRob]);

  return (
    <div>
      <div className={`${isViewOnly ? 'd-none' : ''}`}>
        <NumberField tooltip={tooltip} field={field} />
      </div>

      {isViewOnly ? (
        <div className="d-flex flex-column cmt-4 ">
          <ValidationWrapper
            field={field}
            value={robTotal}
            errorClassName="align-items-center pe-1 cme-2"
          >
            <div className="cms-2">
              <strong className={`fs-12`}>{numberToStr(robTotal)}</strong>
            </div>
          </ValidationWrapper>
        </div>
      ) : null}
      <div className="text-violet fs-10 mt-2">
        out of {vessel.sludge_capacity ? numberToStr(vessel.sludge_capacity) : '-'} m³ -{' '}
        {robCapacityPercent ? robCapacityPercent.toFixed(2) : '0'}%
      </div>
      <div className="mt-1 text-violet fs-10 fw-bold">PREVIOUS ROB: {numberToStr(previousRob)}</div>
    </div>
  );
};

export default SludgeRob;
