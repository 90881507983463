import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';

import { selectVesselProfile } from 'store/vessels/selectors';

import Fade from 'react-reveal/Fade';
import { getValue } from '../../layout/utils';
import EntityLabel from 'common/components/labels/EntityLabel';

const FuelTank = ({ fields }) => {
  const profile = useSelector(selectVesselProfile);

  const fuelTanks = profile.fuel_tanks;

  const fuelTanksFields = fields.find(el => el.key === 'fuel_tanks');

  const formatFuelTankData = {
    ...fuelTanksFields,
    data: [
      ...fuelTanksFields?.data.filter(e => e.label !== 'FUEL CATEGORY'),
      {
        sort_index: 3,
        label: 'FUEL CATEGORY',
        key: 'fuel_category',
        fields: fuelTanksFields?.data.filter(e => e.label === 'FUEL CATEGORY')
      }
    ]
  };

  const fuelCategoryType = tank => {
    let type = '-';

    if (tank?.is_fo) {
      type = 'FO Tank';
    }

    if (tank?.is_go) {
      type = 'GO Tank';
    }

    if (tank?.is_fo && tank?.is_go) {
      type = 'FO Tank & GO Tank';
    }

    return type;
  };

  return (
    <Fade>
      <Row>
        <Col xs={5}>
          {fields.map(field => {
            if (field.condition && !field.condition(profile)) return null;

            if (field.key === 'fuel_tanks') {
              return null;
            }

            return (
              <Row
                key={field.key}
                className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
              >
                <Col
                  className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                  xs={8}
                >
                  {field.label}
                </Col>
                <Col xs={4} className="fs-12  d-flex align-items-start ">
                  {field.render ? field.render(profile) : <span>{getValue(field, profile)}</span>}
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col xs={7}>
          {fuelTanks &&
            fuelTanks.map((tank, index) => {
              return (
                <div key={tank.id} className={`w-100p ${index !== 0 ? 'mt-2' : ''} `}>
                  <Row>
                    <Col
                      className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
                      xs={5}
                    >
                      TANK {+index + 1}
                    </Col>
                    {tank?.has_sounding_table ? (
                      <Col xs={7}>
                        <EntityLabel type="department" color="color_1">
                          Sounding Tables Inserted
                        </EntityLabel>
                      </Col>
                    ) : null}
                  </Row>
                  <div className="position-relative">
                    <div className="col-left-border col-left-border__tanks" />
                    {_sortBy(formatFuelTankData.data, ['sort_index']).map(field => {
                      if (field.condition && !field.condition(profile)) return null;

                      if (field.component) {
                        return <field.component key={field.key} field={field} />;
                      }

                      if (field.key === 'id') {
                        return null;
                      }

                      return (
                        <Row
                          key={field.key}
                          className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
                        >
                          <Col
                            className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                            xs={5}
                          >
                            {field.label}
                          </Col>
                          <Col xs={7} className="fs-12  d-flex align-items-start position-relative">
                            {field.render ? (
                              field.render(profile, index)
                            ) : field.key === 'fuel_category' ? (
                              <span>{fuelCategoryType(tank)}</span>
                            ) : (
                              <span>{getValue(field, tank)}</span>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </Col>
      </Row>
      {/* <Row>
        <Col xs={12}>
          {Object.keys(config).map((el, i) => {
            let item = config[el];

            if (item.customComponent) {
              return <item.customComponent key={i.toString()} fields={item.fields} />;
            }

            return (
              <div key={i.toString()}>
                {item.header ? (
                  <Row className="mt-2 cmb-4">
                    <Col
                      className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
                      xs={4}
                    >
                      {item.header}
                    </Col>
                  </Row>
                ) : null}

                {item.fields.map(field => {
                  if (field.condition && !field.condition(profile)) return null;

                  if (field.component) {
                    return <field.component key={field.key} field={field} />;
                  }

                  return (
                    <Row
                      key={field.key}
                      className={` ${
                        field.className ? field.className : 'cmt-4 cmb-4'
                      }`}
                    >
                      <Col
                        className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                        xs={4}
                      >
                        {field.label}
                      </Col>
                      <Col xs={8} className="fs-12  d-flex align-items-start ">
                        {field.render ? (
                          field.render(profile)
                        ) : (
                          <span>{getValue(field, profile)}</span>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            );
          })}
        </Col>
      </Row> */}
    </Fade>
  );
};

export default FuelTank;
