import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <div className="w-100p height-88">
    <ContentLoader
      speed={1}
      backgroundColor="#F8F9FA"
      foregroundColor="#EEF0F4"
      preserveAspectRatio="none"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
    >
      <rect x="0" y="0" rx="0.5" ry="0.5" width="100" height="100" />
    </ContentLoader>
  </div>
);

export default Loader;
