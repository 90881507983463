import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import attachement from 'common/assets/svg/common/attachement.svg';
import { upload } from 'utils/api';

const UploadFileButton = ({ accept, onChange, group, disabled }) => {
  const [loading, setLoading] = useState(false);
  const refInput = useRef(null);

  const handleSelection = e => {
    e.preventDefault();

    let files = e.target.files;

    if (!files?.length) return;

    let data = new FormData();
    data.append('group', group);

    let uploadUrl = '/files';
    data.append('files[]', files[0], files[0].name);

    setLoading(true);

    upload(uploadUrl, data)
      .then(res => {
        onChange(res.data[0]);
        setLoading(false);
        refInput.current.value = '';
      })
      .catch(error => {
        setLoading(false);
        refInput.current.value = '';
      });
  };

  const handleClick = () => {
    refInput.current.click();
  };

  return (
    <div
      className={`d-inline-flex align-items-center ${
        loading || disabled ? 'disabled' : 'cursor-pointer'
      }`}
      onClick={() => (disabled ? {} : handleClick())}
    >
      <input
        accept={accept}
        type="file"
        onChange={handleSelection}
        ref={refInput}
        className="d-none"
      />
      <SvgRender
        style={{ height: 18, width: 18, transform: 'rotate(-135deg)' }}
        src={attachement}
      />
    </div>
  );
};

UploadFileButton.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default UploadFileButton;
