import React from 'react';
import PropTypes from 'prop-types';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/jobs/_base/components/Status';
import LinkedEntitiesTable from '../_components/LinkedEntitiesTable';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import removeSvg from 'common/assets/svg/actions/remove.svg';
// import DueDate from 'common/components/jobs/_base/components/DueDate';

const LinkedJobs = ({ jobs, removeLinkedJob, previewLinkedJob }) => {
  if (!jobs?.length) return null;

  return (
    <LinkedEntitiesTable
      columns={[
        {
          key: 'id',
          minWidth: 80,
          maxWidth: 80,
          truncate: true,
          header: 'No.',
          headerClassName: 'text-violet'
        },
        {
          key: 'importance_id',
          minWidth: 48,
          maxWidth: 48,
          headerClassName: 'text-violet text-center',
          bodyClassName: 'px-0 text-center',
          header: 'I'
        },
        {
          key: 'title',
          truncate: true,
          headerClassName: 'text-violet',
          bodyClassName: 'pe-0 overflow-hidden',
          header: 'Title'
        },
        { key: 'status', width: 4, headerClassName: 'text-violet', header: 'Status' },
        // {
        //   key: 'due_date',
        //   width: 2,
        //   headerClassName: 'text-violet',
        //   header: 'Due Date',
        //   truncate: true,
        //   type: 'date'
        // },
        { key: 'actions', field: 'actions' }
      ]}
      state={{ data: jobs }}
      rows={{
        importance_id: data => (
          <Priority value={data?.importance_id} withText={false} size="small" />
        ),
        status: data => <Status activeStatus={data?.status} />,
        // due_date: data => (
        //   <DueDate
        //     dueDateChanged={data.last_due_date_change}
        //     className="fs-12"
        //     dueDate={data.due_date}
        //     createdAt={data.created_at}
        //     status={data.status_id}
        //   />
        // ),
        actions: () => ({
          options: [
            {
              label: 'View',
              icon: eyeSvg,
              onClick: (_, data) => previewLinkedJob(data)
            },
            {
              label: 'Remove',
              icon: removeSvg,
              svgStyle: { width: 8, height: 8 },
              onClick: (_, __, index) => removeLinkedJob(index)
            }
          ]
        })
      }}
    />
  );
};

LinkedJobs.propTypes = {
  jobs: PropTypes.array,
  removeLinkedJob: PropTypes.func,
  previewLinkedJob: PropTypes.func
};

export default LinkedJobs;
