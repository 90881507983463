import { CriteriaTypeLabel } from '@/common/types/enums';
import { getGradeValue } from '../../helpers';

import { useAppSelector } from '@/store/hooks';
import {
  selectDefaultScaleLabels,
  selectScaleLabel,
  selectShouldShowDefaultScaleLabel
} from '@/store/lists/selectors-ts';
import { FC } from 'react';

type GradeProps = {
  valueType: CriteriaTypeLabel;
  value: number;
};

const Grade: FC<GradeProps> = ({ valueType, value }) => {
  const grade = getGradeValue(valueType, value);
  const scales = useAppSelector(selectDefaultScaleLabels);
  const shouldShowDefaultScaleLabel = useAppSelector(selectShouldShowDefaultScaleLabel);
  const scale = useAppSelector(state =>
    shouldShowDefaultScaleLabel ? selectScaleLabel(state, grade) : null
  );

  return (
    <div className="d-flex align-items-center">
      {!grade ? (
        <span className="fs-14 lh-16 fw-normal text-gray-common">Not evaluated</span>
      ) : valueType === 'scale' && scales.length ? (
        <div className="d-flex align-items-center">
          {scale ? (
            <div
              className={`me-1 crew-profile__evaluation--results-circle doughnut-graph-circle--${scale?.color}`}
            />
          ) : null}
          <div className="fw-bold fs-14 lh-16 text-primary me-1">{grade}</div>
          {scale ? <div className="fs-14 lh-16 text-primary">{scale?.name}</div> : null}
        </div>
      ) : (
        grade
      )}
    </div>
  );
};

export default Grade;
