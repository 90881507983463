import PropTypes from 'prop-types';
import Priority from 'common/components/general/Priority';
import Status from 'common/components/purchasing/requisition/components/Status';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import removeSvg from 'common/assets/svg/actions/remove.svg';
import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import LinkedEntitiesTable from '../_components/LinkedEntitiesTable';

const LinkedPurchaseCases = ({ linkedPurchasingCases, removeLinkedPurchasingCase }) => {
  if (!linkedPurchasingCases?.length) return null;

  return (
    <LinkedEntitiesTable
      columns={[
        {
          key: 'priority',
          minWidth: 56,
          maxWidth: 56,
          headerClassName: 'text-violet text-center',
          bodyClassName: 'px-0 text-center',
          header: 'I'
        },
        {
          key: 'code',
          minWidth: 124,
          maxWidth: 124,
          truncate: true,
          header: 'No.',
          headerClassName: 'text-violet'
        },
        {
          key: 'description',
          truncate: true,
          header: 'DESCRIPTION',
          headerClassName: 'text-violet',
          bodyClassName: 'pe-0 overflow-hidden'
        },
        { key: 'status', width: 2, headerClassName: 'text-violet', header: 'Status' },
        {
          key: 'submitted_at',
          width: 2,
          headerClassName: 'text-violet',
          header: 'SUBMITTED AT',
          truncate: true,
          type: 'date'
        },
        { key: 'actions', field: 'actions' }
      ]}
      state={{ data: linkedPurchasingCases }}
      rows={{
        priority: data => <Priority value={data.priority?.label} />,
        status: data =>
          data?.status ? <Status status={data?.status} isBullet hasTooltip /> : null,
        actions: () => ({
          options: [
            {
              label: 'View',
              icon: eyeSvg,
              permissions: [permissions.office.purchasing.requisitions.edit],
              onClick: (_, data) =>
                window.open(`${paths.purchasing_requisitions}/${data.id}`, '_blank')
            },
            {
              label: 'Remove',
              icon: removeSvg,
              svgStyle: { width: 8, height: 8 },
              onClick: (_, __, index) => removeLinkedPurchasingCase(index)
            }
          ]
        })
      }}
    />
  );
};

LinkedPurchaseCases.propTypes = {
  linkedPurchasingCases: PropTypes.array,
  removeLinkedPurchasingCase: PropTypes.func
};

export default LinkedPurchaseCases;
