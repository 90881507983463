import React from 'react';

import TicketTypeIcon from './TicketTypeIcon';

const TicketTypeLine = ({ type = 'air' }) => {
  return (
    <div className="ticket-type-line text-primary">
      <div></div>
      <div>
        <TicketTypeIcon type={type} />
      </div>
      <div></div>
    </div>
  );
};

export default TicketTypeLine;
