import React from 'react';
import Input from 'common/components/form/inputs/Input';
import NumberInput from 'common/components/form/inputs/NumberInput';

import UploadFiles from 'common/components/form/inputs/upload-files';
import PropTypes from 'prop-types';

const VesselSystemAttributeView = ({ formField }) => {
  const renderFieldByType = label => {
    switch (label) {
      case 'number':
        return (
          <NumberInput
            value={formField.form_field_params.value}
            className={'fs-12 text-primary"'}
            viewOnly
          />
        );

      case 'text':
        return (
          <Input
            value={formField.form_field_params.value}
            className={'fs-12 text-primary"'}
            viewOnly
          />
        );

      case 'attachment':
      case 'image':
        return formField?.form_field_params?.file ? (
          <UploadFiles
            group="forms.submissions"
            files={[formField.form_field_params.file]}
            size="sm"
            viewOnly
          />
        ) : (
          '-'
        );

      default:
        return;
    }
  };

  return formField?.form_field_params?.vessel_system_attribute?.type
    ? renderFieldByType(formField.form_field_params.vessel_system_attribute.type)
    : '-';
};

VesselSystemAttributeView.propTypes = {
  formField: PropTypes.shape({
    form_field_params: PropTypes.shape({
      vessel_system_attribute: PropTypes.shape({ id: PropTypes.number, type: PropTypes.string })
    })
  })
};

export default VesselSystemAttributeView;
