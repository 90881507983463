import TYPES from './types';
import { get, put } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

export const ignoreEvaluationObligation = params => dispatch => {
  dispatch({ type: TYPES.IGNORE_EVALUATION_OBLIGATION.START, payload: { params } });

  const { id, ...rest } = params;

  return put(`/crew-evaluation-obligations/${id}/ignore`, rest)
    .then(res => {
      dispatch({
        type: TYPES.IGNORE_EVALUATION_OBLIGATION.SUCCESS,
        payload: res.data
      });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Crew evaluation obligation ignored successfully'
        })
      );

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.IGNORE_EVALUATION_OBLIGATION.ERROR, payload: error });

      throw error;
    });
};

export const unignoreEvaluationObligation = id => dispatch => {
  dispatch({ type: TYPES.UNIGNORE_EVALUATION_OBLIGATION.START });

  return put(`/crew-evaluation-obligations/${id}/unignore`)
    .then(res => {
      dispatch({
        type: TYPES.UNIGNORE_EVALUATION_OBLIGATION.SUCCESS,
        payload: res.data
      });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Crew evaluation obligation unignored successfully'
        })
      );

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UNIGNORE_EVALUATION_OBLIGATION.ERROR, payload: error });

      throw error;
    });
};

export const getCrewEvaluationObligations = id => async dispatch => {
  try {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_OBLIGATIONS.START, payload: { id } });

    const response = await get(`/crew/${id}/evaluation-mixed-reasons-obligations`);

    dispatch({ type: TYPES.GET_CREW_EVALUATION_OBLIGATIONS.SUCCESS, payload: response.data });

    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_OBLIGATIONS.ERROR, payload: error });

    throw error;
  }
};
