import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <ContentLoader
    speed={1}
    backgroundColor="#eef0f4"
    foregroundColor="#ebebeb"
    viewBox="0 0 400 50"
    height="100%"
    width="auto"
    preserveAspectRatio="xMidYMid meet"
  >
    <rect x="0" y="0" rx="1px" ry="1px" width="100%" height="20%" />
    <rect x="0" y="25%" rx="1px" ry="1px" width="100%" height="20%" />
    <rect x="0" y="50%" rx="1px" ry="1px" width="100%" height="40%" />
  </ContentLoader>
);

export default Loader;
