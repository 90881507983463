import React from 'react';

import { selectCrewProfile } from 'crew/store/profile/selectors';
import { useSelector } from 'react-redux';
import { formatDate } from 'common/utils/dates';

const ProfileLog = ({ label, datetime, user, className = '' }) => {
  return datetime || user ? (
    <div className={`fs-10 text-gray-400 text-center ${className}`}>
      {label}{' '}
      {user ? (
        <span>
          by {user?.full_name}
          <br />
        </span>
      ) : null}
      {datetime ? <span>at {formatDate(datetime)}</span> : null}
    </div>
  ) : null;
};

const Logs = ({ isOnboard }) => {
  const crew = useSelector(selectCrewProfile);

  return !isOnboard ? (
    <div>
      <ProfileLog label="Created" user={crew?.created_by} datetime={crew?.created_at} />
      {crew?.created_by !== crew?.updated_by ? (
        <ProfileLog
          label="Updated"
          user={crew?.updated_by}
          datetime={crew?.updated_at}
          className="mt-2"
        />
      ) : null}
    </div>
  ) : null;
};

export default Logs;
