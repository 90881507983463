import { useSelector } from 'react-redux';

import useRouter from 'use-react-router';
import { useActions } from 'utils/hooks';

import paths from 'routing/routes/_paths';
import list from 'common/assets/svg/jobs/list_view.svg';
import pmsIcon from 'common/assets/svg/common/setup.svg';
import system from 'common/assets/svg/common/system.svg';
import clock from 'common/assets/svg/common/clock-line.svg';

import { selectJobDrawerIsOpen } from 'common/components/pms/jobs/store/selectors';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';
import { stringifyObj, parseQueryParams } from 'utils/urls';
import { jobEnums } from 'common/utils/fixed';
import { useAppSelector } from '@/store/hooks';
import { selectPmsAssignSystemAndSubsystemToGroup } from '@/store/settings/selectors';
import useTableTopFilter from 'common/components/filters/useTableTopFilter';
import FilterButton from 'common/components/buttons/FilterButton';
import SvgRender from 'common/components/general/SvgRender';

const NavTabs = () => {
  const { location, history } = useRouter();
  const [toggleJobDrawer] = useActions([pmsJobsActions.toggleJobDrawer]);
  const jobDrawerIsOpen = useSelector(selectJobDrawerIsOpen);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const groupsIsVisibleInSystem = useAppSelector(selectPmsAssignSystemAndSubsystemToGroup);

  const { pms, unscheduled } = jobEnums;

  const closeDrawer = () => {
    if (jobDrawerIsOpen) {
      toggleJobDrawer(false, null);
    }
  };

  const onNavigate = pathname => {
    history.replace({ pathname });
  };

  const scheduledTabEnabled = useTableTopFilter({ name: 'type' }, pms);
  const unscheduledTabEnabled = useTableTopFilter({ name: 'type' }, unscheduled);
  const vesselFilterEnabled = useTableTopFilter({ name: 'vessel_id' });

  const applyTableFilter = (value, shouldRemoveFilter) => {
    const { filters, ...rest } = parseQueryParams(history.location.search);

    const filteredFilters = shouldRemoveFilter ? filters.filter(f => f.name !== 'type') : filters;

    const modifiedFilters = filteredFilters?.length
      ? [
          ...filteredFilters,
          {
            name: 'type',
            operation: 'oneOf',
            value: value
          }
        ]
      : [
          {
            name: 'type',
            operation: 'oneOf',
            value: value
          }
        ];

    history.replace({
      pathname: location.pathname,
      search: `${stringifyObj({ filters: modifiedFilters, ...rest })}`
    });
  };

  const addSubTabFilter = action => {
    if (scheduledTabEnabled || unscheduledTabEnabled) {
      applyTableFilter([pms, unscheduled], true);
    } else {
      applyTableFilter([`${action}`], false);
    }
  };

  const removeSubTabFilter = button => {
    const { filters, ...rest } = parseQueryParams(history.location.search);

    if (button === unscheduled && scheduledTabEnabled) {
      applyTableFilter([pms], true);
    } else if (button === pms && unscheduledTabEnabled) {
      applyTableFilter([unscheduled], true);
    } else {
      history.replace({
        pathname: location.pathname,
        search: `${stringifyObj({
          filters: filters.filter(f => f.name !== 'type'),
          ...rest
        })}`
      });
    }
  };

  const filtersDisabled =
    !isOnBoard && !vesselFilterEnabled && location.pathname.includes(paths.pms_jobs_per_system);

  return (
    <div className="d-flex">
      <div onClick={closeDrawer} className="pe-3 border-end">
        <NavigationGroupTabs
          className={`${jobDrawerIsOpen ? 'pointer-events-none' : ''}`}
          tabs={[
            {
              label: 'split-view-button',
              tooltip: groupsIsVisibleInSystem ? 'Per System' : 'Per Group',
              icon: system,
              isActive: location.pathname.includes(paths.pms_jobs_per_system),
              onClick: () => {
                closeDrawer();

                if (!location.pathname.includes(paths.pms_jobs_per_system))
                  onNavigate(paths.pms_jobs_per_system);
              }
            },
            {
              label: 'list-view-button',
              tooltip: 'List View',
              icon: list,
              isActive: location.pathname.includes(paths.pms_jobs),
              onClick: () => {
                closeDrawer();

                if (!location.pathname.includes(paths.pms_jobs)) onNavigate(paths.pms_jobs);
              }
            }
          ]}
        />
      </div>

      <div className={`${!isOnBoard ? 'ps-3' : 'px-3'} d-flex`}>
        <FilterButton
          isActive={scheduledTabEnabled ? true : false}
          onClick={() => {
            if (scheduledTabEnabled) {
              removeSubTabFilter(pms);
            } else {
              addSubTabFilter(pms);
            }
          }}
          className="me-1"
          size="lg"
          status="default"
          disabled={filtersDisabled}
          tooltip={'Planned Jobs filter'}
        >
          <SvgRender src={pmsIcon} style={{ width: 16, height: 16 }} />
        </FilterButton>

        <FilterButton
          isActive={unscheduledTabEnabled ? true : false}
          onClick={() => {
            if (unscheduledTabEnabled) {
              removeSubTabFilter(unscheduled);
            } else {
              addSubTabFilter(unscheduled);
            }
          }}
          size="lg"
          status="default"
          disabled={filtersDisabled}
          tooltip={'Unplanned Maintenance Jobs filter'}
        >
          <SvgRender src={clock} style={{ width: 16, height: 16 }} />
        </FilterButton>
      </div>
    </div>
  );
};

export default NavTabs;
