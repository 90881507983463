import React, { useEffect } from 'react';

import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectPreviousCaptainReportLocalTimestamp } from 'captain-reports/store/selectors';

import useValue from 'captain-reports/utils/useValue';

import * as reportActions from 'captain-reports/store/actions';
import moment from 'moment';

const LocalTimestampField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue] = useActions([reportActions.setValue]);

  const lastReportLocalTimestamp = useSelector(selectPreviousCaptainReportLocalTimestamp);

  const value = useValue({ key: field.key });

  useEffect(() => {
    /*
      When in Noon report and Port Noon report, auto fill the date & time field:
      Get the previous (basic type) report datetime. Find the next date with time 12:00.
      Examples:
      - Previous: 02/11/2021 10:00 => Current auto filled value: 02/11/2021 12:00
      - Previous: 02/11/2021 14:00 => Current auto filled value: 03/11/2021 12:00"
    */

    if (lastReportLocalTimestamp && !value) {
      const last_report_timestamp_at_noon = `${moment(lastReportLocalTimestamp).format(
        'YYYY-MM-DD'
      )} 12:00:00`;
      const diff = moment(last_report_timestamp_at_noon).diff(lastReportLocalTimestamp, 'hours');

      if (diff < 0) {
        // When last_report_timestamp is after last_report_timestamp_at_noon, go to next day
        setValue({
          key: field.key,
          value: moment(lastReportLocalTimestamp)
            .add(1, 'days')
            .hour(12)
            .minute(0)
            .format('YYYY-MM-DD HH:mm'),
          sectionLabel,
          sectionActiveTab,
          subGroup,
          subGroupIndex
        });
      } else {
        setValue({
          key: field.key,
          value: moment(lastReportLocalTimestamp).hour(12).minute(0).format('YYYY-MM-DD HH:mm'),
          sectionLabel,
          sectionActiveTab,
          subGroup,
          subGroupIndex
        });
      }
    }
  }, []);

  return (
    <DateTimeInput
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default LocalTimestampField;
