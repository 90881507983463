import React from 'react';

import EntityLabel from 'common/components/labels/EntityLabel';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

import PropTypes from 'prop-types';

const Vessels = ({ className = '', vessels = [], visibleCount = 3, color }) => {
  const visibleVessels = vessels.slice(0, visibleCount);
  const hiddenVessels = vessels.slice(visibleCount);
  const { avoidRender, tooltipID } = useTooltipID('vessels');

  if (avoidRender) return null;

  return (
    <div className={`${className}`}>
      <div className="text-violet cmb-4">Vessels</div>
      {visibleVessels.map(vessel => (
        <EntityLabel
          type="department"
          color={color}
          className="max-width-100p border-0 border-radius-3"
        >
          <TextWithTooltip className="fs-10 fw-bold">{vessel.name}</TextWithTooltip>
        </EntityLabel>
      ))}
      {hiddenVessels?.length > 0 ? (
        <>
          <EntityLabel type="department" color={color} className="border-0 me-0 border-radius-3">
            <div className="fs-10 fw-bold w-100p cursor-pointer" id={tooltipID}>
              +{hiddenVessels.length}
            </div>
          </EntityLabel>
          <Tooltip target={tooltipID}>
            {hiddenVessels.reduce(
              (str, vessel, index) =>
                str + vessel.name + (index < hiddenVessels.length - 1 ? ', ' : ''),
              ''
            )}
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

Vessels.propTypes = {
  className: PropTypes.string,
  vessels: PropTypes.array,
  visibleCount: PropTypes.number,
  color: PropTypes.string
};

export default Vessels;
