import { strToNumber } from 'common/utils/numbers';
import moment from 'moment';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

export const prefix = {
  template: 'custom_template_id_',
  label: 'template_label_'
};

export const parseLabelBeforeSave = label => {
  const obj = { label_id: label.label_id };

  if (label.type === 'text') {
    obj.value = label.value;
  } else if (label.type === 'number') {
    obj.value = strToNumber(label.value);
  } else if (label.type === 'dropdown') {
    obj.multiple = label.multiple.map(m => ({ dropdown_id: m }));
  } else if (label.type === 'date-range') {
    obj.from = null;
    obj.to = null;

    if (label.dates.from && label.dates.to) {
      obj.from = label.dates.from;
      obj.to = label.dates.to;
    }
  } else if (label.type === 'date') {
    obj.value = label.value ? label.value.format('YYYY-MM-DD') : null;
  } else {
    obj.multiple = label.multiple.map(m => ({ relation_id: m.id }));
  }

  if (label.relation_specific_id) {
    obj.relation_specific_id = label.relation_specific_id;
  }

  return obj;
};

export const getTemplateLabelsParams = (template, labels, isFleet) => {
  const params = { template_id: null, labels: [] };

  let incomingLabels = [];

  if (isFleet) {
    const fleetLabelKeys = Object.keys(labels);
    fleetLabelKeys.forEach(el => {
      const vesselId = +el.split('_')[1];
      labels[el].forEach(lab => {
        incomingLabels.push({ ...lab, relation_specific_id: vesselId });
      });
    });
  } else {
    incomingLabels = labels && labels?.length ? [...labels] : [];
  }

  if (template && template.id) {
    params.template_id = template.id;
  }

  if (incomingLabels && incomingLabels.length) {
    params.labels = incomingLabels.map(label => parseLabelBeforeSave(label));

    params.labels = params.labels.filter(
      l => l.value || (l.multiple && l.multiple.length) || (l.from && l.to)
    );
  }

  return params;
};

export const getLabelValue = (label, jobSavedLabels) => {
  const labelKey = `${prefix.label}${label.id}`;

  if (label.type === 'date') {
    if (jobSavedLabels && jobSavedLabels[labelKey] && jobSavedLabels[labelKey].value) {
      return moment(jobSavedLabels[labelKey].value);
    }
  }

  return _get(jobSavedLabels, `${labelKey}.value`, null);
};

export const getJobTemplateFleetExtraValues = (oldValues, template, jobSavedLabels, vessels) => {
  let result = { ...oldValues };

  let resultKeys = Object.keys(result);

  const vesselIds = [];

  vessels.forEach(vessel => {
    let name = `vessels_${vessel.id}`;

    vesselIds.push(vessel.id);

    if (!result[name]) {
      result[name] = getJobTemplateInitialValues(
        template.labels,
        jobSavedLabels[name] ? jobSavedLabels[name] : {}
      );
    }
  });

  resultKeys.forEach(el => {
    let id = el.split('_')[1];
    if (!vesselIds.includes(+id)) {
      delete result[el];
    }
  });

  return result;
};

export const getJobTemplateFleetInitialValues = (template, jobSavedLabels, vessels) => {
  let result = {};
  vessels.forEach(vessel => {
    let name = `vessels_${vessel.id}`;

    result[name] = getJobTemplateInitialValues(
      template.labels,
      jobSavedLabels[name] ? jobSavedLabels[name] : {}
    );
  });

  return result;
};

export const getJobTemplateInitialValues = (templateLabels, jobSavedLabels) => {
  const result = templateLabels.map(l => {
    const labelKey = `${prefix.label}${l.id}`;

    return {
      label_id: l.id,
      label_name: l.name,
      type: l.type,
      value: getLabelValue(l, jobSavedLabels),
      is_relation: l.is_relation,
      multiple:
        l.is_multiple && _isArray(jobSavedLabels[labelKey])
          ? jobSavedLabels[labelKey].map(d =>
              d.relation_id
                ? { id: d.relation_id, name: _get(d, 'payload.value', '') }
                : d.dropdown_id
            )
          : [],
      dropdown_values: l.dropdown_values,
      dates: {
        from:
          l.type === 'date-range' &&
          jobSavedLabels &&
          jobSavedLabels[labelKey] &&
          jobSavedLabels[labelKey].from
            ? jobSavedLabels[labelKey].from
            : null,
        to:
          l.type === 'date-range' &&
          jobSavedLabels &&
          jobSavedLabels[labelKey] &&
          jobSavedLabels[labelKey].to
            ? jobSavedLabels[labelKey].to
            : null
      }
    };
  });

  return result;
};

export const getLabelEnumerationGroup = (labelType, relationTypes) => {
  for (let [key, value] of Object.entries(relationTypes)) {
    if (labelType === key) {
      return key;
    } else if (value && value.length && value.find(v => v.type === labelType)) {
      return key;
    }
  }
};
