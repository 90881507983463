import { get } from '@/api';
import {
  OptionsRequestParams,
  UseQueryOptionsRequestParams
} from '@/ts-common/components/form/inputs/select/types';

type PaginatedOptionsType<T> = {
  meta: { paging: { current_page: number } };
  data: T[];
};

function isPaginatedResponse<T>(res: any): res is PaginatedOptionsType<T> {
  return res && Array.isArray(res.data);
}

export const getDefaultOptions = <T = unknown>(options?: OptionsRequestParams) => {
  if (!options) return Promise.resolve([]);

  const { path, params } = options;

  const requestParams = { ...(params || {}) };

  return get<T[] | PaginatedOptionsType<T>>(path, requestParams).then(response => {
    return isPaginatedResponse<T>(response.data) ? response.data.data : response.data;
  });
};

export const loadAsyncOptions = async <T = unknown>(
  options?: UseQueryOptionsRequestParams<T>,
  search?: string
) => {
  if (!options || !search?.length) return Promise.resolve([]);

  const { path, params } = options;

  const requestParams = { ...(params || {}), search: search || undefined };

  const response = await get<T[] | PaginatedOptionsType<T>>(path, requestParams);

  return isPaginatedResponse<T>(response.data) ? response.data.data : response.data;
};
