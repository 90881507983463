import { FC, PropsWithChildren } from 'react';

type FormBodyProps = PropsWithChildren<{ isDirty?: boolean; className?: string }>;

const FormBody: FC<FormBodyProps> = ({ children, isDirty, className }) => (
  <div className={`form-body gray-scrollbar${isDirty ? ' form-is-dirty' : ''} ${className || ''}`}>
    {children}
  </div>
);

export default FormBody;
