import useValue from './useValue';
import { useSelector } from 'react-redux';
import { selectPreviousCaptainReport } from 'captain-reports/store/selectors';
import _get from 'lodash/get';

const useAutocompleteValues = (values = []) => {
  const previousReportData = useSelector(selectPreviousCaptainReport);
  /*
  useAutocomplete accepts the autocompleteValues from the field and returns them
  the useValue hook is used so you can pass everything useValue accepts in each element 
  of autocompleteValues to get the value of very nested keys

  e.g {
      key: field.key,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
  }

  use {
    previousReportKey:''
  }

  to get a value from  totals
*/

  let first = useValue(values[0] ? values[0] : {});
  let second = useValue(values[1] ? values[1] : {});
  let third = useValue(values[2] ? values[2] : {});
  let fourth = useValue(values[3] ? values[3] : {});
  let fifth = useValue(values[4] ? values[4] : {});
  let sixth = useValue(values[5] ? values[5] : {});
  let seventh = useValue(values[6] ? values[6] : {});

  let firstValue =
    values[0] && values[0].previousReportKey
      ? _get(previousReportData, `${values[0].previousReportKey}.value`, null)
      : first;
  let secondValue =
    values[1] && values[1].previousReportKey
      ? _get(previousReportData, `${values[1].previousReportKey}.value`, null)
      : second;
  let thirdValue =
    values[2] && values[2].previousReportKey
      ? _get(previousReportData, `${values[2].previousReportKey}.value`, null)
      : third;
  let fourthValue =
    values[3] && values[3].previousReportKey
      ? _get(previousReportData, `${values[3].previousReportKey}.value`, null)
      : fourth;
  let fifthValue =
    values[4] && values[4].previousReportKey
      ? _get(previousReportData, `${values[4].previousReportKey}.value`, null)
      : fifth;
  let sixthValue =
    values[5] && values[5].previousReportKey
      ? _get(previousReportData, `${values[5].previousReportKey}.value`, null)
      : sixth;
  let seventhValue =
    values[6] && values[6].previousReportKey
      ? _get(previousReportData, `${values[6].previousReportKey}.value`, null)
      : seventh;

  return [firstValue, secondValue, thirdValue, fourthValue, fifthValue, sixthValue, seventhValue];
};

export default useAutocompleteValues;
