import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CircledButton from 'common/components/buttons/CircledButton';
import { selectEventId, selectEventVessel } from 'events/store/events/selectors';
import { Col, Row } from 'reactstrap';
import { addEventLubOilAnalysisAssignment } from 'events/store/event-modules/lub-oil-analysis/actions';
import { selectTableListData } from 'common/components/table/store/selectors';
import AsyncSelector from 'common/components/selectors/AsyncSelector';

const AddSystem = ({ tableLabel, refetchData }) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const eventVessel = useSelector(selectEventVessel);
  const eventId = useSelector(selectEventId);
  const dispatch = useDispatch();
  const data = useSelector(state => selectTableListData(state, tableLabel));

  const getSelectedItems = useCallback(
    data => data.map(({ vessel_system_assignment_id }) => vessel_system_assignment_id),
    []
  );

  if (isOnboard) return null;

  const onAdd = async selected => {
    setSelected(selected);

    try {
      setIsLoading(true);

      const params = { event_id: eventId, vessel_system_assignment_id: selected?.id };

      await dispatch(addEventLubOilAnalysisAssignment(params)).unwrap();

      refetchData();
      setIsLoading(false);
      setSelected(null);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setSelected(null);
    }
  };

  return (
    <div>
      {isAdding ? (
        <Row className="align-itesm-center cmt-4" noGutters>
          <Col xs={6}>
            <div className="rounded-lg bg-white cps-4 cpe-4 me-1">
              <AsyncSelector
                className="mb-0"
                placeholder="Select System"
                type="vessel-system-assignments"
                getOptionValue={option => option?.id}
                getOptionLabel={option => option?.description}
                isAsync
                listParams={{
                  vessel_id: eventVessel?.id,
                  used_for_lub_oil_analysis: true
                }}
                defaultOptionsTriggerChange={eventVessel?.id}
                onChange={onAdd}
                isClearable={false}
                disabled={isLoading}
                value={selected}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue.length ? `Search for systems` : `No system found`
                }
                filterOption={({ value }) => !getSelectedItems(data).includes(value)}
              />
            </div>
          </Col>
          <Col xs="auto">
            <CircledButton
              type="close"
              onClick={() => setIsAdding(false)}
              svgStyle={{ width: 10, height: 10 }}
              style={{ width: 20, height: 20 }}
            />
          </Col>
        </Row>
      ) : null}

      <CircledButton
        className="mt-2"
        type="add"
        label="Add system"
        onClick={() => setIsAdding(true)}
        disabled={isAdding || isLoading}
        style={{ width: 20, height: 20 }}
      />
    </div>
  );
};

AddSystem.propTypes = {
  tableLabel: PropTypes.string.isRequired,
  refetchData: PropTypes.func.isRequired
};

export default AddSystem;
