import React from 'react';
import { bool, func, string } from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import Switch from 'common/components/form/inputs/Switch';
import drydock_disabled from 'common/assets/svg/jobs/drydock_disabled.svg';
import drydock_enabled from 'common/assets/svg/jobs/drydock_enabled.svg';
import { useSelector } from 'react-redux';
import { selectIsJobLocked } from '../store/selectors';

const DrydockSwitch = ({ isDrydock, onChange, className = '' }) => {
  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));

  return (
    <div
      data-testid="drydock-switch"
      className={`d-flex align-items-center ${
        isDrydock ? 'bg-lighter-blue text-primary' : 'bg-light-gray-2 text-violet'
      } cpx-6 cpy-6 border-radius-3 ${className}`}
    >
      <SvgRender
        src={isDrydock ? drydock_enabled : drydock_disabled}
        style={{ width: 12, height: 12 }}
        className="text-violet cme-4"
      />
      <div className="fs-10 cme-4">Drydock</div>
      <Switch
        value={isDrydock}
        className="mb-0"
        onChange={onChange}
        switchClassName="blue-switch"
        size={'xs'}
        disabled={isJobLocked}
      />
    </div>
  );
};

DrydockSwitch.propTypes = {
  isDrydock: bool.isRequired,
  onChange: func.isRequired,
  className: string
};

export default DrydockSwitch;
