import React from 'react';
import DateInput from 'common/components/form/inputs/date';

const DateTimePicker = ({ label, className, containerClassName, hasTime, ...rest }) => {
  return (
    <div
      className={`port-statement-datepicker ${containerClassName ? containerClassName : 'mb-2'}`}
    >
      <div className={`d-flex align-items-center position-relative`}>
        <DateInput
          label={label}
          className={`port-statement-datepicker__picker ${className ? className : ''}`}
          hasTime={hasTime}
          showTime={hasTime}
          {...rest}
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
