import React from 'react';

import { Col } from 'reactstrap';

const TemplateField = ({ children, className = '', width = { xs: 3 } }) => {
  return (
    <Col className={`template-field position-relative ${className}`} {...width}>
      {children}
    </Col>
  );
};

export default TemplateField;
