import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import WhiteShadowButton from 'common/components/buttons/WhiteShadowButton';
import AuthCheck from 'components/permissions/AuthCheck';

const EmptyState = ({
  onEmptyButtonCLick,
  emptyText,
  emptyButtonText,
  icon,
  showAddButton = true,
  style = { width: 340, height: 340 },
  permissions = null
}) => {
  return (
    <div className="empty-state d-flex align-items-center h-100p">
      <div className="empty-state__container position-relative">
        <SvgRender className="empty-state__icon" style={style} src={icon} />
        <div className="empty-state__text text-primary">{emptyText}</div>
        {onEmptyButtonCLick && showAddButton ? (
          <AuthCheck permissions={permissions}>
            <WhiteShadowButton
              onClick={onEmptyButtonCLick}
              type={'add'}
              text={emptyButtonText}
              className="add-button fs-16"
              svgClassName="bg-yellow ms-3 text-primary add-button__circle "
            />
          </AuthCheck>
        ) : null}
      </div>
    </div>
  );
};

export default EmptyState;
