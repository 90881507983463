import React from 'react';
import Select from 'common/components/form/inputs/Select';

const LoadingSituationSelector = ({ filter, onChange, isMulti }) => {
  return (
    <Select
      className={`mb-0`}
      placeholder="Select value"
      isMulti={isMulti}
      value={filter.value}
      options={[
        { value: 'ballast', label: 'Ballast' },
        { value: 'laden', label: 'Laden' }
      ]}
      onChange={selected => {
        onChange({
          value: selected
        });
      }}
    />
  );
};

export default LoadingSituationSelector;
