import React from 'react';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';
import { Row, Col } from 'reactstrap';
import { getValue } from '../../layout/utils';

const Generators = ({ field }) => {
  const profile = useSelector(selectVesselProfile);

  const generators = profile.generators ? profile.generators : [];
  const data = field.data;

  return (
    <>
      {generators.map((item, index) => {
        return (
          <Row className="mt-2" key={index.toString()}>
            <Col xs={6}>
              <div key={item.id} className="w-100p mt-2">
                <Row>
                  <Col
                    className="text-end fs-12  fw-bold d-flex align-items-start justify-content-end"
                    xs={8}
                  >
                    GENERATOR {+index + 1}
                  </Col>
                </Row>
                <div className="position-relative">
                  <div className="col-left-border col-left-border__holds" />
                  {data.map((el, i) => {
                    if (el.render) {
                      return el.render(item, el);
                    }
                    return (
                      <Row
                        key={el.key}
                        className={` ${el.className ? el.className : 'cmt-4 cmb-4'}`}
                      >
                        <Col
                          className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                          xs={8}
                        >
                          {el.label}
                        </Col>
                        <Col xs={4} className="fs-12  d-flex align-items-start position-relative">
                          {el.key === 'diesel_engine_cylinders_kw' ? (
                            <div className="d-flex align-items-center">
                              {getValue(
                                { key: 'diesel_engine_cylinders_kw', type: 'number' },
                                item
                              )}

                              <div className="mx-2">@</div>
                              {getValue(
                                { key: 'diesel_engine_capacity_at_rpm', type: 'number' },
                                item
                              )}
                            </div>
                          ) : el.key === 'alternator_capacity_kw' ? (
                            <div className="d-flex align-items-center">
                              {getValue({ key: 'alternator_capacity_kw', type: 'number' }, item)}

                              <div className="mx-1">@</div>
                              {getValue(
                                { key: 'alternator_capacity_at_rpm', type: 'number' },
                                item
                              )}
                            </div>
                          ) : (
                            <span>{getValue(el, item)}</span>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default Generators;
