import { FC, PropsWithChildren, ReactNode } from 'react';
import CollapseBox from 'common/components/collapse/CollapseBox';

import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import Information from 'common/components/general/Information';
import SvgRender from '@/ts-common/components/general/SvgRender';
import { useAppSelector } from '@/store/hooks';

type SectionHeader = {
  icon?: string;
  label?: ReactNode;
  subTitle?: ReactNode;
  info?: ReactNode;
};

type SectionHeaderProps = {
  header: SectionHeader;
  className?;
};

export const SectionHeader: FC<SectionHeaderProps> = ({ header, className }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  return header ? (
    <div
      className={`crew-profile__contracts-section-header text-purple d-flex align-items-center fs-12 fw-medium ${
        className || ''
      }`}
    >
      {header.icon ? (
        <SvgRender className="me-1" src={header.icon} style={{ width: 14, height: 14 }} />
      ) : null}
      {header.label}
      {header?.info ? (
        <Information
          className={'text-moody-blue ms-1'}
          svgStyle={{ height: 11, width: 11 }}
          message={header.info}
          tooltipClassname="max-width-344"
        />
      ) : null}

      {!isOnBoard && header?.subTitle ? (
        <div className="crew-profile__contracts-section-header__subtitle d-flex align-items-center fw-medium text-secondary-gray">
          <span className="cms-12 me-1">|</span> {header.subTitle}
        </div>
      ) : null}
    </div>
  ) : null;
};

type SectionProps = PropsWithChildren<{
  header: SectionHeader;
  className?: string;
  isCollapse?: boolean;
  isDefaultOpened?: boolean;
}>;

const Section: FC<SectionProps> = ({
  header,
  children,
  className,
  isCollapse,
  isDefaultOpened = true
}) => (
  <div
    className={`crew-profile__contracts-section ${className || ''} ${
      isCollapse ? 'collapse-section' : ''
    }`}
  >
    {isCollapse ? (
      <CollapseBox
        hideExpandButton={true}
        reversedExpandBtn={true}
        header={<SectionHeader header={header} className="m-0" />}
        className="ps-0"
        isDefaultOpened={isDefaultOpened}
        customExpandComponent={isOpen => (
          <div className="d-flex align-items-center">
            <span>{isOpen ? 'Shrink' : 'Expand'}</span>
            <SvgRender
              className="cms-12"
              src={arrow}
              style={{
                width: 12,
                height: 12,
                transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)'
              }}
            />
          </div>
        )}
      >
        {children}
      </CollapseBox>
    ) : (
      <>
        <SectionHeader header={header} />
        <div className="crew-profile__contracts-section-body flex-1">{children}</div>
      </>
    )}
  </div>
);

export default Section;
