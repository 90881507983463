import React from 'react';
import EntityLabel from 'common/components/labels/EntityLabel';
import _get from 'lodash/get';

const Department = props => {
  const { properties } = props;

  const name = _get(properties, 'object.name', 'None');
  const color = _get(properties, 'object.color', 'None');

  return (
    <div className="d-flex align-items-center">
      <EntityLabel data-testid="job-activity-department" type="department" color={color}>
        {name}
      </EntityLabel>
    </div>
  );
};

export default Department;
