import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_LATEST_WORKING_SCHEDULE'),
  ...asyncTypes('GET_OLD_WORKING_SCHEDULE'),
  ...asyncTypes('UPDATE_CREW_WORKING_SCHEDULE'),
  ...asyncTypes('REMOVE_SEAMAN_FROM_WORKING_SCHEDULE'),
  ...asyncTypes('PUBLISH_WORKING_SCHEDULE'),
  ...asyncTypes('DOWNLOAD_LATEST_PDF'),
  ...asyncTypes('DOWNLOAD_OLD_PDF')
};

export default TYPES;
