import PropTypes from 'prop-types';

const ColorViewer = ({ color, hideLabel = false, className }) => {
  return (
    <div className={`d-flex align-items-center ${className || ''}`}>
      {color ? (
        <>
          <div
            className="width-14 height-14 border-radius-3 border border-timber-wolf"
            style={{ backgroundColor: color }}
          ></div>

          {hideLabel ? null : <div className="fs-12 fw-medium text-primary cms-6">{color}</div>}
        </>
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

export default ColorViewer;

ColorViewer.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  hideLabel: PropTypes.bool
};
