import TYPES from './types';

import { get } from 'utils/api';
import { convertToFiltersWithOperation } from 'crew/utils/filters';

export const setShiftTypes = data => dispatch =>
  dispatch({ type: TYPES.SET_SHIFT_TYPES, payload: data });

export const setShifts = data => dispatch => dispatch({ type: TYPES.SET_SHIFTS, payload: data });

export const setShift = data => dispatch => dispatch({ type: TYPES.SET_SHIFT, payload: data });

export const setHighlightedSeaman = seaman => dispatch =>
  dispatch({ type: TYPES.SET_HIGHLIGHTED_SEAMAN, payload: seaman });

export const clearWarnings = id => dispatch =>
  dispatch({ type: TYPES.CLEAR_WARNINGS, payload: id });

export const setShiftWarnings = (crewID, warnings) => dispatch =>
  dispatch({ type: TYPES.SET_SHIFT_WARNINGS, payload: { crewID, warnings } });

export const setIsLoading = isLoading => dispatch =>
  dispatch({ type: TYPES.SET_IS_LOADING, payload: isLoading });

export const setSelectedContract = contract => dispatch =>
  dispatch({ type: TYPES.SET_SELECTED_CONTRACT, payload: contract });

export const setPeriodType = period => dispatch =>
  dispatch({ type: TYPES.SET_PERIOD_TYPE, payload: period });

export const setStartDate = date => dispatch =>
  dispatch({ type: TYPES.SET_START_DATE, payload: date });

export const setEndDate = date => dispatch => dispatch({ type: TYPES.SET_END_DATE, payload: date });

export const setSelectedRankFilter = filter => dispatch =>
  dispatch({ type: TYPES.SET_SELECTED_RANK_FILTER, payload: filter });

export const setSelectedVesselFilter = filter => dispatch =>
  dispatch({ type: TYPES.SET_SELECTED_VESSEL_FILTER, payload: filter });

export const setSelectedSorting = sorting => dispatch =>
  dispatch({ type: TYPES.SET_SELECTED_SORTING, payload: sorting });

export const setSelectedShiftType = type => dispatch =>
  dispatch({ type: TYPES.SET_SELECTED_SHIFT_TYPE, payload: type });

export const setInternalShift = shift => dispatch =>
  dispatch({ type: TYPES.SET_INTERNAL_SHIFT, payload: shift });

export const setActiveShiftComment = shift => dispatch =>
  dispatch({ type: TYPES.SET_ACTIVE_SHIFT_COMMENT, payload: shift });

export const setActiveShiftId = id => dispatch =>
  dispatch({ type: TYPES.SET_ACTIVE_SHIFT_ID, payload: id });

export const clearInternalTable = () => dispatch => dispatch({ type: TYPES.CLEAR_INTERNAL_TABLE });

export const clearShiftsState = () => dispatch => dispatch({ type: TYPES.CLEAR_SHIFTS_STATE });

export const setShiftsPortSeaState = shiftsPortSeaState => dispatch =>
  dispatch({ type: TYPES.SET_SHIFTS_PORT_SEA_STATE, payload: shiftsPortSeaState });

export const getWatchKeepingStatistics = params => dispatch => {
  dispatch({ type: TYPES.GET_WATCHKEEPING_STATISTICS.START, payload: { params } });

  return get(
    `/reports/watchkeeping-statistics`,
    params ? { filters: convertToFiltersWithOperation(params) } : {}
  )
    .then(response => {
      dispatch({ type: TYPES.GET_WATCHKEEPING_STATISTICS.SUCCESS, payload: response.data });
    })
    .catch(error => dispatch({ type: TYPES.GET_WATCHKEEPING_STATISTICS.ERROR, payload: error }));
};
