export const getCleanValues = (values, unsetId) => {
  const properties = ['additional_hazards', 'contingencies', 'primary_hazards', 'control_measures'];
  let vals = {};

  properties.forEach(p => {
    const property = values[p].map(el => ({
      ...el,
      id: el?.id === null || unsetId ? undefined : el.id,
      preview: undefined
    }));
    vals = { ...vals, [p]: property };
  });

  return Object.assign({ ...values, id: unsetId ? undefined : values.id }, vals);
};
