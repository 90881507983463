import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { dateToLocal } from 'common/utils/dates';

import { selectTripLogDetails } from 'store/ticketing/selectors';

const LogHistoryView = () => {
  const logDetails = useSelector(selectTripLogDetails);
  const createdAt = _get(logDetails, 'created_at');
  const updatedAt = _get(logDetails, 'updated_at');
  const createdBy = _get(logDetails, 'created_by.full_name');
  const updatedBy = _get(logDetails, 'updated_by.full_name');

  return (
    <div className="d-flex flex-column text-violet fs-12 mt-5">
      {createdBy ? (
        <div className="d-flex align-items-center">
          <div>Created by </div>
          <div className="fw-bold cms-4">{createdBy || '-'}</div>
          {createdAt ? (
            <>
              <div>,</div>
              <div className="cms-4 cme-4"> at </div>
              <div className="fw-bold">{dateToLocal(createdAt).format('DD/MM/YYYY, HH:mm')}</div>
            </>
          ) : null}
        </div>
      ) : null}

      {updatedBy ? (
        <div className="d-flex align-items-center mt-1">
          <div>Updated by </div>
          <div className="fw-bold cms-4">{updatedBy || '-'}</div>
          {updatedAt ? (
            <>
              <div>,</div>
              <div className="cms-4 cme-4"> at </div>
              <div className="fw-bold">{dateToLocal(updatedAt).format('DD/MM/YYYY, HH:mm')}</div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default LogHistoryView;
