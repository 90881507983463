// Action keys to match with port statement keys
export const scheduledActionsToPortStatementActionsMappings = {
  cargo_grade: 'details.cargo_grade',
  cargo_for_charter_party_cargo: 'charter_party_cargo',
  cargo_for_charter_party: 'charter_party',
  stowage_factor: 'details.cargo_sf',
  stowage_factor_unit_id: 'details.cargo_sf_unit_id',
  expected_quantity: 'details.cargo_expected_quantity',
  expected_quantity_unit_id: 'details.cargo_expected_quantity_unit_id',
  bl_quantity: 'details.bl_quantity',
  bl_quantity_unit_id: 'details.bl_quantity_unit_id',
  loading_rate: 'details.cargo_declared_ld_rate',
  loading_rate_unit_id: 'details.cargo_declared_ld_rate_unit_id',
  discharging_rate: 'details.cargo_declared_ld_rate',
  discharging_rate_unit_id: 'details.cargo_declared_ld_rate_unit_id',
  cargo_operations_commenced_at: 'details.cargo_operations_commenced_at',
  cargo_operation_timeline: 'details.cargo_operation_timeline',
  bunkering_is_charterers: 'details.bunkering_is_charterers',
  bunkering_for_charter_party: 'charter_party',
  fuels: 'bunkers',
  terms: 'details.cargo_terms'
};

// Action keys to match with delivery/redelivery keys
export const scheduledActionsToDeliveryFieldsMappings = {
  delivered_to_charter_party: 'delivered_to_charter_party',
  redelivered_to_charter_party: 'redelivered_to_charter_party',
  delivery_date: 'timestamp'
};
