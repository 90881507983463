import React from 'react';
import CoordinatePortsInput from 'common/components/general/CoordinatePortsInput';
import { Button } from 'reactstrap';

const AtSeaMenu = ({
  saveHandler,
  coordinates,
  selectField,
  atSeaValue,
  onCancelMenu,
  saveBtnTitle
}) => {
  return (
    <>
      <CoordinatePortsInput
        type={{ key: 'lat' }}
        coordinates={coordinates}
        selectField={selectField}
      />
      <CoordinatePortsInput
        type={{ key: 'lon' }}
        coordinates={coordinates}
        selectField={selectField}
      />
      <div className="sea-button-container d-flex align-items-center justify-content-end pt-2 pb-1">
        <Button size="sm" className="fs-10 p-0 me-1" color="cancel" onClick={() => onCancelMenu()}>
          CANCEL
        </Button>
        <Button size="sm" className="fs-10" color="primary" onClick={() => saveHandler(atSeaValue)}>
          {saveBtnTitle}
        </Button>
      </div>
    </>
  );
};

export default AtSeaMenu;
