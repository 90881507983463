import GoBackArrow from 'common/components/general/GoBackArrow';
import React from 'react';
import { useSelector } from 'react-redux';
import paths from 'routing/routes/_paths';

const GoBack = () => {
  const isOnboard = useSelector(state => state.isOnBoard);

  return !isOnboard ? <GoBackArrow to={paths.vessels} className={'me-2'} dataCy="go-back" /> : null;
};

export default GoBack;
