import { useSelector } from 'react-redux';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { saveDownloadedFile } from 'common/utils/downloads';

import {
  selectAttachments,
  selectActiveChapterUid,
  selectManualId,
  selectCurrentVersionId
} from 'manuals/store/selectors';

const Attachments = ({ postActions }) => {
  const attachments = useSelector(selectAttachments);
  const manualId = useSelector(selectManualId);
  const activeChapterUid = useSelector(selectActiveChapterUid);
  const currentVersionId = useSelector(selectCurrentVersionId);

  const downloadHandler = async () => {
    try {
      const params = {
        manual_id: manualId,
        chapter_uid: activeChapterUid,
        version_id: currentVersionId
      };

      const res = await postActions.onDownloadAttachments(params);
      saveDownloadedFile(res, `Manual Attachments - ${currentVersionId}`);
    } catch (e) {
      console.error(e);
    }
  };

  if (!attachments?.length) return <span className="fs-12 text-violet fw-medium">-</span>;

  return (
    <div className="d-flex align-items-start">
      <DownloadAttachment
        data={{
          attachments: (attachments || [])?.map(a => a.file),
          attachments_count: (attachments || [])?.filter(a => !a.deleted)?.length
        }}
        downloadFiles={downloadHandler}
        hasAttachments={true}
        canAddAttachments={false}
        popupPlacement={'right'}
        size="xxl"
      />
    </div>
  );
};

export default Attachments;
