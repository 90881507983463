import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import useRouter from 'use-react-router';

import * as authActions from 'store/auth/actions';

import { Link } from 'react-router-dom';
import paths from 'routing/routes/_paths';
import SvgRender from 'common/components/general/SvgRender';
import logoutIcon from 'common/assets/svg/actions/logout.svg';
import personIcon from 'common/assets/svg/common/person_profile.svg';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';

const User = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [logout] = useActions([authActions.logout]);

  const account = useSelector(selectAccount);
  const { location } = useRouter();

  const getPersonInitials = name => {
    if (name && name?.length > 0) {
      const words = name.split(' ');

      return `${words[0].charAt(0)}${words[1] ? `${words[1].charAt(0)}` : ''}`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (location.pathname) {
      setIsOpen(false);
    }
  }, [location.pathname]);

  return (
    <Dropdown
      className="sidebar-navbar--user d-flex flex-column justify-content-center align-items-center"
      direction="right"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle color="link" className="p-0 text-center">
        {getPersonInitials(account?.full_name || account?.name || account?.email)}
      </DropdownToggle>

      <DropdownMenu className="ms-2 px-0" end={true}>
        <div className="fs-12 fw-bold account-name text-truncate ps-1 mb-2">
          {account?.full_name || account?.name || account?.email || ''}
        </div>
        {account.id ? (
          <AuthCheck
            permissions={[permissions.onboard.crew.seaman_profile.personal.own_profile.view]}
          >
            <Link
              className="d-flex dropdown-item fs-14 fw-light align-items-center"
              to={`${paths[account?.type === 'crew' ? 'crew' : 'persons']}/${account.id}`}
            >
              <SvgRender src={personIcon} style={{ width: 13, height: 13 }} className="me-1" />
              Profile
            </Link>
          </AuthCheck>
        ) : null}
        <div className="border-top my-1"></div>
        <DropdownItem
          data-cy="logout"
          className="d-flex pointer fs-14 fw-light align-items-center"
          onClick={logout}
        >
          <SvgRender src={logoutIcon} style={{ width: 13, height: 13 }} className="me-1" />
          Log out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
export default User;
