import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { Col, Row } from 'reactstrap';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import { useActions } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';

const LubricantsLegTotalConsumptionInput = ({ field }) => {
  const [sum, setSum] = useState('');

  const [setValue] = useActions([reportActions.setValue]);

  const rateKey = field.lubricantRateKey;

  let co_1_leg_total_consumption_rate = useValue({ key: 'co_1_leg_total_consumption_rate' });
  let co_2_leg_total_consumption_rate = useValue({ key: 'co_2_leg_total_consumption_rate' });
  let co_3_leg_total_consumption_rate = useValue({ key: 'co_3_leg_total_consumption_rate' });

  useEffect(() => {
    let result = null;

    result = numeral(strToNumber(co_1_leg_total_consumption_rate) || 0)
      .add(strToNumber(co_2_leg_total_consumption_rate) || 0)
      .add(strToNumber(co_3_leg_total_consumption_rate) || 0);

    setSum(result._value);
    setValue({
      key: rateKey,
      value: result._value
    });
  }, [
    co_1_leg_total_consumption_rate,
    co_2_leg_total_consumption_rate,
    co_3_leg_total_consumption_rate
  ]);

  return (
    <Row noGutters>
      <Col xs={6} className={`cpe-4`}>
        <NumberField field={field} />
      </Col>

      <Col className="d-flex align-items-center justify-content-end fs-10 fw-bold" xs={6}>
        {numberToStr(sum)}&nbsp;<span className="text-violet">L/day</span>
      </Col>
    </Row>
  );
};

export default LubricantsLegTotalConsumptionInput;
