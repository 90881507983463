import React from 'react';
import useRouter from 'use-react-router';
import ImportanceDropdownSelector from 'common/components/selectors/ImportanceDropdownSelector';

const Importance = ({ changeEventValue, memoizedDebounce, isCreate, importance, disabled }) => {
  const { match } = useRouter();

  const onImportanceChange = (_, v) => {
    if (!v?.id || disabled) return;

    changeEventValue({ importance: v });
    if (!isCreate) {
      memoizedDebounce({ id: match.params.id, importance_id: v ? v.id : null });
    }
  };

  return (
    <ImportanceDropdownSelector
      list="event-importances"
      value={importance?.id}
      onChange={onImportanceChange}
      initializeWhennEmtpy={isCreate && !disabled}
    />
  );
};

export default Importance;
