export const FORM_FIELD_TYPES = {
  title: {
    label: 'title',
    hideRequired: true,
    hideHelpText: true
  },
  subtitle: {
    label: 'subtitle',
    hideRequired: true,
    hideHelpText: true
  },
  text_with_editor: {
    label: 'text-with-editor'
  },
  instructions: {
    label: 'instructions',
    hideRequired: true,
    hideHelpText: true
  },
  dropdown: {
    label: 'dropdown',
    initialOptions: 2,
    minOptions: 2
  },
  radio: {
    label: 'radio-button',
    initialOptions: 2,
    minOptions: 2
  },
  radio_with_details: {
    label: 'radio-button-with-details',
    initialOptions: 2,
    minOptions: 2
  },
  dropdown_with_details: {
    label: 'dropdown-with-details',
    initialOptions: 2,
    minOptions: 2
  },
  checkbox_with_details: {
    label: 'checkbox-with-details',
    hideFieldName: true
  },
  boolean: {
    label: 'boolean',
    hideFieldName: true
  },
  text: {
    label: 'text'
  },
  string: {
    label: 'string'
  },
  number: {
    label: 'numeric'
  },
  place: {
    label: 'place'
  },
  time: {
    label: 'time'
  },
  date: {
    label: 'date'
  },
  date_range: {
    label: 'date-range'
  },
  datetime: {
    label: 'datetime'
  },
  datetime_range: {
    label: 'datetime-range'
  },
  dates_multiple: {
    label: 'dates'
  },
  attachments: {
    label: 'attachments'
  },
  crew_with_rank: {
    label: 'crew-with-rank'
  },
  crew_participants: {
    label: 'crew-participants'
  },
  drill_dropdown: {
    label: 'drill-dropdown',
    formPlanningSubTypeEnabled: true
  },
  training_dropdown: {
    label: 'trainings-dropdown',
    formPlanningSubTypeEnabled: true
  },
  vessels_dropdown: {
    label: 'vessels-dropdown'
  },
  departments_dropdown: {
    label: 'departments-dropdown'
  },
  office_user: {
    label: 'office-user'
  },
  table_with_fixed_rows: {
    label: 'table-fixed',
    cellWidth: 312
  },
  table_with_dynamic_rows: {
    label: 'table-dynamic',
    cellWidth: 312
  },
  manual_chapter: {
    label: 'manual-chapter',
    hideRequired: true,
    hideHelpText: true
  },
  inventory_survey_rob: {
    label: 'inventory-survey-rob',
    showFieldItemDescription: true
  },
  dropdown_multiple_select: {
    label: 'dropdown-multiple-select',
    initialOptions: 2,
    minOptions: 2
  },
  form_instructions_image: {
    label: 'instructions-image',
    hideRequired: true,
    hideFieldName: true
  },
  root_cause_analysis: {
    label: 'root-cause-analysis'
  },
  risk_assessment: {
    label: 'risk-assessment',
    hideRequired: true
  },
  findings: {
    label: 'findings'
  },
  vessel_system_attribute: {
    label: 'vessel-system-attribute'
  }
};
