import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import {
  selectFieldValue,
  selectCaptainReportLastReportTimezone,
  selectCaptainReportIsOnBoard
} from 'captain-reports/store/selectors';
import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';

import * as reportActions from 'captain-reports/store/actions';
import moment from 'moment';

import Date from 'captain-reports/components/Date';
// import SvgRender from 'common/components/general/SvgRender';
import { Row, Col } from 'reactstrap';

// import arrowLeft from 'common/assets/svg/common/arrows/bordered-arrow-left.svg';
// import arrowRight from 'common/assets/svg/common/arrows/bordered-arrow-right.svg';
// import clock from 'common/assets/svg/common/clock.svg';
import useError from 'captain-reports/utils/useError';

import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

// const TimezoneHoursDiff = ({ diff }) => {
//   const parts = diff.toString().split('.');

//   const hours = Math.abs(parts[0]);
//   const mins = parts[1] ? parseInt((parts[1] * 60) / 100) : 0;

//   return `${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `${mins}0` : mins}h`;
// };

const StoppageEndTimestampField = ({
  tooltip,
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [gmt, setGmt] = useState('');
  const [timezoneDiff, setTimezoneDiff] = useState(null);

  const { avoidRender, tooltipID } = useTooltipID('field-label-reports-gmt');

  const [setValue] = useActions([reportActions.setValue]);

  const lastReportTimezone = useSelector(selectCaptainReportLastReportTimezone);
  const dateValue = useSelector(state => selectFieldValue(state, 'stoppage_end_local_timestamp'));
  const timeZone = useSelector(state => selectFieldValue(state, 'timezone'));

  const isOnBoard = useSelector(selectCaptainReportIsOnBoard);

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  }); // UTC (timestamp)

  useEffect(() => {
    if (timeZone !== null && lastReportTimezone !== null) {
      setTimezoneDiff(timeZone - lastReportTimezone);
    } else if (timezoneDiff !== null) {
      setTimezoneDiff(null);
    }
  }, [lastReportTimezone, timeZone]);

  useEffect(() => {
    if (value) {
      setGmt(value);
    }
  }, []);

  useEffect(() => {
    if (dateValue && timeZone !== null) {
      const val = moment(dateValue);
      const gmtValue = val.subtract(timeZone, 'hours');
      setGmt(gmtValue);

      const formattedGmtValue = gmtValue.format('YYYY-MM-DD HH:mm:ss');

      if (formattedGmtValue !== value) {
        setValue({
          key: field.key,
          value: gmtValue.format('YYYY-MM-DD HH:mm:ss'),
          sectionLabel,
          sectionActiveTab,
          subGroup,
          subGroupIndex
        });
      }
    }
  }, [dateValue, timeZone]);

  if (avoidRender) return null;

  return (
    <div className="position-relative">
      <Row className="fs-10">
        <Col xs="auto">
          {/* {timezoneDiff !== null && !isNaN(timezoneDiff) ? (
            <div
              className={`d-flex align-items-center border-bottom border-white lh-1 cpb-4 cmb-4 ${
                timezoneDiff > 0 ? 'text-violet' : timezoneDiff < 0 ? 'text-orange' : ''
              }`}
            >
              <SvgRender
                src={timezoneDiff === 0 ? clock : timezoneDiff > 0 ? arrowRight : arrowLeft}
                style={{ width: 14, height: 14 }}
              />

              <strong className="ps-1">
                {timezoneDiff === 0 ? (
                  'Same Timezone'
                ) : (
                  <>
                    <TimezoneHoursDiff diff={timezoneDiff} />
                    {`${timezoneDiff > 0 ? ' Advanced' : ' Retarted'}`}
                  </>
                )}
              </strong>
            </div>
          ) : null} */}

          <div className={`d-flex align-items-center ${isOnBoard ? 'text-primary' : 'text-dark'}`}>
            <div id={tooltipID} className="fw-bold me-1">
              UTC:
            </div>
            <Date value={gmt} withTime={true} timeClassName={'cps-4'} />
            {tooltip ? <Tooltip target={tooltipID}>{tooltip}</Tooltip> : null}
          </div>
        </Col>
      </Row>
      {error && <span className="gmt-error fs-10 text-red">*{error}</span>}
    </div>
  );
};

export default StoppageEndTimestampField;
