import { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import numeral from 'numeral';

import { useActions } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr, strToNumber } from 'common/utils/numbers';

const GeneratorLoConsumptionField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue] = useActions([reportActions.setValue]);

  const loFeedRateValue = useValue({
    key: 'dg_lub_oil_consumption_rate',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const runningHours = useValue({
    key: 'dg_running_minutes',
    sectionLabel: 'generators',
    sectionActiveTab: sectionActiveTab
  });

  const lubOilConsumption = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  useEffect(() => {
    let result = '';
    if ((lubOilConsumption || lubOilConsumption === 0) && runningHours) {
      result = numeral(strToNumber(lubOilConsumption))
        .multiply(60)
        .multiply(24)
        .divide(strToNumber(runningHours));
    } else {
      result = '';
    }

    setValue({
      key: 'dg_lub_oil_consumption_rate',
      value: result?._value || result?._value === 0 ? +result?._value.toFixed(2) : null,
      sectionLabel: sectionLabel,
      sectionActiveTab: sectionActiveTab,
      subGroup: subGroup,
      subGroupIndex: subGroupIndex
    });
  }, [lubOilConsumption, runningHours]);

  return (
    <Row className="w-100p text-nowrap align-items-center" noGutters>
      <Col xs={7}>
        <NumberField
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </Col>

      <Col xs={2} className="mt-2">
        <div>
          <span className="fs-10 ms-4 fw-bold">{numberToStr(loFeedRateValue)}</span>
          <span className={'text-violet fs-10 cms-4 fw-bold'}>L/day</span>
        </div>
      </Col>
    </Row>
  );
};

export default GeneratorLoConsumptionField;
