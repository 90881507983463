import PropTypes from 'prop-types';
import { WinguEmail } from 'common/entities/emails/WinguEmailTypes';

import Form from './Form';
import Preview from './Preview';

const Connection = ({ email, setActiveEditId, isActive, onEdit }) => {
  return (
    <>
      {isActive ? (
        <Form onClose={() => setActiveEditId(null)} />
      ) : (
        <Preview email={email} onClose={() => setActiveEditId(null)} onEdit={onEdit} />
      )}
    </>
  );
};

Connection.propTypes = {
  email: WinguEmail.isRequired,
  setActiveEditId: PropTypes.number,
  isActive: PropTypes.bool,
  onEdit: PropTypes.func
};

export default Connection;
