import { useCallback } from 'react';
import _get from 'lodash/get';

import ActionsNumberInput from 'common/components/form/inputs/ActionsNumberInput';
import ActionsTextInput from 'common/components/form/inputs/ActionsTextInput';
import ActionsListSelect from 'common/components/form/inputs/ActionsListSelect';
import { ActionFieldsTypes } from 'common/entities/tables/ActionsField';

import { useEffect, useState } from 'react';

const renderComponent = {
  numberInput: ActionsNumberInput,
  textInput: ActionsTextInput,
  listSelect: ActionsListSelect
};

const renderEmptyComponent = {
  numberInput: () => <div className="text-end w-100p pe-1">-</div>,
  textInput: () => null,
  listSelect: () => null
};

const ActionsField = ({ data, col, row = () => {}, type = 'numberInput' }) => {
  const {
    shouldHideActions = () => {},
    shouldHideInputWhenUndefined = false,
    overrideKey,
    onSave,
    ...rest
  } = row(data, col);
  const key = overrideKey || col.key;
  const tableValue = _get(data, key);

  const [currentValue, setCurrentValue] = useState(null);
  const [hiddenActions, setHiddenActions] = useState(false);

  useEffect(() => {
    setCurrentValue(tableValue || undefined);
  }, [tableValue]);

  useEffect(() => {
    setHiddenActions(shouldHideActions({ key: key, data, column: col }));
  }, [data, col, key, shouldHideActions]);

  const onSaveHandler = useCallback(
    (colKey, rowData, value) => {
      onSave({ key: colKey, data: rowData, value: value });
    },
    [onSave]
  );

  const onChange = useCallback(element => {
    const shouldSelectTargetValue = element?.target?.value === '' || element?.target?.value;

    setCurrentValue(shouldSelectTargetValue ? element?.target?.value : element);
  }, []);

  const RenderInput = renderComponent[type];

  if (hiddenActions && shouldHideInputWhenUndefined && tableValue === undefined) {
    const RenderEmptyInput = renderEmptyComponent[type];

    return <RenderEmptyInput />;
  }

  return (
    <RenderInput
      name={key}
      value={currentValue}
      hiddenActions={hiddenActions}
      onChange={onChange}
      onCancel={() => setCurrentValue(tableValue || null)}
      onSave={value => onSaveHandler(key, data, value)}
      {...rest}
    />
  );
};

export default ActionsField;

ActionsField.propTypes = ActionFieldsTypes;
