import { useState, useEffect } from 'react';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import useValue from 'captain-reports/utils/useValue';
import Select from 'common/components/form/inputs/Select';

import { isReportLocked } from 'captain-reports/store/selectors';
import * as listActions from 'store/lists/actions';
import * as reportActions from 'captain-reports/store/actions';

const AllowanceUnit = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [fetchListOptions, setValue] = useActions([
    listActions.fetchListOptions,
    reportActions.setValue
  ]);

  const isLocked = useSelector(isReportLocked);
  const [allowanceListName, setAllowanceListName] = useState();

  let value = useValue({ key: field.key });

  const allowanceUnitOptions =
    useSelector(state => state.lists[allowanceListName]?.options)?.map(e => ({
      ...e,
      value: e.label,
      label: e.name
    })) || [];

  const instructed_speed_consumption_target = useValue({
    key: 'instructed_speed_consumption_target',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  useEffect(() => {
    if (field.key === 'instructed_speed_allowance_unit' && !allowanceUnitOptions?.length) {
      fetchListOptions('captain-report-enums-instructed-speed-allowance-unit');
      setAllowanceListName('captain-report-enums-instructed-speed-allowance-unit');
    }

    if (field.key === 'instructed_consumption_allowance_unit' && !allowanceUnitOptions?.length) {
      fetchListOptions('captain-report-enums-instructed-consumption-allowance-unit');
      setAllowanceListName('captain-report-enums-instructed-consumption-allowance-unit');
    }
  }, []);

  const isHidden = instructed_speed_consumption_target === 'weather_routing_instruction';

  useEffect(() => {
    if (!isHidden && !value && allowanceUnitOptions?.length) {
      setValue({
        key: field.key,
        value: allowanceUnitOptions[0]?.label,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [allowanceUnitOptions?.length]);

  const changeHandler = value => {
    setValue({
      key: field.key,
      value: value,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });
  };

  return isHidden ? null : (
    <div className="select-input cmt-6 min-width-88 ">
      <Select
        label=" "
        onChange={changeHandler}
        className="w-100p"
        options={allowanceUnitOptions}
        getOptionValue={option => option.name}
        placeholder=""
        isDisabled={isLocked}
        value={value}
      />
    </div>
  );
};

export default AllowanceUnit;
