import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import { Selectors } from 'common/entities/selectors/SelectorTypes';

const CrewSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  listParams,
  showDefaultOptions = true,
  icon,
  ...rest
}) => {
  const [selectedCrewMembers, setSelectedCrewMembers] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'crew-members', listParams },
    { selected: selectedCrewMembers, setSelected: setSelectedCrewMembers }
  );

  const SelectTag = icon ? IconSelect : Select;

  return (
    <SelectTag
      autoFocus
      isAsync
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => getPartyDisplayName(option)}
      icon={icon}
      defaultOptions={() =>
        showDefaultOptions ? getInitialAsyncValues('crew-members', null, null, listParams) : []
      }
      loadOptions={search => getAsyncOptions(search, 'crew-members', listParams)}
      isMulti={isMulti}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selectedCrewMembers, filter?.value, isMulti)
          : []
      }
      onChange={selected => {
        setSelectedCrewMembers(isMulti ? selected : [selected]);
        onChange(
          value !== undefined
            ? selected
            : {
                value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
              }
        );
      }}
      {...rest}
    />
  );
};

export default CrewSelector;

CrewSelector.propTypes = Selectors;
