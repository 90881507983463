import { get, post, put } from '@/api';
import { EventOffHire } from '@/common/types/events';

type GetEventOffHireParamsType = { eventId?: number };

export const getEventOffHire = async ({ eventId }: GetEventOffHireParamsType) => {
  return (await get<EventOffHire | ''>(`/events/${eventId}/off-hire`)).data || null;
};

type OffHireParamsType = {
  eventId?: number;
  off_hire_reason_id?: number;
  associated_element_id: number | null;
  fuel_robs: {
    id?: number;
    fuel_grade_id: number;
    start_rob: number | string | null;
    end_rob: number | string | null;
  }[];
};

export const createOffHire = async ({ eventId, ...rest }: OffHireParamsType) => {
  return (await post<EventOffHire>(`/events/${eventId}/off-hire`, rest)).data;
};

export const editOffHire = async ({ eventId, ...rest }: OffHireParamsType) => {
  return (await put<EventOffHire>(`/events/${eventId}/off-hire`, rest)).data;
};
