const documents = [
  {
    sectionClassName: 'person-documents-preview',
    header: {
      label: 'PASSPORT',
      className: 'g-0',
      color: 'white'
    },
    fields: [
      {
        label: 'ID',
        key: 'passport_number',
        type: 'string',
        inputWidth: 5,
        inputProps: { placeholder: 'Add number' }
      },
      {
        label: 'ISSUE DATE',
        key: 'passport_issued_at',
        type: 'date',
        inputWidth: 'auto',
        inputProps: { placeholder: 'Set date', showClearDate: true }
      },
      {
        label: 'EXPIRATION DATE',
        key: 'passport_expires_at',
        type: 'date',
        inputWidth: 'auto',
        inputProps: { placeholder: 'Set date', showClearDate: true }
      },
      {
        label: 'ATTACHMENT',
        hideLabel: true,
        key: 'passport_attachment',
        type: 'file',
        rowAlignment: 'start',
        inputProps: {
          uploadText: ' ',
          className: 'square-upload cpy-4',
          group: 'parties.photos',
          showFileName: false,
          singleUpload: true
        }
      }
    ]
  },
  {
    sectionClassName: 'person-documents-preview mt-1',
    header: {
      label: 'SEAMAN BOOK',
      className: 'g-0 text-nowrap',
      color: 'white'
    },
    fields: [
      {
        label: 'ID',
        key: 'seaman_book_number',
        type: 'string',
        inputWidth: 5,
        inputProps: { placeholder: 'Add number' }
      },
      {
        label: 'ISSUE DATE',
        key: 'seaman_book_issued_at',
        type: 'date',
        inputWidth: 'auto',
        inputProps: { placeholder: 'Set date', showClearDate: true }
      },
      {
        label: 'EXPIRATION DATE',
        key: 'seaman_book_expires_at',
        type: 'date',
        inputWidth: 'auto',
        inputProps: { placeholder: 'Set date', showClearDate: true }
      },
      {
        label: 'ATTACHMENT',
        hideLabel: true,
        key: 'seaman_book_attachment',
        type: 'file',
        rowAlignment: 'start',
        inputProps: {
          uploadText: ' ',
          className: 'square-upload cpy-4',
          group: 'parties.photos',
          showFileName: false,
          singleUpload: true
        }
      }
    ]
  },
  {
    sectionClassName: 'person-documents-preview mt-1',
    header: {
      label: 'COVID VACCINATION',
      className: 'g-0 text-nowrap',
      color: 'white'
    },
    fields: [
      {
        label: 'DATE',
        key: 'covid_vaccination_date',
        type: 'date',
        inputWidth: 'auto',
        inputProps: { placeholder: 'Set date', showClearDate: true }
      },
      {
        label: 'REMARKS',
        key: 'covid_vaccination_remarks',
        type: 'textarea',
        rowAlignment: 'start',
        inputWidth: 5,
        inputProps: { placeholder: 'Add remarks' }
      }
    ]
  },
  {
    sectionClassName: 'person-documents-preview mt-1',
    header: {
      label: 'INTERNATIONAL VACCINATION',
      className: 'g-0 text-nowrap',
      color: 'white'
    },
    fields: [
      {
        label: 'DATE',
        key: 'international_vaccination_date',
        type: 'date',
        inputWidth: 'auto',
        inputProps: { placeholder: 'Set date', showClearDate: true, openDirection: 'up' }
      },
      {
        label: 'REMARKS',
        key: 'international_vaccination_remarks',
        type: 'textarea',
        rowAlignment: 'start',
        inputWidth: 5,
        inputProps: { placeholder: 'Add remarks' }
      }
    ]
  }
];

export default documents;
