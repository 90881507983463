import React from 'react';
import paths from '@/routing/routes/_paths.js';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';

const Itinerary = React.lazy(() => retry(() => import('@/views/voyages/itinerary/index.jsx')));

const voyages = [
  {
    path: paths.voyages_itinerary,
    component: Itinerary,
    app: true,
    type: 'private',
    exact: true,
    permissions: [
      permissions.onboard.voyages.itinerary.view,
      permissions.onboard.voyages.itinerary.edit
    ],
    requiredOrganizationSettings: [{ itinerary_edit_scheduled_ports_onboard: true }],
    documentTitle: 'Itinerary'
  }
];

export default voyages;
