import React, { useState, useEffect } from 'react';

import { ProfileDrawer } from 'common/components/drawer';

import PageSaving from 'common/components/general/PageSaving';
import JobLocked, { types } from 'common/components/jobs/_base/components/JobLocked';
import SvgRender from 'common/components/general/SvgRender';
import Chatbox from 'common/components/chatbox';
import NavigationTabs from './NavigationTabs';
import MainContent from './MainContent';

import { useDrawer } from 'common/components/drawer';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectIsJobLocked,
  isJobSaving,
  selectSelectedJob
} from 'common/components/jobs/_base/store/selectors';
import {
  selectMaintenanceJobClass,
  selectIsUnscheduledJob,
  selectIsPlannedJob,
  selectFilterPMSJobStatuses
} from 'common/components/pms/jobs/store/selectors';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';

import { useActions } from 'utils/hooks';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import drydock_enabled from 'common/assets/svg/jobs/drydock_enabled.svg';
import safety_enabled from 'common/assets/svg/jobs/guards.svg';
import classIcon from 'common/assets/svg/common/class-color.svg';
import Drydock from './Drydock';

const PmsJobsDrawer = ({ drawer, footer = null, isViewOnly = false, drawertTitle, jobID }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [
    getJob,
    resetJobProfile,
    setSelectedJob,
    getJobMessages,
    postJobMessage,
    getJobUnreadMessages,
    jobMarkAsRead,
    setDefaultJobStatus,
    setJobField
  ] = useActions([
    jobProfileActions.getJob,
    jobProfileActions.resetJobProfile,
    jobProfileActions.setSelectedJob,
    jobProfileActions.getJobMessages,
    jobProfileActions.postJobMessage,
    jobProfileActions.getJobUnreadMessages,
    jobProfileActions.jobMarkAsRead,
    jobProfileActions.setDefaultJobStatus,
    jobProfileActions.setJobField
  ]);

  const filteredJobStatuses = useSelector(selectFilterPMSJobStatuses);
  const maintenanceJobClass = useSelector(selectMaintenanceJobClass);
  const isDrydock = useSelector(state => selectJobField(state, 'is_drydock'));
  const isSafety = useSelector(state => selectJobField(state, 'is_safety'));
  const isUnscheduled = useSelector(selectIsUnscheduledJob);
  const isPlanned = useSelector(selectIsPlannedJob);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const jobStatuses = useSelector(selectJobStatuses);
  const isJobLocked = useSelector(selectIsJobLocked);
  const isSubmitting = useSelector(isJobSaving);
  const selectedJob = useSelector(selectSelectedJob);

  const statusId = useSelector(state => selectJobField(state, 'status_id'));
  const status = jobStatuses.find(el => el.id === statusId);
  const chatDrawer = useDrawer();

  const getUnreadMessages = async () => {
    if (!jobID) return;

    try {
      await getJobUnreadMessages({ job_id: jobID });
    } catch (err) {
      console.error(err);
    }
  };

  const postMessageAction = async params => {
    try {
      await postJobMessage({ job_id: jobID, ...params });
    } catch (error) {
      console.error(error);
    }
  };

  const getMessagesAction = async () => {
    if (!jobID) return;

    getJobMessages({ job_id: jobID });
  };

  const markAsReadAction = async () => {
    if (!jobID) return;

    try {
      await jobMarkAsRead({ job_id: jobID });
    } catch (error) {
      console.error(error);
    }
  };

  const initDrawer = async () => {
    try {
      setIsLoading(true);

      await getJob({ id: jobID || selectedJob?.id });

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (drawer.isOpen && (jobID || selectedJob?.id)) {
      initDrawer();
    }
  }, [drawer.isOpen, jobID, selectedJob?.id]);

  useEffect(() => {
    if (drawer.isOpen && !jobID && filteredJobStatuses?.length) {
      setDefaultJobStatus(
        filteredJobStatuses.find(option =>
          isOnBoard
            ? option.edit_side === 'vessel' && option.is_for_vessel
            : option.edit_side === 'office' && option.is_for_office
        )?.id
      );
    }
  }, [drawer.isOpen, filteredJobStatuses?.length]);

  useUpdateEffect(() => {
    if (!drawer.isOpen) {
      resetJobProfile();
      setSelectedJob(null);
    }
  }, [drawer.isOpen]);

  const constructDrawerName = () => {
    let title = drawertTitle;

    if (isLoading) {
      title = null;
    } else if (jobID) {
      if (isUnscheduled) {
        title = 'Unplanned Maintenance Job';
      } else if (isPlanned) {
        title = 'PMS Job';
      }
    } else {
      title = 'Create Unplanned Maintenance Job';
    }

    return { drawerTitle: title };
  };

  const { drawerTitle } = constructDrawerName();

  return (
    <ProfileDrawer
      drawer={drawer}
      size="lg"
      className={`job-profile job-pms-drawer ${chatDrawer?.isOpen ? 'chat-is-open' : ''}`}
      dontCloseOnClickOutside={true}
      isLoading={isLoading}
      footer={footer}
      header={
        <div
          className={`d-flex align-items-center w-100p justify-content-between ${
            isViewOnly ? 'pointer-events-none' : ''
          }`}
        >
          <div className="height-28 d-flex align-items-center">
            <div className="me-2">{drawerTitle}</div>

            <div
              className={`d-flex align-items-center ${
                isSafety || isDrydock || maintenanceJobClass?.is_class ? 'me-2' : ''
              } `}
            >
              {isSafety ? (
                <div className="d-inline-flex align-items-center cpb-2 cme-4">
                  <SvgRender
                    className="cme-4"
                    src={safety_enabled}
                    style={{ width: 14, height: 14 }}
                  />
                </div>
              ) : null}

              {isDrydock ? (
                <div className="d-inline-flex align-items-center cpb-2 cme-4 ">
                  <SvgRender
                    className="cme-4"
                    src={drydock_enabled}
                    style={{ width: 14, height: 14 }}
                  />
                </div>
              ) : null}

              {maintenanceJobClass?.is_class ? (
                <div className="d-inline-flex align-items-center cpb-2 ">
                  <SvgRender className="cme-4" src={classIcon} style={{ width: 14, height: 14 }} />
                  <div className="fs-10 text-primary cms-2">
                    {maintenanceJobClass.cms_reference_code}
                  </div>
                </div>
              ) : null}
            </div>

            <Drydock
              isPlanned={isPlanned}
              isDrydock={isDrydock}
              jobID={jobID}
              setJobField={setJobField}
            />

            {jobID ? <NavigationTabs /> : null}
          </div>

          {!isLoading && jobID && (
            <div className="d-flex align-items-center me-4">
              <JobLocked
                type={
                  isJobLocked ? (isOnBoard ? types.onboard : types.vessel_processing) : types.done
                }
                status={status?.name}
              />

              <div className="small-separator bg-platinum cpt-12 cpb-12 mx-3"></div>

              <Chatbox
                getMessages={getMessagesAction}
                sendMessage={postMessageAction}
                getUnreadMessages={getUnreadMessages}
                markMessagesAsRead={markAsReadAction}
                drawer={chatDrawer}
              />
            </div>
          )}
        </div>
      }
    >
      <div
        className={`w-100p h-100p overflow-hidden job-profile__body pb-2 ${
          isViewOnly ? 'pointer-events-none' : ''
        }`}
      >
        <div className="ps-4 h-100p pe-2 pt-2">
          <MainContent jobID={jobID} drawerIsOpen={drawer.isOpen} />
        </div>
      </div>

      <PageSaving isSaving={isSubmitting} />
    </ProfileDrawer>
  );
};

export default PmsJobsDrawer;
