import React from 'react';

import { Col } from 'reactstrap';

import { FieldsHeader } from 'captain-reports/templates/layout/_helpers';

import Group from './Group';
import VisibleMultiField from './VisibleMultiField';
import VisibleGroupField from './VisibleGroupField';

import useSubGroupLength from 'captain-reports/utils/useSubGroupLength';
import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';

import _flatten from 'lodash/flatten';

export const SubGroupHeader = ({ section, indexes, groups, label }) => {
  const groupFields = _flatten(groups.map(c => c.fields));
  const visible = useSelector(state => hasAtLeastOneVisibleField(state, groupFields));

  if (!visible) return null;

  return section.subGroupHeaderComponent ? (
    <div className={`template-layout__fields-header fs-10 text-violet mb-1 fw-bold`}>
      <section.subGroupHeaderComponent
        label={label}
        subGroup={section.subGroup}
        indexes={indexes}
        section={section}
        groups={groups}
      />
    </div>
  ) : (
    <FieldsHeader
      className={section.subGroupHeaderClassName}
      header={section.subGroupHeader}
      subLabel={section.subGroupLabel}
      customHeaderClassName={section.customSubGroupHeaderClassName}
    ></FieldsHeader>
  );
};

const Header = ({ groups, title, className }) => {
  const groupFields = _flatten(groups.map(c => c.fields));
  const visible = useSelector(state => hasAtLeastOneVisibleField(state, groupFields));

  if (!visible) return null;

  return <FieldsHeader header={title} className={className || ''} />;
};

const SubGroup = ({ groups, section, label, multiple }) => {
  const numberOfGroups = useSubGroupLength(label, section.subGroup);
  const indexes = Array.from(Array(numberOfGroups).keys());

  return (
    <Col xs={12}>
      <Group>
        {section.subGroupInline && (section.subGroupHeader || section.subGroupHeaderComponent) ? (
          <Col xs={12} className="subgroup-inline-header">
            <SubGroupHeader
              label={label}
              subGroup={section.subGroup}
              indexes={indexes}
              section={section}
              groups={groups}
            />
          </Col>
        ) : null}

        {indexes.map(index => (
          <Col
            key={index}
            xs={
              section.subGroupInline
                ? section.subGroupWidth !== undefined
                  ? section.subGroupWidth
                  : numberOfGroups > 6
                  ? ''
                  : 1
                : 12
            }
            className={`template-field template-field__subgroup`}
          >
            {!section.subGroupInline && section.subGroupHeader ? (
              <Header
                groups={groups}
                title={`${section.subGroupHeader} ${index + 1}`}
                className={section.subGroupHeaderClassName}
              />
            ) : null}

            {section.customComponent ? (
              <section.customComponent
                label={label}
                subGroup={section.subGroup}
                subGroupIndex={index}
                section={section}
                groups={groups}
              />
            ) : (
              groups.map((group, gindex) => (
                <Group key={gindex} className="align-items-center">
                  {group.fields.map((field, findex) => {
                    const formattedField = { ...field };

                    if (section.subGroupInline) {
                      formattedField.width = { xs: 12 };
                      formattedField.label =
                        label === 'dg_consumption_total' ? `CYL # ${index + 1}` : `# ${index + 1}`;
                    }

                    return multiple ? (
                      <VisibleMultiField
                        key={findex}
                        field={formattedField}
                        label={label}
                        subGroup={section.subGroup}
                        subGroupIndex={index}
                      />
                    ) : (
                      <VisibleGroupField
                        key={findex}
                        field={formattedField}
                        subGroup={section.subGroup}
                        subGroupIndex={index}
                      />
                    );
                  })}
                </Group>
              ))
            )}
          </Col>
        ))}
      </Group>
    </Col>
  );
};

export default SubGroup;
