import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, put, post, deleteRequest, download } from 'utils/api';

export const getCrewMedicalCases = createAsyncThunk(
  'GET_MEDICAL_CASES',
  async (params, { rejectWithValue }) => {
    const { eventId, ...rest } = params;

    try {
      const res = await get(`/events/${eventId}/crew-medical-cases`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateCrewMedicalCase = createAsyncThunk(
  'UPDATE_MEDICAL_CASE',
  async (params, { rejectWithValue }) => {
    const { eventId, medicalCaseId, ...rest } = params;

    try {
      const res = await put(`/events/${eventId}/crew-medical-cases/${medicalCaseId}`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createCrewMedicalCase = createAsyncThunk(
  'CREATE_MEDICAL_CASE',
  async (params, { rejectWithValue }) => {
    const { eventId, ...rest } = params;

    try {
      const res = await post(`/events/${eventId}/crew-medical-cases`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteCrewMedicalCase = createAsyncThunk(
  'DELETE_MEDICAL_CASE',
  async (params, { rejectWithValue }) => {
    const { eventId, medicalCaseId, ...rest } = params;

    try {
      const res = await deleteRequest(
        `/events/${eventId}/crew-medical-cases/${medicalCaseId}`,
        rest
      );

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const downloadCrewMedicalAttachments = createAsyncThunk(
  'DOWNLOAD_MEDICAL_CASE_ATTACHMENTS',
  async (params, { rejectWithValue }) => {
    try {
      const res = await download(`/files/entity`, { id: params.id, type: 'crew_medical_case' });

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
