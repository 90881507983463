import { css } from '@emotion/react';

import React, { useEffect, useMemo } from 'react';
import { arrayOf, number, string, shape } from 'prop-types';

import { Row, Col } from 'reactstrap';
import Select from 'common/components/form/styled-inputs/Select';

import { fetchListOptions } from 'store/lists/actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateCrewEvaluationAverageScore } from 'crew/store/evaluations/profile/actions';
import { selectAccountDepartments } from 'store/account/selectors';
import { getFirstFilteredTemplateBasedOnDepartments } from '../../helpers';
import { components } from 'react-select';
import Departments from 'common/components/general/Departments';
import { RankType } from 'common/entities/crew/RankTypes';
import { Department } from 'common/entities/parties/PartyTypes';
import { getCrewEvaluationTemplate } from 'crew/store/evaluations/templates/actions';

const TemplateSelector = ({ fields, selectField, setLoading }) => {
  const dispatch = useDispatch();

  const templates = useSelector(state => state.lists['crew-evaluation-templates'].options);
  const isFetching = useSelector(state => state.lists['crew-evaluation-templates'].isFetching);
  const accountDepartments = useSelector(selectAccountDepartments);

  const firstFilteredTemplate = useMemo(
    () => getFirstFilteredTemplateBasedOnDepartments(templates, accountDepartments),
    [templates, accountDepartments]
  );

  const onTemplateSelect = templateId => {
    if (fields.template_id.value !== templateId) {
      setLoading(true);
      selectField('template_id')(templateId);
      dispatch(updateCrewEvaluationAverageScore({ averageScore: null }));
    }
  };

  useEffect(() => {
    if (fields.rank_id.value) {
      dispatch(
        fetchListOptions('crew-evaluation-templates', '', { rank_id: fields.rank_id.value })
      );
    }
  }, [fields.rank_id.value, dispatch]);

  useEffect(() => {
    if (templates?.length && !fields.template_id.value) {
      selectField('template_id')(
        fields.template_id.value || firstFilteredTemplate?.id || templates[0].id
      );
    }
  }, [fields.template_id.value, firstFilteredTemplate?.id, selectField, templates]);

  useEffect(() => {
    if (fields.template_id.value) {
      const template = templates.find(item => item.id === fields.template_id.value);
      if (template) {
        const { id } = template;
        dispatch(getCrewEvaluationTemplate({ id })).then(() => setLoading(false));
      }
    }
  }, [fields.template_id.value, setLoading, templates, dispatch]);

  return (
    <Row noGutters className="mt-3 justify-content-between">
      <Col xs={12}>
        <Select
          className="w-100p"
          showLabel
          label={<span className="fw-bold text-primary fs-12">Template</span>}
          invisible={false}
          placeholder="Select template"
          options={templates}
          value={fields.template_id.value}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.title}
          onChange={onTemplateSelect}
          disabled={!fields.vessel_id.value && !fields.rank_id.value}
          components={{ Option, SingleValue }}
        />
      </Col>
    </Row>
  );
};

export default TemplateSelector;

const OptionView = ({ data }) => {
  const accountDepartments = useSelector(selectAccountDepartments);
  const firstFilteredDepartment = useMemo(() => {
    const accountDepartmentLabels = accountDepartments
      ? accountDepartments.map(department => department.label)
      : [];

    return (
      data.departments?.find(department => accountDepartmentLabels?.includes(department.label)) ||
      null
    );
  }, [data, accountDepartments]);

  return (
    <div css={style} className="d-flex align-items-center justify-content-between">
      <span className="text-primary fs-12 fw-medium">{data?.title}</span>
      <Departments
        values={firstFilteredDepartment ? [firstFilteredDepartment] : data.departments?.[0] || null}
      />
    </div>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="w-100p">
      <OptionView data={props.data} />
    </components.Option>
  );
};

export const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue className="w-100p" {...props}>
      <OptionView data={props.data} />
    </components.SingleValue>
  );
};

const style = css`
  .entity-label--department {
    padding-right: 0.5rem !important;
  }
`;

OptionView.propTypes = {
  data: shape({
    applicable_ranks: arrayOf(RankType),
    departments: arrayOf(Department),
    id: number.isRequired,
    name: string,
    title: string.isRequired
  })
};
