import React from 'react';

import NumberField from 'captain-reports/templates/inputs/NumberField';
import DateTimeInput from 'captain-reports/templates/inputs/DateTimeInput';
import CheckboxInput from 'captain-reports/templates/inputs/CheckboxInput';

import MainEngineSumpTank from 'captain-reports/templates/components/custom-fields/MainEngineSumpTank';

import MainEngineScavengeAndCamshaft from 'captain-reports/templates/components/custom-fields/MainEngineScavengeAndCamshaft';
import MainEngineFoLoInletSystemOilThrust from 'captain-reports/templates/components/custom-fields/MainEngineFoLoInletSystemOilThrust';
import MainEngineTurbochargers from 'captain-reports/templates/components/custom-fields/MainEngineTurbochargers';
import MainEngineRevCounterInput from 'captain-reports/templates/components/custom-fields/MainEngineRevCounterInput';
import MainEngineExGasDeviationField from 'captain-reports/templates/components/custom-fields/MainEngineExGasDeviationField';
import MainEngineSfocField from 'captain-reports/templates/components/custom-fields/MainEngineSfocField';

import MainEngineHeaderFuelPumpRacks from 'captain-reports/templates/components/custom-fields/MainEngineHeaderFuelPumpRacks';
import MainEngineHeaderCylinderUnit from 'captain-reports/templates/components/custom-fields/MainEngineHeaderCylinderUnit';
import MainEngineHeaderExhaustGas from 'captain-reports/templates/components/custom-fields/MainEngineHeaderExhaustGas';
import MainEngineHeaderJacketCoolingWater from 'captain-reports/templates/components/custom-fields/MainEngineHeaderJacketCoolingWater';
import MainEngineHeaderJacketCoolingWaterTemp from 'captain-reports/templates/components/custom-fields/MainEngineHeaderJacketCoolingWaterTemp';
import MainEngineHeaderUnderPistonTemp from 'captain-reports/templates/components/custom-fields/MainEngineHeaderUnderPistonTemp';
import MainEngineHeaderPistonCoolingOilTemp from 'captain-reports/templates/components/custom-fields/MainEngineHeaderPistonCoolingOilTemp';
import MainEngineHeaderCoFeedRates from 'captain-reports/templates/components/custom-fields/MainEngineHeaderCoFeedRates';
import MainEngineHeaderAlfaLubricatorHmiSetting from 'captain-reports/templates/components/custom-fields/MainEngineHeaderAlfaLubricatorHmiSetting';
import { validateMeRevCounter } from './validations';

const noonConfig = () => [
  {
    fields: [
      {
        label: 'REV COUNTER',
        key: 'me_rev_counter',
        type: 'number',
        width: { xs: 4 },
        render: MainEngineRevCounterInput,
        validation: validateMeRevCounter,
        templateValidation: { required: true }
      },
      {
        label: 'SHAFT POWER',
        key: 'me_shaft_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'EFFECTIVE POWER',
        key: 'me_effective_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'INDICATED POWER',
        key: 'me_indicated_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ENERGY PRODUCED',
        key: 'me_energy_produced',
        subLabel: 'kWh',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Load Indicator',
        key: 'me_load_indicator',
        subLabel: '%',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Governor Setting',
        key: 'me_governor_setting',
        subLabel: '%',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ENG ROOM TEMP',
        key: 'me_engine_room_temperature',
        subLabel: '°C',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Fuel Index',
        key: 'me_fuel_index',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'SFOC',
        key: 'me_sfoc',
        subLabel: 'gr/kWh',
        className: 'pointer-events-none noborder-input text-end-input',
        disabled: true,
        type: 'number',
        width: { xs: 2 },
        render: MainEngineSfocField
      },
      {
        label: 'Fo Regulating Handle',
        key: 'me_fo_regulating_handle',
        width: { xs: 2 },
        inputProps: {
          decimalScale: 1
        },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'fuel_rack_indications',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderFuelPumpRacks,
    fields: [
      {
        label: 'Fuel Pump Racks',
        key: 'me_fuel_pump_racks_indication_per_indication',
        subLabel: '',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'vit_air_indications',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderCylinderUnit,
    fields: [
      {
        label: 'Cylinder Unit Racks VIT',
        key: 'me_vit_per_cylinder',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'VIT Average',
        key: 'me_vit_average',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'VIT Air Pressure',
        key: 'me_vit_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'ex_gas_max_temps',
    subGroupHeaderComponent: MainEngineHeaderExhaustGas,
    subGroupInline: true,
    fields: [
      {
        label: 'EXHAUST GAS TEMPERATURE',
        key: 'me_exhaust_gas_temperature_max_per_cylinder',
        subLabel: '°C',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Exhaust Gas Pressure',
        subLabel: 'bar',
        key: 'me_exhaust_gas_pressure',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroupHeader: 'EXHAUST GAS TEMPERATURE DEVIATION FROM AVERAGE',
    subGroup: 'me_eg_deviations',
    subGroupInline: true,
    fields: [
      {
        label: 'EXHAUST GAS TEMPERATURE DEVIATION FROM AVERAGE',
        key: 'me_exhaust_gas_temperature_max_deviation_from_average',
        subLabel: '°C',
        className: 'pointer-events-none noborder-input',
        type: 'number',
        disabled: true,
        render: MainEngineExGasDeviationField
      }
    ]
  },
  {
    headerComponent: <MainEngineHeaderJacketCoolingWater />,
    className: 'me-jacket-cooling-water',
    fields: [
      {
        label: 'JACKET COOLING WATER INLET TEMPERATURE',
        subLabel: '°C',
        key: 'me_jacket_cool_water_inlet_temperature',
        width: { xs: 1 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'jacket_outlet_temps',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderJacketCoolingWaterTemp,
    fields: [
      {
        label: 'JACKET COOLING WATER OUTLET TEMPERATURE',
        key: 'me_jacket_cool_water_outlet_temperature_per_cylinder',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'under_piston_max_temps',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderUnderPistonTemp,
    fields: [
      {
        label: 'Under Piston Temperature',
        key: 'me_under_piston_temperature',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'alfa_lubricator_settings',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderAlfaLubricatorHmiSetting,
    fields: [
      {
        label: 'ALFA LUBRICATOR HMI SETTING',
        key: 'me_alfa_lubricator_hmi_setting',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'lo_pistons_outlet_temps',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderPistonCoolingOilTemp,
    fields: [
      {
        label: 'PISTON COOLING OIL OUTLET TEMPERATURE',
        key: 'me_pistons_cooling_oil_temperature',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'co_feed_rates',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderCoFeedRates,
    fields: [
      {
        label: 'CO FEED RATE',
        key: 'co_feed_rate_per_cylinder',
        subLabel: 'g/kWh',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'SCAVENGE AIR',
    customComponent: MainEngineScavengeAndCamshaft,
    extraFields: () => config().find(c => c.className === 'camshaft-oil'), // CAMSHAFT
    fields: [
      {
        label: 'Blowers in Operation',
        key: 'me_scavenge_air_aux_blower_in_operation',
        width: { xs: 6 },
        render: CheckboxInput,
        className: 'pe-0 pt-1'
      },
      {
        label: 'Temperature',
        key: 'me_scavenge_air_temperature',
        width: { xs: 3 },
        subLabel: '°C',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_scavenge_air_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'CAMSHAFT OIL',
    className: 'camshaft-oil',
    customComponent: () => null,
    fields: [
      {
        label: 'Temperature',
        key: 'me_camshaft_oil_temperature',
        subLabel: '°C',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_camshaft_oil_pressure',
        subLabel: 'bar',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'FO INLET',
    customComponent: MainEngineFoLoInletSystemOilThrust,
    extraFields: () => ({
      lo_inlet: config().find(c => c.className === 'lo-inlet'),
      system_oil: config().find(c => c.className === 'system-oil-pressure'),
      thrust: config().find(c => c.className === 'thrust-bearing-temp')
    }),
    fields: [
      {
        label: 'Temperature',
        key: 'me_fuel_inlet_temperature',
        subLabel: '°C',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_fuel_inlet_pressure',
        subLabel: 'bar',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'LO INLET',
    className: 'lo-inlet',
    customComponent: () => null,
    fields: [
      {
        label: 'Temperature',
        key: 'me_lub_oil_inlet_temperature',
        subLabel: '°C',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_lub_oil_pressure',
        subLabel: 'bar',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'SYSTEM OIL PRESS AT LO FILTER (bar)',
    className: 'system-oil-pressure',
    customComponent: () => null,
    fields: [
      {
        label: 'Before Filter',
        key: 'me_system_oil_pressure_before_filter',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'After Filter',
        key: 'me_system_oil_pressure_after_filter',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    className: 'thrust-bearing-temp',
    customComponent: () => null,
    fields: [
      {
        label: 'Thrust Bearing Temperature',
        key: 'me_thrust_bearing_temperature',
        subLabel: '°C',
        width: { xs: 12 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Sump Tank',
        key: 'sump_tank_rob',
        subLabel: 'L',
        width: { xs: 3 },
        type: 'number',
        render: MainEngineSumpTank
        // inputProps: { bottomLabel: 'out of 500 mt - 9%' }
      },
      {
        label: 'Engine Load Up',
        key: 'me_engine_load_up',
        width: { xs: 3 },
        render: CheckboxInput,
        className: 'pe-0'
      }
    ]
  },
  {
    customComponent: MainEngineTurbochargers,
    subGroupHeader: 'TURBOCHARGER',
    subGroup: 'turbos',
    fields: [
      {
        label: 'RPM',
        key: 'me_tc_rpm',
        subLabel: 'rpm',
        width: { xs: 12 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'turbos',
    header: 'EXHAUST GAS TEMPERATURE',
    className: 'main-engine-turbos',
    fields: [
      {
        label: 'IN',
        subLabel: '°C',
        key: 'me_tc_inlet_max_temperature',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'OUT',
        subLabel: '°C',
        key: 'me_tc_outlet_max_temperature',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'LUB OIL',
    subGroup: 'turbos',
    className: 'main-engine-turbos',
    fields: [
      {
        label: 'In Pressure',
        subLabel: 'bar',
        key: 'me_tc_lub_oil_inlet_pressure',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'In Temp',
        subLabel: '°C',
        key: 'me_tc_lub_oil_inlet_temperature',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Out Temp',
        subLabel: '°C',
        key: 'me_tc_lub_oil_outlet_temperature',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'turbos',
    className: 'main-engine-turbos',
    fields: [
      {
        label: 'Blowers Clean Date',
        key: 'me_tc_blowers_cleaning_date',
        width: { xs: 3 },
        render: DateTimeInput
      },
      {
        label: 'TURBINE SIDE CLEAN DATE',
        key: 'me_tc_turbine_cleaning_date',
        width: { xs: 3 },
        render: DateTimeInput
      },
      {
        label: 'AIR FILTER PRESSURE DROP',
        subLabel: 'mmWG',
        key: 'me_tc_air_filter_pressure_drop',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'EXH GAS PRESS AFTER TURB.',
        subLabel: 'mmWG',
        key: 'me_tc_exhaust_gas_pressure_after_turbine',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const config = () => [
  {
    fields: [
      {
        label: 'REV COUNTER',
        key: 'me_rev_counter',
        type: 'number',
        width: { xs: 4 },
        render: MainEngineRevCounterInput,
        validation: validateMeRevCounter,
        templateValidation: { required: true }
      },
      {
        label: 'SHAFT POWER',
        key: 'me_shaft_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'EFFECTIVE POWER',
        key: 'me_effective_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'INDICATED POWER',
        key: 'me_indicated_power',
        subLabel: 'kW',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ENERGY PRODUCED',
        key: 'me_energy_produced',
        subLabel: 'kWh',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Load Indicator',
        key: 'me_load_indicator',
        subLabel: '%',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ENG ROOM TEMP',
        key: 'me_engine_room_temperature',
        subLabel: '°C',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Fuel Index',
        key: 'me_fuel_index',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'SFOC',
        key: 'me_sfoc',
        subLabel: 'gr/kWh',
        className: 'pointer-events-none noborder-input text-end-input',
        disabled: true,
        type: 'number',
        width: { xs: 2 },
        render: MainEngineSfocField
      },
      {
        label: 'Fo Regulating Handle',
        key: 'me_fo_regulating_handle',
        width: { xs: 2 },
        inputProps: {
          decimalScale: 1
        },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'fuel_rack_indications',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderFuelPumpRacks,
    fields: [
      {
        label: 'Fuel Pump Racks',
        key: 'me_fuel_pump_racks_indication_per_indication',
        subLabel: '',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'vit_air_indications',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderCylinderUnit,
    fields: [
      {
        label: 'Cylinder Unit Racks VIT',
        key: 'me_vit_per_cylinder',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'VIT Average',
        key: 'me_vit_average',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'VIT Air Pressure',
        key: 'me_vit_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'ex_gas_max_temps',
    subGroupHeaderComponent: MainEngineHeaderExhaustGas,
    subGroupInline: true,
    fields: [
      {
        label: 'EXHAUST GAS TEMPERATURE',
        key: 'me_exhaust_gas_temperature_max_per_cylinder',
        subLabel: '°C',
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Exhaust Gas Pressure',
        subLabel: 'bar',
        key: 'me_exhaust_gas_pressure',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroupHeader: 'EXHAUST GAS TEMPERATURE DEVIATION FROM AVERAGE',
    subGroup: 'me_eg_deviations',
    subGroupInline: true,
    fields: [
      {
        label: 'EXHAUST GAS TEMPERATURE DEVIATION FROM AVERAGE',
        key: 'me_exhaust_gas_temperature_max_deviation_from_average',
        subLabel: '°C',
        className: 'pointer-events-none noborder-input',
        type: 'number',
        disabled: true,
        render: MainEngineExGasDeviationField
      }
    ]
  },
  {
    headerComponent: <MainEngineHeaderJacketCoolingWater />,
    className: 'me-jacket-cooling-water',
    fields: [
      {
        label: 'JACKET COOLING WATER INLET TEMPERATURE',
        subLabel: '°C',
        key: 'me_jacket_cool_water_inlet_temperature',
        width: { xs: 1 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'jacket_outlet_temps',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderJacketCoolingWaterTemp,
    fields: [
      {
        label: 'JACKET COOLING WATER OUTLET TEMPERATURE',
        key: 'me_jacket_cool_water_outlet_temperature_per_cylinder',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'under_piston_max_temps',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderUnderPistonTemp,
    fields: [
      {
        label: 'Under Piston Temperature',
        key: 'me_under_piston_temperature',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'lo_pistons_outlet_temps',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderPistonCoolingOilTemp,
    fields: [
      {
        label: 'PISTON COOLING OIL OUTLET TEMPERATURE',
        key: 'me_pistons_cooling_oil_temperature',
        subLabel: '°C',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'co_feed_rates',
    subGroupInline: true,
    subGroupHeaderComponent: MainEngineHeaderCoFeedRates,
    fields: [
      {
        label: 'CO FEED RATE',
        key: 'co_feed_rate_per_cylinder',
        subLabel: 'g/kWh',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'SCAVENGE AIR',
    customComponent: MainEngineScavengeAndCamshaft,
    extraFields: () => config().find(c => c.className === 'camshaft-oil'), // CAMSHAFT
    fields: [
      {
        label: 'Blowers in Operation',
        key: 'me_scavenge_air_aux_blower_in_operation',
        width: { xs: 6 },
        render: CheckboxInput,
        className: 'pe-0 pt-1'
      },
      {
        label: 'Temperature',
        key: 'me_scavenge_air_temperature',
        width: { xs: 3 },
        subLabel: '°C',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_scavenge_air_pressure',
        subLabel: 'bar',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'CAMSHAFT OIL',
    className: 'camshaft-oil',
    customComponent: () => null,
    fields: [
      {
        label: 'Temperature',
        key: 'me_camshaft_oil_temperature',
        subLabel: '°C',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_camshaft_oil_pressure',
        subLabel: 'bar',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'FO INLET',
    customComponent: MainEngineFoLoInletSystemOilThrust,
    extraFields: () => ({
      lo_inlet: config().find(c => c.className === 'lo-inlet'),
      system_oil: config().find(c => c.className === 'system-oil-pressure'),
      thrust: config().find(c => c.className === 'thrust-bearing-temp')
    }),
    fields: [
      {
        label: 'Temperature',
        key: 'me_fuel_inlet_temperature',
        subLabel: '°C',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_fuel_inlet_pressure',
        subLabel: 'bar',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'LO INLET',
    className: 'lo-inlet',
    customComponent: () => null,
    fields: [
      {
        label: 'Temperature',
        key: 'me_lub_oil_inlet_temperature',
        subLabel: '°C',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Pressure',
        key: 'me_lub_oil_pressure',
        subLabel: 'bar',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'SYSTEM OIL PRESS AT LO FILTER (bar)',
    className: 'system-oil-pressure',
    customComponent: () => null,
    fields: [
      {
        label: 'Before Filter',
        key: 'me_system_oil_pressure_before_filter',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'After Filter',
        key: 'me_system_oil_pressure_after_filter',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    className: 'thrust-bearing-temp',
    customComponent: () => null,
    fields: [
      {
        label: 'Thrust Bearing Temperature',
        key: 'me_thrust_bearing_temperature',
        subLabel: '°C',
        width: { xs: 12 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Sump Tank',
        key: 'sump_tank_rob',
        subLabel: 'L',
        width: { xs: 3 },
        type: 'number',
        render: MainEngineSumpTank
        // inputProps: { bottomLabel: 'out of 500 mt - 9%' }
      },
      {
        label: 'Engine Load Up',
        key: 'me_engine_load_up',
        width: { xs: 3 },
        render: CheckboxInput,
        className: 'pe-0'
      }
    ]
  },
  {
    customComponent: MainEngineTurbochargers,
    subGroupHeader: 'TURBOCHARGER',
    subGroup: 'turbos',
    fields: [
      {
        label: 'RPM',
        key: 'me_tc_rpm',
        subLabel: 'rpm',
        width: { xs: 12 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'turbos',
    header: 'EXHAUST GAS TEMPERATURE',
    className: 'main-engine-turbos',
    fields: [
      {
        label: 'IN',
        subLabel: '°C',
        key: 'me_tc_inlet_max_temperature',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'OUT',
        subLabel: '°C',
        key: 'me_tc_outlet_max_temperature',
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    header: 'LUB OIL',
    subGroup: 'turbos',
    className: 'main-engine-turbos',
    fields: [
      {
        label: 'In Pressure',
        subLabel: 'bar',
        key: 'me_tc_lub_oil_inlet_pressure',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'In Temp',
        subLabel: '°C',
        key: 'me_tc_lub_oil_inlet_temperature',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Out Temp',
        subLabel: '°C',
        key: 'me_tc_lub_oil_outlet_temperature',
        width: { xs: 4 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    subGroup: 'turbos',
    className: 'main-engine-turbos',
    fields: [
      {
        label: 'Blowers Clean Date',
        key: 'me_tc_blowers_cleaning_date',
        width: { xs: 3 },
        render: DateTimeInput
      },
      {
        label: 'TURBINE SIDE CLEAN DATE',
        key: 'me_tc_turbine_cleaning_date',
        width: { xs: 3 },
        render: DateTimeInput
      },
      {
        label: 'AIR FILTER PRESSURE DROP',
        subLabel: 'mmWG',
        key: 'me_tc_air_filter_pressure_drop',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'EXH GAS PRESS AFTER TURB.',
        subLabel: 'mmWG',
        key: 'me_tc_exhaust_gas_pressure_after_turbine',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const mainEngineConfig = {
  noon: () => noonConfig(),
  arrival: () => noonConfig(),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => config(),
  port_noon: () => config(),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default mainEngineConfig;
