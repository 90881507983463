import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { isAuthorized } from 'utils/permissions/authorize';
import { useAppSelector } from '@/store/hooks';
import { selectAccount } from '@/store/account/selectors-ts';
import {
  PermisionEnvironments,
  PermisionModeType,
  PermissionsType
} from '@/common/types/permissions';
import { OrganizationSettings } from '@/types/organization-settings';
import { isVisibleForOrganization } from '@/common/utils/permissions/organization';
import { selectOrganizationSettings } from '@/store/settings/selectors';

type AuthCheckProps = PropsWithChildren<{
  permissions?: PermissionsType;
  mode?: PermisionModeType;
  environments?: PermisionEnvironments;
  avoidCheck?: boolean;
  unAuthorizedRender?: ReactNode;
  requiredOrganizationSettings?: OrganizationSettings[];
}>;

const AuthCheck: FC<AuthCheckProps> = ({
  children,
  permissions = [],
  mode = 'or',
  environments = [],
  unAuthorizedRender,
  avoidCheck,
  requiredOrganizationSettings
}) => {
  const account = useAppSelector(selectAccount);
  const settings = useAppSelector(selectOrganizationSettings);
  const [renderItem, setRenderItem] = useState(
    isVisibleForOrganization(settings, requiredOrganizationSettings) ||
      isAuthorized(account, permissions, mode, environments)
  );

  useEffect(() => {
    if (avoidCheck) {
      setRenderItem(true);
    } else {
      const isVisible = isVisibleForOrganization(settings, requiredOrganizationSettings);

      if (isVisible) {
        if (account) {
          setRenderItem(isAuthorized(account, permissions, mode, environments));
        } else {
          setRenderItem(permissions?.length || environments?.length ? false : true);
        }
      } else {
        setRenderItem(false);
      }
    }
  }, [
    account,
    settings,
    permissions,
    environments,
    avoidCheck,
    mode,
    requiredOrganizationSettings
  ]);

  if (renderItem) {
    return children;
  } else {
    return unAuthorizedRender || null;
  }
};

export default AuthCheck;
