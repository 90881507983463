import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import Select from '@/ts-common/components/form/inputs/select';
import { useGetVesselStoppageFuelGradesQuery } from '@/api/vessels/queries';
import { useForm, useFormState } from '@/utils/hooks';
import config, { EventOffHireFormStateType, OffHireRobsFormStateType } from './config';
import { FieldsStateType, FormState } from '@/common/types/form';
import { useGetEventOffHireQuery } from '@/api/events/event-modules/off-hire/queries';
import RobInput from './RobInput';
import FormSaveActions from '@/common/components/form/FormSaveActions';
import {
  useCreateOffHireMutation,
  useEditOffHireMutation
} from '@/api/events/event-modules/off-hire/mutations';
import { useAppSelector } from '@/store/hooks';
import { selectEventId } from '@/events/store/events/selectors';

type EditModeProps = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditMode: FC<EditModeProps> = ({ setIsEditing }) => {
  const { formState, loadValues, resetForm, collectValues } = useForm(config);
  const { selectField, subStates, ...rest } = useFormState(formState);
  const fields: FieldsStateType<EventOffHireFormStateType> = rest.fields;
  const startRobsSubStates = subStates('start_robs');
  const endRobsSubStates = subStates('end_robs');

  const eventId = useAppSelector(selectEventId);

  const { data: stoppageData } = useGetVesselStoppageFuelGradesQuery();
  const { data: offHireData } = useGetEventOffHireQuery();
  const { mutate: createMutate } = useCreateOffHireMutation();
  const { mutate: editMutate } = useEditOffHireMutation();

  const fuelGrades = stoppageData?.fuel_grades;

  const onClose = () => {
    resetForm();
    setIsEditing(false);
  };

  const onSave = () => {
    const values = collectValues() as EventOffHireFormStateType | null;

    if (!values) return;

    const { off_hire_reason, start_robs, end_robs, associated_element } = values;

    const params = {
      eventId,
      off_hire_reason_id: off_hire_reason?.id,
      fuel_robs: (fuelGrades || []).map(fuelGrade => {
        const startRob = start_robs.find(rob => rob.fuel_grade?.id === fuelGrade.id);
        const endRob = end_robs.find(rob => rob.fuel_grade?.id === fuelGrade.id);

        return {
          start_rob: startRob?.value || null,
          end_rob: endRob?.value || null,
          fuel_grade_id: fuelGrade.id,
          id: startRob?.id || endRob?.id || undefined
        };
      }),
      associated_element_id: associated_element?.id || null
    };

    if (offHireData?.event_id) {
      editMutate(params, {
        onSuccess: () => onClose()
      });
    } else {
      createMutate(params, {
        onSuccess: () => onClose()
      });
    }
  };

  useEffect(() => {
    if (!fuelGrades) return;

    const { startRobs, endRobs } = fuelGrades.reduce<{
      startRobs: OffHireRobsFormStateType[];
      endRobs: OffHireRobsFormStateType[];
    }>(
      (prev, fuelGrade) => {
        const rob = offHireData?.fuel_robs.find(rob => rob.fuel_grade_id === fuelGrade.id);

        prev.startRobs.push({ fuel_grade: fuelGrade, value: rob?.start_rob || null, id: rob?.id });
        prev.endRobs.push({ fuel_grade: fuelGrade, value: rob?.end_rob || null, id: rob?.id });

        return prev;
      },
      {
        startRobs: [],
        endRobs: []
      }
    );

    loadValues({
      off_hire_reason: offHireData?.off_hire_reason || null,
      associated_element: offHireData?.associated_element || '',
      start_robs: startRobs,
      end_robs: endRobs
    });
  }, [fuelGrades, offHireData, loadValues]);

  return (
    <Container className="py-2 px-3 position-relative">
      <div className="d-flex aling-items-center">
        <Select
          isAsync
          className="w-32p"
          placeholder="Select reason"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id.toString()}
          onChange={reason => selectField('off_hire_reason')(reason)}
          label="REASON"
          memoizedRequestParams={{ path: '/lists', params: { list: 'off-hire-reasons' } }}
          {...fields.off_hire_reason}
        />

        <Select
          isAsync
          className="w-32p ms-1"
          placeholder="Select associated element"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id.toString()}
          onChange={element => selectField('associated_element')(element)}
          label="ASSOCIATED ELEMENT"
          memoizedRequestParams={{
            path: '/lists',
            params: {
              list: 'off-hire-reason-associated-elements',
              off_hire_reason_id: fields.off_hire_reason.value?.id
            }
          }}
          {...fields.associated_element}
        />
      </div>

      <div className="d-flex align-items-center">
        <div className="w-34p cme-12">
          <span className="fw-medium text-primary fs-12">Bunkers - Start ROB (mt)</span>
          <div className="d-flex cmt-12">
            {startRobsSubStates.map(
              (startRobFormState: FormState<OffHireRobsFormStateType>, index: number) => (
                <RobInput
                  className={index !== startRobsSubStates.length - 1 ? 'me-1' : ''}
                  key={`start-rob-${index}`}
                  formState={startRobFormState}
                />
              )
            )}
          </div>
        </div>

        <div className="w-34p">
          <span className="fw-medium text-primary fs-12">Bunkers - End ROB (mt)</span>
          <div className="d-flex cmt-12">
            {endRobsSubStates.map(
              (endRobFormState: FormState<OffHireRobsFormStateType>, index: number) => (
                <RobInput key={`end-rob-${index}`} formState={endRobFormState} className="me-1" />
              )
            )}
          </div>
        </div>
      </div>

      <FormSaveActions
        mode="edit"
        onCancel={onClose}
        onSave={onSave}
        style={{ top: 12, right: -10 }}
      />
    </Container>
  );
};

export default EditMode;

const Container = styled.div`
  background-color: rgba(134, 147, 171, 0.1);
  border-radius: 11px;
`;
