import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import { Button } from 'reactstrap';
import closeIcon from 'common/assets/svg/actions/close.svg';
import PropTypes from 'prop-types';

const SignatureCanvas = ({ identifier, onMouseLeave, onClearCanvas }) => {
  return (
    <>
      <div className="text-violet fs-12 cmb-2">Use your device to draw your signature:</div>

      <div className="position-relative max-width-fit">
        <canvas
          className="signature-pad border border-black border-2 border-radius-3"
          id={`signature-pad${identifier ? `-${identifier}` : ''}`}
          width="470"
          height="300"
          onMouseLeave={onMouseLeave}
        ></canvas>
        {onClearCanvas ? (
          <Button
            color="white"
            type="button"
            onClick={onClearCanvas}
            className="px-1 cpy-4 border-radius-5 shadow-none position-absolute"
            style={{ top: 7, right: 7 }}
          >
            <div className="d-flex align-items-center">
              <SvgRender src={closeIcon} className="me-1" style={{ width: 8, height: 8 }} />
              <span className="fs-12 text-primary">Clear</span>
            </div>
          </Button>
        ) : null}
      </div>
    </>
  );
};

SignatureCanvas.propTypes = {
  identifier: PropTypes.string,
  onMouseLeave: PropTypes.func,
  onClearCanvas: PropTypes.func
};

export default SignatureCanvas;
