import React from 'react';
import Radio from 'common/components/form/inputs/Radio';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import { useSelector } from 'react-redux';
import { isReportLocked, selectCaptainReportIsOnBoard } from 'captain-reports/store/selectors';

import * as reportActions from 'captain-reports/store/actions';
// import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';
import useInitializeFromPreviousReport from 'captain-reports/utils/useInitializeFromPreviousReport';

const RadioInput = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const isLocked = useSelector(isReportLocked);

  const isOnBoard = useSelector(selectCaptainReportIsOnBoard);

  useInitializeFromPreviousReport({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onChange = value => {
    setValue({ key: field.key, value, sectionLabel, sectionActiveTab, subGroup, subGroupIndex });
  };

  // useEffect(() => {
  //   if (!value) {
  //     onChange(_get(field, 'options[0].value'));
  //   }
  // }, []);

  return (
    <div className="radio-input h-100p d-flex flex-column justify-content-center">
      {field.options.map((el, index) => (
        <Radio
          disabled={isLocked}
          className={`mb-${index === field.options.length - 1 ? '0' : '1'}`}
          key={index}
          checked={value === el.value}
          onChange={() => onChange(el.value)}
          label={
            <div className="d-flex align-items-center">
              <div className="me-1 text-dark">{el.label}</div>
              {el.icon && (
                <SvgRender
                  src={el.icon}
                  style={{ width: 16, height: 16 }}
                  className={`${isOnBoard ? 'text-primary' : 'text-dark'}`}
                />
              )}
            </div>
          }
        />
      ))}
    </div>
  );
};

export default RadioInput;
