import TYPES from './types';

import _get from 'lodash/get';
import { normalizeShifts } from 'crew/utils/working-schedule/helpers';

export const INITIAL_STATE = {
  isDraft: false,
  isLoading: true,
  originalIsDraft: false,
  latestSchedule: null,
  crewMembers: [],
  oldSelectedSchedule: null
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_OLD_WORKING_SCHEDULE.START:
      return {
        ...state,
        oldSelectedSchedule: payload,
        isLoading: true
      };

    case TYPES.GET_OLD_WORKING_SCHEDULE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDraft: false,
        crewMembers:
          _get(payload, 'crew_members').map(crewMember => {
            const shifts = normalizeShifts(_get(crewMember, 'shifts') || []);

            return {
              ...crewMember,
              shifts
            };
          }) || []
      };

    case TYPES.GET_OLD_WORKING_SCHEDULE.ERROR:
      return {
        ...state,
        isLoading: false,
        oldSelectedSchedule: null
      };

    case TYPES.GET_LATEST_WORKING_SCHEDULE.START:
      return {
        ...state,
        isLoading: true,
        oldSelectedSchedule: null
      };

    case TYPES.GET_LATEST_WORKING_SCHEDULE.SUCCESS:
      return {
        ...state,
        latestSchedule: payload,
        isLoading: false,
        isDraft: _get(payload, 'is_draft'),
        crewMembers:
          _get(payload, 'crew_members').map(crewMember => {
            const shifts = normalizeShifts(_get(crewMember, 'shifts') || []);

            return {
              ...crewMember,
              shifts
            };
          }) || []
      };

    case TYPES.GET_LATEST_WORKING_SCHEDULE.ERROR:
      return {
        ...state,
        isLoading: false
      };

    case TYPES.PUBLISH_WORKING_SCHEDULE.SUCCESS:
      return {
        ...state,
        latestSchedule: { ...state.latestSchedule, ...payload, is_draft: false },
        isDraft: false
      };

    case TYPES.UPDATE_CREW_WORKING_SCHEDULE.START:
      return { ...state, isLoading: true };

    case TYPES.UPDATE_CREW_WORKING_SCHEDULE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDraft: true,
        latestSchedule: { ...state.latestSchedule, is_draft: true },
        crewMembers: state.crewMembers.map(crew => {
          if (crew.seaman.id == payload.crew_member_id) {
            const shifts = normalizeShifts(_get(payload, 'shifts') || []);

            return {
              ...crew,
              ...payload,
              shifts
            };
          }

          return crew;
        })
      };

    case TYPES.UPDATE_CREW_WORKING_SCHEDULE.ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default reducer;
