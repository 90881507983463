import { OrganizationSettings, OrganizationSettingsKey } from '@/types/organization-settings';

export type RequiredOrganizationSettings = {
  [K in OrganizationSettingsKey]: unknown;
};

export const isVisibleForOrganization = (
  state: OrganizationSettings,
  requiredOrganizationSettings?: RequiredOrganizationSettings[]
) => {
  if (!requiredOrganizationSettings) return true;

  return requiredOrganizationSettings.every(requirement =>
    (Object.keys(requirement) as Array<OrganizationSettingsKey>).every(
      key => requirement[key] === state[key]
    )
  );
};
