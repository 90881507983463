import React from 'react';
import moment from 'moment';
import SvgRender from 'common/components/general/SvgRender';
import calendarIcon from 'common/assets/svg/common/calendar.svg';
import location from 'common/assets/svg/common/location-solid.svg';
import crew from 'common/assets/svg/crew/rank.svg';
import crossMedical from 'common/assets/svg/common/cross-medical-health.svg';
import { imageUrlSelector } from 'common/utils/image-size';
import rolodex from 'common/assets/svg/avatars/rolodex.svg';
import PreviewProfileImage from 'common/components/general/ProfileImage';

import { getApiBaseUrl } from 'utils/api';
import { Button } from 'reactstrap';
import { CrewMedicalCaseTypes } from 'common/entities/events/CrewMedicalCaseTypes';

const PreviewHeader = ({ medicalCase }) => {
  const previewImageBaseUrl = getApiBaseUrl();

  return (
    <div className="d-flex w-100p justify-content-start align-items-center fs-14 border-bottom pb-1">
      <PreviewProfileImage
        size="xxs"
        rounded
        className="rounded"
        image={
          previewImageBaseUrl +
          imageUrlSelector({
            file: medicalCase.crew_member.photo,
            label: 'contact_profile'
          })
        }
        fallback={rolodex}
      />
      <div className="ms-2">
        <div className="d-flex align-items-center cmb-4">
          <div className="fw-medium me-1">
            {medicalCase?.crew_member.full_name} - {medicalCase?.description}
          </div>
          {medicalCase.is_work_related ? (
            <Button
              size="sm"
              className={`height-16 lh-12 fs-10 py-0 cms-4 fw-medium 
              bg-moody-blue pointer-events-none border-radius-10
   `}
            >
              Work Related
            </Button>
          ) : null}

          {medicalCase.is_crew_claim ? (
            <Button
              size="sm"
              className={`height-16 lh-12 fs-10 py-0 cms-4 fw-medium 
              bg-turquoise pointer-events-none border-radius-10
   `}
            >
              Crew Claim
            </Button>
          ) : null}
        </div>

        <div className="text-violet d-flex">
          <div className="d-flex align-items-center me-3">
            <SvgRender className={`text-violet`} style={{ height: 14, width: 14 }} src={crew} />
            <div className="ms-1">{medicalCase?.crew_member.details.rank.name}</div>
          </div>

          <div className="d-flex align-items-center me-3">
            <SvgRender
              className={`text-violet`}
              style={{ height: 16, width: 16 }}
              src={calendarIcon}
            />
            <div className="ms-1">
              Date Occurred:&nbsp;{moment(medicalCase?.occurred_at).format('DD/MM/YYYY')}
            </div>
          </div>

          {medicalCase?.location ? (
            <div className="d-flex align-items-center me-3">
              <SvgRender
                className={`text-violet`}
                style={{ height: 14, width: 14 }}
                src={location}
              />
              <div className="ms-1">{medicalCase?.location}</div>
            </div>
          ) : null}

          <div className="d-flex align-items-center">
            <SvgRender
              className={`text-violet`}
              style={{ height: 14, width: 14 }}
              src={crossMedical}
            />
            <div className="ms-1">
              {medicalCase?.type?.name}{' '}
              <strong>
                {medicalCase?.category?.name ? `|  ${medicalCase?.category?.name}` : ''}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewHeader.propTypes = {
  medicalCase: CrewMedicalCaseTypes
};

export default PreviewHeader;
