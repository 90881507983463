import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import _findKey from 'lodash/findKey';

import { FORM_FIELD_TYPES } from 'common/components/forms/digital/constants';
import { strToNumber } from 'common/utils/numbers';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';

/* Form Field Selectors */

export const getField = form_field => form_field?.field;

export const getFieldName = form_field => getField(form_field)?.name;

export const getFieldID = form_field => getField(form_field)?.id;

export const getFieldType = form_field => getField(form_field)?.form_field_type;

export const getFieldTypeLabel = form_field => getFieldType(form_field)?.label;

export const getFieldTypeName = form_field => getFieldType(form_field)?.name;

export const getFieldTypeDescription = form_field => getFieldType(form_field)?.description;

export const getFormFieldParams = form_field => form_field?.form_field_params;

export const getFormShowOnFormList = form_field => form_field?.show_on_form_list;

export const getFieldCanShowOnFormListAsType = form_field =>
  getField(form_field)?.can_show_on_form_list_as_type;

export const getFormFieldParamsOptions = form_field => {
  const formFieldParams = getFormFieldParams(form_field);

  return formFieldParams?.options || [];
};

export const getFormFieldParamsText = form_field => getFormFieldParams(form_field)?.text;
export const getFormFieldParamsItem = form_field => getFormFieldParams(form_field)?.item;
export const getFormFieldItemDescription = form_field =>
  getFormFieldParamsItem(form_field)?.description;

export const getFormFieldID = form_field => form_field?.id;
export const getFormFieldUID = form_field => form_field?.uid;

export const getFormFieldIsRequired = form_field => form_field?.required;

export const getFormFieldHelpText = form_field => form_field?.help_text;

export const getFormFieldParentID = form_field => form_field?.parent_id;

export const getFormFieldSortIndex = form_field => form_field?.sort_index;
export const getFormFieldColumnIndex = form_field => form_field?.column_index;
export const getFormFieldOldColumnIndex = form_field => form_field?.old_column_index;

export const getFormFieldTypeConfig = form_field => {
  const fieldTypeLabel = getFieldTypeLabel(form_field);

  return FORM_FIELD_TYPES[_findKey(FORM_FIELD_TYPES, config => config.label === fieldTypeLabel)];
};

const getFormFieldDefaultValue = form_field => {
  const fieldTypeLabel = getFieldTypeLabel(form_field);

  switch (fieldTypeLabel) {
    case FORM_FIELD_TYPES.date_range.label:
      return { from: null, to: null };
    case FORM_FIELD_TYPES.attachments.label:
    case FORM_FIELD_TYPES.crew_participants.label:
      return [];
    case FORM_FIELD_TYPES.crew_with_rank.label:
      return { crew_rank_id: null, crew_member_id: null };
    case FORM_FIELD_TYPES.place.label:
      return { port_id: null, lat: null, lon: null, port: null };
    case FORM_FIELD_TYPES.radio_with_details.label:
    case FORM_FIELD_TYPES.dropdown_with_details.label:
      return { option: null, additional_info: null };
    default:
      return null;
  }
};

/* -- */

/*
   Create initial state when having no values, eg create mode 
*/

export const getInitialStateWithoutValues = form => {
  const newFormState = {};
  if (!form?.form_fields) return newFormState;

  form.form_fields.forEach(form_field => {
    newFormState[form_field.id] = {
      value: getFormFieldDefaultValue(form_field),
      error: ''
    };
  });

  return newFormState;
};

/*
  Used to parse the nested values from inside a field.
*/

const parseInitialNestedStateValues = values => {
  const parsedValues = [];

  values.forEach(v => {
    if (v.value?.value) {
      parsedValues.push({ ...v, value: v.value?.value });
    } else {
      parsedValues.push(v);
    }
  });

  return parsedValues;
};

/*
   Create initial state when having values, eg edit 
*/

export const getInitialStateWithValues = (form, values = []) => {
  const newFormState = {};

  if (form && form.form_fields) {
    const formValues = parseSubmissionValuesForDisplay(parseInitialNestedStateValues(values));

    form.form_fields.forEach(form_field => {
      newFormState[form_field.id] = {
        value: formValues[form_field.id],
        error: ''
      };
    });
  }

  return newFormState;
};

/*
   Prepare values for submission based on current state and the form. Also checks for errors
   When there are errors, it returns the new state with the errors
*/

export const prepareValuesForSubmission = (state, form) => {
  const { hasError, newState } = validateFields(state, form);

  if (hasError) {
    return { hasError, result: newState };
  }

  const stateFormValues = {};
  Object.keys(state).forEach(el => {
    stateFormValues[el] = state[el].value;
  });

  const parsedFormValues = [];
  form.form_fields.forEach(form_field => {
    const fieldTypeLabel = getFieldTypeLabel(form_field);
    const formFieldId = getFormFieldID(form_field);

    if (formFieldValueParser[fieldTypeLabel]) {
      parsedFormValues.push({
        id: formFieldId,
        value: formFieldValueParser[fieldTypeLabel](stateFormValues, formFieldId)
      });
    } else {
      parsedFormValues.push({
        id: formFieldId,
        value: stateFormValues[formFieldId]
      });
    }
  });

  const params = {
    values: parsedFormValues.filter(({ value }) => value !== undefined),
    id: form.id
  };

  return { hasError: false, result: params };
};

export const parseSubmissionValuesForDisplay = values => {
  return (values || []).reduce((acc, cur) => {
    acc[cur.id] = cur.value;

    return acc;
  }, {});
};

export const parseSubmissionPreviousValuesForDisplay = values => {
  return (values || []).reduce((acc, cur) => {
    acc[cur.id] = { values: cur.values };

    return acc;
  }, {});
};

export const parseSubmissionWarningsForDisplay = values => {
  return (values || []).reduce((acc, cur) => {
    acc[cur.id] = cur.warning;

    return acc;
  }, {});
};

export const initFormFieldsStructure = (formFields = []) => {
  const groupedFields = formFields.filter(formField => !formField.parent_id);
  const subFields = _groupBy(
    formFields.filter(formField => formField.parent_id),
    formField => formField.parent_id
  );

  const transformedFields = [];

  groupedFields.forEach(formField => {
    const fieldTypeLabel = getFieldTypeLabel(formField);
    const formFieldParams = getFormFieldParams(formField) || {};
    const formFieldId = getFormFieldID(formField);

    switch (fieldTypeLabel) {
      case FORM_FIELD_TYPES.table_with_fixed_rows.label:
        if (formFieldParams) {
          const { row_count } = formFieldParams;

          const transformedField = {
            ...formField,
            form_field_params: { ...formFieldParams, rows: Array.from(Array(row_count).keys()) }
          };

          transformedField.form_field_params.fields = _get(subFields, formFieldId, []).reduce(
            (acc, cur) => {
              acc[`${cur.sort_index}_${cur.column_index}`] = cur;

              return acc;
            },
            {}
          );

          transformedFields.push(transformedField);
        }
        break;

      case FORM_FIELD_TYPES.table_with_dynamic_rows.label:
        if (formFieldParams) {
          const transformedField = {
            ...formField,
            form_field_params: { ...formFieldParams, rows: [] }
          };

          transformedField.form_field_params.fields = _get(subFields, formFieldId, []).reduce(
            (acc, cur) => {
              const sort_index = cur.sort_index;

              acc[`${sort_index}_${cur.column_index}`] = {
                ...cur,
                old_column_index: cur.column_index
              };

              if (!transformedField.form_field_params.rows.includes(sort_index)) {
                transformedField.form_field_params.rows.push(sort_index);
              }

              return acc;
            },
            {}
          );

          if (!transformedField.form_field_params.rows?.length)
            transformedField.form_field_params.rows = [0];

          transformedFields.push(transformedField);
        }

        break;

      default:
        transformedFields.push(formField);
        break;
    }
  });

  return transformedFields;
};

const shouldValidateField = (formField, formFields, state) => {
  if (formField.parent_id) {
    const parentFormField = formFields.find(f => f.id === formField.parent_id);
    const parentFormFieldID = getFormFieldID(parentFormField);
    const parentFieldTypeLabel = getFieldTypeLabel(parentFormField);

    const fieldRowIndex = getFormFieldSortIndex(formField);

    switch (parentFieldTypeLabel) {
      case FORM_FIELD_TYPES.table_with_dynamic_rows.label:
        // Do not validate rows that are not visible
        const visibleRows = _get(state, `[${parentFormFieldID}].value.row_count`, 1);

        return fieldRowIndex + 1 <= visibleRows;

      default:
        return true;
    }
  }

  return true;
};

const validateFields = (state, form) => {
  const tempState = {};
  let hasError = false;

  form.form_fields.forEach(formField => {
    const isRequired = getFormFieldIsRequired(formField);
    const fieldTypeLabel = getFieldTypeLabel(formField);
    const formFieldId = getFormFieldID(formField);

    if (shouldValidateField(formField, form.form_fields, state)) {
      if (fieldsValidator[fieldTypeLabel]) {
        const error = fieldsValidator[fieldTypeLabel](state[formFieldId].value, isRequired);

        if (error) {
          tempState[formFieldId] = { ...state[formFieldId], error };
          hasError = true;
        }
      } else {
        const isRequiredWithoutValue = isRequired && !state[formFieldId].value;

        if (isRequiredWithoutValue) {
          tempState[formFieldId] = { ...state[formFieldId], error: REQUIRED_FIELD_MESSAGE };
          hasError = true;
        }
      }
    } else {
      tempState[formFieldId] = { ...state[formFieldId], error: '' };
    }
  });

  return { hasError, newState: { ...state, ...tempState } };
};

const formFieldValueParser = {
  [FORM_FIELD_TYPES.drill_dropdown.label]: (values, formFieldId) => {
    return (values[formFieldId] || []).map(el => el.id);
  },
  [FORM_FIELD_TYPES.training_dropdown.label]: (values, formFieldId) => {
    return (values[formFieldId] || []).map(el => el.id);
  },
  [FORM_FIELD_TYPES.boolean.label]: (values, formFieldId) => {
    return values[formFieldId] === null ? false : values[formFieldId];
  },
  [FORM_FIELD_TYPES.attachments.label]: (values, formFieldId) => {
    return (values[formFieldId] || []).map(el => el.id);
  },
  [FORM_FIELD_TYPES.crew_participants.label]: (values, formFieldId) => {
    return values[formFieldId]
      ? values[formFieldId]
          .filter(el => el.crew_member)
          .map(el => ({
            notes: el?.notes || '',
            crew_rank_id: el?.crew_rank?.id,
            crew_member_id: el?.crew_member?.id
          }))
      : undefined;
  },
  [FORM_FIELD_TYPES.crew_with_rank.label]: (values, formFieldId) => {
    return values[formFieldId]?.crew_member
      ? {
          crew_rank_id: values[formFieldId].crew_rank?.id || null,
          crew_member_id: values[formFieldId].crew_member?.id || null
        }
      : undefined;
  },
  [FORM_FIELD_TYPES.place.label]: (values, formFieldId) => {
    return values[formFieldId]
      ? {
          port_id: values[formFieldId].port_id || null,
          lat: values[formFieldId].lat || null,
          lon: values[formFieldId].lon || null
        }
      : undefined;
  },
  [FORM_FIELD_TYPES.vessels_dropdown.label]: (values, formFieldId) => {
    return { vessels: (values[formFieldId] || []).map(v => (v.id ? v.id : v)) };
  },
  [FORM_FIELD_TYPES.departments_dropdown.label]: (values, formFieldId) => {
    return { departments: (values[formFieldId] || []).map(d => (d.id ? d.id : d)) };
  },
  [FORM_FIELD_TYPES.number.label]: (values, formFieldId) => {
    return strToNumber(values[formFieldId]);
  },
  [FORM_FIELD_TYPES.inventory_survey_rob.label]: (values, formFieldId) => {
    return strToNumber(values[formFieldId]);
  },
  [FORM_FIELD_TYPES.office_user.label]: (values, formFieldId) => {
    return values[formFieldId]?.id || null;
  },
  [FORM_FIELD_TYPES.dates_multiple.label]: (values, formFieldId) => {
    return (values[formFieldId] || []).filter(d => d);
  },
  [FORM_FIELD_TYPES.root_cause_analysis.label]: (values, formFieldId) => {
    return { root_causes: (values[formFieldId] || []).map(el => el.id) };
  },
  [FORM_FIELD_TYPES.dropdown_multiple_select.label]: (values, formFieldId) => {
    return (values[formFieldId] || []).filter(d => d);
  },
  [FORM_FIELD_TYPES.findings.label]: (values, formFieldId) => {
    if (values[formFieldId]?.name) {
      const { id, subcategory, category, name } = values[formFieldId];

      return {
        name,
        finding_category_id: category?.id,
        finding_subcategory_id: subcategory?.id,
        finding_id: id || undefined
      };
    }

    return null;
  }
};

const fieldsValidator = {
  [FORM_FIELD_TYPES.attachments.label]: (value, isRequired) => {
    const isEmpty = value && value.length === 0;
    return isRequired && isEmpty ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.crew_participants.label]: (value = [], isRequired) => {
    if (isRequired && !value.filter(el => el.crew_member).length) {
      return REQUIRED_FIELD_MESSAGE;
    } else if (value.some(el => !el.crew_member && (el.crew_rank || el.notes))) {
      return 'Name is required in all fields';
    }
  },
  [FORM_FIELD_TYPES.date_range.label]: (value, isRequired) => {
    const nothasFromValue = value && !value?.from;
    return isRequired && nothasFromValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.boolean.label]: (value, isRequired) => {
    return isRequired && value === null ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.time.label]: (value, isRequired) => {
    const isInvalidValue = !value && value !== 0;

    return isRequired && isInvalidValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.radio_with_details.label]: (value, isRequired) => {
    const isInvalidValue = !value?.option;

    return isRequired && isInvalidValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.dropdown_with_details.label]: (value, isRequired) => {
    const isInvalidValue = !value?.option;

    return isRequired && isInvalidValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.vessels_dropdown.label]: (value, isRequired) => {
    const isInvalidValue = !value?.length;

    return isRequired && isInvalidValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.departments_dropdown.label]: (value, isRequired) => {
    const isInvalidValue = !value?.length;

    return isRequired && isInvalidValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.number.label]: (value, isRequired) => {
    const isInvalidValue = !value && value !== 0;

    return isRequired && isInvalidValue ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.crew_with_rank.label]: (value, isRequired) => {
    return isRequired && !value?.crew_member ? REQUIRED_FIELD_MESSAGE : '';
  },
  [FORM_FIELD_TYPES.place.label]: (value, isRequired) => {
    return isRequired && !(value?.port_id || value?.lat || value?.lon)
      ? REQUIRED_FIELD_MESSAGE
      : '';
  },
  [FORM_FIELD_TYPES.findings.label]: (value, isRequired) => {
    return isRequired && !value?.name ? REQUIRED_FIELD_MESSAGE : '';
  }
};
