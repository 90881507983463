import React, { useState } from 'react';
import { bool, number, func } from 'prop-types';
import { FindingOriginSource } from 'common/entities/findings/FindingTypes';
import { useDispatch } from 'react-redux';
import { deleteFinding } from '../store/actions';
import binIcon from 'common/assets/svg/actions/delete.svg';
import PreventActionButton from 'common/components/buttons/PreventActionButton';

const DeleteFindingAction = ({ isOnBoard, originSource, id, refetchData, onClose }) => {
  const [preventionModalIsOpen, setPreventionModalIsOpen] = useState(false);
  const dispatch = useDispatch();

  const onDelete = async () => {
    try {
      await dispatch(deleteFinding({ id })).unwrap();
      refetchData();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return id && !isOnBoard && !originSource ? (
    <PreventActionButton
      labelClassName="text-red fw-bold fs-14"
      svgClassName="text-red"
      buttonClassName="box-shadow-none me-auto"
      buttonColor="white"
      buttonLabel="Delete"
      buttonIcon={binIcon}
      onButtonClick={() => setPreventionModalIsOpen(true)}
      closeModal={() => setPreventionModalIsOpen(false)}
      onModalAccept={onDelete}
      isModalOpen={preventionModalIsOpen}
      modalProps={{
        header: 'Delete Finding',
        body: 'Are you sure you want to delete this Finding?'
      }}
    />
  ) : null;
};

DeleteFindingAction.propTypes = {
  isOnBoard: bool,
  id: number.isRequired,
  originSource: FindingOriginSource,
  refetchData: func,
  onClose: func
};

export default DeleteFindingAction;
