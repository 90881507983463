import React from 'react';

import UploadFiles from 'common/components/form/inputs/upload-files';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsJobLocked,
  selectJobField,
  selectOfficeUserIsAuthorized,
  selectOnboardUserIsAuthorized
} from 'common/components/jobs/_base/store/selectors';
import Label from './Label';
import { setJobField } from 'common/components/jobs/_base/store/actions';

const Attachments = () => {
  const dispatch = useDispatch();

  const isOnboard = useSelector(state => state.isOnBoard);
  const canEdit = useSelector(state =>
    isOnboard ? selectOnboardUserIsAuthorized(state) : selectOfficeUserIsAuthorized(state)
  );
  const isJobLocked = useSelector(selectIsJobLocked) || !canEdit;
  const attachments = useSelector(state => selectJobField(state, 'vessel_attachments'));

  return (
    <div className="bg-light-gray rounded-lg p-2 h-100p">
      <Label title="VESSEL ATTACHMENTS" />
      <UploadFiles
        group="jobs.attachments"
        files={attachments}
        onChange={files => dispatch(setJobField('vessel_attachments', files, true))}
        viewOnly={isJobLocked}
        size="sm"
      />
    </div>
  );
};

export default Attachments;
