import React from 'react';
import Fade from 'react-reveal/Fade';
import ProfileDataBox from 'common/components/layouts/profile-data-box';

const Address = ({ config, data }) => {
  return (
    <Fade>
      <ProfileDataBox profile={data} sections={config} />
    </Fade>
  );
};

export default Address;
