import React from 'react';

const EvaluationDaysLeft = ({ data, className = '' }) => {
  const { is_overdue, is_approaching_overdue, days_left } = data;
  const colorClassName = is_overdue ? 'text-red' : is_approaching_overdue ? 'text-warning' : '';

  return <span className={`${colorClassName} ${className} `}>{days_left} days</span>;
};

export default EvaluationDaysLeft;
