import React, { useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';
import PropTypes from 'prop-types';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import * as listActions from 'store/lists/actions';

export const notAllowedTypes = {
  noon: ['departure', 'port_noon'],
  arrival: ['arrival', 'noon', 'instructed_speed'],
  departure: ['departure', 'port_noon'],
  port_noon: ['arrival', 'noon', 'instructed_speed']
};

export const filterReportTypes = (lastReport, options) => {
  if (lastReport && lastReport.template && lastReport.template.type) {
    return options.filter(el => !notAllowedTypes[lastReport.template.type].includes(el.type));
  } else {
    return options;
  }
};

const ListSelect = ({ list, isAsync, lastReport, ...rest }) => {
  const listOptions = useSelector(state => state.lists[list]?.options);
  const options = filterReportTypes(lastReport, listOptions); // Filter report template-types based on the last submitted report

  const isFetching = useSelector(state => state.lists[list]?.isFetching);
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const getListingParams = () => {
    let listParams = rest.listParams;
    if (listParams) {
      return listParams;
    }
    let params = rest.field ? { field: rest.field } : null;

    switch (rest.field) {
      default:
        return params;
    }
  };

  const fetchOptions = search => {
    return getAsyncOptions(search, list, getListingParams());
  };

  const getInitialOptions = () => {
    return getInitialAsyncValues(list, rest.id || null, rest.isMulti || null, getListingParams());
  };

  const selectProps = isAsync
    ? {
        isAsync,
        loadOptions: search => fetchOptions(search),
        defaultOptions: getInitialOptions,
        ...rest
      }
    : { options: options, ...rest };

  useEffect(() => {
    if (!isAsync && !isFetching) {
      fetchListOptions(list, '', getListingParams());
    }
  }, []);

  return (
    <Select
      getOptionValue={option => option.id || option.value}
      getOptionLabel={option => option.name}
      {...selectProps}
    />
  );
};

ListSelect.propTypes = {
  list: PropTypes.string.isRequired
};

export default ListSelect;
