import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import archive from 'common/assets/svg/actions/archive.svg';
import SvgRender from 'common/components/general/SvgRender';
import { getApiBaseUrl } from 'utils/api';
import { useSelector } from 'react-redux';

const ProfileImage = ({
  image,
  fallback,
  size,
  rounded,
  square,
  className = '',
  archived,
  imgProps,
  style,
  dataCy
}) => {
  const [display, setDisplay] = useState(null);
  const [imageError, setImageError] = useState(false);

  const isOnBoard = useSelector(state => state.isOnBoard);

  useEffect(() => {
    if (!image) {
      setDisplay(null);
    } else {
      if (isOnBoard) {
        const baseUrl = getApiBaseUrl();

        setDisplay(!image.includes(baseUrl) ? baseUrl + image : image);
      } else {
        setDisplay(image);
      }
    }
  }, [image]);

  return (
    <div
      className={`profile-image-wrap${square ? ' square' : ''}${
        imageError || !display ? ' d-flex justify-content-center image-fallback' : ''
      } ${
        rounded ? `rounded ${!className.includes('rounded-') ? 'rounded-circle' : ''}` : ''
      } ${className} ${size ? `profile-image-wrap--resized profile-image-wrap--size-${size}` : ''}`}
      style={style}
      data-cy={dataCy}
    >
      {archived && (
        <div
          className={`archived-cover d-flex justify-content-center align-items-center ${
            display ? 'opacity-with-image' : 'opacity-default'
          }`}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <SvgRender src={archive} style={{ height: 36, width: 26 }} />
            <div className="mt-1 fw-bold fs-14">ARCHIVED</div>
          </div>
        </div>
      )}
      <img
        alt="preview"
        src={display ? display : fallback}
        className={`${rounded ? `rounded-circle` : ''}`}
        onError={() => {
          setDisplay(fallback);
          setImageError(true);
        }}
        {...imgProps}
      />
    </div>
  );
};

export default ProfileImage;

ProfileImage.propTypes = {
  image: PropTypes.string,
  size: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 'sm', 'md', 'm', 'lg', 'xl']), // xxxs => $size16 || xxs => $size40 | xs => $size56 | sm => $size72 | md => $size88 | m => $size92 | lg => $size104 | xl => $size120
  fallback: PropTypes.string,
  square: PropTypes.bool,
  style: PropTypes.object // use style to add custom width/height
};
