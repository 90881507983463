import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import SubStep from './SubStep';
import StepWrapper from '../components/StepWrapper';
import CircledButton from 'common/components/buttons/CircledButton';

import { canUserAddMoreDynamicSteps } from '../store/selectors';

import * as reviewProcessActions from 'common/components/review-process/store/actions';

import _sortBy from 'lodash/sortBy';

const ReviewProcessStep = ({
  step,
  steps,
  index,
  entityId,
  loading,
  onFormSelect,
  refetchDrawer,
  collapsable = false
}) => {
  const { id, name, is_dynamic, substeps, is_required } = step;

  const [addReviewProcessSubstep] = useActions([reviewProcessActions.addReviewProcessSubstep]);

  const [isLoading, setIsLoading] = useState(false);

  const canAddMoreDynamicSubsteps = useSelector(state =>
    canUserAddMoreDynamicSteps(state, entityId)
  );

  const addSubstep = async () => {
    setIsLoading(true);

    try {
      await addReviewProcessSubstep({ step_id: id });

      await refetchDrawer();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <StepWrapper
      title={name}
      index={index}
      substeps={substeps}
      steps={steps}
      collapsable={collapsable}
    >
      {_sortBy(substeps, s => s.id).map((subStep, i) => (
        <SubStep
          key={subStep?.id}
          index={i}
          entityId={entityId}
          subStep={subStep}
          loading={loading}
          stepId={id}
          onFormSelect={onFormSelect}
          isDynamic={is_dynamic}
          refetchDrawer={refetchDrawer}
          stepIndex={index}
          isRequired={is_required}
          steps={steps}
        />
      ))}
      {is_dynamic && !canAddMoreDynamicSubsteps ? (
        <CircledButton
          type="add"
          label="Add group"
          disabled={isLoading}
          onClick={addSubstep}
          svgStyle={{ width: 8, height: 8 }}
          style={{ width: 15, height: 15 }}
          className="fs-12 fw-bold text-primary"
        />
      ) : null}
    </StepWrapper>
  );
};

export default ReviewProcessStep;
