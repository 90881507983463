import {
  stringField,
  dateField,
  booleanField,
  hiddenField,
  optionField
} from 'common/utils/form/fieldTypes';

const config = {
  rank_id: hiddenField(),
  rank_name: stringField(), // Not needed in request
  vessel_id: hiddenField(),
  vessel_name: stringField(), // Not needed in request
  date: dateField(),
  template_id: hiddenField(),
  crew_evaluation_obligation_id: hiddenField(),
  crew_evaluation_reason_id: hiddenField(),
  captain_notes: stringField(),
  evaluator_notes: stringField(),
  submission_notes: stringField(),
  evaluatee_notes: stringField(),
  office_only_notes: stringField(),
  training_types: optionField(),
  criteria: {
    sort_index: hiddenField(),
    criterion_id: hiddenField(),
    evaluate: booleanField({ initialValue: true }),
    value: stringField(),
    remarks: stringField(),
    category_id: hiddenField(), // Not needed in request
    name: stringField(), // Not needed in request
    value_type: stringField(), // Not needed in request,
    is_required: booleanField()
  },
  recommended_for_promotion: booleanField({ initialValue: null }),
  recommended_for_reemployment: booleanField({ initialValue: null }),
  recommended_for_promotion_comment: stringField(),
  recommended_for_reemployment_comment: stringField()
};

export default config;
