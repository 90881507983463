import { FC, ReactNode } from 'react';
import { displayFormFieldValue } from '@/ts-common/utils/form/display';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import { DisplayInputValueType } from '@/ts-common/types/forms';

type FormFieldPreviewerProps = {
  label?: ReactNode | string;
  className?: string;
  value?: number | string | null;
  type?: DisplayInputValueType;
};

const FormFieldPreviewer: FC<FormFieldPreviewerProps> = ({
  label,
  className = '',
  value,
  type = 'string'
}) => {
  return (
    <FormGroupWrap className={className} label={label}>
      <div className="fs-12 lh-base text-primary fw-medium">
        {displayFormFieldValue(value, type)}
      </div>
    </FormGroupWrap>
  );
};

export default FormFieldPreviewer;
