import InputTable from 'common/components/input-table';
import { hazardsTableConfig, controlMeasuresTableconfig, contingenciesTableConfig } from './config';
import { useFormState } from 'utils/hooks';
import _sortBy from 'lodash/sortBy';
import RiskAssessmentInfo from '@/common/components/forms/digital/components/risk-assessment/components/RiskAssessmentInfo';

const RiskAssessmentTables = ({ formState, previewMode = false, formField }) => {
  const { subStates, addSubform, removeSubform } = useFormState(formState);

  const primaryHazards = subStates('primary_hazards');
  const additionalHazards = subStates('additional_hazards');
  const controlMeasures = subStates('control_measures');
  const contingencies = subStates('contingencies');

  const onCreateHazard = (isPreview = false) =>
    addSubform('primary_hazards', { preview: isPreview ? true : false });
  const onRemoveHazard = index => removeSubform('primary_hazards', index);

  const onCreateAdditionalHazard = (isPreview = false) =>
    addSubform('additional_hazards', { preview: isPreview ? true : false });
  const onRemoveAdditionalHazard = index => removeSubform('additional_hazards', index);

  const onCreateControlMeasure = (isPreview = false) =>
    addSubform('control_measures', { preview: isPreview ? true : false });
  const onRemoveControlMeasure = index => removeSubform('control_measures', index);

  const onCreateContingency = (isPreview = false) =>
    addSubform('contingencies', { preview: isPreview ? true : false });
  const onRemoveContingency = index => removeSubform('contingencies', index);

  const formFieldParams = formField.form_field_params;

  const tableComponents = [
    { ...formFieldParams.legends, component: <RiskAssessmentInfo previewMode={true} /> },
    {
      ...formFieldParams.summary_of_additional_hazards,
      component: (
        <InputTable
          key="summary_of_additional_hazards"
          previewMode={previewMode}
          className={`mt-2 ${previewMode ? 'pb-0' : ''}`}
          rows={additionalHazards}
          addRow={onCreateAdditionalHazard}
          removeRow={onRemoveAdditionalHazard}
          hideActions={previewMode}
          {...{
            ...hazardsTableConfig,
            title:
              'SUMMARY OF ADDITIONAL HAZARDS (SUGGESTIONS) TO THE RISK ASSESSMENT LIBRARY IDENTIFIED AND CONTROL MEASURES TO BE TAKEN'
          }}
        />
      )
    },
    {
      ...formFieldParams.summary_of_specific_to_the_task_hazards,
      component: (
        <InputTable
          className={previewMode ? 'pb-0' : ''}
          previewMode={previewMode}
          rows={primaryHazards}
          addRow={onCreateHazard}
          removeRow={onRemoveHazard}
          hideActions={previewMode}
          {...hazardsTableConfig}
        />
      )
    },
    {
      ...formFieldParams.contingencies,
      component: (
        <InputTable
          key="contingencies"
          previewMode={previewMode}
          className={`mt-2 ${previewMode ? 'pb-0' : ''}`}
          rows={contingencies}
          addRow={onCreateContingency}
          removeRow={onRemoveContingency}
          hideActions={previewMode}
          {...contingenciesTableConfig}
        />
      )
    },
    {
      ...formFieldParams.action_plan_for_additional_control_measures,
      component: (
        <InputTable
          key="action_plan_for_additional_control_measures"
          previewMode={previewMode}
          className={`mt-2 ${previewMode ? 'pb-0' : ''}`}
          rows={controlMeasures}
          addRow={onCreateControlMeasure}
          removeRow={onRemoveControlMeasure}
          hideActions={previewMode}
          {...controlMeasuresTableconfig}
        />
      )
    }
  ];

  // Sort components by sort_index
  const sortedComponents = _sortBy(
    tableComponents?.filter(c => c?.visible),
    ['sort_index']
  );

  return (
    <div className="mt-2">{sortedComponents.map(sortedComponent => sortedComponent.component)}</div>
  );
};

export default RiskAssessmentTables;
