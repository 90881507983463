import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, put, post, deleteRequest } from 'utils/api';

export const getChecklist = createAsyncThunk(
  'GET_CHECKLIST',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await get(`/events/${event_id}/checklist`, rest);

      return fulfillWithValue(res.data.map(el => ({ ...el, comments: [] })));
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateChecklist = createAsyncThunk(
  'UPDATE_CHECKLIST',
  async (params, { rejectWithValue }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await put(`/events/${event_id}/checklist`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const toggleChecklistOption = createAsyncThunk(
  'TOGGLE_CHECKLIST_OPTION',
  async (params, { rejectWithValue }) => {
    const { event_id, checklist_option_id } = params;

    try {
      const res = await post(`/events/${event_id}/checklist/${checklist_option_id}/toggle`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getComments = createAsyncThunk(
  'GET_COMMENTS',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, checklist_id, ...rest } = params;

    try {
      const res = await get(`/events/${event_id}/checklist/${checklist_id}/comments`, { ...rest });

      return fulfillWithValue({ data: res.data, checklist_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createComment = createAsyncThunk(
  'CREATE_COMMENT',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, checklist_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/checklist/${checklist_id}/comments`, { ...rest });

      return fulfillWithValue({ data: res.data, checklist_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateComment = createAsyncThunk(
  'UPDATE_COMMENT',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, checklist_id, comment_id, ...rest } = params;

    try {
      const res = await put(
        `/events/${event_id}/checklist/${checklist_id}/comments/${comment_id}`,
        { ...rest }
      );

      return fulfillWithValue({ data: res.data, checklist_id, comment_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteComment = createAsyncThunk(
  'DELETE_COMMENT',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, checklist_id, comment_id, ...rest } = params;

    try {
      const res = await deleteRequest(
        `/events/${event_id}/checklist/${checklist_id}/comments/${comment_id}`,
        rest
      );

      return fulfillWithValue({ data: res.data, checklist_id, comment_id });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
