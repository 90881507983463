import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import moment from 'moment';

import { useActions } from 'utils/hooks';

import { numberToStr } from 'common/utils/numbers';
import SvgRender from 'common/components/general/SvgRender';

import * as mgaActions from 'common/components/mga/store/actions';
import { selectLatestPeriod } from 'common/components/mga/store/selectors';
import ColoredTable from 'common/components/colored-table';

import addActionIcon from 'common/assets/svg/actions/add-action.svg';
import edit from 'common/assets/svg/actions/edit.svg';

const PastActions = ({ actionTypeId, accountId }) => {
  const [getMgaActions, setMgaActionForm] = useActions([
    mgaActions.getMgaActions,
    mgaActions.setMgaActionForm
  ]);
  const latestPeriod = useSelector(selectLatestPeriod);
  const [pastActionsData, setPastActionsData] = useState([]);

  const fetchTableData = async () => {
    const filters = [];

    filters.push(
      { name: 'period_id', operation: '=', value: latestPeriod.id },
      { name: 'action_type_id', operation: '=', value: actionTypeId },
      { name: 'account_id', operation: '=', value: accountId }
    );

    const res = await getMgaActions({
      filters,
      sorting: { timestamp: 'asc' }
    });

    setPastActionsData(res?.data);

    return res;
  };

  useEffect(() => {
    fetchTableData();
  }, [actionTypeId, accountId]);

  const columns = [
    {
      header: '',
      key: 'timestamp',
      type: 'date',
      sort: false,
      className: '',
      width: 6
    },
    {
      header: '',
      key: 'amount',
      type: 'string',
      width: 3
    },
    {
      header: 'Actions',
      key: 'actions',
      field: 'actions',
      className: 'text-center'
    }
  ];

  return pastActionsData?.length ? (
    <div className="past-actions mt-4 cpt-6">
      <div className="d-flex align-items-center fs-12 text-primary mb-1 cpb-2">
        <SvgRender src={addActionIcon} style={{ width: 12, height: 12 }} className="me-1" />
        Past Actions: <span className="fw-bold cms-4">{pastActionsData?.length}</span>
      </div>

      <ColoredTable
        hideTableReorder
        hideTableSearch
        hideTableFilters
        hideTopPagination
        hideTableHead
        color="lighter-gray"
        columns={columns}
        rows={{
          timestamp: data => {
            const timestamp = _get(data, 'timestamp');

            return (
              <div className="fw-medium">
                {timestamp ? moment(timestamp).format('DD/MM/YYYY') : '-'}
              </div>
            );
          },
          amount: data => (
            <div className="d-flex align-items-center text-nowrap">
              <div className="text-violet">Amount:</div>
              <div className="cms-4 fw-bold">$ {numberToStr(_get(data, 'amount'), 2, 2)}</div>
            </div>
          ),
          actions: data => ({
            options: [
              {
                wrapperClassName: 'bg-yellow ',
                className: 'text-primary',
                label: 'Edit',
                icon: edit,
                onClick: () => {
                  setMgaActionForm({
                    isOpen: true,
                    active: data
                  });
                }
              }
            ]
          })
        }}
        state={{ data: pastActionsData }}
      />
    </div>
  ) : null;
};

export default PastActions;
