import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from 'utils/hooks';

const PageSaving = ({ isSaving }) => {
  const [stateIsSaved, setStateIsSaved] = useState(null);
  const previousIsSaving = usePrevious(isSaving);

  useEffect(() => {
    if (previousIsSaving && !isSaving) {
      setStateIsSaved(true);

      setTimeout(() => setStateIsSaved(false), 2000);
    }
  }, [isSaving, previousIsSaving]);

  return (
    <>
      <div
        className={`page-saving-loader${isSaving ? ' is-saving' : stateIsSaved ? ' is-saved' : ''}`}
      >
        {isSaving ? (
          <>
            Saving<span>.</span>
            <span>.</span>
            <span>.</span>
          </>
        ) : (
          <>Saved</>
        )}
      </div>
    </>
  );
};

export default PageSaving;

PageSaving.propTypes = {
  isSaving: PropTypes.bool.isRequired
};
