import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PortsAtSea from 'common/components/selectors/port-at-sea';
import VesselPortsSelector from 'common/components/selectors/VesselPortsSelector';
import { SelectLabel } from 'events/components/Select';

import useRouter from 'use-react-router';
import { selectEvent } from 'events/store/events/selectors';
import { changeEventValue } from 'events/store/events/actions';

const Port = ({ vesselId, isCreate, memoizedDebounce, disabled }) => {
  const dispatch = useDispatch();
  const { match } = useRouter();

  const event = useSelector(selectEvent);
  const { port, itinerary_port, port_type } = event;

  const isOnBoard = useSelector(state => state.isOnBoard);

  const onPortChange = name => value => {
    if (name === 'port') {
      const params = { port: value };

      if (value?.code === 'ATSEA') {
        params.port.lon = event.port?.lon || null;
        params.port.lat = event.port?.lat || null;
      }

      dispatch(changeEventValue(params));
    } else if (name === 'lat' || name === 'lon') {
      const params = {
        port: {
          ...event.port,
          lon: event.port?.lon || null,
          lat: event.port?.lat || null,
          [name]: value
        }
      };

      dispatch(changeEventValue(params));
    }
  };

  const autoSaveEventPort = value => {
    if (isCreate) return;
    const params = { id: match.params.id };

    if (port_type === 'port') {
      params.port_id = value?.id || null;
      params.itinerary_port_id = null;

      if (value?.code === 'ATSEA') {
        params.lat = port?.lat;
        params.lon = port?.lon;
      }

      dispatch(changeEventValue({ itinerary_port: null }));
    } else {
      params.port_id = null;
      params.itinerary_port_id = value?.id || null;

      dispatch(changeEventValue({ port: null }));
    }

    memoizedDebounce(params);
  };

  return (
    <div className="mt-2 px-1 mb-3">
      <VesselPortsSelector
        type={port_type}
        label={
          <div className="form-label">
            <SelectLabel type={'port'} />
          </div>
        }
        hiddenTabs={isOnBoard}
        disabledTabs={!vesselId}
        vesselId={vesselId}
        itineraryPort={{ value: itinerary_port, error: null }}
        onChangeItineraryPort={itinerary_port => {
          dispatch(changeEventValue({ itinerary_port }));
          autoSaveEventPort(itinerary_port);
        }}
        onChangeType={port_type => dispatch(changeEventValue({ port_type }))}
      >
        <PortsAtSea
          onlyPort
          groupCoords
          autoFocus={false}
          className="event-port-selector"
          showCoordsInValue={true}
          coordinates={{
            lat: { value: port?.code === 'ATSEA' ? port?.lat : null },
            lon: { value: port?.code === 'ATSEA' ? port?.lon : null }
          }}
          placeholder="Select Port"
          value={port}
          selectField={onPortChange}
          onPortSelect={port => autoSaveEventPort(port)}
          saveBtnTitle="SAVE"
          disabled={disabled}
        />
      </VesselPortsSelector>
    </div>
  );
};

export default Port;
