import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import * as d3 from 'd3';

const DEFAULT_LINE_WIDTH = 7;
const DEFAULT_CORNER_RADIUS = 100;

const DEFAULT_ARC_START_ANGLE = -110;
const DEFAULT_ARC_END_ANGLE = 110;

const DEFAULT_NEEDLE_BASE_WIDTH = 6;
const DEFAULT_NEEDLE_HEAD_WIDTH = 2;
const DEFAULT_NEEDLE_HEAD_LENGTH_PERCENTAGE = 70;

const DEFAULT_INDICATORS = 5;
const DEFAULT_INDICATOR_WIDTH = 2;
const DEFAULT_INDICATOR_HEIGHT = 8;
const DEFAULT_INDICATOR_SPACE = 16;
const DEFAULT_INDICATOR_COLOR = variables.lightBlue;

const DEFAULT_GRADIENT_PERCENT_0 = variables.crimson;
const DEFAULT_GRADIENT_PERCENT_25 = variables.coral;
const DEFAULT_GRADIENT_PERCENT_50 = '#FF8D03';
const DEFAULT_GRADIENT_PERCENT_75 = '#7BF5D7';
const DEFAULT_GRADIENT_PERCENT_100 = variables.turquoise;

const styleDefinitions = {
  gaugeLineWidth: {
    defaultValue: DEFAULT_LINE_WIDTH
  },
  gaugeCornerRadius: {
    defaultValue: DEFAULT_CORNER_RADIUS
  },
  gaugeArcStartAngle: {
    defaultValue: DEFAULT_ARC_START_ANGLE
  },
  gaugeArcEndAngle: {
    defaultValue: DEFAULT_ARC_END_ANGLE
  },
  gaugeNeedleBaseWidth: {
    defaultValue: DEFAULT_NEEDLE_BASE_WIDTH
  },
  gaugeNeedleHeadWidth: {
    defaultValue: DEFAULT_NEEDLE_HEAD_WIDTH
  },
  gaugeNeedleHeadLengthPercentage: {
    defaultValue: DEFAULT_NEEDLE_HEAD_LENGTH_PERCENTAGE
  },
  gaugeIndicators: {
    defaultValue: DEFAULT_INDICATORS
  },
  gaugeIndicatorWidth: {
    defaultValue: DEFAULT_INDICATOR_WIDTH
  },
  gaugeIndicatorHeight: {
    defaultValue: DEFAULT_INDICATOR_HEIGHT
  },
  gaugeIndicatorSpace: {
    defaultValue: DEFAULT_INDICATOR_SPACE
  },
  gaugeIndicatorColor: {
    defaultValue: DEFAULT_INDICATOR_COLOR
  }
};

const deg2rad = deg => {
  return (deg * Math.PI) / 180;
};

const renderPlot = ({ plotID, plot, getPlotStyle, element, schema, getDimensions }) => {
  const getStyle = styleKey => getPlotStyle(styleKey, styleDefinitions[styleKey].defaultValue);
  const range = getStyle('gaugeArcEndAngle') - getStyle('gaugeArcStartAngle');
  const { width, height } = getDimensions();
  const RADIUS = Math.min(width, height) / 2;
  const centerTranslation = `translate(${width / 2} ${height / 2})`;
  const pointerHeadLengthPercent = getStyle('gaugeNeedleHeadLengthPercentage') / 100,
    pointerBaseWidth = getStyle('gaugeNeedleBaseWidth'),
    pointerHeadWidth = getStyle('gaugeNeedleHeadWidth');
  const pointerHeadLength = Math.round(RADIUS * pointerHeadLengthPercent);
  var lineData = [
    [pointerBaseWidth / 2, 0],
    [pointerHeadWidth / 2, -pointerHeadLength],
    [-(pointerHeadWidth / 2), -pointerHeadLength],
    [-(pointerBaseWidth / 2), 0],
    [pointerBaseWidth / 2, 0]
  ];

  const getEndAngle = dataPoint => {
    const ratio = dataPoint[schema.valueKey] / 100;
    return getStyle('gaugeArcStartAngle') + ratio * range;
  };

  const getIsBackground = d => {
    return d.data.isBackground;
  };

  const getGradientIndex = d => {
    if (d.value <= 25) {
      return 1;
    } else if (d.value <= 50) {
      return 2;
    } else if (d.value <= 75) {
      return 3;
    }

    return 4;
  };

  const innerRadius = d => {
    const isBackground = getIsBackground(d);
    const normalInnerRadius = RADIUS - getStyle('gaugeLineWidth');

    if (!isBackground) return normalInnerRadius;

    return normalInnerRadius + getStyle('gaugeLineWidth') / 4;
  };

  const outerRadius = d => {
    const isBackground = getIsBackground(d);
    if (!isBackground) return RADIUS;

    return RADIUS - getStyle('gaugeLineWidth') / 4;
  };

  const drawColors = d3
    .scaleOrdinal()
    .domain(plot.data)
    .range(plot.data.map(dataPoint => dataPoint[schema.colorKey]));

  const accentColor = plot.data.find(d => !d.isBackground)[schema.colorKey] || 'rgba(0,0,0,0)';

  const pie = d3.pie().value(d => d[schema.valueKey]);
  const pieData = pie(plot.data);
  const pointerLine = d3.line();
  const parentElement = d3.select(`#${plotID}`);

  const arc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(deg2rad(getStyle('gaugeArcStartAngle')))
    .endAngle(d => deg2rad(getEndAngle(d)))
    .cornerRadius(getStyle('gaugeCornerRadius'));

  // Positioning the values gauge graph
  parentElement.attr('transform', centerTranslation);

  // Draw indicators
  parentElement
    .select(`#indicator-cutoff_${plotID} rect`)
    .attr('width', getStyle('gaugeIndicatorWidth'))
    .attr('height', RADIUS - getStyle('gaugeIndicatorSpace'))
    .attr('y', RADIUS - getStyle('gaugeIndicatorSpace') - getStyle('gaugeIndicatorHeight'));
  parentElement
    .selectAll('.indicator')
    .attr('width', getStyle('gaugeIndicatorWidth'))
    .attr('height', RADIUS - getStyle('gaugeIndicatorSpace'))
    .attr(
      'transform',
      (_, i) =>
        `translate(-${getStyle('gaugeIndicatorWidth') / 2} 0) rotate(${
          -90 - (i / (getStyle('gaugeIndicators') - 1)) * 180
        } ${getStyle('gaugeIndicatorWidth') / 2} -${getStyle('gaugeIndicatorWidth') / 2})`
    )
    .attr('fill', getStyle('gaugeIndicatorColor'));

  // Draw needle
  parentElement
    .select(`#needle_shadow_${plotID}`)
    .attr('r', pointerBaseWidth / 2)
    .attr('fill', 'rgba(0,0,0,0.5)');
  parentElement
    .select(`#needle_base_${plotID}`)
    .attr('r', pointerBaseWidth / 1.8) // divided by smaller than 2 to be just a tad bit bigger than the shadow
    .attr('fill', accentColor);
  parentElement
    .selectAll(`#needle_${plotID}`)
    .data([lineData])
    .attr('d', pointerLine)
    .attr('transform', `rotate(${getEndAngle(plot.data[1])})`)
    .attr('fill', accentColor);

  // Draw arc
  parentElement
    .selectAll(`.gauge-slices`)
    .data(pieData)
    .attr('width', width)
    .attr('height', height)
    .attr('d', arc)
    .attr('fill', d =>
      schema.linearGradient && !getIsBackground(d)
        ? `url(#grad${getGradientIndex(d)})`
        : drawColors(d.data[schema.colorKey]) || 'rgba(0,0,0,0)'
    );
};

const renderComponent = ({ plotID, plot, getPlotStyle }) => {
  const getStyle = styleKey => getPlotStyle(styleKey, styleDefinitions[styleKey].defaultValue);
  const arrayOfIndicators = Array(getStyle('gaugeIndicators')).fill(null);

  return (
    <>
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_0, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_25, stopOpacity: 1 }} />
        </linearGradient>
        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_0, stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_25, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_50, stopOpacity: 1 }} />
        </linearGradient>
        <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_0, stopOpacity: 1 }} />
          <stop
            offset="33.33%"
            style={{ stopColor: DEFAULT_GRADIENT_PERCENT_25, stopOpacity: 1 }}
          />
          <stop
            offset="66.66%"
            style={{ stopColor: DEFAULT_GRADIENT_PERCENT_50, stopOpacity: 1 }}
          />
          <stop offset="100%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_75, stopOpacity: 1 }} />
        </linearGradient>
        <linearGradient id="grad4" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_0, stopOpacity: 1 }} />
          <stop offset="25%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_25, stopOpacity: 1 }} />
          <stop offset="50%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_50, stopOpacity: 1 }} />
          <stop offset="75%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_75, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: DEFAULT_GRADIENT_PERCENT_100, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <g id={plotID} key={plotID}>
        <circle id={`needle_shadow_${plotID}`} filter="url(#shadow)" />
        {plot.data.map((_, i) => (
          <path key={i} id={`slices_${plotID}_${i}`} className="gauge-slices" />
        ))}
        <clipPath id={`indicator-cutoff_${plotID}`}>
          <rect x="0" />
        </clipPath>
        {arrayOfIndicators.map((_, i) => (
          <rect key={i} className="indicator" clipPath={`url(#indicator-cutoff_${plotID})`} />
        ))}
        <path id={`needle_${plotID}`} />
        <circle id={`needle_base_${plotID}`} />
      </g>
    </>
  );
};

const gauge = {
  renderPlot,
  renderComponent
};

export default gauge;
