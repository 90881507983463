import React from 'react';
import { string } from 'prop-types';

import SvgRender from 'common/components/general/SvgRender';
import vessels from 'common/assets/svg/common/vessels.svg';

const Label = ({ title }) => {
  return (
    <div className="d-flex align-items-center cmb-6">
      <SvgRender src={vessels} style={{ width: 12, height: 12 }} />

      <strong className="text-primary fs-10 ms-1">{title}</strong>
    </div>
  );
};

Label.propTypes = {
  title: string.isRequired
};

export default Label;
