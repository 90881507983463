import React, { useState } from 'react';

import SvgRender from 'common/components/general/SvgRender';
import reset from 'common/assets/svg/actions/reset.svg';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectShifts,
  selectPeriodType,
  selectSelectedRankFilter,
  selectIsLoading
} from 'crew/store/shifts/selectors';
import { useActions } from 'utils/hooks';

import * as shiftDataActions from 'store/shifts-data/actions';

const LoadWorkingScheduleButton = ({ refetchShifts, getShiftParams }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoading = useSelector(selectIsLoading);

  const [isUpdatingShifts, setIsUpdatingShifts] = useState(false);

  const periodType = useSelector(selectPeriodType);
  const selectedRank = useSelector(selectSelectedRankFilter);
  const shifts = useSelector(selectShifts);

  const [loadWorkingScheduleOnEmptyDates] = useActions([
    shiftDataActions.loadWorkingScheduleOnEmptyDates
  ]);

  const onLoad = async () => {
    try {
      setIsUpdatingShifts(true);
      await loadWorkingScheduleOnEmptyDates(getShiftParams());

      setIsUpdatingShifts(false);
      setIsModalOpen(false);

      await refetchShifts(getShiftParams());
    } catch (error) {
      console.log(error);
      setIsUpdatingShifts(false);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="border-start mx-3 height-20"></div>

      <Button
        color="yellow"
        className="d-inline-flex align-items-center py-1 cps-12 cpe-12 text-primary"
        onClick={() => setIsModalOpen(true)}
        disabled={
          isLoading ||
          isUpdatingShifts ||
          !shifts?.length ||
          (periodType === 'monthly' && !selectedRank?.id)
        }
      >
        <SvgRender style={{ height: 15, width: 15 }} src={reset} className="me-1" />
        <span className="fs-12 fw-medium lh-1">Load Schedule on Empty Dates</span>
      </Button>

      <DangerousActionModal
        transparent
        actionIcon={<SvgRender style={{ height: 80, width: 80 }} src={reset} className="me-1" />}
        onAccept={onLoad}
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        actionText={'LOAD'}
        actionHoverColor="primary"
        header={'Load Schedule'}
        body={`Are you sure you want to load schedule on these seamen?`}
      />
    </div>
  );
};

export default LoadWorkingScheduleButton;
