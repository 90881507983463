import React, { useEffect, useState } from 'react';
import CircledButton from 'common/components/buttons/CircledButton';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import AddNewJobLink from './AddNewJobLink';
import FormsLoader from 'common/components/forms/_components/FormsLoader';
import PreviewJob from 'common/components/jobs/_base/components/PreviewJob';

import * as jobLinkedActions from 'common/components/jobs/_base/modules/linked-jobs/store/actions';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import {
  selectJobField,
  selectIsJobLocked,
  isTabActive
} from 'common/components/jobs/_base/store/selectors';

import useRouter from 'use-react-router';
import * as pmsJobsActions from 'common/components/pms/jobs/store/actions';

const JobLinkingPms = ({ hideActions = false, drawerIsOpen }) => {
  const [linkedJobsState, setLinkedJobsState] = useState([]);
  const [linkedJobsOnCreate, setLinkedJobsOnCreate] = useState([]);

  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { history, match } = useRouter();

  const [setActiveJobTab, resetJobProfile, toggleJobDrawer] = useActions([
    jobProfileActions.setActiveJobTab,
    jobProfileActions.resetJobProfile,
    pmsJobsActions.toggleJobDrawer
  ]);

  const editMode = useSelector(state => isTabActive(state, 'linked'));

  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const [getJobLinkedJobs, updateJobLinkedJobs, setLinkedJobs] = useActions([
    jobLinkedActions.getJobLinkedJobs,
    jobLinkedActions.updateJobLinkedJobs,
    jobLinkedActions.setLinkedJobs
  ]);

  const vesselIds = (vessels || [])?.map(e => e?.id);

  const jobHasLinkedJobs = useSelector(state => selectJobField(state, 'has_linked_jobs'));

  const linkedJobs = useSelector(state => selectJobField(state, 'linked')?.data);

  const isJobLocked = useSelector(selectIsJobLocked);

  const jobId = useSelector(state => selectJobField(state, 'id'));

  const isVisible = jobId && (jobHasLinkedJobs || editMode);

  const isVisibleOnCreation = !jobId && (linkedJobsOnCreate?.length || editMode);

  const fetchLinkJobs = async () => {
    try {
      setIsLoading(true);
      const res = await getJobLinkedJobs({ job_id: jobId });
      setLinkedJobsState(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    if (!linkedJobsState.length) {
      setActiveJobTab('linked', false);
    }
  };

  const onChangeInCreateMode = async selected => {
    if (selected.id === 'create') {
      await resetJobProfile();

      if (history.location.pathname.includes('/create')) {
        history.push({
          pathname: `${history.location.pathname.replace('/create', '')}`,
          search: history.location.search
        });
        toggleJobDrawer('true', null);
      } else {
        history.push({
          pathname: `${history.location.pathname}/create`,
          search: history.location.search
        });
      }
    } else {
      setIsLoading(true);

      await setLinkedJobsOnCreate([...linkedJobsOnCreate, selected]);
      setLinkedJobs(
        [...linkedJobsOnCreate, selected]?.map(linked => {
          return { job: linked, type: 'relates' };
        })
      );
      setIsLoading(false);

      setIsAdding(false);
    }
  };

  const onChangeInEditMode = async selected => {
    if (selected.id === 'create') {
      await resetJobProfile();
      history.push({
        pathname: history.location.pathname.replace(`${match.params.jobID}`, `create`),
        search: history.location.search
      });
    } else {
      const res = await updateJobLinkedJobs({
        job_id: jobId,
        linked_jobs: [...linkedJobsState, selected].map(l => {
          return {
            id: l?.job?.id || l?.id,
            type: 'relates'
          };
        })
      });

      if (res) {
        setIsAdding(false);
        fetchLinkJobs();
      }
    }
  };

  const onChange = async selected => {
    if (!jobId) {
      onChangeInCreateMode(selected);
    } else {
      onChangeInEditMode(selected);
    }
  };

  const onDeleteInCreateMode = async selectedId => {
    setLinkedJobsOnCreate(linkedJobsOnCreate.filter(linked => linked?.id !== selectedId));

    if (linkedJobs?.length === 1) {
      setActiveJobTab('linked', true);
    }

    await setLinkedJobs(
      linkedJobsOnCreate
        .filter(linked => linked?.id !== selectedId)
        ?.map(linked => {
          return { job: linked, type: 'relates' };
        })
    );

    setIsAdding(false);
  };

  const onDeleteInEditMode = async selectedId => {
    const filteredState = linkedJobsState.filter(linked => linked.job.id !== selectedId);

    await updateJobLinkedJobs({
      job_id: jobId,
      linked_jobs: filteredState.map(l => {
        return {
          id: l?.job?.id,
          type: 'relates'
        };
      })
    });

    setIsAdding(false);

    await fetchLinkJobs();
  };

  const onDelete = async selectedId => {
    if (!jobId) {
      onDeleteInCreateMode(selectedId);
    } else {
      onDeleteInEditMode(selectedId);
    }
  };

  const navigateToJobPage = pathname => {
    history.push({
      pathname: pathname,
      search: history.location.search
    });
  };

  const onPreviewJob = job => {
    const jobIdParam = match?.params?.jobID || '';
    const pathname = history.location.pathname;

    if (jobId) {
      navigateToJobPage(`${pathname.replace(`${jobIdParam}`, `${job?.id}`)}`);
    } else {
      const constructedPath = pathname.includes('/create')
        ? pathname.replace(`/create`, `/${job?.id}`)
        : pathname + `/${job?.id}`;
      navigateToJobPage(constructedPath);
    }
  };

  useEffect(() => {
    if (jobId ? editMode && !isAdding && !jobHasLinkedJobs : !linkedJobsOnCreate?.length) {
      setIsAdding(true);
    }
  }, [editMode]);

  useEffect(() => {
    if (isVisible && jobId && jobHasLinkedJobs) {
      fetchLinkJobs();
    }
    if (jobHasLinkedJobs && jobId) {
      setActiveJobTab('linked', false);
    }
  }, [isVisible, jobId]);

  useEffect(() => {
    setIsAdding(false);
  }, [jobId]);

  useEffect(() => {
    if (jobId && !linkedJobsState.length && !jobHasLinkedJobs && !isAdding && editMode) {
      setActiveJobTab('linked', false);
    }
  }, [linkedJobsState?.length, jobId]);

  useEffect(() => {
    if (!drawerIsOpen) {
      setLinkedJobsOnCreate([]);
    }
  }, [drawerIsOpen]);

  if (jobId && !isVisible) return null;

  if (!jobId && !isVisibleOnCreation) return null;

  return (
    <div className={`job-forms mb-4  ${hideActions ? 'hide-actions' : ''}`}>
      <div className="d-flex align-items-center justify-content-between form-label me-1 mb-1">
        LINKED JOBS
      </div>

      {linkedJobsState.length || linkedJobs?.length ? (
        <>
          {(jobId ? linkedJobsState : linkedJobs).map((linked, index) => (
            <PreviewJob
              backgroundColor="bg-white"
              key={index}
              className="bg-white border-radius-5"
              job={linked?.job}
              onDelete={onDelete}
              jobId={jobId}
              isPms={true}
              onView={onPreviewJob}
            />
          ))}
        </>
      ) : null}

      <FormsLoader isLoading={isLoading} />

      {isAdding ? (
        <AddNewJobLink
          setIsAdding={setIsAdding}
          vesselIds={vesselIds}
          onChange={onChange}
          linkedJobsState={jobId ? linkedJobsState : linkedJobs || linkedJobsOnCreate}
          jobHasLinkedJobs={
            jobId ? jobHasLinkedJobs : linkedJobs?.length || linkedJobsOnCreate?.length
          }
          onCancel={onCancel}
          key={vesselIds}
        />
      ) : null}

      <CircledButton
        type="add"
        className={`text-primary fw-bold fs-12 lh-1 mt-1`}
        label="Add more"
        svgStyle={{ width: 8, height: 8 }}
        style={{ width: 20, height: 20 }}
        disabled={isAdding}
        onClick={() => setIsAdding(true)}
      />
    </div>
  );
};

export default JobLinkingPms;
