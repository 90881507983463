import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from 'reactstrap';
import ProfileImage from '../components/ProfileImage';
import DepartmentParties from 'common/components/person/components/department-parties';
import PrimaryDetails from 'common/components/person/components/primary-details';

import VerticalTabView from 'common/components/layouts/vertical-tab-view';
import ActiveTabViewActions from 'common/components/layouts/vertical-tab-view/components/ActiveTabViewActions';

import { selectPersonSecurity, selectPersonProfile } from 'store/persons/selectors';
import { isAuthorized } from 'utils/permissions/authorize';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';
import useRouter from 'use-react-router';

const PersonProfile = ({ tabsConfig, leftSideInfoConfig, actions, mode, setMode }) => {
  const { match } = useRouter();
  const account = useSelector(selectAccount);

  const [active, setActive] = useState(
    isAuthorized(account, [
      permissions.office.settings.application_access.notification_preferences.edit
    ]) && tabsConfig.find(tab => match.url.includes(tab.path))
      ? tabsConfig.find(tab => match.url.includes(tab.path))
      : tabsConfig[0]
  );

  const isOnBoard = useSelector(state => state.isOnBoard);
  const profile = useSelector(selectPersonProfile);
  const security = useSelector(selectPersonSecurity);

  const onCloseEditMode = useCallback(() => setMode('view'), [setMode]);
  const setEditMode = useCallback(() => setMode('edit'), [setMode]);
  const onTabClick = useCallback(item => setActive(item), []);

  const renderFormComponent = () => {
    if (mode === 'view' || !active || !active?.form) return null;

    return (
      <active.form
        person={profile}
        config={active?.componentProps?.config}
        onCloseEditMode={onCloseEditMode}
        mode={mode}
        setMode={setMode}
      />
    );
  };

  return (
    <div className="person-profile-layout">
      <Row>
        <Col xs={3} className="d-flex flex-column h-50p position-relative">
          <div className="d-flex h-100p">
            <ProfileImage person={profile} onUpdate={actions?.update} />
            <PrimaryDetails className="flex-1 cms-12" profile={profile} mode={mode} />
          </div>

          {isOnBoard ? null : (
            <DepartmentParties className="cmt-12" profile={profile} mode={mode} />
          )}

          <div className="cmt-12">
            {leftSideInfoConfig.map(c => (
              <c.component person={profile} key={c.path} mode={mode} disabled={mode === 'create'} />
            ))}
          </div>
        </Col>
        <Col xs={9} className="position-relative">
          {mode === 'view' &&
          !isOnBoard &&
          active?.form &&
          isAuthorized(
            account,
            active.editPermissions ?? [permissions.office.contacts.persons.profile.edit]
          ) ? (
            <ActiveTabViewActions
              mode={mode}
              onCloseEditMode={onCloseEditMode}
              onEditClick={setEditMode}
            />
          ) : null}

          <VerticalTabView
            onEmptyButtonCLick={isOnBoard ? null : setEditMode}
            data={{ ...profile, security }}
            onClick={onTabClick}
            disabled={mode !== 'view'}
            className={`h-100p ${mode !== 'view' ? 'tab-view-edit' : ''}`}
            selected={active}
            config={tabsConfig}
            renderComponent={mode === 'view' ? null : renderFormComponent}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PersonProfile;
