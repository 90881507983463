import PropTypes from 'prop-types';
import Survey from './templates/Survey';
import FlagDispensation from './templates/FlagDispensation';
import Input from '@/ts-common/components/form/inputs/Input';
import { DOCUMENT_TEMPLATES } from 'common/components/vessels/profile/tabs/documents/helpers';

const TemplateFields = ({ template, fields, selectField, changeField }) => {
  const renderTemplate = () => {
    switch (template) {
      case DOCUMENT_TEMPLATES.surveys:
        return <Survey fields={fields} selectField={selectField} />;
      case DOCUMENT_TEMPLATES.flag_dispensations:
        return (
          <FlagDispensation fields={fields} selectField={selectField} changeField={changeField} />
        );
      case DOCUMENT_TEMPLATES.item_certificate:
        return (
          <Input
            label="Serial Number"
            onChange={changeField('serial_number')}
            {...fields.serial_number}
          />
        );
      default:
        return;
    }
  };

  return renderTemplate();
};

TemplateFields.propTypes = {
  template: PropTypes.string,
  fields: PropTypes.object.isRequired,
  selectField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired
};

export default TemplateFields;
