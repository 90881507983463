import React, { FC, PropsWithChildren } from 'react';
import { moduleIcons } from 'events/event/modules/config';
import ModuleIcon from './ModuleIcon';
import info from 'common/assets/svg/common/info-line.svg';
import SvgRender from 'common/components/general/SvgRender';

type ModuleWrapperProps = PropsWithChildren<{
  className?: string;
  titleClassName?: string;
  type: keyof typeof moduleIcons;
  action?: React.ReactNode | null;
  infoText?: string;
}>;

const ModuleWrapper: FC<ModuleWrapperProps> = ({
  children,
  className = '',
  titleClassName = '',
  type,
  action = null,
  infoText = '',
  ...rest
}) => {
  const moduleInfo = moduleIcons[type];

  const firstLetter = moduleInfo.title.startsWith('LINKED ')
    ? moduleInfo.title.split(' ')[1][0]
    : moduleInfo.title[0];

  return (
    <div className={`event-module-wrapper ${className} mt-3`} {...rest}>
      <div className="d-flex align-items-start justify-content-between">
        <div className={`d-flex align-items-center ${titleClassName || 'cmb-14'}`}>
          <ModuleIcon letter={firstLetter} color={moduleInfo.color} />
          <div className="ms-1 text-primary fs-10 narrow-black">{moduleInfo.title}</div>
          {infoText ? (
            <div className="d-flex align-items-center text-violet cms-12">
              <SvgRender src={info} style={{ height: 13, width: 13 }} />
              <span className="fs-10 ms-1">{infoText}</span>
            </div>
          ) : null}
        </div>
        {action ? action : null}
      </div>

      {children}
    </div>
  );
};

export default ModuleWrapper;
