import FORMS_TYPES from './types';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case FORMS_TYPES.ADD_FORM_TO_JOB.SUCCESS:
      return { ...state, has_forms: true };

    case FORMS_TYPES.GET_JOB_FORMS.SUCCESS:
      return {
        ...state,
        jobForms: {
          ...state.jobForms,
          forms: payload.map(({ id, form }) => ({
            job_form_id: id,
            ...form
          })),
          formSubmissions: payload.reduce((acc, { id, form_submission }) => {
            acc[id] = form_submission || null;

            return acc;
          }, {})
        },
        has_forms: payload?.length ? true : false
      };

    case FORMS_TYPES.UPDATE_JOB_FORM_SUBMISSION:
      return {
        ...state,
        jobForms: {
          ...state.jobForms,
          formSubmissions: {
            ...state.jobForms.formSubmissions,
            [payload.job_form_id]: payload.form_submission
          }
        }
      };

    case FORMS_TYPES.ADD_JOB_FORM_TO_STATE:
      if (payload.form)
        return {
          ...state,
          jobForms: {
            ...state.jobForms,
            forms: [...state.jobForms.forms, payload.form]
          },
          has_forms: true
        };

      return state;

    case FORMS_TYPES.REMOVE_JOB_FORM_FROM_STATE:
      const forms = state.jobForms.forms.filter(f =>
        payload?.job_form_id ? f.job_form_id !== payload?.job_form_id : f.uid !== payload?.form_uid
      );

      return {
        ...state,
        has_forms: forms.length ? true : false,
        jobForms: {
          ...state.jobForms,
          forms
        }
      };

    case FORMS_TYPES.REMOVE_JOB_FORMS_FROM_STATE:
      return {
        ...state,
        has_forms: false,
        jobForms: {
          ...state.jobForms,
          expandedForms: [],
          forms: [],
          formSubmissions: {}
        }
      };

    case FORMS_TYPES.SET_EXPANDED_FORM:
      return {
        ...state,
        jobForms: {
          ...state.jobForms,
          expandedForms: !payload.expanded
            ? state.jobForms.expandedForms.filter(id => id !== payload.job_form_id)
            : [...state.jobForms.expandedForms, payload.job_form_id]
        }
      };

    case FORMS_TYPES.EXPANDED_ALL_FORMS:
      return {
        ...state,
        jobForms: {
          ...state.jobForms,
          expandedForms: !payload ? [] : state.jobForms.forms.map(f => f.job_form_id)
        }
      };

    default:
      return state;
  }
};

export default reducer;
