import moment from 'moment';
import _get from 'lodash/get';
import { strToNumber } from 'common/utils/numbers';

export const getSystemRunningHoursErrors = (
  vesselSystem,
  previousReportTimestamp,
  currentTimestamp
) => {
  const previousRunningHours = strToNumber(_get(vesselSystem, 'previousRunningHours', null));
  const currentValue = _get(vesselSystem, 'vessel_system_total_running_hours.value', null);
  const hoursBetweenReports =
    previousReportTimestamp && currentTimestamp
      ? moment(moment.utc(currentTimestamp)).diff(moment.utc(previousReportTimestamp), 'hours')
      : null;

  let error = false;

  const lessWarning = previousRunningHours && currentValue && currentValue < previousRunningHours;

  const differenceWarning =
    previousRunningHours &&
    currentValue &&
    hoursBetweenReports &&
    previousRunningHours + hoursBetweenReports + 24 < currentValue;

  if (lessWarning || differenceWarning) error = true;

  return error;
};

export const runningHoursInputsAreValid = data => {
  return data.every(value => value || value === 0);
};
