import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const TemplateSelector = ({ filter, onChange, isMulti, listParams, showDefaultOptions = true }) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'job-templates', listParams },
    { selected: selectedTemplates, setSelected: setSelectedTemplates }
  );

  return (
    <Select
      autoFocus
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      defaultOptions={() =>
        showDefaultOptions ? getInitialAsyncValues('job-templates', null, null, listParams) : []
      }
      isMulti={isMulti}
      value={getFilterSelectorValues(selectedTemplates, filter?.value)}
      loadOptions={search => getAsyncOptions(search, 'job-templates', listParams)}
      onChange={selected => {
        setSelectedTemplates(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
    />
  );
};

export default TemplateSelector;
