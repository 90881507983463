import TYPES from './types';
import { get } from 'utils/api';
import {
  getDefaultListKey,
  updateDefaultListOptions,
  updateDefaultListTimestamp
} from 'common/utils/lists';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

const aliases = {
  'captain-report-enums-boiler-colors': 'captain-report-enums&field=blr_color',
  'captain-report-enums-instructed-speed-consumption-by':
    'captain-report-enums&field=instructed_speed_consumption_by',
  'captain-report-enums-instructed-speed-consumption-target':
    'captain-report-enums&field=instructed_speed_consumption_target',
  'captain-report-enums-seabed-types': 'captain-report-enums&field=seabed_type',
  units: 'units',
  'purpose-of-call': 'identifiers&type=purpose-of-call',
  'berth-type': 'identifiers&type=berth-type',
  'ld-stopping-reason': 'captain-report-enums&field=cargo_operation_stoppage_reason_id',
  'ld-hired-gear-type': 'captain-report-enums&field=hired_gear_type_id',
  'shift-types': 'crew-shift-types',
  'stoppage-reason': 'captain-report-enums&field=stoppage_reason',
  'stoppage-associated-element': 'captain-report-enums&field=stoppage_associated_element',
  'status-at-port': 'captain-report-enums&field=status_at_port',
  'tank-measurement-method': 'captain-report-enums&field=bs_tank_measurement_method',
  'party-and-crew-departments': 'departments&include_crew_departments=true',
  'captain-report-drifting-reason': 'captain-report-enums&field=drifting_reason',
  'captain-report-enums-delivered-at': 'captain-report-enums&field=delivered_at',
  'captain-report-enums-holds-on-delivery': 'captain-report-enums&field=holds_on_delivery',
  'captain-report-enums-instructed-speed-allowance-unit':
    'captain-report-enums&field=instructed_speed_allowance_unit',
  'captain-report-enums-instructed-consumption-allowance-unit':
    'captain-report-enums&field=instructed_consumption_allowance_unit',
  'captain-report-enums-phase-shift': 'captain-report-enums&field=dg_power_factor_phase_shift',
  'captain-report-enums-co-base-number': 'captain-report-enums&field=co_base_number',
  'purchasing-spare-parts': 'purchasing-items&type=spare_part',
  'purchasing-stores': 'stores&limit=50&includes[0]=store_group'
};

const customEndpointAliases = {
  'captain-report-templates': '/captain-reports/templates',
  'mga-accounts': '/mga/accounts',
  'reports-mga': '/reports',
  'reports-event': '/reports',
  reports: 'reports'
};

export const getListUrl = list => {
  const hasCustomUrl = customEndpointAliases[list];

  return !hasCustomUrl
    ? `/lists?list=${_get(aliases, list, list)}`
    : `${customEndpointAliases[list]}`;
};

export const fetchListOptions =
  (list, search = '', params = {}) =>
  (dispatch, getState) => {
    const url = `${getListUrl(list)}${search ? `&search=${search}` : ''}`;

    const defaultLists = getState()?.lists?._defaults;
    const defaultListKey = getDefaultListKey(list, params);
    const listState = getState()?.lists?.[list];

    const { lastFetchedAt, isFetching, options } = defaultLists?.[defaultListKey] || {};

    const formattedParams = {
      ...params,
      search
    };

    if (defaultListKey && !search) {
      if (!lastFetchedAt) {
        dispatch(updateDefaultListTimestamp(list, params));
      } else if (lastFetchedAt && options?.length) {
        if (!_isEqual(listState?.requestParams, formattedParams)) {
          dispatch({
            type: TYPES.FETCH_OPTIONS.SUCCESS,
            payload: { list, options: options }
          });
        }

        return new Promise(resolve => resolve(options));
      } else if (lastFetchedAt && isFetching) {
        return new Promise(resolve => resolve([]));
      }
    }

    dispatch({ type: TYPES.FETCH_OPTIONS.START, payload: { list, params: formattedParams } });

    return get(url, params)
      .then(res => {
        dispatch({ type: TYPES.FETCH_OPTIONS.SUCCESS, payload: { list, options: res.data } });

        if (!search && !options?.length) {
          dispatch(updateDefaultListOptions(list, params, res.data));
        }

        return res.data;
      })
      .catch(error => {
        dispatch({ type: TYPES.FETCH_OPTIONS.ERROR, payload: { list, error } });

        throw error;
      });
  };

export const refetchDeafultListOptions =
  (list, search = '', params) =>
  dispatch => {
    const defaultListKey = getDefaultListKey(list, params);

    dispatch({
      type: TYPES.SET_DEFAULT_LIST,
      payload: {
        list: defaultListKey,
        isFetching: true,
        lastFetchedAt: null
      }
    });

    dispatch(fetchListOptions(list, search, params));
  };
