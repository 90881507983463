import { Fragment } from 'react';

import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _sortBy from 'lodash/sortBy';

import plotTypes, { plotTypeOrdering } from 'common/components/graph/base/plotTypes';
import { getYAxisIndexFromPlot } from '../helpers/_functions';

const orderPlotTypes = plotTypes => _sortBy(plotTypes, plotType => plotTypeOrdering[plotType]);

const useD3Plots = (
  graphUID,
  plots,
  schema,
  events,
  getGraphStyle,
  yAxes,
  getYAxisOption,
  axesConfig
) => {
  const formattedPlots = plots.map((plot, i) => {
    plot['plotIndex'] = i;
    plot['graphUID'] = graphUID;

    return plot;
  });

  const isTimemarkerWithFullOpacity = plot =>
    _get(plot, 'type') === 'timemarker' && _get(plot, 'style.timeMarkerAreaOpacity') === 1;

  const timemarkersWithFullOpacity = formattedPlots.filter(isTimemarkerWithFullOpacity);
  const rest = formattedPlots.filter(plot => !isTimemarkerWithFullOpacity(plot));

  const getPlotID = (plotType, plot) => `graph_${graphUID}_${plotType}_${plot.plotIndex}`;

  const renderPlots = (element, memoryElement, x, y, getDimensions) => {
    const renderSinglePlot = ({ getPlotStyle, plot, xFn, yFn, y0Fn, y0 }) => {
      // This is where the rendering goes on for every plot (every different dataset).
      // Since the type of the plot can be either an array or a string,
      // we have a different function that renders for a single plot type
      // and below that function we call it either with a forEach or just normally (if it is a string).

      const renderSinglePlotType = plotType => {
        // Here's actually the rendering part I was referring to the comment above.
        // This is responsible for rendering each different plotType with the 'renderPlot' function inside every plotType

        // Checks if the following plotType should be "traditionally" rendered with SVG or if it should go to the canvas pipeline.
        const isSVGFallback = !!plotTypes[plotType].renderComponent;

        const plotID = getPlotID(plotType, plot);

        plotTypes[plotType].renderPlot({
          plotIndex: plot.plotIndex,
          plotID,
          getPlotID,
          plot,
          getPlotStyle,
          xFn,
          yFn,
          y0Fn,
          yAxes,
          element: isSVGFallback ? element : memoryElement,
          getGraphStyle,
          events,
          schema,
          graphUID,
          getDimensions,
          getYAxisOption,
          x,
          axesConfig,
          y0
        });
      };

      const localPlotTypes = [];

      if (_isArray(plot.type)) {
        plot.type.forEach(plotType => {
          localPlotTypes.push(plotType);
        });
      } else {
        localPlotTypes.push(plot.type);
      }

      orderPlotTypes(localPlotTypes).forEach(singlePlotType => {
        renderSinglePlotType(singlePlotType);
      });
    };

    if (yAxes.length) {
      formattedPlots.forEach(plot => {
        const axisIndex = getYAxisIndexFromPlot(yAxes, plot);
        const getPlotStyle = (style, fallback) => _get(plot, `style.${style}`, fallback);

        renderSinglePlot({
          xFn: d => x(d.x),
          yFn: d => y[axisIndex].axis(d.y),
          y0Fn: () => y[axisIndex].axis(0),
          y0: y[axisIndex],
          getPlotStyle,
          plot,
          plotIndex: plot.plotIndex
        });
      });
    }
  };

  const renderPlotComponent = plot => {
    // The 'type' attribute can either be a string or an array.
    // If it is an array it means that there are multiple plots and thus multiple components to be returned.

    const getPlotStyle = (style, fallback) => _get(plot, `style.${style}`, fallback);

    let localPlotTypes = [];

    if (_isArray(plot.type)) {
      plot.type.forEach(plotType => {
        localPlotTypes.push(plotType);
      });
    } else {
      localPlotTypes.push(plot.type);
    }

    localPlotTypes = localPlotTypes.filter(t => plotTypes[t].renderComponent);

    return (
      <g className="d3-graph__plot" id={`plot-${graphUID}-${plot.plotIndex}`} key={plot.plotIndex}>
        {orderPlotTypes(localPlotTypes).map(singlePlotType => (
          <Fragment key={getPlotID(singlePlotType, plot)}>
            {plotTypes[singlePlotType].renderComponent({
              plot,
              plotID: getPlotID(singlePlotType, plot),
              events,
              getPlotStyle
            })}
          </Fragment>
        ))}
      </g>
    );
  };

  const renderPlotComponents = graphUID => {
    // For each plot return the components necessary to run the code for each chart type.
    // For example: The scatter type needs a <circle /> element for every point.
    // The function below is what creates all those elements!

    return (
      <>
        <defs>
          <clipPath id={`mask-${graphUID}`}>
            <rect />
          </clipPath>
        </defs>
        <g className="overflow-container">
          <g className="zoom-container">
            <g style={{ zIndex: -1 }}>{timemarkersWithFullOpacity.map(renderPlotComponent)}</g>

            <g>
              <g className="x-axis--grid" />
              <g className="y-axis--grid" />
            </g>

            {rest.map(renderPlotComponent)}
          </g>
        </g>
      </>
    );
  };

  return { renderPlots, renderPlotComponents, graphUID };
};

export default useD3Plots;
