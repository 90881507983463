import React from 'react';
import Information from 'common/components/general/Information';

const colors = {
  neutral: 'innactive',
  red: 'red',
  green: 'turquoise'
};

const getCharacterColor = password => {
  if (!password) return colors.neutral;
  if (password.length < 12) return colors.red;
  return colors.green;
};

const getUpperLower = password => {
  if (!password) return colors.neutral;
  const hasUpper = /[A-Z]+/.test(password);
  const hasLower = /[a-z]+/.test(password);

  if (hasUpper && hasLower) {
    return colors.green;
  }

  return colors.red;
};

const getNumber = password => {
  if (!password) return colors.neutral;
  const hasNumber = /\d/.test(password);

  if (hasNumber) {
    return colors.green;
  }

  return colors.red;
};

const specialChars = password => {
  if (!password) return colors.neutral;
  const hasChar = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password);

  if (hasChar) {
    return colors.green;
  }

  return colors.red;
};

export const isValidPassword = pass => {
  const length = getCharacterColor(pass);
  const letters = getUpperLower(pass);
  const numb = getNumber(pass);
  const special = specialChars(pass);

  const isOk =
    length === colors.green &&
    letters === colors.green &&
    numb === colors.green &&
    special === colors.green;

  return isOk;
};

const Bullet = ({ color }) => {
  return <div className={`circle bg-${color}`} />;
};

const Popper = ({ password }) => {
  return (
    <div className="fs-12 d-flex flex-column align-items-start">
      <div className="text-violet fw-medium mb-2 mt-1">Password must contain</div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={getCharacterColor(password)} />
        <span className="text-primary fw-medium ms-1">at least 12 characters</span>
      </div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={getUpperLower(password)} />
        <span className="ms-1 text-primary fw-medium">
          at least 1 uppercase and 1 lowercase letter
        </span>
      </div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={getNumber(password)} />
        <span className="ms-1 text-primary fw-medium">at least 1 numeric digit</span>
      </div>
      <div className="d-flex align-items-center cmb-14">
        <Bullet color={specialChars(password)} />
        <span className="text-primary fw-medium ms-1">at least 1 special characters</span>
      </div>
    </div>
  );
};

const PasswordValidation = ({ password, className }) => {
  const getColor = () => {
    if (!password) return '#354069';

    const isOk = isValidPassword(password);

    if (isOk) {
      return '#01D9A4';
    }

    return '#FE5F55';
  };

  return (
    <Information
      message={<Popper password={password} />}
      className={className ? className : ''}
      svgStyle={{ height: 14, width: 14, color: getColor() }}
      target={`cell--info-password`}
      placement="right"
      tooltipClassname="password-validation-tooltip"
      hideArrow={true}
    />
  );
};

export default PasswordValidation;
