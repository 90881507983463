import React from 'react';
import { Row, Col } from 'reactstrap';

const LeaderOfInvestigationView = ({ value }) => {
  if (!value?.crew_member) return '-';

  return (
    <Row>
      <Col xs={12}>
        <Row className="fs-12 fw-bold text-violet mb-2">
          <Col>NAME</Col>
          <Col xs={4}>RANK</Col>
        </Row>

        <Row className="fs-12  text-primary mb-1">
          <Col>{value?.crew_member?.full_name}</Col>
          <Col xs={4}>{value?.crew_rank?.name}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LeaderOfInvestigationView;
