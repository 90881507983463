import React, { useState, useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';
import { Row, Col } from 'reactstrap';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';
import { fetchCrewMembers } from 'common/components/forms/digital/lists-helpers';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'common/components/general/Spinner';

const LeaderOfInvestigation = ({
  formFieldId,
  state,
  disabled,
  value,
  headerFormState,
  onChange,
  error,
  isFormForVessel
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [crewOptions, setCrewOptions] = useState([]);

  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);

  const { submissionDate, selectedVesselId } = headerFormState;
  const selectedDate = submissionDate ? submissionDate.format('YYYY-MM-DD') : null;

  const onInputChange = fieldName => opt => {
    if (disabled) return;

    const updatedValue = { ...state[formFieldId].value };
    updatedValue[fieldName] = opt;

    if (fieldName === 'crew_member') {
      updatedValue.crew_rank = opt ? opt.contract_rank || opt.rank : null;
    }

    onChange(updatedValue);
  };

  useEffect(() => {
    const shouldFetchCrew = isOnBoard || (selectedVesselId && isFormForVessel) || !isFormForVessel;

    if (!shouldFetchCrew) {
      onChange({});
      return;
    }

    const initParticipants = async () => {
      setIsLoading(true);

      const crew = await fetchCrewMembers({
        isOnBoard,
        selectedDate,
        selectedVesselId,
        isFormForVessel,
        dispatch,
        search: ''
      });

      const validCrew = crew.filter(c => c?.type === 'crew' || c?.rank_id);
      setCrewOptions(validCrew?.length ? validCrew : []);

      setIsLoading(false);
    };

    initParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFormForVessel, isOnBoard, selectedDate, selectedVesselId]);

  return (
    <Row noGutters>
      {isLoading ? (
        <Spinner />
      ) : (
        <Col xs={12}>
          <Row noGutters className="fs-12 fw-bold text-violet mb-1">
            <Col>NAME</Col>
            <Col xs={4} className="ms-3">
              RANK
            </Col>
          </Row>

          <Row noGutters className="fs-12 fw-bold text-violet">
            <Col>
              <Select
                value={value?.crew_member}
                isAsync
                onChange={onInputChange('crew_member')}
                getOptionValue={option => option.id}
                defaultOptions={crewOptions}
                loadOptions={search =>
                  fetchCrewMembers({ search, isOnBoard, selectedDate, dispatch, selectedVesselId })
                }
                getOptionLabel={option => getPartyDisplayName(option)}
                className="mb-0"
                placeholder="Select"
                error={error}
              />
            </Col>
            <Col xs={4} className="ms-3">
              <Select
                value={value?.crew_rank}
                isAsync
                onChange={onInputChange('crew_rank')}
                getOptionValue={option => option.id}
                defaultOptions={() => getInitialAsyncValues('crew-ranks')}
                loadOptions={search => getAsyncOptions(search, 'crew-ranks')}
                getOptionLabel={option => option.name}
                className="mb-0"
                placeholder="Select"
                disabled
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default LeaderOfInvestigation;
