import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';

import Shifts from 'views/shifts';

const routes = [
  {
    type: 'private',
    exact: true,
    path: paths.shifts,
    app: true,
    component: Shifts,
    permissions: [permissions.onboard.crew.watchkeeping.view],
    documentTitle: 'Watchkeeping'
  }
];

export default routes;
