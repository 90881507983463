import { Button, Label } from 'reactstrap';
import { useState, useEffect } from 'react';
import _get from 'lodash/get';

import FloatingLabelInput from 'common/components/form/styled-inputs/FloatingLabelInput';
import { isValidPassword } from 'common/components/general/PasswordValidation';
import * as crewActions from 'crew/store/profile/actions';

import { useActions, useFormState, useForm } from 'utils/hooks';

import { DrawerFooter, DrawerBody, DrawerHeader } from 'common/components/drawer';

import { changePasswordConfig } from './config';

const isPasswordSame = (currentPassword, repeatPassword) => {
  return currentPassword === repeatPassword;
};

const PasswordField = ({ value, onChange, label, onClear, hideValidation, ...props }) => {
  return (
    <div className="onboard-credentials-password">
      <div className="fs-12 text-primary mb-1">{label}</div>
      <FloatingLabelInput
        invisible
        hideValidation={hideValidation}
        type={'password'}
        className="mb-3 border-1"
        placeholder="Set password"
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

const ChangePassword = ({ drawer, crew }) => {
  const [isLoading, setIsLoading] = useState();

  const { formState, resetForm } = useForm(changePasswordConfig, {});
  const { fields, setFieldValue } = useFormState(formState);

  const [changePassword] = useActions([crewActions.changePassword]);

  useEffect(() => {
    if (!drawer.isOpen) {
      resetForm();
    }
  }, [drawer.isOpen]);

  const onSave = async () => {
    setIsLoading(true);
    if (
      (!fields.password.value && !fields.password_confirmation.value) ||
      !isPasswordSame(fields.password.value, fields.password_confirmation.value) ||
      !isValidPassword(fields.password.value)
    ) {
      setIsLoading(false);

      return false;
    }

    const params = {
      id: crew.id,
      password: fields.password?.value,
      password_confirmation: fields.password_confirmation?.value
    };

    try {
      await changePassword(params);
      setIsLoading(false);
      drawer.close();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <DrawerHeader>Change Password</DrawerHeader>

      <DrawerBody className="mt-3">
        <Label className="form-label fw-bold text-uppercase cmb-10">USERNAME</Label>
        <div className="text-primary fs-12">{_get(crew, 'user.username', '-')}</div>

        <Label className="form-label fw-bold text-uppercase mb-0 mt-4">PASSWORD</Label>
        <PasswordField
          value={fields.password.value}
          onChange={e => setFieldValue('password', e.target.value)}
        />

        <Label className="form-label fw-bold text-uppercase mb-0">REPEAT PASSWORD</Label>
        <PasswordField
          hideValidation={true}
          value={fields.password_confirmation.value}
          onChange={e => setFieldValue('password_confirmation', e.target.value)}
          error={
            !isPasswordSame(fields.password.value, fields.password_confirmation.value)
              ? 'Password should be same'
              : ''
          }
        />
      </DrawerBody>

      <DrawerFooter className="d-flex justify-content-end align-items-center p-3">
        <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
          CANCEL
        </Button>
        <Button onClick={onSave} disabled={isLoading} color="primary" className="px-4">
          SAVE
        </Button>
      </DrawerFooter>
    </>
  );
};

export default ChangePassword;
