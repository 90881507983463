import { useEffect, useState } from 'react';
import variables from '@/common/assets/scss/abstracts/_exports.module.scss';
import useRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { Row, Col } from 'reactstrap';
import { numberToStr } from 'common/utils/numbers';
import ConsentedLabel from 'crew/components/ConsentedLabel';

import { getResultPercentages } from 'crew/store/evaluations/profile/actions';
import { selectEvaluationPercentages } from 'crew/store/evaluations/profile/selectors';
import { useAppSelector } from '@/store/hooks';
import { selectDefaultScaleLabels } from '@/store/lists/selectors-ts';

import D3Doughnut from 'common/components/graph/D3Doughnut';
import TopStatuses from './components/TopStatuses';
import Recommendations from './components/Recommendations';
import ReasonView from '../components/component-analysis/ReasonView';

const EvaluationViewTop = ({ active, isOnboard, components }) => {
  const { match } = useRouter();
  const [data, setData] = useState([]);
  const id = _get(match, 'params.evaluationID', null);
  const dispatch = useDispatch();

  const scales = useAppSelector(selectDefaultScaleLabels);
  const percentages = useSelector(selectEvaluationPercentages);

  useEffect(() => {
    dispatch(getResultPercentages({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (percentages?.plots?.length) {
      setData(
        percentages?.plots?.map(e => ({
          label: e.data_key,
          percentage: percentages?.data[e.data_key]
        }))
      );
    }
  }, [percentages]);

  const generateColors = (scales, percentages) => {
    const filterArray = percentages.plots
      ?.map(percentage => scales.find(scale => scale.label === percentage.data_key))
      .filter(t => t);

    return filterArray?.length
      ? Object.assign(
          ...filterArray.map(percentage => ({
            [percentage.label]: {
              color: variables[percentage?.color.replace('color_', 'scaleRatingColor')]
            }
          }))
        )
      : {};
  };

  const paletteColors = scales?.length && percentages ? generateColors(scales, percentages) : [];

  return (
    <Row>
      <Col xs={8} xxl={8}>
        <div className="d-flex flex-column mb-auto mt-4">
          <TopStatuses active={active} isOnboard={isOnboard} />

          <ReasonView
            reason={
              _get(active, 'obligation.reason', null) ||
              _get(active, 'crew_evaluation_reason', null)
            }
            type={_get(active, 'obligation.evaluator_type', null)}
          />
        </div>
      </Col>

      <Col className={`${isOnboard ? 'ms-5' : 'ms-6'} mt-2 `}>
        <D3Doughnut
          data={{
            graphInner: {
              value: numberToStr(active?.average_score),
              title: (
                <text
                  x="0"
                  y="7%"
                  startOffset="50%"
                  textAnchor="middle"
                  className="fw-bold graph-title fs-10"
                >
                  <tspan x="0" dy="1.2em">
                    AVERAGE
                  </tspan>
                </text>
              )
            },
            percentages: data,
            paletteColors: paletteColors,
            legends: scales
          }}
          customSchema={{
            doughnutMargin: 14,
            doughnutInnerHoleRadius: 10,
            doughnutOuterHoleRadius: 25
          }}
          needLegends={false}
          GraphComponent={components.D3Graph}
        />
      </Col>

      <Col xs={12} className="d-flex align-items-end w-100p mb-1">
        <Recommendations className="flex-1" active={active} isOnboard={isOnboard} />
        <ConsentedLabel showSeperator={false} />
      </Col>
    </Row>
  );
};

export default EvaluationViewTop;
