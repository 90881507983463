import { useSelector } from 'react-redux';
import { selectManualTitle } from 'manuals/store/selectors';

const Title = ({ hideSeperator = false, className = '' }) => {
  const title = useSelector(selectManualTitle);

  return (
    <>
      <div
        className={`d-inline-block text-truncate fs-16 overflow-hidden max-width-182 ${className}`}
      >
        {title}
      </div>
      {hideSeperator ? null : <div className="ps-1 fs-16 me-1">/</div>}
    </>
  );
};

export default Title;
