import React from 'react';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import * as sidebarActions from 'store/sidebar/actions';

import Menu from './menu';
import NewsAndAnnouncements from 'common/components/news-and-announcements';
// import Notifications from './notifications';

const Panel = () => {
  const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const isPanelOpen = useSelector(state => state.sidebar.isPanelOpen);
  const activePanel = useSelector(state => state.sidebar.activePanel);
  const panels = {
    menu: Menu,
    news_and_announcements: NewsAndAnnouncements
    // notifications: Notifications
  };
  const PanelComponent = activePanel ? panels[activePanel] : null;

  return (
    <div
      className={`sidebar-panel${isPanelOpen ? ' is-active' : ''}${
        activePanel ? ` panel-open panel-${activePanel}` : ''
      }`}
    >
      <div
        className={`sidebar-panel-content sidebar-panel-content--${
          activePanel === 'news_and_announcements' ? 'lg' : 'sm'
        }`}
      >
        <div
          className="sidebar-panel-toggle"
          onClick={() => togglePanel(activePanel ? null : 'menu')}
        >
          <div className="sidebar-panel-toggle--burger">
            <div className="sidebar-panel-toggle--burger-inner" />
          </div>
        </div>
        <div className="sidebar-panel-content--inner">
          {PanelComponent ? <PanelComponent /> : null}
        </div>
      </div>
    </div>
  );
};

export default Panel;
