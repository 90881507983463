import PropTypes from 'prop-types';
import { ColumnsType } from 'common/entities/tables/TableTypes';
import Row from './Row';

const Body = ({
  columns,
  rows,
  state,
  lastSticky,
  setDangerousModal,
  collapsableRows,
  triggerOpen,
  onRowClick,
  getRowClassName
}) => {
  return (
    <div className="app-table--body">
      {state.data.map((data, index) => (
        <Row
          key={index}
          columns={columns}
          rows={rows}
          lastSticky={lastSticky}
          setDangerousModal={setDangerousModal}
          data={data}
          triggerOpen={triggerOpen}
          collapsableRows={collapsableRows}
          rowIndex={index}
          onRowClick={onRowClick}
          getRowClassName={getRowClassName}
        />
      ))}
    </div>
  );
};

export default Body;

Body.propTypes = {
  columns: ColumnsType,
  rows: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
  lastSticky: PropTypes.number,
  setDangerousModal: PropTypes.func,
  collapsableRows: PropTypes.func,
  triggerOpen: PropTypes.bool,
  onRowClick: PropTypes.func,
  getRowClassName: PropTypes.func
};
