import { useState, useEffect, useMemo } from 'react';
import { useDrawer } from 'common/components/drawer';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';
import {
  selectJobFormIds,
  selectJobForms,
  selectJobFormSubmission,
  selectJobFormSubmissions
} from 'common/components/jobs/_base/modules/forms/store/selectors';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import FormDrawer from 'common/components/forms/drawer';
import FormHeaderPreview from 'common/components/forms/_components/FormHeaderPreview';

import * as jobFormsActions from 'common/components/jobs/_base/modules/forms/store/actions';
import * as formActions from 'store/forms/actions';
import * as formChatboxActions from 'common/components/forms/chat/store/actions';
import useRouter from 'use-react-router';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import { selectMaintenanceDetaisVesselSystemAssignmentId } from 'common/components/pms/jobs/store/selectors';

const Form = ({
  form,
  jobId,
  jobFormId,
  onFormViewClick,
  canRemoveForms = true,
  unreadMessages
}) => {
  const [removeJobForm] = useActions([jobFormsActions.removeJobForm]);

  const isJobLocked = useSelector(selectIsJobLocked);

  const formSubmission = useSelector(state => selectJobFormSubmission(state, jobFormId));

  const onRemove = async () => {
    await removeJobForm({ jobId, jobFormId, form });
  };

  return (
    <FormHeaderPreview
      onView={
        !jobId && form?.id // This is on create job when we add a form in order to preview the fields
          ? () => onFormViewClick(form, null, true)
          : (!isJobLocked || formSubmission?.id) && jobFormId
          ? formSubmission => onFormViewClick(form, formSubmission)
          : null
      }
      onRemove={canRemoveForms ? onRemove : null}
      form={form}
      formSubmission={formSubmission}
      unreadMessagesCount={unreadMessages[formSubmission?.id] || 0}
      flat={!jobId}
      hiddenFields={{ importance: !jobId }}
      hideEmptySubmissionMessage={!jobId ? true : false}
    />
  );
};

const SavedJobForms = ({ jobId, canRemoveForms }) => {
  const jobForms = useSelector(selectJobForms);
  const excludeFormIds = useSelector(selectJobFormIds);
  const formSubmissions = useSelector(selectJobFormSubmissions);
  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));
  const vessels = useSelector(state => selectJobField(state, 'vessels'));
  const isOnBoard = useSelector(state => state.isOnBoard);

  const drawer = useDrawer(false);
  const [drawerType, setDrawerType] = useState('view');
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedFormSubmissionId, setSelectedFormSubmissionId] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isFormLocked, setIsFormLocked] = useState(false);

  const { location } = useRouter();

  const [getFormSubmission, updateJobForm, updateJobFormSubmission] = useActions([
    formActions.getFormSubmission,
    jobFormsActions.updateJobForm,
    jobFormsActions.updateJobFormSubmission
  ]);

  const [getFormSubmissionUnreadMessages] = useActions([
    formChatboxActions.getFormSubmissionUnreadMessages
  ]);

  const unreadMessages = useMemo(async () => {
    if (!formSubmissions) return;

    const ids = Object.keys(formSubmissions)
      .map(sub => formSubmissions[sub]?.id)
      .filter(el => el);

    if (!ids.length) return;

    try {
      return await getFormSubmissionUnreadMessages({
        form_submission_ids: ids,
        saveUnreadMessages: false
      });
    } catch (err) {
      console.error(err);
      return {};
    }
  }, []);

  const onUpdateFormSubmission = async ({ form_submission_id, form_id, id, ...rest }) => {
    const jobFormId = jobForms.find(form => form.id === form_id)?.job_form_id;

    const res = await updateJobForm({ jobId, jobFormId, form_id, ...rest });

    if (res) {
      const { form, form_id, ...form_submission } = await getFormSubmission({
        id: res.form_submission_id
      });

      updateJobFormSubmission(jobFormId, form_submission);
    }
  };

  const onFormViewClick = (form, formSubmission, isPreviewMode = false) => {
    if (formSubmission) {
      setSelectedForm(form);
      setSelectedFormSubmissionId(formSubmission.id);
      setDrawerType('view');
    } else {
      setSelectedForm(form);
      setSelectedFormSubmissionId(null);

      if (isPreviewMode) {
        setDrawerType('view');
        setIsPreviewMode(true);
        setIsFormLocked(true);

        drawer.open();
        return;
      }

      setDrawerType('add');
      drawer.open();
    }
  };

  useEffect(() => {
    if (!drawer.isOpen) {
      setSelectedForm(null);
      setSelectedFormSubmissionId(null);
    }
  }, [drawer.isOpen]);

  const vesselSystemAssignment = useSelector(selectMaintenanceDetaisVesselSystemAssignmentId);

  return (
    <>
      <div className="job-forms__container">
        {jobForms
          .filter(f => (isOnBoard ? f.is_for_vessel : f))
          .map(form => (
            <Form
              form={form}
              jobId={jobId}
              jobFormId={form.job_form_id}
              key={form.job_form_id}
              onFormViewClick={onFormViewClick}
              canRemoveForms={canRemoveForms}
              unreadMessages={unreadMessages}
            />
          ))}
      </div>

      {location.pathname.startsWith('/jobs/') || location.pathname.startsWith('/pms-jobs') ? (
        <FormDrawer
          drawerType={drawerType}
          drawer={drawer}
          vesselSystemAssignmentId={vesselSystemAssignment}
          initialForm={selectedForm}
          onSaveFormSubmission={onUpdateFormSubmission}
          initialFormSubmissionID={selectedFormSubmissionId}
          initialVesselID={
            !selectedFormSubmissionId && isForVessel && vessels?.length ? vessels[0]?.id : null
          }
          excludeFormIds={excludeFormIds}
          hiddenFields={{
            vessel: isForVessel && !isOnBoard ? false : true,
            type: true,
            status: true
          }}
          filterVesselSelectorOptions={
            isForVessel ? opt => vessels.find(v => v.id === opt.value) : null
          }
          previewMode={isPreviewMode}
          isFormLocked={isFormLocked}
        />
      ) : null}
    </>
  );
};

export default SavedJobForms;
