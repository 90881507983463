import React, { useEffect, useRef } from 'react';
import { select, line, area, scaleLinear, curveCardinal } from 'd3';
import _max from 'lodash/max';
import _min from 'lodash/min';
import PropTypes from 'prop-types';

/* 
This is a very basic line graph with now axis and a drop shadow effect.
The values that you should provide it is just a plain array of numbers like: [2.5, 5, 3, -5, 4]
and bellow is an example of a config so you can modify it accordingly

{
  chartWidth: 400 //pixels,
  chartHeight: 300 //pixels,
  chartPaddingX: 10 //pixels,
  chartPaddingY: 15 //pixels,
  color: '#FF512F',
  lineSize: 2 //pixels,
  gradientOpacity: [0.5, 0.1] //this will make the gradient shadow fade gradually from top to bottom starting at 0.5 opacity going down to 0.1
}

!!! NOTE !!! 
YOU MUST PROVIDE ALL THE CONFIG VALUES IN ORDER TO MAKE IT WORK
if you don't want padding for example just give 0

The id prop is in case you want to rerender the graph based on something else other than just the values length

*/

const D3SimpleLineChart = ({ values, config, id }) => {
  const svgRef = useRef();

  const {
    chartWidth,
    chartHeight,
    chartPaddingX,
    chartPaddingY,
    color,
    lineSize,
    gradientOpacity
  } = config;

  useEffect(() => {
    if (values?.length <= 1) return;

    const maxValue = _max(values);
    const minValue = _min(values);

    const svg = select(svgRef.current);

    const xScale = scaleLinear()
      .domain([0, values.length - 1])
      .range([0, chartWidth - chartPaddingX]);

    const yScale = scaleLinear()
      .domain([minValue, maxValue])
      .range([chartHeight - chartPaddingY, chartPaddingY]);

    const chartLine = line()
      .x((value, index) => xScale(index))
      .y(yScale)
      .curve(curveCardinal);

    //create an area object which covers the area bellow the chart line
    const chartArea = area()
      .x((value, index) => xScale(index))
      .y0(chartHeight - chartPaddingY)
      .y1(yScale)
      .curve(curveCardinal);

    //we first append the line to our svg
    svg
      .selectAll('.line')
      .data([values])
      .join('path')
      .attr('d', chartLine)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', `${lineSize}`);

    //then we create the gradient and append it to the svg at first as defs
    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    //then we append the gradient to the area
    svg
      .selectAll('.area')
      .data([values])
      .join('path')
      .attr('d', chartArea)
      .attr('fill', 'url(#gradient)');

    //and we add opacity to drop gradually from top to bottom
    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', color)
      .attr('stop-opacity', gradientOpacity[0]);

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', color)
      .attr('stop-opacity', gradientOpacity[1]);
  }, [values?.length, id]);

  if (values?.length <= 1) return;

  return (
    <svg height={chartHeight} width={chartWidth} style={{ overflow: 'hidden' }} ref={svgRef} />
  );
};

export default D3SimpleLineChart;

D3SimpleLineChart.propTypes = {
  values: PropTypes.array,
  config: PropTypes.object,
  id: PropTypes.any
};
