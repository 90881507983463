import React from 'react';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import EvaluationNotes from 'crew/components/EvaluationNotes';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';
import _get from 'lodash/get';

const EvaluationObligationReason = ({ evaluation, notes, className = '' }) => {
  const evaluationObligation = _get(evaluation, 'obligation', null) || null;
  const reason =
    _get(evaluation, 'obligation.reason.name', '') ||
    _get(evaluation, 'crew_evaluation_reason.name', '') ||
    '-';

  return (
    <div className={`d-flex align-items-center flex-nowrap w-100p overflow-hidden ${className}`}>
      <div className="flex-1 overflow-hidden">
        <TextWithTooltip>{reason}</TextWithTooltip>
      </div>

      {evaluationObligation ? (
        <div className="ms-auto ps-1 d-flex justify-content-end">
          <EvaluatorDepartment
            evaluator={_get(evaluationObligation, 'evaluator')}
            evaluatorType={_get(evaluationObligation, 'evaluator_type')}
          />
        </div>
      ) : null}

      {notes ? <EvaluationNotes notes={notes} className="ms-1" /> : null}
    </div>
  );
};

export default EvaluationObligationReason;
