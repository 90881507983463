import { useSelector } from 'react-redux';

import { isAuthorized } from 'utils/permissions/authorize';

import paths from 'routing/routes/_paths';
import TableViewIcon from 'views/dashboard/components/TableViewIcon';
import { isDraftEvaluation } from 'crew/profile/main/evaluation/form/helpers';

import eyeSvg from 'common/assets/svg/common/eye.svg';
import permissions from 'common/utils/permissions/constants';

const ViewEvaluationAction = ({ evaluationId, evaluateeId, data }) => {
  const account = useSelector(state => state.account);

  const canSeeEvaluations = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.view
  ]);
  const canSeeOwnEvaluations = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.own_profile.view
  ]);

  return evaluationId &&
    (canSeeEvaluations || (evaluateeId === account.id && canSeeOwnEvaluations)) ? (
    <TableViewIcon
      to={
        evaluateeId
          ? `${paths.crew}/${evaluateeId}/evaluation/${
              isDraftEvaluation(data?.evaluation, true) ? 'draft' : 'view'
            }/${evaluationId}`
          : null
      }
      icon={eyeSvg}
      className="text-primary"
    />
  ) : (
    <></>
  );
};

export default ViewEvaluationAction;
