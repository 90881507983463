import { components } from 'react-select';
import React, { useMemo, useState, useCallback, useRef, useEffect, Fragment } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';

import { Button } from 'reactstrap';
import { copyTextToClipboard, getClipboardContents } from 'common/utils/texts';
import { urlArrayLimit } from 'common/utils/urls';

import searchIcon from 'common/assets/svg/common/search.svg';
import x from 'common/assets/svg/common/x.svg';
import check from 'common/assets/svg/common/check-circle.svg';
import checkSolid from 'common/assets/svg/common/check-circle-solid.svg';
import xCircle from 'common/assets/svg/common/x-circle.svg';
import xCircleSolid from 'common/assets/svg/common/x-circle-solid.svg';

const InputField = React.memo(({ value, onChange }) => {
  const inputRef = useRef(null);
  const [cursorIndex, setCursorIndex] = useState(0);

  const isTextSelected = useCallback(() => {
    const selection = window.getSelection();
    return selection && selection.type === 'Range';
  }, []);

  const clearSelectedText = useCallback(() => window.getSelection().removeAllRanges(), []);

  const selectText = useCallback(element => {
    const range = document.createRange();
    range.selectNode(element);
    window.getSelection().addRange(range);
  }, []);

  const updateCurrentCursorIndex = useCallback(index => {
    if (index >= 0) setCursorIndex(index);
  }, []);

  const handleUserKeyPress = useCallback(
    async event => {
      event.preventDefault();

      const ctrlKey = event.metaKey || event.ctrlKey;
      const code = event.keyCode;

      const input = String.fromCharCode(code);
      let previousText = inputRef.current.innerHTML
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&');

      let currentIndex = previousText?.length ? parseInt(inputRef.current.dataset.index) : 0;

      if (ctrlKey && code === 67) {
        // CTRL+C - copy
        copyTextToClipboard(previousText);
        return;
      } else if (code === 37) {
        // left arrow
        updateCurrentCursorIndex(currentIndex - 1);
        return;
      } else if (code === 39) {
        // right arrow
        if (previousText?.length > currentIndex) updateCurrentCursorIndex(currentIndex + 1);
        return;
      } else if (isTextSelected()) {
        previousText = '';
        currentIndex = 0;
      }

      if (ctrlKey && code === 86) {
        // CTRL+V - paste
        const text = await getClipboardContents();

        if (text) {
          const newText = previousText + text;

          onChange(newText);
          updateCurrentCursorIndex(newText?.length);

          clearSelectedText();
        }
      } else if (ctrlKey && code === 65) {
        // CTRL+A - select all

        clearSelectedText();
        selectText(inputRef.current);
      } else if (/[a-zA-Z0-9]/.test(input) || /[-_@.+=?:;"',/~` ]/.test(event.key)) {
        // character pressed

        onChange(previousText + event.key);
        updateCurrentCursorIndex(currentIndex + 1);

        clearSelectedText();
      } else if (event.keyCode === 8 || event.keyCode === 46) {
        // delete character

        onChange(previousText.substr(0, currentIndex - 1) + previousText.substr(currentIndex));
        updateCurrentCursorIndex(currentIndex - 1);

        clearSelectedText();
      }
    },
    [updateCurrentCursorIndex]
  );

  const Cursor = () => <span className="react-select-multiple-menu__input-cursor"></span>;

  const renderInputValue = () => {
    const characters = Array.from(value);

    return !characters?.length ? (
      <Cursor />
    ) : (
      <>
        {characters.map((c, index) => {
          return (
            <Fragment key={index}>
              {index === cursorIndex ? <Cursor /> : null}
              {c === ' ' ? <>&nbsp;</> : c}
            </Fragment>
          );
        })}
        {cursorIndex === characters?.length ? <Cursor /> : null}
      </>
    );
  };

  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyPress);

    return () => {
      document.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div
      className={`react-select-multiple-menu__input flex-1 ${!value?.length ? 'is-empty' : ''}`}
      data-index={cursorIndex}
      ref={inputRef}
    >
      {renderInputValue()}
    </div>
  );
});

const SelectMultipleMenu = ({ children, ...props }) => {
  const [activeTab, setActiveTab] = useState('all');
  const headerRef = useRef(null);

  const [menuPosition, setMenuPosition] = useState('left');

  const values = props.getValue();

  const getOptionValue = useCallback(
    opt => (props.selectProps.getOptionValue ? props.selectProps.getOptionValue(opt) : opt.value),
    []
  );

  const areAllSelected = useMemo(() => {
    return (
      values?.length &&
      props?.options?.length &&
      props?.options.every(opt => values.find(v => getOptionValue(v) === getOptionValue(opt)))
    );
  }, [values?.length, props?.options?.length]);

  const areNoneSelected = useMemo(() => !values?.length, [values?.length]);

  const tabs = useMemo(
    () => [
      {
        label: 'All',
        onClick: () => setActiveTab('all'),
        size: 'sm',
        isActive: activeTab === 'all'
      },
      {
        label: 'Checked',
        onClick: () => setActiveTab('checked'),
        size: 'sm',
        isActive: activeTab === 'checked'
      },
      {
        label: 'Unchecked',
        onClick: () => setActiveTab('unchecked'),
        size: 'sm',
        isActive: activeTab === 'unchecked'
      }
    ],
    [activeTab]
  );

  const getSelectedOptions = () => {
    if (props.selectProps.isAsync) return props.options;

    if (props.selectProps.inputValue) {
      return props.options.filter(option =>
        (props.selectProps.getOptionLabel ? props.selectProps.getOptionLabel(option) : option.label)
          .toLowerCase()
          .includes(props.selectProps.inputValue.toLowerCase())
      );
    }

    return props.options;
  };

  useEffect(() => {
    if (headerRef?.current) {
      const rect = headerRef?.current.parentNode.getBoundingClientRect();

      if (
        !(
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
      ) {
        setMenuPosition('right');
      }
    }
  }, []);

  const onInputChange = useCallback(props.selectProps.onInputChange, []);

  return (
    <components.Menu className={`react-select-multiple-menu position-${menuPosition}`} {...props}>
      <div className="react-select-multiple-menu__header" ref={headerRef}>
        <SvgRender
          src={searchIcon}
          style={{ width: 14, height: 14 }}
          className="me-1 text-gray-400"
        />
        <InputField value={props.selectProps.inputValue} onChange={onInputChange} />
        <Button
          size="sm"
          color="white"
          className="d-inline-flex align-items-center border-0 react-select-multiple-menu__clear-btn ms-auto"
          disabled={!props.selectProps.inputValue}
          onClick={() => props.selectProps.onInputChange('')}
        >
          <SvgRender src={x} style={{ width: 8, height: 8 }} />
          &nbsp;Clear
        </Button>
      </div>
      <div className="react-select-multiple-menu__actions">
        <Button
          color="link"
          size="sm"
          className={`cme-4 check-btn ${areAllSelected ? 'active' : ''}`}
          onClick={() =>
            !areAllSelected ? props.setValue(getSelectedOptions(), 'select-all-options') : ''
          }
          disabled={
            !props.selectProps.canSelectAllOptions && getSelectedOptions()?.length >= urlArrayLimit
          }
        >
          <SvgRender src={areAllSelected ? checkSolid : check} style={{ width: 12, height: 12 }} />
          Check all
        </Button>
        <Button
          color="link"
          size="sm"
          className={`uncheck-btn ${areNoneSelected ? 'active' : ''}`}
          onClick={() => (!areNoneSelected ? props.setValue([]) : '')}
        >
          <SvgRender
            src={areNoneSelected ? xCircleSolid : xCircle}
            style={{ width: 12, height: 12 }}
          />
          Uncheck all
        </Button>

        <NavigationGroupTabs className="ms-auto" tabs={tabs} />
      </div>
      <div className={`react-select-multiple-menu__list visible-${activeTab}`}>{children}</div>
    </components.Menu>
  );
};

export default SelectMultipleMenu;
