import EvaluationSlider from '@/common/components/form/inputs/EvaluationSlider';
import NumberInput from '@/ts-common/components/form/inputs/NumberInput';

import { Col, Row } from 'reactstrap';
import { scaleNumberIsAllowed } from 'common/utils/numbers';
import { FC, ReactNode } from 'react';
import { ReactSliderProps } from 'react-slider';
import { useAppSelector } from '@/store/hooks';
import {
  selectEvaluationsRangeFrom,
  selectEvaluationsRangeTo,
  selectEvaluationsStep
} from '@/store/settings/selectors';
import { strToNumber } from '@/ts-common/utils/numbers';

type ScaleNumberInputProps = {
  label?: ReactNode;
  value?: number;
  disabled?: boolean;
  onChange: (v: string | number | null) => void;
  sliderProps: ReactSliderProps;
  widths?: number[];
};

const ScaleNumberInput: FC<ScaleNumberInputProps> = ({
  label,
  value,
  disabled,
  onChange,
  sliderProps = {},
  widths = [9, 3]
}) => {
  const min = useAppSelector(selectEvaluationsRangeFrom);
  const max = useAppSelector(selectEvaluationsRangeTo);
  const step = useAppSelector(selectEvaluationsStep);

  const onInputChange = (val: string | number | null) => {
    if (scaleNumberIsAllowed(val, { min: min, max: max, step: step })) {
      onChange(strToNumber(val));
    }
  };

  return (
    <Row className="d-flex align-items-center w-100p">
      <Col xs={widths[0]} className="pe-4">
        <EvaluationSlider
          label={label}
          value={value}
          disabled={disabled}
          className={'w-100p'}
          {...sliderProps}
          {...{ onChange: onChange }}
        />
      </Col>
      <Col xs={widths[1]} className="pe-4">
        <NumberInput
          className="mb-0"
          innerClassName="text-center"
          value={value}
          onChange={e => onInputChange(e.target.value)}
          disabled={disabled}
          isAllowed={value =>
            scaleNumberIsAllowed(value.floatValue, { min: min, max: max, step: step }) ||
            (max &&
              max
                .toString()
                .startsWith(
                  value.value
                )) /* Example: step = 2, max = 10 - this fix allows typing the value 10  */ ||
            value.floatValue === undefined
          }
        />
      </Col>
    </Row>
  );
};

export default ScaleNumberInput;
