import React, { useEffect, useState } from 'react';

import CkEditor from 'common/components/form/inputs/ckeditor';
import Label from './Label';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkIfEditMode,
  selectIsJobLocked,
  selectJobField,
  selectOfficeUserIsAuthorized,
  selectOnboardUserIsAuthorized
} from 'common/components/jobs/_base/store/selectors';
import { setJobField, updateJob } from 'common/components/jobs/_base/store/actions';
import InputSaveActions from '../InputSaveActions';

import { selectJobId } from 'common/components/jobs/_base/store/selectors';

const Comments = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [tempComments, setTempComments] = useState('');

  const dispatch = useDispatch();

  const comments = useSelector(state => selectJobField(state, 'vessel_comments'));
  const isEditMode = useSelector(checkIfEditMode);
  const isOnboard = useSelector(state => state.isOnBoard);
  const canEdit = useSelector(state =>
    isOnboard ? selectOnboardUserIsAuthorized(state) : selectOfficeUserIsAuthorized(state)
  );
  const isJobLocked = useSelector(selectIsJobLocked) || !canEdit;
  const jobId = useSelector(selectJobId);

  useEffect(() => {
    if (comments) {
      setTempComments(prev => (!prev ? comments : prev));
    }
  }, [comments]);

  useEffect(() => {
    if (!isFocused && tempComments && comments !== tempComments) {
      jobId
        ? dispatch(updateJob({ vessel_comments: tempComments }))
        : dispatch(setJobField('vessel_comments', tempComments));
    }
  }, [comments, dispatch, isFocused, tempComments, jobId]);

  return (
    <div className="bg-light-gray rounded-lg p-2 h-100p">
      <Label title="VESSEL COMMENTS" />

      <div>
        <CkEditor
          className={`${isFocused ? '' : 'job-description-editor--hide'} mb-1`}
          isFocused={isFocused}
          onFocus={focus =>
            focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100)
          }
          placeholder="Add some text…"
          id="job-vessel-comments"
          minHeight="32px"
          onChange={v => setTempComments(v)}
          value={tempComments}
          disabled={isJobLocked}
        />

        {isEditMode && isFocused && !isJobLocked ? (
          <InputSaveActions
            onCancel={() => {
              dispatch(setJobField('vessel_comments', comments));
              setTempComments(comments);
              setTimeout(() => setIsFocused(false), 600);
            }}
            onSave={() => {
              dispatch(updateJob({ vessel_comments: tempComments }));
              dispatch(setJobField('vessel_comments', tempComments));
              setTimeout(() => setIsFocused(false), 600);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Comments;
