import { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useAppDispatch } from '@/store/hooks';
import { deleteEvent } from 'events/store/events/actions';
import { getEvents } from '@/api/events/api.ts';
import { EVENTS_TABLE_KEY } from '@/api/events/query-keys.ts';
import { displayDate } from '@/ts-common/utils/dates';
import { Event } from '@/common/types/events';
import { FC } from 'react';

import paths from 'routing/routes/_paths';
import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import Status from 'common/components/general/Status';
import Priority from 'common/components/general/Priority';
import clock from 'common/assets/svg/jobs/clock.svg';
import Subtypes from './components/Subtypes';
import Departments from 'common/components/general/Departments';
import SvgRender from 'common/components/general/SvgRender';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import PageLink from 'common/components/general/page-link';
import MessagesCountBubble from '@/common/components/general/MessagesCountBubble';
import Table, { ColumnType, useTable } from '@webthatmatters/orca-table';
import useTableNavigation from '@/common/utils/hooks/useTableNavigation';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';
import BooleanFilterSelector from '@/common/components/table/filters/BooleanFilterSelector';
import CollectionFilterSelector from '@/common/components/table/filters/CollectionFilterSelector';
import PartyCollectionFilterSelector from '@/common/components/table/filters/PartyCollectionFilterSelector';
import OcimfCategoryCollectionFilterSelector from '@/common/components/table/filters/OcimfCategoryCollectionFilterSelector';
import DepartmentCollectionFilterSelector from '@/common/components/table/filters/DepartmentCollectionFilterSelector';
import DateFilterSelector from '@/common/components/table/filters/DateFilterSelector';
import ImportanceCollectionFilterSelector from '@/common/components/table/filters/ImportanceCollectionFilterSelector';

type EventListProps = {
  isOnBoard: boolean;
};

const EventList: FC<EventListProps> = ({ isOnBoard }) => {
  const { navigate } = useTableNavigation();
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = useState<{ isOpen: boolean; data: Event | null }>({
    isOpen: false,
    data: null
  });

  const columns: ColumnType<Event>[] = [
    {
      header: 'I',
      filterLabel: 'Importance',
      key: 'importance_id',
      type: 'collection',
      canSort: false,
      hidden: true,
      component: ImportanceCollectionFilterSelector,
      canFilter: true
    },
    {
      header: 'I',
      filterLabel: 'Importance',
      key: 'importance',
      type: 'collection',
      canSort: true,
      isSticky: true,
      canFilter: false,
      size: 49,
      row: ({ row: { original } }) => <Priority value={original.importance.label} />
    },
    {
      header: 'Name',
      key: 'name',
      type: 'string',
      canSort: true,
      isSticky: true,
      size: 384,
      className: 'title-cell',
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().isCompactViewEnabled;

        return (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-1 max-width-calc-32">
              <PageLink to={`${paths.events}/${original.id}`} allowOverflow={!isCompactViewEnabled}>
                {original.name}
              </PageLink>
              {!!original.chat_messages_count && (
                <MessagesCountBubble
                  className="width-16 height-16 cms-12"
                  count={original.chat_messages_count}
                />
              )}
            </div>
            {!isOnBoard && original.is_for_vessel ? (
              <>
                <SvgRender
                  id={`event-table-vessel-tooltip-${original.id}`}
                  className={`${
                    original.status.edit_side !== 'vessel' ? 'text-turquoise' : 'text-violet'
                  } cursor-pointer`}
                  src={vesselIcon}
                  style={{ width: 16, height: 16 }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`event-table-vessel-tooltip-${original.id}`}
                  trigger="hover"
                >
                  {original.vessel?.name}
                </UncontrolledTooltip>
              </>
            ) : null}
          </div>
        );
      }
    },
    {
      header: 'Type',
      key: 'type',
      type: 'string',
      canFilter: false,
      canSort: false,
      isSticky: true,
      size: 257,
      className: 'title-cell',
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().isCompactViewEnabled;
        return (
          <div className="d-flex align-items-center justify-content-between">
            <PageLink
              className="d-flex align-items-center text-nowrap overflow-hidden"
              to={`${paths.events}/${original.id}`}
              allowOverflow={!isCompactViewEnabled}
            >
              {original.type?.name}
              <Subtypes data={original} />
            </PageLink>
            {original.is_scheduled && (
              <>
                <SvgRender
                  id={`event-table-scheduled-tooltip-${original.id}`}
                  className="text-orange"
                  src={clock}
                  style={{ width: 13, height: 13 }}
                />

                <UncontrolledTooltip
                  target={`event-table-scheduled-tooltip-${original.id}`}
                  trigger="hover"
                >
                  {'Scheduled'}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        );
      }
    },
    {
      header: 'type',
      filterLabel: 'Type',
      key: 'type_id',
      type: 'collection',
      hidden: true,
      canSort: false,
      component: CollectionFilterSelector,
      componentRest: { memoizedRequestParams: { path: 'lists', params: { list: 'event-types' } } },
      canFilter: true
    },
    {
      header: 'ID',
      key: 'id',
      type: 'string',
      canSort: true,
      canFilter: false,
      size: 65
    },
    {
      header: 'Date',
      key: 'started_at',
      type: 'date',
      canSort: true,
      canFilter: true,
      size: 229,
      component: DateFilterSelector,
      row: ({ row: { original } }) => {
        return (
          <div>
            {original.date_type === 'single' && original.started_at ? (
              <>{displayDate(original.started_at, { time: true })}</>
            ) : original.date_type === 'range' ? (
              <>
                {displayDate(original.started_at, { time: true })} -{' '}
                {displayDate(original.ended_at, { time: true })}
              </>
            ) : (
              '-'
            )}
          </div>
        );
      }
    },
    {
      header: 'Responsible',
      key: 'responsible',
      type: 'string',
      canSort: true,
      canFilter: false,
      isHandlingCompactView: true,
      size: 217,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().isCompactViewEnabled;
        return original.responsible?.full_name ? (
          <PageLink
            to={`${paths.persons}/${original.responsible.id}`}
            allowOverflow={!isCompactViewEnabled}
          >
            {original.responsible.full_name}
          </PageLink>
        ) : (
          <div className="text-secondary">Unassigned</div>
        );
      }
    },
    {
      header: 'Responsible',
      key: 'responsible_id',
      type: 'collection',
      canSort: false,
      hidden: true,
      canFilter: true,
      component: PartyCollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: {
          path: 'lists',
          params: { list: 'parties', can_login: true, ...(isOnBoard ? { onboard: true } : {}) }
        }
      }
    },
    {
      header: 'Department',
      key: 'department_ids',
      type: 'collection',
      canSort: false,
      component: DepartmentCollectionFilterSelector,
      row: ({ row: { original } }) => <Departments values={original.departments} />,
      size: 217
    },
    {
      header: 'Approvers',
      key: 'approvers',
      type: 'string',
      canSort: true,
      canFilter: false,
      hidden: isOnBoard,
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().isCompactViewEnabled;

        return (
          <NamesInTooltip
            names={(original.approvers || []).map(approver => approver.full_name)}
            allowOverflow={!isCompactViewEnabled}
          />
        );
      },
      size: 217
    },
    {
      header: 'Approvers',
      key: 'approver_id',
      type: 'collection',
      canSort: false,
      hidden: true,
      canFilter: true,
      component: PartyCollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: {
          path: 'lists',
          params: { list: 'parties', can_login: true, ...(isOnBoard ? { onboard: true } : {}) }
        }
      }
    },
    {
      header: 'Status',
      key: 'status',
      type: 'collection',
      canSort: true,
      canFilter: false,
      size: 217,
      row: ({ row: { original } }) => <Status type="events" value={original.status} />
    },
    {
      header: 'Status',
      key: 'status_id',
      type: 'collection',
      canSort: false,
      canFilter: true,
      component: CollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: { path: 'lists', params: { list: 'event-statuses' } }
      },
      hidden: true
    },
    {
      header: 'Vessel',
      key: 'vessel',
      type: 'collection',
      canSort: true,
      canFilter: false,
      hidden: isOnBoard,
      size: 145,
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().isCompactViewEnabled;
        return isOnBoard ? null : original.vessel && original.vessel.name ? (
          <PageLink
            to={`${paths.vessels}/${original.vessel.id}`}
            allowOverflow={!isCompactViewEnabled}
          >
            {original.vessel.name}
          </PageLink>
        ) : (
          <div className="text-secondary">No Vessel</div>
        );
      }
    },
    {
      header: 'Vessel',
      key: 'vessel_id',
      type: 'collection',
      canSort: false,
      canFilter: !isOnBoard,
      component: CollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: { path: 'lists', params: { list: 'vessels' } }
      },
      hidden: true
    },
    {
      header: 'Port',
      key: 'port',
      type: 'collection',
      canSort: true,
      size: 217,
      canFilter: false,
      isHandlingCompactView: true,
      row: ({ row: { original }, table: { getState } }) => {
        const isCompactViewEnabled = getState().isCompactViewEnabled;
        return original.port && original.port.name ? (
          !isOnBoard ? (
            <PageLink
              to={`${paths.ports}/${original.port.id}`}
              allowOverflow={!isCompactViewEnabled}
            >
              {original.port.name}
            </PageLink>
          ) : (
            original.port.name
          )
        ) : (
          <div className="text-secondary">No Port</div>
        );
      }
    },
    {
      header: 'Port',
      key: 'port_id',
      type: 'collection',
      canSort: false,
      canFilter: true,
      component: CollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: { path: 'lists', params: { list: 'ports' } }
      },
      hidden: true
    },
    {
      header: 'Included Modules',
      key: 'module_type_ids',
      type: 'collection',
      canSort: false,
      canFilter: true,
      component: CollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: { path: 'lists', params: { list: 'event-modules' } }
      },
      hidden: true
    },
    {
      header: 'Finding Tags',
      key: 'finding_tag_ids',
      type: 'collection',
      canSort: false,
      canFilter: true,
      component: CollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: { path: 'lists', params: { list: 'finding-tags' } }
      },
      hidden: true
    },
    {
      header: 'Audit/Inspection',
      key: 'event_audit_id',
      type: 'collection',
      canSort: false,
      canFilter: !isOnBoard,
      component: CollectionFilterSelector,
      componentRest: {
        memoizedRequestParams: { path: 'lists', params: { list: 'event-audits' } }
      },
      hidden: true
    },
    {
      header: 'Ocimf System Category',
      key: 'ocimf_system_categorization',
      component: OcimfCategoryCollectionFilterSelector,
      type: 'ocimf_system_category',
      hidden: true,
      canFilter: true
    },
    {
      header: 'Watching',
      key: 'is_watching',
      type: 'boolean',
      hidden: true,
      canFilter: !isOnBoard,
      component: BooleanFilterSelector
    },
    {
      key: 'actions',
      actions: ({ original }) => [
        {
          type: 'delete',
          hidden: isOnBoard,
          onClick: () => setDeleteModal({ isOpen: true, data: original })
        }
      ]
    }
  ];

  const table = useTable<Event>({
    label: EVENTS_TABLE_KEY,
    columns,
    navigate,
    fetchingFn: getEvents
  });

  return (
    <>
      <Table {...table} />
      <DangerousActionModal
        onAccept={async () => {
          await dispatch(deleteEvent({ id: deleteModal.data?.id }));
          table.refetchData();
        }}
        onCancel={() => setDeleteModal({ isOpen: false, data: null })}
        isOpen={deleteModal.isOpen}
        header={'Delete Event'}
      >
        <div className="text-center">
          Are you sure you want to delete <strong>{`${deleteModal.data?.name}`}</strong> ? This
          cannot be undone.
        </div>
      </DangerousActionModal>
    </>
  );
};

export default EventList;
