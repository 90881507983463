import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import BasicModal from 'common/components/modals/BasicModal';
import Textarea from 'common/components/form/inputs/Textarea';
import NumberInput from 'common/components/form/inputs/NumberInput';
import { stringField, numberField } from 'common/utils/form/fieldTypes';
import { useSelector } from 'react-redux';
import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';

import { useForm, useFormState, useActions } from 'utils/hooks';
import {
  selectMaintenanceDetaisDueOnRh,
  selectMaintenanceDetaisDueOnRhRemarks
} from 'common/components/pms/jobs/store/selectors';

const RunningHoursDueInputModal = ({ remarksModalOpen, setRemarksModalOpen }) => {
  const [remarksModalIsLoading, setRemarksModalIsLoading] = useState(false);

  const [setMaintenanceField, updateMaintenance] = useActions([
    maintenanceActions.setMaintenanceField,
    maintenanceActions.updateMaintenance
  ]);

  const maintenanceDetaisDueOnRh = useSelector(selectMaintenanceDetaisDueOnRh);
  const maintenanceDetaisDueOnRhRemarks = useSelector(selectMaintenanceDetaisDueOnRhRemarks);

  const config = {
    due_on_rh: numberField({ required: true }),
    due_on_rh_remarks: stringField({ required: true })
  };

  const { formState, collectValues, resetForm, handleSubmitError } = useForm(config, {});
  const { fields, changeField, selectField } = useFormState(formState);

  const onRemarksModalClose = () => {
    resetForm();
    setRemarksModalOpen(false);
    setRemarksModalIsLoading(false);
  };

  const onRemarksModalSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      setRemarksModalIsLoading(true);

      const { due_on_rh, due_on_rh_remarks } = values;

      await updateMaintenance({
        due_on_rh: due_on_rh || null,
        due_on_rh_remarks: due_on_rh_remarks || null
      });
      await setMaintenanceField('due_on_rh', due_on_rh, false, false);
      await setMaintenanceField('due_on_rh_remarks', due_on_rh_remarks, false, false);

      setRemarksModalOpen(false);
      setRemarksModalIsLoading(false);
    } catch (error) {
      console.error(error);
      setRemarksModalIsLoading(false);
      handleSubmitError(error);
    }
  };

  useEffect(() => {
    if (remarksModalOpen) {
      setRemarksModalIsLoading(false);
      selectField('due_on_rh')(maintenanceDetaisDueOnRh || '');
      selectField('due_on_rh_remarks')(maintenanceDetaisDueOnRhRemarks || '');
    } else {
      resetForm();
    }
  }, [remarksModalOpen]);

  return (
    <BasicModal
      isOpen={remarksModalOpen}
      toggle={onRemarksModalClose}
      header={'Change Running Hours Due'}
      body={
        <div>
          <NumberInput
            label={'Running hours due'}
            placeholder="Add value"
            className="mb-0"
            onChange={changeField('due_on_rh')}
            {...fields.due_on_rh}
          />

          <Textarea
            label="Remarks"
            rows={6}
            onChange={changeField('due_on_rh_remarks')}
            className="mt-2 mb-0"
            placeholder=""
            disabled={false}
            {...fields.due_on_rh_remarks}
          />
        </div>
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button
            color="cancel"
            className="px-0 py-1 me-4"
            onClick={onRemarksModalClose}
            disabled={remarksModalIsLoading}
          >
            CANCEL
          </Button>
          <Button
            onClick={onRemarksModalSubmit}
            disabled={remarksModalIsLoading}
            color="primary"
            className="px-4"
          >
            CHANGE
          </Button>
        </div>
      }
    />
  );
};

export default RunningHoursDueInputModal;
