import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import { get, put, post, deleteRequest, download } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import {
  parseMgaActionsColumns,
  parseMgaActionsData,
  parseMgaAccountsColumns,
  parseMgaAccountsData
} from 'common/components/mga/store/parser';
import {
  setMessages,
  setUnreadMessages,
  postMessage,
  setMarkAsRead
} from 'common/components/chatbox/store/actions';
import _orderBy from 'lodash/orderBy';
import {
  selectBaseCurrencyLabel,
  selectCurrenciesWithoutBase
} from '@/common/components/mga/store/selectors-ts';
import { selectRefetchTableTrigger } from '@/common/components/mga/store/selectors.js';

/* MGA Period */
export const getMgaPeriods = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_PERIODS.START, payload: { params } });

  return get(`/mga/periods`, params)
    .then(response => {
      const periods = _orderBy(response.data, 'ended_at', 'desc');

      dispatch({ type: TYPES.GET_MGA_PERIODS.SUCCESS, payload: periods });

      return periods;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_PERIODS.ERROR, payload: error });

      throw error;
    });
};

export const createMgaPeriod = params => dispatch => {
  dispatch({ type: TYPES.CREATE_MGA_PERIOD.START, payload: { params } });
  const { vessel_id, ...rest } = params;

  return post(vessel_id ? `/vessels/${vessel_id}/mga/periods` : `/mga/periods`, rest)
    .then(response => {
      dispatch({ type: TYPES.CREATE_MGA_PERIOD.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
      dispatch(getMgaPeriods({ vessel_id }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_MGA_PERIOD.ERROR, payload: error });

      throw error;
    });
};

export const editMgaPeriod = params => dispatch => {
  dispatch({ type: TYPES.EDIT_MGA_PERIOD.START, payload: { params } });

  return put(`/mga/periods/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.EDIT_MGA_PERIOD.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_MGA_PERIOD.ERROR, payload: error });

      throw error;
    });
};

export const setMgaPeriodForm =
  (state = {}) =>
  dispatch => {
    dispatch({ type: TYPES.SET_MGA_PERIOD_FORM, payload: { ...state } });
  };
/* -- */

/* MGA Action */
export const setMgaActionForm =
  (state = {}) =>
  dispatch => {
    dispatch({ type: TYPES.SET_MGA_ACTION_FORM, payload: { ...state } });
  };

export const getMgaActions = params => (dispatch, getState) => {
  const { table, action_type, ...rest } = params;
  const state = getState();
  const shouldRefetchTable = selectRefetchTableTrigger(state);
  const currencies = selectCurrenciesWithoutBase(state);
  const defaultCurrencyLabel = selectBaseCurrencyLabel(state);

  const columns = parseMgaActionsColumns(params, currencies, defaultCurrencyLabel);

  dispatch({
    type: TABLE_TYPES.GET_TABLE_LIST.START,
    payload: {
      params,
      table,
      // columns: shouldRefetchTable ? columns : [],
      columns,
      data: shouldRefetchTable ? undefined : []
    }
  });

  return get(`/mga/actions`, rest)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: { ...parseMgaActionsData(response.data), columns }, table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });

      throw error;
    });
};

export const getMgaAttachments = params => (dispatch, getState) => {
  const { action_id, ...rest } = params;

  dispatch({
    type: TYPES.GET_MGA_ACTION_ATTACHMENTS.START,
    payload: {
      params
    }
  });

  return get(`mga/actions/${action_id}/attachments`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_MGA_ACTION_ATTACHMENTS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_ACTION_ATTACHMENTS.ERROR, payload: { error } });

      throw error;
    });
};

export const createMgaAction = params => dispatch => {
  dispatch({ type: TYPES.CREATE_MGA_ACTION.START, payload: { params } });

  return post(`/mga/actions`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_MGA_ACTION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_MGA_ACTION.ERROR, payload: error });

      throw error;
    });
};

export const editMgaAction = params => dispatch => {
  dispatch({ type: TYPES.EDIT_MGA_ACTION.START, payload: { params } });

  return put(`/mga/actions/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.EDIT_MGA_ACTION.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.EDIT_MGA_ACTION.ERROR, payload: error });

      throw error;
    });
};

export const deleteMgaAction = params => dispatch => {
  dispatch({ type: TYPES.DELETE_MGA_ACTION.START, payload: { params } });

  return deleteRequest(`/mga/actions/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.DELETE_MGA_ACTION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_MGA_ACTION.ERROR, payload: error });

      throw error;
    });
};

export const downloadMgaActionAttachments = params => dispatch => {
  dispatch({
    type: TYPES.DOWNLOAD_MGA_ACTION_ATTACHMENTS.START,
    payload: { params }
  });

  return download(`/files/entity`, { id: params.id, type: params.type })
    .then(response => {
      dispatch({
        type: TYPES.DOWNLOAD_MGA_ACTION_ATTACHMENTS.SUCCESS,
        payload: { data: response.data, params }
      });
      return response;
    })
    .catch(error =>
      dispatch({
        type: TYPES.DOWNLOAD_MGA_ACTION_ATTACHMENTS.ERROR,
        payload: { error, params }
      })
    );
};

export const getMgaAction = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_ACTION.START, payload: { params } });

  return get(`/mga/actions/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_ACTION.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_ACTION.ERROR, payload: error });

      throw error;
    });
};

export const createMgaAccountDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.CREATE_MGA_ACCOUNT_DIGITAL_SIGNATURE.START, payload: { params } });

  return post(`/period-mga-accounts/signatures`, params)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_MGA_ACCOUNT_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_MGA_ACCOUNT_DIGITAL_SIGNATURE.ERROR, payload: error });

      throw error;
    });
};

export const deleteMgaAccountDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.DELETE_MGA_ACCOUNT_DIGITAL_SIGNATURE.START, payload: { params } });

  const { signature_id, ...rest } = params;

  return deleteRequest(`/period-mga-accounts/signatures/${signature_id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.DELETE_MGA_ACCOUNT_DIGITAL_SIGNATURE.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_MGA_ACCOUNT_DIGITAL_SIGNATURE.ERROR, payload: error });

      throw error;
    });
};

export const getMgaAccountDigitalSignatures = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_ACCOUNT_DIGITAL_SIGNATURES.START, payload: { params } });

  return get(`/period-mga-accounts/signatures`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_ACCOUNT_DIGITAL_SIGNATURES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_ACCOUNT_DIGITAL_SIGNATURES.ERROR, payload: error });

      throw error;
    });
};

export const createMgaActionDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.CREATE_MGA_ACTION_DIGITAL_SIGNATURE.START, payload: { params } });

  return post(`/mga-actions/${params.id}/signatures`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_MGA_ACTION_DIGITAL_SIGNATURE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.CREATE_MGA_ACTION_DIGITAL_SIGNATURE.ERROR, payload: error });

      throw error;
    });
};

export const deleteMgaActionDigitalSignature = params => dispatch => {
  dispatch({ type: TYPES.DELETE_MGA_ACTION_DIGITAL_SIGNATURE.START, payload: { params } });

  return deleteRequest(`/mga-actions/${params.id}/signatures/${params.signature_id}`)
    .then(response => {
      dispatch({ type: TYPES.DELETE_MGA_ACTION_DIGITAL_SIGNATURE.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DELETE_MGA_ACTION_DIGITAL_SIGNATURE.ERROR, payload: error });

      throw error;
    });
};

export const getMgaActionDigitalSignatures = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_ACTION_DIGITAL_SIGNATURES.START, payload: { params } });

  return get(`/mga-actions/${params.id}/signatures`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_ACTION_DIGITAL_SIGNATURES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_ACTION_DIGITAL_SIGNATURES.ERROR, payload: error });

      throw error;
    });
};

export const setWarningCrewContractIds = ids => dispatch => {
  dispatch({ type: TYPES.SET_WARNING_CREW_CONTRACT_IDS, payload: ids });
};

export const setWarningCrewClosingBalance = data => dispatch => {
  dispatch({ type: TYPES.SET_WARNING_CREW_CLOSING_BALANCE, payload: data });
};

export const setWarningCrewOverview = data => dispatch => {
  dispatch({ type: TYPES.SET_WARNING_CREW_OVERVIEW, payload: data });
};

/* -- */

/* MGA Ledgers */

export const getMgaAccountLedger = params => async (dispatch, getState) => {
  const { table, action_type, ...rest } = params;
  const state = getState();
  const shouldRefetchTable = selectRefetchTableTrigger(state);
  const currencies = selectCurrenciesWithoutBase(state);
  const defaultCurrencyLabel = selectBaseCurrencyLabel(state);

  const columns = await parseMgaAccountsColumns(params, false, currencies, defaultCurrencyLabel);

  dispatch({
    type: TABLE_TYPES.GET_TABLE_LIST.START,
    payload: {
      params,
      table,
      columns,
      data: shouldRefetchTable ? undefined : []
    }
  });

  return get(`/mga/account-ledger/table`, rest)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: { ...parseMgaAccountsData(response.data), columns }, table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });

      throw error;
    });
};

export const getMgaAccountsSummary = params => async (dispatch, getState) => {
  const { table, action_type, id, ...rest } = params;
  const state = getState();
  const shouldRefetchTable = selectRefetchTableTrigger(state);
  const currencies = selectCurrenciesWithoutBase(state);
  const defaultCurrencyLabel = selectBaseCurrencyLabel(state);
  const columns = await parseMgaAccountsColumns(params, true, currencies, defaultCurrencyLabel);

  dispatch({
    type: TABLE_TYPES.GET_TABLE_LIST.START,
    payload: {
      params,
      table,
      columns,
      data: shouldRefetchTable ? undefined : []
    }
  });

  return get(`/mga/accounts/${id}/summary`, rest)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: { ...parseMgaAccountsData(response.data), columns }, table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });

      throw error;
    });
};

export const getMgaAccountBalances = params => dispatch => {
  dispatch({ type: TYPES.GET_ACCOUNT_BALANCES.START, payload: { params } });

  return get(`/mga/accounts/${params.id}/balance`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_ACCOUNT_BALANCES.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_ACCOUNT_BALANCES.ERROR, payload: error });

      throw error;
    });
};

export const getMgaWarnings = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_WARNINGS.START, payload: { params } });

  return get(`/mga/warnings`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_WARNINGS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_WARNINGS.ERROR, payload: error });

      throw error;
    });
};

export const getMgaInfo = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_INFO.START, payload: { params } });

  return get(`/mga/info`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_INFO.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_INFO.ERROR, payload: error });

      throw error;
    });
};

export const getMgaBalanceSheetReport = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_BALANCE_SHEET_REPORT.START, payload: { params } });

  return get(`/reports/mga-balance-sheet`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_BALANCE_SHEET_REPORT.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_BALANCE_SHEET_REPORT.ERROR, payload: error });

      throw error;
    });
};
/* -- */

/* SETTINGS */

export const getMgaSettings = params => dispatch => {
  dispatch({ type: TYPES.GET_MGA_SETTING.START, payload: { params } });

  return get('/mga/settings')
    .then(response => {
      dispatch({ type: TYPES.GET_MGA_SETTING.SUCCESS, payload: { data: response.data, params } });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_SETTING.ERROR, payload: error });

      throw error;
    });
};

/* */

/* CHAT ACTIONS */

export const getMgaChatMessages = params => dispatch => {
  const { id } = params;

  dispatch({ type: TYPES.GET_MGA_CHAT_MESSAGES.START, payload: { params } });

  return get(`/mga/periods/${id}/messages`)
    .then(response => {
      dispatch(setMessages(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_MGA_CHAT_MESSAGES.ERROR, payload: error });
      throw error;
    });
};

export const postMgaChatMessage = params => dispatch => {
  const { id, ...rest } = params;

  dispatch({ type: TYPES.POST_MGA_CHAT_MESSAGE.START, payload: { params } });

  return post(`/mga/periods/${id}/messages`, rest)
    .then(response => {
      dispatch(postMessage(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.POST_MGA_CHAT_MESSAGE.ERROR, payload: error });
      throw error;
    });
};

export const getMgaUnreadChatMessages = params => dispatch => {
  const { id } = params;

  dispatch({
    type: TYPES.GET_MGA_UNREAD_MESSAGES.START,
    payload: { params }
  });

  return get(`/mga/periods/${id}/messages/get-unread`)
    .then(response => {
      dispatch(setUnreadMessages({ count: response.data.count }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_MGA_UNREAD_MESSAGES.ERROR,
        payload: error
      });
      throw error;
    });
};

export const mgaChatMessageMarkAsRead = params => dispatch => {
  const { id } = params;
  dispatch({
    type: TYPES.MGA_CHAT_MESSAGE_MARK_AS_READ.START,
    payload: { params }
  });

  return put(`/mga/periods/${id}/messages/mark-as-read`)
    .then(response => {
      dispatch(setMarkAsRead(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.MGA_CHAT_MESSAGE_MARK_AS_READ.ERROR,
        payload: error
      });
      throw error;
    });
};

/* */
