import React, { useState } from 'react';
import Comment from './Comment';
import NewComment from './NewComment';
import spinner from 'common/assets/svg/common/spinner.svg';
import SvgRender from 'common/components/general/SvgRender';
import * as jobChecklistActions from 'common/components/jobs/_base/modules/checklist/store/actions';
import { useActions } from 'utils/hooks';

const CommentsList = ({ data, parentRef, jobId, optionId, comments_count }) => {
  const [loading, setLoading] = useState(false);

  const [getComments, showLessComments] = useActions([
    jobChecklistActions.getComments,
    jobChecklistActions.showLessComments
  ]);

  const getOptionsComments = async () => {
    setLoading(true);
    await getComments({ job_id: jobId, option: optionId });
    setLoading(false);
  };

  const onShowLess = () => {
    showLessComments({ option: optionId });
  };

  return data?.length ? (
    <div>
      <NewComment jobId={jobId} optionId={optionId} parentRef={parentRef} />
      {data.map((comment, i) => (
        <div
          className={`d-flex position-relative ${i !== data.length - 1 ? 'pb-2' : 'pb-1'}`}
          key={comment.id}
        >
          {i !== data.length - 1 && <div className="line" />}
          <div className="circle" />
          <Comment comment={comment} jobId={jobId} optionId={optionId} parentRef={parentRef} />
        </div>
      ))}
      {loading ? (
        <div className="ms-3">
          <SvgRender src={spinner} style={{ width: 20, height: 20 }} />
        </div>
      ) : data.length === comments_count && comments_count <= 2 ? null : data.length ===
        comments_count ? (
        <div className="text-primary ms-3 fs-12 cursor-pointer" onClick={onShowLess}>
          Show less
        </div>
      ) : (
        <div className="text-turquoise ms-3 fs-12 cursor-pointer" onClick={getOptionsComments}>
          Load more
        </div>
      )}
    </div>
  ) : null;
};

export default CommentsList;
