import React from 'react';

const Status = ({ activeStatus, className = '' }) => {
  return activeStatus ? (
    <span className={`view-status view-status--${activeStatus.color} ${className}`}>
      {activeStatus.name}
    </span>
  ) : null;
};

export default Status;
