import TABLE_TYPES from 'common/components/table/store/types';
import { get, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getLinkedPurchaseCases = createAsyncThunk(
  'GET_LINKED_PURCHASE_CASES',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, table, ...rest } = params;

    dispatch({
      type: TABLE_TYPES.GET_TABLE_LIST.START,
      payload: {
        params,
        table
      }
    });

    try {
      const res = await get(`/events/${id}/purchasing-requisitions`, rest);
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: res.data, table }
      });

      return res.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      return rejectWithValue(error);
    }
  }
);

export const linkPurchaseCase = createAsyncThunk(
  'LINK_PURCHASE_CASE',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/events/${id}/purchasing-requisitions`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLinkedPurchaseCase = createAsyncThunk(
  'DELETE_LINKED_PURCHASE_CASE',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, caseId, ...rest } = params;

    try {
      const res = await deleteRequest(`/events/${id}/purchasing-requisitions/${caseId}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
