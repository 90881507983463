import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const PurchasingCategorySelector = ({
  filter,
  onChange,
  isMulti,
  autoFocus = true,
  styled,
  gray,
  ...rest
}) => {
  const [selectedPurchasingCategories, setSelectedPurchasingCategories] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'purchasing-categories' },
    { selected: selectedPurchasingCategories, setSelected: setSelectedPurchasingCategories }
  );

  const SelectTag = styled ? StyledSelect : Select;

  return (
    <SelectTag
      autoFocus={autoFocus}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      value={
        filter?.value
          ? selectedPurchasingCategories.filter(el => filter?.value?.map(Number).includes(el.id))
          : []
      }
      defaultOptions={() => getInitialAsyncValues('purchasing-categories')}
      loadOptions={search => getAsyncOptions(search, 'purchasing-categories')}
      onChange={(selected, options) => {
        setSelectedPurchasingCategories(isMulti ? selected : [selected]);
        onChange(
          { value: selected ? (isMulti ? selected.map(s => s.id) : selected) : '' },
          options
        );
      }}
      {...rest}
    />
  );
};

export default PurchasingCategorySelector;
