// import Account from 'views/account';

export default [
  {
    type: 'private',
    exact: true,
    path: '/account',
    app: true,
    // component: Account
    component: () => null
  }
];
