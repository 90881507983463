import _isArray from 'lodash/isArray';

const DropdownView = ({ value }) => {
  if (!value || !_isArray(value)) return '-';

  const stringToRender = value.map((item, i) => {
    if (value.length === i + 1) {
      return item;
    }
    return item + ', ';
  });

  return stringToRender || '-';
};

export default DropdownView;
