import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import ShadowBox from 'common/components/general/ShadowBox';
import { components } from 'react-select';
import { Row, Col } from 'reactstrap';

const SelectPurchasingItems = ({ onChange, formState }) => {
  const getSelectedItems = useCallback(
    formState => formState.map(({ state }) => state?.id?.value),
    []
  );

  return (
    <ShadowBox className="p-2 mb-2" color="light-1" flat>
      <Row>
        <Col xs={7}>
          <AsyncSelector
            className="mb-0"
            onChange={option =>
              onChange({
                code: option?.code,
                title: option?.description,
                unit: option?.purchasing_unit?.label,
                entity_type: 'item',
                id: option?.id,
                group: { id: option.store_group?.id, name: option.store_group?.name }
              })
            }
            placeholder="Select an item to add in your list by 6-digit code or description"
            type="stores"
            getOptionLabel={option => option.description}
            listParams={{ exclude_ids: getSelectedItems(formState) }}
            defaultOptionsTriggerChange={formState?.length}
            components={{ Option }}
          />
        </Col>
      </Row>
    </ShadowBox>
  );
};

SelectPurchasingItems.propTypes = {
  onChange: PropTypes.func,
  formState: PropTypes.array.isRequired
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="w-auto">
      <div className="d-flex align-items-center fs-14">
        <strong>{props.data?.code}</strong>
        <span className="ms-1">{props.data.description}</span>
      </div>
    </components.Option>
  );
};

export default SelectPurchasingItems;
