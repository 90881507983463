export const mainContactInfoConfig = {
  fields: [
    {
      label: 'EMAIL',
      key: 'email',
      type: 'email',
      render: false,
      validations: { email: true }
    },
    {
      type: 'multiple',
      key: 'phones',
      label: 'PHONE',
      inline: true,
      render: false,
      data: [
        { label: 'TYPE', key: 'type' },
        { label: 'PHONE', key: 'phone' }
      ]
    },
    {
      type: 'multiple',
      key: 'emails',
      render: false,
      data: [
        {
          label: 'type',
          key: 'type',
          type: 'string'
        },
        {
          label: 'value',
          key: 'value',
          type: 'email',
          validations: { email: true }
        }
      ]
    },
    {
      key: 'addresses',
      type: 'multiple',
      render: false,
      data: [
        {
          label: 'DESCRIPTION',
          key: 'description',
          type: 'string'
        },
        {
          label: 'ADDRESS',
          key: 'address',
          type: 'string'
        },
        {
          label: 'CITY',
          key: 'city',
          type: 'string'
        },
        {
          label: 'ZIP CODE',
          key: 'zip',
          type: 'string'
        },
        {
          label: 'VALID',
          key: 'valid_from',
          type: 'date'
        },
        {
          label: 'VALID TO',
          key: 'valid_to',
          type: 'date',
          render: false
        },
        {
          label: 'COUNTRY',
          key: 'country.name',
          select_key: 'country',
          type: 'select',
          validations: { required: true }
        }
      ]
    }
  ]
};
