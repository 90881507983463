import React from 'react';
import { Label } from './Field';
import ListSelect from 'common/components/selectors/ListSelect';
import PropTypes from 'prop-types';

const TicketingTypeSelector = ({ value, onChange, isForCrew = false }) => {
  return (
    <div className="d-flex align-items-center w-100p">
      <Label>Type</Label>
      <ListSelect
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        list="ticketing-types"
        isAsync={false}
        invisible={true}
        placeholder="Select type"
        className="mb-0 flex-1"
        value={value}
        onChange={(_, selectedType) => onChange(selectedType)}
        filterOption={opt => (!isForCrew ? !opt?.data?.is_for_crew : true)}
      />
    </div>
  );
};

TicketingTypeSelector.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isForCrew: PropTypes.bool
};

export default TicketingTypeSelector;
