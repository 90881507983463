import React, { useEffect } from 'react';
import { useActions } from 'utils/hooks';

import NumberField from 'captain-reports/templates/inputs/NumberField';

import { consumption_per_dg_keys } from 'captain-reports/templates/config/bunkerConsumptions';
import { numberToStr, trimFloatNumber } from 'common/utils/numbers';
import { Row, Col } from 'reactstrap';

import useValue from 'captain-reports/utils/useValue';
import useReportTimeDiff from 'captain-reports/utils/useReportTimeDiff';
import * as reportActions from 'captain-reports/store/actions';
import { setLastUpdatedGeneratorTabIndex } from '@/captain-reports/store/actions.js';
import { useAppDispatch } from '@/store/hooks';

export const MetricTonesPerDay = ({
  value,
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const mins_since_last_report = useReportTimeDiff();
  const consumptionRatekey = field?.rateLabel;
  const [setValue] = useActions([reportActions.setValue]);

  useEffect(() => {
    // We use the rateLabel to match the total field.key with the corresponding rate key
    if (consumptionRatekey && mins_since_last_report && (value || value === 0)) {
      setValue({
        key: consumptionRatekey,
        value: trimFloatNumber((value * 24 * 60) / mins_since_last_report, 2, 2),
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [value, consumptionRatekey, mins_since_last_report]);

  return (
    <div
      className={`fs-12 fw-bold lh-1 mt-1 text-end${
        consumption_per_dg_keys.includes(field.key) ? ' text-violet' : ''
      }`}
    >
      {mins_since_last_report
        ? numberToStr((value * 24 * 60) / mins_since_last_report, 2, 2)
        : null}
    </div>
  );
};

const ConsumptionInput = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  className
}) => {
  const dispatch = useAppDispatch();

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  return (
    <Row className="align-items-center">
      <Col xs={7}>
        <NumberField
          className={className || ''}
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          onChangeFallback={() =>
            dispatch(setLastUpdatedGeneratorTabIndex(field.generatorActiveTab))
          }
          subGroupIndex={subGroupIndex}
          allowNegative={false}
        />
      </Col>
      <Col xs={5}>
        <MetricTonesPerDay
          value={value}
          field={field}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </Col>
    </Row>
  );
};

export default ConsumptionInput;
