import React, { useState, useEffect, Fragment } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  typeOfLoggedInUser,
  selectJobField,
  selectIsJobLocked,
  selectFilterJobStatuses,
  checkIfEditMode,
  selectHasBasicJobLayout,
  selectOnboardUserIsAuthorized,
  selectOfficeUserIsAuthorized
} from 'common/components/jobs/_base/store/selectors';
import {
  selectIsPms,
  selectFilterPMSJobStatuses
} from 'common/components/pms/jobs/store/selectors';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';
import { selectJobHasForms } from 'common/components/jobs/_base/modules/forms/store/selectors';

import BaseStatusLabel from 'common/components/statuses/BaseStatusLabel';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as jobFormsActions from 'common/components/jobs/_base/modules/forms/store/actions';
import { selectRestrictedFields } from 'store/jobs-fields/selectors';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Resubmitted from 'common/components/forms/_components/Resubmitted';

const BaseStatusInput = ({
  hideLabel,
  value,
  onChange,
  statuses,
  itemClassName,
  itemStyle,
  avoidCheck,
  handleTooltipVisibility,
  onModalSend,
  setShowModal,
  showModal,
  disabled,
  menuProps = {},
  isJobLocked
}) => {
  const [dropdownOpen, setOpen] = useState(false);

  const closeModal = () => setShowModal(false);
  const toggle = () => setOpen(!dropdownOpen);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const hasBasicJobLayout = useSelector(selectHasBasicJobLayout);

  return (
    <div
      className={`job-input job-input--status status form-group-spacing ${
        hasBasicJobLayout ? 'status-regular w-100p' : ''
      }`}
    >
      {hideLabel ? null : <Label className="form-label ">STATUS</Label>}

      <div>
        <ButtonDropdown
          className={`${isJobLocked ? 'hide-dropdown-toggle' : ''}`}
          disabled={disabled || isJobLocked}
          isOpen={dropdownOpen}
          toggle={toggle}
          direction="down"
        >
          <DropdownToggle
            caret
            color="link"
            className={isJobLocked ? 'disable-toggle-dropdown' : ''}
          >
            {value ? (
              <BaseStatusLabel
                value={value?.name}
                color={value?.color}
                label={value?.label}
                showLockIcon={isJobLocked}
                className="fs-10"
              />
            ) : (
              <BaseStatusLabel value={'Select Status'} className="fs-10"></BaseStatusLabel>
            )}
          </DropdownToggle>
          <DropdownMenu
            className={`${dropdownOpen ? 'd-flex flex-column' : ''} status-dropdown mt-1`}
            {...menuProps}
          >
            {statuses.map((st, index) => {
              const progress = st.progress;

              if (st.id !== value) {
                return (
                  <Fragment key={st.id}>
                    <DropdownItem
                      key={st.id}
                      tag="div"
                      onClick={() => onChange(st)}
                      className={itemClassName(progress, st)}
                      style={itemStyle(progress, index)}
                      disabled={isOnBoard ? !st.can_select_from_onboard : false}
                    >
                      <BaseStatusLabel
                        className="w-100p no-border-radius cursor-pointer fs-10"
                        color={st?.color}
                        value={st?.name}
                        label={st?.label}
                        svgStyle={{ width: 14, height: 14 }}
                      />
                      {!avoidCheck && handleTooltipVisibility(progress) ? (
                        <div className="restricted-status-custom-tooltip">
                          You don't have permissions to select this option
                        </div>
                      ) : null}
                    </DropdownItem>
                  </Fragment>
                );
              }

              return null;
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      <DangerousActionModal
        transparent
        action={'send'}
        onAccept={onModalSend}
        actionHoverColor="primary"
        closeModal={closeModal}
        isOpen={showModal}
        actionText={'SEND'}
        header={isOnBoard ? 'Send to Office' : 'Send to Vessel'}
        body={
          isOnBoard
            ? `Are you sure you want to send this Job to Office? Editing from onboard will be locked.`
            : `Are you sure you want to send this Job to Vessel? Editing from the office will be locked.`
        }
      />
    </div>
  );
};

export const JobStatusDropdown = ({
  isForVessel,
  isJobLocked,
  status,
  hideLabel,
  avoidCheck = false,
  onChange,
  disabled,
  className,
  vesselJobsOnly
}) => {
  const [showModal, setShowModal] = useState(false);
  const [tempStatus, setTempStatus] = useState(null);
  const isResubmitted = useSelector(state => selectJobField(state, 'resubmitted'));
  const jobStatuses = useSelector(selectJobStatuses);
  const restrictedStatusField = useSelector(selectRestrictedFields)?.find(
    item => item.field === 'status_id'
  );
  const isVisible = useSelector(selectIfFieldIsVisible('status_id'));
  const isJobEditMode = useSelector(checkIfEditMode);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const isPms = useSelector(selectIsPms);
  const loggedInUser = useSelector(typeOfLoggedInUser);

  const filteredJobStatuses = useSelector(state =>
    isPms ? selectFilterPMSJobStatuses(state) : selectFilterJobStatuses(state, vesselJobsOnly)
  );

  const restrictedStatuses = filteredJobStatuses.map(status => ({
    status: status.progress,
    value: _get(restrictedStatusField, `settings.${status.progress}`, null),
    name: status.name,
    id: status.id
  }));

  const isUserAllowedToEdit = progress => {
    if (isPms || avoidCheck) return true;

    if (avoidCheck) return true;

    if (_isEmpty(loggedInUser)) return true;

    return restrictedStatuses.some(item => {
      if (item.status === progress && !item.value) {
        return true;
      }

      return (
        item.status === progress &&
        item.value &&
        item.value.some(
          v =>
            (v === loggedInUser.approvers.name && loggedInUser.approvers.value) ||
            (v === loggedInUser.assignee.name && loggedInUser.assignee.value) ||
            (v === loggedInUser.creator.name && loggedInUser.creator.value)
        )
      );
    });
  };

  const handleTooltipVisibility = progress => {
    // Tooltip should be visible only to users that are not able to change this status
    if (isPms || avoidCheck) return false;

    return _isEmpty(loggedInUser)
      ? false
      : (!loggedInUser.assignee.value ||
          !loggedInUser.approvers.value ||
          !loggedInUser.creator.value) &&
          !isUserAllowedToEdit(progress);
  };

  const handleClassName = (progress, st) => {
    // Function to add border top classname only in first item of statuses that are restricted
    if (isPms || avoidCheck) return '';

    return (
      !isUserAllowedToEdit(progress) &&
      restrictedStatuses.filter(item => item.status === progress && item.id !== status)[0].name ===
        st.name
    );
  };

  useEffect(() => {
    // Reset the status on create mode, when isForVessel switch changes and then selected status is not for office or vessel jobs
    if (status && !isJobEditMode) {
      const selectedStatus = jobStatuses.find(s => s.id === status);

      if (!isForVessel && !selectedStatus?.is_for_office) {
        onChange(filteredJobStatuses.find(s => s.is_for_office)?.id);
      }
    }
  }, [isForVessel, isJobEditMode]);

  const onChangeStatus = status => {
    if (isJobLocked) return;

    if (status.edit_side === (isOnBoard ? 'office' : 'vessel')) {
      setShowModal(true);
      setTempStatus(status);
    } else {
      if (isUserAllowedToEdit(status.progress)) {
        onChange(status.id);
      }
    }
  };

  const onModalSend = () => {
    if (isUserAllowedToEdit(tempStatus.progress)) {
      onChange(tempStatus.id);
    }
    setShowModal(false);
  };

  if (!isVisible && !avoidCheck) return null;

  return (
    <div className={`d-flex align-items-center w-100p ${className || ''}`}>
      <BaseStatusInput
        hideLabel={hideLabel}
        isJobLocked={isJobLocked}
        value={
          status
            ? status
            : filteredJobStatuses && filteredJobStatuses[0]
            ? filteredJobStatuses[0].id
            : null
        }
        onChange={onChangeStatus}
        statuses={filteredJobStatuses}
        itemClassName={(progress, st) =>
          `${handleClassName(progress, st) ? 'border-top' : ''} job-profile__left__status`
        }
        itemStyle={(progress, index) =>
          isPms || avoidCheck
            ? {}
            : { order: isUserAllowedToEdit(progress) ? index : restrictedStatuses.length }
        }
        avoidCheck={avoidCheck}
        handleTooltipVisibility={handleTooltipVisibility}
        onModalSend={onModalSend}
        setShowModal={setShowModal}
        showModal={showModal}
        disabled={disabled}
        menuProps={{
          modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }],
          strategy: 'fixed'
        }}
      />

      <Resubmitted
        className={`cms-4 ${isPms ? 'mb-2' : 'height-24'} `}
        isResubmitted={isResubmitted}
      />
    </div>
  );
};

const JobStatus = ({ hideLabel, avoidCheck, lockedTooltip, ...rest }) => {
  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));

  const isOnboard = useSelector(state => state.isOnBoard);
  const canEdit = useSelector(state =>
    isOnboard ? selectOnboardUserIsAuthorized(state) : selectOfficeUserIsAuthorized(state)
  );

  const isJobLocked = useSelector(selectIsJobLocked);
  const statusId = useSelector(state => selectJobField(state, 'status_id'));
  const jobStatuses = useSelector(selectJobStatuses);

  const id = useSelector(state => selectJobField(state, 'id'));

  const [setJobField] = useActions([jobProfileActions.setJobField]);
  const jobHasForms = useSelector(selectJobHasForms);

  const [getJobForms] = useActions([jobFormsActions.getJobForms]);

  const handleOnChange = async new_status_id => {
    await setJobField('status_id', new_status_id, true);

    if (jobHasForms) {
      getJobForms(id);
    }
  };

  const { tooltipID, avoidRender } = useTooltipID('status-locked-tooltip');

  if (avoidRender) return null;

  return (
    <div id={tooltipID}>
      <JobStatusDropdown
        hideLabel={hideLabel}
        avoidCheck={avoidCheck}
        isForVessel={isForVessel}
        isJobLocked={isJobLocked}
        status={jobStatuses.find(st => st.id === statusId)}
        onChange={handleOnChange}
        disabled={!canEdit}
        {...rest}
      />

      {isJobLocked ? (
        <Tooltip
          target={tooltipID}
          innerClassName="min-width-fit max-width-fit"
          placement={'top-start'}
        >
          {isOnboard
            ? 'This job is locked onboard. Only the Office can edit it'
            : 'This job is locked in the Office. Only the vessel can edit it'}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default JobStatus;
