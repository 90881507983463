import PendingLabel from 'common/components/labels/PendingLabel';

const PendingAcknowledge = ({ version }) => {
  if (version?.status === 'active' && !version.current_user_acknowledged) {
    return <PendingLabel label="Pending Acknowledge" isPending />;
  }

  return null;
};

export default PendingAcknowledge;
