import React, { useEffect, useState } from 'react';
import useRouter from 'use-react-router';
import Evaluations from './table';
import CreateHeader from './headers/CreateHeader';
import ViewHeader from './headers/ViewHeader';
import EvaluationForm from './form';

import _get from 'lodash/get';

import * as evaluationTemplateActions from 'crew/store/evaluations/templates/actions';
import { usePrevious, useActions } from 'utils/hooks';
import { useHistory } from 'react-router-dom';
import {
  selectCrewEvaluations,
  selectEvaluationEditing
} from 'crew/store/evaluations/profile/selectors';
import { selectTabView } from '@/crew/store/evaluations/profile/selectors-ts.ts';
import Fade from 'react-reveal/Fade';
import paths from 'routing/routes/_paths';
import { useAppSelector } from '@/store/hooks';

const Evaluation = ({ setCustomTitle, getPromotionPlanActive, components, isOnboard }) => {
  const [vessel, setVessel] = useState(null);
  const [contact, setContact] = useState(null);
  const { match } = useRouter();
  const history = useHistory();
  const [clearCrewEvaluationTemplate] = useActions([
    evaluationTemplateActions.clearCrewEvaluationTemplate
  ]);
  const tabView = useAppSelector(selectTabView);
  const path = _get(match, 'path', '');
  const view = _get(
    match,
    'params.view',
    path.includes('compare-evaluations') ? 'compare-evaluations' : 'table'
  );

  const evaluationID = _get(match, 'params.evaluationID', null);
  const evaluations = useAppSelector(selectCrewEvaluations);
  const isEditingEvaluation = useAppSelector(selectEvaluationEditing);
  const previousView = usePrevious(view);
  const [animatedView, setAnimatedView] = useState(view);

  useEffect(() => {
    if (previousView !== view) {
      setCustomTitle(null);

      if (view === 'create') setCustomTitle(<CreateHeader />);
      if (view === 'view' || view === 'draft') setCustomTitle(<ViewHeader />);
      if (view === 'compare-evaluations') setCustomTitle(<ViewHeader />);

      clearCrewEvaluationTemplate();

      setTimeout(() => {
        setAnimatedView(view);
      }, 300);
    }
  }, [view]);

  const onClick = () => {
    history.push(`${paths.crew}/${match?.params?.id}/evaluation/compare-evaluations`);
  };

  return (
    <div
      className={`crew-profile__evaluation crew-profile__evaluation--${view} ${
        view === 'compare-evaluations' ? 'animated' : ''
      } ${isEditingEvaluation ? 'editing' : ''}`}
    >
      <Fade duration={1400} key={animatedView}>
        {animatedView === 'table' ? (
          <Evaluations
            id={match.params.id}
            isOnboard={isOnboard}
            components={components}
            vessel={vessel}
            setVessel={setVessel}
            contact={contact}
            setContact={setContact}
          />
        ) : animatedView === 'compare-evaluations' ? (
          <components.EvaluationComparison id={match.params.id} components={components} />
        ) : (
          <EvaluationForm
            getPromotionPlanActive={getPromotionPlanActive}
            evaluationID={evaluationID}
            isDraft={view === 'draft'}
            isOnboard={isOnboard}
            components={components}
          />
        )}
      </Fade>

      {isOnboard ? (
        <></>
      ) : view === 'table' || view === 'compare-evaluations' ? (
        <components.EvaluationComparisonButton
          onClick={onClick}
          view={view}
          animatedView={animatedView}
          hidden={(animatedView === 'table' && !evaluations?.length) || tabView === 'given'}
        />
      ) : null}
    </div>
  );
};

export default Evaluation;
