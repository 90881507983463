import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <div>
    <ContentLoader
      speed={1}
      preserveAspectRatio="none"
      width="100%"
      height="120px"
      viewBox="0 0 100 10"
      backgroundColor="#f5f5f5"
      foregroundColor="#ebebeb"
    >
      <rect x="0" y="0" rx="0.1" ry="0.1" width="8" height="2.5" />
      <rect x="9.5" y="0" rx="0.1" ry="0.1" width="88" height="2.5" />
      <rect x="0" y="3" rx="0.1" ry="0.1" width="8" height="2.5" />
      <rect x="9.5" y="3" rx="0.1" ry="0.1" width="88" height="2.5" />
      <rect x="0" y="6" rx="0.1" ry="0.1" width="8" height="2.5" />
      <rect x="9.5" y="6" rx="0.1" ry="0.1" width="88" height="2.5" />
    </ContentLoader>
  </div>
);

export default Loader;
