import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const JobTriggerPrototypeSelector = ({
  filter,
  onChange,
  isMulti,
  listParams,
  value,
  showDefaultOptions = true
}) => {
  const [selectedPrototypes, setSelectedPrototypes] = useState([]);

  const selectedValue = filter?.value || value;

  useFilterSelector(
    selectedValue,
    { list: 'job-trigger-prototypes', listParams },
    { selected: selectedPrototypes, setSelected: setSelectedPrototypes }
  );

  return (
    <Select
      autoFocus
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.title}
      isAsync
      defaultOptions={() =>
        showDefaultOptions
          ? getInitialAsyncValues('job-trigger-prototypes', null, null, listParams)
          : []
      }
      isMulti={isMulti}
      value={
        isMulti
          ? selectedValue?.length
            ? getFilterSelectorValues(selectedPrototypes, selectedValue, isMulti)
            : []
          : selectedValue?.value || selectedValue
      }
      loadOptions={search => getAsyncOptions(search, 'job-trigger-prototypes', listParams)}
      onChange={selected => {
        setSelectedPrototypes(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
    />
  );
};

export default JobTriggerPrototypeSelector;
