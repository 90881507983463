import React from 'react';
import { useFormState } from 'utils/hooks';
import { Row, Col } from 'reactstrap';
import NumberInput from 'common/components/form/inputs/NumberInput';
import CircledButton from 'common/components/buttons/CircledButton';
import { containedInSearch } from './helpers';

const FieldsItemsRow = React.memo(({ state, index, onRemove, searchText }) => {
  const { fields, selectField } = useFormState(state);
  const { title, unit, rob, quantity, code, previous_rob } = fields;

  if (searchText && !containedInSearch(searchText, { title: title.value, code: code.value }))
    return null;

  return (
    <Row
      className="text-primary fs-12 bg-shades-1 border-radius-5 align-items-center cmb-2 ps-1 pe-4 cpy-2 position-relative"
      noGutters
    >
      <Col xs={7}>
        {code.value}. {title.value}
      </Col>
      <Col xs={2}>{unit.value}</Col>
      <Col xs={1} className="text-end cpe-12">
        {previous_rob.value}
      </Col>
      <Col xs={2}>
        <NumberInput
          className="mb-0 pb-0"
          onChange={e => selectField('quantity')(e.target.value)}
          {...quantity}
          placeholder={'Value'}
        />
      </Col>
      {!previous_rob.value ? (
        <CircledButton
          type="remove"
          svgStyle={{ width: 8, height: 8 }}
          style={{ width: 14, height: 14 }}
          onClick={() => onRemove('items', index)}
          className="position-absolute right-8 w-auto"
        />
      ) : null}
    </Row>
  );
});

export default FieldsItemsRow;
