import { Button } from 'reactstrap';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { SerializedStyles } from '@emotion/react';

type ButtonFilterProps = {
  onClick: () => void;
  className?: string;
  isActive?: boolean;
  label?: ReactNode;
  innerClassName?: string;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  css?: SerializedStyles;
};

const ButtonFilter: FC<PropsWithChildren<ButtonFilterProps>> = ({
  onClick,
  className = '',
  isActive,
  label,
  children,
  innerClassName = '',
  disabled,
  size = 'sm',
  css
}) => {
  return (
    <Button
      type="button"
      color="link"
      className={`d-inline-flex align-items-center btn-status-filter ${
        isActive ? 'active' : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      size={size}
      css={css}
    >
      <span className="fs-12 lh-1 fw-medium btn-status-filter__text">{label}</span>
      <span className={`btn-status-filter__num ${innerClassName}`}>{children}</span>
    </Button>
  );
};

export default ButtonFilter;
