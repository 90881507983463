import FileButton from 'common/components/buttons/FileButton';
import FileSyncTooltip from '@/ts-common/components/form/inputs/upload-files/FileSyncTooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import SvgRender from 'common/components/general/SvgRender';
import { isFileSynced } from '@/api/files/api';

const AttachmentFile = ({ onClick, size, file, icon, index, className }) => {
  const { tooltipID, avoidRender } = useTooltipID(`download-attachment-file`);
  const { is_synced } = isFileSynced(file);

  if (avoidRender) return null;

  return (
    <>
      <FileButton
        key={file.id}
        className={`cme-4 ${className}`}
        onClick={e => (is_synced ? onClick(e, file) : null)}
        id={tooltipID}
        size={size}
        dataCy={`file-button-${index + 1}`}
      >
        <SvgRender src={icon} className="p-1" />
      </FileButton>

      <FileSyncTooltip file={file} tooltipID={tooltipID} />
    </>
  );
};
export default AttachmentFile;
