import { Popover, PopoverBody, Button } from 'reactstrap';
import { options, mode, pickers } from 'common/components/form/inputs/date/_constants';
import { useEffect, useMemo } from 'react';
import useOnClickOutside from 'common/utils/hooks/useOnClickOutside';

import SvgRender from 'common/components/general/SvgRender';
import range from 'common/assets/svg/common/arrows/range-arrow.svg';
import clock from 'common/assets/svg/common/clock-line.svg';
import _pickBy from 'lodash/pickBy';
import _isFunction from 'lodash/isFunction';

const Body = ({
  scheduleUpdate,
  isOpen,
  toggle,
  activeMode,
  availableModes,
  toggleTime,
  onModeChange,
  hasTime,
  showTime,
  value,
  dateRange,
  onChange,
  ...rest
}) => {
  const Picker = useMemo(() => (pickers[activeMode] ? pickers[activeMode] : null), [activeMode]);
  const modes = useMemo(
    () => options.filter(opt => availableModes.includes(opt.value)),
    [availableModes]
  );
  const shouldHideOptions = modes?.length <= 1 && !_isFunction(toggleTime);

  useEffect(() => {
    if (!isOpen || !scheduleUpdate) return;

    const timer = setTimeout(() => scheduleUpdate(), 200);

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen, scheduleUpdate]);

  return (
    <PopoverBody>
      {shouldHideOptions ? null : (
        <div className="date-input-popup__options">
          {modes.map(opt => (
            <Button
              color="link"
              key={opt.value}
              type="button"
              className={activeMode === opt.value ? 'active' : ''}
              onClick={() =>
                onModeChange && opt.value !== activeMode
                  ? onModeChange(opt.value, { isRange: opt.value?.includes('range') })
                  : null
              }
            >
              <SvgRender src={opt.icon} style={{ width: 16, height: 16 }} />
              {Object.keys(_pickBy(mode.range, m => m === opt.value))?.length > 0 ? (
                <SvgRender src={range} style={{ width: 12, height: 16 }} />
              ) : (
                <div className="cps-12" />
              )}
              <span className="ms-1">{opt.label}</span>
            </Button>
          ))}

          {hasTime && toggleTime ? (
            <>
              <div className="date-input-popup__options-seperator mt-auto mb-1"></div>
              <Button
                color="link"
                type="button"
                className={showTime ? 'active' : ''}
                onClick={() => toggleTime(!showTime)}
              >
                <SvgRender src={clock} style={{ width: 16, height: 16 }} className="me-1" />
                <span className="cps-12">Show Time</span>
              </Button>
            </>
          ) : null}
        </div>
      )}

      <div className={`date-input-popup__picker ${activeMode}`}>
        <Picker
          value={value}
          dateRange={dateRange}
          onChange={onChange}
          hasTime={hasTime}
          showTime={showTime}
          isOpen={isOpen}
          activeMode={activeMode}
          {...rest}
        />
      </div>
    </PopoverBody>
  );
};

const Popup = ({
  isOpen,
  toggle,
  close,
  target,
  activeMode,
  value,
  dateRange,
  onChange,
  showTime,
  hasTime,
  toggleTime,
  availableModes = [],
  onModeChange,
  popoverProps = {},
  ...rest
}) => {
  useOnClickOutside('.date-input-popover', close);

  return (
    <Popover
      placement={'auto-start'}
      isOpen={isOpen}
      target={target}
      className="date-input-popup"
      popperClassName="date-input-popover"
      toggle={toggle}
      fade={false}
      key={activeMode}
      {...popoverProps}
    >
      {exposedProps => (
        <Body
          isOpen={isOpen}
          toggle={toggle}
          activeMode={activeMode}
          availableModes={availableModes}
          dateRange={dateRange}
          hasTime={hasTime}
          onChange={onChange}
          onModeChange={onModeChange}
          showTime={showTime}
          toggleTime={toggleTime}
          value={value}
          {...exposedProps}
          {...rest}
        />
      )}
    </Popover>
  );
};

export default Popup;
