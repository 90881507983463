import React, { Fragment } from 'react';

import FieldRow from './FieldRow';
import GroupFields from './GroupFields';
import renderMultipleField from './renderMultipleField';

const renderGroupFields = (section, profile) => {
  if (section.fields) {
    return (
      <GroupFields>
        {section.fields.map((field, findex) =>
          field.render !== false ? (
            field.type === 'multiple' ? (
              <Fragment key={findex}>{renderMultipleField(field, profile)}</Fragment>
            ) : (
              <FieldRow
                key={findex}
                field={field}
                findex={findex}
                profile={profile}
                className={field.className}
              />
            )
          ) : null
        )}
      </GroupFields>
    );
  }

  return (section.groups || []).map((group, gindex) =>
    Array.isArray(group) ? (
      <GroupFields key={gindex}>
        {group.map(
          (field, findex) =>
            field.render !== false && (
              <FieldRow
                key={findex}
                field={field}
                findex={findex}
                group={group}
                profile={profile}
                className={field.className}
              />
            )
        )}
      </GroupFields>
    ) : group.type === 'multiple' ? (
      <Fragment key={gindex}>{renderMultipleField(group, profile)}</Fragment>
    ) : null
  );
};

export default renderGroupFields;
