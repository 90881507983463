import Textarea from 'common/components/form/inputs/Textarea';

const Title = ({ value, error, onChange, disabled, className = '' }) => {
  return (
    <Textarea
      label={'Title'}
      value={value}
      name="name"
      white
      rows={1}
      autoResize
      error={error}
      placeholder="Add title"
      dataCy="event_title"
      onChange={onChange}
      className={`event-title fw-medium ${className}`}
      disabled={disabled}
      onKeyDown={e => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    />
  );
};

export default Title;
