import React from 'react';
import CommonInput from 'common/components/form/inputs/Input';
import PasswordValidation from 'common/components/general/PasswordValidation';

const FloatingLabelInput = ({ label, value, wrapperClassName, type, hideValidation, ...props }) => {
  return (
    <div
      className={`floating-label-input${value && value.length ? ' with-value' : ''} ${
        wrapperClassName || ''
      }`}
    >
      <div className={'form-label'}>{label}</div>
      <CommonInput label={null} value={value} type={type} {...props} />

      {type === 'password' && !hideValidation ? (
        <PasswordValidation className="floating-label-input__validation" password={value} />
      ) : null}
    </div>
  );
};
export default FloatingLabelInput;
