import PageTitle from 'common/components/general/PageTitle';
import Gallery from 'common/components/gallery';

const App = ({ children, pageTitle, appClass }) => {
  return (
    <div className="content app-content">
      <main id="main" className="app-content--main">
        <div
          className={`app-content--main-inner ps-1 ps-hd-3 pe-4 pe-hd-5 pt-3 pt-hd-4 pb-2 ${
            appClass || ''
          }`}
        >
          {pageTitle ? <PageTitle className="app-title--header" title={pageTitle} /> : null}
          {children}
        </div>
      </main>
      <div id="drawer-container" />
      <div id="bottom-panel-container" />
      <Gallery />
    </div>
  );
};

export default App;
