import {
  createEventAudit,
  createEventAuditFinding,
  deleteEventAudit,
  getEventAudits,
  getEventAuditsEvaluationCriteria,
  getEventSubmissionFindings,
  updateEventAudit,
  unlinkEventFindings
} from './actions';
import { deleteFinding, updateFinding } from 'common/components/findings/store/actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  loading: false,
  data: {
    audits: [],
    finding_submissions: []
  },
  evaluation_criteria: []
};

const slice = createSlice({
  name: 'eventAudits',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(updateFinding.fulfilled, (state, { payload }) => {
      //   const { data, params } = payload;

      //   const index = state.data.findings.findIndex(f => f.id === data.id);

      //   if (index !== -1) state.data.findings[index] = { ...data, auditId: params.auditId };

      //   return state;
      // })

      // .addCase(createEventAuditFinding.fulfilled, (state, { payload }) => {
      //   const { finding, auditId } = payload;

      //   state.loading = false;
      //   state.data.findings.push({ ...finding, auditId });
      //   return state;
      // })

      // .addCase(deleteFinding.fulfilled, (state, { payload }) => {
      //   state.data.findings = state.data.findings.filter(e => e.id !== payload);
      //   return state;
      // })

      .addCase(getEventSubmissionFindings.fulfilled, (state, { payload }) => {
        state.data.finding_submissions = payload;
        state.loading = false;
        return state;
      })

      .addCase(getEventAudits.pending, state => {
        state.data.audits = [];
        // state.data.findings = [];
        state.loading = true;
        return state;
      })

      .addCase(getEventAudits.fulfilled, (state, { payload }) => {
        state.loading = false;

        payload.forEach(audit => {
          state.data.audits.push(audit);
        });

        return state;
      })

      .addCase(getEventAudits.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(createEventAudit.fulfilled, (state, { payload }) => {
        state.data.audits.push(payload);
        state.loading = false;
        return state;
      })

      .addCase(updateEventAudit.fulfilled, (state, { payload }) => {
        const index = state.data.audits.findIndex(a => a.id === payload.id);
        if (index !== -1) state.data.audits[index] = payload;

        state.loading = false;
        return state;
      })

      .addCase(deleteEventAudit.fulfilled, (state, { payload }) => {
        state.data.audits = state.data.audits.filter(e => e.id !== payload.id);
        // state.data.findings = state.data.findings.filter(f => f.auditId !== payload.id);
        return state;
      })

      .addCase(getEventAuditsEvaluationCriteria.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getEventAuditsEvaluationCriteria.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.evaluation_criteria = payload;
        return state;
      })

      .addCase(getEventAuditsEvaluationCriteria.rejected, state => {
        state.loading = false;
        return state;
      })

      // .addCase(unlinkEventFindings.fulfilled, (state, { payload }) => {
      //   state.data.findings = state.data.findings.filter(e => e.id !== payload.id);

      //   return state;
      // })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default slice.reducer;
