import _get from 'lodash/get';

import { ContractType } from 'common/entities/crew/ContractTypes';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const Rank = ({ latestContract }) => {
  const offSignerContracts = _get(
    latestContract,
    'crew_member.off_signer_rotations[0].off_signer_contracts_ordered',
    []
  );
  const hasPromotion = !!offSignerContracts?.length;

  return (
    <TextWithTooltip className="h-auto fw-bold fs-12">
      {hasPromotion
        ? offSignerContracts.map(
            (signer, index) =>
              `${signer?.rank?.name}${index === offSignerContracts?.length - 1 ? '' : ', '}`
          )
        : latestContract?.rank?.name || '-'}
    </TextWithTooltip>
  );
};

export default Rank;

Rank.propTypes = {
  latestContract: ContractType
};
