import TYPES from './types';

export const updateTableRow =
  ({ data, table }) =>
  dispatch =>
    dispatch({ type: TYPES.UPDATE_TABLE_ROW, payload: { data, table } });

export const removeTableRow =
  ({ data, table }) =>
  dispatch =>
    dispatch({ type: TYPES.REMOVE_TABLE_ROW, payload: { data, table } });

export const resetTableRowUpdate =
  ({ data, table }) =>
  dispatch =>
    dispatch({ type: TYPES.RESET_TABLE_ROW_UPDATE, payload: { data, table } });

export const toggleTableFilters = (open, table) => dispatch =>
  dispatch({ type: TYPES.TOGGLE_TABLE_FILTERS, payload: { open, table } });

export const applyTableFilters = (applied, table, shouldRefetchTable) => dispatch =>
  dispatch({ type: TYPES.APLLY_TABLE_FILTERS, payload: { applied, table, shouldRefetchTable } });

export const selectTableRow = (row, table) => dispatch =>
  dispatch({ type: TYPES.SELECT_TABLE_ROW, payload: { row, table } }); // !! Select SINGLE table row !!

export const selectTableRows = (rows, table) => dispatch =>
  dispatch({ type: TYPES.SELECT_TABLE_ROWS, payload: { rows, table } }); // !! Select MULTIPLE table rows !!

export const clearSelectedTableRows = table => dispatch =>
  dispatch({ type: TYPES.CLEAR_SELECTED_TABLE_ROWS, payload: { table } });

export const getTableSelectedRows = table => (dispatch, getState) => {
  dispatch({ type: TYPES.GET_SELECTED_TABLE_ROWS, payload: { table } });

  return getState().tables.lists[table].selectedRows;
};

export const getTableSelectedRowsIds = table => (dispatch, getState) => {
  dispatch({ type: TYPES.GET_SELECTED_TABLE_ROWS, payload: { table } });

  return getState().tables.lists[table].selectedRows.map(selected => selected.id);
};

export const setTablePageSearch =
  ({ text, table }) =>
  dispatch =>
    dispatch({ type: TYPES.SET_TABLE_PAGE_SEARCH, payload: { text, table } });

export const setTableWarnings =
  ({ warnings = {}, table }) =>
  dispatch =>
    dispatch({ type: TYPES.SET_TABLE_WARNINGS, payload: { warnings, table } });

export const setTableTotals =
  ({ totals, table }) =>
  dispatch =>
    dispatch({ type: TYPES.SET_TABLE_TOTALS, payload: { totals, table } });

export const setTableHoveredColumn =
  ({ key, table }) =>
  (dispatch, getState) => {
    const hoveredColumnKey = getState().tables.lists?.[table]?.hoveredColumn;

    if (hoveredColumnKey !== key)
      dispatch({ type: TYPES.SET_TABLE_HOVERED_COLUMN, payload: { key, table } });
  };

export const setTableRequestParams =
  ({ params, table }) =>
  dispatch =>
    dispatch({ type: TYPES.SET_TABLE_REQUEST_PARAMS, payload: { params, table } });
