import React, { useEffect, useState } from 'react';
import CollapseBox from 'common/components/collapse/CollapseBox';
import { useActions, useCancelablePromise } from 'utils/hooks';
import * as jobActivityActions from 'common/components/jobs/_base/modules/activity/store/actions';
import List from './List';
import ProfileLoader from './ProfileLoader';
import { useSelector } from 'react-redux';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';

const Activity = ({ isDefaultOpened = true }) => {
  const cancelablePromise = useCancelablePromise();
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [paging] = useState({ per_page: 10 });

  const currentActivityJobId = useSelector(state => selectJobField(state, 'activity').jobId);
  const data = useSelector(state => selectJobField(state, 'activity').data);
  const hasMore = useSelector(state => selectJobField(state, 'activity').hasMore);
  const currentActivityPage = useSelector(state => selectJobField(state, 'activity').currentPage);

  const [hasError, setHasError] = useState(false);

  const [getJobActivity, clearJobActivity] = useActions([
    jobActivityActions.getJobActivity,
    jobActivityActions.clearJobActivity
  ]);

  const getActivity = async current_page => {
    if (current_page === 1) {
      setLoading(true);
    } else {
      setFetchingMore(true);
    }

    try {
      const response = await cancelablePromise(
        getJobActivity({
          id: jobId,
          paging: { current_page, per_page: paging.per_page }
        })
      );

      setLoading(false);
      setFetchingMore(false);

      if (current_page === response.meta.last_page) {
      }
    } catch (error) {
      setLoading(false);
      setFetchingMore(false);
      setHasError(true);
    }
  };

  const onOpen = () => {
    getActivity(1);
  };

  useEffect(() => {
    if (jobId && jobId !== currentActivityJobId) {
      clearJobActivity();
      onOpen();
    }
  }, [jobId]);

  return (
    <div className={`job-activity-container mt-3`}>
      <CollapseBox
        header="ACTIVITY"
        headerClassName={`fs-10 fw-bold text-primary mb-0 pb-0`}
        bodyInnerClassName="pt-0"
        className={`job-reminders-preview`}
        isDefaultOpened={isDefaultOpened}
        expandButton={false}
        hideExpandButton={true}
        innerExpandButton={true}
      >
        <div
          className={`job-activity border-radius-7 ${
            data.length === 0 ? 'job-activity-empty' : ''
          } w-100p`}
        >
          {loading ? (
            <ProfileLoader loading={loading} />
          ) : data.length === 0 ? (
            <ProfileLoader isEmpty />
          ) : (
            <div className="h-100p position-relative">
              <div className="line" />
              <List
                fetchingMore={fetchingMore}
                hasError={hasError}
                hasMore={hasMore}
                currentActivityPage={currentActivityPage}
                getActivity={getActivity}
                paging={paging}
                data={data}
              />
            </div>
          )}
        </div>
      </CollapseBox>
    </div>
  );
};

export default Activity;
