import { useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';

import TotalsBar from 'common/components/graph/TotalsBar';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const ContentJobs = () => {
  const totals = useSelector(state => selectDashboardTotals(state, 'jobs'));

  return totals ? (
    <div className="dashboard__totals-jobs">
      <TotalsBar
        legends={[
          {
            label: 'Open',
            total: totals?.open || 0,
            color: variables.primary
          },
          {
            label: 'Becoming Overdue',
            total: totals?.becoming_overdue || 0,
            color: variables.goldBell
          },
          {
            label: 'Overdue',
            total: totals?.overdue || 0,
            color: variables.red
          }
        ]}
      />
    </div>
  ) : null;
};

export default ContentJobs;
