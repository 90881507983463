import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';

import Select from 'common/components/form/inputs/Select';
import Input from 'common/components/form/inputs/Input';

const DropdownWithDetails = ({
  options = [],
  className = '',
  value = { option: null, additional_info: null },
  onChange = () => {},
  disabled,
  error
}) => {
  const selectedOption = useMemo(() => {
    return (
      options.find(option => option?.option === value?.option) || {
        option: null,
        additional_info: false
      }
    );
  }, [options, value]);

  const onSelect = option => {
    if (disabled) {
      return;
    }

    onChange({
      option: option.option,
      additional_info: null
    });
  };

  const onTextChange = e => {
    if (disabled) {
      return;
    }

    onChange({
      option: selectedOption.option,
      additional_info: e?.target?.value || null
    });
  };

  if (!options.length) {
    return null;
  }

  return (
    <Row noGutters className={`dropdown-with-details ${className}`}>
      <Col xs={12}>
        <Select
          options={options}
          value={selectedOption}
          onChange={onSelect}
          getOptionValue={option => option}
          getOptionLabel={option => option.option}
          placeholder="Select"
          disabled={disabled}
          error={error}
          className="mb-0"
        />
      </Col>

      {selectedOption?.additional_info ? (
        <Col xs={12} className="mt-1">
          <Input
            rows={3}
            type="textarea"
            placeholder="Add some text"
            value={value.additional_info}
            className="mb-0"
            onChange={onTextChange}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default DropdownWithDetails;
