const selectLubOilAnalysis = state => state.eventModules.lubOilAnalysis;

export const selectEventLubOilAnalysisIsLoading = state => selectLubOilAnalysis(state)?.isLoading;

export const selectEventLubOilAnalysisData = state => selectLubOilAnalysis(state)?.data;

export const selectEventLubOilAnalysisIsWatingFromVessel = state =>
  !selectEventLubOilAnalysisData(state)?.samples_delivery_date;

export const selectNextEventLubOilAnalysis = state => selectLubOilAnalysis(state)?.nextEventId;
