import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldsHeader } from 'captain-reports/templates/layout/_helpers';

import VisibleMultiField from 'captain-reports/templates/layout/fields/VisibleMultiField';
import TemplateGroup from 'captain-reports/templates/layout/fields/Group';

import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';

const MainEngineFoLoInletSystemOilThrust = ({ section, label }) => {
  const loInletSection = section.extraFields().lo_inlet;
  const systemOilSection = section.extraFields().system_oil;
  const thrustSection = section.extraFields().thrust;

  const visibleFoInletSection = useSelector(state =>
    hasAtLeastOneVisibleField(state, section.fields)
  );
  const visibleLoInletSection = useSelector(state =>
    hasAtLeastOneVisibleField(state, loInletSection.fields)
  );
  const visibleSystemOilSection = useSelector(state =>
    hasAtLeastOneVisibleField(state, systemOilSection.fields)
  );
  const visibleThrustSection = useSelector(state =>
    hasAtLeastOneVisibleField(state, thrustSection.fields)
  );

  return (
    <Row className="align-items-end template-group">
      {visibleFoInletSection ? (
        <Col xs={3} className="template-field mb-0">
          <FieldsHeader header={section.header} />
          <TemplateGroup className="align-items-center">
            {section.fields.map(field => (
              <VisibleMultiField key={field.key} field={field} label={label} />
            ))}
          </TemplateGroup>
        </Col>
      ) : null}
      {visibleLoInletSection ? (
        <Col xs={3} className="template-field mb-0">
          <FieldsHeader header={loInletSection.header} />
          <TemplateGroup className="align-items-center">
            {loInletSection.fields.map(field => (
              <VisibleMultiField key={field.key} field={field} label={label} />
            ))}
          </TemplateGroup>
        </Col>
      ) : null}
      {visibleSystemOilSection ? (
        <Col xs={3} className="template-field mb-0">
          <FieldsHeader header={systemOilSection.header} />
          <TemplateGroup className="align-items-center">
            {systemOilSection.fields.map(field => (
              <VisibleMultiField key={field.key} field={field} label={label} />
            ))}
          </TemplateGroup>
        </Col>
      ) : null}
      {visibleThrustSection ? (
        <Col xs={3} className="template-field mb-0">
          <FieldsHeader header={thrustSection.header} />
          <TemplateGroup className="align-items-center">
            {thrustSection.fields.map(field => (
              <VisibleMultiField key={field.key} field={field} label={label} />
            ))}
          </TemplateGroup>
        </Col>
      ) : null}
    </Row>
  );
};

export default MainEngineFoLoInletSystemOilThrust;
