import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import Edit from './edit';

const SortableItem = SortableElement(
  ({
    checklistState,
    state,
    itemIndex,
    updateChecklistState,
    isEditMode,
    setChecklistState,
    onSubmit,
    onCancel,
    memoizedDebounce,
    submitting,
    isLoading,
    placeholder,
    hasActionButtons
  }) => {
    return (
      <Edit
        isEditMode={isEditMode}
        placeholder={placeholder}
        index={itemIndex}
        state={state}
        onChange={(val, mentions) => updateChecklistState(val, mentions, itemIndex)}
        onAdd={() => setChecklistState([...checklistState, { title: '', mentions: [] }])}
        onRemove={() => {
          const updated = checklistState.filter((s, i) => i !== itemIndex);

          if (!updated.length) updated.push({ title: '', mentions: [] });

          setChecklistState(updated);
          if (!isEditMode && memoizedDebounce) {
            memoizedDebounce(updated);
          }
        }}
        onSubmit={onSubmit}
        onCancel={onCancel}
        lastIndex={checklistState.length}
        disabled={submitting || isLoading}
        hasActionButtons={hasActionButtons}
      />
    );
  }
);

export default SortableItem;
