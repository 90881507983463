import { useState, useEffect } from 'react';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import { selectFieldFile, isReportLocked } from 'captain-reports/store/selectors';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import UploadFiles from 'common/components/form/inputs/upload-files';
import * as reportActions from 'captain-reports/store/actions';

const AttachmentField = ({ field, tooltip }) => {
  const [setValue] = useActions([reportActions.setValue]);
  const isLocked = useSelector(isReportLocked);

  const file = useSelector(state => selectFieldFile(state, field.key));

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (file?.id) {
      setAttachments([file]);
    }
  }, [file?.id]);

  const submitHandler = files => {
    setAttachments(files || []);
    setValue({
      key: field.key,
      value: files[0]?.id || null
    });
  };

  return (
    <div className={`${isLocked && !attachments?.length ? 'pointer-events-none' : ''}`}>
      <FieldLabel
        info={tooltip}
        className="form-label fw-bold text-uppercase cmb-6"
        label={field.label}
        subLabel={field.subLabel}
      />

      {!attachments?.length && isLocked ? (
        <div className="py-2">-</div>
      ) : (
        <UploadFiles
          group="captain-reports"
          viewOnly={isLocked}
          singleUpload={true}
          files={attachments}
          onChange={files => submitHandler(files)}
          size="lg"
        />
      )}
    </div>
  );
};

export default AttachmentField;
