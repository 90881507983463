import _get from 'lodash/get';
import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';

import Editor from 'common/components/form/inputs/Editor';
import _debounce from 'lodash/debounce';

const Notes = ({
  fields,
  configKey,
  label,
  icon,
  selectField,
  isHighlighted,
  viewOnly,
  notes,
  className,
  subtitle
}) => {
  const value = _get(fields, `${configKey}.value`, '');

  return (
    <ShadowBox
      className={`p-2 flex-1 fs-12 d-flex flex-column h-100p crew-profile__evaluation--notes ${
        isHighlighted ? 'border border-warning' : ''
      } ${className || null}`}
    >
      <div className={`d-flex align-items-center mb-1 pb-1 border-bottom fw-medium`}>
        <div
          className={`d-flex flex-1 align-items-center ${
            isHighlighted ? 'text-warning' : 'text-purple'
          } `}
        >
          <SvgRender src={icon} style={{ width: 16, height: 16 }} className="me-1" />
          {label}
        </div>

        <div className="fs-12 text-violet">{subtitle}</div>
      </div>

      <div className="flex-1 overflow-y">
        {viewOnly ? (
          <div className="flex-1 overflow-y" dangerouslySetInnerHTML={{ __html: notes }} />
        ) : (
          <Editor
            placeholder="Add some notes"
            name="notes"
            onChange={_debounce(html => selectField(configKey)(html), 500)}
            minHeight={32}
            id={`evaluation-notes-${configKey}`}
            value={value}
          />
        )}
      </div>
    </ShadowBox>
  );
};

export default Notes;
