import React from 'react';

import ButtonGroupTabs from 'common/components/buttons/ButtonGroupTabs';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import * as shiftActions from 'crew/store/shifts/actions';
import { selectSelectedSorting } from 'crew/store/shifts/selectors';

import { sortingOptions } from './helpers';

const SortingButtonGroup = ({ className }) => {
  const selectedSorting = useSelector(selectSelectedSorting);
  const [setSelectedSorting] = useActions([shiftActions.setSelectedSorting]);

  const onClick = value => {
    setSelectedSorting(value);
  };

  return (
    <div className={`shifts-filters__sorting-button-group ${className || ''}`}>
      <ButtonGroupTabs active={selectedSorting} options={sortingOptions} onClick={onClick} />
    </div>
  );
};

export default SortingButtonGroup;
