import React from 'react';
import PropTypes from 'prop-types';
import { Party } from 'common/entities/parties/PartyTypes';
import Departments from 'common/components/general/Departments';
import CircledButton from 'common/components/buttons/CircledButton';

const List = ({ people, setPeople }) => {
  if (!people.length) return null;

  return people.map(party => (
    <div className="d-flex align-items-center">
      <div className="bg-light-gray d-flex align-items-center justify-content-between cpx-12 cpy-6 cmb-2 border-radius-5 w-100p">
        <span className="fw-medium text-primary fs-12">{party.full_name}</span>

        {party.departments?.length ? <Departments values={party.departments} /> : null}
      </div>

      <CircledButton
        className="cms-12"
        type="remove"
        style={{ width: 15, height: 15 }}
        svgStyle={{ width: 6, height: 1 }}
        onClick={() => setPeople(prev => prev.filter(watcher => watcher.id !== party.id))}
      />
    </div>
  ));
};

List.propTypes = {
  people: PropTypes.arrayOf(Party),
  setPeople: PropTypes.func.isRequired
};

export default List;
