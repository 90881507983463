import { AccountType } from '@/common/types/account';
import TYPES from './types';
import AUTH_TYPES from 'store/auth/types';

const INITIAL_STATE = {} as AccountType;

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_ACCOUNT.SUCCESS:
      return {
        ...state,
        ...payload
      };

    case TYPES.GET_ACCOUNT_PERMISSIONS.SUCCESS: {
      const { permissions } = payload;

      return {
        ...state,
        permissions
      };
    }

    case AUTH_TYPES.LOGOUT.SUCCESS:
    case AUTH_TYPES.LOGOUT.ERROR:
      return {};

    default:
      return state;
  }
};
export default reducer;
