import { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';

import DateInput from 'common/components/form/inputs/date';
import { getDueDateActivity } from 'common/components/jobs/_base/modules/activity/store/actions';
import { selectJobField, selectJobFieldError } from 'common/components/jobs/_base/store/selectors';
import SvgRender from 'common/components/general/SvgRender';
import DueDateInputModal from '@/common/components/pms/jobs/inputs/maintenance/main/system-running-hours/DueDateInputModal';
import edit from 'common/assets/svg/actions/edit.svg';
import { setJobField, setJobFieldError } from 'common/components/jobs/_base/store/actions';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import DueDateHistoryLabel from '@/common/components/jobs/_base/components/due-date-history-label';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';

const FieldComponent = ({ disabled, ...rest }) => {
  const dispatch = useAppDispatch();
  const dueDateError = useAppSelector(state => selectJobFieldError(state, 'due_date'));
  const dueDateValue = useAppSelector(state => selectJobField(state, 'due_date'));

  return (
    <DateInput
      onChange={d => {
        dispatch(setJobField('due_date', d, true));

        if (d && dueDateError) {
          dispatch(setJobFieldError({ due_date: null }));
        }
      }}
      value={dueDateValue}
      key={dueDateValue}
      error={dueDateError}
      showTime={false}
      hasTime={false}
      disabled={disabled}
      {...rest}
    />
  );
};

const DueDate = ({ isProfile }) => {
  const [remarksModalOpen, setRemarksModalOpen] = useState(false);
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const jobId = useAppSelector(state => selectJobField(state, 'id'));
  const [history, setHistory] = useState([]);
  const dispatch = useAppDispatch();

  const fetchDueDateHistory = useCallback(async () => {
    if (!jobId) return null;

    try {
      const res = await dispatch(getDueDateActivity({ id: jobId }));
      setHistory(res);
    } catch (err) {
      setHistory([]);
      console.error(err);
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    if (!remarksModalOpen && !isOnBoard) {
      fetchDueDateHistory();
    }
  }, [remarksModalOpen, fetchDueDateHistory, isOnBoard]);

  const isInputDisabled = !!jobId || isOnBoard;

  return (
    <div className={isProfile ? 'form-group-spacing' : ''}>
      <div className={`job-input job-input--date job-job-input--date`}>
        <DueDateHistoryLabel history={history} />

        <div className="d-flex align-items-center">
          <FieldComponent disabled={isInputDisabled} />

          {jobId ? (
            <UserCanEdit field="due_date">
              <Button
                className="text-primary ms-1 cursor-pointer lh-12 p-0"
                onClick={() => setRemarksModalOpen(true)}
                color="link"
                data-testid="due-date-edit"
              >
                <SvgRender style={{ height: 14, width: 14 }} src={edit} />
              </Button>
            </UserCanEdit>
          ) : null}
        </div>

        <DueDateInputModal
          remarksModalOpen={remarksModalOpen}
          setRemarksModalOpen={setRemarksModalOpen}
        />
      </div>
    </div>
  );
};

export default DueDate;
