import React from 'react';
import PropTypes from 'prop-types';

import useRouter from 'use-react-router';
import moment from 'moment';
import DateInput, { mode } from 'common/components/form/inputs/date';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { selectErrors } from 'events/store/events/selectors';
import { MODES, TIME_FORMAT, onDateChange, onTimeValidation } from './helper';
import { EventDates } from 'common/entities/events/EventTypes';
import { setErrors } from 'events/store/events/actions';

const SingleDatePicker = ({
  event,
  changeEventValue,
  memoizedDebounce,
  isCreate,
  hasLabel,
  disabled
}) => {
  const dispatch = useDispatch();
  const { match } = useRouter();

  const errors = useSelector(selectErrors);
  const eventId = match.params.id;
  const eventObject = isCreate ? event?.dates[0] : event;

  const autoSave = p => {
    const params = {
      id: eventId,
      date_type: _get(p, 'date_type', undefined),
      started_at: _get(p, 'started_at', undefined),
      is_datetime: _get(p, 'is_datetime', undefined),
      ended_at: p.date_type === 'range' ? _get(p, 'ended_at', undefined) : undefined,
      ...p
    };

    memoizedDebounce(params);
  };

  const changeEventDateType = type => {
    const started_at = null;

    const ended_at = type === MODES.range ? null : undefined;

    const is_datetime = _get(eventObject, 'is_datetime', false);
    const date_type = type;

    const paramsSingle = { date_type, started_at, ended_at, is_datetime };

    const params = {
      id: match.params.id,
      started_at,
      ended_at,
      is_datetime,
      date_type
    };

    const error = onTimeValidation({
      started_at,
      ended_at,
      mode: date_type
    });

    dispatch(setErrors({ date: error }));
    changeEventValue(isCreate ? { dates: [{ ...paramsSingle }] } : paramsSingle);

    if (!isCreate && !error) {
      autoSave(params);
    }
  };

  const changeEventDates = dates => {
    const is_datetime = _get(eventObject, 'is_datetime', false);
    const started_at = _get(eventObject, 'started_at', undefined);
    const ended_at =
      eventObject?.date_type === 'range' ? _get(eventObject, 'ended_at', undefined) : undefined;
    const date_type = _get(eventObject, 'date_type') || 'single';

    const params = {
      id: eventId,
      date_type,
      started_at,
      ended_at,
      is_datetime,
      ...dates
    };

    const error = onTimeValidation({
      started_at,
      ended_at,
      mode: date_type,
      ...dates
    });

    dispatch(setErrors({ date: error }));

    changeEventValue(isCreate ? { dates: [{ ...dates, date_type, is_datetime }] } : dates);

    if (!isCreate && !error) {
      autoSave(params);
    }
  };

  const toggleDateTime = hasTime => {
    const date_type = _get(eventObject, 'date_type', undefined);
    const isRange = eventObject?.date_type === 'range';
    const dateFormat = hasTime ? TIME_FORMAT.time : TIME_FORMAT.dateOnly;
    const started_at = eventObject?.started_at
      ? moment(eventObject.started_at).format(dateFormat)
      : undefined;
    const ended_at =
      eventObject?.ended_at && isRange
        ? moment(eventObject.ended_at).format(dateFormat)
        : undefined;

    const is_datetime = hasTime;

    const paramsSingle = {
      is_datetime,
      date_type,
      started_at,
      ended_at
    };

    const params = {
      id: eventId,
      date_type,
      started_at,
      ended_at,
      is_datetime
    };

    const error = onTimeValidation({
      started_at,
      ended_at,
      mode: date_type
    });
    dispatch(setErrors({ date: error }));

    changeEventValue(isCreate ? { dates: [{ ...paramsSingle }] } : paramsSingle);

    if (!isCreate && !error) {
      autoSave(params);
    }
  };

  return (
    <div className="event__sidebar--datepicker ps-1 mb-2">
      <DateInput
        dataCy="event_date"
        label={hasLabel ? 'Date' : ''}
        onChange={selected => {
          const dates = onDateChange({
            started_at: selected?.from || selected,
            ended_at: selected?.to,
            date_type: eventObject?.date_type,
            is_datetime: eventObject?.is_datetime
          });
          changeEventDates(dates);
        }}
        availableModes={[mode.single.date, mode.range.date]}
        activeMode={eventObject?.date_type === 'range' ? mode.range.date : mode.single.date}
        error={errors.date}
        showTime={eventObject?.is_datetime}
        hasTime={true}
        useStringValue={true}
        toggleTime={toggleDateTime}
        value={eventObject?.started_at ? moment(eventObject?.started_at) : null}
        from={eventObject?.started_at ? moment(eventObject?.started_at) : null}
        to={eventObject?.ended_at ? moment(eventObject.ended_at) : null}
        onModeChange={(_, { isRange }) => changeEventDateType(isRange ? 'range' : 'single')}
        disabled={disabled}
      />
    </div>
  );
};

export default SingleDatePicker;

SingleDatePicker.propTypes = {
  memoizedDebounce: PropTypes.func,
  changeEventValue: PropTypes.func,
  dateIndex: PropTypes.number,
  isCreate: PropTypes.bool,
  event: EventDates.isRequired
};
