import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';

const HeaderIcon = ({ label, icon, svgStyle }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  return (
    <div className="d-flex align-items-center template-layout-header-icon">
      <div className="template-layout-header-icon__circle d-inline-flex justify-content-center align-items-center">
        <SvgRender src={icon} style={svgStyle || { width: 16, height: 16 }} />
      </div>
      <strong className={`ps-1 fs-12 lh-1 ${isOnBoard ? 'text-primary' : 'text-dark'}`}>
        {label}
      </strong>
    </div>
  );
};

export default HeaderIcon;
