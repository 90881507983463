import PropTypes from 'prop-types';

import viewIcon from 'common/assets/svg/actions/view.svg';
import editIcon from 'common/assets/svg/actions/edit.svg';
import rankIcon from 'common/assets/svg/crew/rank.svg';
import SvgRender from 'common/components/general/SvgRender';

const actionIcon = {
  viewed: viewIcon,
  edited: editIcon
};

const Warning = ({ action, fullName, rankName, minutesAgo }) => {
  if (!action) return null;

  const icon = actionIcon?.[action];

  return (
    <div className="bg-ghost-white-4 justify-self-start text-royal-blue d-flex align-items-center px-1 cpy-6 fs-12 border-radius-5">
      {icon ? <SvgRender src={icon} style={{ width: 14, height: 14 }} className="me-1" /> : null}

      {fullName ? <div className="fw-bold">{fullName}</div> : null}

      {rankName ? (
        <div className="fw-bold d-flex align-items-center">
          <div className="cms-6 cme-6">|</div>
          <SvgRender src={rankIcon} style={{ width: 12, height: 12 }} className="cme-6" />
          {rankName}
        </div>
      ) : null}

      <div className="cms-6">
        {action} this form <span className="fw-bold">{minutesAgo} </span>
      </div>
    </div>
  );
};

export default Warning;

Warning.propTypes = {
  action: PropTypes.oneOf(['viewed', 'edited']).isRequired,
  fullName: PropTypes.string.isRequired,
  minutesAgo: PropTypes.number.isRequired,
  rankName: PropTypes.string
};
