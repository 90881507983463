import { useSelector } from 'react-redux';
import { selectIsActiveChapterChapterDeleted } from 'manuals/store/selectors';

import SvgRender from 'common/components/general/SvgRender';
import deleteIcon from 'common/assets/svg/actions/delete.svg';

const DeletedBanner = () => {
  const isGettingDeleted = useSelector(selectIsActiveChapterChapterDeleted);

  if (!isGettingDeleted) return null;

  return (
    <div className="chapter-content__preview-deleted d-flex justify-content-center">
      <div className="d-inline-flex align-items-center text-violet">
        <SvgRender className={`me-1`} src={deleteIcon} style={{ width: 16, height: 16 }} />
        <span className="lh-1">This chapter is deleted</span>
      </div>
    </div>
  );
};

export default DeletedBanner;
