import React from 'react';

const RiskRating = ({ previewMode }) => {
  return (
    <div className={`risk-rating ${previewMode ? 'preview' : ''}`}>
      <div className="d-flex">
        <div className="title">
          <strong className="fs-12 text-primary">
            RISK <br /> RATING
          </strong>
        </div>
        <div>
          <strong
            className="d-flex justify-content-center fs-12 text-violet 
            pt-1 cpb-12 border-start border-white"
          >
            LIKELIHOOD (L)
          </strong>
          <div className="d-flex">
            {Array.from({ length: 5 }, (_, i) => (
              <strong className="d-flex align-items-center justify-content-center fs-12 box text-primary">
                {i + 1}
              </strong>
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex position-relative">
        <div className="vertical-text d-flex align-items-center justify-content-end border-bottom border-white">
          <strong className="fs-12 text-violet mb-1">SEVERITY (S)</strong>
        </div>
        <div className="d-flex flex-column vertical-num-container-first">
          {Array.from({ length: 5 }, (_, i) => (
            <strong className="d-flex align-items-center justify-content-center fs-12 box text-primary">
              {i + 1}
            </strong>
          ))}
        </div>
        {Array.from({ length: 5 }, (_, i) => {
          return (
            <div className="d-flex flex-column vertical-num-container">
              {Array.from({ length: 5 }, (_, index) => {
                const value = (i + 1) * (index + 1);
                return (
                  <span
                    className={`d-flex align-items-center justify-content-center fs-12
                    box text-primary ${
                      value < 5 ? 'bg-turquoise' : value > 12 ? 'bg-red' : 'bg-yellow'
                    } 
                    ${value === 25 ? 'last-box' : ''}`}
                  >
                    {value}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RiskRating;
