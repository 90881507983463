import { getCurrentVersionLabel } from 'common/utils/labels';

const CurrentVersion = () => {
  const appVersion = import.meta.env.VITE_APP_VERSION;
  const buildNumber = import.meta.env.VITE_BUILD_NUMBER;

  return appVersion ? (
    <div className="ps-2 text-violet fs-12 fw-light mt-2">
      v. {getCurrentVersionLabel(appVersion, 'onboard-')}
      {buildNumber ? `-${buildNumber}` : ''}
    </div>
  ) : null;
};

export default CurrentVersion;
