import Table from 'views/dashboard/components/Table';
import DueDate from 'common/components/jobs/_base/components/DueDate';
import HeaderTitle from 'views/dashboard/components/HeaderTitle';

import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import moment from 'moment';

import Status from 'common/components/general/Status';
import Priority from 'common/components/general/Priority';
import _get from 'lodash/get';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import paths from 'routing/routes/_paths';
import TableViewIcon from 'views/dashboard/components/TableViewIcon';

const FlagDispenation = () => {
  const columns = [
    {
      header: '',
      key: 'importance_id',
      headerClassName: 'text-violet',
      width: 3
    },
    {
      header: 'DUE DATE',
      key: 'due_date',
      type: 'date',
      headerClassName: 'text-violet',
      width: 2
    },
    {
      header: 'DISPENSATION TYPE',
      key: 'flag_extension_and_dispensation_types',
      headerClassName: 'text-violet',
      width: 2
    },
    {
      header: 'REQUESTED ON',
      key: 'requested_on',
      type: 'date',
      headerClassName: 'text-violet',
      width: 2
    },
    {
      header: 'STATUS',
      key: 'status_id',
      headerClassName: 'text-violet',
      width: 3
    }
  ];

  return (
    <Table
      label="flag_extension_dispensation"
      className="mt-8"
      header={
        <HeaderTitle title="Flag Dispensations" path={paths.flag_extensions_dispensations_jobs} />
      }
      subheader={<span className="text-violet">Sorted by Due Date</span>}
      columns={columns}
      rows={{
        requested_on: data => {
          const requestedOn = _get(data, 'flag_extension.requested_on');
          const attachments = _get(data, 'flag_extension.attachments', []);
          const attachmentsCount = _get(data, 'flag_extension.attachments_count', []);

          return (
            <div className="d-flex justify-content-between">
              <div>{requestedOn ? moment(requestedOn).format('DD/MM/YYYY') : '-'}</div>
              <div className="d-flex align-items-center justify-content-end">
                <DownloadAttachment
                  data={{ attachments_count: attachmentsCount, attachments: attachments }}
                  downloadFiles={() =>
                    downloadAllFilesOfEntity({ id: data?.id, type: 'job_flag_extension' })
                  }
                  hasAttachments={true}
                  canAddAttachments={false}
                  popupPlacement={'left'}
                />
              </div>
            </div>
          );
        },
        flag_extension_and_dispensation_types: data => {
          const type = _get(data, 'flag_extension.type.name');

          return type ? (
            <TextWithTooltip className="d-flex align-items-center">{type}</TextWithTooltip>
          ) : (
            '-'
          );
        },
        due_date: data => (
          <DueDate
            dueDateChanged={data.last_due_date_change}
            className="fs-12"
            dueDate={data.due_date}
            createdAt={data.created_at}
            status={data.status_id}
          />
        ),
        importance_id: data => (
          <div className="d-flex align-items-center">
            <Priority value={data.importance_id} />
            <TextWithTooltip className="h-auto ms-1">{data?.title}</TextWithTooltip>
          </div>
        ),
        status_id: data => (
          <div className="d-flex justify-content-between">
            <Status value={data.status_id} />
            {data.id ? (
              <TableViewIcon
                to={`${paths.flag_extensions_dispensations_jobs}/${data.id}`}
                icon={eyeSvg}
                className={'text-primary'}
              />
            ) : null}
          </div>
        )
      }}
    />
  );
};

export default FlagDispenation;
