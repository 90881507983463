import { useSelector } from 'react-redux';

import {
  selectActiveEvaluationId,
  selectHasEvaluateeConsented,
  selectIsEvaluationSubmitted
} from 'crew/store/evaluations/profile/selectors';

import x from 'common/assets/svg/common/x-circle.svg';
import checkIcon from 'common/assets/svg/common/check-circle.svg';
import send from 'common/assets/svg/actions/send.svg';
import EvaluationInfoLabel from './EvaluationInfoLabel';

const ConsentedLabel = ({ className, showSeperator = true }) => {
  const evaluationId = useSelector(selectActiveEvaluationId);
  const hasEvaluateeConsented = useSelector(selectHasEvaluateeConsented);
  const isEvaluationSubmitted = useSelector(selectIsEvaluationSubmitted);

  const isOnBoard = useSelector(state => state.isOnBoard);

  return evaluationId ? (
    <div className={`d-flex align-items-center ${className || 'cme-4'}`}>
      {hasEvaluateeConsented !== null ? (
        <div>
          {!hasEvaluateeConsented ? (
            <EvaluationInfoLabel
              className="seaman-not-consents-label border-red text-coral"
              svg={x}
            >
              Seaman does not Consent
            </EvaluationInfoLabel>
          ) : (
            <EvaluationInfoLabel
              className="seaman-consents-label border-turquoise text-turquoise"
              svg={checkIcon}
            >
              Seaman Consents
            </EvaluationInfoLabel>
          )}
        </div>
      ) : null}

      {isEvaluationSubmitted && !isOnBoard ? (
        <EvaluationInfoLabel
          className="submitted-label border-turquoise text-turquoise cms-12"
          svg={send}
        >
          Submitted
        </EvaluationInfoLabel>
      ) : null}

      {showSeperator ? (
        <div className="small-seperator text-platinum ms-3 me-3 cpt-12 cpb-12"></div>
      ) : null}
    </div>
  ) : null;
};

export default ConsentedLabel;
