const maritalStatus = [
  {
    fields: [
      {
        label: 'MARITAL STATUS',
        key: 'marital_status.name',
        select_key: 'marital_status_id',
        type: 'select',
        inputProps: {
          placeholder: 'Add marital status'
        }
      },
      {
        label: 'SPOUSE NAME',
        key: 'spouse_name',
        type: 'string',
        inputProps: {
          placeholder: 'Add spouse name'
        }
      },
      {
        label: 'SPOUSE BIRTH DATE',
        key: 'spouse_birthday',
        type: 'date',
        inputProps: {
          dataCy: 'spouse-birthdate',
          placeholder: 'Set date'
        }
      },
      {
        label: 'NUMBER OF CHILDREN',
        key: 'number_of_children',
        type: 'number',
        inputProps: {
          placeholder: 'Value'
        }
      },
      {
        key: 'children',
        type: 'multiple',
        data: [
          {
            label: 'NAME',
            key: 'name',
            type: 'string',
            inputProps: {
              placeholder: 'Add name'
            }
          },
          {
            label: 'SEX',
            key: 'gender.name',
            type: 'listSelect',
            select_key: 'gender_id',
            inputProps: {
              placeholder: 'Add gender ',
              list: 'genders',
              isAsync: false
            }
          },
          {
            label: 'DATE OF BIRTH',
            key: 'birthday',
            type: 'date',
            inputProps: {
              dataCy: 'child_birthday',
              placeholder: 'Set date'
            }
          }
        ]
      }
    ]
  }
];

export default maritalStatus;
