import Select, { SelectProps } from '@/ts-common/components/form/inputs/select';
import IconSelect, { IconSelectProps } from '@/ts-common/components/form/inputs/select/icon-select';
import useFilterQuerySelector from '@/common/components/filters/useFilterQuerySelector';
import { Props } from 'react-select';
import { Form } from '@/common/types/form-submissions';
import { useAppSelector } from '@/store/hooks';
import { selectAccount } from '@/store/account/selectors-ts';
import { getAuthorizedPermissionSettings } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';
import { useMemo } from 'react';

type BaseCollectionFilterProps<Option> = {
  value: number[] | null;
  onChange: (value: number[] | null) => void;
  avoidPermissions?: boolean;
} & SelectProps<Option>;

type CollectionFilterProps<Option> =
  | (BaseCollectionFilterProps<Option> & { icon?: undefined }) // when there's no icon
  | (BaseCollectionFilterProps<Option> & IconSelectProps); // when the icon is provided

function FormCollectionFilterSelector<Option extends Form>({
  value,
  icon,
  onChange,
  isMulti = true,
  avoidPermissions,
  ...rest
}: Omit<Props<Option>, 'value'> & CollectionFilterProps<Option>) {
  const account = useAppSelector(selectAccount);
  const permissionFormUids =
    !avoidPermissions &&
    getAuthorizedPermissionSettings(account, permissions.office.forms.view)?.forms;

  const defaultMemoizedRequestParams = useMemo(
    () => ({ path: 'lists', params: { list: 'forms', uid: permissionFormUids || undefined } }),
    [permissionFormUids]
  );

  const memoizedRequestParams = rest.memoizedRequestParams || defaultMemoizedRequestParams;

  const selectedOptions = useFilterQuerySelector<Option>(
    value || null,
    memoizedRequestParams,
    'uid'
  ) as Option[] | null;

  const CollectionFilterComponent = icon ? IconSelect : Select;

  return (
    <CollectionFilterComponent
      placeholder="Select value"
      icon={icon ? icon : ''}
      autoFocus={true}
      value={
        !isMulti
          ? selectedOptions?.length
            ? (selectedOptions[0] as unknown as Option)
            : null
          : (selectedOptions as unknown as Option[])
      } // Type assertion to make TypeScript happy
      onChange={selected => {
        if (isMulti) {
          const selectedValues = selected as Option[] | null;

          onChange(selectedValues?.length ? selectedValues.map(({ uid }) => uid) : null);
        } else {
          const selectedValue = selected as Option | null;

          onChange(selectedValue ? [selectedValue.uid] : null);
        }
      }}
      memoizedRequestParams={memoizedRequestParams}
      getOptionValue={option => option.uid.toString()}
      getOptionLabel={option => option.name}
      isAsync={true}
      isMulti={isMulti}
      {...rest}
    />
  );
}

export default FormCollectionFilterSelector;
