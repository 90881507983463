import _sortBy from 'lodash/sortBy';
import SingleCriterion from './SingleCriterion';
import { Col, Row } from 'reactstrap';

const CriteriaCategory = ({ criteria, category, view, onRecalculateAverageScore }) => {
  const filteredCriteria = view
    ? criteria.filter(item => item.category_id === category.id)
    : _sortBy(
        criteria.filter(item => item.state.category_id.value === category.id),
        'state.sort_index.value'
      );

  return (
    <div className="criteria-list">
      <div className="criteria-list--header">
        <Row noGutters>
          <Col xs={4} className="criteria-list--header-category">
            {category?.category?.name}
          </Col>
          <Col xs={view ? 3 : 5}>{view ? 'Grade' : 'Evaluate'}</Col>
          <Col xs={view ? 5 : 3}>Remarks</Col>
        </Row>
      </div>
      {filteredCriteria.map((item, i) => (
        <SingleCriterion
          key={i}
          view={view}
          index={i}
          tooltipID={`${category.id}-${i}`}
          criterion={item}
          onRecalculateAverageScore={onRecalculateAverageScore}
        />
      ))}
    </div>
  );
};

export default CriteriaCategory;
