import React, { Fragment, useCallback } from 'react';
import _findLastIndex from 'lodash/findLastIndex';

import BodyCell from './BodyCell';

import * as tableListActions from 'common/components/table/store/actions';
import useActions from 'common/utils/hooks/useActions';
import { useSelector } from 'react-redux';
import { isTableListRowSelected } from 'common/components/table/store/selectors';

const Row = ({
  rows,
  label,
  data,
  columns,
  index,
  getRowClassName,
  getRowId,
  setDangerousModal,
  onCellDoubleClick,
  getColStyle,
  lastSticky,
  lastCell,
  isRowSelectable,
  canSelectRows,
  highlightColumnOnHover
}) => {
  const [selectTableRow] = useActions([tableListActions.selectTableRow]);
  const isSelected = useSelector(state => isTableListRowSelected(state, label, data?.id));

  const onRowClick = useCallback(
    (data, index) => {
      if (canSelectRows && isRowSelectable(data, index)) {
        selectTableRow(data, label);
      }
    },
    [canSelectRows, label]
  );

  return (
    <div
      key={index}
      id={getRowId ? getRowId(data, index) : ''}
      className={`app-table--body--row ${getRowClassName ? getRowClassName(data, index) : ''}${
        isSelected ? ' selected' : ''
      }`}
      onClick={() => onRowClick(data, index)}
    >
      {columns.map((col, i) => {
        const groupLastStickyColumn = col.columns
          ? _findLastIndex(col.columns, o => o.sticky)
          : null;

        return (
          <Fragment key={col.key}>
            {col.columns ? ( // Grouped Columns
              col.columns.map(subCol => (
                <BodyCell
                  groupWidth={col.groupWidth ? col.groupWidth : col.width}
                  key={subCol.key}
                  setDangerousModal={setDangerousModal}
                  isLastSticky={i === groupLastStickyColumn}
                  isLastCell={i === lastCell}
                  col={subCol}
                  groupedCol={col}
                  isGroup={true}
                  row={rows ? rows[subCol.key] : null}
                  data={data}
                  style={getColStyle(subCol)}
                  rowIndex={index}
                  dataGroup={col.key}
                  label={label}
                  onCellDoubleClick={onCellDoubleClick}
                  highlightColumnOnHover={highlightColumnOnHover}
                />
              ))
            ) : (
              <BodyCell
                setDangerousModal={setDangerousModal}
                isLastSticky={i === lastSticky}
                isLastCell={i === lastCell}
                key={col.key}
                col={col}
                groupedCol={col}
                row={rows ? rows[col.key] : null}
                data={data}
                style={getColStyle(col)}
                rowIndex={index}
                label={label}
                onCellDoubleClick={onCellDoubleClick}
                highlightColumnOnHover={highlightColumnOnHover}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Row;
