import React, { useEffect, useState } from 'react';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Warning from './components/Warning';
import _get from 'lodash/get';
import { calculateWarning } from './calculations';
import { usePrevious } from 'utils/hooks';
import deepEqual from 'common/utils/deep-compare';
import SvgRender from 'common/components/general/SvgRender';
import calculator from 'common/assets/svg/common/calculator.svg';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';
import CalculationTooltip from './components/CalculationTooltip';
import FormErrorIconWithTooltip from 'common/components/forms/_components/FormErrorIconWithTooltip';

const NumberInputWrapper = ({
  onChange,
  value,
  error,
  disabled,
  formField,
  state,
  fields = []
}) => {
  const [calculatedValue, setCalculatedValue] = useState(null);

  const { avoidRender, tooltipID } = useTooltipID('numeric-formula-tooltip');

  const rule = _get(formField, 'rules.0.rule', null);
  const ruleValues = _get(formField, 'rules.0.values');
  const { is_calculated, formula, formula_variables, decimal_digit_precision } = formField;

  const message = calculateWarning(rule, ruleValues, value);

  const previousState = usePrevious(state);

  const min = (...nums) => Math.min(...nums);
  const max = (...nums) => Math.max(...nums);

  const evaluate = fn => {
    return new Function('max, min', `return ${fn}`)(max, min);
  };

  const calculate = () => {
    const allHaveValues = formula_variables.every(v => state[v.variable_form_form_field_id].value);

    if (!allHaveValues) {
      setCalculatedValue(null);
      return;
    }

    const replacedFormula = formula.replace(/x\d+/g, v =>
      strToNumber(
        state[formula_variables.find(fv => fv.variable === v).variable_form_form_field_id].value,
        decimal_digit_precision,
        decimal_digit_precision
      )
    );

    const finalValue = evaluate(replacedFormula);
    setCalculatedValue(finalValue);
    onChange(finalValue);
  };

  useEffect(() => {
    if (!is_calculated || !formula_variables || !formula) return;

    const diffs = deepEqual(previousState ? previousState : {}, state, true);

    const formualFieldChanged = !!formula_variables.find(
      fv => fv.variable_form_form_field_id === +Object.keys(diffs)[0]
    );

    if (!formualFieldChanged) return;

    calculate();
  }, [state]);

  useEffect(() => {
    if (value) {
      setCalculatedValue(value);
    }
  }, []);

  if (avoidRender) return null;

  return (
    <>
      {is_calculated ? (
        <div className={`d-flex align-items-center cpt-4`}>
          {error ? <FormErrorIconWithTooltip wrapperClassName="cme-4" /> : null}
          <div
            id={tooltipID}
            className="calculated-numeric-field d-flex align-items-center justify-content-center cursor-pointer"
          >
            <SvgRender
              className="text-turquoise"
              style={{ width: 7, height: 9 }}
              src={calculator}
            />
          </div>
          <span className="fs-12 text-primary ms-1">
            {numberToStr(calculatedValue, decimal_digit_precision, decimal_digit_precision)}
          </span>

          <Tooltip target={tooltipID} innerClassName="min-width-fit">
            <CalculationTooltip
              formula={formula}
              formulaVariables={formula_variables}
              fields={fields}
            />
          </Tooltip>
        </div>
      ) : (
        <NumberInput
          onChange={e => onChange(e.target.value)}
          className={`mb-0 position-relative ${is_calculated ? 'pointer-events-none' : ''}`}
          placeholder="Add number"
          value={value}
          error={error}
          disabled={disabled}
          decimalScale={decimal_digit_precision || undefined}
          fixedDecimalScale={decimal_digit_precision || undefined}
          allowUnlimitedDecimalScale={!decimal_digit_precision}
        />
      )}

      {message ? (
        <Warning
          svgClassName="position-absolute"
          valuesMessage={
            <div>
              <span className="text-nowrap">Value entered is out of the validation range</span>
              <div className="d-flex cmt-4">Range: {message}</div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default NumberInputWrapper;
