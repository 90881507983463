import PropTypes from 'prop-types';
import { RankType } from 'common/entities/crew/RankTypes';

export const offSignerContractsOrderedType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  crew_member_id: PropTypes.number.isRequired,
  vessel_id: PropTypes.number.isRequired,
  number: PropTypes.string,
  contract_from_date: PropTypes.string.isRequired,
  initial_contract_to_date: PropTypes.string.isRequired,
  starting_date: PropTypes.string.isRequired,
  sign_on_date: PropTypes.string.isRequired,
  sign_off_date: PropTypes.string,
  left_home_date: PropTypes.string,
  arrived_home_date: PropTypes.string,
  sign_on_port_id: PropTypes.number.isRequired,
  sign_off_port_id: PropTypes.number,
  sign_off_reason_id: PropTypes.number,
  rank_id: PropTypes.number.isRequired,
  currency_id: PropTypes.number,
  payroll_rank_id: PropTypes.number,
  sign_off_remarks: PropTypes.string,
  remarks: PropTypes.string,
  contract_ending_date_change_reason_id: PropTypes.number,
  contract_to_date: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  sign_on_ticket_id: PropTypes.number,
  sign_off_ticket_id: PropTypes.number,
  external_ids: PropTypes.string,
  crew_wage_id: PropTypes.number,
  requested_sign_off_date: PropTypes.string,
  updated_at_on_sync_to_onboard: PropTypes.string.isRequired,
  sign_on_date_as_per_captain: PropTypes.string,
  sign_on_date_as_per_office: PropTypes.string.isRequired,
  sign_off_date_as_per_captain: PropTypes.string,
  sign_off_date_as_per_office: PropTypes.string,
  sign_on_port_id_as_per_captain: PropTypes.number,
  sign_on_port_id_as_per_office: PropTypes.number.isRequired,
  sign_off_port_id_as_per_captain: PropTypes.number,
  sign_off_port_id_as_per_office: PropTypes.number,
  synced_at: PropTypes.string,
  pivot: PropTypes.shape({
    rotation_id: PropTypes.number.isRequired,
    contract_id: PropTypes.number.isRequired
  }),
  rank: RankType
});

export const offSignerRotationsTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    off_signer_id: PropTypes.number.isRequired,
    vessel_id: PropTypes.number.isRequired,
    contract_id: PropTypes.number.isRequired,
    off_signer_rank_id: PropTypes.number.isRequired,
    on_signer_id: PropTypes.number,
    wont_be_replaced: PropTypes.bool.isRequired,
    deprecated_by: PropTypes.number,
    port_id: PropTypes.number.isRequired,
    date_of_change: PropTypes.string.isRequired,
    on_signer_contract_id: PropTypes.number,
    on_signer_rank_id: PropTypes.number,
    remarks: PropTypes.string,
    replaced: PropTypes.bool.isRequired,
    finalized_at: PropTypes.string,
    replacement_finalized_by: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    last_sent_version: PropTypes.number,
    on_signer_trip_id: PropTypes.number,
    off_signer_trip_id: PropTypes.number,
    on_signer_accepted_ticket_id: PropTypes.number,
    off_signer_accepted_ticket_id: PropTypes.number,
    updated_at_on_sync_to_onboard: PropTypes.string,
    off_signer_contracts_ordered: PropTypes.arrayOf(offSignerContractsOrderedType)
  })
);
