import { Props } from 'react-select';
import {
  MultiValueLabel,
  Option
} from '@/common/components/selectors/departments/DepartmentSelector.jsx';
import CollectionFilterSelector, { CollectionFilterProps } from './CollectionFilterSelector';

type DepartmentCollectionFilterSelectorProps<Option> = {
  showCrewDepartments?: boolean;
} & CollectionFilterProps<Option>;

function DepartmentCollectionFilterSelector<Option extends { id: number; name?: string }>({
  showCrewDepartments,
  ...props
}: Omit<Props<Option>, 'value'> & DepartmentCollectionFilterSelectorProps<Option>) {
  return (
    <CollectionFilterSelector
      {...props}
      memoizedRequestParams={{
        path: 'lists',
        params: {
          list: 'departments',
          include_crew_departments: showCrewDepartments ? true : undefined
        }
      }}
      components={{ Option, MultiValueLabel }}
    />
  );
}

export default DepartmentCollectionFilterSelector;
