import React from 'react';

import { Row, Col } from 'reactstrap';
import renderProfileValue from 'common/components/layouts/profile-data-box/renderProfileValue';

const GroupFields = ({ left, right, className }) => (
  <div className={`crew-profile__contracts-group ${className || ''}`}>
    <Row className={`align-items-center g-0`}>
      <Col xs={6}>
        {left ? (
          <div className="d-flex align-items-center">
            <div className="form-label">{left.field.label}</div>
            {left.field?.subLabel ? left.field.subLabel : null}
          </div>
        ) : null}
      </Col>
      <Col xs={6}>
        {right ? (
          <div className="d-flex align-items-center">
            <div className="form-label">{right.field.label}</div>
            {right.field?.subLabel ? right.field.subLabel : null}
          </div>
        ) : null}
      </Col>
    </Row>

    <Row className={`align-items-center g-0 ${className || ''}`}>
      <Col xs={4}>
        {left ? (
          <div className="fw-bold text-primary ">{renderProfileValue(left.field, left.data)}</div>
        ) : null}
      </Col>
      <Col xs={1}>
        {right ? <div className="crew-profile__contracts-group-seperator"></div> : null}
      </Col>
      <Col xs={{ size: 4, offset: 1 }}>
        {right ? (
          <div className="fw-bold text-primary ">{renderProfileValue(right.field, right.data)}</div>
        ) : null}
      </Col>
    </Row>
  </div>
);

export default GroupFields;
