import { getOfficeAttachments } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  data: [],
  loading: false,
  groups: [] // This is for event templates, we can add groups here but no attachments
};

const slice = createSlice({
  name: 'officeAttachments',
  initialState: INITIAL_STATE,
  reducers: {
    addAttachmentGoup: state => {
      state.groups.push({ name: '' });

      return state;
    },

    editAttachmentGroupName: (state, { payload }) => {
      const { index, name } = payload;

      if (state.groups[index]) {
        state.groups[index].name = name;
      }

      return state;
    },

    removeAttachmentGroup: (state, { payload }) => {
      const { index } = payload;

      if (state.groups[index]) {
        state.groups.splice(index, 1);
      }

      return state;
    },

    setAttachmentGroups: (state, { payload }) => {
      state.groups = payload;

      return state;
    },

    setAttachmentsData: (state, { payload }) => {
      state.data = payload;

      return state;
    },

    editAttachmentGroup: (state, { payload }) => {
      const { id, name, attachments } = payload;
      const index = state.data.findIndex(group => group.id === id);

      if (index !== -1) {
        state.data[index].name = name;
        state.data[index].attachments = attachments;
      }

      return state;
    },

    deleteAttachmentGroup: (state, { payload }) => {
      const { id } = payload;

      state.data = state.data.filter(group => group.id !== id);
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getOfficeAttachments.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getOfficeAttachments.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        return state;
      })

      .addCase(getOfficeAttachments.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const {
  addAttachmentGoup,
  editAttachmentGroupName,
  removeAttachmentGroup,
  setAttachmentGroups,
  setAttachmentsData: setOfficeAttachmentsData,
  editAttachmentGroup,
  deleteAttachmentGroup
} = slice.actions;

export default slice.reducer;
