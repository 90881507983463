import TYPES from './types';

import { addSecondaryBunkerConsumptionColumn } from 'captain-reports/templates/config/_bunkersHelpers';
import { parseReportFields } from 'captain-reports/templates/config/_helpers';
import { validateForm } from 'captain-reports/templates/config/validations';
import _pickBy from 'lodash/pickBy';
import _isArray from 'lodash/isArray';
import _groupBy from 'lodash/groupBy';
import { selectCaptainReportBunkering, selectCaptainReportsConsumptions } from './selectors';
import { checkIfArrayHasValues, isReportStatusLocked } from 'captain-reports/store/helpers';
import {
  selectCaptainReportStatus,
  selectCaptainReportIsOnBoard,
  selectEdittingApprovedReport
} from 'captain-reports/store/selectors';
import moment from 'moment';

export const setValue =
  ({ key, value, sectionLabel, sectionActiveTab, subGroup, subGroupIndex, selectKey }) =>
  (dispatch, getState) => {
    const state = getState();
    const status = selectCaptainReportStatus(state);
    const isOnboard = selectCaptainReportIsOnBoard(state);
    const isEdittingApprovedReport = selectEdittingApprovedReport(state);

    const isLocked = isReportStatusLocked(status, isOnboard, isEdittingApprovedReport);

    if (isLocked) return;

    dispatch({
      type: TYPES.SET_VALUE,
      payload: {
        key,
        value,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex,
        selectKey
      }
    });
  };

export const setComment =
  ({ key, comment, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) =>
  dispatch => {
    dispatch({
      type: TYPES.SET_COMMENT,
      payload: { key, comment, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }
    });
  };

export const setWarning =
  ({ key, error, isRequired, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) =>
  dispatch => {
    dispatch({
      type: TYPES.SET_WARNING,
      payload: {
        key,
        error,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex,
        isRequired
      }
    });
  };

export const setReportTotals = data => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_TOTALS, payload: data });

export const setReportNavigation = data => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_NAVIGATION, payload: data });

export const setReportPreviousBsViscosities = data => dispatch =>
  dispatch({ type: TYPES.SET_PREVIOUS_BS_VISCOSITIES, payload: data });

export const setReportPreviousBsBioFuels = data => dispatch =>
  dispatch({ type: TYPES.SET_PREVIOUS_BS_BIOFUELS, payload: data });

export const setReportBunkering = data => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_BUNKERING, payload: data });

export const setPreviousReport = data => dispatch =>
  dispatch({ type: TYPES.SET_PREVIOUS_REPORT, payload: data });

export const setAerLegAverage = data => dispatch =>
  dispatch({ type: TYPES.SET_AER_LEG_AVERAGE, payload: data });

export const setAerLastReport = data => dispatch =>
  dispatch({ type: TYPES.SET_AER_LAST_REPORT, payload: data });

export const setLegAttainedCII = data => dispatch =>
  dispatch({ type: TYPES.SET_LEG_ATTAINED_CII, payload: data });

export const setReportVessel = data => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_VESSEL, payload: data });

export const setReportData = data => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_DATA, payload: data });

export const setReportTemplateData = template => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_TEMPLATE_DATA, payload: template });

export const setReportTemplateTooltips = templateFields => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_TEMPLATE_TOOLTIPS, payload: templateFields });

export const setReportTemplateVisibleFields = templateFields => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_TEMPLATE_VISIBLE_FIELDS, payload: templateFields });

export const toggleReportTemplateFieldVisibility = (field_label, visible) => dispatch =>
  dispatch({
    type: TYPES.TOGGLE_REPORT_TEMPLATE_FIELD_VISIBILITY,
    payload: { field_label, visible }
  });

export const setReportTemplateValidationFields = templateFields => dispatch =>
  dispatch({
    type: TYPES.SET_REPORT_TEMPLATE_VALIDATION_FIELDS,
    payload: templateFields
  });

export const toggleSection = (sectionLabel, isOpen) => dispatch =>
  dispatch({ type: TYPES.TOGGLE_SECTION, payload: { sectionLabel, isOpen } });

export const forceUpdateSectionProgress = value => dispatch =>
  dispatch({ type: TYPES.FORCE_UPDATE_SECTION_PROGRESS, payload: value });

export const changeSectionActiveTab = (tabIndex, sectionLabel) => dispatch =>
  dispatch({
    type: TYPES.CHANGE_SECTION_ACTIVE_TAB,
    payload: { tabIndex, sectionLabel }
  });

export const setLastUpdatedGeneratorTabIndex = index => dispatch =>
  dispatch({
    type: TYPES.SET_LAST_UPDATED_GENERATOR_TAB_INDEX,
    payload: { index }
  });

export const setForm = data => dispatch => dispatch({ type: TYPES.SET_FORM, payload: data });

export const getReportParams = status => (dispatch, getState) => {
  let state = getState();
  const formData = state.captainReportsForm;
  const reportData = state.captainReports.data;
  const onBoard = state.captainReports.onBoard;

  const {
    id,
    vessel_id,
    updated_at,
    orca_id,
    template_id,
    sent_at,
    created_at,
    synced_at,
    next_port,
    current_port,
    timestamp: ts,
    ...restData
  } = reportData;

  const { errors, ...rest } = validateForm(formData, state, status);
  dispatch(setForm(rest));

  if (errors.required && status !== 'pending_approval') {
    dispatch({ type: TYPES.SET_REQUIRED_VALIDATION_ERROR_FLAG, payload: true });
  } else {
    dispatch({ type: TYPES.SET_REQUIRED_VALIDATION_ERROR_FLAG, payload: false });
  }

  if (errors.template && onBoard && status !== 'pending_approval') {
    dispatch({ type: TYPES.SET_TEMPLATE_VALIDATION_ERROR_FLAG, payload: true });
  } else {
    dispatch({ type: TYPES.SET_TEMPLATE_VALIDATION_ERROR_FLAG, payload: false });
  }

  if (status !== 'pending_approval' && onBoard && (errors.template || errors.required)) {
    return null;
  }
  const { timestamp, local_timestamp, ...restFormData } = formData;

  const params = {
    status,
    id,
    local_timestamp: moment(local_timestamp.value).format('YYYY-MM-DD HH:mm:ss'),
    ...parseReportFields(restFormData)
  };

  const paramsWithValue = _pickBy(params, val =>
    _isArray(val) ? checkIfArrayHasValues(val) : val !== null
  ); // Send to api the NOT empty values

  const initialStateWithValue = _pickBy(restData, val =>
    _isArray(val) ? checkIfArrayHasValues(val) : val !== null
  );

  paramsWithValue.initial_state = initialStateWithValue;

  return paramsWithValue;
};

export const setCaptainReportRebuildComments = data => dispatch =>
  dispatch({ type: TYPES.SET_CAPTAIN_REPORT_REBUILD_COMMENTS, payload: data });

export const setReportOnBoard = onBoard => dispatch =>
  dispatch({ type: TYPES.SET_REPORT_ON_BOARD, payload: onBoard });

export const setInvalidReport = invalid => dispatch =>
  dispatch({ type: TYPES.SET_INVALID_REPORT, payload: invalid });

export const setEdittingApprovedReport = isEditting => dispatch =>
  dispatch({ type: TYPES.SET_EDITTING_APPROVED_REPORT, payload: isEditting });

export const setFutureReports = data => dispatch =>
  dispatch({ type: TYPES.SET_FUTURE_REPORTS, payload: data });

export const setSelectedFutureReports = data => dispatch =>
  dispatch({ type: TYPES.SET_SELECTED_FUTURE_REPORTS, payload: data });

export const setYearAttainedCII = data => dispatch =>
  dispatch({ type: TYPES.SET_YEAR_ATTAINED_CII, payload: data });

export const addBunkerConsumptionColumn =
  ({ index }) =>
  dispatch =>
    dispatch({
      type: TYPES.ADD_BUNKER_CONSUMPTION_COLUMN,
      payload: { index }
    });

export const removeBunkerConsumptionColumn =
  ({ index }) =>
  dispatch =>
    dispatch({
      type: TYPES.REMOVE_BUNKER_CONSUMPTION_COLUMN,
      payload: { index }
    });

export const autoCalculateBunkerConsumptionColumns = () => (dispatch, getState) => {
  const state = getState();

  let consumptions = selectCaptainReportsConsumptions(state);
  let shouldUpdateConsumptions = false;

  const bunkering = selectCaptainReportBunkering(state);
  const bunkeringPerFuelGrade = _groupBy(bunkering, b => b.fuel_grade_id);

  Object.keys(bunkeringPerFuelGrade).forEach(fuelGradeId => {
    if (
      bunkeringPerFuelGrade[fuelGradeId]?.length > 1 &&
      consumptions.filter(
        consumption => parseInt(consumption?.fuel_grade_id?.value) === parseInt(fuelGradeId)
      )?.length === 1
    ) {
      /* If I have 2 different viscosities (per fuel grade) but only one consumption column for this fuel grade, 
        we automatically add the secondary column.
      */
      const index = consumptions.findIndex(
        consumption => parseInt(consumption?.fuel_grade_id?.value) === parseInt(fuelGradeId)
      );

      console.info('Auto-appending consumption column for fuel grade:', fuelGradeId);

      consumptions = addSecondaryBunkerConsumptionColumn({ index }, consumptions);
      shouldUpdateConsumptions = true;
    }
  });

  if (shouldUpdateConsumptions)
    dispatch({
      type: TYPES.AUTO_CALCULATE_BUNKER_CONSUMPTION_COLUMNS,
      payload: consumptions
    });
};
