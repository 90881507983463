import variables from 'common/assets/scss/abstracts/_exports.module.scss';

export const baseOptionStyles = (provided, state, styles = {}) => ({
  ...provided,
  padding: `${variables.size4} ${variables.size12}`,
  fontWeight: 400,
  backgroundColor: (state.isSelected && !state.isMulti) || state.isActive ? variables.bodyBg : '',
  color: variables.primary,
  lineHeight: '15px',
  fontSize: variables.inputsFontSize,

  ['&:not(:last-child)']: {
    marginBottom: 4
  },

  ['&:active']: {
    backgroundColor: variables.bodyBg
  },
  ['&:hover']: {
    backgroundColor: variables.bodyBg
  },

  ...styles
});

export const baseMenuStyles = (provided, _, styles = {}) => ({
  ...provided,
  marginTop: 4,
  marginBottom: 0,
  borderRadius: 3,
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)',
  border: 'none',
  borderTop: 0,
  backgroundColor: '#fff',
  width: '100%',
  zIndex: 100,
  ...styles
});

export const basePlaceholderStyles = (provided, _, styles = {}) => ({
  ...provided,
  fontWeight: variables.inputPlaceholderFontWeight,
  fontSize: variables.inputsFontSize,
  color: variables.inputPlaceholderColor,
  marginLeft: 0,

  ...styles
});
