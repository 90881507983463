import Select, { SelectProps } from '..';
import { GroupBase, MultiValue, Props } from 'react-select';
import SvgRender from '@/ts-common/components/general/SvgRender';

export type IconSelectProps = {
  hideIcon?: boolean;
  iconStyle?: React.CSSProperties;
  icon: string;
  containerClassName?: string;
};

function IconSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  size,
  isMulti,
  value,
  hideIcon,
  iconStyle,
  icon,
  containerClassName = '',
  ...rest
}: Props<Option, IsMulti, Group> & SelectProps<Option> & IconSelectProps) {
  const iconSize = size === 'sm' ? 14 : 16;

  return (
    <div
      className={`icon-select${
        (isMulti ? !(value as MultiValue<Option>)?.length : !value) ? ' icon-select--empty' : ''
      } ${containerClassName} ${hideIcon ? 'icon-select--hide-icon' : ''}`}
    >
      <div className="icon-select__icon">
        <SvgRender src={icon} style={iconStyle || { height: iconSize, width: iconSize }} />
      </div>

      <Select isMulti={isMulti} value={value} size={size} {...rest} />
    </div>
  );
}

export default IconSelect;
