import React from 'react';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';

const DepartmentsInput = ({ onChange, disabled, error, value = [] }) => {
  return (
    <DepartmentSelector
      value={disabled ? null : value}
      error={error}
      onChange={e => onChange(e)}
      placeholder="Select department(s)"
      isMulti={true}
      styled={false}
      autoFocus={false}
      withCrewDepartments={true}
      className={`mb-0`}
    />
  );
};

export default DepartmentsInput;
