import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import _get from 'lodash/get';
import OverFlownText from 'common/components/general/TextOverflow';

const Description = props => {
  const { properties } = props;

  const id = _get(props, 'id', 'None');

  const oldDesc = _get(properties, 'old.description', 'None');
  const newDesc = _get(properties, 'attributes.description', 'None');

  const createBeforeMarkup = () => {
    return {
      __html: oldDesc
    };
  };

  const createAfterMarkup = () => {
    return {
      __html: newDesc
    };
  };

  return (
    <div className="d-flex align-items-center activity-desc">
      <OverFlownText
        text={
          oldDesc ? (
            <div className="desc-container" dangerouslySetInnerHTML={createBeforeMarkup()} />
          ) : (
            'None'
          )
        }
        width={150}
        id={`old-${id}-descOld`}
        className="fs-12 text-violet fw-light"
      />
      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <OverFlownText
        text={newDesc ? <div dangerouslySetInnerHTML={createAfterMarkup()} /> : 'None'}
        width={150}
        id={`new-${id}-descNew`}
        className="fs-12 text-violet fw-light"
      />
    </div>
  );
};

export default Description;
