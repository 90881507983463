import paths from 'routing/routes/_paths';

import Manuals from 'views/manuals';
import ManualInner from 'views/manuals/inner';

import { selectManualTitle } from 'manuals/store/selectors';
import permissions from 'common/utils/permissions/constants';

const manualsRoutes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: paths.manuals,
    component: Manuals,
    permissions: [permissions.onboard.manuals.view],
    documentTitle: 'Manuals'
  },
  {
    path: `${paths.manuals}/:id`,
    component: ManualInner,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.manuals.view],
    documentTitle: selectManualTitle
  },
  {
    path: `${paths.manuals}/:id/chapter/:chapter_uid`,
    component: ManualInner,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.manuals.view],
    documentTitle: selectManualTitle
  }
];

export default manualsRoutes;
