import React from 'react';
import PropTypes from 'prop-types';

import paths from 'routing/routes/_paths';
import { numberToStr } from 'common/utils/numbers';
import Information from 'common/components/general/Information';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectCrewWageAccountAmounts,
  selectHasCrewWarning
} from 'common/components/mga/store/selectors';
import SvgRender from 'common/components/general/SvgRender';
import warningIcon from 'common/assets/svg/common/error-in-circle.svg';

const CrewAccountInfo = ({ id, contractId }) => {
  const wageAccountAmounts = useSelector(state =>
    selectCrewWageAccountAmounts(state, id, contractId)
  );

  if (!wageAccountAmounts?.length) return null;

  return (
    <Information
      svgStyle={{ width: 13, height: 13 }}
      className="text-primary"
      tooltipClassname="tooltip-inner-max-content "
      message={
        <Row className="text-start text-nowrap flex-nowrap pe-1">
          <Col xs="auto" className="pe-4">
            {wageAccountAmounts.map(a => (
              <div key={a.account.id}>{a.account.name}</div>
            ))}
          </Col>
          <Col xs={1}>
            {wageAccountAmounts.map(a => (
              <div className="fw-bold" key={a.account.id}>
                $
              </div>
            ))}
          </Col>
          <Col xs="auto" className="pe-0">
            {wageAccountAmounts.map(a => (
              <div key={a.account.id} className="text-end">
                <strong className="text-end">{numberToStr(a.amount, 2, 2)}</strong>
              </div>
            ))}
          </Col>
          <Col xs="auto" className="ps-0">
            {wageAccountAmounts.map(a => (
              <div key={a.account.id}>
                &nbsp;|&nbsp;<span>{a.label}</span>
              </div>
            ))}
          </Col>
        </Row>
      }
    />
  );
};

CrewAccountInfo.propTypes = {
  id: PropTypes.number.isRequired,
  contractId: PropTypes.number.isRequired
};

const NameColumn = ({ data }) => {
  const hasWarning = useSelector(state => selectHasCrewWarning(state, data.contract_id));

  return (
    <Row className="align-items-center w-100p overflow-hidden text-nowrap" noGutters>
      {hasWarning ? (
        <Col xs="auto" className="d-flex align-items-center">
          <SvgRender
            className="text-red cme-6"
            src={warningIcon}
            style={{ width: 13, height: 13 }}
          />
        </Col>
      ) : null}

      <Col xs={10}>
        <Link
          to={`${paths.crew}/${data.id}`}
          className="d-flex align-items-center justify-content-between w-100p"
        >
          <div className="flex-1 overflow-hidden text-nowrap">
            <TextWithTooltip>{data.full_name}</TextWithTooltip>
          </div>
        </Link>
      </Col>
      <Col xs="auto" className="d-flex align-items-center ms-auto">
        <CrewAccountInfo id={data.id} contractId={data.contract_id} />
      </Col>
    </Row>
  );
};

export default NameColumn;

NameColumn.propTypes = {
  data: PropTypes.shape({
    account_id: PropTypes.number,
    closing_balance: PropTypes.number || PropTypes.string,
    contract_id: PropTypes.number.isRequired,
    days: PropTypes.number || PropTypes.string,
    deductions: PropTypes.object,
    earnings: PropTypes.object,
    from: PropTypes.string,
    full_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    opening_balance: PropTypes.number || PropTypes.string,
    rank: PropTypes.string,
    to: PropTypes.string,
    total_amount_due: PropTypes.number || PropTypes.string,
    total_deductions: PropTypes.number || PropTypes.string
  })
};
