import React from 'react';
import ListSelect from 'common/components/selectors/ListSelect';

const MgaActionTypesSelector = ({ filter, onChange, isMulti, className, ...rest }) => {
  return (
    <ListSelect
      className={`e mb-0 ${className}`}
      placeholder="Select value"
      autoFocus
      list="mga-action-types"
      getOptionValue={option => option.id}
      getOptionLabel={option => `${option.name}`}
      isAsync={false}
      invisible={false}
      isClearable
      onChange={selected => onChange({ value: selected ? selected : '' })}
      value={filter?.value}
      {...rest}
    />
  );
};

export default MgaActionTypesSelector;
