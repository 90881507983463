import { FC } from 'react';
import { useGetCaptainReportsAlarmsDue } from '@/api/captain-reports/queries';
import { useAppSelector } from '@/store/hooks';
import { displayDate } from '@/ts-common/utils/dates';
import { numberToStr } from '@/ts-common/utils/numbers';
import runningHours from 'common/assets/svg/common/reminder.svg';
import Information from '@/ts-common/components/general/Information';

type RunningHoursReportTooltipProps = {
  vesselId?: number;
};

const RunningHoursReportTooltip: FC<RunningHoursReportTooltipProps> = ({ vesselId }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const alarmsQuery = useGetCaptainReportsAlarmsDue(isOnBoard ? {} : { vessel_id: vesselId });

  return (
    <Information
      message={
        <>
          <strong className="mb-1 pe-3">Running Hours Report</strong>
          <span className="mb-1">
            Due Date: {displayDate(alarmsQuery.data?.due_date, { time: true })}
          </span>
          <span>
            <strong>{numberToStr(alarmsQuery.data?.time_remaining)}</strong> Days left
          </span>
        </>
      }
      svgIcon={runningHours}
      svgStyle={{ width: 15, height: 15 }}
      svgClassname="text-primary"
      tooltipClassname="d-flex flex-column align-items-start"
      wrapperClassName={
        !vesselId || alarmsQuery.isPending || alarmsQuery.isError ? 'disabled pe-none' : ''
      }
    />
  );
};

export default RunningHoursReportTooltip;
