import React from 'react';

import _get from 'lodash/get';

const D3XLabel = ({ axesConfig }) => {
  const xLabel = _get(axesConfig, 'x.label');
  if (!xLabel) return null;

  return (
    <div className="d-flex align-items-center justify-content-center">
      <span className="axis-label">{xLabel}</span>
    </div>
  );
};

export default D3XLabel;
