import edit from 'common/assets/svg/actions/edit-empty.svg';
import history from 'common/assets/svg/common/history.svg';
import hourglass from 'common/assets/svg/common/hourglass.svg';

export const getConfig = (type, isJobLocked, isOnBoard) => {
  switch (type) {
    case 'previous':
      return {
        styleClass: 'system-running-hour__sidebar--previous',
        title: 'PREVIOUS',
        dateTitleField: 'DATE & TIME CARRIED OUT JOB',
        hoursTitleField: 'TOTAL SYSTEM RUNNING HOURS',
        dateFieldKey: 'carried_out_at',
        hoursFieldKey: 'total_system_running_hours',
        icon: history,
        color: 'secondary-gray',
        isDisabled: true,
        isLocked: true,
        hasTooltip: true,
        dateInputProps: {
          hasTime: false,
          showTime: false
        }
      };
    case 'fill_in':
      return {
        styleClass: 'system-running-hour__sidebar--fill-in',
        title: 'FILL IN',
        dateTitleField: 'DATE & TIME CARRIED OUT JOB',
        hoursTitleField: 'TOTAL SYSTEM RUNNING HOURS',
        dateFieldKey: 'carried_out_at',
        hoursFieldKey: 'total_system_running_hours',
        icon: edit,
        color: 'primary',
        isDisabled: false,
        isLocked: isJobLocked,
        hasTooltip: true,
        dateInputProps: {
          hasTime: true,
          showTime: true
        }
      };
    case 'due':
      return {
        styleClass: 'system-running-hour__sidebar--due',
        title: 'DUE',
        dateTitleField: 'DUE DATE',
        hoursTitleField: 'RUNNING HOURS DUE',
        dateFieldKey: 'due_date',
        hoursFieldKey: 'due_on_rh',
        icon: hourglass,
        color: 'primary',
        isDisabled: false,
        isLocked: isOnBoard,
        hasTooltip: false,
        dateInputProps: {
          hasTime: false,
          showTime: false
        }
      };
    default:
      return {};
  }
};
