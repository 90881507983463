import { useSelector } from 'react-redux';
import CommonPersonal from 'crew/profile/main/personal';
import { isAuthorized } from 'utils/permissions/authorize';

import getPersonalConfig, { rightSideInfoConfig } from './config';
import { selectAccount } from 'store/account/selectors';

const Personal = () => {
  const account = useSelector(selectAccount);
  const config = getPersonalConfig(account);
  const configAfterPermissions = config.filter(el => isAuthorized(account, el.permissions));

  return (
    <div className="crew-profile__personal">
      <CommonPersonal config={configAfterPermissions} rightSideInfoConfig={rightSideInfoConfig} />
    </div>
  );
};

export default Personal;
