import NumberField from 'captain-reports/templates/inputs/NumberField';
import TemplateFuelGradeField from 'captain-reports/templates/components/custom-fields/TemplateFuelGradeField';

const noon = () => [
  {
    fields: [
      {
        label: 'Fuel Grade used for cargo purposes',
        key: 'cii_reduction_fuel_grade_used_for_cargo_purposes',
        width: { xs: 4 },
        render: TemplateFuelGradeField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Reefer Containers',
        subLabel: 'kWh',
        key: 'cii_reduction_energy_used_for_reefer_containers',
        type: 'number',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Reefer Containers in Use',
        key: 'cii_reduction_reefer_containers_in_use',
        type: 'number',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Cargo Cooling',
        key: 'cii_reduction_energy_used_for_cargo_cooling',
        type: 'number',
        subLabel: 'kWh',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Cargo Discharge',
        key: 'cii_reduction_energy_used_for_cargo_discharging',
        type: 'number',
        subLabel: 'kWh',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  }
];

const departure = () => [
  {
    fields: [
      {
        label: 'Fuel Grade used for cargo purposes',
        key: 'cii_reduction_fuel_grade_used_for_cargo_purposes',
        width: { xs: 4 },
        render: TemplateFuelGradeField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Reefer Containers',
        subLabel: 'kWh',
        key: 'cii_reduction_energy_used_for_reefer_containers',
        type: 'number',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Reefer Containers in Use On Arrival',
        key: 'cii_reduction_reefer_containers_in_use_on_arrival',
        type: 'number',
        width: { xs: 4 },
        render: NumberField
      },
      {
        label: 'Reefer Containers in Use at Departure',
        key: 'cii_reduction_reefer_containers_in_use_at_departure',
        type: 'number',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Cargo Cooling',
        key: 'cii_reduction_energy_used_for_cargo_cooling',
        type: 'number',
        subLabel: 'kWh',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Cargo Discharge',
        key: 'cii_reduction_energy_used_for_cargo_discharging',
        type: 'number',
        subLabel: 'kWh',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  }
];

const port_noon = () => [
  {
    fields: [
      {
        label: 'Fuel Grade used for cargo purposes',
        key: 'cii_reduction_fuel_grade_used_for_cargo_purposes',
        width: { xs: 4 },
        render: TemplateFuelGradeField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Reefer Containers',
        subLabel: 'kWh',
        key: 'cii_reduction_energy_used_for_reefer_containers',
        type: 'number',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Cargo Cooling',
        key: 'cii_reduction_energy_used_for_cargo_cooling',
        type: 'number',
        subLabel: 'kWh',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Energy used for Cargo Discharge',
        key: 'cii_reduction_energy_used_for_cargo_discharging',
        type: 'number',
        subLabel: 'kWh',
        width: { xs: 4 },
        render: NumberField
      }
    ]
  }
];

const cargoRelatedConsumptionsConfig = {
  noon: data => noon(data),
  arrival: data => noon(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => departure(data),
  port_noon: data => port_noon(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default cargoRelatedConsumptionsConfig;
