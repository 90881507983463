import React, { useEffect, useState } from 'react';
import BasicModal from 'common/components/modals/BasicModal';
import { Button } from 'reactstrap';
import { dateToLocal, dateToUtc } from 'common/utils/dates';
import DateInput, { mode } from 'common/components/form/inputs/date';
import PropTypes from 'prop-types';
import moment from 'moment';

const ClonedJobDateModal = ({
  isOpen,
  dueDate,
  dueDateShowTime,
  toggle,
  onSubmit,
  submitting,
  container = '.job-profile'
}) => {
  const [date, setDate] = useState('');
  const [showTime, setShowTime] = useState(false);
  const [error, setError] = useState('');

  const submit = () => {
    if (!date) {
      setError('Due date is required');
      return;
    }

    onSubmit(dateToUtc(date.format('YYYY-MM-DD HH:mm:ss')), showTime);
  };

  useEffect(() => {
    if (!isOpen) {
      setDate('');
      setShowTime(false);
    } else {
      if (!moment.isMoment(dueDate)) {
        setDate(dateToLocal(dueDate));
      } else {
        setDate(dueDate);
      }
      setShowTime(dueDateShowTime);
    }
  }, [dueDate, dueDateShowTime, isOpen]);

  return (
    <BasicModal
      className="clone-job-modal"
      contentClassName="modal-content-box-shadow"
      container={container}
      backdropClassName="blurred-backdrop"
      isOpen={isOpen}
      header="Select due date"
      toggle={toggle}
      size="sm"
      body={
        <div className="d-flex align-items-center">
          <DateInput
            label="Due date"
            value={date}
            error={error}
            className={`m-0 text-primary`}
            showTime={showTime}
            activeMode={mode.single.date}
            hasTime={true}
            toggleTime={() => setShowTime(prev => !prev)}
            onChange={newDate => {
              setError('');
              setDate(newDate);
            }}
          />
        </div>
      }
      footer={
        <div className="d-flex align-items-center">
          <Button color="cancel" onClick={toggle}>
            CANCEL
          </Button>
          <Button disabled={submitting} onClick={submit} color="primary">
            CLONE
          </Button>
        </div>
      }
    />
  );
};

ClonedJobDateModal.propTypes = {
  isOpen: PropTypes.bool,
  dueDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  dueDateShowTime: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  container: PropTypes.string
};

export default ClonedJobDateModal;
