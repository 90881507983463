import TYPES from 'crew/store/evaluations/templates/types';
import TABLE_TYPES from 'common/components/table/store/types';

import { get, put, post } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';

export const getCrewEvaluationTemplates = params => async dispatch => {
  const { table, ...rest } = params;

  try {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_TEMPLATES.START, payload: { rest } });
    dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params: rest, table } });

    const response = await get('/crew-evaluation-templates', params);
    dispatch({
      type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
      payload: { data: response.data, table }
    });
    dispatch({ type: TYPES.GET_CREW_EVALUATION_TEMPLATES.SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_TEMPLATES.ERROR, payload: error });
    dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });

    throw error;
  }
};

export const getCrewEvaluationTemplate = params => async dispatch => {
  try {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_TEMPLATE.START, payload: { params } });

    const { id, ...rest } = params;
    const response = await get(`/crew-evaluation-templates/${id}`, rest);

    dispatch({ type: TYPES.GET_CREW_EVALUATION_TEMPLATE.SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: TYPES.GET_CREW_EVALUATION_TEMPLATE.ERROR, payload: error });

    throw error;
  }
};

export const createCrewEvaluationTemplate = params => async dispatch => {
  try {
    dispatch({ type: TYPES.CREATE_CREW_EVALUATION_TEMPLATE.START, payload: { params } });

    const response = await post('/crew-evaluation-templates', params);

    dispatch({ type: TYPES.CREATE_CREW_EVALUATION_TEMPLATE.SUCCESS, payload: response.data });
    dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));
  } catch (error) {
    dispatch({ type: TYPES.CREATE_CREW_EVALUATION_TEMPLATE.ERROR, payload: error });
    throw error;
  }
};

export const updateCrewEvaluationTemplate = params => async dispatch => {
  const { id, table, ...rest } = params;

  try {
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION_TEMPLATE.START, payload: { params } });
    const response = await put(`/crew-evaluation-templates/${id}`, { ...rest });

    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION_TEMPLATE.SUCCESS, payload: response.data });
    dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

    if (table) dispatch(updateTableRow({ data: response.data, table }));
  } catch (error) {
    dispatch({ type: TYPES.UPDATE_CREW_EVALUATION_TEMPLATE.ERROR, payload: error });
    dispatch(resetTableRowUpdate({ data: params, table }));
  }
};

export const cloneCrewEvaluationTemplate = params => async dispatch => {
  const { id, ...rest } = params;

  try {
    dispatch({ type: TYPES.CLONE_CREW_EVALUATION_TEMPLATE.START, payload: { params } });
    const response = await post(`/crew-evaluation-templates/${id}/clone`, { ...rest });

    dispatch({ type: TYPES.CLONE_CREW_EVALUATION_TEMPLATE.SUCCESS, payload: response.data });
    dispatch(successHandler({ title: 'Success!', message: 'Cloned template successfully' }));
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.CLONE_CREW_EVALUATION_TEMPLATE.ERROR, payload: error });
  }
};

export const archiveCrewEvaluationTemplate = params => dispatch => {
  dispatch({ type: TYPES.ARCHIVE_CREW_EVALUATION_TEMPLATE.START, payload: { params } });

  return put(`/crew-evaluation-templates/${params.id}/archive`)
    .then(response => {
      dispatch({ type: TYPES.ARCHIVE_CREW_EVALUATION_TEMPLATE.SUCCESS, payload: response.data });
      dispatch(
        successHandler({ title: 'Success!', message: 'Evaluation template has been archived' })
      );

      return response.data;
    })
    .catch(error =>
      dispatch({ type: TYPES.ARCHIVE_CREW_EVALUATION_TEMPLATE.ERROR, payload: error })
    );
};

export const unarchiveCrewEvaluationTemplate = params => dispatch => {
  dispatch({ type: TYPES.UNARCHIVE_CREW_EVALUATION_TEMPLATE.START, payload: { params } });

  return put(`/crew-evaluation-templates/${params.id}/unarchive`)
    .then(response => {
      dispatch({ type: TYPES.UNARCHIVE_CREW_EVALUATION_TEMPLATE.SUCCESS, payload: response.data });
      dispatch(
        successHandler({ title: 'Success!', message: 'Evaluation template has been unarchived' })
      );

      return response.data;
    })
    .catch(error =>
      dispatch({ type: TYPES.UNARCHIVE_CREW_EVALUATION_TEMPLATE.ERROR, payload: error })
    );
};

export const clearCrewEvaluationTemplate = params => async dispatch => {
  dispatch({ type: TYPES.CLEAR_CREW_EVALUATION_TEMPLATE, payload: { params } });
};
