import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import SvgRender from 'common/components/general/SvgRender';

import { Button } from 'reactstrap';
import { components } from 'react-select';
import { baseOptionStyles } from 'common/components/form/_selectStyles';

import filter from 'common/assets/svg/table/filter.svg';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const SelectColumnOperation = ({
  isCreate,
  isOperation,
  options,
  onSelect,
  value,
  customText,
  placeholder
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterTable = isCreate && !value && !isOpen;
  const addFilter = !isCreate && !value && !isOpen;

  const renderValue = () => {
    if (value) {
      if (value.symbol) {
        return value.symbol;
      } else if (value.filterLabel) {
        return value.filterLabel;
      } else {
        return value.label;
      }
    } else {
      return placeholder;
    }
  };

  return (
    <Dropdown
      isEmpty={filterTable || addFilter}
      isOperation={isOperation}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <Button
          color="link"
          className={`filter-dropdown--toggle fw-medium d-flex align-items-center justify-content-center text-truncate${
            addFilter ? ' p-0' : ''
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {filterTable || addFilter ? (
            <Fragment>
              <SvgRender src={filter} style={{ height: 16, width: 16 }} />
              <span className="ps-1 text-inactive fw-medium fs-12">
                {addFilter ? 'Add Filter' : `${customText ? customText : 'Filter table'}`}
              </span>
            </Fragment>
          ) : (
            renderValue()
          )}
          {!isOperation && <ChevronDown />}
        </Button>
      }
    >
      <Select
        autoFocus={true}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        controlShouldRenderValue={false}
        tabSelectsValue={false}
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
          Option
        }}
        menuIsOpen
        onChange={val => {
          onSelect(val);
          setIsOpen(false);
        }}
        getOptionValue={option => (option.id ? option.id : option.value)}
        getOptionLabel={option => (option.filterLabel ? option.filterLabel : option.label)}
        options={options}
        placeholder="Search"
        classNamePrefix={`react-select`}
        menuPortalTarget={document.body}
        styles={selectStyles}
        value={value}
      />
    </Dropdown>
  );
};

const selectStyles = {
  control: provided => ({
    ...provided,
    minWidth: 208,
    minHeight: 48,
    padding: 8,
    outline: 'none',
    border: 'none !important',
    borderRadius: 3,
    boxShadow: 'none !important',
    backgroundColor: '#F8F9FB',
    backgroundClip: 'content-box'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    order: 0
  }),
  valueContainer: provided => ({
    ...provided,
    overflow: 'none',
    order: 1
  }),
  menu: provided => ({
    ...provided,
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.1)',
    borderRadius: 0,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    marginTop: 0,
    marginBottom: 0
  }),
  input: provided => ({
    ...provided,
    color: variables.primary,
    marginTop: 0,
    marginBottom: 0,
    display: 'inline-flex'
  }),
  placeholder: (provided, _, styles = {}) => ({
    ...provided,
    fontWeight: variables.inputPlaceholderFontWeight,
    fontSize: variables.inputsFontSize,
    color: variables.inputPlaceholderColor,
    marginLeft: 4,

    ...styles
  }),
  option: baseOptionStyles,
  menuPortal: base => ({ ...base, zIndex: 9999 })
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.symbol ? <span className="operation-symbol">{props.data.symbol}&nbsp;</span> : ''}
      {children}
    </components.Option>
  );
};

const Menu = props => {
  return <div className="filter-dropdown--menu z-index-6 w-100p min-w-224" {...props} />;
};

const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
);

const Dropdown = ({ isOperation, isEmpty, children, isOpen, target, onClose }) => (
  <div
    className={`filter-dropdown position-relative${isOperation ? ' is-operation' : ''}${
      isEmpty ? ' is-empty' : ''
    }`}
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const DropdownIndicator = () => (
  <div style={{ paddingLeft: 10, paddingRight: 10, width: 16 }}>
    <svg width="15" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#CACACA"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.199838 11.199838l6.535456 6.535456" />
        <circle cx="6.554348" cy="6.554348" r="6.554348" />
      </g>
    </svg>
  </div>
);

const ChevronDown = () => (
  <svg
    className="ms-auto dropdown-arrow"
    width="10"
    height="10"
    viewBox="0 0 10 6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.8333333 0L5 3.76 1.1666667 0 0 1.12 5 6l5-4.88z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SelectColumnOperation;
