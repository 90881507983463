import React, { useState, useEffect } from 'react';

import ProfileImage from 'common/components/general/ProfileImage';
import rolodex from 'common/assets/svg/avatars/rolodex.svg';

import {
  selectActiveShiftId,
  selectPeriodType,
  selectHighlightedSeaman
} from 'crew/store/shifts/selectors';
import { useSelector } from 'react-redux';

import paths from 'routing/routes/_paths';

import { getSeamenWithoutShifts } from 'crew/components/shifts/top-filters/helpers';
import { Link } from 'react-router-dom';
import ContractPeriod from './ContractPeriod';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const CrewMemberCard = ({ crew, startDate, endDate }) => {
  const [hasShifts, setHasShifts] = useState(false);

  const highlightedSeaman = useSelector(selectHighlightedSeaman);
  const activeShiftId = useSelector(selectActiveShiftId);
  const isEditing = activeShiftId === crew.id;

  const periodType = useSelector(selectPeriodType);

  useEffect(() => {
    setHasShifts(getSeamenWithoutShifts([crew], startDate, endDate).length);
  }, [crew]);

  const url = `${paths.crew}/${crew.id}`;

  const isDaily = periodType === 'daily';

  return (
    <div
      className={`shifts-crew-member 
        ${highlightedSeaman && highlightedSeaman !== crew.id ? 'opacity-4' : ''}
      ${hasShifts ? 'shifts-crew-member--no-shifts' : ''} ${
        isEditing ? 'shifts-crew-member--edit' : ''
      } ${isDaily ? 'shifts-crew-member--daily' : ''}`}
    >
      <Link to={url}>
        <ProfileImage
          size={'xs'}
          image={crew.photo && crew.photo.url ? crew.photo.url : null}
          fallback={rolodex}
        />
      </Link>
      <div className={`d-flex flex-column px-1 w-100p ${isDaily ? 'ms-4' : 'align-items-center'}`}>
        <div
          className={`d-flex ${
            isDaily ? '' : 'mt-3 align-items-center'
          } flex-column w-100p overflow-hidden`}
        >
          <Link
            to={url}
            className={`d-inline-flex max-width-100p text-primary fs-12 lh-15 fw-medium link-medium ${
              isDaily ? '' : 'text-center'
            }`}
          >
            {crew.full_name}
          </Link>
          <Link
            to={url}
            className="d-inline-flex max-width-100p text-primary fs-10 lh-12 link-medium cmt-6 text-moody-blue fw-bold"
          >
            <TextWithTooltip>{crew.rank}</TextWithTooltip>
          </Link>
        </div>
        <div className={`${isDaily ? 'cmt-12' : 'mt-2 cmb-12'}`}>
          <Link to={url} className="link-medium">
            <ContractPeriod crew={crew} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CrewMemberCard;
