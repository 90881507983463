import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import star from 'common/assets/svg/common/star.svg';
import { dateToLocal } from 'common/utils/dates';

const Header = ({ audit }) => {
  return (
    <div className="evaluations-header">
      <div className="d-flex align-items-center fs-12 fw-medium text-violet">
        <SvgRender src={star} style={{ height: 14, width: 14 }} />
        <span className="ms-1">
          Audit / Inspection Evaluation{' '}
          <span className={`${audit?.evaluation?.created_at ? 'text-turquoise' : 'text-violet'}`}>
            |{' '}
            {audit?.evaluation?.created_at
              ? `Submitted on ${dateToLocal(audit?.evaluation?.created_at).format(
                  'DD/MM/YYYY, HH:mm'
                )}`
              : 'Draft'}
          </span>
        </span>
      </div>
    </div>
  );
};

Header.propTypes = {
  audit: PropTypes.shape({
    evaluation: PropTypes.shape({
      created_at: PropTypes.string.isRequired
    })
  }).isRequired
};

export default Header;
