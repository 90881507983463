import React from 'react';
import PropTypes from 'prop-types';
import SingleDatePicker from './SingleDatePicker';
import MultipleDatePicker from './MultipleDatePicker';

const DatePicker = ({
  event,
  changeEventValue,
  memoizedDebounce,
  isCreate,
  hasLabel,
  isMultipleEvents,
  index,
  disabled
}) => {
  return (
    <>
      {isMultipleEvents ? (
        <MultipleDatePicker
          dateIndex={index}
          hasLabel={hasLabel}
          event={event}
          changeEventValue={changeEventValue}
          disabled={disabled}
        />
      ) : (
        <SingleDatePicker
          isCreate={isCreate}
          hasLabel={hasLabel}
          event={event}
          changeEventValue={changeEventValue}
          memoizedDebounce={memoizedDebounce}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default DatePicker;

DatePicker.propTypes = {
  memoizedDebounce: PropTypes.func,
  changeEventValue: PropTypes.func,
  isCreate: PropTypes.bool,
  isMultipleEvents: PropTypes.bool,
  hasLabel: PropTypes.bool,
  index: PropTypes.number,
  event: PropTypes.object
};
