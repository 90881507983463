import PropTypes from 'prop-types';
import ActionButton from 'common/components/buttons/ActionButton';
import add from 'common/assets/svg/actions/add.svg';
import { useSelector } from 'react-redux';
import { selectModuleSyncButtonIsVisible } from 'events/store/events/selectors';

const AddAuditButton = ({ onClick }) => {
  const isSyncButtonVisible = useSelector(selectModuleSyncButtonIsVisible);

  return (
    <ActionButton
      className={`text-dark ${isSyncButtonVisible ? 'me-8' : ''}`}
      color="yellow"
      onClick={onClick}
      icon={add}
    >
      Add new audit / inspection
    </ActionButton>
  );
};

AddAuditButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default AddAuditButton;
