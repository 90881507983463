import React, { useCallback, useMemo } from 'react';

import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { File } from 'common/entities/files/FileTypes';
import { Mention, MentionsInput } from 'react-mentions';
import SvgRender from 'common/components/general/SvgRender';
import UploadFiles from 'common/components/form/inputs/upload-files';
import rankIcon from 'common/assets/svg/crew/rank.svg';

import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import _debounce from 'lodash/debounce';

import PropTypes from 'prop-types';

const MentionInput = ({
  wrapperClassName = '',
  className,
  placeholder,
  value,
  onChange,
  onFocus,
  onKeyDown,
  isExpanded,
  file,
  onRemoveFile
}) => {
  const getOptions = useCallback(async (search, cb) => {
    let mentionables;

    const params = {
      limit: 5,
      types: ['person', 'crew'],
      can_login: true
    };

    if (search) {
      mentionables = await getAsyncOptions(search, 'parties', params);
    } else {
      mentionables = await refetchAsyncOptions('parties', params);
    }

    const data = mentionables.map(mentionable => {
      const { id, full_name } = mentionable;

      return {
        id,
        display: `@${full_name}`,
        data: mentionable
      };
    });

    cb(data);
  }, []);

  const debouncedGetOptions = useMemo(() => _debounce(getOptions, 500), [getOptions]);

  const renderSuggestion = (entry, search, dis, index, focused) => {
    return (
      <div
        className={`mentions-input-suggestion ${
          focused ? 'mentions-input-suggestion-focused' : ''
        } d-flex align-items-center`}
      >
        <span className="fs-14">{entry.display.substring(1)}</span>
        {entry.data.type === 'crew' && (
          <div className="d-flex align-items-center text-violet fs-12 ms-1">
            <SvgRender src={rankIcon} style={{ width: 12, height: 12 }} />
            <span className="cms-4">{entry.data.details.rank.name}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <Container
      className={`invisible-input ${
        isExpanded ? 'is-expanded' : ''
      } ${wrapperClassName} d-flex flex-column`}
    >
      <MentionsInput
        allowSpaceInQuery={true}
        allowSuggestionsAboveCursor={true}
        forceSuggestionsAboveCursor={true}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        className={`mentions-input form-control form-field gray-scrollbar flex-1 ${
          className ? className : ''
        }`}
      >
        <Mention
          renderSuggestion={renderSuggestion}
          appendSpaceOnAdd
          markup="!#___id__^^^__display__!#$"
          data={debouncedGetOptions}
          className="display-mention"
        />
      </MentionsInput>
      {file ? (
        <div className="pt-1 cmt-4">
          <UploadFiles files={file} size="sm" singleUpload onChange={onRemoveFile} />
        </div>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  min-width: 0;

  &.is-expanded {
    min-height: ${variables.size104};
  }

  .mentions-input {
    resize: vertical !important;

    &__control {
      border-style: none;
    }

    &__input {
      color: inherit;
      font-weight: inherit;
      height: unset;
      overflow: auto !important;
      padding: 0;

      &::placeholder {
        color: ${variables.inputPlaceholderColor};
        font-weight: ${variables.inputPlaceholderFontWeight};
      }
    }

    &__highlighter {
      display: none;
    }
  }
`;

MentionInput.propTypes = {
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  isExpanded: PropTypes.bool,
  file: File,
  onRemoveFile: PropTypes.func
};

export default MentionInput;
