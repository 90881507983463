import React, { useEffect, useState, useMemo } from 'react';
import useRouter from 'use-react-router';

import PageLoader from 'common/components/general/PageLoader';
import Layout from 'manuals/manual-inner/layout';
import Content from './Content';

import * as vesselManualsActions from 'store/manuals/actions';
import * as manualsActions from 'manuals/store/actions';

import useVersionAcknowledge from 'manuals/useVersionAcknowledge';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectManualId,
  selectManualVersions,
  selectCurrentVersionId,
  selectCurrentVersion,
  hasChapters,
  selectTheFirstChapterUid
} from 'manuals/store/selectors';
import { parseQueryParams } from 'common/utils/urls';

const ManualInner = () => {
  const [manualLoading, setManualLoading] = useState(false);
  const [chaptersLoading, setChaptersLoading] = useState(false);

  const manualId = useSelector(selectManualId);

  const versions = useSelector(selectManualVersions);
  const chaptersLength = useSelector(hasChapters);
  const firstChapterUid = useSelector(selectTheFirstChapterUid);

  const currentVersionId = useSelector(selectCurrentVersionId);
  const currentVersion = useSelector(selectCurrentVersion);

  const [
    getManual,
    getManualChapters,
    getChapterPost,
    searchInManualChapters,
    compareVersionsChapterPosts,
    compareVersionsChapters,
    acknowledgeManualVersion,
    downloadManualAttachments,
    setCurrentVersion
  ] = useActions([
    vesselManualsActions.getManual,
    vesselManualsActions.getManualChapters,
    vesselManualsActions.getChapterPost,
    vesselManualsActions.searchInManualChapters,
    vesselManualsActions.compareVersionsChapterPosts,
    vesselManualsActions.compareVersionsChapters,
    vesselManualsActions.acknowledgeManualVersion,
    vesselManualsActions.downloadManualAttachments,
    manualsActions.setCurrentVersion
  ]);

  const { match, history } = useRouter();

  useVersionAcknowledge();

  const initManual = async () => {
    try {
      setManualLoading(true);
      await getManual({ id: match.params.id });

      setManualLoading(false);
    } catch (e) {
      setManualLoading(false);
    }
  };

  const initChapters = async () => {
    try {
      setChaptersLoading(true);
      const params = { id: match.params.id };

      params.version_id =
        currentVersion && currentVersion.status === 'working'
          ? undefined
          : currentVersion
          ? currentVersion.id
          : undefined; // When not an active version, the api will return the working version

      if (!chaptersLength) {
        await getManualChapters(params);
      }

      setChaptersLoading(false);
    } catch (e) {
      setChaptersLoading(false);
    }
  };

  useEffect(() => {
    if (manualId && firstChapterUid && !match.params.chapter_uid) {
      history.replace({
        pathname: `/manuals/${manualId}/chapter/${firstChapterUid}`,
        search: history.location.search
      });
    }
  }, [manualId, match.params.chapter_uid, firstChapterUid]);

  const findCurrentVersion = (historySearch, versions) => {
    const { version } = parseQueryParams(historySearch);
    let version_id = null;

    if (!version) {
      const activeVersion = versions.find(v => v.status === 'active');
      version_id = activeVersion ? activeVersion.id : null;
    } else {
      const activeVersion = versions.find(v => v.id === parseInt(version));
      version_id = activeVersion ? activeVersion.id : null;
    }

    return version_id;
  };

  useEffect(() => {
    if (parseInt(match.params.id, 10) !== manualId) {
      initManual();
    }
  }, []);

  useEffect(() => {
    if (currentVersion?.id) initChapters();
  }, [currentVersion?.id]);

  useEffect(() => {
    if (versions.length && !manualLoading && !currentVersionId)
      setCurrentVersion(findCurrentVersion(history.location.search, versions));
  }, [history.location.search, versions.length, manualLoading, currentVersionId]);

  const chapterActions = useMemo(() => {
    return {
      searchInManualChapters: searchInManualChapters
    };
  }, []);

  const postActions = useMemo(() => {
    return {
      onPostFetch: getChapterPost,
      onManualChaptersFetch: getManualChapters,
      onComparePosts: compareVersionsChapterPosts,
      onCompareChapters: compareVersionsChapters,
      onDownloadAttachments: downloadManualAttachments
    };
  }, []);

  const manualActions = useMemo(() => {
    return {
      onAcknowledgeManualVersion: acknowledgeManualVersion
    };
  }, []);

  return (
    <div className="manual-chapters">
      {manualId && parseInt(match.params.id, 10) === manualId && versions.length ? (
        <Layout
          chapterActions={chapterActions}
          manualActions={manualActions}
          postActions={postActions}
          chaptersLoading={chaptersLoading}
        >
          <Content manualId={manualId} postActions={postActions} />
        </Layout>
      ) : null}

      <PageLoader isLoading={manualLoading || chaptersLoading} />
    </div>
  );
};

export default ManualInner;
