import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

const useD3Events = (options = {}) => {
  const { isTimeseries = false, dateFormat = '%Y-%m-%d' } = options;

  const [groupGraphsAxesInfo, setGroupGraphsAxesInfo] = useState({});
  const [eventsID] = useState(uuid());

  const maxLeftOffset = useRef(0);
  const maxRightOffset = useRef(0);

  useEffect(() => {
    setGroupGraphsAxesInfo({});
  }, []);

  const getElementClass = xAxisValue => {
    const isNotNumber = typeof xAxisValue !== 'number';
    const isDate = xAxisValue instanceof Date;
    const normalizedValue = isNotNumber ? xAxisValue : xAxisValue.toString();

    const value = isDate
      ? moment(normalizedValue, dateFormat).unix()
      : normalizedValue.replaceAll(' ', '_').replaceAll(':', '_').replaceAll('.', '_');
    return `event-${eventsID}-${value}`;
  };

  const addToGroupGraphsAxesInfo = ({ graphUID, ...rest }) => {
    if (!groupGraphsAxesInfo[graphUID]) {
      setGroupGraphsAxesInfo({
        ...groupGraphsAxesInfo,
        [graphUID]: {
          graphUID,
          ...rest
        }
      });
    }
  };

  const setValueToGroupGraphsAxesInfo = useCallback(({ graphUID, ...rest }) => {
    setGroupGraphsAxesInfo(prev => {
      if (prev[graphUID]) {
        return {
          ...prev,
          [graphUID]: {
            ...prev[graphUID],
            ...rest
          }
        };
      }
      return prev;
    });
  }, []);

  return {
    eventsID,
    getElementClass,
    isTimeseries,
    dateFormat,
    groupGraphsAxesInfo,
    addToGroupGraphsAxesInfo,
    setValueToGroupGraphsAxesInfo,
    maxLeftOffset: maxLeftOffset.current,
    maxRightOffset: maxRightOffset.current,
    setMaxLeftOffset: value => {
      maxLeftOffset.current = value;
    },
    setMaxRightOffset: value => {
      maxRightOffset.current = value;
    }
  };
};

export default useD3Events;
