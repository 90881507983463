import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { getCrewMedicalCases } from './actions';

const INITIAL_STATE = {
  data: [],
  activeCase: null
};

const slice = createSlice({
  name: 'eventEmailConnection',
  initialState: INITIAL_STATE,
  reducers: {
    setActiveMedicalCase: (state, { payload }) => {
      state.activeCase = payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder

      .addCase(getCrewMedicalCases.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export const { setActiveMedicalCase } = slice.actions;

export default slice.reducer;
