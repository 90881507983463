import { Row, Col } from 'reactstrap';
import _isFuction from 'lodash/isFunction';

const PickerSelectionGrid = ({
  options = [],
  selectedOption,
  onChange,
  isOptionDisabled,
  isOptionHighlighted,
  isOptionSelected
}) => {
  const isSelected = option =>
    selectedOption == option.value || (_isFuction(isOptionSelected) && isOptionSelected(option));

  return (
    <Row className="date-input-selection-grid" noGutters>
      {options.map((option, index) => {
        const isCurrentOptionSelected = isSelected(option);

        const isFirstSelected =
          selectedOption == option.value && index <= options.findIndex(o => isSelected(o));

        const isLastSelected =
          _isFuction(isOptionSelected) &&
          isOptionSelected(option) &&
          index >= options.findIndex(o => isSelected(o));

        return (
          <Col
            xs={4}
            key={option.label}
            className={`d-flex align-items-center date-input-selection-grid__col ${
              _isFuction(isOptionDisabled) && isOptionDisabled(option) ? 'disabled' : ''
            } ${
              _isFuction(isOptionHighlighted) && isOptionHighlighted(option) ? 'highlighted' : ''
            } ${isCurrentOptionSelected ? 'selected' : ''} ${
              isFirstSelected ? 'selected-start' : ''
            } ${isLastSelected ? 'selected-end' : ''} `}
          >
            <div
              onClick={() => onChange(option.value)}
              className={`date-input-selection-grid__option d-flex align-items-center justify-content-center`}
            >
              {option.label}
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default PickerSelectionGrid;
