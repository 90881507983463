import variables from 'common/assets/scss/abstracts/_exports.module.scss';

export const defaultGraphOptions = {
  margin: 30,
  axisSpacing: 12
};

export const defaultYAxisOptions = {
  ticks: 5,
  color: variables.primary,
  label: '',
  tickPrefix: '',
  tickClass: '',
  titlePadding: 8
};

export const defaultXAxisOptions = {
  tickClass: '',
  ticks: null
};

export const defaultGraphStyles = {
  marginTop: 24,
  marginBottom: 24,
  marginLeft: 16,
  marginRight: 32
};

export const defaultGraphColors = [
  variables.primary,
  variables.dodgerBlue,
  variables.lavenderFloral,
  variables.darkTurquoise,
  variables.orangeWeb,
  variables.moodyBlue,
  variables.roseGold,
  '#33FFE9',
  '#36FF33',
  '#C7FF33',
  '#A233FF',
  '#4233FF',
  '#FF33E0',
  '#33ACFF',
  '#FF5733',
  '#FF9033',
  '#FFCA53',
  '#05A6B9',
  '#336EFF'
];

export const defaultArrowColors = [
  variables.moodyBlue,
  variables.primary,
  variables.violet,
  variables.dodgerBlue,
  variables.lavenderFloral,
  variables.darkTurquoise
];

export const defaultTimemarkerColors = ['#defffc', '#c6ffc5', '#fff19f', '#bde4ff'];
