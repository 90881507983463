import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import NumberInput from 'common/components/form/inputs/NumberInput';

import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';

import { strToNumber, padLeadingZeros } from 'common/utils/numbers';

const getCoordValue = (deg, min) => +(deg + min / 60).toFixed(5);

const getIntegerPart = val => Math.trunc(val);
const getMinute = (val, deg) => Math.round((val - deg) * 60);

const latDirectionOptions = [
  { name: 'North', id: 1 },
  { name: 'South', id: 2 }
];

const lonDirectionOptions = [
  { name: 'East', id: 1 },
  { name: 'West', id: 2 }
];

const CoordinateDirection = ({ type, setDirection, direction }) => {
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>{props.data.name.charAt(0)}</components.SingleValue>
  );

  return (
    <Select
      placeholder=""
      value={direction}
      className="mb-0"
      options={type.key === 'lat' ? latDirectionOptions : lonDirectionOptions}
      getOptionLabel={item => item.name}
      getOptionValue={item => item.id}
      components={{ SingleValue }}
      onChange={setDirection}
      invisible
    />
  );
};

const CoordinatePortsInput = ({ type, coordinates, selectField }) => {
  const [direction, setDirection] = useState(1);
  const [locked, setLocked] = useState(false);
  const [degrees, setDegrees] = useState('');
  const [minutes, setMinutes] = useState('');

  const setCoordinates = (lat, lon) => {
    let deg = getIntegerPart(type.key === 'lat' ? lat : lon);
    const min = getMinute(type.key === 'lat' ? lat : lon, deg);

    if (deg >= 0) {
      setDirection(1); // this is the id on the latDirectionOptions & lonDirectionOptions
    } else {
      setDirection(2);
      deg = deg * -1;
    }

    setDegrees(padLeadingZeros(deg, type.key === 'lat' ? 2 : 3));
    setMinutes(padLeadingZeros(Math.abs(min), 2));
  };

  useEffect(() => {
    if (coordinates && (coordinates.lat.value || coordinates.lon.value)) {
      setCoordinates(coordinates.lat.value, coordinates.lon.value);
    }
  }, []);

  useEffect(() => {
    if (type.key === 'lat' && (degrees == '90' || degrees == '-90')) {
      setMinutes('0');
      setLocked(true);
      return;
    } else if (type.key === 'lon' && (degrees == '180' || degrees == '-180')) {
      setMinutes('0');
      setLocked(true);
      return;
    }

    setLocked(false);
  }, [degrees]);

  useEffect(() => {
    if (degrees && minutes) {
      let updatedValue = getCoordValue(strToNumber(degrees), strToNumber(minutes));

      if (updatedValue < 0) {
        updatedValue = 0;
      } else if (type.key === 'lat' && updatedValue > 90) {
        updatedValue = 90;
      } else if (type.key !== 'lat' && updatedValue > 180) {
        updatedValue = 180;
      }

      if (direction === 2) {
        // If it's id: 2 it has to be negative
        updatedValue = updatedValue * -1;
      }

      selectField(type.key === 'lat' ? 'lat' : 'lon')(updatedValue);
    } else if (!degrees && !minutes) {
      selectField(type.key === 'lat' ? 'lat' : 'lon')(null);
    }
  }, [degrees, minutes, direction]);

  let isAllowed = ({ floatValue }) => {
    if (type.key === 'lat') {
      return floatValue ? floatValue <= 90 && floatValue >= 0 : true;
    } else if (type.key === 'lon') {
      return floatValue ? floatValue <= 180 && floatValue >= 0 : true;
    }
    return true;
  };

  const onChangeDegrees = e => {
    setDegrees(e.target.value);
  };

  const onChangeMinutes = e => {
    setMinutes(e.target.value);
  };

  return (
    <>
      <Row className="mt-1 align-items-center">
        <Col xs={4} className="fs-10 text-primary text-uppercase">
          {type.key === 'lat' ? 'Latitude' : 'Longitude'}
        </Col>
        <Col xs={4}></Col>
        <Col xs={4}></Col>
      </Row>
      <Row className="coordinate-ports align-items-center" noGutters>
        <Col xs={3}>
          <NumberInput
            onChange={onChangeDegrees}
            onBlur={() => setDegrees(padLeadingZeros(degrees, type.key === 'lat' ? 2 : 3))}
            placeholder="Value"
            className="mb-0"
            value={degrees}
            allowLeadingZeros
            decimalScale={0}
            maxLength={type.key === 'lat' ? 2 : 3}
            isAllowed={isAllowed}
            invisible
          />
        </Col>
        <Col xs={1}>
          <div className="coordinate-ports__seperator" />
        </Col>
        <Col xs={3} className="coordinate-ports--minutes-padding">
          <NumberInput
            onChange={onChangeMinutes}
            onBlur={() => setMinutes(padLeadingZeros(minutes, 2))}
            placeholder="Value"
            className="mb-0"
            value={minutes}
            allowLeadingZeros
            maxLength={2}
            error={null}
            disabled={locked}
            invisible
          />
        </Col>
        <Col xs={1}>
          <div className="coordinate-ports__mins text-violet">'</div>
        </Col>
        <Col xs={3} className="coordinates-direction-container">
          <CoordinateDirection direction={direction} setDirection={setDirection} type={type} />
        </Col>
      </Row>
    </>
  );
};

export default CoordinatePortsInput;
