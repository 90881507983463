import React from 'react';
import _get from 'lodash/get';
import Vessel from 'common/components/jobs/_base/components/Vessel';

const VesselChange = props => {
  const { properties } = props;

  const name = _get(properties, 'object.name', 'None');

  return (
    <div className="d-flex align-items-center">
      <Vessel name={name ? name : 'None'} />
    </div>
  );
};

export default VesselChange;
