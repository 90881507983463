import React from 'react';

import Input from 'common/components/form/inputs/Input';

const TextInput = ({ fields, changeField, fieldKey, placeholder = 'Add some text' }) => {
  return (
    <div>
      <Input
        value={fields[fieldKey].value}
        placeholder={placeholder}
        onChange={changeField(fieldKey)}
        className="cmb-12"
        {...fields[fieldKey]}
      />
    </div>
  );
};

export default TextInput;
