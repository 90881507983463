import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CircledButton from 'common/components/buttons/CircledButton';
import Connection from './connection';
import EmailConnectionForm from './connection/Form';

import { selectEmailConnectionData } from 'events/store/event-modules/email-connection/selectors';
import { selectIsTemplate } from 'events/store/events/selectors';

const Body = ({ isCreate }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [activeEmailConnectionId, setActiveEmailConnectionId] = useState(null);

  const data = useSelector(selectEmailConnectionData);
  const isTemplate = useSelector(selectIsTemplate);

  return !isTemplate ? (
    <>
      {data && data?.length
        ? data.map((emailConnection, i) => (
            <Connection
              key={i}
              email={emailConnection.email}
              isActive={emailConnection.id === activeEmailConnectionId}
              onEdit={() => setActiveEmailConnectionId(emailConnection.id)}
            />
          ))
        : null}

      {isAdding ? <EmailConnectionForm onClose={() => setIsAdding(false)} /> : null}

      {!isCreate && (
        <CircledButton
          type="add"
          className="text-primary fw-bold fs-12"
          label="Add more"
          svgStyle={{ width: 8, height: 8 }}
          disabled={isAdding || activeEmailConnectionId}
          onClick={() => setIsAdding(true)}
          style={{ width: 20, height: 20 }}
        />
      )}
    </>
  ) : null;
};

Body.propTypes = {
  isCreate: PropTypes.bool
};

export default Body;
