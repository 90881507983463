import { createSelector } from 'reselect';

export const selectCrewContracts = state => state.crew.contracts.contracts;
export const selectCrewPreviousContracts = state => state.crew.contracts.previousContracts;
export const selectCrewContractsLoading = state =>
  state.crew.contracts.loadingContracts || state.crew.contracts.loadingPreviousContracts;
export const selectCrewActiveContract = state => state.crew.contracts.activeContract;
export const selectCrewActivePreviousContract = state =>
  state.crew.contracts.activePreviousContract;

// For onboard
export const selectVesselContracts = state => state.crew.contracts?.vesselContracts;

export const selectCrewContractForEdit = state => state.crew.contracts?.contractForEdit;

export const selectActiveRotations = state => state.crew.contracts.activeRotations;
export const selectActiveRotationsIsFetching = createSelector(
  selectActiveRotations,
  activeRotations => activeRotations.isFetching
);
export const selectOnSignerRotations = createSelector(
  selectActiveRotations,
  activeRotations => activeRotations.onSignerRotations
);

export const selectOnSignerRotationsWithoutContract = createSelector(
  selectOnSignerRotations,
  onSignerRotations => onSignerRotations.filter(r => !r.on_signer_contract)
);

export const selectOffSignerRotations = createSelector(
  selectActiveRotations,
  activeRotations => activeRotations.offSignerRotations
);
// export const selectOffSignerRotationsForActiveVessel =
//   (offSignerRotations, activeVessel) =>
//   activeVessel ? offSignerRotations.filter(r => r.vessel?.id === activeVessel?.id) : []

export const selectValidActiveRotationsForContracts = createSelector(
  selectOnSignerRotationsWithoutContract,
  selectOffSignerRotations,
  (onSignerRotations = [], offSignerRotations = []) => [...onSignerRotations, ...offSignerRotations]
);

// If off_signer_rotations not empty, then can create contract.

// If on_signer_rotations not empty AND on signer contract not exists, then can create contract.

// If not exists in on_signer_rotations or off_signer_rotations then Show only Message.

// If on_signer_rotations empty AND off_signer_rotations empty AND he has a contract for on signer, then show Message.

export const selectIsEligibleToCreateNewContract = createSelector(
  selectOnSignerRotationsWithoutContract,
  selectOffSignerRotations,
  (onSignerWithoutContractRotation, offSignerRotation) =>
    onSignerWithoutContractRotation.length || offSignerRotation?.length
);

export const selectShowRotationMessage = createSelector(
  selectOffSignerRotations,
  selectOnSignerRotations,
  (offSignerRotation, onSignerRotation) => !offSignerRotation.length && !onSignerRotation.length
);
