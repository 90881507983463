import { useEffect, useState } from 'react';

import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';

import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectFieldVisibility } from 'captain-reports/store/selectors';

import * as reportActions from 'captain-reports/store/actions';

const DeliveredAtField = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [selectedDeliveredAt, setSelectedDeliveredAt] = useState(null);

  const deliveredAtOptions = useSelector(
    state => state.lists['captain-report-enums-delivered-at'].options
  );
  const deliveredAt = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const currentPortVisible = useSelector(state => selectFieldVisibility(state, 'current_port_id'));
  const currentPort = useValue({
    key: 'current_port_id',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue, toggleReportTemplateFieldVisibility] = useActions([
    reportActions.setValue,
    reportActions.toggleReportTemplateFieldVisibility
  ]);

  useEffect(() => {
    if (!deliveredAt && deliveredAtOptions?.length) {
      setValue({
        key: field.key,
        value: deliveredAtOptions[0].value,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [deliveredAtOptions?.length]);

  useEffect(() => {
    if (deliveredAt !== selectedDeliveredAt?.value && deliveredAtOptions?.length) {
      const selected = deliveredAtOptions.find(o => o.value === deliveredAt);

      setSelectedDeliveredAt(selected);
    }
  }, [deliveredAt, deliveredAtOptions?.length]);

  useEffect(() => {
    if (selectedDeliveredAt?.is_special === false && currentPort) {
      setValue({
        key: 'current_port_id',
        value: null,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [selectedDeliveredAt?.is_special]);

  useEffect(() => {
    if (selectedDeliveredAt?.is_special === false && currentPortVisible) {
      toggleReportTemplateFieldVisibility('current_port_id', false);
    } else if (selectedDeliveredAt?.is_special === true && !currentPortVisible) {
      toggleReportTemplateFieldVisibility('current_port_id', true);
    } else if (!selectedDeliveredAt && currentPortVisible) {
      toggleReportTemplateFieldVisibility('current_port_id', false);
    }
  }, [selectedDeliveredAt?.value]);

  return (
    <DynamicSelect
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default DeliveredAtField;
