const TYPES = {
  TOGGLE_GALLERY: 'TOGGLE_GALLERY',
  SET_GALLERY_FILES: 'SET_GALLERY_FILES',
  SET_SELECTED_FILE: 'SET_SELECTED_FILE',
  SET_ROTATE_DIRECTION: 'SET_ROTATE_DIRECTION'
} as const;

export type RotateDirectionType = 'top' | 'bottom' | 'left' | 'right';

export default TYPES;
