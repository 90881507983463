import React from 'react';
import ContentLoader from 'react-content-loader';
import CircledButton from 'common/components/buttons/CircledButton';

import { useSelector } from 'react-redux';
import {
  selectActiveChapterContentHasPost,
  cannotEditManual,
  isChapterPostFormOpen,
  selectIsActiveChapterChapterDeleted,
  selectIsParentChapterDeleted,
  selectActiveChapterUid
} from 'manuals/store/selectors';

const PostLoader = ({ isLoading, togglePostForm }) => {
  const isLocked = useSelector(cannotEditManual);
  const hasContent = useSelector(selectActiveChapterContentHasPost);
  const isFormOpen = useSelector(isChapterPostFormOpen);
  const activeUid = useSelector(selectActiveChapterUid);

  const isGettingDeleted = useSelector(selectIsActiveChapterChapterDeleted);
  const isParentChapterDeleted = useSelector(state =>
    selectIsParentChapterDeleted(state, activeUid)
  );

  if (isFormOpen) return null;
  else if (hasContent && !isLoading) return null;

  return (
    <div className="chapter-content__loader">
      <div className="chapter-content__loader-inner">
        <ContentLoader
          speed={2}
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          width="100%"
          height="100%"
          backgroundColor="#f8f9fb"
          foregroundColor={isLoading ? '#dadce4' : '#f8f9fb'}
          title={isLoading ? 'Loading post...' : ''}
          animate={isLoading}
        >
          <path
            d="M22.71 18.36h-5.6V.52a.52.52 0 00-.52-.52H1.29a.52.52 0 00-.52.52v19.13A4.35 4.35 0 005.11 24h13.78a4.35 4.35 0 004.34-4.35v-.76a.52.52 0 00-.52-.53zM5.11 23a3.31 3.31 0 01-3.3-3.31V1h14.26v17.36H8.94a.52.52 0 00-.52.53v.76a3.32 3.32 0 01-1 2.34A3.29 3.29 0 015.11 23zm17.08-3.31a3.31 3.31 0 01-3.3 3.31H7.94l.25-.23a4.33 4.33 0 001.27-3.08v-.24h12.73zm-9.42-3.3H5.11a.53.53 0 010-1.05h7.66a.53.53 0 010 1.05zM4.59 8.17a.52.52 0 01.52-.52h7.66a.53.53 0 010 1H5.11a.52.52 0 01-.52-.48zm0-3.82a.52.52 0 01.52-.52h7.66a.52.52 0 010 1H5.11a.52.52 0 01-.52-.48zm0 7.65a.52.52 0 01.52-.52h7.66a.52.52 0 010 1H5.11a.52.52 0 01-.52-.48z"
            fill="currentColor"
          />
        </ContentLoader>

        {!isLocked && !isLoading && !hasContent && !isGettingDeleted && !isParentChapterDeleted ? (
          <div className="chapter-content__loader__add d-flex flex-column">
            <div className="fs-18 fw-bold text-primary">New Post</div>
            <div className="mt-auto text-end">
              <CircledButton
                type="add"
                label={<strong className="text-primary">Create</strong>}
                onClick={() => togglePostForm(true)}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostLoader;
