import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import FormSaveActions from 'common/components/form/FormSaveActions';
import { useSelector } from 'react-redux';
import {
  selectEventLubOilAnalysisData,
  selectEventLubOilAnalysisIsWatingFromVessel
} from 'events/store/event-modules/lub-oil-analysis/selectors';
import { displayDate } from 'common/utils/dates';

const Preview = ({ setIsEditing }) => {
  const [isHovering, setIsHovering] = useState(false);

  const data = useSelector(selectEventLubOilAnalysisData);
  const isWatingFromVessel = useSelector(selectEventLubOilAnalysisIsWatingFromVessel);

  return (
    <ShadowBox
      className="cpy-12 px-2 d-flex align-items-center fs-14 fw-medium text-primary position-relative min-height-40 max-height-40"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      flatOnHover
    >
      <div className="text-violet pe-1">Samples Sent for Analysis:</div>
      {isWatingFromVessel ? (
        <div className="d-flex align-items-center">
          <SvgRender src={vesselIcon} style={{ width: 14, height: 14 }} className="me-1" /> Waiting
          from vessel
        </div>
      ) : (
        displayDate(data?.samples_delivery_date, { time: true })
      )}
      <div className="mx-1 text-violet">|</div>
      <div className="text-violet pe-1">Date of Result:</div>
      <div>{displayDate(data?.result_date, { time: true }) || '-'}</div>

      <FormSaveActions
        mode={'view'}
        isHovering={isHovering}
        onEdit={() => setIsEditing(true)}
        style={{ right: -12, top: 10 }}
      />
    </ShadowBox>
  );
};

Preview.propTypes = {
  setIsEditing: PropTypes.func
};

export default Preview;
