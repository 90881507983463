import React from 'react';
import PropTypes from 'prop-types';
import { Action } from 'common/entities/mga/MgaTypes';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import _get from 'lodash/get';
import { Col, Row } from 'reactstrap';

const AccountTable = ({
  actions,
  openingBalance,
  closingBalance,
  accountName,
  totals,
  contracts,
  contractTotals
}) => {
  const hasContracts = contracts?.length;
  const areContractTotalsEqual = strToNumber(totals) === strToNumber(contractTotals);

  return (
    <>
      <div className="cmt-14 ps-2">
        <Row className="d-flex align-items-center">
          <Col xs={7} className="fs-14 text-violet fw-bold">
            {accountName}
          </Col>
          {hasContracts ? (
            <Col xs={5} className="fs-14 text-violet ms-auto fw-bold">
              ACCORDING TO CONTRACTS
            </Col>
          ) : null}
        </Row>

        <div className="d-flex justify-content-between align-items-center cmt-12">
          <strong className="text-primary fs-10">OPENING BALANCE</strong>

          <div className="border-bottom border-primary cpb-2">
            <strong className="text-primary fs-12 border-bottom border-primary ps-4 pe-1 cpb-2">
              {numberToStr(openingBalance, 2, 2)}
            </strong>
          </div>
        </div>

        {actions.map(act => {
          const contractEquivalent = contracts?.find(
            contract => contract?.action?.id === act.action.id
          );

          const areAmountsEqual =
            strToNumber(contractEquivalent?.amount) === strToNumber(_get(act, 'total', 0));

          return (
            <Row key={act.id} className="d-flex align-items-center cmt-12">
              <Col xs={5} className="fs-12 text-dark">
                {_get(act, 'action.name')}
              </Col>

              {hasContracts ? (
                <Col
                  xs={5}
                  className={`fs-12 pe-1 text-end ${areAmountsEqual ? 'text-dark' : 'text-red'}`}
                >
                  {numberToStr(_get(contractEquivalent, 'amount', 0), 2, 2)}
                </Col>
              ) : null}

              <Col xs={hasContracts ? 2 : 7} className="fs-12 text-dark pe-2 text-end">
                {numberToStr(_get(act, 'total', 0), 2, 2)}
              </Col>
            </Row>
          );
        })}
      </div>

      <div className="ps-2 mt-2">
        <Row className="d-flex align-items-center cmt-12">
          <Col xs={5} className="text-primary fs-10 fw-bold">
            TOTALS
          </Col>

          {hasContracts ? (
            <Col xs={5} className="d-flex justify-content-end pe-0">
              <strong
                className={`fs-12 border-bottom border-top border-primary ps-4 pe-1 ${
                  areContractTotalsEqual ? 'text-primary' : 'text-red border-red'
                }`}
              >
                {numberToStr(contractTotals, 2, 2)}
              </strong>
            </Col>
          ) : null}

          <Col xs={hasContracts ? 2 : 7} className="d-flex justify-content-end">
            <strong className="text-primary fs-12 border-bottom border-top border-primary ps-4 pe-1">
              {numberToStr(totals, 2, 2)}
            </strong>
          </Col>
        </Row>

        <div className="d-flex justify-content-between align-items-center cmt-12">
          <strong className="text-primary fs-10">CLOSING BALANCE</strong>

          <div className="border-bottom border-primary cpb-2">
            <strong className="text-primary fs-12 border-bottom border-primary ps-4 pe-1 cpb-2">
              {numberToStr(closingBalance, 2, 2)}
            </strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTable;

AccountTable.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      account_id: PropTypes.number.isRequired,
      action: Action.isRequired,
      action_type_id: PropTypes.number,
      total: PropTypes.string || PropTypes.number
    })
  ),
  openingBalance: PropTypes.string || PropTypes.number,
  closingBalance: PropTypes.string || PropTypes.number,
  accountName: PropTypes.string.isRequired,
  totals: (PropTypes.string || PropTypes.number).isRequired,
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      action: Action.isRequired,
      amount: (PropTypes.string || PropTypes.number).isRequired
    })
  ),
  contractTotals: PropTypes.string || PropTypes.number
};
