import TYPES from './types';
import { deleteRequest, get, post, put } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addJobFormToState = params => dispatch => {
  dispatch({ type: TYPES.ADD_JOB_FORM_TO_STATE, payload: params });
};

export const removeJobFormFromState = (job_form_id, form_uid) => dispatch => {
  dispatch({ type: TYPES.REMOVE_JOB_FORM_FROM_STATE, payload: { job_form_id, form_uid } });
};

export const updateJobFormSubmission = (job_form_id, form_submission) => dispatch => {
  dispatch({ type: TYPES.UPDATE_JOB_FORM_SUBMISSION, payload: { job_form_id, form_submission } });
};

export const removeJobFormsFromState = () => dispatch => {
  dispatch({ type: TYPES.REMOVE_JOB_FORMS_FROM_STATE });
};

export const getJobForms = jobId => dispatch => {
  if (!jobId) return;

  dispatch({ type: TYPES.GET_JOB_FORMS.START });

  return get(`/jobs/${jobId}/forms`)
    .then(response => {
      dispatch({ type: TYPES.GET_JOB_FORMS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_JOB_FORMS.ERROR, payload: error }));
};

export const addFormToJob = params => dispatch => {
  const { jobId, form, ...rest } = params;

  if (!jobId) {
    dispatch(addJobFormToState(params));
    return;
  }

  dispatch({ type: TYPES.ADD_FORM_TO_JOB.START });

  return post(`/jobs/${jobId}/forms`, rest)
    .then(response => {
      dispatch({ type: TYPES.ADD_FORM_TO_JOB.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.ADD_FORM_TO_JOB.ERROR, payload: error });

      throw error;
    });
};

export const updateJobForm = params => dispatch => {
  const { jobId, jobFormId, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_JOB_FORM.START });

  return put(`/jobs/${jobId}/forms/${jobFormId}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_JOB_FORM.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_JOB_FORM.ERROR, payload: error });

      throw error;
    });
};

export const removeJobForm = params => dispatch => {
  const { jobId, jobFormId, form, ...rest } = params;

  if (!jobFormId) {
    dispatch(removeJobFormFromState(null, form?.uid));
    return;
  }

  return deleteRequest(`/jobs/${jobId}/forms/${jobFormId}`, rest)
    .then(response => {
      dispatch({ type: TYPES.REMOVE_JOB_FORM.SUCCESS, payload: jobFormId });

      dispatch(getJobForms(jobId));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.REMOVE_JOB_FORM.ERROR, payload: error });

      throw error;
    });
};

export const bulkRemoveJobForms = createAsyncThunk(
  TYPES.BULK_REMOVE_JOB_FORMS,
  async (params, { rejectWithValue, dispatch }) => {
    const { jobId } = params;

    if (!jobId) {
      dispatch(removeJobFormsFromState());
      return;
    }

    try {
      const res = await deleteRequest(`/jobs/${jobId}/forms`);

      dispatch(getJobForms(jobId));

      return res?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
