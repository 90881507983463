import React, { useState, useEffect } from 'react';
import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';

import moment from 'moment';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import * as reportActions from 'captain-reports/store/actions';

const StoppageDuration = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex,
  tooltip
}) => {
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [setValue] = useActions([reportActions.setValue]);

  const startDate = useValue({ key: 'local_timestamp' });

  const endDate = useValue({ key: 'stoppage_end_local_timestamp' });

  useEffect(() => {
    if (startDate && endDate) {
      let startMom = moment(startDate);
      let endMom = moment(endDate);

      const calculatedMinutes = endMom.diff(startMom, 'minutes');

      let calculatedDays = calculatedMinutes ? Math.trunc(calculatedMinutes / 1440) : '';
      let restminutes = calculatedMinutes ? calculatedMinutes - calculatedDays * 1440 : '';
      let calculatedHours = calculatedMinutes ? Math.trunc(restminutes / 60) : '';
      let restMinutes = calculatedMinutes
        ? calculatedMinutes - calculatedDays * 1440 - calculatedHours * 60
        : '';

      setDays(calculatedDays && calculatedDays >= 0 ? calculatedDays : null);
      setHours(calculatedHours && calculatedHours >= 0 ? calculatedHours : null);
      setMinutes(restMinutes && restMinutes >= 0 ? restMinutes : null);

      setValue({
        key: field.key,
        value: calculatedMinutes,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, [endDate, startDate]);

  return (
    <div className={` form-group  `}>
      <FieldLabel
        info={tooltip}
        className="form-label  fw-bold text-uppercase"
        label={field.label}
        subLabel={field.subLabel}
      />
      <span className="form-control border-0">
        {days ? `${days}d` : ''} {hours ? `${hours}h` : ''} {minutes ? `${minutes}m` : ''}
      </span>
    </div>
  );
};

export default StoppageDuration;
