import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import * as formActions from 'store/forms/actions';
import { useActions } from 'utils/hooks';
import useRouter from 'use-react-router';

const AttachmentsView = ({ fieldID, value }) => {
  const { match } = useRouter();
  const formSubmissionID = match.params?.form_id;

  const [downloadFormSubmissionAttachments] = useActions([
    formActions.downloadFormSubmissionAttachments
  ]);

  const data = useMemo(
    () => ({ id: fieldID, attachments_count: value?.length, attachments: value }),
    [fieldID]
  );

  const downloadAttachments = async data => {
    const res = downloadFormSubmissionAttachments({ formSubmissionID, fieldID: data.id });

    return res;
  };

  return (
    <Row>
      <Col>
        <DownloadAttachment
          isDisabled={false}
          data={data}
          downloadFiles={() => downloadAttachments(data)}
          hasAttachments={true}
          popupPlacement="right"
        />
      </Col>
    </Row>
  );
};

export default AttachmentsView;
