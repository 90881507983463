import { useCallback, useState } from 'react';
import MgaActionTypesSelector from 'common/components/selectors/MgaActionTypesSelector';
import moment from 'moment';

import ActionTableButtons from 'common/components/mga/components/actions/ActionTableButtons';
import ModularTable, { useModularTable } from 'common/components/ModularTable';
import { saveDownloadedFile } from 'common/utils/downloads';
import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import {
  selectRefetchTableTrigger,
  selectLatestPeriod
} from 'common/components/mga/store/selectors';
import { selectCurrenciesWithoutBase } from '@/common/components/mga/store/selectors-ts';
import { useDispatch, useSelector } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import {
  getMgaActions,
  getMgaAttachments,
  downloadMgaActionAttachments
} from 'common/components/mga/store/actions';
import { numberToStr } from 'common/utils/numbers';

import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Col } from 'reactstrap';
import { RESUBMITTED_MESSAGE } from './overview/helpers';
import CellWarningTooltip from 'common/components/table/main/CellWarningTooltip';
import BaseToCurrentCurrency from '@/common/components/mga/components/actions/setup-forex-rate-form/components/BaseToCurrentCurrency.tsx';

const ItemRow = ({ index, children }) => (
  <div className={`d-block mt-${index ? 1 : 0}`}>{children}</div>
);

const Actions = () => {
  const dispatch = useDispatch();
  const shouldRefetchTable = useSelector(selectRefetchTableTrigger);
  const actionTypes = useSelector(state => selectListOptionsFromStore(state, 'mga-action-types'));
  const latestPeriod = useSelector(selectLatestPeriod);
  const currencies = useSelector(selectCurrenciesWithoutBase);
  const [isLoading, setIsLoading] = useState(false);
  const [forexRates, setForexRates] = useState([]);

  const fetchData = useCallback(
    async params => {
      const filters = (_get(params, 'filters', []) || []).filter(f => f.name !== 'period_id');
      const typeFilter = filters.find(f => f.name === 'action_type_id' && f.value);

      filters.push({ name: 'period_id', operation: '=', value: latestPeriod.id });

      const res = await dispatch(
        getMgaActions({
          ...params,
          filters,
          includes: ['totals', 'forex_rates'],
          action_type: actionTypes.find(a => a.id === parseInt(typeFilter?.value))
        })
      );

      setForexRates(res?.forex_rates || []);
      return res;
    },
    [actionTypes, dispatch, latestPeriod.id]
  );

  const table = useModularTable({
    defaultRequestParams: { visible: false, paging: false, sorting: false, filters: true },
    requestTableListFrom: fetchData,
    label: 'mga_actions',
    urlRefetchLevel: 4,
    config: columns => ({ columns: _uniqBy(constructColumns(columns, [], 'mga_actions'), 'key') }),
    excludedFilters: ['period_id'],
    top: {
      filters: [
        {
          name: 'action_type_id',
          operation: '=',
          value: null
        }
      ]
    }
  });

  useUpdateEffect(() => {
    if (shouldRefetchTable) {
      table.refetchData();
    }
  }, [shouldRefetchTable]);

  useUpdateEffect(() => {
    table.refetchData();
  }, [latestPeriod?.id]);

  const selectedActionType = _get(table, 'topFilters', []).find(f => f.name === 'action_type_id');

  const downloadHandler = async (id, downloadFileName) => {
    try {
      setIsLoading(true);
      const response = await dispatch(downloadMgaActionAttachments({ id, type: 'mga_action' }));
      saveDownloadedFile(
        response,
        `${downloadFileName ? `${downloadFileName} - ${moment().format('DD-MM-YYYY')}` : ''}`
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const requestAttachments = async data => {
    try {
      setIsLoading(true);
      const res = await dispatch(getMgaAttachments({ action_id: data.id }));
      setIsLoading(false);
      return res;
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Col xs={5} className="mb-1 ps-0">
        <MgaActionTypesSelector
          filter={{
            value:
              selectedActionType && selectedActionType?.value ? [selectedActionType?.value] : null
          }}
          onChange={({ value }) =>
            table.setTopFilters(
              table.topFilters.map(f => (f.name === 'action_type_id' ? { ...f, value } : f))
            )
          }
          autoFocus={false}
          isMulti={false}
          placeholder="Select action"
        />
      </Col>

      <ModularTable
        loader
        hideTableSearch
        hideTopPagination
        hideTableFilters
        rows={{
          bank_account: data => (
            <TextWithTooltip>
              {data?.bank_account?.bank_name}
              <span className="text-violet cms-4">
                | {data?.bank_account?.iban} | {data?.bank_account?.beneficiary_full_name}
              </span>
            </TextWithTooltip>
          ),
          ...currencies.reduce(
            (acc, curr) => ({
              ...acc,
              [`local_amount_${curr?.id}`]: data => {
                const shouldShowValue = data?.currency_id === curr?.id;
                if (!shouldShowValue) return null;

                return data.is_resubmitted ? (
                  <CellWarningTooltip
                    warning={RESUBMITTED_MESSAGE}
                    color={'yellow'}
                    textColor={'table-color'}
                  >
                    {numberToStr(data.local_amount, 2, 2)}
                  </CellWarningTooltip>
                ) : (
                  numberToStr(data.local_amount, 2, 2)
                );
              }
            }),
            {}
          ),
          items: data => (
            <div>
              {(data?.items || []).map((item, i) => (
                <ItemRow index={i} key={item.id}>{`${
                  item.store?.code || item?.mga_action_item?.store?.code
                }. ${
                  item.store?.description || item?.mga_action_item?.store?.description
                }`}</ItemRow>
              ))}
            </div>
          ),
          action_type: data => (
            <div className="d-flex align-items-center overflow-hidden flex-nowrap w-100p">
              <TextWithTooltip>{data?.action_type?.name || '-'}</TextWithTooltip>
            </div>
          ),
          description: data => (
            <div className="d-flex align-items-center w-100p pe-1">
              <div className="flex-1 overflow-hidden">
                <TextWithTooltip>{data?.description || '-'}</TextWithTooltip>
              </div>

              <DownloadAttachment
                isDisabled={isLoading}
                data={{ attachments_count: data.attachments_count }}
                downloadFiles={() => downloadHandler(data?.id, data?.action_type?.name)}
                requestAttachments={() => requestAttachments(data)}
                hasAttachments={true}
                canAddAttachments={false}
                popupPlacement={'left'}
              />
            </div>
          ),
          items_price: data => (
            <div>
              {(data?.items || []).map((item, i) => (
                <ItemRow index={i} key={item.id}>
                  {numberToStr(item.price_per_unit, 2, 2)}
                </ItemRow>
              ))}
            </div>
          ),
          items_quantity: data => (
            <div>
              {(data?.items || []).map((item, i) => (
                <ItemRow index={i} key={item.id}>
                  {numberToStr(item.quantity, 2, 2)}
                </ItemRow>
              ))}
            </div>
          ),
          amount: data =>
            data.is_resubmitted ? (
              <CellWarningTooltip
                warning={RESUBMITTED_MESSAGE}
                color={'yellow'}
                textColor={'table-color'}
              >
                {numberToStr(data.amount, 2, 2)}
              </CellWarningTooltip>
            ) : (
              numberToStr(data.amount, 2, 2)
            ),
          actions: data => <ActionTableButtons action={data} />
        }}
        {...table}
      />

      <div className="d-flex flex-column flex-1 align-items-end cmt-12">
        <div>
          {forexRates.map(forex => (
            <BaseToCurrentCurrency
              key={forex?.id}
              baseCurrencyLabel={forex?.base_currency?.label}
              currentCurrencyLabel={forex?.currency?.label}
              rate={forex?.rate}
              currenciesClassName="fw-medium"
              period={{ from: forex?.from_date, to: forex?.to_date }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Actions;
