import TextField from 'captain-reports/templates/inputs/TextField';

const cargos = () => [
  {
    fields: [
      {
        label: 'Cargo Grade',
        key: 'cargo_grade_id',
        sendOnlyValue: true,
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Ship Quantities',
        key: 'cargo_ship_qty',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Action Commenced',
        key: 'action_comm',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Shore Quantities',
        key: 'cargo_shore_qty',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Action Completed',
        key: 'action_comp',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'BL Quantities',
        key: 'cargo_bl_qty',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Total Anchor Time',
        key: 'total_anchor_time',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'BL Signed by Master',
        key: 'bl_signed',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'BL Onboard',
        key: 'bl_onboard',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Shipper',
        key: 'shipper',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Consignee',
        key: 'consignee',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Bending Moments',
        key: 'bending_moments',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'BM Frame',
        key: 'bm_frame',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Protest Received',
        key: 'protest_received',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Protest Issued',
        key: 'protest_issued',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Cargo Documents Onboard',
        key: 'cargo_docs',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Number of cargo documents',
        key: 'cargo_docs_no',
        width: { xs: 4 },
        render: TextField
      }
    ]
  },
  {
    fields: [
      {
        label: 'Ship Stop',
        key: 'ship_stop',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Shore Stop',
        key: 'shore_stop',
        width: { xs: 4 },
        render: TextField
      },
      {
        label: 'Dead Freight',
        key: 'dead_freight',
        width: { xs: 4 },
        render: TextField
      }
    ]
  }
];

const cargosConfig = {
  noon: () => [],
  arrival: () => [],
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => [], //data => cargos(data),
  port_noon: () => [],
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default cargosConfig;
