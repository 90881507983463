import React from 'react';
import relates from 'common/assets/svg/jobs/relates.svg';
import blocks from 'common/assets/svg/jobs/blocks.svg';
import blockedBy from 'common/assets/svg/jobs/blocked_by.svg';
import SvgRender from 'common/components/general/SvgRender';

const Relation = ({ type, showText = true }) => {
  const getIcon = type => {
    if (type === 'blocks') {
      return blocks;
    } else if (type === 'blocked_by') {
      return blockedBy;
    } else return relates;
  };

  const getClass = type => {
    if (type === 'blocks') {
      return 'text-red';
    } else if (type === 'blocked_by') {
      return 'text-warning';
    } else return 'text-moody-blue';
  };

  const getText = type => {
    if (type === 'blocks') {
      return 'Blocks';
    } else if (type === 'blocked_by') {
      return 'Blocked by';
    } else return 'Relates to';
  };

  return (
    <div className="display-relation d-flex align-items-center h-100p">
      <SvgRender className={getClass(type)} src={getIcon(type)} style={{ width: 16 }} />
      {showText && <div className="text-primary fs-12 fw-medium ms-2">{getText(type)}</div>}
    </div>
  );
};

export default Relation;
