import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const AlarmItem = ({ className = '', item, renderAlert }) => {
  return (
    <div className={`text-coral fs-12 d-flex align-items-center ${className}`}>
      {renderAlert(item)}
    </div>
  );
};

AlarmItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  renderAlert: PropTypes.func.isRequired
};

const AlertsContainer = ({
  alerts = [],
  color = 'red',
  className = '',
  alertItemClassName = '',
  renderAlert = () => {}
}) => {
  return (
    <Alert
      color={color}
      className={`border-0 fs-12 d-flex align-items-center ${className} rounded`}
    >
      <div className="d-flex flex-column w-100p">
        {alerts.map((e, i) => (
          <AlarmItem
            key={i}
            className={`${i > 0 ? 'mt-1' : ''} ${alertItemClassName}`}
            item={e}
            renderAlert={renderAlert}
          />
        ))}
      </div>
    </Alert>
  );
};

AlertsContainer.propTypes = {
  alerts: PropTypes.array.isRequired,
  color: PropTypes.string, // bootstrap theme color
  className: PropTypes.string,
  alertItemClassName: PropTypes.string,
  renderAlert: PropTypes.func
};

export default AlertsContainer;
