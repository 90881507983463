import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCrewOverviewWarnings } from '../../store/selectors';
import _get from 'lodash/get';
import CellWarningTooltip from 'common/components/table/main/CellWarningTooltip';
import { numberToStr } from 'common/utils/numbers';
import { RESUBMITTED_MESSAGE } from './helpers';

const GroupCellValue = ({ data, groupKey, columnKey, rowIndex }) => {
  const warning = useSelector(state => selectCrewOverviewWarnings(state, rowIndex, columnKey));
  const isResubmitted = _get(
    data,
    `resubmitted_${groupKey}.${columnKey.replace(`${groupKey}.`, '')}`
  );
  const value = _get(data, columnKey);

  const cellWarning = useMemo(() => {
    if (warning && isResubmitted) {
      return (
        `${RESUBMITTED_MESSAGE}<hr style="border-bottom: 1px solid white; margin: 8px 0;" />` +
        warning
      );
    } else if (warning) {
      return warning;
    } else if (isResubmitted) {
      return RESUBMITTED_MESSAGE;
    }

    return null;
  }, [warning, isResubmitted]);

  return cellWarning ? (
    <CellWarningTooltip
      warning={cellWarning}
      color={isResubmitted ? 'yellow' : 'red'}
      textColor={isResubmitted && !warning ? 'table-color' : 'red'}
    >
      {numberToStr(value, 2, 2)}
    </CellWarningTooltip>
  ) : (
    numberToStr(value, 2, 2)
  );
};

GroupCellValue.propTypes = {
  data: PropTypes.object.isRequired,
  groupKey: PropTypes.string.isRequired,
  columnKey: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default GroupCellValue;
