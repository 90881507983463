import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { formatDate, formatTime } from 'cleave-zen';

const DateTimeInput = ({
  value,
  onChange,
  hasTime,
  timePlaceholder = 'hh:mm',
  disabled,
  focused,
  readonly
}) => {
  const [time, setTime] = useState('');

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [dayIsFocused, setDayIsFocused] = useState(false);
  const [monthIsFocused, setMonthIsFocused] = useState(false);
  const [yearIsFocused, setYearIsFocused] = useState(false);

  const [isInvalid, setIsInvalid] = useState(false);

  const [fillTime, setFillTime] = useState(false);

  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  const timeRef = useRef(null);

  useEffect(() => {
    if (value) {
      const vday = moment(value).format('DD');
      const vmonth = moment(value).format('MM');
      const vyear = moment(value).format('YYYY');

      if (vday !== day) setDay(vday ? vday : '');
      if (vmonth !== month) setMonth(vmonth ? vmonth : '');
      if (vyear !== year) setYear(vyear ? vyear : '');

      if (hasTime) {
        const t = moment(value).format('HH:mm');

        if (!time && !fillTime) {
          setTime(t);
          setFillTime(true);
        }
      }
    } else if (!value && (day || month || year || time)) {
      if (day) setDay('');
      if (month) setMonth('');
      if (year) setYear('');
      if (hasTime && time) setTime('');
    }
  }, [value]);

  useUpdateEffect(() => {
    if (
      dayIsFocused &&
      moment(
        `${day}-${month ? month : 12}-${year ? year : moment().year()}`,
        'DD-MM-YYYY',
        true
      ).isValid()
    ) {
      monthRef.current.focus();
    }
  }, [day]);

  useUpdateEffect(() => {
    if (
      monthIsFocused &&
      moment(
        `${day ? day : '01'}-${month}-${year ? year : moment().year()}`,
        'DD-MM-YYYY',
        true
      ).isValid()
    ) {
      yearRef.current.focus();
    }
  }, [month]);

  useUpdateEffect(() => {
    if (
      yearIsFocused &&
      hasTime &&
      moment(`${day ? day : '01'}-${month ? month : 12}-${year}`, 'DD-MM-YYYY', true).isValid()
    ) {
      timeRef.current.focus();
    }
  }, [year]);

  const onInputChange = updated => {
    const d = updated.day;
    const m = updated.month;
    const y = updated.year;
    const t = updated.time;

    if (hasTime) {
      if (value && moment(value).format('DD-MM-YYYY HH:mm') === `${d}-${m}-${y} ${t}`) {
        return;
      }

      if (moment(`${d}-${m}-${y}`, 'DD-MM-YYYY', true).isValid() && `${y}-${m}-${d}` !== value) {
        onChange(moment(`${d}-${m}-${y} ${t}`, 'DD-MM-YYYY HH:mm'));
        setIsInvalid(false);
      } else if (d && m && y && moment(t, 'HH:mm', true).isValid()) {
        setIsInvalid(true);
      }
    } else {
      if (value && moment(value).format('DD-MM-YYYY') === `${d}-${m}-${y}`) {
        return;
      }

      if (moment(`${d}-${m}-${y}`, 'DD-MM-YYYY', true).isValid()) {
        onChange(moment(`${d}-${m}-${y}`, 'DD-MM-YYYY'));
        setIsInvalid(false);
      } else if (d && m && y) {
        setIsInvalid(true);
      }
    }

    if (!d && !m && !y && !t && value) {
      // Reset value
      onChange(null);
    }
  };

  return (
    <div
      className={`datetime-inputs d-flex align-items-center${isInvalid ? ' invalid-date' : ''}${
        !value ? ' empty' : ''
      }${focused ? ' focused' : ''} ${readonly ? 'pointer-events-none' : ''}`}
    >
      <input
        className="form-control form-field form-field__day"
        ref={dayRef}
        value={day}
        placeholder={'dd'}
        onChange={e => {
          const dayValue = formatDate(e.target.value, { datePattern: ['d'] });
          setDay(dayValue);
          onInputChange({ day: dayValue, month, year, time });
          setFillTime(false);
        }}
        onFocus={() => setDayIsFocused(true)}
        onBlur={() => {
          setDayIsFocused(false);
          const d = parseInt(day, 10);

          if (d === 1 || d === 2 || d === 3) {
            setDay(`0${d}`);
          }
        }}
        disabled={disabled}
        data-cy="date-input--day"
      ></input>

      <div className="form-field__separator">-</div>

      <input
        className="form-control form-field form-field__month"
        ref={monthRef}
        value={month}
        placeholder={'mm'}
        onChange={e => {
          const monthValue = formatDate(e.target.value, { datePattern: ['m'] });
          setMonth(monthValue);
          onInputChange({ day, month: monthValue, year, time });
          setFillTime(false);
        }}
        onFocus={() => setMonthIsFocused(true)}
        onBlur={() => {
          setMonthIsFocused(false);

          if (parseInt(month, 10) === 1) {
            setMonth('01');
          }
        }}
        disabled={disabled}
        data-cy="date-input--month"
      ></input>

      <div className="form-field__separator">-</div>

      <input
        className="form-control form-field form-field__year"
        ref={yearRef}
        value={year}
        placeholder={'yyyy'}
        onChange={e => {
          const yearValue = formatDate(e.target.value, { datePattern: ['Y'] });
          setYear(yearValue);
          onInputChange({ day, month, year: yearValue, time });
          setFillTime(false);
        }}
        onFocus={() => setYearIsFocused(true)}
        onBlur={() => setYearIsFocused(false)}
        disabled={disabled}
        data-cy="date-input--year"
      ></input>

      {hasTime && (
        <input
          className="form-control form-field form-field__time"
          ref={timeRef}
          value={time}
          placeholder={timePlaceholder}
          onChange={e => {
            const timeValue = formatTime(e.target.value, {
              timePattern: ['h', 'm'],
              delimiterLazyShow: true
            });
            setTime(timeValue);
            onInputChange({ day, month, year, time: timeValue });
          }}
          disabled={disabled}
          data-cy="date-input--hour"
        ></input>
      )}
    </div>
  );
};

export default DateTimeInput;
