import { stringifyObj } from 'utils/urls';
import paths from 'routing/routes/_paths';
import { OnboardSetupFilter } from '@/common/types/onboard-setup.ts';
import config from '@/common/components/shared-table-configs/index.ts';

interface ItemType extends OnboardSetupFilter {
  type: keyof typeof config;
}

const parsePaths = {
  form_submissions: paths.forms,
  crew_evaluation_obligations: paths.crew_evaluations,
  jobs_list: paths.regular_jobs,
  jobs_flag_extensions_dispensations_list: paths.flag_extensions_dispensations_jobs,
  reports_findings: paths.findings,
  captain_reports_running_hours: paths.pms_running_hours,
  inventory_spare_parts: paths.pms_inventory,
  inventory_items: paths.purchasing_inventory
};

export const createLink = (item: ItemType) => {
  const { filters, visible, sorting, page_size, id } = item;
  const obj: Partial<Pick<OnboardSetupFilter, 'filters' | 'visible' | 'sorting' | 'page_size'>> =
    {};

  if (filters) obj.filters = filters;
  if (visible) obj.visible = visible;
  if (sorting) obj.sorting = sorting;
  if (page_size) obj.page_size = page_size;

  const pathname =
    paths[item.type as keyof typeof paths] || parsePaths[item.type as keyof typeof parsePaths];

  return {
    pathname,
    search: `?${stringifyObj(obj)}&searchId=${id}`
  };
};
