import PendingAcknowledge from 'manuals/components/PendingAcknowledge';

const VersionActions = ({ manualId, version }) => {
  return (
    <div className="d-flex justify-content-end flex-1 flex-nowrape">
      <PendingAcknowledge version={version} />
    </div>
  );
};

export default VersionActions;
