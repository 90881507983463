import React from 'react';

import ShadowBox from 'common/components/general/ShadowBox';

import { Row, Col } from 'reactstrap';
import HeaderIcon from 'captain-reports/templates/components/HeaderIcon';

import { Header } from 'captain-reports/templates/layout/_helpers';
import runningHours from 'captain-reports/assets/svg/running-hours-clock.svg';
import MainInputs from './MainInputs';
import SystemValues from './SystemValues';

const SystemRunningHours = () => {
  return (
    <div>
      <Row>
        <Col xs={7}>
          <ShadowBox className="pt-2 px-2 mb-5" flat>
            <Row className="g-0 ">
              <Col xs={12} className="pe-2 pe-hd-3">
                <div>
                  <Header
                    header={
                      <HeaderIcon
                        icon={runningHours}
                        svgStyle={{ width: 12, height: 12 }}
                        label="RUNNING HOURS"
                      />
                    }
                  />
                  <MainInputs />
                  <SystemValues />
                </div>
              </Col>
            </Row>
          </ShadowBox>
        </Col>
      </Row>
    </div>
  );
};

export default SystemRunningHours;
