import TYPES from './types';

export const INITIAL_STATE = {
  profile: null,
  profileIsLoading: false
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PERSON.START:
      return {
        ...state,
        profileIsLoading: true
      };

    case TYPES.GET_PERSON.ERROR:
      return {
        ...state,
        profile: null,
        profileIsLoading: false
      };

    case TYPES.GET_PERSON.SUCCESS:
      return {
        ...state,
        profile: payload,
        profileIsLoading: false
      };

    case TYPES.RESET_PROFILE:
      return {
        ...state,
        profile: null,
        typeModal: false
      };

    case TYPES.GET_PERSON_DOCUMENTS.SUCCESS:
      if (state.profile) {
        return {
          ...state,
          profile: {
            ...state.profile,
            documents: payload.documents
          }
        };
      }

      return state;

    default:
      return state;
  }
};

export default reducer;
