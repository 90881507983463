import { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer, { DrawerBody, DrawerFooter, DrawerHeader } from 'common/components/drawer';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import FormsSelector from 'common/components/selectors/FormsSelector';
import filterIcon from 'common/assets/svg/common/filter.svg';
import SvgRender from 'common/components/general/SvgRender';
import DateInput, { mode } from 'common/components/form/inputs/date';
import ShadowBox from 'common/components/general/ShadowBox';
import { stringField, optionField } from 'common/utils/form/fieldTypes';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import Information from 'common/components/general/Information';
import { MultiValueLabel, Option } from 'common/components/selectors/jobs/components/Styles';
import { updateEventAuditFinding, getEventAudits } from 'events/store/event-modules/audits/actions';

import { useForm, useFormState } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { selectEventId, selectEventVessel } from 'events/store/events/selectors';

const config = {
  form: optionField(),
  date: stringField(),
  finding: optionField({ required: true })
};

const LinkingForm = ({ drawer, auditId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formState, collectValues, resetForm, hasErrors } = useForm(config, {});
  const { fields, selectField } = useFormState(formState);
  const dispatch = useDispatch();
  const eventId = useSelector(selectEventId);
  const eventVessel = useSelector(selectEventVessel);

  const onSave = async () => {
    try {
      const values = collectValues();
      if (hasErrors || !auditId || !eventId) return;

      setIsLoading(true);
      const { finding } = values;

      await dispatch(
        updateEventAuditFinding({
          event_id: eventId,
          audit_id: auditId,
          finding_ids: finding?.id ? [finding.id] : []
        })
      );

      await dispatch(getEventAudits({ event_id: eventId })).unwrap();

      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const onClose = () => {
    resetForm();
    drawer.close();
  };

  const findingParams = useMemo(
    () => ({
      has_origin_audit: false,
      vessel_id: eventVessel?.id,
      form_uid: fields.form?.value?.uid,
      form_dates: {
        to: fields.date.value?.to?.format('YYYY-MM-DD'),
        from: fields.date.value?.from?.format('YYYY-MM-DD')
      }
    }),
    [eventVessel?.id, fields.date.value?.from, fields.date.value?.to, fields.form?.value?.uid]
  );

  return (
    <Drawer {...drawer}>
      <DrawerHeader className="d-flex align-items-center justify-content-between">
        Link an existing finding
      </DrawerHeader>

      <DrawerBody className="px-9 pt-3 overflow-hidden">
        <ShadowBox className="d-flex flex-column cpt-4 cpb-12 cpx-12" flat>
          <div className="w-100p border-bottom border-platinum pb-1 cmb-12 fs-12 text-violet d-flex align-items-center">
            <SvgRender src={filterIcon} style={{ width: 14, height: 14 }} className="m-1" />
            <div>Filter Selection</div>
          </div>

          <FormsSelector
            isMulti={false}
            label="FORM"
            placeholder="Select form"
            onChange={(_, selected) => selectField('form')(selected)}
            {...fields.form}
          />

          <DateInput
            activeMode={mode.range.date}
            label="FORM DATE"
            className="mb-0"
            onChange={selectField('date')}
            {...fields.date}
          />
        </ShadowBox>

        <div className="d-flex flex-column mt-2">
          <div className="d-flex align-items-center cmb-6">
            <div className="form-label fs-10 me-1 mb-0">FINDING</div>
            <Information
              svgStyle={{ width: 10, height: 10 }}
              tooltipClassname="min-width-fit max-width-255"
              svgClassname="text-primary"
              message={
                'Here we show your filtered results and all reported vessel findings. We do not show findings already linked in other events.'
              }
            />
          </div>

          <AsyncSelector
            onChange={selectField('finding')}
            type="findings"
            placeholder="Select finding"
            components={{ Option, MultiValueLabel }}
            defaultOptionsTriggerChange={`${drawer.isOpen}_${findingParams?.form_uid}_${findingParams?.form_dates?.from}_${findingParams?.form_dates?.to}_${eventVessel?.id}`}
            isMulti={false}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            listParams={findingParams}
            isClearable={false}
            {...fields.finding}
          />
        </div>
      </DrawerBody>

      <DrawerFooter className="d-flex align-items-center justify-content-end px-4">
        <Button color="cancel" onClick={onClose}>
          CLOSE
        </Button>

        <Button color="primary" disabled={isLoading || hasErrors} onClick={onSave}>
          LINK
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default LinkingForm;

LinkingForm.propTypes = {
  drawer: DrawerState.isRequired,
  auditId: PropTypes.number.isRequired
};
