import React, { useEffect, useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const ContractSelector = ({
  crewId,
  onChange,
  isDefaultSelectedFirst = false,
  value,
  isLoading,
  className = ''
}) => {
  const [contracts, setContracts] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    getContracts();
    setMounted(true);
  }, []);

  const getContracts = async () => {
    let results = await getInitialAsyncValues('crew-contracts', 0, false, {
      crew_member_id: crewId
    });
    if (results) {
      setContracts(results);
    }
  };

  useEffect(() => {
    if (!value && !isLoading && mounted && contracts.length) {
      onChange(contracts[isDefaultSelectedFirst ? 0 : contracts.length - 1]);
    }
  }, [value, isLoading, contracts]);

  return (
    <Select
      value={value}
      className={className}
      onChange={(_, o) => onChange(o)}
      isAsync
      defaultOptions={contracts}
      loadOptions={search => getAsyncOptions(search, 'crew-contracts', { crew_member_id: crewId })}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      dynamicWidth
    />
  );
};

export default ContractSelector;
