import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const styleDefinitions = [
  { type: 'attr', key: 'stroke', style: 'polygonBorderColor', defaultValue: 'none' },
  { type: 'attr', key: 'opacity', style: 'polygonOpacity', defaultValue: 0.33 },
  { type: 'attr', key: 'stroke-width', style: 'polygonBorderWidth', defaultValue: 0 },
  { type: 'attr', key: 'fill', style: 'polygonBackgroundColor', defaultValue: variables.primary }
];

const renderPlot = ({ plotID, plot, getPlotStyle, xFn, yFn, element, plotIndex }) => {
  const strData = plot.data.map(d => `${xFn(d)},${yFn(d)}`).join(' ');
  const existingElement = element.select(`.${plotID}`);

  const areaElement = (!existingElement.empty()
    ? existingElement
    : element.append('custom').classed(`polygon ${plotID} plot-${plotIndex}`, true)
  )
    .attr('points', strData)
    .attr('plot-index', plotIndex);

  styleDefinitions.forEach(styleDefinition => {
    areaElement[styleDefinition.type](
      styleDefinition.key,
      getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
    );
  });
};

const area = {
  renderPlot
};

export default area;
