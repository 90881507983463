import React from 'react';
import PropTypes from 'prop-types';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import DepartmentRolesSelector from 'common/components/selectors/DepartmentRolesSelector';
import { Button } from 'reactstrap';

const ResponsibleSelector = ({ isDepartment, selectField, responsible }) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between cmb-2">
        <strong className="text-primary fs-10">RESPONSIBLE TO SUBMIT</strong>

        <div className="d-flex align-items-center">
          <Button
            color={isDepartment ? 'primary' : 'link'}
            size="sm"
            className={`height-16 lh-12 fs-10 py-0 cms-4 fw-medium ${
              !isDepartment ? 'border rounded text-inactive' : ''
            }`}
            onClick={() => selectField('responsible_type')('department')}
          >
            Department
          </Button>

          <Button
            color={!isDepartment ? 'primary' : 'link'}
            size="sm"
            className={`height-16 lh-12 fs-10 py-0 cms-4 fw-medium ${
              isDepartment ? 'border rounded text-inactive' : ''
            }`}
            onClick={() => selectField('responsible_type')('department_role')}
          >
            Role
          </Button>
        </div>
      </div>

      {isDepartment ? (
        <DepartmentSelector
          isMulti={false}
          placeholder="Select department"
          onChange={selectField('responsible')}
          {...responsible}
        />
      ) : (
        <DepartmentRolesSelector
          isMulti={false}
          placeholder="Select role"
          onChange={({ value }) => selectField('responsible')(value)}
          filter={{ value: responsible.value }}
          error={responsible.error}
        />
      )}
    </div>
  );
};

ResponsibleSelector.propTypes = {
  isDepartment: PropTypes.bool.isRequired,
  selectField: PropTypes.func.isRequired,
  responsible: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ])
  }).isRequired
};

export default ResponsibleSelector;
