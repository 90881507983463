import React from 'react';

import Header from './Header';
import Body from './Body';

const TableFormFieldSubmission = ({
  formField,
  formFieldId,
  disabled,
  state,
  setState,
  formValues,
  formPreviousValues,
  previewMode = false,
  dynamic,
  fields,
  headerFormState,
  isFormForVessel,
  formSubmission
}) => {
  return (
    <div className={`table-form-field-submission ${previewMode ? 'preview' : ''}`}>
      <Header formField={formField} />
      <Body
        formField={formField}
        formFieldId={formFieldId}
        formState={state}
        setFormState={setState}
        formValues={formValues}
        formPreviousValues={formPreviousValues}
        previewMode={previewMode}
        disabled={disabled}
        dynamic={dynamic}
        formFields={fields}
        headerFormState={headerFormState}
        formSubmission={formSubmission}
        isFormForVessel={isFormForVessel}
      />
    </div>
  );
};

export default TableFormFieldSubmission;
