import { useMutation } from '@tanstack/react-query';
import { createOffHire, editOffHire } from './api';

export const useCreateOffHireMutation = () => {
  return useMutation({ mutationFn: createOffHire });
};

export const useEditOffHireMutation = () => {
  return useMutation({ mutationFn: editOffHire });
};
