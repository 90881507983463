import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import SvgRender from 'common/components/general/SvgRender';
import ErrorMessage from '@/ts-common/components/form/helpers/ErrorMessage';
import ProfileImage from 'common/components/general/ProfileImage';

import camera from 'common/assets/svg/common/camera.svg';
import spinner from 'common/assets/svg/common/spinner.svg';
import bin from 'common/assets/svg/common/bin.svg';
import { imageUrlSelector } from 'common/utils/image-size';

const UploadImage = ({
  image,
  innerDelete,
  className = '',
  accept,
  onChange,
  group,
  isPublic,
  square,
  label,
  disabled,
  upload,
  imageSizeLabel,
  fallback,
  size,
  rounded,
  canDelete = true,
  dataCy
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const refInput = useRef(null);

  const handleSelection = e => {
    e.preventDefault();

    let files = e.target.files;

    if (!files?.length) return;

    let data = new FormData();
    data.append('group', group);

    if (isPublic) {
      data.append('public', true);
    }

    let uploadUrl = '/files';
    data.append('files[]', files[0], files[0].name);

    setLoading(true);

    upload(uploadUrl, data)
      .then(res => {
        onChange(res.data[0]);
        setLoading(false);
      })
      .catch(error => {
        setError(_get(error, 'data.message', 'Something went wrong'));
        setLoading(false);
      });
  };

  const handleRemove = () => {
    onChange(null);
  };

  const hasImage = image && image.url ? true : false;

  const handleClick = () => {
    if (innerDelete) {
      if (!hasImage || (hasImage && !canDelete)) {
        refInput.current.click();
      }
    } else {
      refInput.current.click();
    }
  };

  return (
    <>
      {label ? (
        <div className="image-uploader-label">
          <label className="form-label">{label}</label>
        </div>
      ) : null}
      <div
        className={`image-uploader-wrapper input-wrapper ${square ? 'is-square' : ''} ${
          loading ? 'pointer-events-none' : ''
        } ${innerDelete ? 'inner-delete' : ''} ${hasImage ? '' : 'is-empty'} ${
          disabled ? 'is-disabled' : ''
        } ${className ? className : 'mb-3'}`}
      >
        {hasImage || fallback ? (
          <ProfileImage
            image={imageUrlSelector({
              file: image,
              label: imageSizeLabel || null
            })}
            fallback={fallback}
            size={size}
            rounded={rounded}
            dataCy={dataCy}
          />
        ) : null}

        <div
          className="mb-0 w-100p image-uploader--input"
          onClick={() => (disabled ? {} : handleClick())}
        >
          <div
            className={`preview ${size ? `preview--resized preview--size-${size}` : ''} ${
              rounded ? `rounded ${!className.includes('rounded-') ? 'rounded-circle' : ''}` : ''
            }`}
          >
            <input
              type="file"
              accept={accept ? accept : '.png, .jpg, .jpeg, .gif, .svg'}
              // value={image && image.url ? image.url : ''}
              onChange={handleSelection}
              ref={refInput}
            />
            {loading ? (
              <img src={spinner} height="32" width="32" alt="spinner" />
            ) : (
              <SvgRender
                style={{ height: 16 }}
                src={camera}
                onClick={() => (innerDelete && hasImage ? refInput.current.click() : {})}
                className={`${!canDelete ? 'ms-0' : ''}`}
              />
            )}
          </div>
        </div>

        {hasImage && !loading && canDelete ? (
          <button type="button" className="btn btn-delete text-white p-1" onClick={handleRemove}>
            <SvgRender style={{ height: 16, width: 16 }} src={bin} />
          </button>
        ) : null}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </>
  );
};
export default UploadImage;

UploadImage.propTypes = {
  image: PropTypes.object,
  group: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  isPublic: PropTypes.bool,
  square: PropTypes.bool,
  label: PropTypes.string,
  upload: PropTypes.func.isRequired,
  image_label: PropTypes.string
};
