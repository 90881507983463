import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

type ContainerProps = PropsWithChildren<{
  label?: string | React.ReactNode;
}>;

const Container: FC<ContainerProps> = ({ children, label }) => {
  return (
    <Wrapper>
      {label && <div className="form-label">{label}</div>}
      <div className="d-flex align-items-start flex-wrap">{children}</div>
    </Wrapper>
  );
};

export default Container;

const Wrapper = styled.div`
  position: relative;
`;
