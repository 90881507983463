import SignaturePad from 'signature_pad';
import { isAuthorized } from 'utils/permissions/authorize';
import { getAuthorizedPermissionSettings } from 'utils/permissions/authorize';

export const setSignaturePad = identifier => {
  const canvas = document.getElementById(`signature-pad${identifier ? `-${identifier}` : ''}`);

  if (!canvas) return null;

  const signaturePad = new SignaturePad(canvas, {
    dotSize: '0.1',
    minWidth: '0.7',
    maxWidth: '1.4'
  });

  return signaturePad;
};

export const getSignatureSvgPath = async drawingPad => {
  const regExpression = /viewBox="([^"]+)"/;

  const svgPath = await drawingPad?.toSVG();

  return svgPath?.replace(regExpression, "viewBox='0 0 470 300'");
};

export const getValidSignatures = (signatures, isOnboard) => {
  return signatures.filter(
    requirement => (isOnboard && requirement.required_signer_type === 'crew_rank') || !isOnboard
  );
};

export const shouldShowCrewAccordingToRank = (crewRankId, permission, account) => {
  if (isAuthorized(account, [permission])) {
    const permissionCrewRanks = getAuthorizedPermissionSettings(account, permission)?.crew_ranks;

    return (permissionCrewRanks || [])?.some(rankId => rankId === crewRankId) || false;
  } else {
    return false;
  }
};
