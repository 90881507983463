import Image from './Image';
import Document from './Document';
import UnknownType from './UnknownType';
import Email from './Email';

const getComponentType = type => {
  switch (type) {
    case 'image':
      return Image;
    case 'doc':
    case 'pdf':
    case 'spreadsheets':
    case 'txt':
      return Document;
    case 'eml':
      return Email;
    default:
      return UnknownType;
  }
};

const Preview = ({ file, containerWidth, containerHeight, type, isGalleryHovered }) => {
  const CenterComponent = getComponentType(type);

  return (
    <CenterComponent
      isGalleryHovered={isGalleryHovered}
      fileType={type}
      file={file}
      containerWidth={containerWidth}
      containerHeight={containerHeight}
    />
  );
};

export default Preview;
