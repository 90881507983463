import PropTypes from 'prop-types';
import Table from 'common/components/table';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import SeverityLegend from 'common/components/general/SeverityLegend';
import Information from 'common/components/general/Information';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import Priority from 'common/components/general/Priority';

import { selectEventId } from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { displayDate } from 'common/utils/dates';
import { removeHtmlTags } from '@/ts-common/utils/texts';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';

import _get from 'lodash/get';
import check from 'common/assets/svg/common/check-circle-solid.svg';
import edit from 'common/assets/svg/actions/edit.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import {
  deleteEventVettingObservation,
  getEventVettingObservations
} from 'events/store/event-modules/vetting/actions';
import { selectEventVettingObservations } from 'events/store/event-modules/vetting/selectors';

const TruncatedText = ({ children }) => (
  <div className="overflow-hidden flex-1 text-truncate d-flex align-items-center">
    <TextWithTooltip innerTooltipClassName="min-width-548">{children}</TextWithTooltip>
  </div>
);

const ObservationsTable = ({ drawer, setActive }) => {
  const dispatch = useDispatch();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const data = useSelector(selectEventVettingObservations);
  const eventId = useSelector(selectEventId);

  const onDeleteObservation = async data => {
    await dispatch(deleteEventVettingObservation({ event_id: eventId, id: data.id })).unwrap();

    dispatch(getEventVettingObservations({ id: eventId })).unwrap();
  };

  const onEditObservation = data => {
    setActive(data);
    drawer.open();
  };

  if (!data?.length) return null;

  return (
    <div className="mt-2">
      <Table
        hideTopPagination
        hideTableFilters
        hideTableSearch
        loader
        getRowClassName={() => 'align-items-center'}
        rows={{
          chapter: data => (
            <TruncatedText>{`${_get(data, 'chapter.number')}. ${_get(
              data,
              'chapter.name'
            )}`}</TruncatedText>
          ),
          question_number: data => <TruncatedText>{_get(data, 'question.number')}</TruncatedText>,
          severity: data => (
            <SeverityLegend number={data?.question?.severity} className="text-current-color" />
          ),
          observation: data => <TruncatedText>{removeHtmlTags(data.observation)}</TruncatedText>,
          risk_rating: data => (
            <SeverityLegend number={data.severity} className="text-current-color" />
          ),
          company_response: data => (
            <TruncatedText>{removeHtmlTags(data.company_response)}</TruncatedText>
          ),
          linked_jobs: data =>
            data?.jobs?.length ? (
              <NamesInTooltip
                names={data?.jobs.map((job, index) => (
                  <div className="d-inline-flex align-items-center" key={index}>
                    <Priority
                      value={job?.importance_id}
                      withText={false}
                      size="small"
                      className="me-1"
                    />
                    {job.title}
                  </div>
                ))}
              />
            ) : null,
          resolved: data =>
            data.resolved_at && (
              <Information
                message={
                  <div className="text-nowrap">{displayDate(data.resolved_at, { time: true })}</div>
                }
                svgStyle={{ width: 12, height: 12 }}
                svgIcon={check}
                svgClassname="text-green"
              />
            ),
          actions: data => ({
            options: [
              {
                label: 'Edit',
                icon: edit,
                onClick: () => onEditObservation(data)
              },
              {
                label: 'Delete',
                icon: remove,
                preventionModal: true,
                modalProps: {
                  onAccept: () => onDeleteObservation(data),
                  header: 'Delete',
                  actionText: 'Delete',
                  action: 'delete',
                  actionHoverColor: 'delete',
                  body: (
                    <div className="text-center">
                      You are about to delete this observation. <br />
                      Are you sure you want to continue?
                    </div>
                  )
                }
              }
            ]
          })
        }}
        state={{ data }}
        columns={[
          {
            header: 'Chapter',
            key: 'chapter',
            minWidth: 200,
            maxWidth: 200,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'VIQ Ref.',
            key: 'question_number',
            minWidth: 80,
            maxWidth: 80,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'Severity',
            key: 'severity',
            minWidth: 64,
            maxWidth: 64,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'Observation',
            key: 'observation',
            minWidth: 310,
            maxWidth: 310,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'RISK RATING',
            key: 'risk_rating',
            minWidth: 88,
            maxWidth: 88,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'COMPANY RESPONSE',
            key: 'company_response',
            minWidth: 310,
            maxWidth: 310,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'Linked jobs',
            key: 'linked_jobs',
            minWidth: 308,
            maxWidth: 308,
            headerClassName: 'bg-transparent'
          },
          {
            header: 'RESOLVED',
            key: 'resolved',
            minWidth: 128,
            maxWidth: 128,
            headerClassName: 'text-start bg-transparent',
            bodyClassName: 'text-start ps-4'
          },
          !isOnBoard && {
            key: 'actions',
            field: 'actions'
          }
        ]}
      />
    </div>
  );
};

ObservationsTable.propTypes = {
  drawer: DrawerState,
  setActive: PropTypes.func
};

export default ObservationsTable;
