export const NO_JOB_LINKED_TEXT = 'No job linked';

export const filterJobStatuses = ({
  statuses,
  departments,
  mainDepartment,
  isForVessel,
  vesselJobsOnly,
  departmentsOptions,
  party_n_crew_departments,
  isOnBoard
}) => {
  // onboard
  if (isOnBoard) {
    return statuses.filter(status => status.is_for_vessel);
  }

  const mainDep = isForVessel
    ? party_n_crew_departments?.find(el => el.id === mainDepartment)
    : departmentsOptions?.find(el => el.id === mainDepartment);

  if (!statuses) return [];

  const filteredJobStatuses = statuses.filter(status => {
    if (
      (Array.isArray(status.departments) && status.departments.length === 0) ||
      departments.length === 0
    ) {
      return true;
    }

    if (mainDep?.is_crew_department) {
      return true;
    }
    if (!mainDepartment) {
      return true;
    }

    return status.departments.includes(mainDepartment);
  });
  if (isOnBoard) {
    return filteredJobStatuses;
  }

  if (vesselJobsOnly) {
    return filteredJobStatuses.filter(status => status.is_for_vessel);
  }

  return filteredJobStatuses.filter(
    status => (isForVessel && status.is_for_vessel) || (!isForVessel && status.is_for_office)
  );
};

export const isJobLocked = (job, statuses, side = 'vessel') => {
  const foundStatus = statuses.find(el => el.id === job.status_id);
  if (!foundStatus) return false;

  const { edit_side } = foundStatus;

  return edit_side === side ? false : true;
};

export const jobTemplatesDropdownColors = [
  { label: 'color_0', value: '#39628D' },
  { label: 'color_1', value: '#FE5F55' },
  { label: 'color_2', value: '#E28413' },
  { label: 'color_3', value: '#01D9A4' },
  { label: 'color_4', value: '#7084D3' }
];
