import React from 'react';

const Sidebar = ({ sidebarComponent, sidebarClassName }) => {
  return (
    <div className={`side-bar-layout__sidebar gray-scrollbar ${sidebarClassName || ''}`}>
      {sidebarComponent}
    </div>
  );
};

export default Sidebar;
