import React from 'react';
import ModuleWrapper from '../components/ModuleWrapper';

import { useDrawer } from 'common/components/drawer';
import { useSelector } from 'react-redux';
import CircledButton from 'common/components/buttons/CircledButton';
import AccountsTable from './Table';
import { selectAccountsLoading } from 'events/store/event-modules/accounts/selectors';
import { selectIsTemplate } from 'events/store/events/selectors';

const Accounts = ({ isCreate }) => {
  const isLoading = useSelector(selectAccountsLoading);
  const drawer = useDrawer(false);
  const isTemplate = useSelector(selectIsTemplate);

  return (
    <ModuleWrapper className="event-accounts" type="accounts">
      {!isTemplate && (
        <>
          {!isCreate && <AccountsTable drawer={drawer} />}
          {!isCreate && !isLoading && (
            <CircledButton
              type="add"
              className="text-primary fw-bold fs-12 add-circled-btn mt-1"
              label="Add expense"
              svgStyle={{ width: 8, height: 8 }}
              disabled={drawer.isOpen}
              onClick={() => drawer.open()}
              style={{ width: 20, height: 20 }}
            />
          )}
        </>
      )}
    </ModuleWrapper>
  );
};

export default Accounts;
