import { get, put, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getJobNotes = createAsyncThunk(
  'GET_JOB_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { id } = params;
    try {
      const response = await get(`/jobs/${id}/notes`);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createJobNotes = createAsyncThunk(
  'CREATE_JOB_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, ...rest } = params;
    try {
      const response = await post(`/jobs/${id}/notes`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateJobNote = createAsyncThunk(
  'UPDATE_JOB_NOTE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, note_id, ...rest } = params;
    try {
      const response = await put(`/jobs/${id}/notes/${note_id}`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteJobNote = createAsyncThunk(
  'DELETE_JOB_NOTE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, note_id, ...rest } = params;
    try {
      const response = await deleteRequest(`/jobs/${id}/notes/${note_id}`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteJobNotes = createAsyncThunk(
  'DELETE_JOB_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, note_id, ...rest } = params;
    try {
      const response = await deleteRequest(`/jobs/${id}/notes`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setJobNotes = options => dispatch => {
  dispatch({ type: 'SET_JOB_NOTES', payload: options });
};
