import _get from 'lodash/get';
import { numberToStr } from 'common/utils/numbers';
import moment from 'moment';

const getDate = (item, profile) => {
  let value = _get(profile, item.key, null);

  if (!value) return '-';

  return moment(value).format('DD/MM/YYYY');
};

export const getValue = (field, profile) => {
  const value = _get(profile, field.key, null);

  if (field.type !== 'boolean' && !value && value !== 0) return '-';

  if (field.type === 'number') {
    const decimalScale =
      field.inputProps && field.inputProps.decimalScale ? field.inputProps.decimalScale : undefined;

    return numberToStr(value, decimalScale, undefined);
  } else if (field.type === 'date') {
    return getDate(field, profile);
  } else if (field.type === 'boolean') {
    return value === true ? 'YES' : 'NO';
  } else {
    return _get(profile, field.key, '-');
  }
};
