import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import useRouter from 'use-react-router';
import VesselPhoto from 'common/components/vessels/profile/tabs/info/components/VesselPhoto';
import VesselInfo from 'common/components/vessels/profile/tabs/info/components/VesselInfo';
import VerticalTabView from 'common/components/layouts/vertical-tab-view';
import ActiveTabViewActions from 'common/components/layouts/vertical-tab-view/components/ActiveTabViewActions';

import paths from 'routing/routes/_paths';
import _get from 'lodash/get';

const Info = ({ profile, config }) => {
  const [active, setActive] = useState(null);
  const [mode, setMode] = useState('view'); //view or edit
  const { history, match } = useRouter();

  let filteredConfig =
    !profile.charter_type || _get(profile, 'charter_type', '').startsWith('tc')
      ? config
      : config.filter(el => el.path !== 'hire-history');

  useEffect(() => {
    if (!match.params.subtab && (!match.params.tab || match.params.tab === 'info')) {
      setActive('general');
    } else {
      setActive(match.params.subtab);
    }
  }, [match.params.subtab, match.params.tab]);

  const checkCondition = item => {
    if (!item.condition) return true;
    else return item.condition(profile);
  };

  const onTabClick = item => {
    history.push(`${paths.vessels}/info/${item.path}`);
  };

  const renderComponent = () => {
    if (mode === 'view' || !active) return null;

    let activeElement = filteredConfig.find(el => el.path === active);
    if (!activeElement || !activeElement.form) return null;

    return <activeElement.form active={activeElement} mode={mode} />;
  };

  return (
    <div className="vessel-info-tab">
      {active && checkCondition(active) ? (
        <>
          <Row>
            <Col xs={4}>
              <Row className="mb-3">
                <Col xs={12}>
                  <VesselPhoto />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <VesselInfo />
                </Col>
              </Row>
            </Col>
            <Col xs={8} className="vessel-info-tabs position-relative">
              <VerticalTabView
                onClick={onTabClick}
                disabled={mode === 'edit'}
                className={`h-100p ${mode === 'edit' ? 'tab-view-edit' : ''}`}
                selected={filteredConfig.find(el => el.path === active) || filteredConfig?.[0]}
                config={filteredConfig}
                renderComponent={mode === 'view' ? null : renderComponent}
              />
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default Info;
