import SvgRender from 'common/components/general/SvgRender';

import vessels from 'common/assets/svg/common/vessels.svg';
import company from 'common/assets/svg/common/company.svg';

const ProfileIcon = ({ type, className }) => {
  return (
    <div
      className={`profile-icon d-flex align-items-center justify-content-center ${
        type === 'vessel' ? 'bg-yellow' : 'bg-dark'
      } ${type === 'vessel' ? 'text-dark' : 'text-white'} ${className || ''}`}
    >
      <SvgRender src={type === 'vessel' ? vessels : company} style={{ width: 15, height: 15 }} />
    </div>
  );
};

export default ProfileIcon;
