import { components } from 'react-select';
import Tooltip from '@/ts-common/components/general/Tooltip';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GroupBase, IndicatorsContainerProps } from 'react-select';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

const MultipleIndicatorsContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: IndicatorsContainerProps<Option, IsMulti, Group>) => {
  const [firstHiddenIndex, setFirstHiddenIndex] = useState<number | null>(null);
  const { tooltipID, avoidRender } = useTooltipID('react-select-multiple-overflow-tooltip');

  const tooltipContainer = useRef<HTMLDivElement>(null);

  const maxLimit = 15;

  const values = props.getValue();
  const hiddenValues = useMemo(() => {
    if (firstHiddenIndex) return values.filter((_, i) => i >= firstHiddenIndex);

    return [];
  }, [firstHiddenIndex, values]);

  const isVisible = useCallback(
    (parent: HTMLElement, child: Element) =>
      !(child instanceof HTMLElement && child.offsetTop > parent.offsetTop),
    []
  );

  useEffect(() => {
    if (tooltipContainer?.current && values?.length) {
      let hasOverflow = false;

      const valueContainer = tooltipContainer.current.previousElementSibling;

      if (!valueContainer) return;

      const controlContainer = valueContainer.parentElement; // react-select__control

      if (!controlContainer) return;

      for (let i = 0; i < valueContainer.children.length; i++) {
        const childNode = valueContainer.children[i];

        if (childNode.tagName === 'DIV' && !isVisible(controlContainer, childNode)) {
          setFirstHiddenIndex(i);
          hasOverflow = true;

          break;
        }
      }

      if (!hasOverflow) setFirstHiddenIndex(null);
    } else if (firstHiddenIndex) {
      setFirstHiddenIndex(null);
    }
  }, [values?.length, props.selectProps.isDisabled, children, firstHiddenIndex, isVisible]);

  return (
    <>
      <div
        ref={tooltipContainer}
        className={`react-select-multiple-overflow-tooltip ${
          firstHiddenIndex === null || hiddenValues?.length === 0 ? 'invisible' : ''
        }${
          props.selectProps?.isDisabled ? ' react-select-multiple-overflow-tooltip--disabled' : ''
        }`}
        id={tooltipID || ''}
      >
        {`+${hiddenValues?.length}`}
      </div>
      {tooltipID && !avoidRender ? (
        <Tooltip target={tooltipID} fade={false}>
          {hiddenValues
            .filter((_, i) => i < maxLimit)
            .map(opt => props.selectProps.getOptionLabel(opt))
            .join(', ')}
          {hiddenValues?.length - 1 > maxLimit ? ', ...' : ''}
        </Tooltip>
      ) : null}
      <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>
    </>
  );
};

export default MultipleIndicatorsContainer;
