import React from 'react';

import { func } from 'prop-types';
import CircledButton from 'common/components/buttons/CircledButton';

const Actions = ({ onEdit, onDelete }) => {
  return (
    <div className="input-save-actions d-flex align-items-center justify-content-end">
      <CircledButton
        svgStyle={{ width: 13, height: 13 }}
        type="edit"
        className="me-1"
        onClick={onEdit}
      />
      <CircledButton type="bin" onClick={onDelete} />
    </div>
  );
};

export default Actions;

Actions.propTypes = {
  onEdit: func.isRequired,
  onDelete: func.isRequired
};
