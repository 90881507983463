import { useState } from 'react';

import Input from 'common/components/form/inputs/Input';
import Checkbox from 'common/components/form/inputs/Checkbox';
import CircledButton from 'common/components/buttons/CircledButton';

import * as manualsActions from 'manuals/store/actions';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { selectManualId } from 'manuals/store/selectors';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const ChapterInput = ({ onCancel, onSave, chapter, parentUid }) => {
  const manualId = useSelector(selectManualId);
  const [title, setTitle] = useState(!parentUid && chapter ? chapter.title : '');
  const [hideSortIndexPath, setHideSortIndexPath] = useState(
    !parentUid && chapter ? !chapter.show_sort_index_path : false
  );
  const [isSaving, setIsSaving] = useState(false);

  const { avoidRender, tooltipID } = useTooltipID('manuals-save-for-review-btn');

  const [
    updateManualChaptersTree,
    toggleChapterCreationForm,
    updateChapter,
    setEdittingChapter,
    setAddingSubchapterTo,
    seChapterExpanded
  ] = useActions([
    manualsActions.updateManualChaptersTree,
    manualsActions.toggleChapterCreationForm,
    manualsActions.updateChapter,
    manualsActions.setEdittingChapter,
    manualsActions.setAddingSubchapterTo,
    manualsActions.seChapterExpanded
  ]);

  const saveChapter = async () => {
    setIsSaving(true);

    try {
      const params = {
        title,
        manual_id: manualId,
        show_sort_index_path: !hideSortIndexPath,
        // id: chapter ? chapter.id : undefined,
        uid: chapter ? chapter.uid : undefined,
        parent_uid: parentUid || undefined
      };

      if (!chapter || parentUid) {
        params.change_request = {
          title: `Created ${title} chapter`,
          status: 'open'
        };
      } else {
        params.change_request = {
          title: `Renamed ${title} chapter`,
          status: 'open'
        };
      }

      const res = await onSave(params);

      if (!chapter) {
        updateManualChaptersTree(res);
        toggleChapterCreationForm(false);
      } else {
        if (parentUid) {
          updateManualChaptersTree(res);
          setAddingSubchapterTo({ [chapter.uid]: false });
          seChapterExpanded({ [parentUid]: true });
        } else {
          updateChapter({
            uid: chapter.uid,
            title,
            open_change_request_count: chapter.open_change_request_count + 1,
            show_sort_index_path: !hideSortIndexPath
          });
          setEdittingChapter({ [chapter.uid]: false });
        }
      }
    } catch (e) {
      console.log(e);
    }

    setIsSaving(false);
  };

  return (
    <div className="chapters-tree__input">
      <Input
        className="w-100p mb-0"
        placeholder="Chapter title"
        value={title}
        onChange={e => setTitle(e.target.value)}
        white
        autoFocus
      />

      <div className="chapters-tree__input-actions d-flex flex-nowrap">
        <CircledButton
          type="close"
          style={{ width: 16, maxWidth: 16, height: 16 }}
          svgStyle={{ width: 8, height: 8 }}
          className={`me-1 ${isSaving ? 'disabled' : ''}`}
          onClick={onCancel}
          disabled={isSaving}
        />
        <CircledButton
          id={tooltipID}
          type="versioning"
          disabled={!title || isSaving}
          style={{ width: 16, maxWidth: 16, height: 16 }}
          svgStyle={{ width: 10, height: 10 }}
          onClick={saveChapter}
        />
        {avoidRender ? null : <Tooltip target={tooltipID}>Save for review</Tooltip>}
      </div>

      <div className="chapters-tree__input-index-toggle cmt-12">
        <Checkbox
          value={hideSortIndexPath}
          onChange={() => setHideSortIndexPath(!hideSortIndexPath)}
          label={<span className="text-primary">Don't show the numbering in the tree</span>}
        />
      </div>
    </div>
  );
};

export default ChapterInput;
