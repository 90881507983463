import React from 'react';
import PropTypes from 'prop-types';
import ShadowBox from 'common/components/general/ShadowBox';
import SvgRender from 'common/components/general/SvgRender';

const EmptyStatePage = ({ label, icon, className }) => {
  return (
    <ShadowBox
      flat
      color="white"
      className={`d-flex align-items-center justify-content-center flex-column text-violet fs-14 fw-medium mx-4 mt-2 ${className}`}
    >
      <SvgRender src={icon} style={{ height: 24, width: 24 }} />
      <span>{label}</span>
    </ShadowBox>
  );
};

EmptyStatePage.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default EmptyStatePage;
