import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrow.svg';

const PickerNavigation = ({ children, currentIndex, goToIndex }) => {
  const onPrevious = () => {
    const previousIndex = currentIndex - 17;

    goToIndex(previousIndex);
  };

  const onNext = () => {
    const nextIndex = currentIndex + 17;

    goToIndex(nextIndex);
  };

  return (
    <div className="date-input-navigation pb-1 mb-2 border-bottom d-flex align-items-center">
      <Button className={`p-0`} color="link" onClick={onPrevious}>
        <SvgRender src={arrow} style={{ width: 12, height: 12, transform: 'rotate(90deg)' }} />
      </Button>
      <div className="flex-1 d-flex align-items-center justify-content-center flex-column">
        <div className="date-input-navigation__label">{children}</div>
      </div>
      <Button color="link" onClick={onNext} className={`p-0`}>
        <SvgRender src={arrow} style={{ width: 12, height: 12, transform: 'rotate(-90deg)' }} />
      </Button>
    </div>
  );
};

export default PickerNavigation;
