import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import CREW_DOCS_TYPES from 'crew/store/documents/types';

import { get, put, post, deleteRequest, download } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';

export const getCrewDocuments = params => dispatch => {
  const table = 'crew_profile_documents';
  dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.START, payload: { params, table } });

  return get(`/crew/${params.id}/documents`, params)
    .then(response => {
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response.data, table }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });

      throw error;
    });
};

export const getCrewDocument = params => dispatch => {
  dispatch({ type: TYPES.GET_CREW_DOCUMENT.START });

  return get(`/crew-documents/${params.document_id}`)
    .then(res => {
      dispatch({
        type: TYPES.GET_CREW_DOCUMENT.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_DOCUMENT.ERROR, payload: error });

      throw error;
    });
};

export const updateCrewDocument = params => dispatch => {
  const { document_id, ...rest } = params;
  dispatch({ type: TYPES.UPDATE_CREW_DOCUMENT.START, payload: { params } });
  dispatch(updateTableRow({ data: params, table: 'crew_profile_documents' }));

  return put(`/crew-documents/${document_id}`, { ...rest })
    .then(response => {
      dispatch({ type: TYPES.UPDATE_CREW_DOCUMENT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));
      dispatch(
        updateTableRow({
          data: { ...response.data, attachments_count: params.attachment_ids.length },
          table: 'crew_profile_documents'
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_CREW_DOCUMENT.ERROR,
        payload: { response: error, params }
      });
      dispatch(resetTableRowUpdate({ data: params, table: 'crew_profile_documents' }));

      throw error;
    });
};

export const getCrewDocumentHistory = params => dispatch => {
  const { crewId, id, ...rest } = params;
  dispatch({ type: TYPES.GET_CREW_DOCUMENT_HISTORY.START, payload: { params } });

  return get(`/crew/${crewId}/documents/${id}/history`, rest)
    .then(response => {
      dispatch({
        type: TYPES.GET_CREW_DOCUMENT_HISTORY.SUCCESS,
        payload: response
      });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_CREW_DOCUMENT_HISTORY.ERROR, payload: { error } });
      throw error;
    });
};

export const createCrewDocument = params => dispatch => {
  dispatch({ type: TYPES.CREATE_CREW_DOCUMENT.START, payload: { params } });

  return post(`/crew/${params.id}/documents`, params)
    .then(response => {
      dispatch({ type: TYPES.CREATE_CREW_DOCUMENT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Document created successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_CREW_DOCUMENT.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const deleteCrewDocument = params => dispatch => {
  dispatch({ type: TYPES.DELETE_CREW_DOCUMENT.START, payload: { params } });

  return deleteRequest(`/crew-documents/${params.id}`, params)
    .then(response => {
      dispatch({ type: TYPES.DELETE_CREW_DOCUMENT.SUCCESS, payload: response.data });
      dispatch(successHandler({ title: 'Success!', message: 'Document deleted successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.DELETE_CREW_DOCUMENT.ERROR,
        payload: { response: error, params }
      });

      return error;
    });
};

export const downloadCrewDocumentAttachments = params => dispatch => {
  dispatch({
    type: CREW_DOCS_TYPES.DOWNLOAD_CREW_DOCUMENT_ATTACHMENTS.START,
    payload: { params }
  });

  return download(`/files/entity`, { id: params.id, type: 'crew_document' })
    .then(response => {
      dispatch({
        type: CREW_DOCS_TYPES.DOWNLOAD_CREW_DOCUMENT_ATTACHMENTS.SUCCESS,
        payload: { data: response.data, params }
      });
      return response;
    })
    .catch(error =>
      dispatch({
        type: CREW_DOCS_TYPES.DOWNLOAD_CREW_DOCUMENT_ATTACHMENTS.ERROR,
        payload: { error, params }
      })
    );
};
