import useRouter from 'use-react-router';

import { Row, Col } from 'reactstrap';
import { getFieldName } from 'common/components/forms/digital/state-utils';
import { renderViewType } from 'common/components/forms/digital/render-helpers';

import TextWithTooltip from 'common/components/general/TextWithTooltip';

import { useSelector } from 'react-redux';
import { selectTableSorting } from 'store/tables/selectors';
import { handleSort } from '../helpers';
import Cell from './Cell';
import CircledButton from 'common/components/buttons/CircledButton';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';
import { isFormAllowed } from 'common/components/forms/helpers';
import { selectAccount } from '@/store/account/selectors';

const RowColumns = ({
  alarm,
  importance,
  form,
  formUid,
  revision,
  form_form_field,
  form_form_field_value,
  department,
  vessels,
  periodicity,
  className,
  columnClassName,
  isHeader,
  isOnBoard,
  isHovering,
  onFormFillWithInitialValues
}) => {
  const { history } = useRouter();
  const tableSorting = useSelector(state => selectTableSorting(state, 'form_planning'));
  const account = useSelector(selectAccount);

  const getBaseColumnClassName = () => {
    let baseClass = '';
    if (isHeader) {
      baseClass = 'matrix-table__header-container--label';
    }

    return `d-flex ${baseClass} ${columnClassName || ''}`;
  };

  const getContainerClassName = () => {
    let baseClass = '';
    if (isHeader) {
      baseClass = 'matrix-table__header-container cpb-14 min-height-40';
    }

    return `${baseClass} ${className || ''}`;
  };

  const commonColumnClassName = getBaseColumnClassName();
  const containerClassName = getContainerClassName();

  const paddingClassName = !isHeader ? 'ps-2' : '';

  return (
    <Row className={`${containerClassName} w-100p flex-nowrap`} noGutters>
      <Col xs={'auto'} className={`${commonColumnClassName} p-0 mt-0 w-100p max-width-24`}>
        {alarm}
      </Col>
      {!isOnBoard && (
        <Col
          xs={2}
          className={`${commonColumnClassName} ps-2 mt-0`}
          onClick={() => isHeader && handleSort('vessel', history, tableSorting)}
        >
          <Cell
            isHeader={isHeader}
            sorted={tableSorting.vessel ? true : false}
            order={tableSorting.vessel}
          >
            {vessels}
          </Cell>
        </Col>
      )}
      <Col
        xs={'auto'}
        className={`${commonColumnClassName} mt-0 w-100p max-width-56 justify-content-center`}
        onClick={() => isHeader && handleSort('importance', history, tableSorting)}
      >
        <Cell
          isHeader={isHeader}
          sorted={tableSorting.importance ? true : false}
          order={tableSorting.importance}
        >
          {importance}
        </Cell>
      </Col>
      <Col
        className={`${commonColumnClassName} ps-0 ${
          !isHeader ? 'flex-column' : ''
        } overflow-hidden mt-0`}
        onClick={() => isHeader && handleSort('form', history, tableSorting)}
      >
        <Cell
          isHeader={isHeader}
          sorted={tableSorting.form ? true : false}
          order={tableSorting.form}
        >
          <TextWithTooltip>
            {form || ''}
            {revision && <strong>&nbsp; - {revision}</strong>}
          </TextWithTooltip>

          {form_form_field ? (
            <div className="text-violet fs-12 d-flex align-items-center text-nowrap">
              <strong>
                {getFieldName(form_form_field)}
                &nbsp;|&nbsp;
              </strong>
              <div className="d-flex align-items-center w-100p overflow-hidden">
                {renderViewType(form_form_field, form_form_field_value)}
              </div>
            </div>
          ) : null}
        </Cell>
      </Col>
      <Col
        xs={3}
        className={`${commonColumnClassName} ${paddingClassName} mt-0`}
        onClick={() => isHeader && handleSort('department', history, tableSorting)}
      >
        <Cell
          isHeader={isHeader}
          sorted={tableSorting.department ? true : false}
          order={tableSorting.department}
        >
          {department}
        </Cell>
      </Col>
      <Col
        xs={2}
        className={`${commonColumnClassName} ${paddingClassName} mt-0`}
        onClick={() => isHeader && handleSort('periodicity', history, tableSorting)}
      >
        <Cell
          isHeader={isHeader}
          sorted={tableSorting.periodicity ? true : false}
          order={tableSorting.periodicity}
        >
          <span>{periodicity}</span>
        </Cell>
      </Col>
      <Col xs={1}>
        {!isHeader && isHovering ? (
          isOnBoard ? (
            <AuthCheck permissions={[permissions.onboard.forms.view]}>
              <div className="d-flex justify-content-end me-2">
                <CircledButton
                  type="add"
                  onClick={onFormFillWithInitialValues}
                  className="z-index-3"
                />
              </div>
            </AuthCheck>
          ) : isFormAllowed(account, permissions.office.forms.create, formUid) ? (
            <AuthCheck permissions={[permissions.office.forms.create]}>
              <div className="d-flex justify-content-end me-2">
                <CircledButton
                  type="add"
                  onClick={onFormFillWithInitialValues}
                  className="z-index-3"
                />
              </div>
            </AuthCheck>
          ) : null
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default RowColumns;
