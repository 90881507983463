import React from 'react';
import CollapseBox from 'common/components/collapse/CollapseBox';
import PropTypes from 'prop-types';

const CollapseColoredBox = ({
  header,
  subHeader,
  children,
  color = 'primary',
  background = 'white',
  className = '',
  headerClassName = '',
  expandButton,
  disableCollapse,
  renderHeader,
  onExpandCallback,
  onHeaderClick,
  reversedExpandBtn,
  collapseBoxHeaderClassName,
  dataCy,
  ...rest
}) => {
  const headerComponent = () => {
    return (
      <div className={`text-${color} fw-bold fs-12 d-flex justify-content-between`}>
        <div className={headerClassName}>{header}</div>
        {subHeader ? <div className="text-primary me-10">{subHeader}</div> : null}
      </div>
    );
  };

  return (
    <CollapseBox
      header={renderHeader ? renderHeader() : headerComponent()}
      className={`collapse-colored-box ${className} collapse-colored-box--colored-${background}`}
      expandButton={expandButton}
      reversedExpandBtn={reversedExpandBtn}
      disableCollapse={disableCollapse}
      onOpen={onExpandCallback}
      onClose={onExpandCallback}
      onHeaderClick={onHeaderClick}
      headerClassName={collapseBoxHeaderClassName}
      dataCy={dataCy}
      {...rest}
    >
      {children}
    </CollapseBox>
  );
};

CollapseColoredBox.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  background: PropTypes.oneOf(['lighter-blue', 'gray', 'white', 'lighter-gray', 'violet']),
  children: PropTypes.node,
  expandButton: PropTypes.bool,
  onExpandCallback: PropTypes.func,
  onHeaderClick: PropTypes.func // Use along with disableCollapse
};

export default CollapseColoredBox;
