import React from 'react';
import { useSelector } from 'react-redux';
import { numberToStr } from 'common/utils/numbers';

import { selectActiveTab, selectEngineSubGroupTotal } from 'captain-reports/store/selectors';

const MainEngineHeaderJacketCoolingWaterTemp = ({ subGroup, label, indexes }) => {
  const activeTab = useSelector(state => selectActiveTab(state, label));
  const total = useSelector(state =>
    selectEngineSubGroupTotal(
      state,
      activeTab,
      subGroup,
      'me_jacket_cool_water_outlet_temperature_per_cylinder'
    )
  );

  return (
    <div className="d-flex align-items-center justify-content-between fs-10 w-100p">
      <div>
        JACKET COOLING WATER OUTLET TEMPERATURE <span className="fw-normal">- PER CYLINDER</span>
      </div>
      <div>
        <span className="fw-normal">AVERAGE: </span>
        <strong>
          {total && indexes.length ? `${numberToStr(total / indexes.length)} °C` : '-'}
        </strong>
      </div>
    </div>
  );
};

export default MainEngineHeaderJacketCoolingWaterTemp;
