import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import FormTypeInformationLabel from 'common/components/forms/_components/FormTypeInformationLabel';

const FormInformation = ({ isForVessel }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const getTooltipMessage = isForVessel =>
    isForVessel || isOnBoard
      ? 'This job is synced with the vessel, in the forms dropdown\nyou see all vessel forms. You DO NOT see any office forms.'
      : 'This job is for office, in the forms dropdown you see\n office & vessel forms.';

  return <FormTypeInformationLabel tooltipMessage={getTooltipMessage(isForVessel)} />;
};

FormInformation.propTypes = {
  isForVessel: PropTypes.bool
};

export default FormInformation;
