import { createSlice } from '@reduxjs/toolkit';
import { getPosts, getPostsWithImage } from './actions';
import TYPES from 'store/sidebar/types';

const INITIAL_STATE = {
  postsWithoutImage: {
    data: [],
    isLoading: true,
    loadMoreLoading: false,
    paging: {
      per_page: 5,
      current_page: 1,
      total: 1,
      last_page: 1
    }
  },
  announcements: {
    data: [],
    isLoading: true,
    loadMoreLoading: false,
    paging: {
      per_page: 5,
      current_page: 1,
      total: 1,
      last_page: 1
    }
  },
  postsWithImage: {
    data: [],
    isLoading: true,
    loadMoreLoading: false,
    paging: {
      per_page: 4,
      current_page: 1,
      total: 1,
      last_page: 1
    }
  }
};

const slice = createSlice({
  name: 'posts',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPosts.pending, (state, { meta }) => {
        const key = meta.arg.key;
        const loadMore = meta.arg.loadMore;

        state[key].isLoading = state[key].data?.length ? false : true;
        state[key].loadMoreLoading = loadMore ? true : false;

        return state;
      })
      .addCase(getPosts.fulfilled, (state, { payload, meta }) => {
        const key = meta.arg.key;

        state[key].data =
          payload.meta.current_page === 1 ? payload.data : [...state[key].data, ...payload.data];
        state[key].paging = payload.meta;
        state[key].isLoading = false;
        state[key].loadMoreLoading = false;

        return state;
      })

      .addCase(getPosts.rejected, (state, { meta }) => {
        const key = meta.arg.key;

        state[key].data = [];
        state[key].isLoading = false;
        state[key].loadMoreLoading = false;

        return state;
      })

      .addCase(getPostsWithImage.pending, state => {
        state.postsWithImage.isLoading = state.postsWithImage.data.length ? false : true;
        state.postsWithImage.loadMoreLoading = true;

        return state;
      })

      .addCase(getPostsWithImage.fulfilled, (state, { payload }) => {
        state.postsWithImage.data =
          payload.meta.current_page === 1
            ? payload.data
            : [...state.postsWithImage.data, ...payload.data];
        state.postsWithImage.paging = payload?.meta;
        state.postsWithImage.isLoading = false;
        state.postsWithImage.loadMoreLoading = false;

        return state;
      })

      .addCase(getPostsWithImage.rejected, state => {
        state.postsWithImage.data = [];
        state.postsWithImage.isLoading = false;
        state.postsWithImage.loadMoreLoading = false;

        return state;
      })

      .addCase(TYPES.TOGGLE_PANEL, () => {
        return INITIAL_STATE;
      });
  }
});

export default slice.reducer;
