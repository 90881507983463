import { useRef, useEffect } from 'react';

const usePortal = (id: string): HTMLElement => {
  const rootElemRef = useRef<HTMLElement>(document.createElement('div'));

  useEffect(() => {
    // Look for existing target DOM element to append to
    const parentElem = document.querySelector<HTMLElement>(`#${id}`);

    // Add the detached element to the parent
    parentElem?.appendChild(rootElemRef.current);

    // This function is run on unmount
    return () => {
      rootElemRef.current.remove();
    };
  }, [id]);

  return rootElemRef.current;
};

export default usePortal;
