import { FC, ReactNode } from 'react';

type PageTitleProps = {
  title?: string | ReactNode;
  render?: () => ReactNode;
  className?: string;
};

const PageTitle: FC<PageTitleProps> = ({ title, render, className }) => {
  return title || render ? (
    <div className={`app-title ${className || ''}`}>
      {render ? (typeof render === 'function' ? render() : render) : title}
    </div>
  ) : null;
};

export default PageTitle;
