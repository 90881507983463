import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRedirect } from 'utils/hooks';
import useRouter from 'use-react-router';

import MoreActionsDropdown from 'common/components/general/MoreActionsDropdown';
import PageLoader from 'common/components/general/PageLoader';
import { useDrawer } from 'common/components/drawer';

import Profile from 'crew/profile';

import { getCrewProfile, getPromotionPlanActive } from 'crew/store/profile/actions';
import {
  selectCrewProfile,
  selectCrewProfileActiveView,
  selectPromotionPlanRankName
} from 'crew/store/profile/selectors';

import views from './_constants';

import _get from 'lodash/get';

import copyToClipboard from 'copy-to-clipboard';
import copyIcon from 'common/assets/svg/actions/copy.svg';
import lockIcon from 'common/assets/svg/common/lock.svg';

import moment from 'moment';

import { isAuthorized } from 'utils/permissions/authorize';
import Drawer from './drawer';
import RankPromotionLabel from 'common/components/labels/RankPromotionLabel';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';

const CrewProfile = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const promotionRankName = useSelector(selectPromotionPlanRankName);
  const { match } = useRouter();

  const [loading, setLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [drawerType, setDrawerType] = useState('');

  const isOnBoard = useSelector(state => state.isOnBoard);

  const crew = useSelector(selectCrewProfile);
  const activeKey = useSelector(selectCrewProfileActiveView);
  const active = views.find(v => v.label === activeKey);

  const drawer = useDrawer();

  useRedirect(shouldRedirect);

  const getSafeDate = date => {
    if (!date) return date;
    return moment(date).format('DD/MM/YYYY');
  };

  const copyDetails = () => {
    const text = `First Name: ${crew.first_name || '-'}
Middle Name: ${_get(crew, 'middle_name') || '-'}
Last Name: ${_get(crew, 'last_name') || '-'}
Gender: ${_get(crew, 'gender.name') || '-'}
Nationality: ${_get(crew, 'nationality.name') || '-'}
Birth Date: ${getSafeDate(_get(crew, 'birthday')) || '-'}
Passport Number: ${_get(crew, 'passport_details[0].code') || '-'}
Passport Issue Date: ${getSafeDate(_get(crew, 'passport_details[0].issued_at')) || '-'}
Passport Expiry Date: ${getSafeDate(_get(crew, 'passport_details[0].expires_at')) || '-'}
Email: ${_get(crew, 'email') || '-'}
${
  crew.phones.length
    ? `Telephone Numbers:
${crew.phones
  .map(phone => `${_get(phone, 'type') || '-'}: ${_get(phone, 'phone') || '-'}`)
  .join('\n')}`
    : ''
}
`;

    copyToClipboard(text);
  };

  const options = [
    {
      isEnabled: true,
      icon: copyIcon,
      clickAction: copyDetails,
      title: 'Copy Details to Clipboard',
      permissions: null
    },
    {
      isEnabled: true,
      icon: lockIcon,
      clickAction: () => {
        setDrawerType('change_password');
        drawer.open();
      },
      title: 'Change Password',
      permissions: [
        permissions.onboard.crew.seaman_profile.security.all_users.edit_password,
        permissions.onboard.crew.seaman_profile.security.own_profile.edit_password
      ]
    }
  ];

  const getProfileInfo = async () => {
    setLoading(true);

    try {
      await dispatch(getCrewProfile({ id: match.params.id }));
      await dispatch(getPromotionPlanActive(match.params.id));

      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e?.status === 404) {
        setShouldRedirect(true);
      }
    }
  };

  useEffect(() => {
    if ((crew && parseInt(match.params.id, 10) !== crew.id) || !crew) getProfileInfo();
  }, [match.params.id]);

  const optionsAfterPermissions = options.filter(el => isAuthorized(account, el.permissions));

  return crew && active && !loading ? (
    <>
      <Profile
        activeKey={activeKey}
        active={active}
        pageTitle={active.name}
        pageDocumentTitle={crew.full_name}
        pageActions={
          <>
            {active.topActions ? <active.topActions /> : null}
            {promotionRankName && !isOnBoard ? (
              <div className="d-flex align-items-center">
                <RankPromotionLabel
                  className="px-2 cpy-4"
                  text={
                    <>
                      Promoted to <strong>{promotionRankName}</strong>
                    </>
                  }
                  textClassName="text-primary fs-12 fw-500"
                  svgClassName="cme-12"
                  svgStyle={{ width: 21, height: 21 }}
                />
                <div className="small-seperator text-platinum ms-3 me-2 py-2" />
              </div>
            ) : null}
            <MoreActionsDropdown options={optionsAfterPermissions} menuClass="mt-2" />
          </>
        }
        views={views}
        isOnboard={true}
        options={optionsAfterPermissions}
      />

      <Drawer drawer={drawer} crew={crew} type={drawerType} />
    </>
  ) : (
    <PageLoader isLoading={true} />
  );
};

export default CrewProfile;
