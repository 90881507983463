import React from 'react';
import ContentLoader from 'react-content-loader';

const ModuleContentLoader = () => (
  <div>
    <ContentLoader
      speed={1}
      height="86"
      width="100%"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor="#f8f9fb"
      foregroundColor="#ebebeb"
      title="Loading"
    >
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="26" />
      <rect x="0" y="30" rx="4" ry="4" width="100%" height="26" />
      <rect x="0" y="60" rx="4" ry="4" width="100%" height="26" />
    </ContentLoader>
  </div>
);

export default ModuleContentLoader;
