import React from 'react';
import _get from 'lodash/get';

import ShadowBox from 'common/components/general/ShadowBox';
import { Row, Col } from 'reactstrap';
import Field from './Field';

import emailIcon from 'common/assets/svg/socials/email.svg';
import phoneIcon from 'common/assets/svg/common/phone.svg';
import policiesIcon from 'common/assets/svg/common/policies.svg';

import PropTypes from 'prop-types';

const PrimaryDetails = ({ className = '', profile, mode }) => {
  const email = _get(profile, 'email');
  const phone = _get(profile, 'phone');
  const accessIsGranted = _get(profile, 'can_login');

  return (
    <ShadowBox className={`bg-white px-2 cpy-12 min-width-0 ${className}`} flat>
      <Row>
        <Col>
          <Field title="Primary Email" icon={emailIcon} content={email} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={6}>
          <Field title="Primary Phone" icon={phoneIcon} content={phone} />
        </Col>
        <Col xs={6}>
          <Field
            title="Access"
            icon={policiesIcon}
            content={mode === 'create' ? '-' : accessIsGranted ? 'Active' : 'Inactive'}
          />
        </Col>
      </Row>
    </ShadowBox>
  );
};

PrimaryDetails.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object,
  mode: PropTypes.string.isRequired
};

export default PrimaryDetails;
