import PropTypes from 'prop-types';
import { hasValue } from 'common/utils/numbers';

import LinkTo from 'views/dashboard/components/LinkTo';

const ContentDueDate = ({ isMissing, remainingTime, color, path, permissions }) => (
  <div className={`d-flex align-items-center justify-content-between text-${color}`}>
    <div className="d-flex align-items-center">
      <div className="fs-20 fw-bold line-height-1">
        {!isMissing ? (
          hasValue(remainingTime) ? (
            <div className="me-1">{remainingTime}</div>
          ) : null
        ) : (
          <div className="fs-12 me-1">Missing</div>
        )}
      </div>
      {!isMissing ? <div className="line-height-10">Days left</div> : null}
    </div>
    <LinkTo bg={color} to={path} permissions={permissions} />
  </div>
);

export default ContentDueDate;

ContentDueDate.propTypes = {
  isMissing: PropTypes.bool.isRequired,
  remainingTime: PropTypes.number,
  color: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  permissions: PropTypes.array
};
