import React, { FC, MouseEvent } from 'react';

import SvgRender from 'common/components/general/SvgRender';

import filter from 'common/assets/svg/table/filter.svg';
import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';
import add from 'common/assets/svg/actions/add.svg';
import share from 'common/assets/svg/common/share-solid.svg';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

type WhiteShadowButtonProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => void;
  text?: string;
  type?: 'filter' | 'share' | 'add' | string;
  icon?: string;
  className?: string;
  isLink?: boolean;
  goTo?: string;
  svgStyle?: React.CSSProperties;
  svgClassName?: string;
  disabled?: boolean;
};

const WhiteShadowButton: FC<WhiteShadowButtonProps> = ({
  onClick,
  text,
  type,
  icon,
  className,
  isLink,
  goTo,
  svgStyle,
  svgClassName,
  disabled
}) => {
  const getIconType = () => {
    switch (type) {
      case 'filter':
        return filter;
      case 'share':
        return share;
      case 'add':
        return add;
      default:
        return arrowFilters;
    }
  };

  const Tag = isLink ? Link : Button;
  const props = isLink ? { to: goTo } : { type: 'button', color: '', disabled };

  return (
    <Tag
      className={`btn btn-white-shadow btn-white-shadow__${
        type ? type : 'default'
      } d-inline-flex align-items-center ${className || ''} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      {...props}
    >
      {text}
      <SvgRender
        className={`position-relative ${svgClassName || ''}`}
        src={icon ? icon : getIconType()}
        style={!type ? { transform: 'rotate(90deg)', ...svgStyle } : svgStyle}
      />
    </Tag>
  );
};
export default WhiteShadowButton;
