import React, { useState } from 'react';
import { PopoverHeader, PopoverBody } from 'reactstrap';

import useTooltipID from 'common/utils/hooks/useTooltipID';

import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';

import HelpIcon from 'common/assets/svg/table/help.svg';
import HelpIconSolid from 'common/assets/svg/common/question-mark-solid.svg';
import { PreviewHtml } from 'common/components/form/inputs/Editor';

const HelpTooltip = ({
  hideTooltip,
  onClick = () => {},
  placement = 'right',
  className = '',
  svgStyle = { width: 13, height: 13 },
  svgClassName = '',
  title = 'Help text',
  text
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { avoidRender, tooltipID: tooltipId } = useTooltipID('forms-help-tooltip');

  if (avoidRender) {
    return null;
  }

  return (
    <>
      <div
        id={tooltipId}
        className={`forms-help-tooltip d-flex align-self-start ${className}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <SvgRender
          onClick={() => onClick()}
          src={isHovered ? HelpIconSolid : HelpIcon}
          className={`pointer text-primary ${svgClassName}`}
          style={svgStyle}
        />
      </div>
      {hideTooltip ? null : (
        <Tooltip
          target={tooltipId}
          placement={placement}
          className="comments-popup"
          innerClassName="forms-help-tooltip__tooltip"
          arrowClassName="forms-help-tooltip__tooltip-arrow"
        >
          <PopoverHeader className="border-bottom d-flex align-items-center text-violet bg-white px-0 pt-0">
            <SvgRender src={HelpIcon} style={{ width: 13, height: 13 }} />
            <span className="comments-popup__title fs-12 fw-medium">{title}</span>
          </PopoverHeader>

          <PopoverBody className="comments-popup__message text-primary pt-2 px-0 fs-14">
            <PreviewHtml value={text} />
          </PopoverBody>
        </Tooltip>
      )}
    </>
  );
};

export default HelpTooltip;
