import React from 'react';
import FormHeader from 'common/components/layouts/vertical-tab-view/components/FormHeader';

export const SectionWrapper = ({ children, className }) => (
  <div className={`profile-data-box ${className || ''}`}>{children}</div>
);

export const SectionHeader = ({ header }) => {
  return header ? (
    <div
      className={`profile-data-box--header d-flex align-items-center justify-content-between text-uppercase ps-4 pe-3 ${
        header.className || 'mb-1 mt-3'
      }`}
    >
      <FormHeader
        item={{ header: header?.label || header }}
        width={header?.width || 4}
        color={header?.color}
        className="mb-0"
      ></FormHeader>

      {header.subLabel ? (
        <div className="fs-12 text-uppercase fw-bold">{header.subLabel}</div>
      ) : null}
    </div>
  ) : null;
};

export const SectionInner = ({ children, footer, profile }) => {
  return (
    <div className={`profile-data-box--wrap flex-1`}>
      {children}
      {footer ? (
        <div className="profile-data-box--footer py-2 ps-4 pe-2">{footer.render(profile)}</div>
      ) : null}
    </div>
  );
};
