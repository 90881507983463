import React from 'react';
import EmptyPage from 'common/components/general/EmptyPage';
import { useSelector } from 'react-redux';

import jobs from 'common/assets/svg/common/jobs.svg';

const TableEmptyPage = ({ hasValidFilters, isLoading, total }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <EmptyPage
      className="pms-jobs-view__empty"
      pageText={
        hasValidFilters && !isLoading && !total ? (
          <span>No PMS jobs for the selected filters.</span>
        ) : (
          <span>
            No filtered PMS jobs.
            <br />
            {isOnBoard ? (
              ''
            ) : (
              <span className="text-violet fs-18 fw-medium">
                To load vessel systems/subsystems along with their corresponding jobs, please select
                one or <br /> more vessels from the vessel filter located at the top left corner of
                this page.
              </span>
            )}
          </span>
        )
      }
      pageIcon={jobs}
    />
  );
};

export default TableEmptyPage;
