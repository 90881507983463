import { holdVentilation } from 'common/utils/fixed';
import HoldsHatches from 'common/components/vessels/profile/tabs/info/components/cargo-gear-holds/HoldsHatches';
import HoldsHatchesList from 'common/components/vessels/profile/tabs/info/components/cargo-gear-holds/HoldsHatchesList';

const cargoGearsAndHolds = [
  {
    fields: [
      {
        label: 'CARGO GEAR FITTED',
        key: 'cargo_gear',
        type: 'boolean',
        inputProps: {
          size: 'sm'
        }
      },
      {
        label: 'CARGO CRANES TYPE',
        key: 'cargo_gear_type',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'CARGO CRANES MAKER',
        key: 'cargo_gear_maker',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'NUMBER OF CRANES',
        key: 'cranes_count',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'CRANES SWL (mt)',
        key: 'cranes_swl',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'CRANE OUTREACH (m)',
        key: 'cranes_outreach',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'CRANES DESCRIPTION',
        key: 'cranes_description',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'NO. OF GRABS',
        key: 'grabs_count',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'GRABS TYPE',
        key: 'grabs_type',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'GRABS CAPACITY - MAX (m³)',
        key: 'grabs_capacity_max',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'GRABS CAPACITY - MIN (m³)',
        key: 'grabs_capacity_min',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      },
      {
        label: 'MAX CARGO SPECIFIC GRAVITY (mt/m³)',
        key: 'grabs_max_cargo_specific_gravity',
        type: 'number',
        condition: profile => profile.cargo_gear,
        formCondition: fields => fields.cargo_gear.value
      }
    ]
  },
  {
    customComponent: HoldsHatches,
    fields: [
      {
        header: 'CARGO HOLDS',
        extraData: [
          {
            label: 'NO. OF HOLDS',
            key: 'holds_number',
            type: 'number'
          },
          {
            label: 'TOTAL GRAIN CAPACITY (m³)',
            key: 'cargo_grain_capacity',
            type: 'number'
          },
          {
            label: 'TOTAL BALE CAPACITY (m³)',
            key: 'cargo_bale_capacity',
            type: 'number'
          },
          {
            label: 'HOLD VENTILATION',
            key: 'hold_ventilation',
            select_key: 'hold_ventilation',
            type: 'select',
            inputProps: {
              placeholder: 'Select',
              options: holdVentilation,
              isClearable: true
            },
            render: (profile, field) =>
              profile[field.key] ? profile[field.key].toUpperCase() : profile[field.key]
          },
          {
            label: 'AIR CHANGES PER HOUR',
            key: 'cycles_per_hour',
            type: 'number',
            formCondition: fields => fields.hold_ventilation.value === 'electric',
            condition: profile => profile.hold_ventilation === 'electric'
          },
          {
            label: 'CO2 FITTED',
            key: 'co2_fitted',
            type: 'boolean',
            inputProps: {
              size: 'sm'
            }
          },
          {
            label: 'SMOKE DETECTION FITTED',
            key: 'smoke_detection_fitted',
            type: 'boolean',
            inputProps: {
              size: 'sm'
            }
          },
          {
            label: 'A60 BULKHEAD FITTED',
            key: 'a60_bulkhead_fitted',
            type: 'boolean',
            inputProps: {
              size: 'sm'
            }
          },
          {
            label: 'AUSTRALIAN HOLD LADDERS',
            key: 'australian_hold_ladders',
            type: 'boolean',
            inputProps: {
              size: 'sm'
            }
          }
        ]
      },
      {
        header: 'CARGO HATCHES',
        extraData: [
          {
            label: 'NO. OF HATCHES',
            key: 'hatch_covers_number',
            type: 'number'
          },
          { label: 'TYPE OF COVERS', key: 'hatch_type' },
          {
            label: 'CEMENT / GRAIN  HOLES FITTED',
            key: 'hatch_covers_holes_fitted',
            type: 'boolean',
            inputProps: {
              size: 'sm'
            }
          }
        ]
      }
    ]
  },
  {
    customComponent: HoldsHatchesList,
    fields: [
      {
        key: 'holds',
        type: 'multiple',
        label: 'HOLD',
        component: null,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            label: 'HOLD NO',
            key: 'hold_no',
            type: 'number'
            // render: (profile, field) => _get(profile, field.key, 0) + 1
          },
          {
            label: 'LENGTH (m)',
            key: 'length',
            type: 'number'
          },
          {
            label: 'WIDTH (m)',
            key: 'width',
            type: 'number'
          },
          {
            label: 'HEIGHT (m)',
            key: 'depth',
            type: 'number'
          },
          {
            label: 'CAPACITY GRAIN (m³)',
            key: 'capacity_grain',
            type: 'number'
          },
          {
            label: 'CAPACITY BALE (m³)',
            key: 'capacity_bale',
            type: 'number'
          },
          { label: 'TANK TOP UNIFORM STRENGTH (mt/m²)', key: 'strength', type: 'number' },
          {
            label: 'MAXIMUM INTAKE (mt)',
            key: 'maximum_intake',
            type: 'number'
          }
        ]
      },
      {
        type: 'multiple',
        key: 'hatches',
        label: 'HATCH',
        component: null,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            label: 'HATCH',
            key: 'hatch_no',
            type: 'number'
          },
          {
            label: 'LENGTH (m)',
            key: 'length',
            type: 'number'
          },
          {
            label: 'WIDTH (m)',
            key: 'width',
            type: 'number'
          },
          { label: 'HATCH COVER STRENGTH  (mt / m²)', key: 'strength', type: 'number' }
        ]
      }
    ]
  }
];

export default cargoGearsAndHolds;

// export default {
//   actions: [],
//   sections: [
//     {
//       header: { label: 'CLEAR DECK' },
//       condition: profile => profile.deck_cargo_approved,
//       groups: [
//         [{ label: 'LENGTH (m)', key: 'clear_deck_length', type: 'number' }],
//         [{ label: 'BREATH (m)', key: 'clear_deck_breadth', type: 'number' }],
//         [{ label: 'AREA (m²)', key: 'clear_deck_area', type: 'number' }],
//         [{ label: 'STRENGTH (mt/m²)', key: 'clear_deck_strength', type: 'number' }],
//         [
//           {
//             label: 'Approved for deck cargo',
//             key: 'deck_cargo_approved',
//             type: 'boolean',
//             render: false
//           }
//         ]
//       ]
//     }
//   ]
// };
