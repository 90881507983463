import { Button } from 'reactstrap';
import { useState, useCallback } from 'react';
import SvgRender from 'common/components/general/SvgRender';

import send from 'common/assets/svg/actions/send.svg';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const PeriodButtonSubmitToOffice = ({ updatePeriodStatus, latestPeriod, isForCorrections }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  const message = isForCorrections ? 'Submit MGA to Office' : 'Submit Draft MGA to Office';

  return (
    <>
      <Button
        type="button"
        color="primary"
        className="d-inline-flex align-items-center px-2 py-1 border-0"
        onClick={openModal}
      >
        <SvgRender src={send} style={{ width: 14, height: 14 }} />
        <span className="fw-medium lh-1 ps-1">{message}</span>
      </Button>

      <DangerousActionModal
        transparent
        action={'send'}
        onAccept={() => updatePeriodStatus(latestPeriod?.id, 'for-review')}
        actionHoverColor="primary"
        closeModal={closeModal}
        isOpen={isModalOpen}
        actionText={'SUBMIT'}
        header={message}
        body={`Are you sure you want to submit this ${
          isForCorrections ? '' : 'Draft'
        } MGA Period to Office? Editing from onboard will be locked.`}
      />
    </>
  );
};

export default PeriodButtonSubmitToOffice;
