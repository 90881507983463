import React, { useState, useEffect } from 'react';

import { Row, Col } from 'reactstrap';
import { useTable } from 'common/components/table';
import useRouter from 'use-react-router';
import { parseQueryParams } from 'common/utils/urls';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import ColoredTable from 'common/components/colored-table';
import PageTitle from 'common/components/general/PageTitle';
import PageLoader from 'common/components/general/PageLoader';
import EmptyPage from 'common/components/general/EmptyPage';

import Status from 'manuals/manuals-table/Status';
import ManualVersionLink from 'manuals/components/ManualVersionLink';
import Versions from 'manuals/manuals-table/Versions';
import LastVersionDate from 'manuals/manuals-table/LastVersionDate';
import ApprovedBy from 'manuals/components/ApprovedBy';
import VersionActions from 'manuals/components/VersionActions';
import NavTabs from 'manuals/components/NavTabs';
import manuals from 'common/assets/svg/common/manuals.svg';

import { useActions } from 'utils/hooks';
import { getLastVersion } from 'manuals/_helpers';
import moment from 'moment';

import * as vesselManualsActions from 'store/manuals/actions';

const Table = ({ table, past, onFilesDownload }) => {
  const tableProps = {
    ...table,
    columns: [
      {
        header: past ? 'Expired Manuals' : 'Manuals',
        key: 'title',
        type: 'string',
        width: 3
      },
      ...table.columns
    ],
    state: {
      ...table.state,
      data: table.state.data.filter(data => {
        const expired = moment(getLastVersion(data.versions)?.valid_until, 'YYYY-MM-DD');

        return past
          ? expired.isBefore(moment())
          : expired._isValid
          ? expired.isSameOrAfter(moment())
          : data;
      })
    }
  };

  const currentTableProps = !past ? {} : { hideTableSearch: true };

  if (past && !tableProps.state.data.length) return null;

  return (
    <>
      <ColoredTable
        hideTopPagination={true}
        className={`${past ? 'mt-4' : ''}`}
        collapsableRows={data =>
          data.versions.length > 1 ? <Versions manualId={data.id} versions={data.versions} /> : null
        }
        rows={{
          title: data => {
            const lastVersion = getLastVersion(data.versions);

            return (
              <div className="flex-1 d-flex align-items-center pe-1">
                <ManualVersionLink
                  manualId={data.id}
                  versionId={lastVersion.status === 'active' ? '' : lastVersion.id}
                >
                  {data.title}
                </ManualVersionLink>
              </div>
            );
          },
          status: data => <Status data={data} />,
          valid_from: data => <LastVersionDate dateKey={'valid_from'} versions={data.versions} />,
          valid_until: data => <LastVersionDate dateKey={'valid_until'} versions={data.versions} />,
          approved_by: data => {
            const lastVersion = getLastVersion(data.versions);

            return (
              <div className="d-flex justify-content-between align-items-center">
                <ApprovedBy version={lastVersion} />
                <VersionActions manualId={data.id} version={lastVersion} />
              </div>
            );
          }
        }}
        {...tableProps}
        {...currentTableProps}
      />
    </>
  );
};

const ManualsList = () => {
  const [hasSearch, setHasSearch] = useState(false);

  const [downloadManualUploadedFiles] = useActions([
    vesselManualsActions.downloadManualUploadedFiles
  ]);

  const { history, match } = useRouter();

  const table = useTable({
    label: 'manuals',
    defaultRequestParams: { visible: false, paging: false, sorting: false, filters: false },
    urlRefetchLevel: 3,
    columns: [
      {
        header: 'Status',
        key: 'status',
        type: 'string',
        width: 2
      },
      {
        header: 'Valid From',
        key: 'valid_from',
        type: 'date',
        width: 2
      },
      {
        header: 'Expiration',
        key: 'valid_until',
        type: 'date',
        width: 2
      },
      {
        header: 'Approved by',
        key: 'approved_by',
        type: 'string'
      }
    ]
  });

  useEffect(() => {
    const { searchText } = parseQueryParams(history.location.search);

    if (searchText) {
      setHasSearch(true);
    } else {
      setHasSearch(false);
    }
  }, [history.location.search]);

  useUpdateEffect(() => {
    if (match.params.id) {
      table.refetchData();
    }
  }, [match.params.id]);

  return (
    <div className="manuals-list">
      <Row className="align-items-center manuals-list__header">
        <Col xs="auto">
          <PageTitle
            className="mb-0"
            render={() => (
              <div className="d-flex align-items-center">
                <NavTabs />

                <div className="border-start ps-3">Manuals</div>
              </div>
            )}
          />
        </Col>
      </Row>

      {!table.fetching && !table.state.data.length ? (
        <EmptyPage
          pageText={hasSearch ? 'No manuals found' : 'There are no manuals on vessel yet.'}
          pageIcon={manuals}
        />
      ) : null}

      <div className="mt-5">
        <div className={!table.state.data.length ? 'd-none' : ''}>
          <Table table={table} onFilesDownload={downloadManualUploadedFiles} />
          <Table table={table} onFilesDownload={downloadManualUploadedFiles} past />
        </div>
      </div>

      <PageLoader isLoading={table.fetching} />
    </div>
  );
};

export default ManualsList;
