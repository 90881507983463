import React, { useState } from 'react';
import PreviewLinkedJob from 'common/components/jobs/_base/components/PreviewLinkedJob';

import { selectEventId } from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { deleteJob } from 'events/store/event-modules/jobs/actions';

const LinkedJob = ({ linkedJob, toggleJobDrawer, isViewOnly }) => {
  const [deleting, setDeleting] = useState(false);

  const eventId = useSelector(selectEventId);

  const dispatch = useDispatch();

  const onView = () => toggleJobDrawer(false, linkedJob?.job);

  const onDelete = async () => {
    if (deleting) return;

    try {
      setDeleting(true);

      await dispatch(deleteJob({ id: eventId, previousJobId: linkedJob.id })).unwrap();
      setDeleting(false);
    } catch (e) {
      setDeleting(false);
    }
  };

  return (
    <PreviewLinkedJob
      job={linkedJob?.job}
      className="mb-1"
      onView={!isViewOnly && onView}
      onDelete={!isViewOnly && onDelete}
    />
  );
};

export default LinkedJob;
