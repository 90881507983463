import React from 'react';
import Select from 'common/components/form/inputs/Select';
import FieldLabel from 'captain-reports/templates/components/FieldLabel';

import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import { useSelector } from 'react-redux';
import { isReportLocked } from 'captain-reports/store/selectors';
import useInitializeFromPreviousReport from 'captain-reports/utils/useInitializeFromPreviousReport';
import * as reportActions from 'captain-reports/store/actions';
import useError from 'captain-reports/utils/useError';

const SelectField = ({
  field,
  tooltip,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const isLocked = useSelector(isReportLocked);

  useInitializeFromPreviousReport({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const onChange = value => {
    setValue({ key: field.key, value, sectionLabel, sectionActiveTab, subGroup, subGroupIndex });
  };

  const { selectRest = {} } = field;

  return (
    <div className="select-input">
      <Select
        label={<FieldLabel info={tooltip} label={field.label} subLabel={field.subLabel} />}
        placeholder=""
        value={value}
        options={field.options}
        onChange={onChange}
        isDisabled={isLocked}
        error={error}
        {...selectRest}
      />
    </div>
  );
};

export default SelectField;
