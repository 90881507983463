import MainLayout from 'common/components/vessels/profile/tabs/info/layout/MainLayout';
import _general from './sections/_general';
import _dimensionsAndLoadlines from './sections/_dimensions-loadlines';
import _tonageCapacities from './sections/_tonnage-capacities';
import _cargoGearHolds from './sections/_cargo-gear-holds';
import _generators from './sections/_generators';
import _mePropulsion from './sections/_me-propulsion';
import _auxiliaryMachinery from './sections/_auxiliary-machinery';
import _ballastTanks from './sections/_ballast-tanks';
import _fuelTanks from './sections/_fuel-tanks';

export const infoTabConfig = [
  {
    label: 'GENERAL',
    path: 'general',
    component: MainLayout,
    componentProps: {
      config: _general
    }
  },
  {
    label: 'DIMENSIONS & LOADLINES',
    path: 'dimensions-loadlines',
    component: MainLayout,
    componentProps: {
      config: _dimensionsAndLoadlines
    }
  },
  {
    label: 'TONNAGE & CAPACITIES',
    path: 'tonnage-capacities',
    component: MainLayout,
    componentProps: {
      config: _tonageCapacities
    }
  },
  {
    label: 'CARGO GEAR & HOLDS',
    path: 'cargo-gear-holds',
    component: MainLayout,
    componentProps: {
      config: _cargoGearHolds
    }
  },
  {
    label: 'ME & PROPULSION',
    path: 'me-propulsion',
    component: MainLayout,
    componentProps: {
      config: _mePropulsion
    }
  },
  {
    label: 'GENERATORS',
    path: 'generators',
    component: MainLayout,
    componentProps: {
      config: _generators
    }
  },
  {
    label: 'AUXILIARY MACHINERY',
    path: 'auxiliary-machinery',
    component: MainLayout,
    componentProps: {
      config: _auxiliaryMachinery
    }
  },
  {
    label: 'BALLAST TANKS',
    path: 'ballast-tanks',
    component: MainLayout,
    componentProps: {
      config: _ballastTanks
    }
  },
  {
    label: 'FUEL TANKS',
    path: 'fuel-tanks',
    component: MainLayout,
    componentProps: {
      config: _fuelTanks
    }
  }
];
