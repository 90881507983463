import _reduce from 'lodash/reduce';
import _findKey from 'lodash/findKey';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';

export const createInitialState = (config, initialValues = {}, initialFormValues = {}) => {
  return _reduce(
    config,
    (state, fieldConfig, fieldName) => {
      let value;
      if (isValueField(config, fieldName)) {
        value = createField(fieldConfig, initialValues[fieldName], initialFormValues);
      } else {
        if (initialValues[fieldName]) {
          value = initialValues[fieldName].map(item =>
            createInitialState(fieldConfig, item, initialFormValues)
          );
        } else {
          value = [createInitialState(fieldConfig, initialValues[fieldName], initialFormValues)];
        }
      }
      return {
        ...state,
        [fieldName]: value
      };
    },
    {}
  );
};

const isValueField = (config, fieldName) => typeof config[fieldName].initialValue !== 'undefined';

const createField = (fieldConfig, initialValue, initialFormValues) => {
  const isDefined = typeof initialValue !== 'undefined';
  let value = isDefined ? initialValue : fieldConfig.initialValue;

  if (fieldConfig.transformOnLoad) {
    value = fieldConfig.transformOnLoad(value, initialFormValues);
  }
  return {
    value,
    error: null
  };
};

export const collectValues = (formState, config, parentFormState) => {
  return _reduce(
    formState,
    (values, fieldContent, fieldName) => {
      let value;

      if (isValueField(config, fieldName)) {
        value = collectFieldValue(fieldContent, config[fieldName], parentFormState);
      } else {
        // Subfields
        value = fieldContent.map(subState =>
          collectValues(subState, config[fieldName], parentFormState)
        );
      }
      return {
        ...values,
        [fieldName]: value
      };
    },
    {}
  );
};

const collectFieldValue = (fieldContent, fieldConfig, formState) =>
  fieldConfig.transformOnSubmit
    ? fieldConfig.transformOnSubmit(fieldContent.value, formState)
    : fieldContent.value;

export const stateHasErrors = state => {
  return _reduce(
    state,
    (hasErrors, fieldContent, fieldKey) => {
      let valueHasErrors = false;
      if (typeof fieldContent.value === 'undefined') {
        if (!_isArray(fieldContent)) {
          console.error(`Invalid stateHasErrors ${fieldKey}`, fieldContent);
          return hasErrors;
        }

        const arrayErrors = fieldContent?.map(subState => stateHasErrors(subState));
        valueHasErrors = arrayErrors.reduce(
          (hasError, value) => (value === true ? true : hasError),
          false
        );
      } else {
        valueHasErrors = !!fieldContent.error;
      }

      return valueHasErrors ? true : hasErrors;
    },
    false
  );
};

export const setValidationErrors = (errors, setState) => {
  if (errors && _isObject(errors)) {
    Object.entries(errors).forEach(([path, message]) => {
      setState(prevState => createStateWithError(prevState, path.split('.'), message));
    });
  }
};

const createStateWithError = (state, path, message) => {
  const [field, index, ...restPath] = path;
  let errorFieldKey = field;

  if (!state[errorFieldKey]) {
    // ex. Case where the errorFieldKey is 'rank_id' / 'vessel_ids', but we keep in state the 'rank' / 'vessels' field
    const fullObjectKey = _findKey(
      state,
      (_, key) =>
        `${key}_id` === errorFieldKey || // singular
        (key.endsWith('s') && `${key.slice(0, -1)}_ids` === errorFieldKey) // plural
    );

    if (fullObjectKey) errorFieldKey = fullObjectKey;
  }

  return _reduce(
    state,
    (state, content, fieldName) => {
      if (fieldName !== errorFieldKey) return { ...state, [fieldName]: content };

      let newContent;
      if (index) {
        content[index] = createStateWithError(content[index], restPath, message);
        newContent = [...content];
      } else {
        newContent = { ...content, error: message };
      }
      return {
        ...state,
        [fieldName]: newContent
      };
    },
    {}
  );
};

export const hasMissingRequiredFields = (state, config) => {
  return _reduce(
    state,
    (hasErrors, content, fieldName) => {
      if (isValueField(config, fieldName)) {
        if (config[fieldName].isRequired && !content.value && content.value !== false) {
          return true;
        } else {
          return hasErrors;
        }
      } else {
        const subFormHasErrors = content.reduce((subFormHasErrors, subState) => {
          const hasErrors = hasMissingRequiredFields(subState, config[fieldName]);
          return hasErrors ? true : subFormHasErrors;
        }, false);
        return subFormHasErrors ? true : hasErrors;
      }
    },
    false
  );
};

export const validateRequiredFields = (setState, config, state) =>
  setState(prevState => setRequiredFieldErrors(prevState, config));

const setRequiredFieldErrors = (state, config) => {
  return _reduce(
    state,
    (state, content, fieldName) => {
      let newContent = { ...content };
      if (isValueField(config, fieldName)) {
        if (config[fieldName].isRequired && !content.value) {
          newContent.error = REQUIRED_FIELD_MESSAGE;
        }
      } else {
        newContent = content.map(subState => setRequiredFieldErrors(subState, config[fieldName]));
      }
      return {
        ...state,
        [fieldName]: newContent
      };
    },
    {}
  );
};
