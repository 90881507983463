import TYPES from './types';

import { get, post, put, download, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';

export const downloadLatestPDF = () => dispatch => {
  dispatch({ type: TYPES.DOWNLOAD_LATEST_PDF.START });

  return download(`/crew-working-schedule/export`)
    .then(res => {
      dispatch({
        type: TYPES.DOWNLOAD_LATEST_PDF.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DOWNLOAD_LATEST_PDF.ERROR, payload: error });

      throw error;
    });
};

export const downloadOldPDF = ({ id }) => dispatch => {
  dispatch({ type: TYPES.DOWNLOAD_LATEST_PDF.START });

  return download(`/crew-working-schedule/export/${id}`)
    .then(res => {
      dispatch({
        type: TYPES.DOWNLOAD_LATEST_PDF.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.DOWNLOAD_LATEST_PDF.ERROR, payload: error });

      throw error;
    });
};

export const removeCrewMemberFromWorkingSchedule = ({ id }) => dispatch => {
  dispatch({ type: TYPES.REMOVE_SEAMAN_FROM_WORKING_SCHEDULE.START });

  return deleteRequest(`/crew-working-schedule/crew/${id}`)
    .then(res => {
      dispatch({
        type: TYPES.REMOVE_SEAMAN_FROM_WORKING_SCHEDULE.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.REMOVE_SEAMAN_FROM_WORKING_SCHEDULE.ERROR, payload: error });

      throw error;
    });
};

export const getLatestSchedule = () => dispatch => {
  dispatch({ type: TYPES.GET_LATEST_WORKING_SCHEDULE.START });

  return get(`/crew-working-schedule`)
    .then(res => {
      dispatch({
        type: TYPES.GET_LATEST_WORKING_SCHEDULE.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_LATEST_WORKING_SCHEDULE.ERROR, payload: error });

      throw error;
    });
};

export const getOldSchedule = params => dispatch => {
  const { id } = params;

  dispatch({ type: TYPES.GET_OLD_WORKING_SCHEDULE.START, payload: id });

  return get(`/crew-working-schedule/${id}`)
    .then(res => {
      dispatch({
        type: TYPES.GET_OLD_WORKING_SCHEDULE.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_OLD_WORKING_SCHEDULE.ERROR, payload: error });

      throw error;
    });
};

export const updateCrewSchedule = params => dispatch => {
  const { crewId, ...rest } = params;

  dispatch({ type: TYPES.UPDATE_CREW_WORKING_SCHEDULE.START, payload: rest });

  return put(`/crew-working-schedule/crew/${crewId}`, rest)
    .then(res => {
      dispatch({
        type: TYPES.UPDATE_CREW_WORKING_SCHEDULE.SUCCESS,
        payload: res.data
      });

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_CREW_WORKING_SCHEDULE.ERROR, payload: error });

      throw error;
    });
};

export const publishCrewSchedule = params => dispatch => {
  dispatch({ type: TYPES.PUBLISH_WORKING_SCHEDULE.START });

  return post(`/crew-working-schedule/publish`, params)
    .then(res => {
      dispatch({
        type: TYPES.PUBLISH_WORKING_SCHEDULE.SUCCESS,
        payload: res.data
      });
      dispatch(successHandler({ title: 'Success!', message: 'Published successfully' }));

      return res.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.PUBLISH_WORKING_SCHEDULE.ERROR, payload: error });

      throw error;
    });
};
