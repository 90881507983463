import { useState } from 'react';
import { Row, Col } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';

import edit from 'common/assets/svg/actions/edit-empty.svg';
import remove from 'common/assets/svg/actions/remove.svg';
import reset from 'common/assets/svg/actions/reset.svg';
import deactivate from 'common/assets/svg/actions/deactivate.svg';

import ColoredTable from 'common/components/colored-table';
import DocumentHistory from './DocumentHistory';

import { selectDocumentTypeCategories } from 'store/vessels/selectors';
import { isAuthorized } from 'utils/permissions/authorize';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ExpirationDate from '../components/ExpirationDate';
import Departments from 'common/components/general/Departments';
import permissions from 'common/utils/permissions/constants';
import DocumentRemindersTooltip from '../components/DocumentRemindersTooltip';
import { selectAccount } from 'store/account/selectors';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  archiveDocumentDocumentAction,
  changeVesselDocumentDrawerStatus,
  downloadVesselDocumentAttachments,
  getVesselDocumentAttachmentsAction
} from '@/common/components/vessels/profile/tabs/documents/store/actions';
import { DOCUMENT_TEMPLATES } from 'common/components/vessels/profile/tabs/documents/helpers';
import { formatDate } from 'common/utils/dates';
import Information from 'common/components/general/Information';
import commentsSvg from 'common/assets/svg/common/comments.svg';

const CategoriesTable = ({ vesselId, type }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const account = useAppSelector(selectAccount);
  const dispatch = useAppDispatch();

  const state = useAppSelector(state => selectDocumentTypeCategories(state, type.id));

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAttachments = async data => {
    setIsDownloading(true);
    const res = await downloadVesselDocumentAttachments({ id: data.document_id });
    setIsDownloading(false);

    return res;
  };

  const requestAttachments = async data => {
    try {
      return await dispatch(getVesselDocumentAttachmentsAction({ id: data.id })).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const isSurveyTemplate = type.template === DOCUMENT_TEMPLATES.surveys;
  const isItemCertificateTemplate = type.template === DOCUMENT_TEMPLATES.item_certificate;

  const canEdit = isOnBoard
    ? isAuthorized(account, [permissions.onboard.vessel.documents.edit])
    : isAuthorized(account, [permissions.office.vessels.documents.edit]);

  const getDocumentActions = data => {
    const options = [];

    if (canEdit) {
      options.push({
        label: 'Delete',
        icon: remove,
        preventionModal: true,
        modalProps: {
          onAccept: () =>
            dispatch(
              archiveDocumentDocumentAction({ vessel_id: vesselId, document_id: data.document_id })
            ),
          header: 'Delete',
          body: (
            <div className="text-center">
              You are about to delete this document. <br />
              Are you sure you want to continue?
            </div>
          )
        }
      });

      if (data.active) {
        options.push(
          {
            label: 'Renew',
            icon: reset,
            onClick: _ =>
              dispatch(
                changeVesselDocumentDrawerStatus({
                  drawerOpen: true,
                  drawerType: 'renew',
                  drawerActiveItem: { ...data, type }
                })
              )
          },
          {
            label: 'Edit',
            icon: edit,
            onClick: _ =>
              dispatch(
                changeVesselDocumentDrawerStatus({
                  drawerOpen: true,
                  drawerType: 'edit',
                  drawerActiveItem: { ...data, type }
                })
              )
          }
        );
      }
    }

    return options;
  };

  return (
    <Row>
      <Col xs={12}>
        <ColoredTable
          columns={[
            {
              className: 'hidden-text',
              header: 'Name',
              key: 'category_name',
              type: 'string',
              minWidth: 630,
              maxWidth: 630
            },
            {
              key: 'attachments_count',
              field: 'attachments'
            },
            {
              header: 'expiration',
              key: 'expiration_date',
              type: 'date',
              minWidth: isItemCertificateTemplate ? 160 : isSurveyTemplate ? 172 : 569,
              maxWidth: isItemCertificateTemplate ? 160 : isSurveyTemplate ? 172 : 569
            },
            {
              header: 'Serial Number',
              key: 'serial_number',
              type: 'string',
              hidden: !isItemCertificateTemplate,
              minWidth: 409,
              maxWidth: 409
            },
            {
              header: 'Range From',
              key: 'range_from',
              type: 'date',
              hidden: !isSurveyTemplate,
              minWidth: 116,
              maxWidth: 116
            },
            {
              header: 'Range To',
              key: 'range_to',
              type: 'date',
              hidden: !isSurveyTemplate,
              minWidth: 116,
              maxWidth: 116
            },
            {
              header: 'Postponed',
              key: 'postponed',
              type: 'date',
              hidden: !isSurveyTemplate,
              minWidth: 265,
              maxWidth: 265
            },
            {
              header: 'issued',
              key: 'issue_date',
              type: 'date',
              minWidth: 99,
              maxWidth: 99
            },

            {
              header: 'issued/certified by',
              key: 'issued_by',
              type: 'date',
              className: 'pe-0',
              minWidth: 212,
              maxWidth: 212
            },
            {
              header: 'Actions',
              key: 'actions',
              field: 'actions',
              className: 'text-center',
              minWidth: 81,
              maxWidth: 81
            }
          ]}
          hideTableSearch
          collapsableRows={(data, isOpen) =>
            data.has_history ? (
              <DocumentHistory
                data={data}
                isOpen={isOpen}
                template={type?.template}
                typeId={type.id}
                isDownloading={isDownloading}
                downloadAttachments={downloadAttachments}
                requestAttachments={requestAttachments}
              />
            ) : null
          }
          color="lighter-gray"
          rows={{
            category_name: data => (
              <div className="d-flex align-items-center flex-nowrap w-100p overflow-hidden">
                <div className="flex-1 overflow-hidden pe-1">
                  <TextWithTooltip className="me-2">{data.category_name}</TextWithTooltip>
                </div>
                <div>
                  <Departments values={[data?.responsible_department]} />
                </div>
              </div>
            ),
            issue_date: data =>
              data.active ? (
                <TextWithTooltip className="h-auto">
                  {formatDate(data.date, { time: true })}
                </TextWithTooltip>
              ) : null,
            expiration_date: data => <ExpirationDate date={data.expiration_date} />,
            attachments_count: data =>
              data.active && (canEdit || (!canEdit && data.attachments_count > 0)) ? (
                {
                  isDisabled: isDownloading,
                  hasAttachments: data.attachments_count > 0,
                  downloadFileName: data.category_name,
                  data,
                  downloadFiles: () => downloadAttachments(data),
                  attachFiles: () =>
                    dispatch(
                      changeVesselDocumentDrawerStatus({
                        drawerOpen: true,
                        drawerType: 'edit',
                        drawerActiveItem: { ...data, type }
                      })
                    )
                }
              ) : !data.active ? (
                <SvgRender
                  className="text-violet"
                  src={deactivate}
                  style={{ width: 16, height: 16, marginLeft: 6 }}
                />
              ) : (
                <div></div>
              ),
            issued_by: data => {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <TextWithTooltip>{data.issued_by}</TextWithTooltip>
                  </div>
                  <div className="d-flex align-items-center">
                    {data.comments ? (
                      <Information
                        target={`vessel-documents-${type.id}-${data.id}`}
                        svgIcon={commentsSvg}
                        svgStyle={{ width: 14, height: 14 }}
                        className="text-purple pe-2 cpt-2"
                        message={data.comments}
                        tooltipClassname="cpx-6"
                        tooltipOffset={[0, 9]}
                      />
                    ) : null}
                    <DocumentRemindersTooltip reminders={data?.reminders} />
                  </div>
                </div>
              );
            },
            actions: data => ({ options: getDocumentActions(data) })
          }}
          state={state}
        />
      </Col>
    </Row>
  );
};

export default CategoriesTable;
