import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Row, Col } from 'reactstrap';
import { Sidebar, Main } from 'crew/components/profile-layout';

import BasicInfo from './sidebar/BasicInfo';
import Views from './sidebar/Views';
import Totals from './sidebar/Totals';
import Logs from './sidebar/Logs';
import PageTitle from 'common/components/general/PageTitle';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import { useAppSelector } from '@/store/hooks';
import { selectCrewProfileDefaultView } from '@/store/settings/selectors.ts';
import { useHistory } from 'react-router-dom';

const CrewProfile = ({
  activeKey,
  active,
  pageTitle,
  pageActions,
  views,
  components = {},
  isOnboard,
  crewActions
}) => {
  const [customTitle, setCustomTitle] = useState(null);
  const { match } = useRouter();
  const history = useHistory();

  const crewProfileDefaultView = useAppSelector(selectCrewProfileDefaultView);

  const isOnCrewProfileRootPath = match.path === `${paths.crew}/:id`;

  useEffect(() => {
    if (crewProfileDefaultView === 'personal_main_info' && isOnCrewProfileRootPath) {
      history.push(`${paths.crew}/${match.params.id}/personal`);
    } else if (crewProfileDefaultView === 'contracts_detailed' && isOnCrewProfileRootPath) {
      history.push(`${paths.crew}/${match.params.id}/contracts`);
    } else if (crewProfileDefaultView === 'contracts_list' && isOnCrewProfileRootPath) {
      history.push(`${paths.crew}/${match.params.id}/contracts/list`);
    }
  }, []);

  return (
    <>
      <Sidebar
        className={`crew-sidebar ${isOnboard ? 'on-board' : ''}`}
        sections={[
          { component: <BasicInfo components={components} crewActions={crewActions} /> },
          { component: <Views views={views} isOnboard={isOnboard} /> },
          {
            component: <Totals components={components} isOnboard={isOnboard} />,
            className: 'flex-1'
          },
          { component: <Logs isOnboard={isOnboard} /> }
        ]}
      />

      <Main withSidebar>
        <div className={`crew-profile position-relative ${isOnboard ? 'on-board' : ''}`}>
          <Row className="align-items-center mb-3">
            <Col className="fs-16 w-100p">
              <SwitchTransition>
                <CSSTransition
                  key={activeKey}
                  addEndListener={(node, done) =>
                    node.addEventListener('transitionend', done, false)
                  }
                  classNames="crew-profile__view"
                >
                  <PageTitle className="mb-0" render={() => customTitle || pageTitle} />
                </CSSTransition>
              </SwitchTransition>
            </Col>
            {pageActions ? (
              <Col xs="auto" className="d-flex justify-content-end pe-7 actions-wrap">
                {pageActions}
              </Col>
            ) : null}
          </Row>

          <SwitchTransition>
            <CSSTransition
              key={activeKey}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames="crew-profile__view"
            >
              <active.component
                isOnboard={isOnboard}
                setCustomTitle={setCustomTitle}
                components={components}
              />
            </CSSTransition>
          </SwitchTransition>
        </div>
      </Main>
    </>
  );
};

export default CrewProfile;
