import SvgRender from 'common/components/general/SvgRender';

import draft from 'common/assets/svg/common/draft.svg';
import submitted from 'common/assets/svg/common/pending.svg';
import for_correction from 'common/assets/svg/common/warning-empty.svg';

export const icons = {
  draft,
  submitted,
  for_correction
};

export const colors = {
  draft: 'violet',
  submitted: 'orange',
  for_correction: 'red'
};

const RequisitionOnboardStatus = ({ status }) => {
  return status ? (
    <div className={`d-inline-flex align-items-center text-${colors[status?.label] || 'primary'}`}>
      <SvgRender src={icons[status?.label]} style={{ width: 16, height: 16 }} className="me-2" />
      <span className="lh-1 text-capitalize">{status?.label.split('_').join(' ')}</span>
    </div>
  ) : null;
};

export default RequisitionOnboardStatus;
