import TYPES from './types';
import COMMON_TYPES from 'store/voyages/common/types';
import PORT_STATEMENT_TYPES from 'captain-reports/store/port-statement/types';
import { LOCATION_CHANGE } from 'connected-react-router';

import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import _findLastIndex from 'lodash/findLastIndex';

import {
  getItineraryPortActions,
  getItineraryPurchaseOrders,
  getItineraryEvents
} from 'common/utils/voyages/helpers';

const INITIAL_STATE = {
  activeForm: { isOpen: false, type: '', entity: null, port: '' },

  itineraries: [], // [ITINERARY_ID]
  itinerary: {}, // { ITINERARY_ID: {} }
  itineraryActions: {}, // { ITINERARY_ID: [] } //
  itineraryTrip: {}, // { ITINERARY_ID: {} }
  itinerariesPaging: { current_page: 1, per_page: 20 },
  itineraryPurchaseOrders: {}, // { ITINERARY_ID: [] } //
  itineraryEvents: {}, // { ITINERARY_ID: [] } //

  portStatement: {}, // { PORT_STATEMENT_ID: { ... port_statement } }
  deliveryReport: {}, // { DELIVERY_REPORT_ID: { ... delivery_report } }
  redeliveryReport: {}, // { REDELIVERY_REPORT_ID: { ... redelivery_report } }

  isLoading: false,

  matchItineraryModal: null,

  reorderModeEnabled: false,

  firstArrivalReport: null
};

const reducer = (state = _cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case COMMON_TYPES.SET_ACTIVE_VESSEL_VOYAGE:
    case COMMON_TYPES.SET_ACTIVE_VESSEL_CHARTER_PARTY:
      return {
        ..._cloneDeep(INITIAL_STATE),
        isLoading: true
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.endsWith('itinerary')) {
        return { ..._cloneDeep(INITIAL_STATE) };
      } else {
        return state;
      }

    case TYPES.GET_ITINERARIES_SCHEDULED_PORTS.START:
      return {
        ...state,
        isLoading: true
      };

    case TYPES.GET_ITINERARIES_REAL_PORTS.START:
      return {
        ...state,
        isLoading: true,
        itinerariesPaging: { ...state.itinerariesPaging, ...payload.params.paging }
      };

    case TYPES.GET_ITINERARIES_REAL_PORTS.SUCCESS:
    case TYPES.GET_ITINERARIES_SCHEDULED_PORTS.SUCCESS:
      const data = payload?.data || payload;
      const meta = payload?.meta;

      const itinerariesState = data.reduce(
        (acc, cur) => {
          const { actions, port_statement, delivery_report, redelivery_report, trip, id, ...rest } =
            cur;

          if (!acc.itineraries.includes(id)) acc.itineraries.push(id);
          acc.itinerary[id] = { ...rest, id, port_statement_id: port_statement?.id };

          if (actions?.length || port_statement)
            acc.itineraryActions[id] = getItineraryPortActions(actions, {
              ...rest,
              port_statement,
              delivery_report,
              redelivery_report
            });

          if (port_statement) acc.portStatement[port_statement?.id] = port_statement;
          if (delivery_report) acc.deliveryReport[rest?.delivery_report_id] = delivery_report;
          if (redelivery_report)
            acc.redeliveryReport[rest?.redelivery_report_id] = redelivery_report;
          if (trip) acc.itineraryTrip[id] = trip;

          return acc;
        },
        {
          itineraries: state.itineraries,
          itinerary: state.itinerary,
          itineraryActions: state.itineraryActions,
          portStatement: state.portStatement,
          deliveryReport: state.deliveryReport,
          redeliveryReport: state.redeliveryReport,
          itineraryTrip: state.itineraryTrip
        }
      );

      return {
        ...state,
        ...itinerariesState,
        itinerariesPaging: meta
          ? { ...state.itinerariesPaging, total: meta.total, last_page: meta.last_page }
          : state.itinerariesPaging,
        isLoading: false
      };

    case TYPES.GET_ITINERARIES_REAL_PORTS.ERROR:
    case TYPES.GET_ITINERARIES_SCHEDULED_PORTS.ERROR:
      return {
        ...state,
        isLoading: false
      };

    case TYPES.GET_ITINERARY.SUCCESS:
    case TYPES.CREATE_ITINERARY.SUCCESS:
    case TYPES.EDIT_ITINERARY.SUCCESS:
      if (payload) {
        const {
          port_statement,
          delivery_report,
          redelivery_report,
          trip,
          id,
          actions,
          purchasing_requisition_suppliers,
          events,
          ...rest
        } = payload;

        const updatedItinerary = { [id]: { ...state.itinerary[id], ...rest, loaded: true } };
        const updatedTrip = trip ? { [id]: { ...state.itineraryTrip[id], ...trip } } : {};
        const updatedItineraryActions = {
          [id]: getItineraryPortActions(actions, {
            ...rest,
            port_statement,
            delivery_report,
            redelivery_report
          })
        };
        const updatedPortStatement = port_statement
          ? {
              [port_statement?.id]: {
                ...state.portStatement[port_statement?.id],
                ...port_statement
              }
            }
          : {};
        const updatedDelivery = delivery_report
          ? {
              [rest?.delivery_report_id]: {
                ...state.deliveryReport[rest?.delivery_report_id],
                ...delivery_report
              }
            }
          : {};
        const updatedRedelivery = redelivery_report
          ? {
              [rest?.redelivery_report_id]: {
                ...state.redeliveryReport[rest?.delivery_report_id],
                ...redelivery_report
              }
            }
          : {};

        return {
          ...state,
          itineraries: !state.itineraries.includes(id)
            ? [id, ...state.itineraries]
            : state.itineraries,
          itinerary: { ...state.itinerary, ...updatedItinerary },
          itineraryTrip: { ...state.itineraryTrip, ...updatedTrip },
          itineraryActions: { ...state.itineraryActions, ...updatedItineraryActions },
          itineraryPurchaseOrders: {
            ...state.itineraryPurchaseOrders,
            ...getItineraryPurchaseOrders(id, purchasing_requisition_suppliers)
          },
          itineraryEvents: { ...state.itineraryEvents, ...getItineraryEvents(id, events) },
          portStatement: { ...state.portStatement, ...updatedPortStatement },
          deliveryReport: { ...state.deliveryReport, ...updatedDelivery },
          redeliveryReport: { ...state.redeliveryReport, ...updatedRedelivery }
        };
      }

      return state;

    case TYPES.UPDATE_ITINERARIES_VOYAGE_NUMBER:
      if (payload) {
        const currentItineraryIndex = state.itineraries.findIndex(id => payload.id === id);
        const previousItineraryId = state.itineraries?.[currentItineraryIndex + 1];

        const nextItineraryInVoyageIndex = _findLastIndex(
          state.itineraries,
          (id, index) => index < currentItineraryIndex && state.itinerary[id]?.has_voyage_no
        );

        const itinerariesInVoyage = state.itineraries.filter(
          (_, index) => index <= currentItineraryIndex && index > nextItineraryInVoyageIndex
        );

        const updatedItinerary = {};
        const newVoyageNo =
          !payload.voyage_no && state.itinerary?.[previousItineraryId]?.voyage_no
            ? state.itinerary?.[previousItineraryId]?.voyage_no
            : payload.voyage_no;

        itinerariesInVoyage.forEach(id => {
          updatedItinerary[id] = { ...state.itinerary[id], voyage_no: newVoyageNo };
        });

        return {
          ...state,
          itinerary: { ...state.itinerary, ...updatedItinerary }
        };
      }

      return state;

    case TYPES.SET_ACTIVE_FORM_PORT:
      return {
        ...state,
        activeForm: { ...state.activeForm, port: payload }
      };

    case TYPES.SET_ACTIVE_ITINERARY_FORM:
      return {
        ...state,
        activeForm: payload ? payload : { isOpen: false, type: '', entity: null }
      };

    case TYPES.MATCH_ITINERARY_PORT.START:
      return {
        ...state,
        matchItineraryModal: null
      };

    case TYPES.MATCH_ITINERARY_PORT.SUCCESS:
      return {
        ...state,
        matchItineraryModal: payload
      };

    case TYPES.SET_ITINERARIES_REORDER_MODE:
      return {
        ...state,
        reorderModeEnabled: payload
      };

    case TYPES.REORDER_ITINERARIES_SCHEDULED_PORTS.SUCCESS:
      const reorderedScheduled = _orderBy(payload || [], ['sort_index'], ['desc']).map(i => i.id);

      return {
        ...state,
        itineraries: [
          ...reorderedScheduled,
          ...state.itineraries.filter(i => !reorderedScheduled.includes(i))
        ]
      };

    case TYPES.DELETE_SCHEDULED_ITINERARY.SUCCESS:
      return {
        ...state,
        itineraries: state.itineraries.filter(i => i !== payload.id)
      };

    case PORT_STATEMENT_TYPES.UPDATE_PORT_STATEMENT_ACTION.SUCCESS:
      if (payload?.params?.updateStateWithNew && payload?.data?.port_statement) {
        const portStatementId = payload?.data?.port_statement?.id;
        const portStatementActionId = payload?.data?.id;

        return {
          ...state,
          portStatement: {
            ...state.portStatement,
            [portStatementId]: {
              ...state.portStatement[portStatementId],
              detailed_actions: (state.portStatement[portStatementId]?.detailed_actions || []).map(
                d =>
                  d.id === parseInt(portStatementActionId)
                    ? {
                        ...d,
                        ...payload?.params?.updateStateWithNew
                      }
                    : d
              )
            }
          }
        };
      }

      return state;

    case `${TYPES.GET_ITINERARIES_PORTS_FIRST_ARRIVAL_REPORT}/pending`:
      return {
        ...state,
        firstArrivalReport: null
      };

    case `${TYPES.GET_ITINERARIES_PORTS_FIRST_ARRIVAL_REPORT}/fulfilled`:
      return {
        ...state,
        firstArrivalReport: payload
      };

    default:
      return state;
  }
};

export default reducer;
