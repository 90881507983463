import React from 'react';

import CircledButton from 'common/components/buttons/CircledButton';

import { useSelector } from 'react-redux';
import { selectActiveShiftId, selectIsLoading } from 'crew/store/shifts/selectors';

import { useActions } from 'utils/hooks';
import * as shiftActions from 'crew/store/shifts/actions';

const TopRightButtons = ({ updateShift, initTable, id, dates }) => {
  const activeShiftId = useSelector(selectActiveShiftId);
  const isLoading = useSelector(selectIsLoading);

  const [clearInternalTable, setActiveShiftId] = useActions([
    shiftActions.clearInternalTable,
    shiftActions.setActiveShiftId
  ]);

  const onSave = async () => {
    await updateShift(dates);
    setActiveShiftId(null);
  };

  const onCancel = () => {
    initTable();
    clearInternalTable();
    setActiveShiftId(null);
  };

  const isEditing = activeShiftId === id;

  return (
    <div className="shifts-card--buttons">
      {isEditing ? (
        <div className="d-flex flex-column">
          <CircledButton type="save" onClick={onSave} disabled={isLoading} />
          <CircledButton onClick={onCancel} type="close" className="mt-1" disabled={isLoading} />
        </div>
      ) : !activeShiftId ? (
        <>
          <CircledButton
            onClick={() => setActiveShiftId(id)}
            svgStyle={{ width: 14, height: 14 }}
            type="edit"
            disabled={isLoading}
          />
        </>
      ) : null}
    </div>
  );
};

export default TopRightButtons;
