/* Bunkers & Consumptions */

import _sortBy from 'lodash/sortBy';
import _mapValues from 'lodash/mapValues';
import _groupBy from 'lodash/groupBy';
import { getFieldValue } from 'captain-reports/templates/config/_helpers';

export const createFormConsumptionsState = ({ bunkers }) => {
  const formConsumptions = [];

  (bunkers?.length ? bunkers : [])?.forEach((bunker, index) => {
    const { id, fuel_grade, consumptions, ...rest } = bunker;

    const consumptionState = {
      bunker_id: id,
      bunker_index: index,
      fuel_grade,
      ...rest
    };

    if (consumptions?.length && id) {
      consumptions?.forEach((consumption, cIndex) => {
        const id = consumption?.id?.value;

        formConsumptions.push({
          ...consumptionState,
          bunker_consumption_index: cIndex,
          id,
          ...consumption
        });
      });
    }
  });

  return _sortBy(formConsumptions, [
    ({ bunker_index }) => bunker_index,
    ({ bunker_consumption_index }) => bunker_consumption_index
  ]);
};

export const addSecondaryBunkerConsumptionColumn = ({ index }, consumptions) => {
  const bunkerConsumption = consumptions[index];
  const newBunkerConsumption = _mapValues(bunkerConsumption, (field, key) => {
    if (['bunker_id', 'bunker_index', 'fuel_grade', 'fuel_grade_id'].includes(key)) {
      return field;
    } else if (key === 'bunker_consumption_index') {
      return { ...field, value: 1 };
    } else if (
      [
        'rob',
        'rob_hfo',
        'rob_lfo',
        'correction',
        'correction_hfo',
        'correction_lfo',
        'leg_consumption_total',
        'leg_consumption_rate'
      ].includes(key)
    ) {
      /* These fields are filled only once in the FIRST Bunker Consumption column. 
        Their formState value is always 0 to avoid any Validation errors.
        We auto-calucalate the vales of the secondary Rob fields in the useAutoCalculateBunkerConsumptionSecondaryRob, and
        then we sum them in the Robs of the FIRST Bunker Consumption column.
        The correction fields have no-autocalculation logic. 
      */
      return { ...field, value: 0 };
    } else {
      return { ...field, value: null };
    }
  });

  return [
    ...consumptions.filter((_, i) => i <= index),
    newBunkerConsumption,
    ...consumptions.filter((_, i) => i > index)
  ];
};

export const removeSecondaryBunkerConsumptionColumn = ({ index }, consumptions) => {
  return consumptions.filter((_, i) => i !== index);
};

export const parseBunkersReportFields = (
  data,
  { forSectionValidation = false, forFieldsComparison = false }
) => {
  const parsedBunkers = [];

  const consumptions = getFieldValue(data.consumptions, true, forSectionValidation);
  const bunkers = _groupBy(consumptions, ({ bunker_id }) => bunker_id?.value);

  Object.keys(bunkers).forEach(bunkerId => {
    const {
      rob,
      rob_hfo,
      rob_lfo,
      correction,
      correction_hfo,
      correction_lfo,
      leg_consumption_total,
      leg_consumption_rate,
      fuel_grade_id
    } = bunkers[bunkerId][0]; // These fields of the first consumption contain the auto-calcaluted values for both consumption columns

    let data = {
      id: bunkerId,
      rob,
      rob_hfo,
      rob_lfo,
      correction,
      correction_hfo,
      correction_lfo,
      fuel_grade_id: fuel_grade_id?.value,
      consumptions: []
    };

    if (!forFieldsComparison) {
      data = { ...data, leg_consumption_total, leg_consumption_rate };
    }

    data.consumptions = bunkers[bunkerId].map(
      ({
        id,
        bunker_id,
        bunker_index,
        bunker_consumption_index,
        fuel_grade,
        fuel_grade_id,
        rob,
        rob_hfo,
        rob_lfo,
        correction,
        correction_hfo,
        correction_lfo,
        leg_consumption_total,
        leg_consumption_rate,
        ...rest
      }) => ({ id: id?.value, ...rest })
    );

    parsedBunkers.push(data);
  });

  return parsedBunkers;
};
