import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const CrewReasonsSelector = ({ filter, onChange, isMulti, params, ...rest }) => {
  const [selectedReasons, setSelectedReasons] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'crew-evaluation-reasons', listParams: params },
    { selected: selectedReasons, setSelected: setSelectedReasons }
  );

  return (
    <Select
      autoFocus
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={
        isMulti
          ? filter.value?.length
            ? selectedReasons.filter(el =>
                (isMulti ? filter.value : [filter.value]).map(Number).includes(el.id)
              )
            : []
          : filter.value
      }
      isMulti={isMulti}
      isAsync
      defaultOptions={() => getInitialAsyncValues('crew-evaluation-reasons', null, isMulti, params)}
      loadOptions={search => getAsyncOptions(search, 'crew-evaluation-reasons', params)}
      onChange={selected => {
        setSelectedReasons(isMulti ? selected : [selected]);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
      {...rest}
    />
  );
};

export default CrewReasonsSelector;
