import { FC, ReactNode } from 'react';
import PageTitle from 'common/components/general/PageTitle';
import config from '@/common/components/shared-table-configs/index.ts';

import SavedSearchesSelector from './SavedSearchesSelector';

type TitleLayoutProps = {
  title?: string | ReactNode;
  label: keyof typeof config;
};

type TitleProps = {
  pageTitle?: string | ReactNode;
  label: keyof typeof config;
};

const Title: FC<TitleProps> = ({ pageTitle, label }) => {
  return (
    <div className="page-table-layout--header-title d-flex align-items-center">
      {pageTitle}
      <SavedSearchesSelector label={label} />
    </div>
  );
};

const TitleLayout: FC<TitleLayoutProps> = ({ title, label }) => {
  return <PageTitle render={() => <Title pageTitle={title} label={label} />} />;
};

export default TitleLayout;
