import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 750 150"
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    animate={true}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
    <rect x="0" y="20" rx="5" ry="5" width="100%" height="15" />
    <rect x="0" y="40" rx="5" ry="5" width="100%" height="15" />
    <rect x="0" y="60" rx="5" ry="5" width="100%" height="15" />
    <rect x="0" y="80" rx="5" ry="5" width="100%" height="15" />
    <rect x="0" y="100" rx="5" ry="5" width="100%" height="15" />
    <rect x="0" y="120" rx="5" ry="5" width="100%" height="15" />
  </ContentLoader>
);

export default Loader;
