import { parseMgaInventoryItems } from 'common/components/mga/store/parser';

export const parse = (data, params, table) => {
  switch (table) {
    case 'mga_inventory_items_additions':
    case 'mga_inventory_items_removals':
      return parseMgaInventoryItems(data, params);

    default:
      return data;
  }
};
