import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import BasicModal from 'common/components/modals/BasicModal';
import Textarea from 'common/components/form/inputs/Textarea';
import DateInput from 'common/components/form/inputs/date';
import moment from 'moment';
import { stringField, dateField } from 'common/utils/form/fieldTypes';
import { useSelector } from 'react-redux';
import {
  selectJobDueDate,
  selectJobDueDateRemarks
} from 'common/components/jobs/_base/store/selectors';

import { useForm, useFormState, useActions } from 'utils/hooks';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

const DueDateInputModal = ({ remarksModalOpen, setRemarksModalOpen, label = 'Due Date' }) => {
  const [remarksModalIsLoading, setRemarksModalIsLoading] = useState(false);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const jobDueDate = useSelector(selectJobDueDate);
  const jobDueDateRemarks = useSelector(selectJobDueDateRemarks);

  const [setJobField, updateJob] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.updateJob
  ]);

  const config = {
    due_date: dateField({ required: true }),
    due_date_remarks: stringField({ required: true })
  };

  const { formState, collectValues, resetForm, handleSubmitError } = useForm(config, {});
  const { fields, changeField, selectField } = useFormState(formState);

  const onRemarksModalClose = () => {
    resetForm();
    setRemarksModalOpen(false);
    setRemarksModalIsLoading(false);
  };

  const onRemarksModalSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    try {
      setRemarksModalIsLoading(true);

      const { due_date, due_date_remarks } = values;

      await updateJob(
        {
          due_date: due_date ? moment(due_date) : null,
          due_date_remarks: due_date_remarks ? due_date_remarks : null
        },
        true,
        false,
        !isOnBoard
      );

      await setJobField('due_date', due_date ? moment(due_date) : null, false, false, !isOnBoard);
      await setJobField(
        'due_date_remarks',
        due_date_remarks ? due_date_remarks : null,
        false,
        false,
        !isOnBoard
      );

      setRemarksModalOpen(false);
      setRemarksModalIsLoading(false);
    } catch (error) {
      console.error(error);
      setRemarksModalIsLoading(false);
      handleSubmitError(error);
    }
  };

  useEffect(() => {
    if (remarksModalOpen) {
      setRemarksModalIsLoading(false);
      selectField('due_date')(jobDueDate || '');
      selectField('due_date_remarks')(jobDueDateRemarks || '');
    } else {
      resetForm();
    }
  }, [remarksModalOpen]);

  return (
    <BasicModal
      isOpen={remarksModalOpen}
      toggle={onRemarksModalClose}
      header={`Change ${label}`}
      body={
        <div>
          <DateInput
            label={label}
            datePlaceholder="Set Date"
            onChange={selectField('due_date')}
            {...fields.due_date}
          />

          <Textarea
            label="Remarks"
            rows={6}
            onChange={changeField('due_date_remarks')}
            className="mt-2 mb-0"
            disabled={false}
            {...fields.due_date_remarks}
          />
        </div>
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button
            color="cancel"
            className="px-0 py-1 me-4"
            onClick={onRemarksModalClose}
            disabled={remarksModalIsLoading}
          >
            CANCEL
          </Button>
          <Button
            onClick={onRemarksModalSubmit}
            disabled={remarksModalIsLoading}
            color="primary"
            className="px-4"
          >
            CHANGE
          </Button>
        </div>
      }
    />
  );
};

export default DueDateInputModal;
