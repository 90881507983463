import React, { useState } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';

import bin from 'common/assets/svg/common/bin-large.svg';
import archive from 'common/assets/svg/actions/archive.svg';
import unarchive from 'common/assets/svg/actions/unarchive.svg';
import arrows from 'common/assets/svg/actions/arrows-change.svg';
import sync from 'common/assets/svg/actions/sync.svg';
import jobsIcon from 'common/assets/svg/common/jobs.svg';
import access from 'common/assets/svg/actions/access-solid.svg';
import share from 'common/assets/svg/common/share-line.svg';
import vessel from 'common/assets/svg/common/vessels.svg';
import deactivate from 'common/assets/svg/actions/deactivate.svg';
import activate from 'common/assets/svg/actions/check.svg';
import send from 'common/assets/svg/actions/send.svg';
import checkCircle from 'common/assets/svg/common/check-circle.svg';
import save from 'common/assets/svg/actions/save_line.svg';
import xCircle from 'common/assets/svg/common/x-circle.svg';
import addition from 'common/assets/svg/manuals/change-requests/addition.svg';
import clock from 'common/assets/svg/common/reminder.svg';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import reviewProcess from 'common/assets/svg/common/review-process.svg';
import unlink from 'common/assets/svg/common/unlink.svg';

export const useDangerousActionModal = (trigers, condition = true) => {
  const [isOpen, setIsOpen] = useState(false);
  const [acceptFunc, setAcceptFunc] = useState(null);
  const preventableActions = trigers.map(triger => (...args) => {
    if (condition) {
      setAcceptFunc(() => () => triger(...args));
      setIsOpen(true);
    } else {
      triger(...args);
    }
  });

  const closeModal = () => {
    setAcceptFunc(null);
    setIsOpen(false);
  };

  return [preventableActions, { isOpen, onAccept: acceptFunc, closeModal }];
};

const DangerousActionModal = ({
  isOpen,
  header,
  body,
  actionText = 'DELETE', // The Default actionText should be 'DELETE'. Do not change it.
  cancelText,
  actionHoverColor,
  actionButtonColor,
  actionIcon,
  hideIcon,
  hideCancelButton = false,
  onAccept,
  onClose,
  closeModal,
  style,
  iconStyle,
  backdropClassName = '',
  className,
  container = 'body',
  transparent,
  action,
  extraFooterButton = null,
  footerClassName,
  closeOnSuccess = true,
  size = 'md',
  showHeader = true
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAccept = async () => {
    try {
      if (onAccept) {
        setIsSubmitting(true);
        await onAccept();
        setIsSubmitting(false);

        if (closeOnSuccess) closeModal();
      } else {
        closeModal();
      }
    } catch (e) {
      setIsSubmitting(false);
      closeModal();
    }
  };

  const handleClose = async () => {
    if (onClose) {
      setIsSubmitting(true);
      await onClose();
      setIsSubmitting(false);
    } else {
      closeModal();
    }
  };

  const renderModalIcon = () => {
    let icon = null;
    let style = iconStyle || { width: 71, height: 81 };

    switch (action) {
      case 'deactivate':
        icon = deactivate;
        style = { width: 80, height: 80, color: variables.red };
        break;
      case 'activate':
        icon = activate;
        style = { width: 80, height: 80, color: variables.turquoise };
        break;
      case 'archive':
        icon = archive;
        style = { width: 55, height: 80 };
        break;
      case 'unarchive':
        icon = unarchive;
        style = { width: 55, height: 80 };
        break;
      case 'send':
        icon = send;
        style = { width: 80, height: 80 };
        break;
      case 'change':
        icon = arrows;
        style = { width: 80, height: 80, color: variables.goldBell };
        break;
      case 'sync':
        icon = sync;
        break;
      case 'save-job':
        icon = jobsIcon;
        break;
      case 'share':
        icon = share;
        style = { ...style, color: variables.coral };
        break;
      case 'access':
        icon = access;
        style = { ...style, color: variables.coral };
        break;
      case 'vessel':
        icon = vessel;
        style = { ...style, color: variables.primary };
        break;
      case 'check-circle':
        icon = checkCircle;
        break;
      case 'save':
        icon = save;
        break;
      case 'x-circle':
        icon = xCircle;
        break;
      case 'addition':
        icon = addition;
        style = { width: 84, height: 84 };
        break;
      case 'snooze':
        icon = clock;
        style = { width: 84, height: 84, color: variables.white };
        break;
      case 'termination':
        icon = deactivate;
        style = { width: 84, height: 84, color: variables.white };
        break;
      case 'delete':
        icon = bin;
        style = { width: 84, height: 84, color: variables.white };
        break;
      case 'reviewProcess':
        icon = reviewProcess;
        style = { width: 80, height: 80 };
        break;
      case 'unlink':
        icon = unlink;
        style = { width: 80, height: 80 };
        break;
      default:
        icon = bin;
        style = { ...style, color: variables.coral };
        break;
    }

    return { src: icon, style };
  };

  return (
    <Modal
      backdrop={backdropClassName ? true : transparent ? false : true}
      centered
      container={container}
      isOpen={isOpen}
      backdropClassName={backdropClassName}
      toggle={() => {}}
      style={style}
      className={`${transparent ? `transparent-delete-modal` : className}`}
      contentClassName={`${transparent ? 'position-relative mb-10' : ''}`}
      size={size}
    >
      {transparent ? null : <ModalHeader toggle={closeModal}>{header}</ModalHeader>}
      {transparent ? (
        <>
          <ModalBody className="d-flex flex-column justify-content-center align-items-center">
            {hideIcon || !isOpen ? null : (
              <div className="mt-9">
                {actionIcon ? (
                  actionIcon
                ) : (
                  <SvgRender src={renderModalIcon().src} style={renderModalIcon().style} />
                )}
              </div>
            )}

            {showHeader ? (
              <div className="fs-28 mb-2 mt-4 fw-normal lh-1 text-center">{header}</div>
            ) : null}

            <div className="text-center fs-18 fw-light">{body}</div>
          </ModalBody>
          <div
            className={`${footerClassName ? footerClassName : 'transparent-delete-modal--buttons'}`}
          >
            {hideCancelButton ? null : (
              <div
                data-cy="cancel"
                className={`button decline${isSubmitting ? ' disabled' : ''}`}
                onClick={handleClose}
              >
                {cancelText ? cancelText : 'NO'}
              </div>
            )}
            <div
              data-cy="proceed"
              className={`button ${
                actionHoverColor && actionHoverColor !== 'delete' ? actionHoverColor : 'red'
              }${isSubmitting ? ' disabled' : ''}`}
              onClick={handleAccept}
            >
              {actionText}
            </div>
            {extraFooterButton}
          </div>
        </>
      ) : (
        <>
          <ModalBody className="fw-normal">{body}</ModalBody>
          <ModalFooter>
            <Button color="cancel" onClick={onClose ? onClose : closeModal} disabled={isSubmitting}>
              {cancelText ? cancelText : 'CANCEL'}
            </Button>
            <Button
              color={actionButtonColor || 'red'}
              className="text-uppercase"
              disabled={isSubmitting}
              onClick={handleAccept}
            >
              {actionText}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default DangerousActionModal;
