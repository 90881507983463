import { FC, useMemo, useState } from 'react';
import { getFilterSelectorValues } from '../filters/helpers';
import { TableFilterSelectorProps } from '@/common/types/front-entities/table';
import { FindingSubCategory } from '@/common/types/findings';
import _isArray from 'lodash/isArray';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import Select from '@/ts-common/components/form/inputs/select';

const FindingSubCategoriesSelector: FC<TableFilterSelectorProps> = ({
  value,
  filter,
  onChange,
  isMulti,
  listParams,
  ...rest
}) => {
  const [selected, setSelected] = useState<FindingSubCategory | FindingSubCategory[] | null>([]);

  useFilterSelector(filter?.value, { list: 'finding-subcategories' }, { selected, setSelected });

  const memoizedRequestParams = useMemo(
    () => ({
      params: { list: 'finding-subcategories', ...(listParams || {}) },
      path: 'lists'
    }),
    [listParams]
  );

  return (
    <Select
      placeholder="Select value"
      getOptionValue={option => option.id.toString()}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      value={
        value !== undefined
          ? value
          : filter?.value
          ? getFilterSelectorValues(selected, filter?.value, isMulti)
          : []
      }
      memoizedRequestParams={memoizedRequestParams}
      onChange={(s: FindingSubCategory | FindingSubCategory[] | null) => {
        setSelected(isMulti && !s ? [] : s);
        onChange(filter?.value !== undefined ? { value: _isArray(s) ? s?.map(s => s.id) : s } : s);
      }}
      {...rest}
    />
  );
};
export default FindingSubCategoriesSelector;
