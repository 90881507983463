import personal from 'common/assets/svg/crew/personal.svg';
import documents from 'common/assets/svg/common/documents.svg';
import contracts from 'common/assets/svg/crew/contracts.svg';
// import training from 'common/assets/svg/crew/training.svg';
// import payroll from 'common/assets/svg/crew/payroll.svg';
// import injury from 'common/assets/svg/crew/injury.svg';
import clock from 'common/assets/svg/common/clock-line.svg';
import star from 'common/assets/svg/common/star.svg';

import Personal from './personal';
import Documents from './documents';
import Contracts from './contracts';
import Evaluation from './evaluation';
import EvaluationTopActions from './evaluation/TopActions';
// import Training from './main/training';
// import Payroll from './main/payroll';
// import Skills from './main/skills';
// import Injury from './main/injury';
import Shifts from './shifts';
import permissions from 'common/utils/permissions/constants';

const views = [
  {
    icon: personal,
    name: 'Personal',
    label: 'personal',
    component: Personal,
    permissions: [
      permissions.onboard.crew.seaman_profile.personal.all_users.view,
      permissions.onboard.crew.seaman_profile.personal.own_profile.view
    ]
  },
  {
    icon: documents,
    name: 'Documents',
    label: 'documents',
    component: Documents,
    permissions: [
      permissions.onboard.crew.seaman_profile.documents.all_users.view,
      permissions.onboard.crew.seaman_profile.documents.own_profile.view
    ]
  },
  {
    icon: contracts,
    name: 'Contracts',
    label: 'contracts',
    component: Contracts,
    permissions: [
      permissions.onboard.crew.seaman_profile.contracts.all_users.view,
      permissions.onboard.crew.seaman_profile.contracts.own_profile.view
    ]
  },
  {
    icon: star,
    name: 'Evaluation',
    label: 'evaluation',
    component: Evaluation,
    permissions: [
      permissions.onboard.crew.seaman_profile.evaluations.all_users.view,
      permissions.onboard.crew.seaman_profile.evaluations.own_profile.view
    ],
    topActions: EvaluationTopActions
  },
  {
    icon: clock,
    name: 'Watch\nkeeping',
    label: 'shifts',
    component: Shifts,
    permissions: [permissions.onboard.crew.watchkeeping.shifts.view]
  }

  // {
  //   icon: payroll,
  //   name: 'Payroll',
  //   label: 'payroll',
  //   component: Payroll
  // },
  // {
  //   icon: training,
  //   name: 'Training',
  //   label: 'training',
  //   component: Training
  // },
  // {
  //   icon: injury,
  //   name: 'Injury',
  //   label: 'injury',
  //   component: Injury
  // },
  // {
  //   name: 'Skills',
  //   label: 'skills',
  //   component: Skills,
  //   hiddenTab: true
  // }
];

export default views;
