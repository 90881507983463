/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

export const typeSubtypeMapping = {
  training: 'crew-training-types',
  drill: 'drills'
};

const SubType = ({ type, value, error, onChange, className, disabled }) => {
  const label = type?.label?.toLowerCase();

  return type && typeSubtypeMapping?.[label] ? (
    <Select
      label={'SubType'}
      className={className}
      placeholder="Select subtype"
      dataCy="select-event-subtype"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isMulti={true}
      value={value}
      error={error}
      loadOptions={search => getAsyncOptions(search, typeSubtypeMapping?.[label])}
      defaultOptions={() => getInitialAsyncValues(typeSubtypeMapping?.[label], null, false)}
      defaultOptionsTriggerChange={type.id}
      onChange={onChange}
      isClearable={false}
      disabled={disabled}
      isAsync
      white
    />
  ) : null;
};

export default SubType;
