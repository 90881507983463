import React from 'react';

import ProfileIcon from 'common/components/chatbox/ProfileIcon';
import TruncatedText from 'common/components/general/TruncatedText';
import Body from './Body';

import { dateToUtc } from 'common/utils/dates';
import { File } from 'common/entities/files/FileTypes';

import PropTypes from 'prop-types';
import Attachments from './Attachments';

const MessageBubble = ({ timestamp, message, mentions, type, username, attachments, side }) => {
  return (
    <div className={`d-flex message-bubble flex-direction-${side}`}>
      <ProfileIcon type={type} className="mt-1" />

      <div
        className={`message-bubble__container message-bubble-${type} message-bubble-${type}--${side}`}
      >
        <div className="message-bubble-header d-flex">
          <span>
            <TruncatedText length={35} text={username || '-'} />
          </span>
          <span className="message-bubble-header__date cms-6 cme-6 fw-bold">·</span>
          <span className="message-bubble-header__date">
            {dateToUtc(timestamp).format('DD/MM/YYYY HH:mm')} UTC
          </span>
        </div>

        <Body message={message} mentions={mentions} />
        <Attachments value={attachments} />
      </div>
    </div>
  );
};

MessageBubble.propTypes = {
  timestamp: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  mentions: PropTypes.array,
  type: PropTypes.string.isRequired,
  username: PropTypes.string,
  attachments: PropTypes.arrayOf(File),
  side: PropTypes.string.isRequired
};

export default MessageBubble;
