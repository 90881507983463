import React from 'react';
import { Row, Col } from 'reactstrap';
import { getValue } from '../../layout/utils';
import { useSelector } from 'react-redux';
import { selectVesselProfile } from 'store/vessels/selectors';

const DraftAndDwt = ({ fields }) => {
  const profile = useSelector(selectVesselProfile);

  return (
    <div className="w-100p mt-3">
      <Row>
        <Col
          className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
          xs={4}
        ></Col>
        <Col xs={8} className="fs-12  d-flex align-items-start ">
          <div className="dimensions-dwt-draft">DWT</div>
          <div className="dimensions-dwt-draft">DRAFT</div>
        </Col>
      </Row>
      {fields.map(field => {
        return (
          <Row key={field.label} className="cmt-4 cmb-4">
            <Col
              className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
              xs={4}
            >
              {field.label}
            </Col>
            <Col xs={8} className="fs-12  d-flex align-items-start ">
              {field.extraData.map(el => (
                <div key={el.key} className="dimensions-dwt-draft ">
                  <span>
                    {getValue(el, profile)}{' '}
                    {getValue(el, profile) === '-' ? null : el.label === 'DWT' ? 'mt' : 'm'}
                  </span>
                </div>
              ))}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default DraftAndDwt;
