import React, { useState, useEffect } from 'react';
import useRouter from 'use-react-router';

import { NavItem } from 'reactstrap';
import { Tabs } from 'common/components/general/Tabs';
import { Link } from 'react-router-dom';
import Table from './table';

import { getCategoryWarnings } from './helpers';
import { getInitialAsyncValues } from 'utils/helpers';

import paths from 'routing/routes/_paths';

import { selectCrewProfile } from 'crew/store/profile/selectors';
import { useSelector } from 'react-redux';

const Documents = ({
  tableForm,
  tableActions,
  onCreateClick,
  onAttachFiles,
  onArchive,
  isOnboard
}) => {
  const { match } = useRouter();
  const [activeTab, setActiveTab] = useState(match.params.category || 'travelling');
  const [tabs, setTabs] = useState([]);

  const crew = useSelector(selectCrewProfile);

  const intitialFetch = async () => {
    try {
      const newTabs = (await getInitialAsyncValues('crew-document-categories')) || [];

      if (Array.isArray(newTabs)) {
        setTabs(newTabs.map(item => ({ label: item.name, path: item.type })));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    intitialFetch();
  }, []);

  useEffect(() => {
    if (match.params.category && match.params.category !== activeTab) {
      setActiveTab(match.params.category);
    } else if (!match.params.category) {
      setActiveTab('travelling');
    }
  }, [match.params.category]);

  return (
    <div className={`crew-profile__documents`}>
      <Tabs pills className="mb-6">
        {tabs.map(tab => (
          <NavItem
            className={`crew-profile__documents__tab${
              getCategoryWarnings(crew, tab.path).all.length
                ? ' crew-profile__documents__tab--notify'
                : ''
            }`}
            key={tab.path}
            active={tab.path === activeTab}
          >
            <Link
              className="nav-link"
              to={`${paths.crew}/${match.params.id}/documents/${tab.path}`}
            >
              {tab.label}
            </Link>
          </NavItem>
        ))}
      </Tabs>

      {activeTab && (
        <Table
          warnings={getCategoryWarnings(crew, activeTab)}
          isOnboard={isOnboard}
          category={activeTab}
          key={activeTab}
          actions={tableActions}
          form={tableForm}
          onArchive={onArchive}
          onCreateClick={onCreateClick}
          onAttachFiles={onAttachFiles}
        />
      )}
    </div>
  );
};

export default Documents;
