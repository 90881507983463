const TYPES = {
  SET_VALUE: 'SET_VALUE',
  SET_WARNING: 'SET_WARNING',
  SET_COMMENT: 'SET_COMMENT',
  SET_INVALID_REPORT: 'SET_INVALID_REPORT',
  SET_REPORT_ON_BOARD: 'SET_REPORT_ON_BOARD',
  SET_REPORT_TOTALS: 'SET_REPORT_TOTALS',
  SET_REPORT_NAVIGATION: 'SET_REPORT_NAVIGATION',
  SET_PREVIOUS_REPORT: 'SET_PREVIOUS_REPORT',
  SET_AER_LEG_AVERAGE: 'SET_AER_LEG_AVERAGE',
  SET_AER_LAST_REPORT: 'SET_AER_LAST_REPORT',
  SET_LEG_ATTAINED_CII: 'SET_LEG_ATTAINED_CII',
  SET_PREVIOUS_BS_VISCOSITIES: 'SET_PREVIOUS_BS_VISCOSITIES',
  SET_PREVIOUS_BS_BIOFUELS: 'SET_PREVIOUS_BS_BIOFUELS',
  SET_REPORT_BUNKERING: 'SET_REPORT_BUNKERING',
  SET_REPORT_VESSEL: 'SET_REPORT_VESSEL',
  SET_REPORT_DATA: 'SET_REPORT_DATA',
  SET_REPORT_TEMPLATE_DATA: 'SET_REPORT_TEMPLATE_DATA',
  SET_REPORT_TEMPLATE_VISIBLE_FIELDS: 'SET_REPORT_TEMPLATE_VISIBLE_FIELDS',
  TOGGLE_REPORT_TEMPLATE_FIELD_VISIBILITY: 'TOGGLE_REPORT_TEMPLATE_FIELD_VISIBILITY',
  SET_REPORT_TEMPLATE_VALIDATION_FIELDS: 'SET_REPORT_TEMPLATE_VALIDATION_FIELDS',
  SET_REPORT_TEMPLATE_TOOLTIPS: 'SET_REPORT_TEMPLATE_TOOLTIPS',
  CHANGE_REPORT_STATUS: 'CHANGE_REPORT_STATUS',
  TOGGLE_SECTION: 'TOGGLE_SECTION',
  CHANGE_SECTION_ACTIVE_TAB: 'CHANGE_SECTION_ACTIVE_TAB',
  GET_REPORT_PARAMS: 'GET_REPORT_PARAMS',
  SET_EDITTING_APPROVED_REPORT: 'SET_EDITTING_APPROVED_REPORT',
  SET_FORM: 'SET_FORM',
  FORCE_UPDATE_SECTION_PROGRESS: 'FORCE_UPDATE_SECTION_PROGRESS',
  SET_REQUIRED_VALIDATION_ERROR_FLAG: 'SET_REQUIRED_VALIDATION_ERROR_FLAG',
  SET_TEMPLATE_VALIDATION_ERROR_FLAG: 'SET_TEMPLATE_VALIDATION_ERROR_FLAG',
  SET_CAPTAIN_REPORT_REBUILD_COMMENTS: 'SET_CAPTAIN_REPORT_REBUILD_COMMENTS',
  SET_FUTURE_REPORTS: 'SET_FUTURE_REPORTS',
  SET_SELECTED_FUTURE_REPORTS: 'SET_SELECTED_FUTURE_REPORTS',
  SET_YEAR_ATTAINED_CII: 'SET_YEAR_ATTAINED_CII',
  ADD_BUNKER_CONSUMPTION_COLUMN: 'ADD_BUNKER_CONSUMPTION_COLUMN',
  AUTO_CALCULATE_BUNKER_CONSUMPTION_COLUMNS: 'AUTO_CALCULATE_BUNKER_CONSUMPTION_COLUMNS',
  REMOVE_BUNKER_CONSUMPTION_COLUMN: 'REMOVE_BUNKER_CONSUMPTION_COLUMN',
  SET_LAST_UPDATED_GENERATOR_TAB_INDEX: 'SET_LAST_UPDATED_GENERATOR_TAB_INDEX'
};

export default TYPES;
