import { useState, useEffect, useCallback } from 'react';

import PickerNavigation from '../components/PickerNavigation';
import PickerSelectionGrid from '../components/PickerSelectionGrid';

import moment from 'moment';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { usePrevious } from 'utils/hooks';
import PropTypes from 'prop-types';

const SingleYearPicker = ({ value, onChange, isOutsideRange, isYearSelected, isDateInRange }) => {
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedYearIndex, setSelectedYearIndex] = useState(0);
  const previousSelectedYearIndex = usePrevious(selectedYearIndex);

  const getYears = useCallback(currentYear => {
    const years = Array(18)
      .fill(null)
      .map((_, i) => {
        const year = +currentYear - i;
        const selectItem = { label: year, value: year };

        return selectItem;
      });

    return years.reverse();
  }, []);

  useEffect(() => {
    const currentYear = value || moment().year();

    setYearOptions(getYears(currentYear));
  }, []);

  useUpdateEffect(() => {
    let currentYear = null;

    if (selectedYearIndex <= previousSelectedYearIndex) {
      currentYear = yearOptions[yearOptions.length - 1].value - 18;
    } else {
      currentYear = yearOptions[yearOptions.length - 1].value + 18;
    }

    setYearOptions(getYears(currentYear));
  }, [selectedYearIndex]);

  return (
    <div className="d-flex flex-column">
      <PickerNavigation goToIndex={setSelectedYearIndex} currentIndex={selectedYearIndex}>
        {value || '-'}
      </PickerNavigation>
      <PickerSelectionGrid
        options={yearOptions}
        selectedOption={value}
        onChange={onChange}
        isOptionDisabled={isOutsideRange ? option => isOutsideRange(option.value) : null}
        isOptionSelected={isYearSelected ? option => isYearSelected(option.value) : null}
        isOptionHighlighted={isDateInRange ? option => isDateInRange(option.value) : null}
      />
    </div>
  );
};

SingleYearPicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  isOutsideRange: PropTypes.func,
  isYearSelected: PropTypes.func,
  isDateInRange: PropTypes.func
};

export default SingleYearPicker;
