import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Col } from 'reactstrap';
import ImageViewer from '../_components/ImagePreview';
import Information from './Information';

const PostWithImage = ({ post, onClick }) => {
  return (
    <StyledRowContainer onClick={() => onClick(post)} className="mb-6 cursor-pointer row">
      <Col xs={6} className="image-side">
        <ImageViewer src={post?.image?.url} />
      </Col>
      <Col xs={6}>
        <Information post={post} />
      </Col>
    </StyledRowContainer>
  );
};

const StyledRowContainer = styled.div`
  min-height: 15.625rem;
  max-height: 15.625rem;

  .image-side {
    max-height: inherit;
  }
`;

PostWithImage.propTypes = {
  post: PropTypes.shape({
    name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    )
  }),
  onClick: PropTypes.func
};

export default PostWithImage;
