import TYPES from './types';

export const toggleGallery = params => dispatch => {
  dispatch({ type: TYPES.TOGGLE_GALLERY, payload: params });
};

export const setGalleryFiles = params => dispatch => {
  dispatch({ type: TYPES.SET_GALLERY_FILES, payload: params });
};

export const setSelectedFile = params => dispatch => {
  dispatch({ type: TYPES.SET_SELECTED_FILE, payload: params });
};

export const setRotateDirection = value => dispatch => {
  dispatch({ type: TYPES.SET_ROTATE_DIRECTION, payload: value });
};
