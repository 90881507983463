import TABLE_TYPES from 'common/components/table/store/types';
import { get, put, post, deleteRequest, download } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAccounts = createAsyncThunk(
  'GET_ACCOUNTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, table, ...rest } = params;

    try {
      const res = await get(`/events/${id}/accounts`, rest);
      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: res.data, table }
      });

      return res.data;
    } catch (error) {
      dispatch({ type: TABLE_TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } });
      return rejectWithValue(error);
    }
  }
);

export const createAccounts = createAsyncThunk(
  'CREATE_ACCOUNTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/events/${id}/accounts`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAccounts = createAsyncThunk(
  'UPDATE_ACCOUNTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, accountId, ...rest } = params;

    try {
      const res = await put(`/events/${id}/accounts/${accountId}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAccounts = createAsyncThunk(
  'DELETE_ACCOUNTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { id, accountId, ...rest } = params;

    try {
      const res = await deleteRequest(`/events/${id}/accounts/${accountId}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccountsAttachments = createAsyncThunk(
  'GET_ACCOUNT_ATTACHMENTS',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`events/accounts/${id}/attachments`, params);

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadAccountsAttachments = createAsyncThunk(
  'DOWNLOAD_ACCOUNT_ATTACHMENTS',
  async (params, { rejectWithValue }) => {
    try {
      const res = await download(`/files/entity`, { id: params.id, type: 'event_m_accounts' });

      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccountCurrencyEquivalent = createAsyncThunk(
  'GET_ACCOUNT_CURRENCY_EQUIVALENT',
  async (params, { rejectWithValue }) => {
    try {
      const res = await get(`/system-currency/equivalent`, params);

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
