import TYPES from './types';
import DOCUMENT_TYPES from '@/common/components/vessels/profile/tabs/documents/store/types';
import _cloneDeep from 'lodash/cloneDeep';
import { LOCATION_CHANGE } from 'connected-react-router';

const DOCUMENTS_INITIAL_STATE = {
  isDataLoading: false,
  drawerOpen: false,
  drawerType: 'create',
  drawerActiveItem: null,
  expandedAllPanels: false,
  types: [], // Top level boxes
  categories: {}, // Inner Tables State
  exludedCategories: [],
  ciiReferenceYearly: {},
  search: ''
};

export const INITIAL_STATE = {
  info: {
    loading: false
  },
  documents: _cloneDeep(DOCUMENTS_INITIAL_STATE)
};

const vesselReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_VESSEL_INFO.START:
      return { ...state, info: { loading: true } };

    case TYPES.GET_VESSEL_INFO.ERROR:
      return { ...state, info: { loading: false } };

    case TYPES.GET_VESSEL_INFO.SUCCESS:
      return { ...state, info: { ...payload, loading: false } };

    case `${DOCUMENT_TYPES.GET_VESSEL_DOCUMENTS}/pending`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isDataLoading: true
        }
      };

    case `${DOCUMENT_TYPES.GET_VESSEL_DOCUMENTS}/fulfilled`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isDataLoading: false,
          types: payload.map(d => ({
            id: d.id,
            type_name: d.category_name,
            template: d.template,
            categories_count: d.types.length
          })),
          exludedCategories: payload.reduce((acc, category) => {
            const categoryIds = category.types.map(c => c.id);

            return [...acc, ...categoryIds];
          }, []),
          categories: payload.reduce((acc, category) => {
            acc[category.id] = {
              data: category.types.map(type => ({ ...type, category_name: type.type_name })),
              paging: null,
              sorting: null
            };

            return acc;
          }, {})
        }
      };

    case `${DOCUMENT_TYPES.GET_VESSEL_DOCUMENTS}/rejected`:
      return {
        ...state,
        documents: {
          ...state.documents,
          isDataLoading: false,
          types: [],
          categories: {}
        }
      };

    case TYPES.GET_VESSEL_CII_REFERENCE_YEARLY.SUCCESS:
      return {
        ...state,
        ciiReferenceYearly: payload
      };

    case DOCUMENT_TYPES.EXPAND_ALL_VESSEL_DOCUMENT_PANELS:
      return {
        ...state,
        documents: {
          ...state.documents,
          expandedAllPanels: payload
        }
      };

    case DOCUMENT_TYPES.SET_VESSEL_DOCUMENTS_SEARCH:
      return {
        ...state,
        documents: {
          ...state.documents,
          search: payload
        }
      };

    case DOCUMENT_TYPES.CHANGE_VESSEL_DOCUMENT_DRAWER_STATUS:
      return {
        ...state,
        documents: {
          ...state.documents,
          drawerOpen: payload.drawerOpen,
          drawerType: payload.drawerType || 'create',
          drawerActiveItem: !payload.drawerActiveItem ? null : { ...payload.drawerActiveItem }
        }
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        documents: {
          ...state.documents,
          search: '',
          isDataLoading: false
        }
      };
    default:
      return state;
  }
};

export default vesselReducer;
