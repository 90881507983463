import { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Title from 'common/components/jobs/_base/inputs/Title';
import Description from 'common/components/jobs/_base/inputs/Description';
import Remarks from 'common/components/jobs/_base/inputs/Remarks';

import ShadowBox from 'common/components/general/ShadowBox';

import AttachedManuals from 'common/components/pms/jobs/inputs/AttachedManuals';
import Maintenance from 'common/components/pms/jobs/inputs/maintenance';
import MainContentHeader from './MainContentHeader';
import Tabs from 'common/components/jobs/_base/components/Tabs';
import Forms from 'common/components/jobs/_base/modules/forms';
import JobsChecklist from 'common/components/jobs/_base/modules/checklist';

import VesselSystemAssignment from 'common/components/pms/jobs/inputs/VesselSystemAssignment';
import JobLinkingPms from 'common/components/pms/jobs/inputs/linked-jobs';
import JobHistory from 'common/components/pms/jobs/components/JobHistory';
import Activity from 'common/components/jobs/_base/modules/activity';
import LinkEntities from 'common/components/link-entities';

import { unlinkJobFinding, setJobField } from 'common/components/jobs/_base/store/actions';
import {
  selectIsJobLocked,
  selectJobEvents,
  selectJobFindings
} from 'common/components/jobs/_base/store/selectors';
import {
  selectIsPlannedJob,
  selectIsUnscheduledJob
} from 'common/components/pms/jobs/store/selectors';
import { jobEnums } from 'common/utils/fixed';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const MainContent = ({ drawerIsOpen, jobID }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isUnscheduled = useSelector(selectIsUnscheduledJob);
  const isPlannedJob = useSelector(selectIsPlannedJob);
  const isJobLocked = useSelector(selectIsJobLocked);
  const findings = useSelector(selectJobFindings);
  const events = useSelector(selectJobEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    if (drawerIsOpen && !jobID) {
      dispatch(setJobField('type', jobEnums.unscheduled));
    }
  }, [jobID, drawerIsOpen, dispatch]);

  const onUnlinkFinding = async id => {
    if (!id || !jobID) return;

    try {
      await dispatch(unlinkJobFinding({ job_id: jobID, id })).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="d-flex flex-column h-100p">
      <MainContentHeader className={`me-2`} drawerIsOpen={drawerIsOpen} />

      <Row className="mt-3 mb-2">
        <Col xs={12}>
          <StyledTitle
            className={`${!isUnscheduled ? 'pms-title' : ''}`}
            disabled={!isUnscheduled || isOnBoard}
            invisible
          />
        </Col>
        <Col xs={6}>
          <VesselSystemAssignment jobID={jobID} />
        </Col>
      </Row>

      <Tabs className="mb-2" />

      <Row className="h-100p flex-nowrap">
        <Col xs={6} className="h-100p">
          <ShadowBox
            className={`pms-job-section cpt-4 h-100p w-100p ${
              isJobLocked ? 'pms-job-section--locked' : ''
            }`}
            color={isJobLocked || !isUnscheduled ? '' : 'light-2'}
            flat={!isUnscheduled ? false : !isJobLocked}
          >
            <Maintenance side="left" className="mb-2" />
            <Description
              className="cps-12 pb-2"
              showA4Layout={false}
              disabled={!isUnscheduled || isOnBoard}
            />

            <AttachedManuals isLocked={!isUnscheduled || isOnBoard} className="cps-12" />
            {isPlannedJob && <JobHistory jobID={jobID} drawerIsOpen={drawerIsOpen} />}
            {isUnscheduled || isOnBoard ? null : (
              <div className="cps-12 cpe-12">
                <Activity />
              </div>
            )}
          </ShadowBox>
        </Col>

        <Col xs={6} className="h-100p">
          <ShadowBox
            className={`pms-job-section cpt-4 h-100p w-100p ${
              isJobLocked ? 'pms-job-section--locked' : ''
            }`}
            color={isJobLocked ? '' : 'light-2'}
            flat={!isJobLocked}
          >
            <Maintenance className="mb-2" side="right" />

            <Forms
              canAddForms={isJobLocked ? false : isUnscheduled} // When job is planned (pms), we cannot add/delete the job forms
              canRemoveForms={isJobLocked ? false : isUnscheduled}
            />
            {isJobLocked ? null : <JobLinkingPms drawerIsOpen={drawerIsOpen} />}
            {jobID ? <JobsChecklist /> : null}
            <Remarks className="cps-12 pb-4" showA4Layout={false} />

            <LinkEntities
              findingsState={{
                className: 'cps-12',
                findingsState: findings,
                onUnlink: onUnlinkFinding
              }}
              eventsState={{
                className: 'cps-12',
                eventsState: events
              }}
            />
          </ShadowBox>
        </Col>
      </Row>
    </div>
  );
};

const StyledTitle = styled(Title)`
  textarea {
    font-size: ${variables.size18};
  }
  textarea::placeholder {
    font-size: ${variables.size18} !important;
  }
`;

JobHistory.propTypes = {
  jobID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  drawerIsOpen: PropTypes.bool
};

export default MainContent;
