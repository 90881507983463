import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import Radio from 'common/components/form/inputs/Radio';
import CircledButton from 'common/components/buttons/CircledButton';
import ListSelect from 'common/components/selectors/ListSelect';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';

const WhoFillTheForms = ({
  fields,
  selectField,
  onRemove,
  disabled,
  activeId,
  areRadiosDisabled = false,
  options,
  hasAutoSave = () => {}
}) => {
  const [isAutoSaving, setIsAutoSaving] = useState(false);

  const onChange = (key, value) => {
    selectField(key)(value);

    if (!hasAutoSave) return;

    setIsAutoSaving(true);
  };

  useEffect(() => {
    if (isAutoSaving) {
      hasAutoSave();

      setIsAutoSaving(false);
    }
  }, [isAutoSaving]);

  return (
    <div className="d-flex flex-column">
      <div className="text-violet fs-12 fw-medium cmb-12 d-flex align-items-center">
        <div>Select who has to take action - Either one of the selected can fill in info.</div>
        {onRemove ? (
          <div className="ms-auto">
            <CircledButton
              type="remove"
              onClick={onRemove}
              disabled={disabled}
              style={{ width: '16px', height: '16px' }}
            />
          </div>
        ) : null}
      </div>
      <div className="d-flex cmb-12">
        <Radio
          className="mb-0 me-3"
          name="departments"
          label="Departments"
          disabled={areRadiosDisabled || disabled}
          checked={fields.who_fill_the_forms?.value === 'departments'}
          onChange={() => {
            selectField('who_fill_the_forms')('departments');

            if (!activeId) {
              selectField('department_roles')(null);
            }
          }}
        />
        <Radio
          className="mb-0"
          name="roles"
          label="Roles"
          disabled={areRadiosDisabled || disabled}
          checked={fields.who_fill_the_forms?.value === 'roles'}
          onChange={() => {
            selectField('who_fill_the_forms')('roles');

            if (!activeId) {
              selectField('departments')(null);
            }
          }}
        />
      </div>
      <Row>
        {fields.who_fill_the_forms.value === 'departments' ? (
          <Col xs={4}>
            <DepartmentSelector
              placeholder="Select departments"
              onChange={d => onChange('departments', d)}
              value={fields.departments.value}
              isMulti={true}
              disabled={disabled}
              options={options}
              error={
                fields.who_fill_the_forms?.value === 'departments' &&
                !fields.departments.value?.length
                  ? REQUIRED_FIELD_MESSAGE
                  : ''
              }
            />
          </Col>
        ) : (
          <Col xs={4}>
            <ListSelect
              getOptionLabel={option => option.name}
              list="department-roles"
              isAsync={false}
              isClearable={true}
              isMulti={true}
              disabled={disabled}
              options={options}
              placeholder="Select role"
              onChange={e => onChange('department_roles', e)}
              error={
                fields.who_fill_the_forms?.value === 'roles' &&
                !fields.department_roles.value?.length
                  ? REQUIRED_FIELD_MESSAGE
                  : ''
              }
              {...fields.department_roles}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
export default WhoFillTheForms;
