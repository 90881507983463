import TYPES from './types';
import _isArray from 'lodash/isArray';

const INITIAL_STATE = {};
const VALUE_IDENTIFIERS = {
  forms: 'uid',
  'crew-document-categories': 'type'
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ASYNC_FILTER_VALUES:
      const newData = { ...state[payload.label] };
      const identifier = VALUE_IDENTIFIERS[payload.label] || 'id';

      (_isArray(payload.data) ? payload.data : [payload.data]).forEach(el => {
        let fieldIdentifier = identifier;

        if (!el?.[fieldIdentifier] && el?.label) fieldIdentifier = 'label';

        if (el?.[fieldIdentifier]) newData[el?.[fieldIdentifier]] = el;
      });

      return {
        ...state,
        [payload.label]: newData
      };

    default:
      return state;
  }
};

export default reducer;
