import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import NoAuthRoute from './NoAuthRoute';
import PublicRoute from './PublicRoute';
import PageDocumentTitle from 'common/components/general/PageDocumentTitle';

const components = {
  private: PrivateRoute,
  no_auth: NoAuthRoute,
  public: PublicRoute,
  default: Route
};

const RenderRoute = props => {
  const TagName = components[props.type || 'public'];

  return (
    <>
      <TagName {...props} />
      <PageDocumentTitle {...props} />
    </>
  );
};
export default RenderRoute;
