import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';

const FormRelatedHeader = ({ icon, text, className }) => {
  return (
    <div
      className={`chapter-content__form-related text-violet d-flex align-items-center pb-1 mb-2 border-bottom ${
        className || ''
      }`}
    >
      <SvgRender src={icon} style={{ height: 16, width: 16 }} className="me-1" />
      <strong className="fs-12 fw-medium">{text}</strong>
    </div>
  );
};

FormRelatedHeader.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string
};

export default FormRelatedHeader;
