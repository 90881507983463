import CircledButton from 'common/components/buttons/CircledButton';

import { useEffect, useState } from 'react';
import { useForm } from 'common/utils/hooks/useForm';

import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';

const FormInner = ({
  index,
  children,
  setRowEditing,
  setRowDeleting,
  disabled,
  onSave,
  onCancel
}) => {
  return (
    <div className="creatable-table__form small-elements-form">
      <div className="creatable-table__form-component">{children}</div>
      <div className="creatable-table__form-actions">
        <div className="creatable-table__form-actions-inner">
          <CircledButton
            type="close"
            className="me-1"
            svgStyle={{ width: 12, height: 12 }}
            onClick={() => {
              setRowEditing();
              if (onCancel) {
                onCancel();
              }
            }}
            disabled={disabled}
          />
          {setRowDeleting ? (
            <CircledButton type="remove" className="me-1" onClick={setRowDeleting} />
          ) : null}
          <CircledButton
            svgStyle={{ width: 12, height: 12 }}
            type="save"
            onClick={onSave}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

const Form = ({
  row,
  index,
  formConfig,
  formOnCancel,
  formOnSave,
  formOnSaveCallback,
  setRowEditing,
  setRowDeletting,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, loadValues, collectValues } = useForm(formConfig, {});

  useEffect(() => {
    if (row) {
      const data = selectDataFromState(row, formConfig);
      loadValues(data);
    }
  }, []);

  const onSubmit = async () => {
    const params = collectValues();

    if (params) {
      try {
        setIsSubmitting(true);

        await formOnSave(params, row, index);

        setIsSubmitting(false);
        setRowEditing();

        if (formOnSaveCallback) {
          formOnSaveCallback();
        }
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <FormInner
      index={index}
      setRowEditing={setRowEditing}
      setRowDeletting={setRowDeletting}
      disabled={isSubmitting}
      onSave={onSubmit}
      onCancel={formOnCancel}
    >
      <rest.formComponent index={index} row={row} formState={formState} />
    </FormInner>
  );
};

export default Form;
