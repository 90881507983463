import _mainInfoConfig from 'common/components/person/profile/sections/main-info/_config';
import MainInfoPreview from 'common/components/person/profile/sections/main-info';

import location from 'common/assets/svg/common/location.svg';
import _addressConfig from 'common/components/person/profile/sections/address/_config';
import AddressPreview from 'common/components/person/profile/sections/address';

import Notes from 'common/components/person/profile/sections/Notes';

import _documentsConfig from 'common/components/person/profile/sections/documents/_config';
import DocumentsPreview from 'common/components/person/profile/sections/documents';

export const leftSideInfoConfig = [{ component: Notes, path: 'notes' }];

export const tabsConfig = [
  {
    label: 'MAIN INFO',
    path: 'main-info',
    component: MainInfoPreview,
    componentProps: {
      config: _mainInfoConfig
    }
  },
  {
    label: 'ADDRESS',
    path: 'address',
    component: AddressPreview,
    showEmpty: data =>
      !data.city &&
      !data.state &&
      !data.country &&
      !data.postal_code &&
      !data.address &&
      !data.secondary_address,
    emptyProps: {
      emptyText: 'This person has no addresses yet.',
      emptyButtonText: 'Add address',
      icon: location
    },
    componentProps: {
      config: _addressConfig
    }
  },
  {
    label: 'DOCUMENTS',
    path: 'documents',
    component: DocumentsPreview,
    componentProps: {
      config: _documentsConfig
    }
  }
];
