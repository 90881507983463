import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import styled from '@emotion/styled';
import newsAndAnnouncements from 'common/assets/svg/common/news-announcements.svg';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const Title = () => {
  return (
    <TitleContainer className="d-flex align-items-center text-current-color position-absolute ms-4">
      <div className="icon d-flex align-items-center justify-content-center bg-yellow padding cpx-12 cpt-12 pb-3">
        <SvgRender src={newsAndAnnouncements} style={{ height: 19, width: 19 }} />
      </div>
      <span className="fs-12 cms-12 mb-1 fw-bold">ANNOUNCEMENTS</span>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  top: -${variables.size28};

  .icon {
    border-radius: 13px;
  }
`;

Title.propTypes = {};

export default Title;
