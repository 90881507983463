import SvgRender from 'common/components/general/SvgRender';
import CircledButton from 'common/components/buttons/CircledButton';

import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectActiveChapterChangeRequestStatus,
  selectActiveChapterChangeRequest
} from 'manuals/store/selectors';
import { PersonWithDepartment } from 'common/components/selectors/_helpers';
import { dateToLocal } from 'common/utils/dates';

import draft from 'common/assets/svg/common/draft.svg';
import versioning from 'common/assets/svg/common/versioning.svg';

import _get from 'lodash/get';

const ChangeRequestPreview = ({ chapter, currentVersionStatus }) => {
  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const toggle = () => setPopoverOpen(!popoverOpen);

  const isDraft = chapter.draft_change_request_count;

  const changeRequestStatus = useSelector(selectActiveChapterChangeRequestStatus);
  const changeRequest = useSelector(selectActiveChapterChangeRequest);

  return changeRequest ? (
    isDraft ? (
      <SvgRender
        className={`me-2 text-${isDraft ? 'violet' : 'yellow'}`}
        src={isDraft ? draft : versioning}
        style={{ width: 18, height: 18 }}
      />
    ) : (
      <>
        <CircledButton
          type={changeRequestStatus === 'approved' ? 'versioning' : 'versioning-yellow'}
          className="me-2"
          id="change-request-popover"
        />
        <UncontrolledPopover
          trigger="hover"
          placement="right-start"
          target="change-request-popover"
          className="chapter-popover info"
          // isOpen={popoverOpen}
          // toggle={toggle}
        >
          <PopoverHeader>{changeRequest.title}</PopoverHeader>
          <PopoverBody className="text-primary">
            {changeRequest.message ? <div className="mb-3">{changeRequest.message}</div> : null}
            <div className="d-flex align-items-center justify-content-between">
              <PersonWithDepartment
                person={_get(changeRequest, 'parties[0]', null)}
                displayClassname={'d-inline-flex align-items-center text-violet fs-12 fw-medium'}
              />
              <span className="text-violet fs-12 fw-medium ps-1">
                {_get(changeRequest, 'created_at', null)
                  ? dateToLocal(changeRequest.created_at).format('DD/MM/YYYY HH:mm')
                  : null}
              </span>
            </div>
            {changeRequest.approved_by ? (
              <div className="border-top mt-1 pt-1">
                <div className="fs-12 fw-bold text-primary mb-1">Approved by</div>
                <div className="d-flex align-items-center justify-content-between">
                  <PersonWithDepartment
                    person={_get(changeRequest, 'approved_by', null)}
                    displayClassname={
                      'd-inline-flex align-items-center text-violet fs-12 fw-medium'
                    }
                  />
                  <span className="text-violet fs-12 fw-medium ps-1">
                    {_get(changeRequest, 'approved_at', null)
                      ? dateToLocal(changeRequest.approved_at).format('DD/MM/YYYY HH:mm')
                      : null}
                  </span>
                </div>
              </div>
            ) : null}
          </PopoverBody>
        </UncontrolledPopover>
      </>
    )
  ) : null;
};

export default ChangeRequestPreview;
