import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';

export const formatNumber = val => {
  if (!val) return null;

  var parts = val.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export const numberToStr = (number, decimalScale, minDecimalFraction) => {
  let formattedNumber = number;

  if (_isString(number) && number?.includes(',')) {
    // replace commas in numbers that are formatted as strings
    formattedNumber = Number(number.replace(/[^\d\.\-]/g, ''));
  }

  formattedNumber = parseFloat(formattedNumber);

  return !isNaN(formattedNumber)
    ? formattedNumber.toLocaleString('en', {
        minimumFractionDigits: minDecimalFraction !== undefined ? minDecimalFraction : 0,
        maximumFractionDigits: decimalScale !== undefined ? decimalScale : 2
      })
    : '-';
};

export const strToNumber = formattedStr => {
  if (_isNumber(formattedStr)) return formattedStr;
  if (!formattedStr || formattedStr.length === 0) return null;
  const numberValue = Number(formattedStr.replace(/[^\d\.\-]/g, ''));

  if (isNaN(numberValue)) return null;

  return numberValue;
};

export const trimFloatNumber = num => {
  return strToNumber(parseFloat(num).toFixed(2));
};

export const padLeadingZeros = (num, size) => {
  if (num == 0 && num !== '') return '0';

  let numString = num + '';

  if (numString.length) {
    while (numString.length < size) numString = '0' + numString;
    return numString;
  } else {
    return '';
  }
};

export const roundNumber = num => {
  return numberToStr(num, 2, 2);
};

export const hasValue = value => value || value === 0;

export const scaleNumberIsAllowed = (value, { min, max, step }) =>
  value >= min && value <= max && value % step === 0;

export const calculatePercentage = (value, total) => {
  if (!total) return 0;

  return (value / total) * 100;
};
