import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectTableListSelectedRowsCount } from 'common/components/table/store/selectors';

import * as tableListActions from 'common/components/table/store/actions';
import useActions from 'common/utils/hooks/useActions';

const SelectableRowsHeader = ({ selectedRowsLabel, label, hideSelectableRowsHeader }) => {
  const [clearSelectedTableRows] = useActions([tableListActions.clearSelectedTableRows]);

  const count = useSelector(state => selectTableListSelectedRowsCount(state, label));

  if (hideSelectableRowsHeader) return;

  return (
    <div className="d-flex align-items-center justify-content-between mb-1 fs-12">
      <div className="flex-1 text-violet">
        *Click on the preferred rows to select {selectedRowsLabel}
      </div>
      <div className="text-primary d-flex align-items-center">
        <span>Selected {selectedRowsLabel}:&nbsp;</span>
        <strong>{count}</strong>
        <div className="border-end ps-2 me-2 py-1"></div>
        <Button
          color="link"
          className="p-0 fw-medium fs-12"
          disabled={count === 0}
          onClick={() => clearSelectedTableRows(label)}
        >
          Clear all
        </Button>
      </div>
    </div>
  );
};

export default SelectableRowsHeader;
