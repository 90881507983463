import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModuleWrapper from '../components/ModuleWrapper';
import Loader from './Loader';
import Body from './Body';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';
import { getChecklist } from 'events/store/event-modules/checklist/actions';
import { selectChecklistIsLoading } from 'events/store/event-modules/checklist/selectors';

const Checklist = () => {
  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isLoading = useSelector(selectChecklistIsLoading);
  const isTemplate = useSelector(selectIsTemplate);

  const fetchChecklist = useCallback(
    async id => {
      try {
        await dispatch(getChecklist({ event_id: id })).unwrap();
      } catch (err) {
        console.error(err);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (eventId && !isTemplate) {
      fetchChecklist(eventId);
    }
  }, [eventId, isTemplate, fetchChecklist]);

  return (
    <ModuleWrapper className="checklist" type="checklist">
      {isLoading ? <Loader /> : <Body />}
    </ModuleWrapper>
  );
};

export default Checklist;
