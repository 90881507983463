import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

function Header({ checklistOptions, editMode }) {
  return (
    <div className="d-flex align-items-center min-height-20">
      <Label className="form-label mb-0 me-1">CHECKLIST</Label>
      {checklistOptions.length && !editMode ? (
        <>
          <div className="title-divider"></div>
          <div className="text-violet fs-12">
            {checklistOptions.filter(check => check.checked).length}/{checklistOptions.length}
          </div>
        </>
      ) : null}
    </div>
  );
}

Header.propTypes = {
  checklistOptions: PropTypes.array,
  editMode: PropTypes.bool
};

export default Header;
