import moment from 'moment';
import PreviewDuration from 'common/components/dates/PreviewDuration';

const ContractDuration = ({ signOn, signOff, from, to }) => {
  const today = moment().format('YYYY-MM-DD');

  const durationShow = (signOn, signOff, today) => {
    if (!signOn) {
      return '-';
    } else if (moment(signOn).isAfter(today)) {
      return '-';
    } else if (signOn && !signOff) {
      return (
        Math.abs(
          moment(today, 'YYYY-MM-DD')
            .startOf('day')
            .diff(moment(signOn, 'YYYY-MM-DD').startOf('day'), 'days')
        ) + 1
      );
    } else if (signOn && signOff) {
      return (
        Math.abs(
          moment(signOff, 'YYYY-MM-DD')
            .startOf('day')
            .diff(moment(signOn, 'YYYY-MM-DD').startOf('day'), 'days')
        ) + 1
      );
    }
  };

  return (
    <>
      <span className="text-primary fw-normal ps-1">Duration Onboard:&nbsp;</span>
      <PreviewDuration
        durationInDays={durationShow(signOn, signOff, today)}
        format={'months'}
        place={'profile'}
        designFormat={'contracts'}
        showFullDuration={false}
      />
    </>
  );
};

export default ContractDuration;
