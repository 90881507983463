import React, { useMemo, useState } from 'react';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const PartyBankAccountsSelector = ({
  filter,
  value,
  onChange,
  listParams = {},
  isMulti,
  ...rest
}) => {
  const [multiAccounts, setMultiAccounts] = useState([]);

  const requestParams = useMemo(
    () => ({
      ...listParams
    }),
    [listParams]
  );

  const handleChange = selected => {
    if (filter !== undefined) {
      onChange({
        value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
      });

      setMultiAccounts(isMulti ? selected : [selected]);
    } else {
      onChange(selected);
    }
  };

  const getValue = () => {
    if (filter !== undefined) {
      return filter.value
        ? multiAccounts.filter(el => filter.value.map(Number).includes(el?.id))
        : [];
    } else {
      return value;
    }
  };

  useFilterSelector(
    filter?.value,
    { list: 'party-bank-accounts', listParams: requestParams },
    { selected: multiAccounts, setSelected: setMultiAccounts }
  );

  return (
    <Select
      className={`mb-0`}
      placeholder="Select bank account"
      isAsync
      isMulti={isMulti}
      getOptionValue={option => option.id}
      getOptionLabel={option =>
        `${option.bank_name} | ${option.iban} | ${option.beneficiary_full_name}`
      }
      loadOptions={search => getAsyncOptions(search, 'party-bank-accounts', requestParams)}
      defaultOptions={() => getInitialAsyncValues('party-bank-accounts', null, null, requestParams)}
      value={getValue()}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default PartyBankAccountsSelector;
