import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import { putFileUrlsInHtml } from 'common/utils/post-images';
import { getMinutesHoursOrDays } from 'common/utils/dates';
import GoBackButton from './GoBackButton';
import ImageViewer from '../_components/ImagePreview';
import UploadFiles from 'common/components/form/inputs/upload-files';
import Tag from '../_components/Tag';
import { getApiBaseUrl } from 'utils/api';

const PreviewPost = ({ active, setActivePost }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const baseUrl = getApiBaseUrl();

  return (
    <Container className="ps-4">
      <GoBackButton setActivePost={setActivePost} />
      <ScrollContainer className="pt-5 pe-10 ps-10 gray-scrollbar">
        <div className={`title fw-bold text-wrap ${!isOnBoard ? 'text-dark' : 'text-primary'}`}>
          {active?.name}
        </div>
        <div className="d-flex align-items-center flex-wrap mt-2 mb-5">
          {(active.tags || [])?.map(tag => (
            <Tag key={tag.id} name={tag.name} className="cmb-4" />
          ))}
          <div className={`fs-14 text-violet ${active?.tags?.length ? 'ms-2' : ''}`}>
            {getMinutesHoursOrDays(active.created_at)} ago
          </div>
        </div>

        {active.image ? <ImageViewer src={active?.image?.url} /> : null}
        {!isOnBoard && active?.vessels ? (
          <div className="d-flex align-items-center flex-wrap mt-3 mb-5">
            {(active.vessels || [])?.map(vessel => (
              <div
                key={vessel?.id}
                className="fs-12 fw-medium align-items-center bg-white text-dark px-1 cpy-6 border-radius-base cme-4 cmb-4"
              >
                {vessel?.name}
              </div>
            ))}
          </div>
        ) : null}
        <div
          className="ck-content"
          dangerouslySetInnerHTML={{
            __html: putFileUrlsInHtml(active?.description, active?.content_attachments, {
              fileIdentifier: isOnBoard ? 'orca_id' : 'id',
              fileBaseUrl: isOnBoard ? baseUrl : ''
            })
          }}
        />
        <UploadFiles
          group="post_announcements"
          className="mt-5"
          files={active?.attachments ? active?.attachments : []}
          viewOnly={true}
          size="lg"
        />
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  color: ${variables.dark};

  .title {
    font-size: ${variables.size32};
  }
`;

const ScrollContainer = styled.div`
  max-height: 92vh;
  overflow-y: auto;

  .image-preview {
    max-height: 450px;
  }
`;

PreviewPost.propTypes = {
  active: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    ),
    created_at: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string
    }),
    vessels: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    ),
    description: PropTypes.string,
    attachments: PropTypes.array
  }),
  setActivePost: PropTypes.func
};

export default PreviewPost;
