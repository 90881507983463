import _sortBy from 'lodash/sortBy';

const MultipleDateInput = ({ value, isOpen, togglePopup }) => {
  const hasValue = value && value.length > 0;

  if (!hasValue) return null;

  return (
    <div
      onClick={togglePopup}
      className={`multi-date-input-value text-primary fs-14 d-flex flex-column align-items-center justify-content-center ${
        isOpen ? 'is-open' : ''
      }`}
    >
      {_sortBy(value, o => o.format('YYYY-MM-DD')).map((date, index) => (
        <div key={index}>{date.format('DD')}</div>
      ))}
    </div>
  );
};

export default MultipleDateInput;
