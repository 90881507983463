import React, { useCallback, useEffect, useState } from 'react';

import editEmpty from 'common/assets/svg/actions/edit-empty.svg';
import SvgRender from 'common/components/general/SvgRender';
import { useDispatch, useSelector } from 'react-redux';
import ColoredLabel from 'common/components/labels/ColoredLabel';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

import PropTypes from 'prop-types';
import { getFormSubmissionSignatureRequirements } from 'store/forms/actions';
import {
  icon,
  label
} from 'common/components/digital-signature/drawer/components/SignatureRequirements';
import _get from 'lodash/get';
import { getValidSignatures } from 'common/components/digital-signature/helpers';

const PendingSignatureRequirementsLabel = ({ count, formSubmissionId }) => {
  const { avoidRender, tooltipID } = useTooltipID('requirements');
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [signatureRequirements, setSignatureRequirements] = useState([]);

  const dispatch = useDispatch();
  const isOnboard = useSelector(state => state.isOnBoard);

  const fetchData = useCallback(async () => {
    if (hasFetchedData || !formSubmissionId || isFetching) return;

    try {
      setIsFetching(true);
      const res = await dispatch(
        getFormSubmissionSignatureRequirements({ id: formSubmissionId })
      ).unwrap();

      setSignatureRequirements(res);
      setIsFetching(false);
      setHasFetchedData(true);
    } catch (error) {
      console.log(error);
      setSignatureRequirements([]);
      setIsFetching(false);
      setHasFetchedData(true);
    }
  }, [dispatch, formSubmissionId, hasFetchedData, isFetching]);

  useEffect(() => {
    setHasFetchedData(false);
  }, [count]);

  if (avoidRender) return null;

  return count && count > 0 ? (
    <>
      <ColoredLabel
        id={tooltipID}
        className={`border-radius-7 cpx-6 py-0 height-16 ${
          isFetching ? 'disabled' : 'cursor-pointer'
        }`}
        color="coral"
        text={
          <>
            <span className="text-white fs-10 fw-bold cme-4">{count}</span>
            <SvgRender
              src={editEmpty}
              className="text-white justify-content-center cpb-2 cmb-2 border-bottom border-white"
              style={{ width: 8, height: 12 }}
            />
          </>
        }
        withShadow
        onMouseEnter={fetchData}
      />
      <Tooltip
        target={tooltipID}
        placement="top"
        innerClassName="max-width-412 p-1"
        className={isFetching ? 'opacity-0' : ''}
        key={isFetching && hasFetchedData}
      >
        <div className="text-start fs-12">
          <div className="fw-bold lh-15 cmb-6">Missing Signatures</div>
          <div className="fw-300">
            {getValidSignatures(signatureRequirements, isOnboard).map((requirement, index) => (
              <div
                key={requirement.id}
                className={`d-flex align-items-center ${index !== 0 ? 'cmt-4' : ''}`}
              >
                From {label?.[requirement.required_signer_type]}:
                <SvgRender
                  src={icon?.[requirement.required_signer_type]}
                  style={{ width: 10, height: 10 }}
                  className="mx-1"
                />
                <strong>{_get(requirement, 'required_signer.name', '-')}</strong>
              </div>
            ))}
          </div>
        </div>
      </Tooltip>
    </>
  ) : null;
};

PendingSignatureRequirementsLabel.propTypes = {
  count: PropTypes.number,
  formSubmissionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default PendingSignatureRequirementsLabel;
