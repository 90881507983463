import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import Select from 'common/components/form/inputs/Select';
import { Option, MultiValueLabel } from './DepartmentSelector';

import * as listActions from 'store/lists/actions';

const DepartmentsFilterSelector = ({ filter, onChange, showCrewDepartments = false }) => {
  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const departments = useSelector(state => state.lists['departments'].options);
  const partyCrewDepartments = useSelector(
    state => state.lists['party-and-crew-departments'].options
  );
  const data = showCrewDepartments ? partyCrewDepartments : departments;

  useEffect(() => {
    if (!departments.length) fetchListOptions('departments');
  }, []);

  useEffect(() => {
    if (!partyCrewDepartments.length && !partyCrewDepartments.isFetching)
      fetchListOptions('party-and-crew-departments');
  }, [showCrewDepartments]);

  return (
    <Select
      placeholder="Select value"
      components={{ Option, MultiValueLabel }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={filter.value}
      options={data}
      isMulti
      onChange={selected => onChange({ value: selected || '' })}
    />
  );
};

export default DepartmentsFilterSelector;
