import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Filter } from 'common/entities/tables/FilterTypes';

import SvgRender from 'common/components/general/SvgRender';
import SelectColumnOperation from 'common/components/filters/SelectColumnOperation';
import SelectValue from 'common/components/filters/SelectValue';
import TableTopFilter from 'common/components/table/TableTopFilter';
import Information from 'common/components/general/Information';

import types from 'common/utils/filters/operators';
import close from 'common/assets/svg/actions/select-close.svg';

const TableTopFilterNumberField = ({
  disabled,
  selectedFilter,
  componentRest,
  onUpdate,
  label,
  info
}) => {
  const [localStateOperation, setLocalStateOperation] = useState();

  return (
    <TableTopFilter>
      <div
        className={`filter-handler table-standalone-top-filter position-relative me-1 ${
          disabled ? 'disabled pointer-events-none' : ''
        }`}
      >
        <div className="filter-handler--field cps-12 cpe-12 d-inline-flex align-items-center">
          {info && (
            <Information
              svgStyle={{ width: 10, height: 10 }}
              className="me-1 text-primary"
              message={info}
              tooltipClassname="text-start max-width-412"
            />
          )}

          <span className="fw-medium fs-12 text-primary">{label}</span>
        </div>
        <SelectColumnOperation
          isOperation
          value={
            localStateOperation ||
            types.number.operations.find(o => o.value === selectedFilter.operation)
          }
          onSelect={operation => {
            setLocalStateOperation(operation);
            onUpdate({
              value: selectedFilter.value,
              operation: operation.value
            });
          }}
          options={types.number.operations}
        />
        <SelectValue
          filter={{
            value: selectedFilter.value && selectedFilter.value !== 0 ? selectedFilter.value : '',
            column: { type: 'number', componentRest: componentRest },
            operation: { value: selectedFilter.operation }
          }}
          updateFilter={({ value }) => onUpdate({ value, operation: localStateOperation?.value })}
          autoFocus={false}
        />

        {selectedFilter.value && (
          <Button
            onClick={() => onUpdate({ value: null })}
            type="button"
            className="d-inline-flex align-items-center table-standalone-top-filter__clear-btn p-0"
            color="link"
          >
            <SvgRender src={close} style={{ width: 16, height: 16 }} />
          </Button>
        )}
      </div>
    </TableTopFilter>
  );
};

TableTopFilterNumberField.propTypes = {
  selectedFilter: Filter.isRequired,
  disabled: PropTypes.bool,
  componentRest: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default TableTopFilterNumberField;
