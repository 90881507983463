import React from 'react';

import SvgRender from 'common/components/general/SvgRender';

import { components } from 'react-select';
import { captainReportIcon } from 'captain-reports/utils/helpers';

export const ReportTemplateName = ({ data, display = 'name' }) => (
  <div className={`d-flex align-items-center`}>
    <SvgRender
      src={captainReportIcon[data.label || data.type]}
      className="me-1"
      style={{ width: 18, height: 20 }}
    />
    <strong className="text-dark text-capitalize">
      {data?.[display] ? data?.[display]?.split('_').join(' ') : ''}
    </strong>
  </div>
);

export default ReportTemplateName;

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <ReportTemplateName data={props.data} />
    </components.Option>
  );
};

export const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <ReportTemplateName data={props.data} />
    </components.MultiValueLabel>
  );
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <ReportTemplateName data={props.data} />
  </components.SingleValue>
);

export const OptionType = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <ReportTemplateName data={props.data} display="type" />
    </components.Option>
  );
};

export const SingleValueType = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <ReportTemplateName data={props.data} display="type" />
  </components.SingleValue>
);
