import React from 'react';

import { Row, Col } from 'reactstrap';

import DateInput from 'common/components/form/inputs/date';
import PropTypes from 'prop-types';
import Input from 'common/components/form/inputs/Input';

const Telemedico = ({ selectField, changeField, fields }) => {
  return (
    <Row xs={12} className="d-flex mb-2">
      <Col xs={4}>
        <DateInput
          label="Date occured"
          value={fields.telemedico_occurred_at.value}
          className="mb-0"
          onChange={selected => {
            selectField('telemedico_occurred_at')(selected);
          }}
        />
      </Col>
      <Col className="ps-1">
        <Input
          placeholder={''}
          label={'info'}
          onChange={e => changeField('telemedico_info')(e)}
          className="mb-0"
          {...fields?.telemedico_info}
        />
      </Col>
    </Row>
  );
};

Telemedico.propTypes = {
  selectField: PropTypes.func,
  changeField: PropTypes.func,
  fields: PropTypes.object
};

export default Telemedico;
