import { components } from 'react-select';

const SelectMultipleMultiValueContainer = ({ children, ...props }) => {
  return (
    <div className={`react-select-multiple-value-container`}>
      <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>
    </div>
  );
};

export default SelectMultipleMultiValueContainer;
