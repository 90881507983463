import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import crossMedical from 'common/assets/svg/common/cross-medical-health.svg';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';

const CrewMedicalTypesSelector = ({
  filter,
  onChange,
  isMulti,
  listParams,
  styled,
  gray,
  className,
  showIcon = false,
  ...rest
}) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const getValue = () => {
    if (isMulti) {
      if (filter.value)
        return selectedTypes
          .filter(el => filter.value.map(Number).includes(el.id))
          .filter(r => filter.value.some(v => v == r.id));
      else return [];
    } else {
      return filter.value;
    }
  };

  useFilterSelector(
    filter?.value,
    { list: 'event-crew-medical-case-types', listParams },
    { selected: selectedTypes, setSelected: setSelectedTypes }
  );

  const SelectTag = showIcon ? IconSelect : styled ? StyledSelect : Select;

  return (
    <SelectTag
      className={className || `mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={getValue()}
      isAsync
      size="sm"
      isMulti={isMulti}
      icon={showIcon ? crossMedical : null}
      loadOptions={search => getAsyncOptions(search, 'event-crew-medical-case-types', listParams)}
      defaultOptions={() =>
        getInitialAsyncValues('event-crew-medical-case-types', null, null, listParams)
      }
      onChange={selected => {
        setSelectedTypes(isMulti ? selected : [selected]);
        onChange({
          value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
        });
      }}
      {...rest}
    />
  );
};

export default CrewMedicalTypesSelector;
