import Input from 'common/components/form/inputs/Input';

const TextInput = ({ error, value, onChange, disabled }) => {
  return (
    <Input
      rows={3}
      type="textarea"
      onChange={e => onChange(e.target.value)}
      value={value}
      className="mb-0"
      placeholder="Add some text"
      error={error}
      disabled={disabled}
    />
  );
};

export default TextInput;
