import area from './area';
import arrow from './arrow';
import blocks from './blocks';
import doughnut from './doughnut';
import line from './line';
import pie from './pie';
import polygon from './polygon';
import scatter from './scatter';
import timedash from './timedash';
import timemarker from './timemarker';
import xAxisLabels from './xAxisLabels';
import gauge from './gauge';
import bar from './bar';

const plotTypes = {
  area,
  arrow,
  blocks,
  doughnut,
  line,
  pie,
  polygon,
  scatter,
  timedash,
  timemarker,
  xAxisLabels,
  gauge,
  bar
};

export default plotTypes;

export const plotTypeOrdering = {
  area: 0,
  arrow: 2,
  blocks: 2,
  doughnut: 0,
  line: 3,
  pie: 0,
  polygon: 2,
  scatter: 2,
  timedash: 1,
  timemarker: -1,
  xAxisLabels: 0,
  gauge: 0,
  bar: 1
};

export const plotTypeType = {
  area: 'canvas',
  arrow: 'canvas',
  blocks: 'svg',
  doughnut: 'svg',
  line: 'canvas',
  pie: 'svg',
  polygon: 'canvas',
  scatter: 'canvas',
  timedash: 'canvas',
  timemarker: 'svg',
  xAxisLabels: 'svg',
  gauge: 'svg',
  bar: 'svg'
};
