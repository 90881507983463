import _uniqBy from 'lodash/uniqBy';

import CHECKLIST_TYPES from './types';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CHECKLIST_TYPES.GET_CHECKLIST_OPTIONS.SUCCESS:
      return {
        ...state,
        checklist: {
          ...state.checklist,
          jobId: state.id,
          options: payload
        }
      };

    case CHECKLIST_TYPES.CLEAR_CHECKLIST:
      return {
        ...state,
        checklist: {
          ...state.checklist,
          jobId: null,
          options: []
        }
      };

    case CHECKLIST_TYPES.SET_CHECKLIST_OPTIONS:
      return {
        ...state,
        checklist: {
          ...state.checklist,
          jobId: state.checklist.jobId,
          options: payload
        }
      };

    case CHECKLIST_TYPES.UPDATE_CHECKLIST_OPTIONS.START:
      return {
        ...state,
        isSaving: true
      };

    case CHECKLIST_TYPES.UPDATE_CHECKLIST_OPTIONS.SUCCESS:
      return {
        ...state,
        isSaving: false,
        checklist: {
          ...state.checklist,
          jobId: state.checklist.jobId,
          options: payload.map(el => ({ ...el, comments: [] }))
        }
      };

    case CHECKLIST_TYPES.UPDATE_CHECKLIST_OPTIONS.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case CHECKLIST_TYPES.CREATE_COMMENT.START:
      return {
        ...state,
        isSaving: true
      };

    case CHECKLIST_TYPES.CREATE_COMMENT.SUCCESS:
      return {
        ...state,
        isSaving: false,
        checklist: {
          ...state.checklist,
          options: state.checklist.options.map(opt => {
            if (opt.id === payload.option) {
              return {
                ...opt,
                comments_count: opt.comments_count + 1,
                comments: [payload.data, ...opt.comments]
              };
            } else {
              return opt;
            }
          })
        }
      };

    case CHECKLIST_TYPES.CREATE_COMMENT.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case CHECKLIST_TYPES.UPDATE_COMMENT.START:
      return {
        ...state,
        isSaving: true
      };

    case CHECKLIST_TYPES.UPDATE_COMMENT.SUCCESS:
      return {
        ...state,
        isSaving: false,
        checklist: {
          ...state.checklist,
          options: state.checklist.options.map(opt => {
            if (opt.id === payload.option) {
              return {
                ...opt,
                comments: opt.comments.map(comment => {
                  if (comment.id === payload.comment) return payload.data;
                  return comment;
                })
              };
            } else {
              return opt;
            }
          })
        }
      };

    case CHECKLIST_TYPES.UPDATE_COMMENT.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case CHECKLIST_TYPES.DELETE_COMMENT.START:
      return {
        ...state,
        isSaving: true
      };

    case CHECKLIST_TYPES.DELETE_COMMENT.SUCCESS:
      return {
        ...state,
        isSaving: false,
        checklist: {
          ...state.checklist,
          options: state.checklist.options.map(opt => {
            if (opt.id === payload.option) {
              return {
                ...opt,
                comments_count: opt.comments_count - 1,
                comments: opt.comments.filter(comment => comment.id !== payload.comment)
              };
            } else {
              return opt;
            }
          })
        }
      };

    case CHECKLIST_TYPES.DELETE_COMMENT.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case CHECKLIST_TYPES.GET_COMMENTS.START:
      return {
        ...state
      };

    case CHECKLIST_TYPES.SHOW_LESS_COMMENTS:
      return {
        ...state,
        isSaving: false,
        checklist: {
          ...state.checklist,
          options: state.checklist.options.map(opt => {
            if (opt.id === payload.option) {
              return {
                ...opt,
                comments: opt.comments.slice(0, 2)
              };
            } else {
              return opt;
            }
          })
        }
      };

    case CHECKLIST_TYPES.GET_COMMENTS.SUCCESS:
      return {
        ...state,
        isSaving: false,
        checklist: {
          ...state.checklist,
          options: state.checklist.options.map(opt => {
            if (opt.id === payload.option) {
              return {
                ...opt,
                comments: _uniqBy([...opt.comments, ...payload.data], 'id')
              };
            } else {
              return opt;
            }
          })
        }
      };

    case CHECKLIST_TYPES.GET_COMMENTS.ERROR:
      return {
        ...state,
        isSaving: false
      };

    case CHECKLIST_TYPES.TOGGLE_COLLAPSE:
      if (state.checklist.openLists.includes(payload)) {
        return {
          ...state,
          checklist: {
            ...state.checklist,
            openLists: state.checklist.openLists.filter(el => el !== payload)
          }
        };
      } else {
        return {
          ...state,
          checklist: {
            ...state.checklist,
            openLists: [...state.checklist.openLists, payload]
          }
        };
      }

    default:
      return state;
  }
};

export default reducer;
