const getIntegerPart = val => Math.trunc(val);
const getMinute = (val, deg) => Math.round((val - deg) * 60);

export const convertCoordinates = (coordinates, type) => {
  const positiveCoordinates = Math.abs(coordinates);
  const degrees = getIntegerPart(positiveCoordinates) || 0;
  const minutes = getMinute(positiveCoordinates, degrees);

  let direction = null;

  if (type === 'latitude') {
    if (coordinates < 0) {
      direction = 'S';
    } else {
      direction = 'N';
    }
  }

  if (type === 'longitude') {
    if (coordinates < 0) {
      direction = 'W';
    } else {
      direction = 'E';
    }
  }

  return { degrees: degrees, minutes: Math.abs(minutes), direction: direction };
};

export const convertAtSeaName = name => {
  if (name === 'At Sea - ') {
    return name.replace('- ', '');
  }

  if (name === 'At Sea - AT') {
    return name.replace('- AT', '');
  }

  return name;
};
