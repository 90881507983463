import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import crossMedical from 'common/assets/svg/common/cross-medical-health.svg';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const CrewMedicalCategoriesSelector = ({
  filter,
  onChange,
  isMulti,
  listParams,
  styled,
  gray,
  showIcon = false,
  ...rest
}) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'event-crew-medical-case-categories', listParams },
    { selected: selectedTypes, setSelected: setSelectedTypes }
  );

  return (
    <Select
      className={`mb-0`}
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={getFilterSelectorValues(selectedTypes, filter?.value)}
      isAsync
      isMulti={isMulti}
      icon={showIcon ? crossMedical : null}
      loadOptions={search =>
        getAsyncOptions(search, 'event-crew-medical-case-categories', listParams)
      }
      defaultOptions={() =>
        getInitialAsyncValues('event-crew-medical-case-categories', null, null, listParams)
      }
      onChange={selected => {
        setSelectedTypes(isMulti ? selected : [selected]);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : [selected.id]) : ''
        });
      }}
      {...rest}
    />
  );
};

export default CrewMedicalCategoriesSelector;
