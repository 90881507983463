import { useState, useEffect, useRef, FC, useCallback } from 'react';
import { Attachment } from '@/ts-common/types/files';

import SvgRender from '@/ts-common/components/general/SvgRender';
import Slider from '@/ts-common/components/form/inputs/Slider';
import Tooltip from '@/ts-common/components/general/Tooltip';

import minusIcon from '@/ts-common/assets/svg/actions/remove.svg';
import plusIcon from '@/ts-common/assets/svg/actions/add.svg';
import minimizeIcon from '@/ts-common/assets/svg/actions/minimize.svg';

import { rotateDirectionEnums } from '../helpers';
import { RotateDirectionType } from '../../store/types';
import { imageUrlSelector } from '@/ts-common/utils/image-size';
import { getFileBaseUrl } from '@/api/files/api';

type ImageProps = {
  file: Attachment;
  isGalleryHovered: boolean;
  containerWidth: number;
  containerHeight: number;
  rotateDirection: RotateDirectionType;
};

const Image: FC<ImageProps> = ({
  file,
  isGalleryHovered,
  containerWidth,
  containerHeight,
  rotateDirection
}) => {
  const [zoomPercentage, setZoomPercentage] = useState<number>(100);
  const [position, setPosition] = useState<{ x: number | null; y: number | null; cursor: string }>({
    x: 0,
    y: 0,
    cursor: 'auto'
  });

  const rotateDirectionValue = rotateDirectionEnums[rotateDirection];
  const imageRef = useRef<HTMLImageElement>(null);

  const previewImageBaseUrl = getFileBaseUrl();

  const onPressZoomKey = (isMinus: boolean) => {
    const value = isMinus ? -10 : 10;

    const newZoomValue = zoomPercentage + value;

    if (newZoomValue >= 100 && newZoomValue <= 200) {
      setZoomPercentage(newZoomValue);
      // triggerReRenderForBug();
    }
  };

  useEffect(() => {
    if (zoomPercentage === 100) {
      setPosition({ x: null, y: null, cursor: 'auto' });
    }
  }, [zoomPercentage]);

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (zoomPercentage <= 100) return;

    // Click position of the image
    const startX = e.clientX;
    const startY = e.clientY;

    // Position after drag the image
    const currentX = position.x || 0;
    const currentY = position.y || 0;

    setPosition({ ...position, cursor: 'grab' });

    // onMouseMove
    const handleMouseMove = (e: MouseEvent) => {
      // Set the new position of the image
      const newPositionX = e.clientX - startX;
      const newPositionY = e.clientY - startY;

      // Add the current with the new position to find the last position of he image
      setPosition({ x: currentX + newPositionX, y: currentY + newPositionY, cursor: 'grab' });
    };

    // Add and remove event listeners when moving mouse and when mouse is up
    const onMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const resetZoom = useCallback(() => {
    setZoomPercentage(100);
  }, []);

  useEffect(() => {
    resetZoom();
    setPosition({ x: null, y: null, cursor: 'auto' });
  }, [rotateDirection, resetZoom]);

  const imgHeight =
    rotateDirection !== 'top' && rotateDirection !== 'bottom' ? containerWidth : containerHeight;

  const imgWidth =
    rotateDirection !== 'top' && rotateDirection !== 'bottom' ? containerHeight : containerWidth;

  return (
    <>
      <img
        ref={imageRef}
        className={`gallery__center--container-image`}
        src={previewImageBaseUrl + imageUrlSelector({ file })}
        alt="img"
        height={imgHeight}
        width={imgWidth}
        draggable={false}
        style={{
          position: 'absolute',
          cursor: position.cursor,
          left: position.x || '',
          top: position.y || '',
          transform: `scale(${zoomPercentage / 100}) rotate(${rotateDirectionValue})`
        }}
        onMouseDown={handleMouseDown}
      />
      <div className="gallery__center--container-zoom-container">
        <div
          className={`gallery__center--container-zoom-slider ${
            isGalleryHovered ? '' : 'gallery__center--container-zoom-slider--hide'
          }`}
        >
          <div
            onClick={() => onPressZoomKey(true)}
            className="cursor-pointer py-2 cme-12 d-flex align-items-center"
          >
            <SvgRender src={minusIcon} style={{ width: 10, height: 2 }} />
          </div>
          <div className="w-100p">
            <div className="position-relative w-100p">
              <Slider
                renderThumb={(props, state) => (
                  <div key={state.valueNow} id="slider-popup-gallery" {...props}>
                    {isGalleryHovered ? (
                      <Tooltip
                        key={state.valueNow}
                        innerClassName="mb-3"
                        hideArrow
                        target="slider-popup-gallery"
                      >
                        {zoomPercentage}&nbsp;%
                      </Tooltip>
                    ) : null}
                  </div>
                )}
                onChange={setZoomPercentage}
                step={10}
                min={100}
                max={200}
                value={zoomPercentage}
              />
            </div>
          </div>
          <div
            onClick={() => onPressZoomKey(false)}
            className="cursor-pointer py-2 cms-12 d-flex align-items-center"
          >
            <SvgRender src={plusIcon} style={{ width: 10, height: 11 }} />
          </div>
        </div>
        {zoomPercentage !== 100 ? (
          <div
            onClick={resetZoom}
            className={`gallery__center--container-zoom-reset ${
              isGalleryHovered ? '' : 'gallery__center--container-zoom-reset--hide'
            }`}
          >
            <SvgRender src={minimizeIcon} style={{ width: 16, height: 16 }} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Image;
