import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';

const NationalitySelector = ({ filter, onChange, isMulti, listParams, ...rest }) => {
  const [selectedNationalities, setSelectedNationalities] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'nationalities', listParams },
    { selected: selectedNationalities, setSelected: setSelectedNationalities }
  );

  return (
    <Select
      placeholder="Select value"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      value={
        filter.value
          ? selectedNationalities.filter(el => filter.value.map(Number).includes(el.id))
          : []
      }
      isAsync
      autoFocus
      isMulti={isMulti}
      loadOptions={search => getAsyncOptions(search, 'nationalities', listParams)}
      defaultOptions={() => getInitialAsyncValues('nationalities', null, null, listParams)}
      onChange={selected => {
        setSelectedNationalities(selected);
        onChange({
          value: selected
            ? isMulti
              ? [...selected.map(s => s.id)]
              : selected.length
              ? selected[0].id
              : null
            : ''
        });
      }}
      {...rest}
    />
  );
};

export default NationalitySelector;
