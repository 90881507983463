import { FC } from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';

import CircledButton from '@/ts-common/components/buttons/CircledButton.tsx';

import ShadowBox from '@/common/components/general/ShadowBox';
import { MgaCurrencyType } from '@/common/types/mga.ts';
import save from '@/common/assets/svg/actions/save.svg';

import { FormState as FormStateType } from '@/common/types/form';
import { useFormState } from 'utils/hooks';
import ExtraCurrency from './ExtraCurrency';

type ContainerProps = {
  currency: MgaCurrencyType;
  resetForm: () => void;
  formState: FormStateType;
  initForm: () => void;
  hideRestCurrencies: boolean;
  setShouldHideCurrencies: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
};

const Container: FC<ContainerProps> = ({
  currency,
  resetForm,
  onSave,
  hideRestCurrencies = false,
  setShouldHideCurrencies,
  formState,
  initForm
}) => {
  const [mode, setMode] = useState<'edit' | 'view'>('view');
  const { subStates, addSubform, removeSubform } = useFormState(formState);
  const isBaseCurrency = currency?.isBase;
  const isEditMode = mode === 'edit' && isBaseCurrency;
  const isViewMode = mode === 'view' && isBaseCurrency;
  const shouldHideCurrency = hideRestCurrencies && !isBaseCurrency;

  const extraState = subStates('extra');

  const onClose = () => {
    resetForm();
    initForm();
    setMode('view');
    setShouldHideCurrencies(false);
  };

  const onEdit = () => {
    setMode('edit');
    setShouldHideCurrencies(true);
  };

  if (shouldHideCurrency) return null;

  const onSaveForm = async () => {
    try {
      await onSave();
      setMode('view');
      setShouldHideCurrencies(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ShadowBox
      color="light-1"
      shadowOnHover={isBaseCurrency}
      flat
      className={`d-flex ${
        mode === 'view' ? 'align-items-center' : 'flex-column'
      }  ps-3 pe-1 cpy-4 position-relative cmb-2`}
      key={currency?.id}
    >
      <div className="d-flex align-items-center flex-1">
        <div className="fs-12 fw-medium text-primary">{currency?.label}</div>

        {isBaseCurrency ? (
          <div className="fs-12 fw-medium text-violet ms-1">Base Currency</div>
        ) : null}
      </div>

      {isEditMode ? (
        <div className="d-flex flex-column align-items-start mt-3 mb-3">
          <div className="form-label fs-">Extra Currencies Used</div>
          {extraState.map((state: FormStateType, index: number) => (
            <ExtraCurrency
              key={index}
              state={state}
              extraState={extraState}
              onRemove={() => removeSubform('extra', index)}
            />
          ))}

          <CircledButton
            type={'add'}
            size={16}
            className="cmt-6"
            label="Add currency"
            onClick={() => addSubform('extra')}
            svgStyle={{ width: 8, height: 8 }}
          />
        </div>
      ) : null}

      {isViewMode ? (
        <CircledButton
          size={16}
          svgStyle={{ width: 9, height: 9 }}
          svgColor="white"
          backgroundColor="primary"
          type="edit"
          onClick={onEdit}
        />
      ) : isEditMode ? (
        <ActionsContainer>
          <CircledButton
            size={16}
            svgStyle={{ width: 8, height: 8 }}
            className="cme-4"
            type="close"
            onClick={onClose}
          />

          <CircledButton
            size={16}
            icon={save}
            svgStyle={{ width: 8, height: 8 }}
            type="send"
            onClick={onSaveForm}
          />
        </ActionsContainer>
      ) : null}
    </ShadowBox>
  );
};

export default Container;

export const ActionsContainer = styled.div`
  position: absolute;
  right: -0.4rem;
  top: 0.3rem;
`;
