export const nokConfig = {
  sections: [
    {
      groups: [
        [
          {
            label: 'NEXT OF KIN',
            key: 'next_of_kin'
          },
          {
            label: 'NOK address same as seafarer',
            key: 'nok_address_same_as_seafarer',
            className: 'd-none'
          },
          {
            label: 'NOK RELATION',
            key: 'nok_relation',
            className: 'pb-2'
          },
          {
            label: 'BIRTH DATE',
            key: 'nok_birth_date',
            className: 'pb-2'
          },
          {
            label: 'NUMBER OF CHILDREN',
            key: 'nok_no_children',
            className: 'pb-2'
          },
          {
            label: 'EMAIL',
            key: 'nok_email',
            className: 'pb-2'
          },
          {
            label: 'MOBILE NUMBER',
            key: 'nok_mobile_number',
            className: 'pb-2'
          },
          {
            label: 'PHONE NUMBER',
            key: 'nok_phone_number',
            className: 'pb-2'
          },
          {
            key: 'nok_address_same_id'
          }
        ]
      ]
    },
    {
      sectionClassName: 'nok-address-preview',
      header: {
        label: 'ADDRESS',
        className: 'mt-0 g-0',
        width: 'auto',
        color: 'white'
      },
      groups: [
        [
          {
            label: 'ADDRESS 1',
            key: 'nok_address_1'
          },
          {
            label: 'CITY',
            key: 'nok_city'
          },
          {
            label: 'ZIP CODE',
            key: 'nok_zip'
          },
          {
            label: 'COUNTRY',
            key: 'nok_country.name',
            select_key: 'nok_country',
            type: 'select'
          }
        ]
      ]
    }
  ]
};

export const nokConfigWithSameAddress = {
  sections: [
    {
      groups: [
        [
          {
            label: 'NEXT OF KIN',
            key: 'next_of_kin'
          },
          {
            label: 'NOK address same as seafarer',
            key: 'nok_address_same_as_seafarer',
            className: 'd-none'
          },
          {
            label: 'NOK RELATION',
            key: 'nok_relation'
          },
          {
            label: 'BIRTH DATE',
            key: 'nok_birth_date',
            className: 'pb-2'
          },
          {
            label: 'NUMBER OF CHILDREN',
            key: 'nok_no_children',
            className: 'pb-2'
          },
          {
            label: 'EMAIL',
            key: 'nok_email',
            className: 'pb-2'
          },
          {
            label: 'MOBILE NUMBER',
            key: 'nok_mobile_number',
            className: 'pb-2'
          },
          {
            label: 'PHONE NUMBER',
            key: 'nok_phone_number',
            className: 'pb-2'
          },
          {
            key: 'nok_address_same_id',
            className: 'd-none'
          }
        ]
      ]
    },
    {
      sectionClassName: 'nok-address-preview',
      header: {
        label: 'ADDRESS',
        className: 'mt-0 g-0',
        width: 'auto',
        color: 'white'
      },
      groups: [
        [
          {
            label: 'ADDRESS 1',
            key: 'nok_address_same.address'
          },
          {
            label: 'CITY',
            key: 'nok_address_same.city'
          },
          {
            label: 'ZIP CODE',
            key: 'nok_address_same.zip'
          },
          {
            label: 'COUNTRY',
            key: 'nok_address_same.country.name',
            select_key: 'nok_address_same.country',
            type: 'select'
          }
        ]
      ]
    }
  ]
};
