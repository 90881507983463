import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Table from 'common/components/table';
import modularTableHook from 'common/components/table/utils/useModularTable';
import PageLoader from 'common/components/general/PageLoader';

const ModularTable = ({
  className = '',
  style = 'report',
  setPageMaxHeight = style === 'default' ? false : true,
  highlightColumnOnHover = style === 'default' ? false : true,
  ...tableProps
}) => {
  const modularTableRef = useRef(null);
  const [topDistance, setTopDistance] = useState(null);

  useEffect(() => {
    if (modularTableRef && style === 'report' && setPageMaxHeight)
      setTopDistance(modularTableRef.current.getBoundingClientRect().top);
  }, [setPageMaxHeight, style]);

  return (
    <div
      ref={modularTableRef}
      className={`modular-table modular-table--${style} ${className}`}
      style={{ maxHeight: topDistance ? `calc(98vh - ${topDistance}px)` : '' }}
    >
      {tableProps?.state?.invalid || !tableProps.loader ? null : (
        <PageLoader isLoading={!tableProps.columns?.length} />
      )}
      {tableProps.columns?.length ? (
        <Table {...tableProps} highlightColumnOnHover={highlightColumnOnHover} />
      ) : null}
    </div>
  );
};

export default ModularTable;

ModularTable.propTypes = {
  style: PropTypes.oneOf(['report', 'default', 'transparent', 'widget'])
};

export const useModularTable = modularTableHook;
