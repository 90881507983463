import { createAsyncThunk } from '@reduxjs/toolkit';
import { successHandler } from 'common/utils/notifications';
import { get, post, deleteRequest, put } from 'utils/api';

export const getOfficeAttachments = createAsyncThunk(
  'GET_OFFICE_ATTACHMENTS',
  async (params, { rejectWithValue }) => {
    const { event_id } = params;

    try {
      const res = await get(`/events/${event_id}/office-attachments`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createOfficeAttachments = createAsyncThunk(
  'CREATE_OFFICE_ATTACHMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/office-attachments/group`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateOfficeAttachments = createAsyncThunk(
  'UPDATE_OFFICE_ATTACHMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, attachment_group_id, ...rest } = params;

    try {
      const res = await put(
        `/events/${event_id}/office-attachments/group/${attachment_group_id}`,
        rest
      );
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteOfficeAttachments = createAsyncThunk(
  'DELETE_OFFICE_ATTACHMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, attachment_group_id } = params;

    try {
      const res = await deleteRequest(
        `/events/${event_id}/office-attachments/group/${attachment_group_id}`
      );
      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
