import * as d3 from 'd3';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const styleDefinitions = [
  { type: 'attr', key: 'stroke', style: 'areaBorderColor', defaultValue: 'none' },
  { type: 'attr', key: 'opacity', style: 'areaOpacity', defaultValue: 0.33 },
  { type: 'attr', key: 'stroke-width', style: 'areaBorderWidth', defaultValue: 0 },
  { type: 'attr', key: 'fill', style: 'areaBackgroundColor', defaultValue: variables.primary }
];

const renderPlot = ({ plotID, plot, getPlotStyle, xFn, yFn, y0Fn, element, plotIndex }) => {
  const area = d3
    .area()
    .x(xFn)
    .y1(yFn)
    .y0(y0Fn)
    // The .defined below is a d3 built-in function that will seperate the <path /> element into multiples when it seed data missing (null yAxis values).
    .defined(d => !(d.y !== 0 && !d.y));

  const existingElement = element.select(`.${plotID}`);

  const areaElement = (!existingElement.empty()
    ? existingElement
    : element.append('custom').classed(`area ${plotID} plot-${plotIndex}`, true)
  )
    .attr('d', area(plot.data))
    .attr('plot-index', plotIndex);

  styleDefinitions.forEach(styleDefinition => {
    areaElement[styleDefinition.type](
      styleDefinition.key,
      getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
    );
  });
};

const area = {
  renderPlot
};

export default area;
