import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { selectFieldVisibility } from 'captain-reports/store/selectors';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr } from 'common/utils/numbers';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const GarbageTotalRobField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  tooltip,
  subGroupIndex
}) => {
  const isFoodWastesVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_food_wastes')
  );

  const isPlasticVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_plastic')
  );

  const isDomesticWastesVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_domestic_wastes')
  );

  const isCookingOilVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_cooking_oil')
  );

  const isIncineratorAshesVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_incinerator_ashes')
  );

  const isOperationalWastesVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_operational_wastes')
  );

  const isCargoResiduesVisible = useSelector(state =>
    selectFieldVisibility(state, 'garbage_rob_cargo_residues')
  );

  const isViewOnly =
    isFoodWastesVisible ||
    isPlasticVisible ||
    isDomesticWastesVisible ||
    isCookingOilVisible ||
    isIncineratorAshesVisible ||
    isOperationalWastesVisible ||
    isCargoResiduesVisible;

  const value = useValue({
    key: 'garbage_rob_total',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  return (
    <Row className="align-items-center mt-6">
      <Col xs={12}>
        <div className={`${isViewOnly ? 'd-none' : ''}`}>
          <NumberField
            field={field}
            sectionLabel={sectionLabel}
            sectionActiveTab={sectionActiveTab}
            subGroup={subGroup}
            subGroupIndex={subGroupIndex}
          />
        </div>

        {isViewOnly ? (
          <ValidationWrapper
            field={{ ...field, subLabel: '' }}
            value={value}
            labelClassName="pt-1"
            errorClassName="align-items-center"
          >
            <strong className={`fs-12 `}>{numberToStr(value)}</strong>
          </ValidationWrapper>
        ) : null}
      </Col>
    </Row>
  );
};

export default GarbageTotalRobField;
