import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_JOB_FORMS'),
  ...asyncTypes('ADD_FORM_TO_JOB'),
  ...asyncTypes('REMOVE_JOB_FORM'),
  ...asyncTypes('UPDATE_JOB_FORM'),
  BULK_REMOVE_JOB_FORMS: 'BULK_REMOVE_JOB_FORMS',
  ADD_JOB_FORM_TO_STATE: 'ADD_JOB_FORM_TO_STATE',
  REMOVE_JOB_FORM_FROM_STATE: 'REMOVE_JOB_FORM_FROM_STATE',
  UPDATE_JOB_FORM_SUBMISSION: 'UPDATE_JOB_FORM_SUBMISSION',
  REMOVE_JOB_FORMS_FROM_STATE: 'REMOVE_JOB_FORMS_FROM_STATE'
};

export default TYPES;
