import {
  VesselDocumentMutationParams,
  GetVesselDocumentAttchmentsParams,
  VesselDocumentsQueryParams,
  createVesselDocument,
  getVesselDocumentAttchaments,
  getVesselDocuments,
  editVesselDocument,
  renewVesselDocument,
  GetVesselDocumentHistoryParams,
  getVesselDocumentHistory,
  archiveDocumentDocument,
  VesselDocumentDeletionParams
} from '@/api/vessels/api';
import { VesselDocumentCategory, VesselDocumentType } from '@/common/types/vessel';
import { successHandler } from '@/common/utils/notifications';
import { AppDispatch } from '@/store';
import { Attachment } from '@/ts-common/types/files';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TYPES, { DrawerStatus } from './types';
import { downloadAllFilesOfEntity } from '@/api/files/api';
import { selectDocumentSearch } from '@/store/vessels/selectors';

export const getVesselDocumentsAction = createAsyncThunk<
  VesselDocumentCategory[],
  VesselDocumentsQueryParams
>(TYPES.GET_VESSEL_DOCUMENTS, async (params, { rejectWithValue, getState }) => {
  const search = selectDocumentSearch(getState());

  try {
    const res = await getVesselDocuments({
      ...params,
      search: search || undefined
    });

    return res;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const createVesselDocumentAction = createAsyncThunk<
  VesselDocumentType,
  VesselDocumentMutationParams
>(
  TYPES.CREATE_VESSEL_DOCUMENT,
  async (params: VesselDocumentMutationParams, { rejectWithValue, dispatch }) => {
    try {
      const res = await createVesselDocument(params);

      dispatch(successHandler({ title: 'Success!', message: 'Document added successfully' }));
      dispatch(getVesselDocumentsAction({ id: params?.vessel_id }));

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editVesselDocumentAction = createAsyncThunk<
  VesselDocumentType,
  VesselDocumentMutationParams
>(
  TYPES.EDIT_VESSEL_DOCUMENT,
  async (params: VesselDocumentMutationParams, { rejectWithValue, dispatch }) => {
    try {
      const res = await editVesselDocument(params);

      dispatch(successHandler({ title: 'Success!', message: 'Document was updated' }));
      dispatch(getVesselDocumentsAction({ id: params?.vessel_id }));

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const renewVesselDocumentAction = createAsyncThunk<
  VesselDocumentType,
  VesselDocumentMutationParams
>(
  TYPES.RENEW_VESSEL_DOCUMENT,
  async (params: VesselDocumentMutationParams, { rejectWithValue, dispatch }) => {
    try {
      const res = await renewVesselDocument(params);

      dispatch(successHandler({ title: 'Success!', message: 'Document was updated' }));
      dispatch(getVesselDocumentsAction({ id: params?.vessel_id }));

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const archiveDocumentDocumentAction = createAsyncThunk<
  VesselDocumentType,
  VesselDocumentDeletionParams
>(
  TYPES.ARCHIVE_VESSEL_DOCUMENT,
  async (params: VesselDocumentDeletionParams, { rejectWithValue, dispatch }) => {
    try {
      const res = await archiveDocumentDocument(params);

      dispatch(successHandler({ title: 'Success!', message: 'Document was deleted' }));
      dispatch(getVesselDocumentsAction({ id: params?.vessel_id }));

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getVesselDocumentAttachmentsAction = createAsyncThunk<
  Attachment[],
  GetVesselDocumentAttchmentsParams
>(
  TYPES.GET_VESSEL_DOCUMENT_ATTACHMENTS,
  async (params: GetVesselDocumentAttchmentsParams, { rejectWithValue }) => {
    try {
      const res = await getVesselDocumentAttchaments(params);

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getVesselDocumentHistoryAction = createAsyncThunk<
  VesselDocumentType[],
  GetVesselDocumentHistoryParams
>(
  TYPES.GET_VESSEL_DOCUMENT_HISTORY,
  async (params: GetVesselDocumentHistoryParams, { rejectWithValue }) => {
    try {
      const res = await getVesselDocumentHistory(params);

      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const downloadVesselDocumentAttachments = (params: { id: string | number }) => {
  return downloadAllFilesOfEntity({ id: params.id, type: 'vessel_document' });
};

export const changeVesselDocumentDrawerStatus =
  (params: DrawerStatus) => (dispatch: AppDispatch) => {
    dispatch({ type: TYPES.CHANGE_VESSEL_DOCUMENT_DRAWER_STATUS, payload: params });
  };

export const expandAllVesselDocumentPanels = (params: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: TYPES.EXPAND_ALL_VESSEL_DOCUMENT_PANELS, payload: params });
};

export const setVesselDocumentsSearch = (params: string | null) => (dispatch: AppDispatch) => {
  dispatch({ type: TYPES.SET_VESSEL_DOCUMENTS_SEARCH, payload: params });
};
