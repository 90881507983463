import Dashboard from 'views/dashboard';

export default [
  {
    type: 'private',
    exact: true,
    app: true,
    path: '/',
    component: Dashboard,
    permissions: null,
    documentTitle: 'Dashboard'
  }
];
