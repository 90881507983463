import { hiddenField, booleanField, stringField, optionField } from 'common/utils/form/fieldTypes';

const config = {
  criteria: {
    id: hiddenField(),
    name: hiddenField(),
    type: hiddenField(),
    scale: stringField(),
    trainings: optionField(),
    radios: booleanField({ initialValue: true }),
    text: stringField()
  },
  remarks: stringField()
};

export default config;
