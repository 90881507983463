import React, { useCallback, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
  toolbarConfig,
  pluginConfig,
  imagesConfig,
  tablesConfig,
  fontColor,
  fontBackgroundColor
} from './config';

import PropTypes from 'prop-types';

import CustomImageUploadAdapterPlugin from './CustomImageUploadAdapterPlugin';
import { getImgTagsFromHtml } from 'common/utils/texts';

const CkEditor = ({
  onChange,
  id,
  className = '',
  isFocused,
  onFocus,
  value,
  maxHeight,
  minHeight,
  customToolbar,
  customPlugins,
  placeholder = '',
  isFullEditor = false,
  uploadedImagesGroup = null,
  styled = false,
  onImageUpload,
  uploadImagesRequestPath = '/files', // override the request path to upload files
  invisible,
  disabled,
  error
}) => {
  const [editor, setEditor] = useState();
  const editorRef = React.useRef();

  const [isLoading, setIsLoading] = useState(true);

  let toolbar = customToolbar || toolbarConfig;
  let plugins = customPlugins || pluginConfig;

  const imageUploadCallback = useCallback(
    image => {
      onImageUpload([image]);
    },
    [onImageUpload]
  );

  const configToUploadImages = uploadedImagesGroup
    ? {
        uploadImagesRequestPath,
        uploadedImagesGroup,
        imageUploadCallback,
        extraPlugins: [CustomImageUploadAdapterPlugin],
        image: {
          toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'linkImage'
          ]
        },
        allowedContent: 'img[alt,fileId,!src]{width,height}'
      }
    : {};

  const config = {
    plugins:
      uploadedImagesGroup && !customPlugins ? [...plugins, ...imagesConfig.plugins] : plugins,
    toolbar:
      uploadedImagesGroup && !customToolbar ? [...toolbar, ...imagesConfig.toolbar] : toolbar,
    placeholder: placeholder,
    fontColor,
    table: tablesConfig,
    fontBackgroundColor,
    link: {
      defaultProtocol: 'https://',
      decorators: {
        isExternal: {
          mode: 'automatic',
          callback: url => url.startsWith('https://'),
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer'
          }
        }
      }
    },
    ...configToUploadImages
  };

  useEffect(() => {
    if (!window.DecoupledEditor) {
      return;
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      setEditor(null);
    };
  }, []);

  return isLoading ? null : (
    <div
      className={`ckeditor_container ${className} ${styled ? 'styled-editor' : ''} ${
        invisible ? 'invisible' : ''
      } ${disabled ? 'pointer-events-none disabled' : ''}`}
      onFocus={() => onFocus(true)}
      ref={editorRef}
    >
      <div className={`${isFullEditor ? 'document-editor' : 'small-editor'}`}>
        <div className={`${isFullEditor ? 'document-editor__toolbar' : 'small-editor__toolbar'}`}>
          <div
            className={`${
              isFullEditor
                ? 'document-editor__editable-container'
                : 'small-editor__editable-container'
            } ${
              !isFullEditor && !isFocused ? 'small-editor__editable-container--notEditMode' : ''
            } ${error ? 'has_error' : ''}`}
            style={{ maxHeight: maxHeight || '', minHeight: minHeight || '' }}
          >
            {!isLoading ? (
              <CKEditor
                disableWatchdog
                onReady={innerEditor => {
                  if (innerEditor) {
                    innerEditor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(
                        innerEditor.ui.view.toolbar.element,
                        innerEditor.ui.getEditableElement()
                      );

                    innerEditor.ui.focusTracker.on('change:isFocused', (_, __, isFocused) => {
                      if (!isFocused) {
                        onFocus(false);
                      } else {
                        onFocus(true);
                      }
                    });

                    innerEditor.editing.view.document.on('clipboardInput', (evt, data) => {
                      const dataTransfer = data.dataTransfer;
                      const htmlContent = dataTransfer.getData('text/html');

                      // If no conent was pasted, abort and let the clipboard feature handle the input.
                      if (disabled || !htmlContent) {
                        return;
                      }

                      if (onImageUpload && getImgTagsFromHtml(htmlContent)) {
                        // When image uploading is requiered, pasting content with img tags is not allowed
                        const plainData = data.dataTransfer.getData('text/plain');
                        innerEditor.execute('input', { text: plainData });
                        evt.stop();
                      }
                    });
                  }

                  setEditor(innerEditor);
                }}
                onError={({ willEditorRestart, ...e }) => {
                  console.log('ckeditor onError');
                  console.log(willEditorRestart);
                  console.log(e);

                  if (willEditorRestart) {
                    setEditor(editor.ui.view.toolbar.element.remove());
                  }
                }}
                onChange={(_, editor) => {
                  if (onChange) {
                    onChange(editor.getData());
                  }
                }}
                id={id}
                editor={window.DecoupledEditor}
                data={value}
                config={config}
                error={error}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CkEditor;

CkEditor.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  placeholder: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  isFullEditor: PropTypes.bool,
  styled: PropTypes.bool,
  customToolbar: PropTypes.array,
  uploadedImagesGroup: PropTypes.string, // s3 group to store the uploaded images
  onImageUpload: PropTypes.func,
  error: PropTypes.string
};
