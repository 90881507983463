import { useEffect, useState } from 'react';

import EmptyState from './EmptyState';
import Form from './form';
import Modal from './Modal';
import PageLoader from 'common/components/general/PageLoader';

import useActions from 'common/utils/hooks/useActions';
import * as mgaActions from 'common/components/mga/store/actions';

import { useSelector } from 'react-redux';
import {
  selectVesselFilter,
  selectPeriodFilter,
  selectIsPastMga
} from 'common/components/mga/store/selectors';

import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';

const MgaPeriod = () => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const vesselFilter = useSelector(selectVesselFilter);
  const periodFilter = useSelector(selectPeriodFilter);
  const isPastMga = useSelector(selectIsPastMga);

  const { history, match } = useRouter();

  const [isLoading, setIsLoading] = useState(isOnBoard);
  const [getMgaPeriods] = useActions([mgaActions.getMgaPeriods]);

  const initPeriods = async params => {
    try {
      setIsLoading(true);

      const periods = await getMgaPeriods(params);

      setIsLoading(false);

      if (params?.vessel_id && periods?.length && !periodFilter) {
        history.replace(`${paths.mga}/${match.params.view}/${vesselFilter}/${periods[0]?.id}`);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOnBoard) initPeriods(isPastMga ? { exclude_status_labels: ['draft'] } : {});
    else if (vesselFilter) initPeriods({ vessel_id: vesselFilter });
  }, [vesselFilter]);

  return (
    <>
      {!isLoading && !isPastMga ? <EmptyState /> : null}
      <Form />
      <Modal />
      <PageLoader isLoading={isLoading} />
    </>
  );
};

export default MgaPeriod;
