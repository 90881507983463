import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getEventVetting = createAsyncThunk(
  'GET_EVENT_VETTING',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await get(`/events/${id}/vetting`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEventVetting = createAsyncThunk(
  'UPDATE_EVENT_VETTING',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await put(`/events/${id}/vetting`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getEventVettingObservations = createAsyncThunk(
  'GET_EVENT_VETTING_OBSERVATIONS',
  async (params, { rejectWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await get(`/events/${id}/vetting/observations`, rest);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const addEventVettingObservation = createAsyncThunk(
  'ADD_EVENT_VETTING_OBSERVATION',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, ...rest } = params;

    try {
      const res = await post(`/events/${event_id}/vetting/observations`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Created successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEventVettingObservation = createAsyncThunk(
  'UPDATE_EVENT_VETTING_OBSERVATION',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, id, ...rest } = params;

    try {
      const res = await put(`/events/${event_id}/vetting/observations/${id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteEventVettingObservation = createAsyncThunk(
  'DELETE_EVENT_VETTING_OBSERVATION',
  async (params, { rejectWithValue, dispatch }) => {
    const { event_id, id, ...rest } = params;

    try {
      const res = await deleteRequest(`/events/${event_id}/vetting/observations/${id}`, rest);
      dispatch(successHandler({ title: 'Success!', message: 'Deleted successfully' }));

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
