import DateInput, { mode } from 'common/components/form/inputs/date';
import moment from 'moment';

const DateRangeInput = ({ disabled, error, value, onChange }) => {
  return (
    <DateInput
      activeMode={mode.range.date}
      error={error}
      from={disabled || !value?.from ? null : moment(value.from, 'YYYY-MM-DD')}
      to={disabled || !value?.to ? null : moment(value.to, 'YYYY-MM-DD')}
      onChange={({ from, to }) =>
        onChange({
          from: from ? from.format('YYYY-MM-DD') : null,
          to: to ? to.format('YYYY-MM-DD') : null
        })
      }
    />
  );
};

export default DateRangeInput;
