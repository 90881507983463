import React from 'react';
import ShadowBox from 'common/components/general/ShadowBox';
import bin from 'common/assets/svg/common/bin.svg';
import rank from 'common/assets/svg/crew/rank.svg';
import SvgRender from 'common/components/general/SvgRender';
import { Button, Col, Row } from 'reactstrap';
import { dateToLocal } from 'common/utils/dates';
import ProfileImage from 'common/components/general/ProfileImage';

import avatar from 'common/assets/svg/avatars/crew.svg';
import _orderBy from 'lodash/orderBy';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import PropTypes from 'prop-types';

const SignaturePreview = ({
  signaturesData,
  setShowDeleteModal,
  disabledDelete,
  viewOnly = false
}) => {
  if (!signaturesData?.length) return null;

  return (
    <Row className={`${viewOnly ? '' : 'border-top pt-3 mt-3'}`} noGutters>
      {_orderBy(signaturesData, 'created_at', 'desc').map(item => {
        return (
          <Col xs={12} className="mb-1" key={item.id}>
            <ShadowBox
              className="cps-4 pe-2 cpy-4 w-100p h-100p d-flex align-items-center"
              color="light-1"
              flat
            >
              <div className="me-2 d-flex align-items-center">
                <ProfileImage
                  image={item?.file.url_inline}
                  style={{ width: 32, height: 32 }}
                  fallback={avatar}
                  className="bg-white"
                />
              </div>

              <div className="d-flex-column ">
                <div
                  className={`d-flex text-primary fs-12 fw-medium ${
                    item.party.crew_rank?.name ? 'text-nowrap' : ''
                  } `}
                >
                  <div className={`${item.party.crew_rank?.name ? 'max-width-182' : null}`}>
                    {item.party.crew_rank?.name ? (
                      <TextWithTooltip>{item.party.full_name}</TextWithTooltip>
                    ) : (
                      item.party.full_name
                    )}
                  </div>
                  {item.party.crew_rank?.name ? (
                    <div className="d-flex align-items-center text-violet ms-1">
                      <SvgRender src={rank} style={{ width: 12, height: 12 }} className="me-1" />{' '}
                      <div className="max-width-182">
                        <TextWithTooltip>{item.party.crew_rank?.name}</TextWithTooltip>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="text-violet fs-10">
                  Signed on: {dateToLocal(item.created_at).format('DD/MM/YYYY, HH:mm')}
                </div>
              </div>
              <div className="ms-auto">
                {viewOnly ? null : (
                  <Button
                    disabled={disabledDelete}
                    color="link"
                    className="d-inline-flex align-items-center p-0 text-red"
                    onClick={() => setShowDeleteModal({ isOpen: true, signature_id: item.id })}
                  >
                    <SvgRender src={bin} style={{ width: 14, height: 16 }} />
                  </Button>
                )}
              </div>
            </ShadowBox>
          </Col>
        );
      })}
    </Row>
  );
};

SignaturePreview.propTypes = {
  signaturesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      created_at: PropTypes.string,
      party: PropTypes.shape({
        crew_rank: PropTypes.shape({
          name: PropTypes.string
        })
      }),
      file: PropTypes.shape({
        url_inline: PropTypes.string
      })
    })
  ),

  setShowDeleteModal: PropTypes.func,
  disabledDelete: PropTypes.bool,
  viewOnly: PropTypes.bool
};

export default SignaturePreview;
