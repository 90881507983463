import React from 'react';
import FieldRow from './FieldRow';
import InlineMultipleFieldsRow from './InlineMultipleFieldsRow';
import GroupFields from './GroupFields';
import _get from 'lodash/get';

import { Row, Col } from 'reactstrap';

const renderMultipleField = (group, profile) => {
  const { key } = group;
  const filtered = group.data.filter(d => d.render !== false);

  if (profile[key] && profile[key].length) {
    return profile[key].map((value, index) => (
      <GroupFields key={index} className={group.inline ? 'inline-group' : ''}>
        {group.inline ? (
          group.condition === undefined || group.condition(profile) ? (
            <InlineMultipleFieldsRow
              field={group}
              data={filtered}
              profileValue={profile[key][index]}
            />
          ) : null
        ) : (
          filtered.map((d, dindex) => {
            return (
              <React.Fragment key={dindex}>
                {d.header ? (
                  index === 0 ? (
                    <Row className="g-0">
                      <Col xs={4} className="border-end">
                        <div className="ps-4 pt-2 fw-bold fs-12">{d.header}</div>
                      </Col>
                    </Row>
                  ) : null
                ) : null}
                <FieldRow
                  key={dindex}
                  field={{
                    ...d,
                    key: `${key}[${index}]${d.key ? `.${d.key}` : ''}`,
                    label: d.labelKey ? _get(value, d.labelKey, '-') || '-' : d.label
                  }}
                  findex={dindex}
                  group={group.data}
                  profile={profile}
                />
              </React.Fragment>
            );
          })
        )}
      </GroupFields>
    ));
  } else {
    return null;
  }
};

export default renderMultipleField;
