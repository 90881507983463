import { useSelector } from 'react-redux';
import { cannotEditManual } from 'manuals/store/selectors';

import emptyRibbon from 'common/assets/svg/common/ribbon-line.svg';
import SvgRender from 'common/components/general/SvgRender';
import CircledButton from 'common/components/buttons/CircledButton';

const EmptyState = ({ handleChapterCreation }) => {
  const isLocked = useSelector(cannotEditManual);

  return (
    <div className="chapters-tree__empty">
      <div className="d-flex align-items-center position-relative">
        <SvgRender
          className="chapters-tree__empty-icon"
          src={emptyRibbon}
          style={{ width: 94, height: 94 }}
        />

        {isLocked ? (
          <div className="chapters-tree__empty-text">
            <strong>No chapters</strong>
          </div>
        ) : (
          <CircledButton
            type="add"
            className="chapters-tree__empty-action text-primary fw-bold"
            label={'Add Chapter'}
            onClick={handleChapterCreation}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyState;
