import EmptyPage from 'common/components/general/EmptyPage';
import accounting from 'common/assets/svg/accounting/calculate.svg';

import { useSelector } from 'react-redux';
import {
  selectHasSetPeriods,
  selectLatestPeriod,
  selectVesselFilter
} from 'common/components/mga/store/selectors';

import useActions from 'common/utils/hooks/useActions';
import * as mgaActions from 'common/components/mga/store/actions';

const EmptyState = () => {
  const [setMgaPeriodForm] = useActions([mgaActions.setMgaPeriodForm]);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const hasPeriods = useSelector(selectHasSetPeriods);
  const latestPeriod = useSelector(selectLatestPeriod);

  const vesselFilter = useSelector(selectVesselFilter);

  if (!isOnBoard && !vesselFilter)
    return (
      <EmptyPage
        className="mga-empty-page"
        pageText={<span>You have not selected any Vessel yet.</span>}
        pageIcon={accounting}
      />
    );

  return !hasPeriods ? (
    <EmptyPage
      pageText={<span>This is the first time you are going to use MGA in Orca.</span>}
      pageIcon={accounting}
      buttonOnClick={() => setMgaPeriodForm({ isOpen: true })}
      buttonText={'Set Balances & Create MGA Period'}
      buttonType="add"
      svgClassName="bg-yellow text-primary"
    />
  ) : !latestPeriod ? (
    <EmptyPage
      className="mga-empty-page"
      pageText={<span>No more MGA Draft periods are set</span>}
      pageIcon={accounting}
    />
  ) : null;
};

export default EmptyState;
