import SvgRender from 'common/components/general/SvgRender';
import archiveIcon from 'common/assets/svg/actions/archive.svg';

const ArchiveLabel = () => {
  return (
    <div className="d-flex align-items-center cps-12 cpe-12 py-1 ms-3 bg-primary border-radius-3 opacity-4 min-height-30 max-height-30">
      <SvgRender src={archiveIcon} style={{ width: 14, height: 19 }} className="text-white me-1" />
      <div className="fs-12 fw-bold text-white">ARCHIVED</div>
    </div>
  );
};

export default ArchiveLabel;
