import _get from 'lodash/get';
import { CrewContract } from '@/common/types/crew.ts';
import { Port } from '@/common/types/ports.ts';

export const selectContractDate = (contract: CrewContract, isSignOn = true): string | undefined => {
  const key = isSignOn ? 'sign_on' : 'sign_off';

  const dateAsPerCaptain = _get(contract, `${key}_date_as_per_captain`);
  const signDate = _get(contract, `${key}_date`);

  // If we have both then always show sign office date
  if (dateAsPerCaptain && signDate) {
    return signDate;
  }

  return dateAsPerCaptain || signDate;
};

export const selectContractPort = (contract: CrewContract, isSignOn = true): Port | undefined => {
  const key = isSignOn ? 'sign_on' : 'sign_off';

  const portAsPerCaptain = _get(contract, `${key}_port`);
  const signPort = _get(contract, `${key}_port_as_per_office`);

  // If we have both then always show sign office port
  if (portAsPerCaptain && signPort) {
    return signPort;
  }

  return portAsPerCaptain || signPort;
};
