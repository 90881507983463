import React from 'react';

import CircledButton from 'common/components/buttons/CircledButton';

const AddRowButton = ({ addRow, label = 'Add Row' }) => {
  return (
    <div className="cps-12 mt-1">
      <CircledButton
        type={'add'}
        label={<strong className="fs-12 text-primary">{label}</strong>}
        onClick={() => addRow(false)}
        svgStyle={{ width: 8, height: 8 }}
        style={{ width: 16, height: 16 }}
      />
    </div>
  );
};

export default AddRowButton;
