import React from 'react';

import { Row, Col } from 'reactstrap';

import DateInput from 'common/components/form/inputs/date';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Information from 'common/components/general/Information';
import { getDatesDifferenceAsDays } from 'common/utils/dates';

import moment from 'moment';
import BaseStatusLabel from 'common/components/statuses/BaseStatusLabel';

const ClosedAt = ({ selectField, fields }) => {
  const shownDays = getDatesDifferenceAsDays(
    fields?.occurred_at?.value,
    fields?.closed_at?.value || moment()
  );

  return (
    <Row xs={12} className="d-flex">
      <Col xs={4}>
        <DateInput
          label="Closed at"
          value={fields?.closed_at?.value}
          from={fields?.closed_at?.value}
          onChange={selected => selectField('closed_at')(selected)}
        />
      </Col>
      <Col xs={8} className="d-flex align-items-center">
        <BaseStatusLabel
          value={fields?.closed_at?.value ? 'Closed' : 'Open'}
          color={fields?.closed_at?.value ? 'color_5' : 'color_3'}
          showLockIcon={false}
          className={'height-24 me-1'}
        />
        <div className="border-start border-pastel-gray height-24"></div>
        <Button
          color="white"
          className="px-1 text-primary height-24 fs-10 d-flex align-items-center ms-1"
        >
          <div className="d-flex">
            <strong>{shownDays}</strong>&nbsp; days open &nbsp;{' '}
            <Information
              message={
                fields?.closed_at?.value
                  ? 'Here we count days between Occurence and Case Close Date.'
                  : 'Here we count days since Occurence.'
              }
              svgStyle={{ width: 12, height: 12 }}
              tooltipClassname="min-width-fit max-width-fit"
              className="text-primary d-flex align-items-center"
            />
          </div>
        </Button>
      </Col>
    </Row>
  );
};

ClosedAt.propTypes = {
  selectField: PropTypes.func,
  fields: PropTypes.object
};

export default ClosedAt;
