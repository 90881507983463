import navigational from 'captain-reports/templates/config/navigational';
import weather from 'captain-reports/templates/config/weatherConditions';

import mainEngine from 'captain-reports/templates/config/mainEngine';
import remarks from 'captain-reports/templates/config/remarks';
import coolers from 'captain-reports/templates/config/coolers';
import freshWater from 'captain-reports/templates/config/freshWater';
import incinerator from 'captain-reports/templates/config/incinerator&sludges';
import cargos from 'captain-reports/templates/config/cargos';
import receivables from 'captain-reports/templates/config/receivables';
import boiler from 'captain-reports/templates/config/boiler';
import generators from 'captain-reports/templates/config/generators';
import bunkerConsumptions from 'captain-reports/templates/config/bunkerConsumptions';
import lubricants from 'captain-reports/templates/config/lubricants';
import otherInfo from 'captain-reports/templates/config/otherInfo';
import instructions from 'captain-reports/templates/config/instructions';
import flowmeters from 'captain-reports/templates/config/flowmeters';
import ballastWater from 'captain-reports/templates/config/ballastWater';
import cargoRelatedConsumptions from 'captain-reports/templates/config/cargoRelatedConsumptions';
import attachments from 'captain-reports/templates/config/attachments';
import passagePlan from 'captain-reports/templates/config/passagePlan';
import { arrayFields, multiTabsSections } from './_constants';

import pollutionIcon from 'common/assets/svg/common/pollution.svg';

import _flatten from 'lodash/flatten';
import _sortBy from 'lodash/sortBy';

import { initReportFields, getSectionConfigSubGroups } from './_helpers';

export const sectionSubGroups = {
  turbos: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].turbochargers_no : 1, // engines
  me_eg_deviations: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  ex_gas_max_temps: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  fuel_rack_indications: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  under_piston_max_temps: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  vit_air_indications: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  jacket_outlet_temps: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  lo_pistons_outlet_temps: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  alfa_lubricator_settings: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  co_feed_rates: ({ vessel, tabIndex }) =>
    vessel.engines[tabIndex] ? vessel.engines[tabIndex].cylinders_no : 1, // engines
  air_coolers: ({ vessel }) => vessel.air_coolers_no || 1, // coolers
  jacket_coolers: ({ vessel }) => vessel.jacket_coolers_no || 1, // coolers
  me_lo_coolers: ({ vessel }) => vessel.main_lo_coolers_no || 1, // coolers
  central_coolers: ({ vessel }) => vessel.central_coolers_no || 1,
  ex_gas_temps: ({ vessel, tabIndex }) =>
    vessel.generators[tabIndex] ? vessel.generators[tabIndex].diesel_engine_cylinders_no : 1, // engines
  consumptions: ({ template }) =>
    template && template.fuel_grades && template.fuel_grades.length ? template.fuel_grades : [], // bunker
  me_lo_pumps_no: ({ vessel }) => vessel.me_lo_pumps_no || 1, // Flowmeters
  main_cooler_sw_pumps_no: ({ vessel }) => vessel.main_cooler_sw_pumps_no || 1, // Flowmeters
  jacket_cooler_pumps_no: ({ vessel }) => vessel.jacket_cooler_pumps_no || 1, // Flowmeters
  me_ltcw_pumps_no: ({ vessel }) => vessel.me_ltcw_pumps_no || 1, // Flowmeters
  me_htcw_pumps_no: ({ vessel }) => vessel.me_htcw_pumps_no || 1, // Flowmeters
  fo_purifiers_no: ({ vessel }) => vessel.fo_purifiers_no || 1, // Flowmeters
  lo_purifiers_no: ({ vessel }) => vessel.lo_purifiers_no || 1, // Flowmeters
  ballast_water: ({ vessel }) => vessel.ballast_tanks || [], // Ballast water
  intermediate_bearing: ({ vessel }) => vessel.intermediate_bearings_no || 1 // General
};

export const sections = [
  { key: 'navigational', config: navigational },
  { key: 'weather', config: weather },
  { key: 'engines', config: mainEngine, side: 'left', title: 'MAIN ENGINE' },
  { key: 'coolers', config: coolers, side: 'left', title: 'COOLERS' },
  { key: 'freshWater', config: freshWater, side: 'left', title: 'FRESH WATER GENERATOR & WATERS' },
  { key: 'boiler', config: boiler, side: 'left', title: 'BOILER' },
  {
    key: 'incinerator',
    config: incinerator,
    side: 'left',
    title: 'INCINERATOR & SLUDGES'
  },
  { key: 'cargos', config: cargos, side: 'left', title: 'CARGOS' },
  { key: 'receivables', config: receivables, side: 'left', title: 'RECEIVABLES AND PORT ACTIONS' },
  { key: 'instructions', config: instructions, side: 'left', title: 'INSTRUCTIONS' },
  { key: 'attachments', config: attachments, side: 'left', title: 'ATTACHMENTS' },
  { key: 'passage_plan', config: passagePlan, side: 'left', title: 'PASSAGE PLAN' },
  {
    key: 'generators',
    config: generators,
    side: 'right',
    title: 'GENERATOR'
  },
  {
    key: 'cargoRelatedConsumption',
    config: cargoRelatedConsumptions,
    side: 'right',
    iconProps: {
      src: pollutionIcon,
      className: 'text-red',
      style: { height: 16 }
    },
    title: 'CARGO RELATED CONSUMPTION'
  },
  {
    key: 'bunker_consumptions',
    config: bunkerConsumptions,
    side: 'right',
    title: 'BUNKERS'
  },
  {
    key: 'ballastWater',
    config: ballastWater,
    side: 'right',
    title: 'BALLAST WATER'
  },
  {
    key: 'lubricants',
    config: lubricants,
    side: 'right',
    title: 'LUBRICANTS'
  },
  { key: 'flowmeters', config: flowmeters, side: 'right', title: 'FLOWMETERS, PUMPS & PURIFIERS' },
  { key: 'general', config: otherInfo, side: 'right', title: 'OTHER INFO' },
  { key: 'remarks', config: remarks, side: 'right', title: 'REMARKS' }
];

const fieldState = field => {
  const state = {
    value: null,
    error: null,
    type: field.type,
    validation: field.validation || null,
    sendOnlyValue: field.sendOnlyValue || false
  };

  if (field.selectRest && field.selectRest.selectKey) {
    // async Selects Full value object
    state.selectValue = null;
    state.selectKey = field.selectRest.selectKey;
  }

  return state;
};

const createFieldsState = (fields, vessel, tabIndex) => {
  const state = {};

  fields.forEach(field => {
    if (field?.subGroup) {
      const numberOfSubs = sectionSubGroups[field?.subGroup]({ vessel, tabIndex });

      state[field.subGroup] = [];

      for (let index = 0; index < numberOfSubs; index++) {
        field.fields.forEach(innerField => {
          state[field.subGroup].push({ [innerField.key]: innerField });
        });
      }
    } else {
      state[field.key] = fieldState(field);
    }
  });

  return state;
};

export const parseSectionFields = ({ section, vessel, template, tabIndex }) => {
  let state = {};
  const templateType = template && template.type ? template.type : 'noon'; // Have a fallback template to create the initial state

  const typeData = section.config?.[templateType] ? section.config?.[templateType]() : [];

  // Do not have on the form's store the fields that are not in config (per template type)
  if (!typeData.length) return {};

  const fields = _flatten(typeData.filter(t => !t.subGroup).map(c => c.fields)); // Create the state without any array fields

  state = {
    ...state,
    ...createFieldsState(fields, vessel, tabIndex)
  };
  // Create the nested arrays of the multi tab sections
  const subGroups = getSectionConfigSubGroups(typeData);

  if (Object.keys(subGroups).length) {
    Object.keys(subGroups).forEach(subKey => {
      state[subKey] = [];

      const numberOfSubs = sectionSubGroups[subKey]({ vessel, tabIndex });
      const subGroupFields = _flatten(subGroups[subKey].map(c => c.fields));

      for (let index = 0; index <= numberOfSubs - 1; index++) {
        state[subKey].push(createFieldsState(subGroupFields));
      }
    });
  }

  return state;
};

export const createDynamicReportState = ({ vessel, template, initialValues }) => {
  let state = {};
  const { type } = template; // Create the new state based on the template type

  // Create the flat fields
  sections.forEach(section => {
    if (!multiTabsSections[section.key]) {
      state = {
        ...state,
        ...parseSectionFields({ section, vessel, template })
      };
    }
  });

  // Create the Multi Tabs Sections (may contain nessted arrays - 2 levels deep)
  Object.keys(multiTabsSections).forEach(key => {
    const section = sections.find(s => s.key === key);
    const numberOfTabs = multiTabsSections[key](vessel);

    // Do not have on the form's store the fields that are not in config (per template type)
    if (!section.config?.[template?.type]) return;
    if (!section.config[template && template.type ? template.type : 'noon']().length) return;

    state[section.key] = [];

    for (let index = 0; index <= numberOfTabs - 1; index++) {
      state[section.key].push(parseSectionFields({ section, vessel, template, tabIndex: index }));
    }
  });

  // Create any other Top Level array - 1 level deep
  Object.keys(arrayFields).forEach(arrKey => {
    const section = sections.find(s => s.key === arrayFields[arrKey].section);
    const subs = sectionSubGroups[arrKey]({ vessel, template });

    if (!section.config?.[template?.type]) return;

    const typeData = section.config[type]();
    const subGroups = getSectionConfigSubGroups(typeData);

    if (Object.keys(subGroups).length && subGroups[arrKey]) {
      const subGroupFields = _flatten(subGroups[arrKey].map(c => c.fields));
      state[arrKey] = [];

      if (arrKey === 'ballast_water') {
        subs.forEach(tank => {
          const ballastTankState = { ballast_tank: tank };

          subGroupFields.forEach(subfield => {
            ballastTankState[subfield.key] = fieldState(subfield);
          });

          state['ballast_water'].push(ballastTankState);
        });
      } else if (arrKey === 'consumptions') {
        // Create custom state for the consumptions
        initialValues?.consumptions?.forEach(
          ({
            fuel_grade,
            fuel_grade_id,
            bunker_id,
            bunker_index,
            bunker_consumption_index,
            id
          }) => {
            const consumptionState = {
              fuel_grade,
              fuel_grade_id,
              bunker_id,
              bunker_index,
              bunker_consumption_index,
              id
            };

            subGroupFields.forEach(subfield => {
              consumptionState[subfield.key] = fieldState(subfield);

              if (subfield.rateLabel) {
                consumptionState[subfield.rateLabel] = fieldState({});
              }
            });

            state['consumptions'].push(consumptionState);
          }
        );
      } else {
        for (let index = 0; index <= subs - 1; index++) {
          state[arrKey].push(createFieldsState(subGroupFields));
        }
      }
    }
  });

  // Set initial values
  if (initialValues) {
    return initReportFields(state, initialValues);
  }

  return state;
};

// logReportTypeFields('noon');
export const logReportTypeFields = templateType => {
  const allReportFields = sections.map(section => section.config[templateType]());
  const flatReportFields = _flatten(_flatten(allReportFields).map(c => c.fields)).map(f => f.key);

  console.log(_sortBy(flatReportFields, [el => el]));
};

const getFormStaticState = () => {
  // This function initializes the form store with all fields on the configs
  let state = {};

  sections.forEach(section => {
    if (!multiTabsSections[section.key]) {
      state = {
        ...state,
        ...parseSectionFields({ section, vessel: {}, template: {} })
      };
    }
  });

  return state;
};

const formStaticState = {
  ...getFormStaticState()
};

export default formStaticState;
