import React from 'react';
import { useSelector } from 'react-redux';

const VesselInfo = () => {
  const vessel = useSelector(state => state.vessel);

  if (!vessel) return null;

  return (
    <div className="sidebar-navbar--vessel fs-14 line-height-1">
      <strong>{vessel.name}</strong>
      <span></span>
      <strong>{vessel.imo_no}</strong>
    </div>
  );
};
export default VesselInfo;
