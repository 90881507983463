import { useSelector } from 'react-redux';
import { selectChaptersRootLevel } from 'manuals/store/selectors';

import ChapterView from './ChapterView';

const Body = ({ chapterActions = {} }) => {
  const root = useSelector(selectChaptersRootLevel);

  return (
    <div className="chapters-tree__body gray-scrollbar">
      {root.map(uid => (
        <ChapterView
          key={uid}
          uid={uid}
          onChapterRename={chapterActions.onChapterRename ? chapterActions.onChapterRename : null}
          onChapterCreate={chapterActions.onChapterCreate ? chapterActions.onChapterCreate : null}
        />
      ))}
    </div>
  );
};

export default Body;
