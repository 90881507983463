import React from 'react';

import { Col } from 'reactstrap';

import DateInput, { mode } from 'common/components/form/inputs/date';
import PropTypes from 'prop-types';

const Hospitalized = ({ selectField, fields }) => {
  return (
    <Col className="mb-2">
      <DateInput
        label="Hospitalized"
        activeMode={mode.range.date}
        from={fields.hospitalized_at_from.value}
        to={fields.hospitalized_at_to.value}
        className="mb-0"
        onChange={selected => {
          selectField('hospitalized_at_from')(selected.from);
          selectField('hospitalized_at_to')(selected.to);
        }}
      />
    </Col>
  );
};

Hospitalized.propTypes = {
  selectField: PropTypes.func,
  fields: PropTypes.object
};

export default Hospitalized;
