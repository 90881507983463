import React from 'react';
import ModuleWrapper from '../components/ModuleWrapper';

import NewObligation from './NewObligation';
import ObligationsTable from './ObligationsTable';
import SubmissionsTable from './SubmissionsTable';
import LinkForm from './LinkForm';
import { useSelector } from 'react-redux';
import { selectIsCreate, selectIsTemplate } from 'events/store/events/selectors';

export const EVENT_SUBMISSIONS_TABLE_LABEL = 'event_form_submisions';
export const EVENT_OBLIGATIONS_TABLE_LABEL = 'event_form_obligations';

const Forms = () => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isCreate = useSelector(selectIsCreate);
  const isTemplate = useSelector(selectIsTemplate);

  if (isTemplate || isCreate) return <ModuleWrapper type="forms" />;

  return (
    <>
      <ModuleWrapper className="event-forms" type="forms" action={!isOnBoard && <NewObligation />}>
        <ObligationsTable />
        <SubmissionsTable />
        {!isOnBoard && <LinkForm />}
      </ModuleWrapper>
    </>
  );
};

export default Forms;
