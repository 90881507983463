import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ActivateButton = ({
  onClick,
  color = 'green',
  className,
  children,
  isActive = false,
  disabled = false
}) => {
  return (
    <Button
      className={`activate-button d-inline-flex align-items-center ${isActive ? 'active' : ''} ${
        className || ''
      } ${disabled ? 'disabled' : ''}`}
      onClick={e => (disabled || !onClick ? {} : onClick(e))}
      color={`outline-${color}`}
    >
      {children}
    </Button>
  );
};
export default ActivateButton;

ActivateButton.propTypes = {
  color: PropTypes.string // any of bootstrap theme colors
};
