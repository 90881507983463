import Select from 'common/components/form/inputs/Select';
import { useState } from 'react';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

const RootCauseAnalysisInput = ({ formFieldParams, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  return (
    <Select
      placeholder="Select root cause"
      isMulti
      isAsync
      onBlur={onBlur}
      onFocus={onFocus}
      isFocused={isFocused}
      className="root-cause-analysis-input"
      defaultOptions={() => getInitialAsyncValues('root-causes', null, true, formFieldParams)}
      loadOptions={search => getAsyncOptions(search, 'root-causes', formFieldParams)}
      getOptionLabel={option => option.title}
      maxVisibleWhenBlurred={3}
      {...rest}
    />
  );
};

export default RootCauseAnalysisInput;
