import { FC } from 'react';
import { Col, Row } from 'reactstrap';
import VesselSelector from 'common/components/selectors/VesselSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import FilterButton from 'common/components/buttons/FilterButton';
import FindingSubCategoriesSelector from '@/common/components/selectors/FindingSubCategoriesSelector';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import { Vessel } from '@/common/types/vessel';
import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';
import { useAppSelector } from '@/store/hooks';

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
  dataCount?: number;
};

const TopFilters: FC<TopFiltersComponentProps> = ({ topFilters, setTopFilters, dataCount }) => {
  const isOnBoard = useAppSelector(state => state.isOnBoard);
  const [resolved, setResolved] = useTableTopFilterValue({ topFilters, setTopFilters }, 'resolved');
  const [findingSubCategory, setFindingSubCategory] = useTableTopFilterValue(
    { topFilters, setTopFilters },
    'finding_subcategory_id'
  );
  const [vessel, setVessel] = useTableTopFilterValue({ topFilters, setTopFilters }, 'vessel_id');

  return (
    <Row className="align-items" noGutters>
      <Col xs={'auto'} className="d-flex w-100p">
        <TableTopFilter className="d-flex w-100p">
          {isOnBoard ? null : (
            <>
              <VesselSelector
                className="mb-0 cpe-12"
                filter={{ value: vessel?.length ? vessel : null }}
                onChange={({ value }: { value: Vessel[] }) => {
                  if (value.length === 0) {
                    setVessel(null);
                  } else {
                    setVessel(value);
                  }
                }}
                placeholder="Select vessel(s)"
                autoFocus={false}
                showIcon
                isMulti
              />

              <FindingSubCategoriesSelector
                placeholder="Select subcategory"
                className="cme-12"
                filter={{
                  value: findingSubCategory?.length ? findingSubCategory : null
                }}
                onChange={({ value }) => {
                  if (value.length === 0) {
                    setFindingSubCategory(null);
                  } else {
                    setFindingSubCategory(value);
                  }
                }}
                isMulti
              />
            </>
          )}

          <FilterButton
            isActive={resolved === 'true'}
            className={`pt-0 pb-0 fw-medium px-1 ${isOnBoard ? '' : 'cms-12'}`}
            onClick={() => {
              setResolved(resolved === 'true' ? null : 'true');
            }}
            size="sm"
          >
            Resolved
          </FilterButton>

          <FilterButton
            isActive={resolved === 'false'}
            className="pt-0 pb-0 fw-medium px-1 cms-12 border-rigth"
            onClick={() => {
              setResolved(resolved === 'false' ? null : 'false');
            }}
            size="sm"
          >
            Unresolved
          </FilterButton>

          {dataCount === undefined ? null : (
            <div className="d-flex align-items-center ms-auto">
              <div className="fs-12 text-primary">
                Results: <span className="fw-bold">{dataCount}</span>
              </div>
            </div>
          )}
        </TableTopFilter>
      </Col>
    </Row>
  );
};

export default TopFilters;
