import TYPES from 'common/components/table/store/types';
import { get } from 'utils/api';
import _get from 'lodash/get';

import { parse } from './payloadParser';

const aliases = {
  captain_reports: 'captain-reports',
  form_submissions: 'form-submissions',
  form_obligations: 'form-obligations',
  purchasing_requisitions: 'purchasing-requisitions',
  purchasing_requisitions_dlv: 'purchasing-requisitions/suppliers',
  purchasing_requisitions_ev: 'purchasing-requisitions/suppliers',
  purchasing_deliverables: 'deliverables',
  pms_jobs_list: 'vessel-systems/maintenance-jobs',
  crew_evaluation_obligations: 'crew-evaluation-obligations',
  mga_actions: 'mga/actions',
  mga_overview: 'mga',
  mga_inventory_items_additions: 'mga/items/additions',
  mga_inventory_items_removals: 'mga/items/removals',
  mga_inventory_items_overview: 'mga/items/overview',
  inventory: 'inventory',
  jobs_detailed: 'jobs',
  captain_reports_running_hours: 'captain-reports'
};

const fixedParams = {};

export const getTableList = params => dispatch => {
  const { table, ...rest } = params;
  let apiParams = { ...rest };
  if (fixedParams[table]) {
    apiParams = { ...apiParams, ...fixedParams[table] };
  }

  dispatch({ type: TYPES.GET_TABLE_LIST.START, payload: { params: rest, table } });

  return get(`/${_get(aliases, table, table)}`, apiParams)
    .then(response => {
      if (
        params &&
        params.paging &&
        response.data.meta &&
        parseInt(params.paging.current_page) > response.data.meta.last_page
      ) {
        dispatch(
          getTableList({
            ...params,
            paging: { ...params.paging, current_page: parseInt(params.paging.current_page) - 1 }
          })
        );
      } else {
        dispatch({
          type: TYPES.GET_TABLE_LIST.SUCCESS,
          payload: { data: parse(response.data, params, table), table }
        });
      }

      return response.data;
    })
    .catch(error => dispatch({ type: TYPES.GET_TABLE_LIST.ERROR, payload: { error, table } }));
};

export {
  updateTableRow,
  removeTableRow,
  resetTableRowUpdate,
  setTableWarnings,
  setTableTotals
} from 'common/components/table/store/actions';
