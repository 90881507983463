import { getVesselCondition } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  data: {},
  loading: false
};

const slice = createSlice({
  name: 'eventVesselCondition',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVesselCondition.pending, state => {
        state.loading = true;
        return state;
      })

      .addCase(getVesselCondition.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        return state;
      })

      .addCase(getVesselCondition.rejected, state => {
        state.loading = false;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default slice.reducer;
