import ChapterInput from './ChapterInput';
import Footer from './Footer';
import Body from './Body';
import ViewChangesSwitch from './ViewChangesSwitch';

import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import * as manualsActions from 'manuals/store/actions';
import {
  hasChapters,
  areAllChaptersExpanded,
  hasSubChapters,
  selectChaptersTreeSearch,
  isCreatingChapter,
  selectCurrentVersionStatus
} from 'manuals/store/selectors';
import Header from 'common/components/tree-layout/main/Header';

const Main = ({ chapterActions, handleChapterCreation }) => {
  const showCreationForm = useSelector(isCreatingChapter);
  const search = useSelector(selectChaptersTreeSearch);
  const notEmpty = useSelector(hasChapters);
  const allExpanded = useSelector(areAllChaptersExpanded);
  const hasCollapsedContent = useSelector(hasSubChapters);
  const currentVersionStatus = useSelector(selectCurrentVersionStatus);

  const [toggleChaptersCollapse, toggleChapterCreationForm] = useActions([
    manualsActions.toggleChaptersCollapse,
    manualsActions.toggleChapterCreationForm
  ]);

  return (
    <div
      className={`chapters-tree__main ${
        currentVersionStatus === 'working' ? 'chapters-tree__main-working' : ''
      }`}
    >
      <ViewChangesSwitch />
      <Header
        disabled={!notEmpty}
        placeholder="Search chapters"
        allExpanded={allExpanded}
        search={search}
        hasCollapsedContent={hasCollapsedContent}
        toggleCollapse={toggleChaptersCollapse}
        onSearchChange={chapterActions.searchInManualChapters}
      />

      <Body chapterActions={chapterActions} />
      {showCreationForm ? (
        <ChapterInput
          onCancel={() => toggleChapterCreationForm(false)}
          onSave={chapterActions.onChapterCreate}
        />
      ) : null}
      <Footer handleChapterCreation={handleChapterCreation} />
    </div>
  );
};

export default Main;
