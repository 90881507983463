import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';

import SaveTableLayout from 'components/layouts/page/table/save-table';
import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';
import jobsIcon from 'common/assets/svg/common/jobs.svg';
import flagIcon from 'common/assets/svg/common/flag.svg';
import findings from 'common/assets/svg/common/findings.svg';
import orcaEnums from 'common/assets/svg/common/orca-enums.svg';

import { useSelector } from 'react-redux';
import config from 'common/components/jobs/list/config';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import permissions from 'common/utils/permissions/constants';

export const jobsNavigationTabs = [
  {
    label: 'regular',
    tooltip: 'Regular',
    icon: jobsIcon,
    path: paths.regular_jobs,
    permissions: [
      permissions.office.jobs.list.regular.view,
      permissions.onboard.jobs.list.regular.view
    ]
  },
  {
    label: 'flag_extensions_dispensations',
    tooltip: 'Flag Extensions-Dispensations',
    icon: flagIcon,
    path: paths.flag_extensions_dispensations_jobs,
    permissions: [
      permissions.office.jobs.list.flag_extension_and_dispensation.view,
      permissions.onboard.jobs.list.flag_extension_and_dispensation.view
    ]
  },
  {
    label: 'findings',
    tooltip: 'Findings',
    icon: findings,
    path: paths.findings,
    permissions: [
      permissions.office.jobs.list.findings.view,
      permissions.onboard.jobs.list.findings.view
    ]
  },
  {
    label: 'orca_jobs',
    tooltip: 'Orca Jobs',
    icon: orcaEnums,
    path: paths.orca_jobs,
    permissions: [permissions.office.jobs.list.orca_jobs.view]
  }
];

const NavTabs = ({ drawer, isFindingsView }) => {
  const { location, match, history } = useRouter();
  const { type } = match.params;

  const isOnboard = useSelector(state => state.isOnBoard);

  const label = useMemo(
    () => (isFindingsView ? 'reports_findings' : config(type, isOnboard)?.label || null),
    [type, isOnboard, isFindingsView]
  );
  const subTitle = useMemo(() => config(type, isOnboard)?.title || null, [type, isOnboard]);

  const onNavigate = useCallback(
    pathname => {
      history.replace({ pathname });
      drawer.close();
    },
    [drawer, history]
  );

  return (
    <Row className="align-items-center mb-4">
      <Col xs="auto">
        <NavigationGroupTabs
          title="Jobs"
          subTitle={subTitle}
          tabs={jobsNavigationTabs
            .map(tab => ({
              ...tab,
              isActive: location.pathname.includes(tab.path),
              onClick: () => {
                if (!location.pathname.includes(tab.path)) onNavigate(tab.path);
              },
              hidden: tab.label === 'orca_jobs' && isOnboard
            }))
            .filter(tab => !tab.hidden)}
        />
      </Col>

      <Col xs="auto">
        <div className="small-separator mx-1 cpy-10 bg-platinum" />
      </Col>

      <Col>
        <SaveTableLayout
          label={label}
          pageTitle={
            isFindingsView ? (
              'Findings'
            ) : (
              <div className="d-flex align-items-center">
                Jobs <div className="fw-bold ms-2">/ {subTitle}</div>
              </div>
            )
          }
          headerClassName="mb-0"
          key={isFindingsView}
          pageActions={
            !isOnboard && !isFindingsView
              ? [
                  { type: 'favorite' },
                  {
                    type: 'create',
                    color: 'primary',
                    text: 'Add Job',
                    onClick: () => drawer.open(),
                    permissions: [permissions.office.jobs.create]
                  }
                ]
              : isOnboard && isFindingsView
              ? [
                  { type: 'favorite' },
                  {
                    type: 'create',
                    color: 'primary',
                    text: 'Add Finding',
                    onClick: () => drawer.open()
                  }
                ]
              : []
          }
        />
      </Col>
    </Row>
  );
};

NavTabs.propTypes = {
  drawer: DrawerState,
  isFindingsView: PropTypes.bool
};

export default NavTabs;
