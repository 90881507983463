import React, { Suspense } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Status from 'common/components/jobs/_base/inputs/Status';
import Assignee from 'common/components/jobs/_base/inputs/Assignee';
import Approvers from 'common/components/jobs/_base/inputs/Approvers';
import Project from 'common/components/jobs/_base/inputs/Project';
import DueDate from 'common/components/jobs/_base/inputs/DueDate';
import Ports from 'common/components/jobs/_base/inputs/Ports';
import Tags from 'common/components/jobs/_base/inputs/Tags';
import Type from 'common/components/jobs/_base/inputs/Type';
import ForVessel from 'common/components/jobs/_base/inputs/for-vessel';
import ShadowBox from 'common/components/general/ShadowBox';
import Title from 'common/components/jobs/_base/inputs/Title';
import Importance from 'common/components/jobs/_base/inputs/Importance';
import Departments from 'common/components/jobs/_base/inputs/Departments';
import { departmentTypeEnums } from 'common/utils/fixed';
import Vessel from 'common/components/jobs/_base/inputs/Vessel';

import projectIcon from 'common/assets/svg/common/projects.svg';
import checkIcon from 'common/assets/svg/common/check-circle.svg';
import departmentIcon from 'common/assets/svg/common/department.svg';
import vesselIcon from 'common/assets/svg/vessels/vessel.svg';
import DrydockSwitch from '../../_base/components/DrydockSwitch';
import { setJobField } from '../../_base/store/actions';
import { selectJobField, selectIsRegularJob } from 'common/components/jobs/_base/store/selectors';

const Reminders = React.lazy(() => import('common/components/jobs/_base/modules/reminders'));

const LeftSide = ({
  customDueDate,
  hide = [],
  avoidCheck = false,
  hideReminderDueDate = false
}) => {
  const dispatch = useDispatch();

  const isOnboard = useSelector(state => state.isOnBoard);
  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));
  const isDrydock = useSelector(state => selectJobField(state, 'is_drydock'));
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const isRegularJob = useSelector(state => selectIsRegularJob(state));
  const isResponsibleOnboardDepartmentVisible = isRegularJob && isForVessel;

  return (
    <Suspense fallback={null}>
      <ShadowBox color="light-1" className={`flex-1 px-3 py-2 ${isOnboard ? 'mb-2' : ''}`} flat>
        <Row className="align-items-center">
          <Col>
            <Type />
          </Col>
          <Col xs="auto">
            <ForVessel avoidCheck={avoidCheck} />
          </Col>

          <Col xs={12}>
            <Title label="Title" className="mb-2" autoResize rows={3} />
          </Col>

          <Col xs={customDueDate ? 6 : 'auto'}>
            {customDueDate ? customDueDate : <DueDate isProfile />}
          </Col>
          <Col xs={'auto'} className="cps-2">
            <Importance avoidCheck={avoidCheck} />
          </Col>
          <Col className="ps-0">
            <Status avoidCheck={avoidCheck} />
          </Col>

          <Col xs={12} className="mb-2 border-top border-platinum cpt-20">
            <Label className="form-label fs-12 text-capitalize">Responsibility</Label>
          </Col>

          <Col xs={12}>
            <Assignee avoidCheck={avoidCheck} />
          </Col>

          <Col
            xs={`${isResponsibleOnboardDepartmentVisible ? '6' : 'auto'}`}
            className={`${isResponsibleOnboardDepartmentVisible ? '' : 'max-w-150 min-w-150'}`}
          >
            <Departments
              avoidCheck={avoidCheck}
              type={departmentTypeEnums.main_department}
              iconLabel={departmentIcon}
              boxPlaceholder={false}
              invisible={false}
              placeholder="Select dept"
              label="PRIMARY DEPARTMENT"
            />
          </Col>

          {isResponsibleOnboardDepartmentVisible ? (
            <Col xs="6" className="ps-0">
              <Departments
                avoidCheck={avoidCheck}
                type={departmentTypeEnums.responsible_onboard_department}
                boxPlaceholder={false}
                invisible={false}
                placeholder="Select dept"
                label="RESPONSIBLE ONBOARD DEPARTMENT"
              />
            </Col>
          ) : null}

          <Col
            xs={`${isResponsibleOnboardDepartmentVisible ? '12' : ''}`}
            className={`${isResponsibleOnboardDepartmentVisible ? '' : 'ps-0'}`}
          >
            <Departments
              type={departmentTypeEnums.departments}
              showMore={false}
              avoidCheck={avoidCheck}
              placeholder={'Secondary dept(s)'}
              invisible={false}
              label="SECONDARY DEPARTMENT(S)"
            />
          </Col>

          <Col xs={12}>
            <Approvers iconLabel={checkIcon} singleField avoidCheck={avoidCheck} />
          </Col>

          {isOnboard ? null : (
            <Col xs={12} className="border-top pt-3 cmt-6">
              <Vessel iconLabel={vesselIcon} invisible={false} />
            </Col>
          )}

          <Col xs={12}>{hide.indexOf('ports') === -1 ? <Ports /> : null}</Col>

          <Col xs={5}>
            <DrydockSwitch
              className="width-min-content"
              isDrydock={isDrydock}
              onChange={() => dispatch(setJobField('is_drydock', !isDrydock, !!jobId))}
            />
          </Col>

          <Col xs={12} className="border-top border-platinum pt-3 cmt-6 mb-2">
            <Tags avoidCheck={avoidCheck} />
          </Col>

          <Col xs={12}>
            {hide.indexOf('projects') === -1 ? (
              <Project view="profile" avoidCheck={avoidCheck} iconLabel={projectIcon} />
            ) : null}
          </Col>
        </Row>
      </ShadowBox>

      {!isOnboard && (
        <Reminders avoidCheck={avoidCheck} isProfile hideDueDate={hideReminderDueDate} />
      )}
    </Suspense>
  );
};

export default LeftSide;
