import TYPES from 'captain-reports/store/system-running-hours/types';
import { get, put } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { setRunningHours } from 'captain-reports/store/system-running-hours/actions';

export const getRunningHours = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_RUNNING_HOURS.START, payload: { params } });

  return get(`/captain-reports/${id}`, rest)
    .then(response => {
      dispatch(setRunningHours({ ...response.data }));
      dispatch({ type: TYPES.GET_RUNNING_HOURS.SUCCESS, payload: response.data });

      const { template, vessel, ...reportData } = response.data;
      const { fields, ...templateData } = template;

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_RUNNING_HOURS.ERROR, payload: error });

      throw error;
    });
};

export const getRunningHoursTotals = params => dispatch => {
  const { id, ...rest } = params;
  dispatch({ type: TYPES.GET_RUNNING_HOURS_TOTALS.START, payload: { params } });

  return get(`/captain-reports/previous-running-hours?report_id=${id}`, rest)
    .then(response => {
      dispatch(setRunningHours({ ...response.data }));
      dispatch({ type: TYPES.GET_RUNNING_HOURS_TOTALS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_RUNNING_HOURS_TOTALS.ERROR, payload: error });

      throw error;
    });
};

export const updateRunningHours = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_RUNNING_HOURS.START, payload: { params } });
  const { id, ...rest } = params;

  return put(`/captain-reports/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_RUNNING_HOURS.SUCCESS, payload: params });
      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_RUNNING_HOURS.ERROR, payload: error });

      throw error;
    });
};
