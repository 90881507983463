import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import TopPagination from './main/TopPagination';
import ArchivedButton from './main/ArchivedButton';
import HiddenButton from './main/HiddenButton';
import ExportIcon from 'common/components/general/ExportIcon';

import _get from 'lodash/get';

const TableTop = ({
  archivedLabel,
  hiddenLabel,
  top,
  topFiltersComponent,
  topCustomComponent,
  defaultRequestParams,
  refetchData,
  showArchived = false,
  showHidden = false,
  state,
  fetching,
  topFilters,
  setTopFilters,
  downLoadExtension,
  downloadText,
  isAsyncTaskProccessing = false,
  onDownload,
  hideTopPagination = false,
  scrollToTopAfterPageChanged
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const onDownloadHandler = async () => {
    setIsDownloading(true);

    try {
      await onDownload();
      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      console.error(e);
    }
  };

  return (
    <Row className="app-table__top g-0 align-items-center">
      <Col>
        <div className="d-flex align-items-center">
          {topFiltersComponent
            ? React.cloneElement(topFiltersComponent, {
                topFilters,
                setTopFilters,
                fetching
              })
            : null}

          {downLoadExtension ? (
            <ExportIcon
              disabled={isDownloading || isAsyncTaskProccessing}
              extension={downLoadExtension}
              text={downloadText}
              showTooltip={isAsyncTaskProccessing}
              onClick={onDownloadHandler}
            />
          ) : null}

          {downLoadExtension && showArchived ? <div className="mx-2 export-divider " /> : null}

          {showArchived && (
            <ArchivedButton
              label={archivedLabel}
              topFilters={topFilters}
              setTopFilters={setTopFilters}
              className={top && topFiltersComponent ? 'ms-3' : ''}
            />
          )}

          {showHidden && (
            <HiddenButton
              label={hiddenLabel}
              topFilters={topFilters}
              setTopFilters={setTopFilters}
              className={top && topFiltersComponent ? 'ms-3' : ''}
            />
          )}
        </div>
      </Col>

      {!hideTopPagination ? (
        <Col xs="auto">
          <div className="app-table--toptotal d-flex align-items-center justify-content-end text-nowrap">
            {state.paging && _get(defaultRequestParams, 'paging') !== false ? (
              <TopPagination
                state={state}
                fetchData={params => refetchData(params)}
                scrollToTop={scrollToTopAfterPageChanged}
              />
            ) : null}
          </div>
        </Col>
      ) : null}

      <Col xs={12}>{top && topCustomComponent ? topCustomComponent : null}</Col>
    </Row>
  );
};

export default TableTop;
