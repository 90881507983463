import { displayDate } from 'common/utils/dates';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import ShadowBox from 'common/components/general/ShadowBox';
import { FormSubmissionType } from 'common/entities/forms/FormTypes';

const Log = ({ label, user, timestamp, index }) => (
  <LogContainer className={`position-relative ${index ? 'cmt-6' : ''}`}>
    <ShadowBox className="p-1 fs-12 text-primary d-flex align-items-cebter" color="light-1" flat>
      <div className="flex-1 overflow-hidden text-truncate">
        {label}{' '}
        <strong>
          {user?.full_name || '-'}
          {user?.crew_rank ? ` | ${user?.crew_rank?.name || ''}` : ''}
        </strong>
      </div>
      <div className="text-violet ps-1">{timestamp ? `${displayDate(timestamp)} UTC` : ''}</div>
    </ShadowBox>
  </LogContainer>
);

Log.propTypes = {
  label: PropTypes.string,
  user: PropTypes.object,
  timestamp: PropTypes.string,
  index: PropTypes.number
};

const Footer = ({ formSubmission }) => {
  const logs = useMemo(() => {
    const isNewlyCreated = formSubmission.created_at === formSubmission.updated_at;
    const data = [];

    if (formSubmission?.reviewed_by) {
      data.push({
        label: 'Reviewed by',
        user: formSubmission.reviewed_by,
        timestamp: formSubmission.reviewed_at
      });
    }

    if (formSubmission?.submitted_by) {
      data.push({
        label: 'Submitted by',
        user: formSubmission.submitted_by,
        timestamp: formSubmission.submitted_at
      });
    }

    if (isNewlyCreated) {
      data.push({
        label: 'Created by',
        user: formSubmission.created_by,
        timestamp: formSubmission.created_at
      });
    } else if (formSubmission.updated_by) {
      data.push({
        label: 'Updated by',
        user: formSubmission.updated_by,
        timestamp: formSubmission.updated_at
      });
    }

    return data;
  }, [formSubmission]);

  return logs?.length ? (
    <Container className="mt-5 ps-4 position-relative">
      {logs.map((log, index) => (
        <Log key={index} index={index} {...log} />
      ))}
    </Container>
  ) : null;
};

Footer.propTypes = {
  formSubmission: FormSubmissionType
};

const LogContainer = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: ${variables.size12};
    left: -${variables.size25};
    height: ${variables.size12};
    width: ${variables.size12};
    background: white;
    border: 1px solid ${variables.pastelGray};
    border-radius: 100%;
    display: block;
  }
`;

const Container = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: ${variables.size12};
    left: ${variables.size12};
    height: calc(100% - ${variables.size24});
    width: 1px;
    background: ${variables.pastelGray};
    display: block;
  }
`;

export default Footer;
