import React from 'react';

const ModuleIcon = ({ letter, color }) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className="module-icon d-flex align-items-center justify-content-center"
    >
      <span className="text-white  narrow-black">{letter}</span>
    </div>
  );
};

export default ModuleIcon;
