import { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useForm, useFormState } from 'utils/hooks';
import config from './config';
import Input from 'common/components/form/inputs/Input';

import { selectEventId } from 'events/store/events/selectors';

import FormSaveActions from 'common/components/form/FormSaveActions';
import {
  createEmailConnection,
  getEmailConnection
} from 'events/store/event-modules/email-connection/actions';

const Form = ({ onClose }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { formState, collectValues } = useForm(config, {});
  const { fields, changeField } = useFormState(formState);

  const eventId = useSelector(selectEventId);

  const saveHandler = async () => {
    const values = collectValues();

    if (!values) return;
    let params = {
      event_id: eventId,
      ...values
    };

    try {
      setIsLoading(true);

      await dispatch(createEmailConnection(params)).unwrap();

      await dispatch(getEmailConnection({ event_id: eventId })).unwrap();
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  return (
    <Row
      className="email-connection__editing position-relative pt-2 pb-2 ps-3 mb-2"
      noGutters
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Col xs="auto" className="me-5">
        <span className="fs-12 fw-medium text-violet">Link an email with the event</span>
      </Col>
      <Col xs={5}>
        <Input
          onChange={changeField('email_id')}
          className="mb-0"
          label=""
          placeholder="Email ID"
          {...fields.email_id}
        />
      </Col>
      <div className="email-connection__form-edit-buttons">
        <FormSaveActions
          isHovering={isHovering}
          onCancel={onClose}
          onSave={saveHandler}
          isDisabled={isLoading || !fields.email_id.value}
          style={{ right: -12 }}
        />
      </div>
    </Row>
  );
};

Form.propTypes = {
  onClose: PropTypes.func
};

export default Form;
