import React from 'react';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';
import SvgRender from 'common/components/general/SvgRender';

import { Link } from 'react-router-dom';
import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';

const ViewHeader = ({ components }) => {
  const { match } = useRouter();
  const { id } = match.params;

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center flex-1">
        <Link className="p-0 d-flex align-items-center" to={`${paths.crew}/${id}/evaluation`}>
          <SvgRender
            className="crew-profile--header-icon"
            style={{ height: 19, width: 16, transform: 'rotate(-90deg)' }}
            src={arrow}
          />
        </Link>
        Back to list
      </div>
    </div>
  );
};

export default ViewHeader;
