import PropTypes from 'prop-types';
import { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const CountryFlag = ({
  code,
  name,
  width = 64,
  pathFolder = 'countries',
  height = 64,
  rounded,
  tooltip,
  className = ''
}) => {
  const [imageError, setImageError] = useState(false);

  const { avoidRender, tooltipID } = useTooltipID('text-tooltip');

  if (avoidRender) return null;

  return (
    <div className={`country-flag d-${imageError ? 'none' : 'inline-flex'} ${className}`}>
      <img
        src={`https://orca-prod.s3-eu-west-1.amazonaws.com/public/${pathFolder}/${code.toLowerCase()}.svg`}
        alt="country flag"
        onError={() => setImageError(true)}
        className={`object-fit-cover ${rounded ? 'rounded-circle' : ''}`}
        width={width}
        height={height}
        id={tooltipID}
      ></img>

      {tooltip && name && (
        <UncontrolledTooltip
          boundariesElement="window"
          placement={'top'}
          fade={false}
          innerClassName="p-1 "
          target={tooltipID}
        >
          {name}
        </UncontrolledTooltip>
      )}
    </div>
  );
};

CountryFlag.propTypes = {
  code: PropTypes.string.isRequired,
  rounded: PropTypes.bool
};

export default CountryFlag;
