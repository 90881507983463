import React from 'react';
import Select from 'common/components/form/inputs/Select';
import { components } from 'react-select';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import arrow90 from 'common/assets/svg/jobs/arrow90.svg';
import SvgRender from 'common/components/general/SvgRender';
import _get from 'lodash/get';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import * as jobRestrictedFieldsActions from 'store/jobs-fields/actions';

export const transformProjectData = data => {
  let result = [];
  if (data?.length) {
    data.forEach(pr => {
      result.push({
        id: pr.id,
        title: pr.title,
        type: pr.type
      });
      pr.subprojects.forEach(subpr => result.push(subpr));
    });
  }

  return result;
};

const Project = ({ hideLabel, avoidCheck = false, className, ...rest }) => {
  const [setJobField, setJobFieldError, checkIfFieldIsRequired] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError,

    jobRestrictedFieldsActions.checkIfFieldIsRequired
  ]);
  const project = useSelector(state => selectJobField(state, 'project'));
  const jobProjectError = useSelector(state => selectJobFieldError(state, 'project'));

  const isJobLocked = useSelector(state => selectIsJobLocked(state, true));
  const isEditMode = useSelector(checkIfEditMode);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const handleChange = val => {
    setJobField('project', val, true);

    if (jobProjectError && val.id) {
      setJobFieldError({ project: null });
    }
  };

  const FieldComponent = ({ ...innerRest }) => (
    <Select
      label={hideLabel ? null : 'Project'}
      className={`job-input job-input--project form-group-spacing ${className ? className : ''}`}
      placeholder="Select project"
      getOptionValue={option => option.id}
      value={project}
      error={jobProjectError}
      isAsync
      loadOptions={search => loadOptions(search)}
      defaultOptions={getDefaultOptions}
      onChange={handleChange}
      components={{ Option, SingleValue }}
      isClearable={isEditMode ? (checkIfFieldIsRequired('project_id') ? false : true) : true}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for projects` : `No projects found`
      }
      invisible={false}
      {...rest}
      {...innerRest}
    />
  );

  const getDefaultOptions = async () => {
    const result = await refetchAsyncOptions('projects');

    return transformProjectData(result);
  };

  const loadOptions = async search => {
    const result = await getAsyncOptions(search, 'projects');

    return transformProjectData(result);
  };

  return (
    <UserCanEdit
      field="project_id"
      fallback={() => <FieldComponent disabled />}
      avoidCheck={avoidCheck}
    >
      <FieldComponent disabled={isOnBoard || isJobLocked || project?.is_system_handled} />
    </UserCanEdit>
  );
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {props.data ? (
      <div className="d-flex align-items-center">
        <span className="text-truncate">{props.data.title}</span>
      </div>
    ) : null}
  </components.SingleValue>
);

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {_get(props, 'data.parent_id') ? (
        <div className="d-flex align-items-center ">
          <SvgRender className="text-warning " src={arrow90} style={{ width: 9, height: 12 }} />
          <span className="fs-12 text-violet ms-1">{props.data.title}</span>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <span>{props.data.title}</span>
        </div>
      )}
    </components.Option>
  );
};

export default Project;
