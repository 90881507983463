import React from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import datesIcon from 'common/assets/svg/jobs/labels/dates.svg';
import dropdownIcon from 'common/assets/svg/jobs/labels/dropdown.svg';
import numberIcon from 'common/assets/svg/jobs/labels/number.svg';
import textIcon from 'common/assets/svg/jobs/labels/text.svg';
import OverFlownText from 'common/components/general/TextOverflow';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import enumIcon from 'common/assets/svg/jobs/labels/enum.svg';

const icons = {
  text: textIcon,
  number: numberIcon,
  date: datesIcon,
  'date-range': datesIcon,
  dropdown: dropdownIcon
};

const TemplateLabel = props => {
  const { properties } = props;

  let name = _get(properties, 'object.label_name', null);

  if (!name) {
    name = _get(properties, 'object[0].label_name', 'None');
  }

  const type = _get(properties, 'object.label_type', null);
  const value = _get(properties, 'object.value', 'None');
  const to = _get(properties, 'object.to', 'None');
  const from = _get(properties, 'object.from', 'None');
  const enumValue = _get(properties, 'object.value', 'None');
  const option = _get(properties, 'object.dropdown_value', 'None');
  const id = _get(props, 'id', 'None');

  const renderContent = () => {
    if (type === 'date') {
      return moment(value).format('DD/MM/YYYY');
    } else if (type === 'number') {
      return value;
    } else if (type === 'text') {
      return (
        <OverFlownText
          text={value ? value : 'None'}
          width={150}
          id={`new-${id}-template-label`}
          className=" text-violet fw-light"
        />
      );
    } else if (type === 'dropdown') {
      return <div>{option}</div>;
    } else if (type === 'date-range') {
      return (
        <div className="d-flex align-items-center ">
          <div>{moment(from).format('DD/MM/YYYY')}</div>
          <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
          <div>{moment(to).format('DD/MM/YYYY')}</div>
        </div>
      );
    }

    return <div>{enumValue}</div>;
  };

  return (
    <div className="d-flex align-items-center fw-light activity-template-label">
      <img src={icons[type] ? icons[type] : enumIcon} alt={'label'} width={14} height={14} />

      <div className="fw-bold ms-1 text-primary fs-12">{name}: </div>
      <div className="ms-2 text-violet">{renderContent()}</div>
    </div>
  );
};

export default TemplateLabel;
