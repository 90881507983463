import React from 'react';
import PropTypes from 'prop-types';
import LinkedEntitiesTable from '../_components/LinkedEntitiesTable';
import Status from 'common/components/general/Status';
import Priority from 'common/components/general/Priority';

import eyeSvg from 'common/assets/svg/common/eye.svg';
import removeSvg from 'common/assets/svg/actions/remove.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const LinkedForms = ({ formSubmissions, removeLinkedForm, previewLinkedForm }) => {
  if (!formSubmissions?.length) return null;

  return (
    <LinkedEntitiesTable
      columns={[
        {
          key: 'id',
          minWidth: 80,
          maxWidth: 80,
          truncate: true,
          header: 'No.',
          headerClassName: 'text-violet'
        },
        {
          key: 'importance_id',
          minWidth: 48,
          maxWidth: 48,
          headerClassName: 'text-violet text-center',
          bodyClassName: 'px-0 text-center',
          header: 'I'
        },
        {
          key: 'name',
          truncate: true,
          headerClassName: 'text-violet',
          bodyClassName: 'pe-0 overflow-hidden',
          header: 'Form'
        },
        { key: 'status', width: 2, headerClassName: 'text-violet', header: 'Status' },
        {
          key: 'submission_date',
          width: 2,
          headerClassName: 'text-violet',
          header: 'Form Date',
          truncate: true,
          type: 'date'
        },
        { key: 'actions', field: 'actions' }
      ]}
      state={{ data: formSubmissions }}
      rows={{
        importance_id: ({ form }) => (
          <Priority value={form?.importance_id} withText={false} size="small" />
        ),
        name: ({ form }) => (
          <div className="overflow-hidden">
            <TextWithTooltip>
              {form?.name}
              {form?.revision && <strong> - {form?.revision}</strong>}
            </TextWithTooltip>
          </div>
        ),
        status: formSubmission => <Status type="forms" value={formSubmission.status} />,
        actions: () => ({
          options: [
            {
              label: 'View',
              icon: eyeSvg,
              onClick: (_, data) => previewLinkedForm(data)
            },
            {
              label: 'Remove',
              icon: removeSvg,
              svgStyle: { width: 8, height: 8 },
              onClick: (_, __, index) => removeLinkedForm(index)
            }
          ]
        })
      }}
    />
  );
};

LinkedForms.propTypes = {
  formSubmissions: PropTypes.array,
  removeLinkedForm: PropTypes.func,
  previewLinkedForm: PropTypes.func
};

export default LinkedForms;
