import moment from 'moment';

import { Row, Col } from 'reactstrap';

const DateRangeView = ({ value }) => {
  return (
    <div className="w-100p">
      <Row>
        <Col xs="auto">
          <span className="me-2 fs-12 fw-bold text-violet">FROM</span>{' '}
          {value?.from ? moment(value.from, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}{' '}
        </Col>
        <Col>
          <span className="me-2 fs-12 fw-bold text-violet">TO</span>
          {value?.to ? moment(value.to, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}{' '}
        </Col>
      </Row>
    </div>
  );
};

export default DateRangeView;
