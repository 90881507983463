import SingleDatePicker from './SingleDatePicker';
import moment from 'moment';

const SingleWeekDatePicker = ({ dateRange, value, onChange, ...rest }) => {
  return (
    <SingleDatePicker
      value={dateRange.starts.value}
      isOutsideRange={date => moment(date).isoWeekday() !== 1}
      onChange={date => onChange({ from: date, to: date.clone().add(6, 'days') })}
      {...rest}
    />
  );
};

export default SingleWeekDatePicker;
