import React, { useState } from 'react';

import { Button } from 'reactstrap';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import SvgRender from 'common/components/general/SvgRender';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as jobWatchersActions from 'common/components/jobs/_base/modules/watchers/store/actions';

import view from 'common/assets/svg/actions/view.svg';

const Watchers = ({ className = '', svgStyle = { width: 22, height: 22 } }) => {
  const [isSaving, setIsSaving] = useState(false);
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const [setJobField] = useActions([jobProfileActions.setJobField]);
  const [updateJobWatchers, deleteJobWatchers] = useActions([
    jobWatchersActions.updateJobWatchers,
    jobWatchersActions.deleteJobWatchers
  ]);
  const isWatcher = useSelector(state => selectJobField(state, 'is_watcher'));

  const handleWatch = async () => {
    setIsSaving(true);
    setJobField('is_watcher', !isWatcher, false);

    if (isWatcher) {
      await deleteJobWatchers({ jobId, is_watcher: !isWatcher });
    } else {
      await updateJobWatchers({ jobId, is_watcher: !isWatcher });
    }

    setIsSaving(false);
  };

  const { avoidRender, tooltipID } = useTooltipID('copy-job-tooltip-container');
  if (avoidRender) return null;

  return (
    <div className={`job-watchers d-inline-flex ${className}`} id={tooltipID}>
      <Button
        color="link"
        type="button"
        className={`d-inline-flex h-100p align-items-center justify-content-center${
          isWatcher ? ' active' : ''
        }`}
        onClick={handleWatch}
        disabled={isSaving}
      >
        <SvgRender src={view} style={svgStyle} />
      </Button>

      <Tooltip
        target={tooltipID}
        placement="top"
        popperClassName="fs-10 job-watchers-tooltip "
        boundariesElement="window"
      >
        {isWatcher ? 'Unwatch job' : 'Watch job'}
      </Tooltip>
    </div>
  );
};

export default Watchers;
