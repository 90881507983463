import React from 'react';

import { Row, Col } from 'reactstrap';
import Information from 'common/components/general/Information';
import DateWithIcon from 'common/components/dates/DateWithIcon';

import commentsSvg from 'common/assets/svg/common/comments.svg';

const IssueDate = ({ date, comments, typeId, certificateId }) => {
  return (
    <Row noGutters className="align-items-center">
      <Col>
        <DateWithIcon date={date} />
      </Col>
      {comments ? (
        <Col xs="auto">
          <Information
            target={`vessel-documents-${typeId}-${certificateId}`}
            svgIcon={commentsSvg}
            svgStyle={{ width: 16, height: 14 }}
            className="text-purple"
            message={comments}
            tooltipClassname="cpx-6 py-1"
            tooltipOffset={[0, 9]}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default IssueDate;
