import React, { useState } from 'react';
import moment from 'moment';

import star from 'common/assets/svg/common/star-solid.svg';
import draft from 'common/assets/svg/common/archive.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import SvgRender from 'common/components/general/SvgRender';
import CircledButton from 'common/components/buttons/CircledButton';
import paths from 'routing/routes/_paths';

import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { numberToStr } from 'common/utils/numbers';

import useRouter from 'use-react-router';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { isDraftEvaluation } from 'crew/profile/main/evaluation/form/helpers';
import * as evaluationActions from 'crew/store/evaluations/profile/actions';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import EvaluationObligationReason from 'crew/components/EvaluationObligationReason';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';

const convertToDate = date => moment(date).format('DD/MM/YYYY');

const Rating = ({ evaluation, isDraft }) => {
  return (
    <span className="evaluations-list--row--rating d-inline-flex align-items-center lh-1 ms-2 me-5">
      {isDraft ? (
        <>
          <SvgRender style={{ width: 12, height: 12 }} src={draft} />
          <span>Draft</span>
        </>
      ) : (
        <>
          <SvgRender style={{ width: 12, height: 12 }} src={star} />
          <span>{numberToStr(evaluation.average_score, 2, 2)}</span>
        </>
      )}
    </span>
  );
};

const DateReceived = ({ evaluation, isDraft }) => {
  return (
    <>
      <span className="evaluations-list--row--created-at text-primary">
        {isDraft ? '-/-/-' : convertToDate(evaluation.date)}
      </span>
    </>
  );
};

const Position = ({ crew, position, className }) => {
  return (
    <strong className={`evaluations-list--row--evaluator-rank ${className || ''}`}>
      <TextWithTooltip>
        {crew && !crew?.details.is_crew ? 'OFFICE' : null}
        {position ? `${!crew?.details.is_crew ? ' / ' : ''} ${position}` : null}
      </TextWithTooltip>
    </strong>
  );
};

const EvaluationRow = ({ evaluation, isReceived, isDraft }) => {
  const positionName =
    _get(evaluation, `${isReceived ? 'evaluator' : 'evaluatee'}.details.position_name`) || null;

  return (
    <Row
      noGutters
      className={`evaluations-list--row${isDraft ? ' evaluations-list--row-draft' : ''}`}
    >
      {isReceived ? (
        <>
          <Col xs={3} className="ps-2 d-flex flex-nowrap">
            <div className="fw-bold evaluations-list--row--rank overflow-hidden">
              <TextWithTooltip>{_get(evaluation, 'rank.name', '')}</TextWithTooltip>
            </div>
            <div className="evaluations-list--row--seperator" />
            <div className="evaluations-list--row--vessel me-1 overflow-hidden">
              <TextWithTooltip>{_get(evaluation, 'vessel.name', '')}</TextWithTooltip>
            </div>
          </Col>
          <Col className="evaluations-list--row--date">
            <DateReceived isDraft={isDraft} evaluation={evaluation} />
          </Col>
          <Col className="pe-2 overflow-hidden">
            <EvaluationObligationReason
              evaluation={{
                ...evaluation,
                obligation: {
                  ...(evaluation?.obligation || {}),
                  evaluator: null,
                  evaluator_type: null
                }
              }}
              notes={evaluation?.evaluator_notes}
            />
          </Col>
          <Col className="d-flex align-items-center flex-nowrap overflow-hidden">
            <div className="d-flex flex-1 overflow-hidden flex-nowrap align-items-center">
              <div className="text-primary evaluations-list--row--evaluator overflow-hidden">
                <TextWithTooltip>{_get(evaluation, 'evaluator.full_name', '-')}</TextWithTooltip>
              </div>

              {evaluation?.evaluator?.details?.is_crew === null ||
              evaluation?.evaluator?.details?.is_crew === undefined ||
              !evaluation?.evaluator?.details?.position_name ? null : (
                <div className="ms-auto me-6">
                  <EvaluatorDepartment
                    evaluator={{
                      ...evaluation?.evaluator,
                      name: evaluation?.evaluator?.details?.position_name,
                      color: evaluation?.evaluator?.details?.department_color,
                      is_crew_department: evaluation?.evaluator?.details?.is_crew
                    }}
                    evaluatorType={
                      evaluation?.evaluator?.is_crew ? 'crew_department' : 'department'
                    }
                  />
                </div>
              )}
            </div>

            <Rating isDraft={isDraft} evaluation={evaluation} />
          </Col>
        </>
      ) : (
        <>
          <Col xs={4} className="ps-2 d-flex flex-nowrap overflow-hidden">
            <div className="text-primary evaluations-list--row--evaluator overflow-hidden">
              <TextWithTooltip>{_get(evaluation, 'evaluatee.full_name', '-')}</TextWithTooltip>
            </div>
            <div className="evaluations-list--row--seperator" />
            <Position
              crew={evaluation.evaluatee}
              position={positionName}
              className="text-moody-blue cme-4"
            />
          </Col>
          <Col xs={2}>
            <div className="evaluations-list--row--vessel text-overflow text-nowrap w-100p">
              <TextWithTooltip>{_get(evaluation, 'vessel.name', '')}</TextWithTooltip>
            </div>
          </Col>
          <Col className="evaluations-list--row--date">
            <DateReceived isDraft={isDraft} evaluation={evaluation} />
          </Col>
          <Col className="d-flex align-items-center flex-nowrap overflow-hidden pe-2">
            <EvaluationObligationReason
              evaluation={{
                ...evaluation,
                obligation: {
                  ...(evaluation?.obligation || {}),
                  evaluator: null,
                  evaluator_type: null
                }
              }}
              notes={evaluation?.evaluator_notes}
              className="flex-1"
            />
            <Rating isDraft={isDraft} evaluation={evaluation} />
          </Col>
        </>
      )}
    </Row>
  );
};

const SingleEvaluation = ({ evaluation, isReceived }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { match } = useRouter();
  const { id } = match.params;

  const isOnboard = useSelector(state => state.isOnBoard);
  const [deleteEvaluation] = useActions([evaluationActions.deleteCrewEvaluation]);

  const isDraft = isDraftEvaluation(evaluation, isOnboard);

  const evaluationPath = mode => `${paths.crew}/${id}/evaluation/${mode}/${evaluation.id}`;

  const onDelete = () => {
    deleteEvaluation(evaluation);
  };

  return (
    <div className="evaluations-list--row-wrapper">
      {isDraft ? (
        <>
          <Link to={evaluationPath('draft')}>
            <EvaluationRow isDraft={isDraft} isReceived={isReceived} evaluation={evaluation} />
            <CircledButton type={'edit'} svgStyle={{ height: 14, width: 14 }} />
          </Link>
        </>
      ) : (
        <>
          <Link to={evaluationPath('view')}>
            <EvaluationRow isDraft={isDraft} isReceived={isReceived} evaluation={evaluation} />
          </Link>
          {!isOnboard ? (
            <>
              <CircledButton
                onClick={() => setShowDeleteModal(true)}
                svgWrapperClassName="bg-coral"
                type={'remove'}
                svgStyle={{ height: 8, width: 8 }}
                style={{ height: 20, width: 20 }}
              />

              <DangerousActionModal
                transparent
                action={'Remove'}
                onAccept={onDelete}
                closeModal={() => setShowDeleteModal(false)}
                isOpen={showDeleteModal}
                actionText={'REMOVE'}
                header={'Remove'}
                body={<div>Are you sure you want to remove this evaluation?</div>}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SingleEvaluation;
