import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const FieldHeaderIcon = ({ item }) => {
  const { tooltipID, avoidRender } = useTooltipID('vessel-profile');
  const { header, headerIcon: { icon, style, className, tooltip, tooltipClassName } = {} } = item;

  if (avoidRender) return null;

  return (
    <div>
      {icon ? (
        <SvgRender
          id={tooltipID}
          src={icon}
          style={style || { width: 16, height: 16 }}
          className={className || ''}
        />
      ) : null}

      {tooltip ? (
        <Tooltip innerClassName={tooltipClassName || ''} target={tooltipID}>
          {tooltip}
        </Tooltip>
      ) : null}

      <span>{header}</span>
    </div>
  );
};

export default FieldHeaderIcon;
