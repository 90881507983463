import React from 'react';
import _get from 'lodash/get';

const Port = props => {
  const { properties } = props;

  const name = _get(properties, 'object.name', 'None');
  const countryCode = _get(properties, 'object.country_code', '');

  return (
    <div className="d-flex align-items-center">
      <div className=" activity-port" data-testid="job-activity-port">
        {name}-{countryCode}
      </div>
    </div>
  );
};

export default Port;
