import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';

const SortableCheckList = SortableContainer(
  ({
    checklistState,
    updateChecklistState,
    setChecklistState,
    memoizedDebounce,
    onSubmit,
    isEditMode,
    onCancel,
    submitting,
    isLoading,
    hasActionButtons,
    placeholder
  }) => {
    return (
      <div>
        {checklistState?.map((state, index) => {
          return (
            <SortableItem
              isEditMode={isEditMode}
              key={index}
              memoizedDebounce={memoizedDebounce}
              state={state}
              checklistState={checklistState}
              index={index}
              itemIndex={index}
              updateChecklistState={updateChecklistState}
              setChecklistState={setChecklistState}
              onSubmit={onSubmit}
              onCancel={onCancel}
              submitting={submitting}
              isLoading={isLoading}
              placeholder={placeholder}
              hasActionButtons={hasActionButtons}
            />
          );
        })}
      </div>
    );
  }
);

export default SortableCheckList;
