import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleApiErrorNotification } from '@/common/utils/notifications';
import { TABLE_QUERY_KEY } from '@webthatmatters/orca-table';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => handleApiErrorNotification(error)
  }),
  mutationCache: new MutationCache({
    onError: error => handleApiErrorNotification(error)
  })
});

queryClient.setQueryDefaults([TABLE_QUERY_KEY], { queryKeyHashFn: key => JSON.stringify(key) });

export default queryClient;
