import React from 'react';
import Select from 'common/components/form/inputs/Select';

import { useSelector } from 'react-redux';
import { selectPeriodType } from 'crew/store/shifts/selectors';

import { useActions } from 'utils/hooks';
import * as shiftActions from 'crew/store/shifts/actions';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const periodOptions = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' }
];

const PeriodSelect = () => {
  const [setPeriodType, setStartDate, setEndDate, setActiveShiftId] = useActions([
    shiftActions.setPeriodType,
    shiftActions.setStartDate,
    shiftActions.setEndDate,
    shiftActions.setActiveShiftId
  ]);

  const periodType = useSelector(selectPeriodType);

  const onChange = value => {
    let newStart, newEnd;

    if (value === 'monthly') {
      newStart = moment().startOf('month').format(dateFormat);
      newEnd = moment().endOf('month').format(dateFormat);
    } else if (value === 'weekly') {
      newStart = moment().startOf('isoweek').format(dateFormat);
      newEnd = moment().endOf('isoweek').format(dateFormat);
    } else {
      newStart = moment().format(dateFormat);
      newEnd = newStart;
    }

    setStartDate(newStart);
    setEndDate(newEnd);
    setPeriodType(value);
    setActiveShiftId(null);
  };

  return (
    <div className="shifts-filters__period-select">
      <Select className="mb-0" options={periodOptions} value={periodType} onChange={onChange} />
    </div>
  );
};

export default PeriodSelect;
