import React from 'react';
import EventTable from 'events/index';
import { Row, Col } from 'reactstrap';
import useRouter from 'use-react-router';
import SaveTableLayout from 'components/layouts/page/table/save-table';
import paths from 'routing/routes/_paths';

const EventList = () => {
  const { history } = useRouter();

  return (
    <Row>
      <Col>
        <SaveTableLayout pageTitle={'Events'} label="events" />
      </Col>
      <Col xs="auto" className="d-flex align-items-start">
        <div
          className={`btn btn-primary page-layout--table-action type-create `}
          onClick={() => history.push(`${paths.events}/create`)}
        >
          Add Event
        </div>
      </Col>

      <Col xs={12}>
        <EventTable isOnBoard={true} />
      </Col>
    </Row>
  );
};

export default EventList;
