import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCaptainReportIsOnBoard,
  selectFieldTemplateTooltip
} from 'captain-reports/store/selectors';

import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const MainEngineHeaderJacketCoolingWater = () => {
  const isOnBoard = useSelector(selectCaptainReportIsOnBoard);
  const tooltip = useSelector(selectFieldTemplateTooltip('me_jacket_cool_water_inlet_temperature'));

  const { tooltipID, avoidRender } = useTooltipID('field-label-reports-jacket-cooling-water-inlet');

  if (avoidRender) return null;

  return (
    <div className="d-flex align-items-center justify-content-between fs-10 w-100p">
      <div>
        <span id={tooltipID} className={`${isOnBoard ? 'text-primary' : 'text-dark'}`}>
          JACKET COOLING WATER INLET TEMPERATURE
        </span>{' '}
        (°C)
        {tooltip ? <Tooltip target={tooltipID}>{tooltip}</Tooltip> : null}
      </div>
    </div>
  );
};

export default MainEngineHeaderJacketCoolingWater;
