import * as d3 from 'd3';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getD3XAxisTicksFn, getD3YAxisTicksFn, getDomainWithPadding } from './axes';

export const getZoom = (width, height) => {
  return d3
    .zoom()
    .scaleExtent([1, 180])
    .translateExtent([
      [0, 0],
      [width, height]
    ]);
};

export const zoomY = ({
  event,
  yAxesElements,
  yReference,
  getYAxisOption,
  y,
  height,
  width,
  element,
  getGraphStyle,
  leftOffset
}) => {
  if (!event.transform) return;

  const transformedAxes = [];

  y.forEach((yAxis, i) => {
    const rescaledYAxis = event.transform.rescaleY(yReference[i]);
    yAxis.axis.domain(rescaledYAxis.domain());
  });

  y.forEach((yAxis, i) => {
    const rescaledYAxis = event.transform.rescaleY(yReference[i]);
    yAxis.axis.domain(rescaledYAxis.domain());

    transformedAxes.push(rescaledYAxis);
  });

  yAxesElements.map((yAxisElement, index) => {
    yAxisElement.element
      .select('.y-axis-ticks__container')
      .call(
        getD3YAxisTicksFn({
          index: yAxisElement.yIndex,
          isAxisOnLeft: index % 2 === 0,
          y,
          getYAxisOption,
          height
        }).scale(transformedAxes[yAxisElement.yIndex])
      )
      .call(g => g.selectAll('.tick').attr('class', `tick ${getYAxisOption('tickClass') || ''}`));
  });

  // y grid lines:

  if (y[0]) {
    const yAxisGrid = d3
      .axisLeft(y[0].axis)
      .tickSize(-width)
      .tickFormat('')
      .ticks(getYAxisOption('ticks') || 5);
    element
      .select('.y-axis--grid')
      .call(yAxisGrid)
      .attr('transform', `translate(${getGraphStyle('marginLeft') + leftOffset} 0)`);
  }
};

export const autoAdjustY = ({
  event,
  yAxesElements,
  dataFromAllPlots,
  getYAxisOption,
  y,
  yReference,
  x,
  width,
  height,
  element,
  getGraphStyle,
  leftOffset,
  disabledPlots,
  schema
}) => {
  if (!event.transform) return;

  const customYDomain = getYAxisOption('customDomain') || [];
  const totalLeftOffset = getGraphStyle('marginLeft') + leftOffset;

  let totalMinY = Infinity;
  let totalMaxY = -Infinity;

  const mappedDisabledPlots = Object.assign(
    {},
    ...disabledPlots.map(plotIndex => ({ [plotIndex]: true }))
  );

  dataFromAllPlots.forEach(datum => {
    if (mappedDisabledPlots[datum.plotIndex] || y.length < datum.axisIndex) return;

    const xInPX = x(datum.x);

    if (xInPX < totalLeftOffset || xInPX > width + totalLeftOffset || !yReference?.length) return;

    const yInPX = yReference[datum.axisIndex](datum.y);

    if (yInPX < totalMinY) totalMinY = yInPX;
    if (yInPX > totalMaxY) totalMaxY = yInPX;
  });

  yAxesElements.forEach((yAxisElement, index) => {
    /* We do a very precise based on scaled values but we can't just do === because the scales sometimes
       return some very precise values that may differ in the 10th+ decimal point */
    if (
      (totalMinY === Infinity || totalMaxY === -Infinity) &&
      // If it doesn't have any data or all the data have the same value and it also doesn't have a custom domain, skip it
      _isEmpty(customYDomain)
    )
      return;

    let minY =
      customYDomain[0] !== 0 && !customYDomain[0]
        ? // We invert the opposites because y() gives us px starting from top left and our actual values start from the bottom
          yReference[index].invert(totalMaxY) //  <-  thats why you see max here
        : customYDomain[0];

    let maxY =
      customYDomain[1] !== 0 && !customYDomain[1]
        ? // We invert the opposites because y() gives us px starting from top left and our actual values start from the bottom
          yReference[index].invert(totalMinY) //  <-  thats why you see min here
        : customYDomain[1];

    // If y values are all the same we create a custom domain by adding 1 to max and subtracting 1 from min so there will be variety
    if (minY.toFixed(6) === maxY.toFixed(6)) {
      minY -= 1;
      maxY += 1;
    }

    y[index].axis.domain(getDomainWithPadding([minY, maxY], getYAxisOption));

    yAxisElement.element
      .select('.y-axis-ticks__container')
      .call(
        getD3YAxisTicksFn({
          index: yAxisElement.yIndex,
          isAxisOnLeft: index % 2 === 0,
          y,
          getYAxisOption,
          height
        })
      )
      .call(g => g.selectAll('.tick').attr('class', `tick ${getYAxisOption('tickClass') || ''}`));

    if (y[0]) {
      if (_get(schema, 'hideGridLines')) return;
      // y grid lines:
      const yAxisGrid = d3
        .axisLeft(y[0].axis)
        .tickSize(-width)
        .tickFormat('')
        .ticks(getYAxisOption('ticks') || 5);
      element
        .select('.y-axis--grid')
        .call(yAxisGrid)
        .attr('transform', `translate(${totalLeftOffset} 0)`);
    }
  });
};

export const zoomX = ({
  event,
  xAxisElement,
  x,
  xReference,
  getXAxisOption,
  schema,
  dateTimeFormat,
  height,
  getGraphStyle,
  element,
  width
}) => {
  if (!event.transform) return;

  const rescaledAxis = event.transform.rescaleX(xReference);

  x.domain(rescaledAxis.domain());

  xAxisElement
    .call(
      getD3XAxisTicksFn({ schema, x, getXAxisOption, dateTimeFormat, width }).scale(rescaledAxis)
    )
    .call(g => g.selectAll('.tick').attr('class', `tick ${getXAxisOption('tickClass')}`));

  if (_get(schema, 'hideGridLines')) return;

  // x grid lines:
  const xAxisGrid = d3
    .axisBottom(x)
    .tickSize(height)
    .tickFormat('')
    .ticks(getXAxisOption('ticks') || 5);

  element
    .select('.x-axis--grid')
    .call(xAxisGrid)
    .attr('transform', `translate(0 ${getGraphStyle('marginTop')})`);
};

const individualZoom = (event, zoomElement, dateTimeFormat) => {
  zoomX({
    event,
    xAxisElement: zoomElement.xAxisElement,
    x: zoomElement.x,
    xReference: zoomElement.xReference,
    getXAxisOption: zoomElement.getXAxisOption,
    schema: zoomElement.schema,
    dateTimeFormat,
    width: zoomElement.width,
    height: zoomElement.height,
    getGraphStyle: zoomElement.getGraphStyle,
    element: zoomElement.element
  });

  if (!_get(zoomElement.schema, 'isTimeseries')) {
    zoomY({
      event,
      yAxesElements: zoomElement.yAxesElements,
      yReference: zoomElement.yReference,
      getYAxisOption: zoomElement.getYAxisOption,
      y: zoomElement.y,
      height: zoomElement.height,
      width: zoomElement.width,
      element: zoomElement.element,
      getGraphStyle: zoomElement.getGraphStyle,
      leftOffset: zoomElement.leftOffset
    });
  } else {
    autoAdjustY({
      event,
      element: zoomElement.element,
      yAxesElements: zoomElement.yAxesElements,
      getYAxisOption: zoomElement.getYAxisOption,
      dataFromAllPlots: zoomElement.dataFromAllPlots,
      y: zoomElement.y,
      yReference: zoomElement.yReference,
      x: zoomElement.x,
      width: zoomElement.width,
      height: zoomElement.height,
      disabledPlots: zoomElement.disabledPlots,
      getGraphStyle: zoomElement.getGraphStyle,
      leftOffset: zoomElement.leftOffset,
      schema: zoomElement.schema,
      axesDimensions: zoomElement.axesDimensions
    });
  }

  zoomElement.renderPlots(
    zoomElement.element,
    zoomElement.memoryElement,
    zoomElement.x,
    zoomElement.y,
    zoomElement.getDimensions
  );
};

export const zoomAllGroupedGraphs = (
  event,
  events,
  graphUID,
  dateTimeFormat,
  isGroupZoomBlocked
) => {
  const groupGraphsAxesInfo = Object.values(events.groupGraphsAxesInfo);
  const zoomDOMElements = d3
    .selectAll(`.d3-graph.${events.getElementClass('zoom')} svg:not(#svg-${graphUID})`)
    .nodes();

  groupGraphsAxesInfo.forEach(zoomElement => {
    individualZoom(event, zoomElement, dateTimeFormat);
  });

  if (!isGroupZoomBlocked) {
    zoomDOMElements.forEach(zoomElement => {
      const elementID = `svg-${graphUID}`;

      if (zoomElement.id === elementID || !document.getElementById(elementID)) return;

      d3.select(zoomElement).call(
        getZoom(zoomElement.width, zoomElement.height).transform,
        event.transform
      );
    });
  }
};
