import React, { useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import { MultiValueLabel, Option } from './components/Styles';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { getFilterSelectorValues } from 'common/components/filters/helpers';

const JobSelector = ({ filter, onChange, isMulti, listParams }) => {
  const [selectedJobs, setSelectedJobs] = useState([]);

  useFilterSelector(
    filter?.value,
    { list: 'jobs', listParams },
    { selected: selectedJobs, setSelected: setSelectedJobs }
  );

  return (
    <Select
      className={`mb-0`}
      placeholder="Select value"
      autoFocus
      components={{ Option, MultiValueLabel }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.title}
      isAsync
      defaultOptions={() => getInitialAsyncValues('jobs', null, null, listParams)}
      isMulti={isMulti}
      value={getFilterSelectorValues(selectedJobs, filter?.value)}
      loadOptions={search => getAsyncOptions(search, 'jobs', listParams)}
      onChange={selected => {
        setSelectedJobs(selected);
        onChange({
          value: selected ? (isMulti ? [...selected.map(s => s.id)] : selected) : ''
        });
      }}
      isClearable
      // menuIsOpen
    />
  );
};

export default JobSelector;
