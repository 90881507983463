import React from 'react';
import { Row, Col } from 'reactstrap';

import renderProfileValue from 'common/components/layouts/profile-data-box/renderProfileValue';
import FormFieldLabel from 'common/components/layouts/vertical-tab-view/components/FormFieldLabel';

const InlineMultipleFieldsRow = ({ field, data, profileValue, className = '' }) => {
  return (
    <Row className={`profile-data-row align-items-center ${className}`}>
      <Col xs={4} className={`profile-data-row__left text-end ${field.labelClassName || ''}`}>
        <FormFieldLabel color={field.labelColor}>{field.label}</FormFieldLabel>
      </Col>
      <Col className={`profile-data-row__right fs-12 fw-normal`}>
        {data.map((d, i) => (
          <span key={i} className="pe-1">
            {renderProfileValue(d, profileValue)}
          </span>
        ))}
      </Col>
    </Row>
  );
};

export default InlineMultipleFieldsRow;
