import { FC, ReactNode } from 'react';
import { FormGroup, Label, Input, InputProps } from 'reactstrap';

interface RadioProps extends Omit<InputProps, 'type'> {
  error?: string | null;
  className?: string;
  name?: string;
  checked?: boolean;
  label?: ReactNode | string;
  invisible?: boolean;
  disabled?: boolean;
  type?: 'green' | 'primary' | 'red';
}

const Radio: FC<RadioProps> = ({
  error,
  className,
  name,
  checked,
  onChange,
  label,
  disabled,
  type,
  invisible
}) => {
  return (
    <FormGroup
      noMargin
      error={error}
      className={`form-group justify-content-center ${
        invisible ? 'invisible-input' : ''
      } input-wrapper${error ? ' has-error' : ''}${className ? ` ${className}` : ''}`}
    >
      {invisible && error ? <div className="input-error-icon"></div> : ''}
      <FormGroup className={`form-radio ${type ? `form-radio__${type}` : ''}`} check>
        <Label check>
          <Input
            name={name}
            type="radio"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className={checked ? 'checked' : ''}
          />
          <span className="checkbox-label">{label}</span>
          <span className="checkmark" />
        </Label>
      </FormGroup>
    </FormGroup>
  );
};

export default Radio;
