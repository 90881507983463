import React from 'react';
import DarkContainer from 'components/layouts/DarkContainer';

import LoginPage from './LoginPage';

const Login = () => {
  return (
    <DarkContainer>
      <LoginPage />
    </DarkContainer>
  );
};

export default Login;
