import React from 'react';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import SvgRender from 'common/components/general/SvgRender';

import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import error from 'common/assets/svg/common/error.svg';
import Tooltip from 'common/components/general/Tooltip';

const FormErrorIconWithTooltip = ({
  message = REQUIRED_FIELD_MESSAGE,
  wrapperClassName = 'form-error-icon-with-tooltip',
  svgClassName = 'cursor-pointer',
  svgIcon = error,
  svgStyle = { width: 12, height: 12 },
  tooltipClassName = '',
  tooltipInnerClassName = 'form-error-icon-with-tooltip--tooltip-inner text-start ps-1 text-red fs-10 fw-normal'
}) => {
  const { tooltipID, avoidRender } = useTooltipID('form-error-tooltip');

  if (avoidRender) return null;

  return (
    <div id={tooltipID} className={wrapperClassName}>
      <SvgRender className={svgClassName} src={svgIcon} style={svgStyle} />
      <Tooltip
        className={tooltipClassName}
        innerClassName={tooltipInnerClassName}
        target={tooltipID}
      >
        {message}
      </Tooltip>
    </div>
  );
};

export default FormErrorIconWithTooltip;
