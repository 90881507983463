import React, { useEffect, useState } from 'react';
import Drawer, { DrawerHeader, DrawerBody, DrawerFooter } from 'common/components/drawer';
import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import * as inventoryActions from 'common/components/inventory/store/actions';
import { Row, Col } from 'reactstrap';

import {
  selectInventoryHistoryDrawerIsOpen,
  selectInventoryHistoryDrawerActive
} from 'common/components/inventory/store/selectors';
import { setHistoryInventoryDrawerAction } from 'common/components/inventory/store/slice';

import { useSelector } from 'react-redux';
import Spinner from 'common/components/general/Spinner';
import { displayDate } from 'common/utils/dates';
import { isRedirectable, redirectTo } from './helpers';

const HistoryDrawer = () => {
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getInventoryHistory, setHistoryInventoryDrawer] = useActions([
    inventoryActions.getInventoryHistory,
    setHistoryInventoryDrawerAction
  ]);

  const isOpen = useSelector(selectInventoryHistoryDrawerIsOpen);
  const active = useSelector(selectInventoryHistoryDrawerActive);

  const fetchHistoryData = async () => {
    setIsLoading(true);

    try {
      const res = await getInventoryHistory({
        item_id: active.entity_id,
        item_type: active.entity_type,
        vessel_id: active.entity_vessel_id
      }).unwrap();

      setHistoryData(res?.data || []);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setHistoryData([]);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchHistoryData();
    }
  }, [isOpen]);

  const onClose = () => {
    setHistoryInventoryDrawer({ isOpen: false, active: null });
    setHistoryData([]);
  };

  return (
    <Drawer size="sm-md" className="history-logs" isOpen={isOpen} close={onClose}>
      <DrawerHeader>
        <div>
          <div>History</div>
          <div className="text-black fs-12 mt-1 fw-bold">{active?.entity_name}</div>
        </div>
      </DrawerHeader>

      <DrawerBody>
        {isLoading ? (
          <Spinner />
        ) : (
          historyData?.map((history, index) => (
            <div className={`pb-2 ${index === 0 ? 'pt-3' : ''} history-logs__timeline`}>
              <Row noGutters>
                <Col
                  className={`d-flex text-violet align-items-center ${
                    isRedirectable(history?.type) ? 'cursor-pointer' : ''
                  }`}
                  onClick={() => redirectTo(history)}
                >
                  {history.type}
                </Col>
                <Col xs={9} className="pe-1 text-primary fw-medium">
                  {history.quantity} @ {displayDate(history.timestamp, { time: true })}
                </Col>
              </Row>
            </div>
          ))
        )}
      </DrawerBody>
      <DrawerFooter className="d-flex justify-content-end">
        <Button color="cancel" onClick={onClose} disabled={''}>
          CLOSE
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default HistoryDrawer;
