import { RootState } from '@/store';
import { strToNumber } from '@/ts-common/utils/numbers';
import { createSelector } from 'reselect';

export const selectOrganizationSettings = (state: RootState) => state.settings;

export const selectSystemCurrency = () => null;

export const selectSystemCurrencyLabel = () => null;

export const selectSystemCurrencySymbol = () => null;

export const selectExternalEmailStatus = () => null;

export const selectPurchasingRequisitionsHighlightCheapest = () => null;

export const selectPurchasingRequisitionsHighlightMostExpensive = () => null;

export const selectEvaluationsRangeFrom = createSelector(selectOrganizationSettings, state => {
  const from = strToNumber(state.evaluations_range_from);

  if (from !== null) {
    return from;
  }

  return 1;
});

export const selectEvaluationsRangeTo = (state: RootState) =>
  +selectOrganizationSettings(state).evaluations_range_to || 5;

export const selectEvaluationsStep = (state: RootState) =>
  +selectOrganizationSettings(state).evaluations_step || 0.5;

const selectPmsAssignSystemAndSubsystemOrAssignmentToGroup = (state: RootState) =>
  selectOrganizationSettings(state).pms_assign_system_and_subsystem_or_assignment_to_group;

export const selectPmsAssignSystemAndSubsystemToGroup = (state: RootState) => {
  const setting = selectPmsAssignSystemAndSubsystemOrAssignmentToGroup(state);

  return setting === 'system' || !setting;
};

export const selectPmsAssignVesselAssignmentToGroup = (state: RootState) =>
  selectPmsAssignSystemAndSubsystemOrAssignmentToGroup(state) === 'system_assignment';

export const selectPmsVesselSystemAssignmentDescriptionPattern = (state: RootState) =>
  selectOrganizationSettings(state).pms_vessel_system_assignment_description_pattern;

export const selectPmsJobsRequireTotalSystemRunningHoursWhenReportedInTheSystem = (
  state: RootState
) =>
  selectOrganizationSettings(state)
    .pms_jobs_require_total_system_running_hours_when_reported_in_the_system;

export const selectItineraryEditScheduledPortsOnboard = (state: RootState) =>
  selectOrganizationSettings(state).itinerary_edit_scheduled_ports_onboard;

export const selectCrewProfileDefaultView = (state: RootState) =>
  selectOrganizationSettings(state).crew_profile_default_view;
