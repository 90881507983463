import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import warning from 'common/assets/svg/common/validation-error.svg';
import warningEmpty from 'common/assets/svg/common/warning-empty.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const Warning = ({ valuesMessage, svgClassName = '' }) => {
  const { tooltipID, avoidRender } = useTooltipID('field-label-reports');

  if (avoidRender) return null;

  return (
    <>
      <div id={tooltipID} className="d-flex align-items-center cursor-pointer">
        <SvgRender
          className={`text-warning number-validation-warning ${svgClassName}`}
          src={warning}
          style={{ width: 16, height: 16 }}
        />
      </div>
      <Tooltip
        hideArrow={true}
        innerClassName="bg-white min-width-fit"
        placement="right"
        target={tooltipID}
      >
        <div className="d-flex align-items-center text-warning border-bottom pb-1 cmb-12">
          <SvgRender
            className="text-warning cme-12"
            src={warningEmpty}
            style={{ height: 13, width: 13 }}
          />
          <span>Attention</span>
        </div>
        <div className="text-primary fs-14 text-start">{valuesMessage}</div>
      </Tooltip>
    </>
  );
};

export default Warning;
