import PropTypes from 'prop-types';
import PageSaving from 'common/components/general/PageSaving';
import CircledButton from 'common/components/buttons/CircledButton';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import {
  selectTripParties,
  selectIsTripEditting,
  selectTripFieldData,
  selectTripHasValidationErrors
} from 'store/ticketing/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { Label } from '../_components/Field';

import {
  updateTripData,
  updateTripParty,
  updateTripParties,
  addTripParticipant,
  editTrip
} from 'store/ticketing/actions';
import ShadowBox from 'common/components/general/ShadowBox';
import TicketingTypeSelector from '../_components/TicketingTypeSelector';
import VesselSelector from 'common/components/selectors/VesselSelector';
import PortSelector from 'common/components/selectors/PortSelector';
import DateInput from 'common/components/form/inputs/date';
import RemoveTripPerson from '../_components/RemoveTripPerson';
import Totals from 'common/components/tickets/drawer/Totals';

const ParticipantsWithoutTicketingCase = ({ tripID, onDeleteTripParticipant }) => {
  const dispatch = useDispatch();

  const tripParties = useSelector(state => selectTripParties(state, tripID));
  const isEditting = useSelector(selectIsTripEditting);

  const vessel = useSelector(state => selectTripFieldData(state, 'vessel'));
  const port = useSelector(state => selectTripFieldData(state, 'port'));
  const date = useSelector(state => selectTripFieldData(state, 'started_at'));

  const tripHasValidationErrors = useSelector(selectTripHasValidationErrors);
  const onAutosaveField = useCallback(
    (updated, tripParties) => {
      if (tripID) {
        if (tripParties) {
          const { index, person, type } = updated;

          if (person) {
            // Add new participant
            const newParties = { id: person.id, ticketing_type_id: type.id };
            dispatch(addTripParticipant({ tripID, parties: newParties }));
          } else if (type) {
            // Update the type of an existing participant
            const updatedTripParties = [...tripParties];
            updatedTripParties[index] = { ...updatedTripParties[index], type };

            if (updatedTripParties[index].person && updatedTripParties[index].type) {
              dispatch(
                editTrip({
                  tripID,
                  parties: updatedTripParties.map(p => ({
                    id: p.person.id,
                    ticketing_type_id: p.type.id
                  }))
                })
              );
            }
          }
        } else {
          dispatch(editTrip({ tripID, ...updated }));
        }
      }
    },
    [dispatch, tripID]
  );

  const onChangeField = useCallback(
    (updated, params) => {
      if (params) {
        dispatch(updateTripParty({ personId: params?.person?.id, index: params.index }, updated));
      } else {
        dispatch(updateTripData(updated));
      }
    },
    [dispatch]
  );

  const getSelectedParties = useCallback(
    (tripParties, maxIndex = 20) =>
      tripParties.filter((_, index) => index <= maxIndex).map(({ person }) => person?.id),
    []
  );

  return (
    <div className="mb-2 border-bottom">
      <Row className="mb-2" noGutters>
        <Col xs={6} className="cpe-4">
          <ShadowBox className="px-3 py-1 h-100p -d-flex flex-column" color="light-3" flat>
            <div className="mb-1 flex-1">
              {tripParties.map(({ person, type }, index) => (
                <Row className="flex-nowrap align-items-center mb-1" key={index}>
                  <Col xs="auto" className="width-56">
                    <Label>Person</Label>
                  </Col>
                  <Col xs={6}>
                    <AsyncSelector
                      onChange={value => {
                        onChangeField({ person: value }, { person: value, index });
                        onAutosaveField({ person: value, type, index }, tripParties);
                      }}
                      type="parties"
                      placeholder="Select person"
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.full_name}
                      value={person}
                      invisible
                      isClearable={false}
                      className="mb-0"
                      selectClassName="overflow-hidden"
                      error={tripHasValidationErrors && !person ? 'Required' : ''}
                      disabled={tripID && person}
                      filterOption={option =>
                        !tripParties.find(({ person }) => person?.id === option.value)
                      }
                      listParams={{ exclude_ids: getSelectedParties(tripParties) }}
                    />
                  </Col>
                  <Col>
                    <TicketingTypeSelector
                      value={type?.id}
                      onChange={value => {
                        onChangeField({ type: value }, { person, index, tripParties });
                        onAutosaveField({ type: value, index }, tripParties);
                      }}
                    />
                  </Col>
                  <Col xs="auto" className="d-inline-flex justify-content-end align-items-center">
                    {tripID && person ? (
                      <RemoveTripPerson
                        person={person}
                        tripID={tripID}
                        tripParties={tripParties}
                        onDeleteTripParticipant={onDeleteTripParticipant}
                      />
                    ) : tripParties?.length > 1 ? (
                      <CircledButton
                        type={'remove'}
                        onClick={() =>
                          dispatch(updateTripParties({ isRemoved: true }, { tripID, index }))
                        }
                        style={{ width: 17, height: 17 }}
                        svgStyle={{ width: 6, height: 1 }}
                        disabled={tripParties?.length === 1}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}
            </div>

            <div className="mt-auto">
              <CircledButton
                type={'add'}
                label="Add more"
                svgStyle={{ width: 8, height: 8 }}
                style={{ width: 18, height: 18 }}
                onClick={() => dispatch(updateTripParties({ isNew: true }, { tripID }))}
                disabled={tripParties?.some(p => !p.person)}
              />
            </div>
          </ShadowBox>
        </Col>
        <Col xs={6} className="cps-4">
          <ShadowBox className="px-3 py-1 h-100p" color="light-3" flat>
            <Row className="mb-2">
              <Col xs={8}>
                <Row>
                  <Col xs="auto" className="width-56">
                    <Label>Vessel</Label>
                  </Col>
                  <Col>
                    <VesselSelector
                      value={vessel}
                      wrapperClassName="mb-0 flex-1"
                      placeholder="Select vessel"
                      onChange={value => {
                        onChangeField({ vessel: value });
                        onAutosaveField({ vessel_id: value?.id || null });
                      }}
                      isMulti={false}
                      autoFocus={false}
                      invisible
                      isClearable
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <Row>
                  <Col xs="auto" className="width-56">
                    <Label>Port</Label>
                  </Col>
                  <Col>
                    <PortSelector
                      className="mb-0 flex-1"
                      placeholder="Select port"
                      isMulti={false}
                      autoFocus={false}
                      invisible
                      onChange={({ value }) => {
                        onChangeField({ port: value });
                        onAutosaveField({ port_id: value?.id || null });
                      }}
                      filter={{ value: port }}
                      error={tripHasValidationErrors && !port ? 'Required' : ''}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={4}>
                <Row className="align-items-center">
                  <Col xs="auto" className="width-56">
                    <Label>Date</Label>
                  </Col>
                  <Col>
                    <DateInput
                      className="mb-0"
                      invisible
                      useStringValue
                      value={date}
                      onChange={date => {
                        onChangeField({ started_at: date });
                        onAutosaveField({ started_at: date || null });
                      }}
                      error={tripHasValidationErrors && !date ? 'Required' : ''}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ShadowBox>
        </Col>
      </Row>

      {tripID ? null : <Totals />}

      <PageSaving isSaving={isEditting} />
    </div>
  );
};

ParticipantsWithoutTicketingCase.propTypes = {
  tripID: PropTypes.number,
  onDeleteTripParticipant: PropTypes.func
};

export default ParticipantsWithoutTicketingCase;
