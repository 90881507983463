import React from 'react';

import { Row, Col } from 'reactstrap';

import renderGroupFields from './renderGroupFields';
import { SectionWrapper, SectionHeader, SectionInner } from './_parts';

const ProfileDataBox = ({ sections = [], profile = {}, className = '' }) => {
  return sections.length ? (
    <Row className={className}>
      {sections.map((section, index) => {
        const breakpoints = section.breakpoints ? section.breakpoints : { xs: 12 };

        return section.condition === undefined || section.condition(profile) ? (
          <Col key={index} className={`${section.sectionClassName || ''}`} {...breakpoints}>
            <SectionWrapper key={index}>
              {section.customComponent ? (
                <section.customComponent key={index} fields={section.fields} />
              ) : (
                <>
                  <SectionHeader header={section.header} />
                  <SectionInner
                    emptyBox={section.emptyBox}
                    footer={section.footer}
                    profile={profile}
                  >
                    {renderGroupFields(section, profile)}
                  </SectionInner>
                </>
              )}
            </SectionWrapper>
          </Col>
        ) : null;
      })}
    </Row>
  ) : null;
};

export default ProfileDataBox;
