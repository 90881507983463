import React, { useEffect, useState } from 'react';
import Drawer, { DrawerHeader, FormFooter } from 'common/components/drawer';

import { FormDrawer } from 'common/components/drawer';
import { FormBody } from 'common/components/drawer';
import { useForm, useFormState } from 'utils/hooks';
import Select from 'common/components/form/inputs/Select';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { optionField, stringField } from 'common/utils/form/fieldTypes';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import Textarea from 'common/components/form/inputs/Textarea';
import UploadFiles from 'common/components/form/inputs/upload-files';
import { lubOilSystemAnalysisResultsOptions } from 'common/utils/fixed';
import { selectEventId } from 'events/store/events/selectors';
import { updateEventLubOilAnalysisAssignment } from 'events/store/event-modules/lub-oil-analysis/actions';
import { components } from 'react-select';
import LubOilAnalysisResult from 'events/components/LubOilAnalysisResult';

const config = {
  attachments: optionField(),
  comments: stringField(),
  company: optionField(),
  analysis: optionField()
};

const EditSystemForm = ({ drawer, active, refetchData }) => {
  const [isSaving, setIsSaving] = useState();
  const { formState, collectValues, loadValues } = useForm(config);
  const { fields, selectField, changeField } = useFormState(formState);

  const dispatch = useDispatch();
  const isOnboard = useSelector(state => state.isOnBoard);
  const eventId = useSelector(selectEventId);

  const onSave = async () => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSaving(true);

      const { attachments, comments, company, analysis } = values;
      const params = { event_id: eventId, id: active?.id };

      if (isOnboard) {
        params.vessel_comments = comments;
        params.vessel_attachment_ids = attachments?.map(({ id }) => id);
      } else {
        params.office_comments = comments;
        params.office_attachment_ids = attachments?.map(({ id }) => id);
        params.company_id = company?.id ?? null;
        params.analysis = analysis;
      }

      await dispatch(updateEventLubOilAnalysisAssignment(params)).unwrap();

      drawer.close();
      refetchData();
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (drawer.isOpen && active) {
      const { vessel_comments, vessel_attachments, office_comments, office_attachments, analysis } =
        active;
      const initialValues = {
        ...active,
        attachments: isOnboard ? vessel_attachments : office_attachments,
        comments: isOnboard ? vessel_comments : office_comments,
        analysis: analysis || lubOilSystemAnalysisResultsOptions[0].value
      };

      loadValues(initialValues);
    }
  }, [active, drawer.isOpen, isOnboard, loadValues]);

  return (
    <Drawer {...drawer}>
      <DrawerHeader>
        <div>Edit System</div>
        <div className="text-truncate fw-bold fs-12 cmt-4">
          {active?.vessel_system_assignment?.description ?? ''}
        </div>
      </DrawerHeader>

      <FormDrawer>
        <FormBody>
          {isOnboard ? null : (
            <AsyncSelector
              label="COMPANY"
              placeholder="Select company"
              getOptionLabel={option => option.full_name}
              type="parties"
              listParams={{ type: 'company' }}
              onChange={selectField('company')}
              {...fields.company}
            />
          )}
          <Textarea
            label="COMMENTS"
            placeholder="Add some text"
            onChange={changeField('comments')}
            rows={10}
            {...fields.comments}
          />

          <div className="mt-4 pt-4 border-top"></div>

          {isOnboard ? null : (
            <Row>
              <Col xs={4}>
                <Select
                  label="ANALYSIS"
                  onChange={selectField('analysis')}
                  options={lubOilSystemAnalysisResultsOptions}
                  components={{ Option, SingleValue }}
                  {...fields.analysis}
                />
              </Col>
            </Row>
          )}

          <UploadFiles
            label={isOnboard ? '' : 'ANALYSIS ATTACHMENTS'}
            group="events.lub-oil-analysis"
            files={fields.attachments?.value}
            onChange={selectField('attachments')}
            size="lg"
          />
        </FormBody>
        <FormFooter>
          <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
            CANCEL
          </Button>
          <Button onClick={onSave} disabled={isSaving} color="primary" className="px-4">
            SAVE
          </Button>
        </FormFooter>
      </FormDrawer>
    </Drawer>
  );
};

const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <LubOilAnalysisResult analysis={data?.value} />
    </components.Option>
  );
};

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <LubOilAnalysisResult analysis={data?.value} />
  </components.SingleValue>
);

export default EditSystemForm;
