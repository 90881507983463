import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import calendarIcon from 'common/assets/svg/common/calendar.svg';
import moment from 'moment';
import PropTypes from 'prop-types';

const Date = ({ date }) => {
  return (
    <div className="d-flex align-items-center text-violet">
      <SvgRender src={calendarIcon} style={{ width: 11, height: 11 }} />
      <span className="ms-1 fs-12">
        Date: <strong>{moment(date).format('DD/MM/YYYY')}</strong>
      </span>
    </div>
  );
};

Date.propTypes = {
  date: PropTypes.string
};

export default Date;
