import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import useRouter from 'use-react-router';
import * as sidebarActions from 'store/sidebar/actions';
import AuthCheck from 'components/permissions/AuthCheck';
import paths from 'routing/routes/_paths';

import arrow from 'assets/svg/common/arrow.svg';
import {
  getMenuItemOrganizationSettings,
  getMenuItemPermissions
} from 'common/utils/permissions/helpers';
import { selectAccount } from 'store/account/selectors';

const isLinkActiveCustomLogic = ({ match, location }, item) => {
  if (location.pathname.includes(paths.forms) && item.label === 'forms') {
    return true;
  } else if (location.pathname.includes(paths.working_schedule) && item.label === 'watchkeeping') {
    return true;
  } else if (location.pathname.includes(paths.filesystem) && item.label === 'manuals') {
    return true;
  } else if (match && match.isExact) {
    return true;
  } else if (location.pathname.startsWith(`${item.path}/`) && item.path !== '/') {
    return true;
  } else if (item.groupPath && location.pathname.startsWith(`${item.groupPath}`)) {
    return true;
  } else return false;
};

const MenuItem = ({ item, onItemClick, isStatic, isDashboard }) => {
  const [togglePanel] = useActions([sidebarActions.togglePanel]);
  const { location } = useRouter();
  const account = useSelector(selectAccount);
  const pathname = useSelector(state =>
    item?.selectPath ? item.selectPath(state, item) : item?.path
  );

  const getParentActiveStatus = item => {
    if (item.submenu && item?.label?.some(label => location.pathname.startsWith(`${label}`))) {
      return true;
    } else if (item.label.includes(paths.pms_jobs_label) && location.pathname === paths.pms_jobs) {
      return true;
    }
  };

  return (
    <AuthCheck
      permissions={getMenuItemPermissions(item)}
      requiredOrganizationSettings={getMenuItemOrganizationSettings(item)}
    >
      <div className={`menu-item${isStatic ? ' menu-item--static' : ''}`}>
        <div className="menu-item--nav">
          {item.submenu ? (
            <Button
              type="button"
              className={getParentActiveStatus(item) ? 'active' : ''}
              color="link"
              onClick={() => onItemClick(item)}
            >
              <SvgRender
                className="menu-item--nav-icon"
                src={item.icon}
                style={{ width: 18, height: 18 }}
              />
              {item.name}
              <SvgRender
                className="menu-item--nav-arrow"
                src={arrow}
                style={{ width: 10, height: 10 }}
              />
            </Button>
          ) : (
            <NavLink
              to={{ pathname, search: '' }}
              exact={true}
              isActive={(match, location) =>
                isLinkActiveCustomLogic({ match, location }, item, account)
              }
              onClick={() => togglePanel(null)}
            >
              {item.icon ? (
                <SvgRender
                  className="menu-item--nav-icon"
                  src={item.icon}
                  style={{ width: 18, height: 18 }}
                />
              ) : (
                <div className="menu-item--nav-icon ps-2" />
              )}
              <div>
                <div className={`${isDashboard && item.is_owned === false ? 'mt-2' : ''}`}>
                  {item.name}
                </div>
                {isDashboard && item.is_owned === false ? (
                  <div className=" mt-1 mb-2 d-flex align-items-center fs-10 text-primary max-width-48 shared-dashboard-label">
                    <span className="fw-normal cme-4">Shared</span>
                  </div>
                ) : null}
              </div>
            </NavLink>
          )}
        </div>
      </div>
    </AuthCheck>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string,
    selectPath: PropTypes.func
  }),
  onItemClick: PropTypes.func,
  isStatic: PropTypes.bool,
  isDashboard: PropTypes.bool
};

export default MenuItem;
