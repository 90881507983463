export const getColor = status => {
  switch (status) {
    case 'working':
      return 'blue';

    case 'pending_approval':
    case 'pending_release':
      return 'orange';

    case 'active':
      return 'green';

    default:
      return 'violet';
  }
};
