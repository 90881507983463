import SvgRender from 'common/components/general/SvgRender';
import arrows from 'common/assets/svg/table/sorting.svg';
import arrow from 'assets/svg/common/arrow.svg';

import PropTypes from 'prop-types';

const SortingArrows = ({ sorted, order }) => {
  return (
    <SvgRender
      src={sorted ? arrow : arrows}
      style={{
        height: 14,
        width: 10,
        transform: order === 'asc' ? 'rotate(180deg)' : 'none'
      }}
      className={`ms-1 sorting-arrows ${
        order === 'asc' ? 'cmb-4' : order === 'desc' ? 'cmt-4' : ''
      }`}
    />
  );
};

SortingArrows.propTypes = {
  sorted: PropTypes.bool,
  order: PropTypes.oneOf(['asc', 'desc'])
};

export default SortingArrows;
