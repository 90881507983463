import TextInput from './components/TextInput';
import Select from 'common/components/form/inputs/Select';
import NumberInput from './components/NumberInput';
import DateInput, { mode } from 'common/components/form/inputs/date';

import _get from 'lodash/get';

import moment from 'moment';
import { dateToLocal, dateToUtc, getCustomFormat } from 'common/utils/dates';

const SelectValue = ({ filter, updateFilter, getTableFilterValue, autoFocus = true }) => {
  const defaultFilterValuesYearStart =
    filter.column?.defaultFilterValues?.[filter?.operation?.value]?.yearStart;

  const defaultFilterValuesYearEnd =
    filter.column?.defaultFilterValues?.[filter?.operation?.value]?.yearEnd;

  const renderValueField = () => {
    switch (filter.column.type) {
      case 'string':
      case 'notes':
        return <TextInput updateFilter={updateFilter} filter={filter} />;

      case 'number':
      case 'price':
      case 'periodicity_interval':
        return (
          <NumberInput
            isAllowed={input => input.value !== '.'}
            updateFilter={updateFilter}
            filter={filter}
            autoFocus={autoFocus}
            {...filter.column.componentRest}
          />
        );

      case 'percentage':
        return (
          <NumberInput
            isAllowed={input =>
              (input?.floatValue >= 0 && input?.floatValue <= 100) || !input?.floatValue
            }
            updateFilter={updateFilter}
            filter={filter}
            autoFocus={autoFocus}
          />
        );

      case 'boolean':
      case 'chat':
        return (
          <Select
            autoFocus={autoFocus}
            placeholder="Select value"
            value={filter.value}
            options={filter.column.valueOptions}
            onChange={selected => updateFilter({ value: selected })}
          />
        );

      case 'collectionOneOf':
      case 'collectionMultiple':
      case 'collection':
        if (!filter.column.component) {
          return (
            <Select
              autoFocus={autoFocus}
              placeholder="Select value"
              value={filter.value}
              options={filter.column.valueOptions}
              onChange={selected => updateFilter({ value: selected })}
              isMulti={true}
              canSelectAllOptions={false}
            />
          );
        } else {
          const TagName = filter.column.component;

          const onChange = selected => {
            if (selected?.value !== undefined) {
              updateFilter(selected);
            } else {
              updateFilter({ value: selected });
            }
          };

          return (
            <TagName
              autoFocus={autoFocus}
              filter={filter}
              onChange={onChange}
              getTableFilterValue={getTableFilterValue}
              canSelectAllOptions={false}
              {...filter.column.componentRest}
            />
          );
        }

      case 'date':
      case 'datetime':
      case 'date_period':
        if (filter.operation && filter.operation.value === 'between') {
          const dates =
            !filter.value || filter.value === ''
              ? { from: null, to: null }
              : filter.column.showUtc
              ? {
                  from: moment(filter.value.from),
                  to: moment(filter.value.to)
                }
              : {
                  from: dateToLocal(filter.value.from),
                  to: dateToLocal(filter.value.to)
                };
          return (
            <DateInput
              className="filter-date-input mb-0"
              activeMode={mode.range.date}
              onChange={({ from, to }) => {
                if (filter.column.type === 'date') {
                  updateFilter({ value: { from, to } });
                } else if (filter.column.showUtc) {
                  updateFilter({
                    value: {
                      from: getCustomFormat(from, 'YYYY-MM-DD HH:mm'),
                      to: getCustomFormat(to, 'YYYY-MM-DD HH:mm')
                    }
                  });
                } else {
                  updateFilter({
                    value: {
                      from: dateToUtc(from, 'startOf', 'YYYY-MM-DD HH:mm'),
                      to: dateToUtc(to, 'endOf', 'YYYY-MM-DD HH:mm')
                    }
                  });
                }
              }}
              placeholder="Starts - Ends"
              useDateInput={false}
              useStringValue={true}
              yearStart={defaultFilterValuesYearStart}
              yearEnd={defaultFilterValuesYearEnd}
              {...dates}
            />
          );
        } else if (
          filter.operation &&
          (filter.operation.value === 'lastDays' || filter.operation.value === 'nextDays')
        ) {
          return (
            <NumberInput
              placeholder="No. of days"
              updateFilter={val => {
                const { value } = val;

                updateFilter({ value: value ? [value, moment.tz.guess()] : '' });
              }}
              filter={{ ...filter, value: _get(filter, 'value[0]', '') }}
              isAllowed={val => val.value === '' || val.floatValue > 0}
            />
          );
        } else if (
          filter.operation &&
          (filter.operation.value === 'beforeDaysFromToday' ||
            filter.operation.value === 'afterDaysFromToday')
        ) {
          return (
            <NumberInput
              placeholder="No. of days"
              updateFilter={({ value }) => updateFilter({ value })}
              filter={{ ...filter, value: _get(filter, 'value', '') }}
              isAllowed={val => (val.value.includes('.') ? false : true)}
            />
          );
        } else {
          return (
            <DateInput
              className="filter-date-input mb-0"
              value={
                filter.column.showUtc && filter.value
                  ? moment(filter.value)
                  : dateToLocal(filter.value)
              }
              onChange={date => {
                if (filter.column.type === 'date') {
                  return updateFilter({ value: date });
                } else if (filter.column.showUtc) {
                  return updateFilter({
                    value: getCustomFormat(date, 'YYYY-MM-DD HH:mm')
                  });
                } else {
                  return updateFilter({ value: dateToUtc(date, 'startOf', 'YYYY-MM-DD HH:mm') });
                }
              }}
              placeholder="Select day"
              useDateInput={false}
              useStringValue={true}
              yearStart={defaultFilterValuesYearStart}
              yearEnd={defaultFilterValuesYearEnd}
            />
          );
        }

      case 'relative_direction':
      case 'inverse_relative_direction':
        return (
          <Select
            autoFocus={autoFocus}
            placeholder="Select value"
            value={filter.value}
            options={filter.column.valueOptions}
            onChange={selected => updateFilter({ value: selected })}
            isMulti={true}
          />
        );

      case 'ocimf_system_category': {
        const TagName = filter.column.component;

        const onChange = selected => {
          updateFilter({ value: selected });
        };

        return <TagName filter={filter} onChange={onChange} {...filter.column.componentRest} />;
      }
      default:
        return 'Not implemented yet';
    }
  };

  return <div className="filter-handler--field">{renderValueField()}</div>;
};

export default SelectValue;
