import React, { useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import { selectIfFieldIsVisible } from 'store/jobs-fields/selectors';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

import * as jobRestrictedFieldsActions from 'store/jobs-fields/actions';

import { Option, SingleValue } from 'common/components/selectors/_partySelectComponents';

const Assignee = ({ hideLabel, avoidCheck = false }) => {
  const [setJobField, setJobFieldError, checkIfFieldIsRequired] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError,
    jobRestrictedFieldsActions.checkIfFieldIsRequired
  ]);
  const assignee = useSelector(state => selectJobField(state, 'assignee'));
  const jobAssigneeError = useSelector(state => selectJobFieldError(state, 'assignee'));
  const isJobLocked = useSelector(state => selectIsJobLocked(state, avoidCheck ? false : true));

  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));

  const isEditMode = useSelector(checkIfEditMode);

  const isVisible = useSelector(selectIfFieldIsVisible('assignee_id'));

  const handleChange = val => {
    setJobField('assignee', val, true);

    if (jobAssigneeError && val?.id) {
      setJobFieldError({ assignee: null });
    }
  };

  const FieldComponent = ({ ...innerRest }) => (
    <Select
      label={hideLabel ? null : 'Assignee'}
      className={`job-input job-input--assignee form-group-spacing`}
      placeholder="Select assignee"
      getOptionValue={option => (isForVessel ? option.entity_uid : option.id)}
      value={assignee}
      error={jobAssigneeError}
      isAsync
      disabled={isJobLocked}
      loadOptions={search =>
        isForVessel
          ? getAsyncOptions(search, 'ranks-n-parties')
          : getAsyncOptions(search, 'parties', { can_login: true })
      }
      defaultOptions={() =>
        isForVessel
          ? getInitialAsyncValues('ranks-n-parties')
          : getInitialAsyncValues('parties', null, false, { can_login: true })
      }
      onChange={handleChange}
      components={{ Option, SingleValue }}
      isClearable={isEditMode ? (checkIfFieldIsRequired('assignee_id') ? false : true) : true}
      defaultOptionsTriggerChange={isForVessel}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for persons` : `No persons found`
      }
      {...innerRest}
    />
  );

  useEffect(() => {
    // Reset the assignee when the selected one is a crew Rank and isForVessel equals to false

    if (!isForVessel && assignee && assignee?.entity_type === 'crew_rank') {
      handleChange(null);
    }
  }, [isForVessel]);

  if (!isVisible) return null;

  return (
    <UserCanEdit
      field="assignee_id"
      fallback={() => <FieldComponent disabled />}
      avoidCheck={avoidCheck}
    >
      <FieldComponent disabled={isJobLocked} />
    </UserCanEdit>
  );
};

export default Assignee;
