import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Input from 'common/components/form/inputs/Input';
import DateInput from 'common/components/form/inputs/date';

const FlagDispensation = ({ fields, selectField, changeField }) => {
  return (
    <Row>
      <Col xs="auto">
        <DateInput
          label="Completed On"
          onChange={selectField('completed_on')}
          {...fields.completed_on}
        />
      </Col>
      <Col>
        <DateInput
          label="Flag Informed On"
          onChange={selectField('flag_informed_on')}
          {...fields.flag_informed_on}
        />
      </Col>
      <Col xs={8}>
        <Input
          label="Extension/Dispensation Requested for"
          onChange={changeField('extension_requested')}
          {...fields.extension_requested}
        />
      </Col>
    </Row>
  );
};

FlagDispensation.propTypes = {
  fields: PropTypes.object.isRequired,
  selectField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired
};

export default FlagDispensation;
