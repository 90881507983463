import React from 'react';

import ConsumptionInput from 'captain-reports/templates/components/custom-fields/ConsumptionInput';
import useValue from 'captain-reports/utils/useValue';

import { consumption_per_dg_keys } from 'captain-reports/templates/config/bunkerConsumptions';
import { useSelector } from 'react-redux';

import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';

const AutoCompleteDgConstTotal = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  return (
    <div className="template-field p-0 noborder-input dark-placeholder">
      <ConsumptionInput
        field={{
          ...field,
          disabled: true,
          inputProps: { placeholder: value || value === 0 ? '' : '-' }
        }}
        className="view-only"
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </div>
  );
};

const ConsumptionDgConsTotalInput = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const visibleConsPerDgFields = useSelector(state =>
    hasAtLeastOneVisibleField(
      state,
      consumption_per_dg_keys.map(key => ({ key }))
    )
  );

  /* When any of the 'consumption_per_dg_keys' are visible,
   the 'me_consumption_total' is disabled and gets its value by summing the 'consumption_per_dg_keys' values.
   Otherwise, it's an editable Input field.
  */

  return visibleConsPerDgFields ? (
    <AutoCompleteDgConstTotal
      field={field}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  ) : (
    <ConsumptionInput
      field={{ ...field, autocomplete: false }}
      sectionLabel={sectionLabel}
      sectionActiveTab={sectionActiveTab}
      subGroup={subGroup}
      subGroupIndex={subGroupIndex}
    />
  );
};

export default ConsumptionDgConsTotalInput;
