import { useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';

import TotalsBar from 'common/components/graph/TotalsBar';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const ContentCaptainReports = () => {
  const totals = useSelector(state => selectDashboardTotals(state, 'captain_reports'));

  return totals ? (
    <div className="dashboard__totals-captain-reports">
      <TotalsBar
        color="white"
        legends={[
          { label: 'Missing', total: totals?.missing || 0 },
          { label: 'Draft', total: totals?.draft || 0, color: variables.frenchSkyBlue },
          { label: 'For Correction', total: totals?.for_correction || 0, color: variables.red }
        ]}
      />
    </div>
  ) : null;
};

export default ContentCaptainReports;
