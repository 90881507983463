import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import Information from 'common/components/general/Information';
import NumberInput from 'common/components/form/inputs/NumberInput';
import {
  selectData,
  selectErrors,
  isReportLocked,
  selectPreviouVesselSystems,
  selectIsEditting,
  selectPreviousReportTimestamp
} from 'captain-reports/store/system-running-hours/selectors';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import * as runningHoursActions from 'captain-reports/store/system-running-hours/actions';
import { useActions } from 'utils/hooks';
import { getSystemRunningHoursErrors } from './helpers';

const SystemValues = () => {
  const data = useSelector(selectData);
  const errors = useSelector(selectErrors);
  const isLocked = useSelector(isReportLocked);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isEditting = useSelector(selectIsEditting);
  const previousVesselSystems = useSelector(selectPreviouVesselSystems);
  const previousReportTimestamp = useSelector(selectPreviousReportTimestamp);
  const currentTimestamp = data.timestamp;

  const [setAssignmentValue] = useActions([runningHoursActions.setAssignmentValue]);

  const onValueChange = (index, value) => {
    setAssignmentValue({ index, value });
  };

  const previousValues = useMemo(() => {
    let result = {};
    if (previousVesselSystems) {
      previousVesselSystems.forEach(el => {
        result[el.vessel_system_assignment_id] = el.previous_vessel_system_total_running_hours;
      });
    }

    return result;
  }, [previousVesselSystems?.length]);

  return (
    <div className="running-hours-values pt-3 template-field">
      <Row className={isOnBoard ? 'text-primary' : 'text-dark'}>
        <Col xs={5} className="fs-10 fw-bold ">
          SYSTEM
        </Col>
        <Col
          xs={2}
          className="fs-10 fw-bold  d-flex align-items-center justify-content-between left-border cps-12"
        >
          <div>TOTAL RUNNING HOURS</div>
          <Information
            message="Fill in Total machine running hours. DO NOT fill in running hours since last report"
            svgStyle={{ height: 12, width: 12 }}
            target={`total-hours-header-info`}
          />
        </Col>
        <Col xs={2} className="fs-10 fw-bold ps-4">
          / PREVIOUS REPORT
        </Col>
      </Row>
      {data.vessel_systems &&
        data.vessel_systems.map((el, i) => {
          const error = getSystemRunningHoursErrors(
            { ...el, previousRunningHours: previousValues[el.vessel_system_assignment_id.value] },
            previousReportTimestamp,
            currentTimestamp
          );
          const value = _get(el, 'vessel_system_total_running_hours.value', null);

          return (
            <Row key={i.toString()}>
              <Col
                xs={5}
                className={`fs-12  d-flex align-items-center ${
                  isOnBoard ? 'text-primary' : 'text-dark'
                }`}
              >
                {_get(el, 'vessel_system_description.value', '-')}
              </Col>
              <Col
                xs={2}
                className="fs-10 fw-bold text-dark d-flex align-items-center justify-content-between left-border pt-1 cps-12"
              >
                <NumberInput
                  onChange={e => onValueChange(i, strToNumber(e.target.value))}
                  placeholder=""
                  value={value}
                  decimalScale={4}
                  disabled={isLocked && !isEditting}
                  className="mb-0"
                  error={errors?.vessel_systems ? !value : error}
                />
              </Col>
              <Col
                xs={2}
                className={`fs-12 fw-bold  ps-4 d-flex align-items-center ${
                  isOnBoard ? 'text-primary' : 'text-dark'
                }`}
              >
                /
                {previousValues[+el?.vessel_system_assignment_id?.value]
                  ? numberToStr(previousValues[+el.vessel_system_assignment_id.value])
                  : '-'}
              </Col>
            </Row>
          );
        })}
    </div>
  );
};

export default SystemValues;
