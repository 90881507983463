import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { downloadAllFilesOfEntity } from 'common/utils/downloads';

import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import CircledButton from 'common/components/buttons/CircledButton';
import { selectEventId, selectIsCreate } from 'events/store/events/selectors';
import {
  deleteOfficeAttachments,
  getOfficeAttachments
} from 'events/store/event-modules/office-attachments/actions';
import {
  deleteVesselAttachments,
  getVesselAttachments
} from 'events/store/event-modules/vessel-attachments/actions';
import { deleteAttachmentGroup } from 'events/store/event-modules/office-attachments/slice';

const AttachmentPreview = ({ attachment, onEdit, onClose, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isCreate = useSelector(selectIsCreate);

  const hideActions =
    (type === 'office_attachments' && isOnBoard) || (type === 'vessel_attachments' && !isOnBoard);

  const onDelete = async () => {
    try {
      setIsLoading(true);

      if (isCreate) {
        dispatch(deleteAttachmentGroup({ id: attachment.id }));

        setIsLoading(false);
        return;
      }

      const actions =
        type === 'office_attachments'
          ? { delete: deleteOfficeAttachments, get: getOfficeAttachments }
          : { delete: deleteVesselAttachments, get: getVesselAttachments };

      await dispatch(
        actions.delete({ event_id: eventId, attachment_group_id: attachment.id })
      ).unwrap();
      await dispatch(actions.get({ event_id: eventId })).unwrap();

      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      onClose();
    }
  };

  return (
    <div className="event-attachments__preview position-relative pt-2 ps-3 pb-3 pe-3 mb-2">
      <div className="mb-2 fw-medium text-primary">{attachment?.name}</div>

      <DownloadAttachment
        isDisabled={isLoading}
        data={{
          attachments: attachment.attachments,
          attachattachments_count: attachment.attachments.length
        }}
        downloadFiles={() =>
          downloadAllFilesOfEntity({
            id: attachment.id,
            type: `event_${type}`
          })
        }
        hasAttachments={true}
        canAddAttachments={false}
        popupPlacement={'right'}
        size="xxl"
      />

      {!hideActions && (
        <div className="event-attachments__preview-edit-buttons d-flex align-items-center">
          <CircledButton
            type="edit"
            className="ms-1"
            onClick={onEdit}
            svgStyle={{ width: 12, height: 12 }}
            style={{ width: 24, height: 24 }}
          />

          <CircledButton
            className="ms-1"
            type="bin"
            onClick={() => setShowDeleteModal(true)}
            svgStyle={{ width: 12, height: 12 }}
            style={{ width: 24, height: 24 }}
          />
        </div>
      )}

      <DangerousActionModal
        transparent
        action={'Remove'}
        onAccept={onDelete}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText={'REMOVE'}
        header={'Remove Attachment'}
        body={<div>Are you sure you want to remove these attachments ?</div>}
      />
    </div>
  );
};

export default AttachmentPreview;
