import React from 'react';

import VesselFields from '../../_base/inputs/vessel-fields';
import { useSelector } from 'react-redux';
import { selectJobField } from '../../_base/store/selectors';

function VesselFieldsWrapper() {
  const isOnboardJob = useSelector(state => selectJobField(state, 'is_for_vessel'));

  if (!isOnboardJob) return null;

  return <VesselFields />;
}

export default VesselFieldsWrapper;
