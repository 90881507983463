import PropTypes from 'prop-types';

export const File = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filename: PropTypes.string,
  url: PropTypes.string,
  url_inline: PropTypes.string,
  encoded_url: PropTypes.string,
  mime_type: PropTypes.string,
  extension: PropTypes.string,
  path: PropTypes.string,
  size: PropTypes.number,
  is_synced: PropTypes.bool,
  onboard_sync_progress: PropTypes.number
});
