import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import { components } from 'react-select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import BasicModal from 'common/components/modals/BasicModal';
import Select from 'common/components/form/inputs/Select';
import Status from 'common/components/general/Status';
import { selectListOptionsFromStore } from 'store/lists/selectors';

import { selectEventId, selectEventVessel } from 'events/store/events/selectors';
import { changeEventValue } from 'events/store/events/actions';

const ForVesselModal = ({
  isOpen,
  setIsOpen,
  isCreate,
  memoizedDebounce,
  selectedStatus,
  setSelectedStatus
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const eventStatuses = useSelector(state => selectListOptionsFromStore(state, 'event-statuses'));
  const eventId = useSelector(selectEventId);
  const eventVessel = useSelector(selectEventVessel);

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (isCreate) {
        const params = {
          vessel: eventVessel,
          status: selectedStatus,
          is_for_vessel: true
        };

        await dispatch(changeEventValue(params));
      } else {
        const params = {
          id: eventId || undefined,
          vessel_id: eventVessel?.id,
          status_id: selectedStatus?.id,
          is_for_vessel: true
        };

        await dispatch(changeEventValue(params));
        memoizedDebounce(params);
      }

      setIsOpen(false);
      setIsSubmitting(false);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const filteredStatuses = useMemo(() => {
    if (!eventStatuses?.length) return [];

    return eventStatuses.filter(s => (eventVessel?.id ? s.is_for_vessel : s.is_for_office));
  }, [eventVessel?.id, eventStatuses]);

  useEffect(() => {
    if (isOpen) {
      if (filteredStatuses.length) {
        const status = filteredStatuses?.filter(s => s.is_for_office)[0];

        setSelectedStatus(status);
      }
    }
  }, [eventVessel, filteredStatuses, isOpen, setSelectedStatus]);

  return (
    <BasicModal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      header="Sync Onboard"
      body={
        <Row>
          <Col xs={12} className="text-warning fs-12 fw-medium mb-3">
            *This action is permanent and cannot be undone. Please be cautious when assigning this
            event to a vessel, as it cannot be reversed.
          </Col>
          <Col xs={12}>
            <Select
              className="mb-0"
              label="Vessel"
              placeholder="Select vessel"
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              value={eventVessel}
              isAsync
              loadOptions={search => getAsyncOptions(search, 'vessels')}
              defaultOptions={() => getInitialAsyncValues('vessels')}
              onChange={value => dispatch(changeEventValue({ vessel: value }))}
              noOptionsMessage={({ inputValue }) =>
                !inputValue.length ? `Search for vessels` : `No vessels found`
              }
            />
          </Col>
          <Col xs={12}>
            <Select
              className="mb-0 mt-2"
              components={{
                Option,
                SingleValue
              }}
              value={selectedStatus}
              isClearable={true}
              options={filteredStatuses}
              getOptionValue={option => option}
              getOptionLabel={option => option.name}
              label="STATUS"
              placeholder="Select status"
              onChange={value => setSelectedStatus(value)}
            />
          </Col>
        </Row>
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button color="cancel" onClick={() => setIsOpen(false)}>
            CANCEL
          </Button>
          <Button
            color="primary"
            disabled={isSubmitting || !eventVessel?.id || !selectedStatus}
            onClick={onSubmit}
          >
            SEND TO VESSEL
          </Button>
        </div>
      }
    />
  );
};

export default ForVesselModal;

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <Status value={props.data} className="my-0" />
  </components.SingleValue>
);

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div>
        <Status value={props.data} />
      </div>
    </components.Option>
  );
};
