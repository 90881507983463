import React from 'react';
import SvgRender from 'common/components/general/SvgRender';

import add from 'common/assets/svg/actions/add.svg';
import PropTypes from 'prop-types';

const CreateOption = ({ label }) => {
  return (
    <div className="d-flex align-items-center">
      <div className={`create-option-round-btn d-flex align-items-center justify-content-center`}>
        <SvgRender src={add} style={{ width: 6, height: 6 }} />
      </div>
      <div className="text-primary fw-bold fs-10">{label}</div>
    </div>
  );
};

CreateOption.propTypes = {
  label: PropTypes.string
};

export default CreateOption;
