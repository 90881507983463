import SvgRender from 'common/components/general/SvgRender';

import calendar from 'common/assets/svg/common/calendar.svg';

const ToggleButton = ({ id, onClick, disabled, className = '' }) => {
  return (
    <button
      type="button"
      className={`date-input__toggle-btn ${className}`}
      id={id}
      onClick={onClick}
      data-cy="date-input-toggle-button"
      disabled={disabled}
    >
      <SvgRender
        src={calendar}
        style={{ width: '100%', height: '80%' }}
        className="pointer-events-none"
      />
    </button>
  );
};

export default ToggleButton;
