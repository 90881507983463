import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CollapseColoredBox from 'common/components/collapse/CollapseColoredBox';
import { getInitialEvaluationValues } from './helpers';
import { useForm } from 'utils/hooks';
import config from './config';
import Header from './Header';
import Body from './body';
import { selectEvaluationCriteria } from 'events/store/event-modules/audits/selectors';

const Evaluations = ({ audit, className = '' }) => {
  const { formState, loadValues, collectValues } = useForm(config);

  const evaluationCriteria = useSelector(selectEvaluationCriteria);

  const hasSubmittedEvaluation = !!audit?.evaluation;

  useEffect(() => {
    const values = getInitialEvaluationValues(audit, evaluationCriteria);

    loadValues(values);
  }, [evaluationCriteria, loadValues, audit]);

  return (
    <CollapseColoredBox
      bodyInnerClassName="px-0 py-0"
      background={hasSubmittedEvaluation ? 'white' : 'violet'}
      expandButton={false}
      hideExpandButton={true}
      className={`audits-event-module-evaluations-${
        hasSubmittedEvaluation ? 'preview' : 'edit'
      } border-radius-7 ${className} mt-1`}
      headerClassName="cpt-12 pb-2"
      innerExpandButton
      header={<Header audit={audit} />}
    >
      <Body
        formState={formState}
        eventAuditId={audit?.id}
        collectValues={collectValues}
        hasSubmittedEvaluation={hasSubmittedEvaluation}
      />
    </CollapseColoredBox>
  );
};

Evaluations.propTypes = {
  audit: PropTypes.shape({
    event_audit_id: PropTypes.number.isRequired
  }),
  className: PropTypes.string
};

export default Evaluations;
