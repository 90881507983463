import SvgRender from 'common/components/general/SvgRender';
import AuthCheck from 'components/permissions/AuthCheck';
import PropTypes from 'prop-types';

const TabsModule = ({ tabs, onTabClick, className = '' }) => {
  return (
    <div className={`module-tabs ${className}`}>
      {tabs.map(tab =>
        tab.visible === false ? null : (
          <AuthCheck permissions={tab.permissions} key={tab.label}>
            <div
              onClick={() => onTabClick(tab.label)}
              className={`module-tabs__button d-flex align-items-center justify-content-center${
                tab.active ? ' module-tabs__button--disabled' : ''
              } ${tab.disabled ? 'disabled' : ''} ${tab.error ? 'border border-red' : ''}`}
            >
              <SvgRender src={tab.icon} style={{ width: 12, height: 12 }} />
              <span className="ms-1">{tab.name}</span>
            </div>
          </AuthCheck>
        )
      )}
    </div>
  );
};

TabsModule.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      error: PropTypes.string,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      active: PropTypes.bool,
      visible: PropTypes.bool,
      permissions: PropTypes.array
    })
  ),
  onTabClick: PropTypes.func
};

export default TabsModule;
