import React from 'react';
import EntityLabel from 'common/components/labels/EntityLabel';
import { components } from 'react-select';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

export const CrewDepartment = ({ name }) => {
  return (
    <EntityLabel type="crew-department">
      <TextWithTooltip className="h-auto">{name || '-'}</TextWithTooltip>
    </EntityLabel>
  );
};

export default CrewDepartment;

export const CrewSingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <CrewDepartment name={props.data.name} />
  </components.SingleValue>
);

export const CrewOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <CrewDepartment name={props.data.name} />
    </components.Option>
  );
};
