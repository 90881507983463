import AddRowButton from './components/AddRowButton';
import Row from './components/Row';

const InputTable = ({
  previewMode,
  title,
  columns,
  addMoreButtonLabel,
  rows,
  addRow,
  removeRow,
  hideActions = false,
  className = ''
}) => {
  const totalActionColumns = (columns || []).filter(c => c.isAction).length;

  return (
    <div className={`input-table ${previewMode ? 'preview' : ''} ${className}`}>
      <strong className="input-table--title d-flex bg-primary text-white ps-1 fs-12">
        {title}
      </strong>

      <div className="d-flex">
        {columns?.map((col, i) => {
          const isLast = i + 1 === columns.length - (hideActions ? totalActionColumns : 0);
          if (col.isAction && hideActions) return null;
          return (
            <div
              key={i}
              id={`table-header-${i}`}
              className={`cpx-12 cpb-12 pt-2 ${
                isLast ? 'border-bottom border-white' : 'border-end border-bottom border-white'
              }`}
              style={{ width: col.width }}
            >
              {col.headerComponent()}
            </div>
          );
        })}
      </div>

      {rows.map((row, i) => (
        <Row
          key={i}
          row={row}
          index={i}
          columns={columns}
          previewMode={previewMode}
          removeRow={() => removeRow(i)}
          hideActions={hideActions}
          totalActionColumns={totalActionColumns}
          removeLastRowBorder={rows.length - 1 === i && hideActions}
          rows={rows}
        />
      ))}

      {!hideActions ? <AddRowButton addRow={addRow} label={addMoreButtonLabel} /> : null}
    </div>
  );
};

export default InputTable;
