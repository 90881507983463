import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ModuleWrapper from '../components/ModuleWrapper';
import Body from './Body';
import AddAuditButton from './AddAuditButton';
import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';

import {
  getEventAudits,
  getEventAuditsEvaluationCriteria
} from 'events/store/event-modules/audits/actions';
import { fetchListOptions } from 'store/lists/actions';
import { selectAreAuditActionsLocked } from 'events/store/event-modules/audits/selectors';

const Audits = ({ isCreate }) => {
  const [isAdding, setIsAdding] = useState(false);

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const areAuditActionsLocked = useSelector(selectAreAuditActionsLocked);
  const isTemplate = useSelector(selectIsTemplate);

  const initAuditModule = useCallback(async () => {
    try {
      await dispatch(getEventAudits({ event_id: eventId })).unwrap();

      if (!areAuditActionsLocked) {
        await dispatch(getEventAuditsEvaluationCriteria()).unwrap();
      }
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, eventId, areAuditActionsLocked]);

  useEffect(() => {
    if (!isCreate && !isTemplate) {
      initAuditModule();
    }
  }, [isTemplate, isCreate, initAuditModule]);

  useEffect(() => {
    dispatch(fetchListOptions('scales'));
  }, [dispatch]);

  return (
    <ModuleWrapper
      className="audits-event-module"
      type="audits"
      action={!areAuditActionsLocked ? <AddAuditButton onClick={() => setIsAdding(true)} /> : null}
    >
      <Body isAdding={isAdding} setIsAdding={setIsAdding} />
    </ModuleWrapper>
  );
};

Audits.propTypes = {
  isCreate: PropTypes.bool
};

export default Audits;
