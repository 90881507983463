import React from 'react';

import _get from 'lodash/get';
import moment from 'moment';

import SvgRender from 'common/components/general/SvgRender';
import clock from 'common/assets/svg/common/clock.svg';

const API_TIME_FORMAT = 'HH:mm:ss';
const TIME_FORMAT = 'HH:mm';

const CrewMemberScheduleShift = ({ shift, watchkeeping }) => {
  const formatTime = time => moment(time, API_TIME_FORMAT).format(TIME_FORMAT);

  return (
    <div className="working-schedule__shift">
      {shift ? (
        <>
          <SvgRender
            className={`${watchkeeping ? 'text-moody-blue' : 'text-violet'} me-1`}
            src={clock}
            style={{ height: 16, width: 16 }}
          />
          <span>
            {formatTime(_get(shift, 'from'))} - {formatTime(_get(shift, 'to'))}
          </span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};

export default CrewMemberScheduleShift;
