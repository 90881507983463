import React, { useState, useEffect } from 'react';
import _sumBy from 'lodash/sumBy';

import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { strToNumber } from 'common/utils/numbers';
import { useFormState } from 'utils/hooks';
import { Row, Col } from 'reactstrap';
import Value from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts/Value';
import PropTypes from 'prop-types';

const DynamicField = ({ formState, view }) => {
  const { fields, subStates, addSubform, removeSubform } = useFormState(formState);

  const [fieldSummary, setFieldSummary] = useState(0);
  const expenseValues = subStates('values');

  const addValueSubmform = () => addSubform('values');

  useEffect(() => {
    const totalAmount = _sumBy(expenseValues, field =>
      field.state.value.value ? strToNumber(field.state.value.value) : 0
    );
    setFieldSummary(totalAmount);
  }, [expenseValues]);

  return (
    <Row className="d-flex justify-content-between mb-1">
      <Col xs={view ? 5 : 4} className="pt-1">
        <TextWithTooltip className={`form-label mb-0 ${view ? 'fw-normal' : ''}`}>
          {fields.title.value || ''}
        </TextWithTooltip>
      </Col>
      <Col xs={view ? 7 : 8}>
        {expenseValues?.map((valueFormState, index) => (
          <Value
            formState={valueFormState}
            addAnotherValue={addValueSubmform}
            addValueSubForm={() => addSubform('values')}
            removeValueSubForm={() => removeSubform('values', index)}
            isLastIndex={index === expenseValues.length - 1}
            key={index}
            index={index}
            dynamicFieldSummary={fieldSummary}
            view={view}
            invoices={view ? fields.invoices.value : []}
          />
        ))}
      </Col>
    </Row>
  );
};

DynamicField.propTypes = {
  formState: PropTypes.object,
  view: PropTypes.bool
};

export default DynamicField;
