import store from 'store';

export const parseTextHtml = (text, mentions) => {
  const accountId = import.meta.env.MODE === `test` ? 1 : store.getState().account.id;
  let newText = text;
  mentions?.forEach(mention => {
    let strToReplace = `!#_${mention.type}---${mention.id}^^^!#$`;
    let strForReplace = `<a ${accountId === mention.id ? "class='self-mention-tag'" : ''} href='/${
      mention.type === 'party' ? 'persons' : 'vessels'
    }/${mention.id}' rel='noreferrer noopener' target="_blank" >@${mention.name}</a>`;

    newText = newText.replace(strToReplace, strForReplace);
  });

  return newText;
};

export const parseText = (text, mentions) => {
  let newText = text;
  mentions?.forEach(mention => {
    let strToReplace = `!#_${mention.type}---${mention.id}^^^!#$`;
    let strForReplace = `!#_${mention.type}---${mention.id}^^^@${mention.name}!#$`;

    newText = newText.replace(strToReplace, strForReplace);
  });

  return newText;
};

export const transformDataForSubmit = (message, mentions, returnName) => {
  let parsedText = message;

  mentions?.forEach(el => {
    parsedText = parsedText.replace(`^^^${el.display}`, '^^^');
  });

  const params = {
    text: parsedText,
    mentions: mentions?.map(el => {
      let idAndType = el.id.split('---');
      if (returnName) {
        return {
          id: +idAndType[1],
          type: idAndType[0],
          name: el.display.substring(1)
        };
      } else {
        return {
          id: +idAndType[1],
          type: idAndType[0]
        };
      }
    })
  };

  return params;
};
