import { FC, CSSProperties } from 'react';
import { Button } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

type ExpandButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
  label?: string;
  style?: CSSProperties;
  position?: 'relative' | 'absolute';
  className?: string;
  disabled?: boolean;
};

const ExpandButton: FC<ExpandButtonProps> = ({
  isExpanded,
  onClick,
  label,
  style = {},
  position = 'relative',
  className = 'fs-12 fw-medium text-primary bg-lighter-blue px-1 cpt-2 cpb-2',
  disabled
}) => {
  return (
    <Button
      style={style}
      color="link"
      className={`d-flex align-items-center rounded-xl position-${position} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label ? <span className="cpe-4">{label}</span> : null}
      <SvgRender
        src={arrow}
        style={{
          width: 9,
          height: 9,
          transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'
        }}
      />
    </Button>
  );
};

export default ExpandButton;
