// import htmldiff from 'manuals/_htmldiff';
import HtmlDiff from 'htmldiff-js';

// import { v4 as uuid } from 'uuid';

export const getHTMLDiff = (oldHTML, newHTML) => {
  return HtmlDiff.execute(oldHTML, newHTML);

  // This functions includes a very weird fix for getting images to work in the diff.

  // The problem is that the images are not being rendered in the diff.
  // That's because the htmldiff functionality cannot catch the case where
  // the tag does not have any content inside it. (e.g. <img /> doesn't work but <img>CONTENT HERE</img> does)
  // The fix is to add some random text inside the images and then use the htmldiff library to get the diff.

  // This is a hack, but it works.

  // The random text is added to the images in the oldHTML and newHTML.
  // Then the htmldiff library is used to get the diff between the two
  // and based on the diff of the random text we then wrap the images around it.

  // Here's the random string that is added to the images.

  // const referenceString = uuid();

  // // Regex to get the img tags.
  // const regexImageTags = /<img src="[^>]*">/g;

  // const patchedOldHTML = oldHTML.replaceAll(
  //   regexImageTags,
  //   // Add the random string to the images.
  //   match => `${match}${referenceString}</img>`
  // );
  // const patchedNewHTML = newHTML.replaceAll(
  //   regexImageTags,
  //   // Add the random string to the images.
  //   match => `${match}${referenceString}</img>`
  // );

  // const diff = htmldiff(patchedOldHTML, patchedNewHTML);

  // // Regex to get all of the new/old images based on the reference_str
  // const regexImageHackyFixForNewTags = new RegExp(
  //   `<img[^>]*><new-text>${referenceString}</new-text></img>`,
  //   'g'
  // );
  // const regexImageHackyFixForOldTags = new RegExp(
  //   `<img[^>]*><removed-text>${referenceString}</removed-text></img>`,
  //   'g'
  // );

  // return (
  //   diff
  //     // Remove the random text and wrap the img tag in the corresponding diff tag.
  //     .replaceAll(
  //       regexImageHackyFixForNewTags,
  //       match =>
  //         `<new-text class="dont-show">${match.replace(
  //           `<new-text>${referenceString}</new-text></img>`,
  //           ''
  //         )}</new-text>`
  //     )
  //     .replaceAll(
  //       regexImageHackyFixForOldTags,
  //       match =>
  //         `<removed-text class="dont-show">${match.replace(
  //           `<removed-text>${referenceString}</removed-text></img>`,
  //           ''
  //         )}</removed-text>`
  //     )
  //     // Remove any leftovers of the random text (in case there was no difference in the img).
  //     .replaceAll(referenceString, '')
  // );
};
