import React, { useState } from 'react';
import useRouter from 'use-react-router';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import SvgRender from 'common/components/general/SvgRender';
import GoBackArrow from 'common/components/general/GoBackArrow';
import { captainReportIcon } from 'captain-reports/utils/helpers';
import {
  selectData,
  isReportLocked,
  selectNavigation
} from 'captain-reports/store/system-running-hours/selectors';
import NavigationArrows from 'common/components/general/NavigationArrows';
import Group from 'captain-reports/components/Group';
import { constructUrlPath } from 'captain-reports/utils/helpers';

import Actions from './Actions';
import PageLoader from 'common/components/general/PageLoader';

import lock from 'common/assets/svg/common/lock.svg';
import paths from 'routing/routes/_paths';
import ReportsChatbox from 'captain-reports/components/ReportsChatbox';

const Header = ({ templateType }) => {
  const isLocked = useSelector(isReportLocked);
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector(selectData);
  const navigation = useSelector(selectNavigation);
  const template = data?.template || {};
  const { history } = useRouter();

  const group = data.report_group;

  const isPmsRunningHours = history.location.pathname.includes(paths.pms_running_hours);

  const navigateToReport = (reportId, reportType, isPmsRunningHours) => {
    const urlPath = constructUrlPath(reportType, isPmsRunningHours);

    history.replace(`${urlPath}/${reportId}`);
  };

  return (
    <Row className="mb-4 align-items-center">
      <Col xs="auto" className="d-flex align-items-center fs-16">
        <GoBackArrow
          to={!isPmsRunningHours ? paths.captain_reports : paths.pms_running_hours}
          className="me-1"
        />

        {isLocked ? (
          <SvgRender
            src={lock}
            className="me-1 text-gray-common"
            style={{ width: 18, height: 20 }}
          />
        ) : null}

        <SvgRender
          src={captainReportIcon[templateType]}
          className="me-1"
          style={{ width: 18, height: 20 }}
        />
        <span className="text-capitalize">{template.name}</span>

        <NavigationArrows
          onNextClick={() =>
            navigateToReport(
              navigation.next_report?.id,
              navigation.next_report?.type,
              isPmsRunningHours
            )
          }
          onPreviousClick={() =>
            navigateToReport(
              navigation.previous_report?.id,
              navigation.previous_report?.type,
              isPmsRunningHours
            )
          }
          disabledNext={!navigation.next_report?.id}
          disabledPrevious={!navigation.previous_report?.id}
          className="my-auto ms-3"
        />

        <div className="header-divider mx-3" />
        <Group group={group} />
      </Col>
      <Col className="text-end">
        <Actions isLoading={isLoading} setIsLoading={setIsLoading} />
        <PageLoader isLoading={isLoading} setIsLoading={setIsLoading} />
      </Col>

      <Col xs="auto">
        <ReportsChatbox id={data.id} className="ms-2" size="lg" />
      </Col>
    </Row>
  );
};

export default Header;
