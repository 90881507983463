import React from 'react';
import { Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectIsFolderExpanded,
  selectFolderSubFolders
} from 'common/components/filesystem/store/selectors';

import Folder from 'common/components/filesystem/components/tree/folder';

const SubFolders = ({ id }) => {
  const isOpen = useSelector(state => selectIsFolderExpanded(state, id));
  const subFolders = useSelector(state => selectFolderSubFolders(state, id));

  return subFolders?.length ? (
    <Collapse isOpen={isOpen}>
      <div className={`chapters-tree__view-submenu`}>
        {subFolders.map(sub => (
          <Folder key={sub.toString()} id={sub} />
        ))}
      </div>
    </Collapse>
  ) : null;
};

export default SubFolders;
