import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import PageSaving from 'common/components/general/PageSaving';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import { useForm, useFormState } from 'utils/hooks';

import ShadowBox from 'common/components/general/ShadowBox';
import DateInput from 'common/components/form/inputs/date';
import Textarea from 'common/components/form/inputs/Textarea';
import SvgRender from 'common/components/general/SvgRender';

import comments from 'common/assets/svg/common/comments.svg';

import config from './config';
import { editParticipant } from 'events/store/event-modules/participants/actions';
import { useDispatch } from 'react-redux';

const AttendanceForm = ({ participant, disabled }) => {
  const [isAutoSaving, setIsAutoSaving] = useState(false);

  const { formState, loadValues, hasErrors } = useForm(config);
  const { fields, selectField } = useFormState(formState);
  const dispatch = useDispatch();

  const isDateNotSuitable = (firstDate, secondDate) => {
    if (!firstDate || !secondDate) return false;

    return moment(firstDate).isAfter(secondDate);
  };

  const onSave = useCallback(
    async params => {
      try {
        if (hasErrors) return;
        const { person_rank, sign_on_port, sign_off_port, ...rest } = params;
        const hasSignOnPortChanged = Object.hasOwn(params, 'sign_on_port');
        const hasSignOffPortChanged = Object.hasOwn(params, 'sign_off_port');

        setIsAutoSaving(true);

        await dispatch(
          editParticipant({
            id: participant.event_id,
            participant_id: participant.id,
            party_id: participant.party_id,
            person_rank_id: person_rank?.id,
            sign_on_port_id: hasSignOnPortChanged ? sign_on_port?.id ?? null : undefined,
            sign_off_port_id: hasSignOffPortChanged ? sign_off_port?.id ?? null : undefined,
            ...rest
          })
        ).unwrap();

        setIsAutoSaving(false);
      } catch (e) {
        console.error(e);
        setIsAutoSaving(false);
      }
    },
    [dispatch, hasErrors, participant.event_id, participant.id, participant.party_id]
  );

  const debouncedAutoSave = useMemo(() => _debounce(onSave, 400), [onSave]);

  const handleFieldChange = (key, value, autoSave = true) => {
    selectField(key)(value);

    if (autoSave) {
      debouncedAutoSave({ [key]: value });
    }
  };

  useEffect(() => {
    const { sign_off_date, sign_on_date, person_rank, notes, sign_on_port, sign_off_port } =
      participant;

    loadValues({ sign_off_date, sign_on_date, person_rank, notes, sign_on_port, sign_off_port });
  }, [participant, loadValues]);

  return (
    <Row className="attendance-form">
      <PageSaving isSaving={isAutoSaving} />

      <Col xs={5} className="attendance-form__container">
        <ShadowBox className="d-flex flex-column px-2 py-1 h-100p w-100p" color="light-1" flat>
          <Label className="form-label fs-10 fw-normal cmb-2">Rank</Label>
          <AsyncSelector
            onChange={e => handleFieldChange('person_rank', e)}
            getOptionLabel={option => option.name}
            className="attendance-form__selector mb-2"
            placeholder="Select rank"
            type="person-ranks"
            isClearable={false}
            styled={true}
            disabled={disabled}
            {...fields.person_rank}
          />
          <Row>
            <Col>
              <Label className="form-label fs-10 fw-normal cmb-2">SIGNED ON BOARD</Label>
              <DateInput
                value={fields.sign_on_date.value}
                error={
                  isDateNotSuitable(fields.sign_on_date.value, fields.sign_off_date.value)
                    ? 'Signed on date should be before signed off date'
                    : ''
                }
                onChange={e =>
                  handleFieldChange(
                    'sign_on_date',
                    moment(e).format('YYYY-MM-DD HH:mm'),
                    !isDateNotSuitable(e, fields.sign_off_date.value)
                  )
                }
                className={'mb-0'}
                hasTime={true}
                showTime={true}
                invisible
                disabled={disabled}
              />
            </Col>
            <Col>
              <Label className="form-label fs-10 fw-normal cmb-2">SIGN ON PORT</Label>
              <Select
                isAsync
                invisible
                placeholder="Select port"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                loadOptions={search => getAsyncOptions(search, 'ports')}
                defaultOptions={() => getInitialAsyncValues('ports')}
                onChange={e => handleFieldChange('sign_on_port', e)}
                isClearable
                className="fs-12"
                disabled={disabled}
                {...fields.sign_on_port}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="form-label cmt-20 fs-10 fw-normal cmb-2">SIGNED OFF BOARD</Label>
              <DateInput
                value={fields.sign_off_date.value}
                error={
                  isDateNotSuitable(fields.sign_on_date.value, fields.sign_off_date.value)
                    ? 'Signed off date should be after signed on date'
                    : ''
                }
                onChange={e => {
                  handleFieldChange(
                    'sign_off_date',
                    moment(e).format('YYYY-MM-DD HH:mm'),
                    !isDateNotSuitable(fields.sign_on_date.value, e)
                  );
                }}
                className={'mb-0'}
                hasTime={true}
                showTime={true}
                invisible
                disabled={disabled}
              />
            </Col>
            <Col>
              <Label className="form-label cmt-20 fs-10 fw-normal cmb-2">SIGN OFF PORT</Label>
              <Select
                isAsync
                invisible
                placeholder="Select port"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                loadOptions={search => getAsyncOptions(search, 'ports')}
                defaultOptions={() => getInitialAsyncValues('ports')}
                onChange={e => handleFieldChange('sign_off_port', e)}
                isClearable
                className="fs-12"
                disabled={disabled}
                {...fields.sign_off_port}
              />
            </Col>
          </Row>
        </ShadowBox>
      </Col>
      <Col className="attendance-form__container">
        <ShadowBox className="px-2 py-1 w-100p h-100p" color="light-1" flat>
          <Textarea
            placeholder="Add some notes"
            rows={7}
            label={
              <div className="d-flex fs-12 align-items-center text-moody-blue text-capitalize fw-medium">
                <SvgRender className="me-1" src={comments} style={{ width: 16, height: 16 }} />
                Notes
              </div>
            }
            name="notes"
            className="attendance-form__notes"
            invisible
            onChange={e => handleFieldChange('notes', e.target.value)}
            disabled={disabled}
            {...fields.notes}
          />
        </ShadowBox>
      </Col>
    </Row>
  );
};

export default AttendanceForm;
