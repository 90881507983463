import Generators from 'common/components/vessels/profile/tabs/info/components/generators/Generators';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';

const generators = [
  {
    fields: [
      {
        label: 'NO. OF MAIN GENERATORS',
        key: 'generators_count',

        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        key: 'generators',
        type: 'multiple',
        component: Generators,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            key: 'generator_number',
            type: 'number',
            render: () => null,
            inputProps: {
              placeholder: 'Select'
            }
          },
          {
            label: 'MAKER',
            key: 'maker.company_name',
            select_key: 'maker',
            type: 'select',
            inputProps: {
              placeholder: 'Add Name',
              isAsync: true,
              getOptionValue: option => option.id,
              getOptionLabel: option => getPartyDisplayName(option),
              defaultOptions: () =>
                getInitialAsyncValues('parties', null, null, { type: 'company' }),
              loadOptions: search => getAsyncOptions(search, 'parties', { type: 'company' }),
              isClearable: true
              // isCreatable: true
            }
          },
          {
            label: 'MODEL',
            key: 'model'
          },
          {
            label: 'NO. OF CYLINDERS',
            key: 'diesel_engine_cylinders_no',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'DIESEL ENGINE CAPACITY (kW @ RPM)',
            key: 'diesel_engine_cylinders_kw',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            key: 'diesel_engine_capacity_at_rpm',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            },
            render: () => null
          },
          {
            label: 'ALTERNATOR VOLTAGE RATING (V)',
            key: 'alternator_voltage_rating',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'ALTERNATOR RATING (kVA)',
            key: 'alternator_rating',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'ALTERNATOR TOTAL NOMIMAL POWER (kW)',
            key: 'alternator_nominal_power',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'ALTERNATOR CAPACITY (kW @ RPM)',
            key: 'alternator_capacity_kw',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            key: 'alternator_capacity_at_rpm',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            },
            render: () => null
          },
          {
            label: 'ALTERNATOR FREQUENCY (Hz)',
            key: 'alternator_frequency',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          }
        ]
      }
    ]
  }
];

export default generators;
