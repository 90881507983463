import React from 'react';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import RankPromotionLabel from 'common/components/labels/RankPromotionLabel';

export const CrewRanks = ({ currentRankName, promotionRankName }) => {
  return (
    <div className="d-flex align-items-center">
      <TextWithTooltip className="me-1 d-flex align-items-center">
        {currentRankName}
      </TextWithTooltip>
      {promotionRankName ? (
        <RankPromotionLabel
          className="px-1 cpy-4 text-nowrap"
          text={promotionRankName}
          textClassName="text-primary fs-10 fw-500"
          svgClassName="cme-6"
          svgStyle={{ width: 12, height: 12 }}
        />
      ) : null}
    </div>
  );
};
