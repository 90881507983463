import { saveAs } from 'file-saver';
import { getResponseContentTypeExtension } from '@/ts-common/utils/files.ts';
import { downloadAllFilesOfEntity as downloadAllFilesOfEntityFunc } from '@/api/files/api.ts';

export const getResponseFilename = response => {
  const { headers } = response;
  let filename = '';

  if (headers['content-disposition']) {
    const pattern = /filename=(?:"([^"]+)"|([^\s;]+))/;
    const patternStar = /filename\*=[^']+'[^']*'([^;]+)/;

    const match = headers['content-disposition'].match(pattern);
    const matchStar = headers['content-disposition'].match(patternStar);

    filename = match ? match?.[1] || match?.[2] : matchStar?.[1];
  } else if (headers?.filename) {
    filename = headers?.filename;
  }

  return filename;
};

export const handleFileDownload = async (
  file,
  download,
  returnBlob = false,
  downloadParams = {}
) => {
  const { requestParams, parsed, customHeaders } = downloadParams;

  try {
    const response = await download(
      file?.url ? file.url : `/files/${file.id}`,
      requestParams,
      true,
      parsed,
      customHeaders
    );
    const type = response.headers['content-type'];
    const name = getResponseFilename(response) || file?.filename;

    if (window.navigator && window.navigator.msSaveOrOpenBlob && !returnBlob) {
      window.navigator.msSaveOrOpenBlob(response.data, name);
    } else {
      const blob = new Blob([response.data], { type });

      if (returnBlob) {
        return blob;
      } else {
        saveAs(blob, name);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveDownloadedFile = (response, filenameFallback) => {
  let contentType = response.headers['content-type'];
  if (contentType === 'application/x-zip') contentType = 'application/zip'; // bug with mime-types module

  const blob = new Blob([response.data], { type: contentType });
  const name =
    getResponseFilename(response) ||
    `${filenameFallback}.${getResponseContentTypeExtension(contentType)}`;

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    saveAs(blob, name);
  }
};

export const handleBlobDownload = async (file, blob) => {
  try {
    saveAs(blob, file.filename);
  } catch (error) {
    console.error(error);
  }
};

export const handleBlobError = async error => {
  const text = await error?.data.text();
  const data = JSON.parse(text);

  return { message: data?.message || 'An error has occured' };
};

// Deprecated - import and use `downloadAllFilesOfEntity` from src/api/files/api.ts
export const downloadAllFilesOfEntity = async ({ id, type, file_type }) =>
  downloadAllFilesOfEntityFunc({ id, type, file_type });
