import { getEventVetting, getEventVettingObservations } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

const INITIAL_STATE = {
  inspection: null,
  observations: []
};

const slice = createSlice({
  name: 'eventVetting',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventVetting.fulfilled, (state, { payload }) => {
        state.inspection = payload;
        return state;
      })

      .addCase(getEventVettingObservations.fulfilled, (state, { payload }) => {
        state.observations = payload;
        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addDefaultCase(state => {
        return state;
      });
  }
});

export default slice.reducer;
