export const shouldShowCloseConfirmation = (
  isDrawerClosing: boolean,
  isOnboard: boolean,
  statusProgress: string,
  statusEditSide: string,
  hasPreviousStatus: boolean,
  hasFormSubmission: boolean
) => {
  if (!isDrawerClosing || isOnboard || hasPreviousStatus) return false;

  if (
    statusProgress &&
    statusProgress !== 'reviewed' &&
    statusEditSide === 'office' &&
    hasFormSubmission
  )
    return true;

  return false;
};
