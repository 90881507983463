import PropTypes from 'prop-types';
import Container from './Container';
import UploadedButton from './UploadButton';
import UploadedFiles from './UploadedFiles';

import { useState } from 'react';
import { File } from 'common/entities/files/FileTypes';
import { Size, Files } from './_types';
import { upload } from 'utils/api';

import _isArray from 'lodash/isArray';
import { useEffect } from 'react';

const UploadFiles = ({
  size = 'sm',
  color = 'primary',
  label,
  files,
  error,
  viewOnly = false,
  canUpload = true,
  canRemove = true,
  singleUpload = false,
  isPublic = false,
  initGalleryFiles = true,
  group,
  onChange,
  imageSizeLabel,
  accept,
  uploadText,
  className = ''
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const uploadedFiles = singleUpload && !!files && !_isArray(files) ? [files] : files;

  const onUploadFiles = async (acceptedFiles, restFiles) => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      setUploadError(null);

      let data = new FormData();
      data.append('group', group);

      if (isPublic) {
        data.append('public', true);
      }
      acceptedFiles.forEach(file => {
        data.append('files[]', file, file.name);
      });

      upload('/files', data)
        .then(res => {
          onChange([...(restFiles || []), ...res.data]);
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error);
          setUploadError('An error occurred');
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      setUploadError('An error occurred');
    }
  };

  const onRemoveFile = (file, restFiles) => {
    onChange(restFiles.filter(f => f.id !== file.id));
  };

  useEffect(() => {
    if (error) setUploadError(error);
    else if (!error && uploadError) setUploadError(null);
  }, [error]);

  return (
    <Container label={label}>
      <div className={`d-flex align-items-start flex-wrap ${className}`}>
        <UploadedFiles
          size={size}
          files={uploadedFiles}
          viewOnly={viewOnly || isLoading}
          canRemove={canRemove}
          singleUpload={singleUpload}
          imageSizeLabel={imageSizeLabel}
          onRemoveFile={file => onRemoveFile(file, uploadedFiles)}
          initGalleryFiles={initGalleryFiles}
        />
        {viewOnly || !canUpload || (singleUpload && uploadedFiles?.length === 1) ? null : (
          <UploadedButton
            size={size}
            onUpload={newFiles => onUploadFiles(newFiles, uploadedFiles)}
            isLoading={isLoading}
            uploadError={uploadError}
            uploadText={uploadText}
            singleUpload={singleUpload}
            color={color}
            accept={accept}
          />
        )}
      </div>
    </Container>
  );
};

export default UploadFiles;

UploadFiles.propTypes = {
  group: PropTypes.string.isRequired, // The s3 storage group to store the uploaded files (provided by API)
  size: Size,
  files: PropTypes.oneOfType([Files, File]).isRequired, // When singleUpload = true, the `files` could be File object instead of an array
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  viewOnly: PropTypes.bool, // Prevent removing or uploading files
  canRemove: PropTypes.bool, // Prevent removing files
  canUpload: PropTypes.bool, // Prevent uploading files
  singleUpload: PropTypes.bool, // Multiple or single file upload
  isPublic: PropTypes.bool, // Set as true if the uploaded files have to be publicly accessible
  error: PropTypes.string, // The error message to display
  onChange: PropTypes.func.isRequired,
  imageSizeLabel: PropTypes.string, // Use it to select the right image size, as used in 'imageUrlSelector' from src/common/utils/image-size.js
  accept: PropTypes.string, // Set it to accept only specifi file types. Ex: accept='.xlsx, .xls'
  uploadText: PropTypes.string, // Change the upload button text
  initGalleryFiles: PropTypes.bool // Init the gallery files on file clic
};
