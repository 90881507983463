import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';
import { useState, useMemo, FC } from 'react';
import useTooltipID from 'common/utils/hooks/useTooltipID';

import { Label } from 'reactstrap';
import ErrorMessage from '@/ts-common/components/form/helpers/ErrorMessage';
import ToggleButton from './components/ToggleButton';
import DatePlaceholder from './components/DatePlaceholder';
import Popup from './Popup';
import Input from './Input';

import { mode } from './_constants';
import { getCustomFormat } from 'common/utils/dates';
import { useUpdateEffect } from 'utils/hooks';
import FormFieldPreviewer from '@/ts-common/components/form/helpers/FormFieldPreviewer';
import { hasRequiredValidation } from 'common/utils/form/validation';

const DateInput: FC<any> = ({
  value, // singe value
  from, // range from value
  to, // range to value
  error,
  onChange,
  activeMode = mode.single.date,
  availableModes = [activeMode],
  onModeChange,
  invisible,
  label,
  innerLabel,
  className = '',
  innerClassName = '',
  hasTime,
  showTime,
  toggleTime,
  disabled,
  size = invisible ? 'xs' : 'sm',
  placeholder,
  useDateInput = true,
  useStringValue = false,
  yearStart,
  yearEnd,
  dataCy,
  dataTestid,
  avoidDateRangeValidation = false,
  lockTextInput = false,
  renderInput,
  viewOnly = false,
  firstInputClassName = '',
  viewIcon,
  ...rest // Rest datepicker props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [invalidDatesError, setInvalidDatesError] = useState('');
  const [dateRangeStarts, setDateRangeStarts] = useState(from);
  const [dateRangeEnds, setDateRangeEnds] = useState(to);
  const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState('');

  const { avoidRender, tooltipID } = useTooltipID('date-input-trigger');

  const togglePopup = () => (disabled ? {} : setIsOpen(!isOpen));
  const closePopup = () => setIsOpen(false);

  const isRange = useMemo(() => activeMode.endsWith('range'), [activeMode]);
  const isWeekdate = useMemo(() => activeMode.startsWith('weekdate'), [activeMode]);

  const dateError = useMemo(() => invalidDatesError || error, [error, invalidDatesError]);
  const isRequired = useMemo(() => hasRequiredValidation(error), [error]);

  const parseDateFormat = (date: any) => {
    if (!useStringValue) return date;

    const dateFormat = `YYYY-MM-DD${hasTime ? ' HH:mm' : ''}`;

    if (isRange) {
      return {
        from: date.from ? getCustomFormat(date.from, dateFormat) : null,
        to: date.to ? getCustomFormat(date.to, dateFormat) : null
      };
    }

    return date ? getCustomFormat(date, dateFormat) : null;
  };

  const handleDateChange = (selected: any) => {
    if (isRange) {
      if (selected.to !== undefined) setDateRangeEnds(selected.to);
      if (selected.from !== undefined) setDateRangeStarts(selected.from);

      const dates = {
        from: dateRangeStarts || from,
        to: dateRangeEnds || to,
        ...selected
      };

      if (selected.to !== undefined && moment(selected.to).isBefore(dates.from)) {
        setInvalidDatesError('Ending date should be after starting');

        return;
      } else if (selected.from !== undefined && moment(selected.from).isAfter(dates.to)) {
        setInvalidDatesError('Starting date should be before ending');

        return;
      }

      if (
        (dates.from && dates.to) ||
        (dates.from === null && dates.to === null) ||
        avoidDateRangeValidation
      ) {
        onChange(parseDateFormat(dates));
        setInvalidDatesError('');
      }
    } else {
      onChange(parseDateFormat(selected));
      closePopup();
    }
  };

  useUpdateEffect(() => {
    if ((from && !dateRangeStarts) || (!from && dateRangeStarts)) {
      setDateRangeStarts(from);
    }

    if ((to && !dateRangeEnds) || (!to && dateRangeEnds)) {
      setDateRangeEnds(to);
    }
  }, [from, to]);

  if (viewOnly)
    return (
      <FormFieldPreviewer
        className={className}
        label={label}
        value={from && to ? { from, to } : value}
        type={hasTime ? `${activeMode}-with-time` : activeMode}
        // viewIcon={viewIcon}
      />
    );

  return (
    <div
      data-cy={dataCy}
      data-testid={dataTestid}
      className={`date-input size--${size} ${
        invisible ? 'invisible-date' : ''
      } ${activeMode} ${className} ${!invisible && label ? 'date-input-form-group' : ''} ${
        isRequired ? 'has-required-error' : ''
      }`}
    >
      {label && <Label className="form-label d-block">{label}</Label>}

      <div
        className={`date-input__inner position-relative mb-0 ${
          invisible ? 'invisible-input' : ''
        } ${dateError ? 'border-red' : ''} ${disabled ? 'disabled' : ''} ${innerClassName}`}
      >
        {useDateInput ? (
          <>
            <ToggleButton
              className={`${value && value?.length ? 'with-value' : ''} ${isOpen ? 'is-open' : ''}`}
              id={tooltipID}
              onClick={togglePopup}
              disabled={disabled}
            />
            {renderInput ? (
              renderInput()
            ) : (
              <div
                onClick={lockTextInput ? togglePopup : () => null}
                className="d-flex align-items-center flex-nowrap text-nowrap"
              >
                <div className={`date-input__field start ${firstInputClassName}`}>
                  {innerLabel ? (
                    <div className="date-input__field-inner-label">{innerLabel}</div>
                  ) : null}

                  <Input
                    activeMode={activeMode}
                    value={isRange ? dateRangeStarts : value}
                    onChange={(date: any) =>
                      isRange ? handleDateChange({ from: date }) : handleDateChange(date)
                    }
                    hasTime={showTime}
                    disabled={disabled || lockTextInput}
                    readonly={isWeekdate}
                    focused={isRange && isOpen && dateRangeFocusedInput === 'startDate'}
                    isOpen={isOpen}
                    togglePopup={togglePopup}
                  />
                </div>
                {isRange ? (
                  <div className="date-input__field end">
                    <div
                      className={`date-input__field-end text-${disabled ? 'inactive' : 'primary'}`}
                    >
                      To
                    </div>
                    <Input
                      activeMode={activeMode}
                      value={dateRangeEnds}
                      onChange={(date: any) => handleDateChange({ to: date })}
                      hasTime={showTime}
                      disabled={disabled || lockTextInput}
                      readonly={isWeekdate}
                      focused={isOpen && dateRangeFocusedInput === 'endDate'}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <div className="w-100p" id={tooltipID || ''}>
            <DatePlaceholder
              value={value}
              hasTime={showTime}
              placeholder={placeholder}
              isRange={isRange}
              dateRange={{ starts: { value: dateRangeStarts }, ends: { value: dateRangeEnds } }}
            />
          </div>
        )}

        {dateError && <ErrorMessage>{dateError}</ErrorMessage>}
      </div>

      {!avoidRender ? (
        <Popup
          target={tooltipID}
          isOpen={isOpen}
          toggle={togglePopup}
          close={closePopup}
          activeMode={activeMode}
          value={value}
          onChange={handleDateChange}
          hasTime={hasTime}
          showTime={showTime}
          toggleTime={toggleTime}
          availableModes={availableModes}
          onModeChange={onModeChange}
          dateRange={{
            starts: { value: dateRangeStarts },
            ends: { value: dateRangeEnds },
            onFocus: setDateRangeFocusedInput
          }}
          yearStart={yearStart}
          yearEnd={yearEnd}
          {...rest}
        />
      ) : null}
    </div>
  );
};

export { mode };
export default DateInput;
