import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CAPTAIN_REPORT_MESSAGES'),
  ...asyncTypes('POST_CAPTAIN_REPORT_MESSAGE'),
  ...asyncTypes('GET_CAPTAIN_REPORT_UNREAD_MESSAGES'),
  ...asyncTypes('CAPTAIN_REPORT_MARK_AS_READ')
};

export default TYPES;
