import PropTypes from 'prop-types';
import PersonAvatar from 'manuals/components/PersonAvatar';
import _get from 'lodash/get';
import { Col, Row } from 'reactstrap';

const ApprovedBy = ({ version }) => {
  return version?.approved_by ? (
    <Row className="d-flex align-items-center">
      <Col xs="auto">
        <PersonAvatar
          photo={_get(version, 'approved_by.photo', null)}
          imgProps={{ width: 20, height: 20 }}
        />
      </Col>
      <Col>
        <span className="fs-12 text-break">{_get(version, 'approved_by.full_name', null)}</span>
      </Col>
    </Row>
  ) : null;
};

ApprovedBy.propTypes = {
  version: PropTypes.object
};

export default ApprovedBy;
