import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import PropTypes from 'prop-types';
import { Size } from './_types';

const boxSize = {
  lg: variables.size56,
  sm: variables.size24,
  xs: variables.size20
};

const boxRadius = {
  lg: variables.borderRadiusLg
};

const FileContainer = ({ size, children, height, id }) => {
  return (
    <Container size={size} height={height} id={id}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  min-width: ${({ size }) => boxSize[size]};
  max-width: ${({ size }) => boxSize[size]};
  width: 100%;
  height: ${({ size, height }) => height || boxSize[size]};
  border-radius: ${({ size }) => boxRadius?.[size] || variables.borderRadiusBase};
  color: currentColor;
  position: relative;

  &:hover {
    .file-remove-icon {
      opacity: 1;
    }
  }
`;

FileContainer.propTypes = {
  size: Size.isRequired,
  children: PropTypes.node,
  height: PropTypes.string,
  id: PropTypes.string
};

export default FileContainer;
