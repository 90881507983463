import { combineReducers } from 'redux';
import tables from './tables/reducer';
import totals from './totals/reducer';
import runningHoursDue from './running-hours-due/reducer';

export default combineReducers({
  tables,
  totals,
  runningHoursDue
});
