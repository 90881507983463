import { asyncTypes } from 'store/_helpers';

const TYPES = {
  SET_MESSAGES: 'SET_MESSAGES',
  POST_MESSAGE: 'POST_MESSAGE',
  SET_UNREAD_MESSAGES: 'SET_UNREAD_MESSAGES',
  SET_MARK_AS_READ: 'SET_MARK_AS_READ',
  SET_FETCHING: 'SET_FETCHING',
  ...asyncTypes('GET_GLOBAL_UNREAD_MESSAGES')
};

export default TYPES;
