import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';

import VesselSelector from 'common/components/selectors/VesselSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import TopPagination from 'common/components/table/main/TopPagination';

const TopFilters = ({ table, topFilters, setTopFilters, className }) => {
  const selectedVessel = topFilters?.find(f => f.name === 'vessel');
  const isOnBoard = useSelector(state => state.isOnBoard);

  const updateTopFilters = useCallback(
    (key, value, operation) => {
      setTopFilters(
        topFilters.map(f =>
          f.name === key ? { ...f, value, operation: operation || f.operation } : f
        )
      );
    },
    [topFilters]
  );

  return (
    <Row className={`d-flex align-items-center cmb-12 w-100p ${className}`} noGutters>
      {!isOnBoard && (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{ value: selectedVessel?.value?.length ? selectedVessel.value : null }}
              isMulti={true}
              isClearable
              autoFocus={false}
              onChange={({ value }) => updateTopFilters('vessel', value?.length ? value : null)}
              placeholder="Select vessel"
            />
          </TableTopFilter>
        </Col>
      )}

      {table && (
        <Col className="ms-auto app-table">
          <div className="app-table--toptotal d-flex align-items-center justify-content-end">
            <TopPagination
              standAlone
              state={{
                paging: _get(table, 'state.paging') || {
                  total: _get(table, 'state.data', []).length
                }
              }}
              fetchData={params => table.refetchData(params)}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default TopFilters;
