import React, { useCallback } from 'react';

import {
  selectVesselSystemAssignmentJobs,
  selectTotalVesselSystemAssignmentJobs,
  selectSelectedVessels
} from 'common/components/pms/jobs/store/selectors';
import { useSelector } from 'react-redux';
import { stringifyObj } from 'common/utils/urls';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';

const Jobs = ({ vesselSystemAssignmentID }) => {
  const { history } = useRouter();

  const selectedVessels = useSelector(selectSelectedVessels);

  const jobs = useSelector(state =>
    selectVesselSystemAssignmentJobs(state, vesselSystemAssignmentID)
  );
  const totalJobs = useSelector(state =>
    selectTotalVesselSystemAssignmentJobs(state, vesselSystemAssignmentID)
  );
  const filterPrototypeJobs = useCallback(
    prototypeID => {
      history.push({
        pathname: paths.pms_jobs,
        search: stringifyObj({
          filters: [
            { name: 'vessel_id', operation: 'oneOf', value: selectedVessels },
            { name: 'prototype_id', operation: 'oneOf', value: [prototypeID] },
            { name: 'is_completed', operation: '=', value: 'true' }
          ]
        })
      });
    },
    [history, selectedVessels]
  );

  return (
    <div className="pms-jobs-view__jobs">
      {totalJobs ? (
        <div className="pms-jobs-view__jobs-inner">
          <TableHeader />

          {jobs.map(jobID => (
            <TableRow key={jobID} jobID={jobID} filterPrototypeJobs={filterPrototypeJobs} />
          ))}
        </div>
      ) : (
        <span className="text-violet fw-normal fs-12">No Jobs</span>
      )}
    </div>
  );
};

export default Jobs;
