import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import arrow from 'common/assets/svg/common/arrow.svg';

const Breadcrumbs = ({
  activeUID,
  activeID,
  className = '',
  previewClassName = '',
  dropdownMenuClassName = '',
  breadcrumbs = [],
  maxLimit,
  onClick = () => {},
  render
}) => {
  const hasMaxLimit = maxLimit && breadcrumbs.length > maxLimit;
  const displayedBreadcrumbs = breadcrumbs.filter((chapter, index) =>
    hasMaxLimit ? index < maxLimit || index === breadcrumbs.length - 1 : chapter
  );
  const active = activeUID || activeID;

  return breadcrumbs ? (
    <div className={`tree-breadcrumbs ${className}`}>
      {displayedBreadcrumbs.map((item, index) => {
        const isActive = item.uid ? active === item.uid : active === item.id;

        return (
          <div
            className={`tree-breadcrumbs__preview${isActive ? ' active' : ''} ${
              previewClassName || ''
            } text-nowrap`}
            key={index}
          >
            {hasMaxLimit && maxLimit && index + 1 === maxLimit ? (
              onClick ? (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="nav-link p-0 cursor-pointer">
                    <span>...</span>
                  </DropdownToggle>
                  <DropdownMenu className={`${dropdownMenuClassName || ''}`}>
                    {breadcrumbs
                      .filter((_, i) => i >= maxLimit - 1 && i + 1 !== breadcrumbs.length)
                      .map(breadcrumb => (
                        <DropdownItem
                          key={breadcrumb.uid || breadcrumb.id}
                          onClick={() => onClick(breadcrumb)}
                        >
                          {render ? render(breadcrumb) : breadcrumb.name}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <span>...</span>
              )
            ) : (
              <div
                className={`${
                  displayedBreadcrumbs.length === 1
                    ? 'max-width-100p'
                    : 'max-width-165 overflow-hidden'
                } ${onClick ? 'pointer' : ''}`}
                onClick={() => (onClick ? onClick(item) : null)}
              >
                <TextWithTooltip>{render ? render(item) : item.name}</TextWithTooltip>
              </div>
            )}

            {!active &&
            (index + 1 === displayedBreadcrumbs.length ||
              displayedBreadcrumbs.length === 1) ? null : (
              <SvgRender
                className={`text-${
                  isActive && !item.has_post ? 'dark' : 'yellow'
                } tree-breadcrumbs__preview-icon ms-1`}
                src={isActive ? null : arrow}
                style={
                  isActive
                    ? { width: 12, height: 14 }
                    : { width: 10, height: 10, transform: 'rotate(-90deg)' }
                }
              />
            )}
          </div>
        );
      })}
    </div>
  ) : null;
};

Breadcrumbs.propTypes = {
  activeUID: PropTypes.number, // use either activeUID || activeID
  activeID: PropTypes.number,
  className: PropTypes.string,
  previewClassName: PropTypes.string,
  dropdownMenuClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  maxLimit: PropTypes.number,
  onClick: PropTypes.func,
  render: PropTypes.func // custom render
};

export default Breadcrumbs;
