import React, { useEffect, useState } from 'react';
import Information from 'common/components/general/Information';
import { findColorByType, getPlotTitle } from '../helpers/_functions';
import _groupBy from 'lodash/groupBy';
import _isArray from 'lodash/isArray';
import { plotTypeType } from '../plotTypes';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import Tooltip from 'common/components/general/Tooltip';

const D3Legends = ({
  plots,
  disabledPlots,
  setDisabledPlots,
  legendInfo,
  graphUID,
  legendsRef
}) => {
  const [mappedPlotGroups, setMappedPlotGroups] = useState([]);
  const { avoidRender, tooltipID } = useTooltipID('plot-info');

  useEffect(() => {
    setMappedPlotGroups(
      Object.values(
        _groupBy(
          plots
            .map((plot, index) => {
              const color = findColorByType(plot);
              return { ...plot, color, index, uniqueId: `${color}-${getPlotTitle(plot)}` };
            })
            .filter(plot => !plot.hideLegend),
          'uniqueId'
        )
      )
    );
  }, [plots]);

  const onClickLegend = plots => {
    let newState = [...disabledPlots];

    plots.forEach(plot => {
      const shouldBeAdded = disabledPlots.indexOf(plot.index) === -1;

      if (shouldBeAdded) {
        newState.push(plot.index);
      } else {
        newState = newState.filter(i => i !== plot.index);
      }
      // If any of the plot types are svg
      const hasSVGElement = _isArray(plot.type)
        ? plot.type.some(plotType => plotTypeType[plotType] === 'svg')
        : plotTypeType[plot.type] === 'svg';

      if (hasSVGElement) {
        const plotID = `plot-${graphUID}-${plot.index}`;
        const element = document.getElementById(plotID);
        // If it should be added in the list of disabled plots, so enabled -> disabled
        if (shouldBeAdded) {
          element.classList.add('d-none');
        } else {
          // disabled -> enabled
          element.classList.remove('d-none');
        }
      }
    });

    setDisabledPlots(newState);
  };

  if (avoidRender) return null;

  return (
    <>
      <div className="d3-graph__legends" ref={legendsRef}>
        {mappedPlotGroups.map(plotGroup => {
          const plot = plotGroup[0];

          const { label, description, customLegendName, info } = plot;
          const title = getPlotTitle(plot);
          const secondary = label ? description : null;
          const isTriangle = plot.style?.shape === 'triangle';

          return (
            <div
              key={plot.index}
              className={`d3-graph__legends__single ${
                disabledPlots.indexOf(plot.index) !== -1
                  ? 'd3-graph__legends__single--disabled'
                  : ''
              }`}
              onClick={() => {
                onClickLegend(plotGroup);
              }}
            >
              <div
                className={`d3-graph__legends__single__block ${isTriangle ? 'triangle' : ''}`}
                style={{ [isTriangle ? 'borderBottomColor' : 'backgroundColor']: plot.color }}
              />
              <span id={info ? tooltipID : undefined} className="d3-graph__legends__single__title">
                {customLegendName ? customLegendName : title}
              </span>

              {info ? <Tooltip target={tooltipID}>{info}</Tooltip> : null}

              {secondary ? (
                <span className="d3-graph__legends__single__secondary">&nbsp;{secondary}</span>
              ) : null}
            </div>
          );
        })}
        {legendInfo ? (
          <Information
            message={legendInfo}
            svgStyle={{ width: 12, height: 12 }}
            tooltipClassname="show-more-tooltip max-content"
            className="ms-1 text-primary"
          />
        ) : null}
      </div>
    </>
  );
};

export default D3Legends;
