import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectEventId, selectIsTemplate } from 'events/store/events/selectors';

import ModuleWrapper from '../components/ModuleWrapper';
import Loader from './Loader';
import Body from './Body';
import { getEmailConnection } from 'events/store/event-modules/email-connection/actions';
import { selectEmailConnectionIsLoading } from 'events/store/event-modules/email-connection/selectors';

const EmailConnection = ({ isCreate }) => {
  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const isLoading = useSelector(selectEmailConnectionIsLoading);
  const isTemplate = useSelector(selectIsTemplate);

  useEffect(() => {
    const fetchEmailConnection = async () => {
      try {
        await dispatch(getEmailConnection({ event_id: eventId })).unwrap();
      } catch (err) {
        console.error(err);
      }
    };

    if (!isCreate && !isTemplate) {
      fetchEmailConnection();
    }
  }, [dispatch, eventId, isCreate, isTemplate]);

  return (
    <ModuleWrapper className="email-connection" type="email_connection">
      {isLoading ? <Loader /> : <Body isCreate={isCreate} />}
    </ModuleWrapper>
  );
};

EmailConnection.propTypes = {
  isCreate: PropTypes.bool
};

export default EmailConnection;
