import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const OrangeLegend = () => {
  return (
    <div className="legend d-flex align-items-center lh-15">
      <Indicator />
      <div className="text-warning fs-12 fw-medium cms-6">
        Default amount set for the selected Wage Class
      </div>
    </div>
  );
};

const Indicator = styled.div`
  width: 9px;
  height: 9px;
  background-color: ${variables.goldBell};
  border-radius: 50%;
`;

export default OrangeLegend;
