import axios from 'axios';
import { upload } from 'utils/api';

class UploadAdapter {
  constructor(loader, imageUploadCallback, uploadedImagesGroup, uploadImagesRequestPath) {
    // The file loader instance to use during the upload.
    this.loader = loader;

    this.uploadUrl = uploadImagesRequestPath;
    this.uploadRequest = axios.CancelToken.source();

    this.imageUploadCallback = imageUploadCallback;
    this.uploadedImagesGroup = uploadedImagesGroup;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          this._sendRequest(resolve, reject, file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.uploadRequest) {
      this.uploadRequest.cancel();
    }
  }

  _sendRequest(resolve, reject, file) {
    const genericErrorText = `Couldn't upload file ${file?.name}.`;

    let data = new FormData();
    data.append('group', this.uploadedImagesGroup);
    data.append('files[]', file, file.name);

    if (file?.size > 2097152) {
      reject('The image upload failed because the image was too big (max 2MB).');
    } else {
      upload(this.uploadUrl, data, true, {
        cancelToken: this.uploadRequest.token,
        onUploadProgress: evt => {
          this.loader.uploadTotal = evt.total;
          this.loader.uploaded = evt.loaded;
        }
      })
        .then(res => {
          if (res && res.data && res.data[0]) {
            resolve({ default: `${res.data[0].url}` });

            return res.data[0];
          }
        })
        .catch(error => {
          console.log(error);

          return reject(
            error && error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : genericErrorText
          );
        })
        .then(uploadedFile => {
          if (uploadedFile && this.imageUploadCallback) {
            this.imageUploadCallback(uploadedFile);
          }
        });
    }
  }
}

const CustomImageUploadAdapterPlugin = function (editor) {
  const fileRepository = editor.plugins.get('FileRepository');

  fileRepository.createUploadAdapter = loader => {
    return new UploadAdapter(
      loader,
      editor.config._config.imageUploadCallback,
      editor.config._config.uploadedImagesGroup,
      editor.config._config.uploadImagesRequestPath
    );
  };
};

export default CustomImageUploadAdapterPlugin;
