import React from 'react';
import Select from 'common/components/form/inputs/Select';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';

import SvgRender from 'common/components/general/SvgRender';

import department from 'common/assets/svg/common/department.svg';
import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import portIcon from 'assets/svg/sidebar/ports.svg';
import voyagesIcon from 'common/assets/svg/common/voyages.svg';
import approversIcon from 'common/assets/svg/common/check-circle.svg';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';

import {
  Option,
  SingleValue,
  MultiValue
} from 'common/components/selectors/_partySelectComponents';

const typeMapping = {
  department,
  vessel: vesselIcon,
  port: portIcon,
  voyage: voyagesIcon,
  approvers: approversIcon
};

const labelMapping = {
  department: 'department(s)',
  vessel: 'vessel',
  port: 'port',
  voyage: 'voyage',
  person: 'person / initiator',
  approvers: 'approver(s)'
};

const placeholderMapping = {
  department: 'department(s)',
  vessel: 'vessel',
  port: 'port',
  voyage: 'voyage',
  person: 'person',
  approvers: 'approver(s)'
};

export const SelectLabel = ({ type }) => (
  <div className="d-flex align-items-center text-primary text-uppercase fw-bold">
    {typeMapping[type] ? (
      <SvgRender className="me-1 fs-9" style={{ width: 14, height: 14 }} src={typeMapping[type]} />
    ) : null}
    {labelMapping[type]}
  </div>
);

const CustomSelect = ({
  value,
  onChange,
  type,
  className,
  list,
  rest = {},
  disabled,
  vessel_id,
  preventOptionsFetch = false
}) => {
  const loadOptions = search => {
    return preventOptionsFetch ? null : getAsyncOptions(search, list, { ...rest });
  };
  const defaultOptions = () => {
    return preventOptionsFetch ? null : getInitialAsyncValues(list, null, false, { ...rest });
  };

  const getOptionLabel = option => {
    switch (type) {
      case 'person':
        return getPartyDisplayName(option);

      case 'voyage':
        return option.title;

      default:
        return option.name;
    }
  };

  if (type === 'department') {
    return (
      <div className={className ? className : ''}>
        <div className={`form-label `}>
          <SelectLabel type={type} />
        </div>

        <DepartmentSelector
          handleOnMenuClose
          isMulti
          disabled={disabled}
          placeholder={`Select ${placeholderMapping[type]}`}
          dataCy="event_department"
          value={value}
          onChange={d => onChange(d ? d : [])}
          showMore={true}
          id={`main-department-select-events`}
        />
      </div>
    );
  }

  return (
    <Select
      isAsync
      label={<SelectLabel type={type} />}
      placeholder={`Select ${placeholderMapping[type]}`}
      dataCy={`event_${labelMapping[type]}`}
      getOptionValue={option => option.id}
      getOptionLabel={getOptionLabel}
      value={value}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      onChange={onChange}
      menuPlacement={type === 'person' ? 'bottom' : 'top'}
      components={type === 'person' ? { Option, SingleValue, MultiValue } : undefined}
      isClearable={true}
      disabled={disabled}
      defaultOptionsTriggerChange={type === 'voyage' ? vessel_id : undefined}
      className={className ? className : ''}
      noOptionsMessage={({ inputValue }) =>
        !inputValue.length ? `Search for ${labelMapping[type]}` : `No ${labelMapping[type]} found`
      }
    />
  );
};

export default CustomSelect;
