import { useCallback, useEffect, useRef } from 'react';
import { renderD3ElementsToCanvas } from '../helpers/_rendering';

const useD3Canvas = (canvasRef, element) => {
  const canvasDimensions = useRef({});
  const context = canvasRef.current ? canvasRef.current.getContext('2d') : null;
  const runningRenderer = useRef();
  const scale = useRef(1);

  const setCanvasDimensions = event => {
    if (!event.detail) return;

    canvasDimensions.current = {
      innerHeight: event.detail.innerHeight,
      innerWidth: event.detail.innerWidth,
      topOffset: event.detail.topOffset,
      leftOffset: event.detail.leftOffset
    };
  };

  useEffect(() => {
    const node = element.node();

    if (node) node.addEventListener('graph-rendered', setCanvasDimensions);
    return () => {
      if (node) node.removeEventListener('graph-rendered', setCanvasDimensions);
    };
  }, [element]);

  const fixCanvasScalingForRetinaDisplays = useCallback(
    (width, height) => {
      if (!context) return;

      const devicePixelRatio = window.devicePixelRatio || 1,
        backingStoreRatio =
          context.webkitBackingStorePixelRatio ||
          context.mozBackingStorePixelRatio ||
          context.msBackingStorePixelRatio ||
          context.oBackingStorePixelRatio ||
          context.backingStorePixelRatio ||
          1,
        ratio = devicePixelRatio / backingStoreRatio;

      if (devicePixelRatio !== backingStoreRatio) {
        canvasRef.current.width = width * ratio;
        canvasRef.current.height = height * ratio;

        canvasRef.current.style.width = width + 'px';
        canvasRef.current.style.height = height + 'px';

        scale.current = ratio;
        context.scale(ratio, ratio);
      }
    },
    [canvasRef, context]
  );

  const renderCanvas = useCallback(
    (element, getDimensions, disabledPlots) => {
      const { width, height } = getDimensions();

      const render = () => {
        if (!context) return;

        // Render D3 elements
        renderD3ElementsToCanvas(
          context,
          element,
          disabledPlots,
          canvasDimensions.current,
          width,
          height,
          scale.current
        );

        // Rerender each frame
        runningRenderer.current = requestAnimationFrame(render);
      };

      fixCanvasScalingForRetinaDisplays(width, height);
      render();
    },
    [context, fixCanvasScalingForRetinaDisplays]
  );

  const destroyCanvasRenderer = useCallback(
    () => (runningRenderer.current ? cancelAnimationFrame(runningRenderer.current) : null),
    []
  );

  return {
    renderCanvas,
    destroyCanvasRenderer,
    canvasDimensions: canvasDimensions.current
  };
};

export default useD3Canvas;
