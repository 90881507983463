import { FC } from 'react';
import arrowIcon from '@/common/assets/svg/actions/right-arrow.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';
import { hasValue } from '@/common/utils/numbers';
import { numberToStr } from '@/ts-common/utils/numbers';
import { displayDate } from '@/ts-common/utils/dates';

type PeriodType = {
  from: string;
  to: string;
};

type BaseToCurrentCurrencyProps = {
  baseCurrencyLabel: string | null | undefined;
  currentCurrencyLabel: string | null | undefined;
  currenciesClassName?: string;
  period?: PeriodType;
  rate?: number;
};

const BaseToCurrentCurrency: FC<BaseToCurrentCurrencyProps> = ({
  baseCurrencyLabel,
  currentCurrencyLabel,
  currenciesClassName,
  period,
  rate
}) => {
  if (!baseCurrencyLabel || !currentCurrencyLabel) return null;

  return (
    <div className="d-flex align-items-center">
      <div className={`text-primary fs-12 ${currenciesClassName || ''}`}>
        {currentCurrencyLabel}
      </div>
      <SvgRender src={arrowIcon} className="text-primary cmx-4" style={{ height: 7 }} />
      <div className={`text-primary fs-12 ${currenciesClassName || ''}`}>{baseCurrencyLabel}</div>

      {period?.from && period?.to ? (
        <div className={`text-primary fs-12 ms-1 ${currenciesClassName || ''}`}>
          ({displayDate(period.from, { time: true })} - {displayDate(period.to, { time: true })})
        </div>
      ) : null}

      {hasValue(rate) ? (
        <div className={`text-primary fs-12 ${currenciesClassName || ''}`}>
          : {numberToStr(rate, 4, 4)}
        </div>
      ) : null}
    </div>
  );
};

export default BaseToCurrentCurrency;
