import { bool } from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';

import ScrollableMidSection from 'common/components/jobs/regular/components/ScrollableMidSection';
import Attachments from 'common/components/jobs/_base/inputs/Attachments';
import Description from 'common/components/jobs/_base/inputs/Description';
import ErrorWrapper from 'common/components/layouts/ErrorWrapper';
import Activity from 'common/components/jobs/_base/modules/activity';
import Tabs from 'common/components/jobs/_base/components/Tabs';
import { Row, Col } from 'reactstrap';
import LinkEntities from 'common/components/link-entities';
import { selectJobFindings, selectJobEvents } from 'common/components/jobs/_base/store/selectors';
import { unlinkJobFinding } from 'common/components/jobs/_base/store/actions';
import { selectJobId } from 'common/components/jobs/_base/store/selectors';
import VesselFieldsWrapper from './VesselFieldsWrapper';

export const MidSection = ({ isEditMode }) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const findings = useSelector(selectJobFindings);
  const events = useSelector(selectJobEvents);
  const jobID = useSelector(selectJobId);
  const dispatch = useDispatch();

  const onUnlinkFinding = async id => {
    if (!id || !jobID) return;

    try {
      await dispatch(unlinkJobFinding({ job_id: jobID, id })).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Tabs />

      <Description className="pb-4 mb-2" />
      <Attachments />
      <LinkEntities
        findingsState={{ onUnlink: onUnlinkFinding, findingsState: findings, color: 'light-1' }}
        eventsState={{ eventsState: events, color: 'light-1' }}
      />

      <VesselFieldsWrapper />

      {isOnboard ? null : (
        <Row className="mb-1">
          {isEditMode && (
            <Col xs={12}>
              <ErrorWrapper text="Activity unavailable" maxHeight={350}>
                <Activity isProfile />
              </ErrorWrapper>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

MidSection.propTypes = {
  isEditMode: bool
};

const MainContent = ({ isEditMode }) => {
  return (
    <div className="d-flex flex-column h-100p">
      <ScrollableMidSection>
        <MidSection isEditMode={isEditMode} />
      </ScrollableMidSection>
    </div>
  );
};
export default MainContent;
