import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  selectLatestPeriod,
  selectHasSetPeriods,
  selectIsLatestPeriodDraft,
  selectIsLatestPeriodForReview,
  selectIsLatestPeriodForCorrections,
  selectIsLatestPeriodApproved,
  selectIsPastMga
} from 'common/components/mga/store/selectors';
import { selectCurrenciesWithoutBase } from '@/common/components/mga/store/selectors-ts';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import useRouter from 'use-react-router';

import PageCommentsPopover from 'common/components/popovers/PageCommentsPopover';

import Seperator from './Seperator';

import PeriodSelector from './PeriodSelector';
import VesselSelector from './VesselSelector';

import ActionForm from './action-form';
import AddActionButton from './AddActionButton';

import PeriodButtonEditDraft from './PeriodButtonEditDraft';
import PeriodButtonCreateDraft from './PeriodButtonCreateDraft';
import PeriodButtonSubmitToOffice from './PeriodButtonSubmitToOffice';
import PeriodButtonSendBackForCorrections from './PeriodButtonSendBackForCorrections';
import PeriodButtonApprove from './PeriodButtonApprove';
import PeriodButtonEditApproved from './PeriodButtonEditApproved';

import OfficeMoreActions from './OfficeMoreActions';

import {
  editMgaPeriod,
  getMgaChatMessages,
  postMgaChatMessage,
  getMgaUnreadChatMessages,
  mgaChatMessageMarkAsRead
} from 'common/components/mga/store/actions';
import { getMgaCurrencies } from '@/common/components/mga/store/actions-ts';
import { useDrawer } from 'common/components/drawer';
import Chatbox from 'common/components/chatbox';
import SetupForexRatesForm from '@/common/components/mga/components/actions/setup-forex-rate-form/index.tsx';
import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';
import { LOGS_ENTITY_TYPES } from '@/common/types/logs';
import LogsTimelineDrawer from '@/common/components/logs-timeline-drawer';

const Actions = () => {
  const chatDrawer = useDrawer();
  const setupForexRatesDrawer = useDrawer();
  const logsDrawer = useDrawer();

  const latestPeriod = useSelector(selectLatestPeriod);
  const hasPeriods = useSelector(selectHasSetPeriods);
  const isPastMga = useSelector(selectIsPastMga);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const currencies = useSelector(selectCurrenciesWithoutBase);

  const isDraft = useSelector(selectIsLatestPeriodDraft);
  const isForReview = useSelector(selectIsLatestPeriodForReview);
  const isForCorrections = useSelector(selectIsLatestPeriodForCorrections);
  const isApproved = useSelector(selectIsLatestPeriodApproved);

  const { match } = useRouter();
  const vesselId = match?.params?.vesselID;

  const statuses = useSelector(state => selectListOptionsFromStore(state, 'mga-period-statuses'));

  const dispatch = useDispatch();

  const updatePeriodStatus = useCallback(
    async (periodID, statusLabel, extraParams = {}) => {
      const params = {
        id: periodID,
        status_id: statuses.find(s => s.label === statusLabel)?.id,
        ...extraParams
      };

      await dispatch(editMgaPeriod(params));
    },
    [dispatch, statuses]
  );
  const getMessagesAction = useCallback(async () => {
    if (!latestPeriod?.id) return;

    try {
      await dispatch(getMgaChatMessages({ id: latestPeriod.id }));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, latestPeriod?.id]);

  const postMessageAction = async params => {
    if (!latestPeriod?.id) return;

    try {
      await dispatch(
        postMgaChatMessage({
          id: latestPeriod.id,
          ...params
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const getUnreadMessages = useCallback(async () => {
    if (!latestPeriod?.id) return;

    try {
      await dispatch(getMgaUnreadChatMessages({ id: latestPeriod.id }));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, latestPeriod?.id]);

  const markAsReadAction = async () => {
    if (!latestPeriod?.id) return;

    try {
      await dispatch(mgaChatMessageMarkAsRead({ id: latestPeriod.id }));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMgaCurrencies = useCallback(async () => {
    try {
      await dispatch(getMgaCurrencies()).unwrap();
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    if (latestPeriod?.id) {
      getUnreadMessages();
      getMessagesAction();
    }
    fetchMgaCurrencies();
  }, [fetchMgaCurrencies, getMessagesAction, getUnreadMessages, latestPeriod?.id]);

  return (
    <>
      {!vesselId && !isOnBoard ? (
        <>
          <Button onClick={setupForexRatesDrawer.open} type="button" color="white" size="md">
            Setup Forex Rates
          </Button>
          <Seperator />
        </>
      ) : null}

      {isOnBoard && !isPastMga ? (
        isForCorrections ? (
          <div className="d-flex align-items-center">
            <PeriodSelector />
            <Seperator />
            <PeriodButtonSubmitToOffice
              latestPeriod={latestPeriod}
              updatePeriodStatus={updatePeriodStatus}
              isForCorrections
            />
            <AddActionButton className="ms-2" />

            {latestPeriod?.review_comments ? (
              <PageCommentsPopover message={latestPeriod?.review_comments} />
            ) : null}
          </div>
        ) : isDraft ? (
          <div className="d-flex align-items-center">
            <PeriodSelector />
            <Seperator />
            <PeriodButtonEditDraft />
            <div className="ms-1"></div>
            <PeriodButtonSubmitToOffice
              latestPeriod={latestPeriod}
              updatePeriodStatus={updatePeriodStatus}
            />
            <AddActionButton className="ms-2" />
          </div>
        ) : hasPeriods ? (
          <PeriodButtonCreateDraft />
        ) : null
      ) : (
        <div className="d-flex align-items-center pe-8">
          {!isPastMga ? <VesselSelector /> : null}
          {!latestPeriod ? <Seperator /> : null}
          <PeriodSelector />

          {latestPeriod ? (
            <>
              <Seperator />
              <Chatbox
                getMessages={getMessagesAction}
                sendMessage={postMessageAction}
                getUnreadMessages={getUnreadMessages}
                markMessagesAsRead={markAsReadAction}
                drawer={chatDrawer}
                size={'sm'}
                fetchOnMount={false}
              />
            </>
          ) : null}

          {isForReview && !isPastMga ? (
            <>
              <Seperator />
              <PeriodButtonSendBackForCorrections
                latestPeriod={latestPeriod}
                updatePeriodStatus={updatePeriodStatus}
              />
              <PeriodButtonApprove
                latestPeriod={latestPeriod}
                updatePeriodStatus={updatePeriodStatus}
              />
              <Seperator />
            </>
          ) : isApproved && !isPastMga ? (
            <>
              <Seperator />
              <PeriodButtonEditApproved
                className="cme-12"
                latestPeriod={latestPeriod}
                updatePeriodStatus={updatePeriodStatus}
              />
            </>
          ) : null}

          {latestPeriod?.id && !isOnBoard ? (
            <ToggleLogsTimelineDrawerButton className="mx-1" onClick={logsDrawer.open} />
          ) : null}

          {hasPeriods && (isForReview || isApproved) && !isPastMga ? (
            <OfficeMoreActions isForReview={isForReview} />
          ) : null}
        </div>
      )}

      {latestPeriod && !isPastMga && isOnBoard ? (
        <>
          <Seperator />
          <Chatbox
            getMessages={getMessagesAction}
            sendMessage={postMessageAction}
            getUnreadMessages={getUnreadMessages}
            markMessagesAsRead={markAsReadAction}
            drawer={chatDrawer}
            size={'sm'}
            fetchOnMount={false}
          />
        </>
      ) : null}

      <ActionForm key={currencies?.length} hasExtraCurrencies={!!currencies?.length} />
      <SetupForexRatesForm drawer={setupForexRatesDrawer} />

      {latestPeriod && !isOnBoard ? (
        <LogsTimelineDrawer
          drawer={logsDrawer}
          entityId={latestPeriod.id}
          entityType={LOGS_ENTITY_TYPES.mga_period}
        />
      ) : null}
    </>
  );
};

export default Actions;
