import BasicModal from 'common/components/modals/BasicModal';
import { useEffect, useState, useCallback } from 'react';

import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { useForm } from 'utils/hooks';

import * as mgaActions from 'common/components/mga/store/actions';

import { useSelector } from 'react-redux';
import {
  selectPeriodModalFormIsOpen,
  selectPeriodFormActive,
  selectPeriods,
  selectVesselFilter
} from 'common/components/mga/store/selectors';

import Body from './form/Body';
import config from './form/config';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import moment from 'moment';

const PeriodModal = () => {
  const [setMgaPeriodForm, createMgaPeriod, editMgaPeriod] = useActions([
    mgaActions.setMgaPeriodForm,
    mgaActions.createMgaPeriod,
    mgaActions.editMgaPeriod
  ]);
  const [isSaving, setIsSaving] = useState(false);
  const [disabledStartedAt, setDisabledStartedAt] = useState(false);

  const { formState, collectValues, loadValues, resetForm, handleSubmitError } = useForm(
    config,
    {}
  );
  const isOpen = useSelector(selectPeriodModalFormIsOpen);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const vesselFilter = useSelector(selectVesselFilter);
  const active = useSelector(selectPeriodFormActive);
  const periods = useSelector(selectPeriods);

  const closeForm = useCallback(() => setMgaPeriodForm({ isModalOpen: false, active: null }), []);

  const onSubmit = async () => {
    const values = collectValues();

    if (!values) return;

    setIsSaving(true);

    try {
      const { initial_balances, ...params } = values;
      const requestParams = { ...params };

      if (!isOnBoard && vesselFilter) requestParams.vessel_id = vesselFilter;

      if (active) {
        await editMgaPeriod({ ...requestParams, id: active.id });
      } else {
        await createMgaPeriod(requestParams);
      }

      closeForm();
    } catch (e) {
      handleSubmitError(e);
    }

    setIsSaving(false);
  };

  const initForm = () => {
    if (periods?.length) {
      setDisabledStartedAt(true);

      loadValues({ started_at: moment(periods[0].ended_at).add(1, 'day'), ended_at: null });
    } else {
      setDisabledStartedAt(false);

      resetForm();
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (active) {
        loadValues(selectDataFromState({ ...active, initial_balances: [] }, config));
        setDisabledStartedAt(true);
      } else {
        initForm();
      }
    }
  }, [isOpen, active?.id]);

  return (
    <BasicModal
      isOpen={isOpen}
      toggle={closeForm}
      header={active ? 'Edit Draft MGA Period' : 'Create new MGA Period'}
      body={
        <Body
          disabledStartedAt={disabledStartedAt}
          className="justify-content-center"
          formState={formState}
        />
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button color="cancel" className="px-0 py-1 me-4" onClick={closeForm} disabled={isSaving}>
            CANCEL
          </Button>
          <Button onClick={onSubmit} disabled={isSaving} color="primary" className="px-4">
            SAVE
          </Button>
        </div>
      }
    />
  );
};

export default PeriodModal;
