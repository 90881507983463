import NumberField from 'captain-reports/templates/inputs/NumberField';
import CheckboxInput from 'captain-reports/templates/inputs/CheckboxInput';
import TextField from 'captain-reports/templates/inputs/TextField';
import { autocompleteGarbageTotalRob } from 'captain-reports/templates/config/_autocompleteHelpers';
import GarbageTotalRobField from 'captain-reports/templates/components/custom-fields/GarbageTotalRobField';
import SewageRobField from 'captain-reports/templates/components/custom-fields/SewageRobField';

const config = () => [
  {
    fields: [
      {
        label: 'Control Air Dryer No.1 on Operation',
        key: 'cad_no_1_in_operation',
        width: { xs: 5 },
        render: CheckboxInput
      },
      {
        label: 'Control Air Dryer No.2 on Operation',
        key: 'cad_no_2_in_operation',
        width: { xs: 5 },
        render: CheckboxInput
      }
    ]
  },
  {
    fields: [
      {
        label: 'AFT Bearing Temp',
        key: 'stern_tube_aft_shaft_bearing_temperature',
        subLabel: '°C',
        width: { xs: 'auto' },
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        render: NumberField,
        type: 'number'
      },
      {
        subGroup: 'intermediate_bearing',
        subGroupHeader: 'Intermediate Bearing Temperature',
        subGroupInline: true,
        subGroupLabel: '°C',
        subGroupWidth: '3',
        customSubGroupHeaderClassName: 'form-label border-bottom-0 text-nowrap',
        fields: [
          {
            label: 'Intermediate Bearing Temperature',
            key: 'stern_tube_intermediate_shaft_bearing_temperature',
            subLabel: '°C',
            className: 'cmt-4',
            width: { xs: '' },
            render: NumberField,
            type: 'number'
          }
        ]
      },
      {
        label: 'FWD Bearing Temp',
        key: 'stern_tube_fwd_shaft_bearing_temperature',
        subLabel: '°C',
        width: { xs: 'auto' },
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Condition',
        key: 'stern_tube_oil_condition',
        width: { xs: 'auto' },
        labelClassName: 'cmb-20',
        className: 'mt-auto',
        render: TextField
      },
      {
        label: 'Oil Added to Stern Tube System',
        subLabel: 'lt',
        key: 'stern_tube_oil_refilled',
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Leakage Onboard',
        key: 'leakage_onboard',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Bilge',
        key: 'oil_bilge_rob',
        sublabel: 'mt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Blind Flange Overboard Fit',
        key: 'blind_flange_overboard_fit',
        width: { xs: 4 },
        render: CheckboxInput,
        className: 'pt-1'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Settling Tank',
        key: 'settling_tank_rob',
        subLabel: 'lt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Hydrophore',
        key: 'hydrophore_rob',
        subLabel: 'lt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Aux. SO',
        subLabel: 'lt',
        key: 'aux_so_rob',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Sewage Rob',
        key: 'sewage_rob',
        width: { xs: 3 },
        render: SewageRobField,
        type: 'number'
      }
    ]
  }
];

const arrival = () => [
  {
    fields: [
      {
        label: 'Control Air Dryer No.1 on Operation',
        key: 'cad_no_1_in_operation',
        width: { xs: 5 },
        render: CheckboxInput
      },
      {
        label: 'Control Air Dryer No.2 on Operation',
        key: 'cad_no_2_in_operation',
        width: { xs: 5 },
        render: CheckboxInput
      }
    ]
  },
  {
    fields: [
      {
        label: 'AFT Bearing Temp',
        key: 'stern_tube_aft_shaft_bearing_temperature',
        subLabel: '°C',
        width: { xs: 'auto' },
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        render: NumberField,
        type: 'number'
      },
      {
        subGroup: 'intermediate_bearing',
        subGroupHeader: 'Intermediate Bearing Temperature',
        subGroupInline: true,
        subGroupLabel: '°C',
        subGroupWidth: '3',
        customSubGroupHeaderClassName: 'form-label border-bottom-0 text-nowrap',
        fields: [
          {
            label: 'Intermediate Bearing Temperature',
            key: 'stern_tube_intermediate_shaft_bearing_temperature',
            subLabel: '°C',
            className: 'cmt-4',
            width: { xs: '' },
            render: NumberField,
            type: 'number'
          }
        ]
      },
      {
        label: 'FWD Bearing Temp',
        key: 'stern_tube_fwd_shaft_bearing_temperature',
        subLabel: '°C',
        width: { xs: 'auto' },
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Condition',
        key: 'stern_tube_oil_condition',
        width: { xs: 'auto' },
        labelClassName: 'cmb-20',
        className: 'mt-auto',
        render: TextField
      },
      {
        label: 'Oil Added to Stern Tube System',
        subLabel: 'lt',
        key: 'stern_tube_oil_refilled',
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      }
    ]
  },

  {
    fields: [
      {
        label: 'Leakage Onboard',
        key: 'leakage_onboard',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Bilge',
        key: 'oil_bilge_rob',
        sublabel: 'mt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Blind Flange Overboard Fit',
        key: 'blind_flange_overboard_fit',
        width: { xs: 4 },
        render: CheckboxInput,
        className: 'pt-1'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Settling Tank',
        key: 'settling_tank_rob',
        subLabel: 'lt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Hydrophore',
        key: 'hydrophore_rob',
        subLabel: 'lt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Aux. SO',
        subLabel: 'lt',
        key: 'aux_so_rob',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Sewage Rob',
        key: 'sewage_rob',
        width: { xs: 3 },
        render: SewageRobField,
        type: 'number'
      }
    ]
  },
  {
    header: (
      <div>
        GARBAGE (m<sup>3</sup>)
      </div>
    ),
    headerClassName: 'border-bottom-grey',
    fields: [
      {
        label: 'Delivered ashore',
        key: 'garbage_delivered_ashore',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Cat A Plastics Rob',
        key: 'garbage_rob_plastic',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat B Food Wastes Rob',
        key: 'garbage_rob_food_wastes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat C Domestic Rob',
        key: 'garbage_rob_domestic_wastes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat D Cooking Oil Rob',
        key: 'garbage_rob_cooking_oil',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat E Incinerator Ashes Rob',
        key: 'garbage_rob_incinerator_ashes',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat F Operational Wastes Rob',
        key: 'garbage_rob_operational_wastes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat G Cargo Residues Rob',
        key: 'garbage_rob_cargo_residues',
        className: 'text-nowrap mb-0',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total Rob',
        key: 'garbage_rob_total',
        className: 'ps-2',
        autocompleteValues: [
          { key: 'garbage_rob_food_wastes' },
          { key: 'garbage_rob_plastic' },
          { key: 'garbage_rob_domestic_wastes' },
          { key: 'garbage_rob_cooking_oil' },
          { key: 'garbage_rob_incinerator_ashes' },
          { key: 'garbage_rob_operational_wastes' },
          { key: 'garbage_rob_cargo_residues' }
        ],
        autocomplete: autocompleteGarbageTotalRob,
        width: { xs: 2 },
        render: GarbageTotalRobField,
        type: 'number'
      }
    ]
  }
];

const departure = () => [
  {
    fields: [
      {
        label: 'Control Air Dryer No.1 on Operation',
        key: 'cad_no_1_in_operation',
        width: { xs: 5 },
        render: CheckboxInput
      },
      {
        label: 'Control Air Dryer No.2 on Operation',
        key: 'cad_no_2_in_operation',
        width: { xs: 5 },
        render: CheckboxInput
      }
    ]
  },
  {
    fields: [
      {
        label: 'AFT Bearing Temp',
        key: 'stern_tube_aft_shaft_bearing_temperature',
        subLabel: '°C',
        width: { xs: 'auto' },
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        render: NumberField,
        type: 'number'
      },
      {
        subGroup: 'intermediate_bearing',
        subGroupHeader: 'Intermediate Bearing Temperature',
        subGroupInline: true,
        subGroupLabel: '°C',
        subGroupWidth: '3',
        customSubGroupHeaderClassName: 'form-label border-bottom-0 text-nowrap',
        fields: [
          {
            label: 'Intermediate Bearing Temperature',
            key: 'stern_tube_intermediate_shaft_bearing_temperature',
            subLabel: '°C',
            className: 'cmt-4',
            width: { xs: '' },
            render: NumberField,
            type: 'number'
          }
        ]
      },
      {
        label: 'FWD Bearing Temp',
        key: 'stern_tube_fwd_shaft_bearing_temperature',
        subLabel: '°C',
        width: { xs: 'auto' },
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Condition',
        key: 'stern_tube_oil_condition',
        width: { xs: 'auto' },
        labelClassName: 'cmb-20',
        className: 'mt-auto',
        render: TextField
      },
      {
        label: 'Oil Added to Stern Tube System',
        subLabel: 'lt',
        key: 'stern_tube_oil_refilled',
        className: 'mt-auto mt-1',
        labelClassName: 'cmb-20',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Leakage Onboard',
        key: 'leakage_onboard',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Bilge',
        key: 'oil_bilge_rob',
        sublabel: 'mt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Blind Flange Overboard Fit',
        key: 'blind_flange_overboard_fit',
        width: { xs: 4 },
        render: CheckboxInput,
        className: 'pt-1'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Settling Tank',
        key: 'settling_tank_rob',
        subLabel: 'lt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Hydrophore',
        key: 'hydrophore_rob',
        subLabel: 'lt',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Aux. SO',
        subLabel: 'lt',
        key: 'aux_so_rob',
        width: { xs: 3 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Sewage Rob',
        key: 'sewage_rob',
        width: { xs: 3 },
        render: SewageRobField,
        type: 'number'
      }
    ]
  },
  {
    header: (
      <div>
        GARBAGE (m<sup>3</sup>)
      </div>
    ),
    headerClassName: 'border-bottom-grey',
    fields: [
      {
        label: 'Delivered ashore',
        key: 'garbage_delivered_ashore',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fields: [
      {
        label: 'Cat A Plastics Rob',
        key: 'garbage_rob_plastic',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat B Food Wastes Rob',
        key: 'garbage_rob_food_wastes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat C Domestic Rob',
        key: 'garbage_rob_domestic_wastes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat D Cooking Oil Rob',
        key: 'garbage_rob_cooking_oil',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat E Incinerator Ashes Rob',
        key: 'garbage_rob_incinerator_ashes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat F Operational Wastes Rob',
        key: 'garbage_rob_operational_wastes',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Cat G Cargo Residues Rob',
        key: 'garbage_rob_cargo_residues',
        className: 'text-nowrap mb-0',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total Rob',
        key: 'garbage_rob_total',
        className: 'ps-2',
        autocompleteValues: [
          { key: 'garbage_rob_food_wastes' },
          { key: 'garbage_rob_plastic' },
          { key: 'garbage_rob_domestic_wastes' },
          { key: 'garbage_rob_cooking_oil' },
          { key: 'garbage_rob_incinerator_ashes' },
          { key: 'garbage_rob_operational_wastes' },
          { key: 'garbage_rob_cargo_residues' }
        ],
        autocomplete: autocompleteGarbageTotalRob,
        width: { xs: 2 },
        render: GarbageTotalRobField,
        type: 'number'
      }
    ]
  }
];

const otherInfoConfig = {
  noon: data => config(data),
  arrival: data => arrival(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => departure(data),
  port_noon: data => config(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default otherInfoConfig;
