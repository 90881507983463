import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModuleWrapper from '../components/ModuleWrapper';
import CircledButton from 'common/components/buttons/CircledButton';

import { selectEventId } from 'events/store/events/selectors';

import Loader from './Loader';

import Form from 'events/event/modules/module-components/crew-medical-case/form/index';
import Preview from 'events/event/modules/module-components/crew-medical-case/form/preview/index';
import { getCrewMedicalCases } from 'events/store/event-modules/crew-medical-cases/actions';
import { setActiveMedicalCase } from 'events/store/event-modules/crew-medical-cases/slice';
import {
  selectCrewActiveMedicalCase,
  selectIsMedicalCaseLocked,
  selectMedicalCasesData
} from 'events/store/event-modules/crew-medical-cases/selectors';

const CrewMedicalCase = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();

  const activeMedicalCase = useSelector(selectCrewActiveMedicalCase);
  const eventId = useSelector(selectEventId);
  const medicalCases = useSelector(selectMedicalCasesData);
  const isLocked = useSelector(selectIsMedicalCaseLocked);

  const closeForm = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const onInit = async () => {
      if (eventId) {
        try {
          setIsLoading(true);

          await dispatch(getCrewMedicalCases({ eventId: eventId })).unwrap();

          setIsLoading(false);
        } catch (e) {
          console.error(e);
        }
      }
    };

    onInit();
  }, [dispatch, eventId]);

  return (
    <ModuleWrapper className="event-medical-crew-cases" type="crew_medical_cases">
      {isLoading ? (
        <div className="w-100p h-100p d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      ) : eventId ? (
        <div
          className={`event-medical-crew-cases__container position-relative text-primary fs-12 `}
        >
          {medicalCases?.length
            ? medicalCases.map(med => (
                <Preview
                  key={med.id}
                  medicalCase={med}
                  closeForm={() => dispatch(setActiveMedicalCase(null))}
                  isLoading={isLoading}
                  activeMedicalCase={activeMedicalCase}
                />
              ))
            : null}

          {showForm && !activeMedicalCase ? (
            <Form closeForm={closeForm} isLoading={isLoading} />
          ) : null}
        </div>
      ) : null}

      {!isLocked && (
        <CircledButton
          type="add"
          label="Add Medical Case"
          className="text-primary fw-bold mt-1 fs-12"
          style={{ width: 20, height: 20 }}
          svgStyle={{ width: 8, height: 8 }}
          onClick={openForm}
          disabled={showForm || activeMedicalCase}
        />
      )}
    </ModuleWrapper>
  );
};

export default CrewMedicalCase;
