import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, put, post } from 'utils/api';

export const getVesselNotes = createAsyncThunk(
  'GET_VESSEL_NOTES',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`/events/${id}/vessel-notes`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createVesselNotes = createAsyncThunk(
  'CREATE_VESSEL_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { id, ...rest } = params;

    try {
      const res = await post(`/events/${id}/vessel-notes`, rest);

      return fulfillWithValue(res.data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editVesselNotes = createAsyncThunk(
  'EDIT_VESSEL_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { id, isTemplate, ...rest } = params;

    try {
      const res = await put(`/events/${id}/vessel-notes`, rest);

      return fulfillWithValue(res.data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
