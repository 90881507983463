import {
  PurchasingRequisitionItemCommentsQueryParams,
  GetItemDetailsParams
} from '@/common/types/purchasing';
import { useQuery } from '@tanstack/react-query';
import {
  comparePurchasingRequisitionItems,
  ComparePurchasingRequisitionItemsPerams,
  getPurchasingRequisitionAccountBudgets,
  GetPurchasingRequisitionAccountBudgetsParams,
  getPurchasingRequisitionItemComments,
  getPurchasingRequisitionItemsSupplierTotals,
  GetPurchasingRequisitionItemsSupplierTotalsParams,
  getStoreItemDetails,
  getSparePartItemDetails
} from './api';
import {
  PURCHASING_REQUISITION_ACCOUNT_BUDGETS,
  PURCHASING_REQUISITION_ITEM_COMMENTS,
  PURCHASING_REQUISITION_ITEMS_COMPARISON,
  PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS,
  PURCHASING_REQUISITION_STORE_ITEM_DETAILS,
  PURCHASING_REQUISITION_SPARE_PARTS_ITEM_DETAILS
} from './query-keys';
import queryClient from '@/utils/query';

/* Comments */
export const useGetPurchasingRequisitionItemComments = ({
  id
}: PurchasingRequisitionItemCommentsQueryParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_ITEM_COMMENTS],
    queryFn: () => getPurchasingRequisitionItemComments({ id }),
    enabled: !!id,
    retry: false
  });
};

export const useGetStoreItemDetails = ({ id }: GetItemDetailsParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_STORE_ITEM_DETAILS],
    queryFn: () => getStoreItemDetails({ id }),
    enabled: false,
    retry: false
  });
};

export const useGetSparePartsItemDetails = ({ id }: GetItemDetailsParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_SPARE_PARTS_ITEM_DETAILS],
    queryFn: () => getSparePartItemDetails({ id }),
    enabled: false,
    retry: false,
    select: data => {
      const {
        attachments,
        comments,
        description,
        part_no,
        part_position_no,
        drawing_no,
        vessel_system
      } = data;

      // How to select system & subsystem from the vessel_system
      // When we have parent_id then the system is the parent and the subsystem is the vessel_system
      // When we don't have parent_id then the system is the vessel_system and the subsystem is the children
      return {
        spare_part: { attachments, comments, description, part_no, part_position_no, drawing_no },
        system: vessel_system.parent_id ? vessel_system.parent : vessel_system,
        subsystem: vessel_system.parent_id
          ? vessel_system
          : vessel_system.children.length
          ? vessel_system.children[0]
          : null
      };
    }
  });
};
/* -- */

/* Comparison */
export const useComparePurchasingRequisitionItems = (
  params: ComparePurchasingRequisitionItemsPerams & {
    enabled: boolean;
    requisition_item_id?: number;
    requisition_supplier_id?: number | string;
  }
) => {
  const { enabled, requisition_item_id, requisition_supplier_id, id, ...rest } = params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_COMPARISON, id, rest],
    queryFn: () => comparePurchasingRequisitionItems({ id, ...rest }),
    enabled: false,
    retry: false,
    select: () => null
  });
};

export const recomparePurchasingRequisitionItems = (id: number) =>
  queryClient.invalidateQueries({ queryKey: [PURCHASING_REQUISITION_ITEMS_COMPARISON, id] });
/* -- */

/* Supplier Totals */
export const useGetPurchasingRequisitionItemsSupplierTotals = (
  params: GetPurchasingRequisitionItemsSupplierTotalsParams
) => {
  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS, params],
    queryFn: () => getPurchasingRequisitionItemsSupplierTotals(params),
    enabled: false,
    retry: false
  });
};

export const recalculatePurchasingRequisitionItemsSupplierTotals = () =>
  queryClient.invalidateQueries({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS]
  });
/* -- */

/* Account Bugdet */
export const useGetPurchasingRequisitionAccountBudgets = (
  params: GetPurchasingRequisitionAccountBudgetsParams
) => {
  const { id } = params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ACCOUNT_BUDGETS, id],
    queryFn: () => getPurchasingRequisitionAccountBudgets({ id }),
    enabled: false,
    retry: false
  });
};
/* -- */
