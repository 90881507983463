import { VesselDocumentType } from '@/common/types/vessel';

const TYPES = {
  CHANGE_VESSEL_DOCUMENT_DRAWER_STATUS: 'CHANGE_VESSEL_DOCUMENT_DRAWER_STATUS',
  GET_VESSEL_DOCUMENTS: 'GET_VESSEL_DOCUMENTS',
  CREATE_VESSEL_DOCUMENT: 'CREATE_VESSEL_DOCUMENT',
  EDIT_VESSEL_DOCUMENT: 'EDIT_VESSEL_DOCUMENT',
  RENEW_VESSEL_DOCUMENT: 'RENEW_VESSEL_DOCUMENT',
  ARCHIVE_VESSEL_DOCUMENT: 'ARCHIVE_VESSEL_DOCUMENT',
  GET_VESSEL_DOCUMENT_ATTACHMENTS: 'GET_VESSEL_DOCUMENT_ATTACHMENTS',
  EXPAND_ALL_VESSEL_DOCUMENT_PANELS: 'EXPAND_ALL_VESSEL_DOCUMENT_PANELS',
  SET_VESSEL_DOCUMENTS_SEARCH: 'SET_VESSEL_DOCUMENTS_SEARCH',
  GET_VESSEL_DOCUMENT_HISTORY: 'GET_VESSEL_DOCUMENT_HISTORY'
} as const;

export type DrawerType = 'renew' | 'edit' | 'create';

export type DrawerStatus = {
  drawerOpen: boolean;
  drawerType?: DrawerType;
  drawerActiveItem?: VesselDocumentType;
};

export type DocumentTypeState = {
  categories_count: number;
  id: number;
  type_name: string;
  template: string | null;
};

export default TYPES;
