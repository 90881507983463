import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { getAsyncOptions, refetchAsyncOptions } from 'utils/helpers';

const MentionInput = ({
  className,
  parentRef,
  value,
  onChange,
  placeholder = 'Add some comments',
  ...rest
}) => {
  const getOptions = async (search, cb) => {
    let result;
    if (search) {
      result = await getAsyncOptions(search, 'vessels-parties', { limit: 8 });
    } else {
      result = await refetchAsyncOptions('vessels-parties', { limit: 8 });
    }

    const data = result.map(el => ({
      id: `${el.type}---${el.id}`,
      display: `@${el.name}`
    }));
    cb(data);
  };

  const renderSuggestion = (entry, search, dis, index, focused) => {
    return (
      <div
        className={`mentions-input-suggestion ${
          focused ? 'mentions-input-suggestion-focused' : ''
        }`}
      >
        {entry.display.substring(1)}
      </div>
    );
  };

  return (
    <div>
      <MentionsInput
        allowSpaceInQuery={true}
        allowSuggestionsAboveCursor={true}
        // forceSuggestionsAboveCursor={true}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        suggestionsPortalHost={parentRef && parentRef.current ? parentRef.current : null}
        className={`mentions-input ${className ? className : ''} ${!value ? 'is-empty' : ''}`}
        {...rest}
      >
        <Mention
          trigger="@"
          renderSuggestion={renderSuggestion}
          appendSpaceOnAdd
          markup="!#___id__^^^__display__!#$"
          data={getOptions}
          className="display-mention"
        />
      </MentionsInput>
    </div>
  );
};

export default MentionInput;
