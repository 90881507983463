import TYPES from './types';
import PROFILE_TYPES from 'crew/store/profile/types';
import _orderBy from 'lodash/orderBy';

const INITIAL_STATE = {
  contracts: [],
  vesselContracts: [],
  loadingContracts: false,
  loadingPreviousContracts: false,
  activeContract: null,
  activePreviousContract: null,
  previousContracts: [],
  contractForEdit: {},
  activeRotations: {
    onSignerRotations: [],
    offSignerRotations: [],
    isFetching: false
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_ACTIVE_CONTRACT:
      return {
        ...state,
        activeContract: payload
      };

    case TYPES.GET_CREW_CONTRACT.SUCCESS:
      return {
        ...state,
        contractForEdit: payload
      };

    case TYPES.SET_ACTIVE_PREVIOUS_CONTRACT:
      return {
        ...state,
        activePreviousContract: payload
      };

    case TYPES.GET_CREW_CONTRACTS.START:
      return {
        ...state,
        loadingContracts: true
      };

    case TYPES.GET_CREW_CONTRACTS.SUCCESS:
      return {
        ...state,
        contracts: payload,
        loadingContracts: false
      };

    case TYPES.GET_CREW_CONTRACTS.ERROR:
      return {
        ...state,
        contracts: [],
        loadingContracts: false
      };

    // For onboard
    case TYPES.GET_VESSEL_CONTRACTS.START:
    case TYPES.GET_VESSEL_CONTRACTS.ERROR:
      return {
        vesselContracts: []
      };

    case TYPES.GET_VESSEL_CONTRACTS.SUCCESS:
      return {
        vesselContracts: payload
      };

    case TYPES.GET_CREW_PREVIOUS_CONTRACTS.START:
      return {
        ...state,
        previousContracts: [],
        activePreviousContract: null,
        loadingPreviousContracts: true
      };

    case TYPES.GET_CREW_PREVIOUS_CONTRACTS.SUCCESS:
      return {
        ...state,
        previousContracts: _orderBy(payload, ['to_date'], ['desc']),
        loadingPreviousContracts: false
      };

    case TYPES.GET_CREW_PREVIOUS_CONTRACTS.ERROR:
      return {
        ...state,
        previousContracts: [],
        activePreviousContract: null,
        loadingPreviousContracts: false
      };

    case TYPES.ADD_NEW_PREVIOUS_CONTRACT:
      return {
        ...state,
        activePreviousContract: { id: 'new' },
        previousContracts: _orderBy(
          [
            ...(state.activePreviousContract && state.activePreviousContract.id === 'new'
              ? []
              : [{ id: 'new' }]),
            ...state.previousContracts
          ],
          ['to_date'],
          ['desc']
        )
      };

    case TYPES.REMOVE_NEW_PREVIOUS_CONTRACT:
      return {
        ...state,
        activePreviousContract: null,
        previousContracts: _orderBy(
          state.previousContracts.filter(prevContract => prevContract.id !== 'new'),
          ['to_date'],
          ['desc']
        )
      };

    case TYPES.UPDATE_CREW_PREVIOUS_CONTRACT.SUCCESS:
      return {
        ...state,
        activePreviousContract: null,
        previousContracts: _orderBy(
          state.previousContracts.map(prevContract =>
            prevContract.id !== payload.id ? prevContract : payload
          ),
          ['to_date'],
          ['desc']
        )
      };

    case TYPES.CREATE_CREW_CONTRACT.SUCCESS:
      return {
        ...state,
        contracts: _orderBy([payload, ...state.contracts], ['contract_from_date'], ['desc'])
      };

    case TYPES.CREATE_CREW_PREVIOUS_CONTRACT.SUCCESS:
      return {
        ...state,
        activePreviousContract: null,
        previousContracts: _orderBy(
          [payload, ...state.previousContracts.filter(prevContract => prevContract.id !== 'new')],
          ['to_date'],
          ['desc']
        )
      };

    case TYPES.CREATE_CREW_PREVIOUS_CONTRACT.ERROR:
      return {
        ...state,
        activePreviousContract: null,
        previousContracts: _orderBy(
          state.previousContracts.filter(prevContract => prevContract.id !== 'new'),
          ['to_date'],
          ['desc']
        )
      };

    case TYPES.UPDATE_CREW_CONTRACT.SUCCESS:
      return {
        ...state,
        contracts: (state.contracts || []).map(d => (d.id === payload.id ? payload : d))
      };

    case TYPES.GET_CREW_ACTIVE_ROTATIONS.START:
      return {
        ...state,
        activeRotations: {
          ...state.activeRotations,
          isFetching: true
        }
      };

    case TYPES.GET_CREW_ACTIVE_ROTATIONS.SUCCESS:
      return {
        ...state,
        activeRotations: {
          ...state.activeRotations,
          onSignerRotations: payload.on_signer_rotations,
          offSignerRotations: payload.off_signer_rotations,
          isFetching: false
        }
      };

    case TYPES.GET_CREW_ACTIVE_ROTATIONS.ERROR:
      return {
        ...state,
        activeRotations: {
          ...state.activeRotations,
          isFetching: false
        }
      };

    case PROFILE_TYPES.GET_CREW.START:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
