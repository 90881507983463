import Header from './Header';
import Body from './Body';

const PmsJobsList = ({ components }) => {
  return (
    <div className="pms-jobs-view list">
      <Header />
      <Body components={components} />
    </div>
  );
};

export default PmsJobsList;
