import React from 'react';
import { useSelector } from 'react-redux';

import { selectFieldVisibility } from 'captain-reports/store/selectors';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import EngineRpm from 'captain-reports/templates/components/custom-fields/EngineRpm';

import { Row, Col } from 'reactstrap';

const MainEngineRevCounterInput = ({
  tooltip,
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const visible_streaming_time = useSelector(state =>
    selectFieldVisibility(state, 'steaming_time')
  );

  return (
    <Row className="align-items-center">
      <Col xs={visible_streaming_time ? 6 : 12}>
        <NumberField
          field={field}
          tooltip={tooltip}
          sectionLabel={sectionLabel}
          sectionActiveTab={sectionActiveTab}
          subGroup={subGroup}
          subGroupIndex={subGroupIndex}
        />
      </Col>
      {visible_streaming_time ? (
        <Col xs={6}>
          <EngineRpm
            tooltip={tooltip}
            sectionLabel={sectionLabel}
            sectionActiveTab={sectionActiveTab}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default MainEngineRevCounterInput;
