import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { getMinutesHoursOrDays } from 'common/utils/dates';
import { displayDate } from '@/ts-common/utils/dates.ts';

const Logs = ({ created_at, updated_at, created_by, updated_by }) => {
  return (
    <Row className="mb-5 fs-10 text-violet fw-medium">
      {created_by ? (
        <Col xs={12}>
          Created by {created_by?.full_name} at {displayDate(created_at) || '-'}{' '}
          <strong>| {getMinutesHoursOrDays(created_at)}</strong>
        </Col>
      ) : null}
      {updated_by ? (
        <Col xs={12}>
          Updated by {updated_by?.full_name} at {displayDate(updated_at) || '-'}{' '}
          <strong>| {getMinutesHoursOrDays(updated_at)}</strong>
        </Col>
      ) : null}
    </Row>
  );
};

Logs.propTypes = {
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  created_by: PropTypes.shape({
    full_name: PropTypes.string
  }),
  updated_by: PropTypes.shape({
    full_name: PropTypes.string
  })
};

export default Logs;
