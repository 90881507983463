import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { moduleIcons } from 'events/event/modules/config';
import EVENT_TYPES from 'events/store/events/types';
import _reduce from 'lodash/reduce';

const INITIAL_STATE = _reduce(
  moduleIcons,
  (prev, value, key) => {
    if (!value.onboard) return prev;

    prev[key] = true;
    return prev;
  },
  {}
);

const slice = createSlice({
  name: 'syncedModules',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(EVENT_TYPES.UPDATE_MODULE.SUCCESS, (state, { payload }) => {
        const { type, sync_to_onboard } = payload;

        state[type.label] = sync_to_onboard;

        return state;
      })

      .addCase(LOCATION_CHANGE, () => {
        return INITIAL_STATE;
      })

      .addMatcher(
        isAnyOf(EVENT_TYPES.GET_EVENT.SUCCESS, EVENT_TYPES.EDIT_EVENT.SUCCESS),
        (state, { payload }) => {
          const { modules } = payload;

          modules.forEach(module => {
            const label = module.type.label;

            if (label) {
              state[label] = module.sync_to_onboard;
            }
          });

          return state;
        }
      );
  }
});

export default slice.reducer;
