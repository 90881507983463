import React from 'react';
import CircledButton from 'common/components/buttons/CircledButton';

const InputSaveActions = ({ onCancel, onSave, disabled }) => {
  return (
    <div className="input-save-actions d-flex align-items-center justify-content-end">
      <CircledButton type="close" className="me-1" onClick={onCancel} />
      <CircledButton type="save" disabled={disabled} onClick={onSave} />
    </div>
  );
};

export default InputSaveActions;
