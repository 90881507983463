import React from 'react';
import PropTypes from 'prop-types';

const TruncatedTextMultilineContent = ({ content, className }) => {
  return <div className={`truncated-text-multilines flex-1 ${className}`}>{content}</div>;
};

TruncatedTextMultilineContent.propTypes = {
  content: PropTypes.string.isRequired
};

export default TruncatedTextMultilineContent;
