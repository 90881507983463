import EditorField from 'captain-reports/templates/inputs/EditorField';

const remarks = () => [
  {
    fields: [
      {
        label: 'Comments',
        subLabel: '°C',
        key: 'comments',
        width: { xs: '' },
        render: EditorField
      }
    ]
  }
];

const remarksConfig = {
  noon: () => remarks(),
  arrival: () => remarks(),
  ballast: () => [],
  delivery: () => remarks(),
  redelivery: () => remarks(),
  departure: () => remarks(),
  port_noon: () => remarks(),
  stoppage: () => remarks(),
  instructed_speed: () => remarks(),
  passage_plan: () => []
};

export default remarksConfig;
