import { useCallback } from 'react';
import useMountRef from './useMountRef';

export default () => {
  const isMounted = useMountRef();

  return useCallback(
    (promise, onCancel) =>
      promise
        ? new Promise((resolve, reject) => {
            promise
              .then(result => {
                if (isMounted()) {
                  resolve(result);
                }
              })
              .catch(error => {
                if (isMounted()) {
                  reject(error);
                }
              })
              .finally(() => {
                if (!isMounted() && onCancel) {
                  onCancel();
                }
              });
          })
        : null,
    [isMounted]
  );
};
