import React from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import jobsIcon from 'common/assets/svg/common/jobs.svg';
import pmsIcon from 'common/assets/svg/common/setup.svg';
import clock from 'common/assets/svg/common/clock-line.svg';
import flagIcon from 'common/assets/svg/common/flag.svg';
import orcaEnumIcon from 'common/assets/svg/common/orca-enums.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Tooltip from 'common/components/general/Tooltip';
import { jobEnums } from 'common/utils/fixed';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const config = {
  [jobEnums.unscheduled]: { title: 'Unplanned maintencance job', icon: clock },
  [jobEnums.pms]: { title: 'PMS job', icon: pmsIcon },
  [jobEnums.regular]: { title: 'Regular job', icon: jobsIcon },
  [jobEnums.flag_dispensation]: { title: 'Flag Dispensation job', icon: flagIcon },
  [jobEnums.orca_jobs]: { title: 'Orca job', icon: orcaEnumIcon }
};

const PreviewJobType = ({
  type = '',
  iconClassName = '',
  iconStyles = { width: 14, height: 14 },
  showTitle = true,
  className = showTitle ? 'fs-10 ms-2 text-violet pe-2' : 'ms-2'
}) => {
  const { tooltipID, avoidRender } = useTooltipID('job-type');

  return (
    <>
      {type ? (
        <div className={`fw-normal d-flex align-items-center ${className}`} id={tooltipID}>
          <SvgRender
            className={`${iconClassName} align-self-center`}
            src={config[type]?.icon}
            style={iconStyles}
          />
          {showTitle ? (
            <TextWithTooltip className="h-auto ms-1">{config[type]?.title}</TextWithTooltip>
          ) : avoidRender ? null : (
            <Tooltip target={tooltipID}>{config[type]?.title}</Tooltip>
          )}
        </div>
      ) : null}
    </>
  );
};

PreviewJobType.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconStyles: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  showTitle: PropTypes.bool
};

export default PreviewJobType;
