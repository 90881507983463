const DatePlaceholder = ({ placeholder, value, hasTime, isRange, dateRange }) => {
  const getText = () => {
    const dateFormat = `DD/MM/YYYY${hasTime ? ' HH:mm' : ''}`;

    if (isRange) {
      return dateRange.starts.value && dateRange.ends.value
        ? `${dateRange.starts.value.format(dateFormat)} - ${dateRange.ends.value.format(
            dateFormat
          )}`
        : null;
    } else {
      return value ? value.format(dateFormat) : null;
    }
  };

  const text = getText();

  return (
    <div className={`date-input__placeholder text-truncate ${!!text ? 'has-value' : ''}`}>
      {text || placeholder}
    </div>
  );
};

export default DatePlaceholder;
