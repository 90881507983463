import { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { numberToStr, strToNumber } from 'common/utils/numbers';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import ShadowBox from 'common/components/general/ShadowBox';
import WageContainerHeader from '../../components/WageContainerHeader';
import { getEquals } from './helpers';
import styled from '@emotion/styled';
import { selectListOptionsFromStore } from '@/store/lists/selectors';

const WageContainer = ({
  wageSettings,
  currencyId,
  defaultCrewWage,
  wage_account_amounts,
  label
}) => {
  const amounts = getEquals(wage_account_amounts, wageSettings);
  const currencies = useSelector(state => selectListOptionsFromStore(state, 'currencies'));
  const selectCurrency = useMemo(
    () => currencies.find(currency => strToNumber(currency?.id) === strToNumber(currencyId)),
    [currencies, currencyId]
  );

  return (
    <ShadowBox className="w-100p h-100p d-flex flex-column mb-1 p-1" color="light-1" flat>
      <WageContainerHeader
        className="cmb-12"
        label={label}
        currencyId={currencyId}
        amounts={amounts}
      />
      <Row className="w-100p gx-1 gy-2">
        {wageSettings?.map((setting, index) => {
          const defaultAccount = (defaultCrewWage?.account_amounts || []).find(
            defaultValue => setting.id === defaultValue.id
          );
          const currentAccount = wage_account_amounts?.find(
            accountAmout => accountAmout.id === setting.id
          );

          const defaultAmount = _get(defaultAccount, 'pivot.amount');
          const currentAmount = _get(currentAccount, 'pivot.amount');
          const currentName = _get(setting, 'name');
          const currentCode = _get(setting, 'code');

          if (!setting.is_set_on_crew_contract && !(currentAmount || currentAmount === 0))
            return null;

          return (
            <WageFieldCol xs="auto" key={index} className="d-flex flex-column pe-1">
              <div>
                <TextWithTooltip className="text-primary fs-10 fw-normal">
                  {currentCode}. {currentName}
                </TextWithTooltip>
              </div>

              <div className="fw-bold d-flex align-items-center text-primary">
                <div className="flex-1">{selectCurrency?.symbol || '$'}</div>
                <div>{numberToStr(currentAmount, 2, 2)}</div>
              </div>

              {defaultAmount && defaultAmount !== currentAmount ? (
                <div className="d-flex align-items-center w-100p text-warning fs-10 fw-bold">
                  <div className="flex-1">{selectCurrency?.symbol || '$'}</div>
                  <div>{numberToStr(defaultAmount, 2, 2)}</div>
                </div>
              ) : null}
            </WageFieldCol>
          );
        })}
      </Row>
    </ShadowBox>
  );
};

const WageFieldCol = styled(Col)`
  width: 11.11%; // Fit 9 columns per row
`;

export default WageContainer;
