import { entityTypePropTypes } from 'common/components/chatbox/_helpers';

import { captainReportIcon } from 'captain-reports/utils/helpers';
import SvgRender from 'common/components/general/SvgRender';

import forms from 'common/assets/svg/common/forms.svg';
import jobs from 'common/assets/svg/common/jobs.svg';
import pms from 'common/assets/svg/common/setup.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';

const ChatEntityIcon = ({ entityType, reportTemplateType, jobType }) => {
  const icon =
    entityType === 'report'
      ? captainReportIcon?.[reportTemplateType]
      : entityType === 'job'
      ? jobType === 'pms'
        ? pms
        : jobs
      : entityType === 'purchasing_requisition'
      ? purchasing
      : forms;

  return icon ? (
    <div className="chat-entity-icon">
      <SvgRender
        className="text-purple justify-content-center"
        src={icon}
        style={{ width: '70%', height: '100%' }}
      />
    </div>
  ) : null;
};

ChatEntityIcon.propTypes = {
  entityType: entityTypePropTypes.isRequired,
  reportTemplateType: function (props, propName) {
    if (
      props['entityType'] === 'report' &&
      (!props[propName] || typeof props[propName] != 'string')
    ) {
      return new Error('Please provide the TYPE of the Report Template');
    }
  }
};

export default ChatEntityIcon;
