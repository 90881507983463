import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('CREATE_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('UPDATE_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('GET_CREW_EVALUATION_TEMPLATES'),
  ...asyncTypes('GET_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('DELETE_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('REORDER_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('ARCHIVE_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('UNARCHIVE_CREW_EVALUATION_TEMPLATE'),
  ...asyncTypes('CLONE_CREW_EVALUATION_TEMPLATE'),
  CLEAR_CREW_EVALUATION_TEMPLATE: 'CLEAR_CREW_EVALUATION_TEMPLATE'
};

export default TYPES;
