import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { formatDate } from 'cleave-zen';

const YearInput = ({ value, onChange, disabled }) => {
  const [year, setYear] = useState('');

  useEffect(() => {
    if (value !== year) {
      setYear(value);
    }
  }, [value]);

  useUpdateEffect(() => {
    if (
      value !== year &&
      year?.length === 4 &&
      moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY', true).year(year).isValid()
    ) {
      onChange(year);
    }
  }, [year]);

  return (
    <div className={`datetime-inputs d-flex align-items-center${!value ? ' empty' : ''}`}>
      <input
        className="form-control form-field form-field__year"
        value={year}
        placeholder={'yyyy'}
        onChange={e => {
          const yearValue = formatDate(e.target.value, { datePattern: ['Y'] });
          setYear(yearValue);
        }}
        disabled={disabled}
        data-cy="date-input--year"
      ></input>
    </div>
  );
};

export default YearInput;

YearInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
