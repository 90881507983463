import { useSelector } from 'react-redux';

import SvgRender from 'common/components/general/SvgRender';
import solidRibbon from 'common/assets/svg/common/ribbon-solid.svg';
import { selectCurrentVersionStatus, selectIsComparedAtVersion } from 'manuals/store/selectors';

import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const config = [
  {
    title: 'Change',
    color: variables.butterYellow,
    visible: ['working', 'pending_approval', 'compare_at']
  },
  {
    title: 'Addition',
    color: variables.turquoise,
    visible: ['working', 'pending_approval', 'compare_at']
  },
  {
    title: 'Deletion',
    color: variables.coral,
    visible: ['working', 'pending_approval', 'compare_at']
  },
  {
    title: 'Draft',
    color: variables.violet,
    visible: ['working', 'pending_approval', 'compare_at']
  },
  {
    title: 'Contains Post',
    color: variables.butterYellow,
    icon: solidRibbon,
    visible: ['all']
  }
];

const Legends = () => {
  let currentVersionStatus = useSelector(selectCurrentVersionStatus);
  const isComparedAtVersion = useSelector(selectIsComparedAtVersion);
  const status = isComparedAtVersion ? 'compare_at' : currentVersionStatus;

  return (
    <div className="chapters-tree__footer-legends d-flex align-items-center w-100p">
      {config.map((item, index) =>
        item?.visible?.find(e => e === status || e === 'all') ? (
          <div className="d-flex align-items-center me-1 me-mdlaptop-2 me-hd-4" key={index}>
            {item?.icon ? (
              <SvgRender src={item?.icon} style={{ height: 12, width: 12, color: item?.color }} />
            ) : (
              <div className="legends-circle" style={{ backgroundColor: item?.color }}></div>
            )}
            <div className="legends-title fs-10 text-primary text-nowrap">{item?.title || '-'}</div>
          </div>
        ) : null
      )}
    </div>
  );
};

export default Legends;
