import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import SvgRender from 'common/components/general/SvgRender';

import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

const LinkTo = ({ onClick, size = 'sm', disabled, to }) => {
  const sizeProps = {
    sm: { className: 'width-16 height-16', iconStyle: { width: 10, height: 10 } },
    md: { className: 'width-24 height-24', iconStyle: { width: 12, height: 12 } }
  };

  const Tag = to ? NavLink : Button;
  const props = to
    ? { to, target: '_blank' }
    : {
        color: 'primary',
        size,
        onClick,
        disabled: disabled
      };

  return (
    <Tag
      className={`btn btn-primary d-inline-flex align-items-center justify-content-center text-white rounded-circle p-0 ${sizeProps[size].className}`}
      {...props}
    >
      <SvgRender src={arrow} style={{ ...sizeProps[size].iconStyle, transform: 'rotate(90deg)' }} />
    </Tag>
  );
};

LinkTo.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  disabled: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default LinkTo;
