import moment from 'moment';

export const getProfileAge = birthday => {
  if (!birthday) return null;

  const years = moment().year();
  const personYears = moment(birthday).year();

  return years > personYears ? years - personYears : 0;
};
