import Information from 'common/components/general/Information';
import React from 'react';
import PropTypes from 'prop-types';

const FormTypeInformationLabel = ({ tooltipMessage = '' }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="me-1">FORM TYPE</span>
      {tooltipMessage ? (
        <Information
          tooltipClassname="max-width-none text-start"
          message={tooltipMessage}
          svgStyle={{ height: 12, width: 12 }}
        />
      ) : null}
    </div>
  );
};

FormTypeInformationLabel.propTypes = {
  tooltipMessage: PropTypes.string
};

export default FormTypeInformationLabel;
