import { createSelector } from 'reselect';
import { findParentChapter, getParentChapterChildren } from '../_helpers';
import { selectAccountRank } from 'store/account/selectors';

const selectManualsReducer = state => state.manualInner;
const getKey = (_, key) => key;

// -- Manual is locked (onboard or non-working versions)

export const cannotEditManual = createSelector(
  selectManualsReducer,
  reducer => reducer.isEditLocked
);

export const isManualOnBoard = createSelector(selectManualsReducer, reducer => reducer.isOnBoard);

// -- Manual create/edit modal

export const isCreatingChapter = createSelector(
  selectManualsReducer,
  reducer => reducer.isCreatingChapter
);

// -- Manual's selectors

export const selectManual = createSelector(selectManualsReducer, reducer => reducer.data);
export const selectManualId = createSelector(selectManual, manual => manual.id);
export const selectManualTitle = createSelector(selectManual, manual => manual.title);
export const selectManualVessels = createSelector(selectManual, manual => manual.vessels || []);
export const selectManualVesselGroups = createSelector(
  selectManual,
  manual => manual.vessel_groups || []
);
export const selectManualVersions = createSelector(
  selectManualsReducer,
  reducer => reducer.versions || []
);

export const selectAcknowledgementRanks = createSelector(
  selectManualsReducer,
  reducer => reducer?.data?.acknowledgement_ranks || []
);

export const showAcknowledgementSubmitButtonBasedOnRank = createSelector(
  selectAcknowledgementRanks,
  selectAccountRank,
  (ranks, crewMemberRank) => !!ranks?.find(rank => rank?.id === crewMemberRank?.id)
);

// -- Chapter's tree related selectors
export const selectSortedChapters = createSelector(
  selectManualsReducer,
  reducer => reducer.sortedChapters
);

export const selectChaptersRootLevel = createSelector(
  selectManualsReducer,
  reducer => reducer.rootChapters || []
);

export const hasChapters = createSelector(selectChaptersRootLevel, root => root.length);

export const selectTheFirstChapterUid = createSelector(selectChaptersRootLevel, root =>
  root?.length ? root[0] : null
);

export const selectSubChapters = createSelector(
  selectManualsReducer,
  reducer => reducer.subChapters
);
export const selectChapterSubChapters = createSelector(
  selectSubChapters,
  getKey,
  (subChapters, uid) => subChapters[uid]
);
export const hasSubChapters = createSelector(
  selectSubChapters,
  subChapters => Object.keys(subChapters).length
);

export const selectChapters = createSelector(selectManualsReducer, reducer => reducer.chapters);
export const selectChapter = createSelector(
  selectChapters,
  getKey,
  (chapters, uid) => chapters[uid]
);

// -- Chapter's tree input enabled (editing a chapter's title)

export const selectChaptersIsEditting = createSelector(
  selectManualsReducer,
  reducer => reducer.isEditingChapter
);
export const isChapterInEditMode = createSelector(
  selectChaptersIsEditting,
  getKey,
  (isEditingChapter, uid) => isEditingChapter[uid]
);

// -- Adding a subchapter to a chapter (on tree)

export const selectAddSubchaptersTo = createSelector(
  selectManualsReducer,
  reducer => reducer.isAddingSubChapterTo
);
export const isAddingSubChapter = createSelector(
  selectAddSubchaptersTo,
  getKey,
  (isAddingSubChapterTo, uid) => isAddingSubChapterTo[uid]
);

// -- Collapsed/Expanded chapters in tree

export const selectExpandedChapters = createSelector(
  selectManualsReducer,
  reducer => reducer.isChapterExpanded
);
export const isChapterExpanded = createSelector(
  selectExpandedChapters,
  getKey,
  (isChapterExpanded, uid) => isChapterExpanded[uid] || false
);
export const areAllChaptersExpanded = createSelector(
  selectExpandedChapters,
  selectSubChapters,
  (isChapterExpanded, subChapters) =>
    Object.keys(isChapterExpanded).length === Object.keys(subChapters).length
);

export const selectMoveModalExpandedChapters = createSelector(
  selectManualsReducer,
  reducer => reducer.isMoveChapterExpanded
);
export const isMoveModalChapterExpanded = createSelector(
  selectMoveModalExpandedChapters,
  getKey,
  (isChapterExpanded, uid) => isChapterExpanded[uid] || false
);
// export const areAllMoveModalChaptersExpanded = createSelector(
//   selectMoveModalExpandedChapters,
//   selectSubChapters,
//   (isChapterExpanded, subChapters) =>
//     Object.keys(isChapterExpanded).length === Object.keys(subChapters).length
// );

// -- Chapter's deletion selectors

export const selectIsParentChapterDeleted = createSelector(
  selectChapters,
  getKey,
  (chapters, uid) => {
    const chapter = chapters[uid];

    if (chapter.parent_uid) {
      const parentUid = findParentChapter(chapter.parent_uid, chapters);

      return parentUid && chapters[parentUid].deleted;
    }

    return false;
  }
);

export const canDeleteChapter = createSelector(
  selectChapters,
  getKey,
  selectSubChapters,
  (chapters, uid, subChapters) => {
    const chapter = chapters[uid];
    const children = subChapters[uid];

    if (!chapter) return {};

    if (chapter.is_new && !children) {
      // new chapter without children, will delete everything without a deletion request
      return { valid: true, change_request: false };
    } else if (chapter.is_new && children && children.every(c => chapters[c].is_new)) {
      // new chapter with new children, will delete everything without a deletion request
      return { valid: true, change_request: false };
    } else if (!chapter.is_new && !children) {
      // old chapter without children, will create a deletion request
      return { valid: true, change_request: true };
    } else {
      // prevent deletion of parent chapters with subchapters containing change-requests, will create a deletion request
      return {
        valid: children.every(c => !chapters[c].open_crs && !chapters[c].approved_crs),
        change_request: true
      };
    }
  }
);

// -- Chapter's modal actions (move, rearrange, delete - src/manuals/manual-inner/chapters-tree/modal-actions)

export const selectChapterModalAction = createSelector(
  selectManualsReducer,
  reducer => reducer.chapterModalAction
);
export const selectChapterModalActionId = createSelector(
  selectChapterModalAction,
  chapterModalAction => chapterModalAction.id
);
export const selectChapterModalActionUid = createSelector(
  selectChapterModalAction,
  chapterModalAction => chapterModalAction.uid
);
export const selectChapterModalActionType = createSelector(
  selectChapterModalAction,
  chapterModalAction => chapterModalAction.type
);
export const selectChapterModalActionIsOpen = createSelector(
  selectChapterModalAction,
  chapterModalAction => chapterModalAction.isOpen
);

// -- Current version of the manual

export const selectCurrentVersion = createSelector(
  selectManualsReducer,
  reducer => reducer.currentVersion
);
export const selectCurrentVersionId = createSelector(selectCurrentVersion, currentVersion =>
  currentVersion ? currentVersion.id : null
);
export const selectCurrentVersionStatus = createSelector(selectCurrentVersion, currentVersion =>
  currentVersion ? currentVersion.status : null
);
export const selectCurrentVersionPdf = createSelector(selectCurrentVersion, currentVersion =>
  currentVersion.pdf ? currentVersion.pdf : null
);

// -- Chapters search in tree

export const selectChaptersInSearch = createSelector(
  [selectManualsReducer],
  reducer => reducer.chaptersInSearch
);
export const chaptersTreeHasSearch = createSelector([selectManualsReducer], reducer =>
  reducer.searchText ? true : false
);
export const isChapterInSearch = createSelector(
  selectChaptersInSearch,
  getKey,
  (chaptersInSearch, uid) => chaptersInSearch[uid]
);
export const selectChaptersTreeSearch = createSelector(
  [selectManualsReducer],
  reducer => reducer.searchText
);
export const hasChaptersTreeSearch = createSelector([selectManualsReducer], reducer =>
  reducer.searchText?.length > 0 ? true : false
);
export const selectChaptersInSearchLength = createSelector(
  [selectManualsReducer],
  reducer => Object.keys(reducer.chaptersInSearch).length
);
export const selectHighlightedChapters = createSelector(
  [selectManualsReducer],
  reducer => reducer.hightlightedChapters
);
export const isChapterHighlighted = createSelector(
  selectHighlightedChapters,
  getKey,
  (highlightedChapters, uid) => highlightedChapters[uid]
);

// -- Manual compare selectors

export const selectComparedAtVersion = createSelector(
  selectManualsReducer,
  reducer => reducer.comparedAtVersion
);
export const selectIsComparedAtVersion = createSelector(
  selectManualsReducer,
  reducer => !!reducer.comparedAtVersion?.id
);
export const selectComparedAtVersionId = createSelector(
  selectManualsReducer,
  reducer => reducer.comparedAtVersion?.id
);
export const selectComparedAtVersionChapterPostData = createSelector(
  selectManualsReducer,
  reducer => reducer.comparedAtVersionChapterPost
);

export const selectComparedAtVersionChapterPostContent = createSelector(
  selectManualsReducer,
  reducer => reducer.comparedAtVersionChapterPost?.post?.content || null
);

export const selectComparedChangesOnly = createSelector(
  selectManualsReducer,
  reducer => reducer.comparedChangesOnly
);

export const selectIsChapterInComparison = createSelector(
  selectChapters,
  getKey,
  selectSubChapters,
  selectComparedAtVersion,
  selectComparedChangesOnly,
  (chapters, uid, subChapters, comparedAtVersion, comparedChangesOnly) => {
    if (!comparedAtVersion || !comparedChangesOnly) {
      return false;
    } else {
      const chapter = chapters[uid];

      if (subChapters[chapter.uid]) {
        return getParentChapterChildren(chapter.uid, chapters, subChapters).some(t => {
          const treeChapter = chapters[t.uid];

          return (
            treeChapter.compare_has_changed ||
            treeChapter.compare_is_new ||
            treeChapter.compare_is_deleted
          );
        });
      } else {
        return chapter.compare_has_changed || chapter.compare_is_new || chapter.compare_is_deleted;
      }
    }
  }
);

// -- Active chapter (when chapter_uid is in URL - /manuals/$id/chapter/$chapter_uid)

export const selectActiveChapterId = createSelector(
  selectManualsReducer,
  reducer => reducer.activeChapterId
);
export const selectActiveChapterUid = createSelector(
  selectManualsReducer,
  reducer => reducer.activeChapterUid
);

export const selectActiveChapterBreadcrumbs = createSelector(
  selectManualsReducer,
  reducer => reducer.activeChapterBreadcrumbs
);

export const selectIsActiveChapterChapterDeleted = createSelector(
  selectChapters,
  selectActiveChapterUid,
  (chapters, activeChapterUid) =>
    activeChapterUid && chapters[activeChapterUid] && chapters[activeChapterUid].deleted
);

export const isChapterPostFormOpen = createSelector(
  selectManualsReducer,
  reducer => reducer.showPostForm
);

export const selectPreviousChapterUid = createSelector(
  selectSortedChapters,
  selectActiveChapterUid,
  (sortedChapters, activeChapterUid) => {
    const previousIndex = sortedChapters.indexOf(activeChapterUid) - 1;

    return previousIndex >= 0 ? sortedChapters[previousIndex] : null;
  }
);

export const selectNextChapterUid = createSelector(
  selectSortedChapters,
  selectActiveChapterUid,
  (sortedChapters, activeChapterUid) => {
    const nextIndex = sortedChapters.indexOf(activeChapterUid) + 1;

    return nextIndex <= sortedChapters.length - 1 ? sortedChapters[nextIndex] : null;
  }
);

// -- Active chapter's content

export const selectActiveChapterContent = createSelector(
  selectManualsReducer,
  reducer => reducer.activeChapterContent
);

export const selectActiveChapterContentHasPost = createSelector(
  selectActiveChapterContent,
  activeChapterContent => activeChapterContent && activeChapterContent.post
);

export const selectActiveChapterPostAttachments = createSelector(
  selectManualsReducer,
  reducer => reducer.activeChapterPostAttachments
);

// -- Active chapter's change request

export const selectActiveChapterChangeRequest = createSelector(
  selectActiveChapterContent,
  activeChapterContent =>
    activeChapterContent && activeChapterContent.change_request
      ? activeChapterContent.change_request
      : null
);

export const selectActiveChapterRelatedChapters = createSelector(
  selectActiveChapterChangeRequest,
  changeRequest => changeRequest && changeRequest.related_chapters
);

export const selectActiveChapterChangeRequestStatus = createSelector(
  selectActiveChapterChangeRequest,
  changeRequest => changeRequest && changeRequest.status
);

export const selectActiveChapterChangeRequestId = createSelector(
  selectActiveChapterChangeRequest,
  changeRequest => changeRequest && changeRequest.id
);

export const selectActiveChapterRelatedChaptersKeywords = createSelector(
  selectActiveChapterChangeRequest,
  changeRequest => changeRequest?.keywords || []
);

// -- Active chapter's post

export const selectPost = createSelector(selectActiveChapterContent, activeChapterContent =>
  activeChapterContent && activeChapterContent.post ? activeChapterContent.post : null
);
export const selectPostTitle = createSelector(selectPost, post => post && post.title);
export const selectPostContent = createSelector(selectPost, post => post && post.content);
export const selectPostPreviousContent = createSelector(
  selectPost,
  post => post && post.previous_content
);

export const selectPostForms = createSelector(selectActiveChapterContent, activeChapterContent =>
  activeChapterContent && activeChapterContent.forms ? activeChapterContent.forms : []
);

export const selectChapterSpecificVessels = createSelector(
  selectActiveChapterContent,
  activeChapterContent => (activeChapterContent ? activeChapterContent?.vessels || [] : [])
);
export const selectChapterSpecificVesselGroups = createSelector(
  selectActiveChapterContent,
  activeChapterContent => (activeChapterContent ? activeChapterContent?.vessel_groups || [] : [])
);

export const selectAttachments = createSelector(selectActiveChapterContent, activeChapterContent =>
  activeChapterContent && activeChapterContent.attachments
    ? activeChapterContent.attachments?.filter(a => !a.deleted)
    : []
);

// Navigation prevention modal selectors
export const selectNavigationPreventionModal = createSelector(
  selectManualsReducer,
  reducer => reducer.navigationPreventionModal
);

export const selectNavigationPreventionModalIsOpen = createSelector(
  selectNavigationPreventionModal,
  navigationPreventionModal => navigationPreventionModal.isOpen
);

export const selectNavigationPreventionModalChapterUid = createSelector(
  selectNavigationPreventionModal,
  navigationPreventionModal => navigationPreventionModal.chapterUid
);

// Related chapters modal selectors
export const selectRelatedChaptersModal = createSelector(
  selectManualsReducer,
  reducer => reducer.relatedChaptersModal
);

export const selectRelatedChaptersModalIsOpen = createSelector(
  selectRelatedChaptersModal,
  relatedChaptersModal => relatedChaptersModal.isOpen
);

export const selectRelatedChaptersModalChapter = createSelector(
  selectRelatedChaptersModal,
  relatedChaptersModal => relatedChaptersModal.chapter
);
