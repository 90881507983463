import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';

import Drawer, { DrawerHeader, FormDrawer, FormFooter, FormBody } from 'common/components/drawer';
import { useForm } from 'common/utils/hooks/useForm';
import { useFormState } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import SeveritySelector from 'common/components/selectors/SeveritySelector';
import VettingViqChapterSelector from 'common/components/selectors/VettingViqChapterSelector';
import VettingViqQuestionSelector from 'common/components/selectors/VettingViqQuestionSelector';
import Editor from 'common/components/form/inputs/Editor';
import ResolveActions from '../../../components/ResolveActions';

import { selectEventId } from 'events/store/events/selectors';
import { dateField, numberField, optionField, stringField } from 'common/utils/form/fieldTypes';
import {
  addEventVettingObservation,
  getEventVettingObservations,
  updateEventVettingObservation
} from 'events/store/event-modules/vetting/actions';
import LinkEntities from 'common/components/link-entities';

const config = {
  chapter: optionField({ required: true }),
  question: optionField({ required: true }),
  observation: stringField({ required: true }),
  company_response: stringField(),
  severity: numberField({ required: true }),
  resolved_at: dateField(),
  jobs: optionField()
};

const Form = ({ drawer, active }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { formState, collectValues, loadValues, resetForm, handleSubmitError } = useForm(
    config,
    {}
  );
  const { fields, selectField } = useFormState(formState);

  const eventId = useSelector(selectEventId);

  const onSubmit = async () => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSubmitting(true);

      const { chapter, question, resolved_at, jobs, ...rest } = values;

      const params = {
        event_id: eventId,
        chapter_id: chapter?.id,
        question_id: question?.id,
        resolved_at,
        job_ids: (jobs || []).map(job => job.id),
        ...rest
      };

      if (active)
        await dispatch(updateEventVettingObservation({ ...params, id: active?.id })).unwrap();
      else await dispatch(addEventVettingObservation(params)).unwrap();

      dispatch(getEventVettingObservations({ id: eventId })).unwrap();
      drawer.close();

      setIsSubmitting(false);
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (drawer?.isOpen && active) {
      loadValues(active);
    } else {
      resetForm();
    }
  }, [active, drawer?.isOpen, loadValues, resetForm]);

  return (
    <Drawer className="vetting-observation-drawer" size="sm-md" {...drawer}>
      <DrawerHeader>{active ? 'Edit' : 'Add'} Observation</DrawerHeader>
      <FormDrawer>
        {drawer?.isOpen ? (
          <FormBody className={'py-2'}>
            <VettingViqChapterSelector
              label="CHAPTER"
              placeholder="Select chapter"
              onChange={value => {
                selectField('chapter')(value);
                selectField('question')(null);
              }}
              autoFocus={false}
              isMulti={false}
              isClearable
              {...fields.chapter}
            />

            <VettingViqQuestionSelector
              label="QUESTION"
              onChange={selectField('question')}
              placeholder="Select question"
              disabled={!fields.chapter.value?.id}
              chapterId={fields.chapter.value?.id}
              {...fields.question}
            />

            <Editor
              label="OBSERVATION"
              placeholder="Add some notes"
              onChange={selectField('observation')}
              id="observation-notes"
              {...fields.observation}
            />

            <Row>
              <Col xs={3}>
                <SeveritySelector
                  label="RISK RATING"
                  onChange={selectField('severity')}
                  className="text-nowrap"
                  {...fields.severity}
                />
              </Col>
            </Row>

            <Editor
              label="COMPANY RESPONSE"
              placeholder="Add some notes"
              onChange={selectField('company_response')}
              id="observation-company-response"
              className="mb-0"
              {...fields.company_response}
            />

            <div className="pt-3 mt-3 border-top">
              <LinkEntities
                jobsState={{
                  linkedJobs: fields.jobs.value,
                  addLinkedJob: job => {
                    selectField('jobs')([...(fields.jobs.value || []), job]);
                  },
                  removeLinkedJob: index =>
                    selectField('jobs')(fields.jobs.value.filter((_, i) => i !== index))
                }}
              />

              <ResolveActions formState={formState} container=".vetting-observation-drawer" />
            </div>
          </FormBody>
        ) : null}
      </FormDrawer>
      <FormFooter>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
          CANCEL
        </Button>
        <Button onClick={onSubmit} color="primary" className="px-4" disabled={isSubmitting}>
          SAVE
        </Button>
      </FormFooter>
    </Drawer>
  );
};

Form.propTypes = {
  drawer: DrawerState,
  active: PropTypes.object
};

export default Form;
