import { useState } from 'react';
import { Button } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import check from 'common/assets/svg/actions/check.svg';

const AcknowledgeSubmitButton = ({ modalMessage, onAccept }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        type="button"
        color="green"
        className="ms-1 fw-normal py-1 px-2 lh-16 d-inline-flex align-items-center text-white"
        onClick={() => setIsModalOpen(true)}
      >
        <SvgRender src={check} style={{ width: 14, height: 14 }} />
        <span className="ps-1">Acknowledge</span>
      </Button>

      <DangerousActionModal
        transparent
        isOpen={isModalOpen}
        header="Acknowledge"
        body={modalMessage}
        actionText="ACKNOWLEDGE"
        onAccept={onAccept}
        actionIcon={<SvgRender src={check} style={{ width: 80, height: 80 }} />}
        actionHoverColor="turquoise"
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default AcknowledgeSubmitButton;
