import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import { FC, ReactNode } from 'react';
import { Input } from 'reactstrap';

type SwitchProps = {
  name?: string;
  value?: boolean;
  onChange?: () => void;
  className?: string;
  switchClassName?: string;
  labelClassName?: string;
  label?: ReactNode;
  size?: 'xs' | 'sm' | 'lg';
  innerOnLabel?: ReactNode;
  innerOffLabel?: ReactNode;
  disabled?: boolean;
  dataCy?: string;
};

const Switch: FC<SwitchProps> = ({
  name,
  value,
  onChange,
  className = '',
  switchClassName = '',
  labelClassName = '',
  label,
  size = 'lg',
  innerOnLabel,
  innerOffLabel,
  disabled,
  dataCy
}) => {
  return (
    <FormGroupWrap className={`switch-wrapper ${className}`}>
      <div
        className={`d-flex align-items-center switch-slider ${switchClassName} ${size} ${
          disabled ? 'disabled' : ''
        }`}
      >
        {label && (
          <span
            className={`slide-label form-label ${value ? 'inactive' : ''} ${labelClassName} ${
              labelClassName.includes('me-') ? '' : 'me-2'
            }`}
          >
            {label}
          </span>
        )}
        <div className={`slider-wrap`} onClick={onChange} data-cy={dataCy}>
          <Input type="checkbox" checked={value ? true : false} name={name} readOnly />
          <span className={`slider round`}>
            {innerOnLabel && value ? innerOnLabel : !value && innerOffLabel ? innerOffLabel : ''}
          </span>
        </div>
      </div>
    </FormGroupWrap>
  );
};

export default Switch;
