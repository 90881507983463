import _forEach from 'lodash/forEach';
import _reverse from 'lodash/reverse';
import _clone from 'lodash/clone';

export const constructColumns = (columns = [], override = [], label) => {
  const tableLabel = label || null;
  const overrideObj = override.reduce((acc, item) => {
    acc[item.key] = item;
    return acc;
  }, {});

  return [
    ...columns.map((column, index) => {
      const overrideData = overrideObj[column.key];
      const item = { ...overrideData, ...column };
      const columnData = selectColumnData(item, tableLabel, columns, index);

      if (columnData?.bodyClassName) {
        columnData.bodyClassName = `${columnData?.bodyClassName ? columnData.bodyClassName : ''} ${
          item?.bodyClassName ? item.bodyClassName : ''
        }`;
      }

      if (columnData?.headerClassName) {
        columnData.headerClassName = `${
          columnData?.headerClassName ? columnData.headerClassName : ''
        } ${item?.headerClassName ? item.headerClassName : ''}`;
      }

      return {
        ...item,
        ...columnData
      };
    })
  ];
};

export const isTotalsBarEnabled = (columns = [], key) =>
  columns.some(col => {
    if (col.columns?.length) {
      return col.columns.some(inner => inner[key]);
    }

    return col[key];
  });

const selectColumnData = (item, tableLabel, columns, parentIndex) => {
  if (item.type === 'group' && item.columns?.length) {
    const nestedColumns = item.columns.map((inner, index) => {
      const findColumn = columns.find(e => e?.key === item.key);

      const restColumnParams = findColumn.columns.find(innner => {
        return innner.key === inner.key ? innner : null;
      });

      const data = { ...restColumnParams, ...inner };

      return {
        ...inner,
        ...styleColumns({
          column: data,
          tableLabel: tableLabel,
          columns: columns,
          groupedData: {
            key: item.key,
            curIndex: index,
            totalColumns: item.columns.length,
            parentIndex: parentIndex
          },
          isParentSticky: item.sticky
        })
      };
    });

    return {
      width: 'auto',
      maxWidth: item.width,
      className: `h-auto`,
      sticky: item.sticky || false,
      left:
        item.sticky && !item.isRightSticky
          ? findStickyPosition(item.key, columns, parentIndex === 0 ? 0 : 8)
          : null,
      right:
        item.sticky && item.isRightSticky
          ? findStickyPosition(item.key, columns, parentIndex === 0 ? 0 : 8, true)
          : null,
      columns: nestedColumns
    };
  } else {
    return styleColumns({
      column: item,
      tableLabel: tableLabel,
      columns: columns
    });
  }
};

const constructClassNamekey = (tableLabel, rowKey, suffix) => {
  return tableLabel
    ? `${tableLabel?.split('_').join('-')}-${rowKey?.toString().split('_').join('-')}-${suffix}`
    : '';
};

const styleColumns = ({ column, tableLabel, columns, groupedData, isParentSticky }) => {
  const hasTotals = isTotalsBarEnabled(columns);

  let params = {
    headerClassName: `title-header ${constructClassNamekey(tableLabel, column.key, 'header')} ${
      hasTotals ? 'title-header--totals-bar' : ''
    } ${
      groupedData?.totalColumns && groupedData.curIndex + 1 === groupedData.totalColumns
        ? 'title-header--end'
        : ''
    } ${column?.headerClassName || ''}`,
    bodyClassName: `item-cell d-flex ${constructClassNamekey(tableLabel, column.key, 'body')} ${
      groupedData?.totalColumns && groupedData.curIndex + 1 === groupedData.totalColumns
        ? 'item-cell--end'
        : ''
    } ${groupedData?.totalColumns && groupedData.curIndex === 0 ? 'item-cell--start' : ''}  ${
      column?.bodyClassName || ''
    }`
  };

  if (groupedData?.key && !column.data_key) {
    params.key = `${groupedData.key}.${column.key}`;
  }

  if (column.width && column.width > 0) {
    params.maxWidth = column.width;
    params.minWidth = column.width;
    params.width = column.width;
  }

  if (column.subLabel) {
    params.header = (
      <div className="d-flex align-items-center flex-wrap">
        <div className="title cme-4">{column.header}</div>
        <div className="unit">{column.subLabel}</div>
      </div>
    );
  }

  if (column.sticky || isParentSticky) {
    params.sticky = true;
    params.left =
      column.left !== undefined
        ? column.left
        : column.isRightSticky
        ? undefined
        : findStickyPosition(
            column.key,
            columns,
            isParentSticky && groupedData.parentIndex !== 0 ? 8 : 0
          );
    params.right =
      column.right !== undefined
        ? column.right
        : column.isRightSticky
        ? findStickyPosition(
            column.key,
            columns,
            isParentSticky && groupedData.parentIndex !== 0 ? 8 : 0,
            true
          )
        : undefined;
  }

  if (column.grid_column_width && column.grid_column_width >= 1 && column.grid_column_width <= 12) {
    params.maxWidth = '';
    params.minWidth = '';
    params.width = column.grid_column_width;
  }

  if (column.text_align) {
    params.headerClassName = `${params.headerClassName} text-${
      column.text_align === 'right' ? 'end' : 'start'
    }`;
    params.bodyClassName = `${params.bodyClassName} text-${
      column.text_align === 'right' ? 'end' : 'start'
    }`;
  }

  if (column.highlight) {
    params.headerClassName = `${params.headerClassName} col-highlight`;
    params.bodyClassName = `${params.bodyClassName} col-highlight`;
  }

  if (column.can_sort) {
    params.sort = true;
  }

  if (column.can_filter) {
    params.canFilter = true;
  }

  return params;
};

export const findStickyPosition = (key, data, offset = 0, isRight = false) => {
  let columnTotalWidth = 0;
  let nestedColumnTotalWidth = 0;

  const clonedData = _clone(data);
  const columns = isRight ? _reverse(clonedData) : data;

  _forEach(columns, e => {
    let shouldStop = false;

    if (e.key === key) {
      return false;
    }

    if (e.columns?.length) {
      _forEach(e.columns, inner => {
        if (inner.key === key) {
          shouldStop = true;
          return false;
        }

        nestedColumnTotalWidth += inner.width || 0;
      });
    }

    if (shouldStop) {
      return false;
    }

    return (columnTotalWidth += e?.width || 0);
  });

  return columnTotalWidth + nestedColumnTotalWidth + offset;
};
