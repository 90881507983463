import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';

import AuthCheck from 'components/permissions/AuthCheck';
import SvgRender from 'common/components/general/SvgRender';

const MoreActionsDropdown = ({
  direction,
  options = [],
  className = '',
  menuClass = '',
  menuPostionIsRight = true
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const customContainer = (Container, params, key, children) => {
    return Container ? (
      <Container key={key} {...params}>
        {children}
      </Container>
    ) : (
      children
    );
  };

  const hasIcon = (component, icon, iconClassName) => {
    return component ? (
      component
    ) : icon ? (
      <SvgRender
        src={icon}
        style={{ width: 16, height: 16 }}
        className={`me-1 ${iconClassName || ''}`}
      />
    ) : null;
  };

  return (
    <>
      <Dropdown
        direction={direction || 'down'}
        className={`more-actions-toggle ${isSettingsOpen ? 'more-actions-toggle--open-menu' : ''} ${
          !className.includes('mx-') ? 'mx-1' : ''
        } ${className}`}
        isOpen={isSettingsOpen}
        onClick={() => setIsSettingsOpen(!isSettingsOpen)}
        toggle={() => setIsSettingsOpen(!isSettingsOpen)}
      >
        <DropdownToggle
          color="link"
          className="py-0 px-1 d-inline-flex align-items-center justify-content-center"
        >
          <span
            className={`more-actions-toggle__title fw-medium ${
              isSettingsOpen ? 'more-actions-toggle__title--open' : ''
            }`}
          >
            More Actions
          </span>
        </DropdownToggle>

        <DropdownMenu className={`${menuClass ? menuClass : 'mt-1'}`} right={menuPostionIsRight}>
          {options &&
            options.length > 0 &&
            options.map((item, index) => (
              <AuthCheck key={index} permissions={item.permissions}>
                {item.isEnabled === true || item.isEnabled === undefined ? (
                  !item.containerComponent ? (
                    <DropdownItem
                      tag="div"
                      className={`btn d-flex align-items-center fw-light ${
                        item.isDisabled ? 'disabled' : ''
                      }`}
                      onClick={item.clickAction}
                    >
                      {hasIcon(item.component, item.icon)}
                      {item.title}
                    </DropdownItem>
                  ) : (
                    customContainer(
                      item.containerComponent && item.containerComponent.component
                        ? item.containerComponent.component
                        : null,
                      item.containerComponent && item.containerComponent.params
                        ? item.containerComponent.params
                        : null,
                      index,
                      <DropdownItem
                        tag="div"
                        key={index}
                        className={`btn d-flex align-items-center fw-light ${
                          item.isDisabled ? 'disabled' : ''
                        }`}
                        onClick={item.clickAction}
                      >
                        {hasIcon(item.component, item.icon, item.iconClassName)}
                        {item.title}
                      </DropdownItem>
                    )
                  )
                ) : null}
              </AuthCheck>
            ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

MoreActionsDropdown.propTypes = {
  direction: PropTypes.string,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  menuClass: PropTypes.string,
  menuPostionIsRight: PropTypes.bool
};

export default MoreActionsDropdown;
