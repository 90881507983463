import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { TableTopFilters } from 'common/entities/tables/TopFilterTypes';

const useTableTopFilterValue = (table, name) => {
  const { topFilters, setTopFilters } = table;

  const value = useMemo(() => topFilters?.find(f => f.name === name)?.value, [topFilters, name]);

  const onChange = useCallback(
    (value, operation) => {
      setTopFilters(
        topFilters.map(f =>
          f.name === name ? { ...f, value, operation: operation || f.operation } : f
        )
      );
    },
    [topFilters, setTopFilters, name]
  );

  return [value, onChange];
};

useTableTopFilterValue.propTypes = {
  table: PropTypes.shape({
    topFilters: TableTopFilters.isRequired,
    setTopFilters: PropTypes.func.isRequired
  }),
  name: PropTypes.string.isRequired
};

export default useTableTopFilterValue;
