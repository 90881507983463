import React from 'react';
import { number, bool, func } from 'prop-types';
import DrydockSwitch from 'common/components/jobs/_base/components/DrydockSwitch';

export const Drydock = ({ jobID, isDrydock, isPlanned, setJobField }) => {
  if (isPlanned) return null;

  return (
    <>
      <div className="small-separator pt-2 cpb-4 me-3" />

      <DrydockSwitch
        className="me-3"
        isDrydock={isDrydock}
        onChange={() => setJobField('is_drydock', !isDrydock, !!jobID)}
      />
    </>
  );
};

Drydock.propTypes = {
  jobID: number,
  isDrydock: bool.isRequired,
  isPlanned: bool.isRequired,
  setJobField: func.isRequired
};

export default Drydock;
