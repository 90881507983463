import React from 'react';
import Departments from 'common/components/general/Departments';

const DepartmentsActions = ({ departments, departmentRoles }) => {
  return (
    <>
      <div className="text-violet fs-12 fw-medium cmb-12">
        Department(s) that have to take action - Either one of the selected departments can fill in
        info.
      </div>

      <div className="cmb-12">
        {departments.length ? (
          <Departments values={departments} maxLimit={5} />
        ) : departmentRoles.length ? (
          <Departments values={departmentRoles} maxLimit={5} />
        ) : null}
      </div>
    </>
  );
};

export default DepartmentsActions;
