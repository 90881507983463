import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import DateInput from 'common/components/form/inputs/date';
import calendar from 'common/assets/svg/common/calendar.svg';

import moment from 'moment';

const FormDate = ({
  onChange,
  value,
  isViewMode,
  showLabel = false,
  dateClassName,
  error,
  disabled
}) => {
  return (
    <div className="forms-date">
      {isViewMode ? (
        <div className="d-flex align-items-center">
          <SvgRender
            src={calendar}
            className="text-violet me-1"
            style={{ height: 16, width: 16 }}
          />
          {showLabel ? <span className="fs-12 lh-15 text-violet me-1">Form Date</span> : null}
          <span
            className={`text-violet fs-12 lh-15 me-1${dateClassName ? ` ${dateClassName}` : ''}`}
          >
            {value ? moment(value).format('DD/MM/YYYY') : '-/-/-'}
          </span>
        </div>
      ) : (
        <DateInput
          disabled={disabled}
          onChange={onChange ? onChange : () => null}
          className="mb-0"
          value={value}
          error={error}
        />
      )}
    </div>
  );
};

export default FormDate;
