import React, { useState } from 'react';

import MoreActionsDropdown from 'common/components/general/MoreActionsDropdown';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import binIcon from 'common/assets/svg/actions/delete.svg';
import * as eventActions from 'events/store/events/actions';
import { useActions } from 'utils/hooks';
import paths from 'routing/routes/_paths';
import useRouter from 'use-react-router';

const Actions = ({ event }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { history } = useRouter();

  const [deleteEvent] = useActions([eventActions.deleteEvent]);

  const options = [
    {
      isEnabled: true,
      icon: binIcon,
      clickAction: () => setDeleteModalOpen(true),
      title: 'Delete'
    }
  ];
  return (
    <>
      <MoreActionsDropdown options={options} className="mx-2 text-nowrap" menuClass="mt-2" />
      <DangerousActionModal
        transparent
        action={'delete'}
        onAccept={async () => {
          await deleteEvent({ id: event.id });
          history.push(`${paths.events}`);
        }}
        closeModal={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        actionText={'DELETE'}
        header="Delete Event"
        body={
          <div className="text-center">
            Are you sure you want to delete <strong>{`${event?.name}`}</strong>? This cannot be
            undone.
          </div>
        }
      />
    </>
  );
};

export default Actions;
