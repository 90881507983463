import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import _get from 'lodash/get';

const Confidential = props => {
  const { properties } = props;

  const oldConf = _get(properties, 'old.confidential', 'None');
  const newConf = _get(properties, 'attributes.confidential', 'None');

  return (
    <div className="d-flex align-items-center">
      <div className="fs-12 text-violet fw-light">
        <span data-testid="job-activity-confidential-before" className="text-red">
          {oldConf ? 'Limited access' : 'Open'}
        </span>
      </div>

      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <div className="fs-12 text-violet fw-light">
        <span data-testid="job-activity-confidential-after" className="text-red">
          {newConf ? 'Limited access' : 'Open'}
        </span>
      </div>
    </div>
  );
};

export default Confidential;
