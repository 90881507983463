import React from 'react';

import Group from 'captain-reports/templates/layout/fields/Group';
import VisibleField from 'captain-reports/templates/layout/fields/VisibleField';
import { Row, Col } from 'reactstrap';
import { FieldsHeader } from 'captain-reports/templates/layout/_helpers';

import useValue from 'captain-reports/utils/useValue';

import Field from 'captain-reports/templates/layout/fields/Field';
import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';
import { selectFieldVisibility, selectFieldTemplateTooltip } from 'captain-reports/store/selectors';

const EcaCheckbox = ({ filteredEcaFields, field }) => {
  const isEcaVisible = useSelector(state => selectFieldVisibility(state, field.key));
  const tooltip = useSelector(selectFieldTemplateTooltip(field.key));

  const hasVisibleEcaFields = useSelector(state =>
    hasAtLeastOneVisibleField(state, filteredEcaFields)
  );

  return isEcaVisible || hasVisibleEcaFields ? (
    <Field className={field.className} width={field.width}>
      <field.render field={field} tooltip={tooltip ? tooltip : ''} />
    </Field>
  ) : null;
};

const EcaFields = ({ section }) => {
  const milesField = section.fields.find(el => el.key === 'miles_to_go');
  const filteredEcaFields = section.fields.filter(
    el => el.key !== 'miles_to_go' && el.key !== 'eca_intersection'
  );
  const ecaField = section.fields.find(el => el.key === 'eca_intersection');
  const eca_intersection = useValue({ key: 'eca_intersection' });

  return (
    <div className={`template-layout__fields `}>
      {!section.subGroup ? <FieldsHeader header={section.header}></FieldsHeader> : null}

      <Group className="align-items-start">
        <Col xs={3}>
          <Row>
            <VisibleField field={milesField} />
          </Row>
        </Col>
        <Col xs={9}>
          <Row
            className={`align-items-center  template-layout__eca-fields ${
              eca_intersection ? 'template-layout__eca-fields-checked' : ''
            }`}
          >
            <EcaCheckbox field={ecaField} filteredEcaFields={filteredEcaFields} />

            {eca_intersection
              ? filteredEcaFields.map((field, findex) => {
                  return <VisibleField key={findex} field={field} />;
                })
              : null}
          </Row>
        </Col>
      </Group>
    </div>
  );
};

export default EcaFields;
