import { FC } from 'react';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';

import ButtonGroupTabs from 'common/components/buttons/ButtonGroupTabs';

import { TableTopFilterType, TableSetTopFilterFunc } from '@/common/types/front-entities/table.ts';

type TopFiltersComponentProps = {
  topFilters: TableTopFilterType[];
  setTopFilters: TableSetTopFilterFunc;
};

const tabs = [
  {
    label: 'Pending',
    value: 'pending',
    size: 'sm'
  },
  {
    label: 'Completed',
    value: 'completed',
    size: 'sm'
  }
];

const TopFilters: FC<TopFiltersComponentProps> = ({ topFilters = [], setTopFilters }) => {
  const [status, setStatus] = useTableTopFilterValue(
    { topFilters: topFilters, setTopFilters: setTopFilters },
    'status'
  );

  const onTabClick = (value: string) => {
    setStatus(value);
  };

  return (
    <div className="d-flex align-items-start">
      <ButtonGroupTabs
        active={status}
        options={tabs}
        onClick={onTabClick}
        equalityCheck={null}
        getStyle={null}
      />
    </div>
  );
};

export default TopFilters;
