import React, { useState } from 'react';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { components } from 'react-select';
import { getFilterSelectorValues } from '../filters/helpers';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import { Row, Col } from 'reactstrap';
import Status from 'common/components/purchasing/requisition/components/Status';

const PurchasingRequisitionTableFilter = ({ filter, onChange, isMulti, ...rest }) => {
  const [selected, setSelected] = useState([]);

  useFilterSelector(filter?.value, { list: 'purchasing-requisitions' }, { selected, setSelected });

  return (
    <PurchasingRequisitionSelect
      placeholder="Select cases"
      value={getFilterSelectorValues(selected, filter?.value, isMulti)}
      onChange={selected => {
        setSelected(isMulti ? selected : [selected]);
        onChange({
          value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
        });
      }}
      isMulti={isMulti}
      {...rest}
    />
  );
};

const PurchasingRequisitionSelect = props => {
  return (
    <AsyncSelector
      type="purchasing-requisitions"
      getOptionLabel={option => `${option.code ? `${option.code}. ` : ''}${option.description}`}
      components={{ Option, SingleValue }}
      {...props}
    />
  );
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Row>
        <Col xs={3}>
          {props.data.code ? (
            <div className="fw-bold fs-10 pe-1 text-truncate">{props.data.code}</div>
          ) : null}
        </Col>
        <Col xs={7}>
          <div className="text-truncate">{props.data.description}</div>
        </Col>
        <Col xs={2} className="ms-auto">
          <Status status={props.data?.status} isBullet />
        </Col>
      </Row>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props} className="w-100p">
      <Row className="align-items-center">
        <Col xs={2} className="lh-16">
          {props.data.code ? (
            <div className="fw-bold fs-10 pe-1 text-truncate">{props.data.code}</div>
          ) : null}
        </Col>
        <Col xs={7} className="ps-0">
          <div className="text-truncate">{props.data.description}</div>
        </Col>
        <Col xs={2} className="ms-auto">
          <Status status={props.data?.status} isBullet />
        </Col>
      </Row>
    </components.SingleValue>
  );
};

const PurchasingRequisitionSelector = ({ filter, ...rest }) => {
  if (filter) return <PurchasingRequisitionTableFilter filter={filter} {...rest} />;

  return <PurchasingRequisitionSelect {...rest} />;
};

export default PurchasingRequisitionSelector;
