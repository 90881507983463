export const getFilterSelectorValues = (
  selected,
  filterValues,
  isMulti = true,
  valueKey = 'id'
) => {
  const values = isMulti ? filterValues || [] : filterValues ? [filterValues] : [];

  return selected?.filter(el =>
    Array.isArray(values)
      ? values
          .map(v => (isNaN(v) ? v : Number(v)))
          .includes(isNaN(el?.[valueKey]) ? el?.[valueKey] : Number(el?.[valueKey]))
      : undefined
  );
};
