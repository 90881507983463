import React, { useMemo } from 'react';

import NumberField from 'captain-reports/templates/inputs/NumberField';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import _debounce from 'lodash/debounce';

import {
  selectConsumptionsTotalMainEngineCons,
  selectFieldVisibility
} from 'captain-reports/store/selectors';
import { strToNumber } from 'common/utils/numbers';

const hasValue = value => value || value === 0;

const MainEngineSfocField = ({
  tooltip,
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const total_consumptions_main_engine = useSelector(selectConsumptionsTotalMainEngineCons);
  const steaming_time = useValue({
    key: 'steaming_time'
  });
  const me_shaft_power = useValue({
    key: 'me_shaft_power',
    sectionLabel,
    sectionActiveTab
  });
  const visible_streaming_time = useSelector(state =>
    selectFieldVisibility(state, 'steaming_time')
  );

  const [setValue] = useActions([reportActions.setValue]);
  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  }); // me_sfoc = me total bunkers cons in gramms / steaming_time (in hours) / me_shaft_power

  const me_effective_power = useValue({
    key: 'me_effective_power',
    sectionLabel,
    sectionActiveTab
  });

  const me_indicated_power = useValue({
    key: 'me_indicated_power',
    sectionLabel,
    sectionActiveTab
  });

  const power = useMemo(
    () =>
      hasValue(me_shaft_power)
        ? strToNumber(me_shaft_power) * 0.985
        : hasValue(me_effective_power)
        ? me_effective_power
        : hasValue(me_indicated_power)
        ? me_indicated_power
        : null,
    [me_shaft_power, me_effective_power, me_indicated_power]
  );

  const debouncedAutoComplete = _debounce(newVal => {
    if (newVal !== value) {
      setValue({
        key: field.key,
        value: newVal,
        sectionLabel,
        sectionActiveTab,
        subGroup,
        subGroupIndex
      });
    }
  }, 600);

  useUpdateEffect(() => {
    const total_consumptions_main_engine_gramms = total_consumptions_main_engine * 1000000;
    const steaming_time_hours = steaming_time / 60;

    const calc_sfoc =
      total_consumptions_main_engine_gramms && steaming_time_hours && hasValue(power)
        ? strToNumber(
            parseFloat(total_consumptions_main_engine_gramms / steaming_time_hours / power).toFixed(
              2
            )
          )
        : null;

    debouncedAutoComplete(calc_sfoc);
  }, [total_consumptions_main_engine, steaming_time, power]);

  return visible_streaming_time ? (
    <div className="w-75p">
      <NumberField
        tooltip={tooltip}
        field={field}
        sectionLabel={sectionLabel}
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      />
    </div>
  ) : null;
};

export default MainEngineSfocField;
