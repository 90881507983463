import { createSelector } from 'reselect';

export const selectRestrictedFields = state => state.jobsRestricted.data;
const selectIsOnboard = state => state.isOnBoard;

export const selectIfFieldIsVisible = field =>
  createSelector([selectRestrictedFields, selectIsOnboard], (fields, isOnBoard) => {
    if (isOnBoard) return true;

    let found = fields.find(el => el.visible && el.field === field);
    if (found) {
      return found.visible;
    } else {
      return false;
    }
  });

export const selectShowDueDateDown = () =>
  createSelector([selectRestrictedFields], fields => {
    let result = fields.filter(el => {
      if (
        (el.field === 'approvers' && el.visible === true) ||
        (el.field === 'assignee_id' && el.visible === true) ||
        (el.field === 'status_id' && el.visible === true) ||
        (el.field === 'priority_id' && el.visible === true)
      ) {
        return true;
      } else {
        return false;
      }
    });
    return result.length < 4;
  });
