import React from 'react';
import { Button } from 'reactstrap';

import { useActions } from 'utils/hooks';
import SvgRender from 'common/components/general/SvgRender';
import newsAndAnnouncements from 'common/assets/svg/common/news-announcements.svg';
import * as sidebarActions from 'store/sidebar/actions';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const NewsAndAnnouncementsButton = () => {
  const [togglePanel] = useActions([sidebarActions.togglePanel]);

  return (
    <AuthCheck
      permissions={[
        permissions.office.news_and_announcements.view,
        permissions.onboard.news_and_announcements.view
      ]}
    >
      <div className="border-top min-width-fit max-width-fit pt-2 ms-2 mt-auto">
        <Button
          color="yellow"
          onClick={() => togglePanel('news_and_announcements')}
          className="d-flex align-items-center text-dark fw-bold fs-12 cpx-12 py-1"
        >
          <SvgRender
            src={newsAndAnnouncements}
            style={{ height: 16, width: 16 }}
            className="me-2"
          />
          News & Announcements
        </Button>
      </div>
    </AuthCheck>
  );
};

export default NewsAndAnnouncementsButton;
