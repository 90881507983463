import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import FieldsItemsRow from './FieldsItemsRow';
import FieldsSparePartsRow from './FieldsSparePartsRow';
import FieldsCategoryRow from 'common/components/inventory/survey/FieldsCategoryRow';
import FieldsCategoryActions from './FieldsCategoryActions';
import { Collapse } from 'reactstrap';

import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

const FieldsCategoryCollapse = ({
  formType,
  data,
  searchText,
  isExpanded,
  onExpand,
  onRemove,
  onCancel,
  onSave
}) => {
  const FieldsRowComponent = formType === 'items' ? FieldsItemsRow : FieldsSparePartsRow;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(payload => setIsOpen(payload), []);
  const setDebouncedToggle = useMemo(() => _debounce(toggle, 500), [toggle]);

  useEffect(() => {
    if (isExpanded && !isOpen) toggle(true);
    else if (!isExpanded && isOpen) setDebouncedToggle(false);
  }, [isExpanded, isOpen, toggle, setDebouncedToggle]);

  return (
    <div className="mb-1">
      <FieldsCategoryRow
        category={_get(data, `[0].state.group.value.name`)}
        isOpen={isExpanded}
        data={data}
        onClick={onExpand}
      />

      <Collapse isOpen={isExpanded}>
        {isExpanded || isOpen
          ? data.map(state => (
              <FieldsRowComponent
                state={state}
                index={state.index}
                onRemove={onRemove}
                searchText={searchText}
              />
            ))
          : null}
        <FieldsCategoryActions onCancel={onCancel} onSave={onSave} />
      </Collapse>
    </div>
  );
};

FieldsCategoryCollapse.propTypes = {
  formType: PropTypes.oneOf(['spare_parts', 'items']).isRequired,
  data: PropTypes.array,
  searchText: PropTypes.string,
  isExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
  onRemove: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default FieldsCategoryCollapse;
