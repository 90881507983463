import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_FORM_PLANNINGS'),
  SET_FORM_PLANNING_FROM: 'SET_FORM_PLANNING_FROM',
  SET_FORM_PLANNING_TO: 'SET_FORM_PLANNING_TO',
  SET_FORM_PLANNING_IN_ALARM: 'SET_FORM_PLANNING_IN_ALARM',
  SET_FORM_PLANNING_STATUSES: 'SET_FORM_PLANNING_STATUSES',
  UPDATE_FORM_PLANNING_SUBMISSION: 'UPDATE_FORM_PLANNING_SUBMISSION',
  UPDATE_FORM_PLANNING_FORM_SUBMISSION_STATUS_TABLE_ROW:
    'UPDATE_FORM_PLANNING_FORM_SUBMISSION_STATUS_TABLE_ROW'
};

export default TYPES;
