import React, { useState, useEffect } from 'react';
import { array, func, bool, oneOf } from 'prop-types';

import NotesLoader from './NotesLoader';
import ModuleWrapper from '../ModuleWrapper';
import NoteEditor from './NoteEditor';
import SavedNotes from './SavedNotes';
import { useForm } from 'utils/hooks';
import { hiddenField, optionField, stringField } from 'common/utils/form/fieldTypes';
import FormSaveActions from 'common/components/form/FormSaveActions';
import ActionButton from 'common/components/buttons/ActionButton';
import add from 'common/assets/svg/actions/add.svg';
import ShadowBox from 'common/components/general/ShadowBox';
import { selectModuleSyncButtonIsVisible } from 'events/store/events/selectors';
import { useSelector } from 'react-redux';

const config = {
  id: hiddenField(),
  content: stringField({ required: true }),
  tags: optionField()
};

const Notes = ({ savedNotes, onSave, onDelete, isLoading, type, hideActions }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showNoteEditor, toggleNoteEditor] = useState(false);
  const isSyncButtonVisible = useSelector(selectModuleSyncButtonIsVisible);

  const { formState, collectValues, resetForm, loadValues } = useForm(config, {});
  const message =
    type === 'office_notes'
      ? `*Edit only from the office,\n visible onboard.`
      : '*Edit only from the vessel,\n visible in the office.';

  const onCreateNote = async () => {
    try {
      const values = collectValues();
      if (!values) return;

      setIsSaving(true);

      await onSave(values);

      toggleNoteEditor(false);
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  const onToggleNoteEditor = values => {
    loadValues(values);
    toggleNoteEditor(true);
  };

  useEffect(() => {
    if (!showNoteEditor) resetForm();
  }, [showNoteEditor, resetForm]);

  return (
    <ModuleWrapper
      className="event-notes"
      type={type}
      action={
        <div className="flex-1 height-20 d-flex align-items-center ps-1">
          <div className="fs-10 lh-1 text-violet fw-medium">{message}</div>
          {!hideActions && (
            <ActionButton
              className={`text-dark ms-auto ${isSyncButtonVisible ? 'me-8' : ''}`}
              color="yellow"
              onClick={() => toggleNoteEditor(true)}
              icon={add}
              disabled={showNoteEditor}
            >
              Add note
            </ActionButton>
          )}
        </div>
      }
    >
      {isLoading ? (
        <NotesLoader />
      ) : (
        <div className="event-notes__content position-relative">
          {showNoteEditor ? (
            <ShadowBox flat color="light-4" className="pt-2 pe-8 pb-2 ps-3 mb-1">
              <NoteEditor formState={formState} fullEditor={type === 'vessel_notes'} />
            </ShadowBox>
          ) : null}
          {!savedNotes?.length ? (
            showNoteEditor ? null : (
              <div className="d-flex align-items-center justify-content-center text-violet fw-medium fs-12 pt-4 pb-4">
                There are no {type === 'office_notes' ? 'office' : 'vessel'} notes at the moment.
              </div>
            )
          ) : showNoteEditor && type === 'vessel_notes' ? null : (
            <SavedNotes
              notes={savedNotes}
              onEditNote={onSave}
              onDeleteNote={onDelete}
              onToggleNoteEditor={onToggleNoteEditor}
              fullPreview={type === 'vessel_notes'}
            />
          )}

          {showNoteEditor ? (
            <div className="event-notes__preview-edit">
              <FormSaveActions
                mode="edit"
                isDisabled={isLoading || isSaving}
                onCancel={() => toggleNoteEditor(false)}
                onSave={onCreateNote}
                style={{ right: -8, top: 16 }}
              />
            </div>
          ) : null}
        </div>
      )}
    </ModuleWrapper>
  );
};

export default Notes;

Notes.propTypes = {
  savedNotes: array,
  onSave: func.isRequired,
  onDelete: func,
  isLoading: bool,
  type: oneOf(['office_notes', 'vessel_notes']),
  hideActions: bool
};
