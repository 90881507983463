import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import FormSaveActions from 'common/components/form/FormSaveActions';
import Status from 'common/components/jobs/_base/components/Status';

import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { useSelector } from 'react-redux';
import { Job } from 'common/entities/jobs/JobTypes';
import Priority from 'common/components/general/Priority';
import PreviewJobType from 'common/components/jobs/PreviewJobType';
import ShadowBox from 'common/components/general/ShadowBox';
import Vessel from 'common/components/jobs/_base/components/Vessel';

import _get from 'lodash/get';

const PreviewLinkedJob = ({ className = '', job, onEdit, onView, onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const jobStatuses = useSelector(selectJobStatuses);
  const jobStatus = job?.status_id ? jobStatuses.find(st => st.id === job?.status_id) : job?.status;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <ShadowBox
      flatOnHover
      className={`cps-12 pe-8 py-1 text-violet fs-14 position-relative ${className}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Row className="align-items-center flex-nowrap" noGutters>
        {jobStatus ? (
          <Col xs="auto" className="border-end cme-12 cpe-12 d-inline-flex align-items-center">
            <Status activeStatus={jobStatus} className="my-0" />
          </Col>
        ) : null}

        <Col xs="auto" className="cpe-12">
          <Priority size="small" value={_get(job, 'importance.id', '')} />
        </Col>
        <Col xs="auto" className="fs-10 cpe-12">
          {job.orca_id || job.id}
        </Col>
        <Col className="overflow-hidden d-flex align-items-center">
          <div className="overflow-hidden">
            <TextWithTooltip>{job.title}</TextWithTooltip>
          </div>
          <PreviewJobType type={job?.type} iconClassName="text-purple" showTitle={false} />
        </Col>
        {(job?.vessels?.length > 0 || job?.vessel) && (
          <Col xs="auto">
            <Vessel
              className="cms-12 me-1"
              name={
                job.is_for_vessel
                  ? _get(job, 'vessel.name', '-')
                  : _get(job, 'vessels[0].name', '-')
              }
            />
            {job.vessels?.length > 1 && (
              <div
                key={job.id}
                id={`job-vessel-${job.id}`}
                className="fs-10 text-primary text-nowrap fw-bold me-1 cursor-pointer"
              >
                <UncontrolledTooltip
                  placement="top-end"
                  boundariesElement="window"
                  target={`job-vessel-${job.id}`}
                  innerClassName="show-more-tooltip"
                >
                  {job.vessels.map((v, i) => (i === 0 ? null : <div key={v.id}>{v.name}</div>))}
                </UncontrolledTooltip>
                +{job.vessels.length - 1} more
              </div>
            )}
          </Col>
        )}
      </Row>

      {onEdit || onDelete || onView ? (
        <FormSaveActions
          mode={'view'}
          isHovering={isHovering}
          onEdit={onEdit ? () => onEdit(job) : null}
          onDelete={onDelete ? () => setShowDeleteModal(true) : null}
          onView={onView && job?.id ? () => onView(job) : null}
          style={{ right: -12, top: 6 }}
          editButtonProps={{ svgClassName: 'text-white', svgWrapperClassName: 'bg-primary' }}
          deleteButtonProps={{ type: 'remove' }}
        />
      ) : null}

      <DangerousActionModal
        transparent
        action={'Remove'}
        onAccept={() => onDelete(job)}
        closeModal={() => setShowDeleteModal(false)}
        isOpen={showDeleteModal}
        actionText={'UNLINK'}
        header={'Unlink'}
        body={<div>Are you sure you want to unlink this Job?</div>}
      />
    </ShadowBox>
  );
};

PreviewLinkedJob.propTypes = {
  job: Job,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  className: PropTypes.string
};

export default PreviewLinkedJob;
