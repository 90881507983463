import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'utils/hooks';
import {
  selectIsCreatingFolder,
  selectIsLoading,
  selectRootFolders,
  selectIsTreeInitialized,
  selectPreventionModalIsOpen,
  selectPreventionModalFolder,
  selectActiveFolderId
} from 'common/components/filesystem/store/selectors';

import Header from './Header';
import Footer from './Footer';
import Folder from './folder';
import TitleInput from 'common/components/tree-layout/components/TitleInput';

import Loader from 'common/components/tree-layout/components/Loader';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import * as filesystemActions from 'common/components/filesystem/store/actions';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

const Tree = () => {
  const { history } = useRouter();

  const isCreating = useSelector(selectIsCreatingFolder);
  const isLoading = useSelector(selectIsLoading);
  const rootFolders = useSelector(selectRootFolders);
  const initialized = useSelector(selectIsTreeInitialized);
  const activeFolderId = useSelector(selectActiveFolderId);

  const preventionModalIsOpen = useSelector(selectPreventionModalIsOpen);
  const preventionModalFolder = useSelector(selectPreventionModalFolder);

  const [setIsCreatingFolder, createFolder, deleteFolder, setPreventionModal] = useActions([
    filesystemActions.setIsCreatingFolder,
    filesystemActions.createFolder,
    filesystemActions.deleteFolder,
    filesystemActions.setPreventionModal
  ]);

  const onCancel = useCallback(() => setIsCreatingFolder(false), []);

  const onSave = useCallback(async name => {
    await createFolder({ name });

    onCancel();
  }, []);

  const onDelete = useCallback(async (id, activeFolderId) => {
    try {
      await deleteFolder({ id });

      if (id === activeFolderId) {
        history.replace(paths.filesystem);
      }
    } catch (error) {}
  }, []);

  return (
    <div className="chapters-tree">
      <Header />

      <div className="filesystem-tree">
        <div className="filesystem-tree__inner gray-scrollbar cpe-4">
          {rootFolders.map(id => (
            <Folder key={id} id={id} />
          ))}
          {isLoading && !initialized ? <Loader /> : null}
        </div>

        {isCreating ? <TitleInput onCancel={onCancel} onSave={onSave} /> : null}

        <Footer />
      </div>

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={() => onDelete(preventionModalFolder?.id, activeFolderId)}
        closeModal={setPreventionModal}
        isOpen={preventionModalIsOpen}
        actionText={'DELETE'}
        header={'Delete'}
        body={
          <>
            Are you sure you want to delete the folder{' '}
            <strong>{preventionModalFolder?.name}</strong>? <br /> All of its content will be
            automatically removed.
          </>
        }
      />
    </div>
  );
};

export default Tree;
