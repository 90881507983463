import Title from 'common/components/forms/digital/components/Title';
import { getFormFieldParams } from 'common/components/forms/digital/state-utils';

const Header = ({ formField }) => {
  const { table_title } = getFormFieldParams(formField);

  return table_title ? (
    <div className="table-form-field-submission__header">
      <Title value={table_title} />
    </div>
  ) : null;
};

export default Header;
