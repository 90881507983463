import { useState } from 'react';
import { Button } from 'reactstrap';

// import SvgRender from 'common/components/general/SvgRender';
// import scan from 'assets/svg/common/scan-icon.svg';

import TicketTypeIcon from 'common/components/crew/TicketTypeIcon';
import UploadFiles from 'common/components/form/inputs/upload-files';
import TicketPrice from 'common/components/tickets/TicketPrice';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';

import { useActions } from 'utils/hooks';
import { upload, download } from 'utils/api';
import { strToNumber } from 'common/utils/numbers';
import CircledButton from 'common/components/buttons/CircledButton';

import { saveDownloadedFile } from 'common/utils/downloads';

import * as ticketingActions from 'store/ticketing/actions';
import ItineraryType from 'common/components/tickets/form/ItineraryType';

export const types = [
  { value: 'air', label: 'Air' },
  { value: 'road', label: 'Road' },
  { value: 'train', label: 'Train' },
  { value: 'other', label: 'Other' }
];

const TicketType = ({
  offerType,
  itineraryType,
  documents,
  selectField,
  previewMode,
  disabledMode,
  price,
  currency,
  accepted,
  hideActions,
  acceptTicket,
  isSubmitting,
  setPreviewMode,
  participantsCount,
  active
}) => {
  const [downloadTicketOfferAttachments] = useActions([
    ticketingActions.downloadTicketOfferAttachments
  ]);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadHandler = async () => {
    try {
      setIsDownloading(true);
      const response = await downloadTicketOfferAttachments({ id: active?.id });

      saveDownloadedFile(response, `${active.id ? active.id : ''}`);
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      console.error(err);
    }
  };

  return (
    <div className="tickets-form-body__type">
      {!disabledMode && !previewMode ? <div className="form-label">SELECT TICKET TYPE:</div> : null}
      <div className={`d-flex align-items-center text-nowrap`}>
        <div>
          {types.map(t => {
            if (previewMode) {
              if (offerType === t.value) {
                return (
                  <div className="ticket-type-button active" key={t.value}>
                    <TicketTypeIcon type={t.value} />
                    <span className="fs-12 fw-medium lh-1 ms-1">{t.label}</span>
                  </div>
                );
              } else {
                return null;
              }
            }

            return (
              <Button
                className={`ticket-type-button ${offerType === t.value ? 'active' : ''}`}
                type="button"
                color="link"
                key={t.value}
                onClick={() => selectField('transportation_type')(t.value)}
              >
                <TicketTypeIcon type={t.value} />
                <span className="fs-12 fw-medium lh-1 ms-1">{t.label}</span>
              </Button>
            );
          })}
        </div>
        <div
          className={`ms-1 d-flex align-items-center justify-content-between ${
            !previewMode ? 'w-100p' : ''
          }`}
        >
          {/* {!previewMode ? (
            <div className="ticket-scan d-flex align-items-center">
              <Button color="white" type="button" className="ticket-scan__button">
                <SvgRender className="me-1" src={scan} style={{ width: 16, height: 16 }} />
                <span className="fs-10 fw-medium lh-1">Scan QR code</span>
              </Button>
            </div>
          ) : null} */}
          <ItineraryType
            itineraryType={itineraryType}
            selectField={selectField}
            previewMode={previewMode}
            disabledMode={disabledMode}
          />
          <div
            className={`ticket-documents ${previewMode && documents.length ? 'preview-mode' : ''} `}
          >
            {previewMode ? (
              documents.length ? (
                <DownloadAttachment
                  isDisabled={isDownloading}
                  data={{
                    attachments: documents ? documents : null
                  }}
                  popupPlacement="right"
                  downloadFiles={downloadHandler}
                  hasAttachments
                />
              ) : null
            ) : (
              <UploadFiles
                upload={upload}
                download={download}
                className={`square-upload mb-0`}
                group="crew.files"
                files={documents ? documents : []}
                onChange={files => selectField('documents')(files)}
                uploadText="Upload doc"
              />
            )}
          </div>
        </div>

        {!accepted && previewMode && !disabledMode ? (
          <div className="d-flex align-items-center h-100p justify-content-end w-100p">
            <CircledButton
              disabled={isSubmitting}
              className="me-1 ticket-action"
              type={'edit'}
              svgStyle={{ width: 14, height: 14 }}
              onClick={() => setPreviewMode(false)}
            />

            <Button
              type="button"
              color="ticket-action"
              className="ticket-action text-nowrap"
              data-cy="accept_ticket"
              onClick={() => acceptTicket(true)}
              disabled={isSubmitting}
            >
              Accept Offer
            </Button>
          </div>
        ) : disabledMode || (previewMode && accepted) ? (
          <div className="d-flex flex-nowrap align-items-center justify-content-end height-inherit ms-auto">
            <TicketPrice
              participantsCount={participantsCount}
              priceClassName="text-secondary-gray"
              offers={[
                {
                  currency,
                  payable_price: strToNumber(price)
                }
              ]}
            />

            {(disabledMode || hideActions) && accepted ? (
              <div className="ms-1 btn-ticket-action ticket-action ticket-action__accepted d-flex align-items-center justify-content-center historical pointer-events-none">
                <span>Accepted</span>
              </div>
            ) : accepted ? (
              <Button
                type="button"
                color="ticket-action"
                className="ticket-action ticket-action__accepted ms-1"
                onClick={() => acceptTicket(false)}
                disabled={isSubmitting}
              >
                <span>Accepted</span>
                <span>Cancel</span>
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TicketType;
