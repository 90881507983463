import TYPES from './types';
import { get, put } from 'utils/api';
import { updateTableRow, resetTableRowUpdate } from 'store/tables/lists/actions';
import { successHandler } from 'common/utils/notifications';

export const getRestrictedFields = () => dispatch => {
  dispatch({ type: TYPES.GET_RESTRICTED_FIELDS.START });

  return get('/jobs/settings/fields')
    .then(response => {
      dispatch({ type: TYPES.GET_RESTRICTED_FIELDS.SUCCESS, payload: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_RESTRICTED_FIELDS.ERROR, payload: error });
      throw error;
    });
};

export const updateRestrictedFields = params => dispatch => {
  const { id, ...rest } = params;
  const table = 'jobs_restricted';
  dispatch({ type: TYPES.UPDATE_RESTRICTED_FIELDS.START });
  dispatch(
    updateTableRow({
      data: { ...params, required: params.required !== undefined ? params.required : true },
      table
    })
  );

  return put(`/jobs/settings/fields/${id}`, rest)
    .then(response => {
      dispatch({ type: TYPES.UPDATE_RESTRICTED_FIELDS.SUCCESS, payload: response.data });
      dispatch(
        updateTableRow({
          data: { ...params, required: params.required !== undefined ? params.required : true },
          table
        })
      );
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Field was updated'
        })
      );
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.UPDATE_RESTRICTED_FIELDS.ERROR, payload: error });
      dispatch(
        resetTableRowUpdate({
          data: { ...params, required: params.required !== undefined ? params.required : true },
          table
        })
      );

      throw error;
    });
};

export const setActive = active => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE, payload: active });
};

export const checkIfFieldIsRequired = field => (_, getState) => {
  const restrictedFields = getState().jobsRestricted.data;
  const requiredFields = restrictedFields.filter(field => field.required);
  const isRequired = requiredFields.some(item => item.field === field);

  return isRequired;
};
