import SvgRender from 'common/components/general/SvgRender';

import save from 'common/assets/svg/actions/save.svg';
import draft from 'common/assets/svg/common/draft.svg';
import archive from 'common/assets/svg/actions/archive.svg';
import close from 'common/assets/svg/actions/close.svg';
import deleteIcon from 'common/assets/svg/actions/delete.svg';
import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';
import editIcon from 'common/assets/svg/actions/edit.svg';
import AverageScoreText from 'crew/profile/main/evaluation/form/components/component-analysis/AverageScoreText';
import PropTypes from 'prop-types';

export const FloatingButtonsGroup = ({ actions, className, fixed, rating = null }) => (
  <div className={`floating-actions ${fixed ? 'floating-actions--fixed' : ''} ${className || ''}`}>
    {actions.map((a, key) => (
      <FloatingButton key={key} {...a} />
    ))}
    {rating ? (
      <div className="floating-button_average">
        <div className="floating-button_average-separator"></div>
        <div className="floating-button_average-button text-primary fs-12">
          <AverageScoreText average={rating} />
        </div>
      </div>
    ) : null}
  </div>
);

const FloatingButton = ({
  onClick,
  text,
  type,
  icon,
  className,
  svgStyle,
  svgClassName,
  svgWrapperClassName,
  textClassName,
  disabled = false
}) => {
  const getIconType = () => {
    switch (type) {
      case 'close':
        return close;
      case 'edit':
        return editIcon;
      case 'save':
        return save;
      case 'draft':
        return draft;
      case 'archive':
        return archive;
      case 'delete':
        return deleteIcon;
      case 'proceed':
      case 'back':
        return arrowFilters;
      case 'save-gray':
        return save;
      default:
        return save;
    }
  };

  return (
    <div
      className={`floating-button floating-button__${type ? type : 'default'}  align-items-center ${
        className || 'd-inline-flex'
      } ${disabled ? 'disabled' : ''}`}
      onClick={() => (disabled ? {} : onClick())}
    >
      <div className={`floating-button__icon ${svgWrapperClassName || ''}`}>
        <SvgRender
          className={`position-relative ${svgClassName || ''}`}
          src={icon ? icon : getIconType()}
          style={svgStyle ? svgStyle : { width: 18, height: 18 }}
        />
      </div>

      <div className={`floating-button__label ${textClassName || ''}`}>
        <span>{text}</span>
      </div>
    </div>
  );
};

FloatingButtonsGroup.propTypes = {
  actions: PropTypes.array,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  rating: PropTypes.oneOf([PropTypes.number, PropTypes.string])
};

export default FloatingButton;
