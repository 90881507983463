import moment from 'moment';
import { createSelector } from 'reselect';
import _findLast from 'lodash/findLast';

const selectReducer = state => state.mga;

const selectIsOnboard = state => state.isOnBoard;

const selectOnboardVessel = state => state?.vessels?.info;

const getKey = (_, key) => key;

const getContractId = (_, __, contract_id) => contract_id;

/* MGA Period  */
export const selectPeriods = createSelector(selectReducer, reducer => reducer.periods);

export const selectHasSetPeriods = createSelector(selectPeriods, periods => periods?.length !== 0);

export const selectPeriodForm = createSelector(selectReducer, reducer => reducer.periodForm);

export const selectPeriodFormIsOpen = createSelector(
  selectPeriodForm,
  periodForm => periodForm.isOpen
);

export const selectPeriodModalFormIsOpen = createSelector(
  selectPeriodForm,
  periodForm => periodForm.isModalOpen
);

export const selectPeriodFormActive = createSelector(
  selectPeriodForm,
  periodForm => periodForm.active
);

export const selectVesselFilter = createSelector(
  selectReducer,
  selectIsOnboard,
  selectOnboardVessel,
  (reducer, isOnboard, onboardVessel) => (isOnboard ? onboardVessel?.id : reducer.vesselFilter)
);

export const selectIsPastMga = createSelector(selectReducer, reducer => reducer.isPastMga);

export const selectPeriodFilter = createSelector(selectReducer, reducer => reducer.periodFilter);

export const selectLatestPeriod = createSelector(
  selectPeriods,
  selectIsOnboard,
  selectVesselFilter,
  selectPeriodFilter,
  selectIsPastMga,

  (periods, isOnBoard, vesselFilter, periodFilter, isPastMga) => {
    if (isPastMga && isOnBoard && periods?.length && !periodFilter) {
      return periods.find(per => per.status?.label !== 'draft');
    }

    if (isOnBoard && !isPastMga)
      return (
        _findLast(periods, p => p.status?.label === 'for-corrections') ||
        periods.find(p => p.status?.label === 'draft')
      );

    if (!vesselFilter) return null;

    return periodFilter ? periods.find(p => p.id == periodFilter) : null;
  }
);

export const selectIsLatestPeriodDraft = createSelector(
  selectLatestPeriod,
  latestPeriod => latestPeriod?.status?.label === 'draft'
);
export const selectIsLatestPeriodForReview = createSelector(
  selectLatestPeriod,
  latestPeriod => latestPeriod?.status?.label === 'for-review'
);
export const selectIsLatestPeriodForCorrections = createSelector(
  selectLatestPeriod,
  latestPeriod => latestPeriod?.status?.label === 'for-corrections'
);
export const selectIsLatestPeriodApproved = createSelector(
  selectLatestPeriod,
  latestPeriod => latestPeriod?.status?.label === 'reviewed'
);

export const canEditPeriodActions = createSelector(
  selectIsOnboard,
  selectLatestPeriod,
  selectIsPastMga,
  (isOnBoard, latestPeriod, isPastMga) => {
    if (isOnBoard)
      return (
        (latestPeriod?.status?.label === 'for-corrections' ||
          latestPeriod?.status?.label === 'draft') &&
        !isPastMga
      );

    return latestPeriod?.status?.label === 'for-review';
  }
);

/* -- */

/* MGA Action  */
export const selectActionForm = createSelector(selectReducer, reducer => reducer.actionForm);

export const selectActionFormIsOpen = createSelector(
  selectActionForm,
  actionForm => actionForm.isOpen
);

export const selectActionModalIsOpen = createSelector(
  selectActionForm,
  actionForm => actionForm.isModalOpen
);

export const selectActionFormActive = createSelector(
  selectActionForm,
  actionForm => actionForm.active
);

export const selectActionFormCell = createSelector(selectActionForm, actionForm => actionForm.cell);

/* -- */

/* MGA Accounts  */
export const selectAccount = createSelector(selectReducer, reducer => reducer.account);

export const selectAccountBalances = createSelector(selectAccount, account => account.balances);
/* -- */

/* Gereric  */
export const selectRefetchTableTrigger = createSelector(
  selectReducer,
  reducer => reducer.shouldRefetchTable
);

const selectCrewContractIdsWarnings = createSelector(
  selectReducer,
  reducer => reducer.warningCrewContractIds
);

export const selectHasCrewWarning = createSelector(
  selectCrewContractIdsWarnings,
  getKey,
  (warnings, contractId) => warnings.includes(contractId)
);

const selectCrewClosingBalanceWarnings = createSelector(
  selectReducer,
  reducer => reducer.warningCrewClosingBalance
);

export const selectLatestPeriodCrewClosingBalanceWarnings = createSelector(
  selectCrewClosingBalanceWarnings,
  getKey,
  selectLatestPeriod,
  (warnings, crewMemberId, latestPeriod) =>
    warnings
      .filter(
        ({ period_id, to }) =>
          period_id === latestPeriod?.id && moment(to).isBefore(latestPeriod.ended_at)
      )
      .filter(({ id }) => (crewMemberId ? id === crewMemberId : id))
);

const selectOverviewWarnings = state => selectReducer(state).warningCrewOverview;

export const selectCrewOverviewWarnings = (state, rowIndex, key) =>
  selectOverviewWarnings(state)?.[rowIndex]?.[key];

/* -- */

/* SETTINGS */
export const selectInventoryDepletionMethod = createSelector(
  selectReducer,
  reducer => reducer.inventoryDepletionMethod
);
/* -- */

/* MGA Wages */
export const selectWageAccountAmounts = createSelector(
  selectReducer,
  reducer => reducer.wageAccountAmounts
);

export const selectCrewWageAccountAmounts = createSelector(
  selectWageAccountAmounts,
  getKey,
  getContractId,
  (wageAccountAmounts, crew_member_id, contractId) =>
    wageAccountAmounts[`${crew_member_id}_${contractId}`]
);
/* -- */
