import React from 'react';
import ContentLoader from 'react-content-loader';

const FormsLoader = ({ isLoading }) => {
  return isLoading ? (
    <div className="mb-1 w-100p">
      <ContentLoader
        speed={1}
        backgroundColor="#eef0f4"
        foregroundColor="#ebebeb"
        viewBox="0 0 400 15"
        height="100%"
        width="auto"
        preserveAspectRatio="xMidYMid meet"
      >
        <rect x="0" y="0" rx="1px" ry="1px" width="100%" height="100%" />
      </ContentLoader>
    </div>
  ) : null;
};

export default FormsLoader;
