import React, { useEffect } from 'react';
import BaseStatusDropdown from 'common/components/statuses/BaseStatusDropdown';
import { useSelector } from 'react-redux';
import { fetchListOptions } from 'store/lists/actions';
import { useDispatch } from 'react-redux';
import { selectListOptionsFromStore } from 'store/lists/selectors';
import PropTypes from 'prop-types';

const PniStatus = ({ value, onChange, view, canChangeStatus }) => {
  const isOnboard = useSelector(state => state.isOnBoard);

  const options = useSelector(state =>
    selectListOptionsFromStore(state, 'crew-medical-case-pni-statuses')
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListOptions('crew-medical-case-pni-statuses'));
  }, [dispatch]);

  return (
    <BaseStatusDropdown
      isLocked={isOnboard || view || !canChangeStatus}
      value={value || options?.[0]}
      onChange={onChange}
      statuses={options}
      statusLabelClassName={'height-24'}
    />
  );
};

PniStatus.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  view: PropTypes.bool,
  canChangeStatus: PropTypes.bool
};

export default PniStatus;
