import React from 'react';

import Editor from 'common/components/form/inputs/Editor';
import { v4 as uuid } from 'uuid';

const EditorInput = ({ className = '', fields, fieldKey, selectField }) => {
  return (
    <div className={className}>
      <Editor
        id={uuid()}
        className="mb-0"
        onChange={html => selectField(fieldKey)(html)}
        value={fields[fieldKey].value}
        hiddenToolbarButtons={['sup', 'sub']}
        plugins={[]}
        {...fields[fieldKey]}
      />
    </div>
  );
};

export default EditorInput;
