import React from 'react';
import PropTypes from 'prop-types';
import Table from './table';

const Inventory = ({ type }) => {
  return <Table type={type} />;
};

Inventory.propTypes = {
  type: PropTypes.oneOf(['pms', 'purchasing']).isRequired
};

export default Inventory;
