import permissions from 'common/utils/permissions/constants';
import paths from 'routing/routes/_paths';
import Mga from 'views/mga';

const routes = [
  {
    path: `${paths.mga}/:view(overview|accounts|actions|inventory|reports)`,
    component: Mga,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.accounting.mga.current.view],
    documentTitle: 'MGA'
  },
  {
    path: `${paths.mga}/past/:view(overview|accounts|actions|inventory|reports)/:vesselID?/:periodID?`,
    component: Mga,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.accounting.mga.past.view],
    documentTitle: 'MGA'
  }
];

export default routes;
