import React from 'react';
import _get from 'lodash/get';
import Relation from 'common/components/jobs/_base/modules/linked-jobs/display/Relation';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import OverFlownText from 'common/components/general/TextOverflow';

const LinkedJob = props => {
  const { properties } = props;

  const { history } = useRouter();

  const title = _get(properties, 'object.title', 'None');
  const type = _get(properties, 'object.pivot.type', 'None');
  const id = _get(properties, 'object.id', 'None');
  const status_id = _get(properties, 'object.status_id', 'None');
  const priority_id = _get(properties, 'object.priority_id', 'None');

  let job = {
    title,
    id,
    priority_id,
    status_id
  };

  const onClick = () => {
    history.push(`${paths.regular_jobs}/${id}`);
  };

  return (
    <div className="d-flex align-items-center activity-linked-job">
      <div>
        <Relation type={type} showText={false} />
      </div>

      <div className="activity-linked-job--single-job d-flex align-items-center">
        <div className="fs-12 text-violet fw-light me-1">{job.id}</div>
        <OverFlownText
          text={job.title}
          width={200}
          id={`job-${id}-linked`}
          onClick={onClick}
          className="fs-12 text-violet fw-light cursor-pointer"
        />
      </div>
    </div>
  );
};

export default LinkedJob;
