import { get, post } from '@/api';
import {
  VesselPmsIntegrationClassType,
  VesselPmsIntegrationStatusBase
} from '@/common/types/reports';
import { VesselSystemMaintenanceJob } from '@/common/types/vessel-systems';

// Job Prototype
export const getVesselSystemMaintenanceJob = async (params: { id: number }) => {
  const response = await get<VesselSystemMaintenanceJob>(
    `/vessel-systems/maintenance-jobs/${params.id}`
  );

  return response.data;
};

type submitVesselPmsAnnualReportParams = {
  vesselId: number;
  classType: VesselPmsIntegrationClassType;
  isClass: boolean;
};

// PMS
export const submitVesselPmsAnnualReport = async ({
  vesselId,
  classType,
  isClass
}: submitVesselPmsAnnualReportParams) => {
  return (
    await post<VesselPmsIntegrationStatusBase>(
      `/vessel-systems/maintenance-jobs/export/class-${classType}`,
      {
        filters: [
          { name: 'vessel_id', operation: 'oneOf', value: [vesselId] },
          { name: 'is_class', operation: '=', value: isClass },
          { name: 'type', operation: 'oneOf', value: ['pms'] }
        ]
      }
    )
  ).data;
};
