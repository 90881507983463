import TYPES from './types';

const INITIAL_STATE = {
  data: []
};

const alarmsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_CAPTAIN_REPORTS_ALARMS:
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
};

export default alarmsReducer;
