import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useFormState } from 'utils/hooks';
import NumberInput from 'common/components/form/inputs/NumberInput';
import Spinner from 'common/components/general/Spinner';
import CollapseBox from 'common/components/collapse/CollapseBox';
import CircledButton from 'common/components/buttons/CircledButton';
import { selectInventoryDepletionMethod } from 'common/components/mga/store/selectors';
import Information from 'common/components/general/Information';
import { getMgaDepletionMethodInfo } from 'common/utils/depletion-method';
import infoLineIcon from 'common/assets/svg/common/info-line.svg';
import SvgRender from 'common/components/general/SvgRender';

import Fifo from './fifo';

import _get from 'lodash/get';
import { useEffect } from 'react';
import { numberToStr, strToNumber } from 'common/utils/numbers';

const AccountRow = ({ formState }) => {
  const { fields, changeField } = useFormState(formState);

  const account = _get(fields, 'account.value');

  return (
    <Row className="fs-12 text-primary mb-1 align-items-center me-3 cpe-2 cms-4">
      <Col className={fields.is_crew.value ? 'ps-3' : ''}>
        {`${account?.code}. ${account?.name}`}

        {account.is_parent_of_crew_accounts ? (
          <div className="d-flex text-violet fs-12 mt-1 cmb-6 pe-16">
            <SvgRender
              src={infoLineIcon}
              style={{ width: 14, height: 14 }}
              className={`cme-12 pointer-events-none cmt-2 text-red`}
            />

            <div className="text-red">
              For Seaman account balances, add a negative number if seaman should receive money from
              vessel. Add a positive, if seaman should give money to the vessel
            </div>
          </div>
        ) : null}
      </Col>

      {!account?.is_parent_of_crew_accounts ? (
        <>
          <Col xs="auto">
            <strong>$</strong>
          </Col>
          <Col xs={2}>
            <NumberInput
              className="mb-0"
              placeholder="Add amount"
              fixedDecimalScale={2}
              innerClassName="text-end"
              onChange={changeField('amount')}
              {...fields.amount}
            />
          </Col>
        </>
      ) : null}
    </Row>
  );
};

const AccountGroup = ({ formState, startedAt }) => {
  const { fields, selectField, subStates, addSubform, removeSubform } = useFormState(formState);
  const itemsSubState = subStates('items');
  const inventoryDepletionMethodLabel = useSelector(selectInventoryDepletionMethod);
  const depletionMethodInfo = getMgaDepletionMethodInfo(inventoryDepletionMethodLabel);
  const pricePerUnitTotal = itemsSubState.reduce(
    (acc, curr) => acc + strToNumber(curr?.state?.total?.value),
    0
  );

  const account = _get(fields, 'account.value');

  useEffect(() => {
    selectField('amount')(pricePerUnitTotal);
  }, [pricePerUnitTotal, selectField]);

  return (
    <CollapseBox
      className={`bg-light-gray rounded-lg mb-1`}
      header={
        <div className="fs-12  text-violet mb-0">
          <Row className="fs-12 text-primary mt-1 mb-1 align-items-center">
            <Col
              className={`${fields.is_crew.value ? 'ps-3' : ''} `}
            >{`${account?.code}. ${account?.name}`}</Col>

            {!account?.is_parent_of_crew_accounts ? (
              <>
                <Col xs="auto" className="cpe-6">
                  <strong>$</strong>
                </Col>
                <Col xs={2} className="me-3 pe-2 d-flex justify-content-end fw-medium">
                  <div className="cpe-4">{numberToStr(fields.amount.value, 2, 2)}</div>
                </Col>
              </>
            ) : null}
          </Row>
        </div>
      }
      innerExpandButton={null}
      bodyClassName="rounded-lg"
      headerClassName="cms-12 cme-6 px-0 mb-0"
      defaultOpen={true}
      disableCollapse
      hideExpandButton
      bodyInnerClassName="cps-12 pe-0 cpb-4"
    >
      <div className="d-flex align-items-center text-violet fs-12 mb-2 cpb-6" id="depletion-method">
        <Information
          className={'cme-12'}
          svgStyle={{ width: 14, height: 14 }}
          tooltipClassname="min-width-fit max-width-412"
          target={'depletion-method'}
          svgIcon={infoLineIcon}
          message={depletionMethodInfo.subTitle}
        />
        <div>*Add Items in your inventory | Inventory Depletion Method:</div>
        <div className="fw-bold cms-4 me-1 cpe-6">{depletionMethodInfo?.name || '-'}</div>
      </div>

      {inventoryDepletionMethodLabel === 'fifo' ? (
        <Fifo
          subState={itemsSubState}
          removeSubform={removeSubform}
          accountId={account?.id}
          startedAt={startedAt}
        />
      ) : null}

      <CircledButton
        onClick={() => addSubform('items')}
        type={'add'}
        style={{ width: 16, height: 16 }}
        label="Add item"
        svgStyle={{ width: 6, height: 6 }}
        className="cmt-2 mb-1"
      />
    </CollapseBox>
  );
};

const InitialBalnces = ({ subStates, isLoading, startedAt }) => {
  const initialBalances = subStates('initial_balances');
  const depletionMethod = useSelector(selectInventoryDepletionMethod);

  return (
    <div>
      <div className="border-top mt-2 mb-2"></div>
      <div className="rounded bg-light-gray text-violet cmb-12 cps-12 cpe-12 py-1 d-flex align-items-center">
        <strong className="fs-12 fw-medium">
          * Please fill in all balances as of today. Note that the number you enter here, will be
          the "Opening Balance" in the period.
        </strong>
      </div>

      <Row className="fw-bold fs-12 text-primary mb-1 me-3 cpe-2 cms-4">
        <Col xs={10}>ACCOUNT</Col>
        <Col xs={2}>BALANCE</Col>
      </Row>

      {isLoading ? (
        <Spinner />
      ) : (
        initialBalances.map((state, i) => {
          const isStoreGroup = state?.state?.account?.value?.store_groups_count;

          return isStoreGroup && (depletionMethod === 'avg' || depletionMethod === 'fifo') ? (
            <AccountGroup
              key={`account-balance-${i}`}
              formState={state}
              index={i}
              startedAt={startedAt}
            />
          ) : (
            <AccountRow key={`account-balance-${i}`} formState={state} index={i} />
          );
        })
      )}
    </div>
  );
};

export default InitialBalnces;
