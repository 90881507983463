import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions, useRedirect } from 'utils/hooks';
import useRouter from 'use-react-router';

import { Row, Col } from 'reactstrap';

import PageLoader from 'common/components/general/PageLoader';
import PageTitle from 'common/components/general/PageTitle';
import PersonProfile from 'common/components/person/profile';

import * as personsActions from 'store/persons/actions';

import { tabsConfig, leftSideInfoConfig } from './config';
import { selectPersonProfile, selectPersonProfileIsLoading } from 'store/persons/selectors';

const Profile = () => {
  const person = useSelector(selectPersonProfile);
  const isLoading = useSelector(selectPersonProfileIsLoading);

  const [getPerson, resetProfile, getPersonDocuments] = useActions([
    personsActions.getPerson,
    personsActions.resetProfile,
    personsActions.getPersonDocuments
  ]);
  const { match } = useRouter();

  const [mode, setMode] = useState('view');

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useRedirect(shouldRedirect);

  const getProfileInfo = async () => {
    try {
      await getPerson({ id: match.params.id });
      getPersonDocuments({ id: match.params.id });
    } catch (e) {
      if (e.status === 404) {
        setShouldRedirect(true);
      }
    }
  };

  useEffect(() => {
    getProfileInfo();

    return () => {
      resetProfile();
    };
  }, [match.params.id]);

  return person ? (
    <div className="person-profile">
      <Row className="align-items-center mb-5 pb-1 height-48">
        <Col xs={'auto'}>
          <PageTitle
            render={() => (
              <div>
                Person / <strong>{person?.full_name}</strong>
              </div>
            )}
          />
        </Col>
      </Row>

      <PersonProfile
        person={person || {}}
        tabsConfig={tabsConfig}
        leftSideInfoConfig={leftSideInfoConfig}
        mode={mode}
        setMode={setMode}
      />
    </div>
  ) : (
    <PageLoader isLoading={isLoading} />
  );
};

export default Profile;
