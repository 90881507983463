import Info from './tabs/info';
import Documents from 'common/components/vessels/profile/tabs/documents';

import { infoTabConfig } from './tabs/info/config';
import permissions from '@/common/utils/permissions/constants';

const vesselConfig = [
  {
    path: 'info',
    label: 'info',
    component: Info,
    props: { config: infoTabConfig },
    permissions: [permissions.onboard.vessel.info.view]
  },
  {
    path: 'documents',
    label: 'documents',
    component: Documents,
    permissions: [
      permissions.onboard.vessel.documents.edit,
      permissions.onboard.vessel.documents.view
    ]
  }
];

export default vesselConfig;
