import React, { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { Row, Col, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import paths from 'routing/routes/_paths';

import { selectOrganizationType } from 'store/account/selectors';

import { formatDate } from 'common/utils/dates';
import { Tabs } from 'common/components/general/Tabs';

import _get from 'lodash/get';
import PageLoader from 'common/components/general/PageLoader';
import PageTitle from 'common/components/general/PageTitle';
import LabelWithTooltip from 'common/components/general/LabelWithTooltip';
import SvgRender from 'common/components/general/SvgRender';

import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import flag from 'common/assets/svg/vessels/flag.svg';
import VesselTypeAbbreviation from 'common/components/vessels/components/VesselTypeAbbreviation';
import GoBack from '../components/GoBack';
import { isAuthorized } from '@/utils/permissions/authorize';
import { useAppSelector } from '@/store/hooks';
import { selectAccount } from '@/store/account/selectors-ts';
import permissions from '@/common/utils/permissions/constants';
import { useDrawer } from 'common/components/drawer';
import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';
import LogsTimelineDrawer from '@/common/components/logs-timeline-drawer';
import { LOGS_ENTITY_TYPES } from '@/common/types/logs';

const VesselProfile = ({ profile, tabs, components }) => {
  const isOnboard = useSelector(state => state.isOnBoard);
  const logsDrawer = useDrawer();
  const [activeTab, setActiveTab] = useState(null);
  const [activeTabSubs, setActiveTabSubs] = useState([]);
  const [activeSub, setActiveSub] = useState(null);

  const { history, match } = useRouter();

  const orgType = useSelector(selectOrganizationType);
  const account = useAppSelector(selectAccount);

  useEffect(() => {
    if (match.params.tab) {
      setActiveTab(match.params.tab);
    } else {
      if (isOnboard) {
        return setActiveTab(
          isAuthorized(account, [permissions.onboard.vessel.info.view])
            ? 'info'
            : isAuthorized(account, [permissions.onboard.vessel.documents.view])
            ? 'documents'
            : null
        );
      } else {
        setActiveTab('info');
      }
    }
  }, [account, isOnboard, match.params.tab]);

  useEffect(() => {
    setActiveTabSubs(
      tabs.find(t => t.path === activeTab) ? tabs.find(t => t.path === activeTab).subs : []
    );
  }, [activeTab]);

  useEffect(() => {
    if (match.params.subtab && match.params.tab !== 'info' && activeTabSubs) {
      const sub = activeTabSubs.find(s => s.path === match.params.subtab);

      setActiveSub(sub ? sub : null);
    } else if (activeTabSubs && activeTabSubs.length) {
      setActiveSub({ ...activeTabSubs[0] });
    } else {
      setActiveSub(null);
    }
  }, [activeTabSubs, match.params.subtab]);

  const renderTabSubContent = () => {
    return React.createElement(activeSub.component, { profile, sub: activeSub });
  };

  const renderTabContent = () => {
    const tab = tabs.find(t => t.label === activeTab);

    if (tab && tab.component) {
      return React.createElement(tab.component, { profile, ...(tab?.props || {}), tabs });
    } else {
      return null;
    }
  };

  const renderTabs = () => {
    return tabs
      .filter(el =>
        el.condition
          ? el.condition({ profile, organizationType: orgType })
          : el.permissions
          ? isAuthorized(account, el.permissions)
          : true
      )
      .map(tab => {
        return (
          <NavItem key={tab.path} active={tab.path === activeTab}>
            <Link
              className="nav-link"
              to={`${paths.vessels}${isOnboard ? '' : `/${profile.id}`}/${tab.path}`}
            >
              {tab.label}
            </Link>
          </NavItem>
        );
      });
  };

  const renderTitle = profile => {
    return (
      <Row className="g-0 align-items-center">
        <Col xs={'auto'} className={'d-flex align-items-center'}>
          <GoBack />
        </Col>
        <Col xs={3} className="d-flex align-items-center">
          {profile.prefix ? <div className="fw-bold me-1">{profile.prefix.name}</div> : null}
          {isOnboard ? (
            <div className="fw-bold my-1">{profile.name}</div>
          ) : (
            <Col xs={10}>
              <Select
                className="mb-0"
                isAsync
                loadOptions={search => getAsyncOptions(search, 'vessels')}
                getOptionValue={option => option.id}
                defaultOptions={() => getInitialAsyncValues('vessels')}
                getOptionLabel={option => option.name}
                value={{ id: profile.id, name: profile.name }}
                onChange={options => {
                  history.push({
                    pathname: `${paths.vessels}/${options.id}/${activeTab}${
                      activeSub ? `/${activeSub.path}` : ''
                    }`
                  });
                }}
              />
            </Col>
          )}
        </Col>
        {profile.type && (
          <Col xs={'auto'} className="d-flex align-items-center ms-3">
            <VesselTypeAbbreviation type={profile.type} />
            <span className="fs-10 fw-normal  ps-1">{profile.type.name}</span>
          </Col>
        )}
        {profile.flag_country && (
          <Col className="d-flex align-items-center ms-3">
            <SvgRender src={flag} style={{ width: 20, height: 22 }} />
            <span className="fs-10 fw-normal  ps-1">{profile.flag_country.name}</span>
          </Col>
        )}
      </Row>
    );
  };

  const renderTooltipContent = data => {
    return (
      <div className="d-flex flex-column align-items-start">
        {!data.share ? (
          <div>Vessel is not shared any more</div>
        ) : (
          <>
            <div className="fw-bold">
              -
              <span className="text-capitalize">
                {_get(data, 'share.organization.domain').split('.')[0]} (Owner)
              </span>
            </div>
            {data.share.sharees.map((el, i) => {
              return (
                <div key={i.toString()}>
                  -<span>{_get(el, 'organization.domain').split('.')[0]}</span>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const actualTabs = renderTabs();

  return (
    <div className={`vessel-profile vessel-profile__${activeTab}`}>
      <PageLoader isLoading={profile.loading} />
      {!profile.loading && profile.id && (
        <>
          <Row className="align-items-center justify-content-between mb-4">
            <Col className="d-flex align-items-center">
              <PageTitle className="mb-0 mt-0 w-100p" render={() => renderTitle(profile)} />
            </Col>
            <Col className="d-flex justify-content-end align-items-center pe-7">
              {profile.origin_organization_id && (
                <LabelWithTooltip
                  renderTooltipContent={() => renderTooltipContent(profile)}
                  id={profile.id}
                  text="Shared"
                />
              )}
              {!isOnboard ? <components.Actions profile={profile} /> : null}
              {!isOnboard ? <ToggleLogsTimelineDrawerButton onClick={logsDrawer.open} /> : null}
            </Col>
          </Row>
          <Row className="g-0">
            <Col
              xs={12}
              className="d-flex justify-content-between border-bottom mb-2 nav-no-border"
            >
              {actualTabs?.length ? <Tabs pills>{actualTabs}</Tabs> : null}
              <Col xs={'auto'} className="d-flex align-items-center fs-10">
                <strong>IMO NO.&nbsp;&nbsp;</strong>
                <div>{profile.imo_no || '-'}</div>
                <div className="small-separator mx-1"></div>
                <strong>BUILT&nbsp;&nbsp;</strong>
                <div>{formatDate(profile.build_date, { time: true })}</div>
                <div className="small-separator mx-1"></div>
                <strong>CODE&nbsp;&nbsp;</strong>
                <div>{profile.code || '-'}</div>
              </Col>
            </Col>
          </Row>

          <Row className="ps-1 vessel-profile__sub">
            {activeSub && activeSub.path && activeTabSubs && activeTab !== 'info' ? (
              <Col xs={12} className="mt-2 vessel-profile__sub-tabs">
                <Tabs tabs>
                  {activeTabSubs
                    .filter(
                      t => !t.condition || t.condition({ profile, organizationType: orgType })
                    )
                    .map(tab => (
                      <NavItem key={tab.path} active={tab.path === activeSub.path}>
                        <Link
                          className="nav-link"
                          to={`${paths.vessels}${isOnboard ? '' : `/${profile.id}`}/${activeTab}/${
                            tab.path
                          }`}
                        >
                          {tab.label}
                        </Link>
                      </NavItem>
                    ))}
                </Tabs>
              </Col>
            ) : null}

            {activeSub && activeSub.component ? (
              <Col xs={12} className="mt-2 vessel-profile__sub-content">
                {renderTabSubContent()}
              </Col>
            ) : (
              <Col xs={12}>{renderTabContent()}</Col>
            )}
          </Row>
        </>
      )}

      {!isOnboard ? (
        <LogsTimelineDrawer
          drawer={logsDrawer}
          entityId={profile.id}
          entityType={LOGS_ENTITY_TYPES.vessel}
        />
      ) : null}
    </div>
  );
};

export default VesselProfile;
