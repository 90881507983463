import Notifications from 'react-notification-system-redux';
import store from 'store';
import { getErrorMessage } from '@/store/notificationsMiddleware';

const defaultParams = {
  position: 'br',
  autoDismiss: 10
  // autoDismiss: false
};

export function successHandler(params = {}) {
  return dispatch => {
    if (import.meta.env.MODE !== 'test')
      dispatch(
        Notifications.success({
          ...defaultParams,
          ...params
        })
      );
  };
}

export function errorHandler(params = {}) {
  return dispatch => {
    if (import.meta.env.MODE !== 'test')
      dispatch(
        Notifications.error({
          ...defaultParams,
          ...params
        })
      );
  };
}

export function infoHandler(params = {}) {
  return dispatch => {
    if (import.meta.env.MODE !== 'test')
      dispatch(
        Notifications.info({
          ...defaultParams,
          ...params
        })
      );
  };
}

export function warningHandler(params = {}) {
  return dispatch => {
    if (import.meta.env.MODE !== 'test')
      dispatch(
        Notifications.warning({
          ...defaultParams,
          ...params
        })
      );
  };
}

export const handleApiErrorNotification = error => {
  const message = getErrorMessage(error);
  if (message) {
    store.dispatch(errorHandler({ title: 'Uh oh!', message }));
  }
};
