import { saveAs } from 'file-saver';
import { AxiosResponse } from 'axios';
import { download } from '@/api';

export const getResponseFilename = (response: AxiosResponse): string => {
  const { headers } = response;
  let filename = '';

  if (headers['content-disposition']) {
    const pattern = /filename=(?:"([^"]+)"|([^\s;]+))/;
    const patternStar = /filename\*=[^']+'[^']*'([^;]+)/;

    const match = headers['content-disposition'].match(pattern);
    const matchStar = headers['content-disposition'].match(patternStar);

    filename = (match ? match?.[1] || match?.[2] : matchStar?.[1]) || '';
  } else if (headers?.filename) {
    filename = headers?.filename;
  }

  return filename || '';
};

interface DownloadParams {
  requestParams?: Record<string, unknown>;
  parsed?: boolean;
  customHeaders?: object;
}

export const handleFileDownload = async (
  attachment: { url?: string; id?: number | string; filename?: string },
  returnBlob = false,
  downloadParams: DownloadParams = {}
) => {
  const { requestParams, parsed, customHeaders } = downloadParams;

  try {
    const response = await download<BlobPart>(
      attachment?.url ? attachment.url : `/files/${attachment.id}`,
      requestParams,
      parsed,
      customHeaders
    );
    if (!response || !response?.headers) return null;

    const contentType: string | undefined = response.headers['content-type'];
    const name = getResponseFilename(response) || attachment?.filename;

    if ((window.navigator as any).msSaveOrOpenBlob && !returnBlob) {
      (window.navigator as any).msSaveOrOpenBlob(response.data, name);
    } else if (contentType && response.data) {
      const blob = new Blob([response.data], { type: contentType });

      if (returnBlob) {
        return blob;
      } else {
        saveAs(blob, name);
        return response;
      }
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
