import { useQuery } from '@tanstack/react-query';
import { GET_VESSEL_STOPPAGE_FUEL_GRADES, VESSEL_ACTIVE_PLANNED_ROUTE } from './query-keys';
import { getVesselActivePlannedRoute, getVesselStoppageFuelGrades } from './api';
import { VesselActivePlannedRouteQueryOptions } from '@/common/types/vessel';
import { useAppSelector } from '@/store/hooks';
import { selectEventVessel } from '@/events/store/events/selectors';

export const useVesselActivePlannedRouteQuery = ({
  id,
  enabled = false
}: VesselActivePlannedRouteQueryOptions) => {
  return useQuery({
    queryKey: [id, VESSEL_ACTIVE_PLANNED_ROUTE],
    queryFn: () => getVesselActivePlannedRoute({ id }),
    enabled: !!id && enabled,
    retry: false
  });
};

export const useGetVesselStoppageFuelGradesQuery = () => {
  const vesselId: number | undefined = useAppSelector(selectEventVessel)?.id;

  return useQuery({
    queryKey: [GET_VESSEL_STOPPAGE_FUEL_GRADES, vesselId],
    queryFn: () => getVesselStoppageFuelGrades({ vesselId }),
    enabled: !!vesselId
  });
};
