import paths from 'routing/routes/_paths';

/*

blockTextColor
blockFontSize
blockBorderColor
blockHeight
blockBorderRadius

*/

const styleDefinitions = [
  {
    type: 'attr',
    key: 'fill',
    style: 'blockTextColor',
    element: 'text',
    defaultValue: '#808080'
  },
  {
    type: 'attr',
    key: 'font-size',
    style: 'blockFontSize',
    element: 'text',
    defaultValue: 10
  },
  {
    type: 'attr',
    key: 'rx',
    style: 'blockBorderRadius',
    element: 'block',
    defaultValue: 1
  },
  {
    type: 'attr',
    key: 'font-weight',
    style: 'blockFontWeight',
    element: 'text',
    defaultValue: '400'
  }
];

const getRectHeight = getPlotStyle => {
  return getPlotStyle('blockHeight', 16);
};

const getColor = getPlotStyle => {
  return getPlotStyle('blockBorderColor', '#808080');
};

const renderPlot = ({ plotID, plot, getPlotStyle, xFn, yFn, element }) => {
  element
    .selectAll(`#${plotID} rect`)
    .data(plot.data)
    .attr('x', xFn)
    .attr('y', yFn)
    .attr('stroke', datum => datum.color || getColor(getPlotStyle));

  element
    .selectAll(`#${plotID} text`)
    .data(plot.data)
    .attr('x', xFn)
    .attr('y', yFn)
    .attr('stroke', datum => datum.color || getColor(getPlotStyle))
    .text(datum => datum.label);

  styleDefinitions.forEach(styleDefinition => {
    if (styleDefinition.element === 'text') {
      element
        // Here is the .selectAll again!
        .selectAll(`#${plotID} text`)
        [styleDefinition.type](
          styleDefinition.key,
          getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
        );
    } else if (styleDefinition.element === 'block') {
      element
        .selectAll(`#${plotID} rect`)
        [styleDefinition.type](
          styleDefinition.key,
          getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
        );
    }
  });
};

const WrapperLink = ({ children, dataPoint }) => (
  <a
    style={{ pointerEvents: 'all', cursor: 'pointer' }}
    target="_blank"
    rel="noopener noreferrer"
    href={`${paths.captain_reports}/${dataPoint[dataPoint.plot.linkKey]}`}
  >
    {children}
  </a>
);

const UselessWrapper = ({ children }) => <>{children}</>;

const renderComponent = ({ plotID, plot, getPlotStyle }) => {
  const rectHeight = getRectHeight(getPlotStyle);
  return (
    <g id={plotID} key={plotID}>
      {plot.data.map((dataPoint, index) => {
        const WrapperElement = dataPoint.plot.linkKey ? WrapperLink : UselessWrapper;

        return (
          <WrapperElement dataPoint={dataPoint} key={dataPoint.id + '-' + index}>
            <g>
              <rect
                width={rectHeight}
                height={rectHeight}
                fill="#ffffff"
                transform={`translate(-${rectHeight / 2}, -${rectHeight / 2})`}
                strokeWidth="1"
              ></rect>
              <text
                baselineShift="0%"
                kerning="0"
                dominantBaseline="central"
                textAnchor="middle"
              ></text>
            </g>
          </WrapperElement>
        );
      })}
    </g>
  );
};

const timemarker = {
  renderPlot,
  renderComponent
};

export default timemarker;
