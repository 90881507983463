import React from 'react';
import resubmitted from 'common/assets/svg/common/history.svg';
import SvgRender from 'common/components/general/SvgRender';

const FormResubmittedLabel = () => {
  return (
    <div className="border-radius-3 bg-light-gray-2  text-violet d-flex align-items-center px-1 height-22 ms-1 ">
      <SvgRender src={resubmitted} style={{ height: 13, width: 13 }} />
      <span className="fs-10 ms-1">Resubmitted</span>
    </div>
  );
};

export default FormResubmittedLabel;
