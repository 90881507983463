import { stringField } from 'common/utils/form/fieldTypes';

const config = {
  comments: stringField(),
  atSeaWatchKeeping: {
    from: stringField({ required: true }),
    to: stringField({ required: true })
  },
  atSeaNonWatchKeeping: {
    from: stringField({ required: true }),
    to: stringField({ required: true })
  },
  atPortWatchKeeping: {
    from: stringField({ required: true }),
    to: stringField({ required: true })
  },
  atPortNonWatchKeeping: {
    from: stringField({ required: true }),
    to: stringField({ required: true })
  }
};

export default config;
