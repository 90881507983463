import React from 'react';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  selectPreviousNavigationalByKey,
  selectFuelGradeCategoryConsumptionsTotalSum
} from 'captain-reports/store/selectors';
import { strToNumber, trimFloatNumber, numberToStr } from 'common/utils/numbers';
import { getCustomFormat } from 'common/utils/dates';
import {
  displayInstructedValue,
  showInstructedComparisonOperatorSymbol
} from 'captain-reports/templates/components/helpers';
import useValue from 'captain-reports/utils/useValue';
import { useActions, useUpdateEffect } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';

export const isInstructedSpeedWog = instructed_speed_consumption_target =>
  instructed_speed_consumption_target === 'weather_routing_instruction';

export const isInstructedSpeedEta = instructed_speed_consumption_target =>
  instructed_speed_consumption_target === 'eta';

const Labels = ({ field }) => (
  <Row className="mb-1">
    <Col xs={3}>
      <strong className="fs-10 lh-1">
        {field.label} <span className="text-violet">{field.subLabel}</span>
      </strong>
    </Col>
    <Col xs={3}>
      <strong className="fs-10 lh-1">
        FO CONS <span className="text-violet">mt/d</span>
      </strong>
    </Col>
    <Col xs={3}>
      <strong className="fs-10 lh-1">
        GO CONS <span className="text-violet">mt/d</span>
      </strong>
    </Col>
  </Row>
);

const RealCons = ({ categories }) => {
  const consumptions_sum_total = useSelector(state =>
    selectFuelGradeCategoryConsumptionsTotalSum(state, categories)
  );

  return <strong className="fs-12 lh-1">{numberToStr(consumptions_sum_total)}</strong>;
};

const Instructed = () => {
  const average_instructed_speed =
    useSelector(state => selectPreviousNavigationalByKey(state, 'average_instructed_speed')) || 0;

  const average_instructed_fo_consumption =
    useSelector(state =>
      selectPreviousNavigationalByKey(state, 'average_instructed_fo_consumption')
    ) || 0;

  const average_instructed_go_consumption =
    useSelector(state =>
      selectPreviousNavigationalByKey(state, 'average_instructed_go_consumption')
    ) || 0;

  const instructed_speed_consumption_target =
    useSelector(state =>
      selectPreviousNavigationalByKey(state, 'instructed_speed_consumption_target')
    ) || 0;

  const instructed_eta =
    useSelector(state => selectPreviousNavigationalByKey(state, 'instructed_eta')) || null;

  const instructed_speed_minimum_allowance =
    useSelector(state =>
      selectPreviousNavigationalByKey(state, 'instructed_speed_minimum_allowance')
    ) || null;

  const instructed_consumption_fo_maximum_allowance =
    useSelector(state =>
      selectPreviousNavigationalByKey(state, 'instructed_consumption_fo_maximum_allowance')
    ) || null;

  const instructed_consumption_go_maximum_allowance =
    useSelector(state =>
      selectPreviousNavigationalByKey(state, 'instructed_consumption_go_maximum_allowance')
    ) || null;

  const isWog = isInstructedSpeedWog(instructed_speed_consumption_target);

  return (
    <Row className="align-items-center">
      {isInstructedSpeedEta(instructed_speed_consumption_target) ? (
        <Col xs={9} className="pt-1">
          <strong className="fs-12 lh-1">Target ETA:</strong>
          <span className="fs-12 lh-1 ps-1">
            {instructed_eta ? getCustomFormat(instructed_eta, 'DD-MM-YYYY HH:mm') : '-'}
          </span>
        </Col>
      ) : (
        <>
          <Col xs={3} className="pt-1">
            <strong className="fs-12 lh-1 text-nowrap">
              {isWog
                ? 'WOG'
                : displayInstructedValue(
                    average_instructed_speed,
                    instructed_speed_minimum_allowance
                  )}
            </strong>
          </Col>
          <Col xs={3} className="pt-1">
            <strong className="fs-12 lh-16 text-nowrap">
              {isWog ? (
                'WOG'
              ) : (
                <>
                  {numberToStr(average_instructed_fo_consumption)}
                  {instructed_consumption_fo_maximum_allowance
                    ? ` (${showInstructedComparisonOperatorSymbol(
                        average_instructed_fo_consumption,
                        instructed_consumption_fo_maximum_allowance
                      )} ${trimFloatNumber(
                        strToNumber(instructed_consumption_fo_maximum_allowance)
                      )})`
                    : null}
                </>
              )}
            </strong>
          </Col>
          <Col xs={3} className="pt-1">
            <strong className="fs-12 lh-16 text-nowrap">
              {isWog ? (
                'WOG'
              ) : (
                <>
                  {numberToStr(average_instructed_go_consumption)}
                  {instructed_consumption_go_maximum_allowance
                    ? ` (${showInstructedComparisonOperatorSymbol(
                        average_instructed_go_consumption,
                        instructed_consumption_go_maximum_allowance
                      )} ${trimFloatNumber(
                        strToNumber(instructed_consumption_go_maximum_allowance)
                      )})`
                    : null}
                </>
              )}
            </strong>
          </Col>
        </>
      )}

      <Col xs={3} className="pt-1 border-start-white">
        <strong className="fs-12 lh-16 text-violet">Instructed</strong>
      </Col>
    </Row>
  );
};

const SpeedOG = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const speedValue = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);

  const steamingTime = useValue({ key: 'steaming_time' });
  const distance_run = useValue({ key: 'distance_run' });

  useUpdateEffect(() => {
    const steamingHours = steamingTime / 60;
    if (!steamingHours || (distance_run !== 0 && !distance_run)) {
      setValue({ key: 'speed_og', value: null });
      return;
    }
    let speedOg = distance_run / steamingHours;
    if (speedOg !== 0) {
      speedOg = +speedOg.toFixed(3);
    }
    setValue({ key: 'speed_og', value: speedOg });
  }, [steamingTime, distance_run]);

  return (
    <div className="template-instructed-speed">
      <Labels field={field} />
      <Row>
        <Col xs={3} className="d-flex align-items-center">
          <strong className="fs-12 lh-16">{numberToStr(speedValue)}</strong>
        </Col>
        <Col xs={3} className="d-flex align-items-center">
          <RealCons categories={['fo']} />
        </Col>
        <Col xs={3} className="d-flex align-items-center">
          <RealCons categories={['go', 'do']} />
        </Col>
        <Col xs={3} className="d-flex align-items-center border-start-white h-100p mt-1 pb-1">
          <strong className="fs-12 lh-16 text-violet">Performed</strong>
        </Col>
      </Row>
      <Instructed />
    </div>
  );
};

export default SpeedOG;
