import React, { FC } from 'react';

import SvgRender from 'common/components/general/SvgRender';
import { borderedFiles } from '@/ts-common/utils/files.ts';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import { BorderedFileType } from '@/ts-common/types/files';

type ExportIconProps = {
  white?: boolean;
  onClick: () => void;
  className?: string;
  textClassname?: string;
  svgStyle?: React.CSSProperties;
  extension?: BorderedFileType;
  disabled?: boolean;
  showTooltip?: boolean;
  tooltipMessage?: string;
  text?: string;
};

const ExportIcon: FC<ExportIconProps> = ({
  white,
  onClick,
  className,
  textClassname,
  svgStyle,
  extension = '',
  disabled,
  showTooltip = false,
  tooltipMessage = 'File is processing',
  text = 'Export Pdf'
}) => {
  const { avoidRender, tooltipID } = useTooltipID('export-icon-tooltip');

  if (avoidRender) return null;

  return (
    <div
      id={tooltipID || ''}
      onClick={() => (disabled ? null : onClick())}
      className={`base-transition ${
        disabled ? '' : 'cursor-pointer'
      } d-inline-flex align-items-center${disabled ? ' opacity-4' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <SvgRender
        src={borderedFiles[extension] || ''}
        style={svgStyle || { width: 12, height: 16 }}
      />
      <span
        className={`ms-1 text-${white ? 'white' : 'primary'} lh-12 ${
          textClassname ? textClassname : 'fw-bold fs-10'
        }`}
      >
        {text}
      </span>

      {showTooltip && tooltipID ? <Tooltip target={tooltipID}>{tooltipMessage}</Tooltip> : null}
    </div>
  );
};

export default ExportIcon;
