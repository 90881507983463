import { useState } from 'react';
import Table from 'common/components/table';
import { selectEventId } from 'events/store/events/selectors';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import companyIcon from 'common/assets/svg/common/company.svg';
import SvgRender from 'common/components/general/SvgRender';
import paths from 'routing/routes/_paths';
import { deleteLinkedPurchaseCase } from 'events/store/event-modules/linked-purchase-cases/actions';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import remove from 'common/assets/svg/actions/remove.svg';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import vesselIcon from 'common/assets/svg/common/vessels.svg';

import Priority from 'common/components/general/Priority';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import Status from 'common/components/purchasing/requisition/components/Status';
import { selectLinkedPurchaseCasesData } from 'events/store/event-modules/linked-purchase-cases/selectors';
import budgetIcon from 'common/assets/svg/accounting/budget.svg';
import Tooltip from 'common/components/general/Tooltip';
import { TableTypes } from 'common/entities/tables/TableTypes';
import permissions from '@/common/utils/permissions/constants';

const LinkedPurchaseCasesTable = ({ table }) => {
  const [modal, setModal] = useState({ isOpen: false, id: null });

  const dispatch = useDispatch();

  const eventId = useSelector(selectEventId);
  const linkedCases = useSelector(selectLinkedPurchaseCasesData);

  const onModalAccept = async () => {
    try {
      await dispatch(deleteLinkedPurchaseCase({ id: eventId, caseId: modal?.id })).unwrap();

      table.refetchData();
    } catch (e) {
      console.error(e);
    }
    setModal({ isOpen: false, id: null });
  };

  return linkedCases?.length ? (
    <>
      <Table
        hideTableReorder
        hideTableFilters
        hideTableSearch
        hideTopPagination
        handleTableSort={({ sorting }) => table.refetchData({ sorting })}
        rows={{
          priority: data => <Priority value={data.priority?.label} />,
          code: data => (
            <div className="d-flex justify-content-between">
              <TextWithTooltip className="h-auto pe-1">{data.code}</TextWithTooltip>
              <SvgRender
                src={!data.is_for_vessel ? companyIcon : vesselIcon}
                style={{ width: 12, height: 12 }}
                className={`cmt-2 ${!data.is_for_vessel ? 'text-moody-blue' : 'text-primary'}`}
              />
            </div>
          ),
          vessel_company: data => (
            <div className="d-flex align-items-center ">
              <TextWithTooltip>
                {data.is_for_vessel
                  ? _get(data, 'vessel.name', '-')
                  : _get(data, 'company.name', '-')}
              </TextWithTooltip>
            </div>
          ),
          description: data => (
            <div className="d-flex justify-content-between">
              <TextWithTooltip className="h-auto pe-1">{data?.description}</TextWithTooltip>
              {data?.include_in_budget ? (
                <>
                  <SvgRender
                    src={budgetIcon}
                    style={{ width: 14, height: 14 }}
                    className="ms-auto text-warning"
                    id={`include_in_budget_${data?.id}`}
                  />

                  <Tooltip
                    innerClassName="min-width-fit max-width-fit"
                    target={`include_in_budget_${data?.id}`}
                    fade={false}
                  >
                    Include in Budget
                  </Tooltip>
                </>
              ) : null}
            </div>
          ),
          tag_id: data =>
            data?.tags?.length ? (
              <NamesInTooltip
                target={`requisition-tags-${data.id}`}
                names={data.tags.map(tag => tag.name)}
              />
            ) : (
              '-'
            ),
          category_id: data =>
            data?.categories?.length ? (
              <NamesInTooltip
                target={`requisition-categories-${data.id}`}
                names={data.categories.map(category => category.name)}
              />
            ) : (
              '-'
            ),
          status: data => <Status status={data?.status} isBullet hasTooltip />,
          actions: data => ({
            options: [
              {
                label: 'View',
                icon: eyeSvg,
                onClick: () => window.open(`${paths.purchasing_requisitions}/${data.id}`),
                permissions: [permissions.office.purchasing.requisitions.edit]
              },
              {
                label: 'Remove Link',
                icon: remove,
                onClick: () => {
                  setModal({ isOpen: true, id: data.id });
                }
              }
            ]
          })
        }}
        {...table}
      />

      <DangerousActionModal
        action={'Delete'}
        transparent
        onAccept={onModalAccept}
        closeModal={() => setModal({ isOpen: false, id: null })}
        isOpen={modal.isOpen}
        actionText={'REMOVE'}
        header={'Remove Linked Purchase Case'}
        body={
          <div className="text-center">
            You are about to remove this <br />
            linked purchase case.
            <br />
            Are you sure you want to continue?
          </div>
        }
      />
    </>
  ) : null;
};

LinkedPurchaseCasesTable.propTypes = {
  table: TableTypes
};

export default LinkedPurchaseCasesTable;
