import React from 'react';
import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrows/arrow-filters.svg';

const GoBackButton = ({ setActivePost }) => {
  return (
    <div
      onClick={() => setActivePost(null)}
      className="d-flex align-items-center fs-16 cursor-pointer"
    >
      <SvgRender src={arrow} style={{ width: 14, height: 14, transform: 'rotate(-90deg)' }} />
      <span className="cms-14">Back</span>
    </div>
  );
};

GoBackButton.propTypes = {
  setActivePost: PropTypes.func.isRequired
};

export default GoBackButton;
