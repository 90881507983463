import React from 'react';
import PropTypes from 'prop-types';

const ShadowBox = ({
  className,
  children,
  flat = false,
  color = '',
  whiteOnHover = false,
  flatOnHover = false,
  shadowOnHover = false,
  onClick = null,
  ...rest
}) => {
  return (
    <div
      className={`shadow-box-wrap ${className ? className : ''} ${flat ? 'flat-box' : ''} ${
        whiteOnHover ? 'white-on-hover' : ''
      } ${flatOnHover ? 'flat-on-hover' : ''} ${shadowOnHover ? 'shadow-on-hover' : ''} ${color}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
};

ShadowBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  flat: PropTypes.bool, // Use to remove shadow,
  color: PropTypes.oneOf([
    'light-1',
    'light-2',
    'light-3',
    'light-4',
    'platinum-1',
    'white',
    'primary',
    'lighter-blue',
    'ligher-orange',
    'ligher-violet'
  ]),
  whiteOnHover: PropTypes.bool,
  flatOnHover: PropTypes.bool,
  onClick: PropTypes.func,
  shadowOnHover: PropTypes.bool
};

export default ShadowBox;
