import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import VersionStatus from 'manuals/components/VersionStatus';
import ApprovedBy from 'manuals/components/ApprovedBy';
import DateWithIcon from 'common/components/dates/DateWithIcon';
import ManualVersionLink from 'manuals/components/ManualVersionLink';
import VersionActions from 'manuals/components/VersionActions';
import CircledButton from 'common/components/buttons/CircledButton';

import { getLastVersion } from 'manuals/_helpers';
import Progress from 'common/components/general/ProgressLine';
import { useDispatch } from 'react-redux';
import { setReviewProcessDrawerIsOpen } from 'common/components/review-process/store/actions';
import ReviewProcessDrawer from 'common/components/review-process';
import entityActions from 'common/components/review-process/entity-actions';

const VersionRow = ({ version, manualId, isOnBoard }) => {
  const dispatch = useDispatch();
  return (
    <Row className={`manual-version-row text-${version.status === 'past' ? 'violet' : 'primary'}`}>
      <Col xs="auto" className="ps-2 cms-4"></Col>
      <Col xs={3} />
      <Col xs={1} className="manual-version-row__cell bordered-left d-flex align-items-center">
        <ManualVersionLink
          manualId={manualId}
          versionId={version.status === 'active' ? '' : version.id}
        >
          <VersionStatus status={version.status} title={version.title} />
        </ManualVersionLink>
      </Col>
      <Col xs={isOnBoard ? 2 : 1} className={'manual-version-row__cell d-flex align-items-center'}>
        {version.status !== 'working' ? (
          <DateWithIcon className="fw-medium" date={version.valid_from} hideIcon={true} />
        ) : null}
      </Col>
      <Col xs={isOnBoard ? 2 : 1} className="manual-version-row__cell d-flex align-items-center">
        {version.status !== 'working' ? (
          <DateWithIcon className="fw-medium" date={version.valid_until} hideIcon={true} />
        ) : null}
      </Col>
      <Col
        xs={1}
        className="manual-version-row__cell py-0 pe-0 bordered-right d-flex align-items-center"
      >
        <ApprovedBy version={{ id: version.id, approved_by: version.approved_by }} />
      </Col>

      <Col className="manual-version-row__cell py-0 pe-0 me-2 bordered-right d-flex align-items-center">
        {version?.review_process?.steps?.length ? (
          <Progress
            steps={version.review_process.steps}
            maxLimit={5}
            onClick={() =>
              dispatch(setReviewProcessDrawerIsOpen(true, version?.review_process?.entity_id))
            }
          />
        ) : (
          '-'
        )}

        <VersionActions manualId={manualId} version={version} />
        <ManualVersionLink
          manualId={manualId}
          versionId={version.status === 'active' ? '' : version.id}
          className="manual-version-link me-1 ms-1"
        >
          <CircledButton type={'view'} svgStyle={{ width: 12, height: 12 }} />
        </ManualVersionLink>
      </Col>
      <ReviewProcessDrawer entityAction={entityActions.manualVersion} />
    </Row>
  );
};

const Versions = ({ versions, manualId }) => {
  const lastVersion = getLastVersion(versions);
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <div>
      {versions.map(v => {
        if (v.id === lastVersion.id) return null;

        return <VersionRow manualId={manualId} key={v.id} version={v} isOnBoard={isOnBoard} />;
      })}
    </div>
  );
};

export default Versions;
