import { createSelector } from 'reselect';

const selectState = state => state.shifts;
const getKey = (_, key) => key;
const getSecKey = (_, __, key) => key;

export const selectShiftTypes = createSelector(selectState, state => state.shiftTypes);

export const selectShifts = createSelector(selectState, state =>
  state.shifts.filter(crew => crew.contract)
);

const selectWarnings = createSelector(selectState, state => state.warnings);

export const selectCrewWarnings = createSelector(
  selectWarnings,
  getKey,
  (warnings, crewID) => warnings?.[crewID]
);

export const selectCrewDateWarnings = createSelector(
  selectCrewWarnings,
  getSecKey,
  (crewWarnings, date) => crewWarnings?.[date] || []
);

export const selectHighlightedSeaman = createSelector(
  selectState,
  state => state.highlightedSeaman
);

export const selectIsLoading = createSelector(selectState, state => state.isLoading);

export const selectPeriodType = createSelector(selectState, state => state.periodType);

export const selectStartDate = createSelector(selectState, state => state.startDate);

export const selectSelectedContract = createSelector(selectState, state => state.selectedContract);

export const selectEndDate = createSelector(selectState, state => state.endDate);

export const selectSelectedRankFilter = createSelector(
  selectState,
  state => state.selectedRankFilter
);

export const selectSelectedVesselFilter = state => selectState(state).selectedVesselFilter;

export const selectshiftsPortSeaState = createSelector(
  selectState,
  state => state.shiftsPortSeaState
);

export const selectSelectedSorting = createSelector(selectState, state => state.selectedSorting);

export const selectActiveInternalShift = createSelector(selectState, state => state.activeShift);
export const selectCrewActiveInternalShift = createSelector(
  selectActiveInternalShift,
  getKey,
  (activeShift, crewID) => {
    return activeShift[crewID];
  }
);

export const selectActiveShiftComments = createSelector(
  selectState,
  state => state.activeShiftComments
);
export const selectCrewShiftComments = createSelector(
  selectActiveShiftComments,
  getKey,
  (activeShiftComments, crewID) => {
    return activeShiftComments[crewID];
  }
);

export const selectActiveShiftId = createSelector(selectState, state => state.activeShiftId);

export const selectSelectedShiftType = () =>
  createSelector(selectState, state => state.selectedShiftType);

export const selectWatchkeepingStatisticsData = createSelector(
  selectState,
  state => state.watchkeepingStatistics?.data
);

export const selectWatchkeepingStatisticsGraphData = createSelector(
  selectWatchkeepingStatisticsData,
  state => state?.warnings
);
