import TYPES from './types';

const INITIAL_STATE = {};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_DASHBOARD_TOTALS.SUCCESS:
      return {
        ...state,
        ...payload
      };

    case TYPES.GET_DASHBOARD_TOTAL_FINDINGS.SUCCESS:
      return {
        ...state,
        findings: payload
      };

    default:
      return state;
  }
};

export default reducer;
