import OvalButton from 'common/components/buttons/OvalButton';
import PropTypes from 'prop-types';

const LinkedTabModuleActions = ({
  showDeleteButton = false,
  showCancelButton = false,
  onCancel,
  onDelete
}) => {
  const handleDelete = async () => {
    await onDelete();
    onCancel();
  };

  return (
    <div className="d-flex">
      {showDeleteButton && (
        <OvalButton
          type="delete"
          className="ms-1"
          onClick={handleDelete}
          text="Delete Widget"
          size="sm"
        />
      )}
      {showCancelButton && (
        <OvalButton type="close" className="ms-1" onClick={onCancel} size="sm" />
      )}
    </div>
  );
};

LinkedTabModuleActions.propTypes = {
  showDeleteButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func
};

export default LinkedTabModuleActions;
