import React from 'react';

const ProfileSidebar = ({ className, sections = [] }) => {
  return (
    <div className={`page-profile--sidebar ${className || ''}`}>
      {sections.map((section, index) => (
        <div className={`page-profile--sidebar-section ${section.className || ''}`} key={index}>
          {section.component}
        </div>
      ))}
    </div>
  );
};

export default ProfileSidebar;
