import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorBoundaryFallback from '../../ErrorBoundaryFallback';

import {
  selectAccountId,
  selectAccountFullname,
  selectAccountEmail
} from 'store/account/selectors';

import { useSelector } from 'react-redux';
import { post } from 'utils/api';

const BUGSNAG_URL = '/bugsnag-report';
const NOTIFIER = {
  name: 'Bugsnag JavaScript',
  url: 'https://github.com/bugsnag/bugsnag-js',
  version: '7.10.5'
};

let reporting = false;

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  enabledReleaseStages: ['production', 'staging', 'preproduction'],
  plugins: [new BugsnagPluginReact()],
  appVersion: `${import.meta.env.VITE_APP_VERSION}-${import.meta.env.VITE_BUILD_NUMBER}`,
  releaseStage: import.meta.env.VITE_RELEASE_STAGE,
  onError: async (event, cb) => {
    cb(null, false); // prevents report from being sent

    if (!reporting) {
      reporting = true;

      try {
        const body = { events: [event], notifier: NOTIFIER };
        const res = await post(BUGSNAG_URL, body, false);
        console.log('Reported', res);

        reporting = false;
      } catch (error) {
        console.log(error);
        reporting = false;
      }
    }

    return false;
  }
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const BugsnagHOC = props => {
  const accountId = useSelector(selectAccountId);
  const accountEmail = useSelector(selectAccountEmail);
  const accountName = useSelector(selectAccountFullname);

  if (accountId) Bugsnag.setUser(accountId, accountEmail, accountName);

  const Fallback = () => <ErrorBoundaryFallback history={props.history} />;

  return <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>;
};

export default BugsnagHOC;
