import { useQuery } from '@tanstack/react-query';
import { getCaptainReportsAlarmsDue } from './api';
import { CAPTAIN_REPORT_ALARMS_DUE } from './query-keys';

export const useGetCaptainReportsAlarmsDue = (params: unknown) => {
  return useQuery({
    queryKey: [CAPTAIN_REPORT_ALARMS_DUE, params],
    queryFn: () => getCaptainReportsAlarmsDue(),
    enabled: false,
    retry: false
  });
};
