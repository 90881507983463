import { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';

const useWindowWidth = (debounce?: number): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth || 0); // Specify number type

  const resizeWindow = () => setWindowWidth(window.innerWidth);

  const resizeWindowThrottled = debounce ? _debounce(resizeWindow, debounce) : resizeWindow;

  useEffect(() => {
    resizeWindowThrottled();
    window.addEventListener('resize', resizeWindowThrottled);

    return () => window.removeEventListener('resize', resizeWindowThrottled);
  }, [resizeWindowThrottled]); // Include resizeWindowThrottled in dependency array

  return windowWidth;
};

export default useWindowWidth;
