import editIcon from 'common/assets/svg/actions/edit.svg';
import SvgRender from 'common/components/general/SvgRender';
import Drawer, { DrawerHeader } from 'common/components/drawer';
import Radio from 'common/components/form/inputs/Radio';
import Select from 'common/components/form/inputs/Select';

import { Button } from 'reactstrap';
import { FormDrawer } from 'common/components/drawer';
import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';
import { useDrawer } from 'common/components/drawer';
import { useForm, useFormState } from 'utils/hooks';
import { optionField, booleanField } from 'common/utils/form/fieldTypes';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { useSelector } from 'react-redux';
import {
  selectActiveFolderId,
  selectActiveFolderIsForAllVessels,
  selectActiveFolderVessels
} from 'common/components/filesystem/store/selectors';
import { useEffect, useState } from 'react';
import { useActions } from 'utils/hooks';

import * as filesystemActions from 'common/components/filesystem/store/actions';
import AuthCheck from '@/components/permissions/AuthCheck';
import permissions from '@/common/utils/permissions/constants';

import ResetVisibility from './reset-visibility';

const config = {
  is_for_all_vessels: booleanField(),
  vessels: optionField()
};

const FolderVisibility = () => {
  const activeFolderId = useSelector(selectActiveFolderId);
  const activeFolderIsForAllVessels = useSelector(selectActiveFolderIsForAllVessels);
  const activeFolderVessels = useSelector(selectActiveFolderVessels);
  const [editFolder] = useActions([filesystemActions.editFolder]);

  const drawer = useDrawer(false);
  const [isSaving, setIsSaving] = useState(false);
  const { formState, loadValues, collectValues, handleSubmitError } = useForm(config);
  const { fields, selectField, setFieldError } = useFormState(formState);

  useEffect(() => {
    if (drawer.isOpen) {
      loadValues({
        is_for_all_vessels: activeFolderIsForAllVessels || false,
        vessels: activeFolderVessels
      });
    }
  }, [drawer.isOpen]);

  const editFolderVisibility = async () => {
    const { vessels, is_for_all_vessels } = collectValues();

    if (!is_for_all_vessels && !vessels?.length) {
      setFieldError('vessels', 'Required');
      return;
    }

    submitFolderVisibility({
      is_for_all_vessels,
      vessel_ids: vessels.map(v => v.id),
      id: activeFolderId
    });
  };

  const resetFolderVisibility = () => {
    submitFolderVisibility({
      is_for_all_vessels: false,
      vessel_ids: [],
      id: activeFolderId
    });
  };

  const submitFolderVisibility = async params => {
    try {
      setIsSaving(true);

      await editFolder(params);

      drawer.close();
      setIsSaving(false);
    } catch (error) {
      handleSubmitError(error);
      setIsSaving(false);
    }
  };

  return (
    <div className="text-violet d-flex align-items-start mb-5">
      <span className="text-nowrap">Folder visibility: </span>
      <strong className="ps-1">
        {activeFolderIsForAllVessels ? (
          'All Vessels'
        ) : !activeFolderVessels?.length ? (
          <span className="text-red fw-medium">
            Select vessel(s) to sync this folder and its files
          </span>
        ) : (
          (activeFolderVessels || []).map(v => v.name).join(', ')
        )}
      </strong>

      <AuthCheck permissions={[permissions.office.files.edit]}>
        <Button
          type="button"
          color="outline-violet"
          className="px-1 py-0 ms-2 cmt-4 border-0 d-inline-flex align-items-center bg-white filesystem-content__edit-btn"
          onClick={drawer.open}
        >
          <SvgRender src={editIcon} style={{ width: 16, height: 16 }} />
        </Button>
      </AuthCheck>
      <Drawer {...drawer}>
        <DrawerHeader>Edit Folder Visibility</DrawerHeader>

        <FormDrawer>
          <FormBody>
            <div className="text-primary fw-bold fs-12 mb-2">FOLDER VISIBILITY</div>
            <Radio
              label={'All Vessels'}
              name="radio"
              checked={fields.is_for_all_vessels.value}
              onChange={() => {
                selectField('is_for_all_vessels')(true);
                selectField('vessels')([]);
              }}
            />

            <Radio
              label={'Specific Vessel(s)'}
              name="radio"
              checked={!fields.is_for_all_vessels.value}
              onChange={() => selectField('is_for_all_vessels')(false)}
            />

            {!fields.is_for_all_vessels.value ? (
              <Select
                isAsync
                isMulti
                placeholder="Select vessels"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                loadOptions={search => getAsyncOptions(search, 'vessels')}
                defaultOptions={() => getInitialAsyncValues('vessels')}
                onChange={selectField('vessels')}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue.length ? `Search for vessels` : `No vessels found`
                }
                {...fields.vessels}
              />
            ) : null}
          </FormBody>
          <FormFooter>
            <div className="flex-1 ps-4">
              <ResetVisibility onReset={resetFolderVisibility} />
            </div>
            <Button color="cancel" className="px-0 py-1 me-4" onClick={drawer.close}>
              CANCEL
            </Button>
            <Button
              color="primary"
              className="px-4"
              disabled={isSaving}
              onClick={editFolderVisibility}
            >
              SAVE
            </Button>
          </FormFooter>
        </FormDrawer>
      </Drawer>
    </div>
  );
};

export default FolderVisibility;
