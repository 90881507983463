import React from 'react';
import PropTypes from 'prop-types';
import ScaleRatings from 'common/components/general/ScaleRatings';

const Rating = ({ hasSubmittedEvaluation, showNumber = false }) => {
  return <ScaleRatings showNumber={showNumber} hasBullet={hasSubmittedEvaluation} />;
};

Rating.propTypes = {
  hasSubmittedEvaluation: PropTypes.bool,
  showNumber: PropTypes.bool
};

export default Rating;
