import { useEffect, useMemo } from 'react';

const usePageInfiniteScroll = (
  totalPages,
  callback,
  rerenderOn = {},
  offset = 300,
  isDisabled = false,
  target = window,
  isWindow = true
) => {
  const condition = useMemo(() => {
    return isWindow
      ? window.innerHeight + window.pageYOffset + offset >= document.body.offsetHeight
      : target?.scrollHeight - (target?.scrollTop + target?.clientHeight) <= 20;
  }, [isWindow, target, offset]);

  const loadMore = () => {
    if (condition) {
      callback();
    }
  };

  useEffect(() => {
    if (isDisabled) return;

    if (totalPages > 1) {
      target.addEventListener('scroll', loadMore, true);
    }

    return () => (totalPages > 1 ? target.removeEventListener('scroll', loadMore, true) : null);
  }, [totalPages, rerenderOn, isDisabled]);
};

export default usePageInfiniteScroll;
