import { createSelector } from 'reselect';

const selectTicketing = state => state.ticketing;
const getKey = (_, key) => key;

/* Trip Drawer */

export const selectIsTripEditting = createSelector(
  selectTicketing,
  ticketing => ticketing.tripIsEditting
);

export const isTripDrawerOpened = createSelector(
  selectTicketing,
  ticketing => ticketing.tripDrawerIsOpen
);

export const selectTripIDs = createSelector(selectTicketing, ticketing => ticketing.tripIDs);

export const selectTripCaseID = createSelector(selectTicketing, ticketing => ticketing.tripCaseID);

export const selectTripType = createSelector(selectTicketing, ticketing => ticketing.tripType);

export const selectTripDisabled = createSelector(
  selectTicketing,
  ticketing => ticketing.tripDisabled
);

export const selectTripParties = createSelector(selectTicketing, getKey, (ticketing, tripID) =>
  ticketing.tripParties.filter(p => (tripID ? p.trip_id === tripID : p))
);

export const selectTripVisibleParties = createSelector(
  selectTicketing,
  getKey,
  (ticketing, tripID) =>
    ticketing.tripVisibleParties.filter(p => (tripID ? p.trip_id === tripID : p))
);

export const selectCollpasedTrips = createSelector(
  selectTicketing,
  getKey,
  ticketing => ticketing.tripIsCollapsed
);

export const selectIsTripCollpased = createSelector(
  selectCollpasedTrips,
  getKey,
  (tripIsCollapsed, tripID) => (tripIsCollapsed[tripID] ? true : false)
);

export const selectTripOffers = createSelector(selectTicketing, getKey, (ticketing, tripID) =>
  ticketing.tripOffers.filter(p => (tripID ? p.trip_id === tripID : p))
);

export const selectTripAcceptedOffer = createSelector(selectTripOffers, tripOffers =>
  tripOffers?.find(o => o.accepted)
);

export const selectTripPreventionModal = createSelector(
  selectTicketing,
  ticketing => ticketing.preventionModal
);

export const selectTripLogDetails = createSelector(
  selectTicketing,
  ticketing => ticketing.logDetails
);
/* -- */

const selectTripData = state => selectTicketing(state)?.tripData;

export const selectTripFieldData = (state, key) => selectTripData(state)?.[key];

export const selectTripHasValidationErrors = state =>
  selectTicketing(state)?.tripHasValidationErrors;

export const selectTicketsTotals = state => selectTicketing(state)?.ticketsTotals;
