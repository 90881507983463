import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import paths from 'routing/routes/_paths';

import PlaceAndDateText from '../_components/PlaceAndDateText';
import Form from 'views/crew/contracts/form';
import PromotionLabel from 'views/crew/_components/PromotionLabel';

import { useTable } from 'common/components/table';
import ColoredTable from 'common/components/colored-table';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import useDrawer from 'common/components/drawer/useDrawer';
import CircledButton from 'common/components/buttons/CircledButton';
import DocumentTypeIndicator from 'crew/components/DocumentTypeIndicator';
import AuthCheck from 'components/permissions/AuthCheck';
import permissions from 'common/utils/permissions/constants';

const ContractsTable = () => {
  const [active, setActive] = useState(null);

  const drawer = useDrawer();

  const table = useTable({
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: true },
    label: 'crew-unsigned',
    urlRefetchLevel: 3,
    columns: [
      {
        header: 'Seamen with contracts - ready to sign on',
        key: 'full_name',
        type: 'string',
        minWidth: 300,
        maxWidth: 300,
        sort: true
      },
      {
        header: '',
        key: 'promotion_label',
        minWidth: 14,
        maxWidth: 14
      },
      {
        header: 'Rank',
        key: 'rank_id',
        type: 'collection',
        sort: true,
        width: 1
      },
      {
        header: 'Nationality',
        key: 'nationality_id',
        className: 'text-truncate',
        type: 'collection',
        sort: true,
        minWidth: 150,
        maxWidth: 150
      },
      {
        header: 'Place & Date of Birth',
        key: 'birthday',
        className: 'text-truncate',
        type: 'string',
        minWidth: 300,
        maxWidth: 300,
        sort: true,
        canFilter: false
      },
      {
        header: 'SIGN ON DATE & PORT',
        key: 'active_contract_sign_on',
        className: 'text-start',
        minWidth: 300,
        maxWidth: 300,
        sort: true,
        canFilter: false
      },
      {
        header: 'Req Sign Off Date',
        key: 'requested_sign_off_date',
        type: 'date',
        sort: true,
        canFilter: false,
        width: 1
      }
    ]
  });

  return (
    <ColoredTable
      hideTableReorder
      hideTableSearch
      hideTableFilters
      hideTopPagination
      drawer={<Form drawer={drawer} active={active} />}
      rows={{
        full_name: data => (
          <div className="d-flex align-items-center justify-content-between flex-nowrap w-100p ">
            <div className="position-absolute start-0 cms-4 h-100p cpt-4 cpb-4">
              <DocumentTypeIndicator type={'m'} className="h-100p" />
            </div>

            <div className="flex-1 max-width-calc-16 cms-2">
              <AuthCheck
                permissions={[permissions.onboard.crew.seaman_profile.personal.all_users.view]}
                unAuthorizedRender={data.full_name}
              >
                {data.type === 'crew' ? (
                  <Link to={`${paths.crew}/${data.id}`}>
                    <TextWithTooltip>{data.full_name || '-'}</TextWithTooltip>
                  </Link>
                ) : (
                  <Link to={`${paths.persons}/${data.person_id}`}>
                    <TextWithTooltip>{data.full_name || '-'}</TextWithTooltip>
                  </Link>
                )}
              </AuthCheck>
            </div>
          </div>
        ),
        promotion_label: data => {
          const initialRank = _get(data, 'contract_rank.name');
          const promotionRank = _get(data, 'active_promotion_plan.promotion_rank.name');

          return initialRank && promotionRank ? (
            <PromotionLabel initialRank={initialRank} promotionRank={promotionRank} />
          ) : null;
        },
        rank_id: data => (
          <TextWithTooltip className="d-flex align-items-center">
            {data.contract_rank ? data.contract_rank.name : '-'}
          </TextWithTooltip>
        ),
        nationality_id: data => _get(data, 'nationality.name', '-'),
        birthday: data => <PlaceAndDateText place={data?.place} date={data?.birthday} />,
        active_contract_sign_on: data => (
          <CircledButton
            label={'Add'}
            type={'add'}
            onClick={() => {
              setActive(data);
              drawer.open();
            }}
            style={{ width: 18, height: 18 }}
            svgStyle={{ width: 9, height: 9 }}
          />
        ),
        requested_sign_off_date: data => {
          const signOffDate = _get(data, 'active_contract.requested_sign_off_date');

          return signOffDate ? moment(signOffDate).format('DD/MM/YYYY hh:mm') : '-';
        }
      }}
      {...table}
      className="mb-3"
    />
  );
};

export default ContractsTable;
