import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Select from 'common/components/form/inputs/Select';

import { fetchListOptions } from 'store/lists/actions';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { selectListOptionsFromStore } from 'store/lists/selectors';

const PartyCompanySpecificTypeSelector = ({ filter, onChange, type, isMulti, ...rest }) => {
  const dispatch = useDispatch();

  const companyTypes = useSelector(state =>
    selectListOptionsFromStore(state, 'party-company-types')
  );
  const types = useSelector(state => selectListOptionsFromStore(state, 'parties'));

  useEffect(() => {
    const selected = companyTypes?.find(s => s?.label === type);
    if (selected) {
      dispatch(fetchListOptions('parties', '', { company_type_ids: [selected?.id] }));
    }
  }, [companyTypes, companyTypes.length, dispatch, type]);

  useEffect(() => {
    dispatch(fetchListOptions('party-company-types', type));
  }, [dispatch, type]);

  return (
    <Select
      placeholder="Select value"
      dataCy="select-company"
      autoFocus
      getOptionValue={option => option.id}
      getOptionLabel={option => option.full_name}
      value={filter.value?.id || filter.value}
      options={types}
      isMulti={isMulti}
      isClearable
      onChange={selected => onChange({ value: selected ? (isMulti ? [selected] : selected) : '' })}
      {...rest}
    />
  );
};

PartyCompanySpecificTypeSelector.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  type: PropTypes.string
};

export default PartyCompanySpecificTypeSelector;
