import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import _get from 'lodash/get';
import { numberToStr } from 'common/utils/numbers';
import reminderPr from 'common/assets/svg/common/reminder.svg';

const Reminder = props => {
  const { properties } = props;

  let reminder;

  if (props.description === 'reminder.added') {
    reminder = _get(properties, 'attributes', {});
  } else {
    reminder = _get(properties, 'old', {});
  }

  return (
    <div className="d-flex align-items-center">
      <SvgRender
        src={reminderPr}
        style={{ width: 14, height: 14 }}
        className={`me-2 ${reminder.priority === 'low' ? 'text-turquoise' : 'text-red'}`}
      />
      <div className="d-flex align-items-center fs-12 text-primary fw-bold ">
        {numberToStr(reminder.days_before > 0 ? reminder.days_before : reminder.months_before)}
        <span className="text-capitalize ps-1">{reminder.days_before > 0 ? 'days' : 'months'}</span>
        <span className="fw-light ps-1">before</span>
      </div>
    </div>
  );
};

export default Reminder;
