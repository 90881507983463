import { Files, Size } from './_types';
import { useSelector } from 'react-redux';
import { getApiBaseUrl } from 'utils/api';
import useGallery from 'common/components/gallery/useGallery';

import FilePreview from './FilePreview';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const UploadedFiles = ({
  files,
  size,
  viewOnly,
  canRemove,
  onRemoveFile,
  imageSizeLabel,
  singleUpload,
  initGalleryFiles
}) => {
  const { setFiles, setIsOpen, setCurrentFile } = useGallery();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const previewImageBaseUrl = isOnBoard ? getApiBaseUrl() : '';

  const onFileClick = useCallback(
    file => {
      if (initGalleryFiles) setFiles(files);

      setCurrentFile(file);
      setIsOpen(true);
    },
    [files, initGalleryFiles]
  );

  return files?.map((file, index) => (
    <FilePreview
      key={file.id}
      file={file}
      size={size}
      previewImageBaseUrl={previewImageBaseUrl}
      viewOnly={viewOnly}
      canRemove={canRemove}
      onFileClick={onFileClick}
      onFileRemove={onRemoveFile}
      imageSizeLabel={imageSizeLabel}
      singleUpload={singleUpload}
      index={index}
    />
  ));
};

UploadedFiles.propTypes = {
  files: Files.isRequired,
  size: Size.isRequired,
  viewOnly: PropTypes.bool,
  canRemove: PropTypes.bool,
  singleUpload: PropTypes.bool,
  imageSizeLabel: PropTypes.string,
  initGalleryFiles: PropTypes.bool
};

export default UploadedFiles;
