import TYPES from './types';
import { get, put, post, deleteRequest } from 'utils/api';
import { successHandler } from 'common/utils/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getEntityReviewProcess = params => dispatch => {
  dispatch({ type: TYPES.GET_ENTITY_REVIEW_PROCESS.START, payload: { params } });

  return get(`/review-processes/entity`, params)
    .then(response => {
      dispatch({ type: TYPES.GET_ENTITY_REVIEW_PROCESS.SUCCESS, payload: response.data });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.GET_ENTITY_REVIEW_PROCESS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const checkIfReviewProcessIsEnabled = params => dispatch => {
  dispatch({ type: TYPES.CHECK_IF_REVIEW_PROCESS_IS_ENABLED.START, payload: { params } });

  return get(`/review-processes/can-create`, params)
    .then(response => {
      dispatch({
        type: TYPES.CHECK_IF_REVIEW_PROCESS_IS_ENABLED.SUCCESS,
        payload: { ...params, ...response.data }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CHECK_IF_REVIEW_PROCESS_IS_ENABLED.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const createEntityReviewProcess = params => dispatch => {
  dispatch({ type: TYPES.CREATE_ENTITY_REVIEW_PROCESS.START, payload: { params } });

  return post(`/review-processes`, params)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_ENTITY_REVIEW_PROCESS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_ENTITY_REVIEW_PROCESS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const updateReviewProcessSubstepRemarks = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_REMARKS.START, payload: { params } });
  const { substep_id, entityId, ...rest } = params;

  return put(`/review-process-substep/${substep_id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_REMARKS.SUCCESS,
        payload: { data: response.data, entityId }
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_REMARKS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const updateReviewProcessSubstepActions = params => dispatch => {
  dispatch({ type: TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_ACTIONS.START, payload: { params } });
  const { step_id, substep_id, ...rest } = params;

  return put(`/review-process-steps/${step_id}/review-process-substeps/${substep_id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_ACTIONS.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_ACTIONS.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const approveReviewProcessSubstep = params => dispatch => {
  dispatch({ type: TYPES.APPROVE_REVIEW_PROCESS_SUBSTEP.START, payload: { params } });

  const { substep_id, ...rest } = params;

  return put(`/review-process-substep/${substep_id}/mark-as-completed`, rest)
    .then(response => {
      dispatch({ type: TYPES.APPROVE_REVIEW_PROCESS_SUBSTEP.SUCCESS, payload: response.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Step approved'
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.APPROVE_REVIEW_PROCESS_SUBSTEP.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const revokeReviewProcessSubstep = params => dispatch => {
  dispatch({ type: TYPES.REVOKE_REVIEW_PROCESS_SUBSTEP.START, payload: { params } });

  const { substep_id, ...rest } = params;

  return put(`review-process-substep/${substep_id}/revoke-approval`, rest)
    .then(response => {
      dispatch({ type: TYPES.REVOKE_REVIEW_PROCESS_SUBSTEP.SUCCESS, payload: response.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Step revoked'
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.REVOKE_REVIEW_PROCESS_SUBSTEP.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const rejectReviewProcessStep = params => dispatch => {
  dispatch({ type: TYPES.REJECT_REVIEW_PROCESS_STEP.START, payload: { params } });

  const { step_id, review_process_id, ...rest } = params;

  return put(`review-processes/${review_process_id}/review-process-steps/${step_id}/reject`, rest)
    .then(response => {
      dispatch({ type: TYPES.REJECT_REVIEW_PROCESS_STEP.SUCCESS, payload: response.data });
      dispatch(
        successHandler({
          title: 'Success!',
          message: 'Step rejected'
        })
      );

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.REJECT_REVIEW_PROCESS_STEP.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const createReviewProcessSubstepFormSubmission = params => dispatch => {
  dispatch({
    type: TYPES.CREATE_REVIEW_PROCESS_FORM_SUBMISSION.START,
    payload: { params }
  });
  const { substep_id, substep_form_id, ...rest } = params;

  return put(`/review-process-substep/${substep_id}/forms/${substep_form_id}`, rest)
    .then(response => {
      dispatch({
        type: TYPES.CREATE_REVIEW_PROCESS_FORM_SUBMISSION.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.CREATE_REVIEW_PROCESS_FORM_SUBMISSION.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};
export const addReviewProcessSubstep = params => dispatch => {
  dispatch({
    type: TYPES.ADD_REVIEW_PROCESS_SUBSTEP.START,
    payload: { params }
  });
  const { step_id, ...rest } = params;

  return post(`/review-process-steps/${step_id}/review-process-substeps`, rest)
    .then(response => {
      dispatch({
        type: TYPES.ADD_REVIEW_PROCESS_SUBSTEP.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.ADD_REVIEW_PROCESS_SUBSTEP.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const removeReviewProcessSubstep = params => dispatch => {
  dispatch({
    type: TYPES.REMOVE_REVIEW_PROCESS_SUBSTEP.START,
    payload: { params }
  });
  const { step_id, substep_id, ...rest } = params;

  return deleteRequest(
    `/review-process-steps/${step_id}/review-process-substeps/${substep_id}`,
    rest
  )
    .then(response => {
      dispatch({
        type: TYPES.REMOVE_REVIEW_PROCESS_SUBSTEP.SUCCESS,
        payload: response.data
      });

      return response.data;
    })
    .catch(error => {
      dispatch({
        type: TYPES.REMOVE_REVIEW_PROCESS_SUBSTEP.ERROR,
        payload: { response: error, params }
      });

      throw error;
    });
};

export const setReviewProcessDrawerIsOpen = (data, entityId) => dispatch =>
  dispatch({ type: TYPES.SET_REVIEW_PROCESS_DRAWER_IS_OPEN, payload: { data, entityId } });

export const setReviewProcessFormDrawerIsOpen = data => dispatch =>
  dispatch({ type: TYPES.SET_REVIEW_PROCESS_FORM_DRAWER_IS_OPEN, payload: { data } });

export const setActiveReviewProcessEntityId = data => dispatch =>
  dispatch({ type: TYPES.SET_ACTIVE_REVIEW_PROCESS_ENTITY_ID, payload: data });

//notes

export const getReviewProcessNotes = createAsyncThunk(
  'GET_REVIEW_PROCESS_NOTES',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`/review-processes/${id}/notes`);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createReviewProcessNotes = createAsyncThunk(
  'CREATE_REVIEW_PROCESS_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, ...rest } = params;
    try {
      const response = await post(`/review-processes/${id}/notes`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateReviewProcessNote = createAsyncThunk(
  'UPDATE_REVIEW_PROCESS_NOTE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, note_id, ...rest } = params;
    try {
      const response = await put(`/review-processes/${id}/notes/${note_id}`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteReviewProcessNote = createAsyncThunk(
  'DELETE_REVIEW_PROCESS_NOTE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { type, id, note_id, ...rest } = params;
    try {
      const response = await deleteRequest(`/review-processes/${id}/notes/${note_id}`, { ...rest });

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
