import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CHECKLIST_OPTIONS'),
  ...asyncTypes('UPDATE_CHECKLIST_OPTIONS'),
  ...asyncTypes('TOGGLE_CHECKLIST_OPTION'),
  ...asyncTypes('CREATE_COMMENT'),
  ...asyncTypes('UPDATE_COMMENT'),
  ...asyncTypes('DELETE_COMMENT'),
  ...asyncTypes('GET_COMMENTS'),
  SHOW_LESS_COMMENTS: 'SHOW_LESS_COMMENTS',
  SET_CHECKLIST_OPTIONS: 'SET_CHECKLIST_OPTIONS',
  CLEAR_CHECKLIST: 'CLEAR_CHECKLIST',
  TOGGLE_COLLAPSE: 'TOGGLE_COLLAPSE'
};

export default TYPES;
