import { useSelector } from 'react-redux';
import {
  selectFieldValue,
  selectPreviousCaptainReportTimestamp,
  selectCaptainReportTemplateType
} from 'captain-reports/store/selectors';

import useValue from 'captain-reports/utils/useValue';

import moment from 'moment';

const useReportTimeDiff = () => {
  const templateType = useSelector(selectCaptainReportTemplateType);

  const timestamp = useSelector(state => selectFieldValue(state, 'timestamp'));
  const lastReportTimestamp = useSelector(selectPreviousCaptainReportTimestamp);

  const minutesSinceLastReport =
    timestamp && lastReportTimestamp
      ? moment(timestamp).diff(moment(lastReportTimestamp), 'minutes')
      : null;

  const steamingTime = useValue({
    key: 'steaming_time'
  });

  if (templateType === 'noon' || templateType === 'arrival') return steamingTime;

  return minutesSinceLastReport;
};

export default useReportTimeDiff;
