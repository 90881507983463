import React, { useMemo } from 'react';
import Tooltip from 'common/components/general/Tooltip';
import { getFieldID, getFieldTypeLabel } from '../../state-utils';
import history from 'common/assets/svg/common/history.svg';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/jobs/priority_arrow.svg';
import moment from 'moment';
import { minutesToFormat } from 'common/utils/dates';
import { FORM_FIELD_TYPES } from 'common/components/forms/digital/constants';
import { strToNumber } from 'common/utils/numbers';
import D3SimpleLineChart from 'common/components/graph/D3SimpleLineChart';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import _isObject from 'lodash/isObject';

const prohibitedTypes = [
  FORM_FIELD_TYPES.table_with_fixed_rows.label,
  FORM_FIELD_TYPES.risk_assessment.label,
  FORM_FIELD_TYPES.crew_participants.label,
  FORM_FIELD_TYPES.table_with_dynamic_rows.label,
  FORM_FIELD_TYPES.manual_chapter.label,
  FORM_FIELD_TYPES.text_with_editor.label,
  FORM_FIELD_TYPES.root_cause_analysis.label,
  FORM_FIELD_TYPES.attachments.label,
  FORM_FIELD_TYPES.dates_multiple.label,
  FORM_FIELD_TYPES.departments_dropdown.label,
  FORM_FIELD_TYPES.vessels_dropdown.label,
  FORM_FIELD_TYPES.instructions.label,
  FORM_FIELD_TYPES.form_instructions_image.label,
  FORM_FIELD_TYPES.findings.label,
  FORM_FIELD_TYPES.crew_with_rank.label,
  FORM_FIELD_TYPES.vessel_system_attribute.label
];

const parseValueAsString = (label, value) => {
  switch (label) {
    case FORM_FIELD_TYPES.office_user.label:
      return value?.full_name || '-';

    case FORM_FIELD_TYPES.place.label:
      return value?.port_id ? `${value.port.code} - ${value.port.name}` : '-';

    case FORM_FIELD_TYPES.radio_with_details.label:
      return value?.option || '-';

    case FORM_FIELD_TYPES.inventory_survey_rob.label:
      return value?.value || '-';

    case FORM_FIELD_TYPES.boolean.label:
      return value ? 'Checked' : 'Unchecked';

    case FORM_FIELD_TYPES.date.label:
      return value ? moment(value).format('DD/MM/YYYY') : '-';

    case FORM_FIELD_TYPES.time.label:
      return value ? minutesToFormat(value) : '-';

    case FORM_FIELD_TYPES.datetime.label:
      return value ? moment(value).format('DD/MM/YYYY HH:mm') : '-';

    case FORM_FIELD_TYPES.datetime_range.label:
      const from = value?.from ? moment(value.from).format('DD/MM/YYYY HH:mm') : '';
      const to = value?.to ? moment(value.to).format('DD/MM/YYYY HH:mm') : '';
      return `${from} - ${to}`;

    case FORM_FIELD_TYPES.crew_with_rank:
      return value?.crew_member_id
        ? `${value?.crew_member?.full_name} - ${value?.crew_rank?.name}`
        : '-';

    default:
      return value && !_isObject(value) ? value : '-';
  }
};

const PreviousValuesTooltip = ({ tooltipID, previousValues, formField }) => {
  const label = getFieldTypeLabel(formField);
  const formFieldId = getFieldID(formField);

  const values = useMemo(
    () => (previousValues?.values?.length ? previousValues.values.filter(v => v.value) : []),
    [previousValues]
  );
  const shouldRender = useMemo(() => !prohibitedTypes.includes(label), [label]);

  if (!shouldRender || !values?.length) return null;

  return (
    <Tooltip placement="auto-start" hideArrow innerClassName="min-width-fit" target={tooltipID}>
      <div className="d-flex align-items-center border-bottom border-secondary cpb-4 mb-1">
        <SvgRender src={history} style={{ width: 14, height: 14 }} />
        <span className="fs-12 fw-medium cms-6">Past Values</span>
      </div>

      {label === FORM_FIELD_TYPES.number.label ? (
        <D3SimpleLineChart
          values={values.map(v => strToNumber(v.value))}
          id={`tooltip-graph-${formFieldId}`}
          config={{
            chartWidth: 130,
            chartHeight: 47,
            chartPaddingY: 10,
            chartPaddingX: 0,
            color: variables.red,
            lineSize: 1.5,
            gradientOpacity: [0.3, 0]
          }}
        />
      ) : null}

      <div>
        {values.map(v => (
          <div className="d-flex align-items-center">
            <span className="fs-12 fw-medium">
              {moment(v.submission_date).format('DD/MM/YYYY')}
            </span>
            <SvgRender
              className="cms-12 cme-12"
              src={arrow}
              style={{ width: 8, height: 19, transform: 'rotate(90deg)' }}
            />
            <span className="fs-12 fw-300 text-nowrap">{parseValueAsString(label, v?.value)}</span>
          </div>
        ))}
      </div>
    </Tooltip>
  );
};

export default PreviousValuesTooltip;
