import { useState } from 'react';
import PropTypes from 'prop-types';

import CircledButton from 'common/components/buttons/CircledButton';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import Preview from './Preview';
import Form from './Form';

const CreatableTable = ({
  color = 'white',
  className = '',
  rows = [],
  topComponent,
  formComponent,
  formOnCancel,
  formOnSave,
  formOnSaveCallback,
  hideActions = false,
  hideIndex = false,
  formConfig,
  dangerousModalProps = {},
  rowOnDelete,
  rowOnDeleteCallback,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState({});
  const [isAdding, setIsAdding] = useState(!hideActions && !rows.length ? true : false);
  const [dangerousModal, setDangerousModal] = useState({
    isOpen: false,
    active: null,
    index: null
  });

  const setRowEditing = (index, edit) => {
    setIsEditing({ ...isEditing, [index]: edit });
  };

  return (
    <div
      className={`creatable-table ${color !== 'white' ? `${color}-colored` : ''} ${
        hideIndex ? 'hidden-indexes' : ''
      } ${className}`}
    >
      {topComponent ? <div className="creatable-table__top">{topComponent}</div> : null}
      <div className="creatable-table__main">
        {rest.headerComponent ? (
          <div className="creatable-table__main-header">
            <rest.headerComponent />
          </div>
        ) : null}
        <div className="creatable-table__main-rows">
          {rows.map((row, index) =>
            isEditing[index] ? (
              <Form
                key={index}
                row={row}
                index={index}
                setRowEditing={() => setRowEditing(index, false)}
                setRowDeleting={
                  rowOnDelete ? () => setDangerousModal({ isOpen: true, active: row, index }) : null
                }
                formConfig={formConfig}
                formComponent={formComponent}
                formOnCancel={formOnCancel}
                formOnSave={formOnSave}
                formOnSaveCallback={formOnSaveCallback}
              ></Form>
            ) : (
              <Preview
                key={index}
                index={index}
                setRowEditing={() => setRowEditing(index, true)}
                setRowDeleting={
                  rowOnDelete ? () => setDangerousModal({ isOpen: true, active: row, index }) : null
                }
                row={row}
                setDangerousModal={setDangerousModal}
                hideActions={hideActions}
              >
                <rest.previewComponent row={row} />
              </Preview>
            )
          )}

          {isAdding ? (
            <Form
              index={rows.length}
              setRowEditing={() => setIsAdding(false)}
              formConfig={formConfig}
              formComponent={formComponent}
              formOnCancel={formOnCancel}
              formOnSave={formOnSave}
              formOnSaveCallback={formOnSaveCallback}
            ></Form>
          ) : null}
        </div>
      </div>

      {!hideActions ? (
        <CircledButton
          type="add"
          label="Add more"
          className="text-primary fw-bold fs-12 mt-1"
          svgStyle={{ width: 8, height: 8 }}
          style={{ width: 16, height: 16 }}
          onClick={() => setIsAdding(true)}
          disabled={isAdding}
          data-cy="add-more"
        />
      ) : null}

      <DangerousActionModal
        transparent
        onAccept={async () => {
          try {
            await rowOnDelete(dangerousModal?.active, dangerousModal?.index);
            setRowEditing(dangerousModal?.index, false);

            if (rowOnDeleteCallback) rowOnDeleteCallback();
            setDangerousModal({ isOpen: false, active: null, index: null });
          } catch (error) {}
        }}
        closeModal={() => setDangerousModal({ isOpen: false, active: null, index: null })}
        isOpen={dangerousModal.isOpen}
        actionText={'DELETE'}
        header={'Delete'}
        body={<div className="text-center">Are you sure you want to delete this?</div>}
        {...dangerousModalProps}
      />
    </div>
  );
};

CreatableTable.propTypes = {
  color: PropTypes.oneOf(['white', 'gray']),
  className: PropTypes.string,
  rows: PropTypes.array.isRequired, // data
  hideActions: PropTypes.bool, // Set as true to hide actions
  hideIndex: PropTypes.bool, // Set as true to hide table indexes
  topComponent: PropTypes.node,
  headerComponent: PropTypes.func,
  previewComponent: PropTypes.func.isRequired,
  formComponent: PropTypes.func,
  formConfig: PropTypes.object, // useForm config
  formOnCancel: PropTypes.func,
  formOnSave: PropTypes.func,
  formOnSaveCallback: PropTypes.func, // will get called after submitting succefully
  dangerousModalProps: PropTypes.object, // props to append to <DangerousActionModal />
  rowOnDelete: PropTypes.func,
  rowOnDeleteCallback: PropTypes.func // will get called after deleting succefully
};

export default CreatableTable;
