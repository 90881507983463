import { FuelGrade } from '@/common/types/enums';
import { OffHireReason, OffHireReasonAssociatedElement } from '@/common/types/events';
import { hiddenField, numberField, optionField } from '@/common/utils/form/fieldTypes';

export type OffHireRobsFormStateType = {
  id: number | undefined;
  fuel_grade: FuelGrade | null;
  value: number | string | null;
};

export type EventOffHireFormStateType = {
  off_hire_reason: OffHireReason | null;
  associated_element: OffHireReasonAssociatedElement | null;
  start_robs: OffHireRobsFormStateType[];
  end_robs: OffHireRobsFormStateType[];
};

const config = {
  off_hire_reason: optionField(),
  associated_element: optionField(),
  start_robs: {
    fuel_grade: hiddenField(),
    value: numberField()
  },
  end_robs: {
    id: hiddenField(),
    fuel_grade: hiddenField(),
    value: numberField()
  }
};

export default config;
