import { useEffect, useState, useMemo } from 'react';

import { useFormState } from 'utils/hooks';
import { Row, Col, Button } from 'reactstrap';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { formatDate } from 'common/utils/dates';
import { useSelector } from 'react-redux';
import { exludedDocumentCategories } from 'store/vessels/selectors';

import { FormBody } from 'common/components/drawer';
import { FormFooter } from 'common/components/drawer';

import Input from 'common/components/form/inputs/Input';
import Select from 'common/components/form/inputs/Select';
import UploadFiles from 'common/components/form/inputs/upload-files';
import DateInput from 'common/components/form/inputs/date';
import Textarea from 'common/components/form/inputs/Textarea';
import Departments from 'common/components/general/Departments';
import TemplateFields from 'common/components/vessels/profile/tabs/documents/components/TemplateFields';
import moment from 'moment';
import { selectListOptionsFromStore, selectListDefaultOptions } from 'store/lists/selectors';
import { DOCUMENT_TEMPLATES } from 'common/components/vessels/profile/tabs/documents/helpers';

import GroupedOptionsSelect from 'common/components/form/styled-inputs/GroupedOptionsSelect';

import _get from 'lodash/get';

const DocumentsFormBody = ({
  formState,
  collectValues,
  close,
  isDirty,
  hasErrors,
  submitForm,
  isSubmitting,
  drawerType,
  updated,
  selectedActiveCategoryId,
  vesselId,
  department,
  template
}) => {
  const exludedDocumentCategoryIds = useSelector(exludedDocumentCategories);
  const documentTypes = useSelector(state =>
    selectListOptionsFromStore(state, 'vessel-document-types')
  );

  const documentCategories = useSelector(state =>
    selectListDefaultOptions(state, 'vessel-document-categories')
  );

  const { fields, changeField, selectField, setFieldError, setFieldValue } =
    useFormState(formState);

  const [error, setError] = useState(null);
  const selectedDocumentType = useMemo(() => {
    const value = fields?.category?.value?.id || fields?.category?.value;

    return documentTypes?.find(e => e?.id === value);
  }, [fields?.category?.value]);

  const selectedTemplate = useMemo(() => {
    return (
      documentCategories?.find(cat => cat.id === selectedDocumentType?.vessel_document_category_id)
        ?.template || template
    );
  }, [selectedDocumentType, documentCategories, template]);

  const isFlagDispensationTemplate = selectedTemplate === DOCUMENT_TEMPLATES.flag_dispensations;
  const isSurveyTemplate = selectedTemplate === DOCUMENT_TEMPLATES.surveys;
  const isItemCertificateTemplate = selectedTemplate === DOCUMENT_TEMPLATES.item_certificate;

  const handleSubmitForm = async () => {
    const values = collectValues();
    if (!values) return;

    const {
      category,
      port,
      attachments,
      expiration_date,
      extension_requested,
      completed_on,
      flag_informed_on,
      range_from,
      range_to,
      postponed,
      serial_number,
      ...rest
    } = values;

    const params = {
      ...rest,
      vessel_id: vesselId,
      extension_dispensation_requested_for: isFlagDispensationTemplate
        ? extension_requested || null
        : null,
      completed_on: isFlagDispensationTemplate ? completed_on || null : null,
      flag_informed_on: isFlagDispensationTemplate ? flag_informed_on || null : null,
      range_from: isSurveyTemplate ? range_from || null : null,
      range_to: isSurveyTemplate ? range_to || null : null,
      postponed: isSurveyTemplate ? postponed || null : null,
      serial_number: isItemCertificateTemplate ? serial_number || null : null,
      expiration_date: expiration_date,
      vessel_document_type_id: category?.id || category || null,
      port_id: port && port.id ? port.id : null,
      attachments: attachments.map(a => a.id)
    };

    submitForm(params);
  };

  const excludeIds = exludedDocumentCategoryIds.filter(e => e !== selectedActiveCategoryId);

  useEffect(() => {
    if (fields.expiration_date.value) setError(null);
  }, [fields.expiration_date.value]);

  return (
    <>
      <FormBody isDirty={isDirty} className="vessel-document-form">
        {drawerType !== 'renew' ? (
          <GroupedOptionsSelect
            onChange={sel => selectField('category')(sel)}
            onGroupSelect={group =>
              selectField('category')([...fields.category.value, ...group.options])
            }
            groupBy="category"
            list="vessel-document-types"
            placeholder=""
            excludeIds={excludeIds}
            listParams={{ vessel_id: vesselId }}
            closeMenuOnSelect={false}
            isMulti={false}
            isClearable={false}
            label="NAME"
            className={drawerType === 'renew' ? 'update-certificate' : ''}
            {...fields.category}
            value={fields.category?.value?.id || fields.category?.value}
          />
        ) : (
          <div className="mb-2">
            <div className="form-label">Name</div>
            <div className="fs-12 text-primary fw-medium">{fields?.category?.value?.name}</div>
          </div>
        )}

        {selectedDocumentType ? (
          <>
            <div className="form-label">CATEGORY</div>
            <span className="mb-2 fs-12 text-primary fw-medium">
              {selectedDocumentType?.category?.name}
            </span>
          </>
        ) : null}

        {department ? (
          <div className="border-bottom w-100p pb-3 mb-3">
            <div className="form-label">RESPONSIBLE DEPARTMENT</div>
            <Departments values={[department]} />
          </div>
        ) : null}

        <Row className="position-relative">
          <Col xs="auto">
            <DateInput
              label={'ISSUE DATE'}
              onChange={date => {
                setFieldValue('issue_date', date);
                if (moment(date).isAfter(fields.expiration_date.value)) {
                  setFieldError('issue_date', 'Issue date should be before expiration date');
                } else {
                  setFieldError('issue_date', null);
                  setFieldError('expiration_date', null);
                }
              }}
              {...fields.issue_date}
            />
          </Col>
          <Col>
            <DateInput
              label={'EXPIRATION DATE'}
              onChange={date => {
                if (moment(date).isBefore(fields.issue_date.value)) {
                  setFieldError('expiration_date', 'Expiration date should be after issue date');
                } else {
                  setFieldError('issue_date', null);
                  setFieldError('expiration_date', null);
                }
                setFieldValue('expiration_date', date);
              }}
              className={`expire-doc-date-input ${
                moment(fields.expiration_date.value).isBefore(moment().format('YYYY-MM-DD'))
                  ? 'text-red'
                  : ''
              }`}
              yearEnd={moment().add(18, 'year')}
              yearStart={moment().subtract(2, 'year')}
              value={fields.expiration_date.value}
              error={error}
            />
          </Col>
        </Row>

        <TemplateFields
          template={selectedTemplate}
          fields={fields}
          selectField={selectField}
          changeField={changeField}
        />

        <Row>
          <Col>
            <Input
              label="ISSUED / CERTIFIED BY"
              onChange={changeField('issued_by')}
              {...fields.issued_by}
            />
          </Col>
          <Col>
            <Select
              isAsync
              label="PORT NAME"
              placeholder=""
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              loadOptions={search => getAsyncOptions(search, 'ports')}
              defaultOptions={() => getInitialAsyncValues('ports')}
              onChange={selectField('port')}
              isClearable
              {...fields.port}
            />
          </Col>
        </Row>

        <Textarea
          className="mb-0"
          label="COMMENTS"
          onChange={changeField('comments')}
          {...fields.comments}
        />

        <div className="border-bottom mt-4 mb-3"></div>

        <UploadFiles
          group="vessels.documents"
          files={fields.attachments.value}
          onChange={files => selectField('attachments')(files)}
          uploadText="Upload files"
          size="lg"
        />

        {updated ? (
          <div className="mt-auto mb-1 text-violet">
            Last modified by
            <strong> {_get(updated, 'by.full_name', '-')}</strong>, at
            <strong> {formatDate(updated.at, { time: true })}</strong>
          </div>
        ) : null}
      </FormBody>

      <FormFooter>
        <Button color="cancel" className="px-0 py-1 me-4" onClick={close}>
          CANCEL
        </Button>
        <Button
          onClick={handleSubmitForm}
          disabled={isSubmitting || hasErrors}
          color="primary"
          className="px-4"
        >
          SAVE
        </Button>
      </FormFooter>
    </>
  );
};

export default DocumentsFormBody;
