import { useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';
// import { selectDashboardTableData } from 'store/dashboard/tables/selectors';

import CrewRotationType from 'common/components/crew/CrewRotationType';

const ContentCrewChange = () => {
  const totals = useSelector(state => selectDashboardTotals(state, 'crew_rotations'));
  // const data = useSelector(state => selectDashboardTableData(state, 'crew_rotations'));
  // const totals = data?.length
  //   ? {
  //       off_signers: data.filter(d => d.off_signer)?.length,
  //       on_signers: data.filter(d => d.on_signer)?.length
  //     }
  //   : null;

  return totals ? (
    <div className="dashboard__totals-crew-switches d-flex">
      <div className="d-flex align-items-center flex-1 text-violet">
        <strong className="fs-20 line-height-1">{totals?.off_signers || 0}</strong>
        <span className="fs-12 line-height-1">&nbsp;Off&nbsp;</span>
        <CrewRotationType
          type="off_signer"
          textClassName="d-none"
          svgClassName="text-violet"
          svgStyle={{ width: 6, height: 10 }}
        />
      </div>
      <div className="d-flex align-items-center flex-1 text-green">
        <strong className="fs-20 line-height-1">{totals?.on_signers || 0}</strong>
        <span className="fs-12 line-height-1">&nbsp;On&nbsp;</span>
        <CrewRotationType
          type="on_signer"
          textClassName="d-none"
          svgStyle={{ width: 6, height: 10 }}
        />
      </div>
    </div>
  ) : null;
};

export default ContentCrewChange;
