import { strToNumber } from 'common/utils/numbers';

export const calculateWarning = (rule, values, value) => {
  if (!values) return null;

  const firstValue = strToNumber(values[0]);
  const secondValue = values[1] ? strToNumber(values[1]) : null;
  const val = strToNumber(value);

  if (!val) return null;

  switch (rule) {
    case 'numeric_between':
      if (val > secondValue || val < firstValue) {
        return (
          <strong>
            Between {firstValue} - {values[1]}
          </strong>
        );
      } else {
        return null;
      }

    case 'numeric_equal':
      if (val !== firstValue) {
        return <strong>Equal to {firstValue}</strong>;
      } else {
        return null;
      }

    case 'numeric_greater':
      if (val <= firstValue) {
        return <strong>Greater than {firstValue}</strong>;
      } else {
        return null;
      }

    case 'numeric_greater_or_equal':
      if (val < firstValue) {
        return <strong>Equal or Greater than {firstValue}</strong>;
      } else {
        return null;
      }

    case 'numeric_less':
      if (val >= firstValue) {
        return <strong>Less than {firstValue}</strong>;
      } else {
        return null;
      }

    case 'numeric_less_or_equal':
      if (val > firstValue) {
        return <strong>Equal or Less than {firstValue}</strong>;
      } else {
        return null;
      }

    default:
      return null;
  }
};
