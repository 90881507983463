import DraftAndDwt from 'common/components/vessels/profile/tabs/info/components/dimensions-loadlines/DraftAndDwt';

const dimensionsAndLoadlines = [
  {
    fields: [
      {
        label: 'L.O.A. (m)',
        key: 'loa',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'L.B.P. (m)',
        key: 'lbp',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'DEPTH MOULDED (m)',
        key: 'depth_moulded',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'BREADTH MOULDED (m)',
        key: 'breadth_moulded',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'BREADTH EXTREME (m)',
        key: 'breadth_extreme',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'T.P.C ON SSW (mt)',
        key: 'ssw_tpc',
        type: 'number',
        inputProps: {
          decimalScale: 3
        }
      },
      {
        label: 'KTM (m)',
        key: 'ktm',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'KTM IN COLLAPSED CONDITION (m)',
        key: 'ktm_in_collapsed_condition',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'FRESH WATER ALLOWANCE (mm)',
        key: 'fresh_water_allowance',
        type: 'number'
      },
      { label: 'LIGHT SHIP (mt)', key: 'light_ship', type: 'number' },
      {
        label: 'LIGHTSHIP DRAFT (m)',
        key: 'lightship_draft',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'DESIGN DRAFT (m)',
        key: 'design_draft',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'SCANTLING DRAFT (m)',
        key: 'scantling_draft',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },

      {
        label: 'NORMAL BALLAST DRAFT (m)',
        key: 'ballast_normal_draft',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'HEAVY BALLAST DRAFT (m)',
        key: 'ballast_heavy_draft',
        type: 'number',
        inputProps: {
          decimalScale: 4
        }
      },
      {
        label: 'CONSTANT WEIGHT (mt)',
        key: 'constant_weight',
        type: 'number',
        inputProps: {
          decimalScale: 3
        }
      }
    ]
  },
  {
    customComponent: DraftAndDwt,
    fields: [
      {
        label: 'WINTER NORTH ATLANTIC',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_winter_north_atlantic'
          },
          {
            label: 'DRAFT',
            type: 'number',
            key: 'draft_winter_north_atlantic',
            inputProps: {
              decimalScale: 4
            }
          }
        ]
      },
      {
        label: 'WINTER',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_winter'
          },
          {
            label: 'DRAFT',
            type: 'number',
            key: 'draft_winter',
            inputProps: {
              decimalScale: 4
            }
          }
        ]
      },
      {
        label: 'SUMMER',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_summer'
          },
          {
            label: 'DRAFT',
            type: 'number',
            key: 'draft_summer',
            inputProps: {
              decimalScale: 4
            }
          }
        ]
      },
      {
        label: 'TROPICAL',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_tropical'
          },
          {
            label: 'DRAFT',
            type: 'number',
            key: 'draft_tropical',
            inputProps: {
              decimalScale: 4
            }
          }
        ]
      },
      {
        label: 'FRESH',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_fresh'
          },
          {
            label: 'DRAFT',
            type: 'number',
            key: 'draft_fresh',
            inputProps: {
              decimalScale: 4
            }
          }
        ]
      },
      {
        label: 'TROPICAL FRESH',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_tropical_fresh'
          },
          {
            label: 'DRAFT',
            type: 'number',
            key: 'draft_tropical_fresh',
            inputProps: {
              decimalScale: 4
            }
          }
        ]
      },
      {
        label: 'PANAMA',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_panama'
          }
        ]
      },
      {
        label: 'ST LAWRENCE SEAWAY (FRESH WATER)',
        extraData: [
          {
            label: 'DWT',
            type: 'number',
            key: 'dwt_st_lawrence_seaway'
          }
        ]
      }
    ]
  }
];

export default dimensionsAndLoadlines;
