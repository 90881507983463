import React from 'react';
import comments from 'common/assets/svg/common/comments.svg';

import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const EvaluationNotes = ({ notes, className = '' }) => {
  const { tooltipID, avoidRender } = useTooltipID('evaluation-notes');

  if (avoidRender) return null;

  return notes ? (
    <div className={`d-flex align-items-center ${className}`}>
      <SvgRender
        id={tooltipID}
        src={comments}
        style={{ width: 17, height: 14 }}
        className="text-purple"
      />
      <Tooltip target={tooltipID} placement={'right'}>
        <div
          className="d-flex-column justify-content-center"
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      </Tooltip>
    </div>
  ) : null;
};

export default EvaluationNotes;
