import React from 'react';

import AsyncSelector from 'common/components/selectors/AsyncSelector';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import * as shiftActions from 'crew/store/shifts/actions';
import { selectSelectedRankFilter } from 'crew/store/shifts/selectors';

const RankSelector = ({ className }) => {
  const selectedRank = useSelector(selectSelectedRankFilter);
  const [onChange] = useActions([shiftActions.setSelectedRankFilter]);

  return (
    <AsyncSelector
      onChange={onChange}
      type="crew-ranks"
      placeholder="Select rank"
      className={`mb-0 shifts-filters__rank-select ${selectedRank ? 'has-value' : ''} ${className}`}
      selectClassName="flex-1"
      value={selectedRank}
    />
  );
};

export default RankSelector;
