import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { filterAndMapData } from '../helpers/_functions';

const DEFAULT_TRANSITION = 100;
const DEFAULT_OPACITY = 1;

const constructArrowPoints = () => {
  let points = '';

  // Make the heights negative because the canvas' origin is at the top left corner.
  const FULL_HEIGHT = -16;
  const TIP_HEIGHT = -10;
  const FULL_WIDTH = 2;
  const TIP_WIDTH = 6;

  // 7 Points:

  // Bottom Left Point
  points += `${-(FULL_WIDTH / 2)},${-(FULL_HEIGHT / 2)} `;
  // Left Side Tip Bottom Right Point
  points += `${-(FULL_WIDTH / 2)},${(FULL_HEIGHT - TIP_HEIGHT) / 2} `;
  // Left Side Tip Bottom Left Point
  points += `${-((FULL_WIDTH + TIP_WIDTH) / 2)},${(FULL_HEIGHT - TIP_HEIGHT) / 2} `;
  // Middle Tip Point
  points += `0,${FULL_HEIGHT / 2} `;
  // Right Side Tip Bottom Right Point
  points += `${(FULL_WIDTH + TIP_WIDTH) / 2},${(FULL_HEIGHT - TIP_HEIGHT) / 2} `;
  // Right Side Tip Bottom Left Point
  points += `${FULL_WIDTH / 2},${(FULL_HEIGHT - TIP_HEIGHT) / 2} `;
  // Bottom Right Point
  points += `${FULL_WIDTH / 2},${-(FULL_HEIGHT / 2)}`;

  return points;
};

const ARROW_POINTS = constructArrowPoints();

const styleDefinitions = [
  { type: 'attr', key: 'fill', style: 'arrowBackgroundColor', defaultValue: variables.lightBlue },
  { type: 'attr', key: 'transition', style: 'arrowTransition', defaultValue: DEFAULT_TRANSITION },
  { type: 'attr', key: 'stroke', style: 'arrowBorderColor', defaultValue: null },
  {
    type: 'attr',
    key: 'stroke-width',
    style: 'arrowBorderWidth',
    defaultValue: null
  },
  { type: 'attr', key: 'opacity', style: 'arrowOpacity', defaultValue: DEFAULT_OPACITY }
];

const renderPlot = ({ plotID, plot, getPlotStyle, xFn, yFn, element, plotIndex }) => {
  const filteredData = filterAndMapData(plot, plotID);
  if (!filteredData.length) return;

  // Notice the .selectAll here. It is important since the number of elements we have is the same as our data points.
  // This is different from the rest since in the line plotType for example, we only have one single <path /> element.
  const existingElements = element.selectAll(`.${plotID}`).data(filteredData);

  const el = (!existingElements.empty()
    ? existingElements
    : existingElements.enter().append('custom').classed(`polygon ${plotID} plot-${plotIndex}`, true)
  )
    .attr('x', xFn)
    .attr('y', yFn)
    .attr('plot-index', plotIndex)
    .attr('points', ARROW_POINTS)
    .attr('rotation', datum => +datum.angle);

  styleDefinitions.forEach(styleDefinition => {
    el[styleDefinition.type](
      styleDefinition.key,
      getPlotStyle(styleDefinition.style, styleDefinition.defaultValue)
    );
  });
};
const arrow = {
  renderPlot
};

export default arrow;
