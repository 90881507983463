import SvgRender from 'common/components/general/SvgRender';
import send from 'common/assets/svg/actions/send.svg';

const DeliveryReportSent = ({ className = '' }) => {
  return (
    <div className={`d-flex align-items-center text-warning ${className}`}>
      <SvgRender src={send} style={{ width: 14, height: 14 }} />
      <span className="lh-1 ps-1">Delivery Report Sent</span>
    </div>
  );
};

export default DeliveryReportSent;
