import { combineReducers } from 'redux';

import officeNotes from 'events/store/event-modules/office-notes/slice';
import vesselNotes from 'events/store/event-modules/vessel-notes/slice';
import agents from 'events/store/event-modules/agents/slice';
import underwaterServices from 'events/store/event-modules/underwater-services/slice';
import lubOilAnalysis from 'events/store/event-modules/lub-oil-analysis/slice';
import officeAttachments from 'events/store/event-modules/office-attachments/slice';
import vesselAttachments from 'events/store/event-modules/vessel-attachments/slice';
import participants from 'events/store/event-modules/participants/slice';
import jobs from 'events/store/event-modules/jobs/slice';
import events from 'events/store/event-modules/events/slice';
import audits from 'events/store/event-modules/audits/slice';
import crewChanges from 'events/store/event-modules/crew-changes/slice';
import vesselCondition from 'events/store/event-modules/vessel-condition/slice';
import checklist from 'events/store/event-modules/checklist/slice';
import vetting from 'events/store/event-modules/vetting/slice';
import emailConnection from 'events/store/event-modules/email-connection/slice';
import accounts from 'events/store/event-modules/accounts/slice';
import crewMedicalCases from 'events/store/event-modules/crew-medical-cases/slice';
import syncedModules from 'events/store/event-modules/synced-modules/slice';
import linkedPurchaseCases from 'events/store/event-modules/linked-purchase-cases/slice';

export default combineReducers({
  underwaterServices,
  officeNotes,
  vesselNotes,
  agents,
  lubOilAnalysis,
  officeAttachments,
  vesselAttachments,
  participants,
  jobs,
  events,
  audits,
  crewChanges,
  vesselCondition,
  checklist,
  vetting,
  emailConnection,
  accounts,
  crewMedicalCases,
  syncedModules,
  linkedPurchaseCases
});
