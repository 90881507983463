import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_CREW_DOCUMENTS'),
  ...asyncTypes('GET_CREW_DOCUMENT'),
  ...asyncTypes('CREATE_CREW_DOCUMENT'),
  ...asyncTypes('UPDATE_CREW_DOCUMENT'),
  ...asyncTypes('DELETE_CREW_DOCUMENT'),
  ...asyncTypes('GET_CREW_DOCUMENT_HISTORY')
};

export default TYPES;
