import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import useRouter from 'use-react-router';
import _get from 'lodash/get';
import moment from 'moment';

import SvgRender from 'common/components/general/SvgRender';
import vessel from 'common/assets/svg/common/vessels.svg';
import { displayDate } from 'common/utils/dates';
import calendar from 'common/assets/svg/common/calendar-blue.svg';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';
import { getCrewContracts } from 'crew/store/contracts/actions';
import Rank from 'crew/profile/main/evaluation/headers/components/Rank';
import { borderedFilesTypes } from '@/ts-common/utils/files.ts';
import { handleFileDownload } from '@/ts-common/utils/download.ts';
import ExportIcon from 'common/components/general/ExportIcon';

const TopStatusButton = () => {
  return <div className="crew-profile__evaluation--topstatus-button"></div>;
};

const TopStatuses = ({ active, isOnboard }) => {
  const dispatch = useDispatch();
  const [latestContract, setLatestContract] = useState();
  const [isDownloading, setIsDownloading] = useState(false);

  const { match } = useRouter();

  const id = _get(match, 'params.id', null);
  const evaluationID = _get(match, 'params.evaluationID', null);

  const fetchContracts = useCallback(async () => {
    if (!id || !active?.vessel_id || !active?.date) return;

    try {
      const res = await dispatch(
        getCrewContracts({
          id,
          date: moment(active.date)?.format('YYYY-MM-DD'),
          vessel_id: active.vessel_id,
          latest: true,
          has_promotion: true
        })
      );

      setLatestContract(res?.[0] || {});
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, id, active?.vessel_id, active?.date]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  const onDownload = async () => {
    try {
      setIsDownloading(true);

      await handleFileDownload({ url: `/crew-evaluation/${evaluationID}/export` }, false, {
        parsed: true
      });

      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);

      console.error(err);
    }
  };

  return active ? (
    <>
      <div>
        <TopStatusButton />
        <TopStatusButton />
        <TopStatusButton />
      </div>
      <div className="d-flex align-items-center justify-content-center flex-column crew-profile__evaluation--topstatus">
        <div className="pb-1 border-bottom-gray-200 text-primary w-100p">
          <Row noGutters>
            <Col xs={2} className="d-flex align-items-center ps-1">
              <SvgRender src={vessel} style={{ width: 16, height: 20 }} className="me-1" />
              <TextWithTooltip className="fw-bold h-auto fs-14 lh-16">
                {active?.vessel?.name || '-'}
              </TextWithTooltip>
            </Col>

            <Col xs={5} className="d-flex align-items-center">
              <SvgRender src={calendar} style={{ width: 19, height: 20 }} className="me-1" />
              <span className="fw-bold fs-14 lh-16">DATE:</span> &nbsp;
              {displayDate(active.date, { time: true })}
            </Col>

            {evaluationID ? (
              <Col className="d-flex align-items-center justify-content-end">
                <ExportIcon
                  extension={borderedFilesTypes.excel}
                  text="Export excel"
                  onClick={onDownload}
                  disabled={isDownloading}
                />
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="w-100p pt-1 fs-12 crew-profile__evaluation--topstatus--details">
          <Row noGutters>
            <Col xs={'auto'} className="d-flex align-items-center max-width-216">
              <span className="fw-normal text-primary">RANK</span>

              <span className="fw-bold text-violet cms-6 overflow-hidden text-nowrap">
                <Rank latestContract={latestContract} />
              </span>

              <div className="small-separator bg-primary cpy-6 cme-12 cms-12" />
            </Col>

            <Col xs="auto" className="d-flex align-items-center fs-12 lh-16">
              <span>SIGN ON DATE</span> &nbsp;
              <span className="fw-bold text-violet">
                {displayDate(latestContract?.sign_on_date) || '-'}
              </span>
              <div className="small-separator bg-primary cpy-6 cme-12 cms-12" />
            </Col>

            <Col xs="auto" className="d-flex align-items-center fs-12 lh-16">
              <span>SIGN OFF DATE</span> &nbsp;
              <span className="fw-bold text-violet">
                {displayDate(latestContract?.sign_off_date) || '-'}
              </span>
              <div className="small-separator bg-primary cpy-6 cme-12 cms-12" />
            </Col>

            <Col xs={3} className="d-flex align-items-center">
              <span className="fw-normal text-primary">EVALUATOR</span>
              <div className="w-100p d-flex text-nowrap overflow-hidden align-items-center">
                <TextWithTooltip className="flex-1 fw-bold text-violet cms-4">
                  {active?.evaluator?.full_name || '-'}{' '}
                  {active?.evaluator?.details?.position_name
                    ? ` | ${active.evaluator.details.position_name}`
                    : ''}
                </TextWithTooltip>

                {active?.evaluator?.details?.is_crew === null ||
                active?.evaluator?.details?.is_crew === undefined ||
                !active?.evaluator?.details?.position_name ? null : (
                  <div className="text-nowrap cms-4">
                    <EvaluatorDepartment
                      evaluator={{
                        ...active?.evaluator,
                        name: active?.evaluator?.details?.position_name,
                        color: active?.evaluator?.details?.department_color,
                        is_crew_department: active?.evaluator?.details?.is_crew
                      }}
                      evaluatorType={
                        active?.evaluator?.details?.is_crew ? 'crew_department' : 'department'
                      }
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  ) : null;
};

export default TopStatuses;
