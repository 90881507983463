import React from 'react';

import SvgRender from 'common/components/general/SvgRender';
import expand from 'common/assets/svg/actions/expand.svg';
import reduce from 'common/assets/svg/actions/reduce.svg';

const ExpandedContract = ({ reduced }) => {
  return (
    <div className={`expanded-contract text-white bg-${reduced ? 'green' : 'red'}`}>
      <SvgRender src={reduced ? reduce : expand} style={{ width: reduced ? 14 : 20, height: 12 }} />
    </div>
  );
};

export default ExpandedContract;
