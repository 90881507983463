import NumberField from 'captain-reports/templates/inputs/NumberField';
import CheckboxInput from 'captain-reports/templates/inputs/CheckboxInput';

import DynamicSelect from 'captain-reports/templates/inputs/DynamicSelect';

const config = () => [
  {
    fields: [
      {
        label: 'Exhaust Gas Differenal Pressure',
        key: 'boiler_exhaust_gas_differential_pressure',
        subLabel: 'mmWG',
        width: { xs: 'auto' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Soot blowing carried out',
        key: 'boiler_soot_blowing_carried_out',
        width: { xs: 3 },
        render: CheckboxInput,
        className: 'pt-1'
      },
      {
        label: 'Boiler Steam Pressure',
        key: 'boiler_steam_pressure',
        subLabel: 'bar',
        width: { xs: 'auto' },
        render: NumberField,
        className: 'max-width-182',
        type: 'number'
      },
      {
        label: 'Steam Dumping Valve Opening',
        key: 'boiler_system_dump_valve_opening',
        subLabel: '%',
        width: { xs: 'auto' },
        render: NumberField,
        className: 'max-width-182 text-nowrap',
        type: 'number'
      }
    ]
  },
  {
    header: 'WATER',
    headerClassName: 'border-bottom-grey',
    fields: [
      {
        label: 'Color',
        key: 'boiler_water_color',
        width: { xs: 2 },
        render: DynamicSelect,
        selectRest: {
          list: 'captain-report-enums-boiler-colors',
          isAsync: false
        }
      },
      {
        label: 'Chlorid',
        key: 'boiler_water_chloride',
        subLabel: 'ppm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Conductivity',
        key: 'boiler_water_conductivity',
        subLabel: 'μS/cm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Oil Presence in cascade tank',
        key: 'boiler_oil_presence_in_cascade_tank',
        width: { xs: 5 },
        render: CheckboxInput,
        className: 'pt-1'
      }
    ]
  },
  {
    fields: [
      {
        label: 'P-Alkalinity',
        key: 'boiler_p_alkalinity',
        subLabel: 'ppm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'T-Alkalinity',
        key: 'boiler_t_alkalinity',
        subLabel: 'ppm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Hydrazine',
        key: 'boiler_hydrazine',
        subLabel: 'ppm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Phosphate',
        key: 'boiler_phosphate',
        subLabel: 'ppm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Condensate',
        key: 'boiler_condensate',
        subLabel: 'pH',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const boilerConfig = {
  noon: data => config(data),
  arrival: data => config(data),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: data => config(data),
  port_noon: data => config(data),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default boilerConfig;
