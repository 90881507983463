import { FC, useEffect, useState } from 'react';
import useRouter from 'use-react-router';

import { parseQueryParams } from 'utils/urls';
import ListTopFilters from 'common/components/pms/jobs/components/ListTopFilters';
import { getTableList } from 'store/tables/lists/actions';
import { setTableShouldRefetchData } from 'common/components/jobs/_base/store/actions';
import { deleteJobAction } from 'common/components/jobs/_base/store/actions-ts';
import { toggleJobDrawer } from 'common/components/pms/jobs/store/actions';
import _isArray from 'lodash/isArray';
import useTableTopFilter from 'common/components/filters/useTableTopFilter';
import { selectJobDrawerIsOpen } from 'common/components/pms/jobs/store/selectors';
import { selectTableShouldRefetchData } from 'common/components/jobs/_base/store/selectors';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { getColumns } from 'common/components/pms/jobs/views/list/_tableColumns';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';
import binIcon from '@/common/assets/svg/actions/delete.svg';
import JobDrawer from 'common/components/pms/jobs/drawer';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import Table, {
  useTable,
  TableQueryState,
  FetchingFnRequestParamsType
} from '@webthatmatters/orca-table';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import useTableNavigation from '@/common/utils/hooks/useTableNavigation';
import { getAuthorizedPermissionSettings } from '@/utils/permissions/authorize';
import { selectAccount } from '@/store/account/selectors-ts';
import permissions from '@/common/utils/permissions/constants';
import { PMS_JOBS_LIST_TABLE_KEY } from '@/api/pms/query-keys.ts';

type BodyProps = {
  components?: {
    BulkActionsButton?: FC<{
      totals: number;
      refetchData: (
        options?: RefetchOptions | undefined
      ) => Promise<QueryObserverResult<TableQueryState<unknown>, Error>>;
    }>;
  };
};

const requestParams = { filter: true, paging: true, sorting: true, visible: true };

const Body: FC<BodyProps> = ({ components = {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState<any>(null);

  const { history, match } = useRouter();
  const urlJobId =
    (parseQueryParams(history.location.search) as any).jobID || (match.params as any).jobID;
  const createMode = history.location.pathname.includes('/create');
  const dispatch = useAppDispatch();

  const tableShouldRefetchData = useAppSelector(selectTableShouldRefetchData);
  const jobDrawerIsOpen = useAppSelector(selectJobDrawerIsOpen);

  const doneJobsFilterEnabled = useTableTopFilter({ name: 'is_completed' });

  const jobStatuses = useAppSelector(selectJobStatuses);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const account = useAppSelector(selectAccount);
  const permissionFormUids = getAuthorizedPermissionSettings(
    account,
    permissions.office.forms.view
  )?.forms;

  const fetchData = async (params?: FetchingFnRequestParamsType | undefined) => {
    const formattedParams = {
      ...params,
      sorting: {
        ...(params?.sorting || {}),
        assignment_description: params?.sorting?.assignment_vessel_system_id ?? undefined,
        status_completion: params?.sorting?.status_id ?? undefined
      },
      filters: params?.filters?.filter(f => {
        return (
          f.name !== 'due_date' ||
          f.operation !== 'between' ||
          (_isArray(f.value) && f.value.length === 2)
        );
      }),
      table: params?.label
    };

    try {
      return await (dispatch(getTableList(formattedParams)) as any);
    } catch (error) {
      console.error(error);
    }
  };

  const { navigate } = useTableNavigation();
  const table = useTable<any>({
    columns: getColumns(
      { jobStatuses, doneJobsFilterEnabled, isOnBoard, permissionFormUids },
      { history, dispatch, setActive, setIsModalOpen }
    ).map(c =>
      c.key === 'last_done_at'
        ? {
            ...c,
            hidden: doneJobsFilterEnabled
          }
        : c.key === 'carried_out_at'
        ? {
            ...c,
            hidden: !doneJobsFilterEnabled,
            canFilter: doneJobsFilterEnabled
          }
        : c.key === 'last_done_rh'
        ? {
            ...c,
            header: doneJobsFilterEnabled ? 'R/H When Done' : 'Last Done R/H',
            sort: doneJobsFilterEnabled
          }
        : c
    ) as any,
    label: PMS_JOBS_LIST_TABLE_KEY,
    navigate,
    fetchingFn: fetchData,
    defaultRequestParams: requestParams,
    defaultPerPage: 100,
    defaultHiddenColumns: ['vessel_dpt'],
    topFilters: [
      {
        name: 'vessel_id',
        operation: 'oneOf',
        value: null
      },
      {
        name: 'is_completed',
        operation: '=',
        value: null
      },
      {
        name: 'due_date',
        operation: '=',
        value: null
      },
      {
        name: 'remaining_rh_until_due',
        operation: '=',
        value: null
      },
      {
        name: 'timing',
        operation: 'oneOf',
        value: null
      },
      {
        name: 'type',
        operation: 'oneOf',
        value: null
      }
    ]
  });

  const onDelete = async (id: number | null) => {
    if (!id) return null;
    try {
      await dispatch(deleteJobAction({ id, table: PMS_JOBS_LIST_TABLE_KEY }));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (urlJobId) {
      dispatch(toggleJobDrawer(true, urlJobId));
    } else if (createMode) {
      dispatch(toggleJobDrawer(true, null));
    }
  }, [urlJobId, createMode, dispatch]);

  useEffect(() => {
    if (!jobDrawerIsOpen && tableShouldRefetchData) {
      table.refetchData();
      dispatch(setTableShouldRefetchData(false));
    }
  }, [tableShouldRefetchData, jobDrawerIsOpen, table, dispatch]);

  return (
    <>
      <Table
        hideTopPagination
        tableMidComponent={
          components.BulkActionsButton ? (
            <div className="mt-2">
              <components.BulkActionsButton
                totals={table.options.meta?.totalElements || 0}
                refetchData={table.refetchData}
              />
            </div>
          ) : null
        }
        topFiltersComponent={
          <ListTopFilters doneJobsFilterEnabled={doneJobsFilterEnabled} table={table} />
        }
        {...table}
      />

      <DangerousActionModal
        isOpen={isModalOpen}
        icon={binIcon}
        onAccept={() => onDelete(active?.id)}
        onCancel={() => setIsModalOpen(false)}
        cancelButtonText="CANCEL"
        acceptButtonText="DELETE"
        header="Remove Receipt"
      >
        Are you sure you want to remove this receipt?
      </DangerousActionModal>

      <JobDrawer refetchData={table.refetchData} />
    </>
  );
};

export default Body;
