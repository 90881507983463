import React from 'react';
import _isNumber from 'lodash/isNumber';

import _get from 'lodash/get';

const Totals = ({ totals }) => {
  const getTime = (key, decimalPlaces = 2) => {
    const value = _get(totals, key);

    if (!value && !_isNumber(value)) return '-';

    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value % 60);

    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };

  return (
    <div className="shifts-table--row-totals">
      <span>{getTime('normal')}</span>
      <span>{getTime('worked')}</span>
      <span className="border-end">{getTime('overtime')}</span>
      <span className="sm">{getTime('any_24h', 1)}</span>
      <span className="sm">{getTime('any_7d', 1)}</span>
    </div>
  );
};

export default Totals;
