import React, { useState } from 'react';
import moment from 'moment';
import Table, { useTable } from 'common/components/table';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';

import { useDispatch, useSelector } from 'react-redux';

import { selectEventId } from 'events/store/events/selectors';
import AccountsForm from './form';
import Loader from './Loader';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import edit from 'common/assets/svg/actions/edit.svg';
import deleteSvg from 'common/assets/svg/actions/delete.svg';
import companyIcon from 'common/assets/svg/common/company.svg';
import CommentsPopover from 'common/components/popovers/CommentsPopover';
import SvgRender from 'common/components/general/SvgRender';
import { numberToStr } from 'common/utils/numbers';
import paths from 'routing/routes/_paths';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import {
  selectAccountsHasData,
  selectAccountsLoading
} from 'events/store/event-modules/accounts/selectors';
import {
  deleteAccounts,
  downloadAccountsAttachments,
  getAccounts,
  getAccountsAttachments
} from 'events/store/event-modules/accounts/actions';
import invoicesIcon from 'common/assets/svg/common/invoices.svg';

const AccountsTable = ({ drawer }) => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [active, setActive] = useState(null);

  const dispatch = useDispatch();
  const { avoidRender, tooltipID } = useTooltipID('invoice-accounts-button');

  const isLoading = useSelector(selectAccountsLoading);
  const hasData = useSelector(selectAccountsHasData);
  const eventId = useSelector(selectEventId);

  const downloadAttachments = async data => {
    if (!data?.id) return;
    try {
      return await dispatch(downloadAccountsAttachments({ id: data.id })).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const requestAttachments = async data => {
    if (!data?.id) return;
    try {
      const res = await dispatch(getAccountsAttachments({ id: data.id })).unwrap();
      return res;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async params => {
    if (!eventId) return;

    try {
      await dispatch(getAccounts({ id: eventId, ...params })).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const onModalAccept = async () => {
    if (!eventId && !idToDelete) return;

    try {
      await dispatch(deleteAccounts({ id: eventId, accountId: idToDelete })).unwrap();
      table.refetchData();
    } catch (e) {
      console.error(e);
    }
    setIdToDelete(null);
  };

  const onEdit = row => {
    if (!row) return;

    setActive(row);
    drawer.open();
  };

  const table = useTable({
    label: 'events_accounts',
    requestTableListFrom: fetchData,
    defaultRequestParams: { paging: false, filters: false, sorting: true },
    columns: [
      {
        header: 'DATE',
        key: 'date',
        type: 'string',
        sort: true,
        minWidth: 100,
        maxWidth: 100,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'CATEGORY',
        key: 'category',
        type: 'string',
        sort: true,
        minWidth: 180,
        maxWidth: 180,
        headerClassName: 'bg-transparent'
      },
      {
        header: '',
        key: 'supplier_type',
        type: 'string',
        minWidth: 30,
        maxWidth: 30,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'SUPPLIER NAME',
        key: 'supplier',
        type: 'string',
        sort: true,
        minWidth: 200,
        maxWidth: 200,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'LOCAL AMOUNT',
        key: 'price',
        type: 'number',
        sort: true,
        minWidth: 120,
        maxWidth: 120,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'CUR.',
        key: 'currency',
        type: 'string',
        sort: true,
        minWidth: 80,
        maxWidth: 80,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'AMOUNT IN USD',
        key: 'amount_in_usd',
        type: 'price',
        sort: true,
        minWidth: 125,
        maxWidth: 125,
        headerClassName: 'bg-transparent'
      },
      {
        header: '',
        key: 'invoice_amount',
        type: 'price',
        bodyClassName: 'd-flex align-items-start',
        minWidth: 125,
        maxWidth: 125,
        headerClassName: 'bg-transparent'
      },
      {
        key: 'comments',
        type: 'string',
        minWidth: 36,
        maxWidth: 36,
        headerClassName: 'bg-transparent'
      },
      {
        key: 'attachments_count',
        field: 'attachments',
        headerClassName: 'bg-transparent'
      },
      {
        header: 'Created by',
        key: 'created_by',
        type: 'string',
        sort: true,
        minWidth: 160,
        maxWidth: 160,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'Created At',
        key: 'created_at',
        type: 'datetime',
        sort: true,
        minWidth: 160,
        maxWidth: 160,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'Updated by',
        key: 'updated_by',
        type: 'string',
        sort: true,
        minWidth: 160,
        maxWidth: 160,
        headerClassName: 'bg-transparent'
      },
      {
        header: 'Updated AT',
        key: 'updated_at',
        type: 'datetime',
        sort: true,
        minWidth: 180,
        maxWidth: 180,
        headerClassName: 'bg-transparent'
      },
      {
        key: 'actions',
        field: 'actions'
      }
    ]
  });

  const { refetchData } = table;

  if (avoidRender) return null;

  return (
    <>
      {isLoading && !hasData ? (
        <Loader />
      ) : (
        <>
          {hasData ? (
            <Table
              hideTableReorder
              hideTableFilters
              hideTableSearch
              hideTopPagination
              rows={{
                date: data => <div>{moment(data?.date).format('DD/MM/YYYY')}</div>,
                category: data => (
                  <TextWithTooltip className="d-flex align-items-center">
                    {data?.category?.name}
                  </TextWithTooltip>
                ),
                supplier_type: () => (
                  <SvgRender
                    className="text-moody-blue"
                    src={companyIcon}
                    style={{ width: 12, height: 12 }}
                  />
                ),
                supplier: data => (
                  <TextWithTooltip className="d-flex align-items-center">
                    {data?.supplier?.full_name}
                  </TextWithTooltip>
                ),
                currency: data => (
                  <TextWithTooltip className="d-flex align-items-center">
                    {data?.currency?.label}
                  </TextWithTooltip>
                ),
                comments: data => (
                  <div className="d-flex align-items-center justify-content-center">
                    {data.comments ? <CommentsPopover comments={data.comments} /> : '-'}
                  </div>
                ),
                attachments_count: data => ({
                  hasAttachments: data?.attachments_count > 0,
                  data,
                  downloadFiles: downloadAttachments,
                  canAddAttachments: false,
                  requestAttachments: () => requestAttachments(data)
                }),
                created_by: data => (
                  <TextWithTooltip className="d-flex align-items-center">
                    {data?.created_by?.full_name}
                  </TextWithTooltip>
                ),
                updated_by: data => (
                  <TextWithTooltip className="d-flex align-items-center">
                    {data?.updated_by?.full_name}
                  </TextWithTooltip>
                ),

                price: data => <div>{numberToStr(data?.price, 2, 2) || '-'}</div>,
                amount_in_usd: data => (
                  <div className="d-flex align-items-center">
                    {numberToStr(data?.price_base_currency_equivalent, 2, 2) || '-'}
                  </div>
                ),
                invoice_amount: data =>
                  data?.invoices?.length ? (
                    <div className="d-flex align-items-center ms-1">
                      <div className="d-flex me-1">
                        <div
                          id={tooltipID}
                          className=" bg-turquoise d-flex align-items-center cp-6 text-white fw-medium w-max border-radius-3 h-20 shadow-sm cursor-pointer"
                          onClick={() =>
                            window.open(`${paths.accounting_invoices}/${data?.invoices[0]?.id}`)
                          }
                        >
                          <SvgRender
                            className="me-1"
                            src={invoicesIcon}
                            style={{ width: 14, height: 14 }}
                          />
                          {data.invoices.length}
                        </div>

                        <Tooltip
                          placement="top"
                          target={tooltipID}
                          boundariesElement="viewport"
                          innerClassName="min-width-fit max-width-fit"
                        >
                          <div className="text-start">Opens linked Invoices in a new tab</div>
                        </Tooltip>
                      </div>
                    </div>
                  ) : null,
                actions: data => ({
                  options: [
                    {
                      label: 'Edit',
                      icon: edit,
                      onClick: () => {
                        onEdit(data);
                      }
                    },
                    {
                      label: 'Delete',
                      icon: deleteSvg,
                      onClick: () => {
                        setIsModalOpen(true);
                        setIdToDelete(data.id);
                      }
                    }
                  ]
                })
              }}
              {...table}
            />
          ) : null}
        </>
      )}

      <AccountsForm
        drawer={drawer}
        active={active}
        setActive={setActive}
        refetchData={refetchData}
      />

      <DangerousActionModal
        transparent
        onAccept={onModalAccept}
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        actionText={'DELETE'}
        header={'Delete Expense'}
        body={
          <div className="text-center">
            You are about to delete this expense. <br />
            Are you sure you want to continue?
          </div>
        }
      />
    </>
  );
};

AccountsTable.propTypes = {
  drawer: DrawerState
};

export default AccountsTable;
