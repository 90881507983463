import React, { useEffect, useState, useCallback, FC } from 'react';
import { Collapse, Button } from 'reactstrap';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import CircledButton from 'common/components/buttons/CircledButton';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'common/assets/svg/common/arrow.svg';
import arrowFilter from 'common/assets/svg/common/arrows/arrow-filters.svg';

import _debounce from 'lodash/debounce';

const CollapseBox: FC<any> = React.memo(function CollapseBox({
  header,
  children,
  className,
  headerClassName = '',
  bodyClassName = '',
  bodyInnerClassName = '',
  arrowLeft,
  isDefaultOpened,
  isShadow,
  triggerOpen,
  disabled = false,
  onOpen,
  onClose,
  disableCollapse = false,
  expandButton = false,
  reversedExpandBtn = false,
  hideExpandButton = false,
  defaultOpen = false,
  hideHeader = false,
  innerExpandButton,
  onHeaderClick,
  customExpandComponent,
  dataCy,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [innerContentIsOpen, setInnerContentIsOpen] = useState(defaultOpen);
  const setDebouncedInnerContentIsOpen = useCallback(_debounce(setInnerContentIsOpen, 300), []);

  useEffect(() => {
    if (isDefaultOpened !== undefined) {
      if (isDefaultOpened !== isOpen) {
        setIsOpen(isDefaultOpened);
        setInnerContentIsOpen(isDefaultOpened);
      }
    }
  }, [isDefaultOpened]);

  useUpdateEffect(() => {
    if (isOpen && onOpen) {
      onOpen(true);
    }
  }, [isOpen, onOpen]);

  useUpdateEffect(() => {
    if (!isOpen && onClose) {
      onClose(false);
    }
  }, [isOpen, onClose]);

  const onClick = useCallback(() => {
    const toggled = !isOpen;

    setIsOpen(toggled);

    if (toggled) setInnerContentIsOpen(toggled);
    else setDebouncedInnerContentIsOpen(toggled); // Delay closing to complete the animation
  }, [isOpen]);

  useEffect(() => {
    if (triggerOpen === true && isOpen === false) {
      setIsOpen(true);
      setInnerContentIsOpen(true);
    } else if (triggerOpen === false && isOpen === true) {
      setIsOpen(false);
      setDebouncedInnerContentIsOpen(false);
    }
  }, [triggerOpen]);

  return (
    <div
      className={`collapse-box${isOpen ? ' open' : ''} ${isShadow ? 'shadow-collapse' : ''} ${
        disabled ? 'opacity-5' : ''
      }  ${className || ''}`}
      data-cy={dataCy}
      {...rest}
    >
      {hideHeader ? null : (
        <div
          className={`collapse-box--header d-flex align-items-center fw-normal ${
            arrowLeft ? 'flex-row-reverse' : ''
          } ${!disableCollapse ? 'cursor-pointer' : ''} ${headerClassName || ''}`}
          onClick={disableCollapse ? onHeaderClick || undefined : onClick}
        >
          {header ? (
            <div className="flex-1 min-width-0" data-cy="collapse-box">
              {header}
            </div>
          ) : null}

          {hideExpandButton === false ? null : customExpandComponent ? (
            customExpandComponent(isOpen)
          ) : expandButton ? (
            <CircledButton
              onClick={onClick}
              className="collapse-box--expand"
              type="arrow"
              style={{ width: 20, height: 20 }}
              reversed={reversedExpandBtn}
              svgStyle={{
                transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                width: 12,
                height: 12
              }}
              label={
                <strong className="fw-medium text-primary fs-12">
                  {isOpen ? 'Shrink' : 'Expand'}
                </strong>
              }
            />
          ) : innerExpandButton ? (
            <div className={`d-flex align-items-center flex-row-reverse`}>
              <Button color="link" className={`p-0`}>
                <SvgRender
                  className="text-violet cms-10"
                  src={arrowFilter}
                  style={{ width: 11, height: 11, transform: isOpen ? `` : 'rotate(180deg)' }}
                />
              </Button>
              <div className="text-violet fs-12 fw-medium">{isOpen ? 'Shrink' : 'Expand'}</div>
            </div>
          ) : hideExpandButton ? null : (
            <Button color="link" className={`${arrowLeft ? 'ps-0 pe-1 py-1' : 'p-1 ms-auto'}`}>
              <SvgRender className="arrow-svg" src={arrow} style={{ width: 12, height: 12 }} />
            </Button>
          )}
        </div>
      )}
      <Collapse className={`collapse-box--body ${bodyClassName}`} isOpen={isOpen}>
        {innerContentIsOpen ? (
          <div className={`collapse-box--body-inner ${bodyInnerClassName}`}>{children}</div>
        ) : null}
      </Collapse>
    </div>
  );
});

export default CollapseBox;
