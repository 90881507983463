import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import useRouter from 'use-react-router';

import Select from 'common/components/form/styled-inputs/Select';
import { parseQueryParams } from 'common/utils/urls';
import { useActions } from 'utils/hooks';
import { components } from 'react-select';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';

import * as evaluationObligations from 'crew/store/evaluations/obligations/actions';
import { useSelector } from 'react-redux';
import { selectActiveEvaluationObligation } from '@/crew/store/evaluations/profile/selectors';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue
    className="w-100p d-flex align-items-center justify-content-between"
    {...props}
  >
    <span>{props.data.name}</span>

    <div className="me-1">
      <EvaluatorDepartment
        evaluator={props?.data?.evaluator}
        evaluatorType={props.data?.evaluator_type}
      />
    </div>
  </components.SingleValue>
);

const Option = ({ children, ...props }) => (
  <components.Option
    className="w-100p d-flex align-items-center justify-content-between"
    {...props}
  >
    <span>{props.data.name}</span>
    <EvaluatorDepartment
      evaluator={props?.data?.evaluator}
      evaluatorType={props.data?.evaluator_type}
    />
  </components.Option>
);

const ReasonSelector = ({ fields, selectField }) => {
  const [reasons, setReasons] = useState([]);
  const [getCrewEvaluationObligations] = useActions([
    evaluationObligations.getCrewEvaluationObligations
  ]);
  const activeEvaluationObligation = useSelector(selectActiveEvaluationObligation);
  const { location, match } = useRouter();

  const params = parseQueryParams(location.search);

  const activeEvaluationObligationReason = useMemo(() => {
    if (!activeEvaluationObligation) return null;

    return {
      id: activeEvaluationObligation.id,
      name: activeEvaluationObligation?.reason?.name,
      evaluator: activeEvaluationObligation?.evaluator,
      evaluator_type: activeEvaluationObligation?.evaluator_type,
      type: 'obligation'
    };
  }, [activeEvaluationObligation]);

  const getObligations = useCallback(async () => {
    if (!match?.params?.id) return;

    try {
      const res = await getCrewEvaluationObligations(match.params.id);

      if (params?.obligation_id) {
        selectField('crew_evaluation_obligation_id')(params?.obligation_id);
      }

      if (!res?.length) return;

      const obligations = res
        .filter(item => item.object_type === 'crew_evaluation_obligation')
        .map(o => ({
          id: o.id,
          name: `${o.reason.name} ${o?.evaluator?.name ? '- ' + o.evaluator.name : ''} ${
            o?.trigger_date ? '- ' + moment(o.trigger_date).format('DD/MM/YYYY') : ''
          }`,
          evaluator: o?.evaluator || '',
          evaluator_type: o?.evaluator_type || '',
          type: 'obligation'
        }));

      const reasons = res
        .filter(item => item.object_type === 'crew_evaluation_reason')
        .map(r => ({
          id: r.id,
          name: r.name,
          type: 'reason'
        }));

      setReasons([
        ...obligations,
        ...reasons,
        ...(activeEvaluationObligationReason &&
        !obligations.find(obligation => obligation.id === activeEvaluationObligationReason.id)
          ? [activeEvaluationObligationReason]
          : [])
      ]);
    } catch (err) {
      console.error(err);
    }
  }, [getCrewEvaluationObligations, match.params.id, params?.obligation_id, selectField]);

  useEffect(() => {
    getObligations();
  }, [getObligations, match.params.id, params.obligation_id, activeEvaluationObligationReason]);

  const onReasonSelect = reasonID => {
    if (reasonID === null) {
      selectField('crew_evaluation_obligation_id')(null);
      selectField('crew_evaluation_reason_id')(null);
      return;
    }

    if (
      fields.crew_evaluation_obligation_id.value === reasonID ||
      fields.crew_evaluation_reason_id.value === reasonID
    )
      return;

    if (reasons.find(r => r.id === reasonID).type === 'obligation') {
      selectField('crew_evaluation_obligation_id')(reasonID);
      selectField('crew_evaluation_reason_id')(null);
    } else {
      selectField('crew_evaluation_reason_id')(reasonID);
      selectField('crew_evaluation_obligation_id')(null);
    }
  };

  return (
    <Row noGutters className="mt-3 justify-content-between">
      <Col xs={12}>
        <Select
          className="w-100p"
          showLabel
          label={<span className="fw-bold text-primary fs-12">Reason</span>}
          placeholder="Select reason"
          options={reasons}
          components={{ SingleValue, Option }}
          value={
            fields.crew_evaluation_obligation_id.value || fields.crew_evaluation_reason_id.value
          }
          invisible={false}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          onChange={onReasonSelect}
          disabled={!fields.vessel_id.value && !fields.rank_id.value}
          isClearable={true}
          error={
            fields.crew_evaluation_obligation_id.error || fields.crew_evaluation_reason_id.error
          }
        />
      </Col>
    </Row>
  );
};

export default ReasonSelector;
