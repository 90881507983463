import React from 'react';
import Tooltip from 'common/components/general/Tooltip';

const FormOrigin = ({ formSubmission }) => {
  return (
    <Tooltip
      target={`base-status-dropdown-origin_${formSubmission?.id ? formSubmission.id : ''}`}
      placement="top-start"
      innerClassName={'forms-submission-status-origin-tooltip'}
    >
      <div className="text-nowrap text-start">
        This form is created inside a {formSubmission?.origin}.<br />
        Status is automatically inherited from the <b>{formSubmission?.origin}'s status.</b>
      </div>
    </Tooltip>
  );
};

export default FormOrigin;
