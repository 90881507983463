import { SingleDatePicker } from 'react-dates';
import { useState, useEffect } from 'react';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import PropTypes from 'prop-types';
import MonthSelect from '../components/MonthSelect';
import YearSelect from '../components/YearSelect';
import Fade from 'react-reveal/Fade';

import moment from 'moment';

export const renderMonthElement = (
  { month },
  handleMonthChange,
  handleYearChange,
  options,
  { yearStart, yearEnd }
) => (
  <div className="date-input-month-element">
    <MonthSelect value={month} onChange={handleMonthChange} />
    <YearSelect
      value={month}
      onChange={handleYearChange}
      dateOptions={options}
      yearStart={yearStart}
      yearEnd={yearEnd}
    />
  </div>
);

const SingleDatePickerWrap = ({
  value,
  onChange,
  useStringValue,
  showTime,
  hasTime,
  initialVisibleMonth,
  isOpen,
  activeMode,
  yearStart,
  yearEnd,
  ...rest
}) => {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [initialVisibleMonthValue, setInitialVisibleMonthValue] = useState(
    initialVisibleMonth && initialVisibleMonth instanceof moment ? initialVisibleMonth : moment()
  );

  useEffect(() => {
    if (value && value !== '') {
      const initialValue = value instanceof moment ? value : moment(value);
      setDate(initialValue);

      if (showTime) {
        setTime(initialValue.clone().format('HH:mm'));
      }
    } else {
      setDate(null);

      if (showTime) {
        setTime('00:00');
      }
    }

    if (value && !date) {
      setInitialVisibleMonthValue(moment(value));
    }
  }, [value]);

  useUpdateEffect(() => {
    setTime(showTime ? '00:00' : null);
  }, [showTime]);

  const handleDateChange = date => {
    if (showTime) {
      if (date) {
        const formattedDate = date.format('YYYY-MM-DD');
        const newDatetime = moment(`${formattedDate} ${time}:00`);

        onChange(newDatetime);
      } else {
        onChange(null);
      }
    } else {
      onChange(date);
    }
  };

  const handleMonthChange = value => {
    setInitialVisibleMonthValue(value.moment);
  };

  const handleYearChange = value => {
    setInitialVisibleMonthValue(value.moment);
  };

  return (
    <Fade
      duration={600}
      key={initialVisibleMonthValue && initialVisibleMonthValue?.format('YYYY-MM')}
    >
      <div className="date-input-single-date">
        <SingleDatePicker
          date={date}
          weekDayFormat="ddd"
          daySize={40}
          numberOfMonths={1}
          firstDayOfWeek={1}
          renderMonthElement={props =>
            renderMonthElement(props, handleMonthChange, handleYearChange, [], {
              yearStart,
              yearEnd
            })
          }
          initialVisibleMonth={() => initialVisibleMonthValue}
          focused={true}
          appendToBody={false}
          isOutsideRange={() => false}
          hideKeyboardShortcutsPanel
          onDateChange={date => handleDateChange(date)}
          onFocusChange={() => true}
          {...rest}
        />
      </div>
    </Fade>
  );
};

SingleDatePickerWrap.propTypes = {
  value: PropTypes.instanceOf(moment),
  initialVisibleMonth: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  useStringValue: PropTypes.bool // Use it to return string instead of a moment object
};

export default SingleDatePickerWrap;
