export const mode = {
  single: {
    date: 'date-single',
    year: 'year-single',
    month: 'month-single'
  },
  range: {
    date: 'date-range',
    year: 'year-range',
    month: 'month-range',
    weekdate: 'weekdate-range'
  },
  multiple: {
    date: 'date-multiple'
  }
} as const;

export const SINGLE_DATE_WITH_TIME = `${mode?.single?.date}-with-time` as const;
