import { Attachment } from '@/ts-common/types/files';

interface ImageUrlSelectorProps {
  file: Attachment | null;
  label?: string | null;
}

export const imageUrlSelector = ({ file, label }: ImageUrlSelectorProps): string | null => {
  let url: string | null = null;

  if (file && file.image_sizes && file.image_sizes.length && label) {
    const imageSize = file.image_sizes.find(inner => inner.size_label === label);

    if (imageSize) {
      url = imageSize.resized.url;
    } else {
      url = file.url ?? null;
    }
  } else {
    url = file?.url ?? null;
  }

  return url;
};
