import React, { useCallback } from 'react';
import { useActions } from 'utils/hooks';
import TicketOfferHandler from 'common/components/tickets/TicketOfferHandler';

import * as ticketingActions from 'store/ticketing/actions';

const TicketButton = ({ participant }) => {
  const [openTripDrawer] = useActions([ticketingActions.openTripDrawer]);

  const { trip_id, accepted_tickets } = participant || {};

  const hasPendingOffers = trip_id ? true : false;
  const hasAcceptedOffers = trip_id && accepted_tickets.length ? true : false;

  const toggleTicketDrawer = useCallback(() => {
    if (trip_id) openTripDrawer({ type: 'event', tripIDs: [trip_id], disabled: true });
  }, [openTripDrawer, trip_id]);

  return (
    <TicketOfferHandler
      hasAcceptedOffers={hasAcceptedOffers}
      hasPendingOffers={hasPendingOffers}
      showEmptyButton
      size="lg"
      onClick={hasPendingOffers || hasAcceptedOffers ? toggleTicketDrawer : null}
    />
  );
};

export default TicketButton;
