import { useState, useEffect, FC, useCallback } from 'react';

import GalleryHeader from './components/GalleryHeader';
import GalleryCenterContainer from './components/GalleryCenterContainer';
import GalleryFooter from './components/GalleryFooter';
import SvgRender from '@/ts-common/components/general/SvgRender';

import arrowIcon from '@/ts-common/assets/svg/arrows/arrow-filters.svg';
import { Attachment } from '@/ts-common/types/files';
import { RotateDirectionType } from './store/types';

type GalleryProps = {
  files: Attachment[];
  isOpen: boolean;
  selectedFile: Attachment | null;
  rotateDirection: RotateDirectionType;
  setSelectedFile: (file: Attachment) => void;
  toggleGallery: (isOpen: boolean) => void;
  setRotateDirection: (rotateDirection: RotateDirectionType) => void;
};

const Gallery: FC<GalleryProps> = ({
  files,
  isOpen,
  rotateDirection,
  setRotateDirection,
  selectedFile,
  setSelectedFile,
  toggleGallery
}) => {
  const [isGalleryHovered, setIsGalleryHovered] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const setIsOpen = (value: boolean) => {
    toggleGallery(value);
  };

  useEffect(() => {
    if (!isOpen) return;

    if (selectedFile?.id && files.length) {
      setCurrentIndex(files.findIndex((file: Attachment) => file.id === selectedFile.id));
    } else {
      setCurrentIndex(0);
    }
  }, [files, selectedFile?.id, isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    if (isOpen && !selectedFile?.id && files.length) {
      setSelectedFile(files[0]);
    }
  }, [files, isOpen, selectedFile?.id, setSelectedFile]);

  const onClickItem = (index: number) => {
    if (files[index]) {
      setCurrentIndex(index);
    }
  };

  const onNextClick = () => {
    if (files[currentIndex + 1]) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onPrevClick = () => {
    if (files[currentIndex - 1]) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const onKeydown = (event: KeyboardEvent) => {
    if (event) {
      if (event.key === 'Escape') {
        setIsOpen(false);
      } else if (event.keyCode === 37) {
        onPrevClick();
      } else if (event.keyCode === 39) {
        onNextClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  const setGalleryHoveredState = useCallback(
    () => setIsGalleryHovered(prev => (!prev ? true : prev)),
    []
  );

  const setGalleryUnhoveredState = useCallback(
    () => setIsGalleryHovered(prev => (prev ? false : prev)),
    []
  );

  return isOpen ? (
    <div className="gallery__background">
      <div
        className="gallery"
        onMouseEnter={setGalleryHoveredState}
        onMouseLeave={setGalleryUnhoveredState}
      >
        {currentIndex >= 0 ? (
          <GalleryHeader
            setIsOpen={setIsOpen}
            file={files[currentIndex]}
            setRotateDirection={setRotateDirection}
          />
        ) : null}

        <div className="gallery__center">
          {files.length ? (
            <div
              onClick={onPrevClick}
              className="gallery__center--arrow gallery__center--arrow-left"
            >
              {currentIndex !== 0 ? (
                <SvgRender src={arrowIcon} style={{ width: 14, height: 16 }} />
              ) : null}
            </div>
          ) : null}

          {currentIndex >= 0 ? (
            <GalleryCenterContainer
              isGalleryHovered={isGalleryHovered}
              file={files[currentIndex]}
              rotateDirection={rotateDirection}
            />
          ) : null}

          {files.length ? (
            <div
              onClick={onNextClick}
              className="gallery__center--arrow gallery__center--arrow-right"
            >
              {currentIndex !== files.length - 1 ? (
                <SvgRender src={arrowIcon} style={{ width: 14, height: 16 }} />
              ) : null}
            </div>
          ) : null}
        </div>

        {currentIndex >= 0 ? (
          <GalleryFooter
            onClickItem={onClickItem}
            files={files}
            selectedFile={files[currentIndex]}
          />
        ) : null}
      </div>
    </div>
  ) : null;
};

export default Gallery;
