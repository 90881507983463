import React from 'react';

import { Link } from 'react-router-dom';
import paths from 'routing/routes/_paths';

const ManualVersionLink = ({ versionId, manualId, chapterUid, children, className = '' }) => {
  return (
    <Link
      to={`${paths.manuals}/${manualId}${chapterUid ? `/chapter/${chapterUid}` : ''}${
        versionId ? `?version=${versionId}` : ''
      }`}
      className={`d-inline-flex align-items-center justify-content-center text-decoration-none ${className}`}
    >
      {children}
    </Link>
  );
};

export default ManualVersionLink;
