import { Row, Col } from 'reactstrap';
import CharterPartySelector from 'common/components/selectors/CharterPartySelector';

import useValue from 'captain-reports/utils/useValue';
import useError from 'captain-reports/utils/useError';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectCaptainReportIsOnBoard,
  selectCaptainReportVessel,
  isReportLocked
} from 'captain-reports/store/selectors';

import * as reportActions from 'captain-reports/store/actions';

const DeliveredToCharterField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const isOnboard = useSelector(selectCaptainReportIsOnBoard);
  const vessel = useSelector(selectCaptainReportVessel);
  const isLocked = useSelector(isReportLocked);

  const [setValue] = useActions([reportActions.setValue]);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const error = useError({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  if (isOnboard) return null;

  return (
    <div className="pt-2 border-top border-white">
      <Row noGutters>
        <Col xs={6} className="cpe-6">
          <CharterPartySelector
            isClearable
            disabled={isLocked}
            placeholder="Select charter party"
            value={value}
            error={error}
            label={field.label}
            vesselId={vessel?.id}
            onChange={selected =>
              setValue({
                key: field.key,
                value: selected
                  ? { ...selected, id: selected?.id || selected?.charter_party_id }
                  : null,
                sectionLabel,
                sectionActiveTab,
                subGroup,
                subGroupIndex,
                selectKey: field?.selectRest?.selectKey
              })
            }
          />

          <div className="fs-10 lh-12 text-violet fw-medium cmt-12">
            *Here you should select a charterer from vessel’s charter parties list. If you do not
            see the charterer you want, then you can add a new charter party in menu Voyages {'>'}
            Operations.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DeliveredToCharterField;
