import Boilers from 'common/components/vessels/profile/tabs/info/components/auxiliaries-machinery/Boilers';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';

const auxiliaryMachinery = [
  {
    header: 'BOILERS',
    fields: [
      {
        label: 'NO. OF BOILERS',
        key: 'boilers_no',

        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        key: 'boilers',
        type: 'multiple',
        component: Boilers,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            key: 'boiler_number',
            type: 'number',
            render: () => null
          },
          {
            label: 'BOILER MAKER',
            key: 'maker.company_name',
            select_key: 'maker',
            type: 'select',
            inputProps: {
              placeholder: 'Add Maker',
              isAsync: true,
              getOptionValue: option => option.id,
              getOptionLabel: option => getPartyDisplayName(option),
              defaultOptions: () =>
                getInitialAsyncValues('parties', null, null, { type: 'company' }),
              loadOptions: search => getAsyncOptions(search, 'parties', { type: 'company' }),
              isClearable: true
              // isCreatable: true
            }
          },
          {
            label: 'BOILER MODEL',
            key: 'model'
          },

          {
            label: 'BOILER CAPACITY (t/h)',
            key: 'capacity',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          }
        ]
      }
    ]
  },
  {
    header: 'COOLERS',
    fields: [
      {
        label: 'NO. OF AIR COOLERS',
        key: 'air_coolers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF JACKET COOLERS',
        key: 'jacket_coolers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF CONTROL AIR DRYERS (CAD)',
        key: 'control_air_dryers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF CENTRAL COOLERS',
        key: 'central_coolers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF MAIN LO COOLERS',
        key: 'main_lo_coolers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      }
    ]
  },
  {
    header: 'PUMPS',
    fields: [
      {
        label: 'NO. OF MAIN LO PUMPS',
        key: 'me_lo_pumps_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF MAIN COOLER SW PUMPS',
        key: 'main_cooler_sw_pumps_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF JACKET COOLER PUMPS',
        key: 'jacket_cooler_pumps_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF LTCW PUMPS',
        key: 'me_ltcw_pumps_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF HLTCW PUMPS',
        key: 'me_htcw_pumps_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      }
    ]
  },
  {
    header: 'PURIFIERS',
    fields: [
      {
        label: 'NO. OF FO PURIFIERS',
        key: 'fo_purifiers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF LO PURIFIERS',
        key: 'lo_purifiers_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      }
    ]
  }
];

export default auxiliaryMachinery;
