import { useEffect, useState } from 'react';

import Drawer, { DrawerHeader, DrawerBody, DrawerFooter } from 'common/components/drawer';
import ShiftsContainer from './ShiftsContainer';
import TextArea from 'common/components/form/inputs/Textarea';
import { Button, Row, Col } from 'reactstrap';

import { useForm, useFormState, useActions } from 'utils/hooks';
import * as workingScheduleActions from 'store/working-schedule/actions';

import config from './config';
import _get from 'lodash/get';
import { getFormattedTime } from 'common/utils/dates';
import moment from 'moment';

const WorkingScheduleDrawer = ({ active, drawer }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [hasTimeError, setHasTimeError] = useState({});
  const { formState, loadValues, collectValues } = useForm(config, {});
  const {
    fields,
    changeField,
    subStates,
    hasErrors: formHasErrors,
    addSubform,
    removeSubform
  } = useFormState(formState);

  const [updateCrewSchedule] = useActions([workingScheduleActions.updateCrewSchedule]);

  useEffect(() => {
    if (drawer.isOpen) {
      const getShifts = path => {
        const shifts = _get(active, path) || [];
        return shifts.length ? shifts : [{ from: null, to: null }];
      };

      const parseTimeData = data => {
        return data.map(shift => {
          return {
            ...shift,
            from: moment(shift.from, 'HH:mm:ss').format('HH:mm'),
            to: moment(shift.to, 'HH:mm:ss').format('HH:mm')
          };
        });
      };

      const params = {
        comments: _get(active, 'comments'),
        atSeaWatchKeeping:
          active.shifts.atSea.watchkeeping.length > 0
            ? parseTimeData(getShifts('shifts.atSea.watchkeeping'))
            : [],
        atSeaNonWatchKeeping:
          active.shifts.atSea.nonWatchkeeping.length > 0
            ? parseTimeData(getShifts('shifts.atSea.nonWatchkeeping'))
            : [],
        atPortWatchKeeping:
          active.shifts.atPort.watchkeeping.length > 0
            ? parseTimeData(getShifts('shifts.atPort.watchkeeping'))
            : [],
        atPortNonWatchKeeping:
          active.shifts.atPort.nonWatchkeeping.length > 0
            ? parseTimeData(getShifts('shifts.atPort.nonWatchkeeping'))
            : []
      };

      loadValues(params);
    }
  }, [active, drawer.isOpen, loadValues]);

  const onSave = async () => {
    const values = collectValues();
    if (!values || formHasErrors) return;

    try {
      setIsSaving(true);

      const shifts = [
        ...values.atSeaWatchKeeping.map(shift => ({
          ...shift,
          from: getFormattedTime(shift.from),
          to: getFormattedTime(shift.to),
          is_watchkeeping: true,
          is_at_sea: true
        })),
        ...values.atSeaNonWatchKeeping.map(shift => ({
          ...shift,
          from: getFormattedTime(shift.from),
          to: getFormattedTime(shift.to),
          is_watchkeeping: false,
          is_at_sea: true
        })),
        ...values.atPortWatchKeeping.map(shift => ({
          ...shift,
          from: getFormattedTime(shift.from),
          to: getFormattedTime(shift.to),
          is_watchkeeping: true,
          is_at_sea: false
        })),
        ...values.atPortNonWatchKeeping.map(shift => ({
          ...shift,
          from: getFormattedTime(shift.from),
          to: getFormattedTime(shift.to),
          is_watchkeeping: false,
          is_at_sea: false
        }))
      ].filter(shift => shift.from && shift.to);

      const params = {
        crewId: _get(active, 'seaman.id'),
        comments: values.comments,
        shifts
      };

      await updateCrewSchedule(params);

      setIsSaving(false);
      drawer.close();
    } catch (e) {
      setIsSaving(false);
    }
  };

  const setHasTimeErrorPerSubform = type => (index, value) => {
    setHasTimeError({ ...hasTimeError, [`${type}-${index}`]: value });
  };

  const hasErrors = !Object.values(hasTimeError).every(val => !val);

  return (
    <Drawer {...drawer} className="working-schedule__drawer">
      <DrawerHeader>
        Edit <b>{_get(active, 'seaman.full_name')}'s</b> Working Schedule
      </DrawerHeader>
      <DrawerBody className="py-3 gray-scrollbar">
        <Row className="mb-4">
          <Col xs={6}>
            <ShiftsContainer
              isWatchkeeping
              isAtSea
              subStates={subStates('atSeaWatchKeeping')}
              addSubform={() => addSubform('atSeaWatchKeeping')}
              removeSubform={i => removeSubform('atSeaWatchKeeping', i)}
              setHasTimeError={setHasTimeErrorPerSubform('atSeaWatchKeeping')}
              totalFormState={formState.state.atSeaNonWatchKeeping.concat(
                formState.state.atSeaWatchKeeping
              )}
            />
          </Col>
          <Col xs={6}>
            <ShiftsContainer
              isAtSea
              subStates={subStates('atSeaNonWatchKeeping')}
              addSubform={() => addSubform('atSeaNonWatchKeeping')}
              removeSubform={i => removeSubform('atSeaNonWatchKeeping', i)}
              setHasTimeError={setHasTimeErrorPerSubform('atSeaNonWatchKeeping')}
              totalFormState={formState.state.atSeaNonWatchKeeping.concat(
                formState.state.atSeaWatchKeeping
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <ShiftsContainer
              isWatchkeeping
              subStates={subStates('atPortWatchKeeping')}
              addSubform={() => addSubform('atPortWatchKeeping')}
              removeSubform={i => removeSubform('atPortWatchKeeping', i)}
              setHasTimeError={setHasTimeErrorPerSubform('atPortWatchKeeping')}
              totalFormState={formState.state.atPortNonWatchKeeping.concat(
                formState.state.atPortWatchKeeping
              )}
            />
          </Col>
          <Col xs={6}>
            <ShiftsContainer
              subStates={subStates('atPortNonWatchKeeping')}
              addSubform={() => addSubform('atPortNonWatchKeeping')}
              removeSubform={i => removeSubform('atPortNonWatchKeeping', i)}
              setHasTimeError={setHasTimeErrorPerSubform('atPortNonWatchKeeping')}
              totalFormState={formState.state.atPortNonWatchKeeping.concat(
                formState.state.atPortWatchKeeping
              )}
            />
          </Col>
        </Row>
        <TextArea
          label="COMMENTS"
          className="mt-4"
          onChange={changeField('comments')}
          {...fields.comments}
        />
      </DrawerBody>
      <DrawerFooter className="d-flex justify-content-end p-3">
        <Button color="cancel" onClick={() => drawer.close()}>
          CANCEL
        </Button>
        <Button
          color="primary"
          disabled={isSaving || hasErrors}
          onClick={onSave}
          className="ms-2 px-5"
        >
          SAVE
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default WorkingScheduleDrawer;
