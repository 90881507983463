import { useEffect, useState } from 'react';
import { DrawerBody, DrawerHeader, DrawerFooter } from 'common/components/drawer';
import EvaluationSlider from '@/common/components/form/inputs/EvaluationSlider.tsx';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { evaluateItineraryPortAgent } from '@/common/components/voyages/itinerary/store/actions';
import { useDispatch } from 'react-redux';
import { ItineraryAgent } from 'common/entities/itineraries/ItineraryTypes';

const EvaluationForm = ({ onSubmitCallback = () => {}, agent, closeForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState(agent?.evaluation?.rating || 1);

  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await dispatch(evaluateItineraryPortAgent({ id: agent?.id, rating: range }));

      onSubmitCallback();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (!agent?.id) return;

    if (agent?.evaluation?.rating) {
      setRange(agent.evaluation.rating);
    } else {
      setRange(1);
    }
  }, [agent?.id, agent?.evaluation?.rating]);

  return (
    <>
      <DrawerHeader>
        Evaluate Agent
        <div className="fs-12 text-dark fw-bold pt-1">{agent?.party?.full_name}</div>
      </DrawerHeader>
      <DrawerBody className="pt-4">
        <div className={'form-label mb-3'}>Communication & Service</div>
        <EvaluationSlider
          markClassName="fs-10 text-platinum mt-1 cps-4"
          onChange={v => setRange(v)}
          defaultValue={0}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          value={range}
        />
      </DrawerBody>
      <DrawerFooter className="w-100p d-flex justify-content-end py-1">
        <Button color="cancel" className="px-0 py-1 me-4 height-40" onClick={() => closeForm()}>
          CANCEL
        </Button>
        <Button
          color="primary"
          onClick={() => onSubmit()}
          className="px-4 height-40"
          disabled={isLoading}
        >
          SUBMIT
        </Button>
      </DrawerFooter>
    </>
  );
};

EvaluationForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func,
  agent: ItineraryAgent.isRequired
};

export default EvaluationForm;
