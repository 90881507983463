import PropTypes from 'prop-types';

export const SelectorsBasicType = PropTypes.shape({
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  error: PropTypes.string,
  onChange: PropTypes.func
});
export const Selectors = PropTypes.shape({
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  autoFocus: PropTypes.bool,
  styled: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  defaultOptionsTriggerChange: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  listParams: PropTypes.object
});
