import PropTypes from 'prop-types';

import Switch from 'common/components/form/inputs/Switch';
import SvgRender from 'common/components/general/SvgRender';
import vessels from 'common/assets/svg/common/vessels.svg';

const ForVesselSwitch = ({ hideLabel = false, onChange, value, disabled, className = '' }) => {
  return (
    <div
      className={`for-vessel-switch d-inline-flex align-items-center${!value ? ' inactive' : ''}${
        disabled ? ' disabled' : ''
      } ${className}`}
      onClick={!disabled ? onChange : () => {}}
    >
      <SvgRender src={vessels} style={{ width: 9, height: 12 }} className="me-1" />
      {!hideLabel ? <small className="fs-10 fw-medium pe-1">Sync Onboard</small> : null}
      <Switch
        switchClassName="blue-switch"
        className="mb-0"
        size="sm"
        onChange={!disabled ? onChange : () => {}}
        value={value}
      />
    </div>
  );
};

ForVesselSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func
};

export default ForVesselSwitch;
