import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormState } from 'common/entities/form/FormTypes';
import { useFormState } from 'utils/hooks';
import RadiosWithText from './RadiosWithText';
import ScaleNumberInput from './ScaleNumberInput';
import Trainings from './Trainings';

const Criterion = ({ index, criterionState, hasSubmittedEvaluation, errors }) => {
  const { fields, selectField } = useFormState(criterionState);
  const { state } = criterionState;

  let CriterionComponent = null;

  const componentProps = {
    selectField,
    fields,
    hasSubmittedEvaluation,
    errors
  };

  switch (state.type.value) {
    case 'scale':
      CriterionComponent = ScaleNumberInput;
      break;

    case 'trainings':
      CriterionComponent = Trainings;
      break;

    case 'radio-button-with-text-input':
      CriterionComponent = RadiosWithText;
      break;

    default:
      return null;
  }

  return (
    <Row
      noGutters
      className="criterio-row d-flex align-items-center flex-1 cpy-10 cpx-12 text-primary fs-13"
    >
      <Col xs={5} className="fw-medium">
        <span className="fw-bold me-1">{index + 1}.</span>
        {state.name.value}
      </Col>
      <Col xs={6}>
        <CriterionComponent {...componentProps} />
      </Col>
    </Row>
  );
};

Criterion.propTypes = {
  index: PropTypes.number.isRequired,
  criterionState: FormState,
  hasSubmittedEvaluation: PropTypes.bool
};

export default Criterion;
