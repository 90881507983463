import React from 'react';
import useRouter from 'use-react-router';
import { Button, Col, Row } from 'reactstrap';
import {
  selectIsCreating,
  selectEvent,
  selectEventId,
  selectisEditingModules,
  selectIsCreate,
  selectMultiEventsDateValue,
  selectIsMultipleEventsCreate,
  selectIsMainInfoLocked
} from 'events/store/events/selectors';
import { useDrawer } from 'common/components/drawer';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import paths from 'routing/routes/_paths';
import Toolbar from 'events/event/modules/toolbar';
import ModuleList from 'events/event/modules/module-list';
import Watchers from 'events/components/watchers';
import Actions from 'events/components/Actions';
import { dateValidation } from '../helpers';
import DigitalSignature from 'common/components/digital-signature';
import { selectEventTemplateChecklist } from 'events/event-templates/store/selectors';
import { selectOfficeNotesFormData } from 'events/store/event-modules/office-notes/selectors';
import { selectVesselNotesContent } from 'events/store/event-modules/vessel-notes/selectors';
import { selectChecklistState } from 'events/store/event-modules/checklist/selectors';
import {
  createEvent,
  createEventDigitalSignature,
  deleteEventDigitalSignature,
  getEvent,
  getEventDigitalSignatures,
  setErrors
} from 'events/store/events/actions';
import { selectOfficeAttachmentsData } from 'events/store/event-modules/office-attachments/selectors';
import Chat from './Chat';
import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';
import LogsTimelineDrawer from '@/common/components/logs-timeline-drawer';
import TimelinePoint from '@/common/components/logs-timeline-drawer/TimelinePoint';
import { LOGS_ENTITY_TYPES } from '@/common/types/logs';

const Header = () => {
  const dispatch = useDispatch();
  const { history } = useRouter();

  const isOnBoard = useSelector(state => state.isOnBoard);
  const isCreating = useSelector(selectIsCreating);
  const event = useSelector(selectEvent);
  const eventId = useSelector(selectEventId);
  const isEditingModules = useSelector(selectisEditingModules);
  const isMainInfoLocked = useSelector(selectIsMainInfoLocked);
  const isCreate = useSelector(selectIsCreate);
  const multiEventDates = useSelector(selectMultiEventsDateValue);
  const isMultipleEventsCreate = useSelector(selectIsMultipleEventsCreate);
  const checklistState = useSelector(selectChecklistState);
  const officeSavedNotes = useSelector(selectOfficeNotesFormData);
  const vesselSavedNotes = useSelector(selectVesselNotesContent);
  const templateSavedChecklist = useSelector(selectEventTemplateChecklist);
  const officeAttachmentsData = useSelector(selectOfficeAttachmentsData);

  const signatureDrawer = useDrawer(false);
  const logsDrawer = useDrawer();

  const onCreate = async () => {
    if (!event.type) {
      dispatch(setErrors({ type: 'Type is required' }));
      return;
    }

    if (!event.name) {
      dispatch(setErrors({ title: 'Title is required' }));
      return;
    }

    if (event.type?.has_subtype && !event.subtypes?.length) {
      dispatch(setErrors({ subtypes: 'Subtype is required' }));
      return;
    }

    const dateType = _get(event, 'date_type', undefined);
    const startedAt = _get(event, 'started_at', undefined);
    const endedAt = _get(event, 'ended_at', undefined);

    const error = dateValidation({
      startedAt: multiEventDates?.length
        ? multiEventDates.some(event => event?.started_at)
        : !!startedAt,
      endedAt: multiEventDates?.length ? multiEventDates.some(event => event?.ended_at) : !!endedAt,
      dateType
    });

    if (error) {
      dispatch(setErrors({ date: error }));
      return;
    }

    const checklistData = checklistState?.[0]?.title ? checklistState : templateSavedChecklist;
    const checklist = checklistData?.length
      ? checklistData.map(c => ({
          sort_index: c?.sort_index,
          title: c?.title,
          mentions: c.mentions
        }))
      : [];

    try {
      const params = {
        name: _get(event, 'name', undefined),
        date_type: isMultipleEventsCreate
          ? undefined
          : startedAt
          ? _get(event, 'date_type', undefined)
          : undefined,

        is_scheduled: _get(event, 'is_scheduled', undefined),
        is_datetime: isMultipleEventsCreate ? undefined : _get(event, 'is_datetime', undefined),
        is_for_vessel: _get(event, 'is_for_vessel', undefined),
        ended_at: isMultipleEventsCreate
          ? undefined
          : dateType === 'range'
          ? _get(event, 'ended_at', undefined)
          : undefined,
        type_id: _get(event, 'type.id', undefined),
        subtype_ids:
          _get(event, 'type.label', undefined) === 'drill' ||
          _get(event, 'type.label', undefined) === 'training'
            ? _get(event, 'subtypes', []).map(s => s.id)
            : undefined,
        status_id: _get(event, 'status.id', undefined),
        importance_id: _get(event, 'importance.id', undefined),
        port_id: event?.port_type === 'port' ? _get(event, 'port.id', undefined) : null,
        itinerary_port_id:
          event?.port_type === 'itinerary_port'
            ? _get(event, 'itinerary_port.id', undefined)
            : null,
        lat:
          event?.port?.code === 'ATSEA' && event?.port_type === 'port'
            ? _get(event, 'port.lat', undefined)
            : null,
        lon:
          event.port?.code === 'ATSEA' && event?.port_type === 'port'
            ? _get(event, 'port.lon', undefined)
            : null,
        vessel_id: _get(event, 'vessel.id', undefined),
        responsible_id: _get(event, 'responsible.id', undefined),
        department_ids: _get(event, 'department_ids', undefined),
        approver_ids: event?.approvers?.length
          ? event.approvers.map(approver => approver.id)
          : undefined,
        remarks: _get(event, 'remarks', ''),
        tags: _get(event, 'tags', []),
        module_type_ids: _get(event, 'modules', []).map(el => el.type.id),
        checklist: checklist?.[0]?.title ? checklist : undefined,
        dates: _get(event, 'dates', undefined),
        watcher_ids: event.watchers.map(watcher => watcher.id),
        office_attachment_groups: officeAttachmentsData.map(group => ({
          ...group,
          attachments: group.attachments.map(attachment => attachment.id)
        }))
      };

      if (isOnBoard) {
        params.vessel_notes = { content: vesselSavedNotes };
      } else {
        params.office_notes = {
          content: officeSavedNotes?.content,
          tags: officeSavedNotes?.tags?.map(({ text }) => text)
        };
      }

      const res = await dispatch(createEvent(params));

      if (isMultipleEventsCreate) {
        history.push(`${paths.events}`);
      } else {
        history.push(`${paths.events}/${res[0]}`);
      }
    } catch {}
  };

  return (
    <div className="mt-n3">
      <Row className="d-flex align-items-center mb-5 flex-nowrap g-0">
        <Col className="max-w-65p">
          <Toolbar />
        </Col>

        <Col
          xs="auto"
          className={`${
            isOnBoard ? 'd-flex align-items-center justify-content-end' : ''
          } cpb-2 cps-12 ms-auto`}
        >
          {isCreate ? (
            <div className="d-flex align-items-center justify-content-end">
              <Watchers className="me-3" />
              <Button
                color={'cancel'}
                className={`d-flex align-items-center justify-content-center me-1`}
                data-cy="cancel"
                onClick={() => history.goBack()}
              >
                CANCEL
              </Button>
              <Button
                disabled={isCreating}
                color={'primary'}
                className={`d-flex align-items-center justify-content-center ${
                  !isOnBoard ? 'me-8' : ''
                }`}
                data-cy="create"
                onClick={onCreate}
              >
                CREATE
              </Button>
            </div>
          ) : (
            <div
              className={`d-flex align-items-center header-right-part ${!isOnBoard ? 'pe-6' : ''}`}
            >
              <div className={`d-flex align-items-center ${!isOnBoard ? 'border-end pe-2' : ''}`}>
                <Watchers className="me-3" />
                <DigitalSignature
                  count={event.signatures_count}
                  drawer={signatureDrawer}
                  onCreate={async (party_id, signature) =>
                    await dispatch(
                      createEventDigitalSignature({ id: eventId, party_id, signature })
                    )
                  }
                  onFetch={async () => await dispatch(getEventDigitalSignatures({ id: eventId }))}
                  onDelete={async signature_id =>
                    await dispatch(deleteEventDigitalSignature({ id: eventId, signature_id }))
                  }
                  onClose={async () => {
                    if (eventId) {
                      await dispatch(getEvent({ id: eventId }));
                    }
                  }}
                />
              </div>
              {!isOnBoard && <Actions event={event} />}
              <Chat />
              {!isOnBoard && (
                <ToggleLogsTimelineDrawerButton className="ms-1" onClick={logsDrawer.open} />
              )}
            </div>
          )}
        </Col>
      </Row>
      {isEditingModules && !isMainInfoLocked ? <ModuleList /> : null}
      {!isOnBoard && (
        <LogsTimelineDrawer
          drawer={logsDrawer}
          entityId={eventId}
          entityType={LOGS_ENTITY_TYPES.event}
        />
      )}
    </div>
  );
};

export default Header;
