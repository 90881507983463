import { FC } from 'react';
import { CrewDepartment } from 'common/components/selectors/departments/CrewDepartment';
import { Department as DepartmentType } from '@/common/types/enums';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import EntityLabel from 'common/components/labels/EntityLabel';
import TextWithTooltip from '@/ts-common/components/general/TextWithTooltip';

export const Department = ({ value }: { value?: DepartmentType }) => {
  if (!value) return null;

  if (value.is_crew_department) {
    return <CrewDepartment name={value.name} key={value.id} />;
  } else {
    return (
      <EntityLabel type="department" color={value.color}>
        <TextWithTooltip className="h-auto">{value.name}</TextWithTooltip>
      </EntityLabel>
    );
  }
};

type DepartmentsProps = {
  values?: DepartmentType[] | null;
  maxLimit?: number;
  allowOverflow?: boolean;
};

const Departments: FC<DepartmentsProps> = ({
  values = [],
  maxLimit = 1,
  allowOverflow = false
}) => {
  if (!values?.length) return null;

  return (
    <div className="departments-wrap">
      <NamesInTooltip
        maxHeight={values.length > 1 ? 18 : 20}
        names={(values || []).map((value, index) => (
          <Department value={value} key={index} />
        ))}
        maxLimit={maxLimit} // show always the first department to prevent issues with the hiddenNamesCount
        customSeparator=" "
        showThreeDots={false}
        allowOverflow={allowOverflow}
      />
    </div>
  );
};

export default Departments;
