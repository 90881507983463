import React from 'react';

import { Row, Col } from 'reactstrap';

import DateInput from 'common/components/form/inputs/date';
import Input from 'common/components/form/inputs/Input';
import PropTypes from 'prop-types';
import Information from 'common/components/general/Information';
import { getDatesDifferenceAsDays } from 'common/utils/dates';

const ArrivedAt = ({ selectField, changeField, fields }) => {
  const shownDays = getDatesDifferenceAsDays(fields?.arrived_at?.value, fields?.closed_at?.value);

  return (
    <Row xs={12} className="d-flex mb-2">
      <Col xs={4}>
        <DateInput
          label={
            <div className="d-flex">
              <div className="me-1">Arrived at</div>
              <Information
                message={'Fill in only if seaman has been repatriated.'}
                svgStyle={{ width: 10, height: 10 }}
                tooltipClassname="min-width-fit max-width-fit"
                className="text-primary d-flex align-items-center"
              />
            </div>
          }
          value={fields?.arrived_at?.value}
          from={fields?.arrived_at?.value}
          onChange={selected => selectField('arrived_at')(selected)}
          className="mb-0"
        />
      </Col>
      <Col className="ps-1">
        <Input
          placeholder={'Add location'}
          label={
            <div className="d-flex justify-content-between">
              <div>WHERE</div>
              <div>
                {shownDays ? (
                  <div className="text-primary d-flex">
                    <strong>{shownDays}</strong>
                    &nbsp;
                    <div className="fs-10 fw-medium text-lowercase me-1">days since arrival</div>
                    <Information
                      message={'Here we count days between Arrival and Case Close Date.'}
                      svgStyle={{ width: 12, height: 12 }}
                      tooltipClassname="min-width-fit max-width-fit"
                      className="text-primary d-flex align-items-center"
                    />
                  </div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>
            </div>
          }
          onChange={e => changeField('arrived_at_location')(e)}
          className="mb-0"
          {...fields?.arrived_at_location}
        />
      </Col>
    </Row>
  );
};

ArrivedAt.propTypes = {
  selectField: PropTypes.func,
  changeField: PropTypes.func,
  fields: PropTypes.object
};

export default ArrivedAt;
