import React, { useEffect, useState } from 'react';
import numeral from 'numeral';

import NumberField from 'captain-reports/templates/inputs/NumberField';

import { Row, Col } from 'reactstrap';

import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import * as reportActions from 'captain-reports/store/actions';
import { numberToStr, strToNumber } from 'common/utils/numbers';

import {
  selectConsumptionFuelGrade,
  selectPreviousCaptainReportBunkerPerFuelGrade
} from 'captain-reports/store/selectors';
import { useSelector } from 'react-redux';
import useAutoCalculateBunkerConsumptionSecondaryRob from 'captain-reports/utils/useAutoCalculateBunkerConsumptionSecondaryRob';

const HfoLfoConsumptionRob = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [robValue, setRobValue] = useState('');

  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const prevBunker = useSelector(state =>
    selectPreviousCaptainReportBunkerPerFuelGrade(state, fuelGrade?.id)
  );

  const secondaryRobValue = useAutoCalculateBunkerConsumptionSecondaryRob({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const supplied = useValue({
    key: 'supplied',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const correctionHfo = useValue({
    key: 'correction_hfo',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const correctionLfo = useValue({
    key: 'correction_lfo',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const value = useValue({
    key: 'total_consumption',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const viscosity = useValue({
    key: 'viscosity',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const suppliedViscosity = useValue({
    key: 'supplied_viscosity',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);

  const isSuppliedHfo = suppliedViscosity > 80;
  const isSuppliedLfo = suppliedViscosity && suppliedViscosity <= 80;

  const isViewOnly = prevBunker?.[field.key]?.value || prevBunker?.[field.key]?.value === 0;
  const isFoGrade = fuelGrade.category === 'fo';
  const isHfo = field.key.includes('hfo');

  useEffect(() => {
    let result = null;
    let prevValue = prevBunker?.[field.key]?.value;

    if ((!prevValue && prevValue !== 0) || !isFoGrade) {
      return;
    }

    const prev = numeral(strToNumber(prevValue || 0));
    result = prev;

    // Only Fo grade fuels have HFO/LFO
    // If viscosity is between 80.0 and 700.0 cSt → HFO
    // if viscosity is less than or equal to 80.0 cSt → LFO

    if (viscosity > 80) {
      if (isHfo) {
        result = prev.subtract(+value || 0);
      }
    } else if (viscosity && viscosity <= 80) {
      if (isHfo) {
        result = prev;
      }
      if (!isHfo) {
        result = prev.subtract(+value || 0);
      }
    }

    // Supplied field use supplied viscosity field to determine which HFO/LFO values to change.
    // Supplied viscosity rules are same with viscosity rules above.

    if (isSuppliedHfo) {
      if (isHfo) {
        result = result.add(+supplied || 0);
      }
    } else if (isSuppliedLfo) {
      if (!isHfo) {
        result = result.add(+supplied || 0);
      }
    }

    result = result.add(isHfo ? +correctionHfo : +correctionLfo || 0);

    if (secondaryRobValue) result = result.add(secondaryRobValue);

    setValue({
      key: field.key,
      value: result?._value,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });

    setRobValue(result?._value);
  }, [value, supplied, isSuppliedLfo, correctionLfo, correctionHfo, viscosity, secondaryRobValue]);

  return (
    <Row className={`align-items-center ${!isFoGrade ? 'd-none' : ''}`}>
      <Col xs={12}>
        {isViewOnly ? (
          <ValidationWrapper
            field={{ key: field.key, label: null }}
            value={value}
            sectionLabel={sectionLabel}
            errorClassName="align-items-end"
            sectionActiveTab={sectionActiveTab}
            subGroup={subGroup}
            subGroupIndex={subGroupIndex}
            right={true}
          >
            <div
              className={`w-100p mt-2 fs-12 fw-bold text-end ${
                robValue && robValue < 0 ? 'text-red' : ''
              }`}
            >
              {numberToStr(robValue)}
            </div>
          </ValidationWrapper>
        ) : (
          <NumberField
            field={field}
            sectionLabel={sectionLabel}
            sectionActiveTab={sectionActiveTab}
            subGroup={subGroup}
            subGroupIndex={subGroupIndex}
          />
        )}
      </Col>
    </Row>
  );
};

export default HfoLfoConsumptionRob;
