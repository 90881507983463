import React from 'react';
import Priority from 'common/components/general/Priority';
import { Department } from 'common/components/general/Departments';

import { components } from 'react-select';

const OptionPreview = ({ data, font }) => {
  return (
    <div className="d-flex align-items-center w-100p">
      <Priority className="cpe-12" value={data?.importance?.label} withText={false} size="small" />
      <div className={`text-truncate fs-${font}`}>
        {data.name}
        {data?.revision && <strong>&nbsp; - {data.revision}</strong>}
      </div>
      <div className="ms-auto me-1">
        <Department value={data?.department} />
      </div>
    </div>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="w-100p">
      {props.data ? <OptionPreview data={props.data} font={14} /> : null}
    </components.Option>
  );
};

export const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue className="w-100p" {...props}>
      {props.data ? <OptionPreview data={props.data} font={12} /> : null}
    </components.SingleValue>
  );
};
