import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';

const Header = ({ icon, title, color = 'violet' }) => {
  return (
    <div className={`d-flex align-items-center text-${color} mb-2`}>
      <SvgRender src={icon} style={{ width: 13, height: 13 }} />

      <div className="fs-12 fw-medium ms-1 ">{title}</div>
    </div>
  );
};

Header.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.oneOf(['violet', 'primary'])
};

export default Header;
