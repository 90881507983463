import React from 'react';

import warningIcon from 'captain-reports/assets/svg/warning-empty.svg';
import SvgRender from 'common/components/general/SvgRender';

import { PopoverHeader, UncontrolledPopover, PopoverBody, Button } from 'reactstrap';

const RestHoursRules = ({ className = '' }) => {
  const popoverId = 'rest-hours-rules';

  return (
    <div className={`rest-hours-rules ${className}`}>
      <Button
        color="link"
        type="button"
        id={popoverId}
        className="d-inline-flex align-items-center text-violet border border-violet"
        size="sm"
      >
        <SvgRender src={warningIcon} style={{ height: 14, width: 14 }} />
        <span className="fw-medium lh-1 ps-1">Rest Hours</span>
      </Button>

      <UncontrolledPopover
        className="rest-hours-rules--popover"
        placement="bottom-end"
        target={popoverId}
        trigger="hover"
        fade={false}
        hideArrow
      >
        <PopoverHeader className="text-primary bg-white fs-12 lh-1 fw-medium px-0 pt-0 pb-1 border-primary">
          Rest Hours
        </PopoverHeader>
        <PopoverBody className="cpt-12">
          <ul className="m-0 p-0 fs-10 text-primary">
            <li>A minimum of 10 hours of rest in any 24-hour period</li>
            <li>A minimum of 77 hours of rest in any 7 day period</li>
            <li>
              The hours of rest may be divided into no more than two periods, one of which shall be
              at least 6 hours in length, and the intervals between consecutive periods of rest
              shall not exceed 14 hours.
            </li>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default RestHoursRules;
