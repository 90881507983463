import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import _isObject from 'lodash/isObject';

import * as d3 from 'd3';

const DEFAULT_OUTER_HOLE_RADIUS = 39;
const DEFAULT_INNER_HOLE_RADIUS = 11;
const DEFAULT_MARGIN = 25;
const DEFAULT_DOUGHNUT_SHADOWS = [
  { x: -9, y: -9, blur: 8, color: 'rgba(255, 255, 255, 0.8)', opacity: 1 },
  { x: 9, y: 9, blur: 8, color: '#e0e4ef', opacity: 1 }
];
const DEFAULT_LINEAR_GRADIENT = [
  { offset: '0%', color: variables.bodyBg },
  { offset: '50%', color: '#EDF0F8' }
];
const DEFAULT_INNER_VALUE_COLOR = variables.primary;
const DEFAULT_INNER_TITLE_COLOR = variables.primary;
const DEFAULT_SPACE_BETWEEN = 6;

const styleDefinitions = {
  doughnutShadows: {
    defaultValue: DEFAULT_DOUGHNUT_SHADOWS
  },
  doughnutGradient: {
    defaultValue: DEFAULT_LINEAR_GRADIENT
  },
  doughnutValueColor: {
    defaultValue: DEFAULT_INNER_VALUE_COLOR
  },
  doughnutTitleColor: {
    defaultValue: DEFAULT_INNER_TITLE_COLOR
  },
  doughnutMargin: {
    defaultValue: DEFAULT_MARGIN
  },
  doughnutOuterHoleRadius: {
    defaultValue: DEFAULT_OUTER_HOLE_RADIUS
  },
  doughnutInnerHoleRadius: {
    defaultValue: DEFAULT_INNER_HOLE_RADIUS
  },
  doughnutSpaceBetween: {
    defaultValue: DEFAULT_SPACE_BETWEEN
  }
};

const renderPlot = ({ plotID, plot, getPlotStyle, element, schema, getDimensions, events }) => {
  const getStyle = styleKey => getPlotStyle(styleKey, styleDefinitions[styleKey].defaultValue);
  const { doughnutMargin, doughnutInnerHoleRadius, doughnutOuterHoleRadius } = schema;

  const { width, height } = getDimensions();

  const drawColors = d3
    .scaleOrdinal()
    .domain(plot.data)
    .range(plot.data.map(dataPoint => dataPoint[schema.colorKey]));

  const pie = d3.pie().value(d => d[schema.valueKey]);
  const pieData = pie(plot.data);

  const OUTER_WIDTH = width;
  const OUTER_HEIGHT = height;
  const INNER_WIDTH = OUTER_WIDTH - (doughnutMargin || getStyle('doughnutMargin'));
  const INNER_HEIGHT = OUTER_HEIGHT - (doughnutMargin || getStyle('doughnutMargin'));

  const INNER_RADIUS = Math.min(INNER_WIDTH, INNER_HEIGHT) / 2;
  const RADIUS = Math.min(OUTER_WIDTH, OUTER_HEIGHT) / 2;

  const arc = d3
    .arc()
    .innerRadius(RADIUS - (doughnutOuterHoleRadius || getStyle('doughnutOuterHoleRadius')))
    .outerRadius(RADIUS);

  const paddingInRadians = 6.28319 - getStyle('doughnutSpaceBetween') / 57.2958;

  const innerArc = d3
    .arc()
    .innerRadius(INNER_RADIUS - (doughnutInnerHoleRadius || getStyle('doughnutInnerHoleRadius')))
    .outerRadius(INNER_RADIUS)
    .padAngle(paddingInRadians);

  // Positioning the values doughnut graph
  element
    .select(`#${plotID}`)
    .attr(
      'transform',
      `translate(${INNER_WIDTH / 2 + (OUTER_WIDTH - INNER_WIDTH) / 2} ${
        INNER_HEIGHT / 2 + (OUTER_HEIGHT - INNER_HEIGHT) / 2
      })`
    );

  // Positioning the gray background
  element.select(`#background_circle_${plotID}`).style('transform', `translate(50%, 50%)`);

  // inner value
  element.select(`#inner_value_${plotID}`).style('fill', getStyle('doughnutValueColor'));

  // The title of the middle
  element
    .select(`#inner_title_${plotID}`)
    .style('fill', getStyle('doughnutTitleColor'))
    .style('fill-opacity', '0.2');

  // Gray background of the doughnut graph
  element
    .selectAll(`#background_circle_${plotID} path`)
    .data([{ startAngle: 0, endAngle: 100 }])
    .attr('width', OUTER_WIDTH)
    .attr('height', OUTER_HEIGHT)
    .attr('d', arc);

  // Values draw
  element
    .selectAll(`.doughnut-slices`)
    .data(pieData)
    .attr('width', INNER_WIDTH)
    .attr('height', INNER_HEIGHT)
    .attr('d', innerArc)
    .attr('fill', d => drawColors(d.data[schema.colorKey]) || 'rgba(0,0,0,0)');

  const dispatchHoveringEvent = dataPoint => {
    // dispatch custom event to document that contains the graph uid in the name
    // and the data point in the detail
    const currentlyHovering = dataPoint
      ? {
          currentElementID: `#slices_${plotID}_${dataPoint?.data.label}`,
          dataPoint: {
            ...dataPoint,
            percentageValue: (
              ((dataPoint.endAngle - dataPoint.startAngle) / (2 * Math.PI)) *
              100
            )?.toFixed(1)
          },
          plot
        }
      : null;

    const event = new CustomEvent(`graph-hovering--${events.eventsID}`, {
      detail: currentlyHovering
    });
    // dispatch the event to the document
    document.dispatchEvent(event);
  };

  const onMouseOver = dataPoint => {
    dispatchHoveringEvent(dataPoint);
  };

  const onMouseOut = dataPoint => {
    dispatchHoveringEvent(null);
  };

  if (events)
    element
      .selectAll('.doughnut-slices')
      .on('mouseover', (_, dataPoint) => onMouseOver(dataPoint))
      .on('mouseout', (_, dataPoint) => onMouseOut(dataPoint));
};

const renderComponent = ({ plotID, plot, getPlotStyle }) => {
  const getStyle = styleKey => getPlotStyle(styleKey, styleDefinitions[styleKey].defaultValue);

  return (
    <>
      <filter id={'shadow_' + plotID} colorInterpolationFilters="sRGB">
        {getStyle('doughnutShadows').map((value, i) => (
          <feDropShadow
            key={i}
            floodColor={value?.color}
            dx={value?.x}
            dy={value?.y}
            stdDeviation={value?.blur}
            floodOpacity={value?.opacity}
          />
        ))}
      </filter>

      <linearGradient id={'gradient_' + plotID} gradientTransform="rotate(100)">
        {getStyle('doughnutGradient').map((value, i) => (
          <stop key={i} offset={value.offset} stopColor={value.color} />
        ))}
      </linearGradient>

      <g id={'background_circle_' + plotID} key={'background_circle'}>
        <path
          key={'background_circle'}
          fill={`url('#gradient_${plotID}')`}
          filter={`url(#shadow_${plotID})`}
        />

        {plot?.innerValue ? (
          <>
            {_isObject(plot.innerValue) ? (
              plot.innerValue
            ) : (
              <text
                x="0"
                y="0"
                id={'inner_value_' + plotID}
                startOffset="50%"
                textAnchor="middle"
                className="fw-medium fs-28 graph-inner-title"
              >
                {plot.innerValue || '-'}
                {plot.isPercentage && plot.innerValue !== '-' ? '%' : ''}
              </text>
            )}

            {_isObject(plot?.graphInnerTitle) ? (
              plot?.graphInnerTitle
            ) : (
              <text
                x="0"
                y="14%"
                id={'inner_title_' + plotID}
                startOffset="50%"
                textAnchor="middle"
                className="fw-black fs-12"
              >
                {plot?.graphInnerTitle}
              </text>
            )}
          </>
        ) : null}
      </g>

      <g id={plotID} key={plotID}>
        {/* We create a <path /> for each data point. */}
        {plot.data.map((e, i) => (
          <path key={i} id={`slices_${plotID}_${e.label}`} className="doughnut-slices" />
        ))}
      </g>
    </>
  );
};

const doughnut = {
  renderPlot,
  renderComponent
};

export default doughnut;
