import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useDrawer } from 'common/components/drawer';
import CircledButton from 'common/components/buttons/CircledButton';
import FormsDrawer from 'common/components/forms/drawer';
import LinkFormFilters from 'common/components/forms/drawer/LinkFormFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventFormSubmissions,
  linkFormSubmissionToEvent
} from 'events/store/event-modules/forms/actions';
import { selectEventId } from 'events/store/events/selectors';
import { selectTableListRequestParams } from 'common/components/table/store/selectors';
import { EVENT_SUBMISSIONS_TABLE_LABEL } from '.';

const LinkForm = () => {
  const [selectedFormSubmission, setSelectedFormSubmission] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const dispatch = useDispatch();
  const drawer = useDrawer();

  const eventId = useSelector(selectEventId);
  const eventSubmissionsTableRequestParams = useSelector(state =>
    selectTableListRequestParams(state, EVENT_SUBMISSIONS_TABLE_LABEL)
  );

  const submissionListParams = useMemo(
    () => ({
      date_from: selectedDates[0] ? selectedDates[0].format('YYYY-MM-DD') : undefined,
      date_to: selectedDates[1] ? selectedDates[1].format('YYYY-MM-DD') : undefined,
      form_uid: selectedForm?.uid ?? undefined,
      status_ids: selectedStatuses?.length ? selectedStatuses : undefined,
      linked_to_event: false,
      from_obligation: false
    }),
    [selectedDates, selectedForm?.uid, selectedStatuses]
  );

  const onClose = () => {
    setSelectedFormSubmission(null);
    setSelectedForm(null);
    setSelectedDates([]);
    setSelectedStatuses([]);
    drawer.close();
  };

  const onLink = async () => {
    if (!selectedFormSubmission?.id) return;

    try {
      await dispatch(
        linkFormSubmissionToEvent({ eventId, form_submission_id: selectedFormSubmission.id })
      ).unwrap();

      dispatch(getEventFormSubmissions({ eventId, ...eventSubmissionsTableRequestParams }));
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <CircledButton
        className="mt-2"
        type="link"
        svgClassName="text-primary"
        svgWrapperClassName="bg-white"
        label="Link an existing form"
        onClick={() => drawer.open()}
      />

      <FormsDrawer
        isOnLinkMode
        css={selectedFormSubmission?.id ? null : style}
        isFormLocked={!selectedFormSubmission?.id}
        drawerType="link_form"
        drawer={drawer}
        initialFormSubmissionID={selectedFormSubmission?.id}
        onSubmitCallback={onLink}
        onDrawerClose={onClose}
        customSelectorComponent={
          <LinkFormFilters
            selectedForm={selectedForm}
            setSelectedForm={setSelectedForm}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            selectedFormSubmission={selectedFormSubmission}
            setSelectedFormSubmission={setSelectedFormSubmission}
            submissionListParams={submissionListParams}
            formsListParams={{ visibility: 'events' }}
          />
        }
      />
    </>
  );
};

export default LinkForm;

const style = css`
  .form-manager--header {
    .shadow-box-wrap {
      :nth-child(2) {
        display: none;
      }
    }
  }
`;
