import { get, put, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TABLE_TYPES from 'common/components/table/store/types';

export const getUnderwaterServicesInfo = createAsyncThunk(
  'GET_UNDERWATER_SERVICES_INFO',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, ...rest } = params;
    try {
      const response = await get(`/events/${event_id}/underwater-services`, rest);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUnderwaterServicesInfo = createAsyncThunk(
  'UPDATE_UNDERWATER_SERVICE_INFO',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, ...rest } = params;
    try {
      const response = await put(`/events/${event_id}/underwater-services`, rest);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUnderwaterServicesInfo = createAsyncThunk(
  'ADD_UNDERWATER_SERVICE_INFO',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, ...rest } = params;
    try {
      const response = await post(`/events/${event_id}/underwater-services`, rest);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//services

export const getUnderwaterServices = createAsyncThunk(
  'GET_UNDERWATER_SERVICES',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, event_id, ...rest } = params;

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: {
          params,
          table
        }
      });

      const response = await get(`/events/${event_id}/underwater-services/values`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: {
          data: response?.data,
          table
        }
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const addUnderwaterService = createAsyncThunk(
  'ADD_UNDERWATER_SERVICE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, ...rest } = params;
    try {
      const response = await post(`/events/${event_id}/underwater-services/values`, rest);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnderwaterService = createAsyncThunk(
  'GET_UNDERWATER_SERVICE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, ...rest } = params;
    try {
      const response = await get(`/events/${event_id}/underwater-services/values`, rest);

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUnderwaterService = createAsyncThunk(
  'UPDATE_UNDERWATER_SERVICE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, underwater_service_id, ...rest } = params;
    try {
      const response = await put(
        `/events/${event_id}/underwater-services/values/${underwater_service_id}`,
        rest
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUnderwaterService = createAsyncThunk(
  'DELETE_UNDERWATER_SERVICE',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { event_id, underwater_service_id, ...rest } = params;
    try {
      const response = await deleteRequest(
        `/events/${event_id}/underwater-services/values/${underwater_service_id}`,
        rest
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
