import { useQuery } from '@tanstack/react-query';
import { VESSEL_PMS_INTEGRATION } from './query-keys';
import { getVesselPmsIntegrationType } from './api';
import { VesselPmsIntegration } from '@/common/types/vessel';

export const useVesselPmsIntegrationTypeQuery = (vesselId?: number) => {
  return useQuery<VesselPmsIntegration>({
    queryKey: [VESSEL_PMS_INTEGRATION, vesselId],
    queryFn: () => getVesselPmsIntegrationType(vesselId),
    enabled: !!vesselId,
    retry: false
  });
};
