import { FC, useCallback, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import CircledButton from '@/ts-common/components/buttons/CircledButton.tsx';
import NumberInput from '@/ts-common/components/form/inputs/NumberInput';
import ShadowBox from '@/common/components/general/ShadowBox';
import DateInput from '@/common/components/form/inputs/date';
import { FormState as FormStateType } from '@/common/types/form';
import {
  selectCurrenciesWithoutBase,
  selectBaseCurrency
} from '@/common/components/mga/store/selectors-ts';
import { dateField, hiddenField, numberField } from '@/common/utils/form/fieldTypes';
import save from '@/common/assets/svg/actions/save.svg';
import { createMgaForexRate, getMgaForexRate } from '@/common/components/mga/store/actions-ts';
import { Currency } from '@/common/types/enums.ts';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useForm, useFormState } from '@/utils/hooks';
import BarLabel from './components/BarLabel';
import BaseToCurrentCurrency from './components/BaseToCurrentCurrency';

const config = {
  from_date: dateField({ required: true }),
  to_date: dateField({ required: true }),
  rates: {
    currency: hiddenField(),
    rate: numberField()
  }
};

export type RateType = {
  currency: Currency;
  rate: number;
};

export type FormConfigType = {
  from_date: string;
  to_date: string;
  rates: RateType[];
};

type RateProps = {
  state: FormStateType;
};

const isDateNotSuitable = (firstDate: string, secondDate: string): boolean => {
  if (!firstDate || !secondDate) return false;

  return moment(firstDate).isAfter(secondDate);
};

const Rate: FC<RateProps> = ({ state }) => {
  const { fields, changeField } = useFormState(state);
  const baseCurrency = useAppSelector(selectBaseCurrency);

  return (
    <Row className="cmb-6">
      <Col xs={6} className="d-flex align-items-center">
        <BaseToCurrentCurrency
          currenciesClassName="fw-medium"
          baseCurrencyLabel={baseCurrency?.label}
          currentCurrencyLabel={fields.currency.value?.label}
        />
      </Col>

      <Col xs={6}>
        <NumberInput
          placeholder="Add value"
          onChange={changeField('rate')}
          className="mb-0"
          decimalScale={4}
          {...fields.rate}
        />
      </Col>
    </Row>
  );
};

const ForexRate = () => {
  const { formState, collectValues, loadValues, resetForm, hasErrors } = useForm(config);
  const { subStates, fields, selectField, setFieldError } = useFormState(formState);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const ratesState = subStates('rates');
  const currencies = useAppSelector(selectCurrenciesWithoutBase);
  const dispatch = useAppDispatch();

  const initForm = useCallback(() => {
    loadValues({ rates: currencies.map(currency => ({ currency, rate: null })) });
  }, [currencies, loadValues]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  if (!currencies?.length) return null;

  const onSave = async () => {
    const values = collectValues();

    if (!values || hasErrors) return;

    try {
      const { rates, from_date, to_date } = values as FormConfigType;

      if (isDateNotSuitable(from_date, to_date)) {
        setFieldError('to_date', 'Should be after From date');
        return;
      }

      setIsSubmitting(true);

      const parsedParams = {
        from_date,
        to_date,
        rates: rates.map(({ rate, currency }) => ({ rate, currency_id: currency?.id }))
      };

      await dispatch(createMgaForexRate(parsedParams)).unwrap();

      resetForm();
      initForm();
      setIsSubmitting(false);

      await dispatch(getMgaForexRate()).unwrap();
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mb-4 cpb-2">
      <BarLabel title="FOREX RATES" />

      <ShadowBox color="light-1" flat className={`d-flex px-4 py-4 position-relative cmb-2`}>
        <Row className="flex-1">
          <Col xs={4}>
            <DateInput
              label={
                <div>
                  <span className="form-label text-primary">PERIOD</span>{' '}
                  <span className="form-label text-violet">FROM</span>
                </div>
              }
              onChange={(date: string) => selectField('from_date')(date)}
              className="mb-0"
              {...fields.from_date}
            />
          </Col>

          <Col xs={4}>
            <DateInput
              label={
                <div>
                  <span className="form-label text-primary">PERIOD</span>{' '}
                  <span className="form-label text-violet">TO</span>
                </div>
              }
              onChange={(date: string) => {
                selectField('to_date')(date);
              }}
              error={
                isDateNotSuitable(fields.from_date.value, fields.to_date.value)
                  ? 'Should be after from date'
                  : ''
              }
              className="mb-0"
              {...fields.to_date}
            />
          </Col>

          <Col className="mt-3" xs={8}>
            <div className="form-label text-primary cmb-4">Forex Rates</div>

            {ratesState.map((state: FormStateType, index: number) => (
              <Rate key={index} state={state} />
            ))}
          </Col>
        </Row>

        <ActionsContainer>
          <CircledButton
            size={16}
            disabled={isSubmitting}
            icon={save}
            svgStyle={{ width: 8, height: 8 }}
            type="send"
            onClick={onSave}
          />
        </ActionsContainer>
      </ShadowBox>
    </div>
  );
};

export default ForexRate;

export const ActionsContainer = styled.div`
  position: absolute;
  right: -0.4rem;
  top: 0.5rem;
`;
