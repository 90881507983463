import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useActions } from 'utils/hooks';
import Slider from 'react-slick';

import Loader from './Loader';
import ArrowNext from 'common/components/news-and-announcements/_components/ArrowNext';
import ArrowPrevious from 'common/components/news-and-announcements/_components/ArrowPrevious';
import NewsBox from 'common/components/news-and-announcements/_components/NewsBox';

import { selectPostCategoryPosts } from 'store/lists/selectors';
import {
  selectPostsWithoutImageIsLoading,
  selectPostsWithoutImageData,
  selectPostsWithoutImageCurrentPage,
  selectPostsWithoutImageLastPage,
  selectPostsWithoutImageLoadMoreLoading
} from 'common/components/news-and-announcements/store/selectors';

import * as postsActions from 'common/components/news-and-announcements/store/actions';

const SLIDES_PER_PAGE = 2;

const PostsWithoutImage = ({ setActivePost }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: SLIDES_PER_PAGE,
    slidesToScroll: 1,
    nextArrow: <ArrowNext className="position-absolute" />,
    prevArrow: <ArrowPrevious className="position-absolute" />,
    afterChange: index => setActiveSlide(index)
  };

  const categoryPost = useSelector(selectPostCategoryPosts);
  const isLoading = useSelector(selectPostsWithoutImageIsLoading);
  const loadMoreLoading = useSelector(selectPostsWithoutImageLoadMoreLoading);
  const data = useSelector(selectPostsWithoutImageData);
  const currentPage = useSelector(selectPostsWithoutImageCurrentPage);
  const totalPages = useSelector(selectPostsWithoutImageLastPage);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const [getPosts] = useActions([postsActions.getPosts]);

  useEffect(() => {
    if (loadMoreLoading || currentPage === totalPages) return;

    const currentPageIndex = Math.floor(activeSlide / SLIDES_PER_PAGE);

    // Calculate the last index of the current page.
    const lastSlideIndex = (currentPageIndex + 1) * SLIDES_PER_PAGE - 1;

    // Check if the current slide is the last slide of the current page.
    if (activeSlide === lastSlideIndex) {
      const params = {
        paging: {
          per_page: 5,
          current_page: currentPage + 1
        },
        filters: [
          { name: 'category_id', operation: 'oneOf', value: [categoryPost?.id] },
          { name: 'has_image', operation: '=', value: false }
        ]
      };

      if (categoryPost?.id) getPosts({ params, key: 'postsWithoutImage' });

      // Update the slider position to prevent multiple fetch requests.
      setActiveSlide(lastSlideIndex + 1);
    }
  }, [activeSlide, getPosts, categoryPost, totalPages, currentPage, loadMoreLoading]);

  return isLoading ? (
    <Loader />
  ) : (
    <div
      className={`w-100p overflow-hidden position-relative ${
        !isOnBoard ? 'text-dark' : 'text-primary'
      }`}
    >
      <Slider {...sliderSettings}>
        {(data || [])?.map((post, i) => (
          <NewsBox
            key={i}
            size="lg"
            post={post}
            type="posts-without-image"
            onClick={() => setActivePost(post)}
          />
        ))}
      </Slider>
    </div>
  );
};

PostsWithoutImage.propTypes = {
  setActivePost: PropTypes.func.isRequired
};

export default PostsWithoutImage;
