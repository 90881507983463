import TimeLogger from 'common/components/form/inputs/TimeLogger';

const TimeInput = ({ value, onChange, disabled, error }) => {
  return (
    <TimeLogger
      value={value}
      onChange={minutes => onChange(minutes)}
      error={error}
      disabled={disabled}
    />
  );
};

export default TimeInput;
