import AUTH_TYPES from 'store/auth/types';

import Cookie from 'utils/cookie';

// const jwt_decode = require('jwt-decode');

const authMiddleware = store => next => ({ type, payload, ...rest }) => {
  switch (type) {
    case AUTH_TYPES.CREDENTIALS_LOGIN.SUCCESS:
      const timestamp = new Date().getTime();
      Cookie.set('accessToken', payload.token, 0);
      Cookie.set('activeSessionTimestamp', `${timestamp}`, 0);

      // window.localStorage.setItem('refreshToken', payload.refresh_token);
      // window.localStorage.setItem('tokenExpiresAt', jwt_decode(payload.token).exp);
      break;

    // case AUTH_TYPES.REFRESH_TOKEN.SUCCESS:
    //   Cookie.set('accessToken', payload.token);
    //   // window.localStorage.setItem('refreshToken', payload.refresh_token);
    //   // window.localStorage.setItem('tokenExpiresAt', jwt_decode(payload.token).exp);

    //   break;

    case AUTH_TYPES.LOGOUT.SUCCESS:
    case AUTH_TYPES.LOGOUT.ERROR:
      Cookie.erase('accessToken');
      Cookie.erase('activeSessionTimestamp');
      // Cookie.erase('directive_id');
      // window.localStorage.removeItem('refreshToken');
      // window.localStorage.removeItem('tokenExpiresAt');

      break;

    default:
      break;
  }

  next({ type, payload, ...rest });
};

export default authMiddleware;
