import React from 'react';

const ReportType = ({ template }) => {
  const name = template
    ? `${template.code || ''}${template.code && template.title ? ' - ' : ''}${template.title || ''}`
    : '';

  return template && name !== '' ? (
    <div className="d-flex align-items-center mb-5">
      <span className="fw-medium fs-12 text-violet lh-15 mx-1">Evaluation template:</span>
      <span className="fw-normal fs-12 text-violet lh-15 me-6">{name}</span>
    </div>
  ) : null;
};

export default ReportType;
