import React from 'react';
import { v4 as uuid } from 'uuid';

import { minutesToFormat } from 'common/utils/dates';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import CheckboxWithTime from 'common/components/general/CheckboxWithTime';

const MAX_DIGITS = 5; // hh:mm number of digits including ':'

const TimeLogger = ({ className = '', value, error, onChange = () => {}, ...restProps }) => {
  const { avoidRender, tooltipID } = useTooltipID(`time-logger-error-${uuid()}`);

  const onTimeChange = time => {
    try {
      if (!time.length) {
        onChange(null);
      }

      if (time.length === MAX_DIGITS) {
        const [hours, minutes] = time.split(':');
        const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

        onChange(totalMinutes);
      }
    } catch {
      onChange(null);
    }
  };

  if (avoidRender) return null;

  return (
    <div className={`time-logger d-flex align-items-center ${className}`} id={tooltipID}>
      <CheckboxWithTime
        checkboxValue
        alwaysShowTime
        hasMaxTime={false}
        time={minutesToFormat(value, 'hh:mm')}
        onTimeChange={onTimeChange}
        className="position-relative d-flex align-items-center"
        hasError={error}
        {...restProps}
      />
    </div>
  );
};

export default TimeLogger;
