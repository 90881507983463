import { createSelector } from 'reselect';
import { initTopFiltersState } from 'common/utils/filters/helpers';

const selectTablesReducer = state => state.tables;
const selectTableListsReducer = state => state.tables.lists;
const selectTableFiltersReducer = state => state.tables.listsFilters;
const selectTableSearchReducer = state => state.tables.listsSearch;

const getLabel = (_, label) => label;
const getRowIndex = (_, __, rowIndex) => rowIndex;
const getRowId = (_, __, rowId) => rowId;
const getColumnKey = (_, __, ___, columnKey) => columnKey;
const getTopFiltersConfig = (_, __, config) => config;

// Tables
export const selectTableList = createSelector(
  [selectTableListsReducer, getLabel],
  (lists, label) => lists[label]
);

export const selectTableListInvalid = createSelector([selectTableList], list => list?.invalid);

export const selectTableListSorting = createSelector(
  [selectTableList],
  list => list?.sorting || {}
);

export const selectTableListPaging = createSelector([selectTableList], list => list?.paging || {});

export const selectTableListHasPendingRequest = createSelector(
  [selectTableList],
  list => list?.pendingRequest
);

export const selectTableListData = createSelector([selectTableList], list => list?.data || []);

export const selectTableListDataLength = createSelector(
  [selectTableListData],
  data => data?.length || 0
);

export const selectTableListIsEmpty = createSelector(
  [selectTableListData, selectTableListHasPendingRequest],
  (data, pendingRequest) => (pendingRequest ? false : !data?.length)
);

export const selectTableListDataIds = createSelector(
  selectTableListData,
  data => data?.map(item => item?.id) || []
);

export const selectTableListColumns = createSelector(selectTableList, table => table?.columns);

export const selectTableListPercentages = createSelector(
  selectTableList,
  table => table?.percentages
);

export const selectTableListTotals = createSelector([selectTableList], list => list?.totals || {});

export const selectTableListBasicState = createSelector(
  [selectTableListSorting, selectTableListPaging, selectTableListData, selectTableListTotals],
  (sorting, paging, data, totals) => {
    const state = { data, sorting, paging, totals };

    return state;
  }
);

export const selectTableListWarnings = createSelector([selectTableList], list => list?.warnings);

export const selectTableListHoveredColumn = createSelector(
  [selectTableList],
  list => list?.hoveredColumn
);

export const selectTableRowWarnings = createSelector(
  [selectTableListWarnings, getRowIndex],
  (warnings, rowIndex) => warnings?.[rowIndex] || null
);

export const selectTableRowCellWarning = createSelector(
  [selectTableRowWarnings, getColumnKey],
  (rowWarnings, columnKey) => rowWarnings?.[columnKey] || null
);

export const selectTableHoveredColumnKey = createSelector(
  [selectTableListHoveredColumn],
  hoveredColumn => hoveredColumn || null
);

export const selectTableListRequestParams = (state, key) =>
  selectTableList(state, key)?.requestParams || {};

// Selected table rows
export const selectTableListSelectedRows = createSelector(
  [selectTableList],
  list => list?.selectedRows || []
);

export const isTableListRowSelected = createSelector(
  [selectTableListSelectedRows, getRowId],
  (selectedRows, rowId) => selectedRows.findIndex(r => r.id === rowId) !== -1
);

export const selectTableListSelectedRowsCount = createSelector(
  [selectTableListSelectedRows],
  selectedRows => selectedRows?.length
);

// Filters
export const selectTableListFilters = createSelector(
  [selectTableFiltersReducer, getLabel],
  (listsFilters, label) => listsFilters[label]
);

export const selectTableFiltersOpen = createSelector([selectTableListFilters], list_filters =>
  list_filters && list_filters.isFiltersOpen !== undefined ? list_filters.isFiltersOpen : false
);

export const selectAppliedTableFilters = createSelector(
  [selectTableListFilters],
  list_filters => list_filters && list_filters.applied
);

export const selectShouldRefetchTableFiltersOnApply = createSelector(
  [selectTableListFilters],
  list_filters => list_filters && list_filters.shouldRefetchTable
);

export const selectTableFilters = createSelector([selectTableListFilters], list_filters =>
  list_filters && list_filters.filters !== undefined ? list_filters.filters : []
);

export const selectTableTopFilters = createSelector(
  [selectTableListRequestParams, getTopFiltersConfig],
  (requestParams, top) => (top?.filters?.length ? initTopFiltersState(requestParams, top) : [])
);

// Page Search
export const selectTableListPageSearch = createSelector(
  [selectTableSearchReducer, getLabel],
  (listsSearch, label) => listsSearch[label]
);

// Tables Location
export const selectTablesPreviousLocation = createSelector(
  [selectTablesReducer],
  tables => tables.previousLocation
);

export const selectTablesPreviousLocationPath = createSelector(
  [selectTablesPreviousLocation],
  previousLocation => previousLocation.path
);

export const selectTablesPreviousLocationSearch = createSelector(
  [selectTablesPreviousLocation],
  previousLocation => previousLocation.search
);

export const selectTablesCurrentLocation = createSelector(
  [selectTablesReducer],
  tables => tables.location
);

export const selectTablesCurrentLocationPath = createSelector(
  [selectTablesCurrentLocation],
  currentLocation => currentLocation.path
);

export const selectTablesCurrentLocationNavigatorAction = createSelector(
  [selectTablesCurrentLocation],
  currentLocation => currentLocation.action
);

export const selectTablesCurrentLocationSearch = createSelector(
  [selectTablesCurrentLocation],
  currentLocation => currentLocation.search
);
