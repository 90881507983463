import { useCallback } from 'react';
import SvgRender from 'common/components/general/SvgRender';
import { Button } from 'reactstrap';
import { useActions } from 'utils/hooks';
import { canEditPeriodActions } from 'common/components/mga/store/selectors';
import { useSelector } from 'react-redux';

import * as mgaActions from 'common/components/mga/store/actions';

import edit from 'common/assets/svg/actions/edit-empty.svg';
import deleteSvg from 'common/assets/svg/actions/delete.svg';

const ActionTableButtons = ({ action }) => {
  const [setMgaActionForm] = useActions([mgaActions.setMgaActionForm]);
  const canEdit = useSelector(canEditPeriodActions);

  const editAction = useCallback(active => setMgaActionForm({ isOpen: true, active }), []);

  const toggleDeleteModal = useCallback(
    (isOpen, active) => setMgaActionForm({ isModalOpen: isOpen, active }),
    []
  );

  if (!canEdit) return null;

  return (
    <div className="d-flex justify-content-center w-100p">
      <Button
        color="link"
        type="button"
        className="p-0 d-flex align-items-center"
        onClick={() => editAction(action)}
      >
        <SvgRender src={edit} style={{ width: 14, height: 14 }} />
      </Button>
      <Button
        onClick={() => toggleDeleteModal(true, action)}
        color="link"
        type="button"
        className="ms-2 p-0 d-flex align-items-center"
      >
        <SvgRender src={deleteSvg} style={{ width: 14, height: 14 }} />
      </Button>
    </div>
  );
};

export default ActionTableButtons;
