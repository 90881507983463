import Preview from './Preview';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow.svg';

import _orderBy from 'lodash/orderBy';
import _flatten from 'lodash/flatten';

import moment from 'moment';

const From = ({ routes }) => {
  const ordered = _orderBy(routes, ['departure'], ['asc']);
  const route = ordered[0];

  return (
    <div className="d-inline-flex align-items-center text-violet">
      <strong className="text-primary fs-12 lh-1">
        {route.from_airport ? route.from_airport.iata_code : route.from}
      </strong>
      <span className="seperator-violet height-12"></span>
      <strong className="fs-12 lh-1">{moment(route.departure).format('DD MMM YYYY')}</strong>
    </div>
  );
};

const To = ({ routes }) => {
  const ordered = _orderBy(routes, ['arrival'], ['desc']);
  const route = ordered[0];

  return (
    <div className="d-inline-flex align-items-center text-violet">
      <strong className="text-primary fs-12 lh-1">
        {route.to_airport ? route.to_airport.iata_code : route.to}
      </strong>
      <span className="seperator-violet height-12"></span>
      <strong className="fs-12 lh-1">{moment(route.arrival).format('DD MMM YYYY')}</strong>
    </div>
  );
};

const Tickets = ({ className = '', tickets }) => {
  const orderedTickets = _orderBy(tickets, ['routes[0].departure'], ['asc']);
  const routes = _flatten(tickets.map(t => t.routes));

  return (
    <div className={`crew-profile__contracts-tickets ${className}`}>
      <div className="crew-profile__contracts-tickets-header d-flex align-items-center justify-content-between">
        <strong className="text-purple fs-12 ">TRAVEL</strong>
        <div className="d-flex align-items-center ms-2">
          <From routes={routes} />
          <SvgRender src={arrow} style={{ width: 19, height: 8 }} className="mx-1 text-primary" />
          <To routes={routes} />
        </div>
      </div>

      {orderedTickets.map(ticket => (
        <Preview ticket={ticket} key={ticket.id} />
      ))}
    </div>
  );
};

export default Tickets;
