import { numberToStr } from 'common/utils/numbers';

export const showInstructedComparisonOperatorSymbol = (value1, value2) => {
  if (value1 > value2) {
    return '>';
  }

  if (value1 < value2) {
    return '<';
  }

  if (value1 === value2) {
    return '=';
  }

  return '';
};

export const displayInstructedValue = (value, comparedTo, color = '', decimalScale) => (
  <>
    {decimalScale ? numberToStr(value, decimalScale, decimalScale) : numberToStr(value, 1)}
    <span className={color || ''}>
      {comparedTo
        ? ` (${showInstructedComparisonOperatorSymbol(value, comparedTo)} ${numberToStr(
            comparedTo,
            2,
            2
          )})`
        : null}
    </span>
  </>
);

export const selectRob = (rob, viscosity, fuelGradeCategory) => {
  if (fuelGradeCategory === 'go') return null;

  if (viscosity <= 80) {
    return numberToStr(rob?.lfo);
  } else if (viscosity > 80) {
    return numberToStr(rob?.hfo);
  } else {
    return null;
  }
};
