import React from 'react';

const EmptyStateLoaders = ({ isEmpty }) => {
  return isEmpty ? (
    <>
      <div className="job-reminders__empty-dots">
        <span />
        <span />
        <span />
      </div>
      <span className="job-reminders__empty-text">This job has no activity data</span>
    </>
  ) : null;
};

export default EmptyStateLoaders;
