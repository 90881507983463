import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { isDateToday } from 'crew/utils/helpers';

import SvgRender from 'common/components/general/SvgRender';

import { getTimezoneHoursDiff } from 'common/utils/dates';
import arrowLeft from 'common/assets/svg/common/arrows/bordered-arrow-left.svg';
import arrowRight from 'common/assets/svg/common/arrows/bordered-arrow-right.svg';
import clock from 'common/assets/svg/common/clock.svg';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import holidays from 'common/assets/svg/crew/holidays-circle.svg';

const DateLabel = ({ date, isHoliday, timezoneDifference }) => {
  const { avoidRender, tooltipID } = useTooltipID('date-label');
  const { tooltipID: holidayTooltipId } = useTooltipID('holiday-label');

  const isSameTimezone = timezoneDifference === 0;
  const timeDiffColorClass =
    timezoneDifference > 0 ? 'text-violet' : timezoneDifference < 0 ? 'text-orange' : '';

  if (avoidRender) return null;

  return (
    <div className="shifts-table--row-date d-flex flex-column align-items-start">
      <div className="d-flex align-items-center">
        <div
          className={`fw-bold fs-12 lh-15 ${isDateToday(date) ? 'text-violet' : 'text-primary'} ${
            isHoliday ? 'text-warning' : ''
          }`}
        >
          {moment(date).format('ddd DD.MM')}{' '}
        </div>

        {isHoliday ? (
          <>
            <div id={holidayTooltipId} className="d-flex align-items-center text-warning">
              <SvgRender src={holidays} className="cms-6" style={{ width: 14, height: 14 }} />
            </div>
            <Tooltip target={holidayTooltipId}>Holiday</Tooltip>
          </>
        ) : null}

        {isSameTimezone ? null : (
          <>
            <div id={tooltipID} className="d-flex align-items-center">
              <SvgRender
                src={
                  timezoneDifference === 0 ? clock : timezoneDifference > 0 ? arrowRight : arrowLeft
                }
                className={`cms-4 ${timeDiffColorClass}`}
                style={{ width: 11, height: 11 }}
              />
            </div>
            <Tooltip target={tooltipID}>Vessel changed timezone</Tooltip>
          </>
        )}
      </div>

      <div>
        {isSameTimezone ? null : (
          <div className={`d-flex align-items-center fs-10 fw-medium lh-1 ${timeDiffColorClass}`}>
            {getTimezoneHoursDiff(timezoneDifference)}
            {`${timezoneDifference > 0 ? ' Advanced' : ' Retarted'}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default DateLabel;

DateLabel.propTypes = {
  date: PropTypes.instanceOf(moment),
  isHoliday: PropTypes.bool,
  timezoneDifference: PropTypes.number
};
