import React from 'react';
import UploadFiles from 'common/components/form/inputs/upload-files';
import SvgRender from 'common/components/general/SvgRender';

import downloadIcon from 'common/assets/svg/actions/download.svg';

import * as formActions from 'store/forms/actions';
import { useActions } from 'utils/hooks';

const Form = ({
  uploadedFile,
  originalFile,
  setUploadedFile,
  uploadedFileError,
  setUploadedFileError,
  form
}) => {
  const [downloadFormFile] = useActions([formActions.downloadFormFile]);

  const onDownload = async (type = 'uploaded') => {
    const file = { id: null, name: null };

    if (type === 'original') {
      file.id = originalFile.id;
      file.name = originalFile.filename;
    } else {
      file.id = uploadedFile?.id || form?.file_id || null;
      file.name = uploadedFile?.filename || form.file?.filename || null;
    }

    const response = await downloadFormFile({ id: file.id });

    if (response && response.data) {
      let contentType = response.headers['content-type'];

      const blob = new Blob([response.data], { type: contentType });
      const name = file.name;

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, name);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';

        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = name;

        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    }
  };

  return (
    <div className="upload-form-input">
      <div className={`d-flex align-items-center mt-1 ${!uploadedFile ? ' mb-1' : ''}`}>
        <div
          onClick={() => (originalFile ? onDownload('original') : {})}
          className={`d-flex align-items-center justify-content-center download-btn ${
            uploadedFile ? 'mb-1' : ''
          }`}
        >
          <SvgRender src={downloadIcon} style={{ width: 16, height: 16 }} />
          <div className="cms-14 fs-12 fw-medium">Download original</div>
        </div>
        <div className="upload-divider" />
        <UploadFiles
          uploadText="Upload edited form"
          group="forms.submissions"
          files={uploadedFile ? [uploadedFile] : []}
          onChange={files => {
            setUploadedFileError(false);
            setUploadedFile(files && files.length ? files[0] : null);
          }}
          singleUpload={true}
          error={uploadedFileError}
          size="lg"
        />
      </div>
    </div>
  );
};

export default Form;
