import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import PropTypes from 'prop-types';

const ActionButton = ({ icon, children, className = '', ...rest }) => (
  <Button
    className={`d-inline-flex align-items-center lh-15 ${className}`}
    type="button"
    size="sm"
    {...rest}
  >
    {icon && <SvgRender src={icon} style={{ width: 10, height: 10 }} />}
    <strong className="ms-1 fw-medium">{children}</strong>
  </Button>
);

ActionButton.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

export default ActionButton;
