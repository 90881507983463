import React, { useEffect } from 'react';

import { useActions } from 'utils/hooks';
import * as eventActions from 'events/store/events/actions';
import { selectVesselNameAndId } from 'store/vessels/selectors';
import SingleEvent from 'events/event';
import { useSelector } from 'react-redux';

const Event = () => {
  const [setIsOnBoard] = useActions([eventActions.setIsOnBoard]);
  const vessel = useSelector(selectVesselNameAndId);

  useEffect(() => {
    setIsOnBoard(true);
  }, []);

  return <SingleEvent vessel={vessel} />;
};

export default Event;
