import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';
import evaluationReasonsIcon from 'common/assets/svg/common/evaluation-reasons.svg';

import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';

const ReasonView = ({ reason, evaluator, type }) => {
  if (!reason) return null;

  return (
    <div className="rounded-lg d-flex align-items-center fs-12 lh-15 mt-3 bg-light-gray-2 py-1 cps-12">
      <SvgRender
        src={evaluationReasonsIcon}
        className={`text-violet`}
        style={{ width: 15, height: 15 }}
      />
      <span className="ps-1 text-violet">EVALUATION REASON</span>
      <span className="ps-1 text-violet fw-bold ">{_get(reason, 'name')}</span>

      {evaluator ? (
        <div className="me-1 ms-auto">
          <EvaluatorDepartment evaluator={evaluator} evaluatorType={type} />
        </div>
      ) : null}
    </div>
  );
};

export default ReasonView;
