import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import logo from 'assets/svg/logos/orca-onboard.svg';

import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';

const DarkContainer = ({ children, className }) => {
  const { history } = useRouter();

  return (
    <div className={`dark-container ${className || ''}`}>
      <div className="dark-container__logo">
        <SvgRender
          onClick={() => history.push(paths.landing)}
          className="pointer"
          src={logo}
          style={{ width: 180, height: 76 }}
        />
      </div>

      <div className="dark-container__inner">{children}</div>
    </div>
  );
};

export default DarkContainer;
