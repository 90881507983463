import { useSelector } from 'react-redux';
import { selectPostForms, selectCurrentVersion } from 'manuals/store/selectors';

import { Row, Col } from 'reactstrap';
import FormHeaderPreview from 'common/components/forms/_components/FormHeaderPreview';

const Forms = () => {
  const forms = useSelector(selectPostForms);

  const currentVersion = useSelector(selectCurrentVersion);

  const isWorkingVersion = currentVersion.status === 'working';

  return (
    <div className="chapter-content__preview-uploads">
      <div className="fs-12 text-violet fw-medium cmb-6">Digital</div>
      {forms.length ? (
        forms.map((form, index) => (
          <Row
            key={form.uid}
            className={`chapter-content__preview-uploads-form${
              form.deleted ? ' form-deleted' : ''
            }${form.is_new && isWorkingVersion ? ' form-new' : ''}`}
          >
            <Col>
              <FormHeaderPreview form={form.form} hideEmptySubmissionMessage />
            </Col>
          </Row>
        ))
      ) : (
        <div className="fs-12 text-violet fw-medium mt-2">-</div>
      )}
    </div>
  );
};

export default Forms;
