import paths from 'routing/routes/_paths';
import Event from 'views/events/event';
import Events from 'views/events';

import { selectEventName } from 'events/store/events/selectors';
import permissions from 'common/utils/permissions/constants';

const routes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: paths.events,
    component: Events,
    permissions: [permissions.onboard.events.view],
    documentTitle: 'Events'
  },
  {
    path: `${paths.events}/create`,
    component: Event,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.events.view],
    documentTitle: 'Create Event'
  },
  {
    path: `${paths.events}/:id`,
    component: Event,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.events.view],
    documentTitle: selectEventName
  }
];

export default routes;
