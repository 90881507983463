import React from 'react';
import Fade from 'react-reveal/Fade';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectCrewProfile } from 'crew/store/profile/selectors';

import ShadowBox from 'common/components/general/ShadowBox';
import moment from 'moment';

const AddressFormItem = ({ label, value }) => {
  return (
    <Row className={`mb-1 default-form-layout__row ps-2`}>
      <Col xs={4} className="text-end">
        <strong className="text-end text-blue fs-12 ">{label}</strong>
      </Col>
      <Col xs={7} className={`fs-12 d-flex align-items-center default-form-layout__row-input`}>
        {value}
      </Col>
    </Row>
  );
};

const PhoneDetails = ({ phone }) => {
  if (!phone) return null;

  const { type, phone: phoneNumber } = phone;

  return (
    <>
      {type ? (
        <>
          {type}&nbsp;-&nbsp;{phoneNumber}
        </>
      ) : (
        phoneNumber
      )}
    </>
  );
};

const ContactInfo = () => {
  const crew = useSelector(selectCrewProfile);

  return (
    <Fade>
      <div className="profile-data-box ">
        <div className="profile-data-box--wrap flex-1">
          <div className="profile-data-box--group ">
            <Row className="profile-data-row  align-items-center">
              <Col xs={4} className="profile-data-row__left text-end ">
                <strong className="text-end text-table-color fs-12 ">EMAILS</strong>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs={4} className="profile-data-row__left text-end ">
                <strong className="text-end text-blue fs-12 ">PRIMARY</strong>
              </Col>
              <Col className="profile-data-row__right fs-12 fw-normal">{crew?.email || '-'}</Col>
            </Row>

            {crew?.emails?.length
              ? crew.emails?.map(e =>
                  e?.value ? (
                    <Row className="align-items-center">
                      <Col xs={4} className="profile-data-row__left text-end ">
                        <strong className="text-end text-blue fs-12 text-uppercase">
                          {e?.type}
                        </strong>
                      </Col>
                      <Col className="profile-data-row__right fs-12 fw-normal">
                        {e?.value || '-'}
                      </Col>
                    </Row>
                  ) : null
                )
              : null}

            <Row className={`align-items-center mt-3 ${crew?.phones?.length === 1 ? 'mb-3' : ''}`}>
              <Col xs={4} className="profile-data-row__left text-end ">
                <strong className="text-end text-blue fs-12 ">PHONE</strong>
              </Col>
              <Col className="profile-data-row__right fs-12 fw-normal">
                <PhoneDetails phone={crew?.phones[0]} />
              </Col>
            </Row>

            {crew?.phones?.length > 1
              ? crew.phones?.map((c, i) =>
                  i > 0 ? (
                    <Row
                      className={`align-items-center ${
                        crew?.phones?.length - 1 === i ? 'mb-3' : ''
                      }`}
                    >
                      <Col xs={4} className="profile-data-row__left text-end "></Col>
                      <Col className="profile-data-row__right fs-12 fw-normal">
                        <PhoneDetails phone={c} />
                      </Col>
                    </Row>
                  ) : null
                )
              : null}

            {crew?.addresses?.length
              ? crew.addresses.map(a => (
                  <Col xs={11}>
                    <ShadowBox color="light-4" className="py-1 px-2 mb-1" flat>
                      <Row>
                        <Col
                          xs="auto"
                          className={
                            'd-flex align-items-center fs-12 bg-primary text-white px-2 rounded height-20 '
                          }
                        >
                          ADDRESS&nbsp;|&nbsp;<strong>{a?.description || 'MAIN'}</strong>
                        </Col>
                      </Row>

                      <div className="mt-2">
                        <AddressFormItem label={'ADDRESS'} value={a.address || '-'} />
                        <AddressFormItem
                          label={'VALID'}
                          value={
                            <>
                              <strong className="pe-1">From</strong>
                              <span>
                                {a?.valid_from ? moment(a?.valid_from).format('DD/MM/YYYY') : '-'}
                              </span>
                              <strong className="px-1">To</strong>
                              <span>
                                {a?.valid_to ? moment(a?.valid_to).format('DD/MM/YYYY') : '-'}
                              </span>
                            </>
                          }
                        />
                        <AddressFormItem label={'CITY'} value={a.city || '-'} />
                        <AddressFormItem label={'ZIP CODE'} value={a.zip || '-'} />
                        <AddressFormItem label={'COUNTRY'} value={a?.country?.name || '-'} />
                      </div>
                    </ShadowBox>
                  </Col>
                ))
              : null}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default ContactInfo;
