import React from 'react';

import loader from 'common/assets/svg/common/loader.svg';

const PageLoader = ({ isLoading, className = '' }) => {
  return isLoading ? (
    <div className={`page-loader ${className}`}>
      <img alt="page-loader" src={loader} width={48} height={48} />
    </div>
  ) : null;
};

export default PageLoader;
