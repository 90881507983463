import React, { useState } from 'react';
import { Selectors } from 'common/entities/selectors/SelectorTypes';
import Select from 'common/components/form/inputs/Select';

import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';

import useFilterSelector from 'common/components/filters/useFilterSelector';

const EventAuditReasonsSelector = ({
  filter,
  value,
  onChange,
  isMulti,
  autoFocus = true,
  styled,
  wrapperClassName = '',
  defaultOptionsTriggerChange,
  listParams,
  ...rest
}) => {
  const [selectedReason, setSelectedReason] = useState([]);

  useFilterSelector(
    value || filter?.value,
    { list: 'event-audits-reasons' },
    { selected: selectedReason, setSelected: setSelectedReason }
  );

  return (
    <div className={`${wrapperClassName}`}>
      <Select
        autoFocus={autoFocus}
        className="mb-0"
        placeholder="Select reason"
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        isAsync
        isMulti={isMulti}
        size="sm"
        value={
          value !== undefined
            ? value
            : filter?.value
            ? selectedReason.filter(el => filter?.value.map(Number).includes(el.id))
            : []
        }
        defaultOptions={() =>
          getInitialAsyncValues('event-audits-reasons', null, null, {
            defaultOptionsTriggerChange,
            ...listParams
          })
        }
        loadOptions={search => getAsyncOptions(search, 'event-audits-reasons')}
        onChange={selected => {
          setSelectedReason(isMulti ? selected : [selected]);
          onChange(
            value !== undefined
              ? selected
              : {
                  value: selected ? (isMulti ? selected.map(s => s.id) : selected) : ''
                }
          );
        }}
        defaultOptionsTriggerChange={defaultOptionsTriggerChange}
        {...rest}
      />
    </div>
  );
};

EventAuditReasonsSelector.propTypes = Selectors;

export default EventAuditReasonsSelector;
