import { FC } from 'react';

type BarLabelProps = {
  title: string;
};

const BarLabel: FC<BarLabelProps> = ({ title }) => {
  return (
    <div className="bg-primary cpx-12 text-white border-radius-base fw-bold fs-12 mb-1">
      {title}
    </div>
  );
};

export default BarLabel;
