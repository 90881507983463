import { selectIsCreate, selectIsTemplate } from 'events/store/events/selectors';

const selectJobs = state => state.eventModules.jobs;

const selectIsOnboard = state => state.isOnBoard;

export const selectJobData = state => selectJobs(state).data;
export const selectJobLoading = state => selectJobs(state).loading;
export const selectJobEditing = state => selectJobs(state).editing;

export const selectJobsActionsAreLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);
  const isTemplate = selectIsTemplate(state);

  if (isOnBoard || isCreate || isTemplate) return true;

  return false;
};
