import Filters from 'common/components/filters';
import Columns from './columns';
import { useSelector } from 'react-redux';
import useActions from 'common/utils/hooks/useActions';
import * as tableListActions from 'common/components/table/store/actions';
import { selectTableFiltersOpen } from 'common/components/table/store/selectors';
import { commonExcludedFilters } from 'common/utils/filters/helpers';

import _isFunction from 'lodash/isFunction';

const TableHeader = ({
  columns = [],
  filters = [],
  setFilters,
  excludedFilters = [],
  topFilters = [],
  state,
  label,
  customColumnsReorder,
  customText,
  hideTableReorder = false,
  hideTableSearch = false,
  handleTableSearch,
  handleTableApplyFilters,
  grayFiltersBg
}) => {
  const [toggleTableFilters, applyTableFilters] = useActions([
    tableListActions.toggleTableFilters,
    tableListActions.applyTableFilters
  ]);
  const isOpen = useSelector(state => selectTableFiltersOpen(state, label));

  const tableExcludedFilters = excludedFilters
    ? [...commonExcludedFilters, ...excludedFilters]
    : [...commonExcludedFilters];

  const onApply = applied => {
    const shouldRefetchTable = _isFunction(handleTableApplyFilters);

    if (shouldRefetchTable && applied) {
      handleTableApplyFilters(applied, label);
    }

    applyTableFilters(applied, label, !shouldRefetchTable);
  };

  const onToggle = open => toggleTableFilters(open, label);

  return (
    <Filters
      label={label}
      columns={columns.filter(c => c.canFilter !== false)}
      selected={filters}
      onChange={setFilters}
      onApply={onApply}
      onToggle={onToggle}
      isOpen={isOpen}
      customText={customText}
      excludedFilters={[...tableExcludedFilters, ...topFilters.map(t => t.name)]}
      extraFilters={
        hideTableReorder ? null : customColumnsReorder ? (
          customColumnsReorder
        ) : (
          <Columns columns={columns} state={state} />
        )
      }
      hideTableSearch={hideTableSearch}
      handleTableSearch={handleTableSearch}
      grayBg={grayFiltersBg}
    />
  );
};

export default TableHeader;
