import { MgaInventoryStoreItem } from '@/common/types/mga';
import { StoreItem } from '@/common/types/purchasing';
import { numberToStr } from '@/ts-common/utils/numbers';
import { FC, PropsWithChildren } from 'react';
import { components, OptionProps } from 'react-select';
import ColorViewer from '@/common/components/general/ColorViewer';

type InventoryStoreOptionProps = OptionProps<MgaInventoryStoreItem | StoreItem, boolean>;

const InventoryStoreOption: FC<PropsWithChildren<InventoryStoreOptionProps>> = ({
  children,
  ...props
}) => {
  if ('store' in props.data && props.data.store) {
    const item = props.data as MgaInventoryStoreItem;

    return (
      <components.Option {...props}>
        <div className="d-flex w-100p">
          <strong className="cpe-6">{item.store?.code ?? ''}</strong>
          {item.store?.description ?? '-'} | {item.remaining_quantity} @ $
          {numberToStr(item.price_per_unit, 2, 2)}{' '}
        </div>
      </components.Option>
    );
  } else {
    const item = props.data as StoreItem;

    return (
      <components.Option {...props}>
        <div className="d-flex w-100p">
          {item.code ? <strong>{item.code} - </strong> : null}
          {item.description}
          {item.color_name ? (
            <span className="text-violet">&nbsp;-&nbsp;{item.color_name}</span>
          ) : null}
          {item.color_hex_code ? (
            <div className="d-flex ms-auto">
              <ColorViewer color={`#${item.color_hex_code}`} className={'ms-auto'} hideLabel />
            </div>
          ) : null}
        </div>
      </components.Option>
    );
  }
};

export default InventoryStoreOption;
