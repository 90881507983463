import { FC, useMemo, useState } from 'react';
import { getFilterSelectorValues } from '../filters/helpers';
import { TableFilterSelectorProps } from '@/common/types/front-entities/table';
import { ClassNumbersType } from '@/common/types/enums';
import useFilterSelector from 'common/components/filters/useFilterSelector';
import Select from '@/ts-common/components/form/inputs/select';

const ClassNumbersSelector: FC<TableFilterSelectorProps> = ({
  value,
  filter,
  onChange,
  isMulti,
  listParams,
  ...rest
}) => {
  const [selected, setSelected] = useState<ClassNumbersType | ClassNumbersType[] | null>([]);

  const selectedValue = filter?.value || value;

  useFilterSelector(
    selectedValue,
    { list: 'existing-class-numbers' },
    { selected, setSelected },
    'label'
  );

  const memoizedRequestParams = useMemo(
    () => ({
      params: { ...(listParams || {}), list: 'existing-class-numbers' },
      path: 'lists'
    }),
    [listParams]
  );

  return (
    <Select
      placeholder="Select value"
      getOptionValue={option => option.label.toString()}
      getOptionLabel={option => option.name}
      isAsync
      isMulti={isMulti}
      value={
        isMulti
          ? selectedValue?.length
            ? getFilterSelectorValues(selected, selectedValue, isMulti, 'label')
            : []
          : selectedValue?.value || selectedValue
      }
      memoizedRequestParams={memoizedRequestParams}
      onChange={(s: ClassNumbersType | ClassNumbersType[] | null) => {
        setSelected(isMulti && !s ? [] : s);
        onChange({
          value: s ? (isMulti && Array.isArray(s) ? [...s.map(s => s.label)] : s) : ''
        });
      }}
      {...rest}
    />
  );
};
export default ClassNumbersSelector;
