import React, { useEffect } from 'react';
import { Selectors } from 'common/entities/selectors/SelectorTypes';
import Select from 'common/components/form/inputs/Select';

import { fetchListOptions } from 'store/lists/actions';

import { useDispatch, useSelector } from 'react-redux';

import { selectListDefaultOptions } from 'store/lists/selectors';

const OcimfCategorySelector = ({ filter, value, onChange, autoFocus = true, ...rest }) => {
  const dispatch = useDispatch();

  const options = useSelector(state =>
    selectListDefaultOptions(state, 'crew-medical-case-ocimf-system-categorization')
  );

  const customOptions = [
    { value: 'true', label: 'Filled in' },
    { value: 'false', label: 'Empty' }
  ];

  const listFormattedOptions = options.map(o => {
    return { value: o?.name, label: o?.name };
  });

  const allOptions = [...listFormattedOptions, ...customOptions];

  useEffect(() => {
    dispatch(fetchListOptions('crew-medical-case-ocimf-system-categorization'));
  }, [dispatch]);

  return (
    <div>
      <Select
        placeholder="Select type"
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={filter?.value}
        options={allOptions}
        onChange={selected => {
          onChange(selected);
        }}
        {...rest}
      />
    </div>
  );
};

OcimfCategorySelector.propTypes = Selectors;

export default OcimfCategorySelector;
