import { get, put, post, deleteRequest } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import TABLE_TYPES from 'common/components/table/store/types';
import { successHandler } from 'common/utils/notifications';

export const getEventLubOilAnalysis = createAsyncThunk(
  'GET_EVENT_LUB_OIL_ANALYSIS',
  async (params, { rejectWithValue }) => {
    try {
      const { event_id, ...rest } = params;

      const response = await get(`/events/${event_id}/lub-oil-analysis`, rest);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEventLubOilAnalysis = createAsyncThunk(
  'UPDATE_EVENT_LUB_OIL_ANALYSIS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { event_id, ...rest } = params;

      const response = await put(`/events/${event_id}/lub-oil-analysis`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getNextEventLubOilAnalysis = createAsyncThunk(
  'GET_NEXT_EVENT_LUB_OIL_ANALYSIS',
  async (params, { rejectWithValue }) => {
    try {
      const { event_id, ...rest } = params;
      const response = await get(`/events/${event_id}/lub-oil-analysis/next-event-id`, rest);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getEventLubOilAnalysisAssignments = createAsyncThunk(
  'GET_EVENT_LUB_OIL_ANALYSIS_ASSIGNMENTS',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { table, event_id, ...rest } = params;

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.START,
        payload: { params, table }
      });

      const response = await get(`/events/${event_id}/lub-oil-analysis/assignments`, rest);

      dispatch({
        type: TABLE_TYPES.GET_TABLE_LIST.SUCCESS,
        payload: { data: response?.data, table }
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const addEventLubOilAnalysisAssignment = createAsyncThunk(
  'ADD_EVENT_LUB_OIL_ANALYSIS_ASSIGNMENT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { event_id, ...rest } = params;
      const response = await post(`/events/${event_id}/lub-oil-analysis/assignments`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Added successfully' }));

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateEventLubOilAnalysisAssignment = createAsyncThunk(
  'UPDATE_EVENT_LUB_OIL_ANALYSIS_ASSIGNMENT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { event_id, id, ...rest } = params;
      const response = await put(`/events/${event_id}/lub-oil-analysis/assignments/${id}`, rest);

      dispatch(successHandler({ title: 'Success!', message: 'Updated successfully' }));

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const removeEventLubOilAnalysisAssignment = createAsyncThunk(
  'REMOVE_EVENT_LUB_OIL_ANALYSIS_ASSIGNMENT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { event_id, id, ...rest } = params;
      const response = await deleteRequest(
        `/events/${event_id}/lub-oil-analysis/assignments/${id}`,
        rest
      );

      dispatch(successHandler({ title: 'Success!', message: 'Removed successfully' }));

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
