import React, { useEffect, useState } from 'react';

import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import PropTypes from 'prop-types';
import { TableTypes } from 'common/entities/tables/TableTypes';
import Form from './Form';
import config from './config';
import { useForm } from 'utils/hooks';
import selectDataFromState from 'common/utils/hooks/useForm/selectDataFromState';
import { useDispatch } from 'react-redux';
import {
  getAccountsAttachments,
  updateAccounts
} from 'events/store/event-modules/accounts/actions';

const EditForm = ({ active, drawer, refetchData, setActive }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { formState, collectValues, hasErrors, loadValues, resetForm } = useForm(config);

  const editForm = async params => {
    try {
      setIsSubmitting(true);

      const { id, event_id } = active;
      if (!event_id || !id) return null;

      await dispatch(updateAccounts({ id: event_id, accountId: id, ...params })).unwrap();

      setIsSubmitting(false);
      drawer.close();
      refetchData();
      resetForm();
      setActive(null);
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (drawer.isOpen) {
      const initForm = async () => {
        if (!active) return;

        try {
          const requestedAttachments = await dispatch(
            getAccountsAttachments({ id: active.id })
          ).unwrap();

          const initialValues = selectDataFromState(active, config);
          const { attachments, ...rest } = initialValues;

          loadValues({
            attachments: requestedAttachments?.length ? requestedAttachments : [],
            ...rest
          });
        } catch (error) {
          console.error(error);
        }
      };

      initForm();
    } else {
      setActive(null);
      resetForm();
    }
  }, [active, dispatch, drawer.isOpen, loadValues, resetForm, setActive]);

  return (
    <Form
      active={active}
      drawer={drawer}
      formState={formState}
      collectValues={collectValues}
      hasErrors={hasErrors}
      submitForm={editForm}
      isSubmitting={isSubmitting}
    />
  );
};

EditForm.propTypes = {
  active: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    event_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes?.string,
    currency: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  drawer: DrawerState,
  refetchData: TableTypes.refetchData,
  setActive: PropTypes.func
};

export default EditForm;
