import { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { selectComparedAtVersion } from 'manuals/store/selectors';
import { parseQueryParams, stringifyObj } from 'common/utils/urls';
import useRouter from 'use-react-router';

import SvgRender from 'common/components/general/SvgRender';
import x from 'common/assets/svg/common/x.svg';
import historyIcon from 'common/assets/svg/common/history.svg';
import { getColor } from './helpers';

const Button = ({ clickHandler }) => {
  const [isHovered, setIsHovered] = useState(false);
  const selectedVersion = useSelector(selectComparedAtVersion);
  const { location, history } = useRouter();

  const resetChanges = () => {
    const { compared, ...rest } = parseQueryParams(location.search);

    history.push({ pathname: location.pathname, search: stringifyObj(rest) });
  };

  return (
    <div
      className={`manual-versions-compare ${
        isHovered ? 'hovered' : ''
      } d-flex align-items-center text-primary ps-2 cursor-pointer ms-1`}
    >
      <div
        className="d-flex align-items-center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={clickHandler}
      >
        <SvgRender className="cme-12" style={{ height: 15 }} src={historyIcon} />
        <span className="fs-14 text-primary text-nowrap">
          {selectedVersion?.valid_from ? 'C' : 'See c'}hanges since
        </span>

        {selectedVersion?.valid_from ? (
          <div className="d-flex align-items-center text-nowrap">
            :
            <span className="cms-6 fw-bold text-primary">
              {moment(selectedVersion?.valid_from).format('DD/MM/YYYY')}
            </span>
            {selectedVersion?.title ? (
              <span className={`fw-bold text-${getColor(selectedVersion?.status)} cms-6`}>
                - {selectedVersion?.title}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>

      {selectedVersion?.valid_from ? (
        <>
          <div className="manual-versions-compare__separator cms-10"></div>
          <div
            className="manual-versions-compare__remove-container px-2 d-flex align-items-center"
            onClick={resetChanges}
          >
            <SvgRender style={{ height: 10, width: 10 }} className="text-primary" src={x} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Button;
