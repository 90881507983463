import { useEffect } from 'react';
import { useFormState } from 'utils/hooks';

const Row = ({
  row,
  columns,
  index,
  removeRow,
  previewMode,
  hideActions,
  totalActionColumns,
  removeLastRowBorder,
  rows
}) => {
  const { fields, changeField, selectField } = useFormState(row);

  useEffect(() => {
    if (rows?.length) {
      selectField('sort_index')(row.index);
    }
  }, [rows?.length]);

  return (
    <div className="d-flex">
      {columns?.map((col, i) => {
        const isLast = i + 1 === columns.length - (hideActions ? totalActionColumns : 0);
        if (col.isAction && hideActions) return null;
        return (
          <div
            key={i}
            id={`cell-row-${i}`}
            className={`cpx-12 pb-1 pt-1 border-white ${isLast ? '' : 'border-end'} ${
              !removeLastRowBorder ? 'border-bottom' : ''
            }`}
            style={{ width: col.width }}
          >
            {(col.previewCellComponent && fields.preview.value) || previewMode
              ? col.previewCellComponent({ fields, changeField, selectField, removeRow, index })
              : col.cellComponent({ fields, changeField, selectField, removeRow, index })}
          </div>
        );
      })}
    </div>
  );
};

export default Row;
