import { selectWarnings } from 'common/components/mga/store/selectors-ts.ts';
import { displayDate } from 'common/utils/dates';
import { numberToStr } from 'common/utils/numbers';
import AlertsContainer from 'common/components/alerts/AlertsContainer';
import PropTypes from 'prop-types';
import { useAppSelector } from '@/store/hooks';
import { NavLink } from 'react-router-dom';
import linkIcon from '@/common/assets/svg/common/link.svg';
import SvgRender from '@/ts-common/components/general/SvgRender';
import paths from '@/routing/routes/_paths';
import usePathConstructor from '@/common/components/mga/hook/usePathContructor.ts';
import { stringifyObj } from 'utils/urls';
import _get from 'lodash/get';

const CrewClosingBalanceWarnings = ({ crewMemberId, excludeMiscWarnings = false }) => {
  const warnings = useAppSelector(state =>
    selectWarnings(state, crewMemberId, excludeMiscWarnings)
  );

  const pathname = usePathConstructor();

  if (!warnings?.length) return null;

  return (
    <AlertsContainer
      isOpen
      className="cmt-6 pb-1 pt-1 px-2 mb-1"
      alerts={warnings}
      renderAlert={({
        full_name,
        mga_account_id,
        to,
        type,
        message,
        closing_balance,
        warning_type,
        ...data
      }) => {
        if (warning_type === 'action-for-off-board-crew') {
          return (
            <div>
              - Orca detected that{' '}
              <strong className="fw-medium">{_get(data, 'crew_member_full_name', '')}</strong>{' '}
              {message}
            </div>
          );
        }

        return type ? (
          <NavLink
            className="d-flex align-items-center text-red"
            to={`${paths.mga}/accounts${pathname}?${stringifyObj({
              filters: [{ name: 'account_id', operation: '=', value: mga_account_id }]
            })}`}
          >
            <SvgRender src={linkIcon} style={{ width: 12, height: 12 }} className="text-red me-1" />
            - <strong className="fw-medium cms-2 cme-4">{full_name}</strong>
            {message}
          </NavLink>
        ) : (
          <div>
            - Orca detected that <strong className="fw-medium">{full_name}</strong> is going to sign
            off at <strong className="fw-medium">{displayDate(to, { time: true })}</strong> and his
            closing balance is{' '}
            <strong className="fw-medium">
              {numberToStr(closing_balance, 2, 2)} {closing_balance > 0 ? '>' : '<'} 0.00 USD
            </strong>
            .
          </div>
        );
      }}
    />
  );
};

CrewClosingBalanceWarnings.propTypes = {
  crewMemberId: PropTypes.number
};

export default CrewClosingBalanceWarnings;
