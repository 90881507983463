import { useEffect } from 'react';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import {
  selectCaptainReportTotals,
  selectGeneratorsLegRunningHoursTotal,
  selectLegTotalDgPower,
  selectCaptainReportTemplateType,
  selectMainEngineTotalPower,
  selectCaptainReportTotalsMainEnginePower
} from 'captain-reports/store/selectors';
import { numberToStr } from 'common/utils/numbers';
import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import useReportTimeDiff from 'captain-reports/utils/useReportTimeDiff';

const LubricantLegAverageFeedRateField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const captainReportTemplateType = useSelector(selectCaptainReportTemplateType);
  const mins_since_last_report = useReportTimeDiff();
  const [setValue] = useActions([reportActions.setValue]);
  const power = useSelector(selectMainEngineTotalPower);

  const legTotalDgPower = useSelector(selectLegTotalDgPower);
  const totals = useSelector(selectCaptainReportTotals);
  const consumptionKey = field.key?.replace(
    '_leg_average_feed_rate',
    '_leg_total_consumption_total'
  );
  const generatorsLegRunningHoursTotal = useSelector(selectGeneratorsLegRunningHoursTotal);
  const legPower = useSelector(selectCaptainReportTotalsMainEnginePower);
  const feedRateNumber = field.key?.split('_')?.[1];
  const baseNumberKey =
    feedRateNumber && feedRateNumber !== 'leg' ? `co_${feedRateNumber}_base_number` : null;

  const isSo = field.key.includes('so');
  const isGo = field.key.includes('go');

  const legConsumption = useValue({
    key: consumptionKey,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const steamingTimeValue = useValue({
    key: 'steaming_time',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const steamingTime =
    captainReportTemplateType === 'port_noon' ? mins_since_last_report : steamingTimeValue;

  const baseNumber = useValue({
    key: baseNumberKey,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const legSteamingTime = totals?.steaming_time || null;

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  useEffect(() => {
    let calculation = null;

    if (isSo) {
      if (steamingTime && legConsumption && power) {
        let consumptionInGrams = legConsumption * 0.92 * 1000;
        let steamingTimeInHours = steamingTime / 60;

        // so_leg_total_consumption_total * 0.92 * 1000 / (steaming_time / 60) / (me_shaft_power * 0.985 || me_effective_power || me_indicated_power)
        calculation = numeral(consumptionInGrams)
          .divide(steamingTimeInHours)
          .divide(power)
          ?._value?.toFixed(2);
      }
    }
    if (isGo) {
      if (legConsumption && generatorsLegRunningHoursTotal && legTotalDgPower) {
        const legTotalDgRunningMinutes = generatorsLegRunningHoursTotal / 60;
        const go_leg_total_consumption_total = legConsumption * 0.92 * 1000;

        // go_leg_total_consumption_total 0.92 * 1000 / sum(leg_total_dg_running_minutes / 60) / sum(leg total dg_power) || sum means all DGs
        calculation = numeral(go_leg_total_consumption_total)
          .divide(legTotalDgRunningMinutes)
          .divide(legTotalDgPower)
          ?._value?.toFixed(2);
      }
    } else {
      if (legPower && legSteamingTime && legConsumption) {
        // If co_[each]_base_number >= 55 then 0.94 else 0.92
        let multiplier = +baseNumber >= 55 ? 0.94 : 0.92;

        let consumptionInGrams = legConsumption * multiplier * 1000;
        let steamingTimeInHours = legSteamingTime / 60;

        // co_[each]_leg_total_consumption_total * 0.92 * 1000 / (leg_total_steaming_time / 60) / (leg average me_shaft_power * 0.985 || leg average me_effective_power || leg average me_indicated_power)
        calculation = numeral(consumptionInGrams)
          .divide(steamingTimeInHours)
          .divide(legPower)
          ?._value?.toFixed(2);
      }
    }

    setValue({
      key: field.key,
      value: calculation || calculation === 0 ? +calculation : null,
      sectionLabel: sectionLabel,
      sectionActiveTab: sectionActiveTab,
      subGroup: subGroup,
      subGroupIndex: subGroupIndex
    });
  }, [
    legSteamingTime,
    legPower,
    legConsumption,
    baseNumber,
    steamingTime,
    power,
    generatorsLegRunningHoursTotal,
    legTotalDgPower
  ]);

  return (
    <div
      className={`fw-bold d-flex align-items-center fs-12 ${
        field.key === 'co_3_leg_average_feed_rate' ? 'cpe-2' : ''
      } ${
        field.key === 'go_leg_average_feed_rate' ? 'justify-content-end' : 'justify-content-start'
      }  height-24`}
    >
      <ValidationWrapper
        field={{
          ...field,
          key: field.key,
          label: isSo ? field.label : ''
        }}
        value={value}
        sectionLabel={sectionLabel}
        errorClassName="align-items-end"
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
        right={isSo ? false : true}
      >
        {numberToStr(value)}
      </ValidationWrapper>
    </div>
  );
};

export default LubricantLegAverageFeedRateField;
