import { createSelector } from 'reselect';
import { MgaCurrencyType, MgaStateType, PastRatesType } from '@/common/types/mga.ts';
import { RootState } from '@/store';
import { selectLatestPeriodCrewClosingBalanceWarnings } from '@/common/components/mga/store/selectors.js';

const selectReducer = (state: RootState): MgaStateType => state.mga;
const getKey = (_: unknown, key: string | number) => key;
const getSecondKey = (_: unknown, __: unknown, key: string | number) => key;

/* Forex Rate Currencies */
export const selectCurrencies = (state: RootState): MgaCurrencyType[] =>
  selectReducer(state).currencies;

export const selectPastRates = (state: RootState): PastRatesType[] =>
  selectReducer(state).pastRates;

export const selectBaseCurrency = createSelector(
  selectCurrencies,
  (currencies: MgaCurrencyType[]) => currencies.find(currency => currency?.isBase)
);

export const selectBaseCurrencyLabel = createSelector(
  selectCurrencies,
  (currencies: MgaCurrencyType[]) => currencies.find(currency => currency?.isBase)?.label || 'USD'
);

export const selectCurrenciesWithoutBase = createSelector(
  selectCurrencies,
  (currencies: MgaCurrencyType[]) => currencies.filter(currency => !currency?.isBase)
);
/* -- */

const selectMiscWarnings = (state: RootState) => selectReducer(state).miscWarnings;

export const selectWarnings = createSelector(
  selectMiscWarnings,
  getKey,
  getSecondKey,
  selectLatestPeriodCrewClosingBalanceWarnings,
  (miscWarnings, crewMemberId, excludeMiscWarnings, closingBalanceWarnings) => {
    if (excludeMiscWarnings) {
      return closingBalanceWarnings;
    } else {
      return closingBalanceWarnings.concat(miscWarnings);
    }
  }
);
