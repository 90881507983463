import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'utils/hooks';
import CircledButton from 'common/components/buttons/CircledButton';

import Form from 'events/event/modules/module-components/crew-medical-case/form/index';
import { Row, Col, Button } from 'reactstrap';
import Textarea from 'common/components/form/inputs/Textarea';
import PreviewHeader from '../compoments/PreviewHeader';
import SvgRender from 'common/components/general/SvgRender';
import comments from 'common/assets/svg/common/comments.svg';
import { selectEventId } from 'events/store/events/selectors';

import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import DownloadAttachment from 'common/components/buttons/DownloadAttachment';
import config from 'events/event/modules/module-components/crew-medical-case/form/config';
import ClaimAmounts from 'events/event/modules/module-components/crew-medical-case/form/compoments/claim-amounts';
import PropTypes from 'prop-types';
import { CrewMedicalCaseTypes } from 'common/entities/events/CrewMedicalCaseTypes';
import Information from 'common/components/general/Information';
import BaseStatusLabel from 'common/components/statuses/BaseStatusLabel';
import { displayDate } from 'common/utils/dates';
import {
  deleteCrewMedicalCase,
  downloadCrewMedicalAttachments,
  getCrewMedicalCases
} from 'events/store/event-modules/crew-medical-cases/actions';
import { setActiveMedicalCase } from 'events/store/event-modules/crew-medical-cases/slice';
import { selectIsMedicalCaseLocked } from 'events/store/event-modules/crew-medical-cases/selectors';
import CheckedButton from 'common/components/buttons/CheckedButton';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const Preview = ({ medicalCase, closeForm, isLoading, activeMedicalCase }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const { loadValues, formState } = useForm(config, {});
  const { fields, selectField } = useFormState(formState);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const isLocked = useSelector(selectIsMedicalCaseLocked);
  const eventId = useSelector(selectEventId);
  const claimExpensesOptions = useSelector(state => state.lists['event-medical-expenses'])?.options;

  const load = useCallback(async () => {
    loadValues({
      ...medicalCase,
      medical_type: medicalCase?.crew_medical_case_type_id,
      crew_member: medicalCase?.crew_member || null,
      expenses: medicalCase?.expenses?.map(exp => {
        return {
          ...exp,
          title: exp?.medical_expense.type,
          invoices: exp?.invoices?.length ? exp?.invoices : []
        };
      })
    });
  }, [loadValues, medicalCase]);

  const onDelete = async () => {
    try {
      await dispatch(
        deleteCrewMedicalCase({ eventId: eventId, medicalCaseId: medicalCase.id })
      ).unwrap();
      await dispatch(getCrewMedicalCases({ eventId: eventId })).unwrap();
      dispatch(setActiveMedicalCase(null));
    } catch (e) {
      console.error(e);
    }
  };

  const downloadAttachments = async () => {
    try {
      await dispatch(downloadCrewMedicalAttachments({ id: medicalCase?.id })).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    load();
  }, [activeMedicalCase, load, medicalCase]);

  if (activeMedicalCase && activeMedicalCase.id === medicalCase.id) {
    return <Form closeForm={closeForm} isLoading={isLoading} />;
  }

  return (
    <div className="event-medical-crew-cases__content preview">
      <PreviewHeader medicalCase={medicalCase} />
      <div className="event-medical-crew-cases__content-top  d-flex justify-content-between preview">
        <div className={`event-medical-crew-cases__content-left preview text-primary`}>
          <Row className="d-flex">
            <Col xs={6} className="d-flex-column pb-0">
              <div className="fs-10">LOCAL P&I CORRESPONDENT</div>
              <div className="fw-medium">
                {medicalCase?.local_pni_correspondent?.full_name || '-'}
              </div>
            </Col>
            <Col xs={2} className="d-flex-column pb-0">
              <div className="fs-10">DATE REPORTED</div>
              <div className="fw-medium">
                {medicalCase?.reported_at
                  ? displayDate(medicalCase.reported_at, { time: true })
                  : '-'}
              </div>
            </Col>
            <Col xs={4} className="d-flex-column pb-0 ">
              <div className="fs-10">REPORTED TO</div>
              <div className="fw-medium">
                {medicalCase?.reported_to ? (
                  <TextWithTooltip>{medicalCase.reported_to}</TextWithTooltip>
                ) : (
                  '-'
                )}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={6} className="d-flex-column pb-0">
              <div className="d-flex align-items-center">
                <div className="fs-10">OCIMF SYSTEM CATEGORY</div>
                <Information
                  message={'This field is going to affect results in LTIF & TRCF Crew Report.'}
                  svgStyle={{ width: 10, height: 10 }}
                  tooltipClassname="min-width-fit max-width-fit"
                  className="text-primary d-flex align-items-center cms-6"
                />
              </div>
              <div className="fw-medium">
                {medicalCase?.ocimf_system_categorization?.name || '-'}
              </div>
            </Col>
            <Col xs={2} className="d-flex-column pb-0">
              <div className="fs-10 text-nowrap">PRE EXISTING COND.</div>
              <div className="fw-medium first-letter-capitalize">
                {medicalCase.pre_existing_condition.replace('_', ' ') || '-'}
              </div>
            </Col>

            <Col xs={2} className="d-flex-column pb-0">
              <div className="fs-10">DUTY SUSPENDED</div>
              <div className="fw-medium">
                {medicalCase?.duty_suspended_at
                  ? displayDate(medicalCase.duty_suspended_at, { time: true })
                  : '-'}
              </div>
            </Col>

            <Col xs={2} className="d-flex-column pb-0">
              <div className="fs-10">DUTY RESUMED</div>
              <div className="fw-medium">
                {medicalCase?.duty_resumed_at
                  ? displayDate(medicalCase.duty_resumed_at, { time: true })
                  : '-'}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={6} className="d-flex align-items-end">
              <div className="fw-medium d-flex align-items-center">
                <CheckedButton
                  isChecked={medicalCase.telemedico}
                  hideUncheckedIcon
                  style={{ width: 14, height: 14 }}
                  uncheckedIconColor="violet"
                />
                <div className="fs-12 fw-medium ms-1">Telemedico</div>
              </div>
            </Col>

            {medicalCase.telemedico ? (
              <Col xs={2} className="d-flex-column pb-0">
                <div className="fs-10">DATE OCCURED</div>
                <div className="fw-medium">
                  {medicalCase?.telemedico_occurred_at
                    ? displayDate(medicalCase.telemedico_occurred_at, { time: true })
                    : '-'}
                </div>
              </Col>
            ) : null}

            {medicalCase.telemedico ? (
              <Col xs={4} className="d-flex-column pb-0">
                <div className="fs-10">INFO</div>
                <div className="fw-medium">
                  {medicalCase?.telemedico_info ? (
                    <TextWithTooltip className="h-auto">
                      {medicalCase.telemedico_info}
                    </TextWithTooltip>
                  ) : (
                    '-'
                  )}
                </div>
              </Col>
            ) : null}
          </Row>

          <Row className="mt-2">
            <Col xs={6} className="d-flex align-items-end">
              <div className="fw-medium d-flex align-items-center">
                <CheckedButton
                  isChecked={medicalCase.hospitalized}
                  hideUncheckedIcon
                  style={{ width: 14, height: 14 }}
                  uncheckedIconColor="violet"
                />
                <div className="fs-12 fw-medium ms-1">Hospitalized</div>
              </div>
            </Col>

            {medicalCase.hospitalized ? (
              <Col xs={6} className="d-flex-column pb-0">
                <div className="fs-10">HOSPITALIZED</div>
                <div className="fw-medium">
                  {medicalCase?.hospitalized_at_from && medicalCase?.hospitalized_at_to ? (
                    <div>
                      {' '}
                      From {displayDate(medicalCase.hospitalized_at_from, { time: true })}
                      &nbsp;-&nbsp;To&nbsp;
                      {displayDate(medicalCase.hospitalized_at_to, { time: true })}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </Col>
            ) : null}
          </Row>

          <Row className="mt-2">
            <Col xs={6} className="d-flex align-items-end">
              <div className="fw-medium d-flex align-items-center">
                <CheckedButton
                  isChecked={medicalCase.repatriated}
                  hideUncheckedIcon
                  style={{ width: 14, height: 14 }}
                  uncheckedIconColor="violet"
                />
                <div className="fs-12 fw-medium ms-1">Repatriated</div>
              </div>
            </Col>

            {medicalCase.repatriated ? (
              <Col xs={2} className="d-flex-column pb-0">
                <div className="fs-10">ARRIVED AT</div>
                <div className="fw-medium">
                  {medicalCase?.arrived_at
                    ? displayDate(medicalCase.arrived_at, { time: true })
                    : '-'}
                </div>
              </Col>
            ) : null}

            {medicalCase.repatriated ? (
              <Col xs={4} className="d-flex-column pb-0">
                <div className="fs-10">WHERE</div>
                {medicalCase?.arrived_at_location ? (
                  <div className="fw-medium d-flex align-items-center">
                    <TextWithTooltip>{medicalCase.arrived_at_location}</TextWithTooltip>
                    &nbsp;
                    {medicalCase?.days_since_arrival ? (
                      <>
                        |&nbsp;
                        <div className="text-violet fs-10 d-flex align-items-center min-width-88">
                          <TextWithTooltip>
                            <strong className="fs-12"> {medicalCase?.days_since_arrival}</strong>
                            &nbsp;<span className="flex-nowrap">days since arrival</span>
                          </TextWithTooltip>
                          <Information
                            message={'Here we count days between Arrival and Case Close Date.'}
                            svgStyle={{ width: 12, height: 12 }}
                            tooltipClassname="min-width-fit max-width-fit"
                            className="text-violet d-flex align-items-center ms-1"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  '-'
                )}
              </Col>
            ) : null}
          </Row>

          <Row className="mt-2">
            <Col xs={6} className="d-flex align-items-end cpb-2">
              <BaseStatusLabel
                value={medicalCase?.closed_at ? 'Closed' : 'Open'}
                color={medicalCase?.closed_at ? 'color_5' : 'color_3'}
                showLockIcon={false}
                className={'height-24 me-1 '}
              />
            </Col>

            <Col xs={2} className="d-flex-column pb-0">
              <div className="fs-10">CLOSED AT</div>
              <div className="fw-medium">
                {medicalCase?.closed_at ? displayDate(medicalCase.closed_at, { time: true }) : '-'}
              </div>
            </Col>

            <Col xs={3} className="d-flex align-items-end cpb-2">
              <Button
                color="white"
                className="px-1 text-primary height-24 fs-10 d-flex align-items-center"
              >
                <div className="d-flex">
                  <div className="cme-6">
                    <span className="fs-12">{medicalCase?.days_open}</span>{' '}
                    <span className="fw-medium">days open</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Information
                      message={'Here we count days between Occurence and Case Close Date.'}
                      svgStyle={{ width: 12, height: 12 }}
                      tooltipClassname="min-width-fit max-width-fit"
                      className="text-primary d-flex align-items-center"
                    />
                  </div>
                </div>
              </Button>
            </Col>
          </Row>
        </div>
        <div className="event-medical-crew-cases__content-right preview overflow-hidden d-flex-column justify-content-between">
          <div className="event-medical-crew-cases__content-right-container d-flex-column">
            <div
              className={`d-flex-column text-violet event-medical-crew-cases__content-right-remarks ${
                !medicalCase.attachments.length ? 'extend' : ''
              }`}
            >
              <div className="d-flex text-moody-blue pb-2">
                <SvgRender className={`me-1`} style={{ height: 16, width: 16 }} src={comments} />
                Remarks
              </div>

              <Textarea
                placeholder={'Add some remarks'}
                value={medicalCase.remarks}
                readOnly={true}
                invisible
                rows={!medicalCase.attachments.length ? 17 : 14}
              />
            </div>

            <div className="d-flex-column cmt-10">
              <div className="fs-10"></div>
              <div className="fw-medium">
                <DownloadAttachment
                  data={medicalCase}
                  hasAttachments={medicalCase.attachments.length > 0}
                  popupPlacement="right"
                  size="lg"
                  downloadFiles={downloadAttachments}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isOnBoard && (
        <div className={`event-medical-crew-cases__content-bottom preview`}>
          <ClaimAmounts
            claimExpensesOptions={claimExpensesOptions}
            selectField={selectField}
            formState={formState}
            loadValues={loadValues}
            fields={fields}
            activeMedicalCase={activeMedicalCase}
            view={true}
          />
        </div>
      )}

      {isLocked ? null : (
        <div className="event-medical-crew-cases__content-actions preview">
          <CircledButton
            type="edit"
            svgStyle={{ width: 12, height: 12 }}
            onClick={() => dispatch(setActiveMedicalCase(medicalCase))}
          />
          <CircledButton
            type="remove"
            svgStyle={{ width: 12, height: 12 }}
            onClick={() => setShowDeleteModal(true)}
            className="ms-1"
          />
        </div>
      )}

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDelete}
        isOpen={showDeleteModal}
        actionText={'Delete'}
        header={'Delete Medical Case'}
        closeModal={() => setShowDeleteModal(false)}
        body={
          <div>
            Are you sure you want to delete this <span className="fw-bold">Crew Medical Case</span>{' '}
            ?{' '}
          </div>
        }
      />
    </div>
  );
};

Preview.propTypes = {
  medicalCase: CrewMedicalCaseTypes,
  activeMedicalCase: CrewMedicalCaseTypes,
  closeForm: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Preview;
