import { FC } from 'react';

import SvgRender from '@/ts-common/components/general/SvgRender';
import add from '@/ts-common/assets/svg/actions/add.svg';

type CreateOptionProps = { label: string };

const CreateOption: FC<CreateOptionProps> = ({ label }) => {
  return (
    <div className="d-flex align-items-center">
      <div className={`create-option-round-btn d-flex align-items-center justify-content-center`}>
        <SvgRender src={add} style={{ width: 6, height: 6 }} />
      </div>
      <div className="text-primary fw-bold fs-10">{label}</div>
    </div>
  );
};

export default CreateOption;
