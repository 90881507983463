import manuals from 'common/assets/svg/common/manuals.svg';
import documents from 'common/assets/svg/common/documents.svg';
import paths from 'routing/routes/_paths';

import NavigationGroupTabs from 'common/components/buttons/NavigationGroupTabs';
import permissions from '@/common/utils/permissions/constants';

export const tabs = [
  {
    label: 'manuals',
    tooltip: 'Manuals',
    icon: manuals,
    to: paths.manuals,
    permissions: [permissions.office.manuals.list.view, permissions.onboard.manuals.view]
  },
  {
    label: 'filesystem',
    tooltip: 'Files',
    icon: documents,
    to: paths.filesystem,
    isActive: (_, location) => location.pathname.startsWith(paths.filesystem),
    permissions: [permissions.office.files.view, permissions.onboard.files.view]
  }
];

const NavTabs = () => {
  return <NavigationGroupTabs tabs={tabs} />;
};

export default NavTabs;
