import PersonPreview from 'common/components/tickets/PersonPreview';
import PageSaving from 'common/components/general/PageSaving';
import {
  selectTripIDs,
  selectTripParties,
  selectTripVisibleParties,
  selectIsTripCollpased,
  selectIsTripEditting
} from 'store/ticketing/selectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import * as ticketingActions from 'store/ticketing/actions';
import Totals from 'common/components/tickets/drawer/Totals';

const Participants = ({ tripID, onDeleteTripParticipant }) => {
  const [updateTripParty, collapseTrip, editTrip] = useActions([
    ticketingActions.updateTripParty,
    ticketingActions.collapseTrip,
    ticketingActions.editTrip
  ]);

  const tripParties = useSelector(state => selectTripParties(state, tripID));
  const visibleTripParties = useSelector(state => selectTripVisibleParties(state, tripID));
  const isTripCollapsed = useSelector(state => selectIsTripCollpased(state, tripID));
  const isEditting = useSelector(selectIsTripEditting);

  const singleTrip = useSelector(selectTripIDs)?.length === 1;

  const onChangeType = (personId, params) => {
    updateTripParty({ personId }, params);

    if (tripID) {
      editTrip({
        tripID,
        parties: tripParties.map(p => {
          return {
            id: p.person.id,
            ticketing_type_id: p.person.id === personId ? params?.type?.id : p.ticketing_type?.id
          };
        })
      });
    }
  };

  return (
    <div className="mb-2 border-bottom">
      {tripParties.map(({ person, port, vessel, started_at, type }, index) =>
        visibleTripParties?.length && !visibleTripParties.find(p => p.id === person.id) ? null : (
          <PersonPreview
            key={index}
            tripID={tripID}
            type={type}
            person={person}
            personType={visibleTripParties.find(p => p.id === person.id)?.type}
            port={port}
            vessel={vessel}
            date={started_at}
            hiddenParties={
              visibleTripParties?.length
                ? tripParties.filter(
                    party => !visibleTripParties.find(p => p.id === party.person.id)
                  )
                : null
            }
            onChangeType={newType => onChangeType(person.id, { type: newType })}
            onCollapse={() => collapseTrip(tripID)}
            showCollapseButton={tripID && !singleTrip}
            isCollapsed={isTripCollapsed}
            tripParties={tripParties}
            onDeleteTripParticipant={onDeleteTripParticipant}
            isLastPerson={tripParties?.length - 1 === index}
          />
        )
      )}

      {tripID ? null : <Totals />}

      <PageSaving isSaving={isEditting} />
    </div>
  );
};

export default Participants;
