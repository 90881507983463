import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';

import * as listActions from 'store/lists/actions';

import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';

import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

const GroupedOptionsSelect = ({
  label,
  onChange,
  onGroupSelect,
  loadOptions,
  defaultOptions,
  list,
  styled,
  excludeIds,
  isMulti = true,
  isAsync = false,
  optionsSortBy,
  groupBy,
  listParams = {},
  value,
  className = '',
  ...rest
}) => {
  const listOptions = useSelector(state => (state.lists[list] ? state.lists[list].options : []));
  const isFetching = useSelector(state =>
    state.lists[list] ? state.lists[list].isFetching : false
  );

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const SelectTag = styled ? StyledSelect : Select;

  const getOptions = data => {
    let options = [];

    options = _sortBy(data, [optionsSortBy ? optionsSortBy : o => o[groupBy].name]);

    if (excludeIds?.length) {
      options = options.filter(item => !excludeIds.includes(item.id));
    }

    return options;
  };

  const selectProps = { options: getOptions(listOptions), ...rest };

  const handleOptionSelect = selected => {
    if (selected) {
      onChange(
        isMulti
          ? selected.map(s => {
              /* the select always returns the id (number), 
            so we need to find the actual object and return this instead
          */
              return listOptions.find(l => l.id === s);
            })
          : selected
      );

      return;
    }

    onChange(selected);
  };

  useEffect(() => {
    if (!isFetching) {
      fetchListOptions(list, listParams.search, listParams);
    }
  }, []);

  return (
    <SelectTag
      label={label}
      hideSelectedOptions={true}
      showLabel={styled && label ? true : null}
      onChange={handleOptionSelect}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      placeholder=""
      isClearable
      components={{ Option: props => GroupOption(props, groupBy, onGroupSelect, isMulti) }}
      isMulti={isMulti}
      isAsync={false}
      value={isMulti ? (value ? value.map(v => v.id) : []) : value}
      className={`grouped-select ${className}`}
      {...selectProps}
    />
  );
};

export const GroupHeader = ({ groupOptions, group, onGroupSelect, isMulti }) => {
  return (
    <div
      className={`grouped-select-option__header ${isMulti ? '' : 'pointer-events-none'}`}
      onClick={isMulti ? () => onGroupSelect({ group, options: groupOptions }) : () => {}}
    >
      {group.name}{' '}
      <span className="text-violet">{`${groupOptions.length} item${
        groupOptions.length === 1 ? '' : 's'
      }`}</span>
    </div>
  );
};

export const GroupOption = ({ children, ...props }, groupBy, onGroupSelect, isMulti) => {
  if (props.isSelected) return null;

  const group = _get(props.data, groupBy, null) || null;
  const firstOptionInGroup =
    group && group.id
      ? props.options
          .filter(o =>
            props.hasValue
              ? isMulti
                ? !props.selectProps.value?.find(s => s.id === o.id)
                : props.selectProps.value?.id !== o.id
              : o
          ) // filter the selected values
          .find(opt => opt[groupBy].id === group.id)
      : null;

  return (
    <div className="grouped-select-option">
      {group && firstOptionInGroup && firstOptionInGroup.id === props.data.id ? (
        <GroupHeader
          isMulti={isMulti}
          options={props.options}
          group={group}
          groupOptions={props.options.filter(
            opt =>
              opt[groupBy].id === group.id &&
              (props.hasValue
                ? isMulti
                  ? !props.selectProps.value?.find(s => s.id === opt.id)
                  : props.selectProps.value?.id !== opt.id
                : opt)
          )}
          onGroupSelect={onGroupSelect}
        />
      ) : null}
      <components.Option {...props}>{children}</components.Option>
    </div>
  );
};

GroupedOptionsSelect.propTypes = {
  list: PropTypes.string.isRequired, // the listing type
  listParams: PropTypes.object, // Listing extra params
  groupBy: PropTypes.string.isRequired, // ex. if groupBy='type', each option format should be: { ..., id, type: { id, name } }
  onGroupSelect: PropTypes.func.isRequired, // this will be called when a group header is selected
  styled: PropTypes.bool, // whether to render a styled select or not
  excludeIds: PropTypes.array,
  optionsSortBy: PropTypes.func // https://lodash.com/docs/4.17.15#sortBy - Options are by default sorted based on the $groubBy.name
};

export default GroupedOptionsSelect;
