import React from 'react';
import { components } from 'react-select';
import SvgRender from 'common/components/general/SvgRender';
import searchIcon from 'common/assets/svg/common/search.svg';

const SelectSearchIndicator = props => {
  return (
    <components.DropdownIndicator
      className="react-select__dropdown-indicator--search py-0"
      {...props}
    >
      <SvgRender src={searchIcon} style={{ width: 16, height: 16 }} />
    </components.DropdownIndicator>
  );
};

export default SelectSearchIndicator;
