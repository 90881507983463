import React from 'react';
import NumberField from 'captain-reports/templates/inputs/NumberField';
import CheckboxInput from 'captain-reports/templates/inputs/CheckboxInput';

import Flowmeters from 'captain-reports/templates/components/custom-fields/Flowmeters';

const config = () => [
  {
    header: (
      <span className="fw-normal">
        <strong>ME FLOWMETER</strong> - VOLUME (L) / TEMPERATURE (°C)
      </span>
    ),
    customComponent: Flowmeters,
    extraFields: () => [
      config()[1],
      config()[2],
      config()[3],
      config()[4],
      config()[5],
      config()[6],
      config()[7],
      config()[8],
      config()[9],
      config()[10],
      config()[11],
      config()[12]
    ],
    fields: [
      {
        hideLabel: true,
        key: 'me_booster_unit_flowmeter_volume',
        width: { xs: '' },
        render: NumberField
      },
      {
        hideLabel: true,
        key: 'me_booster_unit_flowmeter_temperature',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    customComponent: () => <div />,
    fields: [
      {
        hideLabel: true,
        key: 'me_inlet_flowmeter_volume',
        width: { xs: '' },
        render: NumberField
      },
      {
        hideLabel: true,
        key: 'me_inlet_flowmeter_temperature',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    customComponent: () => <div />,
    fields: [
      {
        hideLabel: true,
        key: 'me_outlet_flowmeter_volume',
        width: { xs: '' },
        render: NumberField
      },
      {
        hideLabel: true,
        key: 'me_outlet_flowmeter_temperature',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    header: (
      <span className="fw-normal">
        <strong>DG FLOWMETER</strong> - VOLUME (L) / TEMPERATURE (°C)
      </span>
    ),
    customComponent: () => <div />,
    fields: [
      {
        hideLabel: true,
        key: 'dg_booster_unit_flowmeter_volume',
        width: { xs: '' },
        render: NumberField
      },
      {
        hideLabel: true,
        key: 'dg_booster_unit_flowmeter_temperature',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    customComponent: () => <div />,
    fields: [
      {
        hideLabel: true,
        key: 'dg_inlet_flowmeter_volume',
        width: { xs: '' },
        render: NumberField
      },
      {
        hideLabel: true,
        key: 'dg_inlet_flowmeter_temperature',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    customComponent: () => <div />,
    fields: [
      {
        hideLabel: true,
        key: 'dg_outlet_flowmeter_volume',
        width: { xs: '' },
        render: NumberField
      },
      {
        hideLabel: true,
        key: 'dg_outlet_flowmeter_temperature',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    customComponent: () => <div />,
    header: 'PUMPS IN OPERATION DISCHARGE PRESSURE (bar)',
    subGroup: 'me_lo_pumps_no',
    subGroupInline: true,
    fields: [
      {
        label: 'ME LO',
        key: 'me_lub_oil_pump_discharge_pressure',
        render: NumberField,
        type: 'number',
        className: 'smaller-template-input',
        width: { xs: '' }
      }
    ]
  },
  {
    customComponent: () => <div />,
    subGroup: 'main_cooler_sw_pumps_no',
    subGroupInline: true,
    fields: [
      {
        label: 'MAIN CSW',
        key: 'main_cool_sea_water_pump_discharge_pressure',
        render: NumberField,
        type: 'number',
        className: 'smaller-template-input',
        width: { xs: '' }
      }
    ]
  },
  {
    customComponent: () => <div />,
    subGroup: 'jacket_cooler_pumps_no',
    subGroupInline: true,
    fields: [
      {
        label: 'JACKET CW',
        key: 'jacket_cool_water_pump_discharge_pressure',
        render: NumberField,
        type: 'number',
        className: 'smaller-template-input',
        width: { xs: '' }
      }
    ]
  },
  {
    customComponent: () => <div />,
    subGroup: 'me_ltcw_pumps_no',
    subGroupInline: true,
    fields: [
      {
        label: 'LTCW',
        key: 'me_ltcw_pump_discharge_pressure',
        render: NumberField,
        type: 'number',
        className: 'smaller-template-input',
        width: { xs: '' }
      }
    ]
  },
  {
    customComponent: () => <div />,
    subGroup: 'me_htcw_pumps_no',
    subGroupInline: true,
    fields: [
      {
        label: 'HLTCW',
        key: 'me_htcw_pump_discharge_pressure',
        render: NumberField,
        type: 'number',
        className: 'smaller-template-input',
        width: { xs: '' }
      }
    ]
  },
  {
    customComponent: () => <div />,
    header: 'PURIFIERS IN OPERATION',
    subGroup: 'fo_purifiers_no',
    subGroupInline: true,
    fields: [
      {
        label: 'FO',
        key: 'fo_purifier_in_operation',
        render: CheckboxInput,
        width: { xs: 'auto' },
        className: 'pe-2'
      }
    ]
  },
  {
    customComponent: () => <div />,
    subGroup: 'lo_purifiers_no',
    subGroupInline: true,
    fields: [
      {
        label: 'LO',
        key: 'lub_oil_purifier_in_operation',
        render: CheckboxInput,
        width: { xs: 'auto' },
        className: 'pe-2'
      }
    ]
  }
];

const flowmetersConfig = {
  noon: () => config(),
  arrival: () => config(),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => [],
  port_noon: () => [],
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default flowmetersConfig;
