import { useSelector } from 'react-redux';

import { multiTabsSections, arrayFields } from 'captain-reports/templates/config/_constants';
import {
  selectFieldError,
  selectEngineDataError,
  selectEngineSubGroupDataError,
  selectGeneratorsDataError,
  selectGeneratorsSubGroupDataError,
  selectSubGroupDataError
} from 'captain-reports/store/selectors';

const useError = ({ key, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const error = useSelector(state => {
    if (!key) return null;

    if (multiTabsSections[sectionLabel]) {
      switch (sectionLabel) {
        case 'engines':
          if (subGroup) {
            return selectEngineSubGroupDataError(
              state,
              sectionActiveTab,
              subGroup,
              subGroupIndex,
              key
            );
          }

          return selectEngineDataError(state, sectionActiveTab, key);

        case 'generators':
          if (subGroup) {
            return selectGeneratorsSubGroupDataError(
              state,
              sectionActiveTab,
              subGroup,
              subGroupIndex,
              key
            );
          }

          return selectGeneratorsDataError(state, sectionActiveTab, key);

        default:
          console.error('Missing multiple section selector: ' + sectionLabel);
          return null;
      }
    } else {
      if (arrayFields[subGroup]) {
        return selectSubGroupDataError(state, subGroup, subGroupIndex, key);
      } else {
        return selectFieldError(state, key);
      }
    }
  });

  return error;
};

export default useError;
