import React, { useState, useEffect } from 'react';

import SingleContractShiftsCard from 'crew/components/shifts/crew-profile/SingleContractShiftsCard';
import ContractSelector from 'crew/components/ContractSelector';
import RestHoursRules from 'crew/components/shifts/RestHoursRules';
import { convertToFilters } from 'crew/utils/filters';
import ExportPdf from 'common/components/general/ExportPdf';
import PageLoader from 'common/components/general/PageLoader';
import useRouter from 'use-react-router';
import { isAuthorized } from 'utils/permissions/authorize';

import {
  selectShifts,
  selectStartDate,
  selectEndDate,
  selectIsLoading,
  selectSelectedContract
} from 'crew/store/shifts/selectors';
import { selectCrewMemberId } from 'crew/store/profile/selectors';
import { useSelector } from 'react-redux';

import { download } from 'utils/api';
import { handleBlobDownload } from 'common/utils/downloads';
import { useActions, usePrevious } from 'utils/hooks';
import * as shiftsActions from 'crew/store/shifts/actions';
import * as shiftsDataActions from 'store/shifts-data/actions';

import moment from 'moment';
import permissions from 'common/utils/permissions/constants';
import { selectAccount } from 'store/account/selectors';

const CrewProfileShifts = () => {
  const { match } = useRouter();

  const [isDownloading, setIsDownloading] = useState(false);

  const shifts = useSelector(selectShifts);
  const account = useSelector(selectAccount);

  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const selectedContract = useSelector(selectSelectedContract);
  const isLoading = useSelector(selectIsLoading);
  const crewId = useSelector(selectCrewMemberId);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const prevSelectedContract = usePrevious(selectedContract);
  const prevStartDate = usePrevious(startDate);

  const [
    setPeriodType,
    setStartDate,
    setEndDate,
    getCrewShift,
    fetchShiftTypes,
    clearShiftsState,
    setSelectedContract
  ] = useActions([
    shiftsActions.setPeriodType,
    shiftsActions.setStartDate,
    shiftsActions.setEndDate,
    shiftsDataActions.getCrewShift,
    shiftsDataActions.getShiftTypes,
    shiftsActions.clearShiftsState,
    shiftsActions.setSelectedContract
  ]);

  const onDateChange = val => {
    setSelectedContract(val);

    const dateCheck =
      moment(val.contract_from_date, 'YYYY-MM-DD').isBefore(moment()) &&
      moment(val.contract_to_date, 'YYYY-MM-DD').isAfter(moment());

    const dateToStartFrom = dateCheck ? moment() : moment(val.contract_from_date, 'YYYY-MM-DD');

    const start = dateToStartFrom.startOf('month').format('YYYY-MM-DD');
    const end = dateToStartFrom.endOf('month').format('YYYY-MM-DD');

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    setSelectedContract(null);
    clearShiftsState();
    fetchShiftTypes();

    const start = moment().format('YYYY-MM-DD');
    const end = moment().format('YYYY-MM-DD');

    setStartDate(start);
    setEndDate(end);
    setPeriodType('monthly');
    getCrewShift({ crewId: match.params.id, from: start, to: end });
  }, []);

  const selectedContractParams = selectedContract ? { contract_id: selectedContract.id } : {};

  useEffect(() => {
    if (
      (selectedContract &&
        prevSelectedContract &&
        selectedContract.id !== prevSelectedContract.id) ||
      (prevStartDate
        ? prevStartDate !== startDate
        : startDate
        ? startDate !== moment().startOf('month').format('YYYY-MM-DD')
        : false)
    ) {
      if (isLoading) return;
      getCrewShift({
        crewId: match.params.id,
        from: startDate,
        to: endDate,
        ...selectedContractParams
      });
    }
  }, [startDate, selectedContract]);

  const downloadPdf = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    const params = {
      filters: convertToFilters({ from: startDate, to: endDate, ...selectedContractParams })
    };

    try {
      const file = await download(`/crew-shifts/${crewId}/export`, params, true, true);
      const type = file.headers['content-type'];

      const blob = new Blob([file.data], { type });
      handleBlobDownload({ filename: 'watchkeeping-export.pdf' }, blob);
    } catch (e) {
      console.error(e);
    }
    setIsDownloading(false);
  };

  return (
    <div className="crew-profile-shifts">
      {isLoading ? <PageLoader isLoading={isLoading} /> : null}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center flex-1">
          <ContractSelector
            crewId={match.params.id}
            onChange={onDateChange}
            value={selectedContract}
            isLoading={isLoading}
            className="mb-0 w-100p max-width-580"
          />
          <ExportPdf
            className="border-start cps-12 cms-12"
            showLabel="Export PDF"
            onClick={downloadPdf}
            svgStyle={{ width: 20, height: 22 }}
            disabled={isDownloading}
          />
        </div>
        <RestHoursRules />
      </div>
      {shifts.length ? (
        <SingleContractShiftsCard
          isViewOnly={
            !isAuthorized(account, [permissions.onboard.crew.watchkeeping.shifts.edit]) || isOnBoard
          }
          crew={shifts[0]}
        />
      ) : null}
    </div>
  );
};

export default CrewProfileShifts;
