import React from 'react';

import Tooltip from 'common/components/general/Tooltip';
import ProfileImage from 'common/components/general/ProfileImage';
import _get from 'lodash/get';
import avatar from 'common/assets/svg/avatars/crew.svg';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import { PersonWithDepartment } from 'common/components/selectors/_helpers';

const PersonAvatar = ({ photo, person, small, imgProps = {}, withToolip }) => {
  const { avoidRender, tooltipID } = useTooltipID('manual-party-tooltip');

  const props = small ? { width: 20, height: 20 } : imgProps ? imgProps : { width: 24, height: 24 };

  return withToolip && avoidRender ? null : (
    <>
      <ProfileImage
        className={`manual-person-avatar ${small ? 'small-img' : ''}`}
        image={_get(photo, 'url', null)}
        fallback={avatar}
        imgProps={{ ...props, id: tooltipID }}
      />
      {withToolip && person ? (
        <Tooltip target={tooltipID}>
          <PersonWithDepartment displayClassname="text-white" withLink={false} person={person} />
        </Tooltip>
      ) : null}
    </>
  );
};

export default PersonAvatar;
