import { Button } from 'reactstrap';
import { useState, useCallback } from 'react';
import SvgRender from 'common/components/general/SvgRender';

import check from 'common/assets/svg/actions/check.svg';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

const PeriodButtonApprove = ({ updatePeriodStatus, latestPeriod }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  return (
    <>
      <Button
        type="button"
        color="primary"
        className="d-inline-flex align-items-center px-2 py-1 border-0 ms-1"
        onClick={openModal}
      >
        <SvgRender src={check} style={{ width: 14, height: 14 }} />
        <span className="fw-normal lh-1 ps-1">Approve</span>
      </Button>

      <DangerousActionModal
        transparent
        actionIcon={
          <SvgRender src={check} style={{ width: 80, height: 70 }} className="text-white" />
        }
        onAccept={() => updatePeriodStatus(latestPeriod?.id, 'reviewed')}
        actionHoverColor="primary"
        closeModal={closeModal}
        isOpen={isModalOpen}
        actionText="APPROVE"
        cancelText="CANCEL"
        header={'Approve'}
        body={`Are you sure you want to approve this MGA Period?`}
      />
    </>
  );
};

export default PeriodButtonApprove;
