import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import vessel from 'common/assets/svg/vessels/vessel.svg';
import { numberToStr } from 'common/utils/numbers';
import { displayDate } from 'common/utils/dates';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import { Col, Row } from 'reactstrap';
import { displaySparePart } from 'common/utils/form/display';

const GroupHeader = ({ data, children }) =>
  data.isVessel ? (
    <div className="d-flex align-items-center fw-bold fs-10 text-primary">
      <SvgRender className="cme-4" src={vessel} style={{ height: 12, width: 12 }} />
      {data?.vessel?.name || '-'}
    </div>
  ) : data.isGroup ? (
    <div className="table-group-header overflow-hidden">
      <span className="text-truncate">{data?.group?.name || data?.group?.description || '-'}</span>
    </div>
  ) : (
    <div className="cps-2">{children}</div>
  );

const ItemCell = ({ data, children, truncate = true }) =>
  data.isVessel || data.isGroup ? null : truncate ? (
    <TextWithTooltip className="h-auto">{children}</TextWithTooltip>
  ) : (
    children
  );

const lastSurveyCommonRows = {
  'last_survey.last_survey_rob': data => (
    <ItemCell data={data}>{numberToStr(data?.last_survey_rob)}</ItemCell>
  ),
  'last_survey.last_survey_timestamp': data => (
    <ItemCell data={data}>
      {displayDate(data?.last_survey_timestamp, { time: true }) || '-'}
    </ItemCell>
  ),
  'last_survey.last_survey_calculated_rob': data => (
    <ItemCell data={data}>{numberToStr(data?.last_survey_calculated_rob)}</ItemCell>
  )
};

const lastActionCommonRows = {
  'last_auto_update.last_auto_update_action': data => (
    <ItemCell data={data}>
      <TextWithTooltip className="h-auto">
        {data?.last_action?.includes('maintenance')
          ? 'Maintenance'
          : data?.last_action?.includes('delivery')
          ? 'Delivery'
          : '-'}
      </TextWithTooltip>
    </ItemCell>
  ),
  'last_auto_update.last_auto_update_quantity': data => (
    <ItemCell data={data}>{numberToStr(data?.last_action_quantity)}</ItemCell>
  ),
  'last_auto_update.last_auto_update_date': data => (
    <ItemCell data={data}>
      {displayDate(data?.last_action_timestamp, { time: true }) || '-'}
    </ItemCell>
  )
};

export const itemsColumns = [
  {
    header: 'ITEMS',
    type: 'string',
    key: 'items',
    className: 'position-static',
    minWidth: 373,
    maxWidth: 1061,
    width: ''
  },
  {
    header: 'UNIT',
    key: 'unit',
    type: 'string',
    minWidth: 71,
    maxWidth: 71
  },
  {
    header: 'ROB',
    key: 'rob',
    type: 'number',
    text_align: 'right',
    minWidth: 58,
    maxWidth: 58
  },
  {
    header: 'Last Survey',
    key: 'last_survey',
    type: 'group',
    columns: [
      {
        header: 'ROB',
        type: 'number',
        key: 'last_survey_rob',
        minWidth: 55,
        maxWidth: 55
      },
      {
        header: 'VS CALC ROB',
        key: 'last_survey_calculated_rob',
        type: 'number',
        minWidth: 65,
        maxWidth: 65
      },
      {
        header: 'DATE',
        key: 'last_survey_timestamp',
        type: 'date',
        minWidth: 100,
        maxWidth: 100
      }
    ]
  },
  {
    header: 'Last Auto Update',
    key: 'last_auto_update',
    type: 'group',
    columns: [
      {
        header: 'ACTION',
        type: 'string',
        key: 'last_auto_update_action',
        minWidth: 103,
        maxWidth: 103
      },
      {
        header: 'QTY',
        type: 'number',
        key: 'last_auto_update_quantity',
        minWidth: 58,
        maxWidth: 58
      },
      {
        header: 'DATE',
        key: 'last_auto_update_date',
        type: 'date',
        minWidth: 100,
        maxWidth: 100
      }
    ]
  },
  {
    header: '',
    key: 'history',
    className: 'cpx-4',
    minWidth: 50,
    maxWidth: 50
  }
];

export const itemsRows = {
  items: data => (
    <GroupHeader data={data}>
      <TextWithTooltip>
        {data?.item?.code}. {data?.item?.description || '-'}
      </TextWithTooltip>
    </GroupHeader>
  ),
  unit: data => <ItemCell data={data}>{data?.item?.purchasing_unit?.notation || '-'}</ItemCell>,
  rob: data => <ItemCell data={data}>{numberToStr(data?.calculated_rob)}</ItemCell>,
  ...lastSurveyCommonRows,
  ...lastActionCommonRows
};

export const sparePartsRows = {
  items: data => (
    <GroupHeader data={data}>
      <TextWithTooltip>{displaySparePart(data?.item)}</TextWithTooltip>
    </GroupHeader>
  ),
  unit: data => <ItemCell data={data}>{data?.item?.unit?.name || '-'}</ItemCell>,
  rob: data => (
    <ItemCell data={data}>
      <span
        className={
          data.rob_is_under_minimum_required
            ? 'text-red fw-medium'
            : data.rob_is_under_optimal
            ? 'text-warning fw-medium'
            : ''
        }
      >
        {numberToStr(data?.calculated_rob)}
      </span>
    </ItemCell>
  ),
  minimum_required_rob: data => {
    const item = data?.item;

    const optimalRob = item?.optimal_rob;
    const minimumRob = item?.minimum_required_rob;
    const isOfficial = item?.is_rob_requirement_official;
    const officialText = isOfficial ? 'Official' : 'Company';

    return (
      <ItemCell data={data} truncate={false}>
        <Row className="flex-nowrap text-nowrap w-100p" noGutters>
          <Col xs={5}>
            {numberToStr(optimalRob)}/{numberToStr(minimumRob)}
          </Col>
          <Col xs={7} className="text-start">
            {minimumRob ? (
              <span
                className={`text-truncate fw-medium ${isOfficial ? 'text-violet' : 'text-warning'}`}
              >
                <span className="px-1">|</span>
                {officialText}
              </span>
            ) : null}
          </Col>
        </Row>
      </ItemCell>
    );
  },
  'last_survey.last_survey_location': data => (
    <ItemCell data={data}>{data?.last_survey_location || '-'}</ItemCell>
  ),
  'last_survey.last_survey_rob_new': data => (
    <ItemCell data={data}>{numberToStr(data?.last_survey_rob_new)} </ItemCell>
  ),
  'last_survey.last_survey_rob_used': data => (
    <ItemCell data={data}>{numberToStr(data?.last_survey_rob_used)}</ItemCell>
  ),
  'last_survey.last_survey_rob_in_repair': data => (
    <ItemCell data={data}>{numberToStr(data?.last_survey_rob_in_repair)}</ItemCell>
  ),
  ...lastSurveyCommonRows,
  ...lastActionCommonRows
};
