import TYPES from './types';
import { runningHoursInputsAreValid } from 'captain-reports/templates/system-running-hours/helpers';

import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';
import { strToNumber } from 'common/utils/numbers';

export const setRunningHours = data => dispatch =>
  dispatch({ type: TYPES.SET_RUNNING_HOURS, payload: data });

export const setDataValue = data => dispatch =>
  dispatch({ type: TYPES.SET_DATA_VALUE, payload: data });

export const setAssignmentValue = data => dispatch =>
  dispatch({ type: TYPES.SET_ASSIGNMENT_VALUE, payload: data });

export const setErrorValue = data => dispatch =>
  dispatch({ type: TYPES.SET_ERROR_VALUE, payload: data });

export const setEditting = data => dispatch =>
  dispatch({ type: TYPES.SET_IS_EDITTING, payload: data });

export const resetState = () => dispatch => dispatch({ type: TYPES.RESET_STATE });

export const setRunningHoursRebuildComments = data => dispatch =>
  dispatch({ type: TYPES.SET_RUNNING_HOURS_REBUILD_COMMENTS, payload: data });

export const setRunningHoursInputsAreValid = () => (dispatch, getState) => {
  const valid = runningHoursInputsAreValid(
    getState().systemRunningHours.data.vessel_systems.map(system =>
      strToNumber(system.vessel_system_total_running_hours?.value)
    )
  );

  dispatch(setErrorValue({ vessel_systems: valid ? '' : REQUIRED_FIELD_MESSAGE }));

  return valid;
};
