import {
  PlannedRouteWaypointSelectOptions,
  PlannedRouteWaypointsQueryOptions
} from '@/common/types/planned-routes';
import { useQuery } from '@tanstack/react-query';
import { getPlannedRouteWaypoints } from './api';
import { PLANNED_ROUTE_WAYPOINTS } from './query-keys';

export const usePlannedRouteWaypointsQuery = ({
  id,
  enabled = false
}: PlannedRouteWaypointsQueryOptions) => {
  return useQuery({
    queryKey: [id, PLANNED_ROUTE_WAYPOINTS],
    queryFn: () => getPlannedRouteWaypoints({ id }),
    enabled: !!id && enabled,
    retry: false
  });
};

// selectors
export const useSelectPlannedRouteWaypoint = ({
  id,
  waypoint_id
}: PlannedRouteWaypointSelectOptions) => {
  return useQuery({
    queryKey: [id, PLANNED_ROUTE_WAYPOINTS],
    queryFn: () => getPlannedRouteWaypoints({ id }),
    enabled: false,
    retry: false,
    select: data => (waypoint_id ? data?.find(w => w.id === waypoint_id) : null),
    staleTime: 5 * 60 * 1000 //(5 minutes)
  });
};
