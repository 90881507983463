import React, { useState } from 'react';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';
import Tooltip from 'common/components/general/Tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

import {
  selectFieldValue,
  selectPreviousCaptainReportFirstRevCounter
} from 'captain-reports/store/selectors';
import { numberToStr, trimFloatNumber } from 'common/utils/numbers';

import useValue from 'captain-reports/utils/useValue';

const EngineRpm = ({ tooltip, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const engine_rpm = useSelector(state => selectFieldValue(state, 'engine_rpm'));
  const steaming_time = useValue({ key: 'steaming_time' });
  const curRevCounter = useValue({
    key: 'me_rev_counter',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [rpm, setRpm] = useState(engine_rpm);

  const { avoidRender, tooltipID } = useTooltipID('field-label-reports-main-engine');

  const lastReportRevCounter = useSelector(selectPreviousCaptainReportFirstRevCounter) || 0;

  useUpdateEffect(() => {
    const calcRPM =
      steaming_time && curRevCounter
        ? trimFloatNumber((curRevCounter - lastReportRevCounter) / steaming_time)
        : null;

    setRpm(calcRPM);
  }, [curRevCounter, steaming_time, lastReportRevCounter]);

  if (avoidRender) return null;

  return (
    <div className="template-field mb-0 p-0">
      <div className="form-label">
        <span id={tooltipID}>RPM</span>
      </div>

      <div className="d-flex fw-bold fs-12 lh-1 align-items-center justify-content-end height-32">
        {numberToStr(rpm)}
      </div>

      {tooltip ? <Tooltip target={tooltipID}>{tooltip}</Tooltip> : null}
    </div>
  );
};

export default EngineRpm;
