import ModularTable from 'common/components/ModularTable';
import { selectTableList } from 'common/components/table/store/selectors';
import { selectCurrenciesWithoutBase } from '@/common/components/mga/store/selectors-ts';
import { numberToStr } from '@/common/utils/numbers';
import { useAppSelector } from '@/store/hooks';

const ItemsTable = ({ header, ...table }) => {
  const state = useAppSelector(state => selectTableList(state, table.label));
  const currencies = useAppSelector(selectCurrenciesWithoutBase);

  const handleTableSort = ({ sorting }) => {
    table.refetchData({ sorting }, { fetchOnly: table.label, sorting });
  };

  return (
    <ModularTable
      mainHeaderComponent={
        <div className="text-white bg-primary fs-10 fw-bold rounded-sm px-1 mt-1 cmx-4">
          {header}
        </div>
      }
      loader
      hideTableSearch
      hideTopPagination
      hideTableFilters
      handleTableSort={handleTableSort}
      {...table}
      rows={{
        ...currencies.reduce(
          (acc, curr) => ({
            ...acc,
            [`price_per_unit_${curr?.id}`]: data => {
              const shouldShowValue = data?.currency_id === curr?.id;
              if (!shouldShowValue) return null;

              return <div>{numberToStr(data?.local_price_per_unit, 2, 2)}</div>;
            },
            [`total_price_${curr?.id}`]: data => {
              const shouldShowValue = data?.currency_id === curr?.id;
              if (!shouldShowValue) return null;

              return <div>{numberToStr(data?.local_total_price, 2, 2)}</div>;
            }
          }),
          {}
        )
      }}
      state={state}
    />
  );
};

export default ItemsTable;
