import { useEffect } from 'react';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import {
  selectMainEngineTotalPower,
  selectGeneratorsRunningHoursTotal,
  selectGeneratorLoadTotal,
  selectCaptainReportTemplateType
} from 'captain-reports/store/selectors';
import { numberToStr } from 'common/utils/numbers';
import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import * as reportActions from 'captain-reports/store/actions';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import useReportTimeDiff from 'captain-reports/utils/useReportTimeDiff';

const LubricantFeedRateField = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const captainReportTemplateType = useSelector(selectCaptainReportTemplateType);
  const mins_since_last_report = useReportTimeDiff();
  const [setValue] = useActions([reportActions.setValue]);
  const consumptionKey = field.key?.replace('_feed_rate', '_consumption_total');
  const generatorsRunningHoursTotal = useSelector(selectGeneratorsRunningHoursTotal);
  const power = useSelector(selectMainEngineTotalPower);

  const dgPowerTotal = useSelector(selectGeneratorLoadTotal);
  const feedRateNumber = field.key?.match(/\d+/)?.[0] || null;
  const baseNumberKey = feedRateNumber ? `co_${feedRateNumber}_base_number` : null;
  const isGo = field.key.includes('go');
  const isSo = field.key.includes('so');

  const consumption = useValue({
    key: consumptionKey,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const baseNumber = useValue({
    key: baseNumberKey,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const steamingTimeValue = useValue({
    key: 'steaming_time',
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  const steamingTime =
    captainReportTemplateType === 'port_noon' ? mins_since_last_report : steamingTimeValue;

  const value = useValue({
    key: field.key,
    sectionLabel: sectionLabel,
    sectionActiveTab: sectionActiveTab,
    subGroup: subGroup,
    subGroupIndex: subGroupIndex
  });

  useEffect(() => {
    let calculation = null;

    if (isGo) {
      if (consumption && dgPowerTotal && generatorsRunningHoursTotal) {
        const total = consumption * 0.92 * 1000;
        const runningHoursTotal = generatorsRunningHoursTotal / 60;

        // go_consumption_total * 0.92 * 1000 / sum(dg_running_minutes / 60) / sum(dg_power) || sum means all DGs
        calculation = numeral(total)
          .divide(runningHoursTotal)
          .divide(dgPowerTotal)
          ?._value?.toFixed(2);
      }
    } else if (isSo) {
      if (consumption && power && steamingTime) {
        const total = consumption * 0.9 * 1000;
        const steamingHours = steamingTime / 60;

        // so_consumption_total * 0.90 * 1000 / (steaming_time / 60) / (me_shaft_power * 0.985 || me_effective_power || me_indicated_power)
        calculation = numeral(total).divide(steamingHours).divide(power)?._value?.toFixed(2);
      }
    } else {
      if (power && steamingTime && consumption) {
        // If co_[each]_base_number >= 55 then 0.94 else 0.92
        let multiplier = +baseNumber >= 55 ? 0.94 : 0.92;

        let consumptionInGrams = consumption * multiplier * 1000;
        let steamingTimeInHours = steamingTime / 60;

        // co_[each]_consumption_total * 0.92 * 1000 / (steaming_time / 60) /  (me_shaft_power * 0.985 || me_effective_power || me_indicated_power)
        calculation = numeral(consumptionInGrams)
          .divide(steamingTimeInHours)
          .divide(power)
          ?._value?.toFixed(2);
      }
    }

    setValue({
      key: field.key,
      value: calculation || calculation === 0 ? +calculation : null,
      sectionLabel: sectionLabel,
      sectionActiveTab: sectionActiveTab,
      subGroup: subGroup,
      subGroupIndex: subGroupIndex
    });
  }, [steamingTime, power, consumption, baseNumber, generatorsRunningHoursTotal, dgPowerTotal]);

  return (
    <div
      className={`fw-bold d-flex align-items-center fs-12 ${
        field.key === 'co_3_feed_rate' ? 'cpe-2' : ''
      } ${isSo ? '' : 'justify-content-end'} height-24`}
    >
      <ValidationWrapper
        field={{
          key: field.key,
          label: isSo ? field.label : '',
          subLabel: isSo ? field.subLabel : ''
        }}
        value={value}
        sectionLabel={sectionLabel}
        errorClassName="align-items-end"
        sectionActiveTab={sectionActiveTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
        right={isSo ? false : true}
      >
        {numberToStr(value)}
      </ValidationWrapper>
    </div>
  );
};

export default LubricantFeedRateField;
