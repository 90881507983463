import React from 'react';
import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';
import arrow from 'assets/svg/common/arrow-right-long.svg';
import EntityLabel from 'common/components/labels/EntityLabel';

const Department = props => {
  const { properties } = props;

  const name = _get(properties, "attributes['department.name']") || 'None';
  const color = _get(properties, "attributes['department.color']") || 'None';

  const oldName = _get(properties, "old['department.name']") || 'None';
  const oldColor = _get(properties, "old['department.color']") || 'None';

  return (
    <div className="d-flex align-items-center">
      <EntityLabel
        data-testid="job-activity-main-department-before"
        type="department"
        color={oldColor}
      >
        {oldName ? oldName : 'None'}
      </EntityLabel>
      <SvgRender className="text-violet" src={arrow} style={{ width: 32, height: 17 }} />
      <EntityLabel data-testid="job-activity-main-department-after" type="department" color={color}>
        {name}
      </EntityLabel>
    </div>
  );
};

export default Department;
