import React from 'react';
import { Col } from 'reactstrap';
import Field from './Field';
import { useSelector } from 'react-redux';
import { selectFieldVisibility, selectFieldTemplateTooltip } from 'captain-reports/store/selectors';

const VisibleField = ({ field, showCol = false }) => {
  const visible = useSelector(state => selectFieldVisibility(state, field.key));

  const tooltip = useSelector(selectFieldTemplateTooltip(field.key));

  if (!visible) {
    return showCol ? (
      <Col className={`template-field ${field.className}`} {...field.width}></Col>
    ) : null;
  }

  return (
    <Field className={field.className} width={field.width}>
      <field.render field={field} tooltip={tooltip ? tooltip : ''} />
    </Field>
  );
};

export default VisibleField;
