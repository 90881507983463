export const getMgaDepletionMethodInfo = label => {
  switch (label) {
    case 'none':
      return { label, name: 'NONE', subTitle: '* None - Do not track inventory' };
    case 'avg':
      return {
        label,
        name: 'AVERAGE',
        subTitle:
          'Average - When adding an item to the inventory, Orca automatically calculates the average price of all items (same item from the library, that has been supplied many times). When removing items from the inventory, Orca selects the above average price.'
      };
    case 'fifo':
      return {
        label,
        name: 'FIFO',
        subTitle:
          'FIFO - First In First Out. When removing items from the inventory, Orca selects the price of the oldest non depleted item added.'
      };
    default:
      return { name: null, subTitle: null };
  }
};
