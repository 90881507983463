import AttachmentForm from './AttachmentForm';
import AttachmentPreview from './AttachmentPreview';

const Attachment = ({ attachment, setActiveEditId, activeEditId, type }) => {
  return (
    <>
      {activeEditId === attachment.id ? (
        <AttachmentForm
          attachment={attachment}
          activeEditId={activeEditId}
          onClose={() => setActiveEditId(null)}
          type={type}
        />
      ) : (
        <AttachmentPreview
          attachment={attachment}
          type={type}
          onClose={() => setActiveEditId(null)}
          onEdit={() => setActiveEditId(attachment.id)}
        />
      )}
    </>
  );
};
export default Attachment;
