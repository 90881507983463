import Tooltip from '@/ts-common/components/general/Tooltip';
import { isFileSynced } from '@/api/files/api';
import { Attachment } from '@/ts-common/types/files';
import { fileSize } from '@/ts-common/utils/files';
import { FC, useMemo } from 'react';
import { numberToStr } from '@/ts-common/utils/numbers';

type FileSyncTooltipProps = {
  file: Attachment;
  tooltipID: string;
};

const FileSyncTooltip: FC<FileSyncTooltipProps> = ({ file, tooltipID }) => {
  const { sync_progress, is_synced, is_onboard } = isFileSynced(file);

  const percentage = useMemo(() => {
    if (sync_progress && file.size) {
      const value = (100 * sync_progress) / file.size;

      return numberToStr(value, 2);
    }

    return 0;
  }, [file.size, sync_progress]);

  return (
    <Tooltip fade={false} target={tooltipID} innerClassName="fs-12 tooltip-inner-max-content">
      <div>
        {file.filename}
        {file.size ? ` | ${fileSize(file.size)}` : ''}
      </div>
      {!is_synced ? (
        <div className="pt-1 mt-1 border-top">
          {`Syncing from ${is_onboard ? 'office' : 'onboard'} still in progress`}
          {percentage ? (
            <>
              <span> | </span>
              <strong>{percentage} %</strong>
            </>
          ) : null}
        </div>
      ) : null}
    </Tooltip>
  );
};

export default FileSyncTooltip;
