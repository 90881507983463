import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { useCallback, useState } from 'react';
import { constructColumns } from 'common/components/table/utils/modular-table/helpers';
import LinkedOriginIcon from 'common/components/findings/report/components/LinkedOriginIcon';
import LinkedStatus from 'common/components/findings/report/components/LinkedStatus';
import LinkedDate from 'common/components/findings/report/components/LinkedDate';

import ModularTable, { useModularTable } from 'common/components/ModularTable';

import { DrawerState } from 'common/entities/drawer/DrawerTypes';

import edit from 'common/assets/svg/actions/edit.svg';

import { selectEventId } from 'events/store/events/selectors';
import {
  getEventAudits,
  unlinkEventFindings,
  getAuditFindings
} from 'events/store/event-modules/audits/actions';
import { selectAreAuditActionsLocked } from 'events/store/event-modules/audits/selectors';
import { deleteFinding } from 'common/components/findings/store/actions';

import LinkedFindingsDescription from 'common/components/findings/report/components/LinkedFindingsDescription';

import { rows, columns } from 'common/components/findings/report/tableConfig';
import bin from 'common/assets/svg/common/bin.svg';
import unlink from 'common/assets/svg/common/unlink.svg';

const FindingsTable = ({
  drawer,
  audit,
  active,
  navigation,
  setActive,
  setNavigation,
  setAuditId,
  setEventAuditId
}) => {
  const dispatch = useDispatch();
  const [findingsData, setFindingsData] = useState([]);
  const [sorting, setSorting] = useState({});

  const eventId = useSelector(selectEventId);
  const areAuditActionsLocked = useSelector(selectAreAuditActionsLocked);

  const onDeleteFinding = async data => {
    try {
      await dispatch(deleteFinding({ id: data.id })).unwrap();

      await dispatch(getEventAudits({ event_id: eventId })).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const onUnlinkFinding = async id => {
    if (!id) return;

    try {
      await dispatch(unlinkEventFindings({ eventId, eventAuditId: audit?.id, id })).unwrap();
      table.refetchData();
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = useCallback(
    async params => {
      if (!audit?.id || !eventId) return;

      try {
        const res = await dispatch(
          getAuditFindings({ ...params, eventId, auditId: audit?.id })
        ).unwrap();

        setSorting(params.sorting);
        setFindingsData(res);
      } catch (error) {
        console.error(error);
      }
    },
    [audit?.id, dispatch, eventId]
  );

  const table = useModularTable({
    label: 'event_audit_findings',
    requestTableListFrom: fetchData,
    defaultRequestParams: { visible: false, paging: false, sorting: true, filters: false },
    config: () => ({
      columns: constructColumns(columns, [], table.label)
    })
  });

  const onEditFinding = data => {
    setActive(data);
    setAuditId(data.origin_audit?.id);
    setEventAuditId(data.origin_audit?.event_audit_id);
    drawer.open();
  };

  useEffect(() => {
    const findingIndex = findingsData?.findIndex(field => field.id === active?.id);

    if (findingIndex !== -1) {
      setNavigation({
        next_id: findingsData?.[findingIndex + 1]?.id,
        previous_id: findingsData?.[findingIndex - 1]?.id
      });
    } else {
      setNavigation({ next_id: null, previous_id: null });
    }

    if (navigation.selected_id) {
      setActive(findingsData.find(e => e.id === navigation.selected_id));
    }
  }, [active?.id, setNavigation, findingsData, navigation.selected_id, setActive]);

  if (!findingsData?.length) return null;

  return (
    <Container className="mt-2">
      <ModularTable
        style="default"
        hideTopPagination
        hideTableFilters
        hideTableSearch
        loader
        handleTableSort={({ sorting }) => table.refetchData({ sorting })}
        rows={{
          ...rows,
          origin_icon: data => <LinkedOriginIcon data={data} isEvent />,
          status: data => <LinkedStatus data={data} isEvent />,
          linked_with_date: data => <LinkedDate data={data} isEvent />,
          description: data => <LinkedFindingsDescription data={data} isEvent />,
          actions: data => ({
            options: !areAuditActionsLocked
              ? [
                  {
                    label: 'Edit',
                    icon: edit,
                    onClick: () => onEditFinding(data)
                  },
                  {
                    label: 'Unlink Finding',
                    icon: unlink,
                    preventionModal: true,
                    modalProps: {
                      onAccept: () => onUnlinkFinding(data?.id),
                      header: 'Unlink',
                      actionText: 'UNLINK',
                      action: 'unlink',
                      actionHoverColor: 'delete',
                      body: (
                        <div className="text-center">
                          You are about to unlink this finding. <br />
                          Are you sure you want to continue?
                        </div>
                      )
                    }
                  },
                  ...(!data?.is_from_submission
                    ? [
                        {
                          label: 'Delete',
                          icon: bin,
                          preventionModal: true,
                          modalProps: {
                            onAccept: () => onDeleteFinding(data),
                            header: 'Delete Finding',
                            actionText: 'Delete',
                            action: 'Delete',
                            actionHoverColor: 'delete',
                            body: (
                              <div className="text-center">
                                You are about to delete this finding. <br />
                                Are you sure you want to continue?
                              </div>
                            )
                          }
                        }
                      ]
                    : [])
                ]
              : []
          })
        }}
        {...table}
        state={{ ...table.state, sorting, data: findingsData }}
      />
    </Container>
  );
};

const Container = styled.div`
  .app-table--head-inner {
    .sticky {
      background-color: ${variables.antiFlashWhite1} !important;
    }
  }
`;

FindingsTable.propTypes = {
  drawer: DrawerState,
  audit: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  findings: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  setActive: PropTypes.func,
  setAuditId: PropTypes.func,
  setEventAuditId: PropTypes.func
};

export default FindingsTable;
