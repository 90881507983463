import { createSelector } from 'reselect';
import { underApprovalSuppliersHaveFilledApprovedQuantities } from 'common/components/purchasing/requisition/store/selectors';
import entityActions from 'common/components/review-process/entity-actions';

const getKey = (_, key) => key;

const selectReviewProcess = state => state.reviewProcess;

const selectReviewProcessEntities = createSelector(
  selectReviewProcess,
  reducer => reducer?.entities
);

const selectReviewProcessEntity = createSelector(
  selectReviewProcessEntities,
  getKey,
  (entities, entityId) => entities?.[entityId]
);

export const selectReviewProcessId = createSelector(
  selectReviewProcessEntity,
  reducer => reducer?.id
);

export const selectReviewEntityId = createSelector(
  selectReviewProcessEntity,
  reducer => reducer?.entityId
);

export const selectReviewProcessName = createSelector(
  selectReviewProcessEntity,
  reducer => reducer?.description
);

export const selectReviewProcessSteps = createSelector(
  selectReviewProcessEntity,
  reducer => reducer?.steps || []
);

export const selectReviewProcessStepsLength = createSelector(
  selectReviewProcessEntity,
  reducer => reducer?.steps?.length
);

export const selectReviewProcessMandatorySteps = createSelector(selectReviewProcessSteps, steps =>
  steps?.filter(step => step.is_required)
);

export const selectReviewProcessMandatoryStepsLength = createSelector(
  selectReviewProcessSteps,
  steps => steps?.filter(step => step.is_required)?.length
);

export const selectReviewProcessMandatoryStepsFilledLength = createSelector(
  selectReviewProcessMandatorySteps,
  steps =>
    steps
      .map(step => step.substeps.every(subStep => subStep.completed_by))
      .filter(substep => substep)?.length
);

export const selectReviewProcessStepsFilledLength = createSelector(
  selectReviewProcessSteps,
  steps =>
    steps
      .map(step => step.substeps.every(subStep => subStep.completed_by))
      .filter(substep => substep)?.length
);

export const shouldCreateReviewProcess = createSelector(
  selectReviewProcessEntity,
  reducer => reducer?.isEnabled
);

export const canUserAddMoreDynamicSteps = createSelector(
  selectReviewProcessId,
  selectReviewProcessMandatoryStepsLength,
  selectReviewProcessMandatoryStepsFilledLength,
  (reviewProcessId, mandatorySteps, mandatoryStepsCompleted) =>
    reviewProcessId && mandatorySteps === mandatoryStepsCompleted
);

export const isReviewProcessCompleted = createSelector(
  selectReviewProcessId,
  selectReviewProcessStepsLength,
  selectReviewProcessStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, steps, stepsCompleted, canCreate) =>
    (!reviewProcessId && !canCreate) ||
    (reviewProcessId && steps === stepsCompleted) ||
    (reviewProcessId && !steps)
);

export const isReviewProcessRejected = (state, entityId) =>
  selectReviewProcessEntity(state, entityId)?.is_rejected;

export const reviewProcessHasSomeCompletedSteps = createSelector(
  selectReviewProcessId,
  selectReviewProcessStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, stepsCompleted) => (reviewProcessId && stepsCompleted ? true : false)
);

export const isMandatoryStepsCompleted = createSelector(
  selectReviewProcessId,
  selectReviewProcessMandatoryStepsLength,
  selectReviewProcessMandatoryStepsFilledLength,
  shouldCreateReviewProcess,
  (reviewProcessId, mandatorySteps, mandatoryStepsCompleted, canCreate) =>
    (!reviewProcessId && !canCreate) ||
    (reviewProcessId && mandatorySteps === mandatoryStepsCompleted) ||
    (reviewProcessId && !mandatorySteps)
);

export const selectReviewProcessDrawerIsOpen = createSelector(
  selectReviewProcess,
  reducer => reducer?.isDrawerOpen
);

export const selectActiveEntityId = createSelector(
  selectReviewProcess,
  reducer => reducer?.activeEntityId
);

export const selectReviewProcessFormDrawerIsOpen = createSelector(
  selectReviewProcess,
  reducer => reducer?.isFormDrawerOpen
);

export const getPreventedApprovalInfoMessage = (state, entityId) => {
  const entity = selectReviewProcessEntity(state, entityId);

  if (
    entity?.review_process_action === entityActions.purchasingRequisitionApproval.label &&
    !underApprovalSuppliersHaveFilledApprovedQuantities(state)
  ) {
    return 'Please fill in Approved Quantities in the Approved QTY column';
  }

  return null;
};

export const selectReviewProcessNotes = state => selectReviewProcess(state)?.notes;
