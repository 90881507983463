import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import { selectCaptainReportLubricantTotals } from 'captain-reports/store/selectors';

import useValue from 'captain-reports/utils/useValue';
import * as reportActions from 'captain-reports/store/actions';
import _debounce from 'lodash/debounce';
import { numberToStr } from 'common/utils/numbers';

const LubricantTotalAutocomplete = ({
  field,
  sectionLabel,
  sectionActiveTab,
  subGroup,
  subGroupIndex
}) => {
  const [setValue] = useActions([reportActions.setValue]);
  const totalLubricants = useSelector(selectCaptainReportLubricantTotals);

  const value = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const autocompleteValue = useValue({
    key: field.autocompleteValues[0].key
  });

  const debouncedAutoComplete = _debounce(() => {
    let autocompleteKey = field.autocompleteValues[0].key;
    let total = totalLubricants[`total_${autocompleteKey}`]
      ? totalLubricants[`total_${autocompleteKey}`]
      : 0;
    const currentValue = autocompleteValue ? autocompleteValue : 0;

    let result = currentValue + total;

    if (result !== value) {
      setValue({ key: field.key, value: result });
    }
  }, 600);

  useEffect(() => {
    debouncedAutoComplete();
  }, [autocompleteValue]);

  return (
    <div
      className={`d-flex align-items-center justify-content-end height-24 fw-bold fs-12 lh-1 pt-1 ${
        !field.label ? 'mt-1' : ''
      }`}
    >
      {numberToStr(value)}
    </div>
  );
};

export default LubricantTotalAutocomplete;
