import React from 'react';

const ProfileMain = ({ className, children, withSidebar }) => {
  return (
    <div className={`page-profile--main ${withSidebar ? ' with-sidebar' : ''} ${(className = '')}`}>
      {children}
    </div>
  );
};

export default ProfileMain;
