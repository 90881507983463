import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import {
  defaultGraphStyles,
  defaultGraphOptions,
  defaultYAxisOptions,
  defaultXAxisOptions
} from 'common/components/graph/base/helpers/_defaultOptions';

const checkIfStrContainsCapitalLetters = str =>
  str.split('').filter(char => char !== '-' && char === char.toUpperCase()).length;

const useD3Config = (styles, schema, axesConfig) => {
  const getGraphOption = option => _get(schema, option, _get(defaultGraphOptions, option));
  const getGraphStyle = style => _get(styles, style, _get(defaultGraphStyles, style));

  const getYAxisOption = (option, axisKey) => {
    if (_isArray(_get(axesConfig, 'y')) && axisKey) {
      const axisWithSameKey = axesConfig.y.find(yAxis => yAxis.key === axisKey);
      if (axisWithSameKey) return _get(axisWithSameKey, option);
    }

    return _get(axesConfig, `y.${option}`, _get(defaultYAxisOptions, option));
  };

  const getXAxisOption = option => {
    return _get(axesConfig, `x.${option}`, _get(defaultXAxisOptions, option));
  };

  // The SVG attributes are only lowercase so that's how we are going to differentiate them from React props.
  // Below we filter only the lowercase-only keys and reassemble them into a new object
  const graphSVGAttributes = Object.keys(styles)
    .filter(key => !checkIfStrContainsCapitalLetters(key))
    .map(key => ({ key, value: styles[key] }));

  return { getGraphOption, getGraphStyle, getYAxisOption, getXAxisOption, graphSVGAttributes };
};

export default useD3Config;
