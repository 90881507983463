import React from 'react';

import Section from '../../components/Section';
import GroupFields from '../../components/GroupFields';
import ExpandedContract from 'common/components/crew/ExpandedContract';
import { isContractExtendedPeriodReduced } from './helpers';
import contracts from 'common/assets/svg/crew/contracts.svg';

import moment from 'moment';

const Details = ({ contract }) => {
  const { contract_from_date, initial_contract_to_date, contract_to_date, sign_off_reason } =
    contract;

  const isExtPeriodReduced = isContractExtendedPeriodReduced(
    contract_to_date,
    initial_contract_to_date
  );

  return (
    <Section className="h-100p" header={{ icon: contracts, label: 'Contract Details' }}>
      <GroupFields
        left={{
          data: { contract_from_date },
          field: {
            label: 'STARTS',
            type: 'date',
            key: 'contract_from_date',
            dateFormat: 'DD MMM YYYY'
          }
        }}
        right={{
          data: { initial_contract_to_date },
          field: {
            label: 'ENDS',
            type: 'date',
            key: 'initial_contract_to_date',
            dateFormat: 'DD MMM YYYY'
          }
        }}
      />

      <GroupFields
        right={{
          data: { sign_off_reason },
          field: {
            label: 'CONTRACT ENDING REASON',
            key: 'sign_off_reason.name'
          }
        }}
      />

      {contract?.extensions?.length ? (
        <div className={`contract-ending-date preview${isExtPeriodReduced ? ' reduced' : ''}`}>
          <ExpandedContract reduced={isExtPeriodReduced} />
          {contract.extensions.map((extension, index) => (
            <div
              key={index}
              className={
                contract.extensions.length > 1
                  ? contract.extensions.length === index + 1
                    ? 'cpt-12 border-top'
                    : 'cpb-12'
                  : ''
              }
            >
              <GroupFields
                className={`text-nowrap ${
                  contract.extensions.length === index + 1 ? '' : 'disabled'
                }`}
                left={{
                  data: {
                    date: extension.date
                      ? moment(extension.date, 'YYYY-MM-DD').format('DD MMM YYYY')
                      : '-'
                  },
                  field: {
                    label: `NEW CONTRACT ENDING DATE${index !== 0 ? ` #${index + 1}` : ''}`,
                    key: 'date'
                  }
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </Section>
  );
};

export default Details;
