import React from 'react';

export const Layout = ({ className, children }) => (
  <div className={`template-layout ${className}`}>{children}</div>
);

export const Header = ({ header }) =>
  header ? <div className={`template-layout__header`}>{header}</div> : null;

export const CollapsableHeader = ({ header }) => {
  if (header) {
    return (
      <div className={`template-layout__header template-layout__header-collapsable`}>{header}</div>
    );
  } else {
    return null;
  }
};

export const Fields = ({ className, children }) => (
  <div className={`template-layout__fields ${className || ''}`}>{children}</div>
);

export const FieldsHeader = ({
  header,
  headerComponent,
  className,
  customHeaderClassName = '',
  subLabel = ''
}) =>
  header || headerComponent ? (
    <div
      className={`${
        customHeaderClassName
          ? customHeaderClassName
          : 'template-layout__fields-header fs-10 text-violet mb-1 fw-bold d-flex align-items-center justify-content-between'
      } ${className ? className : ''}`}
    >
      {!header && !subLabel ? null : (
        <div className="d-flex align-items-center">
          {header}
          {subLabel ? <div className="text-violet cms-2">{subLabel}</div> : null}
        </div>
      )}

      {headerComponent}
    </div>
  ) : null;
