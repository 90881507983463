import React from 'react';
import PropTypes from 'prop-types';
import DeliveryReportSent from './DeliveryReportSent';

import warning from 'common/assets/svg/common/warning-empty.svg';
import SvgRender from 'common/components/general/SvgRender';

const DeliveryReportStatus = ({ status }) => {
  return status === 'for_correction' ? (
    <div className="d-flex align-items-center text-danger">
      <SvgRender src={warning} style={{ width: 14, height: 14 }} />
      <span className="fs-12 lh-1 ps-1">For Corrections</span>
    </div>
  ) : status === 'submitted' ? (
    <DeliveryReportSent className="fs-12" />
  ) : null;
};

DeliveryReportStatus.propTypes = {
  status: PropTypes.oneOf(['for_correction', 'submitted'])
};

export default DeliveryReportStatus;
