import { useDefaultOptionsQuery } from '@/ts-common/components/form/inputs/select/api/queries';
import { OptionsRequestParams } from '@/ts-common/components/form/inputs/select/types';

const useFilterQuerySelector = <Option extends { id: number; [key: string]: any }>(
  value: number[] | string[] | null,
  memoizedRequestParams: OptionsRequestParams,
  identifier = 'id'
) => {
  const defaultOptions = useDefaultOptionsQuery(memoizedRequestParams);
  const data = defaultOptions.data as Option[] | undefined;

  const asyncOptions = useDefaultOptionsQuery({
    path: memoizedRequestParams.path,
    params: getCollectionFilterRequestParams(value, memoizedRequestParams, identifier),
    enabled:
      !!value &&
      value.length > 0 &&
      defaultOptions.isFetched &&
      !value.every(
        v => !!data?.find((opt: Option) => opt[identifier] === (identifier === 'id' ? +v : v))
      )
  });

  return asyncOptions.data || value;
};

const getCollectionFilterRequestParams = (
  value: number[] | string[] | null,
  memoizedRequestParams: OptionsRequestParams,
  identifier: string
) => {
  if (!value || !value.length) return {};

  if (memoizedRequestParams.params?.paging) {
    return {
      [identifier]: value,
      ...memoizedRequestParams.params,
      paging: { current_page: 1, per_page: 100 }
    };
  }

  return { [identifier]: value, ...(memoizedRequestParams.params || {}) };
};

export default useFilterQuerySelector;
