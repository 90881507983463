import React from 'react';
import { useSelector } from 'react-redux';

import Select from 'common/components/form/inputs/Select';
import CircledButton from 'common/components/buttons/CircledButton';
import ShadowBox from 'common/components/general/ShadowBox';

import { Row, Col } from 'reactstrap';
import { getInitialAsyncValues, getAsyncOptions } from 'utils/helpers';
import { Option, SingleValue } from 'common/components/selectors/_formSelectComponents';
import { selectJobId } from 'common/components/jobs/_base/store/selectors';
import FormInformation from './FormInformation';

export const constructParams = data => {
  const { isPms, vesselIds, formOrigin, isForVessel } = data;

  if (isPms) {
    return { visibility: 'pms_jobs', vessel_ids: vesselIds };
  } else {
    const params = { visibility: formOrigin };

    if (formOrigin !== 'pms_jobs') {
      if (isForVessel) {
        params.vessel_ids = vesselIds;
      } else {
        if (isForVessel) {
          params.vessel_ids = vesselIds;
        } else {
          params.office_or_for_vessel_ids = vesselIds;
        }
      }
    }
    return params;
  }
};

const AddNewForm = ({
  onCreate,
  onCancel,
  excludeFormIds,
  isPms,
  vesselIds,
  isForVessel,
  formOrigin = 'jobs'
}) => {
  const jobId = useSelector(selectJobId);
  const extraParams = constructParams({ isPms, vesselIds, formOrigin, isForVessel });

  return (
    <ShadowBox flat className="ps-3 pt-2 pb-1 mb-1" color="light-4">
      <Row className="align-items-center" noGutters>
        <Col xs={11}>
          <Select
            isAsync
            invisible
            label={<FormInformation isForVessel={isForVessel || isPms} />}
            defaultOptions={() => getInitialAsyncValues('forms', null, true, extraParams)}
            loadOptions={search => getAsyncOptions(search, 'forms', extraParams)}
            onChange={form => onCreate(form)}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            defaultOptionsTriggerChange={jobId || vesselIds}
            filterOption={opt => !excludeFormIds.includes(opt.value)}
            placeholder="Select form type"
            components={{ Option, SingleValue }}
          />
        </Col>
        <Col xs="auto" className="ms-auto mb-1 me-n1">
          <CircledButton type="close" onClick={onCancel} />
        </Col>
      </Row>
    </ShadowBox>
  );
};

export default AddNewForm;
