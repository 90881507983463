import Select from 'common/components/form/inputs/Select';

const DropdownInput = ({ options, disabled, onChange, error, value }) => {
  return (
    <Select
      value={value}
      options={options.map(option => ({ value: option }))}
      onChange={onChange}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.value}
      className="mb-0"
      placeholder="Select"
      error={error}
      disabled={disabled}
    />
  );
};

export default DropdownInput;
