import React from 'react';

import _get from 'lodash/get';
import SvgRender from 'common/components/general/SvgRender';
import CopyText from 'common/components/general/CopyText';
import copy from 'common/assets/svg/actions/copy.svg';
import PropTypes from 'prop-types';
import { displayDate } from 'common/utils/dates';

const CopyParticipants = ({ participants }) => {
  const getParticipantInfo = participants => {
    return participants
      .map(participant => {
        let info = `Name: ${_get(participant, 'party.full_name', '') || ''}\n`;
        info += `Participant type: ${_get(participant, 'party.type', '') || ''}\n`;

        if (participant?.party?.details?.nationality?.name)
          info += `Nationality: ${participant?.party?.details?.nationality?.name}\n`;
        if (participant?.party?.details?.rank?.name)
          info += `Rank: ${participant?.party?.details?.rank?.name}\n`;

        if (
          participant?.party?.documents?.passport_number ||
          participant?.party?.crew_passport?.code
        )
          info += `Passport No: ${
            participant?.party?.documents?.passport_number ||
            participant?.party?.crew_passport?.code
          }\n`;
        if (
          participant?.party?.documents?.passport_issued_at ||
          participant?.party?.crew_passport?.issued_at
        )
          info += `Passport Issued: ${displayDate(
            participant?.party?.documents?.passport_issued_at ||
              participant?.party?.crew_passport?.issued_at,
            { time: true }
          )}\n`;
        if (
          participant?.party?.documents?.passport_expires_at ||
          participant?.party?.crew_passport?.expires_at
        )
          info += `Passport Expiration: ${displayDate(
            participant?.party?.documents?.passport_expires_at ||
              participant?.party?.crew_passport?.expires_at,
            { time: true }
          )}\n`;

        if (
          participant?.party?.documents?.seaman_book_number ||
          participant?.party?.crew_seaman_book?.code
        )
          info += `Seaman Book No: ${
            participant?.party?.documents?.seaman_book_number ||
            participant?.party?.crew_seaman_book?.code
          }\n`;
        if (
          participant?.party?.documents?.seaman_book_issued_at ||
          participant?.party?.crew_seaman_book?.issued_at
        )
          info += `Seaman Book Issued: ${displayDate(
            participant?.party?.documents?.seaman_book_issued_at ||
              participant?.party?.crew_seaman_book?.issued_at,
            { time: true }
          )}\n`;
        if (
          participant?.party?.documents?.seaman_book_expires_at ||
          participant?.party?.crew_seaman_book?.expires_at
        )
          info += `Seaman Book Expiration: ${displayDate(
            participant?.party?.documents?.seaman_book_expires_at ||
              participant?.party?.crew_seaman_book?.expires_at,
            { time: true }
          )}\n`;

        return info;
      })
      .join('\n');
  };
  return (
    <div className="position-absolute top-14 left-120">
      <CopyText
        text={getParticipantInfo(participants)}
        customText="Copied Details to Clipboard"
        infoTooltip="Copy Participant Details to Clipboard"
      >
        <SvgRender
          className="pointer-events-none text-primary text-royal-blue"
          src={copy}
          style={{ heihgt: 12, width: 12 }}
        />
      </CopyText>
    </div>
  );
};

CopyParticipants.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      party: PropTypes.shape({
        details: PropTypes.shape({
          rank: PropTypes.shape({
            name: PropTypes.string
          }),
          nationality: PropTypes.shape({
            name: PropTypes.string
          })
        }),
        documents: PropTypes.shape({
          seaman_book_number: PropTypes.number,
          seaman_book_issued_at: PropTypes.string,
          seaman_book_expires_at: PropTypes.string,
          passport_number: PropTypes.number,
          passport_issued_at: PropTypes.string,
          passport_expires_at: PropTypes.string
        })
      })
    })
  )
};

export default CopyParticipants;
