import emptyFile from 'common/assets/svg/files/bordered/file-empty.svg';
import SvgRender from 'common/components/general/SvgRender';
import CircledButton from 'common/components/buttons/CircledButton';

const EmptyState = ({ isLocked, emptyText, createText, onCreate }) => {
  return (
    <div className="chapters-tree__empty">
      <div className="d-flex align-items-center position-relative">
        <SvgRender
          className="chapters-tree__empty-icon"
          src={emptyFile}
          style={{ width: 94, height: 94 }}
        />

        {isLocked ? (
          <div className="chapters-tree__empty-text">
            <strong>{emptyText}</strong>
          </div>
        ) : (
          <CircledButton
            type="add"
            className="chapters-tree__empty-action text-primary fw-bold"
            label={createText}
            onClick={onCreate}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyState;
