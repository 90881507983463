import React from 'react';

const LocationLabels = ({ rows, type }) => {
  return (
    <div className={`shifts-table--location ${type === 'daily' ? '' : 'ps-2'}`}>
      {rows.map((shift, i) => {
        const { isAtSea, isDisabled } = shift;
        const isLast = i + 1 === rows.length;

        return (
          <div
            key={i}
            className={`shifts-table--location-container ${isDisabled ? 'opacity-4' : ''} ${
              isLast ? 'shifts-table--location-container__last' : ''
            }`}
          >
            <div
              className={`shifts-table--location-label shifts-table--location-label-${
                isAtSea ? 'sea' : 'port'
              }`}
            >
              {isAtSea ? 'Sea' : 'Port'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocationLabels;
