import OriginIcon from 'common/components/findings/report/components/OriginIcon';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import Priority from 'common/components/general/Priority';
import Information from 'common/components/general/Information';
import { rows } from 'common/components/findings/report/tableConfig';

export const getColumns = (isOnBoard = false) => [
  {
    header: '',
    key: 'name',
    width: 3,
    headerClassName: 'text-violet',
    bodyClassName: 'd-flex justify-content-start cpt-6'
  },
  {
    header: 'Category',
    key: 'category.name',
    truncate: true,
    type: 'string',
    headerClassName: 'text-violet',
    bodyClassName: 'd-flex justify-content-start pt-1',
    width: 2
  },
  {
    header: 'LINKED WITH',
    key: 'linked_with',
    type: 'string',
    headerClassName: 'text-violet',
    bodyClassName: 'd-flex justify-content-start cpt-6',
    width: 4
  },
  {
    header: '',
    key: 'status',
    sort: false,
    bodyClassName: 'd-flex justify-content-start cpt-6',
    width: isOnBoard ? 2 : 1
  },
  {
    header: (
      <div className="d-flex align-items-center flex-nowrap">
        <div className="fs-10 fw-bold">DAYS IN</div>
        <Information
          message={
            <div>
              Here we count:
              <ul className="ps-2 pb-0 mb-0">
                <li>Days since Reported date for unresolved findings.</li>
                <li>Days elapsed between Reported and resolution dates.</li>
              </ul>
            </div>
          }
          tooltipClassname="text-start min-width-fit max-width-412"
          className="ms-1"
          target={`total-hours-header-info`}
          svgStyle={{ height: 12, width: 12 }}
        />
      </div>
    ),
    key: 'days_unresolved',
    type: 'number',
    headerClassName: 'text-violet',
    bodyClassName: 'd-flex justify-content-start text-start pt-1',
    width: 1
  },
  ...(isOnBoard
    ? []
    : [
        {
          header: 'RESOLUTION DATE',
          key: 'resolved_at',
          type: 'number',
          headerClassName: 'text-violet text-nowrap',
          bodyClassName: 'd-flex justify-content-start text-start pt-1',
          width: 1
        }
      ]),
  ...(isOnBoard
    ? []
    : [
        {
          header: 'Actions',
          key: 'actions',
          field: 'actions'
        }
      ])
];

export const getRows = (isOnBoard = false) => {
  const fontSize = isOnBoard ? 'fs-14' : 'fs-12';

  return {
    ...rows,
    linked_with: data => (
      <div className="cmt-4">
        {data?.origin_audit ? (
          <div className="d-flex align-items-center cmb-4">
            <OriginIcon type="event" />

            <TextWithTooltip className={`ms-1 ${fontSize}`}>
              {data?.origin_audit?.event?.name}
            </TextWithTooltip>
          </div>
        ) : null}
        {data?.origin_form_submission ? (
          <div className="d-flex align-items-center cmb-4">
            <OriginIcon type="origin_form" />

            <TextWithTooltip className={`ms-1 ${fontSize}`}>
              {data?.origin_form_submission?.form?.name}

              {data?.origin_form_submission?.form?.revision ? (
                <strong> - {data.origin_form_submission.form.revision}</strong>
              ) : null}
            </TextWithTooltip>
          </div>
        ) : null}
        {data?.form_submissions?.length
          ? data?.form_submissions?.map(form => (
              <div className="d-flex align-items-center cmb-4">
                <OriginIcon type="form" />

                <TextWithTooltip className={`ms-1 d-flex align-items-center ${fontSize}`}>
                  {form?.form?.name}{' '}
                  {form?.form?.revision ? <strong> - {form.form.revision}</strong> : null}
                </TextWithTooltip>
              </div>
            ))
          : null}
        {data?.jobs?.length
          ? data?.jobs?.map(job => (
              <div className="d-flex align-items-center cmb-4">
                <OriginIcon type="jobs" />

                <TextWithTooltip className={`ms-1 ${fontSize}`}>{job?.title}</TextWithTooltip>
              </div>
            ))
          : null}
        {data?.purchasing_requisitions?.length
          ? data?.purchasing_requisitions?.map(requisition => (
              <div className="d-flex align-items-center cmb-4">
                <OriginIcon type="purchasing_requisition" />

                <TextWithTooltip className={`ms-1 ${fontSize}`}>
                  {requisition?.description}
                </TextWithTooltip>
              </div>
            ))
          : null}

        {!data?.is_linked ? (
          <div className="d-flex align-items-center">
            <OriginIcon isLinked={data?.is_linked} isFromVessel={data?.is_from_vessel} />
          </div>
        ) : null}
      </div>
    ),
    name: data => (
      <div className={`d-flex align-items-center cpt-2 ${fontSize}`}>
        <div className="min-width-16 cpt-2 me-1">
          <Priority value={data.importance_id} />
        </div>

        {isOnBoard ? null : <div className="me-1 fw-bold text-nowrap">{data?.id}</div>}

        <TextWithTooltip className="h-auto">{data?.name}</TextWithTooltip>
      </div>
    )
  };
};
