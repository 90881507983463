import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';
import _isNumber from 'lodash/isNumber';
import _isInteger from 'lodash/isInteger';

export const deepArrayEqual = (arr1, arr2, floatingPointCheck) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const arr1value = arr1[i];
    const arr2value = arr2[i];
    const areObjects = _isObject(arr1value) && _isObject(arr2value);
    const areArray = Array.isArray(arr1value) && Array.isArray(arr2value);

    if (
      (areObjects && !deepEqual(arr1value, arr2value, false, floatingPointCheck)) ||
      (!areObjects && !areArray & !checkEquality(arr1value, arr2value, floatingPointCheck)) ||
      (areArray && !deepArrayEqual(arr1value, arr2value, floatingPointCheck))
    ) {
      return false;
    }
  }
  return true;
};

const checkEquality = (val1, val2, floatingPointCheck = 10e-4) => {
  if (_isString(val1) && _isString(val2)) {
    return val1 === val2;
  } else if (_isBoolean(val1) || _isBoolean(val2)) {
    return !!val1 === !!val2;
  } else if (_isNumber(val1) && _isNumber(val2) && (!_isInteger(val1) || !_isInteger(val2))) {
    return Math.abs(val1 - val2) < floatingPointCheck;
  } else {
    return val1 === val2;
  }
};

export const deepEqualMutatedArrayFields = (
  initialObject,
  mutatedObject,
  excludeKeys,
  shouldReturnIndexRowData = false
) => {
  let data = [];
  let fields = {};

  Object.keys(mutatedObject).forEach(key => {
    if (_isArray(mutatedObject[key])) {
      for (let i = 0; i < mutatedObject[key].length; i++) {
        const initialObj = initialObject[key]?.[i] || [];
        const mutatedObj = mutatedObject[key]?.[i] || [];

        if (!deepEqual(initialObj, mutatedObj)) {
          let params = {};

          excludeKeys?.map(e => {
            params[e] = mutatedObj[e];
          });

          data.push({
            ...(shouldReturnIndexRowData
              ? mutatedObj
              : deepEqual(initialObj, mutatedObj, true) || mutatedObj),
            ...params
          });
        }
      }

      fields[key] = data;
    }
  });

  return fields;
};

const deepEqual = (initialObject, mutatedObject, returnDiff = false, floatingPointCheck) => {
  const keys1 = Object.keys(initialObject);
  const keys2 = Object.keys(mutatedObject);
  let fields = {};

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const initialValue = initialObject[key];
    const mutatedValue = mutatedObject[key];
    const areObjects = _isObject(initialValue) && _isObject(mutatedValue);
    const areArray = Array.isArray(initialValue) && Array.isArray(mutatedValue);

    if (
      (areObjects && !deepEqual(initialValue, mutatedValue, false, floatingPointCheck)) ||
      (!areObjects && !areArray & !checkEquality(initialValue, mutatedValue, floatingPointCheck)) ||
      (areArray && !deepArrayEqual(initialValue, mutatedValue, floatingPointCheck))
    ) {
      if (areObjects) {
        console.log('Not Equal: ', key);
        console.log(initialValue);
        console.log(mutatedValue);
      }

      if (returnDiff) {
        fields[key] = mutatedValue;
      } else {
        return false;
      }
    }
  }

  return returnDiff ? fields : true;
};

export default deepEqual;
