import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Input from 'common/components/jobs/_base/inputs/MentionInput';
import * as jobChecklistActions from 'common/components/jobs/_base/modules/checklist/store/actions';
import { useActions } from 'utils/hooks';
import InputSaveActions from 'common/components/jobs/_base/inputs/InputSaveActions';
import CommentsList from './CommentList';
import spinner from 'common/assets/svg/common/spinner.svg';
import SvgRender from 'common/components/general/SvgRender';
import { useSelector } from 'react-redux';
import { selectChecklistOption } from 'common/components/jobs/_base/store/selectors';
import { transformDataForSubmit } from '../helpers';

const Comments = ({ parentRef, onClose, optionId, isOpen, jobId }) => {
  const [getComments, createComment] = useActions([
    jobChecklistActions.getComments,
    jobChecklistActions.createComment
  ]);

  const opt = useSelector(selectChecklistOption(optionId));

  const { comments_count, comments } = opt;

  const [loading, setLoading] = useState(false);

  const [newMessage, setNewMessage] = useState('');
  const [newMentions, setNewMentions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const onInputChange = (e, newValue, newPlainText, mentions) => {
    setNewMentions(mentions);
    setNewMessage(e.target.value);
  };

  const onNewMessage = async () => {
    if (newMessage === '') return;
    setSubmitting(true);
    let params = transformDataForSubmit(newMessage, newMentions);

    const res = await createComment({ job_id: jobId, option: optionId, ...params });
    if (res && res.id) {
      setNewMessage('');
      setNewMentions('');
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (isOpen) {
      const getOptionsComments = async () => {
        setLoading(true);
        await getComments({ job_id: jobId, option: optionId, paging: { per_page: 2 } });
        setLoading(false);
      };

      if (comments?.length === 0) {
        getOptionsComments();
      }
    }
  }, [comments?.length, getComments, isOpen, jobId, optionId]);

  return (
    <Row className="comments-section ps-4">
      {comments_count === 0 ? (
        <Col
          xs={11}
          className={`comments-section--empty position-relative ${submitting ? 'loading' : ''}`}
        >
          <Input parentRef={parentRef} value={newMessage} onChange={onInputChange} />
          <div className="new-comment-actions">
            <InputSaveActions onCancel={onClose} onSave={onNewMessage} />
          </div>
        </Col>
      ) : loading ? (
        <div className="w-100p d-flex align-items-center justify-content-center">
          <SvgRender src={spinner} style={{ width: 20, height: 20 }} />
        </div>
      ) : (
        <Col xs={11} className="h-100p position-relative">
          <CommentsList
            comments_count={comments_count}
            jobId={jobId}
            optionId={optionId}
            parentRef={parentRef}
            data={comments}
          />
        </Col>
      )}
    </Row>
  );
};

export default Comments;
