import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import SvgRender from 'common/components/general/SvgRender';
import close from 'common/assets/svg/actions/close.svg';

const ClearButton = ({ onClick, ...rest }) => {
  return (
    <Button
      size="sm"
      color="grey"
      className="d-flex align-items-center justify-content-center text-nowrap fs-12 fw-medium cpt-4 cpb-4 pe-1 ps-1 text-primary"
      onClick={onClick}
      {...rest}
    >
      <SvgRender src={close} style={{ width: 8, height: 8 }} />
      <div className="cps-6">Clear</div>
    </Button>
  );
};

ClearButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ClearButton;
