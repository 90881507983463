import { createSelector } from 'reselect';

const selectJobsReducer = state => state.jobs;
const getKey = (_, key) => key;

export const selectJobHasForms = createSelector([selectJobsReducer], job => job.has_forms);

/* Job Forms */
const selectJobFormsReducer = createSelector(selectJobsReducer, jobs => jobs.jobForms);

export const selectJobForms = createSelector(selectJobFormsReducer, jobForms => jobForms.forms);

export const selectJobFormIds = createSelector(selectJobForms, forms => forms.map(f => f.id));

export const selectJobFormSubmissions = createSelector(
  selectJobFormsReducer,
  jobForms => jobForms.formSubmissions
);

export const selectJobFormSubmission = createSelector(
  selectJobFormSubmissions,
  getKey,
  (formSubmissions, job_form_id) => formSubmissions[job_form_id]
);
/* -- */
