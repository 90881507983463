import React, { useEffect } from 'react';
import ListLoader from '../components/ListLoader';
import Assignments from './assignments';
import Samples from './samples';
import ModuleWrapper from '../components/ModuleWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { selectEventId, selectEventSavedVessel } from 'events/store/events/selectors';
import { getEventLubOilAnalysis } from 'events/store/event-modules/lub-oil-analysis/actions';
import { selectEventLubOilAnalysisIsLoading } from 'events/store/event-modules/lub-oil-analysis/selectors';
import NextLubOilAnalysisEventButton from './samples/NextLubOilAnalysisEventButton';
import Warnings from './warnings';

const LubOilAnalysis = () => {
  const dispatch = useDispatch();
  const eventId = useSelector(selectEventId);
  const eventVessel = useSelector(selectEventSavedVessel);
  const isLoading = useSelector(selectEventLubOilAnalysisIsLoading);

  useEffect(() => {
    if (eventId) dispatch(getEventLubOilAnalysis({ event_id: eventId }));
  }, [eventId, eventVessel?.id, dispatch]);

  if (isLoading) return <ListLoader />;
  if (!eventId) return <ModuleWrapper type="lub_oil_analysis" />;

  return (
    <ModuleWrapper type="lub_oil_analysis" action={<NextLubOilAnalysisEventButton />}>
      <Samples />
      <Assignments />
      <Warnings />
    </ModuleWrapper>
  );
};

export default LubOilAnalysis;
