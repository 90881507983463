import React from 'react';
import { useActions } from 'utils/hooks';

import Select from 'common/components/form/inputs/Select';
import VersionStatus from 'manuals/components/VersionStatus';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { selectManualVersions, selectCurrentVersionId } from 'manuals/store/selectors';
import * as manualsActions from 'manuals/store/actions';
import useRouter from 'use-react-router';

import paths from 'routing/routes/_paths';

const ManualVersions = ({ chaptersLoading }) => {
  const { match, history } = useRouter();
  const versions = useSelector(selectManualVersions);

  const currentVersionId = useSelector(selectCurrentVersionId);

  const [setComparedAtVersion, setCurrentVersion] = useActions([
    manualsActions.setComparedAtVersion,
    manualsActions.setCurrentVersion
  ]);

  const handleChange = version => {
    const newVersion = versions.find(v => v.id === parseInt(version));

    setComparedAtVersion(null);
    setCurrentVersion(parseInt(version));

    if (newVersion.status === 'active') {
      history.push(`${paths.manuals}/${match.params.id}`);
    } else {
      history.push(`${paths.manuals}/${match.params.id}?version=${version}`);
    }
  };

  return (
    <Select
      value={currentVersionId}
      onChange={handleChange}
      options={versions}
      components={{ Option, SingleValue }}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.title}
      dynamicWidth
      isClearable={false}
      hideSelectedOptions={true}
      className="mb-0 ms-1 version-selector"
      noOptionsMessage={() => `No other versions`}
      menuPosition="fixed"
      disabled={chaptersLoading}
    />
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <VersionStatus status={props.data.status} title={props.data.title} />
  </components.SingleValue>
);

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <VersionStatus status={props.data.status} title={props.data.title} />
    </components.Option>
  );
};

export default ManualVersions;
