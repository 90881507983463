import { Button, Col, Row } from 'reactstrap';

import SvgRender from 'common/components/general/SvgRender';
import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';

const CollapseHeader = ({ setOpen, isOpen, title, className = '' }) => {
  return (
    <Row className={`g-0 collapse-toggle-header ${className}`}>
      <Col className={`collapse-toggle-header__top ${!isOpen ? 'closed' : ''}`}>
        <Button
          type="button"
          color="link"
          onClick={() => setOpen(!isOpen)}
          className={`text-purple collapse-toggle-header__toggle ${!isOpen ? 'closed' : ''}`}
        >
          <SvgRender
            src={arrowFilters}
            className="align-items-center"
            style={{
              width: 8,
              height: 9,
              transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'all .2s ease-in'
            }}
          />
          {title ? <span className="fw-medium fs-12">{title}</span> : null}
        </Button>
      </Col>
    </Row>
  );
};

export default CollapseHeader;
