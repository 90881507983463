import React from 'react';
import PropTypes from 'prop-types';
import { TableTopFilters } from 'common/entities/tables/TopFilterTypes';

const MorePageActions = ({ topFilters, ...rest }) => {
  return <rest.component topFilters={topFilters} />;
};

MorePageActions.propTypes = {
  topFilters: TableTopFilters.isRequired,
  component: PropTypes.func
};

export default MorePageActions;
