import { FC, ReactNode, useState } from 'react';

import { UncontrolledTooltipProps } from 'reactstrap';

import Tooltip from '@/ts-common/components/general/Tooltip';
import SvgRender from '@/ts-common/components/general/SvgRender';
import info from '@/ts-common/assets/svg/common/info.svg';
import useTooltipID from '@/ts-common/utils/hooks/useTooltipID';

export interface InformationProps extends Omit<UncontrolledTooltipProps, 'target'> {
  message: ReactNode | string;
  className?: string;
  wrapperClassName?: string;
  tooltipClassname?: string;
  svgClassname?: string;
  svgStyle?: Record<string, unknown>;
  hoverIcon?: string;
  svgIcon?: string;
  renderComponent?: (isHovering: boolean) => ReactNode;
}

const Information: FC<InformationProps> = ({
  message,
  className = '',
  tooltipClassname = '',
  svgClassname = '',
  svgStyle,
  renderComponent,
  hoverIcon,
  placement,
  hideArrow,
  svgIcon,
  wrapperClassName = ''
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { avoidRender, tooltipID } = useTooltipID('information-tooltip');

  if (avoidRender) return null;

  return (
    <div className={`d-inline-flex information-tooltip ${wrapperClassName}`}>
      <div
        className={`${className} d-flex cursor-pointer`}
        id={tooltipID}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {renderComponent ? (
          renderComponent(isHovering)
        ) : (
          <SvgRender
            src={isHovering && hoverIcon ? hoverIcon : svgIcon || info}
            style={svgStyle || { width: 18, height: 18 }}
            className={`${svgClassname ? svgClassname : ''} pe-none`}
          />
        )}
      </div>

      <Tooltip
        fade={false}
        target={tooltipID}
        innerClassName={tooltipClassname ?? ''}
        placement={placement}
        hideArrow={hideArrow}
      >
        {message}
      </Tooltip>
    </div>
  );
};

export default Information;
