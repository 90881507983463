import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_ENTITY_REVIEW_PROCESS'),
  ...asyncTypes('CREATE_ENTITY_REVIEW_PROCESS'),
  ...asyncTypes('CHECK_IF_REVIEW_PROCESS_IS_ENABLED'),
  ...asyncTypes('UPDATE_REVIEW_PROCESS_SUBSTEP_REMARKS'),
  ...asyncTypes('UPDATE_REVIEW_PROCESS_SUBSTEP_ACTIONS'),
  ...asyncTypes('APPROVE_REVIEW_PROCESS_SUBSTEP'),
  ...asyncTypes('REVOKE_REVIEW_PROCESS_SUBSTEP'),
  ...asyncTypes('REJECT_REVIEW_PROCESS_STEP'),
  ...asyncTypes('ADD_REVIEW_PROCESS_SUBSTEP'),
  ...asyncTypes('REMOVE_REVIEW_PROCESS_SUBSTEP'),
  SET_REVIEW_PROCESS_DRAWER_IS_OPEN: 'SET_REVIEW_PROCESS_DRAWER_IS_OPEN',
  SET_REVIEW_PROCESS_FORM_DRAWER_IS_OPEN: 'SET_REVIEW_PROCESS_FORM_DRAWER_IS_OPEN',
  SET_ACTIVE_REVIEW_PROCESS_ENTITY_ID: 'SET_ACTIVE_REVIEW_PROCESS_ENTITY_ID',
  RESET_ACTIVE_ENTITY_REVIEW_PROCESS: 'RESET_ACTIVE_ENTITY_REVIEW_PROCESS',

  // Form submission
  ...asyncTypes('CREATE_REVIEW_PROCESS_FORM_SUBMISSION')
} as const;

export default TYPES;
