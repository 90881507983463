import { useAppSelector } from '@/store/hooks';
import { selectEvaluationsRangeTo } from '@/store/settings/selectors';
import { numberToStr } from '@/ts-common/utils/numbers';
import { FC } from 'react';

type AverageScoreTextProps = {
  average: string | number;
};

const AverageScoreText: FC<AverageScoreTextProps> = ({ average }) => {
  const maxScore = useAppSelector(selectEvaluationsRangeTo);

  return (
    <div className="d-flex align-items-center justify-content-center fw-medium">
      <div className="fs-12 cme-12">Average Rating:</div>
      <div className="fs-18">
        <span className="text-primary ">{average ? numberToStr(average, 2) : '-'}</span>{' '}
        <span className="text-violet">/ {maxScore}</span>
      </div>
    </div>
  );
};

export default AverageScoreText;
