import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateInput, { mode } from 'common/components/form/inputs/date';

import * as eventActions from 'events/store/events/actions';
import { useActions, useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectErrors, selectMultiEventsDateValue } from 'events/store/events/selectors';
import { TIME_FORMAT, onDateChange, onTimeValidation } from './helper';

const MultipleDatePicker = ({ dateIndex, event, changeEventValue, hasLabel, disabled }) => {
  const [setErrors] = useActions([eventActions.setErrors]);
  const errors = useSelector(selectErrors);
  const multiEventDates = useSelector(selectMultiEventsDateValue);
  const { fields, selectField } = useFormState(event);

  const changeEventDateType = type => {
    selectField('started_at')(null);
    selectField('ended_at')(null);
    selectField('date_type')(type);

    const started_at = fields?.started_at?.value;
    const ended_at = fields?.ended_at?.value;
    const date_type = type;

    const error = onTimeValidation({
      started_at,
      ended_at,
      mode: date_type
    });

    setErrors({ date: error, dateInputId: dateIndex });

    const params = {
      started_at: fields?.started_at?.value,
      ended_at: fields?.ended_at?.value,
      date_type
    };

    const eventDates = [...multiEventDates];
    eventDates[dateIndex] = params;

    changeEventValue({ dates: [...eventDates] });
  };

  const changeEventDates = dates => {
    selectField('started_at')(dates?.started_at);

    if (fields?.date_type?.value === 'range') {
      selectField('ended_at')(dates?.ended_at);
    }
    const started_at = fields?.started_at?.value;
    const ended_at = fields?.date_type?.value || dates.ended_at;
    const date_type = fields?.date_type?.value;

    const error = onTimeValidation({
      started_at,
      ended_at,
      mode: date_type,
      ...dates
    });

    setErrors({ date: error, dateInputId: dateIndex });

    const params = {
      started_at: dates?.started_at,
      ended_at: dates?.ended_at,
      date_type: fields?.date_type?.value,
      is_datetime: fields?.is_datetime?.value
    };

    const eventDates = [...multiEventDates];
    eventDates[dateIndex] = params;

    changeEventValue({ dates: [...eventDates] });
  };

  const toggleDateTime = hasTime => {
    const date_type = fields?.date_type?.value;
    const isRange = date_type === 'range';
    const dateFormat = hasTime ? TIME_FORMAT.time : TIME_FORMAT.dateOnly;

    const started_at = fields?.started_at?.value
      ? moment(fields?.started_at?.value).format(dateFormat)
      : undefined;

    const ended_at =
      fields?.ended_at?.value && isRange
        ? moment(fields?.ended_at?.value).format(dateFormat)
        : undefined;

    const is_datetime = hasTime;
    selectField('is_datetime')(hasTime);

    const params = {
      is_datetime,
      started_at,
      ended_at,
      date_type
    };

    const error = onTimeValidation({
      started_at,
      ended_at,
      mode: date_type
    });

    setErrors({ date: error, dateInputId: dateIndex });

    const eventDates = [...multiEventDates];
    eventDates[dateIndex] = params;

    changeEventValue({ dates: [...eventDates] });
  };

  return (
    <div className="event__sidebar--datepicker ps-1">
      <DateInput
        dataCy="event_date"
        label={hasLabel ? 'Date' : ''}
        onChange={selected => {
          const dates = onDateChange({
            started_at: selected?.from || selected,
            ended_at: selected?.to,
            date_type: fields?.date_type?.value,
            is_datetime: fields?.is_datetime?.value
          });

          changeEventDates(dates);
        }}
        availableModes={[mode.single.date, mode.range.date]}
        activeMode={fields?.date_type?.value === 'range' ? mode.range.date : mode.single.date}
        error={errors?.dateInputId === dateIndex ? errors.date : null}
        showTime={fields?.is_datetime?.value}
        hasTime={true}
        useStringValue={true}
        toggleTime={toggleDateTime}
        value={fields?.started_at?.value ? moment(fields?.started_at?.value) : null}
        from={fields?.started_at?.value ? moment(fields?.started_at?.value) : null}
        to={fields?.ended_at?.value ? moment(fields?.ended_at?.value) : undefined}
        onModeChange={(_, { isRange }) => changeEventDateType(isRange ? 'range' : 'single')}
        disabled={disabled}
      />
    </div>
  );
};

export default MultipleDatePicker;

MultipleDatePicker.propTypes = {
  memoizedDebounce: PropTypes.func,
  changeEventValue: PropTypes.func,
  dateIndex: PropTypes.number,
  event: PropTypes.object
};
