import { useRef, useEffect } from 'react';
import * as d3 from 'd3';

import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import { usePrevious } from 'utils/hooks';

import useWindowWidth from 'common/utils/hooks/useWindowWidth';
import useD3Canvas from './useD3Canvas';
import useD3Active from './useD3Active';

const useD3Element = (renderGraph, canvasRef, events, disabledPlots, schema, dependencies = []) => {
  const legendsRef = useRef();
  const graphRef = useRef();

  const { renderCanvas, destroyCanvasRenderer, canvasDimensions } = useD3Canvas(
    canvasRef,
    d3.select(graphRef.current?.firstChild)
  );

  const createMemoryElement = () => {
    const memElem = document.createElement('custom');
    return d3.select(memElem);
  };

  const getBoundingClientRect = element => {
    const { top, right, bottom, left, width, height, x, y } = element.getBoundingClientRect();
    return { top, right, bottom, left, width, height, x, y };
  };

  const { isActive } = useD3Active(events);

  const memoryElement = useRef(createMemoryElement());

  const windowWidth = useWindowWidth(64);

  const getDimensions = () => {
    // The purpose of this function is to return the {width, height} of the parent of the svg element.
    // This is done because we need the {width, height} for D3 calculations.
    // TLDR: Your graph will always take 100% of both the width and the height of the parent.
    // If you want to get around that, wrap the graph component in a <div> and give the <div> the size you want or pass the 'dimesions' object inside 'schema'.
    if (!graphRef.current) return { width: 0, height: 0 };

    const graphRect = getBoundingClientRect(graphRef.current.parentNode);

    const customDimensions = _get(schema, 'dimensions');

    if (customDimensions) {
      return { ...graphRect, ...customDimensions };
    }

    return graphRect;
  };

  const renderGraphRegular = () => {
    const element = d3.select(graphRef.current?.firstChild);

    const dimensions = getDimensions();

    renderGraph({
      element,
      memoryElement: memoryElement.current,
      getDimensions: () => dimensions
    });
  };

  const renderGraphDebounced = _debounce(renderGraphRegular, 128);

  // The useEffect below is run when resizing the window in order to rerender the graph
  // (since it needs the width & height for calculations)

  const prevWindowWidth = usePrevious(windowWidth);
  const prevDeps = usePrevious(dependencies);

  useEffect(() => {
    if (!_isEqual(prevDeps, dependencies) || prevWindowWidth !== windowWidth) {
      renderGraphDebounced();
    }
    return () => {};
  }, [dependencies, windowWidth]);

  useEffect(() => {
    let timeoutRef;

    renderCanvas(memoryElement.current, getDimensions, disabledPlots);

    return () => {
      if (timeoutRef) clearTimeout(timeoutRef);
      destroyCanvasRenderer();
    };
  }, [dependencies, canvasDimensions, disabledPlots, isActive]);

  useEffect(() => {
    renderGraphDebounced();
    return () => {};
  }, [graphRef.current]);

  return { graphRef, legendsRef, getDimensions };
};

export default useD3Element;
