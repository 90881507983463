import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_MAINTENANCE'),
  ...asyncTypes('UPDATE_MAINTENANCE'),
  ...asyncTypes('DOWNLOAD_ATTACHED_MANUALS'),
  SET_MAINTENANCE_FIELD: 'SET_MAINTENANCE_FIELD',
  INITIALIZE_PMS_SPARE_PARTS: 'INITIALIZE_PMS_SPARE_PARTS',
  INITIALIZE_CONSUMED_SPARE_PARTS: 'INITIALIZE_CONSUMED_SPARE_PARTS',
  ADD_MAINTENANCE_SPARE_PART: 'ADD_MAINTENANCE_SPARE_PART',
  REMOVE_MAINTENANCE_SPARE_PART: 'REMOVE_MAINTENANCE_SPARE_PART',
  SET_MAINTENANCE_SPARE_PART: 'SET_MAINTENANCE_SPARE_PART',
  RESET_SPARE_PARTS: 'RESET_SPARE_PARTS',
  SET_SPARE_PARTS_COLLAPSED: 'SET_SPARE_PARTS_COLLAPSED'
};

export default TYPES;
