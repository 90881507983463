import SvgRender from 'common/components/general/SvgRender';
import { getFieldName, getFormFieldParentID } from 'common/components/forms/digital/state-utils';

import x from 'common/assets/svg/common/x-circle.svg';
import check from 'common/assets/svg/common/check-circle-solid.svg';

const BooleanView = ({ value, formField }) => {
  const fieldName = getFieldName(formField);
  const formFieldParentID = getFormFieldParentID(formField);

  return (
    <div className="d-flex align-items-center">
      <SvgRender
        src={value ? check : x}
        className={`text-${value ? 'green' : 'violet'}`}
        style={{ height: 16, width: 16 }}
      />
      {formFieldParentID ? null : <span className="fs-12 text-primary ps-1">{fieldName}</span>}
    </div>
  );
};

export default BooleanView;
