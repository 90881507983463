import TYPES from './types';

export const BUNKER_SURVEY_INITIAL_STATE = {
  bunkerSurvey: {
    data: null,
    isSlimView: false,
    isViewOnly: true,
    drawer: { isOpen: false, activeActionId: null, type: '' }
  }
};

const reducer = (state = BUNKER_SURVEY_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_BUNKER_SURVEY_SLIM_VIEW:
      return {
        ...state,
        bunkerSurvey: {
          ...state.bunkerSurvey,
          isSlimView: payload
        }
      };

    case TYPES.SET_BUNKER_SURVEY_VIEW_ONLY:
      return {
        ...state,
        bunkerSurvey: {
          ...state.bunkerSurvey,
          isViewOnly: payload
        }
      };

    case TYPES.SET_BUNKER_SURVEY_DRAWER:
      return {
        ...state,
        bunkerSurvey: {
          ...state.bunkerSurvey,
          drawer: {
            isOpen: payload?.isOpen || false,
            activeActionId: payload?.activeActionId || null,
            type: payload.type
          }
        }
      };

    case TYPES.SET_BUNKER_SURVEY_DATA:
      return {
        ...state,
        bunkerSurvey: {
          ...state.bunkerSurvey,
          data: payload
        }
      };

    default:
      return state;
  }
};

export default reducer;
