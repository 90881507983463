import React from 'react';
import retry from '@/common/utils/lazy-retry.js';
import permissions from '@/common/utils/permissions/constants.js';
import paths from '@/routing/routes/_paths.js';

const Purchasing = React.lazy(() => retry(() => import('@/views/purchasing/index.jsx')));
const Requisition = React.lazy(() =>
  retry(() => import('@/views/purchasing/requisition/index.jsx'))
);
const Inventory = React.lazy(() => retry(() => import('@/views/purchasing/inventory/index.jsx')));
const Deliverables = React.lazy(() =>
  retry(() => import('@/views/purchasing/deliverables/index.jsx'))
);
const Deliverable = React.lazy(() =>
  retry(() => import('@/views/purchasing/deliverables/deliverable/index.jsx'))
);

const purchasingRoutes = [
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.purchasing_requisitions}/create`,
    component: Requisition,
    permissions: [permissions.onboard.purchasing.requisitions.view],
    documentTitle: 'Create Purchasing Requisitions'
  },
  {
    type: 'private',
    exact: true,
    app: true,
    path: `${paths.purchasing_requisitions}`,
    component: Purchasing,
    permissions: [permissions.onboard.purchasing.requisitions.view],
    documentTitle: 'Purchasing Requisitions'
  },
  {
    path: `${paths.purchasing_requisitions}/:id`,
    component: Requisition,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.purchasing.requisitions.view],
    documentTitle: 'Purchasing Requisition'
  },
  {
    path: `${paths.purchasing_requisitions}/:id/suppliers/:supplierRequisitionID`,
    component: Requisition,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.purchasing.requisitions.view],
    documentTitle: 'Purchasing Requisition'
  },
  {
    path: paths.purchasing_inventory,
    component: Inventory,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.purchasing.stores_inventory.view],
    documentTitle: 'Stores Inventory'
  },
  {
    path: paths.purchasing_deliverables,
    component: Deliverables,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.purchasing.deliverables.view],
    documentTitle: 'Deliverables'
  },
  {
    path: `${paths.purchasing_deliverables}/:id`,
    component: Deliverable,
    app: true,
    type: 'private',
    exact: true,
    permissions: [permissions.onboard.purchasing.deliverables.view],
    documentTitle: 'Deliverable'
  }
];

export default purchasingRoutes;
