import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import SvgRender from 'common/components/general/SvgRender';
import FormFieldLabel from 'common/components/layouts/vertical-tab-view/components/FormFieldLabel';

import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectCrewProfile } from 'crew/store/profile/selectors';
import { getValue } from 'common/components/layouts/vertical-tab-view/_utils';
import { useActions } from 'utils/hooks';

import maritalStatus from 'common/assets/svg/common/marital-status.svg';
import kid from 'common/assets/svg/common/kid.svg';

import * as listActions from 'store/lists/actions';

const MaritalStatus = ({ config }) => {
  const profile = useSelector(selectCrewProfile);

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const genders = useSelector(state => state.lists['genders']);

  useEffect(() => {
    if (!genders.options.length && !genders.isFetching) {
      fetchListOptions('genders');
    }
  }, []);

  const Gender = ({ child }) => {
    const gender = genders.options.find(g => g.id === child.gender_id);

    return gender?.name || '-';
  };

  return (
    <Fade>
      <Row className="marital-status-view">
        <Col xs={12}>
          <Row>
            <Col xs={10}>
              <div className="marital-status-view__box">
                <SvgRender
                  src={maritalStatus}
                  style={{ width: 16, height: 16 }}
                  className="me-1 text-purple marital-status-view__box-icon"
                />

                {Object.keys(config).map((el, i) => {
                  let item = config[el];

                  if (item.customComponent) {
                    return <item.customComponent key={i.toString()} fields={item.fields} />;
                  }

                  return (
                    <div key={i.toString()}>
                      {item.fields.map(field => {
                        if (field.type === 'multiple') return null;

                        return (
                          <Row
                            key={field.key}
                            className={field.className ? field.className : 'cmt-4 cmb-4'}
                          >
                            <Col className="d-flex align-items-start justify-content-end" xs={5}>
                              {field.hideLabel ? null : (
                                <FormFieldLabel>{field.label}</FormFieldLabel>
                              )}
                            </Col>

                            <Col xs={7} className="fs-12 d-flex align-items-start">
                              {field.key === 'number_of_children' ? (
                                profile.children?.length || '-'
                              ) : (
                                <span>{getValue(field, profile)}</span>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Col>
            {profile.children.map(child => {
              return (
                <Col xs={10} key={child.id.toString()}>
                  <div className="marital-status-view__box mt-1 ">
                    <SvgRender
                      src={kid}
                      style={{ width: 11, height: 11 }}
                      className="me-1 text-purple marital-status-view__box-icon"
                    />

                    {config[0].fields[config[0].fields.length - 1].data.map(field => {
                      if (field.type === 'multiple') return null;

                      return (
                        <Row
                          key={field.key}
                          className={` ${field.className ? field.className : 'cmt-4 cmb-4'}`}
                        >
                          <Col
                            className="text-end text-blue fs-12  fw-bold d-flex align-items-start justify-content-end"
                            xs={5}
                          >
                            {field.hideLabel ? null : field.label}
                          </Col>

                          <Col xs={7} className="fs-12  d-flex align-items-start ">
                            {field.key === 'number_of_children' ? (
                              profile.children?.length || '-'
                            ) : (
                              <span>
                                {field.select_key === 'gender_id' ? (
                                  <Gender child={child} />
                                ) : (
                                  getValue(field, child)
                                )}
                              </span>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Fade>
  );
};

export default MaritalStatus;
