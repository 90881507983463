import AttachmentField from 'captain-reports/templates/inputs/AttachmentField';

const departure = () => [
  {
    fields: [
      {
        label: 'Departure Condition Printout',
        key: 'departure_condition_printout',
        type: 'file',
        width: { xs: 3 },
        render: AttachmentField
      },
      {
        label: 'Passage Plan',
        type: 'file',
        key: 'passage_plan',
        width: { xs: 3 },
        render: AttachmentField
      }
    ]
  }
];

const arrival = () => [
  {
    fields: [
      {
        label: 'Pre-Arrival Tests',
        key: 'pre_arrival_tests',
        type: 'file',
        width: { xs: 3 },
        render: AttachmentField
      }
    ]
  }
];

const noon = () => [
  {
    fields: [
      {
        label: 'Other Attachments',
        key: 'other_attachment',
        type: 'file',
        width: { xs: 12 },
        render: AttachmentField
      }
    ]
  }
];

const attachmentsConfig = {
  noon: () => noon(),
  arrival: () => arrival(),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => departure(),
  port_noon: () => noon(),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default attachmentsConfig;
