const DropdownWithDetailsView = ({ value }) => {
  return (
    <div className="fs-12 text-primary">
      <div>{value?.option || '-'}</div>
      {value?.additional_info ? <div className="mt-1">{value.additional_info}</div> : null}
    </div>
  );
};

export default DropdownWithDetailsView;
