import React from 'react';
import { Row, Col } from 'reactstrap';
import FormTypeSelector from './FormTypeSelector';
// import FormDate from 'common/components/forms/_components/FormDate';
import CircledButton from 'common/components/buttons/CircledButton';

const EmptyFormSubmission = ({
  selectedFormId,
  setSelectedFormId,
  selectedForm,
  setSelectedForm,
  setFormState,
  onFormTypeChange,
  excludeFormIds,
  setIsFetching,
  setUploadedFile,
  setUploadedFileError,
  onClose,
  selectedVesselId
}) => {
  return (
    <Row noGutters>
      <Col xs={4}>
        <FormTypeSelector
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
          selectedFormId={selectedFormId}
          setSelectedFormId={setSelectedFormId}
          setIsFetching={setIsFetching}
          setFormState={setFormState}
          onFormTypeChange={onFormTypeChange}
          excludeFormIds={excludeFormIds}
          setUploadedFile={setUploadedFile}
          setUploadedFileError={setUploadedFileError}
          selectedVesselId={selectedVesselId}
        />
      </Col>
      {/* <Col className="d-flex flex-column justify-content-center cpe-4 ps-1">
        <div className="form-label fs-10 fw-normal mb-1">FORM DATE</div>
        <FormDate value={null} onChange={() => {}} isViewMode={true} error={null} />
      </Col> */}

      {onClose ? (
        <div className="collapse-form__actions">
          <CircledButton type="close" onClick={onClose} svgStyle={{ width: 10, height: 10 }} />
        </div>
      ) : null}
    </Row>
  );
};

export default EmptyFormSubmission;
