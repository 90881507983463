import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelector from 'common/components/selectors/AsyncSelector';
import { Itinerary } from 'common/entities/itineraries/ItineraryTypes';
import { useMemo } from 'react';
import { Button } from 'reactstrap';

const VesselPortsSelector = ({
  className = '',
  label,
  type = 'port',
  onChangeType,
  itineraryPort,
  onChangeItineraryPort,
  vesselId,
  hiddenTabs = false,
  disabledTabs = false,
  children
}) => {
  const isPortsTabActive = useMemo(() => type === 'port', [type]);

  return (
    <div className={className}>
      <Tabs
        label={label}
        type={type}
        hidden={hiddenTabs}
        disabled={disabledTabs}
        onChangeType={onChangeType}
      />

      <div>
        {isPortsTabActive ? (
          children
        ) : (
          <AsyncSelector
            placeholder="Select itinerary"
            getOptionValue={option => option.id}
            getOptionLabel={option => option.description}
            defaultOptionsTriggerChange={vesselId}
            className="mb-0"
            listParams={{ vessel_id: vesselId }}
            type="itinerary-ports"
            onChange={onChangeItineraryPort}
            {...itineraryPort}
          />
        )}
      </div>
    </div>
  );
};

const Tabs = ({ label, type, hidden, disabled, onChangeType }) => {
  const tabs = useMemo(
    () => [
      { label: 'All Ports', value: 'port' },
      { label: 'Vessel Itinerary Ports', value: 'itinerary_port' }
    ],
    []
  );

  return (
    <div className="d-flex align-items-center cmb-4">
      <div className="flex-1">{label}</div>
      {hidden ? null : (
        <div className="d-flex align-items-center">
          {tabs.map(tab => {
            const isActive = tab.value === type;

            return (
              <Button
                key={tab.value}
                color={isActive && !disabled ? 'primary' : 'link'}
                size="sm"
                className={`height-16 lh-12 fs-10 py-0 cms-4 fw-medium ${
                  !isActive
                    ? 'border rounded text-inactive'
                    : disabled
                    ? 'bg-primary text-white'
                    : ''
                }`}
                disabled={disabled}
                onClick={() => onChangeType(tab.value)}
              >
                {tab.label}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

VesselPortsSelector.propTypes = {
  children: PropTypes.node.isRequired, // Use the PortsSelector of your choice
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.oneOf(['port', 'itinerary_port']).isRequired,
  className: PropTypes.string,
  vesselId: PropTypes.number,
  hiddenTabs: PropTypes.bool, // hide/show the tabs
  disabledTabs: PropTypes.bool, // disabled tabs
  onChangeType: PropTypes.func.isRequired,
  itineraryPort: PropTypes.PropTypes.shape({
    value: Itinerary,
    error: PropTypes.string,
    disabled: PropTypes.bool
  }).isRequired,
  onChangeItineraryPort: PropTypes.func.isRequired
};

export default VesselPortsSelector;
