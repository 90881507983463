import { useSelector } from 'react-redux';
import { selectLatestPeriod } from 'common/components/mga/store/selectors';
import MgaAccountsSelector from 'common/components/selectors/MgaAccountsSelector';

const CrewAccountField = ({ fields, selectField, disabled }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  const { action_type } = fields;
  const latestPeriod = useSelector(selectLatestPeriod);
  const isVisible =
    action_type.value?.debit_type === 'crew' || action_type.value?.credit_type === 'crew';

  if (!isVisible) return null;

  const key = action_type.value?.debit_type === 'crew' ? 'debit_account' : 'credit_account';

  return (
    <MgaAccountsSelector
      label="SEAMAN"
      className="mb-2"
      placeholder="Select account"
      value={fields[key].value}
      onChange={value => {
        selectField(key)(value);
        selectField('bank_account')(null);
      }}
      isMulti={false}
      autoFocus={false}
      listParams={{
        period_id: latestPeriod?.id,
        only_crew_accounts: true,
        ...(isOnBoard ? { show_account_page_onboard: true } : {})
      }}
      disabled={disabled}
    />
  );
};

export default CrewAccountField;
