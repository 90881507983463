import React from 'react';
import { Row, Col } from 'reactstrap';
import { renderViewType, customFieldRows } from 'common/components/forms/digital/render-helpers';
import {
  getFieldTypeLabel,
  getFieldName,
  getFormFieldTypeConfig,
  parseSubmissionValuesForDisplay,
  initFormFieldsStructure,
  getFormFieldItemDescription,
  parseSubmissionPreviousValuesForDisplay,
  getFormFieldID
} from 'common/components/forms/digital/state-utils';

import { FORM_FIELD_TYPES } from 'common/components/forms/digital/constants';
import useTooltipID from 'common/utils/hooks/useTooltipID';
import PreviousValuesTooltip from './components/previous-values-tooltip';

const FormFieldView = ({
  formField,
  formFieldValue,
  formValues,
  formPreviousValues,
  formFields,
  formFieldPreviousValues
}) => {
  const { tooltipID, avoidRender } = useTooltipID('previous-values');
  const formFieldID = getFormFieldID(formField);

  return (
    <>
      <div id={`${tooltipID}-${formFieldID}`}>
        {renderViewType(
          formField,
          formFieldValue ? formFieldValue : null,
          formValues,
          formFields,
          formPreviousValues
        )}
      </div>
      {!avoidRender ? (
        <PreviousValuesTooltip
          formField={formField}
          tooltipID={`${tooltipID}-${formFieldID}`}
          previousValues={formFieldPreviousValues}
        />
      ) : null}
    </>
  );
};

const Display = ({ form_fields, form_submission_values, form_submission_previous_values }) => {
  const values = parseSubmissionValuesForDisplay(form_submission_values);
  const previousValues = parseSubmissionPreviousValuesForDisplay(form_submission_previous_values);
  const fields = initFormFieldsStructure(form_fields);

  const getColumnPaddingHelperClass = type => {
    switch (type) {
      case FORM_FIELD_TYPES.form_instructions_image.label:
        return 'pt-3';
      case FORM_FIELD_TYPES.attachments.label:
        return 'cpt-6';

      default:
        return '';
    }
  };

  return (
    <div>
      {fields.map(formField => {
        const fieldTypeLabel = getFieldTypeLabel(formField);

        return (
          <Row className="mb-1 pt-1" key={formField.id}>
            {customFieldRows.includes(fieldTypeLabel) ? (
              <Col xs={12}>
                <FormFieldView
                  formField={formField}
                  formFieldValue={values[formField.id]}
                  formFieldPreviousValues={previousValues[formField.id]}
                  formPreviousValues={previousValues}
                  formValues={values}
                  formFields={fields}
                />
              </Col>
            ) : (
              <>
                <Col
                  xs={3}
                  className={`pe-2 d-flex justify-content-end text-primary fw-bold fs-12 text-end
                  ${getColumnPaddingHelperClass(fieldTypeLabel)}`}
                >
                  {getFormFieldTypeConfig(formField)?.hideFieldName
                    ? ''
                    : getFormFieldTypeConfig(formField)?.showFieldItemDescription
                    ? getFormFieldItemDescription(formField)
                    : getFieldName(formField)}
                </Col>
                <Col xs={9}>
                  <FormFieldView
                    formField={formField}
                    formFieldValue={values[formField.id]}
                    formFieldPreviousValues={previousValues[formField.id]}
                    formFields={fields}
                  />
                </Col>
              </>
            )}
          </Row>
        );
      })}
    </div>
  );
};

export default Display;
