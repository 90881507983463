import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useActions } from 'utils/hooks';

import Loader from './Loader';
import { usePageInfiniteScroll } from 'utils/hooks';

import { selectPostCategoryPosts } from 'store/lists/selectors';
import {
  selectPostsWithImageIsLoading,
  selectPostsWithImageLoadMoreLoading,
  selectPostsWithImageData,
  selectPostsWithImageCurrentPage,
  selectPostsWithImageLastPage
} from 'common/components/news-and-announcements/store/selectors';

import * as postsActions from 'common/components/news-and-announcements/store/actions';
import PostWithImage from './PostWithImage';

const PostsWithImage = ({ setActivePost, containerRef }) => {
  const isLoading = useSelector(selectPostsWithImageIsLoading);
  const loadMoreLoading = useSelector(selectPostsWithImageLoadMoreLoading);
  const data = useSelector(selectPostsWithImageData);
  const currentPage = useSelector(selectPostsWithImageCurrentPage);
  const totalPages = useSelector(selectPostsWithImageLastPage);
  const categoryPost = useSelector(selectPostCategoryPosts);
  const isOnBoard = useSelector(state => state.isOnBoard);

  const [getPostsWithImage] = useActions([postsActions.getPostsWithImage]);

  const loadMorePages = useCallback(async () => {
    if (loadMoreLoading || currentPage === totalPages) return;

    getPostsWithImage({
      loadMore: true,
      currentPage,
      categoryId: categoryPost?.id
    });
  }, [getPostsWithImage, currentPage, totalPages, loadMoreLoading, categoryPost?.id]);

  usePageInfiniteScroll(
    totalPages,
    loadMorePages,
    { currentPage },
    30,
    false,
    containerRef?.current,
    false
  );

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className={`pe-4 ps-4 mt-6 ${!isOnBoard ? 'text-dark' : 'text-primary'}`}>
        {(data || [])?.map(post => (
          <PostWithImage key={post.id} post={post} onClick={post => setActivePost(post)} />
        ))}
      </div>
      {loadMoreLoading ? <Loader /> : null}
    </>
  );
};

PostsWithImage.propTypes = {
  setActivePost: PropTypes.func,
  containerRef: PropTypes.object
};

export default PostsWithImage;
