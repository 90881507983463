import TYPES from './types';

const INITIAL_STATE = {
  data: [],
  oldData: [],
  fetching: false,
  hasError: false,
  active: null
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.GET_CREW_EVALUATION_TEMPLATES.START:
    case TYPES.GET_CREW_EVALUATION_TEMPLATE.START:
      return {
        ...state,
        fetching: true,
        hasError: false
      };

    case TYPES.GET_CREW_EVALUATION_TEMPLATES.SUCCESS:
      return {
        ...state,
        data: payload,
        fetching: false,
        hasError: false
      };

    case TYPES.GET_CREW_EVALUATION_TEMPLATES.ERROR:
      return {
        ...state,
        data: [],
        fetching: false,
        hasError: true
      };

    case TYPES.GET_CREW_EVALUATION_TEMPLATE.SUCCESS:
      return {
        ...state,
        active: payload,
        fetching: false,
        hasError: false
      };

    case TYPES.GET_CREW_EVALUATION_TEMPLATE.ERROR:
      return {
        ...state,
        active: null,
        fetching: false,
        hasError: true
      };

    case TYPES.CLEAR_CREW_EVALUATION_TEMPLATE:
      return {
        ...state,
        active: null,
        fetching: false,
        hasError: false
      };

    case TYPES.CREATE_CREW_EVALUATION_TEMPLATE.SUCCESS:
      let newData = [...state.data, payload];
      return {
        ...state,
        data: newData
      };

    case TYPES.UPDATE_CREW_EVALUATION_TEMPLATE.SUCCESS:
      const updatedData = state.data.map(el => {
        if (el.id === payload.id) {
          return {
            ...el,
            name: payload.name
          };
        } else {
          return el;
        }
      });

      return {
        ...state,
        data: updatedData
      };

    case TYPES.REORDER_CREW_EVALUATION_TEMPLATE.START:
      return {
        ...state,
        data: payload.newData,
        oldData: state.data
      };

    case TYPES.REORDER_CREW_EVALUATION_TEMPLATE.SUCCESS:
      return {
        ...state,
        oldData: []
      };

    case TYPES.REORDER_CREW_EVALUATION_TEMPLATE.ERROR:
      return {
        ...state,
        data: state.oldData,
        oldData: []
      };

    default:
      return state;
  }
};

export default reducer;
