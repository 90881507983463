import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import SvgRender from 'common/components/general/SvgRender';
import vesselIcon from 'common/assets/svg/common/vessels.svg';
import Switch from 'common/components/form/inputs/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsModuleSynced } from 'events/store/event-modules/synced-modules/selectors';
import { updateModule } from 'events/store/events/actions';
import {
  selectEventIsForVessel,
  selectIsCreate,
  selectIsTemplate,
  selectModuleIdByLabel
} from 'events/store/events/selectors';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { moduleIcons } from '../modules/config';

const SyncButton = ({ label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const isOnboard = useSelector(state => state.isOnBoard);
  const isCreate = useSelector(selectIsCreate);
  const isModuleSynced = useSelector(state => selectIsModuleSynced(state, label));
  const moduleId = useSelector(state => selectModuleIdByLabel(state, label));
  const isTemplate = useSelector(selectIsTemplate);
  const isForVessel = useSelector(selectEventIsForVessel);
  const isModuleSyncable = moduleIcons[label].onboard;
  const moduleName = moduleIcons[label].title;

  const handleToggle = () => {
    dispatch(updateModule({ id: moduleId, sync_to_onboard: !isModuleSynced }));
  };

  if (!isModuleSyncable || isCreate || !moduleId || isOnboard || isTemplate || !isForVessel)
    return null;

  return (
    <SyncButtonContainer className="d-flex align-items-center cpy-6 cpe-6 ps-1">
      <SvgRender className="text-primary" src={vesselIcon} style={{ width: 12, height: 12 }} />
      <Switch
        switchClassName="blue-switch"
        className="mb-0 ms-1"
        size="sm"
        onChange={isModuleSynced ? () => setIsModalOpen(true) : handleToggle}
        value={isModuleSynced}
      />

      <DangerousActionModal
        transparent
        actionIcon={<SvgRender src={vesselIcon} style={{ width: 85, height: 85 }} />}
        action="save"
        onAccept={handleToggle}
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        actionText="UNSYNC"
        cancelText="CANCEL"
        actionHoverColor="primary"
        header="Unsync from Onboard"
        body={
          <div>
            Are you sure you want to unsync <br /> <strong>{moduleName}</strong> module from
            onboard? <br /> Module will become invisible to the <br /> vessel.
          </div>
        }
      />
    </SyncButtonContainer>
  );
};

SyncButton.propTypes = {
  label: PropTypes.string.isRequired // Module's unique label
};

export default SyncButton;

export const SyncButtonContainer = styled.div`
  position: absolute;
  top: 0.688rem;
  right: 2rem;
  background-color: rgba(112, 132, 211, 0.1);
  border-radius: 3px;
`;
