import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, put, post, deleteRequest } from 'utils/api';

export const getOfficeNotes = createAsyncThunk(
  'GET_OFFICE_NOTES',
  async (params, { rejectWithValue }) => {
    const { id } = params;

    try {
      const res = await get(`/events/${id}/office-notes`);

      return res.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createOfficeNotes = createAsyncThunk(
  'CREATE_OFFICE_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { eventId, ...rest } = params;

    try {
      const res = await post(`/events/${eventId}/office-notes`, rest);

      return fulfillWithValue(res.data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const editOfficeNotes = createAsyncThunk(
  'EDIT_OFFICE_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { eventId, id, isTemplate, ...rest } = params;

    try {
      const res = await put(`/events/${eventId}/office-notes/${id}`, rest);

      return fulfillWithValue(res.data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteOfficeNotes = createAsyncThunk(
  'DELETE_OFFICE_NOTES',
  async (params, { rejectWithValue, fulfillWithValue }) => {
    const { eventId, id, isTemplate, ...rest } = params;

    try {
      const res = await deleteRequest(`/events/${eventId}/office-notes/${id}`, rest);

      return fulfillWithValue(res.data);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
