import Select from 'common/components/form/inputs/Select';
import SvgRender from 'common/components/general/SvgRender';

// Deprecated component - use @/ts-common/components/form/inputs/select/icon-select
const IconSelect = ({
  isMulti,
  value,
  icon,
  size = 'lg',
  iconStyle,
  hideIcon = false,
  containerClassName = '',
  ...rest
}) => {
  const iconSize = rest.size === 'sm' ? 14 : 16;

  return (
    <div
      className={`icon-select${
        (isMulti ? !value?.length : !value) ? ' icon-select--empty' : ''
      } ${containerClassName} ${hideIcon ? 'icon-select--hide-icon' : ''}`}
    >
      <div className="icon-select__icon">
        <SvgRender src={icon} style={iconStyle || { height: iconSize, width: iconSize }} />
      </div>

      <Select isMulti={isMulti} value={value} size={size} {...rest} />
    </div>
  );
};

export default IconSelect;
