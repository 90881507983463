import React from 'react';

import { dateToLocal } from 'common/utils/dates';
import moment from 'moment';

export const Date = ({ value, withTime, parseToLocal = false, timeClassName }) => {
  if (!value) return null;

  const date = parseToLocal ? dateToLocal(value) : moment(value);

  return (
    <div className="preview-date">
      <span>{date.format('DD/MM/YYYY')}</span>
      {withTime ? (
        <span className={timeClassName ? timeClassName : 'ps-1'}>{date.format('HH:mm')}</span>
      ) : null}
    </div>
  );
};

export default Date;
