import { booleanField, stringField } from 'common/utils/form/fieldTypes';

const config = {
  dates: {
    date_type: stringField({ initialValue: 'single' }),
    started_at: stringField(),
    ended_at: stringField(),
    is_datetime: booleanField({ initialValue: false })
  }
};

export default config;
