import { FC } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const BasicModal: FC<any> = ({
  isOpen,
  header,
  body,
  footer,
  toggle = () => null,
  container,
  backdropClassName,
  contentClassName = '',
  ...rest
}) => {
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={`${
        container && backdropClassName ? 'modal-content-box-shadow' : ''
      } ${contentClassName}`}
      container={container}
      backdropClassName={backdropClassName}
      {...rest}
    >
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

export default BasicModal;
