import TYPES from './types';
import { get, post, put } from 'utils/api';

import {
  setMessages,
  setUnreadMessages,
  postMessage,
  setMarkAsRead,
  setFetching
} from 'common/components/chatbox/store/actions';

export const getFormSubmissionMessages = params => dispatch => {
  dispatch({ type: TYPES.GET_FORM_SUBMISSION_MESSAGES.START, payload: { params } });
  dispatch(setFetching(true));

  return get(`/form-submissions/${params.id}/messages`)
    .then(response => {
      dispatch(setMessages(response.data));
      dispatch(setFetching(false));
      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION_MESSAGES.ERROR, payload: error });
      dispatch(setFetching(false));
      throw error;
    });
};

export const postFormSubmissioMessage = params => dispatch => {
  const { id, ...rest } = params;

  dispatch({ type: TYPES.POST_FORM_SUBMISSION_MESSAGE.START, payload: { params } });

  return post(`/form-submissions/${id}/messages`, rest)
    .then(response => {
      dispatch(postMessage(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.POST_FORM_SUBMISSION_MESSAGE.ERROR, payload: error });
      throw error;
    });
};

export const getFormSubmissionUnreadMessages = params => dispatch => {
  dispatch({ type: TYPES.GET_FORM_SUBMISSION_UNREAD_MESSAGES.START, payload: { params } });

  const { form_submission_ids, saveUnreadMessages } = params;

  return get(`/form-submission-unread-messages`, { form_submission_ids })
    .then(response => {
      saveUnreadMessages &&
        dispatch(setUnreadMessages({ count: response.data[form_submission_ids[0]] }));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.GET_FORM_SUBMISSION_UNREAD_MESSAGES.ERROR, payload: error });
      throw error;
    });
};

export const formSubmissionMarkAsRead = params => dispatch => {
  dispatch({ type: TYPES.FORM_SUBMISSION_MARK_AS_READ.START, payload: { params } });

  return put(`/form-submissions/${params.id}/messages/mark-as-read`)
    .then(response => {
      dispatch(setMarkAsRead(response.data));

      return response.data;
    })
    .catch(error => {
      dispatch({ type: TYPES.FORM_SUBMISSION_MARK_AS_READ.ERROR, payload: error });
      throw error;
    });
};
