const retry = (fn, retriesLeft = 5, interval = 100) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          console.error(error);

          if (retriesLeft === 1) {
            window.location.reload();
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export default retry;
// window.location.reload(false);
