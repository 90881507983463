import PropTypes from 'prop-types';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import Select from 'common/components/form/inputs/Select';
import NamesInTooltip from 'common/components/general/NamesInTooltip';

const Trainings = ({ selectField, fields, hasSubmittedEvaluation, errors }) => {
  return hasSubmittedEvaluation ? (
    <div data-cy="trainings-view" className="d-flex align-items-center fs-13 text-primary">
      <div className="fw-bold">Training(s): </div>
      &nbsp;
      <NamesInTooltip
        maxHeight={20}
        names={(fields?.trainings?.value || []).map(training => training?.name)}
      />
    </div>
  ) : (
    <Select
      placeholder="Select training(s)"
      className="mb-0"
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isMulti={true}
      loadOptions={search => getAsyncOptions(search, 'crew-training-types')}
      defaultOptions={() => getInitialAsyncValues('crew-training-types')}
      onChange={value => selectField('trainings')(value)}
      isClearable={false}
      isAsync
      {...fields?.trainings}
      error={errors?.trainings && !fields?.trainings?.value?.length ? errors?.trainings : null}
      dataCy="evaluation-trainings"
    />
  );
};

Trainings.propTypes = {
  fields: PropTypes.shape({
    trainings: PropTypes.shape({ value: PropTypes.array, error: PropTypes.string })
  }).isRequired,
  selectField: PropTypes.func.isRequired
};

export default Trainings;
