import TYPES from './types';

const INITIAL_STATE = {
  loading: false,
  data: {},
  isEditting: false,
  navigation: {},
  totals: {},
  previousReport: {},
  previousNavigational: {},
  previousVesselSystems: [],
  errors: {
    local_timestamp: '',
    timezone: '',
    vessel_systems: ''
  }
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.RESET_STATE:
      return INITIAL_STATE;
    case TYPES.GET_RUNNING_HOURS_TOTALS.SUCCESS:
      return {
        ...state,
        navigation: payload.navigation,
        previousReport: payload.previous_report,
        previousVesselSystems: payload.previous_running_hours
      };
    case TYPES.SET_IS_EDITTING:
      return {
        ...state,
        isEditting: payload
      };

    case TYPES.GET_RUNNING_HOURS.START:
      return {
        ...state,
        loading: true
      };

    case TYPES.UPDATE_RUNNING_HOURS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          status: payload.status
        }
      };

    case TYPES.GET_RUNNING_HOURS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...payload,
          timestamp: payload.timestamp,
          timezone: payload.timezone || payload.timezone === 0 ? payload.timezone.toString() : null,
          local_timestamp: payload.local_timestamp ?? null
        }
      };

    case TYPES.SET_DATA_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };

    case TYPES.SET_ERROR_VALUE:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload
        }
      };

    case TYPES.SET_RUNNING_HOURS_REBUILD_COMMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          rebuild_comments: payload
        }
      };

    case TYPES.SET_ASSIGNMENT_VALUE:
      const newSystems = [...state.data.vessel_systems];
      newSystems[payload.index].vessel_system_total_running_hours.value = payload.value;

      return {
        ...state,
        data: {
          ...state.data,
          newSystems: newSystems
        }
      };

    default:
      return state;
  }
};

export default reducer;
