import TYPES from 'captain-reports/store/types';
import initialFormState, {
  createDynamicReportState
} from 'captain-reports/templates/config/_initialState';
import {
  createFormConsumptionsState,
  addSecondaryBunkerConsumptionColumn,
  removeSecondaryBunkerConsumptionColumn
} from 'captain-reports/templates/config/_bunkersHelpers';
import { LOCATION_CHANGE } from 'connected-react-router';

import paths from 'routing/routes/_paths';
import _isObject from 'lodash/isObject';

const INITIAL_STATE = {
  ...initialFormState
};

const getValueFromPayload = (value, selectKey) => {
  if (selectKey) {
    if (_isObject(value)) {
      return {
        value: value.id || value.value,
        selectValue: value
      };
    } else {
      return {
        value: null,
        selectValue: null
      };
    }
  }
  return {
    value
  };
};

const formReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.SET_REPORT_VESSEL:
      const { initialValues, template, ...restData } = payload;
      const { bunkers, ...restValues } = initialValues;

      const formInitialValues = { ...restValues };
      formInitialValues.consumptions = createFormConsumptionsState({ template, bunkers });

      return {
        ...createDynamicReportState({ ...restData, template, initialValues: formInitialValues })
      };

    case TYPES.SET_VALUE:
      const { sectionLabel, sectionActiveTab, key, subGroup, subGroupIndex, selectKey } = payload;

      const { value, selectValue } = getValueFromPayload(payload.value, selectKey);

      if (sectionLabel) {
        const array = [...state[sectionLabel]];

        if (subGroup) {
          array[sectionActiveTab][subGroup][subGroupIndex][key].value = value;
          array[sectionActiveTab][subGroup][subGroupIndex][key].error = '';

          if (selectKey)
            array[sectionActiveTab][subGroup][subGroupIndex][key].selectValue = selectValue;
        } else {
          array[sectionActiveTab][key].value = value;
          array[sectionActiveTab][key].error = '';

          if (selectKey) array[sectionActiveTab][key].selectValue = selectValue;
        }

        return {
          ...state,
          [sectionLabel]: array
        };
      } else if (!sectionLabel && subGroup) {
        const array = [...state[subGroup]];

        if (array[subGroupIndex][key]) {
          array[subGroupIndex][key].value = value;
          array[subGroupIndex][key].error = '';

          if (selectKey) array[subGroupIndex][key].selectValue = selectValue;
        }

        return {
          ...state,
          [subGroup]: array
        };
      }

      const updated = {
        ...state[payload.key],
        value,
        error: null
      };

      if (selectKey) updated.selectValue = selectValue;

      return {
        ...state,
        [payload.key]: updated
      };

    case TYPES.SET_WARNING:
      const { error, isRequired } = payload;

      if (payload.sectionLabel) {
        const array = [...state[payload.sectionLabel]];

        if (payload.subGroup) {
          array[payload.sectionActiveTab][payload.subGroup][payload.subGroupIndex][
            payload.key
          ].error = error;
          array[payload.sectionActiveTab][payload.subGroup][payload.subGroupIndex][
            payload.key
          ].isRequired = isRequired;
        } else {
          array[payload.sectionActiveTab][payload.key].error = error;
          array[payload.sectionActiveTab][payload.key].isRequired = isRequired;
        }

        return {
          ...state,
          [payload.sectionLabel]: array
        };
      } else if (!payload.sectionLabel && payload.subGroup) {
        const array = [...state[payload.subGroup]];

        array[payload.subGroupIndex][payload.key].error = error;
        array[payload.subGroupIndex][payload.key].isRequired = isRequired;
        return {
          ...state,
          [payload.subGroup]: array
        };
      }

      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          error: error,
          isRequired: isRequired
        }
      };

    case TYPES.SET_COMMENT:
      const { comment } = payload;

      if (payload.sectionLabel) {
        const array = [...state[payload.sectionLabel]];

        if (payload.subGroup) {
          array[payload.sectionActiveTab][payload.subGroup][payload.subGroupIndex][
            payload.key
          ].comment = comment;
          array[payload.sectionActiveTab][payload.subGroup][payload.subGroupIndex][
            payload.key
          ].error = '';
        } else {
          array[payload.sectionActiveTab][payload.key].comment = comment;
          array[payload.sectionActiveTab][payload.key].error = '';
        }

        return {
          ...state,
          [payload.sectionLabel]: array
        };
      } else if (!payload.sectionLabel && payload.subGroup) {
        const array = [...state[payload.subGroup]];

        array[payload.subGroupIndex][payload.key].comment = comment;
        array[payload.subGroupIndex][payload.key].error = '';

        return {
          ...state,
          [payload.subGroup]: array
        };
      }

      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          error: null,
          comment
        }
      };

    case TYPES.SET_FORM:
      return {
        ...state,
        ...payload
      };

    case TYPES.ADD_BUNKER_CONSUMPTION_COLUMN:
      return {
        ...state,
        consumptions: addSecondaryBunkerConsumptionColumn(payload, state.consumptions)
      };

    case TYPES.REMOVE_BUNKER_CONSUMPTION_COLUMN:
      return {
        ...state,
        consumptions: removeSecondaryBunkerConsumptionColumn(payload, state.consumptions)
      };

    case TYPES.AUTO_CALCULATE_BUNKER_CONSUMPTION_COLUMNS:
      return {
        ...state,
        consumptions: payload
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(`${paths.captain_reports}/`)) {
        return { ...initialFormState };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default formReducer;
