import React from 'react';

import DateWithIcon from 'common/components/dates/DateWithIcon';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const CreatedAtDate = ({ createdAt, issuedBy }) => {
  return (
    <div className="d-flex align-items-center">
      <DateWithIcon className="text-nowrap" date={createdAt} hideIcon />
      <div className="small-seperator text-violet"></div>
      <TextWithTooltip className="d-flex align-items-center text-violet fw-bold">
        <span className="lh-1">{issuedBy ? issuedBy : '-'}</span>
      </TextWithTooltip>
    </div>
  );
};

export default CreatedAtDate;
