import Engines from 'common/components/vessels/profile/tabs/info/components/me-propulsion/Engines';
import PerformanceCurves from 'common/components/vessels/profile/tabs/info/components/me-propulsion/PerformanceCurves';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';

const mePropulsion = [
  {
    fields: [
      {
        label: 'NO. OF ENGINES',
        key: 'me_count',

        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        key: 'engines',
        type: 'multiple',
        component: Engines,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },

          {
            label: 'ENGINE MANUFACTURER',
            key: 'maker.company_name',
            select_key: 'maker',
            type: 'select',
            inputProps: {
              placeholder: 'Add Name',
              isAsync: true,
              getOptionValue: option => option.id,
              getOptionLabel: option => getPartyDisplayName(option),
              defaultOptions: () =>
                getInitialAsyncValues('parties', null, null, { type: 'company' }),
              loadOptions: search => getAsyncOptions(search, 'parties', { type: 'company' }),
              isClearable: true
              // isCreatable: true
            }
          },
          {
            label: 'MODEL',
            key: 'model'
          },
          {
            label: 'NO. OF CYLINDERS',
            key: 'cylinders_no',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'NO. OF TURBOCHARGERS',
            key: 'turbochargers_no',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'REDUCTION GEAR RATIO',
            key: 'reduction_gear_ratio',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'NCR RPM',
            key: 'ncr_rpm',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'MCR RPM',
            key: 'mcr_rpm',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'MAX RPM',
            key: 'max_rpm',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'NCR POWER (kW)',
            key: 'ncr_kw',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'MCR POWER (kW)',
            key: 'mcr_kw',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'MAX POWER (kW)',
            key: 'max_kw',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          }
        ]
      },
      {
        key: 'engine_performance_curves',
        type: 'multiple',
        component: PerformanceCurves,
        data: [
          {
            label: 'ID',
            key: 'id',
            type: 'number',
            render: () => null
          },
          {
            label: 'RPM',
            key: 'rpm',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'LOAD (%)',
            key: 'load',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            }
          },
          {
            label: 'POWER (kW)',
            key: 'power',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            },
            className: 'text-nowrap'
          },
          {
            label: 'ISO CORRECTED SFOC (gr/kWh)',
            key: 'sfoc',
            type: 'number',
            inputProps: {
              placeholder: 'Add no.'
            },
            className: 'text-nowrap'
          }
        ]
      }
    ]
  },
  {
    header: 'PROPULSION',
    fields: [
      {
        label: 'PROPELLERS NO.',
        key: 'propeller_count',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'PROPELLER TYPE',
        key: 'propeller_type',
        inputProps: {
          placeholder: 'Add type'
        }
      },
      {
        label: 'PROPELLER DIAMETER (m)',
        key: 'propeller_diameter',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'PROPELLER PITCH (m/rev)',
        key: 'propeller_pitch',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'PROPELLER BLADES',
        key: 'propeller_blades_no',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF BOW THRUSTERS',
        key: 'bow_thrusters_count',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'BOW THRUSTERS RATING (kW)',
        key: 'bow_thrusters_rating',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'NO. OF STERN THRUSTERS',
        key: 'stern_thrusters_count',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      },
      {
        label: 'STERN THRUSTERS RATING (kW)',
        key: 'stern_thrusters_rating',
        type: 'number',
        inputProps: {
          placeholder: 'Add no.'
        }
      }
    ]
  }
];

export default mePropulsion;
