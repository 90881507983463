import SvgRender from '@/ts-common/components/general/SvgRender';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GroupBase, MenuProps, OnChangeValue, components } from 'react-select';
import { Button } from 'reactstrap';

import searchIcon from '@/ts-common/assets/svg/common/search.svg';
import x from '@/ts-common/assets/svg/common/x.svg';
import check from '@/ts-common/assets/svg/common/check-circle.svg';
import checkSolid from '@/ts-common/assets/svg/common/check-circle-solid.svg';
import xCircle from '@/ts-common/assets/svg/common/x-circle.svg';
import xCircleSolid from '@/ts-common/assets/svg/common/x-circle-solid.svg';
import { urlArrayLimit } from '@/ts-common/utils/urls';
import NavigationGroupTabs from '@/ts-common/components/buttons/NavigationGroupTabs';
import { copyTextToClipboard, getClipboardContents } from '@/ts-common/utils/texts';

type CustomInputProps = {
  value?: string;
  onChange: (newValue: string) => void;
};

const CustomInput = memo(function CustomInput({ value, onChange }: CustomInputProps) {
  const inputRef = useRef<HTMLDivElement>(null);
  const [cursorIndex, setCursorIndex] = useState(0);

  const isTextSelected = useCallback(() => {
    const selection = window.getSelection();
    return !!(selection && selection.type === 'Range');
  }, []);

  const clearSelectedText = useCallback(() => window.getSelection()?.removeAllRanges(), []);

  const selectText = useCallback((element: Node) => {
    const range = document.createRange();
    range.selectNode(element);

    window.getSelection()?.addRange(range);
  }, []);

  const updateCurrentCursorIndex = useCallback((index: number) => {
    if (index >= 0) setCursorIndex(index);
  }, []);

  const handleUserKeyPress = useCallback(
    async (event: KeyboardEvent) => {
      event.preventDefault();

      const ctrlKey = event.metaKey || event.ctrlKey;
      const code = event.code;

      if (!inputRef.current) return;

      let previousText = inputRef.current.innerHTML
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&');

      let currentIndex =
        previousText?.length && inputRef.current.dataset.index
          ? parseInt(inputRef.current.dataset.index)
          : 0;

      // CTRL+C - copy
      if (ctrlKey && code === 'KeyC') return copyTextToClipboard(previousText);

      // Left arrow
      if (code === 'ArrowLeft') return updateCurrentCursorIndex(currentIndex - 1);

      // Right arrow
      if (code === 'ArrowRight') {
        if (previousText.length > currentIndex) updateCurrentCursorIndex(currentIndex + 1);
        return;
      }

      if (isTextSelected()) {
        previousText = '';
        currentIndex = 0;
      }

      // CTRL+V - paste
      if (ctrlKey && code === 'KeyV') {
        const text = await getClipboardContents();

        if (text) {
          const newText = previousText + text;
          onChange(newText);
          updateCurrentCursorIndex(newText?.length);

          clearSelectedText();
        }

        return;
      }

      // CTRL+A - select all
      if (ctrlKey && code === 'KeyA') {
        clearSelectedText();
        selectText(inputRef.current);

        return;
      }

      // Delete character
      if (code === 'Backspace' || code === 'NumpadDecimal') {
        onChange(
          previousText.substring(0, currentIndex - 1) + previousText.substring(currentIndex)
        );
        updateCurrentCursorIndex(currentIndex - 1);

        clearSelectedText();

        return;
      }

      // character pressed
      if (event.key.length === 1) {
        const newText =
          previousText.substring(0, currentIndex) +
          event.key +
          previousText.substring(currentIndex);

        onChange(newText);
        updateCurrentCursorIndex(currentIndex + 1);

        clearSelectedText();
      }
    },
    [clearSelectedText, isTextSelected, onChange, selectText, updateCurrentCursorIndex]
  );

  const Cursor = () => <span className="react-select-multiple-menu__input-cursor"></span>;

  const renderInputValue = () => {
    const characters = Array.from(value ?? '');

    return !characters?.length ? (
      <Cursor />
    ) : (
      <>
        {characters.map((c, index) => {
          return (
            <Fragment key={index}>
              {index === cursorIndex ? <Cursor /> : null}
              {c === ' ' ? <>&nbsp;</> : c}
            </Fragment>
          );
        })}
        {cursorIndex === characters?.length ? <Cursor /> : null}
      </>
    );
  };

  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyPress);

    return () => {
      document.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div
      className={`react-select-multiple-menu__input flex-grow-1 flex-shrink-1 ${
        !value?.length ? 'is-empty' : ''
      }`}
      data-index={cursorIndex}
      ref={inputRef}
    >
      {renderInputValue()}
    </div>
  );
});

const MultipleMenu = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: MenuProps<Option, IsMulti, Group>) => {
  const [activeTab, setActiveTab] = useState<'all' | 'checked' | 'unchecked'>('all');
  const [menuPosition, setMenuPosition] = useState<'left' | 'right'>('left');

  const headerRef = useRef<HTMLDivElement>(null);

  const values = props.getValue();
  const getOptionValue = useCallback(
    (option: Option) => props.selectProps.getOptionValue(option),
    [props.selectProps]
  );

  const areAllSelected = useMemo(
    () =>
      props.options.every(option =>
        values.find(value => getOptionValue(value) === getOptionValue(option as Option))
      ),
    [getOptionValue, props.options, values]
  );
  const areNoneSelected = !values.length;

  const tabs = useMemo(
    () => [
      {
        label: 'All',
        onClick: () => setActiveTab('all'),
        size: 'sm',
        isActive: activeTab === 'all'
      },
      {
        label: 'Checked',
        onClick: () => setActiveTab('checked'),
        size: 'sm',
        isActive: activeTab === 'checked'
      },
      {
        label: 'Unchecked',
        onClick: () => setActiveTab('unchecked'),
        size: 'sm',
        isActive: activeTab === 'unchecked'
      }
    ],
    [activeTab]
  );

  const getSelectedOptions = () => {
    if (props.selectProps.isAsync) return props.options;

    if (props.selectProps.inputValue) {
      return props.options.filter(option =>
        props.selectProps
          .getOptionLabel(option as Option)
          .toLowerCase()
          .includes(props.selectProps.inputValue.toLowerCase())
      );
    }

    return props.options;
  };

  useEffect(() => {
    if (headerRef?.current) {
      const rect = headerRef.current.parentElement?.getBoundingClientRect();

      if (!rect) return;

      if (
        !(
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
      ) {
        setMenuPosition('right');
      }
    }
  }, []);

  const onInputChange = (value: string) => {
    props.selectProps.onInputChange(value, {
      action: 'input-change',
      prevInputValue: props.selectProps.inputValue
    });
  };

  return (
    <components.Menu className={`react-select-multiple-menu position-${menuPosition}`} {...props}>
      <div className="react-select-multiple-menu__header" ref={headerRef}>
        <SvgRender
          src={searchIcon}
          style={{ width: 14, height: 14 }}
          className="me-1 text-silver"
        />
        <CustomInput value={props.selectProps.inputValue} onChange={onInputChange} />
        <Button
          size="sm"
          color="white"
          className="d-inline-flex align-items-center border-0 react-select-multiple-menu__clear-btn ms-auto"
          disabled={!props.selectProps.inputValue}
          onClick={() => onInputChange('')}
        >
          <SvgRender src={x} style={{ width: 8, height: 8 }} />
          &nbsp;Clear
        </Button>
      </div>
      <div className="react-select-multiple-menu__actions">
        <Button
          color="link"
          size="sm"
          className={`cme-4 check-btn ${areAllSelected ? 'active' : ''}`}
          onClick={() =>
            !areAllSelected
              ? props.setValue(
                  getSelectedOptions() as OnChangeValue<Option, IsMulti>,
                  'select-option'
                )
              : ''
          }
          disabled={
            !props.selectProps.canSelectAllOptions && getSelectedOptions()?.length >= urlArrayLimit
          }
        >
          <SvgRender src={areAllSelected ? checkSolid : check} style={{ width: 12, height: 12 }} />
          Check all
        </Button>
        <Button
          color="link"
          size="sm"
          className={`uncheck-btn ${areNoneSelected ? 'active' : ''}`}
          onClick={() =>
            !areNoneSelected
              ? props.setValue([] as OnChangeValue<Option, IsMulti>, 'select-option')
              : ''
          }
        >
          <SvgRender
            src={areNoneSelected ? xCircleSolid : xCircle}
            style={{ width: 12, height: 12 }}
          />
          Uncheck all
        </Button>

        <NavigationGroupTabs className="ms-auto" tabs={tabs} />
      </div>
      <div className={`react-select-multiple-menu__list visible-${activeTab}`}>{children}</div>
    </components.Menu>
  );
};

export default MultipleMenu;
