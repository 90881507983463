import TYPES from './types';
import _uniqBy from 'lodash/uniqBy';

const DEFAULT_STATE = {
  isLoading: false,
  data: [],
  shouldRefetchTableData: false,
  paging: {
    current_page: 1,
    last_page: 1,
    per_page: 4,
    total: 0
  }
};

const INITIAL_STATE = {
  forms_for_correction: { ...DEFAULT_STATE },
  forms_to_finalize: { ...DEFAULT_STATE },
  forms_periodical_forms: { ...DEFAULT_STATE },
  forms_obligations: { ...DEFAULT_STATE },
  jobs: { ...DEFAULT_STATE },
  crew_rotations: { ...DEFAULT_STATE },
  evaluation_obligations: { ...DEFAULT_STATE },
  flag_extension_dispensation: { ...DEFAULT_STATE },
  events: { ...DEFAULT_STATE },
  findings: { ...DEFAULT_STATE },
  purchasing_requisitions_dlv: { ...DEFAULT_STATE }
};

const getTableStateData = (state, payload) => {
  const defaultTableStateData = [...state[payload.table].data, ...payload.data.data];

  switch (payload.table) {
    case 'evaluation_obligations':
      const mergedArray = state[payload.table].data.map(stateData => {
        const matchingItem = payload.data.data.find(
          payloadData => payloadData.name === stateData.name
        );

        if (matchingItem) {
          return {
            ...stateData,
            data: _uniqBy([...stateData.data, ...matchingItem.data], 'id')
          };
        } else {
          return stateData;
        }
      });

      return state[payload.table].data?.length ? [...mergedArray] : defaultTableStateData;

    default:
      return defaultTableStateData;
  }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_DASHBOARD_TABLE_DATA.START:
      return {
        ...state,
        [payload.table]: {
          ...state[payload.table],
          data: payload.params?.paging?.current_page === 1 ? [] : state[payload.table].data,
          isLoading: true,
          shouldRefetchTableData: false
        }
      };

    case TYPES.GET_DASHBOARD_TABLE_DATA.SUCCESS:
      let tableState = {};

      if (payload.data?.meta) {
        tableState.data = getTableStateData(state, payload);
        tableState.paging = payload.data.meta;
      } else {
        tableState = { ...payload.data };
      }

      return {
        ...state,
        [payload.table]: {
          ...state[payload.table],
          ...tableState,
          isLoading: false
        }
      };

    case TYPES.GET_DASHBOARD_TABLE_DATA.ERROR:
      return {
        ...state,
        [payload.table]: {
          ...state[payload.table],
          ...DEFAULT_STATE
        }
      };

    case TYPES.REFETCH_DASHBOARD_TABLE_DATA:
      return {
        ...state,
        [payload.table]: {
          ...state[payload.table],
          shouldRefetchTableData: true
        }
      };

    case TYPES.GET_DASHBOARD_FORM_PLANNINGS.SUCCESS:
      const formsInAlarm = payload.reduce((acc, planning) => {
        acc.push({
          form: planning?.form,
          alarm_state: true,
          fixed_value: true
        }); // fixed_value -> will be excluded from data count selectors

        return acc;
      }, []);

      return {
        ...state,
        forms_to_finalize: {
          ...state.forms_to_finalize,
          data: [...formsInAlarm, ...state.forms_to_finalize.data.filter(f => !f.alarm_state)]
        }
      };

    default:
      return state;
  }
};

export default reducer;
