import { RootState } from '@/store';
import { createSelector } from 'reselect';

const selectTableListsReducer = (state: RootState) => state.tables.lists as any;
const selectTableReducer = (state: RootState) => state.tables;

export const selectTableList = (state: RootState, label: string) =>
  selectTableListsReducer(state)?.[label];

export const selectTableListInvalid = (state: RootState, label: string) =>
  selectTableList(state, label)?.invalid;

export const selectTableListSelectedRows = (state: RootState, label: string) =>
  selectTableList(state, label)?.selectedRows;

export const selectTableListSelectedRowsCount = createSelector(
  [selectTableListSelectedRows],
  selectedRows => selectedRows?.length
);

export const selectTableListData = createSelector([selectTableList], list => list?.data || []);

export const selectTableListDataIds = createSelector(
  selectTableListData,
  data => data?.map((item: { id: number }) => item.id) || []
);

export const selectHasTableFilters = (state: RootState) =>
  selectTableReducer(state).hasSavedTableFilters;

export const selectTableListPaging = (state: RootState, label: string) =>
  selectTableList(state, label)?.paging || {};
