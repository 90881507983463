import React from 'react';
import SvgRender from 'common/components/general/SvgRender';
import { icon } from '@/ts-common/utils/files.ts';

const UnknownType = ({ file }) => {
  return (
    <div className="gallery__footer--item-unknown">
      <SvgRender src={icon(file.mime_type, file.extension)} style={{ height: 24, width: 24 }} />
    </div>
  );
};

export default UnknownType;
