const colors = {
  neutral: 'innactive',
  red: 'red',
  green: 'turquoise'
};

export const getCharacterColor = (password: string) => {
  if (!password) return colors.neutral;
  if (password.length < 12) return colors.red;
  return colors.green;
};

export const getUpperLower = (password: string) => {
  if (!password) return colors.neutral;
  const hasUpper = /[A-Z]+/.test(password);
  const hasLower = /[a-z]+/.test(password);

  if (hasUpper && hasLower) {
    return colors.green;
  }

  return colors.red;
};

export const getNumber = (password: string) => {
  if (!password) return colors.neutral;
  const hasNumber = /\d/.test(password);

  if (hasNumber) {
    return colors.green;
  }

  return colors.red;
};

export const specialChars = (password: string) => {
  if (!password) return colors.neutral;
  const hasChar = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password);

  if (hasChar) {
    return colors.green;
  }

  return colors.red;
};

export const isValidPassword = (password: string) => {
  const length = getCharacterColor(password);
  const letters = getUpperLower(password);
  const numb = getNumber(password);
  const special = specialChars(password);

  const isOk =
    length === colors.green &&
    letters === colors.green &&
    numb === colors.green &&
    special === colors.green;

  return isOk;
};
