import TYPES from './types';

export const setManualInnerData = (data, isEditLocked, isOnBoard) => dispatch => {
  dispatch({ type: TYPES.SET_MANUAL_INNER_DATA, payload: { data, isEditLocked, isOnBoard } });
};

export const updateManualInnerData = data => dispatch => {
  dispatch({ type: TYPES.UPDATE_MANUAL_INNER_DATA, payload: data });
};

export const setManualInnerChapters = (data, params) => dispatch => {
  dispatch({ type: TYPES.SET_MANUAL_INNER_CHAPTERS, payload: { data, params } });
};

export const setCurrentVersion = id => dispatch => {
  dispatch({ type: TYPES.SET_CURRENT_VERSION, payload: id });
};

export const updateCurrentVersion = data => dispatch => {
  dispatch({ type: TYPES.UPDATE_CURRENT_VERSION, payload: data });
};

export const toggleChapterCreationForm = isOpen => dispatch => {
  dispatch({ type: TYPES.TOGGLE_CHAPTER_CREATION_FORM, payload: isOpen });
};

export const updateManualChaptersTree = newChapter => dispatch => {
  dispatch({ type: TYPES.UPDATE_MANUAL_CHAPTERS_TREE, payload: newChapter });
};

export const updateChapter = updatedChapter => dispatch => {
  dispatch({ type: TYPES.UPDATE_CHAPTER, payload: updatedChapter });
};

export const setEdittingChapter = params => dispatch => {
  // params: { chapterId: true|false }
  dispatch({ type: TYPES.SET_EDITTING_CHAPTER, payload: params });
};

export const setAddingSubchapterTo = params => dispatch => {
  // params: { chapterId: true|false }
  dispatch({ type: TYPES.SET_ADDING_SUBCHAPTER_TO, payload: params });
};

export const seChapterExpanded = (params, closeOtherChapters) => dispatch => {
  // params: { chapterId: true|false }
  dispatch({ type: TYPES.SET_CHAPTER_EXPANDED, payload: { params, closeOtherChapters } });
};

export const setMoveModalChapterExpanded = (params, closeOtherChapters) => dispatch => {
  // params: { chapterId: true|false }
  dispatch({
    type: TYPES.SET_MOVE_MODAL_CHAPTER_EXPANDED,
    payload: { params, closeOtherChapters }
  });
};

export const toggleChaptersCollapse = areOpen => dispatch => {
  dispatch({ type: TYPES.EXPAND_OR_COLLAPSE_CHAPTERS, payload: areOpen });
};

export const setActiveChapterUid = id => dispatch => {
  dispatch({ type: TYPES.SET_ACTIVE_CHAPTER_UID, payload: id });
};

export const setChapterModalAction = payload => dispatch => {
  // payload: { id, isOpen, type }
  dispatch({ type: TYPES.SET_CHAPTER_MODAL_ACTION, payload });
};

export const searchInChaptersTree = payload => dispatch => {
  dispatch({ type: TYPES.SEARCH_IN_CHAPTERS_TREE, payload });
};

// --

export const setChapterPost = payload => dispatch => {
  dispatch({ type: TYPES.SET_CHAPTER_POST, payload });
};

export const togglePostForm = isOpen => dispatch => {
  dispatch({ type: TYPES.TOGGLE_POST_FORM, payload: isOpen });
};

export const setChapterPostAttachments = payload => dispatch => {
  dispatch({ type: TYPES.SET_CHAPTER_POST_ATTACHMENTS, payload });
};

// --

export const setComparedAtVersionChapterPost = data => dispatch => {
  dispatch({ type: TYPES.SET_COMPARED_AT_VERSION_POST, payload: data });
};

export const setComparedAtVersion = data => dispatch => {
  dispatch({ type: TYPES.SET_COMPARED_AT_VERSION, payload: data });
};

export const setComparedChangesOnly = data => dispatch => {
  dispatch({ type: TYPES.SET_COMPARED_CHANGES_ONLY, payload: data });
};

export const setNavigationPreventionModal = payload => dispatch => {
  dispatch({ type: TYPES.SET_NAVIGATION_PREVENTION_MODAL, payload });
};

export const setRelatedChaptersModal = payload => dispatch => {
  dispatch({ type: TYPES.SET_RELATED_CHAPTERS_MODAL, payload });
};
