import React from 'react';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { FieldsHeader } from 'captain-reports/templates/layout/_helpers';
import VisibleMultiField from 'captain-reports/templates/layout/fields/VisibleMultiField';
import { hasAtLeastOneVisibleField } from 'captain-reports/store/selectors';

const FieldsSection = ({ label, section, className, size }) => {
  const visible = useSelector(state => hasAtLeastOneVisibleField(state, section.fields));

  if (!visible) return null;

  return (
    <Col xs={size}>
      <FieldsHeader header={section.header} className={className || ''} />

      <Row>
        {section.fields.map(field => (
          <VisibleMultiField
            key={field.key}
            field={{ ...field, width: { xs: section.header === 'LUB OIL' ? 3 : 6 } }}
            label={label}
          />
        ))}
      </Row>
    </Col>
  );
};

const GeneratorsOilAndAir = ({ section, label }) => {
  const secondSection = section.extraFields()[0];
  const thirdSection = section.extraFields()[1];

  return (
    <>
      <Row>
        <FieldsSection section={section} className="border-bottom-grey" label={label} size={4} />
        <FieldsSection
          section={secondSection}
          className="border-bottom-grey"
          label={label}
          size={4}
        />
      </Row>

      <Row>
        <FieldsSection
          section={thirdSection}
          className="border-bottom-grey"
          label={label}
          size={8}
        />
      </Row>
    </>
  );
};

export default GeneratorsOilAndAir;
