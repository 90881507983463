import React from 'react';
import Textarea from 'common/components/form/inputs/Textarea';

const FreeText = ({ value, onChange, disabled }) => {
  return !disabled ? (
    <div className="w-100p pe-4">
      <Textarea
        className="mb-0 ms-0 border-0 evaluation-input"
        autoResize
        maxLength={130}
        white
        rows={1}
        placeholder="Add some free text"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  ) : null;
};

export default FreeText;
