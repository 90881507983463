import React from 'react';
import DateInput from 'common/components/form/inputs/date';
import moment from 'moment';

export const DateField = ({ label, value, error, onChange, className }) => (
  <div className={className || ''}>
    <div className="form-label">{label}</div>
    <div className="d-flex align-items-center">
      <DateInput value={value} error={error} onChange={onChange} className={'mb-0'} />
    </div>
  </div>
);

export const wageSectionOrder = ['recurring', 'ad-hoc', 'hourly', 'overtime_per_hour'];

export const constructNameFromType = type => {
  switch (type) {
    case 'recurring':
      return 'Recurring 30-Days';
    case 'hourly':
      return 'Hourly';
    case 'ad-hoc':
      return 'Ad hoc';
    case 'overtime_per_hour':
      return 'Overtime per Hour';
    default:
      return '-';
  }
};

export const getEquals = (array1 = [], array2 = []) => {
  return (
    array1?.filter(object1 => {
      return array2.some(object2 => {
        return object1.id === object2.id;
      });
    }) || []
  );
};

export const isContractExtendedPeriodReduced = (updatedContractToDate, contractToDate) => {
  if (moment(updatedContractToDate).diff(moment(contractToDate)) === 0) {
    return null;
  } else {
    return updatedContractToDate && contractToDate && updatedContractToDate !== contractToDate
      ? moment(updatedContractToDate, 'YYYY-MM-DD').isBefore(contractToDate, 'YYYY-MM-DD')
      : false;
  }
};
