import _get from 'lodash/get';
import useRouter from 'use-react-router';
import { Row, Col } from 'reactstrap';

import Table, { useTable } from 'common/components/table';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import { stringifyObj } from 'common/utils/urls';

import RoundedLineIconWithTooltip from 'common/components/general/RoundedLineIconWithTooltip';
import {
  isDraftEvaluation,
  getConsentSeamanStatusParams
} from 'crew/profile/main/evaluation/form/helpers';
import { isAuthorized } from 'utils/permissions/authorize';
import { useSelector } from 'react-redux';
import EvaluationDaysLeft from 'crew/components/EvaluationDaysLeft';
import paths from 'routing/routes/_paths';

import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import eyeSvg from 'common/assets/svg/common/eye.svg';
import draftSvg from 'common/assets/svg/common/draft.svg';
import crewSvg from 'common/assets/svg/common/crew.svg';

import SaveTableLayout from 'components/layouts/page/table/save-table';

import useTableTopFilter from 'common/components/filters/useTableTopFilter';

import { dateToUtc } from 'common/utils/dates';
import EvaluatorDepartment from 'crew/components/EvaluatorDepartment';
import { selectAccount } from 'store/account/selectors';
import permissions from 'common/utils/permissions/constants';
import config from '@/common/components/shared-table-configs/index.ts';

const LABEL = 'crew_evaluation_obligations';

const Evaluations = () => {
  const { history } = useRouter();

  const account = useSelector(selectAccount);
  const canSeeEvaluations = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.view
  ]);
  const canSeeOwnEvaluations = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.own_profile.view
  ]);
  const canEvaluate = isAuthorized(account, [
    permissions.onboard.crew.seaman_profile.evaluations.all_users.create
  ]);

  const isInCompletedTab = useTableTopFilter({ name: 'status' }, 'completed');

  const columns = config[LABEL].getColumns(isInCompletedTab);
  const topFilters = config[LABEL].getTopFilters();
  const TopFilters = config[LABEL].topFiltersComponent;

  const table = useTable({
    label: LABEL,
    defaultRequestParams: { paging: true, filters: true, sorting: true },
    columns,
    top: {
      filters: topFilters
    }
  });

  const activeTab = _get(topFilters[0], 'value', 'pending');

  return (
    <div className={`crew-evaluations ${activeTab}`}>
      <Row className="align-items-center mb-4 w-100p" noGutters>
        <Col xs="auto">
          <SaveTableLayout
            className="mb-0"
            label={LABEL}
            pageTitle={
              <div className="d-flex align-items-center">
                <span>
                  Evaluation Obligations
                  <strong className="ps-2 text-capitalize">/ {activeTab}</strong>
                </span>
              </div>
            }
          />
        </Col>
      </Row>

      <Table
        loader
        topFiltersComponent={<TopFilters />}
        rows={{
          reason: data => {
            const reasonName = _get(data, 'reason.name', '-');

            return (
              <div className="overflow-hidden flex-nowrap pe-1">
                <TextWithTooltip>{reasonName}</TextWithTooltip>
              </div>
            );
          },
          evaluator: data => {
            const evaluatorType = _get(data, 'evaluator_type', false);
            const evaluator = _get(data, 'evaluator', '');

            return evaluatorType === 'crew_member' ? (
              <span>{_get(evaluator, 'full_name', '')}</span>
            ) : (
              <EvaluatorDepartment evaluator={evaluator} evaluatorType={evaluatorType} />
            );
          },
          evaluatee: data => {
            const evaluateeName = _get(data, 'evaluatee.full_name', '-');
            const evaluateeId = _get(data, 'evaluatee.id', '');

            return evaluateeId || evaluateeId === 0 ? (
              <div className="overflow-hidden flex-nowrap pe-1">
                <TextWithTooltip>{evaluateeName}</TextWithTooltip>
              </div>
            ) : (
              '-'
            );
          },
          rank: data => {
            const rank = _get(data, 'crew_rank.name', '-');

            return rank;
          },
          days_left: data => <EvaluationDaysLeft data={data} />,
          evaluation_date: data => dateToUtc(data?.evaluation?.date)?.format('DD/MM/YYYY') || '-',
          evaluation_status: data => {
            const isDraft = isDraftEvaluation(data?.evaluation, true);
            const isCaptainForReview = _get(data, 'evaluation.sent_to_captain_for_review');

            return activeTab === 'pending' ? (
              <div className="d-flex align-items-center me-4 rounded-end rounded-lg">
                <RoundedLineIconWithTooltip
                  tooltipMessage="Draft"
                  icon={draftSvg}
                  activeColor={'secondary-gray'}
                  isActive={isDraft}
                  className="cme-10"
                />

                <RoundedLineIconWithTooltip
                  tooltipMessage={
                    isCaptainForReview ? 'Sent to Captain for Review' : 'Captain’s Review'
                  }
                  icon={crewSvg}
                  isActive={isCaptainForReview}
                  activeColor={'primary'}
                />

                <div className="small-separator text-platinum cme-12 cms-12"></div>
                <RoundedLineIconWithTooltip
                  onClick={
                    data?.evaluation?.id
                      ? () => {
                          history.push(
                            `${paths.crew}/${data.evaluatee.id}/evaluation/view/${data.evaluation.id}`
                          );
                        }
                      : false
                  }
                  icon={crewPlanning}
                  {...getConsentSeamanStatusParams(data)}
                />
              </div>
            ) : (
              <div className="w-100p d-flex align-items-center evaluation-status-completed ms-4 rounded-end rounded-lg">
                <RoundedLineIconWithTooltip
                  icon={crewPlanning}
                  {...getConsentSeamanStatusParams(data)}
                />
              </div>
            );
          },
          actions: data => {
            const obligationId = _get(data, 'id', '');
            const evaluateeId = _get(data, 'evaluatee_id', '');
            const evaluationId = _get(data, 'evaluation.id');

            const evaluateSeamanAction = canEvaluate
              ? {
                  className: 'text-white',
                  wrapperClassName: 'bg-primary',
                  label: 'Evaluate Seaman',
                  icon: crewPlanning,
                  onClick: evaluateeId
                    ? () => {
                        history.push({
                          pathname: `${paths.crew}/${evaluateeId}/evaluation/create`,
                          search: stringifyObj({
                            obligation_id: obligationId
                          })
                        });
                      }
                    : () => null
                }
              : {};

            const viewEvaluationAction =
              evaluationId &&
              (canSeeEvaluations || (evaluateeId === account.id && canSeeOwnEvaluations))
                ? {
                    className: 'text-primary',
                    wrapperClassName: 'bg-white',
                    label: 'View Evaluation',
                    icon: eyeSvg,
                    onClick: evaluateeId
                      ? () => {
                          history.push({
                            pathname: `${paths.crew}/${evaluateeId}/evaluation/${
                              isDraftEvaluation(data?.evaluation, true) ? 'draft' : 'view'
                            }/${evaluationId}`
                          });
                        }
                      : () => null
                  }
                : {};

            return {
              options: [
                activeTab === 'pending'
                  ? !data.evaluation
                    ? evaluateSeamanAction
                    : viewEvaluationAction
                  : activeTab === 'completed'
                  ? viewEvaluationAction
                  : {}
              ]
            };
          }
        }}
        {...table}
      />
    </div>
  );
};

export default Evaluations;
