import { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer, { DrawerHeader, FormDrawer, FormBody } from 'common/components/drawer';
import Participants from './Participants';
import Offers from './Offers';
import Spinner from 'common/components/general/Spinner';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import {
  isTripDrawerOpened,
  selectTripIDs,
  selectTripDisabled,
  selectTripPreventionModal,
  selectTripType
} from 'store/ticketing/selectors';
import { selectIsOnboard } from 'events/store/events/selectors';

import {
  getTrip,
  deleteTripParticipant,
  setTripPreventionModal,
  getTicketsTotals,
  closeTripDrawer
} from 'store/ticketing/actions';
import { fetchListOptions } from 'store/lists/actions';

import _uniq from 'lodash/uniq';

import LogHistoryView from './LogHistoryView';
import ParticipantsWithoutTicketingCase from './ParticipantsWithoutTicketingCase';
import { useDispatch } from 'react-redux';

const TicketsDrawer = ({ onCloseFallback, components }) => {
  const isOnBoard = useSelector(selectIsOnboard);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);

  const isOpen = useSelector(isTripDrawerOpened);
  const tripIDs = useSelector(selectTripIDs);
  const tripType = useSelector(selectTripType);
  const disabled = useSelector(selectTripDisabled);
  const preventionModal = useSelector(selectTripPreventionModal);

  const { open, message, ...rest } = preventionModal;

  const getTotals = useCallback(async () => await dispatch(getTicketsTotals()), [dispatch]);

  const onClose = () => {
    dispatch(closeTripDrawer());

    if (onCloseFallback) {
      onCloseFallback();
    }
  };

  const initTrips = useCallback(async () => {
    setIsLoading(true);

    for (let tripID of _uniq(tripIDs)) {
      await dispatch(getTrip({ id: tripID }));
    }

    setIsLoading(false);
  }, [dispatch, tripIDs]);

  const onDeleteTripParticipant = async params => {
    try {
      await dispatch(deleteTripParticipant(params));

      initTrips();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAddNew = useCallback(() => {
    setIsAddingNew(prev => !prev);
  }, []);

  useEffect(() => {
    if (isOpen && tripIDs?.length) {
      setIsAddingNew(false);
      initTrips();
    } else if (isOpen && !tripIDs?.length) {
      getTotals();
      setIsAddingNew(true);
    }
  }, [getTotals, initTrips, isOpen, tripIDs?.length]);

  useEffect(() => {
    if (!isOnBoard) dispatch(fetchListOptions('ticketing-types'));

    return () => {
      dispatch(closeTripDrawer());
    };
  }, [dispatch, isOnBoard]);

  return (
    <Drawer
      isOpen={isOpen}
      className="tickets-drawer"
      size="lg"
      dontCloseOnClickOutside={true}
      close={onClose}
    >
      <DrawerHeader>Create new ticket</DrawerHeader>

      <FormDrawer>
        <FormBody className="px-4 pb-2" key={isOpen}>
          {isLoading ? (
            <Spinner />
          ) : tripIDs?.length ? (
            _uniq(tripIDs).map(tripID => (
              <Fragment key={tripID}>
                {tripType === 'list' ? (
                  <ParticipantsWithoutTicketingCase
                    tripID={tripID}
                    onDeleteTripParticipant={disabled ? null : onDeleteTripParticipant}
                  />
                ) : (
                  <Participants
                    tripID={tripID}
                    onDeleteTripParticipant={disabled ? null : onDeleteTripParticipant}
                  />
                )}
                <Offers
                  tripID={tripID}
                  isAddingNew={isAddingNew}
                  toggleAddNew={toggleAddNew}
                  components={components}
                />
                <LogHistoryView />
              </Fragment>
            ))
          ) : (
            <>
              {tripType === 'list' ? <ParticipantsWithoutTicketingCase /> : <Participants />}
              <Offers isAddingNew={isAddingNew} toggleAddNew={toggleAddNew} />
            </>
          )}
        </FormBody>
      </FormDrawer>

      <DangerousActionModal
        transparent
        cancelText="CANCEL"
        actionText="PROCEED"
        actionHoverColor={'delete'}
        isOpen={open}
        body={<div className="text-center fs-18">{message}</div>}
        closeModal={() => dispatch(setTripPreventionModal({ open: false, message: '' }))}
        {...rest}
      />
    </Drawer>
  );
};

export default TicketsDrawer;

TicketsDrawer.propTypes = {
  onCloseFallback: PropTypes.func,
  components: PropTypes.objectOf(PropTypes.elementType)
};
