import { Props } from 'react-select';
import { DropdownIndicator } from 'common/components/form/inputs/Select';
import {
  ImportanceMultiValueLabel,
  ImportanceOption,
  ImportanceSingleValue
} from '@/common/components/selectors/_helpers';
import CollectionFilterSelector, { CollectionFilterProps } from './CollectionFilterSelector';

function ImportanceCollectionFilterSelector<Option extends { id: number; name?: string }>({
  memoizedRequestParams = { path: 'lists', params: { list: 'importances' } },
  ...props
}: Omit<Props<Option>, 'value'> & CollectionFilterProps<Option>) {
  return (
    <CollectionFilterSelector
      memoizedRequestParams={memoizedRequestParams}
      {...props}
      components={{
        DropdownIndicator,
        Option: ImportanceOption,
        MultiValueLabel: ImportanceMultiValueLabel,
        SingleValue: ImportanceSingleValue
      }}
      isAsync={false}
    />
  );
}

export default ImportanceCollectionFilterSelector;
