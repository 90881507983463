import TYPES from './types';
import { LOCATION_CHANGE } from 'connected-react-router';

import paths from 'routing/routes/_paths';
import _pickBy from 'lodash/pickBy';

import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure,
  toggleNestedItems,
  getTreeOfSearchedItems,
  getItemBreadcrumbs
} from 'common/components/tree-layout/utils/helpers';

const INITIAL_STATE = {
  initialized: false,

  isCreatingFolder: false,
  isLoading: false,
  isAddingSubFolders: {}, // { parent_id: true }
  isEditingFolders: {}, // { id: true }
  isEmpty: true,

  activeFolderId: null,
  activeFolder: null,
  activeFolderBreadcrumbs: [],

  expandedFolders: {},

  folders: {},
  rootFolders: [],
  subFolders: {},

  treeSearch: '',
  foldersInSearch: {},
  highlightedFolders: [],

  preventionModal: {
    isOpen: null,
    folder: null
  }
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.GET_FOLDERS.START:
      return {
        ...state,
        isLoading: true,
        highlightedFolders: !payload?.params?.search ? [] : state.highlightedFolders
      };

    case TYPES.GET_FOLDERS.SUCCESS:
      const subFolders = getSubItems(payload.data);

      if (payload?.params?.search) {
        const foldersInSearch = getTreeOfSearchedItems(
          state.folders,
          state.subFolders,
          payload.data
        );

        return {
          ...state,
          isLoading: false,
          foldersInSearch,
          highlightedFolders: payload.data.map(d => d.id),
          expandedFolders: _pickBy(foldersInSearch, (_, key) => state.subFolders[key])
        };
      }

      return {
        ...state,
        isLoading: false,
        isEmpty: !payload.data?.length,
        initialized: !state.initialized ? true : state.initialized,
        rootFolders: getRootItemsIDs(payload.data),
        subFolders,
        folders: normalizeTreeStructure(payload.data.map((f, i) => ({ ...f, sort_index: i }))),
        expandedFolders: payload.params?.search
          ? toggleNestedItems(subFolders, true)
          : state.expandedFolders,
        highlightedFolders: [],
        foldersInSearch: {}
      };

    case TYPES.GET_FOLDERS.ERROR:
      return { ...state, isLoading: false, rootFolders: [], subFolders: {}, expandedFolders: {} };

    case TYPES.EXPAND_FOLDER:
      return {
        ...state,
        expandedFolders: { ...state.expandedFolders, [payload.id]: payload.isOpen }
      };

    case TYPES.TOGGLE_ALL_FOLDERS:
      return {
        ...state,
        expandedFolders: toggleNestedItems(state.subFolders, payload)
      };

    case TYPES.EDIT_FOLDER.SUCCESS:
      return {
        ...state,
        folders: {
          ...state.folders,
          [payload.id]: {
            ...state.folders[payload.id],
            parent_id: payload.parent_id,
            name: payload.name
          }
        },
        activeFolder:
          state.activeFolder?.id === payload?.id
            ? { ...state.activeFolder, ...payload }
            : state.activeFolder
      };

    case TYPES.SET_ACTIVE_FOLDER_ID:
      return {
        ...state,
        activeFolderId: payload,
        activeFolderBreadcrumbs: payload ? getItemBreadcrumbs(payload, state.folders) : [],
        activeFolder: null
      };

    case TYPES.SET_TREE_SEARCH:
      return {
        ...state,
        treeSearch: payload.search
      };

    case TYPES.SET_IS_CREATING_FOLDER:
      return {
        ...state,
        isCreatingFolder: payload
      };

    case TYPES.SET_IS_EDITING_FOLDER:
      return {
        ...state,
        isEditingFolders: { ...state.isEditingFolders, ...payload }
      };

    case TYPES.SET_IS_ADDING_SUBFOLDER:
      return {
        ...state,
        isAddingSubFolders: { ...state.isAddingSubFolders, ...payload }
      };

    case TYPES.CREATE_FOLDER.SUCCESS:
      if (payload.parent_id && !state.expandedFolders[payload.parent_id]) {
        return {
          ...state,
          expandedFolders: { ...state.expandedFolders, [payload.parent_id]: true }
        };
      }

      return state;

    case TYPES.GET_FOLDER.SUCCESS:
      return {
        ...state,
        activeFolder: payload
      };

    case TYPES.ADD_FOLDER_FILES.START:
      return {
        ...state,
        activeFolder: {
          ...state.activeFolder,
          files: [...(state.activeFolder.files || []), ...payload.files]
        }
      };

    case TYPES.DELETE_FOLDER_FILE.START:
      return {
        ...state,
        activeFolder: {
          ...state.activeFolder,
          files: (state.activeFolder.files || []).filter(f => f.id !== payload.file_id)
        }
      };

    case TYPES.DELETE_FOLDER.SUCCESS:
      if (payload.id === state.activeFolderId)
        return { ...state, activeFolder: null, activeFolderId: null };

      return state;

    case TYPES.SET_PREVENTION_MODAL:
      return {
        ...state,
        preventionModal: {
          isOpen: payload.isOpen,
          folder: payload.id ? state.folders[payload.id] : null
        }
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(`${paths.filesystem}`)) {
        return {
          ...state,
          initialized: false,
          activeFolderId: null,
          activeFolder: null,
          activeFolderBreadcrumbs: [],
          isCreatingFolder: false,
          expandedFolders: {},
          treeSearch: ''
        };
      }

      return state;

    default:
      return state;
  }
};
export default reducer;
