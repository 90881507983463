import React from 'react';

import RoundedButtonWithTooltip from 'common/components/buttons/RoundedButtonWithTooltip';
import SvgRender from 'common/components/general/SvgRender';

import recommendedForPromotionIcon from 'common/assets/svg/common/promotion.svg';
import notRecommendedForPromotionIcon from 'common/assets/svg/common/not-recommended-for-promotion.svg';

import recommendedForReemploymentIcon from 'common/assets/svg/common/reemployed.svg';
import notRecommendedForReemploymentIcon from 'common/assets/svg/common/not-reemployed.svg';
import recommendedForReemploymentGrayOutIcon from 'common/assets/svg/common/not-recommended-for-reemploment.svg';

import { isRankRecommendedForPromotion } from 'crew/utils/helpers';
import {
  getRecommendedForPromotionExpandedText,
  getRecommendedForReemploymentExpandedText
} from 'crew/profile/main/evaluation/form/helpers';

const Recommendations = ({ active, className }) => {
  const recommendedForPromotion = active?.recommended_for_promotion;
  const recommendedForReemployment = active?.recommended_for_reemployment;
  const recommendedForPromotionComment = active?.recommended_for_promotion_comment;
  const recommendedForReemploymentComment = active?.recommended_for_reemployment_comment;

  return (
    <div className={`mt-2 d-flex ${className || ''}`}>
      {isRankRecommendedForPromotion(active?.rank?.name) ? (
        <RoundedButtonWithTooltip
          tooltipMessage={
            recommendedForPromotionComment ||
            recommendedForPromotion === null ||
            recommendedForPromotion
              ? ''
              : 'Not Recommended for Promotion'
          }
          expandedText={getRecommendedForPromotionExpandedText(
            recommendedForPromotion,
            recommendedForPromotionComment
          )}
          className="p-0 me-3 d-flex align-items-center justify-content-center position-relative"
          style={{ width: 56, height: 56 }}
        >
          <SvgRender
            src={
              recommendedForPromotion ? recommendedForPromotionIcon : notRecommendedForPromotionIcon
            }
            style={{ width: 24, height: 24 }}
          />
        </RoundedButtonWithTooltip>
      ) : null}

      <RoundedButtonWithTooltip
        className="p-0 d-flex align-items-center justify-content-center position-relative"
        expandedText={getRecommendedForReemploymentExpandedText(
          recommendedForReemployment,
          recommendedForReemploymentComment
        )}
        style={{ width: 56, height: 56 }}
      >
        <SvgRender
          src={
            recommendedForReemployment === null
              ? recommendedForReemploymentGrayOutIcon
              : recommendedForReemployment
              ? recommendedForReemploymentIcon
              : notRecommendedForReemploymentIcon
          }
          style={{ width: 32, height: 32 }}
        />
      </RoundedButtonWithTooltip>
    </div>
  );
};

export default Recommendations;
