import React from 'react';
import paths from 'routing/routes/_paths';

import retry from 'common/utils/lazy-retry';
import { selectJobField } from 'common/components/jobs/_base/store/selectors';
import permissions from 'common/utils/permissions/constants';

const Jobs = React.lazy(() => retry(() => import('views/jobs')));

const baseJobsRoute = `${paths.jobs}/:type(regular|flag-extension-dispensation)`;

const jobs = [
  {
    path: [`${baseJobsRoute}`, `${baseJobsRoute}/:id`, paths.findings, `${paths.findings}/:id`],
    component: Jobs,
    app: true,
    type: 'private',
    exact: true,
    permissions: {
      0: [permissions.onboard.jobs.list.view],
      1: [permissions.onboard.jobs.list.view],
      2: [permissions.onboard.jobs.list.findings.view]
    },
    documentTitle: (state, { location }) =>
      location.pathname.startsWith(paths.findings)
        ? 'Findings'
        : location.pathname === `${paths.jobs}/regular` ||
          location.pathname === `${paths.jobs}/flag-extension-dispensation`
        ? 'Jobs'
        : selectJobField(state, 'document_title')
  }
];

export default jobs;
