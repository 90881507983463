import CircledButton from '@/ts-common/components/buttons/CircledButton';
import styled from '@emotion/styled';
import { FC, useCallback, useState } from 'react';
import { Attachment, Size } from '@/ts-common/types/files';
import DangerousActionModal from '@/ts-common/components/modals/DangerousActionModal';

const iconSize: {
  [key in 'lg' | 'sm' | 'xs']: number;
} = {
  lg: 18,
  sm: 12,
  xs: 10
};

const svgSize: {
  [key in 'lg' | 'sm' | 'xs']: number;
} = {
  lg: 8,
  sm: 4,
  xs: 4
};

type FileRemoveButtonProps = {
  size: Size;
  file: Attachment;
  onClick: () => void;
};

const FileRemoveButton: FC<FileRemoveButtonProps> = ({ size, onClick, file }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  return (
    <Container className="file-remove-icon" size={size} data-testid="files-remove-button">
      <CircledButton
        type="remove"
        svgStyle={{ width: svgSize[size], height: svgSize[size] }}
        size={iconSize[size]}
        onClick={openModal}
      />

      <DangerousActionModal
        onAccept={async () => {
          await onClick();
          closeModal();
        }}
        onCancel={closeModal}
        isOpen={isModalOpen}
        header={'Delete File'}
      >{`Are you sure you want to delete the file "${file?.filename}"?`}</DangerousActionModal>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  right: ${({ size }: { size: Size }) => `-${iconSize?.[size] / 2 - 2}px`};
  top: -2px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
`;

export default FileRemoveButton;
