import PropTypes from 'prop-types';

export const Importance = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string,
  sort_index: PropTypes.number
});

export const JobStatusType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  sort_index: PropTypes.number,
  all_departments: PropTypes.bool,
  can_select_from_onboard: PropTypes.bool,
  color: PropTypes.string,
  completion: PropTypes.number,
  departments: PropTypes.arrayOf(PropTypes.number),
  edit_side: PropTypes.oneOf(['vessel', 'office']),
  is_for_office: PropTypes.bool,
  is_for_vessel: PropTypes.bool,
  progress: PropTypes.string
});

export const Job = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  importance: Importance,
  importance_id: PropTypes.number,
  status_id: PropTypes.number,
  status: JobStatusType
});
