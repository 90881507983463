import { Fragment } from 'react';
import {
  getFormFieldParams,
  getFieldName,
  getFormFieldHelpText,
  getFormFieldID,
  getFieldTypeLabel
} from 'common/components/forms/digital/state-utils';
import { renderInputType, renderViewType } from 'common/components/forms/digital/render-helpers';

import { Row } from 'reactstrap';
import Cell from './Cell';
import RemoveRowButton from './RemoveRowButton';
import HelpTooltip from 'common/components/forms/_components/HelpTooltip';
import PreviousValuesTooltip from '../previous-values-tooltip';
import useTooltipID from 'common/utils/hooks/useTooltipID';

const Input = ({
  formField,
  disabled,
  formState,
  setFormState,
  fields,
  headerFormState,
  formSubmission,
  isFormForVessel
}) => {
  const formFieldHelpText = getFormFieldHelpText(formField);

  return (
    <div className="d-flex position-relative">
      <Row className={`flex-1 ${formFieldHelpText ? 'me-2' : 'w-100p'}`}>
        {renderInputType({
          formField,
          state: formState,
          setState: setFormState,
          disabled,
          fields,
          headerFormState,
          formSubmission,
          isFormForVessel
        })}
      </Row>
      {formFieldHelpText ? (
        <HelpTooltip className="mt-1 position-absolute right-0" text={formFieldHelpText} />
      ) : null}
    </div>
  );
};

const Preview = ({ formField, value, fields, previousValues }) => {
  const { tooltipID, avoidRender } = useTooltipID('previous-values');
  const formFieldID = getFormFieldID(formField);
  const fieldTypeLabel = getFieldTypeLabel(formField);

  return (
    <>
      <Row
        className="table-form-field-submission__cell-inner"
        data-type={fieldTypeLabel}
        id={`${tooltipID}-${formFieldID}`}
      >
        {renderViewType(formField, value, null, fields)}
      </Row>
      {!avoidRender ? (
        <PreviousValuesTooltip
          formField={formField}
          tooltipID={`${tooltipID}-${formFieldID}`}
          previousValues={previousValues}
        />
      ) : null}
    </>
  );
};

const Title = ({ column, formField, dynamic }) => {
  return (
    <strong className="fs-12 text-primary">
      {dynamic || column.has_same_fields ? getFieldName(formField) : column.title}
    </strong>
  );
};

const TableRow = ({
  formField,
  index,
  heading,
  disabled,
  formState,
  setFormState,
  formValues = {},
  formPreviousValues = {},
  previewMode,
  dynamic,
  removeTableRow,
  isLast,
  isFirst,
  cellWidth,
  formFields,
  headerFormState,
  formSubmission,
  isFormForVessel
}) => {
  const { fields, columns } = getFormFieldParams(formField);

  return (
    <div className="table-form-field-submission__row">
      {columns.map(column => {
        const rowFormField = fields[`${index}_${column.column_index}`];
        const rowFormFieldValue = formValues ? formValues[rowFormField?.id] : null;
        const rowFormFieldPerviousValues = formPreviousValues
          ? formPreviousValues[rowFormField?.id]
          : null;

        return (
          <Fragment key={column.column_index}>
            <Cell width={column.width || cellWidth}>
              {heading ? (
                <Title column={column} formField={rowFormField} dynamic={dynamic} />
              ) : previewMode ? (
                <Preview
                  formField={rowFormField}
                  value={rowFormFieldValue}
                  previousValues={rowFormFieldPerviousValues}
                  fields={formFields}
                />
              ) : (
                <Input
                  formField={rowFormField}
                  disabled={disabled}
                  formState={formState}
                  setFormState={setFormState}
                  fields={formFields}
                  headerFormState={headerFormState}
                  formSubmission={formSubmission}
                  isFormForVessel={isFormForVessel}
                />
              )}
            </Cell>

            {!previewMode && dynamic && column.column_index === columns.length - 1 ? (
              <RemoveRowButton
                heading={heading}
                index={index}
                removeTableRow={removeTableRow}
                isLast={isLast}
                isFirst={isFirst}
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

export default TableRow;
