import BooleanView from 'common/components/forms/digital/components/boolean/BooleanView';

import _get from 'lodash/get';

const CheckboxWithDetailsView = ({ formField, value }) => {
  return (
    <div className="fs-12 text-primary">
      <div>
        <BooleanView formField={formField} value={_get(value, 'option', false)} />
      </div>
      {value?.additional_info ? <div className="mt-1">{value?.additional_info}</div> : null}
    </div>
  );
};

export default CheckboxWithDetailsView;
