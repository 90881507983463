import React from 'react';

import { selectCrewBirthday } from 'crew/store/profile/selectors';
import { strToNumber, numberToStr } from 'common/utils/numbers';
import { getProfileAge } from 'common/utils/profiles';
import { useAppSelector } from '@/store/hooks';
import { isAuthorized } from '@/utils/permissions/authorize';
import permissions from '@/common/utils/permissions/constants';
import { selectAccount } from '@/store/account/selectors';

const Totals = ({ components }) => {
  const birthday = useAppSelector(selectCrewBirthday);
  const account = useAppSelector(selectAccount);
  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const isPermissionVisible = isAuthorized(account, [
    permissions.office.crew.seaman_profile.personal.main_info.view
  ]);
  const isVisible = isOnBoard || isPermissionVisible;

  return (
    <div className="crew-sidebar__totals d-flex flex-column h-100p">
      {components.RatingGraph ? <components.RatingGraph /> : null}

      {isVisible ? (
        <div
          className={`crew-sidebar__totals-age d-flex align-items-center border rounded text-primary`}
        >
          <span className="text-violet fw-medium fs-12">Age</span>
          <strong className="fs-16">
            {getProfileAge(birthday) ? numberToStr(strToNumber(getProfileAge(birthday))) : '-'}
          </strong>
          <span className="fw-medium fs-12">years old</span>
        </div>
      ) : null}
    </div>
  );
};

export default Totals;
