import { useSelector } from 'react-redux';
import { selectDashboardTotals } from 'store/dashboard/totals/selectors';

import TotalsBar from 'common/components/graph/TotalsBar';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const ContentPmsJobs = () => {
  const totals = useSelector(state => selectDashboardTotals(state, 'pms_jobs'));

  return totals ? (
    <div className="dashboard__totals-pms_jobs">
      <TotalsBar
        legends={[
          {
            label: 'Bec. Overdue',
            total: totals?.becoming_overdue || 0,
            color: variables.goldBell
          },
          {
            label: 'Overdue',
            total: totals?.overdue || 0,
            color: variables.red
          },
          {
            label: 'Corrections',
            total: totals?.for_corrections || 0,
            color: variables.crimson
          }
        ]}
      />
    </div>
  ) : null;
};

export default ContentPmsJobs;
