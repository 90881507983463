import _get from 'lodash/get';

export const getCategoryWarnings = (crew, category) => {
  const expired = (_get(crew, 'document_warnings.expired') || []).filter(
    warning => _get(warning, 'category') === category
  );
  const missing = (_get(crew, 'document_warnings.missing') || []).filter(
    warning => _get(warning, 'category') === category
  );
  const reminder = (_get(crew, 'document_warnings.reminder') || []).filter(
    warning => _get(warning, 'category') === category
  );

  const allWarnings = [
    ...expired.map(warning => ({ ...warning, type: 'expired' })),
    ...missing.map(warning => ({ ...warning, type: 'missing', className: 'missing' })),
    ...reminder.map(warning => ({ ...warning, type: 'reminder' }))
  ];
  return {
    missing,
    reminder,
    expired,
    all: allWarnings
  };
};
