import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import useD3Config from 'common/components/graph/base/hooks/useD3Config';
import useD3Element from 'common/components/graph/base/hooks/useD3Element';
import useD3Plots from 'common/components/graph/base/hooks/useD3Plots';
import useD3Axes from 'common/components/graph/base/hooks/useD3Axes';

import D3Tooltip from 'common/components/graph/base/components/D3Tooltip';
import D3Legends from 'common/components/graph/base/components/D3Legends';
import D3XLabel from 'common/components/graph/base/components/D3XLabel';

import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';

import { usePrevious } from 'utils/hooks';

import {
  mapDataFromPlotToAxis,
  formatDataForTooltip,
  combineDataFromAllPlots
} from 'common/components/graph/base/helpers/_functions';

import { v4 as uuid } from 'uuid';
import { getYAxes } from './helpers/axes';
import useD3Zoom from './hooks/useD3Zoom';

const D3Graph = ({
  plots,
  schema,
  style = {},
  events,
  axesConfig,
  containerRef,
  graphUID,
  showLegends,
  showXLabel,
  legendInfo,
  dataFromAllPlots,
  setParentDisabledPlots,
  className
}) => {
  const canvasRef = useRef();

  const [disabledPlots, setInternalDisabledPlots] = useState([]);
  const setDisabledPlots = val => {
    setParentDisabledPlots(val);
    setInternalDisabledPlots(val);
  };

  const setGroupGrapAxesInfo = events.setValueToGroupGraphsAxesInfo;

  useEffect(() => {
    setGroupGrapAxesInfo({ graphUID, disabledPlots, dataFromAllPlots });
  }, [disabledPlots, graphUID, setGroupGrapAxesInfo, dataFromAllPlots]);

  const { getGraphStyle, getGraphOption, getYAxisOption, getXAxisOption, graphSVGAttributes } =
    useD3Config(style, schema, axesConfig);

  const { renderAxes, yAxes } = useD3Axes(
    plots,
    schema,
    events,
    getGraphStyle,
    getGraphOption,
    getYAxisOption,
    getXAxisOption,
    disabledPlots,
    graphUID,
    dataFromAllPlots,
    axesConfig
  );

  const { renderPlots, renderPlotComponents } = useD3Plots(
    graphUID,
    plots,
    schema,
    events,
    getGraphStyle,
    yAxes,
    getYAxisOption,
    axesConfig
  );

  const renderGraph = ({ element, getDimensions, memoryElement }) => {
    const axesDetails = renderAxes(element, getDimensions, renderPlots, memoryElement);

    renderPlots(element, memoryElement, axesDetails.x, axesDetails.y, getDimensions);
    graphSVGAttributes.forEach(svgAtrribute => {
      element.attr(svgAtrribute.key, svgAtrribute.value);
    });
    if (axesDetails.leftOffset !== 0) {
      const event = new CustomEvent('graph-rendered', {
        detail: axesDetails
      });

      const elementNode = element.node();
      if (elementNode) elementNode.dispatchEvent(event);
    }
  };

  const { graphRef, legendsRef, getDimensions } = useD3Element(
    renderGraph,
    canvasRef,
    events,
    disabledPlots,
    schema,
    [plots, schema, style, events]
  );

  const { width, height } = getDimensions();

  return (
    <>
      <div
        className={`d3-graph ${events.getElementClass('zoom')} ${
          className || ''
        } position-relative`}
        ref={containerRef}
      >
        <div className="w-100p flex-1 d-flex" ref={graphRef}>
          <svg
            id={'svg-' + graphUID}
            className={`d3-graph__svg d3-graph__svg--shown position-relative`}
            style={{
              height: '100%',
              width: '100%',
              marginRight: '0px',
              marginLeft: `0px`
            }}
            width={width}
            height={height}
          >
            {axesConfig?.x?.hideTicks ? null : (
              <g className="x-axis">
                <text className="x-axis-title" />
              </g>
            )}

            {axesConfig?.y?.hideTicks
              ? null
              : yAxes.map((e, index) => (
                  <g key={`y-axis-${index}`} className={`y-axis y-axis-${index}`}>
                    <g className="y-axis-ticks__container"></g>
                    <g className={`y-axis-title__container y-axis-title__container-${index}`}>
                      <text className={`y-axis-title y-axis-title-${index}`} />
                    </g>
                  </g>
                ))}
            {renderPlotComponents(graphUID)}
          </svg>
          <canvas
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              marginRight: '0px',
              marginLeft: `0px`
            }}
            width={width}
            height={height}
            ref={canvasRef}
          />
        </div>
      </div>
      {showXLabel ? <D3XLabel axesConfig={axesConfig} /> : null}
      {showLegends ? (
        <D3Legends
          legendsRef={legendsRef}
          plots={plots}
          setDisabledPlots={setDisabledPlots}
          disabledPlots={disabledPlots}
          legendInfo={legendInfo}
          graphUID={graphUID}
        />
      ) : null}
    </>
  );
};

const OptimizedD3Graph = ({
  tooltipOptions,
  plots,
  schema,
  events,
  data,
  showLegends,
  showXLabel,
  axesConfig,
  className,
  legendInfo,
  debug,
  style,
  callbacks
}) => {
  const containerRef = useRef();

  const [disabledPlots, setInteralDisabledPlots] = useState([]);

  const graphUIDRef = useRef(uuid());
  const graphUID = graphUIDRef.current;
  const [groupedDataByXAxis, setGroupedDataByXAxis] = useState([]);
  const [mappedPlots, setMappedPlots] = useState([]);

  const [dataFromAllPlots, setDataFromAllPlots] = useState([]);

  const prevPlots = usePrevious(plots);
  const prevData = usePrevious(data);
  const prevSchema = usePrevious(schema);

  const setDisabledPlots = disabled => {
    setInteralDisabledPlots(disabled);
    if (callbacks?.setDisabledPlots) {
      const plotLabels = disabled.map(disabledPlotIndex => plots[disabledPlotIndex].label);
      callbacks.setDisabledPlots(plotLabels);
    }
  };

  useEffect(() => {
    if (!_isEqual(plots, prevPlots) || !_isEqual(data, prevData) || !_isEqual(schema, prevSchema)) {
      // The above is a performance improvement because every time a rerender was triggered through the parent it would pass on to the graph, which is unneccessary and adds performance overhead.

      const newMappedPlots = plots.map(mapDataFromPlotToAxis(graphUID, schema, data));
      const yAxes = getYAxes(newMappedPlots, axesConfig?.y);

      setMappedPlots(newMappedPlots);

      setDataFromAllPlots(combineDataFromAllPlots(newMappedPlots, yAxes));
      setGroupedDataByXAxis(formatDataForTooltip(newMappedPlots, yAxes));
    }
  }, [plots, schema, data, prevPlots, prevData, prevSchema, graphUID, axesConfig?.y]);

  const { isLoading } = useD3Zoom({ events, schema, graphUID });

  const memoizedGraph = useMemo(
    () => (
      <D3Graph
        plots={mappedPlots}
        schema={schema || {}}
        style={style}
        debug={debug}
        events={events}
        axesConfig={axesConfig}
        containerRef={containerRef}
        graphUID={graphUID}
        showLegends={showLegends}
        showXLabel={showXLabel}
        legendInfo={legendInfo}
        dataFromAllPlots={dataFromAllPlots}
        setParentDisabledPlots={setDisabledPlots}
        className={className}
      />
    ),
    [
      mappedPlots,
      axesConfig,
      dataFromAllPlots,
      graphUID,
      schema,
      events,
      legendInfo,
      showLegends,
      showXLabel,
      className,
      style,
      debug
    ]
  );

  return (
    <div
      className={`d3-graph__container ${isLoading ? 'opacity-0' : 'base-transition  opacity-10'}`}
    >
      {memoizedGraph}
      {_get(tooltipOptions, 'disabled', false) ? null : (
        <D3Tooltip
          axesConfig={axesConfig}
          yAxes={events ? events.groupGraphsAxesInfo[graphUID]?.y : null}
          xAxis={events ? events.groupGraphsAxesInfo[graphUID]?.x : null}
          eventsID={events ? events.eventsID : null}
          graphUID={graphUID}
          schema={schema}
          containerRef={containerRef}
          options={tooltipOptions}
          points={groupedDataByXAxis}
          disabledPlots={disabledPlots}
          forcePopperTooltip={_get(tooltipOptions, 'forcePopperTooltip')}
        />
      )}
    </div>
  );
};

export default OptimizedD3Graph;

OptimizedD3Graph.propTypes = {
  callbacks: PropTypes.shape({
    setDisabledPlots: PropTypes.func
  })
};
