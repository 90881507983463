import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

const Tag = ({ name, className }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <ContainerTag
      className={`fs-12 fw-medium align-items-center text-white cpx-12 cpy-4 border-radius-base cme-4 ${
        !isOnBoard ? 'bg-dark' : 'bg-primary'
      } ${className || ''}`}
    >
      {name}
    </ContainerTag>
  );
};

const ContainerTag = styled.div`
  border-radius: 5px;
`;

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Tag;
