import SvgRender from '@/ts-common/components/general/SvgRender';
import { icon } from '@/ts-common/utils/files';
import { FC } from 'react';
import { Attachment } from '@/ts-common/types/files';

type UnknownTypeProps = {
  file: Attachment;
};

const UnknownType: FC<UnknownTypeProps> = ({ file }) => {
  return (
    <div className="gallery__center--container-unknown">
      <SvgRender src={icon(file.mime_type, file.extension)} style={{ height: 64, width: 64 }} />
      <span className="fs-14 fw-normal mt-2 text-white">
        No preview available for this file type.
      </span>
    </div>
  );
};

export default UnknownType;
