import React from 'react';
import _get from 'lodash/get';
import styled from '@emotion/styled';

import useTooltipID from 'common/utils/hooks/useTooltipID';
import ProfileImage from 'common/components/general/ProfileImage';
import CountryFlag from 'common/components/general/CountryFlag';
import Tooltip from 'common/components/general/Tooltip';
import SvgRender from 'common/components/general/SvgRender';
import avatar from 'common/assets/svg/avatars/crew.svg';
import error from 'common/assets/svg/common/error.svg';
import PageLink from 'common/components/general/page-link';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

import paths from 'routing/routes/_paths';

const PersonBasicInfo = ({
  person,
  label,
  withFlag,
  vessel,
  hiddenTooltip,
  warning,
  profileClassName,
  profileTooltipContent,
  linkPath = paths.crew,
  bottomInfo,
  className = '',
  smallView = false,
  signerType,
  isLinkEnabled,
  profileImageProps = {},
  innerTooltipClassName = '',
  permissions,
  imgProps
}) => {
  const { avoidRender, tooltipID } = useTooltipID('person-basic-info');

  if (avoidRender) return null;

  return person ? (
    <div
      className={`person-basic-info d-flex align-items-center flex-nowrap text-nowrap overflow-hidden ${className} ${
        smallView ? 'fs-12' : ''
      }`}
    >
      <div id={`profile-${tooltipID}`} className="d-flex">
        <ProfileImage
          className={`crew-small-avatar ${smallView ? 'cme-10' : 'cme-12'} ${
            profileClassName || ''
          }`}
          image={_get(person, 'photo.url', null)}
          fallback={avatar}
          imgProps={imgProps ? imgProps : { width: 24, height: 24 }}
          {...profileImageProps}
        />

        {profileTooltipContent ? (
          <Tooltip target={`profile-${tooltipID}`} innerClassName="max-width-344" fade={false}>
            {profileTooltipContent}
          </Tooltip>
        ) : null}
      </div>

      <div className="d-flex flex-column justify-content-center me-1 flex-1 overflow-hidden">
        {label ? <div className="form-label">{label}</div> : null}

        <div className="d-flex flex-1 align-items-center overflow-hidden w-100p flex-nowrap">
          {!hiddenTooltip && warning && tooltipID ? (
            <>
              <div id={`warning-${tooltipID}`} className="d-inline-flex align-items-center cme-4">
                <SvgRender src={error} style={{ width: 14, height: 13 }} />
              </div>
              <Tooltip
                target={`warning-${tooltipID}`}
                innerClassName={innerTooltipClassName}
                fade={false}
              >
                {warning}
              </Tooltip>
            </>
          ) : null}

          <div className="flex-1 overflow-hidden d-flex align-items-center">
            {smallView ? (
              <div className="width-4 cme-6">
                {signerType ? (
                  <span className="fs-10 text-truncate fw-medium d-inline-block text-violet">
                    {signerType ? signerType : null}
                  </span>
                ) : null}
              </div>
            ) : null}

            {isLinkEnabled ? (
              <PersonContainer>
                <PageLink
                  id={`crew-${tooltipID}`}
                  className="fw-medium max-width-100p"
                  to={`${linkPath}/${person.id}`}
                  permissions={permissions}
                  hideLinkIcon
                >
                  {person.full_name}
                </PageLink>
              </PersonContainer>
            ) : (
              <TextWithTooltip className="h-auto"> {person.full_name}</TextWithTooltip>
            )}
          </div>
        </div>

        {vessel || bottomInfo ? (
          <div className="w-100p overflow-hidden">
            <span className="fs-10 text-truncate fw-medium max-width-100p d-inline-block align-middle text-violet ">
              {bottomInfo ? bottomInfo : vessel?.name}
            </span>
          </div>
        ) : null}
      </div>
      {withFlag && _get(person, 'details.nationality.country', '') ? (
        <CountryFlag
          className="crew-country-flag"
          code={_get(person, 'details.nationality.country.code', '')}
          name={_get(person, 'details.nationality.country.name', '')}
          width={32}
          height={32}
          rounded
          tooltip
        />
      ) : null}
    </div>
  ) : null;
};

export default PersonBasicInfo;

const PersonContainer = styled.div`
  width: 100%;
  a {
    color: inherit !important;
  }
`;
