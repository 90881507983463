import { useState, useEffect } from 'react';

import SingleMonthYearPicker from './SingleMonthYearPicker';
import moment from 'moment';

const RangeMonthYearPicker = ({ dateRange, onChange }) => {
  const [focusedInput, setFocusedInput] = useState(
    dateRange.starts?.value && !dateRange.ends?.value ? 'endDate' : 'startDate'
  );

  const onDatesChange = date => {
    const dates = {
      from: dateRange.starts.value.clone(),
      to: dateRange.ends.value ? dateRange.ends.value.clone() : null
    };

    if (focusedInput === 'startDate') {
      dates.from = date.clone();
    } else {
      dates.to = date.clone();
    }

    if (
      dates.from &&
      dateRange.ends.value &&
      moment(dates.from.clone().format('YYYY-MM-DD')).isAfter(
        dateRange.ends.value.clone().format('YYYY-MM-DD')
      )
    ) {
      dates.to = null;
    }

    if (dates.from || dates.to) {
      if (focusedInput === 'startDate') {
        setFocusedInput('endDate');
      } else {
        setFocusedInput('startDate');
      }

      onChange(dates);
    } else {
      setFocusedInput('startDate');
      onChange({ from: null, to: null });
    }
  };

  useEffect(() => {
    dateRange.onFocus(focusedInput);
  }, [focusedInput]);

  const isDateInRange = date => {
    const fullDate = moment(`${date.format('YYYY')}-${date.format('MM')}`, 'YYYY-MM');
    const start = moment(dateRange.starts.value, 'YYYY-MM-DD');
    const end = moment(dateRange.ends.value, 'YYYY-MM-DD');

    return fullDate.isBetween(start, end, 'month', '[]') && date.format('YYYY-MM-DD') !== start;
  };

  const isDaySelected = date =>
    moment(dateRange.ends.value).format('YYYY-MM') === date.format('YYYY-MM');

  const isOutsideRange = date =>
    focusedInput === 'endDate' && dateRange.starts.value
      ? moment(date.format('YYYY-MM-DD')).isBefore(
          dateRange.starts.value.clone().format('YYYY-MM-DD')
        )
      : false;

  return (
    <div className="date-input-range-month d-flex">
      <SingleMonthYearPicker
        value={dateRange.starts.value}
        isDayHighlighted={isDateInRange}
        isDaySelected={isDaySelected}
        isOutsideRange={isOutsideRange}
        onChange={onDatesChange}
      />
    </div>
  );
};

export default RangeMonthYearPicker;
