import App from 'components/layouts/App';

import { Route, Redirect } from 'react-router-dom';
import { isAuthorized } from 'utils/permissions/authorize';
import _isEqual from 'lodash/isEqual';

import paths from 'routing/routes/_paths';
import { getRoutePermissions } from 'common/utils/permissions/helpers';
import { useAppSelector } from '@/store/hooks';
import { selectOrganizationSettings } from '@/store/settings/selectors';
import { isVisibleForOrganization } from '@/common/utils/permissions/organization';

const AuthenticatedRoute = ({
  pageTitle,
  appClass,
  app,
  permissions,
  permissionsMode,
  requiredOrganizationSettings,
  environments,
  children
}) => {
  const account = useAppSelector(state => state.account, _isEqual);
  const settings = useAppSelector(selectOrganizationSettings);

  if (
    requiredOrganizationSettings?.length
      ? isVisibleForOrganization(settings, requiredOrganizationSettings)
      : isAuthorized(account, permissions, permissionsMode, environments)
  ) {
    if (app) {
      return (
        <App pageTitle={pageTitle} appClass={appClass}>
          {children}
        </App>
      );
    } else {
      return children;
    }
  } else if (account && account.permissions) {
    return <Redirect to={paths.unauthorized} />;
  } else {
    return null;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const permissions = getRoutePermissions(rest, props.match);

        return rest.authenticated ? (
          props.location.pathname.includes(paths.logout) ? (
            <Component />
          ) : (
            <AuthenticatedRoute
              pageTitle={rest.pageTitle}
              appClass={rest.appClass}
              permissions={permissions}
              permissionsMode={rest.permissionsMode}
              requiredOrganizationSettings={rest.requiredOrganizationSettings}
              environments={rest.environments}
              app={rest.app}
            >
              <Component />
            </AuthenticatedRoute>
          )
        ) : (
          <Redirect
            push={false}
            to={{
              pathname: '/login',
              search: !props.location.pathname.includes('logout')
                ? `?redirect=${encodeURIComponent(props.location.pathname.trim())}`
                : '',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
