import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { strToNumber } from 'common/utils/numbers';

const selectCrewProfileReducer = state => state.crew.profile;

export const selectCrewProfile = createSelector(selectCrewProfileReducer, crew => crew?.data);

export const selectCrewProfileRankId = state => selectCrewProfileReducer(state)?.data?.rank_id;

export const selecteCrewProfileRankName = state => selectCrewProfileReducer(state)?.rank?.name;

export const selectedCrewProfileId = state => selectCrewProfile(state)?.id;

export const selectCrewProfileName = createSelector(selectCrewProfile, crew => crew?.full_name);

export const selectCrewProfileActiveView = createSelector(
  selectCrewProfileReducer,
  crew => crew?.activeView
);

export const selectCrewStatusLabel = createSelector(selectCrewProfile, crew => crew?.status?.label);
export const selectCrewActiveVessel = createSelector(
  selectCrewProfile,
  crew => crew?.active_vessel
);

export const selectCrewBirthday = createSelector(selectCrewProfile, crew => crew?.birthday);

export const selectCrewAverageScore = createSelector(selectCrewProfile, crew =>
  crew?.average_score ? strToNumber(crew.average_score) : null
);

export const selectPromotionPlan = state => _get(state, 'crew.profile.data.promotion_plan');

export const selectPromotionPlanRankName = state =>
  selectPromotionPlan(state)?.promotion_rank?.name;

export const selectRecommendationCount = state =>
  _get(state, 'crew.profile.data.recommendation_info.count');

export const selectFirstRecommendationDate = state =>
  _get(state, 'crew.profile.data.recommendation_info.date_of_first_recommendation');

export const selectCrewMemberId = createSelector(selectCrewProfile, crew => crew.id);

export const selectNegativeReemploymentTerminated = state =>
  _get(state, 'crew.profile.data.negative_reemployment.status');

export const selectNegativeReemploymentVotes = state =>
  _get(state, 'crew.profile.data.negative_reemployment.votes');
