import React, { useState } from 'react';

import { Button, Row, Col } from 'reactstrap';
import Modal from 'common/components/modals/BasicModal';
import DateInput from 'common/components/form/inputs/date';

import SvgRender from 'common/components/general/SvgRender';
import calendar from 'common/assets/svg/common/calendar.svg';

import moment from 'moment';

const WorkingSchedulePublishModal = ({ isOpen, toggle, onPublish, isPublishing }) => {
  const [applicableSince, setApplicableSince] = useState(moment());
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header="Publish working schedule"
      className="working-schedule__modal"
      footer={
        <>
          <Button color="cancel" onClick={toggle}>
            CANCEL
          </Button>
          <Button
            color="primary"
            disabled={isPublishing || !applicableSince}
            onClick={() => onPublish(applicableSince)}
          >
            PUBLISH
          </Button>
        </>
      }
      body={
        <Row>
          <Col xs={5}>
            <div className="cmb-2 text-primary fs-10 fw-bold">PUBLISHED ON</div>
            <span className="text-primary fw-500 fs-12">{moment().format('DD/MM/YYYY')}</span>
          </Col>
          <Col xs={2} className="d-flex align-items-center fw-bold ps-0">
            -
          </Col>
          <Col xs={5}>
            <DateInput
              label="STARTING FROM"
              onChange={setApplicableSince}
              value={applicableSince}
            />
          </Col>
        </Row>
      }
    />
  );
};

export default WorkingSchedulePublishModal;
