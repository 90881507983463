import { useState, useEffect, useMemo } from 'react';
import useRouter from 'use-react-router';

import { ProfileDrawer } from 'common/components/drawer';
import LeftSide from 'common/components/jobs/regular/components/LeftSide';
import MainContent from 'common/components/jobs/regular/components/MainContent';
import { useDrawer } from 'common/components/drawer';

import Watchers from 'common/components/jobs/_base/modules/watchers';
import PageSaving from 'common/components/general/PageSaving';
import JobLocked, { types } from 'common/components/jobs/_base/components/JobLocked';
import { Row, Col } from 'reactstrap';
import { jobEnums } from 'common/utils/fixed';
import Actions from 'common/components/jobs/regular/components/Actions';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectIsJobLocked,
  isJobSaving,
  selectSelectedJob,
  selectHasBasicJobLayout,
  checkIfEditMode,
  isJobArchived
} from 'common/components/jobs/_base/store/selectors';
import { selectJobStatuses } from 'store/jobs-statuses/selectors';

import { useActions } from 'utils/hooks';

import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import * as listActions from 'store/lists/actions';
import ShadowBox from 'common/components/general/ShadowBox';
import JobSelector from 'common/components/jobs/_base/modules/forms/components/JobSelector';
import Chat from 'common/components/jobs/_base/modules/Chat';
import JobTypeTabs from 'common/components/jobs/_base/components/JobTypeTabs';
import JobTypeSelectors from 'common/components/jobs/maintenance/components/JobSelectorFilters';
import PmsMainContent from 'common/components/pms/jobs/drawer/MainContent';
import ArchiveLabel from 'common/components/jobs/regular/components/ArchiveLabel';
import { selectIsPms } from 'common/components/pms/jobs/store/selectors';

const JobProfileDrawer = ({
  drawer,
  jobID,
  footer = null,
  isJobLinkingView = false,
  isViewOnly,
  isOnEvents = false,
  drawertTitle = 'Close',
  hideJobTypeTabs = false,
  className = '',
  defaultType = null,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const chatDrawer = useDrawer();
  const { history, match } = useRouter();
  const { type } = match.params;

  const [
    getJob,
    resetJobProfile,
    setSelectedJob,
    setDefaultJobStatus,
    fetchListOptions,
    setJobField,
    setPrefilledVessel
  ] = useActions([
    jobProfileActions.getJob,
    jobProfileActions.resetJobProfile,
    jobProfileActions.setSelectedJob,
    jobProfileActions.setDefaultJobStatus,
    listActions.fetchListOptions,
    jobProfileActions.setJobField,
    jobProfileActions.setPrefilledVessel
  ]);

  const jobStatuses = useSelector(selectJobStatuses);
  const isJobLocked = useSelector(selectIsJobLocked);
  const isSubmitting = useSelector(isJobSaving);
  const isOnboard = useSelector(state => state.isOnBoard);
  const selectedJob = useSelector(selectSelectedJob);
  const isEditMode = useSelector(checkIfEditMode);
  const isArchived = useSelector(isJobArchived);

  const isPms = useSelector(selectIsPms);

  const statusId = useSelector(state => selectJobField(state, 'status_id'));
  const hasBasicJobLayout = useSelector(selectHasBasicJobLayout);
  const status = useMemo(() => jobStatuses.find(el => el.id === statusId), [jobStatuses, statusId]);

  const initDrawer = async () => {
    try {
      setIsLoading(true);

      await getJob({ id: jobID || selectedJob?.id });

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (drawer.isOpen && !isOnboard) fetchListOptions('job-restricted-fields');
  }, [drawer.isOpen, fetchListOptions, isOnboard]);

  useEffect(() => {
    if (drawer.isOpen) {
      if (jobID || selectedJob?.id) {
        initDrawer();
      } else {
        setJobField(
          'type',
          history.location?.pathname?.includes('pms') ? jobEnums.unscheduled : jobEnums.regular
        );
      }
    }
  }, [drawer.isOpen, jobID, selectedJob?.id]);

  useEffect(() => {
    if (drawer.isOpen && !(jobID || selectedJob?.id) && jobStatuses?.length && !statusId) {
      setDefaultJobStatus(
        jobStatuses.find(option =>
          isOnboard
            ? option.edit_side === 'vessel' && option.is_for_vessel
            : option.edit_side === 'office' && option.is_for_office
        ).id
      );
    }
  }, [drawer.isOpen, jobID, selectedJob?.id, jobStatuses?.length, statusId]);

  useEffect(() => {
    if (drawer.isOpen && !isEditMode && type) {
      setJobField('type', type);
    }
  }, [type, isEditMode, drawer.isOpen, setJobField]);

  useUpdateEffect(() => {
    if (!drawer.isOpen) {
      resetJobProfile(defaultType ? { type: defaultType } : {});
      setSelectedJob(null);
      setPrefilledVessel(null);
    }
  }, [drawer.isOpen, defaultType]);

  const hiddenJobFields = isJobLinkingView && !selectedJob;

  const onClose = () => {
    drawer.close();
  };

  return (
    <ProfileDrawer
      drawer={drawer}
      size="lg"
      className={`job-profile ${className} ${chatDrawer?.isOpen ? 'chat-is-open' : ''} ${
        hasBasicJobLayout ? 'regular' : ''
      }`}
      dontCloseOnClickOutside={true}
      isLoading={isLoading}
      footer={footer}
      headerComponent={
        isJobLinkingView ? (
          <ShadowBox flat className="px-3 mt-3 pb-2 cpt-12 cmb-12">
            <JobTypeTabs drawerIsOpen={drawer.isOpen} />
            <Row className="mt-1">
              <JobTypeSelectors isJobLinkingView={isJobLinkingView} />
              <Col xs={5}>
                <JobSelector
                  selectedJob={selectedJob || null}
                  onJobChange={selected => setSelectedJob(selected)}
                  label={'JOB'}
                  invisible={false}
                />
              </Col>
            </Row>
          </ShadowBox>
        ) : !selectedJob && !isViewOnly && !hideJobTypeTabs ? (
          <ShadowBox flat className="px-3 mt-3 pb-2 cpt-12 cmb-12">
            <JobTypeTabs drawerIsOpen={drawer.isOpen} isOnCreate={true} />
          </ShadowBox>
        ) : null
      }
      header={
        <div
          className={`d-flex align-items-center w-100p justify-content-between ${
            isViewOnly ? 'pointer-events-none' : ''
          }`}
        >
          <div className="height-28 d-flex align-items-center">{drawertTitle}</div>

          <div className="min-height-32">
            {!isLoading && jobID && (
              <div className="d-flex align-items-center">
                <JobLocked
                  className="me-1"
                  type={isJobLocked ? types.vessel_processing : types.done}
                  status={status?.name}
                />

                {isArchived ? <ArchiveLabel /> : null}
                {isOnboard ? null : <Watchers className="ms-2 me-2" />}
                <Chat className="cms-4 me-2" drawer={chatDrawer} />
                <Actions onArchive={onClose} />
              </div>
            )}
          </div>
        </div>
      }
      leftSide={
        hiddenJobFields ? null : hasBasicJobLayout ? (
          <div
            className={`job-profile__left h-100p d-flex flex-column pt-2 ${
              isViewOnly ? 'pointer-events-none' : ''
            }`}
          >
            <LeftSide />
          </div>
        ) : null
      }
      {...rest}
    >
      {hiddenJobFields ? null : (
        <div
          className={`w-100p h-100p overflow-hidden job-profile__body pb-2 ${
            isViewOnly ? 'pointer-events-none' : ''
          }`}
        >
          <div className={`${hasBasicJobLayout ? 'ps-4' : ''} h-100p pt-2 pe-2`}>
            {isPms ? (
              <PmsMainContent drawerIsOpen={drawer.isOpen} jobID={jobID || selectedJob?.id} />
            ) : (
              <MainContent isEditMode={!!jobID || !!selectedJob?.id} />
            )}
          </div>
        </div>
      )}

      <PageSaving isSaving={isSubmitting} />
    </ProfileDrawer>
  );
};

export default JobProfileDrawer;
