import { func, string, bool } from 'prop-types';

import addIcon from 'common/assets/svg/actions/add.svg';
import CircledButton from 'common/components/buttons/CircledButton';
import SvgRender from 'common/components/general/SvgRender';

const AddMore = ({ onClick, variant, label }) => (
  <div onClick={onClick} className={`form-list-add-more ${variant ? 'variant' : ''}`}>
    {variant ? (
      <>
        <SvgRender src={addIcon} style={{ width: 12, height: 12, marginTop: -2, marginRight: 8 }} />
        <span className="fs-12 lh-1">{label}</span>
      </>
    ) : (
      <CircledButton
        style={{ width: 16, height: 16 }}
        svgStyle={{ width: 8, height: 8 }}
        type="add"
        className="me-1"
        label={label}
      />
    )}
  </div>
);

export default AddMore;

AddMore.propTypes = {
  onClick: func.isRequired,
  variant: bool,
  label: string
};
