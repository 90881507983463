const tonnageCapacities = [
  {
    fields: [
      { label: 'GROSS TONNAGE (gt)', key: 'grt', type: 'number' },
      { label: 'NET TONNAGE (nrt)', key: 'nrt', type: 'number' },
      { label: 'SUEZ CANAL GROSS TONNAGE', key: 'suez_gt', type: 'number' },
      { label: 'SUEZ CANAL NET TONNAGE', key: 'suez_nt', type: 'number' },
      { label: 'PANAMA CANAL NET TONNAGE', key: 'panama_nt', type: 'number' }
    ]
  },
  {
    header: 'CAPACITIES',
    fields: [
      {
        label: 'FW GENERATOR NOMINAL CAPACITY (mt/day)',
        key: 'fw_generator_nominal_capacity',
        type: 'number'
      },
      { label: 'FRESH AND DRINKING WATER (mt)', key: 'fresh_water_capacity', type: 'number' },
      { label: 'DISTILLED WATER (mt)', key: 'distilled_water_capacity', type: 'number' },
      { label: 'SEWAGE (mt)', key: 'sewage_tank_capacity', type: 'number' },
      {
        label: 'CYLINDER OIL (L)',
        key: 'cylinder_oil_capacity',
        type: 'number',
        className: 'mt-2 '
      },
      { label: 'SYSTEM OIL (L)', key: 'system_oil_capacity', type: 'number' },
      { label: 'GENERATOR OIL (L)', key: 'generator_oil_capacity', type: 'number' },
      { label: 'SUMP  (L)', key: 'sump_capacity', type: 'number', className: 'mt-2 ' },
      { label: 'OIL BILGE (L)', key: 'oil_bilge_capacity', type: 'number' },
      {
        label: 'SLUDGE (m³)',
        key: 'sludge_capacity',
        type: 'number'
      },
      { label: 'SLOP  (m³)', key: 'slop_capacity', type: 'number' }
    ]
  }
];

export default tonnageCapacities;
