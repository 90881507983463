import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 750 150"
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    animate={true}
  >
    <rect x="0" y="5" rx="5" ry="5" width="100%" height="20" />
    <rect x="0" y="30" rx="5" ry="5" width="100%" height="20" />
    <rect x="0" y="55" rx="5" ry="5" width="100%" height="20" />
    <rect x="0" y="80" rx="5" ry="5" width="100%" height="20" />
    <rect x="0" y="105" rx="5" ry="5" width="100%" height="20" />
    <rect x="0" y="130" rx="5" ry="5" width="100%" height="20" />
  </ContentLoader>
);

export default Loader;
