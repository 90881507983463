import ShadowBox from 'common/components/general/ShadowBox';
import UploadFiles from 'common/components/form/inputs/upload-files';
import FormSaveActions from 'common/components/form/FormSaveActions';

import { Row, Col } from 'reactstrap';
import { useForm, useFormState } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { selectEventId } from 'events/store/events/selectors';
import { selectEventUnderwaterServicesInfo } from 'events/store/event-modules/underwater-services/selectors';
import Editor from 'common/components/form/inputs/Editor';
import SvgRender from 'common/components/general/SvgRender';
import Textarea from 'common/components/form/inputs/Textarea';
import comments from 'common/assets/svg/common/comments.svg';
import attachmentIcon from 'captain-reports/assets/svg/attachment.svg';
import { removeHtmlTags } from '@/ts-common/utils/texts';
import { config } from 'events/event/modules/module-components/underwater-services/config';
import {
  getUnderwaterServicesInfo,
  updateUnderwaterServicesInfo
} from 'events/store/event-modules/underwater-services/actions';

const UnderwaterServicesInfo = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewMode, setPreviewMode] = useState(true);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();
  const { formState, loadValues, collectValues, handleSubmitError } = useForm(config);
  const { fields, selectField } = useFormState(formState);

  const isOnBoard = useSelector(state => state.isOnBoard);
  const eventId = useSelector(selectEventId);
  const underwaterServicesInfoData = useSelector(selectEventUnderwaterServicesInfo);

  const onSave = async () => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSubmitting(true);
      const { attachments, remarks } = values;

      const params = {
        underwater_services_attachment_ids: attachments?.map(a => a.id),
        underwater_services_remarks: remarks
      };

      await dispatch(updateUnderwaterServicesInfo({ event_id: eventId, ...params })).unwrap();
      await dispatch(getUnderwaterServicesInfo({ event_id: eventId })).unwrap();

      setIsSubmitting(false);
      setPreviewMode(true);
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isOnBoard) {
      setPreviewMode(true);
    }

    loadValues({
      remarks:
        removeHtmlTags(underwaterServicesInfoData?.underwater_service_details?.remarks) || '',
      attachments:
        underwaterServicesInfoData?.underwater_service_details?.underwater_service_attachments || []
    });
  }, [previewMode, underwaterServicesInfoData, loadValues, isOnBoard]);

  useEffect(() => {
    if (
      !underwaterServicesInfoData?.underwater_service_details?.remarks &&
      !underwaterServicesInfoData?.underwater_service_details?.underwater_service_attachments
        ?.length &&
      !isOnBoard
    ) {
      setPreviewMode(false);
    }
  }, [underwaterServicesInfoData, setPreviewMode, isOnBoard]);

  return (
    <Row
      className="height-200"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Col xs={8} className="h-100p">
        <ShadowBox
          className="d-flex p-2 ps-3  h-100p"
          color={previewMode ? null : 'light-4'}
          flat={previewMode ? false : true}
          flatOnHover={previewMode ? true : false}
        >
          {previewMode ? (
            <div className="d-flex pe-4 w-100p">
              <div className="w-100p">
                <div className="d-flex-column text-violet fs-12">
                  <div className="d-flex  text-moody-blue pb-2">
                    <SvgRender
                      className={`me-1`}
                      style={{ height: 16, width: 16 }}
                      src={comments}
                    />
                    Remarks
                  </div>
                  <Row xs={12} className="ps-1">
                    <Textarea
                      value={removeHtmlTags(fields?.remarks?.value)}
                      readOnly={true}
                      invisible
                      className="w-100p"
                      rows={9}
                    />
                  </Row>
                </div>
              </div>
            </div>
          ) : (
            <Editor
              label="REMARKS"
              placeholder="Add some text"
              className="w-100p pe-3"
              onChange={selectField('remarks')}
              id="underwater-services-remarks"
              minHeight="110px"
              maxHeight="110px"
              {...fields.remarks}
            />
          )}
        </ShadowBox>
      </Col>

      <Col xs={4} className="h-100p">
        <ShadowBox
          className="d-flex p-2 ps-3 position-relative h-100p"
          color={previewMode ? null : 'light-4'}
          flat={previewMode ? false : true}
          flatOnHover={previewMode ? true : false}
        >
          {previewMode ? (
            <div className="d-flex pe-4 w-100p">
              <div className="d-flex-column text-violet fs-12">
                <div className="d-flex align-items center text-moody-blue pb-2">
                  <SvgRender
                    className={`me-1`}
                    style={{ height: 16, width: 16, transform: 'rotate(-45deg)' }}
                    src={attachmentIcon}
                  />
                  Attachments
                </div>
                <UploadFiles
                  group="events.underwater_services"
                  files={fields.attachments?.value}
                  onChange={selectField('attachments')}
                  size="lg"
                  viewOnly={true}
                />
              </div>
            </div>
          ) : (
            <div className="h-100p gray-scrollbar overflow-y-scroll overflow-x-hidden">
              <UploadFiles
                label="ATTACHMENTS"
                group="events.underwater_services"
                files={fields.attachments?.value}
                onChange={selectField('attachments')}
                size="lg"
              />
            </div>
          )}

          {!isOnBoard && (
            <FormSaveActions
              isDisabled={isSubmitting}
              isHovering={isHovering}
              mode={previewMode ? 'view' : 'edit'}
              onCancel={() => setPreviewMode(!previewMode)}
              onEdit={() => setPreviewMode(false)}
              onSave={onSave}
              style={{ right: -12, top: 5 }}
            />
          )}
        </ShadowBox>
      </Col>
    </Row>
  );
};

export default UnderwaterServicesInfo;
