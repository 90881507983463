import { useCallback, useEffect, useRef } from 'react';

export const useMountRef = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return function cleanup() {
      isMounted.current = false;
      return isMounted.current;
    };
  }, []);
  const checker = useCallback(() => {
    return isMounted.current;
  }, []);
  return checker;
};

export default useMountRef;
