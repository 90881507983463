import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_EVENT_TEMPLATES'),
  ...asyncTypes('GET_EVENT_TEMPLATE'),
  ...asyncTypes('CREATE_EVENT_TEMPLATE'),
  ...asyncTypes('EDIT_EVENT_TEMPLATE'),
  ...asyncTypes('GET_EVENT_TEMPLATE_FIELD_DATA'),
  ...asyncTypes('ARCHIVE_TEMPLATE'),
  ...asyncTypes('UNARCHIVE_TEMPLATE')
};

export default TYPES;
