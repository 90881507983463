import PropTypes from 'prop-types';
import { ReminderType } from 'common/entities/reminders/ReminderTypes';

export const DocumentTypeReminders = PropTypes.arrayOf(
  PropTypes.shape({
    reminder_id: PropTypes.number.isRequired,
    remindable_id: PropTypes.number.isRequired,
    remindable_type: PropTypes.string.isRequired,
    triggerable_id: PropTypes.number.isRequired,
    triggerable_type: PropTypes.string.isRequired,
    reminder: ReminderType.isRequired
  })
).isRequired;
