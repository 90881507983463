import { FC, useState } from 'react';

import { Button } from 'reactstrap';
import { selectTableListRequestParams } from '@/common/components/table/store/selectors';
import { useAppSelector } from '@/store/hooks';
import useTableTopFilterValue from '@/common/components/filters/useTableTopFilterValue';
import { parseBoolean } from '@/common/utils/parsing';
import Information from '@/ts-common/components/general/Information';
import info from '@/common/assets/svg/common/info-line.svg';
import AnnualReportStatus from './AnnualReportStatus';
import { useVesselPmsAnnualSubmissionMutation } from '@/common/api/vessel-systems/mutations';
import ReportSubmissionModal from './ReportSubmissionModal';
import { useVesselPmsIntegrationTypeQuery } from '@/common/api/vessels/queries';
import { PMS_JOBS_LIST_TABLE_KEY } from '@/api/pms/query-keys.ts';

type AnnualReportActionProps = {
  isList?: boolean;
};

const AnnualReportAction: FC<AnnualReportActionProps> = ({ isList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableRequestParams = useAppSelector(state =>
    selectTableListRequestParams(state, isList ? PMS_JOBS_LIST_TABLE_KEY : 'pms_jobs_per_system')
  );
  const [vesselFilter] = useTableTopFilterValue(
    { topFilters: tableRequestParams?.filters, setTopFilters: () => null },
    'vessel_id'
  );
  const [isClassFilter] = useTableTopFilterValue(
    { topFilters: tableRequestParams?.filters, setTopFilters: () => null },
    'is_class'
  );
  const hasValidVesselFilter = Array.isArray(vesselFilter) && vesselFilter.length === 1;
  const hasValidClassFilter = !!isClassFilter;

  const hasValidFilters = hasValidVesselFilter && hasValidClassFilter;

  const vesselPmsIntegrationQuery = useVesselPmsIntegrationTypeQuery(
    hasValidFilters ? vesselFilter[0] : undefined
  );
  const vesselPmsAnnualSubmissionMutation = useVesselPmsAnnualSubmissionMutation();

  const integrationType = vesselPmsIntegrationQuery.data?.type;

  const onAnnualSubmit = () => {
    if (!integrationType) return;

    vesselPmsAnnualSubmissionMutation.mutate({
      vesselId: vesselFilter[0],
      classType: integrationType,
      isClass: parseBoolean(isClassFilter) ?? false
    });

    setIsModalOpen(false);
  };

  if (!integrationType) return null;

  return (
    <div className="d-flex align-items-center">
      {!!integrationType && vesselFilter && (
        <AnnualReportStatus integrationType={integrationType} vesselId={vesselFilter[0]} />
      )}
      <Button
        id="annual-submission-button"
        className="d-flex align-items-center justify-content-center fs-14 fw-medium text-nowrap"
        type="button"
        color="white"
        disabled={!hasValidFilters || vesselPmsAnnualSubmissionMutation.isPending}
        onClick={() => setIsModalOpen(true)}
      >
        {!!integrationType && (
          <span className="text-primary">{integrationType.toUpperCase()} -&nbsp;</span>
        )}
        <span className="text-violet">Submit Annual Report</span>

        {!hasValidFilters && (
          <Information
            svgIcon={info}
            svgStyle={{ width: 14, height: 14 }}
            className="pe-auto cursor-initial ms-1"
            message="To enable button, you should first select a Vessel and select the Class filter."
            target="annual-submission-button"
          />
        )}
      </Button>
      <div className="small-separator mx-3 cpt-10 pb-2" />

      <ReportSubmissionModal
        isLoe={false}
        isBv={integrationType === 'bv'}
        isOpen={isModalOpen}
        onAccept={onAnnualSubmit}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default AnnualReportAction;
