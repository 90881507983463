import { selectIsCreate } from 'events/store/events/selectors';

const selectIsOnboard = state => state.isOnBoard;
const selectCrewMedicalCases = state => state.eventModules.crewMedicalCases;

export const selectCrewActiveMedicalCase = state => selectCrewMedicalCases(state).activeCase;
export const selectMedicalCasesData = state => selectCrewMedicalCases(state).data;

export const selectIsMedicalCaseLocked = state => {
  const isOnBoard = selectIsOnboard(state);
  const isCreate = selectIsCreate(state);

  return isCreate || isOnBoard;
};
