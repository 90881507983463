import React from 'react';

import SingleDatePicker from './SingleDatePicker';

const MultiDatePicker = ({ className, value, ...rest }) => {
  const hasValue = value && value.length > 0;

  return (
    <SingleDatePicker
      isDayHighlighted={day =>
        hasValue && value.find(v => v.format('YYYY-MM-DD') === day.format('YYYY-MM-DD'))
      }
      {...rest}
      value={null}
    />
  );
};

export default MultiDatePicker;
