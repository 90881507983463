import React, { useMemo, useRef, useState, useEffect } from 'react';

import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _minBy from 'lodash/minBy';
import _maxBy from 'lodash/maxBy';

import { numberToStr } from 'common/utils/numbers';

import {
  findColorByType,
  getBarChartClassNames,
  getTooltipXPosition,
  getTooltipYPosition
} from '../helpers/_functions';

import moment from 'moment';
import { Tooltip } from 'reactstrap';

const getPointValue = point => {
  if (point.plot.type === 'arrow') {
    return point.angle;
  }

  return point.y;
};

const getY = (point, y) => {
  if (point.plot.type === 'arrow') {
    return point.angle;
  }

  return y;
};

const getFormattedValue = (value, options) => {
  const normalizedValue = _isNumber(+value) ? numberToStr(value) : value;
  return _get(options, 'formatNumber') ? options.formatNumber(value) : normalizedValue;
};

const getFormattedValueFromPoint = (point, options) =>
  getFormattedValue(getPointValue(point), options);

const MinMaxValues = ({ filterPlotType, data, options }) => {
  const transformedData = filterPlotType ? data.filter(d => d.plot.type === filterPlotType) : [];

  if (transformedData.length === 0) return null;

  const minPoint = _minBy(transformedData, d => getFormattedValueFromPoint(d));
  const maxPoint = _maxBy(transformedData, d => getFormattedValueFromPoint(d));

  const min = getFormattedValueFromPoint(minPoint);
  const max = getFormattedValueFromPoint(maxPoint);

  return (
    <div className="fs-12 cmb-4 d-flex flex-column">
      <span className="fw-normal">
        Min:{' '}
        <b>
          <FormattedValue value={min} options={options} plot={minPoint.plot} />
        </b>
      </span>
      <span className="fw-normal">
        Max:{' '}
        <b>
          <FormattedValue value={max} options={options} plot={maxPoint.plot} />
        </b>
      </span>
    </div>
  );
};

const FormattedValue = ({ value, options, plot }) => {
  const formattedValue = getFormattedValue(value, options);

  return (
    <>
      {plot.prefix ? `${plot.prefix} ` : null}
      {formattedValue}
      {plot.suffix ? ` ${plot.suffix}` : null}
    </>
  );
};

const SingleValue = ({ value, y, plot, index, options, isOnSameGraph }) => {
  return (
    <div
      key={`${index}-tooltip`}
      className={`d3-tooltip__plot fw-medium${
        // If the point is not the point we're hovering over have it have a little less opacity
        index !== 'x' && (value !== y || !isOnSameGraph) ? ' opacity-7' : ''
      }`}
    >
      {_get(options, 'showColor') ? (
        <div
          className="d3-tooltip__color-block"
          style={{
            backgroundColor: findColorByType({
              style: plot.style,
              type: plot.type
            })
          }}
        ></div>
      ) : null}
      {plot.label}
      {plot.description ? (
        <span className="text-violet cms-4">{plot.description}</span>
      ) : null}:{' '}
      <b>
        <FormattedValue value={value} options={options} plot={plot} />
      </b>
    </div>
  );
};

const InnerD3Tooltip = ({
  options,
  filteredCurrentPoints,
  currentlyHovering,
  isOnSameGraph,
  schema,
  axesConfig
}) => {
  if (!currentlyHovering) return null;

  const { y, timeMarkerLabel } = currentlyHovering;
  const customMessage = _get(options, 'showCustomMessage');
  const isTimeseries = _get(schema, 'isTimeseries');
  const minMaxPlotType = _get(options, 'minMaxPlotType');
  const hidePointInfo = _get(options, 'hidePointInfo');

  return (
    <div className="d3-tooltip">
      {timeMarkerLabel ? <div className="fw-light">{timeMarkerLabel}</div> : null}
      {_get(options, 'showX', true) ? (
        <div className="d3-tooltip__title fw-bold mb-1">
          {filteredCurrentPoints.length
            ? isTimeseries
              ? moment(filteredCurrentPoints[0].x).format(
                  _get(options, 'dateFormat') || 'DD/MM/YYYY'
                )
              : filteredCurrentPoints[0].date
              ? moment(filteredCurrentPoints[0].date).format(
                  _get(options, 'dateFormat') || 'DD/MM/YYYY'
                )
              : null
            : null}
        </div>
      ) : null}

      {customMessage ? customMessage(currentlyHovering) : null}

      <MinMaxValues data={filteredCurrentPoints} filterPlotType={minMaxPlotType} />

      {filteredCurrentPoints.length ? (
        !isTimeseries && hidePointInfo !== true ? (
          <SingleValue
            isOnSameGraph={isOnSameGraph}
            value={filteredCurrentPoints[0].x}
            y={y}
            plot={{
              label: _get(axesConfig, 'x.label') || 'X',
              prefix: _get(axesConfig, 'x.prefix'),
              suffix: _get(axesConfig, 'x.suffix')
            }}
            index={'x'}
            options={options}
          />
        ) : null
      ) : null}

      {hidePointInfo !== true
        ? filteredCurrentPoints.map((singlePoint, i) => (
            <SingleValue
              key={`${singlePoint.y}-${singlePoint.x}-${singlePoint.plot.graphUID}-${singlePoint.plot.plotIndex}-${singlePoint.axisIndex}`}
              isOnSameGraph={isOnSameGraph}
              value={getPointValue(singlePoint)}
              y={getY(singlePoint, y)}
              plot={singlePoint.plot}
              index={i}
              options={options}
            />
          ))
        : null}
    </div>
  );
};

const getFilteredCurrentPoints = (allPoints, currentlyHovering, disabledPlots) => {
  const points = {};

  allPoints.forEach(point => {
    if (disabledPlots.includes(point.plotIndex)) return;

    if (!points[point.plotIndex]) {
      points[point.plotIndex] = point;
    } else {
      if (point.y === currentlyHovering.y) {
        points[point.plotIndex] = point;
      }
    }
  });

  return Object.values(points);
};

const D3Tooltip = ({
  currentlyHovering,
  children,
  graphUID,
  schema,
  containerRef,
  options,
  points,
  forcePopperTooltip,
  disabledPlots,
  axesConfig,
  yAxes,
  xAxis
}) => {
  const tooltipRef = useRef();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [graphWidth, setGraphWidth] = useState();
  const [graphHeight, setGraphHeight] = useState();

  useEffect(() => {
    if (tooltipRef.current) {
      const dimensions = tooltipRef.current.getBoundingClientRect();
      setWidth(dimensions.width);
      setHeight(dimensions.height);
    }

    if (containerRef.current) {
      const dimensions = containerRef.current.getBoundingClientRect();
      setGraphWidth(dimensions.width);
      setGraphHeight(dimensions.height);
    }
  }, [tooltipRef.current, containerRef.current]);

  if (!currentlyHovering) return null;

  const { x, timeMarkerLabel, currentElementID } = currentlyHovering;
  const currentPoints = points[x] || [];

  const filteredCurrentPoints = getFilteredCurrentPoints(
    currentPoints,
    currentlyHovering,
    disabledPlots
  );

  const isOnSameGraph = currentlyHovering.plot?.graphUID === graphUID;
  const currentGraphCurrentlyHovering = isOnSameGraph
    ? currentlyHovering
    : filteredCurrentPoints.find(point => point.plot?.graphUID === graphUID) || currentlyHovering;

  const yAxisValueKey = _get(schema, 'yAxis');
  const { y, axisIndex } = currentGraphCurrentlyHovering;

  const yPosition = getTooltipYPosition(
    yAxes[axisIndex]?.axis,
    { y, name: currentGraphCurrentlyHovering[yAxisValueKey], axesConfig },
    height,
    graphHeight
  );
  const xPosition = getTooltipXPosition({
    x,
    y,
    axesConfig,
    xScale: xAxis,
    hoveredItem: currentlyHovering,
    tooltipWidth: width
  });

  const shouldCheckForYOverflow = graphHeight > height;

  const customMessage = _get(options, 'showCustomMessage');

  const isOutOfTheRightSide = xPosition + width >= graphWidth;
  const isCompletelyOutOfTheRightSide = xPosition >= graphWidth;
  const isOutOfTheLeftSide = xPosition < 0;
  const isOutOfTheTopSide = shouldCheckForYOverflow ? yPosition - height / 2 < 0 : false;
  const isOutOfTheBottomSide = shouldCheckForYOverflow
    ? yPosition + height / 2 >= graphHeight
    : false;

  const barChartTooltipClassNames = getBarChartClassNames(currentlyHovering);

  const shouldBeShown =
    currentPoints?.length ||
    timeMarkerLabel ||
    customMessage ||
    (forcePopperTooltip && currentElementID);

  return (forcePopperTooltip || timeMarkerLabel) && shouldBeShown ? (
    <Tooltip
      placement={_get(options, 'tooltipPlacement') || 'right'}
      className={`d3-tooltip ${_get(options, 'tooltipClassName')}`}
      innerClassName="max-width-none"
      isOpen
      hideArrow
      fade={false}
      target={currentElementID}
      boundariesElement={_get(options, 'isContainerized') ? containerRef.current : undefined}
    >
      <InnerD3Tooltip
        axesConfig={axesConfig}
        currentlyHovering={currentlyHovering}
        isOnSameGraph={isOnSameGraph}
        children={children}
        graphUID={graphUID}
        schema={schema}
        containerRef={containerRef}
        currentPoints={currentPoints}
        options={options}
        points={points}
        disabledPlots={disabledPlots}
        filteredCurrentPoints={filteredCurrentPoints}
      />
    </Tooltip>
  ) : (
    <div
      className={`d3-tooltip__wrapper ${!shouldBeShown ? 'd-none' : ''}`}
      style={{ transform: `translate(${xPosition}px, ${yPosition}px)` }}
    >
      <div
        ref={tooltipRef}
        className={`d3-tooltip--custom ${
          isOutOfTheLeftSide || isCompletelyOutOfTheRightSide ? 'd-none' : ''
        } ${isOutOfTheRightSide ? 'tooltip-align-right' : ''} ${_get(
          options,
          'tooltipClassName'
        )} ${barChartTooltipClassNames}`}
      >
        <div
          className={`d3-tooltip--custom--v-align ${isOutOfTheTopSide ? 'tooltip-align-top' : ''} ${
            isOutOfTheBottomSide ? 'tooltip-align-bottom' : ''
          }`}
        >
          <InnerD3Tooltip
            axesConfig={axesConfig}
            currentlyHovering={currentlyHovering}
            isOnSameGraph={isOnSameGraph}
            children={children}
            graphUID={graphUID}
            schema={schema}
            containerRef={containerRef}
            currentPoints={currentPoints}
            options={options}
            points={points}
            disabledPlots={disabledPlots}
            filteredCurrentPoints={filteredCurrentPoints}
          />
        </div>
      </div>
    </div>
  );
};

const MemoizedD3Tooltip = props => {
  const [currentlyHovering, setCurrentlyHovering] = useState();

  const handleHoverEvent = e => setCurrentlyHovering(e.detail);

  useEffect(() => {
    document.addEventListener(`graph-hovering--${props.eventsID}`, handleHoverEvent);
    return () => {
      document.removeEventListener(`graph-hovering--${props.eventsID}`, handleHoverEvent);
    };
  }, [props.eventsID]);

  const renderedTooltip = useMemo(
    () => <D3Tooltip {...props} currentlyHovering={currentlyHovering} /> || null,
    [currentlyHovering, props.points, props.xAxis, props.yAxes, props.disabledPlots]
  );
  return renderedTooltip;
};

export default MemoizedD3Tooltip;
