import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  selectFieldVisibility,
  selectActiveTab,
  selectGeneratorLoadTotal,
  selectGeneratorsRunningHours
} from 'captain-reports/store/selectors';
import * as reportActions from 'captain-reports/store/actions';
import { useActions } from 'utils/hooks';
import useValue from 'captain-reports/utils/useValue';
import { numberToStr } from 'common/utils/numbers';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';

const GeneratorLoadSharingField = ({ tooltip, subGroup, subGroupIndex, field }) => {
  const visible = useSelector(state => selectFieldVisibility(state, field.key));
  const loadTotal = useSelector(selectGeneratorLoadTotal);
  const [setValue] = useActions([reportActions.setValue]);
  const activeTab = useSelector(state => selectActiveTab(state, 'generators'));
  const runningHours = useSelector(selectGeneratorsRunningHours);
  const shouldBeCalculated = runningHours.filter(e => e >= 1440).length >= 2;

  const value = useValue({
    key: field.key,
    sectionLabel: 'generators',
    sectionActiveTab: activeTab
  });

  const singleGeneratorLoad = useValue({
    key: 'dg_power',
    sectionLabel: 'generators',
    sectionActiveTab: activeTab
  });

  const singleGeneratorRunningMinutes = useValue({
    key: 'dg_running_minutes',
    sectionLabel: 'generators',
    sectionActiveTab: activeTab
  });

  useEffect(() => {
    let calculation = null;

    // We need to have two or more generators with 24 hours running operation or more.
    // In case we have two or more generators with more than 24 hours then we calculate ONLY the DGs with dg_running_minutes = 0 or dg_running_minutes >= 1,440 minutes.
    // We don’t calculate generators with running hours > 0 and < 24.
    if (
      shouldBeCalculated &&
      (singleGeneratorRunningMinutes === 0 || singleGeneratorRunningMinutes >= 1440)
    ) {
      if ((loadTotal || loadTotal === 0) && (singleGeneratorLoad || singleGeneratorLoad === 0)) {
        // dg_load_sharing_percentage = dg_load of one generator / the sum of all dgs dg_load * 100
        calculation = (singleGeneratorLoad / loadTotal) * 100;
      }
    }

    if (value !== +calculation?.toFixed(2)) {
      setValue({
        key: field.key,
        value: +calculation?.toFixed(2) || null,
        sectionLabel: 'generators',
        sectionActiveTab: activeTab
      });
    }
  }, [singleGeneratorLoad, shouldBeCalculated, loadTotal, singleGeneratorRunningMinutes]);

  if (!visible) return null;

  return (
    <div>
      <ValidationWrapper
        field={field}
        value={value}
        labelClassName="fs-10 cpt-4"
        sectionLabel={'generators'}
        errorClassName="align-items-center"
        sectionActiveTab={activeTab}
        subGroup={subGroup}
        subGroupIndex={subGroupIndex}
      >
        <div className="height-24 cps-2 fs-12 fw-bold cpt-4">{numberToStr(value)}</div>
      </ValidationWrapper>
    </div>
  );
};

export default GeneratorLoadSharingField;
