import PropTypes from 'prop-types';

export const CrewMedicalCaseTypes = PropTypes.shape({
  id: PropTypes.number,
  attachments: PropTypes.array,
  covered_by_pni: PropTypes.string,
  location: PropTypes.string,
  created_at: PropTypes.string,
  crew_medical_case_type_id: PropTypes.number,
  is_crew_claim: PropTypes.bool,
  is_work_related: PropTypes.bool,
  remarks: PropTypes.string,
  reported_at: PropTypes.string,
  reported_to: PropTypes.string,
  occurred_at: PropTypes.string,
  hospitalized_at_from: PropTypes.string,
  hospitalized_to_from: PropTypes.string,
  duty_resumed_at: PropTypes.string,
  event_id: PropTypes.number,
  duty_suspended_at: PropTypes.string,
  updated_by_id: PropTypes.number,
  repatriated: PropTypes.bool,
  status: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  crew_member: PropTypes.shape({
    full_name: PropTypes.string,
    photo_id: PropTypes.string || PropTypes.number,
    details: PropTypes.shape({
      rank: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  expenses: PropTypes.shape({
    id: PropTypes.number,
    crew_medical_case_id: PropTypes.number,
    event_medical_expense_id: PropTypes.number,
    medical_expense: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string
    }),
    values: PropTypes.shape({
      crew_medical_case_expense_id: PropTypes.number,
      sort_index: PropTypes.number,
      value: PropTypes.string
    })
  })
});
