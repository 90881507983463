import React, { useEffect, useState } from 'react';

import { Button } from 'reactstrap';

import BasicModal from 'common/components/modals/BasicModal';

import Textarea from 'common/components/form/inputs/Textarea';

const SendForCorrectionsModal = ({ bodyText, isOpen, setIsOpen, onSubmit, disabled }) => {
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);

  const onInputChange = e => setDescription(e.target.value);

  const onSubmitHandler = () => {
    if (!description) {
      setError('Required');
      return;
    }

    onSubmit(description);
  };

  useEffect(() => {
    if (description) setError(null);
  }, [description]);

  useEffect(() => {
    if (isOpen) {
      setDescription('');
    }
  }, [isOpen]);

  return (
    <BasicModal
      isOpen={isOpen}
      className="send-for-correction-modal"
      toggle={() => setIsOpen(!isOpen)}
      header={'Send to Vessel for Correction'}
      body={
        <div>
          <div className="fs-14 text-dark">{bodyText}</div>

          <Textarea
            rows={5}
            onChange={onInputChange}
            className="send-for-correction-modal__textarea mb-0"
            label="COMMENTS"
            placeholder="Add some text"
            value={description}
            error={error}
            disabled={disabled}
          />
        </div>
      }
      footer={
        <div className="mt-0 d-flex align-items-center">
          <Button color="cancel" onClick={() => setIsOpen(false)}>
            CANCEL
          </Button>
          <Button
            disabled={disabled}
            onClick={onSubmitHandler}
            className="send-for-correction-modal__submit-btn"
            color="primary"
          >
            SEND
          </Button>
        </div>
      }
    />
  );
};

export default SendForCorrectionsModal;
