import { Row, Col } from 'reactstrap';
import ShadowBox from 'common/components/general/ShadowBox';
import PersonBasicInfo from 'common/components/person/components/PersonBasicInfo';
import Information from 'common/components/general/Information';
import CircledButton from 'common/components/buttons/CircledButton';
import CrewRotationType from 'common/components/crew/CrewRotationType';
import _get from 'lodash/get';

import moment from 'moment';
import groups from 'common/assets/svg/common/group.svg';

import { Value, Label } from './_components/Field';
import TicketingTypeSelector from './_components/TicketingTypeSelector';
import RemoveTripPerson from './_components/RemoveTripPerson';
import PropTypes from 'prop-types';

const PersonPreview = ({
  person,
  personType,
  port,
  vessel,
  date,
  type,
  showCollapseButton,
  onChangeType,
  onCollapse,
  tripID,
  hiddenParties,
  isCollapsed,
  tripParties,
  onDeleteTripParticipant,
  isLastPerson
}) => {
  return (
    <ShadowBox
      className={`ps-3 cpe-12 py-1 ${isLastPerson ? 'cmb-4' : 'mb-2'}`}
      color="light-3"
      flat
    >
      <Row>
        <Col xs={3} className="d-flex align-items-center text-primary">
          <PersonBasicInfo person={person} withFlag className="width-auto" />
          {personType ? (
            <CrewRotationType
              type={personType}
              textClassName={personType === 'on_signer' ? 'text-green' : ''}
            />
          ) : null}
        </Col>
        <Col xs={2}>
          <TicketingTypeSelector
            value={type?.id}
            onChange={onChangeType}
            isForCrew={person?.details?.rank ? true : false}
          />
        </Col>
        {person?.details?.rank ? (
          <Col xs={2} className="d-flex">
            <Label>Rank</Label>
            <Value>{_get(person, 'details.rank.name', '-')}</Value>
          </Col>
        ) : null}
        <Col xs={2} className="d-flex">
          <Label>Port & Date</Label>
          <Value>
            {_get(port, 'name', '-')}, {date ? moment(date).format('DD/MM/YYYY') : '-'}
          </Value>
        </Col>
        <Col xs={2} className="d-flex">
          <Label>Vessel</Label>
          <Value>{_get(vessel, 'name', '-')}</Value>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {hiddenParties?.length ? (
            <div
              className={`d-flex align-items-center ${
                showCollapseButton ? 'border-end pe-1 me-1' : ''
              }`}
            >
              <strong className="text-purple">{hiddenParties?.length + 1}&nbsp;</strong>
              <Information
                svgStyle={{ width: 18, height: 18 }}
                svgIcon={groups}
                className="text-purple"
                target={'trip-parties'}
                message={[person.full_name, ...hiddenParties.map(p => p?.person?.full_name)].join(
                  ', '
                )}
              />
            </div>
          ) : null}
          {showCollapseButton ? (
            <CircledButton
              onClick={onCollapse}
              type="arrow"
              style={{
                width: 21,
                height: 21
              }}
              svgStyle={{
                transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)',
                width: 12,
                height: 12
              }}
            />
          ) : null}
          <RemoveTripPerson
            className="ms-1"
            person={person}
            tripID={tripID}
            tripParties={tripParties}
            onDeleteTripParticipant={onDeleteTripParticipant}
          />
        </Col>
      </Row>
    </ShadowBox>
  );
};

PersonPreview.propTypes = {
  person: PropTypes.shape({ full_name: PropTypes.string }),
  personType: PropTypes.string,
  port: PropTypes.shape({ name: PropTypes.string }),
  vessel: PropTypes.shape({ name: PropTypes.string }),
  type: PropTypes.shape({ id: PropTypes.number }),
  showCollapseButton: PropTypes.bool,
  onChangeType: PropTypes.func,
  onCollapse: PropTypes.func,
  tripID: PropTypes.number,
  isCollapsed: PropTypes.bool,
  hiddenParties: PropTypes.arrayOf(
    PropTypes.shape({
      person: PropTypes.shape({ full_name: PropTypes.string })
    })
  ),
  tripParties: PropTypes.array,
  onDeleteTripParticipant: PropTypes.func,
  isLastPerson: PropTypes.bool
};

export default PersonPreview;
