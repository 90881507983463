import { Row, Col } from 'reactstrap';
import Checkbox from 'common/components/form/inputs/Checkbox';
import Value from './values';
import Remarks from './remarks';
import Grade from './Grade';
import { useFormState } from 'utils/hooks';
import { REQUIRED_FIELD_MESSAGE } from 'common/utils/form/validation';

const SingleCriterionView = ({ criterion, index }) => {
  return (
    <Row noGutters className="h-100p flex-1">
      <Col xs={4} className="d-flex align-items-center fw-normal pe-3">
        <span className="criteria-list--row-index">{index + 1}.</span>
        {criterion.name}
      </Col>
      <Col xs={3} className="d-flex align-items-center">
        <Row noGutters className="w-100p d-flex align-items-center fw-normal">
          <Grade value={criterion.value} valueType={criterion.value_type} />
        </Row>
      </Col>
      <Col xs={5} className="d-flex align-items-center fw-normal">
        <span>{criterion.remarks || '-'}</span>
      </Col>
    </Row>
  );
};

const SingleCriterionCreateOrDraft = ({
  criterion,
  index,
  tooltipID,
  onRecalculateAverageScore
}) => {
  const { fields, selectField, setFieldError } = useFormState(criterion);

  const onCheckboxChange = () => {
    const isChecked = !fields.evaluate.value;
    const fieldValue = fields.value?.value;

    selectField('evaluate')(isChecked);
    onRecalculateAverageScore(
      'evaluate',
      isChecked,
      fields.category_id.value,
      fields.sort_index.value
    );

    if (!isChecked) {
      setFieldError('value', null);
      return;
    }

    if (isChecked && !fieldValue) {
      if (fieldValue === 0 && fields.value_type.value === 'question') return;

      setFieldError('value', REQUIRED_FIELD_MESSAGE);
    }
  };

  return (
    <Row noGutters className="h-100p">
      <Col xs={4} className="d-flex align-items-center fw-normal">
        <span className="criteria-list--row-index">{index + 1}.</span>
        {fields.name.value}
      </Col>
      <Col xs={5} className="d-flex align-items-center">
        <Row noGutters className="w-100p d-flex align-items-center fw-normal">
          <Col xs={3}>
            {!fields.is_required.value ? (
              <Checkbox value={fields.evaluate.value} onChange={onCheckboxChange} />
            ) : null}
          </Col>
          <Col xs={9}>
            <Value
              valueType={fields.value_type.value}
              onChange={value => {
                selectField('value')(value);
                onRecalculateAverageScore(
                  'value',
                  value,
                  fields.category_id.value,
                  fields.sort_index.value
                );
              }}
              value={fields.value.value}
              error={fields.value.error}
              tooltipID={tooltipID}
              setFieldError={setFieldError}
              enabled={fields.evaluate.value}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="d-flex align-items-center fw-normal">
        <Remarks
          value={fields.remarks.value}
          onChange={value => selectField('remarks')(value)}
          enabled={fields.evaluate.value}
        />
      </Col>
    </Row>
  );
};

const SingleCriterion = ({ criterion, index, view, tooltipID, onRecalculateAverageScore }) => {
  return (
    <div className={`criteria-list--row ${view ? 'd-flex align-items-center' : ''}`}>
      {view ? (
        <SingleCriterionView criterion={criterion} index={index} />
      ) : (
        <SingleCriterionCreateOrDraft
          criterion={criterion}
          index={index}
          tooltipID={tooltipID}
          onRecalculateAverageScore={onRecalculateAverageScore}
        />
      )}
    </div>
  );
};

export default SingleCriterion;
