import React, { useEffect } from 'react';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectIsJobLocked,
  selectOfficeUserIsAuthorized
} from 'common/components/jobs/_base/store/selectors';

import { selectListOptionsFromStore } from 'store/lists/selectors';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';
import { departmentTypeEnums } from 'common/utils/fixed';
import PropTypes from 'prop-types';

const Departments = ({
  type,
  boxPlaceholder,
  placeholder,
  showMore = true,
  avoidCheck = false,
  className = '',
  ...rest
}) => {
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const [setJobField] = useActions([jobProfileActions.setJobField]);
  const value = useSelector(state => selectJobField(state, type));

  const partyAndCrewDepartments = useSelector(state =>
    selectListOptionsFromStore(state, 'party-and-crew-departments')
  );
  const isJobLocked = useSelector(selectIsJobLocked);
  const canEdit = useSelector(state => (avoidCheck ? true : selectOfficeUserIsAuthorized(state)));
  const isForVessel = useSelector(state => selectJobField(state, 'is_for_vessel'));

  useEffect(() => {
    // Reset the main_department when the selected one is a crew department and isForVessel equals to false
    // We want this to be triggered only when isForVessel is changed
    if (
      !isForVessel &&
      type === departmentTypeEnums.main_department &&
      value &&
      partyAndCrewDepartments.find(d => d.id === value && d.is_crew_department)
    ) {
      setJobField(departmentTypeEnums.main_department, null, true);
    }
  }, [isForVessel]);

  return (
    <DepartmentSelector
      boxPlaceholder={boxPlaceholder}
      placeholder={placeholder}
      value={value}
      onChange={d => {
        if (type === departmentTypeEnums.departments) {
          setJobField(departmentTypeEnums.departments, d ? d : [], true);
        } else {
          setJobField(type, d ? d : null, true);
        }
      }}
      disabled={!canEdit || isJobLocked}
      jobId={jobId}
      showMore={showMore}
      isMulti={type === departmentTypeEnums.departments}
      withCrewDepartments={
        type === departmentTypeEnums.main_department && isForVessel ? true : false
      }
      onlyCrewDepartments={type === departmentTypeEnums.responsible_onboard_department}
      className={`job-input job-input--departments ${className}`}
      invisible
      {...rest}
    />
  );
};

Departments.propTypes = {
  type: PropTypes.oneOf(Object.values(departmentTypeEnums)).isRequired,
  boxPlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  showMore: PropTypes.bool,
  avoidCheck: PropTypes.bool,
  className: PropTypes.string
};

export default Departments;
