import NumberField from 'captain-reports/templates/inputs/NumberField';
import LubricantsConsumptionInput from 'captain-reports/templates/components/custom-fields/LubricantsConsumptionInput';
import LubricantsLegTotalConsumptionInput from 'captain-reports/templates/components/custom-fields/LubricantsLegTotalConsumptionInput';
import LubricantTotalAutocomplete from 'captain-reports/templates/components/custom-fields/LubricantTotalAutocomplete';
import LubricantLegTotalConsumed from 'captain-reports/templates/components/custom-fields/LubricantLegTotalConsumed';
import LubricantTotalRob from 'captain-reports/templates/components/custom-fields/LubricantTotalRob';
import LubricantSoRobField from 'captain-reports/templates/components/custom-fields/LubricantSoRobField';
import LubricantFeedRateField from 'captain-reports/templates/components/custom-fields/LubricantFeedRateField';
import LubricantLegAverageFeedRateField from 'captain-reports/templates/components/custom-fields/LubricantLegAverageFeedRateField';
import LubricantsSoLegTotalConsumption from 'captain-reports/templates/components/custom-fields/LubricantsSoLegTotalConsumption';
import BaseNumberField from 'captain-reports/templates/components/custom-fields/BaseNumberField';

import {
  autocompleteTotalCo,
  autocompleteLubricantsSoTotalRob,
  autocompleteSoConsumption
} from 'captain-reports/templates/config/_autocompleteHelpers';

const getTotalConsumedFieldTitle = type => {
  let name =
    type === 'noon' || type === 'arrival'
      ? 'LEG TOTAL CONSUMED'
      : type === 'port_noon' || type === 'departure'
      ? 'PORT TOTAL CONSUMED'
      : 'TOTAL CONSUMED';

  return name;
};

const getTotalSuppliedFieldTitle = type => {
  let name =
    type === 'port_noon' || type === 'departure'
      ? 'PORT TOTAL SUPPLIED'
      : type === 'noon'
      ? 'LEG TOTAL SUPPLIED'
      : 'TOTAL SUPPLIED';

  return name;
};

const arrival = params => [
  {
    fieldTitle: 'BASE NUMBER',
    fields: [
      {
        label: 'Base  Number',
        subLabel: 'CO 1',
        key: 'co_1_base_number',
        previousReportInitializationKey: 'co_1_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'CO 2',
        key: 'co_2_base_number',
        previousReportInitializationKey: 'co_2_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input',
        previousReportInitializationKey: 'co_3_base_number',
        key: 'co_3_base_number',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'GO',
        key: 'go_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: () => null,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'CONSUMPTION',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Consumption',
        subLabel: 'CO 1',
        key: 'co_1_consumption_total',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 1 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_1_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'CO 2',
        className: 'text-end-input lubricant-input',
        key: 'co_2_consumption_total',
        width: { xs: '' },
        type: 'number',
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 2 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_2_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input',
        key: 'co_3_consumption_total',
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 3 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_3_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'TOTAL CO',
        className: 'text-end-input lubricant-input cms-4 readonly-input',
        key: 'co_total_consumption_total',
        autocompleteValues: [
          { key: 'co_1_consumption_total' },
          { key: 'co_2_consumption_total' },
          { key: 'co_3_consumption_total' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'TOTAL CO RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input cms-4',
        key: 'go_consumption_total',
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'GO RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'go_consumption_rate',
        type: 'number',
        render: () => null
      }
    ]
  },
  {
    fieldTitle: 'OIL REPLACED',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Oil Replaced',
        subLabel: 'GO',
        key: 'go_replaced',
        className: 'text-end-input lubricant-input ms-auto me-9 px-0',
        width: { xs: { size: 1 } },
        type: 'number',
        render: NumberField
      }
    ]
  },
  {
    fieldTitle: 'FEED RATE',
    fieldSubTitle: 'g/kwh',
    fields: [
      {
        label: 'CO Feed Rate',
        subLabel: 'CO 1',
        key: 'co_1_feed_rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        subLabel: 'CO 2',
        key: 'co_2_feed_rate',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className:
          'text-end-input text-end lubricant-input readonly-input d-flex pe-10 justify-content-end',
        subLabel: 'CO 3',
        key: 'co_3_feed_rate',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className: 'lubricant-input text-end-input readonly-input d-flex cpe-4 justify-content-end',
        subLabel: 'TOTAL CO',
        key: 'co_total_feed_rate',
        autocompleteValues: [
          { key: 'co_1_feed_rate' },
          { key: 'co_2_feed_rate' },
          { key: 'co_3_feed_rate' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: 6 },
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        subLabel: 'GO',
        key: 'go_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-1',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: getTotalConsumedFieldTitle(params.type),
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Total Consumed',
        subLabel: 'CO 1',
        className: 'text-end-input lubricant-input readonly-input pe-2',
        key: 'co_1_leg_total_consumption_total',
        lubricantRateKey: 'co_1_leg_total_consumption_rate',
        width: { xs: '' },
        type: 'number',
        autocompleteValues: [{ key: 'co_1_consumption_total' }],
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_1_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'CO 2',
        autocompleteValues: [{ key: 'co_2_consumption_total' }],
        className: 'text-end-input lubricant-input readonly-input pe-2',
        key: 'co_2_leg_total_consumption_total',
        lubricantRateKey: 'co_2_leg_total_consumption_rate',
        width: { xs: '' },
        type: 'number',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_2_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'CO 3',
        autocompleteValues: [{ key: 'co_3_consumption_total' }],
        key: 'co_3_leg_total_consumption_total',
        className: 'text-end-input lubricant-input readonly-input pe-2',
        width: { xs: '' },
        type: 'number',
        lubricantRateKey: 'co_3_leg_total_consumption_rate',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_3_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'TOTAL CO',
        className: 'text-end-input lubricant-input readonly-input cpe-4',
        key: 'co_total_leg_total_consumption_total',
        autocompleteValues: [
          { key: 'co_1_leg_total_consumption_total' },
          { key: 'co_2_leg_total_consumption_total' },
          { key: 'co_3_leg_total_consumption_total' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: '' },
        disabled: true,
        lubricantRateKey: 'leg_total_co_total_consumption_rate',
        render: LubricantsLegTotalConsumptionInput,
        type: 'number'
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'leg_total_co_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        autocompleteValues: [{ key: 'go_consumption_total' }],
        className: 'text-end-input lubricant-input readonly-input',
        subLabel: 'GO',
        key: 'go_leg_total_consumption_total',
        width: { xs: '' },
        type: 'number',
        lubricantRateKey: 'go_leg_total_consumption_rate',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'go_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      }
    ]
  },
  {
    fieldTitle: 'LEG AVG FEED RATE',
    fieldSubTitle: 'g/kwh',
    fields: [
      {
        label: 'CO Leg Average Feed Rate',
        subLabel: 'CO 1',
        key: 'co_1_leg_average_feed_rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        subLabel: 'CO 2',
        key: 'co_2_leg_average_feed_rate',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className: 'text-end-input lubricant-input readonly-input d-flex pe-10 justify-content-end',
        subLabel: 'CO 3',
        key: 'co_3_leg_average_feed_rate',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className: 'lubricant-input text-end-input readonly-input d-flex cpe-4 justify-content-end',
        subLabel: 'TOTAL CO',
        key: 'co_total_leg_average_feed_rate',
        autocompleteValues: [
          { key: 'co_1_leg_average_feed_rate' },
          { key: 'co_2_leg_average_feed_rate' },
          { key: 'co_3_leg_average_feed_rate' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: 6 },
        disabled: true,
        render: NumberField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'GO Leg Average Feed Rate',
        subLabel: 'GO',
        key: 'go_leg_average_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-1',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'CORRECTION',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Correction CO 1',
        subLabel: 'CO 1',
        key: 'co_1_correction',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction',
        subLabel: 'CO 2',
        key: 'co_2_correction',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction',
        className: 'text-end-input lubricant-input',
        subLabel: 'CO 3',
        key: 'co_3_correction',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction Total',
        subLabel: 'TOTAL CO',
        key: 'co_total_correction',
        className: 'lubricant-input readonly-input text-end-input',
        autocompleteValues: [
          { key: 'co_1_correction' },
          { key: 'co_2_correction' },
          { key: 'co_3_correction' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Correction GO',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input',
        key: 'go_correction',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      }
    ]
  },
  {
    fieldTitle: 'ROB',
    fieldSubTitle: 'L',
    fieldClassName: 'rob',
    fields: [
      {
        label: 'ROB',
        subLabel: 'CO 1',
        key: 'co_1_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'CO 2',
        key: 'co_2_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'CO 3',
        key: 'co_3_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'TOTAL CO',
        key: 'co_total_rob',
        className: 'text-end-input pe-8 cme-10 lubricant-input readonly-input lubricant-input__rob',
        width: { xs: '' },
        autocompleteValues: [{ key: 'co_1_rob' }, { key: 'co_2_rob' }, { key: 'co_3_rob' }],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'GO',
        key: 'go_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      }
    ]
  },
  {
    header: 'SO',
    subLabel: 'L',
    fields: [
      {
        label: 'MEASURED ROB',
        key: 'so_rob_measured',
        className: 'ps-0',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'MEASURED SUMP TANK',
        key: 'so_sump_tank_rob',
        className: 'ps-0',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'TOTAL ROB',
        key: 'so_rob',
        className: 'ps-0 mb-3',
        autocompleteValues: [{ key: 'so_sump_tank_rob' }, { key: 'so_rob_measured' }],
        autocomplete: autocompleteLubricantsSoTotalRob,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantSoRobField,
        type: 'number'
      },
      {
        label: 'CONSUMPTION',
        key: 'so_consumption_total',
        className: 'ps-0 cmt-4 readonly-input mb-2 cpb-12',
        autocompleteValues: [
          { key: 'so_rob' },
          { key: 'so_supplied' },
          { previousReportKey: 'so_rob' },
          { key: 'so_consumption_total' }
        ],
        autocomplete: autocompleteSoConsumption,
        disabled: true,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantsSoLegTotalConsumption,
        type: 'number'
      },
      {
        label: 'LEG TOTAL CONS',
        key: 'so_leg_total_consumption_total',
        className: 'ps-0 readonly-input mb-3',
        lubricantRateKey: 'so_leg_total_consumption_rate',
        autocompleteValues: [{ key: 'so_consumption_total' }],
        disabled: true,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantLegTotalConsumed,
        type: 'number'
      },
      {
        label: 'SO Consumption Rate',
        className: 'lubricant-input readonly-input',
        subLabel: 'SO',
        width: { xs: '' },
        key: 'so_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'FEED RATE',
        key: 'so_feed_rate',
        className: 'ps-0 pe-0 readonly-input mb-3',
        subLabel: 'g/kwh',
        width: { xs: 2 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'LEG AVG FEED RATE',
        key: 'so_leg_average_feed_rate',
        className: 'ps-0 pe-0 readonly-input mb-4',
        disabled: true,
        subLabel: 'g/kwh',
        width: { xs: 2 },
        render: LubricantLegAverageFeedRateField,
        type: 'number'
      },
      {
        label: 'MEASURED SUMP TANK',
        key: 'so_sump_tank_measurement_cm',
        className: 'pl-0',
        subLabel: 'cm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const noon = params => [
  {
    fieldTitle: 'BASE NUMBER',
    fields: [
      {
        label: 'Base  Number',
        subLabel: 'CO 1',
        key: 'co_1_base_number',
        previousReportInitializationKey: 'co_1_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'CO 2',
        key: 'co_2_base_number',
        previousReportInitializationKey: 'co_2_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input',
        previousReportInitializationKey: 'co_3_base_number',
        key: 'co_3_base_number',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'GO',
        key: 'go_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: () => null,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'CONSUMPTION',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Consumption',
        subLabel: 'CO 1',
        key: 'co_1_consumption_total',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 1 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_1_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'CO 2',
        className: 'text-end-input lubricant-input',
        key: 'co_2_consumption_total',
        width: { xs: '' },
        type: 'number',
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 2 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_2_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input',
        key: 'co_3_consumption_total',
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 3 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_3_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'TOTAL CO',
        className: 'text-end-input lubricant-input cms-4 readonly-input',
        key: 'co_total_consumption_total',
        autocompleteValues: [
          { key: 'co_1_consumption_total' },
          { key: 'co_2_consumption_total' },
          { key: 'co_3_consumption_total' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'TOTAL CO RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input cms-4',
        key: 'go_consumption_total',
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'GO RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'go_consumption_rate',
        type: 'number',
        render: () => null
      }
    ]
  },
  {
    fieldTitle: 'OIL REPLACED',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Oil Replaced',
        subLabel: 'GO',
        key: 'go_replaced',
        className: 'text-end-input lubricant-input ms-auto me-9 px-0',
        width: { xs: { size: 1 } },
        type: 'number',
        render: NumberField
      }
    ]
  },
  {
    fieldTitle: 'FEED RATE',
    fieldSubTitle: 'g/kwh',
    fields: [
      {
        label: 'CO Feed Rate',
        subLabel: 'CO 1',
        key: 'co_1_feed_rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex justify-content-end cpe-12',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex justify-content-end cpe-12',
        subLabel: 'CO 2',
        key: 'co_2_feed_rate',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        subLabel: 'CO 3',
        key: 'co_3_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-10',
        width: { xs: 7 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className: 'lubricant-input text-end-input readonly-input d-flex justify-content-end',
        subLabel: 'TOTAL CO',
        key: 'co_total_feed_rate',
        autocompleteValues: [
          { key: 'co_1_feed_rate' },
          { key: 'co_2_feed_rate' },
          { key: 'co_3_feed_rate' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: 6 },
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        subLabel: 'GO',
        key: 'go_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-1',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: getTotalConsumedFieldTitle(params.type),
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Total Consumed',
        subLabel: 'CO 1',
        className: 'text-end-input lubricant-input readonly-input pe-2',
        key: 'co_1_leg_total_consumption_total',
        lubricantRateKey: 'co_1_leg_total_consumption_rate',
        width: { xs: '' },
        type: 'number',
        autocompleteValues: [{ key: 'co_1_consumption_total' }],
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_1_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'CO 2',
        autocompleteValues: [{ key: 'co_2_consumption_total' }],
        className: 'text-end-input lubricant-input readonly-input pe-2',
        key: 'co_2_leg_total_consumption_total',
        type: 'number',
        lubricantRateKey: 'co_2_leg_total_consumption_rate',
        width: { xs: '' },
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_2_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'CO 3',
        autocompleteValues: [{ key: 'co_3_consumption_total' }],
        key: 'co_3_leg_total_consumption_total',
        className: 'text-end-input lubricant-input readonly-input pe-2',
        width: { xs: '' },
        type: 'number',
        lubricantRateKey: 'co_3_leg_total_consumption_rate',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_3_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'TOTAL CO',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_total_leg_total_consumption_total',
        autocompleteValues: [
          { key: 'co_1_leg_total_consumption_total' },
          { key: 'co_2_leg_total_consumption_total' },
          { key: 'co_3_leg_total_consumption_total' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: '' },
        disabled: true,
        lubricantRateKey: 'leg_total_co_total_consumption_rate',
        render: LubricantsLegTotalConsumptionInput,
        type: 'number'
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'leg_total_co_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        autocompleteValues: [{ key: 'go_consumption_total' }],
        className: 'text-end-input lubricant-input readonly-input',
        subLabel: 'GO',
        key: 'go_leg_total_consumption_total',
        width: { xs: '' },
        type: 'number',
        lubricantRateKey: 'go_leg_total_consumption_rate',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'go_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      }
    ]
  },
  {
    fieldTitle: 'LEG AVG FEED RATE',
    fieldSubTitle: 'g/kwh',
    fields: [
      {
        label: 'CO Leg Average Feed Rate',
        subLabel: 'CO 1',
        key: 'co_1_leg_average_feed_rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        subLabel: 'CO 2',
        key: 'co_2_leg_average_feed_rate',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className: 'text-end-input lubricant-input readonly-input d-flex pe-10 justify-content-end',
        subLabel: 'CO 3',
        key: 'co_3_leg_average_feed_rate',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className: 'lubricant-input text-end-input readonly-input d-flex cpe-4 justify-content-end',
        subLabel: 'TOTAL CO',
        key: 'co_total_leg_average_feed_rate',
        autocompleteValues: [
          { key: 'co_1_leg_average_feed_rate' },
          { key: 'co_2_leg_average_feed_rate' },
          { key: 'co_3_leg_average_feed_rate' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: 6 },
        disabled: true,
        render: NumberField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'GO Leg Average Feed Rate',
        subLabel: 'GO',
        key: 'go_leg_average_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-1',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'CORRECTION',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Correction CO',
        subLabel: 'CO 1',
        key: 'co_1_correction',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction CO',
        subLabel: 'CO 2',
        key: 'co_2_correction',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction CO',
        className: 'text-end-input lubricant-input',
        subLabel: 'CO 3',
        key: 'co_3_correction',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction Total',
        subLabel: 'TOTAL CO',
        key: 'co_total_correction',
        className: 'lubricant-input readonly-input text-end-input',
        autocompleteValues: [
          { key: 'co_1_correction' },
          { key: 'co_2_correction' },
          { key: 'co_3_correction' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Correction',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input',
        key: 'go_correction',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      }
    ]
  },
  {
    fieldTitle: 'ROB',
    fieldSubTitle: 'L',
    fieldClassName: 'rob',
    fields: [
      {
        label: 'ROB',
        subLabel: 'CO 1',
        key: 'co_1_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'CO 2',
        key: 'co_2_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'CO 3',
        key: 'co_3_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'TOTAL CO',
        key: 'co_total_rob',
        className: 'text-end-input pe-8 cme-10 lubricant-input readonly-input lubricant-input__rob',
        width: { xs: '' },
        autocompleteValues: [{ key: 'co_1_rob' }, { key: 'co_2_rob' }, { key: 'co_3_rob' }],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'GO',
        key: 'go_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      }
    ]
  },
  {
    header: 'SO',
    subLabel: 'L',
    fields: [
      {
        label: 'MEASURED ROB',
        key: 'so_rob_measured',
        className: 'ps-0',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'MEASURED SUMP TANK',
        key: 'so_sump_tank_rob',
        className: 'ps-0',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'TOTAL ROB',
        key: 'so_rob',
        className: 'ps-0 mb-3',
        autocompleteValues: [{ key: 'so_sump_tank_rob' }, { key: 'so_rob_measured' }],
        autocomplete: autocompleteLubricantsSoTotalRob,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantSoRobField,
        type: 'number'
      },
      {
        label: 'CONSUMPTION',
        key: 'so_consumption_total',
        className: 'ps-0 cmt-4 readonly-input mb-2 cpb-12',
        autocompleteValues: [
          { key: 'so_rob' },
          { key: 'so_supplied' },
          { previousReportKey: 'so_rob' },
          { key: 'so_consumption_total' }
        ],
        autocomplete: autocompleteSoConsumption,
        disabled: true,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantsSoLegTotalConsumption,
        type: 'number'
      },
      {
        label: 'LEG TOTAL CONS',
        key: 'so_leg_total_consumption_total',
        className: 'ps-0 mb-1 readonly-input mb-2 cpb-12 ',
        lubricantRateKey: 'so_leg_total_consumption_rate',
        autocompleteValues: [{ key: 'so_consumption_total' }],
        disabled: true,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantLegTotalConsumed,
        type: 'number'
      },
      {
        label: 'SO Consumption Rate',
        className: 'lubricant-input readonly-input',
        subLabel: 'SO',
        width: { xs: '' },
        key: 'so_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'FEED RATE',
        key: 'so_feed_rate',
        className: 'ps-0 pe-0 readonly-input mb-2',
        subLabel: 'g/kwh',
        width: { xs: 2 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'LEG AVG FEED RATE',
        key: 'so_leg_average_feed_rate',
        className: 'ps-0 pe-0  readonly-input mb-4',
        disabled: true,
        subLabel: 'g/kwh',
        width: { xs: 2 },
        render: LubricantLegAverageFeedRateField,
        type: 'number'
      },
      {
        label: 'MEASURED SUMP TANK',
        key: 'so_sump_tank_measurement_cm',
        className: 'pl-0',
        subLabel: 'cm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const departure = params => [
  {
    fieldTitle: 'BASE NUMBER',
    fields: [
      {
        label: 'Base  Number',
        subLabel: 'CO 1',
        key: 'co_1_base_number',
        previousReportInitializationKey: 'co_1_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'CO 2',
        key: 'co_2_base_number',
        previousReportInitializationKey: 'co_2_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input',
        key: 'co_3_base_number',
        previousReportInitializationKey: 'co_3_base_number',
        width: { xs: '' },
        render: BaseNumberField,
        selectRest: {
          list: 'captain-report-enums-co-base-number',
          isAsync: false
        }
      },
      {
        label: 'Base  Number',
        subLabel: 'GO',
        key: 'go_base_number',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        render: () => null,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'CONSUMPTION',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Consumption',
        subLabel: 'CO 1',
        key: 'co_1_consumption_total',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 1 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_1_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'CO 2',
        className: 'text-end-input lubricant-input',
        key: 'co_2_consumption_total',
        width: { xs: '' },
        type: 'number',
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 2 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_2_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input',
        key: 'co_3_consumption_total',
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'CO 3 RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_3_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'TOTAL CO',
        className: 'text-end-input lubricant-input cms-4 readonly-input',
        key: 'co_total_consumption_total',
        autocompleteValues: [
          { key: 'co_1_consumption_total' },
          { key: 'co_2_consumption_total' },
          { key: 'co_2_consumption_total' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'TOTAL CO RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Consumption',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input cms-4',
        key: 'go_consumption_total',
        type: 'number',
        width: { xs: '' },
        render: LubricantsConsumptionInput
      },
      {
        label: 'Consumption',
        subLabel: 'GO RATE',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'go_consumption_rate',
        type: 'number',
        render: () => null
      }
    ]
  },
  {
    fieldTitle: getTotalConsumedFieldTitle(params.type),
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Total Consumed',
        subLabel: 'CO 1',
        className: 'text-end-input lubricant-input readonly-input pe-2',
        key: 'co_1_leg_total_consumption_total',
        lubricantRateKey: 'co_1_leg_total_consumption_rate',
        width: { xs: '' },
        type: 'number',
        autocompleteValues: [{ key: 'co_1_consumption_total' }],
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_1_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'CO 2',
        autocompleteValues: [{ key: 'co_2_consumption_total' }],
        className: 'text-end-input lubricant-input readonly-input pe-2',
        key: 'co_2_leg_total_consumption_total',
        type: 'number',
        lubricantRateKey: 'co_2_leg_total_consumption_rate',
        width: { xs: '' },
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_2_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'CO 3',
        autocompleteValues: [{ key: 'co_3_consumption_total' }],
        key: 'co_3_leg_total_consumption_total',
        className: 'text-end-input lubricant-input readonly-input pe-2',
        width: { xs: '' },
        type: 'number',
        lubricantRateKey: 'co_3_leg_total_consumption_rate',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_3_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        subLabel: 'TOTAL CO',
        className: 'text-end-input lubricant-input readonly-input',
        key: 'co_total_leg_total_consumption_total',
        autocompleteValues: [
          { key: 'co_1_leg_total_consumption_total' },
          { key: 'co_2_leg_total_consumption_total' },
          { key: 'co_3_leg_total_consumption_total' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: '' },
        disabled: true,
        lubricantRateKey: 'leg_total_co_total_consumption_rate',
        render: LubricantsLegTotalConsumptionInput,
        type: 'number'
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'leg_total_co_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'Total Consumed',
        autocompleteValues: [{ key: 'go_consumption_total' }],
        className: 'text-end-input lubricant-input readonly-input',
        subLabel: 'GO',
        key: 'go_leg_total_consumption_total',
        width: { xs: '' },
        type: 'number',
        lubricantRateKey: 'go_leg_total_consumption_rate',
        render: LubricantLegTotalConsumed
      },
      {
        className: 'text-end-input lubricant-input readonly-input',
        key: 'go_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      }
    ]
  },
  {
    fieldTitle: 'LEG AVG FEED RATE',
    fieldSubTitle: 'g/kwh',
    fields: [
      {
        label: 'CO Leg Average Feed Rate',
        subLabel: 'CO 1',
        key: 'co_1_leg_average_feed_rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className:
          'text-end-input lubricant-input readonly-input d-flex cpe-12 justify-content-end',
        subLabel: 'CO 2',
        key: 'co_2_leg_average_feed_rate',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className: 'text-end-input lubricant-input readonly-input d-flex pe-10 justify-content-end',
        subLabel: 'CO 3',
        key: 'co_3_leg_average_feed_rate',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'CO Leg Average Feed Rate',
        className: 'lubricant-input text-end-input readonly-input d-flex cpe-4 justify-content-end',
        subLabel: 'TOTAL CO',
        key: 'co_total_leg_average_feed_rate',
        autocompleteValues: [
          { key: 'co_1_leg_average_feed_rate' },
          { key: 'co_2_leg_average_feed_rate' },
          { key: 'co_3_leg_average_feed_rate' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: 6 },
        disabled: true,
        render: NumberField,
        type: 'number',
        templateValidation: { hide_is_required: true }
      },
      {
        label: 'GO Leg Average Feed Rate',
        subLabel: 'GO',
        key: 'go_leg_average_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-1',
        width: { xs: 6 },
        render: LubricantLegAverageFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'OIL REPLACED',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Oil Replaced',
        subLabel: 'GO',
        key: 'go_replaced',
        className: 'text-end-input lubricant-input ms-auto me-9 px-0',
        width: { xs: { size: 1, offset: 10 } },
        type: 'number',
        render: NumberField
      }
    ]
  },
  {
    fieldTitle: 'FEED RATE',
    fieldSubTitle: 'g/kwh',
    fields: [
      {
        label: 'CO Feed Rate',
        subLabel: 'CO 1',
        key: 'co_1_feed_rate',
        className: 'text-end-input lubricant-input readonly-input d-flex cpe-2 justify-content-end',
        width: { xs: 5 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className: 'text-end-input lubricant-input readonly-input d-flex cpe-2 justify-content-end',
        subLabel: 'CO 2',
        key: 'co_2_feed_rate',
        width: { xs: 5 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        subLabel: 'CO 3',
        key: 'co_3_feed_rate',
        className:
          'text-end-input text-end lubricant-input readonly-input d-flex pe-10 justify-content-end',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        className: 'lubricant-input text-end-input readonly-input d-flex cpe-4 justify-content-end',
        subLabel: 'TOTAL CO',
        key: 'co_total_feed_rate',
        autocompleteValues: [
          { key: 'co_1_feed_rate' },
          { key: 'co_2_feed_rate' },
          { key: 'co_3_feed_rate' }
        ],
        autocomplete: autocompleteTotalCo,
        width: { xs: 6 },
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CO Feed Rate',
        subLabel: 'GO',
        key: 'go_feed_rate',
        className: 'text-end-input text-end lubricant-input readonly-input pe-1',
        width: { xs: 6 },
        render: LubricantFeedRateField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: 'SUPPLIED',
    fieldSubTitle: 'L',
    fieldClassName: 'supplied',
    fields: [
      {
        label: 'Supplied',
        subLabel: 'CO 1',
        className: 'text-end-input lubricant-input lubricant-input__supplied',
        key: 'co_1_supplied',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Supplied',
        subLabel: 'CO 2',
        key: 'co_2_supplied',
        className: 'text-end-input lubricant-input lubricant-input__supplied',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Supplied',
        subLabel: 'CO 3',
        className: 'text-end-input lubricant-input lubricant-input__supplied',
        key: 'co_3_supplied',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Supplied',
        subLabel: 'TOTAL CO',
        key: 'co_total_supplied',
        className: 'lubricant-input readonly-input lubricant-input__supplied text-end-input cpe-4',
        width: { xs: 6 },
        autocompleteValues: [
          { key: 'co_1_supplied' },
          { key: 'co_2_supplied' },
          { key: 'co_3_supplied' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Supplied',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input lubricant-input__supplied',
        key: 'go_supplied',
        width: { xs: '' },
        render: NumberField,
        type: 'number'
      }
    ]
  },
  {
    fieldTitle: getTotalSuppliedFieldTitle(params.type),
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Total Supplied',
        autocompleteValues: [{ key: 'co_1_supplied' }],
        subLabel: 'CO 1',
        key: 'co_1_leg_total_supplied',
        className: 'text-end-input lubricant-input readonly-input cme-10',
        width: { xs: '' },
        render: LubricantTotalAutocomplete
      },
      {
        label: 'Total Supplied',
        autocompleteValues: [{ key: 'co_2_supplied' }],
        subLabel: 'CO 2',
        key: 'co_2_leg_total_supplied',
        className: 'text-end-input lubricant-input readonly-input cme-10',
        width: { xs: '' },
        render: LubricantTotalAutocomplete
      },
      {
        label: 'Total Supplied',
        autocompleteValues: [{ key: 'co_3_supplied' }],
        className: 'text-end-input lubricant-input readonly-input pe-2',
        subLabel: 'CO 3',
        key: 'co_3_leg_total_supplied',
        width: { xs: '' },
        render: LubricantTotalAutocomplete
      },
      {
        label: 'Total Supplied',
        subLabel: 'TOTAL CO',
        key: 'co_total_leg_total_supplied',
        className: 'lubricant-input readonly-input text-end-input cpe-4',
        autocompleteValues: [
          { key: 'co_1_leg_total_supplied' },
          { key: 'co_2_leg_total_supplied' },
          { key: 'co_3_leg_total_supplied' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Total Supplied',
        subLabel: 'GO',
        autocompleteValues: [{ key: 'go_supplied' }],
        className: 'text-end-input lubricant-input readonly-input cme-10',
        key: 'go_leg_total_supplied',
        width: { xs: '' },
        render: LubricantTotalAutocomplete
      }
    ]
  },
  {
    fieldTitle: 'CORRECTION',
    fieldSubTitle: 'L',
    fields: [
      {
        label: 'Correction CO',
        subLabel: 'CO 1',
        key: 'co_1_correction',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction CO',
        subLabel: 'CO 2',
        key: 'co_2_correction',
        className: 'text-end-input lubricant-input',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction CO',
        className: 'text-end-input lubricant-input ',
        subLabel: 'CO 3',
        key: 'co_3_correction',
        width: { xs: '' },
        type: 'number',
        render: NumberField
      },
      {
        label: 'Correction Total',
        subLabel: 'TOTAL CO',
        key: 'co_total_correction',
        className: 'lubricant-input readonly-input text-end-input',
        autocompleteValues: [
          { key: 'co_1_correction' },
          { key: 'co_2_correction' },
          { key: 'co_3_correction' }
        ],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        width: { xs: 6 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'Correction',
        subLabel: 'GO',
        className: 'text-end-input lubricant-input',
        key: 'go_correction',
        type: 'number',
        width: { xs: '' },
        render: NumberField
      }
    ]
  },
  {
    fieldTitle: 'ROB',
    fieldSubTitle: 'L',
    fieldClassName: 'rob',
    fields: [
      {
        label: 'ROB',
        subLabel: 'CO 1',
        key: 'co_1_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'CO 2',
        key: 'co_2_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'CO 3',
        key: 'co_3_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'TOTAL CO',
        key: 'co_total_rob',
        className: 'text-end-input pe-8 cme-10 lubricant-input readonly-input lubricant-input__rob',
        width: { xs: '' },
        autocompleteValues: [{ key: 'co_1_rob' }, { key: 'co_2_rob' }, { key: 'co_3_rob' }],
        autocomplete: autocompleteTotalCo,
        disabled: true,
        render: NumberField,
        type: 'number'
      },
      {
        label: 'ROB',
        subLabel: 'GO',
        key: 'go_rob',
        className: 'text-end-input lubricant-input lubricant-input__rob',
        width: { xs: '' },
        render: LubricantTotalRob,
        type: 'number'
      }
    ]
  },
  {
    header: 'SO',
    subLabel: 'L',
    fields: [
      {
        label: 'MEASURED ROB',
        key: 'so_rob_measured',
        className: 'ps-0',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'MEASURED SUMP TANK',
        key: 'so_sump_tank_rob',
        className: 'ps-0',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'TOTAL ROB',
        key: 'so_rob',
        className: 'ps-0 mb-1 cpb-4',
        autocompleteValues: [{ key: 'so_sump_tank_rob' }, { key: 'so_rob_measured' }],
        autocomplete: autocompleteLubricantsSoTotalRob,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantSoRobField,
        type: 'number'
      },
      {
        label: 'SUPPLIED',
        key: 'so_supplied',
        className: 'mt-2 ps-0 mb-2 cpb-12',
        subLabel: 'L',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      },
      {
        label: 'CONSUMPTION',
        key: 'so_consumption_total',
        className: 'ps-0 cmt-4 readonly-input mb-2',
        autocompleteValues: [
          { key: 'so_rob' },
          { key: 'so_supplied' },
          { previousReportKey: 'so_rob' },
          { key: 'so_consumption_total' }
        ],
        autocomplete: autocompleteSoConsumption,
        disabled: true,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantsSoLegTotalConsumption,
        type: 'number'
      },
      {
        label: 'LEG TOTAL CONS',
        key: 'so_leg_total_consumption_total',
        className: 'ps-0 readonly-input cmt-6',
        lubricantRateKey: 'so_leg_total_consumption_rate',
        autocompleteValues: [{ key: 'so_consumption_total' }],
        disabled: true,
        subLabel: 'L',
        width: { xs: 2 },
        render: LubricantLegTotalConsumed,
        type: 'number'
      },
      {
        label: 'FEED RATE',
        key: 'so_feed_rate',
        className: 'ps-0 pe-0 readonly-input mb-0',
        subLabel: 'g/kwh',
        width: { xs: 2 },
        render: LubricantFeedRateField,
        type: 'number'
      },
      {
        label: 'LEG AVG FEED RATE',
        key: 'so_leg_average_feed_rate',
        className: 'ps-0 pe-0 readonly-input mb-4',
        disabled: true,
        subLabel: 'g/kwh',
        width: { xs: 2 },
        render: LubricantLegAverageFeedRateField,
        type: 'number'
      },
      {
        label: 'SO Consumption Rate',
        className: 'lubricant-input readonly-input',
        subLabel: 'SO',
        width: { xs: '' },
        key: 'so_leg_total_consumption_rate',
        type: 'number',
        render: () => null
      },
      {
        label: 'MEASURED SUMP TANK',
        key: 'so_sump_tank_measurement_cm',
        className: 'pl-0',
        subLabel: 'cm',
        width: { xs: 2 },
        render: NumberField,
        type: 'number'
      }
    ]
  }
];

const lubricantsConfig = {
  noon: () => noon({ type: 'noon' }),
  arrival: () => arrival({ type: 'arrival' }),
  ballast: () => [],
  delivery: () => [],
  redelivery: () => [],
  departure: () => departure({ type: 'departure' }),
  port_noon: () => departure({ type: 'port_noon' }),
  stoppage: () => [],
  instructed_speed: () => [],
  passage_plan: () => []
};

export default lubricantsConfig;
