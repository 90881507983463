import React from 'react';
import AddActionButton from './AddActionButton';
import PeriodButtonCreateDraft from './PeriodButtonCreateDraft';
import MoreActionsDropdown from 'common/components/general/MoreActionsDropdown';
import PropTypes from 'prop-types';
import calendar from 'common/assets/svg/common/calendar-blue.svg';

const OfficeMoreActions = ({ isForReview }) => {
  return (
    <MoreActionsDropdown
      className="mx-0"
      menuPostionIsRight={false}
      options={[
        {
          title: (
            <AddActionButton color="link" className="p-0 text-dark fw-light" hideIcon={false} />
          ),
          isEnabled: isForReview
        },
        {
          title: (
            <PeriodButtonCreateDraft
              color="link"
              className="p-0 text-dark fw-light"
              icon={calendar}
            />
          ),
          isEnabled: true
        }
      ]}
    />
  );
};

AddActionButton.propTypes = {
  isForReview: PropTypes.bool
};

export default OfficeMoreActions;
