import React, { useState, useEffect, useRef } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';
import { formatDate } from 'cleave-zen';

const DateMonthInput = ({ value, onChange, disabled, focused }) => {
  const [day] = useState('01');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [monthIsFocused, setMonthIsFocused] = useState(false);

  const [isInvalid, setIsInvalid] = useState(false);

  const monthRef = useRef(null);
  const yearRef = useRef(null);

  useEffect(() => {
    if (value) {
      const vmonth = moment(value).format('MM');
      const vyear = moment(value).format('YYYY');

      setMonth(vmonth ? vmonth : '');
      setYear(vyear ? vyear : '');
    } else if (!value && (month || year)) {
      setMonth('');
      setYear('');
    }
  }, [value]);

  useUpdateEffect(() => {
    if (
      monthIsFocused &&
      moment(`${day}-${month}-${year ? year : moment().year()}`, 'DD-MM-YYYY', true).isValid()
    ) {
      yearRef.current.focus();
    }
  }, [month]);

  useUpdateEffect(() => {
    if (value && moment(value).format('DD-MM-YYYY') === `${day}-${month}-${year}`) {
      return;
    }

    if (
      moment(`${day}-${month}-${year}`, 'DD-MM-YYYY', true).isValid() &&
      month?.length === 2 &&
      year?.length === 4
    ) {
      onChange(moment(`${day}-${month}-${year}`, 'DD-MM-YYYY'));
      setIsInvalid(false);
    } else if (day && month && year) {
      setIsInvalid(true);
    }

    if (!day && !month && !year && value) {
      // Reset value
      onChange(null);
    }
  }, [month, year]);

  return (
    <div
      className={`datetime-inputs d-flex align-items-center${isInvalid ? ' invalid-date' : ''}${
        !value ? ' empty' : ''
      }${focused ? ' focused' : ''}`}
    >
      <input
        className="form-control form-field form-field__month"
        ref={monthRef}
        value={month}
        placeholder={'mm'}
        onChange={e => {
          const monthValue = formatDate(e.target.value, { datePattern: ['m'] });
          setMonth(monthValue);
        }}
        onFocus={() => setMonthIsFocused(true)}
        onBlur={() => {
          setMonthIsFocused(false);

          if (parseInt(month, 10) === 1) {
            setMonth('01');
          }
        }}
        disabled={disabled}
        data-cy="date-input--month"
      ></input>

      <div className="form-field__separator">-</div>

      <input
        className="form-control form-field form-field__year"
        ref={yearRef}
        value={year}
        placeholder={'yyyy'}
        onChange={e => {
          const yearValue = formatDate(e.target.value, { datePattern: ['Y'] });
          setYear(yearValue);
        }}
        disabled={disabled}
        data-cy="date-input--year"
      ></input>
    </div>
  );
};

DateMonthInput.propTypes = {
  value: PropTypes.instanceOf(moment)
};

export default DateMonthInput;
