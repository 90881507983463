import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import NumberField from 'captain-reports/templates/inputs/NumberField';

import { Row, Col } from 'reactstrap';

import useValue from 'captain-reports/utils/useValue';
import { useActions } from 'utils/hooks';
import ValidationWrapper from 'captain-reports/templates/components/ValidationWrapper';
import * as reportActions from 'captain-reports/store/actions';
import { numberToStr, strToNumber } from 'common/utils/numbers';

import {
  selectPreviousCaptainReportBunkerPerFuelGrade,
  selectConsumptionFuelGrade,
  selectCaptainReportStatus,
  selectEdittingApprovedReport
} from 'captain-reports/store/selectors';

import { useSelector } from 'react-redux';
import useAutoCalculateBunkerConsumptionSecondaryRob from 'captain-reports/utils/useAutoCalculateBunkerConsumptionSecondaryRob';
import { isReportStatusLocked } from 'captain-reports/store/helpers';

const ConsumptionRob = ({ field, sectionLabel, sectionActiveTab, subGroup, subGroupIndex }) => {
  const [robValue, setRobValue] = useState('');

  const supplied = useValue({
    key: 'supplied',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const correction = useValue({
    key: 'correction',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const secondaryRobValue = useAutoCalculateBunkerConsumptionSecondaryRob({
    field,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const totalConsumption = useValue({
    key: 'total_consumption',
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const rob = useValue({
    key: field.key,
    sectionLabel,
    sectionActiveTab,
    subGroup,
    subGroupIndex
  });

  const [setValue] = useActions([reportActions.setValue]);

  const fuelGrade = useSelector(state =>
    selectConsumptionFuelGrade(state, 'consumptions', subGroupIndex)
  );
  const prevBunker = useSelector(state =>
    selectPreviousCaptainReportBunkerPerFuelGrade(state, fuelGrade?.id)
  );

  const status = useSelector(selectCaptainReportStatus);
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isEdittingApprovedReport = useSelector(selectEdittingApprovedReport);

  const isLocked = isReportStatusLocked(status, isOnBoard, isEdittingApprovedReport);

  const isViewOnly = prevBunker?.rob?.value || prevBunker?.rob?.value === 0;

  useEffect(() => {
    if (isLocked) {
      setRobValue(rob);
      return;
    }

    if (!prevBunker?.rob?.value && prevBunker?.rob?.value !== 0) {
      return;
    }

    const prev = numeral(strToNumber(prevBunker?.rob?.value || 0)); // Calculate the previous rob only for the First Column
    const result = prev
      .subtract(+totalConsumption || 0)
      .add(+supplied || 0)
      .add(+correction || 0)
      .add(secondaryRobValue);

    setValue({
      key: field.key,
      value: result?._value,
      sectionLabel,
      sectionActiveTab,
      subGroup,
      subGroupIndex
    });

    setRobValue(result?._value);
  }, [totalConsumption, supplied, correction, secondaryRobValue, isLocked, rob]);

  return (
    <Row className="align-items-center">
      <Col xs={12}>
        {isViewOnly ? (
          <ValidationWrapper
            field={{ key: field.key, label: null }}
            value={totalConsumption}
            sectionLabel={sectionLabel}
            errorClassName="align-items-end"
            sectionActiveTab={sectionActiveTab}
            className="pb-1"
            subGroup={subGroup}
            subGroupIndex={subGroupIndex}
            right={true}
          >
            <div
              className={`w-100p mt-2 fs-12 fw-bold text-end ${
                robValue && robValue < 0 ? 'text-red' : ''
              }`}
            >
              {numberToStr(robValue)}
            </div>
          </ValidationWrapper>
        ) : (
          <NumberField
            field={field}
            sectionLabel={sectionLabel}
            sectionActiveTab={sectionActiveTab}
            subGroup={subGroup}
            subGroupIndex={subGroupIndex}
          />
        )}
      </Col>
    </Row>
  );
};

export default ConsumptionRob;
