import { useState } from 'react';

import Input from 'common/components/form/inputs/Input';
import CircledButton from 'common/components/buttons/CircledButton';

const TitleInput = ({ onCancel, onSave, initialTitle }) => {
  const [title, setTitle] = useState(initialTitle ? initialTitle : '');

  const [isSaving, setIsSaving] = useState(false);

  const onSaveTitle = async () => {
    if (!title) return;
    setIsSaving(true);

    try {
      await onSave(title);
    } catch (e) {
      console.log(e);
    }

    setIsSaving(false);
  };

  return (
    <div className="chapters-tree__input">
      <Input
        className="w-100p mb-0"
        placeholder="Title"
        value={title}
        onChange={e => setTitle(e.target.value)}
        white
        autoFocus
      />

      <div className="chapters-tree__input-actions d-flex flex-nowrap">
        <CircledButton
          type="close"
          style={{ width: 16, maxWidth: 16, height: 16 }}
          svgStyle={{ width: 8, height: 8 }}
          className={`me-1 ${isSaving ? 'disabled' : ''}`}
          onClick={onCancel}
          disabled={isSaving}
        />
        <CircledButton
          type="save"
          disabled={!title || isSaving}
          style={{ width: 16, maxWidth: 16, height: 16 }}
          svgStyle={{ width: 8, height: 8 }}
          onClick={onSaveTitle}
        />
      </div>
    </div>
  );
};

export default TitleInput;
