import { createSelector } from 'reselect';

const selectDashboardReducer = state => state.dashboard.tables;
const getLabel = (_, label) => label;

export const selectDashboardTable = createSelector(
  [selectDashboardReducer, getLabel],
  (tables, label) => tables[label]
);

export const selectDashboardTablePagination = createSelector(
  [selectDashboardTable],
  table => table.paging
);

export const selectDashboardTableData = createSelector(
  [selectDashboardTable],
  table => table?.data
);

export const selectDashboardTableFetchedData = createSelector(
  [selectDashboardTableData],
  data => (data || [])?.filter(d => !d.fixed_value)?.length
);

export const selectDashboardTableIsLoading = createSelector(
  [selectDashboardTable],
  table => table.isLoading
);

export const selectShouldRefetchDashboardTableData = createSelector(
  [selectDashboardTable],
  table => table.shouldRefetchTableData
);

// Evaluations
export const selectEvaluationsTableData = (state, label, name) =>
  selectDashboardTableData(state, label)?.find(data => data.name === name)?.data || [];

export const selectEvaluationsHaveData = (state, label, name) =>
  selectEvaluationsTableData(state, label, name)?.length;
export const selectEvaluationsFetchedData = (state, label, name) =>
  selectEvaluationsTableData(state, label, name)?.filter(d => !d.fixed_value)?.length;

// Crew

export default createSelector([selectDashboardTable], table => table.date_of_change);

export const selectCrewPort = createSelector([selectDashboardTable], table => table.next_port);
