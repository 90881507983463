import * as d3 from 'd3';
import _get from 'lodash/get';
import { isPlotTypesTooltipAllowed } from './_functions';

export const findClosestDataPoint = (data, pointerX, pointerY, x, y) => {
  let closestDataPoint = null;
  let closestDistance = Infinity;

  data.forEach(datum => {
    const xPosition = x(datum.x);
    const yPosition = y[datum.axisIndex] ? y[datum.axisIndex].axis(datum.y) : null;

    if (!xPosition || !yPosition) return null;

    const distance = Math.sqrt(
      Math.pow(xPosition - pointerX, 2) + Math.pow(yPosition - pointerY, 2)
    );

    if (distance < closestDistance) {
      closestDistance = distance;
      closestDataPoint = { ...datum, yPosition, xPosition };
    }
  });

  return closestDataPoint;
};

const addHoverListener = ({
  dataFromAllPlots,
  schema,
  memoryElement,
  element,
  events,
  x,
  y,
  graphUID,
  disabledPlots,
  boundlessHover
}) => {
  const getXAndY = () => {
    return {
      x: events.groupGraphsAxesInfo[graphUID].x || x,
      y: events.groupGraphsAxesInfo[graphUID].y || y
    };
  };

  const limitDataToBounds = (pointerX, pointerY) => {
    const newFlatData = [];
    const newGroupedData = {};

    const { x, y } = getXAndY();

    dataFromAllPlots.forEach(datum => {
      if (disabledPlots.includes(datum.plotIndex) || !datum.y) return;

      datum['xPosition'] = x(datum.x);
      datum['yPosition'] = y[datum.axisIndex] ? y[datum.axisIndex].axis(datum.y) : null;

      if (!boundlessHover && isOutsideMouseBounds(datum.xPosition, pointerX)) return;
      if (!boundlessHover && isOutsideMouseBounds(datum.yPosition, pointerY)) return;

      newFlatData.push(datum);
      if (!newGroupedData[datum.x]) {
        newGroupedData[datum.x] = [];
      }
      newGroupedData[datum.x].push(datum);
    });

    return { groupedData: newGroupedData, flatData: newFlatData };
  };

  const sortYData = yData => {
    if (!yData) return [];

    return yData.sort((a, b) => a.yPosition - b.yPosition);
  };
  const sortXData = data => {
    if (!data) return [];

    return data.sort((a, b) => a.xPosition - b.xPosition);
  };

  const dispatchHoveringEvent = dataPoint => {
    // dispatch custom event to document that contains the graph uid in the name
    // and the data point in the detail
    const event = new CustomEvent(`graph-hovering--${events.eventsID}`, {
      detail: dataPoint
    });
    // dispatch the event to the document
    document.dispatchEvent(event);
  };

  const isOutsideMouseBounds = (mousePos, pointerPos) => {
    const DISTANCE_IN_PX = 16;
    const from = pointerPos - DISTANCE_IN_PX;
    const to = pointerPos + DISTANCE_IN_PX;

    return mousePos < from || mousePos > to;
  };

  const getIsBarChart = data => data.some(datum => datum?.plot?.type?.includes('bar'));

  const onMouseMove = e => {
    const { x, y } = getXAndY();

    const pointerX = d3.pointer(e)[0];
    const bisectX = d3.bisector(d => {
      return d && d.xPosition;
    });

    const pointerY = d3.pointer(e)[1];
    const bisectY = d3.bisector(d => {
      return d.yPosition;
    });

    const isBarChart = getIsBarChart(dataFromAllPlots);

    const closestDataPoint = findClosestDataPoint(dataFromAllPlots, pointerX, pointerY, x, y);

    if (!isBarChart && !closestDataPoint) return;

    if (
      !isBarChart &&
      (isOutsideMouseBounds(closestDataPoint.yPosition, pointerY) ||
        isOutsideMouseBounds(closestDataPoint.xPosition, pointerX))
    ) {
      onMouseOut();
      return;
    }

    const { flatData, groupedData } = limitDataToBounds(pointerX, pointerY);
    const data = sortXData(flatData);

    const iX = bisectX.center(data, pointerX, 0);
    const xDatapoint = data[iX];

    if (!xDatapoint || !isPlotTypesTooltipAllowed(xDatapoint)) return;

    const yData = sortYData(groupedData[xDatapoint.x]);
    if (yData.length) {
      const iY = bisectY.center(yData, pointerY, 0);
      const yDatapoint = yData[iY];

      if (yDatapoint) {
        const shape = _get(yDatapoint, 'plot.style.shape') === 'triangle' ? 'polygon' : 'circle';
        onMouseHover(yDatapoint);
        const hoverPoints = memoryElement.selectAll(`custom.${shape}.hover-points`).data(yData);

        (!hoverPoints.empty()
          ? hoverPoints
          : hoverPoints.enter().append('custom').classed(`${shape} hover-points`, true)
        )
          .attr('x', d => x(d.x))
          .attr('y', d => d.yPosition)
          .attr('plot-index', d => d.plotIndex)
          .attr('radius', shape === 'circle' ? 3 : null)
          .attr('stroke', '#354069')
          .attr('stroke-width', 1.5)
          .attr('fill', '#fff')
          .attr('points', shape === 'polygon' ? '-4,2 4,2 0,-4' : null);
      }
    }
  };

  const onMouseHover = dataPoint => {
    memoryElement.selectAll('custom.hovering-node').classed('hovering-node', false);
    memoryElement.selectAll(`custom.plot-${dataPoint.plotIndex}`).classed('hovering-node', true);

    dispatchHoveringEvent(dataPoint); // <- this sets the id of the current hovering circle in order for the tooltip to work.
  };

  const onMouseOut = e => {
    // Same here as on mouseover
    dispatchHoveringEvent(null);

    memoryElement.selectAll('custom.circle.hover-points').remove();
    memoryElement.selectAll('custom.polygon.hover-points').remove();
    memoryElement.selectAll('custom.hovering-node').classed('hovering-node', false);
  };

  if (events && !_get(schema, 'disableHover')) {
    element
      .on('mousemove', onMouseMove)
      .on('mouseout', onMouseOut, { passive: true })
      .selectAll('.hover')
      .on('mousedown', onMouseOut, { passive: true })
      .on('drag', onMouseOut, { passive: true })
      .on('wheel', onMouseOut, { passive: true });
  }
};

export default addHoverListener;
