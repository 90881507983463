import React, { useEffect, useState } from 'react';
import { func, string, array, bool } from 'prop-types';
import Select from 'common/components/form/inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';
import AsyncSelector from './AsyncSelector';

const OnMenuCloseSelector = ({ onChange, icon, value, isAsync, ...rest }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tempSelectedOptions, setTempSelectedOptions] = useState(value?.length ? value : []);

  const handleActualOnChange = () => {
    onChange(tempSelectedOptions);
    setIsMenuOpen(false);
  };

  const handleTempOnChange = tempValues => {
    setTempSelectedOptions(tempValues);

    if (!isMenuOpen) {
      onChange(tempValues);
    }
  };

  useEffect(() => {
    if (value?.length && !isMenuOpen) {
      setTempSelectedOptions(value);
    }
  }, [value, tempSelectedOptions, isMenuOpen]);

  const Tag = icon ? IconSelect : isAsync ? AsyncSelector : Select;

  return (
    <Tag
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={handleActualOnChange}
      onChange={handleTempOnChange}
      {...rest}
      isMulti
      value={tempSelectedOptions}
    />
  );
};

OnMenuCloseSelector.propTypes = {
  onChange: func.isRequired,
  icon: string,
  value: array,
  isAsync: bool
};

export default OnMenuCloseSelector;
