import { asyncTypes } from 'store/_helpers';

const TYPES = {
  ...asyncTypes('GET_JOB_STATUSES'),
  ...asyncTypes('CREATE_JOB_STATUS'),
  ...asyncTypes('REORDER_JOB_STATUSES'),
  ...asyncTypes('UPDATE_JOB_STATUS'),
  ...asyncTypes('DELETE_JOB_STATUS'),
  ...asyncTypes('ASSIGN_DEPARTMENTS')
};

export default TYPES;
