import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import FormSaveActions from 'common/components/form/FormSaveActions';
import Status from 'common/components/jobs/_base/components/Status';
import { NO_JOB_LINKED_TEXT } from 'common/components/jobs/_base/_helpers';

import { selectJobStatuses } from 'store/jobs-statuses/selectors';
import { useSelector } from 'react-redux';
import { Job } from 'common/entities/jobs/JobTypes';
import PreviewJobType from 'common/components/jobs/PreviewJobType';

// Possibly deprecated. Use the PreviewLinkedJob instead.
const PreviewJob = ({
  job,
  notes = null,
  typeName = '',
  backgroundColor = 'bg-shades-1',
  color = 'primary',
  isFlat = false,
  onClick,
  onDelete,
  onView,
  onEdit,
  isEventModule = false,
  showType = false,
  isLinkedWithForm = false,
  isPms = false
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState({ isOpen: false, linkedJob: null });
  const jobStatuses = useSelector(selectJobStatuses);
  const jobStatus = job?.status_id ? jobStatuses.find(st => st.id === job?.status_id) : job?.status;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <Row className={`w-100p flex-nowrap ${isFlat ? '' : 'cmb-4'} text-${color}`}>
        {notes ? (
          <Col
            xs={`${isEventModule && showType ? 3 : isEventModule ? 4 : 3}`}
            className={`d-flex align-items-center ${
              isFlat ? 'border-end' : `ps-3 pe-1 cpt-6 cpb-6 border-radius-5 ${backgroundColor}`
            }`}
          >
            <TextWithTooltip>{notes ? <span>{notes}</span> : null}</TextWithTooltip>
          </Col>
        ) : null}

        {isEventModule && showType ? (
          <Col
            xs={'auto'}
            className={`d-flex align-items-center ms-1
               ps-1 pe-1 cpt-6 cpb-6 border-radius-5 ${backgroundColor} max-width-165 w-100p`}
          >
            <TextWithTooltip className="d-flex align-items-center text-primary fs-12 fw-medium">
              {typeName || '-'}
            </TextWithTooltip>
          </Col>
        ) : null}

        <Col
          className={`d-flex align-items-center ms-1  ${
            isFlat ? '' : `ps-2 pe-3 cpt-6 cpb-6 border-radius-5 ${backgroundColor}`
          }`}
          xs={`${isEventModule ? '' : isPms ? 12 : 9}`}
        >
          <Row
            noGutters
            className="align-items-center w-100p"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <Col xs={9} className="overflow-hidden">
              <div
                className="link-medium fs-12 fw-medium d-flex align-items-center cursor-pointer"
                onClick={onClick ? () => onClick(job) : null}
              >
                <div className="overflow-hidden">
                  <TextWithTooltip>{job?.title || NO_JOB_LINKED_TEXT}</TextWithTooltip>
                </div>
                <PreviewJobType type={job?.type} />
              </div>
            </Col>

            <Col xs="auto" className={`ms-auto d-flex justify-content-end`}>
              <Status activeStatus={jobStatus} />
            </Col>
            <Col
              className={`d-flex align-items-center min-width-fit ${
                isLinkedWithForm ? 'max-width-48' : 'max-width-72'
              } ${isPms ? 'ms-auto' : ''}`}
            >
              {onEdit || onDelete || onView ? (
                <FormSaveActions
                  mode={'view'}
                  isHovering={isHovering}
                  onEdit={onEdit ? () => onEdit(job) : null}
                  onDelete={
                    onDelete
                      ? () =>
                          setShowDeleteModal({
                            isOpen: true,
                            linkedJob: isPms ? job?.id : job
                          })
                      : null
                  }
                  onView={onView && job?.id ? () => onView(job) : null}
                  style={{ right: -24 }}
                  editButtonProps={{
                    svgClassName: 'text-white',
                    svgWrapperClassName: 'bg-primary'
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </Col>

        <DangerousActionModal
          transparent
          action={'Remove'}
          onAccept={() => onDelete(showDeleteModal.linkedJob)}
          closeModal={() => setShowDeleteModal({ isOpen: false, linkedJob: null })}
          isOpen={showDeleteModal.isOpen}
          actionText={'REMOVE'}
          header={'Remove'}
          body={<div>Are you sure you want to remove this job link ?</div>}
        />
      </Row>
    </>
  );
};

PreviewJob.propTypes = {
  job: Job,
  disabled: PropTypes.bool,
  notes: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  isFlat: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  isEventModule: PropTypes.bool,
  isLinkedWithForm: PropTypes.bool,
  isPms: PropTypes.bool
};

export default PreviewJob;
