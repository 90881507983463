import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';

import ListSelect from 'common/components/selectors/ListSelect';

import { selectIsJobLocked } from 'common/components/jobs/_base/store/selectors';
import { selectMaintenanceDetaisField } from 'common/components/pms/jobs/store/selectors';

import * as maintenanceActions from 'common/components/jobs/maintenance/store/actions';
import { components } from 'react-select';
import TextWithTooltip from 'common/components/general/TextWithTooltip';

const MaintenanceJobType = ({ hideLabel = false, className, ...rest }) => {
  const [setMaintenanceField] = useActions([maintenanceActions.setMaintenanceField]);
  const isJobLocked = useSelector(selectIsJobLocked);
  const jobTypeId = useSelector(state => selectMaintenanceDetaisField(state, 'job_type_id'));

  return (
    <div className={`d-flex align-items-center  ${isJobLocked ? 'pointer-events-none' : ''}`}>
      {hideLabel ? null : <span className="form-label mb-0 me-1">Type:</span>}

      <ListSelect
        placeholder="Select type"
        onChange={opt => setMaintenanceField('job_type_id', opt, true)}
        getOptionLabel={option => option.name}
        list="maintenance-job-types"
        className={className}
        invisible={true}
        isClearable
        isAsync={false}
        disabled={isJobLocked}
        value={jobTypeId}
        extraPortalStyle={{
          minWidth: 250
        }}
        components={{ Option, SingleValue }}
        {...rest}
      />
    </div>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="text-truncate">{props.data.name}</div>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <TextWithTooltip>
      <div className="text-truncate overflow-hidden">{props.data.name}</div>
    </TextWithTooltip>
  </components.SingleValue>
);

export default MaintenanceJobType;
