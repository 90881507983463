import React, { useState, useEffect } from 'react';
import Select from 'common/components/form/inputs/Select';
import SelectSearchIndicator from 'common/components/form/helpers/SelectSearchIndicator';
import ShadowBox from 'common/components/general/ShadowBox';
import { Row, Col } from 'reactstrap';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { components } from 'react-select';
import _get from 'lodash/get';
import { selectEventId } from 'events/store/events/selectors';
import { useDispatch, useSelector } from 'react-redux';
import SvgRender from 'common/components/general/SvgRender';

import relatesIcon from 'common/assets/svg/jobs/relates.svg';
import causesIcon from 'common/assets/svg/events/modules/causes.svg';
import causedByIcon from 'common/assets/svg/events/modules/caused-by.svg';
import Priority from 'common/components/general/Priority';
import BaseStatusLabel from 'common/components/statuses/BaseStatusLabel';
import moment from 'moment';

import CircledButton from 'common/components/buttons/CircledButton';
import paths from 'routing/routes/_paths';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import { createEvent, deleteEvent, updateEvent } from 'events/store/event-modules/events/actions';

const relations = [
  { id: 'relates_to', name: 'Relates to', icon: relatesIcon },
  { id: 'caused_by', name: 'Caused by', icon: causedByIcon },
  { id: 'causes', name: 'Causes ', icon: causesIcon }
];

const formatDate = (date, showTime) => {
  if (!date) return '';
  const d = moment(date).format('DD/MM/YYYY');
  const h = moment(date).format('HH:mm');

  return showTime ? `${d} | ${h}` : d;
};

const Event = ({ event, setIsAdding, selectedEventIds, isViewOnly }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [relationValue, setRelationValue] = useState('relates_to');

  const dispatch = useDispatch();

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const eventId = useSelector(selectEventId);

  const onChange = async opt => {
    try {
      setEditing(true);
      if (event && event.related_event.id === value?.id) {
        await dispatch(
          updateEvent({
            id: eventId,
            previousEventId: event.id,
            related_event_id: opt.id,
            relation_type: relationValue
          })
        ).unwrap();
      } else {
        await dispatch(
          createEvent({ id: eventId, related_event_id: opt.id, relation_type: relationValue })
        ).unwrap();
        setIsAdding(false);
      }
      setEditing(false);
      setValue(opt);
    } catch (e) {
      setEditing(false);
    }
  };

  const onRelationChange = async opt => {
    if (value) {
      try {
        setEditing(true);
        if (event && event.related_event.id === value.id) {
          await dispatch(
            updateEvent({
              id: eventId,
              previousEventId: event.id,
              related_event_id: value.id,
              relation_type: opt
            })
          ).unwrap();
        } else {
          await dispatch(
            createEvent({ id: eventId, related_event_id: value.id, relation_type: opt })
          ).unwrap();
        }
        setEditing(false);
      } catch (e) {
        setEditing(false);
      }
    }
    setRelationValue(opt);
  };

  const onView = () =>
    window.open(`${window.location.origin}${paths.events}/${value.id}`, '_blank');

  const onDelete = async () => {
    if (deleting) return;

    try {
      setDeleting(true);

      await dispatch(
        deleteEvent({
          id: eventId,
          previousEventId: event.id
        })
      ).unwrap();
      setDeleting(false);
    } catch (e) {
      setDeleting(false);
    }
  };

  const onEmptyOptionDelete = () => setIsAdding(false);

  useEffect(() => {
    if (event?.id) {
      setValue(event?.related_event);
      setRelationValue(event?.relation_type);
    }
  }, [event?.id, event?.related_event, event?.relation_type]);

  return (
    <div
      className={`${
        editing ? 'opacity-5 pointer-events-none' : ''
      } position-relative single-job mb-1`}
    >
      <Row>
        <Col xs={2}>
          <ShadowBox className="px-2 py-1" flatOnHover>
            <Select
              invisible
              getOptionLabel={op => op.name}
              getOptionValue={op => op.id}
              options={relations}
              value={relationValue}
              onChange={onRelationChange}
              components={{ Option: RelationOption, SingleValue: SingleRelationValue }}
              disabled={isViewOnly}
            />
          </ShadowBox>
        </Col>
        <Col xs={10}>
          <ShadowBox className="ps-2 pe-8 py-1" flatOnHover>
            <Select
              invisible
              placeholder="Search for events"
              getOptionValue={option => option.id}
              isAsync
              defaultOptionsTriggerChange={selectedEventIds}
              defaultOptions={() =>
                getInitialAsyncValues('events', null, null, { exclude_ids: selectedEventIds })
              }
              value={value}
              loadOptions={search =>
                getAsyncOptions(search, 'events', { exclude_ids: selectedEventIds })
              }
              onChange={onChange}
              components={{ Option, SingleValue, DropdownIndicator: SelectSearchIndicator }}
              disabled={isViewOnly}
            />
          </ShadowBox>
        </Col>
      </Row>

      <div className="d-flex align-items-center job-actions">
        {value ? (
          <CircledButton
            type="view"
            svgStyle={{ width: 14, height: 14 }}
            className={`text-primary fw-bold fs-12 mt-1 me-1  `}
            onClick={onView}
          />
        ) : null}
        {!isViewOnly && (
          <CircledButton
            type="remove"
            className={`text-primary fw-bold fs-12 mt-1  `}
            onClick={value ? openDeleteModal : onEmptyOptionDelete}
          />
        )}
      </div>

      <DangerousActionModal
        transparent
        action={'Delete'}
        onAccept={onDelete}
        closeModal={closeDeleteModal}
        isOpen={showDeleteModal}
        actionText={'Unlink'}
        header={'Unlink'}
        body={`Are you sure you want to unlink this event ?`}
      />
    </div>
  );
};

export const SingleRelationValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {props.data ? <Relation data={props.data} /> : null}
  </components.SingleValue>
);

export const RelationOption = ({ children, ...props }) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <Relation data={data} />
    </components.Option>
  );
};

const Relation = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <SvgRender src={data.icon} style={{ width: 17, height: 17 }} />
      <div className="cms-12 text-primary fs-12">{data.name}</div>
    </div>
  );
};

export const SingleValue = ({ children, ...props }) => (
  <components.SingleValue className="w-100p pe-2" {...props}>
    {props.data ? <EventOption data={props.data} isValue /> : null}
  </components.SingleValue>
);

export const Option = ({ children, ...props }) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <EventOption data={data} />
    </components.Option>
  );
};

const EventOption = ({ data, isValue }) => {
  return (
    <Row className="d-flex align-items-center" noGutters>
      <Col xs={'auto'} className="d-flex justify-content-center align-items-center">
        <Priority size="small" value={_get(data, 'importance.label', '')} />
      </Col>
      <Col className="ps-2 w-100p d-flex align-items-center justify-content-between">
        <div
          className={`text-truncate text-nowrap overflow-x-hidden fw-bold ${
            isValue ? 'fs-12' : 'fs-14'
          }`}
        >
          {data.type.name} {'  '}
          <span className="ms-1 text-truncate text-nowrap fw-normal overflow-x-hidden ">
            {data.name}
          </span>
        </div>

        <div className="d-flex align-items-center ms-auto ">
          <div className="fw-bold fs-10 text-violet cme-12">
            {data.date_type === 'range' && data.started_at
              ? `${formatDate(data.started_at, data.is_datetime)} - ${formatDate(
                  data.ended_at,
                  data.is_datetime
                )}`
              : formatDate(data.started_at, data.is_datetime)}
          </div>

          <BaseStatusLabel
            color={data.status.color}
            value={data.status.name}
            label={data.status.label}
            className="event-module-status"
          />
        </div>
      </Col>
    </Row>
  );
};

export default Event;
