import TYPES from './types';
import TABLE_TYPES from 'common/components/table/store/types';
import LIST_TYPES from 'store/lists/types';

import { arrayMove } from 'react-sortable-hoc';
import _sortBy from 'lodash/sortBy';

const INITIAL_STATE = {
  statuses: []
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TYPES.REORDER_JOB_STATUSES.START:
      return {
        ...state,
        statuses: arrayMove([...state.statuses], payload.oldIndex, payload.newIndex)
      };

    case TABLE_TYPES.GET_TABLE_LIST.SUCCESS:
    case LIST_TYPES.FETCH_OPTIONS.SUCCESS:
      if (payload?.list === 'job-statuses' || payload?.table === 'jobs_statuses') {
        return {
          ...state,
          statuses: _sortBy(payload?.options || payload?.data, [el => el.sort_index])
        };
      } else {
        return state;
      }

    case TYPES.CREATE_JOB_STATUS.SUCCESS:
      return {
        ...state,
        statuses: _sortBy([...state.statuses, payload], [el => el.sort_index])
      };

    case TYPES.UPDATE_JOB_STATUS.SUCCESS:
      const newStatuses = state.statuses.map(el => {
        if (el.id !== payload.id) return el;
        return payload;
      });
      return {
        ...state,
        statuses: _sortBy(newStatuses, [el => el.sort_index])
      };

    case TYPES.ASSIGN_DEPARTMENTS.SUCCESS:
      let deps = payload.departments.map(el => el.id);
      let statuses = state.statuses.map(status => {
        if (status.id !== payload.id) return status;

        return {
          ...status,
          departments: deps
        };
      });
      return {
        ...state,
        statuses: _sortBy(statuses, [el => el.sort_index])
      };

    default:
      return state;
  }
};

export default reducer;
