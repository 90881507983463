import React from 'react';
import ContentLoader from 'react-content-loader';

const NotesLoader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 750 48"
    backgroundColor="#F8F9FA"
    foregroundColor="#EEF0F4"
    animate={true}
  >
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="48" />
  </ContentLoader>
);

export default NotesLoader;
