import React, { useState, useCallback, useEffect } from 'react';

import Textarea from 'common/components/form/inputs/Textarea';

import { useActions } from 'utils/hooks';
import { useSelector } from 'react-redux';
import {
  selectJobField,
  selectJobFieldError,
  checkIfEditMode,
  selectIsJobLocked
} from 'common/components/jobs/_base/store/selectors';
import UserCanEdit from 'common/components/jobs/_base/permissions/UserCanEdit';
import * as jobProfileActions from 'common/components/jobs/_base/store/actions';

const FieldComponent = ({ disabled, setIsFocused, setTitle, ...rest }) => {
  const [setJobField, setJobFieldError, updateJob] = useActions([
    jobProfileActions.setJobField,
    jobProfileActions.setJobFieldError,
    jobProfileActions.updateJob
  ]);
  const jobTitleError = useSelector(state => selectJobFieldError(state, 'title'));
  const jobId = useSelector(state => selectJobField(state, 'id'));
  const isJobLocked = useSelector(selectIsJobLocked);
  const jobTitle = useSelector(state => selectJobField(state, 'title'));

  const handleBlur = () => {
    setTimeout(() => setIsFocused(false), 200);
  };

  const handleKeyPress = e => {
    if (jobId) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();
        if (!jobTitle) {
          return; // Title should only be updated if it is a valid string
        } else {
          updateJob({ title: jobTitle });
          setTitle(jobTitle);
        }
      }
    }
  };

  return (
    <Textarea
      maxLength={255}
      autoResize={isJobLocked}
      className={`mb-0 ${isJobLocked ? 'pointer-events-none' : ''}`}
      placeholder="Add title"
      name="title"
      onFocus={() => setIsFocused(true)}
      onChange={e => {
        setJobField('title', e.target.value, false);

        if (jobTitleError && e.target.value) {
          setJobFieldError({ title: null });
        }
      }}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      value={jobTitle}
      error={jobTitleError}
      rows={1}
      disabled={disabled}
      {...rest}
    />
  );
};

const Title = ({ className, avoidCheck = false, ...rest }) => {
  const [updateJob] = useActions([jobProfileActions.updateJob]);

  const [isFocused, setIsFocused] = useState(false);
  const isEditMode = useSelector(checkIfEditMode);
  const isJobLocked = useSelector(state => selectIsJobLocked(state, avoidCheck ? false : true));

  const jobTitle = useSelector(state => selectJobField(state, 'title'));

  const [title, setTitle] = useState(jobTitle);

  useEffect(() => {
    if (!isFocused && isEditMode) {
      if (jobTitle !== title) {
        if (!jobTitle) {
          return; // Title should only be updated if it is a valid string
        } else {
          updateJob({ title: jobTitle });
          setTitle(jobTitle);
        }
      }
    }
  }, [isFocused]);

  return (
    <div
      className={`job-input job-input--with-actions job-input--title ${
        isFocused ? 'is-focused' : ''
      } ${className || ''}`}
    >
      <UserCanEdit
        field="title"
        fallback={() => (
          <FieldComponent
            setIsFocused={setIsFocused}
            setTitle={setTitle}
            disabled={true}
            {...rest}
          />
        )}
        avoidCheck={avoidCheck}
      >
        <>
          <FieldComponent
            setIsFocused={setIsFocused}
            setTitle={setTitle}
            disabled={isJobLocked}
            {...rest}
          />
        </>
      </UserCanEdit>
    </div>
  );
};

export default Title;
