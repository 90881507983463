import chat from 'common/assets/svg/common/chat-bubble.svg';

import { selectGlobalTotalUnreadMessages } from 'common/components/chatbox/store/selectors';
import { useSelector } from 'react-redux';

const Counter = () => {
  const total = useSelector(selectGlobalTotalUnreadMessages);

  return (
    <div className="d-inline-flex align-items-center justify-content-center rounded rounded-lg bg-light-gray p-1">
      <img alt="chatbox-icon" src={chat} width={16} height={16} />
      <strong className="fs-12 lh-1 ps-1">{total}</strong>
    </div>
  );
};

export default Counter;
