import PropTypes from 'prop-types';
import OvalButton from 'common/components/buttons/OvalButton';
import { selectInventoryIsSavingSurvey } from '../store/selectors';
import { useAppSelector } from '@/store/hooks';
import CircledButton from '@/ts-common/components/buttons/CircledButton';

const FieldsCategoryActions = ({ onCancel, onSave }) => {
  const isSaving = useAppSelector(selectInventoryIsSavingSurvey);

  return (
    <div className="d-flex justify-content-end mt-1 pb-2">
      <CircledButton type="close" onClick={onCancel} disabled={isSaving} />
      <OvalButton className="py-0 ms-1" type="save" onClick={onSave} disabled={isSaving} />
    </div>
  );
};

FieldsCategoryActions.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default FieldsCategoryActions;
