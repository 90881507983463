import React from 'react';
import Priority from 'common/components/general/Priority';

import { components } from 'react-select';

const OptionPreview = ({ data }) => {
  return (
    <div className="d-flex align-items-center w-100p">
      <Priority className="cpe-4" value={data?.priority?.id} withText={false} size="small" />
      <span className="fs-10 text-primary cme-12">{data.id}</span>
      <div className={`text-truncate fs-12 fw-medium`}>{data.title}</div>
      <div className="ms-auto">
        <span className={`view-status view-status--${data.status.color} my-0`}>
          {data.status.name}
        </span>
      </div>
    </div>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="w-100p">
      {props.data ? <OptionPreview data={props.data} /> : null}
    </components.Option>
  );
};

export const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue className="w-100p" {...props}>
      {props.data ? <OptionPreview data={props.data} /> : null}
    </components.SingleValue>
  );
};
