import PropTypes from 'prop-types';
import SvgRender from 'common/components/general/SvgRender';
import MarkAsResolvedModal from './MarkAsResolvedModal';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';

import check from 'common/assets/svg/actions/check.svg';
import x from 'common/assets/svg/common/x.svg';

import { useState } from 'react';
import { useFormState } from 'utils/hooks';

import ActionButton from 'common/components/buttons/ActionButton';
import { FormState } from 'common/entities/form/FormTypes';

const ResolveActions = ({ formState, showMarkAsResolve = true, container }) => {
  const [isResolveModalOpen, setIsResolveModalOpen] = useState(false);
  const [isUnresolveModalOpen, setIsUnResolveModalOpen] = useState(false);
  const [isHoveringResolvedButton, setIsHoveringResolvedButton] = useState(false);

  const { fields, selectField } = useFormState(formState);

  const isResolved = !!fields.resolved_at.value;

  return (
    <>
      {showMarkAsResolve && (
        <div className="pt-3 border-top">
          {isResolved ? (
            <ActionButton
              className={`text-white ${
                isHoveringResolvedButton || isUnresolveModalOpen ? 'bg-coral' : ''
              }`}
              color={isHoveringResolvedButton || isUnresolveModalOpen ? 'coral' : 'green'}
              onClick={() => setIsUnResolveModalOpen(true)}
              onMouseEnter={() => setIsHoveringResolvedButton(true)}
              onMouseLeave={() => setIsHoveringResolvedButton(false)}
              icon={check}
            >
              Marked as{' '}
              {isHoveringResolvedButton || isUnresolveModalOpen ? 'Unresolved' : 'Resolved'}
            </ActionButton>
          ) : (
            <ActionButton
              className="text-white"
              color="violet"
              onClick={() => setIsResolveModalOpen(true)}
              icon={check}
            >
              Mark as Resolved
            </ActionButton>
          )}
        </div>
      )}

      <MarkAsResolvedModal
        onCancel={() => setIsResolveModalOpen(false)}
        onSave={({ resolved_at }) => selectField('resolved_at')(resolved_at)}
        isOpen={isResolveModalOpen}
        initialValue={fields.resolved_at.value}
        container={container}
      />

      <DangerousActionModal
        transparent
        action={'Delete'}
        container={container}
        actionIcon={<SvgRender src={x} style={{ width: 80, height: 70 }} className="text-white" />}
        onAccept={() => selectField('resolved_at')(null)}
        closeModal={() => setIsUnResolveModalOpen(false)}
        isOpen={isUnresolveModalOpen}
        actionText={'UNRESOLVED'}
        header={'Mark as unresolved'}
        body={'Are you sure you want to mark this finding as unresolved?'}
      />
    </>
  );
};

ResolveActions.propTypes = {
  formState: FormState,
  showForms: PropTypes.bool,
  showMarkAsResolve: PropTypes.bool,
  container: PropTypes.string
};

export default ResolveActions;
