import React, { useEffect, useState } from 'react';
import Select from 'common/components/form/inputs/Select';
import StyledSelect from 'common/components/form/styled-inputs/Select';
import IconSelect from 'common/components/form/styled-inputs/IconSelect';

import { selectListFromStore } from 'store/lists/selectors';
import { getAsyncOptions, getInitialAsyncValues } from 'utils/helpers';
import { getPartyDisplayName } from 'common/utils/labels';

import { useSelector } from 'react-redux';
import { useActions } from 'utils/hooks';
import useCancelablePromise from 'common/utils/hooks/useCancelablePromise';

import * as listActions from 'store/lists/actions';

const getLabel = (option, list) => {
  switch (list) {
    case 'ports':
    case 'cargo-grades':
    case 'fuel-grades':
      return option.name;
    case 'parties':
      return getPartyDisplayName(option);
    case 'vessels':
      return option.name;
    case 'report-groups':
      return option.label;
    case 'manuals':
      return option.title;

    default:
      return option.name;
  }
};

const ListSelect = ({
  list,
  params = {},
  isCp = false,
  vesselTypeId = null,
  isAsync = false,
  placeholder,
  styled,
  invisible = false,
  filter,
  icon,
  ...rest
}) => {
  const cancelablePromise = useCancelablePromise();
  const [isInitialized, setIsInitialized] = useState(false);

  const { options, isFetching } = useSelector(state => selectListFromStore(state, list)) || {
    options: [],
    isFetching: false
  };

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);

  const getListOptions = async (isLoading, optionsLength, isInit) => {
    if (!isLoading && !optionsLength && !isInit) {
      setIsInitialized(true);
      await cancelablePromise(fetchListOptions(list, '', params));
    }
  };

  const fetchData = search => {
    if (isCp) params.role = 'charterers';

    if (list === 'cargo-grades') {
      params.vessel_type_id = vesselTypeId;
    }

    return getAsyncOptions(search, list, params);
  };

  const getDefaultOptions = () => {
    if (list === 'cargo-grades') {
      return getInitialAsyncValues(list, null, null, { vessel_type_id: vesselTypeId });
    } else if (list === 'parties' && isCp) {
      return getInitialAsyncValues(list, null, null, { role: 'charterers' });
    }

    return getInitialAsyncValues(list, null, null, params);
  };

  const selectProps = isAsync
    ? {
        isAsync,
        loadOptions: search => fetchData(search),
        defaultOptions: getDefaultOptions,
        ...rest
      }
    : { ...rest, options: rest?.options?.length ? rest?.options : options };

  useEffect(() => {
    if (!isAsync) {
      getListOptions(isFetching, options?.length, isInitialized);
    }
  }, [isAsync, isFetching, options?.length, params]);

  const SelectTag = icon ? IconSelect : styled ? StyledSelect : Select;

  return (
    <SelectTag
      placeholder={placeholder}
      getOptionValue={option => option.id}
      getOptionLabel={option => getLabel(option, list)}
      label={null}
      invisible={invisible}
      isAsync={isAsync}
      value={filter !== undefined ? filter?.value : null}
      icon={icon}
      {...selectProps}
    />
  );
};
export default ListSelect;
