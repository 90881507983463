import { useState, FC, useMemo } from 'react';
import _groupBy from 'lodash/groupBy';
import { components, OptionProps, SingleValue, MultiValue } from 'react-select';
import _isArray from 'lodash/isArray';

import useFilterSelector from 'common/components/filters/useFilterSelector';

import { getFilterSelectorValues } from '../filters/helpers';
import Select from '@/ts-common/components/form/inputs/select';
import { VesselSystemItemType } from '@/common/types/pms.ts';
import { TableFilterSelectorProps } from '@/common/types/front-entities/table';
import emptyRibbon from 'common/assets/svg/common/ribbon-line.svg';
import burger from 'common/assets/svg/common/chapter.svg';

import SvgRender from '@/ts-common/components/general/SvgRender.tsx';

export interface VesselSystemGroupSelectorProps extends TableFilterSelectorProps {
  wrapperClassName?: string | null;
  invisible?: boolean;
  error?: string | null;
}

interface ParsedVesselSystemItemType extends VesselSystemItemType {
  depth: number;
}

type SelectedType =
  | SingleValue<ParsedVesselSystemItemType>
  | MultiValue<ParsedVesselSystemItemType>;

type Element = {
  id: number;
  code: string;
  name: string;
  depth?: number;
};

type GroupedElements = {
  [key: number]: Element[];
};

type GroupProps = {
  data: {
    id: number;
    code: string;
    name: string;
    depth: number;
    parent_id?: number | null;
  };
  className?: string;
};

const makeArray = (
  arr: Element[],
  result: Element[],
  grouped: GroupedElements,
  depth: number
): void => {
  arr.forEach(el => {
    result.push({ ...el, depth });
    const children = grouped[el.id];
    if (children) {
      makeArray(children, result, grouped, depth + 1);
    }
  });
};

const Group: FC<GroupProps> = ({ data, className }) => {
  return (
    <div className={`d-flex align-items-center ${className ? className : ''}`}>
      <SvgRender
        src={!data.parent_id ? burger : emptyRibbon}
        style={{ width: 14, height: 14, minWidth: 14, minHeight: 14 }}
        className="me-1"
      />
      <div className="lh-16">
        {data.code} {data.name}
      </div>
    </div>
  );
};

const VesselSystemGroupSelector: FC<VesselSystemGroupSelectorProps> = ({
  filter,
  value,
  onChange,
  isMulti,
  wrapperClassName = '',
  showIcon,
  listParams,
  ...rest
}) => {
  const [selected, setSelected] = useState<SelectedType>([]);

  const selectedValue = filter?.value || value;

  useFilterSelector(selectedValue, { list: 'vessel-system-groups' }, { selected, setSelected });

  const constructOptions = (res: VesselSystemItemType[]) => {
    const grouped = _groupBy(res, 'parent_id');
    const allIds = res.map(el => el.id);

    const root = res ? res.filter(el => !el.parent_id || !allIds.includes(el.parent_id)) : [];

    const result: ParsedVesselSystemItemType[] = [];
    makeArray(root, result, grouped, 0);
    return result;
  };

  const memoizedRequestParams = useMemo(
    () => ({
      params: { ...(listParams || {}), list: 'vessel-system-groups' },
      path: '/lists'
    }),
    [listParams]
  );

  return (
    <div className={`${wrapperClassName}`}>
      <Select
        className={`form-field position-relative mb-0`}
        placeholder="Select system group"
        getOptionValue={(option: VesselSystemItemType) => option.id.toString()}
        getOptionLabel={(option: VesselSystemItemType) => `${option.code}. ${option.name}`}
        isAsync
        components={{ Option: Option as any }}
        isMulti={isMulti}
        memoizedRequestParams={memoizedRequestParams}
        value={
          isMulti
            ? selectedValue?.length
              ? getFilterSelectorValues(selected, selectedValue, isMulti)
              : []
            : selectedValue?.value || selectedValue
        }
        parseOptions={constructOptions}
        onChange={s => {
          setSelected(s);
          onChange({
            value: s ? (isMulti && _isArray(s) ? [...s.map(s => s.id)] : s) : ''
          });
        }}
        size="sm"
        {...rest}
      />
    </div>
  );
};

const Option: FC<OptionProps<ParsedVesselSystemItemType>> = ({ children, data, ...props }) => {
  const multiplier = 3;

  return (
    <components.Option {...props} data={data} className="w-100p">
      <Group data={data} className={`ps-${data.depth * multiplier}`} />
    </components.Option>
  );
};
export default VesselSystemGroupSelector;
