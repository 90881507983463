import loader from 'common/assets/svg/common/loader-blue.svg';
import styled from '@emotion/styled';
import { Size } from './_types';

const iconSize = {
  lg: 18,
  sm: 12,
  xs: 10
};

const ProgressLoader = ({ size }) => {
  return (
    <Container
      size={size}
      className="d-flex align-items-center justify-content-center bg-white rounded-circle"
    >
      <img
        alt="file-progress-loader"
        src={loader}
        width={iconSize?.[size]}
        height={iconSize?.[size]}
      />
    </Container>
  );
};

ProgressLoader.propTypes = {
  size: Size.isRequired
};

const Container = styled.div`
  width: ${({ size }) => `${iconSize?.[size]}px`};
  height: ${({ size }) => `${iconSize?.[size]}px`};
  position: absolute;
  right: ${({ size }) => `-${iconSize?.[size] / 2 - 2}px`};
  top: -2px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);

  > img {
    transform: scale(0.8);
  }
`;

export default ProgressLoader;
