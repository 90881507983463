import PropTypes from 'prop-types';
import paths from 'routing/routes/_paths';

import { reportURL } from 'captain-reports/components/ReportLink';
import _get from 'lodash/get';

export const entityTypePropTypes = PropTypes.oneOf(['report', 'form_submission']);

export const getEntityLocationPath = (entityType, message) =>
  entityType === 'report'
    ? reportURL[_get(message, 'report.template.type', '')] || paths.captain_reports
    : entityType === 'job'
    ? message?.job?.type === 'pms'
      ? paths.pms_jobs
      : paths.regular_jobs
    : entityType === 'purchasing_requisition'
    ? paths.purchasing_requisitions
    : paths.forms;
